<div class="introduction">
          <p class="heading" id="5">SAP ECC with Full & Delta Load    </p>
          <ol>
              <li>Click on<b> Create new project.</b>  </li>
              <li>Select <b>SAP ECC</b>SAP ECC as source system.    </li>
              <img src="assets\user-manual\data-&-AI\2.svg" >
              <figcaption class="caption">SAP ECC - source system </figcaption>
              <li>Provide required details for Azure Synapse as destination and for SAP ECC as source system.  </li>
              <img src="assets\user-manual\data-&-AI\SAP-ECC.svg" >
              <figcaption class="caption">SAP ECC - source details </figcaption>
              <li>Select the extractor. </li>
              <img src="assets\user-manual\data-&-AI\SAP-ECC(1).svg" >   
              <figcaption class="caption">SAP ECC - select extractor </figcaption>  
              <li>Choose the load type. </li>
              <img src="assets\user-manual\data-&-AI\SAP-ECC(2).svg" >
              <figcaption class="caption"> SAP ECC – load type</figcaption>
              <li>Wait for the extractor process is complete.  </li>
              <img src="assets\user-manual\data-&-AI\SAP-ECC(3).svg" >
              <figcaption class="caption">SAP ECC - migrate data</figcaption>
                  
              <li>If data migration is successful, Successful migration message will be displayed.  </li>  
              <img src="assets\user-manual\data-&-AI\SAP-ECC(4).svg" >
              <figcaption class="caption"> SAP ECC - migration success </figcaption>
              <li>In case of error/failure, Failed data migration message will be displayed.  </li>
              <img src="assets\user-manual\data-&-AI\36.svg" >
              <figcaption class="caption">SAP ECC - migration failure </figcaption>
              <li>In case of partial migration, Partial data migration message will be displayed.  </li>
              <img src="assets\user-manual\data-&-AI\SAP-ECC(5).svg" >
              <figcaption class="caption"> SAP ECC - partial success </figcaption>
              <li>Project summary page will be displayed after migration process is complete.   </li>
              <img src="assets\user-manual\data-&-AI\SAP-ECC(6).svg" >
              <figcaption class="caption">SAP ECC - project summary </figcaption>
            
            
              
          </ol>
    
</div>
