import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken, Selector, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

export class DataGovernanceOverviewStateModel {
  dataflows?:TileData;
  datasets?:TileData;
  integrationRuntimes?:TileData;
  linkedservices?:TileData;
  pipelines?:TileData;
  sparkJobDefinitions?:TileData;
  triggers?:TileData;
  libraries?:TileData;
  notebooks?:TileData;
  sqlScripts?:TileData;
  bigDataPools?:TileData;
  sqlPools?:TileData;
  datasort?: string;
  filterData?: FilterData;
  filterDataForm:{
    model: {
      subscription:string,
      resourceGroup:string,
      workspace:string,
      sqlPoolName?:string;
      sparkPoolName?:string;
    }
  };
  projectDetail:{
    projectList:any[],
    selectedProject:any
  }
}

export interface FilterData {
  subscriptionList: any[],
  resourceGroupList: any[],
  synapseWorkspaceList: any[],
  sqlPoolList?: any[],
  sparkPoolList?: any[],
}

export interface TileData {
  value: any[];
  refreshedTime?: Date;
}

export class GetTilesDataAction {
  static readonly type = '[DataGovernanceOverview] getTilesData';

  constructor(readonly workspace: string, readonly tileName: string) { }
}

export class SortTilesDataAction {
  static readonly type = '[DataGovernanceOverview] sortTilesData';

  constructor(readonly tileName: string, readonly fieldName: string) { }
}

export class GetFilterDetailAction {
  static readonly type = '[DataGovernanceOverview] getFilterDetail';
  
  constructor(readonly grovernanceProjectData: any, readonly changeValue:{
    subscriptionId:string,
    subscription:string,
    resourceGroup:string,
    workspace:string,
    sqlPoolName?:string,
    sparkPoolName?:string,
  }) { }
}

export class GetProjectListAction {
  static readonly type = '[DataGovernanceOverview] getProjectList';

  constructor(readonly projectList: any[], readonly selectedProject: any) { }
}

const DATA_GOVERNANCE_OVERVIEW_STATE_TOKEN = new StateToken<DataGovernanceOverviewStateModel>('dataGovernanceOverview');

@State<DataGovernanceOverviewStateModel>({
  name: DATA_GOVERNANCE_OVERVIEW_STATE_TOKEN,
  defaults: {
    bigDataPools:{
      value:[],
      refreshedTime:new Date()
    },
    dataflows:{
      value:[],
      refreshedTime:new Date()
    },
    datasets:{
      value:[],
      refreshedTime:new Date()
    },
    integrationRuntimes:{
      value:[],
      refreshedTime:new Date()
    },
    libraries:{
      value:[],
      refreshedTime:new Date()
    },
    linkedservices:{
      value:[],
      refreshedTime:new Date()
    },
    pipelines:{
      value:[],
      refreshedTime:new Date()
    },
    sparkJobDefinitions:{
      value:[],
      refreshedTime:new Date()
    },
    sqlPools:{
      value:[],
      refreshedTime:new Date()
    },
    sqlScripts:{
      value:[],
      refreshedTime:new Date()
    },
    triggers:{
      value:[],
      refreshedTime:new Date()
    },
    notebooks:{
      value:[],
      refreshedTime:new Date()
    },
    filterDataForm:{
      model: undefined
    },
    projectDetail:{
      projectList:[],
      selectedProject:null
    }
  }
})
@Injectable()
export class DataGovernanceOverviewState {
  constructor(private readonly apiService:ApiService,private http:HttpClient,private store:Store) {}

  @Selector([DataGovernanceOverviewState])
  static filterData(state:DataGovernanceOverviewStateModel): FilterData {
    return state?.filterData
  }
  
  @Selector([DataGovernanceOverviewState])
  static filterDataForm(state:DataGovernanceOverviewStateModel): any {
    return state?.filterDataForm
  }
  
  @Selector([DataGovernanceOverviewState.filterDataForm])
  static filterDataFormData(state:any): any {
    return state?.model
  }
  
  @Selector([DataGovernanceOverviewState])
  static bigDataPools(state:DataGovernanceOverviewStateModel): TileData {
    return state?.bigDataPools
  }

  @Selector([DataGovernanceOverviewState])
  static datasets(state:DataGovernanceOverviewStateModel): TileData {
    return state?.datasets
  }

  @Selector([DataGovernanceOverviewState])
  static dataflows(state:DataGovernanceOverviewStateModel): TileData {
    return state?.dataflows
  }

  @Selector([DataGovernanceOverviewState])
  static integrationRuntimes(state:DataGovernanceOverviewStateModel): TileData {
    return state?.integrationRuntimes
  }

  @Selector([DataGovernanceOverviewState])
  static libraries(state:DataGovernanceOverviewStateModel): TileData {
    return state?.libraries
  }

  @Selector([DataGovernanceOverviewState])
  static linkedservices(state:DataGovernanceOverviewStateModel): TileData {
    return state?.linkedservices
  }
  
  @Selector([DataGovernanceOverviewState])
  static notebooks(state:DataGovernanceOverviewStateModel): TileData {
    return state?.notebooks
  }

  @Selector([DataGovernanceOverviewState])
  static pipelines(state:DataGovernanceOverviewStateModel): TileData {
    return state?.pipelines
  }

  @Selector([DataGovernanceOverviewState])
  static sparkJobDefinitions(state:DataGovernanceOverviewStateModel): TileData {
    return state?.sparkJobDefinitions
  }

  @Selector([DataGovernanceOverviewState])
  static sqlPools(state:DataGovernanceOverviewStateModel): TileData {
    return state?.sqlPools
  }

  @Selector([DataGovernanceOverviewState])
  static sqlScripts(state:DataGovernanceOverviewStateModel): TileData {
    return state?.sqlScripts
  }

  @Selector([DataGovernanceOverviewState])
  static triggers(state:DataGovernanceOverviewStateModel): TileData {
    return state?.triggers
  }
  
  @Selector([DataGovernanceOverviewState])
  static projectDetail(state:DataGovernanceOverviewStateModel): any {
    return state?.projectDetail
  }

  @Action(GetTilesDataAction)
  getTilesData(ctx: StateContext<DataGovernanceOverviewStateModel>, action: GetTilesDataAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getTilesData', [action.workspace,action.tileName]);
    return this.http.get<TileData>(url)
    .pipe(
      tap((tileData:TileData) => {
        ctx.patchState({
          [action.tileName]:{
            value:tileData.value,
            refreshedTime:new Date()
          }
        })
      }));
  }
  
  @Action(SortTilesDataAction)
  sortTilesData(ctx: StateContext<DataGovernanceOverviewStateModel>, action: SortTilesDataAction) {
      let num = 0;
      (ctx.getState().datasort === 'desc') ? num = 1 : num = -1;
      let value = [...ctx.getState()[action.tileName].value];
      let fieldName=action.fieldName;
      value.sort((a, b) => {
        switch (fieldName) {
          case 'name': {
            let x = a.name ? a.name : ''
            let y = b.name ? b.name : ''
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          default: {
            let val1,val2;
            fieldName.split('.').forEach(dt=>{
              if(val1!=undefined){
                val1=val1[dt]
              }else {
                val1=a[dt]
              }
              if(val2!=undefined){
                val2=val2[dt]
              }else {
                val2=b[dt]
              }
            })
            let x = val1 ? val1 : ' ';
            let y = val2 ? val2 : ' ';
            return (x < y) ? num : ((x > y) ? -num : 0);
          }
        }
      })
      ctx.patchState({
        [action.tileName]:{
          ...ctx.getState()[action.tileName],
          value
        },
        datasort: ctx.getState().datasort === 'desc' ? 'asc' : 'desc'
      })
  }

  @Action(GetFilterDetailAction)
  getFilterDetail(ctx: StateContext<DataGovernanceOverviewStateModel>, action: GetFilterDetailAction) {
    const synapseWorkspaceList=[];
    if(action.grovernanceProjectData) {
      action.grovernanceProjectData.Resource.forEach(e=>{
        let obj ={
          workspaceName : e.workspace.name ?  e.workspace.name.split('/')[0] : null
        }
        if(!synapseWorkspaceList.find(dt=>dt.workspaceName.toLowerCase()===obj.workspaceName.toLowerCase())) {
          synapseWorkspaceList.push(obj)
        }
      })
    } else if( action.changeValue ) {
      synapseWorkspaceList.push({
        workspaceName:action.changeValue.workspace
      })
    }
    this.store.dispatch([new GetTilesDataAction(synapseWorkspaceList[0].workspaceName,'sqlPools'),
    new GetTilesDataAction(synapseWorkspaceList[0].workspaceName,'bigDataPools')]).subscribe(res=>{
      const sqlPools=ctx.getState().sqlPools;
      const subscriptionList=ctx.getState().filterData&&ctx.getState().filterData.subscriptionList?ctx.getState().filterData.subscriptionList:[];
      const bigDataPools=ctx.getState().bigDataPools;
      ctx.patchState({
        filterData:{
          subscriptionList:[{
            subscriptionName:action.grovernanceProjectData?action.grovernanceProjectData.Subscription:action.changeValue.subscription,
            subscriptionId:action.grovernanceProjectData?action.grovernanceProjectData.Subscription_Id:subscriptionList[0].subscriptionId
          }],
          resourceGroupList:[{
            resourceGroupName:action.grovernanceProjectData?action.grovernanceProjectData.Resource_Group:action.changeValue.resourceGroup
          }],
          synapseWorkspaceList,
          sqlPoolList:sqlPools.value,
          sparkPoolList:bigDataPools.value,
        }
      });
    })
  }

  @Action(GetProjectListAction)
  getProjectList(ctx : StateContext<DataGovernanceOverviewStateModel>, action: GetProjectListAction) {
    if(action.projectList && action.projectList.length && action.selectedProject) {
      ctx.patchState({
        projectDetail:{
          projectList:action.projectList,
          selectedProject:action.selectedProject
        }
      })
      return ctx.dispatch(new GetFilterDetailAction(action.selectedProject,null))
    } else if((!action.projectList ||!(action.projectList && action.projectList.length)) && action.selectedProject) {
        ctx.patchState({
          projectDetail:{
            ...ctx.getState().projectDetail,
            selectedProject:action.selectedProject
          }
        })
        return ctx.dispatch(new GetFilterDetailAction(action.selectedProject,null))
    } else {
      ctx.patchState({
        projectDetail:{
          projectList:[],
          selectedProject:null
        }
      })
    }
  }
}
