<div class="custom-fixed-tables">
    <table class="table">
        <thead>
          <tr>
            <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('firewallData',0)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('firewallData',1)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('firewallData',2)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('firewallData',3)"></div></th>
            <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('firewallData',4)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Virtual Network</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('firewallData',5)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Public IP Address</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('firewallData',6)"></div></th>
            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('firewallData',7)"></div></th>
            <th style="border-left: none;" class="wid-75"></th>
        </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data"  class="cursor-pointer">
              <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(firewallDetailsTemplate,row,'firewallPopup')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
              <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[6] | displayTxt}}</td>
              <td isEllipsisActive>{{ getTags(row[7]) }}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('ipConfig',row)">
                      <p class="delete-popup-text">Public IP Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('changeFirewallPolicy',row)">
                      <p class="delete-popup-text">Change firewall policy</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteFirewallData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>

  <ng-container *ngIf="showSidesheet">
 
    <!--Public IP Config -->
    <ng-container *ngIf="currentTemplate == 'ipConfig'" >
        <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" id="sideSheets">
            <div class="header">
                <div class="d-flex">
                    <h4 class="half-width">Public IP Configuration<br> <span class="blur-text">Firewall | {{selectedRow[0]}}</span></h4>
                   <div class="text-right half-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <div class="mb-3">
                    <button class="btn-btn-secondary" (click)="addPublicIP()">
                      <img src="/assets/governance/add-btn.svg" alt="">
                      Add Public IP Configuration
                     </button>
                </div>
                <div class="custom-fixed-tables">
                    <table class="table">
                        <thead>
                            <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Name</span>
                                      <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('name',connectionList)"> -->
                                  </div>
                              </th>
                              <th style="border-right: none;">
                                  <div class="flex-custom no-wrap">
                                      <span>IP Address</span>
                                      <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('status',connectionList)"> -->
                                  </div>
                              </th>
                              <th style="border-left: none;"></th>
                            </tr>
                          </thead>
                          <tbody >
                            <ng-container *ngFor="let list of publicIPConfigList">
                                <tr>
                                    <td>{{list?.name}}</td>
                                    <!-- <td>{{list?.properties?.publicIPAddress?.id.split('/')[8]}}</td> -->
                                    <td>{{list?.properties?.publicIPAddress?.id ? getIPAddress(list?.properties?.publicIPAddress?.id) : ''}}</td>
                                    <td style="width: 1px;">
                                        <span class="float-right">
                                            <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                            <mat-menu #actionInbound="matMenu" class="delete-popup" id="matMenu">
                                              <div class="mat-menu-item" mat-menu-item (click)="addPublicIP(list)">
                                                <p class="delete-popup-text">Edit</p>
                                              </div>
                                              <div class="mat-menu-item" mat-menu-item (click)="action(list,'removePublicIP')">
                                                <p class="delete-popup-text">Delete</p>
                                              </div>
                                            </mat-menu>
                                          </span>    
                                    </td>
                                </tr>
                              </ng-container>
                              <div class="col-md-12 mt-3" *ngIf="!publicIPConfigList?.length">
                                <p class="content-14 blur-text">No results</p>
                              </div>
                          </tbody>
                    </table>
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">

              <!-- <button class="btn btn-btn-primary disabled" (click)="saveLNG('connection')">Save</button> -->
          </div>
          <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
            <div class="header">
                <div class="d-flex">
                    <h4 >{{isEdit ? 'Edit' : 'Add'}} Public IP Configuration <br> <span class="blur-text">Firewall | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation();resetForm();">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                        <input class="form-control full-width" [class.disabled]="isEdit" type="text" name="ipName" [clearable]="false" [class.border-danger]="inputError?.nameEmpty|| inputError?.nameMaxLen ||inputError?.nameExist|| inputError?.nameValid ||inputError?.nameLastValid" id="ipName" formControlName="ipName" autocomplete="off" placeholder=""   (keyup)="validIPName($event,80,'IPName')" >
                        <p class="text-danger" *ngIf="inputError?.nameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.nameMaxLen">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.nameExist">{{existedIPName}} already exists in the resource group</p>
                        <p class="text-danger" *ngIf="inputError?.nameValid ||inputError?.nameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                            and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>  
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Public IP address<span class="mandatory">*</span></label>
                      <ng-select [items]="publicIPList" groupBy="type" bindLabel="name" bindValue="id" name="ipAddress" [clearable]="false" id="ipAddress" (change)="saveDisable=true" formControlName="ipAddress"></ng-select>
                    </div>  
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('addPublicIP')" (click)="saveFirewallData('addPublicIP')">{{isEdit ? 'Save' : 'Add'}}</button>
            </div>
        </div>
        </div>
    </ng-container>
     <!-- Change Firewall polcy-->
     <ng-container *ngIf="currentTemplate == 'changeFirewallPolicy'">
      <div class="sidebar-right single" style="width: 675px;" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Select an Azure Firewall Policy<br> <span class="blur-text">Firewall | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
           <form [formGroup]="currentForm">
            <div class="custom-fixed-tables mb-3">
                <table class="table">
                    <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Name</span>
                                  <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('size',skuList)"> -->
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Inherits From</span>
                              </div>
                          </th>
                          <th>
                            <div class="flex-custom no-wrap">
                                <span>Firewall Policy Tier</span>
                            </div>
                        </th>
                          <th>
                            <div class="flex-custom no-wrap">
                                <span>Subscription</span>
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Resource Group</span>
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container formArrayName="policyName" *ngFor="let form of currentForm.get('policyName')['controls'];let i=index;let list of firewallPolicyList">
                            <tr [class.disabled-icon]="!list.checked && policyCount.length" >
                              <!--[class.disabled-icon]="(policyCount.length && policyCount[0].id!=list.id) && disableRow" -->
                                <td>
                                  <input type="checkbox"  name="policyName" class="form-check-inline"  id='policyName_{{i}}'  (change)="enableAction(list,$event,i)" formControlName="name" [checked]="list?.checked">
                                  <label class="value" for="policyName_{{i}}">{{list?.name}}</label>
                                </td>
                                <td></td>
                                <td isEllipsisActive>{{list?.properties?.sku?.tier}}</td>
                                <td isEllipsisActive>{{ getProjectName(list?.id.split('/')[2])}}</td>
                                <td isEllipsisActive>{{list?.id.split('/')[4].toLowerCase()}}</td>
                            </tr>
                          </ng-container>
                          <div class="col-md-12 mt-3" *ngIf="!firewallPolicyList?.length">
                            <p class="content-14 blur-text">No results</p>
                          </div>
                      </tbody>
                </table>
            </div>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="!policyCount.length || !saveDisable "(click)="saveFirewallData('changeFirewallPolicy')">Save</button>
         </div>
         <!-- || invalidForm('scaling') -->
      </div>
    </ng-container>

</ng-container>
  
  <ng-template #firewallDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">Azure Firewall</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" (click)="openSidesheet('ipConfig',selectedRow)">Public IP Configuration</div>
            <div class="tab-item" (click)="openSidesheet('changeFirewallPolicy',selectedRow)">Change firewall policy</div>
            <div class="tab-item" (click)="action(selectedRow,'deleteFirewallData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedRow[i]:getTags(selectedRow[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedRow[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>