import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security-posture',
  templateUrl: './security-posture.component.html',
  styleUrls: ['./security-posture.component.scss']
})
export class SecurityPostureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
