import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-connection-actions',
  templateUrl: './connection-actions.component.html',
  styleUrls: ['./connection-actions.component.scss']
})
export class ConnectionActionsComponent implements OnInit {
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("connectionDetailsTemplate") connectionDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    sharedKeyMaxLen:false,
    sharedKeyEmpty:false,
    sharedKeyASCII:false,
    IPsecKBNameEmpty:false,
    IPsecKBInvalidRange:false,
    IPsecKBInvalidNumber:false,
    IPsecSecNameEmpty:false,
    IPsecSecInvalidRange:false,
    IPsecSecInvalidNumber:false,
    timeOutNameEmpty:false,
    timeOutInvalidRange:false,
    timeOutInvalidNumber:false,
  }
  selectedType: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;



  encryption=[
    // {id:'None',name:'None'},
    {id:'AES128',name:'AES128'},
    {id:'AES192',name:'AES192'},
    {id:'AES256',name:'AES256'},
    {id:'GCMAES128',name:'GCMAES128'},
    {id:'GCMAES256',name:'GCMAES256'},
  ]
  integrity=[
    // {id:'None',name:'None'},
    {id:'SHA1',name:'SHA1'},
    {id:'SHA256',name:'SHA256'},
    {id:'SHA384',name:'SHA384'},
    {id:'MD5',name:'MD5'},
  ]
  DHGroup=[
    {id:'None',name:'None'},
    {id:'DHGroup1',name:'DHGroup1'},
    {id:'DHGroup14',name:'DHGroup14'},
    {id:'DHGroup2',name:'DHGroup2'},
    {id:'DHGroup2048',name:'DHGroup2048'},
    {id:'DHGroup2024',name:'DHGroup2024'},
    {id:'DHGroup256',name:'DHGroup256'},
    {id:'DHGroup384',name:'DHGroup384'},
  ]
  IPsecEncryption=[
    {id:'None',name:'None'},
    {id:'AES128',name:'AES128'},
    {id:'AES192',name:'AES192'},
    {id:'AES256',name:'AES256'},
    {id:'DES',name:'DES'},
    {id:'DES3',name:'DES3'},
    {id:'GCMAES128',name:'GCMAES128'},
    {id:'GCMAES192',name:'GCMAES192'},
    {id:'GCMAES256',name:'GCMAES256'},
  ]
  IPsecIntegrity=[
    // {id:'None',name:'None'},
    {id:'GCMAES128',name:'GCMAES128'},
    {id:'GCMAES192',name:'GCMAES192'},
    {id:'GCMAES256',name:'GCMAES256'},
    {id:'MD5',name:'MD5'},
    {id:'SHA1',name:'SHA1'},
    {id:'SHA256',name:'SHA256'},
  ]
  PFSGroup=[
    {id:'None',name:'None'},
    {id:'ECP256',name:'ECP256'},
    {id:'PFS2048',name:'PFS2048'},
    {id:'ECP384',name:'ECP384'},
    {id:'PFS24',name:'PFS24'},
    {id:'PFS2',name:'PFS2'},
    {id:'PFS1',name:'PFS1'},
  ]

  connectionData: any;
  vpnGatewayData: any;
  ingressList: any;
  egressList: any;

  constructor( public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private validatorService : ValidatorsService){ }

  ngOnInit(): void {
  }

  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteConnectionData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteConnectionData')
      }
    }
  }
    // Trigger action of the Connection
  
  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "sharedKey" : {
        this.closeModal()
        this.currentForm = this.util.getForm('connectionSharedKey')
        await this.getConnectionData(this.selectedRow)
        this.currentForm.get('sharedKey').setValue(this.connectionData?.properties?.sharedKey)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "natRules" : {
        this.closeModal()
        this.currentForm = this.util.getForm('connectionNATRules')
        await this.getVPNGatewayData(this.selectedRow)
        await this.getConnectionData(this.selectedRow)
        let ingressArray=this.connectionData?.properties?.ingressNatRules?this.connectionData?.properties?.ingressNatRules.map(e=>e.id):[]
        let egressArray=this.connectionData?.properties?.egressNatRules?this.connectionData?.properties?.egressNatRules.map(e=>e.id):[]
        this.currentForm.get('ingressNAT').setValue(ingressArray)
        this.currentForm.get('egressNAT').setValue(egressArray)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "configuration" : {
        this.closeModal()
        this.currentForm = this.util.getForm('connectionConfiguration')
        await this.getVPNGatewayData(this.selectedRow)
        await this.getConnectionData(this.selectedRow)
        await this.setDefaultValue('configuration')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
    }
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteConnectionData' :return this.deleteConnectionData(this.selectedRow)
    }
  }
  
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedRow=row;
    this.selectedType=type;
    // type=='appServicePopup'?this.getAppServicePlanData(row):false 
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteConnectionData':return 'Delete Connection'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteConnectionData':return `Are you sure you want to delete “${this.selectedRow[0]}”?` 
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteConnectionData':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      case 'connected': return 'st-success';
      case 'not connected': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  toolTip(){
    return `Setting "Use policy based traffic selector" to true on a connection will configure the 
    Azure VPN gateway to connect to policy-based VPN firewall on premises. If you enable
     this field, you need to ensure your VPN device has the matching traffic selectors defined 
    with all combinations of your on-premises network (local network gateway) prefixes to/from 
    the Azure virtual network prefixes, instead of any-to-any. For example, if your on-premises network
     prefixes are 10.1.0.0/16 and 10.2.0.0/16, and your virtual network prefixes are 192.168.0.0/16 and 
    172.16.0.0/16, you need to specify the following traffic selectors:
    10.1.0.0/16 <====> 192.168.0.0/16
    10.1.0.0/16 <====> 172.16.0.0/16
    10.2.0.0/16 <====> 192.168.0.0/16
    10.2.0.0/16 <====> 172.16.0.0/16`
  }
  getSuccesText(type){
    switch(type){
      case'sharedKey':return 'Shared key created successfully!'
      case'natRules':return `“${this.selectedRow[0]}” NAT rules updated successfully!`
      case'config':return `“${this.selectedRow[0]}” configuration updated successfully!`
      default: return 'Changes saved successfully!'
    }
  }
  
  getFailureText(type){
    switch(type){
      case'sharedKey':return 'Shared key failed to be created.'
      case'natRules':return `Failed to update “${this.selectedRow[0]}” NAT rules `
      case'config':return `“${this.selectedRow[0]}” configuration updating failed`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'sharedKey':return 'Creating shared key...'
      case'natRules':return `Updating “${this.selectedRow[0]}” NAT rules...`
      case'config':return `Updating “${this.selectedRow[0]}” configuration...`
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'connectonData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }
  
  invalidForm(type){
    switch(type){
      case'sharedKey':{
        return this.inputError.sharedKeyEmpty|| this.inputError.sharedKeyMaxLen|| this.inputError.sharedKeyASCII;
      }
      case'config':{
        return this.inputError.IPsecKBNameEmpty|| this.inputError.IPsecKBInvalidRange|| this.inputError.IPsecKBInvalidNumber
        || this.inputError.IPsecSecNameEmpty|| this.inputError.IPsecSecInvalidRange|| this.inputError.IPsecSecInvalidNumber
        || this.inputError.timeOutNameEmpty|| this.inputError.timeOutInvalidRange|| this.inputError.timeOutInvalidNumber
      }
    }
  }

  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      sharedKeyMaxLen:false,
      sharedKeyEmpty:false,
      sharedKeyASCII:false,
      IPsecKBNameEmpty:false,
      IPsecKBInvalidRange:false,
      IPsecKBInvalidNumber:false,
      IPsecSecNameEmpty:false,
      IPsecSecInvalidRange:false,
      IPsecSecInvalidNumber:false,
      timeOutNameEmpty:false,
      timeOutInvalidRange:false,
      timeOutInvalidNumber:false,
    }
  }

  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'configuration':{
      this.saveDisable=true
      switch(subType){
        case 'azureIP':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('azureIP').setValue(value)
          break;
        }
        case 'bgp':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('bgp').setValue(value)
          break;
        }
        case 'customBGP':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('customBGP').setValue(value)
          break;
        }
        case 'policy':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('policy').setValue(value)
          if(value){
            this.currentForm.get('encryption').setValidators(Validators.required)
            this.currentForm.get('integrity').setValidators(Validators.required)
            this.currentForm.get('DHGroup').setValidators(Validators.required)
            this.currentForm.get('IPsecEncryption').setValidators(Validators.required)
            this.currentForm.get('IPsecIntegrity').setValidators(Validators.required)
            this.currentForm.get('PFSGroup').setValidators(Validators.required)
            this.currentForm.get('IPsecKB').setValidators(Validators.required)
            this.currentForm.get('IPsecSec').setValidators(Validators.required)
            // this.resetValidation()
            this.currentForm.get('IPsecKB').setValue(102400000)
            this.currentForm.get('IPsecSec').setValue(27000)
          }else{
            this.currentForm.get('encryption').setValidators(null)
            this.currentForm.get('integrity').setValidators(null)
            this.currentForm.get('DHGroup').setValidators(null)
            this.currentForm.get('IPsecEncryption').setValidators(null)
            this.currentForm.get('IPsecIntegrity').setValidators(null)
            this.currentForm.get('PFSGroup').setValidators(null)
            this.currentForm.get('IPsecKB').setValidators(null)
            this.currentForm.get('IPsecSec').setValidators(null)
            this.inputError.IPsecKBNameEmpty=false,
            this.inputError.IPsecKBInvalidRange=false,
            this.inputError.IPsecKBInvalidNumber=false,
            this.inputError.IPsecSecNameEmpty=false,
            this.inputError.IPsecSecInvalidRange=false,
            this.inputError.IPsecSecInvalidNumber=false
          }
          this.currentForm.get('encryption').updateValueAndValidity()
          this.currentForm.get('integrity').updateValueAndValidity()
          this.currentForm.get('DHGroup').updateValueAndValidity()
          this.currentForm.get('IPsecEncryption').updateValueAndValidity()
          this.currentForm.get('IPsecIntegrity').updateValueAndValidity()
          this.currentForm.get('PFSGroup').updateValueAndValidity()
          this.currentForm.get('IPsecKB').updateValueAndValidity()
          this.currentForm.get('IPsecSec').updateValueAndValidity()
          break;
        }
        case 'trafficSelector':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('trafficSelector').setValue(value)
          break;
        }
        case 'connectionMode':{
          let value = id
          this.currentForm.get('connectionMode').setValue(value)
          break;
        }
      }
    break;}
    
   }
  }
  
  async setDefaultValue(type){
  switch(type){
   case 'configuration':{
    let ingressArray=this.connectionData?.properties?.ingressNatRules?this.connectionData?.properties?.ingressNatRules.map(e=>e.id):[]
    let egressArray=this.connectionData?.properties?.egressNatRules?this.connectionData?.properties?.egressNatRules.map(e=>e.id):[]
    this.currentForm.get('azureIP').setValue(this.connectionData?.properties?.useLocalAzureIpAddress)
    this.currentForm.get('bgp').setValue(this.connectionData?.properties?.enableBgp)
    this.currentForm.get('customBGP').setValue(false)
    this.currentForm.get('policy').setValue(this.connectionData?.properties?.ipsecPolicies.length==0?false:true)
    this.currentForm.get('trafficSelector').setValue(this.connectionData?.properties?.usePolicyBasedTrafficSelectors)
    this.currentForm.get('DPDTimeout').setValue(this.connectionData?.properties?.dpdTimeoutSeconds)
    this.currentForm.get('connectionMode').setValue(this.connectionData?.properties?.connectionMode)
    this.currentForm.get('ingressNAT').setValue(ingressArray)
    this.currentForm.get('egressNAT').setValue(egressArray)
    if(this.connectionData?.properties?.ipsecPolicies.length){
      this.currentForm.get('encryption').setValue(this.connectionData?.properties?.ipsecPolicies[0]?.ikeEncryption)
      this.currentForm.get('integrity').setValue(this.connectionData?.properties?.ipsecPolicies[0]?.ikeIntegrity)
      this.currentForm.get('DHGroup').setValue(this.connectionData?.properties?.ipsecPolicies[0]?.dhGroup)
      this.currentForm.get('IPsecEncryption').setValue(this.connectionData?.properties?.ipsecPolicies[0]?.ipsecEncryption)
      this.currentForm.get('IPsecIntegrity').setValue(this.connectionData?.properties?.ipsecPolicies[0]?.ipsecIntegrity)
      this.currentForm.get('PFSGroup').setValue(this.connectionData?.properties?.ipsecPolicies[0]?.pfsGroup)
      this.currentForm.get('IPsecKB').setValue(this.connectionData?.properties?.ipsecPolicies[0]?.saDataSizeKilobytes)
      this.currentForm.get('IPsecSec').setValue(this.connectionData?.properties?.ipsecPolicies[0]?.saLifeTimeSeconds)
    }
    break;
  }
  }
  }
  
  async saveConnectionData(type){
    switch(type){
      case'sharedKey':{
        let rawValue = this.currentForm.getRawValue()
          let body={
            "value":rawValue.sharedKey
          }
        await this.updateConnectionSharedKey(this.selectedRow,body,'sharedKey')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }

      case'natRules':{
        let body=this.connectionData
        let rawValue = this.currentForm.getRawValue()
        let ingress=rawValue.ingressNAT.map(e=>{
          return {id:e}
        })
        body.properties['ingressNatRules']=ingress.length?ingress:[]
        let egress=rawValue.egressNAT.map(e=>{
          return {id:e}
        })
        body.properties['egressNatRules']=egress.length?egress:[]
        await this.updateConnectionData(this.selectedRow,body,'natRules')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
  
      case'config':{
        let body=this.connectionData
        let rawValue = this.currentForm.getRawValue()
        body.properties['useLocalAzureIpAddress']=rawValue.azureIP
        body.properties['enableBgp']=rawValue.bgp
        if(!rawValue.policy){
          body.properties['ipsecPolicies']=[]
        }else{
          body.properties['ipsecPolicies']=[{
            "saLifeTimeSeconds": rawValue.IPsecSec, 
            "saDataSizeKilobytes": rawValue.IPsecKB, 
            "ipsecEncryption": rawValue.IPsecEncryption, 
            "ipsecIntegrity": rawValue.IPsecIntegrity, 
            "ikeEncryption": rawValue.encryption, 
            "ikeIntegrity": rawValue.integrity, 
            "dhGroup": rawValue.DHGroup, 
            "pfsGroup": rawValue.PFSGroup 
          }]
        }
        body.properties['usePolicyBasedTrafficSelectors']=rawValue.trafficSelector
        body.properties['dpdTimeoutSeconds']=rawValue.DPDTimeout
        body.properties['connectionMode']=rawValue.connectionMode
        let ingress=rawValue.ingressNAT.map(e=>{
          return {id:e}
        })
        body.properties['ingressNatRules']=ingress.length?ingress:[]
        let egress=rawValue.egressNAT.map(e=>{
          return {id:e}
        })
        body.properties['egressNatRules']=egress.length?egress:[]
        await this.updateConnectionData(this.selectedRow,body,'config')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
    }
  }
  
  //validation

  sharedKeyValidation(event){
    let value=event.target.value
    this.saveDisable=true
    if(value==''){
      this.inputError.sharedKeyEmpty=true
    }else{
      this.inputError.sharedKeyEmpty=false
      if(0<value.length&&value.length>128){
        this.inputError.sharedKeyMaxLen=true
      }else{
        this.inputError.sharedKeyMaxLen=false
        if(/^[-~]+$/.test(value[value.length-1])){
          this.inputError.sharedKeyASCII=true;
        }
        else{
          this.inputError.sharedKeyASCII=false;
        }
      }
    }
  }
  validTimeout(event,type){
    let value=event.target.value
    this.saveDisable=true
    this.inputError[type+'NameEmpty']=false
    this.inputError[type+'InvalidNumber']=false
    this.inputError[type+'InvalidRange']=false
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError[type+'InvalidNumber']=true;
      }
      switch(type){
        case 'timeOut':{
          if(value>3600 || value<9){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'IPsecKB':{
          if(value!=0 && (value>2147483647 || value<1024)){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'IPsecSec':{
          if(value>172799 || value<300){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
      }
    }else this.inputError[type+'NameEmpty']=true
  }

  //API's

  getConnectionData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let connName = data[0]
    return this.util.handleRequest('get','a3s_management_getConnectionData',[subsId,rgName,connName],null,null,null,true).then(async res=>{
      this.connectionData= res.body
      console.log('Connection Data',this.connectionData)
    })
  }
  async getVPNGatewayData(data,type?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vpnName = data[5]
    return this.util.handleRequest('get','a3s_management_getVPNGatewayData',[subsId,rgName,vpnName],null,null,null,true).then(res=>{
      this.vpnGatewayData = res.body
      this.ingressList=this.vpnGatewayData?.properties?.natRules.filter(x=>x?.properties?.mode=='IngressSnat')
      this.egressList=this.vpnGatewayData?.properties?.natRules.filter(x=>x?.properties?.mode=='EgressSnat')
      console.log('VPN Data',this.vpnGatewayData)
    })
  }
  updateConnectionData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let connName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateConnectionData',[subsId,rgName,connName],body,null,null,true).then(res=>{
      console.log('Connection Data updated',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  updateConnectionSharedKey(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let connName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateConnectionSharedKey',[subsId,rgName,connName],body,null,null,true).then(res=>{
      console.log('Connection Shared key updated',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  deleteConnectionData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],connectionName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteConnection',[subscriptionId,resourceGroupName,connectionName],null,null,null,true).then(async res=>{
      this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
  }
}
