import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken, Selector } from '@ngxs/store';
import { UtilService } from '../services/util.service';
import { GetBluePrintProjects, SetCurrentProject } from './security-bucket.actions';

export class SecurityBucketStateModel {
   allProject?: any[];
   currentProjectDetail?:any;
}

const defaults = {
  allProject: []
};

const SECURITY_BUCKET_STATE_TOKEN = new StateToken<SecurityBucketStateModel>('securityBucket');

@State<SecurityBucketStateModel>({
  name: 'SecurityBucket',
  defaults
})
@Injectable()
export class SecurityBucketState {
  constructor(private util:UtilService,private title: TitleCasePipe){}

  @Selector()
  static allProjects(state: SecurityBucketStateModel) {
    return state?.allProject;
  }
  
  @Selector()
  static currentProjectDetail(state: SecurityBucketStateModel) {
    return state?.currentProjectDetail;
  }

  @Action(GetBluePrintProjects)
  async getBluePrintProjects(ctx: StateContext<SecurityBucketStateModel>){
    const get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    const createdBy = get_Email.userName;
    const keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;          
          //"5439fd34-19ed-4a74-b94d-149957f106fe"
          break;
        }
      }catch(e){}
    }
    const userProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getAllProjects',[createdBy,userObjectId]);
    const orgProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getOrgProjects',[localStorage.getItem('id')]);
    const list = [...userProject,...orgProject]
    ctx.patchState({
      allProject:list.filter(e=> e.subscriptionId && e.status == 'DEPLOYED')
    });
    return ctx.dispatch(new SetCurrentProject(ctx.getState().allProject[0]))
  }

  @Action(SetCurrentProject)
  async setCurrentProject(ctx: StateContext<SecurityBucketStateModel>,action:any){
    let list={...action.projectDetail};
    list['currentResourceType'] = null;
    // this.currentProject = list.projectName
    if(!list.isManual || (list.isManual && list.isSpecificResource)){
      var resourcesArray = await this.util.handleRequest('get','a3s_architectureBlueprint_getProjectResourceListForDashboards',[list.projectId],null,null,null,true);
      var resourceGroupsArray = []
      resourcesArray.forEach(e=>{
        let rg = JSON.parse(e.resourceData)
        e['resourceGroupName'] = rg.hasOwnProperty('resourceGroup') ? rg.resourceGroup : null
        e['projectId'] = list.projectId
        e['resourceType'] = this.title.transform(this.util.splitWordByCaps(e.resourceType))
        e['subscriptionId'] = list.subscriptionId
        rg.hasOwnProperty('resourceGroup') ? resourceGroupsArray.push({resourceGroupName:rg.resourceGroup,projectId:list.projectId,resourceType:e.resourceType}) : null
      })
      var uniqueRG = this.util.unique(resourceGroupsArray,['resourceGroupName'])
      list['resourceGroups'] = uniqueRG
      list['resources'] = this.util.unique(resourcesArray,['resourceName']);
      // Get Unique Resource Types and the resource counts
      list['resourceTypes'] = this.util.unique(resourcesArray,['resourceType']);
    } else if(list.isManual){
      let resources = await this.util.handleRequest('get','a3s_management_getResourceTypes',[list.subscriptionId],null,null,null,true)
      let types = this.util.getStaticResourceTypes()
      var array = []
      types.forEach(x=>{
        resources.body.value.forEach(e=>{
          if(x.type.toLowerCase() == e.type.toLowerCase()){
            e['resourceName']= e.name
            e['resourceType'] =x.name
            array.push(e)
          }
        })
      })
      let resourceData = array
      let resourcesArray = resourceData
      var resourceGroupsArray = []
      resourcesArray.forEach(e=>{
        e['resourceGroupName'] = e.id.split('/')[4]
        e['projectId'] = list.projectId
        e['subscriptionId'] = list.subscriptionId
        resourceGroupsArray.push({resourceGroupName: e['resourceGroupName'],projectId:list.projectId,resourceType:e.resourceType})
      })
      var uniqueRG = this.util.unique(resourceGroupsArray,['resourceGroupName'])
      list['resourceGroups'] = uniqueRG
      list['resources'] = resourcesArray;
      list['resourceTypes'] =this.util.unique(resourcesArray,['resourceType'])
    }
    
    // this.currentProjectData = list
    // // let wait = await this.util.delay(1000)
    // this.util.setObservable('currentFilteredProject',list)
    // this.subMenuOpen('types_'+index) // open sub menus
    ctx.patchState({
      currentProjectDetail:list
    })
  }
}
