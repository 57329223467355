import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ResourceTypes } from '../@model/resource-types'
import { twoDecimal } from '../pipes/custom.pipe';
@Injectable({
  providedIn: 'root'
})
export class FunctionService {

  constructor(private twoDecimalPipe: twoDecimal) { }

  changeTimeFormat(createdTime){
    var d = new Date();
    createdTime=parseInt(createdTime);
    var currentTime=Math.floor(d.getTime()/1000.0);
    var timeDiff=currentTime-createdTime;
    var output;
    if(timeDiff<60){
      output='just now';
      return output;
    }
    else if(timeDiff<3600){
      let time=Math.floor(timeDiff/60);
      output=`${time} mins ago`;
      if(time==1) output=`${time} min ago`;
      return output;
    }
    else if(timeDiff<86400){
      let time=Math.floor(timeDiff/3600);
      output=`${time} hours ago`;
      if(time==1) output=`${time} hour ago`;
      return output;
    }
    else if(timeDiff<2629743){
      let time=Math.floor(timeDiff/86400);
      output=`${time} days ago`;
      if(time==1) output=`${time} day ago`;
      return output;
    }
    else if(timeDiff<31556926){
      let time=Math.floor(timeDiff/2629743);
      output=`${time} months ago`;
      if(time==1) output=`${time} month ago`;
      return output;
    }
    else{
      let time=Math.floor(timeDiff/31556926);
      output=`${time} years ago`;
      if(time==1) output=`${time} year ago`;
      return output;
    }
  }

  refreshedTimeFormat(date){
    var d = new Date()
    var seconds = Math.floor((d.getTime() - date.getTime()) / 1000);

    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years agon";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " mins ago";
    }
    return Math.floor(seconds) + " sec ago";
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var AMPM = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    seconds = seconds < 10 ?'0' +seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds + ' '+ AMPM;
    return strTime;
  }

  doSortBasedonKey(data, key): any{
    return  data.sort(function compare(a, b) {
       if(a[key] < b[key]) return -1;
        if( a[key] > b[key]) return 1;
      });
  }

  getTimeRangeQuery(filter){
     switch(filter.type){
       case 'last30mins' : return 'where timestamp > ago(30m)'
       case 'lasthr' : return 'where timestamp > ago(1h)'
       case 'last4hr' : return 'where timestamp > ago(4h)'
       case 'last12hr' : return 'where timestamp > ago(12h)'
       case 'last24hr' : return 'where timestamp > ago(24h)'
       case 'last48hr' : return 'where timestamp > ago(48h)'
       case 'last3days' : return 'where timestamp > ago(3d)'
       case 'last7days' : return 'where timestamp > ago(7d)'
       case 'custom' : return `where timestamp > todatetime(${`'${new Date(filter.from).toISOString()}'`}) and timestamp < todatetime(${`'${new Date(filter.to).toISOString()}'`})`
     }
  }

  getInsightsTimeRangeQuery(filter){
    switch(filter.type){
      case 'last30mins' : return 'where TimeGenerated >= ago(30m)'
      case 'lasthr' : return 'where TimeGenerated >= ago(1h)'
      case 'last4hr' : return 'where TimeGenerated >= ago(4h)'
      case 'last12hr' : return 'where TimeGenerated >= ago(12h)'
      case 'last24hr' : return 'where TimeGenerated >= ago(24h)'
      case 'last48hr' : return 'where TimeGenerated >= ago(48h)'
      case 'last3days' : return 'where TimeGenerated >= ago(3d)'
      case 'last7days' : return 'where TimeGenerated >= ago(7d)'
      case 'custom' : return `where TimeGenerated >= todatetime(${`'${new Date(filter.from).toISOString()}'`}) and TimeGenerated <= todatetime(${`'${new Date(filter.to).toISOString()}'`})`
    }
 }

  getIdentitySecurityTimeRangeQuery(filter){
    switch(filter.type){
      case 'last30mins' : return `let starttime = todatetime('${new Date(new Date().setMinutes(new Date().getMinutes() - 30)).toISOString()}');let endtime = todatetime('${new Date().toISOString()}');`
      case 'lasthr' : return `let starttime = todatetime('${new Date(new Date().setHours(new Date().getHours() - 1)).toISOString()}');let endtime = todatetime('${new Date().toISOString()}');`
      case 'last4hr' : return `let starttime = todatetime('${new Date(new Date().setHours(new Date().getHours() - 4)).toISOString()}');let endtime = todatetime('${new Date().toISOString()}');`
      case 'last12hr' : return `let starttime = todatetime('${new Date(new Date().setHours(new Date().getHours() - 12)).toISOString()}');let endtime = todatetime('${new Date().toISOString()}');`
      case 'last24hr' : return `let starttime = todatetime('${new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()}');let endtime = todatetime('${new Date().toISOString()}');`
      case 'last48hr' : return `let starttime = todatetime('${new Date(new Date().setDate(new Date().getDate() - 2)).toISOString()}');let endtime = todatetime('${new Date().toISOString()}');`
      case 'last3days' : return `let starttime = todatetime('${new Date(new Date().setDate(new Date().getDate() - 3)).toISOString()}');let endtime = todatetime('${new Date().toISOString()}');`
      case 'last7days' : return `let starttime = todatetime('${new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()}');let endtime = todatetime('${new Date().toISOString()}');`
      case 'custom' : return `let starttime = todatetime('${new Date(filter.from).toISOString()}');let endtime = todatetime('${new Date(filter.to).toISOString()}');`
    }
 }
 
 getNetworkMonitoringTimeRangeQuery(filter){
    switch(filter.type){
      case 'last30mins' : return `let startTime = todatetime('${new Date(new Date().setMinutes(new Date().getMinutes() - 30)).toISOString()}');let endTime = todatetime('${new Date().toISOString()}');`
      case 'lasthr' : return `let startTime = todatetime('${new Date(new Date().setHours(new Date().getHours() - 1)).toISOString()}');let endTime = todatetime('${new Date().toISOString()}');`
      case 'last4hr' : return `let startTime = todatetime('${new Date(new Date().setHours(new Date().getHours() - 4)).toISOString()}');let endTime = todatetime('${new Date().toISOString()}');`
      case 'last12hr' : return `let startTime = todatetime('${new Date(new Date().setHours(new Date().getHours() - 12)).toISOString()}');let endTime = todatetime('${new Date().toISOString()}');`
      case 'last24hr' : return `let startTime = todatetime('${new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()}');let endTime = todatetime('${new Date().toISOString()}');`
      case 'last48hr' : return `let startTime = todatetime('${new Date(new Date().setDate(new Date().getDate() - 2)).toISOString()}');let endTime = todatetime('${new Date().toISOString()}');`
      case 'last3days' : return `let startTime = todatetime('${new Date(new Date().setDate(new Date().getDate() - 3)).toISOString()}');let endTime = todatetime('${new Date().toISOString()}');`
      case 'last7days' : return `let startTime = todatetime('${new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()}');let endTime = todatetime('${new Date().toISOString()}');`
      case 'custom' : return `let startTime = todatetime('${new Date(filter.from).toISOString()}');let endTime = todatetime('${new Date(filter.to).toISOString()}');`
    }
 }

 getCustomQueryTimeRangeParm(filter){
  switch(filter.type){
    case 'last30mins' : return `${new Date(new Date().setMinutes(new Date().getMinutes() - 30)).toISOString()}/${new Date().toISOString()}`
    case 'lasthr' : return `${new Date(new Date().setHours(new Date().getHours() - 1)).toISOString()}/${new Date().toISOString()}`
    case 'last4hr' : return `${new Date(new Date().setHours(new Date().getHours() - 4)).toISOString()}/${new Date().toISOString()}`
    case 'last12hr' : return `${new Date(new Date().setHours(new Date().getHours() - 12)).toISOString()}/${new Date().toISOString()}`
    case 'last24hr' : return `${new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()}/${new Date().toISOString()}`
    case 'last48hr' : return `${new Date(new Date().setDate(new Date().getDate() - 2)).toISOString()}/${new Date().toISOString()}`
    case 'last3days' : return `${new Date(new Date().setDate(new Date().getDate() - 3)).toISOString()}/${new Date().toISOString()}`
    case 'last7days' : return `${new Date(new Date().setDate(new Date().getDate() - 4)).toISOString()}/${new Date().toISOString()}`
    case 'custom' : return `${new Date(filter.from).toISOString()}/${new Date(filter.to).toISOString()}`
  }
}

 getTimeFilterText(filter){
  switch(filter.type){
    case 'last30mins' : return 'Last 30 mins'
    case 'lasthr' : return 'Last hour'
    case 'last4hr' : return 'Last 4 hours'
    case 'last12hr' : return 'Last 12 hours'
    case 'last24hr' : return 'Last 24 hours'
    case 'last48hr' : return 'Last 48 hours'
    case 'last3days' : return 'Last 3 days'
    case 'last7days' : return 'Last 7 days'
    case 'last30days' : return 'Last 30 days'
    case 'custom' : return `Custom`
  }
 }

 getUTCText(filter){
  return filter.timeZone ==='local'?'(UTC)'+moment().format('Z'):'(UTC)';
}

 getCommonIsoDate(filter){
  var currentDate = new Date();
  switch(filter.type){
    case 'last30mins' : return {from:new Date(currentDate.getTime() - 30*60000).toISOString(),to:new Date().toISOString()}
    case 'lasthr' : return {from:new Date(currentDate.getTime() - 60*60000).toISOString(),to:new Date().toISOString()}
    case 'last4hr' : return {from:new Date(currentDate.getTime() - 4*60*60000).toISOString(),to:new Date().toISOString()}
    case 'last12hr' : return {from:new Date(currentDate.getTime() - 12*60*60000).toISOString(),to:new Date().toISOString()}
    case 'last24hr' : return {from:new Date(currentDate.getTime() - 24*60*60000).toISOString(),to:new Date().toISOString()}
    case 'last48hr' : return {from:new Date(currentDate.getTime() - 48*60*60000).toISOString(),to:new Date().toISOString()}
    case 'last3days' : return {from:new Date(currentDate.getTime() - 72*60*60000).toISOString(),to:new Date().toISOString()}
    case 'last7days' : return {from:new Date(currentDate.getTime() - 168*60*60000).toISOString(),to:new Date().toISOString()}
    case 'last30days' : return {from:new Date(currentDate.getTime() - 720*60*60000).toISOString(),to:new Date().toISOString()}
    case 'last60days' : return {from:new Date(currentDate.getTime() - 1440*60*60000).toISOString(),to:new Date().toISOString()}
    case 'custom' : return {from:new Date(filter.from).toISOString(),to:new Date(filter.to).toISOString()}
  }
 }

 getLastSeenTimeRangeQuery(filter){
  switch(filter.type){
    case 'last30mins' : return 'where LastSeen > ago(30m)'
    case 'lasthr' : return 'where LastSeen > ago(1h)'
    case 'last4hr' : return 'where LastSeen > ago(4h)'
    case 'last12hr' : return 'where LastSeen > ago(12h)'
    case 'last24hr' : return 'where LastSeen > ago(24h)'
    case 'last48hr' : return 'where LastSeen > ago(48h)'
    case 'last3days' : return 'where LastSeen > ago(3d)'
    case 'last7days' : return 'where LastSeen > ago(7d)'
    case 'custom' : return `where LastSeen > todatetime(${`'${new Date(filter.from).toISOString()}'`}) and LastSeen < todatetime(${`'${new Date(filter.to).toISOString()}'`})`
  }
}

  generatePassword(passwordLength) {
      var numberChars = "0123456789";
      var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lowerChars = "abcdefghijklmnopqrstuvwxyz";
      var allChars = numberChars + upperChars + lowerChars;
      var randPasswordArray = Array(passwordLength);
      randPasswordArray[0] = numberChars;
      randPasswordArray[1] = upperChars;
      randPasswordArray[2] = lowerChars;
      randPasswordArray = randPasswordArray.fill(allChars, 3);
      return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
  }
    
  shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
  }

  splitWordByCapsExceptAbb(data){
    return data.split(/([A-Z][a-z]+)/).join(" ")
  }
  splitWordByCaps(data){
    return data.split(/(?=[A-Z])/).join(" ")
  }

  delay(delayInms) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(2);
      }, delayInms);
    });
  }

  doOperation(operator,a,b){
    switch(operator){
      case '<': return a < b;
      case '>': return a > b;
      case '=': return a == b;
      case '!=': return a !== b;
      case '<=': return a < b || a == b;
    }
  }
  
  getAnalyticsUseCaseShortName(type){
    switch (type) {
      case 'Demand Forecasting': return 'DF'
      case 'Product Seasonality Classifier':return 'PC'
      case 'Sales Prediction':return 'SP'
      case 'Revenue Prediction':return 'RP'
      case 'Knowledge Mining':return 'KM'
      case 'Modern Finance':return 'MF'
      case 'Form Recognition':return 'FR'
      default: return 'DF'
    }
  }
  getRegionName(val){
    switch(val){
      case 'US East' :return 'East US'
      case 'US Central' :return 'Central US'
      case 'US West' :return 'West US'
      case 'US East 2' :return 'East US 2'
      case 'US Central 2' :return 'Central US 2'
      case 'US West 2' :return 'West US 2'
      case 'US East 3' :return 'East US 3'
      case 'US Central 3' :return 'Central US 3'
      case 'US West 3' :return 'West US 3'
      case 'AU Southeast' :return 'Australia Southeast'
      case 'Br South' :return 'Brazil South'
      case 'CA East' :return 'Canada East'
      case 'DE West Central' :return 'Germany West Central'
      case 'IN Central' :return 'Central India'
      case 'IN West' :return 'West India'
      case 'JA West' :return 'Japan West'
      case 'KR Central' :return 'Korea Central'
      case 'Uk South' :return 'UK South'
      case 'US South Central ' :return 'South Central US'
      case 'US West Central' :return 'West Central US'
      case 'ZA North' :return 'South Africa North'
      case 'IN South' :return 'South India'
      case 'IN West' :return 'West India'
      default:return val
    }
  }
  getResourceTypeName(val){
    let mergename = val.replace(/\s/g, '').toLowerCase()
    switch(mergename){
      case 'virtualprivatenetworkgateway' : return 'Virtual Network Gateway'
      case 'virtualmachinescaleset' : return 'VM Scale Set'
      case 'publicipaddresses' : return 'Public IP Address'
      case 'networkinterface' : return 'Network Interface'
      case 'virtualnetworks' : return 'Virtual Network'
      case 'networksecuritygroups' : return 'Network Security Group'
      case 'localnetworkgateways' : return 'Local Network Gateway'
      case 'subnets' : return 'Subnet'
      case 'publicipaddress' : return 'Public IP Address'
      case 'sqlvirtualmachine' : return 'SQL Virtual Machine'
      case 'sqldatabase' : return 'SQL Database'
      case 'sqlserver' : return 'SQL Server'
      case 'dnszone' : return 'DNS Zone'
      case 'cdnprofile' : return 'CDN Profile'
      case 'dedicatedsqlpool' : return 'Dedicated SQL Pool'
      case 'virtualmachines' : return 'Virtual Machines'
      case 'disks' : return 'Disk'
      case 'storageaccount' :  return 'Storage Accounts'
      case 'storageaccounts' :  return 'Storage Accounts'
      default: {  val=this.splitWordByCaps(val)
      // return this.titleCase.transform(val)
      }
    }
  }
 
  getStaticResourceTypes(){
    return ResourceTypes.types
  }

  getProperResourceTypeName(type){
    let resourceType = type.replace(/\s/g, '').toLowerCase()
    switch(resourceType){
      case 'linuxvirtualmachine':
      case 'virtualmachine':
      case 'virtualmachines': return 'Virtual Machines'
      case 'loadbalancer':
      case 'loadbalancers': return 'Load Balancers'
      case 'sqldatabase':
      case 'sqldatabases': return 'SQL Database'
      default : return type
    }
  }

  getTimeGranularity(filterDate) {
    if(filterDate.timeGranularity === 'automatic') {
      const diffMinutes=moment.duration(moment(filterDate.to).diff(filterDate.from)).as('minutes');
      let step;
      if(diffMinutes<1||diffMinutes==1) {
        step='15sec';
      } else if(diffMinutes<5||diffMinutes==5) {
        step='1min';
      } else if(diffMinutes<30||diffMinutes==30) {
        step='5min';
      } else if(diffMinutes<60||diffMinutes==60) {
        step='15min';
      } else if(diffMinutes<240||diffMinutes==240) {
        step='1hr';
      } else if(diffMinutes<720||diffMinutes==720) {
        step='3hr';
      } else if(diffMinutes<1440||diffMinutes==1440) {
        step='6hr';
      } else if(diffMinutes<2880||diffMinutes==2880) {
        step='12hr';
      } 
      if(!step) {
        const diffDays=moment.duration(moment(filterDate.to).diff(filterDate.from)).as('days');
        if(diffDays<7||diffDays==7) {
          step='1d';
        } else if(diffDays<15||diffDays==15) {
          step='3d';
        } else if(diffDays<30||diffDays==30) {
          step='7d';
        } else if(diffDays<60||diffDays==60) {
          step='15d';
        } else if(diffDays>60) {
          step='30d';
        }
      }
      return step
    } else {
      return filterDate.timeGranularity;
    }
    
  }


  /************************************************ Echarts dependencies *******************************************************/
  getPieChartTooltip(data){
    return {
      trigger: 'item',
      formatter: function (params) {
        var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
        var xx = `${colorSpan(params.color)}  ${params.name} <span class="float-right ml-2">${params.value}${data && data.unit ? data.unit : ''}</span>`
        return xx;
      },    
      padding: [3,8],
      backgroundColor : "#000000",
      borderColor : "#000000",
      textStyle: {
        color:"#FFFFFF",
        textBorderColor: "#FFFFFF",
        fontSize: '12',
      },
    }
  }

  getSingleBarChartTooltip(){
    var self = this
    return {
      formatter: function (params) {
        var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
        var xx = `${params.name} <br />${colorSpan(params.color)}  ${params.seriesName}<span class="float-right ml-2">${params.value}</span>`
        return xx;
      },       
      padding: [8,9],
      backgroundColor : "#000000",
      borderColor : "#000000",
      textStyle: {
        color:"#FFFFFF",
        textBorderColor: "#FFFFFF",
        fontSize: '12',
      },
    }
  }

  getHorizontalBarChartTooltip(data?:any){
    var self = this
    return {
      formatter: function (params) {
        var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
        var xx = `${params.name} <br />${colorSpan(params.color)}  ${self.getHealthNameByColor(params.color)}<span class="float-right ml-2">${self.twoDecimalPipe.transform(params.value)}%</span>`
        return xx;
      },       
      padding: [8,9],
      backgroundColor : "#000000",
      borderColor : "#000000",
      textStyle: {
        color:"#FFFFFF",
        textBorderColor: "#FFFFFF",
        fontSize: '12',
      },
    }
  }

  getMultipleVerticalBarChartTooltip(data?:any){
    var self = this
    return {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
      formatter: function (params) {
        var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
        let name = `${params[0].name} <br />`
        params.forEach(item => {
          var xx = `${colorSpan(item.color)} ${self.getHealthName(item.seriesName)} <span class="float-right ml-2">${item.value ? item.value : 0}%</span><br />`
          name += xx;
        })
        return name;
      },  
      padding: [8,8],
      backgroundColor : "#000000",
      borderColor : "#000000",
      textStyle: {
        color:"#FFFFFF",
        textBorderColor: "#FFFFFF",
        fontSize: '12',
      },
    }
  }

  getLineChartTooltip(){
    var self = this
    return {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
      formatter: function (params) {
        var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
        let name = `${params[0].name} <br />`
        params.forEach(item => {
          var xx = `${colorSpan(item.color)} ${item.seriesName}  <span class="float-right ml-2">${self.twoDecimalPipe.transform(item.value)}</span><br />`
          name += xx;
        })
        return name;
      },  
      padding: [8,8],
      backgroundColor : "#000000",
      borderColor : "#000000",
      textStyle: {
        color:"#FFFFFF",
        textBorderColor: "#FFFFFF",
        fontSize: '12',
      },
    }
  }

  getChartTooltipStyles(){
    return {
      padding: [8,8],
      backgroundColor : "#000000",
      borderColor : "#000000",
      textStyle: {
        color:"#FFFFFF",
        textBorderColor: "#FFFFFF",
        fontSize: '12',
      },
    }
  }

  getHealthName(value){
    if(value.includes('Unhealthy')) return 'Unhealthy'
    else if(value.includes('Healthy')) return 'Healthy'
  }

  getHealthNameByColor(value){
    if(value == "#06C493") return 'Healthy'
    else return 'Unhealthy'
  }

  getDynamicXaxisFormat(xAxisData,value){
    let length = xAxisData.length
    if(length > 3){
      return (value.length > 10 ? (value.slice(0,10)+"...") : value )
    }
    else if(length <= 3){
      return (value.length > 20 ? (value.slice(0,20)+"...") : value )
    }
  }

}
