
<div class="close-page-body">
    <div class="logo">
        <img src="../../../assets/azure-abot-logo.png">
        <div class="header-logo">
          <h2>AI-POWERED AZURE AUTOMATION AS </h2>
          <h2>A SERVICE</h2>
        </div>
    </div>
    <div class="details">
        <img src="../../../assets/icons/green-tick.svg">
        <span class="text-16-26 flex-custom">Admin consent granted successfully.</span>
        <span class="text-14-22 flex-custom mb-3">You may continue your registration now</span>
        <button class="btn btn-outline" (click)="closeWindow()">Close this window</button>
    </div>
</div>
  