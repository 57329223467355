import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-firewall-actions',
  templateUrl: './firewall-actions.component.html',
  styleUrls: ['./firewall-actions.component.scss']
})
export class FirewallActionsComponent implements OnInit {
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("firewallDetailsTemplate") firewallDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    nameEmpty:false,
    nameMaxLen:false,
    nameExist:false,
    nameValid:false,
    nameLastValid:false,
  }
  selectedType: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;
  azureFirewallData: any;
  publicIPAllList: any;
  publicIPList: any;
  isEdit: boolean;
  existedIPName: any;
  firewallPolicyList: any;
  policyCount: any;
  publicIPConfigList: any;


  constructor(public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }

  
  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteFirewallData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteFirewallData')
      }
      case 'removePublicIP': {
        let index = this.azureFirewallData?.properties?.ipConfigurations.findIndex(e=> e.name == data.name)
        this.azureFirewallData?.properties?.ipConfigurations.splice(index,1)
        this.updateFirewallData(this.selectedRow,this.azureFirewallData,'removePublicIP') 
      }
    }
  }
    // Trigger action of the Connection
  
  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){

      case "changeFirewallPolicy" : {
        this.closeModal()
        this.currentForm = this.util.getForm('firewallChangeFirewallPolicy')
        await this.getFirewallData(this.selectedRow)
        await this.getFirewallPolicyList(this.selectedRow)
        await this.setDefaultValue('changeFirewallPolicy')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "ipConfig" : {
        this.closeModal()
        await this.getFirewallData(this.selectedRow)
        await this.getPublicIPList()
        // this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
    }
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteFirewallData' :return this.deleteFirewallData(this.selectedRow)
    }
  }
  
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedRow=row;
    this.selectedType=type;
    // type=='appServicePopup'?this.getAppServicePlanData(row):false 
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteFirewallData':return 'Delete Firewall'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteFirewallData':return `Are you sure you want to delete “${this.selectedRow[0]}”?` 
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteFirewallData':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      case 'connected': return 'st-success';
      case 'not connected': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  getSuccesText(type){
    switch(type){
      case'addPublicIP':return `“${this.currentForm.get('ipName').value}” added successfully!`
      case'editPublicIP':return `“${this.currentForm.get('ipName').value}” updated successfully!`
      case'changeFirewallPolicy':return 'Firewall policy updated successfully!'
      default: return 'Changes saved successfully!'
    }
  }
  
  getFailureText(type){
    switch(type){
      case'addPublicIP':return `“${this.currentForm.get('ipName').value}” failed to be added.`
      case'editPublicIP':return `“${this.currentForm.get('ipName').value}” failed to be updated.`
      case'changeFirewallPolicy':return 'Firewall policy failed to be updated.'
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'addPublicIP':return `Adding “${this.currentForm.get('ipName').value}”...`
      case'editPublicIP':return `Updating “${this.currentForm.get('ipName').value}”...`
      case'changeFirewallPolicy':return 'Updating Firewall policy...'
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'firewallData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }
  
  invalidForm(type){
    switch(type){
      case'addPublicIP':{
        return this.inputError?.nameEmpty|| this.inputError?.nameMaxLen ||this.inputError?.nameExist|| this.inputError?.nameValid ||this.inputError?.nameLastValid
      }
    }
  }

  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      nameEmpty:false,
      nameMaxLen:false,
      nameExist:false,
      nameValid:false,
      nameLastValid:false,
    }
  }

  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'configuration':{
      this.saveDisable=true

    break;} 
   }
  }
  get policyNameList() {
    return this.currentForm.get('policyName') as FormArray;
  }
  
  async setDefaultValue(type){
  switch(type){
   case 'changeFirewallPolicy':{
    let policyName=this.azureFirewallData?.properties?.firewallPolicy?.id.split('/')[8]
    this.policyCount=[]
    this.policyNameList.controls.splice(0,1)
    this.firewallPolicyList.forEach((e,i)=>{
      if(e.name == policyName){
           e['checked']=true
           this.policyNameList.push(this.util.getForm('changeFirewallPolicy',{
            name:true,
          }))
           this.policyCount.push(e)
      }else{
        e['checked']=false
        this.policyNameList.push(this.util.getForm('changeFirewallPolicy',{
          name:false,
        }))
      }
    })
    break;
  }
  }
  }
  async addPublicIP(row){
      this.currentForm = this.util.getForm('firewallIPConfig')
      if(row){
        this.isEdit=true
        this.saveDisable=true 
        this.currentForm.get('ipName').setValue(row?.name)   
        this.currentForm.get('ipAddress').setValue(row?.properties?.publicIPAddress?.id) 
      }else{
        this.isEdit=false
        this.resetForm()
      }
      await this.getPublicIPList()
      this.showSubSidesheet = true
  }
  getIPAddress(id){
   let address =this.publicIPAllList.filter(e=>e.id==id)[0].properties?.ipAddress
   return address
  }
  
  enableAction(body,event,index,){
    if (event.target.checked) {
      body.checked = true
    }
    else body.checked = false
    this.saveDisable=true
    this.policyCount=[]
    this.firewallPolicyList.forEach(e=>{
      if(e.checked){
        return this.policyCount.push(e)
      }
    })
    console.log('Check',this.azureFirewallData)
  }
  async saveFirewallData(type){
    switch(type){
      case'addPublicIP':{
        let rawValue = this.currentForm.getRawValue()
        if(this.isEdit){
          let index = this.azureFirewallData?.properties?.ipConfigurations.findIndex(e=> e.name == rawValue.ipName)
          this.azureFirewallData.properties.ipConfigurations[index].properties.publicIPAddress.id= rawValue.ipAddress 
          await this.updateFirewallData(this.selectedRow,this.azureFirewallData,'editPublicIP') 
        }else{
          let body=this.azureFirewallData?.properties?.ipConfigurations
          body.push({
            "name": rawValue.ipName, 
            "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/azureFirewalls/${this.selectedRow[0]}/azureFirewallIpConfigurations/${rawValue.ipName}`, 
            "properties": { 
                "privateIPAllocationMethod": "Dynamic", 
                "publicIPAddress": { 
                    "id": rawValue.ipAddress 
                },
                // "subnet" :{
                //   id: "/subscriptions/798c5710-50e6-4305-802e-e7a3630f7e2e/resourceGroups/Test-Firewall/providers/Microsoft.Network/virtualNetworks/Vnet1/subnets/AzureFirewallSubnet"
                // }
            } 
          })
        await this.updateFirewallData(this.selectedRow,this.azureFirewallData,'addPublicIP')
        }
        this.showSubSidesheet=false
        await this.getPublicIPList()
        this.saveDisable=false 
        break;
      }

      case'changeFirewallPolicy':{
        let body=this.azureFirewallData
        let rawValue = this.currentForm.getRawValue()
        body.properties['firewallPolicy']={id:this.policyCount[0].id}
        await this.updateFirewallData(this.selectedRow,body,'changeFirewallPolicy')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
    }
  }
  
  //validation
  validIPName(event,limitNum,subType?:any){
    let value =event.target.value;
    this.existedIPName=value
    this.inputError['nameExist'] = false;
   if(value==''){
    this.inputError['nameEmpty'] = true;
   }else{ this.inputError['nameEmpty'] = false;}
      if (value.length > limitNum) {
        this.inputError['nameMaxLen'] = true;
        return;
      }
      else {
        this.inputError['nameMaxLen'] = false;
      }
    if(value.length==1){
      if(/^[A-Za-z0-9]*$/.test(value)){
        this.inputError['nameValid']=false;
      }
      else{
        this.inputError['nameValid']=true;
      }
    }
    if(/^[A-Za-z0-9_]+$/.test(value[value.length-1]) && !this.inputError['nameValid']){
      this.inputError['nameLastValid']=false;
    }
    else{
      this.inputError['nameLastValid']=true;
    }
    switch(subType){
      case 'IPName':{
        this.publicIPList.forEach(e=>{
          if(e.name==value){
            this.inputError['nameExist'] = true;
            return;
          }
        })
      }
    }
  }

  //API's

  getFirewallData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let firewallName = data[0]
    return this.util.handleRequest('get','a3s_management_getFirewallData',[subsId,rgName,firewallName],null,null,null,true).then(async res=>{
      this.azureFirewallData= res.body
      console.log('Azure Firewall Data',this.azureFirewallData)
    })
  }
  async getPublicIPList(type?:any){
    let subsId = this.selectedRow[2]
    let temp=[]
    return  this.util.handleRequest('get','a3s_management_getPublicIPlistData',[subsId],null,null,null,true).then(res=>{
      this.publicIPAllList = res.body.value
       this.publicIPList=this.publicIPAllList.filter(e=>e.location == this.selectedRow[3])
       if(this.azureFirewallData?.properties?.managementIpConfiguration){
        this.publicIPConfigList=[]
        temp.push({name:'None',id:'None',type:'Can be associated with this firewall'})
        this.publicIPConfigList=this.azureFirewallData?.properties?.ipConfigurations
      }
        this.publicIPList.forEach(e=>{
          if( !e.properties.ipConfiguration && e.properties.publicIPAddressVersion=='IPv4' ){
            return temp.push({
              name:e.name+` (${e.properties.ipAddress})`,
              id:e.id,
              type: 'Can be associated with this firewall'
              })
          }
          })
          if(!this.azureFirewallData?.properties?.managementIpConfiguration){
            temp.push({name:'None (must have forced tunneling enabled)',id:'None',type:'Cannot be associated with this firewall'})
            this.publicIPConfigList=this.azureFirewallData?.properties?.ipConfigurations
          } 
          this.publicIPList.forEach(e=>{
            if(this.isEdit &&  this.currentForm.get('ipAddress').value==e.id){
              return temp.push({
                name:e.name+` (${e.properties.ipAddress})`,
                id:e.id,
                type: 'Can be associated with this firewall'
                })
            }
            else if( e.properties.ipConfiguration || e.properties.publicIPAddressVersion!='IPv4' ){
              return temp.push({
                name:e.name+` (${e.properties.ipAddress})`,
                id:e.id,
                type: 'Cannot be associated with this firewall'
                })
            }
            })     
          this.publicIPList=temp
      console.log('public IP List Data',this.publicIPList)
    })
  }
  getFirewallPolicyList(data,type?:any){
    let subsId = data[2]
    return this.util.handleRequest('get','a3s_management_getFirewallPolicyList',[subsId],null,null,null,true).then(async res=>{
      this.firewallPolicyList= res.body.value
      console.log('Firewall Policy List',this.firewallPolicyList)
    })
  }
  updateFirewallData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let firewallName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateFirewallData',[subsId,rgName,firewallName],body,null,null,true).then(res=>{
      console.log('Azure Firewall updated',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
      if(type=='addPublicIP'){
        let index = this.publicIPConfigList.findIndex(e=> e.name== this.currentForm.get('ipName').value)
        this.publicIPConfigList.splice(index,1)
      }
    })
  }

  deleteFirewallData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],firewallName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteFirewallData',[subscriptionId,resourceGroupName,firewallName],null,null,null,true).then(async res=>{
      this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
  }
}
