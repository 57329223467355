<router-outlet></router-outlet>
<div class="chatbox">
  <div class="message-box" *ngIf="onhover" >
    <p>Please double click to open chatbox.</p>
  </div>
<!-- <i cdkDrag cdkDragBoundary="body" class="fas bot" *ngIf="onHome && !this.flow.showIframe" (dblclick)="this.flow.ShowChatbot();onhover = false"
(mouseover)="onhover = true" (mouseout)="onhover = false"><img src="assets/Chatbot.svg" alt=""></i> -->

</div>
<div class="chatbot" *ngIf="this.flow.showIframe">
    <div class="header">
      <div class="logo">
        <img src="assets/icons/dynapt-blue-back.svg" alt="">
        <p>A3S Support</p>
      </div>      
      
      <div class="icons">
        <i class="fa fa-times" (click)="this.flow.showIframe=false"></i>
      </div>
    </div>
    <div class="chat-bot-area">
      <div class="cssload-wrench" *ngIf="isLoading"></div>
      <iframe [src]='this.flow.iframeSRC' (load)="isLoading=false" [class.d-none]="isLoading"></iframe>
    </div>

</div>

<ngx-ui-loader text='Please Wait...'></ngx-ui-loader>

<!-- <authentication-managed-identity resource="https://graph.microsoft.com"> </authentication-managed-identity> -->