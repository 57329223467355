import { Pipe, PipeTransform,ChangeDetectorRef } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { FunctionService } from '../services/function.service';

@Pipe({
  name: 'percent'
})
export class percent implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      if (value.toString().indexOf('.') > -1) { value = value.toFixed(2); }
      return value + '%';
    }
    else {
      return '0 %'
    }
  }
}

@Pipe({
  name: 'daysToMonths'
})
export class daysToMonths implements PipeTransform {
  constructor(  ) { }
  transform(value: any, args?: any): any {
    try {
      if (value && value > 0) {
        return (value/30).toFixed(0) + ' Months';
      } else {
        return '--';
      }
    }
    catch{
      console.log(value);
    }
  }
}

@Pipe({
  name: 'bytesToSize'
})
export class bytesToSize implements PipeTransform {
  constructor(  ) { }
  transform(value: any, args?: any): any {
    try {
      let decimals = 2;
      if (value === 0) return '0 Byte';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(value) / Math.log(k));
      return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    catch{
      console.log(value);
    }
  }
}

@Pipe({
  name: 'bytesToMB'
})
export class bytesToMB implements PipeTransform {
  constructor(  ) { }
  transform(value: any, args?: any): any {
    try {
      let decimals = 2;
      if (value === 0) return '0 Byte';
      const k = 1024 *1024;
      const dm = decimals < 0 ? 0 : decimals;
      const i = Math.floor(Math.log(value) / Math.log(k));
      return parseFloat((value / Math.pow(k, i)).toFixed(dm));
    }
    catch{
      console.log(value);
    }
  }
}

@Pipe({
  name: 'bytesToGB'
})
export class bytesToGB implements PipeTransform {
  constructor(  ) { }
  transform(value: any, args?: any): any {
    try {
      let decimals = 2;
      if (value === 0) return '0 Byte';
      const k = 1024*1024*1024;
      const dm = decimals < 0 ? 0 : decimals;
      const i = Math.floor(Math.log(value) / Math.log(k));
      return parseFloat((value / Math.pow(k, i)).toFixed(dm));
    }
    catch{
      console.log(value);
    }
  }
}

@Pipe({
  name: 'displayTxt'
})
export class displayTxt implements PipeTransform {
  transform(value: any, args?: any): any {
    try {
      if (value == undefined || value == null || value.length == 0 ||value === 0) {
        value = "--";
      }
      return value;
    }
    catch{
    }
  }
}

@Pipe({
  name:'firstLetterCaps'
})
export class FirstLetterCaps implements PipeTransform{
  constructor(){}
  transform(word:string){
    if (!word) return word;
    let firstL= word[0].toUpperCase()
    let abb=word.substr(1).toLowerCase()
    let final=firstL +abb
    final=final.replace('Ip','IP');final=final.replace('Vpn','VPN');final=final.replace('Bs','BS');final=final.replace('Sql','SQL')
    final=final.replace('ip','IP'); final=final.replace('vpn','VPN');final=final.replace('hdd','HDD');final=final.replace('gb','GB')
    return final ;
  }
}

@Pipe({
  name: 'inDays'
})
export class inDays implements PipeTransform {
  transform(value: any, args?: any): any {
    try {
      if (!value) {
        return '';
      } else {
        return value.toString().split('.')[0] + " day" + (value.toString().split('.')[0] == '1' ? '' : 's');
      }
    }
    catch {

    }
  }
}

@Pipe({
  name: 'dateFormat'
})
export class DateFormat implements PipeTransform {
  transform(value: any, args?: any): any {
    try {
      value = new Date(value.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
      var datePipe = new DatePipe("en-US");
      value = datePipe.transform(value, 'dd-MMM-yyyy');
      return value;
    }
    catch {}
  }
}

@Pipe({
  name: 'indianTimeFormat'
})
export class IndianTimeFormat implements PipeTransform {
  transform(value: any, args?: any): any {
    try {
      var date = new Date(value);
      var hours = date.getHours();
      var minutes : any = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }
    catch {}
  }
}

@Pipe({
  name: 'a3sDateTimeFormat'
})
export class A3sDateTimeFormat implements PipeTransform {
  constructor(private func:FunctionService){}
  transform(value: any, args?: any): any {
    try {
      var d = new Date(value);
      var timeStamp=`${d.getMonth() +1}/${d.getDate()}/${d.getFullYear()}, ${this.func.formatAMPM(d)}`;;
      return timeStamp;
    }
    catch {}
  }
}

@Pipe({
  name: 'indianNumber'
})
export class indianNumber implements PipeTransform {
  constructor(private changeDetectorRef:ChangeDetectorRef){}
  transform(value: any, args?: any): any {
    // this.changeDetectorRef.detectChanges();
    if(value || parseInt(value) === 0){
      if(value.toString().indexOf(',') > -1){
        return value
      }
     else if (parseInt(value) === 0 || value) {
      value = Number(value).toLocaleString('en-In', { maximumFractionDigits: 2 });
      return value;
    }
    else {
      return null;
    }
    }
    else{
      return null;
    }
  }
}

@Pipe({
  name: 'twoDecimal'
})
export class twoDecimal implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value ||  value == 0 ) {
      if (value.toString().indexOf('.') > -1) { value = Number(value).toFixed(2); }
      return value;
    }
    else {
      return '0'
    }
  }
}

@Pipe({
  name: 'exponentialToNumber'
})
export class exponentialToNumber implements PipeTransform {

  transform(value: any, args?: any): any {
    let pattern = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/g
    if (value) {
      if (pattern.test(value)) { value = Number(value).toFixed(2); }
      return value;
    }
    else {
      return '0'
    }
  }
}

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value >= 10000000) value = (value / 10000000).toFixed() + ' Cr';
    else if (value >= 100000) value = (value / 100000).toFixed() + ' L';
    else if (value >= 1000) value = (value / 1000).toFixed() + ' K';
    return value;
  }
}

@Pipe({
  name: 'spaceBetweenCapital'
})
export class SpaceBetweenCapitalPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      value = value.replace(/([a-z])([A-Z])/g, '$1 $2');
      value = value.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      return value;
    }
  }
}

@Pipe({
  name: 'thousandSuffixes'
})
export class ThousandSuffixesPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    var exp, rounded,
      suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];

    if (Number.isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      return input;
    }

    exp = Math.floor(Math.log(input) / Math.log(1000));

    return (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];


  }

}


