import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-dedicate-compute',
  templateUrl: './dedicate-compute.component.html',
  styleUrls: ['./dedicate-compute.component.scss']
})
export class DedicateComputeComponent implements OnInit {
  dedicatedComputeMenus: any = [];
  currentUrl: any;

  constructor(private router: Router, public util: UtilService) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event) => {
        this.currentUrl = event['urlAfterRedirects']
    })
    if(!this.currentUrl) this.currentUrl = this.router.url
   }

  ngOnInit(): void {
    let infraDeploymentMenus = this.util.getStatic('infraDeploymentMenus');
    let infraDeploymentSubMenus = infraDeploymentMenus[0].subMenus;
    this.dedicatedComputeMenus = infraDeploymentSubMenus[0].dedicateComputeOptions;
    console.log("1 ",this.dedicatedComputeMenus);
    //console.log("2 ",this.util.getStatic('infraDeploymentMenus[0]') );
  }

  routeToChild(link)
  {
    this.util.route([link],false);
  }

  allowUrl(){
    return ['a3s/infraDeploymentBucket/deployment/dedicateCompute'].indexOf(this.currentUrl)  > -1 ? true :false;
  }

}
