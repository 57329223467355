<!-- Header start -->
<nav class="header">
    <div class="flex-custom">
      <a class="header-brand" href="">
        <img src="assets/azure-abot-logo.png" width="40" height="40" class="d-inline-block align-top" alt="">
        <h2>AI Powered Azure Automation <span style= "display:block;">as a Service</span></h2>
      </a>

      <button class="btn btn-portal ml-auto" (click)= "route()" >Portal</button>
    </div>  
</nav>

  <div class="user-manual-container">
  <div class="a3s-userManualbg">
    <div class ="a3s-userManual">
        <h1>A3S user manual</h1>
        <span>Learn how to leverage A3S capabilities.</span>
    </div>    
  </div>
    <div class="test">

      <div class="user-manual-content">
        <div class="sidebar-content">
          <app-user-manual-sidebar [activeSidebar]= "activeSidebar" (onChangeSidebar)="changeTab($event)" ></app-user-manual-sidebar> 
        </div>
        <div class="content">
           <!-- A3S Introduction Component--> 
          <div *ngIf="activeSidebar=='Introduction'">
            <app-a3s-introduction > </app-a3s-introduction>
          </div>
           <!-- Intended Audience Componenet-->
           <div *ngIf="activeSidebar=='Intended-audience'">
            <app-intended-audience></app-intended-audience>
         </div>
         <!-- AI-powered Azure Automation as a Service -->
          <div *ngIf="activeSidebar=='AI-powered'">

          </div>
             <!-- AI-Overview -->
            <div *ngIf="activeSidebar=='AI-powered-AI-Overview'">
                <app-a3s-overview></app-a3s-overview>
            </div>
            <!-- Key-Capabilities -->
            <div *ngIf="activeSidebar=='AI-powered-Key-Capabilities'">
                <app-a3s-key-capabilities></app-a3s-key-capabilities>
            </div>
          <!-- A3S-Functional-Buckets -->
          <div *ngIf="activeSidebar=='A3S-Functional-Buckets'">
            <app-a3s-functional-buckets></app-a3s-functional-buckets>
          </div>
          <!-- Functional-Overview -->
          <div *ngIf="activeSidebar=='Functional-Overview'">
            <app-functional-overview></app-functional-overview>
          </div>
          <div *ngIf="activeSidebar=='A3-SProject'" >
 
          </div>

            <div *ngIf="activeSidebar=='A3S-Project-Concept-of-Project'">
              <app-a3s-project-concept></app-a3s-project-concept>
            </div>

            <div *ngIf="activeSidebar=='A3S-Project-User-Scenarios'">
              <app-a3s-user-scenarios></app-a3s-user-scenarios>
            </div>
          
          <!-- A3S-Filtres -->
          <div *ngIf="activeSidebar=='A3S-CFilters'">
            <!-- <p class="heading">A3S Filters</p> -->
            <app-a3s-filtres></app-a3s-filtres>
          </div>
          <!-- A3S-Login -->
          <div *ngIf="activeSidebar=='A3S-LoginSteps'">
            <!-- <p class="heading">A3S Login</p> -->
            <app-a3s-login></app-a3s-login>
          </div>
          
          <!-- Home-Page -->
          <div *ngIf="activeSidebar=='HP-Overview' || activeSidebar=='HP-BTB' ||activeSidebar=='HP-Hamburger' ||
          activeSidebar=='HP-Logo' || activeSidebar=='HP-Search' || activeSidebar=='HP-Chat' ||
          activeSidebar=='HP-Notifications' || activeSidebar=='HP-KC'|| activeSidebar=='HP-User' ||
          activeSidebar=='HP-Log-out' ||activeSidebar=='HP-Connect' || activeSidebar=='HP-Newsletter'||
          activeSidebar=='HP-Delete' || activeSidebar=='HP-Tiles' ||activeSidebar=='HP-Recent' ||activeSidebar=='HP-Favorites'">
            <!-- <p class="heading">Home Page</p> -->
            <app-a3s-homepage [activeSidebar]="activeSidebar" ></app-a3s-homepage>
          </div>
          <!-- -->
          <!-- Apps-&-Infrastructure -->
          <div *ngIf="activeSidebar=='Apps-Infrastructure'">
            <app-infrastructure></app-infrastructure>
          </div>
         
            <!-- Archietecture-Blueprints -->
            <div *ngIf="activeSidebar=='Apps-Infrastructure-Archietecture-Blueprints'">
              <app-architecture-blueprints></app-architecture-blueprints>
            </div>
                    <div *ngIf="activeSidebar=='Apps-Infrastructure-Archietecture-Blueprints-Create'">
                      <app-create></app-create>
                    </div>
                    <div *ngIf="activeSidebar=='Apps-Infrastructure-Archietecture-Blueprints-Delete'">
                      <app-delete></app-delete>
                    </div>
            <!-- Design-Tool-Components -->
            <div *ngIf="activeSidebar=='Apps-Infrastructure-Design-Tool-Components'">
              <app-design-tool-components  (onChangeSidebar)="changeTab($event)"></app-design-tool-components>
            </div>
            <!-- Using-Architecture-Design-Tool -->
            <div *ngIf="activeSidebar=='Apps-Infrastructure-Using-Architecture-Design-Tool'">
              <app-using-archi-design-tool></app-using-archi-design-tool>
            </div>
            <!-- Project-Status -->
            <div *ngIf="activeSidebar=='Apps-Infrastructure-Project-Status'">
              <app-project-status></app-project-status>
            </div>
                      <div *ngIf="activeSidebar=='Apps-Infrastructure-Project-Status-In-Review'">
                        <app-in-review></app-in-review>
                      </div>
                      <div *ngIf="activeSidebar=='Apps-Infrastructure-Project-Status-Approved'">
                        <app-approved></app-approved>
                      </div>
                      <div *ngIf="activeSidebar=='Apps-Infrastructure-Project-Status-Deployed'">
                        <app-deployed></app-deployed>
                      </div>
                      <div *ngIf="activeSidebar=='Apps-Infrastructure-Project-Status-Failed'">
                        <app-failed></app-failed>
                      </div>
            <!-- Infrastructure-Management -->
            <div *ngIf="activeSidebar=='Apps-Infrastructure-Infrastructure-Management'">
              <app-infrastructure-management></app-infrastructure-management>
            </div>
                      <div *ngIf="activeSidebar=='Apps-Infrastructure-IM-Overview'">
                        <app-infrastructure-management-overview></app-infrastructure-management-overview>
                      </div>
                      <div *ngIf="activeSidebar=='Apps-Infrastructure-IM-RM'">
                        <app-resource-management></app-resource-management>
                      </div>

          <!-- Data-&-AI -->
          <div *ngIf="activeSidebar=='Data-AI'">
            <app-data-ai></app-data-ai>
          </div>
          <!-- Data-Migration -->
          <div *ngIf="activeSidebar=='Data-AI-Data-Migration'">
            <app-data-migration></app-data-migration>
          </div>
                          <div *ngIf="activeSidebar=='Apps-Infrastructure-DM-Overview'">
                            <app-data-migration-overview></app-data-migration-overview>
                          </div>
                          <div *ngIf="activeSidebar=='Apps-Infrastructure-DM-SQL'">
                            <app-sql-server></app-sql-server>
                          </div>
                          <div *ngIf="activeSidebar=='Apps-Infrastructure-DM-Oracle'">
                            <app-oracle-server></app-oracle-server>
                          </div>

                          <div *ngIf="activeSidebar=='Apps-Infrastructure-DM-SAP-Hana'">
                            <app-sap-hana></app-sap-hana>
                          </div>
                          <div *ngIf="activeSidebar=='Apps-Infrastructure-DM-SAP-ECC'">
                            <app-sap-ecc></app-sap-ecc>
                          </div>
                          <div *ngIf="activeSidebar=='Apps-Infrastructure-DM-ABS'">
                            <app-azure-blob-storage></app-azure-blob-storage>
                          </div>
          <!-- Data-Governance -->
          <div *ngIf="activeSidebar=='Data-AI-Data-Gov'">
            <app-data-governance></app-data-governance>
          </div>
          <!-- Data-Analytics -->
          <div *ngIf="activeSidebar=='Data-AI-Data-Analytics'">
            <app-data-analytics></app-data-analytics>
          </div>
                            <div *ngIf="activeSidebar=='Apps-Infrastructure-DA-DF'">
                              <app-demand-forecasting></app-demand-forecasting>
                            </div>
                            <div *ngIf="activeSidebar=='Apps-Infrastructure-DA-FR'">
                              <app-form-recognition></app-form-recognition>
                            </div>
                            <div *ngIf="activeSidebar=='Apps-Infrastructure-DA-SP'">
                              <app-sales-prediction></app-sales-prediction>
                            </div>
                            <div *ngIf="activeSidebar=='Apps-Infrastructure-DA-RP'">
                              <app-revenue-prediction></app-revenue-prediction>
                            </div>
                            <div *ngIf="activeSidebar=='Apps-Infrastructure-DA-PS'">
                              <app-product-seasonality></app-product-seasonality>
                            </div>
                            <div *ngIf="activeSidebar=='Apps-Infrastructure-DA-KM'">
                              <app-knowledge-mining></app-knowledge-mining>
                            </div>
                            <div *ngIf="activeSidebar=='Apps-Infrastructure-DM-MF'">
                              <app-modern-finance></app-modern-finance>
                            </div>
                            <div *ngIf="activeSidebar=='Apps-Infrastructure-DM-RR'">
                            <app-retail-recommender></app-retail-recommender>
                            </div>
          <!-- Data-&-AI-DataOps -->
          <div *ngIf="activeSidebar=='Data-AI-DataOps'">
            <app-dataops></app-dataops>
          </div>

            <!-- Infrastructure-Health-Monitoring -->
            <div *ngIf="activeSidebar=='Infrastructure-Health-Monitoring'">
              <app-infrastructure-health-monitoring></app-infrastructure-health-monitoring>
            </div>
            <!-- Overview -->
            <div *ngIf="activeSidebar=='Infrastructure-Health-Monitoring-Overview'">
              <app-infrastructure-health-monitoring-overview></app-infrastructure-health-monitoring-overview>
            </div>
            <!-- Metrics -->
            <div *ngIf="activeSidebar=='Infrastructure-Health-Monitoring-Metrics'">
              <app-metrics-infras-health-monitoring></app-metrics-infras-health-monitoring>
            </div>
            <!-- Insights -->
            <div *ngIf="activeSidebar=='Infrastructure-Health-Monitoring-Insights'">
              <app-insights-infra-health-monitoring></app-insights-infra-health-monitoring>
            </div>
                      <div *ngIf="activeSidebar=='IHM-insights-query'">
                        <app-queries></app-queries>
                      </div>
                      <div *ngIf="activeSidebar=='IHM-insights-custom-query'">
                        <app-custom-query></app-custom-query>
                      </div>
            <!-- Alerts -->
            <div *ngIf="activeSidebar=='Infrastructure-Health-Monitoring-Alerts'">
              <app-alerts-infra-health-monitoring></app-alerts-infra-health-monitoring>
            </div>
                    <div *ngIf="activeSidebar=='IHM-alerts-newAction'">
                      <app-create-new-action></app-create-new-action>
                    </div>
                    <div *ngIf="activeSidebar=='IHM-alerts-custom-New-Alert'">
                      <app-create-new-alerts></app-create-new-alerts>
                    </div>
          
          <!-- Application-Health-Monitoring -->
          <div *ngIf="activeSidebar=='Application-Health-Monitoring'">
            <app-application-health-monitoring></app-application-health-monitoring>
          </div>
            <!-- Overview -->
            <div *ngIf="activeSidebar=='Application-Health-Monitoring-Overview'">
              <app-overview-application-health-monitoring></app-overview-application-health-monitoring>
            </div>
            <!-- metrics -->
            <div *ngIf="activeSidebar=='Application-Health-Monitoring-metrics'">
              <app-metrics-application-health-monitoring></app-metrics-application-health-monitoring>
            </div>
                      <div *ngIf="activeSidebar=='AHM-Availability'">
                        <app-availability></app-availability>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-Average'">
                        <app-average></app-average>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-SQ'">
                        <app-metrics-server-req></app-metrics-server-req>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-RT'">
                        <app-metrics-response-time></app-metrics-response-time>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-Exception'">
                        <app-metrics-exception></app-metrics-exception>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-Events'">
                        <app-metrics-events></app-metrics-events>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-page-views'">
                        <app-metrics-page-view></app-metrics-page-view>
                      </div>
                      
            <!-- Usage -->
            <div *ngIf="activeSidebar=='Application-Health-Monitoring-Usage'">
              <app-insights-application-health-monitoring></app-insights-application-health-monitoring>
            </div>
                      <div *ngIf="activeSidebar=='AHM-Users'">
                        <app-users-by-country></app-users-by-country>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-Sessions'">
                        <app-usage-session></app-usage-session>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-usageEvents'">
                        <app-usage-events></app-usage-events>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-Overall'">
                        <app-usage-overall-retentions></app-usage-overall-retentions>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-Users-returned'">
                        <app-users-returned></app-users-returned>
                      </div>
                      <div *ngIf="activeSidebar=='AHM-return-Events'">
                        <app-initiate-return-event></app-initiate-return-event>
                      </div>
            <!-- Alerts -->
            <div *ngIf="activeSidebar=='Application-Health-Monitoring-Alerts'">
              <app-alerts-application-health-monitoring></app-alerts-application-health-monitoring>
            </div>
                    <div *ngIf="activeSidebar=='AHM-alerts-newAction'">
                      <app-application-create-new-action></app-application-create-new-action>
                    </div>
                    <div *ngIf="activeSidebar=='AHM-alerts-custom-New-Alert'">
                      <app-application-create-new-alert></app-application-create-new-alert>
                    </div>
         
          <!-- Cost-Monitoring-&-Optimization -->
          <div *ngIf="activeSidebar=='Cost-Monitoring-Optimization'">
            <app-cost-monitoring-and-optimization></app-cost-monitoring-and-optimization>
          </div>
            <!-- Overview -->
            <div *ngIf="activeSidebar=='Cost-Monitoring-Optimization-Overview'">
              <app-overview-cm-and-optimization></app-overview-cm-and-optimization>
            </div>
            <!-- Cost-Analysis -->
            <div *ngIf="activeSidebar=='Cost-Monitoring-Optimization-Cost-Analysis'">
              <app-cost-analysis></app-cost-analysis>
            </div>
            <!-- Optimize -->
            <div *ngIf="activeSidebar=='Cost-Monitoring-Optimization-Optimize'">
              <app-optimize-cmo></app-optimize-cmo>
            </div>
            <!-- Budget-&-Alerts -->
            <div *ngIf="activeSidebar=='Cost-Monitoring-Optimization-Budget-Alerts'">
              <app-budget-and-alerts></app-budget-and-alerts>
            </div>
                      <div *ngIf="activeSidebar=='CM-alerts'">
                        <app-create-set></app-create-set>
                      </div>
            <!-- Billing -->
            <div *ngIf="activeSidebar=='Cost-Monitoring-Optimization-Billing'">
              <app-billing></app-billing>
            </div>
                    <div *ngIf="activeSidebar=='CM-cost'">
                      <app-cost></app-cost>
                    </div>
                    <div *ngIf="activeSidebar=='CM-Invoices'">
                      <app-bill-invoice></app-bill-invoice>
                    </div>
                    <div *ngIf="activeSidebar=='CM-RT'">
                      <app-reservation-transcation></app-reservation-transcation>
                    </div>
                    <div *ngIf="activeSidebar=='CM-BP'">
                      <app-billing-profile></app-billing-profile>
                    </div>
         
          <!-- Governance -->
          <div *ngIf="activeSidebar=='Governance'">
            <app-governance></app-governance>
          </div>
            <!-- Overview -->
            <div *ngIf="activeSidebar=='Governance-Overview'">
              <app-overview-governance></app-overview-governance>
            </div>
            <!-- Enterprise-Policies -->
            <div *ngIf="activeSidebar=='Governance-Enterprise-Policies'">
              <app-enterprise-policy-governance></app-enterprise-policy-governance>
            </div>
                        <div *ngIf="activeSidebar=='Gov-policy'">
                          <app-assign-policy></app-assign-policy>
                        </div>
                        <div *ngIf="activeSidebar=='Gov-Initiate'">
                          <app-assign-initiatives></app-assign-initiatives>
                        </div>
            <!-- Identity-and-Access-Management -->
            <div *ngIf="activeSidebar=='Governance-Identity-and-Access-Management'">
              <app-identity-and-access-management></app-identity-and-access-management>
            </div>
                              <div *ngIf="activeSidebar=='IAM-Overview'">
                                <app-iden-overview></app-iden-overview>
                              </div>
                              <div *ngIf="activeSidebar=='IAM-addUser'">
                                <app-add-user></app-add-user>
                              </div>
                              <div *ngIf="activeSidebar=='IAM-Invite'">
                                <app-invite-user></app-invite-user>
                              </div>
                              <div *ngIf="activeSidebar=='IAM-addRole'">
                                <app-add-role-assign></app-add-role-assign>
                              </div>
                              <div *ngIf="activeSidebar=='IAM-UM'">
                                <app-user-management></app-user-management>
                              </div>
                              <div *ngIf="activeSidebar=='IAM-addGroup'">
                                <app-add-group></app-add-group>
                              </div>
                              <div *ngIf="activeSidebar=='IAM-GM'">
                                <app-group-management></app-group-management>
                              </div>
            <!-- Auditing-and-Logging -->
            <div *ngIf="activeSidebar=='Governance-Auditing-and-Logging'">
              <app-auditing-and-logging></app-auditing-and-logging>
            </div>
                      <div *ngIf="activeSidebar=='Gov-Activity'">
                        <app-activity-logs></app-activity-logs>
                      </div>
                      <div *ngIf="activeSidebar=='Gov-Sign-Ins'">
                        <app-sign-ins></app-sign-ins>
                      </div>
                      <div *ngIf="activeSidebar=='Gov-Audit'">
                        <app-audit-logs></app-audit-logs>
                      </div>

         
          <!-- Security -->
          <div *ngIf="activeSidebar=='Security'">
            <app-security></app-security>
          </div>
            <!-- Security-Overview -->
            <div *ngIf="activeSidebar=='Security-Overview'">
              <app-overview-security></app-overview-security>
            </div>
                      <div *ngIf="activeSidebar=='Sec-alerts'">
                        <app-overview-alerts></app-overview-alerts>
                      </div>
                      <div *ngIf="activeSidebar=='Sec-overall'">
                        <app-overall-score></app-overall-score>
                      </div>
                      <div *ngIf="activeSidebar=='Sec-coverage'">
                        <app-coverage></app-coverage>
                      </div>
                      <div *ngIf="activeSidebar=='Sec-active'">
                        <app-active-recom></app-active-recom>
                      </div>

            <!-- Security-Posture -->
            <div *ngIf="activeSidebar=='Security-Security-Posture'">
              <app-security-posture></app-security-posture>
            </div>
                      <div *ngIf="activeSidebar=='Sec-IS'">
                        <app-identity-security></app-identity-security>
                      </div>
                      <div *ngIf="activeSidebar=='Sec-EP'">
                        <app-endpoint></app-endpoint>
                      </div>
                      <div *ngIf="activeSidebar=='Sec-activities'">
                        <app-sec-activities></app-sec-activities>
                      </div>
                      <div *ngIf="activeSidebar=='Sec-Recomm'">
                        <app-sec-recomm></app-sec-recomm>
                      </div>
            <!-- Security-Alerts -->
            <div *ngIf="activeSidebar=='Security-Alerts'">
              <app-alerts-security></app-alerts-security>
            </div>
          
          <!-- Compliance -->
          <div *ngIf="activeSidebar=='Compliance'">
            <app-compliance></app-compliance>
          </div>
            <!-- Overview -->
            <div *ngIf="activeSidebar=='Compliance-Overview'">
              <app-compliance-overview></app-compliance-overview>
            </div>
                        <div *ngIf="activeSidebar=='Comp-benchmark'">
                          <app-compliance-benchmark></app-compliance-benchmark>
                        </div>
                        <div *ngIf="activeSidebar=='Comp-status'">
                          <app-compliance-status></app-compliance-status>
                        </div>
                        <div *ngIf="activeSidebar=='Comp-list'">
                          <app-list-of-standards></app-list-of-standards>
                          
                        </div>
                        <div *ngIf="activeSidebar=='Comp-DAS'">
                          <app-deploy-standards></app-deploy-standards>
                          
                        </div>
            <!-- Manage-Policy -->
            <div *ngIf="activeSidebar=='Compliance-Manage-Policy'">
              <app-compliance-manage-policy></app-compliance-manage-policy>
            </div>
                      <div *ngIf="activeSidebar=='Comp-default'">
                        <app-default-a3s-security></app-default-a3s-security>
                      </div>
                      <div *ngIf="activeSidebar=='Comp-MP'">
                        <app-manage-policy></app-manage-policy>
                      </div>
            <!-- Industry-and-Regulatory-Standards -->
            <div *ngIf="activeSidebar=='Compliance-Industry-and-Regulatory-Standards'">
              <app-industry-and-regulatory-standards></app-industry-and-regulatory-standards>
            </div>
                      <div *ngIf="activeSidebar=='Comp-IRS'">
                        <app-add-standards></app-add-standards>
                      </div>
            <!-- Reports -->
            <div *ngIf="activeSidebar=='Compliance-Reports'">
              <app-compliance-reports></app-compliance-reports>
            </div>
         


          <!-- A3S-Support -->
          <div *ngIf="activeSidebar=='A3S-Support'">
            <app-a3s-support></app-a3s-support>
          </div>
          <!-- Azure-Support -->
          <div *ngIf="activeSidebar=='Azure-Support'">
            <app-azure-support></app-azure-support>
          </div>
          <!-- SAP-Support -->
          <div *ngIf="activeSidebar=='SAP-Support'">
            <app-sap-support></app-sap-support>
          </div>




        </div>
        <div class="article-content">
          <p class="right-sidebar">In this article</p>
          <div class="links-bg">
            <div class="links" *ngFor="let data of articleList" [class.active-link]="currentArticle == data.value">
              <div class="list" (click)= "scrollToPosition(data?.id);currentArticle = data.value">{{data?.value}}</div>
            </div>
         
          </div>
        </div>

    </div>
    
    </div>
   
  
  

  <div class="footer">
    <a class="a3s">
      <a class="a3s-link" href="https://azureautomation.ai/">©2021 Azureautomation.ai</a>
      <img class="ml-3" src="assets\user-manual\Rectangle.svg">
      <span >Privacy</span>
    </a>
  </div> 

   

</div>

