import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { UtilService } from 'src/app/services/util.service';
import { ValidatorsService } from 'src/app/services/validators.service';
import { IPv4, IPSubnetwork } from 'ip-matching';
import * as fs from 'file-saver';
// import { type } from 'os';

@Component({
  selector: 'app-action-template',
  templateUrl: './action-template.component.html',
  styleUrls: ['./action-template.component.scss']
})
export class ActionTemplateComponent implements OnInit {
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  @ViewChild("dissociateConfirm") dissociateConfirmPopup: TemplateRef<any>;
  @ViewChild("addSecurityRulesPopup") addSecurityRulesPopupTemplate: TemplateRef<any>;
  @Input() data : any;
  @Input() allData: any;
  @Input() currentProject : any;
  @Input() templateName : any;
  @ViewChild("dataDisk") dataDiskTemplate: TemplateRef<any>;
  @ViewChild("connectVM") connectVMTemplate: TemplateRef<any>;
  @ViewChild("subnet") subnetTemplate: TemplateRef<any>;
  @ViewChild("subnetType") subnetTypeTemplate: TemplateRef<any>;
  @ViewChild("autoShutdown") autoShutdownTemplate: TemplateRef<any>;
  @ViewChild("addressSpace") addressSpaceTemplate: TemplateRef<any>;
  @ViewChild("configuration") configurationTemplate: TemplateRef<any>;
  @ViewChild("associateIp") associateIpTemplate: TemplateRef<any>;
  @ViewChild("disassociateIp") disassociateIpTemplate: TemplateRef<any>;
  @ViewChild("inboundSecurityRules") inboundSecurityRulesTemplate: TemplateRef<any>;
  @ViewChild("outboundSecurityRules") outboundSecurityRulesTemplate: TemplateRef<any>;
  @ViewChild("dnsServer") dnsServerTemplate: TemplateRef<any>;
  @ViewChild("ddosProtection") ddosProtectionTemplate: TemplateRef<any>;
  @ViewChild("networkInterface") networkInterfaceTemplate: TemplateRef<any>;
  @ViewChild("nsgSubnets") nsgSubnetsTemplate: TemplateRef<any>;
  @ViewChild("nicNsg") nicNSGTemplate: TemplateRef<any>;
  @ViewChild("nicDnsServer") nicDnsServerTemplate: TemplateRef<any>;nicIPConfiguration
  @ViewChild("nicIPConfiguration") nicIPConfigurationTemplate: TemplateRef<any>;

  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  currentTemplate:TemplateRef<any>;

  currentForm: FormGroup;
  currentSubForm: FormGroup;
  showSubSidesheet: any;
  editSubSidesheet:any
  selectedRow: any;
  selectedType: any;
  dissociate: any;
  currentConnectTab: any = 'RDP'

  vnetData: any;
  vmData: any;
  nsgData: any;
  nicData: any;
  subnetData: any;


  sourceTypeList:any=[
    {name:'None (empty disk)'},
    {name:'Snapshot'}
  ];
  diskEncryptionTypeList:any=[
    {name:'(default) Encryption at-rest with a platform-managed key',id:'1'},
    {name:'Encryption at-rest with a customer-managed key',id:'2',"encryptionType": "EncryptionAtRestWithCustomerKey"},
    {name:'Double encryption with platform-managed and customer-managed keys',id:'3',"encryptionType": "EncryptionAtRestWithPlatformAndCustomerKeys"}
  ];
  diskSKUList:any=[
    {name:'Premium SSD',id:'Premium_LRS'},
    {name:'Standard SSD',id:'StandardSSD_LRS'},
    {name:'Standard HDD',id:'Standard_LRS'}
  ];
  vmSourceList:any=[
    {name:'Any'},
    {name:'IP addresses'}
  ];

  destinationList:any=[
    {name:'Any'},
    {name:'IP addresses'},
    {name:'VirtualNetwork'}
  ];

  vmServiceList:any=[
    {name:'HTTP'},
    {name:'HTTPS'},
    {name:'SSH'},
    {name:'RDP'},
    {name:'MS SQL'},
    {name:'MySQL'},
    {name:'Custom'},
    {name:'DNS(TCP)'},
    {name:'DNS(UDP)'}
  ];
  inputError= {
    sourceNotValidPortVal : false,
    sourceInvalidPortRange:false,
    sourceOverlap:false,
    destNotValidPortVal:false,
    destInvalidPortRange:false,
    destOverlap:false,
    sourceInvalidArgument:false,
    destInvalidArgument:false,
    sourceNotValidCIDR:false,
    destNotValidCIDR:false,
    sourceOverlaping:false,
    destOverlaping:false,
    VMPriorityValid:false,
    VMPriorityMaxMin:false,
    nameLen:false,
    nameValid:false,
    VMdescription:false,
    VMPriorityDupCheck:false,
    nameDupCheck:false,
    dnsName:false,
    dnsNameLength:false,
    dnsNameFLetter:false,
    dnsNameLLetter:false,
    idleTimeout:false,
    emptyBox:false,
    // subnet
    subnetName: false,
    subnetChar: false,
    subnetAdrspc: false,
    subnetDescription: false,
    subnetDuplicate:false,
    subnetNotValidCIDR:false,
    subnetOverlaping:false,
    subnetAddresNotInVnet:false,
    subnetPerfix:false,
    dsServiceCheck:false,
    dnsError:false,
    dnsUnique:false,
    ipNameMaxLen:false,
    ipNameValid:false,
    ipNameLastValid:false,
    ipNameExist:false,
    publicIPNameMaxLen:false,
    publicIPNameValid:false,
    publicIPNameLastValid:false,
  }
  ddosProtectionPlanList: any = [];
  natGetwaysList: any = [];
  routeTablesList: any = [];
  nsgList: any = [];
  servicesList: any;
  dsServiceList: any = [];
  resourceGroupList: any = [];
  timeZoneList: any = [];
  existingVNList: any = [];
  existingSubnetList: any = [];
  currentSecurityRules: any;
  isSecurityRulesEdit: any;
  associatePublicIp:any;
  networkInterfaceList: any;
  LoadBalancerList: any;
  nicPublicIPData: any;
  PublicIPData: any;
  associatedTo: any;
  checkLBData: any;
  dnsNameCheck: any ={available:true}
  vnetRgName: any;
  dynamicName: any;
  datasort: string;
  vNetOverlap : any;
  warningMessage:boolean =false;
  infoMessage:boolean=false;
  publicIPList: any;
  nicPublicIP: any;
  vmConfigurationData: any;
  showpublicIP: boolean;
  dropdownHeader: string;
  vmIpAddressList: any;
  // disableAutoShutdown: boolean;  
  sortDefaultNsg: boolean =false;
  showGatewaySubnet: boolean =false;
  publicIPAllList: any;
  inputAddress: any;
  subnetList: any;
  saveDisable:boolean=false;
  subSideSaveDisable: boolean;
  constructor(private util:UtilService,private cdr: ChangeDetectorRef,private modalService: NgbModal,private validatorService : ValidatorsService) { }
  
  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    var $target = $(event.target);
   if(!$target.closest('#createPublicIP').length && $('#createPublicIP').is(":visible")&& event.target['innerText'] !== 'Create New' && !$target.closest('.ng-option').length || event.target['ariaLabel'] == 'Close'){
    this.showpublicIP=false
   }
  }

  ngOnInit(): void {
    // this.associatePublicIp = this.util.getStatic('associatePublicIp');
    // this.currentForm = this.util.getForm('associatePublicIp')
  }
  
  get addressSpaceList() {
    return this.currentForm.get('addressSpaceArray') as FormArray;
  }

  get tagsList() {
    return this.currentSubForm.get('tagsArray') as FormArray;
  }


  /******************************************** Common ************************************************************/
  async ngAfterViewInit(){
    switch(this.templateName){
      case "dataDisk" : {
        this.currentTemplate = this.dataDiskTemplate;
        this.currentForm = this.util.getForm('dataDisk')
        this.getVMData('dataDisk',this.data)
        break;
      } 
      case "connectVM" : {
        this.currentTemplate = this.connectVMTemplate;
        this.currentForm = this.util.getForm('connectVM')
        this.getVMConfiguration('connectVM',this.data)
        break;
      } 
      case "subnet" : {
        this.currentTemplate = this.subnetTemplate;
        this.currentForm = this.util.getForm('addSubnet')
        this.saveDisable=false
        await this.getResourceGroupList()
        this.getNATGatewayData('vnetSubnet')
        this.getRouteTableData('vnetSubnet');
        this.getNSGlistData('nicNsg');
        this.getServiceEndpointData(this.data,'vnetSubnet');
        this.getDelegateSubnetData(this.data,'vnetSubnet');  
        // this.getListByPropName('natGateways');
        // this.getListByPropName('networkSecurityGroups');
        // this.getListByPropName('routeTables');
        // this.getListByPropName('availableDelegations');
        // this.getListByPropName('service');
        this.getVNETData('subnet',this.data)
        break;
      } 
      // case "autoShutdown" : {
      //   this.currentTemplate = this.autoShutdownTemplate;
      //   this.timeZoneList=this.util.getStatic('timeZoneList');
      //   this.currentForm = this.util.getForm('autoShutdown');
      //   this.getAutoShutdownData(this.data)
      //   break;
      // } 
      case "addressSpace" : {
        this.currentTemplate = this.addressSpaceTemplate;
        this.currentForm = this.util.getForm('addressSpace')
        this.getVNETData('addressSpace',this.data)
        this.saveDisable=false
        break;
      } 
      case "configuration" : {
        this.currentTemplate = this.configurationTemplate;
        this.currentForm = await this.util.getForm('configuration')
        await this.getPublicIPAddress('configuration',this.data)
        this.ipCondition()

        break;
      } 
      case "associateIp" : {
        this.associatePublicIp = await this.util.getStatic('associatePublicIp');
        this.currentTemplate = this.associateIpTemplate;
        this.currentForm = await this.util.getForm('associatePublicIp')
        await this.getPublicIPAddress('associateIp',this.data)
        await this.getNICList('associateNIC',this.data)
        break;
      } 
      case "disassociateIp" : {
        this.currentTemplate = this.disassociateIpTemplate;
        this.getPublicIPAddress('disassociate',this.data)
        this.currentForm = this.util.getForm('dissociatePublicIp')
        break;
      } 
      case "subnetType" : {
        this.currentTemplate = this.subnetTypeTemplate;
        this.currentForm = this.util.getForm('editSubnet')
        await this.getSubnetType('edit',this.data)
        this.getVNETData('subnetCon',this.data)
        this.getNATGatewayData('subnet')
        this.getRouteTableData('subnet');
        this.getNSGlistData('subnet');
        this.getServiceEndpointData(this.data,'subnet');
        this.getDelegateSubnetData(this.data,'subnet');  
        this.currentForm.get('vnetName').setValue(this.data[1])
        this.currentForm.get('subnetName').setValue(this.data[0])
        this.currentForm.get('subnetAddressSpace').setValue(this.data[3])
        !this.subnetData.properties?.natGateway?  this.currentForm.get('natGateway').setValue('None'):this.currentForm.get('natGateway').setValue(this.subnetData.properties?.natGateway?.id)
        !this.subnetData.properties?.routeTable?  this.currentForm.get('routeTable').setValue('None'):this.currentForm.get('routeTable').setValue(this.subnetData.properties?.routeTable.id)
        !this.subnetData.properties?.networkSecurityGroup?  this.currentForm.get('nsg').setValue('None'):this.currentForm.get('nsg').setValue(this.subnetData.properties?.networkSecurityGroup.id)
        this.subnetData.properties.delegations.length!=0 ?  this.currentForm.get('delegatedSubnet').setValue(this.subnetData.properties?.delegations[0]?.properties?.serviceName):this.currentForm.get('delegatedSubnet').setValue('None')
        let service=[]
        this.subnetData.properties.serviceEndpoints.forEach(e => {
          service.push(e.service)
        });
        this.currentForm.get('services').setValue(service)
        this.subnetData.name=='GatewaySubnet'?  this.currentForm.get('nsg').setValue('None'):false
        break;
      }
      case "inboundSecurityRules" : {
        this.currentTemplate = this.inboundSecurityRulesTemplate;
        await this.getNSGData('inboundSecurityRules',this.data)
        this.currentForm = this.util.getForm('inboundSecurityRules')
        break;
      } 
      case "outboundSecurityRules" : {
        this.currentTemplate = this.outboundSecurityRulesTemplate;
        this.currentForm = this.util.getForm('outboundSecurityRules')
        this.getNSGData('outboundSecurityRules',this.data)
        break;
      } 
      case "networkInterface" : {
        this.currentTemplate = this.networkInterfaceTemplate;
        this.currentForm = this.util.getForm('networkInterface')
        this.getNSGData('networkInterface',this.data)
        break;
      } 
      case "nsgSubnets" : {
        this.currentTemplate = this.nsgSubnetsTemplate;
        this.getListByPropName('virtualNetworks');
        this.getListByPropName('subnetByVN');
        this.currentForm = this.util.getForm('nsgSubnet')
        this.getNSGData('nsgSubnet',this.data)
        break;
      } 
      case "ddosProtection" : {
        this.currentTemplate = this.ddosProtectionTemplate;
        this.currentForm = this.util.getForm('ddosProtection')
        this.getVNETData('ddosProtection',this.data)
        this.getAllDDoSProtectionPlan(this.data)
        break;
      } 
      case "dnsServer" : {
        this.currentTemplate = this.dnsServerTemplate;
        this.currentForm = this.util.getForm('dnsServer')
        this.getVNETData('dnsServer',this.data)
        this.saveDisable=false
        break;
      } 
      case "nicNsg" : {
        this.currentTemplate = this.nicNSGTemplate;
        this.currentForm = this.util.getForm('nicNsg')
        this.saveDisable=false
        await this.getNICData('nicNsg',this.data)
        await this.getNSGlistData('nicNsg')
        this.currentForm.get('nsgList').setValue(this.nicData?.properties?.networkSecurityGroup ?this.nicData?.properties?.networkSecurityGroup?.id:'None')
        break;
      } 
      case "nicDnsServer" : {
        this.currentTemplate = this.nicDnsServerTemplate;
        this.currentForm = this.util.getForm('nicDnsServer',this.nicData)
        await this.getNICData('nicNsg',this.data)  
        let addressSpaceArray  = this.nicData['properties']?.dnsSettings?.dnsServers
        // this.addressSpaceList.get('value').value.setvalue(null)
        addressSpaceArray.forEach(e=>{
          this.addressSpaceList.insert(0,this.util.getForm('addAddressSpace',{value:e}))
        });
        this.currentForm.get('dnsServer').setValue(this.nicData['properties']?.dnsSettings?.dnsServers.length?false:true)
        this.currentForm.get('value').setValue(addressSpaceArray)
        break;
      } 
      case "nicIPConfiguration" : {
        this.currentTemplate = this.nicIPConfigurationTemplate;
        this.currentForm = this.util.getForm('nicIPConfiguration',this.nicData)
        await this.getNICData('nicNsg',this.data)  
        this.currentForm.get('ipForwarding').setValue(this.nicData['properties']?.enableIPForwarding?true:false)
        this.currentForm.get('subnetIP').setValue(this.nicData['properties'].ipConfigurations[0].properties?.subnet?.id)
        console.log('currentData',this.vnetData)
        let vnetRg = this.nicData.properties.ipConfigurations[0].properties.subnet.id.split('/')[4]
        await this.getSubnetListData(this.data,vnetRg)
        await this.getPublicIPList()
        break;
      } 
    }
    this.cdr.detectChanges();
    console.log('currentData',this.data)
    console.log('currentForm',this.currentForm)
    console.log('currentTemplate',this.currentTemplate)
  }

  triggerOutput(){
    this.triggerBtnvalue.emit({value:true,type:'close'})
  }

  initialiseSubForm(type){
    switch(type){
      case 'ddosProtection' : {
        this.currentSubForm = this.util.getForm('createDdosProtectionPlan')
        break;
      }
    }
  }
  openConfirmModal(template: TemplateRef<any>,row){
    this.selectedRow = row
    this.modalService.open(template,{ windowClass: 'mgmt-popup confirm-popup' });
  }

  openModal(template: TemplateRef<any>,row?:any,type?:any) {
    this.selectedRow=row;
    this.selectedType=type;
    console.log(this.selectedRow)
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  
  openNormalModal(template: TemplateRef<any>,name?:any,isEdit?:any){
    this.currentSecurityRules = name 
    this.isSecurityRulesEdit = isEdit
    this.modalService.open(template);
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  getSuccesText(type){
    switch(type){
      case 'addressSpace' :  return 'Address space added successfully!'
      case 'dnsServer' :  return 'DNS server created successfully!'
      case 'Nic': return this.selectedRow.nicName +' dissociated successfully!'
      case 'associate': return this.data[0] +' associated successfully!'
      case 'dissociate': return this.data[0] +' dissociated successfully!'
      case 'configurationIP': return this.data[0] +' changes saved successfully!'
      case 'dissociateNic': return this.selectedRow.nicName +' dissociated successfully!'
      case 'dissociateSubnet': return this.selectedRow.subnetName +' dissociated successfully!'
      case 'deleteOutbound':  return this.selectedRow.name +' deleted successfully!'
      case 'deleteInbound': return this.selectedRow.name +' deleted successfully!'
      case 'nicNsg': return this.data[0] +' changes saved successfully!'
      case 'puclicIPCreate': return 'Public IP created successfully!'
      default: return 'Changes saved successfully!'
    }
  }

  getFailureText(type){
    switch(type){
      case 'addressSpace' :  return 'Failed to add address space.'
      case 'dnsServer' :  return 'DNS server failed to be created.'
      case 'associate': return this.data[0] +' failed to be associated.'
      case 'dissociateNic': return this.selectedRow.nicName +' failed to be dissociated.'
      case 'dissociateSubnet': return this.selectedRow.subnetName +' failed to be dissociated.'
      case 'deleteOutbound':
      case 'deleteInbound': return this.selectedRow.name +' failed to be deleted.'
      case 'dnsName': return 'DNS Name is Invalid'
      case 'nicNsg': return 'Failed to save changes to'+ this.data[0] 
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case 'nicNsg' :  return `Saving changes to ${this.data[0]}...`
      default: return `Saving changes to ${this.data[0]}...`
    }
  }

  resetForm(type?:any){
    if(type=='nicIP'){
      this.currentForm.get('name').setValue(null)
      this.currentForm.get('type').setValue(null)
      this.currentForm.get('allocation').setValue(null)
      this.currentForm.get('publicIPAddress').setValue(null)
      this.currentForm.get('ipAddress').setValue(null)
      this.currentForm.get('publicIPAddressInput').setValue(null)
      this.currentForm.get('publicIPName').setValue(null)
      this.currentForm.get('sku').setValue(null)
      this.currentForm.get('assigment').setValue(null)
    }
   else   this.currentForm.reset()
  }
  resetValidation(){
    this.inputError= {
      sourceNotValidPortVal : false,
      sourceInvalidPortRange:false,
      sourceOverlap:false,
      destNotValidPortVal:false,
      destInvalidPortRange:false,
      destOverlap:false,
      sourceInvalidArgument:false,
      destInvalidArgument:false,
      sourceNotValidCIDR:false,
      destNotValidCIDR:false,
      sourceOverlaping:false,
      destOverlaping:false,
      VMPriorityValid:false,
      VMPriorityMaxMin:false,
      nameLen:false,
      nameValid:false,
      VMdescription:false,
      VMPriorityDupCheck:false,
      nameDupCheck:false,
      dnsName:false,
      dnsNameLength:false,
      dnsNameFLetter:false,
      dnsNameLLetter:false,
      idleTimeout:false,
      emptyBox:false,
      // subnet
      subnetName: false,
      subnetChar: false,
      subnetAdrspc: false,
      subnetDescription: false,
      subnetDuplicate:false,
      subnetNotValidCIDR:false,
      subnetOverlaping:false,
      subnetAddresNotInVnet:false,
      subnetPerfix:false,
      dsServiceCheck:false,
      dnsError:false,
      dnsUnique:false,
      ipNameMaxLen:false,
      ipNameValid:false,
      ipNameExist:false,
      ipNameLastValid:false,
      publicIPNameMaxLen:false,
      publicIPNameValid:false,
      publicIPNameLastValid:false,
    }
  }

  addDefaultData(type,subType?:any){
    switch(type){
      case 'vNetSubnet':{
        this.resetForm()
        this.currentForm.get('vnetName').setValue(this.data[0])
        this.currentForm.get('nsg').setValue('None')
        this.currentForm.get('natGateway').setValue('None')
        this.currentForm.get('routeTable').setValue('None')
        this.currentForm.get('delegatedSubnet').setValue('None')
        switch(subType){
          case 'addSubnet':{
            this.currentForm.get('subnetName').enable()
            this.currentForm.get('nsg').enable()
            break;
          }
          case 'addGatewaySubnet':{
            this.currentForm.get('subnetName').setValue('GatewaySubnet')
            this.currentForm.get('subnetName').disable()
            this.currentForm.get('nsg').disable()
            break;
          }
        }
      }
     
    }
  }

  getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.data[2],
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    return this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,false).then(async res=>{
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
      });
  }

  async getListByPropName(propName){
    let reqObj={
      subscriptionId:this.data[2],
      // resourceGroupName:propName=='virtualNetworks' ? undefined: this.data[1],
      // location:this.data[3],
      resourceGroupName: undefined,
      location: undefined,
      fetchPropName:propName,
      virtualtNetworkName: propName=='subnetByVN' ? this.selectedRow.vnetName : undefined
    };
    await this.util.handleRequest('post','a3s_management_getListByPropName',[],reqObj).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name== this.data[1])
      switch(propName){
        case 'natGateways' : {
          if(res.value&&res.value.length>0)
          result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
          this.natGetwaysList=result;
          break;
        }
        case 'routeTables': {
          if(res.value&&res.value.length>0)
          result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
          this.routeTablesList=result;
          break;
        }
        case 'networkSecurityGroups' :  {
          if(res.value&&res.value.length>0)
          result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
          this.nsgList=result;
          break;
        }
        case 'service' :  return   this.servicesList=res.value;
        case 'availableDelegations':{
          let dsResult=[{
            serviceName:"None"
          }];
          if(res.value&&res.value.length>0)
          dsResult.push(...res.value);
          this.dsServiceList=dsResult;
          break;
        }
        case 'virtualNetworks':{
          this.existingVNList=res.value;
          this.existingVNList=this.existingVNList.filter(x=>x.location==this.data[3])
          break;
        }
        case 'subnetByVN':{
          this.existingSubnetList=res.value;
          break;
        }
      }
    })

  }

  getStatusClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }

  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  /******************************************** Common End************************************************************/

  /******************************************** Virtual Machine ************************************************************/
  getVMData(type,data){
    this.util.handleRequest('get','a3s_management_getVMData',[data[2],data[1],data[0]],null,null,null,true).then(res=>{
      this.vmData = res.body
      switch(type){
        case 'dataDisk':{
         break;
        }
        default:{
          break;
        }
      }
      console.log('VM Data',this.vmData)
    })
  }

  async getVMConfiguration(type,data){
    this.util.handleRequest('get','a3s_management_getVMConfig',[data[2],data[1],data[0]],null,null,null,true).then(async res=>{
      this.vmConfigurationData = res.body
      let array = res.body.properties.networkProfile.networkInterfaces
      let nicName;
      if(array.length == 1){
        nicName = array[0].id.split('/')[array[0].id.split('/').length - 1]
      }
      else if(array.length){
        let filter = array.filter(e=> e.primary)
        nicName = filter[0].id.split('/')[filter[0].id.split('/').length - 1]
      }
      let adminName = this.vmConfigurationData.properties.osProfile.adminUsername
      this.currentForm.get('sshPrivateKey').setValue(`chmod 400 ${adminName}.pem`)
      this.currentForm.get('sshPrivatePath').setValue(`~/.ssh/${adminName}`)
    
      switch(type){
        case 'connectVM':{
          let NICData = await this.util.handleRequest('get','a3s_management_getVMNetworkInterface',[data[2],data[1],nicName],null,null,null,true)
          NICData.body.properties.ipConfigurations.forEach(e=>{
            if(e.properties.primary){
              let publicIpValue = e.properties.publicIPAddress ? e.properties.publicIPAddress.properties.ipAddress : null
              let privateIpValue = e.properties.privateIPAddress ? e.properties.privateIPAddress : null
              let publicIp = e.properties.publicIPAddress ? {name : `Public IP address (${e.properties.publicIPAddress.properties.ipAddress})`,value:e.properties.publicIPAddress.properties.ipAddress} : null
              let privateIp = e.properties.privateIPAddress ? {name :`Private IP address (${e.properties.privateIPAddress})` ,value:e.properties.privateIPAddress} : null
              this.vmIpAddressList= [...this.vmIpAddressList,...[privateIp],...[publicIp]]
              if(!publicIp) this.currentForm.get('rdpIp').setValue(privateIp)
              this.currentForm.get('sshCommand').setValue(`ssh -i ~/.ssh/${adminName} ${adminName}@${publicIpValue ? publicIpValue : privateIpValue}`)
            }
          })
         break;
        }
        default:{
          break;
        }
      }
      console.log('VM Data',this.vmData)
    })
  }

  

  setEnableSharedDisk(type){
    let value = type == 'yes' ? true : false
    this.currentForm.get('enableSharedDisk').setValue(value)
  }

  toggleEmailValidator(event){
   let checked = event.target.checked
   checked ? this.currentForm.get('email').setValidators([Validators.email]) :   this.currentForm.get('email').setValidators(null);
   this.currentForm.get('email').updateValueAndValidity()
  }

 
  /******************************************** Virtual Machine End************************************************************/

  /******************************************** Virtual Network ************************************************************/
  getVNETData(type,data){
    let sub,rgName,vName
    if(type=='subnetCon'){
     sub=data[8]
     rgName=data[2]
     vName=data[1]
       return this.util.handleRequest('get','a3s_management_getVNETData',[sub,rgName,vName],null,null,null,true).then(res=>{
        this.vnetData = res.body
      })
    }
    else{
    this.util.handleRequest('get','a3s_management_getVNETData',[data[2],data[1],data[0]],null,null,null,true).then(res=>{
      this.vnetData = res.body
      switch(type){
        case 'addressSpace':{
          let addressSpaceArray  = res.body.properties.addressSpace.addressPrefixes
          addressSpaceArray.forEach(e=>{
            this.addressSpaceList.push(this.util.getForm('addAddressSpace',{value:e}))
          });
          console.log('Address space Form',this.addressSpaceList)
          break;
        }
        case 'subnet':{
          let subnetArray  = res.body.properties.subnets
          subnetArray.forEach(e=>{
            if(e.name=="GatewaySubnet") return this.showGatewaySubnet= true
          //  this.subnetArrayList.push(this.util.getForm('addSubnet',{name:e.name,ipValue:''}))
          })
          // console.log('Subnet Form',this.subnetArrayList)
          break;
        }
        case 'dnsServer':{
          let  dhcpOptions = res.body.properties.dhcpOptions
          let value = !dhcpOptions || !dhcpOptions.dnsServers.length ? true : false
          this.currentForm.get('dnsServer').setValue(value)
          if(!value){
            let addressSpaceArray  = res.body.properties.dhcpOptions.dnsServers
            addressSpaceArray.forEach(e=>{
              this.addressSpaceList.push(this.util.getForm('addAddressSpace',{value:e}))
            });
          }
          else{
            this.addressSpaceList.push(this.util.getForm('addAddressSpace'))
          }
        }
        case 'ddosProtection':{
          let  isEnable = res.body.properties.enableDdosProtection ? true : false
          this.currentForm.get('ddosProtection').setValue(isEnable)
          isEnable?this.currentForm.get('ddosProtectionPlan').setValue(res.body.properties.ddosProtectionPlan.id):false
        }
        default:{
          break;
        }
      }
      console.log('VNET Data',this.vnetData)
    })
   }
  }

  async saveVNETData(type){
    switch(type){
      case 'addressSpace':{
        let rawValue = this.currentForm.getRawValue()
        // let array  = rawValue.addressSpaceArray.map(e=>{
        //   if(e.value) return e.value
        // })
        let array  = rawValue.addressSpaceArray.filter(e=>{
          if(e.value&&e.value!=='') return e.value
        })
        array= array.map(e=> {return e.value})
        this.vnetData.properties.addressSpace.addressPrefixes = array
        console.log('Raw Form Value',this.currentForm.getRawValue())
        console.log('Request Body',this.vnetData)
        this.UpdateVNETData(this.data,this.vnetData,'addressSpace')
        break;
      }
      case 'subnet':{
        let rawValue = this.currentForm.getRawValue()
        let subnetName=this.currentForm.get('subnetName').value
        // let body=this.vnetData.properties.subnets[0].properties
        let body={
          "name":subnetName,
          "id":`/subscriptions/${this.data[2]}/resourceGroups/${this.data[1]}/providers/Microsoft.Network/virtualNetworks/${this.data[0]}/subnets/${subnetName}`,
          "location":this.data[3],
          "properties":{
            "addressPrefix":this.currentForm.get('subnetAddressSpace').value,
            "natGateway":{},
            "networkSecurityGroup":{},
            "routeTable":{},
            "serviceEndpoints":[],
            "delegations":[]
        }}
        rawValue.natGateway!='None' ? body['properties'].natGateway={"id":rawValue.natGateway}  :delete body['properties'].natGateway
        if(rawValue.nsg!=null){
          rawValue.nsg!='None' ? body['properties'].networkSecurityGroup={"id":rawValue.nsg} : delete body['properties'].networkSecurityGroup
        }
        rawValue.routeTable!='None' ? body['properties'].routeTable={"id":rawValue.routeTable} :delete body['properties'].routeTable
      let serviceName =this.currentForm.get('services').value
      body['properties'].serviceEndpoints=[]
      if(serviceName!=null){
        if(serviceName.length){
          serviceName.forEach((e,i) => {
             body['properties'].serviceEndpoints.push({"service":e})
          });
        }
      }
      let delegate= this.currentForm.get('delegatedSubnet').value
      if(delegate.length && delegate!='None'){
      let checkDelegate=this.dsServiceList.filter(e=>e.serviceName==delegate)
      body['properties'].delegations=[{"id":checkDelegate[0].id,
        "properties":{
          "serviceName":checkDelegate[0].serviceName},
          "name":checkDelegate[0].name,
      }]
      }
      else{body['properties'].delegations=[]}
          
        console.log('Raw Form Value',this.currentForm.getRawValue())
        console.log('Request Body',this.vnetData)
        await this.UpdateSubnetType(this.data,body,'vnetSubnet')
        await this.getVNETData('',this.data)
        // this.createNewSubnet(this.data,body,'vnetSubnet')
        // this.UpdateVNETData(this.data,this.vnetData,'subnet')
        break;
      }
      case 'dnsServer':{
        let rawValue = this.currentForm.getRawValue()
        let array  = rawValue.addressSpaceArray.filter(e=>{
          if(e.value&&e.value!=='') return e.value
        })
        array= array.map(e=> {return e.value})
        this.vnetData.properties.dhcpOptions={"dnsServers" : rawValue.dnsServer? [] : array }// set array empty if changed to default
        console.log('Raw Form Value',this.currentForm.getRawValue())
        console.log('Request Body',this.vnetData)
        this.UpdateVNETData(this.data,this.vnetData,'dnsServer')
        break;
     }
     case 'ddosProtection':{
      let rawValue = this.currentForm.getRawValue()
      let plan =rawValue.ddosProtectionPlan
      this.vnetData.properties.enableDdosProtection = rawValue.ddosProtection
      if(rawValue.ddosProtection){
        this.vnetData.properties.ddosProtectionPlan = {
          id : plan
        }
      }else delete this.vnetData.properties.ddosProtectionPlan
      console.log('Raw Form Value',this.currentForm.getRawValue())
      console.log('Request Body',this.vnetData)
      this.UpdateVNETData(this.data,this.vnetData,'ddosProtection')
      break;
    }
   }

  }

  UpdateVNETData(data,body,type){
    this.util.handleRequest('post','a3s_management_updateVNETData',[data[2],data[1],data[0]],body,null,null,true).then(res=>{
      console.log('VNET Data',res)
      this.showSubSidesheet = false
      this.triggerOutput()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  createNewSubnet(data,body,type){
    this.util.handleRequest('post','a3s_management_createNewSubnet',[data[2],data[1],data[0]],body,null,null,true).then(res=>{
      console.log('VNET Data',res)
      this.showSubSidesheet = false
      this.triggerOutput()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }

  async getAllDDoSProtectionPlan(data,type?:any){
    return this.util.handleRequest('get','a3s_management_getAllDDOSProtectionPlan',[data[2]],null,null,null,true).then(res=>{
      this.ddosProtectionPlanList = res.body.value
      // let loc=data[3]
      // this.ddosProtectionPlanList= this.ddosProtectionPlanList.filter(e=>e.location==loc)
    })
  }

  createDdosProtectionPlan(){
    let rawValue = this.currentSubForm.getRawValue();
    let tagObject={}
    rawValue.tagsArray.forEach(e=>{
      if(e.name) tagObject[e.name] = e.value
    })
    let body= {
     "properties": {
       "mode": "Incremental",
       "templateLink": {
         "uri": "https://a3sarmtemplate.blob.core.windows.net/armtemplates/ddosProtectionPlan.json",
         "contentVersion": "1.0.0.0"
       },
       "parameters": {
            "ddosProtectionPlanName": {
                "value": rawValue.name
            },
            "location": {
              "value":this.data[3]
          },
            "tags": {
                "value": tagObject
            }
       }
     }
    }
    this.util.handleRequest('post','a3s_management_createDDOSProtectionPlan',[this.data[2],this.data[1]],body,null,null,true).then(async res=>{
      this.util.success('DDoS protection created successfully!')
      this.showSubSidesheet=false
      await this.getAllDDoSProtectionPlan(this.data,'newDDoS')
      let planList=this.ddosProtectionPlanList
      let check=planList.filter(e=>e.name==rawValue.name)
      this.currentForm.get('ddosProtectionPlan').setValue(check[0].id)
     },err=>{
       this.util.error('DDoS protection failed to be created.')
     })
     
  }

  checkIPValidation(index,type){
    if(!this.addressSpaceList.controls.length) return;
    let data = this.addressSpaceList.controls.some((e,i)=> {
      if(e.get('value').value!=''){
        let split=e.get('value').value.split('.')
        let inputSplit =this.addressSpaceList.at(index).get('value').value.split('.')
         if(type=='addressSpace')return index !== i && split[1] == inputSplit[1] && split[0] == inputSplit[0]
         if(type=='dnsIP')return index !== i && e.get('value').value == this.addressSpaceList.at(index).get('value').value
      }
    })
    return data 
  }

  editSubnet(data){
   console.log('Current Subnet',data)
   this.currentForm.get('vnetName').setValue(this.data[0])
   this.currentForm.get('subnetName').setValue(data.name)
   this.currentForm.get('subnetName').disable() 
   data.name=='GatewaySubnet'?  this.currentForm.get('nsg').disable():false
   this.currentForm.get('subnetAddressSpace').setValue(data.properties.addressPrefix) 
   !data.properties?.natGateway?  this.currentForm.get('natGateway').setValue('None'):this.currentForm.get('natGateway').setValue(data.properties?.natGateway?.id)
   !data.properties?.routeTable?  this.currentForm.get('routeTable').setValue('None'):this.currentForm.get('routeTable').setValue(data.properties?.routeTable.id)
   !data.properties?.networkSecurityGroup?  this.currentForm.get('nsg').setValue('None'):this.currentForm.get('nsg').setValue(data.properties?.networkSecurityGroup.id)
   data.properties.delegations.length!=0 ?  this.currentForm.get('delegatedSubnet').setValue(data.properties?.delegations[0]?.properties?.serviceName):this.currentForm.get('delegatedSubnet').setValue('None')
   let service=[]
   data.properties.serviceEndpoints.forEach(e => {
     service.push(e.service)
   });
   this.currentForm.get('services').setValue(service)
 
  //  this.currentForm.get('description').setValue()
  }
  async editSubnetList(){
    let raw = this.currentForm.getRawValue()
    await this.getSubnetType('edit',this.data)
    let body = this.subnetData
    body['properties'].addressPrefix=this.currentForm.get('subnetAddressSpace').value
    raw.natGateway!='None' ? body['properties'].natGateway={"id":raw.natGateway}  :delete body['properties'].natGateway
    if(raw.nsg!=null){
      raw.nsg!='None' ? body['properties'].networkSecurityGroup={"id":raw.nsg} : delete body['properties'].networkSecurityGroup
    }
    raw.routeTable!='None' ? body['properties'].routeTable={"id":raw.routeTable} :delete body['properties'].routeTable
  let serviceName =this.currentForm.get('services').value
  body['properties'].serviceEndpoints=[]
  if(serviceName!=null){
    if(serviceName.length){
      serviceName.forEach((e,i) => {
         body['properties'].serviceEndpoints.push({"service":e})
      });
    }
  }
  let delegate= this.currentForm.get('delegatedSubnet').value
  if(delegate.length && delegate!='None'){
  let checkDelegate=this.dsServiceList.filter(e=>e.serviceName==delegate)
    body['properties'].delegations=[{"id":checkDelegate[0].id,
    "properties":{
      "serviceName":checkDelegate[0].serviceName},
      "name":checkDelegate[0].name,
  }]
  }
  else{body['properties'].delegations=null}
  // this.currentForm.updateValueAndValidity()
     this.UpdateSubnetType(this.data,body,'edit')
  }

  removeSubnet(index){
    this.vnetData.properties.subnets.splice(index,1)
    // this.UpdateVNETData(this.data,this.vnetData,'')
  }

  addAddressSpace(){
    this.addressSpaceList.push(this.util.getForm('addAddressSpace'))
  }
  removeAddressSpace(index) {
    this.addressSpaceList.controls.splice(index, 1);
  }

  addTag(){
    this.tagsList.push(this.util.getForm('addTags'))
  }
  
  removeTag(index) {
    this.tagsList.controls.splice(index, 1);
  }

  setDdosProtection(type){
    let value
    if(type == 'disable'){
      value = false
      this.currentForm.get('ddosProtectionPlan').setValidators(null)
    }
    else{
      value = true
      this.currentForm.get('ddosProtectionPlan').setValidators([Validators.required])
    }
    this.currentForm.get('ddosProtectionPlan').updateValueAndValidity()
    this.currentForm.get('ddosProtection').setValue(value)
  }

  setDnsServer(type){
    let value = type == 'default' ? true : false
    this.saveDisable=true
    this.currentForm.get('dnsServer').setValue(value)
    this.warningMessage=true
  }
  setIpForwarding(type){
    this.saveDisable=true
    let value = type != 'disabled' ? true : false
    this.currentForm.get('ipForwarding').setValue(value)
  }
  
  setRadioBtn(data,type){
    this.subSideSaveDisable=true
    switch(type){
      case 'type':{
        let value = data == 'primary' ? true : false
        this.currentForm.get('type').setValue(value)
        break;
      }
      case 'allocation':{
        let value = data == 'dynamic' ? 'Dynamic' : 'Static'
        this.currentForm.get('allocation').setValue(value)
        break;
      }
      case 'publicIPAddress':{
        let value = data == 'associate' ? true : false
        this.currentForm.get('publicIPAddress').setValue(value)
        this.warningMessage=value==true?true:false
        break;
      }
      case 'sku':{
        let value = data == 'basic' ? 'Basic' : 'Standard'
        this.currentForm.get('sku').setValue(value)
        if(value=='Standard'){
          this.currentForm.get('assigment').setValue('Static')
        }
        break;
      }
      case 'assigment':{
        let value = data == 'dynamic' ? 'Dynamic' : 'Static'
        this.currentForm.get('assigment').setValue(value)
        break;
      }
    }
  }
  onToggle(type){
    switch(type){
      case 'publicIP' : {
        return this.showpublicIP = !this.showpublicIP
      }
    }
  }
  toggle(){
    $('#toggleContent').toggleClass('open')
    $('#toggleIcon').toggleClass('after')
  }

  getToggleText(){

    return $('#toggleContent').hasClass('open') ? 'Hide advanced settings':'View advanced settings (Optional)'
  }
  /******************************************** Virtual Network End ************************************************************/
 
  /******************************************** Common ************************************************************/

  action(data,type){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteInbound': return this.openModal(this.deleteConfirmPopup,data,'inbound')
      case 'deleteOutbound': return this.openModal(this.deleteConfirmPopup,data,'outbound')
      case 'deleteIP': return this.openModal(this.deleteConfirmPopup,data,'deleteIP')
      case 'dissociateSubnet': return this.openModal(this.dissociateConfirmPopup,data,'dissociateSubnet')
      case 'dissociateNic': return this.openModal(this.dissociateConfirmPopup,data,'dissociateNic')
      case 'dissociatePublicIp': return this.openModal(this.dissociateConfirmPopup,data,'dissociateNICPublicIp')
    }
  }

  getCurrentResourceTitle(){
    switch(this.selectedType){
      case 'inbound' : return 'inbound security rules';
      case 'outbound' : return 'outbound security rules';
      case 'dissociateSubnet': return 'Subnet'
      case 'dissociateNic': return 'Network Interface'
      case 'deleteIP': return 'IP Configuration'
    }
   }
   getResourceName(){
  switch(this.selectedType){
    case 'deleteIP': return this.selectedRow.name
    case 'inbound': return this.selectedRow.name
    case 'outbound': return this.selectedRow.name
    case 'dissociateSubnet': return this.selectedRow.subnetName
    case 'dissociateNic': return this.selectedRow.nicName
    case 'dissociateNICPublicIp': return this.selectedRow
    default: return this.data[0]
  }

  }

   deleteAction(){
    switch(this.selectedType){
      case 'inbound' :{
        let index = this.nsgData.properties.securityRules.findIndex(e=> e.name == this.selectedRow.name)
        this.nsgData.properties.securityRules.splice(index,1)
        this.UpdateNSGData(this.data,this.nsgData,'deleteInbound')
        break;
      }
      case 'outbound' : {
        let index = this.nsgData.properties.securityRules.findIndex(e=> e.name == this.selectedRow.name)
        this.nsgData.properties.securityRules.splice(index,1)
        this.UpdateNSGData(this.data,this.nsgData,'deleteOutbound')
        break;
      }
      case 'deleteIP' : {
        let index = this.nicData.properties.ipConfigurations.findIndex(e=> e.name == this.selectedRow.name)
        this.nicData.properties.ipConfigurations.splice(index,1)
        this.UpdateNICData(this.data,this.nicData,'deleteIP')
        break;
      }
      // case 'deleteVNetSubnet' : {
      //   let index = this.nicData.properties.ipConfigurations.findIndex(e=> e.name == this.selectedRow.name)
      //   this.nicData.properties.ipConfigurations.splice(index,1)
      //   this.UpdateNICData(this.data,this.nicData,'deleteIP')
      //   break;
      // }
    }
   }
   checkRT(){
     this.currentForm.get('resourceType').value== 1? this.associate('publicIPAddressNIC'):this.associate('publicIPAddressLB')
   }
  
   async associate(type){
     switch (type) {
       case 'nsgSubnet':{
        this.getListByPropName('virtualNetworks');
        this.getListByPropName('subnetByVN');
        // this.getSubnetList('associateSubnetNSG',this.data)
        break;
       }
       case 'publicIPAddressNIC':{
         this.getNICList('associateNIC',this.data);
        break;
        }
        case 'publicIPAddressLB':{
          await this.getPublicIPAddress('associateIp',this.data)
          await this.getLoadBalancerList('associateNIC',this.data);
         break;
         }
        //  case 'publicIPAddress':{
        //   this.getNICList('associateNIC',this.data);
        //   this.getLoadBalancerList('associateNIC',this.data);
        //  break;
        //  }
        case 'nsgAssociate':{
          await this.getNICList('associateNICNSG',this.data);
          this.filterNICList('associateNICNSG')
         break;
         }    
     }

   }

   async dissociateAction(){
    switch(this.selectedType){
      case 'dissociateSubnet': {
        await this.getSubnetData('dissociateSubnet',this.data,this.selectedRow)
        delete this.subnetData.properties.networkSecurityGroup
        let index =  this.nsgData?.properties?.subnets.findIndex(e=> e.name == this.selectedRow.subnetName)
        this.nsgData?.properties?.subnets.splice(index,1)
        this.UpdateSubnetData(this.data,this.subnetData,'dissociateSubnet',this.selectedRow)
        break;
      }
      case 'dissociateNic': {
        await this.getNICData('dissociateNic',this.data,this.selectedRow)
        delete this.nicData.properties.networkSecurityGroup
        let index =  this.nsgData?.properties?.networkInterfaces.findIndex(e=> e.name == this.selectedRow.nicName)
        this.nsgData?.properties?.networkInterfaces.splice(index,1)
        this.UpdateNICData(this.data,this.nicData,'dissociateNic',this.selectedRow)
        break;
      }
      case 'dissociateNICPublicIp': {
        // await this.getPublicIPAddress('dissociateNic',this.data)
        let nicName = this.PublicIPData.properties.ipConfiguration.id.split('/')[8]
        let rgName = this.PublicIPData.properties.ipConfiguration.id.split('/')[4]
        await this.fetchNICConfig(this.data[2],rgName,nicName)
        delete this.nicData.properties.ipConfigurations[0].properties.publicIPAddress
        await this.updateNICPublicIP(this.data[2],this.nicData,rgName,nicName,'dissociate')
        this.closeModal()
        break;
      }
    }
   }

  /******************************************** NSG ************************************************************/
  async getNSGData(type,data){
    return this.util.handleRequest('get','a3s_management_getNSGData',[data[2],data[1],data[0]],null,null,null,true).then(res=>{
      this.nsgData = res.body
      let sorted = this.nsgData.properties.securityRules
      sorted.sort((a,b)=>{
             let x = a?.properties?.priority;
             let y = b?.properties?.priority;
             return x-y
           })
      this.nsgData.properties.securityRules = sorted
      switch(type){
        case 'networkInterface':{
          this.nsgData?.properties?.networkInterfaces.forEach(e=>{
            e['nicName']= e.id.split('/')[8]
          })
          break;
        }
        case 'nsgSubnet':{
          this.nsgData.properties.subnets.forEach(async e=>{
            let subsId = e.id.split('/')[2]
            let rgName = e.id.split('/')[4]
            e['vnetName']= e.id.split('/')[8]
            e['subnetName']= e.id.split('/')[10]
            await this.util.handleRequest('get','a3s_management_getSubnetData',[subsId,rgName, e['vnetName'],e['subnetName']],null,null,null,true).then(res=>{
            this.subnetData = res.body
            })
            e['addressRange'] = this.subnetData.properties.addressPrefix
          })
          break;
        }
      }
      console.log('NSG Data',this.nsgData)
    })
  }
  getTableArraydata(data,type){
    switch(type) {
      case'source':{if(data?.properties?.sourceAddressPrefix){
        return data?.properties?.sourceAddressPrefix=='*'?'Any': data?.properties?.sourceAddressPrefix}
        else if(data?.properties?.sourceAddressPrefixes.length){ return data?.properties?.sourceAddressPrefixes.join(', ')}
      }
      case'destination':{if(data?.properties?.destinationAddressPrefix){
        return data?.properties?.destinationAddressPrefix=='*'?'Any': data?.properties?.destinationAddressPrefix}
        else if(data?.properties?.destinationAddressPrefixes.length){ return data?.properties?.destinationAddressPrefixes.join(', ')}
      }
      case'sourcePort':{if(data?.properties?.sourcePortRange){
        return data?.properties?.sourcePortRange=='*'?'Any': data?.properties?.sourcePortRange}
        else if(data?.properties?.sourcePortRanges.length){ return data?.properties?.sourcePortRanges.join(', ')}
      }
    }
  }
  async saveNSGRuleData(type){
    switch(type){
      case 'outbound':
      case 'inbound':{
        let rawValue = this.currentForm.getRawValue()
        let sourceIP=rawValue.source=='IP addresses'?rawValue.sourceIP.split(','):false
        let destinationIP=rawValue.destination=='IP addresses'?rawValue.destinationIP.split(','):false
        let sourcePort=rawValue.sourcePort.split(',')
        let destinationPort=rawValue.destinationPort.split(',')
        let body ={
                "name": rawValue.name,
                "properties": {
                  "protocol":rawValue.protocol,
                  "destinationAddressPrefix": rawValue.destination=="VirtualNetwork"?rawValue.destination:'*',
                  "sourceAddressPrefix": rawValue.source=='Any'? '*':rawValue.source,
                  "access": rawValue.action,
                  "destinationPortRange": rawValue.destinationPort=='Any'?'*':rawValue.destinationPort,
                  "sourcePortRange": rawValue.sourcePort=='Any'?'*':rawValue.sourcePort,
                  "priority": rawValue.priority,
                  "direction": this.currentSecurityRules == 'inbound' ? "Inbound" : "Outbound",
                  "description":rawValue.description,
                  "sourceAddressPrefixes":[],
                  "destinationAddressPrefixes":[],
                  "destinationPortRanges":[],
                  "sourcePortRanges":[]
                }
              }
              if(rawValue.source=='IP addresses' && sourceIP.length==1){
                body['properties'].sourceAddressPrefix = rawValue.sourceIP;
              }
              else if(rawValue.source=='IP addresses'&&  sourceIP.length!=1){
                body['properties'].sourceAddressPrefixes = sourceIP;
                body['properties'].sourceAddressPrefix = '';
              }
              if(rawValue.destination=='IP addresses'  && destinationIP.length==1){
                body['properties'].destinationAddressPrefix = rawValue.destinationIP=='Any'?'*':rawValue.destinationIP;
              }
              else if(rawValue.destination=='IP addresses'&& destinationIP.length!=1){
                body['properties'].destinationAddressPrefixes = destinationIP;
                body['properties'].destinationAddressPrefix = '';
              }
              if(sourcePort.length==1){
                body['properties'].sourcePortRange = rawValue.sourcePort=='Any'?'*':rawValue.sourcePort;
              }
              else if(sourcePort.length!=1){
                body['properties'].sourcePortRanges = sourcePort;
                body['properties'].sourcePortRange = '';
              }
              if(destinationPort.length==1){
                body['properties'].destinationPortRange = rawValue.destinationPort=='Any'?'*':rawValue.destinationPort;
              }
              else if(destinationPort.length!=1){
                body['properties'].destinationPortRanges = destinationPort;
                body['properties'].destinationPortRange = '';
              }
              await this.UpdateNSGRuleData(this.data,body,type)
              await this.util.delay(1000)
              await this.getNSGData('',this.data)
              break;
        }
        // this.UpdateNSGData(this.data,body,type)
      
      }
   }

  UpdateNSGData(data,body,type){
   
    this.util.handleRequest('post','a3s_management_updateNSGData',[data[2],data[1],data[0]],body,null,null,true).then(res=>{
      console.log('NSG Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  UpdateNSGRuleData(data,body,type){
    let ruleName = body.name
    this.util.handleRequest('post','a3s_management_updateNSGRuleData',[data[2],data[1],data[0],ruleName],body,null,null,true).then(res=>{
      console.log('NSG Rule Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  portRangeValidation(event,limit,flag){
    let value=event.target.value;
    
    this.inputError[flag+'InvalidPortRange']=false; 
    this.inputError[flag+'Overlap']=false;
    this.inputError[flag+'NotValidPortVal']=false;
    if(value&&value!=""){
      if(value.includes(',-')||value.includes('-,')){
        this.inputError[flag+'NotValidPortVal']=true;
        return;
      }else if(value[value.length-1]==","||value[value.length-1]=="-"){
        this.inputError[flag+'InvalidPortRange']=true;
      }else if(value!="*"&&(!(/^[0-9,-]*$/).test(value))){
        this.inputError[flag+'NotValidPortVal']=true;
      }else if(value>65535 || value<0){
        this.inputError[flag+'NotValidPortVal']=true;
      }
      else{
        let splitArr=value.split(',');
        if(splitArr.length==1){
          let splitArr1=splitArr[0].split('-');
          if(splitArr1.length>1&&(!splitArr1[1]||parseInt(splitArr1[0])>parseInt(splitArr1[1])||splitArr1[0]==splitArr1[1])){
            //Invalid port range specified
            this.inputError[flag+'InvalidPortRange']=true;
            return;
          }else if(splitArr1.length==1&&parseInt(splitArr1[0])>limit){
            this.inputError[flag+'InvalidPortRange']=true;
            return;
          }else if(splitArr1.length>1&&splitArr1[1]&&parseInt(splitArr1[1])>limit){
            this.inputError[flag+'InvalidPortRange']=true;
            return;
          }
        }else if(splitArr.length>1&&!splitArr[1]){
          //Invalid port range specified
          this.inputError[flag+'InvalidPortRange']=true;
          return;
        }else {
          let tempList=[];
          for(let i=0;i<splitArr.length;i++){
            let splitArr1=splitArr[i].split('-');
            if(splitArr1.length>1&&(!splitArr1[1]||parseInt(splitArr1[0])>parseInt(splitArr1[1])||splitArr1[0]==splitArr1[1])){
              //Invalid port range specified
              this.inputError[flag+'InvalidPortRange']=true;
              break;
            }else {//if(splitArr1.length==1){
             this.inputError[flag+'InvalidPortRange']=false; 
             this.inputError[flag+'Overlap']=false;
              for(let j=0;j<tempList.length;j++){
                if(!(tempList[j] instanceof Array)){
                if(splitArr1[0]==tempList[j]){
                  this.inputError[flag+'Overlap']=true;
                  break;
                }else if(splitArr1[1]==tempList[j]){
                  this.inputError[flag+'Overlap']=true;
                  break;
                }else if(parseInt(splitArr1[0])<parseInt(tempList[j])&&splitArr1[1]&&parseInt(splitArr1[1])>parseInt(tempList[j])){
                  this.inputError[flag+'Overlap']=true;
                  break;
                }
              }else if((tempList[j] instanceof Array)){
                  if(splitArr1[0]==tempList[j][0]||(tempList[j][1]&&splitArr1[0]==tempList[j][1])){
                  this.inputError[flag+'Overlap']=true;
                  break;
                  }else if(splitArr1[1]&&(splitArr1[1]==tempList[j][0]||(tempList[j][1]&&splitArr1[1]==tempList[j][1]))){
                  this.inputError[flag+'Overlap']=true;
                  break;
                  }else if(splitArr1[0]&&parseInt(splitArr1[0])>parseInt(tempList[j][0])&&parseInt(splitArr1[0])<parseInt(tempList[j][1])){
                  this.inputError[flag+'Overlap']=true;
                  break;
                  }else if(splitArr1[1]&&parseInt(splitArr1[1])<parseInt(tempList[j][0])&&parseInt(splitArr1[1])>parseInt(tempList[j][1])){
                  this.inputError[flag+'Overlap']=true;
                  break;
                  }
                  else if(splitArr1[1]>tempList[j][0]&&splitArr1[1]<tempList[j][1]){
                    this.inputError[flag+'Overlap']=true;
                    break;
                  }
                  else if(splitArr1[0]>tempList[j][0]&&splitArr1[1]<tempList[j][1]){
                    this.inputError[flag+'Overlap']=true;
                    break;
                  }
                  else{
                    this.inputError[flag+'Overlap']=false;
                  }
                }else{
                  this.inputError[flag+'Overlap']=false;
                }
              }
              if((splitArr1[0]&&parseInt(splitArr1[0])>limit)||(splitArr1[1]&&parseInt(splitArr1[1])>limit)){
                this.inputError[flag+'NotValidPortVal']=true;
              }
            tempList.push(splitArr1.length>1?splitArr1:splitArr1[0]);
            if(this.inputError[flag+'Overlap']||this.inputError[flag+'InvalidPortRange']||this.inputError[flag+'NotValidPortVal']){
               return false;
             }
            }

          }
        }
        this.inputError[flag+'NotValidPortVal']=false;
      }
    }

  }
  invalidForm(type){
    switch(type){
      case'inOutBound':{
        return this.inputError.sourceInvalidArgument||this.inputError.sourceInvalidPortRange||this.inputError.sourceNotValidCIDR||this.inputError.sourceNotValidPortVal
        ||this.inputError.sourceOverlaping||this.inputError.sourceOverlap || this.inputError.destInvalidArgument||this.inputError.destInvalidPortRange
        ||this.inputError.destNotValidCIDR||this.inputError.destNotValidPortVal ||this.inputError.destOverlaping||this.inputError.destOverlap
        ||this.inputError.VMPriorityValid||this.inputError.VMPriorityMaxMin ||this.inputError.VMPriorityDupCheck||this.inputError.nameDupCheck;
      }
      case'vnetSubnet':{
        return this.inputError.ipNameMaxLen||this.inputError.ipNameExist||this.inputError.ipNameValid||this.inputError.subnetAdrspc
        ||this.inputError.subnetNotValidCIDR||this.inputError.subnetOverlaping || this.inputError.subnetPerfix||this.inputError.subnetAddresNotInVnet;
      }
      case'nicIP':{
        return this.inputError.ipNameMaxLen||this.inputError.ipNameExist||this.inputError.ipNameValid||this.inputError.dnsError||this.inputError?.ipNameLastValid;
      }
    }
  }
  validNICAddressSpace(event,flag){
    let value=event.target.value;
    this.inputError[flag+'InvalidArgument']=false;//sourceInvalidArgument
    this.inputError[flag+'NotValidCIDR']=false;//sourceNotValidCIDR
    this.inputError[flag+'Overlaping']=false;//sourceOverlaping
    this.inputError[flag+'NonNullRange']=false;

    if(value&&value!=""){
    const splitArComma=value.split(',');
    let ipV4Value;
    let tempArr=[];
    for(let i=0;i<splitArComma.length;i++){
      if(!splitArComma[i]){
       // let inputError="Invalid argument: 'A non-null address range is required.'";
        this.inputError[flag+'NonNullRange']=true;
        break;
      }else{
        const splitArr=splitArComma[i].split('/');
        if(splitArr.length>1&&!splitArr[1]){
          //Maliform
          this.inputError[flag+'NotValidCIDR']=true;
        }else{
          ipV4Value=splitArr[0];
        }
        
        try{
          let ipV4Obj=new this.ipV4(ipV4Value);
         // item.VNetAdrspc=false;
          let vlIPRange;
          if(splitArr.length>1){
          try{
            vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
            //item.VNetAdrspc=false;
            if(vlIPRange.input!=splitArComma[i]){
              //item.notValidCIDR=true;
              //item.validCIDRValue=vlIPRange.input;
              this.inputError[flag+'NotValidCIDR']=true;
              break;
            }else{
              //item.notValidCIDR=false;
              //item.validCIDRValue=null;
              for(let t=0;t<tempArr.length;t++){
                if(tempArr[t].includes("/")){
                  let split2=tempArr[t].split('/');
                  let range=new this.ipSubnetWork(new this.ipV4(split2[0]),split2[1]);
                  if(splitArr.length==1){
                    let splitIPv4=ipV4Value.split('.');
                    if(ipV4Value==range.input){
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }
                    if(splitIPv4[0]>range.range.left.parts[0]&&splitIPv4[0]<range.range.right.parts[0]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]>range.range.left.parts[1]&&splitIPv4[1]<range.range.right.parts[1]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]>range.range.left.parts[2]&&splitIPv4[2]<range.range.right.parts[2]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]==range.range.left.parts[2]&&splitIPv4[3]>range.range.left.parts[3]&&splitIPv4[3]<range.range.right.parts[3]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }
                  }else{
                    if(vlIPRange.input==range.input){
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }
                    if(range.range.left.parts[0]>vlIPRange.range.left.parts[0]&&range.range.right.parts[0]<vlIPRange.range.right.parts[0]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]>vlIPRange.range.left.parts[1]&&range.range.right.parts[1]<vlIPRange.range.right.parts[1]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]>vlIPRange.range.left.parts[2]&&range.range.right.parts[2]<vlIPRange.range.right.parts[2]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]==vlIPRange.range.left.parts[2]&&range.range.left.parts[3]>vlIPRange.range.left.parts[3]&&range.range.right.parts[3]<vlIPRange.range.right.parts[3]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }
                  }
                }else if(vlIPRange&&(vlIPRange.range.left.input==tempArr[t]||vlIPRange.range.right.input==tempArr[t])){
                  //overlapping
                  this.inputError[flag+'Overlaping']=true;
                  break;
                }else if(splitArr.length==1&&(ipV4Value==tempArr[t])){
                  //overlapping
                  this.inputError[flag+'Overlaping']=true;
                  break;
                }
              }
              if(this.inputError[flag+'Overlaping']){
                return false;
              }
              tempArr.push(splitArComma[i]);
            }
          }catch(e){
            //item.VNetAdrspc=true;
            this.inputError[flag+'NotValidCIDR']=true;
            break;
          }
        }else{
          for(let t=0;t<tempArr.length;t++){
                if(tempArr[t].includes("/")){
                  let split2=tempArr[t].split('/');
                  let range=new this.ipSubnetWork(new this.ipV4(split2[0]),split2[1]);
                  if(splitArr.length==1){
                    let splitIPv4=ipV4Value.split('.');
                    if(ipV4Value==range.input){
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }
                    if(splitIPv4[0]>range.range.left.parts[0]&&splitIPv4[0]<range.range.right.parts[0]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]>range.range.left.parts[1]&&splitIPv4[1]<range.range.right.parts[1]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]>range.range.left.parts[2]&&splitIPv4[2]<range.range.right.parts[2]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]==range.range.left.parts[2]&&splitIPv4[3]>range.range.left.parts[3]&&splitIPv4[3]<range.range.right.parts[3]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }
                  }else{
                    if(vlIPRange.input==range.input){
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }
                    if(range.range.left.parts[0]>vlIPRange.range.left.parts[0]&&range.range.right.parts[0]<vlIPRange.range.right.parts[0]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]>vlIPRange.range.left.parts[1]&&range.range.right.parts[1]<vlIPRange.range.right.parts[1]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]>vlIPRange.range.left.parts[2]&&range.range.right.parts[2]<vlIPRange.range.right.parts[2]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]==vlIPRange.range.left.parts[2]&&range.range.left.parts[3]>vlIPRange.range.left.parts[3]&&range.range.right.parts[3]<vlIPRange.range.right.parts[3]){
                      //overlapping
                      this.inputError[flag+'Overlaping']=true;
                      break;
                    }
                  }
                }else if(vlIPRange&&(vlIPRange.range.left.input==tempArr[t]||vlIPRange.range.right.input==tempArr[t])){
                  //overlapping
                  this.inputError[flag+'Overlaping']=true;
                  break;
                }else if(splitArr.length==1&&(ipV4Value==tempArr[t])){
                  //overlapping
                  this.inputError[flag+'Overlaping']=true;
                  break;
                }
              }
              if(this.inputError[flag+'Overlaping']){
                return false;
              }
          tempArr.push(ipV4Value);
        }        
        }catch(e){
       //   item.VNetAdrspc=true;
       this.inputError[flag+'InvalidArgument']=true;
          break;
        }
      }
    }
  }
  }
  changeVmService(){
    if(this.currentForm.get('service').value=='HTTP'){
      this.currentForm.get('destinationPort').setValue('80');
      this.currentForm.get('protocol').setValue('TCP');
      // this.vmInboundRule.disableBasedOnService=true;
    }else if(this.currentForm.get('service').value=='HTTPS'){
      this.currentForm.get('destinationPort').setValue('443');
      this.currentForm.get('protocol').setValue('TCP');
   
    }else if(this.currentForm.get('service').value=='SSH'){
      this.currentForm.get('destinationPort').setValue('22');
      this.currentForm.get('protocol').setValue('TCP');

    }else if(this.currentForm.get('service').value=='RDP'){
      this.currentForm.get('destinationPort').setValue('3389');
      this.currentForm.get('protocol').setValue('TCP');
   
    }else if(this.currentForm.get('service').value=='MS SQL'){
      this.currentForm.get('destinationPort').setValue('1433');
      this.currentForm.get('protocol').setValue('TCP');

    }else if(this.currentForm.get('service').value=='MySQL'){
      this.currentForm.get('destinationPort').setValue('3306');
      this.currentForm.get('protocol').setValue('TCP');
  
    }else if(this.currentForm.get('service').value=='DNS(TCP)'||this.currentForm.get('service').value=='DNS(UDP)'){
      this.currentForm.get('destinationPort').setValue('53');
      this.currentForm.get('protocol').setValue('TCP');

    }else if(this.currentForm.get('service').value=='Custom'){
      this.currentForm.get('destinationPort').setValue('8080');
      this.currentForm.get('protocol').setValue('*');

    }else{
      this.currentForm.get('destinationPort').setValue(undefined);
      this.currentForm.get('protocol').setValue('*');
      // this.vmInboundRule.disableBasedOnService=false;
    }
  }
  validateVMPriority(event){
    let value=event.target.value;
    let existNsgData = this.nsgData.properties.securityRules
    this.inputError.VMPriorityValid=false;
    this.inputError.VMPriorityMaxMin=false;
    this.inputError.VMPriorityDupCheck=false;
    if(value){
      if(!(/^[0-9]*$/).test(value)){
        this.inputError.VMPriorityValid=true;
        return;
      }else if(parseInt(value)<100||parseInt(value)>4096){
        this.inputError.VMPriorityMaxMin=true;
      }
      let inboundCheck =existNsgData.filter(e=>e.properties.direction=='Inbound')
       if(inboundCheck && inboundCheck.length && this.currentSecurityRules=='inbound'){
        
        let findObj=inboundCheck.find((dt,index)=>dt.properties.priority==value);
        if(findObj){
          this.inputError.VMPriorityDupCheck=true;
          // return;
        }
        let name=this.currentForm.get('name').value
        let findname=inboundCheck.find((dt,index)=>dt.name==name);
        if(value==findname.properties.priority){
          this.inputError.VMPriorityDupCheck=false;
        }

      }
      let outnboundCheck =existNsgData.filter(e=>e.properties.direction=='Outbound')
      if(outnboundCheck && outnboundCheck.length && this.currentSecurityRules=='outbound'){
        let findObj=outnboundCheck.find((dt,index)=>dt.properties.priority==value);
        if(findObj){
          this.inputError.VMPriorityDupCheck=true;
          return;
        }
      }
   
      // if(this.currentResourceDtls&&this.currentResourceDtls.vmInBoundRule&&this.vmInboundRule.type=='inboundRules'){
      //   let findObj=this.currentResourceDtls.vmInBoundRule.find((dt,index)=>dt.priority==value&&this.currentResourceDtls.updateIndex!=index);
      //   if(findObj){
      //     this.inputError.VMPriorityDupCheck=true;
      //     return;
      //   }
      // }
      // if(this.currentResourceDtls&&this.currentResourceDtls.vmOutBoundRule&&this.vmInboundRule.type=='outboundRules'){
      //   let findObj=this.currentResourceDtls.vmOutBoundRule.find((dt,index)=>dt.priority==value&&this.currentResourceDtls.updateIndex!=index);
      //   if(findObj){
      //     this.inputError.VMPriorityDupCheck=true;
      //     return;
      //   }
      // }
    }
  }
  validateInOutBoundName(event,limit){
    let value=event.target.value;
    let existNsgData = this.nsgData.properties.securityRules
    this.inputError.nameLen=false;
    this.inputError.nameValid=false;
    this.inputError.nameDupCheck=false;
    if(value){
      if(value.length>limit){
        this.inputError.nameLen=true;
        return;
      }
      if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
        this.inputError.nameValid = false;
      }
      else{
        this.inputError.nameValid = true;
        return;
      }
  
      for(let input in value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
          this.inputError.nameValid = true;
          return;
        }
      }
      let findObj=existNsgData.find((dt,index)=>dt.name==value)
              if(findObj){
                this.inputError.nameDupCheck=true;
                return;
              }
      // let inboundCheck =existNsgData.filter(e=>e.properties.direction=='Inbound')
    
      //     if(inboundCheck&&inboundCheck.length&&this.currentSecurityRules=='inbound'){
      //         let findObj=inboundCheck.find((dt,index)=>dt.name==value)
      //         if(findObj){
      //           this.inputError.nameDupCheck=true;
      //           return;
      //         }
      //       }
      // let outnboundCheck =existNsgData.filter(e=>e.properties.direction=='Outbound')
      //     if(outnboundCheck&&outnboundCheck.length&&this.currentSecurityRules=='outbound'){
      //         let findObj=outnboundCheck.find((dt,index)=>dt.name==value)
      //         if(findObj){
      //           this.inputError.nameDupCheck=true;
      //           return;
      //         }
      //       }
    }
  }
  VmDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMdescription = true;
    }
    else this.inputError.VMdescription = false;
  }
  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }
  isCharANumber(char){
    return (/[0-9]/).test(char);
  }
  setDefaultValue(){
    this.resetValidation()
   let sorted =_.cloneDeep(this.nsgData.properties.securityRules) 
   sorted.sort((a,b)=>{
          let x = a?.properties?.priority;
          let y = b?.properties?.priority;
          return y-x
        })
    let prio= sorted.length ? sorted[0].properties?.priority + 10 :100
    this.currentForm.get('source').setValue('Any')
    this.currentForm.get('destination').setValue('Any')
    this.currentForm.get('sourcePort').setValue('*')
    this.currentForm.get('destinationPort').setValue('8080')
    this.currentForm.get('service').setValue('Custom')
    this.currentForm.get('action').setValue('Allow')
    this.currentForm.get('protocol').setValue('*')
    this.currentForm.get('priority').setValue(prio)
    this.currentForm.get('name').setValue('Port_8080')
    sorted.forEach(e=>{e.name=='Port_8080'? this.currentForm.get('name').setValue(''):false})
  }
  editSecurityRules(list,name){
    console.log('currentList',list),
    this.resetValidation()
    if(list.properties.sourceAddressPrefix=='VirtualNetwork'){
      this.currentForm.get('source').setValue('VirtualNetwork')
    }
    else if(list.properties.sourceAddressPrefix=='*'){
      this.currentForm.get('source').setValue('Any')
      this.currentForm.get('sourceIP').setValue('*')
     }
   else{
    this.currentForm.get('source').setValue('IP addresses')
    this.currentForm.get('sourceIP').setValue(list.properties.sourceAddressPrefix == '*' ? 'Any' :list.properties.sourceAddressPrefix)
    list?.properties?.sourceAddressPrefixes.length?this.currentForm.get('sourceIP').setValue(list?.properties?.sourceAddressPrefixes.join(',')):false
   }
    if(list.properties.destinationAddressPrefix=='VirtualNetwork'){
      this.currentForm.get('destination').setValue('VirtualNetwork')
    }
    else if(list.properties.destinationAddressPrefix=='*'){
      this.currentForm.get('destination').setValue('Any')
      this.currentForm.get('destinationIP').setValue('*')
     }
   else{
    this.currentForm.get('destination').setValue('IP addresses')
    this.currentForm.get('destinationIP').setValue(list.properties.destinationAddressPrefix == '*' ? 'Any' :list.properties.destinationAddressPrefix)
    list?.properties?.destinationAddressPrefixes.length?this.currentForm.get('destinationIP').setValue(list?.properties?.destinationAddressPrefixes.join(',')):false
  }
    this.currentForm.get('priority').setValue(list.properties.priority == '*' ? 'Any' :list.properties.priority)
    this.currentForm.get('name').setValue(list.name)
    this.currentForm.get('sourcePort').setValue(list.properties.sourcePortRange ? list.properties.sourcePortRange:list?.properties?.sourcePortRanges.join(','))
    this.currentForm.get('destinationPort').setValue(list.properties.destinationPortRange?list.properties.destinationPortRange:list?.properties?.destinationPortRanges.join(','))
    this.currentForm.get('protocol').setValue(list.properties.protocol == '*' ? '*' :list.properties.protocol)
    this.currentForm.get('action').setValue(list.properties.access)
    if(this.currentForm.get('destinationPort').value==80){
      this.currentForm.get('service').setValue('HTTP');
      // this.vmInboundRule.disableBasedOnService=true;
    }else if(this.currentForm.get('destinationPort').value==443){
      this.currentForm.get('service').setValue('HTTPS');
   
    }else if(this.currentForm.get('destinationPort').value==22){
      this.currentForm.get('service').setValue('SSH');

    }else if(this.currentForm.get('destinationPort').value==3389){
      this.currentForm.get('service').setValue('RDP');
   
    }else if(this.currentForm.get('destinationPort').value==1433){
      this.currentForm.get('service').setValue('MS SQL');

    }else if(this.currentForm.get('destinationPort').value==3306){
      this.currentForm.get('service').setValue('MySQL');
  
    }else if(this.currentForm.get('destinationPort').value==53){
      this.currentForm.get('service').setValue('DNS(TCP)');
    }else{
      this.currentForm.get('service').setValue('Custom');
    }
    this.openNormalModal(this.addSecurityRulesPopupTemplate,name,true)
  }

  setProtocol(value){
    this.currentForm.get('protocol').setValue(value)
  }

  setAction(value){
    this.currentForm.get('action').setValue(value)
  }

  getSecurityRulesHeader(){
    switch (this.currentSecurityRules) {
      case 'inbound': return this.isSecurityRulesEdit ? 'Inbound Security Rules' : 'Add Inbound Security Rules' ;
      case 'outbound': {
        this.vmSourceList.length==2?this.vmSourceList.splice(2,0,{name:'VirtualNetwork'}):false
        this.destinationList.length==3?this.destinationList.splice(2,1):false
        return this.isSecurityRulesEdit ? 'Outbound Security Rules' : 'Add Outbound Security Rules'} ;
    }
  }

  saveNSGNICAssociate(){
    let data = this.nicData
    let splitted =data.id.split('/')
    let subId= splitted[2]
    let rgName= splitted[4]
    let nicName= splitted[8]
   
   switch(data.type){
       case 'Microsoft.Network/networkInterfaces':
        data['properties'].networkSecurityGroup =  { 
              "id": `/subscriptions/${subId}/resourceGroups/${this.data[1]}/providers/Microsoft.Network/networkSecurityGroups/${this.data[0]}`
          }, 
       this.updateNICPublicIP(subId,data,rgName,nicName,'associate');break
    }
  }
 
  getSubnetList(data){
    let subsId = this.data[2]
    let rgName = data.id.split('/')[4] 
    this.vnetRgName= rgName
    let vnetName = data.name
    this.util.handleRequest('get','a3s_management_getSubnetListData',[subsId,rgName,vnetName],null,null,null,true).then(res=>{
      this.existingSubnetList = res.body.value
        this.existingSubnetList = this.existingSubnetList.filter(dt=>dt.name!= 'GatewaySubnet')
        this.existingSubnetList = this.existingSubnetList.filter(dt=>dt.name!= 'AzureFirewallSubnet')
        this.existingSubnetList = this.existingSubnetList.filter(dt=>dt.name!= 'AzureBastionSubnet')
        // this.existingSubnetList= this.existingSubnetList.filter(x=>{
        //   if(x.properties?.applicationGatewayIPConfigurations) x.properties?.applicationGatewayIPConfigurations.length!=0})
      let tempArray=[]
      Promise.all(this.allData.map(async e => {
        let subsId= e[2]
        let rgName= e[1]
        let nicName= e[0]
          return this.util.handleRequest('get','a3s_management_getNSGData',[subsId,rgName,nicName],null,null,null,true)
        })).then(final=>{
          final.forEach(e=>{
            if(e['body'].properties?.subnets){
              let sublist=e['body'].properties?.subnets
              sublist.map(e=>{
                let vnet =e.id.split('/')[8]
                let subnet =e.id.split('/')[10]
               if(vnet==vnetName){
                tempArray.push({name:subnet})
              }
            })
            }
          })
          let check= this.existingSubnetList.filter(e=>tempArray.every(x=>x.name!=e.name))
          this.existingSubnetList=check
        })
      console.log('Subnet Data',this.subnetData)
    })
  }

  async getSubnetData(type,data,subData?:any){ 
    let subsId = data[2]
    let rgName =  type == 'associateSubnet' ? this.vnetRgName:subData.id.split('/')[4]
    let subnetName =  subData.subnetName 
    let vnetName = subData.vnetName
    if(type=='edit'){
      subsId = data[8]
      rgName = data[2]
      subnetName =  data[0] 
      vnetName = data[1] 
    }
    return this.util.handleRequest('get','a3s_management_getSubnetData',[subsId,rgName,vnetName,subnetName],null,null,null,true).then(res=>{
      this.subnetData = res.body
      // switch(type){
      //   case 'networkInterface':{
         
      //     break;
      //   }
      // }
      console.log('Subnet Data',this.subnetData)
    })
  }
  async saveNsgSubnet(){
    let subsId = this.data[2]
    let rgName = this.data[1]
    let nsgName = this.data[0]
    let vnetName =this.currentForm.get('virtualNetwork').value
    let subnetName =this.currentForm.get('subnet').value
    let subData={vnetName,subnetName}
    await this.getSubnetData('associateSubnet',this.data,subData)
    let body =this.subnetData
    body['properties'].networkSecurityGroup={
      id:`/subscriptions/${subsId}/resourceGroups/${rgName}/providers/Microsoft.Network /networkSecurityGroups/${nsgName}`
    }
    await this.UpdateSubnetData(this.data,body,'associateSubnet',subData)
  }

  UpdateSubnetData(data,body,type,subData?:any){
    let subsId = data[2]
    let rgName =  type == 'associateSubnet' ? this.vnetRgName:subData.id.split('/')[4]
    let subnetName =  subData.subnetName 
    let vnetName = subData.vnetName
    if(type=='edit'){
      subsId = data[8]
      rgName = data[2]
      subnetName =  data[0] 
      vnetName = data[1] 
    }
    this.util.handleRequest('post','a3s_management_updateSubnetData',[subsId,rgName,vnetName,subnetName],body,null,null,true).then(res=>{
      console.log('Subnet Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      this.triggerOutput()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  
  /******************************************** NSG End ************************************************************/
 
  /******************************************** IP Address ************************************************************/
  async getPublicIPAddress(type,data,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let nicName = data[0] 
    // let nicName = type == 'dissociateNic' ?  subData.nicName : data[0] 
    await this.util.handleRequest('get','a3s_management_getPublicIPAddress',[subsId,rgName,nicName],null,null,null,true).then(res=>{
      this.PublicIPData = res.body
      console.log('IP Data',this.PublicIPData)
     let edit = _.cloneDeep(this.PublicIPData)
     if(edit.properties.ipConfiguration){
      this.associatedTo = edit.properties.ipConfiguration.id.split('/')[8]
    } if(type=='configuration'){
      this.saveDisable=false
      this.currentForm.get('idleTimeout').setValue( edit.properties.idleTimeoutInMinutes)
      this.currentForm.get('ipAssignment').setValue( edit.properties.publicIPAllocationMethod)
      this.currentForm.get('dnsName').setValue( edit.properties?.dnsSettings?.domainNameLabel)
      this.dynamicName=edit.properties?.dnsSettings?.domainNameLabel
      this.warningMessage=edit?.properties?.ipConfiguration?.id.includes('virtualmachines')?true:false
      if(edit?.properties?.ipConfiguration){
        // this.currentForm.get('ipAssignment').disable()
        $('#static').prop('disabled','disabled');
        $('#dynamic').prop('disabled','disabled');
        $('#disable').addClass('disabled');
        // $('#disable').addClass('disabled-icon')
      }
    }
    })
  }
 async updatePublicIPAddress(subsId,body,rgName,nicName,type?:any){
   return this.util.handleRequest('post','a3s_management_updatePublicIPAddress',[subsId,rgName,nicName],body,null,null,true).then(res=>{
      this.PublicIPData = res.body
      // this.showSubSidesheet = false
      this.util.success(this.getSuccesText(type))
     
    })
  }
  updateConfigPublicIPAddress(subsId,body,rgName,nicName){
  
    this.util.handleRequest('post','a3s_management_updateConfigPublicIPAddress',[subsId,rgName,nicName],body,null,null,true).then(res=>{
      this.PublicIPData = res.body
      this.showSubSidesheet = false
      this.triggerOutput()
      console.log('update Data',this.PublicIPData)
    })
  }
  async getNICList(type,data){
    let subsId = data[2]
    await this.util.handleRequest('get','a3s_management_getNICList',[subsId],null,null,null,true).then(async res=>{
      this.networkInterfaceList = res.body.value
      if(type=='associateNIC'){
        let tempArray= []
        Promise.all(this.allData.map( e => {
          let subsId= e[2]
          let rgName= e[1]
          let nicName= e[0]
          return this.util.handleRequest('get','a3s_management_getPublicIPAddress',[subsId,rgName,nicName],null,null,null,true)
          })).then(final=>{
          final.map(e=>{
            let nicConfigure = e['body'].properties
            if(nicConfigure.ipConfiguration){
              // nicConfigure.ipConfiguration.forEach(e=>
                tempArray.push({name:nicConfigure.ipConfiguration.id.split('/')[8]})
            }
          })
          let loc = this.PublicIPData.location
          this.networkInterfaceList = this.networkInterfaceList.filter(e=>e.location==loc)
          let check=this.networkInterfaceList.filter(e=>tempArray.every(x=>e.name!=x.name))
          this.networkInterfaceList=check
          console.log('nic',check) 
        })
      }
      console.log('networkInterfaceList Data',this.networkInterfaceList)
    })
  }
  filterNICList(type){

    if(type=='associateNICNSG'){
      let tempArray= []
      Promise.all(this.allData.map( e => {
        let subsId= e[2]
        let rgName= e[1]
        let nsgName= e[0]
        return this.util.handleRequest('get','a3s_management_getNSGData',[subsId,rgName,nsgName],null,null,null,true)
        })).then(final=>{
        final.map(e=>{
          let nicConfigure = e['body'].properties
          if(nicConfigure.networkInterfaces){
            nicConfigure.networkInterfaces.forEach(e=>
              tempArray.push({name:e.id.split('/')[8]}))
          }
        })
        let loc = this.nsgData.location
        this.networkInterfaceList = this.networkInterfaceList.filter(e=>e.location==loc)
        let check=this.networkInterfaceList.filter(e=>tempArray.every(x=>e.name!=x.name))
        this.networkInterfaceList=check
        console.log('nic',check)  
      })
      
    }
  }

  async fetchNICConfig(subId,rgName,IpName){
    let nicName = IpName
    await this.util.handleRequest('get','a3s_management_fetchNICConfig',[subId,rgName,nicName],null,null,null,true).then(res=>{
      this.nicData = res.body
      console.log('NIC Data',this.nicData)
    })
  }

  updateNICPublicIP(subId,body,rgName,nicName,type){
    // let type ='associate'
    this.util.handleRequest('post','a3s_management_updateNICPublicIP',[subId,rgName,nicName],body,null,null,true).then(res=>{
      console.log('NIC Data',res)
      this.showSubSidesheet = false
      this.triggerOutput()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }

  getLoadBalancerList(type,data){
    let subsId = data[2]
    this.util.handleRequest('get','a3s_management_getLBList',[subsId],null,null,null,true).then(res=>{
      this.LoadBalancerList = res.body.value
      if(type=='associateNIC'){
        let tempArray= []
        Promise.all(this.LoadBalancerList.map( e => {
          let split = e.id.split('/')
          let subsId= split[2]
          let rgName= split[4]
          let nicName= e.name
          return  this.util.handleRequest('get','a3s_management_fetchLBConfig',[subsId,rgName,nicName],null,null,null,true)
          })).then(final=>{
          final.map(e=>{
            let loc =  e['body'].location
            let lbSku = e['body'].sku.name
            let checkSku=this.PublicIPData.sku.name
            let ipLoc = this.PublicIPData.location
            if(loc != ipLoc){
              tempArray.push({name:e['body'].name})
            }
            if(lbSku != checkSku){
                tempArray.push({name:e['body'].name})
            }
            if(lbSku=='Basic'){
               lbSku==checkSku ? tempArray.push({name:e['body'].name}) :false
            }
          })
          let check=this.LoadBalancerList.filter(e=>tempArray.every(x=>e.name!=x.name))
          this.LoadBalancerList=check
          console.log('nic',check) 
        })
      }
      console.log('LoadBalancerList Data',this.LoadBalancerList)
    })
  }
  fetchLBConfig(subId,rgName,IpName){
    // let subsId = data[2]
    // let rgName = data[1]
    let nicName = IpName
    this.util.handleRequest('get','a3s_management_fetchLBConfig',[subId,rgName,nicName],null,null,null,true).then(res=>{
      this.nicData = res.body
    
      console.log('NIC Data',this.nicData)
    })
  }

  async updateLBPublicIP(subId,body,rgName,nicName,type){
   
    this.util.handleRequest('post','a3s_management_updateLBPublicIP',[subId,rgName,nicName],body,null,null,true).then(res=>{
      console.log('NIC Data',res)
      this.showSubSidesheet = false
      this.triggerOutput()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
      console.log('error',err)
    })
  }
  async checkLBPresence(){ 
    let nicName = this.nicData.properties.frontendIPConfigurations[0].properties?.publicIPAddress.id.split('/')[8]
    let rgName = this.nicData.properties.frontendIPConfigurations[0].properties?.publicIPAddress.id.split('/')[4]
    let subsId = this.nicData.properties.frontendIPConfigurations[0].properties?.publicIPAddress.id.split('/')[2]
    // await this.fetchNICConfig(this.data[2],rgName,nicName)
      delete this.nicData.properties.frontendIPConfigurations[0].properties.publicIPAddress
      await this.updateLBPublicIP(subsId,this.nicData,rgName,nicName,'');     
      // return this.updatePublicIPAddress(subsId,body,rgName,nicName,'');            
    
  }

  fetch(data){
    let splitted =data.id.split('/')
    let subId= splitted[2]
    let rgName= splitted[4]
    let IpName= splitted[8]
   switch(data.type){
       case 'Microsoft.Network/loadBalancers': this.fetchLBConfig(subId,rgName,IpName);break
       case 'Microsoft.Network/networkInterfaces': this.fetchNICConfig(subId,rgName,IpName);break
    }
  }
 async savePublicIP(){
    let data = this.nicData
    let splitted =data.id.split('/')
    let subId= splitted[2]
    let rgName= splitted[4]
    let nicName= splitted[8]
   
   switch(data.type){
       case 'Microsoft.Network/loadBalancers':{
        // await this.checkLBPresence()
        data['properties'].frontendIPConfigurations[0].properties.publicIPAddress ={ 
        "id":`/subscriptions/${subId}/resourceGroups/${this.data[1]}/providers/Microsoft.Network/publicIPAddresses/${this.data[0]}` 
      }
       await this.updateLBPublicIP(subId,data,rgName,nicName,'associate');break
       }  
       case 'Microsoft.Network/networkInterfaces':
        data['properties'].ipConfigurations[0].properties.publicIPAddress ={ 
          "id":`/subscriptions/${subId}/resourceGroups/${this.data[1]}/providers/Microsoft.Network/publicIPAddresses/${this.data[0]}` 
        }
       this.updateNICPublicIP(subId,data,rgName,nicName,'associate');break
    }
  }
  async configure(){
    let dnsName=this.currentForm.get('dnsName').value
   let object = this.PublicIPData
   delete object.properties.provisioningState
   object.properties.publicIPAllocationMethod =this.currentForm.get('ipAssignment').value
   object.properties.idleTimeoutInMinutes = Number(this.currentForm.get('idleTimeout').value)
   if(this.currentForm.get('idleTimeout').value==''){
    object.properties.idleTimeoutInMinutes =4
   }
  if(dnsName!=undefined){
    if(dnsName!=''){
     object.properties.dnsSettings =  { "domainNameLabel": dnsName, 
     "fqdn": `${dnsName}.${object.location}.cloudapp.azure.com` }
   }
   else{
    object.properties.dnsSettings=null
   }
  }
   this.updatePublicIPAddress(this.data[2],object,this.data[1],this.data[0],'configurationIP')
  //  this.triggerOutput()
  //  if( this.dnsNameCheck.available==true ){
  //    this.updatePublicIPAddress(this.data[2],object,this.data[1],this.data[0],'configurationIP')
  //  }
}
  ipCondition(){
    let check = this.PublicIPData?.sku?.name
    let ipAssign = this.PublicIPData.properties?.publicIPAllocationMethod
    if( check =='Standard'){
     $('#static').prop('checked','checked');
     $('#static').prop('disabled','disabled');
     $('#dynamic').prop('disabled','disabled');
     $('#disable').addClass('disabled');
    }
    else if(ipAssign=='Static' && check =='Basic'){
     $('#static').prop('checked','checked');
    }
  
  }
  
  changeIP(value){
    this.saveDisable=true
   value == 'static' ? this.currentForm.get('ipAssignment').setValue('Static') :this.currentForm.get('ipAssignment').setValue('Dynamic')
 }

 async getDNSNameValidation(data,name?:any){
   let subsId = data[2]
   let locationName = data[3]
   let dnsName = name
   // let nicName = type == 'dissociateNic' ?  subData.nicName : data[0] 
   await this.util.handleRequest('get','a3s_management_getDNSNameValidation',[subsId,locationName,dnsName],null,null,null,true).then(res=>{
     this.dnsNameCheck = res.body
     console.log('NIC Data',this.dnsNameCheck)
    },err=>{
      this.dnsNameCheck.available=false
      console.log('error',err)
    })
 }

 async dnsValidation(event){
    let value=event.target.value;
    this.dynamicName = value
    await this.getDNSNameValidation(this.data,value)
    console.log('dnsName',this.dnsNameCheck.available )
    let existName =this.PublicIPData.properties?.dnsSettings?.domainNameLabel
    if(existName==value){
      this.dnsNameCheck.available=true
    }
    if(!value.length){
      this.inputError['dnsNameLLetter']=false 
      this.inputError['dnsNameFLetter']=false
      this.inputError['dnsNameLength']=false
      this.dnsNameCheck.available=true
    }
    else{
    if(/^[a-z0-9]+$/.test(value[value.length-1])){
      this.inputError['dnsNameLLetter']=false
    }
    else{
      this.inputError['dnsNameLLetter']=true
    }
    if(value.length==1){
      if(/^[a-z]*$/.test(value)){
        this.inputError['dnsNameFLetter']=false
      }
      else{
        this.inputError['dnsNameFLetter']=true
      }
    }
    if(2<value.length && value.length<64){
      this.inputError['dnsNameLength']=false
    }
    else{
      this.inputError['dnsNameLength']=true
      this.dnsNameCheck.available=false
    }
  }
  
}

  idleTimeValidation(event){
    let value=event.target.value;
    if(!value.length){
      this.inputError['idleTimeout']=false 
    }
    else{
      if(value>3 && value<31 ){
        this.inputError['idleTimeout']=false
      }
      else{
        this.inputError['idleTimeout']=true
      }
    }
  }
  /******************************************** IP Address End ************************************************************/

    /******************************************** Subnets Start ************************************************************/

    async getSubnetType(type,data){
    switch(type){
      case'edit':{
        let subsId = data[8]
        let rgName = data[2]
        let subnetName =  data[0] 
        let vnetName = data[1]
        return this.util.handleRequest('get','a3s_management_getSubnetData',[subsId,rgName,vnetName,subnetName],null,null,null,true).then(res=>{
          this.subnetData = res.body
          console.log('Subnet Data',this.subnetData)
        })
      }
      case'VnetSubnet':{

      }
    }
    }
  
    async UpdateSubnetType(data,body,type){
      let subsId,rgName,subnetName,vnetName
      switch(type){
        case'edit':{
          subsId = data[8]
          rgName = data[2]
          subnetName =  data[0] 
          vnetName = data[1] 
          break;
        }
        case'vnetSubnet':{
          subsId = data[2]
          rgName = data[1]
          subnetName = this.currentForm.get('subnetName').value
          vnetName = data[0] 
          break;
        }
      }
      await this.util.handleRequest('post','a3s_management_updateSubnetData',[subsId,rgName,vnetName,subnetName],body,null,null,true).then(res=>{
        console.log('Subnet Data',res)
        type=='vnetSubnet'?this.showSubSidesheet = false:  this.triggerOutput()
        this.util.success(this.getSuccesText(type))
      },err=>{
        this.util.error(this.getFailureText(type))
      })
    }
    
subnetOverlap(limitField){
  let value =limitField.target.value
  this.inputError.subnetOverlaping=false
  this.vnetData.properties.subnets.forEach(e=>{
      let split=e.properties.addressPrefix.split('.')
      let val=value.split('.')
      if(split[1]==val[1]&&split[2]==val[2]){
        this.inputError.subnetOverlaping=true
      this.vNetOverlap=e.properties.addressPrefix}
    
  })
}
    subnetAddressSpace(limitField,type?:any){
      let value =limitField.target.value
      if(!this.isAddressSpace(value)){
        console.log(value,this.isAddressSpace(value));
        this.inputError.subnetAdrspc=true;
        return;
      }
      this.inputError.subnetAdrspc=false;
  
      let splitArr=value.split('/');
      try{
        let vlRangeObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
        this.inputError.subnetNotValidCIDR=false;
        if(vlRangeObj.bits>29){
          this.inputError.subnetPerfix=true;
          return;
        }else{
          this.inputError.subnetPerfix=false;
        }
        if(vlRangeObj.input!=value){
          this.inputError.subnetNotValidCIDR=true;
        }else{
          this.inputError.subnetNotValidCIDR=false;
        }
        // let resourceData=JSON.parse(this.parentResourceDtls.resourceData);
         this.vnetData.properties.addressSpace.addressPrefixes.find((dt,index)=>{
          // let check= this.vnetData.properties.addressSpace.addressPrefixes[0]
          // if(dt.address){
            // let splitAr=dt.address.split('/');
             let splitAr=dt.split('/');
            let rangeObj=new IPSubnetwork(new IPv4(splitAr[0]),splitAr[1]);
              if(this.validateOverloopAddress(vlRangeObj,rangeObj)){
                this.inputError.subnetAddresNotInVnet=false;
                return true;
              }else if(index== this.vnetData.properties.addressSpace.addressPrefixes.length-1){
                this.inputError.subnetAddresNotInVnet=true;
              }
          // }
        })
  
        if(this.vnetData.properties.subnets && this.vnetData.properties.subnets.length){
          this.vnetData.properties.subnets.find(dt=>{
            if(dt.name && this.data[0]!=dt.name){
              // let resourceDataSub=JSON.parse(dt.resourceData);
              if(dt.properties.addressPrefix== value){
                const splitArrSub=dt.properties.addressPrefix.split('/');
                let ipRangObj=new IPSubnetwork(new IPv4(splitArrSub[0]),splitArrSub[1]);
              if(this.validateOverloopAddress(vlRangeObj,ipRangObj)){
                this.inputError.subnetOverlaping=true;
                this.vNetOverlap=dt.properties.addressPrefix;
                return true;
              }else{
                this.inputError.subnetOverlaping=false;
                this.vNetOverlap='';
              }
              }
              else{
                this.inputError.subnetOverlaping=false;
                this.vNetOverlap='';
              }
            }
          })
        }
      }catch(e){
        this.inputError.subnetNotValidCIDR=true;
        return;
      }
    }
    addressSpaceoverlap(event,type,index){
      let value =event.target.value
      if(type=='addressSpace' ){
        this.vnetData.properties.addressSpace.addressPrefixes.forEach((e,i)=>{
          let inputSplit=e.split('.')
          let split=value.split('.')
          if(!this.inputError.subnetOverlaping && value.length==11 && index!=i){
            this.inputError.subnetOverlaping= e==value ||(split[1] == inputSplit[1] && split[0] == inputSplit[0]) ? true:false
            if(this.inputError.subnetOverlaping){
              this.inputAddress=value
              this.vNetOverlap=e
            }
          }
        })
        if(value==''){
          this.inputError.emptyBox=true
          this.inputError.subnetAdrspc=false
          this.inputError.dnsUnique=false
          this.inputError.subnetNotValidCIDR=false
          this.inputError.subnetOverlaping=false
        }else   this.inputError.emptyBox=false
      }
    }
    
    validateOverloopAddress(vlIPRange,ipRangObj){
      if(vlIPRange.range.left.input==ipRangObj.range.left.input){
        return true;
      }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
       return true;
      }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]){
        if(vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]){
          if(vlIPRange.range.left.parts[2]>ipRangObj.range.left.parts[2]){
            if(vlIPRange.range.right.parts[2]<ipRangObj.range.right.parts[2]){
              return true;
            }
          }else if(vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
            if(vlIPRange.range.left.parts[3]>ipRangObj.range.left.parts[3]){
              if(vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
                return true;
              }
            }
          }
        }
      }else if(vlIPRange.range.left.parts[0]<ipRangObj.range.left.parts[0]){
        if(vlIPRange.range.right.parts[0]>ipRangObj.range.right.parts[0]){
          return true;
        }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]){
          if(vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
            return true;
          }
        }
      }else {
        return false;
      }
    }
    isAddressSpace(string){
      return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
    }
    async getNATGatewayData(type){
      let subsId = this.currentProject.subscriptionId
      await this.util.handleRequest('get','a3s_management_getNATGatewayData',[subsId],null,null,null,true).then(res=>{
        this.natGetwaysList = res.body.value
        switch(type){
          case'subnet':{
            let loc = this.data[7]
            this.natGetwaysList=this.natGetwaysList.filter(e=>e.location == loc)
            let check=this.natGetwaysList.filter(e=>e.name == this.subnetData.properties?.natGateway?.id.split('/')[8])
            // let vnetName = this.natGetwaysList.filter(e=>e.properties?.subnets.some(x=>x.id.includes(this.data[1])==this.data[1]))
            if(check.length){
              this.natGetwaysList=this.natGetwaysList.filter(e=>e.name!=check?.name)
            }
             this.natGetwaysList=this.natGetwaysList.filter(e=> { 
               if(e.properties?.subnets){
                return  e.properties?.subnets[0].id.split('/')[8]==this.data[1]
                }
              else{
                return e
              }})
          }
          case 'vnetSubnet':{
            let loc = this.data[3]
            this.natGetwaysList=this.natGetwaysList.filter(e=>e.location == loc)
          }
        }
       
        this.natGetwaysList.unshift({id:'None', name:'None'})
        console.log('natGetwaysList Data',this.natGetwaysList)
      })
    }

    async getRouteTableData(type){
      let subsId = this.currentProject.subscriptionId
      await  this.util.handleRequest('get','a3s_management_getRouteTableData',[subsId],null,null,null,true).then(res=>{
        this.routeTablesList = res.body.value
         let loc = type=='vnetSubnet'?this.data[3]:this.data[7]
         this.routeTablesList=this.routeTablesList.filter(e=>e.location == loc)
         this.routeTablesList.unshift({id:'None', name:'None'})
        console.log('routeTablesList Data',this.routeTablesList)
      })
    }
    async getNSGlistData(type){
      let subsId = this.currentProject.subscriptionId
      await  this.util.handleRequest('get','a3s_management_getNSGlistData',[subsId],null,null,null,true).then(res=>{
        this.nsgList = res.body.value
        switch(type){
          case 'subnet':{
            let loc = this.data[7]
            this.nsgList=this.nsgList.filter(e=>e.location == loc)
            }
            break;
          
          case 'nicNsg':{
            let loc = this.data[3]
            this.nsgList=this.nsgList.filter(e=>e.location == loc)
            }
            break;
        }
        this.nsgList.unshift({id:'None', name:'None'})
        console.log('nsgList Data',this.nsgList)
      })
    }
    async getServiceEndpointData(data,type?:any){
      let subsId = this.currentProject.subscriptionId
      let locationName = type=='vnetSubnet'?data[3]:data[7]
      await  this.util.handleRequest('get','a3s_management_getServiceEndpointData',[subsId,locationName],null,null,null,true).then(res=>{
        this.servicesList = res.body.value
        console.log('servicesList Data',this.servicesList)
      })
    }
    async getDelegateSubnetData(data,type?:any){
      let subsId = this.currentProject.subscriptionId
      let rgName = data[2]
      let locationName =type=='vnetSubnet'?data[3]: data[7]
      await  this.util.handleRequest('get','a3s_management_getDelegateSubnetData',[subsId,rgName,locationName],null,null,null,true).then(res=>{
        this.dsServiceList = res.body.value
        this.dsServiceList.unshift({id:'None', serviceName:'None'})
        console.log('dsServiceList Data',this.dsServiceList)
      })
    }



      /******************************************** Subnets End ************************************************************/
    /******************************************** Network Interface start ************************************************************/

  async getNICData(type,data,subData?:any){
    let subsId = data[2]
    let rgName = type == 'dissociateNic' ?  subData.id.split('/')[4] : data[1] 
    let nicName = type == 'dissociateNic' ?  subData.nicName : data[0] 
    return this.util.handleRequest('get','a3s_management_getNICData',[subsId,rgName,nicName],null,null,null,true).then(res=>{
      this.nicData = res.body
      switch(type){
        case 'networkInterface':{
          
          break;
        }
      }
      console.log('NIC Data',this.nicData)
    })
  }
  
  async getPublicIPList(type?:any){
    let subsId = this.data[2]
    let temp=[]
    return  this.util.handleRequest('get','a3s_management_getPublicIPlistData',[subsId],null,null,null,true).then(res=>{
      this.publicIPAllList = res.body.value
      // let nicIP = this.nicData['properties']?.ipConfigurations[0].properties?.publicIPAddress?.id
      // this.nicPublicIP= this.publicIPList.filter(e=>e.id==nicIP)
      this.nicData['properties'].ipConfigurations.map(e=>{
        let nicIP =e.properties?.publicIPAddress?.id
        return this.nicPublicIP= this.publicIPAllList.filter(e=>e.id==nicIP)
      })
       let loc = this.data[3]
       this.publicIPList=this.publicIPAllList.filter(e=>e.location == loc)
      //  this.publicIPList=this.publicIPList.filter(e=>e.sku.name==this.nicData.properties.nicType) &&e.sku.name=='Standard'
      if(this.publicIPList){
       let array1=this.publicIPList.filter(e=>!e?.properties?.ipConfiguration)
       let array2=this.publicIPList.filter(e=>e?.properties?.ipConfiguration)
        this.publicIPList=[...array1,...array2]
        this.publicIPList.forEach(e=>{
          let val=e.properties.ipConfiguration
          this.dropdownHeader=val?'Cannot be associated with network interface': 'Can be associated with network interface'
          return temp.push({name:`${e.name}`,
                              id:e.id,
                              type: this.dropdownHeader
                            })
          })
          this.publicIPList=temp
      }
      console.log('routeTablesList Data',this.publicIPList)
    })
  }

  getSubnetListData(data,vnetRg){
    let subsId = data[2]
    let rgName = vnetRg
    let vnetName = data[4]
    let temp=[]
    this.util.handleRequest('get','a3s_management_getSubnetListData',[subsId,rgName,vnetName],null,null,null,true).then(res=>{
      this.subnetList= res.body.value
      let loc = this.data[3]
      // subnetList=subnetList.filter(e=>e.location == loc)
      let array1=this.subnetList.filter(e=>{return e.name!='GatewaySubnet'&& e.name!='AzureFirewallSubnet'&&e.name!='AzureBastionSubnet'})
      let array2=this.subnetList.filter(e=>{return e.name=='GatewaySubnet'|| e.name=='AzureFirewallSubnet'||e.name=='AzureBastionSubnet'})
      // let array1=this.subnetList.filter(e=>array2.some(x=>x.name!=e.name))
      this.subnetList=[...array1,...array2]
      this.subnetList.forEach(e=>{
        let val=e.name
        if(val!='GatewaySubnet'&& val!='AzureFirewallSubnet'&& val!='AzureBastionSubnet'){
       this.dropdownHeader='Can be associated with network interface'
        }
        else{
          this.dropdownHeader='Cannot be associated with network interface'
        }
        return temp.push({name:`${e.name} (${e?.properties?.addressPrefix})`,
                            id:e.id,
                            type: this.dropdownHeader
                            // type:e.name!='GatewaySubnet'||e.name!='AzureFirewallSubnet'||e.name!='AzureBastionSubnet'?'Can be associated with network interface':'Cannot be associated with network interface'
                          })
        })
      this.existingSubnetList=temp
      console.log('Subnet Data',this.subnetList)
    })
  }
  changeNicDns(value){
    value == 'static' ? this.currentForm.get('nicDnsServer').setValue('inherit') :this.currentForm.get('nicDnsServer').setValue('custom')
  }
  dropdownDisable(){
    return this.existingSubnetList.filter(e=>e.type=='Cannot be associated with network interface')
  }
  UpdateNICData(data,body,type,subData?:any){
    let subsId = data[2]
    let rgName = type == 'dissociateNic' ?  subData.id.split('/')[4] : data[1] 
    let nicName = type == 'dissociateNic' ?  subData.nicName : data[0] 
    this.util.info(this.getInProgress(type))
    this.util.handleRequest('post','a3s_management_updateNICData',[subsId,rgName,nicName],body,null,null,true).then(res=>{
      console.log('NIC Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // subData=='addIP'?false:this.triggerOutput()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  async saveNic(type){
    switch(type){
      case 'nicNsg':{
        let body=this.nicData
        if(this.currentForm.get('nsgList').value!='None'){
          body['properties'].networkSecurityGroup = {
            id:this.currentForm.get('nsgList').value
          }
        }
        else{
          delete body['properties'].networkSecurityGroup
        }
        this.UpdateNICData(this.data,body,type)
        break;
      }
      case 'nicDns':{
        let body=_.cloneDeep(this.nicData) 
        if(this.currentForm.get('dnsServer').value==true){
           body['properties'].dnsSettings.dnsServers=[]
          } 
          else{
            // let dns = this.currentForm.get('addressSpaceArray').value
            // body['properties'].dnsSettings.dnsServers.length?dns.pop():false
            // let filterDns=[]
            // dns.map((e,i)=>{
            //   if(e.value!=''){
            //     filterDns.push(e.value)
            //   }
            // })
            let rawValue = this.currentForm.getRawValue()
            let array  = rawValue.addressSpaceArray.filter(e=>{
              if(e.value&&e.value!=='') return e.value
            })
            array= array.map(e=> {return e.value})
              if(body['properties']?.virtualMachine){
                body['properties'].dnsSettings.appliedDnsServers = _.cloneDeep(array) 
              }
            body['properties'].dnsSettings.dnsServers = array
        }
        await this.UpdateNICData(this.data,body,type)
        this.triggerOutput()
        // await this.getNICData('nicNsg',this.data) 
      }
      case 'ipConfiguration':{
        let body=this.nicData
        body['properties'].enableIPForwarding= this.currentForm.get('ipForwarding').value
       
        if(this.currentForm.get('subnetIP').value){
          body['properties'].ipConfigurations[0].properties.subnet.id= this.currentForm.get('subnetIP').value
        }
        this.UpdateNICData(this.data,body,type)
        break;
      }
      case 'addIP':{
        await this.getPublicIPList()
        let body=_.cloneDeep(this.nicData) 
        let ipName=this.currentForm.get('name').value
        let ipAddress = this.currentForm.get('ipAddress').value
        let publicIP = this.currentForm.get('publicIPAddress').value
        let publicIPAss = this.currentForm.get('publicIPAddressInput').value
        let splitted=body['properties'].ipConfigurations[0].id.split('/')
        let ipBody=_.cloneDeep(body['properties'].ipConfigurations[0]) 
        ipBody['name']=ipName
        ipBody['id']=`/subscriptions/${splitted[2]}/resourceGroups/${splitted[4]}/providers/Microsoft.Network/networkInterfaces/${splitted[8]}/ipConfigurations/${ipName}`
        ipBody['properties'].privateIPAllocationMethod=this.currentForm.get('allocation').value
        ipBody['properties'].primary=this.currentForm.get('type').value
        let split = ipBody['properties'].privateIPAddress.split('.')
        split[split.length-1]=(Number(split[split.length-1])+1).toString()
        split =split.join('.')
        ipBody['properties'].privateIPAddress=ipAddress?ipAddress:split
      if(publicIP){
        publicIPAss? ipBody['properties'].publicIPAddress={"id":publicIPAss}:false }
        else{delete ipBody['properties'].publicIPAddress}
        body['properties'].ipConfigurations.push(ipBody)
        await this.UpdateNICData(this.data,body,type,'addIP')
        await this.util.delay(1000)
        await this.getNICData('nicNsg',this.data) 
        await this.getPublicIPList()
        this.showSubSidesheet = false
        this.resetForm('nicIP')
     
        break;
      }
      case 'addpublicIP':{
        let body=this.nicData
        let splitted=body['properties'].ipConfigurations[0].id.split('/')
        let ipName=this.currentForm.get('publicIPName').value
        let sku = this.currentForm.get('sku').value
        let assign = this.currentForm.get('assigment').value
        let publicIPbody={
          "properties": {
            "publicIPAllocationMethod": assign,
            "idleTimeoutInMinutes": 10,
            "publicIPAddressVersion": "IPv4"
          },
          "sku": {
            "name": sku,
            "tier": sku=="Standard"?"Global":"Regional"
          },
          "location": body.location
        }
        await this.updatePublicIPAddress(splitted[2],publicIPbody,splitted[4],ipName,'puclicIPCreate')
        await this.getPublicIPList()
        // let id=this.PublicIPData.filter(e=>e.name==ipName)
        this.currentForm.get('publicIPAddressInput').setValue(this.PublicIPData.id)
        // this.currentForm.get('allocation').value=='Dynamic'? this.currentForm.get('ipAddress').setValue('Unassigned'):this.currentForm.get('ipAddress').value
        this.onToggle('publicIP')
        break;
      }
      case 'editIP':{
        let body=this.nicData
        let ipName=this.currentForm.get('name').value
        let ipAddress = this.currentForm.get('ipAddress').value
        let publicIP = this.currentForm.get('publicIPAddress').value
        let publicIPAss = this.currentForm.get('publicIPAddressInput').value
        // let ipBody=body['properties'].ipConfigurations.filter(e=>e.name==ipName) 
        body['properties'].ipConfigurations.map((e,i)=>{
          if(e.name==ipName){
            e['name']=ipName
            e['properties'].privateIPAllocationMethod=this.currentForm.get('allocation').value
            e['properties'].privateIPAddress=ipAddress
            if(publicIP){
              publicIPAss? e['properties'].publicIPAddress={"id":publicIPAss}:false }
              else{delete e['properties'].publicIPAddress}
            // publicIP? e['properties'].publicIPAddress={"id":publicIP}:delete e['properties'].publicIPAddress
          }
        })
        this.editSubSidesheet = false
        await this.UpdateNICData(this.data,body,type,'addIP')
        await this.util.delay(1000)
        await this.getNICData('nicNsg',this.data) 
        await this.getPublicIPList()
        this.resetForm('nicIP')
      
        break;
      }
    } 
  }
  getpublicIpAddressName(list){
  let name =this.publicIPAllList.filter(e=>e.id==list?.properties?.publicIPAddress?.id)
  return name[0].properties?.ipAddress ? name[0].properties?.ipAddress:''
  }
  CheckDnsServer(event,index,type?:any){
    this.warningMessage=true
      let value=event.target.value;
      this.inputError['dnsError']=false;//sourceInvalidArgument
  
      if(value&&value!=""){
      const splitArComma=value.split(',');
      let ipV4Value;
      let tempArr=[];
      for(let i=0;i<splitArComma.length;i++){
        if(!splitArComma[i]){
         // let inputError="Invalid argument: 'A non-null address range is required.'";
          this.inputError['dnsError']=true;
          break;
        }else{
          const splitArr=splitArComma[i].split('/');
          if(splitArr.length>1&&!splitArr[1]){
            //Maliform
            this.inputError['dnsError']=true;
          }else{
            ipV4Value=splitArr[0];
          }
          
          try{
            let ipV4Obj=new this.ipV4(ipV4Value);
           // item.VNetAdrspc=false;
            let vlIPRange;
            if(splitArr.length>1){
            try{
              vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
              //item.VNetAdrspc=false;
              if(vlIPRange.input!=splitArComma[i]){
                //item.notValidCIDR=true;
                //item.validCIDRValue=vlIPRange.input;
                this.inputError['dnsError']=true;
                break;
              }
            }catch(e){
              //item.VNetAdrspc=true;
              this.inputError['dnsError']=true;
              break;
            }
          }        
          }catch(e){
         //   item.VNetAdrspc=true;
         this.inputError['dnsError']=true;
            break;
          }
        }
      }
    }
    if(value&&value!=""){
    const splitArComma=value.split(',');
    let ipV4Value;
    let tempArr=[];
    for(let i=0;i<splitArComma.length;i++){
      if(!splitArComma[i]){
       // let inputError="Invalid argument: 'A non-null address range is required.'";
        this.inputError['NonNullRange']=true;
        break;
      }
    }
  }
  if(type=='nicIPAddress'){
    this.invalidIP(value)
  }
  if(index!=''){
    if(!this.addressSpaceList.controls.length) return;
    let data = this.addressSpaceList.controls.some((e,i)=> {
      if(index !== i && e.get('value').value == value && e.get('value').value != '' ){
        this.inputError['dnsUnique']=true
      }
      // else{ this.inputError['dnsUnique']=false}
      else if(index !== i &&e.get('value').value != ''){
        this.inputError['dnsUnique']=false
      }
      // return index !== i && e.get('value').value == this.addressSpaceList.at(index).get('value').value
    })
  }
  else{
    this.getIPAddressValidation(value)
  }
}
invalidIP(value,type?:any){
  // let value=event.target.value
  let inputSplit=value.split('.')
  if(inputSplit.length!=3||inputSplit[inputSplit.length-1]){
    this.inputError.dnsError=true
  }
  let subnet=this.currentForm.get('subnetIP').value
  let sub=this.subnetList.filter(e=>e.id==subnet)
  let split=sub[0].properties.addressPrefix.split('.')
  let existIP=this.nicData.properties.ipConfigurations.filter((e,i)=>{
    if(e.name!=this.currentForm.get('name').value){return e.properties.privateIPAddress==value}}
    )
  if(inputSplit.length!=4||inputSplit[inputSplit.length-1]==''){
      this.inputError.dnsError=true
  }else{
  if(split[0]!=inputSplit[0] || split[2]!=inputSplit[2] || split[1]!=inputSplit[1] || existIP.length){
    this.inputError.dnsError=true
  }else if(inputSplit[3]<'5'||!(/^[0-9]*$/.test(inputSplit[3]))){ this.inputError.dnsError=true}
  else if(value.length>8){ this.inputError.dnsError=true}
  // else if(existIP[0].name==this.currentForm.get('name').value){ this.inputError.dnsError=true}
  else   this.inputError.dnsError=false
  }
}
makePrimary(list){
  let type='makePrimary';
  let body=this.nicData
  body['properties'].ipConfigurations.map((e,i)=>{
    if(e.properties.primary){
      e.properties.primary=false
    }
   return e.name==list.name?e.properties.primary=true:false
  })
  this.UpdateNICData(this.data,body,type)
}
async setIPConfiDefault(type,data?:any){
  switch(type){
    case 'addIP':{
      let body=this.nicData
      if(body['properties'].ipConfigurations.length){
        let temp=body['properties'].ipConfigurations.map(e=>{
          if( e.properties.primary==true){}
         this.infoMessage= true
          this.currentForm.get('type').setValue(false)
          $('#primary').prop('disabled','disabled');
          $('#secondary').prop('disabled','disabled');
          $('#disable').addClass('disabled');
        })
      }else{
        this.infoMessage= false
      }
      this.currentForm.get('allocation').setValue('Dynamic')
      this.currentForm.get('publicIPAddress').setValue(false)
      break;
    }
    case 'publicIP':{
      this.currentForm.get('sku').setValue('Basic')
      this.currentForm.get('assigment').setValue('Static')
      this.currentForm.get('publicIPName').setValue(null)
    }
    case 'editIP':{
      await this.getPublicIPList()
      let body=data
      this.currentForm.get('name').setValue(body.name)
      this.currentForm.get('allocation').setValue(body.properties.privateIPAllocationMethod)
      this.currentForm.get('ipAddress').setValue(body.properties.privateIPAddress)
      this.currentForm.get('publicIPAddress').setValue(body.properties.publicIPAddress?true:false)
      this.warningMessage=body.properties.publicIPAddress?true:false
      this.currentForm.get('publicIPAddressInput').setValue(body.properties?.publicIPAddress?.id)
    }
  }

}
validIPName(event,limitNum,flag,subType?:any){
  let value =event.target.value;
  this.inputError[flag+'Exist'] = false;
  // this.inputError[flag+'MaxLen'] = false;
  if(subType=='ddosName'){
    if (value.length > limitNum ||value.length<2) {
      this.inputError[flag+'MaxLen'] = true;
      return;
    }
    else {
      this.inputError[flag+'MaxLen'] = false;
    }
  }else{
    if (value.length > limitNum) {
      this.inputError[flag+'MaxLen'] = true;
      return;
    }
    else {
      this.inputError[flag+'MaxLen'] = false;
    }
  }
  // let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
  // if(value[value.length-1]=='.'||regex.test(value)){
  //   this.inputError[type+'valid']=true;
  //   return;
  // }else{
  //   this
  //   this.inputError[type+'valid']=false;
  // }
  if(value.length==1){
    if(/^[A-Za-z0-9]*$/.test(value)){
      this.inputError[flag+'Valid']=false;
    }
    else{
      this.inputError[flag+'Valid']=true;
    }
  }
  // else this.inputError[flag+'Valid']=false;
  if(/^[A-Za-z0-9_]+$/.test(value[value.length-1]) && !this.inputError[flag+'Valid']){
    this.inputError[flag+'LastValid']=false;
  }
  else{
    this.inputError[flag+'LastValid']=true;
  }
  switch(subType){
    case 'vnetSubnet':{
      this.vnetData.properties.subnets.forEach(e=>{
        if(e.name==value){
          this.inputError[flag+'Exist'] = true;
          return;
        }
      })
      if(value==0){ this.inputError[flag+'Valid']=false;}
      break;
    }
    case'ipName':{
      this.nicData?.properties?.ipConfigurations.forEach(e=>{
        if(e.name==value){
          this.inputError[flag+'Exist'] = true;
          return;
        }
      })
      break;
    }
    case'publicIPName':{
      this.publicIPList.forEach(e=>{
        if(e.name==value){
          this.inputError[flag+'Exist'] = true;
          return;
        }
      })
      break;
    }
    case'ddosName':{
      this.ddosProtectionPlanList.forEach(e=>{
        if(e.name==value){
          this.inputError[flag+'Exist'] = true;
          return;
        }
      })
      break;
    }
  }
  // if(this.ResourceGroups&&this.ResourceGroups.length){
  //   let findObj=this.ResourceGroups.find(dt=>dt.name==value);
  //   this.inputError.duplicateResourceName = findObj?true:false;
  // }else {
  //   this.inputError.duplicateResourceName = false;
  // }
}
getIPAddressValidation(val){
  let subsId = this.data[2]
  let rgName = this.data[1]
  let vnetName = this.data[4]
  let ipAddress = val
  this.util.handleRequest('get','a3s_management_getIPAddressValidation',[subsId,rgName,vnetName,ipAddress],null,null,null,true).then(res=>{
    let ipValidation= res.body
    ipValidation.available==true? this.inputError['dnsError']=false: this.inputError['dnsError']=true;
   
  })
}
/******************************************** Network Interface end ************************************************************/
  sortByKey(primaryKey,data,type?:any){
    let num = 0;
    (this.datasort === 'desc') ? num = -1 : num = 1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'nsg':{
        this.sortDefaultNsg=this.datasort==='desc'?true:false
        let sorted1=this.nsgData.properties.securityRules;
        let sorted2=this.nsgData.properties.defaultSecurityRules;
        switch(type){
          case'priority':{
            sorted1.sort((a,b)=>{
              let x = a?.properties?.priority;
              let y = b?.properties?.priority;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            sorted2.sort((a,b)=>{
              let x = a?.properties?.priority;
              let y = b?.properties?.priority;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            break;
          }
          case'name':{
            // let merg =[...sorted1,...sorted2]
            sorted1.sort((a,b)=>{
              let x = a?.name;
              let y = b?.name;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            sorted2.sort((a,b)=>{
              let x = a?.name;
              let y = b?.name;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            break;
          }
          case'port':{
            sorted1.sort((a,b)=>{
              let x = a?.properties?.sourcePortRange;
              let y = b?.properties?.sourcePortRange;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            sorted2.sort((a,b)=>{
              let x = a?.properties?.sourcePortRange;
              let y = b?.properties?.sourcePortRange;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            break;
          }
          case'protocol':{
            sorted1.sort((a,b)=>{
              let x = a?.properties?.protocol;
              let y = b?.properties?.protocol;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            sorted2.sort((a,b)=>{
              let x = a?.properties?.protocol;
              let y = b?.properties?.protocol;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            break;
          }
          case'sourecIP':{
            sorted1.sort((a,b)=>{
              let x = a?.properties?.sourceAddressPrefix;
              let y = b?.properties?.sourceAddressPrefix;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            sorted2.sort((a,b)=>{
              let x = a?.properties?.sourceAddressPrefix;
              let y = b?.properties?.sourceAddressPrefix;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            break;
          }
          case'destIP':{
            sorted1.sort((a,b)=>{
              let x = a?.properties?.destinationAddressPrefix;
              let y = b?.properties?.destinationAddressPrefix;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            sorted2.sort((a,b)=>{
              let x = a?.properties?.destinationAddressPrefix;
              let y = b?.properties?.destinationAddressPrefix;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            break;
          }
          case'action':{
            sorted1.sort((a,b)=>{
              let x = a?.properties?.access;
              let y = b?.properties?.access;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            sorted2.sort((a,b)=>{
              let x = a?.properties?.access;
              let y = b?.properties?.access;
              return (x < y) ? num : ((x > y) ? -num : 0)
            })
            break;
          }
        }
        this.nsgData.properties.securityRules=sorted1;
        this.nsgData.properties.defaultSecurityRules=sorted2;
        break;
      }
    }
  }

 
}
