import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusDirective, AvoidspaceDirective, IsEllipsisActiveDirective, NumberlimitDirective, OnlynumberDirective, Restrict2dotDirective } from './custom.directive';

@NgModule({
    imports: [
        CommonModule
      ],
      declarations: [
        Restrict2dotDirective,
        NumberlimitDirective,
        OnlynumberDirective,
        AvoidspaceDirective,
        AutofocusDirective,
        IsEllipsisActiveDirective
      ],
      exports: [
        Restrict2dotDirective,
        NumberlimitDirective,
        OnlynumberDirective,
        AvoidspaceDirective,
        AutofocusDirective,
        IsEllipsisActiveDirective
      ]
})
export class DirectiveModule {}