<div class="data-wrap flex-custom no-wrap">
    <div class="left-data">
        <div class="data-card">
            <div class="d-flex">
                <div class="ml-2 full-width">
                    <span class="text-14-22">Total web apps</span>
                    <p class="text-24-30 bold-600 mb-0">{{resourceList?.length}}</p>
                </div>
            </div>
         </div>
         <div class="data-card">
            <div class="d-flex">
                <span class="strip-status big green"></span>
                <div class="ml-2 full-width">
                    <span class="text-14-22">Healthy web apps</span>
                    <p class="text-24-30 bold-600 mb-0">{{healthyResourceCount}}</p>
                </div>
            </div>
          </div>
    </div>
    <div class="data-card right-data">
        <div class="d-flex">
            <span class="strip-status big red"></span>
            <div class="ml-2 full-width">
                <span class="text-14-22">Unhealthy web apps</span>
                <p class="text-24-30 bold-600 mb-0">{{unhealthyResourceCount}}</p>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6 left">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Project Health</h4>
                        <p>Refreshed:{{ refreshedTimeFormat(projectHealthData.refreshTime)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="refreshData('projectHealthData')"><img
                                src="/assets/health-monitoring/refresh.svg" alt=""></span>
                    </div>
                </div>
            </div>
            <div class="chart-ht" echarts [loading]='true' [options]="projectHealthData.options" *ngIf="projectHealthData.options  && !projectHealthData?.showLoader"></div>
            <div class="loader-gif" *ngIf="projectHealthData?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!projectHealthData.options && !projectHealthData?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
        </div>
    </div>

    <div class="col-md-6 right">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Health by Web App</h4>
                        <p>Refreshed:{{ refreshedTimeFormat(projectHealthData.refreshTime)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="refreshData('webApp')"><img
                                src="/assets/health-monitoring/refresh.svg" alt=""></span>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="projectHealthData.options  && !projectHealthData?.showLoader">
                <div class="data-box" [ngClass]="list?.health?.class == 'red' ? 'error' : 'success'" *ngFor="let list of resourceList">
                    <span class="strip-status" [ngClass]="list?.health?.class"></span>
                    <span class="content-14 ml-3">{{list?.resourceName}}</span>
                    <span class="content-14 ml-auto mr-3">{{list?.health?.healthPercent}} %</span>
                 </div>
            </ng-container>
             <div class="loader-gif" *ngIf="projectHealthData?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!projectHealthData.options && !projectHealthData?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
        </div>
    </div>

    <div class="col-md-6 left">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Availability Health (%)</h4>
                        <p>Refreshed:{{ refreshedTimeFormat(availabilityData.refreshTime)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="refreshData('availability')"><img
                                src="/assets/health-monitoring/refresh.svg" alt=""></span>
                    </div>
                </div>
            </div>
            <div class="chart-ht" echarts [loading]='true' [options]="availabilityData.options" *ngIf="availabilityData.options  && !availabilityData?.showLoader"></div>
            <div class="loader-gif" *ngIf="availabilityData?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!availabilityData.options && !availabilityData?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
        </div>
    </div>

    <div class="col-md-6 right">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Server Response Time Health (%)</h4>
                        <p>Refreshed:{{ refreshedTimeFormat(serverResponse.refreshTime)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="refreshData('serverResponse')"><img
                                src="/assets/health-monitoring/refresh.svg" alt=""></span>
                    </div>
                </div>
            </div>
            <div class="chart-ht" echarts [loading]='true' [options]="serverResponse.options" *ngIf="serverResponse.options  && !serverResponse?.showLoader"></div>
            <div class="loader-gif" *ngIf="serverResponse?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!serverResponse.options && !serverResponse?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
        </div>
    </div>

    <div class="col-md-6 left">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Failed Requests Health (%)</h4>
                        <p>Refreshed:{{ refreshedTimeFormat(failedRequest.refreshTime)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="refreshData('failedRequest')"><img
                                src="/assets/health-monitoring/refresh.svg" alt=""></span>
                    </div>
                </div>
            </div>
            <div class="chart-ht" echarts [loading]='true' [options]="failedRequest.options" *ngIf="failedRequest.options  && !failedRequest?.showLoader"></div>
            <div class="loader-gif" *ngIf="failedRequest?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!failedRequest.options && !failedRequest?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
        </div>
    </div>

    <div class="col-md-6 right">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>CPU Usage Health (%)</h4>
                        <p>Refreshed:{{ refreshedTimeFormat(cpuUsage.refreshTime)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="refreshData('cpuUsage')"><img
                                src="/assets/health-monitoring/refresh.svg" alt=""></span>
                    </div>
                </div>
            </div>
            <div class="chart-ht" echarts [loading]='true' [options]="cpuUsage.options" *ngIf="cpuUsage.options  && !cpuUsage?.showLoader"></div>
            <div class="loader-gif" *ngIf="cpuUsage?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!cpuUsage.options && !cpuUsage?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
        </div>
    </div>

    <div class="col-md-6 left">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Memory Available Health (%)</h4>
                        <p>Refreshed:{{ refreshedTimeFormat(memoryAvailable.refreshTime)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="refreshData('memoryAvailable')"><img
                                src="/assets/health-monitoring/refresh.svg" alt=""></span>
                    </div>
                </div>
            </div>
            <div class="chart-ht" echarts [loading]='true' [options]="memoryAvailable.options" *ngIf="memoryAvailable.options  && !memoryAvailable?.showLoader"></div>
            <div class="loader-gif" *ngIf="memoryAvailable?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!memoryAvailable.options && !memoryAvailable?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
        </div>
    </div>
</div>