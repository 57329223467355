 <div class="azure-support">
        <p class="heading" id="0">Azure Support</p>
        <p class="mb-0" >You can get Azure certified resources for building custom applications and 
            deployments on Azure with A3S.  </p>
            <img src="assets\user-manual\support\List(1).svg" >
        <ul>
        <li>Click <b>create new ticket</b>.</li>
         <li>Fill the form below to provide your requirements to A3S support team.  </li>
         <img src="assets\user-manual\support\Support-Form(1).svg" >
         <li>Click <b>submit</b>. </li>
         <li>Support team will work with you to provide the needed Azure certified resources 
             per your requirements.  </li>
        </ul>
 </div>
  