import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
@Component({
  selector: 'app-application-monitoring-bucket',
  templateUrl: './application-monitoring-bucket.component.html',
  styleUrls: ['./application-monitoring-bucket.component.scss']
})
export class ApplicationMonitoringBucketComponent implements OnInit {
  applicationMonitorMenu: any;
  projectList: any = [];
  currentProject: any;
  currentResource: any;
  currentProjectData: any;
  currentUrl: any;
  projectHealthSubs: any;
  masterCurrentProjectData: any;
  currrentTab: any;

  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    var $target = $(event.target);
    this.applicationMonitorMenu.forEach(e=> {
      let element = document.getElementById(e.menu)
      if(!$target.closest(element).length && !$target.closest("#no-access-tooltip").length){
         e.restrictAccess = false
      }
    })
  }


  constructor(private util:UtilService,private router: Router) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event) => {
        this.currentUrl = event['urlAfterRedirects']
        console.log('current url',this.currentUrl)
    })
    if(!this.currentUrl) this.currentUrl = this.router.url
   }

  async ngOnInit(){
    this.applicationMonitorMenu= this.util.getCache('applicationMonitorMenus');
    this.projectHealthSubs = this.util.getObservable().subscribe((res) => {
      if(res.projectHealth && res.projectHealth){
        let healthData = res.projectHealth
        this.projectList.forEach(x=>{
          if(x.projectId == this.currentProjectData.projectId) x['healthClass'] = healthData.class
        })
      }
    });
    await this.getResourceTypeStaticData()
    this.getBlueprintProject()
    console.log(this.applicationMonitorMenu);
  }

  triggerMenuClick(menu){
    this.currrentTab = menu;
    let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
    if(data && data.scope.some(x=> x.id == menu.tabId)) menu['restrictAccess'] = false
    else return menu['restrictAccess'] = true;
    this.closeOthers(menu.menu)
    if(this.allowSubMenus(menu)) {
      this.subMenuOpen(menu.menu)
      this.setCurrentProject(menu.projectList[0],0)
    }
  }

  getResourceTypeStaticData(){
    return this.util.handleRequest('get','a3s_static_getByTable',['dbo','resourceTypes'],null,null,null,true).then(res=>{
      if(!res || !res.recordset.length) return;
      this.util.setCache('resourceTypeList',res.recordset)
    })
  }

  ngOnDestroy() {
    if(this.projectHealthSubs) this.projectHealthSubs.unsubscribe();
  }

  getImage(list){
    if(!list.healthClass) return 'assets/global-filter/strong.svg'
    switch(list.healthClass){
      case 'green': return 'assets/global-filter/strong.svg'
      case 'red': return 'assets/global-filter/weak.svg'
    }
  }

  async getBlueprintProject(){
    if(!this.applicationMonitorMenu.length) {
      this.applicationMonitorMenu= this.util.getStatic('applicationMonitorMenus');
      let applicationMonitorMenu=this.applicationMonitorMenu;
        applicationMonitorMenu.map(dt=>{
        dt.projectList=[];
        return dt;
      })
      var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
      var createdBy = get_Email.userName;
      let keys=Object.keys(sessionStorage);
      let userObjectId;
      for(let i=0;i<keys.length;i++){
        try{
          if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
            userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
            break;
          }
        }catch(e){}
      }
      let userProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getAllProjects',[createdBy,userObjectId])
      let orgProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getOrgProjects',[localStorage.getItem('id')])
      let providerList = await this.util.handleRequest('get','a3s_applicationHealthMonitor_getProviderList',[],null,null,null,true);
      if(providerList) {
          let data=providerList.body.value||[];
          data=data.filter(dt=>dt.properties.providerSettings.providerType=='SapHana' || dt.properties.providerSettings.providerType=='SapNetWeaver');
          this.util.setCache('masterProviderList',data)
      }
      let list :any[]= [...userProject,...orgProject]
      let tempList = list.filter(e=> e.subscriptionId && e.status == 'DEPLOYED')
      Promise.all(tempList.map(async e=>{
        let data;
        if(!e.isManual || (e.isManual && e.isSpecificResource)){
            data = await this.getProjectResource(e.projectId)
        }
        else if(e.isManual){
          data = await this.getAllResources(e)
        }
        e['resourceData'] = data
        return {projectData:e,resourceData:data}
      })).then(async final=>{
        await Promise.all(final.map(async e=>{
          let filter = e.resourceData.filter(e=> this.filterResourceTypes(e))
          if(filter.length) {
            applicationMonitorMenu.map(dt=>{
              if(dt.route!='/a3s/applicationMonitorBucket/saphana' && dt.route!='/a3s/applicationMonitorBucket/sapNetWeaver'){
                  dt.projectList.push(e.projectData);
              }
              return dt;
            })
          }
          let providerList : any[]=this.util.getCache('masterProviderList');
          let ipData : any= providerList.filter(x=> x.properties.providerSettings.providerType=='SapHana').map(xe=> `'${xe.properties.providerSettings.hostname}'`)
          let ipResponse;
          if(ipData.length){
              ipData = ipData.join(',')
              let body ={ ipAddr : ipData }
              ipResponse = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getVMDetailsByGraphQuery',[],body,null,null,true)
          }
          await Promise.all(providerList.map(async xe=>{
              xe['resourceName'] = null
              if(xe.properties.providerSettings.providerType=='SapNetWeaver'){
                  xe['resourceName'] = xe.properties.providerSettings.sapHostname.split('.')[0]
              }
              else if(xe.properties.providerSettings.providerType=='SapHana'){
                  let filterData = ipResponse.body.data.filter(x=> x.privateIP == xe.properties.providerSettings.hostname)
                  xe['resourceName'] = filterData.length ? filterData[0].virtualMachine.split('/')[filterData[0].virtualMachine.split('/').length - 1] : null
              }
          }))
          providerList = providerList.filter(ex=> e.resourceData.some(x=> x.resourceName.toLowerCase() == ex.resourceName.toLowerCase()))
          this.util.setCache('providerList',providerList)
          if(providerList&&providerList.length&&e.resourceData.find(e=> this.filterSAPResourceTypes(e))) {
            if(providerList.filter(dt=>dt.properties&&dt.properties.providerSettings.providerType=='SapNetWeaver').length) {
                applicationMonitorMenu.map(dt=>{
                    if(dt.route=='/a3s/applicationMonitorBucket/sapNetWeaver'){
                        dt.projectList.push(e.projectData);
                    }
                    return dt;
                  })
            } 
            if(providerList.filter(dt=>dt.properties&&dt.properties.providerSettings.providerType=='SapHana').length) {
                applicationMonitorMenu.map(dt=>{
                    if(dt.route=='/a3s/applicationMonitorBucket/saphana'){
                        dt.projectList.push(e.projectData);
                    }
                    return dt;
                })
            }
          }
        }))
        this.applicationMonitorMenu=applicationMonitorMenu.filter(dt=>dt.projectList.length);
        let index=this.applicationMonitorMenu.findIndex(dt=>this.currentUrl.includes(dt.route)? true :false);
        this.triggerMenuClick(this.applicationMonitorMenu[index]);
        // this.subMenuOpen(this.applicationMonitorMenu[index].menu);
        // this.setCurrentProject(this.applicationMonitorMenu[index].projectList[0],0)
        console.log('Filter Project List',this.applicationMonitorMenu)
      })
    } else {    
      let index=this.applicationMonitorMenu.findIndex(dt=>this.currentUrl.includes(dt.route)? true :false);
      this.triggerMenuClick(this.applicationMonitorMenu[index]);
    // this.setCurrentProject(this.applicationMonitorMenu[0].projectList[0],0)
    }
    console.log('Filter Project List',this.applicationMonitorMenu)
  }

  getProjectResource(id){
     return this.util.handleRequest('get','a3s_architectureBlueprint_getProjectResourceListForDashboards',[id],null,null,null,true)
  }

  async setCurrentProject(list,index){
    list['currentResource'] = null
    list['currentResourceGroup'] = null
    this.currentResource = null;
    if(!list.isManual || (list.isManual && list.isSpecificResource)){
      var resourcesArray = await this.getProjectResource(list.projectId)
      var resourceGroupsArray = []
      resourcesArray.forEach(e=>{
        let rg = JSON.parse(e.resourceData)
        e['resourceGroupName'] = rg.hasOwnProperty('resourceGroup') ? rg.resourceGroup : null
        e['projectId'] = list.projectId
        e['resourceType'] = this.splitWordByCaps(e.resourceType)
        e['subscriptionId'] = list.subscriptionId
        rg.hasOwnProperty('resourceGroup') ? resourceGroupsArray.push({resourceGroupName:rg.resourceGroup,projectId:list.projectId,resourceType:e.resourceType}) : null
      })
      var uniqueRG = this.util.unique(resourceGroupsArray,['resourceGroupName'])
      list['resourceGroups'] = uniqueRG
      list['resources'] = resourcesArray.filter(e=> this.filterResourceTypes(e))
      let webAppData = await this.getWebApp(list.subscriptionId)
      let onboardedWebAppData = await this.getOnboardedWebApp(list.subscriptionId)
      onboardedWebAppData.recordset.forEach(e=>{
        webAppData.forEach(x=>{
          if(e.applicationId == x.finalData.properties.AppId) e['webAppName'] = x.name
        })
      })
      list['resources'].forEach(e=>{
        onboardedWebAppData.recordset.forEach(y=>{
          if(e.resourceName == y.webAppName) {
            e['currentWebAppId'] = y.applicationId
            e['currentWebAppName'] = y.name
            e['currentWebAppKey'] = y.applicationKey
          }
        })
      })
      // list['resources'].forEach(e=>{
      //   webAppData.forEach(y=>{
      //     if(e.resourceName == y.name) {
      //       e['currentWebAppId'] = y.finalData.properties.AppId
      //       e['currentWebAppName'] = y.finalData.name
      //     }
      //   })
      // })
      list['resources'] = list['resources'].filter(e=> e.currentWebAppId )
      // Get Unique Resource Types and the resource counts
      var uniqueResourceType = this.util.unique(list.resources,['resourceType'])
      var filteredResourceType = uniqueResourceType.filter(e=> this.filterResourceTypes(e))
      filteredResourceType.forEach(e=>{
        var data = resourcesArray.filter(x=> x.resourceType.toLowerCase() == e['resourceType'].toLowerCase())
        e['count'] = data.length
      })
      list['resourceTypes'] = filteredResourceType // Used in side bar under each project
    }
    else if(list.isManual){
      let resourceData = await this.getAllResources(list)
      let resourcesArray = resourceData
      var resourceGroupsArray = []
      resourcesArray.forEach(e=>{
        e['resourceGroupName'] = e.id.split('/')[4]
        e['projectId'] = list.projectId
        e['subscriptionId'] = list.subscriptionId
        resourceGroupsArray.push({resourceGroupName: e['resourceGroupName'],projectId:list.projectId,resourceType:e.resourceType})
      })
      let filteredRg = resourceGroupsArray.filter(e=> this.filterResourceTypes(e))
      var uniqueRG = this.util.unique(filteredRg,['resourceGroupName'])
      list['resourceGroups'] = uniqueRG
      list['resources'] = resourcesArray.filter(e=> this.filterResourceTypes(e))
      let webAppData = await this.getWebApp(list.subscriptionId)
      let onboardedWebAppData = await this.getOnboardedWebApp(list.subscriptionId)
      onboardedWebAppData.recordset.forEach(e=>{
        webAppData.forEach(x=>{
          if(e.applicationId == x.finalData.properties.AppId) e['webAppName'] = x.name
        })
      })
      list['resources'].forEach(e=>{
        onboardedWebAppData.recordset.forEach(y=>{
          if(e.resourceName == y.webAppName) {
            e['currentWebAppId'] = y.applicationId
            e['currentWebAppName'] = y.name
            e['currentWebAppKey'] = y.applicationKey
          }
        })
      })
      // list['resources'].forEach(e=>{
      //   webAppData.forEach(y=>{
      //     if(e.resourceName == y.name) {
      //       e['currentWebAppId'] = y.finalData.properties.AppId
      //       e['currentWebAppName'] = y.finalData.name
      //     }
      //   })
      // })
      list['resources'] = list['resources'].filter(e=> e.currentWebAppId )
      // Get Unique Resource Types and the resource counts
      var uniqueResourceType = this.util.unique(list.resources,['resourceType'])
      var filteredResourceType = uniqueResourceType.filter(e=> this.filterResourceTypes(e))
      filteredResourceType.forEach(e=>{
        var data = resourcesArray.filter(x=> x.resourceType.toLowerCase() == e['resourceType'].toLowerCase())
        e['count'] = data.length
      })
      list['resourceTypes'] = filteredResourceType // Used in side bar under each project
    }
   
    if(this.currentProject == list.projectName) {
      this.currentProjectData = list
      this.masterCurrentProjectData = _.cloneDeep(this.currentProjectData) 
      // await this.util.delay(1000)
      // this.routeToParticularView()
      return this.util.setObservable('currentFilteredProject',list)
    }

    this.currentProject = list.projectName

    this.applicationMonitorMenu[0].projectList.forEach((e,i)=>{
      i == index ? '' : $('#types_' + i).siblings().slideUp('fast') // close all sub menus
    })
    this.subMenuOpen('types_'+index) // open sub menus

    this.currentProjectData = list
    this.masterCurrentProjectData = _.cloneDeep(this.currentProjectData) 
    // await this.util.delay(1000)
    // this.routeToParticularView()
    this.util.setObservable('currentFilteredProject',list)
  }

  async setCurrentResourceType(type){
    this.currentResource = type.resourceName
    this.currentProjectData['currentResource'] = type.resourceName
    this.currentProjectData['currentResourceGroup'] = type.resourceGroupName
    // let webAppData = await this.getOnboardedWebApp(this.currentProjectData.subscriptionId)
    this.currentProjectData['currentWebApp'] = type.currentWebAppId
    this.currentProjectData['currentWebAppId'] = type.currentWebAppId
    this.currentProjectData['currentWebAppKey'] = type.currentWebAppKey
    this.currentProjectData['currentWebAppName'] = type.currentWebAppName
    // this.currentProjectData['currentWebApp'] = webAppData[0]
    // this.currentProjectData['currentWebAppId'] = webAppData[0].finalData.properties.AppId
    let wait = await this.util.delay(300)
    this.util.route(['/a3s/applicationMonitorBucket/metricsUsage'],true);
    this.util.setObservable('currentFilteredProject',this.currentProjectData)
  }

  getWebApp(id){
   return this.util.handleRequest('get','a3s_applicationHealthMonitor_getWebApp',[id],null,null,null,true)
  }

  getOnboardedWebApp(id){
    return this.util.handleRequest('get','a3s_onboarded_applicationInsightsBySubs',[id],null,null,null,true)
   }

  async getAllResources(list){
    let resources = await this.util.handleRequest('get','a3s_management_getResourceTypes',[list.subscriptionId],null,null,null,true)
    let types = this.util.getCache('resourceTypeList')
    var array = []
    types.forEach(x=>{
      resources.body.value.forEach(e=>{
         if(x.azureType.toLowerCase() == e.type.toLowerCase()){
          e['resourceName']= e.name
          e['resourceType'] =x.formattedName
          array.push(e)
         }
      })
    })
    return array;
  }

  filterResourceTypes(list){
    let name = list['resourceType'].replace(/\s/g, '').toLowerCase()
    let includedList = ['webapp','appservices']
    return includedList.indexOf(name) > -1 ? true : false
  }

  filterSAPResourceTypes(list){
    let name = list['resourceType'].replace(/\s/g, '').toLowerCase()
    let includedList = ['azuremonitorforsapsolutions']
    return includedList.indexOf(name) > -1 ? true : false
  }


  routeToParticularView(){
    switch(this.currentUrl){
      case '/a3s/applicationMonitorBucket/dashboard':
      case '/a3s/applicationMonitorBucket/metricsUsage' :  this.util.route(['/a3s/applicationMonitorBucket/dashboard'],true);break;
      case '/a3s/applicationMonitorBucket/alerts' :  this.util.route(['/a3s/applicationMonitorBucket/alerts'],true);break;
    }
  }

  checkRouteActive(type){
    switch(type){
      case 'metricsUsage': return ['/a3s/applicationMonitorBucket/dashboard','/a3s/applicationMonitorBucket/metricsUsage'].indexOf(this.currentUrl) > -1 ? true : false
      case 'alerts': return ['/a3s/applicationMonitorBucket/alerts'].indexOf(this.currentUrl) > -1 ? true : false
      case 'saphana': return ['/a3s/applicationMonitorBucket/saphana'].indexOf(this.currentUrl) > -1 ? true : false
      case 'sapNetWeaver': return ['/a3s/applicationMonitorBucket/sapNetWeaver'].indexOf(this.currentUrl) > -1 ? true : false
    }
  }

  async subMenuOpen(id){
    if(!id.includes('types_')){
      $('#metricsUsage').removeClass('open').siblings().slideUp(300);
      $('#alerts').removeClass('open').siblings().slideUp(300);
      $('#saphana').removeClass('open').siblings().slideUp(300);
      $('#sapNetWeaver').removeClass('open').siblings().slideUp(300);
    }
    await this.util.delay(1000)
    $('#' + id).toggleClass('open').siblings().slideToggle(300);
  }

  closeOthers(id){
    $('#metricsUsage').removeClass('open').siblings().slideUp(300);
    $('#alerts').removeClass('open').siblings().slideUp(300);
    $('#saphana').removeClass('open').siblings().slideUp(300);
    $('#sapNetWeaver').removeClass('open').siblings().slideUp(300);
  }

  allowSubMenus(list){
    return ['Web Applications','Metrics & Usage','Alerts','SAP HANA','SAP NetWeaver'].indexOf(list.value) > -1 ? true : false
  }

  collapseSider() {
    $(".main-content").toggleClass('collapse-sidebar');
  }

  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  
  routeTo(){
    this.util.route(['/a3s/applicationMonitorBucket/metricsUsage'],true);
  }

  allowUrl(){
    return ['/a3s/applicationMonitorBucket/metricsUsage','/a3s/applicationMonitorBucket/saphana','/a3s/applicationMonitorBucket/sapNetWeaver'].indexOf(this.currentUrl)  > -1 ? true :false;
  }

  checkA3sAccess(){
    let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
    return data && data.scope.some(x=> this.currrentTab ? x.id == this.currrentTab.tabId : false) ? true : false;
  }

}
