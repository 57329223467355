import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-returned',
  templateUrl: './users-returned.component.html',
  styleUrls: ['./users-returned.component.scss']
})
export class UsersReturnedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
