<router-outlet></router-outlet>
<div class="header-container">
    <h2 class="title">Create Linux Virtual Machine</h2>
    <span class="content-14 blur-text">Deployment</span>
</div>

<div class="data-migrate-container">
    <div *ngIf="state == 'init'">
      <div class="process-stepper-container">
            <ng-container *ngFor="let steps of linuxVmStepper">
              <div class="stepper-header">
                <div class="stepper-icon" [ngClass]="getClass(steps)">
                  <div class="stepper-content">
                    <span *ngIf="!steps?.isCompleted && !steps?.isError">{{steps?.step}}</span>
                    <img src="assets/icons/tick.svg" *ngIf="steps?.isCompleted"> 
                    <!-- <img src="assets/icons/cross-fail.svg" *ngIf="steps?.isCompleted"> -->
                    <img src="assets/icons/cross-fail.svg" *ngIf="steps?.isError">
                  </div>
                </div>
                <div class="stepper-label-linux" [class.active]="step == steps?.limit && !steps?.isError" [class.bold-600]="steps?.isError || steps?.isCompleted">
                  {{steps?.value}}</div>
              </div>
              <div class="stepper-horizontal-line-linux" [class.active]="step >= steps?.limit + 1"></div>
            </ng-container>
      </div>
    </div>

    <ng-container [ngSwitch]="currentProcess">
        <ng-container *ngSwitchCase="'submitBasicInfo'">
            <form action="#" class="mt-3" #sourceForm="ngForm">
                <div class="upper-section">
                <div class="form-group row">
                    <label for="lname" class="control-label col-sm-2">Subscription<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6">
                    <ng-select [items]="subscriptions" (change)="getRgBySubscription()" bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.subscription" name="subscription" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="control-label col-sm-2">Resource Group<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6">
                      <ng-select [items]="ResourceGroups" bindLabel="name" bindValue="name" [(ngModel)]="model.resourceGroup" name="resourceGroup" placeholder="Choose resource group" [clearable]="false" required></ng-select>
                      <a href="" class="blue-text text-12-18" >Create new</a>
                    </div>
                </div>
                <br>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Resource Group Location<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.resource" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngSwitchCase="'selectDiskType'">
          <form action="#" class="mt-3" #sourceForm="ngForm">  
            <div class="upper-section">
              <div class="form-group row">
                  <label for="diskType" class="control-label col-sm-2">OS disk type<span class="mandatory">*</span></label>
                  <div class="col-sm-6">
                  <ng-select [items]="diskType"  bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.diskType" name="diskType" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                  </div>
              </div>
              <div class="form-group row">
                <label for="encryptionType" class="control-label col-sm-2">Encryption type<span class="mandatory">*</span></label>
                <div class="col-sm-6">
                <ng-select [items]="diskType"  bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.encryptionType" name="diskType" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                </div>
            </div>
            <div class="form-group row">
              <label for="email" class="control-label col-sm-2">Enable Ultra Disk compatibility<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
              <div class="col-sm-6 mt-2">
              <input type="checkbox" [(ngModel)]="model.enableUltraDisk" height="14px" width="14px" disabled>
              <div><span class="blur-text mt-2 text-12-18">Ultra disk is available only for Availability Zones in eastus.</span></div>
              </div>
            </div>
            <div class="form-group row">
              <span class="control-label col-sm-8">You can add and configure additional data disks for your virtual machine. This VM also comes with a temporary disk.</span>
            </div> 
            <a href="" class="blue-text text-12-18" >Create and attach a new disk</a>
          </div>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="'submitNetworkInfo'">
          <form action="#" class="mt-3" #sourceForm="ngForm">
            <div class="upper-section">
            <div class="form-group row">
                <label for="lname" class="control-label col-sm-2">Virtual Network<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                <ng-select [items]="subscriptions" (change)="getRgBySubscription()" bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.virtualNetwork" name="subscription" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                <a href="" class="blue-text text-12-18" >Create new</a>
                </div>
            </div>
            <div class="form-group row">
                <label for="email" class="control-label col-sm-2">Virtual network address space<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                  <ng-select [items]="ResourceGroups" bindLabel="name" bindValue="name" [(ngModel)]="model.virtualNetworkAddressSpace" name="resourceGroup" placeholder="Choose resource group" [clearable]="false" required></ng-select>
                </div>
            </div>
            <div class="form-group row">
              <label for="password" class="control-label col-sm-2">Virtual network location<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
              <div class="col-sm-6">
                <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.virtualNetworkLocation" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
              </div>
            </div>
            <div class="form-group row">
              <label for="password" class="control-label col-sm-2">Subnet<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
              <div class="col-sm-6">
                <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnet" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
              </div>
            </div>
            <div class="form-group row">
              <label for="password" class="control-label col-sm-2">Subnet address space<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
              <div class="col-sm-6">
                <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnetAddressSpace" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
              </div>
            </div>
            </div>
        </form>
        </ng-container>
        <ng-container *ngSwitchCase="'submitVmInfo'">
            <form action="#" class="mt-3" #sourceForm="ngForm">
              <div class="upper-section">
                <div class="form-group row">
                    <label for="lname" class="control-label col-sm-2">Virtual machine name<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6">
                    <ng-select [items]="subscriptions" (change)="getRgBySubscription()" bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.virtualNetwork" name="subscription" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="control-label col-sm-2">Virtual machine location <span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6">
                      <ng-select [items]="ResourceGroups" bindLabel="name" bindValue="name" [(ngModel)]="model.virtualNetworkAddressSpace" name="resourceGroup" placeholder="Choose resource group" [clearable]="false" required></ng-select>
                    </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Virtual machine size<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.virtualNetworkLocation" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Computer name<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnet" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">User name<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnetAddressSpace" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Password<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnetAddressSpace" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Confirm password<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnetAddressSpace" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="horizontal-line"></div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 right-align">
                  <a href="">+ Add another virtual machine</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </form>
        </ng-container>
    </ng-container>
</div>

<div class="fixed-btm-btn flex-custom no-wrap">
  <button class="btn btn-outline mr-16"
  *ngIf="currentProcess == 'selectDiskType' || currentProcess=='submitNetworkInfo' || currentProcess=='submitVmInfo'"
  (click)="goToPreviousStep()">Back</button>
    <button class="btn btn-btn-primary"
      *ngIf="currentProcess == 'submitBasicInfo' || currentProcess == 'selectDiskType' || currentProcess=='submitNetworkInfo'"
      (click)="moveToNextStep()">Next</button>
    <button class="btn btn-btn-primary" *ngIf="currentProcess == 'submitVmInfo'" [class.disabled]="checkDisable()"
      (click)="moveToNextStep()">Deploy</button>

    <div class="form-group btm-checkbox" *ngIf="currentProcess == 'selectAnalysisType' ">
      <input type="checkbox" class="checkbox-input" name="store-output" checked disabled><span>Store Output in Synapse SQL Pool</span> 
    </div>

    <button class="btn btn-btn-primary" *ngIf="currentProcess == 'submitInfo'" (click)="routeToListPage()">Finish</button>

    <button class="btn btn-no-outline ml-auto" *ngIf="currentProcess == 'submitBasicInfo' || currentProcess == 'selectDiskType' || currentProcess=='submitNetworkInfo'" (click)="openModal(cancelAnalytics)">Cancel</button>
</div> 
<!-- [class.disabled]="checkDisable()" -->

<ng-template #createNewResourceGroup>

</ng-template>