import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { UtilService } from '../../../services/util.service';
import { NewResourceGroup, ResourceInputError } from '../resources-model';

@Component({
  selector: 'app-new-resource-group',
  templateUrl: './new-resource-group.component.html',
  styleUrls: ['./new-resource-group.component.scss']
})
export class NewResourceGroupComponent implements OnInit {
  @Input() anchor:any;
  @Input() show:boolean;
  @Input() projectData:any;
  @Input() resourceGroupList:any;
  @Input() newResourceGroup:NewResourceGroup = {
    resourceGroupId: null,
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  }
  @Output('onToggle') triggerBtnvalue = new EventEmitter<NewResourceGroup>();
  
  resourcesGroupLocationList: any = [];
  inputError:ResourceInputError = {
    maxLenResourceName:false,
    duplicateResourceName:false,
    validResourceName:false
  };
  rgSubscriptionCheck:boolean=false;

  constructor(private util:UtilService) { }

  ngOnInit(): void {
    this.resourcesGroupLocationList = this.util.getStatic('countryList');  
  }

  checkDisable(): boolean {
    return !this.newResourceGroup.resourceGroupName || !this.newResourceGroup.resourceGroupLocation
  }

  validateResourceName(event, limitNum){
    let value =event.target.value;
    if (value.length > limitNum) {
      this.inputError.maxLenResourceName = true;
      return;
    }
    else {
      this.inputError.maxLenResourceName = false;
    }

    let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(value[value.length-1]=='.'||regex.test(value)){
      this.inputError.validResourceName=true;
      return;
    }else{
      this.inputError.validResourceName=false;
    }

    if(this.resourceGroupList&&this.resourceGroupList.length&&value){
      let findObj=this.resourceGroupList.find(dt=>dt.name.toLowerCase()===value.toLowerCase()&&!dt.newResourceGroupEdit);
      this.inputError.duplicateResourceName = findObj?true:false;
    }else {
      this.inputError.duplicateResourceName = false;
    }
  }

  cancel() {
    this.onToggle(false);
  }

  onToggle(flag:boolean): void {
    if (flag) {
      this.triggerBtnvalue.emit(this.newResourceGroup);
    } else {
      this.triggerBtnvalue.emit(null);
    }
    this.show = !this.show;
    this.newResourceGroup = {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  async createNewRgHelper(){
    this.newResourceGroup['newResourceGroupEdit']=false;
    await this.createNewResourceGroup();
    // this.onToggle();    
  }

  async createNewResourceGroup(){
    this.newResourceGroup.subscriptionId=this.projectData.subscriptionId;
    this.newResourceGroup.subscription=this.projectData.subscription;
    let result  = _.cloneDeep(this.newResourceGroup);
    result['status']='Pending';
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    result['createdBy'] = userName;
    result['projectId']=this.projectData.projectId;
    this.util.handleRequest('post','a3s_architectureBlueprint_addNewResourceGroup',[],result,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      this.newResourceGroup.resourceGroupId=res.resourceGroupId;
      this.onToggle(true);
    })
  }

  updateNewResourceGroup(index){
    let request=_.cloneDeep(this.newResourceGroup);    
    this.util.handleRequest('put','a3s_architectureBlueprint_updateNewResourceGroup',[],request,null,null,false).then(async res=>{
      if(!res){
        return false;
      }
      this.newResourceGroup['newResourceGroupEdit']=false;
      this.onToggle(true);
    });
  }

}
