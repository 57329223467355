<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':routeTablemodel.routesShow||routeTablemodel.subnetsShow }">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Route Table</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="routeTablemodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(null)">Create new
                    </p>
                </div>
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="Name" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar || inputError?.duplicateAi" id="routeTable" autocomplete="off" [(ngModel)]="routeTablemodel.resourceName" placeholder="Enter a name" name="routeTable" (keyup)="VmNameValidation($event,255);aiDuplicateCheck($event)" required>
                <p class="text-danger" *ngIf="inputError?.duplicateAi">The name must be unique in the current resource group.</p>
                <!-- <p class="text-danger" *ngIf="inputError?.duplicateAi">The name must be unique in the current resource group.</p> -->
                <p class="text-danger" *ngIf="inputError?.VmSpecialChar || inputError?.VMname">The name must contain between 1 to 255 characters inclusive. The name only allows alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>

        
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="routeTablemodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
    <div *ngIf="showView">
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="propagateGatewayRoutes" class="control-label ">Propagate gateway routes<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select \'no\', to prevent the propagation of on-premises routes to the network interfaces in associated subnets." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="propagateGatewayRoutes" [value]="'Yes'" ([ngModel])="routeTablemodel.resourceData.propagateGatewayRoutes" [checked]="routeTablemodel.resourceData.propagateGatewayRoutes=='Yes'" (change)="routeTablemodel.resourceData.propagateGatewayRoutes=$event.target.value;" ><span>Yes</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="propagateGatewayRoutes" [value]="'No'" ([ngModel])="routeTablemodel.resourceData.propagateGatewayRoutes" [checked]="routeTablemodel.resourceData.propagateGatewayRoutes=='No'" (change)="routeTablemodel.resourceData.propagateGatewayRoutes=$event.target.value;"><span>No</span></label>                    
            </div>             
        </div>

        <div class="form-group">
        <div class="label-div">
            <label for="routes" class="control-label">Route</label> 
        </div>
        <div class="input-box">
            <ng-select [items]="routesList" bindLabel="name" [(ngModel)]="routeTablemodel.resourceData.routes" name="routes" placeholder="" [clearable]="false"></ng-select>
        </div>   
        <div class="add-another-tag">
            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="routeTablemodel.routesShow=true;">Add route</p>             
        </div>  
        </div>

        <div class="form-group">
        <div class="label-div">
            <label for="routes" class="control-label">Subnet</label> 
        </div>
        <div class="input-box">
            <ng-select [items]="subnetsList" bindLabel="name" [(ngModel)]="routeTablemodel.resourceData.subnets" name="subnets" placeholder="" [clearable]="false"></ng-select>
        </div>   
        <div class="add-another-tag">
            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="routeTablemodel.subnetsShow=true;">Associate subnet</p>             
        </div>  
        </div>

    
                <div class="form-group">
                    <div class="label-div d-flex">
                        <label class="control-label " style="min-width: max-content;">Tag name </label>
                        <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                        </label>
                        <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="routeTablemodel.resourceData&&routeTablemodel.resourceData.tagList&&routeTablemodel.resourceData.tagList.length>1"></label>
                        <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                    </div>
                    <div *ngFor="let item of routeTablemodel.resourceData.tagList;let i = index">
                        <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
    
                        <div class="input-box" style="padding-bottom: 6px;">
                            <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
    
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
    
                        <div class="input-box">
                            <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
    
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="routeTablemodel.resourceData.tagList.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                        <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                        <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                        <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
    
                    <div class="add-another-tag">
                        <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                    </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('routeTable')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('routeTable')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="routeTablemodel.routesShow">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Add Route</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="routeTablemodel.routesShow=false;">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
                            
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Route Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="routesObj.name" placeholder="Enter a name" name="name" (keyup)="laNameValidation($event,1,80);lawDuplicateCheck($event)" required>
                <p class="text-danger" *ngIf="inputError?.laname">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="inputError?.laSpecialChar">The name can contain only letters, numbers and '-'. The '-' shouldn't be the first or the last symbol. </p>
                <p class="text-danger" *ngIf="inputError?.VmSpecialChar">The name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                <p class="text-danger" *ngIf="inputError?.duplicateLaw">The name must be unique in the current resource group.</p>
                </div>              
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="addressPrefix" class="control-label ">Address prefix<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The destination IP address range in CIDR notation that this route applies to. If the destination IP address of a packet falls in this range, it matches this route." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" *ngFor="let item of routesObj.resourceData.virtualNetworkAddressSpace;let i = index">
                    <div class="d-flex">
                        <input type="text" class="form-control" [class.border-danger]="item.VNetAdrspc" id="VNetAdrSpc" autocomplete="off" [(ngModel)]="routesObj.addressPrefix" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace" (keyup)="VNetAddressSpace($event,item,i)" required>
                        <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete.png" (click)="deleteAddress(routesObj,i)" *ngIf="routesObj.resourceData.virtualNetworkAddressSpace.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use {{item.validCIDRValue}} address space</p>
                    <p class="text-danger" *ngIf="item.overlooping">overlaps with address space {{item.overlapingValue}}</p>
                    <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
                </div> 
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="nextHopType" class="control-label">Next hop type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The next hop handles the matching packets for this route. It can be the virtual network, the virtual network gateway, the Internet, a virtual appliance, or none." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="nextHopTypeList" bindLabel="name" bindValue="id" [(ngModel)]="routesObj.nextHopType" name="nextHopType" placeholder="" [clearable]="false"></ng-select>
                </div>   
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="nextHopIpAddress" class="control-label">Next hop address<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The IP address of the next hop. This can be set only when the 'Next hop type' is 'Virtual appliance'." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" *ngFor="let item of routesObj.resourceData.virtualNetworkAddressSpace;let i = index">
                    <div class="d-flex">
                        <input type="text" class="form-control" [class.border-danger]="item.VNetAdrspc" id="VNetAdrSpc" autocomplete="off" [disabled]="routesobj.nextHopType=='VirtualNetworkGateway' && 'VirtualNetwork' && 'Internet' && 'None'" [(ngModel)]="routesObj.nextHopIpAddress" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace" (keyup)="VNetAddressSpace($event,item,i)" required>
                        <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete.png" (click)="deleteAddress(routesObj,i)" *ngIf="routesObj.resourceData.virtualNetworkAddressSpace.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use {{item.validCIDRValue}} address space</p>
                    <p class="text-danger" *ngIf="item.overlooping">overlaps with address space {{item.overlapingValue}}</p>
                    <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
                </div>
            </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('routesObj')" (click)="saveSubSideSheet('routesObj')">Save</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('lawObj')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
</div>

<div class="sidebar-right" *ngIf="routeTablemodel.subnetsShow">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Associate Subnet</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="routeTablemodel.subnetsShow=false;">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
                            
            <div class="form-group">
                <div class="label-div">
                    <label for="VNetName" class="control-label ">Virtual network<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only virtual networks in the currently selected subscription and location are listed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="existingVNList" bindLabel="name" [(ngModel)]="subnetsObj.VNetName" name="VNetName" placeholder="" (change)="getVirtualNetworkList()" [clearable]="false" required>
                    </ng-select>
                </div>              
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="SubnetName" class="control-label">Subnet<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="existingSubnetList" bindLabel="name" [(ngModel)]="subnetsObj.subnetName" name="subnetName" placeholder="" (change)="getSubnetList()" [clearable]="false" required>
                    </ng-select>
                </div>              
            </div>
            
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('subnetsObj')" (click)="saveSubSideSheet('subnetsObj')">Save</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('lawObj')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
</div>
</div>