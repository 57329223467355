import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';

import { SecurityBucketRoutingModule } from './security-bucket-routing.module';
import { SecurityBucketComponent } from './security-bucket.component';
import { SecurityOverviewComponent } from './security-posture/security-overview/security-overview.component';
import { SecurityPostureComponent } from './security-posture/security-posture.component';
import { SecurityRecommendationsComponent } from './security-recommendations/security-recommendations.component';
import { SecurityAlertManagementComponent } from './security-alert-management/security-alert-management.component';
import { IdentitySecurityComponent } from './security-posture/identity-security/identity-security.component';
import { EndpointsSecurityComponent } from './security-posture/endpoints-security/endpoints-security.component';
import { ActivitiesSecurityComponent } from './security-posture/activities-security/activities-security.component';
import { ApplicationSecurityComponent } from './security-posture/application-security/application-security.component';
import { NetworkSecurityComponent } from './security-posture/network-security/network-security.component';
import { EchartsModule } from '../common/echarts/echarts.module';
import { DirectiveModule } from '../directive/directive.module';
import { PipesModule } from '../pipes/pipes.module';
import { BreadcrumbsModule } from '../common/breadcrumbs/breadcrumbs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { IdentitySecurityLinuxComponent } from './security-posture/identity-security-linux/identity-security-linux.component';
import { EndpointSecurityLinuxComponent } from './security-posture/endpoint-security-linux/endpoint-security-linux.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActivitiesSecurityLinuxComponent } from './security-posture/activities-security-linux/activities-security-linux.component';
import { ActivitiesSecurityWindowsComponent } from './security-posture/activities-security-windows/activities-security-windows.component';
import { EndpointSecurityWindowsComponent } from './security-posture/endpoint-security-windows/endpoint-security-windows.component';
import { IdentitySecurityWindowsComponent } from './security-posture/identity-security-windows/identity-security-windows.component';
import { EndpointSecurityWindowsLinuxComponent } from './security-posture/endpoint-security-windows-linux/endpoint-security-windows-linux.component';
import { IdentitySecurityWindowsLinuxComponent } from './security-posture/identity-security-windows-linux/identity-security-windows-linux.component';


@NgModule({
  declarations: [
    SecurityBucketComponent,
    SecurityOverviewComponent,
    SecurityPostureComponent,
    SecurityRecommendationsComponent,
    SecurityAlertManagementComponent,    
    IdentitySecurityComponent,
    EndpointsSecurityComponent,
    ActivitiesSecurityComponent,
    ApplicationSecurityComponent,
    NetworkSecurityComponent,
    IdentitySecurityLinuxComponent,
    EndpointSecurityLinuxComponent,
    ActivitiesSecurityLinuxComponent,
    ActivitiesSecurityWindowsComponent,
    EndpointSecurityWindowsComponent,
    IdentitySecurityWindowsComponent,
    EndpointSecurityWindowsLinuxComponent,
    IdentitySecurityWindowsLinuxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SecurityBucketRoutingModule,
    DirectiveModule,
    PipesModule,
    EchartsModule,
    BreadcrumbsModule,
    NgxEchartsModule.forRoot({
      echarts
    }),
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    UiSwitchModule,
    ReactiveFormsModule,
    NgSelectModule,
    AngularMaterialModule
  ],
  providers: [
    TitleCasePipe,
    DatePipe
  ]
})
export class SecurityBucketModule { }
