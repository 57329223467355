import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-close-page',
  templateUrl: './close-page.component.html',
  styleUrls: ['./close-page.component.scss']
})
export class ClosePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  closeWindow(){
    window.close();
    return false;
  }
}
