<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':virtualMachinemodel.publicIPShow||virtualMachinemodel.newDiskShow||virtualMachinemodel.nsgShow||virtualMachinemodel.availabilitySetShow||virtualMachinemodel.storageAccountShow}">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4><span *ngIf="virtualMachinemodel.resourceType=='sqlVirtualMachines'">SQL </span><span *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachines'">Linux </span>Virtual Machine</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="virtualMachinemodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(null)">Create new
                    </p>
                </div>
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label for="VNetName" class="control-label ">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" id="VNetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="virtualMachinemodel.resourceData.vnetName" name="VirtualNetworkName" disabled>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="SubnetName" class="control-label ">Subnet<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" id="SubnetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="virtualMachinemodel.resourceData.subnet" name="SubnetName" disabled>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="VMname" class="control-label">Virtual machine name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="All virtual machines in a subscription must have the distinct name." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar || inputError?.VMOnlyNUmbers || inputError?.duplicateVnet" id="VMname" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceName" placeholder="Enter virtual machine name" name="projectName" (keyup)="VmNameValidation($event,65)" required>
                <p class="text-danger" *ngIf="inputError?.VMname">The value must be between 1 and 64 characters long.</p>
                <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Azure resource names cannot contain special characters \/""[]:|<>+=;,?*@&,
                    whitespace, or begin with '_' or end with '.' or '-'</p>
                <p class="text-danger" *ngIf="inputError?.VMOnlyNUmbers">Virtual machine name cannot contain only numbers.</p>
                <p class="text-danger" *ngIf="inputError?.duplicateVnet">This name is already taken.</p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label">Virtual machine size<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select a VM size to support the workload that you want to run. The size that you choose then determines factors such as processing power, memory, and storage capacity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" *ngIf="virtualMachinemodel.resourceData&&virtualMachinemodel.resourceData.selectedVMSize" style="font-size: 12px;font-weight: 400;">
                    <span style="font-size: 13px;font-weight: 600;">{{virtualMachinemodel?.resourceData?.selectedVMSize?.name}}</span>
                    <br>
                        <span> {{virtualMachinemodel?.resourceData?.selectedVMSize?.capabilities[2]?.value}}</span> CPU -
                        <span> {{virtualMachinemodel?.resourceData?.selectedVMSize?.capabilities[5]?.value}}</span> RAM 
                        <span *ngIf="virtualMachinemodel?.resourceData?.selectedVMSize?.newCapabilities?.UncachedDiskIOPS?.value">- {{virtualMachinemodel?.resourceData?.selectedVMSize?.newCapabilities?.UncachedDiskIOPS?.value}} Max IOPS</span>
                </div> 
                <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="triggerBtn('vmSize')">Choose size</p> 
            </div>            
            </div> 
            <div class="form-group" style="margin-bottom: 10px !important;"  *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachines'">
                <div class="label-div">
                    <label for="authType" class="control-label ">Authentication type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose whether the administrator account will use 
                        username/password or SSH keys for authentication." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box radio-flex">
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="authType" [value]="'ssh'" ([ngModel])="virtualMachinemodel.resourceData.authType" [checked]="virtualMachinemodel.resourceData.authType=='ssh'" (change)="virtualMachinemodel.resourceData.authType=$event.target.value;"><span>SSH</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="authType" [value]="'password'" ([ngModel])="virtualMachinemodel.resourceData.authType" [checked]="virtualMachinemodel.resourceData.authType=='password'" (change)="virtualMachinemodel.resourceData.authType=$event.target.value;"><span>Password</span></label>                                    
                </div>             
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="adminUsername" class="control-label">Admin username<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The administrator username for the VM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.VMAdminReserv || inputError?.VmAdminSpecialChar || inputError?.VMAdminUsername" id="adminUsername" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.adminUsername" placeholder="" name="adminUsername" (keyup)="VmAdminUsernameValidation($event,20)">
                <p class="text-danger" *ngIf="inputError?.VMAdminReserv">Usernames must not include reserved words.</p>
                <p class="text-danger" *ngIf="inputError?.VmAdminSpecialChar">Username must only contain letters, numbers, hyphens, and underscores and may not start with a hyphen or number.</p>
                <p class="text-danger" *ngIf="inputError?.VMAdminUsername">The value is in between 1 and 20 characters long.</p>
                
                </div>              
            </div>
            <div class="form-group" *ngIf="virtualMachinemodel.resourceType!='linuxVirtualMachines'||virtualMachinemodel.resourceData.authType=='password'">
                <div class="label-div">
                    <label for="adminPassword" class="control-label">Admin password<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The administrator password for the VM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="password" class="form-control" [class.border-danger]="inputError?.VMAdminPassword || inputError?.VmAdminPasswordLen" id="adminPassword" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.adminPassword" placeholder="" name="adminPassword" (keyup)="VmAdminPasswordValidation($event,123,12)">
                <p class="text-danger" *ngIf="inputError?.VMAdminPassword">Password must have 3 of the following: 1 lower case character, 1 upper case character, 1 number, and 1 special character.</p>
                <p class="text-danger" *ngIf="inputError?.VmAdminPasswordLen">The value must be between 12 and 123 characters long.</p>
                </div>              
            </div>
            <div class="form-group"  *ngIf="virtualMachinemodel.resourceType!='linuxVirtualMachines'||virtualMachinemodel.resourceData.authType=='password'">
                <div class="label-div">
                    <label for="adminConfirmPassword" class="control-label">Confirm password<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="password" class="form-control" [class.border-danger]="inputError?.VMAdminPasswordMatch" id="adminConfirmPassword" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.adminConfirmPassword" placeholder="" name="adminConfirmPassword" (keyup)="VMAdminConfirmPassword($event)">
                <p class="text-danger" *ngIf="inputError?.VMAdminPasswordMatch">Password and confirm password must match.</p>
                </div>              
            </div>
            <div class="form-group" *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachines'&&virtualMachinemodel.resourceData.authType=='ssh'">
                <div class="label-div">
                    <label for="sshPublicKey" class="control-label">SSH public key source<span></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="sshPublicKeyList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.sshPublicKey" name="sshPublicKey" placeholder="" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group" *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachines'&&virtualMachinemodel.resourceData.authType=='ssh'&&virtualMachinemodel.resourceData.sshPublicKey=='genNewKeyPair'">
                <div class="label-div">
                    <label for="keyPairName" class="control-label">Key pair name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.keyPairNameSpChar||inputError?.keyPairNameDup" id="keyPairName" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.keyPairName" placeholder="" name="keyPairName" (keyup)="keyPairNameValidation($event)" required>
                    <p class="text-danger" *ngIf="inputError?.keyPairNameSpChar">Azure resource names cannot contain special characters \/""[]:|<>+=;,?*@&,
                        whitespace, or begin with '_' or end with '.' or '-'</p>
                    <p class="text-danger" *ngIf="inputError?.keyPairNameDup">SSH key pair name must be unique.</p>
                    </div>    
            </div>
            <div class="form-group" *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachines'&&virtualMachinemodel.resourceData.authType=='ssh'&&virtualMachinemodel.resourceData.sshPublicKey=='useExistKeyAzure'">
                <div class="label-div">
                    <label for="storedKeys" class="control-label">Stored Keys<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="storedKeysList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.storedKeys" name="storedKeys" placeholder="" [clearable]="false"></ng-select>  
                </div>
            </div>
            <div class="form-group" *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachines'&&virtualMachinemodel.resourceData.authType=='ssh'&&virtualMachinemodel.resourceData.sshPublicKey=='useExistPublicKey'">
                <div class="label-div">
                    <label for="publicKey" class="control-label">SSH public key<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an RSA public key in the single-line format (starting with ssh-rsa\) 
                        or the multi-line PEM format. You can generate SSH keys using ssh-keygen on Linux 
                        and OS X, or PuTTYGen on Windows." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <textarea class="form-control" [class.border-danger]="inputError?.publicKeyValid||inputError?.sshKeyBase64Valid" id="publicKey" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.publicKey" placeholder="" name="publicKey" (keyup)="publicKeyValidation($event)" required></textarea>
                    <p class="text-danger" *ngIf="inputError?.publicKeyValid">Multiline SSH key must begin with '---- BEGIN SSH2 PUBLIC KEY ----' 
                        and end with '---- END SSH2 PUBLIC KEY ----'.</p>
                    <p class="text-danger" *ngIf="inputError?.sshKeyBase64Valid">SSH key must be encoded in base64.</p>
                    </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="virtualMachinemodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
            <div *ngIf="showView">
                <div class="form-group">
                    <div class="label-div">
                        <label for="availabilityOption" class="control-label">Availablity options<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="An Availability Zone is a physically separate zone, within an Azure region. 
                            An availability set is a logical grouping of VMs that allows Azure to 
                            understand how your application is built to provide for redundancy
                                and availability." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="virtualMachinemodel.resourceData.availabilityOptionList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.availabilityOption" name="availabilityOption" placeholder="Choose" [clearable]="false" (change)="changeAvailablity()" ></ng-select>
                    </div>   
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceData.availabilityOption=='availabilityZone'">
                    <div class="label-div">
                        <label for="availabilityZone" class="control-label">Availablity zone<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can optionally specify an availability zone in which to deploy your VM. 
                            If you choose to do so, your managed disk and public IP (if you have one) 
                            will be created in the same availability zone as your virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="virtualMachinemodel.resourceData.tempAvailabilityZoneList" bindLabel="name" bindValue="name" [(ngModel)]="virtualMachinemodel.resourceData.availabilityZone" name="availabilityZone" placeholder="" [clearable]="false" (change)="changeAvailablity()" ></ng-select>
                    </div>   
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceData.availabilityOption=='availabilitySet'">
                    <div class="label-div">
                        <label for="availabilitySet" class="control-label">Availablity set<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="An availability set is a logical grouping of VMs that allows Azure to understand how your application is built to provide for redundancy and availability. We recommended that two or more VMs are created within an availability set to provide for a highly available application and to meet the 99.95% Azure SLA" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="availabilitySetList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.availabilitySet" name="availabilitySet" placeholder="" [clearable]="false"></ng-select>
                    </div> 
                    <div class="add-another-tag">
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewVmAS()">Create new</p>  
                    </div>  
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="image" class="control-label">Image<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the base operating system or application for the VM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="imageList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.image" name="image" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="osDiskType" class="control-label">OS disk type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can choose between Azure managed disks 
                            types to support your workload or scenario." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="osDiskTypeList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.osDiskType" name="osDiskType" placeholder="Choose" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="osDiskType" class="control-label">Disk encryption type</label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="diskEncryptionTypeList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.diskEncryptionType" name="osDiskType" placeholder="Choose" (change)="changeEncryptionType('newVm')" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceData.diskEncryptionType!='1'">
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                        <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                        <label class="" style="padding: 8px 0px !important;color: #703501;">
                            Once a customer-managed key is used, 
                            you can't change the selection back to 
                            a platform-managed key.</label>
                    </div>  
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceData.diskEncryptionType!='1'">
                    <div class="label-div">
                        <label for="diskEncryptionSet" class="control-label">Disk encryption set<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="diskEncryptionSetList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.diskEncryptionSet" name="diskEncryptionSet" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="diskName" class="control-label">Data disks</label>
                    </div>
                    <div class="label-div d-flex" *ngIf="virtualMachinemodel.resourceData.newDiskList&&virtualMachinemodel.resourceData.newDiskList.length>0">
                        <label class="control-label " style="min-width: max-content;width: 90px;">LUN</label>
                        <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Name
                        </label>
                    </div>
                    <div *ngFor="let item of virtualMachinemodel.resourceData.newDiskList;let i = index">
                        <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
    
                        <div class="input-box" style="padding-bottom: 6px;width: 74px;">
                            <input type="text" class="form-control" id="LUNNum#{{i}}" autocomplete="off" [class.border-danger]="item?.maxLUNNumLen || item?.VMOnlyNUmbers || item?.validEmpty" [(ngModel)]="item.LUNNum" name="LUNNum#{{i}}" (keyup)="checkLUNNumInput(item,64,i)">
                        </div>
    
                        <div style="padding-right: 30px;"></div>
    
                        <div class="input-box" style="width: 52%">
                            <!-- <ng-select [items]="virtualMachinemodel.resourceData.newDiskList" [closeOnSelect]="true" bindLabel="name" [(ngModel)]="item.diskName" name="diskName" placeholder="Choose" [clearable]="false">
                            </ng-select> -->
                            <label class="control-label " style="padding-left: 6.5px !important;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            padding-top: 4px !important;">{{item.diskName}}
                            </label>
                        </div>
    
                        <span><img src="/assets/icons/edit.png" (click)="editDisk(i)"></span>
                        <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" (click)="deleteDisk(i)"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.maxLUNNumLen">LUN must be less than 64.</p>
                        <p class="text-danger" *ngIf="item.VMOnlyNUmbers">Value can be numbers only</p>
                        <p class="text-danger" *ngIf="item.validEmpty">Value can't be empty</p>
                    </div>
    
                    <div class="add-another-tag">
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewDisk()">Create and attach a new disk</p>             
                    </div>
                    </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="publicIp" class="control-label">Public IP<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use a public IP address if you want to communicate
                            with the virtual machine from outside the virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="tempVmPublicIPList" [closeOnSelect]="true" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.publicIp" name="publicIp" placeholder="Choose" [clearable]="false">
                        </ng-select>
                    </div> 
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewPublicIP()">Create new</p>  
                </div>
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="NIC" class="control-label ">NIC network security group<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A network security group contains security rules that allow or deny inbound 
                            network traffic to, or outbound network traffic from, the virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box radio-flex">
                        <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="NIC" [value]="'None'" ([ngModel])="virtualMachinemodel.resourceData.nicNSG" [checked]="virtualMachinemodel.resourceData.nicNSG=='None'" (change)="virtualMachinemodel.resourceData.nicNSG=$event.target.value;"><span>None</span></label>
                        <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="NIC" [value]="'Basic'" ([ngModel])="virtualMachinemodel.resourceData.nicNSG" [checked]="virtualMachinemodel.resourceData.nicNSG=='Basic'" (change)="virtualMachinemodel.resourceData.nicNSG=$event.target.value;"><span>Basic</span></label>                    
                        <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="NIC" [value]="'Advanced'" ([ngModel])="virtualMachinemodel.resourceData.nicNSG" [checked]="virtualMachinemodel.resourceData.nicNSG=='Advanced'" (change)="virtualMachinemodel.resourceData.nicNSG=$event.target.value;"><span>Advanced</span></label>                    
                    </div>             
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceData.nicNSG=='Advanced'">
                    <div class="label-div">
                        <label for="VMNSG" class="control-label">Network Security Group<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select class="margin-select" [items]="nsgList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.networkSecurityGroup" name="VMNSG" placeholder="Choose Network Security Group" [clearable]="false"></ng-select>
                    </div>
                    <div class="add-another-tag">   
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewNSG()">Create new</p>
                </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight">Monitoring</label>
                        <label for="bootDiagnostics" class="control-label ">Boot diagnostics<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use this feature to troubleshoot boot failures for custom or platform images." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box radio-flex">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;padding-bottom: 16px;"><input type="radio" name="bootDiagnostics" [value]="'EWMSA'" ([ngModel])="virtualMachinemodel.resourceData.bootDiagnostics" [checked]="virtualMachinemodel.resourceData.bootDiagnostics=='EWMSA'" (change)="virtualMachinemodel.resourceData.bootDiagnostics=$event.target.value;"><span>Enable with managed storage account (recommended)</span></label>
                        <label class="col-sm-11 radio-label" style="padding-left: 0px !important;"><input type="radio" name="bootDiagnostics" [value]="'EWCSA'" ([ngModel])="virtualMachinemodel.resourceData.bootDiagnostics" [checked]="virtualMachinemodel.resourceData.bootDiagnostics=='EWCSA'" (change)="virtualMachinemodel.resourceData.bootDiagnostics=$event.target.value;"><span>Enable with custom storage account</span></label>                    
                        <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="bootDiagnostics" [value]="'Disable'" ([ngModel])="virtualMachinemodel.resourceData.bootDiagnostics" [checked]="virtualMachinemodel.resourceData.bootDiagnostics=='Disable'" (change)="virtualMachinemodel.resourceData.bootDiagnostics=$event.target.value;"><span>Disable</span></label>                    
                    </div>             
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                    </div>
                    <div class="input-box">
                        <label class="col-sm-10 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enableOSGuestDiagnostics" id="enableOSGuestDiagnostics" [(ngModel)]="virtualMachinemodel.resourceData.enableOSGuestDiagnostics" ><span>Enable OS guest diagnostics<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use your corporate Active Directory credentials to log in to the VM, 
                            enforce MFA, and enable access via RBAC roles." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>             
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel?.resourceData?.enableOSGuestDiagnostics||virtualMachinemodel.resourceData.bootDiagnostics=='EWCSA'">
                    <div class="label-div">
                        <label for="DSA" class="control-label">Diagnostics storage account<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Get metrics every minute for your virtual machine. You can 
                            use them to create alerts and stay informed on your applications." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="storageAccountList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.diagnosticsStorageAccount" name="DSA" placeholder="Choose" [clearable]="false"></ng-select>
                    </div>   
                    <div class="add-another-tag">   
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewStorageAcount()">Create new</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight">Identity</label>
                    </div> 
                    <div class="input-box">
                        <label class="col-sm-11 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="systemAssignedManagedIdentity" id="systemAssignedManagedIdentity" [disabled]="virtualMachinemodel.resourceData.loginWithAzureAD" [(ngModel)]="virtualMachinemodel.resourceData.systemAssignedManagedIdentity" ><span>System assigned managed identity<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A system assigned managed identity enables Azure resources to
                            authenticate to cloud services (e.g. Azure Key Vault) without storing
                            credentials in code. Once enabled, all necessary permissions can be
                            granted via Azure role-based access control. The lifecycle of this type 
                            of managed identity is tied to the lifecycle of this resource. Additionally,
                            each resource (e.g. Virtual Machine) can only have one system 
                            assigned managed identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="label-div  d-flex" *ngIf="virtualMachinemodel.resourceData.loginWithAzureAD" style="background: #F4FBFF;border: 1px solid #4AB5FB;box-sizing: border-box;border-radius: 2px;margin: 10px 0px;">
                        <label class="" style="padding: 11.33px 9.33px 0px 9.33px !important;"><img
                            src="/assets/Infra/blueprint/popup-error-info.png" alt=""></label>
                        <label style="font-size: 12px;color: #21262C;padding: 8px 12px 0px 0px;">System managed identity must be on to login with Azure AD credentials.</label>
                        </div>           
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight">Azure AD</label>
                    </div> 
                    <div class="input-box">
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="loginWithAzureAD" id="loginWithAzureAD" [disabled]="disabledLoginAD()" [(ngModel)]="virtualMachinemodel.resourceData.loginWithAzureAD" (change)="virtualMachinemodel.resourceData.loginWithAzureAD?virtualMachinemodel.resourceData.systemAssignedManagedIdentity=true:''" ><span>Login with Azure AD<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use your corporate Active Directory credentials to log in 
                            to the VM, enforce MFA, and enable access via RBAC roles." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>  
                    <div class="label-div  d-flex" *ngIf="!disabledLoginAD()"  style="background: #F4FBFF;border: 1px solid #4AB5FB;box-sizing: border-box;border-radius: 2px;">
                        <label class="" style="padding: 11.33px 9.33px 0px 9.33px !important;"><img
                            src="/assets/Infra/blueprint/popup-error-info.png" alt=""></label>
                        <label style="font-size: 12px;color: #21262C;padding: 8px 12px 0px 0px;">RBAC role assignment of Virtual Machine Administrator Login or Virtual Machine 
                            User Login is required when using Azure AD login.</label>
                        </div>   
                        <div class="label-div d-flex" *ngIf="disabledLoginAD()" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                        <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                        <label class="" style="padding: 8px 0px !important;color: #703501;">
                            This image does not support Login with Azure AD</label>
                    </div>       
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label class="control-label text-weight">Auto-shutdown</label>
                    </div> 
                    <div class="input-box">
                        <label class="col-sm-8 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="autoShutdownStatus" id="autoShutdownStatus" [(ngModel)]="virtualMachinemodel.resourceData.autoShutdownStatus" ><span>Enable auto-shutdown<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures your virtual machine to automatically shutdown daily." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>           
                </div>
                <div class="form-group"  *ngIf="virtualMachinemodel.resourceData.autoShutdownStatus">
                    <div class="label-div">
                        <label for="autoShutdownTime" class="control-label">Shutdown time<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures the time when the virtual machine shuts down daily." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                    </div> 
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.validTimeFormat" id="autoShutdownTime" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.autoShutdownTime" placeholder="h:mm:ss A" name="autoShutdownTime" (keyup)="autoShutdownTimeValidation($event)" required>
                    <p class="text-danger" *ngIf="inputError?.validTimeFormat">Time format must match 'h:mm:ss A'.</p>
                    </div>    
                </div>
                <div class="form-group"  *ngIf="virtualMachinemodel.resourceData.autoShutdownStatus">
                    <div class="label-div">
                        <label for="timeZone" class="control-label">Time zone<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures the time zone in which the auto-shutdown time is." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                    </div> 
                    <div class="input-box">
                        <ng-select class="margin-select" [items]="timeZoneList" bindLabel="time" bindValue="name" [(ngModel)]="virtualMachinemodel.resourceData.timeZone" name="timeZone" placeholder="" [clearable]="false"></ng-select>
                    </div>         
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceData.autoShutdownStatus" style="margin-bottom: 10px !important;">
                    <div class="input-box">
                        <label class="col-sm-10 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="autoShutdownNotificationStatus" id="autoShutdownNotificationStatus" [(ngModel)]="virtualMachinemodel.resourceData.autoShutdownNotificationStatus" ><span>Notification before shutdown<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Subscribe for notifications before the virtual machine is 
                            shutdown so that you can skip/delay the shutdown." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>           
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceData.autoShutdownStatus&&virtualMachinemodel.resourceData.autoShutdownNotificationStatus">
                    <div class="label-div">
                        <label for="emailVM" class="control-label">Email<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The email address where notifications will be sent." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                    </div> 
                    <div class="input-box">
                        <input type="email" class="form-control" [class.border-danger]="emailVM?.errors?.email||emailVM?.invalid" id="emailVM" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.email" placeholder="" name="emailVM" email #emailVM="ngModel" required>
                    <p class="text-danger" *ngIf="emailVM?.errors?.email||emailVM?.invalid">Please provide a valid email address.</p>
                    </div>          
                </div>
                <div class="form-group">

                    <div class="label-div d-flex">
                        <label class="control-label " style="min-width: max-content;">Tag name </label>
                        <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                        </label>
                        <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="virtualMachinemodel.resourceData&&virtualMachinemodel.resourceData.tagList&&virtualMachinemodel.resourceData.tagList.length>1"></label>
                        <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                    </div>
                    <div *ngFor="let item of virtualMachinemodel.resourceData.tagList;let i = index">
                        <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
    
                        <div class="input-box" style="padding-bottom: 6px;">
                            <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
    
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
    
                        <div class="input-box">
                            <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
    
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="virtualMachinemodel.resourceData.tagList.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                        <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                        <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                        <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
    
                    <div class="add-another-tag">
                        <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                    </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="virtualMachinemodel.publicIPShow">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Public IP</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.publicIPShow=false;">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="publicIPObj?.nameLen || publicIPObj?.nameValid || publicIPObj?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="publicIPObj.name" placeholder="" name="name" (keyup)="vmPublicIPValidation($event,80)" required>
                <p class="text-danger" *ngIf="publicIPObj?.nameLen">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="publicIPObj?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                <p class="text-danger" *ngIf="publicIPObj?.nameDupCheck">This public IP is already there in subscription</p>
                </div>              
            </div>
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="SKU" class="control-label ">SKU<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Standard: Always use static allocation method.&#13;Basic: Assigned with the static or dynamic allocation method." matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="SKU" [value]="'Basic'" ([ngModel])="publicIPObj.SKU" [checked]="publicIPObj.SKU=='Basic'" (change)="changeSKU($event)"><span>Basic</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="SKU" [value]="'Standard'" ([ngModel])="publicIPObj.SKU" [checked]="publicIPObj.SKU=='Standard'" (change)="changeSKU($event)"><span>Standard</span></label>                    
                </div>             
            </div>
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="assignment" class="control-label ">Assignment</label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;" *ngIf="publicIPObj.SKU=='Basic'"><input type="radio" name="assignment" [value]="'Dynamic'" ([ngModel])="publicIPObj.assignment" [checked]="publicIPObj.assignment=='Dynamic'" (change)="publicIPObj.assignment=$event.target.value;"><span>Dynamic</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="assignment" [value]="'Static'" ([ngModel])="publicIPObj.assignment" [checked]="publicIPObj.assignment=='Static'" (change)="publicIPObj.assignment=$event.target.value;"><span>Static</span></label>                    
                </div>             
            </div>
            <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="publicIPObj.SKU=='Standard'">
                <div class="label-div">
                    <label for="routingPreference" class="control-label ">Routing preference<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure routing preference enables you to choose how your traffic routes between Azure and the Internet. You can choose to route traffic either via the Microsoft network, or, via the ISP network (public internet)" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="routingPreference" [value]="'microsoftNetwork'" ([ngModel])="publicIPObj.routingPreference" [checked]="publicIPObj.routingPreference=='microsoftNetwork'" (change)="publicIPObj.routingPreference=$event.target.value;"><span>Microsoft network</span></label>
                    <label class="col-sm-3 radio-label" style="padding-left: 0px !important;"><input type="radio" name="routingPreference" [value]="'Internet'" ([ngModel])="publicIPObj.routingPreference" [checked]="publicIPObj.routingPreference=='Internet'" (change)="publicIPObj.routingPreference=$event.target.value;"><span>Internet</span></label>                    
                </div>             
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('publicIp')" (click)="saveSubSideSheet('publicIp')">Ok</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="virtualMachinemodel.newDiskShow">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Create a new disk</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.newDiskShow=false;">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="newDiskObj?.nameLen || newDiskObj?.nameValid || newDiskObj?.duplicateName" id="name" autocomplete="off" [(ngModel)]="newDiskObj.name" placeholder="" name="name" (keyup)="vmDiskNameValidation($event,80)" required>
                <p class="text-danger" *ngIf="newDiskObj?.nameLen">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="newDiskObj?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                <p class="text-danger" *ngIf="newDiskObj?.duplicateName">This disk is already there in subscription</p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="sourceType" class="control-label">Source type<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Create a disk from a snapshot of another disk, 
                        a blob in a storage account, or create an empty disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="sourceTypeList" bindLabel="name" bindValue="name" [(ngModel)]="newDiskObj.sourceType" name="sourceType" placeholder="Choose" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group" *ngIf="newDiskObj.sourceType=='Snapshot'">
                <div class="label-div">
                    <label for="snapshot" class="control-label">Snapshot<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a snapshot from another disk to use as the source of this new disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="snapshotList" bindLabel="name" [(ngModel)]="newDiskObj.snapshot" name="snapshot" placeholder="Choose" [clearable]="false" (change)="changeSanpshot(newDiskObj)" ></ng-select>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="size" class="control-label">Size<span class="mandatory">*</span></label>
                </div>
                <div class="input-box" style="font-size: 12px;font-weight: 400;" *ngIf="newDiskObj.newVMDiskSize">
                    <span style="font-size: 13px;font-weight: 600;">{{newDiskObj?.newVMDiskSize?.customDiskSize}} GiB</span>
                    <br>
                    <span>{{newDiskObj?.newVMDiskSize?.diskSKU?.name}} LRS</span>
                </div> 
                <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="triggerBtn('newDisk')">{{newDiskObj?.newVMDiskSize?.customDiskSize?"Change ":"Choose "}}size</p>  
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="encryptionType" class="control-label">Encryption type<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="diskEncryptionTypeList" bindLabel="name" bindValue="id" [(ngModel)]="newDiskObj.encryptionType" name="encryptionType" placeholder="Choose" (change)="changeEncryptionType('newDisk')" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group" *ngIf="newDiskObj.encryptionType!='1'">
                <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                        Once a customer-managed key is used, 
                        you can't change the selection back to 
                        a platform-managed key.</label>
                </div>  
            </div>
            <div class="form-group" *ngIf="newDiskObj.encryptionType!='1'">
                <div class="label-div">
                    <label for="diskEncryptionSet" class="control-label">Disk encryption set<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="diskEncryptionSetListForDisk" bindLabel="name" bindValue="id" [(ngModel)]="newDiskObj.diskEncryptionSet" name="diskEncryptionSet" placeholder="" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="esd" class="control-label ">Enable shared disk<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to be attached to two or more virtual machines, 
                        depending on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'Yes'" [disabled]="disabledEnableDiskShare(newDiskObj)" ([ngModel])="newDiskObj.enableSharedDisk" [checked]="newDiskObj.enableSharedDisk=='Yes'" (change)="newDiskObj.enableSharedDisk=$event.target.value;changeMaxShareList($event,newDiskObj)"><span>Yes</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'No'" [disabled]="disabledEnableDiskShare(newDiskObj)" ([ngModel])="newDiskObj.enableSharedDisk" [checked]="newDiskObj.enableSharedDisk=='No'" (change)="newDiskObj.enableSharedDisk=$event.target.value;changeMaxShareList($event,newDiskObj)"><span>No</span></label>                    
                </div>  
                <!-- <p class="text-muted-msg" *ngIf="newDiskObj?.newVMDiskSize?.selectVmDiskSizeData&&(newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.name!='Premium_LRS'||newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.capabilities[6]?.value==1)">Shared disk not available for the selected size.</p>            -->
            </div>
            <div class="form-group" *ngIf="newDiskObj?.newVMDiskSize?.selectVmDiskSizeData&&(newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.name!='Premium_LRS'||newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.capabilities[6]?.value==1)">
                <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                        Shared disk not available for the selected size.</label>
                </div>  
            </div>
            <div class="form-group" *ngIf="newDiskObj.enableSharedDisk=='Yes'">
                <div class="label-div">
                    <label for="maxShares" class="control-label">Max shares<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The number of max shares a shared disk has 
                        depends on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="maxSharesList" bindLabel="name" bindValue="name" [(ngModel)]="newDiskObj.maxShares" name="maxShares" placeholder="Select disk share" [clearable]="false"></ng-select>
                </div>   
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="newDiskObj.updateIndex==undefined" [class.disabled]="checkDataDisabled('newDisk')" (click)="saveSubSideSheet('newDisk')">Ok</button>
                <button class="btn btn-btn-primary" *ngIf="newDiskObj.updateIndex!=undefined" [class.disabled]="checkDataDisabled('newDisk')" (click)="updateSubSideSheet('newDisk')">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="virtualMachinemodel.nsgShow">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Create network security group</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.nsgShow=false;">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="vmNSGObj.name" placeholder="" name="name" (keyup)="checkNSGName($event,80)" required>
                <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                <p class="text-danger" *ngIf="inputError?.nameDupCheck">This network security group is already there in subscription</p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="inboundRules" class="control-label">Inbound rules<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add rules to allow or deny traffic to this virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" style="font-size: 12px;font-weight: 400;">
                    <div style="border-left: solid 2px #2989E9;padding-left: 8px;margin-bottom: 8px;" *ngFor="let item of vmNSGObj.vmInBoundRule;let i=index">
                        <span>{{item?.priority}}</span>: <span>{{item?.name}}</span>
                        <span class="pull-right" style="cursor: pointer;" (click)="deleteRules('inboundRules',i)"><img src="/assets/icons/delete_new.png" alt=""></span>
                        <span class="pull-right" style="padding-right: 11.33px;cursor: pointer;" (click)="editRules('inboundRules',i)"><img src="/assets/icons/edit.png" alt=""></span>
                        <br>
                        <span>{{item?.source}}</span>
                        <br>
                        <span>{{item?.service}}</span><span>({{item?.protocol}}/{{item?.destinationPortRange}})</span>
                    </div>
                </div>      
                <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="vmNSGObj.updateIndex=undefined;triggerBtn('inboundRules')">Add an inbound rule</p>  
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="outboundRules" class="control-label">Outbound rules<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add rules to allow or deny traffic originating from this virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" style="font-size: 12px;font-weight: 400;">
                    <div style="border-left: solid 2px #2989E9;padding-left: 8px;margin-bottom: 8px;" *ngFor="let item of vmNSGObj.vmOutBoundRule;let i=index">
                        <span>{{item?.priority}}</span>: <span>{{item?.name}}</span>
                        <span class="pull-right" style="cursor: pointer;" (click)="deleteRules('outboundRules',i)"><img src="/assets/icons/delete_new.png" alt=""></span>
                        <span class="pull-right" style="padding-right: 11.33px;cursor: pointer;" (click)="editRules('outboundRules',i)"><img src="/assets/icons/edit.png" alt=""></span>                            
                        <br>
                        <span>{{item?.source}}</span>
                        <br>
                        <span>{{item?.service}}</span><span>({{item?.protocol}}/{{item?.destinationPortRange}})</span>
                    </div>
                </div>  
                <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="vmNSGObj.updateIndex=undefined;triggerBtn('outboundRules')">Add an outbound rule</p>  
                </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('nsg')" (click)="saveSubSideSheet('nsg')">Ok</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="virtualMachinemodel.availabilitySetShow">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Availability set</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.availabilitySetShow=false;">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="vmASObj.name" placeholder="" name="name" (keyup)="checkVmASName($event,80)" required>
                <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                <p class="text-danger" *ngIf="inputError?.nameDupCheck">This availability set is already there in subscription</p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="faultDomains" class="control-label">Fault domains<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the same fault domain share 
                        a common power source and physical network switch." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="number" class="form-control remove-arrows" id="faultDomains" [class.border-danger]="inputError?.asFaultDomainsLen||inputError?.asFaultDomainsMaxLen" autocomplete="off" [(ngModel)]="vmASObj.faultDomains" placeholder="" name="faultDomains" (keyup)="checkfaultDomains($event,vmASObj)" required>
                <p class="text-danger" *ngIf="inputError?.asFaultDomainsLen">Fault domains must be at least 1</p>
                <p class="text-danger" *ngIf="inputError?.asFaultDomainsMaxLen">Fault domains can not be at more than 3
            </p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="updateDomains" class="control-label">Update domains<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the same update domain will be restarted 
                        together during planned maintenance. Azure never restarts 
                        more than one update domain at a time" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="number" class="form-control remove-arrows" [class.border-danger]="inputError?.asUpdateDomainsLen || inputError?.asUpdateDomainsMaxLen" [disabled]="vmASObj.faultDomains==1" id="updateDomains" autocomplete="off" [(ngModel)]="vmASObj.updateDomains" placeholder="" name="updateDomains" (keyup)="checkUpdateDomains($event,20)" required>
                <p class="text-danger" *ngIf="inputError?.asUpdateDomainsLen">Update domains must be at least 1</p>
                <p class="text-danger" *ngIf="inputError?.asUpdateDomainsMaxLen">Update domains can not be at more than 20</p>
                <p class="text-muted-msg" *ngIf="vmASObj.faultDomains==1">The update domain count must be 1 when fault domain count is 1.</p>
                </div>              
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('vmASObj')" (click)="saveSubSideSheet('vmASObj')">Ok</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="virtualMachinemodel.storageAccountShow">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Storage Account</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.storageAccountShow=false;">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName || inputError?.storageAccountChar || inputError?.duplicateSa" id="storageAccountName" autocomplete="off" [(ngModel)]="vmStorageAccount.name" placeholder="Enter storage account name" name="StorageAccountName" (keyup)="storageAccountNameValidation($event,3,24);" required>
                    <p class="text-danger" *ngIf="inputError?.storageAccountName">The name must be between 3 and 24 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.storageAccountChar">The field can contain only lowercase letters and numbers.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateSa">The storage account name is already taken.</p>
                </div>                
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="kind" class="control-label">Account kind<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="General purpose storage accounts provide storage for blobs, 
                        files, tables, and queues in a unified account." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="accountKindList" bindLabel="name" bindValue="id" [(ngModel)]="vmStorageAccount.kind" name="kind" (change)="filterStorageSkus(vmStorageAccount.performance,vmStorageAccount.kind);" placeholder="" [clearable]="false">
                    </ng-select>
                </div>   
            </div>
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="performance" class="control-label ">Performance<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Standard storage accounts are backed by magnetic drives 
                        and provide the lowest cost per GB. " matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="performance" [value]="'Standard'" ([ngModel])="vmStorageAccount.performance" [disabled]="true" [checked]="vmStorageAccount.performance=='Standard'" (change)="vmStorageAccount.performance=$event.target.value;filterStorageSkus(vmStorageAccount.performance,vmStorageAccount.kind);"><span>Standard</span></label>
                    <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="performance" [value]="'Premium'" ([ngModel])="vmStorageAccount.performance" [disabled]="true" [checked]="vmStorageAccount.performance=='Premium'" (change)="vmStorageAccount.performance=$event.target.value;filterStorageSkus(vmStorageAccount.performance,vmStorageAccount.kind);"><span>Premium</span></label>
                </div>             
            </div>
    
            <div class="form-group">
                <div class="label-div">
                    <label for="redundancy" class="control-label">Replication<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The data in your Azure storage account is always replicated 
                        to ensure durability and high availability. " matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="tempStorageSkusList" bindLabel="rdname" bindValue="name" [(ngModel)]="vmStorageAccount.redundancy" name="redundancy" placeholder="" [clearable]="false"></ng-select>
                </div>   
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('vmStorageAccount')" (click)="saveSubSideSheet('vmStorageAccount')">Ok</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="loader-fs" *ngIf="apiloader">
    <img class="loader"  src="./assets/spinloader.gif">
</div> -->