import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-application-monitoring-metrics-usage',
  templateUrl: './application-monitoring-metrics-usage.component.html',
  styleUrls: ['./application-monitoring-metrics-usage.component.scss']
})
export class ApplicationMonitoringMetricsUsageComponent implements OnInit {
  currentTab : any = 'metrics'

  masterFilterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  };
  searchFilterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  };
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  searchKey: any;
  subscriptionApp: Subscription;
  currentProjectData: any;
  constructor(private util:UtilService) { }

  ngOnInit(): void {
    this.subscriptionApp = this.util.getObservable().subscribe((res) => {
      if(res.currentFilteredProject){
        this.currentProjectData = res.currentFilteredProject
      }
    });
  }

  ngOnDestroy() {
    if(this.subscriptionApp) this.subscriptionApp.unsubscribe();
  }
    
  setTab(tab){
    this.currentTab = tab
    this.util.setObservable('currentFilteredProject',this.currentProjectData)
  }
}
