<div class="sidebar-right">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Subnet</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="VNetName" class="control-label ">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" id="VNetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="subnetModel.resourceData.vnetName" name="VirtualNetworkName" disabled>
                </div>              
            </div>
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="subnetType" class="control-label ">Subnet type</label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="subnetType" [value]="'subnet'" ([ngModel])="subnetModel.resourceData.subnetType" [checked]="subnetModel.resourceData.subnetType=='subnet'" (change)="selectedSubnetType($event)" ><span>Subnet</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="subnetType" [value]="'gatewaySubnet'" ([ngModel])="subnetModel.resourceData.subnetType" [checked]="subnetModel.resourceData.subnetType=='gatewaySubnet'" (change)="selectedSubnetType($event)"><span>GatewaySubnet</span></label>                    
                </div>             
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="VMname" class="control-label col-sm-2">Subnet name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A subnet is a range of IP addresses in your virtual network, which can be used to isolate virtual machines from each other or from the Internet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.subnetName || inputError?.subnetChar" id="SubnetName" autocomplete="off" [disabled]="subnetModel.resourceData.subnetType=='gatewaySubnet'" [(ngModel)]="subnetModel.resourceName" placeholder="Enter subnet name" name="SubnetName" (keyup)="subnetNameValidation($event,80)" required>
                    <p class="text-danger" *ngIf="inputError?.subnetName">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetDuplicate">Subnet names must be unique within a virtual network.</p>
                    </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label col-sm-2">Subnet address space<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The subnet's address range in CIDR notation (e.g. 192.168.1.0/24). 
                        It must be contained by the address space of the virtual network. 
                        The address range of a subnet which is in use can't be edited." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.subnetAdrspc" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="subnetModel.resourceData.subnetAddressSpace" placeholder="eg. 10.0.0.0/24" name="SubnetAddressSpace" (keyup)="subnetAddressSpace($event)" required>
                    <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid CIDR Block.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetOverlaping">This subnet address space overlaps with existing {{subnetModel.overlapingValue}}</p>
                    <p class="text-danger" *ngIf="inputError?.subnetPerfix">The prefix must be smaller than or equal to 29</p>
                    <p class="text-danger" *ngIf="inputError?.subnetAddresNotInVnet">Subnet address range should contained under same virtual network's address spaces</p>
                </div>              
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label col-sm-2">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.subnetDescription" [(ngModel)]="subnetModel.comments" placeholder="Enter a reason for adding this resource..." (keyup)="subnetDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.subnetDescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div>
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
            <div *ngIf="showView">
                <div class="form-group">
                    <div class="label-div">
                        <label for="NAT" class="control-label">NAT gateway<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="NAT gateway associated to this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select class="margin-select" [items]="natGetwaysList" bindLabel="name" bindValue="id" [(ngModel)]="subnetModel.resourceData.natGatway" name="NAT" placeholder="Choose NAT Gateway" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="NSG" class="control-label">Network Security Group</label>
                    </div>
                    <div class="input-box">
                        <ng-select class="margin-select" [items]="nsgList" bindLabel="name" bindValue="id" [disabled]="subnetModel.resourceData.subnetType=='gatewaySubnet'" [(ngModel)]="subnetModel.resourceData.networkSecurityGroup" name="NSG" placeholder="Choose Network Security Group" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="routeTable" class="control-label">Route Table</label>
                    </div>
                    <div class="input-box">
                        <ng-select class="margin-select" [items]="routeTablesList" bindLabel="name" bindValue="id" [(ngModel)]="subnetModel.resourceData.routeTable" name="routeTable" placeholder="Choose route table" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="Services" class="control-label">Services<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enable one or more service endpoints for this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box margin-multi-select">
                        <ng-select class="" [items]="servicesList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" (change)="validateServiceWithDelegate()" [(ngModel)]="subnetModel.resourceData.services" name="Services" placeholder="Choose Services" [clearable]="false">
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>      
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="DSService" class="control-label">Delegate subnet to a service<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Designate a subnet to be used by a dedicated service." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select class="margin-select" [items]="dsServiceList" bindLabel="serviceName"  [(ngModel)]="subnetModel.resourceData.dsService" (change)="validateServiceWithDelegate()" name="DSService" placeholder="Choose Delegate subnet" [clearable]="false"></ng-select>
                        <p class="text-danger" *ngIf="inputError?.dsServiceCheck">Service endpoints cannot be used in a subnet with the selected delegation.</p>
                    </div>   
                </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>