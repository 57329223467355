
<div class="security-overview-container">
    <div class="header-container">
      <h2 class="title">Overview</h2>
      <!-- <span>{{currentProject?.projectName}}</span> -->
    </div>
     <!-- Filter Section -->
     <div class="custom-filter-wrapper flex-custom">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                        <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                        <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                        <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                        <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button>
    </div>
  
   <div class="dashboard-body">
      <div class="data-wrap tiles">
        <div class="data-card mt-2">
            <img src="./assets/security/total-assessed.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Total assessed</div>
              <div class="val-text">{{assessedList?.length}}</div>
            </div>
            <img src="./assets/security/found-unhealthy.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Found unhealthy</div>
              <div class="val-text">{{assedUnhealthyList?.length}}</div>
            </div>
            <img src="./assets/security/found-unmonitored.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Found unmonitored</div>
              <div class="val-text">{{assedUnmonitoredList?.length}}</div>
            </div>
            <img src="./assets/security/unregistered-subscriptions.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Unregistered subscriptions
                <div class="float-right">
                  <span class="action-icon" mattooltip="Table View" (click)="alertsTableView(assessedResourcesPopup)" mattooltipclass="nav-tooltips" mattooltipshowdelay="500" class="mat-tooltip-trigger action-icon ml-2">
                      <img src="/assets/security/viewTable.svg" alt="">
                  </span>
              </div>
              </div>
              <div class="val-text">{{filterUnregisted()}}</div>
            </div>
        </div>
      </div>
      <div class="data-wrap tiles">
        <div class="data-card row">
            <div class="col-sm-12 title-div">
                <label class="title">Alerts</label>
                <div class="float-right">
                    <span class="action-icon" mattooltip="Table View" (click)="alertsTableView(alertsPopup)" mattooltipclass="nav-tooltips" mattooltipshowdelay="500" class="mat-tooltip-trigger action-icon ml-2">
                        <img src="/assets/security/viewTable.svg" alt="">
                    </span>
                </div>
            </div>
            <div class="col-sm-12 card-sections">
            <img src="./assets/security/security-alerts.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Security alerts</div>
              <div class="val-text">{{alertsData?.length}}</div>
            </div>
            <img src="./assets/security/resources-affected.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Resources affected</div>
              <div class="val-text">{{alertResourceAffected?.length}}</div>
            </div>
            <div class="ml-2 full-width" style="width: 123.59% !important;">
              <div class="name-txt">Active alerts by severity</div>
                <span class="progress progress-bar-mutliple w-92 mb-2">
                    <span class="progress-bar progress-bar-custom-high" [style.width]="filterAlertSeverity('High','perc')+'%'"></span>
                    <span class="progress-bar progress-bar-custom-meduim" [style.width]="filterAlertSeverity('Medium','perc')+'%'"></span>
                    <span class="progress-bar progress-bar-custom-low" [style.width]="filterAlertSeverity('Low','perc')+'%'"></span>
                </span>
                <div class="severty-legend">
                    <span *ngIf="filterAlertSeverity('High','data')?.length"><img src="./assets/security/severty-high.svg">High ({{filterAlertSeverity('High','data')?.length}})</span>
                    <span *ngIf="filterAlertSeverity('Medium','data')?.length"><img src="./assets/security/severty-meduim.svg">Meduim ({{filterAlertSeverity('Medium','data')?.length}})</span>
                    <span *ngIf="filterAlertSeverity('Low','data')?.length"><img src="./assets/security/severty-low.svg">Low ({{filterAlertSeverity('Low','data')?.length}})</span>
                </div>
            </div>
        </div>
        </div>
        <div class="data-card side-card">
            <img src="./assets/security/overall-secure-score.svg">
            <div class="row" style="width:100% !important">
            <div class="col-sm-12 ml-2 full-width" style="padding-right: 8px;">
              <div class="name-txt">Overall secure score
                <div class="float-right">
                  <span class="action-icon" mattooltip="Table View"  (click)="alertsTableView(secureScorePopup)" mattooltipclass="nav-tooltips" mattooltipshowdelay="500" class="mat-tooltip-trigger action-icon ml-2">
                      <img src="/assets/security/viewTable.svg" alt="">
                  </span>
              </div>
              </div>
            </div>
            <div class="col-sm-12 progress-div">
                <p class="blur-text mb-1"><span>{{getFixedVal(OSSData?.length ? OSSData[0]?.OverrallScore : 0)}}%</span> ({{OSSData?.length ? OSSData[0]?.Current : 0}} out of {{OSSData?.length ? OSSData[0]?.Max : 0}} points)</p>
                <span class="progress w-92 mb-2">
                    <span class="progress-bar progress-bar-blue" [style.width]="getFixedVal(OSSData?.length ? OSSData[0]?.OverrallScore : 0)+'%'"></span>
                </span>
            </div>
        </div>
        </div>
      </div>
      <div class="data-wrap tiles">
        <div class="data-card row">
            <div class="col-sm-12 title-div"><label class="title">Coverage</label>
              <div class="float-right">
                <span class="action-icon" (click)="alertsTableView(coveragePopup)" mattooltip="Table View" mattooltipclass="nav-tooltips" mattooltipshowdelay="500" class="mat-tooltip-trigger action-icon ml-2">
                    <img src="/assets/security/viewTable.svg" alt="">
                </span>
            </div>
            </div>
            <div class="col-sm-12 card-sections">
            <img src="./assets/security/not-covered.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Not covered</div>
              <div class="val-text">{{totalCalFilter(coverageData,'Not covered')}}</div>
            </div>
            <img src="./assets/security/partially-covered.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Partially covered</div>
              <div class="val-text">{{totalCalFilter(coverageData,'Partially covered')}}</div>
            </div>
            <img src="./assets/security/fully-covered.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Fully covered</div>
              <div class="val-text">{{totalCalFilter(coverageData,'Fully covered')}}</div>
            </div>
        </div>
        </div>
        <div class="data-card side-card">
            <img src="./assets/security/active-recommendations.svg">
            <div class="ml-2 full-width">
              <div class="name-txt">Active recommendations
                <div class="float-right">
                  <span class="action-icon" (click)="alertsTableView(activeRecommendationsPopup)" mattooltip="Table View" mattooltipclass="nav-tooltips" mattooltipshowdelay="500" class="mat-tooltip-trigger action-icon ml-2">
                      <img src="/assets/security/viewTable.svg" alt="">
                  </span>
              </div>
              </div>
              <div class="val-text progress-div" style="margin-left: -10px;">{{activeRecommData?.length}}</div>
            </div>
          </div>
      </div>
  </div> 
</div>
  
<ng-template #alertsPopup>
  <div class="medium-popup-wrapper2 width-1240">
      <div class="modal-header">
          <div class="d-flex">
              <div class="heading-content"><h3>Alerts</h3></div>
              <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
          </div>
      </div>
      <div class="modal-body">
          <div class="custom-fixed-tables">
              <table class="table" #tableData>
                  <thead>
                    <tr>
                      <th class="wid-100">
                          <div class="flex-custom no-wrap">
                              <span>Severity</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','severity')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Alert title</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','displayName')">
                          </div>
                      </th>
                      <th class="wid-150">
                          <div class="flex-custom no-wrap">
                              <span>Affected resource</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','affectedResource')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource type</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','resourceType')">
                          </div>
                      </th>
                      <th>
                        <div class="flex-custom no-wrap">
                            <span>Resource group</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','RG')">
                        </div>
                    </th>
                    <th>
                      <div class="flex-custom no-wrap">
                          <span>Start time (UTC)</span>
                          <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','activityStartTime')">
                      </div>
                  </th>
                    <th>
                      <div class="flex-custom no-wrap">
                          <span>MITRE ATT&CK® tactics<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="MITRE ATT&CK® is a globally-accessible knowledge base of 
                            adversary tactics and techniques based on real-world observations.​" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span>
                          <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','mitreAttact')">
                      </div>
                  </th>
                      <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Status</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','status')">
                          </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of alertsData">
                        <td class="wid-100" > <span [ngClass]="row?.severity=='Medium'?'medium-severity':row?.severity=='Low'?'low-severity':row?.severity=='High'?'high-severity':''"></span>{{row?.severity | displayTxt}}</td>
                        <td [title]="row?.displayName">{{row?.displayName | displayTxt}}</td>
                        <td class="wid-150">{{row?.affectedResource | displayTxt}}</td>
                        <td>{{row?.resourceType | displayTxt}}</td>
                        <td>{{row?.RG | displayTxt}}</td>
                        <td>{{row?.activityStartTime | date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        <td>{{row?.mitreAttact | displayTxt | spaceBetweenCapital}}</td>
                        <td>{{row?.status | displayTxt}}</td>
                    </tr>
                    <div class="col-md-12 mt-3" *ngIf="!alertsData?.length">
                      <p class="content-14 blur-text">No data Found</p>
                    </div>
                  </tbody>
              </table>
            </div>
      </div>
      <div class="modal-footer d-flex">
          <button class="btn btn-outline" (click)="downloadFile('alerts',tableData)" *ngIf="alertsData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
          <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
      </div>
      
  </div>
</ng-template>

<ng-template #secureScorePopup>
  <div class="medium-popup-wrapper2 width-1120">
      <div class="modal-header">
          <div class="d-flex">
              <div class="heading-content"><h3>Secure Score</h3></div>
              <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
          </div>
      </div>
      <div class="modal-body">
          <div class="custom-fixed-tables">
              <table class="table" #tableData>
                  <thead>
                    <tr>
                      <!-- <th>
                          <div class="flex-custom no-wrap">
                              <span>Name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('OSSTableData','name')">
                          </div>
                      </th> -->
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Subscription</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('OSSTableData','subscriptionId')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Secure score</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('OSSTableData','percentage')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Unhealthy resources</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('OSSTableData','resourceType')">
                          </div>
                      </th>
                      <th>
                        <div class="flex-custom no-wrap">
                            <span>Healthy resources</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('OSSTableData','RG')">
                        </div>
                    </th>
                      <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Total resources</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('OSSTableData','status')">
                          </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of OSSTableData">
                        <!-- <td>{{row?.name | displayTxt}}</td> -->
                        <td>{{filterSubscriptionName(row?.subscriptionId) | displayTxt}}</td>
                        <td>
                          <div class="progress-div">
                            <p class="blur-text mb-1"><span>{{getFixedVal(row?.percentage)}}%</span> ({{row?.current}} of {{row?.max}})</p>
                            <span class="progress w-92 mb-2">
                                <span class="progress-bar progress-bar-blue" [style.width]="row?.percentage+'%'"></span>
                            </span>
                        </div>
                        </td>
                        <td>{{row?.resourceHealth?.unhealthyResources}}</td>
                        <td>{{row?.resourceHealth?.healthyResources}}</td>
                        <td>{{row?.resourceHealth?.totalResources}}</td>
                    </tr>
                    <div class="col-md-12 mt-3" *ngIf="!OSSTableData?.length">
                      <p class="content-14 blur-text">No data Found</p>
                    </div>
                  </tbody>
              </table>
            </div>
      </div>
      <div class="modal-footer d-flex">
          <button class="btn btn-outline" (click)="downloadFile('SecureScore',tableData)" *ngIf="OSSTableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
          <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
      </div>
      
  </div>
</ng-template>

<ng-template #assessedResourcesPopup>
  <div class="medium-popup-wrapper2 width-1120">
      <div class="modal-header">
          <div class="d-flex">
              <div class="heading-content"><h3>Assessed Resources</h3></div>
              <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
          </div>
      </div>
      <div class="modal-body">
          <div class="custom-fixed-tables">
              <table class="table" #tableData>
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('assessedList','resourceName')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Type</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('assessedList','resourceType')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resouce group</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('assessedList','resourceGroup')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Subscription</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('assessedList','subscriptionId')">
                          </div>
                      </th>
                      <th>
                        <div class="flex-custom no-wrap">
                            <span>Agent monitoring</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('assessedList','agentMonitoring')">
                        </div>
                    </th>
                      <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Resource health</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('assessedList','resourceHealth')">
                          </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of assessedList">
                        <td>{{row?.resourceName | displayTxt}}</td>
                        <td>{{row?.resourceType | displayTxt}}</td>
                        <td>{{row?.resourceGroup | displayTxt}}</td>
                        <td>{{filterSubscriptionName(row?.subscriptionId)}}</td>
                        <td>{{row?.agentMonitoring | titlecase}}</td>
                        <td>
                          <div class="chip" *ngIf="row?.resourceHealth" [ngClass]="getStatusClass(row?.resourceHealth)">
                            {{row?.resourceHealth}}
                        </div>
                        </td>
                    </tr>
                    <div class="col-md-12 mt-3" *ngIf="!assessedList?.length">
                      <p class="content-14 blur-text">No data Found</p>
                    </div>
                  </tbody>
              </table>
            </div>
      </div>
      <div class="modal-footer d-flex">
          <button class="btn btn-outline" (click)="downloadFile('AssessedResources',tableData)" *ngIf="assessedList?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
          <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
      </div>
      
  </div>
</ng-template>

<ng-template #activeRecommendationsPopup>
  <div class="medium-popup-wrapper2 width-1120">
      <div class="modal-header">
          <div class="d-flex">
              <div class="heading-content"><h3>Active Recommendations</h3></div>
              <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
          </div>
      </div>
      <div class="modal-body">
        <div class="pull-right custom-switches d-flex" style="padding-top: 16px;width: 150px;">
            <label style="font-size: 13px;font-weight: 400;padding-right: 8px;">Group by controls</label>
            <p style="position: absolute;top: 10px;right: 6px;">
            <ui-switch [(ngModel)]="groupByCtrl"></ui-switch>
        </p>
      </div>
          <div class="custom-fixed-tables" style="margin-top: 3rem !important;">
              <table class="table" #tableData>
                  <thead *ngIf="groupByCtrl">
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Controls</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','displayName')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Max score</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','resourceType')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Current score</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','resourceGroup')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Potential score increase</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','subscriptionId')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Unhealthy resources</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','resourceHealth')">
                          </div>
                      </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Resource health</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','resourceHealth')">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="groupByCtrl">
                    <tr *ngFor="let row of secureScoreData">
                        <td [title]="row?.properties?.displayName">{{row?.properties?.displayName | displayTxt}}</td>
                        <td style="width:1% !important">{{row?.properties?.score?.max}}</td>
                        <td>
                            <div class="progress-div d-flex">
                                <span style="padding-right: 8px;">{{row?.properties?.score?.current}}</span>
                                <span class="progress w-92 mb-2" style="margin-top: 8px;max-width: 71%;position: absolute;right: 8px;">
                                    <span class="progress-bar progress-bar-blue" [style.width]="row?.properties?.score?.percentage*100+'%'"></span>
                                </span>
                            </div>
                        </td>
                        <td style="width: 1% !important;"><span style="font-weight: 600;font-size: 14px;">+{{pointsPerc(row?.properties?.score)}}%</span> ({{fixedDecimal(row?.properties?.score?.max-row?.properties?.score?.current)}} points)</td>
                        <td style="width: 1% !important;">
                            <span *ngIf="row?.properties?.unhealthyResourceCount">{{row?.properties?.unhealthyResourceCount}} out of {{row?.properties?.healthyResourceCount+row?.properties?.notApplicableResourceCount+row?.properties?.unhealthyResourceCount}}</span>
                            <span *ngIf="!(row?.properties?.unhealthyResourceCount)">None</span>
                        </td>
                        <td>
                            <div class="progress-div">
                                <span class="progress progress-bar-mutliple w-92 mb-2" style="width: 100% !important;">
                                    <span class="progress-bar progress-bar-healthy" [style.width]="(row?.properties?.healthyResourceCount/(row?.properties?.healthyResourceCount+row?.properties?.notApplicableResourceCount+row?.properties?.unhealthyResourceCount))*100+'%'"></span>
                                    <span class="progress-bar progress-bar-unhealthy" [style.width]="(row?.properties?.unhealthyResourceCount/(row?.properties?.healthyResourceCount+row?.properties?.notApplicableResourceCount+row?.properties?.unhealthyResourceCount))*100+'%'"></span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <div class="col-md-12 mt-3" *ngIf="!secureScoreData?.length">
                      <p class="content-14 blur-text">No data Found</p>
                    </div>
                  </tbody>
                  <thead *ngIf="!groupByCtrl">
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Recommendation</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','displayName')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Unhealthy resources</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','resourceType')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource type</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','resourceGroup')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource group</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','subscriptionId')">
                          </div>
                      </th>
                      <th>
                        <div class="flex-custom no-wrap">
                            <span>Resource health</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','agentMonitoring')">
                        </div>
                    </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Initiative</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','resourceHealth')">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="!groupByCtrl">
                    <tr *ngFor="let row of activeRecommData">
                        <td [title]="row?.displayName">{{row?.displayName | displayTxt}}</td>
                        <td>{{filterUnHealthy(row?.totalResources,"Unhealthy")}} of {{row?.totalResources?.length}} {{row?.resourceTypes.join('& ')}}</td>
                        <td>{{row?.resourceTypes.join(', ')}}</td>
                        <td [title]="row?.resourceGroups.join(', ')">{{row?.resourceGroups.join(', ')}}</td>
                        <td>
                            <div class="progress-div">
                                <span class="progress progress-bar-mutliple w-92 mb-2" style="width: 100% !important;">
                                    <span class="progress-bar progress-bar-healthy" [style.width]="(filterUnHealthy(row?.totalResources,'Healthy')/(row?.totalResources?.length))*100+'%'"></span>
                                    <span class="progress-bar progress-bar-unhealthy" [style.width]="(filterUnHealthy(row?.totalResources,'Unhealthy')/(row?.totalResources?.length))*100+'%'"></span>
                                </span>
                            </div>
                        </td>
                        <td>{{filterInitiatives(row?.initiatives)}}
                        </td>
                    </tr>
                    <div class="col-md-12 mt-3" *ngIf="!activeRecommData?.length">
                      <p class="content-14 blur-text">No data Found</p>
                    </div>
                  </tbody>
              </table>
            </div>
      </div>
      <div class="modal-footer d-flex">
          <button class="btn btn-outline" (click)="downloadFile('ActiveRecommendations',tableData)" *ngIf="activeRecommData?.length||secureScoreData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
          <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
      </div>
      
  </div>
</ng-template>

<ng-template #coveragePopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Coverage</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #tableData>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Subscription</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('coverageTableData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Coverage</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('coverageTableData','subscriptionId')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Total resources</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('coverageTableData','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of coverageTableData">
                          <td>{{filterSubscriptionName(row?.subscriptionId) | displayTxt}}</td>
                          <td>
                            <div class="chip" [ngClass]="getStatusClass(row?.Coverage)">
                                <img [src]="getStatusChipImg(row?.Coverage)">
                                {{row?.Coverage}}
                              </div>
                          </td>
                          <td>{{row?.resourcesCount}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!coverageTableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Coverage',tableData)" *ngIf="coverageTableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>