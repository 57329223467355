<div class="introduction">
    <p class="heading" id="0">Infrastructure Health Monitoring </p>
     <p class="para">Continual monitoring of the resources deployed on Azure under various 
              projects in A3S is key to making sure that any issues or errors are identified as early 
              as possible and thereon remediation steps are undertaken to ensure healthy status of all 
              the resources. End goal of this ongoing infrastructure monitoring and remediation is to 
              learn from and analyze incident management data logs with AI/ML to implement preemptive 
              measures that avoid the potential errors and issues from occurring in the first place.  </p>
            <label>Infrastructure Health Monitoring consists of the following sections: </label>
      <ol>
          <li><b>Metrics :</b> display health metrics of a project. </li>
          <li><b>Insights : </b>Insights: enable execution of prebuilt or custom queries to retrieve information for
                     a given time frame (for auditing) or to view & analyze the potential error or issue. </li>
            <li><b>Alerts :</b> view & modify existing alerts and create new alerts. </li>
                
      </ol>
</div>
