<div class="introduction">
    <p class="heading" id="0">Alerts </p>
    <p class="para">This page enables the user to view and modify existing alerts or to 
        create new ones as needed.  </p>
        <p class="mb-0" >Further, stay informed in real time via SMS text messages and/or email
             whenever an alert goes for the resources deployed in Azure across various 
             projects. The alert indicates a potential error or issue, and relevant information 
             will be shared in the message sent. </p>
             <img src="assets\user-manual\infra-health-monitoring\Alert.svg" >
             <figcaption class="caption">Alerts list </figcaption>
         
</div>
