import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-load-balancer-actions',
  templateUrl: './load-balancer-actions.component.html',
  styleUrls: ['./load-balancer-actions.component.scss']
})
export class LoadBalancerActionsComponent implements OnInit {
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("loadBalancerDetailsTemplate") loadBalancerDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  @ViewChild("addVMtoPool") addVMtoPool: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    frontendNameEmpty:false,
    frontendNameMaxLen:false,
    frontendNameExist:false,
    frontendNameValid:false,
    publicIPNameEmpty:false,
    publicIPNameMaxLen:false,
    publicIPNameExist:false,
    publicIPNameValid:false,
    ipPrefixNameEmpty:false,
    ipPrefixNameMaxLen:false,
    ipPrefixNameExist:false,
    ipPrefixNameValid:false,
    portInvalidSizeRange:false,
    portInvalidEmpty:false,
    portInvalidNumber:false,
    pathInvalidEmpty:false,
    intervalInvalidSizeRange:false,
    intervalInvalidEmpty:false,
    intervalInvalidNumber:false,
    thresholdInvalidSizeRange:false,
    thresholdInvalidEmpty:false,
    thresholdInvalidNumber:false,
    idleTimeoutRange:false,
    idleTimeoutNumber:false,
    idleTimeoutEmpty:false,
    portLBInvalidSizeRange:false,
    portLBInvalidEmpty:false,
    portLBInvalidNumber:false,
    backendPortInvalidSizeRange:false,
    backendPortInvalidEmpty:false,
    backendPortInvalidNumber:false,
    machineCountInvalidSizeRange:false,
    machineCountInvalidEmpty:false,
    machineCountInvalidNumber:false,
    InvalidPortInstance:false,
    ipError:false
  }
  selectedType: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;
  currentSubSheet: any;
  saveBtnEnable: boolean;

  availabilityZoneList=[
    {name:'No Zone'},
    {name:'Zone-redundant'},
    {name:'1'},
    {name:'2'},
    {name:'3'},
  ]
  prefixSizeList=[
    {name:'/31 (2 addresses)',id:31},
    {name:'/30 (4 addresses)',id:30},
    {name:'/29 (8 addresses)',id:29},
    {name:'/28 (16 addresses)',id:28},
    {name:'/27 (32 addresses)',id:27},
    {name:'/26 (64 addresses)',id:26},
    {name:'/25 (128 addresses)',id:25},
    {name:'/24 (256 addresses)',id:24},
  ]
  protocolList=[
    {name:'TCP'},
    {name:'HTTP'},
    {name:'HTTPS'},
  ]
  sessionPersistenceList=[
    {name:'None',id:'Default'},
    {name:'Client IP',id:'SourceIP'},
    {name:'Client IP and protocol',id:'SourceIPProtocol'},
  ]
  portAllocationList=[
    {name:'Manually choose number of outbound ports',id:'manual'},
    {name:'Use the default number of outbound ports',id:'default'},
  ]
  outboundPortList=[
    {name:'Ports per instance',id:'portInstance'},
    {name:'Maximum number of backend instances',id:'backendInstance'},
  ]
  serviceTagList:any=[
    {name:'HTTP'},
    {name:'HTTPS'},
    {name:'SSH'},
    {name:'RDP'},
    {name:'MS SQL'},
    {name:'MySQL'},
    {name:'Custom'},
    {name:'DNS(TCP)'},
    {name:'DNS(UDP)'}
  ];

  // daysList=this.util.getStatic('dayOfWeeks');
  // localTimeList=this.util.getStatic('localTimeList');
  loadBalancerData: any;
  publicIPAllList: any;
  publicIPList: any;
  loadBalancerList: any;
  isEdit: boolean;
  gatewayLBList:any;
  selectedData: any;
  frontendIPList: any[];
  backendPoolsList: any[];
  probesList: any[];
  targetVMList: any[];
  publicIpPrefixList: any;
  vnetData: any;
  vmNicList: any;
  vmSetNicList: any[];
  selectedVMList: any[];
  selectedVMScaleSetList: any[];
  vmPoolList: any[];
  vmScaleSetList: any;
  ipPoolList: any[];
  vNetName: any;
  nicData: any;
  subnetList: any;
  defaultIPList: any;
  vmData: any;

  constructor(public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private _dataMigrationService: DataMigrationService,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }

  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteLBData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteLBData')
      }
      case 'deleteFrontendIP':{
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteFrontendIP')
      }
      case'deleteLBRules':{
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteLBRules')
      }
      case'deleteInboundRules':{
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteInboundRules')
      }
      case'deleteOutboundRules':{
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteOutboundRules')
      }
      case'deleteBackendPool':{
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteBackendPool')
      }
    }
  }
    // Trigger action of the SQL DB
  
  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case 'frontendIP':{
        this.closeModal()
        await this.getLoadBalancerData(this.selectedRow,'frontendIP')
        await this.getPublicIPList()
        await this.getLoadBalancerListbsRG(this.selectedRow)
        await this.getPublicIpPrefixList(this.selectedRow)
        // await this.setDefaultValue('frontendIP')
        this.saveDisable=false
        this.showSidesheet = true;
        this.isEdit=false
        this.showSubSidesheet=false
        break;
      }
      case 'healthProbes':{
        this.closeModal()
        await this.getLoadBalancerData(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        this.showSubSidesheet=false
        this.isEdit=false
        break;
      }
      case 'backendPool':{
        this.closeModal()
        await this.getLoadBalancerData(this.selectedRow)
        await this.getVMScaleSetList()
        await this.getPublicIPList()
        this.saveDisable=false
        this.showSidesheet = true;
        this.showSubSidesheet=false
        this.isEdit=false
        await this.getVNETData(this.selectedRow)
        // await this.getVMStatus(this.nicData.body.properties.virtualMachine.id)
        // this.loadBalancerData.properties.frontendIPConfigurations[0].properties.subnet ?await this.getVNETData(this.selectedRow) :false
        break;
      }
      case 'lbRules':{
        this.closeModal()
        await this.getLoadBalancerData(this.selectedRow,'lbRules')
        this.saveDisable=false
        this.showSidesheet = true;
        this.showSubSidesheet=false
        this.isEdit=false
        break;
      }
      case 'inboundRules':{
        this.closeModal()
        await this.getLoadBalancerData(this.selectedRow,'inboundRules')
        this.saveDisable=false
        this.showSidesheet = true;
        this.isEdit=false
        break;
      }
      case 'outboundRules':{
        this.closeModal()
        await this.getLoadBalancerData(this.selectedRow,'lbRules')
        this.saveDisable=false
        this.showSidesheet = true;
        this.showSubSidesheet=false
        this.isEdit=false
        break;
      }
    }
  }
  async openSubSideSheet(type,subType?:any){
    this.currentSubSheet = type
    switch(this.currentSubSheet){
      case "addFrontendIP" : {
        this.currentForm = this.util.getForm('lbFrontendIP')
        this.isEdit= subType ? true:false
        this.isEdit ? this.setDefaultValue('editFrontendIP',subType) :this.setDefaultValue('addFrontendIP')
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case 'addBackendPool':{
        this.saveDisable=false
        this.vmPoolList=[]
        this.vmScaleSetList=[]
        this.ipPoolList=this.defaultIPList
        this.currentForm = this.util.getForm('lbBackendPool')
        // await this.getVMListbsRG(this.selectedRow,'backendPool')
        this.isEdit= subType ? true:false
        this.isEdit ? this.setDefaultValue('editbackendPool',subType) :this.setDefaultValue('addBackendPool')
        this.showSubSidesheet=true
        break;
      }
      case "addHealthProbes" : {
        this.currentForm = this.util.getForm('lbHealthProbes')
          await this.setDefaultValue('addHealthProbes')
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "addLBRules" : {
        this.currentForm = this.util.getForm('loadBalancerRules')
          await this.setDefaultValue('addLBRules')
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      
      case "addInboundRules" : {
        this.currentForm = this.util.getForm('inboundRules')
        await this.getVMListbsRG(this.selectedRow,'inboundRules')
          await this.setDefaultValue('addInboundRules')
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "addOutboundRules" : {
        this.currentForm = this.util.getForm('outboundRules')
          await this.setDefaultValue('addOutboundRules')
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
    }
  }
  ipArray(){
    this.ipArrayList.push(this.util.getForm('addIPtoPool'))
  }
  get ipArrayList() {
    return this.currentForm.get('ipArray') as FormArray;
  }
  removeIPPool(index,tanch) {
    this.saveDisable=true
    this.ipArrayList.controls.splice(index, 1);
    if(this.inputError.ipError){
      this.inputError['ipError']=false;
    }else{
      if(tanch.get('ipAddress').value!=''){
        let i=this.ipPoolList.findIndex(e=>e.name==tanch.get('ipAddress').value)
        this.ipPoolList.splice(i, 1);
        let temp=this.ipPoolList.filter(e=>this.defaultIPList.some(x=>e.name!=x.name))
        this.defaultIPList.forEach(e=>{
          temp.unshift(e)
        })
        this.ipPoolList=temp
      }
    }
  }
  // get vmPoolList() {
  //   return this.currentForm.get('vmPool') as FormArray;
  // }
  // vmPoolArray(){
  //   this.vmPoolList.push(this.util.getForm('addVMPool'))
  // }
  // get vmScaleSetList() {
  //   return this.currentForm.get('vmScaleSet') as FormArray;
  // }
  // vmScaleSetArray(){
  //   this.vmScaleSetList.push(this.util.getForm('addVMScaleSetPool'))
  // }
  // openPopUp(data?:any){
  //   this.subSideSheetForm = this.formBuilder.group({
  //     poolName:[null],
  //     resourceName: this.formBuilder.array([this.addVMPool(data)]),
  //   })
  // }
  // addVMPool(data?:any){
  //   let tempForm = this.formBuilder.group({
  //     name :[data && data.name ? data.name : false],
  //     checked:[data && data.checked ? data.checked : false]
  //   })
  //   return tempForm;
  // }
  enableAction(body,event,type?:any){
    if (event.target.checked) body['checked'] = true
    else  body['checked'] = false
    this.selectedVMList=[]
    // this.selectedVMScaleSetList=[]
    if(this.selectedType=='openVmtoPool'){
      this.vmNicList.forEach(e=>{
        if(e.checked){
          this.selectedVMList.push(e)
        }
      })
    } else if(this.selectedType=='openVmScaleSetPool'){
      this.vmSetNicList.forEach(e=>{
        if(e.checked){
          this.selectedVMList.push(e)
        }
      })
    }
    if(type && type=='vmPool'){
      this.vmPoolList.forEach(e=>{
        if(e.checked){
          this.selectedVMList.push(e)
        }
      })
    }else if(type && type=='vmScaleSetPool'){
      this.vmScaleSetList.forEach(e=>{
        if(e.checked){
          this.selectedVMList.push(e)
        }
      })
    }
    
  
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteLBData' :return this.deleteLoadBalancerData(this.selectedData)
      case 'deleteFrontendIP' :{
        let index=this.loadBalancerData.properties.frontendIPConfigurations.findIndex(e=>e.name==this.selectedData.name)
        this.loadBalancerData?.properties?.frontendIPConfigurations.splice(index,1)
        return this.updateLBData(this.selectedRow,this.loadBalancerData,'deleteFrontendIP')
      }
      case'deleteLBRules':{
        let index=this.loadBalancerData.properties.loadBalancingRules.findIndex(e=>e.name==this.selectedData.name)
        this.loadBalancerData?.properties?.loadBalancingRules.splice(index,1)
        return this.updateLBData(this.selectedRow,this.loadBalancerData,'deleteLBRules')
      }
      case'deleteInboundRules':{
        let index=this.loadBalancerData.properties.inboundNatRules.findIndex(e=>e.name==this.selectedData.name)
        this.loadBalancerData?.properties?.inboundNatRules.splice(index,1)
        return this.updateLBData(this.selectedRow,this.loadBalancerData,'deleteInboundRules')
      }
      case'deleteOutboundRules':{
        let index=this.loadBalancerData.properties.outboundRules.findIndex(e=>e.name==this.selectedData.name)
        this.loadBalancerData?.properties?.outboundRules.splice(index,1)
        return this.updateLBData(this.selectedRow,this.loadBalancerData,'deleteOutboundRules')
      }
      case'deleteBackendPool':{
        let index=this.loadBalancerData.properties.backendAddressPools.findIndex(e=>e.name==this.selectedData.name)
        this.loadBalancerData?.properties?.backendAddressPools.splice(index,1)
        return this.updateLBData(this.selectedRow,this.loadBalancerData,'deleteBackendPool')
      }
    }
  }
  async deleteProbes(row){
    let index=this.loadBalancerData.properties.probes.findIndex(e=>e.name==row.name)
    this.loadBalancerData?.properties?.probes.splice(index,1)
    await this.updateLBData(this.selectedRow,this.loadBalancerData,'deleteHealthProbes')
  }
  removeVMPool(type){
    switch(type){
      case 'openVmtoPool':{
        this.selectedVMList.forEach(e=>{
          let index=this.vmPoolList.findIndex(x=>e.name==x.name)
          this.vmPoolList?.splice(index,1)
        })
        break;
      }
      case 'openVmScaleSetPool':{
        this.selectedVMList.forEach(e=>{
          let index=this.vmScaleSetList.findIndex(x=>e.name==x.name)
          this.vmScaleSetList?.splice(index,1)
        })
        break;
      }
    }
    
  
  }
  addVmtoPool(){
    switch(this.selectedType){
      case 'openVmtoPool':{
        this.vmPoolList=[]
         this.selectedVMList.forEach(e=>{
          if(e.checked){
            this.vmPoolList.push({
              name:e.name,
              id:e.id,
              ipConfig:e.ipConfig,
              checked:false,
            })
          }
        })
        // this.selectedVMList.forEach(e=>{
        //   if(e.checked){
        //     this.vmPoolList.push(this.util.getForm('addVMPool',{
        //       name:e.name,
        //       checked:false,
        //     }))
        //   }
        // })
        break;
      }
      case 'openVmScaleSetPool':{
        this.vmScaleSetList=[]
        this.selectedVMList.forEach(e=>{
          if(e.checked){
            this.vmScaleSetList.push({
              name:e.name,
              id:e.id,
              ipConfig:e.ipConfig,
              checked:false,
            })
          }
        })
        break;
      }
    }
    this.closeModal()
    this.selectedVMList=[]
  
  }
  
  openModal(template: TemplateRef<any>,row,type?:any){
    // this.selectedRow=row
    this.selectedData=row;
    this.selectedType=type;
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteLBData':return 'Delete Load Balancer'
      case'deleteFrontendIP':return 'Delete Frontend IP Configuration'
      case'deleteLBRules':return 'Delete Load Balancing Rule'
      case'deleteInboundRules':return 'Delete Inbound Rule'
      case'deleteOutboundRules':return 'Delete Outbound Rule'
      case'deleteBackendPool':return 'Delete Backend Pools'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteLBData':return `Are you sure you want to delete “${this.selectedData[0]}”?` 
      case'deleteFrontendIP':return `This action will permanently delete frontend IP configuration 'fnip1' and dependent resources below. Additionally, all virtual machine scale sets connected to this frontend IP configuration will be updated as part of the delete process.`
      case'deleteLBRules':return `Are you sure you want to delete “${this.selectedData.name}”?`
      case'deleteInboundRules':return `Are you sure you want to delete “${this.selectedData.name}”?`
      case'deleteOutboundRules':return `Are you sure you want to delete “${this.selectedData.name}”?`
      case'deleteBackendPool':return `This action will permanently delete backend pool '${this.selectedData.name}' and all the associated load balancing rules, inbound NAT rules, and outbound rules.`
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteLBData':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'online': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      case 'connected': return 'st-success';
      case 'not connected': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  getToolTip(){
    return `Session persistence specifies that traffic from a client should be handled 
    by the same virtual machine in the backend pool for the duration of a session. 
    "None" specifies that successive requests from the same client may be 
    handled by any virtual machine. "Client IP" specifies that successive requests 
    from the same client IP address will be handled by the same virtual machine. 
    "Client IP and protocol" specifies that successive requests from the same 
    client IP address and protocol combination will be handled by the same
    virtual machine.`
  }
  getSuccesText(type){
    switch(type){
      case'addFrontendIP':return `Frontend IP configuration added successfully!`
      case'editFrontendIP':return `Frontend IP configuration updated successfully!`
      case'addHealthProbes':return `Health probe added successfully!`
      case'addLBRules':return'Load balancing rule added successfully!'
      case'addInboundRules':return `Inbound NAT rule added successfully!`
      case'addOutboundRules':return `Outbound NAT rule added successfully!`
      case'addBackendPool':return `Backend pool added successfully!`
      default: return 'Changes saved successfully!'
    }
  }
  
  getFailureText(type){
    switch(type){
      case'addFrontendIP':return 'Failed to add Frontend IP configuration.'
      case'editFrontendIP':return `Failed to update Frontend IP configuration.`
      case'addHealthProbes':return `Failed to add Health probe.`
      case'addLBRules':return'Failed to add Load balancing rule.'
      case'addInboundRules':return `Failed to add Inbound NAT rule.`
      case'addOutboundRules':return `Failed to add Outbound NAT rule.`
      case'addBackendPool':return `Failed to add Backend pool.`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'addFrontendIP':return `Adding Frontend IP configuration...`
      case'editFrontendIP':return `Updating Frontend IP configuration...`
      case'addHealthProbes':return `Adding Health probe...`
      case'addLBRules':return 'Adding Load balancing rule...'
      case'addInboundRules':return `Adding Inbound NAT rule...`
      case'addOutboundRules':return `Adding Outbound NAT rule...`
      case'addBackendPool':return `Adding Backend pool...`
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'loadBalancersData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }
  
  invalidForm(type){
    switch(type){
      case'frontendIP':{
        return this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
        || this.inputError?.publicIPNameEmpty|| this.inputError?.publicIPNameMaxLen || this.inputError?.publicIPNameExist|| this.inputError?.publicIPNameValid
        || this.inputError?.ipPrefixNameEmpty|| this.inputError?.ipPrefixNameMaxLen || this.inputError?.ipPrefixNameExist|| this.inputError?.ipPrefixNameValid 
        || this.inputError?.ipError
      }
      case'backendPool':{
        return this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
        || this.inputError?.ipError
      }
      case'healthProbes':{
        return this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
        || this.inputError?.portInvalidSizeRange|| this.inputError?.portInvalidNumber || this.inputError?.intervalInvalidSizeRange|| this.inputError?.intervalInvalidNumber
        || this.inputError?.thresholdInvalidSizeRange|| this.inputError?.thresholdInvalidNumber
      }
      case'healthProbes':{
        return this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
        || this.inputError?.portInvalidSizeRange|| this.inputError?.portInvalidNumber || this.inputError?.intervalInvalidSizeRange|| this.inputError?.intervalInvalidNumber
        || this.inputError?.thresholdInvalidSizeRange|| this.inputError?.thresholdInvalidNumber
      }
      case'lbRules':{
        return this.inputError?.idleTimeoutEmpty|| this.inputError?.idleTimeoutNumber || this.inputError?.idleTimeoutRange|| this.inputError?.backendPortInvalidEmpty
        || this.inputError?.backendPortInvalidNumber|| this.inputError?.backendPortInvalidSizeRange || this.inputError?.machineCountInvalidSizeRange || this.inputError?.portLBInvalidNumber|| this.inputError?.portLBInvalidSizeRange
        || this.inputError?.portLBInvalidEmpty|| this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
      }
      case'inboundRules':{
        return this.inputError?.idleTimeoutEmpty|| this.inputError?.idleTimeoutNumber || this.inputError?.idleTimeoutRange|| this.inputError?.backendPortInvalidEmpty
        || this.inputError?.backendPortInvalidNumber|| this.inputError?.backendPortInvalidSizeRange || this.inputError?.machineCountInvalidEmpty|| this.inputError?.machineCountInvalidNumber
        || this.inputError?.machineCountInvalidSizeRange|| this.inputError?.machineCountInvalidSizeRange || this.inputError?.portLBInvalidNumber|| this.inputError?.portLBInvalidSizeRange
        || this.inputError?.portLBInvalidEmpty|| this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
      }
      case'outboundRules':{
        return this.inputError?.idleTimeoutEmpty|| this.inputError?.idleTimeoutNumber || this.inputError?.idleTimeoutRange|| this.inputError?.backendPortInvalidEmpty
        || this.inputError?.backendPortInvalidNumber|| this.inputError?.backendPortInvalidSizeRange || this.inputError?.portLBInvalidNumber|| this.inputError?.portLBInvalidSizeRange || this.inputError?.InvalidPortInstance
        || this.inputError?.portLBInvalidEmpty|| this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
      }
    }
  }

  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      frontendNameEmpty:false,
      frontendNameMaxLen:false,
      frontendNameExist:false,
      frontendNameValid:false,
      publicIPNameEmpty:false,
      publicIPNameMaxLen:false,
      publicIPNameExist:false,
      publicIPNameValid:false,
      ipPrefixNameEmpty:false,
      ipPrefixNameMaxLen:false,
      ipPrefixNameExist:false,
      ipPrefixNameValid:false,
      portInvalidSizeRange:false,
      portInvalidEmpty:false,
      portInvalidNumber:false,
      pathInvalidEmpty:false,
      intervalInvalidSizeRange:false,
      intervalInvalidEmpty:false,
      intervalInvalidNumber:false,
      thresholdInvalidSizeRange:false,
      thresholdInvalidEmpty:false,
      thresholdInvalidNumber:false,
      idleTimeoutRange:false,
      idleTimeoutNumber:false,
      idleTimeoutEmpty:false,
      portLBInvalidSizeRange:false,
      portLBInvalidEmpty:false,
      portLBInvalidNumber:false,
      backendPortInvalidSizeRange:false,
      backendPortInvalidEmpty:false,
      backendPortInvalidNumber:false,
      machineCountInvalidSizeRange:false,
      machineCountInvalidEmpty:false,
      machineCountInvalidNumber:false,
      InvalidPortInstance:false,
      ipError:false
    }
  }

  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'frontendIP' :{
      this.saveDisable=true
      switch(subType){
        case 'ipType':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('ipType').setValue(value)
          break;
        }
        case 'assigment':{
          let value = id
          this.currentForm.get('publicIPAssignment').setValue(value)
          break;
        }
        case 'routePrefer':{
          let value = id 
          this.currentForm.get('publicIPRoutePrefer').setValue(value)
          break;
        }
      }
    }
    case 'lbRules' :{
      this.saveDisable=true
      switch(subType){
        case 'tcpReset':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('tcpReset').setValue(value)
          break;
        }
        case 'floatingIP':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('floatingIP').setValue(value)
          break;
        }
        case 'protocolLBRules':{
          let value = id 
          this.currentForm.get('protocolLBRules').setValue(value)
          break;
        }
        case 'outboundNetwork':{
          let value = id=='yes' ? true : false
          this.currentForm.get('outboundNetwork').setValue(value)
          break;
        }
      }
    }
    case 'inboundNatRules' :{
      this.saveDisable=true
      switch(subType){
        case 'type':{
          let value = id 
          this.currentForm.get('type').setValue(value)
          break;
        }
        case 'protocol':{
          let value = id 
          this.currentForm.get('protocol').setValue(value)
          break;
        }
      }
    }
    case 'backendPool' :{
      this.saveDisable=true
      switch(subType){
        case 'poolConfig':{
          let value = id 
          this.currentForm.get('poolConfig').setValue(value)
          break;
        }
      }
    }
    
  }
}

  async setDefaultValue(type,data?:any){
  switch(type){
   case 'addFrontendIP':{
    this.currentForm.get('ipType').setValue(true)
    this.currentForm.get('gatewayLB').setValue('None')
    this.currentForm.get('publicIPSKU').setValue('Standard')
    this.currentForm.get('publicIPTier').setValue('Regional')
    this.currentForm.get('prefixSize').setValue(this.prefixSizeList[3].name)
    this.currentForm.get('publicIPZone').setValue('Zone-redundant')
    this.currentForm.get('publicIPRoutePrefer').setValue('MSNetwork')
    this.selectedRow[5]!='Internal'?  this.currentForm.get('publicIPAssignment').setValue('Static'):  this.currentForm.get('publicIPAssignment').setValue('Dynamic')
    break;
   }
   case'editFrontendIP':{
    this.currentForm.get('frontendIPName').setValue(data.name)
    if(this.selectedRow[5]=='Internal'){
      this.currentForm.get('subnet').setValue(data.properties.subnet.id)
      this.currentForm.get('publicIPAddress').setValue(data.properties.privateIPAddress)
      this.currentForm.get('publicIPAssignment').setValue(data.properties.privateIPAllocationMethod)
      this.currentForm.get('publicIPZone').setValue(data.properties.zone?data.properties.zone:'No Zone')
    }else{
      this.currentForm.get('ipType').setValue(true)
      this.currentForm.get('publicIP').setValue(data.properties.publicIPAddress.id)
      this.currentForm.get('gatewayLB').setValue('None')
      this.currentForm.get('publicIPSKU').setValue('Standard')
      this.currentForm.get('publicIPTier').setValue('Regional')
      this.currentForm.get('publicIPAssignment').setValue('Static')
      this.currentForm.get('prefixSize').setValue(this.prefixSizeList[3].name)
      this.currentForm.get('publicIPZone').setValue('Zone-redundant')
      this.currentForm.get('publicIPRoutePrefer').setValue('MSNetwork')
    }
 
    break;
   }
   case 'addHealthProbes':{
    this.currentForm.get('protocol').setValue('TCP')
    this.currentForm.get('port').setValue(80)
    this.currentForm.get('path').setValue('/')
    this.currentForm.get('interval').setValue(5)
    this.currentForm.get('threshold').setValue(2)
    break;
   }
   case 'addLBRules':{
    this.currentForm.get('protocolLBRules').setValue('Tcp')
    this.currentForm.get('idealTimeout').setValue(4)
    this.currentForm.get('session').setValue('Default')
    this.currentForm.get('tcpReset').setValue(false)
    this.currentForm.get('floatingIP').setValue(false)
    this.currentForm.get('outboundNetwork').setValue(true)
    break;
   }
   case 'addInboundRules':{
    this.currentForm.get('protocol').setValue('Tcp')
    this.currentForm.get('idealTimeout').setValue(4)
    this.currentForm.get('tcpReset').setValue(false)
    this.currentForm.get('floatingIP').setValue(false)
    this.currentForm.get('type').setValue('azureVM')
    this.currentForm.get('targetVM').setValue('None')
    this.currentForm.get('serviceTag').setValue('Custom')
    break;
   }
   case 'addOutboundRules':{
    this.currentForm.get('protocol').setValue('All')
    this.currentForm.get('idealTimeout').setValue(4)
    this.currentForm.get('tcpReset').setValue(true)
    this.currentForm.get('portAllocation').setValue('manual')
    this.currentForm.get('outboundPort').setValue('portInstance')
    this.currentForm.get('portInstance').setValue(0)
    this.currentForm.get('backendInstance').setValue(1)
    break;
   }
   case'addBackendPool':{
    this.currentForm.get('poolConfig').setValue('nic')
    break;
   }
   case 'editbackendPool':{
    this.currentForm.get('poolName').setValue(data.name)
    this.currentForm.get('poolConfig').setValue('nic')
    let poolArray=this.vmNicList.filter(e=>data.properties.backendIPConfigurations.some(x=>x.id.split('/')[8]==e.id.split('/')[8]))
    poolArray.foreach(e=>{
      this.vmPoolList.push({
        name:e.name,
        id:e.id,
        ipConfig:e.ipConfig,
        checked:false,
      })
    })
    break;
   }
  }
  }
  getPublicIPAddress(row){
    let address=this.publicIPAllList.filter(e=>e.id==row?.properties?.publicIPAddress?.id)
    return address[0]?.properties.ipAddress ? `${address[0]?.properties.ipAddress} (${address[0].name})` : address[0].name
  }
  pathvalidator(event){
    this.saveDisable=true
    if(event.name!='TCP'){
      this.currentForm.get('path').setValidators(Validators.required)
    }else{
      this.currentForm.get('path').setValidators(null)
    }
    this.currentForm.get('path').updateValueAndValidity()
  }
  outboundPort(event){
    this.saveDisable=true
      this.currentForm.get('portInstance')?.setValue(0)
      this.currentForm.get('backendInstance')?.setValue(1)
  }
  getRuleCount(row,type){
    let count=0
    if(type=='backendPool'){
      this.loadBalancerData.properties.inboundNatRules.forEach(e=>{
        if(e?.properties?.backendAddressPool && e?.properties?.backendAddressPool?.id==row.id){
          count=count+1
        }
      })
      this.loadBalancerData.properties.loadBalancingRules.forEach(e=>{
        e.properties.backendAddressPools.forEach(x=>{
          if(x.id==row.id){
            count=count+1
          }
        })
        
      })
      this.loadBalancerData.properties.outboundRules.forEach(e=>{
        if(e?.properties?.backendAddressPool?.id==row.id){
          count=count+1
        }
      })
    }else{
      this.loadBalancerData.properties.inboundNatRules.forEach(e=>{
        if(e?.properties?.frontendIPConfiguration && e?.properties?.frontendIPConfiguration?.id==row.id){
          count=count+1
        }
      })
      if( this.loadBalancerData.properties.outboundRules &&  this.loadBalancerData.properties.outboundRules.length){
        this.loadBalancerData.properties.outboundRules.forEach(e=>{
          e.properties.frontendIPConfigurations.forEach(x=>{
            if(x.id==row.id){
              count=count+1
            }
          })
        })
      }
      this.loadBalancerData.properties.loadBalancingRules.forEach(e=>{
        if(e?.properties?.frontendIPConfiguration?.id==row.id){
          count=count+1
        }
      })
    }
    return count
  }
  async saveLBConfig(type,subType?:any){
    switch(type){
      case'frontendIP':{
        let body=this.loadBalancerData
        let rawValue=this.currentForm.getRawValue()
        if(this.isEdit){
          let index=body.properties.frontendIPConfigurations.findIndex(e=>e.name==rawValue.frontendIPName)
          if(this.selectedRow[5]=='Internal'){
            body.properties.frontendIPConfigurations[index].properties.subnet.id=rawValue.subnet
            body.properties.frontendIPConfigurations[index].properties.privateIPAddress=rawValue.publicIPAddress
            body.properties.frontendIPConfigurations[index].properties.privateIPAllocationMethod=rawValue.publicIPAssignment
          }else{
            if(rawValue.ipType){
              body.properties.frontendIPConfigurations[index].properties.publicIPAddress.id=rawValue.publicIP
            }
            body.properties.frontendIPConfigurations[index].properties=rawValue.gatewayLB
          }
          await this.updateLBData(this.selectedRow,body,'editFrontendIP')
        }else{
          let object={ 
            "name": rawValue.frontendIPName, 
            "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/loadBalancers/${this.selectedRow[0]}/frontendIPConfigurations/${rawValue.frontendIPName}`, 
            "etag": body.etag, 
            "type": "Microsoft.Network/loadBalancers/frontendIPConfigurations", 
            "properties": { 
                "provisioningState": "Succeeded", 
                "privateIPAllocationMethod": "Dynamic", 
            } 
        } 
        if(this.selectedRow[5]!='Internal'){
          object.properties['publicIPAddress']={
            "id": rawValue.publicIP
          }
        }else{
          object.properties['privateIPAllocationMethod']=rawValue.publicIPAssignment
          object.properties['privateIPAddress']=rawValue.publicIPAddress
          object.properties['subnet']={
            "id": rawValue.subnet
          }
        }
        body.properties.frontendIPConfigurations.push(object)
          await this.updateLBData(this.selectedRow,body,'addFrontendIP')
        }
        await this.getLoadBalancerData(this.selectedRow,'frontendIP')
        break;
      }
      case'healthProbes':{
        let body=this.loadBalancerData
        let rawValue= subType=='subForm' ?this.subSideSheetForm.getRawValue() :this.currentForm.getRawValue()
        let object={
                "name": rawValue.probeName,   
                "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/loadBalancers/${this.selectedRow[0]}/probes/${rawValue.probeName}`,   
                "etag": body.etag,   
                "properties": {   
                    "provisioningState": "Succeeded",   
                    "protocol": rawValue.protocol,    
                    "port": Number(rawValue.port),  
                    "intervalInSeconds":Number(rawValue.interval),   
                    "numberOfProbes":Number(rawValue.threshold), //unhealthy threshold value should be added here   
            	},   
                "type": "Microsoft.Network/loadBalancers/probes"         	
        }
        rawValue.protocol!='TCP' ? object['properties']['path']= rawValue.path :false
        body.properties.probes.push(object)
        await this.updateLBData(this.selectedRow,body,'addHealthProbes')
        if(subType=='subForm'){
          await this.getLoadBalancerData(this.selectedRow)
          this.currentForm.get('probe').setValue(object.id)
        }
        break;
      }
      case'lbRules':{
        let body=this.loadBalancerData
        let rawValue=this.currentForm.getRawValue()
        let poolArray=[]
        rawValue.backendPool.forEach(e=>{
          poolArray.push({id:e})
        })
        let object={   
                "name": rawValue.lbRuleName,   
                "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/loadBalancers/${this.selectedRow[0]}/loadBalancingRules/${rawValue.lbRuleName}`,   
                "etag": body.etag,   
                "type": "Microsoft.Network/loadBalancers/loadBalancingRules",   
                "properties": {   
                    "provisioningState": "Succeeded",   
                    "frontendIPConfiguration": {   
                        "id":   rawValue.frontendIP
                	},   
                    "frontendPort":rawValue.frontendPort,   
                    "backendPort":rawValue.backendPort,   
                    "enableFloatingIP": rawValue.floatingIP,    
                    "idleTimeoutInMinutes":rawValue.idealTimeout,   
                    "protocol": rawValue.protocolLBRules,   
                    "enableDestinationServiceEndpoint": false,   
                    "enableTcpReset": rawValue.tcpReset,   
                    "allowBackendPortConflict": false,   
                    "loadDistribution": rawValue.session,   
                    "backendAddressPool": {   
                        "id": rawValue.backendPool[0] 
                	},   
                    "backendAddressPools": poolArray,   
                    "probe": {   
                        "id": rawValue.probe
                	}   
            	}   
        }  
        body.properties.loadBalancingRules.push(object)
        this.updateLBData(this.selectedRow,body,'addLBRules')
        break;
      }
      case'inboundRules':{
        let body=this.loadBalancerData
        let rawValue=this.currentForm.getRawValue()
        let object={   
                          "name": rawValue.inboundName,   
                          "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/loadBalancers/${this.selectedRow[0]}/inboundNatRules/${rawValue.inboundName}`,   
                          "etag": body.etag,   
                          "type": "Microsoft.Network/loadBalancers/inboundNatRules",   
                          "properties": {   
                              "provisioningState": "Succeeded",   
                              "frontendIPConfiguration": {   
                                  "id": rawValue.frontendIP  
                          	},   
                              "frontendPort":rawValue.frontendPort,   
                              "backendPort":rawValue.backendPort,    
                              "enableFloatingIP": rawValue.floatingIP,   
                              "idleTimeoutInMinutes":rawValue.idealTimeout,   
                              "protocol": rawValue.protocol,    
                              "enableDestinationServiceEndpoint": false,   
                              "enableTcpReset": rawValue.tcpReset,   
                              "allowBackendPortConflict": false   
                      	}   
                  	}  
        body.properties.inboundNatRules.push(object)
        this.updateLBData(this.selectedRow,body,'addInboundRules')
        break;
      }
      case'outboundRules':{
        let body=this.loadBalancerData
        let rawValue=this.currentForm.getRawValue()
        let frontendIP=[]
        rawValue.frontendIP.forEach(e=>{
          frontendIP.push({id:e})
        })
        let object={ 
                      "name": rawValue.outboundName,   
                      "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/loadBalancers/${this.selectedRow[0]}/outboundRules/${rawValue.outboundName}`,   
                      "etag": body.etag,   
                      "type": "Microsoft.Network/loadBalancers/outboundRules",   
                      "properties": {   
                          "provisioningState": "Succeeded",   
                          "allocatedOutboundPorts": rawValue.portAllocation=='default'?63984:rawValue.outboundPort=='portInstance'?rawValue.portInstance:rawValue.backendInstance,   
                          "protocol": rawValue.protocol,   
                          "enableTcpReset": rawValue.protocol!='Udp'?rawValue.tcpReset :false,   
                          "idleTimeoutInMinutes":rawValue.idealTimeout,   
                          "backendAddressPool": {   
                              "id":rawValue.backendPool 
                      	},   
                          "frontendIPConfigurations":frontendIP    
                  	}
                }  
                body.properties.outboundRules && body.properties.outboundRules.length? body.properties['outboundRules'].push(object): body.properties['outboundRules']=[object]
       
        this.updateLBData(this.selectedRow,body,'addOutboundRules')
        break;
      }
      case'backendPool':{
        let body=this.loadBalancerData
        let rawValue=this.currentForm.getRawValue()
        if(this.isEdit){
          let i=this.loadBalancerData.properties.backendAddressPools.findIndex(e=>e.name==rawValue.poolName)
          if(rawValue.poolConfig=='nic'){
            this.loadBalancerData.properties.backendAddressPools[i].properties.backendIPConfigurations
            let index=this.nicData.body.properties.ipConfigurations.findIndex(e=>this.loadBalancerData.properties.backendAddressPools[i].properties.backendIPConfigurations[0].id==e.id)
            this.nicData.body.properties.ipConfigurations[index].properties.loadBalancerBackendAddressPools=[]
            this.loadBalancerData.properties.backendAddressPools[i].properties.loadBalancerBackendAddresses=[]
            this.vmPoolList.forEach(e=>{
              let split=e.id.split('/')
              let poolBody={   
                                            "name": `${split[4]}_${split[8]}${split[10]}`,   
                                            "id": `/subscriptions/${split[2]}/resourceGroups/${split[2]}/providers/Microsoft.Network/loadBalancers/${this.selectedRow[0]}/backendAddressPools/${ this.loadBalancerData.properties.backendAddressPools[i].name}/loadBalancerBackendAddresses/${split[4]}_${split[8]}${split[10]}`,   
                                            "etag": body.etag,   
                                            "properties": {   
                                                "provisioningState": "Succeeded",   
                                                "networkInterfaceIPConfiguration": {   
                                                   "id": e.id
                                            	   },   
                                                "inboundNatRulesPortMapping": []   
                                        	},   
                                            "type": "Microsoft.Network/loadBalancers/backendAddressPools/loadBalancerBackendAddresses"   
                                    	}  
              this.loadBalancerData.properties.backendAddressPools[i].properties.loadBalancerBackendAddresses.push(poolBody)
            })
          }else{

          }
          await this.UpdateNICData(this.selectedRow,this.nicData.body)
          this.updateLBData(this.selectedRow,this.loadBalancerData,'editBackendPool')
        }else{
          let poolVmArray=[]
          this.vmPoolList.forEach(e=>{
            let split=e.id.split('/')
            let poolBody={   
                  "name": `${split[4]}_${split[8]}${split[10]}`,   
                  "id": `/subscriptions/${split[2]}/resourceGroups/${split[2]}/providers/Microsoft.Network/loadBalancers/${this.selectedRow[0]}/backendAddressPools/${rawValue.poolName}/loadBalancerBackendAddresses/${split[4]}_${split[8]}${split[10]}`,   
                  "etag": body.etag,   
                  "properties": {   
                    "provisioningState": "Succeeded",   
                    "networkInterfaceIPConfiguration": {   
                       "id": e.id
                    },   
                    "inboundNatRulesPortMapping": []   
                  },   
                 "type": "Microsoft.Network/loadBalancers/backendAddressPools/loadBalancerBackendAddresses"   
            }  
          poolVmArray.push(poolBody)
          })
          let object= {
            "name": rawValue.poolName,
            "id":  `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/loadBalancers/${this.selectedRow[0]}/backendAddressPools/${rawValue.poolName}`,
            "etag": body.etag,
            "properties": {
                "provisioningState": "Succeeded",
                "loadBalancerBackendAddresses":poolVmArray
            },
            "type": "Microsoft.Network/loadBalancers/backendAddressPools"
        }  
          body.properties.backendAddressPools.push(object)
          this.updateLBData(this.selectedRow,body,'addBackendPool')
        }
      

        break;
      }
  }
  }
  async createPublicIP(){
    let rawValue=this.currentForm.getRawValue()
    let publicIPbody={
      "properties": {
        "publicIPAllocationMethod": rawValue.publicIPAssignment,
        "idleTimeoutInMinutes": 10,
        "publicIPAddressVersion": "IPv4"
      },
      "sku": {
        "name": rawValue.publicIPSKU,
        "tier": rawValue.publicIPTier
      },
      "location": this.selectedRow[3]
    }
    await this.updatePublicIPAddress(this.selectedRow[2],publicIPbody,this.selectedRow[1],rawValue.publicIPName,'puclicIPCreate')
    await this.getPublicIPList()
    let id=this.publicIPList.filter(e=>e.name.split(' (')[0]==rawValue.publicIPName)[0].id
    this.currentForm.get('publicIP').setValue(id)
  }
  async createPublicIpPrefix(){
    let rawValue=this.currentForm.getRawValue()
    let body={
      "type": "Microsoft.Network/publicipprefixes",
      "name": rawValue.publicIPName,
      "location": this.selectedRow[3],
      "properties": {
      "prefixLength":  rawValue.prefixSize,
      "publicIPAddressVersion": "IPv4"
      },
      "sku": {
        "name": "Standard",
        "tier": "Regional"
      },
      "zones": [rawValue.publicIPZone],
      // "dependsOn": []
      }
    await this.createPublicIPPrefix(this.selectedRow[2],body,this.selectedRow[1],rawValue.publicIPName)
    await this.getPublicIpPrefixList(this.selectedRow)
    let id=this.publicIpPrefixList.filter(e=>e.name==rawValue.publicIPName)[0].id
    this.currentForm.get('publicPrefix').setValue(id)
  }
  
  //validation
  validIPName(event,type,subType?:any){
    let value =event.target.value;
    this.saveDisable=true
    this.inputError[type+'NameExist'] = false;
    this.inputError[type+'NameEmpty'] = false
    this.inputError[type+'NameMaxLen'] = false;
    this.inputError[type+'NameValid']=false;
   if(value==''){
    this.inputError[type+'NameEmpty'] = true;
   }else{
    if (value.length > 80) {
      this.inputError[type+'NameMaxLen'] = true;
      return;
    }
   }
    if(value.length==1 && !(/^[A-Za-z0-9]*$/.test(value)) || !(/^[A-Za-z0-9_]+$/.test(value[value.length-1]))){
        this.inputError[type+'NameValid']=true;
    }
    switch(subType){
      case 'frontend':{
        this.loadBalancerData.properties.frontendIPConfigurations.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'publicIPName':{
        this.publicIPAllList.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'ipPrefix':{
        this.publicIpPrefixList.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'probe':{
        this.loadBalancerData.properties.probes.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'rules':{
        this.loadBalancerData.properties.loadBalancingRules.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
        this.loadBalancerData.properties.inboundNatRules.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'outboundRule':{
        this.loadBalancerData.properties.outboundRules.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'backendPool':{
        this.loadBalancerData.properties.backendAddressPools.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
    }
  }
  validProbes(event,type){
    let value=event.target.value;
    this.saveDisable=true
    this.inputError[type+'InvalidSizeRange']=false; 
    this.inputError[type+'InvalidEmpty']=false;
    this.inputError[type+'InvalidNumber']=false;
    if(value&&value!="" &&type!='path'){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError[type+'InvalidNumber']=true;
      }
      if(type=='port' && (value<1 || Number(value)>65535)) return this.inputError[type+'InvalidSizeRange']=true;
      else if(type=='interval' && (value<5 || Number(value)>2147483646)) return this.inputError[type+'InvalidSizeRange']=true;
      else if(type=='threshold' && (value<2 || Number(value)>429496729)) return this.inputError[type+'InvalidSizeRange']=true;
      if((type=='portLB' || type=='backendPort' || type=='machineCount') && (value<0 || Number(value)>65534)) return this.inputError[type+'InvalidSizeRange']=true;
    }else{
      this.inputError[type+'InvalidEmpty']=true;
    }
  }
  validPortInstance(event,type){
    let value=event.target.value;
    this.saveDisable=true
    this.inputError[type+'InvalidSizeRange']=false; 
    this.inputError[type+'InvalidEmpty']=false;
    this.inputError[type+'InvalidNumber']=false;
    this.inputError['InvalidPortInstance']=false;
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError[type+'InvalidNumber']=true;
      }
      if( this.currentForm.get('portInstance')?.value*this.currentForm.get('backendInstance')?.value>0) return this.inputError['InvalidPortInstance']=true;
      if(type=='portLB' && (value%8!=0)) return this.inputError[type+'InvalidSizeRange']=true;
      else if(type=='backendPort' && (value<0 || Number(value)>1000)) return this.inputError[type+'InvalidSizeRange']=true;
    }else{
      this.inputError[type+'InvalidEmpty']=true;
    }
  }
  idleTimeValidation(event){
    let value=event.target.value;
    this.inputError['idleTimeoutRange']=false; 
    this.inputError['idleTimeoutEmpty']=false;
    this.inputError['idleTimeoutNumber']=false;
    if(value&&value!="" ){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError['idleTimeoutNumber']=true;
      }
      if(value<4 || Number(value)>30) return this.inputError['idleTimeoutRange']=true;

    }else{
      this.inputError['idleTimeoutEmpty']=true;
    }
  }
  validIPAddressPool(event,index,list){
    let val=event.name
    list.get('resourceName').setValue(event.resourceName)
    this.inputError['ipError']=false; 
    let temp=[]
    if(this.ipArrayList.length){
      this.ipArrayList.controls.forEach(e=>{
        if(event.name==e.get('ipAddress').value){
          temp.push(event.id)
        }
      })
    }
    return this.inputError['ipError']=temp.length>1?true:false;
  }
  pushIPAddress(event,list){
    let val=event.target.value;
    if(event.key=='Enter'){
      list.get('resourceName').setValue('Private Network Resource')
        this.ipArrayList.controls.forEach(e=>{
          if(val==e.get('ipAddress').value){
            this.inputError['ipError']=true
          }else this.inputError['ipError']=false
        })
        this.ipPoolList= this.ipPoolList.length ? this.ipPoolList:[]
     if(!this.inputError.ipError){
      this.ipPoolList.push({
        name:val,
        id:val,
        resourceName:'Private Network Resource'
      })
     }
     list.get('ipAddress').setValue(val)
    }
  }
  dataSizeValidation(event){
    let value=event.target.value;
    this.saveDisable=true
    this.inputError['InvalidSizeRange']=false; 
    this.inputError['InValidSize']=false;
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError['InValidSize']=true;
      }
      if(value<30 || value>180) return this.inputError['InvalidSizeRange']=true;
    }else{
      this.inputError['InvalidSizeRange']=true;
      this.inputError['InValidSize']=true;
    }
  }
  CheckDnsServer(event,index,type?:any){
      let value=event.target.value;
      this.inputError['ipError']=false;//sourceInvalidArgument
  
      if(value&&value!=""){
      const splitArComma=value.split(',');
      let ipV4Value;
      let tempArr=[];
      for(let i=0;i<splitArComma.length;i++){
        if(!splitArComma[i]){
         // let inputError="Invalid argument: 'A non-null address range is required.'";
          this.inputError['ipError']=true;
          break;
        }else{
          const splitArr=splitArComma[i].split('/');
          if(splitArr.length>1&&!splitArr[1]){
            //Maliform
            this.inputError['ipError']=true;
          }else{
            ipV4Value=splitArr[0];
          }
          
          try{
            let ipV4Obj=new this.ipV4(ipV4Value);
           // item.VNetAdrspc=false;
            let vlIPRange;
            if(splitArr.length>1){
            try{
              vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
              //item.VNetAdrspc=false;
              if(vlIPRange.input!=splitArComma[i]){
                //item.notValidCIDR=true;
                //item.validCIDRValue=vlIPRange.input;
                this.inputError['ipError']=true;
                break;
              }
            }catch(e){
              //item.VNetAdrspc=true;
              this.inputError['ipError']=true;
              break;
            }
          }        
          }catch(e){
         //   item.VNetAdrspc=true;
         this.inputError['ipError']=true;
            break;
          }
        }
      }
    }

}
  invalidIP(event,type?:any){
    let value=event.target.value
    let inputSplit=value.split('.')
    // if(inputSplit.length!=3||inputSplit[inputSplit.length-1]){
    //   this.inputError['ipError']=true
    // }
    let subnet=this.currentForm.get('subnet').value
    let sub=this.vnetData.properties.subnets.filter(e=>e.id==subnet)
    let split=sub[0].properties.addressPrefix.split('.')
    // let existIP=this.nicData.properties.ipConfigurations.filter((e,i)=>{
    //   if(e.name!=this.currentForm.get('ipError').value){return e.properties.privateIPAddress==value}}
    //   )
    if(inputSplit[0]==split[0]&&inputSplit[1]==split[1]&&inputSplit[2]==split[2]&&inputSplit.length==4&&inputSplit[3]!=''&&inputSplit[3].length<=3){
      this.inputError['ipError']=false
    }else this.inputError['ipError']=true
    // if(inputSplit.length!=4||inputSplit[inputSplit.length-1]==''){
    //     this.inputError['ipError']=true
    // }else{
    // if(split[0]!=inputSplit[0] || split[2]!=inputSplit[2] || split[1]!=inputSplit[1]){
    //   this.inputError['ipError']=true
    // }else if(inputSplit[3]<'4'||!(/^[0-9]*$/.test(inputSplit[3]))){ this.inputError['ipError']=true}
    // else if(value.length>8){ this.inputError['ipError']=true}
    // else if(existIP[0].name==this.currentForm.get('name').value){ this.inputError.dnsError=true}
    // else   this.inputError['ipError']=false
    // }
  }
 async getVMStatus(id){
  let split=id.split('/')
  this.vmData=await this.util.handleRequest('get','a3s_management_getVMConfig',[split[2],split[4],split[8]],null,null,null,true)
 }


  //API's
  async getPublicIPList(type?:any){
    let subsId = this.selectedRow[2]
    let temp=[]
    return  this.util.handleRequest('get','a3s_management_getPublicIPlistData',[subsId],null,null,null,true).then(res=>{
      this.publicIPAllList = res.body.value
       this.publicIPList=this.publicIPAllList.filter(e=>e.location == this.selectedRow[3])
        this.publicIPList.forEach(e=>{
          if( !e.properties.ipConfiguration && e.properties.publicIPAddressVersion=='IPv4' ){
            return temp.push({
              name: e.properties.ipAddress ? e.name+` (${e.properties.ipAddress})` : e.name,
              id:e.id,
              type: 'Can be associated with this load balancer'
              })
          }
          })
          this.publicIPList.forEach(e=>{
            if(this.isEdit &&  this.currentForm.get('publicIP').value==e.id){
              return temp.push({
                name: e.properties.ipAddress ? e.name+` (${e.properties.ipAddress})` : e.name,
                id:e.id,
                type: 'Can be associated with this load balancer'
                })
            }
            else if( e.properties.ipConfiguration || e.properties.publicIPAddressVersion!='IPv4' ){
              return temp.push({
                name: e.properties.ipAddress ? e.name+` (${e.properties.ipAddress})` : e.name,
                id:e.id,
                type: 'Cannot be associated with this load balancer'
                })
            }
            })     
          this.publicIPList=temp
      console.log('public IP List Data',this.publicIPList)
    })
  }
  async updatePublicIPAddress(subsId,body,rgName,IPName,type?:any){
    return this.util.handleRequest('post','a3s_management_updatePublicIPAddress',[subsId,rgName,IPName],body,null,null,true).then(res=>{
      //  this.PublicIPData = res.body
       // this.showSubSidesheet = false
       this.util.success(this.getSuccesText(type))
      
     })
   }
   getVMListbsRG(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let temp=[{name:'None',id:'None'}]
    return this.util.handleRequest('get','a3s_management_getVMList',[subsId,rgName],null,null,null,true).then(async res=>{
      let vmlist= res.body.value
      if(type=='inboundRules'){
        vmlist.forEach(e=>{
          return temp.push({
            name:e.name,
            id:e.id,
            })
        })
        this.targetVMList=temp
      }else{
        this.targetVMList=vmlist
      }
      console.log('VM list',this.targetVMList)
    })
  }
  getPublicIpPrefixList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let temp=[{name:'None',id:'None'}]
    return this.util.handleRequest('get','a3s_management_getPublicIpPrefixList',[subsId,rgName],null,null,null,true).then(async res=>{
      this.publicIpPrefixList= res.body.value
      console.log('Public IP Prefix list',this.publicIpPrefixList)
    })
  }
  async createPublicIPPrefix(subsId,body,rgName,IPName,type?:any){
    return this.util.handleRequest('post','a3s_management_createPublicIPPrefix',[subsId,rgName,IPName],body,null,null,true).then(res=>{
      //  this.util.success(this.getSuccesText(type))
     })
   }
  getLoadBalancerListbsRG(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let temp=[{name:'None',id:'None'}]
    return this.util.handleRequest('get','a3s_management_getLoadBalancerList',[subsId,rgName],null,null,null,true).then(async res=>{
      this.loadBalancerList= res.body.value
      this.loadBalancerList.forEach(e=>{
        if(e.sku.name=='Gateway'){
          e.properties.frontendIPConfigurations.forEach(x=>{
             temp.push({
              name:`${x.name} (${x.properties.privateIPAddress})`,
              id:x.id
             })
          })
        }
      })
      this.gatewayLBList=temp
      console.log('Load Balancer list',this.loadBalancerList)
    })
  }


  async getLoadBalancerData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let lbName = data[0]
    return this.util.handleRequest('get','a3s_management_getLoadBalancerData',[subsId,rgName,lbName],null,null,null,true).then(async res=>{
      this.loadBalancerData= res.body
      this.frontendIPList=[]
      this.backendPoolsList=[]
      this.probesList=[]
      if(this.loadBalancerData?.properties?.frontendIPConfigurations[0].properties?.subnet){
        this.vNetName=this.loadBalancerData?.properties?.frontendIPConfigurations[0].properties?.subnet.id
      }else if(this.loadBalancerData?.properties?.backendAddressPools[0].properties.backendIPConfigurations){
        this.loadBalancerData?.properties?.backendAddressPools[0].properties.backendIPConfigurations.forEach(async e=>{
          if(e.id.includes('Microsoft.Network/networkInterfaces')){
            let nicData=  await this.util.handleRequest('get','a3s_management_getVMNetworkInterface',[this.selectedRow[2],this.selectedRow[1],e.id.split('/')[8]],null,null,null,true)
            nicData.body.properties.ipConfigurations.forEach(x=>{
              let lbPool=x.properties.loadBalancerBackendAddressPools.filter(dt=>dt.id==this.loadBalancerData?.properties?.backendAddressPools[0].id)
              if(lbPool.length){
                this.vNetName=x.properties.subnet.id
              }
            })
          }
        })
      }
      switch(type){
        case'frontendIP':{
          if(this.vNetName){
            let split=this.vNetName.split('/')
            let sub=split[2]
            let rgName=split[4]
            let vName=split[8]
            let vnetData= await this.util.handleRequest('get','a3s_management_getVNETData',[sub,rgName,vName],null,null,null,true)
            this.subnetList=[]
            this.vnetData=vnetData.body
            vnetData.body.properties.subnets.forEach(e=>{
              this.subnetList.push({
                name:`${e.name} (${e.properties.addressPrefix})`,
                id:e.id
              })
            })
          }
          break;
        }
        case'lbRules':{
          this.loadBalancerData.properties.frontendIPConfigurations.forEach(e=>{
            this.frontendIPList.push({
              name:e.properties.privateIPAddress?`${e.name} (${e.properties.privateIPAddress})`:`${e.name}`,
              id:e.id
            })
          })
          this.backendPoolsList=this.loadBalancerData.properties.backendAddressPools
          this.probesList=this.loadBalancerData.properties.probes
          break;
        }
        case 'inboundRules':{
          this.backendPoolsList=[{name:'None',id:'None'}]
          this.loadBalancerData.properties.frontendIPConfigurations.forEach(e=>{
            this.frontendIPList.push({
              name:e.properties.privateIPAddress?`${e.name} (${e.properties.privateIPAddress})`:`${e.name}`,
              id:e.id
            })
          })
          this.backendPoolsList=this.loadBalancerData.properties.backendAddressPools
          break;
        }
      }
      console.log('Load Balancer Data',this.loadBalancerData)
    })
  }

  updateLoadBalancerData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let sqlVMName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateLoadBalancerData',[subsId,rgName,sqlVMName],body,null,null,true).then(res=>{
      console.log('Load Balancer updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  updateLBData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let sqlVMName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateLBData',[subsId,rgName,sqlVMName],body,null,null,true).then(res=>{
      console.log('Load Balancer updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
      if(type=='addLBRules'){
        let index = this.loadBalancerData.properties.loadBalancingRules.findIndex(e=> e.name == this.currentForm.get('lbRuleName').value)
        this.loadBalancerData.properties.loadBalancingRules.splice(index,1)
      }else if(type=='addOutboundRules'){
        let index = this.loadBalancerData.properties.outboundRules.findIndex(e=> e.name == this.currentForm.get('outboundName').value)
        this.loadBalancerData.properties.outboundRules.splice(index,1)
      }else if(type=='addInboundRules'){
        let index = this.loadBalancerData.properties.inboundNatRules.findIndex(e=> e.name == this.currentForm.get('inboundName').value)
        this.loadBalancerData.properties.inboundNatRules.splice(index,1)
      }
    })
  }

  deleteLoadBalancerData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],sqlVMName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteLoadBalancerData',[subscriptionId,resourceGroupName,sqlVMName],null,null,null,true).then(async res=>{
      console.log('Load Balancer Data Deleted',res)
      this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
  }
  getVNETData(data,type?:any){
    let split=this.vNetName.split('/')
    let sub=split[2]
    let rgName=split[4]
    let vName=split[8]
       return this.util.handleRequest('get','a3s_management_getVNETData',[sub,rgName,vName],null,null,null,true).then(res=>{
         let vnetData= res.body
         this.vnetData= vnetData.properties.subnets.filter(e=>this.vNetName==e.id)
         this.vmNicList=[]
         this.vmSetNicList=[]
         this.ipPoolList=[]
         this.defaultIPList=[]
         this.vnetData[0].properties.ipConfigurations.forEach(async e=>{
          if(e.id.includes('Microsoft.Network/networkInterfaces')){
            // && e.id.split('/')[4]==this.selectedRow[1]
            this.nicData =  await this.util.handleRequest('get','a3s_management_getVMNetworkInterface',[e.id.split('/')[2],e.id.split('/')[4],e.id.split('/')[8]],null,null,null,true)
            this.nicData.body.properties.ipConfigurations.forEach(x=>{
              if(e.id.split('/')[10]==x.name){
                this.vmNicList.push({
                  name:this.nicData.body.properties.virtualMachine.id.split('/')[8],
                  id:x.id,
                  // resourceName:this.nicData.body.name,
                  ipConfig:x.name,
                  privateIPAddress:x.properties.privateIPAddress
                })
              }
              this.ipPoolList.push({
                name:x.properties.privateIPAddress,
                id:x.id,
                resourceName:this.nicData.body.properties.virtualMachine.id.split('/')[8]
              })
              this.defaultIPList=this.ipPoolList
            })
          }else if(e.id.includes('Microsoft.Compute/virtualMachineScaleSets')){
            // && e.id.split('/')[4]==this.selectedRow[1]
            // let nicData =  await this.util.handleRequest('get','a3s_management_getVMNetworkInterface',[this.selectedRow[2],this.selectedRow[1],e.id.split('/')[12]],null,null,null,true)
            // body.properties.ipConfigurations
            let scaleSetList=await this.util.handleRequest('get','a3s_management_getVMScaleSetList',[this.selectedRow[2]],null,null,null,true)
            scaleSetList.body.value.forEach(x=>{
              x.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations.forEach(res=>{
                if(e.id.split('/')[12]==res.name){
                  this.vmSetNicList.push({
                    name:x.name,
                    id:x.id,
                    ipConfig:res.name,
                    // resourceName:this.nicData.body.name,
                    // privateIPAddress:x.properties.privateIPAddress
                  })
                }
              })
            })
            this.vmSetNicList =  this.util.unique(this.vmSetNicList, ['name']);
          }
         })

         console.log('VM Nic',this.vmNicList)
      })
  }
  getVMScaleSetList(){
      return this.util.handleRequest('get','a3s_management_getVMScaleSetList',[this.selectedRow[2]],null,null,null,true).then(res=>{
         let vmScaleSetList= res.body
         console.log('VM Scale Set List',vmScaleSetList)
      })
  }
  UpdateNICData(data,body,type?:any){
    let subsId = data[2]
    let rgName = data[1] 
    let nicName = this.nicData.body.name
    this.util.handleRequest('post','a3s_management_updateNICData',[subsId,rgName,nicName],body,null,null,true).then(res=>{
      console.log('NIC Data',res)
    },err=>{
    })
  }

}
