import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infra-assessment',
  templateUrl: './infra-assessment.component.html',
  styleUrls: ['./infra-assessment.component.scss']
})
export class InfraAssessmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
