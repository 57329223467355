import { Injectable, TemplateRef } from '@angular/core';
import { StaticValues } from '../../assets/constants/static';
import { DataSetService } from './data-set.service';
import { ApiService } from './api.service';
import { LoaderService } from './loader.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { FunctionService } from './function.service';
import { FormService } from './form.service';
import { FilesService } from './files.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class UtilService {

   

    private constValues = StaticValues.statics;

    constructor(
        private api:ApiService,
        private modal : ModalService,
        private datamod: DataSetService,
        private loader : LoaderService,
        private logger:LoggerService,
        private func:FunctionService,
        private files:FilesService,
        private form :FormService,
        private router:Router) {
            loader.loaderQue.subscribe((que) => {
                if (que.length > 0) {
                  loader.showLoader();
                } else {
                  loader.hideLoader();
                }
              });
          
         }


    route(url,skip?: any,parameters?: any){
       return this.router.navigate(url,{ queryParams: parameters, skipLocationChange: skip ? skip : false })
    }

    //handler for api requests
    handleRequest(type: string, url: string, parameters?: any, body?: any, applicationType?: any, neglectError?: any, avoidLoader?: any): Promise<any> {
        return this.api.handleRequest(type, url, parameters, body, applicationType, neglectError, avoidLoader);
    }

    getStatic(target, key?: any) {
        return key ? this.constValues[target][key] : this.constValues[target];
    }

    showModal(title?: any, body?: any) {
        this.modal.showModal(title,body);
    }

    showSimpleTemplateModal(type) {
        this.modal.showSimpleTemplateModal(type);
    }

    openModal(template: TemplateRef<any>) {
        this.modal.openModal(template);
    }

    closeModal() {
        this.modal.closeModal();
    }

    setObservable(target, value) {
        this.datamod.setObservable(target, value);
    }

    getObservable(): Observable<any> {
        return this.datamod.getObservable();
    }

    // to set and get cache values
    getCache(target, reset?: any) {
        return this.datamod.getCache(target, reset);
    }
    setCache(target, value) {
        this.datamod.setCache(target, value);
    }
    
    // to show and hide the loader
    showLoader(){
      this.loader.showLoader();
    }

    hideLoader(){
        this.loader.hideLoader();
    }

    // get unique based on property
    unique(arr, keyProps) {
        const kvArray = arr.map(entry => {
        const key = keyProps.map(k => entry[k]).join('|');
        return [key, entry];
        });
        const map = new Map(kvArray);
        return Array.from(map.values());
    }

    // Logger Functions
    log(txt, modifers?: any){
        this.logger.log(txt,modifers);
    }

    warn(txt){
        this.logger.warn(txt);
    }
    
    consoleError(txt){
        this.logger.consoleError(txt);
    }

    table(data){
        this.logger.table(data);
    }
    groupCollapsed(txt,modifers?:any){
        this.logger.groupCollapsed(txt,modifers);
    }
      
    group(txt,modifers?:any){
        this.logger.group(txt,modifers);
    }
    
    groupEnd(){
        this.logger.groupEnd();
    }

    groupedLog(data, text?:any){
        this.logger.groupCollapsed('%c \'' + text + '\' data ready ', 'successValue');
        this.logger.log(data);
        this.logger.groupEnd();
    }

    groupedTableLog(data, text?:any){
        this.logger.groupCollapsed('%c \'' + text + '\' data ready ', 'successValue');
        this.logger.table(data);
        this.logger.groupEnd();
    }

    success(text){
        this.logger.success(text);
    }
    
    error(text){
        this.logger.error(text);
    }

    info(text){
        this.logger.info(text);
    }
    
    changeTimeFormat(createdTime){
        return this.func.changeTimeFormat(createdTime)
    }

    formatAMPM(date){
        return this.func.formatAMPM(date)
    }

    refreshedTimeFormat(date){
        return this.func.refreshedTimeFormat(date)
    }

    getTimeRangeQuery(filter){
        return this.func.getTimeRangeQuery(filter)
    }

    getIdentitySecurityTimeRangeQuery(filter){
        return this.func.getIdentitySecurityTimeRangeQuery(filter)
    }
    
    getNetworkMonitoringTimeRangeQuery(filter){
        return this.func.getNetworkMonitoringTimeRangeQuery(filter)
    }

    getTimeFilterText(filter){
        return this.func.getTimeFilterText(filter)
    }
    
    getUTCText(filter){
        return this.func.getUTCText(filter)
    }

    getCommonIsoDate(filter){
        return this.func.getCommonIsoDate(filter)
    }
    
    getLastSeenTimeRangeQuery(filter){
        return this.func.getLastSeenTimeRangeQuery(filter)
    }

    getInsightsTimeRangeQuery(filter){
        return this.func.getInsightsTimeRangeQuery(filter)
    }
    getCustomQueryTimeRangeParm(filter){
        return this.func.getCustomQueryTimeRangeParm(filter)
    }
    generatePassword(length){
        return this.func.generatePassword(length);
    }

    doSortBasedonKey(data, key){
        return this.func.doSortBasedonKey(data, key)
    }

    splitWordByCaps(data){
        return this.func.splitWordByCaps(data)
    }
    splitWordByCapsExceptAbb(data){
        return this.func.splitWordByCapsExceptAbb(data)
    }
    getRegionName(location){
        return this.func.getRegionName(location)
    }
    getResourceTypeName(location){
        return this.func.getResourceTypeName(location)
    }

    delay(delayInms){
        return this.func.delay(delayInms)
    }

    doOperation(operator,a,b){
        return this.func.doOperation(operator,a,b)
    }

    // getDynaptDatas(){
    //     return this.func.getDynaptDatas()
    // }

    // getZenviroTechDatas(){
    //     return this.func.getZenviroTechDatas()
    // }

    getStaticResourceTypes(){
        return this.func.getStaticResourceTypes()
    }

    getProperResourceTypeName(type){
        return this.func.getProperResourceTypeName(type)
    }

    // getZenviroTechProjectData(){
    //     return this.func.getZenviroTechProjectData()
    // }

    getAnalyticsUseCaseShortName(type){
        return this.func.getAnalyticsUseCaseShortName(type)
    }

    getPieChartTooltip(data){
        return this.func.getPieChartTooltip(data)
    }

    getHorizontalBarChartTooltip(data?:any){
        return this.func.getHorizontalBarChartTooltip(data)
    }

    getMultipleVerticalBarChartTooltip(data?:any){
        return this.func.getMultipleVerticalBarChartTooltip(data)
    }

    getLineChartTooltip(){
        return this.func.getLineChartTooltip()
    }

    getSingleBarChartTooltip(){
        return this.func.getSingleBarChartTooltip()
    }

    getChartTooltipStyles(){
        return this.func.getChartTooltipStyles()
    }

    getDynamicXaxisFormat(xAxisData,value){
        return this.func.getDynamicXaxisFormat(xAxisData,value)
    }

    getTimeGranularity(filterDate){
        return this.func.getTimeGranularity(filterDate);
    }


    // ********************************** Files Services **************//
    generateHealthMonitoringCSV(name,data,additionalData?:any,ignoreName?:any){
        this.files.generateHealthMonitoringCSV(name,data,additionalData,ignoreName);
    }

    generateInsightsCSV(name,data){
        this.files.generateInsightsCSV(name,data);
    }

    generateCommonCSV(name,data){
        this.files.generateCommonCSV(name,data);
    }

    generateCSVForTableData(name,data,ignoreName?:any){
        this.files.generateCSVForTableData(name,data,ignoreName);
    }
    
    generateCSVForCustom(name,headers,dataList): void {
        this.files.generateCSVForCustom(name,headers,dataList);
    }

    /***************************************** Form Service ************************************/
     //handler to get reactive forms
    getForm(type, data?: any) {
        return this.form.getForm(type, data);
    }
}
