import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { TileRequest } from '../data-governance-ddc/data-governance-ddc.state';
import { TileData } from '../data-governance-overview/data-governance-overview.state';


export class DataGovernanceSecurityStateModel {
  datasort?:string;
  workspaceAdministrator?:any;
  auditSettings?:TileData;
  extendAuditSettings?:TileData;
  ipFirewallRules?:TileData;
  managedIdentitySQLSettings?:TileData;
  privateEndpointConnections?:TileData;
  recoverableSQLPools?:TileData;
  securityAlertPolicies?:TileData;
  vulnerabilityAssessments?:TileData;
  connectionPolicies?:TileData;
  currentMaintenanceWindowOptions?:TileData;
  geoBackupPolicies?:TileData;
  metadataSyncConfig?:TileData;
  replicationLinks?:TileData;
  restorePoints?:TileData;
  securityAlertPoliciesSQLPOOL?:TileData;
  transparentDataEncryption?:TileData;
  vulnerabilityAssessmentScans?:TileData;
  vulnerabilityAssessmentsSQLPOOL?:TileData;
}

export class GetTilesDataSecurityAction {
  static readonly type = '[DataGovernanceSecurity] getTilesDataSecurity';

  constructor(readonly payload: TileRequest) { }
}

export class GetWorkspaceAdministratorAction {
  static readonly type = '[DataGovernanceSecurity] getWorkspaceAdministrator';

  constructor(readonly payload: TileRequest) { }
}

export class SortTilesDataSecurityAction {
  static readonly type = '[DataGovernanceSecurity] sortTilesDataSecurity';

  constructor(readonly tileName: string, readonly fieldName: string) { }
}

const DATA_GOVERNANCE_SECURITY_STATE_TOKEN = new StateToken<DataGovernanceSecurityState>('dataGovernanceSecurity');

@State<DataGovernanceSecurityStateModel>({
  name: DATA_GOVERNANCE_SECURITY_STATE_TOKEN,
  defaults:{
    workspaceAdministrator:{
      value:[],
      refreshedTime:new Date()
    },
    auditSettings:{
      value:[],
      refreshedTime:new Date()
    },
    extendAuditSettings:{
      value:[],
      refreshedTime:new Date()
    },
    ipFirewallRules:{
      value:[],
      refreshedTime:new Date()
    },
    managedIdentitySQLSettings:{
      value:[],
      refreshedTime:new Date()
    },
    privateEndpointConnections:{
      value:[],
      refreshedTime:new Date()
    },
    recoverableSQLPools:{
      value:[],
      refreshedTime:new Date()
    },
    securityAlertPolicies:{
      value:[],
      refreshedTime:new Date()
    },
    vulnerabilityAssessments:{
      value:[],
      refreshedTime:new Date()
    },
    connectionPolicies:{
      value:[],
      refreshedTime:new Date()
    },
    currentMaintenanceWindowOptions:{
      value:[],
      refreshedTime:new Date()
    },
    geoBackupPolicies:{
      value:[],
      refreshedTime:new Date()
    },
    metadataSyncConfig:{
      value:[],
      refreshedTime:new Date()
    },
    replicationLinks:{
      value:[],
      refreshedTime:new Date()
    },
    restorePoints:{
      value:[],
      refreshedTime:new Date()
    },
    securityAlertPoliciesSQLPOOL:{
      value:[],
      refreshedTime:new Date()
    },
    transparentDataEncryption:{
      value:[],
      refreshedTime:new Date()
    },
    vulnerabilityAssessmentScans:{
      value:[],
      refreshedTime:new Date()
    },
    vulnerabilityAssessmentsSQLPOOL:{
      value:[],
      refreshedTime:new Date()
    },
  }
})
@Injectable()
export class DataGovernanceSecurityState {
  constructor(private readonly apiService:ApiService,private http:HttpClient) {}

  @Selector([DataGovernanceSecurityState])
  static workspaceAdministrator(state:DataGovernanceSecurityStateModel): any {
    return state?.workspaceAdministrator
  }
  
  @Selector([DataGovernanceSecurityState])
  static auditSettings(state:DataGovernanceSecurityStateModel): TileData {
    return state?.auditSettings
  }

  @Selector([DataGovernanceSecurityState])
  static extendAuditSettings(state:DataGovernanceSecurityStateModel): TileData {
    return state?.extendAuditSettings
  }

  @Selector([DataGovernanceSecurityState])
  static ipFirewallRules(state:DataGovernanceSecurityStateModel): TileData {
    return state?.ipFirewallRules
  }

  @Selector([DataGovernanceSecurityState])
  static managedIdentitySQLSettings(state:DataGovernanceSecurityStateModel): TileData {
    return state?.managedIdentitySQLSettings
  }

  @Selector([DataGovernanceSecurityState])
  static privateEndpointConnections(state:DataGovernanceSecurityStateModel): TileData {
    return state?.privateEndpointConnections
  }

  @Selector([DataGovernanceSecurityState])
  static recoverableSQLPools(state:DataGovernanceSecurityStateModel): TileData {
    return state?.recoverableSQLPools
  }

  @Selector([DataGovernanceSecurityState])
  static securityAlertPolicies(state:DataGovernanceSecurityStateModel): TileData {
    return state?.securityAlertPolicies
  }

  @Selector([DataGovernanceSecurityState])
  static vulnerabilityAssessments(state:DataGovernanceSecurityStateModel): TileData {
    return state?.vulnerabilityAssessments
  }

  @Selector([DataGovernanceSecurityState])
  static connectionPolicies(state:DataGovernanceSecurityStateModel): TileData {
    return state?.connectionPolicies
  }

  @Selector([DataGovernanceSecurityState])
  static currentMaintenanceWindowOptions(state:DataGovernanceSecurityStateModel): TileData {
    return state?.currentMaintenanceWindowOptions
  }

  @Selector([DataGovernanceSecurityState])
  static geoBackupPolicies(state:DataGovernanceSecurityStateModel): TileData {
    return state?.geoBackupPolicies
  }

  @Selector([DataGovernanceSecurityState])
  static metadataSyncConfig(state:DataGovernanceSecurityStateModel): TileData {
    return state?.metadataSyncConfig
  }

  @Selector([DataGovernanceSecurityState])
  static replicationLinks(state:DataGovernanceSecurityStateModel): TileData {
    return state?.replicationLinks
  }

  @Selector([DataGovernanceSecurityState])
  static restorePoints(state:DataGovernanceSecurityStateModel): TileData {
    return state?.restorePoints
  }

  @Selector([DataGovernanceSecurityState])
  static securityAlertPoliciesSQLPOOL(state:DataGovernanceSecurityStateModel): TileData {
    return state?.securityAlertPoliciesSQLPOOL
  }

  @Selector([DataGovernanceSecurityState])
  static transparentDataEncryption(state:DataGovernanceSecurityStateModel): TileData {
    return state?.transparentDataEncryption
  }

  @Selector([DataGovernanceSecurityState])
  static vulnerabilityAssessmentScans(state:DataGovernanceSecurityStateModel): TileData {
    return state?.vulnerabilityAssessmentScans
  }

  @Selector([DataGovernanceSecurityState])
  static vulnerabilityAssessmentsSQLPOOL(state:DataGovernanceSecurityStateModel): TileData {
    return state?.vulnerabilityAssessmentsSQLPOOL
  }


  @Action(GetTilesDataSecurityAction)
  getTilesDataSecurity(ctx: StateContext<DataGovernanceSecurityStateModel>, action: GetTilesDataSecurityAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getTilesDataForDDC');
    return this.http.post<any[]>(url,action.payload)
    .pipe(
      tap((tileDatas:any[]) => {
        let results={};
        tileDatas.forEach(tileData=>{
          const findObj=action.payload.tileList.find(tile=>tileData[tile.tileName]);
          results={
            ...results,
            [findObj.tileName]:{
              value:tileData[findObj.tileName].value?tileData[findObj.tileName].value:tileData[findObj.tileName]?[tileData[findObj.tileName]]:[],
              refreshedTime:new Date()
            }           
          }
        })
        ctx.patchState({
          ...results
        })
      }));
  }
  
  @Action(GetWorkspaceAdministratorAction)
  getWorkspaceAdministrator(ctx: StateContext<DataGovernanceSecurityStateModel>, action: GetWorkspaceAdministratorAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getWorkspaceAdministrator');
    return this.http.post<any>(url,action.payload)
    .pipe(
      tap((tileData:any) => {
        ctx.patchState({
          workspaceAdministrator:{
            ...tileData
          }
        })
      }));
  }
  
  @Action(SortTilesDataSecurityAction)
  sortTilesDataSecurity(ctx: StateContext<DataGovernanceSecurityStateModel>, action: SortTilesDataSecurityAction) {
      let num = 0;
      (ctx.getState().datasort === 'desc') ? num = 1 : num = -1;
      let value = [...ctx.getState()[action.tileName].value];
      let fieldName=action.fieldName;
      value.sort((a, b) => {
        switch (fieldName) {
          case 'name': {
            let x = a.name ? a.name : ''
            let y = b.name ? b.name : ''
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          default: {
            let val1,val2;
            fieldName.split('.').forEach(dt=>{
              if(val1!=undefined){
                val1=val1[dt]
              }else {
                val1=a[dt]
              }
              if(val2!=undefined){
                val2=val2[dt]
              }else {
                val2=b[dt]
              }
            })
            let x = val1 ? val1 : ' ';
            let y = val2 ? val2 : ' ';
            return (x < y) ? num : ((x > y) ? -num : 0);
          }
        }
      })
      ctx.patchState({
        [action.tileName]:{
          ...ctx.getState()[action.tileName],
          value
        },
        datasort: ctx.getState().datasort === 'desc' ? 'asc' : 'desc'
      })
  }
}

