import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-active-recom',
  templateUrl: './active-recom.component.html',
  styleUrls: ['./active-recom.component.scss']
})
export class ActiveRecomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
