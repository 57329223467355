<div class="introduction">
  
 
    <p class="heading" id="0">Events  </p>
    <p class="para mb-0">Average of number of events that occurred in a given timeframe.   </p>
    <img style="width:50%" src="assets\user-manual\apps-health-monitoring\Tile(10).svg" >
    
    <figcaption class="caption col-md-6">Avg number of events</figcaption>

  
          
</div>