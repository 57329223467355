import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-vpn-gateway-actions',
  templateUrl: './vpn-gateway-actions.component.html',
  styleUrls: ['./vpn-gateway-actions.component.scss']
})
export class VpnGatewayActionsComponent implements OnInit {
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("vpnGatewayDetailsTemplate") vpnGatewayDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  localNetworkData: any;
  connectionList: any;
  vpnList: any;
  inputError={
    ipError:false,
    ipNonNullRange:false,
    ipUnique:false,
    adrcSpcError:false,
    adrcSpcNonNullRange:false,
    adrcSpcUnique:false,
    bgpError:false,
    bgpNonNullRange:false,
    bgpUnique:false,
    asnError:false,
    asnValidNum:false,
    asnValue:false,
    nameEmpty:false,
    nameExist:false,
    nameMaxLen:false,
    nameValid:false,
    nameLastValid:false,
    sharedKeyMaxLen:false,
    sharedKeyEmpty:false,
    sharedKeyASCII:false,
    defCustomAzureIP:false,
    secCustomAzureIP:false,
    primaryIPError:false,
    secIPError:false,
    sameIPError:false,
    validTenant:false,
    subnetNotValidCIDR:false,
    subnetAdrspc:false,
    exNotValidCIDR:false,
    exAdrspc:false,
    inNotValidCIDR:false,
    inAdrspc:false,
    uniqueNATname:false,
  }
  selectedType: any;
  selectedSideSheetRow: any;
  number: any;
  tempForm: FormGroup;
  asnNumber: number;
  connectionName: any;
  disable: boolean;
  saveDisable: boolean;
  vpnGatewayData: any;
  disableCondition: boolean;
  connectionType=[
    {id:'Vnet2Vnet',name:'VNet-to-VNet'},
    {id:'IPsec',name:'Site-to-site(IPsec)'},
  ]
  connectionAllList: any;
  ingressList: any;
  egressList: any;
  lngList: any;
  vpn1List: any;
  skuList:any;
  subSideSheetForm: FormGroup;
  tunnelTypeList=[
    {name:'OpenVPN (SSL)',id:'OpenVPN'},
    {name:'SSTP (SSL)',id:'SSTP'},
    {name:'IKEv2',id:'IkeV2'},
    {name:'IKEv2 and OpenVPN (SSL) ',id:'IkeV2 and OpenVPN'},
    {name:'IKEv2 and SSTP (SSL)',id:'IKEv2 and SSTP'},
  ]
  authenticationTypeList=[
    {name:'Azure certificate',id:'Certificate'},
    {name:'RADIUS authentication',id:'Radius'},
    {name:'Azure Active Directory',id:'AAD'},
  ]
  natModeList=[
    {name:'IngressSnat',id:'IngressSnat'},
    {name:'EgressSnat',id:'EgressSnat'},
  ]
  natIPConfigList=[
    {name:'Default',id:'Default'},
  ]
  natTypeList=[
    {name:'Static',id:'Static'},
    {name:'Dynamic',id:'Dynamic'},
  ]
  certificates: boolean;
  radiusAuth: boolean;
  azureDictionary: boolean;
  vpnGatewayAllConfig: any;
  publicIPList: any;
  publicIPAllList: any;
  skuToolTip: string;
  natRuleCondition: boolean;
  currentIndex: any;
  matMenuOpen: boolean;
  disableActiveMode: boolean;
  showDeleteButton: boolean;
  showDeleteBtn: boolean;
  natRuleConnection: any[];
  point2siteCondition: boolean;
  constructor(public util:UtilService, private titleCase:TitleCasePipe,private formBuilder: FormBuilder,private modalService: NgbModal,private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }

  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "configuration" : {
        this.closeModal()
        this.currentForm = this.util.getForm('vpnConfig')
        await this.getVPNGatewayData(this.selectedRow,'vpnData')
        await this.getPublicIPList()
        await this.getToolTip()
        await this.setDefaultValue('vpnConfig')
        this.saveDisable=false
        this.showSidesheet = true;
        this.showSubSidesheet=false
        break;
      } 
      case "connection" : {
        this.closeModal()
        // this.currentForm = this.util.getForm('vpnConnection')
        await this.getVPNGatewayData(this.selectedRow,'vpnData')
        await this.getConnectionList('VPN',this.selectedRow)
        await this.getConnectionData(this.selectedRow)
        // await this.getVPNList(this.selectedRow)
       this.showSidesheet = true;
       this.showSubSidesheet=false
        break;
      } 
      case "point-to-site" : {
        this.closeModal()
        this.currentForm = this.util.getForm('vpnPointToSite')
        await this.getVPNGatewayData(this.selectedRow,'vpnData')
        this.vpnGatewayData?.properties?.vpnClientConfiguration?await this.setDefaultValue('point2Site'):false
        this.showDeleteBtn=false
        this.showSidesheet = true;
        break;
      } 
      case "natRule" : {
        this.closeModal()
        this.currentForm = this.util.getForm('vpnNATRule')
        await this.getConnectionList('VPN',this.selectedRow)
        await this.setDefaultValue('natRule')
       this.saveDisable=false
        this.showSidesheet = true;
        break;
      }  
      case "deleteVPNGateway" : {
        this.closeModal()
        this.openModal(this.deleteConfirmPopup,this.selectedRow,'deleteVPNGateway')
        break;
      }  
    }
  }

  async deleteAction(){
    switch(this.selectedType){
      case 'deleteVPNGateway' :{
        this.deleteVPNGatewayData(this.selectedRow)
        break;
      }
      case 'deleteConnection' :{
        this.deleteConnectionData(this.selectedRow,this.selectedSideSheetRow.name)
        break;
      }
      case 'deletePoint2Site' :{
      delete this.vpnGatewayData?.properties?.vpnClientConfiguration
      this.updateVPNGatewayData(this.selectedRow,this.vpnGatewayData,'deletePoint2Site')
      this.closeModal()
        break;
      }
    }
  }
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedSideSheetRow=row;
    this.selectedType=type;
    type=='vpnGatewayPopUp'?this.getVPNGatewayData(row,'disableCondition'):false 
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.showSidesheet = false
    this.resetForm()
    this.resetValidation()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  getCurrentResourceTitle(){
    switch(this.selectedType){
      case'deleteConnection':return 'Remove Connection'
      case'deleteVPNGateway':return 'Delete Virtual Network Gateway'
      case'deletePoint2Site':return 'Delete Point-to-site Configuration'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteConnection':return 'remove '+`"${this.selectedSideSheetRow.name}"`
      case'deleteVPNGateway':return 'delete '+`"${this.selectedRow[0]}"`
      case'deletePoint2Site':return 'delete point-to-site configuration'
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getButtonName(){
    switch(this.selectedType){
      case'deleteConnection':return 'Remove'
      case'deleteVPNGateway':return 'Delete'
      case'deletePoint2Site':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }

  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  addCustomAzureIP(){
    this.customAzureIPList.push(this.util.getForm('addCustomAzureIP'))
  }
  addSecCustomAzureIP(){
    this.secCustomAzureIPList.push(this.util.getForm('addSecCustomAzureIP'))
  }
  addRootCertificates(){
    this.rootCertificatesList.push(this.util.getForm('addRootCertificates'))
  }
  addRevokedCertificates(){
    this.revokedCertificatesList.push(this.util.getForm('addRevokedCertificates'))
  }
  addVPNNATRule(){
    this.vpnNATRuleList.push(this.util.getForm('addNATRule'))
  }
  get vpnNATRuleList() {
    return this.currentForm.get('vpnNATRuleArray') as FormArray;
  }
  get customAzureIPList() {
    return this.currentForm.get('customAzureIPArray') as FormArray;
  }
  get secCustomAzureIPList() {
    return this.currentForm.get('secCustomAzureIPArray') as FormArray;
  }
  get rootCertificatesList() {
    return this.currentForm.get('rootCertificatesArray') as FormArray;
  }
  get revokedCertificatesList() {
    return this.currentForm.get('revokedCertificatesArray') as FormArray;
  }
  removeVPNNATRule(index,tanch) {
    this.saveDisable=true
    // tanch.reset()
    // this.inputError['uniqueNATname']=false
    this.vpnNATRuleList.controls.splice(index, 1);
  }
  removeCustomAzureIP(index) {
    this.saveDisable=true
    this.customAzureIPList.controls.splice(index, 1);
  }
  removeSecCustomAzureIP(index) {
    this.saveDisable=true
    this.secCustomAzureIPList.controls.splice(index, 1);
  }
  removeRootCertificates(index) {
    this.saveDisable=true
    this.rootCertificatesList.controls.splice(index, 1);
  }
  removeRevokedCertificates(index) {
    this.saveDisable=true
    this.revokedCertificatesList.controls.splice(index, 1);
  }

  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }

  getSuccesText(type){
    switch(type){
      case'vpnConfig':return 'Virtual network gateway configuration updated successfully!'
      case'addConnection':return `Connection added to “${this.selectedRow[0]}” successfully!`
      case'vpnPoint2Site': return  `Point-to-site added to “${this.selectedRow[0]}” successfully!`
      case 'vpnNATRule' :  return `“${this.selectedRow[0]}” NAT rules updated successfully!`
      case'deletePoint2Site':return 'Point-to-site congifuration deleted successfully!'
      default: return 'Changes saved successfully!'
    }
  }

  getFailureText(type){
    switch(type){
      case'vpnConfig':return 'Virtual network gateway configuration updating failed'
      case'addConnection':return `Failed to add connection to “${this.selectedRow[0]}”.`
      case'vpnPoint2Site':return `Failed to add point-to-site to “${this.selectedRow[0]}”`
      case 'vpnNATRule' :  return `Failed to update “${this.selectedRow[0]}” NAT rules `
      case'deletePoint2Site':return 'Point-to-site congifuration failed to be deleted.'
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'vpnConfig':return 'Updating virtual network gateway configuration...'
      case'addConnection':return `Adding connection to “${this.selectedRow[0]}”...`
      case'vpnPoint2Site':return`Adding point-to-site to “${this.selectedRow[0]}”...`
      case'vpnNATRule': return `Updating “${this.selectedRow[0]}” NAT rules...`
      case'deletePoint2Site':return 'Deleting point-to-site congifuration...'
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'vpnGateway':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
      case 'name':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = a?.name;
          let y = b?.name;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.connectionList=sorted;
        break;
      }
      case 'status':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = a?.properties?.connectionStatus ? a?.properties?.connectionStatus:'Unknown';
          let y = b?.properties?.connectionStatus ? b?.properties?.connectionStatus:'Unknown';
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.connectionList=sorted;
        break;
      }
      case 'connectionType':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = a?.properties?.connectionType;
          let y = b?.properties?.connectionType;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.connectionList=sorted;
        break;
      }
      case 'VPN':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x =a?.properties?.connectionType=='Vnet2Vnet'? a?.properties?.virtualNetworkGateway1?.id.split('/')[8]:a?.properties?.localNetworkGateway2?.id.split('/')[8];
          let y = b?.properties?.connectionType=='Vnet2Vnet'? b?.properties?.virtualNetworkGateway1?.id.split('/')[8]:b?.properties?.localNetworkGateway2?.id.split('/')[8];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.connectionList=sorted;
        break;
      }
    }
  }
  invalidForm(type){
    switch(type){
      case'addConnection':{
       return this.inputError?.nameEmpty||this.inputError?.nameMaxLen||this.inputError?.nameExist||this.inputError?.nameValid||this.inputError?.nameLastValid
      }
      case'createPublicIP':{
        return this.inputError?.nameEmpty||this.inputError?.nameMaxLen||this.inputError?.nameExist||this.inputError?.nameValid||this.inputError?.nameLastValid
       }
       case'vpnConfiguration':{
        return this.inputError?.secCustomAzureIP||this.inputError?.defCustomAzureIP||this.inputError?.asnError||this.inputError?.asnValidNum||this.inputError?.asnValue
       }
       case'point2Site':{
        return this.inputError?.validTenant||this.inputError?.primaryIPError||this.inputError?.secIPError||this.inputError?.sameIPError||this.inputError?.nameEmpty||this.inputError?.subnetAdrspc||this.inputError?.subnetNotValidCIDR ||(this.currentForm.get('secServerIP').value && !this.currentForm.get('secServerSecret').value)
       }
       case'natRule':{
        return this.inputError?.exAdrspc||this.inputError?.exNotValidCIDR||this.inputError?.inAdrspc||this.inputError?.inNotValidCIDR
       }
    }
  }
  resetForm(){
    this.currentForm.reset()
  }
  resetValidation(){
    this.inputError={
      ipError:false,
      ipNonNullRange:false,
      ipUnique:false,
      adrcSpcError:false,
      adrcSpcNonNullRange:false,
      adrcSpcUnique:false,
      bgpError:false,
      bgpNonNullRange:false,
      bgpUnique:false,
      asnError:false,
      asnValidNum:false,
      asnValue:false,
      nameEmpty:false,
      nameExist:false,
      nameMaxLen:false,
      nameValid:false,
      nameLastValid:false,
      sharedKeyMaxLen:false,
      sharedKeyEmpty:false,
      sharedKeyASCII:false,
      defCustomAzureIP:false,
      secCustomAzureIP:false,
      primaryIPError:false,
      secIPError:false,
      sameIPError:false,
      validTenant:false,
      subnetNotValidCIDR:false,
      subnetAdrspc:false,
      exNotValidCIDR:false,
      exAdrspc:false,
      inNotValidCIDR:false,
      inAdrspc:false,
      uniqueNATname:false,
    }
    this.certificates=false;this.azureDictionary=false;this.radiusAuth=false
  }

 
  setRadioBtn(id,type){
  switch(type){
    case 'protocol':{
      this.saveDisable=true
      let value = id == '1' ? true : false
      this.currentForm.get('IKEProtocol').setValue(value)
      break;}
    case 'active-mode':{
      this.saveDisable=true
      let value = id == 'yes' ? true : false
      this.currentForm.get('activeMode').setValue(value)
      this.currentForm.get('publicIp2').setValue(null)
      this.currentForm.get('publicIp3').setValue(null)
      break;}
    case 'gatewayIP':{
      this.saveDisable=true
      let value = id == 'yes' ? true : false
      this.currentForm.get('gatewayIP').setValue(value)
      break;}
    case 'sku':{
      this.saveDisable=true
      let value = id == 'basic' ? 'Basic':'Standard'
      this.subSideSheetForm.get('IPsku').setValue(value)
      this.subSideSheetForm.get('routing').setValue('Internet')
      break;}
    case 'routing':{
      this.saveDisable=true
      let value = id == 'internet' ? 'Internet':'MicrosoftNetwork'
      this.subSideSheetForm.get('routing').setValue(value)
      break;}
    case 'routeTranslation':{
      this.saveDisable=true
      let value = id == 'enable' ? true:false
      this.currentForm.get('routeTranslation').setValue(value)
      break;}
   }
  }

  async setDefaultValue(type){
  switch(type){
    case'vpnConfig':{
      this.currentForm.get('sku').setValue(this.vpnGatewayData?.properties?.sku?.name)
      this.currentForm.get('activeMode').setValue(this.vpnGatewayData?.properties?.activeActive)
      if(this.vpnGatewayData?.properties?.activeActive&&this.vpnGatewayData?.properties?.ipConfigurations.length==2){
        let publicIpId=this.vpnGatewayData?.properties?.ipConfigurations[1]?.properties?.publicIPAddress?.id
        this.publicIPList.push({name:publicIpId.split('/')[8],id:publicIpId})
        this.currentForm.get('publicIp2').setValue(publicIpId)
      }
      this.currentForm.get('gatewayIP').setValue(this.vpnGatewayData?.properties?.enablePrivateIpAddress)
      this.currentForm.get('configBGP').setValue(this.vpnGatewayData?.properties?.enableBgp)
      if(this.vpnGatewayData?.properties?.vpnType=='RouteBased'){
        this.currentForm.get('asn').setValue(this.vpnGatewayData?.properties?.bgpSettings?.asn)
        let customAzureIPArray  = this.vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses[0]?.customBgpIpAddresses
        customAzureIPArray.forEach(e=>{
          this.customAzureIPList.insert(0,this.util.getForm('addCustomAzureIP',{value:e}))
        });
        if(this.vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses.length>1){
          let secCustomAzureIPArray  = this.vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses[1]?.customBgpIpAddresses
          secCustomAzureIPArray.forEach(e=>{
            this.secCustomAzureIPList.insert(0,this.util.getForm('addSecCustomAzureIP',{value:e}))
          });
        }
        // if(this.vpnGatewayData?.properties?.sku?.name=='Basic'){
        //   $('#enableActive').prop('disabled',true);
        //   $('#disableActive').prop('disabled',true);
        //   $('#configBGP').prop('disabled',true);
        // }else if(this.vpnGatewayData?.properties?.sku?.name=='Standard'){
        //   $('#enableActive').prop('disabled',true);
        //   $('#disableActive').prop('disabled',true);
        //   $('#configBGP').prop('disabled',false);
        // }
        // else if(this.vpnGatewayData?.properties?.sku?.name=='HighPerformance'){
        //   $('#enableActive').prop('disabled',false);
        //   $('#disableActive').prop('disabled',false);
        //   $('#configBGP').prop('disabled',false);
        // }
      }
      break;
    }
    case'addConnection':{
      this.currentForm = this.util.getForm('vpnConnection')
      await this.getVPNList(this.selectedRow)
      await this.getLNGList(this.selectedRow)
      this.currentForm.get('location').setValue(this.getRegionName(this.selectedRow[3]))
      this.currentForm.get('rg').setValue(this.selectedRow[1])
      this.currentForm.get('sub').setValue(this.getProjectName(this.selectedRow[2]))
      this.currentForm.get('connectionType').setValue('Vnet2Vnet')
      this.vpn1List=this.vpnList.filter(e=>e.name==this.selectedRow[0])
      this.vpnList=this.vpnList.filter(e=>e.name!=this.selectedRow[0])
      this.currentForm.get('vpnName').setValue( this.vpn1List[0].id)
      this.currentForm.get('IKEProtocol').setValue(false)
      this.showSubSidesheet = true;
      break;
    }
    case 'point2Site':{
      this.showDeleteButton= this.vpnGatewayData?.properties?.vpnClientConfiguration?true:false
      this.currentForm.get('addressPool').setValue(this.vpnGatewayData?.properties?.vpnClientConfiguration?.vpnClientAddressPool?.addressPrefixes[0])
      this.currentForm.get('tunnelType').setValue(this.vpnGatewayData?.properties?.vpnClientConfiguration?.vpnClientProtocols[0])
      this.currentForm.get('authenticationType').setValue(this.vpnGatewayData?.properties?.vpnClientConfiguration?.vpnAuthenticationTypes)
      await this.getAuthenticationType()
      this.currentForm.get('primaryServerIP').setValue(this.vpnGatewayData?.properties?.vpnClientConfiguration?.radiusServerAddress)
      this.currentForm.get('primaryServerSecret').setValue(this.vpnGatewayData?.properties?.vpnClientConfiguration?.radiusServerSecret)
      this.currentForm.get('tenant').setValue(this.vpnGatewayData?.properties?.vpnClientConfiguration?.aadTenant)
      this.currentForm.get('audience').setValue(this.vpnGatewayData?.properties?.vpnClientConfiguratio?.aadAudience)
      this.currentForm.get('issuer').setValue(this.vpnGatewayData?.properties?.vpnClientConfiguration?.aadIssuer)
      let rootCertificatesArray  = this.vpnGatewayData?.properties?.vpnClientConfiguration?.vpnClientRootCertificates
      if(rootCertificatesArray.length){
        rootCertificatesArray.forEach(e=>{
          this.rootCertificatesList.insert(0,this.util.getForm('addRootCertificates',{
            name:e?.name,
            value:e?.properties?.publicCertData,
          }))
        });
      }
      let revokedCertificatesArray  = this.vpnGatewayData?.properties?.vpnClientConfiguration?.vpnClientRevokedCertificates
      if(revokedCertificatesArray.length){
          revokedCertificatesArray.forEach(e=>{
           this.revokedCertificatesList.insert(0,this.util.getForm('addRevokedCertificates',{
          name:e.name,
          value:e?.properties?.thumbprint,
        }))
      });
      }
      break;
    }
    case'natRule':{
      this.currentForm.get('routeTranslation').setValue(this.vpnGatewayData?.properties?.enableBgpRouteTranslationForNat)
      let natRuleArray  = this.vpnGatewayData?.properties?.natRules
      natRuleArray.forEach(e=>{
        let internalArr=e?.properties?.internalMappings.map(x=>{return x.addressSpace})
        let externalArr=e?.properties?.externalMappings.map(x=>{return x.addressSpace})
        this.vpnNATRuleList.insert(0,this.util.getForm('addNATRule',{
          name:e.name,
          type:e?.properties?.type,
          IPConfig:e?.properties?.ipConfigurationId,
          mode:e?.properties?.mode,
          internalMapping:internalArr.join(', '),
          externalMapping:externalArr.join(', '),
        }))
      });
     // this.showSubSidesheet = true;
      break;
    }
  }
  }
  getAuthenticationType(){
     let type=this.currentForm.get('authenticationType')?.value
     this.certificates=false
     this.radiusAuth=false
     this.azureDictionary=false
     type.forEach(e=>{
      !this.certificates? this.certificates=e=='Certificate'?true:false  :false  
      !this.radiusAuth?this.radiusAuth=e=='Radius'?true:false  :false  
      !this.azureDictionary?this.azureDictionary=e=='AAD'?true:false :false  
     })
    //  if(this.radiusAuth){
    //   this.currentForm.get('primaryServerIP').setValidators([Validators.required])
    //   this.currentForm.get('primaryServerSecret').setValidators([Validators.required])
    //   this.currentForm.get('secServerIP').value?this.currentForm.get('secServerSecret').setValidators([Validators.required]):this.currentForm.get('secServerSecret').setValidators(null)
    //  }else if(!this.radiusAuth){
    //   this.currentForm.get('primaryServerIP').setValidators(null)
    //   this.currentForm.get('primaryServerSecret').setValidators(null)
    //   this.currentForm.get('secServerSecret').setValidators(null)
    //  }
    //  if(this.azureDictionary){
    //   this.currentForm.get('tenant').setValidators([Validators.required])
    //   this.currentForm.get('audience').setValidators([Validators.required])
    //   this.currentForm.get('issuer').setValidators([Validators.required])
    //  }else if(!this.azureDictionary){
    //   this.currentForm.get('tenant').setValidators(null)
    //   this.currentForm.get('audience').setValidators(null)
    //   this.currentForm.get('issuer').setValidators(null)
    //  }
    //  this.currentForm.get('primaryServerIP').updateValueAndValidity()
    //  this.currentForm.get('primaryServerSecret').updateValueAndValidity()
    //  this.currentForm.get('secServerIP').updateValueAndValidity()
    //  this.currentForm.get('tenant').updateValueAndValidity()
    //  this.currentForm.get('audience').updateValueAndValidity()
    //  this.currentForm.get('issuer').updateValueAndValidity()
  }
  getpublicIPConfig(event){
   // this.publicIPList=this.publicIPList.filter(e=>e.id!=this.currentForm.get('publicIp2').value||e.id!=this.currentForm.get('publicIp3').value||e.id!=event.id)
  }
  conditionForActiveMode(event){
    let val= event.id
    if(val=='Standard' && this.vpnGatewayData?.properties?.vpnType=='RouteBased'){
     this.currentForm.get('activeMode').setValue(false)
     this.currentForm.get('configBGP').setValue(this.vpnGatewayData?.properties?.enableBgp)
      $('#enableActive').prop('disabled',true);
      $('#disableActive').prop('disabled',true);
      $('#configBGP').prop('disabled',false);
      // $('#disable').addClass('disabled');
    }
    else if(val=='HighPerformance' && this.vpnGatewayData?.properties?.vpnType=='RouteBased'){
      this.currentForm.get('activeMode').setValue(this.vpnGatewayData?.properties?.activeActive)
      this.vpnGatewayData?.properties?.activeActive?this.currentForm.get('publicIp2').setValue(this.vpnGatewayData?.properties?.ipConfigurations[1]?.properties?.publicIPAddress?.id):this.currentForm.get('publicIp2').setValue(null)
      this.currentForm.get('configBGP').setValue(this.vpnGatewayData?.properties?.enableBgp)
      $('#enableActive').prop('disabled',false);
      $('#disableActive').prop('disabled',false);
      $('#configBGP').prop('disabled',false);
    }else if(val=='Basic' && this.vpnGatewayData?.properties?.vpnType=='RouteBased'){
      this.currentForm.get('activeMode').setValue(false)
      this.currentForm.get('configBGP').setValue(false)
      $('#enableActive').prop('disabled',true);
      $('#disableActive').prop('disabled',true);
      $('#configBGP').prop('disabled',true);
    }
  }
  async saveVPNGatewayData(type){
    switch(type){
      case'vpnConfiguration':{
        let rawValue = this.currentForm.getRawValue()
        // let rawValue1=this.subSideSheetForm.getRawValue()
        let body=this.vpnGatewayData
        let customAzureIPArray  = []
        rawValue.customAzureIPArray.forEach(e=>{
          if(e.value&&e.value!=='') return customAzureIPArray.push(e.value)
        })
        let secCustomAzureIPArray  = []
        rawValue.secCustomAzureIPArray.forEach(e=>{
          if(e.value&&e.value!=='') return secCustomAzureIPArray.push(e.value)
        })
        let publicIPName=rawValue.publicIp2? rawValue.publicIp2.split('/')[8]:false
        body['properties'].sku.name=rawValue.sku
        body['properties'].sku.tier=rawValue.sku
        body['properties'].activeActive=rawValue.activeMode
        body['properties'].enablePrivateIpAddress=rawValue.gatewayIP
        body['properties'].enableBgp=rawValue.configBGP
        if(body['properties'].vpnType=='RouteBased'){
          body['properties'].bgpSettings.asn=rawValue.asn
          body['properties'].bgpSettings.bgpPeeringAddresses[0].customBgpIpAddresses=customAzureIPArray
          let defBGP=body['properties'].bgpSettings.bgpPeeringAddresses[0].defaultBgpIpAddresses
          let tunnelIP=body['properties'].bgpSettings.bgpPeeringAddresses[0].tunnelIpAddresses
          if(rawValue.activeMode){   
              let secPublicIP={
                "name":'ActiveActive',  //publicIPName,
                "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/virtualNetworkGateways/${this.selectedRow[0]}/ipConfigurations/ActiveActive`,
                "etag": "W/\"1badf396-27ee-4e89-a416-bb22732abba6\"", 
                "type": "Microsoft.Network/virtualNetworkGateways/ipConfigurations", 
                "properties": { 
                    "privateIPAllocationMethod":  body['properties'].ipConfigurations[0]. properties.privateIPAllocationMethod, 
                    "publicIPAddress": { 
                        "id":rawValue.publicIp2 ,
                        // "sku": {
                        //   "name": "Basic"
                        //   },
                        //   "zones": [
                        //       "1","2","3"
                        //   ]
                    }, 
                    "subnet": body['properties'].ipConfigurations[0].properties.subnet
                } 
              }
              body['properties'].ipConfigurations.splice(1,2)
              body['properties'].ipConfigurations.push(secPublicIP)
              if(rawValue.activeMode&&body['properties'].vpnClientConfiguration  && rawValue.sku.includes('VpnGw')){// && (rawValue.sku=='VpnGw2AZ'||rawValue.sku=='VpnGw3AZ' || rawValue.sku=='VpnGw4AZ'||rawValue.sku=='VpnGw5AZ')){
                let thirdPublicIp={
                   "name": "PointToSiteIpConfig",
                   "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/virtualNetworkGateways/${this.selectedRow[0]}/ipConfigurations/PointToSiteIpConfig-1650472618388`,
                   "properties": {
                       "privateIPAllocationMethod": body['properties'].ipConfigurations[0]. properties.privateIPAllocationMethod,
                       "publicIPAddress": {
                           "id":rawValue.sku!='VpnGw3AZ'&&rawValue.sku!='VpnGw2AZ'? rawValue.publicIp2:rawValue.publicIp3,
                          //  "sku": {
                          //   "name": "Basic"
                          //   },
                          //   "zones": [
                          //     "1","2","3"
                          //   ]
                       },
                       "subnet":  body['properties'].ipConfigurations[0].properties.subnet
                     }
                   }
                   body['properties'].ipConfigurations.splice(2,1)
                   body['properties'].ipConfigurations.push(thirdPublicIp)
                 }else body['properties'].ipConfigurations.splice(2,1)
            }else  body['properties'].ipConfigurations.splice(1,2)
          if(rawValue.activeMode||rawValue.configBGP){
            body['properties'].bgpSettings.bgpPeeringAddresses.splice(1,1)
            body['properties'].bgpSettings.bgpPeeringAddresses.push({
              "ipconfigurationId":`/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/virtualNetworkGateways/${this.selectedRow[0]}/ipConfigurations/ActiveActive`, 
              "defaultBgpIpAddresses": defBGP, 
              "customBgpIpAddresses":rawValue.configBGP?secCustomAzureIPArray:customAzureIPArray, 
              "tunnelIpAddresses": tunnelIP
            })
          }else body['properties'].bgpSettings.bgpPeeringAddresses.splice(1,1)
        }
        await this.updateVPNGatewayData(this.selectedRow,body,'vpnConfig')
        await this.getVPNGatewayData(this.selectedRow,'vpnData')
        break;

      }
      case'natRule':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.vpnGatewayData
        let natRuleArray  = []
        rawValue.vpnNATRuleArray.forEach(e=>{
          let internalAdrsc =[]
          let tempArr= e.internalMapping.split(',')
          tempArr.forEach(x=>internalAdrsc.push({addressSpace:x}))
          let externalAdrsc =[]
          let tempArr1=e.externalMapping.split(',')
          tempArr1.forEach(x=>externalAdrsc.push({addressSpace:x}))
          if(e.name&&e.name!=='') return natRuleArray.push({
            "name": e.name,   //name of nat rtule 
            "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/virtualNetworkGateways/${this.selectedRow[0]}/natRules/${e.name}`, 
            "etag": "W/\"05d96705-1221-4070-a038-25e746598078\"", 
            "properties": { 
                "type": e.type,  // type can be static or dynamic 
                "mode": e.mode, 
                "internalMappings": internalAdrsc, 
                "externalMappings": externalAdrsc, 
                "ipConfigurationId": e.IPConfig || ''
            },
            "type": "Microsoft.Network/virtualNetworkGateways/natRules" 
          })
        })
        body['properties'].enableBgpRouteTranslationForNat=rawValue.routeTranslation
        body['properties'].natRules=natRuleArray
        await this.updateVPNGatewayData(this.selectedRow,body,'vpnNATRule')
        await this.getVPNGatewayData(this.selectedRow,'vpnData')
        break;  
      }
      case'point2site':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.vpnGatewayData
        let rootCertificatesArray  = []
        let revokedCertificatesArray  = []
        if(this.certificates || body?.properties?.sku?.name=='Basic'){
          rawValue.rootCertificatesArray.forEach(e=>{
            if(e.value&&e.value!==''&&e.name&&e.name!=='') return rootCertificatesArray.push({
              "name":e.name,
              "id":`/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/virtualNetworkGateways/${this.selectedRow[0]}/vpnClientRootCertificates/${e.name}`,
              "etag": "W/\"be797518-f5bf-435f-b219-5066ebb2f126\"",
              "properties": { 
                "provisioningState": "Succeeded", 
                "publicCertData": e.value
             }, 
            "type": "Microsoft.Network/virtualNetworkGateways/vpnClientRootCertificates" 
            })
          })
          rawValue.revokedCertificatesArray.forEach(e=>{
          if(e.value&&e.value!==''&&e.name&&e.name!=='') return revokedCertificatesArray.push({
            "name":e.name,
            "properties": { 
              "thumbprint": e.value
             }, 
           })
         })
        }
        if(!this.azureDictionary){
          rawValue.tenant=null;rawValue.issuer=null;rawValue.audience=null
        }
        if(!this.radiusAuth){
          rawValue.primaryServerIP=null;rawValue.primaryServerSecret=null
        }
        if(body?.properties?.sku?.name=='Basic'){
          rawValue.tunnelType ='SSTP'
          rawValue.authenticationType =['Certificate']
        }
        body={
                      "vpnClientAddressPool": { 
                          "addressPrefixes": [ 
                            rawValue.addressPool           //address pool  ip 
                          ] 
                      }, 
                  "vpnClientProtocols": [ 
                    rawValue.tunnelType    		//tunnel type 
                  ], 
                  "vpnAuthenticationTypes": rawValue.authenticationType  ,			//authentication types 
                  "vpnClientRootCertificates": rootCertificatesArray, 
                  "vpnClientRevokedCertificates": revokedCertificatesArray, 
                  "vpnClientConnectionHealth": { 
                  "vpnClientConnectionsCount": 0, 
                  "totalIngressBytesTransferred": 0, 
                  "totalEgressBytesTransferred": 0 
                  }, 
                  "radiusServerAddress":   rawValue.primaryServerIP  || ''	, 
                  "radiusServerSecret":   rawValue.primaryServerSecret  || ''	, 
                  "radiusServers": [], 
                  "vpnClientIpsecPolicies": [] ,

                  "aadTenant": rawValue.tenant  || '',//"https://login.microsoftonline.com/9be10acf-2a10-42a0-9748-00a93cd07042/", 

                  "aadAudience": rawValue.audience || '' ,//"41b23e61-6c1e-4545-b367-cd054e0ed4b4", 
      
                  "aadIssuer": rawValue.issuer || ''  ,//"https://sts.windows.net/9be10acf-2a10-42a0-9748-00a93cd07042/" 
            }, 
        
        this.vpnGatewayData['properties'].vpnClientConfiguration=body
        await this.updateVPNGatewayData(this.selectedRow,this.vpnGatewayData,'vpnPoint2Site')
        // await this.getVPNGatewayData(this.selectedRow,'vpnData')
        break;  
      }
      case 'addConnection':{
        let rawValue = this.currentForm.getRawValue()
          let body= {"name": rawValue.name, 
              "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/connections/${rawValue.name}`, 
              "type": "Microsoft.Network/connections", 
              "location": this.selectedRow[3], 
              "properties": { 
                  "packetCaptureDiagnosticState": "None", 
                  "virtualNetworkGateway1": { 
                      "id": rawValue.vpnName
                  }, 
                  "connectionType": rawValue.connectionType, 
                  "connectionProtocol":rawValue.IKEProtocol?'IKEv1':'IKEv2' , 
                  "routingWeight": 0, 
                  "sharedKey":rawValue.sharedKey , 
                  "enableBgp":rawValue.BGP?true:false,  //if user clicks on enable BGP make this as true else false. 
                  "useLocalAzureIpAddress": rawValue.ipAddress?true:false, //if user want to enable private IP to make this as true else false. 
                  "usePolicyBasedTrafficSelectors": false, 
                  "ipsecPolicies": [], 
                  "trafficSelectorPolicies": [], 
                  "connectionStatus": "NotConnected", 
                  "ingressBytesTransferred": 0, 
                  "egressBytesTransferred": 0, 
                  "expressRouteGatewayBypass": false, 
                  "dpdTimeoutSeconds": 0, 
                  "connectionMode": "Default" 
              } 
          } 
          if(rawValue.connectionType=='IPsec'){
            body.properties['localNetworkGateway2']={id:rawValue.localNetName}
          }else{
            body.properties['virtualNetworkGateway2']={id:rawValue.vpnName2}
          }
          if(rawValue.ingressNAT){
            let ingress=rawValue.ingressNAT.map(e=>{
              return {id:e}
            })
            body.properties['ingressNatRules']=rawValue.ingressNAT?ingress:[]
          }
          if(rawValue.egressNAT){
            let egress=rawValue.egressNAT.map(e=>{
              return {id:e}
            })
            body.properties['egressNatRules']=rawValue.egressNAT?egress:[]
          }
          await this.updateConnectionData(this.selectedRow,body,'addConnection')
        
          break;
          }
   
    }
  
  }
  getToolTip(){
    if(this.vpnGatewayData?.properties?.gatewayType=='ExpressRoute'){
      this.skuList=[{id:'Standard',name:'Standard'},{id:'HighPerformance',name:'High performance'},{id:'UltraPerformance',name:'Ultra performance'},
    ]
      this.skuToolTip='ExpressRoute gateway types are offered in three SKUs: Standard, High performance, and Ultra performance.'
    }
    else if(this.vpnGatewayData?.properties?.vpnType=='PolicyBased'){
      this.skuList=[{id:'Basic',name:'Basic'},]
      this.currentForm.get('sku').setValue('Basic')
      this.currentForm.get('sku').disable()
      this.skuToolTip='Policy-based VPN gateway types are offered only in the Basic SKU.'
    }
    else if(this.vpnGatewayData?.properties?.vpnType=='RouteBased'){
      this.skuList=[{id:'Basic',name:'Basic',key:1},{id:'Standard',name:'Standard',key:2},{id:'HighPerformance',name:'High performance',key:3},
      {id:'VpnGw1',name:'VpnGw1',key:4},{id:'VpnGw2',name:'VpnGw2',key:5},{id:'VpnGw3',name:'VpnGw3',key:6},{id:'VpnGw4',name:'VpnGw4',key:7},{id:'VpnGw5',name:'VpnGw5',key:8},
      {id:'VpnGw1AZ',name:'VpnGw1AZ',key:9},{id:'VpnGw2AZ',name:'VpnGw2AZ',key:10},{id:'VpnGw3AZ',name:'VpnGw3AZ',key:11},{id:'VpnGw4AZ',name:'VpnGw4AZ',key:12},{id:'VpnGw5AZ',name:'VpnGw5AZ',key:13},
    ]
     let temp=this.vpnGatewayData?.properties?.sku?.name
     let tempArr=this.skuList.filter(x=>x.id==temp)
     if(tempArr[0].key==9){
       this.skuList=this.skuList.filter(x=>x.key>=tempArr[0].key)
     }else if(tempArr[0].key>9){
      this.skuList=this.skuList.filter(x=>x.key>9)
    }else if(tempArr[0].key<=6&&tempArr[0].key>=4){
      this.skuList=this.skuList.filter(x=>x.key<=6&&x.key>=4)
     }else if(tempArr[0].key==7||tempArr[0].key==8){
      this.skuList=this.skuList.filter(x=>x.key<9&&x.key>4)
     }else{
      this.skuList=[{id:'Basic',name:'Basic'},{id:'Standard',name:'Standard'},{id:'HighPerformance',name:'High performance'}]
     }
     this.skuToolTip='If the current SKU is Basic, Standard, or High performance, it can be upgraded or downgraded to a Basic, Standard, or High performance SKU. If the current SKU is VpnGw1, VpnGw2, or VpnGw3, it can be upgraded or downgraded to VpnGw1, VpnGw2, or VpnGw3. If the gateway is being created to coexist with an ExpressRoute gateway, choose any SKU except for Basic.'
    }

  }
  getPeerName(event){
    if(event?.connectionType=='Vnet2Vnet'){
      let vnetName=event?.virtualNetworkGateway2.id.split('/')[8]
     vnetName= vnetName==this.selectedRow[0]?event?.virtualNetworkGateway1.id.split('/')[8]:event?.virtualNetworkGateway2.id.split('/')[8]
      return vnetName
    } else if(event?.connectionType=='IPsec'){
      let localNetName=event?.localNetworkGateway2.id.split('/')[8]
      return localNetName
    }
  }
  getConnectionCount(ruleName){
    let tempArr=[],tempArr1=[],tempArr2=[]
    this.connectionList.forEach(e=>{
      if(e?.properties?.egressNatRules){
        e?.properties?.egressNatRules.map(x=>{
          return tempArr1.push(x.id)
        })
      }
      return tempArr1
    })
      this.connectionList.forEach(e=>{
      if(e?.properties?.ingressNatRules){
        e?.properties?.ingressNatRules.map(x=>{
          return tempArr2.push(x.id)
        })
      }
      return tempArr2
     })
    tempArr=[...tempArr1,...tempArr2]
    this.natRuleConnection=tempArr.filter(e=>e.split('/')[10]==ruleName)
    let count=this.natRuleConnection?.length
    return count
}
//Validation
validIPName(event,limitNum,subType?:any){
  let value =event.target.value;
  this.connectionName=value
  this.inputError['nameExist'] = false;
  // this.inputError[flag+'MaxLen'] = false;
 if(value==''){
  this.inputError['nameEmpty'] = true;
 }else{ this.inputError['nameEmpty'] = false;}
    if (value.length > limitNum) {
      this.inputError['nameMaxLen'] = true;
      return;
    }
    else {
      this.inputError['nameMaxLen'] = false;
    }
  if(value.length==1){
    if(/^[A-Za-z0-9]*$/.test(value)){
      this.inputError['nameValid']=false;
    }
    else{
      this.inputError['nameValid']=true;
    }
  }
  // else this.inputError[flag+'Valid']=false;
  if(/^[A-Za-z0-9_]+$/.test(value[value.length-1]) && !this.inputError['nameValid']){
    this.inputError['nameLastValid']=false;
  }
  else{
    this.inputError['nameLastValid']=true;
  }
  switch(subType){
    case 'connectionName':{
      this.connectionAllList.forEach(e=>{
        if(e.name==value){
          this.inputError['nameExist'] = true;
          return;
        }
      })
    }
    case 'publicIP':{
      this.publicIPAllList.forEach(e=>{
        if(e.name==value&&e.location==this.selectedRow[3]){
          this.inputError['nameExist'] = true;
          return;
        }
      })
    }
  }
}

sharedKeyValidation(event){
  let value=event.target.value
  if(value==''){
    this.inputError.sharedKeyEmpty=true
  }else{
    this.inputError.sharedKeyEmpty=false
    if(0<value.length&&value.length>128){
      this.inputError.sharedKeyMaxLen=true
    }else{
      this.inputError.sharedKeyMaxLen=false
      if(/^[-~]+$/.test(value[value.length-1])){
        this.inputError.sharedKeyASCII=true;
      }
      else{
        this.inputError.sharedKeyASCII=false;
      }
    }
  }
}

asnValidation(event){
  let value=event.target.value;
  this.asnNumber=Number(value)
  this.inputError.asnValue=false
  this.inputError.asnError=false
  this.inputError.asnValidNum=false
  if(value=='') {this.inputError.asnValue=true, 
                  this.inputError.asnError=false
                  this.inputError.asnValidNum=false}
  else{this.inputError.asnValue=false
  if(!(/^[0-9]*$/.test(value))){this.inputError.asnValidNum=true
  }
  else{this.inputError.asnValidNum=false
    this.inputError.asnError=value=='0'||value=='23456'?true:false
    if(!this.inputError.asnError){
      if( this.asnNumber>64495 && this.asnNumber<131072){
        this.inputError.asnError=true}
        else this.inputError.asnError=false}
        // this.inputError.asnError=(value>'23455'&&value<'131072')?true:false}
    if(!this.inputError.asnError){
      if( this.asnNumber>4199999999 && this.asnNumber<4294967296){
        this.inputError.asnError=true}
        else this.inputError.asnError=false}
  }
} 
 
}
CheckIPAddress(event,flag,index?:any){ 
  // this.warningMessage=true
    let value=event.target.value;
    this.number=value
    this.inputError[flag+'Error']=false;//sourceInvalidArgument

    if(value&&value!=""){
    const splitArComma=value.split(',');
    let ipV4Value;
    let tempArr=[];
    for(let i=0;i<splitArComma.length;i++){
      if(!splitArComma[i]){
       // let inputError="Invalid argument: 'A non-null address range is required.'";
        this.inputError[flag+'Error']=true;
        break;
      }else{
        const splitArr=splitArComma[i].split('/');
        if(splitArr.length>1&&!splitArr[1]){
          //Maliform
          this.inputError[flag+'Error']=true;
        }else{
          ipV4Value=splitArr[0];
        }
        
        try{
          let ipV4Obj=new this.ipV4(ipV4Value);
         // item.VNetAdrspc=false;
          let vlIPRange;
          if(splitArr.length>1){
          try{
            vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
            //item.VNetAdrspc=false;
            if(vlIPRange.input!=splitArComma[i]){
              //item.notValidCIDR=true;
              //item.validCIDRValue=vlIPRange.input;
              this.inputError[flag+'Error']=true;
              break;
            }
          }catch(e){
            //item.VNetAdrspc=true;
            this.inputError[flag+'Error']=true;
            break;
          }
        }        
        }catch(e){
       //   item.VNetAdrspc=true;
       this.inputError[flag+'Error']=true;
          break;
        }
      }
    }
    if(!value.includes('/') && flag=='adrcSpc'){
      this.inputError[flag+'Error']=true;
    }
  }
  
  if(value&&value!=""){
  const splitArComma=value.split(',');
  let ipV4Value;
  let tempArr=[];
  for(let i=0;i<splitArComma.length;i++){
    if(!splitArComma[i]){
     // let inputError="Invalid argument: 'A non-null address range is required.'";
      this.inputError[flag+'NonNullRange']=true;
      break;
    }
  }
}
// if(index!=''){
//   if(!this.addressSpaceList.controls.length) return;
//   let data = this.addressSpaceList.controls.some((e,i)=> {
//     if(index !== i && e.get('value').value == value && e.get('value').value != '' ){
//       this.inputError[flag+'Unique']=true
//     }
//     else if(index !== i &&e.get('value').value != ''){
//       this.inputError[flag+'Unique']=false
//     }
//     return index !== i && e.get('value').value == this.addressSpaceList.at(index).get('value').value
//   })
// }
// else{
//   this.getIPAddressValidation(value)
// }
}
azureBGPValidation(event,flag){
  let value=event.target.value;
  this.saveDisable=true
  let split =value.split('.')
  if(value){
  if(split.length==4 && split[3]!=''){
        if(split[0]==169 && split[1]==254 && (split[2]==21||split[2]==22) && split[3]>=0 &&split[3]<256){
          this.inputError[flag+'CustomAzureIP']=false
        }else this.inputError[flag+'CustomAzureIP']=true
  }else   this.inputError[flag+'CustomAzureIP']=true
}
}
CheckDnsServer(event,index,type?:any){
    let value=event.target.value;
    this.inputError[type+'IPError']=false;//sourceInvalidArgument
    this.inputError['sameIPError']=false;
    if(value&&value!=""){
    const splitArComma=value.split(',');
    let ipV4Value;
    let tempArr=[];
    for(let i=0;i<splitArComma.length;i++){
      if(!splitArComma[i]){
       // let inputError="Invalid argument: 'A non-null address range is required.'";
        this.inputError[type+'IPError']=true;
        break;
      }else{
        const splitArr=splitArComma[i].split('/');
        if(splitArr.length>1&&!splitArr[1]){
          //Maliform
          this.inputError[type+'IPError']=true;
        }else{
          ipV4Value=splitArr[0];
        }
        
        try{
          let ipV4Obj=new this.ipV4(ipV4Value);
         // item.VNetAdrspc=false;
          let vlIPRange;
          if(splitArr.length>1){
          try{
            vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
            //item.VNetAdrspc=false;
            if(vlIPRange.input!=splitArComma[i]){
              //item.notValidCIDR=true;
              //item.validCIDRValue=vlIPRange.input;
              this.inputError[type+'IPError']=true;
              break;
            }
          }catch(e){
            //item.VNetAdrspc=true;
            this.inputError[type+'IPError']=true;
            break;
          }
        }        
        }catch(e){
       //   item.VNetAdrspc=true;
       this.inputError[type+'IPError']=true;
          break;
        }
      }
    }
    if(this.currentForm.get('primaryServerIP').value==this.currentForm.get('secServerIP').value){
        this.inputError['sameIPError']=true;
    }
  }
}
validTenant(event){
  let value=event.target.value;
  let split =value.split('/')
    if((split.length==4|| (split.length==5 &&split[4]==''))&& split[3]!='' && split[0]=='https:' && split[1]=='' && split[2]!=''){
            this.inputError['validTenant']=false
    }else   this.inputError['validTenant']=true
   
  
}
addressSpace(event,type?:any){
  let value =event.target.value
  if(value && type=='addressSpace'){
    this.inputError.nameEmpty=false
    if(!this.isAddressSpace(value)){
      console.log(value,this.isAddressSpace(value));
      this.inputError.subnetAdrspc=true;
      this.inputError[type+'Adrspc']=true;
      return;
    }
    this.inputError.subnetAdrspc=false;
    this.inputError[type+'Adrspc']=false;
    let splitArr=value.split('/');
    try{
      let vlRangeObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
      this.inputError.subnetNotValidCIDR=false;
      this.inputError[type+'NotValidCIDR']=false;
      // if(vlRangeObj.bits>29){
      //   this.inputError.subnetPerfix=true;
      //   return;
      // }else{
      //   this.inputError.subnetPerfix=false;
      // }
      if(vlRangeObj.input!=value){
        this.inputError.subnetNotValidCIDR=true;
        this.inputError[type+'NotValidCIDR']=true;
      }else{
        this.inputError.subnetNotValidCIDR=false;
        this.inputError[type+'NotValidCIDR']=false;
      }
    }catch(e){
      this.inputError.subnetNotValidCIDR=true;
      this.inputError[type+'NotValidCIDR']=true;
      return;
    }
  }else this.inputError.nameEmpty=true
}
validNATAddressSpace(event,flag,i){
  let value=event.target.value;
  this.saveDisable=true
  this.currentIndex=i
  this.inputError[flag+'Adrspc']=false;//sourceInvalidArgument
  this.inputError[flag+'NotValidCIDR']=false;//sourceNotValidCIDR

  if(value&&value!=""){
  const splitArComma=value.split(',');
  let ipV4Value;
  let tempArr=[];
  for(let i=0;i<splitArComma.length;i++){
    if(!splitArComma[i]){
     // let inputError="Invalid argument: 'A non-null address range is required.'";
      this.inputError[flag+'NotValidCIDR']=true;
      break;
    }else{
      const splitArr=splitArComma[i].split('/');
      if(splitArr.length>1&&!splitArr[1]){
        //Maliform
        this.inputError[flag+'NotValidCIDR']=true;
      }else{
        ipV4Value=splitArr[0];
      }
      
      try{
        let ipV4Obj=new this.ipV4(ipV4Value);
       // item.VNetAdrspc=false;
        let vlIPRange;
        if(splitArr.length>1){
        try{
          vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
          //item.VNetAdrspc=false;
          if(vlIPRange.input!=splitArComma[i]){
            //item.notValidCIDR=true;
            //item.validCIDRValue=vlIPRange.input;
            this.inputError[flag+'NotValidCIDR']=true;
            break;
          }
        }catch(e){
          //item.VNetAdrspc=true;
          this.inputError[flag+'NotValidCIDR']=true;
          break;
        }
      } 
      }catch(e){
     //   item.VNetAdrspc=true;
     this.inputError[flag+'Adrspc']=true;
        break;
      }
    }
  }
}
}

isAddressSpace(string){
  return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
}
natRuleNameValid(event,index){
  let value =event.target.value
  this.saveDisable=true
  this.currentIndex=index
  if(index!=''){
    if(!this.vpnNATRuleList.controls.length) return;
    let data = this.vpnNATRuleList.controls.some((e,i)=> {
      if(index !== i && e.get('name').value == value && e.get('name').value != '' ){
        this.inputError['uniqueNATname']=true
      }
      else if(index !== i && e.get('name').value != value &&e.get('name').value != ''){
        this.inputError['uniqueNATname']=false
      }
    })
  }
}
publicIPForm(){
  this.subSideSheetForm = this.formBuilder.group({
    publicIPName:[null,[Validators.required]],
    IPsku:['Basic'],
    routing:[null],
  })
  this.showSubSidesheet=true
}
async createNewPublicIP(){
  let rawValue=this.subSideSheetForm.getRawValue()
  let id=`/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/publicIPAddresses/${rawValue.publicIPName}`
    let sku=rawValue.IPsku
  let publicIPbody={
      "properties": {
        "publicIPAllocationMethod": sku=='Basic'?'Dynamic':'Static',
        "idleTimeoutInMinutes": 10,
        "publicIPAddressVersion": "IPv4",
        "ipTags": [
          {
          "ipTagType": "RoutingPreference",
          "tag":  rawValue.routing
          }
          ],
      },
      "sku": {
        "name": sku,
        "tier": "Regional"//sku=="Standard"?"Global":"Regional"
      },
      "location": this.selectedRow[3]
    }
    sku!='Basic'?publicIPbody['zones']=["1","2","3"]:delete publicIPbody?.properties.ipTags
    rawValue.routing=='MicrosoftNetwork'?delete publicIPbody?.properties.ipTags: false
    await this.updatePublicIPAddress(this.selectedRow[2],publicIPbody,this.selectedRow[1],rawValue.publicIPName,'puclicIPCreate')
    await this.getPublicIPList()
    if(this.vpnGatewayData?.properties?.activeActive&&this.vpnGatewayData?.properties?.ipConfigurations.length==2){
      let publicIpId=this.vpnGatewayData?.properties?.ipConfigurations[1]?.properties?.publicIPAddress?.id
      this.publicIPList.push({name:publicIpId.split('/')[8],id:publicIpId})
    }
    this.currentForm.get('publicIp2').setValue(id)
    this.showSubSidesheet = false
}
  //API's
  async getVPNGatewayData(data,type,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vpnName = data[0]
    return this.util.handleRequest('get','a3s_management_getVPNGatewayData',[subsId,rgName,vpnName],null,null,null,true).then(res=>{
      this.vpnGatewayData = res.body
      this.ingressList=this.vpnGatewayData?.properties?.natRules.filter(x=>x?.properties?.mode=='IngressSnat')
      this.egressList=this.vpnGatewayData?.properties?.natRules.filter(x=>x?.properties?.mode=='EgressSnat')
      switch(type){
        case'disableCondition':{
          this.disableCondition=this.vpnGatewayData?.properties?.vpnType=='RouteBased'?true:false
          this.point2siteCondition=this.vpnGatewayData?.properties?.activeActive&&this.vpnGatewayData?.properties?.sku?.name.includes('VpnGw')?true:false
          this.natRuleCondition=this.vpnGatewayData?.properties?.sku?.name=='Basic'||(this.vpnGatewayData?.properties?.sku?.name.includes('VpnGw')&&!this.vpnGatewayData?.properties?.sku?.name.includes('AZ'))?true:false
        }
      }
      this.disableActiveMode=!this.vpnGatewayData?.properties?.activeActive&&this.vpnGatewayData?.properties?.vpnClientConfiguration&&this.vpnGatewayData?.properties?.sku?.name.includes('VpnGw')?true:false
      console.log('VPN Data',this.vpnGatewayData)
    })
  }

  async getVPNGatewayAllConfig(data,type,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vpnName = data[0]
    return this.util.handleRequest('get','a3s_management_getVPNGatewayAllConfig',[subsId,rgName,vpnName],null,null,null,true).then(res=>{
      this.vpnGatewayAllConfig = res.body
      console.log('VPN Data',this.vpnGatewayAllConfig)
    })
  }
  async getPublicIPList(type?:any){
    let subsId = this.selectedRow[2]
    return  this.util.handleRequest('get','a3s_management_getPublicIPlistData',[subsId],null,null,null,true).then(res=>{
      this.publicIPAllList = res.body.value
       let loc = this.selectedRow[3]
       this.publicIPList=this.publicIPAllList.filter(e=>e.location == loc && !e?.properties?.ipConfiguration)// && e?.properties?.publicIPAllocationMethod!='Static')
       this.publicIPList=this.vpnGatewayData?.properties?.sku?.name.includes('VpnGw')?this.publicIPList.filter(e=>e?.sku?.name=='Standard'):this.publicIPList.filter(e=>e?.sku?.name=='Basic')
      console.log('publicIPList Data',this.publicIPList)
    })
  }
  async updatePublicIPAddress(subsId,body,rgName,nicName,type?:any){
    return this.util.handleRequest('post','a3s_management_updatePublicIPAddress',[subsId,rgName,nicName],body,null,null,true).then(res=>{
       let respo = res.body
       // this.showSubSidesheet = false
       this.util.success(this.getSuccesText(type))
      
     })
   }
  async getConnectionList(type,data,subData?:any){
    let subsId = data[2]
    let rgName = data[1] 
    return this.util.handleRequest('get','a3s_management_getConnectionList',[subsId,rgName],null,null,null,true).then(res=>{
      this.connectionAllList= res.body.value
      this.connectionAllList=this.connectionAllList.filter(x=>x.properties?.virtualNetworkGateway1||x.properties?.virtualNetworkGateway2)
      this.connectionList=this.connectionAllList.filter(x=>x.properties?.virtualNetworkGateway1.id.split('/')[8]==this.vpnGatewayData.id.split('/')[8] || x.properties?.virtualNetworkGateway2.id.split('/')[8]==this.vpnGatewayData.id.split('/')[8] )
      console.log('NIC Data',this.connectionList)
    })
  }

  async getConnectionData(data,subData?:any){
    let subsId = data[2]
    let rgName = data[1] 
    this.connectionList.map(e=>{
      let connectionName = e.name
      return this.util.handleRequest('get','a3s_management_getConnectionData',[subsId,rgName,connectionName],null,null,null,true).then(res=>{
        let connectionData= res.body
          e['properties'].connectionStatus=e.name==connectionData.name ? connectionData?.properties?.connectionStatus:false
      console.log('NIC Data',this.connectionList)
    })
    })
  }
  async getLNGList(data,type?:any){
    let subsId = data[2]
    let rgName = data[1] 
    return this.util.handleRequest('get','a3s_management_getLocalNetworkGatewayList',[subsId,rgName],null,null,null,true).then(res=>{
      this.lngList= res.body.value
      this.lngList=this.lngList.filter(e=>e.location==data[3])
      console.log('NIC Data',this.connectionList)
    })
  }
  async getVPNList(data,type?:any){
    let subsId = data[2]
    let rgName = data[1] 
    return this.util.handleRequest('get','a3s_management_getVPNGatewayList',[subsId,rgName],null,null,null,true).then(res=>{
      this.vpnList= res.body.value
      this.vpnList=this.vpnList.filter(x=>x.location==data[3] && x.properties.gatewayType!='ExpressRoute')
      console.log('NIC Data',this.connectionList)
    })
  }
  updateConnectionData(data,body,type,subData?:any){
    let subsId = data[2]
    let rgName = type == 'deleteConnection' ?  data[1] : data[1] 
    let connectionName = type == 'deleteConnection' ? this.selectedSideSheetRow.name : body.name
    this.util.info(this.getInProgress(type))
    return this.util.handleRequest('post','a3s_management_updateConnectionData',[subsId,rgName,connectionName],body,null,null,true).then(res=>{
      console.log('NIC Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      this.resetForm()
      this.connectionList.push(body)
      // this.closeSidesheet()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  updateVPNGatewayData(data,body,type){
    let subsId = data[2]
    let rgName =  data[1] 
    let vpnName = data[0]
    this.util.info(this.getInProgress(type))
    return this.util.handleRequest('post','a3s_management_updateVPNGatewayData',[subsId,rgName,vpnName],body,null,null,true).then(res=>{
      console.log('VPN Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      this.util.success(this.getSuccesText(type))
      switch(type){
        case'deletePoint2Site':{
          this.resetForm()
          this.currentForm.get('tunnelType').setValue('OpenVPN')
          this.getAuthenticationType()
          this.showDeleteBtn=false
        }
        case'vpnPoint2Site':{
          this.showDeleteButton=true
        }
      }
    },err=>{
      this.util.error(this.getFailureText(type))
      if(type=='vpnPoint2Site'){
        this.showDeleteButton=false
      }else if(type=='deletePoint2Site'){
        this.showDeleteBtn=true
      }
    })
  }
 
  deleteVPNGatewayData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],vpnName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteVPNGatewayData',[subscriptionId,resourceGroupName,vpnName],null,null,null,true).then(async res=>{
      this.closeModal()
        let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
        this.data.splice(index,1)
      this.util.success(data[0] + ' deleted successfully!')
    },err=>{
      this.util.error(data[0] +' failed to deleted.')
    })
  }

  deleteConnectionData(data,name){
    let subscriptionId = data[2],resourceGroupName = data[1],connectionName = name
   // this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteConnection',[subscriptionId,resourceGroupName,connectionName],null,null,null,true).then(async res=>{
      this.closeModal()
     // this.util.success(data[0] + ' deleted successfully!')
      let index = this.connectionList.findIndex(e=> e.name == this.selectedSideSheetRow.name)
      this.connectionList.splice(index,1)
    },err=>{
     // this.util.error(data[0] +' failed to deleted.')
    })
  }
}
