<div class="introduction">
   
            <p class="heading" id="5">User Management   </p>
            <ul>
                <li>Click on ellipsis (<img class="ellipis" src="assets\user-manual\Vector.svg" >). </li>
                <li>Chose the operation that you want to perform.  </li>
                <ol style="list-style-type: lower-alpha;">
                    <li>Edit User. </li>
                    <li>Manage Roles. </li>
                    <img style="width: 50%;height: 50%;" src="assets\user-manual\governance\Side-sheet(5).svg" >
                    <li>Reset Password. </li>
                    <li>Delete User. </li>
                </ol>
                <li>Provide all the needed inputs.   </li>
                <li>Click <b>Save. </b> </li>

            </ul>
          
       
         
           
       
           
           
             
</div>
