import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
import { LoaderService } from 'src/app/services/loader.service';
import { NewResourceGroup, ResourceInputError, SAResourceModel } from '../resources-model';

@Component({
  selector: 'app-storage-account',
  templateUrl: './storage-account.component.html',
  styleUrls: ['./storage-account.component.scss']
})
export class StorageAccountComponent implements OnInit {
  @Input() projectData : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  storageAccountmodel : SAResourceModel={
    resourceId: null,
    resourceName: null,
    resourceType: 'storageAccounts',
    resourceData: {
      subnet: null,
      vnetName: null,
      vnetResourceGroup: null,
      performance: 'Standard',
      kind: 'StorageV2',
      redundancy: 'Standard_LRS',
      infrastructureEncryptionEnabled: false,
      customerManagedKeysEnabled: false,
      readAccessEnabled: true,
      allowBlobPublicAccess: true,
      secureTransferEnable: true,
      keyAccessEnabled: false,
      minimumTlsVersion: 'TLS1_2',
      accessTier: 'Hot',
      recovery: 6,
      containerSoftDeleteRetentionDays: 7,
      blobSoftDeleteRetentionDays: 7,
      shareSoftDeleteRetentionDays: 7,
      largeFileSharesState: false,
      isShareSoftDeleteEnabled: false,
      changeFeed: false,
      isVersioningEnabled: false,
      isBlobSoftDeleteEnabled: false,
      isContainerSoftDeleteEnabled: false,
      isContainerRestoreEnabled: false,
      resourceGroup: null,
      tagList: [{
        tagName:null,
        value:null
      }]
    },
    comments: null,
    createdBy: null
  };
  show : boolean = false;
  inputError:ResourceInputError= {
    storageAccountName:false,
    storageAccountChar:false,
    restoredays:false,
    duplicateSa:false,
    VNetDescription: false,
    blobSoftDeleteRetentionDays: false,
    shareSoftDeleteRetentionDays: false,
  }
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  resourceGroupList:any=[];
  showView:boolean=false;
  storageSkusList:any=[];
  tempStorageSkusList:any=[];
  storageAccountList: any=[];
  acntTypeListForStand:any=[
    {name:'Storage V2',id:'StorageV2'}
  ];

  accountKindList:Array<{name:string,id:string}>=[
    {name:'StorageV2 (general purpose v2)',id:'StorageV2'},
    {name:'Storage (general purpose v1)',id:'Storage'}
  ]

  acntTypeListForPerm:any=[
    {name:'Block blobs', discription: 'Best for high transaction rates or low storage letancy',id:'BlockBlobStorage'},
    {name:'File shares',discription: 'Best for enterprise or high-performance applications that need to scale',id:'FileStorage'},
    {name:'Page blobs',discription: 'Best for random read and write operations',id:'PageBlobStorage'}
  ];

  minTlsvList:any=[
    {name:'Version 1.0',id:"TLS1_0"},
    {name:'Version 1.1',id:"TLS1_1"},
    {name:'Version 1.2',id:"TLS1_2"}
  ];

  redncyListForStand:any=[
    {name:'Locally-redundant storage (LRS)',id:'LRS'},
    {name:'Geo-redundant storage (GRS)',id:'GRS'},
    {name:'Zone-redundant storage (ZRS)',id:'ZRS'},
    {name:'Geo-zone-redundant storage (GZRS)',id:'GZRS'}
  ];

  redncyListForOther:any=[
    {name:'Locally-redundant storage (LRS)',id:'LRS'},
    {name:'Zone-redundant storage (ZRS)',id:'ZRS'}
  ];

  redncyListForPerm:any=[
    {name:'Locally-redundant storage (LRS)',id:'LRS'}
  ];
  existingNewVMList: any=[];
  existingNewSAList: any=[];
  
  constructor(private util:UtilService,private loader:LoaderService) { }

  async ngOnInit() {
    this.loader.showLoader();
    await this.getResourceGroupList();
    let parentResourceData,resourceObj;
    if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
      this.storageAccountmodel.resourceData.subnet=this.parentResourceDtls.resourceName;
      parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
      this.storageAccountmodel.resourceData.vnetName=parentResourceData.vnetName;
      resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
      this.storageAccountmodel.resourceData.resourceGroup=resourceObj.name;
      this.storageAccountmodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
      this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
    }         
    
    await this.getListByPropName('storageAccounts',undefined,undefined);
    await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
    this.filterStorageSkus(this.storageAccountmodel.resourceData.performance,this.storageAccountmodel.resourceData.kind);        
    await this.getExistNewlyResourceDetails('virtualMachines');
    await this.getExistNewlyResourceDetails('storageAccounts');
    if(this.sideSheetViewType=='edit'){
      await this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null,null,null,true).then(async res=>{
        this.storageAccountmodel=res;
        this.storageAccountmodel.resourceData=JSON.parse(res.resourceData);
        if(this.parentResourceDtls['updateRequestFrom']){
          this.storageAccountmodel.resourceData.vnetName=this.parentResourceDtls.resourceName;
          this.storageAccountmodel.resourceData.resourceGroup=resourceObj.name;
        }
      });
    }
    this.loader.hideLoader();
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType === 'virtualMachines') {
        this.existingNewVMList=res;
      } else if(resourceType === 'storageAccounts') {
        this.existingNewSAList=res;
      }
    },err=>{
      if(resourceType === 'virtualMachines') {
        this.existingNewVMList=[];
      } else if(resourceType === 'storageAccounts') {
        this.existingNewSAList=[];
      }
    });
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
      });
  }
  
  onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.storageAccountmodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.storageAccountmodel.resourceData.resourceGroup=undefined; 
    });
  }

  editNewResourceGroup(item){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item);    
    this.show = !this.show;
  }

  async getNewResourceGroup(){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addTagVM(){
    this.storageAccountmodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTagVM(index){
    this.storageAccountmodel.resourceData.tagList.splice(index,1);
  }

  hideShow(){
    this.showView=!this.showView;
  }

  async getListByPropName(propName,resourceObj,selectedObj){    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      let parentResourceData=this.parentResourceDtls&&this.parentResourceDtls.resourceData?JSON.parse(this.parentResourceDtls.resourceData):{};
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name==this.storageAccountmodel&&this.storageAccountmodel.resourceData&&this.storageAccountmodel.resourceData.resourceGroup?this.storageAccountmodel.resourceData.resourceGroup:parentResourceData.resourceGroup);
      if(propName=='storageSkus'){
        this.storageSkusList=res.value;
      } else if(propName=='storageAccounts'){
        this.storageAccountList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }
      return  result;
    },err=>{
    });
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.storageAccountmodel);
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  filterStorageSkus(performance,kind){
    let kindVal=_.cloneDeep(kind);
    if(kind=='PageBlobStorage'){
      kindVal='StorageV2'
    }
    this.tempStorageSkusList=this.storageSkusList.filter(dt=>dt.tier==performance&&dt.kind==kindVal);
    this.tempStorageSkusList.map(dt=>{
      let findObj=this.util.getStatic('redundancy').find(ds=>ds.id==dt.name.split("_")[1]);
      if(findObj){
        dt.rdname=findObj.name;
      }
      return dt;
    });
    let findObj=this.tempStorageSkusList.find(dt=>dt.name.includes('_LRS'));
    if(this.storageAccountmodel&&this.storageAccountmodel.resourceData){
    this.storageAccountmodel.resourceData.redundancy= findObj?findObj.name:'Standard_LRS';
    }
  }

  saDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateSa =false; 
    if(value){
   let findObj=this.storageAccountList.find(dt=>dt.name==value);
    if(this.existingNewSAList&&!findObj){
      findObj=this.existingNewSAList.find(dt=>dt.resourceName==value&&dt.resourceId!=this.storageAccountmodel.resourceId);
    }
    if(this.existingNewVMList&&!findObj){
      findObj=this.existingNewVMList.find(dt=>{
        if(dt.resourceData) {
          const resourceData=JSON.parse(dt.resourceData);
          if(resourceData.diagnosticsStorageAccount) {
            return resourceData.diagnosticsStorageAccount.name===value;
          }
        }
      });
    }
    if(findObj){
      this.inputError.duplicateSa =true; 
      return;
    }else{
      this.inputError.duplicateSa =false; 
    }

    this.checkStorageNameAvail(event,'duplicateSa');
  }
  }

  async checkStorageNameAvail(event,flagCheck){    
    let value=event.target.value;
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      name:value
    }
    await this.util.handleRequest('post','a3s_architectureBlueprint_checkStorageName',[],reqObj,null,null,true).then(res=>{
      if(res&&res.nameAvailable){
        this.inputError[flagCheck] =false; 
      }else{
        this.inputError[flagCheck] =true; 
      }
    })
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.storageAccountmodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  storageAccountNameValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.storageAccountName = true;
        return
    }
    else this.inputError.storageAccountName = false;

    for(let input of value){
      if(!(this.isCharALetter(input) || this.isCharANumber(input))){
        this.inputError.storageAccountChar = true;
        return;
      }
    }
    this.inputError.storageAccountChar = false;
    this.saDuplicateCheck(limitField)
  }

  storageAccountDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VNetDescription = true;
    }
    else this.inputError.VNetDescription = false;
  }

  changeDaysRestore(limitField){
    let value = limitField.target.value;
    for(let input of value){
      if( !(this.isCharANumber(input)) ){
        this.inputError.restoredays = true;
        return;
      }
    }
    if (!value || parseInt(value)<1 || parseInt(value) > 364) {
      this.inputError.restoredays = true;
    }
    else this.inputError.restoredays = false;
  }

  checkSoftDelete(limitField, flag) {
    let value = limitField.target.value;
    if (!value || parseInt(value)<1 ||parseInt(value) > 365) {
      this.inputError[flag] = true;
    }
    else this.inputError[flag] = false;

  }

  saveResourceDetails(){
    var model = this.storageAccountmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.storageAccountmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  disabledCheckBoxSA(flag){
    switch(flag){
      case 'largeFile':{
        if(this.storageAccountmodel.resourceData.kind=='PageBlobStorage'||this.storageAccountmodel.resourceData.kind=='BlockBlobStorage'){
          this.storageAccountmodel.resourceData.largeFileSharesState=false;
          this.storageAccountmodel.resourceData.isShareSoftDeleteEnabled=false;
          return true;
        }else {
          return false;
        }
      }
      case 'tracking':{
        if(this.storageAccountmodel.resourceData.kind=='PageBlobStorage'||this.storageAccountmodel.resourceData.kind=='FileStorage'){
          this.storageAccountmodel.resourceData.changeFeed=false;
          this.storageAccountmodel.resourceData.isVersioningEnabled=false;
          return true;
        }else {
          return false;
        }
      }
      case 'soft':{
        if(this.storageAccountmodel.resourceData.kind=='FileStorage'){
          this.storageAccountmodel.resourceData.isBlobSoftDeleteEnabled=false;
          this.storageAccountmodel.resourceData.isContainerSoftDeleteEnabled=false;
          return true;
        }else {
          return false;
        }
      }
      case 'point':{
        if(this.storageAccountmodel.resourceData.kind=='PageBlobStorage'||this.storageAccountmodel.resourceData.kind=='FileStorage'||this.storageAccountmodel.resourceData.kind=='BlockBlobStorage'){
          this.storageAccountmodel.resourceData.isContainerRestoreEnabled=false;
          return true;
        }else {
          return false;
        }
      }
      case 'largeFiles':{
        if(this.storageAccountmodel.resourceData.performance=='Standard'&&(this.storageAccountmodel.resourceData.redundancy.includes('GRS')||this.storageAccountmodel.resourceData.redundancy.includes('GZRS'))){
          return true;
        }else {
          return false;
        }
      }
      case 'trackings':{
        if(this.storageAccountmodel.resourceData.isContainerRestoreEnabled==true){
          this.storageAccountmodel.resourceData.changeFeed=true;
          this.storageAccountmodel.resourceData.isVersioningEnabled=true;
          return true;
        }else {
          this.storageAccountmodel.resourceData.changeFeed=false;
          this.storageAccountmodel.resourceData.isVersioningEnabled=false;
          return false;
        }
      }
    }
  }

  checkDataDisabled(){
    return !this.storageAccountmodel.resourceName || this.inputError.storageAccountChar || this.inputError.storageAccountName || this.inputError.duplicateSa || this.inputError.blobSoftDeleteRetentionDays||this.inputError.shareSoftDeleteRetentionDays||this.inputError.restoredays;
  }
}
