import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
    $('.main-content').addClass('grey-bg');
  }

  ngOnDestroy(){
    $('.main-content').removeClass('grey-bg');
  }

}
