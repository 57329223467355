import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deployed',
  templateUrl: './deployed.component.html',
  styleUrls: ['./deployed.component.scss']
})
export class DeployedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
