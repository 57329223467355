<div class="custom-fixed-tables">
    <table class="table">
        <thead>
          <tr>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Name</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('SqlVirtualMachines',0)">
                </div>
            </th>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Resource Group</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('SqlVirtualMachines',1)">
                </div>
            </th>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Subscription</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('SqlVirtualMachines',2)">
                </div>
            </th>
            <th>
              <div class="flex-custom no-wrap">
                  <span>Region</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('SqlVirtualMachines',3)">
              </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>SKU</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('SqlVirtualMachines',4)">
              </div>
          </th>
          <th>
              <div class="flex-custom no-wrap">
                  <span>Image Offer</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('SqlVirtualMachines',5)">
              </div>
          </th>
          <th style="border-right: none;">
            <div class="flex-custom no-wrap">
                <span>Tags</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('SqlVirtualMachines',6)">
            </div>
          </th>
          <th style="border-left: none;" class="wid-75"></th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data">
              <td isEllipsisActive  class="blue-text cursor-pointer" (click)="openModal(sqlVMDetailsTemplate,row,'sqlVMPopup')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive>{{ row[4] | titlecase | displayTxt }}</td>
              <td isEllipsisActive>{{ row[5] | displayTxt }}</td>
              <td isEllipsisActive>{{getTags(row[6])}}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2" (click)="getVMStatus(row)">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" [class.disabled-icon]="disableAction" mat-menu-item (click)="openSidesheet('configure',row)">
                      <p class="delete-popup-text">Configure</p>
                    </div>
                    <div class="mat-menu-item" [class.disabled-icon]="disableAction" mat-menu-item (click)="openSidesheet('storageConfiguration',row)">
                      <p class="delete-popup-text">Storage Configuration</p>
                    </div>
                    <div class="mat-menu-item" [class.disabled-icon]="disableAction" mat-menu-item (click)="openSidesheet('patching',row)">
                      <p class="delete-popup-text">Patching</p>
                    </div>
                    <div class="mat-menu-item" [class.disabled-icon]="disableAction" mat-menu-item (click)="openSidesheet('additionalFeatures',row)">
                        <p class="delete-popup-text">Additional Features</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteSqlVMData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
             </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>

  <ng-container *ngIf="showSidesheet">
    <!-- Configure-->
    <ng-container *ngIf="currentTemplate == 'configure'">
     <div class="sidebar-right single add-alert">
         <div class="header">
             <div class="d-flex">
           <h4 class="threeFour-width">Configure<br> <span class="blur-text">SQL Virtual Machine | {{selectedRow[0]}}</span></h4>
               <div class="text-right full-width">
                 <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                   <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                 </button>
               </div>
             </div>
         </div>
     
         <div class="body scroll-y">
             <form [formGroup]="currentForm">
              <div class="mb-3">
                <label class="flex-custom no-wrap">SQL Server License<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If you have SQL Server licenses with Software Assurance (SA) 
                  or a SQL Server subscription, use Azure Hybrid Benefit to save
                  the money. Azure Hybrid benefits is only supported for 
                  SQL Standard or Enterprise edition." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="serverLicense"  class="form-check-inline" id='PAYG' (change)="setRadioBtn('PAYG','configure')" [checked]="currentForm.get('serverLicense')?.value =='PAYG'">
                      <label class="value" for="PAYG">Pay As You Go</label>
                  </div>
                </div>
                <div class="flex-custom">
                  <div class="checkbox-cs">
                      <input type="radio" name="serverLicense" class="form-check-inline" id='AHUB' (change)="setRadioBtn('AHUB','configure')" [checked]="currentForm.get('serverLicense')?.value == 'AHUB'">
                      <label class="value" for="AHUB">Azure Hybrid Benefit</label>
                  </div>
                </div>
                <div class="flex-custom">
                  <div class="checkbox-cs">
                      <input type="radio" name="serverLicense" class="form-check-inline" id='HA/DR' (change)="setRadioBtn('HA/DR','configure')" [checked]="currentForm.get('serverLicense')?.value == 'HA/DR'">
                      <label class="value" for="HA/DR">HA/DR</label>
                  </div>
                </div>
              </div>
              <div class="comm-mb" *ngIf="errorMsg">
                <div class="label-div d-flex redAlertBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/red-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;color: #BB202F;">
                   {{currentForm.get('serverLicense')?.value}} is only supported for SQL standard and enterprise edition.</label>
                 </div>  
              </div>
               <div class="mb-3">
                 <label class="flex-custom no-wrap">Edition<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Note that, before changing the edition type here, you must change 
                  the SQL Server edition type on virtual machine manually. The edition
                  type selected here must match the edition on virtual machine. Changes 
                  made to the edition type here, are just applicable to the billing rates." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                 <ng-select [items]="editionList" (change)="setEdition($event)" bindLabel="name" bindValue="name" name="edition" [clearable]="false" id="edition" formControlName="edition"></ng-select>
               </div>
               <div class="mb-3" *ngIf="warningMsg">
                <div class="label-div d-flex warningBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;color: #703501;">
                    Note that, before changing the edition type here, you must change the SQL Server edition type on virtual machine manually. The edition type selected here must match the edition on virtual machine. Changes made to the edition type here, are just applicable to the billing rates.</label>
                 </div>  
              </div>
             </form>
         </div>

         <div class="fixed-btm-btn flex-custom no-wrap">
             <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || errorMsg" (click)="saveSQLVMData('configure')">Save</button>
         </div>
     </div>
   </ng-container>
    <!-- Additional Features-->
    <ng-container *ngIf="currentTemplate == 'additionalFeatures'">
    <div class="sidebar-right single add-alert">
        <div class="header">
            <div class="d-flex">
          <h4 class="threeFour-width">Additional Features<br> <span class="blur-text">SQL Virtual Machine | {{selectedRow[0]}}</span></h4>
              <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
    
        <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-3">
                <label class="flex-custom no-wrap">SQL Server Machine</label>
                <label class="flex-custom no-wrap"> Learning Services (In-Database)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enables SQL Server Machine Learning Services (In-Database), 
                  allowing you to utilize advanced analytics within your SQL Server.
                  SQL Server Machine Learning Services (In-Database) is only 
                  supported with SQL Server 2017 Enterprise." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="sqlServerMLService"  class="form-check-inline" id='Disable' (change)="setRadioBtn('no','additionalFeatures')" [checked]="currentForm.get('sqlServerMLService')?.value ==false">
                      <label class="value" for="Disable">Disable</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="sqlServerMLService" class="form-check-inline" id='Enable' (change)="setRadioBtn('yes','additionalFeatures')" [checked]="currentForm.get('sqlServerMLService')?.value == true">
                      <label class="value" for="Enable">Enable</label>
                  </div>
                </div>
              </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable" (click)="saveSQLVMData('additionalFeatures')">Save</button>
        </div>
    </div>
    </ng-container>
    <!-- Patching-->
    <ng-container *ngIf="currentTemplate == 'patching'">
    <div class="sidebar-right single add-alert">
        <div class="header">
            <div class="d-flex">
          <h4 class="threeFour-width">Patching<br> <span class="blur-text">SQL Virtual Machine | {{selectedRow[0]}}</span></h4>
              <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
    
        <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-3">
                <div class="label-div d-flex blueBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;">
                    Only Windows and SQL Server updates marked Important are installed. Other SQL Server updates, such as Cumulative Updates, must be installed manually.</label>
                </div>  
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap"> Automated patching<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set a patching window during which all 
                  Windows and SQL patches will be applied." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="automatedPatching"  class="form-check-inline" id='Disable' (change)="setRadioBtn('no','patching')" [checked]="currentForm.get('automatedPatching')?.value ==false">
                      <label class="value" for="Disable">Disable</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="automatedPatching" class="form-check-inline" id='Enable' (change)="setRadioBtn('yes','patching')" [checked]="currentForm.get('automatedPatching')?.value == true">
                      <label class="value" for="Enable">Enable</label>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="currentForm.get('automatedPatching')?.value">
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Maintenance schedule<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the day(s) during which patching can take place." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <ng-select [items]="daysList"  bindLabel="name" bindValue="name" (change)="saveDisable=true" name="schedule" [clearable]="false" id="schedule" formControlName="schedule"></ng-select>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Maintenance start hour (local time)</label>
                  <ng-select [items]="localTimeList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="startHour" [clearable]="false" id="startHour" formControlName="startHour"></ng-select>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Maintenance window duration (minutes)</label>
                  <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.InValidSize|| inputError?.InvalidSizeRange" id="windowDuration" name="windowDuration" formControlName="windowDuration" (keyup)="dataSizeValidation($event)"/>
                  <!-- <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p> -->
                  <p class="text-danger" *ngIf="inputError?.InValidSize">The value must be a valid number.</p>
                  <p class="text-danger" *ngIf="inputError?.InvalidSizeRange">The value must be between 30 and 180.</p>
                </div>
              </ng-container>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('patching')" (click)="saveSQLVMData('patching')">Save</button>
        </div>
    </div>
    </ng-container>
    <!-- Storage Configuration -->
    <ng-container *ngIf="currentTemplate == 'storageConfiguration'">
    <div class="sidebar-right single width-652" [class.sub-sheet-652]="showSubSidesheet" >
        <div class="header">
            <div class="d-flex">
              <h4 class="threeFour-width ">Storage Configuration<br> <span class="blur-text">SQL Virtual Machine | {{selectedRow[0]}}</span></h4>
              <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body scroll-y">
          <div class="mb-3">
            <label class="flex-custom no-wrap bold-600">Storage Usage<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Review your storage optimization configuration here or extend 
              your storage capacity by selecting from a variety of disk types." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
          </div>
          <div class="custom-fixed-tables mb-3">
                <table class="table">
                    <thead>
                        <tr>
                          <th><div class="flex-custom no-wrap"><span>Drive</span></div></th>
                          <th><div class="flex-custom no-wrap"><span>Drive Capacity</span></div></th>
                          <th><div class="flex-custom no-wrap"><span>Configure Drive</span></div></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr >
                          <td style="width: 50px;">Data</td>
                          <td isEllipsisActive>SQLVMDATA1 (F:) {{freeSizeF}} Gib free of {{totalSizeF}} Gib</td>
                          <td [class.disabled-icon]="remDiskCount<1" style="width: 50px;"><button class="btn whiteBtn" (click)="openSubSideSheet('data')">Configure</button></td>
                        <tr>
                          <td style="width: 50px;">Log</td>
                          <td isEllipsisActive>SQLVMLOG (G:) {{freeSizeG}} Gib free of {{totalSizeG}} Gib</td>
                          <td [class.disabled-icon]="remDiskCount<1" style="width: 50px;"><button class="btn whiteBtn" (click)="openSubSideSheet('log')">Configure</button></td>
                        </tr>
                        <tr>
                          <td style="width: 50px;">TempDb</td>
                          <td isEllipsisActive>Temporary Storage (D:) {{freeSizeD}} Gib free of {{totalSizeD}} Gib</td>
                          <td [class.disabled-icon]="remDiskCount<1" style="width: 50px;"><button class="btn whiteBtn" (click)="openSubSideSheet('tempDB')">Configure</button></td>
                        </tr>
                      </tbody>
                </table>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showSubSidesheet">
        <div class="sidebar-right single width-652" *ngIf="currentSubSheet=='data'">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Extend Data Drive<br> <span class="blur-text">SQL Virtual Machine | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetSubSideSheetForm()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-3">
                <div class="label-div d-flex blueBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;">
                    Adds more capacity for high volume data needs. Note that the desired disk performance might not be reached if the overall IOPS, throughput and storage size exceeded the current virtual machine maximum (Standard_DS12_v2).</label>
                </div>  
              </div>
              <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th style="width: 100%;">
                              <div class="flex-custom no-wrap">
                                  <span>Disk</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Disk Size</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Max IOPS</span>
                              </div>
                          </th>
                          <th >
                              <div class="flex-custom no-wrap">
                                  <span>Max Throughput (MB/s)</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Number of Disks</span>
                              </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr>
                          <td style="max-width: 175px;">
                            <ng-select style="position: fixed;max-width: 175px;" [items]="performanceTier" bindLabel="name" bindValue="id" (change)="getStorageDetails($event)" name="disk" [clearable]="false" id="disk" formControlName="disk"></ng-select>
                          </td>
                          <td>{{currentForm.get('Gib').value}}</td>
                          <td>{{currentForm.get('IOPS').value}}</td>
                          <td>{{currentForm.get('MBps').value}}</td>
                          <td>
                            <input type="text" style="height: 32px;padding: 5px;" [class.border-danger]="errorMsg" name="diskCount" class="form-check-inline width-58"  id='diskCount'  formControlName="diskCount" (keyup)="ValidDiskcount($event)">
                          </td>
                        </tr>
                      </tbody>
                </table>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap" style="float: right;"><img class="mr-1" src="/assets/actions/grey-info.svg" alt="">{{currentForm.get('Gib').value}} GiB, {{currentForm.get('IOPS').value}} IOPS, {{currentForm.get('MBps').value}} MB/s</label>
              </div>
              <div class="mb-3 mt-5" *ngIf="errorMsg">
                <div class="label-div d-flex redAlertBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/red-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;color: #BB202F;">
                    The selected VM size ({{currentVMSize}}) only supports up to {{remDiskCount}} remaining data disks to add.</label>
                 </div>  
              </div>
              <div class="mb-3 mt-5">
                <div class="label-div d-flex warningBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;color: #703501;">
                    The desired disk performance might not be reached due to the maximum virtual machine performance cap. The selected VM size (Standard_DS12_v2) only supports up to 2800 remaining IOPS out of 12800 virtual machine maximum IOPS, 0 MB/s remaining data disk througput out of 192 MB/s virtual machine maximum throughput.</label>
                 </div>  
              </div>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || errorMsg"(click)="action(sqlVmExtensionData,'storageData')">Save</button>
          </div>
        </div>

        <div class="sidebar-right single width-652" *ngIf="currentSubSheet=='log'">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Extend Log Drive<br> <span class="blur-text">SQL Virtual Machine | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetSubSideSheetForm()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-3">
                <div class="label-div d-flex blueBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;">
                    Adds more capacity for high volume data needs. Note that the desired disk performance might not be reached if the overall IOPS, throughput and storage size exceeded the current virtual machine maximum (Standard_DS12_v2).</label>
                </div>  
              </div>
              <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th style="width: 100%;">
                              <div class="flex-custom no-wrap">
                                  <span>Disk</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Disk Size</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Max IOPS</span>
                              </div>
                          </th>
                          <th >
                              <div class="flex-custom no-wrap">
                                  <span>Max Throughput (MB/s)</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Number of Disks</span>
                              </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr>
                          <td style="max-width: 175px;">
                            <ng-select style="position: fixed;max-width: 175px;" [items]="performanceTier" bindLabel="name" bindValue="id" (change)="getStorageDetails($event)" name="disk" [clearable]="false" id="disk" formControlName="disk"></ng-select>
                          </td>
                          <td>{{currentForm.get('Gib').value}}</td>
                          <td>{{currentForm.get('IOPS').value}}</td>
                          <td>{{currentForm.get('MBps').value}}</td>
                          <td>
                            <input type="text" style="height: 32px;padding: 5px;" [class.border-danger]="errorMsg" name="diskCount" class="form-check-inline width-58"  id='diskCount'  formControlName="diskCount" (keyup)="ValidDiskcount($event)">
                          </td>
                        </tr>
                      </tbody>
                </table>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap" style="float: right;"><img class="mr-1" src="/assets/actions/grey-info.svg" alt="">{{currentForm.get('Gib').value}} GiB, {{currentForm.get('IOPS').value}} IOPS, {{currentForm.get('MBps').value}} MB/s</label>
              </div>
              <div class="mb-3 mt-5" *ngIf="errorMsg">
                <div class="label-div d-flex redAlertBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/red-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;color: #BB202F;">
                    The selected VM size ({{currentVMSize}}) only supports up to {{remDiskCount}} remaining data disks to add.</label>
                 </div>  
              </div>
              <div class="mb-3 mt-5">
                <div class="label-div d-flex warningBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;color: #703501;">
                    The desired disk performance might not be reached due to the maximum virtual machine performance cap. The selected VM size (Standard_DS12_v2) only supports up to 2800 remaining IOPS out of 12800 virtual machine maximum IOPS, 0 MB/s remaining data disk througput out of 192 MB/s virtual machine maximum throughput.</label>
                 </div>  
              </div>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || errorMsg"(click)="action(sqlVmExtensionData,'storageLog')">Save</button>
          </div>
        </div>

        <div class="sidebar-right single width-652" *ngIf="currentSubSheet=='tempDB'">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">tempdb Configuration<br> <span class="blur-text">SQL Virtual Machine | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetSubSideSheetForm()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-3">
                <label class="flex-custom no-wrap bold-600">tempdb files<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Manage tempdb data and log files. 
                  Set number of files, size and autogrowth" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap"> Configure tempdb data files<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configure all tempdb data files." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="configureTempDB"  class="form-check-inline" id='no' (change)="setRadioBtn('no','storageConfiguration')" [checked]="currentForm.get('configureTempDB')?.value ==false">
                      <label class="value" for="no">No</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="configureTempDB" class="form-check-inline" id='yes' (change)="setRadioBtn('yes','storageConfiguration')" [checked]="currentForm.get('configureTempDB')?.value == true">
                      <label class="value" for="yes">Yes</label>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="currentForm.get('configureTempDB')?.value">
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Number of files<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Total number of tempdb data files" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control half-width" type="text" autocomplete="off" [class.border-danger]="inputError?.filesCountEmpty|| inputError?.filesCountInvalidNumber || inputError?.filesCountInvalidSizeRange" id="filesCount" name="filesCount" formControlName="filesCount" (keyup)="tempDBValidation($event,'filesCount')"/>
                  <p class="text-danger" *ngIf="inputError?.filesCountEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.filesCountInvalidNumber">The value must be a valid number.</p>
                  <p class="text-danger" *ngIf="inputError?.filesCountInvalidSizeRange">The value cannot be less than current tempdb file count.</p>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Initial size (MB)<span class="mandatory">*</span></label>
                  <input class="form-control half-width" type="text" autocomplete="off" [class.border-danger]="inputError?.initialSizeEmpty|| inputError?.initialSizeInvalidNumber|| inputError?.initialSizeInvalidSizeRange" id="initialSize" name="initialSize" formControlName="initialSize" (keyup)="tempDBValidation($event,'initialSize')"/>
                  <p class="text-danger" *ngIf="inputError?.initialSizeEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.initialSizeInvalidNumber">The value must be a valid number.</p>
                  <!-- <p class="text-danger" *ngIf="inputError?.InValidSize">The value must be a valid number.</p> -->
                  <p class="text-danger" *ngIf="inputError?.initialSizeInvalidSizeRange">The value cannot be less than current tempdb file size.</p>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Autogrowth (MB)<span class="mandatory">*</span></label>
                  <input class="form-control half-width" type="text" autocomplete="off" [class.border-danger]="inputError?.autogrowthEmpty|| inputError?.autogrowthInvalidNumber" id="autogrowth" name="autogrowth" formControlName="autogrowth" (keyup)="tempDBValidation($event,'autogrowth')"/>
                  <p class="text-danger" *ngIf="inputError?.autogrowthEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.autogrowthInvalidNumber">The value must be a valid number.</p>
                  <!-- <p class="text-danger" *ngIf="inputError?.InValidSize">The value must be a valid number.</p> -->
                  <!-- <p class="text-danger" *ngIf="inputError?.InvalidSizeRange">The value must be between 30 and 180.</p> -->
                </div>
              </ng-container>
              <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>File Name</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Size (MB)</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Autogrowth Size (MB)</span>
                              </div>
                          </th>
                          <th >
                              <div class="flex-custom no-wrap">
                                  <span>File path</span>
                              </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let data of tempDBList.Storage.TempFilesStatuses;let i = index">
                          <td>{{data.Name}}</td>
                          <td>{{data.Size}}</td>
                          <td>{{data.Growth}}</td>
                          <td>{{data.DefaultFilePath}}</td>
                        </tr>
                      </tbody>
                </table>
              </div>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || invalidForm('storageTempDB') || currentForm.invalid"(click)="action(sqlVmExtensionData,'storageTempDB')">Save</button>
          </div>
        </div>
      </ng-container>
    </ng-container>
 </ng-container>

  <ng-template #sqlVMDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">SQL Virtual Machine</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" [class.disabled-icon]="disableAction" (click)="openSidesheet('configure',selectedRow)">Configure</div>
            <div class="tab-item" [class.disabled-icon]="disableAction" (click)="openSidesheet('storageConfiguration',selectedRow)">Storage Configuration</div>
            <div class="tab-item" [class.disabled-icon]="disableAction" (click)="openSidesheet('patching',selectedRow)">Patching</div>
            <div class="tab-item" [class.disabled-icon]="disableAction" (click)="openSidesheet('additionalFeatures',selectedRow)">Additional Features</div>
            <div class="tab-item" (click)="action(selectedRow,'deleteSqlVMData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedRow[i]:getTags(selectedRow[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedRow[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #storageConfigurationConfirm>
    <div class="medium-popup-wrapper"  style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p class="bold-600 mb-3">Are you sure you want to proceed? </p>
          <!-- <p>{{getStorageConfig()}}</p> -->
          <p>{{getResourceName()}}</p>
          <ul class="list-text" *ngIf="selectedType!='storageTempDB'">
            <li>{{currentForm.get('disk').value}} x {{currentForm.get('diskCount').value}} ({{currentForm.get('Gib').value}} Gib, {{currentForm.get('IOPS').value}} IOPS, {{currentForm.get('MBps').value}} MB/s)</li>
          </ul>
          <ul class="list-text" *ngIf="selectedType=='storageTempDB'">
            <li>Number of files: {{currentForm.get('filesCount').value}}</li>
            <li>Initial size (MB): {{currentForm.get('initialSize').value}}</li> 
            <li>Autogrowth (MB): {{currentForm.get('autogrowth').value}}</li>
          </ul>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-btn-primary" (click)="saveSQLVMData('storageConfiguration')">Yes</button>
        </div>
      </div>
    </div>
  </ng-template>
