<ng-template #accessDenied>
    <div class="medium-popup-wrapper width-320 simple-popup">
      <div class="modal-body">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="dlt-content">
          <img src="assets/home/denied.svg" alt="">
          <h2>Denied Access</h2>
          <p>You do not have access to this section, please contact your organization's admin for more details.</p>
          <button type="button" class="btn btn-primary btn-home" (click)="closeModal()">Back to home</button>
        </div>
      </div>
    </div>
  
    <!-- <div class="modal fade" id="myModal" role="dialog" >
      <div class="modal-dialog">
        <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <div class="modal-body">
            <img src="assets/home/denied.svg" alt="">
            <h2>Denied Access</h2>
              <div class="modal-p">
                <p>You do not have access to this section, please contact your organization's admin for more details.</p>
              </div>
              <button type="button" class="btn btn-primary btn-home" data-dismiss="modal">Back to home</button>
          </div>          
        </div>        
      </div>
    </div> -->
</ng-template>
  
  
<ng-template #underConstruction>
    <!--Modal-->

    <div class="medium-popup-wrapper width-320 simple-popup">
    <div class="modal-body">
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="dlt-content">
        <img src="assets/home/coming-soon.svg" alt="">
        <h2>Coming Soon...</h2>
        <p>This section is under construction, we will notify you once it is done.</p>
        <button type="button" class="btn btn-primary btn-home" (click)="closeModal()">Back to home</button>
    </div>
    </div>
</div>

    <!-- <div class="modal fade" id="myModal2" role="dialog" >
    <div class="modal-dialog">
    <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <div class="modal-body">
        <img src="assets/home/coming-soon.svg" alt="">
        <h2>Coming Soon</h2>
            <div class="modal-p">
            <p>This section is under construction, we will notify you once it is done.</p>
            </div>
            <button type="button" class="btn btn-primary btn-home" data-dismiss="modal">Back to home</button>
        </div>          
    </div>        
    </div>
</div> -->
</ng-template>

<ng-template #appHealthAccessDenied>
  <div class="medium-popup-wrapper width-320 simple-popup">
    <div class="modal-body">
      <div class="text-right">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="dlt-content">
        <div class="text-center">
          <img src="assets/home/denied.svg" alt="">
        </div>
        <h2>Access Denied </h2>
        <p>This section is not included in your A3S subscription. Please contact our support team if you want to gain access.</p>
        <button type="button" class="btn btn-btn-primary flex-custom full-width text-center mb-2" (click)="routeTo()">Contact A3S support</button>
        <button type="button" class="btn btn-outline flex-custom full-width text-center" (click)="closeModal()">Not now</button>
      </div>
    </div>
  </div>
</ng-template>