<div class="introduction">
    <p class="heading" id="0">Add Standards </p>
        <ul>
            <li>Click <b>Add</b> button on the standard that you want to add to the regulatory 
                compliance dashboard and then assign it to the subscription.</li>
                <img src="assets\user-manual\compliance\Tile.svg" >
                <figcaption class="caption">Add standards </figcaption>
            <li>Provide the needed inputs in the side sheet. </li>
            <img src="assets\user-manual\compliance\Add-Standard.svg" >
            <figcaption class="caption">Add standard side sheet </figcaption>
        </ul>
        
</div>