import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { TimechartService } from 'src/app/services/timechart.service';
import { UtilService } from 'src/app/services/util.service';
import { ApplicationMonitoringQueries } from '../../application-monitoring-queries';

@Component({
  selector: 'app-am-sap-hana-data-size',
  templateUrl: './am-sap-hana-data-size.component.html',
  styleUrls: ['./am-sap-hana-data-size.component.scss']
})
export class AmSapHanaDataSizeComponent implements OnInit {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  searchKey:any;
  subscriptionApp: Subscription;
  timeFilterList: any = [];
  timeFilterFlag: boolean=false;
  datasort: string;
  selectedData: any;
  providerList: any=[];
  refreshedTime: any = {
    highestCPU: new Date(),
    highestMemory: new Date()
  };
  currentFilter:any= {
    type : 'last24hr',
    from:  new Date(new Date().setDate(new Date().getDate() - 1)),
    to: new Date(),
    timeGranularity:'automatic',
    timeZone:'utc'
  };
  dataSizeDetails:any = {
    type:'dataSizeDetails',
    tempData:[],
    tableData:[],
    showLoader : true,
    refreshTime : new Date(),
  };
  hanaFileSystems:any = {
    type:'hanaFileSystems',
    tempData:[],
    tableData:[],
    showLoader : true,
    refreshTime : new Date(),
  };
  columnDataSize:any = {
    type:'columnDataSize',
    tempData:[],
    tableData:[],
    xAxis:[],
    series:[],
    legendData:[],
    showLoader : true,
    refreshTime : new Date(),
  };
  memoryStatistics:any = {
    type:'memoryStatistics',
    tempData:[],
    tableData:[],
    xAxis:[],
    series:[],
    legendData:[],
    showLoader : true,
    refreshTime : new Date(),
  };
  dbTenantsList:any=[];
  filterForm:FormGroup;
  currentProjectData: any;
  sidList: any=[];

  constructor(private util: UtilService,private modalService: NgbModal,private timechartService:TimechartService,private fb:FormBuilder) { }

  ngOnInit(): void {
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.filterForm=this.fb.group({
      dbTenants:[''],
      currentFilter:['last24hr'],
      provider:[''],
      sid:[''],
    })
    this.subscriptionApp = this.util.getObservable().subscribe(async (res) => {
      if(res.currentFilteredProject){
        this.currentProjectData = res.currentFilteredProject;
        await this.getProviderList();
        this.triggerFilter(res.currentFilteredProject,'project')  
      }
    });
  }

  getProviderList() {
    this.providerList=[];
    return this.util.handleRequest('get','a3s_applicationHealthMonitor_getProviderList',[],null,null,null,true).then(async res=>{
      if(!res || !res.body) {
        return 
      }
      let data=res.body.value||[];
      data=data.filter(dt=>dt.properties.providerSettings.providerType=='SapHana');
      let ipData : any= data.filter(x=> x.properties.providerSettings.providerType=='SapHana').map(xe=> `'${xe.properties.providerSettings.hostname}'`)
      ipData = ipData.join(',')
      let body ={ ipAddr : ipData }
      let ipResponse = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getVMDetailsByGraphQuery',[],body,null,null,true)
      await Promise.all(data.map(async xe=>{
          xe['resourceName'] = null
          if(xe.properties.providerSettings.providerType=='SapNetWeaver'){
              xe['resourceName'] = xe.properties.providerSettings.sapHostname.split('.')[0]
          }
          else if(xe.properties.providerSettings.providerType=='SapHana'){
              let filterData = ipResponse.body.data.filter(x=> x.privateIP == xe.properties.providerSettings.hostname)
              xe['resourceName'] = filterData.length ? filterData[0].virtualMachine.split('/')[filterData[0].virtualMachine.split('/').length - 1] : null
          }
      }))
      data = data.filter(ex=> this.currentProjectData.resourceData.some(x=> x.resourceName.toLowerCase() == ex.resourceName.toLowerCase()))
      this.providerList=data.map(dt=>{
        dt.groupBy='All';
        dt.checked=true;
        return dt;
      });
      this.filterForm.patchValue({
        provider:this.providerList[0].name
      });
    })
  }

  getTilesData(type) {    
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    const filterSubs=tempSubList.filter(dt=>dt.checked);
    if(!filterSubs.length) {
      this[type].tableData=[];
      this[type].tempData=[];
      this[type].showLoader = false;
    } else {
      this.currentFilter.type=this.filterForm.value.currentFilter;
      let body ={
        "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
        "query": ApplicationMonitoringQueries[type].replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.currentFilter))
      }
      this[type].tableData=[];
      this[type].tempData=[];
      this[type].showLoader = true;
      return this.util.handleRequest('post','a3s_applicationHealthMonitor_runLogAnalyticsQuery',[],body,null,null,true).then(res=>{
        if(!res || !res.body) {
          this[type].showLoader = false;
          return 
        }
        res.body.tables.forEach(dt=>{
          dt.rows.forEach(ds=>{
            this[type].tempData.push(ds);
          });
        })
        this.prepareData(type);
        this[type].showLoader = false;
      },err=>{
        this[type].showLoader = false;
      })
    }
  }

  // checkProviderVal(providerName) {
  //   return this.filterForm.value.provider==providerName;
  // }

  checkProviderVal(providerName) {
    // return this.filterForm.value.provider==providerName;
    return this.providerList && this.providerList.length ? this.providerList.some(e=> e.name.toLowerCase() == providerName.toLowerCase()) ? true : false : false;
  }

  checkSidVal(sidName) {
    return this.filterForm.value.sid&&this.filterForm.value.sid.toLowerCase() == sidName.toLowerCase();
  }


  changeFilter(){
    this.getTilesData('dataSizeDetails');
    this.getTilesData('hanaFileSystems');
  }

  prepareData(type) {
    this[type].tableData=[];
    let unique,filter;
    if(type=='dataSizeDetails') {
      this.dbTenantsList=[];
      unique = this.util.unique(this[type].tempData,['13'])
      filter = unique.filter(e=> this.providerList.some(x=> x.name.toLowerCase() == e[12].toLowerCase()))
      this.sidList = filter.map(e=>e[13])
    }
    else {
      // unique = this.util.unique(this[type].tempData,['9'])
      // filter = unique.filter(e=> this.providerList.some(x=> x.name.toLowerCase() == e[8].toLowerCase()))
      // this.sidList = filter.map(e=>e[9])
    }
    this.filterForm.patchValue({
      sid: this.filterForm.value.sid ? this.filterForm.value.sid : this.sidList[0]
    });
    this[type].tempData.forEach(dt=>{
      let value,sidValue;
      switch (type) {
        case 'dataSizeDetails':
        case 'hanaFileSystems':
          sidValue=dt[dt.length-1]
          value=dt[dt.length-2]
          break;
      
        default:
          break;
      }
      if(this.checkSidVal(sidValue) && this.checkProviderVal(value)) {
        if(type==='hanaFileSystems') {
          dt[3]=Number(dt[3].toFixed(2));
          let data=JSON.parse(dt[7]);
          dt[7]=data[0]>data[data.length-1]?'UP':data[0]<data[data.length-1]?'DOWN':'EQUAL';
          this[type].tableData.push(dt);
        } else if(type=='dataSizeDetails') {
          if(dt[5]){
            dt[5]=dt[5]>1?dt[5]+' GB':(dt[5]*1000)+' MB'
          }
          if(dt[6]){
            dt[6]=dt[6]>1?dt[6]+' GB':(dt[6]*1000)+' MB'
          }
          dt[7]=Number(dt[7].toFixed(2));
          dt[8]=Number(dt[8].toFixed(2));
          if(dt[9]) {
            dt[9]=Number(dt[9].toFixed(0));
          }
          if(!this.dbTenantsList.length) {
            this.dbTenantsList=[{name:'All'}];
          }
          if(!this.dbTenantsList.find(ds=>ds.name.toLowerCase()===dt[1].toLowerCase())) {
            this.dbTenantsList.push({name:dt[1]})
          }
          this[type].tableData.push(dt);
        }
      }
    })

    if(type=='dataSizeDetails'&&this.dbTenantsList.length) {
      this.filterForm.patchValue({
        dbTenants:'All'
      })
    }
  }

  filterTenantData(){
    this.dataSizeDetails.tableData=[];
    if(this.filterForm.value.dbTenants) {
      this.dataSizeDetails.tempData.forEach(dt=>{
        if(this.checkSidVal(dt[dt.length-1]) && this.checkProviderVal(dt[dt.length-2])){
          // if(dt[5]){
          //   dt[5]=dt[5]>1?dt[5]+' GB':(dt[5]*1000)+' MB'
          // }
          // if(dt[6]){
          //   dt[6]=dt[6]>1?dt[6]+' GB':(dt[6]*1000)+' MB'
          // }
          // dt[7]=Number(dt[7].toFixed(2));
          // dt[8]=Number(dt[8].toFixed(2));
          // if(dt[9]) {
          //   dt[9]=Number(dt[9].toFixed(0));
          // }
          if(this.filterForm.value.dbTenants=='All'||dt[1].toLowerCase()===this.filterForm.value.dbTenants.toLowerCase()) {
            this.dataSizeDetails.tableData.push(dt);
          }
        }
      })
    }
  }

  ngOnDestroy() {
    if(this.subscriptionApp) this.subscriptionApp.unsubscribe();
  }

  loadData() {
    this.getTilesData('dataSizeDetails');
    this.getTilesData('hanaFileSystems');
  }

  async refreshData(type){
    switch (type) {
      case 'dataSizeDetails':{
        this.dataSizeDetails.refreshTime = new Date();
        this.getTilesData('dataSizeDetails');
        break;
      }
      case 'hanaFileSystems':{
        this.hanaFileSystems.refreshTime = new Date();
        this.getTilesData('hanaFileSystems');
        break;
      }
      case 'columnDataSize':{
        this.columnDataSize.refreshTime = new Date();
        break;
      }
      case 'memoryStatistics':{
        this.memoryStatistics.refreshTime = new Date();
        break;
      }
    }    
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    this.loadData();
    this.closeFilter()
  }

  openModalForCustom(value,template: TemplateRef<any>,list?:any) {
    list.currentFilter = value;
    this.openModal(template,list);
  }
  
  applyCustomFilter(){
    this.currentFilter=this.selectedData.currentFilter;
    this.loadData();
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 9;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = {currentFilter:this.currentFilter};
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    this.loadData();
    console.log('Filter Object',this.filterObject)
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    // this.getAllData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

}
