<div class="security-recommednation-container">
  <div class="header-container">
    <h2 class="title">Recommendations</h2>
    <!-- <span>{{currentProject?.projectName}}</span> -->
  </div>
 
   <!-- Filter Section -->
   <div class="custom-filter-wrapper flex-custom">
    <div class="custom-filter">
        <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
     
        <div class="filter-dropdown" id="filter-dropdown" >
            <div class="enlist-srch">
                <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                <img src="assets/icons/search.png">
            </div>
            <div class="filter-content">
                <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                    <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                    <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                </div>
                <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                    <p  class="content-14 blur-text">No filter found</p>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-filter">
        <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
        <div class="filter-dropdown" id="filter-dropdown" >
            <div class="enlist-srch">
                <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                <img src="assets/icons/search.png">
            </div>
            <div class="filter-content">
                <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                    <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                    <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                </div>
                <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                    <p  class="content-14 blur-text">No filter found</p>
                </div>
            </div>
           
        </div>
    </div>
    <div class="custom-filter">
        <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
        <div class="filter-dropdown" id="filter-dropdown" >
            <div class="enlist-srch">
                <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                <img src="assets/icons/search.png">
            </div>
            <div class="filter-content">
                <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                    <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                    <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                </div>
                <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                    <p  class="content-14 blur-text">No filter found</p>
                </div>
            </div>
        </div>
    </div>
    <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
        <img src="/assets/common-icons/close-filter.svg" alt="">
        Clear Filter
    </button>
</div>

  <div class="dashboard-body">
    <div class="row">
      <div class="col-md-6 padding-div">
        <div class="graph-div coverage-div">
          <div class="heading">
              <div class="d-flex" style="padding-bottom: 40px;">
                  <div class="about">
                      <h4>Coverage</h4>
                  </div>                
              </div>                
          </div>
         <div class="d-flex coverage-data" style="padding-bottom: 42px;">
           <img src="./assets/security/completed-controls.svg">
           <div class="progress-div">
             <p class="blur-text mb-1"><span>{{coverageResourceData?.controlsStatus?.completedControls}}</span>Completed controls
              <label>{{coverageResourceData?.controlsStatus?.totalControls}} Total</label>
            </p>
             <span class="progress mb-2">
               <span class="progress-bar progress-bar-blue" [style.width]="(coverageResourceData?.controlsStatus?.completedControls/coverageResourceData?.controlsStatus?.totalControls)*100+'%'"></span>
             </span>
           </div>
         </div>
         <div class="d-flex coverage-data" style="padding-bottom: 50px;">
          <img src="./assets/security/completed-recommendations.svg">
          <div class="progress-div">
            <p class="blur-text mb-1"><span>{{coverageResourceData?.assessmentsStatus?.completedAssessments}}</span>Completed recommendations
              <label>{{coverageResourceData?.assessmentsStatus?.totalAssessments}} Total</label>
            </p>
            <span class="progress mb-2">
              <span class="progress-bar progress-bar-blue" [style.width]="coverageResourceData?.assessmentsStatus?.totalAssessments==0?'0%':(coverageResourceData?.assessmentsStatus?.completedAssessments/coverageResourceData?.assessmentsStatus?.totalAssessments)*100+'%'"></span>
            </span>
          </div>
        </div>
          <!-- <div class="loader-gif" *ngIf="coverageResourceData?.showLoader">
              <img src="assets/icons/blue-loader.gif"/>
              <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!coverageResourceData?.data?.length && !coverageResourceData?.showLoader">
              <img src="/assets/health-monitoring/no-data.svg" alt="">
              <p class="text-14-22 bold-600 mt-3">No data found</p>
          </div> -->
      </div>
      </div>
      <div class="col-md-6 padding-div">
        <div class="graph-div coverage-div" style="margin-right: 0px">
          <div class="heading">
              <div class="align-items-baseline d-flex">
                  <div class="about">
                      <h4>Resource Health</h4>
                      <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.resourceHealth)}}</p>
                  </div> 
                  <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('resourceHealth')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                  </div>                  
              </div>                
          </div>
          <!-- <app-doughnut-chart [data]='coverageResourceData' *ngIf="coverageResourceData?.data?.length && !coverageResourceData?.showLoader"></app-doughnut-chart> -->
          <div echarts [loading]='true' [options]="options" *ngIf="coverageResourceData?.data?.length && !coverageResourceData?.showLoader" style="height: 220px;"></div>
          <div class="loader-gif" *ngIf="coverageResourceData?.showLoader">
              <img src="assets/icons/blue-loader.gif"/>
              <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!coverageResourceData?.data?.length && !coverageResourceData?.showLoader">
              <img src="/assets/health-monitoring/no-data.svg" alt="">
              <p class="text-14-22 bold-600">No data found</p>
          </div>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 padding-div">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Recommendations</h4>
                        <div class="pull-right custom-switches d-flex">
                          <label style="font-size: 13px;font-weight: 400;padding-right: 8px;white-space: nowrap;">Group by controls</label>
                          <p style="margin: 0px;margin-top: -5px;">
                          <ui-switch [(ngModel)]="groupByCtrl"></ui-switch>
                      </p>
                      <div style="width: 1px;height: 24px;background: #EEEFF0;margin: 0px 8px;"></div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" style="padding-top: 0px;" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('Recommendations',groupByCtrl?'table1':'table2')"><img src="assets/security/download_icon.svg" alt=""></span>
                        </div> 
                    </div>
                    
                    </div>
                </div>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="groupByCtrl&&secureScoreData?.tableData?.length && !activeRecommData?.showLoader">
              <table class="table" id="table1">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Controls</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','displayNameControl')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Max score</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','max')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Current score</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','current')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Potential score increase</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','PSI')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Unhealthy resources</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','unhealthyResourceCount')">
                          </div>
                      </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Resource health</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','resourceHealthSSD')">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of secureScoreData.tableData">
                        <td [title]="row?.properties?.displayName">{{row?.properties?.displayName | displayTxt}}</td>
                        <td style="width: 1% !important;">{{row?.properties?.score?.max}}</td>
                        <td>
                            <div class="progress-div d-flex">
                                <span style="min-width:30px;max-width: 40px;">{{row?.properties?.score?.current}}</span>
                                <span class="progress w-92 mb-2" style="margin-top: 8px;margin-left: 8px;">
                                    <!-- max-width: 71%;position: absolute;right: 8px; -->
                                    <span class="progress-bar progress-bar-blue" [style.width]="row?.properties?.score?.percentage*100+'%'"></span>
                                </span>
                            </div>
                        </td>
                        <td style="width: 1% !important;">
                          <span style="font-weight: 600;font-size: 14px;">+{{pointsPerc(row?.properties?.score)}}%</span> ({{fixedDecimal(row?.properties?.score?.max-row?.properties?.score?.current)}} points)
                        </td>
                        <td style="width: 1% !important;">
                            <span *ngIf="row?.properties?.unhealthyResourceCount">{{row?.properties?.unhealthyResourceCount}} out of {{row?.properties?.healthyResourceCount+row?.properties?.notApplicableResourceCount+row?.properties?.unhealthyResourceCount}}</span>
                            <span *ngIf="!(row?.properties?.unhealthyResourceCount)">None</span>
                        </td>
                        <td>
                            <div class="progress-div">
                                <span class="progress progress-bar-mutliple w-92 mb-2" style="width: 100% !important;">
                                    <span class="progress-bar progress-bar-healthy" [style.width]="percProgress(row?.properties?.healthyResourceCount,(row?.properties?.healthyResourceCount+row?.properties?.notApplicableResourceCount+row?.properties?.unhealthyResourceCount))+'%'"></span>
                                    <span class="progress-bar progress-bar-unhealthy" [style.width]="percProgress(row?.properties?.unhealthyResourceCount,(row?.properties?.healthyResourceCount+row?.properties?.notApplicableResourceCount+row?.properties?.unhealthyResourceCount))+'%'"></span>
                                </span>
                            </div>
                        </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="!groupByCtrl&&activeRecommData?.tableData?.length && !activeRecommData?.showLoader" >
              <table class="table" id="table2">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Recommendation</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','displayName')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Unhealthy resources</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','unhealthyResources')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource type</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','resourceTypes')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource group</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','resourceGroups')">
                          </div>
                      </th>
                      <th>
                        <div class="flex-custom no-wrap">
                            <span>Resource health</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','resourceHealth')">
                        </div>
                    </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Initiative</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeRecommData','initiatives')">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of activeRecommData.tableData">
                        <td [title]="row?.displayName">{{row?.displayName | displayTxt}}</td>
                        <td>{{filterUnHealthy(row?.totalResources,"Unhealthy")}} of {{row?.totalResources?.length}} {{row?.resourceTypes.join('& ')}}</td>
                        <td>{{row?.resourceTypes.join(', ')}}</td>
                        <td>{{row?.resourceGroups.join(', ')}}</td>
                        <td>
                            <div class="progress-div">
                                <span class="progress progress-bar-mutliple w-92 mb-2" style="width: 100% !important;">
                                    <span class="progress-bar progress-bar-healthy" [style.width]="(filterUnHealthy(row?.totalResources,'Healthy')/(row?.totalResources?.length))*100+'%'"></span>
                                    <span class="progress-bar progress-bar-unhealthy" [style.width]="(filterUnHealthy(row?.totalResources,'Unhealthy')/(row?.totalResources?.length))*100+'%'"></span>
                                </span>
                            </div>
                        </td>
                        <td>{{filterInitiatives(row?.initiatives)}}
                        </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="loader-gif" *ngIf="activeRecommData?.showLoader||secureScoreData?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(groupByCtrl&&!secureScoreData?.tableData?.length && !activeRecommData?.showLoader)||(!groupByCtrl&&!activeRecommData?.tableData?.length && !activeRecommData?.showLoader)">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-16-26 bold-600">There are no recommendations at this time.</p>
            </div>
        </div>
    </div>
    </div>
  </div>
</div>