import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProgressNotificationMessage } from '../@model/model';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient) { }

    url = environment.serverUrl;

    /** private function used in for headers. @return email */
    private get_email(): string {
        let email = "";
        for (let i = 0; sessionStorage.key(i); i++) {
            if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                email = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['username']
            }
        }
        return email;
    }

    /** private function used in for headers. @return token */
    private getToken() {
        return `Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`;;
    }

    /** private variable used for keeping track of live notification in navbar*/
    private all_live_notifications: Array<ProgressNotificationMessage> = [];

    /**
     * return the notification that exist in the ALL_LIVE_NOTIFICATIONS
     * @param id NOTIFICATION ID
     */
    get_notification_if_exist(id): ProgressNotificationMessage {
        let _notification = null;
        for (let _i = 0; _i < this.all_live_notifications.length; _i++) {
            const el = this.all_live_notifications[_i];
            if (el._id == id) {
                _notification = el;
                break;
            }
        }
        return _notification;
    }

    /**private variable to data sharing among siblling components */
    private _progressNotificationMessageSource = new Subject<ProgressNotificationMessage>();
    SourceProgrerssNotifications$ = this._progressNotificationMessageSource.asObservable();

    /**
     * function that sets notifications attributes and share it to all components!!
     * functions saves the notification in the notification!!
     * @param notification NOTIFICATION BODY | TYPE
     */
    send_progress_notification(notification: ProgressNotificationMessage) {

        if (!notification.MessageDate)
            notification.MessageDate = moment(Date.now()).format('YYYY-MM-DD');
        if (!notification.MessageTime)
            notification.MessageTime = moment(Date.now()).format('HH:mm:ss');;

        if (!notification.is_navigation) {
            notification.is_navigation = false;
            notification.navigate_to = window.location.pathname;
        }

        if (!notification.saved_in_backend) notification.saved_in_backend = false;
        if (!notification.is_error_occurred) {
            notification.error_message = "";
            notification.is_error_occurred = false;
        }

        let payload = {
            ...notification,
            jobs_complete: notification['progress']['jobs_complete'],
            total_jobs: notification['progress']['total_jobs'],
            "Email": localStorage.getItem('email'),
            "Name": JSON.parse(localStorage.getItem('UserInfo'))['userName'],
            "MessageDate": moment(Date.now()).format('YYYY-MM-DD'),
            "MessageTime": moment(Date.now()).format('HH:mm:ss'),
        }

        let _index = this.all_live_notifications.findIndex((obj => obj._id == notification._id));
        if (_index >= 0)
            this.all_live_notifications[_index] = notification;
        else this.all_live_notifications.push(notification);

        _index = this.all_live_notifications.findIndex((obj) => obj._id == notification._id);

        this._progressNotificationMessageSource.next(notification);

        // delete payload.progress;
        // saving notification to backend
        // if (this.all_live_notifications.length && !this.all_live_notifications[_index].saved_in_backend) {
        //     return this.http.post<any>(this.url + 'api/sendNotification', payload, {
        //         headers: new HttpHeaders({
        //             'id': localStorage.getItem('id'),
        //             'Authorization': this.getToken()
        //         })
        //     }).subscribe(res => { this.all_live_notifications[_index].saved_in_backend = true; },
        //         err => { this.all_live_notifications[_index].saved_in_backend = true; }); // update it to false!!
        // }
    }

    /**
     * backend API call 
     * @param data payload for notifications
     */
    getLogData(data) {
        data.Email = this.get_email();
        return this.http.post<any>(this.url + "api/requestfromDB", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    /**
     * public method that components use to check all live notifications!
     */
    get_all_live_notifications(): Array<ProgressNotificationMessage> {
        return this.all_live_notifications;
    }
}

