import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { IndianTimeFormat } from 'src/app/pipes/custom.pipe';
import { UtilService } from 'src/app/services/util.service';
import { SecurityBucketState } from 'src/app/state/security-bucket.state';
import { SecurityQueries } from '../../security-queries';

@Component({
  selector: 'app-activities-security-windows',
  templateUrl: './activities-security-windows.component.html',
  styleUrls: ['./activities-security-windows.component.scss']
})
export class ActivitiesSecurityWindowsComponent implements OnInit {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }

  searchKey:any;
  resourceList: any = [];
  datasort: string;
  subsName: any;
  subscriptionList: any=[];
  keyVaultsData: any = {
    type:'keyVaultsData',
    color:['#0B70D4'],
    name:'KeyVault Callers',
    xAxis:[],
    showLegend : true,
    legendData:[],
    data:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  keyVaultsOption: any = {
    color:['#0B70D4'],
    name:'KeyVault Callers',
    xAxis:[],
    showLegend : true,
    legendData:[],
    data:[],
    tableData:[],
    showLoader : true
  };
  refreshedTime: any = {
    activityOperations: new Date(),
    grantingPermissions: new Date(),
    portOpenedT: new Date(),
    keyVaultsOption: new Date(),
    portOpenedAzure: new Date()
  }
  options:any={};
  activityOperations: any = {
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  grantingPermissions: any = {
    type:'grantingPermissions',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  portOpenedT: any = {
    type:'portOpenedT',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  portOpenedAzure: any = {
    type:'portOpenedAzure',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'Port Opened for an Azure Resource',
    xAxis:[],
    yAxis:[],
    showLegend : true,
    legendData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    },
    xAxisFromatVal:'MonthWithDate'
  };
  selectedData: any;
  timeFilterList: any=[];
  timeFilterFlag: boolean=false;
  subscriptionInfra: Subscription;
  currentProject: any;
  @Select(SecurityBucketState.currentProjectDetail) currentProjectDetail$: Observable<any>;
  
  constructor(private util:UtilService,private modalService: NgbModal,private timeFormat:IndianTimeFormat) { }

  async ngOnInit() {
    this.timeFilterList=this.util.getStatic('timeFilterList');
    // this.getBlueprintProject();
    this.subscriptionInfra = this.currentProjectDetail$.subscribe((res) => {
      if(res){
        this.currentProject = _.cloneDeep({...res});//res.currentFilteredProject;
        this.triggerFilter(this.currentProject,'project')
        this.getTableData('activityOperations');
        this.getTableData('grantingPermissions');
        this.getTableData('portOpenedT');
        this.getKeyVaultData();
        this.getPortOpenedAzureData();
      }
    });
  }

  ngOnDestroy() {
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

  filterCheck(value){
    return (this.filterObject.subscriptionList.find(dtl=>dtl.checked&&value.split("/")[2]&&dtl.subscriptionId===(value.split("/")[2])) && this.filterObject.resourceGroupList.find(dtl=>dtl.checked&&value.split("/")[4]&&dtl.resourceGroupName.toLowerCase()===(value.split("/")[4]).toLowerCase()) && this.filterObject.resourceList.find(dtl=>dtl.checked&&(value.split("/")[value.split("/").length-1])&&dtl.resourceName.toLowerCase()===(value.split("/")[value.split("/").length-1]).toLowerCase()))||(this.filterObject.subscriptionList.find(dtl=>dtl.checked&&dtl.subscriptionId===(value.split("/")[2])) && !value.split("/")[4])
  }

  filterTableData(type){
    if(type==='activityOperations') {
      this[type].tableData=this[type].tempTableData.filter((dt)=>this.filterCheck(dt[2]));
    } else if(type==='keyVaultsData') {
      this[type].tableData=this[type].tempTableData.filter((dt)=>this.filterCheck(dt[4]));
    }else if(type==='portOpenedT') {
      this[type].tableData=this[type].tempTableData.filter((dt)=>this.filterCheck(dt[9]));
    }  else {
      this[type].tableData=this[type].tempTableData;
    }
  }
  
  getTableData(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": this.getTableQuery(type)
    }
    this[type].tempTableData=[];
    this[type].showLoader=true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this[type].showLoader=false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempTableData.push(ds);
        });
      })
      this.filterTableData(type);
    },err=>{
      this[type].showLoader=false;
    })
  }

  getTableQuery(type){
    switch(type){
      case 'activityOperations':
        return SecurityQueries.activityOperations.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.activityOperations.currentFilter))//"AzureActivity | summarize Count=count() by OperationName, ResourceGroup, _ResourceId | sort by Count desc nulls last "
      case 'grantingPermissions':
        return SecurityQueries.grantingPermissions.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.grantingPermissions.currentFilter))//"AzureActivity | summarize StartTime = min(TimeGenerated), EndTime = max(TimeGenerated) by Caller, CallerIpAddress | order by StartTime desc "
      case 'portOpenedT':
        return SecurityQueries.portOpenedT.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.portOpenedT.currentFilter))//"AzureActivity   | where ActivityStatusValue == 'Accepted'  | extend publicIPAddress_ = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).ipAddress)    | extend publicIPAddressVersion_ = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).publicIPAddressVersion)    | extend publicIPAllocationMethod_ = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).publicIPAllocationMethod)  | extend access = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).access)    | extend description = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).description)    | extend destinationPortRange = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).destinationPortRange)    | extend direction = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).direction)    | extend protocol = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).protocol)    | extend sourcePortRange = tostring(parse_json(tostring(parse_json(tostring(parse_json(Properties).responseBody)).properties)).sourcePortRange)    | summarize StartTime = min(TimeGenerated), EndTime = max(TimeGenerated), ResourceIds = makeset(ResourceId) by Caller, CallerIpAddress, Resource, ResourceGroup,    ActivityStatusValue, ActivitySubstatus, SubscriptionId, access, description, destinationPortRange, direction, protocol, sourcePortRange     | extend timestamp = StartTime, AccountCustomEntity = Caller, IPCustomEntity = CallerIpAddress   | project timestamp, Resource, ResourceGroup,CallerIpAddress, Caller, ResourceIds "
      case 'keyVaultsData':
        return SecurityQueries.keyVaultsDataForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.keyVaultsData.currentFilter))//"AzureDiagnostics | where ResourceProvider =='MICROSOFT.KEYVAULT' | summarize count() by CallerIPAddress, Resource, OperationName, httpStatusCode_d "
    }
  }

  getQuery(type){
    switch(type){
      case 'keyVaultsOption':
        return "AzureDiagnostics | where ResourceProvider =='MICROSOFT.KEYVAULT' | summarize count() by CallerIPAddress, Resource | take 10 "   
      case 'portOpenedAzure':
        return "AzureActivity | make-series dcount(ResourceId)  default=0 on EventSubmissionTimestamp in range(ago(7d), now(), 1d) by Caller | extend AccountCustomEntity = Caller | project-away AccountCustomEntity | render timechart "   
    }
  }

  filterKeyVaultData(){
    this.keyVaultsData.data=[];
    this.keyVaultsData.xAxis=[];
      this.keyVaultsData.tempData.forEach(ds=>{
        if(this.filterCheck(ds[2])){
          this.keyVaultsData.xAxis.push(ds[0])
          this.keyVaultsData.data.push(ds[3])
        }
      });
    this.filterObject.subscriptionList.forEach(dt=>{
      this.keyVaultsData.legendData.push({icon:'circle',name:dt.displayName})
    })
    this.setKeyVaultsOptions();
  }

  getKeyVaultData(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.keyVaultsData.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.keyVaultsData.currentFilter))//this.getQuery('keyVaultsOption')
    }
    this.keyVaultsData.showLoader = true;
    this.keyVaultsData.xAxis = []
    this.keyVaultsData.legendData = []
    this.keyVaultsData.data = [];   
    this.keyVaultsData.tempData = [];   
    this.getTableData('keyVaultsData'); 
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.keyVaultsData.showLoader = false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.keyVaultsData.tempData.push(ds);
        });
      })
      this.filterKeyVaultData();
    },err=>{
      this.keyVaultsData.showLoader = false;
    })
  }

  // Charts Options
  setKeyVaultsOptions(){
    let xAxisData = this.keyVaultsData.xAxis;
    let legendData = this.keyVaultsData.legendData;
    let series = [];
    let  dObj = {
      name: this.keyVaultsData.legendData[0].name,
      type: 'bar',
      barWidth: 70,
      stack: 'total',
      emphasis: {
          focus: 'series'
      },
      data: this.keyVaultsData.data
    };
    series.push(dObj);
    console.log('series',series)
    this.keyVaultsOption = {
      color: ['#0B70D4','#62C2FB','#FF5959','#656BFD'],
      tooltip: {
        // trigger: 'axis',
        // axisPointer: {           
        //     type: 'shadow'        
        // }
      },
      legend: {
          data: legendData || [],
          bottom: 10,
          left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        bottom: 62,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: xAxisData || [],
        axisTick: {
          show: false
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            return value//(value.length > 10 ? (value.slice(0,10)+"...") : value )
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#333',
          padding: [5, 0, 0, 0],
          fontSize: 10, 
          formatter: function (params) {
            return params
          },
        },
      },
      series: series
    };
  }

  getPortOpenedAzureData(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.portOpenedAzure.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.portOpenedAzure.currentFilter))//this.getQuery('portOpenedAzure')
    }
    this.portOpenedAzure.showLoader = true
    this.portOpenedAzure.legendData = []
    this.portOpenedAzure.series = []
    this.portOpenedAzure.xAxis = []
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.portOpenedAzure.showLoader = false
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach((e,index1)=>{
          let obj = {
            name : e[0],
            type : 'line',
            data: JSON.parse(e[1])
          }
          this.portOpenedAzure.legendData.push({icon:'circle',name:e[0]});
          this.portOpenedAzure.series.push(obj);
          var timeArray = JSON.parse(e[2]);
          let sorted : any = timeArray.sort((a,b)=> {
            var d1 = new Date(b);
            var d2 = new Date(a);
            return d2 > d1 ? 1 : 0;
          })
        
          sorted.forEach((x)=>{
            if(index1 == 0){
              this.portOpenedAzure.xAxis.push(moment(x).format('MMM DD'))//this.timeFormat.transform(x))
            }
          })
        })
      })
    },err=>{
      this.portOpenedAzure.showLoader = false
    })
  }

  getJsonData(rowData){
    if(rowData){
      try {
        return JSON.parse(rowData).join(', ');
      } catch {
        return ''
      }
    }else{
      return ''
    }
  }

  openPopup(template?: TemplateRef<any>) {
    this.modalService.open(template);
  }

  downloadDirectTableView(name,tableId){
    let table=document.getElementById(tableId);
    this.downloadFile(name,table)
  }

  downloadFile(name,tableId){
    this.util.generateCSVForTableData(name,tableId);
  }

  // closeModal() {
  //   this.modalService.dismissAll();
  // }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    list.currentFilter.type = value
    switch(list.type){
      case 'portOpenedAzure' :this.getPortOpenedAzureData();break;
      case 'keyVaultsData' :this.getKeyVaultData();break;
      case 'grantingPermissions' :this.getTableData('grantingPermissions');break;
      case 'portOpenedT' :this.getTableData('portOpenedT');break;
      case 'activityOperations' :this.getTableData('activityOperations');break;
    }
    this.closeFilter()
  }
  
  applyCustomFilter(){
    this.selectedData.currentFilter.type = 'custom';
    switch(this.selectedData.type){
      case 'portOpenedAzure' : this.portOpenedAzure = this.selectedData;this.getPortOpenedAzureData();break;
      case 'keyVaultsData' : this.keyVaultsData = this.selectedData;this.getKeyVaultData();break;
      case 'grantingPermissions' : this.grantingPermissions = this.selectedData;this.getTableData('grantingPermissions');break;
      case 'portOpenedT' : this.portOpenedT = this.selectedData;this.getTableData('portOpenedT');break;
      case 'activityOperations' : this.activityOperations = this.selectedData;this.getTableData('activityOperations');break;
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 9;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if(!this.timeFilterFlag){
    this.closeFilter();
    }
    this.timeFilterFlag=false;
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.currentFilter.type);
    return findObj&&findObj.name?findObj.name:''
  }


  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    // this.getAllData()
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    console.log('Filter Object',this.filterObject)
    this.filterTableData('activityOperations');
    this.filterKeyVaultData();
    this.filterTableData('keyVaultsData');
    this.filterTableData('portOpenedT');
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    // this.getAllData();
    this.filterTableData('activityOperations');
    this.filterKeyVaultData();
    this.filterTableData('keyVaultsData');
    this.filterTableData('portOpenedT');
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

  async refreshData(type){
    switch (type) {
      case 'activityOperations':{
        this.refreshedTime.activityOperations = new Date();
        this.getTableData('activityOperations');
        break;
      }
      case 'grantingPermissions':{
        this.refreshedTime.grantingPermissions = new Date();
        this.getTableData('grantingPermissions');
        break;
      }
      case 'portOpenedT':{
        this.refreshedTime.portOpenedT = new Date();
        this.getTableData('portOpenedT');
        break;
      }
      case 'keyVaultsOption':{
        this.refreshedTime.keyVaultsOption = new Date();
        this.getKeyVaultData();
        break;
      }
      case 'portOpenedAzure':{
        this.refreshedTime.portOpenedAzure = new Date();
        this.getPortOpenedAzureData();
        break;
      }
    }
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

}
