<div class="introduction">
   

        <p class="heading" id="0">Overview </p>
        <ul>
            <li>Count of directory roles.  </li>
            <img src="assets\user-manual\governance\Row.svg" >
            <figcaption class="caption"> count of directory roles </figcaption>
            <li>List of users. </li>
            <img src="assets\user-manual\governance\Tile(2).svg" >
            <figcaption class="caption">List of users </figcaption>
            <li>List of groups.</li>
            <img src="assets\user-manual\governance\Tile(3).svg" >
            <figcaption class="caption"> List of groups </figcaption>
          
        </ul>
      
    
       
     
       
           
           
             
</div>
