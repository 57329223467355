 <div class="introduction">
          
          <p class="heading" id="1">Data Estate Scan   </p>
          
            <ol>
                <li>Click <b>Create new project. </b></li>
                <li>Provide Project name and source details – you can choose either Azure Synapse workspace and/or data lake storage. </li>
                <img src="assets\user-manual\data-&-AI\data-governance\Data-Governance(1).svg" >
                <figcaption class="caption">Data governance - select source </figcaption>
                <img src="assets\user-manual\data-&-AI\data-governance\Data-Governance(2).svg" >
                <figcaption class="caption">Data governance - select source </figcaption>
                <li>Click <b>Register.</b> </li>
                <li>Select data estates that need to be scanned. </li>
                <img src="assets\user-manual\data-&-AI\data-governance\Data-Governance(3).svg" >
                <figcaption class="caption">Data governance - select scope </figcaption>
                <li>Click <b>Scan.</b> </li>           
                <li>Upon successful completion, results of the scan will be displayed. </li>
                <img src="assets\user-manual\data-&-AI\data-governance\Data-Governance(4).svg" >
                <figcaption class="caption"> Data governance – output </figcaption>
                <li>Click <b>Finish</b> or <b>View in A3S BI dashboard.</b> </li>
                <img src="assets\user-manual\data-&-AI\data-governance\Data-Governance(5).svg" >
                <figcaption class="caption">Data governance - project summary </figcaption>
                <img src="assets\user-manual\data-&-AI\data-governance\Data-Governance(6).svg" >
                <figcaption class="caption">Data governance – A3S dashboard </figcaption>
              
           
            </ol>
 </div>
