<div class="introduction">
    <p class="heading">DataOps</p>
     
            
        <ol>
            <li>Click <b>DataOps -> Workspace Deployment</b>  </li>
            <img src="assets\user-manual\data-&-AI\DataOps.svg" >
            <figcaption class="caption">DataOps – Choose deployment </figcaption>
            <li>Fill the <b>PAT token</b> and click <b>Authorize</b> . </li>
            <img src="assets\user-manual\data-&-AI\DataOps(1).svg" >
            <figcaption class="caption">DataOps– Generate PAT token </figcaption>
            <li>Fill in the details and then click  <b>Next </b></li>
            <img src="assets\user-manual\data-&-AI\DataOps(2).svg" >
            <figcaption class="caption">DataOps – Source details </figcaption>
            <li>Then select the artifacts and click Next  </li>
            <img src="assets\user-manual\data-&-AI\DataOps(3).svg" >
            <figcaption class="caption">DataOps – Select artifacts  </figcaption>
            <li>The artifacts will now be deployed   </li>
            <img src="assets\user-manual\data-&-AI\DataOps(4).svg" >
            <figcaption class="caption">DataOps – Deployment success </figcaption>
            <li>Project summary page    </li>
            <img src="assets\user-manual\data-&-AI\DataOps(5).svg" >
            <figcaption class="caption">DataOps: Summary </figcaption>
            <li>View in A3S dashboard for detailed summary  </li>
            <img src="assets\user-manual\data-&-AI\Dashboard.svg" >
            <figcaption class="caption">DataOps: Summary </figcaption>
          
            
             
        </ol>
</div>