import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a3s-support',
  templateUrl: './a3s-support.component.html',
  styleUrls: ['./a3s-support.component.scss']
})
export class A3sSupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
