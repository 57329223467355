<div class="introduction">
          <p class="heading" id="0">Data analytics  </p>
          <label>Following analytics use cases are part of this offering: </label>
            <ol>
                <li>Demand forecasting.  </li>
                <li>Sales Predictions. </li>
                <li>Revenue Predictions. </li>
                <li>Knowledge Mining. </li>
                <li>Modern Finance. </li>
                <li>Product Seasonality Classifier. </li>
                <li>Text Analytics.  </li>
                <li>Topic Modeling. </li>
                <li>Image Classification. </li>
           
            </ol>
 </div>
 
 
 
 
 
 
 

