<div class="introduction">
  <p class="heading" id="5">Product Seasonality Classifier </p>

  <p>This use case aims to provide a predictable cyclic variation of given data depending on the time for a particular year.</p>
    <ol>
        <li>Click <b>Data analytics -> Create project -> Seasonality classifier. </b></li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(1).svg" >
        <figcaption class="caption">Seasonality classifier - use case </figcaption>
        <li>Fill in source details to connect to the data source. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(2).svg" >
        <figcaption class="caption">Seasonality classifier – source detail </figcaption>
        <li>Prepare data by selecting <b>Table</b> and <b>Train ratio</b>  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(3).svg" >
        <figcaption class="caption">Seasonality classifier - prepare data  </figcaption>
        <li>Verify the training data. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(4).svg" >
        <figcaption class="caption"> Seasonality classifier - training data  </figcaption>
        <li>Click <b>Finish</b> or View in <b>A3S BI Dashboard.</b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(5).svg" >
        <figcaption class="caption">Seasonality classifier - output </figcaption>
        <li>Project Summary page. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(7).svg" >
        <figcaption class="caption"> Seasonality classifier - project summary </figcaption>
        <li>View in A3S BI Dashboard. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier.svg" >
        <figcaption class="caption"> Seasonality classifier - BI dashboard </figcaption>
        <li>In case of error/failure, Failure message will be displayed. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(6).svg" >
        <figcaption class="caption">Seasonality classifier - failure  </figcaption>
   
    </ol>
</div>
