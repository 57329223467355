
<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':synapseAnalyticsmodel.SADedicatedPoolShow||synapseAnalyticsmodel.SAApacheSprakPoolShow||synapseAnalyticsmodel.SAAzureADTenantShow}">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Synapse Workspace</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="synapseAnalyticsmodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 8px;"><i class="fa fa-trash" (click)="deleteNewResourceGroup(item,index)"></i></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 25px;"><img src="/assets/Infra/blueprint/palette/edit.svg" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(null)">Create new
                    </p>
                </div>
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Workspace details</label>
                    <label for="workspaceName" class="control-label">Workspace name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.workspaceMaxLen||inputError?.workspaceNameCheck||inputError?.workspaceStartCheck||inputError?.workspaceEndCheck||inputError?.workspacePartText||inputError?.workspaceNameUnqiue" id="workspaceName" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceName" placeholder="" name="workspaceName" (keyup)="validateWorksapceName($event,50)" (blur)="checkSynapseWorkspaceName($event)">
                    <p class="text-danger" *ngIf="inputError?.workspaceMaxLen">Workspace name must be between 1 and 50 characters long.</p>
                    <p class="text-danger" *ngIf="inputError?.workspaceNameCheck">Workspace name must contain only lowercase letters or numbers or hyphens.</p>
                    <p class="text-danger" *ngIf="inputError?.workspaceStartCheck">Workspace name must start with a letter or a number.</p>
                    <p class="text-danger" *ngIf="inputError?.workspaceEndCheck">Workspace name must end with a letter or a number.</p>
                    <p class="text-danger" *ngIf="inputError?.workspacePartText">Workspace name must not contain '-ondemand' word.</p>
                    <p class="text-danger" *ngIf="inputError?.workspaceNameUnqiue">Workspace name must be unique.</p>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="dataLakeStorageGen" class="control-label">Select Data Lake Storage Gen2<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This account will be the primary storage account for the workspace, 
                        holding catalog data and metadata associated with the workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="dataLakeStorageGen" [value]="'subscriptions'" ([ngModel])="synapseAnalyticsmodel.resourceData.dataLakeStorageGen" [checked]="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'" (change)="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=$event.target.value;"><span>From subscription</span></label>
                    <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="dataLakeStorageGen" [value]="'manually'" ([ngModel])="synapseAnalyticsmodel.resourceData.dataLakeStorageGen" [checked]="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'" (change)="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=$event.target.value;"><span>Manually via URL</span></label>                    
                </div>                     
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="accountName" class="control-label">Account name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only Data Lake Storage Gen2 accounts with Hierarchical 
                        Name Space (HNS) enabled are listed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'">
                    <ng-select [items]="storageAccountList" bindLabel="name" [(ngModel)]="synapseAnalyticsmodel.resourceData.storageAccount" name="accountName" (change)="changeStorageAccountSA()" placeholder="" [clearable]="false">
                    </ng-select>
                </div>  
                <div class="input-box" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'">
                    <input type="text" class="form-control" [class.border-danger]="!inputError?.accountNameCheck||inputError?.accountNameValid" id="accountName" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.accountNameVal" placeholder="" name="accountName" (keyup)="validateAccountNameSA($event,3,24)">
                    <span class="dns-helper-label">.dfs.core.windows.net</span>
                    <p class="text-danger" *ngIf="!inputError?.accountNameCheck">There was no storage account found with this name.</p>
                    <p class="text-danger" *ngIf="inputError?.accountNameValid">Account name can contain only lowercase letters 
                        and numbers. Name must be between 3 and 24 characters.</p>
                </div>
                <div class="add-another-tag" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'">
                    <ng-template #createSAAccount>
                        <div class="form-group">
                            <div class="label-div">
                                <label class="control-label text-weight">Data Lake Storage Gen2 account</label>
                                <label for="nameSAAccount" class="control-label">Name<span class="mandatory">*</span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName||inputError?.duplicateSa" id="nameSAAccount" autocomplete="off" [(ngModel)]="createSAAccObj.name" placeholder="" name="nameSAAccount" (keyup)="storageMLValidation($event,3,24)">
                                <p class="text-danger" *ngIf="inputError?.storageAccountName">Storage account name must be between 3 and 24 characters in length and use numbers and lower-case letters only.</p>
                                <p class="text-danger" *ngIf="inputError?.duplicateSa">This name already exists.</p>
                                </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createSAAccObj.name||inputError?.storageAccountName||inputError?.duplicateSa" (click)="saveSubSideSheet('createSAAccount');p.hide();">Save</button>
                            <button class="btn btn-outline" (click)="p.hide();">Cancel</button>
                        </div>
                    </ng-template> 
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createSAAccount"
                    triggers="" placement="bottom" containerClass="custom-popover" #p="bs-popover" [outsideClick]="true" (click)="closeOtherPop(undefined,popFS);openPop(p,'createSAAccount');">Create new</p> 
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="fileSystemName" class="control-label">File system name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'">
                    <ng-select [items]="fileSystemNameList" bindLabel="name" [(ngModel)]="synapseAnalyticsmodel.resourceData.fileSystemName" name="fileSystemName" placeholder="" [clearable]="false">
                    </ng-select>
                </div>  
                <div class="input-box" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.fileNameCheck||inputError?.fileNameLen||inputError?.fileNameEndCheck||inputError?.fileNameStartCheck||inputError?.fileNameHypensCheck" id="fileSystemName" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.fileSystemNameVal" placeholder="" name="fileSystemName" (keyup)="validateFileSystemName($event,3,63)">
                    <p class="text-danger" *ngIf="inputError?.fileNameCheck">File system name must contain only lowercase letters, numbers, or hyphens.</p>
                    <p class="text-danger" *ngIf="inputError?.fileNameLen">File system name must be between 3 and 63 characters long.</p>
                    <p class="text-danger" *ngIf="inputError?.fileNameEndCheck">File system name must end with a letter or a number.</p>
                    <p class="text-danger" *ngIf="inputError?.fileNameStartCheck">File system name must start with a letter or a number.</p>
                    <p class="text-danger" *ngIf="inputError?.fileNameHypensCheck">File system name must not contain two consecutive hyphens.</p>
                </div>
                <div class="add-another-tag" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'">
                    <ng-template #createSAFile>
                        <div class="form-group">
                            <div class="label-div">
                                <label class="control-label text-weight">Data Lake Storage Gen2 file system</label>
                                <label for="nameFile" class="control-label">Name<span class="mandatory">*</span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.fileNameValidation||inputError?.fileNameUnqiue||inputError?.fileNameEnd||inputError?.fileNameStart||inputError?.fileNameHypens||inputError?.fileNameLenCheck" id="nameFile" autocomplete="off" [(ngModel)]="createSAFileObj.name" placeholder="" name="nameFile" (keyup)="validateFileSystemNamePop($event,3,63)">
                                <p class="text-danger" *ngIf="inputError?.fileNameValidation">File system name must contain only lowercase letters, numbers, or hyphens.</p>
                                <p class="text-danger" *ngIf="inputError?.fileNameEnd">File system name must end with a letter or a number.</p>
                                <p class="text-danger" *ngIf="inputError?.fileNameUnqiue">File system name already exist.</p>
                                <p class="text-danger" *ngIf="inputError?.fileNameStart">File system name must start with a letter or a number.</p>
                                <p class="text-danger" *ngIf="inputError?.fileNameHypens">File system name must not contain two consecutive hyphens.</p>
                                <p class="text-danger" *ngIf="inputError?.fileNameLenCheck">File system name must be between 3 and 63 characters long.</p>
                                </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createSAFileObj.name||inputError?.fileNameValidation||inputError?.fileNameUnqiue||inputError?.fileNameEnd||inputError?.fileNameStart||inputError?.fileNameHypens||inputError?.fileNameLenCheck" (click)="saveSubSideSheet('createSAFileObj');popFS.hide();">Save</button>
                            <button class="btn btn-outline" (click)="popFS.hide();">Cancel</button>
                        </div>
                    </ng-template> 
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createSAFile"
                    triggers="" placement="bottom" containerClass="custom-popover" #popFS="bs-popover" [outsideClick]="true" (click)="closeOtherPop(undefined,p);openPop(popFS,'createSAFile');">Create new</p> 
                </div>  
            </div>
            <div class="form-group">
            <div class="label-div d-flex" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 8px 4px 0px !important;color: #703501;">
                    Additional configuration is required. After you create your workspace, perform these tasks:
<br> &#8226; Assign other users to the <span class="text-weight-12">Contributor</span> role on workspace
<br> &#8226; Assign other users the appropriate Synapse RBAC roles using Synapse Studio
<span *ngIf="showMore">
<br><br>Contact an <span class="text-weight-12">Owner</span> of the storage account, and ask them to perform the following tasks:
<br> &#8226; Assign the workspace MSI to the <span class="text-weight-12">Storage Blob Data Contributor</span> role on the storage account
<br> &#8226; Assign you and other users to the <span class="text-weight-12">Storage Blob Data Contributor</span> role on the storage account
<br>
Once these tasks are complete, the following Spark features can be used: (1) Spark Library Management, (2) Read and Write data to SQL pool databases via the Spark SQL connector, and (3) Create Spark databases and tables</span>
<div class="add-another-tag">
<p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="showMore=!showMore"><span *ngIf="!showMore">Show more<img style="padding-left: 4px;" src="/assets/Infra/blueprint/arrow_down_show_more.svg" alt=""></span>
    <span *ngIf="showMore">Show less<img style="padding-left: 4px;" src="/assets/Infra/blueprint/arrow_up_show_less.svg" alt=""></span></p> 
</div></label>
            </div>
        </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="assignUser" class="control-label">Assign users Storage Blob Contributor access 
                        to Data Lake Storage Gen2</label>
                </div>
                <div class="input-box">
                    <ng-select [items]="assignUserList" [multiple]="true" bindLabel="displayName" [closeOnSelect]="false" [(ngModel)]="synapseAnalyticsmodel.resourceData.assignUser" name="assignUser" placeholder="" [clearable]="false">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div *ngIf="synapseAnalyticsmodel.resourceData.assignUser.length == 1" class="ng-values-list">
                                <div class="ng-value" *ngFor="let item of items | slice:0:1">
                                <span class="ng-value-icon right" (click)="clear(item)">×</span>  
                                <span class="ng-value-label">{{item.displayName}} - {{item.userPrincipalName}}</span>                                    
                                </div>
                            </div>
                            <div *ngIf="synapseAnalyticsmodel.resourceData.assignUser.length > 1" class="ng-summary-list">
                                <div class="ng-value">
                                <span class="ng-value-label">{{ synapseAnalyticsmodel.resourceData.assignUser.length }} Selected</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.displayName}} - {{item.userPrincipalName}}
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="assignApplication" class="control-label">Assign application Storage Blob Contributor 
                        access to Data Lake Storage Gen2</label>
                </div>
                <div class="input-box">
                    <ng-select [items]="assignApplicationList" [multiple]="true" bindLabel="displayName" [closeOnSelect]="false" [(ngModel)]="synapseAnalyticsmodel.resourceData.assignApplication" name="assignApplication" placeholder="" [clearable]="false">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div *ngIf="synapseAnalyticsmodel.resourceData.assignApplication.length == 1" class="ng-values-list">
                                <div class="ng-value" *ngFor="let item of items | slice:0:1">
                                <span class="ng-value-icon right" (click)="clear(item)">×</span>  
                                <span class="ng-value-label">{{item.displayName}}</span>                                    
                                </div>
                            </div>
                            <div *ngIf="synapseAnalyticsmodel.resourceData.assignApplication.length > 1" class="ng-summary-list">
                                <div class="ng-value">
                                <span class="ng-value-label">{{ synapseAnalyticsmodel.resourceData.assignApplication.length }} Selected</span>
                                </div>
                            </div>
                            </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.displayName}}
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Security</label>
                    <label class="control-label " style="color: #3E4852;">If you don't provide a password, one will be 
                        automatically generated. You can change 
                        the password later.</label>
                    <label for="adminUsername" class="control-label ">Admin username<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.adminStartValid||inputError?.adminLenValid||inputError?.adminValidChar||inputError?.adminReservValid" id="adminUsername" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.adminUsername" placeholder="" name="adminUsername" (keyup)="validateAdminUsername($event,128)">
                    <p class="text-danger" *ngIf="inputError?.adminStartValid">Your login name must not start with numbers or symbols</p>
                    <p class="text-danger" *ngIf="inputError?.adminReservValid">Your login name must not contain a SQL Identifier or a typical 
                        system name (like admin, administrator, sa, root, dbmanager, 
                        loginmanager, etc.) or a built-in database user or role (like dbo, guest, public, etc.)</p>
                    <p class="text-danger" *ngIf="inputError?.adminValidChar">Your login name must not include non-alphanumeric characters.</p>
                    <p class="text-danger" *ngIf="inputError?.adminLenValid">Your login name must be between 1 and 128 characters long.</p>
                </div>             
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="adminPassword" class="control-label ">Password</label>
                </div>
                <div class="input-box">
                    <input type="password" class="form-control" [class.border-danger]="inputError?.adminPLenValid||inputError?.adminPValid||inputError?.adminPNameValid" id="adminPassword" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.adminPassword" placeholder="" name="adminPassword" (keyup)="validateAdminPassword($event,8,128)">
                    <p class="text-danger" *ngIf="inputError?.adminPLenValid">Your password must be between 8 and 128 characters long.</p>
                    <p class="text-danger" *ngIf="inputError?.adminPValid">Your password must contain characters from three of the following 
                        categories – English uppercase letters, English lowercase letters, 
                        numbers (0-9), and non-alphanumeric characters (!, $, #, %, etc.).</p>
                    <p class="text-danger" *ngIf="inputError?.adminPNameValid">Your password cannot contain all or part of the login name. Part of a login name is defined as three or more consecutive alphanumeric characters.</p>
                </div>             
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="adminConfirm" class="control-label ">Confirm password</label>
                </div>
                <div class="input-box">
                    <input type="password" class="form-control" [class.border-danger]="inputError?.adminPConfirmValid" id="adminConfirm" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.adminConfirm" placeholder="" name="adminConfirm" (keyup)="validateAdminConfrimPassword($event)">
                    <p class="text-danger" *ngIf="inputError?.adminPConfirmValid">Password and confirm password must match.</p>
                </div>             
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="dedicatedSQLPool" class="control-label">Dedicated SQL pool</label>
                </div>
                <div class="d-flex" *ngFor="let item of synapseAnalyticsmodel.resourceData.dedicatedSQLPool;let i = index">
                    <div class="label-div w-92">
                        <label class="control-label"><span class="text-weight">{{item?.name}}</span><br><span>{{item?.performanceLevel}}</span></label>
                        <!-- <label class="control-label"></label> -->
                    </div>
                        <span><img src="/assets/icons/edit.png" class="p-blue-btn" (click)="editDedicateSqlPool(i)"></span>
                        <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" class="p-blue-btn" (click)="deleteDedicateSqlPool(i)"></span>
                </div>  
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewDSP();">Create new</p> 
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="apacheSparkPool" class="control-label">Apache spark pool</label>
                </div>
                <div class="d-flex" *ngFor="let item of synapseAnalyticsmodel.resourceData.apacheSparkPool;let i = index">
                    <!-- <ng-select [items]="apacheSparkPoolList" bindLabel="name" [(ngModel)]="synapseAnalyticsmodel.resourceData.apacheSparkPool" name="apacheSparkPool" placeholder="" [clearable]="false">
                    </ng-select> -->
                    <div class="label-div w-92">
                        <label class="control-label"><span class="text-weight">{{item?.name}}</span><br><span>{{item?.nodeSize?.name}} - {{item?.numberNodes}} nodes</span></label>
                    </div>
                        <span><img src="/assets/icons/edit.png" class="p-blue-btn" (click)="editApacheSparkPool(i)"></span>
                        <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" class="p-blue-btn" (click)="deleteApacheSparkPool(i)"></span>
                </div>  
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewASP();">Create new</p> 
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label ">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="synapseAnalyticsmodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div>
                
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
        <div *ngIf="showView">
        <div class="form-group">
            <div class="label-div">
                <label for="managedResourceGroup" class="control-label ">Managed resource group<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Managed resource group is a container that holds ancillary resources 
                    created by Azure Synapse Analytics for your workspace. By default,
                    a managed resource group is created for you when your workspace is 
                    created. Optionally, you can specify the name of the resource group that 
                    will be created by Azure Synapse Analytics to satisfy your organization’s 
                    resource group name policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.managedLenValid||inputError?.managedValid||inputError?.managedPermValid||inputError?.managedDupValid" id="managedResourceGroup" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.managedResourceGroup" placeholder="" name="managedResourceGroup" (keyup)="validateManagedResourceGroup($event,90)">
                <p class="text-danger" *ngIf="inputError?.managedLenValid">Managed resource group names only allow up to 90 characters.</p>
                <p class="text-danger" *ngIf="inputError?.managedValid">Managed resource group names only allow alphanumeric characters, 
                    periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                <p class="text-danger" *ngIf="inputError?.managedPermValid">You do not have permissions to create a resource group in the 
                    selected subscription Dynapt Enterprise Subscription.</p>
                <p class="text-danger" *ngIf="inputError?.managedDupValid">Managed resource group name must be unique in the selected subscription.</p>
            </div>             
        </div>
        <div class="form-group">
            <div class="input-box">
                <label class="col-sm-12 radio-label"  style="padding-left: 0px !important; margin-bottom: 24px !important;"><input type="checkbox" name="allowPipelines" id="allowPipelines" [(ngModel)]="synapseAnalyticsmodel.resourceData.allowPipelines" ><span>Allow pipelines (running as workspace's 
                    system assigned identity) to access SQL pools.<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="CONTROL permissions to SQL pools will be 
                    granted to the system assigned identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>           
        </div>
        <div class="form-group">
            <div class="label-div"><label class="control-label text-weight">Workspace encryption</label></div>
            <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;margin-bottom: 24px !important;">
                <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 0px !important;color: #703501;">
                    Double encryption configuration cannot be changed after opting into using a customer-managed key at the time of workspace creation.</label>
            </div>
            <div class="label-div">
                <label for="doubleEncryp" class="control-label ">Double encryption using a customer-managed
                    key<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose to encrypt all data at rest in the workspace 
                        with a key managed by you (customer-managed key).
                        This will provide double encryption with encryption at 
                        the infrastructure layer that uses platform-managed keys." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="doubleEncryp" [value]="'enable'" ([ngModel])="synapseAnalyticsmodel.resourceData.doubleEncryp" [checked]="synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'" (change)="synapseAnalyticsmodel.resourceData.doubleEncryp=$event.target.value;"><span>Enable</span></label>
                <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="doubleEncryp" [value]="'disable'" ([ngModel])="synapseAnalyticsmodel.resourceData.doubleEncryp" [checked]="synapseAnalyticsmodel.resourceData.doubleEncryp=='disable'" (change)="synapseAnalyticsmodel.resourceData.doubleEncryp=$event.target.value;"><span>Disable</span></label>                    
            </div>                     
        </div>
        <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'">
            <div class="label-div">
                <label for="encryptionKey" class="control-label ">Encryption key<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can use your own key by selecting an existing 
                    key or creating a new one in Key Vault." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-5 radio-label" style="padding-left: 0px !important;"><input type="radio" name="encryptionKey" [value]="'key'" ([ngModel])="synapseAnalyticsmodel.resourceData.encryptionKey" [checked]="synapseAnalyticsmodel.resourceData.encryptionKey=='key'" (change)="synapseAnalyticsmodel.resourceData.encryptionKey=$event.target.value;"><span>Select a key</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="encryptionKey" [value]="'identifier'" ([ngModel])="synapseAnalyticsmodel.resourceData.encryptionKey" [checked]="synapseAnalyticsmodel.resourceData.encryptionKey=='identifier'" (change)="synapseAnalyticsmodel.resourceData.encryptionKey=$event.target.value;"><span>Enter a key identifier</span></label>                    
            </div>                     
        </div>
        <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'&&synapseAnalyticsmodel.resourceData.encryptionKey=='key'">
            <div class="label-div">
                <label for="encryptionKey" class="control-label ">Key vault and key<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The encryption key must be in an Azure Key Vault 
                    located in the same region as the Synapse workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div *ngIf="synapseAnalyticsmodel.resourceData.keyVaultData"><span>Key: {{synapseAnalyticsmodel.resourceData.keyVaultData.keyVault.name}}</span></div>    
            <div class="add-another-tag">
                <ng-template #selectKeyVault>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="keyVault" class="control-label">Key vault<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="keyVaultList" bindLabel="name" [(ngModel)]="keyForMLObj.keyVault" name="keyVault" placeholder="" (change)="keyVaultChangeForKey()" [clearable]="false">
                            </ng-select>
                        </div>   
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="key" class="control-label">Key<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only the keys meeting the following requirement(s)
                                will be shown. Required key type(s): RSA,RSA-HSM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="keyList" bindLabel="name" [(ngModel)]="keyForMLObj.key" name="key" placeholder="" [clearable]="false">
                                <ng-template ng-label-tmp let-item="item">
                                    {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                                </ng-template>
                            </ng-select>
                        </div>   
                    </div>
                    <div class="d-flex">
                        <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!keyForMLObj.keyVault||!keyForMLObj.key" (click)="saveSubSideSheet('selectKeyVault');popKV.hide();">Save</button>
                        <button class="btn btn-outline" (click)="popKV.hide();">Cancel</button>
                    </div>
                </ng-template> 
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="selectKeyVault"
                triggers="" placement="top" containerClass="custom-popover" #popKV="bs-popover" (click)="closeOtherPop(undefined,popKV);openPop(popKV,'selectKeyVault');">Select key vault and key</p> 
            </div>                  
        </div>
        <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'&&synapseAnalyticsmodel.resourceData.encryptionKey=='identifier'">
            <div class="label-div">
                <label for="keyIdentifier" class="control-label ">Key identifier<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" matTooltip="The encryption key must be in an Azure Key Vault 
                    located in the same region as the Synapse workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.keyIdentifierReq||inputError?.keyIdentifierValid" id="keyIdentifier" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.keyIdentifier" placeholder="" name="keyIdentifier">
                <p class="text-danger" *ngIf="inputError?.keyIdentifierReq">Key identifier is required</p>
                <p class="text-danger" *ngIf="inputError?.keyIdentifierValid">Please enter a valid key identifier</p>
            </div>                     
        </div>
        <div class="form-group">
            <div class="label-div"><label class="control-label text-weight">Networking</label></div>
            <div class="input-box">
                <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="allowConnections" id="allowConnections" [(ngModel)]="synapseAnalyticsmodel.resourceData.allowConnections" ><span>Allow connections from all IP addresses<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow connections from all IP addresses to your workspace's endpoints. 
                    You can restrict this to just Azure datacenter IP addresses and/or 
                    specific IP address ranges after creating the workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                <label class="col-sm-12 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="checkbox" name="virtualNetwork" id="virtualNetwork" [(ngModel)]="synapseAnalyticsmodel.resourceData.virtualNetwork" ><span>Enable managed virtual network<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose this option to ensure that connections from your workspace 
                        to your data sources use Azure Private Links." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>                    
        </div>
        <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData?.virtualNetwork">
            <div class="label-div">
                <label for="allowOutbound" class="control-label ">Allow outbound data traffic only to 
                    approved targets<span></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-3 radio-label" style="padding-left: 0px !important;"><input type="radio" name="allowOutbound" [value]="'yes'" ([ngModel])="synapseAnalyticsmodel.resourceData.allowOutbound" [checked]="synapseAnalyticsmodel.resourceData.allowOutbound=='yes'" (change)="synapseAnalyticsmodel.resourceData.allowOutbound=$event.target.value;"><span>Yes</span></label>
                <label class="col-sm-3 radio-label" style="padding-left: 0px !important;"><input type="radio" name="allowOutbound" [value]="'no'" ([ngModel])="synapseAnalyticsmodel.resourceData.allowOutbound" [checked]="synapseAnalyticsmodel.resourceData.allowOutbound=='no'" (change)="synapseAnalyticsmodel.resourceData.allowOutbound=$event.target.value;"><span>No</span></label>                    
            </div>                     
        </div>
        <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData?.virtualNetwork&&synapseAnalyticsmodel.resourceData?.allowOutbound=='yes'">
            <div class="label-div">
                <label for="allowOutbound" class="control-label ">Azure AD tenants<span></span></label>
            </div>
            <div class="d-flex" *ngFor="let item of synapseAnalyticsmodel.resourceData.azureADTenants;let i = index">
                <div class="label-div w-92">
                    <label class="control-label"><span class="text-weight">{{item?.tenantId?.displayName||'(Unavailable)'}}</span><br><span>{{item?.tenantId?.tenantId||item.tenantVal}}</span></label>
                </div> 
                <span><img src="/assets/icons/edit.png" (click)="editTenantSA(i)"></span>
                <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" (click)="deleteTenantSA(i)"></span>
            </div>   
            <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="addTenants();">Add tenant</p> 
            </div>                   
        </div>
        <div class="form-group">
            <div class="label-div d-flex">
                <label class="control-label " style="min-width: max-content;">Tag name </label>
                <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                </label>
                <label style="padding-left: 6px !important;width: 18px;"
                *ngIf="synapseAnalyticsmodel.resourceData&&synapseAnalyticsmodel.resourceData.tagList&&synapseAnalyticsmodel.resourceData.tagList.length>1"></label>
            </div>
            <div *ngFor="let item of synapseAnalyticsmodel.resourceData.tagList;let i = index">
                <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                <div class="input-box" style="padding-bottom: 6px;">
                    <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                    [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                </div>

                <div style="padding-left: 6px;padding-right: 6px;">:</div>

                <div class="input-box">
                    <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                    [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                </div>

                <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                    *ngIf="synapseAnalyticsmodel.resourceData.tagList.length>1"></span>
                </div>
                <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                512.</p>
                <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                <>%&\?/.</p>
                <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                is 256.</p>
            </div>

            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
            </div>
            </div>
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('synapseAnalytics')" (click)="triggerBtn('save')">Save</button>
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('synapseAnalytics')" (click)="triggerBtn('update')">Update</button>
        </div>
    </div>
</div>    
</div>  

<div class="sidebar-right" *ngIf="synapseAnalyticsmodel.SADedicatedPoolShow">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Dedicated SQL pool</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();synapseAnalyticsmodel.SADedicatedPoolShow=false;">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="poolName" class="control-label">Dedicated SQL pool name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.dedicatedNameValid||inputError?.dedicatedNameCheck||inputError?.dedicatedNameLen||inputError?.dedicatedNameReserved||inputError?.dedicatedNameDup" id="poolName" autocomplete="off" [(ngModel)]="SADedicatedPoolObj.name" placeholder="" name="poolName" (keyup)="validateDPName($event,60)">
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameCheck">Dedicated SQL pool name should not match special patterns.</p>
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameLen">The value has a length of at most 60.</p>
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameReserved">Dedicated SQL pool name should not contain reserved words.</p>
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameDup">No dedicated SQL pool with the same name exists in the workspace.</p>
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameValid">Your dedicated SQL pool name can't end with '.' or ' ', can't contain '<,>,*,%,&,:,\,/,?,@,-' or control characters</p>
                </div> 
            </div>
            <div class="form-group" *ngIf="SADedicatedPoolObj.visibleGeoRedundantOption">
                <div class="label-div">
                    <label for="geoReduandant" class="control-label">Geo-redundant<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Replicates data and backups to a paired region for data protection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="geoReduandant" [value]="'yes'" ([ngModel])="SADedicatedPoolObj.geoReduandant" [checked]="SADedicatedPoolObj.geoReduandant=='yes'" (change)="SADedicatedPoolObj.geoReduandant=$event.target.value;"><span>Yes</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="geoReduandant" [value]="'no'" ([ngModel])="SADedicatedPoolObj.geoReduandant" [checked]="SADedicatedPoolObj.geoReduandant=='no'" (change)="SADedicatedPoolObj.geoReduandant=$event.target.value;"><span>No</span></label>                    
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="performanceLevel" class="control-label">Performance level<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a dedicated SQL pool performance level that best fits your analytics needs." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="performanceLevelList" bindLabel="name" bindValue="name" [(ngModel)]="SADedicatedPoolObj.performanceLevel" name="performanceLevel" placeholder="" (change)="performanceLevelChange()" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            
            <div class="form-group">
                <div class="label-div">
                    <label for="estimatedPrice" class="control-label">Estimated price<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="While in preview, dedicated SQL pool has a preview discount. Any changes in pricing will
                        be announced at a future date. The preview price shown is an estimate and does not 
                        reflect enterprise agreements, credits, or discounts." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <span style="font-weight: 600;">Est. Cost Per Hour</span><br>
                    <span>{{SADedicatedPoolObj?.priceDetails?.amount}} USD</span>
                </div>
                <div class="add-another-tag">
                    <ng-template #viewPriceDtls>
                        <div class="form-group">
                            <div class="label-div">
                                <label class="control-label text-weight">Cost summary</label>
                            </div>
                            <div style="height: 1px;background: #FFFFFF;border: 1px solid #EEEFF0;box-sizing: border-box;margin-bottom: 8px;"></div>   
                            <div class="label-div d-flex">
                                <label class="">Cost per 100 DWUs (USD)</label>
                                <label class="label-right-cost pull-right">1.51</label>
                            </div>   
                            <div class="label-div d-flex">
                                <label class="">100 DWUs selected</label>
                                <label class="label-right-cost pull-right">X {{SADedicatedPoolObj?.priceDetails?.count}}</label>
                            </div> 
                            <div style="height: 1px;background: #FFFFFF;border: 1px solid #EEEFF0;box-sizing: border-box;margin-bottom: 8px;"></div>  
                            <div class="label-div d-flex">
                                <label class=" text-weight-12">EST. Cost per hour</label>
                                <label class="label-right-cost text-weight-12 pull-right">X {{SADedicatedPoolObj?.priceDetails?.amount}} USD</label>
                            </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" (click)="popView.hide();">Ok</button>
                        </div>
                    </ng-template> 
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="viewPriceDtls"
                    triggers="" placement="bottom" containerClass="custom-popover" #popView="bs-popover" [outsideClick]="true" (click)="popView.show();">View pricing details</p> 
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div d-flex">
                  <label class="control-label " style="min-width: max-content;">Tag name </label>
                  <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                  </label>
                  <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="SADedicatedPoolObj&&SADedicatedPoolObj.tagList&&SADedicatedPoolObj.tagList.length>1"></label>
                </div>
                <div *ngFor="let item of SADedicatedPoolObj.tagList;let i = index">
                  <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                      <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                        [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                      <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                        [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagSA(i,'SADedicatedPoolObj')"
                        *ngIf="SADedicatedPoolObj.tagList.length>1"></span>
                  </div>
                  <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                  <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                  <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                  <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div class="add-another-tag">
                  <p class="blue-text cursor-pointer lbl-btn" (click)="addTagSA('SADedicatedPoolObj')">Add another tag</p>
                </div>
              </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="SADedicatedPoolObj.updateIndex==undefined" [class.disabled]="!SADedicatedPoolObj?.name ||inputError?.dedicatedNameValid||inputError?.dedicatedNameCheck||inputError?.dedicatedNameLen||inputError?.dedicatedNameReserved||inputError?.dedicatedNameDup" (click)="saveSubSideSheet('SADedicatedPool')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="SADedicatedPoolObj.updateIndex!=undefined" [class.disabled]="!SADedicatedPoolObj?.name ||inputError?.dedicatedNameValid||inputError?.dedicatedNameCheck||inputError?.dedicatedNameLen||inputError?.dedicatedNameReserved||inputError?.dedicatedNameDup" (click)="updateSubSideSheet('SADedicatedPool')">Update</button>
            </div>
        </div>
    </div>
</div>

<div class="sidebar-right" *ngIf="synapseAnalyticsmodel.SAApacheSprakPoolShow">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Apache Spark pool</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();synapseAnalyticsmodel.SAApacheSprakPoolShow=false;">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="poolName" class="control-label">Apache Spark pool name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.apacheNameValid||inputError?.apacheNameStart||inputError?.apacheNameEnd||inputError?.apacheNameUnique||inputError?.apacheNameReserved||inputError?.apacheNameLen" id="poolName" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.name" placeholder="" name="poolName" (keyup)="validateASPName($event,15)">
                        <p class="text-danger" *ngIf="inputError?.apacheNameValid">Name must contain only letters or numbers.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameStart">Name must start with a letter.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameEnd">Name must end with a letter or a number.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameUnique">Name must be unique within the workspace.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameReserved">Name must not be a reserved name.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameLen">Name must be between 1 and 15 characters long.</p>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight-12" style=" padding-bottom: 0px !important;margin-bottom: 0px !important;">Node size family</label>
                    <label>MemoryOptimized</label>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="nodeSize" class="control-label">Node size<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="nodeSizeList" bindLabel="name" [(ngModel)]="SAApacheSparkPoolObj.nodeSize" name="nodeSize" placeholder="" (change)="nodeSizeChange()" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="autoscale" class="control-label">Autoscale<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If enabled, your Apache Spark pool will automatically scale up 
                        and down based on the amount of activity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="autoscale" [value]="'enable'" ([ngModel])="SAApacheSparkPoolObj.autoscale" [checked]="SAApacheSparkPoolObj.autoscale=='enable'" (change)="SAApacheSparkPoolObj.autoscale=$event.target.value;"><span>Enabled</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="autoscale" [value]="'disable'" ([ngModel])="SAApacheSparkPoolObj.autoscale" [checked]="SAApacheSparkPoolObj.autoscale=='disable'" (change)="SAApacheSparkPoolObj.autoscale=$event.target.value;"><span>Disabled</span></label>                    
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="numberNodes" class="control-label">Number of nodes<span class="mandatory">*</span></label>
                </div>
                <div class="input-box d-flex">
                    <input type="text" style="max-width:48px !important" class="form-control" id="numberNodes" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.numberNodes" (change)="silderValueChange()" placeholder="" name="numberNodes">
                    <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options" (userChange)="changeValSider()"></ngx-slider>
                    <input type="text" style="max-width:48px !important" class="form-control pull-right" id="maxNumberNodes" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.maxNumberNodes" (change)="silderValueChange()" placeholder="" name="numberNodes">
                </div> 
            </div>
            
            <div class="form-group">
                <div class="label-div">
                    <label for="estimatedPrice" class="control-label">Estimated price<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="While in preview, dedicated SQL pool has a preview discount. Any changes in pricing will
                        be announced at a future date. The preview price shown is an estimate and does not 
                        reflect enterprise agreements, credits, or discounts." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div style="display: grid;">
                    <span class="text-weight-12">Est. Cost Per Hour</span>
                    <span style="font-size: 12px;line-height: 18px;">{{SAApacheSparkPoolObj?.priceDetails?.amountMin}} to {{SAApacheSparkPoolObj?.priceDetails?.amountMax}} USD</span>
                </div>
                <div class="add-another-tag">
                    <ng-template #viewPriceDtls>
                        <div class="form-group" style="margin-bottom: 16px !important;">
                            <div class="label-div">
                                <label class="control-label text-weight">Cost summary</label>
                            </div>   
                            <div style="height: 1px;background: #FFFFFF;border: 1px solid #EEEFF0;box-sizing: border-box;margin-bottom: 8px;"></div>
                            <div class="label-div d-flex">
                                <label class="">Cost per Node ({{SAApacheSparkPoolObj.nodeSize.vcores}} vCores) (USD)</label>
                                <label class="label-right-cost pull-right">{{SAApacheSparkPoolObj.nodeSize.amount}}</label>
                            </div>   
                            <div class="label-div d-flex">
                                <label class="">Node ({{SAApacheSparkPoolObj.nodeSize.vcores}} vCores) selected</label>
                                <label class="label-right-cost pull-right">X {{SAApacheSparkPoolObj.numberNodes}} to {{SAApacheSparkPoolObj.maxNumberNodes}}</label>
                            </div>  
                            <div style="height: 1px;background: #FFFFFF;border: 1px solid #EEEFF0;box-sizing: border-box;margin-bottom: 8px;"></div> 
                            <div class="label-div d-flex">
                                <label class=" text-weight-12">EST. Cost per hour</label>
                                <label class="label-right-cost text-weight-12 pull-right">{{SAApacheSparkPoolObj?.priceDetails?.amountMin}} to {{SAApacheSparkPoolObj?.priceDetails?.amountMax}} USD</label>
                            </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" (click)="popView.hide();">Ok</button>
                        </div>
                    </ng-template> 
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="viewPriceDtls"
                    triggers="" placement="bottom" containerClass="custom-popover" #popView="bs-popover" [outsideClick]="true" (click)="popView.show();">View pricing details</p> 
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Automatic Pausing</label>
                    <label for="automaticPausing" class="control-label">Automatic Pausing<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If enabled, the Apache Spark pool will automatically 
                        pause after a specified amount of idle time." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="automaticPausing" [value]="'enable'" ([ngModel])="SAApacheSparkPoolObj.automaticPausing" [checked]="SAApacheSparkPoolObj.automaticPausing=='enable'" (change)="SAApacheSparkPoolObj.automaticPausing=$event.target.value;"><span>Enabled</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="automaticPausing" [value]="'disable'" ([ngModel])="SAApacheSparkPoolObj.automaticPausing" [checked]="SAApacheSparkPoolObj.automaticPausing=='disable'" (change)="SAApacheSparkPoolObj.automaticPausing=$event.target.value;SAApacheSparkPoolObj.numberMinutes=15;"><span>Disabled</span></label>                    
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="numberMinutes" class="control-label">Number of minutes idle<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" [disabled]="SAApacheSparkPoolObj.automaticPausing=='disable'" [class.border-danger]="inputError?.numberMinutesValid||inputError?.numberMinutesLen||inputError?.numberMinutesIdle" class="form-control" id="numberMinutes" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.numberMinutes" placeholder="" name="numberMinutes" (keyup)="validateASPNumberMinu($event,5,10080)">
                    <p class="text-danger" *ngIf="inputError?.numberMinutesValid">Value must be a valid number.</p>
                    <p class="text-danger" *ngIf="inputError?.numberMinutesLen">Please enter a value between 5 and 10080</p>
                    <p class="text-danger" *ngIf="inputError?.numberMinutesIdle">Enter number of idle minutes before pool is automatically paused.</p>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Component versions</label>
                    <label for="apacheSpark" class="control-label">Apache Spark<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="apacheSparkList" bindLabel="name" bindValue="id" [(ngModel)]="SAApacheSparkPoolObj.apacheSpark" name="apacheSpark" placeholder="" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">Python<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'3.6':'3.6'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">Scala<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'2.11.12':'2.12.12'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">Java<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'1.8.0_272':'1.8.0_282'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">.NET Core<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'3.1':'3.1'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">.NET for Apache Spark<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'1.0':'1.1.1'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">Delta Lake<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'0.6':'0.8'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Spark configuration<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Upload a Spark configuration file to specify additional 
                        properties on the Spark pool. This will be referenced to 
                        configure Spark applications upon job submission." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    <label for="packages" class="control-label">File upload</label>
                </div> 
                <div class="input-box d-flex custom-div-upload" *ngIf="!SAApacheSparkPoolObj.showUploadDetails">
                    <input type="text" class="form-control" placeholder="Select .txt file" [(ngModel)]="SAApacheSparkPoolObj.fileName" onclick="document.getElementById('files').click()" readonly>
                    <button class="btn btn-btn-primary custom-btn-upload" onclick="document.getElementById('files').click()"><img class="tooltip-icon" src="/assets/icons/uploadBtn.png" alt=""></button>
                    <input type="file"  style="display: none;" id="files" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.fileUpload" placeholder="Select .txt file" name="fileUpload" (change)="changeFileSparkCinfig($event.target.files)">
                </div>
                <div class="" *ngIf="!SAApacheSparkPoolObj.showUploadDetails">
                    <button class="btn btn-btn-primary" [class.disabled]="!SAApacheSparkPoolObj.fileUpload&&!SAApacheSparkPoolObj.fileDetails" (click)="SAApacheSparkPoolObj.showUploadDetails=true;">Upload</button>
                </div>
                <div class="d-flex" *ngIf="SAApacheSparkPoolObj.showUploadDetails">
                    <div class="label-div w-92">
                        <label class="control-label"><span class="text-weight">{{SAApacheSparkPoolObj.fileName}}</span><br><span>{{SAApacheSparkPoolObj?.fileDetails.size}}</span></label>
                        <!-- <label class="control-label"></label> -->
                    </div>
                        <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" class="p-blue-btn" (click)="deleteFileUpload()"></span>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Packages</label>
                    <label for="packages" class="control-label">Allow session level packages<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This setting allows users to add or update packages 
                        during a notebook or batch session." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="packages" [value]="'enable'" ([ngModel])="SAApacheSparkPoolObj.packages" [checked]="SAApacheSparkPoolObj.packages=='enable'" (change)="SAApacheSparkPoolObj.packages=$event.target.value;"><span>Enabled</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="packages" [value]="'disable'" ([ngModel])="SAApacheSparkPoolObj.packages" [checked]="SAApacheSparkPoolObj.packages=='disable'" (change)="SAApacheSparkPoolObj.packages=$event.target.value;"><span>Disabled</span></label>                    
                </div>
            </div>
            <div class="form-group">
                <div class="label-div d-flex">
                  <label class="control-label " style="min-width: max-content;">Tag name </label>
                  <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                  </label>
                  <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="SAApacheSparkPoolObj&&SAApacheSparkPoolObj.tagList&&SAApacheSparkPoolObj.tagList.length>1"></label>
                </div>
                <div *ngFor="let item of SAApacheSparkPoolObj.tagList;let i = index">
                  <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                      <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                        [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                      <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                        [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagSA(i,'SAApacheSparkPoolObj')"
                        *ngIf="SAApacheSparkPoolObj.tagList.length>1"></span>
                  </div>
                  <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                  <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                  <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                  <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div class="add-another-tag">
                  <p class="blue-text cursor-pointer lbl-btn" (click)="addTagSA('SAApacheSparkPoolObj')">Add another tag</p>
                </div>
              </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="SAApacheSparkPoolObj.updateIndex==undefined" [class.disabled]="checkDataDisabled('SAApacheSprakPool')" (click)="saveSubSideSheet('SAApacheSprakPool')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="SAApacheSparkPoolObj.updateIndex!=undefined" [class.disabled]="checkDataDisabled('SAApacheSprakPool')" (click)="updateSubSideSheet('SAApacheSprakPool')">Update</button>
            </div>
        </div>
    </div>
</div>

<div class="sidebar-right" *ngIf="synapseAnalyticsmodel.SAAzureADTenantShow">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Select Azure AD tenants</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();synapseAnalyticsmodel.SAAzureADTenantShow=false;">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">            
            <div class="form-group">
                <div class="input-box">
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="autoscale" [value]="'azure'" ([ngModel])="SAAzureADTenantObj.autoscale" [checked]="SAAzureADTenantObj.autoscale=='azure'" (change)="SAAzureADTenantObj.autoscale=$event.target.value;"><span>Select by Azure AD tenant name</span></label>
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="autoscale" [value]="'manually'" ([ngModel])="SAAzureADTenantObj.autoscale" [checked]="SAAzureADTenantObj.autoscale=='manually'" (change)="SAAzureADTenantObj.autoscale=$event.target.value;"><span>Manually via tenant ID</span></label>                    
                </div> 
            </div>
            <div class="form-group" *ngIf="SAAzureADTenantObj.autoscale=='azure'">
                <div class="label-div">
                    <label for="tenant" class="control-label">Tenants<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure AD tenants that you can add as
                        approved tenants for the workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="tenantList" bindLabel="tenantId" [(ngModel)]="SAAzureADTenantObj.tenantId" name="tenant" placeholder="" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            <div class="form-group" *ngIf="SAAzureADTenantObj.autoscale=='manually'">
                <div class="label-div">
                    <label for="tenantVal" class="control-label">Tenant ID<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure AD tenants that you can add as
                        approved tenants for the workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" id="tenantVal" [class.border-danger]="inputError?.tenantFormat||inputError?.tenantValid" autocomplete="off" [(ngModel)]="SAAzureADTenantObj.tenantVal" placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" (keyup)="validateTenant($event)" name="tenantVal">
                    <p class="text-danger" *ngIf="inputError?.tenantFormat">Must be in the format of 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.</p>
                    <p class="text-danger" *ngIf="inputError?.tenantValid">Only lowercase letters or numbers or hyphens are allowed.</p>
                </div> 
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="SAAzureADTenantObj.updateIndex==undefined" [class.disabled]="(SAAzureADTenantObj.autoscale=='azure'&&!SAAzureADTenantObj.tenantId)||(SAAzureADTenantObj.autoscale=='manually'&&!SAAzureADTenantObj.tenantVal)" (click)="saveSubSideSheet('SAAzureADTenant')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="SAAzureADTenantObj.updateIndex!=undefined" [class.disabled]="(SAAzureADTenantObj.autoscale=='azure'&&!SAAzureADTenantObj.tenantId)||(SAAzureADTenantObj.autoscale=='manually'&&!SAAzureADTenantObj.tenantVal)" (click)="updateSubSideSheet('SAAzureADTenant')">Update</button>
            </div>
        </div>
    </div>
</div>