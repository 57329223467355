<div class="introduction">
   

    <p class="heading" id="0">Sessions </p>
    <p class="para mb-0">Average number of live sessions to the application in a given timeframe.   </p>
    <img style="width:50%"src="assets\user-manual\apps-health-monitoring\Tile(9).svg" >
    <figcaption class="caption col-md-6">Avg live sessions</figcaption>


  
          
</div>