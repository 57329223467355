import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-set',
  templateUrl: './create-set.component.html',
  styleUrls: ['./create-set.component.scss']
})
export class CreateSetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
