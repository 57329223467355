import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revenue-prediction',
  templateUrl: './revenue-prediction.component.html',
  styleUrls: ['./revenue-prediction.component.scss']
})
export class RevenuePredictionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
