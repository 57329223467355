import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { number } from 'echarts';
import { UtilService } from 'src/app/services/util.service';


@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss']
})
export class UserManualComponent implements OnInit {
  articleList: any = [];
  currentArticle: any;
  activeSidebar = "Introduction";

  constructor(private util:UtilService) { }

  ngOnInit(): void {
    this.changeTab(this.activeSidebar)

  }

  changeTab(tab){
    this.activeSidebar = tab;
    this.articleList = []
    setTimeout(() => {
      $('.heading').each(e=>{
       
        let data = $('.heading')[e].innerText
        let num = $('.heading')[e].id
        this.util.unique(this.articleList,['data'])
        let obj={
          value: data,
          id: num,
        }
        this.articleList.push(obj)
      
       })
      this.currentArticle = this.articleList[0].value
    }, 1000);
    console.log($('.heading'))
  }

  scrollToPosition(id){
    let content=$('.introduction')
    let exactHeading=$('#'+id)
    let position=exactHeading.offset().top 
    - content.offset().top 
    + content.scrollTop();
    $('.user-manual-container').animate({scrollTop:position});
    //  let position =$('#'+id).offset().top
    //  $('.user-manual-container').animate({scrollTop:position},'slow');
  }

  route(){
   this.util.route(['a3s/home'])
  }
}
