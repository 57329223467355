import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { BreadcrumbsModule } from '../../common/breadcrumbs/breadcrumbs.module';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectiveModule } from '../../directive/directive.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxEchartsModule } from 'ngx-echarts';
import { UiSwitchModule } from 'ngx-ui-switch';
import * as echarts from 'echarts';

import { ManagementComponent } from './management.component';
import { OverviewManagementComponent } from './overview-management/overview-management.component';
import { ActionTemplateComponent } from './overview-management/action-template/action-template.component';
import { RegionChartComponent } from './overview-management//region-chart/region-chart.component';
import { SubscriptionChartComponent } from './overview-management/subscription-chart/subscription-chart.component';

import { VirtualMachineActionsComponent } from './resource-types-actions/virtual-machine-actions/virtual-machine-actions.component';
import { LocalNetworkGatewayActionsComponent } from './resource-types-actions/local-network-gateway-actions/local-network-gateway-actions.component';
import { DiskActionsComponent } from './resource-types-actions/disk-actions/disk-actions.component';
import { StorageAccountActionsComponent } from './resource-types-actions/storage-account-actions/storage-account-actions.component';
import { VpnGatewayActionsComponent } from './resource-types-actions/vpn-gateway-actions/vpn-gateway-actions.component';
import { VmScaleSetActionsComponent } from './resource-types-actions/vm-scale-set-actions/vm-scale-set-actions.component';
import { AppServiceActionsComponent } from './resource-types-actions/app-service-actions/app-service-actions.component';
import { AppServicePlanActionsComponent } from './resource-types-actions/app-service-plan-actions/app-service-plan-actions.component';
import { ConnectionActionsComponent } from './resource-types-actions/connection-actions/connection-actions.component';
import { FirewallActionsComponent } from './resource-types-actions/firewall-actions/firewall-actions.component';
import { AppRoutingModule } from '../../app-routing.module';
import { EchartsModule } from '../../common/echarts/echarts.module';
import { SqlDatabaseActionsComponent } from './resource-types-actions/sql-database-actions/sql-database-actions.component';
import { SqlVmActionsComponent } from './resource-types-actions/sql-vm-actions/sql-vm-actions.component';
import { LoadBalancerActionsComponent } from './resource-types-actions/load-balancer-actions/load-balancer-actions.component';
import { AzureBastionActionsComponent } from './resource-types-actions/azure-bastion-actions/azure-bastion-actions.component';
import { ApplicationGatewayActionsComponent } from './resource-types-actions/application-gateway-actions/application-gateway-actions.component';
import { MlWorkspaceActionsComponent } from './resource-types-actions/ml-workspace-actions/ml-workspace-actions.component';

@NgModule({
  declarations: [
    ManagementComponent,
    OverviewManagementComponent,
    ActionTemplateComponent,
    RegionChartComponent,
    SubscriptionChartComponent,
    VirtualMachineActionsComponent,
    LocalNetworkGatewayActionsComponent,
    DiskActionsComponent,
    StorageAccountActionsComponent,
    VpnGatewayActionsComponent,
    VmScaleSetActionsComponent,
    AppServiceActionsComponent,
    AppServicePlanActionsComponent,
    ConnectionActionsComponent,
    FirewallActionsComponent,
    SqlDatabaseActionsComponent,
    SqlVmActionsComponent,
    LoadBalancerActionsComponent,
    AzureBastionActionsComponent,
    ApplicationGatewayActionsComponent,
    MlWorkspaceActionsComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    EchartsModule,
    FormsModule,
    AngularMaterialModule,
    BreadcrumbsModule,
    PipesModule,
    DirectiveModule,
    ReactiveFormsModule,
    NgxEchartsModule.forRoot({
      echarts
    }),
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    UiSwitchModule,
    NgSelectModule,

  ],
  providers: [
    TitleCasePipe,
    DatePipe
  ]
})
export class ManagementModule { }
