<div class="custom-fixed-tables">
    <table class="table">
      <thead>
        <tr>
          <th>
              <div class="flex-custom no-wrap">
                  <span>Name</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('localNetwork',0)">
              </div>
          </th>
          <th>
              <div class="flex-custom no-wrap">
                  <span>Resource Group</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('localNetwork',1)">
              </div>
          </th>
          <th>
              <div class="flex-custom no-wrap">
                  <span>Subscription</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('localNetwork',2)">
              </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>Region</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('localNetwork',3)">
            </div>
        </th>
        <th style="border-right: none;">
          <div class="flex-custom no-wrap">
              <span>IP Address</span>
              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('localNetwork',4)">
          </div>
        </th>
        <th  style="border-right: none;">
          <div class="flex-custom no-wrap">
              <span>Tags</span>
              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('localNetwork',5)">
          </div>
        </th>
        <th style="border-left: none;" class="wid-75"></th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let row of data" >
            <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(lngDetailsTemplate,row,'lngPopUp')">{{ row[0] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
            <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
            <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
            <td isEllipsisActive>{{ row[4] |  displayTxt}}</td>
            <td isEllipsisActive>{{getTags(row[5])}}</td>
            <td class="text-right wid-75">
              <span>
                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                <mat-menu #action2="matMenu" class="delete-popup">
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                    <p class="delete-popup-text">Configuration</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('connection',row)">
                    <p class="delete-popup-text">Connections</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('deleteLNG',row)">
                    <p class="delete-popup-text">Delete</p>
                  </div>
                </mat-menu>
              </span>              
            </td>
        </tr>
        <div class="col-md-12 mt-3" *ngIf="!data?.length">
          <p class="content-14 blur-text">No data Found</p>
        </div>
      </tbody>
  </table>
  </div> 
  <ng-container *ngIf="showSidesheet">

    <!-- Configuration LNG -->
    <ng-container *ngIf="currentTemplate == 'configuration'" >
        <div class="sidebar-right single add-alert" id="sideSheets">
            <div class="header">
                <div class="d-flex">
                    <h4>Configuration<br> <span class="blur-text">Local Network Gateway | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetValidation();resetForm();saveDisable=false">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                     
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">IP address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The public IP address of your local gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" name="ipAddress" [clearable]="false" id="ipAddress" formControlName="ipAddress"   autocomplete="off" placeholder="" (keyup)="saveDisable=true;CheckIPAddress($event,'ip');"  >
                    </div>
                    <p class="text-danger" *ngIf="inputError?.ipError||inputError?.ipNonNullRange">Invalid IP address.</p>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Address Space<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="One or more IP address ranges (in CIDR notation) that define your 
                            local network's address space. For example: 192.168.0.0/16. If you 
                            plan to use this local network gateway in a BGP-enabled connection, 
                            then the minimum prefix you need to declare is the host address of your 
                            BGP Peer IP address on your VPN device." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-container formArrayName="addressSpaceArray" *ngFor="let list of currentForm.get('addressSpaceArray')['controls']; let i = index;">
                            <div class="notify-wrap mb-2" id="address-space-list-{{i}}" [formGroupName]="i">
                                <input class="form-control full-width" type="text" autocomplete="off" id="addressSpace-{{i}}" name="addressSpace-{{i}}" formControlName="value" (keyup)="CheckIPAddress($event,'adrcSpc',i);saveDisable=true" />
                                <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeAddressSpace(i)" *ngIf="addressSpaceList?.length > 1" >
                            </div>
                            <!-- <p class="text-danger" *ngIf="checkIPValidation(i,'dnsIP')">Each DNS server address must be unique. Invalid IP address.</p> -->
                        </ng-container>
                         <p class="text-danger" *ngIf="inputError?.adrcSpcError||inputError?.adrcSpcNonNullRange"> Invalid argument: 'Malformed IP address: {{number}}'</p> 
                        <div style="margin-top: -4px;" class="action-btn" (click)="addAddressSpace()">Add another address space</div>
                    </div>
                    <div class="comm-mb">
                        <div class="checkbox-cs"> 
                          <input type="checkbox" name="enable-auto" class="form-check-inline" id='bgpConfig' value="bgpConfig" formControlName="configBGP" (click)="saveDisable=true" >
                          <label class="value" for="bgpConfig" (click)="saveDisable=true" >Configure BGP settings</label>
                        </div>
                    </div>
              
                      <ng-container *ngIf="currentForm.get('configBGP').value">
                              <!--(click)="initialiseForm()" <form [formGroup]="tempForm"> -->
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Autonomous system number (ASN)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A BGP-enabled connection between two network gateways requires that their 
                                ASNs be different. Additionally, the following ASNs are reserved by Azure: 8075, 
                                8076, 12076 (public), 65515, 65517, 65518, 65519, 65520" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width" type="text" name="asn" [clearable]="false" id="asn" formControlName="ASN"   autocomplete="off" placeholder=""  (keyup)="asnValidation($event);saveDisable=true" >
                        </div>
                        <p class="text-danger" *ngIf="inputError?.asnValue">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.asnValidNum">The value must be a valid number.</p>
                        <p class="text-danger" *ngIf="inputError?.asnError"> ASN {{asnNumber}} is reserved</p>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">BGP peer IP address<span class="mandatory">*</span></label>
                            <input class="form-control full-width" type="text" name="bgpIPAddress" [clearable]="false" id="bgpIPAddress" formControlName="bgpIPAddress"   autocomplete="off" placeholder="" (keyup)="CheckIPAddress($event,'bgp');saveDisable=true"   >
                        </div>
                        <p class="text-danger" *ngIf="inputError?.bgpError ||inputError?.bgpNonNullRange">Invalid IP address.</p>
                       <!-- </form> -->
                      </ng-container>
                </form>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('config')||!saveDisable" (click)="saveLNG('config');saveDisable=false">Save</button>
            </div>
        </div>
    </ng-container>

    <!-- Connection LNG -->
    <ng-container *ngIf="currentTemplate == 'connection'" >
        <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" id="sideSheets">
            <div class="header">
                <div class="d-flex">
                    <h4 class="half-width">Connections<br> <span class="blur-text">Local Network Gateway | {{selectedRow[0]}}</span></h4>
                   <div class="text-right half-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <div class="custom-fixed-tables">
                    <table class="table">
                        <thead>
                            <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Name</span>
                                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('name',connectionList)">
                                  </div>
                              </th>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Status</span>
                                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('status',connectionList)">
                                  </div>
                              </th>
                              <th>
                                <div class="flex-custom no-wrap">
                                    <span>Connection type</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectionType',connectionList)">
                                </div>
                            </th>
                              <th style="border-right: none;">
                                <div class="flex-custom no-wrap">
                                    <span>Virtual network gateway</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('VPN',connectionList)">
                                </div>
                            </th>
                            <th style="border-left: none;"></th>
                            </tr>
                          </thead>
                          <tbody >
                            <ng-container *ngFor="let list of connectionList">
                                <tr>
                                    <td>{{list?.name}}</td>
                                    <td>{{splitWordByCaps(list?.properties?.connectionStatus)}}</td>
                                    <!-- <td>{{list?.properties?.connectionStatus?list?.properties?.connectionStatus:'Unknown'}}</td> -->
                                    <td>Site-to-site ({{list?.properties?.connectionType}})</td>
                                    <td>{{getVPNName(list)}}</td>
                                    <td>
                                        <span >
                                            <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                            <mat-menu #actionInbound="matMenu" class="delete-popup" id="matMenu">
                                              <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteConnection')">
                                                <p class="delete-popup-text">Remove</p>
                                              </div>
                                            </mat-menu>
                                          </span>    
                                    </td>
                                </tr>
                              </ng-container>
                              <div class="col-md-12 mt-3" *ngIf="!connectionList?.length">
                                <p class="content-14 blur-text">No results</p>
                              </div>
                          </tbody>
                    </table>
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn whiteBtn" (click)="setDefaultValue('addConnection');showSubSidesheet = true">
                <img src="/assets/governance/add-btn.svg" alt="">
                Add connection 
            </button>
              <!-- <button class="btn btn-btn-primary disabled" (click)="saveLNG('connection')">Save</button> -->
          </div>
          <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
            <div class="header">
                <div class="d-flex">
                    <h4 >Add Connection <br> <span class="blur-text">Local Network Gateway | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation();resetForm();">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                        <input class="form-control full-width" type="text" name="source" [clearable]="false" id="source" formControlName="name" [class.border-danger]="inputError?.sourceInvalidArgument"  autocomplete="off" placeholder=""   (keyup)="validIPName($event,80,'connectionName')" >
                        <p class="text-danger" *ngIf="inputError?.nameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.nameMaxLen">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.nameExist">{{connectionName}} already exists in the resource group</p>
                        <p class="text-danger" *ngIf="inputError?.nameValid ||inputError?.nameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                            and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div> 
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Connection type<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use a VNet-to-VNet connection to connect two Azure virtual networks. 
                        Use a site-to-site connection to connect your on-premises network with 
                        your Azure virtual network. Use an ExpressRoute connection to connect 
                        to an Azure virtual network to an existing circuit." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width disabled" type="text" name="connectionType" [clearable]="false" id="connectionType" formControlName="connectionType"  autocomplete="off" placeholder=""  >
                    </div>   
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Virtual network gateway<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Azure virtual network that will be used by this connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="vpnList"  bindLabel="name" bindValue="id" name="vpnName" [clearable]="false" id="VPN" (change)="getVPNConfig($event);saveDisable=true" formControlName="vpnName"></ng-select>
                    </div>  
                    <!-- groupBy="type" -->
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Local network gateway<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A connection for this local network gateway is being added." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width disabled" type="text" name="localNetName" [clearable]="false" id="LNG" formControlName="localNetName"   autocomplete="off" placeholder=""  >
                    </div>  
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Shared key (PSK)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A mixture of letters and numbers, used to establish encryption for the connection.
                        The same shared key must be used in both the virtual network and local network
                        gateways. If your gateway device doesn't provide one, you can make one up here 
                        and provide it to your device." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" name="sharedKey" [clearable]="false" id="sharedKey" formControlName="sharedKey"   autocomplete="off" placeholder=""  (keyup)="sharedKeyValidation($event)">
                      <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.sharedKeyMaxLen">A shared key must contain between 1 to 128 characters. </p>
                      <p class="text-danger" *ngIf="inputError?.sharedKeyASCII">Invalid shared key configured. The shared key should be a combination of printable ASCII characters and should be between 1 to 128 in length. </p>
                    </div>      
                    <div class="comm-mb">
                      <div class="checkbox-cs"> 
                        <input type="checkbox" name="enable-auto" class="form-check-inline" id='ipAddress' formControlName="ipAddress" >
                        <label class="value" for="ipAddress" >Use Azure Private IP Address<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Checking this option allows you to use Azure private IPs to establish an IPsec VPN connection. 
                          Support for private IPs must be set on the VPN gateway for this option to work. It is only supported on AZ SKUs" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      </div>
                  </div>
                  <div class="comm-mb">
                    <div class="checkbox-cs"> 
                      <input type="checkbox" name="enable-auto" class="form-check-inline" id='BGP'[class.disabled-icon]="disable" formControlName="BGP" >
                      <label class="value" for="BGP" [class.disabled-icon]="disable">Enable BGP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Border gateway protocol" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    </div>
                    <!-- disabled="vpnList?.properties?.sku?.name=='Basic'" -->
                </div>
                <div class="comm-mb">
                  <div class="checkbox-cs" *ngIf="currentForm.get('BGP').value"> 
                    <input type="checkbox" name="enable-auto" class="form-check-inline disabled-icon" id='customBGP' formControlName="customBGP" disabled>
                    <label class="value disabled-icon" for="customBGP" >Enable Custom BGP Addresses</label>
                  </div>
              </div>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap" >IKE Protocol<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IKE protocol used by this connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom ">
                            <div class="checkbox-cs" id="disable">
                                <input type="radio" name="IKEProtocol"  class="form-check-inline" id='1' value="1" (change)="setRadioBtn('1','protocol')" [disabled]="infoMessage" >
                                <label class="value" for="1">IKEv1</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="IKEProtocol" class="form-check-inline" id='2' value="2" (change)="setRadioBtn('2','protocol')" [disabled]="infoMessage" checked>
                                <label class="value" for="2">IKEv2</label>
                            </div>
                        </div>
                    </div>    
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Subscription<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When adding a connection for an existing peer, the subscription can't be changed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width disabled" type="text" name="sub" [clearable]="false" id="sub" formControlName="sub"   autocomplete="off" placeholder=""  >
                    </div>       
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Resource group<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When adding a connection for an existing peer, the resource group can't be changed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width disabled" type="text" name="rg" [clearable]="false" id="rg" formControlName="rg"   autocomplete="off" placeholder=""  >
                    </div>  
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Location<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When adding a connection for an existing peer, the location can't be changed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width disabled" type="text" name="location" [clearable]="false" id="location" formControlName="location"   autocomplete="off" placeholder=""  >
                    </div>  
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('addConnection')" (click)="saveLNG('addConnection')">Save</button>
            </div>
        </div>
        </div>
    </ng-container>

</ng-container>

<ng-template #lngDetailsTemplate>
  <div class="medium-popup-wrapper width-832">
      <div class="modal-body scroll-y">
      <div class="d-flex mb-3">
          <div class="popup-title">Local Network Gateway</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="tab-list">
          <div class="tab-item" (click)="closeModal();openSidesheet('configuration',selectedSideSheetRow)">Configuration</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('connection',selectedSideSheetRow)">Connections</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('deleteLNG',selectedSideSheetRow)">Delete</div>
      </div>
      <div class="row row1" >
          <div class="col-md-6" *ngFor="let data of selectedSideSheetRow;let i= index;let col of columns">
              <div class="list-item">
                  <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                  <ng-container *ngIf="col.name!='location'&&col.name!='subscriptionId'">
                    <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedSideSheetRow[i]:getTags(selectedSideSheetRow[i])}}</div>
                  </ng-container>
                  <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedSideSheetRow[i])}}</div>
                  <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedSideSheetRow[i])}}</div>
              </div>
          </div>
      </div>
      </div>
      <div class="submit-sec text-right pad-10-24">
      <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
      </div>
  </div>
</ng-template>
<!-- 
  <ng-template #configuration>
    <div class="sidebar-right single add-alert" >
        <div class="header">
            <div class="d-flex">
                <h4 >Configuration<br> <span class="blur-text">Local Network Gateway | {{selectedRow[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">IP address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The public IP address of your local gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" name="ipAddress" [clearable]="false" id="ipAddress" formControlName="ipAddress"   autocomplete="off" placeholder=""   >
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">IP address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="One or more IP address ranges (in CIDR notation) that define your 
                        local network's address space. For example: 192.168.0.0/16. If you 
                        plan to use this local network gateway in a BGP-enabled connection, 
                        then the minimum prefix you need to declare is the host address of your 
                        BGP Peer IP address on your VPN device." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" name="ipAddress" [clearable]="false" id="ipAddress" formControlName="ipAddress" [class.border-danger]=""  autocomplete="off" placeholder=""   >
                </div>   
                <div class="" *ngIf="addressSpaceList?.length">
                    <label class="flex-custom no-wrap">IP address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="One or more IP address ranges (in CIDR notation) that define your 
                        local network's address space. For example: 192.168.0.0/16. If you 
                        plan to use this local network gateway in a BGP-enabled connection, 
                        then the minimum prefix you need to declare is the host address of your 
                        BGP Peer IP address on your VPN device." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <ng-container formArrayName="addressSpaceArray" *ngFor="let list of currentForm.get('addressSpaceArray')['controls']; let i = index;">
                        <div class="notify-wrap" id="address-space-list-{{i}}" [formGroupName]="i">
                            <input class="form-control full-width" type="text" autocomplete="off" id="addressSpace-{{i}}" name="addressSpace-{{i}}" formControlName="value" />
                            <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeAddressSpace(i)" [class.disabled-icon]="addressSpaceList?.length <= 1">
                        </div>
                        <p class="text-danger" *ngIf="checkIPValidation(i,'addressSpace')">Address spaces within a virtual network cannot overlap.</p>
                    </ng-container>
                    <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid argument: 'The prefix must be between 1 and 32.'.</p>
                    <span class="action-btn" (click)="addAddressSpace()">Add another address space</span>
                </div>                 
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid" (click)="saveNic('nicNsg')">Save</button>
        </div>
    </div>
</ng-template> -->

  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" id="popup" >
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getCurrentResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>Are you sure want to {{getResourceName()}}?</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getButtonName()}}</button>
        </div>
      </div>
    </div>
</ng-template>