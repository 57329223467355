import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security-posture',
  templateUrl: './security-posture.component.html',
  styleUrls: ['./security-posture.component.scss']
})
export class SecurityPostureComponent implements OnInit {
  currentTab : any = 'overview'
  constructor() { }

  ngOnInit(): void {
    $('.main-content').addClass('only-grey-bg');
  }

  ngOnDestroy() {
    $('.main-content').removeClass('only-grey-bg');
  }

}
