import { TitleCasePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilService } from '../services/util.service';
import { GetBluePrintProjects, SetCurrentProject } from '../state/security-bucket.actions'
import { SecurityBucketState } from '../state/security-bucket.state'

@Component({
  selector: 'app-security-bucket',
  templateUrl: './security-bucket.component.html',
  styleUrls: ['./security-bucket.component.scss']
})
export class SecurityBucketComponent implements OnInit {
  securityMenu: any = [];
  currentUrl: any;
  projectList: any = [];
  currentProject: any;
  currentProjectData: any;
  @Select(SecurityBucketState.allProjects) allProjects$: Observable<any[]>;
  currrentTab: any;

  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    var $target = $(event.target);
    this.securityMenu.forEach(e=> {
      let element = document.getElementById(e.menu)
      if(!$target.closest(element).length && !$target.closest("#no-access-tooltip").length){
         e.restrictAccess = false
      }
    })
  }
  
  constructor(private util:UtilService,private router: Router,private title: TitleCasePipe,private store:Store) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event) => {
        this.currentUrl = event['urlAfterRedirects']
    })
    if(!this.currentUrl) this.currentUrl = this.router.url
   }

  ngOnInit(): void {
    this.securityMenu = this.util.getStatic('securityMenus');
    // this.getBlueprintProject();
    this.store.dispatch(new GetBluePrintProjects()).subscribe((res)=>{
      this.triggerMenuClick(this.securityMenu[0])
      this.projectList=res.SecurityBucket.allProject;
      // this.setCurrentProject(this.projectList[0],0);
    });
  }

  triggerMenuClick(menu){
    this.currrentTab = menu;
    let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
    if(data && data.scope.some(x=> x.id == menu.tabId)) menu['restrictAccess'] = false
    else return menu['restrictAccess'] = true;
    this.closeOthers(menu.menu)
    if(this.allowSubMenus(menu)) {
      this.subMenuOpen(menu.menu)
      this.setCurrentProject(this.projectList[0],0)
    }
  }
  
  collapseSider() {
    $(".main-content").toggleClass('collapse-sidebar');
  }

  allowUrl(url){
    return ['/a3s/securityBucket/'+url].indexOf(this.currentUrl)  > -1 ? true :false;
  }
  
  allowSubMenus(list){
    return ['Security posture','Recommendations','Alert Management'].indexOf(list.value) > -1 ? true : false
  }

  closeOthers(id){
    $('#securityPosture').removeClass('open').siblings().slideUp(300);
    $('#recommendations').removeClass('open').siblings().slideUp(300);
    $('#alertManagement').removeClass('open').siblings().slideUp(300);
  }


  async subMenuOpen(id){
    if(!id.includes('types_')){
      $('#securityPosture').removeClass('open').siblings().slideUp(300);
      $('#recommendations').removeClass('open').siblings().slideUp(300);
      $('#alertManagement').removeClass('open').siblings().slideUp(300);
    }
    // else $('#' + id).removeClass('open').siblings().slideUp(300);
    // let wait = await this.util.delay(1000)
    $('#' + id).toggleClass('open').siblings().slideToggle(300);
  }

  async getAllResources(list){
    let resources = await this.util.handleRequest('get','a3s_management_getResourceTypes',[list.subscriptionId],null,null,null,true)
    // let types = await this.getAllResourceTypes(list)
    let types = this.util.getStaticResourceTypes()
    var array = []
    types.forEach(x=>{
      resources.body.value.forEach(e=>{
         if(x.type.toLowerCase() == e.type.toLowerCase()){
          e['resourceName']= e.name
          e['resourceType'] =x.name
          array.push(e)
         }
      })
    })
    return array;
  }

  getProjectResource(id){
    return this.util.handleRequest('get','a3s_architectureBlueprint_getProjectResourceListForDashboards',[id],null,null,null,true)
 }

  async setCurrentProject(listDetails,index){
    this.currentProject = listDetails.projectName;
    this.store.dispatch(new SetCurrentProject(listDetails));
  }

  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }

  checkA3sAccess(){
    let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
    return data && data.scope.some(x=> this.currrentTab ? x.id == this.currrentTab.tabId : false) ? true : false;
  }

}
