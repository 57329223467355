import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a3s-filtres',
  templateUrl: './a3s-filtres.component.html',
  styleUrls: ['./a3s-filtres.component.scss']
})
export class A3sFiltresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
