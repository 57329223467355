<div class="introduction">
          <p class="heading" id="3">Deployed Successfully  </p>
          <ol>
              <li>Click <b>Deploy</b> to start the automated deployment of the finalized and approved architecture on Azure.</li>
              <img src="assets\user-manual\apps-&-infra\Infra-BP-(1).svg " >
              <figcaption class="caption">Deploy </figcaption>
              <img src="assets\user-manual\apps-&-infra\deploy-bp.svg " >
              <figcaption class="caption">Deployment progress </figcaption>
              <li>All needed resources are deployed on Azure and a successful deployment notification is displayed. </li>
              <img src="assets\user-manual\apps-&-infra\Project-summary-fail.svg " >
              <figcaption class="caption">Project deployment success</figcaption>
              <li>Project status is ‘Deployed successfully’  </li>
              
              
             
          </ol>
   
</div>
