import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { IndianTimeFormat } from 'src/app/pipes/custom.pipe';
import { UtilService } from 'src/app/services/util.service';
import { SecurityBucketState } from 'src/app/state/security-bucket.state';
import { SecurityQueries } from '../../security-queries';

@Component({
  selector: 'app-endpoint-security-windows',
  templateUrl: './endpoint-security-windows.component.html',
  styleUrls: ['./endpoint-security-windows.component.scss']
})
export class EndpointSecurityWindowsComponent implements OnInit {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  
  searchKey:any;
  resourceList: any = []; 
  datasort: string;
  subsName: any;
  groupByCtrl:boolean=true;
  subscriptionList: any=[];
  refreshedTime: any = {
    resourceHealth: new Date(),
    updateAvailAcross: new Date(),
    missingSecurity: new Date(),
    devicesWithFailed: new Date(),
    devicesWithMost: new Date(),
    runningProcessesCard: new Date(),
    missingSecurityCard: new Date(),
    vmFailedCard: new Date(),
    newProcesesObserved: new Date(),
    processStartedData: new Date()
  }
  runningProcessesCard: any = {
    type:'runningProcessesCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  missingSecurityCard: any = {
    type:'missingSecurityCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  vmFailedCard: any = {
    type:'vmFailedCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  options:any={};
  showLoader: any={
    decvicesWithSecurity:true,
    missingSecurityData:true,
    updateAvailAcrossData:true,
    devicesWithMostData:true,
    devicesWithFailedData:true
  };
  devicesWithSecurityData: any=[];
  devicesWithSecurityOptions: any;
  devicesWithSecurityFilter:any={
    type:'devicesWithSecurity',
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  devicesWithSecurityTableData: any=[];
  processStartedDataTableData: any=[];
  devicesWithMostTableData: any=[];
  missingSecurityTableData: any=[];
  devicesWithFailedTableData: any=[];
  updateAvailAcrossTableData: any=[];
  devicesWithSecurityTempTableData: any=[];
  devicesWithMostTempTableData: any=[];
  missingSecurityTempTableData: any=[];
  devicesWithFailedTempTableData: any=[];
  updateAvailAcrossTempTableData: any=[];
  processStartedDataTempTableData: any=[];
  updateAvailAcrossOption: any = {
    type:'updateAvailAcross',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  processStartedData: any = {
    type:'processStartedData',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'Process Started',
    xAxis:[],
    yAxis:[],
    showLegend : true,
    legendData:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    max:undefined,
    showLoader : true,
    currentFilter: {
      type : 'last3days',
      from:  new Date(new Date().setDate(new Date().getDate() - 3)),
      to: new Date()
    }
  };
  missingSecurityOption: any = {
    type:'missingSecurity',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  devicesWithFailedOption: any = {
    type:'devicesWithFailed',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  devicesWithMostOption: any = {
    type:'devicesWithMost',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    tempData:[],
    data:[],
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  newProcesesObserved: any = {
    type:'newProcesesObserved',
    tableData:[],
    tempData:[],
    onlyTable:true,
    tempTableData:[],
    showLoader : false,
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  timeFilterList: any=[];
  selectedData: any;
  timeFilterFlag: boolean=false;
  subscriptionInfra: Subscription;
  currentProject: any;
  tempDevicesWithSecurityData: any=[];
  @Select(SecurityBucketState.currentProjectDetail) currentProjectDetail$: Observable<any>;
  
  constructor(private util:UtilService,private modalService: NgbModal,private timeFormat:IndianTimeFormat) { }

  async ngOnInit() {
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.subscriptionInfra = this.currentProjectDetail$.subscribe((res) => {
      if(res){
        this.currentProject = _.cloneDeep({...res});//res.currentFilteredProject;
        this.triggerFilter(this.currentProject,'project')
        this.getData();
      }
    });
  }
  
  ngOnDestroy() {
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

  getData(): void {
    // await this.getAllSubscriptions();
    this.getDevicesWithSecurityData();
    this.getUpdateAvailAcross();
    this.getMissingSecurity();
    this.getDevicesWithFailed();
    this.getDevicesWithMost();
    // this.getResources();
    this.getCardData('missingSecurityCard');
    this.getCardData('vmFailedCard');
    this.getCardData('runningProcessesCard');
    this.getNewProcesesObserved('newProcesesObserved');
    this.getProcessStartedData();
  }

  getProcessStartedData(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.processStartedData.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.processStartedData.currentFilter))
    }
    this.getTableData('processStartedData');
    this.processStartedData.showLoader = true
    this.processStartedData.legendData = []
    this.processStartedData.series = []
    this.processStartedData.xAxis = []
    //this.processStartedData.tableData=[];
    this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.processStartedData.showLoader = false
      if(!res || !res.body) return 
      
      res.body.tables.forEach((dt,index1)=>{
        this.processStartedData.tempData.push(dt);
      })
      this.filterAllData('processStartedData')
    },err=>{
      this.processStartedData.showLoader = false
    })
  }

  filterAllData(type){
    this[type].legendData = [];
    this[type].data=[];
    this[type].yAxis=[];
    this[type].series=[];
    this[type].xAxis=[];
    this[type].tempData.forEach((ds,index1)=>{
      if(type==='processStartedData'){
        var timeArray = [];
        ds.rows.forEach((dt)=>{
          if(this.filterCheck(dt[2])){
            let findIndex=this.processStartedData.series.findIndex(d=>d.name==dt[1]);
            if(findIndex>-1){
            this.processStartedData.series[findIndex].data.push(dt[3]);
            }else{
              let obj = {
                name : dt[1],
                type : 'line',
                data: [dt[3]]
              }            
              this.processStartedData.series.push(obj);
            }
            let findTime=timeArray.find(dt=>dt==dt[0]);
            if(!findTime){
              timeArray.push(dt[0]);       
            }
            let findObj=this.processStartedData.legendData.find(d=>d.name==dt[1]);
            if(!findObj){
              this.processStartedData.legendData.push({icon:'circle',name:dt[1]});
            }
          }
        });
       
        let sorted : any = timeArray.sort((a,b)=> {
          var d1 = new Date(b);
          var d2 = new Date(a);
          return d2 > d1 ? 1 : 0;
        })
      
        sorted.forEach((x)=>{
          if(index1 == 0){
            let findXAxis=this.processStartedData.xAxis.find(dt=>dt==this.timeFormat.transform(x));
            if(!findXAxis){
            this.processStartedData.xAxis.push(this.timeFormat.transform(x))
            }
          }
        })
      }
    })
  }

  getNewProcesesObserved(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": this.getQuery(type)
    }
    this[type].tableData=[];
    this[type].tempTableData=[];
    if(this[type].onlyTable){
      this[type].showLoader = true;
    }
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      if(this[type].onlyTable){
        this[type].showLoader = false;
      }
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempTableData.push(ds);
        });
      })
      this.filterNPOData(type);
    },err=>{
      if(this[type].onlyTable){
        this[type].showLoader = false;
      }
    })
  }

  filterNPOData(type) {
    this[type].tableData=[];
    this[type].tempTableData.forEach(dt=>{
      let value;
      switch (type) {
        case 'newProcesesObserved':
          value=dt[5];
          break;
      
        default:
          break;
      }
        if(!value||this.filterCheck(value)){
          this[type].tableData.push(dt);
        }
    })
  }

  getCardData(type) {
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": this.getQuery(type)
    }
    this[type].showLoader=true;
    this[type].tableData=[];
    this[type].tempTableData=[];
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      if(!res || !res.body) {
        this[type].showLoader=false;
        return
      } 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempTableData.push(ds);
        });
      })
      this.filterCardData(type);
      this[type].showLoader=false;
    },err=>{
      this[type].showLoader=false;
    })
  }

  filterCardData(type) {
    this[type].tableData=[];
    if(this[type].tempTableData&&this[type].tempTableData.length) {
      this[type].tempTableData.forEach(dt=>{
        let value;
        switch (type) {
          case 'vmFailedCard':
            value=dt[1];
            break;
          case 'runningProcessesCard':
            value=dt[2];
            break;
          case 'missingSecurityCard':
            value=dt[1];
            break;

          default:
            break;
        }
        if(value && this.filterCheck(value)) {
          this.filterCardDetails(type,dt);
        }
      })
      if(type === 'missingSecurityCard'||type==='vmFailedCard'|| type==='runningProcessesCard') {
        this.filterData(type);
      }
    } else {
      this[type].tableData = [];
    }
  }

  filterCardDetails(type,dt) {
    let name,countVal;
    if(type==='vmFailedCard'||type==='missingSecurityCard') {
      name=dt[0].split(".")[0];
      countVal=dt[2];
    } else if(type==='runningProcessesCard') {
      name=dt[1];
      countVal=dt[3];
    }
    let findIndex=this[type].tableData.findIndex(ds=>ds.name===name);
    if(findIndex>-1) {
      this[type].tableData[findIndex].count=this[type].tableData[findIndex].count+Number(countVal);
    } else {
      this[type].tableData.push({
        name:name,
        count:Number(countVal)
      })
    }
  }

  filterData(type): void {
    if(type==='missingSecurityCard'||type === 'runningProcessesCard'||type==='vmFailedCard') {
      const tableData=this[type].tableData;
      tableData.sort((a,b)=>{
        if(a.count>b.count) {
          return -1;
        }

        if(a.count<b.count) {
          return 1
        }
      });
      this[type].tableData=tableData.slice(0,4);
    }
  }

  filterCheck(value){
    return (this.filterObject.subscriptionList.find(dtl=>dtl.checked&&value.split("/")[2]&&dtl.subscriptionId===(value.split("/")[2])) && this.filterObject.resourceGroupList.find(dtl=>dtl.checked&&value.split("/")[4]&&dtl.resourceGroupName.toLowerCase()===(value.split("/")[4]).toLowerCase()) && this.filterObject.resourceList.find(dtl=>dtl.checked&&(value.split("/")[value.split("/").length-1])&&dtl.resourceName.toLowerCase()===(value.split("/")[value.split("/").length-1]).toLowerCase()))||(this.filterObject.subscriptionList.find(dtl=>dtl.checked&&dtl.subscriptionId===(value.split("/")[2])) && !value.split("/")[4])
  }

  filterDWS(){
    this.devicesWithSecurityData=[];
    this.tempDevicesWithSecurityData.forEach(e=>{
      e.rows.forEach(dt=>{
        if(this.filterCheck(dt[1])){
          this.devicesWithSecurityData.push({
            "computer":dt[0].split('.')[0],
            "securityLogs":dt[2]
          })
        }
      })
    })
    this.setDevicesWithSecurityOptions();
  }

  filterOptionData(optionName,checkCol){
    this[optionName].data=[];
    this[optionName].yAxis=[];
    this[optionName].tempData.forEach(dt=>{
      if(this.filterCheck(dt[checkCol])){
        this[optionName].yAxis.push(dt[0].split(".")[0])
        this[optionName].data.push(optionName==='updateAvailAcrossOption'?dt[1]:dt[2])
      }
    })
  }

  filterTableData(type){
    this[type+"TableData"]=[];
    this[type+"TempTableData"].forEach(dt=>{
      let value;
      switch (type) {
        case 'devicesWithSecurity':
        case 'missingSecurity':
          value=dt[2];
          break;
        case 'updateAvailAcross':
          value=dt[6];
          break;
        case 'devicesWithMost':
        case 'devicesWithFailed':
          value=dt[1];
          break;
        case 'processStartedData':
          value=dt[3];
          break;
      
        default:
          break;
      }
        if(!value||this.filterCheck(value)){
          this[type+"TableData"].push(dt);
        }
    })
  }

  getDevicesWithSecurityData(){
    this.showLoader.decvicesWithSecurity = true;
    this.devicesWithSecurityData = [];
    this.tempDevicesWithSecurityData=[];
    this.getTableData('devicesWithSecurity'); 
    Promise.all(this.filterObject.subscriptionList.map(e=>{
      let body ={
        "subscriptions":[e.subscriptionId],
        "query": SecurityQueries.devicesWithSecurity.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithSecurityFilter.currentFilter))
        //"SecurityEvent | summarize securityLogs = count() by Computer | order by securityLogs desc | take 10 "
      } 
      // this.getTableData('devicesWithSecurity');
      return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true)
    })).then((final:any)=>{
      
      final.forEach((res:any)=>{
        if(!res) return 
        let data =res;
        this.tempDevicesWithSecurityData=data.body.tables;
        this.filterDWS();
        this.showLoader.decvicesWithSecurity = false;
      })
    },err=>{
      this.showLoader.decvicesWithSecurity = false;
    })
    
  }

  getTableData(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": this.getQuery(type)
    }
    this[type+"TableData"]=[];
    this[type+"TempTableData"]=[];
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type+"TempTableData"].push(ds);
        });
      })
      this.filterTableData(type);
    })
  }

  getQuery(type){
    switch(type){
      case 'devicesWithSecurity':
        return SecurityQueries.devicesWithSecurityForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithSecurityFilter.currentFilter))//"SecurityEvent | summarize securityLogs = count() by Computer, Activity | order by securityLogs desc "
      case 'devicesWithFailed':
        return SecurityQueries.devicesWithFailedForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithFailedOption.currentFilter))//"SecurityEvent | where EventID == 4625 | summarize LogonCount = count() by Computer | order by LogonCount desc "
      case 'devicesWithMost':
        return SecurityQueries.devicesWithMostForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithMostOption.currentFilter))//"SecurityEvent | where EventID == 4624 | summarize LogonCount = count() by Computer | order by LogonCount desc "
      case 'missingSecurity':
        return SecurityQueries.missingSecurityForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.missingSecurityOption.currentFilter))//"Update | where Classification in ('Security Updates', 'Critical Updates') | where UpdateState == 'Needed' and Optional == false and Approved == true | summarize count() by Computer,Classification "
      case 'updateAvailAcross':
        return SecurityQueries.updateAvailAcrossForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.updateAvailAcrossOption.currentFilter))//"UpdateSummary  | summarize by Computer, OsVersion, CriticalUpdatesMissing, SecurityUpdatesMissing, OtherUpdatesMissing, TotalUpdatesMissing "
      case 'newProcesesObserved':
        return SecurityQueries.newProcesesObserved.replace('TIMERANGE',this.util.getLastSeenTimeRangeQuery(this[type].currentFilter))
      case 'processStartedData':
        return SecurityQueries.processStartedDataTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'runningProcessesCard':
        return SecurityQueries.processStartedData.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'missingSecurityCard':
        return SecurityQueries.missingSecurity.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'vmFailedCard':
        return SecurityQueries.devicesWithFailed.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
    }
  }

  getUpdateAvailAcross(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.updateAvailAcross.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.updateAvailAcrossOption.currentFilter))
      //"UpdateSummary  | summarize by Computer, TotalUpdatesMissing | order by TotalUpdatesMissing desc | take 10 "
    }
    this.getTableData('updateAvailAcross');
    this.updateAvailAcrossOption.yAxis=[];
    this.updateAvailAcrossOption.data=[];
    this.updateAvailAcrossOption.tempData=[];
    this.showLoader.updateAvailAcrossData = true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.showLoader.updateAvailAcrossData = false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.updateAvailAcrossOption.tempData.push(ds);
        });
      })
      this.filterOptionData('updateAvailAcrossOption',2);
    },err=>{
      this.showLoader.updateAvailAcrossData = false
    })
  }

  getMissingSecurity(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.missingSecurity.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.missingSecurityOption.currentFilter))
      //"Update | where Classification in ('Security Updates', 'Critical Updates') | where UpdateState == 'Needed' and Optional == false and Approved == true | summarize count() by Computer | take 10 "
    }
    this.getTableData('missingSecurity');
    this.missingSecurityOption.yAxis=[];
    this.missingSecurityOption.data=[];
    this.missingSecurityOption.tempData=[];
    this.showLoader.missingSecurityData = true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.showLoader.missingSecurityData = false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.missingSecurityOption.tempData.push(ds);
        });
      })
      this.filterOptionData('missingSecurityOption',1);
    },err=>{
      this.showLoader.missingSecurityData = false
    })
  }
  
  getDevicesWithMost(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.devicesWithMost.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithMostOption.currentFilter))
      //"SecurityEvent | where EventID == 4624 | summarize LogonCount = count() by Computer | order by LogonCount desc | take 10 "
    }
    this.getTableData('devicesWithMost');
    this.devicesWithMostOption.yAxis=[];
    this.devicesWithMostOption.data=[];
    this.devicesWithMostOption.tempData=[];
    this.showLoader.devicesWithMostData = true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.showLoader.devicesWithMostData = false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.devicesWithMostOption.tempData.push(ds);
        });
      })
      this.filterOptionData('devicesWithMostOption',1);
    },err=>{
      this.showLoader.devicesWithMostData = false
    })
  }

  getDevicesWithFailed(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.devicesWithFailed.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithFailedOption.currentFilter))
      //"SecurityEvent | where EventID == 4625 | summarize LogonCount = count() by Computer | order by LogonCount desc | take 10 "
    }
    this.getTableData('devicesWithFailed');
    this.devicesWithFailedOption.yAxis=[];
    this.devicesWithFailedOption.data=[];
    this.devicesWithFailedOption.tempData=[];
    this.showLoader.devicesWithFailedData = true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.showLoader.devicesWithFailedData = false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.devicesWithFailedOption.tempData.push(ds);
        });
      })
      this.filterOptionData('devicesWithFailedOption',1);
    },err=>{
      this.showLoader.devicesWithFailedData = false
    })
  }


  // Charts Options
  setDevicesWithSecurityOptions(){
    let tempData=_.cloneDeep(this.devicesWithSecurityData);
    let xAxisData = tempData.map(e=>e.computer);
    let legendData = [];
    let series = [];
    let  dObj = {
      name: '',
      type: 'bar',
      barWidth: 70,
      stack: 'total',
      // label: {
      //   show: true,
      //   rotate: 90,
      //   fontSize: 10, 
      // },
      emphasis: {
          focus: 'series'
      },
      data: this.devicesWithSecurityData.map(e=>e.securityLogs)
    };
    series.push(dObj);
    //this.devicesWithSecurityData.forEach((e,i)=>{
     // e.data.policyAssignments.forEach((x,j)=>{
        //if(i == 0) legendData.push({name:x.basics.properties.displayName,icon:'circle'})
        // series[i] = dObj
        // series[i]['name'] = e.computer;
        // series[i].data.push(e.securityLogs)
      //})
    //})
    console.log('series',series)
    this.devicesWithSecurityOptions = {
      color: ['#0B70D4','#62C2FB','#FF5959','#656BFD'],
      tooltip: {
          // trigger: 'axis',
          // axisPointer: {            // Use axis to trigger tooltip
          //     type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
          // }
      },
      legend: {
          data: legendData || [],
          bottom: 10,
          left: 'left'
      },
      grid: {
        left: '1%',
        right: '2%',
        bottom: '2%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: xAxisData || [],
        axisTick: {
          show: false
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            return (value.length > 10 ? (value.slice(0,10)+"...") : value )
          }
          // rotate: 30 //If the label names are too long you can manage this by rotating the label.
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#333',
          padding: [5, 0, 0, 0],
          fontSize: 10, 
          formatter: function (params) {
            return params
          },
        },
      },
      series: series
    };
  }

  openPopup(template?: TemplateRef<any>) {
    this.modalService.open(template);
  }

  downloadFile(name,tableId){
    this.util.generateCSVForTableData(name,tableId);
  }

  downloadDirectTableView(name,tableId){
    let table=document.getElementById(tableId);
    if(table) {
      this.downloadFile(name,table)
    }
  }

  // closeModal() {
  //   this.modalService.dismissAll();
  // }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    list.currentFilter.type = value
    switch(list.type){
      case 'devicesWithFailed' :this.getDevicesWithFailed();break;
      case 'devicesWithMost' :this.getDevicesWithMost();break;
      case 'missingSecurity' :this.getMissingSecurity();break;
      case 'updateAvailAcross' :this.getUpdateAvailAcross();break;
      case 'devicesWithSecurity' :this.getDevicesWithSecurityData();break;
      case 'processStartedData' :this.getProcessStartedData();break;
      case 'newProcesesObserved' :this.getNewProcesesObserved('newProcesesObserved');break;
      case 'runningProcessesCard' :this.getCardData('runningProcessesCard');break;
      case 'missingSecurityCard' :this.getCardData('missingSecurityCard');break;
      case 'vmFailedCard' :this.getCardData('vmFailedCard');break;
    }
    this.closeFilter()
  }
  
  applyCustomFilter(){
    this.selectedData.currentFilter.type = 'custom';
    switch(this.selectedData.type){
      case 'devicesWithFailed' : this.devicesWithFailedOption = this.selectedData;this.getDevicesWithFailed();break;
      case 'devicesWithMost' :this.devicesWithMostOption = this.selectedData;this.getDevicesWithMost();break;
      case 'missingSecurity' :this.missingSecurityOption = this.selectedData;this.getMissingSecurity();break;
      case 'updateAvailAcross' :this.updateAvailAcrossOption = this.selectedData;this.getUpdateAvailAcross();break;
      case 'devicesWithSecurity' :this.devicesWithSecurityFilter = this.selectedData;this.getDevicesWithSecurityData();break;
      case 'newProcesesObserved' :this.newProcesesObserved = this.selectedData;this.getNewProcesesObserved('newProcesesObserved');break;
      case 'processStartedData' :this.processStartedData = this.selectedData;this.getProcessStartedData();break;
      case 'runningProcessesCard' :this.runningProcessesCard = this.selectedData;this.getCardData('runningProcessesCard');break;
      case 'missingSecurityCard' :this.missingSecurityCard = this.selectedData;this.getCardData('missingSecurityCard');break;
      case 'vmFailedCard' :this.vmFailedCard = this.selectedData;this.getCardData('vmFailedCard');break;
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 100;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if(!this.timeFilterFlag){
    this.closeFilter();
    }
    this.timeFilterFlag=false;
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.currentFilter.type);
    return findObj&&findObj.name?findObj.name:''
  }

  applyDataFilter(){
    this.filterDWS();
    this.filterOptionData('updateAvailAcrossOption',2);
    this.filterOptionData('missingSecurityOption',1);
    this.filterOptionData('devicesWithFailedOption',1);
    this.filterOptionData('devicesWithMostOption',1);
    this.filterTableData('devicesWithFailed');
    this.filterTableData('devicesWithMost');
    this.filterTableData('devicesWithSecurity');
    this.filterTableData('missingSecurity');
    this.filterTableData('updateAvailAcross');
    this.filterNPOData('newProcesesObserved');
    this.filterAllData('processStartedData');
    this.filterCardData('runningProcessesCard');
    this.filterCardData('missingSecurityCard');
    this.filterCardData('vmFailedCard');
  }


  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    // this.getAllData()
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    console.log('Filter Object',this.filterObject);
    this.applyDataFilter();
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    this.applyDataFilter();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

  async refreshData(type){
    switch (type) {
      case 'resourceHealth':{
        this.refreshedTime.resourceHealth = new Date();
        this.getDevicesWithSecurityData();
        break;
      }
      case 'updateAvailAcross':{
        this.refreshedTime.updateAvailAcross = new Date();
        this.getUpdateAvailAcross();
        break;
      }
      case 'missingSecurity':{
        this.refreshedTime.missingSecurity = new Date();
        this.getMissingSecurity();
        break;
      }
      case 'devicesWithMost':{
        this.refreshedTime.devicesWithMost = new Date();
        this.getDevicesWithMost();
        break;
      }
      case 'devicesWithFailed':{
        this.refreshedTime.devicesWithFailed = new Date();
        this.getDevicesWithFailed();
        break;
      }
      case 'processStartedData':{
        this.refreshedTime.processStartedData = new Date();
        this.getProcessStartedData();
        break;
      }
      case 'newProcesesObserved':{
        this.refreshedTime.newProcesesObserved = new Date();
        this.getNewProcesesObserved('newProcesesObserved');
        break;
      }
      case 'runningProcessesCard':{
        this.refreshedTime.runningProcessesCard = new Date();
        this.getCardData('runningProcessesCard');
        break;
      }
      case 'missingSecurityCard':{
        this.refreshedTime.missingSecurityCard = new Date();
        this.getCardData('missingSecurityCard');
        break;
      }
      case 'vmFailedCard':{
        this.refreshedTime.vmFailedCard = new Date();
        this.getCardData('vmFailedCard');
        break;
      }
    }
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

}
