<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Connection</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 8px;"><i class="fa fa-trash" (click)="deleteNewResourceGroup(item,index)"></i></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 25px;"><img src="/assets/Infra/blueprint/palette/edit.svg" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(null)">Create new
                    </p>
                </div>
            </div> 

            <div class="form-group">
                <div class="label-div">
                    <label for="connectionType" class="control-label">Connection type<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use a VNet-to-VNet connection to connect two Azure virtual networks.Use a site-to-site connection to connect your on-premises network with your Azure virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="connectionTypeList" bindLabel="name" bindValue="id" [(ngModel)]="connectionsmodel.resourceData.connectionType" name="connectionType" placeholder="Choose" [clearable]="false"></ng-select>
                </div>   
            </div>

            <div class="form-group" *ngIf="connectionsmodel.resourceData.connectionType=='IPsec'">
                <div class="label-div">
                    <label for="connectionName" class="control-label ">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar || inputError?.duplicateConnName" id="VNetName" autocomplete="off" [(ngModel)]="connectionsmodel.resourceName" placeholder="Enter a name" name="connectionName" (keyup)="VNetNameValidation($event,80);connNameDuplicateCheck($event,'duplicateConnName')" required>
                    <p class="text-danger" *ngIf="inputError?.VNetName">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateConnName">There is already a resource with the same name and type within the current resource group.</p>
                    <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>

                <div class="label-div">
                    <label for="localNetworkGatewayName" class="control-label">Virtual network gateway<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The first Azure virtual network that will be used by this connection. Traffic will flow from this virtual network to the second virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="virtualNetworkGatewayList" bindLabel="name" [(ngModel)]="connectionsmodel.resourceData.localNetworkGatewayName" name="localNetworkGatewayName" placeholder="" [clearable]="false" required>
                    </ng-select>
                </div>  
            
                <div class="label-div">
                        <label for="localNetworkGatewayRG" class="control-label">Local network gateway<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The local network that will be used by the connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="localNetworkGatewayList" bindLabel="name" [(ngModel)]="connectionsmodel.resourceData.localNetworkGatewayRG" name="localNetworkGatewayRG" placeholder="" [clearable]="false" required>
                        </ng-select>
                    </div>  
            </div>


            <div class="form-group" *ngIf="connectionsmodel?.resourceData?.connectionType!=='IPsec'">
                <div class="input-box">
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="establishBidirectional" id="establishBidirectional" [(ngModel)]="connectionsmodel.resourceData.establishBidirectional"><span>Establish bidirectional connectivity<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip="Connections are unidirectional, and traffic will only flow from the first virtual network gateway to the second one. To establish bidirectional connectivity, check this option, and two connections will be created so that traffic can flow in both directions." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    <div class="form-group" *ngIf="connectionsmodel?.resourceData?.establishBidirectional">
        
                        <div class="label-div">
                            <label for="connectionName" class="control-label ">First connection name<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="inputError?.VNetNameFirstConn || inputError?.VNetCharFirstConn || inputError?.duplicateConnNameF" id="VNetName" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.connectionName" placeholder="Enter a name" name="connectionName" (keyup)="validationFirstConn($event,80);connNameIPsecDuplicateCheck($event,'connectionName','duplicateConnNameF')" required>
                            <p class="text-danger" *ngIf="inputError?.VNetNameFirstConn">The name must be between 1 and 80 characters.</p>
                            <p class="text-danger" *ngIf="inputError?.duplicateConnNameF">There is already a resource with the same name and type within the current resource group.</p>
                            <p class="text-danger" *ngIf="inputError?.VNetCharFirstConn">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                        </div>

                        <div class="label-div">
                            <label for="connectionReverseName" class="control-label ">Second connection name<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="inputError?.VNetNameSecondConn || inputError?.VNetCharSecondConn || inputError?.duplicateConnNameSecondConn" id="VNetName" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.connectionReverseName" placeholder="Enter a name" name="connectionName" (keyup)="validationSecondConn($event,80);connNameIPsecDuplicateCheck($event,'connectionReverseName','duplicateConnNameSecondConn')" required>
                            <p class="text-danger" *ngIf="inputError?.VNetNameSecondConn">The name must be between 1 and 80 characters.</p>
                            <p class="text-danger" *ngIf="inputError?.duplicateConnNameSecondConn">There is already a resource with the same name and type within the current resource group.</p>
                            <p class="text-danger" *ngIf="inputError?.VNetCharSecondConn">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group" *ngIf="!connectionsmodel?.resourceData?.establishBidirectional && connectionsmodel?.resourceData?.connectionType!=='IPsec'">
                <div class="label-div">
                    <label for="connectionName" class="control-label ">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar || inputError?.duplicateConnName" id="VNetName" autocomplete="off" [(ngModel)]="connectionsmodel.resourceName" placeholder="Enter a name" name="connectionName" (keyup)="VNetNameValidation($event,80);connNameDuplicateCheck($event,'duplicateConnName')" required>
                    <p class="text-danger" *ngIf="inputError?.VNetName">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateConnName">There is already a resource with the same name and type within the current resource group.</p>
                    <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>              
            </div>
    
            <div class="form-group" *ngIf="connectionsmodel?.resourceData?.connectionType!=='IPsec'">
                <div class="label-div">
                    <label for="virtualNetworkGatewayName" class="control-label">First virtual network gateway<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The first Azure virtual network that will be used by this connection.Traffic will flow from this virtual network to the second virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="virtualNetworkGatewayList" bindLabel="name" [(ngModel)]="connectionsmodel.resourceData.virtualNetworkGatewayName" name="virtualNetworkGatewayName" placeholder="" [clearable]="false" required>
                    </ng-select>
                </div>  
            </div>

            <div class="form-group" *ngIf="connectionsmodel?.resourceData?.connectionType!=='IPsec'">
                    <div class="label-div">
                        <label for="virtualNetworkGatewayRG" class="control-label">Second virtual network gateway<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The second Azure virtual network that will be used by this connection.Traffic will flow from the first virtual network to this one." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="virtualNetworkGatewayList" bindLabel="name" [(ngModel)]="connectionsmodel.resourceData.virtualNetworkGatewayRG" name="virtualNetworkGatewayRG" placeholder="" [clearable]="false" required>
                        </ng-select>
                    </div>  
            </div>


            <div class="form-group">
                <div class="label-div">
                    <label for="sharedKey" class="control-label ">Shared key (PSK)<span class="mandatory">*<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip="A mixture of letters and numbers, used to establish encryption for the connection.The same shared key must be used in both the virtual network and local network gateways.If your gateway device doesn't provide one, you can make one up here and provide it to your device." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.sharedKeyName || inputError?.sharedKeyChar" id="sharedKey" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.sharedKey" placeholder="" name="sharedKey" (keyup)="sharedKeyNameValidation($event,1,128);" required>
                    <p class="text-danger" *ngIf="inputError?.sharedKeyName">A shared key must contain between 1 to 128 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.sharedKeyChar">The field can contain only letters and numbers.</p>
                </div>              
            </div>
            
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label ">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="connectionsmodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div>
                
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
            
        <div *ngIf="showView">

        <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="IKEProtocol" class="control-label ">IKE Protocol<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IKE protocol used by this connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IKEProtocol" [value]="'IKEv1'" [disabled]="connectionsmodel.resourceData.connectionType=='IPsec'" ([ngModel])="connectionsmodel.resourceData.IKEProtocol" [checked]="connectionsmodel.resourceData.IKEProtocol=='IKEv1'" (change)="connectionsmodel.resourceData.IKEProtocol=$event.target.value;"><span>IKEv1</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IKEProtocol" [value]="'IKEv2'" [disabled]="connectionsmodel.resourceData.connectionType=='IPsec'" ([ngModel])="connectionsmodel.resourceData.IKEProtocol" [checked]="connectionsmodel.resourceData.IKEProtocol=='IKEv2'" (change)="connectionsmodel.resourceData.IKEProtocol=$event.target.value;"><span>IKEv2</span></label>                    
            </div>             
        </div>

        <div class="form-group">
            <div class="input-box">
                <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="useLocalAzureIpAddress" id="useLocalAzureIpAddress" [(ngModel)]="connectionsmodel.resourceData.useLocalAzureIpAddress" ><span>Use Azure Private IP Address<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Checking this option allows you to use Azure private IPs to establish an IPsec VPN connection" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
        </div>

        <div class="form-group">
            <div class="input-box">
                <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enableBgp" id="enableBgp" [(ngModel)]="connectionsmodel.resourceData.enableBgp" ><span>Enable BGP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Border gateway protocol" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
        </div>
        
        <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="connectionsmodel?.resourceData?.IKEProtocol!=='IKEv1'">
            <div class="label-div">
                <label for="ipsecPolicies" class="control-label ">IPsec / IKE policy<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IPsec / IKE policy will be negotiated while dialing the connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="ipsecPolicies" [value]="'Default'" ([ngModel])="connectionsmodel.resourceData.ipsecPolicies" [checked]="connectionsmodel.resourceData.ipsecPolicies=='Default'" (change)="connectionsmodel.resourceData.ipsecPolicies=$event.target.value;"><span>Default</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="ipsecPolicies" [value]="'Custom'" ([ngModel])="connectionsmodel.resourceData.ipsecPolicies" [checked]="connectionsmodel.resourceData.ipsecPolicies=='Custom'" (change)="connectionsmodel.resourceData.ipsecPolicies=$event.target.value;"><span>Custom</span></label>                    
            </div>             
        </div>

        <div *ngIf="connectionsmodel.resourceData.ipsecPolicies=='Custom'">
            <div class="form-group">
                <div class="label-div">
                    <label for="IKEPhase1" class="control-label ">IKE Phase 1<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IKE Phase 1" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>

                <div class="label-div">
                    <label for="ikeEncryption" class="control-label">Encryption<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="ikeEncryptionList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.ikeEncryption" name="ikeEncryption" placeholder="" [clearable]="false"></ng-select>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="ikeIntegrity" class="control-label">Integrity/PRF<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="ikeIntegrityList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.ikeIntegrity" name="ikeIntegrity" placeholder="" [clearable]="false"></ng-select>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="dhGroup" class="control-label">DH Group<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="dhGroupList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.dhGroup" name="dhGroup" placeholder="" [clearable]="false"></ng-select>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="IKEPhase2(IPsec)" class="control-label ">IKE Phase 2(IPsec)<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="KE phase 2 policy will be used for IPsec Quick Mode (QM) SA creation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>

                <div class="label-div">
                    <label for="ipsecEncryption" class="control-label">IPsec Encryption<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="ipsecEncryptionList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.ipsecEncryption" name="ipsecEncryption" placeholder="" [clearable]="false"></ng-select>
                </div>      
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="ipsecIntegrity" class="control-label">IPsec Integrity/PRF<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="ipsecIntegrityList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.ipsecIntegrity" name="ipsecIntegrity" placeholder="" [clearable]="false"></ng-select>
                </div>      
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="pfsGroup" class="control-label">PFS Group<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="pfsGroupList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.pfsGroup" name="pfsGroup" placeholder="" [clearable]="false"></ng-select>
                </div>      
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="saDataSizeKilobytes" class="control-label">IPsec SA lifetime in KiloBytes<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The IPsec(QM) SA will expire after this much kilobytes of data is transported over the connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.saDataSizelimit||inputError?.saDataTimeNum" id="saDataSizeKilobytes" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.saDataSizeKilobytes" placeholder="" name="saDataSizeKilobytes" (keyup)="changesaDataSizeLimit($event)">
                <p class="text-danger" *ngIf="inputError?.saDataSizelimit">SA life time in kilobytes must be between 1024 and 2147483647.</p>
                <p class="text-danger" *ngIf="inputError?.saDataTimeNum">Value must be a valid number.</p>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="saLifeTimeSeconds" class="control-label">IPsec SA lifetime in seconds<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The IPsec(QM) SA will expire after this time." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.saLifeTimeLimit||inputError?.saLifeTimeNum" id="saLifeTimeSeconds" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.saLifeTimeSeconds" placeholder="" name="saLifeTimeSeconds" (keyup)="changesaLifeTimeLimit($event)">
                <p class="text-danger" *ngIf="inputError?.saLifeTimeLimit">SA life time in seconds must be between 300 and 172799.</p>
                <p class="text-danger" *ngIf="inputError?.saLifeTimeNum">Value must be a valid number.</p>
                </div>
            </div>
        </div>

        <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="connectionsmodel?.resourceData?.IKEProtocol!=='IKEv1'">
            <div class="label-div">
                <label for="usePolicyBasedTrafficSelectors" class="control-label ">Use policy based traffic selectors<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Setting Use policy based traffic selector to true on a connection will configure the Azure VPN gateway to connect to policy-based VPN firewall on premises." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="usePolicyBasedTrafficSelectors" [value]="'Enable'" ([ngModel])="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors" [checked]="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors=='Enable'" (change)="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors=$event.target.value;"><span>Enable</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="usePolicyBasedTrafficSelectors" [value]="'Disable'" ([ngModel])="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors" [checked]="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors=='Disable'" (change)="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors=$event.target.value;"><span>Disable</span></label>                    
            </div>             
        </div>

        <div class="form-group" *ngIf="connectionsmodel?.resourceData?.IKEProtocol!=='IKEv1'">
            <div class="label-div">
                <label for="dpdTimeoutSeconds" class="control-label">DPD timeout in seconds<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Dead Peer Detection Timeout of the connection in seconds" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
            <input type="text" class="form-control" [class.border-danger]="inputError?.dpdtimeout||inputError?.dpdtimeoutNum" id="dpdTimeoutSeconds" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.dpdTimeoutSeconds" placeholder="" name="idleTimeout" (keyup)="changedpdTimeout($event)">
            <p class="text-danger" *ngIf="inputError?.dpdtimeout">DPD timeout in seconds must be between 9 and 3600.</p>
            <p class="text-danger" *ngIf="inputError?.dpdtimeoutNum">Value must be a valid number.</p>
            </div>              
        </div>

        <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="connectionsmodel?.resourceData?.IKEProtocol!=='IKEv1'">
            <div class="label-div">
                <label for="connectionMode" class="control-label">Connection mode<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Connection mode is used to decide which gateway can initiate the connection.
                    When this value is set to:
                    Default: both Azure and on-premise VPN gateway can initiate the connection.
                    ResponderOnly: Azure VPN gateway will never initiate the connection. On-premise 
                    VPN gateway must initiate the connection.
                    InitiatorOnly: Azure VPN gateway will initiate the connection and reject any connection
                    attempts from on-premise VPN gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box radio-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="connectionMode" [value]="'Default'" ([ngModel])="connectionsmodel.resourceData.connectionMode" [disabled]="connectionsmodel.resourceData.connectionType=='Vnet2Vnet'" [checked]="connectionsmodel.resourceData.connectionMode=='Default'" (change)="connectionsmodel.resourceData.connectionMode=$event.target.value;"><span>Default</span></label>
                <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="connectionMode" [value]="'InitiatorOnly'" ([ngModel])="connectionsmodel.resourceData.connectionMode" [disabled]="connectionsmodel.resourceData.connectionType=='Vnet2Vnet'" [checked]="connectionsmodel.resourceData.connectionMode=='InitiatorOnly'" (change)="connectionsmodel.resourceData.connectionMode=$event.target.value;"><span>InitiatorOnly</span></label>
                <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="connectionMode" [value]="'ResponderOnly'" ([ngModel])="connectionsmodel.resourceData.connectionMode" [disabled]="connectionsmodel.resourceData.connectionType=='Vnet2Vnet'" [checked]="connectionsmodel.resourceData.connectionMode=='ResponderOnly'" (change)="connectionsmodel.resourceData.connectionMode=$event.target.value;"><span>ResponderOnly</span></label>
            </div>             
        </div>    

        
            <div class="form-group">
                <div class="label-div d-flex">
                <label class="control-label " style="min-width: max-content;">Tag name </label>
                <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                </label>
                <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="connectionsmodel.resourceData&&connectionsmodel.resourceData.tagList&&connectionsmodel.resourceData.tagList.length>1"></label>
                <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                </div>
                <div *ngFor="let item of connectionsmodel.resourceData.tagList;let i = index">
                <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                    <input type="text" class="form-control" id="tagName" autocomplete="off"
                        [(ngModel)]="item.tagName" name="tagName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                    <input type="text" class="form-control" id="tagValue" autocomplete="off"
                        [(ngModel)]="item.value" name="tagValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                        *ngIf="connectionsmodel.resourceData.tagList.length>1"></span>
                </div>
                <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div  class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                </div>
            </div>
                
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
        </div>
    </div>
</div>    
</div>   