<div class="overview-content">
    <div class="header-container">
        <h2 class="title">Enqueue Statistics</h2>
    </div>

     <!-- Filter Section -->
     <div class="d-flex">
        <div class="custom-filter-wrapper flex-custom" style=" width: 100%;">
            <div class="custom-filter">
                <span class="text">Subscription</span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <div class="enlist-srch">
                        <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                        <img src="assets/icons/search.png">
                    </div>
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-dropdown" [formGroup]="processDetails">
                <ng-select [items]="providerList" *ngIf="providerList.length" [searchable]="false" bindLabel="name"
                  bindValue="name" name="provider" formControlName="provider" [closeOnSelect]="true" [clearable]="false"
                  id="provider" (change)="changeFilter()">
                  <ng-template ng-label-tmp let-item="item">
                    SID: {{item?.name}}
                  </ng-template>
                </ng-select>
            </div>
            <div class="custom-filter">
                <span class="text">App server</span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngIf="processList?.length>1">
                            <input type="checkbox" class="form-check-inline" name="f_listItem_{{processList.length}}" (change)="checkedAll($event)" [checked]="checkedAllAppServer()">
                            <label class="value" for="f_listItem_{{processList.length}}" id="f_listItemName_{{processList.length}}">All</label>
                        </div>
                        <div class="checkbox-cs" *ngFor="let data of processList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="checkedValue(data,$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.name}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!processList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-select *ngIf="timeFilterView" [items]="timeFilterList" [searchable]="false" bindLabel="name" bindValue="id" [closeOnSelect]="true" name="timeRange" placeholder="Time Range:" style="width: 200px;" (change)="currentFilter.type=='custom'?openModal(openCal,null):applyFilter(currentFilter.type,null)" [(ngModel)]="currentFilter.type" [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
                Time Range: {{item?.name}}
            </ng-template>
        </ng-select>
    </div>
    <div class="row custom-padding">
        <div class="col-md-12 right">
            <div class="graph-div card-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Enqueue Lock Statistics</h4>
                            <p>Refreshed: {{refreshedTimeFormat(enqueueLockStatistics?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('enqueueLockStatistics')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="row enqueue-lock-statistics" *ngIf="!enqueueLockStatistics?.showLoader">
                  <div class="col-md-4">
                      <span class="header-enqueue-lock">Number of lock requests</span>
                      <span class="value-enqueue-lock">{{enqueueLockStatistics?.numberLockRequests&&enqueueLockStatistics?.numberLockRequests[0]?filterAmtVal(enqueueLockStatistics?.numberLockRequests[0],false):'0'}} <span class="value-unit-enqueue-lock">{{filterAmtVal(enqueueLockStatistics?.numberLockRequests[0],true)}}</span></span>
                      <span class="perc-enqueue-lock" *ngIf="enqueueLockStatistics?.numberLockRequests"><img [src]="enqueueLockStatistics?.numberLockRequests&&enqueueLockStatistics?.numberLockRequests[1]<0?'assets/monitor/SAPHANA/down-trendline.svg':'assets/monitor/SAPHANA/up-trendline.svg'" alt="">{{enqueueLockStatistics?.numberLockRequests[2]}}%</span>
                  </div>
                  <div class="col-md-4">
                      <span class="header-enqueue-lock">Rejected lock requests</span>
                      <span class="value-enqueue-lock">{{enqueueLockStatistics?.rejectedLockRequests&&enqueueLockStatistics?.rejectedLockRequests[0]?filterAmtVal(enqueueLockStatistics?.rejectedLockRequests[0],false):'0'}} <span class="value-unit-enqueue-lock">{{filterAmtVal(enqueueLockStatistics?.rejectedLockRequests[0],true)}}</span></span>
                      <span class="perc-enqueue-lock" *ngIf="enqueueLockStatistics?.rejectedLockRequests"><img [src]="enqueueLockStatistics?.rejectedLockRequests&&enqueueLockStatistics?.rejectedLockRequests[1]<0?'assets/monitor/SAPHANA/down-trendline.svg':'assets/monitor/SAPHANA/up-trendline.svg'" alt="">{{enqueueLockStatistics?.rejectedLockRequests[2]}}%</span>
                  </div>
                  <div class="col-md-4">
                      <span class="header-enqueue-lock">Enqueue Errors</span>
                      <span class="value-enqueue-lock">{{enqueueLockStatistics?.enqueueErrors&&enqueueLockStatistics?.enqueueErrors[0]?filterAmtVal(enqueueLockStatistics?.enqueueErrors[0],false):'0'}} <span class="value-unit-enqueue-lock">{{filterAmtVal(enqueueLockStatistics?.enqueueErrors[0],true)}}</span></span>
                      <span class="perc-enqueue-lock" *ngIf="enqueueLockStatistics?.enqueueErrors"><img [src]="enqueueLockStatistics?.enqueueErrors&&enqueueLockStatistics?.enqueueErrors[1]<0?'assets/monitor/SAPHANA/down-trendline.svg':'assets/monitor/SAPHANA/up-trendline.svg'" alt="">{{enqueueLockStatistics?.enqueueErrors[2]}}%</span>
                  </div>
                </div>
                <div class="row enqueue-lock-statistics" *ngIf="!enqueueLockStatistics?.showLoader">
                  <div class="col-md-4">
                      <span class="header-enqueue-lock">Average time for locked operations</span>
                      <span class="value-enqueue-lock">{{enqueueLockStatistics?.avgTimeLockOperations&&enqueueLockStatistics?.avgTimeLockOperations[0]?millisToMinutesAndSeconds(enqueueLockStatistics?.avgTimeLockOperations[0],false):'0'}} <span class="value-unit-enqueue-lock">{{millisToMinutesAndSeconds(enqueueLockStatistics?.avgTimeLockOperations[0],true)}}</span></span>
                      <span class="perc-enqueue-lock" *ngIf="enqueueLockStatistics?.avgTimeLockOperations"><img [src]="enqueueLockStatistics?.avgTimeLockOperations&&enqueueLockStatistics?.avgTimeLockOperations[1]<0?'assets/monitor/SAPHANA/down-trendline.svg':'assets/monitor/SAPHANA/up-trendline.svg'" alt="">{{enqueueLockStatistics?.avgTimeLockOperations[2]}}%</span>
                  </div>
                  <div class="col-md-4">
                      <span class="header-enqueue-lock">Average waiting time taken for locked operations</span>
                      <span class="value-enqueue-lock">{{enqueueLockStatistics?.avgWaitingTimeLockOperations&&enqueueLockStatistics?.avgWaitingTimeLockOperations[0]?secondToMinutesAndHours(enqueueLockStatistics?.avgWaitingTimeLockOperations[0],false):'0'}} <span class="value-unit-enqueue-lock">{{secondToMinutesAndHours(enqueueLockStatistics?.avgWaitingTimeLockOperations[0],true)}}</span></span>
                      <span class="perc-enqueue-lock" *ngIf="enqueueLockStatistics?.avgWaitingTimeLockOperations"><img [src]="enqueueLockStatistics?.avgWaitingTimeLockOperations&&enqueueLockStatistics?.avgWaitingTimeLockOperations[1]<0?'assets/monitor/SAPHANA/down-trendline.svg':'assets/monitor/SAPHANA/up-trendline.svg'" alt="">{{enqueueLockStatistics?.avgWaitingTimeLockOperations[2]}}%</span>
                  </div>
                  <div class="col-md-4">
                      <span class="header-enqueue-lock">Average waiting time taken in enqueue server</span>
                      <span class="value-enqueue-lock">{{enqueueLockStatistics?.avgTimeTakenEnqueueServer&&enqueueLockStatistics?.avgTimeTakenEnqueueServer[0]?millisToMinutesAndSeconds(enqueueLockStatistics?.avgTimeTakenEnqueueServer[0],false):'0'}} <span class="value-unit-enqueue-lock">{{millisToMinutesAndSeconds(enqueueLockStatistics?.avgTimeTakenEnqueueServer[0],true)}}</span></span>
                      <span class="perc-enqueue-lock" *ngIf="enqueueLockStatistics?.avgTimeTakenEnqueueServer"><img [src]="enqueueLockStatistics?.avgTimeTakenEnqueueServer&&enqueueLockStatistics?.avgTimeTakenEnqueueServer[1]<0?'assets/monitor/SAPHANA/down-trendline.svg':'assets/monitor/SAPHANA/up-trendline.svg'" alt="">{{enqueueLockStatistics?.avgTimeTakenEnqueueServer[2]}}%</span>
                  </div>
                </div>
                <div class="loader-gif custom-loader-gif" *ngIf="enqueueLockStatistics?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <!-- <div class="dash-text-center" *ngIf="(!enqueueLockStatistics?.tableData?.length && !enqueueLockStatistics?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div> -->
            </div>
        </div>
        <div class="col-md-12 right">
            <div class="graph-div card-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Active Work Process (SM66)</h4>
                            <p>Refreshed: {{refreshedTimeFormat(activeWorkProcessEnqueue?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('activeWorkProcessEnqueue')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="activeWorkProcessEnqueue?.tableData?.length && !activeWorkProcessEnqueue?.showLoader">
                  <table class="table">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Lock Object Type</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeWorkProcessEnqueue',0)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Status</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeWorkProcessEnqueue',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Avergae Number Of Objects</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeWorkProcessEnqueue',2)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Max Number of Objects</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeWorkProcessEnqueue',3)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Object Limit</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activeWorkProcessEnqueue',4)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of activeWorkProcessEnqueue.tableData">
                            <tr>
                                <td>{{row[0]}}</td>
                                <td>
                                    <div class="chip" [ngClass]="getStatusClass(row[1])">
                                        {{row[1] | titlecase }}
                                      </div>
                                </td>
                                <td>{{row[2]}}</td>
                                <td>{{row[3]}}</td>
                                <td>{{row[4]}}</td>
                            </tr>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif custom-loader-gif" *ngIf="activeWorkProcessEnqueue?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!activeWorkProcessEnqueue?.tableData?.length && !activeWorkProcessEnqueue?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 right">
            <div class="graph-div custom-progress-card">
                <div class="heading">
                    <div class="d-flex flex-align-custom">
                        <div class="about">
                            <h4>Enqueue Locks</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(enqueueLocks?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('enqueueLocks')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="enqueueLocks?.series?.length && !enqueueLocks?.showLoader">
                    <div class="chart-ht" echarts [options]="enqueueLocks?.options" [loading]="true"></div>
                    <div class="d-flex" *ngIf="enqueueLocks?.averageList?.length">
                        <ng-container *ngFor="let item of enqueueLocks?.averageList" >
                          <div class="bot-strip" [ngStyle]="{'border-color':item.colorCode}">
                              <div class="str-name">{{item.name}}</div>
                              <div class="str-value">{{item.average}}</div>
                          </div>
                        </ng-container>
                    </div>
                </ng-container>
                <div class="loader-gif custom-loader-gif" *ngIf="enqueueLocks?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!enqueueLocks?.series?.length && !enqueueLocks?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
    
    </div>
</div>

<ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>