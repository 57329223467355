<div class="introduction">
          <p class="heading" id="1">In Review  </p>
          <ol>
              <li>Create a new project.</li>
              <li>Draw architecture diagram. </li>
              <li>Click <b>Submit for review button.</b> </li>
              <img src="assets\user-manual\apps-&-infra\submit-review.png" >
              <figcaption class="caption">Submit for review </figcaption>
              <li>A3S architecture team will review the architecture diagram. </li>
              <li>Project status is ‘In Review’. </li>
              <img src="assets\user-manual\apps-&-infra\Infra-BP.svg " >
              <figcaption class="caption">Under review </figcaption>
             
          </ol>
             
</div>