
<ng-container *ngIf="!isApplicationLoaded">
  <div class="navbar skelton-loader">
    <mat-drawer-container class="example-container" >
      <mat-toolbar class="justify-content-center" style="overflow: hidden;">
        <div class="nav-top-left mr-auto">
          <span class="not-icon pointer-event-none">
            <a class="">
              <ngx-skeleton-loader appearance="circle" [theme]="{
                width: '20px',
                height: '20px',
                'border-radius': '4px',
                'background-color': '#2989E9'
              }"></ngx-skeleton-loader>
            </a>
          </span>
          <a class="navbar-brand pointer-event-none">
            <span class="brand-name">
              <ngx-skeleton-loader [theme]="{
                width: '189px',
               'border-radius': '32px',
                height: '18px',
                'margin-bottom': '0',
                'background-color': '#2989E9'
              }"></ngx-skeleton-loader>
            </span>
          </a>
        
        </div>
        <!-- <span class="example-spacer"></span> -->
        <div class="notif-profile ml-auto">
          <div class="not-icon pointer-event-none" style="left: -35px;">
            <a>
              <ngx-skeleton-loader appearance="circle" [theme]="{
                width: '20px',
                height: '20px',
                'border-radius': '4px',
                'background-color': '#2989E9'
              }"></ngx-skeleton-loader>
            </a>
          </div>
          <div class="not-icon pointer-event-none">
            <a class="">
              <ngx-skeleton-loader appearance="circle" [theme]="{
                width: '20px',
                height: '20px',
                'border-radius': '4px',
                'background-color': '#2989E9'
              }"></ngx-skeleton-loader>
            </a>
            
          </div>
          <div class="knowledge pointer-event-none">
            <a>
              <ngx-skeleton-loader appearance="circle" [theme]="{
                width: '20px',
                height: '20px',
                'border-radius': '4px',
                'background-color': '#2989E9'
              }"></ngx-skeleton-loader>
            </a>
          </div>
          
    
            <!-- Profile -->
          <div class="profile pointer-event-none">
            <span class="">
              <ngx-skeleton-loader appearance="circle" [theme]="{
                width: '24px',
                height: '24px',
                'border-radius': '20px',
                'background-color': '#2989E9'
              }"></ngx-skeleton-loader>
            </span>
          </div>

        </div>
       </mat-toolbar>
    </mat-drawer-container>
  </div>
</ng-container>

<ng-container *ngIf="isApplicationLoaded">
  <div class="navbar">
    <mat-drawer-container class="example-container" [hasBackdrop]="true">
      <mat-toolbar class="justify-content-center">
        <div class="nav-top-left mr-auto">
          <span class="nav-top-left-btn hover-blue " matTooltip="Portal Menu" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
            <button (click)="drawer.toggle()" mat-icon-button class="example-icon"
            aria-label="Example icon-button with menu icon">
            <mat-icon>menu</mat-icon>
            </button>
          </span>
            <a class="navbar-brand hover-blue" routerLink="/a3s/home" matTooltip="Home" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">              
              <img src="assets/navbar/a3s-logo.svg" width="30" height="30" class="d-inline-block" alt="">
              <span class="brand-name">AI Powered Azure Automation </span>
            </a>
        </div>
        
        <div class="search-content">
          <div class="enlist-srch global-search">
            <img src="assets/icons/search.png">
            <input [(ngModel)]="searchText" placeholder="Search Projects and A3S documentation" autocomplete="off" class="form-control" type="text" id="projectSearch" 
               (keyup)="search($event)" >  
          </div>

          <div class="search-expand">
            <div class="search-result">
                <p class="result-head" >Project </p>
                <p class="projectName" *ngFor="let list of result" (click)="route(list)">{{list?.searchProject}}
                <span class="subtext">({{list?.projectTitle}})</span></p>
                <p class="noresult" *ngIf="!result?.length" > No results found</p>
          
                <hr style="margin: 4px 0px;">
                <p class="result-head">A3S Documentation</p>
                <p class="projectName" *ngFor="let list of manualResult" (click)="changeTab(list)" >
                {{list?.value}}
                  <ng-container *ngIf ="list?.type !=='parent'">
                        <span class="subtext">({{list?.Title}})</span>
                  </ng-container>
                        <span><img class="ellipis" src="assets\user-manual\new-tab.svg"></span></p>
                 
                <p  class="noresult" *ngIf="!manualResult?.length" > No results found </p>
          
                <!-- <hr style="margin: 4px 0px;">
                <p class="result-head">Azure Documentation</p>
                <p class="noresult" >No results found</p> -->
            </div>
          </div>
          
        </div>
      

        <div class="notif-profile ml-auto">
          <div class="not-icon text-center hover-blue" matTooltip="Support Chatbox" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
            <a><img style="width: 20px;height: 20px;" src="assets/icons/chatBot.svg" alt="" (click)="openSupportChatBot()"></a>
            <!-- <mat-menu #chatbot="matMenu" class="notification-popup">
              <div class="chatbot" *ngIf="this.flowservice.showIframe">
                <div class="header">
                  <div class="logo">
                    <img src="assets/icons/dynapt-blue-back.svg" alt="">
                    <p>A3S Support</p>
                  </div>
  
                  <div class="icons">
                    <i class="fa fa-times" (click)="this.flowservice.showIframe=false"></i>
                  </div>
                </div>
                <div class="chat-bot-area">
                  <div class="cssload-wrench" *ngIf="isLoading"></div>
                  <iframe [src]='this.flowservice.iframeSRC' (load)="isLoading=false" [class.d-none]="isLoading"></iframe>
                </div>
              </div>
            </mat-menu> -->
            

          </div>
          <div class="not-icon text-center hover-blue" matTooltip="Notifications" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
            <a class="">
              <img src="assets/icons/notifications.svg" [matMenuTriggerFor]="notification"
                (click)="notificationread();endNotification=5">
              <span class="badge" *ngIf="showBadge()">
                {{NotificationMessage?.length + NotificationList?.length}}
              </span>
            </a>
       
            <mat-menu #notification="matMenu" class="notification-popup">
              <div class="notification-header flex-custom no-wrap align-items-baseline">
                <h2>Notifications</h2>
                <span class="blur-text blue-text cursor-pointer ml-auto mr-3" (click)="updateViewAll()" *ngIf="NotificationList?.length">Mark all as seen</span>
              </div>
              <ng-container *ngIf="NotificationMessage.length > 0">

                <div class="notification-content p-0" 
                  *ngFor="let item of NotificationMessage.reverse() | slice:0:endNotification">
                  <!--progess notifications with %-->
                  <div class="alert mb-0" *ngIf="item.is_progress">
                    <div class="d-flex" [class.pointer]="item.is_navigation" (click)="notification_navigation(item)">
                      <div class="alert-icon">
                        <!-- progress and running notifications image-->
                        <img *ngIf="!item.is_error_occurred && item.progress.jobs_complete < item.progress.total_jobs"
                          height="16px" width="16px" src="assets/icons/right-progress.svg">
                        <!-- process complete image-->
                        <img *ngIf="!item.is_error_occurred && item.progress.jobs_complete >= item.progress.total_jobs"
                          height="16px" width="16px" src="assets/icons/green-tick.svg">
                        <!-- error image-->
                        <img *ngIf="item.is_error_occurred" height="16px" width="16px" src="assets/icons/red-wrong.svg">
                      </div>
                      <div class="full-width notif-line d-flex">
                        <div>
                          <p class="mb-0 notif-msg" *ngIf="!item.is_error_occurred">
                            {{item.Message}}
                          </p>
                          <p class="text-danger mb-0 notif-msg" *ngIf="item.is_error_occurred">{{item.error_message}}</p>                      
                          <span class="text-secondary pt-1">
                            {{ item.progress.jobs_complete / item.progress.total_jobs * 100 | number}} % completed
                          </span>
                        </div>
                        <div class="notif-time">
                          <p class="blur-text ml-auto float-right ">{{item.MessageTime}}</p>
                        </div>
                        
                      </div>
                    </div>
                  </div>
      
                  <!--simple notification-->
                  
                  <div class="alert mb-0" *ngIf="!item.is_progress">
                    <div class="d-flex" [class.pointer]="item.isNavigation" (click)="notification_navigation(item)">
                      <div class="alert-icon">
                        <img height="16px" width="16px" *ngIf="item.processType == 'diagramToolReviewComplete' " src="assets/icons/green-tick.svg">
                        <img height="16px" width="16px" *ngIf="item.processType == 'commentNotification' " src="assets/icons/comment-icon.svg">
                        <img height="16px" width="16px" *ngIf="item.processType == 'sap' || item.processType == 'sql' " src="assets/icons/right-progress.svg">
                      </div>
                      <div class="notif-line d-flex">
                        <div class="notif-content">
                          <span class="notif-msg">{{item.Message || item.message}}</span> 
                        </div>
                        <div class="notif-time">
                          <span class="blur-text  " >{{item.createdDate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                </div>
              </ng-container>  

              <ng-container *ngIf="NotificationList.length > 0">
                <div class="notification-content p-0"  *ngFor="let item of NotificationList.reverse()">
                  
                  <div class="alert mb-0" *ngIf="!item?.isProgress">
                    <div class="d-flex" [class.pointer]="item?.isNavigation" (click)="notification_navigation(item)">
                      <div class="alert-icon">
                        <img height="16px" width="16px" *ngIf="item.processType == 'diagramToolReviewComplete' || item.processType == 'bpDeploymentSuccess' || item.processType == 'frAnalyticsSuccess' || item.processType == 'MFAnalyticsSuccess' || item.processType == 'bpParameterSuccess' " src="assets/icons/green-tick.svg">
                        <img height="16px" width="16px" *ngIf="item.processType == 'commentNotification' " src="assets/icons/comment-icon.svg">
                        <img height="16px" width="16px" *ngIf="item.processType == 'sap' || item.processType == 'sql' || item.processType == 'bpDeploymentStarted' || item.processType == 'frAnalytics' || item.processType == 'MFAnalytics' " src="assets/icons/right-progress.svg">
                        <img height="16px" width="16px" *ngIf="item.processType == 'bpDeploymentFailed' || item.processType == 'frAnalyticsFailed' || item.processType == 'MFAnalyticsFailed' " src="assets/icons/red-wrong.svg">
                      </div>
                      <div class="notif-line d-flex">
                        <div class="notif-content">
                          <span class="notif-msg">{{item?.message || item?.Message}}</span> 
                          <p class="blur-text view-pr-sum" *ngIf="item.processType == 'bpDeploymentSuccess'">View project summary</p>
                        </div>
                        <div class="notif-time">
                          <span class="blur-text">{{changeTimeFormat(item?.createdDate)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                </div>
              </ng-container>

    
              <!--no notification found!-->
              <div *ngIf="!NotificationMessage.length && !NotificationList.length" class="no-notification">
                <span class="" mat-menu-item >No Notifications Available</span>
              </div>
            </mat-menu>

          </div>
              <!-- Knowledge Center -->
            <div class="knowledge text-center hover-blue" matTooltip="Knowledge Center" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
              <a href="/UserManual" target="_blank" (click)="setValue()">
                <img src="assets/icons/knowledge-centre.svg" alt="">
              </a>
            </div>
    
            <!-- Profile -->
          <div class="profile text-center hover-blue">
            <span *ngIf="profileImg" class="profile-pic" [matMenuTriggerFor]="profile"><img src="{{profileImg}}"  /></span>
            <span *ngIf="!profileImg" class="profile-name" [matMenuTriggerFor]="profile">{{usernameInitials}}</span>
            
            <mat-menu #profile="matMenu" class="navbat-mat-menu">
              <div class="d-flex navbar-profile-head" mat-menu-item>
                <div> 
                  <div class="no-pic-initials-popup" *ngIf="!profileImg">
                    <span class="img-fluid rounded-circle img-responsive">{{usernameInitials}}</span>
                  </div>
                  <span *ngIf="profileImg" class="img-fluid rounded-circle img-responsive"><img src="{{profileImg}}"  /></span>
                  <!-- <img [src]="profileImg || 'assets/icons/pro-pic.png'" class="img-fluid rounded-circle p-2 img-responsive" /> -->
                </div>
                <div class="head-details">
                  <p class=" m-0 username">{{loginUser}}</p>
                  <p class="text-muted userEmailID">{{userEmailProfile}}</p>
                  <span class="a3s-access-chip" [ngClass]="getRoleClass(a3sUserDetails?.roleName)">{{ a3sUserDetails?.roleName ? a3sUserDetails?.roleName : 'No access' }}</span>
                </div>
              </div>
              <div class=" d-flex align-items-center navbar-menu-item" mat-menu-item routerLink="/a3s/governanceBucket/a3sAccessControl" title="" *ngIf="checkAccessControl()">
                <img src="assets/navbar/navbar-access-control.svg" class="img-fluid img-responsive" />
                <p class="m-0 option">A3S Access Control</p>
              </div>
              <div class=" d-flex align-items-center navbar-menu-item" mat-menu-item routerLink="/a3s/accountSettings" title="">
                <img src="assets/icons/user.svg" class="img-fluid img-responsive" />
                <p class="m-0 option">My account</p>
              </div>
              <button class="d-flex align-items-center navbar-menu-item" mat-menu-item (click)="logout()">
                <img src="assets/icons/logout.svg" class="img-fluid img-responsive" />
                <p class="m-0 option">Logout</p>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar>
      <mat-drawer #drawer [mode]="'over'" class="pt-0 pb-0 px-0">
        <div class="bg-light-blue d-flex align-items-center hover-lgt-blue" (click)="drawer.close()">
          <svg class="m-3 ml-4 pointer" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1C14 1.55228 13.5523 2 13 2H1C0.447716 2 0 1.55228 0 1Z"
              fill="#0B70D4" />
            <path
              d="M0 6C0 5.44772 0.447715 5 1 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H1C0.447716 7 0 6.55228 0 6Z"
              fill="#0B70D4" />
            <path
              d="M0 11C0 10.4477 0.447715 10 1 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H1C0.447716 12 0 11.5523 0 11Z"
              fill="#0B70D4" />
          </svg>
        </div>
        <mat-list class="d-flex flex-column justify-content-between" style="min-height: 91vh;">
          <div>
            <div mat-subheader>Services</div>
            <ng-container *ngFor="let content of dashboardRoutes">
              <ng-container *ngFor="let routes of content.contentList">
                <mat-list-item   *ngIf="['A3S Support','Azure Support','SAP Support'].indexOf(routes?.Name) > -1 ? false : true">
                  <div class="d-flex align-items-center full-width" (click)="changeRoute(routes,drawer)" id="{{routes.route}}">
                    <img mat-list-icon [src]="routes.img">
                    <div mat-line>{{routes?.Name}}</div>
                  </div> 
                  <div class="no-access-tooltip-wrapper" *ngIf="routes?.restrictAccess" id="no-access-tooltip">
                    <div class="left-pane">
                      <img src="./assets/common-icons/no-access-info.svg">
                    </div>
                    <div class="right-pane">
                      <div class="content">
                        <span>You don’t have access to this bucket</span>
                        <p>To get access please contant your 
                          administrator.</p>
                      </div>
                      <img class="cursor-pointer" src="assets/Infra/blueprint/close.svg" (click)="routes.restrictAccess = false">
                    </div>
                  </div>
                   
                </mat-list-item>
              </ng-container>
            </ng-container>
          </div>

         
          <div>
            <mat-divider></mat-divider>
            <ng-container *ngFor="let content of dashboardRoutes">
              <ng-container *ngFor="let routes of content.contentList">
                <mat-list-item *ngIf="['A3S Support','Azure Support','SAP Support'].indexOf(routes?.Name) > -1 ? true : false">
                  <div class="d-flex align-items-center full-width" (click)="changeRoute(routes,drawer)" id="{{routes.route}}">
                    <img mat-list-icon [src]="routes.img">
                    <div mat-line>{{routes?.Name}}</div>
                  </div> 
                  <div class="no-access-tooltip-wrapper left-tool" *ngIf="routes?.restrictAccess" id="no-access-tooltip">
                    <div class="left-pane">
                      <img src="./assets/common-icons/no-access-info.svg">
                    </div>
                    <div class="right-pane">
                      <div class="content">
                        <span>You don’t have access to this bucket</span>
                        <p>To get access please contant your 
                          administrator.</p>
                      </div>
                      <img class="cursor-pointer" src="assets/Infra/blueprint/close.svg" (click)="routes.restrictAccess = false">
                    </div>
                  </div>
                </mat-list-item>
              </ng-container>
            </ng-container>
          </div>
        


        </mat-list>
      </mat-drawer>
    </mat-drawer-container>
  </div>

</ng-container>

 <!-- Child Routes -->
 <div class="main-content">
  <router-outlet></router-outlet>
</div>