<div class="introduction">
  
   
    <p class="heading"  id="0">Users Returned After # of Weeks  </p>
    <p class="para mb-0">Shows the number of returning users on a weekly basis.  </p>
    <img  src="assets\user-manual\apps-health-monitoring\Tile(12).svg" >

    <figcaption class="caption "> User retention per week </figcaption>
  
          
</div>