import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bill-invoice',
  templateUrl: './bill-invoice.component.html',
  styleUrls: ['./bill-invoice.component.scss']
})
export class BillInvoiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
