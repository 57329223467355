import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { TileDetail, TileRequest } from '../data-governance-ddc/data-governance-ddc.state';
import { TileData } from '../data-governance-overview/data-governance-overview.state';

export class DataGovernanceMonitoringStateModel {
  datasort?:string;
  queryDataFlowDebugSessions?:TileData;
  queryPipelineRuns?:TileData;
  sparkApplications?:TileData;
  sparkBatches?:TileData;
  sparkSessions?:TileData;
  sparkStatements?:TileData;
  columns?:TileData;
  tables?:TileData;
  schemas?:TileData;
  activeQueryCount?:TileData;
  sqlPoolUsage?:TileData;
  workloadGroups?:TileData;
  sqlDTUUsage?:TileData;
  workloadClassifiers?:TileData;
}

export class SparkPoolTileRequest {
  workspaceName:string;
  tileList:TileDetail[];
}

export class GetWorkspaceTileDataAction {
  static readonly type = '[DataGovernanceMonitoring] getWorkspaceTileData';

  constructor(readonly workspace: string, readonly tileName: string) { }
}

export class GetSparkPoolTileDataAction {
  static readonly type = '[DataGovernanceMonitoring] getSparkPoolTileData';

  constructor(readonly payload:SparkPoolTileRequest) { }
}

export class GetColumnsDataAction {
  static readonly type = '[DataGovernanceMonitoring] getColumnsData';

  constructor(readonly payload:TileRequest) { }
}

export class GetTablesDataAction {
  static readonly type = '[DataGovernanceMonitoring] getTablesData';

  constructor(readonly payload:TileRequest) { }
}

export class GetWorkloadClassifiersDataAction {
  static readonly type = '[DataGovernanceMonitoring] getWorkloadClassifiersData';

  constructor(readonly payload:TileRequest) { }
}

export class GetSQLPoolTileDataAction {
  static readonly type = '[DataGovernanceMonitoring] getSQLPoolTileData';

  constructor(readonly payload:TileRequest) { }
}

export class SortWorkspaceTileDataAction {
  static readonly type = '[DataGovernanceMonitoring] sortWorkspaceTileData';

  constructor(readonly tileName: string, readonly fieldName: string) { }
}

const DATA_GOVERNANCE_MONITORING_STATE_TOKEN = new StateToken<DataGovernanceMonitoringState>('dataGovernanceMonitoring');

@State<DataGovernanceMonitoringStateModel>({
  name: DATA_GOVERNANCE_MONITORING_STATE_TOKEN,
  defaults:{
    queryDataFlowDebugSessions:{
      value:[],
      refreshedTime:new Date()
    },
    queryPipelineRuns:{
      value:[],
      refreshedTime:new Date()
    },
    sparkApplications:{
      value:[],
      refreshedTime:new Date()
    },
    sparkBatches:{
      value:[],
      refreshedTime:new Date()
    },
    sparkSessions:{
      value:[],
      refreshedTime:new Date()
    },
    sparkStatements:{
      value:[],
      refreshedTime:new Date()
    },
    columns:{
      value:[],
      refreshedTime:new Date()
    },
    tables:{
      value:[],
      refreshedTime:new Date()
    },
    schemas:{
      value:[],
      refreshedTime:new Date()
    },
    activeQueryCount:{
      value:[],
      refreshedTime:new Date()
    },
    workloadGroups:{
      value:[],
      refreshedTime:new Date()
    },
    sqlPoolUsage:{
      value:[],
      refreshedTime:new Date()
    },
    sqlDTUUsage:{
      value:[],
      refreshedTime:new Date()
    },
    workloadClassifiers:{
      value:[],
      refreshedTime:new Date()
    },
  }
})
@Injectable()
export class DataGovernanceMonitoringState {

  constructor(private readonly apiService:ApiService,private http:HttpClient) {}

  @Selector([DataGovernanceMonitoringState])
  static queryDataFlowDebugSessions(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.queryDataFlowDebugSessions
  }
  
  @Selector([DataGovernanceMonitoringState])
  static queryPipelineRuns(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.queryPipelineRuns
  }
  
  @Selector([DataGovernanceMonitoringState])
  static sparkApplications(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.sparkApplications
  }

  @Selector([DataGovernanceMonitoringState])
  static sparkBatches(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.sparkBatches
  }
  
  @Selector([DataGovernanceMonitoringState])
  static sparkSessions(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.sparkSessions
  }

  @Selector([DataGovernanceMonitoringState])
  static sparkStatements(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.sparkStatements
  }
  
  @Selector([DataGovernanceMonitoringState])
  static columns(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.columns
  }
  
  @Selector([DataGovernanceMonitoringState])
  static tables(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.tables
  }

  @Selector([DataGovernanceMonitoringState])
  static activeQueryCount(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.activeQueryCount
  }

  @Selector([DataGovernanceMonitoringState])
  static sqlPoolUsage(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.sqlPoolUsage
  }

  @Selector([DataGovernanceMonitoringState])
  static workloadGroups(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.workloadGroups
  }

  @Selector([DataGovernanceMonitoringState])
  static schemas(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.schemas
  }
  
  @Selector([DataGovernanceMonitoringState])
  static sqlDTUUsage(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.sqlDTUUsage
  }
  
  @Selector([DataGovernanceMonitoringState])
  static workloadClassifiers(state:DataGovernanceMonitoringStateModel): TileData {
    return state?.workloadClassifiers
  }

  @Action(GetWorkspaceTileDataAction)
  getWorkspaceTileData(ctx: StateContext<DataGovernanceMonitoringStateModel>, action: GetWorkspaceTileDataAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getTilesData', [action.workspace,action.tileName]);
    return this.http.get<TileData>(url)
    .pipe(
      tap((tileData:TileData) => {
        ctx.patchState({
          [action.tileName]:{
            value:tileData.value?tileData.value:tileData?[tileData]:[],
            refreshedTime:new Date()
          }
        })
      }));
  }
  
  @Action(GetColumnsDataAction)
  getColumnsData(ctx: StateContext<DataGovernanceMonitoringStateModel>, action: GetColumnsDataAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getCloumnListBySQLPool');
    return this.http.post<TileData>(url,action.payload)
    .pipe(
      tap((tileData:TileData) => {
        ctx.patchState({
          columns:{
            value:tileData.value?tileData.value:tileData?[tileData]:[],
            refreshedTime:new Date()
          }
        })
      }));
  }

  @Action(GetTablesDataAction)
  getTablesData(ctx: StateContext<DataGovernanceMonitoringStateModel>, action: GetTablesDataAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getTableListBySQLPool');
    return this.http.post<TileData>(url,action.payload)
    .pipe(
      tap((tileData:TileData) => {
        ctx.patchState({
          tables:{
            value:tileData.value?tileData.value:tileData?[tileData]:[],
            refreshedTime:new Date()
          }
        })
      }));
  }

  @Action(GetWorkloadClassifiersDataAction)
  getWorkloadClassifiersData(ctx: StateContext<DataGovernanceMonitoringStateModel>, action: GetWorkloadClassifiersDataAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getWorkloadClassifierBySQLPool');
    return this.http.post<TileData>(url,action.payload)
    .pipe(
      tap((tileData:TileData) => {
        ctx.patchState({
          workloadClassifiers:{
            value:tileData.value?tileData.value:tileData?[tileData]:[],
            refreshedTime:new Date()
          }
        })
      }));
  }

  @Action(GetSparkPoolTileDataAction)
  getSparkPoolTileData(ctx: StateContext<DataGovernanceMonitoringStateModel>, action: GetSparkPoolTileDataAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getTilesDataBySprakPoolName');
    return this.http.post<any[]>(url,action.payload)
    .pipe(
      tap((tileDatas:any[]) => {
        let results={};
        tileDatas.forEach(tileData=>{
          const findObj=action.payload.tileList.find(tile=>tileData[tile.tileName]);
          results={
            ...results,
            [findObj.tileName]:{
              value:tileData[findObj.tileName].value?tileData[findObj.tileName].value:tileData[findObj.tileName]?[tileData[findObj.tileName]]:[],
              refreshedTime:new Date()
            }           
          }
        })
        ctx.patchState({
          ...results
        })
      }));
  }

  @Action(GetSQLPoolTileDataAction)
  getSQLPoolTileData(ctx: StateContext<DataGovernanceMonitoringStateModel>, action: GetSQLPoolTileDataAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getTilesDataForDDC');
    return this.http.post<any[]>(url,action.payload)
    .pipe(
      tap((tileDatas:any[]) => {
        let results={};
        tileDatas.forEach(tileData=>{
          const findObj=action.payload.tileList.find(tile=>tileData[tile.tileName]);
          results={
            ...results,
            [findObj.tileName]:{
              value:tileData[findObj.tileName].value?tileData[findObj.tileName].value:tileData[findObj.tileName]?[tileData[findObj.tileName]]:[],
              refreshedTime:new Date()
            }           
          }
        })
        ctx.patchState({
          ...results
        })
      }));
  }

  @Action(SortWorkspaceTileDataAction)
  sortWorkspaceTileData(ctx: StateContext<DataGovernanceMonitoringStateModel>, action: SortWorkspaceTileDataAction) {
      let num = 0;
      (ctx.getState().datasort === 'desc') ? num = 1 : num = -1;
      let value = [...ctx.getState()[action.tileName].value];
      let fieldName=action.fieldName;
      value.sort((a, b) => {
        switch (fieldName) {
          case 'name': {
            let x = a.name ? a.name : ''
            let y = b.name ? b.name : ''
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          default: {
            let val1,val2;
            fieldName.split('.').forEach(dt=>{
              if(val1!=undefined){
                val1=val1[dt]
              }else {
                val1=a[dt]
              }
              if(val2!=undefined){
                val2=val2[dt]
              }else {
                val2=b[dt]
              }
            })
            let x = val1 ? val1 : ' ';
            let y = val2 ? val2 : ' ';
            return (x < y) ? num : ((x > y) ? -num : 0);
          }
        }
      })
      ctx.patchState({
        [action.tileName]:{
          ...ctx.getState()[action.tileName],
          value
        },
        datasort: ctx.getState().datasort === 'desc' ? 'asc' : 'desc'
      })
  }
}
