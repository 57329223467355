import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { StackedLineChartComponent } from './stacked-line-chart/stacked-line-chart.component';
import { HorizontalStackedBarChartComponent } from './horizontal-stacked-bar-chart/horizontal-stacked-bar-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { VerticalStackedBarChartComponent } from './vertical-stacked-bar-chart/vertical-stacked-bar-chart.component';
import { HorizontalBarChartComponent } from './horizontal-bar-chart/horizontal-bar-chart';
import { StackedCommonTimeLineChartComponent } from './stacked-common-time-line-chart/stacked-common-time-line-chart.component'
@NgModule({
  imports: [
    CommonModule,
    // NgxEchartsModule,
    NgxEchartsModule.forRoot({
      echarts
    }),
  ],
  declarations: [StackedLineChartComponent,HorizontalStackedBarChartComponent,BarChartComponent,DoughnutChartComponent,VerticalStackedBarChartComponent,HorizontalBarChartComponent,StackedCommonTimeLineChartComponent],
    exports:[StackedLineChartComponent,HorizontalStackedBarChartComponent,BarChartComponent,DoughnutChartComponent,VerticalStackedBarChartComponent,HorizontalBarChartComponent,StackedCommonTimeLineChartComponent]
})
export class EchartsModule { }
