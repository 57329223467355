<div class="introduction">
 
    <p class="heading" id="3">Reservation Transactions </p>
    <p class="mb-0" >Reservation Transactions landing page displays the lists of transactions 
     for Reserved Instances.  </p>
    
    <ul>
        <img src="assets\user-manual\cm-and-optimize\Reservation-Transactions.svg" >
        <figcaption class="caption">Reservation Transactions  </figcaption>
          <li>Use Subscription, Resource group and Resource filters to view specific transaction(s).   </li>
           
    </ul>
 

  
</div>
