import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insights-application-health-monitoring',
  templateUrl: './insights-application-health-monitoring.component.html',
  styleUrls: ['./insights-application-health-monitoring.component.scss']
})
export class InsightsApplicationHealthMonitoringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
