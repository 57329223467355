<div class="introduction">
    <p class="heading" id="0">Application Health Monitoring </p>
     <p class="para">Continual monitoring of the applications deployed on Azure under various 
              projects in A3S is key to making sure that any issues or errors are identified as early as 
              possible and thereon remediation steps are undertaken to ensure healthy status of all the 
              applications. End goal of this ongoing application monitoring and remediation is to learn from 
              and analyze incident management data logs with AI/ML to implement preemptive measures that avoid
              the potential errors and issues from occurring in the first place.   </p>
           <p>Application Health Monitoring consists of the following:</p> 
              <ol> 
                <li><b>Metrics :</b> display health metrics of a project. </li>
                <li><b>Usage :</b> displays region-wise usage report and frequency of the returning users to the
                    application.</li>
                <li><b>Alerts :</b> view & modify existing alerts and create new alerts. </li>
                
            </ol>
</div>

