import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-initiate-return-event',
  templateUrl: './initiate-return-event.component.html',
  styleUrls: ['./initiate-return-event.component.scss']
})
export class InitiateReturnEventComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
