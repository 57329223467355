import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss']
})
export class DeploymentComponent implements OnInit {
  infraDeploymentMenu: any;
  currentUrl: any;

  constructor(private util:UtilService,private router: Router,) { 

    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event) => {
        this.currentUrl = event['urlAfterRedirects']
    })
    if(!this.currentUrl) this.currentUrl = this.router.url
   }

  ngOnInit(): void {
    this.infraDeploymentMenu = this.util.getStatic('infraDeploymentMenus');
  }

  allowUrl(){
    return ['a3s/infraDeploymentBucket/deployment'].indexOf(this.currentUrl)  > -1 ? true :false;
  }

}
