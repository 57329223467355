<div class="introduction">
    <p class="heading" id="0">User Scenarios </p>
    <p>A3S projects are created per these 2 scenarios:  </p>
    
    <ol>
        <li>Customer is new to Azure and signs up for A3S:</li>
        <ul>
            <li>All the actions that a user initiates in Apps & Infrastructure and Data & AI functional buckets will always start with creation of a project. </li>
            <li>Thereon, all the projects will be available in all the other A3S functional buckets i.e., Infrastructure Health Monitoring, Application Health Monitoring, Cost Monitoring & Optimization, Governance, Security and Compliance for the user to leverage respective functionality in context of a project.  </li>
            <li>This approach makes it easy for the user to focus on their projects versus getting drowned in flow of information not relevant to things that they care about.  </li>
        </ul> 
        <li>Customer already uses Azure and has various resources provisioned across Subscription(s) & Resource Group(s): </li>
        <ul>
            <li>Customer signs up for A3S. </li>
            <li>As part of the onboarding process, A3S team will take a one-time action to create a project to get the user started with the concept of A3S project. </li>
            <li>The user can allocate whatever Subscriptions & Resource Groups they want under this project.</li>
            <li>Once the initial project is created, it will be available in all the other A3S functional buckets i.e., Infrastructure Health Monitoring, Application Health Monitoring, Cost Monitoring & Optimization, Governance, Security and Compliance for the user to leverage respective functionality in context of a project. </li>
            <li>User can create additional projects as needed to bring the other existing resources provisioned in various Subscriptions & Resource Groups under A3S management. </li>
            <li>Apart from above, rest of the user experience will be same as detailed point 1 above. </li>
        </ul>
           
           
    </ol>


</div>

