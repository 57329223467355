<div class="medium-popup-wrapper common-popup width-530 cancel-migrate">
    <div class="modal-body text-center">
            <div class="full-width d-flex mb-3 header-top">
              <h2 class="modal-title full-width text-left  mr-auto">Cancel Migration</h2>
              <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                  <span aria-hidden="true">&times;</span>
                </button>
                <!-- <button type="button" class="close pull-right gen-api" (click)="close()">
                  <span aria-hidden="true">
                    <img src="assets/icons/close.png" />
                  </span>
                </button> -->
              </div>
            </div>

            <div class="dlt-content text-left">
                <p>Are you sure you want to cancel migration? </p>
            </div>

             <!-- Buttons -->
            <div class="model-submit mt-3 text-right">
                <button id="primary" class="btn btn-outline" (click)="close()">No</button>
                <button id="secondary" class="btn btn-red ml-3" (click)="secondaryAction()">Yes</button>
            </div>
    </div>
</div>