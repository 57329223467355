<div class="introduction">
 
        <p class="heading" id="0">Add User </p>
        <p>Create new user</p>
            <ul class="mb-0">
                <li>Click <b>Add user button</b>  on the Users tile. </li>
                <li>Select <b>Create user.</b> </li>
                <li>Provide the needed inputs in the side sheet.  </li>
                <li>Click <b>Create.</b> </li>

            </ul>
            <img style="width: 50%;height: 50%;" src="assets\user-manual\governance\Side-sheet(2).svg" >
   
            <figcaption class="caption col-md-6" style="padding-left: 150px;">Create user side sheet</figcaption>
             
</div>
