import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
import { NewResourceGroup, NSGModel, ResourceInputError } from '../resources-model';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-network-security-group',
  templateUrl: './network-security-group.component.html',
  styleUrls: ['./network-security-group.component.scss']
})
export class NetworkSecurityGroupComponent implements OnInit {
  @Input() projectData : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  nsgmodel : NSGModel = {
    resourceId:null,
    resourceName:null,
    resourceType:'networkSecurityGroups',
    resourceData:{
      resourceGroup:null,
      vmInBoundRule:[],
      updateIndex:null,
      vmOutBoundRule:[],
      tagList:[{
        tagName:null,
        value:null
      }]
    },
    comments:null,
    createdBy:null
  };
  show : boolean = false;
  resourcesGroupLocationList: any = [];
  inputError: ResourceInputError = {
    nameLen:false,
    nameValid:false,
    nameDupCheck:false,
    VMdescription:false
  }
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  resourceGroupList:any=[];
  showView:boolean=false;
  nsgList: any=[];
  existingNewVMList: any=[];
  existingNewNSGList: any=[];
  
  constructor(private util:UtilService,private loader:LoaderService) { }

  async ngOnInit() {
    const randomNumber=Math.random;
    this.loader.queActions('push',`'${randomNumber}'`);
    await this.getResourceGroupList();
    let parentResourceData,resourceObj;
    if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
      this.nsgmodel.resourceData.subnet=this.parentResourceDtls.resourceName;
      parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
      this.nsgmodel.resourceData.vnetName=parentResourceData.vnetName;
      resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
      this.nsgmodel.resourceData.resourceGroup=resourceObj.name;
      this.nsgmodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
      this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
    }
    await this.getListByPropName('networkSecurityGroups',undefined,undefined);
    await this.getExistNewlyResourceDetails('virtualMachines');
    await this.getExistNewlyResourceDetails('networkSecurityGroups');
    if(this.sideSheetViewType=='edit'){
      this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null,null,null,true).then(async res=>{
        this.nsgmodel=res;
        this.nsgmodel.resourceData=JSON.parse(res.resourceData);
        if(this.parentResourceDtls['updateRequestFrom']){
          this.nsgmodel.resourceData.vnetName=this.parentResourceDtls.resourceName;
          this.nsgmodel.resourceData.resourceGroup=resourceObj.name;
        }
      });
    }
    this.loader.queActions('pop',`'${randomNumber}'`);
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType === 'virtualMachines') {
        this.existingNewVMList=res;
      } else if(resourceType === 'networkSecurityGroups') {
        this.existingNewNSGList=res;
      }
    },err=>{
      if(resourceType === 'virtualMachines') {
        this.existingNewVMList=[];
      } else if(resourceType === 'networkSecurityGroups') {
        this.existingNewNSGList=[];
      }
    });
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
        if(this.projectData.resourceGroup&&this.projectData.resourceGroup!='null'){
        this.nsgmodel.resourceData.resourceGroup=this.projectData.resourceGroup;        
        }
      });
  }

  onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.nsgmodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }

      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.nsgmodel.resourceData.resourceGroup=undefined;      
    });
  }

  editNewResourceGroup(item,index){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item);
    this.show = !this.show;
    
  }

  async getNewResourceGroup(){
   await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addTagVM(){
    this.nsgmodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTagVM(index){
    this.nsgmodel.resourceData.tagList.splice(index,1);
  }

  hideShow(){
    this.showView=!this.showView;
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      let parentResourceData=this.parentResourceDtls&&this.parentResourceDtls.resourceData?JSON.parse(this.parentResourceDtls.resourceData):{};
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name==this.nsgmodel&&this.nsgmodel.resourceData&&this.nsgmodel.resourceData.resourceGroup?this.nsgmodel.resourceData.resourceGroup:parentResourceData.resourceGroup);
      if(propName=='networkSecurityGroups'){
        this.nsgList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }
      return  result;
    },err=>{
      if(propName=='networkSecurityGroups'){
        this.nsgList=[];
      }
    });
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
      // await this.clearResourceModel();
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls={
        ...this.nsgmodel,
        updateRequestFrom:this.parentResourceDtls['updateRequestFrom'],
        resourceData:JSON.stringify(this.nsgmodel.resourceData)
      }
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
      // await this.clearResourceModel();
    }else if(flag=='inboundRules'||flag=='outboundRules'){
      this.triggerBtnvalue.emit({value:false,data:this.nsgmodel.resourceData,type:flag})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  checkNSGName(event,limit){
    let value=event.target.value;
    this.inputError.nameLen=false;
    this.inputError.nameValid=false;
    this.inputError.nameDupCheck=false;
    if(value){
      if(value.length>limit){
        this.inputError.nameLen=true;
        return;
      }
      if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
        this.inputError.nameValid = false;
      }
      else{
        this.inputError.nameValid = true;
        return;
      }
  
      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
          this.inputError.nameValid = true;
          return;
        }
      }
    
      if(this.nsgList&&this.nsgList.length>0){
        let findObj=this.nsgList.find((dt,index)=>dt.name.toLowerCase()==value.toLowerCase()&&dt.id);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.existingNewNSGList){
        let findObj=this.existingNewNSGList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase()&&dt.resourceId!=this.nsgmodel.resourceId);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.existingNewVMList){
        let findObj=this.existingNewVMList.find(dt=>{
          if(dt.resourceData) {
            const resourceData=JSON.parse(dt.resourceData);
            if(resourceData.networkSecurityGroup) {
              return resourceData.networkSecurityGroup.name.toLowerCase()==value.toLowerCase();
            }
          }
        });
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
    }
  }

  VmDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMdescription = true;
    }
    else this.inputError.VMdescription = false;
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.nsgmodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  saveResourceDetails(){
    var model = this.nsgmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.nsgmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }


  deleteRules(type,i){
    if(type=='inboundRules'){
      this.nsgmodel.resourceData.vmInBoundRule.splice(i,1);
    }else if(type=='outboundRules'){
      this.nsgmodel.resourceData.vmOutBoundRule.splice(i,1);
    }
  }

  editRules(type,i){
      this.nsgmodel.resourceData.updateIndex=i;
      this.triggerBtn(type);
  }

  checkDataDisabled(name){
    return !this.nsgmodel.resourceName || !this.nsgmodel.resourceData.resourceGroup || this.inputError.nameLen || this.inputError.nameValid || this.inputError.nameDupCheck;
  }
}
