import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { IndianTimeFormat } from 'src/app/pipes/custom.pipe';
import { UtilService } from 'src/app/services/util.service';
import { SecurityBucketState } from 'src/app/state/security-bucket.state';
import { SecurityQueries } from '../../security-queries'

@Component({
  selector: 'app-identity-security-windows',
  templateUrl: './identity-security-windows.component.html',
  styleUrls: ['./identity-security-windows.component.scss']
})
export class IdentitySecurityWindowsComponent implements OnInit {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  
  searchKey:any;
  resourceList: any = [];
  datasort: string;
  subsName: any;
  groupByCtrl:boolean=true;
  subscriptionList: any=[];
  processStartedData: any = {
    type:'processStartedData',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'Process Started',
    xAxis:[],
    yAxis:[],
    showLegend : true,
    legendData:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    max:undefined,
    showLoader : true,
    currentFilter: {
      type : 'last3days',
      from:  new Date(new Date().setDate(new Date().getDate() - 3)),
      to: new Date()
    }
  };
  refreshedTime: any = {
    processUsedData: new Date(),
    remotedLoggedAcc: new Date(),
    processStartedData: new Date(),
    accountWithFailed: new Date(),
    failedLoginAttempts: new Date(),
    multiplePasswordReset: new Date(),
    newProcesesObserved: new Date(),
    processAttemptedCard: new Date(),
    multiplePasswordResetCard: new Date(),
    failedLogonCard: new Date(),
  }
  options:any={};
  processUsedData = {
    type:'processUsedData',
    data:[],
    tempData:[],
    legendData:[],
    tempTableData:[],
    tableData:[],
    color:['#DB4CB2','#5D43A6', '#19AADE','#FBB040','#1DE4BD','#7D3AC1','#2989E9', '#D40B78', '#FF6F32', '#62C2FB'],
    showLoader : true,
    height:270,
    labelFontSize:25,
    lineHeight:31,
    seriesCenter:['50%','45%'],
    legendType:'scroll',
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  }
  remotedLoggedAccOption: any = {
    type:'remotedLoggedAcc',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last3days',
      from:  new Date(new Date().setDate(new Date().getDate() - 3)),
      to: new Date()
    }
  };
  accountWithFailedOption: any = {
    type:'accountWithFailed',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last3days',
      from:  new Date(new Date().setDate(new Date().getDate() - 3)),
      to: new Date()
    }
  };
  failedLoginAttemptsOption: any = {
    type:'failedLoginAttempts',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last3days',
      from:  new Date(new Date().setDate(new Date().getDate() - 3)),
      to: new Date()
    }
  };
  multiplePasswordReset: any = {
    type:'multiplePasswordReset',
    tableData:[],
    tempTableData:[],
    onlyTable:true,
    showLoader : false,
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  newProcesesObserved: any = {
    type:'newProcesesObserved',
    tableData:[],
    tempData:[],
    onlyTable:true,
    tempTableData:[],
    showLoader : false,
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  multiplePasswordResetCard: any = {
    type:'multiplePasswordResetCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    onlyTable: true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  processAttemptedCard: any = {
    type:'multiplePasswordResetCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    onlyTable: true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  failedLogonCard: any = {
    type:'failedLogonCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    onlyTable: true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  selectedData: any;
  timeFilterList: any=[];
  timeFilterFlag: boolean=false;
  currentProject: any;
  subscriptionInfra: Subscription;
  @Select(SecurityBucketState.currentProjectDetail) currentProjectDetail$: Observable<any>;
  assignUserList: any=[];
  detailsPop: any;
  
  constructor(private util:UtilService,private modalService: NgbModal,private timeFormat:IndianTimeFormat,private datePipe:DatePipe) { }

  async ngOnInit() {
    this.timeFilterList=this.util.getStatic('timeFilterList');
    // this.getBlueprintProject();
    // await this.getAllSubscriptions();
    this.subscriptionInfra = this.currentProjectDetail$.subscribe(async (res) => {
      if(res){
        this.currentProject = _.cloneDeep({...res});//res.currentFilteredProject;
        this.triggerFilter(this.currentProject,'project')
        await this.getListByPropNameUsingGrpah('users');
        this.getProcessUsedData();
        this.getQueryResult('accountWithFailedOption');
        this.getQueryResult('failedLoginAttemptsOption');
        this.getQueryResult('remotedLoggedAccOption');
        this.getTableData('multiplePasswordReset');
        this.getTableData('newProcesesObserved');
        this.getTableData('processAttemptedCard');
        this.getTableData('failedLogonCard');
        this.getTableData('multiplePasswordResetCard');
        this.getProcessStartedData();
      }
    });
  }

  ngOnDestroy() {
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

  getTableData(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": this.getTableQuery(type)
    }
    this[type].tableData=[];
    this[type].tempTableData=[];
    if(this[type].onlyTable){
      this[type].showLoader = true;
    }
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      if(this[type].onlyTable){
        this[type].showLoader = false;
      }
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempTableData.push(ds);
        });
      })
      this.filterTableData(type);
      this[type].showLoader = false;
    },err=>{
      if(this[type].onlyTable){
        this[type].showLoader = false;
      }
    })
  }

  getLastSeenTimeRangeQuery(filter){
    switch(filter.type){
      case 'last30mins' : return 'where LastSeen > ago(30m)'
      case 'lasthr' : return 'where LastSeen > ago(1h)'
      case 'last4hr' : return 'where LastSeen > ago(4h)'
      case 'last12hr' : return 'where LastSeen > ago(12h)'
      case 'last24hr' : return 'where LastSeen > ago(24h)'
      case 'last48hr' : return 'where LastSeen > ago(48h)'
      case 'last3days' : return 'where LastSeen > ago(3d)'
      case 'last7days' : return 'where LastSeen > ago(7d)'
      case 'custom' : return `where LastSeen > todatetime(${`'${new Date(filter.from).toISOString()}'`}) and LastSeen < todatetime(${`'${new Date(filter.to).toISOString()}'`})`
    }
 }

  getTableQuery(type){
    switch(type){
      case 'accountWithFailedOption':
        return SecurityQueries.accountWithFailedForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))//"SecurityEvent | where TimeGenerated > ago(1d) | where AccountType == 'User' and EventID == 4625 | summarize failed_login_attempts=count(), latest_failed_login=arg_max(TimeGenerated, Account) by Account  | where failed_login_attempts > 5 | project-away Account1 | order by failed_login_attempts desc "
      case 'failedLoginAttemptsOption':
        return SecurityQueries.failedLoginAttemptsForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))//"SecurityEvent  | where AccountType == 'User' and EventID == 4625 and TimeGenerated > ago(6h)  | summarize IPCount = dcount(IpAddress), makeset(IpAddress)  by Account | where IPCount > 5 | sort by IPCount desc "
      case 'remotedLoggedAccOption':
        return SecurityQueries.remotedLoggedAccForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))//"SecurityEvent | where EventID == 4624 and (LogonTypeName == '3 - Network' or LogonTypeName == '10 - RemoteInteractive') | summarize RemoteLogonCount = count() by Account, Computer | order by RemoteLogonCount desc "
      case 'processUsedData':
        return SecurityQueries.processUsedDataTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'processStartedData':
        return SecurityQueries.processStartedDataTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'multiplePasswordReset':
        return SecurityQueries.multiplePasswordReset.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))      
      case 'newProcesesObserved':
        return SecurityQueries.newProcesesObserved.replace('TIMERANGE',this.getLastSeenTimeRangeQuery(this[type].currentFilter))
      case 'processAttemptedCard':
        return SecurityQueries.processUsedData.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'failedLogonCard':
        return SecurityQueries.accountWithFailedOption.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'multiplePasswordResetCard':
        return SecurityQueries.multiplePasswordReset.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
    }
  }

  getQuery(type){
    switch(type){
      case 'accountWithFailedOption':
        return "SecurityEvent | where TimeGenerated > ago(1d) | where AccountType == 'User' and EventID == 4625 | summarize failed_login_attempts=count(), latest_failed_login=arg_max(TimeGenerated, Account) by Account  | where failed_login_attempts > 5 | project-away Account1 | order by failed_login_attempts desc | take 10 | project-away latest_failed_login"
      case 'failedLoginAttemptsOption':
        return "SecurityEvent  | where AccountType == 'User' and EventID == 4625 and TimeGenerated > ago(6h)  | summarize IPCount = dcount(IpAddress)  by Account | where IPCount > 5 | sort by IPCount desc | take 10 "
      case 'remotedLoggedAccOption':
        return "SecurityEvent | where EventID == 4624 and (LogonTypeName == '3 - Network' or LogonTypeName == '10 - RemoteInteractive') | summarize RemoteLogonCount = count() by Account, Computer | order by RemoteLogonCount desc | take 10 "        
      case 'processUsedData':
        return "SecurityEvent | where TimeGenerated > ago(7d) | where EventID == 4648 | summarize count() by Process | render piechart "             
    }
  }

  getQueryResult(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries[type].replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))//this.getQuery(type)
    }
    this.getTableData(type);
    this[type].showLoader = true;
    this[type].yAxis=[];
    this[type].data=[];
    this[type].tempData=[];
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this[type].showLoader = false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempData.push(ds);
          
        });
      })
      this.filterAllData(type);
    },err=>{
      this[type].showLoader = false;
    })
  }

  async getListByPropNameUsingGrpah(propName){    
    let reqObj={
      fetchPropName:propName
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropNameUsingGrpah',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='users'){
        this.assignUserList=res.value;
       } 
      return  result;
    },err=>{
      if(propName=='users'){
        this.assignUserList=[];
       } 
    });
  }

  filterCheck(value){
    return (this.filterObject.subscriptionList.find(dtl=>dtl.checked&&value.split("/")[2]&&dtl.subscriptionId===(value.split("/")[2])) && this.filterObject.resourceGroupList.find(dtl=>dtl.checked&&value.split("/")[4]&&dtl.resourceGroupName.toLowerCase()===(value.split("/")[4]).toLowerCase()) && this.filterObject.resourceList.find(dtl=>dtl.checked&&(value.split("/")[value.split("/").length-1])&&dtl.resourceName.toLowerCase()===(value.split("/")[value.split("/").length-1]).toLowerCase()))||(this.filterObject.subscriptionList.find(dtl=>dtl.checked&&dtl.subscriptionId===(value.split("/")[2])) && !value.split("/")[4])
  }

  filterTableData(type){
    this[type].tableData=[];
    this[type].tempTableData.forEach(dt=>{
      let value;
      switch (type) {
        case 'processUsedData':
        case 'remotedLoggedAccOption':
        case 'failedLoginAttemptsOption':
        case 'accountWithFailedOption':
          value=dt[2];
          break;
        case 'processStartedData':
          value=dt[3];
          break;
        case 'newProcesesObserved':
          value=dt[5];
          break;
        case 'processAttemptedCard':
          value=dt[1];
          break;
        case 'failedLogonCard':
          value=dt[2];
          break;
      
        default:
          break;
      }
        if(value&&this.filterCheck(value)){
          this[type].tableData.push(dt);
        }else if(type === 'multiplePasswordReset'||type === 'multiplePasswordResetCard'){
          let findObj=this.assignUserList.find(user=>user.mail===dt[0]);
          if(findObj){
            dt[8]=findObj.displayName;
            this[type].tableData.push(dt);
          }
        }
    })
    if(type==='failedLogonCard'||type === 'processAttemptedCard'|| type === 'multiplePasswordResetCard') {
      this.filterDataForCard(type);
    }
  }

  filterDataForCard(type): void {
    if(type==='failedLogonCard'||type=='processAttemptedCard'||type==='multiplePasswordResetCard') {
      if(this[type].tableData&&this[type].tableData.length){
        this[type].tableData=this[type].tableData.slice(0,4);
      } 
    } 
  }

  filterAllData(type){
    this[type].legendData = [];
    this[type].data=[];
    this[type].yAxis=[];
    this[type].series=[];
    this[type].xAxis=[];
    this[type].tempData.forEach((ds,index1)=>{
      if(type==='processUsedData'&&this.filterCheck(ds[1])){
        let findIndex=this.processUsedData.data.findIndex(dt=>dt.name==ds[0]);
          if(findIndex>-1){
            this.processUsedData.data[findIndex].value=this.processUsedData.data[findIndex].value+ds[2];
          }else{
            this.processUsedData.data.push({value:ds[2],name:ds[0]});
            this.processUsedData.legendData.push({icon:'circle',name:ds[0]})
          }
      } else if(type==='processStartedData'){
        var timeArray = [];
        ds.rows.forEach((dt)=>{
          if(this.filterCheck(dt[2])){
            let findIndex=this.processStartedData.series.findIndex(d=>d.name==dt[1]);
            if(findIndex>-1){
            this.processStartedData.series[findIndex].data.push(dt[3]);
            }else{
              let obj = {
                name : dt[1],
                type : 'line',
                data: [dt[3]]
              }            
              this.processStartedData.series.push(obj);
            }
            let findTime=timeArray.find(dt=>dt==dt[0]);
            if(!findTime){
              timeArray.push(dt[0]);       
            }
            let findObj=this.processStartedData.legendData.find(d=>d.name==dt[1]);
            if(!findObj){
              this.processStartedData.legendData.push({icon:'circle',name:dt[1]});
            }
          }
        });
       
        let sorted : any = timeArray.sort((a,b)=> {
          var d1 = new Date(b);
          var d2 = new Date(a);
          return d2 > d1 ? 1 : 0;
        })
      
        sorted.forEach((x)=>{
          if(index1 == 0){
            let findXAxis=this.processStartedData.xAxis.find(dt=>dt==this.timeFormat.transform(x));
            if(!findXAxis){
            this.processStartedData.xAxis.push(this.timeFormat.transform(x))
            }
          }
        })
      } else if(type=='accountWithFailedOption'&&this.filterCheck(ds[2])){
        this[type].yAxis.push(ds[0]);
        this[type].data.push(ds[3]);
      } else if(this.filterCheck(ds[1])){
        this[type].yAxis.push(ds[0]);
        this[type].data.push(type=='failedLoginAttemptsOption'?ds[3]:ds[2]);
      }
    })
  }

  getProcessUsedData(){
    this.processUsedData.showLoader = true;
    this.processUsedData.data = [];
    this.processUsedData.legendData = [];
    this.processUsedData.tableData=[];
    this.processUsedData.tempData =[];
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    this.getTableData('processUsedData');
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.processUsedData.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.processUsedData.currentFilter))
    }
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.processUsedData.showLoader = false
      if(!res || !res.body) return 
      //var chrome = 0, winlogon = 0 , svchost = 0;
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.processUsedData.tempData.push(ds);
          // if(ds[0]=="chrome.exe"){
          //   chrome=chrome+ds[1];
          // }else if(ds[0]=="winlogon.exe"){
          //   winlogon=winlogon+ds[1];
          // }else if(ds[0]=="svchost.exe"){
          //   svchost=svchost+ds[1];
          // }
          
        });
      })
      this.filterAllData('processUsedData');

      // this.processUsedData.data.push({value:chrome,name:'Chrome'})
      // this.processUsedData.data.push({value:winlogon,name:'Winlogon.exe'})
      // this.processUsedData.data.push({value:svchost,name:'svchost.exe'})
      // this.processUsedData.legendData.push({icon:'circle',name:'Chrome'})
      // this.processUsedData.legendData.push({icon:'circle',name:'Winlogon.exe'})
      // this.processUsedData.legendData.push({icon:'circle',name:'svchost.exe'})
    },err=>{
      this.processUsedData.showLoader = false
    })
  }

  filterData(row){
    if(row[row.length-1]=="$"){
      return row.substring(0,row.length-1);
    }else{
      return row
    }
  }
  
  getProcessStartedData(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.processStartedData.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.processStartedData.currentFilter))
    }
    this.getTableData('processStartedData');
    this.processStartedData.showLoader = true
    this.processStartedData.legendData = []
    this.processStartedData.series = []
    this.processStartedData.xAxis = []
    //this.processStartedData.tableData=[];
    this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.processStartedData.showLoader = false
      if(!res || !res.body) return 
      
      res.body.tables.forEach((dt,index1)=>{
        this.processStartedData.tempData.push(dt);
      })
      this.filterAllData('processStartedData')
    },err=>{
      this.processStartedData.showLoader = false
    })
    
    // return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
    //   this.processStartedData.showLoader = false
    //   if(!res || !res.body) return 
    //   res.body.tables.forEach(dt=>{
    //     dt.rows.forEach((e,index1)=>{
          
    //     })
    //   })
    // },err=>{
    //   this.processStartedData.showLoader = false
    // })
  }

  getJsonData(rowData){
    if(rowData){
      try {
        return JSON.parse(rowData).join(', ');
      } catch {
        return ''
      }
    }else{
      return ''
    }
  }

  openPopup(template?: TemplateRef<any>) {
    this.modalService.open(template);
  }

  openPopupCustom(type:string, list, template?: TemplateRef<any>): void {
    if(type==='multiplePasswordReset') {
      this.detailsPop=list;
      this.detailsPop[9]=[];
      if(list[7]) {
        let data=JSON.parse(list[7]);
        data.forEach(dt=>{
          this.detailsPop[9].push({
            initiatedBy:dt
          })
        })
      }
      if(list[4]) {
        let data=JSON.parse(list[4]);
        data.forEach((dt,index)=>{
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            timeGenerated:dt
          }
        })
      }
      if(list[5]) {
        let data=JSON.parse(list[5]);
        data.forEach((dt,index)=>{
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            result:dt
          }
        })
      }
      if(list[6]) {
        let data=JSON.parse(list[6]);
        data.forEach((dt,index)=>{
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            message:dt
          }
        })
      }
    } else {
      this.detailsPop=list;
    }
    this.modalService.open(template);
  }

  downloadDirectTableView(name,tableId){
    let table=document.getElementById(tableId);
    if(table) {
      this.downloadFile(name,table)
    } else {
      this.downloadFileCustom(name);
    }
  }

  downloadFileCustom(name): void {
    let headers = [];
    let fileName = name;
    let dataList = [];
    if (name == 'MultiplePasswordReset') {
      fileName = 'Multiple Password Reset';
      headers = ['User','Email','Count of Password Resets','First Reset Time (UTC)','Last Reset Time (UTC)'];
    } else if (name == 'MPRPopup') {
      fileName = 'Multiple Password Reset';
      headers = ['User','Email','Count of Password Resets','Initiated By','Time Generated (UTC)','Result','Message'];
      this.detailsPop[9].forEach(data=>{
        let value=[];
        value.push(this.detailsPop[8]);
        value.push(this.detailsPop[0]);
        value.push(this.detailsPop[1]);
        Object.keys(data).forEach(key=>{
          if(key === 'timeGenerated') {
            value.push(this.datePipe.transform(data[key],'MM/dd/yyyy, hh:mm:ss a'));  
          } else {
            value.push(data[key]);
          }
        })
        dataList.push(value);
      })
    }
    this.util.generateCSVForCustom(fileName, headers, dataList);
  }

  downloadFile(name,tableId){
    this.util.generateCSVForTableData(name,tableId);
  }

  // closeModal() {
  //   this.modalService.dismissAll();
  // }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    list.currentFilter.type = value
    switch(list.type){
      case 'processUsedData' :this.getProcessUsedData();break;
      case 'processStartedData' :this.getProcessStartedData();break;
      case 'remotedLoggedAcc' :this.getQueryResult('remotedLoggedAccOption');break;
      case 'accountWithFailed' :this.getQueryResult('accountWithFailedOption');break;
      case 'failedLoginAttempts' :this.getQueryResult('failedLoginAttemptsOption');break;   
      case 'multiplePasswordReset' :this.getTableData('multiplePasswordReset');break;   
      case 'newProcesesObserved' :this.getTableData('newProcesesObserved');break;   
      case 'processAttemptedCard' :this.getTableData('processAttemptedCard');break;   
      case 'failedLogonCard' :this.getTableData('failedLogonCard');break;   
      case 'multiplePasswordResetCard' :this.getTableData('multiplePasswordResetCard');break;   
    }
    this.closeFilter()
  }
  
  applyCustomFilter(){
    this.selectedData.currentFilter.type = 'custom';
    switch(this.selectedData.type){
      case 'processUsedData' : this.processUsedData = this.selectedData;this.getProcessUsedData();break;
      case 'processStartedData' : this.processStartedData = this.selectedData;this.getProcessStartedData();break;
      case 'remotedLoggedAcc' :this.remotedLoggedAccOption = this.selectedData;this.getQueryResult('remotedLoggedAccOption');break;
      case 'accountWithFailed' :this.accountWithFailedOption = this.selectedData;this.getQueryResult('accountWithFailedOption');break;
      case 'failedLoginAttempts' :this.failedLoginAttemptsOption = this.selectedData;this.getQueryResult('failedLoginAttemptsOption');break; 
      case 'multiplePasswordReset' :this.multiplePasswordReset = this.selectedData;this.getTableData('multiplePasswordReset');break; 
      case 'newProcesesObserved' :this.newProcesesObserved = this.selectedData;this.getTableData('newProcesesObserved');break; 
      case 'processAttemptedCard' :this.processAttemptedCard = this.selectedData;this.getTableData('processAttemptedCard');break; 
      case 'failedLogonCard' :this.failedLogonCard = this.selectedData;this.getTableData('failedLogonCard');break; 
      case 'multiplePasswordResetCard' :this.multiplePasswordResetCard = this.selectedData;this.getTableData('multiplePasswordResetCard');break; 
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 100;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if(!this.timeFilterFlag){
    this.closeFilter();
    }
    this.timeFilterFlag=false;
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.currentFilter.type);
    return findObj&&findObj.name?findObj.name:''
  }

  applyFilterData(){
    this.filterTableData('processStartedData');
    this.filterTableData('processUsedData');
    this.filterTableData('remotedLoggedAccOption');
    this.filterTableData('accountWithFailedOption');
    this.filterTableData('failedLoginAttemptsOption');
    this.filterTableData('newProcesesObserved');
    this.filterAllData('remotedLoggedAccOption');
    this.filterAllData('accountWithFailedOption');
    this.filterAllData('failedLoginAttemptsOption');
    this.filterAllData('processUsedData');
    this.filterAllData('processStartedData');
    this.filterDataForCard('processAttemptedCard');
    this.filterDataForCard('failedLogonCard');
    this.filterDataForCard('multiplePasswordResetCard');
  }


  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    // this.getAllData()
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    console.log('Filter Object',this.filterObject)
    this.applyFilterData();
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    this.applyFilterData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }


  async refreshData(type){
    switch (type) {
      case 'processUsedData':{
        this.refreshedTime.processUsedData = new Date();
        this.getProcessUsedData();
        break;
      }
      case 'remotedLoggedAcc':{
        this.refreshedTime.remotedLoggedAcc = new Date();
        this.getQueryResult('remotedLoggedAccOption');
        break;
      }
      case 'processStartedData':{
        this.refreshedTime.processStartedData = new Date();
        this.getProcessStartedData();
        break;
      }
      case 'accountWithFailed':{
        this.refreshedTime.accountWithFailed = new Date();
        this.getQueryResult('accountWithFailedOption');
        break;
      }
      case 'failedLoginAttempts':{
        this.refreshedTime.failedLoginAttempts = new Date();
        this.getQueryResult('failedLoginAttemptsOption');
        break;
      }
      case 'multiplePasswordReset':{
        this.refreshedTime.multiplePasswordReset = new Date();
        this.getTableData('multiplePasswordReset');
        break;
      }
      case 'newProcesesObserved':{
        this.refreshedTime.newProcesesObserved = new Date();
        this.getTableData('newProcesesObserved');
        break;
      }
      case 'processAttemptedCard':{
        this.refreshedTime.processAttemptedCard = new Date();
        this.getTableData('processAttemptedCard');
        break;
      }
      case 'failedLogonCard':{
        this.refreshedTime.failedLogonCard = new Date();
        this.getTableData('failedLogonCard');
        break;
      }
      case 'multiplePasswordResetCard':{
        this.refreshedTime.multiplePasswordResetCard = new Date();
        this.getTableData('multiplePasswordResetCard');
        break;
      }
    }
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

}