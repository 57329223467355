<div class="alerts-container">
    <!-- <div class="header-container">
      <h2 class="title">Alerts</h2>
    </div> -->

     <!-- Filter Section -->
     <!-- <div class="custom-filter-wrapper flex-custom">
      <div class="custom-filter">
          <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
       
          <div class="filter-dropdown" id="filter-dropdown" >
              <div class="enlist-srch">
                  <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                  <img src="assets/icons/search.png">
              </div>
              <div class="filter-content">
                  <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                      <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                      <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                  </div>
                  <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                      <p  class="content-14 blur-text">No filter found</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="custom-filter">
          <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
          <div class="filter-dropdown" id="filter-dropdown" >
              <div class="enlist-srch">
                  <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                  <img src="assets/icons/search.png">
              </div>
              <div class="filter-content">
                  <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                      <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                      <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                  </div>
                  <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                      <p  class="content-14 blur-text">No filter found</p>
                  </div>
              </div>
             
          </div>
      </div>
      <div class="custom-filter">
          <span class="text">Web App<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
          <div class="filter-dropdown" id="filter-dropdown" >
              <div class="enlist-srch">
                  <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                  <img src="assets/icons/search.png">
              </div>
              <div class="filter-content">
                  <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                      <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                      <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                  </div>
                  <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                      <p  class="content-14 blur-text">No filter found</p>
                  </div>
              </div>
          </div>
      </div>
      <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
          <img src="/assets/common-icons/close-filter.svg" alt="">
          Clear Filter
      </button>
     </div> -->
      
    <div class="dashboard-body mt-2">
          <div class="data-wrapper">
            <div class="flex-custom no-wrap">
              <div class="header"></div>
              <div class="flex-custom no-wrap ml-auto">
                <button class="btn-btn-secondary" (click)="showNewAlert = true;createAlertForm();isEdit=false">
                  <img src="/assets/governance/add-btn.svg" alt="">
                  New alert rule
                </button>
                <button class="btn-btn-secondary" (click)="showNewAction = true;createActionForm();">
                  <img src="/assets/governance/add-btn.svg" alt="">
                  New action group
                </button>
                <ng-container *ngIf="alertList?.length">
                  <img style="height: 24px;margin-left: 8px;" src="assets\user-manual\Rectangle.svg">
                  <span class="action-icon ml-2" matTooltip="Download CSV" matTooltipClass="nav-tooltips"
                      matTooltipShowDelay="500" (click)="downloadDirectTableView('Application Health Alerts','table1') "><img
                      src="/assets/common-icons/download-grey.svg" alt=""></span>
                </ng-container>
              </div>
            </div>
            <div class="custom-fixed-tables" style="max-height: 656px;" *ngIf="alertList?.length && !showLoader">
              <table class="table" id="table1">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertList','name')">
                          </div>
                      </th>
                      <th class="col-5">
                          <div class="flex-custom no-wrap">
                              <span>Condition</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertList','condition')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Status</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertList','status')">
                          </div>
                      </th>
                      <th>
                        <div class="flex-custom no-wrap">
                            <span>Target Resource</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertList','targetResource')">
                        </div>
                      </th>
                      <th>
                        <div class="flex-custom no-wrap">
                            <span>Target Resource Type</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertList','targetResourceType')">
                        </div>
                      </th> 
                      <th style="border-right: none;">
                        <div class="flex-custom no-wrap">
                            <span>Signal Type</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertList','signalType')">
                        </div>
                      </th>
                      <th style="border-left: none;"></th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of alertList">
                        <td [title]="row?.name">{{row?.name | displayTxt}}</td>
                        <td [title]="row?.condition">{{row?.condition | displayTxt}}</td>
                        <td>{{row?.status | displayTxt}}</td>
                        <td [title]="row?.targetResource">{{row?.targetResource | displayTxt}}</td>
                        <td [title]="row?.targetResourceType">{{row?.targetResourceType | displayTxt}}</td>
                        <td [title]="row?.signalType">{{row?.signalType| displayTxt}}</td>
                        <td class="text-right">
                          <span>
                            <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="alert">
                            <mat-menu #alert="matMenu" class="delete-popup">
                              <div class="mat-menu-item" mat-menu-item (click)="editAlert(row)">
                                <p class="delete-popup-text">Edit alert</p>
                              </div>
                              <div class="mat-menu-item" mat-menu-item (click)="openConfirmModal(enableDisableConfirm,row)">
                                <p class="delete-popup-text">{{row.status == 'Enabled' ? 'Disable alert': 'Enable alert'}}</p>
                              </div>
                              <div class="mat-menu-item" mat-menu-item (click)="openConfirmModal(deleteConfirm,row)">
                                <p class="delete-popup-text">Delete alert</p>
                              </div>
                            </mat-menu>
                          </span>              
                        </td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="loader-gif" *ngIf="showLoader">
              <img src="assets/icons/blue-loader.gif"/>
              <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!alertList?.length && !showLoader)">
                <img src="/assets/common-icons/no-data-alert.svg" alt="">
                <p class="text-16-26 bold-600">No alerts found.</p>
            </div>
          </div>
    </div>
</div>


<ng-container *ngIf="showNewAction">
  <div class="sidebar-right single add-action">
        <div class="header">
            <div class="d-flex">
               <h4> Create action group</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showNewAction = false;resetForm('actionGroup')">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body scroll-y">
          <form [formGroup]="actionForm">
            <div class="comm-mb" *ngIf="!showNewAlert">
              <label class="flex-custom nowrap">Subscription<span class="mandatory">*</span></label>
              <ng-select [items]="subscriptions"  bindLabel="subscriptionName" bindValue="subscriptionId" (change)="getRgBySubscription()"  name="actionSubs" [clearable]="false" id="actionSubs" formControlName="subscription"></ng-select>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Resource group<span class="mandatory">*</span></label>
              <ng-select [items]="ResourceGroups" bindLabel="name" bindValue="name" name="rgName" [clearable]="false" id="rgName" formControlName="resourceGroup"></ng-select>
              <!-- <span class="action-btn" (click)="openModal(createResourceGroupPopup)">Create new</span> -->
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Action group name<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an action group name that is unique within the resource group." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <input class="form-control full-width" type="text" formControlName="actionGroup" (keyup)="updateActionGroupDisaplayName()" [class.border-danger]="(actionForm.get('actionGroup')?.dirty ||  actionForm.get('actionGroup')?.touched ) && actionForm.get('actionGroup')?.errors?.specialCharacterValidator || actionForm.get('actionGroup')?.errors?.duplicated"/>
              <p class="text-danger" *ngIf="(actionForm.get('actionGroup')?.dirty ||  actionForm.get('actionGroup')?.touched ) && actionForm.get('actionGroup')?.errors?.specialCharacterValidator">Name is Invalid.</p>
              <p class="text-danger" *ngIf="(actionForm.get('actionGroup')?.dirty ||  actionForm.get('actionGroup')?.touched ) && actionForm.get('actionGroup')?.errors?.duplicated">Name already exist.</p>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Display name<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This display name will be shown as the action group name in email and SMS notifications." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <input class="form-control full-width" type="text" formControlName="displayName" maxlength="12" [class.border-danger]="(actionForm.get('displayName')?.dirty ||  actionForm.get('displayName')?.touched ) && actionForm.get('displayName')?.errors?.specialCharacterValidator"/>
              <p class="text-danger" *ngIf="(actionForm.get('displayName')?.dirty ||  actionForm.get('displayName')?.touched ) && actionForm.get('displayName')?.errors?.specialCharacterValidator">Name is Invalid.</p>
              <p class="blur-text">This display name is limited to 12 characters</p>
            </div>
            <div class="divider comm-mb"></div>
            <div class="sub-title">Notifications</div>
            <p class="blur-text">Configure the method in which users will be notified when the action group triggers.</p>
            <div class="comm-mb">
              <ng-container formArrayName="notificationArray" *ngFor="let tanch of actionForm.get('notificationArray')['controls']; let i = index;">
                <div class="comm-mb ">
                  <div class="notify-wrap" id="notify-list-{{i}}" [formGroupName]="i">
                    <div class="w-50">
                      <label class="flex-custom nowrap">Notification type<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the type of notification that will be sent to the reciever." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="actionNotifyList"  (change)="selectNotify($event,i)" bindLabel="name" bindValue="id" name="notify" [clearable]="false" id="notify" formControlName="type"></ng-select>
                    </div>
                    <div class="w-50 ml-3">
                      <label class="flex-custom nowrap">Name<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a unique name for the notification, under 128 characters.Notification and action names must be unique from one another." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" formControlName="value" maxlength="128" [class.border-danger]="tanch.get('value')?.errors?.required || tanch.get('value')?.errors?.duplicated" (keyup)="checkDuplicates('value',i)"/>
                      <p class="text-danger" *ngIf="tanch.get('value')?.errors?.required">Name is required.</p>
                      <p class="text-danger" *ngIf="tanch.get('value')?.errors?.duplicated">Name cannot be same.</p>
                    </div>
                    <img src="assets/app-monitoring/Edit.svg" alt="" class="cursor-pointer ml-2 mt-3"  *ngIf="tanch.get('type').value" (click)="editNotification(i)">
                    <ng-container *ngIf="notifyList?.length > 1">
                      <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeNotify(i)">
                    </ng-container>
                  </div>
                  <span class="action-btn" (click)="addNotify()" *ngIf="i == notifyList?.length-1 && checkNotifyDataAvailable()">Add another notification</span>
                </div>
              </ng-container>
            </div>
            <div class="divider comm-mb"></div>
            <div class="comm-mb">
              <ng-container formArrayName="tagsArray" *ngFor="let tanch of actionForm.get('tagsArray')['controls']; let i = index;">
                <div class="comm-mb ">
                  <div class="notify-wrap" id="tags-list-{{i}}" [formGroupName]="i">
                    <div class="w-50">
                      <label class="flex-custom nowrap">Tag name<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" formControlName="name"/>
                    </div>
                    <span class="ml-3 mt-3">:</span>
                    <div class="w-50 ml-3">
                      <label class="flex-custom nowrap">Value<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" formControlName="value"/>
                    </div>
                    <ng-container *ngIf="tagsList?.length > 1">
                      <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeTag(i)">
                    </ng-container>
                  </div>
                  <span class="action-btn" (click)="addTag()" *ngIf="i == tagsList?.length-1">Add another tag</span>
                </div>
              </ng-container>
            </div>
          </form>
        </div>
        
        <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-outline" (click)="showNewAction = false;resetForm('actionGroup')">Cancel</button>
          <button class="btn btn-btn-primary" [class.disabled]="actionForm.invalid" (click)="saveActionGroups()">Save</button>
        </div>
  </div>
</ng-container>

<ng-container *ngIf="showNewAlert">
  <div class="sidebar-right single add-alert" [class.multiple]="showNewAction">
        <div class="header">
            <div class="d-flex">
               <h4>Create Alert Rule</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showNewAlert = false;resetForm('alertRule')">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body scroll-y">
          <form [formGroup]="alertForm">
            <div class="sub-title mb-3">Scope</div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Subscription<span class="mandatory">*</span></label>
              <ng-select [items]="subscriptions"  bindLabel="subscriptionName" bindValue="subscriptionId" [class.disabled]="isEdit" name="subs" (change)="getAllActionGroups();getRgBySubscriptionForAlert()" [clearable]="false" id="subs" formControlName="subscription"></ng-select>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Select scope<span class="mandatory">*</span></label>
              <ng-select [items]="alertScopeList" bindLabel="value" bindValue="id" name="scope" [class.disabled]="isEdit" (change)="getResourcesByType($event)" [clearable]="false" id="scope" formControlName="scope"></ng-select>
            </div>
            <div class="comm-mb" *ngIf="alertForm.get('scope')?.value == 3">
              <label class="flex-custom nowrap">Select storage account<span class="mandatory">*</span></label>
              <ng-select [items]="storageAccountList" bindLabel="name" bindValue="id" name="storageAccount"  (change)="getResourcesByStorageAccount($event)" [clearable]="false" id="storageAccount" formControlName="storageAccount"></ng-select>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Resource<span class="mandatory">*</span></label>
              <ng-select *ngIf="alertForm.get('scope')?.value !== 1" [items]="resourceList" bindLabel="name" bindValue="id" [class.disabled]="isEdit" name="resource" (change)="getAvailableMetricsByResourceId()" [clearable]="false" id="resource" formControlName="resource"></ng-select>

              <div class="margin-multi-select" *ngIf="alertForm.get('scope')?.value == 1">
                <ng-select [items]="resourceList" bindLabel="name" bindValue="id" multiple="true" [selectableGroup]="true" [closeOnSelect]="false" name="resource" (change)="getAvailableMetricsByResourceId()" [clearable]="false" id="resource" formControlName="resource">
                  <ng-template ng-multi-label-tmp let-items="items">
                    <span>{{items.length}} selected</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="checkbox-cs">
                      <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> {{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Alert type<span class="mandatory">*</span></label>
              <ng-select [items]="alertsTypeList" bindLabel="name.localizedValue" bindValue="name.value" name="type" (change)="getAggregationType($event)" [clearable]="false" id="type" formControlName="alertType"></ng-select>
            </div>
            <div class="sub-title mb-3">Alert Logic</div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Operator<span class="mandatory">*</span></label>
              <ng-select [items]="alertOperatorList" bindLabel="value" bindValue="id" name="operator" [clearable]="false" id="operator" formControlName="operator"></ng-select>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Aggregation type<span class="mandatory">*</span></label>
              <ng-select [items]="aggregationTypeList" bindLabel="value" bindValue="value" name="aggregation" [clearable]="false" id="aggregation" formControlName="aggregationType"></ng-select>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Threshold value<span class="mandatory">*</span></label>
              <input class="form-control full-width" type="text" formControlName="thresholdValue" onlyNumber/>
              <p class="blur-text text-right" *ngIf="alertForm.get('unit')?.value">{{alertForm.get('unit')?.value}}</p>
            </div>
            <div class="sub-title mb-3">Evaluated based on</div>
            <div class="comm-mb">
              <div class="flex-custom no-wrap">
                <div class="w-50">
                  <label class="flex-custom nowrap">Period<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Aggregation granularity" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <ng-select [items]="alertPeriodList" bindLabel="value" bindValue="id" name="alertPeriod" (change)=filterFreq($event) [clearable]="false" id="alertPeriod" formControlName="period"></ng-select>
                </div>
                <div class="w-50 ml-3">
                  <label class="flex-custom nowrap">Frequency<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Frequency of evaluation"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <ng-select [items]="alertFrequencyList" bindLabel="value" bindValue="id" name="alertFrequency" [clearable]="false" id="alertFrequency" formControlName="frequency"></ng-select>
                </div>
              </div>
            </div>
            <div class="sub-title mb-3">Actions</div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Action group</label>
              <div class="margin-multi-select">
                <ng-select [items]="actionGroupsList" multiple="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="actionGroup" [clearable]="false" id="actionGroup" formControlName="actionGroup">
                  <ng-template ng-multi-label-tmp let-items="items">
                    <span>{{items.length}} selected</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="checkbox-cs">
                      <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> {{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <span class="action-btn" (click)="showNewAction = true;removeValidator()">Create new</span>
            </div>
            <div class="sub-title mb-3">Alert rule details </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Resource group <span class="mandatory">*</span></label>
              <ng-select [items]="ResourceGroups" bindLabel="name" bindValue="name" [class.disabled]="isEdit" name="alertRg" [clearable]="false" id="alertRg" formControlName="resourceGroup"></ng-select>
              <!-- <span class="action-btn" (click)="openModal(createResourceGroupPopup)">Create new</span> -->
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Alert rule name <span class="mandatory">*</span></label>
              <input class="form-control full-width" type="text" [class.disabled]="isEdit" formControlName="alertRuleName" maxlength="128" [class.border-danger]="(alertForm.get('alertRuleName')?.dirty ||  alertForm.get('alertRuleName')?.touched ) && alertForm.get('alertRuleName')?.errors?.specialCharacterValidator"/>
              <p class="text-danger" *ngIf="(alertForm.get('alertRuleName')?.dirty ||  alertForm.get('alertRuleName')?.touched ) && alertForm.get('alertRuleName')?.errors?.specialCharacterValidator">Name is Invalid.</p>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Description</label>
              <textarea class="form-control full-width" formControlName="description" maxlength="2048"></textarea>
            </div>
            <div class="comm-mb">
              <label class="flex-custom nowrap">Severity <span class="mandatory">*</span></label>
              <ng-select [items]="alertSeverityList" bindLabel="value" bindValue="id" name="severity" [clearable]="false" id="severity" formControlName="severity"></ng-select>
            </div>
            <div class="comm-mb">
              <div class="checkbox-cs editcheckboc">
                <input type="checkbox" name="enableAlert" value="enableAlert" class="mr-2 checkboxStyle" id='enableAlert' formControlName="enableAlert" >
                <label class="pb-0 value" for="enableAlert">Enable alert rule upon creation</label>
              </div>
            </div>
            <div class="comm-mb">
              <div class="checkbox-cs editcheckboc">
                <input type="checkbox" name="automate" value="automate" class="mr-2 checkboxStyle" id='automate' formControlName="automateAlert" >
                <label class="pb-0 value" for="automate">Automatically resolve alerts</label>
              </div>
            </div>
          </form>
        </div>
        
        <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-outline" (click)="showNewAlert = false;resetForm('alertRule')">Cancel</button>
          <button class="btn btn-btn-primary" [class.disabled]="alertForm.invalid" (click)="createNewAlertRule()">Save</button>
        </div>
  </div>
</ng-container>

<ng-template #pushPopup>
  <div class="medium-popup-wrapper2 width-700">
    <div class="modal-header">
      <div class="d-flex">
          <div class="heading-content"><h3>Email/SMS message/Push/Voice</h3></div>
          <button class="cursor-pointer float-right" (click)="closeModal();resetForm('current');removeParticularIndex()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
      </div>
    </div>
    <div class="modal-body">
      <form [formGroup]="currentForm">
        <div class="checkbox-cs">
          <input type="checkbox" name="Email" value="enableAlert" class="form-check-inline" id='Email' formControlName="enableEmail" (change)="changeEmailValidator($event,'email')">
          <label class="value" for="Email">Email</label>
        </div>
        <div class="comm-mb">
          <label class="flex-custom nowrap">Email<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can specify one email address per notification. To add another email address, add another notification." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
          <input class="form-control w-50" type="text" formControlName="emailName" [class.disabled]="!currentForm.get('enableEmail').value" [class.border-danger]="currentForm.get('enableEmail').value && currentForm?.controls?.emailName?.errors?.email"/>
          <p class="text-danger" *ngIf="currentForm.get('enableEmail').value && currentForm?.controls?.emailName?.errors?.pattern">Please enter a valid email address.</p>
        </div>
        <div class="divider comm-mb"></div>
        <div class="checkbox-cs">
          <input type="checkbox" name="sms" value="enableAlert" class="form-check-inline" id='sms' formControlName="enableSMS" (change)="changeCode($event,'sms')">
          <label class="value" for="sms">SMS (Carrier charges may apply)</label>
        </div>
        <div class="flex-custom no-wrap">
          <div class="w-50">
            <label class="flex-custom nowrap">Country code<span class="mandatory">*</span></label>
            <ng-select [items]="countryPhoneCodeList" bindLabel="value" bindValue="value" name="smsCountryCode" [clearable]="false" id="smsCountryCode" formControlName="countryCodeSMS" [class.disabled]="!currentForm.get('enableSMS').value"></ng-select>
          </div>
          <div class="w-50 ml-3">
            <label class="flex-custom nowrap">Phone number<span class="mandatory">*</span></label>
            <input class="form-control full-width" type="text" formControlName="phoneNumberSMS" [class.disabled]="!currentForm.get('enableSMS').value" onlyNumber/>
          </div>
        </div>
      
        <div class="divider comm-mb"></div>
        <div class="checkbox-cs">
          <input type="checkbox" name="push" value="enableAlert" class="form-check-inline" id='push' formControlName="enablePushNotify" (change)="changeEmailValidator($event,'pushNotify')">
          <label class="value" for="push">Azure app Push Notifications</label>
        </div>
        <div class="comm-mb">
          <label class="flex-custom nowrap">Azure account email<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This is the email you use to log into your Azure account" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
          <input class="form-control w-50" type="text" formControlName="emailPushNotify" [class.disabled]="!currentForm.get('enablePushNotify').value" [class.border-danger]="currentForm.get('enablePushNotify').value && currentForm?.controls?.emailPushNotify?.errors?.email"/>
          <p class="text-danger" *ngIf="currentForm.get('enablePushNotify').value && currentForm?.controls?.emailPushNotify?.errors?.pattern">Please enter a valid email address.</p>
        </div>
        <div class="divider comm-mb"></div>
        <div class="checkbox-cs">
          <input type="checkbox" name="voice" value="enableAlert" class="form-check-inline" id='voice' formControlName="enableVoice" (change)="changeCode($event,'voice')">
          <label class="value" for="voice">Voice</label>
        </div>
        <div class="flex-custom no-wrap">
          <div class="w-50">
            <label class="flex-custom nowrap">Country code<span class="mandatory">*</span></label>
            <ng-select class="disabled" [items]="countryPhoneCodeList" bindLabel="value" bindValue="value" name="voiceCountryCode" [clearable]="false" id="voiceCountryCode" formControlName="countryCodeVoice"></ng-select>
          </div>
          <div class="w-50 ml-3">
            <label class="flex-custom nowrap">Phone number<span class="mandatory">*</span></label>
            <input class="form-control full-width" type="text" formControlName="phoneNumberVoice" [class.disabled]="!currentForm.get('enableVoice').value" onlyNumber/>
          </div>
        </div>
        <div class="divider comm-mb"></div>
        <div class="comm-mb">
          <label class="flex-custom nowrap">Enable the common alert schema</label>
          <div class="flex-custom no-wrap">
            <div class="checkbox-cs">
              <input type="radio" name="commonAlert"  class="form-check-inline" id='yes' (change)="setCommonAlert('yes')">
              <label class="value" for="yes">Yes</label>
            </div>
            <div class="checkbox-cs ml-2">
              <input type="radio" name="commonAlert" class="form-check-inline" id='no' (change)="setCommonAlert('no')" checked>
              <label class="value" for="no">No</label>
            </div>
          </div>
        </div>
        <div class="comm-mb" *ngIf="currentForm.get('enableCommonAlert').value">
          <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
            <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
            <label class="" style="padding: 8px 0px !important;color: #703501;">
              If you have existing integrations to parse the alert payload, you'll need to adjust them to the common alert schema.</label>
          </div>  
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex">
      <button class="btn btn-outline ml-auto" (click)="closeModal();resetForm('current');removeParticularIndex()">Cancel</button>
      <button class="btn btn-btn-primary" [class.disabled]="checkSaveEnabled('push') || currentForm.invalid" (click)="closeModal();updateNameValidation()">Save</button>
    </div>
  </div>
</ng-template>

<ng-template #emailPopup>
  <div class="medium-popup-wrapper2">
    <div class="modal-header">
      <div class="d-flex">
          <div class="heading-content"><h3>Email Azure Resource Manager Role</h3></div>
          <button class="cursor-pointer float-right" (click)="closeModal();resetForm('current');removeParticularIndex()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
      </div>
    </div>
    <div class="modal-body">
      <form [formGroup]="currentForm">
        <div class="comm-mb">
          <label class="flex-custom nowrap">Azure resource manager role<span class="mandatory">*</span></label>
          <ng-select [items]="rolesList" bindLabel="name" bindValue="id" name="role" [clearable]="false" id="role" formControlName="rmRole"></ng-select>
        </div>
        <div class="comm-mb">
          <label class="flex-custom nowrap">Enable the common alert schema</label>
          <div class="flex-custom no-wrap">
            <div class="checkbox-cs">
              <input type="radio" name="commonAlert" class="form-check-inline" id='yes' (change)="setCommonAlert('yes')">
              <label class="value" for="yes">Yes</label>
            </div>
            <div class="checkbox-cs ml-2">
              <input type="radio" name="commonAlert"  class="form-check-inline" id='no' (change)="setCommonAlert('no')" checked>
              <label class="value" for="no">No</label>
            </div>
          </div>
        </div>
        <div class="comm-mb" *ngIf="currentForm.get('enableCommonAlert')?.value">
          <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
            <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
            <label class="" style="padding: 8px 0px !important;color: #703501;">
              If you have existing integrations to parse the alert payload, you'll need to adjust them to the common alert schema.</label>
          </div>  
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex">
      <button class="btn btn-outline ml-auto" (click)="closeModal();resetForm('current');removeParticularIndex()">Cancel</button>
      <button class="btn btn-btn-primary" [class.disabled]="!currentForm?.get('rmRole').value" (click)="closeModal();updateNameValidation()">Save</button>
    </div>
  </div>
</ng-template>

<ng-template #createResourceGroupPopup>
  <div class="medium-popup-wrapper2 width-350">
    <div class="modal-header">
      <div class="d-flex">
          <div class="heading-content"><h3>Create Resource Group</h3></div>
          <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
      </div>
    </div>
    <div class="modal-body">
      <div class="comm-mb">
        <label class="flex-custom nowrap">Resource group name<span class="mandatory">*</span></label>
        <input class="form-control full-width" type="text" id="rgName" [(ngModel)]="newResourceGroup.resourceName" (keyup)="validateResourceName($event,90)"/>
        <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
        <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
        <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
      </div>
      <div class="comm-mb">
        <label class="flex-custom nowrap">Resource group location<span class="mandatory">*</span></label>
        <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
      </div>
     
    </div>
    <div class="modal-footer d-flex">
      <button class="btn btn-outline ml-auto" (click)="closeModal()">Cancel</button>
      <button class="btn btn-btn-primary" [class.disabled]="!newResourceGroup.resourceName || !newResourceGroup.resourceLocation">Save</button>
    </div>
  </div>
</ng-template>




<!-- Common delete popup -->
<ng-template #deleteConfirm>
  <div class="medium-popup-wrapper">
    <div class="modal-body">
      <div class="d-flex mb-3">
        <div class="popup-title">Delete Alert Rule</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="dlt-content">
        <p>Are you sure you want to delete "{{selectedRow?.name}}"?</p>
      </div>
      <div class="submit-sec text-right">
        <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
        <button id="secondary" class="btn btn-red" (click)="deleteAlert()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Common enable/disable popup -->
<ng-template #enableDisableConfirm>
  <div class="medium-popup-wrapper">
    <div class="modal-body">
      <div class="d-flex mb-3">
        <div class="popup-title">{{selectedRow?.status == 'Disabled' ? 'Enable' : 'Disable'}} Alert Rule</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="dlt-content">
        <p>Are you sure you want to {{selectedRow?.status == 'Disabled' ? 'enable' : 'disable'}} "{{selectedRow?.name}}"?</p>
      </div>
      <div class="submit-sec text-right">
        <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
        <button id="secondary" class="btn btn-btn-primary" (click)="enableDisableAlert()">{{selectedRow?.status == 'Disabled' ? 'Enable' : 'Disable'}}</button>
      </div>
    </div>
  </div>
</ng-template>