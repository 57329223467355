<div class="introduction">
    <p class="heading" id="0">Overview </p>
       <p class="para mb-0">The landing page of Cost monitoring & optimization displays a dashboard
            with various tiles as below:</p>
            <img style="width:100%;margin-bottom: 12px;"src="assets\user-manual\cm-and-optimize\Overview.svg" >
              
            <ul>
                   <li><b>Spending rate and forecast</b> displays current cost incurred and thereon shows 
                       the forecast for remaining period in a chosen timeframe.  </li>
                       <img  style="width:100%;" src="assets\user-manual\cm-and-optimize\Row.svg" >
                       <figcaption class="caption">Spending rate & forecast </figcaption>

                   <li><b>Cost by Resource Group</b> shows the cost incurred by Resource Group. </li>
                   <img src="assets\user-manual\cm-and-optimize\0.svg" >
                   <figcaption class="caption col-md-6">Cost by RG </figcaption>

                   <li><b>Cost by Service Type</b> shows the cost incurred by service types (for example, 
                       Virtual Machine, Storage, Key Vault, etc.). </li>
                       <img src="assets\user-manual\cm-and-optimize\1.svg" >
                       <figcaption class="caption col-md-6">Cost by resource type  </figcaption>

                   <li><b>Cost by Resource</b> shows the cost incurred by individual resource (for example, 
                       Virtual Machine.).  </li>
                       <img src="assets\user-manual\cm-and-optimize\2.svg" >
                       <figcaption class="caption col-md-6"> Cost by resource </figcaption>

                   <li><b>Cost by Location:</b> cost incurred by the resources deployed in various regions. </li>
                   <img src="assets\user-manual\cm-and-optimize\4.svg" >
                   <figcaption class="caption col-md-6"> Cost by location  </figcaption>

       </ul>
   </div>
   