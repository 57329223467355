import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sql-server',
  templateUrl: './sql-server.component.html',
  styleUrls: ['./sql-server.component.scss']
})
export class SqlServerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
