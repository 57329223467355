<div class="introduction">
    <p class="heading" id="0">Budget & Alerts </p>
        <p class="para">One of the easiest ways to control cost is to set budgets 
            for your projects. And get notified immediately if the budget threshold is 
            crossed so that you can take remedial actions to contain the cost.  </p>
            <p class="mb-0" >Budgets & Alerts landing page enables you to set budget for your projects and 
                thereon the threshold value, which if crossed leads to notification trigger.</p>
                <img src="assets\user-manual\cm-and-optimize\Budgets-&-Alerts.svg" >

  
  
</div>
