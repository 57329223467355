import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compliance-manage-policy',
  templateUrl: './compliance-manage-policy.component.html',
  styleUrls: ['./compliance-manage-policy.component.scss']
})
export class ComplianceManagePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
