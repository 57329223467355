import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-app-service-actions',
  templateUrl: './app-service-actions.component.html',
  styleUrls: ['./app-service-actions.component.scss']
})
export class AppServiceActionsComponent implements OnInit {
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("appServiceDetailsTemplate") appServiceDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  @ViewChild("appServiceActionConfirm") appServiceActionPopup: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    InvalidPortRange:false,
    NotValidPortVal:false,
    trafficLimit:false,
    validScaleName:false,
    validNumber:false,
    validCount:false,
    defvalidNumber:false,
    defvalidCount:false,
    minValidInstance:false,
    maxValidInstance:false,
    defValidInstance:false,
    validMinInstance:false,
    validMaxInstance:false,
    validMaximumInstance:false,
    validDefInstance:false,
    defMinValidInstance:false,
    defMaxValidInstance:false,
    defaultDefValidInstance:false,
    defValidMinInstance:false,
    defValidMaxInstance:false,
    defValidMaximumInstance:false,
    defValidDefInstance:false,
  }
  selectedType: any;
  selectedSideSheetRow: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;

  appServiceData: any;
  disableStart: boolean;
  disableStop: boolean;
  timeZoneList=this.util.getStatic('timeZoneList');
  currentTab: any;
  ResourceGroupsList: any;

  cloneSettingList:any=[
    {name:'Do not clone settings',id:'noClone'}
  ];
  connSettingTypeList:any=[
    {name:'MySQL',id:'MySql'},
    {name:'SQLServer',id:'SQLServer'},
    {name:'SQLAzure',id:'SQLAzure'},
    {name:'PostgreSQL',id:'PostgreSql'},
    {name:'Custom',id:'Custom'},
  ];
  mode=[
    {id:'Disabled',name:'Disabled'},
  ]
  appServiceSlotList: any;
  appServiceWebConfig: any;
  currentSubSideSheet: string;
  isEdit: boolean;
  appSettingList: any;
  connSettingList: any;
  disabledSave: boolean;
  deploySlotsConfig: any;
  autoScaleData: any;
  disableAutoScaleName: boolean;
  defaultScaleCondition: boolean;
  scaleCondition: boolean;
  stackTypeList: any;
  majorVersionList: any;
  minorVersionList: any;
  ftpStateList:any=[
    {name:'All allowed',id:'AllAllowed'},
    {name:'FTPS only',id:'FtpsOnly'},
    {name:'Disabled',id:'Disabled'},
  ];
  httpversionList:any=[
    {name:'2.0',id:true},
    {name:'1.1',id:false},
  ];
  tlsVersionList:any=[
    {name:'1.0',id:'1.0'},
    {name:'1.1',id:'1.1'},
    {name:'1.2',id:'1.2'},
  ];
  appServicePlanData: any;
  appServicePlanSKUList: any;
  appServicePlanAllSKUList: any;
  additionalTierlist: any;
  appSettingBody: any;
  connSettingBody: any;
  currentConnString: any;
  currentAppSetting: any;


  constructor( public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private _dataMigrationService: DataMigrationService,
    private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }
   async getOverviewCondition(data){
      this.disableStart=false
      this.disableStop=false
      await this.getAppServiceData(data,'disableCondition')
    }

  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'startAppService': {
        this.closeModal()
        return this.startAppService(data)
      }
      case 'restartAppService': {
        this.closeModal()
        return this.openModal(this.appServiceActionPopup,data,'restartAppService')
      }
      case 'stopAppService': {
        this.closeModal()
        return this.openModal(this.appServiceActionPopup,data,'stopAppService')
      }
      case 'deleteAppServiceData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteAppServiceData')
      }
    }
  }
    // Trigger action of the App service 

  startAppService(data){
    let subscriptionId = data[2],resourceGroupName = data[1],appServiceName = data[0];
    return this.util.handleRequest('post','a3s_management_startAppService',[subscriptionId,resourceGroupName,appServiceName],null,null,null,true).then(res=>{
      this.util.success(data[0] + ' started successfully!')
      this.closeModal()
    },err=>{
      this.util.error(data[0] +' failed to start.')
      this.closeModal()
    })
  }
  restartAppService(data){
    let subscriptionId = data[2],resourceGroupName = data[1],appServiceName = data[0];
      return this.util.handleRequest('post','a3s_management_restartAppService',[subscriptionId,resourceGroupName,appServiceName],null,null,null,true).then(res=>{
        this.util.success(data[0] + ' restarted successfully!')
        this.closeModal()
      },err=>{
        this.util.error(data[0] +' failed to restart.')
        this.closeModal()
    })
  }
  stopAppService(data){
    let subscriptionId = data[2],resourceGroupName = data[1],appServiceName = data[0];
      return this.util.handleRequest('post','a3s_management_stopAppService',[subscriptionId,resourceGroupName,appServiceName],null,null,null,true).then(res=>{
        this.util.success(data[0] + ' stopped successfully!')
        this.closeModal()
      },err=>{
        this.util.error(data[0] +' failed to stop .')
        this.closeModal()
    })
  }
  get trafficArrayList() {
    return this.currentForm.get('trafficArray') as FormArray;
  }

  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "deployementSlots" : {
        this.closeModal()
        this.currentForm = this.util.getForm('appServiceDeployementSlots')
        await this.getAppServiceConfigData(this.selectedRow)
        await this.getAppServiceSlotList(this.selectedRow)
        await this.setDefaultValue('deployementSlots')
        this.saveDisable=false
        this.showSidesheet = true
        this.showSubSidesheet=false
        break;
      } 
      case "configuration" : {
        this.closeModal()
        await this.getDeploySlotsConfig(this.selectedRow,'slotconfignames')
        await this.getStackList()
        await this.getAppServiceConfigData(this.selectedRow)
        await this.getConnStringList(this.selectedRow,'connectionstrings') 
        await this.getAppSettingsList(this.selectedRow,'appsettings')
 
        this.saveDisable=false
        this.currentTab ='appSetting'
        this.showSidesheet = true;
        break;
      } 
      case "scaleUp" : {
        this.closeModal()
        this.currentForm = this.util.getForm('appServiceScaleUp')
        await this.getAppServicePlanData(this.selectedRow)
        await this.getAppServicePlanSKUList(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "scaleOut" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetScaling')
        this.currentTab = 'configure'
        await this.getRgBySubscription()
        await this.getAppServicePlanData(this.selectedRow)
        await this.getAutoScalingData(this.selectedRow)
        await this.setDefaultValue('scaleOut')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
    }
  }
  getRgBySubscription(){
    let body={
        subscriptionId : this.selectedRow[2],
    }
    return this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
        console.log('Resource Group list ',res);
        this.ResourceGroupsList = res && res.value ? res.value : []
    })
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteAppServiceData' :return this.deleteAppServiceData(this.selectedRow)
      case 'restartAppService' :return  this.restartAppService(this.selectedRow)    
      case 'stopAppService' :return this.stopAppService(this.selectedRow)
    }
  }

  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedRow=row;
    this.selectedType=type;
    type=='appServicePopup'?this.getAppServiceData(row,'disableCondition'):false 
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }

  getResourceTitle(){
    switch(this.selectedType){
      case'deleteAppServiceData':return 'Delete App Service'
      case'stopAppService' : return'Stop Web App'
      case'restartAppService' : return'Restart App '
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteAppServiceData':return `Are you sure you want to delete “${this.selectedRow[0]}”?` 
      case'stopAppService':return `Are you sure you want to stop “${this.selectedRow[0]}”? `
      case'restartAppService':return `Are you sure you want to restart “${this.selectedRow[0]}”? `
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteAppServiceData':return 'Delete'
      case'restartAppService':return 'Restart'
      case'stopAppService':return 'Stop'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  // getStatusName(val){
  //   let name = val?.statuses.length<2 ? 'updating':val?.statuses[1]?.code.split('/')[1].toLowerCase() 
  //   return name
  // }
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }

  getSuccesText(type){
    switch(type){
      case'upgradePolicy':return `${this.selectedRow[0]} policy upgraded successfully!` 
      case 'addSlot':return `“${this.subSideSheetForm.get('slotName').value}” slot created successfully!` 
      case'configuration':return `“${this.selectedRow[0]}” configuration updated successfully!`
      case'scaleUp':return `“${this.selectedRow[0]}” Scaled up successfully!`
      case'scaleOut':return `“${this.selectedRow[0]}” Scaled out successfully!`
      default: return 'Changes saved successfully!'
    }
  }

  getFailureText(type){
    switch(type){
      case'upgradePolicy':return `${this.selectedRow[0]} policy failed to upgrade.`
      case 'addSlot':return `“${this.subSideSheetForm.get('slotName').value}” slot failed to be created.` 
      case 'configuration':return `“${this.selectedRow[0]}” configuration failed to be updated.`
      case'scaleUp':return `“${this.selectedRow[0]}” failed to be scaled up.`
      case'scaleOut':return `“${this.selectedRow[0]}” failed to be scaled out.`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'upgradePolicy':return `Upgrading ${this.selectedRow[0]} policy ...`
      case 'addSlot':return `Creating new slot “${this.subSideSheetForm.get('slotName').value}”...` 
      case'configuration':return `Updating “${this.selectedRow[0]}” configuration...`
      case'scaleUp':return `Scaling up “${this.selectedRow[0]}”...`
      case'scaleOut':return `Scaling out “${this.selectedRow[0]}”...`
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'appServiceData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
      case 'size':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a?.series;
          let y = b?.series;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }

  invalidForm(type){
    switch(type){
        case'scaling':{
      return this.inputError?.validScaleName||this.inputError?.validMinInstance||this.inputError?.minValidInstance||((this.currentForm.get('instanceMin')?.dirty ||  this.currentForm.get('instanceMin')?.touched) && !this.currentForm.get('instanceMin')?.value) ||
      this.inputError?.validMaximumInstance||this.inputError?.validMaxInstance||this.inputError?.maxValidInstance||((this.currentForm.get('instanceMax')?.dirty ||  this.currentForm.get('instanceMax')?.touched) && !this.currentForm.get('instanceMax')?.value) ||
      this.inputError?.validDefInstance||this.inputError?.defValidInstance||((this.currentForm.get('instanceDef')?.dirty ||  this.currentForm.get('instanceDef')?.touched) && !this.currentForm.get('instanceDef')?.value) ||
      this.inputError?.validCount||this.inputError?.validNumber||((this.currentForm.get('scaleInstanceCount')?.dirty ||  this.currentForm.get('scaleInstanceCount')?.touched) && !this.currentForm.get('scaleInstanceCount')?.value) ||
      this.inputError?.defMinValidInstance||this.inputError?.defValidMinInstance||((this.currentForm.get('defInstanceMin')?.dirty ||  this.currentForm.get('defInstanceMin')?.touched) && !this.currentForm.get('defInstanceMin')?.value) ||
      this.inputError?.defValidMaximumInstance|| this.inputError?.defMaxValidInstance||this.inputError?.defValidMaxInstance||((this.currentForm.get('defInstanceMax')?.dirty ||  this.currentForm.get('defInstanceMax')?.touched) && !this.currentForm.get('defInstanceMax')?.value) ||
      this.inputError?.defValidDefInstance||this.inputError?.defaultDefValidInstance||((this.currentForm.get('defInstanceDef')?.dirty ||  this.currentForm.get('defInstanceDef')?.touched) && !this.currentForm.get('defInstanceDef')?.value) ||
      this.inputError?.defvalidNumber||this.inputError?.defvalidCount||((this.currentForm.get('defScaleInstanceCount')?.dirty ||  this.currentForm.get('defScaleInstanceCount')?.touched) && !this.currentForm.get('defScaleInstanceCount')?.value) ||
      this.inputError?.InvalidPortRange||this.inputError?.NotValidPortVal||((this.currentForm.get('overRideInstanceCount')?.dirty ||  this.currentForm.get('overRideInstanceCount')?.touched) && !this.currentForm.get('overRideInstanceCount')?.value)
   }
    //   case'configuration':{
    //     return this.inputError?.InvalidPortRange||this.inputError?.NotValidPortVal||((this.currentForm.get('terminationDelay')?.dirty ||  this.currentForm.get('terminationDelay')?.touched) && !this.currentForm.get('terminationDelay')?.value)
    //  }
    }
  }
  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      InvalidPortRange:false,
    NotValidPortVal:false,
    trafficLimit:false,
    validScaleName:false,
    validNumber:false,
    validCount:false,
    defvalidNumber:false,
    defvalidCount:false,
    minValidInstance:false,
    maxValidInstance:false,
    defValidInstance:false,
    validMinInstance:false,
    validMaxInstance:false,
    validMaximumInstance:false,
    validDefInstance:false,
    defMinValidInstance:false,
    defMaxValidInstance:false,
    defaultDefValidInstance:false,
    defValidMinInstance:false,
    defValidMaxInstance:false,
    defValidMaximumInstance:false,
    defValidDefInstance:false,
    }
  }
  async deletescaleCondition(index){
    this.saveDisable=true
   this.scaleConditionArrayList.controls.splice(index, 1);
   this.scaleCondition= this.scaleConditionArrayList.controls.length==0?false:true
  }
  get scaleConditionArrayList() {
    return this.currentForm.get('scaleConditonArray') as FormArray;
  }
  addScaleCondition(){
    if(!this.scaleCondition){
      this.scaleConditionArrayList.controls.length==0?this.scaleConditionArrayList.push(this.util.getForm('addScaleConditonArray')):false
      return this.scaleCondition=true
    }
    this.scaleCondition=true
    this.scaleConditionArrayList.push(this.util.getForm('addScaleConditonArray'))
  }
  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'configuration':{
      this.saveDisable=true
      switch(subType){
        case 'webSocket':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('webSocket').setValue(value)
          break;
        }
        case 'alwaysOn':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('alwaysOn').setValue(value)
          break;
        }
        case 'arrAffinity':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('arrAffinity').setValue(value)
          break;
        }
        case 'httpsOnly':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('httpsOnly').setValue(value)
          break;
        }
        case 'remoteDebug':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('remoteDebug').setValue(value)
          break;
        }
        case 'clientDebug':{
          let value = id 
          this.currentForm.get('clientDebug').setValue(value)
          break;
        }
      }
    break;}  

    case 'scaling':{
      this.saveDisable=true
      switch(subType){
        case 'scale':{
          let value = id == 'manual' ? 'manual' : 'custom'
          this.currentForm.get('scale').setValue(value)
          if(value=='custom'){
            this.currentForm.get('autoscaleName').setValidators(Validators.required)
            this.currentForm.get('defScaleConditionName').setValidators(Validators.required)
            // this.resetValidation()
            // this.resetForm()
          }else{
            this.currentForm.get('autoscaleName').setValidators(null)
            this.currentForm.get('defScaleConditionName').setValidators(null)

          }
          this.currentForm.get('autoscaleName').updateValueAndValidity()
          this.currentForm.get('defScaleConditionName').updateValueAndValidity()
          
          break;
        }
        case 'defScaleMode':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('defScaleMode').setValue(value)
          break;
        }
        case 'scaleMode':{
          let value = id == 'yes' ? true : false
          this.scaleConditionArrayList.controls[index].get('scaleMode').setValue(value)
          break;
        }
        case 'schedule':{
          let value = id == 'yes' ? true : false
          this.scaleConditionArrayList.controls[index].get('schedule').setValue(value)
          // formArr.get('schedule').setValue(value)
          break;
        }
      }
    break;}
    
    case 'scaleUp':{
      // this.saveDisable=true
      this.currentForm.get('type').setValue(id)
      if(id=='dev'){
        this.appServicePlanSKUList=this.appServicePlanAllSKUList.filter(dt=>dt.sku.name=='F1' || dt.sku.name=='B1')
        this.appServicePlanSKUList = this.appServiceData?.kind=='app'? this.appServicePlanAllSKUList.filter(dt=>dt.sku.name=='F1' || dt.sku.name=='D1' || dt.sku.name=='B1') : this.appServicePlanSKUList
        this.additionalTierlist=this.appServicePlanAllSKUList.filter(dt=>dt.sku.name=='B2' || dt.sku.name=='B3')
      }else if(id=='production'){
        this.appServicePlanSKUList=this.appServicePlanAllSKUList.filter(dt=>dt.sku.tier=='PremiumV3' || dt.sku.tier=='PremiumV2') 
        this.additionalTierlist=this.appServicePlanAllSKUList.filter(dt=>dt.sku.tier=='Standard' || dt.sku.tier=='Premium')
      }else{
        this.appServicePlanSKUList=[]
        this.additionalTierlist=[]
      }
    break;} 
   }
  }

  async setDefaultValue(type){
  switch(type){
    case'deployementSlots':{
      this.currentForm.get('defaultTraffic').setValue(100)
      let trafficPercent=0;
      if( this.appServiceWebConfig?.properties?.routingRules.length==this.appServiceSlotList.length){
        this.appServiceWebConfig?.properties?.routingRules.forEach(e=>{
          trafficPercent=trafficPercent+e.reroutePercentage
          this.trafficArrayList.push(this.util.getForm('addtrafficArray',{
            value:e.reroutePercentage===0?'0':e.reroutePercentage,
          }))
        })
      }else if( this.appServiceWebConfig?.properties?.routingRules.length!=this.appServiceSlotList.length){
        this.appServiceWebConfig?.properties?.routingRules.forEach(e=>{
          trafficPercent=trafficPercent+e.reroutePercentage
          this.trafficArrayList.push(this.util.getForm('addtrafficArray',{
            value:e.reroutePercentage===0?'0':e.reroutePercentage,
          }))
        })
        this.trafficArrayList.push(this.util.getForm('addtrafficArray',{
          value:'0',
        }))
      }
    //   else{
    //     this.appServiceSlotList.forEach(e=>{
    //       this.trafficArrayList.push(this.util.getForm('addtrafficArray',{
    //         value:'0',
    //       }))
    //   })
    // }
      this.currentForm.get('defaultTraffic').setValue(100-trafficPercent)
      this.trafficArrayList.controls.splice(0,1)
     break;
   }
   case'generalSetting':{
    let data=this.appServiceData;let config=this.appServiceWebConfig
    if(config.properties.linuxFxVersion!=''){
      let split=config.properties.linuxFxVersion.split('|')
      let typeIndex=this.stackTypeList.findIndex(e=>e.properties.displayText.toLowerCase()==split[0].toLowerCase())
      this.majorVersionList=this.stackTypeList[typeIndex].properties.majorVersions
      // let splitVer=split[1].includes('-')?split[1].split('-'):split[1].split('.')
      this.majorVersionList.forEach(e=>{
          e.minorVersions.forEach(x=>{
            if(x.value==split[1]){
              this.minorVersionList=e.minorVersions
              this.currentForm.get('stackType').setValue(this.titleCase.transform(split[0]))
              this.currentForm.get('majorVersion').setValue(e.displayText)
              this.currentForm.get('minorVersion').setValue(split[1])
            }
          })
      })
      // let majorVer=this.majorVersionList.filter(e=>e.value.toLowerCase()==splitVer[0])
      // this.minorVersionList=majorVer[0].minorVersions
      //  this.currentForm.get('stackType').setValue(this.titleCase.transform(split[0]))
      //  this.currentForm.get('majorVersion').setValue(majorVer[0].displayText)
      //  this.currentForm.get('minorVersion').setValue(split[1])
    }else{
      this.currentForm.get('stackType').setValue('')
      this.currentForm.get('majorVersion').setValue('')
      this.currentForm.get('minorVersion').setValue('')
    }
     this.currentForm.get('startupCommand').setValue(config.properties.appCommandLine)
     this.currentForm.get('ftpState').setValue(config.properties.ftpsState)
     this.currentForm.get('httpVersion').setValue(config.properties.http20Enabled)
     this.currentForm.get('webSocket').setValue(config.properties.webSocketsEnabled)
     this.currentForm.get('alwaysOn').setValue(config.properties.alwaysOn)
     this.currentForm.get('arrAffinity').setValue(data.properties.clientAffinityEnabled)
     this.currentForm.get('httpsOnly').setValue(data.properties.httpsOnly)
     this.currentForm.get('tlsVersion').setValue(config.properties.minTlsVersion)
     this.currentForm.get('remoteDebug').setValue(config.properties.remoteDebuggingEnabled)
     this.currentForm.get('remoteDebug').disable()
     if(!data.properties.clientCertEnabled){
      this.currentForm.get('clientDebug').setValue('Ignored')
     }else{
      this.currentForm.get('clientDebug').setValue(data.properties.clientCertMode)
     }
  
     this.currentForm.get('exclusionPath').setValue(data.properties.clientCertExclusionPaths)
   break;
 }
   case 'scaleOut':{
    this.currentForm.get('scale').setValue('manual')
    this.currentForm.get('overRideInstanceCount').setValue(this.appServicePlanData?.sku?.capacity)
    this.currentForm.get('mode').setValue('Disabled')
    this.currentForm.get('rg').setValue(this.selectedRow[1])
    this.currentForm.get('defInstanceMin').setValue(2)
    this.currentForm.get('defInstanceMax').setValue(2)
    this.currentForm.get('defInstanceDef').setValue(2)
    this.currentForm.get('defScaleMode').value=true
    if(this.autoScaleData.length){
      this.disableAutoScaleName=true
      this.autoScaleData[0].properties?.enabled ?   this.currentForm.get('scale').setValue('custom') : this.currentForm.get('scale').setValue('manual')
      this.currentForm.get('autoscaleName').setValue(this.autoScaleData[0].name)
      this.currentForm.get('defScaleConditionName').setValue(this.autoScaleData[0].properties.profiles[0].name)
      if(this.autoScaleData[0].properties.profiles[0].capacity.minimum==this.autoScaleData[0].properties.profiles[0].capacity.maximum){
        this.currentForm.get('defScaleMode').setValue(false)
        this.currentForm.get('defScaleInstanceCount').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
        this.currentForm.get('defInstanceMin').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
        this.currentForm.get('defInstanceMax').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
        this.currentForm.get('defInstanceDef').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
      } else{
        this.currentForm.get('defScaleMode').setValue(true)
        this.currentForm.get('defInstanceMin').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
        this.currentForm.get('defInstanceMax').setValue(this.autoScaleData[0].properties.profiles[0].capacity.maximum)
        this.currentForm.get('defInstanceDef').setValue(this.autoScaleData[0].properties.profiles[0].capacity.default)
      }
      if(this.autoScaleData[0].properties.profiles.length>1){
        let scaleConditionArr=this.autoScaleData[0].properties.profiles.filter(e=>e.fixedDate)
        this.scaleCondition=true
        scaleConditionArr.forEach(e=>{
          let startTimeFormat=this.a3sDateTimeFormat.transform(e.fixedDate.start)
          startTimeFormat=startTimeFormat.split(', ')
          let endTimeFormat=this.a3sDateTimeFormat.transform(e.fixedDate.end)
          endTimeFormat=endTimeFormat.split(', ')
          let scaleMode=e.capacity.minimum==e.capacity.maximum?false:true
          this.scaleConditionArrayList.insert(0,this.util.getForm('addScaleConditonArray',{
            scaleConditionName:e.name,
            scaleMode:scaleMode,
            instanceMin:e.capacity.minimum!=e.capacity.maximum?e.capacity.minimum:null,
            instanceMax:e.capacity.minimum!=e.capacity.maximum?e.capacity.maximum:null,
            instanceDef:e.capacity.minimum!=e.capacity.maximum?e.capacity.default:null,
            scaleInstanceCount:e.capacity.minimum==e.capacity.maximum?e.capacity.minimum:null,
            schedule:true,
            timeZone:e.fixedDate.timeZone,
            startDate:e.fixedDate.start,
            startTime:startTimeFormat[1],
            endDate:e.fixedDate.end,
            endTime:endTimeFormat[1],
          }))
        })
        this.scaleConditionArrayList.controls.splice(this.scaleConditionArrayList.controls.length-1,1)
      } else this.scaleCondition=false

    }
    this.defaultScaleCondition=true 
    break;
  }
  }
  }
  addSlotSideSheet(){
    this.showSubSidesheet=true
    this.subSideSheetForm=this.formBuilder.group({
      slotName : [null],
      cloneSetting : ['noClone'],
    })
  }
  addAppSetting(){
    this.showSubSidesheet=true
    this.isEdit=false
    this.disabledSave=true
    this.currentSubSideSheet='appSetting'
    this.initializeAppSettingform()
  }
  initializeAppSettingform(){
    this.subSideSheetForm=this.formBuilder.group({
      name : [null],
      value : [null],
      deploySetting : [null],
    })
  }
  addConnSetting(){
    this.showSubSidesheet=true
    this.isEdit=false
    this.disabledSave=true
    this.currentSubSideSheet='connSetting'
    this.initializeConnSettingform()
  }
  initializeConnSettingform(){
    this.subSideSheetForm=this.formBuilder.group({
      name : [null],
      value : [null],
      connType : ['Mysql'],
      deploySetting : [null],
    })
  }
  editAppSetting(data,index){
    console.log('Current app setting data',data)
      this.isEdit=true
      this.initializeAppSettingform()
      this.currentAppSetting=data
      // let check= Object.keys(data.properties)
      let tempFilter=this.deploySlotsConfig.properties.appSettingNames.filter(e=>e==data?.name)
      this.subSideSheetForm.get('name').setValue(data?.name)
      this.subSideSheetForm.get('value').setValue(data?.value)
      this.subSideSheetForm.get('deploySetting').setValue(tempFilter.length?true:false)
      this.showSubSidesheet=true
      this.disabledSave=true
      this.currentSubSideSheet='appSetting'
      this.resetValidation()
   }
   editConnSetting(data){
    console.log('Current app setting data',data)
      this.isEdit=true
      this.initializeConnSettingform()
      this.currentConnString=data
      // let check= Object.keys(data.properties)
      let tempFilter=this.deploySlotsConfig.properties.connectionStringNames.filter(e=>e==data?.name)
      this.subSideSheetForm.get('name').setValue(data?.name)
      this.subSideSheetForm.get('value').setValue(data?.value)
      this.subSideSheetForm.get('connType').setValue(data?.type)
      this.subSideSheetForm.get('deploySetting').setValue(tempFilter.length?true:false)
      this.showSubSidesheet=true
      this.disabledSave=true
      this.currentSubSideSheet='connSetting'
      this.resetValidation()
   }
   
  removeAppSetting(list,index){
    delete this.appSettingBody.properties[list?.name]
    this.appSettingList.splice(index,1)
    let tempFilter=this.deploySlotsConfig.properties.appSettingNames.findIndex(e=>e==list?.name)
    this.deploySlotsConfig.properties.appSettingNames.splice(tempFilter,1)
    // this.updateConfigSetting(this.selectedRow,this.appSettingBody,'appsettings')
    this.saveDisable=true
  }
  
  removeConnSetting(list,index){
    delete this.connSettingBody.properties[list?.name]
    this.connSettingList.splice(index,1)
    let tempFilter=this.deploySlotsConfig.properties.connectionStringNames.findIndex(e=>e==list?.name)
    this.deploySlotsConfig.properties.connectionStringNames.splice(tempFilter,1)
    // this.updateConfigSetting(this.selectedRow,this.connSettingBody,'connectionstrings')
    // let tempFilter=this.deploySlotsConfig.properties.connectionStringNames.filter(e=>e==list?.name)
    this.saveDisable=true
  }
  async changeTab(name){
    if(name=='generalSetting'){
      this.currentForm = this.util.getForm('appServiceGeneralSetting')
      await this.setDefaultValue('generalSetting')
    }
    this.resetValidation()
    this.currentTab=name
    // this.resetForm()
    this.saveDisable=false
  }
  selectedStackType(event){
    let selectedType= event.properties.displayText
    this.saveDisable=true
    this.majorVersionList=this.stackTypeList.filter(e=>e.properties.displayText==selectedType)[0].properties.majorVersions
    this.currentForm.get('majorVersion').setValue(this.majorVersionList[0].displayText)
    this.minorVersionList=this.majorVersionList[0].minorVersions
    this.currentForm.get('minorVersion').setValue(this.minorVersionList[0].value)
    
  }
  selectedMajorVersion(event){
    let selectedType= event.displayText
    this.saveDisable=true
    this.minorVersionList=this.majorVersionList.filter(e=>e.displayText==selectedType)[0].minorVersions
    this.currentForm.get('minorVersion').setValue(this.minorVersionList[0].value)
  }
  setTime(event,type,index){
    this.saveDisable=true
    if(type=='start'){
      let startTimeFormat=this.a3sDateTimeFormat.transform(event.value)
      startTimeFormat=startTimeFormat.split(', ')
      this.scaleConditionArrayList.controls[index].get('startTime').setValue(startTimeFormat[1])
    }else{
      let endTimeFormat=this.a3sDateTimeFormat.transform(event.value)
      endTimeFormat=endTimeFormat.split(', ')
      this.scaleConditionArrayList.controls[index].get('endTime').setValue(endTimeFormat[1])
    }
  }
  setTier(list,i){
    this.saveDisable=true
    this.appServicePlanSKUList.map(e=>e['active']=false)
    this.additionalTierlist.map(e=>e['active']=false)
    list.active=true
    this.currentForm.get('skuName').setValue(list?.sku?.name)
    this.currentForm.get('skuTier').setValue(list?.sku?.tier)
  }
  async saveAppServiceData(type){
    switch(type){
      case 'deployementSlot':{
        let rawValue=this.currentForm.getRawValue()
        this.appServiceWebConfig.properties.routingRules=[]
        this.appServiceSlotList.forEach((e,i)=>{
          let slotName=e.name.split('/')[1]
          this.appServiceWebConfig?.properties?.routingRules.push({
            "actionHostName": e?.properties?.defaultHostName, 
            "reroutePercentage":Number( rawValue.trafficArray[i].value), 
            "changeStep": null, 
            "changeIntervalInMinutes": null, 
            "minReroutePercentage": null, 
            "maxReroutePercentage": null, 
            "changeDecisionCallbackUrl": null, 
            "name": slotName 
          })
        })
        this.appServiceWebConfig.properties.experiments.rampUpRules=this.appServiceWebConfig?.properties?.routingRules
        this.updateAppServiceConfigData(this.selectedRow,this.appServiceWebConfig,'deployementSlot')
        this.saveDisable=false
        break;
      }
      case 'addSlot':{
        let rawValue=this.subSideSheetForm.getRawValue(),body;
        if(rawValue.cloneSetting=='noClone'){
          body={
            "location": this.selectedRow[3], 
            "kind": "app,linux", 
            "properties": { 
                "serverFarmId": this.appServiceData.properties.serverFarmId, 
                "siteConfig": {} 
            } 
          }
        }else{
          body={
            "location": this.selectedRow[3], 
            "kind": "app,linux", 
            "properties": { 
                "serverFarmId": this.appServiceData.properties.serverFarmId, 
                "httpsOnly": false, 
                "clientCertEnabled": false, 
                "clientCertMode": "Required", 
                "clientCertExclusionPaths": null 
            } 
          }
        }
        let deployBody={
          "actionHostName": `${this.appServiceData.name}-${rawValue.slotName }.azurewebsites.net`, 
          "reroutePercentage":0, 
          "changeStep": null, 
          "changeIntervalInMinutes": null, 
          "minReroutePercentage": null, 
          "maxReroutePercentage": null, 
          "changeDecisionCallbackUrl": null, 
          "name": rawValue.slotName 
        }
        this.appServiceWebConfig?.properties?.routingRules.push(deployBody)
        this.appServiceWebConfig.properties.experiments.rampUpRules.push(deployBody)
        this.trafficArrayList.push(this.util.getForm('addtrafficArray',{
          value:'0',
        }))
        this.updateAppServiceSlotData(this.selectedRow,body,'addSlot')
        this.updateAppServiceConfigData(this.selectedRow,this.appServiceWebConfig)
        break;
      }
      case'addAppSetting':{
        let rawValue = this.subSideSheetForm.getRawValue()
        let settingName= rawValue.name
        if(this.isEdit){
          let index = this.appSettingList.findIndex(e=>e.name==this.currentAppSetting.name)
          if(rawValue?.deploySetting){
            let index= this.deploySlotsConfig.properties.connectionStringNames.findIndex(e=>e==settingName)
            index>-1?false:this.deploySlotsConfig.properties.appSettingNames.push(settingName)
            // this.updateDeploySlotsConfig(this.selectedRow,this.deploySlotsConfig)
          }else{
            let index= this.deploySlotsConfig.properties.appSettingNames.findIndex(e=>e==settingName)
            this.deploySlotsConfig.properties.appSettingNames.splice(index,1)
            // this.updateDeploySlotsConfig(this.selectedRow,this.deploySlotsConfig)
          }
          delete this.appSettingBody.properties[this.currentAppSetting.name]
          this.appSettingBody.properties[settingName]=rawValue.value
          this.appSettingList[index].name=settingName
          this.appSettingList[index].value=rawValue?.value
          this.appSettingList[index].deploySetting=rawValue?.deploySetting?'Yes':'No'
          // this.updateConfigSetting(this.selectedRow,this.appSettingBody,'appsettings')
        }else{
          if(rawValue?.deploySetting){
            this.deploySlotsConfig.properties.appSettingNames.push(settingName)
            // this.updateDeploySlotsConfig(this.selectedRow,this.deploySlotsConfig)
          }
          this.appSettingBody.properties[settingName]=rawValue.value
          // this.updateConfigSetting(this.selectedRow,this.appSettingBody,'appsettings')
          // this.appSettingList.length?false:this.appSettingList=[]
          this.appSettingList.push({
            name:settingName,
            value:rawValue.value,
            deploySetting:rawValue?.deploySetting?'Yes':'No'
          })
        }
        this.showSubSidesheet=false 
        this.saveDisable=true
        break;
      }
      case'addConnSetting':{
        let rawValue = this.subSideSheetForm.getRawValue()
        let connName= rawValue.name
        if(this.isEdit){
          let index = this.connSettingList.findIndex(e=>e.name==this.currentConnString.name)
          if(rawValue?.deploySetting){
            let index= this.deploySlotsConfig.properties.connectionStringNames.findIndex(e=>e==connName)
            index>-1?false:this.deploySlotsConfig.properties.connectionStringNames.push(connName)
            // this.updateDeploySlotsConfig(this.selectedRow,this.deploySlotsConfig)
          }else{
            let index= this.deploySlotsConfig.properties.connectionStringNames.findIndex(e=>e==connName)
            this.deploySlotsConfig.properties.connectionStringNames.splice(index,1)
            // this.updateDeploySlotsConfig(this.selectedRow,this.deploySlotsConfig)
          }
          delete this.connSettingBody.properties[this.currentConnString.name]
          this.connSettingBody.properties[connName]={ 
            "value": rawValue?.value, 
            "type": rawValue?.connType
          }
          this.connSettingList[index].name=connName
          this.connSettingList[index].value=rawValue?.value
          this.connSettingList[index].type=rawValue?.connType
          this.connSettingList[index].deploySetting=rawValue?.deploySetting?'Yes':'No'
          // this.updateConfigSetting(this.selectedRow,this.connSettingBody,'connectionstrings')
        }else{
          if(rawValue?.deploySetting){
            this.deploySlotsConfig.properties.connectionStringNames.push(connName)
          }
        this.connSettingBody.properties[connName]={ 
          "value": rawValue?.value, 
          "type": rawValue?.connType
        } 
        // this.updateConfigSetting(this.selectedRow,this.connSettingBody,'connectionstrings')
        this.connSettingList.push({
          name:connName,
          value:rawValue.value,
          type: rawValue?.connType,
          deploySetting:rawValue?.deploySetting?'Yes':'No'
        })
        }
        this.showSubSidesheet=false 
        this.saveDisable=true
        break;
      }

      case 'configuration':{
        if(this.currentTab=='generalSetting'){
          let rawValue=this.currentForm.getRawValue()
          // this.appServiceWebConfig['properties']['linuxFxVersion']=rawValue.stackType.toUpperCase()+'|'+rawValue.minorVersion
            this.appServiceWebConfig['properties']['appCommandLine']=rawValue.startupCommand
            this.appServiceWebConfig['properties']['ftpsState']=rawValue.ftpState
            this.appServiceWebConfig['properties']['http20Enabled']=rawValue.httpVersion
            this.appServiceWebConfig['properties']['webSocketsEnabled']=rawValue.webSocket
            this.appServiceWebConfig['properties']['alwaysOn']=rawValue.alwaysOn
            this.appServiceData['properties']['clientAffinityEnabled']=rawValue.arrAffinity //
            this.appServiceData['properties']['httpsOnly']=rawValue.httpsOnly  //
            this.appServiceWebConfig['properties']['minTlsVersion']=rawValue.tlsVersion
            this.appServiceWebConfig['properties']['remoteDebuggingEnabled']=rawValue.remoteDebug
            if(rawValue.clientDebug=='Ignored'){ //
              this.appServiceWebConfig['properties']['clientCertEnabled']=false
              this.appServiceData['properties']['clientCertEnabled']=false
            }else{
              this.appServiceWebConfig['properties']['clientCertEnabled']=true
              this.appServiceWebConfig['properties']['clientCertMode']=rawValue.clientDebug
              this.appServiceData['properties']['clientCertEnabled']=true
              this.appServiceData['properties']['clientCertMode']=rawValue.clientDebug
            
            }
            this.appServiceData['properties']['clientCertExclusionPaths']=rawValue.exclusionPath //
            console.log('Updated general setting',this.appServiceData)
            console.log('Updated general setting Config',this.appServiceWebConfig)
            this.updateAppServiceData(this.selectedRow,this.appServiceData,)
            this.updateAppServiceConfigData(this.selectedRow,this.appServiceWebConfig,'configuration')
        }else{
          this.updateConfigSetting(this.selectedRow,this.appSettingBody,'appsettings')
          this.updateConfigSetting(this.selectedRow,this.connSettingBody,'connectionstrings')
          this.updateDeploySlotsConfig(this.selectedRow,this.deploySlotsConfig)
        }
        break;
      }

      case'scaleUp':{
        let body=this.appServicePlanData
        let rawValue = this.currentForm.getRawValue()
            body.sku.name=rawValue.skuName
            body.sku.tier=rawValue.skuTier
            this.updateAppServicePlanData(this.selectedRow,body,'scaleUp')
        this.showSidesheet=false
        this.saveDisable=false 
        break;
      }

      case'scaleOut':{
        let body=this.appServicePlanData
        let rawValue = this.currentForm.getRawValue()
          if(rawValue?.scale=='manual'){
            body.sku.capacity=rawValue.overRideInstanceCount
            this.updateAppServicePlanData(this.selectedRow,body,'scaleOut')
            if(this.autoScaleData.length){
              this.autoScaleData[0].properties.enabled=false
              this.updateAutoScalingData(this.selectedRow,this.autoScaleData[0],'scaling')
             }
            }else{
              let autoScaleBody={
                "location": this.selectedRow[3], 
                "tags": {}, 
                "properties": { 
                    "name": rawValue?.autoscaleName, 
                    "enabled": true, 
                    "predictiveAutoscalePolicy": { 
                        "scaleMode": "Disabled", 
                        "scaleLookAheadTime": null 
                    }, 
                    "targetResourceUri": body.id,  
                    "profiles":[] ,      
                    "notifications": [] 
                } 
              }
              if(this.defaultScaleCondition){
                autoScaleBody.properties["profiles"].push({ 
                    "name": rawValue?.defScaleConditionName, 
                    "capacity": { 
                        "minimum": rawValue?.defScaleMode? rawValue?.defInstanceMin : rawValue?.defScaleInstanceCount, 
                        "maximum": rawValue?.defScaleMode? rawValue?.defInstanceMax : rawValue?.defScaleInstanceCount, 
                        "default": rawValue?.defScaleMode? rawValue?.defInstanceDef : rawValue?.defScaleInstanceCount 
                    }, 
                    "rules": [] 
                })
              }
              if(this.scaleCondition){
                rawValue.scaleConditonArray.forEach(e=>{
                  autoScaleBody.properties["profiles"].push({ 
                    "name":e?.scaleConditionName, 
                    "capacity": { 
                        "minimum": e?.scaleMode? e?.instanceMin : e?.scaleInstanceCount, 
                        "maximum": e?.scaleMode? e?.instanceMax : e?.scaleInstanceCount,  
                        "default": e?.scaleMode? e?.instanceDef : e?.scaleInstanceCount, 
                    }, 
                    "rules": [], 
                    "fixedDate": { 
                        "timeZone": "UTC", 
                        "start": e?.startDate, 
                        "end": e?.endDate
                    } 
                })
                })
              }
              this.updateAutoScalingData(this.selectedRow,autoScaleBody,'scaleOut')
            }
     
        this.showSidesheet=false
        this.saveDisable=false 
        break;
      }

    }
  
  }


//validation
portRangeValidation(event,limit,type?:any){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError['InvalidPortRange']=false; 
  this.inputError['NotValidPortVal']=false;
  if(value&&value!=""){
    if((!(/^[0-9]*$/).test(value))){
      this.inputError['NotValidPortVal']=true;
    }
    if(type=='instanceCount'){
      if(value>1000 || value<1){
        this.inputError['InvalidPortRange']=true;
      }
    }
    else{
      if(value>65535 || value<0){
        this.inputError['InvalidPortRange']=true;
      }
    }
  }
}
validAutoscaleName(event){
  let value=event.target.value;
  this.saveDisable=true
  let regex=/\,|\%|\&|\>|\<|\#|\.|\?|\/|\\|\s/;
  if(regex.test(value)){
    this.inputError['validScaleName']=true;
  }else this.inputError['validScaleName']=false;
}
instanceLimitValidation(event,type,index){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError[type+'ValidInstance']=false; 
  this.inputError['validMinInstance']=false;
  this.inputError['validMaxInstance']=false;
  this.inputError['validMaximumInstance']=false;
  this.inputError['validDefInstance']=false;
  this.scaleConditionArrayList.controls.forEach((e,i)=>{
        if(i==index&&value&&value!=""){
            if((!(/^[0-9]*$/).test(value))){
              this.inputError[type+'ValidInstance']=true;
            }
            if(type=='min'){
              if(value>1000 || value<1){
                this.inputError['validMinInstance']=true;
              }
              this.inputError['validMaxInstance']=Number(this.currentForm.get('instanceMax').value)<value?true:false
            }else if(type=='max'){
              this.inputError['validMaxInstance']=Number(this.currentForm.get('instanceMin').value)>value?true:false
              this.inputError['validMaximumInstance'] = value>1000?true:false
            }else if(type=='def'){
              if(Number(this.currentForm.get('instanceMax').value)>value||Number(this.currentForm.get('instanceMin').value<value)){
                this.inputError['validDefInstance']=true;
              }
            }
        }
  })
 
}
instanceLimitDefaultValidation(event,type){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError[type+'ValidInstance']=false; 
  this.inputError['defValidMinInstance']=false;
  this.inputError['defValidMaxInstance']=false;
  this.inputError['defValidMaximumInstance']=false;
  this.inputError['defValidDefInstance']=false;
  if(value&&value!=""){
    if((!(/^[0-9]*$/).test(value))){
      this.inputError[type+'ValidInstance']=true;
    }
    if(type=='defMin'){
      if(value>1000 || value<1){
        this.inputError['defValidMinInstance']=true;
      }
      this.inputError['defValidMaxInstance']=Number(this.currentForm.get('defInstanceMax').value)<value?true:false
    }else if(type=='defMax'){
      this.inputError['defValidMaxInstance']=Number(this.currentForm.get('defInstanceMin').value)>value?true:false
      this.inputError['defValidMaximumInstance'] = value>1000?true:false
    }else if(type=='defaultDef'){
      if(Number(this.currentForm.get('defInstanceMax').value)>value||Number(this.currentForm.get('defInstanceMin').value<value)){
        this.inputError['defValidDefInstance']=true;
      }
    }
  }
}
validInstanceCount(event,type){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError[type+'validNumber']=false; 
  this.inputError[type+'validCount']=false;
  if(value&&value!=""){
    if((!(/^[0-9]*$/).test(value))){
      this.inputError[type+'validNumber']=true;
    }
    if(value>1000 || value<1){
      this.inputError[type+'validCount']=true;
    }
  }
}
validSlotName(event){
  let value=event.target.value;
  this.inputError['InvalidMinCharc']=false; 
  this.inputError['InvalidMaxCharc']=false;
  this.inputError['InvalidCharc']=false;
  this.inputError['NameDupCheck'] = false;
  if(value&&value!=""){
    if(value.length<2){
      this.inputError['InvalidMinCharc']=true;
    }
    else if(value.length>48){
      this.inputError['InvalidMinCharc']=true;
    }
    if((/[._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/).test(value)){
      this.inputError['InvalidCharc']=true; 
    }
    this.appServiceSlotList.forEach(e=>{
      if(e?.name?.split('/')[1]==value){
        this.inputError['NameDupCheck'] = true;
        return;
      }
    })
  }
}
validTrafficValue(event,index){
  let value=Number(event.target.value);
  this.saveDisable=true
  this.inputError['trafficLimit']=false;
  let trafficpercent=0
  this.trafficArrayList.controls.forEach(e=>{
    trafficpercent=Number(trafficpercent + Number(e.value.value))
  })
 this.currentForm.get('defaultTraffic').setValue(100-trafficpercent)
 let defTraf=  this.currentForm.get('defaultTraffic').value
  if(defTraf<0){
    this.inputError['trafficLimit']=true;
    this.currentForm.get('defaultTraffic').setValue('Error')
  }else{
    this.currentForm.get('defaultTraffic').setValue(defTraf)
  }
}
validSettingName(event,type){
  let value=event.target.value;
  this.disabledSave=false
  this.inputError['InvalidCharc']=false;
  this.inputError['NameDupCheck'] = false;
  if(value&&value!=""){
    if(!(/[a-zA-Z0-9]/).test(value)){
      this.inputError['InvalidCharc']=true; 
    }
    if((/[-!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/).test(value)){
      this.inputError['InvalidCharc']=true; 
    }
  }
  switch(type){
    case 'appSetting':{
      this.appSettingList.forEach(e=>{
        if(e.name==value){
          this.inputError['NameDupCheck'] = true;
          return;
        }
      })
    }
    case 'connString':{
      this.connSettingList.forEach(e=>{
        if(e.name==value){
          this.inputError['NameDupCheck'] = true;
          return;
        }
    })
  }
}
}

  //API's
   getAppServiceData(data,type,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appServiceName = data[0]
    return this.util.handleRequest('get','a3s_management_getAppServiceData',[subsId,rgName,appServiceName],null,null,null,true).then(async res=>{
      this.appServiceData = res.body
      switch(type){
        case'disableCondition':{
         this.disableStart=this.appServiceData?.properties?.state=='Running' || this.appServiceData?.properties?.state=='Starting'?true:false
        }
      }
      console.log('App Service Data',this.appServiceData)
    })
  }

  updateAppServiceData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let appServiceName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateAppServiceData',[subsId,rgName,appServiceName],body,null,null,true).then(res=>{
      console.log('App Service Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  deleteAppServiceData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],appServiceName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteAppServiceData',[subscriptionId,resourceGroupName,appServiceName],null,null,null,true).then(async res=>{
      this.closeModal()
        let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
        this.data.splice(index,1)
      this.util.success(data[0] + ' deleted successfully!')
    },err=>{
      this.util.error(data[0] +' failed to deleted.')
    })
  }
  getAppServiceConfigData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appServiceName = data[0]
    return this.util.handleRequest('get','a3s_management_getAppServiceConfigData',[subsId,rgName,appServiceName],null,null,null,true).then(async res=>{
      this.appServiceWebConfig= res.body
      console.log('App Service Configuration',this.appServiceWebConfig)
    })
  }
  updateAppServiceConfigData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let appServiceName = data[0]
    let configName = 'web'
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateAppServiceConfig',[subsId,rgName,appServiceName,configName],body,null,null,true).then(async res=>{
      console.log('App Service config Data updated',res)
      // this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  getAppServiceSlotList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appServiceName = data[0]
    return this.util.handleRequest('get','a3s_management_getAppServiceSlotList',[subsId,rgName,appServiceName],null,null,null,true).then(async res=>{
      this.appServiceSlotList= res.body.value
      this.cloneSettingList=[{name:'Do not clone settings',id:'noClone'}],
      this.cloneSettingList.push({name:this.selectedRow[0],id:this.selectedRow[0]})
      this.appServiceSlotList.forEach(e=>{
        let slotName=e.name.replace('/','-')
        this.cloneSettingList.push({name:slotName,id:slotName})
      })
      // this.deploySoltList=[]
      console.log('App Service Configuration',this.appServiceSlotList)
    })
  }

  updateAppServiceSlotData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let appServiceName = data[0]
    let slotName = this.subSideSheetForm.get('slotName').value
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateAppServiceSlot',[subsId,rgName,appServiceName,slotName],body,null,null,true).then(async res=>{
      console.log('App Service added slot',res)
      switch(type){
        case'addSlot':{
          await this.getAppServiceSlotList(this.selectedRow)
        }
      }
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  getDeploySlotsConfig(data,type?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appServiceName = data[0]
    return this.util.handleRequest('get','a3s_management_getAppServiceConfig',[subsId,rgName,appServiceName,type],null,null,null,true).then(async res=>{
      this.deploySlotsConfig= res.body
      console.log('Connection String list',this.deploySlotsConfig)
    })
  }
  updateDeploySlotsConfig(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let appServiceName = data[0]
    let slotName = 'slotconfignames'
    this.util.info(this.getInProgress('configuration'))
    return this.util.handleRequest('post','a3s_management_updateAppServiceConfig',[subsId,rgName,appServiceName,slotName],body,null,null,true).then(async res=>{
      console.log('Deploy slot configuration updated',res)
      this.util.success(this.getSuccesText('configuration'))
    },err=>{
      console.log('Deploy slot configuration failed',err)
      this.util.error(this.getFailureText('configuration'))
    })
  }

  updateConfigSetting(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let appServiceName = data[0]
    let configType = type
    // this.util.info(this.getInProgress('configSetting'))
    return this.util.handleRequest('post','a3s_management_updateAppServiceConfigType',[subsId,rgName,appServiceName,configType],body,null,null,true).then(async res=>{
      this.showSubSidesheet = false
      this.closeModal()
      // this.util.success(this.getSuccesText('configSetting'))
    },err=>{
      // this.util.error(this.getFailureText('configSetting'))
    })
  }

  getStackList(){
    return this.util.handleRequest('get','a3s_management_getAppServiceStackList',null,null,null,null,true).then(async res=>{
      this.stackTypeList= res.body.value
      this.stackTypeList=this.stackTypeList.filter(e=>e.properties.displayText!='Java' || e.properties.displayText!='Java Containers'  )
      console.log('Stack type list',this.stackTypeList)
    })
  }
  
  getAppSettingsList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appServiceName = data[0]
    return this.util.handleRequest('post','a3s_management_getAppSettingsList',[subsId,rgName,appServiceName],null,null,null,true).then( res=>{
      let appSetting= res
      this.appSettingBody= res
      let nameArr:any=Object.keys(appSetting.properties)
      this.appSettingList=[]
      nameArr.forEach((e,i)=>{
        let tempFilter=this.deploySlotsConfig.properties.appSettingNames.filter(x=>x==e)
        this.appSettingList.push({
          name:e,
          value:appSetting.properties[e],
          deploySetting:tempFilter.length?'Yes':'No'
        })
      })
      console.log('App settings list',nameArr)
    },err=>{
      console.log('App settings list',err)
    })
  }
  getConnStringList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appServiceName = data[0]
    return this.util.handleRequest('post','a3s_management_getConnStringList',[subsId,rgName,appServiceName],null,null,null,true).then( res=>{
      let connSetting= res
      this.connSettingBody= res
      let nameArr:any=Object.keys(connSetting.properties)
      this.connSettingList=[]
      nameArr.forEach((e,i)=>{
        let tempFilter=this.deploySlotsConfig.properties.connectionStringNames.filter(x=>x==e)
        this.connSettingList.push({
          name:e,
          value:connSetting.properties[e].value,
          type:connSetting.properties[e].type,
          deploySetting:tempFilter.length?'Yes':'No'
        })
      })
      console.log('Connection String list',connSetting)
    },err=>{
      console.log('Connection String list failed',err)
    })
  }
  getAppServicePlanSKUList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appServiceName = data[7]
    return this.util.handleRequest('get','a3s_management_getAppServicePlanSKUList',[subsId,rgName,appServiceName],null,null,null,true).then(async res=>{
      this.appServicePlanAllSKUList= res.body.value
      this.appServicePlanAllSKUList.map(e=>e['active']=false)
      this.appServicePlanAllSKUList.map(e=>{
        if(this.appServicePlanData.sku.name==e.sku.name){
                e['active']=true
        }
      })
      this.appServicePlanSKUList=this.appServicePlanAllSKUList.filter(dt=>dt.sku.tier=='PremiumV3' || dt.sku.tier=='PremiumV2')
      this.additionalTierlist=this.appServicePlanAllSKUList.filter(dt=>dt.sku.tier=='Standard' || dt.sku.tier=='Premium')
      console.log('App Service Configuration',this.appServicePlanAllSKUList)
    })
  }
  getAppServicePlanData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appServiceName = data[7]
    return this.util.handleRequest('get','a3s_management_getAppServicePlanData',[subsId,rgName,appServiceName],null,null,null,true).then(async res=>{
      this.appServicePlanData= res.body
      console.log('App Service Configuration',this.appServicePlanData)
    })
  }
  updateAppServicePlanData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let appServiceName = data[7]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateAppServicePlanData',[subsId,rgName,appServiceName],body,null,null,true).then(res=>{
      console.log('App Service config Data updated',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  getAutoScalingData(data,type?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vmScaleSetName = data[0]
    return this.util.handleRequest('get','a3s_management_getAutoScalingData',[subsId,rgName,vmScaleSetName],null,null,null,true).then(res=>{
      let autoscaleList = res.body.value
      this.autoScaleData=autoscaleList.filter(e=>e.properties.targetResourceUri.toLowerCase()==this.appServicePlanData.id.toLowerCase())
      this.disableAutoScaleName=false
      console.log('autoscale List',autoscaleList)
    })
  }
  updateAutoScalingData(data,body,type?:any){
    let subsId = data[2]
    let rgName = data[1]
    let autoScaleName = this.currentForm.get('autoscaleName').value
    this.util.info(this.getInProgress(type))
    return this.util.handleRequest('post','a3s_management_updateAutoScalingData',[subsId,rgName,autoScaleName],body,null,null,true).then(res=>{
      console.log('Updated auto scale data',res)
      this.closeModal()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
}
