export class ResourceTypes {
    public static types = [
        {id:0,type:'microsoft.resources/subscriptions',name:'Subscriptions',singular:'Subscription',plural:'Subscriptions',dbName:'subscriptions'},
        {id:1,type:'microsoft.compute/virtualmachines',name:'Virtual Machines',singular:'Virtual Machine',plural:'Virtual Machines',dbName:'virtualMachines'},
        {id:2,type:'microsoft.keyvault/vaults',name:'Key Vaults',singular:'Key Vaults',plural:'Key Vaults',dbName:'keyVaults'},
        {id:3,type:'microsoft.network/networkinterfaces',name:'Network Interface',singular:'Network Interface',plural:'Network Interfaces',dbName:'networkInterfaces'},
        {id:4,type:'microsoft.sql/servers/databases',name:'SQL Databases',singular:'SQL Database',plural:'SQL Databases',dbName:'sqlDatabases'},
        {id:5,type:'microsoft.web/site',name:'Web App',singular:'Web App',plural:'Web Apps',dbName:'webApp'},
        {id:6,type:'microsoft.web/serverfarms',name:'App Service Plan',singular:'App Service Plan',plural:'App Service Plans',dbName:'appServicePlans'},
        {id:7,type:'microsoft.network/loadbalancers',name:'Load Balancers',singular:'Load Balancer',plural:'Load Balancers',dbName:'loadBalancers'},
        {id:8,type:'microsoft.compute/disks',name:'Disks',singular:'Disk',plural:'Disks',dbName:'disks'},
        {id:9,type:'microsoft.compute/availabilitysets',name:'Availability Sets',singular:'Availability Set',plural:'Availability Sets',dbName:'availabilitySets'},
        {id:10,type:'microsoft.sqlvirtualmachine/sqlvirtualmachines',name:'SQL Virtual Machines',singular:'SQL Virtual Machine',plural:'SQL Virtual Machines',dbName:'sqlVirtualMachines'},
        {id:11,type:'microsoft.desktopvirtualization/hostpools',name:'Hostpools',singular:'Hostpool',plural:'Hostpools',dbName:'hostpools'},
        {id:12,type:'microsoft.compute/virtualmachinescalesets',name:'VM Scale Sets',singular:'VM Scale Set',plural:'VM Scale Sets',dbName:'virtualMachineScaleSets'},
        {id:13,type:'microsoft.network/networksecuritygroups',name:'Network Security Groups',singular:'Network Security Group',plural:'Network Security Groups',dbName:'networkSecurityGroups'},
        {id:14,type:'microsoft.network/publicipaddresses',name:'Public IP Addresses',singular:'Public IP Address',plural:'Public IP Addresses',dbName:'publicIPAddress'},
        {id:15,type:'microsoft.network/virtualnetworks',name:'Virtual Networks',singular:'Virtual Network',plural:'Virtual Networks',dbName:'virtualNetworks'},
        {id:16,type:'microsoft.network/networkwatchers',name:'Network Watchers',singular:'Network Watcher',plural:'Network Watchers',dbName:'networkWatchers'},
        {id:17,type:'microsoft.network/dnszones',name:'DNS Zones',singular:'DNS Zone',plural:'DNS Zones',dbName:'dnsZones'},
        {id:18,type:'microsoft.network/routetables',name:'Route Tables',singular:'Route Table',plural:'Route Tables',dbName:'routeTables'},
        {id:19,type:'microsoft.network/applicationgateways',name:'Application Gateways',singular:'Application Gateway',plural:'Application Gateways',dbName:'applicationGateways'},
        {id:20,type:'microsoft.network/localnetworkgateways',name:'Local Network Gateways',singular:'Local Network Gateway',plural:'Local Network Gateways',dbName:'localNetworkGateways'},
        {id:21,type:'microsoft.network/virtualnetworkgateways',name:'Virtual Network Gateways',singular:'Virtual Network Gateway',plural:'Virtual Network Gateways',dbName:'virtualNetworkGateways'},
        {id:22,type:'microsoft.storage/storageaccounts',name:'Storage Accounts',singular:'Storage Account',plural:'Storage Accounts',dbName:'storageAccounts'},
        {id:23,type:'microsoft.datalakestore/accounts',name:'Azure Data Lake Storage Gen1',singular:'Azure Data Lake Storage Gen1',plural:'Azure Data Lake Storage Gen1',dbName:'adlsGen1'},
        {id:24,type:'microsoft.web/certificates',name:'Certificates',singular:'Certificate',plural:'Certificates',dbName:'appServiceCertificates '},
        {id:25,type:'microsoft.web/staticsites',name:'Static Web Apps',singular:'Azure Static App',plural:'Azure Static Apps',dbName:'azureStaticApps'},
        {id:26,type:'microsoft.web/hostingenvironments',name:'App Service Environments',singular:'App Service Environment',plural:'App Service Environments',dbName:'appServiceEnvironments'},
        {id:27,type:'microsoft.cdn/profiles',name:'CDN Profiles',singular:'CDN Profile',plural:'CDN Profiles',dbName:'cdnProfiles'},
        {id:28,type:'microsoft.documentdb/databaseaccounts',name:'Azure Cosmos DB',singular:'Azure Cosmos DB',plural:'Azure Cosmos Databases',dbName:'cosmosDB'},
        {id:29,type:'microsoft.sql/servers',name:'SQL Servers',singular:'SQL Server',plural:'SQL Servers',dbName:'sqlServer'},
        {id:30,type:'microsoft.datafactory/factories',name:'Data Factories',singular:'Data Factory',plural:'Data Factories',dbName:'dataFactories'},
        {id:31,type:'microsoft.sql/managedinstances',name:'SQL Managed Instances',singular:'SQL Managed Instance',plural:'SQL Managed Instances'},
        {id:32,type:'microsoft.operationalinsights/workspaces',name:'Log Analytics Workspaces',singular:'Log Analytics Workspace',plural:'Log Analytics Workspaces',dbName:'logAnalyticWorkspaces'},
        {id:33,type:'microsoft.synapse/workspaces',name:'Azure Synapse',singular:'Synapse Analytics Workspace',plural:'Synapse Analytics Workspace',dbName:'synapseAnalyticsWorkspaces'},
        {id:34,type:'microsoft.insights/components',name:'Application Insights',singular:'Application Insight',plural:'Application Insights',dbName:'applicationInsights'},
        {id:35,type:'microsoft.synapse/workspaces/bigdatapools',name:'Apache Spark Pool',singular:'Apache Spark Pool',plural:'Apache Spark Pools',dbName:'Apache Spark Pools'},
        {id:36,type:'microsoft.synapse/workspaces/sqlpools',name:'Dedicated SQL Pool',singular:'Dedicated SQL Pool',plural:'Dedicated SQL Pools',dbName:'Dedicated SQL Pools'},
        {id:37,type:'microsoft.machinelearningservices/workspaces',name:'Machine Learning Workspace',singular:'Machine Learning Workspace',plural:'Machine Learning Workspaces',dbName:'machineLearningWorkspaces'},
        {id:38,type:'microsoft.containerregistry/registries',name:'Container Registry',singular:'Container Registry',plural:'Container Registries',dbName:'Container Registries'},
        {id:39,type:'microsoft.containerinstance/containergroups',name:'Container Instances',singular:'Container Instance',plural:'Container Instances',dbName:'Container Instances'},
        {id:40,type:'microsoft.cognitiveservices/accounts',name:'Cognitive Services',singular:'Cognitive Service',plural:'Cognitive Services',dbName:'Cognitive Services'},
        {id:41,type:'microsoft.containerservice/managedclusters',name:'Azure Kubernetes Services',singular:'Azure Kubernetes Service',plural:'Azure Kubernetes Services',dbName:'Azure Kubernetes Services'},
        {id:42,type:'microsoft.automation/automationaccounts',name:'Automation Accounts',singular:'Automation Account',plural:'Automation Accounts',dbName:'automationAccounts'},
        {id:43,type:'microsoft.insights/workbooks',name:'Workbooks',singular:'Workbook',plural:'Workbooks',dbName:'workbook'},
        {id:44,type:'microsoft.network/firewallPolicies',name:'Firewall Policies',singular:'Firewall Policy',plural:'Firewall Policies',dbName:'firewallPolicy'},
        {id:45,type:'microsoft.network/bastionHosts',name:'Azure Bastions',singular:'Azure Bastion',plural:'Azure Bastions',dbName:'bastions'},
        {id:46,type:'microsoft.network/azurefirewalls',name:'Azure Firewalls',singular:'Azure Firewall',plural:'Azure Firewalls',dbName:'firewalls'},
        {id:47,type:'microsoft.network/connections',name:'Connections',singular:'Connection',plural:'Connections',dbName:'connections'},
        {id:48,type:'microsoft.web/sites',name:'App Service',singular:'App Service',plural:'App Services',dbName:'appServices'},
        {id:49,type:'microsoft.network/subnets',name:'Subnets',singular:'Subnet',plural:'Subnets',dbName:'subnets'},
        {id:50,type:'microsoft.network/expressroutecircuits',name:'ExpressRoute Gateway',singular:'ExpressRoute Gateway',plural:'ExpressRoute Gateways',dbName:'expressRouteGateways'},
    ]
}