<div class="introduction">
    <p class="heading" id="6">Knowledge mining</p>
    <p>This use case makes the user search contents from their documents attached to this service. This use case packs up with markdown parsing skillset which enables the user to better readability </p>
    
      <ol>
          <li>Click <b>Data analytics -> Create new project -> Knowledge mining.</b>  </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Knowledge-Mining(1).svg" >
          <figcaption class="caption"> Knowledge mining - use case </figcaption>
          <li>Fill in source details to connect to the data source. </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Knowledge-Mining(2).svg" >
          <figcaption class="caption"> Knowledge mining - source details </figcaption>
          <li>Select tables.  </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Knowledge-Mining(3).svg" >
          <figcaption class="caption"> Knowledge mining - select table </figcaption>
          <li>Start the search.  </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Knowledge-Mining(4).svg" >
          <figcaption class="caption">Knowledge mining – smart search </figcaption>
          <li>See result of the search query. </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Knowledge-Mining(5).svg" >
          <figcaption class="caption"> Knowledge mining - search result </figcaption>
          <li>Project summary page. </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Knowledge-Mining(6).svg" >
          <figcaption class="caption"> Knowledge mining - project summary </figcaption>
        
     
      </ol>
  </div>
