import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPv4, IPSubnetwork } from 'ip-matching';


@Component({
  selector: 'app-local-network-gateway-actions',
  templateUrl: './local-network-gateway-actions.component.html',
  styleUrls: ['./local-network-gateway-actions.component.scss']
})
export class LocalNetworkGatewayActionsComponent implements OnInit {
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("lngDetailsTemplate") lngDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  localNetworkData: any;
  connectionList: any;
  vpnList: any;
  inputError={
    ipError:false,
    ipNonNullRange:false,
    ipUnique:false,
    adrcSpcError:false,
    adrcSpcNonNullRange:false,
    adrcSpcUnique:false,
    bgpError:false,
    bgpNonNullRange:false,
    bgpUnique:false,
    asnError:false,
    asnValidNum:false,
    asnValue:false,
    nameEmpty:false,
    nameExist:false,
    nameMaxLen:false,
    nameValid:false,
    nameLastValid:false,
    sharedKeyMaxLen:false,
    sharedKeyEmpty:false,
    sharedKeyASCII:false,

  }
  selectedType: any;
  selectedSideSheetRow: any;
  number: any;
  tempForm: FormGroup;
  asnNumber: number;
  connectionName: any;
  disable: boolean;
  saveDisable: boolean;
  constructor(public util:UtilService, private titleCase:TitleCasePipe,private formBuilder: FormBuilder,private modalService: NgbModal,private validatorService : ValidatorsService) { }


  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    var $target = $(event.target);
    let popupCheck=$('#popup').is(":visible")
    let matMenuCheck=$('#matMenu').is(":visible")
    // event.target['ariaLabel'] == 'Close'
    if(this.showSidesheet&& !popupCheck&&!matMenuCheck){
   if(!$target.closest('#sideSheets').length && $('#sideSheets').is(":visible")&& event.target['innerText'] !== 'Connections'&& event.target['innerText'] !== 'Configuration'&& event.target['innerText'] !== 'Remove'&& event.target['innerText'] !== 'Save' && !$target.closest('.ng-option').length && event.target['localName'] !== 'img'){
    this.showSubSidesheet=false;
    this.showSidesheet=false
   }
  }
  }


  ngOnInit(): void {
  }
//----------------------------------------Common-----------------------------------------------------------------------------------
  async openSidesheet(type,data?:any){
    this.showSidesheet = true;
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "configuration" : {
        this.currentForm = this.util.getForm('configurationLNG')
        await this.getLNGData('LNG',this.selectedRow)
        this.setDefaultValue('config')
        // this.openModal(this.configurationTemplate,data)
        break;
      } 
      case "connection" : {
        this.currentForm = this.util.getForm('connectionLNG')
        await this.getLNGData('LNG',this.selectedRow)
        await this.getConnectionList('LNG',this.selectedRow)
        await this.getConnectionData(this.selectedRow)
        await this.getVPNList(this.selectedRow)
        break;
      }  
      case "deleteLNG" : {
        await this.getLNGData('LNG',this.selectedRow)
        this.openModal(this.deleteConfirmPopup,this.selectedRow,'deleteLNG')
        break;
      }  
    }
  }

  async action(data,type){
    console.log(type +'Data',data)
    switch(type){
   case'deleteConnection':return this.openModal(this.deleteConfirmPopup,data,'deleteConnection')
    }
  }
  async deleteAction(){
    switch(this.selectedType){
      case'deleteLNG':{
        await this.deleteLNGData(this.selectedRow)
        // await this.getLNGData('',this.selectedRow)
      }
      case 'deleteConnection' :{
        this.deleteConnectionData(this.selectedRow,this.selectedSideSheetRow.name)
        // await this.util.delay(1000)
        // this.getLNGData('',this.selectedRow)
        // let index = this.connectionList.findIndex(e=> e.name == this.selectedSideSheetRow.name)
        // this.connectionList.splice(index,1)
        // this.updateConnectionData(this.selectedRow,this.connectionList,'deleteConnection')
        break;
      }
    }
  }
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedSideSheetRow=row;
    this.selectedType=type;
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  closeSidesheet(){
    this.showSidesheet = false
  }

  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }

  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  addAddressSpace(){
    this.saveDisable=true
    this.addressSpaceList.push(this.util.getForm('addAddressSpace'))
  }
  get addressSpaceList() {
    return this.currentForm.get('addressSpaceArray') as FormArray;
  }
  removeAddressSpace(index) {
    this.saveDisable=true
    this.addressSpaceList.controls.splice(index, 1);
  }
  getCurrentResourceTitle(){
    switch(this.selectedType){
      case'deleteConnection':return 'Remove Connection'
      case'deleteLNG':return 'Delete Local Network Gateway'
      default:
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteConnection':return 'remove '+`"${this.selectedSideSheetRow.name}"`
      case'deleteLNG':return 'delete '+`"${this.selectedRow[0]}"`
      default:
    }
  }
  getButtonName(){
    switch(this.selectedType){
      case'deleteConnection':return 'Remove'
      case'deleteLNG':return 'Delete'
      default:
    }
  }
  getSuccesText(type){
    switch(type){
      case 'config' :  return `${this.selectedRow[0]} changes saved successfully!`
      case 'addConnection': return `Connection added to ${this.selectedRow[0]} successfully!`
      default: return 'Changes saved successfully!'
    }
  }

  getFailureText(type){
    switch(type){
      case 'config' :  return `Failed to save configuration to ${this.selectedRow[0]}.`
      case 'addConnection': return `Failed to add connection to ${this.selectedRow[0]}.`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'addConnection': return `Adding connection to ${this.selectedRow[0]}...`
      case'config': return `Saving configuration to ${this.selectedRow[0]}...`
      default: return 'Updating...'
      // default: return `Saving changes to ${this.data[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'localNetwork':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
      case 'name':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = a?.name;
          let y = b?.name;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.connectionList=sorted;
        break;
      }
      case 'status':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = a?.properties?.connectionStatus ? a?.properties?.connectionStatus:'Unknown';
          let y = b?.properties?.connectionStatus ? b?.properties?.connectionStatus:'Unknown';
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.connectionList=sorted;
        break;
      }
      case 'VPN':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = a?.properties?.virtualNetworkGateway1?.id.split('/')[8];
          let y = b?.properties?.virtualNetworkGateway1?.id.split('/')[8];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.connectionList=sorted;
        break;
      }
    }
  }

  CheckIPAddress(event,flag,index?:any){ 
    // this.warningMessage=true
      let value=event.target.value;
      this.number=value
      this.inputError[flag+'Error']=false;//sourceInvalidArgument
  
      if(value&&value!=""){
      const splitArComma=value.split(',');
      let ipV4Value;
      let tempArr=[];
      for(let i=0;i<splitArComma.length;i++){
        if(!splitArComma[i]){
         // let inputError="Invalid argument: 'A non-null address range is required.'";
          this.inputError[flag+'Error']=true;
          break;
        }else{
          const splitArr=splitArComma[i].split('/');
          if(splitArr.length>1&&!splitArr[1]){
            //Maliform
            this.inputError[flag+'Error']=true;
          }else{
            ipV4Value=splitArr[0];
          }
          
          try{
            let ipV4Obj=new this.ipV4(ipV4Value);
           // item.VNetAdrspc=false;
            let vlIPRange;
            if(splitArr.length>1){
            try{
              vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
              //item.VNetAdrspc=false;
              if(vlIPRange.input!=splitArComma[i]){
                //item.notValidCIDR=true;
                //item.validCIDRValue=vlIPRange.input;
                this.inputError[flag+'Error']=true;
                break;
              }
            }catch(e){
              //item.VNetAdrspc=true;
              this.inputError[flag+'Error']=true;
              break;
            }
          }        
          }catch(e){
         //   item.VNetAdrspc=true;
         this.inputError[flag+'Error']=true;
            break;
          }
        }
      }
      if(!value.includes('/') && flag=='adrcSpc'){
        this.inputError[flag+'Error']=true;
      }
    }
    
    if(value&&value!=""){
    const splitArComma=value.split(',');
    let ipV4Value;
    let tempArr=[];
    for(let i=0;i<splitArComma.length;i++){
      if(!splitArComma[i]){
       // let inputError="Invalid argument: 'A non-null address range is required.'";
        this.inputError[flag+'NonNullRange']=true;
        break;
      }
    }
  }
  if(index!=''){
    if(!this.addressSpaceList.controls.length) return;
    let data = this.addressSpaceList.controls.some((e,i)=> {
      if(index !== i && e.get('value').value == value && e.get('value').value != '' ){
        this.inputError[flag+'Unique']=true
      }
      // else{ this.inputError['dnsUnique']=false}
      else if(index !== i &&e.get('value').value != ''){
        this.inputError[flag+'Unique']=false
      }
      return index !== i && e.get('value').value == this.addressSpaceList.at(index).get('value').value
    })
  }
  // else{
  //   this.getIPAddressValidation(value)
  // }
}

getVPNName(list){
  if(list?.properties?.virtualNetworkGateway1){
    return list?.properties?.virtualNetworkGateway1?.id.split('/')[8]
  }
}
// initialiseForm(){
//   this.tempForm = this.formBuilder.group({
//     ASN:[null,[Validators.required]],
//     bgpIPAddress:[null,[Validators.required]],
//   })
//   return this.tempForm;
// }
invalidForm(type){
  switch(type){
    case'config':{
      return this.inputError.ipError||this.inputError.ipNonNullRange||this.inputError.ipUnique||this.inputError.adrcSpcError||this.inputError?.adrcSpcNonNullRange
      || this.inputError.adrcSpcUnique|| this.inputError.bgpError|| this.inputError.bgpNonNullRange|| this.inputError.asnError|| this.inputError.asnValidNum|| this.inputError.asnValue;
    }
    case'addConnection':{
      return this.inputError.nameEmpty||this.inputError.nameMaxLen||this.inputError.nameExist||this.inputError.nameValid||this.inputError?.nameLastValid
      || this.inputError.sharedKeyEmpty|| this.inputError.sharedKeyMaxLen|| this.inputError.sharedKeyASCII;
    }
  }
}
resetForm(){
  this.currentForm.reset()
}
getVPNConfig(val){
if(val.properties.sku.name=='Basic'){
  this.disable=true
  // $('#BGP').addClass('disabled-icon');
}else{
  this.disable=false
}
}
setRadioBtn(id,type){
switch(type){
  case 'protocol':
    this.saveDisable=true
    let value = id == '1' ? true : false
    this.currentForm.get('IKEProtocol').setValue(value)
    break;
}
}
setDefaultValue(type){
switch(type){
  case'config':{
    let body=this.localNetworkData
    let addressSpaceArray  = body.properties.localNetworkAddressSpace.addressPrefixes
    addressSpaceArray.forEach(e=>{
      this.addressSpaceList.insert(0,this.util.getForm('addAddressSpace',{value:e}))
    });
    if(body.properties.bgpSettings){
      $('#bgpConfig').prop('checked','checked');
      this.currentForm.get('configBGP').setValue(true)
    this.currentForm.get('ASN').setValue(body.properties?.bgpSettings?.asn)
    this.currentForm.get('bgpIPAddress').setValue(body.properties.bgpSettings?.bgpPeeringAddress)}
    this.currentForm.get('ipAddress').setValue(body.properties.gatewayIpAddress)
    this.currentForm.get('value').setValue(addressSpaceArray)
    break;
  }
  case'addConnection':{
    let val=this.connectionList[0]
      this.currentForm.get('location').setValue(this.getRegionName(this.selectedRow[3]))
      this.currentForm.get('rg').setValue(this.selectedRow[1])
      this.currentForm.get('sub').setValue(this.getProjectName(this.selectedRow[2]))
      this.currentForm.get('localNetName').setValue(this.selectedRow[0])
      this.currentForm.get('connectionType').setValue('Site-to-site (IPsec)')
      this.currentForm.get('IKEProtocol').setValue(false)
      break;
      // this.currentForm.get('sub').setValue(this.getProjectName(this.data[2]))
  }
  
}
}

async saveLNG(type){
  switch(type){
    case 'config':{
      let body = this.localNetworkData
      let rawValue = this.currentForm.getRawValue()
      // let rawTempValue = this.tempForm.getRawValue()
        let array  = rawValue.addressSpaceArray.filter(e=>{
          if(e.value&&e.value!=='') return e.value
        })
        array= array.map(e=> {return e.value})
      body['properties'].localNetworkAddressSpace.addressPrefixes=array
      body['properties'].gatewayIpAddress=rawValue.ipAddress
      if(rawValue.configBGP){
        body['properties'].bgpSettings={
                        "asn":rawValue.ASN, 
                        "bgpPeeringAddress":rawValue.bgpIPAddress, 
                        "peerWeight": 0 
        }
      }
      if(!rawValue.configBGP){
        delete body['properties'].bgpSettings
      }
      await this.updateLNGData(this.selectedRow,body,'config')
      this.util.delay(2000)
      await this.getLNGData('',this.selectedRow) 
      break;
    }
    case 'addConnection':{
      let rawValue = this.currentForm.getRawValue()
        let body= {"name": rawValue.name, 
            "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/connections/${rawValue.name}`, 
            "type": "Microsoft.Network/connections", 
            "location": this.selectedRow[3], 
            "properties": { 
                "packetCaptureDiagnosticState": "None", 
                "virtualNetworkGateway1": { 
                    "id": rawValue.vpnName
                }, 
                "localNetworkGateway2": { 
                    "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/localNetworkGateways/${this.selectedRow[0]}`
                }, 
                "connectionType": "IPsec", 
                "connectionProtocol":rawValue.IKEProtocol?'IKEv1':'IKEv2' , 
                "routingWeight": 0, 
                "sharedKey":rawValue.sharedKey , 
                "enableBgp":rawValue.BGP?true:false,  //if user clicks on enable BGP make this as true else false. 
                "useLocalAzureIpAddress": rawValue.ipAddress?true:false, //if user want to enable private IP to make this as true else false. 
                "usePolicyBasedTrafficSelectors": false, 
                "ipsecPolicies": [], 
                "trafficSelectorPolicies": [], 
                "connectionStatus": "NotConnected", 
                "ingressBytesTransferred": 0, 
                "egressBytesTransferred": 0, 
                "expressRouteGatewayBypass": false, 
                "dpdTimeoutSeconds": 0, 
                "connectionMode": "Default" 
            } 
        } 
        await this.updateConnectionData(this.selectedRow,body,'addConnection')
        // await this.util.delay(15000)
        // await this.getConnectionList('LNG',this.selectedRow)
        break;
        }
  }

}
asnValidation(event){
  let value=event.target.value;
  this.asnNumber=Number(value)
  this.inputError.asnValue=false
  this.inputError.asnError=false
  this.inputError.asnValidNum=false
  if(value=='') {this.inputError.asnValue=true, 
                  this.inputError.asnError=false
                  this.inputError.asnValidNum=false}
  else{this.inputError.asnValue=false
  if(!(/^[0-9]*$/.test(value))){this.inputError.asnValidNum=true
  }
  else{this.inputError.asnValidNum=false
    this.inputError.asnError=value=='0'||value=='23456'?true:false
    if(!this.inputError.asnError){
      if( this.asnNumber>64495 && this.asnNumber<131072){
        this.inputError.asnError=true}
        else this.inputError.asnError=false}
        // this.inputError.asnError=(value>'23455'&&value<'131072')?true:false}
    if(!this.inputError.asnError){
      if( this.asnNumber>4199999999 && this.asnNumber<4294967296){
        this.inputError.asnError=true}
        else this.inputError.asnError=false}
  }
} 
  // if(value!=0||value!=23456||value!=65535||value!=4294967295||64495>value ||value<234567||value<64496||value<64511||value<234567||value<234567){
  //   this.inputError.asnError=true
  // }
}
isCharANumber(char){
  return (/[0-9]/).test(char);
}
isAddressSpace(string){
  return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
}
sharedKeyValidation(event){
  let value=event.target.value
  if(value==''){
    this.inputError.sharedKeyEmpty=true
  }else{
    this.inputError.sharedKeyEmpty=false
    if(0<value.length&&value.length>128){
      this.inputError.sharedKeyMaxLen=true
    }else{
      this.inputError.sharedKeyMaxLen=false
      if(/^[-~]+$/.test(value[value.length-1])){
        this.inputError.sharedKeyASCII=true;
      }
      else{
        this.inputError.sharedKeyASCII=false;
      }
    }
  }
}
validIPName(event,limitNum,subType?:any){
  let value =event.target.value;
  this.connectionName=value
  this.inputError['nameExist'] = false;
  // this.inputError[flag+'MaxLen'] = false;
 if(value==''){
  this.inputError['nameEmpty'] = true;
 }else{ this.inputError['nameEmpty'] = false;}
    if (value.length > limitNum) {
      this.inputError['nameMaxLen'] = true;
      return;
    }
    else {
      this.inputError['nameMaxLen'] = false;
    }
  if(value.length==1){
    if(/^[A-Za-z0-9]*$/.test(value)){
      this.inputError['nameValid']=false;
    }
    else{
      this.inputError['nameValid']=true;
    }
  }
  // else this.inputError[flag+'Valid']=false;
  if(/^[A-Za-z0-9_]+$/.test(value[value.length-1]) && !this.inputError['nameValid']){
    this.inputError['nameLastValid']=false;
  }
  else{
    this.inputError['nameLastValid']=true;
  }
  switch(subType){
    case 'connectionName':{
      this.connectionList.forEach(e=>{
        if(e.name==value){
          this.inputError['nameExist'] = true;
          return;
        }
      })
    }
  }
}
resetValidation(){
  this.inputError={
    ipError:false,
    ipNonNullRange:false,
    ipUnique:false,
    adrcSpcError:false,
    adrcSpcNonNullRange:false,
    adrcSpcUnique:false,
    bgpError:false,
    bgpNonNullRange:false,
    bgpUnique:false,
    asnError:false,
    asnValidNum:false,
    asnValue:false,
    nameEmpty:false,
    nameExist:false,
    nameMaxLen:false,
    nameValid:false,
    nameLastValid:false,
    sharedKeyMaxLen:false,
    sharedKeyEmpty:false,
    sharedKeyASCII:false,
  }
}
splitWordByCaps(data){
  return this.util.splitWordByCaps(data)
}
splitWordByCapsExceptAbb(data){
  return this.util.splitWordByCapsExceptAbb(data)
}

//-------------------------------------------------LNG-Action-API-----------------------------------------------------------------------------------
async getLNGData(type,data,subData?:any){
  let subsId = data[2]
  let rgName = data[1]
  let LocalNetName = data[0]
  return this.util.handleRequest('get','a3s_management_getLNGData',[subsId,rgName,LocalNetName],null,null,null,true).then(res=>{
    this.localNetworkData = res.body
    console.log('NIC Data',this.localNetworkData)
  })
}
async getConnectionList(type,data,subData?:any){
  let subsId = data[2]
  let rgName = data[1] 
  return this.util.handleRequest('get','a3s_management_getConnectionList',[subsId,rgName],null,null,null,true).then(res=>{
    let connectionAllList= res.body.value
    connectionAllList=connectionAllList.filter(x=>x.properties?.localNetworkGateway2)
    this.connectionList=connectionAllList.filter(x=>x.properties?.localNetworkGateway2.id.split('/')[8]==this.localNetworkData.id.split('/')[8])
    console.log('NIC Data',this.connectionList)
  })
}
async getConnectionData(data,subData?:any){
  let subsId = data[2]
  let rgName = data[1] 
  // let connectionName = this.connectionList[0].name 
  this.connectionList.map(e=>{
    let connectionName = e.name
    return this.util.handleRequest('get','a3s_management_getConnectionData',[subsId,rgName,connectionName],null,null,null,true).then(res=>{
      let connectionData= res.body
        e['properties'].connectionStatus=e.name==connectionData.name ? connectionData?.properties?.connectionStatus:false
    console.log('NIC Data',this.connectionList)
  })
  })
}
async getVPNList(data,type?:any){
  let subsId = data[2]
  return this.util.handleRequest('get','a3s_management_getVPNList',[subsId],null,null,null,true).then(res=>{
    this.vpnList = res.body.value
    let loc=this.selectedRow[3]
    this.vpnList=this.vpnList.filter(x=>x.location==loc)
    this.vpnList=this.vpnList.filter(x=>x.properties.gatewayType!='ExpressRoute')
    console.log('NIC Data',this.vpnList)
  })
}
updateLNGData(data,body,type){
  let subsId = data[2]
  let rgName =  data[1] 
  let nicName =  data[0] 
  this.util.info(this.getInProgress(type))
  this.util.handleRequest('post','a3s_management_updateLNGData',[subsId,rgName,nicName],body,null,null,true).then(res=>{
    console.log('NIC Data',res)
    // this.showSubSidesheet = false
    // this.closeSidesheet()
    this.util.success(this.getSuccesText(type))
  },err=>{
    this.util.error(this.getFailureText(type))
  })
}
updateConnectionData(data,body,type,subData?:any){
  let subsId = data[2]
  let rgName = type == 'deleteConnection' ?  data[1] : data[1] 
  let connectionName = type == 'deleteConnection' ? this.selectedSideSheetRow.name : body.name
  this.util.info(this.getInProgress(type))
  return this.util.handleRequest('post','a3s_management_updateConnectionData',[subsId,rgName,connectionName],body,null,null,true).then(res=>{
    console.log('NIC Data',res)
    this.showSubSidesheet = false
    this.closeModal()
    this.resetForm()
    this.connectionList.push(body)
    // this.closeSidesheet()
    this.util.success(this.getSuccesText(type))
  },err=>{
    this.util.error(this.getFailureText(type))
  })
}
deleteLNGData(data){
  let subscriptionId = data[2],resourceGroupName = data[1],connectionName = data[0]
  this.util.info(`Deleting ${data[0] }...`)
  return this.util.handleRequest('delete','a3s_management_deleteLNGData',[subscriptionId,resourceGroupName,connectionName],null,null,null,true).then(async res=>{
    // await this.getManagementNetworkInterface()
    this.closeModal()
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
}
deleteConnectionData(data,name){
  let subscriptionId = data[2],resourceGroupName = data[1],connectionName = name
  this.util.info(`Deleting ${data[0] }...`)
  return this.util.handleRequest('delete','a3s_management_deleteConnection',[subscriptionId,resourceGroupName,connectionName],null,null,null,true).then(async res=>{
    this.closeModal()
    this.util.success(data[0] + ' deleted successfully!')
    let index = this.connectionList.findIndex(e=> e.name == this.selectedSideSheetRow.name)
    this.connectionList.splice(index,1)
    // await this.util.delay(15000)
    // await this.getConnectionList('',this.selectedRow)
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
}
}
