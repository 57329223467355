import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { UtilService } from '../../../../services/util.service';
import { NgForm } from '@angular/forms';
import { AuzreSubscription } from '../../../../@model/model';
import { DataMigrationService } from 'src/app/services/data-migration.service';

@Component({
  selector: 'app-linux-vm',
  templateUrl: './linux-vm.component.html',
  styleUrls: ['./linux-vm.component.scss']
})
export class LinuxVmComponent implements OnInit {
  @ViewChild('sourceForm') public sourceForm: NgForm;
  linuxVmStepper: any;
  state: any = 'init';
  step: any = 0;
  currentProcess: any = 'submitBasicInfo';
  subscriptions: AuzreSubscription[] = [];
  model= {
    subscription: null,
    resourceGroup: null,
    resourceGroupLocation: null,
    diskType: null,
    encryptionType: null,
    enableUltraDisk: null,
    virtualNetwork: null,
    virtualNetworkAddressSpace: null,
    virtualNetworkLocation: null,
    subnet: null,
    subnetAddressSpace: null,
    virtualMachineName: null,
    virtualMachineLocation: null,
    virtualMachineSize: null,
    computerName: null,
    username: null,
    password: null,
    confirmPassword: null,
  }
  modalService: any;
  loaded: boolean;
  ResourceGroups: any = [];
  constructor(private util:UtilService,private _dataMigrationService: DataMigrationService,) { }

  ngOnInit(): void {
    this.linuxVmStepper=_.cloneDeep(this.util.getStatic('linuxVMStepper'));
    this.subscriptions = this.util.getCache('subscriptionList');
    console.log('subscriptionList',this.subscriptions)
  }

  getRgBySubscription(){
    if(!this.model.subscription) return;
    let subsid = this.subscriptions.filter(e=> e.SubscriptionName == this.model.subscription)[0].SubscriptionID
    let body={
        subscriptionId : subsid,
    }
    this.loaded = false;
    this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
        console.log('Resource Group list ',res);
        this.ResourceGroups = res && res.value ? res.value : []
        this.loaded =  true;
    },err=>{
        this.loaded =  true;
    })
    this.model.resourceGroup = null;
    //  this.util.handleRequest('post','a3s_resourceGroup_getRgBySubscription',[],body).then(res=>{
    //     this.ResourceGroups = res || []
    // })
  }

  getClass(steps) {
    if (steps.isCompleted) return 'success';
    else if (steps.isError) return 'error'
    else if (this.step >= steps.limit) return 'active'
  }

  openModal(template: TemplateRef<any>, showError?: any) {
    this.modalService.open(template);
  }

  moveToNextStep(){
    switch (this.currentProcess) {
      case 'submitBasicInfo':{
        this.currentProcess='selectDiskType';
        this.linuxVmStepper[this.step].isCompleted=true;
        this.linuxVmStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 'selectDiskType':{
        this.currentProcess='submitNetworkInfo';
        this.linuxVmStepper[this.step].isCompleted=true;
        this.linuxVmStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 'submitNetworkInfo':{
        this.currentProcess='submitVmInfo';
        this.linuxVmStepper[this.step].isCompleted=true;
        this.linuxVmStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
    }
  }
}
