<div class="introduction">
    <p class="heading" id="0">Optimize </p>
        <p class="para ">A3S delivers the most critical aspect of managing expenditure on
             Azure by constantly monitoring the cost incurred by the resources deployed on 
             Azure across various projects and thereon analyzes that data in real time to provide
              actionable recommendations to optimize the resource provisioning, utilization and the
               pricing structure under which to consume the resource, among many other things.  </p>
        <p class="mb-0">Optimize landing page displays the recommendations on how to use the resources in cost effective manner. </p>
        <img src="assets\user-manual\cm-and-optimize\Optimize.svg" > 
        <figcaption class="caption">Cost monitoring – recommendations </figcaption>
        <ul>   
                   <li>By default, the information is shared for the first project.  </li>
                   <li>Project can be chosen from left menu as needed. </li>
                   <li>Use Subscription, Resource group and Resource filters to find recommendations for a specific resource within a project. </li>
                
       </ul>
</div>
