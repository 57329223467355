
<div class="dashboard-body mt-3">
    <div class="row">
        <div class="col-md-12 center">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Users By Country & Region</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.usersList)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('usersList')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(userByCountryPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter0')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter0">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',usersList)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',usersList)">Last hour</div>
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',usersList)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',usersList)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',usersList)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',usersList)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',usersList)">Last 3 days</div>
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',usersList)">Last 7 days</div>
                                        <div class="select-item" [class.active]="usersList?.currentFilter?.type == 'custom'" (click)="openModal(openCal,usersList)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="usersListOptions" *ngIf="usersList?.xAxis?.length && usersListOptions && !usersList?.showLoader"></div>
                <div class="loader-gif" *ngIf="usersList?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!usersList?.xAxis?.length && !usersList?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>

        <div class="col-md-6 left">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Sessions</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(refreshedTime.sessions)}} </p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('sessions')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(sessionPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter1">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',sessions)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',sessions)">Last hour</div>
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',sessions)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',sessions)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',sessions)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',sessions)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',sessions)">Last 3 days</div>
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',sessions)">Last 7 days</div>
                                        <div class="select-item" [class.active]="sessions?.currentFilter?.type == 'custom'" (click)="openModal(openCal,sessions)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="sessionsOptions" *ngIf="sessions?.xAxis?.length && sessionsOptions  && !sessions?.showLoader"></div>
                <div class="loader-gif" *ngIf="sessions?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!sessions?.xAxis?.length && !sessions?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Events</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.events)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('events')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(eventsPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter2')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter2">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',events)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',events)">Last hour</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',events)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',events)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',events)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',events)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',events)">Last 3 days</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',events)">Last 7 days</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'custom'" (click)="openModal(openCal,events)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="eventsOptions" *ngIf="events?.xAxis?.length && eventsOptions && !events?.showLoader"></div>
                <div class="loader-gif" *ngIf="events?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!events?.xAxis?.length && !events?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>

        <div class="col-md-6 left">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Overall Retention</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.overallRetention)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('overallRetention')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="overallRetentionOptions" *ngIf="overallRetention?.xAxis?.length && overallRetentionOptions && !overallRetention?.showLoader"></div>
                <div class="loader-gif" *ngIf="overallRetention?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!overallRetention?.xAxis?.length && !overallRetention?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Users Returned After # of Weeks</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.userReturned)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('userReturned')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="userReturned?.tableData?.rows?.length && !userReturned?.showLoader">
                    <table class="table">
                        <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Used on</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('userReturned','Cohort')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Users</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('userReturned','Users')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span><1w</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('userReturned','<1w')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>1w</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('userReturned','1w')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>2w</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('userReturned','2w')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>3w</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('userReturned','3w')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>4w</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('userReturned','4w')">
                                </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let row of userReturned?.tableData?.rows">
                              <td>{{row?.Cohort | displayTxt}}</td>
                              <td>{{row?.Users}}</td>
                              <td>{{row['<1w']}}</td>
                              <td>{{row['1w']}}</td>
                              <td>{{row['2w']}}</td>
                              <td>{{row['3w']}}</td>
                              <td>{{row['4w']}}</td>
                          </tr>                           
                        </tbody>
                    </table>
                </div>
                <div class="loader-gif" *ngIf="userReturned?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!userReturned?.tableData?.rows?.length && !userReturned?.showLoader ">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>

        <div class="col-md-6 left">
            <div class="graph-div mb-0">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Initiating Events</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.initiateEvents)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('initiateEvents')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="initiatingEvents?.tableData?.rows?.length && !initiatingEvents?.showLoader">
                    <table class="table">
                        <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('initiatingEvents','Name')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Users</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('initiatingEvents','Users')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Rate</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('initiatingEvents','Rate')">
                                </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let row of initiatingEvents?.tableData?.rows">
                              <td>{{row?.Name | displayTxt}}</td>
                              <td>{{row?.Users}}</td>
                              <td>{{row?.Rate}}</td>
                          </tr>                           
                        </tbody>
                    </table>
                </div>
                <div class="loader-gif" *ngIf="initiatingEvents?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!initiatingEvents?.tableData?.rows?.length && !initiatingEvents?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div mb-0">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Returned Events</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.returnedEvents)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('returnedEvents')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="returnedEvents?.tableData?.rows?.length && !returnedEvents?.showLoader">
                    <table class="table">
                        <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('returnedEvents','Name')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Users</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('returnedEvents','Users')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Rate</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('returnedEvents','Rate')">
                                </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let row of returnedEvents?.tableData?.rows">
                            <td>{{row?.Name | displayTxt}}</td>
                            <td>{{row?.Users}}</td>
                            <td>{{row?.Rate}}</td>
                          </tr>                           
                        </tbody>
                    </table>
                </div>
                <div class="loader-gif" *ngIf="returnedEvents?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!returnedEvents?.tableData?.rows?.length && !returnedEvents?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>


<!-- Pop Up Tables -->

<ng-template #userByCountryPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>User by Country</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Country</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('usersList','dimension')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Users</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('usersList','Users')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of usersList?.tableData?.rows">
                          <td>{{row?.dimension | displayTxt}}</td>
                          <td>{{row?.Users}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!usersList?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('usersList')" *ngIf="usersList?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #sessionPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Sessions</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('sessions','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Sessions</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('sessions','Sessions')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of sessions?.tableData?.rows">
                          <td>{{row?.timestamp | a3sDateTimeFormat| displayTxt}}</td>
                          <td>{{row?.Sessions}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!sessions?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('sessions')" *ngIf="sessions?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #eventsPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Events</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('events','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Events</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('events','Events')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of events?.tableData?.rows">
                          <td>{{row?.timestamp | a3sDateTimeFormat| displayTxt}}</td>
                          <td>{{row?.Events}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!events?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('events')" *ngIf="events?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>