import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
import { MLResourceInputErroModel, NewResourceGroup, MLResourceModel } from '../resources-model';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-machine-learning',
  templateUrl: './machine-learning.component.html',
  styleUrls: ['./machine-learning.component.scss']
})
export class MachineLearningComponent implements OnInit {
  @Input() projectData : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();

  machineLearningmodel: MLResourceModel ={
    MLSelectKeyShow: false,
    resourceId: null,
    resourceName: null,
    resourceType: 'machineLearningWorkspaces',
    resourceData: {
      subnet: null,
      vnetName: null,
      resourceGroup: null,
      vnetResourceGroup: null,
      containerRegistry: null,
      userObjectId: null,
      identityType:'systemAssigned',
      accessType:'accessKey',
      encryptionType:'Microsoft',
      tagList: [{
        tagName:null,
        value:null
      }],
      storageAccount: null,
      keyVault: null,
      applicationInsights: null,
      encrypKeyVault: null,
      userManagedIdentity: null,
    },
    comments: null,
    createdBy: null
  };
  show : boolean = false;
  inputError: MLResourceInputErroModel = {
    VMdescription: false,
    appInsightDup: false,
    appInsightName: false,
    managedIdentityDup: false,
    managedIdentityName: false,
    containerRgDup: false,
    containerRgName: false,
    keyVaultNamelen: false,
    keyVaultName: false,
    keyVaultDup: false,
    duplicateSa: false,
    storageAccountName: false,
    VMname: false,
    duplicateAi: false,
    keyNewNamelen: false,
    keyNewName: false,
    keyNewDup: false
  }
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  resourceGroupList:any=[];
  showView:boolean=false;
  storageAccountList:any=[];
  keyVaultList:any=[];
  keyVaultListForKey:any=[];
  applicationInsightsList:any=[];
  containerRegistryList:any=[];
  userManagedIdentityList:any=[];
  keyForMLObj: any={};
  createMLSAObj: any={};
  createMLKeyObj: any={};
  createMLAIObj: any={};
  createMLCRObj: any={};
  createMLUMIObj: any={};
  versionList: any[];
  keyList: any[];
  fileSystemNameList: any=[];
  lawList: any=[];
  storageSkusList:any=[];
  tempStorageSkusList:any=[];
  existingNewMLList: any=[];
  existingNewAIList: any=[];
  createNewKeyObj: any={};
  createNewKeyVaultObj: any={};
  skuList:any=[
    {name:'Basic'},
    {name:'Standard'},
    {name:'Premium'},
  ]
  
  constructor(private util:UtilService,private loader:LoaderService) { }

  async ngOnInit() {
    const randomVal=`'${Math.random}'`
    this.loader.queActions('push',randomVal);
    await this.getResourceGroupList();
    // if(this.sideSheetViewType=='new'){
    //   this[this.templateName+'model'].resourceData={};
    //   this[this.templateName+'model'].resourceName=null;
    //   this[this.templateName+'model'].resourceData.tagList=[{}];
    // }
    let parentResourceData,resourceObj;
    if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
      this.machineLearningmodel.resourceData.subnet=this.parentResourceDtls.resourceName;
      parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
      this.machineLearningmodel.resourceData.vnetName=parentResourceData.vnetName;
      resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
      this.machineLearningmodel.resourceData.resourceGroup=resourceObj.name;
      this.machineLearningmodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
      this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
    }

    await this.getListByPropName('storageAccountsForML',undefined,undefined);
    await this.getListByPropName('registries',undefined,undefined);
    await this.getListByPropName('components',undefined,undefined);
    await this.getListByPropName('vaults',undefined,undefined);
    await this.getListByPropName('userAssignedIdentities',undefined,undefined);
    await this.getListByPropName('machineLearning',undefined,undefined);
    if(resourceObj&&resourceObj.location) {
      await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
    }
    this.machineLearningmodel.resourceData.containerRegistry=this.containerRegistryList[0];
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    this.machineLearningmodel.resourceData.userObjectId=userObjectId;
    await this.getExistNewlyResourceDetails('machineLearningWorkspaces');
    await this.getExistNewlyResourceDetails('applicationInsights');

    if(this.sideSheetViewType=='edit'){
      this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null).then(async res=>{
        this.machineLearningmodel=res;
        this.machineLearningmodel.resourceData=JSON.parse(res.resourceData);
        if(this.parentResourceDtls['updateRequestFrom']){
          this.machineLearningmodel.resourceData.vnetName=this.parentResourceDtls.resourceName;
          this.machineLearningmodel.resourceData.resourceGroup=resourceObj.name;
        }
      });
    }
    this.loader.queActions('pop',randomVal);
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType === 'machineLearningWorkspaces') {
        this.existingNewMLList=res;
      }
      if(resourceType === 'applicationInsights') {
        this.existingNewAIList=res;
      }
    },err=>{
      if(resourceType === 'machineLearningWorkspaces') {
        this.existingNewMLList=[];
      }
      if(resourceType === 'applicationInsights') {
        this.existingNewAIList=[];
      }
    });
  }

  async resourceGroupChange(){
    if(this.machineLearningmodel&&this.machineLearningmodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.machineLearningmodel.resourceData.resourceGroup);
      if(resourceObj&&!resourceObj.resourceGroupId) {
        // await this.getListByPropName('workspaces',resourceObj,undefined);
        await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
      }
    }
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
      });
  }

  onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.machineLearningmodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.machineLearningmodel.resourceData.resourceGroup=undefined;
    });
  }

  editNewResourceGroup(item,index){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item);
    this.show = !this.show;
  }

  async getNewResourceGroup(){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addTagVM(){
    this.machineLearningmodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTagVM(index){
    this.machineLearningmodel.resourceData.tagList.splice(index,1);
  }

  hideShow(){
    this.showView=!this.showView;
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName=='storageAccountsForML'?'storageAccounts':propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='machineLearning'){
        this.lawList=res.value;
      } else if(propName=='storageSkus'){
        this.storageSkusList=res.value;
      } else if(propName == 'vaults'){
        this.keyVaultList=res.value;
        this.keyVaultListForKey=_.cloneDeep(res.value);
      }else if(propName == 'components'){
      this.applicationInsightsList=res.value;
      }else if(propName == 'registries'){
      let PIPResult=[{
        name:"None"
      }];
      if(res.value&&res.value.length>0)
      PIPResult.push(...res.value);
      this.containerRegistryList=PIPResult;
      }else if(propName == 'storageAccountsForML'){
      this.storageAccountList=res.value.filter(dt=>!dt.properties.isHnsEnabled);
      }else if(propName == 'userAssignedIdentities'){
      this.userManagedIdentityList=res.value;
      }
      return  result;
    },err=>{
    });
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.machineLearningmodel);
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  workspaceNameValidation(limitField, limitNum) {
    let value =limitField.target.value;
    this.inputError.duplicateAi=false;
    if (value.length > limitNum) {
        this.inputError.VMname = true;
        return;
    }
    else this.inputError.VMname = false;

    let regex=/\@|\&|\ |\*|\.|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
    if(value[0]=='_'|| value[0]=='-'||regex.test(value)){
      this.inputError.VMname =true;
      return;
    } 
    this.inputError.VMname =false;

    let findObj=this.lawList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
    if(this.existingNewMLList&&!findObj){
      findObj=this.existingNewMLList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase()&&dt.resourceId!=this.machineLearningmodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateAi=true;
      return;
    }
    this.inputError.duplicateAi=false;
    this.populateDefaultValueML();
    if(value.length<3){
      this.inputError.VMname = true;
      return;
    }
  }

  populateDefaultValueML(){
    if(this.machineLearningmodel.resourceName){
      let value=this.machineLearningmodel.resourceName.toString().substring(0, 10);
      let tempList=_.cloneDeep(this.storageAccountList);
      this.storageAccountList=[];
      let findObj=tempList.find(dt=>!dt.id&&!dt.createNew);
      if(!findObj){
        tempList.splice(0,0,{
          name:value+(Math.random().toString().split('.')[1]).substring(0,10)
        });
      }else{
        tempList[0].name=value+(Math.random().toString().split('.')[1]).substring(0,10);
      }
      this.storageAccountList=_.cloneDeep(tempList);
      this.machineLearningmodel.resourceData.storageAccount=this.storageAccountList[0];

      tempList=_.cloneDeep(this.keyVaultList);
      this.keyVaultList=[];
      let findObjK=tempList.find(dt=>!dt.id&&!dt.createNew);
      if(!findObjK){
        tempList.splice(0,0,{
          name:(Number(value[0])?value:'a'+value)+(Math.random().toString().split('.')[1]).substring(0,10)
        });
      }else{
        tempList[0].name=(Number(value[0])?value:'a'+value)+(Math.random().toString().split('.')[1]).substring(0,10);
      }
      this.keyVaultList=_.cloneDeep(tempList);
      this.machineLearningmodel.resourceData.keyVault=this.keyVaultList[0];

      tempList=_.cloneDeep(this.applicationInsightsList);
      this.applicationInsightsList=[];
      let findObjA=tempList.find(dt=>!dt.id&&!dt.createNew);
      if(!findObjA){
        tempList.splice(0,0,{
          name:value+(Math.random().toString().split('.')[1]).substring(0,10)
        });
      }else{
        tempList[0].name=value+(Math.random().toString().split('.')[1]).substring(0,10);
      }
      this.applicationInsightsList=_.cloneDeep(tempList);
      this.machineLearningmodel.resourceData.applicationInsights=this.applicationInsightsList[0];
    }else{
      let tempList=_.cloneDeep(this.storageAccountList);
      this.storageAccountList=[];
      tempList.splice(0,1);
      this.storageAccountList=_.cloneDeep(tempList);
      tempList=_.cloneDeep(this.keyVaultList);
      this.keyVaultList=[];
      tempList.splice(0,1);
      this.keyVaultList=_.cloneDeep(tempList);
      tempList=_.cloneDeep(this.applicationInsightsList);
      this.applicationInsightsList=[];
      tempList.splice(0,1);
      this.applicationInsightsList=_.cloneDeep(tempList);
    }
  }

  VmDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMdescription = true;
    }
    else this.inputError.VMdescription = false;
  }

  async checkStorageNameAvail(event,flagCheck){    
    let value=event.target.value;
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      name:value
    }
    await this.util.handleRequest('post','a3s_architectureBlueprint_checkStorageName',[],reqObj,null,null,true).then(res=>{
      if(res&&res.nameAvailable){
        this.inputError[flagCheck] =false; 
      }else{
        this.inputError[flagCheck] =true; 
      }
    })
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.machineLearningmodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  aiMLValidation(limitField, higherLimitNum){
    let value=limitField.target.value;
    this.inputError.appInsightDup =false; 
    if (value.length > higherLimitNum) {
        this.inputError.appInsightName = true;
        return
    }
    else this.inputError.appInsightName = false;

    for(let input of value){
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='_'||input=='-'||input=='.'||input=='('||input==')')){
        this.inputError.appInsightName = true;
        return;
      }
    }

    if(value[value.length-1]=="."){
      this.inputError.appInsightName = true;
      return;
    }
    this.inputError.appInsightName = false;

    
    if(value){
    let findObj=this.applicationInsightsList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
      if(this.existingNewMLList&&!findObj){
        findObj=this.existingNewMLList.find(dt=>{
          if(dt.resourceId!=this.machineLearningmodel.resourceId){
            let resourceData=JSON.parse(dt.resourceData);
            if(resourceData.applicationInsights.name.toLowerCase()==value.toLowerCase()){
              return true;
            }
          }
        });
      }
      if(this.existingNewAIList&&!findObj){
        findObj=this.existingNewAIList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase());
      }
      if(findObj){
        this.inputError.appInsightDup =true; 
        return;
      }else{
        this.inputError.appInsightDup =false; 
      }
    }
  }

  manageIdentityValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError.managedIdentityDup =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.managedIdentityName = true;
        return
    }
    else this.inputError.managedIdentityName = false;

    if(!(this.isCharALetter(value[0]) || this.isCharANumber(value[0]))){
      this.inputError.managedIdentityName = true;
      return;
    }

    for(let input of value){
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-' || input == '_')){
        this.inputError.managedIdentityName = true;
        return;
      }
    }
    this.inputError.managedIdentityName = false;
    if(value){
      let findObj=this.userManagedIdentityList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
        if(this.existingNewMLList&&!findObj){
          findObj=this.existingNewMLList.find(dt=>{
            if(dt.resourceId!=this.machineLearningmodel.resourceId){
              let resourceData=JSON.parse(dt.resourceData);
              if(resourceData.userManagedIdentity.name.toLowerCase()==value.toLowerCase()){
                return true;
              }
            }
          });
        }
        if(findObj){
          this.inputError.managedIdentityDup =true; 
          return;
        }else{
          this.inputError.managedIdentityDup =false; 
        }
      }
  }

  closeOtherPop(p1,p2){
    if(p1){
      p1.hide();
    }

    if(p2){
      p2.hide();
    }
  }

  containerMLValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError.containerRgDup =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.containerRgName = true;
        return
    }
    else this.inputError.containerRgName = false;

    for(let input of value){
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input))){
        this.inputError.containerRgName = true;
        return;
      }
    }
    this.inputError.containerRgName = false;
    if(value){
      let findObj=this.containerRegistryList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
        if(this.existingNewMLList&&!findObj){
          findObj=this.existingNewMLList.find(dt=>{
            if(dt.resourceId!=this.machineLearningmodel.resourceId){
              let resourceData=JSON.parse(dt.resourceData);
              if(resourceData.containerRegistry.name.toLowerCase()==value.toLowerCase()){
                return true;
              }
            }
          });
        }
        if(findObj){
          this.inputError.containerRgDup =true; 
          return;
        }else{
          this.inputError.containerRgDup =false; 
        }
      }
  }

  keyMLValidate(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.keyVaultNamelen = true;
        return
    }
    else this.inputError.keyVaultNamelen = false;

    let count=0,hypCount;
    for(let input of value){
      if(count==0&&(this.isCharANumber(input)||input=='-')){
        this.inputError.keyVaultName = true;
        return;
      }
      if(input=="-"){
        if(hypCount&&hypCount+1==count){
          this.inputError.keyVaultName = true;
          return;
        }
        hypCount=count;
      }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError.keyVaultName = true;
        return;
      }
      count=count+1;
    }
    this.inputError.keyVaultName = false;
    this.keyMLDuplicateCheck(limitField)
  }

  newKeyValidate(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError.keyNewNamelen = false;
    this.inputError.keyNewName = false;
    this.inputError.keyNewDup =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.keyNewNamelen = true;
        return
    }
    else this.inputError.keyNewNamelen = false;

    let count=0,hypCount;
    for(let input of value){
      // if(count==0&&(this.isCharANumber(input)||input=='-')){
      //   this.inputError.keyNewName = true;
      //   return;
      // }
      // if(input=="-"){
      //   if(hypCount&&hypCount+1==count){
      //     this.inputError.keyNewName = true;
      //     return;
      //   }
      //   hypCount=count;
      // }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError.keyNewName = true;
        return;
      }
      // count=count+1;
    }
    
    if(value){
    let findObj=this.keyList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
      if(this.existingNewMLList&&!findObj){
        findObj=this.existingNewMLList.find(dt=>{
          if(dt.resourceId!=this.machineLearningmodel.resourceId){
            let resourceData=JSON.parse(dt.resourceData);
            if(resourceData.key.name.toLowerCase()==value.toLowerCase()){
              return true;
            }
          }
        });
      }
      if(findObj){
        this.inputError.keyNewDup =true; 
        return;
      }else{
        this.inputError.keyNewDup =false; 
      }
    }
  }
  
  newKeyVaultValidate(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError.keyVaultNamelen = false;
    this.inputError.keyVaultName = false;
    this.inputError.keyVaultDup =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.keyVaultNamelen = true;
        return
    }
    else this.inputError.keyVaultNamelen = false;

    let count=0,hypCount;
    for(let input of value){
      if(count==0&&(this.isCharANumber(input)||input=='-')){
        this.inputError.keyVaultName = true;
        return;
      }
      if(input=="-"){
        if(hypCount&&hypCount+1==count){
          this.inputError.keyVaultName = true;
          return;
        }
        hypCount=count;
      }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError.keyVaultName = true;
        return;
      }
       count=count+1;
    }
    
    if(value){
    let findObj=this.keyVaultList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
      if(this.existingNewMLList&&!findObj){
        findObj=this.existingNewMLList.find(dt=>{
          if(dt.resourceId!=this.machineLearningmodel.resourceId){
            let resourceData=JSON.parse(dt.resourceData);
            if(resourceData.encrypKeyVault.keyVault.name.toLowerCase()==value.toLowerCase()){
              return true;
            }
          }
        });
      }
      if(findObj){
        this.inputError.keyVaultDup =true; 
        return;
      }else{
        this.inputError.keyVaultDup =false; 
      }
    }
  }

  keyMLDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.keyVaultDup =false; 
    if(value){
   let findObj=this.keyVaultList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
    if(this.existingNewMLList&&!findObj){
      findObj=this.existingNewMLList.find(dt=>{
        if(dt.resourceId!=this.machineLearningmodel.resourceId){
          let resourceData=JSON.parse(dt.resourceData);
          if(resourceData.keyVault.name.toLowerCase()==value.toLowerCase()){
            return true;
          }
        }
      });
    }
    if(findObj){
      this.inputError.keyVaultDup =true; 
      return;
    }else{
      this.inputError.keyVaultDup =false; 
    }

    this.checkKeyNameAvail(event);
  }
  }

  async checkKeyNameAvail(event){    
    let value=event.target.value;
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      name:value
    }
    await this.util.handleRequest('post','a3s_architectureBlueprint_checkKeyName',[],reqObj,null,null,true).then(res=>{
      if(res&&res.nameAvailable){
        this.inputError.keyVaultDup =false; 
      }else{
        this.inputError.keyVaultDup =true; 
      }
    })
  }

  storageMLValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError.duplicateSa =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.storageAccountName = true;
        return
    }
    else this.inputError.storageAccountName = false;

    for(let input of value){
      if(!input||!(this.isCharALowerCaseLetter(input) || this.isCharANumber(input))){
        this.inputError.storageAccountName = true;
        return;
      }
    }
    this.inputError.storageAccountName = false;
    this.saMLDuplicateCheck(limitField)
  }

  saMLDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateSa =false; 
    if(value){
   let findObj=this.storageAccountList.find(dt=>dt.name==value);
    if(this.existingNewMLList&&!findObj){
      findObj=this.existingNewMLList.find(dt=>{
        if(dt.resourceId!=this.machineLearningmodel.resourceId){
          let resourceData=JSON.parse(dt.resourceData);
          if(resourceData.storageAccount&&resourceData.storageAccount.name==value){
            return true;
          }
        }
      });
    }
    if(findObj){
      this.inputError.duplicateSa =true; 
      return;
    }else{
      this.inputError.duplicateSa =false; 
    }

    this.checkStorageNameAvail(event,'duplicateSa');
  }
  }

  saveResourceDetails(){
    // if(this.machineLearningmodel.resourceData.encryptionType=='Customer'&&this.machineLearningmodel.resourceData.encrypKeyVault&&this.machineLearningmodel.resourceData.encrypKeyVault.keyVault&&this.machineLearningmodel.resourceData.encrypKeyVault.keyVault.name){
    //   this.machineLearningmodel.resourceData.encrypKeyVault.keyVaultResourceId=`/subscriptions/${this.projectData.subscriptionId}/resourceGroups/${this.machineLearningmodel.resourceData.resourceGroup}/providers/Microsoft.KeyVault/vaults/${this.machineLearningmodel.resourceData.encrypKeyVault.keyVault.name}`;
    // }
    var model = this.machineLearningmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.machineLearningmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  selectMLEncrypKeyVault(){
    this.machineLearningmodel.MLSelectKeyShow=true;
    if(this.machineLearningmodel.resourceData.encrypKeyVault){
    this.keyForMLObj=_.cloneDeep(this.machineLearningmodel.resourceData.encrypKeyVault);
    }else{
      this.keyForMLObj={};
    }
  }

  async keyVaultChangeForKey(){
    this.versionList=[];
    this.keyList=[];
    this.keyForMLObj.key=undefined;
    this.keyForMLObj.version=undefined;
    if(this.keyForMLObj.keyVault){
      let reqObj={
        vaultUri:this.keyForMLObj.keyVault.name,
        token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      }
      await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
        if(res && res.value){
          this.keyList=res.value.map((dt)=>{
            dt.name=dt.kid.split('/')[dt.kid.split('/').length-1];
            return dt;
          });
        }
      });
    }
  }

  async keyChangeForVersion(){
    this.versionList=[];
    this.keyForMLObj.version=undefined;
    if(this.keyForMLObj.keyVault&&this.keyForMLObj.key){
      let reqObj={
        vaultUri:this.keyForMLObj.keyVault.name,
        token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
        keyName:this.keyForMLObj.key.name
      }
     await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
        if(res){
          this.versionList=res.value;
        }
      });
    }
  }

  filterStorageSkus(performance,kind){
    let kindVal=_.cloneDeep(kind);
    if(kind=='PageBlobStorage'){
      kindVal='StorageV2'
    }
    this.tempStorageSkusList=this.storageSkusList.filter(dt=>dt.tier==performance&&dt.kind==kindVal);
    this.tempStorageSkusList.map(dt=>{
      let findObj=this.util.getStatic('redundancy').find(ds=>ds.id==dt.name.split("_")[1]);
      if(findObj){
        dt.rdname=findObj.name;
      }
      return dt;
    });
    let findObj=this.tempStorageSkusList.find(dt=>dt.name.includes('_LRS'));
    if(this.createMLSAObj){
    this.createMLSAObj.replication= findObj?findObj.name:'Standard_LRS';
    }
  }

  openPop(popId,flag){
    if(flag=="createMLStorageAccount"){
      let findObj=this.storageAccountList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLSAObj=_.cloneDeep(findObj);
        this.filterStorageSkus('Standard','StorageV2');
        this.createMLSAObj.replication=findObj.replication;
      }else{
        this.createMLSAObj={};
        this.filterStorageSkus('Standard','StorageV2');
      }
    }
    if(flag=="createMLKeyVault"){
      let findObj=this.keyVaultList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLKeyObj=_.cloneDeep(findObj);
      }else{
        this.createMLKeyObj={};
      }
    }
    if(flag=="createMLApplicationInsights"){
      let findObj=this.applicationInsightsList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLAIObj=_.cloneDeep(findObj);
      }else{
        this.createMLAIObj={};
      }
    }
    if(flag=="createMLContainerRegistry"){
      let findObj=this.containerRegistryList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLCRObj=_.cloneDeep(findObj);
      }else{
        this.createMLCRObj={
          sku:'Standard'
        };
      }
    }
    if(flag=="createMLUserManagedIdentity"){
      let findObj=this.userManagedIdentityList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLUMIObj=_.cloneDeep(findObj);
      }else{
        this.createMLUMIObj={};
      }
    }
    if(flag=="createNewKey"){
      let findObj=this.keyList.find(dt=>dt.createNew);
      if(findObj){
        this.createNewKeyObj=_.cloneDeep(findObj);
      }else{
        this.createNewKeyObj={};
      }
    }
    if(flag=="createNewKeyVault"){
      let findObj=this.keyVaultListForKey.find(dt=>dt.createNew);
      if(findObj){
        this.createNewKeyVaultObj=_.cloneDeep(findObj);
      }else{
        this.createNewKeyVaultObj={};
      }
    }
    popId.show();
  }

  saveSubSideSheet(type){
    switch(type){
      case 'createNewKey':{
        let tempList=_.cloneDeep(this.keyList);
        this.keyList=[];
        this.createNewKeyObj.createNew=true;
        let findIndex=tempList.find(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createNewKeyObj);
        }else{
        tempList.splice(0,0,this.createNewKeyObj);
        }
        this.keyList=tempList;
        this.keyForMLObj.key=_.cloneDeep(this.createNewKeyObj);
        return
      }
      case 'createNewKeyVault':{
        let tempList=_.cloneDeep(this.keyList);
        this.keyList=[];
        this.createNewKeyVaultObj.createNew=true;
        let findIndex=tempList.find(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createNewKeyVaultObj);
        }else{
        tempList.splice(0,0,this.createNewKeyVaultObj);
        }
        this.keyList=tempList;
        this.keyForMLObj.keyVault=_.cloneDeep(this.createNewKeyVaultObj);
        return
      }
      case 'MLSelectKey':{
        this.machineLearningmodel.resourceData.encrypKeyVault=_.cloneDeep(this.keyForMLObj);
        this.machineLearningmodel.MLSelectKeyShow=false;
        this.keyForMLObj={};
        return;
      }
      case 'createMLStorageAccount':{
        let tempList=_.cloneDeep(this.storageAccountList);
        this.storageAccountList=[];
        this.createMLSAObj.createNew=true;
        let findIndex=tempList.find(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createMLSAObj);
        }else{
        tempList.splice(0,0,this.createMLSAObj);
        }
        this.storageAccountList=tempList;
        this.machineLearningmodel.resourceData.storageAccount=_.cloneDeep(this.createMLSAObj);
        return
      }
      case 'createMLKeyVault':{
        let tempList=_.cloneDeep(this.keyVaultList);
        this.keyVaultList=[];
        this.createMLKeyObj.createNew=true;
        let findIndex=tempList.find(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createMLKeyObj);
        }else{
        tempList.splice(0,0,this.createMLKeyObj);
        }
        this.keyVaultList=tempList;
        this.machineLearningmodel.resourceData.keyVault=_.cloneDeep(this.createMLKeyObj);
        return
      }
      case 'createMLApplicationInsights':{
        let tempList=_.cloneDeep(this.applicationInsightsList);
        this.applicationInsightsList=[];
        this.createMLAIObj.createNew=true;
        let findIndex=tempList.find(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createMLAIObj);
        }else{
        tempList.splice(0,0,this.createMLAIObj);
        }
        this.applicationInsightsList=tempList;
        this.machineLearningmodel.resourceData.applicationInsights=_.cloneDeep(this.createMLAIObj);
        return
      }
      case 'createMLContainerRegistry':{
        let tempList=_.cloneDeep(this.containerRegistryList);
        this.containerRegistryList=[];
        this.createMLCRObj.createNew=true;
        let findIndex=tempList.findIndex(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createMLCRObj);
        }else{
        tempList.push(this.createMLCRObj);
        }
        this.containerRegistryList=tempList;
        this.machineLearningmodel.resourceData.containerRegistry=_.cloneDeep(this.createMLCRObj);
        return
      }
      case 'createMLUserManagedIdentity':{
        let tempList=_.cloneDeep(this.userManagedIdentityList);
        this.userManagedIdentityList=[];
        this.createMLUMIObj.createNew=true;
        let findIndex=tempList.findIndex(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createMLUMIObj);
        }else{
        tempList.push(this.createMLUMIObj);
        }
        this.userManagedIdentityList=tempList;
        this.machineLearningmodel.resourceData.userManagedIdentity=_.cloneDeep(this.createMLUMIObj);
        return
      }
    }
  }

  checkDataDisabled(){
    return !this.machineLearningmodel.resourceData.resourceGroup||!this.machineLearningmodel.resourceName||this.inputError.VMname||this.inputError.duplicateAi||(this.machineLearningmodel.resourceData.identityType=='userAssigned'&&!this.machineLearningmodel.resourceData.userManagedIdentity)||(this.machineLearningmodel.resourceData.encryptionType=='Customer'&&!this.machineLearningmodel.resourceData.encrypKeyVault);
  }
}
