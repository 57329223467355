
 <div class="introduction">
    <p class="heading" id="0">Concept of Project </p>
    <p class="para">"Project” is a key and only way of using A3S effectively. Everything in A3S is 
        encapsulated within a Project. If you are using Azure for first time, you’ll create Projects
        in Digital Transformation bucket to execute various tasks. If you have been using Azure and 
        are looking to leverage A3S for your existing deployments, you’ll need to create Project(s) in
        A3S and thereon put the deployments underneath it as required.</p>
   <p>Once Projects have been created thereon all the functionality provided in Monitoring & Remediation
        and GRC buckets are conducted in context of Projects. You’ll monitor infrastructure & application
        health and associated costs by the Project and similarly GRC functionality also works at the 
        Project level with some exceptions.</p>
        <p>Important points about Project: </p>
        <ul>
            <li>Deployment of resources through architecture blueprint requires user to create a project.  </li>
            <li>For example, ‘User A’ creates ‘Project 1’ and deploys set of resources in the architecture blueprint to Azure.</li>
            <li>Later, Project 1 will be available in other buckets of A3S. For example, resources deployed under Project 1 can be monitored in the Infrastructure Health Monitoring bucket. </li>
            <li>Advantages of the concept of projects are as below: </li>
                <ul style="list-style-type: circle;">
                    <li>Concise and precise management of the resources: User 1 will have access to the resources that are deployed under Project 1. </li>
                    <li>Focused scope makes management easy: an application administrator can view and manage only web apps and not the domain servers.</li>
                    <li>Users can manage their respective projects, whereas global/organization admins have access to all the projects to keep track and have oversight across the tenant. </li>
                </ul>
            <li>Some parts of A3S functional buckets cannot be categorized by projects. For example,  </li>
            <ul style="list-style-type: circle;">
                <li>Compliance standards can be applied on subscription level only. </li>
                <li>A project can span single/multiple subscriptions. </li>
                <li>If User 1 wants to add standards for Project 1 that spans across subscriptions, then other resources in those subscriptions that do not fall under Project 1 will also be impacted by application of those standards.</li>
                <li>In such scenarios as above, the access to such parts of the buckets is provided to global/organization administrators only. For example, compliance administrator can access the entire bucket and can manage policies and standards irrespective of the projects. Compliance administrator can add/remove standards as needed in the subscription. </li>
            </ul>

        </ul>
       
 </div>

