import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metrics-infras-health-monitoring',
  templateUrl: './metrics-infras-health-monitoring.component.html',
  styleUrls: ['./metrics-infras-health-monitoring.component.scss']
})
export class MetricsInfrasHealthMonitoringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
