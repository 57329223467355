<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Storage Account</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="storageAccountmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                  <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(false)">Create new
                  </p>
                </div>
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="storageAccountName" class="control-label col-sm-2">Storage account name<span class="mandatory"> * <img src="/assets/Infra/info.svg" alt="" matTooltip="The name must be unique across all existing storage account names in Azure. It must be 3 to 24 characters long, and can contain only lowercase letters and numbers." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName || inputError?.storageAccountChar || inputError?.duplicateSa" id="storageAccountName" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceName" placeholder="Enter storage account name" name="StorageAccountName" (keyup)="storageAccountNameValidation($event,3,24);" required>
                    <p class="text-danger" *ngIf="inputError?.storageAccountName">The name must be between 3 and 24 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.storageAccountChar">The field can contain only lowercase letters and numbers.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateSa">The storage account name is already taken.</p>
                </div>              
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label col-sm-2">Description </label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="storageAccountmodel.comments" placeholder="Enter a reason for adding this resource..." (keyup)="storageAccountDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
            
            <div *ngIf="showView">

                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="performance" class="control-label ">Performance<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Premium: optimized for high transaction rates and single-digit consistent storage latency.Standard: optimized for high capacity and high throughput" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box radio-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="performance" [value]="'Standard'" ([ngModel])="storageAccountmodel.resourceData.performance" [checked]="storageAccountmodel.resourceData.performance=='Standard'" (change)="storageAccountmodel.resourceData.performance=$event.target.value;storageAccountmodel.resourceData.kind='StorageV2';filterStorageSkus(storageAccountmodel.resourceData.performance,storageAccountmodel.resourceData.kind);"><span><b>Standard</b></span></label>
                        <label class="radio-label" style="padding-left: 19px;">Recommended for most scenarios(general-purpose v2 account)</label>
                        <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="performance" [value]="'Premium'" ([ngModel])="storageAccountmodel.resourceData.performance" [checked]="storageAccountmodel.resourceData.performance=='Premium'" (change)="storageAccountmodel.resourceData.performance=$event.target.value;storageAccountmodel.resourceData.kind='PageBlobStorage';filterStorageSkus(storageAccountmodel.resourceData.performance,storageAccountmodel.resourceData.kind);"><span><b>Premium</b></span></label>                    
                        <label class="radio-label" style="padding-left: 19px;">Recommended for scenarios that require low latency</label>
                    </div>             
                </div>

                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="kind" class="control-label">Account type<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose an account type that matches your storage needs and optimizes your costs" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="storageAccountmodel.resourceData.performance=='Standard'?acntTypeListForStand:acntTypeListForPerm" bindLabel="name" bindValue="id" [disabled]="storageAccountmodel.resourceData.performance=='Standard'" [(ngModel)]="storageAccountmodel.resourceData.kind" name="kind" (change)="filterStorageSkus(storageAccountmodel.resourceData.performance,storageAccountmodel.resourceData.kind);" placeholder="" [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                {{item?.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <b>{{item?.name}} :</b><br>{{item?.discription}}
                            </ng-template>
                        </ng-select>
                    </div>   
                </div>
        
                <div class="form-group">
                    <div class="label-div">
                        <label for="redundancy" class="control-label">Redundancy<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The data in your Azure storage account is always replicated to ensure durability and high availability. Choose a replication strategy that matches your durability requirements." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <!-- storageAccountmodel.resourceData.performance=='Standard'?redncyListForStand:storageAccountmodel.resourceData.kind=='PageBlobStorage'?redncyListForPerm:redncyListForOther -->
                        <ng-select [items]="tempStorageSkusList" bindLabel="rdname" bindValue="name" [(ngModel)]="storageAccountmodel.resourceData.redundancy" name="redundancy" placeholder="" [clearable]="false"></ng-select>
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;" *ngIf="storageAccountmodel.resourceData.performance=='Standard'&&(storageAccountmodel.resourceData.redundancy=='GRS'||storageAccountmodel.resourceData.redundancy=='GZRS')"><input type="checkbox" name="readAccessEnabled" id="readAccessEnabled" [(ngModel)]="storageAccountmodel.resourceData.readAccessEnabled" ><span>Make read access to data available in the event of regional availability</span></label>
                    </div>   
                    <!-- <section class="redun-check">
                        <mat-checkbox class="" [color]="'primary'">Make read access to data available in the event of regional availability</mat-checkbox>
                      </section> -->
                </div>
                

                  <div class="form-group">
                    <div class="label-div">
                        <label for="Security" class="control-label">Security<span class="mandatory"></span></label>
                    </div>
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="secureTransferEnable" id="secureTransferEnable" [(ngModel)]="storageAccountmodel.resourceData.secureTransferEnable" ><span>Enable secure transfer<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The secure transfer option enhances the security of your storage account by only allowing requests to the storage account by secure connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="infrastructureEncryptionEnabled" id="infrastructureEncryptionEnabled" [(ngModel)]="storageAccountmodel.resourceData.infrastructureEncryptionEnabled" ><span>Enable infrastructure encryption<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="By default, Azure encrypts storage account data at rest. Infrastructure encryption adds a second layer of encryption to your storage account’s data." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="keyAccessEnabled" id="keyAccessEnabled" [(ngModel)]="storageAccountmodel.resourceData.keyAccessEnabled" ><span>Enable storage account key access<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When storage account key access is disabled, any requests to the account that are authorized with Shared Key, including shared access signatures (SAS), will be denied." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="allowBlobPublicAccess" id="allowBlobPublicAccess" [(ngModel)]="storageAccountmodel.resourceData.allowBlobPublicAccess" ><span>Enable blob public access<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If public access is disallowed for a blob, containers in that blob cannot besubsequently configured for public access." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    <!-- <section class="redun-check">
                        <mat-checkbox class="" [color]="'primary'"></mat-checkbox>
                        <mat-checkbox class="" [color]="'primary'"></mat-checkbox>
                        <mat-checkbox class="" [color]="'primary'"></mat-checkbox>
                      </section> -->
                    </div>
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="minimumTlsVersion" class="control-label">Minimum TLS version<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set the minimum TLS version needed by applications using your storage account's data." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="minTlsvList" bindLabel="name" bindValue="id" [(ngModel)]="storageAccountmodel.resourceData.minimumTlsVersion" name="minimumTlsVersion" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>

                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="bStorage" class="control-label " style="font-weight: 600;font-size: 13px;margin-bottom: 19px;">Blob storage</label>
                        <label for="accessTier" class="control-label ">Access tier</label>
                    </div>
                    <div class="input-box radio-flex">
                        <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="accessTier" [value]="'Hot'" ([ngModel])="storageAccountmodel.resourceData.accessTier" [checked]="storageAccountmodel.resourceData.accessTier=='Hot'" (change)="storageAccountmodel.resourceData.accessTier=$event.target.value"><span><b>Hot:</b></span></label>
                        <label class="radio-label" style="padding-left: 18px">Frequently accessed data and day-to-day usage scenarios</label>
                        <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="accessTier" [value]="'Cool'" ([ngModel])="storageAccountmodel.resourceData.accessTier" [checked]="storageAccountmodel.resourceData.accessTier=='Cool'" (change)="storageAccountmodel.resourceData.accessTier=$event.target.value"><span><b>Cool:</b></span></label>
                        <label class="radio-label" style="padding-left: 19px">Infrequently accessed data and backup scenarios</label>
                    </div>             
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="aFiles" class="control-label">Azure Files<span class="mandatory"></span></label>
                    </div>
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="largeFileSharesState" id="largeFileSharesState" [(ngModel)]="storageAccountmodel.resourceData.largeFileSharesState" [disabled]="disabledCheckBoxSA('largeFile')" [disabled]="disabledCheckBoxSA('largeFiles')"><span>Enable large file shares<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provides file share support up to a maximum of 100 TiB." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <!-- <section class="redun-check">
                        <mat-checkbox class="redun-check">Enable secure transfer<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The secure transfer option enhances the security of your storage account by only allowing requests to the storage account by secure connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></mat-checkbox>
                    </section> -->
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="tAq" class="control-label">Tables and Queues<span class="mandatory"></span></label>
                    </div>
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="customerManagedKeysEnabled" id="customerManagedKeysEnabled" [(ngModel)]="storageAccountmodel.resourceData.customerManagedKeysEnabled" ><span>Enable support for customer-managed keys<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use point-in-time restore to restore one or more containers to an earlier state. If point-in-time restore is enabled, then versioning, change feed, and blob soft delete must also be enabled" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <!-- <section class="redun-check">
                        <mat-checkbox class="redun-check"></mat-checkbox>
                      </section> -->
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="recovery" class="control-label">Recovery<span class="mandatory"></span></label>
                    </div>
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isContainerRestoreEnabled" id="isContainerRestoreEnabled" [disabled]="disabledCheckBoxSA('point')" [(ngModel)]="storageAccountmodel.resourceData.isContainerRestoreEnabled" ><span style="margin-right: -16px;">Enable point-in-time restore for containers<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When turned on, enables support for using customer-managed keys (CMKs) to encrypt your tables and queues" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <div class="form-group" *ngIf="storageAccountmodel?.resourceData?.isContainerRestoreEnabled">
                            <div class="label-div">
                                <label for="restoredays" class="control-label col-sm-2">Maximum restore point (days ago)<span class="mandatory">*<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip=" Set the oldest possible restore point. For example, if you choose 30 days ago, then the earliest date for a restore on May 31 is May 1." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.restoredays" id="restoredays" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceData.recovery" placeholder="" name="recovery" (keyup)="changeDaysRestore($event)" required>
                                <!-- <p class="text-danger" *ngIf="inputError?.subnetAdrspc">This value must be less than the value (90) that's set for blob soft delete.</p> -->
                                <p class="text-danger" *ngIf="inputError?.restoredays">The value must be between 1 and 364.</p>
                            </div>              
                        </div>

                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isContainerSoftDeleteEnabled" id="isContainerSoftDeleteEnabled" [disabled]="disabledCheckBoxSA('soft')" [(ngModel)]="storageAccountmodel.resourceData.isContainerSoftDeleteEnabled" ><span style="margin-right: -16px;">Enable soft delete for containers<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Soft delete enables you to recover containers that were previously marked for deletion." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <div class="form-group" *ngIf="storageAccountmodel?.resourceData?.isContainerSoftDeleteEnabled">
                            <div class="label-div">
                                <label for="restoredays" class="control-label col-sm-2">Days to retain deleted containers<span class="mandatory">*<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip="Set the number of days that a container marked for deletion persists until it's permanently deleted." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.shareSoftDeleteRetentionDays" id="shareSoftDeleteRetentionDays" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceData.containerSoftDeleteRetentionDays" placeholder="" name="shareSoftDeleteRetentionDays" (keyup)="checkSoftDelete($event,'shareSoftDeleteRetentionDays')" required>
                                <p class="text-danger" *ngIf="inputError?.shareSoftDeleteRetentionDays">The value must be between 1 and 365.</p>
                            </div>
                        </div>

                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isBlobSoftDeleteEnabled" id="isBlobSoftDeleteEnabled" [disabled]="disabledCheckBoxSA('soft')" [(ngModel)]="storageAccountmodel.resourceData.isBlobSoftDeleteEnabled" ><span>Enable soft delete for blobs<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Soft delete enables you to recover blobs that were previously marked for deletion." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <div class="form-group" *ngIf="storageAccountmodel?.resourceData?.isBlobSoftDeleteEnabled">
                            <div class="label-div">
                                <label for="blobSoftDeleteRetentionDays" class="control-label col-sm-2">Days to retain deleted blobs<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set the number of days that a blob marked for deletion
                                    persists until it's permanently deleted." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.blobSoftDeleteRetentionDays" id="blobSoftDeleteRetentionDays" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceData.blobSoftDeleteRetentionDays" placeholder="" name="blobSoftDeleteRetentionDays" (keyup)="checkSoftDelete($event,'blobSoftDeleteRetentionDays')" required>
                                <p class="text-danger" *ngIf="inputError?.blobSoftDeleteRetentionDays">The value must be between 1 and 365.</p>
                            </div>              
                        </div>
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isShareSoftDeleteEnabled" id="isShareSoftDeleteEnabled" [disabled]="disabledCheckBoxSA('largeFile')" [(ngModel)]="storageAccountmodel.resourceData.isShareSoftDeleteEnabled" ><span>Enable soft delete for file shares<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Soft delete enables you to recover file shares that were previously marked for deletion" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <div class="form-group" *ngIf="storageAccountmodel?.resourceData?.isShareSoftDeleteEnabled">
                            <div class="label-div">
                                <label for="shareSoftDeleteRetentionDays" class="control-label col-sm-2">Days to retain deleted file shares<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set the number of days that a file share marked for 
                                    deletion persists until it's permanently deleted." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.shareSoftDeleteRetentionDays" id="shareSoftDeleteRetentionDays" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceData.shareSoftDeleteRetentionDays" placeholder="" name="shareSoftDeleteRetentionDays" (keyup)="checkSoftDelete($event,'shareSoftDeleteRetentionDays')" required>
                                <p class="text-danger" *ngIf="inputError?.shareSoftDeleteRetentionDays">The value must be between 1 and 365.</p>
                            </div>              
                        </div>  
                    </div>
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="tracking" class="control-label">Tracking<span class="mandatory"></span></label>
                    </div>
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isVersioningEnabled" id="isVersioningEnabled" [disabled]="disabledCheckBoxSA('tracking')" [disabled]="disabledCheckBoxSA('trackings')" [(ngModel)]="storageAccountmodel.resourceData.isVersioningEnabled" ><span>Enable versioning for blobs<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use versioning to automatically maintain previous versions of your blobs for recovery and restoration" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="changeFeed" id="changeFeed" [disabled]="disabledCheckBoxSA('tracking')" [disabled]="disabledCheckBoxSA('trackings')" [(ngModel)]="storageAccountmodel.resourceData.changeFeed" ><span>Enable blob change feed<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Keep track of create, modification, and delete changes to blobs in your account" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <!-- <section class="redun-check">
                        <mat-checkbox class="redun-check"></mat-checkbox>
                        <mat-checkbox class="redun-check"></mat-checkbox>
                      </section> -->
                </div>
               
                <div class="form-group">
                    <div class="label-div d-flex">
                      <label class="control-label " style="min-width: max-content;">Tag name </label>
                      <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value</label>
                      <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="storageAccountmodel.resourceData&&storageAccountmodel.resourceData.tagList&&storageAccountmodel.resourceData.tagList.length>1"></label>
                      <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                    </div>
                    <div *ngFor="let item of storageAccountmodel.resourceData.tagList;let i = index">
                      <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
  
                        <div class="input-box" style="padding-bottom: 6px;">
                          <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
  
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
  
                        <div class="input-box">
                          <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
  
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="storageAccountmodel.resourceData.tagList.length>1"></span>
                      </div>
                      <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                      <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                      <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                      <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
  
                    <div class="add-another-tag">
                      <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                  </div>
            </div>

            <!-- <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('storageAccount')" (click)="triggerBtn()">Save</button>
            </div> -->

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>  