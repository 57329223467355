<div class="introduction">
          <p class="heading" id="0">Create Project</p>
          <ol>
              <li> Click <b>Create new project</b> at the bottom of the page. </li>
              <img src=" assets\user-manual\apps-&-infra\list-of-project.png ">
              <figcaption class="caption">Create project</figcaption>
              <li>Enter <b>project name, department,</b>and select <b>subscription</b>. Resources are deployed in the selected subscription. </li>
              <img src=" assets\user-manual\apps-&-infra\create-project.png " >
              <figcaption class="caption">Create new project </figcaption>
              <li>Click <b>Next.</b> </li>
              <li>Next screen displays architecture design drag & drop tool with an empty canvas. </li>
              <img src="assets\user-manual\apps-&-infra\empty-canvas.svg "> 
              <figcaption class="caption">Empty canvas</figcaption>
          </ol>
     
</div>
