<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Log Analytics Workspace</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="logAnalyticsmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div> 
                <div class="add-another-tag">
                  <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(false)">Create new
                  </p>
                </div>
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="logAnalytics" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace name should include 4-63 letters, digits or '-'. The '-' shouldn't be the first or the last symbol." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="logAnalyticsmodel.resourceName" placeholder="Enter a workspace name" name="logAnalytics" (keyup)="laNameValidation($event,4,63);lawDuplicateCheck($event)" required>
                <p class="text-danger" *ngIf="inputError?.laname">The workspace name must be between 4 and 63 characters.</p>
                <p class="text-danger" *ngIf="inputError?.laSpecialChar">The workspace name can contain only letters, numbers and '-'. The '-' shouldn't be the first or the last symbol. </p>
                <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Workspace name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                <p class="text-danger" *ngIf="inputError?.duplicateLaw">Workspace name must be unique in the current resource group.</p>
            </div>              
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="sku" class="control-label">Pricing tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The cost of your workspace depends on the pricing tier and what solutions you use. You can change to a Capacity Reservation tier after your workspace is created" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="pTierList" bindLabel="name" bindValue="id" [(ngModel)]="logAnalyticsmodel.resourceData.sku" name="sku" placeholder="" [clearable]="false"></ng-select>
                </div>   
            </div>

        
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="logAnalyticsmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
            
                   <div class="form-group">
                    <div class="label-div d-flex">
                      <label class="control-label " style="min-width: max-content;">Tag name </label>
                      <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                      </label>
                      <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="logAnalyticsmodel.resourceData&&logAnalyticsmodel.resourceData.tagList&&logAnalyticsmodel.resourceData.tagList.length>1"></label>
                      <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                    </div>
                    <div *ngFor="let item of logAnalyticsmodel.resourceData.tagList;let i = index">
                      <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
  
                        <div class="input-box" style="padding-bottom: 6px;">
                          <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
  
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
  
                        <div class="input-box">
                          <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
  
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="logAnalyticsmodel.resourceData.tagList.length>1"></span>
                      </div>
                      <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                      <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                      <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                      <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
  
                    <div class="add-another-tag">
                      <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                  </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
</div>
