import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/services/util.service';


@Component({
  selector: 'app-create-azure-support-ticket',
  templateUrl: './create-azure-support-ticket.component.html',
  styleUrls: ['./create-azure-support-ticket.component.scss']
})
export class CreateAzureSupportTicketComponent implements OnInit {
  supportForm:FormGroup;
  numberRegEx = /\-?\d*\.?\d{1,2}/;

  supportInterestList = [
    {id:1,value:'Lift and shift strategy plan'},
    {id:2,value:'New cloud deployment'},
    {id:3,value:'Support existing cloud deployment'},
    {id:4,value:'End to end cloud migration project'},
    {id:5,value:'Data Analytics'},
    {id:6,value:'ML/AI'},
    {id:7,value:'Other'}
  ]

  levelOfExpertise=[ {id:1,value:'Architect'},
  {id:2,value:'Senior Engineer'},
  {id:3,value:'Intermediate Engineer'},
  {id:4,value:'Support Engineer'},
]
  constructor(private util:UtilService,private fb:FormBuilder) {

    this.supportForm=this.fb.group({
      problemSummary:[null,[Validators.required,Validators.maxLength(140)]],
      issueDetails:[null,[Validators.required,Validators.maxLength(20000)]],
      supportInterest:[null,Validators.required],
      expertiseLevel:[null,Validators.required],
      startDate:[null,Validators.required],
      endDate:[null,Validators.required],
      supportHours:[null,[Validators.required, Validators.pattern(this.numberRegEx)]],
      firstName:[null,[Validators.required,Validators.maxLength(65)]],
      lastName:[null,[Validators.required,Validators.maxLength(65)]],
      email:[null,[Validators.required,Validators.maxLength(200),Validators.email]],
      phoneNumber:[null,[Validators.required,Validators.minLength(6),Validators.maxLength(30),Validators.pattern(this.numberRegEx)]],
      organizationName:[null,Validators.required],
    })
  }

   get supportFormControls() {
    return this.supportForm.controls;
  }

  ngOnInit(): void {
    console.log('Hi i am azure support')
    
  }  

  submit(){
    let rawValue = this.supportForm.getRawValue()
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    var createdBy = get_Email.userName;
    rawValue['status'] = "open"
    rawValue['createdBy']= createdBy
    this.util.handleRequest('post','a3s_supportTicket_updateAzureTicket',[],rawValue,null,null,true).then(res=>{
      this.util.route(['/a3s/azureSupport'])
      this.util.success('Azure support ticket submitted successful!')
    },err=>{
      this.util.error('Failed to submit Azure support!')
    })
  }



}