<div class="introduction">
  
       <p class="heading" id="4">Billing Profiles  </p>
       <p class="mb-0" >A billing profile contains a payment method, Bill-to information, and other 
           invoice settings, such as purchase order number and email invoice preference. </p>
           <img src="assets\user-manual\cm-and-optimize\Billing-Profiles.svg" >
           <figcaption class="caption"> Billing profiles</figcaption>
           <p class="heading" style="font-size: 32px;" id="40">Add Billing Profile  </p>
           
           <ul class="mb-0">
               <li>Click(+ Add billing profile)  to initiate the wizard.</li>
           </ul>
           <img class="mb-3" src="assets\user-manual\cm-and-optimize\Add-New-Billing-Profile.svg" >
           <p class="heading"  style="font-size: 32px;" id="41">Delete Billing Profile </p>
          
           <ul >
               <li>Click ellipsis (<img class="ellipis" src="assets\user-manual\Vector.svg" >) in line with the billing profile in the table. </li>
               <li>Select <b>Delete</b> from the drop down. </li>
           </ul>
           <p class="heading"  style="font-size: 32px;" id="42">Export Billing Profile  </p>
           
           <ul>
               <li>Click ellipsis (<img class="ellipis" src="assets\user-manual\Vector.svg" >) in line with the billing profile in the table. </li>
               <li>Select <b>Export</b> CSV from the drop down.</li> 
           </ul>

     
</div>
