import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSetService } from 'src/app/services/data-set.service';

@Component({
    selector: 'app-pop-up',
    templateUrl: './pop-up.component.html',
    styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {

    constructor(public currentModal: NgbActiveModal, private dataset: DataSetService) { }

    ngOnInit(): void {
    }


    primaryAction() {

    }

    secondaryAction() {
        this.dataset.setObservable('userAction', 'cancel');
        this.close();
        // switch (this.type) {
        //   // case 'mcderror': {
        //   //   this.dataset.setObservable('userResponse', 'reuploadMCD');
        //   //   break;
        //   // }
        //   // case 'session': {
        //   //   this.dataset.setObservable('userResponse', 'cancel');
        //   //   break;
        //   // }
        //   default: {
        //     this.dataset.setObservable('userResponse', 'cancel');
        //   }
        // }
    }

    close() {
        this.currentModal.close();
    }
}
