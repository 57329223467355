<div class="introduction">
            
        <p class="heading" id="0">Coverage  </p>
        <ul class="mb-0" >
            <li><b>Not covered:  </b>  Number of subscriptions that not covered under security policies. 
                It indicates that the subscriptions are newly created and not covered under the policies.  
         </li>
            <li><b>Partially covered:  </b> Some number of security policies have been applied to the subscriptions
                out of total subscriptions.   </li>
            <li><b>Fully covered: </b> All available controls are implemented in the subscription. </li>
            
        </ul>
        <img src="assets\user-manual\security\Card(3).svg" >
       

 </div>
  