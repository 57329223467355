<div class="custom-fixed-tables" >
    <table class="table">
      <thead>
        <tr>
          <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('sqlDBData',0)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('sqlDBData',1)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('sqlDBData',2)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('sqlDBData',3)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('sqlDBData',6)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Tier</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('sqlDBData',5)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Server Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('sqlDBData',4)"></div></th>
          <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('sqlDBData',7)"></div></th>
          <th style="border-left: none;" class="wid-75"></th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let row of data">
            <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(sqlDatabaseDetailsTemplate,row,'sqlDatabasePopup')">{{ row[0] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
            <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
            <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
            <td isEllipsisActive style="width: auto;" class="status-tag ml-12 mt-2 mb-2 mr-1" [ngClass]="getStatusClass(row[6])">{{row[6] | titlecase | displayTxt}}</td>
            <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
            <td isEllipsisActive>{{ getTags(row[7]) }}</td>
            <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('computeStorage',row)">
                      <p class="delete-popup-text">Compute + storage</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('copyDB',row)">
                      <p class="delete-popup-text">Copy</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('restore',row)">
                      <p class="delete-popup-text">Restore</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('firewallSetting',row)">
                        <p class="delete-popup-text">Firewall settings</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteSqlDatabaseData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
        </tr>
        <div class="col-md-12 mt-3" *ngIf="!data?.length">
          <p class="content-14 blur-text">No data Found</p>
        </div>
      </tbody>
  </table>
  </div>
  <ng-container *ngIf="showSidesheet">
     <!-- Compute + Storage -->
     <ng-container *ngIf="currentTemplate == 'computeStorage'">
      <div class="sidebar-right single add-alert">
          <div class="header">
              <div class="d-flex">
            <h4 class="threeFour-width">Compute + Storage<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></h4>
                <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
      
          <div class="body scroll-y">
              <form [formGroup]="currentForm">
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600">Service and Compute Tier</label>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Service tier<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The vCore model provides a choice between a provisioned compute tier 
                    and a server-less compute tier. Whereas, DTU-based model provides bundled 
                    compute and storage packages balanced for common workloads." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <ng-select [items]="serviceTier" groupBy="type" (change)="setServiceTier($event)" bindLabel="name" bindValue="id" name="serviceTier" [clearable]="false" id="serviceTier" formControlName="serviceTier">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div id="item-{{index}}" name="item-{{index}}"class="form-check-inline" [class.disabled-icon]="(sqlDatabaseData?.sku?.tier=='Hyperscale' && (item.id=='BusinessCritical' || item.type=='DTU-based purchasing model')) || (currentDataSize>5 && item.id=='Basic')">{{item.name}}</div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='Hyperscale'">
                  <div class="label-div d-flex blueBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;">
                      You can move to General Purpose tier up until 45 days after updating to Hyperscale.</label>
                  </div>  
                </div>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='Basic'">
                  <label class="flex-custom no-wrap bold-600">DTUs</label>
                  <label class="flex-custom no-wrap">5 (Basic)</label>
                  <!-- {{sqlDatabaseData?.sku?.capacity}} -->
                </div>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='Standard' || currentForm.get('serviceTier')?.value=='Premium'">
                  <label class="flex-custom no-wrap">DTUs</label>
                  <ng-select [items]="dtuList"  bindLabel="name" bindValue="id" name="dtu" (change)="setDataSize($event)" [clearable]="false" id="dtu" formControlName="dtu"></ng-select>
                </div>
                <ng-container *ngIf="currentForm.get('serviceTier')?.value=='GeneralPurpose' || currentForm.get('serviceTier')?.value=='Hyperscale' || currentForm.get('serviceTier')?.value=='BusinessCritical'">
                  <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='GeneralPurpose'">
                    <label class="flex-custom no-wrap">Compute tier<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provisioned: Compute resources are pre-allocated. Billed per hour based on vCores configured​. 
                      Serverless: Compute resources are auto-scaled​. Billed per second based on vCores used​." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="computeTier"  class="form-check-inline" id='provisioned' (change)="setRadioBtn('yes','computeStorage','computeTier')" [checked]="currentForm.get('computeTier')?.value ==true">
                          <label class="value" for="provisioned">Provisioned</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="computeTier" class="form-check-inline" id='serverless' (change)="setRadioBtn('no','computeStorage','computeTier')" [checked]="currentForm.get('computeTier')?.value == false">
                          <label class="value" for="serverless">Serverless</label>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600">Compute Hardware</label>
                  </div>
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Hardware configuration<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Based on the workload requirements, select the hardware configuration." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> </label>
                    <label class="flex-custom no-wrap bold-600">{{currentForm.get('hardwareConfig').value}}</label>
                    <label class="flex-custom no-wrap">up to {{currentForm.get('maxVCore').value}} vCores, up to {{currentForm.get('maxMemory').value}} memory</label>
                    <span class="action-btn" (click)="openHardwareConfigPopup()">Change configuration</span>
                  </div>
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">{{!currentForm.get('computeTier').value ? 'Max vCores' : 'vCores'}}</label>
                    <ng-select [items]="vCoresList"  bindLabel="name" bindValue="id" name="vCores" (change)="setMinVCore($event)" [clearable]="false" id="vCores" formControlName="vCores"></ng-select>
                  </div>
                  <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='Hyperscale'">
                    <label class="flex-custom no-wrap">High-Availability Secondary Replicas<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Increasing the number of High Availability replicas improves availability" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> </label>
                    <ng-select [items]="replicasList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="replicas" [clearable]="false" id="replicas" formControlName="replicas"></ng-select>
                  </div>
                  <ng-container *ngIf="!currentForm.get('computeTier')?.value && currentForm.get('serviceTier')?.value=='GeneralPurpose'">
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Min vCores</label>
                      <ng-select [items]="minVCoresList"  bindLabel="name" bindValue="id" name="minVCores" (change)="saveDisable=true" [clearable]="false" id="minVCores" formControlName="minVCores"></ng-select>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Min memory</label>
                      <label class="flex-custom no-wrap">2.02 GB</label>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Max memory</label>
                      <label class="flex-custom no-wrap">3 GB</label>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Auto-pause Delay</label>
                      <div class="checkbox-cs"> 
                        <input type="checkbox" name="autoPauseDelay" (change)="saveDisable=true"  class="form-check-inline"  id='autoPauseDelay' formControlName="autoPauseDelay">
                        <label class="value" for="autoPauseDelay">Enable auto-pause<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The database automatically pauses if it is inactive for the time period 
                          specified here, and automatically resumes when database activity recurs. 
                          Alternatively, auto-pausing can be disabled." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      </div>
                   </div>
                   <div class="mb-3" *ngIf="currentForm.get('autoPauseDelay')?.value">
                    <div class="flex-custom">
                      <div class="comm-mb mr-3" style="width:75px;">
                        <label class="flex-custom no-wrap ">Days</label>
                        <ng-select [items]="days"  bindLabel="name" bindValue="name" name="days" (change)="saveDisable=true" [clearable]="false" id="days" formControlName="days"></ng-select>
                      </div>
                      <div class="comm-mb mr-3" style="width:75px;">
                        <label class="flex-custom no-wrap ">Hours</label>
                        <ng-select [items]="hours"  bindLabel="name" bindValue="name" name="hrs" (change)="saveDisable=true" [clearable]="false" id="hrs" formControlName="hrs"></ng-select>
                      </div>
                      <div class="comm-mb mr-3" style="width:75px;">
                        <label class="flex-custom no-wrap ">Minutes</label>
                        <ng-select [items]="minutes" bindLabel="name" bindValue="name" name="mins" [clearable]="false" (change)="saveDisable=true" id="mins" formControlName="mins"></ng-select>
                      </div>
                    </div>
                 </div>
                  </ng-container>
                  <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value!='Hyperscale'">
                    <label class="flex-custom no-wrap">Data max size (GB)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The maximum data storage capacity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> </label>
                    <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.InValidSize|| inputError?.InvalidSizeRange" id="dataMaxSize" name="dataMaxSize" formControlName="dataMaxSize" (keyup)="dataSizeValidation($event)"/>
                    <!-- <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p> -->
                    <p class="text-danger" *ngIf="inputError?.InValidSize">The value must be a valid number.</p>
                    <p class="text-danger" *ngIf="inputError?.InvalidSizeRange">The value must be between 1 and {{dataSizeRange}}</p>
                  </div>
                  <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value!='Hyperscale'">
                    <label class="flex-custom no-wrap">Log space allocated</label>
                    <label class="flex-custom no-wrap">1 TB</label>
                  </div>
                </ng-container>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='ElasticPool'">
                  <label class="flex-custom no-wrap">Data max size (GB)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The maximum data storage capacity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> </label>
                  <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.InValidSize|| inputError?.InvalidSizeRange" id="dataMaxSize" name="dataMaxSize" formControlName="dataMaxSize" (keyup)="dataSizeValidation($event)"/>
                  <!-- <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p> -->
                  <p class="text-danger" *ngIf="inputError?.InValidSize">The value must be a valid number.</p>
                  <p class="text-danger" *ngIf="inputError?.InvalidSizeRange">The value must be between 1 and {{dataSizeRange}}</p>
                </div>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='Basic' || currentForm.get('serviceTier')?.value=='Standard' || currentForm.get('serviceTier')?.value=='Premium'">
                  <label class="flex-custom no-wrap">Data max size (GB)</label>
                  <ng-select [items]="dataSizeList"  bindLabel="name" bindValue="name" (change)="saveDisable=true" name="dataMaxSize" [clearable]="false" id="dataMaxSize" formControlName="dataMaxSize"></ng-select>
                </div>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='BusinessCritical' || currentForm.get('serviceTier')?.value=='Premium'">
                  <label class="flex-custom no-wrap">Read scale-out</label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="readScaleOut"  class="form-check-inline" id='enableScaleOut' (change)="setRadioBtn('yes','computeStorage','readScaleOut')" [checked]="currentForm.get('readScaleOut')?.value ==true">
                        <label class="value" for="enableScaleOut">Enabled  </label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="readScaleOut" class="form-check-inline" id='disableScaleOut' (change)="setRadioBtn('no','computeStorage','readScaleOut')" [checked]="currentForm.get('readScaleOut')?.value == false">
                        <label class="value" for="disableScaleOut">Disabled  </label>
                    </div>
                  </div>
                </div>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value!='Hyperscale' && currentForm.get('serviceTier')?.value!='Basic' && currentForm.get('serviceTier')?.value!='Standard' && currentForm.get('serviceTier')?.value!='ElasticPool'">
                  <label class="no-wrap">Would you like to make this database zone redundant?<span class="ml-1"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provides enhanced availability by spreading 
                    replicas across availability zones within one region." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="databaseZone"  class="form-check-inline" id='databaseZoneYes' (change)="setRadioBtn('yes','computeStorage','databaseZone')" [checked]="currentForm.get('databaseZone')?.value ==true">
                        <label class="value" for="databaseZoneYes">Yes</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="databaseZone" class="form-check-inline" id='databaseZoneNo' (change)="setRadioBtn('no','computeStorage','databaseZone')" [checked]="currentForm.get('databaseZone')?.value == false">
                        <label class="value" for="databaseZoneNo">No</label>
                    </div>
                  </div>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('databaseZone')?.value">
                  <div class="label-div d-flex warningBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                      For General Purpose tier, zone redundancy incurs an additional cost. </label>
                   </div>  
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Backup storage redundancy<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Geo-redundant and locally-redundant storage is available in all regions. 
                    Zone-redundant storage is available in regions with availability zones." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div [class.disabled-icon]="currentForm.get('serviceTier')?.value=='Hyperscale'">
                    <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="backupStorage"  class="form-check-inline" id='local' (change)="setRadioBtn('Local','computeStorage','backupStorage')" [checked]="currentForm.get('backupStorage')?.value =='Local'">
                        <label class="value" for="local">Locally-redundant backup storage</label>
                    </div>
                  </div>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="backupStorage" class="form-check-inline" id='zone' (change)="setRadioBtn('Zone','computeStorage','backupStorage')" [checked]="currentForm.get('backupStorage')?.value == 'Zone'">
                        <label class="value" for="zone">Zone-redundant backup storage</label>
                    </div>
                  </div>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="backupStorage" class="form-check-inline" id='geo' (change)="setRadioBtn('Geo','computeStorage','backupStorage')" [checked]="currentForm.get('backupStorage')?.value == 'Geo'">
                        <label class="value" for="geo">Geo-redundant backup storage</label>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('backupStorage')?.value == 'Geo'">
                  <div class="label-div d-flex warningBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                      Selected value for backup storage redundancy is Geo-redundant backup storage. Note that database backups will be geo-replicated to the paired region.</label>
                   </div>  
                </div>
                <!-- <div class="comm-mb" *ngIf="currentDataSize>currentForm.get('dataMaxSize')?.value">
                  <div class="label-div d-flex redAlertBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/red-info.svg" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #BB202F;">
                      The storage size of your database can not be smaller than its current size. Please select a larger storage size.</label>
                   </div>  
                </div> -->
              </form>
          </div>

          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('computeStorage')" (click)="saveSQLDBData('computeStorage')">Save</button>
          </div>
      </div>
    </ng-container>
    <!-- Copy -->
    <ng-container *ngIf="currentTemplate == 'copyDB'">
      <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
            <h4 class="threeFour-width">Copy Database<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></h4>
                <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
      
          <div class="body scroll-y">
              <form [formGroup]="subSideSheetForm">
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Subscription<span class="mandatory">*</span></label>
                  <ng-select [items]="subscriptionList" class="disabled"  bindLabel="name" bindValue="id" name="subscription" [clearable]="false" id="subscription" formControlName="subscription"></ng-select>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Resource group<span class="mandatory">*</span></label>
                  <ng-select [items]="resourceGroupsList" class="disabled"  bindLabel="name" bindValue="id" name="rg" [clearable]="false" id="rg" formControlName="rg"></ng-select>
                </div>
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600">Database Details</label>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Database name<span class="mandatory">*</span></label>
                  <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.dbNameEmpty|| inputError?.dbNameLen ||inputError?.dbNameInvalid ||inputError?.dbNameDup" id="dbName" name="dbName" formControlName="dbName" (keyup)="validDBName($event,128,'db')"/>
                  <p class="text-danger" *ngIf="inputError?.dbNameEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.dbNameLen">The value has a length of at most 128.</p>
                  <p class="text-danger" *ngIf="inputError?.dbNameInvalid">Your database name can't end with '.' or ' ', can't contain '<,>,*,%,&,:,\,/,?' or control characters.</p>
                  <p class="text-danger" *ngIf="inputError?.dbNameDup">No database with the same name exists in the server</p>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Server<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The connection string and credentials 
                    will be based on the server." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control full-width disabled-input" type="text" autocomplete="off"  id="serverName" name="serverName" formControlName="serverName"/>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Want to use SQL elastic pool?<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Elastic pools provide a simple and cost effective solution for 
                    managing the performance of multiple databases within a fixed budget. An elastic pool provides compute (eDTUs) and storage
                     resources that are shared between all the databases it contains. Databases within a pool only use the resources they need, 
                     when they need them, within configurable limits. The price of a pool is based only on the amount of resources configured and 
                     is independent of the number of databases it contains." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="sqlElasticPool"  class="form-check-inline" id='elasticPoolYes' (change)="setRadioBtn('yes','copyDB','sqlElasticPool')" [checked]="subSideSheetForm.get('sqlElasticPool')?.value ==true">
                        <label class="value" for="elasticPoolYes">Yes</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="sqlElasticPool" class="form-check-inline" id='elasticPoolNo' (change)="setRadioBtn('no','copyDB','sqlElasticPool')" [checked]="subSideSheetForm.get('sqlElasticPool')?.value == false">
                        <label class="value" for="elasticPoolNo">No</label>
                    </div>
                  </div>
                </div>
                <div class="mb-3" *ngIf="subSideSheetForm.get('sqlElasticPool')?.value">
                  <label class="flex-custom no-wrap">Elastic pool<span class="mandatory">*</span></label>
                  <ng-select [items]="elasticPoolList"  bindLabel="name" bindValue="id" name="elasticPool" [clearable]="false" id="elasticPool" formControlName="elasticPool"></ng-select>
                </div>
                <div class="mb-3" *ngIf="!subSideSheetForm.get('sqlElasticPool')?.value">
                  <label class="flex-custom no-wrap bold-600">Compute + storage<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a SQL Service tier and performance 
                    level that best fits your application needs." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <label class="flex-custom no-wrap ">{{subSideSheetForm.get('hardwareConfig').value}},{{subSideSheetForm.get('vCore').value}} vCores,{{subSideSheetForm.get('memory').value}} GB Storage</label>
                    <label class="flex-custom no-wrap">zone redundant disabled</label>
                    <span class="action-btn" (click)="openSubSideSheet('configureDB')">Configure database</span>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Backup storage redundancy<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Geo-redundant and locally-redundant storage is available in all regions. 
                    Zone-redundant storage is available in regions with availability zones." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="backupStorage"  class="form-check-inline" id='local' (change)="setRadioBtn('Local','copyDB','backupStorage')" [checked]="subSideSheetForm.get('backupStorage')?.value =='Local'">
                        <label class="value" for="local">Locally-redundant backup storage</label>
                    </div>
                  </div>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="backupStorage" class="form-check-inline" id='zone' (change)="setRadioBtn('Zone','copyDB','backupStorage')" [checked]="subSideSheetForm.get('backupStorage')?.value == 'Zone'">
                        <label class="value" for="zone">Zone-redundant backup storage</label>
                    </div>
                  </div>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="backupStorage" class="form-check-inline" id='geo' (change)="setRadioBtn('Geo','copyDB','backupStorage')" [checked]="subSideSheetForm.get('backupStorage')?.value == 'Geo'">
                        <label class="value" for="geo">Geo-redundant backup storage</label>
                    </div>
                  </div>
                </div>
                <div class="comm-mb" *ngIf="subSideSheetForm.get('backupStorage')?.value == 'Geo'">
                  <div class="label-div d-flex warningBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                      Selected value for backup storage redundancy is Geo-redundant backup storage. Note that database backups will be geo-replicated to the paired region.</label>
                   </div>  
                </div>
              </form>
          </div>

          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable|| subSideSheetForm.invalid || invalidForm('copyDB')" (click)="saveSQLDBData('copyDB')">Save</button>
          </div>
      </div>

      <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
              <h4>Configure<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
    
        <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-2">
                <label class="flex-custom no-wrap bold-600">Service and Compute Tier</label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Service tier<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The vCore model provides a choice between a provisioned compute tier 
                  and a server-less compute tier. Whereas, DTU-based model provides bundled 
                  compute and storage packages balanced for common workloads." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="serviceTier" groupBy="type" (change)="setServiceTier($event);saveBtnEnable=true" bindLabel="name" bindValue="id" name="serviceTier" [clearable]="false" id="serviceTier" formControlName="serviceTier">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div id="item-{{index}}" name="item-{{index}}"class="form-check-inline" [class.disabled-icon]="item.id!=sqlDatabaseData?.sku.tier"> {{item.name}}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='GeneralPurpose'">
                <label class="flex-custom no-wrap">Compute tier<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provisioned: Compute resources are pre-allocated. Billed per hour based on vCores configured​. 
                  Serverless: Compute resources are auto-scaled​. Billed per second based on vCores used​." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="computeTier"  class="form-check-inline" id='provisioned' (change)="setRadioBtn('yes','copyDB','computeTier')" [checked]="currentForm.get('computeTier')?.value ==true">
                      <label class="value" for="provisioned">Provisioned</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="computeTier" class="form-check-inline" id='serverless' (change)="setRadioBtn('no','copyDB','computeTier')" [checked]="currentForm.get('computeTier')?.value == false">
                      <label class="value" for="serverless">Serverless</label>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <label class="flex-custom no-wrap bold-600">Compute Hardware</label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Hardware configuration<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Based on the workload requirements, select the hardware configuration." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> </label>
                <label class="flex-custom no-wrap bold-600">{{currentForm.get('hardwareConfig').value}}</label>
                <label class="flex-custom no-wrap">up to {{currentForm.get('maxVCore').value}} vCores, up to {{currentForm.get('maxMemory').value}} memory</label>
                <span class="action-btn" (click)="openHardwareConfigPopup()">Change configuration</span>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">vCores</label>
                <ng-select [items]="vCoresList"  bindLabel="name" bindValue="id" name="vCores" (change)="saveBtnEnable=true" [clearable]="false" id="vCores" formControlName="vCores"></ng-select>
              </div>
              <div class="mb-2">
                <label class="flex-custom no-wrap bold-600">Data max size <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The maximum data storage capacity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> </label>
                <label class="flex-custom no-wrap">{{currentForm.get('dataMaxSize').value}} GB</label>
              </div>
              <div class="mb-2">
                <label class="flex-custom no-wrap bold-600">Log space allocated</label>
                <label class="flex-custom no-wrap">1 TB</label>
              </div>
 
            </form>
        </div>
    
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="!saveBtnEnable" (click)="saveConfigureDB('copyDB')">Save</button>
        </div>
      </div>
    </ng-container>
    <!-- Firewall Setting -->
    <ng-container *ngIf="currentTemplate == 'firewallSetting'">
      <div class="sidebar-right single width-881" [class.sub-sheet]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Firewall Settings<br> <span class="blur-text">{{selectedRow[4]}} | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-3">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="publicNetworkAccess" class="form-check-inline" (change)="denyPublicAccess($event)" id='publicNetworkAccess' formControlName="publicNetworkAccess">
                  <label class="value" for="publicNetworkAccess">Deny public network access</label>
                </div>
             </div>
             <div class="mb-3" *ngIf="currentForm.get('publicNetworkAccess').value">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  You have chosen to deny all public connectivity to the server via firewall rules. To ensure clients can still connect, we recommend creating
                  a private endpoint.</label>
              </div>  
            </div>
             <div class="mb-3">
              <label class="flex-custom no-wrap" >Minimum TLS Version<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This sets the Minimal TLS Version property for all SQL Database and SQL 
                Data Warehouse databases associated with the server. Any login attempts 
                from clients using a TLS version less than the Minimal TLS Version shall be rejected." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom ">
                  <div class="checkbox-cs">
                      <input type="radio" name="minTLSversion"  class="form-check-inline" id='1.0' value="1.0" (change)="setRadioBtn('1.0','firewallSetting','minTLSversion')" [checked]="currentForm.get('minTLSversion')?.value == '1.0'">
                      <label class="value" for="1.0">1.0</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="minTLSversion" class="form-check-inline" id='1.1' value="1.1" (change)="setRadioBtn('1.1','firewallSetting','minTLSversion')" [checked]="currentForm.get('minTLSversion')?.value == '1.1'">
                      <label class="value" for="1.1">1.1</label>
                  </div>
                  <div class="checkbox-cs">
                    <input type="radio" name="minTLSversion" class="form-check-inline" id='1.2' value="1.2" (change)="setRadioBtn('1.2','firewallSetting','minTLSversion')" [checked]="currentForm.get('minTLSversion')?.value == '1.2'">
                    <label class="value" for="1.2">1.2</label>
                </div>
              </div>
             </div> 
             <div class="mb-3">
              <label class="flex-custom no-wrap" >Connection Policy</label>
              <div class="flex-custom ">
                  <div class="checkbox-cs">
                      <input type="radio" name="connectionPolicy"  class="form-check-inline" id='Default' value="Default" (change)="setRadioBtn('Default','firewallSetting','connectionPolicy')" [checked]="currentForm.get('connectionPolicy')?.value == 'Default'">
                      <label class="value" for="Default">Default</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="connectionPolicy" class="form-check-inline" id='Proxy' value="Proxy" (change)="setRadioBtn('Proxy','firewallSetting','connectionPolicy')" [checked]="currentForm.get('connectionPolicy')?.value == 'Proxy'">
                      <label class="value" for="Proxy">Proxy</label>
                  </div>
                  <div class="checkbox-cs">
                    <input type="radio" name="connectionPolicy" class="form-check-inline" id='Redirect' value="Redirect" (change)="setRadioBtn('Redirect','firewallSetting','connectionPolicy')" [checked]="currentForm.get('connectionPolicy')?.value == 'Redirect'">
                    <label class="value" for="Redirect">Redirect</label>
                </div>
              </div>
             </div> 
             <ng-container *ngIf="!currentForm.get('publicNetworkAccess').value">
              <div class="mb-3">
                <label class="flex-custom no-wrap" >Allow Azure services and resources to access this server<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Connections from the IPs specified below provide
                  access to all of the databases in hariserver5." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <div class="flex-custom ">
                    <div class="checkbox-cs">
                        <input type="radio" name="allowAzure"  class="form-check-inline" id='enable' value="enable" (change)="setRadioBtn('enable','firewallSetting','allowAzure')" [checked]="currentForm.get('allowAzure')?.value == true">
                        <label class="value" for="enable">Yes</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="allowAzure" class="form-check-inline" id='disable' value="disable" (change)="setRadioBtn('disable','firewallSetting','allowAzure')" [checked]="currentForm.get('allowAzure')?.value == false">
                        <label class="value" for="disable">No</label>
                    </div>
                </div>
             </div> 
             <div class="mb-3">
              <label class="flex-custom no-wrap bold-600">Client IP address</label>
              <label class="flex-custom no-wrap">156.212.246.129</label>
            </div>
            <div class="mb-1">
              <label class="flex-custom no-wrap bold-600">Firewall rules</label>
            </div>
            <div class="mb-3">
              <ng-container formArrayName="firewallRuleArray" *ngFor="let tanch of currentForm.get('firewallRuleArray')['controls']; let i = index;">
                <div class="mb-2 ">
                  <div class="notify-wrap mb-1" [formGroupName]="i">
                    <div class="w-50">
                      <label class="flex-custom nowrap">Rule name</label>
                      <input class="form-control full-width" [class.disabled-input]="currentForm.get('publicNetworkAccess').value" type="text" autocomplete="off" id="name_{{i}}" name="name_{{i}}" formControlName="name" (keyup)="ruleNameValidation($event,i)"/>
                    </div>
                    <div class="w-50 ml-3">
                      <label class="flex-custom nowrap">Start IP</label>
                      <input class="form-control full-width" [class.disabled-input]="currentForm.get('publicNetworkAccess').value" type="text" autocomplete="off" id="startIP_{{i}}" name="startIP_{{i}}" formControlName="startIP" (keyup)="ipValidation($event,i)"/>
                    </div>
                    <div class="w-50 ml-3">
                      <label class="flex-custom nowrap">End IP</label>
                      <input class="form-control full-width" [class.disabled-input]="currentForm.get('publicNetworkAccess').value" type="text" autocomplete="off" id="endIP_{{i}}" name="endIP_{{i}}" formControlName="endIP" (keyup)="ipValidation($event,i)"/>
                    </div>
                    <ng-container>
                      <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeRule(tanch,i)">
                    </ng-container>
                  </div>
                  <!--*ngIf="firewallRuleArrayList?.length!=1"
                     <span class="action-btn" [class.disabled-icon]="currentForm.get('publicNetworkAccess').value" (click)="addFirewallRuleArray()" *ngIf="i == firewallRuleArrayList?.length-1">Add firewall rule</span> -->
                </div>
              </ng-container>
              <span class="action-btn" [class.disabled-icon]="currentForm.get('publicNetworkAccess').value" (click)="addFirewallRuleArray()">Add firewall rule</span>
              <p class="text-danger" *ngIf="inputError?.invalidRuleName">The firewall rule name cannot be empty, it cannot contain a slash (/) or a backslash (\), 
                can't end with '.', and can't contain '<,>,*,%,&,:,\,/,?'. Additionally,
                the firewall rule name cannot exceed 128 characters.</p> 
              <p class="text-danger" *ngIf="inputError?.invalidIP">IP address must be a valid IPv4 address.</p> 
       
              <!-- *ngIf="i == firewallRuleArrayList?.length-1" -->
            </div> 
            <div class="mb-2">
              <label class="flex-custom no-wrap bold-600">Virtual Networks</label>
            </div>
            <div class="mb-2">
              <div class="flex-custom no-wrap">
                <button class="btn-btn-secondary" (click)="openSubSideSheet('addVNetRule')">
                  <img src="/assets/governance/add-btn.svg" alt="">
                  Add existing virtual network
               </button>
               <!-- <button class="btn-btn-secondary ml-12" (click)="openSubSideSheet('createVNet')">
                <img src="/assets/governance/add-btn.svg" alt="">
                Create new virtual network
               </button> -->
              </div>
              <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th> <div class="flex-custom no-wrap"><span>Rule Name</span></div></th>
                          <th> <div class="flex-custom no-wrap"><span>Virtual Network</span></div></th>
                          <th> <div class="flex-custom no-wrap"><span>Subnet</span></div></th>
                          <th> <div class="flex-custom no-wrap"><span>Address Range</span></div></th>
                          <th> <div class="flex-custom no-wrap"><span>Endpoint Status</span></div></th>
                          <th> <div class="flex-custom no-wrap"><span>Resource Group</span></div></th>
                          <th> <div class="flex-custom no-wrap"><span>Subscription</span></div></th>
                          <th style="border-right: none;"> <div class="flex-custom no-wrap"><span>State</span></div></th>
                          <th style="border-left: none;"></th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let list of sqlServerVNEetRule;let i=index">
                            <tr>
                                <td isEllipsisActive>{{list?.name | displayTxt}}</td>
                                <td isEllipsisActive>{{list?.properties?.virtualNetworkSubnetId.split('/')[8] | displayTxt}}</td>
                                <td isEllipsisActive>{{list?.properties?.virtualNetworkSubnetId.split('/')[10] | displayTxt}}</td>
                                <td isEllipsisActive>{{getAddressSpace(list)}}</td>
                                <td isEllipsisActive>{{getEndpointStatus('existing',list)}}</td>
                                <td isEllipsisActive>{{list?.properties?.virtualNetworkSubnetId.split('/')[4] | displayTxt}}</td>
                                <td isEllipsisActive>{{getProjectName(list?.properties?.virtualNetworkSubnetId.split('/')[2]) | displayTxt}}</td>
                                <td isEllipsisActive>{{list?.properties?.state | displayTxt}}</td>
                                <td class="text-right">
                                  <span>
                                    <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                    <mat-menu #action2="matMenu" class="delete-popup" >
                                      <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteVNETRule',i)">
                                          <p class="delete-popup-text">Delete</p>
                                      </div>
                                    </mat-menu>
                                  </span>              
                                </td>
                                <!-- <td><img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeVNETRule(list,i)"></td> -->
                            </tr>
                        </ng-container>
                        <div class="col-md-12 mt-3" *ngIf="!sqlServerVNEetRule?.length">
                          <p class="content-14 blur-text">No vnet rules for this server.</p>
                        </div>
                      </tbody>
                </table>
              </div>
              </div>
             </ng-container>
            <div class="mb-3">
              <label class="flex-custom no-wrap bold-600">Outbound Networking<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Restrict network access to a specific set of resources by 
                supplying their fully-qualified domain names." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            </div>
            <div class="mb-0">
              <label class="flex-custom no-wrap bold-600">Restrict outbound networking</label>
              <label class="flex-custom no-wrap">Restrictions {{sqlServerData.properties.restrictOutboundNetworkAccess | lowercase}}</label>
              <span class="action-btn" (click)="openSubSideSheet('outboundNetworking')">Configure outbound networking restrictions</span>
            </div>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || invalidForm('firewallSetting')"(click)="saveSQLDBData('firewallSetting','firewallRule')">Save</button>
          </div>
        
      </div>

      <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet && currentSubSheet=='outboundNetworking'">
        <div class="header">
            <div class="d-flex">
              <h4>Restrict Outbound Networking<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetSubSideSheetForm()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
    
        <div class="body scroll-y">
            <form [formGroup]="subSideSheetForm">
              <div class="mb-3">
                <div class="label-div d-flex blueBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;">
                    Only resources and applications at the fully-qualified domain names listed below will be allowed to access this server when outbound networking restrictions are enabled. When disabled, the list of domains below will be retained, but restrictions will not apply.</label>
                </div>  
              </div>
              <div class="mb-3">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="restrictOutbound " class="form-check-inline"  id='restrictOutbound' (change)="saveBtnEnable=true" formControlName="restrictOutbound">
                  <label class="value" for="restrictOutbound">Restrict outbound networking</label>
                </div>
             </div>
             <!-- <div class="mb-2">
              <button class="btn-btn-secondary" #anchor1 (click)="showKendoPopup = true">
                <img src="/assets/governance/add-btn.svg" alt="">
                Add domain
              </button>
             </div>
              <kendo-popup [anchor]="anchor1" (anchorViewportLeave)="showKendoPopup = false" *ngIf="showKendoPopup" id="domainName">
                  <div class='content kendo-content resourceGroup'>
                    <div class="rgPopup-form mb-3">
                        <label for="domainName" class="">Fully-qualified domain name<span class="mandatory">*</span></label>
                        <div>
                          <input type="text" class="form-control"  id="domainName" autocomplete="off" [clearable]="false" placeholder="" name="domainName" formControlName="domainName" >
                        </div>              
                    </div>
                    <div class="d-flex">
                        <button class="btn btn-btn-primary mr-2" [class.disabled]="!subSideSheetForm.get('domainName').value" (click)="pushDomainName();showKendoPopup=false">Ok</button>
                        <button class="btn btn-outline" (click)="showKendoPopup=false">Cancel</button>
                    </div>
                  </div>
              </kendo-popup>
              <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th style="border-right: none;"> <div class="flex-custom no-wrap"><span>Allowed Fully-qualified Domain Names</span></div></th>
                          <th style="border-left: none;" class="wid-75"></th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let list of domainNameList;let i=index">
                            <tr>
                                <td isEllipsisActive>{{list?.name | displayTxt}}</td>
                                <td><img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer" (click)="action(list,'removeDomainName')"></td>
                            </tr>
                        </ng-container>
                      </tbody>
                </table>
            </div> -->
            </form>
        </div>
    
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="!saveBtnEnable" (click)="saveSQLDBData('firewallSetting','outboundNetworking')">Create</button>
        </div>
      </div>

      <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet && currentSubSheet=='addVNetRule'">
        <div class="header">
            <div class="d-flex">
              <h4>Create/Update Vnet Rule<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetSubSideSheetForm()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
    
        <div class="body scroll-y">
            <form [formGroup]="subSideSheetForm">
              <div class="mb-3">
                <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.vnetRuleNameEmpty|| inputError?.vnetRuleNameLen ||inputError?.vnetRuleNameInvalid" id="ruleName" name="ruleName" formControlName="ruleName" (keyup)="validVNETRuleName($event,64,'vnetRule')"/>
                <p class="text-danger" *ngIf="inputError?.vnetRuleNameEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.vnetRuleNameLen">The value must have a length of at least 2 and at most 64.</p>
                <p class="text-danger" *ngIf="inputError?.vnetRuleNameInvalid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Subscription<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Please choose which subscription you want to use for vnet subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="subscriptionList" class="disabled"  bindLabel="name" bindValue="id" name="sub" [clearable]="false" id="sub" formControlName="sub"></ng-select>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Virtual network<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Please choose which virtual network should be used in rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="vNetList" (change)="getSubnetList($event)"  bindLabel="name" bindValue="id" name="vNet" [clearable]="false" id="vNet" formControlName="vNet"></ng-select>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Subnet name / Address prefix<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Please choose which subnet should be used in rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="subnetList" (change)="getEndpoint($event)"  bindLabel="name" bindValue="id" name="subnet" [clearable]="false" id="subnet" formControlName="subnet"></ng-select>
              </div> 
              <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th> <div class="flex-custom no-wrap"><span>Virtual Network</span></div></th>
                          <th> <div class="flex-custom no-wrap"><span>Service Endpoint Status</span></div></th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container>
                            <tr *ngIf="vNetList?.length!=0">
                                <td isEllipsisActive style="max-width: 118px;">{{subSideSheetForm.get('subnet').value.split('/')[8]}}/{{subSideSheetForm.get('subnet').value.split('/')[10]}}</td>
                                     <td isEllipsisActive>{{endpointStatus}}</td>
                                <!-- <td isEllipsisActive>{{getEndpointStatus('create')}}</td> -->
                            </tr>
                            <div class="col-md-12 mt-3" *ngIf="!vNetList?.length">
                              <p class="content-14 blur-text">No data Found</p>
                            </div>
                        </ng-container>
                      </tbody>
                </table>
            </div>
              <div class="mb-3" *ngIf="vNetList.length && endpointStatus!='Enabled'">
                <div class="flex-custom no-wrap checkbox-cs"> 
                  <input type="checkbox" name="ignoreEndpoint " (change)="saveBtnEnable=true" class="form-check-inline mb-3"  id='ignoreEndpoint' formControlName="ignoreEndpoint">
                  <label class="value" style="white-space:initial;" for="ignoreEndpoint">Ignore Missing Microsoft.Sql Service Endpoint<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the subnet will not be able to connect to the SQL 
                    server until Microsoft.Sql service endpoint is added to the subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
             </div>
              <div class="mb-3" *ngIf="vNetList.length && !subSideSheetForm.get('ignoreEndpoint').value && endpointStatus!='Enabled'">
                <div class="label-div d-flex blueBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;">
                    Selected subnet does not have service endpoint enabled for Microsoft.Sql. Enabling access may take up to 15 minutes to complete.</label>
                </div>  
              </div>
            </form>
        </div>
    
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="!saveBtnEnable || invalidForm('addVNetRule')" (click)="saveSQLDBData('firewallSetting','addVNetRule')">Create</button>
        </div>
      </div>

      <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet && currentSubSheet=='createVNet'">
        <div class="header">
            <div class="d-flex">
              <h4>Create Virtual Network<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetSubSideSheetForm()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
    
        <div class="body scroll-y">
            <form [formGroup]="subSideSheetForm">
              <div class="mb-3">
                <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.sharedKeyEmpty|| inputError?.sharedKeyMaxLen ||inputError?.sharedKeyASCII" id="vNetName" name="vNetName" formControlName="vNetName" (keyup)="sharedKeyValidation($event)"/>
                <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.sharedKeyMaxLen">A shared key must contain between 1 to 128 characters. </p>
                <p class="text-danger" *ngIf="inputError?.sharedKeyASCII">Invalid shared key configured. The shared key should be a combination of printable ASCII characters and should be between 1 to 128 in length. </p>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Address space<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The virtual network's address range in CIDR notation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.sharedKeyEmpty|| inputError?.sharedKeyMaxLen ||inputError?.sharedKeyASCII" id="addressSpace" name="addressSpace" formControlName="addressSpace" (keyup)="sharedKeyValidation($event)"/>
                <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.sharedKeyMaxLen">A shared key must contain between 1 to 128 characters. </p>
                <p class="text-danger" *ngIf="inputError?.sharedKeyASCII">Invalid shared key configured. The shared key should be a combination of printable ASCII characters and should be between 1 to 128 in length. </p>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Subscription<span class="mandatory">*</span></label>
                <ng-select [items]="subscriptionList"   bindLabel="name" bindValue="id" name="sub" [clearable]="false" id="sub" formControlName="sub"></ng-select>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Resource group<span class="mandatory">*</span></label>
                <ng-select [items]="resourceGroupsList"   bindLabel="name" bindValue="id" name="rg" [clearable]="false" id="rg" formControlName="rg"></ng-select>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Location<span class="mandatory">*</span></label>
                <ng-select [items]="resourceGroupsList"   bindLabel="name" bindValue="id" name="location" [clearable]="false" id="location" formControlName="location"></ng-select>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap bold-600">Subnet</label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Subnet name<span class="mandatory">*</span></label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.sharedKeyEmpty|| inputError?.sharedKeyMaxLen ||inputError?.sharedKeyASCII" id="subnetName" name="subnetName" formControlName="subnetName" (keyup)="sharedKeyValidation($event)"/>
                <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.sharedKeyMaxLen">A shared key must contain between 1 to 128 characters. </p>
                <p class="text-danger" *ngIf="inputError?.sharedKeyASCII">Invalid shared key configured. The shared key should be a combination of printable ASCII characters and should be between 1 to 128 in length. </p>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Address range<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The virtual network's address range in CIDR notation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.sharedKeyEmpty|| inputError?.sharedKeyMaxLen ||inputError?.sharedKeyASCII" id="addressRange" name="addressRange" formControlName="addressRange" (keyup)="sharedKeyValidation($event)"/>
                <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.sharedKeyMaxLen">A shared key must contain between 1 to 128 characters. </p>
                <p class="text-danger" *ngIf="inputError?.sharedKeyASCII">Invalid shared key configured. The shared key should be a combination of printable ASCII characters and should be between 1 to 128 in length. </p>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Service endpoint<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The subnet will be created with a service endpoint for 'Microsoft.Storage'." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <label class="flex-custom no-wrap bold-600">Microsoft.Storage</label>
              </div>
 
            </form>
        </div>
    
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="!saveBtnEnable" (click)="saveSQLDBData('firewallSetting','createVNet')">Create</button>
        </div>
      </div>
    </ng-container>

    <!-- Restore -->
    <ng-container *ngIf="currentTemplate == 'restore'">
      <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
            <h4 class="threeFour-width">Restore Database<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></h4>
                <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
      
          <div class="body scroll-y">
              <form [formGroup]="subSideSheetForm">
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Subscription<span class="mandatory">*</span></label>
                  <ng-select [items]="subscriptionList" class="disabled"  bindLabel="name" bindValue="id" name="subscription" [clearable]="false" id="subscription" formControlName="subscription"></ng-select>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Resource group<span class="mandatory">*</span></label>
                  <ng-select [items]="resourceGroupsList" class="disabled"  bindLabel="name" bindValue="id" name="rg" [clearable]="false" id="rg" formControlName="rg"></ng-select>
                </div>
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600">Source Details</label>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Source database</label>
                  <input class="form-control full-width disabled-input" type="text" autocomplete="off" id="sourceDB" name="sourceDB" formControlName="sourceDB"/>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Select source</label>
                  <ng-select [items]="sourceTypeList" class="disabled"  bindLabel="name" bindValue="id" name="sourceType" [clearable]="false" id="sourceType" formControlName="sourceType"></ng-select>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Earliest restore point</label>
                  <input class="form-control full-width disabled-input" type="text" autocomplete="off" id="earliestPoint" name="earliestPoint" formControlName="earliestPoint"/>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap ">Restore point (UTC)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a restore point between the earliest 
                    restore point and the current time in UTC." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                    <div class="mb-3 mr-3 w-50">
                      <input  [owlDateTimeTrigger]="dt1"  [min]="minDate"  [max]="maxDate" class="form-control full-width" (dateTimeChange)="setTime($event)"  [owlDateTime]="dt1" placeholder="MM/DD/YYYY" formControlName="restoreDate">
                      <owl-date-time [pickerType]="'calendar'"  #dt1></owl-date-time>
                    </div>
                    <div class="mb-3" style="width: 44%;">
                      <input class="form-control" type="text" autocomplete="off" id="restoreTime" name="restoreTime" placeholder="h:mm:ss A" formControlName="restoreTime" />
                      <!-- (keyup)=validRestoreTime($event) -->
                    </div>
                  </div>
                  <p class="text-danger" *ngIf="inputError?.invalidRestore">The value must be between {{subSideSheetForm.get('earliestPoint')?.value}} and {{currentTime | a3sDateTimeFormat}}</p>
                </div>
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600">Database Details</label>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Database name<span class="mandatory">*</span></label>
                  <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.dbNameEmpty|| inputError?.dbNameLen ||inputError?.dbNameInvalid||inputError?.dbNameDup" id="dbName" name="dbName" formControlName="dbName" (keyup)="validDBName($event,128,'db')"/>
                  <p class="text-danger" *ngIf="inputError?.dbNameEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.dbNameLen">The value has a length of at most 128.</p>
                  <p class="text-danger" *ngIf="inputError?.dbNameInvalid">Your database name can't end with '.' or ' ', can't contain '<,>,*,%,&,:,\,/,?' or control characters.</p>
                  <p class="text-danger" *ngIf="inputError?.dbNameDup">No database with the same name exists in the server</p>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Server<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The connection string and credentials 
                    will be based on the server." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control full-width disabled-input" type="text" autocomplete="off"  id="serverName" name="serverName" formControlName="serverName"/>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Want to use SQL elastic pool?<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Elastic pools provide a simple and cost effective solution for 
                    managing the performance of multiple databases within a fixed budget. An elastic pool provides compute (eDTUs) and storage
                     resources that are shared between all the databases it contains. Databases within a pool only use the resources they need, 
                     when they need them, within configurable limits. The price of a pool is based only on the amount of resources configured and 
                     is independent of the number of databases it contains." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="sqlElasticPool"  class="form-check-inline" id='elasticPoolYes' (change)="setRadioBtn('yes','copyDB','sqlElasticPool')" [checked]="subSideSheetForm.get('sqlElasticPool')?.value ==true">
                        <label class="value" for="elasticPoolYes">Yes</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="sqlElasticPool" class="form-check-inline" id='elasticPoolNo' (change)="setRadioBtn('no','copyDB','sqlElasticPool')" [checked]="subSideSheetForm.get('sqlElasticPool')?.value == false">
                        <label class="value" for="elasticPoolNo">No</label>
                    </div>
                  </div>
                </div>
                <div class="mb-3" *ngIf="subSideSheetForm.get('sqlElasticPool')?.value">
                  <label class="flex-custom no-wrap">Elastic pool<span class="mandatory">*</span></label>
                  <ng-select [items]="elasticPoolList"  bindLabel="name" bindValue="id" name="elasticPool" [clearable]="false" id="elasticPool" formControlName="elasticPool"></ng-select>
                </div>
                <div class="mb-3" *ngIf="!subSideSheetForm.get('sqlElasticPool')?.value">
                  <label class="flex-custom no-wrap">Compute + storage<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a SQL Service tier and performance 
                    level that best fits your application needs." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <label class="flex-custom no-wrap ">{{subSideSheetForm.get('hardwareConfig').value}},{{subSideSheetForm.get('vCore').value}} vCores,{{subSideSheetForm.get('memory').value}} GB Storage</label>
                    <label class="flex-custom no-wrap">zone redundant disabled</label>
                    <span class="action-btn" (click)="openSubSideSheet('configureDB','restore')">Configure database</span>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Backup storage redundancy<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Geo-redundant and locally-redundant storage is available in all regions. 
                    Zone-redundant storage is available in regions with availability zones." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="backupStorage"  class="form-check-inline" id='local' (change)="setRadioBtn('Local','copyDB','backupStorage')" [checked]="subSideSheetForm.get('backupStorage')?.value =='Local'">
                        <label class="value" for="local">Locally-redundant backup storage</label>
                    </div>
                  </div>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="backupStorage" class="form-check-inline" id='zone' (change)="setRadioBtn('Zone','copyDB','backupStorage')" [checked]="subSideSheetForm.get('backupStorage')?.value == 'Zone'">
                        <label class="value" for="zone">Zone-redundant backup storage</label>
                    </div>
                  </div>
                  <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="backupStorage" class="form-check-inline" id='geo' (change)="setRadioBtn('Geo','copyDB','backupStorage')" [checked]="subSideSheetForm.get('backupStorage')?.value == 'Geo'">
                        <label class="value" for="geo">Geo-redundant backup storage</label>
                    </div>
                  </div>
                </div>
                <div class="comm-mb" *ngIf="subSideSheetForm.get('backupStorage')?.value == 'Geo'">
                  <div class="label-div d-flex warningBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                      Selected value for backup storage redundancy is Geo-redundant backup storage. Note that database backups will be geo-replicated to the paired region.</label>
                   </div>  
                </div>
              </form>
          </div>

          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable|| subSideSheetForm.invalid || invalidForm('restoreDB')" (click)="saveSQLDBData('restoreDB')">Save</button>
          </div>
      </div>

      <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
              <h4>Configure<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
    
        <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-2">
                <label class="flex-custom no-wrap bold-600">Service and Compute Tier</label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Service tier<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The vCore model provides a choice between a provisioned compute tier 
                  and a server-less compute tier. Whereas, DTU-based model provides bundled 
                  compute and storage packages balanced for common workloads." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="serviceTier" groupBy="type" (change)="setServiceTier($event);saveBtnEnable=true" bindLabel="name" bindValue="id" name="serviceTier" [clearable]="false" id="serviceTier" formControlName="serviceTier">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div id="item-{{index}}" name="item-{{index}}"class="form-check-inline" [class.disabled-icon]="(item.id=='Hyperscale' && sqlDatabaseData?.sku.tier!='Hyperscale') || (item.id!='Hyperscale' && sqlDatabaseData?.sku.tier=='Hyperscale')">{{item.name}}</div>
                  </ng-template>
                </ng-select>
              </div>
              <ng-container *ngIf="currentForm.get('serviceTier')?.value=='Basic' || currentForm.get('serviceTier')?.value=='Standard' || currentForm.get('serviceTier')?.value=='Premium'">
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='Basic'">
                  <label class="flex-custom no-wrap bold-600">DTUs</label>
                  <label class="flex-custom no-wrap">{{sqlDatabaseData?.sku?.capacity}} (Basic)</label>
                </div>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='Standard' || currentForm.get('serviceTier')?.value=='Premium'">
                  <label class="flex-custom no-wrap">DTUs</label>
                  <ng-select [items]="dtuList"  bindLabel="name" bindValue="id" name="dtu" (change)="setDataSize($event);saveBtnEnable=true" [clearable]="false" id="dtu" formControlName="dtu"></ng-select>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap bold-600">Included storage</label>
                  <label class="flex-custom no-wrap">{{currentForm.get('serviceTier')?.value=='Basic'?'2':currentForm.get('serviceTier')?.value=='Standard'?'250':'500'}} GB</label>
                </div>
              </ng-container>
              
              <ng-container *ngIf="currentForm.get('serviceTier')?.value=='GeneralPurpose' || currentForm.get('serviceTier')?.value=='Hyperscale' || currentForm.get('serviceTier')?.value=='BusinessCritical'">
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='Hyperscale'">
                  <div class="label-div d-flex blueBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;">
                      Databases originally created in Hyperscale tier cannot be changed to other tiers.</label>
                  </div>  
                </div>
                <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='GeneralPurpose'">
                  <label class="flex-custom no-wrap">Compute tier<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provisioned: Compute resources are pre-allocated. Billed per hour based on vCores configured​. 
                    Serverless: Compute resources are auto-scaled​. Billed per second based on vCores used​." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="computeTier"  class="form-check-inline" id='provisioned' (change)="setRadioBtn('yes','computeStorage','computeTier')" [checked]="currentForm.get('computeTier')?.value ==true">
                        <label class="value" for="provisioned">Provisioned</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="computeTier" class="form-check-inline" id='serverless' (change)="setRadioBtn('no','computeStorage','computeTier')" [checked]="currentForm.get('computeTier')?.value == false">
                        <label class="value" for="serverless">Serverless</label>
                    </div>
                  </div>
                </div>
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600">Compute Hardware</label>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Hardware configuration<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Based on the workload requirements, select the hardware configuration." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> </label>
                  <label class="flex-custom no-wrap bold-600">{{currentForm.get('hardwareConfig').value}}</label>
                  <label class="flex-custom no-wrap">up to {{currentForm.get('maxVCore').value}} vCores, up to {{currentForm.get('maxMemory').value}} memory</label>
                  <span class="action-btn" (click)="openHardwareConfigPopup()">Change configuration</span>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">vCores</label>
                  <ng-select [items]="vCoresList"  bindLabel="name" bindValue="id" name="vCores" (change)="saveBtnEnable=true" [clearable]="false" id="vCores" formControlName="vCores"></ng-select>
                </div>
                <ng-container *ngIf="currentForm.get('serviceTier')?.value!='Hyperscale'">
                  <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600">Data max size <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The maximum data storage capacity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> </label>
                    <label class="flex-custom no-wrap">{{currentForm.get('dataMaxSize').value}} GB</label>
                  </div>
                  <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600">Log space allocated</label>
                    <label class="flex-custom no-wrap">1 TB</label>
                  </div>
                </ng-container>
              </ng-container>

              <div class="mb-3" *ngIf="currentForm.get('serviceTier')?.value=='BusinessCritical' || currentForm.get('serviceTier')?.value=='Premium'">
                <label class="flex-custom no-wrap">Read scale-out</label>
                <div class="flex-custom disabled-icon">
                    <div class="checkbox-cs">
                      <input type="radio" name="readScaleOut"  class="form-check-inline" id='enableScaleOut' (change)="setRadioBtn('yes','computeStorage','readScaleOut')" [checked]="currentForm.get('readScaleOut')?.value ==true">
                      <label class="value" for="enableScaleOut">Enabled  </label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="readScaleOut" class="form-check-inline" id='disableScaleOut' (change)="setRadioBtn('no','computeStorage','readScaleOut')" [checked]="currentForm.get('readScaleOut')?.value == false">
                      <label class="value" for="disableScaleOut">Disabled  </label>
                  </div>
                </div>
              </div>
              
             
 
            </form>
        </div>
    
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="!saveBtnEnable" (click)="saveConfigureDB('restoreDB')">Save</button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #sqlDatabaseDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">SQL Database</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" (click)="openSidesheet('computeStorage',selectedRow)">Compute + storage</div>
            <div class="tab-item" (click)="openSidesheet('copy',selectedRow)">Copy</div>
            <div class="tab-item" (click)="openSidesheet('restore',selectedRow)">Restore</div>
            <div class="tab-item" (click)="openSidesheet('firewallSetting',selectedRow)">Firewall settings</div>
            <div class="tab-item" (click)="action(selectedRow,'deleteSqlDatabaseData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedRow[i]:getTags(selectedRow[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedRow[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #sqlHardwareConfigurationPopup>
    <div class="medium-popup-wrapper width-832" style="width: 832px;padding:16px 24px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">SQL Hardware Configuration<br> <span class="blur-text">SQL Database | {{selectedRow[0]}}</span></div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="custom-fixed-tables">
          <table class="table" >
              <thead>
                <tr>
                  <th><div class="flex-custom no-wrap"><span>Configuration</span></div></th>
                  <th><div class="flex-custom no-wrap"><span>Description</span></div></th>
                  <th><div class="flex-custom no-wrap"><span>Max vCores</span></div></th>
                  <th><div class="flex-custom no-wrap"><span>Max memory</span></div></th>
                  <th><div class="flex-custom no-wrap"><span>Max storage</span></div></th>
                  <th><div class="flex-custom no-wrap"><span>Compute cost / vCore / month</span></div></th>    
                </tr>
              </thead>
              <tbody > 
                <tr *ngFor="let row of sqlHardwareConfigurationList" [class.disabled-icon]="disableHardwareConfigRow(row)"
                 (click)="setDefHardware(row,i);" id="row-{{i}}" [class.active-row]="row?.active">
                    <td isEllipsisActive>{{row?.configuration}}</td>
                    <td isEllipsisActive>{{row?.description}}</td>
                    <td isEllipsisActive>{{row?.maxVCores}}</td>
                    <td isEllipsisActive>{{row?.maxMemory}}</td>
                    <td isEllipsisActive>{{row?.maxStorage}}</td>
                    <td isEllipsisActive>{{row.computeCost}}</td>
                </tr>
                <div class="col-md-12 mt-3" *ngIf="!sqlHardwareConfigurationList?.length">
                  <p class="content-14 blur-text">No data Found</p>
                </div>
              </tbody>
          </table>
        </div>

        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="setHardwareConfig()">Select</button>
        </div>
    </div>
  </ng-template>