import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPv4, IPSubnetwork } from 'ip-matching';
import * as _ from 'lodash';
import { LoaderService } from '../../../services/loader.service';
import { UtilService } from '../../../services/util.service';
import { NewResourceGroup, ResourceInputError, ResourceModel } from '../resources-model'

@Component({
  selector: 'app-virtual-network-resource',
  templateUrl: './virtual-network-resource.component.html',
  styleUrls: ['./virtual-network-resource.component.scss']
})
export class VirtualNetworkResourceComponent implements OnInit {
  @Input() projectData : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:string;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  VNetmodel : ResourceModel = {
    resourceId:null,
    resourceName:null,
    resourceType:'virtualNetworks',
    resourceData:{
      virtualNetworkAddressSpace:[{
        address:null,
        VNetAdrspc:null
      }],
      resourceGroup:null,
      tagList:[{
        tagName:null,
        value:null
      }]
    },
    comments:null,
    createdBy:null,
  };
  show : boolean = false;
  inputError:ResourceInputError = {
    VNetName : false,
    VNetChar: false,
    VNetDescription: false,
    duplicateVnet:false
  }
  
  resourceGroupList:any=[];
  showView:boolean=false;
  existingVNList:any=[];
  existingNewVNList:any=[];
  existingVNListForName:any=[];
  publicIPs:Array<string>=[
    '0.0.0.0/1',
    '127.0.0.0/8',
    '169.254.0.0/16',
    '169.254.0.0/15',
    '168.63.129.16/28',
    '168.63.129.16/29',
    '168.63.129.16/30',
    '168.63.129.16/31',
    '168.63.129.16/32',
    '224.0.0.0/4',
    '224.0.0.0/3',
    '255.255.255.255/32'
  ];
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  
  constructor(private util:UtilService,private loader : LoaderService) { }

  async ngOnInit() {
    this.loader.showLoader();
    await this.getResourceGroupList();
    await this.getListByPropName('virtualNetworks',undefined,undefined);
    // this.existingNewVNList=this.parentResourceDtls;
    await this.getExistNewlyResourceDetails();
    if(this.sideSheetViewType=='new'){
      this.clearResourceModel();
    }
    if(this.sideSheetViewType=='edit'){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null,null,null,true).then(async res=>{
        this.VNetmodel=res;
        if(this.VNetmodel.comments=='null'){
          this.VNetmodel.comments=null;
        }
        this.VNetmodel.resourceData=JSON.parse(res.resourceData);
      });
    }
    this.loader.hideLoader();
  }

  async getExistNewlyResourceDetails() {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,'virtualNetworks'],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      this.existingNewVNList=res;
    },err=>{
      this.existingNewVNList=[];
    });
  }  

  async getVirtualNetworkList(){
    if(this.VNetmodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.VNetmodel.resourceData.resourceGroup);
      if(!resourceObj.resourceGroupId){
        this.loader.queActions('push', 'virtualNetworks');
        await this.getListByPropName('virtualNetworks',resourceObj,undefined);
        this.loader.queActions('pop', 'virtualNetworks');
      }else{
        this.existingVNListForName=[];
      }
    }
  }

  async resourceGroupChange(){
    // await this.getVirtualNetworkList();
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
        if(this.projectData.resourceGroup&&this.projectData.resourceGroup!='null'){
        this.VNetmodel.resourceData.resourceGroup=this.projectData.resourceGroup;        
        }
      });
  }

  public onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.VNetmodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.VNetmodel.resourceData.resourceGroup=undefined; 
    });
  }

  editNewResourceGroup(item){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item);    
    this.show = !this.show;
  }

  async getNewResourceGroup(){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addNewAddress(){
    this.VNetmodel.resourceData.virtualNetworkAddressSpace.push({
      address:null
    });
  }

  deleteAddress(index){
    this.VNetmodel.resourceData.virtualNetworkAddressSpace.splice(index,1);
  }

  addTag(){
    this.VNetmodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTag(index){
    this.VNetmodel.resourceData.tagList.splice(index,1);
  }

  async getListByPropName(propName,resourceObj,selectedObj){    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      if(propName=='virtualNetworks'&&!resourceObj){
        this.existingVNList=res.value;
      }else if(propName=='virtualNetworks'&&resourceObj){
        this.existingVNListForName=res.value;
      }
    },err=>{
      if(propName=='virtualNetworks'&&!resourceObj){
        this.existingVNList=[];
      }else if(propName=='virtualNetworks'&&resourceObj){
        this.existingVNListForName=[];
      }
    });
  }

  async clearResourceModel(){
    this.VNetmodel.resourceName=null;
    this.VNetmodel.comments=null;
    this.VNetmodel.resourceData={
      virtualNetworkAddressSpace:[{
        address:null,
        VNetAdrspc:null
      }],
      resourceGroup:null,
      tagList:[{
        tagName:null,
        value:null
      }]
    };
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
      await this.clearResourceModel();
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.VNetmodel);
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
      await this.clearResourceModel();
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){}

  VNetNameValidation(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VNetName = true;
    }
    else this.inputError.VNetName = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError.VNetChar = false;
    }
    else{
      this.inputError.VNetChar = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError.VNetChar = true;
        return;
      }
    }

    let regex=/\`|\ |\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(regex.test(value)){
      this.inputError.VNetChar=true;
      return;
    }else{
      this.inputError.VNetChar=false;
    }

    let findObj=this.existingVNListForName.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
    if(findObj){
      this.inputError.duplicateVnet=true;
      return;
    }else if(this.existingNewVNList&&this.existingNewVNList.length){
      let findObjN=this.existingNewVNList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase()&&dt.resourceId!=this.VNetmodel.resourceId);
      if(findObjN){
        this.inputError.duplicateVnet=true;
        return;
      }else{
        this.inputError.duplicateVnet=false;
      }
    }else{
      this.inputError.duplicateVnet=false;
    }
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  isAddressSpace(string){
    return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
  }

  VNetAddressSpace(limitField,item,index){
    let value =limitField.target.value
    if(!this.isAddressSpace(value)){
      console.log(value,this.isAddressSpace(value));
      item.VNetAdrspc=true;
      return;
    }
    item.VNetAdrspc=false;
    this.validateAddress(item,index);
  }

  validateOverloopAddress(vlIPRange,ipRangObj){
    if(vlIPRange.range.left.input==ipRangObj.range.left.input){
      return true;
    }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
     return true;
    }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]){
        if(vlIPRange.range.left.parts[2]>ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.right.parts[2]<ipRangObj.range.right.parts[2]){
            return true;
          }
        }else if(vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.left.parts[3]>ipRangObj.range.left.parts[3]){
            if(vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
              return true;
            }
          }
        }
      }
    }else if(vlIPRange.range.left.parts[0]<ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.right.parts[0]>ipRangObj.range.right.parts[0]){
        return true;
      }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]){
        if(vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
          return true;
        }
      }
    }else {
      return false;
    }
  }

  validateAddress(item,index){
    const splitAr=item.address.split('/');
    let vlIPRange;
    try{
      vlIPRange=new IPSubnetwork(new IPv4(splitAr[0]),Number(splitAr[1]));
      item.VNetAdrspc=false;
    }catch(e){
      item.VNetAdrspc=true;
      return false;
    }
    if(vlIPRange.input!=item.address){
      item.notValidCIDR=true;
      item.validCIDRValue=vlIPRange.input;
      return false;
    }else{
      item.notValidCIDR=false;
      item.validCIDRValue=null;
    }

    if(this.publicIPs.includes(item.address)){
     item.publicAddress=true;
     return false;
    }else{
    item.publicAddress=false;
    }

    if(vlIPRange.range.left.parts[0]!="10"&&vlIPRange.range.left.parts[0]!="192"&&vlIPRange.range.left.parts[0]!="172"){
      item.publicAddress=true;
     return false;
    }else if(vlIPRange.range.left.parts[0]=="192"&&vlIPRange.range.left.parts[1]!="168"){
      item.publicAddress=true;
      return false;
    }else if(vlIPRange.range.left.parts[0]=="172"&&vlIPRange.range.left.parts[1]!="16"){
      item.publicAddress=true;
      return false;
    }else{
      item.publicAddress=false;
      }
    if(this.existingVNList&&this.existingVNList.length){
      let result=this.existingVNList.find(dt=>{
        if(dt.properties.addressSpace.addressPrefixes.length){
          let addressPrefixes=dt.properties.addressSpace.addressPrefixes;
          return addressPrefixes.find(vl=>{
            const splitArr=vl.split('/');
           let ipRangObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
           if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
             item.overlapingValue=vl;
             return true
           }

          })
        }
      })
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

    if(this.existingNewVNList&&this.existingNewVNList.length){
      let result=this.existingNewVNList.find((dt)=>{
        try {
          if(dt.resourceData&&dt.resourceId!=this.VNetmodel.resourceId){
            let resourceData=JSON.parse(dt.resourceData);
           return resourceData.virtualNetworkAddressSpace.find(dtl=>{
              if(dtl.address){
                const splitArr=dtl.address.split('/');
                  let ipRangObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
                if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
                  item.overlapingValue=dtl.address;
                  return true
                }
              }
            })
          }
        } catch (error) {}
      });
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

    if(item.address){
      let result=this.VNetmodel.resourceData.virtualNetworkAddressSpace.find((dt,i)=>{
        try {
          if(dt.address&&i!=index){
            const splitArr=dt.address.split('/');
            let ipRangObj;
            try{              
              ipRangObj=new IPSubnetwork(new IPv4(splitArr[0]),Number(splitArr[1]));
              if(vlIPRange.input==ipRangObj.input){
                item.overlapingValue=dt.address;
                return true
              }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
                item.overlapingValue=dt.address;
                return true
              }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]==ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[2]==ipRangObj.range.right.parts[2]&&vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
                item.overlapingValue=dt.address;
                return true
              }else if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
                item.overlapingValue=dt.address;
                return true
              }
            } catch (err) {}
          }
        } catch (error) {}
      });
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

  }


  checkTagInput(item,limitNum,index): boolean {
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return false;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return false;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return false;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.VNetmodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
        return false
      }
    }
  }


  VNetDescription(limitField, limitNum): void {
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VNetDescription = true;
    }
    else this.inputError.VNetDescription = false;
  }

  saveResourceDetails(){
    var model = this.VNetmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.VNetmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  checkVnetAddress():boolean {
    if(this.VNetmodel.resourceData.virtualNetworkAddressSpace.length>0){
      for(let i=0;i<this.VNetmodel.resourceData.virtualNetworkAddressSpace.length;i++){
        if(!this.VNetmodel.resourceData.virtualNetworkAddressSpace[i].address||this.VNetmodel.resourceData.virtualNetworkAddressSpace[i].VNetAdrspc||this.VNetmodel.resourceData.virtualNetworkAddressSpace[i].notValidCIDR||this.VNetmodel.resourceData.virtualNetworkAddressSpace[i].overlooping){
          return true;
        }
      }
    }
    return false;
  }

  checkDataDisabled(): boolean {
    return !this.VNetmodel.resourceName ||!this.VNetmodel.resourceData.resourceGroup || this.checkVnetAddress() || this.inputError.VNetChar || this.inputError.VNetName ||this.inputError.duplicateVnet;
  }
}
