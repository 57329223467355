import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-additional-standards',
  templateUrl: './additional-standards.component.html',
  styleUrls: ['./additional-standards.component.scss']
})
export class AdditionalStandardsComponent implements OnInit {
  @Input('currentFilteredProject') currentFilteredProject: any ;
  datasort: string;
  deployStandardList: any = [];
  deployStandardListDummy: any = [];
  showDeployComplainceStandard=false;
  searchFormGroup:FormGroup;
  formGroup: FormGroup;
  subscriptionList: any;
  showLoader : boolean = false;
  managedIdentityList : any = [];
  locationList: any = [];
  currentStandardData: any;
  currentParameters: any;
  currentGlobalParameters: any;
  currentArtifacts: any;
  showData: any = false;
  selectedRow: any;
  subscriptionInfra: any;

  constructor(private util:UtilService,private fb: FormBuilder,private modal: ModalService) { }

  ngOnInit(): void {
    $("body").css("position", "relative");
    this.searchFormGroup=this.fb.group({
      searchText:['']
    })
  }

  ngOnChanges() {
    this.subscriptionList = this.util.getCache('subscriptionList');
    this.subscriptionList = this.subscriptionList.filter(e=> e.subscriptionId == this.currentFilteredProject.subscriptionId)
    this.getAllData();
  }

  getAllData(){
    this.getStandardListFromTable();
    this.getStandardList();
  }

  getStandardListFromTable(){
    this.showLoader = true
    return this.util.handleRequest('get','a3s_static_getByTable',['dbo','additionalStandards'],null,null,null,true).then(res=>{
      if(!res || !res.recordset.length) return;
      this.deployStandardList = res.recordset
      this.deployStandardListDummy=_.cloneDeep(this.deployStandardList);
    })
  }

  getStandardList(){
    Promise.all(this.subscriptionList.map(e=>{
      return this.util.handleRequest('get','a3s_compliance_additionalStandardsList',[e.subscriptionId],null,null,null,true)
    })).then(final=>{
      let array = []
      final.forEach(e=>{
        e['body'].value.forEach(x=>{
          array.push(x)
        })
      })
      console.log('array',array)
      this.deployStandardList.forEach(async e=>{
        let filterData = array.filter(x=> x.name  == e.assignmentName)
        e.status = filterData.length ? filterData[0].properties.provisioningState : null
        e.subscriptionId = filterData.length ? filterData[0].properties.blueprintId.split('/')[2] : null
        e.bluePrintName = filterData.length ? filterData[0].properties.blueprintId.split('/')[6] : null
        if(e.status == 'failed'){
          let data = await this.util.handleRequest('get','a3s_compliance_getDeployFailed',[e.subscriptionId,e.assignmentName],null,null,null,true)
          e['errorMessage'] = data.body.value.length ? data.body.value[0].properties.deployments[1].result.error.message : null
        }
      })

      console.log('deployStandardList--------------------------------',this.deployStandardList)
      this.showLoader = false
    },err=>{
      this.showLoader = false
    })
  }

  getLocations(){
    return this.util.handleRequest('get','a3s_static_getLocation',[],null,null,null,true).then(res=>{
      if(!res || !res.body) return;
      this.locationList = res.body.value
    })
  }

  intializeFormInput() {
    this.formGroup=this.fb.group({
      subscription : [null,[Validators.required]],
      assignmentName: [{value: this.getAssignmentName(), disabled: true},[Validators.required]],
      location : [null,[Validators.required]],
      lockAssignment:['dontLock',[Validators.required]],
      managedIdentity:['systemAssigned',[Validators.required]],
      userAssignedIdentity:[null]
    })
  }

  getAssignmentName(){
    let data = this.deployStandardListDummy.filter(e=> e.name  == this.currentStandardData.name)
    return data.length ? data[0].assignmentName : null
  }

  getManagedIdentityList(){
    let subsId = this.formGroup.get('subscription').value
    if(!subsId) return;
    return this.util.handleRequest('get','a3s_compliance_getManagedIdentityList',[subsId],null,null,null,true).then(res=>{
      this.managedIdentityList = res.body.value
    })
  }

  async setStandard(list){
    await this.getLocations();
    this.showData = true;
    this.currentStandardData = list
    this.currentParameters = list.parameters ? JSON.parse(list.parameters) : ''
    this.currentGlobalParameters =  this.currentParameters.properties.layout.globalParameters
    this.currentArtifacts =  this.currentParameters.properties.layout.artifacts
    this.currentGlobalParameters.forEach(e=>{
      let filter = Object.keys(this.currentParameters.properties.parameters).filter(x=> x == e.name)
      e['values'] = filter && filter.length ? this.currentParameters.properties.parameters[filter[0]] : []
      e.newValue = null;
      if(e.values.metadata.strongType == "location"){
        e.values.allowedValues = this.locationList.map(e=>e.displayName)
      }
     
      if(!e.values.hasOwnProperty('allowedValues') || !e.values.allowedValues.length){
        e.values['inputType'] = "text"
        e.newValue = e.values.hasOwnProperty('defaultValue') && e.values.defaultValue.length ? e.values.defaultValue : null
        if(e.values.type == "array") e.newValue = '[]'
      }
      else if((e.values.hasOwnProperty('allowedValues') || e.values.allowedValues.length) && e.values.type == "string"){
        e.values['inputType'] = "dropdown"
        e.newValue = e.values.hasOwnProperty('defaultValue') && e.values.defaultValue.length ? e.values.defaultValue : null
      }
      else if(e.values.type == "array"){
        e.values['inputType'] = "multiDropdown"
        e.newValue = e.values.hasOwnProperty('defaultValue') && e.values.defaultValue.length ? e.values.defaultValue : null
      }
      if((e.values.type == "string" && !e.values.hasOwnProperty('defaultValue')) || (e.values.type == "array" && !e.values.hasOwnProperty('defaultValue'))){
        e.isMandatory = true
      }
      else{
        e.isMandatory = false
      }
    })
    this.currentArtifacts.forEach(e=>{
      e.showDetail = true;
      e.parameters.forEach(par=>{
        par.showDetail = true;
        let filter = Object.keys(this.currentParameters.properties.parameters).filter(x=> x == par.name)
        par['values'] = filter && filter.length ? this.currentParameters.properties.parameters[filter[0]] : []
        par.newValue = null;
        if(par.values.metadata.strongType == "location"){
          par.values.allowedValues = this.locationList.map(e=>e.displayName)
        }
        if(!par.values.hasOwnProperty('allowedValues') || !par.values.allowedValues.length){
          par.newValue = par.values.hasOwnProperty('defaultValue') && par.values.defaultValue.length ? par.values.defaultValue : null
          par.values['inputType'] = "text"
          if(par.values.type == "array") par.newValue = '[]'
        }
        else if((par.values.hasOwnProperty('allowedValues') || par.values.allowedValues.length) && par.values.type == "string"){
          par.values['inputType'] = "dropdown"
          par.newValue = par.values.hasOwnProperty('defaultValue') && par.values.defaultValue.length ? par.values.defaultValue : null
        }
        else if(par.values.type == "array"){
          par.values['inputType'] = "multiDropdown"
          par.newValue = par.values.hasOwnProperty('defaultValue') && par.values.defaultValue.length ? par.values.defaultValue : null
        }
        if((par.values.type == "string" && !par.values.hasOwnProperty('defaultValue')) || (par.values.type == "array" && !par.values.hasOwnProperty('defaultValue'))){
          par.isMandatory = true
        }
        else{
          par.isMandatory = false
        }
      })
    })
    this.intializeFormInput()
    this.formGroup.get('subscription').setValue(this.currentFilteredProject.subscriptionId)
    this.showDeployComplainceStandard=true
    console.log('currentParameters',this.currentParameters)
    console.log('currentGlobalParameters',this.currentGlobalParameters)
    console.log('currentArtifacts',this.currentArtifacts)
  }

  checkManageIdentityAccess(event){
    let subsId = this.formGroup.get('subscription').value
    let principalId = this.managedIdentityList.filter(e=> e.name == event.name)[0].properties.principalId
    if(!subsId) return;
    return this.util.handleRequest('get','a3s_compliance_getAccessDetails',[subsId,principalId],null,null,null,true).then(res=>{
      if(!res || !res.body) return;
      let data = res.body.value.length ?  res.body.value[0] : null
      if(!data) return this.formGroup.get('userAssignedIdentity').setErrors({'incorrect': true}); 
      let roleId = `/subscriptions/${subsId}/providers/Microsoft.Authorization/roleDefinitions/8e3af657-a8ff-443c-a75c-2fe8c4bcb635`
      if(data.properties.roleDefinitionId == roleId) this.formGroup.get('userAssignedIdentity').setErrors({'incorrect': false}); 
      else this.formGroup.get('userAssignedIdentity').setErrors({'incorrect': true}); 
      console.log(res)
    },err=>{
      this.formGroup.get('userAssignedIdentity').setErrors({'incorrect': true}); 
    })
  }

  deployStandard(){
    let body ={
      standardName : this.currentStandardData.name,
      formData : this.formGroup.getRawValue(),
      globalParameters : this.currentGlobalParameters,
      artifacts : this.currentArtifacts
    }
    this.util.info(`Assigning ${body.formData.assignmentName}...`)
    this.showDeployComplainceStandard = false;
    return this.util.handleRequest('post','a3s_compliance_additionalStandardsDeploy',[],body,null,null,true).then(res=>{
      console.log(res)
      this.util.success(`${body.formData.assignmentName} assigned successfully!`)
      this.getStandardList()
      this.showDeployComplainceStandard = false;
    },err=>{
      this.util.error(`${body.formData.assignmentName} failed to be assigned.`)
      this.getStandardList()
      this.showDeployComplainceStandard = false;
    })
  }

  removeAssignment(){
    this.util.info(`Removing ${this.selectedRow.assignmentName}...`)
    this.closeModal();
    return this.util.handleRequest('delete','a3s_compliance_removeStandard',[this.selectedRow.subscriptionId,this.selectedRow.bluePrintName,this.selectedRow.assignmentName],null,null,null,true).then(res=>{
      this.util.success(`${this.selectedRow.assignmentName} removed successfully!`)
      this.getStandardList()
    },err=>{
      this.util.error(`${this.selectedRow.assignmentName} failed to be removed.`)
      this.getStandardList()
    })
  }
  
  updateValidator(){
    let value = this.formGroup.get('managedIdentity').value
    if(value =='userAssigned'){
      this.formGroup.get('userAssignedIdentity').setValue(null)
      this.formGroup.get('userAssignedIdentity').setValidators([Validators.required])
    }else this.formGroup.get('userAssignedIdentity').setValidators(null)
    this.formGroup.get('userAssignedIdentity').setErrors({'incorrect': false}); 
    this.formGroup.get('userAssignedIdentity').updateValueAndValidity()
  }

  searchTableData(){
    if(this.searchFormGroup.value && this.searchFormGroup.value['searchText']) {
      let txt=this.searchFormGroup.value['searchText'];
      txt = txt.toLowerCase();
      this.deployStandardList = [...this.deployStandardListDummy];
      let list = this.deployStandardList.filter(resp => {
          return resp.name.toLowerCase().includes(txt)
      })
      this.deployStandardList = list;
    } else {
      this.deployStandardList = this.deployStandardListDummy;
    }
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData?this[tableName].tableData:this[tableName];
    sorted.sort((a, b) => {
        switch (value) {
            case 'max': {
              let x = a.properties.score? a.properties.score.max: ' ';
              let y = b.properties.score? b.properties.score.max: ' ';
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
  }

  openConfirmModal(template: TemplateRef<any>,row){
    this.selectedRow = row
    this.modal.openModal(template,'mgmt-popup confirm-popup');
  }
  
  closeModal() {
    this.modal.closeModal();
  }

  checkDisabled(){
    let data = this.currentArtifacts.map(e=>e.parameters).flat()
    return this.currentGlobalParameters.some(e=> e.isMandatory && !e.newValue) || data.some(e=> e.isMandatory && !e.newValue)
  }

}
