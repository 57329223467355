<div class="introduction">
           
             <p class="heading" id="1">Create New Action Group </p>  
             <ol class="mb-0" >
                 <li>Click <b>Alerts </b>from the left menu. </li>
                 <li>Click <b>New Action group.</b> </li>
                 <li>Provide needed inputs in the New Action Group side sheet. </li>
                 <li>Click <b>Create.</b> </li>
             </ol>
             <img style="width:50%"src="assets\user-manual\apps-health-monitoring\Side-sheet-action.svg" >
             <figcaption class="caption col-md-6" style="padding-left: 150px;">Action group side sheet</figcaption>

          
</div>
