<div class="introduction">
  <p class="heading" id="2">Form Recognition</p>
      <p>The Form Recognition uses Azure Form recognition service and SDK. 
        This use case automatically extracts the information from the documents and no training required. </p>
  
    <ol>
        <li>Click <b>Data analytics -> Create project -> Form recognition.</b>    </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(1).svg" >
        <figcaption class="caption">Form recognition - use case </figcaption>

        <li>Fill <b>Project name</b> and <b>Source details</b> (connection properties). </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\1.svg" >
        <figcaption class="caption">Form recognition - source details  </figcaption>

        <li>Select <b>document type.</b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\2.svg" >
        <figcaption class="caption">Form recognition – document type  </figcaption>
    

        <li>Click <b>Next.</b>  </li>
        <li>Select <b>Data Labels</b> and click<b> Next.</b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\3.svg" >
        <figcaption class="caption">Form recognition - select labels  </figcaption>
     

        <li>Click <b>Finish.</b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\4.svg" >
        <figcaption class="caption">Form recognition – output  </figcaption>
     

        <li>Click View in <b>A3S BI Dashboard. </b></li>
   
        <img src="assets\user-manual\data-&-AI\data-analytic\5.svg" >
        <figcaption class="caption">Form recognition - BI dashboard (tile view) </figcaption>
<!-- 
        <img src="assets\user-manual\data-&-AI\data-analytic\6.svg" >
        <figcaption class="caption">Form recognition - BI dashboard (table view) </figcaption> -->

       
     
    
    </ol>
</div>
