<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Network Security Group</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="nsgmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(null)">Create new
                    </p>
                </div>
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="nsgmodel.resourceName" placeholder="" name="name" (keyup)="checkNSGName($event,80)" required>
                <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                <p class="text-danger" *ngIf="inputError?.nameDupCheck">NSG name must be unique in the current resource group.</p>
                </div>              
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="inboundRules" class="control-label">Inbound rules<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add rules to allow or deny incoming traffic." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" style="font-size: 12px;font-weight: 400;">
                    <div style="border-left: solid 2px #2989E9;padding-left: 8px;margin-bottom: 8px;" *ngFor="let item of nsgmodel.resourceData.vmInBoundRule;let i=index">
                        <span>{{item?.priority}}</span>: <span>{{item?.name}}</span>
                        <span class="pull-right show-pointer-cursor"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteRules('inboundRules',i)"></span>
                        <span class="pull-right show-pointer-cursor" style="padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editRules('inboundRules',i)"></span>
                        <br>
                        <span>{{item?.source}}</span>
                        <br>
                        <span>{{item?.service}}</span><span>({{item?.protocol}}/{{item?.destinationPortRange}})</span>
                    </div>
                </div>      
                <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="nsgmodel.resourceData.updateIndex=undefined;triggerBtn('inboundRules')">Add an inbound rule</p>  
                </div>
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="outboundRules" class="control-label">Outbound rules<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add rules to allow or deny outgoing traffic." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" style="font-size: 12px;font-weight: 400;">
                    <div style="border-left: solid 2px #2989E9;padding-left: 8px;margin-bottom: 8px;" *ngFor="let item of nsgmodel.resourceData.vmOutBoundRule;let i=index">
                        <span>{{item?.priority}}</span>: <span>{{item?.name}}</span>
                        <span class="pull-right show-pointer-cursor"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteRules('outboundRules',i)"></span>
                        <span class="pull-right show-pointer-cursor" style="padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editRules('outboundRules',i)"></span>                            
                        <br>
                        <span>{{item?.source}}</span>
                        <br>
                        <span>{{item?.service}}</span><span>({{item?.protocol}}/{{item?.destinationPortRange}})</span>
                    </div>
                </div>  
                <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="nsgmodel.resourceData.updateIndex=undefined;triggerBtn('outboundRules')">Add an outbound rule</p>  
                </div>
            </div>
            
            <div class="form-group">
                <div class="label-div d-flex">
                    <label class="control-label " style="min-width: max-content;">Tag name </label>
                    <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                    </label>
                    <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="nsgmodel.resourceData&&nsgmodel.resourceData.tagList&&nsgmodel.resourceData.tagList.length>1"></label>
                    <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                </div>
                <div *ngFor="let item of nsgmodel.resourceData.tagList;let i = index">
                    <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                        <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                        [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                        <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                        [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                        *ngIf="nsgmodel.resourceData.tagList.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                    <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                    <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                    <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                </div>
                </div>
            
        
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="nsgmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
            

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('nsgsr')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('nsgsr')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>