import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { percent,displayTxt,daysToMonths,inDays,FirstLetterCaps, indianNumber, DateFormat,IndianTimeFormat, A3sDateTimeFormat, twoDecimal, exponentialToNumber, CurrencyPipe,SpaceBetweenCapitalPipe, bytesToSize, bytesToGB, bytesToMB, ThousandSuffixesPipe } from './custom.pipe';

@NgModule({
  imports: [
    CommonModule
  ],

  declarations: [daysToMonths,percent,displayTxt,FirstLetterCaps,inDays,indianNumber,DateFormat,IndianTimeFormat,A3sDateTimeFormat,twoDecimal,exponentialToNumber,CurrencyPipe,SpaceBetweenCapitalPipe,bytesToSize,bytesToMB,bytesToGB,ThousandSuffixesPipe],

  exports: [daysToMonths,percent,displayTxt,FirstLetterCaps, inDays,indianNumber,DateFormat,IndianTimeFormat,A3sDateTimeFormat,twoDecimal,exponentialToNumber,CurrencyPipe,SpaceBetweenCapitalPipe,bytesToSize,bytesToMB,bytesToGB,ThousandSuffixesPipe],

  providers: [daysToMonths,FirstLetterCaps,indianNumber,DateFormat,IndianTimeFormat,A3sDateTimeFormat,twoDecimal,exponentialToNumber,CurrencyPipe,SpaceBetweenCapitalPipe,bytesToSize,bytesToMB,bytesToGB,ThousandSuffixesPipe]
})
export class PipesModule { }
