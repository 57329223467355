import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { SecurityBucketState } from 'src/app/state/security-bucket.state';

@Component({
  selector: 'app-identity-security',
  templateUrl: './identity-security.component.html',
  styleUrls: ['./identity-security.component.scss']
})
export class IdentitySecurityComponent implements OnInit {
  currentProject: any;
  subscriptionInfra: Subscription;
  @Select(SecurityBucketState.currentProjectDetail) currentProjectDetail$: Observable<any>;
  filterWindowVM: any=[];
  filterLinuxVM: any=[];
  
  constructor() { }

  async ngOnInit() {
    this.subscriptionInfra = this.currentProjectDetail$.subscribe((res) => {
      if(res){
        this.currentProject = _.cloneDeep({...res});//res.currentFilteredProject;
        this.filterWindowVM=this.currentProject.resources.filter(dt=>dt.resourceType=="virtualMachines"||dt.resourceType=="Virtual Machines");
        this.filterLinuxVM=this.currentProject.resources.filter(dt=>dt.resourceType=="linuxVirtualMachines"||dt.resourceType=='Linux Virtual Machines');
      }
    });
  }

  ngOnDestroy() {
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

}