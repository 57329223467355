<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
              <h4>Network Interfaces</h4>
              <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="networkInterfacemodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 8px;"><i class="fa fa-trash" (click)="deleteNewResourceGroup(item,index)"></i></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 25px;"><img src="/assets/Infra/blueprint/palette/edit.svg" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="add-another-tag">
                  <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(false)">Create new
                  </p>
                </div>
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label for="NIname" class="control-label ">Network interface name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar || inputError?.duplicateVnet" id="VNetName" autocomplete="off" [(ngModel)]="networkInterfacemodel.resourceName" placeholder="Enter network interface name" name="VirtualNetworkName" (keyup)="VNetNameValidation($event,80);niDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.VNetName">The length must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateVnet">There is already a resource with the same name and type within the current resource group.</p>
                    <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>              
            </div>
            
                <div class="form-group">
                    <div class="label-div">
                        <label for="VNetName" class="control-label ">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="VNetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="networkInterfacemodel.resourceData.vnetName" name="VirtualNetworkName" disabled>
                    </div>              
                </div>
              
            
                <div class="form-group">
                    <div class="label-div">
                        <label for="subnetName" class="control-label ">Subnet<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="subnetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="networkInterfacemodel.resourceData.subnet" name="subnetName" disabled>
                    </div>              
                </div>
                
            
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="privateIPAddr" class="control-label ">Private IP address assignment</label>
                </div>
                <div class="input-box d-flex">
                    <!-- col-sm-6 d-flex"> -->
                    <!-- <div class="box-radio full-width">
                       <input type="radio" value="subnet" [(ngModel)]="subnetModel.resourceData.subnetType" checked>
                       <label for="subnet" >Subnet</label>
                       <input type="radio" value="gatewaySubnet" [(ngModel)]="subnetModel.resourceData.subnetType">
                        <label for="gatewaySubnet" >Gateway subnet</label>
                    </div>                   -->
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="privateIPAddr" [value]="'Dynamic'" ([ngModel])="networkInterfacemodel.resourceData.privateIPAddr" [checked]="networkInterfacemodel.resourceData.privateIPAddr=='Dynamic'" (change)="networkInterfacemodel.resourceData.privateIPAddr=$event.target.value;assignPrivateIPToDynamicNIC()" ><span>Dynamic</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="privateIPAddr" [value]="'Static'" ([ngModel])="networkInterfacemodel.resourceData.privateIPAddr" [checked]="networkInterfacemodel.resourceData.privateIPAddr=='Static'" (change)="networkInterfacemodel.resourceData.privateIPAddr=$event.target.value;assignPrivateIPToDynamicNIC()"><span>Static</span></label>                    
                </div>             
            </div>
            <div class="form-group" *ngIf="networkInterfacemodel.resourceData.privateIPAddr=='Static'">
                <div class="label-div">
                    <label for="privateIPAddrSpace" class="control-label col-sm-2">Private IP address</label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.nicValidIPError || inputError?.nicRegisterIPError || inputError?.nicAddrUsedError || !inputError?.nicSubnetRangeError" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="networkInterfacemodel.resourceData.privateIPAddrSpace" placeholder="" name="privateIPAddrSpace" (keyup)="nicPrivateIpAddress(networkInterfacemodel)" required>
                    <!-- <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p> -->
                    <p class="text-danger" *ngIf="inputError?.nicValidIPError">Invalid IP address</p>
                    <p class="text-danger" *ngIf="inputError?.nicRegisterIPError">The IP address falls within the reserved IP range of subnet</p>
                    <p class="text-danger" *ngIf="inputError?.nicAddrUsedError">IP is already used</p>
                    <p class="text-danger" *ngIf="!inputError?.nicSubnetRangeError">The IP must be in the subnet</p>
                </div>              
            </div> 
            
            
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label">Network security group name<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use network security tools to control access to the network interface." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="nsgList" bindLabel="name" bindValue="id" [(ngModel)]="networkInterfacemodel.resourceData.nsg" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                    </ng-select>
                </div>  
                   </div>
             
            
            <div class="form-group">

              <div class="label-div d-flex">
                <label class="control-label " style="min-width: max-content;">Tag name </label>
                <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                </label>
                <label style="padding-left: 6px !important;width: 18px;"
                  *ngIf="networkInterfacemodel.resourceData&&networkInterfacemodel.resourceData.tagList&&networkInterfacemodel.resourceData.tagList.length>1"></label>
                <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
              </div>
              <div *ngFor="let item of networkInterfacemodel.resourceData.tagList;let i = index">
                <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                  <div class="input-box" style="padding-bottom: 6px;">
                    <input type="text" class="form-control" id="tagName" autocomplete="off"
                      [(ngModel)]="item.tagName" name="tagName" (keyup)="checkTagInput(item,512,i)">
                  </div>

                  <div style="padding-left: 6px;padding-right: 6px;">:</div>

                  <div class="input-box">
                    <input type="text" class="form-control" id="tagValue" autocomplete="off"
                      [(ngModel)]="item.value" name="tagValue" (keyup)="checkTagInput(item,256)">
                  </div>

                  <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                      *ngIf="networkInterfacemodel.resourceData.tagList.length>1"></span>
                </div>
                <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                  512.</p>
                <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                  <>%&\?/.</p>
                <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                  is 256.</p>
              </div>

              <div  class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
              </div>
            </div>
            
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label ">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="networkInterfacemodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
        </div>
    </div>
</div>    