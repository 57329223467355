import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';
import { ValidatorsService } from 'src/app/services/validators.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor( private formBuilder: FormBuilder,private validatorService : ValidatorsService) { }

  getForm(type, data?: any, def?: any) {
    switch (type) {
      case 'dataDisk':{
        let tempForm = this.formBuilder.group({
          lunArray:this.formBuilder.array([]),
          // lun:[null,[this.validatorService.onlyNumberValidator,Validators.minLength(0),Validators.maxLength(63)]],
          existDataDisk:[null],
        })
        return tempForm;
      }
      case 'createDataDisk':{
        let tempForm = this.formBuilder.group({
          name : [data && data.name ? data.name : null,[Validators.required]],
          sourceType : [data && data.sourceType ? data.sourceType : 'none',[Validators.required]],
          snapshot:['none'],
          size : [data && data.size ? data.size : null],
          encryptionType : ['EncryptionAtRestWithPlatformKey'],
          diskEncryption:[null],
          enableSharedDisk : [data && data.enableSharedDisk ? data.enableSharedDisk : null],
          maxShares:[null]
        })
        return tempForm;
      }
      case 'connectVM':{
        let tempForm = this.formBuilder.group({
          rdpIp : [data && data.rdpIp ? data.rdpIp : null,[Validators.required]],
          rdpPort : [data && data.rdpPort ? data.rdpPort : 3389,[Validators.required]],
          sshPrivateKey : [data && data.sshPrivateKey ? data.sshPrivateKey : 'chmod 400 username.pem'],
          sshPrivatePath : [data && data.sshPrivatePath ? data.sshPrivatePath : null],
          sshCommand : [data && data.sshCommand ? data.sshCommand : 'ssh -i <private key path> azureuser@52.170.19.26']
        })
        return tempForm;
      }
      case 'autoShutdown':{
        let tempForm = this.formBuilder.group({
          enableAutoShutdown : [data && data.enableAutoShutdown ? data.enableAutoShutdown : true],
          shutdownTime : [data && data.shutdownTime ? data.shutdownTime : null],
          timeZone : [data && data.timeZone ? data.timeZone : null],
          notifyShutdown : [data && data.notifyShutdown ? data.notifyShutdown : false],
          email : [data && data.email ? data.email : null,[this.validatorService.validateEmail]]
        })
        return tempForm;
      }
      case 'capture':{
        let tempForm = this.formBuilder.group({
          subscription : [data && data.subscription ? data.subscription : null],
          resourceGroup : [data && data.resourceGroup ? data.resourceGroup : null],
          region : [data && data.region ? data.region : null,[Validators.required]],
          shareImageComputeGallery : [data && data.shareImageComputeGallery ? data.shareImageComputeGallery : true],
          autoDelete : [data && data.autoDelete ? data.autoDelete : false],
          zoneResilincy : [data && data.zoneResilincy ? data.zoneResilincy : false],
          managedImageName : [data && data.managedImageName ? data.managedImageName : null],
          targetComputeGallery : [data && data.targetComputeGallery ? data.targetComputeGallery : null],
          osState : [data && data.osState ? data.osState : 'Generalized'],
          newAzureComputeGallery:[null],
          targetImageDefinition : [data && data.targetImageDefinition ? data.targetImageDefinition : null],
          versionNumber : [data && data.versionNumber ? data.versionNumber : null],
          excludeLatest : [data && data.excludeLatest ? data.excludeLatest : false],
          endLifeDate : [data && data.endLifeDate ? data.endLifeDate : null,[this.validatorService.futureDateValidator]],
          defaultReplicaCount : [data && data.defaultReplicaCount ? data.defaultReplicaCount : 1,[Validators.required,this.validatorService.onlyNumberValidator,Validators.minLength(1),Validators.maxLength(50)]],
          replicationArray : this.formBuilder.array([this.getForm('replicationArray')]),
          tagsArray : this.formBuilder.array([this.addTagsFormArray(data)]),
        })
        return tempForm;
      }
      case 'captureVMImageDefinition':{
        let tempForm = this.formBuilder.group({
        imageDefinitionName:[null ,[Validators.required]],
        osType : [null],
        vmGeneration : [data && data.versionNumber ? data.versionNumber : null,[Validators.required]],
        publisher : [data && data.excludeLatest ? data.excludeLatest : null,[Validators.required]],
        offer : [data && data.endLifeDate ? data.endLifeDate : null,[Validators.required]],
        sku : [data && data.endLifeDate ? data.endLifeDate : null ,[Validators.required]],
      })
      return tempForm;
      }
      case 'replicationArray':{
        let tempForm = this.formBuilder.group({
          targetRegion : [data && data.targetRegion ? data.targetRegion : null],
          targetReplicaCount : [data && data.targetReplicaCount ? data.targetReplicaCount : 1,[Validators.required,Validators.minLength(1),Validators.maxLength(50)]],
          storageAccountType : [data && data.storageAccountType ? data.storageAccountType : "Standard_LRS"],
        })
        return tempForm;
      }
      case 'lunArray':{
        let tempForm = this.formBuilder.group({
          lun : [data ? data.lun : null],
          diskName : [data && data.name ? data.name : null],
        })
        return tempForm;
      }
      case 'resetPassword':{
        let tempForm = this.formBuilder.group({
          mode : [data && data.mode ? data.mode : 'resetPwd',[Validators.required]],
          userName : [data && data.userName ? data.userName : null,[Validators.required,Validators.minLength(1),Validators.maxLength(64),this.validatorService.userNameValidator]],
          password : [data && data.password ? data.password : null,[Validators.required,Validators.minLength(12),Validators.maxLength(72)]],
          confirmPwd : [data && data.confirmPwd ? data.confirmPwd : null,[Validators.required]],
          sshKey : [data && data.sshKey ? data.sshKey : null],
        },
        {  
          validator: this.validatorService.MatchPassword('password', 'confirmPwd'),  
        } 
        )
        return tempForm;
      }
      case 'addressSpace':{
        let tempForm = this.formBuilder.group({
          addressSpaceArray : this.formBuilder.array([]),
        })
        return tempForm;
      }
      case 'subnet':{
        let tempForm = this.formBuilder.group({
          subnetArray : this.formBuilder.array([]),
        })
        return tempForm;
      }
      case 'dnsServer':{
        let tempForm = this.formBuilder.group({
          dnsServer : [data && data.dnsServer ? data.dnsServer : true],
          addressSpaceArray : this.formBuilder.array([]),
        })
        return tempForm;
      }
      case 'ddosProtection':{
        let tempForm = this.formBuilder.group({
          ddosProtection : [data && data.ddosProtection ? data.ddosProtection : false],
          ddosProtectionPlan : [data && data.ddosProtectionPlan ? data.ddosProtectionPlan : null],
        })
        return tempForm;
      }
      case 'createDdosProtectionPlan':{
        let tempForm = this.formBuilder.group({
          name : [data && data.name ? data.name : null,[Validators.required]],
          tagsArray : this.formBuilder.array([this.addTagsFormArray(data)]),
        })
        return tempForm;
      }
      case 'addAddressSpace':{
        return this.addAddressSpaceFormArray(data);
      }
      case 'addNATRule':{
        return this.addVPNNATRuleArray(data)
      }
      case 'addCustomAzureIP':{
        return this.addCustomAzureIPArray(data);
      }
      case 'addSecCustomAzureIP':{
        return this.addSecCustomAzureIPArray(data);
      }
      case 'addRootCertificates':{
        return this.addRootCertificatesArray(data);
      }
      case 'addRevokedCertificates':{
        return this.addRevokedCertificatesArray(data);
      }
      case 'addSubnet':{
        return this.addSubnetFormArray(data);
      }
      case 'editSubnet':{
        return this.editSubnetFormArray(data);
      }
      case 'addTags':{
        return this.addTagsFormArray(data);
      }
      case 'addVMScaleSetDataDiskArray':{
        return this.addVMScaleSetDataDiskArray(data);
      }
      case 'addScaleConditonArray':{
        return this.addScaleConditonArray(data);
      }
      case 'addtrafficArray':{
        return this.addtrafficArray(data);
      }
      case 'changeFirewallPolicy':{
        return this.changeFirewallPolicy(data);
      }
      case 'addFirewallRuleArray':{
        return this.addFirewallRuleArray(data);
      }
      case 'addVMPool':{
        return this.addVMPool(data);
      }
      case 'addVMScaleSetPool':{
        return this.addVMScaleSetPool(data);
      }
      case 'addIPtoPool':{
        return this.addIPtoPool(data);
      }
      case 'addExclusiveArray':{
        return this.addExclusiveArray(data);
      }
      case 'addbackendTargetArray':{
        return this.addbackendTargetArray(data);
      }
      case 'addHostNameArray':{
        return this.addHostNameArray(data);
      }
      case 'addInstanceArray':{
        return this.addInstanceArray(data);
      }
      case 'configuration':{
        let tempForm = this.formBuilder.group({
          ipAssignment : [data && data.ipAssignment ? data.ipAssignment : null],
          ipAddress : [data && data.ipAddress ? data.ipAddress : null],
          idleTimeout : [data && data.idleTimeout ? data.idleTimeout : null],
          dnsName : [data && data.dnsName ? data.dnsName : null,[this.validatorService.smallCharacterValidator]],
        })
        return tempForm;
      }
      case 'associatePublicIp':{
        let tempForm = this.formBuilder.group({
          resourceType : [data && data.resourceType ? data.resourceType : 1],
          loadBalancer : [data && data.loadBalancer ? data.loadBalancer : null,[Validators.required]],
          networkInterface:[data && data.networkInterface ? data.networkInterface : null,[Validators.required]],
        })
        return tempForm;
      }
      case 'dissociatePublicIp':{
        let tempForm = this.formBuilder.group({
          name : [data && data.name ? data.name : null],
          associatedTo : [data && data.associatedTo ? data.associatedTo : null],
        })
        return tempForm;
      }
      case 'inboundSecurityRules':{
        let tempForm = this.formBuilder.group({
          source : [data && data.source ? data.source : 'Any'],
          sourceIP:[null],
          destinationIP:[null],
          sourcePort : [data && data.sourcePort ? data.sourcePort : null,[Validators.required]],
          destination : [data && data.destination ? data.destination : 'Any'],
          service : [data && data.service ? data.service : 'Custom'],
          destinationPort : [data && data.destinationPort ? data.destinationPort : null,[Validators.required]],
          protocol : [data && data.protocol ? data.protocol : 'Any'],
          action : [data && data.action ? data.action : 'Allow'],
          priority : [data && data.priority ? data.priority : null,[Validators.required]],
          name : [data && data.name ? data.name : null,[Validators.required]],
          description : [data && data.description ? data.description : null],
        })
        return tempForm;
      }
      case 'outboundSecurityRules':{
        let tempForm = this.formBuilder.group({
          source : [data && data.source ? data.source : 'Any'],
          sourceIP:[null],
          destinationIP:[null],
          sourcePort : [data && data.sourcePort ? data.sourcePort : null,[Validators.required]],
          destination : [data && data.destination ? data.destination : 'Any'],
          service : [data && data.service ? data.service : 'Custom'],
          destinationPort : [data && data.destinationPort ? data.destinationPort : null,[Validators.required]],
          protocol : [data && data.protocol ? data.protocol : 'Any'],
          action : [data && data.action ? data.action : 'Allow'],
          priority : [data && data.priority ? data.priority : null,[Validators.required]],
          name : [data && data.name ? data.name : null,[Validators.required]],
          description : [data && data.description ? data.description : null],
        })
        return tempForm;
      }
      case 'networkInterface':{
        let tempForm = this.formBuilder.group({
          networkInterface : [data && data.source ? data.source : null],
        })
        return tempForm;
      }
      case 'nsgSubnet':{
        let tempForm = this.formBuilder.group({
          virtualNetwork : [data && data.source ? data.source : null],
          subnet : [data && data.source ? data.source : null],
        })
        return tempForm;
      }

      case 'assignPolicyInitiatives':{
        let tempForm = this.formBuilder.group({
          scope : [data && data.scope ? data.scope : null,[Validators.required]],
          subscription : [data && data.subscription ? data.subscription : null],
          rgName : [data && data.rgName ? data.rgName : null],
          exclusionRgName : [data && data.exclusionRgName ? data.exclusionRgName : null],
          resource : [data && data.resource ? data.resource : null],
          exclusion : [data && data.exclusion ? data.exclusion : null],
          definition : [data && data.policyDefinition ? data.policyDefinition : null,[Validators.required]],
          assignment : [data && data.assignment ? data.assignment : null,[Validators.required]],
          description : [data && data.description ? data.description : null],
          policyEnforcement : [data && data.policyEnforcement ? data.policyEnforcement : true],
          assignedBy : [data && data.assignedBy ? data.assignedBy : null],
          nComplianceMessage : [data && data.nComplianceMessage ? data.nComplianceMessage : null],
        })
        return tempForm;
      }
      case 'nicNsg':{
        let tempForm = this.formBuilder.group({
          nsgList : ['None'],
        })
        return tempForm;
      }
      case 'nicDnsServer':{
        let tempForm = this.formBuilder.group({
          dnsServer : [null],
          addressSpaceArray : this.formBuilder.array([this.addAddressSpaceFormArray()]),

        })
        return tempForm;
      }
      case 'nicIPConfiguration':{
        let tempForm = this.formBuilder.group({
          ipForwarding : [null],
          subnetIP : [null],
          name:[null,[Validators.required]],
          type:[null],
          allocation : [null],
          publicIPAddress : [null],
          ipAddress : [null],
          publicIPAddressInput : [null],
          publicIPName: [null],
          sku: [null],
          assigment:[null],
          // subnetIP : [null],
        })
        return tempForm;
      }
      case 'configurationLNG':{
        let tempForm = this.formBuilder.group({
          ipAddress : [null,[Validators.required]],
          addressSpaceArray : this.formBuilder.array([this.addAddressSpaceFormArray()]),
          configBGP : [null],
          ASN:[null],
          bgpIPAddress:[null],
        })
        return tempForm;
      }
      case 'connectionLNG':{
        let tempForm = this.formBuilder.group({
          name : [null,[Validators.required]],
          connectionType : [null,[Validators.required]],
          vpnName:[null,[Validators.required]],
          localNetName:[null,[Validators.required]],
          sharedKey:[null,[Validators.required]],
          ipAddress:[null],
          BGP:[null],
          customBGP:[null],
          IKEProtocol:[null],
          sub:[null,[Validators.required]],
          rg:[null,[Validators.required]],
          location:[null,[Validators.required]],
        })
        return tempForm;
      }
      case 'diskConfiguration':{
        let tempForm = this.formBuilder.group({
          sharedDisk : [null],
          maxShares:[null],
          onDemandBursting : [null],
        })
        return tempForm;
      }
      case 'diskSizeAndPerformance':{
        let tempForm = this.formBuilder.group({
          diskSKU : [null],
          diskSize:[null,[Validators.required],[Validators.pattern('^[0-9]+$')]],
          performance : [null],
        })
        return tempForm;
      }
      case 'diskNetworking':{
        let tempForm = this.formBuilder.group({
          networkConnectivity : [null],
          diskAccess : [null],
        })
        return tempForm;
      }
      case 'encryption':{
        let tempForm = this.formBuilder.group({
          encryptionType : [null],
          diskEncryption : [null],
        })
        return tempForm;
      }
      case 'vpnConnection':{
        let tempForm = this.formBuilder.group({
          name : [null,[Validators.required]],
          connectionType : [null,[Validators.required]],
          vpnName:[null,[Validators.required]],
          vpnName2:[null],
          localNetName:[null],
          sharedKey:[null,[Validators.required]],
          ipAddress:[null],
          BGP:[null],
          customBGP:[null],
          IKEProtocol:[null],
          ingressNAT:[null],
          egressNAT:[null],
          sub:[null,[Validators.required]],
          rg:[null,[Validators.required]],
          location:[null,[Validators.required]],
        })
        return tempForm;
      }
      case 'vpnConfig':{
        let tempForm = this.formBuilder.group({
          sku : [null,[Validators.required]],
          activeMode : [null],
          gatewayIP: [null],
          publicIp2:[null],
          publicIp3:[null],
          configBGP:[null],
          asn:[null],
          customAzureIPArray:this.formBuilder.array([this.addCustomAzureIPArray()]),
          secCustomAzureIPArray:this.formBuilder.array([this.addSecCustomAzureIPArray()]),
        })
        return tempForm;
      }
      case 'vpnPointToSite':{
        let tempForm = this.formBuilder.group({
          addressPool : [null,[Validators.required]],
          tunnelType : ['OpenVPN'],
          authenticationType: [null],
          rootCertificatesArray : this.formBuilder.array([this.addRootCertificatesArray()]),
          revokedCertificatesArray : this.formBuilder.array([this.addRevokedCertificatesArray()]),
          primaryServerIP: [null],
          primaryServerSecret: [null],
          secServerIP: [null],
          secServerSecret: [null],
          tenant: [null],
          audience: [null],
          issuer: [null],
        })
        return tempForm;
      }
      case 'vpnNATRule':{
        let tempForm = this.formBuilder.group({
          routeTranslation : [null],
          vpnNATRuleArray : this.formBuilder.array([this.addVPNNATRuleArray()]),
        })
        return tempForm;
      }
      case 'VMScaleSetInstance':{
        let tempForm = this.formBuilder.group({
          instanceID : [null],
        })
        return tempForm;
      }
      case 'VMScaleSetUpgradePolicy':{
        let tempForm = this.formBuilder.group({
          upgradeMode : [null],
          rollingUpgrade : [null],
          pauseTime : [null],
          maxInstance : [null],
          maxUpgrade : [null],
          prioritize : [null],
          zoneUpgrade : [null],
        })
        return tempForm;
      }
      case 'VMScaleSetHealthAndRepair':{
        let tempForm = this.formBuilder.group({
          appHealth : [null],
          AHM : [null],
          protocal : [null],
          portNumber : [null],
          path : [null],
          automaticRepair : [null],
          gracePeriod : [null],
        })
        return tempForm;
      }
      case 'VMScaleSetConfiguration':{
        let tempForm = this.formBuilder.group({
          enableTermination : [null],
          terminationDelay : [null],
          enableProvisioning : [null],
          proximityGroup : [null],
          hostGroup : [null],
          capacityGroup : [null],
          secureBoot : [null],
          vTPM : [null],
        })
        return tempForm;
      }
      case 'VMScaleSetSize':{
        let tempForm = this.formBuilder.group({
          sizeSeries : [null],
          skuName : [null],
          skuCapacity : [null],
        })
        return tempForm;
      }
      case 'VMScaleSetDisk':{
        let tempForm = this.formBuilder.group({
          hostCatching : [null],
          vmScaleSetDataDiskArray : this.formBuilder.array([this.addVMScaleSetDataDiskArray()]),
        })
        return tempForm;
      }
      case 'VMScaleSetScaling':{
        let tempForm = this.formBuilder.group({
          scale:[null],
          overRideInstanceCount:[null],
          autoscaleName:[null],
          rg:[null],
          mode:[null],
          preLaunchSetup:[null],
          defScaleConditionName:[null],
          defScaleMode:[null],
          defInstanceMin:[null],
          defInstanceMax:[null],
          defInstanceDef:[null],
          defScaleInstanceCount:[null],
          scaleConditonArray : this.formBuilder.array([this.addScaleConditonArray()]),
          scaleInPolicy : [null],
        })
        return tempForm;
      }
      case 'VMScaleSetNetworking':{
        let tempForm = this.formBuilder.group({
          ipConfig : [null],
          vNet : [null],
          subnet : [null],
        })
        return tempForm;
      }
      case 'VMScaleSetLoadBalncer':{
        let tempForm = this.formBuilder.group({
          lbOptions : ['Azure load balancer'],
          lbSelector : [null,[Validators.required]],
          backendPool : [null,[Validators.required]],
          poolName : [null,[Validators.required]],
        })
        return tempForm;
      }
      case 'VMScaleSetNIC':{
        let tempForm = this.formBuilder.group({
          nicName : [null,[Validators.required]],
          vNet : [null],
          subnet : [null],
          NICNSG : [null],
          selectInbound:[null],
          configNSG:[null],
          publicInbound:[null],
          publicIP:[null],
          nsgName:[null]
        })
        return tempForm;
      }

      case 'appServiceDeployementSlots':{
        let tempForm = this.formBuilder.group({
          defaultTraffic : [null],
          trafficArray : this.formBuilder.array([this.addtrafficArray()]),
        })
        return tempForm;
      }
      case 'appServiceGeneralSetting':{
        let tempForm = this.formBuilder.group({
          stackType : [null],
          majorVersion : [null],
          minorVersion : [null],
          startupCommand : [null],
          ftpState:['AllAllowed'],
          httpVersion:[false],
          webSocket:[false],
          alwaysOn:[true],
          arrAffinity:[false],
          httpsOnly:[false],
          tlsVersion:['1.0'],
          remoteDebug:[false],
          clientDebug:['ignore'],
          exclusionPath:[null],
        })
        return tempForm;
      }
      case 'appServiceScaleUp':{
        let tempForm = this.formBuilder.group({
          type : ['production'],
          skuName : [null],
          skuTier: [null],
        })
        return tempForm;
      }
      case 'connectionSharedKey':{
        let tempForm = this.formBuilder.group({
          sharedKey : [null],
        })
        return tempForm;
      }
      case 'connectionNATRules':{
        let tempForm = this.formBuilder.group({
          ingressNAT : [null],
          egressNAT : [null],
        })
        return tempForm;
      }
      case 'connectionConfiguration':{
        let tempForm = this.formBuilder.group({
          azureIP : [null],
          bgp : [null],
          customBGP:[null],
          policy : [null],
          encryption : [null],
          integrity:[null],
          DHGroup : [null],
          IPsecEncryption : [null],
          IPsecIntegrity:[null],
          PFSGroup : [null],
          IPsecKB : [null],
          IPsecSec:[null],
          trafficSelector : [null],
          DPDTimeout : [null],
          connectionMode : [null],
          // protocol : [null],
          ingressNAT : [null],
          egressNAT : [null],
        })
        return tempForm;
      }
      case 'firewallIPConfig':{
        let tempForm = this.formBuilder.group({
          ipName : [null,[Validators.required]],
          ipAddress : [null,[Validators.required]],
        })
        return tempForm;
      }
      case 'firewallChangeFirewallPolicy':{
        let tempForm = this.formBuilder.group({
          policyName :this.formBuilder.array([this.changeFirewallPolicy()]),
        })
        return tempForm;
      }
      case 'computeStorageSqlDB':{
        let tempForm = this.formBuilder.group({
          serviceTier : [null],
          computeTier : [null],
          hardwareConfig : [null],
          maxVCore: [null],
          maxMemory: [null],
          vCores : [null],
          replicas:[null],
          minVCores : [null],
          autoPauseDelay: [null],
          days: [null],
          hrs : [null],
          mins : [null],
          dataMaxSize : [null],
          readScaleOut:[null],
          dtu:[null],
          databaseZone : [null],
          backupStorage : [null],
        })
        return tempForm;
      }
      case 'copySqlDB':{
        let tempForm = this.formBuilder.group({
          subscription : [null,[Validators.required]],
          rg : [null,[Validators.required]],
          dbName : [null,[Validators.required]],
          serverName : [null,[Validators.required]],
          sqlElasticPool : [null,[Validators.required]],
          elasticPool : [null],
          backupStorage : [null],
          tier : [null],
          hardwareConfig : [null],
          vCore : [null],
          memory : [null],
        })
        return tempForm;
      }
      case 'restoreSqlDB':{
        let tempForm = this.formBuilder.group({
          subscription : [null,[Validators.required]],
          rg : [null,[Validators.required]],
          sourceDB : [null,[Validators.required]],
          sourceType : [null,[Validators.required]],
          earliestPoint : [null,[Validators.required]],
          restoreDate : [null],
          restoreTime : [null],
          dbName : [null,[Validators.required]],
          serverName : [null,[Validators.required]],
          sqlElasticPool : [null,[Validators.required]],
          elasticPool : [null],
          backupStorage : [null],
          hardwareConfig : [null],
          tier : [null],
          vCore : [null],
          memory : [null],
        })
        return tempForm;
      }
      case 'firewallSettingSqlDB':{
        let tempForm = this.formBuilder.group({
          publicNetworkAccess : [null],
          minTLSversion : [null],
          connectionPolicy : [null],
          allowAzure: [null],
          firewallRuleArray :this.formBuilder.array([this.addFirewallRuleArray()]),
        })
        return tempForm;
      }
      case 'configureSqlVM':{
        let tempForm = this.formBuilder.group({
          serverLicense : [null],
          edition : [null],
        })
        return tempForm;
      }
      case 'additionalFeaturesSqlVM':{
        let tempForm = this.formBuilder.group({
          sqlServerMLService : [null],
        })
        return tempForm;
      }
      case 'patchingSqlVM':{
        let tempForm = this.formBuilder.group({
          automatedPatching : [null],
          schedule : [null],
          startHour : [null],
          windowDuration : [null],
        })
        return tempForm;
      }
      case 'lbFrontendIP':{
        let tempForm = this.formBuilder.group({
          frontendIPName : [null,[Validators.required]],
          ipType : [null],
          subnet : [null],
          publicIP : [null],
          publicPrefix : [null],
          gatewayLB : [null],
          publicIPName:[null],
          publicIPSKU : [null],
          publicIPTier : [null],
          publicIPAddress : [null],
          publicIPAssignment:[null],
          prefixSize:[null],
          publicIPZone:[null],
          publicIPRoutePrefer:[null],
        })
        return tempForm;
      }
      case 'lbHealthProbes':{
        let tempForm = this.formBuilder.group({
          probeName : [null,[Validators.required]],
          protocol : [null,[Validators.required]],
          port : [null,[Validators.required]],
          path : [null],
          interval : [null,[Validators.required]],
          threshold:[null,[Validators.required]],
        })
        return tempForm;
      }
      case 'loadBalancerRules':{
        let tempForm = this.formBuilder.group({
          lbRuleName : [null,[Validators.required]],
          frontendIP : [null,[Validators.required]],
          backendPool : [null,[Validators.required]],
          protocolLBRules : [null],
          frontendPort : [null,[Validators.required]],
          backendPort:[null,[Validators.required]],
          probe : [null,[Validators.required]],
          session : [null],
          idealTimeout : [null],
          tcpReset : [null],
          floatingIP : [null],
          outboundNetwork : [null],
          probeName : [null],
          protocol : [null],
          port : [null],
          path : [null],
          interval : [null],
          threshold : [null],
        })
        return tempForm;
      }
      case 'inboundRules':{
        let tempForm = this.formBuilder.group({
          inboundName : [null,[Validators.required]],
          type : [null,[Validators.required]],
          targetVM : [null],
          frontendIP : [null,[Validators.required]],
          frontendPort : [null,[Validators.required]],
          serviceTag : [null,[Validators.required]],
          poolMachinesCount : [null],
          backendPort:[null,[Validators.required]],
          protocol : [null],
          tcpReset : [null],
          idealTimeout : [null],
          floatingIP : [null],
        })
        return tempForm;
      }
      case 'outboundRules':{
        let tempForm = this.formBuilder.group({
          outboundName : [null,[Validators.required]],
          frontendIP : [null,[Validators.required]],
          protocol : [null],
          idealTimeout : [null],
          tcpReset : [null],
          backendPool : [null,[Validators.required]],
          portAllocation : [null],
          outboundPort:[null],
          portInstance : [null],
          backendInstance : [null],
        })
        return tempForm;
      }
      case 'lbBackendPool':{
        let tempForm = this.formBuilder.group({
          poolName : [null,[Validators.required]],
          vNet : [null],
          poolConfig : [null,[Validators.required]],
          vmPool: [null],
          vmScaleSet : [null],
          // vmPool: this.formBuilder.array([this.addVMPool()]),
          // vmScaleSet : this.formBuilder.array([this.addVMScaleSetPool()]),
          ipArray: this.formBuilder.array([this.addIPtoPool()]),
          // ipAddress : [null],
          // resourceName:[null]
        })
        return tempForm;
      }
      case 'bastionConfig':{
        let tempForm = this.formBuilder.group({
          tier : [null],
          bastionInstance : [null],
          copyPaste: [null],
          ipBasedConn : [null],
          kerberos: [null],
          native : [null],
        })
        return tempForm;
      }
      case 'appGatewayConfig':{
        let tempForm = this.formBuilder.group({
          tier : [null,[Validators.required]],
          capacityType : [null],
          instanceCount: [null],
          minInstance : [null],
          maxInstance: [null],
          http2 : [null,[Validators.required]],
        })
        return tempForm;
      }
      case 'appGatewayWebAppFirewall':{
        let tempForm = this.formBuilder.group({
          tier : [null,[Validators.required]],
          wafStatus : [null],
          wafMode: [null],
          exclusiveArray : this.formBuilder.array([this.addExclusiveArray()]),
          inspectReqBody: [null],
          maxReqSize: [null],
          fileLimit: [null],
        })
        return tempForm;
      }
      case 'appGatewayBackendPool':{
        let tempForm = this.formBuilder.group({
          poolName : [null,[Validators.required]],
          poolTarget : [null],
          backendTargetArray : this.formBuilder.array([this.addbackendTargetArray()]),
        })
        return tempForm;
      }
      case 'appGatewayBackendSetting':{
        let tempForm = this.formBuilder.group({
          settingName : [null,[Validators.required]],
          backendPort : [null,[Validators.required]],
          cookieAffinity : [null],
          affinityName:[null],
          connectionDraining : [null],
          drainTimeOut:[null],
          reqTimeOut : [null,[Validators.required]],
          backendPath : [null],
          newHost : [null],
          hostOverRide : [null],
          hostName : [null],
          useProbe : [null],
          probe : [null],
        })
        return tempForm;
      }
      case 'appGatewayListeners':{
        let tempForm = this.formBuilder.group({
          listenerName : [null,[Validators.required]],
          frontendIP : [null,[Validators.required]],
          port : [null],
          listenerType:[null],
          hostType : [null],
          hostName:[null],
          ruleName:[null],
          hostNameArray : this.formBuilder.array([this.addHostNameArray()]),
          errorPgUrl : [null,[Validators.required]],
          badGateway : [null],
          forbidden : [null],
        })
        return tempForm;
      }
      case 'appGatewayRoutingRule':{
        let tempForm = this.formBuilder.group({
          ruleName : [null,[Validators.required]],
          priority : [null,[Validators.required]],
          listener : [null,[Validators.required]],
          targetType:[null],
          backendTarget : [null],
          backendSetting:[null],
          redirectionType:[null],
          redirectionTarget : [null],
          targetUrl : [null],
          targetListener : [null],
          includeQueryString : [null],
          includePath : [null],   
        })
        return tempForm;
      }
      case 'appGatewayProbe':{
        let tempForm = this.formBuilder.group({
          probeName : [null,[Validators.required]],
          protocol : [null,[Validators.required]],
          hostName : [null],
          pickHostName:[null],
          pickPort : [null],
          port:[null],
          path:[null],
          interval : [null],
          timeOut : [null],
          threshold : [null],
          useProbe : [null],
          bodyMatch : [null],
          codeMatch : [null],
          backendSettings : [null],
          testHealthProbe:[null]
        })
        return tempForm;
      }
      case 'stoargeAccountConfig':{
        let tempForm = this.formBuilder.group({
          performance : [null],
          secureTransfer : [null],
          allowBlob:[null],
          allowAccountKey : [null],
          allowUpperLimit:[null],
          days:[null],
          hours:[null],
          mins : [null],
          seconds : [null],
          defaultAAD : [null],
          minimumTLSVersion : [null],
          accessTier : [null],
          replication : [null],
          largeFileShares : [null],
        })
        return tempForm;
      }
      case 'stoargeAccountEncryption':{
        let tempForm = this.formBuilder.group({
          encryptionType : [null],
          encryptionKey : [null],
          keyURI:[null],
          sub : [null],
          identityType:[null],
        })
        return tempForm;
      }
      case 'stoargeAccountDataProtection':{
        let tempForm = this.formBuilder.group({
          azureBackup : [null],
          restoreContainer : [null],
          deleteBlobs:[null],
          deleteContainer : [null],
          blobsVersion:[null],
          blobChangeFeed:[null],
          changeFeedLogs:[null],
          changeFeedDays:[null],
        })
        return tempForm;
      }
      case 'stoargeAccountNetworking':{
        let tempForm = this.formBuilder.group({
          allowAccess : [null],
          routingPreference : [null],
          networkRouting:[null],
          internetRouting : [null],
          instanceArray : this.formBuilder.array([this.addInstanceArray()]),
          ipArray : this.formBuilder.array([this.addAddressSpaceFormArray()]),
          addClientIP:[null],
          allowAS:[null],
          storageLogging:[null],
          storageMetrics:[null],
        })
        return tempForm;
      }
    }
  }
  addInstanceArray(data?:any){
    let tempForm = this.formBuilder.group({
      instanceName :[data && data.instanceName ? data.instanceName : ''],
      resourceType :[data && data.resourceType ? data.resourceType : ''],
    })
    return tempForm;
  }
  addHostNameArray(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : ''],
    })
    return tempForm;
  }
  addbackendTargetArray(data?:any){
    let tempForm = this.formBuilder.group({
      targetType :[data && data.targetType ? data.targetType : 'IP address or FQDN'],
      target:[data && data.target ? data.target : ''],
    })
    return tempForm;
  }
  addExclusiveArray(data?:any){
    let tempForm = this.formBuilder.group({
      field :[data && data.field ? data.field : ''],
      operator:[data && data.operator ? data.operator : ''],
      selector:[data && data.selector ? data.selector : '']
    })
    return tempForm;
  }
  addIPtoPool(data?:any){
    let tempForm = this.formBuilder.group({
      ipAddress :[data && data.ipAddress ? data.ipAddress : ''],
      resourceName:[data && data.resourceName ? data.resourceName : '']
    })
    return tempForm;
  }
  addVMScaleSetPool(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : ''],
      checked:[data && data.checked ? data.checked : false]
    })
    return tempForm;
  }
  addVMPool(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : ''],
      checked:[data && data.checked ? data.checked : false]
    })
    return tempForm;
  }
  addFirewallRuleArray(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : ''],
      startIP :[data && data.startIP ? data.startIP : ''],
      endIP :[data && data.endIP ? data.endIP : ''],
    })
    return tempForm;
  }
  changeFirewallPolicy(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : ''],
    })
    return tempForm;
  }
  addtrafficArray(data?:any){
    let tempForm = this.formBuilder.group({
      value :[data && data.value ? data.value : ''],
    })
    return tempForm;
  }
  addScaleConditonArray(data?:any){
    let tempForm = this.formBuilder.group({
      scaleConditionName:[data && data.scaleConditionName ? data.scaleConditionName : ''],
      scaleMode:[data && data.scaleMode ? data.scaleMode : true],
      instanceMin:[data && data.instanceMin ? data.instanceMin : '2'],
      instanceMax:[data && data.instanceMax ? data.instanceMax : '2'],
      instanceDef:[data && data.instanceDef ? data.instanceDef : '2'],
      scaleInstanceCount:[data && data.scaleInstanceCount ? data.scaleInstanceCount : '2'],
      schedule:[data && data.schedule ? data.schedule : true],
      timeZone:[data && data.timeZone ? data.timeZone : ''],
      startDate:[data && data.startDate ? data.startDate : ''],
      startTime:[data && data.startTime ? data.startTime : ''],
      endDate:[data && data.endDate ? data.endDate : ''],
      endTime:[data && data.endTime ? data.endTime : ''],
    })
    return tempForm;
  }
  addVMScaleSetDataDiskArray(data?:any){
    let tempForm = this.formBuilder.group({
      lun :[data && data.lun ? data.lun : ''],
      lunList :[data && data.lunList ? data.lunList : ''],
      storageType :[data && data.storageType ? data.storageType : ''],
      size :[data && data.size ? data.size : ''],
      encryption :[data && data.encryption ? data.encryption : ''],
      hostCatchingDisk :[data && data.hostCatchingDisk ? data.hostCatchingDisk : ''],
    })
    return tempForm;
  }

  addVPNNATRuleArray(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : ''],
      type :[data && data.type ? data.type : ''],
      IPConfig :[data && data.IPConfig ? data.IPConfig : ''],
      mode :[data && data.mode ? data.mode : ''],
      internalMapping :[data && data.internalMapping ? data.internalMapping : ''],
      externalMapping :[data && data.externalMapping ? data.externalMapping : ''],
    })
    return tempForm;
  }

  addCustomAzureIPArray(data?:any){
    let tempForm = this.formBuilder.group({
      value :[data && data.value ? data.value : ''],
    })
    return tempForm;
  }
  addSecCustomAzureIPArray(data?:any){
    let tempForm = this.formBuilder.group({
      value :[data && data.value ? data.value : ''],
    })
    return tempForm;
  }
  addAddressSpaceFormArray(data?:any){
    let tempForm = this.formBuilder.group({
      value :[data && data.value ? data.value : ''],
    })
    return tempForm;
  }
  addRootCertificatesArray(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : ''],
      value :[data && data.value ? data.value : ''],
    })
    return tempForm;
  }
  addRevokedCertificatesArray(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : ''],
      value :[data && data.value ? data.value : ''],
    })
    return tempForm;
  }

  addSubnetFormArray(data?:any){
    let tempForm = this.formBuilder.group({
      vnetName :[data && data.vnetName ? data.vnetName : null],
      subnetName :[data && data.subnetName ? data.subnetName : null,[Validators.required]],
      subnetAddressSpace :[data && data.subnetAddressSpace ? data.subnetAddressSpace : null,[Validators.required]],
      description :[data && data.description ? data.description : null],
      natGateway :[data && data.natGateway ? data.natGateway : null],
      nsg :[data && data.nsg ? data.nsg : null],
      routeTable :[data && data.routeTable ? data.routeTable : null],
      services :[data && data.services ? data.services : null],
      delegatedSubnet :[data && data.delegatedSubnet ? data.delegatedSubnet : null],
    })
    return tempForm;
  }

  addTagsFormArray(data?:any){
    let tempForm = this.formBuilder.group({
      name :[data && data.name ? data.name : null],
      value :[data && data.value ? data.value : null],
    })
    return tempForm;
  }
  editSubnetFormArray(data?:any){
    let tempForm = this.formBuilder.group({
      vnetName :[data && data.vnetName ? data.vnetName : null],
      subnetName :[data && data.name ? data.name : null],
      subnetAddressSpace :[data && data.properties.addressPrefix ? data.properties.addressPrefix : null],
      natGateway :[data && data.properties.natGateway ? data.properties.natGateway : null],
      nsg :[data && data.properties.nsg ? data.properties.nsg : null],
      routeTable :[data && data.properties.routeTable ? data.properties.routeTable : null],
      services :[data && data.properties.services ? data.properties.services : []],
      delegatedSubnet :[data && data.properties.delegations ? data.properties.delegations : []],
    })
    return tempForm;
  }

}
