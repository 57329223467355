<div class="introduction">
  

            <p class="heading" id="21">Security Recommendations   </p>
            <p class="mb-0" >Provides all the network and cybersecurity related best practices 
                & recommendations to reduce vulnerabilities and to avoid security breaches.     </p>
                <img class="image mb-3" src="assets\user-manual\security\Recommendations.svg" >

            <p class="heading" style="font-size: 32px;" id="22">Coverage </p>
            <p class="mb-0" >Tile displays the number of implemented controls 
                (group of recommendations) and individual recommendations out of total.     </p>
                <img class="image" style="width:50%" src="assets\user-manual\security\11.svg" >
                <figcaption class="caption col-md-6">Security recommendations - coverage</figcaption>

            <p class="heading" style="font-size: 32px;" id="23">Resource Health   </p>
            <p class="mb-0" >Tile displays resource state healthy for resources which either aren't impacted, or the issue is remediated, unhealthy for which are still impacted by the identified issue,
                and not applicable for which the recommendation does not have a specific reason.     </p>
                <img class="image" style="width:50%" src="assets\user-manual\security\12.svg" >
                <figcaption class="caption col-md-6">Resource health </figcaption>

            <p class="heading" style="font-size: 32px;" id="24">Recommendations  </p>
            <p class="mb-0" >Table displays list of recommended best practices to be followed in
                 the environment to strengthen the security posture.      </p>
                 <img class="image"  src="assets\user-manual\security\13.svg" >
                 <figcaption class="caption">List of recommendations </figcaption>
</div>     
        