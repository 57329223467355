import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IPv4 } from 'ip-matching';
import * as _ from 'lodash';
import { LoaderService } from 'src/app/services/loader.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-virtual-network-gateway',
  templateUrl: './virtual-network-gateway.component.html',
  styleUrls: ['./virtual-network-gateway.component.scss']
})
export class VirtualNetworkGatewayComponent implements OnInit {
  @Input() projectData : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();

  showView:boolean=false;
  vngForm:FormGroup;
  inputError:any={};
  resourceGroupList:any=[];
  availabilityZoneList:any=[{
    name:'Zone-redundant'
  },{
    name:'1'
  },{
    name:'2'
  },{
    name:'3'
  }];
  publicIPAddressList:any=[];
  vngList:any=[];
  generationList:Array<{name:String}>=[{
    name:'Generation2'
  },{
    name:'Generation1'
  }];
  basicGenerationList:Array<{name:String}>=[{
    name:'Generation1'
  }];
  generation2List:Array<{name:String}>=[{
    name:'Generation2'
  }];
  tempGenerationList:Array<{name:String}>=[];
  tempSKUList:Array<{name:String}>=[];
  basicSKUList:Array<{name:String}>=[{
    name:'Basic'
  }];
  expressSKUList:Array<{name:String}>=[{
    name:'Standard'
  },{
    name:'High performance'
  },{
    name:'Ultra performance'
  },{
    name:'ErGw1AZ'
  },{
    name:'ErGw2AZ'
  },{
    name:'ErGw3AZ'
  }];
  skuList:Array<{name:String}>=[{
    name:'Basic'
  },{
    name:'VpnGw1'
  },{
    name:'VpnGw2'
  },{
    name:'VpnGw3'
  },{
    name:'VpnGw4'
  },{
    name:'VpnGw5'
  },{
    name:'VpnGw1AZ'
  },{
    name:'VpnGw2AZ'
  },{
    name:'VpnGw3AZ'
  },{
    name:'VpnGw4AZ'
  },{
    name:'VpnGw5AZ'
  }];
  tempPublicIPSKUList:any=[];
  publicIPSKUList:any=[{
    name:'Basic'
  },{
    name:'Standard'
  }]
  standPublicIPSKUList:any=[{
    name:'Standard'
  }];
  tempPublicIPAddressList:any=[];
  PIPList: any=[];
  VMList: any=[];

  constructor(private loader:LoaderService,private util:UtilService,private fb:FormBuilder) {
    
   }

  async ngOnInit(): Promise<void> {  
    this.tempGenerationList=this.generationList; 
    this.tempSKUList=this.skuList; 
    this.initForm(); 
    this.loader.showLoader();
    await this.getResourceGroupList();
    let parentResourceData,resourceObj;
    await this.getListByPropName('virtualNetworkGateways',undefined,undefined);
    await this.getListByPropName('publicIPAddresses',undefined,undefined);
    await this.getExistNewlyResourceDetails('virtualNetworkGateways');
    await this.getExistNewlyResourceDetails('virtualMachines');
    await this.getExistNewlyResourceDetails('publicIPAddress');
    if(this.parentResourceDtls&&this.parentResourceDtls.resourceName&&this.sideSheetViewType=='new'){
      // this.synapseAnalyticsmodel.resourceData.subnet=this.parentResourceDtls.resourceName;
      parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
      this.vngForm.patchValue({
        resourceData:{
          vnetName:parentResourceData.vnetName,
          resourceGroup:parentResourceData.resourceGroup,
          gatewaySubnetAddress:parentResourceData.subnetAddressSpace,
          assignment:'dynamic',
          sku:'VpnGw2',
          generation:'Generation2',
          publicIPAddressName:parentResourceData.vnetName+"-pip"
        }
      })
      // .resourceData.vnetName=parentResourceData.vnetName;
      resourceObj=this.resourceGroupList.find(dt=>parentResourceData.resourceGroup&&dt.name.toLowerCase()==parentResourceData.resourceGroup.toLowerCase());
      // this.synapseAnalyticsmodel.resourceData.resourceGroup=resourceObj.name;
      // this.synapseAnalyticsmodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
      //this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
    }

    if(this.sideSheetViewType=='edit'){
      await this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null,null,null,true).then(async res=>{
        this.vngForm.patchValue({
          ...res,
          resourceData:JSON.parse(res.resourceData)
        });
        resourceObj=this.resourceGroupList.find(dt=>JSON.parse(res.resourceData).resourceGroup&&dt.name.toLowerCase()==JSON.parse(res.resourceData).resourceGroup.toLowerCase());
      });
    }
    // if(resourceObj&&resourceObj.location){
    //   this.tempPublicIPAddressList=this.publicIPAddressList.filter((dt)=>!dt.properties||!dt.properties.ipConfiguration)
    //   this.tempPublicIPAddressList=this.tempPublicIPAddressList.filter((dt)=>dt.location.toLowerCase()===resourceObj.location.toLowerCase());
    // }
    this.vngForm.controls.resourceData.get('resourceGroup').disable();
    this.vngForm.controls.resourceData.get('vnetName').disable();
    this.vngForm.controls.resourceData.get('gatewaySubnetAddress').disable();
    this.vngForm.controls.resourceData.get('availabilityZone').disable();
    this.setDefaultValidator();
    this.changeIPAddressList();
    this.loader.hideLoader();
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType === 'virtualNetworkGateways') {
        res.forEach(dt=>this.vngList.push(dt));
      } 
      if(resourceType === 'virtualMachines') {
        this.VMList=res;
      } 
      if(resourceType === 'publicIPAddress') {
        this.PIPList=res;
      } 
    },err=>{
    });
  }

  initForm(): void {
    this.vngForm=this.fb.group({
      resourceData:this.fb.group({
        resourceGroup:[null,[Validators.required]],
        vnetName:[null],
        gatewaySubnetAddress:[null],
        gatewayType:['Vpn',[Validators.required]],
        vpnType:['RouteBased'],
        sku:['VpnGw2'],
        generation:['Generation2'],
        publicAddressIPType:['Basic'],
        publicIPType:['new'],
        publicIPAddressName:[null,Validators.compose([Validators.maxLength(80),this.patternValidator(),this.duplicateCheckValidator(this.publicIPAddressList,'publicIPAddressName')])],
        assignment:[{value:'dynamic',disabled:true}],
        publicIPAddress:[null],
        publicIPSKU:['Basic'],
        activeMode:['disabled'],
        secondPIPType:['new'],
        secondPIPName:[null,Validators.compose([Validators.maxLength(80),this.patternValidator(),this.duplicateCheckValidator(this.publicIPAddressList,'secondPIPName')])],
        secondPIPAddress:[null],
        configureBGP:['disabled'],
        asn:['65515'],
        customIPAddress:[null,Validators.compose([this.ipAddressValidator()])],
        secondCustomIPAddress:[null,Validators.compose([this.ipAddressValidator()])],
        availabilityZone:[null],
        availabilityZonePIP:[null],
        tagList:this.fb.array([]),
      }),
      resourceId:[null],
      resourceType:['virtualNetworkGateways'],
      createdBy:[null],
      resourceName:['',Validators.compose([Validators.required,Validators.maxLength(80),this.patternValidator(),this.duplicateCheckValidator(this.vngList,null)])],
      comments:['',[Validators.maxLength(300)]]
    });
    this.addTags();
    
  }

  setDefaultValidator(){
    this.vngForm.get('resourceName').setValidators(Validators.compose([Validators.required,Validators.maxLength(80),this.patternValidator(),this.duplicateCheckValidator(this.vngList,null)]));
    this.vngForm.get('resourceName').updateValueAndValidity();
    this.setValidatorForAddressName();
  }

  setValidatorForAddressName(){
    if(this.vngForm.controls.resourceData.value.publicIPType=='new'){
      this.vngForm.controls.resourceData.get('publicIPAddressName').setValidators(Validators.compose([Validators.required,Validators.maxLength(80),this.patternValidator(),this.duplicateCheckValidator(this.publicIPAddressList,'publicIPAddressName')]));
    } else {
      this.vngForm.controls.resourceData.get('publicIPAddressName').setValidators(Validators.compose([Validators.maxLength(80),this.patternValidator(),this.duplicateCheckValidator(this.publicIPAddressList,'publicIPAddressName')]));
    }
    if(this.vngForm.controls.resourceData.value.gatewayType=='Vpn'&&this.vngForm.controls.resourceData.value.activeMode=='enabled'&&this.vngForm.controls.resourceData.value.secondPIPType=='new'){
      this.vngForm.controls.resourceData.get('secondPIPName').setValidators(Validators.compose([Validators.required,Validators.maxLength(80),this.patternValidator(),this.duplicateCheckValidator(this.publicIPAddressList,'secondPIPName')]));
    } else {
      this.vngForm.controls.resourceData.get('secondPIPName').setValidators(Validators.compose([Validators.maxLength(80),this.patternValidator(),this.duplicateCheckValidator(this.publicIPAddressList,'secondPIPName')]));
    }
    this.vngForm.controls.resourceData.get('publicIPAddressName').updateValueAndValidity();
    this.vngForm.controls.resourceData.get('secondPIPName').updateValueAndValidity();
  }

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      } else if(!(this.isCharALetter(control.value[0])||this.isCharANumber(control.value[0]))){
        return {invalidName:true}
      } else if(!(this.isCharALetter(control.value[control.value.length-1])||this.isCharANumber(control.value[control.value.length-1])||control.value[control.value.length-1]==='_')) {
        return {invalidName:true}
      }

      for(let input of control.value){
        if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-'||input=='.'||input=='_')){
          return {invalidName:true}
        }
      }
      return null
    };
  }

  validNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      } 

      for(let input of control.value){
        if(!input||this.isCharANumber(input)){
          return {invalidNumber:true}
        }
      }
      return null
    };
  }

  ipAddressValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      } 
      try {
        new IPv4(control.value);        
      } catch (error) {
        return {invalidIPAddress:true}
      }
      return null
    };
  }

  duplicateCheckValidator(list:any,resourceDataColumn:any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      } else if(!resourceDataColumn&&list.find(dt=>(dt.resourceName&&(dt.resourceId&&dt.resourceId!=this.vngForm.value.resourceId)&&dt.resourceName.toLowerCase()===control.value.toLowerCase())||(dt.name&&dt.name.toLowerCase()===control.value.toLowerCase()))){
        return {duplicateName:true}
      } else if(resourceDataColumn&&this.vngList.find(dt=>((dt.resourceId&&dt.resourceId!=this.vngForm.value.resourceId)&&JSON.parse(dt.resourceData)[resourceDataColumn]&&JSON.parse(dt.resourceData)[resourceDataColumn].toLowerCase()===control.value.toLowerCase()))){
        return {duplicateName:true}
      } else if(resourceDataColumn&&list.find(dt=>(dt.name&&dt.name.toLowerCase()===control.value.toLowerCase()))){
        return {duplicateName:true}
      } else if(resourceDataColumn&&resourceDataColumn==='secondPIPName'&&(this.vngList.find(dt=>(dt.resourceName&&(dt.resourceId&&dt.resourceId!=this.vngForm.value.resourceId)&&JSON.parse(dt.resourceData).publicIPAddressName&&JSON.parse(dt.resourceData).publicIPAddressName.toLowerCase()===control.value.toLowerCase()))||(this.vngForm.controls.resourceData.value.publicIPAddressName&&this.vngForm.controls.resourceData.value.publicIPAddressName.toLowerCase()===control.value.toLowerCase()))){
        return {duplicateName:true}
      } else if(resourceDataColumn&&this.VMList.find(dt=>JSON.parse(dt.resourceData)&&JSON.parse(dt.resourceData).publicIp&&JSON.parse(dt.resourceData).publicIp.name&&JSON.parse(dt.resourceData).publicIp.name.toLowerCase()===control.value.toLowerCase())){
        return {duplicateName:true}
      } else if(resourceDataColumn&&this.PIPList.find(dt=>dt.resourceName&&dt.resourceName.toLowerCase()===control.value.toLowerCase())){
        return {duplicateName:true}
      }
      
      return null
    };
  }

  changeGatewayType(): void {
    if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.gatewayType==='ExpressRoute')){
      this.tempSKUList=this.expressSKUList;
      this.vngForm.controls.resourceData.patchValue({
        sku:'Standard',
        publicIPSKU:'Basic',
        assignment:'dynamic'
      });
      // this.tempPublicIPSKUList=this.publicIPSKUList;
    } else {
      this.tempSKUList=this.skuList;
      this.vngForm.controls.resourceData.patchValue({
        sku:'VpnGw2'
      });
    }
    this.changeAZValidators();
    this.changeSecondPublicIPType();
    this.changeConfigureBGP();
    this.setValidatorForAddressName();
    this.changeIPAddressList();
  }

  changeSKU(): void {
    if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.sku==='Basic'||this.vngForm.controls.resourceData.value.sku==='VpnGw1'||this.vngForm.controls.resourceData.value.sku==='VpnGw1AZ')){
      this.tempGenerationList=this.basicGenerationList;
      this.vngForm.controls.resourceData.patchValue({generation:this.basicGenerationList[0].name});
    } else if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.sku==='VpnGw4'||this.vngForm.controls.resourceData.value.sku==='VpnGw5'||this.vngForm.controls.resourceData.value.sku==='VpnGw4AZ'||this.vngForm.controls.resourceData.value.sku==='VpnGw5AZ')){
      this.tempGenerationList=this.generation2List;
      this.vngForm.controls.resourceData.patchValue({generation:this.generation2List[0].name});
    } else {
      this.tempGenerationList=this.generationList;
    }

    if(this.vngForm.controls.resourceData.value.sku==='Basic'){
      this.vngForm.controls.resourceData.get('activeMode').disable();
      this.vngForm.controls.resourceData.get('configureBGP').disable();
      this.vngForm.controls.resourceData.patchValue({
        activeMode:'disabled',
        configureBGP:'disabled',
      });
    } else {
      this.vngForm.controls.resourceData.get('activeMode').enable();
      this.vngForm.controls.resourceData.get('configureBGP').enable();
    }

    if(this.vngForm.controls.resourceData.value.gatewayType==='ExpressRoute'&&this.vngForm.controls.resourceData.value.sku.includes('AZ')){
      this.tempPublicIPSKUList=this.standPublicIPSKUList;
      this.vngForm.controls.resourceData.patchValue({
        publicIPSKU:'Standard',
        assignment:'static',
        availabilityZonePIP:'Zone-redundant'
      });
    }
    this.changePIPSKU();
    this.changeIPAddressList();
    this.changeAZForPIPValidators();
    this.setValidatorForAddressName();
    this.changeAZValidators();
  }

  ipSKUChange(){
    this.vngForm.controls.resourceData.patchValue({
      assignment:this.vngForm.controls.resourceData.value.publicIPSKU==='Standard'?'static':'dynamic'
    });
  }

  changeIPAddressList(){
    let ipAddressList=[];
    let resourceObj=this.resourceGroupList.find(dt=>this.vngForm.controls.resourceData.value.resourceGroup&&dt.name.toLowerCase()==this.vngForm.controls.resourceData.value.resourceGroup.toLowerCase());
    ipAddressList=this.publicIPAddressList.filter((dt)=>!dt.properties||!dt.properties.ipConfiguration)
    if(resourceObj&&resourceObj.location){
      ipAddressList=ipAddressList.filter((dt)=>dt.location.toLowerCase()===resourceObj.location.toLowerCase());
    }
    if(this.vngForm.controls.resourceData.value.sku==='Basic'&&this.vngForm.controls.resourceData.value.gatewayType=='Vpn'){
      this.tempPublicIPAddressList=ipAddressList.filter((dt)=>(!dt.sku||dt.sku.name.toLowerCase()==='basic')&&(!dt.properties||dt.properties.publicIPAllocationMethod.toLowerCase()==='dynamic'));
    } else if(this.vngForm.controls.resourceData.value.sku.includes('AZ')&&this.vngForm.controls.resourceData.value.gatewayType=='Vpn'){
      this.tempPublicIPAddressList=ipAddressList.filter((dt)=>(!dt.sku||dt.sku.name.toLowerCase()==='standard')&&(!dt.properties||dt.properties.publicIPAllocationMethod.toLowerCase()==='static'));
    } else if(this.vngForm.controls.resourceData.value.sku.includes('VpnGw')&&this.vngForm.controls.resourceData.value.gatewayType=='Vpn'){
      this.tempPublicIPAddressList=ipAddressList.filter((dt)=>(!dt.sku||dt.sku.name.toLowerCase()===this.vngForm.controls.resourceData.value.publicAddressIPType.toLowerCase())&&(!dt.properties||(this.vngForm.controls.resourceData.value.publicAddressIPType.toLowerCase()==='basic'?dt.properties.publicIPAllocationMethod.toLowerCase()==='dynamic':dt.properties.publicIPAllocationMethod.toLowerCase()==='static'))&&!dt.zones);
    } else if(this.vngForm.controls.resourceData.value.sku.includes('AZ')&&this.vngForm.controls.resourceData.value.gatewayType=='ExpressRoute'){
      this.tempPublicIPAddressList=ipAddressList.filter((dt)=>(!dt.sku||dt.sku.name.toLowerCase()==='standard'));
    } else if(this.vngForm.controls.resourceData.value.gatewayType=='ExpressRoute'){
      this.tempPublicIPAddressList=ipAddressList;
    }

    if(this.vngForm.controls.resourceData.value.publicIPAddress&&!this.tempPublicIPAddressList.find(dt=>dt.name.toLowerCase()===this.vngForm.controls.resourceData.value.publicIPAddress.name.toLowerCase())){
      this.vngForm.controls.resourceData.patchValue({
        publicIPAddress: null
      });
    }
    if(this.vngForm.controls.resourceData.value.secondPIPAddress&&!this.tempPublicIPAddressList.find(dt=>dt.name.toLowerCase()===this.vngForm.controls.resourceData.value.secondPIPAddress.name.toLowerCase())){
      this.vngForm.controls.resourceData.patchValue({
        secondPIPAddress: null
      });
    }
  }

  vpnType(): void {
    if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.vpnType==='PolicyBased')){
      this.tempGenerationList=this.basicGenerationList;
      this.tempSKUList=this.basicSKUList; 
      this.vngForm.controls.resourceData.patchValue({
        generation:this.basicGenerationList[0].name,
        sku:this.basicSKUList[0].name,
        activeMode:'disabled',
        configureBGP:'disabled',
      });
      this.vngForm.controls.resourceData.get('activeMode').disable();
      this.vngForm.controls.resourceData.get('configureBGP').disable();
    } else {
      this.tempGenerationList=this.generationList;
      this.tempSKUList=this.skuList; 
      this.vngForm.controls.resourceData.get('activeMode').enable();
      this.vngForm.controls.resourceData.get('configureBGP').enable();
    }
    this.changeAZForPIPValidators();
    this.setValidatorForAddressName();
    this.changeAZValidators();
  }

  changeSecondPublicIPType(): void {
    if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.gatewayType==='Vpn'&&this.vngForm.controls.resourceData.value.activeMode==='enabled'&&this.vngForm.controls.resourceData.value.secondPIPType==='existing')){
      this.vngForm.controls.resourceData.get('secondPIPAddress').setValidators([Validators.required]);
    } else {
      this.vngForm.controls.resourceData.get('secondPIPAddress').clearValidators();
    }
    this.vngForm.controls.resourceData.get('secondPIPAddress').updateValueAndValidity();
    this.setValidatorForAddressName();
    this.changeAZValidators();
  }

  changePublicIPType(): void {
    if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.publicIPType==='existing')){
      this.vngForm.controls.resourceData.get('publicIPAddress').setValidators([Validators.required]);
    } else {
      this.vngForm.controls.resourceData.get('publicIPAddress').clearValidators();
    }
    this.vngForm.controls.resourceData.get('publicIPAddress').updateValueAndValidity();
    this.changeAZForPIPValidators();
    this.setValidatorForAddressName();
    this.changeAZValidators();
  }
  
  changeConfigureBGP(): void {
    if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.gatewayType==='ExpressRoute'&&this.vngForm.controls.resourceData.value.configureBGP==='enabled')){
      this.vngForm.controls.resourceData.get('asn').setValidators(Validators.compose([Validators.required,this.validNumber()]));
    } else {
      this.vngForm.controls.resourceData.get('asn').clearValidators();
    }
    this.vngForm.controls.resourceData.get('asn').updateValueAndValidity();
  }

  changeAZValidators(): void {
    if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.gatewayType==='Vpn'&&this.vngForm.controls.resourceData.value.secondPIPType==='new'&&this.vngForm.controls.resourceData.value.sku.includes('AZ')&&this.vngForm.controls.resourceData.value.activeMode=='enabled')){
      this.vngForm.controls.resourceData.get('availabilityZone').setValidators([Validators.required]);
      this.vngForm.controls.resourceData.patchValue({
        availabilityZone:this.vngForm.controls.resourceData.value.availabilityZonePIP
      });
    } else {
      this.vngForm.controls.resourceData.get('availabilityZone').clearValidators();
      this.vngForm.controls.resourceData.patchValue({
        availabilityZone:null
      });
    }
    this.vngForm.controls.resourceData.get('availabilityZone').updateValueAndValidity();
  }

  changeAZForPIPValidators(): void {
    if(this.vngForm.controls.resourceData.value&&(this.vngForm.controls.resourceData.value.publicIPType==='new'&&(this.vngForm.controls.resourceData.value.sku.includes('AZ')))){
      this.vngForm.controls.resourceData.get('availabilityZonePIP').setValidators([Validators.required]);
      if(!this.vngForm.controls.resourceData.value.availabilityZonePIP) {
        this.vngForm.controls.resourceData.patchValue({
          availabilityZonePIP:'Zone-redundant',
        });
      }
    } else {
      this.vngForm.controls.resourceData.get('availabilityZonePIP').clearValidators();
      this.vngForm.controls.resourceData.patchValue({
        availabilityZonePIP:null,
      });
    }
    this.vngForm.controls.resourceData.get('availabilityZonePIP').updateValueAndValidity();
  }

  changeAZPIP(){
    this.vngForm.controls.resourceData.patchValue({
      availabilityZone:this.vngForm.controls.resourceData.value.availabilityZonePIP
    })
  }

  changePIPSKU(){
    if(this.vngForm.controls.resourceData.value.sku.includes('AZ')){
      this.vngForm.controls.resourceData.patchValue({
        publicIPSKU:'Standard'
      })
    } else if(this.vngForm.controls.resourceData.value.sku==='Basic'){
      this.vngForm.controls.resourceData.patchValue({
        publicIPSKU:'Basic'
      })
    } else if(this.vngForm.controls.resourceData.value.sku.includes('VpnGw')&&this.vngForm.controls.resourceData.value.publicAddressIPType){
      this.vngForm.controls.resourceData.patchValue({
        publicIPSKU:this.vngForm.controls.resourceData.value.publicAddressIPType
      })
    }

    if(this.vngForm.controls.resourceData.value.publicIPSKU==='Basic'){
      this.vngForm.controls.resourceData.patchValue({
        assignment:'dynamic'
      });
    } else {
      this.vngForm.controls.resourceData.patchValue({
        assignment:'static'
      });
    }
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='virtualNetworkGateways'){
        this.vngList=res.value;
      }else if(propName=='publicIPAddresses'){
        this.publicIPAddressList=res&&res.value?res.value:[];
      }
      return  result;
    },err=>{
    });
  }

  async getResourceGroupList(): Promise<void> {
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
      });
  }

  async getNewResourceGroup(): Promise<void> {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }
  
  tagList() : FormArray {
    return this.vngForm.controls.resourceData.get("tagList") as FormArray
  }
  

  newTag(): FormGroup {
    return this.fb.group({
      tagName: null,
      value: null,
    })
  }

  addTags(): void {
    this.tagList().push(this.newTag());
  }

  removeTag(i:number): void {
    this.tagList().removeAt(i);
  }

  isCharALetter(char: string): boolean {
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char: string): boolean {
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char: string): boolean {
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char: string): boolean {
    return (/[A-Z]/).test(char);
  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.vngForm.controls.resourceData.value.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  addTag(){
    this.vngForm.controls.resourceData.value.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTag(index){
    this.vngForm.controls.resourceData.value.tagList.splice(index,1);
  }

  hideShow(): void {
    this.showView = !this.showView;
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.vngForm.getRawValue());
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  saveResourceDetails(){
    var model = this.vngForm.getRawValue();
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.vngForm.getRawValue();
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  checkDataDisabled(): boolean {
  return this.vngForm.invalid
  }
}
