<div class="introduction">
          <p class="heading" id="0">Project Status  </p>
          <p>Following are the status that a project can have: </p>
          <ol>
              <li>In Review </li>
              <li>Approved </li>
              <li>Deployed Successfully </li>
              <li>Failed to Deploy </li>

          </ol>   
</div>


