<div class="compliance-overview-container">
    <div class="header-container">
      <h2 class="title">Overview</h2>
    </div>
    <!-- Filter Section -->
    <div class="custom-filter-wrapper flex-custom ">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button> -->
    </div>
    <div class="dashboard-body">
        <div class="row">
            <div class="col-md-4 padding-div">
                <div class="graph-div mt-0" style="height: 300px;">
                    <div class="heading">
                        <div class="d-flex">
                            <div class="about">
                                <h4>Compliance Benchmark</h4>
                                <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.complianceTime)}}</p>
                            </div>
                            <div class="float-right flex-custom no-wrap">
                              <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('complianceTime')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          </div> 
                        </div>
                    </div>

                    <div class="gph-outer" *ngIf="azureResource?.totalControls && !showLoader?.overall">
                    <!-- *ngIf="complianceDataList?.length && overallResourceOptions && !showLoader?.overall" -->
                    <div class="gph-wrap"><div echarts [loading]='true' [options]="overallResourceOptions" style="height: 140px;"></div></div>
                    <div class="txt-wrap">
                        <span class="bold-500">{{azureResource?.passedControls}}</span>
                        <p>out of {{azureResource?.totalControls}} passed controls
                            <!-- <span class="bold-600">{{getTotal('overallResource')}}</span> -->
                        </p>
                    </div>
                    </div>
                    <div class="loader-gif" *ngIf="showLoader?.overall">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                    </div>
                    <div class="dash-text-center" *ngIf="!azureResource?.totalControls && !showLoader?.overall">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                    </div>
                </div>
            </div>
          <div class="col-md-8 padding-div pl-4">
            <div class="graph-div mt-0" style="padding-right: 12px;height: 300px;">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Compliance Status</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.complianceTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('complianceTime')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" style="min-height: 204px !important;max-height: 204px !important;" *ngIf="(complianceOption?.tableData?.length && !complianceOption?.showLoader)">
                  <table class="table">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Standard name</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','displayNameControl')">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Passed controls</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','max')">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Chart</span>
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let row of complianceOption?.tableData">
                            <td>{{row.standardId}}</td>
                            <td>{{row.passedControls}}/{{row.totalControls}}</td>
                            <td>
                                <div class="progress-div d-flex">
                                    <span class="progress w-92 mb-2" style="margin-top: 8px;">
                                        <span class="progress-bar progress-bar-blue" [style.width]="(row.passedControls/row.totalControls)*100+'%'"></span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif" *ngIf="complianceOption?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!complianceOption?.tableData?.length && !complianceOption?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
          </div>
      <div class="row">
        <div class="col-md-12 padding-div">
          <div class="graph-div" style="padding-right: 12px;">
              <div class="heading">
                  <div class="">
                    <div class="ml-auto">
                        <div class="main-nav">
                          <ul class="nav-wrap m-0" style="padding: 0px;display: flex;">
                            <li class="list-item cursor-pointer" *ngFor="let item of standardList;" [class.active]="currentTab == item.name" (click)="currentTab = item.name;getStandardData(item.name);">
                              <span class="menu-name">{{item.name}}</span>
                            </li>
                            <!-- <li class="list-item cursor-pointer" [class.active]="currentTab == 'SOC'" (click)="currentTab = 'SOC';getStandardData('ISO 27001');">
                              <span class="menu-name">ISO 27001</span>
                            </li>
                            <li class="list-item cursor-pointer" [class.active]="currentTab == 'PCI'" (click)="currentTab = 'PCI';getStandardData('PCI DSS 3.2.1');">
                              <span class="menu-name">PCI DSS 3.2.1</span>
                            </li>
                            <li class="list-item cursor-pointer" [class.active]="currentTab == 'HITRUST'" (click)="currentTab = 'HITRUST';getStandardData('SOC TSP');">
                              <span class="menu-name">SOC TSP</span>
                            </li>
                            <li class="list-item cursor-pointer" [class.active]="currentTab == 'us_goverment'" (click)="currentTab = 'us_goverment';getStandardData('ISO 27001-2013');">
                              <span class="menu-name">ISO 27001-2013</span>
                            </li> -->
                          </ul>
                        </div>
                      </div>
                  </div>
                  <div class="download-report" style="right: 16px;position: absolute;top: 16px;">
                    <ng-template #downloadReport>
                        <div class="form-group" style="margin-bottom: 11px !important;">
                            <div class="label-div">
                                <label class="control-label">Download regulatory stardard compliance 
                                    status report</label>
                            </div>  
                        </div>
                        <div class="form-group" style="margin-bottom: 16px !important;">
                            <div class="label-div">
                                <label for="reportStandard" class="control-label">Report standard</label>
                            </div>
                            <div class="input-box">
                                <ng-select [items]="standardList" bindLabel="name" bindValue="name" [(ngModel)]="downloadReportObj.reportStandard" name="reportStandard" placeholder="" [clearable]="false">
                                </ng-select>
                            </div>   
                        </div>
                        <div class="form-group" style="margin-bottom: 16px !important;">
                            <div class="label-div">
                                <label for="format" class="control-label">Format</label>
                            </div>
                            <div class="input-box">
                                <ng-select [items]="formatList" bindLabel="name"  bindValue="name" [(ngModel)]="downloadReportObj.format" name="format" placeholder="" [clearable]="false">
                                </ng-select>
                            </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!downloadReportObj.reportStandard||!downloadReportObj.format" (click)="getReportData(downloadReportObj.reportStandard);pop.hide();">Download</button>
                            <button class="btn btn-outline" (click)="pop.hide();">Cancel</button>
                        </div>
                    </ng-template> 
                    <label class="cursor-pointer" style="padding: 5px 4px;font-size: 13px;line-height: 20px;color: #3E4852;" [popover]="downloadReport"
                    triggers="" placement="bottom" containerClass="custom-popover fix-width-custom-popover" #pop="bs-popover" (click)="openDownloadPop(pop);">
                        <img src="assets/compliance/download.svg" alt="" style="padding-right: 6px;">Download report
                    </label>
                </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="(standardDetails?.tableData?.length && !standardDetails?.showLoader)">
                <table class="table">
                  <tbody >
                      <tr *ngFor="let item of standardDetails?.tableData">
                          <span class="cursor-pointer" (click)="item.showDetail=!item.showDetail;"> 
                              <img [src]="item.showDetail?'assets/compliance/up-arrow.svg':'assets/compliance/down-arrow.svg'" alt="" style="padding-right: 8px;"> 
                              <img [src]="item.failedCount?'assets/compliance/error.svg':'assets/compliance/success.svg'" alt="" style="padding-right: 8px;"> {{item.name}} {{getControlNameVal(item)}}</span>
                          <div class="custom-fixed-tables m-0 mt-3" style="padding-left: 28px !important; min-height: 38px !important;height: max-content !important;" *ngIf="item.showDetail">
                            <table class="table">
                                <tbody>
                                    <tr *ngFor="let row of item.child">
                                        <span class="cursor-pointer" (click)="row.showDetail=!row.showDetail;">
                                            <img [src]="row.showDetail?'assets/compliance/up-arrow.svg':'assets/compliance/down-arrow.svg'" alt="" style="padding-right: 8px;">
                                            <img [src]="row.failedCount?'assets/compliance/error.svg':'assets/compliance/success.svg'" alt="" style="padding-right: 8px;">{{row.name}} {{getControlNameVal(row)}}</span>
                                        <div class="custom-fixed-tables m-0 mt-3" style="padding-left: 28px !important;min-height: 38px !important;height: max-content !important;" *ngIf="row.child&&row.showDetail">
                                            <table class="table">
                                                <tbody>
                                                    <tr *ngFor="let list of row.child">
                                                        <span class="cursor-pointer" (click)="list.showDetail=!list.showDetail;">
                                                            <img [src]="list.showDetail?'assets/compliance/up-arrow.svg':'assets/compliance/down-arrow.svg'" alt="" style="padding-right: 8px;">
                                                            <img [src]="list.failedCount?'assets/compliance/error.svg':'assets/compliance/success.svg'" alt="" style="padding-right: 8px;">{{list.name}} {{getControlNameVal(list)}}</span>
                                                        <div class="custom-fixed-tables m-0 mt-3" style="padding-left: 38px !important;min-height: 77px !important;height: max-content !important;max-height: 228px !important;" *ngIf="list.showDetail">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr style="font-weight: 600;font-size: 13px;line-height: 20px;">
                                                                        <td>Customer responsibility</td>
                                                                        <td>Resource type</td>
                                                                        <td>Failed resources</td>
                                                                        <td>Resource compliance status</td>
                                                                    </tr>
                                                                    <tr *ngFor="let list1 of list.tableData">
                                                                        <td [title]="list1.DisplayName"><a class="cursor-pointer" (click)="detailView(list1,detailViewPop)">{{list1.DisplayName}}</a></td>
                                                                        <td>{{list1.resourceType}}</td>
                                                                        <td>{{list1.FailedResources}} of {{list1.TotalResources}}</td>
                                                                        <td>
                                                                            <div class="progress-div">
                                                                              <span class="progress mb-2">
                                                                                  <span class="progress-bar progress-bar-custom" [style.width]="(list1?.FailedResources/list1.TotalResources)*100+'%'"></span>
                                                                              </span>
                                                                          </div>
                                                                          </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="custom-fixed-tables m-0 mt-3" style="padding-left: 38px !important;min-height: 77px !important;height: max-content !important;max-height: 228px !important;" *ngIf="!row.child&&row.showDetail">
                                            <table class="table">
                                                <tbody>
                                                    <tr style="font-weight: 600;font-size: 13px;line-height: 20px;">
                                                        <td>Customer responsibility</td>
                                                        <td>Resource type</td>
                                                        <td>Failed resources</td>
                                                        <td>Resource compliance status</td>
                                                    </tr>
                                                    <tr *ngFor="let list of row.tableData">
                                                        <td [title]="list.DisplayName"><a class="cursor-pointer" (click)="detailView(list,detailViewPop)">{{list.DisplayName}}</a></td>
                                                        <td>{{list.resourceType}}</td>
                                                        <td>{{list.FailedResources}} of {{list.TotalResources}}</td>
                                                        <td>
                                                            <div class="progress-div">
                                                              <span class="progress mb-2">
                                                                  <span class="progress-bar progress-bar-custom" [style.width]="(list?.FailedResources/list.TotalResources)*100+'%'"></span>
                                                              </span>
                                                          </div>
                                                          </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      </tr>
                  </tbody>
              </table>
              </div>
              <div class="loader-gif" *ngIf="standardDetails?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!standardDetails?.tableData?.length && !standardDetails?.showLoader)">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No data found</p>
              </div>
          </div>
      </div>
      </div>
    


      <app-additional-standards [currentFilteredProject]="currentFilteredProject"></app-additional-standards>
      
    </div>
  </div>

  <ng-template #detailViewPop>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>{{detailObj.DisplayName}}</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="form-group row" style="padding-top: 16px !important;">
                <div class="col-md-2">
                    <div class="label-div">
                        <label class="control-label" style="padding-bottom: 0px !important;">Severity</label>
                        <label class="control-label content-val" style="padding-top: 0px !important;">{{detailObj?.severity}}</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="label-div">
                        <label class="control-label" style="padding-bottom: 0px !important;">Freshness interval</label>
                        <label class="control-label content-val" style="padding-top: 0px !important;">{{detailObj?.freshnessInterval}}</label>
                    </div>
                </div>
            </div>
            <div class="form-group" style="margin-bottom: 16px !important;">
                <div class="label-div">
                    <label class="control-label text-weight" style="padding-bottom: 0px !important;">Description</label>
                    <label class="control-label" style="padding-top: 0px !important;width:100%;font-weight: 400;" [innerHtml]="detailObj?.description"></label>
                </div>
            </div>
            <div class="form-group" style="margin-bottom: 16px !important;">
                <div class="label-div">
                    <label class="control-label text-weight" style="padding-bottom: 0px !important;">Remediation steps</label>
                    <label class="control-label" style="padding-top: 0px !important;width:100%;font-weight: 400;" [innerHtml]="detailObj?.remediationSteps"></label>
                </div>
            </div>
            <div class="form-group" style="margin-bottom: 12px !important;">
                <div class="label-div">
                    <label class="control-label text-weight">Affected resources</label>
                </div>
            </div>
            <div class="">
                <div class="ml-auto">
                    <div class="main-nav">
                      <ul class="nav-wrap m-0" style="padding: 0px;display: flex;">
                        <li class="list-item cursor-pointer" [class.active]="currentTabDt == 'UR'" (click)="currentTabDt = 'UR';">
                          <span class="menu-name">Unhealthy resources ({{detailObj?.UnhealthyResources.length}})</span>
                        </li>
                        <li class="list-item cursor-pointer" [class.active]="currentTabDt == 'HR'" (click)="currentTabDt = 'HR';">
                          <span class="menu-name">Healthy resources ({{detailObj?.HealthyResources.length}})</span>
                        </li>
                        <li class="list-item cursor-pointer" [class.active]="currentTabDt == 'NAR'" (click)="currentTabDt = 'NAR';">
                          <span class="menu-name">Not applicable resources ({{detailObj?.NotApplicableResources.length}})</span>
                        </li>
                      </ul>
                    </div>
                  </div>
              </div>
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Name</span>
                                <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','displayName')"> -->
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Subscription</span>
                              <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','resourceHealth')"> -->
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of currentTabDt == 'UR'?detailObj?.UnhealthyResources:currentTabDt == 'HR'?detailObj?.HealthyResources:detailObj?.NotApplicableResources; let i=index;">
                          <td>{{row}}</td>
                          <td>{{filterSubscriptionVal(currentTabDt,i)}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!(currentTabDt == 'UR'?detailObj?.UnhealthyResources:currentTabDt == 'HR'?detailObj?.HealthyResources:detailObj?.NotApplicableResources)?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>