import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-industry-and-regulatory-standards',
  templateUrl: './industry-and-regulatory-standards.component.html',
  styleUrls: ['./industry-and-regulatory-standards.component.scss']
})
export class IndustryAndRegulatoryStandardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
