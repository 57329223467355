<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Load Balancer</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="loadBalancermodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(false)">Create new
                    </p>
                </div>
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="loadBalancer" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar || inputError?.duplicateVnet" id="VMname" autocomplete="off" [(ngModel)]="loadBalancermodel.resourceName" placeholder="Enter load balancer name" name="projectName" (keyup)="VmNameValidation($event,65)" required>
                <p class="text-danger" *ngIf="inputError?.VMname">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="inputError?.VmSpecialChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens. </p>
                <p class="text-danger" *ngIf="inputError?.duplicateVnet">Load balancer name must be unique.</p>
                </div>              
            </div>

            <!-- <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div> -->
    <!-- <div *ngIf="showView"> -->

        <!-- <div class="form-group">
            <div class="label-div">
                
            </div>
            </div> -->

            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="instanceDetails" class="load">Instance details<span class="mandatory"></span></label>
                    <label for="type" class="control-label ">Type<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can use internal load balancers to balance traffic from private IP addresses. Public load balancers can balance traffic originating from public IP addresses." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="type" [value]="'internal'" ([ngModel])="loadBalancermodel.resourceData.type" [checked]="loadBalancermodel.resourceData.type=='internal'" (change)="loadBalancermodel.resourceData.type=$event.target.value;changeLoaderType()" ><span>Internal</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="type" [value]="'public'" ([ngModel])="loadBalancermodel.resourceData.type" [checked]="loadBalancermodel.resourceData.type=='public'" (change)="loadBalancermodel.resourceData.type=$event.target.value;changeLoaderType()"><span>Public</span></label>                    
            </div>             
        </div>

        <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="sku" class="control-label ">SKU<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a Standard load balancer for up to 1000 instances, greater backend pool flexibility, HA ports, and zonal and zone-redundant scenarios." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="sku" [value]="'Standard'" ([ngModel])="loadBalancermodel.resourceData.sku" [checked]="loadBalancermodel.resourceData.sku=='Standard'" (change)="loadBalancermodel.resourceData.sku=$event.target.value;changeLoaderSKU()"><span>Standard</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="sku" [value]="'Basic'" ([ngModel])="loadBalancermodel.resourceData.sku" [checked]="loadBalancermodel.resourceData.sku=='Basic'" (change)="loadBalancermodel.resourceData.sku=$event.target.value;changeLoaderSKU()"><span>Basic</span></label>                    
            </div>             
        </div>

        <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="tier" class="control-label ">Tier</label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="tier" [value]="'Regional'" [disabled]="loadBalancermodel.resourceData.sku=='Basic'||(loadBalancermodel.resourceData.sku=='Standard'&&loadBalancermodel.resourceData.type=='internal')" ([ngModel])="loadBalancermodel.resourceData.tier" [checked]="loadBalancermodel.resourceData.tier=='Regional'" (change)="loadBalancermodel.resourceData.tier=$event.target.value;changeLoaderTier()"><span>Regional</span></label>
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;" *ngIf="showGlobalTier()"><input type="radio" name="tier" [value]="'Global'" [disabled]="loadBalancermodel.resourceData.sku=='Basic'||(loadBalancermodel.resourceData.sku=='Standard'&&loadBalancermodel.resourceData.type=='internal')" ([ngModel])="loadBalancermodel.resourceData.tier" [checked]="loadBalancermodel.resourceData.tier=='Global'" (change)="loadBalancermodel.resourceData.tier=$event.target.value;changeLoaderTier()"><span>Global</span></label>                    
            </div>             
        </div>

        <div class="form-group" *ngIf="loadBalancermodel.resourceData.tier=='Global'">
            <div class="label-div d-flex" style="background: #F4FBFF;border: 1px solid #62C2FB;;box-sizing: border-box;border-radius: 2px;">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blueprint/popup-error-info.png" alt=""></label>
                <label class="" style="padding: 8px 0px !important;color: #0B70D4;">
                    A cross-region (global) load balancer can only be deployed
                        to a home region. Make sure 
                        you have selected a valid region.</label>
            </div>  
        </div>

        <div class="form-group"  *ngIf="loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard')">
            <div class="label-div">
                <label for="instanceDetails" class="load">Configure virtual network</label>
            </div>
            
            <div class="label-div">
                <label for="virtualNetwork" class="control-label">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="existingVNList" bindLabel="name" bindValue="name" [(ngModel)]="loadBalancermodel.resourceData.virtualNetwork" name="virtualNetwork" placeholder="" (change)="getSubnetList()" [clearable]="false" required>
                </ng-select>
            </div>   
        </div>
        <div class="form-group"  *ngIf="loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard')"> 
            <div class="label-div">
                <label for="subnet" class="control-label">Subnet<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="existingSubnetList" bindLabel="name" [(ngModel)]="loadBalancermodel.resourceData.subnet" name="subnet" placeholder="" [clearable]="false" required>
                    <ng-template ng-label-tmp let-item="item">
                        {{item?.name}} ({{item?.properties?.addressPrefix}})
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        {{item?.name}} ({{item?.properties?.addressPrefix}})
                    </ng-template>
                </ng-select>
            </div>  
        </div>
            

        <!-- <div class="form-group" *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))">
            <div class="label-div">
                
            </div>
            </div> -->

        <div class="form-group" *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))">
            <div class="label-div">
                <label for="publicIPAddress" class="load">Public IP address</label>
                <label for="publicIP" class="control-label">Public IP address<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the public IP address to be used in the frontend IP configuration." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="publicIP" [value]="'new'" ([ngModel])="loadBalancermodel.resourceData.publicIP" [checked]="loadBalancermodel.resourceData.publicIP=='new'" (change)="loadBalancermodel.resourceData.publicIP=$event.target.value"><span>Create new</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="publicIP" [value]="'existing'" ([ngModel])="loadBalancermodel.resourceData.publicIP" [checked]="loadBalancermodel.resourceData.publicIP=='existing'" (change)="loadBalancermodel.resourceData.publicIP=$event.target.value"><span>Use existing</span></label>                    
            </div>           
        </div>

        <div class="form-group" *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))&&loadBalancermodel.resourceData.publicIP=='existing'&&loadBalancermodel.resourceData.tier!='Global'">
            <div class="label-div">
                <label for="publicIPaddress" class="control-label col-sm-2">Public IP address name<span class="mandatory">*</span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="vmPublicIPList" bindLabel="name" bindValue="name" [(ngModel)]="loadBalancermodel.resourceData.publicIPaddress" name="publicIPaddress" placeholder="" [clearable]="false" required>
                </ng-select>
            </div>             
        </div> 
        

        <div class="form-group" *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))&&(loadBalancermodel.resourceData.publicIP=='new'||loadBalancermodel.resourceData.tier=='Global')">
            <div class="label-div">
                <label for="publicIPAddressName" class="control-label">Public IP address name<span class="mandatory">*</span></label>
            </div>
            <div class="input-box">
            <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar" id="VMname" autocomplete="off" [(ngModel)]="loadBalancermodel.resourceData.publicIPaddressName" placeholder="" name="projectName" (keyup)="VmNameValidation($event,65)" required>
            <p class="text-danger" *ngIf="inputError?.VMname">The name must be between 1 and 80 characters.</p>
            <p class="text-danger" *ngIf="inputError?.VmSpecialChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens. </p>
            </div>              
        </div>

        <div class="form-group"  *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))&&(loadBalancermodel.resourceData.publicIP=='new'||loadBalancermodel.resourceData.tier=='Global')">
            <div class="label-div">
                <label for="publicIPAddresssku" class="control-label">Public IP address SKU</label>
                <label for="standard" class="load">{{loadBalancermodel.resourceData.sku}}</label>
            </div>
            </div>

        <div class="form-group"  *ngIf="(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))||(loadBalancermodel.resourceData.publicIP=='new'||loadBalancermodel.resourceData.tier=='Global')" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="IPaddressAssignment" class="control-label ">IP address assignment</label>
            </div>
        <div class="input-box d-flex">
            <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPaddressAssignment" [value]="'Dynamic'" [disabled]="loadBalancermodel.resourceData.tier=='Global'" [disabled]="loadBalancermodel.resourceData.type=='public'&&loadBalancermodel.resourceData.sku=='Standard'" ([ngModel])="loadBalancermodel.resourceData.IPaddressAssignment" [checked]="loadBalancermodel.resourceData.IPaddressAssignment=='Dynamic'" (change)="loadBalancermodel.resourceData.IPaddressAssignment=$event.target.value" ><span>Dynamic</span></label>
            <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPaddressAssignment" [value]="'Static'" [disabled]="loadBalancermodel.resourceData.tier=='Global'" [disabled]="loadBalancermodel.resourceData.type=='public'&&loadBalancermodel.resourceData.sku=='Standard'" ([ngModel])="loadBalancermodel.resourceData.IPaddressAssignment" [checked]="loadBalancermodel.resourceData.IPaddressAssignment=='Static'" (change)="loadBalancermodel.resourceData.IPaddressAssignment=$event.target.value"><span>Static</span></label>                    
        </div>             
    </div>

    <div class="form-group" *ngIf="loadBalancermodel.resourceData.publicIP=='new'&&loadBalancermodel.resourceData.IPaddressAssignment=='Static'&&loadBalancermodel.resourceData.tier!='Global'&&loadBalancermodel.resourceData.type!='public'">
        <div class="label-div">
            <label for="privateIPAddrSpace" class="control-label col-sm-2">Private IP address</label>
        </div>
        <div class="input-box">
            <input type="text" class="form-control" [class.border-danger]="inputError?.nicValidIPError || inputError?.nicRegisterIPError || inputError?.nicAddrUsedError || !inputError?.nicSubnetRangeError" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="loadBalancermodel.resourceData.privateIPAddrSpace" placeholder="" name="privateIPAddrSpace" (keyup)="loadBalancerPrivateIpAddress(loadBalancermodel)" required>
            <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
            <p class="text-danger" *ngIf="inputError?.nicValidIPError">Invalid IP address</p>
            <p class="text-danger" *ngIf="inputError?.nicRegisterIPError">The IP address falls within the reserved IP range of subnet</p>
            <p class="text-danger" *ngIf="inputError?.nicAddrUsedError">IP is already used</p>
            <p class="text-danger" *ngIf="!inputError?.nicSubnetRangeError">The IP must be in the subnet</p>
        </div>              
    </div> 
    

        <!--  // *ngIf="loadBalancermodel.resourceData.type=='internal' && loadBalancermodel.resourceData.type=='public' && loadBalancermodel.resourceData.sku=='Standard'" -->
        <div class="form-group" *ngIf="((loadBalancermodel.resourceData.type=='internal' && loadBalancermodel.resourceData.sku=='Standard')||(loadBalancermodel.resourceData.publicIP=='new'&&loadBalancermodel.resourceData.sku!='Basic'))&&loadBalancermodel.resourceData.tier!='Global'">
        <div class="label-div">
            <label for="availabilityZone" class="control-label">Availablity zone</label>
        </div>
        <div class="input-box">
            <ng-select [items]="loadBalancermodel.resourceData.type=='internal'?zonesListForInternal:zonesList" bindLabel="name" bindValue="name" [disabled]="loadBalancermodel.resourceData.routingPreference=='internet'" [(ngModel)]="loadBalancermodel.resourceData.availabilityZone" name="availabilityZone" placeholder="" [clearable]="false"></ng-select>
        </div>   
    </div>

    <!-- // *ngIf="!loadBalancermodel.resourceData.sku=='Basic' && !loadBalancermodel.resourceData.publicIP=='existing'" -->
    <div class="form-group" *ngIf="(loadBalancermodel.resourceData.publicIP=='new'&&(loadBalancermodel.resourceData.type=='public'&&loadBalancermodel.resourceData.sku=='Standard'))" style="margin-bottom: 10px !important;"> 
    <div class="label-div">
            <label for="routingPreference" class="control-label ">Routing preference<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Determines how your traffic routes between Azure and the Internet. 
                Selecting Microsoft global network delivers traffic via Microsoft global network closest to user. Internet route option uses transit ISP network. Egress data transfer price varies based on the routing selection. Routing preference option of a Public IP can’t be changed once created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box d-flex">
            <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="routingPreference" [value]="'microsoftNetwork'" [disabled]="loadBalancermodel.resourceData.tier=='Global'" ([ngModel])="loadBalancermodel.resourceData.routingPreference" [checked]="loadBalancermodel.resourceData.routingPreference=='microsoftNetwork'" (change)="loadBalancermodel.resourceData.routingPreference=$event.target.value;"><span>Microsoft network</span></label>
            <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="routingPreference" [value]="'internet'" [disabled]="loadBalancermodel.resourceData.tier=='Global'" ([ngModel])="loadBalancermodel.resourceData.routingPreference" [checked]="loadBalancermodel.resourceData.routingPreference=='internet'" (change)="loadBalancermodel.resourceData.routingPreference=$event.target.value;"><span>Internet</span></label>                    
        </div>             
    </div>

    <div class="form-group">
        <div class="label-div">
            <label for="comment" class="control-label">Description</label>
        </div>
        <div class="input-box">
            <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="loadBalancermodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
            <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
        </div>                    
    </div> 


                <div class="form-group">
                    <div class="label-div d-flex">
                        <label class="control-label " style="min-width: max-content;">Tag name </label>
                        <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                        </label>
                        <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="loadBalancermodel.resourceData&&loadBalancermodel.resourceData.tagList&&loadBalancermodel.resourceData.tagList.length>1"></label>
                        <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                    </div>
                    <div *ngFor="let item of loadBalancermodel.resourceData.tagList;let i = index">
                        <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
    
                        <div class="input-box" style="padding-bottom: 6px;">
                            <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
    
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
    
                        <div class="input-box">
                            <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
    
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="loadBalancermodel.resourceData.tagList.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                        <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                        <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                        <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
    
                    <div class="add-another-tag">
                        <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                    </div>
            <!-- </div> -->

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>