<div class="introduction">
 
        <p class="heading" id="0">Alerts </p>
        <ul class="mb-0" >
            <li><b>Alerts:</b> number of security alerts. </li>
            <li><b>Resources affected: </b> number of resources with active alerts.  </li>
            <li><b>Active alerts by severity: </b> number of active alters at each severity level. </li>
            
        </ul>
        <img src="assets\user-manual\security\Card(1).svg" >
     
 </div>
  