 <div class="introduction">
          <p class="heading" id="2">SQL Server </p>
          <ol>
              <li>Click on<b> Create new project.</b>  </li>
              <li>Select <b>Source data system – SQL Server. </b> </li>
              <img src="assets\user-manual\data-&-AI\2.svg" >
              <figcaption class="caption">SQL - source system </figcaption>
              <li>Provide required details for Azure synapse as destination and SQL server as the source system.  </li>
              <img src="assets\user-manual\data-&-AI\3.svg" >
              <figcaption class="caption">SQL - source details </figcaption>
              <li>Click <b>Next</b> and wait for the source information to load completely. </li>
              <li>Select table(s) to be migrated and click <b>Next.</b> </li>
              <img src="assets\user-manual\data-&-AI\5.svg" >
              <figcaption class="caption">SQL - select tables </figcaption>
              <li>Click <b>Next</b> to migrate schema.  </li>
              <li>Click <b>Migrate data.</b> </li>
              <img src="assets\user-manual\data-&-AI\8.svg" >
              <figcaption class="caption">SQL - migrate data </figcaption>
              <li>Data migration progress will be displayed.  </li>
              <img src="assets\user-manual\data-&-AI\9.svg" >
              <figcaption class="caption">SQL - migration progress </figcaption>
              <li>If data migration was successful, then Successful data migration message will be displayed. </li>
              <img src="assets\user-manual\data-&-AI\10.svg" >
              <figcaption class="caption">SQL - migration success </figcaption>
              <li>In case of error/failure, a Failed data migration message will be displayed. </li>  
              <img src="assets\user-manual\data-&-AI\11.svg">
              <figcaption class="caption">SQL - migration failure </figcaption>
              <li>In case of partial migration, Data migration partial success message will be displayed. </li>
              <img src="assets\user-manual\data-&-AI\12.svg" >
              <figcaption class="caption"> SQL - migration partial success </figcaption>
              <li>Upon completion of the migration, project summary will be displayed. </li>
              <img src="assets\user-manual\data-&-AI\14.svg" >
              <figcaption class="caption"> SQL - project summary </figcaption>
              <li>Click <b>View in A3S BI dashboard</b> button to view data in a dashboard.  </li>
              <img src="assets\user-manual\data-&-AI\SQL-BI.png" >
              <figcaption class="caption">SQL - BI dashboard</figcaption>
            
              
          </ol>
    
</div>
