<div class="introduction">
    <p class="heading" id="0">Introduction</p>
     <p class="para">This documentation is intended to be a step by step, detailed user manual for AI-powered 
           Azure Automation as a Service (A3S) users to follow to get the most out of our zero code, fully 
           automated, AI-powered Azure Management SaaS platform that’s backed by relentless 24x7 support for 
           instant support requirements that you may have as our valuable user. </p>
         <label>A3S' takes the grunt work out of your Azure usage, making it a truly delightful experience. 
          A3S will make your Azure usage super easy, will reduce the time, effort, and money you spend while 
          using Azure and in doing that it will enable you & your team to redirect those efforts, time, and 
          money to building your business.  </label>
         
          <p><b>Let A3S take care of Azure, so that you can focus on your business.   </b></p>
         <p class="main">A3S – Zero Code, 100% Automated, AI-powered Azure Management Platform.</p>
</div>
    
   
    
