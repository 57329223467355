 <div class="a3s-functional-overview">
    <p class="heading" id="0">Functional Overview</p>
    <p>All of A3S’ Azure cloud management capabilities are presented to you for easy 
         navigation and use with Tiles on the A3S portal’s landing page:  </p>

    <table class="table">
        <tr>
            <th>The … Tile displays </th>
            <th>Provides… </th>
        </tr>
        <tr>
            <td style="font-weight: 700;">Home </td>
            <td>
                <ul>
                    <li>Search bar to search projects and content A3S solution and Azure documentation. </li>
                    <li>Chat icon to initiate conversation with A3S product engineer. </li>
                    <li>Bell icon to view notifications from all/specific actions. </li>
                    <li>Hamburger menu to navigate through various services of A3S.</li>
                    <li>Recent activities section to see latest activities performed in the environment. </li>
                    <li>Favorites shows activities you perform most frequently for quick access. </li>
                </ul>
            </td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Apps & Infrastructure </td>
            <td>
                <ul>
                    <li>On-premises Assessment </li>
                    <li>Migration to Azure </li>
                    <li>Architecture blueprint </li>
                    <ul style="list-style-type: circle;">
                        <li>Create architecture diagrams. </li>
                        <li>Workflow to finalize the architecture and thereon deployment on Azure. </li>
                        <li>View list of projects and respective summary. </li>
                    </ul>
                    <li>Manage Infrastructure </li>
                    <ul style="list-style-type: circle;">
                        <li>Inventory of resources. </li>
                        <li>Infrastructure overview. </li>
                        <li>Resource categorization for easy navigation and management. </li>
                        <li>Perform actions (modify resources) with a single click. </li>
                    </ul>
                    <li>Business Continuity </li>
                </ul>
            </td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Data &AI </td>
            <td>
                <ul>
                    <li>Migrate data from source data systems. </li>
                    <li>Data governance and security. </li>
                    <li>Data analytics. </li>
                    <li>DataOps. </li>
                </ul>
            </td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Infrastructure Health Monitoring </td>
            <td>
                <ul>
                    <li>Monitor and analyze infrastructure health by project and thereon related subscription, resource groups, and resources. </li>
                    <li>Apply filters to drill down to resource level and look health metrics of a specific resource. </li>
                    <li>Insights: provides prebuilt queries to retrieve the relevant data of a resource for auditing and analyzing & understanding the health pattern. </li>
                    <li>Alerts: enables creation and management of alerts for various metrics of Azure resources. </li>
                </ul>
            </td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Application Health Monitoring </td>
            <td><ul>
                <li>Consolidated health report of by project.  </li>
                <li>Use filters to drill down to check health related metrics of an application. </li>
                <li>Region based usage & count of returning users. </li>
                <li>Mechanism to keep you alerted when anything goes wrong. </li>
            </ul></td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Cost Monitoring & Optimization </td>
            <td><ul>
                <li>Track current cost incurred by resources and forecast expenses. </li>
                <li>View expenditure by scope such as Project, Subscription, etc. </li>
                <li>Create budgets and get alerted when thresholds are crossed. </li>
                <li>Recommendations to reduce the cost.  </li>
            </ul></td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Governance </td>
            <td><ul>
                <li>Enterprise policies to ensure resources are governed per applicable organizational policies. </li>
                <li>Identity and access management provides access control framework to manage the users and groups. </li>
                <li>Auditing & logging captures all user activity & actions performed in A3S for review and auditing as needed. </li>
            </ul></td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Compliance </td>
            <td><ul>
                <li>Ensures resources are compliant with the required or applicable regulatory compliances. </li>
            <li>Compliance policy management. </li>
            <li>Add/remove more regulatory compliance standards. </li>
        </ul></td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Security </td>
            <td><ul>
                <li>Calculates ‘Secure score’ based on analysis of multiple 
                    dimensions of all deployed and active projects.</li>
                <li>Endpoints and identity protection to capture malicious activities to secure various resources,</li>
                <li>Recommendations to strengthen the security posture. </li>

            </ul></td>
        </tr>
        <tr>
            <td style="font-weight: 700;">A3S Support </td>
            <td><ul><li>Product related support from trained A3S product engineers, log tickets and chat for instant help.  </li></ul></td>
        </tr>
        <tr>
            <td style="font-weight: 700;">Azure Support </td>
            <td>
                <ul><li>Azure certified resources for building custom applications and deployments on Azure with A3S. </li></ul>
            </td>
        </tr>
        <tr>
            <td style="font-weight: 700;">SAP Support </td>
            <td>
                <ul><li>SAP certified resources for migration of SAP systems to Azure and ongoing Basis & Functional support with A3S. </li></ul>
            </td>
        </tr>
    </table>



</div>
