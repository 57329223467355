<div class="custom-fixed-tables">
    <table class="table">
        <thead>
          <tr>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Name</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmData',0)">
                </div>
            </th>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Resource Group</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmData',1)">
                </div>
            </th>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Subscription</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmData',2)">
                </div>
            </th>
            <th>
              <div class="flex-custom no-wrap">
                  <span>Region</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmData',3)">
              </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>Status</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmData',4)">
              </div>
          </th>
          <th>
              <div class="flex-custom no-wrap">
                  <span>Operating System</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmData',5)">
              </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>Size</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmData',6)">
            </div>
          </th>
          <th style="border-right: none;">
            <div class="flex-custom no-wrap">
                <span>Tags</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmData',7)">
            </div>
          </th>
          <th style="border-left: none;" class="wid-75"></th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data">
              <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(vmDetailsTemplate,row,'vmPopup')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td class="ellips-td" isEllipsisActive><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive><div class="status-tag" [ngClass]="getStatusClass(row[4])">{{ row[4] | titlecase | displayTxt}}</div></td>
              <td isEllipsisActive>{{ row[5] }}</td>
              <td isEllipsisActive>{{ row[6] | displayTxt}}</td>
              <td isEllipsisActive>{{ getTags(row[7]) |displayTxt }}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action1">
                  <mat-menu #action1="matMenu" class="delete-popup">
                    <div class="mat-menu-blur" mat-menu-item (click)="openSidesheet('connectVM',row)">
                      <p class="delete-popup-text" [class.blur]="row[4] != 'running'">Connect</p>
                    </div>
                    <div class="mat-menu-blur" mat-menu-item (click)="action(row,'startVM')">
                      <p class="delete-popup-text" [class.blur]="row[4] == 'running'">Start</p>
                    </div>
                    <div class="mat-menu-blur" mat-menu-item (click)="action(row,'restartVM')">
                      <p class="delete-popup-text" [class.blur]="row[4] != 'running'">Restart</p>
                    </div>
                    <div class="mat-menu-blur" mat-menu-item (click)="action(row,'stopVM')">
                      <p class="delete-popup-text" [class.blur]="row[4] != 'running'">Stop</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('autoShutdown',row)">
                      <p class="delete-popup-text">Auto-shutdown</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'powerOffVM')">
                      <p class="delete-popup-text">Power Off</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('capture',row)">
                      <p class="delete-popup-text">Capture</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('dataDisk',row)">
                      <p class="delete-popup-text">Data Disks</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('resetPassword',row)">
                      <p class="delete-popup-text">Reset Password</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('redeploy',row)">
                      <p class="delete-popup-text">Redeploy + Reapply</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteVM')">
                      <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
</div>

<ng-container *ngIf="showSidesheet">

    <!-- Connect VM -->
    <ng-container *ngIf="currentTemplate == 'connectVM'">
        <div class="sidebar-right single add-alert" >
            <div class="header">
                <div class="d-flex">
                    <h4>Connect<br> <span class="blur-text">Virtual Machine | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                     
                    <div class="main-nav">
                        <ul class="nav-wrap m-0" style="padding: 0px;display: flex;">
                          <li class="list-item cursor-pointer" [class.active]="currentConnectTab == 'RDP'" (click)="currentConnectTab = 'RDP'">
                            <span class="menu-name">RDP</span>
                          </li>
                          <li class="list-item cursor-pointer" [class.active]="currentConnectTab == 'SSH'" (click)="currentConnectTab = 'SSH'">
                            <span class="menu-name">SSH</span>
                          </li>
                        </ul>
                    </div>
    
                    <ng-container *ngIf="currentConnectTab == 'RDP'">
                        <div class="sub-title mt-3 mb-2">Connect with RDP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="To connect to your virtual machine via RDP, select an IP address,optionally change the port number, and download the RDP file." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">IP address<span class="mandatory">*</span></label>
                            <ng-select class="full-width" [items]="vmIpAddressList" bindLabel="name" bindValue="value" name="ipAddress" formControlName="rdpIp" [clearable]="false" id="ipAddress"></ng-select>
                        </div>   
        
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Port number<span class="mandatory">*</span></label>
                            <input class="form-control full-width" type="text" autocomplete="off" id="portNumber" formControlName="rdpPort" name="portNumber" (keyup)="portRangeValidation($event,65535,'source')"/>
                            <p class="text-danger" *ngIf="inputError?.sourceNotValidPortVal">The port must be a number between 0 and 65535.</p>
                            <p class="text-danger" *ngIf="inputError?.sourceInvalidPortRange">Invalid port range specified.</p>
                            <p class="text-danger" *ngIf="inputError?.sourceOverlap">Overlapping ports are not permitted</p>
                          </div>   
                        <button class="btn btn-btn-primary full-width" [class.disabled]="currentForm?.invalid ||invalidForm('RDP')" (click)="downloadRDP()">Download RDP file</button>
                    </ng-container>
                    <ng-container *ngIf="currentConnectTab == 'SSH'">
                        <div class="sub-title mt-4 mb-2">Connect via SSH with client</div>
                        <ol class="pl-3"><li class="text-12-18">
                          <div class="comm-mb">
                            <span>Open the client of your choice, e.g. <span class="blue-text" style="cursor: default">PuTTY</span> or <span class="blue-text" style="cursor: default">other clients.</span> </span>
                        </div>
                        </li>
                      </ol>
                        <div class="comm-mb">
                          <ol class="pl-3"><li class="text-12-18" value="2">
                            <span>Ensure you have read-only access to the private key.</span></li></ol>
                            <div class="pw-input">
                                <div class="pw-outer full-width">
                                  <input class="form-control full-width" type="text" formControlName="sshPrivateKey" id="privateKey"/>
                                </div>
                                <img class="copy-img tooltip-icon" matTooltip="Copy" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" src="/assets/governance/copy.svg" alt="Icon" (click)="copyClipboard($event,'privateKey')">
                            </div>
                        </div>
                    
                        <div class="comm-mb">
                          <ol class="pl-3"><li class="text-12-18 mb-3" value="3">
                            <span >Provide a path to your SSH private key file.<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="By default, SSH key pairs are created in the ~/.ssh directory with 
                                a key name of 'id_rsa'. If either the key name or specified directory 
                                were created differently, the private key path and name are required 
                                to successfully connect." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></li></ol>
                            <label class="flex-custom no-wrap mt-3">Private key path</label>
                            <input class="form-control full-width" type="text" (keyup)="updateSSHCommand($event)" autocomplete="off" formControlName="sshPrivatePath" id="privateKeyPath" name="privateKeyPath" placeholder="~/.ssh/{{adminName}}" />
                        </div>
                        <div class="comm-mb">
                          <ol class="pl-3"><li class="text-12-18" value="4">
                            <span>Run the example command below to connect to your VM.</span></li></ol>
                            <div class="pw-input">
                                <div class="pw-outer full-width">
                                  <input class="form-control full-width" type="text" formControlName="sshCommand" id="vmCommand"/>
                                </div>
                                <img class="copy-img tooltip-icon" matTooltip="Copy" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" src="/assets/governance/copy.svg" alt="Icon" (click)="copyClipboard($event,'vmCommand')">
                            </div>
                        </div>
                    </ng-container>
                  
    
    
                    <div class="comm-mb" *ngIf="selectedRow[4] !== 'running'">
                        <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                          <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                          <label class="" style="padding: 8px 0px !important;color: #703501;">
                            Connect is disabled because this virtual machine is deallocated.</label>
                        </div>  
                    </div>
                    
                </form>
            </div>
        </div>
    </ng-container>

    <!-- Auto-shutdown VM -->
    <ng-container *ngIf="currentTemplate == 'autoShutdown'">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
                   <h4>Auto-shutdown<br> <span class="blur-text">Virtual Machine | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetValidation();resetForm();saveDisable=false">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                        <div class="checkbox-cs"> 
                          <input type="checkbox" name="enable-auto" class="form-check-inline"  id='enable-auto' formControlName="enableAutoShutdown" (change)="toggleTimeValidator($event);saveDisable=true">
                          <label class="value" for="enable-auto">Enable auto-shutdown <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures your virtual machine to automatically shutdown daily." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        </div>
                    </div>
                    <div class="comm-mb" *ngIf="currentForm.get('enableAutoShutdown').value">
                        <label class="flex-custom no-wrap">Shutdown time <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures the time when the virtual machine shuts down daily." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" id="time" name="time" placeholder="hh:mm:ss A" formControlName="shutdownTime" (keyup)="saveDisable=true" [class.border-danger]="(currentForm.get('shutdownTime')?.dirty ||  currentForm.get('shutdownTime')?.touched )&& currentForm.get('shutdownTime')?.errors?.timeFormatValidator"/>
                        <p class="text-danger" *ngIf="(currentForm.get('shutdownTime')?.dirty ||  currentForm.get('shutdownTime')?.touched )&& currentForm.get('shutdownTime')?.errors?.timeFormatValidator">Time format must match 'hh:mm:ss A'.</p>
                    </div>
                    <div class="comm-mb" *ngIf="currentForm.get('enableAutoShutdown').value">
                        <label class="flex-custom no-wrap">Time zone <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Subscribe for notifications before the virtual machine is shutdown so that you can skip/delay the shutdown." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="timeZoneList" bindLabel="time" bindValue="name" name="timeZone" [clearable]="false" (change)="saveDisable=true" id="timeZone" formControlName="timeZone"></ng-select>
                    </div>
                    <div class="comm-mb"  *ngIf="currentForm.get('enableAutoShutdown').value">
                        <div class="checkbox-cs">
                          <input type="checkbox" name="notify-shutdown" class="form-check-inline" id='notify-shutdown' formControlName="notifyShutdown" (change)="toggleEmailValidator($event)">
                          <label class="value" for="notify-shutdown">Notification before shutdown <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures the time zone in which the auto-shutdown time is." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        </div>
                    </div>
                    <div class="comm-mb" *ngIf="currentForm.get('enableAutoShutdown').value && currentForm.get('notifyShutdown').value">
                        <label class="flex-custom no-wrap">Email <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The email address where notifications will be sent." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" id="email" name="email" formControlName="email" (keyup)="validEmail($event)" [class.border-danger]="inputError?.validEmail"/>
                        <p class="text-danger" *ngIf="inputError?.validEmail">Please provide a valid email address.</p>
                        <!-- currentForm.get('email')?.errors?.emailValidator -->
                    </div>
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || currentForm.invalid || inputError?.validEmail" (click)="saveVMData('autoShutdown')">Save</button>
            </div>
        </div>
    </ng-container>

    <!-- Data disk -->
    <ng-container *ngIf="currentTemplate == 'dataDisk'">
        <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet">
            <div class="header">
                <div class="d-flex">
                   <h4>Data Disks<br> <span class="blur-text">Virtual Machine | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();showExistDataDisk = false">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="sideSheetForm">
                    <div class="no-wrap mb-2">
                      <!-- <div class="row">
                        <label class="half-width pr-0">Target regions</label>
                        <label class="half-width pr-0">Target region replica count</label> let list of sideSheetForm.get('lunArray')['controls'] [(ngModel)]="list.lun"
                      </div> -->
                      <ng-container formArrayName="lunArray" *ngFor="let list of sideSheetForm.get('lunArray')['controls']; let i = index;">
                        <div class="row mb-2" [formGroupName]="i">
                          <div class="ml-3">
                            <label class="flex-custom no-wrap">LUN</label>
                            <input class="form-control" style="width: 81px;" type="text" autocomplete="off" id="LUNId_{{i}}" name="LUNId_{{i}}" formControlName="lun" (keyup)="lunValidation($event,i)" />
                          </div>
                        <div class="w-50 ml-3">
                            <label class="flex-custom no-wrap">Name</label>
                               <span class="text-14-22 bold-600">{{list.get('diskName').value}}</span>
                            <!-- <span class="text-14-22 bold-600">{{data?.name}}</span> -->
                        </div>
                        <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer ml-2 mt-4" (click)="action(selectedRow,'deleteDataDisk',i,list.get('diskName').value);saveDisable=true"> 
                       </div>
                       </ng-container>
                    </div>
                    <p class="text-danger" *ngIf="inputError?.nameEmpty">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.limitNum">Value should be only numbers and must between 0 and 63</p>
                    <p class="text-danger" *ngIf="inputError?.dupCheck">Value is already taken.It should be unique</p>
                    <div *ngIf="!vmData?.properties?.storageProfile?.dataDisks?.length">
                      <p class="text-12-18 mb-1">No data disks attached</p>
                    </div>
                    <!-- sideSheetForm.get('lun')?.errors?.onlyNumberValidator|| -->
                    <div class="mb-2">
                        <span class="action-btn" (click)="showSubSidesheet = true;resetForm();resetValidation();addDefault()">Create and attach a new disk</span>
                    </div>
                    <div class="mb-2">
                      <span class="action-btn" #anchor2 (click)="onToggle()">Attach an existing disk</span>
                    </div>
                    <kendo-popup [anchor]="anchor2" (anchorViewportLeave)="showExistDataDisk = false" *ngIf="showExistDataDisk" id="createLa">
                        <div class='content kendo-content logAnalytics'>
                          <div class="rgPopup-form">
                             <div class="sub-title mb-2">Choose Data Disk</div>
                              <label for="rgName" >Data Disk<span class="mandatory">*</span></label>
                              <div class="input-box mb-2">
                                <ng-select [items]="existDiskData" bindLabel="name" bindValue="name" name="existDataDisk" [clearable]="false" id="existDataDisk" formControlName="existDataDisk" placeholder="Choose Data Disk" (change)="changeExistData($event)" required></ng-select>
                              </div>              
                          </div>
                          <div class="d-flex mb-2">
                              <button class="btn btn-btn-primary mr-2" [class.disabled]="!sideSheetForm.get('existDataDisk')?.value" (click)="addDiskInVM('exist')">Ok</button>
                              <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
                          </div>
                      </div>
                    </kendo-popup>
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="invalidForm('dataDisk') ||!saveDisable" (click)="saveVMData('dataDisk')">Save</button>
            </div>
        </div>
        <!-- [class.disabled]="!sideSheetForm.get('existDataDisk')?.value" -->
        <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
            <div class="header">
                <div class="d-flex">
                   <h4>Create a new disk</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetForm()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                        <input class="form-control full-width" type="text" autocomplete="off" id="diskName" name="diskName" formControlName="name" (keyup)="validIPName($event,80,'disk')"/>
                        <p class="text-danger" *ngIf="inputError?.diskNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.diskNameLen">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.diskNameDupCheck">{{diskName}} already exists in the resource group</p>
                        <p class="text-danger" *ngIf="inputError?.diskNameValid ||inputError?.diskNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                            and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Source type<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Create a disk from a snapshot of another disk, a blob in a storage account, or create an empty disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="sourceTypeList" bindLabel="name" bindValue="id" name="sourceType" [clearable]="false" id="sourceType" formControlName="sourceType"></ng-select>
                    </div>
                    <div class="comm-mb" *ngIf="currentForm.get('sourceType').value=='snapshot'">
                      <label class="flex-custom no-wrap">Snapshot<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a snapshot from another disk to use as the source of this new disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                      <ng-select [items]="snapshotList" bindLabel="name" bindValue="id" name="snapshot" [clearable]="false" id="snapshot" formControlName="snapshot" placeholder="Choose" (change)="changeSanpshot(newDiskObj)"></ng-select>
                  </div>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Size<span class="mandatory">*</span></label>
                        <span class="text-12-18 bold-600"> {{popupForm.get('diskSize').value}} GiB</span> <br>
                        <span class="text-12-18">{{popupForm.get('diskSKU').value}}</span> <br>
                        <span class="action-btn" (click)="triggerChangeSize();getSKUList(selectedRow,'default')">Change size</span>
                    </div>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Encryption type<span class="mandatory">*</span></label>
                        <ng-select [items]="diskEncryptionTypeList" bindLabel="name" bindValue="id" name="encryptionType" [clearable]="false" id="encryptionType" formControlName="encryptionType" (change)="getEncryptionList(selectedRow,$event);saveDisable=true"></ng-select>
                    </div>
                    <div *ngIf="currentForm.get('encryptionType').value!='EncryptionAtRestWithPlatformKey'">
                      <div class="comm-mb">
                        <div class="label-div d-flex warningBox">
                          <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                          <label class="" style="padding: 8px 0px !important;color: #703501;">
                            Once a customer-managed key is used, you can't change the selection back to a platform-managed key.</label>
                         </div>  
                      </div>
                      <div class="comm-mb">
                        <label class="flex-custom no-wrap">Disk encryption set<span class="mandatory">*</span></label>
                        <ng-select [items]="diskEncryption"  bindLabel="name" bindValue="id" name="diskEncryption" [clearable]="false" id="diskEncryption" (change)="saveDisable=true" formControlName="diskEncryption"></ng-select>
                      </div> 
                    </div>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Enable shared disk<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to be attached to two or more virtual machines, depending on storage type and disk size. When shared disk is enabled host caching is unavailable." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom no-wrap">
                            <div class="checkbox-cs">
                                <input type="radio" name="enableSharedDisk"  class="form-check-inline" id='yes' (change)="setEnableSharedDisk('yes')">
                                <label class="value" for="yes">Yes</label>
                            </div>
                            <div class="checkbox-cs ml-2">
                                <input type="radio" name="enableSharedDisk" class="form-check-inline" id='no' (change)="setEnableSharedDisk('no')" checked>
                                <label class="value" for="no">No</label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group" *ngIf="newDiskObj?.newVMDiskSize?.selectVmDiskSizeData&&(newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.name!='Premium_LRS'||newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.capabilities[6]?.value==1)">
                      <div class="label-div d-flex warningBox">
                          <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                          <label class="" style="padding: 8px 0px !important;color: #703501;">
                              Shared disk not available for the selected size.</label>
                      </div>  
                  </div> -->
                  <div class="comm-mb" *ngIf="currentForm.get('enableSharedDisk').value">
                    <label class="flex-custom no-wrap">Max shares<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The number of max shares a shared disk has 
                      depends on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                    <ng-select [items]="maxSharesList"  bindLabel="name" bindValue="id" name="maxShares" [clearable]="false" id="maxShares" placeholder="Select disk share" (change)="saveDisable=true" formControlName="maxShares"></ng-select>
                  </div> 
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid" (click)="createNewDisk()">Save</button>
            </div>
        </div>
    </ng-container>

      <!-- Capture -->
      <ng-container *ngIf="currentTemplate == 'capture'">
        <div class="sidebar-right single add-alert width-544" [class.sub-sheet]="showSubSidesheet">
            <div class="header">
                <div class="d-flex">
                   <h4>Capture<br> <span class="blur-text">Virtual Machine | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetForm();resetValidation()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Subscription</label>
                    <!-- <input class="form-control full-width" class="disabled" type="text" autocomplete="off" id="subscription" name="subscription" formControlName="subscription"/> -->
                    <ng-select [items]="subscriptionList" class="disabled"  bindLabel="subscriptionName" bindValue="subscriptionId" name="subscription" [clearable]="false" id="subscription" formControlName="subscription"></ng-select>
                  </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Resource group</label>
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" name="resourceGroup" [clearable]="false" id="resourceGroup" (change)="saveDisable=true" formControlName="resourceGroup"></ng-select>
                    <!-- getGalleryName($event); -->
                  </div>
                  <div class="sub-title mb-2">Instance Details</div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Region<span class="mandatory">*</span></label>
                    <input class="form-control full-width disabled" type="text" autocomplete="off" id="region" name="region" formControlName="region"/>
                  </div>

                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Share image to Azure compute gallery<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Create or choose the Azure compute gallery to replicate
                      your images globally, maintain versions, and more." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <div class="checkbox-cs">
                          <input type="radio" name="shareImage"  class="form-check-inline" id='yes' (change)="setShareImageCompute('yes')" checked>
                          <label class="value" for="yes">Yes, share it to a gallery as a VM image version.</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="shareImage" class="form-check-inline" id='no' (change)="setShareImageCompute('no')">
                          <label class="value" for="no">No, capture only a managed image.</label>
                      </div>
                   </div>
                   <div class="comm-mb">
                    <div class="checkbox-cs"> 
                      <input type="checkbox" name="auto-delete" class="form-check-inline"  id='auto-delete' formControlName="autoDelete">
                      <label class="value" for="auto-delete">Automatically delete this virtual machine after creating the image<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can select this checkbox to have the virtual machine 
                        deleted automatically once the image is created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                     </div>
                   </div>

                   <ng-container *ngIf="!currentForm.get('shareImageComputeGallery')?.value">
                    <div class="comm-mb">
                      <div class="checkbox-cs"> 
                        <input type="checkbox" name="zone" class="form-check-inline"  id='zone' formControlName="zoneResilincy">
                        <label class="value" for="zone">Zone resiliency<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Zone resilient snapshots can be used in any availability zone." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       </div>
                     </div>
                     <div class="comm-mb">
                      <label class="flex-custom no-wrap">Name<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name of the image that will be created from this virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" id="managedImageName" name="managedImageName" formControlName="managedImageName" (keyup)="validIPName($event,80,'managedImage');saveDisable=true"/>
                      <p class="text-danger" *ngIf="inputError?.managedImageNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.managedImageNameLen">The name must be between 1 and 80 characters.</p>
                      <p class="text-danger" *ngIf="inputError?.managedImageNameValid ||inputError?.managedImageNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                          and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>
                   </ng-container>
                  
                  <ng-container *ngIf="currentForm.get('shareImageComputeGallery')?.value">
                    <div class="sub-title mb-2">Gallery Details</div>
                    <div class="comm-mb">
                     <label class="flex-custom no-wrap">Target Azure compute gallery<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Azure compute gallery for this VM image version." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                     <ng-select [items]="azureComputeGalleryList" bindLabel="name" bindValue="name" name="computeGallery" [clearable]="false" id="computeGallery" formControlName="targetComputeGallery" (change)="getVmImageDefinitionList($event);saveDisable=true">
                      <ng-template ng-label-tmp let-item="item">
                        {{item?.name}}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          {{item?.name}}<br>
                          <span>{{getRegionName(item?.location)}}</span>
                      </ng-template>
                    </ng-select>
                     <span class="action-btn" #anchor2 (click)="onToggle();saveDisable=true">Create new</span>
                    </div>

                    <kendo-popup [anchor]="anchor2" (anchorViewportLeave)="showNewAzureComputeGallery = false" *ngIf="showNewAzureComputeGallery" id="createLa">
                      <!-- <form> -->
                        <div class='content kendo-content logAnalytics'>
                          <div class="rgPopup-form">
                              <label for="rgName" class="">Target Azure compute gallery<span class="mandatory">*</span></label>
                              <div class="input-box mb-2">
                                <input type="text" class="form-control"  id="rgName" autocomplete="off"  placeholder="Enter name" name="rgName" formControlName="newAzureComputeGallery" (keyup)="validIPName($event,80,'newAzureComputeGallery')"  required>
                                <p class="text-danger" *ngIf="inputError?.newAzureComputeGalleryNameEmpty">The value must not be empty.</p>
                                <p class="text-danger" *ngIf="inputError?.newAzureComputeGalleryNameLen">The name must be between 1 and 80 characters.</p>
                                <p class="text-danger" *ngIf="inputError?.newAzureComputeGalleryNameDupCheck">An azure compute gallery with this name already exists in the current subscription.</p>
                                <p class="text-danger" *ngIf="inputError?.newAzureComputeGalleryNameValid ||inputError?.newAzureComputeGalleryNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                                    and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                              </div>              
                          </div>
                          <div class="d-flex mb-2">
                              <button class="btn btn-btn-primary mr-2" [class.disabled]="!currentForm.get('newAzureComputeGallery')?.value || invalidForm('targetAzureGallery')" (click)="pushName()">Ok</button>
                              <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
                          </div>
                      </div>
                      <!-- </form> -->
                    </kendo-popup>

                    <div class="comm-mb">
                     <label class="flex-custom no-wrap">Operating system state<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If you choose to generalize the virtual machine, no further actions 
                       on it are allowed. For example, a generalized virtual machine can't 
                       be started or modified. If you choose the specialized virtual 
                       machine, you can continue to use it as normal." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       <div class="checkbox-cs" [class.disbled-icon]="generalizedState=='generalized'">
                           <input type="radio" name="osState"  class="form-check-inline" id='generalized' (change)="setOsState('Generalized');saveDisable=true" checked>
                           <label class="value" for="generalized">Generalized<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="VMs created from this image require hostname, admin user, 
                             and other VM related setup to be completed on first boot." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       </div>
                       <div class="checkbox-cs" [class.disbled-icon]="generalizedState=='generalized'">
                           <input type="radio" name="osState" class="form-check-inline" id='specialized' (change)="setOsState('Specialized')">
                           <label class="value" for="specialized">Specialized<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="VMs created from this image are completely configured and do not
                             require parameters such as hostname and admin user/password." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       </div>
                      </div>
                      <div class="comm-mb" *ngIf="generalizedState=='generalized'">
                        <div class="label-div d-flex blueBox">
                          <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                          <label class="" style="padding: 8px 0px !important;">
                            This virtual machine has been generalized.</label>
                        </div>  
                    </div>
                      <div class="comm-mb" *ngIf="currentForm.get('osState').value == 'Generalized'">
                       <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                         <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                         <label class="" style="padding: 8px 0px !important;color: #703501;">
                           Capturing a virtual machine image will make the virtual machine unusable. This action cannot be undone.</label>
                       </div>  
                      </div>
                      <div class="comm-mb">
                       <label class="flex-custom no-wrap">Target VM image definition<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="
                        The parent VM image definition for this version." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       <ng-select [items]="vmImageDefinitionList" [class.disabled]="!currentForm.get('targetComputeGallery').value" bindLabel="name" bindValue="name" name="targetImageDefinition" [clearable]="false" id="targetImageDefinition" (change)="selectedImage($event);saveDisable=true" formControlName="targetImageDefinition" >
                        <ng-template ng-label-tmp let-item="item">
                          {{item?.name}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <span style="color: #21262C!important;">{{item?.name}}</span><br>
                            <span>OS type: {{item?.properties?.osType}}, VM generation: {{item?.properties?.hyperVGeneration}} </span>
                        </ng-template>
                       </ng-select>
                       <span class="action-btn" (click)="imageDefForm();showSubSidesheet = true;saveDisable=true" *ngIf="currentForm.get('targetComputeGallery').value">Create new</span>
                     </div>
 
                     <div class="sub-title mb-2">Version Details</div>
                     <div class="comm-mb">
                       <label class="flex-custom no-wrap">Version number<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="
                        The VM image version name in semantic version pattern. The allowed characters are digit and period. For example: 0.0.1, 15.35.0" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       <input class="form-control full-width" type="text" autocomplete="off" id="versionNumber" name="versionNumber" placeholder="Example: 0.0.1, 15.35.0" formControlName="versionNumber" (keyup)="getVmValidVersion($event);saveDisable=true"/>
                       <p class="text-danger" *ngIf="inputError?.nameEmpty">The value must not be empty.</p>
                       <p class="text-danger" *ngIf="inputError?.validVersion">The VM image version name should follow Major(int).Minor(int).Patch(int) format. For example: 0.0.1, 15.35.0</p>
                      </div>
                     <div class="comm-mb">
                       <div class="checkbox-cs"> 
                         <input type="checkbox" name="exclude-latest" class="form-check-inline"  id='exclude-latest' formControlName="excludeLatest">
                         <label class="value" for="exclude-latest">Exclude from latest<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If this flag is set to 'Yes', VMs created using 
                           the latest version will omit this version." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       </div>
                       <span class="text-12-18" *ngIf="currentForm.get('excludeLatest').value">The cost of your storage account depends on the usage and the options you choose below.</span>
                     </div>
                    
                     <div class="comm-mb">
                       <label class="flex-custom no-wrap">End of life date</label>
                       <input [min]="min" [owlDateTimeTrigger]="dt1" class="form-control full-width" [owlDateTime]="dt1" placeholder="MM/DD/YYYY" formControlName="endLifeDate" (keyup)="validEndDate($event)">
                       <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                       <!-- <input class="form-control full-width" type="text" autocomplete="off" id="lifeDate" name="lifeDate" placeholder="MM/DD/YYYY" formControlName="endLifeDate"/> -->
                       <p class="text-danger" *ngIf="inputError?.endDateFormat">Date format must match 'MM/DD/YYYY' or 'YYYY-MM-DD'.</p>
                       <p class="text-danger" *ngIf="inputError?.validEndDate || currentForm.get('endLifeDate')?.errors?.futureDateValidator">End-of-life date must be set to a future date.</p> 
                      </div>
 
                     <div class="sub-title mb-2">Replication</div>
                     <div class="comm-mb">
                       <label class="flex-custom no-wrap">Default replica count<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The default number of replicas to be created per region. The default
                         value can be overriden per region in the replication table." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       <input class="form-control full-width" type="text" autocomplete="off" id="replication" name="replication" formControlName="defaultReplicaCount" (keyup)="setReplicationCount($event,'input')"/>
                       <p class="text-danger" *ngIf="!currentForm.get('defaultReplicaCount')?.value">The value must not be empty.</p>
                       <p class="text-danger" *ngIf="currentForm.get('defaultReplicaCount')?.errors?.onlyNumberValidator">The value must be a valid number.</p>
                       <p class="text-danger" *ngIf="inputError?.limitNum">The value must be between 1 and 50.</p>
            
                      </div>
 
                     <div class="comm-mb">
                       <div class="row" style="padding: 0px;">
                         <label class="col-md-3 pr-0">Target regions</label>
                         <label class="col-md-4 pr-0">Target region replica count</label>
                         <label class="col-md-4 pr-0">Storage account type</label>
                       </div>
                       <ng-container formArrayName="replicationArray" *ngFor="let list of currentForm.get('replicationArray')['controls']; let i = index;">
                        <div class="row mb-2" style="padding: 0px;" [formGroupName]="i">
                          <div class="col-md-3 pr-0">
                            <input class="form-control full-width disabled" type="text" autocomplete="off" id="targetRegion_{{i}}" name="targetRegion_{{i}}" formControlName="targetRegion"/>
                           <!-- <ng-select [items]="sourceTypeList" class="disabled" bindLabel="name" bindValue="name" name="targetRegion" [clearable]="false" id="targetRegion_{{i}}" formControlName="targetRegion_{{i}}"></ng-select> -->
                          </div>
                          <div class="col-md-4 pr-0">
                           <input class="form-control full-width" type="text" autocomplete="off" id="replicaCount_{{i}}" name="replicaCount_{{i}}" formControlName="targetReplicaCount"  (keyup)="setReplicationCount($event,'array',i)"/>
                          </div>
                          <div class="col-md-4 pr-0">
                           <ng-select [items]="storageAccountTypeList" bindLabel="name" bindValue="id" name="storageAccountType_{{i}}" [clearable]="false" id="storageAccountType_{{i}}" formControlName="storageAccountType" (change)="saveDisable=true"></ng-select>
                          </div>
                          <div class="col-md-1 pr-0">
                           <img src="assets/app-monitoring/Delete.svg" [class.disabled-icon]="i == 0" *ngIf="replicationArrayList?.length > 1" alt="" class="cursor-pointer ml-2" (click)="removeTag(i);saveDisable=true">
                          </div>
                       </div>
                       <!-- <p class="text-danger" *ngIf="replicationArrayList.at(i).get('targetReplicaCount')?.errors">The value must be between 1 and 50.</p> -->
                       </ng-container>
                       <p class="text-danger" *ngIf="inputError?.limitNumReplica">The value must be between 1 and 50.</p>
                       <span class="action-btn" (click)="addReplication();saveDisable=true">Add new</span>
                     </div>
                     <div class="comm-mb">
                      <ng-container formArrayName="tagsArray" *ngFor="let tanch of currentForm.get('tagsArray')['controls']; let i = index;">
                        <div class="comm-mb ">
                          <div class="notify-wrap" id="tags-list-{{i}}" [formGroupName]="i">
                            <div class="w-50">
                              <label class="flex-custom nowrap">Tag name<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                              <input class="form-control full-width" type="text" formControlName="name"/>
                            </div>
                            <span class="ml-3" style="margin-top: 20px;">:</span>
                            <div class="w-50 ml-3">
                              <label class="flex-custom nowrap">Value<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Tag values are case-sensitive and are limited to 256 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                              <input class="form-control full-width" type="text" formControlName="value"/>
                            </div>
                            <ng-container *ngIf="tagsList?.length > 1">
                              <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeTagList(i)">
                            </ng-container>
                          </div>
                          <span class="action-btn" (click)="addTag()" *ngIf="i == tagsList?.length-1">Add another tag</span>
                        </div>
                      </ng-container>
                  </div> 
                  </ng-container>
                  
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"  [class.disabled]="!saveDisable || invalidForm('capture') || currentForm.invalid" (click)="saveVMData('capture')">Save</button>
            </div>
        </div>
        <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
            <div class="header">
                <div class="d-flex">
                   <h4>VM image definition <br> <span class="blur-text">Virtual Machine | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false; resetSideSheetValidation()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="sideSheetForm">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">VM image definition name<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name of the image." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" id="vmImageName" name="vmImageName" formControlName="imageDefinitionName" (keyup)="validIPName($event,80,'managedImage')"/>
                        <p class="text-danger" *ngIf="inputError?.managedImageNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.managedImageNameLen">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.managedImageNameDupCheck">VM image definition name must be unique in its Azure compute gallery.</p>
                        <p class="text-danger" *ngIf="inputError?.managedImageNameValid ||inputError?.managedImageNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                            and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">OS type<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the operating system type for the image." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="checkbox-cs disabled-icon">
                            <input type="radio" name="osType"  class="form-check-inline" id='linux' (change)="setOsType('Linux')" [checked]="sideSheetForm.get('osType')?.value == 'Linux'">
                            <label class="value" for="linux">Linux</label>
                        </div>
                        <div class="checkbox-cs disabled-icon">
                            <input type="radio" name="osType" class="form-check-inline" id='windows' (change)="setOsType('Windows')" [checked]="sideSheetForm.get('osType')?.value == 'Windows'">
                            <label class="value" for="Windows">Windows</label>
                        </div>
                    </div>
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">VM generation<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Generation 2 VMs use the new UEFI-based boot architecture rather 
                        than the BIOS-based architecture used by generation 1 VMs." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <div class="checkbox-cs disabled-icon">
                          <input type="radio" name="vmGeneration"  class="form-check-inline" id='V1'(change)="setVmGen('V1')" [checked]="sideSheetForm.get('vmGeneration')?.value == 'V1'">
                          <label class="value" for="V1">Gen 1</label>
                      </div>
                      <div class="checkbox-cs disabled-icon">
                          <input type="radio" name="vmGeneration" class="form-check-inline" id='V2'(change)="setVmGen('V2')" [checked]="sideSheetForm.get('vmGeneration')?.value == 'V2'">
                          <label class="value" for="V2">Gen 2</label>
                      </div>
                    </div>
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Publisher<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name of the VM image definition publisher." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" id="publisher" name="publisher" formControlName="publisher" (keyup)="validIPName($event,128,'publisher')"/>
                      <p class="text-danger" *ngIf="inputError?.publisherNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.publisherNameLen">The name must be between 1 and 128 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.publisherNameValid ||inputError?.publisherNameLastValid">The VM image definition publisher can contain only letters, numbers, hyphens, 
                          periods, and underscores. The publisher can't end with a period.</p>
                    </div>
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Offer<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name of the VM image definition offer." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" id="offer" name="offer" formControlName="offer" (keyup)="validIPName($event,64,'offer')"/>
                      <p class="text-danger" *ngIf="inputError?.offerNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.offerNameLen">The name must be between 1 and 64 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.offerNameValid ||inputError?.offerNameLastValid">The VM image definition offer can contain only letters, numbers, hyphens, 
                          periods, and underscores. The offer can't end with a period.</p>
                    </div>
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">SKU<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name of the VM image definition SKU." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" id="sku" name="sku" formControlName="sku" (keyup)="validIPName($event,128,'sku')"/>
                      <p class="text-danger" *ngIf="inputError?.skuNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.skuNameLen">The name must be between 1 and 128 characters.</p>
                      <p class="text-danger" *ngIf="inputError?.skuNameValid ||inputError?.skuNameLastValid">The VM image definition sku can contain only letters, numbers, hyphens, periods, 
                        and underscores. The sku can't end with a period..</p>
                    </div>
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="sideSheetForm.invalid || invalidForm('imgDef')" (click)="pushName('imageDefinitionName'); resetSideSheetValidation()">Save</button>
            </div>
        </div>
    </ng-container>


    <!-- Reset Password -->
    <ng-container *ngIf="currentTemplate == 'resetPassword'">
      <div class="sidebar-right single add-alert" >
          <div class="header">
              <div class="d-flex">
                  <h4>Reset Password<br> <span class="blur-text">Virtual Machine | {{selectedRow[0]}}</span></h4>
                 <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
              <form [formGroup]="currentForm">
                <div class="comm-mb" *ngIf="selectedRow[4] !== 'running'">
                  <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                      The virtual machine must be running before using VMAccess.</label>
                  </div>  
                </div>

                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Mode<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The specified user will be created or updated, and granted sudo privileges." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <div class="checkbox-cs" [class.disabled-icon] = "selectedRow[4] !== 'running'">
                          <input type="radio" name="mode"  class="form-check-inline" id='resetPwd' (change)="setMode('resetPwd')" checked>
                          <label class="value" for="resetPwd">Reset password</label>
                      </div>
                      <div class="checkbox-cs" [class.disabled-icon] = "selectedRow[4] !== 'running'" *ngIf="selectedRow[5] == 'Linux'">
                        <input type="radio" name="mode"  class="form-check-inline" id='resetSSH' (change)="setMode('resetSSH')">
                        <label class="value" for="resetSSH">Reset SSH public key</label>
                      </div>
                      <div class="checkbox-cs" [class.disabled-icon] = "selectedRow[4] !== 'running'">
                          <input type="radio" name="mode" class="form-check-inline" id='resetConfig' (change)="setMode('resetConfig')">
                          <label class="value" for="resetConfig">Reset configuration only</label>
                      </div>
                  </div>

                  <div class="comm-mb" *ngIf="currentForm.get('mode').value == 'resetPwd' || currentForm.get('mode').value == 'resetSSH'">
                    <label class="flex-custom no-wrap">Username<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The specified user will be created or updated, and granted admin privileges." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" [class.disabled] = "selectedRow[4] !== 'running'" autocomplete="off" id="userName" name="userName" formControlName="userName"/>
                    <p class="text-danger mb-0" *ngIf="currentForm?.controls?.userName?.errors?.minlength || currentForm?.controls?.userName?.errors?.maxLength">The value must be between 1 and 64 characters long.</p>
                    <p class="text-danger mb-0" *ngIf="currentForm?.controls?.userName?.errors?.userNameValidator">Username must only contain letters, numbers, hyphens, and underscores and may not start with a hyphen or number.</p>
                  </div>
                  <div class="comm-mb" *ngIf="currentForm.get('mode').value == 'resetPwd'">
                    <label class="flex-custom no-wrap">Password<span class="mandatory">*</span></label>
                    <input class="form-control full-width" type="text" [class.disabled] = "selectedRow[4] !== 'running'" autocomplete="off" id="diskName" name="diskName" formControlName="password"/>
                    <p class="text-danger mb-0" *ngIf="currentForm?.controls?.password?.errors?.minlength || currentForm?.controls?.password?.errors?.maxLength">The value must be between 12 and 72 characters long.</p>
                  </div>
                  <div class="comm-mb" *ngIf="currentForm.get('mode').value == 'resetPwd'">
                    <label class="flex-custom no-wrap">Confirm password<span class="mandatory">*</span></label>
                    <input class="form-control full-width" type="text" [class.disabled] = "selectedRow[4] !== 'running'" autocomplete="off" id="diskName" name="diskName" formControlName="confirmPwd"/>
                    <p class="text-danger mb-0" *ngIf="currentForm?.controls?.confirmPwd?.errors?.passwordMismatch">Password and confirm password must match.</p>
                  </div>
                  <div class="comm-mb" *ngIf="currentForm.get('mode').value == 'resetSSH'">
                    <label class="flex-custom no-wrap">SSH public key<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an RSA public key in the single-line format (starting with 'ssh-rsa') or the multi-line PEM format. You can generate SSH keys using ssh-keygen 
                      on Linux and OS X, or PuTTYGen on Windows." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <textarea name="comment" class="full-width form-control" [class.disabled] = "selectedRow[4] !== 'running'" formControlName="sshKey"></textarea>
                    <p class="text-danger mb-0" *ngIf="currentForm?.controls?.sshKey?.errors?.sshKeyValidator">Multiline SSH key must begin with '---- BEGIN SSH2 PUBLIC KEY ----' and end with '---- END SSH2 PUBLIC KEY ----'.</p>
                  </div>
              </form>
          </div>

          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid" (click)="resetPassword()">Save</button>
          </div>
        

      </div>
    </ng-container>

    <!-- Redeploy and Reapply -->
    <ng-container *ngIf="currentTemplate == 'redeploy'">
      <div class="sidebar-right single add-alert" >
          <div class="header">
              <div class="d-flex">
                  <h4>Redeploy + Reapply<br> <span class="blur-text">Virtual Machine | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
            <div class="comm-mb"> <span class="text-13-20">Can't connect to your virtual machine?</span></div>
            <div class="comm-mb">  
              <span class="text-13-20 bold-600">Redeploy<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Try redeploying your virtual machine, which will migrate it to a new Azure host. 
                If you continue, the virtual machine will be restarted and you will lose any data 
                on the temporary drive. While the redeployment is in progress, the virtual
                machine will be unavailable." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span>
              <button class="btn btn-btn-primary full-width" (click)="reDeployVM()">Redeploy</button>
            </div>
            <div class="comm-mb"> <span class="text-13-20">Virtual machine in a failed state?</span></div>
            <div class="comm-mb">  
              <span class="text-13-20 bold-600">Reapply<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Try reapplying your virtual machine’s state. This operation will rerun VM 
                provisioning and help solve the VM failed state, in case when VM provisioning 
                failed while executing a previous VM action." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span>
              <button class="btn btn-btn-primary full-width" (click)="reApplyVM()">Reapply</button>
            </div>
          </div>
      </div>
    </ng-container>

</ng-container>




<ng-template #vmDetailsTemplate>
  <div class="medium-popup-wrapper width-832">
      <div class="modal-body scroll-y">
      <div class="d-flex mb-3">
          <div class="popup-title">Virtual Machine</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="tab-list">
          <div class="tab-item" [class.disabled-icon]="selectedRow[4] != 'running'" (click)="closeModal();openSidesheet('connectVM',selectedRow)">Connect</div>
          <div class="tab-item" [class.disabled-icon]="selectedRow[4] == 'running'" (click)="action(selectedRow,'startVM')">Start</div>
          <div class="tab-item" [class.disabled-icon]="selectedRow[4] != 'running'" (click)="action(selectedRow,'restartVM')">Restart</div>
          <div class="tab-item" [class.disabled-icon]="selectedRow[4] != 'running'" (click)="action(selectedRow,'stopVM')">Stop</div>
          <div class="tab-item" (click)="action(selectedRow,'powerOffVM')">Power Off</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('capture',selectedRow)">Capture</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('dataDisk',selectedRow)">Datadisks</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('redeploy',selectedRow)">Redeploy</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('resetPassword',selectedRow)">ResetPassword</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('autoShutdown',selectedRow)">AutoShutdown</div>
          <div class="tab-item" (click)="action(selectedRow,'deleteVM')">Delete</div>
      </div>
      <div class="row row1" >
          <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
              <div class="list-item">
                  <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                  <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                    <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'? selectedRow[i] : getTags(selectedRow[i])}}</div>
                  </ng-container>
                  <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
                  <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedRow[i] | titlecase}}</div>
                  <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
              </div>
          </div>
      </div>
      </div>
      <div class="submit-sec text-right pad-10-24">
      <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
      </div>
  </div>
</ng-template>

<ng-template #deleteConfirm>
  <div class="medium-popup-wrapper">
    <div class="modal-body">
      <div class="d-flex mb-3">
        <div class="popup-title">Delete {{getResourceTitle()}}</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="dlt-content">
        <p>Are you sure want to delete {{getResourceName()}}?</p>
      </div>
      <div class="submit-sec text-right">
        <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
        <button id="secondary" class="btn btn-red" (click)="deleteAction()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #stopVM>
  <div class="medium-popup-wrapper">
    <div class="modal-body">
      <div class="d-flex mb-3">
        <div class="popup-title">Stop Virtual Machine</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="dlt-content">
        <ng-container *ngIf="vmPublicIpData && vmPublicIpData?.publicIpesponse?.properties?.deleteOption !== 'Detach'">
          <div class="label-div d-flex mb-2" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
            <label class="" style="padding: 2.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
            <label class="text-12-18 mb-0" style="padding: 8px 0px !important;color: #703501;">Public IP address ‘{{vmPublicIpData?.properties?.publicIPAddress?.properties?.ipAddress}}’ will be lost if this VM is stopped</label>
          </div>  
          <div class="checkbox-cs mb-3"> 
              <input type="checkbox" name="reserve-ip" class="form-check-inline" [(ngModel)]="selectedRow.reserveIp" id='reserve-ip'>
              <label class="value" for="reserve-ip">Do you want to reserve the public IP address?</label>
          </div>
        </ng-container>
        <p>Are you sure you want to stop {{selectedRow[0]}}?</p>
      </div>
      <div class="submit-sec text-right">
        <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
        <button id="secondary" class="btn btn-btn-primary" (click)="stopVMAction(selectedRow)">Stop</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #virtualMachineDiskSizePopup>
  <div class="virtual-machine-size-popup modal-lg">
      <div class="modal-header-diagram-tool" style="margin-right: 8px;">
          <h3 class="save-version-heading">Select a disk size</h3>
          <button type="button" class="close pull-right margin-close-btn" aria-label="Close" (click)="diskSizeNoChanges()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body-diagram-tool scroll-y">
        <form [formGroup]="popupForm">
          <div class="form-group">
              <div class="label-div">
                  <label for="diskSKU" class="control-label" style="padding: 0px; padding-bottom: 6px;">Disk SKU<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Premium SSD disks offer high-performance, low-latency disk support for I/O-intensive applications and production workloads. Standard SSD Disks are a cost effective storage option optimized for workloads that need consistent performance at lower IOPS levels. Use Standard HDD disks for Dev/Test scenarios and less critical workloads at lowest cost." container="body" placement="above" containerClass="disk-tooltip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
              </div>
              <div class="input-box model-drop-down">
                <ng-select style="padding: 0px;width: 53%;font-weight: 400;font-size: 13px;" [items]="diskSKUList" bindLabel="name" bindValue="id"  name="diskSKU" placeholder="Choose disk SKU" formControlName="diskSKU" (change)="getSKUList(selectedRow,'change',$event);saveDisable=true" [clearable]="false"></ng-select>
                  <!-- <ng-select style="padding: 0px;width: 53%;font-weight: 400;font-size: 13px;" [items]="diskSKUList" bindLabel="name" bindValue="id"  name="diskSKU" placeholder="Choose disk SKU" (change)="vmDiskSizeListFilter()" [clearable]="false"></ng-select> -->
              </div>   
          </div>
          <div class="custom-fixed-tables" style="height: calc(100vh - 410px);max-height: 223px; min-height: 136px;">
                <table class="fix-width-vms table">
                    <thead>
                      <tr>
                        <th class="table-name">
                          <div class="flex-custom no-wrap">
                            <span>Size (GiB)</span>
                          </div>
                        </th>
                        <th class="status">
                          <div class="flex-custom no-wrap">
                            <span>Performance tier</span>
                          </div>
                        </th>
                        <th class="status">
                            <div class="flex-custom no-wrap">
                              <span>Provisioned IOPS</span>
                            </div>
                        </th>
                        <th class="status" style="border-right: solid 1px #8CD4FD;">
                          <div class="flex-custom no-wrap">
                            <span>Provisioned throughput</span>
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <ng-container *ngFor="let list of skuList,let i=index">
                          <tr (click)="setsize(list,i);" id="row-{{i}}" [class.active-row]="list?.active">
                              <td>{{list?.capabilities[0].value}}</td>
                              <td>{{list?.size}}</td>
                              <td>{{list?.capabilities[2].value}}</td>
                              <td>{{list?.capabilities[4].value}}</td>
                          </tr>
                        </ng-container>
                        <div class="col-md-12 mt-3" *ngIf="!skuList?.length">
                          <p class="content-14 blur-text">No results</p>
                        </div>
                    </tbody>
                </table>
            </div>
            <div class="form-group">
              <div class="label-div">
                  <label for="customDiskSizeAdd" class="control-label" style="padding: 0px; padding-bottom: 6px;">Custom disk size (GiB)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enter the size of the disk you would like to create." matTooltipClass="nav-tooltips" container="body" placement="above" containerClass="disk-tooltip" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
              </div>
              <div class="input-box">
                  <input type="number" style="width: 53%;" class="form-control remove-arrows" [class.border-danger]="diskSizeMsg" id="customDiskSizeAdd" autocomplete="off" formControlName="diskSize" placeholder="" name="customDiskSizeAdd" (keyup)="validSize($event)" >
                  <p class="text-danger" *ngIf="diskSizeMsg">The value must be between 1 and 32767.</p>
              </div>   
          </div>
            <div class="form-group" *ngIf="popupForm.get('diskSKU')?.value=='Premium_LRS'">
              <div class="label-div">
                  <label for="performanceTier" class="control-label" style="padding: 0px; padding-bottom: 6px;">Performance tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Changing the performance tier allows you to prepare for and meet higher demand without using your disk's bursting capability." container="body" placement="above" containerClass="disk-tooltip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
              </div>
              <div class="input-box model-drop-down">
                <ng-select style="padding: 0px;width: 53%;font-weight: 400;font-size: 13px;" [items]="performance"  bindLabel="name" bindValue="id" name="performance" [clearable]="false" id="performance" (change)="saveDisable=true" formControlName="performance" placeholder="Select a performance tier to upgrade"></ng-select>
                  <!-- <ng-select style="padding: 0px;width: 53%;font-weight: 400;font-size: 13px;" [items]="vmDisk.performanceTierList" bindLabel="name" [disabled]="vmDisk?.performanceTierList?.length==0&&vmDisk?.customDiskSizeAdd" [(ngModel)]="vmDisk.performanceTier" name="performanceTier" placeholder="Select a performance tier to upgrade" [clearable]="false"> -->        
                  <!-- </ng-select> -->
                   <p class="text-danger" *ngIf="diskTier">Upgraded tiers are not available for the selected size.</p>
              </div>   
          </div>
        </form>
      </div>
      <div class="fixed-popup-btm-btn">
        <button class="btn btn-btn-primary pull-right" [class.disabled]="popupForm.invalid || diskSizeMsg"
          (click)="closeModal();selectedVMDisk();">Select</button>
      </div>
  </div>
</ng-template>
