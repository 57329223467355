import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { textAlignToString } from '@syncfusion/ej2-angular-diagrams';
import { number } from 'echarts';

@Component({
  selector: 'app-disk-actions',
  templateUrl: './disk-actions.component.html',
  styleUrls: ['./disk-actions.component.scss']
})
export class DiskActionsComponent implements OnInit {
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  @ViewChild("diskDetailsTemplate") diskDetailsTemplate: TemplateRef<any>;
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  saveDisable:boolean;

  inputError={
    sizeGB:false,
  }
  encryptionType=[
    {id:'EncryptionAtRestWithPlatformKey',name:'(Default) Encryption at-rest with a platform-managed key'},
    {id:'EncryptionAtRestWithCustomerKey',name:'Encryption at-rest with a customer-managed key'},
    {id:'EncryptionAtRestWithPlatformAndCustomerKeys',name:'Double encryption with platform-managed and customer-managed keys'}
  ]
  maxSharesList=[]
  diskSKU=[
    {id:'Premium_LRS',name:'Premium SSD'},
    {id:'StandardSSD_LRS',name:'Standard SSD'},
    {id:'Standard_LRS',name:'Standard HDD'}
  ]
  performance=[];
  selectedType: any;
  selectedSideSheetRow: any;
  diskData: any;
  deleteDisable: any;
  diskAccessList: any;
  encryptionList: any;
  diskEncryption: any;
  diskSizeMsg: boolean;
  diskStateMsg: boolean;
  diskSkuMsg: boolean;
  skuList: any;
  diskTier: boolean;
  vmConfig: any;


  constructor(public util:UtilService, private titleCase:TitleCasePipe,private formBuilder: FormBuilder,private modalService: NgbModal,private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }

  //----------------------------------------Common-----------------------------------------------------------------------------------
 
  
  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "diskConfiguration" : {
        this.currentForm = this.util.getForm('diskConfiguration')
        let size=this.diskData?.properties?.diskSizeGB
        this.maxSharesList=[]
        let maxShare=size<=512 ? 3 : size>512 && size<=4096 ? 5:10
        for(let i=1;i<=maxShare;i++){this.maxSharesList.push({id:i,name:`${i}`})}
        let sharedDisk=Number(this.diskData?.properties?.maxShares) ||1
        this.currentForm.get('sharedDisk').setValue(sharedDisk==1?false:true)
        sharedDisk!=1?this.currentForm.get('maxShares').setValue(sharedDisk):false
        this.currentForm.get('onDemandBursting').setValue(this.diskData?.properties?.burstingEnabled)
        this.diskStateMsg=this.diskData?.properties?.diskState!='Unattached'?true:false
        this.diskSizeMsg=size<512?true:false
        this.diskSkuMsg=this.diskData?.sku?.name.includes('Premium')?false:true
        if(this.diskSkuMsg||this.diskStateMsg || this.diskSizeMsg){
          this.currentForm.get('sharedDisk').disable()
          this.currentForm.get('onDemandBursting').disable()
        }
        this.showSidesheet = true;
        break;
      } 
      case "sizeAndPerformance" : {
        this.currentForm = this.util.getForm('diskSizeAndPerformance')
        this.currentForm.get('diskSKU').setValue(this.diskData?.sku?.name)
        await this.getSKUList(this.selectedRow,'default')
        this.currentForm.get('performance').setValue(this.diskData?.properties?.tier)
        this.diskData?.managedBy?await this.checkDiskVMData(this.diskData): this.diskStateMsg=this.diskData?.properties?.diskState!='Unattached'?true:false
        this.showSidesheet = true;
        let size=this.diskData?.properties?.diskSizeGB
        size=size.toString()
        this.currentForm.get('diskSize').setValue(size)
    
        break;
      } 
      case "encryption" : {
        this.currentForm = this.util.getForm('encryption')
        await this.getEncryptionList(this.selectedRow,'')
        // this.setDefaultValue('encryption')
        this.currentForm.get('encryptionType').setValue(this.diskData?.properties?.encryption?.type)
        if(this.diskData?.properties?.encryption?.type!="EncryptionAtRestWithPlatformKey"){
          this.currentForm.get('diskEncryption').setValue(this.diskData?.properties?.encryption?.diskEncryptionSetId)
          this.currentForm.get('diskEncryption').disable()
          this.currentForm.get('encryptionType').disable()
        }
        if(this.diskData?.properties?.encryption?.type=="EncryptionAtRestWithPlatformKey" && this.diskData?.properties?.diskState!='Unattached'){
          this.currentForm.get('encryptionType').disable()
        }
        this.showSidesheet = true;
        break;
      } 
      case "diskNetworking" : {
        this.currentForm = this.util.getForm('diskNetworking')
        await this.getDiskAccessList(this.selectedRow)
        this.currentForm.get('networkConnectivity').setValue(this.diskData?.properties?.networkAccessPolicy)
        this.currentForm.get('diskAccess').setValue(this.diskData?.properties?.diskAccessId)
        // this.setDefaultValue('diskNetworking')
        this.showSidesheet = true;
        break;
      }  
      case "deleteDisk" : {
        this.openModal(this.deleteConfirmPopup,this.selectedRow,'deleteDisk')
        break;
      }  
    }
  }

  async deleteAction(){
    switch(this.selectedType){
      case 'deleteDisk' :{
        await this.deleteDiskData(this.selectedRow)
        break;
      }
    }
  }
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedSideSheetRow=row;
    this.selectedType=type;
    type=='diskPopUp'?this.getDiskData(row,'deleteCondition'):false 
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  closeSidesheet(){
    this.showSidesheet = false
  }

  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }

  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  addAddressSpace(){
    this.addressSpaceList.push(this.util.getForm('addAddressSpace'))
  }
  get addressSpaceList() {
    return this.currentForm.get('addressSpaceArray') as FormArray;
  }
  removeAddressSpace(index) {
    this.addressSpaceList.controls.splice(index, 1);
  }

  getSuccesText(type){
    switch(type){
      case'networking':return 'Disk networking updated successfully!'
      case'encryption':return 'Disk encryption updated successfully!'
      case'configuration': return 'Disk configuration updated successfully!'
      case 'sizeAndPerformance' :  return 'Disk size and performance updated successfully!'
      default: return 'Changes saved successfully!'
    }
  }

  getFailureText(type){
    switch(type){
      case'networking':return 'Disk networking updating failed'
      case'encryption':return 'Disk encryption updating failed'
      case'configuration':return 'Disk configuration updating failed'
      case 'sizeAndPerformance' :  return 'Disk size and performance updating failed'
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'networking':return 'Updating disk networking...'
      case'encryption':return 'Updating disk encryption...'
      case'configuration':return'Updating disk configuration...'
      case'sizeAndPerformance': return 'Updating disk size and performance...'
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'disk':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
      case 'size':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = Number(a?.capabilities[0].value);
          let y = Number(b?.capabilities[0].value);
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.skuList=sorted;
        break;
      }
      case 'tier':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = a?.size;
          let y = b?.size;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.skuList=sorted;
        break;
      }
      case 'IOPS':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = Number(a?.capabilities[2].value);
          let y = Number(b?.capabilities[2].value);
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.skuList=sorted;
        break;
      }
      case 'Mbps':{
        let sorted=list;
        sorted.sort((a,b)=>{
          let x = Number(a?.capabilities[4].value);
          let y = Number(b?.capabilities[4].value);
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.skuList=sorted;
        break;
      }
    }
  }
  invalidForm(type){
    switch(type){
      case'sizeAndPerformance':{
        return this.inputError.sizeGB||this.diskSizeMsg||this.diskStateMsg;
      }
    }
  }
  resetForm(){
    this.currentForm.reset()
  }
  resetValidation(){
    return this.inputError.sizeGB=false,this.diskSizeMsg=false,this.diskStateMsg=false,this.diskTier=false
  }

 
  setRadioBtn(id,type){
  switch(type){
    case 'networking':
      this.saveDisable=true
      switch(id){
        case'public':{
          let value='AllowAll'
          this.currentForm.get('networkConnectivity').setValue(value)
          break;}
        case'private':{
          let value='AllowPrivate'
          this.currentForm.get('networkConnectivity').setValue(value)
          break}
        case'deny':{
          let value='DenyAll'
          this.currentForm.get('networkConnectivity').setValue(value)
          break}
      }
      break;
      case 'diskConfiguration':
      this.saveDisable=true
      let value=id=='yes'?true:false
      id=='yes'?this.currentForm.get('onDemandBursting').setValue(false):false
      this.currentForm.get('sharedDisk').setValue(value)
      break;
     
  }
  }
  setDefaultValue(type){
  switch(type){
    case'encryption':{
        this.currentForm.get('encryptionType').setValue('EncryptionAtRestWithPlatformKey')
    }
    case'diskNetworking':{
      // this.currentForm.get('networkConnectivity').setValue('EncryptionAtRestWithPlatformKey')
    }
  }
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  
  async saveDisk(type){
    switch(type){
      case'networking':{
        let body = this.diskData
        let rawValue = this.currentForm.getRawValue()
        if(rawValue.networkConnectivity=='AllowPrivate'){
          body.properties['networkAccessPolicy']=rawValue.networkConnectivity
          body.properties['diskAccessId']=rawValue.diskAccess
        }else{
          body.properties['networkAccessPolicy']=rawValue.networkConnectivity=='AllowAll'?'AllowAll':'DenyAll'
          body.properties['diskAccessId']? delete body.properties['diskAccessId'] : false
        }
      
        this.UpdateDiskData(this.selectedRow,body,'networking')
        break;

      }
      case'encryption':{
        let body = this.diskData
        let rawValue = this.currentForm.getRawValue()
        body['properties'].encryption.type=rawValue.encryptionType
        if(rawValue.encryptionType!='EncryptionAtRestWithPlatformKey'){
          body['properties'].encryption.diskEncryptionSetId=rawValue.diskEncryption 
        }
        this.UpdateDiskData(this.selectedRow,body,'encryption')
        break;  
      }
      case'configuration':{
        let body = this.diskData
        let rawValue = this.currentForm.getRawValue()
        body['properties'].burstingEnabled=rawValue.onDemandBursting
        body['properties'].maxShares=rawValue.sharedDisk?rawValue.maxShares:1
        this.UpdateDiskData(this.selectedRow,body,'configuration')
        break;  
      }
      case'sizeAndPerformance':{
        let body = this.diskData
        let rawValue = this.currentForm.getRawValue()
        let tier=rawValue.diskSKU=='StandardSSD_LRS'?'Standard':rawValue.diskSKU.split('_')[0]
        body['sku'].name=rawValue.diskSKU
        body['sku'].tier=tier
        body['properties'].diskSizeGB=Number(rawValue.diskSize)
        if(tier!='Standard'){
          body['properties'].tier=rawValue.performance
        }
        this.UpdateDiskData(this.selectedRow,body,'sizeAndPerformance')
        break;  
      }
   
    }
  
  }

  async getDiskData(data,type,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let diskName = data[0]
    this.util.handleRequest('get','a3s_management_getDiskData',[subsId,rgName,diskName],null,null,null,true).then(res=>{
      this.diskData = res.body
      switch(type){
        case'deleteCondition':{
          this.deleteDisable=res.body.properties.diskState!='Unattached'?true:false
        }
      }
      console.log('Disk Data',this.diskData)
    })
  }
  UpdateDiskData(data,body,type){
    let subsId = data[2]
    let rgName =  data[1] 
    let diskName =  data[0] 
    this.util.info(this.getInProgress(type))
    this.util.handleRequest('post','a3s_management_updateDiskData',[subsId,rgName,diskName],body,null,null,true).then(res=>{
      console.log('disk Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      this.util.success(this.getSuccesText(type))
      type=='sizeAndPerformance'?this.getDiskData(this.selectedRow,''):false
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  async getSKUList(data,type,event?:any){
    let subsId = data[2]
    let loc = data[3]
    return this.util.handleRequest('get','a3s_management_getSKUDiskData',[subsId,loc],null,null,null,true).then(async res=>{
      this.skuList = res.body.value
      this.skuList= type=='default'?this.skuList.filter(e=>e.name==this.diskData?.sku?.name): this.skuList.filter(e=>e.name==event.id)
      let sorted=this.skuList.filter(e=>e.resourceType=='disks');
      sorted.sort((a,b)=>{
        let x = Number(a?.capabilities[0].value);
        let y = Number(b?.capabilities[0].value);
        return x-y
      })
      this.skuList=sorted;
      this.skuList.forEach(e=>{
        let num=this.diskData?.properties?.diskSizeGB
        num=this.sizeRange(num)
        e['active']=e.capabilities[0].value==num ?true:false
        // && e.tier==this.diskData?.sku?.tier
      })
      console.log('SKU List',this.skuList)
      await this.getPerformanceDropdown('default')
    })
  }
  sizeRange(num){
    if(num>0&&num<=4)  num=4
    if(num>4&&num<=8)  num=8
    if(num>8&&num<=16)  num=16
    if(num>16&&num<=32)  num=32
    if(num>32&&num<=64)  num=64
    if(num>64&&num<=128)  num=128
    if(num>128&&num<=256)  num=256
    if(num>256&&num<=512) num=512
    if(num>512&&num<=1024)  num=1024
    if(num>1024&&num<=2048)  num=2048
    if(num>2048&&num<=4096)  num=4096
    if(num>4096&&num<=8192)  num=8192
    if(num>8192&&num<=16384) num=16384
    if(num>16384&&num<=32767)  num=32767
    return num
  }
  getPerformanceDropdown(type){
    this.performance=[]
    this.skuList.map(e=>{
      if(e.active){
        this.performance.push({
          name:`${e.size} - ${e.capabilities[2].value} IOPS, ${e.capabilities[4].value} MBps (default)`,
          GiB : Number(e.capabilities[0].value),
          id:e.size, 
        })
      }else{
        this.performance.push({
          name:`${e.size} - ${e.capabilities[2].value} IOPS, ${e.capabilities[4].value} MBps`,
          GiB : Number(e.capabilities[0].value),
          id:e.size, 
        })
      }
     
      let GIB= type=='default'?Number(this.diskData?.properties?.diskSizeGB):Number(type?.capabilities[0]?.value)
      if(5000>GIB){
        this.performance=this.performance.filter(e=>5000>=e.GiB)
      }else this.performance=this.performance.filter(e=>5000<=e.GiB)
      this.performance=this.performance.filter(e=>GIB<=e.GiB)
    
    })
    
  }
   validSize(event){
    let value=event.target.value;
     this.saveDisable=true
     this.diskSizeMsg=this.diskData?.properties?.diskSizeGB>value?true:false
     if(value>0&&value<32768){
       this.inputError.sizeGB=false
     }else this.inputError.sizeGB=true
    this.skuList.forEach(async e=>{
      e.active=false
      // let temp=[4,8,16,32,64,128,256,512,1024,2048,4096,8192,16384,32767]
      let num=Number(value)
      value= this.sizeRange(num)
            // temp.reduce((prev,current) => {num>a&&num<=b?num=b:false};
      // console.log('arr',temp)
      if(value==e.capabilities[0]?.value){
      e.active=true
      this.currentForm.get('performance').setValue(e.size)
      this.diskTier=e?.size=='P50' || e?.size=='P80' ? true : false
      this.diskTier?this.currentForm.get('performance').disable():this.currentForm.get('performance').enable()
      await this.getPerformanceDropdown(e)
      }
    })
  } 

  async setsize(list,i){
    this.saveDisable=true
    this.skuList.forEach(e=>e.active=false)
    list.active=true
    let GIB=Number(list?.capabilities[0]?.value)
    this.inputError.sizeGB=this.currentForm.get('diskSize')>32767?true:false
    this.diskSizeMsg=GIB<this.diskData?.properties?.diskSizeGB?true:false
    this.currentForm.get('performance').setValue(list.size)
    this.diskTier=list?.size=='P50' || list?.size=='P80' ? true : false
    this.diskTier?this.currentForm.get('performance').disable():this.currentForm.get('performance').enable()
    await this.getPerformanceDropdown(list)
    // if(5000>GIB){
    //   this.performance=this.performance.filter(e=>5000>=e.GiB)
    // }else this.performance=this.performance.filter(e=>5000<=e.GiB)
    // this.performance=this.performance.filter(e=>GIB<=e.GiB)
    this.currentForm.get('diskSize').setValue(GIB)
  }
  async getDiskAccessList(data){
    let subsId = data[2]
    this.util.handleRequest('get','a3s_management_getDiskAccessData',[subsId],null,null,null,true).then(res=>{
      this.diskAccessList = res.body.value
      let loc=this.selectedRow[3]
      this.diskAccessList=this.diskAccessList.filter(x=>x.location==loc)

      console.log('Disk Data',this.diskAccessList)
    })
  }
  async checkDiskVMData(data){
    let split= data?.managedBy.split('/')
    let subsId = split[2]
    let rgName = split[4]
    let vmName = split[8]
    return this.util.handleRequest('get','a3s_management_checkDiskVMData',[subsId,rgName,vmName],null,null,null,true).then(res=>{
      this.vmConfig=res.body.statuses[1].displayStatus
      this.diskStateMsg=this.diskData?.properties?.diskState!='Unattached'  || this.vmConfig!='VM deallocated'?true:false
      console.log('Disk Data',this.diskAccessList)
    })
  }

  async getEncryptionList(data,type?:any){
    let subsId = data[2]
    return this.util.handleRequest('get','a3s_management_getDiskEncryptionSets',[subsId],null,null,null,true).then(res=>{
      this.encryptionList = res.body.value
      let loc=this.selectedRow[3]
      this.encryptionList=this.encryptionList.filter(x=>x.location==loc)
      if(type!=''){
        this.encryptionList=this.encryptionList.filter(x=>x.properties.encryptionType==type?.id)
      }
      this.diskEncryption=  this.encryptionList
      console.log('NIC Data',this.encryptionList)
    })
  }
  deleteDiskData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],diskName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteDiskData',[subscriptionId,resourceGroupName,diskName],null,null,null,true).then(async res=>{
      this.closeModal()
        let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
        this.data.splice(index,1)
      this.util.success(data[0] + ' deleted successfully!')
    },err=>{
      this.util.error(data[0] +' failed to deleted.')
    })
  }
}
