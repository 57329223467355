<div class="introduction">
    <p class="heading" id="0">Insights</p>
    <p class="para mb-0">Insights tab enables you to retrieve the historical metric data for a specific timeframe.   </p>
    <img src="assets\user-manual\infra-health-monitoring\Queries.svg" >
    <figcaption class="caption"> Insights - query list  </figcaption>
    <p>This page is categorized into two sections: </p>
    <ol>
        <li>Queries: predefined list of queries. </li>
        <li>Custom Query: queries that user can write to retrieve data as needed. </li>
    </ol>

          
</div>
