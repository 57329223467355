<div class="introduction">
      <p class="heading">Create New Alert Group </p>  
                         <ol class="mb-0" >
                             <li>Click <b>Alerts</b> in the left menu  </li>
                             <li>Click <b>New alert rule. </b> </li>
                             <li>Provide the needed inputs in the Alert rule side sheet. </li>
                             <li>Click <b>Create.</b> </li>
                         </ol>
                         <img style="width:50%;height:50%"s src="assets\user-manual\infra-health-monitoring\Side-sheet(1).svg" >
                         <figcaption class="caption col-md-6"> Create alert rule</figcaption>
            
</div>