<div class="body-content">
  <div class="main col-md-12" >
    <app-breadcrumbs></app-breadcrumbs>

    <div class="header-container">
      <h2 class="title">Create A3S Support Tickets</h2>
    </div>

    <form [formGroup]='supportForm'>
      <div class="upper-section">
        <div class="info mb-3">
          <span class="sql-data-head bold-600">Problem Description</span>
        </div>

        <div class="form-group row">
          <label for="issueType" class="control-label col-sm-2">Issue Type<span class="mandatory">*</span></label>
         <div class="col-sm-6">
            <ng-select [items]="issueType" bindLabel="value" bindValue="value" name="issueType"  formControlName="issueType" (change)="changeissuetype($event)" ></ng-select> 
          </div>
        </div>

        <div *ngIf="!isTechnicalIssue" class="form-group row">
          <label for="problemType" class="control-label col-sm-2">Problem Type<span class="mandatory">*</span></label>
         <div class="col-sm-6">
            <ng-select [items]="problemType" bindLabel="value" bindValue="value" name="problemType"  formControlName="problemType"></ng-select> 
          </div>
         
        </div>
        <div *ngIf="isTechnicalIssue"    class="form-group row">
          <label for="showBucket" class="control-label col-sm-2">Select Bucket<span class="mandatory">*</span></label>
         <div class="col-sm-6">
            <ng-select [items]="showBucket" bindLabel="value" bindValue="value" name="showBucket"  formControlName="showBucket"></ng-select> 
          </div>
          </div> 
          <div *ngIf="isTechnicalIssue && isTechnicalIssueSelected"     class="form-group row">
            <label for="showSubBucket" class="control-label col-sm-2">Select Sub-Bucket<span class="mandatory">*</span></label>
            <ng-container [ngSwitch]="selectedBucket">
              <div class="col-sm-6" *ngSwitchCase="'Apps and Infrastructure'" >
              
            
                 <ng-select [items]="showSubBucketForAppsInfrastructure" bindLabel="value" bindValue="value" name="showSubBucket"  formControlName="showSubBucket"></ng-select> 
              </div>
               
               <div class="col-sm-6" *ngSwitchCase="'Data & AI'" >
                
                <ng-select [items]="showSubBucketForDatAI" bindLabel="value" bindValue="value" name="showSubBucket"  formControlName="showSubBucket"></ng-select> 
               
              </div>
              <div class="col-sm-6" *ngSwitchCase="'Infrastructure Health Monitoring'" >
                
                <ng-select [items]="showSubBucketForInfrastructureHealthMonitoring" bindLabel="value" bindValue="value" name="showSubBucket"  formControlName="showSubBucket"></ng-select> 
               
              </div>
              <div class="col-sm-6" *ngSwitchCase="'Application Health Monitoring'" >
                
                <ng-select [items]="showSubBucketForApplicationHealthMonitoring" bindLabel="value" bindValue="value" name="showSubBucket"  formControlName="showSubBucket"></ng-select> 
               
              </div>
              <div class="col-sm-6" *ngSwitchCase="'Cost Monitoring and Optimization'" >
                
                <ng-select [items]="showSubBucketForCostMonitoringandOptimization" bindLabel="value" bindValue="value" name="showSubBucket"  formControlName="showSubBucket"></ng-select> 
               
              </div>
              <div class="col-sm-6" *ngSwitchCase="'Governance'" >
                
                <ng-select [items]="showSubBucketForGovernance" bindLabel="value" bindValue="value" name="showSubBucket"  formControlName="showSubBucket"></ng-select> 
               
              </div>
              <div class="col-sm-6" *ngSwitchCase="'Security'" >
                
                <ng-select [items]="showSubBucketForSecurity" bindLabel="value" bindValue="value" name="showSubBucket"  formControlName="showSubBucket"></ng-select> 
               
              </div>
              <div class="col-sm-6" *ngSwitchCase="'Compliance'" >
                
                <ng-select [items]="showSubBucketForCompliance" bindLabel="value" bindValue="value" name="showSubBucket"  formControlName="showSubBucket"></ng-select> 
               
              </div>
            </ng-container>
            </div>
      
  <div class="upper-section"></div>
       

       <div class="info mb-3">
        <div class="fixed-btm-btn" ></div>
        <span class="sql-data-head bold-600">Additional Details</span>
      </div>
      <div class="form-group row">
          <label for="problemSummary" class="control-label col-sm-2">Problem summary<span class="mandatory">*</span></label>
          <div class="col-sm-6">
            <!-- [class.border-danger]="inputError?.project||inputError?.duplicateProject" -->
            <input type="text" class="form-control" id="problemSummary" autocomplete="off" formControlName="problemSummary" name="problemSummary">
            <p class="text-danger" *ngIf="supportFormControls.problemSummary?.errors?.maxlength">The value must have a length of at most 140 .</p>   
          </div>
         </div>
        
        
         <div class="form-group row">
          <label for="issue" class="control-label col-sm-2">Please provide details about your issue <span class="mandatory">*</span></label>
          <div class="col-sm-6">
            <textarea class="form-control ht-148" id="issue" autocomplete="off" name="issue" formControlName="issueDetails"></textarea>
            <p class="text-danger" *ngIf="supportFormControls.issueDetails?.errors?.maxlength">The value must have a length of at most 20000</p>
          </div>
         </div>

         <div class="form-group row">
          <label for="severity" class="control-label col-sm-2">Severity<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg"></label>
         <div class="col-sm-6">
            <ng-select [items]="severity" bindLabel="value" bindValue="value" name="severity"  formControlName="severity" ></ng-select> 
          </div>
        </div>

        

     
       </div>
       <div class="upper-section"></div>
       

       <div class="info mb-3">
        <div class="fixed-btm-btn" ></div>
        <span class="sql-data-head bold-600">Contact Details</span>
      </div>

      <div class="form-group row">
        <label for="firstName" class="control-label col-sm-2">First name<span class="mandatory">*</span></label>
        <div class="col-sm-6">
          <!-- [class.border-danger]="inputError?.project||inputError?.duplicateProject" -->
          <input type="text" class="form-control" id="firstName" autocomplete="off" formControlName="firstName" name="firstName">
          <p class="text-danger" *ngIf="supportFormControls.firstName?.errors?.maxlength">The value must have a length of at most 65.</p>
          <!-- <p class="text-danger" *ngIf="inputError?.project">Project name cannot exceed 75 characters</p>
        <p class="text-danger" *ngIf="inputError?.duplicateProject">This project name already exists. </p> -->
        </div>
       </div>

       <div class="form-group row">
        <label for="lastName" class="control-label col-sm-2">Last name<span class="mandatory">*</span></label>
        <div class="col-sm-6">
          <!-- [class.border-danger]="inputError?.project||inputError?.duplicateProject" -->
          <input type="text" class="form-control" id="lastName" autocomplete="off" formControlName="lastName" name="lastName">
          <p class="text-danger" *ngIf="supportFormControls.lastName?.errors?.maxlength">The value must have a length of at most 65.</p>

          <!-- <p class="text-danger" *ngIf="inputError?.project">Project name cannot exceed 75 characters</p>
        <p class="text-danger" *ngIf="inputError?.duplicateProject">This project name already exists. </p> -->
        </div>
       </div>

       <div class="form-group row">
        <label for="email" class="control-label col-sm-2">Email<span class="mandatory">*</span></label>
        <div class="col-sm-6">
          <!-- [class.border-danger]="inputError?.project||inputError?.duplicateProject" -->
          <input type="text" class="form-control" id="email" autocomplete="off" formControlName="email" name="email">
          <p class="text-danger" *ngIf="supportFormControls.email?.errors?.maxlength">The value must have a length of at most 200.</p>
          <p class="text-danger" *ngIf="supportFormControls.email?.errors?.email">Please enter a valid email address </p>


          <!-- <p class="text-danger" *ngIf="inputError?.project">Project name cannot exceed 75 characters</p>
        <p class="text-danger" *ngIf="inputError?.duplicateProject">This project name already exists. </p> -->
        </div>
       </div>

       <div class="form-group row">
        <label for="phone" class="control-label col-sm-2">Phone<span class="mandatory">*</span></label>
        <div class="col-sm-6">
          <!-- [class.border-danger]="inputError?.project||inputError?.duplicateProject" -->
          <input type="text" class="form-control" id="phone" autocomplete="off" formControlName="phoneNumber" name="phone">
         
          <p class="text-danger" *ngIf="supportFormControls.phoneNumber?.errors?.pattern">
          <p class="text-danger" *ngIf="supportFormControls.phoneNumber?.errors?.minlength">Please enter a valid phone number. It must be between 6 to 30.</p>
                 
          <!-- <p class="text-danger" *ngIf="inputError?.project">Project name cannot exceed 75 characters</p>
        <p class="text-danger" *ngIf="inputError?.duplicateProject">This project name already exists. </p> -->
        </div>
       </div>

       <div class="form-group row">
        <label for="country" class="control-label col-sm-2">Country/region <span class="mandatory">*</span></label>
        <div class="col-sm-6">
          <!-- [class.border-danger]="inputError?.project||inputError?.duplicateProject" -->
          
          <ng-select [items]="countryList" bindLabel="name" bindValue="name"  name="country"  formControlName="country"></ng-select>
          
 
          
          
          <!-- <p class="text-danger" *ngIf="inputError?.project">Project name cannot exceed 75 characters</p>
        <p class="text-danger" *ngIf="inputError?.duplicateProject">This project name already exists. </p> -->
        </div>
       </div>
       </form>
<div class="fixed-btm-btn" >
      <button class="btn btn-btn-primary" (click)="submit()" [class.disabled]="supportForm?.invalid">Submit Ticket</button>
    </div>
</div>
</div>



<!-- <form [formGroup]='supportForm'>
    <fieldset>
        <p> Problem description</p>
        
        <label for="Problem Summary">Problem Summary<span class="mandatory">*</span></label>
<input type="text" id="problem summary*" name="problem summary*" formControlName='summary'> 
  

  <label for="Please provide details about your issue">Please provide details about your issue <span class="mandatory">*</span></label>
  <textarea name="message" rows="5" cols="53"> </textarea>
  
  <label for="supportinterest">Support Interest<span class="mandatory">*</span>
  <img src="https://img.icons8.com/material-outlined/24/000000/info.png"/></label>
  
  <select class="form-select" aria-label="Default select example">
    <select class="form-select" aria-label="Default select example"></select>
    <option selected> </option>

    <option value="1">Lift and shift strategy plan</option>
    <option value="2">New cloud deployment</option>
    <option value="3">Support existing cloud deployment</option>
    <option value="4">End to end cloud migration project</option>
    <option value="5">Data Analytics</option>
    <option value="6">ML/AI</option>
    <option value="7">Other</option>
  </select>

  <label for="levelofexpertise">Level of expertise<span class="mandatory">*</span>
    <img src="https://img.icons8.com/material-outlined/24/000000/info.png"/></label>
    
    
    <select class="form-select" aria-label="Default select example">
      <option selected> </option>
      <option value="1">Architect</option>
      <option value="2">Senior Engineer</option>
      <option value="3">Intermediate Engineer</option>
      <option value="3">Support Engineer</option>
    </select>

  
  <label for="start date">Start date<span class="mandatory">*</span></label>
  <input type="date" name="begin" placeholder="MM-DD-YYYY" value=""
  min="01-01-1997" max="01-01-1997"id="start date" name="start date"><br>
  <br>

  <label for="end date">End date<span class="mandatory">*</span></label>
  <input type="date" name="begin" placeholder="MM-DD-YYYY" value=""
  min="01-01-1997" max="01-01-1997" id="end date" name="end date"><br>


  <label for="Hours of support required">Hours of support required<span class="mandatory">*</span></label>
  <input type="text" id="Hours of support required" name="Hours of support required">
  <br>
  <hr>

   <p> Contact Details</p>
  <label for="First Name">First Name<span class="mandatory">*</span></label>
  <input type="text" id="First Name" name="First Name">
  <br>

  <label for="Last Name">Last Name<span class="mandatory">*</span></label>
  <input type="text" id="Severity" name="Last Name">
  <br>


  <label for="Email id">Email id<span class="mandatory">*</span></label>
  <input type="text" id="Email id" name="Email id">
  

  <label for="Phone">Phone<span class="mandatory">*</span></label>
  <input type="number" id="Phone" name="Phone">

  <label for="Company">Company/organization name<span class="mandatory">*</span></label>
  <select class="form-select" aria-label="Default select example">
    <option selected>Open this select menu</option>
    <option *ngFor="let country of countries" value="country">{{country.name}}</option>
    
  </select>
  
  <hr>
  <button  (click)='getForm()'>
    Submit Ticket
  </button> -->

