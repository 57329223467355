<div class="custom-fixed-tables">
    <table class="table">
        <thead>
          <tr>
            <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectonData',0)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectonData',1)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectonData',2)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectonData',3)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectonData',4)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Peer1</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectonData',5)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Peer2</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectonData',6)"></div></th>
            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectonData',7)"></div></th>
            <th style="border-left: none;" class="wid-75"></th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data">
              <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(connectionDetailsTemplate,row,'connectionPopup')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive><div class="status-tag" [ngClass]="getStatusClass(row[4])">{{ row[4] | titlecase | displayTxt}}</div></td>
              <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[6] | displayTxt}}</td>
              <td isEllipsisActive>{{ getTags(row[7]) }}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('sharedKey',row)">
                      <p class="delete-popup-text">Shared Key</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                      <p class="delete-popup-text">Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('natRules',row)">
                      <p class="delete-popup-text">NAT Rules</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteConnectionData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>

  <ng-container *ngIf="showSidesheet">
    <!-- shared Key -->
    <ng-container *ngIf="currentTemplate == 'sharedKey'">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">Shared Key<br> <span class="blur-text">Connection | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
         
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Shared key (PSK)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A mixture of letters and numbers, used to establish encryption for the connection. 
                        The same shared key must be used in both the virtual network and local network 
                        gateways. If your gateway device doesn't provide one, you can make one up here 
                        and provide it to your device." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.sharedKeyEmpty|| inputError?.sharedKeyMaxLen ||inputError?.sharedKeyASCII" id="sharedKey" name="sharedKey" formControlName="sharedKey" (keyup)="sharedKeyValidation($event)"/>
                      <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.sharedKeyMaxLen">A shared key must contain between 1 to 128 characters. </p>
                      <p class="text-danger" *ngIf="inputError?.sharedKeyASCII">Invalid shared key configured. The shared key should be a combination of printable ASCII characters and should be between 1 to 128 in length. </p>
                    </div>
                </form>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('sharedKey')" (click)="saveConnectionData('sharedKey')">Save</button>
            </div>
        </div>
    </ng-container>
    <!-- NAT rules -->
    <ng-container *ngIf="currentTemplate == 'natRules'">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">NAT Rules<br> <span class="blur-text">Connection | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
         
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Ingress NAT Rules</label>
                        <div class="margin-multi-select">
                          <ng-select class="full-width" [items]="ingressList" [multiple]="true" [selectableGroup]="true" (change)="saveDisable=true" placeholder="0 selected" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="ingressNAT" [clearable]="false" id="ingressNAT" formControlName="ingressNAT">
                            <ng-template ng-multi-label-tmp let-items="items">
                              <span>{{items.length}} selected</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="flex-custom">
                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> {{item.name}}
                                    <!-- <label class="value" for="item-{{index}}" [ngModel]="item$.selected" [checked]="item$.selected">{{item.name}}</label> -->
                                  </div>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div> 
                      <div class="comm-mb">
                        <label class="flex-custom no-wrap">Egress NAT Rules</label>
                        <div class="margin-multi-select">
                          <ng-select class="full-width" [items]="egressList" [multiple]="true" [selectableGroup]="true" (change)="saveDisable=true" placeholder="0 selected" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="egressNAT" [clearable]="false" id="egressNAT" formControlName="egressNAT">
                            <ng-template ng-multi-label-tmp let-items="items">
                              <span>{{items.length}} selected</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="flex-custom">
                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> {{item.name}}
                                    <!-- <label class="value" for="item-{{index}}" [ngModel]="item$.selected" [checked]="item$.selected">{{item.name}}</label> -->
                                  </div>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div> 
                </form>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable" (click)="saveConnectionData('natRules')">Save</button>
            </div>
        </div>
    </ng-container>
     <!-- Scale Out -->
     <ng-container *ngIf="currentTemplate == 'configuration'">
      <div class="sidebar-right single width-652" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Configuration<br> <span class="blur-text">Connection | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
           <form [formGroup]="currentForm">
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Use Azure Private IP Address<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Checking this option allows you to use Azure private IPs to establish an IPsec VPN connection. 
                    Support for private IPs must be set on the VPN gateway for this option to work. 
                    It is only supported on AZ SKUs" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="azureIP"  class="form-check-inline" id='ipDisable' (change)="setRadioBtn('no','configuration','azureIP')" [checked]="currentForm.get('azureIP')?.value ==false">
                      <label class="value" for="ipDisable">Disable</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="azureIP" class="form-check-inline" id='ipEnable' (change)="setRadioBtn('yes','configuration','azureIP')" [checked]="currentForm.get('azureIP')?.value == true">
                      <label class="value" for="ipEnable">Enable</label>
                  </div>
                </div>
              </div>   
              <div class="comm-mb">
                <label class="flex-custom no-wrap">BGP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Border gateway protocol" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="bgp"  class="form-check-inline" id='bgpDisable' (change)="setRadioBtn('no','configuration','bgp')" [checked]="currentForm.get('bgp')?.value ==false">
                      <label class="value" for="bgpDisable">Disable</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="bgp" class="form-check-inline" id='bgpEnable' (change)="setRadioBtn('yes','configuration','bgp')" [checked]="currentForm.get('bgp')?.value == true">
                      <label class="value" for="bgpEnable">Enable</label>
                  </div>
                </div>
              </div>  
              <div class="comm-mb" *ngIf="currentForm.get('bgp')?.value ==true && connectionData?.properties?.connectionType!='Vnet2Vnet'">
                <label class="flex-custom no-wrap">Enable Custom BGP Addresses</label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="customBGP"  class="form-check-inline" id='customBGPDisable' (change)="setRadioBtn('no','configuration','customBGP')" [checked]="currentForm.get('customBGP')?.value ==false">
                      <label class="value" for="customBGPDisable">Disable</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="customBGP" class="form-check-inline" id='customBGPEnable' (change)="setRadioBtn('yes','configuration','customBGP')" [checked]="currentForm.get('customBGP')?.value == true">
                      <label class="value" for="customBGPEnable">Enable</label>
                  </div>
                </div>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap">IPsec / IKE policy<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IPsec / IKE policy will be negotiated while dialing the connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="policy"  class="form-check-inline" id='default' (change)="setRadioBtn('no','configuration','policy')" [checked]="currentForm.get('policy')?.value ==false">
                      <label class="value" for="default">Default</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="policy" class="form-check-inline" id='custom' (change)="setRadioBtn('yes','configuration','policy')" [checked]="currentForm.get('policy')?.value == true">
                      <label class="value" for="custom">Custom</label>
                  </div>
                </div>
              </div> 
              <ng-container *ngIf="currentForm.get('policy')?.value ==true">
                <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600">IKE Phase 1<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IKE phase 1 policy will be used for IKE Main Mode (MM) SA creation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div> 
                <div class="mb-3">
                    <div class="flex-custom">
                      <div class="comm-mb mr-3" style="width:190px;">
                        <label class="flex-custom no-wrap ">Encryption<span class="mandatory">*</span></label>
                        <ng-select [items]="encryption" bindLabel="name" bindValue="id" name="encryption" (change)="saveDisable=true" [clearable]="false" id="encryption" formControlName="encryption"></ng-select>
                      </div>
                      <div class="comm-mb mr-3" style="width:190px;">
                        <label class="flex-custom no-wrap ">Integrity/PRF<span class="mandatory">*</span></label>
                        <ng-select [items]="integrity" bindLabel="name" bindValue="id" name="integrity" (change)="saveDisable=true" [clearable]="false" id="integrity" formControlName="integrity"></ng-select>
                      </div>
                      <div class="comm-mb mr-3" style="width:190px;">
                        <label class="flex-custom no-wrap ">DH Group<span class="mandatory">*</span></label>
                        <ng-select [items]="DHGroup" bindLabel="name" bindValue="id" name="DHGroup" (change)="saveDisable=true" [clearable]="false" id="DHGroup" formControlName="DHGroup"></ng-select>
                      </div>
                    </div>
                </div>
                <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600">IKE Phase 2(IPsec)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="KE phase 2 policy will be used for IPsec Quick Mode (QM) SA creation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div> 
                <div class="mb-3">
                    <div class="flex-custom">
                      <div class="comm-mb mr-3" style="width:190px;">
                        <label class="flex-custom no-wrap ">IPsec Encryption<span class="mandatory">*</span></label>
                        <ng-select [items]="IPsecEncryption"  bindLabel="name" bindValue="id" name="IPsecEncryption" (change)="saveDisable=true" [clearable]="false" id="IPsecEncryption" formControlName="IPsecEncryption"></ng-select>
                      </div>
                      <div class="comm-mb mr-3" style="width:190px;">
                        <label class="flex-custom no-wrap ">IPsec Integrity<span class="mandatory">*</span></label>
                        <ng-select [items]="IPsecIntegrity"  bindLabel="name" bindValue="id" name="IPsecIntegrity" (change)="saveDisable=true" [clearable]="false" id="IPsecIntegrity" formControlName="IPsecIntegrity"></ng-select>
                      </div>
                      <div class="comm-mb mr-3" style="width:190px;">
                        <label class="flex-custom no-wrap ">PFS Group<span class="mandatory">*</span></label>
                        <ng-select [items]="PFSGroup" bindLabel="name" bindValue="id" name="PFSGroup" [clearable]="false" (change)="saveDisable=true" id="PFSGroup" formControlName="PFSGroup"></ng-select>
                      </div>
                    </div>
                 </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">IPsec SA lifetime in KiloBytes<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The IPsec(QM) SA will expire after this much kilobytes 
                        of data is transported over the connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.IPsecKBNameEmpty|| inputError?.IPsecKBInvalidRange ||inputError?.IPsecKBInvalidNumber" id="IPsecKB" name="IPsecKB" formControlName="IPsecKB" (keyup)="validTimeout($event,'IPsecKB')"/>
                    <p class="text-danger" *ngIf="inputError?.IPsecKBNameEmpty">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.IPsecKBInvalidNumber">The value must be a valid number.</p>
                    <p class="text-danger" *ngIf="inputError?.IPsecKBInvalidRange">SA life time in kilobytes must be 0 or between 1024 and 2147483647.</p>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">IPsec SA lifetime in seconds<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The IPsec(QM) SA will expire after this time." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.IPsecSecNameEmpty|| inputError?.IPsecSecInvalidRange ||inputError?.IPsecSecInvalidNumber" id="IPsecSec" name="IPsecSec" formControlName="IPsecSec" (keyup)="validTimeout($event,'IPsecSec')"/>
                    <p class="text-danger" *ngIf="inputError?.IPsecSecNameEmpty">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.IPsecSecInvalidNumber">The value must be a valid number.</p>
                    <p class="text-danger" *ngIf="inputError?.IPsecSecInvalidRange">SA life time in seconds must be between 300 and 172799.</p>
                </div>
              </ng-container>
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Use policy based traffic selector<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" [matTooltip]="toolTip()" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="trafficSelector"  class="form-check-inline" id='trafficSelectorDisable' (change)="setRadioBtn('no','configuration','trafficSelector')" [checked]="currentForm.get('trafficSelector')?.value ==false">
                      <label class="value" for="trafficSelectorDisable">Disable</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="trafficSelector" class="form-check-inline" id='trafficSelectorEnable' (change)="setRadioBtn('yes','configuration','trafficSelector')" [checked]="currentForm.get('trafficSelector')?.value == true">
                      <label class="value" for="trafficSelectorEnable">Enable</label>
                  </div>
                </div>
              </div>       
              <div class="comm-mb">
                <label class="flex-custom no-wrap">DPD timeout in seconds<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Dead Peer Detection Timeout of the connection in seconds" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.timeOutNameEmpty|| inputError?.timeOutInvalidRange ||inputError?.timeOutInvalidNumber" id="DPDTimeout" name="DPDTimeout" formControlName="DPDTimeout" (keyup)="validTimeout($event,'timeOut')"/>
                <p class="text-danger" *ngIf="inputError?.timeOutNameEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.timeOutInvalidNumber">The value must be a valid number.</p>
                <p class="text-danger" *ngIf="inputError?.timeOutInvalidRange">DPD timeout in seconds must be between 9 and 3600.</p>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Connection Mode<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Connection mode is used to decide which gateway can initiate the connection.
                    When this value is set to:
                    Default: both Azure and on-premise VPN gateway can initiate the connection.
                    ResponderOnly: Azure VPN gateway will never initiate the connection. On-premise
                     VPN gateway must initiate the connection.
                    InitiatorOnly: Azure VPN gateway will initiate the connection and reject any 
                    connection attempts from on-premise VPN gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="connectionMode"  class="form-check-inline" id='Default' (change)="setRadioBtn('Default','configuration','connectionMode')" [checked]="currentForm.get('connectionMode')?.value =='Default'">
                      <label class="value" for="Default">Default</label>
                  </div>
                </div>
                <div class="flex-custom">
                  <div class="checkbox-cs">
                      <input type="radio" name="connectionMode" class="form-check-inline" id='InitiatorOnly' (change)="setRadioBtn('InitiatorOnly','configuration','connectionMode')" [checked]="currentForm.get('connectionMode')?.value == 'InitiatorOnly'">
                      <label class="value" for="InitiatorOnly">InitiatorOnly</label>
                  </div>
                </div>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="connectionMode" class="form-check-inline" id='ResponderOnly' (change)="setRadioBtn('ResponderOnly','configuration','connectionMode')" [checked]="currentForm.get('connectionMode')?.value == 'ResponderOnly'">
                        <label class="value" for="ResponderOnly">ResponderOnly</label>
                    </div>
                  </div>
              </div>  
              <div class="comm-mb">
                <label class="flex-custom no-wrap">IKE Protocol<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IKE protocol used by this connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <label class="flex-custom no-wrap bold-600">{{connectionData?.properties?.connectionProtocol}}</label>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Ingress NAT Rules</label>
                <div class="margin-multi-select">
                  <ng-select class="full-width" [items]="ingressList" [multiple]="true" [selectableGroup]="true" (change)="saveDisable=true" placeholder="0 selected" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="ingressNAT" [clearable]="false" id="ingressNAT" formControlName="ingressNAT">
                    <ng-template ng-multi-label-tmp let-items="items">
                      <span>{{items.length}} selected</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="flex-custom">
                            <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> {{item.name}}
                            <!-- <label class="value" for="item-{{index}}" [ngModel]="item$.selected" [checked]="item$.selected">{{item.name}}</label> -->
                          </div>
                        </ng-template>
                  </ng-select>
                </div>
              </div> 
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Egress NAT Rules</label>
                <div class="margin-multi-select">
                  <ng-select class="full-width" [items]="egressList" [multiple]="true" [selectableGroup]="true" (change)="saveDisable=true" placeholder="0 selected" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="egressNAT" [clearable]="false" id="egressNAT" formControlName="egressNAT">
                    <ng-template ng-multi-label-tmp let-items="items">
                      <span>{{items.length}} selected</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="flex-custom">
                            <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> {{item.name}}
                            <!-- <label class="value" for="item-{{index}}" [ngModel]="item$.selected" [checked]="item$.selected">{{item.name}}</label> -->
                          </div>
                        </ng-template>
                  </ng-select>
                </div>
              </div> 
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('config') || !saveDisable "(click)="saveConnectionData('config')">Save</button>
         </div>
         <!-- || invalidForm('scaling') -->
      </div>
    </ng-container>

</ng-container>



  <ng-template #connectionDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">Connection</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" (click)="openSidesheet('sharedKey',selectedRow)">Shared Key</div>
            <div class="tab-item" (click)="openSidesheet('configuration',selectedRow)">Configuration</div>
            <div class="tab-item" (click)="openSidesheet('natRules',selectedRow)">NAT Rules</div>
            <div class="tab-item" (click)="action(selectedRow,'deleteConnectionData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedRow[i]:getTags(selectedRow[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedRow[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>