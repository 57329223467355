import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-simple-template-modal',
  templateUrl: './simple-template-modal.component.html',
  styleUrls: ['./simple-template-modal.component.scss']
})
export class SimpleTemplateModalComponent implements OnInit {
  @Input() type;
  
  @ViewChild('accessDenied') accessDenied : TemplateRef<any>;
  @ViewChild('underConstruction') underConstruction : TemplateRef<any>;
  @ViewChild('appHealthAccessDenied') appHealthAccessDenied : TemplateRef<any>;

  ngbModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
    windowClass: null,
  }; 
  constructor(private modal: NgbModal,private util: UtilService) { }

  ngOnInit() {
   
  }

  ngAfterViewInit(){
    switch (this.type) {
      case 'accessDenied': {
        this.openModal(this.accessDenied);
        break;
      }
      case 'underConstruction':{
        this.openModal(this.underConstruction);
        break;
      }
      case 'appHealthAccessDenied':{
        this.openModal(this.appHealthAccessDenied);
        break;
      }
    }
  }
  
  // openModal(template: TemplateRef<any>) {
  //   this.modal.openModal(template);
  // }

  // closeModal() {
  //   this.modal.closeModal();
  // }

  openModal(template: TemplateRef<any>,className?:any) {
    this.ngbModalOptions.windowClass = className
    this.modal.open(template,this.ngbModalOptions);
  }

  closeModal() {
    this.modal.dismissAll();
  }

  routeTo(){
    this.util.route(['/a3s/a3sSupport'])
    this.closeModal()
  }

}
