<div class="custom-fixed-tables">
    <table class="table">
      <thead>
        <tr>
          <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('bastionData',0)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('bastionData',1)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('bastionData',2)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('bastionData',3)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Virtual Network</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('bastionData',4)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Public IP Address</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('bastionData',5)"></div></th>
          <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('bastionData',6)"></div></th>
          <th style="border-left: none;" class="wid-75"></th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let row of data" >
            <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(bastionDetailsTemplate,row,'bastionPopup')">{{ row[0] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
            <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
            <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
            <td isEllipsisActive>{{ row[4] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
            <td isEllipsisActive>{{ getTags(row[6]) }}</td>
            <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                      <p class="delete-popup-text">Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteBastionData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
        </tr>
        <div class="col-md-12 mt-3" *ngIf="!data?.length">
          <p class="content-14 blur-text">No data Found</p>
        </div>
      </tbody>
  </table>
  </div>

  <ng-container *ngIf="showSidesheet">
     <!-- configuration -->
     <ng-container *ngIf="currentTemplate == 'configuration'">
      <div class="sidebar-right single add-alert" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Configuration<br> <span class="blur-text">Bastions | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
           <form [formGroup]="currentForm">
            <div class="mb-3">
                <label class="flex-custom no-wrap">Tier<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure Bastion supports two SKUs: Basic and Standard." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="tierList" bindLabel="name" bindValue="name" (change)="validTier($event)" name="tier" [clearable]="false" id="tier" formControlName="tier"></ng-select>
                <p class="text-danger" *ngIf="inputError?.invalidTier">Downgrading Bastion is not permitted</p>
            </div>
            <div class="mb-3" [class.disabled-icon]="currentForm.get('tier').value=='Basic'">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="copyPaste" class="form-check-inline" id='copyPaste' value="copyPaste" formControlName="copyPaste" (change)="saveDisable=true" >
                  <label class="value" for="copyPaste" (change)="saveDisable=true" >Copy and paste<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Checking this box will enable copy and paste for all 
                    sessions on this Azure Bastion resource." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
            </div>
            <div class="mb-3" [class.disabled-icon]="currentForm.get('tier').value=='Basic'">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="ipBasedConn" class="form-check-inline" id='ipBasedConn' value="ipBasedConn" formControlName="ipBasedConn" (change)="saveDisable=true" >
                  <label class="value" for="ipBasedConn" (change)="saveDisable=true" >IP-based connection</label>
                </div>
            </div>
            <div class="mb-3">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="kerberos" class="form-check-inline" id='kerberos' value="kerberos" formControlName="kerberos" (change)="saveDisable=true" >
                  <label class="value" for="kerberos" (change)="saveDisable=true" >Kerberos authentication (Preview)</label>
                </div>
            </div>
            <div class="mb-3" [class.disabled-icon]="currentForm.get('tier').value=='Basic'">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="native" class="form-check-inline" id='native' value="native" formControlName="native" (change)="saveDisable=true" >
                  <label class="value" for="native" (change)="saveDisable=true" >Native client support</label>
                </div>
            </div>
            <!-- [class.disabled-input]="currentForm.get('tier').value=='Basic'" -->
            <div class="comm-mb">
                <label class="flex-custom no-wrap">Bastion instances<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Bastion can support 2-50 VM instances." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.IPsecKBNameEmpty|| inputError?.IPsecKBInvalidRange ||inputError?.IPsecKBInvalidNumber" id="bastionInstance" name="bastionInstance" formControlName="bastionInstance" (keyup)="validInstance($event,'IPsecKB')"/>
                <p class="text-danger" *ngIf="inputError?.IPsecKBNameEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.IPsecKBInvalidNumber">The value must be a valid number.</p>
                <p class="text-danger" *ngIf="inputError?.IPsecKBInvalidRange">Value must be between 2 and 50.</p>
            </div>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('config') || !saveDisable "(click)="saveBastionData('config')">Apply</button>
         </div>
      </div>
    </ng-container>

</ng-container>

  <ng-template #bastionDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">Azure Bastion</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" (click)="openSidesheet('configuration',selectedRow)">Configuration</div>
            <div class="tab-item" (click)="action(selectedRow,'deleteBastionData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedRow[i]:getTags(selectedRow[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedRow[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>