<div class="introduction">
    <p class="heading" id="0">Design Tool Components </p>
    <p>This interface has the following parts: </p>
      <ul>
          <li><b>Icon palette –</b> contains all Azure resource icons that can be dragged and dropped into the canvas for creating an architecture diagram. </li>
          <li><b>Tool bar –</b> icons from left to right:</li>
            <ul style="list-style-type: circle;">
              <li>Hide icon palette <img src="assets\user-manual\apps-&-infra\2.svg" >  – hides left icon inventory. </li>
              <li>Undo and redo <img src="assets\user-manual\apps-&-infra\1.svg"  >   <img src="assets\user-manual\apps-&-infra\14.svg" > 
                – to move a step backward or forward respectively. </li>
              <li>Red cross <img src="assets\user-manual\apps-&-infra\13.svg" > – to remove resource icon from the diagram.  </li>
              <li>Cut <img src="assets\user-manual\apps-&-infra\12.svg" > – to move a resource icon from the diagram. </li>
              <li>Copy <img src="assets\user-manual\apps-&-infra\11.svg" > – to copy a resource icon.  </li>
              <li>Paste <img src="assets\user-manual\apps-&-infra\10.svg" > – to paste a resource icon.  </li>
              <li>Edit <img src="assets\user-manual\apps-&-infra\9.svg" > – to edit the side sheet of the selected resource.  </li>
              <li>Text box <img src="assets\user-manual\apps-&-infra\8.svg" > – to insert text box into the canvas.  </li>
              <li>Chat <img src="assets\user-manual\apps-&-infra\7.svg" > – to initiate chat with A3S team.  </li>
              <li>Save <img src="assets\user-manual\apps-&-infra\6.svg" > – to save the architecture diagram. </li>
              <li>Version history <img src="assets\user-manual\apps-&-infra\5.svg "> – to view version history of the diagram. </li>
              <li>Download & upload <img src="assets\user-manual\apps-&-infra\4.svg"  >   <img src="assets/Infra/blueprint/palette/upload.svg"> – to download the architecture diagram or upload the architecture diagram or other documents as needed.  </li>
              <li>Print <img src="assets\user-manual\apps-&-infra\3.svg" > – to print the architecture diagram. </li>
            </ul>
            <img class="image" src="assets\user-manual\design-tool.png">
          <li><b>Review/Approve/Deploy:</b> this button shows the state of the diagram in a project execution flow .</li>
              <ul *ngFor= "let project of projectStatus">
                <li class="redirect" (click)="redirect(project?.activeSidebar)" >{{project.value}}</li>
              </ul>
          <li><b>Canvas:</b> design area in which icons can be dropped. Create architecture diagram here. </li>
          <li><b>Side sheet –</b> a collection of inputs respective to the resources. These input values help in creation of an architecture that is ready for deployment on Azure. </li>
      </ul>
         
</div>
