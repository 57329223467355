import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlowService } from 'src/app/services/flow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginMsalService } from 'src/app/services/login-msal.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilService } from 'src/app/services/util.service';
import { textChangeRangeIsUnchanged } from 'typescript';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {
  
  public data = "sample";
  Email:string;
  UserEmail : string;
  UserName:string;
  Organization:string;
  loader:boolean = false;
  requestData= new FormGroup({
    subject: new FormControl(''),
    message: new FormControl(''),
  });
  records: any;
  sendButtonEnable:boolean = false;
  toggleColor: ThemePalette = 'accent';
  toggleDisabled = true;
  globalAdmin: string;
  isAdmin = false;
  profileImg : any;
  loginUser: any;
  usernameInitials: string;
  userRoleList:any=[];
  limitShow:any=4;
  dummyList= [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  
  //util: any;
  
  // @ViewChild('deleteAccountPopup') deleteAccountPopup: TemplateRef<any>;

  constructor(private util: UtilService, private flowsrevice:FlowService,private router:Router, private modalService: NgbModal, 
              private loginmsal: LoginMsalService, private _snackBar: MatSnackBar, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loader = true
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    this.UserName = get_Email.userName
    this.globalAdmin = get_Email.GlobalAdmin
    this.Email = localStorage.getItem('id')
    this.UserEmail = localStorage.getItem('email')
    var temp = this.Email.split("@");
    this.Organization = temp[1];

    this.loginUser = this.get_user_details();
    this.usernameInitials = this.getInitials(this.loginUser);

    if(this.UserEmail == this.globalAdmin)
      this.isAdmin = true

    this.profileImg = this.util.getCache('userImage');

    this.getUserRoleList();
    //console.log(this.profileImg);

    var payload ={
      "Operation":"GetSavedProgress",
    }
    this.flowsrevice.getDataFromDB(payload).subscribe(result=>{
      this.loader = false
      console.log(result)
      this.records = result.recordset
      console.log(this.records)
    })
  }

  getUserRoleList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){}
    }
    this.util.handleRequest('get','a3s_accounts_getUserRoleList',[userObjectId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      
      this.userRoleList=res;
    });
  }

  get_user_details() {
    let user_details;
    for (let i = 0; sessionStorage.key(i); i++) {
        if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
            user_details = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['name']
        }
    }
    return user_details;
}

getInitials(name) {
  var parts = name.split(' ')
  var initials = ''
  for (var i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0]
    }
  }
  if(initials.length > 2)
      initials = initials.charAt(0) + initials.charAt(initials.length-1);
  return initials.toUpperCase()
}

  openModal(template, showError?: any) {
    this.modalService.open(template);
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  logoutLoader = false;
  logout() {
      this.logoutLoader = true;
      this.loginmsal.logout();
  }

  openSnackBar(message: string, action: string) {
    
    let sb = this._snackBar.open(message, action, {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    sb.onAction().subscribe(() => {
      alert('closed');
    });
  }

  showSuccess() {
    this.toastr.success('Changes Saved Succesfully', '',    
    {timeOut: 5000,toastClass:'custom-toast-1' ,positionClass: 'toast-top-center',
      easeTime:300, closeButton: true});
  }

  enableButton(){
    var sub = this.requestData.value.subject;
    var msg = this.requestData.value.message; 

    if(sub.length>0 && msg.length>0)
      this.sendButtonEnable = true;
    else
      this.sendButtonEnable = false;
  }

  sendRequestData(){
    //console.log(this.requestData.value.subject + "   " + this.requestData.value.message);
    // this.http.post("http://localhost:3000/api/deleteAccRequest",this.data)
    // .subscribe((result)=>{
    //   console.warn;
    // });
    var data = {
      subject : this.requestData.value.subject,
      message : this.requestData.value.message,
      accountEmail : this.UserEmail,
    };
    console.log(data);
    this.closeModal();
    this.util.handleRequest('post','a3s_accounts_deleteAccRequest',[],data,null,null,true).then(res=>{
      console.log('data sent successfully ' + res);
      //if(!res) console.log("no res");
      if(res.sentCheck)
      {
        this.toastr.success('Request email sent successfully', '',    
        {timeOut: 5000, positionClass: 'toast-top-center',
          easeTime:300, closeButton: true});        
      }
      else{
        this.toastr.error("Email can't be sent", '',    
        {timeOut: 5000, positionClass: 'toast-top-center',
          easeTime:300, closeButton: true});
        
      }
      
    });
  }


  //Connect to teams
  openWebHook()
  {
    // window.open(
    //   'https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fchat%2F0%2F0%3Fusers%3D28%3A5c9ab5cb-2ac8-4042-b7b7-aacf913e03c9&type=chat&deeplinkId=39772a9a-6cd7-4eea-a649-ad3098d3f9ef&directDl=true&msLaunch=true&enableMobilePage=true&suppressPro',
    //   '_blank' // <- This is what makes it open in a new window.
    // );

    window.open(
      'https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fchat%2F0%2F0%3Fusers%3D28%3A7a637309-33d4-4d41-83cd-d69f4dc4d465&type=chat&deeplinkId=4200ee96-58e1-4206-b461-29b79ad188ca&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true',
      '_blank' // <- This is what makes it open in a new window.
    )
  }

}
