<div class="header-container">
    <h2 class="title">Create Project</h2>
    <span class="content-14 blur-text">Architecture Blueprint</span>
</div>

<div class="form-part">
    <form action="#" class="">
        <div class="upper-section" [formGroup]="formProject">

            <!-- <div class="info mb-3">
            <span class="sql-data-head bold-600">Project details</span>
            </div>
         -->
            <div class="form-group row">
                <label for="projectName" class="control-label col-sm-2">Project Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt=""  matTooltip="Project name must be unique and cannot exceed 75 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                <div class="col-sm-6"  style="padding:0px;">
                <input type="text" class="form-control"  id="fname" autocomplete="off" formControlName="projectName" placeholder="Enter project name" tabindex="0" [customAutofocus]="true"
                    name="projectName" id="projectName" required [class.border-danger]="inputError?.projectName || inputError?.specialChar ||inputError?.projectNameExist || inputError?.whiteSpace" (keyup)="limitText($event,75)">
                <p class="text-danger mb-0" *ngIf="inputError?.projectName">Project name cannot exceed 75 characters</p>
                <p class="text-danger mb-0" *ngIf="inputError?.specialChar">Name cannot contain special character in between</p>
                <p class="text-danger mb-0" *ngIf="inputError?.projectNameExist">This project name already exists.</p>
                <p class="text-danger mb-0" *ngIf="inputError?.whiteSpace">Whitespace is not allowed at the start and end of the project name.</p>
                
                </div>
            
            </div>
            
            <div class="form-group row">
                <label for="department" class="control-label col-sm-2">Department<span class="mandatory">*</span></label>
                <div class="col-sm-6" style="padding:0px;">
                <ng-select [items]="departmentList"  bindLabel="name" bindValue="name" notFoundText='No department found'
                formControlName="departmentName" name="department" id="department" placeholder="Choose department" [clearable]="false" required></ng-select>
                </div>
            </div>

            <div class="form-group row">
                <label for="subscription" class="control-label col-sm-2">Subscription<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the subscription where deployment will take place." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                <div class="col-sm-6" style="padding:0px;">
                <ng-select [items]="subscriptions"  bindLabel="subscriptionName" bindValue="subscriptionId" notFoundText='No subscription found'
                formControlName="subscription" name="subscription" id="subscription" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                </div>
            </div>

            <!-- <div class="form-group row">
                <label for="resourceGroup" class="control-label col-sm-2">Resource group<span class="mandatory">* <img src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                <div class="col-sm-6">
                <ng-select [items]="resourceGroups"  bindLabel="name" bindValue="name" [(ngModel)]="model.resourceGroup" notFoundText='No resource group in this subscription. Please choose another subscription' 
                    name="resourceGroup" id="resourceGroup" placeholder="Choose resource group" (change)="getLocationOfRG()" [clearable]="false" required></ng-select>
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new</p>
                <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
                    <div class='content'>
                        <div class="rgPopup-form">
                            <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                            <div class="input-box">
                            <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" placeholder="Enter name" name="rgName" required>
                            </div>              
                        </div>
                        <div class="rgPopup-form">
                            <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                            <div class="input-box">
                            <ng-select [items]="resourcesGroupLocationList" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                            </div>
                        </div>
                        <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                            You don't have the access to create a new resource group for this subscription
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Ok</button>
                            <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
                        </div>
                    </div>
                </kendo-popup>
                </div>                
            </div>
            <div class="form-group row">
              <label for="resourceGroupLocation" class="control-label col-sm-2">Resource group location<span class="mandatory">* <img src="/assets/Infra/info.svg" alt="" matTooltip="Choose the Azure region that's right for 
                you and your customers" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
              <div class="col-sm-6">
                <ng-select [disabled]="disableLocationsDropdown" [items]="countryList" bindLabel="key" bindValue="value" [(ngModel)]="model.resourceGroupLocation" name="resourceGroupLocation" 
                id="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
              </div>
            </div> -->
        </div>
    </form>

    <div class="fixed-btm-btn flex-custom no-wrap">
        <button class="btn btn-btn-primary" (click)="routeToDiagramTool()" [class.disabled]="checkDisable('main-page')||disableSaveBtn">Next</button>
    </div>

</div>