import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-monitoring-sap-netweaver',
  templateUrl: './application-monitoring-sap-netweaver.component.html',
  styleUrls: ['./application-monitoring-sap-netweaver.component.scss']
})
export class ApplicationMonitoringSapNetweaverComponent implements OnInit {
  
  currentTab = 'overview';

  constructor() { }

  ngOnInit(): void {
    $('.main-content').addClass('only-grey-bg');
  }

  ngOnDestroy() {
    $('.main-content').removeClass('only-grey-bg');
  }

}
