<div class="custom-fixed-tables">
    <table class="table">
        <thead>
          <tr>
            <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',0)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',1)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',2)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',3)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Type</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',4)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',5)"></div></th>
            <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',6)"></div></th>
            <th><div class="flex-custom no-wrap"><span>App Service Plan</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',7)"></div></th>
            <th><div class="flex-custom no-wrap"><span>URL</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',8)"></div></th>
            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',9)"></div></th>
            <th style="border-left: none;" class="wid-75"></th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data" >
              <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(appServiceDetailsTemplate,row,'appServicePopup')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td isEllipsisActive>{{ getProjectName(row[2]) | displayTxt }}</td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
              <td isEllipsisActive><div class="status-tag" [ngClass]="getStatusClass(row[5])">{{ row[5] | titlecase | displayTxt}}</div></td>
              <td isEllipsisActive>{{ getAppSkuName(row[6]) | displayTxt}}</td>
              <td isEllipsisActive>{{ row[7] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[8] | displayTxt}}</td>
              <td isEllipsisActive>{{ getTags(row[9])}}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2" (click)="getOverviewCondition(row)">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item"  [class.disabled-icon]="disableStart" mat-menu-item (click)="action(row,'startAppService')">
                      <p class="delete-popup-text" >Start</p>
                    </div>
                    <div class="mat-menu-item"  [class.disabled-icon]="!disableStart"mat-menu-item (click)="action(row,'restartAppService')">
                      <p class="delete-popup-text" >Restart</p>
                    </div>
                    <div class="mat-menu-item"  [class.disabled-icon]="!disableStart"mat-menu-item (click)="action(row,'stopAppService')">
                      <p class="delete-popup-text">Stop</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('deployementSlots',row)">
                      <p class="delete-popup-text">Deployment slots</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                      <p class="delete-popup-text">Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('scaleUp',row)">
                      <p class="delete-popup-text">Scale up (App Service plan)</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('scaleOut',row)">
                      <p class="delete-popup-text">Scale out (App Service plan)</p>
                    </div>
                    <!-- <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('identity',row)">
                        <p class="delete-popup-text">Identity</p>
                    </div> -->
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteAppServiceData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>

  <ng-container *ngIf="showSidesheet">


    <!-- Deployment Slots -->
    <ng-container *ngIf="currentTemplate == 'deployementSlots'">
      <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4 class="half-width">Deployment Slots <br> <span class="blur-text">App Services | {{selectedRow[0]}}</span></h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        
        <div class="body scroll-y">
          <form [formGroup]="currentForm">
            <div class="mb-3">
              <button class="btn-btn-secondary" (click)="addSlotSideSheet()">
                <img src="/assets/governance/add-btn.svg" alt="">
                Add slot
               </button>
             </div>
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Name</span>
                                  <!-- <img class="sort" src="assets/icons/sort.png"> -->
                              </div>
                          </th>
                          <th style="width:1px;">
                              <div class="flex-custom no-wrap">
                                  <span>Status</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>App Service Plan</span>
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Traffic (%)</span>
                              </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                            <tr>
                                <td isEllipsisActive style=" max-width: 140px;">{{appServiceData?.name | displayTxt}}</td>
                                <td class="status-tag ml-12 mt-2 mb-2 mr-1" style="max-width: 70px;" [ngClass]="getStatusClass(appServiceData?.properties?.state)">{{appServiceData?.properties?.state | titlecase | displayTxt}}</td>
                                <td isEllipsisActive style="max-width: 70px;">{{appServiceData?.properties?.serverFarmId.split('/')[8] | displayTxt}}</td>
                                <td>
                                  <input type="text" style="max-width: 125px;" name="traffic" class="form-check-inline disabled-input"  id='traffic_{{i}}' formControlName="defaultTraffic">
                                </td>
                            </tr>
                        <!-- <ng-container *ngFor="let list of appServiceSlotList"> -->
                          <ng-container formArrayName="trafficArray" *ngFor="let form of currentForm.get('trafficArray')['controls'];let i=index;let list of appServiceSlotList">
                            <tr [formGroupName]="i">
                              <td isEllipsisActive style="max-width: 140px;">{{list?.name?.replace('/','-') | displayTxt}}</td>
                              <td class="status-tag ml-12 mt-2 mb-2 mr-1" style="max-width: 70px;" [ngClass]="getStatusClass(list?.properties?.state)">{{list?.properties?.state | titlecase | displayTxt}}</td>
                              <td isEllipsisActive style="max-width: 140px;">{{list?.properties?.serverFarmId.split('/')[8] | displayTxt}}</td>
                              <td>
                                <input type="text" style="max-width: 125px;" name="traffic" class="form-check-inline"  id='traffic_{{i}}' [class.border-danger]="inputError.trafficLimit"  (keyup)="validTrafficValue($event,i)" formControlName="value">
                                <span *ngIf="inputError.trafficLimit"><img class="tooltip-icon" src="/assets/actions/errorQues.svg" alt="" matTooltip="Sum of routing percentage value of all rules must be less than or equal to 100.0" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span>
                              </td>
                          </tr>
                          </ng-container>
                        <!-- </ng-container> Value must be an decimal in the range of 0 to 100-->
                      </tbody>
                </table>
            </div>
          </form>
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || inputError.trafficLimit" (click)="saveAppServiceData('deployementSlot')">Save</button>
      </div>
    </div>
    <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
      <div class="header">
          <div class="d-flex">
            <h4>Add a Slot <br> <span class="blur-text">App Services | {{selectedRow[0]}}</span></h4>
             <div class="text-right half-width">
              <button type="button" class="close pull-right" aria-label="Close" (click)="close_subSheet()">
                <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
              </button>
            </div>
          </div>
      </div>
  
      <div class="body scroll-y">
          <form [formGroup]="subSideSheetForm">
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Name</label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.InvalidMinCharc|| inputError?.NameDupCheck ||inputError?.InvalidMaxCharc||inputError?.InvalidCharc||((subSideSheetForm.get('slotName')?.dirty ||  subSideSheetForm.get('slotName')?.touched) && !subSideSheetForm.get('slotName')?.value)" id="slotName" name="slotName" formControlName="slotName" (keyup)="validSlotName($event)"/>
                <p class="blur-text text-right">{{subSideSheetForm.get('slotName')?.value?selectedRow[0]+'-'+subSideSheetForm.get('slotName')?.value:''}}.azurewebsites.net</p>
                <p class="text-danger" *ngIf="(subSideSheetForm.get('slotName')?.dirty ||  subSideSheetForm.get('slotName')?.touched) && !subSideSheetForm.get('slotName')?.value">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.InvalidMinCharc">The name must be at least 2 characters.</p>
                <p class="text-danger" *ngIf="inputError?.NameDupCheck">This slot name is not available.</p>
                <p class="text-danger" *ngIf="inputError?.InvalidMaxCharc">The combined site name and slot name must be fewer than 59 characters.</p>
                <p class="text-danger" *ngIf="inputError?.InvalidCharc">Slot names cannot contain special characters _`~\/""[]:|<>+=;,?*@&, or whitespace.</p>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Clone settings from:</label>
                <ng-select [items]="cloneSettingList"  bindLabel="name" bindValue="id" name="cloneSetting" [clearable]="false" id="cloneSetting" formControlName="cloneSetting"></ng-select>
              </div> 
          </form>
      </div>
  
      <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-btn-primary" [class.disabled]="!subSideSheetForm.get('slotName').value ||inputError?.NameDupCheck|| inputError?.InvalidMinCharc||inputError?.InvalidMaxCharc||inputError?.InvalidCharc" (click)="saveAppServiceData('addSlot')">Save</button>
      </div>
    </div>
    </ng-container>
    <!-- Configuration -->
    <ng-container *ngIf="currentTemplate == 'configuration'">
      <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4 class="half-width">Configuration <br> <span class="blur-text">App Services | {{selectedRow[0]}}</span></h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        
        <div class="body scroll-y">
          <form [formGroup]="currentForm">
            <div class="tabs-section">
              <div class="main-nav">
                <ul class="nav-wrap m-0 p-0" >
                  <li class="list-item" [class.active]="currentTab == 'appSetting'" (click)="changeTab('appSetting')">
                    <span class="menu-name">Application settings</span>
                  </li>
                  <li class="list-item" [class.active]="currentTab == 'generalSetting'" (click)="changeTab('generalSetting')">
                    <span class="menu-name">General settings</span>
                  </li>
                </ul>
              </div>
            </div>
            <ng-container *ngIf="currentTab =='appSetting'">
              <div class="mb-2">
                <label class="flex-custom no-wrap">Application Settings<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Application settings are encrypted at rest and transmitted over an encrypted channel. 
                  You can choose to display them in plain text in your browser by using the controls
                  below. Application Settings are exposed as environment variables for access by 
                  your application at runtime." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              </div>
              <div class="mb-3">
                <button class="btn-btn-secondary" (click)="addAppSetting()">
                  <img src="/assets/governance/add-btn.svg" alt="">
                  Add new application settings
                 </button>
               </div>
              <div class="custom-fixed-tables">
                  <table class="table">
                      <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                    <!-- <img class="sort" src="assets/icons/sort.png"> -->
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Value</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Source</span>
                                </div>
                            </th>
                            <th style="border-right: none;">
                                <div class="flex-custom no-wrap">
                                    <span>Deploy slot settings</span>
                                </div>
                            </th>
                            <th style="border-left: none;"> </th>
                          </tr>
                        </thead>
                        <tbody >
                            <ng-container *ngFor="let list of appSettingList;let i=index">
                              <tr>
                                <td isEllipsisActive style="max-width:135px;">{{list?.name | displayTxt}}</td>
                                <td isEllipsisActive style="max-width:135px;">{{list?.value | displayTxt}}</td>
                                <td isEllipsisActive style="max-width:135px;">App Service Config</td>
                                <td isEllipsisActive style="max-width:135px;">{{list?.deploySetting | displayTxt}}</td>
                                <td>
                                  <img src="assets/app-monitoring/Edit.svg" alt="" class="cursor-pointer ml-2" (click)="editAppSetting(list,i)">
                                  <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-3" (click)="removeAppSetting(list,i)">
                                </td>
                              </tr>
                            </ng-container>
                            <div class="col-md-12 mt-3" *ngIf="!appSettingList?.length">
                              <p class="content-14 blur-text">No application settings to display</p>
                            </div>
                        </tbody>
                  </table>
              </div>
              <div class="mb-2">
                <label class="flex-custom no-wrap">Connection Strings<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Connection strings are encrypted at rest and transmitted over an encrypted channel." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              </div>
              <div class="mb-3">
                <button class="btn-btn-secondary" (click)="addConnSetting()">
                  <img src="/assets/governance/add-btn.svg" alt="">
                  New connection string
                 </button>
               </div>
              <div class="custom-fixed-tables">
                  <table class="table">
                      <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                    <!-- <img class="sort" src="assets/icons/sort.png"> -->
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Value</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Source</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Type</span>
                                </div>
                            </th>
                            <th style="border-right: none;">
                              <div class="flex-custom no-wrap">
                                  <span>Deploy slot settings</span>
                              </div>
                            </th>
                            <th style="border-left: none;"> </th>
                          </tr>
                        </thead>
                        <tbody >
                          <ng-container *ngFor="let list of connSettingList;let i=index">
                            <tr>
                              <td isEllipsisActive style="max-width:135px;">{{list?.name | displayTxt}}</td>
                              <td isEllipsisActive style="max-width:135px;">{{list?.value | displayTxt}}</td>
                              <td isEllipsisActive style="max-width:135px;">App Service Config</td>
                              <td isEllipsisActive style="max-width:135px;">{{list?.type | displayTxt}}</td>
                              <td isEllipsisActive style="max-width:135px;">{{list?.deploySetting | displayTxt}}</td>
                              <td>
                                <img src="assets/app-monitoring/Edit.svg" alt="" class="cursor-pointer ml-2" (click)="editConnSetting(list)">
                                <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-3" (click)="removeConnSetting(list,i)"> 
                              </td>
                            </tr>
                          </ng-container>
                          <div class="col-md-12 mt-3" *ngIf="!connSettingList?.length">
                            <p class="content-14 blur-text">No connection strings to display</p>
                          </div>
                        </tbody>
                  </table>
              </div>
            </ng-container>
            <ng-container *ngIf="currentTab =='generalSetting'">

              <ng-container *ngIf="appServiceData.kind!='app'">
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600">Stack Settings</label>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Type</label>
                  <ng-select [items]="stackTypeList" class="disabled"  bindLabel="properties.displayText" bindValue="properties.displayText" name="stackType" [clearable]="false" id="stackType" (change)="selectedStackType($event)" formControlName="stackType"></ng-select>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Major version</label>
                  <ng-select [items]="majorVersionList" class="disabled" bindLabel="displayText" bindValue="displayText" name="majorVersion" [clearable]="false" id="majorVersion" (change)="selectedMajorVersion($event)" formControlName="majorVersion"></ng-select>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Minor version</label>
                  <ng-select [items]="minorVersionList" class="disabled" bindLabel="displayText" bindValue="value" name="minorVersion" [clearable]="false" id="minorVersion" (change)="saveDisable=true" formControlName="minorVersion"></ng-select>
                </div>
                <div class="mb-3" >
                  <label class="flex-custom no-wrap">Startup Command</label>
                  <!-- <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an optional startup command that will be run as part of container startup." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> -->
                  <input class="form-control" type="text" autocomplete="off" id="startupCommand" name="startupCommand" formControlName="startupCommand"/>
                </div>
              </ng-container>
          
              <div class="mb-2">
                <label class="flex-custom no-wrap bold-600">Platform Settings</label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">FTP state</label>
                <!-- <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="FTP based deployment can be disabled or configured to accept FTP (plain text) or FTPS (secure) connections. " matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> -->
                <ng-select [items]="ftpStateList" bindLabel="name" bindValue="id" name="ftpState" [clearable]="false" id="ftpState" (change)="saveDisable=true" formControlName="ftpState"></ng-select>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">HTTP version</label>
                <ng-select [items]="httpversionList" bindLabel="name" bindValue="id" name="httpVersion" [clearable]="false" id="httpVersion" (change)="saveDisable=true" formControlName="httpVersion"></ng-select>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Web sockets</label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="webSocket"  class="form-check-inline" id='webSocketOn' (change)="setRadioBtn('yes','configuration','webSocket')" [checked]="currentForm.get('webSocket')?.value ==true">
                      <label class="value" for="webSocketOn">On</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="webSocket" class="form-check-inline" id='webSocketOff' (change)="setRadioBtn('no','configuration','webSocket')" [checked]="currentForm.get('webSocket')?.value == false">
                      <label class="value" for="webSocketOff">Off</label>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Always on</label>
                <!-- <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an optional startup command that will be run as part of container startup." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> -->
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="alwaysOn"  class="form-check-inline" id='alwaysOn' (change)="setRadioBtn('yes','configuration','alwaysOn')" [checked]="currentForm.get('alwaysOn')?.value ==true">
                      <label class="value" for="alwaysOn">On</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="alwaysOn" class="form-check-inline" id='alwaysOff' (change)="setRadioBtn('no','configuration','alwaysOn')" [checked]="currentForm.get('alwaysOn')?.value == false">
                      <label class="value" for="alwaysOff">Off</label>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">ARR affinity</label>
                <!-- <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Improve performance of your stateless app by turning Affinity Cookie off, stateful apps should keep this setting on for compatibility." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> -->
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="arrAffinity"  class="form-check-inline" id='arrAffinityOn' (change)="setRadioBtn('yes','configuration','arrAffinity')" [checked]="currentForm.get('arrAffinity')?.value ==true">
                      <label class="value" for="arrAffinityOn">On</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="arrAffinity" class="form-check-inline" id='arrAffinityOff' (change)="setRadioBtn('no','configuration','arrAffinity')" [checked]="currentForm.get('arrAffinity')?.value == false">
                      <label class="value" for="arrAffinityOff">Off</label>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">HTTPS Only</label>
                <!-- <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enable this feature to redirect all HTTP traffic to HTTPS." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> -->
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="httpsOnly"  class="form-check-inline" id='httpsOnlyOn' (change)="setRadioBtn('yes','configuration','httpsOnly')" [checked]="currentForm.get('httpsOnly')?.value ==true">
                      <label class="value" for="httpsOnlyOn">On</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="httpsOnly" class="form-check-inline" id='httpsOnlyOff' (change)="setRadioBtn('no','configuration','httpsOnly')" [checked]="currentForm.get('httpsOnly')?.value == false">
                      <label class="value" for="httpsOnlyOff">Off</label>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Minimum TLS Version</label>
                <!-- <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the minimum TLS encryption version required by your app." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> -->
                <ng-select [items]="tlsVersionList" bindLabel="name" bindValue="id" name="tlsVersion" [clearable]="false" id="tlsVersion" (change)="saveDisable=true" formControlName="tlsVersion"></ng-select>
              </div>
              <div class="mb-2">
                <label class="flex-custom no-wrap bold-600">Debugging</label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Remote debugging</label>
                <!-- <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Your selected runtime stack does not currently support remote debugging." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> -->
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="remoteDebug"  class="form-check-inline" id='remoteDebugOn' (change)="setRadioBtn('yes','configuration','remoteDebug')" [checked]="currentForm.get('remoteDebug')?.value ==true">
                      <label class="value" for="remoteDebugOn">On</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="remoteDebug" class="form-check-inline" id='remoteDebugOff' (change)="setRadioBtn('no','configuration','remoteDebug')" [checked]="currentForm.get('remoteDebug')?.value == false">
                      <label class="value" for="remoteDebugOff">Off</label>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <label class="flex-custom no-wrap bold-600">Incoming Client Certificates</label>
              </div>
              <div class="mb-2">
                <label class="flex-custom no-wrap">Remote debugging</label>
                <!-- <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Your selected runtime stack does not currently support remote debugging." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"> -->
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="clientDebug"  class="form-check-inline" id='require' (change)="setRadioBtn('Required','configuration','clientDebug')" [checked]="currentForm.get('clientDebug')?.value =='Required'">
                      <label class="value" for="require">Require</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="clientDebug" class="form-check-inline" id='allow' (change)="setRadioBtn('Allowed','configuration','clientDebug')" [checked]="currentForm.get('clientDebug')?.value == 'Allowed'">
                      <label class="value" for="allow">Allow</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="clientDebug" class="form-check-inline" id='optional' (change)="setRadioBtn('Optional','configuration','clientDebug')" [checked]="currentForm.get('clientDebug')?.value == 'Optional'">
                      <label class="value" for="optional">Optional</label>
                  </div>
                  <div class="checkbox-cs">
                    <input type="radio" name="clientDebug" class="form-check-inline" id='ignore' (change)="setRadioBtn('Ignored','configuration','clientDebug')" [checked]="currentForm.get('clientDebug')?.value == 'Ignored'">
                    <label class="value" for="ignore">Ignore</label>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label *ngIf="currentForm.get('clientDebug')?.value == 'Required'" class="flex-custom no-wrap">All requests must be authenticated through a client certificate.</label>
                <label *ngIf="currentForm.get('clientDebug')?.value == 'Allowed'" class="flex-custom no-wrap">Clients will be prompted for a certificate, if no certificate is provided fallback to SSO or other means of authentication. Unauthenticated requests will be blocked.</label>
                <label *ngIf="currentForm.get('clientDebug')?.value == 'Optional'" class="flex-custom no-wrap">Clients will not be prompted for a certificate by default. Unless the request can be authenticated through other means (like SSO), it will be blocked.</label>
                <label *ngIf="currentForm.get('clientDebug')?.value == 'Ignored'" class="flex-custom no-wrap">No client authentication is required. Unauthenticated requests will not be blocked.</label>
              </div>
              <div class="mb-3" >
                <label class="flex-custom no-wrap">Certificate exclusion path</label>
                <div class="flex-custom no-wrap">
                  <input class="form-control disabled-input" type="text" autocomplete="off" id="exclusionPath" name="exclusionPath" formControlName="exclusionPath" />
                  <img src="assets/app-monitoring/Edit.svg" alt="" class="cursor-pointer disabled-icon ml-3 mb-1">
                </div>
              </div>
            </ng-container>
          </form>
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-btn-primary" [class.disabled]="!saveDisable" (click)="saveAppServiceData('configuration')">Save</button>
      </div>
    </div>
    <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet && currentSubSideSheet =='appSetting'">
      <div class="header">
          <div class="d-flex">
            <h4>{{isEdit?'Edit Application Setting':'Add Application Setting'}}<br> <span class="blur-text">App Services | {{selectedRow[0]}}</span></h4>
             <div class="text-right half-width">
              <button type="button" class="close pull-right" aria-label="Close" (click)="close_subSheet()">
                <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
              </button>
            </div>
          </div>
      </div>
  
      <div class="body scroll-y">
        <form [formGroup]="subSideSheetForm">
          <div class="comm-mb">
            <label class="flex-custom no-wrap">Name</label>
            <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.NameDupCheck||inputError?.InvalidCharc||((subSideSheetForm.get('name')?.dirty ||  subSideSheetForm.get('name')?.touched) && !subSideSheetForm.get('name')?.value)" id="appName" name="appName" formControlName="name" (keyup)="validSettingName($event,'appSetting')"/>
            <p class="text-danger" *ngIf="(subSideSheetForm.get('name')?.dirty ||  subSideSheetForm.get('name')?.touched) && !subSideSheetForm.get('name')?.value">The value must not be empty.</p>
            <p class="text-danger" *ngIf="inputError?.InvalidCharc">App Setting names can only contain letters, numbers (0-9), periods ("."), and underscores ("_"). For hierarchical config, use double underscore instead ("__") instead of colon (":").</p>
            <p class="text-danger" *ngIf="inputError?.NameDupCheck">App setting names must be unique.</p>
          </div>
          <div class="comm-mb">
            <label class="flex-custom no-wrap">Value</label>
            <input class="form-control full-width " type="text" autocomplete="off" id="appValue" name="appValue" formControlName="value" (keyup)="disabledSave=false"/>
          </div>
          <div class="mb-2">
            <div class="checkbox-cs"> 
              <input type="checkbox" name="appDeploy" class="form-check-inline"  id='appDeploy' formControlName="deploySetting" (change)="disabledSave=false" [checked]="subSideSheetForm.get('deploySetting')?.value ==true">
              <label class="value" for="appDeploy">Deploy slot setting</label>
            </div>
          </div>
      </form>
      </div>
  
      <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-btn-primary" [class.disabled]="disabledSave || !subSideSheetForm.get('name').value ||inputError?.InvalidCharc" (click)="saveAppServiceData('addAppSetting')">Ok</button>
      </div>
    </div>
    <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet && currentSubSideSheet =='connSetting'">
      <div class="header">
          <div class="d-flex">
            <h4>{{isEdit?'Edit Connection String':'Add Connection String'}}<br> <span class="blur-text">App Services | {{selectedRow[0]}}</span></h4>
             <div class="text-right half-width">
              <button type="button" class="close pull-right" aria-label="Close" (click)="close_subSheet()">
                <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
              </button>
            </div>
          </div>
      </div>
  
      <div class="body scroll-y">
          <form [formGroup]="subSideSheetForm">
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Name</label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.NameDupCheck|| inputError?.InvalidCharc||((subSideSheetForm.get('name')?.dirty ||  subSideSheetForm.get('name')?.touched) && !subSideSheetForm.get('name')?.value)" id="connName" name="connName" formControlName="name" (keyup)="validSettingName($event,'connString')"/>
                <p class="text-danger" *ngIf="(subSideSheetForm.get('name')?.dirty ||  subSideSheetForm.get('name')?.touched) && !subSideSheetForm.get('name')?.value">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.InvalidCharc">Connection String names can only contain letters, numbers (0-9), periods ("."), and underscores ("_"). For hierarchical config, use double underscore instead ("__") instead of colon (":").</p>
                <p class="text-danger" *ngIf="inputError?.NameDupCheck">Connection string names must be unique.</p>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Value</label>
                <input class="form-control full-width " type="text" autocomplete="off" id="connValue" name="connValue" formControlName="value" (keyup)="disabledSave=false"/>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Type</label>
                <ng-select [items]="connSettingTypeList"  bindLabel="name" bindValue="id" name="connType" [clearable]="false" id="connType" formControlName="connType" (change)="disabledSave=false"></ng-select>
              </div> 
              <div class="mb-2">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="connDeploy" class="form-check-inline"  id='connDeploy' formControlName="deploySetting" (change)="disabledSave=false" [checked]="subSideSheetForm.get('deploySetting')?.value ==true">
                  <label class="value" for="connDeploy">Deploy slot setting</label>
                </div>
              </div>
          </form>
      </div>
  
      <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-btn-primary" [class.disabled]="disabledSave || !subSideSheetForm.get('name').value || inputError?.InvalidCharc" (click)="saveAppServiceData('addConnSetting')">Ok</button>
      </div>
    </div>
    </ng-container>
     
      <!-- Scale Up -->
      <ng-container *ngIf="currentTemplate == 'scaleUp'">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">Scale Up<br> <span class="blur-text">App Services Plan | {{selectedRow[7]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <form [formGroup]="currentForm">
              <div class="body" style="height:auto;">
                  <div class="">
                    <div class="flex-custom radioBtnBorder mb-2" [class.active]="currentForm.get('type')?.value =='dev'">
                        <div class="checkbox-cs ml-12" [class.radioBtnActive]="currentForm.get('type')?.value =='dev'">
                          <input type="radio" name="type"  class="form-check-inline" id='dev' (change)="setRadioBtn('dev','scaleUp','type')" [checked]="currentForm.get('type')?.value =='dev'">
                          <label class="value bold-600" for="dev">Dev / Test<br><span style="font-weight: 400;">For less demanding workloads</span></label>
                      </div>
                    </div>
                    <div class="flex-custom radioBtnBorder mb-2"  [class.active]="currentForm.get('type')?.value =='production'">
                      <div class="checkbox-cs ml-12" [class.radioBtnActive]="currentForm.get('type')?.value =='production'">
                          <input type="radio" name="type" class="form-check-inline" id='production' (change)="setRadioBtn('production','scaleUp','type')" [checked]="currentForm.get('type')?.value == 'production'">
                          <label class="value bold-600" for="production">Production<br><span style="font-weight: 400;">For most production workloads</span></label>
                      </div>
                    </div>
                    <div class="flex-custom radioBtnBorder mb-2"  [class.active]="currentForm.get('type')?.value =='isolated'">
                      <div class="checkbox-cs ml-12" [class.radioBtnActive]="currentForm.get('type')?.value =='isolated'">
                          <input type="radio" name="type" class="form-check-inline" id='isolated' (change)="setRadioBtn('isolated','scaleUp','type')" [checked]="currentForm.get('type')?.value == 'isolated'">
                          <label class="value bold-600" for="isolated">Isolated<br><span style="font-weight: 400;">Advanced networking and scale</span></label>
                      </div>
                    </div>
                  </div>
                  <div class="comm-mb" *ngIf="currentForm.get('type')?.value =='isolated'">
                    <div class="label-div d-flex "style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        Isolated pricing tiers within an App Service Environment (ASE) are not available for your configuration. An ASE is a powerful feature offering of Azure App Service that gives network isolation and improved scale capabilities.</label>
                    </div>  
                  </div>  
                </div>
                <div class="scaleUpBody">
                  <div class="mb-3" *ngIf="appServicePlanSKUList.length">
                    <label class="flex-custom no-wrap bold-600" style="font-size: 14px;">Recommended Pricing Tiers</label>
                  </div>
                  <ng-container *ngFor="let list of appServicePlanSKUList;let i=index ">
                    <div class="comm-mb">
                      <div class="flex-custom tierList mb-2" [class.active]="list?.active" (click)="setTier(list,i)">
                        <!-- <div class="checkbox-cs ml-12" [class.radioBtnActive]="currentForm.get('type')?.value =='dev'"> -->
                          <label class="value bold-600" style="padding:8px;">{{list?.sku?.name}}<br>
                            <!-- <span style="font-weight: 400;">For less demanding workloads</span> -->
                          </label>
                      <!-- </div> -->
                    </div>
                    </div>
                  </ng-container>
                  <div class="mb-3" *ngIf="additionalTierlist.length">
                    <label class="flex-custom no-wrap bold-600" style="font-size: 14px;">Additional Pricing Tiers</label>
                  </div>
                  <ng-container *ngFor="let list of additionalTierlist;let i=index ">
                    <div class="comm-mb">
                      <div class="flex-custom tierList mb-2" [class.active]="list?.active" (click)="setTier(list,i)">
                        <!-- <div class="checkbox-cs ml-12" [class.radioBtnActive]="currentForm.get('type')?.value =='dev'"> -->
                          <label class="value bold-600" style="padding:8px ;">{{list?.sku?.name}}<br>
                            <!-- <span style="font-weight: 400;">For less demanding workloads</span> -->
                          </label>
                      <!-- </div> -->
                    </div>
                    </div>
                  </ng-container>
                </div>
            </form>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable" (click)="saveAppServiceData('scaleUp')">Apply</button>
            </div>
        </div>
      </ng-container>
       <!-- Scale Out -->
       <ng-container *ngIf="currentTemplate == 'scaleOut'">
        <div class="sidebar-right single width-652" >
            <div class="header">
                <div class="d-flex">
                  <h4 class="threeFour-width ">Scale Out<br> <span class="blur-text">App Services Plan | {{selectedRow[7]}}</span></h4>
                  <div class="text-right half-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
             <form [formGroup]="currentForm">
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Choose how to scale your resource</label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="scale"  class="form-check-inline" id='scale' (change)="setRadioBtn('manual','scaling','scale')" [checked]="currentForm.get('scale')?.value =='manual'">
                        <label class="value bold-600" for="scale">Manual scale<br><span style="font-weight: 400;">Maintain a fixed instance count</span></label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="customScale" class="form-check-inline" id='customScale' (change)="setRadioBtn('custom','scaling','scale')" [checked]="currentForm.get('scale')?.value == 'custom'">
                        <label class="value bold-600" for="customScale">Custom autoscale<br><span style="font-weight: 400;">Scale on any schedule, based on any metrics</span></label>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="currentForm.get('scale').value=='manual'">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap bold-600">Override Condition</label>
                  </div>
                  <div class="mb-3" >
                    <label class="flex-custom no-wrap">Instance count<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Indicates desired capacity" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control half-width" type="text" autocomplete="off" [class.border-danger]="inputError?.InvalidPortRange||inputError?.NotValidPortVal||((currentForm.get('overRideInstanceCount')?.dirty ||  currentForm.get('overRideInstanceCount')?.touched) && !currentForm.get('overRideInstanceCount')?.value)" id="overRideInstanceCount" name="overRideInstanceCount" formControlName="overRideInstanceCount" (keyup)="portRangeValidation($event,1000,'instanceCount')"/>
                    <p class="text-danger" *ngIf="(currentForm.get('overRideInstanceCount')?.dirty ||  currentForm.get('overRideInstanceCount')?.touched) && !currentForm.get('overRideInstanceCount')?.value">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.InvalidPortRange||inputError?.NotValidPortVal">The value must be between 1 and 1000.</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="currentForm.get('scale').value=='custom'">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Autoscale setting name<span class="mandatory">*</span></label>
                    <input class="form-control full-width " [class.disabled-input]="disableAutoScaleName" type="text" autocomplete="off" [class.border-danger]="inputError?.validScaleName" id="autoscaleName" name="autoscaleName" formControlName="autoscaleName" (keyup)="validAutoscaleName($event)"/>
                    <p class="text-danger" *ngIf="inputError?.validScaleName">The autoscale name may not contain /, %, &, >, <, #, ., ?, or \.</p>
                  </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Resource group</label>
                    <ng-select [items]="ResourceGroupsList" class="disabled"  bindLabel="name" bindValue="name" name="rg" [clearable]="false" id="rg" (change)="saveDisable=true" formControlName="rg"></ng-select>
                  </div>
                  <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600">Predictive autoscale (public preview)</label>
                  </div> 
                  <div class="mb-3">
                    <div class="flex-custom">
                      <div class="comm-mb mr-3 width-300">
                        <label class="flex-custom no-wrap ">Mode</label>
                        <ng-select [items]="mode"  class="disabled" bindLabel="name" bindValue="id" name="mode" [clearable]="false" id="mode" formControlName="mode"></ng-select>
                      </div>
                      <div class="mb-4">
                        <label class="flex-custom no-wrap">Pre-launch setup of instances (minutes)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify the time by which instances are launched in advance." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control disabled-input width-300" type="text" autocomplete="off" id="preLaunchSetup" name="preLaunchSetup" formControlName="preLaunchSetup" />
                      </div>
                    </div>
                 </div>
                 <div class="mb-3" *ngIf="defaultScaleCondition==false">
                  <button class="btn-btn-secondary" (click)="defaultScaleCondition=true">
                    <img src="/assets/governance/add-btn.svg" alt="">
                    Add a default scale condition
                   </button>
                 </div>
                <ng-container *ngIf="defaultScaleCondition">
                  <div class="mb-3">
                    <div class="flex-custom">
                      <label class="flex-custom no-wrap bold-600 mr-auto">Default Scale Condition<span class="mandatory">*</span></label>
                      <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer ml-2 mt-4" *ngIf="scaleCondition" (click)="defaultScaleCondition=false"> 
                    </div>
                   </div>
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Scale condition name<span class="mandatory">*</span></label>
                    <input class="form-control full-width " type="text" autocomplete="off" id="defScaleConditionName" name="defScaleConditionName" formControlName="defScaleConditionName" (keyup)="saveDisable=true"/>
                  </div>
                  <div class="comm-mb">
                    <div class="label-div d-flex blueBox">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;">
                        The very last or default recurrence rule cannot be deleted. Instead, you can disable autoscale to turn off autoscale.</label>
                    </div>  
                  </div>
                  <div class="mb-3">
                      <label class="flex-custom no-wrap">Scale mode</label>
                      <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="defScaleMode"  class="form-check-inline" id='metricScale' (change)="setRadioBtn('yes','scaling','defScaleMode')" [checked]="currentForm.get('defScaleMode')?.value ==true">
                            <label class="value" for="metricScale">Scale based on a metric</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="defScaleMode" class="form-check-inline" id='instanceCountScale' (change)="setRadioBtn('no','scaling','defScaleMode')" [checked]="currentForm.get('defScaleMode')?.value == false">
                            <label class="value" for="instanceCountScale">Scale to a specific instance count</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('defScaleMode').value">
                      <label class="flex-custom no-wrap bold-600">Instance Limits</label>
                      <div class="flex-custom">
                        <div class="mb-3 mr-2">
                          <label class="flex-custom no-wrap">Minimum<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify minimum instance count" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.defMinValidInstance||inputError?.defValidMinInstance||((currentForm.get('defInstanceMin')?.dirty ||  currentForm.get('defInstanceMin')?.touched) && !currentForm.get('defInstanceMin')?.value)" id="defInstanceMin" name="defInstanceMin" formControlName="defInstanceMin" (keyup)="instanceLimitDefaultValidation($event,'defMin')"/>
                        </div>
                        <div class="mb-3 mr-2">
                          <label class="flex-custom no-wrap">Maximum<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify maximum instance count" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.defMaxValidInstance|| inputError?.defValidMaximumInstance||inputError?.defValidMaxInstance||((currentForm.get('defInstanceMax')?.dirty ||  currentForm.get('defInstanceMax')?.touched) && !currentForm.get('defInstanceMax')?.value)" id="defInstanceMax" name="defInstanceMax" formControlName="defInstanceMax" (keyup)="instanceLimitDefaultValidation($event,'defMax')"/>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Default<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="In case there is a problem reading the resource metrics and the current 
                            capacity is below the default capacity, then to ensure the availability of 
                            the resource, Autoscale will scale out to the default.If the current capacity 
                            is already higher than the default capacity, Autoscale will not scale in." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.defValidDefInstance||inputError?.defaultDefValidInstance||((currentForm.get('defInstanceDef')?.dirty ||  currentForm.get('defInstanceDef')?.touched) && !currentForm.get('defInstanceDef')?.value)" id="defInstanceDef" name="defInstanceDef" formControlName="defInstanceDef" (keyup)="instanceLimitDefaultValidation($event,'defaultDef')"/>
                        </div>
                      </div>
                      <p class="text-danger" *ngIf="((currentForm.get('defInstanceMin')?.dirty ||  currentForm.get('defInstanceMin')?.touched) && !currentForm.get('defInstanceMin')?.value) || ((currentForm.get('defInstanceDef')?.dirty
                      ||  currentForm.get('defInstanceDef')?.touched) && !currentForm.get('defInstanceDef')?.value) || ((currentForm.get('defInstanceMax')?.dirty ||  currentForm.get('defInstanceMax')?.touched) && !currentForm.get('defInstanceMax')?.value)">The value must not be empty.</p>
                     <p class="text-danger" *ngIf="inputError?.defValidMinInstance || inputError?.defValidMaximumInstance">'Standard_B1s' SKU has minimum instance count of 0, and can scale out to 1000 instances. If you need to 
                       further scale out your instances, consider scaling up to a larger SKU.</p>
                     <p class="text-danger" *ngIf="inputError?.defMinValidInstance || inputError?.defMaxValidInstance || inputError?.defaultDefValidInstance">The value must be a valid number.</p>
                     <p class="text-danger" *ngIf="inputError?.defValidMaxInstance">Instance maximum value cannot be lower than minimum value.</p>
                     <p class="text-danger" *ngIf="inputError?.defValidDefInstance">Instance default value should be within min/max value.</p>
                   </div>
                   <div class="mb-3" *ngIf="!currentForm.get('defScaleMode').value">
                    <label class="flex-custom no-wrap">Instance count</label>
                    <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.defvalidNumber||inputError?.defvalidCount||((currentForm.get('defScaleInstanceCount')?.dirty ||  currentForm.get('defScaleInstanceCount')?.touched) && !currentForm.get('defScaleInstanceCount')?.value)" id="defScaleInstanceCount" name="defScaleInstanceCount" formControlName="defScaleInstanceCount" (keyup)="validInstanceCount($event,'def')"/>
                    <p class="text-danger" *ngIf="(currentForm.get('defScaleInstanceCount')?.dirty ||  currentForm.get('defScaleInstanceCount')?.touched) && !currentForm.get('defScaleInstanceCount')?.value">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.defvalidCount">The value must be between 1 and 1000.</p>
                    <p class="text-danger" *ngIf="inputError?.defvalidNumber">The value must be a valid number.</p>
                  </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Schedule</label>
                    <label class="flex-custom no-wrap bold-600">This scale condition is executed when none of the other scale condition(s) match</label>
                  </div>
                </ng-container>
               
                <ng-container *ngIf="scaleCondition">
                 <ng-container formArrayName="scaleConditonArray" *ngFor="let list of currentForm.get('scaleConditonArray')['controls']; let i = index;">
                  <div [formGroupName]="i">
                  <div class="mb-3">
                    <div class="flex-custom">
                      <label class="flex-custom no-wrap bold-600 mr-auto">Scale Condition<span class="mandatory">*</span></label>
                      <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer ml-2 mt-4" *ngIf="defaultScaleCondition || scaleConditionArrayList.length>1"  (click)="deletescaleCondition(i)"> 
                    </div>
                    <!-- *ngIf="defaultScaleCondition" -->
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Scale condition name<span class="mandatory">*</span></label>
                      <input class="form-control full-width " type="text" autocomplete="off" id="scaleConditionName_{{i}}" name="scaleConditionName_{{i}}" formControlName="scaleConditionName" (keyup)="saveDisable=true"/>
                    </div>
                    <div class="comm-mb">
                      <div class="label-div d-flex blueBox">
                        <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                        <label class="" style="padding: 8px 0px !important;">
                          The very last or default recurrence rule cannot be deleted. Instead, you can disable autoscale to turn off autoscale.</label>
                      </div>  
                    </div>
                    <div class="mb-3">
                        <label class="flex-custom no-wrap">Scale mode</label>
                        <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="scaleMode"  class="form-check-inline" id='metricScale_{{i}}' (change)="setRadioBtn('yes','scaling','scaleMode',i)" [checked]="list.get('scaleMode')?.value ==true">
                              <label class="value" for="metricScale_{{i}}">Scale based on a metric</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="scaleMode" class="form-check-inline" id='instanceCountScale_{{i}}' (change)="setRadioBtn('no','scaling','scaleMode',i)" [checked]="list.get('scaleMode')?.value == false">
                              <label class="value" for="instanceCountScale_{{i}}">Scale to a specific instance count</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="list.get('scaleMode').value">
                        <label class="flex-custom no-wrap bold-600">Instance Limits</label>
                        <div class="flex-custom">
                          <div class="mb-3 mr-2">
                            <label class="flex-custom no-wrap">Minimum<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify minimum instance count" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.validMinInstance||inputError?.minValidInstance||((list.get('instanceMin')?.dirty ||  list.get('instanceMin')?.touched) && !list.get('instanceMin')?.value)" id="instanceMin_{{i}}" name="instanceMin_{{i}}" formControlName="instanceMin" (keyup)="instanceLimitValidation($event,'min',i)"/>
                          </div>
                          <div class="mb-3 mr-2">
                            <label class="flex-custom no-wrap">Maximum<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify maximum instance count" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.validMaxInstance|| inputError?.validMaximumInstance||inputError?.maxValidInstance||((list.get('instanceMax')?.dirty ||  list.get('instanceMax')?.touched) && !list.get('instanceMax')?.value)" id="instanceMax_{{i}}" name="instanceMax_{{i}}" formControlName="instanceMax" (keyup)="instanceLimitValidation($event,'max',i)"/>
                          </div>
                          <div class="mb-3">
                            <label class="flex-custom no-wrap">Default<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="In case there is a problem reading the resource metrics and the current 
                              capacity is below the default capacity, then to ensure the availability of 
                              the resource, Autoscale will scale out to the default.If the current capacity 
                              is already higher than the default capacity, Autoscale will not scale in." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.validDefInstance||inputError?.defValidInstance||((list.get('instanceDef')?.dirty ||  list.get('instanceDef')?.touched) && !list.get('instanceDef')?.value)" id="instanceDef_{{i}}" name="instanceDef_{{i}}" formControlName="instanceDef" (keyup)="instanceLimitValidation($event,'def',i)"/>
                          </div>
                        </div>
                        <p class="text-danger" *ngIf="((list.get('instanceMin')?.dirty ||  list.get('instanceMin')?.touched) && !list.get('instanceMin')?.value) || ((list.get('instanceDef')?.dirty
                          ||  list.get('instanceDef')?.touched) && !list.get('instanceDef')?.value) || ((list.get('instanceMax')?.dirty ||  list.get('instanceMax')?.touched) && !list.get('instanceMax')?.value)">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.validMinInstance || inputError?.validMaximumInstance">'Standard_B1s' SKU has minimum instance count of 0, and can scale out to 1000 instances. If you need to 
                          further scale out your instances, consider scaling up to a larger SKU.</p>
                        <p class="text-danger" *ngIf="inputError?.minValidInstance || inputError?.maxValidInstance || inputError?.defValidInstance">The value must be a valid number.</p>
                        <p class="text-danger" *ngIf="inputError?.validMaxInstance">Instance maximum value cannot be lower than minimum value.</p>
                        <p class="text-danger" *ngIf="inputError?.validDefInstance">Instance default value should be within min/max value.</p>
                      </div>
                      <div class="mb-3" *ngIf="!list.get('scaleMode').value">
                      <label class="flex-custom no-wrap">Instance count</label>
                      <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.validCount||inputError?.validNumber||((list.get('scaleInstanceCount')?.dirty ||  list.get('scaleInstanceCount')?.touched) && !list.get('scaleInstanceCount')?.value)" id="scaleInstanceCount_{{i}}" name="scaleInstanceCount_{{i}}" formControlName="scaleInstanceCount" (keyup)="validInstanceCount($event,'')"/>
                      <p class="text-danger" *ngIf="(list.get('scaleInstanceCount')?.dirty ||  list.get('scaleInstanceCount')?.touched) && !list.get('scaleInstanceCount')?.value">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.validCount">The value must be between 1 and 1000.</p>
                      <p class="text-danger" *ngIf="inputError?.validNumber">The value must be a valid number.</p>
                    </div>
                  <div class="mb-3">
                      <label class="flex-custom no-wrap">Schedule</label>
                    <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="schedule"  class="form-check-inline" id='specificSchedule_{{i}}' (change)="setRadioBtn('yes','scaling','schedule',i)" checked>
                        <label class="value" for="specificSchedule_{{i}}">Specify start/end dates</label>
                      </div>
                      <!-- [checked]="list.get('schedule')?.value == false" -->
                      <div class="checkbox-cs">
                        <input type="radio" name="schedule" class="form-check-inline" id='repeatSchedule_{{i}}' (change)="setRadioBtn('no','scaling','schedule',i)" >
                        <label class="value" for="repeatSchedule_{{i}}">Repeat specific days</label>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3" *ngIf="!list.get('schedule').value">
                  <div class="flex-custom">
                    <input type="checkbox" name="monday_{{i}}" class="form-check-inline"  id='monday_{{i}}' formControlName="monday">
                    <label class="value mr-4" for="monday_{{i}}">monday</label>
                    <input type="checkbox" name="tuesday_{{i}}" class="form-check-inline"  id='tuesday_{{i}}' formControlName="tuesday">
                    <label class="value mr-4" for="tuesday_{{i}}">Tuesday</label>
                    <input type="checkbox" name="wednesday_{{i}}" class="form-check-inline"  id='wednesday_{{i}}' formControlName="wednesday">
                    <label class="value mr-4" for="wednesday_{{i}}">Wednesday</label>
                    <input type="checkbox" name="thursday_{{i}}" class="form-check-inline"  id='thursday_{{i}}' formControlName="thursday">
                    <label class="value mr-4" for="thursday_{{i}}">Thursday</label>
                    <input type="checkbox" name="friday_{{i}}" class="form-check-inline"  id='friday_{{i}}' formControlName="friday">
                    <label class="value mr-4" for="friday_{{i}}">Friday</label>
                    <input type="checkbox" name="saturday_{{i}}" class="form-check-inline"  id='saturday_{{i}}' formControlName="saturday">
                    <label class="value mr-5" for="saturday_{{i}}">Saturday</label>
                    <input type="checkbox" name="sunday_{{i}}" class="form-check-inline"  id='sunday_{{i}}' formControlName="sunday">
                    <label class="value" for="sunday_{{i}}">Sunday</label>
                  </div>
                </div>
                    
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Timezone</label>
                    <ng-select [items]="timeZoneList"  bindLabel="time" bindValue="name" name="timeZone_{{i}}" (change)="saveDisable=true" [clearable]="false" id="timeZone_{{i}}" formControlName="timeZone"></ng-select>
                  </div>
                  <div class="mb-3">
                    <div class="flex-custom">
                      <div class="mb-3 mr-3 width-300">
                        <label class="flex-custom no-wrap ">Start date</label>
                        <input  [owlDateTimeTrigger]="dt1" class="form-control full-width" (dateTimeChange)="setTime($event,'start',i)"  [owlDateTime]="dt1" placeholder="MM/DD/YYYY" formControlName="startDate">
                        <owl-date-time [pickerType]="'calendar'"  #dt1></owl-date-time>
                      </div>
                      <div class="mb-3 mt-12">
                        <input class="form-control width-300" type="text" autocomplete="off" (keyup)="saveDisable=true"  id="startTime_{{i}}" name="startTime_{{i}}" placeholder="h:mm:ss A" formControlName="startTime" />
                      </div>
                    </div>
                    <!-- <p class="text-danger" *ngIf="inputError?.endDateFormat">Date format must match 'MM/DD/YYYY' or 'YYYY-MM-DD'.</p> -->
                  </div>
                  <div class="mb-3">
                  <div class="flex-custom">
                    <div class="comm-mb mr-3 width-300">
                      <label class="flex-custom no-wrap ">End date</label>
                      <input [owlDateTimeTrigger]="dt2" class="form-control full-width" (dateTimeChange)="setTime($event,'end',i)" [owlDateTime]="dt2" placeholder="MM/DD/YYYY" formControlName="endDate">
                      <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                    </div>
                    <div class="mb-3 mt-12">
                      <input class="form-control width-300" type="text" autocomplete="off" id="endTime_{{i}}" name="endTime_{{i}}" placeholder="h:mm:ss A" formControlName="endTime" (keyup)="saveDisable=true" />
                    </div>
                  </div>
                  <!-- <p class="text-danger" *ngIf="inputError?.endDateFormat">Date format must match 'MM/DD/YYYY' or 'YYYY-MM-DD'.</p> -->
                </div>
                  <div class="mb-3" *ngIf="!defaultScaleCondition">
                    <label class="flex-custom no-wrap">Schedule</label>
                    <label class="flex-custom no-wrap bold-600">This scale condition is executed when none of the other scale condition(s) match</label>
                  </div>
                 </div>
                </ng-container>
                </ng-container>
                  <button class="btn-btn-secondary" (click)="addScaleCondition()">
                    <img src="/assets/governance/add-btn.svg" alt="">
                    Add a scale condition
                   </button>
                   <!-- *ngIf="!scaleCondition" -->
                </ng-container>             
 
             </form>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('scaling') || !saveDisable "(click)="saveAppServiceData('scaleOut')">Save</button>
           </div>
           <!-- || invalidForm('scaling') -->
        </div>
      </ng-container>

  </ng-container>


  <ng-template #appServiceDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">App Service</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" [class.disabled-icon]="disableStart" (click)="action(selectedRow,'startAppService')">Start</div>
            <div class="tab-item" [class.disabled-icon]="!disableStart" (click)="action(selectedRow,'restartAppService')">Restart</div>
            <div class="tab-item" [class.disabled-icon]="!disableStart" (click)="action(selectedRow,'stopAppService')">Stop</div>
            <div class="tab-item" (click)="openSidesheet('deployementSlots',selectedRow)">Deployment slots</div>
            <div class="tab-item" (click)="openSidesheet('configuration',selectedRow)">Configuration</div>
            <div class="tab-item" (click)="openSidesheet('scaleUp',selectedRow)">Scale up</div>
            <div class="tab-item" (click)="openSidesheet('scaleOut',selectedRow)">Scale out</div>
            <div class="tab-item" (click)="action(selectedRow,'deleteAppServiceData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedRow[i]:getTags(selectedRow[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedRow[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #appServiceActionConfirm>
    <div class="medium-popup-wrapper"  style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-btn-primary" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>