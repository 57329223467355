import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-stacked-common-time-line-chart',
  template: `<div echarts [options]="options" [loading]="true"></div>`,
})
export class StackedCommonTimeLineChartComponent implements OnChanges {
  options: any = {};
  @Input('data') data: any = [];
  constructor() { }

  ngOnChanges() {
    console.log(this.data)
    if(!this.data.series){
      this.data.series=[{
        data: this.data.yAxis,
        type: 'line',
    }]
    }
    var chartLabelColors = ['#7D3AC1', '#DB4CB2', '#19AADE', '#1DE4BD', '#FBB040'];
    var xAxisData=this.data.xAxis;
    var max=this.data.max&&this.data.xAxis.length>this.data.max?this.data.max:undefined;
    var xAxisFromatVal=this.data.xAxisFromatVal;
    this.options = {
      color: this.data.color,
      tooltip: {
        show: true,
      },
      legend: {
        show: this.data.showLegend || false,
        data: this.data.legendData,
        type: 'scroll',
        bottom: 0,
        left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.data.xAxis,
          max:max,
          axisLabel: {
            interval: 1,
            color: '#626F7B',
            padding: [8, 18, 0, 18],
            fontSize: 12, fontFamily: 'Inter',
            axisLine: {
              lineStyle: {
                color: '#8995A2',
              }
            },
            splitLine: {
              lineStyle: {
                color: '#8995A2',
              }
            },
            formatter: function (params,index) {
              if(!xAxisFromatVal&&(index==xAxisData.length-1)){
                return '(UTC)'
              }else{
                return params //+ '\n\n (UTC)'
              }
            }, 
          },
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
            formatter: function (params) {
              return params 
            },
          },
      },
      series: this.data.series
    };
  }

 
}
