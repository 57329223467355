<div class="overview-content">
    <div class="header-container">
        <h2 class="title">Data Size</h2>
    </div>

     <!-- Filter Section -->
     <div class="d-flex" [formGroup]="filterForm">
        <div class="custom-filter-wrapper flex-custom" style=" width: 100%;">
            <div class="custom-filter">
                <span class="text">Subscription</span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <div class="enlist-srch">
                        <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                        <img src="assets/icons/search.png">
                    </div>
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="custom-dropdown">
                <ng-select [items]="providerList" *ngIf="providerList.length" [searchable]="false" bindLabel="name"
                  bindValue="name" name="provider" formControlName="provider" [closeOnSelect]="true" [clearable]="false"
                  id="provider" (change)="changeFilter()">
                  <ng-template ng-label-tmp let-item="item">
                    Provider: {{item?.name}}
                  </ng-template>
                </ng-select>
              </div> -->
              <div class="custom-dropdown">
                <ng-select [items]="sidList" *ngIf="sidList.length" [searchable]="false" name="provider" formControlName="sid" [closeOnSelect]="true" [clearable]="false"
                  id="provider" (change)="changeFilter()">
                  <ng-template ng-label-tmp let-item="item">
                    SID: {{item}}
                  </ng-template>
                </ng-select>
            </div>
        </div>
        <ng-select [items]="dbTenantsList" [searchable]="false" bindLabel="name" bindValue="name" [closeOnSelect]="true" name="dbTenants" *ngIf="dbTenantsList.length" placeholder="DB tenats:" style="width: 200px;    padding-right: 12px;" (change)="filterTenantData()" [clearable]="false" formControlName="dbTenants" >
            <ng-template ng-label-tmp let-item="item">
                DB Tenants: {{item?.name}}
            </ng-template>
        </ng-select>
        <ng-select [items]="timeFilterList" *ngIf="timeFilterList.length" [searchable]="false" bindLabel="name" bindValue="id" [closeOnSelect]="true" (close)="filterForm.value.currentFilter=='custom'?openModal(openCal,null):''" name="timeRange" placeholder="Time Range:" style="width: 200px;" (change)="filterForm.value.currentFilter=='custom'?openModal(openCal,null):applyFilter(filterForm.value.currentFilter,null)" formControlName="currentFilter" [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
                Time Range: {{item?.name}}
            </ng-template>
        </ng-select>
    </div>
    
    <div class="row custom-padding">
        <div class="col-md-12 right" *ngIf="false">
            <label class="parameters-entry">Parameters entry</label>
            <div class="d-flex parameters-entry-form">
                <label>Prediction offset GiB<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Prediction offset GiB" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                <input type="text">
                <button><img src="/assets/monitor/SAPHANA/refresh.svg"></button>
            </div>
            <div class="d-flex parameters-entry-form">
                <label>Data growth rate GiB/day<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Data growth rate GiB/day" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                <input type="text">
                <button><img src="/assets/monitor/SAPHANA/refresh.svg"></button>
            </div>
            <div class="d-flex parameters-entry-form">
                <label>Global Allocation Limit GiB<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Global Allocation Limit GiB" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                <input type="text">
            </div>
            <div class="d-flex parameters-entry-form mb-4">
                <label>Projected Limit line %<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Projected Limit line %" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                <input type="text">
                <button><img src="/assets/monitor/SAPHANA/refresh.svg"></button>
            </div>

        </div>
        <div class="col-md-6 left" *ngIf="false">
            <div class="d-flex info-message mb-4">
                <img src="/assets/monitor/SAPHANA/info.svg">
                <span>Columnar data will reach chosen limit in -79 days, on 12-2021</span>
            </div>
        </div>
        <div class="col-md-6 right" *ngIf="false">
            <div class="d-flex info-message mb-4">
                <img src="/assets/monitor/SAPHANA/info.svg">
                <span>Total Memory consumption will reach global allocation limit in -3386 days, on (11-2012)</span>
            </div>
        </div>
        <div class="col-md-6 left" *ngIf="false">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Column Data Size (GiB)</h4>
                            <p>Refreshed: {{refreshedTimeFormat(columnDataSize?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('columnDataSize')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="loader-gif" *ngIf="columnDataSize?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!columnDataSize?.tableData?.length && !columnDataSize?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right" *ngIf="false">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Memory statistics (GiB)</h4>
                            <p>Refreshed: {{refreshedTimeFormat(memoryStatistics?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('memoryStatistics')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="loader-gif" *ngIf="memoryStatistics?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!memoryStatistics?.tableData?.length && !memoryStatistics?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <!-- <h4>HANA File Systems</h4> -->
                            <p>Refreshed: {{refreshedTimeFormat(dataSizeDetails?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('dataSizeDetails')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="dataSizeDetails?.tableData?.length && !dataSizeDetails?.showLoader">
                  <table class="table" id="dataSizeDetails">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Rank</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>TenantDB</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Table:[Partition Count]</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap table-column-header">
                                  <span>Row Count</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap table-column-header">
                                  <span>Data Size</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap table-column-header">
                                  <span>Loaded Data</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap table-column-header">
                                  <span>% of total size</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap table-column-header">
                                  <span>% of all loaded</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap table-column-header">
                                  <span>Daily row growth</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',4)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap table-column-header">
                                <span>Row Size Limit Status</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('dataSizeDetails',0)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of dataSizeDetails.tableData">
                            <tr>
                                <td>{{row[0]}}</td>
                                <td class="elipse-column" [title]="row[1]">{{row[1]}}</td>
                                <td class="elipse-column" [title]="row[3]">{{row[3]}}</td>
                                <td>{{row[4]}}</td>
                                <td>{{row[5]}}</td>
                                <td>{{row[6]}}</td>
                                <td>
                                    <div class="progress-div">
                                        <span>{{row[7]}}%</span>
                                        <span class="progress w-92 mb-2">
                                            <span class="progress-bar progress-bar-blue" [style.width]="row[7]+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div class="progress-div">
                                        <span>{{row[8]}}%</span>
                                        <span class="progress w-92 mb-2">
                                            <span class="progress-bar progress-bar-blue" [style.width]="row[8]+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>{{row[9]}}</td>
                                <td class="elipse-column" [title]="row[11]">{{row[11]}}</td>
                            </tr>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif" *ngIf="dataSizeDetails?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!dataSizeDetails?.tableData?.length && !dataSizeDetails?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>HANA File Systems</h4>
                            <p>Refreshed: {{refreshedTimeFormat(hanaFileSystems?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('hanaFileSystems')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="hanaFileSystems?.tableData?.length && !hanaFileSystems?.showLoader">
                  <table class="table" id="hanaFileSystems">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Host</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaFileSystems',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Usage</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaFileSystems',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Path</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaFileSystems',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Average Utilization</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaFileSystems',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Statistics</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaFileSystems',4)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Used Size Trendline</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaFileSystems',0)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of hanaFileSystems.tableData">
                            <tr>
                                <td>{{row[0]}}</td>
                                <td>{{row[1]}}</td>
                                <td>{{row[2]}}</td>
                                <td>
                                    <div class="progress-div">
                                        <span>{{row[3]}}%</span>
                                        <span class="progress w-92 mb-2">
                                            <span class="progress-bar progress-bar-success" [style.width]="row[3]+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    {{row[4]}} out of {{row[6]}} used
                                    <span class="progress progress-bar-mutliple w-92 mb-2">
                                        <span class="progress-bar progress-bar-used" [style.width]="(row[4]/row[6])*100+'%'"></span>
                                        <span class="progress-bar progress-bar-max" [style.width]="(row[5]/row[6])*100+'%'"></span>
                                        <span class="progress-bar progress-bar-sucess" [style.width]="100+'%'"></span>
                                    </span>
                                </td>
                                <td><img [src]="row[7]=='UP'?'assets/monitor/SAPHANA/up-trendline.svg':row[7]=='DOWN'?'assets/monitor/SAPHANA/down-trendline.svg':'assets/monitor/SAPHANA/equal-trendline.svg'"></td>
                            </tr>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif" *ngIf="hanaFileSystems?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!hanaFileSystems?.tableData?.length && !hanaFileSystems?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
</div>
  
<ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>