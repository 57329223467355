<div class="introduction">
   

    <p class="heading"  id="1">Users by Country </p>
    <p class="para mb-0">Shows the number of the user per country. X axis shows country name, Y axis show the number of users.   </p>
    <img src="assets\user-manual\apps-health-monitoring\Row.svg" >

    <figcaption class="caption">Usage by location </figcaption>
 
   

  
          
</div>