import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { TileData } from '../data-governance-overview/data-governance-overview.state';

export class DataGovernanceAccessManagementStateModel {
  roleAssignments: TileData;
  datasort?: string;
}

export class GetRoleAssignmentDataAction {
  static readonly type = '[DataGovernanceAccessManagement] getRoleAssignmentData';

  constructor(readonly workspace: string) { }
}

export class SortRoleAssignmentDataAction {
  static readonly type = '[DataGovernanceAccessManagement] sortRoleAssignmentData';

  constructor(readonly fieldName: string) { }
}

const DATA_GOVERNANCE_ACCESS_MANAGEMENT_STATE_TOKEN = new StateToken<DataGovernanceAccessManagementState>('dataGovernanceAccessManagement');

@State<DataGovernanceAccessManagementStateModel>({
  name: DATA_GOVERNANCE_ACCESS_MANAGEMENT_STATE_TOKEN,
  defaults: {
    roleAssignments: {
      value: [],
      refreshedTime: new Date()
    }
  }
})
@Injectable()
export class DataGovernanceAccessManagementState {
  constructor(private readonly apiService:ApiService,private http:HttpClient) {}

  @Selector([DataGovernanceAccessManagementState])
  static roleAssignments(state:DataGovernanceAccessManagementStateModel): TileData {
    return state?.roleAssignments
  }

  @Action(GetRoleAssignmentDataAction)
  getRoleAssignmentData(ctx: StateContext<DataGovernanceAccessManagementStateModel>, action: GetRoleAssignmentDataAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getRoleAssignmentsTileData', [action.workspace]);
    return this.http.get<TileData>(url)
    .pipe(
      tap((tileData:TileData) => {
        ctx.patchState({
          roleAssignments:{
            value:tileData.value,
            refreshedTime:new Date()
          }
        })
      }));
  }
  
  @Action(SortRoleAssignmentDataAction)
  sortRoleAssignmentData(ctx: StateContext<DataGovernanceAccessManagementStateModel>, action: SortRoleAssignmentDataAction) {
      let num = 0;
      (ctx.getState().datasort === 'desc') ? num = 1 : num = -1;
      let value = [...ctx.getState().roleAssignments.value];
      let fieldName=action.fieldName;
      value.sort((a, b) => {
        switch (fieldName) {
          case 'name': {
            let x = a.name ? a.name : ''
            let y = b.name ? b.name : ''
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          default: {
            let x = a[fieldName] ? a[fieldName] : ' ';
            let y = b[fieldName] ? b[fieldName] : ' ';
            return (x < y) ? num : ((x > y) ? -num : 0);
          }
        }
      })
      ctx.patchState({
        roleAssignments:{
          ...ctx.getState().roleAssignments,
          value
        },
        datasort: ctx.getState().datasort === 'desc' ? 'asc' : 'desc'
      })
  }
}
