<div class="introduction">
   
    <p class="heading" id="3" >Custom Query  </p>
    <p class="mb-0">A text box is provided for the user to write their own query and once written,
        the query can be run against the chosen resources to retrieve the data. </p>
        <img class="mt-0" src="assets\user-manual\infra-health-monitoring\4.svg" >
        <figcaption class="caption"> Insights - custom query  </figcaption>

    <ol>
        <li>Write the query in the text box. </li>
        <li>Select <b>Time range.</b>   </li>
        <img src="assets\user-manual\infra-health-monitoring\Custom-query-time.svg" >
        <figcaption class="caption">Insights - custom query time range </figcaption>
        <li>Select <b>Scope.</b> </li>
        <img src="assets\user-manual\infra-health-monitoring\5.svg" >
        <figcaption class="caption"> Insights - custom query scope  </figcaption>
        <li>Click <b>Run.</b>  </li>
        <li>Successful run of the query will return the data and will be displayed in the form of chart and/or table. </li>
        <img src="assets\user-manual\infra-health-monitoring\Custom-query-run.svg" >
        <figcaption class="caption">Insights - custom query chart view </figcaption>
        <li>In case the query fails, or the data is not available for the selected time range, the image below will be shown. </li>
        <img src="assets\user-manual\infra-health-monitoring\Customquery-nodata.svg" >
        <figcaption class="caption">Insights - custom query no data  </figcaption>
        <li>Toggle between <b>Chart</b> (graphical representation) and <b>Table</b> (information in rows and columns). </li>
        <img src="assets\user-manual\infra-health-monitoring\7.svg" >
        <figcaption class="caption">Insights - custom query table view  </figcaption>
        <li>Download the report using <b>Download CSV</b> button.  </li>
    </ol>
    

          
</div>
