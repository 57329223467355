<div class="arch-summary">
    <div class="header-container">
        <h2 class="title">{{data?.projectName | titlecase }}</h2>
        <span class="content-14 blur-text">Architecture Blueprint</span>
    </div>
    
    <div class="summary">
      <h4>Project Summary</h4>

          <div class="row outer-row">
              <div class="column col-lg-6 col-md-12 col-sm-12">
                  <div class="summary-line d-flex" *ngIf="data?.departmentName">
                    <div >Department </div>
                    <div class="colon">:</div>
                    <div class="value-label-val">{{data?.departmentName | displayTxt}}</div>
                  </div>
                  <div class="summary-line d-flex">
                      <div >Subscription </div>
                      <div class="colon">:</div>
                      <div class="value-label-val">{{data?.subscriptionName | displayTxt}}</div>
                  </div>
                  <!-- <div class="summary-line d-flex">
                      <div >Resource group </div>
                      <div class="colon">:</div>
                      <div class="value-label-val">{{data?.resourceGroup | displayTxt}}</div>
                  </div> -->
                  <div class="summary-line d-flex">
                    <div >Deployed on (UTC) </div>
                    <div class="colon">:</div>
                    <div class="value-label-val">{{data?.deploymentTime | date :'MM/dd/yyyy, hh:mm:ss a'}}</div>
                  </div>
                  <div class="summary-line d-flex">
                      <div >Status </div>
                      <div class="colon">:</div>
                      <div class="value-label-val">{{data?.status=="FAILED"?"Failed to deploy":data?.status | titlecase}}</div>
                  </div>
              </div>
          </div>
      
    </div>

    <div class="custom-fixed-tables">
      <!-- <h4 style="font-size: 14px;font-weight: 600;">Resource(s) deployed</h4> -->
        <table class="table fix-width">
            <thead>
              <tr>
                <th class="table-name">
                  <div class="flex-custom no-wrap">
                    <span>Resource name</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resource')">
                  </div>
                </th>
                <th class="status">
                  <div class="flex-custom no-wrap">
                    <span>Resource Group</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resourceGroup')">
                  </div>
                </th>
                <th class="status">
                    <div class="flex-custom no-wrap">
                      <span>Status</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deploymentResourceStatus')">
                    </div>
                </th>
                <th style="border-right: solid 1px #8CD4FD;">
                    <div class="flex-custom no-wrap">
                        <span>Deployed on (UTC)</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deploymentResourceTime')">
                    </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of tableList">
                <td>{{list?.resourceName | displayTxt}}</td>
                <td>{{list?.resourceGroup | displayTxt}}</td>
                <td><span *ngIf="list?.deploymentResourceStatus=='Succeeded'" class="success"><img src="assets/icons/success.png">Deployed successfully</span>
                  <span *ngIf="list?.deploymentResourceStatus=='Failed'" class="failed"><img src="assets/icons/fail.png">Failed to deploy</span></td>
                <td>{{list?.deploymentResourceTime | date :'MM/dd/yyyy, hh:mm:ss a'}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!tableList?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
    </div>

</div>


<div class="fixed-btm-btn">
    <!-- <button class="btn btn-outline" (click)="routeToListPage()"> <span>Back to projects list</span>  </button> -->
    <button class="btn btn-btn-primary" (click)="routeToDiagram()"> <span>View architecture</span>  </button>
</div>