<div class="introduction">
    <p class="heading" id="0">Security Posture </p>
            <p class="para">Security posture of a project is comprised of: </p>
            <ol>
                <li>Identity Security. </li>
                <li>Endpoint Protection. </li>
                <li>Activities.</li>
                <li>Application Security.  </li>
                <li>Network Security.</li>
            </ol>

          
</div>     
        
