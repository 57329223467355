import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute,NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectorModel, Diagram, DiagramComponent, DiagramConstraints, IExportOptions, NodeModel, PaletteModel, PrintAndExport, 
  SnapSettingsModel, SnapConstraints, SymbolPreviewModel, Snapping, GridlinesModel,Node,MarginModel,TextModel,ShapeAnnotationModel,
  AnnotationConstraints,NodeConstraints,ScrollSettingsModel,BezierSegmentModel,SymbolDragSizeModel } from '@syncfusion/ej2-angular-diagrams';
import { ExpandMode } from '@syncfusion/ej2-navigations';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { UtilService } from 'src/app/services/util.service';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from 'src/app/services/notification.service';
import { NeverActivate } from '../../../services/never-activate.guard';
import { Subscription } from 'rxjs';
import { ProgressNotificationMessage } from 'src/app/@model/model';
import { findIndex } from 'rxjs/operators';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { LoaderService } from 'src/app/services/loader.service';

Diagram.Inject(PrintAndExport);
Diagram.Inject(Snapping); 
@Component({
  selector: 'app-diagram-tool',
  templateUrl: './diagram-tool.component.html',
  styleUrls: ['./diagram-tool.component.scss']
})
export class DiagramToolComponent implements OnInit {
  projectData: any;
  toolGroups: any = [];
  // snapSettings: Object;
  resourcesGroupLocationList: any = []

  newResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
  }
  show : boolean = false;
  showpop: boolean = false;
  showCommentSection : boolean = false;
  viewVersionHistoryDropdown : boolean = false;
  hidden : boolean =true;
  showMore : boolean = false;
  fullList : boolean = false;
  reviewSubmittedCheck : boolean = false;
  currentTemplate: any;
  status: string;
  selectedItems:any= [];
  nodeList:any=[];
  changedDiagramContent:boolean=false;
  routingSubscription: Subscription;
  subscription: Subscription;
  isRouteAllowed: boolean = true;
  movingUrl: any;
  nodeValc:NodeModel;
  groupRd:any={};
  symbolMargin: MarginModel;
  deleteIconDisable:boolean=true;
  editSideSheetEnable:boolean=true;
  sideSheetViewType:string='new';
  currentResourceDtls:any;
  nodeSizeState:string;
  nodePositionState:string;
  paletteHidden : boolean = false;
  disableDeployBtn : boolean =false;
  paletteStatus : string = "Hide";
  symbolDragSize:SymbolDragSizeModel;
  savedNodeList:any=[];
  notificationObject = {
    show : false,
    style: null,
    text : null
  }
  user: string;
  datasort: string;
  versionHistory = {
    // title: null,
    description: null,
    data:null,
    projectId: null,
    createdBy: null
  }

  commentObject = {
    comment : null,
    createdBy: null,
    projectId: null
  }

  commentsList: any = [];
  versionHistoryList: any = [];
  overallPercentage: any = 0;
  deploymentStatus: any;
  parentResourceDtls:any;
  updateRequestFrom:any;
  subnetUpdateMsg:any;
  selectChildList:any=[];
  vmSizeList:any=[];
  dummyVMSizeList:any=[];
  dummyVMDiskSizeList:any=[];
  vmDiskSizeList:any=[];
  selectVmSizeData:any;
  public expandMode: ExpandMode;
  public palettes: PaletteModel[];
  @ViewChild("diagram") public diagram: DiagramComponent;
  @ViewChild("unableToUseVN") public unableToUseVN: TemplateRef<any>;
  @ViewChild("unableToUseSubnet") public unableToUseSubnet: TemplateRef<any>;
  @ViewChild("unsavedChanges") public unsavedChanges: TemplateRef<any>;
  @ViewChild("submitForReview") public submitForReview: TemplateRef<any>;
  @ViewChild("versionHistoryPopup") public versionHistoryPopup: TemplateRef<any>;
  @ViewChild("updateResourceDataAlert") public updateResourceDataAlert: TemplateRef<any>;
  @ViewChild("virtualMachineSizePopup") public virtualMachineSizePopup: TemplateRef<any>;
  @ViewChild('virtualMachineDiskSizePopup') public virtualMachineDiskSizePopup: TemplateRef<any>;
  @ViewChild('virtualMachineInboundRulePopup') public virtualMachineInboundRulePopup:TemplateRef<any>;
  @ViewChild('unableToUseGatewaySubnet') public unableToUseGatewaySubnet:TemplateRef<any>;
  @ViewChild('unableToUseVNG') public unableToUseVNG:TemplateRef<any>;
  @ViewChild('unableToUseVNGForGateway') public unableToUseVNGForGateway:TemplateRef<any>;
  @ViewChild('unableToUsepublicIP') public unableToUsepublicIP:TemplateRef<any>
  @ViewChild('unableToUsestorageAccount') public unableToUsestorageAccount:TemplateRef<any>
  @ViewChild('unableToUsenetworkInterface') public unableToUsenetworkInterface:TemplateRef<any>
  @ViewChild('unableToUsekeyVault') public unableToUsekeyVault:TemplateRef<any>
  @ViewChild('unableToUserouteTable') public unableToUserouteTable:TemplateRef<any>
  @ViewChild('unableToUseconnections') public unableToUseconnections:TemplateRef<any>
  @ViewChild('unableToUselocalNetworkGateway') public unableToUselocalNetworkGateway:TemplateRef<any>
  @ViewChild('unableToUseloadBalancer') public unableToUseloadBalancer:TemplateRef<any>
  @ViewChild('unableToUselogAnalytics') public unableToUselogAnalytics:TemplateRef<any>
  @ViewChild('unableToUseapplicationInsight') public unableToUseapplicationInsight:TemplateRef<any>
  @ViewChild('unableToUsedisk') public unableToUsedisk:TemplateRef<any>
  @ViewChild('unableToUsensg') public unableToUsensg:TemplateRef<any>
  @ViewChild("generateNewKeyPair") public generateNewKeyPair: TemplateRef<any>;
  public options: IExportOptions;
  public symbolPreview: SymbolPreviewModel[];
  commentToDeleteId: number = -1;
  diagramApproved: boolean = false;
  versionHistoryShortList: any;
  deploymentInterval: NodeJS.Timeout;
  progressInterval: NodeJS.Timeout;
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  vmSeriesList:any=this.util.getStatic('vmSizeList');
  diskSKUList:any=[
    {name:'Premium SSD',id:'Premium_LRS'},
    {name:'Standard SSD',id:'StandardSSD_LRS'},
    {name:'Standard HDD',id:'Standard_LRS'}
  ];
  vmSeries:any=this.vmSeriesList[0].name;
  vmDisk:any={
  diskSKU:this.diskSKUList[0],
  performanceTierList:[]
  };

  vmSourceList:any=[
    {name:'Any'},
    {name:'IP addresses'}
  ];

  destinationList:any=[
    {name:'Any'},
    {name:'IP addresses'},
    {name:'VirtualNetwork'}
  ];

  vmServiceList:any=[
    {name:'HTTP'},
    {name:'HTTPS'},
    {name:'SSH'},
    {name:'RDP'},
    {name:'MS SQL'},
    {name:'MySQL'},
    {name:'Custom'},
    {name:'DNS(TCP)'},
    {name:'DNS(UDP)'}
  ];

  vmInboundRule:any={
    source:'Any',
    sourcePortRange:'*',
    destination:'Any',
    service:'Custom',
    destinationPortRange:8080,
    protocol:'Any',
    action:'Allow',
    disableBasedOnService:false
  };

  availabilityOptionMainList:any=[
    {name:'No infrastructure redundancy required',id:'None'},
    {name:'Availablity zone',id:'availabilityZone'},
    {name:'Availability set',id:'availabilitySet'}
  ];
  availabilityOptionWithoutZoneList:any=[
    {name:'No infrastructure redundancy required',id:'None'},
    {name:'Availability set',id:'availabilitySet'}
  ];

  availabilityZoneList:any=[
    {name:'1'},
    {name:'2'},
    {name:'3'}
  ];

  inputError= {
    sourceNotValidPortVal : false,
    sourceInvalidPortRange:false,
    sourceOverlap:false,
    destNotValidPortVal:false,
    destInvalidPortRange:false,
    destOverlap:false,
    sourceInvalidArgument:false,
    destInvalidArgument:false,
    sourceNotValidCIDR:false,
    destNotValidCIDR:false,
    sourceOverlaping:false,
    destOverlaping:false,
    VMPriorityValid:false,
    VMPriorityMaxMin:false,
    nameLen:false,
    nameValid:false,
    VMdescription:false,
    VMPriorityDupCheck:false,
    nameDupCheck:false,
  }

  generateKeyPair:any=[];

// public symbolPreview: SymbolPreviewModel[];
// public getSymbolInfo(symbol) {
// //Defines the symbol description
//   return { width: 48, height: 12, description: { text: symbol.shape['shape'] } }
// };

  public getImageShapes(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Virtual Machine',
            width:40,height:40,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/VM.svg',
            }
        },
        {
            id: 'Virtual Network',
            width:40,height:40,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/virtualNetwork.svg',
            }
        },
        {
            id: 'Virtual WANs',
            width:40,height:40,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/images/Virtual-WANs.svg',
            }
        },
    ];
    return basicShapes;
  };

  public getNetworkingImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Virtual Network',
            width:100,height:100,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/virtualNetwork.svg',
            },
            style:{
              strokeColor :  'none',
              fill:'none'
            }
        },
        {
          id: 'Subnet',
          width:100,height:100,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/subnet.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Load Balancer',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/loadBalancers.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Application Gateways',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/applicationGateways.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Local Network Gateway',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/networkGateways.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Virtual Network Gateway',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/virtualNetworkGateways.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Network Watcher',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/networkWatcher.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Route Table',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/routeTables.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Network Interfaces',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/networkInterfaces.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Connections',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/connections.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Network Security Groups',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/networkSecurityGroups.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Public IP',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/PublicIP.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Azure Bastion',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/azureBastion.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Azure Firewall',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/azureFirewall.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
    ];
    return basicShapes;
  };
  public getDomainImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Azure AD Domain Network',
            width:100,height:100,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/azureAdDomain.svg',
            },
            style:{
              strokeColor :  'none',
              fill:'none'
            }
        },
        {
          id: 'Users',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/users.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Service Groups',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/serviceGroups.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'App Registrations',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/appRegistrations.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Enterprise Applications',
          width:140,height:140,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/enterpriseApplications.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Managed Identities',
          width:110,height:110,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/managedIdentities.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },

        
    ];
    return basicShapes;
  };
  public getVMImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Virtual Machine',
            width:100,height:100,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/VM.svg',
            },
            style:{
              strokeColor :  'none',
              fill:'none'
            }
        },
        {
          id: 'Windows Virtual Machine',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/windowsVM.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Linux Virtual Machine',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/linuxVirtualMachine.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Windows Virtual Desktop',
          width:100,height:100,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/windowsVD.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'SQL Virtual Machine',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/sqlVM.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        }, 
        {
          id: 'Availability Sets',
          width:100,height:100,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/availabilitySets.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'VM Scale Sets',
          width:100,height:100,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/VMScaleSets.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },        
    ];
    return basicShapes;
  };

  public getDatabasesImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Synapse Analytics',
            width:130,height:130,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/synapseAnalytics.svg',
            },
            style:{
              strokeColor :  'none',
              fill:'none'
            }
        },
        {
          id: 'Azure Cosmos DB',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/azureCosmosDB.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'SQL Database',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/sqlDatabase.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'SQL Elastic Pools',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/sqlElasticPools.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Managed Database',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/managedDatabase.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        }, 
        {
          id: 'SQL Data Warehouse',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/sqlDataWarehouses.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },        
    ];
    return basicShapes;
  };

  public getManagementAndGovernanceImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Automation Accounts',
            width:130,height:130,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/automationAccounts.svg',
            },
            style:{
              strokeColor :  'none',
              fill:'none'
            }
        },
        {
          id: 'Monitor',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/monitor.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Alerts',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/alerts.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Advisors',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/advisor.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Activity Log',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/activityLog.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        }, 
        {
          id: 'Log Analytics Workspace',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/logAnalyticsWorkspaces.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Application Insights',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/applicationInsights.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        }, 
        {
          id: 'Metrics',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/metrics.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },    
        {
          id: 'Policy',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/policy.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },       
    ];
    return basicShapes;
  };

  public getContainersImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Container Instance',
            width:130,height:130,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/containerInstance.svg',
            },
            style:{
              strokeColor : 'none',
              fill:'none'
            }
        },
        {
            id: 'Container Registry',
            width:130,height:130,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/containerReg.svg',
            },
            style:{
              strokeColor : 'none',
              fill:'none'
            }
        },
    ];
    return basicShapes;
  };

  public getSecurityImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Azure Sentinel',
            width:130,height:130,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/azureSentinel.svg',
            },
            style:{
              strokeColor :  'none',
              fill:'none'
            }
        },
        {
          id: 'Conditional Access',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/conditionalAccess.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Security Center',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/securityCenter.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Application Security Groups',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/applicationSecurityGroups.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Key Vault',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/keyVaults.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },         
    ];
    return basicShapes;
  };

  public getStorageImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'Storage Account',
            width:100,height:100,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/storageAccount.svg',
            },
            style:{
              strokeColor :  'none',
              fill:'none'
            }
        },
        {
          id: 'Data lake Storage',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/dataLakeStorage.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },
        {
          id: 'Disks',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/disks.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
        },                
    ];
    return basicShapes;
  };

  public getWebImages(): NodeModel[] {
    let basicShapes: NodeModel[] = [
        {
            id: 'App Services',
            width:130,height:130,offsetX:200, offsetY:200,
            shape: {
            type: 'Image',
            source: 'assets/Infra/blueprint/appServices.svg',
            },
            style:{
              strokeColor :  'none',
              fill:'none'
            }
        },
        {
          id: 'App Service Plan',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/appServicePlans.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'Static Apps',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/staticApps.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'CDN Profiles',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/CDNProfiles.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },
        {
          id: 'App Service Environments',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
                type: 'Image',
                source: 'assets/Infra/blueprint/appServiceEnvironments.svg',
          },
          style:{
            strokeColor : 'none',
            fill:'none'
          }
        },         
    ];
    return basicShapes;
  };

  public getMachineLearningImages(): (NodeModel | ConnectorModel)[] {
    let basicShapes: NodeModel[] = [
      {
          id: 'Machine Learning',
          width:130,height:130,offsetX:200, offsetY:200,
          shape: {
          type: 'Image',
          source: 'assets/Infra/blueprint/machineLearning.svg',
          },
          style:{
            strokeColor :  'none',
            fill:'none'
          }
      },         
  ];
  return basicShapes;
  }

  public segment:BezierSegmentModel[]=[{
    type:'Bezier'
  }];
  public getConnectors(): (NodeModel |ConnectorModel)[] {
      let connectorSymbols: ConnectorModel[] = [{
              id: 'Line',
              type: 'Straight',
              sourcePoint: {
                  x: 0,
                  y: 0
              },
              targetPoint: {
                  x: 36,
                  y: 36
              },
              targetDecorator: {
                  shape: 'None'
              },
              style: {
                fill: '#4AB5FB',
                strokeColor: '#4AB5FB',
              },
          },
          {
              id: 'Connector',
              type: 'Straight',
              sourcePoint: {
                  x: 0,
                  y: 0
              },
              targetPoint: {
                  x: 36,
                  y: 36
              },
              targetDecorator: {
                  shape: 'Arrow',
                  style: {
                    fill: '#4AB5FB',
                    strokeColor: '#4AB5FB',
                  }
              },
              style: {
                  fill: '#4AB5FB',
                  strokeColor: '#4AB5FB',
              },
          },{
            id: 'Bezier',
            type: 'Bezier',
            sourcePoint: {
                x: 0,
                y: 0
            },
            targetPoint: {
                x: 36,
                y: 36
            },
            segments:this.segment,
            targetDecorator: {
                shape: 'Arrow',
                style: {
                  fill: '#4AB5FB',
                  strokeColor: '#4AB5FB',
                }
            },
            style: {
                fill: '#4AB5FB',
                strokeColor: '#4AB5FB',
            },
        },
      ];
      return connectorSymbols;
  };

  public getShapes(): NodeModel[]{
    let shapes:NodeModel[]=[
      {
        id:'Rectangle',
        // Size of the node
        width: 350,
        height: 350,
        style: {
            fill: 'none',
           strokeColor: '#8CD4FD',
          strokeDashArray:"2,2",
           strokeWidth:2,
        },
        shape:{
          type: 'Basic',
          shape: 'Rectangle',
          cornerRadius: 6,
        }, 
      }
    ];
    return shapes
  }

  shapeAnnotation:ShapeAnnotationModel[]=[{
    annotationType:'String',
    content:'',
    constraints:AnnotationConstraints.ReadOnly
  }]

  public vmRectangleNode: NodeModel = {
    // Position of the node
    id:'vmRect',
    offsetX: 200,
    offsetY: 240,
    // Size of the node
    width: 350,
    height: 150,
    style: {
      // fill: '#6BA5D7',
       strokeColor: '#8CD4FD',
       strokeDashArray:"2,2",
       strokeWidth:2,
    },
    shape:{
      type: 'Basic',
      shape: 'Rectangle',
      cornerRadius: 6,
    },
    annotations:this.shapeAnnotation    
  };
  public subnetRectangleNode: NodeModel = {
    // Position of the node
    id:'subnetRect',
    offsetX: 110,
    offsetY: 255,
    // Size of the node
    width: 150,
    height: 75,
    style: {
      // fill: '#6BA5D7',
       strokeColor: '#8CD4FD',
       strokeDashArray:"2,2",
       strokeWidth:2,
    },
    shape:{
      type: 'Basic',
      shape: 'Rectangle',
      cornerRadius: 6,
    },
    annotations:this.shapeAnnotation
  };
  
  public textModel(content): TextModel {
    let textModel:TextModel= {
      type: 'Text',
      content: content,
    };
    return textModel;
  }

  public txtNode: NodeModel={
    id:'text',
    offsetX: 110,
    offsetY: 255,
    // Size of the node
    width: 100,
    height: 50,
    shape:this.textModel('Text Element'),
      style:{
        fill :  'none',
        strokeColor :  'none',
        color :  '#333B43',
        textAlign :  'Center',
      }
  }; 

  public snapSettings: SnapSettingsModel = {
    // hide both Horizontal and Vertical gridlines
    constraints:  SnapConstraints.None,
};

  public scrollSettings:ScrollSettingsModel={
    scrollLimit: 'Infinity',
  }

  constructor(private util:UtilService,private toastr: ToastrService, private _notificationService: NotificationService, 
    private route: ActivatedRoute, private modalService: NgbModal, private _snackBar: MatSnackBar,private routingService: NeverActivate, private router: Router, private loader:LoaderService) { 
    if (route.queryParams['value'].data && JSON.parse(route.queryParams['value'].data)) {
      this.projectData = JSON.parse(route.queryParams['value'].data);
      
      this.versionHistory.projectId = this.projectData.projectId;
      this.commentObject.projectId=this.projectData.projectId;
      this.status=this.projectData.status;
      console.log('projectData',this.projectData)
    }

    this.routingSubscription = this.routingService.isNavigationBlocked.subscribe(val => { this.isRouteAllowed = val });
    this.router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          this.movingUrl=event.url;
          this.open_route_block_popup();
        }
    });


    // let horizontalGridlines: GridlinesModel = {lineColor: 'red', lineDashArray: '1,1' };
    // let verticalGridlines: GridlinesModel = {lineColor: 'red', lineDashArray: '1,1'};
    // let diagram: Diagram = new Diagram({
    //   width: '100%', height: '570px',
    //   snapSettings: { horizontalGridlines, verticalGridlines, constraints: SnapConstraints.None,
    //   snapObjectDistance: 5, snapAngle: 5 },
    // });

    // setTimeout(() => {
    //   diagram.appendTo('#diagram');
    // }, 500);
    

  }

  open_route_block_popup() {
      if (!this.isRouteAllowed) {
        this.modalService.open(this.unsavedChanges);
      }
  }

  ngOnInit(): void {
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    this.user=get_Email.userName;
    this.resourcesGroupLocationList = this.util.getStatic('countryList');
    this.getVersionHistoryData(); 
    this.getAllComments();

    if(this.status=='UNDER REVIEW'){
      setTimeout(() => {
        this.diagram.constraints = DiagramConstraints.None;
      }, 500);
      this.reviewSubmittedCheck = true;
      this.notificationObject.show = true;
      this.notificationObject.style = 'brown';
      this.notificationObject.text = "Architecture blueprint is under review. Editing is disabled until review is complete";
      this.closeModal();
    }
    else if(this.status=='APPROVED'){
      setTimeout(() => {
        this.diagram.constraints = DiagramConstraints.None;
      }, 500);
      this.reviewSubmittedCheck = true;
      this.diagramApproved = true;
      this.notificationObject.show = true;
      this.notificationObject.style = 'blue';
      this.notificationObject.text = "Architecture blueprint is approved. Click deploy to start the deployment.";
      this.closeModal();
    }
    else if(this.status=='DEPLOYED'){
      this.reviewSubmittedCheck = true;
      this.diagramApproved = true
      this.deploymentStatus = 'success'
      setTimeout(() => {
        this.diagram.constraints = DiagramConstraints.None;
      }, 500);
    }
    else if(this.status=='FAILED'){
      this.reviewSubmittedCheck = true;
      this.diagramApproved = true
      this.deploymentStatus = 'failed'
      setTimeout(() => {
        this.diagram.constraints = DiagramConstraints.None;
      }, 500);
    }

    this.expandMode = 'Multiple'
    this.palettes = [
      {
          id: 'Shapes',
          expanded: false,
          symbols: [...this.getConnectors(), ...this.getShapes()],
          title: 'Connectors',
          iconCss: 'e-ddb-icons e-basic e-connector'
      },
      {
        id: 'image',
        expanded: true,
        // height:97,
        symbols: this.getNetworkingImages(),
        title: 'Networking',
        iconCss: 'e-ddb-icons'
      },
      {
        id: 'image2',
        expanded: false,
        // height:107,
        symbols: this.getDomainImages(),
        title: 'Identity',
        iconCss: 'e-ddb-icons'
      },
      {
        id: 'image3',
        expanded: false,
        // height:97,
        symbols: this.getVMImages(),
        title: 'Compute',
        iconCss: 'e-ddb-icons'
      },{
        id: 'image5',
        expanded: false,
        // height:97,
        symbols: this.getDatabasesImages(),
        title: 'Database',
        iconCss: 'e-ddb-icons'
      },{
        id: 'image6',
        expanded: false,
        // height:97,
        symbols: this.getManagementAndGovernanceImages(),
        title: 'Management & Governance',
        iconCss: 'e-ddb-icons'
      },{
        id: 'image7',
        expanded: false,
        // height:97,
        symbols: this.getContainersImages(),
        title: 'Containers',
        iconCss: 'e-ddb-icons'
      },{
        id: 'image8',
        expanded: false,
        // height:97,
        symbols: this.getSecurityImages(),
        title: 'Security',
        iconCss: 'e-ddb-icons'
      }, {
        id: 'image4',
        expanded: false,
        // height:100,
        symbols: this.getStorageImages(),
        title: 'Storage',
        iconCss: 'e-ddb-icons'
      },{
        id: 'image9',
        expanded: false,
        // height:97,
        symbols: this.getWebImages(),
        title: 'Web',
        iconCss: 'e-ddb-icons'
      },{
        id: 'image10',
        expanded: false,
        // height:97,
        symbols: this.getMachineLearningImages(),
        title: 'Machine Learning',
        iconCss: 'e-ddb-icons'
      },
    ],

    this.symbolPreview = [{
      height: 100,
      width: 100
    }]
    this.symbolMargin = {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    };
    this.symbolDragSize={
      height:34,
      width:34
    };
  }

  ngAfterViewInit(){
    // load blueprint if exist
     if(this.projectData.blueprintData){
       this.diagram.appendTo('#diagram');
       this.diagram.loadDiagram(this.projectData.blueprintData)
       let {nodeList,groupRd}=JSON.parse(this.projectData.blueprintData);
       if(nodeList){
       this.nodeList=nodeList;
       Object.assign(this.savedNodeList,nodeList);
       }
       if(groupRd){
        this.groupRd=groupRd;
       }
     }
     //  Changing the palatte search placeholder
     setTimeout(() => {
      $('#symbolpalette_search #textEnter').attr('placeholder','Search resources')
      $('#symbolpalette_search #textEnter').attr('autocomplete','off')
      $('#symbolpalette_search #iconSearch').addClass('e-icons')
     }, 100);
    
  }
  public getSymbolInfo(symbol) {
    //Defines the symbol description
    //symbol.style.fontSize=10;
    if(symbol.shape){
      if(symbol.id.includes('Azure')){
        return { width:90, height:100  } // description: { text: symbol.id.length>15?symbol.id.substring(0,15)+'...':symbol.id }
      }else if(symbol.id.includes('Windows Virtual')){
        return { width:80, height:90 }
      }else{
        return { width:80, height:90 }
      }
    }else{
      return {width:20,height:10} // description:{text:symbol.id}
    }
    //return { width: 48, height: 48, description: { text: "hello" } }
  };

  routeBack(){
      this.util.route(['/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList'],true);
  }

  dragEnter(event){
    console.log('dragEnter');
    console.log(event);
    if(this.ignoreActions(event)) return;
    if(event.element instanceof Node){
      if(event.element.id.includes('Virtual Network')||event.element.id.includes('Subnet')){
        event.element.width=34;
        event.element.height=34;
        event.element.maxWidth=34;
        event.element.maxHeight=34;
        event.element.minWidth=34;
        event.element.minHeight=34;
      }else{
        // event.element.maxWidth=event.element.width;
        // event.element.maxHeight=event.element.height;
        // event.element.minWidth=event.element.width;
        // event.element.minHeight=event.element.height;
        }
      }
    let checkNode = this.checkResourceEligibleToDrop(event);
    // if not VNet
    if(!checkNode){
      this.diagram.remove(event.element);
      switch(event.element.oldProperties.id){
        case "Windows Virtual Machine" :
        case "Linux Virtual Machine" :
        case "SQL Virtual Machine" :
        case 'Virtual Machine' : return  this.modalService.open(this.unableToUseSubnet)
        case 'Subnet' : return  this.modalService.open(this.unableToUseVN)
        case 'Public IP' : return  this.modalService.open(this.unableToUsepublicIP)
        case 'Network Interface' : return  this.modalService.open(this.unableToUsenetworkInterface)
        case 'Key Vault' : return  this.modalService.open(this.unableToUsekeyVault)
        case 'Route Table' : return  this.modalService.open(this.unableToUserouteTable)
        case 'Connections' : return  this.modalService.open(this.unableToUseconnections)
        case 'Local Network Gateway' : return  this.modalService.open(this.unableToUselocalNetworkGateway)
        case 'Load Balancer' : return  this.modalService.open(this.unableToUseloadBalancer)
        case 'Storage Account' : return  this.modalService.open(this.unableToUsestorageAccount)
        case 'Log Analytics Workspace' : return  this.modalService.open(this.unableToUselogAnalytics)
        case 'Application Insights' : return  this.modalService.open(this.unableToUseapplicationInsight)
        case 'Disks' : return  this.modalService.open(this.unableToUsedisk)
        case 'Network Security Groups' : return  this.modalService.open(this.unableToUsensg)
      }
      return;
    }
    this.addNodeRuntime(event);
    this.diagram.updateViewPort();
  }

  dragLeave(event){
    console.log('dragLeave');
    this.removeEventElements(event.element);
    this.diagram.updateViewPort();
  }

  drop(event){
    console.log('drop');
    console.log(event);
    this.parentResourceDtls={};
     if(this.ignoreActions(event)) return;

     const idVal=event.element.id.split(`${event.element.id.substring(event.element.id.length-5,event.element.id.length)}`);
     if(this.diagram.nodes&&idVal[0]!=='Virtual Network'&&!event.element.id.includes('Machine Learning')&&!event.element.id.includes('Synapse Analytics')){
      let res=this.diagram.nodes.find(val=>{
        if(val.id=='subnetRect'+val.id.substring(val.id.length-5)&&idVal[0]!=='Virtual Network'&&!event.element.id.includes('Subnet')){
          if(val.wrapper.bounds.top<event.element.wrapper.bounds.top&&val.wrapper.bounds.bottom>event.element.wrapper.bounds.bottom){
            if(val.wrapper.bounds.left<event.element.wrapper.bounds.left&&val.wrapper.bounds.right>event.element.wrapper.bounds.right){
              event.element.maxWidth=event.element.width;
              event.element.maxHeight=event.element.height;
              event.element.minWidth=event.element.width;
              event.element.minHeight=event.element.height;
              this.groupRd['virtualNetwork'].find(dt=>{
                if(dt.subnet){
                  this.parentResourceDtls=dt.subnet.find(dtl=>dtl.subnetId==('Subnet'+val.id.substring(val.id.length-5)));
                  if(this.parentResourceDtls){
                    return true;
                  }
                }
              })
              return true;
              }
            }
        }else if(val.id=='vmRect'+val.id.substring(val.id.length-5)&&event.element.id.includes('Subnet')){
          if(val.wrapper.bounds.top<event.element.wrapper.bounds.top&&val.wrapper.bounds.bottom>event.element.wrapper.bounds.bottom){
            if(val.wrapper.bounds.left<event.element.wrapper.bounds.left&&val.wrapper.bounds.right>event.element.wrapper.bounds.right){
                this.parentResourceDtls=this.groupRd['virtualNetwork'].find(dt=>dt.vnetId==('Virtual Network'+val.id.substring(val.id.length-5)))
                if(this.parentResourceDtls){
                  return true;
                }
              }
            }
        }
      })
      if(!res&&!event.element.id.includes('Subnet')){
       
        if(event.element.id.includes('Subnet')){
          //this.diagram.select([event.element],false);
        this.modalService.open(this.unableToUseSubnet);
        //this.delete();
        }else if(event.element.id.includes('Public IP')){
          this.modalService.open(this.unableToUsepublicIP);
          this.delete();
        }else if(event.element.id.includes('Network Interface')){
            this.modalService.open(this.unableToUsenetworkInterface);
            this.delete();
        }else if(event.element.id.includes('Key Vault')){
            this.modalService.open(this.unableToUsekeyVault);
            this.delete();
        }else if(event.element.id.includes('Route Table')){
            this.modalService.open(this.unableToUserouteTable);
            this.delete();
        }else if(event.element.id.includes('Connections')){
            this.modalService.open(this.unableToUseconnections);
            this.delete();
        }else if(event.element.id.includes('Local Network Gateway')){
            this.modalService.open(this.unableToUselocalNetworkGateway);
            this.delete();    
        }else if(event.element.id.includes('Load Balancer')){
              this.modalService.open(this.unableToUseloadBalancer);
              this.delete();
        }else if(event.element.id.includes('Storage Account')){
              this.modalService.open(this.unableToUsestorageAccount);
              this.delete();
        }else if(event.element.id.includes('Network Security Groups')){
              this.modalService.open(this.unableToUsensg);
              this.delete();
        }else if(event.element.id.includes('Log Analytics Workspace')){
               this.modalService.open(this.unableToUselogAnalytics);
               this.delete();
              }else if(event.element.id.includes('Disk')){
          this.modalService.open(this.unableToUsedisk);
          this.delete();
        }else if(event.element.id.includes('Application Insights')){
               this.modalService.open(this.unableToUseapplicationInsight);
               this.delete();
        } else if(idVal[0]==='Virtual Network Gateway'){
          this.modalService.open(this.unableToUseVNG);
          this.delete();
        } else{
          this.modalService.open(this.unableToUseVN);
          this.delete();
        }
        return;
      } 
     }else if(idVal[0]==='Virtual Network'||event.element.id.includes('Machine Learning')||event.element.id.includes('Synapse Analytics')){
      this.parentResourceDtls=this.groupRd['virtualNetwork'];
     }

     if(this.parentResourceDtls&&this.parentResourceDtls.resourceName=='GatewaySubnet'&&event.element.id.includes('Virtual Machine')){
      this.modalService.open(this.unableToUseGatewaySubnet);
      this.delete();
      return ;
     } else if (this.parentResourceDtls&&this.parentResourceDtls.resourceName!=='GatewaySubnet'&&event.element.id.includes('Virtual Network Gateway')) {
      this.modalService.open(this.unableToUseVNG);
      this.delete();
      return ;
     } else if (this.parentResourceDtls&&this.parentResourceDtls.resourceName==='GatewaySubnet'&&event.element.id.includes('Virtual Network Gateway')&&this.parentResourceDtls.devices&&this.parentResourceDtls.devices.find(dt=>dt.resourceType&&dt.resourceType==='virtualNetworkGateways')) {
      this.modalService.open(this.unableToUseVNGForGateway);
      this.delete();
      return ;
     }

     let checkNode = this.checkResourceEligibleToDrop(event);
    // // if not VNet
     if(checkNode){
    this.setTemplate(event)
     }
    setTimeout(() => {
        this.diagram.constraints = DiagramConstraints.None;
    }, 1000);
    this.diagram.updateViewPort();
  }

  checkResourceEligibleToDrop(event){
    console.log(event)
    console.log(this.diagram)
    console.log(this.diagram.nodes)
    let checkNode ;
    switch(event.element.oldProperties.id){
       case 'Subnet': checkNode = this.diagram.nodes.some(e=> e.id.includes('Virtual Network'));break;
       case 'Virtual Machine':
       case 'Windows Virtual Machine': checkNode = this.diagram.nodes.some(e=> e.id.includes('Virtual Network')) && this.diagram.nodes.some(e=> e.id.includes('Subnet'));break;
       default : checkNode = true;
    }
    console.log(checkNode)
    return checkNode;
  }

  addNodeRuntime(event){
    console.log(event.element.id.substring(event.element.id.length-5));

    switch(event.element.oldProperties.id){
      case 'Virtual Network' : 
        const vmRect=this.vmRectangleNode;
        vmRect.id=vmRect.id+event.element.id.substring(event.element.id.length-5);
        vmRect.offsetX=event.element.offsetX+170;
        vmRect.offsetY=event.element.offsetY+76.5;
        this.diagram.add(vmRect,true);
        this.vmRectangleNode.id='vmRect';
        break;
      case 'Subnet' :
        const subRect=this.subnetRectangleNode;
        subRect.id=subRect.id+event.element.id.substring(event.element.id.length-5);
        subRect.offsetX=event.element.offsetX+65;
        subRect.offsetY=event.element.offsetY+37;
        this.diagram.add(subRect,true);
        this.subnetRectangleNode.id='subnetRect';
        break;
    }

    // setTimeout(() => {
    //   this.diagram.appendTo('#diagram');
    // }, 500);
   
  }

  removeNodeRuntime(selectedItems){
    console.log('Diagram',this.diagram);
    
    if(selectedItems&&selectedItems.length>0){
      selectedItems.map(item=>{
        this.removeEventElements(item);        
      });
    }
  }

  removeEventElements(item:any){
    let elementID;
        if(["connectors"].indexOf(item.propName) > -1 || ["Rectangle"].indexOf(item.shape.basicShape) > -1 ) elementID = item.id
        else elementID=item.id.substring(item.id.length-5);
        switch(item.id){ //event.actualObject.oldProperties.id
          case 'Virtual Network'+elementID : {
            let node = this.diagram.getNodeObject('vmRect'+elementID);
            return  this.diagram.remove(node);
          }
          case 'Subnet'+elementID : {
            let node = this.diagram.getNodeObject('subnetRect'+elementID);
            return  this.diagram.remove(node);
          }
          case 'vmRect'+elementID:{
            let node = this.diagram.getNodeObject('Virtual Network'+elementID);
            return  this.diagram.remove(node);
          }
          case 'subnetRect'+elementID:{
            let node = this.diagram.getNodeObject('Subnet'+elementID);
            return  this.diagram.remove(node);
          }
          default:
            break;
        }
  }

  ignoreActions(event){
    return ["connectors"].indexOf(event.element.propName) > -1 ? true : false;
  }

  setTemplate(event){
    this.currentTemplate = null;
    switch(event.element.oldProperties.id){
        case "Virtual Network" : this.currentTemplate = 'virtualNetworks';break;
        case "Windows Virtual Machine" :
        case "Virtual Machine" : this.currentTemplate = 'virtualMachines';break;
        case "Linux Virtual Machine" : this.currentTemplate = 'linuxVirtualMachines';break;
        case "SQL Virtual Machine" : this.currentTemplate = 'sqlVirtualMachines';break;
        case "Subnet" : this.currentTemplate = 'subnets';break;
        case "Azure AD Domain Network" : this.currentTemplate = 'azureADDomain';break;
        case "Storage Account" : this.currentTemplate = 'storageAccounts';break;
        case "Windows Virtual Desktop" : this.currentTemplate = 'windowsVirtualDesktop';break;
        case "Network Interfaces" : this.currentTemplate = 'networkInterfaces';break;
        case "Key Vault" : this.currentTemplate = 'keyVaults';break;
        case "Route Table" : this.currentTemplate = 'routeTables';break;
        case "Connections" : this.currentTemplate = 'connections';break;
        case "Local Network Gateway" : this.currentTemplate = 'localNetworkGateways';break;
        case "Virtual Network Gateway" : this.currentTemplate = 'virtualNetworkGateways';break;
        case "Load Balancer" : this.currentTemplate = 'loadBalancers';break;
        case "Public IP" : this.currentTemplate = 'publicIPAddress';break;
        case "Log Analytics Workspace" : this.currentTemplate = 'logAnalyticWorkspaces';break;
        case "Application Insights" : this.currentTemplate = 'applicationInsights';break;
        case "Disks" : this.currentTemplate = 'disks';break;
        case "Network Security Groups" : this.currentTemplate = 'networkSecurityGroups';break;
        case "Availability Sets" : this.currentTemplate = 'availabilitySets';break;
        case "Machine Learning" : this.currentTemplate = 'machineLearningWorkspaces';break;
        case "Synapse Analytics" : this.currentTemplate = 'synapseAnalyticsWorkspaces';break;
        default : this.toastr.error('No template found')
    }
    this.currentTemplate ?  (this.sideSheetViewType='new',this.show = true) : this.show = false;
  }

  selectionChange(event){
    console.log(event)
    if(event && event.type == "Removal") this.show = false
  }

  collectionChange(event){
    console.log(event)
    this.changedDiagramContent=true;
    this.routingService.update_routing_status(false); // blocked routing
    if(event && event.type == "Removal") this.show = false
  }

  download(){
    this.options = {};
    this.options.mode = 'Download';
    this.options.format = 'SVG';
    this.diagram.exportDiagram(this.options);
  }

  replicate(){
    let data = this.diagram.saveDiagram()
    console.log('Data',data)
    // this.diagramCopy.loadDiagram(data)
  }

  editSideSheet(){    
    let selectItems=this.selectChildList&&this.selectChildList.length?this.selectChildList:this.selectedItems;
    this.currentResourceDtls=this.groupRd['virtualNetwork'].find(ds=>ds.vnetId==selectItems[0].id);
    this.currentTemplate = null;
    if(this.currentResourceDtls){
    this.currentTemplate = this.currentResourceDtls.resourceType?this.currentResourceDtls.resourceType:'virtualNetwork';
    this.parentResourceDtls=this.groupRd['virtualNetwork'].length>1?this.groupRd['virtualNetwork']:[];
    }else{
      this.groupRd['virtualNetwork'].find(dt=>{
        this.currentResourceDtls=dt.subnet.find(ds=>ds.subnetId==selectItems[0].id);
        if(this.currentResourceDtls){
          this.parentResourceDtls=dt;
          this.parentResourceDtls['updateRequestFrom']=_.cloneDeep(this.updateRequestFrom);
          this.updateRequestFrom='';
          this.currentTemplate = this.currentResourceDtls.resourceType?this.currentResourceDtls.resourceType:'subnet';
          return true;
        }else{
         let res= dt.subnet.find(ds=>{
           if(ds.devices){
            this.currentResourceDtls=ds.devices.find(dd=>dd.id==selectItems[0].id);
            if(this.currentResourceDtls){
              this.parentResourceDtls=dt;
              this.currentTemplate = this.currentResourceDtls.resourceType?this.currentResourceDtls.resourceType:'virtualMachine';//need to change
              return true;
            }
          }
          });
          if(res){
            return true;
          }
        }
      })
    //  
    }
    this.sideSheetViewType='edit';
    this.currentTemplate ?  this.show = true : this.show = false;
  }

  delete(){
    let selected = this.selectedItems;
    this.deleteResourcesByResourceIDs(selected);
    this.removeNodeRuntime(selected);
    setTimeout(() => {
      this.diagram.remove();
    }, 500);
   
    console.log('Diagram',this.diagram)
    console.log('Current selected',selected)
    this.deleteIconDisable=true;
    setTimeout(() => {
        this.diagram.constraints = DiagramConstraints.Default;
    }, 100);
    this.diagram.updateViewPort();
  }

  deleteResourcesByResourceIDs(selectedItems){
    if(selectedItems&&selectedItems.length>0&&this.nodeList.length>0){
      let rescourceIDs;
      selectedItems.map(item=>{
        let res;
        let elmLast=item.id.substring(item.id.length-5);
        if(item.id==('vmRect'+elmLast)){
          res=this.nodeList.filter(e => e.nodeId.includes('Virtual Network'+elmLast));
        }else if(item.id==('subnetRect'+elmLast)){
          res=this.nodeList.filter(e => e.nodeId.includes('Subnet'+elmLast));
        }

        let index=this.nodeList.findIndex(ds=>res&&res[0]?ds.nodeId==res[0].nodeId:ds.nodeId==item.id);
        if(index!=null&&index!=undefined&&index>-1){
        rescourceIDs=rescourceIDs?rescourceIDs+","+this.nodeList[index].resourceId:this.nodeList[index].resourceId;
        if(this.groupRd['virtualNetwork']){
          let indexG=this.groupRd['virtualNetwork'].findIndex(ds=>res?ds.vnetId==res[0].nodeId:ds.vnetId==item.id);
          if(indexG>-1){
            if(this.groupRd['virtualNetwork'][indexG].subnet){
              this.groupRd['virtualNetwork'][indexG].subnet.forEach(el => {
                if(el.devices){
                  el.devices.forEach(dt=>{
                    let indexN=this.nodeList.findIndex(ds=>ds.resourceId==dt.resourceId);
                    if(indexN!=null&&indexN!=undefined&&indexN>-1){
                    rescourceIDs=rescourceIDs?rescourceIDs+","+this.nodeList[indexN].resourceId:this.nodeList[indexN].resourceId;
                    this.diagram.remove(this.diagram.getNodeObject(dt.id));
                    this.nodeList.splice(indexN,1);
                    }
                  })
                }
                let indexN=this.nodeList.findIndex(ds=>ds.resourceId==el.resourceId);
                  if(indexN!=null&&indexN!=undefined&&indexN>-1){
                  rescourceIDs=rescourceIDs?rescourceIDs+","+this.nodeList[indexN].resourceId:this.nodeList[indexN].resourceId;
                  this.diagram.remove(this.diagram.getNodeObject(el.subnetId));
                  this.nodeList.splice(indexN,1);
                  this.diagram.remove(this.diagram.getNodeObject('subnetRect'+el.subnetId.substring(el.subnetId.length-5)));
                  }
              });
            }
            this.groupRd['virtualNetwork'].splice(index,1);
          }else{
            this.groupRd['virtualNetwork'].find((el,i) => {
              if(el.subnet){
                let indexS=el.subnet.findIndex(ds=>res?ds.subnetId==res[0].nodeId:ds.subnetId==item.id);
                if(indexS>-1){
                  if(el.subnet[indexS].devices){
                    el.subnet[indexS].devices.forEach(dt=>{
                      let indexN=this.nodeList.findIndex(ds=>ds.resourceId==dt.resourceId);
                      if(indexN!=null&&indexN!=undefined&&indexN>-1){
                      rescourceIDs=rescourceIDs?rescourceIDs+","+this.nodeList[indexN].resourceId:this.nodeList[indexN].resourceId;
                      this.diagram.remove(this.diagram.getNodeObject(dt.id));
                      this.nodeList.splice(indexN,1);
                      }
                    })
                  }
                  this.groupRd['virtualNetwork'][i].subnet.splice(indexS,1);
                  return true;
                }else{
                  let resD=el.subnet.find((elm,n)=>{
                    if(elm.devices){
                      let indexD=elm.devices.findIndex(ds=>res?ds.id==res[0].nodeId:ds.id==item.id);
                      if(indexD>-1){
                        this.groupRd['virtualNetwork'][i].subnet[n].devices.splice(indexD,1);
                        return true;
                      }
                    }
                  })
                  if(resD){
                    return true;
                  }
                }
              }
            });
          }
        }
        this.nodeList.splice(index,1);
        }
        // let data=Object.keys(this.groupRd);
        // data.map(dt=>{
        //   if(dt.includes(elmLast)){
        //     delete this.groupRd[dt];
        //   }
        // })
      });
      if(rescourceIDs){
      this.util.handleRequest('delete','a3s_architectureBlueprint_deleteResourcesByResourceIDs',[rescourceIDs],null,null,null,true).then(res=>{
        console.log('delete resource',res);
        })
      }
    }
  }

  addGroupData(node,resourceDtl){
    if(!resourceDtl){
      let res;
        let elmLast=node.id.substring(node.id.length-5);
        if(node.id==('vmRect'+elmLast)){
          res=this.nodeList.filter(e => e.nodeId.includes('Virtual Network'+elmLast));
        }else if(node.id==('subnetRect'+elmLast)){
          res=this.nodeList.filter(e => e.nodeId.includes('Subnet'+elmLast));
        }

        let index=this.nodeList.findIndex(ds=>res&&res[0]?ds.nodeId==res[0].nodeId:ds.nodeId==node.id);
        if(index!=null&&index!=undefined&&index>-1){
          resourceDtl=this.nodeList[index];
        }
        if(!resourceDtl){
          return false;
        }
    }
    let parentId,parentSubnetId;
     this.diagram.nodes.find((val:any,k)=>{
       if(val.id.includes('vmRect')){
         if(val.wrapper.bounds.top<node.wrapper.bounds.top&&val.wrapper.bounds.bottom>node.wrapper.bounds.bottom){
           if(val.wrapper.bounds.left<node.wrapper.bounds.left&&val.wrapper.bounds.right>node.wrapper.bounds.right){
             parentId=val.id;
           }
         }
       }
       
       if(val.id.includes('subnetRect')){
         if(val.wrapper.bounds.top<node.wrapper.bounds.top&&val.wrapper.bounds.bottom>node.wrapper.bounds.bottom){
           if(val.wrapper.bounds.left<node.wrapper.bounds.left&&val.wrapper.bounds.right>node.wrapper.bounds.right){
            // this.groupRd[args.element.id].subnetId=val.id;   
             parentSubnetId=val.id;   
             let index=this.groupRd['virtualNetwork'].findIndex(dt=>dt.vnetId.substring(dt.vnetId.length-5)==parentId.substring(parentId.length-5));
            if(index>-1){
              let indexSub=this.groupRd['virtualNetwork'][index]['subnet'].findIndex(dt=>dt.subnetId.substring(dt.subnetId.length-5)==parentSubnetId.substring(parentSubnetId.length-5));
              if(index>-1){
                if(!this.groupRd['virtualNetwork'][index]['subnet'][indexSub]['devices']){
                  this.groupRd['virtualNetwork'][index]['subnet'][indexSub]['devices']=[];
                }
                if(!(this.groupRd['virtualNetwork'][index]['subnet'][indexSub]['devices'].find(dt=>dt.id==node.id))){
                  let splitArr=node.id.split(node.id.substring(node.id.length-5));
                  this.groupRd['virtualNetwork'][index]['subnet'][indexSub]['devices'].push({
                    id: node.id,
                    resourceName:resourceDtl.resourceName,
                    resourceId :resourceDtl.resourceId,
                    resourceData:resourceDtl.resourceData,
                    type:splitArr[0],
                    resourceType:resourceDtl.resourceType
                  });
                }
              }
            }         
           }
         }
       }
       if(!parentSubnetId&&parentId){
        if(node.id.includes('Subnet'+node.id.substring(node.id.length-5))){
          let index=this.groupRd['virtualNetwork'].findIndex(dt=>dt.vnetId.substring(dt.vnetId.length-5)==parentId.substring(parentId.length-5));
          if(index>-1){
            if(!this.groupRd['virtualNetwork'][index]['subnet']){
              this.groupRd['virtualNetwork'][index]['subnet']=[];
            }
            if(!(this.groupRd['virtualNetwork'][index]['subnet'].find(dt=>dt.subnetId==node.id))){
              this.groupRd['virtualNetwork'][index]['subnet'].push({
                subnetId:  node.id,
                resourceName:resourceDtl.resourceName,
                resourceId :resourceDtl.resourceId,
                resourceData:resourceDtl.resourceData
              });
            //  let indx=this.diagram.nodes.findIndex(dt=>dt.id==parentId);
            //  let indx1=this.diagram.nodes.findIndex(dt=>dt.id=='subnetRect'+node.id.substring(node.id.length-5));
              //let indx2=this.diagram.nodes.findIndex(dt=>dt.id==node.id); 
              //this.diagram.addChildToGroup(this.diagram.nodes[indx],this.diagram.nodes[indx1]);
              //this.diagram.addChildToGroup(this.diagram.nodes[indx],this.diagram.nodes[indx2]);
              // this.diagram.nodes[indx2].maxHeight=48;
              // this.diagram.nodes[indx2].maxWidth=48;
             // this.diagram.addProcess(this.diagram.nodes[indx1],parentId);
              return true;
            }else if((this.groupRd['virtualNetwork'][index]['subnet'].find(dt=>dt.subnetId==node.id))){
              // let indx1=this.diagram.nodes.findIndex(dt=>dt.id=='subnetRect'+node.id.substring(node.id.length-5)); 
              // this.diagram.nodes[indx1].offsetX=node.offsetX;
              // this.diagram.nodes[indx1].offsetY=node.offsetY;
            }
          }
        }
       }
     });
     if(!parentId&&!parentSubnetId){
      if(!this.groupRd['virtualNetwork']){
        this.groupRd['virtualNetwork']=[];
      }
      if(!(this.groupRd['virtualNetwork'].find(dt=>dt.vnetId==node.id))){//&&node.id.includes('Virtual Network')
        this.groupRd['virtualNetwork'].push({
          vnetId: node.id,
          resourceName:resourceDtl.resourceName,
          resourceId :resourceDtl.resourceId,
          resourceData:resourceDtl.resourceData
        });
      }
    }
  }

  dropTest(event){
    console.log(this.nodeList)
    console.log(event)
    setTimeout(() => {
      console.log(this.diagram)
      let nodeLen=this.diagram.nodes.length;
      let shapeAnnotationModel:ShapeAnnotationModel[]=[{
        annotationType:'String',
        content:'Policy',
        constraints:AnnotationConstraints.ReadOnly,
        margin:{
          top:30
        },
        style:{
          color:'#333B43',
          textWrapping: 'NoWrap',
          fontSize:12,
          textAlign:'Center'
        }
      }];
      this.diagram.addNodeLabels(this.diagram.nodes[nodeLen-1], shapeAnnotationModel);
    }, 1000);
   
  }

  submit(event){
    this.updateRequestFrom='';
    this.selectVmSizeData=null;
    this.selectChildList=[];
    if(event.type == 'close'&&this.sideSheetViewType=='new') {
      this.delete();
    }else{
      let projectId=this.versionHistory.projectId;
      if(event.type=='update'){
        this.show = false;
        let res=event.data;
        if(event.type=='update'&&res&&this.diagram.nodes&&this.diagram.nodes.length){
          let indexN=this.nodeList.findIndex(dt=>dt.resourceId==res.resourceId);
          if(indexN>-1){
            this.nodeList[indexN].resourceName=res.resourceName;
            let index=this.diagram.nodes.findIndex(dt=>dt.id==this.nodeList[indexN].nodeId);
            if(index>-1&&this.diagram.nodes[index] instanceof Node){
              // let shapeAnnotationModel:ShapeAnnotationModel[]=[{
              //   annotationType:'String',
              //   content:res.resourceName,
              //   constraints:AnnotationConstraints.ReadOnly,
              //   margin:{
              //     top:25
              //   },
              //   style:{
              //     color:'#333B43',
              //     fontSize:12,
              //     textAlign:'Center'
              //   }
              // }];
              this.diagram.nodes[index].annotations[0].content=res.resourceName;
              // let nodeObj:any=this.diagram.nodes[index];
              // this.diagram.removeLabels(nodeObj,this.diagram.nodes[index].annotations);
              // this.diagram.addNodeLabels(this.diagram.nodes[index], shapeAnnotationModel);
           }
          }

          let indexG=this.groupRd['virtualNetwork'].findIndex(dt=>dt.resourceId==res.resourceId);
          if(indexG>-1){
            let subnetUpdate=false;
            if(this.groupRd['virtualNetwork'][indexG].resourceName!=res.resourceName||
            JSON.parse(this.groupRd['virtualNetwork'][indexG].resourceData).resourceGroup!=JSON.parse(res.resourceData).resourceGroup||
            JSON.stringify(JSON.parse(this.groupRd['virtualNetwork'][indexG].resourceData).virtualNetworkAddressSpace)!=JSON.stringify(JSON.parse(res.resourceData).virtualNetworkAddressSpace)){
              subnetUpdate=true;
            }
            this.groupRd['virtualNetwork'][indexG].resourceName=res.resourceName;
            this.groupRd['virtualNetwork'][indexG].resourceData=res.resourceData;
            if(this.groupRd['virtualNetwork'][indexG].subnet&&subnetUpdate){
              this.selectChildList=[];
              let subnetList=_.cloneDeep(this.groupRd['virtualNetwork'][indexG].subnet);
              this.subnetUpdateMsg=subnetList.map(dt=>dt.resourceName);
              let findObj:any=this.diagram.nodes.find(dt=>dt.id==this.groupRd['virtualNetwork'][indexG].subnet[0].subnetId);
              if(findObj){
               // this.selectedItems=[];
              //  this.selectedItems.push(findObj);
               // this.diagram.unSelect(this.selectedItems[0]);
               this.selectChildList.push(findObj);
               //this.diagram.clearSelection();
               //this.diagram.select([findObj]);
                this.updateRequestFrom='virtualNetwork';
                this.openModal(this.updateResourceDataAlert);
              }
            }

          }else{
            this.groupRd['virtualNetwork'].find((dt,index)=>{
              let indexS=dt.subnet.findIndex(dts=>dts.resourceId==res.resourceId);
              if(indexS>-1){
                this.groupRd['virtualNetwork'][index].subnet[indexS].resourceName=res.resourceName;
                this.groupRd['virtualNetwork'][index].subnet[indexS].resourceData=res.resourceData;
                if(((dt.subnet.length-1>indexS+1&&dt.subnet.length-1!=indexS+1)||dt.subnet.length-1==indexS+1)&&res['updateRequestFrom']){
                  let findObj:any=this.diagram.nodes.find(dt=>dt.id==this.groupRd['virtualNetwork'][index].subnet[indexS+1].subnetId);
                  if(findObj){
                   this.selectChildList=[];
                    this.subnetUpdateMsg.splice(indexS,1);
                    //this.diagram.unSelect(this.selectedItems[0]);
                    this.selectChildList.push(findObj);
                       //this.selectedItems.push(findObj);
                        this.updateRequestFrom='virtualNetwork';
                      // this.openModal(this.updateResourceDataAlert);
                      setTimeout(() => {
                        this.editSideSheet();
                      }, 100);
                     
                  }
                }else{
                  this.updateRequestFrom=false;
                  this.selectChildList=[];
                }
                return true;
              }else{
                dt.subnet.find((dtd,indexM)=>{
                  let indexD=dtd.devices.findIndex(dts=>dts.resourceId==res.resourceId);
                  if(indexD>-1){
                    this.groupRd['virtualNetwork'][index].subnet[indexM].devices[indexD].resourceName=res.resourceName;
                    this.groupRd['virtualNetwork'][index].subnet[indexM].devices[indexD].resourceData=res.resourceData;
                    return true;
                  }
                });
              }
            })
          }
          setTimeout(() => {
            this.diagram.constraints = DiagramConstraints.Default;
          }, 100);


        }
       
      }else if(event.type=='save'){
      this.util.handleRequest('get','a3s_architectureBlueprint_getProjectResourceList',[projectId],null,null,null,false).then(res=>{
          console.log('res',res);
          if(res&&res.length>0&&this.diagram.nodes&&this.diagram.nodes.length){
            let nodeLen=this.diagram.nodes.length;
            this.nodeList.push({
              nodeId:this.diagram.nodes[nodeLen-1].id,
              resourceName:res[res.length-1].resourceName,
              resourceId:res[res.length-1].resourceId
            });
            if(this.diagram.nodes[nodeLen-1] instanceof Node){
              let shapeAnnotationModel:ShapeAnnotationModel[]=[{
                annotationType:'String',
                content:res[res.length-1].resourceName,
                constraints:AnnotationConstraints.ReadOnly,
                margin:{
                  top:25
                },
                style:{
                  color:'#333B43',
                  fontSize:12,
                  textAlign:'Center'
                }
              }];
              this.diagram.addNodeLabels(this.diagram.nodes[nodeLen-1], shapeAnnotationModel);
              
              this.addGroupData(this.diagram.nodes[nodeLen-1],res[res.length-1]);
          }
          }
          this.show = false
          setTimeout(() => {
            this.diagram.constraints = DiagramConstraints.Default;
          }, 100);
        })
      }else if(event.type=='vmSize'){
        this.vmSizeList=event.vmSizeList.filter(dt=>dt.resourceType=='virtualMachines');
        this.vmSizeListFilter();
        this.currentResourceDtls=event.data;
        this.openModal(this.virtualMachineSizePopup);
      }else if(event.type=='newDisk' || event.type=='tempDisk' ){
        this.vmDiskSizeList=event.vmSizeList.filter(dt=>dt.resourceType=='disks');
        this.currentResourceDtls=event.data;
        this.vmDiskSizeListFilter();
        this.openModal(this.virtualMachineDiskSizePopup);
      }else if(event.type=='inboundRules'||event.type=='outboundRules'){
        this.currentResourceDtls=event.data;
        this.vmInboundRule={
          source:'Any',
          sourcePortRange:'*',
          destination:'Any',
          service:'Custom',
          destinationPortRange:8080,
          protocol:'Any',
          action:'Allow',
          disableBasedOnService:false
        };
        this.destinationList=[];
        this.vmSourceList=[];
        if(event.type=='inboundRules'){
          this.vmSourceList=[
            {name:'Any'},
            {name:'IP addresses'}
          ];
        
          this.destinationList=[
            {name:'Any'},
            {name:'IP addresses'},
            {name:'VirtualNetwork'}
          ];
        }else if(event.type=='outboundRules'){
          this.vmSourceList=[
            {name:'Any'},
            {name:'IP addresses'},
            {name:'VirtualNetwork'}
          ];
          this.destinationList=[
            {name:'Any'},
            {name:'IP addresses'}
          ];
        }
        this.vmInboundRule.type=event.type;
        if(this.currentResourceDtls.updateIndex!=undefined){
          this.vmInboundRule=_.cloneDeep(event.type=='inboundRules'?this.currentResourceDtls.vmInBoundRule[this.currentResourceDtls.updateIndex]:this.currentResourceDtls.vmOutBoundRule[this.currentResourceDtls.updateIndex]);
        }
        this.openModal(this.virtualMachineInboundRulePopup);
      }else{
        this.show = false;
        setTimeout(() => {
          this.diagram.constraints = DiagramConstraints.Default;
        }, 100);
      }
    }
     
    }

    selectVmSize(list){      
      let findIndex=this.vmSizeList.findIndex(dt=>dt.activeRow==true);
      if(findIndex>-1){
        this.vmSizeList[findIndex].activeRow=false;
      }
      list.activeRow=true;
      this.selectVmSizeData=list;
    }

    selectDiskSize(list,flag){
      let findIndex=this.vmDiskSizeList.findIndex(dt=>dt.activeRow==true);
      if(findIndex>-1){
        this.vmDiskSizeList[findIndex].activeRow=false;
      }
      list.activeRow=true;
      this.vmDisk.selectVmDiskSizeData=list;
      if(flag){
      this.vmDisk.customDiskSizeAdd=list.capabilities[0].value;
      }
      this.vmDisk.performanceTierList=[];
      if(this.vmDisk.diskSKU.id=='Premium_LRS'){
        let filterList;
        if(parseInt(list.capabilities[0].value)<8192){
          filterList=this.dummyVMDiskSizeList.filter(dt=>(parseInt(dt.capabilities[0].value)>parseInt(list.capabilities[0].value)||parseInt(dt.capabilities[0].value)==parseInt(list.capabilities[0].value))&&parseInt(dt.capabilities[0].value)<8192)
        }else {
          filterList=this.dummyVMDiskSizeList.filter(dt=>(parseInt(dt.capabilities[0].value)>parseInt(list.capabilities[0].value)||parseInt(dt.capabilities[0].value)==parseInt(list.capabilities[0].value))&&(parseInt(dt.capabilities[0].value)>4096))
        }
        this.vmDisk.performanceTierList=filterList.length>1?filterList:[];
      } 
    }

    customDiskSizeChange(){
      if(this.vmDisk.customDiskSizeAdd){
        if(parseInt(this.vmDisk.customDiskSizeAdd)>parseInt(this.dummyVMDiskSizeList[this.dummyVMDiskSizeList.length-1].capabilities[0].value)){
          this.vmDisk.customDiskSizeAdd=parseInt(this.dummyVMDiskSizeList[this.dummyVMDiskSizeList.length-1].capabilities[0].value);
        }else if(parseInt(this.vmDisk.customDiskSizeAdd)<1){
          this.vmDisk.customDiskSizeAdd=1;
        }
        let findObj=this.dummyVMDiskSizeList.find(dt=>parseInt(dt.capabilities[0].value)>parseInt(this.vmDisk.customDiskSizeAdd)||dt.capabilities[0].value==this.vmDisk.customDiskSizeAdd);
        this.selectDiskSize(findObj,false);
      }else if(this.vmDisk.customDiskSizeAdd==0){
        this.vmDisk.customDiskSizeAdd=1;
      }
    }

    selectedVmList(){
      this.currentResourceDtls.resourceData.selectedVMSize=this.selectVmSizeData;
      if(this.currentResourceDtls.resourceData&&this.currentResourceDtls.resourceData.selectedVMSize){
        let findObj=this.currentResourceDtls.resourceData.selectedVMSize.restrictions.find(dt=>dt.type=='Zone');
        if(findObj&&findObj.restrictionInfo&&findObj.restrictionInfo.zones){
          this.currentResourceDtls.resourceData.tempAvailabilityZoneList=this.availabilityZoneList.filter(dt=>{
            let findIndex=findObj.restrictionInfo.zones.findIndex(zd=>zd==dt.name);
            if(findIndex<0){
              return true;
            }
          });
        }else{
          this.currentResourceDtls.resourceData.tempAvailabilityZoneList=this.availabilityZoneList;
        }
      }else{
        this.currentResourceDtls.resourceData.tempAvailabilityZoneList=this.availabilityZoneList;
      }
      this.currentResourceDtls.resourceData.availabilityZone=undefined;
      if(this.currentResourceDtls.resourceData.tempAvailabilityZoneList.length>0){
        this.currentResourceDtls.resourceData.availabilityOptionList=this.availabilityOptionMainList;
      }else{
        this.currentResourceDtls.resourceData.availabilityOptionList=this.availabilityOptionWithoutZoneList;
        this.currentResourceDtls.resourceData.availabilityOption=this.availabilityOptionWithoutZoneList[0].id;
      }
    }

    sortVMSizeData(value) {
      let num = 0;
      (this.datasort === 'desc') ? num = 1 : num = -1;
      this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
      let sorted = this.dummyVMSizeList;
      sorted.sort((a, b) => {
          switch (value) {
              case 'vmSize': {
                  let x = a.size.toLowerCase();
                  let y = b.size.toLowerCase();
                  return (x < y) ? num : ((x > y) ? -num : 0)
              }
              case 'vCPUs': {
                  let x = a.newCapabilities.vCPUs.value.toLowerCase();
                  let y = b.newCapabilities.vCPUs.value.toLowerCase();
                  return (x < y) ? num : ((x > y) ? -num : 0)
              }
              case 'RAM': {
                  let x = a.newCapabilities.MemoryGB.value.toLowerCase();
                  let y = b.newCapabilities.MemoryGB.value.toLowerCase();
                  return (x < y) ? num : ((x > y) ? -num : 0)
              }
              case 'dataDisk': {
                  let x = a.newCapabilities.MaxDataDiskCount.value.toLowerCase();
                  let y = b.newCapabilities.MaxDataDiskCount.value.toLowerCase();
                  return (x < y) ? num : ((x > y) ? -num : 0)
              }
              case 'maxIOPS': {
                  let x = a.newCapabilities.UncachedDiskIOPS&&a.newCapabilities.UncachedDiskIOPS.value?a.newCapabilities.UncachedDiskIOPS.value.toLowerCase():'';
                  let y = b.newCapabilities.UncachedDiskIOPS&&b.newCapabilities.UncachedDiskIOPS.value?b.newCapabilities.UncachedDiskIOPS.value.toLowerCase():'';
                  return (x < y) ? num : ((x > y) ? -num : 0)
              }
              default: {
                  return;
              }
          }
      })
      this.dummyVMSizeList = sorted;
  }

    selectedVMDisk(){
      this.vmDisk.customDiskSize=this.vmDisk.customDiskSizeAdd;
      this.currentResourceDtls.newVMDiskSize=this.vmDisk;
    }

    vmSizeListFilter(){
        //let splitArr=this.vmSeries.split(' ');
        let sizeList=this.vmSeriesList.find(dt=>dt.name==this.vmSeries).sizeList;
        this.dummyVMSizeList=this.vmSizeList.filter(dt=>{
          let findObj=dt.restrictions.find(ds=>ds.type=="Location");
          if(!findObj){
            dt.newCapabilities={};
            dt.newCapabilities.vCPUs=dt.capabilities.find(ds=>ds.name=='vCPUs');
            dt.newCapabilities.MemoryGB=dt.capabilities.find(ds=>ds.name=='MemoryGB');
            dt.newCapabilities.MaxDataDiskCount=dt.capabilities.find(ds=>ds.name=='MaxDataDiskCount');
            dt.newCapabilities.UncachedDiskIOPS=dt.capabilities.find(ds=>ds.name=='UncachedDiskIOPS');
            // if(splitArr.length>1&&dt.size[0]==splitArr[0][0]&&splitArr[1]==dt.size.split('_')[1]){
            //   return dt;
            // }else if(splitArr.length==1&&dt.size[0]==splitArr[0][0]){
            //   return dt;
            // }
            if(sizeList.includes(dt.size)){
              if(!dt.newCapabilities.UncachedDiskIOPS&&dt.newCapabilities.MaxDataDiskCount&&dt.newCapabilities.MaxDataDiskCount.value){
                dt.newCapabilities.UncachedDiskIOPS={
                  name:"UncachedDiskIOPS",
                  value:parseInt(dt.newCapabilities.MaxDataDiskCount.value)*500
                }
              }
              return dt;
            }
          }
        });
        this.vmDisk.selectVmDiskSizeData=undefined;
        this.vmDisk.customDiskSize=undefined;   
    }

    vmDiskSizeListFilter(){
      this.vmDisk.customDiskSizeAdd=undefined; 
      this.vmDisk.performanceTier=undefined; 
      let findIndex=this.vmDiskSizeList.findIndex(dt=>dt.activeRow==true);
      if(findIndex>-1){
        this.vmDiskSizeList[findIndex].activeRow=false;
      }
        this.dummyVMDiskSizeList=this.vmDiskSizeList.filter(dt=>{
          if(dt.name==this.vmDisk.diskSKU.id){
            if(this.currentResourceDtls.sourceType!='Snapshot'&&this.currentResourceDtls.sourceType!='snapshot'){
              return true;
            }else if((this.currentResourceDtls.sourceType=='Snapshot'||this.currentResourceDtls.sourceType=='snapshot')&&this.currentResourceDtls.snapshot
            &&this.currentResourceDtls.snapshot.properties&&this.currentResourceDtls.snapshot.properties.diskSizeGB){
              if(dt.capabilities[0].value==this.currentResourceDtls.snapshot.properties.diskSizeGB||dt.capabilities[0].value>this.currentResourceDtls.snapshot.properties.diskSizeGB){
              return true;
              }
            }else{
              return true;
            }
          }
        });
        let num = -1;
        // (this.datasort === 'desc') ? num = 1 : num = -1;
        let sorted = this.dummyVMDiskSizeList;
        sorted.sort((a, b) => {
          let x = Number(a.capabilities[0].value);
          let y = Number(b.capabilities[0].value);
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.dummyVMDiskSizeList = sorted; 
    }

    validNICAddressSpace(event,flag){
      let value=event.target.value;
      this.inputError[flag+'InvalidArgument']=false;//sourceInvalidArgument
      this.inputError[flag+'NotValidCIDR']=false;//sourceNotValidCIDR
      this.inputError[flag+'Overlaping']=false;//sourceOverlaping
      this.inputError[flag+'NonNullRange']=false;

      if(value&&value!=""){
      const splitArComma=value.split(',');
      let ipV4Value;
      let tempArr=[];
      for(let i=0;i<splitArComma.length;i++){
        if(!splitArComma[i]){
         // let inputError="Invalid argument: 'A non-null address range is required.'";
          this.inputError[flag+'NonNullRange']=true;
          break;
        }else{
          const splitArr=splitArComma[i].split('/');
          if(splitArr.length>1&&!splitArr[1]){
            //Maliform
            this.inputError[flag+'NotValidCIDR']=true;
          }else{
            ipV4Value=splitArr[0];
          }
          
          try{
            let ipV4Obj=new this.ipV4(ipV4Value);
           // item.VNetAdrspc=false;
            let vlIPRange;
            if(splitArr.length>1){
            try{
              vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
              //item.VNetAdrspc=false;
              if(vlIPRange.input!=splitArComma[i]){
                //item.notValidCIDR=true;
                //item.validCIDRValue=vlIPRange.input;
                this.inputError[flag+'NotValidCIDR']=true;
                break;
              }else{
                //item.notValidCIDR=false;
                //item.validCIDRValue=null;
                for(let t=0;t<tempArr.length;t++){
                  if(tempArr[t].includes("/")){
                    let split2=tempArr[t].split('/');
                    let range=new this.ipSubnetWork(new this.ipV4(split2[0]),split2[1]);
                    if(splitArr.length==1){
                      let splitIPv4=ipV4Value.split('.');
                      if(ipV4Value==range.input){
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }
                      if(splitIPv4[0]>range.range.left.parts[0]&&splitIPv4[0]<range.range.right.parts[0]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]>range.range.left.parts[1]&&splitIPv4[1]<range.range.right.parts[1]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]>range.range.left.parts[2]&&splitIPv4[2]<range.range.right.parts[2]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]==range.range.left.parts[2]&&splitIPv4[3]>range.range.left.parts[3]&&splitIPv4[3]<range.range.right.parts[3]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }
                    }else{
                      if(vlIPRange.input==range.input){
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }
                      if(range.range.left.parts[0]>vlIPRange.range.left.parts[0]&&range.range.right.parts[0]<vlIPRange.range.right.parts[0]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]>vlIPRange.range.left.parts[1]&&range.range.right.parts[1]<vlIPRange.range.right.parts[1]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]>vlIPRange.range.left.parts[2]&&range.range.right.parts[2]<vlIPRange.range.right.parts[2]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]==vlIPRange.range.left.parts[2]&&range.range.left.parts[3]>vlIPRange.range.left.parts[3]&&range.range.right.parts[3]<vlIPRange.range.right.parts[3]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }
                    }
                  }else if(vlIPRange&&(vlIPRange.range.left.input==tempArr[t]||vlIPRange.range.right.input==tempArr[t])){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(splitArr.length==1&&(ipV4Value==tempArr[t])){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                }
                if(this.inputError[flag+'Overlaping']){
                  return false;
                }
                tempArr.push(splitArComma[i]);
              }
            }catch(e){
              //item.VNetAdrspc=true;
              this.inputError[flag+'NotValidCIDR']=true;
              break;
            }
          }else{
            for(let t=0;t<tempArr.length;t++){
                  if(tempArr[t].includes("/")){
                    let split2=tempArr[t].split('/');
                    let range=new this.ipSubnetWork(new this.ipV4(split2[0]),split2[1]);
                    if(splitArr.length==1){
                      let splitIPv4=ipV4Value.split('.');
                      if(ipV4Value==range.input){
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }
                      if(splitIPv4[0]>range.range.left.parts[0]&&splitIPv4[0]<range.range.right.parts[0]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]>range.range.left.parts[1]&&splitIPv4[1]<range.range.right.parts[1]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]>range.range.left.parts[2]&&splitIPv4[2]<range.range.right.parts[2]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]==range.range.left.parts[2]&&splitIPv4[3]>range.range.left.parts[3]&&splitIPv4[3]<range.range.right.parts[3]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }
                    }else{
                      if(vlIPRange.input==range.input){
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }
                      if(range.range.left.parts[0]>vlIPRange.range.left.parts[0]&&range.range.right.parts[0]<vlIPRange.range.right.parts[0]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]>vlIPRange.range.left.parts[1]&&range.range.right.parts[1]<vlIPRange.range.right.parts[1]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]>vlIPRange.range.left.parts[2]&&range.range.right.parts[2]<vlIPRange.range.right.parts[2]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]==vlIPRange.range.left.parts[2]&&range.range.left.parts[3]>vlIPRange.range.left.parts[3]&&range.range.right.parts[3]<vlIPRange.range.right.parts[3]){
                        //overlapping
                        this.inputError[flag+'Overlaping']=true;
                        break;
                      }
                    }
                  }else if(vlIPRange&&(vlIPRange.range.left.input==tempArr[t]||vlIPRange.range.right.input==tempArr[t])){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(splitArr.length==1&&(ipV4Value==tempArr[t])){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                }
                if(this.inputError[flag+'Overlaping']){
                  return false;
                }
            tempArr.push(ipV4Value);
          }        
          }catch(e){
         //   item.VNetAdrspc=true;
         this.inputError[flag+'InvalidArgument']=true;
            break;
          }
        }
      }
    }
    }

    portRangeValidation(event,limit,flag){
      let value=event.target.value;
      
      this.inputError[flag+'InvalidPortRange']=false; 
      this.inputError[flag+'Overlap']=false;
      this.inputError[flag+'NotValidPortVal']=false;
      if(value&&value!=""){
        if(value.includes(',-')||value.includes('-,')){
          this.inputError[flag+'NotValidPortVal']=true;
          return;
        }else if(value[value.length-1]==","||value[value.length-1]=="-"){
          this.inputError[flag+'InvalidPortRange']=true;
        }else if(value!="*"&&(!(/^[0-9,-]*$/).test(value))){
          this.inputError[flag+'NotValidPortVal']=true;
        }else{
          let splitArr=value.split(',');
          if(splitArr.length==1){
            let splitArr1=splitArr[0].split('-');
            if(splitArr1.length>1&&(!splitArr1[1]||parseInt(splitArr1[0])>parseInt(splitArr1[1])||splitArr1[0]==splitArr1[1])){
              //Invalid port range specified
              this.inputError[flag+'InvalidPortRange']=true;
              return;
            }else if(splitArr1.length==1&&parseInt(splitArr1[0])>limit){
              this.inputError[flag+'InvalidPortRange']=true;
              return;
            }else if(splitArr1.length>1&&splitArr1[1]&&parseInt(splitArr1[1])>limit){
              this.inputError[flag+'InvalidPortRange']=true;
              return;
            }
          }else if(splitArr.length>1&&!splitArr[1]){
            //Invalid port range specified
            this.inputError[flag+'InvalidPortRange']=true;
            return;
          }else {
            let tempList=[];
            for(let i=0;i<splitArr.length;i++){
              let splitArr1=splitArr[i].split('-');
              if(splitArr1.length>1&&(!splitArr1[1]||parseInt(splitArr1[0])>parseInt(splitArr1[1])||splitArr1[0]==splitArr1[1])){
                //Invalid port range specified
                this.inputError[flag+'InvalidPortRange']=true;
                break;
              }else {//if(splitArr1.length==1){
               this.inputError[flag+'InvalidPortRange']=false; 
               this.inputError[flag+'Overlap']=false;
                for(let j=0;j<tempList.length;j++){
                  if(!(tempList[j] instanceof Array)){
                  if(splitArr1[0]==tempList[j]){
                    this.inputError[flag+'Overlap']=true;
                    break;
                  }else if(splitArr1[1]==tempList[j]){
                    this.inputError[flag+'Overlap']=true;
                    break;
                  }else if(parseInt(splitArr1[0])<parseInt(tempList[j])&&splitArr1[1]&&parseInt(splitArr1[1])>parseInt(tempList[j])){
                    this.inputError[flag+'Overlap']=true;
                    break;
                  }
                }else if((tempList[j] instanceof Array)){
                    if(splitArr1[0]==tempList[j][0]||(tempList[j][1]&&splitArr1[0]==tempList[j][1])){
                    this.inputError[flag+'Overlap']=true;
                    break;
                    }else if(splitArr1[1]&&(splitArr1[1]==tempList[j][0]||(tempList[j][1]&&splitArr1[1]==tempList[j][1]))){
                    this.inputError[flag+'Overlap']=true;
                    break;
                    }else if(splitArr1[0]&&parseInt(splitArr1[0])>parseInt(tempList[j][0])&&parseInt(splitArr1[0])<parseInt(tempList[j][1])){
                    this.inputError[flag+'Overlap']=true;
                    break;
                    }else if(splitArr1[1]&&parseInt(splitArr1[1])<parseInt(tempList[j][0])&&parseInt(splitArr1[1])>parseInt(tempList[j][1])){
                    this.inputError[flag+'Overlap']=true;
                    break;
                    }else{
                      this.inputError[flag+'Overlap']=false;
                    }
                  }else{
                    this.inputError[flag+'Overlap']=false;
                  }
                }
                if((splitArr1[0]&&parseInt(splitArr1[0])>limit)||(splitArr1[1]&&parseInt(splitArr1[1])>limit)){
                  this.inputError[flag+'NotValidPortVal']=true;
                }
              tempList.push(splitArr1.length>1?splitArr1:splitArr1[0]);
              if(this.inputError[flag+'Overlap']||this.inputError[flag+'InvalidPortRange']||this.inputError[flag+'NotValidPortVal']){
                 return false;
               }
              }

            }
          }
          this.inputError[flag+'NotValidPortVal']=false;
        }
      }

    }

    disableInOutBound(){
      return !this.vmInboundRule.name||!this.vmInboundRule.priority||!this.vmInboundRule.destinationPortRange||!this.vmInboundRule.sourcePortRange
      ||this.inputError.sourceInvalidArgument||this.inputError.sourceInvalidPortRange||this.inputError.sourceNotValidCIDR||this.inputError.sourceNotValidPortVal
      ||this.inputError.sourceOverlaping||this.inputError.sourceOverlap || this.inputError.destInvalidArgument||this.inputError.destInvalidPortRange
      ||this.inputError.destNotValidCIDR||this.inputError.destNotValidPortVal ||this.inputError.destOverlaping||this.inputError.destOverlap
      ||this.inputError.VMPriorityValid||this.inputError.VMPriorityMaxMin ||this.inputError.VMPriorityDupCheck||this.inputError.nameDupCheck;
    }

    changeVmService(){
      if(this.vmInboundRule.service=='HTTP'){
        this.vmInboundRule.destinationPortRange=80;
        this.vmInboundRule.protocol='TCP';
        this.vmInboundRule.disableBasedOnService=true;
      }else if(this.vmInboundRule.service=='HTTPS'){
        this.vmInboundRule.destinationPortRange=443;
        this.vmInboundRule.protocol='TCP';
        this.vmInboundRule.disableBasedOnService=true;
      }else if(this.vmInboundRule.service=='SSH'){
        this.vmInboundRule.destinationPortRange=22;
        this.vmInboundRule.protocol='TCP';
        this.vmInboundRule.disableBasedOnService=true;
      }else if(this.vmInboundRule.service=='RDP'){
        this.vmInboundRule.destinationPortRange=3389;
        this.vmInboundRule.protocol='TCP';
        this.vmInboundRule.disableBasedOnService=true;
      }else if(this.vmInboundRule.service=='MS SQL'){
        this.vmInboundRule.destinationPortRange=1433;
        this.vmInboundRule.protocol='TCP';
        this.vmInboundRule.disableBasedOnService=true;
      }else if(this.vmInboundRule.service=='MySQL'){
        this.vmInboundRule.destinationPortRange=3306;
        this.vmInboundRule.protocol='TCP';
        this.vmInboundRule.disableBasedOnService=true;
      }else if(this.vmInboundRule.service=='DNS(TCP)'||this.vmInboundRule.service=='DNS(UDP)'){
        this.vmInboundRule.destinationPortRange=53;
        this.vmInboundRule.protocol='TCP';
        this.vmInboundRule.disableBasedOnService=true;
      }else{
        this.vmInboundRule.destinationPortRange=undefined;
        this.vmInboundRule.protocol='Any';
        this.vmInboundRule.disableBasedOnService=false;
      }
    }

    validateVMPriority(event){
      let value=event.target.value;
      this.inputError.VMPriorityValid=false;
      this.inputError.VMPriorityMaxMin=false;
      this.inputError.VMPriorityDupCheck=false;
      if(value){
        if(!(/^[0-9]*$/).test(value)){
          this.inputError.VMPriorityValid=true;
          return;
        }else if(parseInt(value)<100||parseInt(value)>4096){
          this.inputError.VMPriorityMaxMin=true;
        }
        if(this.currentResourceDtls&&this.currentResourceDtls.vmInBoundRule&&this.vmInboundRule.type=='inboundRules'){
          let findObj=this.currentResourceDtls.vmInBoundRule.find((dt,index)=>dt.priority==value&&this.currentResourceDtls.updateIndex!=index);
          if(findObj){
            this.inputError.VMPriorityDupCheck=true;
            return;
          }
        }
        if(this.currentResourceDtls&&this.currentResourceDtls.vmOutBoundRule&&this.vmInboundRule.type=='outboundRules'){
          let findObj=this.currentResourceDtls.vmOutBoundRule.find((dt,index)=>dt.priority==value&&this.currentResourceDtls.updateIndex!=index);
          if(findObj){
            this.inputError.VMPriorityDupCheck=true;
            return;
          }
        }
      }
    }

    isCharALetter(char){
      return (/[a-zA-Z]/).test(char);
    }
  
    isCharANumber(char){
      return (/[0-9]/).test(char);
    }

    validateInOutBoundName(event,limit){
      let value=event.target.value;
      this.inputError.nameLen=false;
      this.inputError.nameValid=false;
      this.inputError.nameDupCheck=false;
      if(value){
        if(value.length>limit){
          this.inputError.nameLen=true;
          return;
        }
        if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
          this.inputError.nameValid = false;
        }
        else{
          this.inputError.nameValid = true;
          return;
        }
    
        for(let input in value){
          if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
            this.inputError.nameValid = true;
            return;
          }
        }
      
        if(this.currentResourceDtls&&this.currentResourceDtls.vmInBoundRule){
          let findObj=this.currentResourceDtls.vmInBoundRule.find((dt,index)=>dt.name==value&&this.currentResourceDtls.updateIndex!=index);
          if(findObj){
            this.inputError.nameDupCheck=true;
            return;
          }
        }
        if(this.currentResourceDtls&&this.currentResourceDtls.vmOutBoundRule){
          let findObj=this.currentResourceDtls.vmOutBoundRule.find((dt,index)=>dt.name==value&&this.currentResourceDtls.updateIndex!=index);
          if(findObj){
            this.inputError.nameDupCheck=true;
            return;
          }
        }
      }
    }

    VmDescription(limitField, limitNum){
      let value =limitField.target.value
      if (value.length > limitNum) {
          this.inputError.VMdescription = true;
      }
      else this.inputError.VMdescription = false;
    }

    addInOutBoundRule(){
      
      let tempData=_.cloneDeep(this.vmInboundRule);
      this.vmInboundRule.data=tempData;
      if(tempData.type=='inboundRules'){
        if(!this.currentResourceDtls.vmInBoundRule){
          this.currentResourceDtls.vmInBoundRule=[];
        }
        if(this.currentResourceDtls.updateIndex!=undefined){
          const index=this.currentResourceDtls.updateIndex;
          this.currentResourceDtls.updateIndex=undefined;
          this.currentResourceDtls.vmInBoundRule[index]=_.cloneDeep(this.vmInboundRule.data);
        }else{
          this.currentResourceDtls.vmInBoundRule.push(this.vmInboundRule.data);
        }
      }else if(tempData.type=='outboundRules'){
        if(!this.currentResourceDtls.vmOutBoundRule){
          this.currentResourceDtls.vmOutBoundRule=[];
        }
        if(this.currentResourceDtls.updateIndex!=undefined){
          const index=this.currentResourceDtls.updateIndex;
          this.currentResourceDtls.updateIndex=undefined;
          this.currentResourceDtls.vmOutBoundRule[index]=_.cloneDeep(this.vmInboundRule.data);
        }else{
          this.currentResourceDtls.vmOutBoundRule.push(this.vmInboundRule.data);
        }
      }
    }


  onToggle(){
    this.showpop = !this.showpop;
  }

  toggleCommentSidebar(){
    this.showCommentSection = !this.showCommentSection;
  }

  toggleFullList(){
    this.fullList = !this.fullList;
    if(this.fullList){
      this.versionHistoryShortList = this.versionHistoryList;
    }
    else{
      this.versionHistoryShortList = this.versionHistoryList.slice(0,4);
    }
  }

  isDiagramCanvasEmpty(){
    return this.diagram && !this.diagram.nodes.length &&!this.diagram.connectors.length ? true : false;
  }

  checkDisable(value){
    switch (value){
      case 'rg-popup': return !this.newResourceGroup.resourceGroupName || !this.newResourceGroup.resourceGroupLocation
    }
    
  }

    openModal(template: TemplateRef<any>, showError?: any) {
        this.modalService.open(template);
    }

    closeModal() {
        this.modalService.dismissAll();
        this.hidden=true;
        this.versionHistory.description = null;
      }

    toggleHidden(){
        this.hidden=!this.hidden;
        this.viewVersionHistoryDropdown = !this.viewVersionHistoryDropdown;
    }

    async UpdateVersionHistory(){
      var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
      this.versionHistory.createdBy = get_Email.userName
      this.versionHistory.data = this.diagram.saveDiagram();
      let data=JSON.parse(this.versionHistory.data);
      data.nodeList=this.nodeList;
      data.groupRd=this.groupRd;
      data=JSON.stringify(data);
      this.versionHistory.data=data;
      console.log(this.versionHistory);
      let result=_.cloneDeep(this.versionHistory);
      console.log('result',result);
      return this.util.handleRequest('post','a3s_architectureBlueprint_updateVersionHistory',[],result,null,null,true).then(res=>{
        console.log('Blueprint saved successfully');
        this.notificationObject.show = true;
        this.notificationObject.style = 'black';
        this.notificationObject.text = "Changes Saved";
        setTimeout(() => {
          this.notificationObject.show = false;
          this.notificationObject.style = null;
          this.notificationObject.text = null;
          this.changedDiagramContent=false;
          this.routingService.update_routing_status(true); // unblocking routing
          this.isRouteAllowed = true;
         
           // this.router.navigate([this.movingUrl], { relativeTo: this.route });
           if( this.movingUrl=="Review"){
              this.openModal(this.submitForReview);
            }
        }, 3000);
        if(!res) return;
      })
    }

    async openModalVersionHistory(template: TemplateRef<any>, showError?: any) {
      await this.getVersionHistoryData();
      this.modalService.open(template);
    }
    
    async saveVersionHistory(){
      await this.UpdateVersionHistory();
      this.closeModal();
      await this.getVersionHistoryData();
    }

    getVersionHistoryData(){
      let projectId=this.versionHistory.projectId
      return this.util.handleRequest('get','a3s_architectureBlueprint_getAllVersionHistory',[projectId],null,null,null,false).then(res=>{
        console.log('res',res);
        this.versionHistoryList = res || []
        this.versionHistoryShortList = this.versionHistoryList;
        console.log(this.versionHistoryList.length);
        if(this.versionHistoryList.length>5){
          this.versionHistoryShortList = this.versionHistoryList.slice(0,4);
          this.showMore = true;
        }
        console.log(this.showMore,this.fullList);
        this.util.groupedLog(this.versionHistoryList,'Version history list');
      })
    }

    async saveComment(){
      var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
      this.commentObject.createdBy = get_Email.userName;
      console.log(this.commentObject);
      let result=_.cloneDeep(this.commentObject);
      console.log('result',result);
      return this.util.handleRequest('post','a3s_architectureBlueprint_updateCommentList',[],result,null,null,true).then(res=>{
        console.log('Comment created successfully');
        this.commentObject.comment = null;
        if(!res) return;
      })
    }

    async submitReview(){
      this.status='UNDER REVIEW';
      let statusObject={
        status : this.status,
        projectId : this.projectData.projectId,
      }
      return this.util.handleRequest('post','a3s_architectureBlueprint_submitForReview',[],statusObject,null,null,true).then(res=>{
        if(!res) return;
        console.log('Project under review');
        setTimeout(() => {
            this.diagram.constraints = DiagramConstraints.None;
        }, 500);
        this.reviewSubmittedCheck = true;
        this.notificationObject.show = true;
        this.notificationObject.style = 'brown';
        this.notificationObject.text = "Architecture blueprint is under review. Editing is disabled until review is complete";
        this.changedDiagramContent=false;
        this.routingService.update_routing_status(true); // unblocking routing
        this.isRouteAllowed = true;
        this.closeModal();
      })
    }

    async diagramApprovedHelper(){
      this.status='APPROVED';
      let statusObject={
        status : this.status,
        projectId : this.projectData.projectId,
      }
      console.log(statusObject);
      return this.util.handleRequest('post','a3s_architectureBlueprint_submitForReview',[],statusObject,null,null,true).then(async res=>{
        if(!res) return;
        console.log('Project approved');
        setTimeout(() => {
          this.diagram.constraints = DiagramConstraints.None;
        }, 500);
        this.diagramApproved = true;
        this.notificationObject.show = true;
        this.notificationObject.style = 'blue';
        this.notificationObject.text = "Architecture blueprint is approved. Click deploy to start the deployment";
        await this.getAllComments();
        this.showCommentSection=true;
        this.reviewedNotificationFunc();

      })
    }

    async submitForReviewHelper(){
      await this.submitReview();
      await this.getAllComments();
      this.showCommentSection=true;
    }

    moveBackToPending(){
      this.status='PENDING';
      let statusObject={
        status : this.status,
        projectId : this.projectData.projectId,
      }
      return this.util.handleRequest('post','a3s_architectureBlueprint_submitForReview',[],statusObject,null,null,true).then(async res=>{
        if(!res) return;
        console.log('Project moved back to pending');
        this.reviewSubmittedCheck = false;
        this.diagramApproved = false
        this.notificationObject.show = false;
        this.notificationObject.style = null;
        this.notificationObject.text = null;
        this.closeModal();
        setTimeout(() => {
          this.diagram.constraints = DiagramConstraints.Default;
        }, 500);
      })
    }

    async updateComment(){
      await this.saveComment();
      await this.getAllComments();
      this.commentNotif();
    }

    async getAllComments(){
      let projectId=this.versionHistory.projectId;
      
      return this.util.handleRequest('get','a3s_architectureBlueprint_getCommentList',[projectId],null,null,null,false).then(res=>{
        console.log('res',res);
        this.commentsList = res || []
        this.util.groupedLog(this.commentsList,'Comments list');

        for(var comment of this.commentsList.reverse()){
          if(this.user != comment.createdBy)
          {
            this.commentToDeleteId = comment.commentId
            break;
          }            
        }
      })      
      
    }

    async deleteComment(id){
      let commentId=id;
      return this.util.handleRequest('delete','a3s_architectureBlueprint_deleteComment',[commentId],null,null,null,false).then(async res=>{
        console.log('res',res);
        await this.getAllComments();
        // this.toastr.success('Request email sent successfully', '',    
        // {timeOut: 5000, positionClass: 'toast-top-center',
        //   easeTime:300, closeButton: true});

      })
    }

    async deployBlueprint(){
      this.disableDeployBtn=true;
      let tempList=[];
      if(this.groupRd['virtualNetwork']){
        this.groupRd['virtualNetwork'].forEach(el=>{
            if(el.subnet){
              el.subnet.forEach(elm => {
               if(elm.devices){
                elm.devices.forEach(eld => {
                  if(eld.resourceType=='linuxVirtualMachine'){
                    if(JSON.parse(eld.resourceData).authType=='ssh'&&JSON.parse(eld.resourceData).sshPublicKey=='genNewKeyPair'){
                      tempList.push(eld);
                    }
                  }
                });
               }
              });
            }
        })
      }     
      if(tempList.length){
        this.generateKeyPair=tempList;
        this.openModal(this.generateNewKeyPair);
      }else{
        this.loader.showLoader();
        await this.saveNewResourceGroupBeforDeploy();
        await this.updateParametersForDeploy();
        this.loader.hideLoader();
      }
    }

    async downloadDeploy(){
      this.loader.showLoader();
      await this.saveNewResourceGroupBeforDeploy();
      let tempReqObj=[];
      this.generateKeyPair.forEach(async (dt)=>{
        let resourceData=JSON.parse(dt.resourceData);
        tempReqObj.push({
          subscriptionId:this.projectData.subscriptionId,
          sshPublicKeyName:resourceData.keyPairName,
          resourceGroupName:resourceData.resourceGroup,
          location:resourceData.resourceGroupLocation
        });
      })
      let reqObj={
        'listReq':tempReqObj
      }
      await this.util.handleRequest('POST','a3s_architectureBlueprint_generateKeyPairForVM',[],tempReqObj,null,null,true).then(async res=>{
        if(res&&res.length){
          console.log(res);
          let requestObj=[];
          this.generateKeyPair.forEach(async (dt) => {
            let resourceData = JSON.parse(dt.resourceData);
            let findObj = res.find(ds => ds.sshPublicKeyName == resourceData.keyPairName);
            resourceData.publicKey = findObj.publicKey.split('\r\n').join('');
            dt.resourceData = resourceData;
            requestObj.push(dt);
            const blob = new Blob([findObj.privateKey], { type: 'application/x-pem-file' });
            // const url= window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = resourceData.keyPairName+".pem";
            // start download
            a.click();
          });
          await this.util.handleRequest('post','a3s_architectureBlueprint_updateMultipleResourceData',[],requestObj,null,null,true).then(async result=>{
            await this.updateParametersForDeploy();
            return result;
          });
        }else{
          this.disableDeployBtn=false;
        }
        return res
      });
      this.loader.hideLoader();
    }

    async saveNewResourceGroupBeforDeploy(){
      let reqObj={
        token:JSON.parse(sessionStorage.getItem('data'))['token']
      };
     await this.util.handleRequest('put','a3s_architectureBlueprint_saveNewResourceGroup',[this.projectData.projectId],reqObj,null,null,true).then(async res=>{
        if(res){
          let requestObj={
            resourceGroup:JSON.parse(this.groupRd['virtualNetwork'][0].resourceData).resourceGroup
          }
          await this.util.handleRequest('put','a3s_architectureBlueprint_updateBlueprintProjectResourceData',[this.projectData.projectId],requestObj,null,null,true).then(async result=>{
            return result;
          });
        }
        return res
      })
    }

    async updateParametersForDeploy(){
      this.generateKeyPair=[];
      await this.util.handleRequest('put','a3s_architectureBlueprint_updateParametersJSON',[this.projectData.projectId],null,null,null,true).then(async res=>{
        await this.sendNotificationHelper(this.projectData.projectName+"project deployment related parameters saved successfully", "bpParameterSuccess",this.projectData);
       await this.deployBlueprintAfterParametersUpdate();
      })
    }

   async deployBlueprintAfterParametersUpdate(){
      let reqObj={
        projectId:this.projectData.projectId,
        token:JSON.parse(sessionStorage.getItem('data'))['token']
      };
      await this.util.handleRequest('post','a3s_architectureBlueprint_deploy',[],reqObj,null,null,true).then(async res=>{
        this.deploymentStatus = 'started';
        let projectData=_.cloneDeep(this.projectData);
        const notificationRes=await this.sendDeploymentNotification(projectData);
        console.log('Deployed Data',res);
        this.overallPercentage = 0;
        this.startProgress(notificationRes,projectData);
        this.deploymentInterval = setInterval(async () => {
          await this.getDeploymentStatus(projectData).then(async res => {
            if(!res) return
            switch(res.properties.provisioningState){
              case 'Succeeded': {
                await this.updateProjectStatus('DEPLOYED',projectData);
                clearInterval(this.progressInterval);
                clearInterval(this.deploymentInterval);
                await this.updateDeploymetDetailByProjectId();
                this.deploymentStatus = projectData.projectId==this.projectData.projectId?'success':'';
                await this.updateNotification(notificationRes.notificationId,null,projectData);
                this.sendDeploymentNotification(projectData);
                this.disableDeployBtn=false;
                if(projectData.projectId==this.projectData.projectId){
                this.util.setCache('currentProject',this.projectData.projectName);
                this.projectData['fromDiagramTool']=true;
                this.util.route(['/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList/architectureDeploymentStatus'],true,{ data: JSON.stringify(this.projectData)});
              }
                return true;
              }
              case 'Failed': {
                await this.updateProjectStatus('FAILED',projectData);
                clearInterval(this.progressInterval);
                clearInterval(this.deploymentInterval);
                await this.updateDeploymetDetailByProjectId();
                this.deploymentStatus = projectData.projectId==this.projectData.projectId?'failed':'';
                await this.updateNotification(notificationRes.notificationId,null,projectData);
                this.sendDeploymentNotification(projectData);
                this.disableDeployBtn=false;
                if(projectData.projectId==this.projectData.projectId){
                this.util.setCache('currentProject',this.projectData.projectName);
                this.projectData['fromDiagramTool']=true;
                this.util.route(['/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList/architectureDeploymentStatus'],true,{ data: JSON.stringify(this.projectData)});
                }
                return true;
              }
              case 'Running': return this.deploymentStatus = projectData.projectId==this.projectData.projectId?'started':'';
            }
            console.log(res)
          }, err => {
              this.util.consoleError('Getting Deployment Status Api Failed.')
          })
      }, 30000);
      },err=>{
        this.loader.hideLoader();
      })
    }

    getDeploymentStatus(projectData){
      let reqObj={
        projectId:projectData.projectId,
        token:JSON.parse(sessionStorage.getItem('data'))['token']
      };
      return this.util.handleRequest('post','a3s_architectureBlueprint_getDeploymentStatus',[],reqObj,null,null,true);
    }

    updateProjectStatus(status,projectData){
      this.status=status;
      let statusObject={
        status : this.status,
        projectId : projectData.projectId,
      }
      return this.util.handleRequest('post','a3s_architectureBlueprint_submitForReview',[],statusObject,null,null,true).then(async res=>{
        if(!res) return;
        console.log('Project moved back to pending');
        if(projectData.projectId==this.projectData.projectId){
        this.reviewSubmittedCheck = true;
        this.diagramApproved = true
        this.projectData.status = status
        setTimeout(() => {
          this.diagram.constraints = DiagramConstraints.Default;
        }, 500);
      }
      })
    }

    async updateDeploymetDetailByProjectId(){
      await this.util.handleRequest('put','a3s_architectureBlueprint_updateDeploymetDetailByProjectId',[this.projectData.projectId],null,null,null,false).then(async res=>{
        if(!res) return;
        console.log('Deployment update in project and resource records');
      })
    }

    startProgress(notificationRes,projectData){
      this.progressInterval = setInterval(async () => {
        this.overallPercentage >= 99 ? this.overallPercentage = 99 : this.overallPercentage++;
        // this.overallPercentage += 2;
        if(this.overallPercentage<99){
          await this.updateNotification(notificationRes.notificationId,this.overallPercentage,projectData);
        }
      }, 10000)
    }

    createNewResourceGroup(){
      this.newResourceGroup.subscriptionId= this.projectData.subscriptionId;
      console.log(this.newResourceGroup);
      this.onToggle();
      let result  = _.cloneDeep(this.newResourceGroup)
      this.util.handleRequest('post','a3s_serviceDeployment_createRG',[],result,null,null,true).then(res=>{
        return res;
      })   
      
    }

    changeTimeFormat(createdTime){
      return this.util.changeTimeFormat(createdTime);
    }

    /**
     * This method will use when any node or item are selected in diagram will call by EmitType<ISelectionChangeEventArgs>
     * @param args all selected node object in diagram.
     */
    public selectionItems(args: any): void {
      this.selectedItems=args&&args.newValue?args.newValue:[];
      if(this.selectedItems.length>0){
        if(this.selectedItems.length==1&&!(this.selectedItems[0].id.includes('vmRect')||this.selectedItems[0].id.includes('subnetRect'))){
            this.editSideSheetEnable=false;
        }else{
          this.editSideSheetEnable=true;
        }
      this.deleteIconDisable=false;
      }else{
        this.deleteIconDisable=true;
        this.editSideSheetEnable=true;
      }
    }

    public nodePositionChange(args:any):void{
     // console.log('nodePositionChange',args);
      if(args.state=="Completed"){
        args.source.nodes?args.source.nodes.map(dt=>this.diagram.unSelect(dt)):this.diagram.unSelect(args.source);
        this.diagram.updateViewPort();
      }
      this.nodePositionState=args.state;
      let findObj=args.source.nodes?args.source.nodes.find(dt=>dt.id=='vmRect'+dt.id.substring(dt.id.length-5)):(args.source.id=='vmRect'+args.source.id.substring(args.source.id.length-5)?args.source:undefined);
      let findObj2=args.source.nodes?args.source.nodes.find(dt=>dt.id=='subnetRect'+dt.id.substring(dt.id.length-5)):(args.source.id=='subnetRect'+args.source.id.substring(args.source.id.length-5)?args.source:undefined);
      let nodeDt;
      if(!findObj&&findObj2){
        if(this.groupRd['virtualNetwork']){
          let index=this.groupRd['virtualNetwork'].findIndex(el=>{
              if(el.subnet){
                let findSubnet=el.subnet.find(elm => {
                 if(elm.subnetId.substring(elm.subnetId.length-5)==findObj2.id.substring(findObj2.id.length-5)){
                   return true;
                 }
                });
                if(findSubnet){
                  return true;
                }
              }
          })
          nodeDt=this.diagram.nodes.find(ds=>ds.id=='vmRect'+this.groupRd['virtualNetwork'][index].vnetId.substring(this.groupRd['virtualNetwork'][index].vnetId.length-5));
        }
      }else if(!findObj&&!findObj2){
        if(this.groupRd['virtualNetwork']){
          let subnetInd=-1;
          let index=this.groupRd['virtualNetwork'].findIndex(el=>{
              if(el.subnet){
                let findSubnet=el.subnet.findIndex(elm => {
                 if(elm.devices){
                  return elm.devices.find(eld => {
                    if(eld.id){
                      let nodeData=args.source.nodes?args.source.nodes:[args.source];
                      return nodeData.find(dt=>dt.id==eld.id);
                    }
                  });
                 }
                });
                if(findSubnet>-1){
                  subnetInd=findSubnet;
                  return true;
                }
              }
          })
          if(subnetInd>-1&&index>-1){
            nodeDt=this.diagram.nodes.find(ds=>ds.id=='subnetRect'+this.groupRd['virtualNetwork'][index].subnet[subnetInd].subnetId.substring(this.groupRd['virtualNetwork'][index].subnet[subnetInd].subnetId.length-5));
          }
        }
      }
      if(nodeDt){
        if(nodeDt.wrapper.corners.bottom-5<args.source.wrapper.corners.bottom){
          args.targetPosition.y=args.targetPosition.y+5;
        }
        else if(nodeDt.wrapper.corners.top+5>args.source.wrapper.corners.top){
          args.targetPosition.y=args.targetPosition.y-5;
        }
        else if(nodeDt.wrapper.corners.left+5>args.source.wrapper.corners.left){
          args.targetPosition.x=args.targetPosition.x-5;
        }
        else if(nodeDt.wrapper.corners.right-5<args.source.wrapper.corners.right){
          args.targetPosition.x=args.targetPosition.x+5;
        }
      }
    }

    public nodeSizeChange(args:any):void{
      this.nodeSizeState=args.state;
      if(this.nodePositionState){
        if(args.source.nodes.length>1){
          let vflag=false,sflag=false;
          args.source.nodes.map(dt=>{
            if(dt.id=='vmRect'+dt.id.substring(dt.id.length-5)){
              vflag=true;
            }else if(dt.id=='subnetRect'+dt.id.substring(dt.id.length-5)&&!vflag&&!sflag){
              sflag=true;
            }else {
            this.diagram.unSelect(dt);
            }
          })
          this.diagram.updateViewPort();
          }
      }
      // if(args.source.nodes.length>1){
      //   args.cancel=true;
      // }else{
      //   args.cancel=false;
      // }
      if(!args.cancel&&args.newValue&&args.oldValue&&args.source&&args.source.nodes){
          let findObj=args.source.nodes.find(dt=>dt.id=='vmRect'+dt.id.substring(dt.id.length-5));
          let findObj2=args.source.nodes.find(dt=>dt.id=='subnetRect'+dt.id.substring(dt.id.length-5));
          if(findObj){
          let index=this.diagram.nodes.findIndex(ds=>ds.id=='Virtual Network'+findObj.id.substring(findObj.id.length-5));
            this.diagram.nodes[index].offsetX=args.source.wrapper.bounds.x;
            this.diagram.nodes[index].offsetY=args.source.wrapper.bounds.y;
          }else if(findObj2){
            if(this.groupRd['virtualNetwork']){
              let indexS=this.diagram.nodes.findIndex(ds=>ds.id==findObj2.id);
              let indexV=this.groupRd['virtualNetwork'].findIndex(el=>{
                  if(el.subnet){
                    let findSubnet=el.subnet.find(elm => {
                      if(elm.subnetId.substring(elm.subnetId.length-5)==findObj2.id.substring(findObj2.id.length-5)){
                       return true;
                     }
                    });
                    if(findSubnet){
                      return true;
                    }
                  }
              })
              if(indexV>-1){
              let nodeDt=this.diagram.nodes.find(ds=>ds.id=='vmRect'+this.groupRd['virtualNetwork'][indexV].vnetId.substring(this.groupRd['virtualNetwork'][indexV].vnetId.length-5));
              if(nodeDt){
                if(nodeDt.wrapper.corners.bottom-5<args.source.wrapper.corners.bottom){
                  //args.targetPosition.y=args.targetPosition.y+5;
                  this.diagram.nodes[indexS].maxHeight=args.source.height-5;
                }
                else if(nodeDt.wrapper.corners.top+5>args.source.wrapper.corners.top){
                  //args.targetPosition.y=args.targetPosition.y-5;
                  this.diagram.nodes[indexS].maxHeight=args.source.height-5;
                }else if(args.newValue.height&&args.newValue.height!=args.oldValue.height){
                  //args.targetPosition.x=args.targetPosition.x+5;
                 this.diagram.nodes[indexS].maxHeight=nodeDt.height-5;
                }
                
                if(nodeDt.wrapper.corners.left+5>args.source.wrapper.corners.left){
                  //args.targetPosition.x=args.targetPosition.x-5;
                  this.diagram.nodes[indexS].maxWidth=args.source.width-5;
                }
                else if(nodeDt.wrapper.corners.right-5<args.source.wrapper.corners.right){
                  //args.targetPosition.x=args.targetPosition.x+5;
                  this.diagram.nodes[indexS].maxWidth=args.source.width-5;
                }else if(args.newValue.width&&args.newValue.width!=args.oldValue.width){
                  //args.targetPosition.x=args.targetPosition.x+5;
                 this.diagram.nodes[indexS].maxWidth=nodeDt.width-5;
                }
              }
            }
            }
            let index=this.diagram.nodes.findIndex(ds=>ds.id=='Subnet'+findObj2.id.substring(findObj2.id.length-5));
            this.diagram.nodes[index].offsetX=args.source.nodes.length>1?findObj2.wrapper.bounds.x:args.source.wrapper.bounds.x;
            this.diagram.nodes[index].offsetY=args.source.nodes.length>1?findObj2.wrapper.bounds.y:args.source.wrapper.bounds.y;
          }
      }
    }

    public connectionChange(args:any):void{

     if(args.oldValue&&args.oldValue&&args.oldValue.selectedItems&&!(args.oldValue.selectedItems.wrapper==null&&args.oldValue.selectedItems.wrapper==undefined)){
     this.changedDiagramContent=true;
     this.routingService.update_routing_status(false); // blocked routing
     }
     this.diagram.updateViewPort();

     if(!this.nodeSizeState||this.nodeSizeState=='Completed'||(this.nodePositionState!=null&&this.nodeSizeState!='Completed')){
      if(args.element.id&&args.newValue&&!(args.newValue.width&&args.newValue.height)){
        if(args.element.id.includes('Virtual Network')){
          let node=this.diagram.getObject('vmRect'+args.element.id.substring(args.element.id.length-5));
          this.diagram.select([args.element,node]);
        }else if(args.element.id.includes('Subnet')){
          let node=this.diagram.getObject('subnetRect'+args.element.id.substring(args.element.id.length-5));
          this.diagram.select([args.element,node]);
        }else if(args.element.id.includes('vmRect')){
          let node:any=this.diagram.getObject('Virtual Network'+args.element.id.substring(args.element.id.length-5));
          let selectedItems=[args.element,node];
          if(!(args.newValue.width&&args.newValue.height)){
            if(this.groupRd['virtualNetwork']){
              this.groupRd['virtualNetwork'].find(el=>{
                if(el.vnetId=='Virtual Network'+args.element.id.substring(args.element.id.length-5)){
                  if(el.subnet){
                    el.subnet.forEach(elm => {
                      selectedItems.push(this.diagram.getObject(elm.subnetId));
                      selectedItems.push(this.diagram.getObject('subnetRect'+elm.subnetId.substring(elm.subnetId.length-5)));
                      if(elm.devices){
                        elm.devices.forEach(dElm => {
                          selectedItems.push(this.diagram.getObject(dElm.id));
                        });
                      }
                    });
                    return true;
                  }
                }
              })
            }
          }else{
            // let index=this.diagram.nodes.findIndex(dt=>dt.id='Virtual Network'+args.element.id.substring(args.element.id.length-5));
            // this.diagram.nodes[index].offsetX=args.newValue.offsetX+15;
            // this.diagram.nodes[index].offsetX=args.newValue.offsetY+22;
          }
          this.diagram.select(selectedItems);
        }else if(args.element.id.includes('subnetRect')){
          let node=this.diagram.getObject('Subnet'+args.element.id.substring(args.element.id.length-5));
          let selectedItems=[args.element,node];
          if(!(args.newValue.width&&args.newValue.height)){
            if(this.groupRd['virtualNetwork']){
              this.groupRd['virtualNetwork'].find(el=>{
                if(el.subnet){
                  let flag=false;
                  el.subnet.find(elm => {
                    if(elm.subnetId=='Subnet'+args.element.id.substring(args.element.id.length-5)){
                      if(elm.devices){
                        elm.devices.forEach(dElm => {
                          selectedItems.push(this.diagram.getObject(dElm.id));
                        });
                      }
                      flag=true;
                      return true;
                    }
                  });
                  if(flag){
                    return true;
                  }
                }
                
              })
            }
          }
          this.diagram.select(selectedItems);
        }else{
          this.diagram.select([args.element]);
        }
        this.addGroupData(args.element,undefined);     
      }
     }
    // console.log('changesProp',args);
    }

    savePageData(){
      this.closeModal();
      this.openModalVersionHistory(this.versionHistoryPopup);
    }

    openChooseFile() {
      $("#imDataFile").click();
    }

    leavePage(){
      if(this.movingUrl){
        let rescourceIDs;
        if(this.nodeList.length>0&&this.savedNodeList.length==0){
          rescourceIDs=this.nodeList.map(dt=>dt.resourceId).toString();
        }else if(this.nodeList.length>0&&this.savedNodeList.length>0){
          let filterList=this.nodeList.filter(dt=>{
            if(!this.savedNodeList.find(d=>d.resourceId==dt.resourceId)){
              //rescourceIDs=rescourceIDs?rescourceIDs+","+this.nodeList[index].resourceId:this.nodeList[index].resourceId;
            return dt;
            }
            });
            rescourceIDs=filterList.map(dt=>dt.resourceId).toString();
        }
        if(rescourceIDs){
          this.util.handleRequest('delete','a3s_architectureBlueprint_deleteResourcesByResourceIDs',[rescourceIDs],null,null,null,true).then(res=>{
            console.log('delete resource',res);
            })
          }
        this.changedDiagramContent=false;
        this.routingService.update_routing_status(true); // unblocking routing
        this.isRouteAllowed = true;
        this.router.navigate([this.movingUrl], { relativeTo: this.route });
        this.closeModal();
        }else{
          this.openModal(this.submitForReview);
        }
    }

    checkBeforeSubmitForReview(submitForReview){
      if(this.changedDiagramContent){
        this.movingUrl="Review";
        this.openModalVersionHistory(this.versionHistoryPopup)
      }else{
      this.openModal(submitForReview);
      }
    }

  collapseSider() {
    $(".blueprint-main-body").toggleClass('collapse-sidebar');
    this.paletteHidden = !this.paletteHidden;
    if(this.paletteHidden) this.paletteStatus="Show";
    if(!this.paletteHidden) this.paletteStatus="Hide";
  }


  // **************************************  Notification functions ***************************************//

  reviewedNotificationFunc(){
    this.sendNotificationHelper(this.projectData.projectName+ " review is complete", "diagramToolReviewComplete",this.projectData);
  }

  commentNotif(){
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    var commentedBy = get_Email.userName
    this.sendNotificationHelper(commentedBy+ ' commented on '+this.projectData.projectName, "commentNotification",this.projectData);
  }
  
  async sendDeploymentNotification(projectData){
    if(this.deploymentStatus == 'success'){
      this.sendNotificationHelper(projectData.projectName+ " project is deployed successfully", "bpDeploymentSuccess",projectData);
    } 
    else if(this.deploymentStatus == 'failed') {
      this.sendNotificationHelper(projectData.projectName+ " project failed to deploy", "bpDeploymentFailed",projectData);
    }
    else if(this.deploymentStatus == 'started') {
     return await this.sendNotificationHelper(projectData.projectName+ " project deployment is in progress...", "bpDeploymentStarted",projectData);
    }
  }

  sendNotificationHelper(message, typeOfNotif,projectData) {
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    var commentedBy = get_Email.userName

      let notificationDetails={
        Message : message,
        createdBy : commentedBy,
        isProgress : 0,
        isNavigation : 1,
        navigateTo : typeOfNotif=='bpDeploymentSuccess' || typeOfNotif=='bpDeploymentFailed' ? '/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList/architectureBlueprintSummary':'/a3s/diagramTool',
        processType : typeOfNotif,
        isViewed : 0,
        data : JSON.stringify(projectData),
      }
      return this.util.handleRequest('post','a3s_notifications_createNotification',[],notificationDetails,null,null,true).then(async res=>{
        if(!res) return;
        return this.util.handleRequest('get','a3s_notifications_getAllNotifications',[commentedBy],null,null,null,true).then(async result=>{
            this.util.setObservable('refreshNotification',true);
            return result[result.length-1];
        }) 
      })
  }

  updateNotification(notificationId,overallPercentage,projectData){
    if(overallPercentage){
      let notificationDetails={
        notificationId,
        message : projectData.projectName+ " project deployment is in progress... "+overallPercentage+"%"
      }
      return this.util.handleRequest('post','a3s_notifications_updateNotificationProgressPrecentage',[],notificationDetails,null,null,true).then(async res=>{
        if(!res) return;
            this.util.setObservable('refreshNotification',true); 
        return res;
      })
    }else{
      return this.util.handleRequest('put','a3s_notifications_updateNotificationViewedStatus',[notificationId],null,null,null,true).then(async res=>{
        if(!res) return;
            this.util.setObservable('refreshNotification',true); 
        return res;
      })
    }
  }
  /**
  //    * SENDS NOTIFICATION TO NAVBAR and SERVICE SAVES IT IN BACKEND
  //    * @param _message - Notification Message
  //    * @param _id UNIQUE ID OF NOTIFICATION
  //    * @param __is_progressive Is Notification generated for running process
  //    * @param _job_completed_so_far % of jobs completed so far | default - 0
  //    * @param _max Maximum Jobs | default 100
  //    */
  //  sendNotificationToBackend(_message, _id, type, __is_progressive = true, _job_completed_so_far = 0, _max = 100) {
  //   _message = _message || "";
  //   _id = _id || _message;
  //   _job_completed_so_far = _job_completed_so_far || 0;
  //   _max = _max || 100;
  //   let _noti: ProgressNotificationMessage = {
  //       _id: _id,
  //       Message: _message,
  //       is_progress: __is_progressive,
  //       progress: {
  //           jobs_complete: _job_completed_so_far,
  //           total_jobs: _max
  //       },
  //       is_error_occurred: false,
  //       is_navigation: true,
  //       navigate_to: '/a3s/diagramTool',
  //       process_type: type,
  //       notification_data: this.projectData,
  //   }
  //   if (_noti.is_error_occurred || _id == -1) {
  //       _noti.is_error_occurred = true;
  //       _noti.error_message = "Something went wrong! Please try again..."
  //   }

  //   // console.log(_noti);
  //   // console.log("first version of notification!!");
  //   this._notificationService.send_progress_notification(_noti);
  // }

}
