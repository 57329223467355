 <div class="introduction">
          <p class="heading" id="0">Using Architecture Design Tool </p>
          <ol>
              <li>Choose a resource from the icon palette and drop it on the empty canvas. </li>
              <li>A side sheet with list of needed inputs will appear from the right-hand side. 
                  <img src="assets\user-manual\apps-&-infra\Vnet.png" >
                  <figcaption class="caption">Drag & drop VNET </figcaption>
                  In the above image, Virtual Network icon is dropped, hence respective side 
                  sheet appears to the right to collect the needed inputs to configure it on Azure while 
                  deploying. </li>
              <li>Side sheet has basic and advanced input fields section. In the image below, Virtual 
                  Machine side sheet shows list of basic inputs as well as advanced inputs.</li>
                  <img src="assets\user-manual\apps-&-infra\adv-side-sheet.png" >
                    <figcaption class="caption">Advance side sheet </figcaption>
            </ol>
                  <b>Important : We encourage users to fill in list of inputs with which they are familiar. 
                    Values for the remaining inputs will be discussed with users and filled in by A3S 
                    team to create a best practices based architecture that’s ready for deployment. </b> 
                    
         
        
</div>
