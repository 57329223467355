<div class="profile-main-body">
    
    <div class="header grey-bottom col-lg-12 col-md-12 col-sm-12">
        <app-breadcrumbs></app-breadcrumbs>
        <div class="d-flex ">
            <div><h1>My Account</h1></div>
            <div class="log-out-btn">
                <button class="btn btn-outline" (click)="logout()">
                    Log Out
                </button>
            </div>
        </div>        
    </div>
    <div class="lower-body row">
        <div>
        <div class="profile-pic">
            <div *ngIf="!profileImg">
                <span >{{usernameInitials}}</span>
            </div>            
            <img *ngIf="profileImg" [src]="profileImg" alt="" >
        </div>
        <div class="info-setting-lower">
            <div class="info">
              <p class="grey-text font-12 line-20">Name</p>
              <p class="font-14 line-22 bold-600">{{UserName}}</p>
            </div>
            <div class="info">
              <p class="grey-text font-12 line-20">Organization</p>
              <p class="font-14 line-22 bold-600">{{Organization | titlecase}}</p>
            </div>
            <div class="info">
              <p class="grey-text font-12 line-20">Organization Email</p>
              <p class="font-14 line-22 bold-600">{{Email}}</p>
            </div>
            <div class="info">
              <p class="grey-text font-12 line-20">Role(s) in organization</p>
              <p class="font-14 line-22 bold-600" *ngFor="let list of userRoleList | slice: 0:(limitShow);">{{list?.displayName}}</p>
              <p class="font-14 line-22 bold-600" *ngIf="userRoleList.length==0">User</p>
              <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" *ngIf="userRoleList.length>4&&limitShow==4" (click)="limitShow=userRoleList.length">Show more</p>
              <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" *ngIf="limitShow>4" (click)="limitShow=4">Show less</p>
            </div>
        </div>
    </div>
        <div class="right-lower-body">
            <!-- <div class="info-setting grey-bottom ">
                <div class="d-flex">
                    <div>
                        <p class="grey-text font-12 line-20">NAME</p>
                        <p class="font-14 line-22 bold-600">{{UserName}}</p>
                    </div>
                    <div>
                        <p class="grey-text font-12 line-20">ORGANIZATION</p>
                        <p class="font-14 line-22 bold-600">{{Organization | titlecase}}</p>
                    </div>
                    <div>
                        <p class="grey-text font-12 line-20">ORGANIZATION EMAIL</p>
                        <p class="font-14 line-22 bold-600">{{Email}}</p>
                    </div>
                </div>
            </div> -->
            <div class="connect-apps grey-bottom">
                <div class="width-430">
                    <h3>Connect Apps</h3>
                    <div class="row">
                        <div class="teams-icon"><img src="/assets/navbar/ms-teams.svg" alt=""></div>
                        <div>
                            <p class="bold-600">Teams</p>
                            <div class="d-flex">
                                <div class="abbot-describe">
                                    <p class="font-12 line-18 grey-text">Abbot - our Microsoft Teams bot enables easy, 
                                        intuitive & instant access to A3S functionality 
                                        directly from Teams. Connect now & make your 
                                        Azure usage even more easier & fun!</p>
                                </div>
                                <div class="connect-btn" >
                                    <a class="font-12 line-18 " (click)="openWebHook()">Connect</a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>                
            </div>
    
            <div class="newsletter grey-bottom">
                <div class="width-430">
                    <h3>Newsletter</h3>
                    <div class="d-flex">
                        <div class="newsletter-content">
                           <p class="font-13 line-20">Receive our weekly newsletter</p>
                        </div>
                        <div class="toggle-icon disabled">
                            <mat-slide-toggle  (change)="showSuccess()" [color]="toggleColor" [disabled]="toggleDisabled"></mat-slide-toggle>
                        </div>
                    </div>
                </div>                
            </div>
    
            <div class="accOption " *ngIf="isAdmin==true">
                <div class="width-430">
                    <h3>Account Option</h3>
                    <div>
                        <p class="grey-text font-12 line-18">Send an account deletion request to our customer service.</p>
                        <a class="font-12 line-18 red-text" class="delete-account-btn" (click)="openModal(deleteAccountPopup)" >Delete organization account</a>
                    </div>
                </div>
            </div>
        </div>      

    </div>


    
</div>

<ng-template #deleteAccountPopup>
    <div class="medium-popup-wrapper modal-sap-hana delete-acc-popup">
        <div class="modal-header">
            <h2 class="modal-title pull-left">Delete Account Request</h2>
            <button type="button" class="close pull-right gen-api" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
      <div class="modal-body">
    <form action="" [formGroup]="requestData">
        <div class="label-input">
            <p>Subject</p>
            <input type="text" placeholder="Enter your subject" class="input-subject" (keyup)="enableButton()" formControlName="subject">
        </div>
        <div class="label-input">
            <p>Message</p>
            <textarea type="text" placeholder="Enter your message" class="input-msg" (keyup)="enableButton()" formControlName="message"></textarea>
        </div>
        <p class="grey-text">Our customer support will get back to you within 12 hours</p>

        <div class="model-submit mt-3 float-right">
            <button type="button" (click)="closeModal()" class="btn btn-outline"
              >Cancel</button>
            <button type="button" (click)="sendRequestData()" class="btn btn-btn-primary" [disabled]="!sendButtonEnable"
              >Send</button>
        </div>
    </form>
      </div>
    </div>
  </ng-template>


  