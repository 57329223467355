import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-stacked-line-chart',
  template: `<div echarts [options]="options" [loading]="true"></div>`,
})
export class StackedLineChartComponent implements OnChanges {
  options: any = {};
  @Input('data') data: any = [];
  constructor() { }

  ngOnChanges() {
    console.log(this.data)
    if(!this.data.series){
      this.data.series=[{
        data: this.data.yAxis,
        type: 'line',
    }]
    }
    var chartLabelColors = ['#7D3AC1', '#DB4CB2', '#19AADE', '#1DE4BD', '#FBB040'];
    this.options = {
      // title: {
      //   text: 'Sample Chart'
      // },
      color: this.data.color,
      tooltip: {
        show: true,
        // trigger: 'axis',
        // axisPointer: {
        //   type: 'cross',
        //   label: {
        //     backgroundColor: '#6a7985'
        //   }
        // }
      },
      legend: {
        show: this.data.showLegend || false,
        data: this.data.legendData,
        bottom: 0,
        left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        // top: 35,
        // bottom:  8,
        // right: 10,
        containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.data.xAxis,
          axisLabel: {
            interval: 0,
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
            axisLine: {
              lineStyle: {
                color: '#8995A2',
              }
            },
            splitLine: {
              lineStyle: {
                color: '#8995A2',
              }
            },
            formatter: function (params) {
              return params + '\n\n (UTC)'
            }, 
          },
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
            formatter: function (params) {
              return params + ' %'
            },
          },
      },
      series: this.data.series
    };
  }

 
}
