<div class="mgmt-oveview">
    <!-- Filter Section -->
    <div class="custom-filter-wrapper flex-custom">
      <div class="custom-filter">
          <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>

          <div class="filter-dropdown" id="filter-dropdown" >
              <div class="enlist-srch">
                  <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                  <img src="assets/icons/search.png">
              </div>
              <div class="filter-content">
                  <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                      <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                      <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                  </div>
                  <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                      <p  class="content-14 blur-text">No filter found</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="custom-filter">
          <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
          <div class="filter-dropdown" id="filter-dropdown" >
              <div class="enlist-srch">
                  <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                  <img src="assets/icons/search.png">
              </div>
              <div class="filter-content">
                  <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                      <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                      <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                  </div>
                  <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                      <p  class="content-14 blur-text">No filter found</p>
                  </div>
              </div>
             
          </div>
      </div>
      <div class="custom-filter">
          <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
          <div class="filter-dropdown" id="filter-dropdown" >
              <div class="enlist-srch">
                  <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                  <img src="assets/icons/search.png">
              </div>
              <div class="filter-content">
                  <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                      <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                      <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                  </div>
                  <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                      <p  class="content-14 blur-text">No filter found</p>
                  </div>
              </div>
          </div>
      </div>
      <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
          <img src="/assets/common-icons/close-filter.svg" alt="">
          Clear Filter
      </button>
      <!-- <div class="flex-custom nowrap ml-auto cursor-pointer">
        <div class="learn-text">Learn more</div>
        <img src="assets/health-monitoring/learn-more.svg" alt="Icon" class="time-ico">
      </div> -->
    </div>


  <!-- Graph Section  mb-4-->
  <div class="box-row" *ngIf="currentDashboard == 'overview'">
    <div class="colm-3">
      <div class="graph-div" style="padding: 0px;">
        <div class="heading" style="padding: 16px 24px;">
            <div class="d-flex">
                <div class="about">
                    <h4>Top 10 Resource Types</h4>
                </div>
            </div>
        </div>
        <app-horizontal-bar-chart [data]='resourceTypeData' type="top10resource" *ngIf="resourceTypeData?.data?.length"></app-horizontal-bar-chart>
        <div class="loader-gif" *ngIf="resourceTypeData?.showLoader">
          <img src="assets/icons/blue-loader.gif"/>
          <p>Getting data...</p>
        </div>
        <div class="dash-text-center" *ngIf="!resourceTypeData?.data?.length && !resourceTypeData?.showLoader">
            <img src="/assets/health-monitoring/no-data.svg" alt="">
            <p class="text-14-22 bold-600 mt-3">No data found</p>
        </div>
      </div>
      <!-- <div class="box-view">
        <div class="box-title">Top 10 Resource Types</div>
        <app-horizontal-bar-chart [data]='resourceTypeData' *ngIf="resourceTypeData?.data?.length"></app-horizontal-bar-chart>
        <div class="loader-gif" *ngIf="resourceTypeData?.showLoader">
          <img src="assets/icons/blue-loader.gif"/>
          <p>Getting data...</p>
        </div>
        <div class="dash-text-center" *ngIf="!resourceTypeData?.data?.length && !resourceTypeData?.showLoader">
            <img src="/assets/health-monitoring/no-data.svg" alt="">
            <p class="text-14-22 bold-600 mt-3">No data found</p>
        </div>
      </div> -->
    </div>
    <div class="colm-3">
      <div class="graph-div">
        <div class="heading mb-4">
            <div class="d-flex">
                <div class="about">
                    <h4>By Subscription</h4>
                </div>
            </div>
        </div>
        <app-subscription-chart [data]='totalSubscriptions' *ngIf="totalSubscriptions?.data?.length"></app-subscription-chart>
        <div class="loader-gif" *ngIf="totalSubscriptions?.showLoader">
          <img src="assets/icons/blue-loader.gif"/>
          <p>Getting data...</p>
        </div>
        <div class="dash-text-center" *ngIf="!totalSubscriptions?.data?.length && !totalSubscriptions?.showLoader">
            <img src="/assets/health-monitoring/no-data.svg" alt="">
            <p class="text-14-22 bold-600 mt-3">No data found</p>
        </div>
    </div>
      <!-- <div class="box-view">
        <div class="box-title">By Subscription</div>
        <div class="pr-3 pl-3">
          <app-subscription-chart [data]='totalSubscriptions' *ngIf="totalSubscriptions?.data?.length"></app-subscription-chart>
          <div class="loader-gif" *ngIf="totalSubscriptions?.showLoader">
            <img src="assets/icons/blue-loader.gif"/>
            <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!totalSubscriptions?.data?.length && !totalSubscriptions?.showLoader">
              <img src="/assets/health-monitoring/no-data.svg" alt="">
              <p class="text-14-22 bold-600 mt-3">No data found</p>
          </div>
        </div>
      </div> -->
    </div>
    <div class="colm-3">
      <div class="graph-div">
        <div class="heading mb-4">
            <div class="d-flex">
                <div class="about">
                    <h4>By Region</h4>
                </div>
            </div>
        </div>
        <app-region-chart [data]='regionData' *ngIf="regionData?.data?.length"></app-region-chart>
        <div class="loader-gif" *ngIf="regionData?.showLoader">
          <img src="assets/icons/blue-loader.gif"/>
          <p>Getting data...</p>
        </div>
        <div class="dash-text-center" *ngIf="!regionData?.data?.length && !regionData?.showLoader">
            <img src="/assets/health-monitoring/no-data.svg" alt="">
            <p class="text-14-22 bold-600 mt-3">No data found</p>
        </div>
       </div>
      <!-- <div class="box-view">
        <div class="box-title">By Region</div>
        <div class="pr-3 pl-3">
          <app-region-chart [data]='regionData' *ngIf="regionData?.data?.length"></app-region-chart>
          <div class="loader-gif" *ngIf="regionData?.showLoader">
            <img src="assets/icons/blue-loader.gif"/>
            <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!regionData?.data?.length && !regionData?.showLoader">
              <img src="/assets/health-monitoring/no-data.svg" alt="">
              <p class="text-14-22 bold-600 mt-3">No data found</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div class="row" *ngIf="currentDashboard !== 'overview'">
    <div class="col-md-6">
      <div class="graph-div">
          <div class="heading mb-4">
              <div class="d-flex">
                  <div class="about">
                      <h4>Resources By Subscription</h4>
                  </div>
              </div>
          </div>
          <app-subscription-chart [data]='totalSubscriptions' *ngIf="totalSubscriptions?.data?.length"></app-subscription-chart>
          <div class="loader-gif" *ngIf="totalSubscriptions?.showLoader">
            <img src="assets/icons/blue-loader.gif"/>
            <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!totalSubscriptions?.data?.length && !totalSubscriptions?.showLoader">
              <img src="/assets/health-monitoring/no-data.svg" alt="">
              <p class="text-14-22 bold-600 mt-3">No data found</p>
          </div>
      </div>
    </div>
    <div class="col-md-6 pr-0">
      <div class="graph-div">
          <div class="heading mb-4">
              <div class="d-flex">
                  <div class="about">
                      <h4>Resources By Region</h4>
                  </div>
              </div>
          </div>
          <app-region-chart [data]='regionData' *ngIf="regionData?.data?.length"></app-region-chart>
          <div class="loader-gif" *ngIf="regionData?.showLoader">
            <img src="assets/icons/blue-loader.gif"/>
            <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!regionData?.data?.length && !regionData?.showLoader">
              <img src="/assets/health-monitoring/no-data.svg" alt="">
              <p class="text-14-22 bold-600 mt-3">No data found</p>
          </div>
      </div>
    </div>
  </div>

  <div class="acc-item" *ngFor="let box of resourceTypes;index as i">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item',i,box?.resourceType)" id="acc_item_{{i}}">
      <span class="count">{{box?.count}}</span>
     <p class="header-text">{{box?.resourceType}}</p> 
      <!-- <p class="header-text">{{getResourceTypeName(box?.resourceType)}}</p> -->
    </div>
    <div class="acc-content">
      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Disks'">
        <app-disk-actions [data]="disksData" [columns]="columns" [templateName]="currentTemplate" [currentProjectData]="currentProjectData"></app-disk-actions>
      </ng-container>
      
      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Virtual Machines'">
        <app-virtual-machine-actions [data]="vmData" [columns]="columns" [currentProjectData]="currentProjectData"></app-virtual-machine-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Local Network Gateways'">
        <app-local-network-gateway-actions [data]="localNetworkGatewaysData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-local-network-gateway-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Virtual Network Gateways'">
        <app-vpn-gateway-actions [data]="vpnGatewaysData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-vpn-gateway-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'VM Scale Sets'">
        <app-vm-scale-set-actions [data]="vmScaleSetData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-vm-scale-set-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'App Services'">
        <app-app-service-actions [data]="appServiceData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-app-service-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'App Service Plans'">
        <app-app-service-plan-actions [data]="appServicePlanData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-app-service-plan-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Connections'">
        <app-connection-actions [data]="connections" [columns]="columns"  [currentProjectData]="currentProjectData"></app-connection-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Azure Firewalls'">
        <app-firewall-actions [data]="azureFirewall" [columns]="columns"  [currentProjectData]="currentProjectData"></app-firewall-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'SQL Databases'">
        <app-sql-database-actions [data]="sqlDatabaseData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-sql-database-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'SQL Virtual Machines'">
        <app-sql-vm-actions [data]="sqlVmData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-sql-vm-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Load Balancers'">
        <app-load-balancer-actions [data]="loadBalancersData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-load-balancer-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Azure Bastions'">
        <app-azure-bastion-actions [data]="azureBastionData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-azure-bastion-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Application Gateways'">
        <app-application-gateway-actions [data]="AppGatewayData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-application-gateway-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Storage Accounts'">
        <app-storage-account-actions [data]="storageAccountData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-storage-account-actions>
      </ng-container>

      <ng-container *ngIf="getResourceTypeName(box?.resourceType) == 'Machine Learning Workspaces'">
        <app-ml-workspace-actions [data]="MLWorkspaceData" [columns]="columns"  [currentProjectData]="currentProjectData"></app-ml-workspace-actions>
      </ng-container>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Virtual Networks'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('virtualNetworks',0)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('virtualNetworks',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('virtualNetworks',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('virtualNetworks',3)">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Address Spaces</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('virtualNetworks',4)">
                  </div>
              </th>
              <th style="border-right: none;">
                  <div class="flex-custom no-wrap">
                      <span>Tags</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('virtualNetworks',5)">
                  </div>
              </th>
              <th style="border-left: none;" class="wid-75"></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of vnetData" >
                  <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(vmDetailsTemplate,row,'VNET')">{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive><div [title]="row[4]" class="ellips-text"><span *ngFor="let list of row[4];index as i">{{  list | displayTxt }}<span *ngIf="i != (row[4].length-1)">, </span></span></div></td>
                  <td isEllipsisActive>{{getTags(row[5])}}</td>
                  <td class="text-right wid-75">
                    <span>
                      <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                      <mat-menu #action2="matMenu" class="delete-popup">
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('addressSpace',row)">
                          <p class="delete-popup-text">Address Space</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('subnet',row)">
                          <p class="delete-popup-text">Subnet</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('ddosProtection',row)">
                          <p class="delete-popup-text">DDoS Protection Standard</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('dnsServer',row)">
                          <p class="delete-popup-text">DNS Server</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteVNET')">
                          <p class="delete-popup-text">Delete</p>
                        </div>
                      </mat-menu>
                    </span>              
                  </td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!vnetData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Hostpools'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('hostpools',0)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('hostpools',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('hostpools',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('hostpools',3)">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Load Balancing Type</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('hostpools',4)">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Host Pool Type</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('hostpools',5)">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('hostpools',6)">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of hostPoolsData">
                <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[4] | titlecase | displayTxt }}</td>
                  <td isEllipsisActive>{{ getHostPoolType(row[5]) | titlecase | displayTxt }}</td>
                  <td isEllipsisActive>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!hostPoolsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Availability Sets'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('availabilitySets',0)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('availabilitySets',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('availabilitySets',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('availabilitySets',3)">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Virtual Machines</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('availabilitySets',4)">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Tags</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('availabilitySets',5)">
                  </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of availabilitySetData" >
                 <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[4] | displayTxt }}</td>
                  <td isEllipsisActive>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!availabilitySetData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Public IP Addresses'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',0)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',3)">
                  </div>
              </th>
              <th style="border-right: none;">
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',4)">
                </div>
              </th>
              <th style="border-left: none;" class="wid-75"></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of publicIpData">
                  <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(vmDetailsTemplate,row,'PublicIP')">{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{getTags(row[4])}}</td>
                  <td class="text-right wid-75">
                    <span>
                      <img  src="assets/icons/option_btn.png" (click)="getPublicIPAddress(row)" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action4">
                      <!-- <div *ngIf="matMenuOpen"> -->
                        <div id="matMenu" >
                        <mat-menu #action4="matMenu" class="delete-popup" >
                          <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('associateIp',row)" *ngIf="!AssociatePublicIPData">
                            <p class="delete-popup-text">Associate</p>
                          </div>
                          <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('disassociateIp',row)" *ngIf="AssociatePublicIPData && bothDisable" >
                            <p class="delete-popup-text">Dissociate</p>
                          </div>
                          <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                            <p class="delete-popup-text">Configuration</p>
                          </div>
                          <div class="mat-menu-item" mat-menu-item (click)="action(row,'deletePublicIP')" *ngIf="!bothDisable || !AssociatePublicIPData">
                            <p class="delete-popup-text">Delete</p>
                            <!-- *ngIf="!bothDisable || !AssociatePublicIPData || AssociatePublicIPData " -->
                          </div>
                        </mat-menu>
                      </div>
                    </span>              
                  </td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!publicIpData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Network Security Groups'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkSecurityData',0)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkSecurityData',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkSecurityData',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkSecurityData',3)">
                  </div>
              </th>
              <th style="border-right: none;">
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkSecurityData',4)">
                </div>
              </th>
              <th style="border-left: none;" class="wid-75"></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of networkSecurityData">
                  <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(vmDetailsTemplate,row,'NSG')">{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2])}}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{getTags(row[4])}}</td>
                  <td class="text-right wid-75">
                    <span>
                      <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action3">
                      <mat-menu #action3="matMenu" class="delete-popup ">
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('inboundSecurityRules',row)">
                          <p class="delete-popup-text">Inbound Security Rules</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('outboundSecurityRules',row)">
                          <p class="delete-popup-text">Outbound Security Rules</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('networkInterface',row)">
                          <p class="delete-popup-text">Network Interfaces</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('nsgSubnets',row)">
                          <p class="delete-popup-text">Subnets</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteNSG')">
                          <p class="delete-popup-text">Delete</p>
                        </div>
                      </mat-menu>
                    </span>              
                  </td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!networkSecurityData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Network Interfaces'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png"(click)="sortByKey('networkInterfaceData',0)" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkInterfaceData',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkInterfaceData',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkInterfaceData',3)">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Virtual Network</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkInterfaceData',4)">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Public IP Address</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkInterfaceData',5)">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Attached To</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkInterfaceData',6)">
                </div>
              </th>
              <th style="border-right: none;">
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkInterfaceData',7)">
                </div>
              </th>
              <th style="border-left: none;" class="wid-75"></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of networkInterfaceData">
                  <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(vmDetailsTemplate,row,'NIC')">{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive class="whitespace-no">{{ row[4] | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[5] | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[6] | displayTxt }}</td>
                  <td isEllipsisActive>{{getTags(row[7])}}</td>
                  <td class="text-right wid-75">
                    <span>
                      <img  src="assets/icons/option_btn.png" (click)="getNICData(row)" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action3">
                      <mat-menu #action3="matMenu" class="delete-popup ">
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('nicIPConfiguration',row)">
                          <p class="delete-popup-text">IP configuration</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('nicDnsServer',row)">
                          <p class="delete-popup-text">DNS servers</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('nicNsg',row)">
                          <p class="delete-popup-text">Network Security Group</p>
                        </div>
                        <div class="mat-menu-blur" mat-menu-item (click)="UpdateNICData(row)" [class.blur]="nicData?.properties?.virtualMachine">
                          <p class="delete-popup-text" [class.blur]="nicData?.properties?.virtualMachine" >{{nicData?.properties?.enableAcceleratedNetworking ? 'Disable Accelerated Networking' : 'Enable Accelerated Networking'}}</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteNIC')">
                          <p class="delete-popup-text">Delete</p>
                        </div>
                      </mat-menu>
                    </span>              
                  </td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!networkInterfaceData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'DNS Zones'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png"(click)="sortByKey('dnsZonesData',0)" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('dnsZonesData',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('dnsZonesData',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('dnsZonesData',3)">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Records</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('dnsZonesData',4)">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Servers</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('dnsZonesData',5)">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('dnsZonesData',6)">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of dnsZonesData" >
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[4] | displayTxt }}</td>
                  <td class="ellips-td">
                    <div [title]="row[5]" class="ellips-text"><span *ngFor="let list of row[5]">{{  list | displayTxt }}, </span></div>
                  </td>
                  <td>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!dnsZonesData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Network Watchers'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkWatchersData',0)" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkWatchersData',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkWatchersData',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkWatchersData',3)">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('networkWatchersData',4)">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of networkWatchersData">
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{getTags(row[4])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!networkWatchersData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Certificates'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png"(click)="sortByKey('serviceCertificateData',0)" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('serviceCertificateData',1)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('serviceCertificateData',2)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('serviceCertificateData',3)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Issuer</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('serviceCertificateData',4)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Expiry Date</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('serviceCertificateData',5)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('serviceCertificateData',6)"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of serviceCertificateData">
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td class="ellips-td"><div [title]='row[4]' class="ellips-text">{{ row[4] }}</div></td>
                  <td isEllipsisActive>{{ row[5] | dateFormat | displayTxt}}</td>
                  <td isEllipsisActive>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!serviceCertificateData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Log Analytics Workspaces'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Workspace ID</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of logAnalytickWorkspaceData" >
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2])}}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[4] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
                  <td isEllipsisActive>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!logAnalytickWorkspaceData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Actiongroups'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('actionGroupsData',0)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('actionGroupsData',1)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('actionGroupsData',2)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('actionGroupsData',3)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('actionGroupsData',4)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('actionGroupsData',5)"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of actionGroupsData" >
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
                  <td isEllipsisActive>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!actionGroupsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Metricalerts'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png"(click)="sortByKey('alertsData',0)" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('alertsData',1)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Metric Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('alertsData',2)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Severity</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('alertsData',3)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('alertsData',4)"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of alertsData" >
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[2] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[3] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!alertsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Automation Accounts'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png"(click)="sortByKey('automationAccountsData',0)" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('automationAccountsData',1)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('automationAccountsData',2)"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('automationAccountsData',3)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('automationAccountsData',5)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('automationAccountsData',6)"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of automationAccountsData" >
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[3] | titlecase | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
                  <td isEllipsisActive>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!automationAccountsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Key Vaults'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('keyVaultsData',0)" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('keyVaultsData',1)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('keyVaultsData',2)"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('keyVaultsData',3)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Vault URL</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('keyVaultsData',4)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('keyVaultsData',5)"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of keyVaultsData">
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[3] | titlecase | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[4] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='getTags(row[6])' class="ellips-text">{{ getTags(row[6])}}</div></td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!keyVaultsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Workbooks'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('workbooksData',0)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('workbooksData',1)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('workbooksData',2)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Gallery</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('workbooksData',3)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Kind</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('workbooksData',4)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Last Modified</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('workbooksData',5)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('workbooksData',6)"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of workbooksData" >
                   <!-- class="blue-text cursor-pointer" -->
                  <td class="ellips-td"><div [title]='row[0]' class="ellips-text">{{ row[0] }}</div></td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[3] | titlecase | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[4] | titlecase|  displayTxt}}</td>
                  <td isEllipsisActive>{{ row[5] | dateFormat | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='getTags(row[6])' class="ellips-text">{{ getTags(row[6])}}</div></td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!workbooksData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Application Insights'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('applicationInsightsData',0)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('applicationInsightsData',1)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appServiceData',2)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('applicationInsightsData',3)"></div></th>
                <!-- <th><div class="flex-custom no-wrap"><span>Application Type</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('applicationInsightsData',4)"></div></th> -->
                <th><div class="flex-custom no-wrap"><span>Instrumentation Key</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('applicationInsightsData',5)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Created On</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('applicationInsightsData',6)"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('applicationInsightsData',6)"></div></th>
                <!-- <th><div class="flex-custom no-wrap"><span>Properties</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('applicationInsightsData',7)"></div></th> -->
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of applicationInsightsData">
                   <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td isEllipsisActive>{{ getProjectName(row[2]) | displayTxt}}</td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <!-- <td>{{ row[4] | titlecase | displayTxt}}</td> -->
                  <td isEllipsisActive>{{ row[4] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[5] | dateFormat | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='getTags(row[6])' class="ellips-text">{{ getTags(row[6])}}</div></td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!applicationInsightsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Subnets'">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('subnet',0)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Virtual Network</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('subnet',1)">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('subnet',2)">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Address Prefix</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('subnet',3)">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Delegated to</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('subnet',4)">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Security Group</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('subnet',5)">
                  </div>
              </th>
              <th style="border-right: none;">
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('subnet',6)">
                </div>
              </th>
              <th style="border-left: none;" class="wid-75"></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of subnetData">
                  <td isEllipsisActive class="cursor-pointer blue-text" (click)="openModal(vmDetailsTemplate,row,'Subnet')">{{ row[0]  | displayTxt }}</td>
                  <td isEllipsisActive class="whitespace-no">{{ row[1] | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[2] | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[3] | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[4] | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[5] | displayTxt }}</td>
                  <td isEllipsisActive>{{getTags(row[6])}}</td>
                  <td class="text-right wid-75">
                    <span>
                      <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                      <mat-menu #action2="matMenu" class="delete-popup">
                        <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('subnetType',row)">
                          <p class="delete-popup-text">Edit</p>
                        </div>
                        <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteSubnetRow')">
                          <p class="delete-popup-text">Delete</p>
                        </div>
                      </mat-menu>
                    </span>              
                  </td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!subnetData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'SQL Servers'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Server Name</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of sqlServersData" >
                     <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
                  <td isEllipsisActive>{{ getTags(row[6]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!sqlServersData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Firewall Policies'">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of firewallPolicyData">
                     <!-- class="blue-text cursor-pointer" -->
                  <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                  <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                  <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
                  <td isEllipsisActive>{{ getTags(row[5]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!firewallPolicyData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Route Tables'">
        <table class="table">
          <thead>
            <tr>
              <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
              <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let row of routeTablesData">
                   <!-- class="blue-text cursor-pointer" -->
                <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                <td isEllipsisActive>{{ getTags(row[4]) }}</td>
            </tr>
            <div class="col-md-12 mt-3" *ngIf="!routeTablesData?.length">
              <p class="content-14 blur-text">No data Found</p>
            </div>
          </tbody>
      </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Azure Data Lake Storage Gen1'">
        <table class="table">
          <thead>
            <tr>
              <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
              <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Type</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let row of LeakStoreData">
                   <!-- class="blue-text cursor-pointer" -->
                <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                <td isEllipsisActive>{{ row[4] | displayTxt}}</td>
                <td isEllipsisActive>{{ getTags(row[5]) }}</td>
            </tr>
            <div class="col-md-12 mt-3" *ngIf="!LeakStoreData?.length">
              <p class="content-14 blur-text">No data Found</p>
            </div>
          </tbody>
      </table>
      </div>

      <div class="custom-fixed-tables" *ngIf="getResourceTypeName(box?.resourceType) == 'Synapse Analytics Workspaces'">
        <table class="table">
          <thead>
            <tr>
              <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
              <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>SQL Admin Username</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Workspace URL</span><img class="sort" src="assets/icons/sort.png"></div></th>
              <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let row of synapseWorkspaceDats">
                   <!-- class="blue-text cursor-pointer" -->
                <td isEllipsisActive>{{ row[0] | displayTxt}}</td>
                <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
                <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
                <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
                <td isEllipsisActive>{{ row[4] | displayTxt}}</td>
                <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
                <td isEllipsisActive>{{ getTags(row[6]) }}</td>
            </tr>
            <div class="col-md-12 mt-3" *ngIf="!synapseWorkspaceDats?.length">
              <p class="content-14 blur-text">No data Found</p>
            </div>
          </tbody>
      </table>
      </div>
    </div>
  </div>



  <!-- Accordion Section -->
  <!-- Virtual Machines -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_1')" id="acc_item_1">
      <span class="count">{{ vmData?.length }}</span>
      <p class="header-text">Virtual Machine</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Status</span>
                    <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Operating System</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Size</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of vmData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td><div class="status-tag" [ngClass]="getStatusClass(row[4])">{{ row[4] | titlecase | displayTxt}}</div></td>
                  <td>{{ row[5] }}</td>
                  <td>{{ row[6] | displayTxt}}</td>
                  <td>{{ getTags(row[7]) |displayTxt }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!vmData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Virtual Network -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_2')" id="acc_item_2">
      <span class="count">{{ vnetData?.length }}</span>
      <p class="header-text">Virtual Network</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Address Spaces</span>
                    <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Tags</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of vnetData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td><div [title]="row[4]" class="ellips-text"><span *ngFor="let list of row[4];index as i">{{  list | displayTxt }}<span *ngIf="i != (row[4].length-1)">, </span></span></div></td>
                  <td>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!vnetData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Host Pools -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_3')" id="acc_item_3">
      <span class="count">{{ hostPoolsData?.length }}</span>
      <p class="header-text">Hostpools</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Load Balancing Type</span>
                    <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Host Pool Type</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of hostPoolsData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt }}</td>
                  <td>{{ getHostPoolType(row[5]) | titlecase | displayTxt }}</td>
                  <td>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!hostPoolsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Availability Set -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_4')" id="acc_item_4">
      <span class="count">{{ availabilitySetData?.length }}</span>
      <p class="header-text">Availability Set</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Virtual Machines</span>
                    <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Tags</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of availabilitySetData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | displayTxt }}</td>
                  <td>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!availabilitySetData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- VM Scale Set -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_5')" id="acc_item_5">
      <span class="count">{{ vmScaleSetData?.length }}</span>
      <p class="header-text">Virtual Machine Scale Sets</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Operating System</span>
                    <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of vmScaleSetData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt }}</td>
                  <td>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!vmScaleSetData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- SQL Virtual Machine  -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_6')" id="acc_item_6">
      <span class="count">{{ sqlVmData?.length }}</span>
      <p class="header-text">SQL Virtual Machine </p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>SKU</span>
                    <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Image Offer</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of sqlVmData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt }}</td>
                  <td>{{ row[5] | displayTxt }}</td>
                  <td>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!sqlVmData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Subnet  -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_7')" id="acc_item_7">
      <span class="count">{{ subnetData?.length }}</span>
      <p class="header-text">Subnets</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Virtual Network</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Address Prefix</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Delegated to</span>
                    <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Security Group</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of subnetData"  class="cursor-pointer">
                  <td>{{ getName(row[0]) | titlecase | displayTxt }}</td>
                  <td class="whitespace-no">{{ getName(row[1]) | titlecase | displayTxt }}</td>
                  <td>{{ row[2] | displayTxt }}</td>
                  <td>{{ row[3] | displayTxt }}</td>
                  <td>{{ row[4] | displayTxt }}</td>
                  <td>{{ getName(row[5]) | titlecase | displayTxt }}</td>
                  <td>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!subnetData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Public IP Addresses -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_8')" id="acc_item_8">
      <span class="count">{{ publicIpData?.length }}</span>
      <p class="header-text">Public IP Addresses</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of publicIpData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{getTags(row[4])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!publicIpData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Network Security Groups  -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_9')" id="acc_item_9">
      <span class="count">{{ networkSecurityData?.length }}</span>
      <p class="header-text">Network Security Groups</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of networkSecurityData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{getTags(row[4])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!networkSecurityData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Network Interface  -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_10')" id="acc_item_10">
      <span class="count">{{ networkInterfaceData?.length }}</span>
      <p class="header-text">Network Interface</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Virtual Network</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>IP Address</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Attached To</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of networkInterfaceData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td class="whitespace-no">{{ getName(row[4]) | titlecase | displayTxt }}</td>
                  <td>{{ row[5] | displayTxt }}</td>
                  <td>{{ getName(row[6]) | titlecase | displayTxt }}</td>
                  <td>{{getTags(row[7])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!networkInterfaceData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- DNS Zones -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_11')" id="acc_item_11">
      <span class="count">{{ dnsZonesData?.length }}</span>
      <p class="header-text">DNS Zones</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Records</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Servers</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of dnsZonesData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | displayTxt }}</td>
                  <td class="ellips-td">
                    <div [title]="row[5]" class="ellips-text"><span *ngFor="let list of row[5]">{{  list | displayTxt }}, </span></div>
                  </td>
                  <td>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!dnsZonesData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Network Watchers -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_12')" id="acc_item_12">
      <span class="count">{{ networkWatchersData?.length }}</span>
      <p class="header-text">Network Watchers</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of networkWatchersData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{getTags(row[4])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!networkWatchersData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Route Tables -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_13')" id="acc_item_13">
      <span class="count">{{ routeTablesData?.length }}</span>
      <p class="header-text">Route Tables</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of routeTablesData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{getTags(row[4])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!routeTablesData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Load Balancers -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_14')" id="acc_item_14">
      <span class="count">{{ loadBalancersData?.length }}</span>
      <p class="header-text">Load Balancers</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>SKU</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Type</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of loadBalancersData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase |  displayTxt}}</td>
                  <td>{{ row[5] | titlecase |  displayTxt}}</td>
                  <td>{{ getTags(row[6]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!loadBalancersData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Local Network Gateways -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_15')" id="acc_item_15">
      <span class="count">{{ localNetworkGatewaysData?.length }}</span>
      <p class="header-text">Local Network Gateways</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Name</span>
                        <img class="sort" src="assets/icons/sort.png" >
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Resource Group</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                    <div class="flex-custom no-wrap">
                        <span>Subscription</span>
                        <img class="sort" src="assets/icons/sort.png">
                    </div>
                </th>
                <th>
                  <div class="flex-custom no-wrap">
                      <span>Region</span>
                      <img class="sort" src="assets/icons/sort.png">
                  </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>IP Address</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Tags</span>
                    <img class="sort" src="assets/icons/sort.png">
                </div>
              </th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of localNetworkGatewaysData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] |  displayTxt}}</td>
                  <td>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!localNetworkGatewaysData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Storage Account -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_16')" id="acc_item_16">
      <span class="count">{{ storageAccountData?.length }}</span>
      <p class="header-text">Storage Account</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Access Tier</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Kind</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of storageAccountData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td>{{ row[3] | titlecase | displayTxt}}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | titlecase | displayTxt}}</td>
                  <td>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!storageAccountData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Disks -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_17')" id="acc_item_17">
      <span class="count">{{ disksData?.length }}</span>
      <p class="header-text">Disks</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Size (GB)</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Owner</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of disksData"  class="cursor-pointer">
                  <td class="ellips-td"><div [title]='row[0]' class="ellips-text">{{ row[0] }}</div></td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td>{{ row[3] | displayTxt}}</td>
                  <td>{{ row[4] | displayTxt}}</td>
                  <td>{{ row[5] | displayTxt}}</td>
                  <td>{{getTags(row[6]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!disksData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Data Lake Storage -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_18')" id="acc_item_18">
      <span class="count">{{ LeakStoreData?.length }}</span>
      <p class="header-text">Data Lake Storage</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Type</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of LeakStoreData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!LeakStoreData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- App Service -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_19')" id="acc_item_19">
      <span class="count">{{ appServiceData?.length }}</span>
      <p class="header-text">App Service</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Type</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>App Service Plan</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>URL</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of appServiceData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td>{{ row[3] | titlecase | displayTxt}}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ getAppSkuName(row[5]) | displayTxt}}</td>
                  <td>{{ row[6] | titlecase | displayTxt}}</td>
                  <td>{{ row[7] | displayTxt}}</td>
                  <td>{{ getTags(row[8])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!appServiceData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- App Service Plan -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_20')" id="acc_item_20">
      <span class="count">{{ appServicePlanData?.length }}</span>
      <p class="header-text">App Service Plan</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Operating System</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Apps</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Pricing Tier</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of appServicePlanData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | displayTxt}}</td>
                  <td>{{ row[6] | displayTxt}}</td>
                  <td>{{getTags(row[7])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!appServicePlanData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- App Service Certificates -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_21')" id="acc_item_21">
      <span class="count">{{ serviceCertificateData?.length }}</span>
      <p class="header-text">App Service Certificates</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Issuer</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Expiry Date</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of serviceCertificateData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td class="ellips-td"><div [title]='row[4]' class="ellips-text">{{ row[4] }}</div></td>
                  <td>{{ row[5] | dateFormat | displayTxt}}</td>
                  <td>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!serviceCertificateData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- App Service Environments -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_22')" id="acc_item_22">
      <span class="count">{{ appServiceEnvData?.length }}</span>
      <p class="header-text">App Service Environments</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Virtual Network</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Operating System</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Kind</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of appServiceEnvData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td>{{ row[3] | titlecase | displayTxt}}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | titlecase | displayTxt}}</td>
                  <td>{{ row[6] | titlecase | displayTxt}}</td>
                  <td>{{ getTags(row[7]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!appServiceEnvData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Azure Static Apps -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_23')" id="acc_item_23">
      <span class="count">{{ azureStaticAppsData?.length }}</span>
      <p class="header-text">Azure Static Apps</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>URL</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of azureStaticAppsData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[1] }}</div></td>
                  <td>{{ row[2] | displayTxt}}</td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | displayTxt}}</td>
                  <td>{{ getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!azureStaticAppsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- CDN Profiles -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_24')" id="acc_item_24">
      <span class="count">{{ cdnProfilesData?.length }}</span>
      <p class="header-text">CDN Profiles</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>State</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of cdnProfilesData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[1] }}</div></td>
                  <td>{{ row[2] | displayTxt}}</td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | titlecase | displayTxt}}</td>
                  <td>{{ getTags(row[6]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!cdnProfilesData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Synapse Workspace -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_25')" id="acc_item_25">
      <span class="count">{{ synapseWorkspaceDats?.length }}</span>
      <p class="header-text">Synapse Workspace</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SQL Admin Username</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Workspace Web URL</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of synapseWorkspaceDats"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | displayTxt}}</td>
                  <td>{{ getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!synapseWorkspaceDats?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- SQL Servers -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_26')" id="acc_item_26">
      <span class="count">{{ sqlServersData?.length }}</span>
      <p class="header-text">SQL Servers</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Server Name</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of sqlServersData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | displayTxt}}</td>
                  <td>{{ getTags(row[6]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!sqlServersData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- SQL Database -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_27')" id="acc_item_27">
      <span class="count">{{ sqlDatabaseData?.length }}</span>
      <p class="header-text">SQL Database</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tier</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Server Name</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of sqlDatabaseData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | displayTxt}}</td>
                  <td>{{ row[6] | displayTxt}}</td>
                  <td>{{ getTags(row[7]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!sqlDatabaseData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Dedicated SQL Pool -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_28')" id="acc_item_28">
      <span class="count">{{ sqlPoolsData?.length }}</span>
      <p class="header-text">Dedicated SQL Pool</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Performance Level</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Workspace Name</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of sqlPoolsData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{ row[5] | displayTxt}}</td>
                  <td>{{ row[6] | displayTxt}}</td>
                  <td>{{ getTags(row[7]) }}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!sqlPoolsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Log Analytics Workspace -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_29')" id="acc_item_29">
      <span class="count">{{ logAnalytickWorkspaceData?.length }}</span>
      <p class="header-text">Log Analytics Workspace</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Workspace ID</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of logAnalytickWorkspaceData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | displayTxt}}</td>
                  <td>{{ row[5] | displayTxt}}</td>
                  <td>{{getTags(row[6])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!logAnalytickWorkspaceData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Action Groups -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_30')" id="acc_item_30">
      <span class="count">{{ actionGroupsData?.length }}</span>
      <p class="header-text">Action Groups</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of actionGroupsData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ row[2] }}</div></td>
                  <td>{{ getRegionName(row[3]) | displayTxt }}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!actionGroupsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Alerts -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_31')" id="acc_item_31">
      <span class="count">{{ alertsData?.length }}</span>
      <p class="header-text">Alerts</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Metric Name</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Severity</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of alertsData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ row[2] | displayTxt}}</td>
                  <td>{{ row[3] | displayTxt}}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!alertsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Workbooks -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_32')" id="acc_item_32">
      <span class="count">{{ workbooksData?.length }}</span>
      <p class="header-text">Workbooks</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Gallery</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Kind</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Last Modified</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of workbooksData"  class="cursor-pointer">
                  <td class="ellips-td"><div [title]='row[0]' class="ellips-text">{{ row[0] }}</div></td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td>{{ row[3] | titlecase | displayTxt}}</td>
                  <td>{{ row[4] | titlecase|  displayTxt}}</td>
                  <td>{{ row[5] | dateFormat | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='getTags(row[6])' class="ellips-text">{{ getTags(row[6])}}</div></td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!workbooksData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Automation Accounts -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_33')" id="acc_item_33">
      <span class="count">{{ automationAccountsData?.length }}</span>
      <p class="header-text">Automation Accounts</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Status</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of automationAccountsData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td>{{ row[3] | titlecase | displayTxt}}</td>
                  <td>{{ row[4] | titlecase | displayTxt}}</td>
                  <td>{{getTags(row[5])}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!automationAccountsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Application Insights -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_34')" id="acc_item_34">
      <span class="count">{{ applicationInsightsData?.length }}</span>
      <p class="header-text">Application Insights</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Application Type</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Instrumentation Key</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Created On</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Properties</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of applicationInsightsData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td>{{ row[3] | titlecase | displayTxt}}</td>
                  <td>{{ row[4] | displayTxt}}</td>
                  <td>{{ row[5] | dateFormat | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='getTags(row[6])' class="ellips-text">{{ getTags(row[6])}}</div></td>
                  <td>--</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!applicationInsightsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

  <!-- Key Vaults -->
  <!-- <div class="acc-item">
    <div class="acc-head d-flex" (click)="openAccordion('acc_item_35')" id="acc_item_35">
      <span class="count">{{ keyVaultsData?.length }}</span>
      <p class="header-text">Key Vaults</p>
    </div>
    <div class="acc-content">
      <div class="custom-fixed-tables">
        <table class="table">
            <thead>
              <tr>
                <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" ></div></th>
                <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Vault URL</span><img class="sort" src="assets/icons/sort.png"></div></th>
                <th><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png"></div></th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let row of keyVaultsData"  class="cursor-pointer">
                  <td>{{ row[0] | displayTxt}}</td>
                  <td>{{ row[1] | displayTxt}}</td>
                  <td>{{ getRegionName(row[2]) | displayTxt }}</td>
                  <td>{{ row[3] | titlecase | displayTxt}}</td>
                  <td>{{ row[4] | displayTxt}}</td>
                  <td class="ellips-td"><div [title]='getTags(row[6])' class="ellips-text">{{ getTags(row[6])}}</div></td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!keyVaultsData?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
      </div>
    </div>
  </div> -->

</div>


<ng-container *ngIf="showSidesheet">
  <app-action-template [templateName]="currentTemplate" [data]="currentData" [allData]="alldataList" [currentProject]="currentProjectData" (triggerBtn)="closeSidesheet($event)"></app-action-template>
</ng-container>


<ng-template #vmDetailsTemplate>
  <div class="medium-popup-wrapper width-832">
    <div class="modal-body scroll-y">
      <div class="d-flex mb-3">
        <div class="popup-title">{{getCurrentResourceTitle() | titlecase}}</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="tab-list" *ngIf="selectedType == 'Subnet'">
        <div class="tab-item" (click)="closeModal();openSidesheet('subnetType',selectedRow)">Edit</div>
        <div class="tab-item" (click)="closeModal();action(selectedRow,'deleteSubnetRow')">Delete</div>
      </div>
      <div class="tab-list" *ngIf="selectedType == 'VNET'">
        <div class="tab-item" (click)="closeModal();openSidesheet('addressSpace',selectedRow)">Address Space</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('subnet',selectedRow)">Subnet</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('ddosProtection',selectedRow)">DDoS Protection Standard</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('dnsServer',selectedRow)">DNS Server</div>
        <div class="tab-item" (click)="closeModal();action(selectedRow,'deleteVNET')">Delete</div>
      </div>
      <div class="tab-list" *ngIf="selectedType == 'NSG'">
        <div class="tab-item" (click)="closeModal();openSidesheet('inboundSecurityRules',selectedRow)">Inbound Security Rules</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('outboundSecurityRules',selectedRow)">Outbound Security Rules</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('networkInterface',selectedRow)">Network Interfaces</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('nsgSubnets',selectedRow)">Subnets</div>
        <div class="tab-item" (click)="closeModal();action(selectedRow,'deleteNSG')">Delete</div>
      </div>
      <div class="tab-list" *ngIf="selectedType == 'NIC'">
        <div class="tab-item" (click)="closeModal();openSidesheet('nicIPConfiguration',selectedRow)">IP Configuration</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('nicDnsServer',selectedRow)">DNS Servers</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('nicNsg',selectedRow)">Network Security Group</div>
        <div class="tab-item" [class.disabled-icon]="nicData?.properties?.virtualMachine" (click)="closeModal();UpdateNICData(selectedRow)">{{nicData?.properties?.enableAcceleratedNetworking ? 'Disable Accelerated Networking' : 'Enable Accelerated Networking'}}</div>
        <div class="tab-item" (click)="closeModal();action(selectedRow,'deleteNIC')">Delete</div>
      </div>
      <div class="tab-list" *ngIf="selectedType == 'PublicIP'">
        <div class="tab-item" (click)="closeModal();openSidesheet('associateIp',selectedRow)" [class.disabled-icon]="AssociatePublicIPData">Associate</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('disassociateIp',selectedRow)" [class.disabled-icon]="!AssociatePublicIPData && !bothDisable">Dissociate</div>
        <div class="tab-item" (click)="closeModal();openSidesheet('configuration',selectedRow)">Configuration</div>
        <div class="tab-item" (click)="closeModal();action(selectedRow,'deletePublicIP')" [class.disabled-icon]="bothDisable || AssociatePublicIPData || !AssociatePublicIPData">Delete</div>
      </div>
      <div class="row row1" >
        <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
          <div class="list-item">
            <div class="label-text" *ngIf="col.name!='publicIPAddress'">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name)| firstLetterCaps}}</div>
            <div class="label-text" *ngIf="col.name=='publicIPAddress'">Public IP Address</div>
            <ng-container *ngIf="col.name!='location'&&col.name!='subscriptionId'">
              <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedRow[i]:getTags(selectedRow[i])}}</div>
            </ng-container>
            <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
            <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
        </div>
        </div>
      </div>
    </div>
    <div class="submit-sec text-right pad-10-24">
    <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirm>
  <div class="medium-popup-wrapper">
    <div class="modal-body">
      <div class="d-flex mb-3">
        <div class="popup-title">Delete {{getCurrentResourceTitle()}}</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="dlt-content">
        <p>Are you sure want to delete {{selectedRow[0]}}?</p>
      </div>
      <div class="submit-sec text-right">
        <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
        <button id="secondary" class="btn btn-red" (click)="deleteAction()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #stopVM>
  <div class="medium-popup-wrapper">
    <div class="modal-body">
      <div class="d-flex mb-3">
        <div class="popup-title">Stop Virtual Machine</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="dlt-content">
        <ng-container *ngIf="vmPublicIpData && vmPublicIpData?.publicIpesponse?.properties?.deleteOption !== 'Detach'">
          <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
            <label class="" style="padding: 2.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
            <label class="text-12-18" style="padding: 8px 0px !important;color: #703501;">Public IP address ‘{{vmPublicIpData?.properties?.publicIPAddress?.properties?.ipAddress}}’ will be lost if this VM is stopped</label>
          </div>  
          <div class="checkbox-cs"> 
              <input type="checkbox" name="reserve-ip" class="form-check-inline" [(ngModel)]="selectedRow.reserveIp" id='reserve-ip'>
              <label class="value" for="reserve-ip">Do you want to reserve the public IP address?</label>
          </div>
        </ng-container>
        <p>Are you sure you want to stop {{selectedRow[0]}}?</p>
      </div>
      <div class="submit-sec text-right">
        <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
        <button id="secondary" class="btn btn-btn-primary" (click)="stopVMAction(selectedRow)">Stop</button>
      </div>
    </div>
  </div>
</ng-template>