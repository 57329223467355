
<div class="introduction">
       <p class="heading" id="0">Billing </p>
       <p>Billing contains following sub-sections: </p>
          
          <ol>
                      <li>Project cost.  </li>
                      <li>Invoices. </li>
                      <li>Reservation Transactions. </li>
                      <li>Billing profiles. </li>
                     
          </ol>
         
         
   </div>
   
