import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alerts-application-health-monitoring',
  templateUrl: './alerts-application-health-monitoring.component.html',
  styleUrls: ['./alerts-application-health-monitoring.component.scss']
})
export class AlertsApplicationHealthMonitoringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
