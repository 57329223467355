<div class="introduction">
 
    <p class="heading" id="0">Initiating Events and Returning Events  </p>
    <p class="para mb-0" >Shows the name of event and corresponding number of users.   </p>
    <img style="width:50%" src="assets\user-manual\apps-health-monitoring\Tile(13).svg" >
    <figcaption class="caption col-md-6">Initiating events</figcaption>
    <img style="width:50%" src="assets\user-manual\apps-health-monitoring\Tile(14).svg" >
    <figcaption class="caption col-md-6">Returned events </figcaption>
  
          
</div>