import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
import { HealthThreshold } from '../../health-threshold';
@Component({
  selector: 'app-application-overview',
  templateUrl: './application-overview.component.html',
  styleUrls: ['./application-overview.component.scss']
})
export class ApplicationOverviewComponent implements OnInit {
  @Input('filterObject') filterObject: any ;
  @Input('currentProjectData') currentProjectData: any ;
  projectHealthData: any={
    type:'projectHealthData',
    showLoader : false,
    options:null,
    legendData: [],
    data: [],
    total:0,
    text:'Healthy',
    unit:'%',
    refreshTime : new Date()
  }
  resources: any ={
    type:'resources',
    availability:0,
    serverResponse:0,
    failedRequest:0,
    cpuUsage:0,
    showLoader : false,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  }

  availabilityData: any = {
    type:'availabilityData',
    xAxis: [],
    yAxis: [],
    color: '#2989E9',
    showLoader : false,
    data:[],
    options:null,
    refreshTime : new Date()
  };

  serverResponse: any = {
    type:'serverResponse',
    xAxis: [],
    yAxis: [],
    color: '#2989E9',
    showLoader : false,
    data:[],
    options:null,
    refreshTime : new Date()
  };

  failedRequest: any = {
    type:'failedRequest',
    xAxis: [],
    yAxis: [],
    color: '#2989E9',
    showLoader : false,
    data:[],
    options:null,
    refreshTime : new Date()
  };

  cpuUsage: any = {
    type:'cpuUsage',
    xAxis: [],
    yAxis: [],
    color: '#2989E9',
    showLoader : false,
    data:[],
    options:null,
    refreshTime : new Date()
  };

  memoryAvailable: any = {
    type:'memoryAvailable',
    xAxis: [],
    yAxis: [],
    color: '#2989E9',
    showLoader : false,
    data:[],
    options:null,
    refreshTime : new Date()
  };

  resourceList: any = [];
  healthThreshold: any;
  masterHealthThreshold: any;
  healthyResourceCount: any = 0
  unhealthyResourceCount: any = 0
  subscriptionOverview: any;
  currentProject: any;

  constructor(private util:UtilService) { }

  ngOnInit(): void {
    this.healthThreshold = _.cloneDeep(HealthThreshold.webApp)
    this.masterHealthThreshold = _.cloneDeep(this.healthThreshold)
    this.subscriptionOverview = this.util.getObservable().subscribe((res) => {
      if(res.currentFilteredProject && res.currentFilteredProject.currentWebAppId){
        this.currentProject = res.currentFilteredProject
      }
    });
  }

  ngOnDestroy() {
    if(this.subscriptionOverview) this.subscriptionOverview.unsubscribe();
  }

  ngOnChanges() {
    this.resourceList = _.cloneDeep(this.filterObject.resourceList.filter(e=> e.checked))
    if(!this.resourceList.length) return;
    this.getMetricsHealthTableData()
    console.log('Filter Object',this.filterObject)
  }

async getMetricsHealthTableData(){
  this.projectHealthData.showLoader = true
    return Promise.all(this.resourceList.map(async resource=>{
      if(!resource.currentWebAppId) return resource
      let data = `availabilityResults | TIMERANGE | where true | where true and true | extend percentage = toint(success) * 100 | summarize avg(percentage) by bin(timestamp, 5m) | order by timestamp desc | summarize percentage = avg(avg_percentage)`
      let query = data.replace('TIMERANGE',this.util.getTimeRangeQuery({ type : 'last24hr'}))
      let requestData = { query: query }
      let availability = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getData',[resource.currentWebAppId,resource.currentWebAppKey],requestData,null,null,true).catch(e=>  {this.resources.showLoader = false ; return resource} )
      resource.availability = availability.body.tables[0].rows[0][0] == 'NaN' ? 0:availability.body.tables[0].rows[0][0] || 0

      let data1 = `requests | TIMERANGE | where client_Type != 'Browser' | summarize avg(duration) by bin(timestamp, 1m)| order by timestamp desc | summarize value = avg(avg_duration)`
      let query1 = data1.replace('TIMERANGE',this.util.getTimeRangeQuery({ type : 'last24hr'}))
      let requestData1 = { query: query1 }
      let serverResponse = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getData',[resource.currentWebAppId,resource.currentWebAppKey],requestData1,null,null,true).catch(e=>  {this.resources.showLoader = false ; return resource} )
      resource.serverResponse = serverResponse.body.tables[0].rows[0][0] == 'NaN' ? 0:serverResponse.body.tables[0].rows[0][0] || 0

      let data2 = `requests | TIMERANGE | where client_Type != 'Browser' | summarize failedCount=sumif(itemCount, success == false) by bin(timestamp, 1m) | summarize totalFailedRequests = sum(failedCount)`
      let query2 = data2.replace('TIMERANGE',this.util.getTimeRangeQuery({ type : 'last24hr'}))
      let requestData2 = { query: query2 }
      let failedRequest = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getData',[resource.currentWebAppId,resource.currentWebAppKey],requestData2,null,null,true).catch(e=>  {this.resources.showLoader = false ; return resource})
      resource.failedRequest = failedRequest.body.tables[0].rows[0][0] == 'NaN' ? 0:failedRequest.body.tables[0].rows[0][0] || 0

      let data3 = `availabilityResults | TIMERANGE | where true | where true and true | extend percentage = toint(success) * 100 | summarize avg(percentage) by bin(timestamp, 5m) | order by timestamp desc | summarize percentage = avg(avg_percentage)`
      let query3 = data3.replace('TIMERANGE',this.util.getTimeRangeQuery({ type : 'last24hr'}))
      let requestData3 = { query: query3 }
      let cpuUsage = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getData',[resource.currentWebAppId,resource.currentWebAppKey],requestData3,null,null,true).catch(e=>  {this.resources.showLoader = false ; return resource} )
      resource.cpuUsage = cpuUsage.body.tables[0].rows[0][0] == 'NaN' ? 0:cpuUsage.body.tables[0].rows[0][0] || 0

      let filter = this.util.getCommonIsoDate({ type : 'last24hr'})
      let requestData4 = { subsId: resource.subscriptionId , rgName: resource.resourceGroupName ,resourceName: resource.currentWebAppName,timeSpan:`${filter.from}/${filter.to}`}
      let memoryAvailable = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getMemoryAvailable',[],requestData4,null,null,true).catch(e=>  {this.resources.showLoader = false ; return resource})
      resource.memoryAvailable = memoryAvailable.body.value.length && memoryAvailable.body.value[0].timeseries.length ? memoryAvailable.body.value[0].timeseries[0].data[0].average || 0 : 0
    
      resource.availabilityHealth = this.getMetricHealth(resource.availability,'availability')
      resource.serverResponseHealth = this.getMetricHealth(resource.serverResponse,'serverResponse')
      resource.failedRequestHealth = this.getMetricHealth(resource.failedRequest,'failedRequest')
      resource.cpuUsageHealth = this.getMetricHealth(resource.cpuUsage,'cpuUsage')
      resource.memoryAvailableHealth = this.getMetricHealth(resource.memoryAvailable,'memoryAvailable')
      resource.health = this.getResourceHealth(resource)
      return resource 
    })).then(final=>{
      console.log(final)
      this.setAvailability(final)
      this.setServerResponse(final)
      this.setFailedRequest(final)
      this.setCpuUsage(final)
      this.setMemoryAvailable(final)
      this.calculateProjectHealth(final)
      this.calculateTotalResourceHealth(final)
      return final
    },err=>{
      this.projectHealthData.showLoader = false
    })
}

setAvailability(final){
  this.availabilityData.showLoader = true;
  this.availabilityData.xAxis = []
  this.availabilityData.data = []
  final.forEach(e=>{
    this.availabilityData.xAxis.push(e.resourceName)
    let styleData1 = { value : e.availabilityHealth.healthPercent , itemStyle: { color: e.availabilityHealth.class == 'green' ? '#06C493' : '#FF526E'}}
    this.availabilityData.data.push(styleData1)
  })
  this.availabilityData.options = this.getVerticalBarOptions(this.availabilityData)
  this.availabilityData.showLoader = false;
}

setServerResponse(final){
  this.serverResponse.showLoader = true;
  this.serverResponse.xAxis = []
  this.serverResponse.data = []
  final.forEach(e=>{
    this.serverResponse.xAxis.push(e.resourceName)
    let styleData2 = { value : e.serverResponseHealth.healthPercent , itemStyle: { color: e.serverResponseHealth.class == 'green' ? '#06C493' : '#FF526E'}}
    this.serverResponse.data.push(styleData2)
  })
  this.serverResponse.options = this.getVerticalBarOptions(this.serverResponse)
  this.serverResponse.showLoader = false;
}

setFailedRequest(final){
  this.failedRequest.showLoader = true;
  this.failedRequest.xAxis = []
  this.failedRequest.data = []
  final.forEach(e=>{
    this.failedRequest.xAxis.push(e.resourceName)
    let styleData3 = { value : e.failedRequestHealth.healthPercent , itemStyle: { color: e.failedRequestHealth.class == 'green' ? '#06C493' : '#FF526E'}}
    this.failedRequest.data.push(styleData3)
  })
  this.failedRequest.options = this.getVerticalBarOptions(this.failedRequest)
  this.failedRequest.showLoader = false;
}

setCpuUsage(final){
  this.cpuUsage.showLoader = true;
  this.cpuUsage.xAxis = []
  this.cpuUsage.data = []
  final.forEach(e=>{
    this.cpuUsage.xAxis.push(e.resourceName)

    let styleData4 = { value : e.cpuUsageHealth.healthPercent , itemStyle: { color: e.cpuUsageHealth.class == 'green' ? '#06C493' : '#FF526E'}}
    this.cpuUsage.data.push(styleData4)
  })
  this.cpuUsage.options = this.getVerticalBarOptions(this.cpuUsage)
  this.cpuUsage.showLoader = false;
}

setMemoryAvailable(final){
  this.memoryAvailable.showLoader = true;
  this.memoryAvailable.xAxis = []
  this.memoryAvailable.data = []
  final.forEach(e=>{
    this.memoryAvailable.xAxis.push(e.resourceName)

    let styleData4 = { value : e.memoryAvailableHealth.healthPercent , itemStyle: { color: e.memoryAvailableHealth.class == 'green' ? '#06C493' : '#FF526E'}}
    this.memoryAvailable.data.push(styleData4)
  })
  this.memoryAvailable.options = this.getVerticalBarOptions(this.memoryAvailable)
  this.memoryAvailable.showLoader = false;
}

calculateProjectHealth(resourceTypeHealth){
  this.projectHealthData.data = []
  let avg = 100 / resourceTypeHealth.length
  let healthFlag = resourceTypeHealth.every(e=> e.health ? e.health.class == 'green' : false)
  resourceTypeHealth =  healthFlag ? resourceTypeHealth.filter(e=> e.health ? e.health.class == 'green' : false) : resourceTypeHealth.filter(e=> e.health ? e.health.class == 'red' : false) // Get healthly array
  let healthPercent = avg * resourceTypeHealth.length
  this.projectHealthData.total = healthPercent.toFixed(0)
  this.projectHealthData.color = [healthFlag ? '#06C493' : '#FF526E','#EEEFF0']
  this.projectHealthData.text = healthFlag ? 'Healthy' : 'Unhealthy'
  this.projectHealthData.data.push({name:this.projectHealthData.text ,value:healthPercent.toFixed(0)})
  this.projectHealthData.data.push({name:this.projectHealthData.text == 'Unhealthy' ? 'Healthy' : 'Unhealthy',value:(100 - healthPercent).toFixed(0)})
  this.projectHealthData.options = this.getPieOptions(this.projectHealthData)
  this.projectHealthData.showLoader = false
  this.util.setObservable('projectHealth',{project : this.currentProject,healthPercent : healthPercent ,class : healthFlag ? 'green' : 'red'})
  return { healthPercent : healthPercent ,class : healthFlag ? 'green' : 'red'}
}

calculateTotalResourceHealth(projectHealth){
  let healthyCount = 0,unhealthyCount = 0
  let healthyFilter =projectHealth.filter(x=> x.health ? x.health.class == "green" : false) 
  healthyCount = healthyCount + healthyFilter.length
  let unhealthyFilter = projectHealth.filter(x=> x.health ? x.health.class == "red" : false) 
  unhealthyCount = unhealthyCount + unhealthyFilter.length
  this.healthyResourceCount = healthyCount
  this.unhealthyResourceCount = unhealthyCount
  console.log('Healthy Count',healthyCount)
  console.log('UnHealthy Count',unhealthyCount)
}

getMetricHealth(value,type){
  value = value ? value : 0
  let data = this.masterHealthThreshold[type]
  if(!data) return;
  let className,healthPercent;
  className = this.util.doOperation(data.operator,value,data.threshold) ? 'red' : 'green'
  switch(type){
    case 'availability': healthPercent = value;break;
    case 'serverResponse': healthPercent = value > 2000 ? 0: (2000 - value)/20;break;
    case 'failedRequest': healthPercent = value > 10 ? 0 : (10 - value)*10;break;
    case 'cpuUsage': healthPercent = value;break;
    case 'memoryAvailable': healthPercent = value < 100 ? (100 - value)/10 : 100;break;
  }
  return {healthPercent : healthPercent ,class : className}
}

getResourceHealth(value){
  let data = this.masterHealthThreshold
  let average = 100/Object.keys(this.masterHealthThreshold).length
  let array = []
  Object.keys(data).forEach(e=>{
    switch(e){
      case 'availability': array.push(this.getMetricHealth(value.availability,e));break;
      case 'serverResponse':  array.push(this.getMetricHealth(value.serverResponse,e));break;
      case 'failedRequest':  array.push(this.getMetricHealth(value.failedRequest,e));break;
      case 'cpuUsage':  array.push(this.getMetricHealth(value.cpuUsage,e));break;
      case 'memoryAvailable':  array.push(this.getMetricHealth(value.memoryAvailable,e));break;
    }
  })
  let healthFlag = array.every(e=> e.class == 'green')
  array = healthFlag ? array.filter(e=> e.class == 'green') : array.filter(e=> e.class == 'red') // Get healthly array
  let healthPercent = average * array.length
  return {healthPercent : healthPercent ,class : healthFlag ? 'green' : 'red'}
}

getVerticalBarOptions(data){
  var self = this
  let options = {
    color: ['#0B70D4','#62C2FB','#FF5959','#656BFD'],
    tooltip: this.util.getHorizontalBarChartTooltip(),
    grid: {
      left: 0,
      right: '2%',
      bottom: 0,
      top:15,
      // bottom: 62,
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: data.xAxis || [],
      axisTick: {
        show: false
      },
      axisLabel: {
        interval: 0,
        formatter: function (value) {
          return self.util.getDynamicXaxisFormat(data.xAxis,value);
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#333',
        padding: [5, 0, 0, 0],
        fontSize: 10, 
        formatter: function (params) {
          return params +'%'
        },
      },
    },
    series: [{
      data: data.data || [],
      type: 'bar',
      barWidth: 50,
    }]
  };
  return options
}

getPieOptions(data){
  let options = {
    color: data.color || [],
    tooltip:this.util.getPieChartTooltip(data),
    legend: {
      showLegend: data.showLegend || false,
      bottom: '2%',
      left: 'left',
      data: data.legendData
    },
    series: [
        {
            name: data.type,
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            label: {
              color: '#000',
              fontSize: '20',
              position: 'center',
              formatter: function (params){
                return `{a|${data.total}${data.unit}}` + '\n' + `{b|${data.text}}`;; // Use sum variable here
              },
              rich: {
                a: {
                    color: 'black',
                    lineHeight: 52,
                    fontSize:34,
                },
                b:{
                  lineHeight: 18,
                  fontSize:12,
                }
              }
            },
            labelLine: {
              show: false,
            },
            data: data.data || []
        }
    ]
  };
  return options
}

refreshData(type){
  switch(type){
    case 'webApp':
    case 'projectHealthData':{
      this.projectHealthData.refreshTime = new Date()
      this.getMetricsHealthTableData()
      break;
    }
    case 'availability': {
      this.availabilityData.refreshTime = new Date()
       this.setAvailability(this.resourceList)
      break;
    }
    case 'serverResponse': {
      this.serverResponse.refreshTime = new Date()
      this.setServerResponse(this.resourceList)
       break;
    }
    case 'failedRequest': {
      this.failedRequest.refreshTime = new Date()
      this.setFailedRequest(this.resourceList)
      break;
    }
    case 'cpuUsage': {
      this.cpuUsage.refreshTime = new Date()
      this.setCpuUsage(this.resourceList)
      break;
    }
    case 'memoryAvailable': {
      this.memoryAvailable.refreshTime = new Date()
      this.setMemoryAvailable(this.resourceList)
      break;
    }
  }
}

refreshedTimeFormat(time) {
  if (time) return this.util.refreshedTimeFormat(time);
}

}
