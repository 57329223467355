import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
import { LabelType, Options } from "@angular-slider/ngx-slider";
import { NewResourceGroup, SWResourceInputError, SWResourceModel } from '../resources-model';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-synapse-workspace',
  templateUrl: './synapse-workspace.component.html',
  styleUrls: ['./synapse-workspace.component.scss']
})
export class SynapseWorkspaceComponent implements OnInit {
  @Input() projectData : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  synapseAnalyticsmodel: SWResourceModel = {
    SAApacheSprakPoolShow: false,
    SADedicatedPoolShow: false,
    SAAzureADTenantShow: false,
    resourceId:null,
    resourceName:null,
    resourceType:'synapseAnalyticsWorkspaces',
    resourceData:{
      dataLakeStorageGen:'subscriptions',
      adminUsername:'sqladminuser',
      doubleEncryp:'disable',
      allowConnections:true,
      allowOutbound:'no',
      dedicatedSQLPool:[],
      apacheSparkPool:[],
      azureADTenants:[],
      encryptionKey:'key',
      tagList:[{
        tagName:null,
        value:null
      }]
    },
    comments:null,
    createdBy:null,
  };
  show : boolean = false;
  inputError: SWResourceInputError= {
    workspaceMaxLen:false,
    workspaceNameCheck:false,
    workspaceStartCheck:false,
    workspaceEndCheck:false,
    workspacePartText:false,
    workspaceNameUnqiue:false,
    accountNameCheck:true,
    accountNameValid:false,
    storageAccountName:false,
    duplicateSa:false,
    fileNameCheck:false,
    fileNameLen:false,
    fileNameEndCheck:false,
    fileNameStartCheck:false,
    fileNameHypensCheck:false,
    fileNameValidation:false,
    fileNameUnqiue:false,
    fileNameEnd:false,
    fileNameStart:false,
    fileNameHypens:false,
    fileNameLenCheck:false,
    adminStartValid:false,
    adminLenValid:false,
    adminValidChar:false,
    adminReservValid:false,
    adminPLenValid:false,
    adminPValid:false,
    adminPNameValid:false,
    adminPConfirmValid:false,
    VNetDescription:false,
    managedLenValid:false,
    managedValid:false,
    managedPermValid:false,
    managedDupValid:false,
    keyIdentifierReq:false,
    keyIdentifierValid:false,
    //others 
    azureADDomainDescription:false,
    WVDsize:false,
    WVDdescription:false,
    nameLen:false,
    nameValid:false,
    nameDupCheck:false,
    dedicatedNameLen:false,
    dedicatedNameCheck:false,
    dedicatedNameDup:false,
    dedicatedNameValid:false,
    dedicatedNameReserved:false,
    apacheNameLen:false,
    apacheNameStart:false,
    apacheNameEnd:false,
    apacheNameUnique:false,
    apacheNameValid:false,
    apacheNameReserved:false,
    numberMinutesLen:false,
    numberMinutesValid:false,
    numberMinutesIdle:false
  }
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  resourceGroupList:any=[];
  showView:boolean=false;
  nodeSizeList:any=[
    {name:'Small (4 vCores / 32 GB)',id:'Small',amount:0.58,vcores:4},
    {name:'Medium (8 vCores / 64 GB)',id:'Medium',amount:1.16,vcores:8},
    {name:'Large (16 vCores / 128 GB)',id:'Large',amount:2.32,vcores:16},
    {name:'XLarge (32 vCores / 256 GB)',id:'XLarge',amount:4.64,vcores:32},
    {name:'XXLarge (64 vCores / 512 GB)',id:'XXLarge',amount:9.27,vcores:64},
  ]
  
  apacheSparkList:any=[
    {name:'2.4',id:'2.4'},
    {name:'3.0 (preview)',id:'3.0'}
  ]

  performanceLevelList:any=[];

  storedKeysList:any=[];
  storageAccountList:any=[];
  keyVaultList:any=[];
  keyVaultListForKey:any=[];
  keyForMLObj: any={};
  versionList: any[];
  keyList: any[];
  fileSystemNameList: any=[];
  assignUserList: any=[];
  assignApplicationList: any=[];
  createSAAccObj: any={};
  createSAFileObj:any= {};
  SADedicatedPoolObj: any={};
  SAApacheSparkPoolObj:any= {};
  SAAzureADTenantObj:any= {};
  tenantList: any=[];
  value: number = 3;
  highValue: number = 10;
  options: Options = {
    floor: 3,
    ceil: 200,
    translate:(value: number, label: LabelType): any => {
      return ""
  }
  }
  existingNewVMList: any=[];
  existingNewSAList: any=[];
  existingNewSYList: any=[];
  existingNewMLList: any=[];
  
  constructor(private util:UtilService,private loader:LoaderService) { }

  async ngOnInit() {
    this.performanceLevelList = this.util.getStatic('performanceLevelList');
    const randomVal=`'${Math.random}'`;
    this.loader.queActions('push',randomVal);
    await this.getResourceGroupList();
    let parentResourceData,resourceObj;
    if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
      this.synapseAnalyticsmodel.resourceData.subnet=this.parentResourceDtls.resourceName;
      parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
      this.synapseAnalyticsmodel.resourceData.vnetName=parentResourceData.vnetName;
      resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
      this.synapseAnalyticsmodel.resourceData.resourceGroup=resourceObj.name;
      this.synapseAnalyticsmodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
      this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
    }

    await this.getListByPropName('storageAccountsForSA',undefined,undefined);
    await this.getListByPropNameUsingGrpah('users');
    // await this.getListByPropNameUsingGrpah('applications');
    await this.getListByPropNameUsingGrpah('servicePrincipals');
    await this.getListByPropName('vaults',undefined,undefined);
    await this.getListByPropName('tenants',undefined,undefined);   
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
      }
    }
    this.synapseAnalyticsmodel.resourceData.userObjectId=userObjectId;
    this.synapseAnalyticsmodel.resourceData.a3sAppObjectId="051a67ff-bfd8-44d7-b7b1-cf06dfb66497";
    await this.getExistNewlyResourceDetails('synapseAnalyticsWorkspaces');
    await this.getExistNewlyResourceDetails('storageAccounts');
    await this.getExistNewlyResourceDetails('machineLearningWorkspaces');
    if(this.sideSheetViewType=='edit'){
      this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null).then(async res=>{
        this.synapseAnalyticsmodel=res;
        this.synapseAnalyticsmodel.resourceData=JSON.parse(res.resourceData);
        if(this.parentResourceDtls['updateRequestFrom']){
          this.synapseAnalyticsmodel.resourceData.vnetName=this.parentResourceDtls.resourceName;
          this.synapseAnalyticsmodel.resourceData.resourceGroup=resourceObj.name;
        }
      });
    }
    this.loader.queActions('pop',randomVal);
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType === 'virtualMachines') {
        this.existingNewVMList=res;
      } else if(resourceType === 'storageAccounts') {
        this.existingNewSAList=res;
      }else if(resourceType === 'synapseAnalyticsWorkspaces') {
        this.existingNewSYList=res;
      }else if(resourceType === 'machineLearningWorkspaces') {
        this.existingNewMLList=res;
      }
    },err=>{
      if(resourceType === 'virtualMachines') {
        this.existingNewVMList=[];
      } else if(resourceType === 'synapseAnalyticsWorkspaces') {
        this.existingNewSYList=[];
      } else if(resourceType === 'storageAccounts') {
        this.existingNewSAList=[];
      } else if(resourceType === 'machineLearningWorkspaces') {
        this.existingNewMLList=[];
      }
    });
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
      });
  }

  onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.synapseAnalyticsmodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }

      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.synapseAnalyticsmodel.resourceData.resourceGroup=undefined;
      
    });
  }

  editNewResourceGroup(item,index){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item);    
    this.show = !this.show;
  }

  async getNewResourceGroup(){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addTagVM(){
    this.synapseAnalyticsmodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTagVM(index){
    this.synapseAnalyticsmodel.resourceData.tagList.splice(index,1);
  }

  hideShow(){
    this.showView=!this.showView;
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName=='storageAccountsForSA'?'storageAccounts':propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName == 'vaults'){
        this.keyVaultList=res.value;
        this.keyVaultListForKey=_.cloneDeep(res.value);
      }else if(propName=='storageAccountsForSA'){
        this.storageAccountList=res.value.filter(dt=>dt.properties&&dt.properties.isHnsEnabled);
      }else if(propName=='tenants'){
        this.tenantList=res.value;
      }
      return  result;
    },err=>{
    });
  }

  async getListByPropNameUsingGrpah(propName){
    
    let reqObj={
      fetchPropName:propName
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropNameUsingGrpah',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='users'){
        this.assignUserList=res.value;
       } else if(propName=='servicePrincipals'){
          this.assignApplicationList=res.value;
      }
      return  result;
    },err=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='users'){
        this.assignUserList=[];
       } else if(propName=='servicePrincipals'){
          this.assignApplicationList=[];
      }
    });
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.synapseAnalyticsmodel);
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  validateWorksapceName(event,maxLimit){
    let value=event.target.value;
    this.inputError.workspaceMaxLen=false;
    this.inputError.workspaceNameCheck=false;
    this.inputError.workspaceEndCheck=false;
    this.inputError.workspaceStartCheck=false;
    this.inputError.workspacePartText=false;
    this.inputError.workspaceNameUnqiue=false;
    if(value){
      let count=0,hypCount;
      if(value.includes('-ondemand')){
        this.inputError.workspacePartText = true;
        return;
      }
    for(let input of value){
      if(count==0&&(input=='-')){
        this.inputError.workspaceStartCheck = true;
        return;
      }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError.workspaceNameCheck = true;
        return;
      }
      count=count+1;
    }
      if(value.length>maxLimit){
        this.inputError.workspaceMaxLen=true;
        return;
      }
      if(value[value.length-1]=="-"){
        this.inputError.workspaceEndCheck=true;
        return;
      }
    }
  }

  async checkSynapseWorkspaceName(event) {    
    let value=event.target.value;
    let findObj;
    if(this.existingNewSYList&&value){
      findObj=this.existingNewSYList.find(dt=>value.toLowerCase()==dt.resourceName.toLowerCase()&&dt.resourceId!=this.synapseAnalyticsmodel.resourceId);
      if(findObj){
        this.inputError.workspaceNameUnqiue=true;
      }
    }
    if(!findObj&&value){
      let reqObj={
        subscriptionId:this.projectData.subscriptionId,
        name:value
      }
      await this.util.handleRequest('post','a3s_architectureBlueprint_checkSynapseWorkspaceName',[],reqObj,null,null,true).then(res=>{
        if(res&&res.available){
          this.inputError.workspaceNameUnqiue =false; 
        }else if(res&&res.reason) {
          this.inputError.workspaceNameUnqiue =true; 
        }
      })
    }
  }

  storageMLValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError.duplicateSa =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.storageAccountName = true;
        return
    }
    else this.inputError.storageAccountName = false;

    for(let input of value){
      if(!input||!(this.isCharALowerCaseLetter(input) || this.isCharANumber(input))){
        this.inputError.storageAccountName = true;
        return;
      }
    }
    this.inputError.storageAccountName = false;
    this.saMLDuplicateCheck(limitField)
  }

  saMLDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateSa =false; 
    if(value){
   let findObj=this.storageAccountList.find(dt=>dt.name.toLowerCase()==value.toLowerCase()&&!dt.createNew);
    if(this.existingNewSYList&&!findObj){
      findObj=this.existingNewSYList.find(dt=>{
        if(dt.resourceId!=this.synapseAnalyticsmodel.resourceId){
          let resourceData=JSON.parse(dt.resourceData);
          if(resourceData.storageAccount&&resourceData.storageAccount.name.toLowerCase()==value.toLowerCase()){
            return true;
          }
        }
      });
    }else if(this.existingNewSAList&&!findObj){
      findObj=this.existingNewSAList.find(dt=>{
        if(dt.resourceName&&dt.resourceName.toLowerCase()==value.toLowerCase()){
          return true;
        }
      });
    } else if(this.existingNewMLList&&!findObj){
      findObj=this.existingNewMLList.find(dt=>{
        if(dt.resourceData){
          let resourceData=JSON.parse(dt.resourceData);
          if(resourceData.storageAccount&&resourceData.storageAccount.name.toLowerCase()==value.toLowerCase()){
            return true;
          }
        }
      });
    }
    if(findObj){
      this.inputError.duplicateSa =true; 
      return;
    }else{
      this.inputError.duplicateSa =false; 
    }

    this.checkStorageNameAvail(event,'duplicateSa');
  }
  }

  validateFileSystemName(event,minLimit,maxLimit){
    let value=event.target.value;
    this.inputError.fileNameLen=false;
    this.inputError.fileNameCheck=false;
    this.inputError.fileNameEndCheck=false;
    this.inputError.fileNameStartCheck=false;
    this.inputError.fileNameHypensCheck=false;
    if(value){
      let count=0,hypCount;
    for(let input of value){
      if(count==0&&(input=='-')){
        this.inputError.fileNameStartCheck = true;
        return;
      }
      if(input=="-"){
        if(hypCount&&hypCount+1==count){
          this.inputError.fileNameHypensCheck = true;
          return;
        }
        hypCount=count;
      }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError.fileNameCheck = true;
        return;
      }
      count=count+1;
    }
      if(value.length>maxLimit||value.length<minLimit){
        this.inputError.fileNameLen=true;
        return;
      }
      if(value[value.length-1]=="-"){
        this.inputError.fileNameEndCheck=true;
        return;
      }
    }
  }

  validateFileSystemNamePop(event,minLimit,maxLimit){
    let value=event.target.value;
    this.inputError.fileNameLenCheck=false;
    this.inputError.fileNameValidation=false;
    this.inputError.fileNameEnd=false;
    this.inputError.fileNameStart=false;
    this.inputError.fileNameHypens=false;
    this.inputError.fileNameUnqiue=false;
    if(value){
      let count=0,hypCount;
    for(let input of value){
      if(count==0&&(input=='-')){
        this.inputError.fileNameStart = true;
        return;
      }
      if(input=="-"){
        if(hypCount&&hypCount+1==count){
          this.inputError.fileNameHypens = true;
          return;
        }
        hypCount=count;
      }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError.fileNameValidation = true;
        return;
      }
      count=count+1;
    }
      if(value.length>maxLimit||value.length<minLimit){
        this.inputError.fileNameLenCheck=true;
        return;
      }
      if(value[value.length-1]=="-"){
        this.inputError.fileNameEnd=true;
        return;
      }

      let findObj=this.fileSystemNameList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
      if(!findObj){
        if(this.existingNewSYList){
          findObj=this.existingNewSYList.find(dt=>{
            if(dt.resourceId!=this.synapseAnalyticsmodel.resourceId){
              let resourceData=JSON.parse(dt.resourceData);
              if(resourceData.fileSystemName&&resourceData.fileSystemName.name.toLowerCase()==value.toLowerCase()){
                return true;
              }
            }
          });
        }
      }
      if(findObj){
        this.inputError.fileNameUnqiue=true;
      }
    }
  }

  validateAccountNameSA(event,minLimit,maxLimit){
    let value=event.target.value;
    this.inputError.accountNameValid=false;
    this.inputError.accountNameCheck=true;
    if(value){
      if(value.length>maxLimit||value.length<minLimit){
        this.inputError.accountNameValid=true;
        return;
      }

      for(let input of value){
        if( !(this.isCharALowerCaseLetter(input) || this.isCharANumber(input)) ){
          this.inputError.accountNameValid = true;
          return;
        }
      }
      this.checkStorageNameAvail(event,'accountNameCheck');
    }
  }

  changeStorageAccountSA(){
    this.fileSystemNameList=[];
    if(this.synapseAnalyticsmodel.resourceData.storageAccount&&this.synapseAnalyticsmodel.resourceData.storageAccount.id){
      let reqObj={
        storageAccountId:this.synapseAnalyticsmodel.resourceData.storageAccount.id,
        token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      }
      this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
        if(res){
          this.fileSystemNameList=res.value;
        }
      });
    }
  }

  validateAdminUsername(limitField, limitNum){
    let value =limitField.target.value;
    this.inputError.adminStartValid = false;
    this.inputError.adminLenValid =false;
    this.inputError.adminValidChar =false;
    this.inputError.adminReservValid =false;
    if(value){
      if (value.length > limitNum) {
          this.inputError.adminLenValid = true;
          return;
      }

      if( !(this.isCharALetter(value[0]))){
        this.inputError.adminStartValid = true;
        return;
      }

      let regex=/\`|\~|\ |\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      if(regex.test(value)){
        this.inputError.adminValidChar =true;
        return;
      } 

      if(value=='admin'||value=='administrator'||value=='sa'||value=='root'||value=='dbmanager'||value=='loginmanager'||value=='dbo'||value=='guest'||value=='public'){
        this.inputError.adminReservValid =true;
        return;
      }
    }
  }

  validateAdminPassword(limitField,minLen,maxLen){
    let value =limitField.target.value;
    this.inputError.adminPLenValid =false;
    this.inputError.adminPValid =false;
    this.inputError.adminPNameValid =false;
    if(value){
      if (value.length > maxLen||value.length<minLen) {
          this.inputError.adminPLenValid = true;
          return;
      }

      let count=0;
      if((/[A-Z]/).test(value)){
        count=count+1;
      }
      if((/[a-z]/).test(value)){
        count=count+1;
      }
      if((/[0-9]/).test(value)){
        count=count+1;
      }
      let regexT=/\%|\#|\$|\!|\`|\ |\(|\)|\-|\-|\~|\@|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      if(regexT.test(value)){
        count=count+1;
      }
      if(count<3){
        this.inputError.adminPValid = true;
        return;
      } 
    }
  }
  
  validateAdminConfrimPassword(event){
    let value =event.target.value;
    this.inputError.adminPConfirmValid =false;
    if(value){
      if(value!=this.synapseAnalyticsmodel.resourceData.adminPassword){
        this.inputError.adminPConfirmValid =true;
      }
    }
  }

  validateManagedResourceGroup(event,maxLen){
    let value =event.target.value;
    this.inputError.managedLenValid =false;
    this.inputError.managedValid =false;
    this.inputError.managedDupValid =false;
    if(value){
      if(value.length>maxLen){
        this.inputError.managedLenValid =true;
      }

      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) ||input=='.' || input=='_' || input=='-' || input=='"') ){
          this.inputError.managedValid = true;
          return;
        }
      }

      if(value[value.length]=='.'){
        this.inputError.managedValid = true;
        return;
      }

      let findObj=this.resourceGroupList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
      if(!findObj){
        if(this.existingNewSYList){
          findObj=this.existingNewSYList.find(dt=>{
            if(dt.resourceId!=this.synapseAnalyticsmodel.resourceId){
              let resourceData=JSON.parse(dt.resourceData);
              if(resourceData.managedResourceGroup&&resourceData.managedResourceGroup.toLowerCase()==value.toLowerCase()){
                return true;
              }
            }
          });
        }
      }
      if(findObj){
        this.inputError.managedDupValid=true;
      }
    }
  }

  validateDPName(event,maxLen){
    let value =event.target.value;
    this.inputError.dedicatedNameLen =false;
    this.inputError.dedicatedNameCheck =false;
    this.inputError.dedicatedNameDup =false;
    this.inputError.dedicatedNameValid =false;
    this.inputError.dedicatedNameReserved =false;
    if(value){
      if(value.length>maxLen){
        this.inputError.dedicatedNameLen =true;
        return;
      }

      let regex=/\`|\~|\-|\@|\^|\&|\%|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      if(regex.test(value)||value[value.length-1]=="."||value[value.length-1]==" "){
        this.inputError.dedicatedNameValid =true;
        return;
      }
      
      let findObj;
      if(!findObj){
        if(this.existingNewSYList){
          findObj=this.existingNewSYList.find(dt=>{
            if(dt.resourceId!=this.synapseAnalyticsmodel.resourceId&&dt.resourceData){
              let resourceData=JSON.parse(dt.resourceData);              
              if(resourceData.dedicatedSQLPool&&resourceData.dedicatedSQLPool.find(dt=>dt.name.toLowerCase()==value.toLowerCase())){
                return true;
              }
            }
          });
        }
        if(this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool&&!findObj){
          findObj=this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
         }
      }
      if(findObj){
        this.inputError.dedicatedNameDup=true;
      }
    }
  }
  
  validateASPName(event,maxLen){
    let value =event.target.value;
    this.inputError.apacheNameLen =false;
    this.inputError.apacheNameStart =false;
    this.inputError.apacheNameEnd =false;
    this.inputError.apacheNameUnique =false;
    this.inputError.apacheNameValid =false;
    this.inputError.apacheNameReserved =false;
    if(value){
      if(value.length>maxLen){
        this.inputError.apacheNameLen =true;
        return;
      }

      if(!this.isCharALetter(value[0])){
        this.inputError.apacheNameStart =true;
        return;
      }      

      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input)) ){
          this.inputError.apacheNameValid = true;
          return;
        }
      }
      
      let findObj;
      if(!findObj){
        if(this.existingNewSYList){
          findObj=this.existingNewSYList.find(dt=>{
            if(dt.resourceId!=this.synapseAnalyticsmodel.resourceId&&dt.resourceData){
              let resourceData=JSON.parse(dt.resourceData);              
              if(resourceData.apacheSparkPool&&resourceData.apacheSparkPool.find(dt=>dt.name.toLowerCase()==value.toLowerCase())){
                return true;
              }
            }
          });
        }
        if(this.synapseAnalyticsmodel.resourceData.apacheSparkPool&&!findObj){
         findObj=this.synapseAnalyticsmodel.resourceData.apacheSparkPool.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
        }
      }
      if(findObj){
        this.inputError.apacheNameUnique=true;
      }
    }
  }

  validateASPNumberMinu(event,minLen,maxLen){
    let value =event.target.value;
    this.inputError.numberMinutesLen =false;
    this.inputError.numberMinutesValid =false;
    this.inputError.numberMinutesIdle =false;
    if(value){
      if(value>maxLen||value<minLen){
        this.inputError.numberMinutesLen =true;
        return;
      }      

      for(let input of value){
        if( !(this.isCharANumber(input)) ){
          this.inputError.numberMinutesValid = true;
          return;
        }
      }
      
    }
  }

  performanceLevelChange(){
    if(this.SADedicatedPoolObj.performanceLevel){
      this.SADedicatedPoolObj.priceDetails.count=parseInt(this.SADedicatedPoolObj.performanceLevel.split('DW')[1].split('c')[0])/100;
      this.SADedicatedPoolObj.priceDetails.amount=1.51*this.SADedicatedPoolObj.priceDetails.count;
    }
  }

  addTenants(){
    this.synapseAnalyticsmodel.SAAzureADTenantShow=true;
    this.SAAzureADTenantObj={
      autoscale:'azure'
    };
  }

  validateTenant(event){
    let value=event.target.value;

  }

  createNewDSP(){
    this.synapseAnalyticsmodel.SADedicatedPoolShow=true;
    this.SADedicatedPoolObj={
      geoReduandant:'yes',
      performanceLevel:'DW100c',
      visibleGeoRedundantOption:false,
      tagList:[{}],
      priceDetails:{
        amount:1.51,
        count:1
      }
    };
    this.checkVisibleGeoRedundantOption();
  }

  checkVisibleGeoRedundantOption(){
    this.SADedicatedPoolObj.visibleGeoRedundantOption=false;
    if(this.synapseAnalyticsmodel&&this.synapseAnalyticsmodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.synapseAnalyticsmodel.resourceData.resourceGroup);
      if(resourceObj.resourceGroupLocation||resourceObj.location){
        let reqObj={
          subscriptionId:this.projectData.subscriptionId,
          location:resourceObj.resourceGroupLocation||resourceObj.location,
          fetchPropName:'capabilities',
          token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
        }
        this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
          if(res&&res.status&&res.status!="Available"){
            this.SADedicatedPoolObj.visibleGeoRedundantOption=true;
          }
        });
      }else{
        this.SADedicatedPoolObj.visibleGeoRedundantOption=false;
      }
    } 
  }

  addTagSA(fieldObj){
    this[fieldObj].tagList.push({});
  }

  deleteTagSA(index,fieldObj){
    this[fieldObj].tagList.splice(index,1);
  }

  silderValueChange(){
    if(this.SAApacheSparkPoolObj.numberNodes){
      this.value=parseInt(this.SAApacheSparkPoolObj.numberNodes.toString());
    }
    if(this.SAApacheSparkPoolObj.maxNumberNodes){
      this.highValue=parseInt(this.SAApacheSparkPoolObj.maxNumberNodes.toString());
    }
    if(this.SAApacheSparkPoolObj.nodeSize){
      this.SAApacheSparkPoolObj.priceDetails={
        amountMin:(this.SAApacheSparkPoolObj.nodeSize.amount*this.value).toFixed(2),
        amountMax:(this.SAApacheSparkPoolObj.nodeSize.amount*this.highValue).toFixed(2)
      }
    }
  }

  changeValSider(){
    if(this.SAApacheSparkPoolObj.nodeSize){
      this.SAApacheSparkPoolObj.priceDetails={
        amountMin:(this.SAApacheSparkPoolObj.nodeSize.amount*this.value).toFixed(2),
        amountMax:(this.SAApacheSparkPoolObj.nodeSize.amount*this.highValue).toFixed(2)
      };
      
      this.SAApacheSparkPoolObj.numberNodes=this.value;
      this.SAApacheSparkPoolObj.maxNumberNodes=this.highValue;
      
    }
  }

  nodeSizeChange(){
    if(this.SAApacheSparkPoolObj.nodeSize){
      this.SAApacheSparkPoolObj.priceDetails={
        amountMin:(this.SAApacheSparkPoolObj.nodeSize.amount*this.value).toFixed(2),
        amountMax:(this.SAApacheSparkPoolObj.nodeSize.amount*this.highValue).toFixed(2)
      }
    }
  }
  
  createNewASP(){
    this.synapseAnalyticsmodel.SAApacheSprakPoolShow=true;
    this.SAApacheSparkPoolObj={
      nodeSize:this.nodeSizeList[1],
      autoscale:'enable',
      automaticPausing:'enable',
      apacheSpark:'2.4',
      packages:'enable',
      numberMinutes:15,
      numberNodes:3,
      maxNumberNodes:10,
      tagList:[{}],
      priceDetails:{
        amountMin:(this.nodeSizeList[1].amount*3).toFixed(2),
        amountMax:(this.nodeSizeList[1].amount*10).toFixed(2)
      }
    };
  }

  editDedicateSqlPool(i){
    let temp=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool[i]);
    this.SADedicatedPoolObj=_.cloneDeep(temp);
    this.SADedicatedPoolObj.updateIndex=i;
    this.synapseAnalyticsmodel.SADedicatedPoolShow=true;
  }
  
  editTenantSA(i){
    let temp=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.azureADTenants[i]);
    this.SAAzureADTenantObj=_.cloneDeep(temp);
    this.SAAzureADTenantObj.updateIndex=i;
    this.synapseAnalyticsmodel.SAAzureADTenantShow=true;
  }

  deleteDedicateSqlPool(i){
    this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool.splice(i,1);
  }

  deleteTenantSA(i){
    this.synapseAnalyticsmodel.resourceData.azureADTenants.splice(i,1);
  }

  editApacheSparkPool(i){
    let temp=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.apacheSparkPool[i]);
    this.SAApacheSparkPoolObj=_.cloneDeep(temp);
    this.SAApacheSparkPoolObj.updateIndex=i;
    this.synapseAnalyticsmodel.SAApacheSprakPoolShow=true;
  }

  deleteApacheSparkPool(i){
    this.synapseAnalyticsmodel.resourceData.apacheSparkPool.splice(i,1);
  }

  async checkStorageNameAvail(event,flagCheck){    
    let value=event.target.value;
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      name:value
    }
    await this.util.handleRequest('post','a3s_architectureBlueprint_checkStorageName',[],reqObj,null,null,true).then(res=>{
      if(res&&res.nameAvailable){
        this.inputError[flagCheck] =false; 
      }else{
        this.inputError[flagCheck] =true; 
      }
    })
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.synapseAnalyticsmodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  VNetDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VNetDescription = true;
    }
    else this.inputError.VNetDescription = false;
  }

  closeOtherPop(p1,p2){
    if(p1){
      p1.hide();
    }

    if(p2){
      p2.hide();
    }
  }

  changeFileSparkCinfig(fileList: FileList): void {
    let file = fileList[0];
    let fileReader: FileReader = new FileReader();
    let self = this;
    this.SAApacheSparkPoolObj.fileDetails=file;
    this.SAApacheSparkPoolObj.fileName=file.name;
    fileReader.onloadend = function(x) {
      self.SAApacheSparkPoolObj.sparkConfigPropertiesContent = fileReader.result;
    }
    fileReader.readAsText(file);
  }

  deleteFileUpload(){
    this.SAApacheSparkPoolObj.fileDetails=undefined;
    this.SAApacheSparkPoolObj.fileUpload=undefined;
    this.SAApacheSparkPoolObj.sparkConfigPropertiesContent=undefined;
    this.SAApacheSparkPoolObj.showUploadDetails=false;
  }

  keyVaultChangeForKey(){
    this.keyList=[];
    this.keyForMLObj.key=undefined;
    if(this.keyForMLObj.keyVault){
      let reqObj={
        vaultUri:this.keyForMLObj.keyVault.name,
        token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      }
      this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
        if(res){
          this.keyList=res.value;
        }
      });
    }
  }

  saveResourceDetails(){
    var model = this.synapseAnalyticsmodel;
    var resourceObj=this.resourceGroupList.find(dt=>dt.name.toLowerCase()==this.synapseAnalyticsmodel.resourceData.resourceGroup.toLowerCase());
    model.resourceData['resourceGroupLocation']=resourceObj.location;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.synapseAnalyticsmodel;
    var resourceObj=this.resourceGroupList.find(dt=>dt.name.toLowerCase()==this.synapseAnalyticsmodel.resourceData.resourceGroup.toLowerCase());
    model.resourceData['resourceGroupLocation']=resourceObj.location;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  openPop(popId,flag){
    if(flag=="createSAAccount"){
      let findObj=this.storageAccountList.find(dt=>dt.createNew);
      if(findObj){
        this.createSAAccObj=_.cloneDeep(findObj);
      }else{
        this.createSAAccObj={};
      }
    }
    if(flag=="createSAFile"){
      let findObj=this.fileSystemNameList.find(dt=>dt.createNew);
      if(findObj){
        this.createSAFileObj=_.cloneDeep(findObj);
      }else{
        this.createSAFileObj={};
      }
    }
    popId.show();
  }

  updateSubSideSheet(type){
    switch (type) {
      case 'SADedicatedPool':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool);
        this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=[];
        tempList[this.SADedicatedPoolObj.updateIndex]=this.SADedicatedPoolObj;
        tempList[this.SADedicatedPoolObj.updateIndex].updateIndex=undefined;
        this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=_.cloneDeep(tempList);
        this.synapseAnalyticsmodel.SADedicatedPoolShow=false;        
        return
      }
      case 'SAApacheSprakPool':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.apacheSparkPool);
        this.synapseAnalyticsmodel.resourceData.apacheSparkPool=[];
        tempList[this.SAApacheSparkPoolObj.updateIndex]=this.SAApacheSparkPoolObj;
        tempList[this.SAApacheSparkPoolObj.updateIndex].updateIndex=undefined;
        this.synapseAnalyticsmodel.resourceData.apacheSparkPool=_.cloneDeep(tempList);
        this.synapseAnalyticsmodel.SAApacheSprakPoolShow=false;        
        return
      }      
      case 'SAAzureADTenant':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.azureADTenants);
        this.synapseAnalyticsmodel.resourceData.azureADTenants=[];
        tempList[this.SAAzureADTenantObj.updateIndex]=this.SAAzureADTenantObj;
        tempList[this.SAAzureADTenantObj.updateIndex].updateIndex=undefined;
        this.synapseAnalyticsmodel.resourceData.azureADTenants=_.cloneDeep(tempList);
        this.synapseAnalyticsmodel.SAAzureADTenantShow=false;        
        return
      }
    }
  }

  saveSubSideSheet(type){
    switch(type){
      case 'selectKeyVault': {
        this.synapseAnalyticsmodel.resourceData.keyVaultData=_.cloneDeep(this.keyForMLObj);
        return
      }
      case 'createSAAccount':{
        let tempList=_.cloneDeep(this.storageAccountList);
        this.storageAccountList=[];
        this.createSAAccObj.createNew=true;
        let findObj=tempList.find(dt=>!dt.id);
        if(findObj){
          tempList[0]=_.cloneDeep(this.createSAAccObj);
        }else{
        tempList.splice(0,0,this.createSAAccObj);
        }
        this.storageAccountList=tempList;
        this.synapseAnalyticsmodel.resourceData.storageAccount=_.cloneDeep(this.createSAAccObj);
        return
      }
      case 'createSAFileObj':{
        let tempList=_.cloneDeep(this.fileSystemNameList);
        this.fileSystemNameList=[];
        this.createSAFileObj.createNew=true;
        let findObj=tempList.find(dt=>!dt.id);
        if(findObj){
          tempList[0]=_.cloneDeep(this.createSAFileObj);
        }else{
        tempList.splice(0,0,this.createSAFileObj);
        }
        this.fileSystemNameList=tempList;
        this.synapseAnalyticsmodel.resourceData.fileSystemName=_.cloneDeep(this.createSAFileObj);
        return
      }
      case 'SADedicatedPool':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool);
        this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=[];
        tempList.push(this.SADedicatedPoolObj);
        this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=tempList;
        this.synapseAnalyticsmodel.SADedicatedPoolShow=false;
        return
      }
      case 'SAApacheSprakPool':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.apacheSparkPool);
        this.synapseAnalyticsmodel.resourceData.apacheSparkPool=[];
        tempList.push(this.SAApacheSparkPoolObj);
        this.synapseAnalyticsmodel.resourceData.apacheSparkPool=tempList;
        this.synapseAnalyticsmodel.SAApacheSprakPoolShow=false;
        return
      }
      case 'SAAzureADTenant':{        
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.azureADTenants);
        this.synapseAnalyticsmodel.resourceData.azureADTenants=[];
        tempList.push(this.SAAzureADTenantObj);
        this.synapseAnalyticsmodel.resourceData.azureADTenants=tempList;
        this.synapseAnalyticsmodel.SAAzureADTenantShow=false;
        return
      }
    }
  }

  checkDataDisabled(name){
    switch(name){
     case 'synapseAnalytics': return !this.synapseAnalyticsmodel.resourceData.resourceGroup||!this.synapseAnalyticsmodel.resourceName||this.inputError.workspaceMaxLen||this.inputError.workspaceNameCheck||this.inputError.workspaceStartCheck
      ||this.inputError.workspaceEndCheck||this.inputError.workspacePartText||this.inputError.workspaceNameUnqiue||(this.synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'&&(!this.synapseAnalyticsmodel.resourceData.storageAccount
      ||!this.synapseAnalyticsmodel.resourceData.fileSystemName))||(this.synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'&&(!this.synapseAnalyticsmodel.resourceData.fileSystemNameVal||this.inputError.fileNameCheck||this.inputError.fileNameLen
      ||this.inputError.fileNameEndCheck||this.inputError.fileNameStartCheck||this.inputError.fileNameHypensCheck||!this.synapseAnalyticsmodel.resourceData.accountNameVal||!this.inputError?.accountNameCheck||this.inputError.accountNameValid))
      ||!this.synapseAnalyticsmodel.resourceData.adminUsername||this.inputError.adminStartValid||this.inputError.adminLenValid||this.inputError.adminValidChar||this.inputError.adminReservValid ||(this.synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'&&this.synapseAnalyticsmodel.resourceData.encryptionKey=='identifier'&&(!this.synapseAnalyticsmodel.resourceData.keyIdentifier));
      case 'SAApacheSprakPool': return !this.SAApacheSparkPoolObj.name||this.inputError.apacheNameValid||this.inputError.apacheNameStart||this.inputError.apacheNameEnd||this.inputError.apacheNameUnique||this.inputError.apacheNameReserved||this.inputError.apacheNameLen||!this.SAApacheSparkPoolObj.numberMinutes||this.inputError.numberMinutesValid||this.inputError.numberMinutesLen||this.inputError.numberMinutesIdle||!this.SAApacheSparkPoolObj.numberNodes||!this.SAApacheSparkPoolObj.maxNumberNodes
    }
  }
}
