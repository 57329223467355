<div class="introduction">
    <p class="heading" id="0">Overview </p>
       <p class="para mb-0">The landing page of Security bucket shows the key security related metrics for
            the first project.   </p>
            <img src="assets\user-manual\security\Overview.svg" >
            <figcaption class="caption">Security  - Overview Dashboard </figcaption>
            <ul class="mb-0" >
                <li>Click table icon to view data in tabular format.</li>
                <li>Download the data using Download CSV button.</li>
                <li>Use Filters at the top to drill down further into a project and choose the 
                    scope within which you want to view & analyze the data.</li>
                <li><b>Total assessed: </b>number of assessed resources. </li>
                <li><b>Found unhealthy:</b> number of resources not following security best practices. </li>
                <li><b>Found unmonitored:</b> out of A3S security monitoring. </li>
                <li><b>Unregistered subscriptions:</b> subscriptions out of A3S security monitoring. </li>
            </ul>
            <img src="assets\user-manual\security\Card.svg" >
   
 </div>
  