import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { stringify } from 'querystring';
import { A3sDateTimeFormat } from '../pipes/custom.pipe';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private titlecase :TitleCasePipe,private a3sDate:A3sDateTimeFormat) { }


  generateCommonCSV(name,data){
    // name.replace(/(?!^)([A-Z]|\d+)/g, " $1")
    let downloadName = name
    let fileName = downloadName+'.csv';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name);
    let cols = []
    data.columns.forEach(e=>{
       cols.push( { header: e.value, key: e.key+'key', width: 60,style: { font: { name: 'Arial Black' ,bold:true} } },)
    })
    worksheet.columns = cols
    data.rows.forEach(e=>{
      let obj={}
      data.columns.forEach(x=>{
        obj[x.key+'key'] = e[x.key]
      })
      worksheet.addRow(obj)
    })
    workbook.csv.writeBuffer().then((datas) => {
      let blob = new Blob([datas as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    }).catch((err)=>{
      console.log('Failed to download...')
    })
  }

  generateInsightsCSV(name,data){
    let downloadName = name.replace(/(?!^)([A-Z]|\d+)/g, " $1")
    let fileName = this.titlecase.transform(downloadName)+'.csv';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name);
    let cols = []
    data.columns.forEach(e=>{
      if(name=='Custom Query'){
        cols.push( { header:this.splitWordByCapsExceptAbb(e.name), key: e.name+'key', width: 60,style: { font: { name: 'Arial Black' ,bold:true} } },)
      }else{
        cols.push( { header: e.name, key: e.name+'key', width: 60,style: { font: { name: 'Arial Black' ,bold:true} } },)
      }
    })
    worksheet.columns = cols
    data.rows.forEach(e=>{
      let obj={}
      data.columns.forEach(x=>{
        obj[x.name+'key'] = e[x.name]
      })
      worksheet.addRow(obj)
    })
    workbook.csv.writeBuffer().then((datas) => {
      let blob = new Blob([datas as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    }).catch((err)=>{
      console.log('Failed to download...')
    })
  }

  generateCSVForTableData(name,tableId,ignoreName?:any) {
    let downloadName = name.replace(/(?!^)([A-Z]|\d+)/g, " $1")
    let fileName = ignoreName ? name+'.csv': this.titlecase.transform(downloadName)+'.csv';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name);
    let cols = [];
    for(let j=0;j<tableId.rows[0].cells.length;j++){
      let dt=tableId.rows[0].cells[j];
      cols.push({ header: dt.innerText, key: dt.innerText+'key', width: 60,style: { font: { name: 'Arial Black' ,bold:true} } },)
    }
    // data.columns.forEach(e=>{
    //    cols.push( { header: e.name, key: e.name+'key', width: 60,style: { font: { name: 'Arial Black' ,bold:true} } },)
    // })
    worksheet.columns = cols;
    // data.rows.forEach(e=>{
    //   let obj={}
    //   data.columns.forEach(x=>{
    //     obj[x.name+'key'] = e[x.name]
    //   })
    //   worksheet.addRow(obj)
    // })
    for(let i=1;i<tableId.rows.length;i++){
        let obj={};
        for(let j=0;j<tableId.rows[i].cells.length;j++){
          let dt=tableId.rows[i].cells[j];
          obj[cols[j].key] = dt.innerText; 
        }
        worksheet.addRow(obj);
    }
    workbook.csv.writeBuffer().then((datas) => {
      let blob = new Blob([datas as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    }).catch((err)=>{
      console.log('Failed to download...')
    })
  }
  
  generateCSVForCustom(name,headers,dataList): void {
    let fileName = name+'.csv';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name);
    let cols = [];
    headers.forEach(header=>{
      cols.push({ header: header, key: header+'key', width: 60,style: { font: { name: 'Arial Black' ,bold:true} } },)
    });
    worksheet.columns = cols;
    dataList.forEach(data=>{
      let obj={};
      data.forEach((value,index)=>{
          obj[headers[index]+'key'] = value;
      })
      worksheet.addRow(obj);
    })
    workbook.csv.writeBuffer().then((datas) => {
      let blob = new Blob([datas as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    }).catch((err)=>{
      console.log('Failed to download...')
    })
  }

  generateHealthMonitoringCSV(name,data,additionalData?:any,ignoreName?:any){
    let downloadName=name.split(/(?=[A-Z])/).join(" ")
    let fileName = ignoreName ? name+'.csv': this.titlecase.transform(downloadName)+'.csv';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name);
    worksheet.columns = this.getTilesColumns(name,additionalData)
    console.log('CSV Data',data)
    data.forEach(e=>{
      worksheet.addRow(this.getRowData(name,e,additionalData))
    })
    
    workbook.csv.writeBuffer().then((datas) => {
      let blob = new Blob([datas as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    }).catch((err)=>{
      console.log('Failed to download...')
    })
  }
  splitWordByCapsExceptAbb(data){
    return data.split(/([A-Z][a-z]+)/).join(" ")
  }
  getTilesColumns(name,additionalData?:any){
    switch(name){

      case 'keyVaultResources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'Availability (%)', key: 'availability', width: 60 },
          { header: 'Capacity used (%)', key: 'capacity', width: 60 },
          { header: 'Latency (ms)', key: 'latency', width: 60 },
          { header: 'Authentication Errors', key: 'authentication', width: 60 },
          { header: 'Throttling', key: 'throttling', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }
      case 'appServicePlanResources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'CPU Usage (%)', key: 'cpuUsage', width: 60 },
          { header: 'Memory Usage (%)', key: 'memoryUsage', width: 60 },
          { header: 'Queued Storage Requests', key: 'queuedStorage', width: 60 },
          { header: 'Queued HTTP Requests', key: 'queuedHttp', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }
     case 'networkInterfaceResources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'Bytes Received', key: 'bytesReceived', width: 60 },
          { header: 'Bytes Sent', key: 'bytesSent', width: 60 },
          { header: 'Packets Received', key: 'packetsReceived', width: 60 },
          { header: 'Packets Sent', key: 'packetSent', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }
      case 'VM Resources':
      case 'vmResources':{
        return [
          { header: 'Resource Name', key: 'resourceName', width: 60 },
          { header: 'Operating System', key: 'os', width: 60 },
          { header: 'Availability', key: 'availability', width: 60 },
          { header: 'Free Disk Space (%)', key: 'freeDisk', width: 60 },
          { header: 'Memory Usage (%)', key: 'memoryUsage', width: 60 },
          { header: 'CPU Usage (%)', key: 'cpuUsage', width: 60 },
        ];
      }
      case 'diskResources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'Disk Size (GB)', key: 'size', width: 60 },
          { header: 'Disk Read (bytes/sec)', key: 'read', width: 60 },
          { header: 'Disk Write (bytes/sec)', key: 'write', width: 60 },
          { header: 'Connected to', key: 'connected', width: 60 },
        ];
      }
      case 'loadBalancerResources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'Frontend availability (%)Data Out (MB)  ', key: 'frontendAvailability', width: 60 },
          { header: 'Backend availability (%)', key: 'backendAvailability', width: 60 },
          { header: 'Data in (throughput)', key: 'dataIn', width: 60 },
          { header: 'Data out (throughput)', key: 'dataOut', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }

      case 'expressRouteGatewayResources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'CPU Usage (%) ', key: 'cpuUsage', width: 60 },
          { header: 'Packets Received (per sec)', key: 'packetsReceived', width: 60 },
          { header: 'ExpressRoute Circuit', key: 'expressRouteCircuit', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }

      case 'vpnGatewayResources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'Data Out (MB) ', key: 'dataOut', width: 60 },
          { header: 'Data In (MB)', key: 'dataIn', width: 60 },
          { header: 'Egress Packet Drop', key: 'egressPacketDrop', width: 60 },
          { header: 'Ingress Packet Drop', key: 'ingressPacketDrop', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }
      
      case 'applicationGatewayResources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'Bytes Sent', key: 'bytesSent', width: 60 },
          { header: 'Bytes Received', key: 'bytesReceived', width: 60 },
          { header: 'Unhealthy Backend Instances', key: 'unhealthyBackendInstances', width: 60 },
          { header: 'Compute Usage', key: 'computeUsage', width: 60 },
          { header: 'Request Handling Time', key: 'requestHandlingTime', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }

      case 'vmAvailability':{
        return [
          { header: 'Virtual Machine', key: 'vm', width: 60,style: { font: { name: 'Arial Black' ,bold:true} } },
          { header: 'Status', key: 'status', width: 60,style: { font: { name: 'Arial Black' ,bold:true} } },
        ];
      }
      case 'resources':{
        return [
          { header: 'Resource Name', key: 'name', width: 60 },
          { header: 'Subscription', key: 'subs', width: 60 },
          { header: 'Resource Group', key: 'rg', width: 60 },
          { header: 'Type', key: 'type', width: 60 },
          { header: 'Location', key: 'location', width: 60 },
        ];
      }
      case 'windowsVm':{
        return [
          { header: 'VM Name', key: 'name', width: 60 },
          { header: 'Resource Group', key: 'rg', width: 60 },
          { header: 'Location', key: 'location', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }
      case 'linuxVm':{
        return [
          { header: 'VM Name', key: 'name', width: 60 },
          { header: 'Resource Group', key: 'rg', width: 60 },
          { header: 'Location', key: 'location', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
        ];
      }
      case 'events':{
        return [
          { header: 'Virtual Machine', key: 'vm', width: 60 },
          { header: 'Event Log', key: 'log', width: 60 },
          { header: 'Type', key: 'type', width: 60 },
          { header: 'Description', key: 'description', width: 60 },
        ];
      }
      case 'alerts':{
        return [
          { header: 'Name', key: 'name', width: 60 },
          { header: 'Severity', key: 'severity', width: 60 },
          { header: 'Monitor Condition', key: 'monitor', width: 60 },
          { header: 'Affected Resource', key: 'affected', width: 60 },
          { header: 'Monitor Service', key: 'service', width: 60 },
          { header: 'Signal Type', key: 'type', width: 60 },
          { header: 'Fired Time', key: 'time', width: 60 },
          { header: 'Subscription', key: 'subs', width: 60 },
        ];
      }
      case 'memoryPercent':{
        return [
          { header: 'Time Generated (UTC)', key: 'time', width: 60 },
          { header: 'Virtual Machine', key: 'vm', width: 60 },
          { header: 'Memory Usage (%)', key: 'usage', width: 60 },
        ];
      }
      case 'cpuPercent':{
        return [
          { header: 'Time Generated (UTC)', key: 'time', width: 60 },
          { header: 'Virtual Machine', key: 'vm', width: 60 },
          { header: 'CPU Utilization(%)', key: 'utilize', width: 60 },
        ];
      }
      case 'freeDisk':{
        let arr=[];
        arr.push({ header: 'Virtual Machine', key: 'vm', width: 60 })
        additionalData.forEach(e=>{
          arr.push({ header: e.name, key: e.name, width: 60 })
        })
        arr.push({ header: 'Total', key: 'total', width: 60 })
        return arr;
      }
      case 'activityLogs':{
        return [
          { header: 'Activity', key: 'activity', width: 60 },
          { header: 'Resource', key: 'resource', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
          { header: 'Date', key: 'date', width: 60 },
          { header: 'Event initiated by', key: 'event', width: 60 },
        ];
      }
      case 'signIns':{
        return [
          { header: 'Date', key: 'date', width: 60 },
          { header: 'Username', key: 'uname', width: 60 },
          { header: 'Application', key: 'app', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
          { header: 'IP Address', key: 'ipAddr', width: 60 },
          { header: 'Location', key: 'location', width: 60 },
          { header: 'Authentication requirement', key: 'auth', width: 60 },
        ];
      }
      case 'auditLogs':{
        return [
          { header: 'Date', key: 'date', width: 60 },
          { header: 'Service', key: 'service', width: 60 },
          { header: 'Category', key: 'category', width: 60 },
          { header: 'Activity', key: 'activity', width: 60 },
          { header: 'Status', key: 'status', width: 60 },
          { header: 'Target', key: 'target', width: 60 },
          { header: 'Initiated By', key: 'initiated', width: 60 },
        ];
      }
      case 'spendingRate':{
        return [
          { header: 'Usage Date', key: 'date', width: 60 },
          { header: 'Cost (USD)', key: 'cost', width: 60 },
        ];
      }
      case 'costByResourceGroup':{
        return [
          { header: 'Resource Group', key: 'resourceGroup', width: 60 },
          { header: 'Cost (USD)', key: 'cost', width: 60 },
        ];
      }
      case 'costByLocation':{
        return [
          { header: 'Location', key: 'location', width: 60 },
          { header: 'Cost (USD)', key: 'cost', width: 60 },
        ];
      }
      case 'costResourceData':{
        return [
          { header: 'Resource Name', key: 'resourceName', width: 60 },
          { header: 'Resource Type', key: 'resourceType', width: 60 },
          { header: 'Resource Group', key: 'resourceGroup', width: 60 },
          { header: 'Subscription Id', key: 'subscriptionId', width: 60 },
          { header: 'Cost (USD)', key: 'cost', width: 60 },
        ];
      }
      case 'costByServiceType':{
        return [
          { header: 'Service Type', key: 'serviceType', width: 60 },
          { header: 'Cost (USD)', key: 'cost', width: 60 },
        ];
      }
      case 'costRecommendation':{
        return [
          { header: 'Reommendation Name', key: 'recommendationName', width: 60 },
          { header: 'Recommendation Type Id', key: 'recommendationTypeId', width: 60 },
          { header: 'Category', key: 'category', width: 60 },
          { header: 'Scope', key: 'scope', width: 60 },
          { header: 'Resource Group', key: 'rg', width: 60 },
          { header: 'Subscription Id', key: 'subId', width: 60 },
          { header: 'Impact', key: 'impact', width: 60 },
          { header: 'Last Updated', key: 'lastUpdate', width: 60 },
          { header: 'Impacted Resource Type', key: 'impactResourceType', width: 60 },
          { header: 'Impacted Resource', key: 'ImpactResource', width: 60 },
          { header: 'Resource Id', key: 'resourceId', width: 60 },
          { header: 'Target Resource Count', key: 'resourceCount', width: 60 },
          { header: 'Annual Savings Amount', key: 'savingAmount', width: 60 },
        ];
      }

    }
  }

  getRowData(name,data,additionalData){
    switch(name){
      case 'costRecommendation':{
        return{
          recommendationName: data.recommendationName,
          recommendationTypeId : data.recommendationTypeId,
          category : data.category,
          scope: data.scope,
          rg: data.resourceGroup,
          subId: data.subscriptionId,
          impact: data.impact,
          lastUpdate: data.lastUpdated,
          impactResourceType: data.impactedResourceType,
          ImpactResource: data.impactedResource,
          resourceId: data.resourceId,
          resourceCount: data.targetResourceCount,
          savingAmount: data.annualSavingsAmount,
        }
      }
      case 'keyVaultResources':{
        return{
          name: data.resourceName,
          availability : data.availabilty,
          capacity: data.capacity,
          latency: data.latency,
          authentication: data.authenticationError,
          throttling: data.throttling,
          status: data.status
        }
      }
      case 'appServicePlanResources':{
        return{
          name: data.resourceName,
          cpuUsage : data.cpuUsage,
          memoryUsage: data.memoryUsage,
          queuedStorage: data.queuedStorageRequest,
          queuedHttp: data.queuedHttpRequest,
          status: data.status
        }
      }
      case 'networkInterfaceResources':{
        return{
          name: data.resourceName,
          bytesReceived : data.bytesReceived,
          bytesSent: data.bytesSent,
          packetsReceived: data.packetsReceived,
          packetSent: data.packetsSent,
          status: data.status
        }
      }
      case 'loadBalancerResources':{
        return{
          name: data.resourceName,
          frontendAvailability : data.frontendAvailability,
          backendAvailability: data.backendAvailability,
          dataIn: data.dataIn,
          dataOut: data.dataOut,
          status: data.status
        }
      }
      case 'vpnGatewayResources':{
        return{
          name: data.resourceName,
          dataOut: data.dataOut,
          dataIn: data.dataIn,
          egressPacketDrop:  data.egressPacketDrop,
          ingressPacketDrop: data.ingressPacketDrop,
          status: data.status
        }
      }
      case 'expressRouteGatewayResources':{
        return{
          name: data.resourceName,
          cpuUsage: data.cpuUsage,
          packetsReceived: data.packetsReceived,
          expressRouteCircuit:  data.expressRouteCircuit,
          status: data.status
        }
      }
      case 'applicationGatewayResources':{
        return{
          name: data.resourceName,
          bytesSent: data.bytesSent,
          bytesReceived: data.bytesReceived,
          unhealthyBackendInstances:  data.unhealthyBackendInstances,
          computeUsage:  data.computeUsage,
          requestHandlingTime:  data.requestHandlingTime,
          status: data.status
        }
      }          
      case 'VM Resources':
      case 'vmResources':{
        return{
          resourceName: data.resourceName,
          os : data.os,
          availability: data.availability,
          freeDisk: data.freeDiskSpace == 0 ? 0 : data.freeDiskSpace,
          memoryUsage: data.memoryUsage == 0 ? 0 : data.memoryUsage,
          cpuUsage: data.cpuUsage == 0 ? 0 : data.cpuUsage
        }
      }
      case 'diskResources':{
        return{
          name: data.resourceName,
          size : data.diskSize,
          read: data.dataReadBytes,
          write: data.dataWriteBytes,
          connected: data.vmConnected,
        }
      }

      case 'vmAvailability':{
        return{
          vm:  data.name,
          status: data.status,
       }
      }
      case 'resources':{
        return{
          name:  data.name,
          subs: 'Azure subscription 1',
          rg:  data.rgName,
          type:  data.type,
          location:  data.location,
       }
      }
      case 'linuxVm':{
        return{
          name:  data.name,
          rg:  data.rgName,
          location:  data.location,
          status: this.getStatusText(data.properties.extended.instanceView.powerState.code)
       }
      }
      case 'windowsVm':{
        return{
          name:  data.name,
          rg:  data.rgName,
          location:  data.location,
          status: this.getStatusText(data.properties.extended.instanceView.powerState.code)
       }
      }
      case 'events':{
        return{
          vm:  data.name,
          log:  data.log,
          type:  data.type,
          description: data.description
       }
      }
      case 'alerts':{
        return{
          name:  data.name,
          severity:  this.getSeverityName(data.properties.essentials.severity),
          monitor:  data.properties.essentials.monitorCondition,
          affected: data.properties.essentials.targetResourceName,
          service: data.properties.essentials.monitorService,
          type: data.properties.essentials.signalType ,
          time: data.properties.essentials.startDateTime,
          subs: 'Azure subscription 1',
       }
      }
      case 'memoryPercent':{
        return{
          time:  data.time,
          vm:  data.name,
          usage:  data.value,
       }
      }
      case 'cpuPercent':{
        return{
          time:  data.time,
          vm:  data.name,
          utilize:  data.percentage,
       }
      }
      case 'freeDisk':{
        let obj = {};
        obj['vm'] = data.Computer
        additionalData.forEach(e=>{
          obj[e.name] = this.getDiskValue(e.name,data)
        })
        obj['total'] = this.getTotal(data,additionalData)
        return obj;
      }
      case 'activityLogs':{
        return{
          activity: data.operationName.localizedValue,
          resource: this.getResourceName(data.resourceId),
          status: data.status.localizedValue,
          date: this.a3sDate.transform(data.eventTimestamp),
          event: data.caller,
       }
      }
      case 'signIns':{
        return{
          date: this.a3sDate.transform(data.createdDateTime),
          uname: data.userDisplayName,
          app: data.appDisplayName,
          status: this.getStatus(data),
          ipAddr: data.ipAddress,
          location: this.getLocation(data),
          auth: data.authenticationRequirement
       }
      }
      case 'auditLogs':{
        return{
          date: this.a3sDate.transform(data.activityDateTime),
          service: data.loggedByService,
          category: data.category,
          activity: data.activityDisplayName,
          status: data.result,
          target: this.getTarget(data),
          initiated: data.initiatedBy.app ?  data.initiatedBy.app.displayName :  data.initiatedBy.user ? data.initiatedBy.user.displayName : '',
       }
      }
      case 'spendingRate':{
        return{
          date: data.UsageDate,
          cost: data.CostUSD,
        }
      }
      case 'costByResourceGroup':{
        return{
          resourceGroup: data.ResourceGroup,
          cost: data.CostUSD,
        }
      }
      case 'costByLocation':{
        return{
          location: data.ResourceLocation,
          cost: data.CostUSD,
        }
      }
      case 'costResourceData':{
        return{
          resourceName:  data.ResourceId.split('/')[8],
          resourceType: data.resourceType,
          resourceGroup: data.ResourceId.split('/')[4],
          subscriptionId: data.ResourceId.split('/')[2],
          cost: data.CostUSD,
        }
      }
      case 'costByServiceType':{
        return{
          serviceType: data.ServiceName,
          cost: data.CostUSD,
        }
      }
    }
  }

  getStatus(row){
    if(row.errorCode == 0) return 'Success'
    else if(row.errorCode == 50140) return 'Interrupted'
    else return 'Failure'
  }


  getLocation(row){
    return row.location.city +','+row.location.state+','+row.location.countryOrRegion
  }

  getTarget(row){
    return row.targetResources.length ? row.targetResources.map(e=>e.displayName).join(',') : null
  }

  getResourceName(id){
    return id.includes('/') ? id.split('/')[8] : ''
  }

  getDiskValue(disk,row){
    return row[disk] ? row[disk] : 0
  }

  getTotal(row,data){
    if(!data.length) return
    var total = 0;
    data.forEach(e=>{
       total = row[e.name] ? total + row[e.name] : total
    })
   return total.toFixed(2);
  }

  getStatusText(value){
    let status = value.toLowerCase()
    switch (status) {
      case 'powerstate/running':
      case 'running': return 'Running';
      case 'powerstate/stopped':
      case 'stopped': return 'Stopped';
      case 'powerstate/deallocated':
      case 'stopped deallocated': return 'Stopped Deallocated';
      default: return 'warning-chip';
    }
  }

  getSeverityName(id) {
    switch (id) {
      case 'Sev3': return 'Info'
      case 'Sev2': return 'Warning'
      case 'Sev0': return 'Critical';
      case 'Sev1': return 'Error';
      case 'Sev4': return 'Verbose';
   }
  }
}
