<div class="arch-deployment-status">
    <div class="header-container">
        <h2 class="title">{{data?.projectName | titlecase }}</h2>
        <span class="content-14 blur-text">Architecture Blueprint</span>
    </div>

    <div class="deployment-status">
      <div class="deployment-success" *ngIf="data.deploymentStatus=='Succeeded'">
        <img src="assets/icons/deployment_success.png">
        <h4>Deployment Completed Successfully!</h4>
      </div>
      <div class="deployment-failed" *ngIf="data.deploymentStatus=='Failed'">
        <img src="assets/icons/deployment_failed.png">
        <h4>Deployment Failed!</h4>
        <p>{{data?.resourceFailedCount}} resources failed to deploy</p>
      </div>
      <button class="btn btn-btn-primary" (click)="routeToDiagram()"> <span>View architecture</span>  </button>
    </div>

    <div class="custom-fixed-tables">
      <!-- <h4 style="font-size: 14px;font-weight: 600;">Resource(s) deployed</h4> -->
        <table class="table fix-width">
            <thead>
              <tr>
                <th class="table-name">
                  <div class="flex-custom no-wrap">
                    <span>Resource name</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resource')">
                  </div>
                </th>
                <th class="status">
                    <div class="flex-custom no-wrap">
                      <span>Resource Group</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resourceGroup')">
                    </div>
                  </th>
                  <th class="status">
                    <div class="flex-custom no-wrap">
                      <span>Subscription</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortAll('subscriptionName')">
                    </div>
                  </th>
                <th class="status">
                    <div class="flex-custom no-wrap">
                      <span>Status</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deploymentResourceStatus')">
                    </div>
                </th>
                <th class="status" style="border-right: solid 1px #8CD4FD;">
                    <div class="flex-custom no-wrap">
                        <span>Deployed on (UTC)</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deploymentResourceTime')">
                    </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of tableList">
                <td>{{list?.resourceName | displayTxt}}</td>
                <td>{{list?.resourceGroup | displayTxt}}</td>
                <td>{{data?.subscriptionName | displayTxt}}</td>
                <td><span *ngIf="list?.deploymentResourceStatus=='Succeeded'" class="success"><img src="assets/icons/success.png">Deployed successfully</span>
                  <span *ngIf="list?.deploymentResourceStatus=='Failed'" class="failed"><img src="assets/icons/fail.png">Failed to deploy</span></td>
                <td>{{list?.deploymentResourceTime | date :'MM/dd/yyyy, hh:mm:ss a'}}</td>
              </tr>
              <div class="col-md-12 mt-3" *ngIf="!tableList?.length">
                <p class="content-14 blur-text">No data Found</p>
              </div>
            </tbody>
        </table>
    </div>

</div>