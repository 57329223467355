import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { resourceObject } from '../../../@model/resource-model';
import * as _ from 'lodash';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { NewResourceGroup, ResourceInputError, RTResourceModel } from '../resources-model';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-route-table',
  templateUrl: './route-table.component.html',
  styleUrls: ['./route-table.component.scss']
})
export class RouteTableComponent implements OnInit {
  @Input() projectData : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  routeTablemodel : RTResourceModel ={
    routesShow:false,
    subnetsShow:false,
    resourceId:null,
    resourceName:null,
    resourceType:'routeTables',
    resourceData:{
      routes:null,
      subnets:null,
      subnet:null,
      vnetName:null,
      resourceGroup:null,
      vnetResourceGroup:null,
      propagateGatewayRoutes:'Yes',
      tagList:[{
        tagName:null,
        value:null
      }]
    },
    comments:null,
    createdBy:null,
  };
  show : boolean = false;
  inputError: ResourceInputError = {
    VMname:false,
    duplicateAi:false,
    VmSpecialChar:false,
    VMdescription:false,
    laname:false,
    laSpecialChar:false,
    duplicateLaw:false,
  }
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  resourceGroupList:any=[];
  routeTablesList:any=[];
  existingNewVNList:any=[];
  existingSubnetList:any=[];
  existingVNList:any=[];
  showView:boolean=false;
  routesObj:any=resourceObject.routesObj;
  subnetsObj:any=resourceObject.subnetsObj;
  publicIPs:any=[
    '0.0.0.0/1',
    '127.0.0.0/8',
    '169.254.0.0/16',
    '169.254.0.0/15',
    '168.63.129.16/28',
    '168.63.129.16/29',
    '168.63.129.16/30',
    '168.63.129.16/31',
    '168.63.129.16/32',
    '224.0.0.0/4',
    '224.0.0.0/3',
    '255.255.255.255/32'
  ];
  nextHopTypeList:any=[
    {name:'Virtual network gateway',id:'VirtualNetworkGateway'},
    {name:'Virtual network',id:'VirtualNetwork'},
    {name:'Internet',id:'Internet'},
    {name:'Virtual appliance',id:'VirtualAppliance'},
    {name:'None',id:'None'}
  ]

  routesList:any=[];

  subnetsList:any=[];
  existingVNListForName: any[];
  existingNewRTList: any=[];
  
  constructor(private util:UtilService,private loader:LoaderService,) { }

  async ngOnInit() {
    this.loader.showLoader();
    await this.getResourceGroupList();
    await this.getListByPropName('virtualNetworks',undefined,undefined);
    // if(this.sideSheetViewType=='new'){
    //   this.routeTablemodel.resourceData={};
    //   this.routeTablemodel.resourceName=null;
    //   this.routeTablemodel.resourceData.tagList=[{}];
    // }
    let parentResourceData,resourceObj;
    if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
      this.existingNewVNList=this.parentResourceDtls;
      this.routeTablemodel.resourceData.subnet=this.parentResourceDtls.resourceName;
      parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
      this.routeTablemodel.resourceData.vnetName=parentResourceData.vnetName;
      resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
      this.routeTablemodel.resourceData.resourceGroup=resourceObj.name;
      this.routeTablemodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
      this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
    }  
    await this.getListByPropName('routeTables',undefined,undefined);
    await this.getExistNewlyResourceDetails('routeTables');
    // this.routeTablemodel.resourceData.propagateGatewayRoutes= 'Yes';
    if(this.sideSheetViewType=='edit'){
     await this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null,null,null,true).then(async res=>{
        this.routeTablemodel=res;
        this.routeTablemodel.resourceData=JSON.parse(res.resourceData);
        if(this.parentResourceDtls['updateRequestFrom']){
          this.routeTablemodel.resourceData.vnetName=this.parentResourceDtls.resourceName;
          this.routeTablemodel.resourceData.resourceGroup=resourceObj.name;
        }
      });
    }
    this.loader.hideLoader();
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType === 'routeTables') {
        this.existingNewRTList=res;
      }
    },err=>{
      if(resourceType === 'routeTables') {
        this.existingNewRTList=[];
      }
    });
  }

  async getVirtualNetworkList(){
    if(this.routeTablemodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.routeTablemodel.resourceData.resourceGroup);
      if(!resourceObj.resourceGroupId){
        this.loader.showLoader();
        await this.getListByPropName('virtualNetworks',resourceObj,undefined);
        this.loader.hideLoader();
      }else{
        this.existingVNListForName=[];
      }
    }
  }

  async getSubnetList(){
    if(this.routeTablemodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.routeTablemodel.resourceData.resourceGroup);
      if(!resourceObj.resourceGroupId){
        this.loader.showLoader();
        await this.getListByPropName('subnetByVN',resourceObj,undefined);
        this.loader.hideLoader();
      }else{
        this.existingSubnetList=[];
      }
    }
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
        if(this.projectData.resourceGroup&&this.projectData.resourceGroup!='null'){
        this.routeTablemodel.resourceData.resourceGroup=this.projectData.resourceGroup;        
        }
      });
  }

  onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.routeTablemodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }

      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.routeTablemodel.resourceData.resourceGroup=undefined; 
    });
  }

  editNewResourceGroup(item,index){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item);
    this.show = !this.show;
  }

  async getNewResourceGroup(){
   await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addTagVM(){
    this.routeTablemodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTagVM(index){
    this.routeTablemodel.resourceData.tagList.splice(index,1);
  }

  hideShow(){
    this.showView=!this.showView;
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[];
      let parentResourceData=this.parentResourceDtls&&this.parentResourceDtls.resourceData?JSON.parse(this.parentResourceDtls.resourceData):{};
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name==this.routeTablemodel&&this.routeTablemodel.resourceData&&this.routeTablemodel.resourceData.resourceGroup?this.routeTablemodel.resourceData.resourceGroup:parentResourceData.resourceGroup);
      if(propName=='routeTables'){
        if(res.value&&res.value.length>0)
        result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.routeTablesList=result;
      }else if(propName=='virtualNetworks'&&!resourceObj){
        this.existingVNList=res.value;
      }else if(propName == 'subnetByVN'){
        this.existingSubnetList=res.value;
       }else if(propName=='virtualNetworks'&&resourceObj){
        this.existingVNListForName=res.value;
      }
      return  result;
    },err=>{
      if(propName=='routeTables'){
        this.routeTablesList=[];
      }else if(propName=='virtualNetworks'&&!resourceObj){
        this.existingVNList=[];
      }
    });
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.routeTablemodel);
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  laNameValidation(limitField, minLimit,maxLimit) {
    let value =limitField.target.value
    if (value.length > maxLimit || value.length<minLimit) {
        this.inputError.laname = true;
    }
    else this.inputError.laname = false;

    let regex=/\@|\&|\*|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
    if(value[0]=='-'||value[value.length-1]=='-' ||regex.test(value)){
      this.inputError.laSpecialChar =true;
      return;
    } 
    this.inputError.laSpecialChar =false;
  }

  VmNameValidation(limitField, limitNum) {
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMname = true;
    }
    else this.inputError.VMname = false;

    let regex=/\@|\&|\*|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
    if(value[0]=='_'||value[value.length-1]=='.' || value[value.length-1]=='-'||regex.test(value)){
      this.inputError.VmSpecialChar =true;
      return;
    } 
    this.inputError.VmSpecialChar =false;

    if((/^[0-9]*$/).test(value)){
      this.inputError.VMOnlyNUmbers =true;
      return;
    }

    this.inputError.VMOnlyNUmbers =false; 
  }

  VmDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMdescription = true;
    }
    else this.inputError.VMdescription = false;
  }

  aiDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateAi =false; 
    if(value){
   let findObj=this.routeTablesList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
    if(this.existingNewRTList && !findObj){
      findObj=this.existingNewRTList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase()&&dt.resourceId!=this.routeTablemodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateAi =true; 
    }else{
      this.inputError.duplicateAi =false; 
    }
  }
  }

  lawDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateLaw =false; 
    if(value){
   let findObj=this.routeTablesList.find(dt=>{
     if(dt.properties&&dt.properties.routes){
      return dt.properties.routes.find(dtl=>dtl.name.toLowerCase()==value.toLowerCase());
     }
    });
    if(this.existingNewRTList && !findObj){
      findObj=this.existingNewRTList.find(dt=>{
        if(dt.resourceData&&dt.resourceId!=this.routeTablemodel.resourceId) {
          const resourceData=JSON.parse(dt.resourceData);
          if(resourceData.routes){
            resourceData.routes.toLowerCase()==value.toLowerCase();
          }
        }
      });
    }
    if(findObj){
      this.inputError.duplicateLaw =true; 
    }else{
      this.inputError.duplicateLaw =false; 
    }
  }
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  isAddressSpace(string){
    return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
  }

  VNetAddressSpace(limitField,item,index){
    let value =limitField.target.value
    if(!this.isAddressSpace(value)){
      console.log(value,this.isAddressSpace(value));
      item.VNetAdrspc=true;
      return;
    }
    item.VNetAdrspc=false;
    this.validateAddress(item,index);
  }

  validateOverloopAddress(vlIPRange,ipRangObj){
    if(vlIPRange.range.left.input==ipRangObj.range.left.input){
      return true;
    }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
     return true;
    }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]){
        if(vlIPRange.range.left.parts[2]>ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.right.parts[2]<ipRangObj.range.right.parts[2]){
            return true;
          }
        }else if(vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.left.parts[3]>ipRangObj.range.left.parts[3]){
            if(vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
              return true;
            }
          }
        }
      }
    }else if(vlIPRange.range.left.parts[0]<ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.right.parts[0]>ipRangObj.range.right.parts[0]){
        return true;
      }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]){
        if(vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
          return true;
        }
      }
    }else {
      return false;
    }
  }

  validateAddress(item,index){
    const splitAr=item.address.split('/');
    let vlIPRange;
    try{
      vlIPRange=new IPSubnetwork(new IPv4(splitAr[0]),splitAr[1]);
      item.VNetAdrspc=false;
    }catch(e){
      item.VNetAdrspc=true;
      return false;
    }
    if(vlIPRange.input!=item.address){
      item.notValidCIDR=true;
      item.validCIDRValue=vlIPRange.input;
      return false;
    }else{
      item.notValidCIDR=false;
      item.validCIDRValue=null;
    }

    if(this.publicIPs.includes(item.address)){
     item.publicAddress=true;
     return false;
    }else{
    item.publicAddress=false;
    }

    if(vlIPRange.range.left.parts[0]!="10"&&vlIPRange.range.left.parts[0]!="192"&&vlIPRange.range.left.parts[0]!="172"){
      item.publicAddress=true;
     return false;
    }else if(vlIPRange.range.left.parts[0]=="192"&&vlIPRange.range.left.parts[1]!="168"){
      item.publicAddress=true;
      return false;
    }else if(vlIPRange.range.left.parts[0]=="172"&&vlIPRange.range.left.parts[1]!="16"){
      item.publicAddress=true;
      return false;
    }else{
      item.publicAddress=false;
      }
    if(this.existingVNList&&this.existingVNList.length){
      let result=this.existingVNList.find(dt=>{
        if(dt.properties.addressSpace.addressPrefixes.length){
          let addressPrefixes=dt.properties.addressSpace.addressPrefixes;
          return addressPrefixes.find(vl=>{
            const splitArr=vl.split('/');
           let ipRangObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
           if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
             item.overlapingValue=vl;
             return true
           }

          })
        }
      })
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

    if(this.existingNewVNList&&this.existingNewVNList.length){
      let result=this.existingNewVNList.find((dt)=>{
        try {
          if(dt.resourceData&&dt.resourceId!=this.routeTablemodel.resourceId){
            let resourceData=JSON.parse(dt.resourceData);
           return resourceData.virtualNetworkAddressSpace.find(dtl=>{
              if(dtl.address){
                const splitArr=dtl.address.split('/');
                  let ipRangObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
                if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
                  item.overlapingValue=dtl.address;
                  return true
                }
              }
            })
          }
        } catch (error) {}
      });
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

    if(item.address){
      let result=this.routesObj.resourceData.virtualNetworkAddressSpace.find((dt,i)=>{
        try {
          if(dt.address&&i!=index){
            const splitArr=dt.address.split('/');
              let ipRangObj;
              ipRangObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
              if(vlIPRange.input==ipRangObj.input){
                item.overlapingValue=dt.address;
                return true
              }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
                item.overlapingValue=dt.address;
                return true
              }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]==ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[2]==ipRangObj.range.right.parts[2]&&vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
                item.overlapingValue=dt.address;
                return true
              }else if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
              item.overlapingValue=dt.address;
              return true
            }
          }
        } catch (error) {}
      });
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.routeTablemodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  saveResourceDetails(){
    var model = this.routeTablemodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.routeTablemodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  saveSubSideSheet(type){
    switch(type){
      case 'routesObj':{
        if(!this.routesList){
          this.routesList=[];
        }
        //this.nsgList.push(this.vmNSGObj);
        let tempList=_.cloneDeep(this.routesList);
        this.routesObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.routesObj;
          }else{
            tempList.push(this.routesObj);
          }
        }else{
          tempList.push(this.routesObj);
        }
        this.routesList=[];
        this.routesList=tempList;
        this.routeTablemodel.resourceData.routes=_.cloneDeep(this.routesObj);
        this.routeTablemodel.routesShow=false;
        //this.lawObj={};
        return;
      }
      case 'subnetsObj':{
        if(!this.subnetsList){
          this.subnetsList=[];
        }
        //this.nsgList.push(this.vmNSGObj);
        let tempList=_.cloneDeep(this.subnetsList);
        this.subnetsObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.subnetsObj;
          }else{
            tempList.push(this.subnetsObj);
          }
        }else{
          tempList.push(this.subnetsObj);
        }
        this.subnetsList=[];
        this.subnetsList=tempList;
        this.routeTablemodel.resourceData.subnets=_.cloneDeep(this.subnetsObj);
        this.routeTablemodel.subnetsShow=false;
        //this.lawObj={};
        return;
      }
    }
  }

  checkDataDisabled(name){
    switch(name){
      case 'routeTable': return !this.routeTablemodel.resourceName||!this.routeTablemodel.resourceData.resourceGroup || this.inputError.VmSpecialChar || this.inputError.VMname; 
      case 'routesObj': return !this.routesObj.name|| this.inputError.laSpecialChar || this.inputError.laname || this.inputError.VmSpecialChar || this.inputError.duplicateLaw;
      case 'subnetsObj': return !this.subnetsObj.vnetName|| !this.subnetsObj.subnetName;
    }
  }
}
