import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit {

  bucketList: any =[
    { 
      name :'Infrastructure Deployment & Management' ,
      tabs:[
        { id : 1, name :'Architecture Blueprint', bucketName:'Infrastructure Deployment & Management',route:'/a3s/infraDeploymentBucket/architectureBlueprint',checked: false},
        { id : 2, name :'Management', bucketName:'Infrastructure Deployment & Management',route:'/a3s/infraDeploymentBucket/management',checked: false}
      ]
    },
    { 
      name :'Cost Monitoring and Optimization' ,
      tabs:[
        { id : 1, name :'Overview',bucketName:'Cost Monitoring and Optimization',route:'/a3s/costMonitoringBucket/overview', checked: false},
        { id : 2, name :'Billing', bucketName:'Cost Monitoring and Optimization',route:'/a3s/costMonitoringBucket/billing',checked: false}
      ]
    },
    { 
      name :'Data & AI' ,
      tabs:[
        { id : 1, name :'Data Migration', bucketName:'Data & AI',route:'/a3s/dataMigrationBucket/dataMigration',checked: false},
        { id : 2, name :'Data Governance', bucketName:'Data & AI',route:'/a3s/dataMigrationBucket/dataGovernance',checked: false},
        { id : 3, name :'Data Analytics', bucketName:'Data & AI',route:'/a3s/dataMigrationBucket/dataAnalytics',checked: false},
        { id : 4, name :'DataOps', bucketName:'Data & AI',route:'',checked: false},
      ]
    },
    { 
      name :'Governance' ,
      tabs:[
        { id : 1, name :'Enterprise Policies', bucketName:'Governance',route:'/a3s/governanceBucket/overviewPolicies',checked: false},
        { id : 2, name :'Identity & Access Management', bucketName:'Governance',route:'/a3s/governanceBucket/identityAccessManagement',checked: false},
        { id : 3, name :'Auditing & Logging', bucketName:'Governance',route:'/a3s/governanceBucket/auditingLogging',checked: false},
      ]
    },
    { 
      name :'Infrastructure Health Monitoring' ,
      tabs:[
        { id : 1, name :'Metrics', bucketName:'Infrastructure Health Monitoring',route:'/a3s/infraHealthMonitorBucket/dashboard',checked: false},
        { id : 2, name :'Insights',bucketName:'Infrastructure Health Monitoring',route:'/a3s/infraHealthMonitorBucket/insights', checked: false},
        { id : 3, name :'Alerts', bucketName:'Infrastructure Health Monitoring',route:'/a3s/infraHealthMonitorBucket/alerts',checked: false},
      ]
    },
    { 
      name :'Security' ,
      tabs:[
        { id : 1, name :'Security Posture', bucketName:'Security',route:'/a3s/securityBucket/securityPosture',checked: false},
        { id : 2, name :'Recommendations', bucketName:'Security',route:'/a3s/securityBucket/recommendations',checked: false},
        { id : 3, name :'Alert Management', bucketName:'Security',route:'/a3s/securityBucket/alertManagement',checked: false},
      ]
    },
    { 
      name :'Application Health Monitoring' ,
      tabs:[
        { id : 1, name :'Metrics & Usage', bucketName:'Application Health Monitoring',route:'/a3s/applicationMonitorBucket/dashboard',checked: false},
        { id : 2, name :'Alerts', bucketName:'Application Health Monitoring',route:'/a3s/applicationMonitorBucket/alerts',checked: false}
      ]
    },
    { 
      name :'Compliance' ,
      tabs:[
        { id : 1, name :'Overview', bucketName:'Compliance',route:'/a3s/complianceBucket/overview',checked: false},
        { id : 2, name :'Manage Policy', bucketName:'Compliance',route:'/a3s/complianceBucket/managePolicy',checked: false},
        { id : 3, name :'Reports', bucketName:'Compliance',route:'/a3s/complianceBucket/reports',checked: false},
      ]
    },
  ]
  selectedFavourites: any = [];
  favouriteList: any = [];

  constructor(private util: UtilService) { }

  ngOnInit(): void {
    this.getAllFavourites()
  }

  getAllFavourites(){
    let email = localStorage.getItem('email')
    this.util.handleRequest('get','a3s_favourites_all',[email],null,null,null,true).then(res=>{
      let data = res.recordset
      if(!data.length) return;
      this.favouriteList = JSON.parse(data[0].favourites)
      this.selectedFavourites = this.favouriteList
      this.bucketList.forEach(b=>{
        b.tabs.forEach(t=>{
          this.favouriteList.forEach(e=>{
            if(e.name == t.name){
              t.checked = true;
            }
          })
        })
      })
      console.log('favouriteList',this.favouriteList)
    })
  }

  UpdateArray(event,tab){
    if(event.target.checked){
      this.selectedFavourites.push(tab)
    }
    else{
      let find = this.selectedFavourites.findIndex(e=> e.name == tab.name)
      this.selectedFavourites.splice(find,1)
    }
    this.addFavourites()
  }
  
  addFavourites(){
    let email = localStorage.getItem('email')
    let body ={
      email : email,
      userName : email,
      favourites : this.selectedFavourites
    }
    this.util.handleRequest('post','a3s_favourites_add',[],body,null,null,true).then(res=>{
      this.util.success('Favourites Updated successfully!')
    },err=>{
      this.util.error('Failed to update favourites.')
    })
  }

}
