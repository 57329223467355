<div  class="custom-fixed-tables" >
    <table class="table">
        <thead>
          <tr>
            <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('storageAccountData',0)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('storageAccountData',1)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('storageAccountData',2)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('storageAccountData',3)"></div></th>
            <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('storageAccountData',4)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Kind</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('storageAccountData',5)"></div></th>
            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('storageAccountData',6)"></div></th>
            <th style="border-left: none;" class="wid-75"></th>
        </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data">
              <td isEllipsisActive class="blue-text cursor-pointer"  (click)="openModal(storageAccountDetailsTemplate,row,'storageAccountPopUp')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td isEllipsisActive>{{ getProjectName(row[2]) | displayTxt }}</td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
              <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
              <td isEllipsisActive>{{getTags(row[6])}}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2"> 
                  <mat-menu #action2="matMenu" class="delete-popup">
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                      <p class="delete-popup-text">Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('networking',row)">
                      <p class="delete-popup-text">Networking</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('encryption',row)">
                      <p class="delete-popup-text">Encryption</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('dataProtection',row)" *ngIf="row[5]!='FileStorage'">
                      <p class="delete-popup-text">Data Protection</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item  (click)="action(row,'deleteStorageAccountData')">
                      <p class="delete-popup-text" >Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>
  <ng-container *ngIf="showSidesheet">
    <!-- configuration -->
    <ng-container *ngIf="currentTemplate == 'configuration'">
     <div class="sidebar-right single add-alert" >
         <div class="header">
             <div class="d-flex">
               <h4 class="threeFour-width ">Configuration<br> <span class="blur-text">Storage Account | {{selectedRow[0]}}</span></h4>
               <div class="text-right half-width">
                 <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                   <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                 </button>
               </div>
             </div>
         </div>
         <div class="body scroll-y">
          <form [formGroup]="currentForm">
            <div class="mb-3">
              <label class="flex-custom no-wrap">Account kind</label>
              <label class="flex-custom no-wrap">{{selectedRow[5]}}</label>
            </div>
            <div class="mb-3">
              <label class="flex-custom no-wrap">Performance<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Standard storage accounts are backed by magnetic drives and 
                provide the lowest cost per GB. They're best for applications
                that require bulk storage or where data is accessed infrequently. 
                Premium storage accounts are backed by solid state drives and 
                offer consistent, low-latency performance. They can only be used 
                with Azure virtual machine disks, and are best for I/O-intensive 
                applications, like databases." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom">
                  <div class="checkbox-cs">
                    <input type="radio" name="performance"  class="form-check-inline" id='Standard' (change)="setRadioBtn('no','config','performance')" [checked]="currentForm.get('performance')?.value =='Standard'" disabled>
                    <label class="value" for="Standard">Standard</label>
                </div>
                <div class="checkbox-cs">
                    <input type="radio" name="performance" class="form-check-inline" id='Premium' (change)="setRadioBtn('yes','config','performance')" [checked]="currentForm.get('performance')?.value == 'Premium'" disabled>
                    <label class="value" for="Premium">Premium</label>
                </div>
              </div>
             </div>
             <div class="mb-3">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  This setting cannot be changed after the storage account is created.</label>
              </div>  
            </div>
             <div class="mb-3">
              <label class="flex-custom no-wrap">Secure transfer required<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The secure transfer option enhances the security of your storage account by 
                only allowing requests to the storage account by secure connection. For example, 
                when calling REST APIs to access your storage accounts, you must connect 
                using HTTPs. Any requests using HTTP will be rejected when 'secure transfer
                required' is enabled. When you are using the Azure files service, connection 
                without encryption will fail, including scenarios using SMB 2.1, SMB 3.0 without 
                encryption, and some flavors of the Linux SMB client. Because Azure storage 
                doesn’t support HTTPs for custom domain names, this option is not applied when 
                using a custom domain name." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom">
                  <div class="checkbox-cs">
                    <input type="radio" name="secureTransfer"  class="form-check-inline" id='secureTransferDisable' (change)="setRadioBtn('no','config','secureTransfer')" [checked]="currentForm.get('secureTransfer')?.value ==false">
                    <label class="value" for="secureTransferDisable">Disable</label>
                </div>
                <div class="checkbox-cs">
                    <input type="radio" name="secureTransfer" class="form-check-inline" id='secureTransferEnable' (change)="setRadioBtn('yes','config','secureTransfer')" [checked]="currentForm.get('secureTransfer')?.value == true">
                    <label class="value" for="secureTransferEnable">Enable</label>
                </div>
              </div>
             </div>
             <div class="mb-3">
              <label class="flex-custom no-wrap">Allow Blob public access<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When allow blob public access is enabled, one is permitted to configure container ACLs 
                to allow anonymous access to blobs within the storage account. When disabled, no 
                anonymous access to blobs within the storage account is permitted, regardless of 
                underlying ACL configurations" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom">
                  <div class="checkbox-cs">
                    <input type="radio" name="allowBlob"  class="form-check-inline" id='allowBlobDisable' (change)="setRadioBtn('no','config','allowBlob')" [checked]="currentForm.get('allowBlob')?.value ==false">
                    <label class="value" for="allowBlobDisable">Disable</label>
                </div>
                <div class="checkbox-cs">
                    <input type="radio" name="allowBlob" class="form-check-inline" id='allowBlobEnable' (change)="setRadioBtn('yes','config','allowBlob')" [checked]="currentForm.get('allowBlob')?.value == true">
                    <label class="value" for="allowBlobEnable">Enable</label>
                </div>
              </div>
             </div>
             <div class="mb-3" *ngIf="!currentForm.get('allowBlob')?.value">
              <div class="label-div d-flex warningBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                <label class="" style="padding: 8px 0px !important;color: #703501;">
                  When Allow storage account key access is disabled, any requests to the account that are authorized with Shared Key, including shared access signatures (SAS), will be denied. Client applications that currently access the storage account using Shared Key will no longer work.</label>
               </div>  
             </div>
             <div class="mb-3">
              <label class="flex-custom no-wrap">Allow storage account key access<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When Allow storage account key access is disabled, any requests to the account that are 
                authorized with Shared Key, including shared access signatures (SAS), will be denied" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom">
                  <div class="checkbox-cs">
                    <input type="radio" name="allowAccountKey"  class="form-check-inline" id='allowAccountKeyDisable' (change)="setRadioBtn('no','config','allowAccountKey')" [checked]="currentForm.get('allowAccountKey')?.value ==false">
                    <label class="value" for="allowAccountKeyDisable">Disable</label>
                </div>
                <div class="checkbox-cs">
                    <input type="radio" name="allowAccountKey" class="form-check-inline" id='allowAccountKeyEnable' (change)="setRadioBtn('yes','config','allowAccountKey')" [checked]="currentForm.get('allowAccountKey')?.value == true">
                    <label class="value" for="allowAccountKeyEnable">Enable</label>
                </div>
              </div>
             </div>
             <div class="mb-3" *ngIf="!currentForm.get('allowAccountKey')?.value">
              <div class="label-div d-flex warningBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                <label class="" style="padding: 8px 0px !important;color: #703501;">
                  When Allow storage account key access is disabled, any requests to the account that are authorized with Shared Key, including shared access signatures (SAS), will be denied. Client applications that currently access the storage account using Shared Key will no longer work.</label>
               </div>  
            </div>
             <div class="mb-3">
              <label class="no-wrap">Allow recommended upper limit for shared access 
                signature (SAS) expiry interval<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When a SAS token with an expiry that exceeds the recommended interval is used, 
                that usage is logged." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom">
                  <div class="checkbox-cs">
                    <input type="radio" name="allowUpperLimit"  class="form-check-inline" id='allowUpperLimitDisable' (change)="setRadioBtn('no','config','allowUpperLimit')" [checked]="currentForm.get('allowUpperLimit')?.value ==false">
                    <label class="value" for="allowUpperLimitDisable">Disable</label>
                </div>
                <div class="checkbox-cs">
                    <input type="radio" name="allowUpperLimit" class="form-check-inline" id='allowUpperLimitEnable' (change)="setRadioBtn('yes','config','allowUpperLimit')" [checked]="currentForm.get('allowUpperLimit')?.value == true">
                    <label class="value" for="allowUpperLimitEnable">Enable</label>
                </div>
              </div>
             </div>
             <ng-container *ngIf="currentForm.get('allowUpperLimit')?.value">
              <div class="mb-3">
                <label class="flex-custom no-wrap">Days<span class="mandatory">*</span></label>
                <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.daysNameEmpty|| inputError?.daysInvalidNumber" id="days" name="days" formControlName="days" (keyup)="validInstance($event,'days')"/>
                <p class="text-danger" *ngIf="inputError?.daysNameEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.daysInvalidNumber">The value must be a valid number.</p>
               </div>
               <div class="mb-3">
                <label class="flex-custom no-wrap">Hours<span class="mandatory">*</span></label>
                <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.hoursNameEmpty|| inputError?.hoursInvalidNumber" id="hours" name="hours" formControlName="hours" (keyup)="validInstance($event,'hours')"/>
                <p class="text-danger" *ngIf="inputError?.hoursNameEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.hoursInvalidNumber">The value must be a valid number.</p>
               </div>
               <div class="mb-3">
                <label class="flex-custom no-wrap">Minutes<span class="mandatory">*</span></label>
                <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.minsNameEmpty|| inputError?.minsInvalidNumber" id="mins" name="mins" formControlName="mins" (keyup)="validInstance($event,'mins')"/>
                <p class="text-danger" *ngIf="inputError?.minsNameEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.minsInvalidNumber">The value must be a valid number.</p>
               </div>
               <div class="mb-3">
                <label class="flex-custom no-wrap">Seconds<span class="mandatory">*</span></label>
                <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.secNameEmpty|| inputError?.secInvalidNumber" id="seconds" name="seconds" formControlName="seconds" (keyup)="validInstance($event,'sec')"/>
                <p class="text-danger" *ngIf="inputError?.secNameEmpty">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.secInvalidNumber">The value must be a valid number.</p>
               </div>
             </ng-container>
            
             <div class="mb-3">
              <label class="no-wrap">Default to Azure Active Directory authorization in 
                the Azure portal<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When this property is enabled, the Azure portal authorizes requests to blobs, queues, 
                and tables with Azure Active Directory by default." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom">
                  <div class="checkbox-cs">
                    <input type="radio" name="defaultAAD"  class="form-check-inline" id='defaultAADDisable' (change)="setRadioBtn('no','config','defaultAAD')" [checked]="currentForm.get('defaultAAD')?.value ==false">
                    <label class="value" for="defaultAADDisable">Disable</label>
                </div>
                <div class="checkbox-cs">
                    <input type="radio" name="defaultAAD" class="form-check-inline" id='defaultAADEnable' (change)="setRadioBtn('yes','config','defaultAAD')" [checked]="currentForm.get('defaultAAD')?.value == true">
                    <label class="value" for="defaultAADEnable">Enable</label>
                </div>
              </div>
             </div>
             <div class="mb-3">
              <label class="flex-custom no-wrap">Minimum TLS version<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set the minimum TLS version needed by applications using your storage account's data." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <ng-select [items]="minTLSList" bindLabel="name" bindValue="id" (change)="validTLSVersion($event)" name="minimumTLSVersion" [clearable]="false" id="minimumTLSVersion" formControlName="minimumTLSVersion"></ng-select>
             </div>
             <div class="mb-3" *ngIf="validMinTLSVersion=='high'">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  Increase the TLS version will prevent connections using a lower TLS version from connecting to this storage account</label>
              </div>  
             </div>
             <div class="mb-3" *ngIf="validMinTLSVersion=='low'">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  Lowering the TLS version may create security and compliance issues for this storage account.</label>
              </div>  
             </div>
             <div class="mb-3" *ngIf="selectedRow[5]=='StorageV2'">
              <label class="flex-custom no-wrap">Blob access tier (default)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The account access tier is the default tier that is inferred by any blob without an explicitly 
                set tier. The hot access tier is ideal for frequently accessed data, and the cool access tier 
                is ideal for infrequently accessed data. The archive access tier can only be set at the blob 
                level and not on the account." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom">
                  <div class="checkbox-cs">
                    <input type="radio" name="accessTier"  class="form-check-inline" id='accessTierCool' (change)="setRadioBtn('Cool','config','accessTier')" [checked]="currentForm.get('accessTier')?.value =='Cool'">
                    <label class="value" for="accessTierCool">Cool</label>
                </div>
                <div class="checkbox-cs">
                    <input type="radio" name="accessTier" class="form-check-inline" id='accessTierHot' (change)="setRadioBtn('Hot','config','accessTier')" [checked]="currentForm.get('accessTier')?.value == 'Hot'">
                    <label class="value" for="accessTierHot">Hot</label>
                </div>
              </div>
             </div>
             <div class="mb-3" *ngIf="currentForm.get('accessTier')?.value=='Cool'">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  Changing the access tier may result in additional charges.</label>
              </div>  
             </div>
             <div class="mb-3">
              <label class="flex-custom no-wrap">Replication<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The data in your Azure storage account is always replicated to ensure durability and high 
               availability. Choose a replication strategy that matches your durability requirements. Some 
               settings can't be changed after the storage account is created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <ng-select [items]="replicationList" [class.disabled]="selectedRow[4]=='Premium'" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="replication" [clearable]="false" id="replication" formControlName="replication"></ng-select>
             </div>
             <div class="mb-3" *ngIf="selectedRow[5]=='StorageV2'">
              <label class="flex-custom no-wrap">Large file shares<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provides file share support up to a maximum of 100 TiB. Large file share storage accounts do not 
                have the ability to convert to geo-redundant storage offerings and upgrade is permanent." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <div class="flex-custom">
                  <div class="checkbox-cs">
                    <input type="radio" name="largeFileShares"  class="form-check-inline" id='largeFileSharesDisable' (change)="setRadioBtn('no','config','largeFileShares')" [checked]="currentForm.get('largeFileShares')?.value ==false" [disabled]="!storageAccountData?.sku?.name.includes('LRS') || storageAccountData?.properties?.largeFileSharesState=='Enabled' || selectedRow[4]=='Premium'">
                    <label class="value" for="largeFileSharesDisable">Disable</label>
                </div>
                <div class="checkbox-cs">
                    <input type="radio" name="largeFileShares" class="form-check-inline" id='largeFileSharesEnable' (change)="setRadioBtn('yes','config','largeFileShares')" [checked]="currentForm.get('largeFileShares')?.value == true" [disabled]="!storageAccountData?.sku?.name.includes('LRS') || storageAccountData?.properties?.largeFileSharesState=='Enabled' || selectedRow[4]=='Premium'">
                    <label class="value" for="largeFileSharesEnable">Enable</label>
                </div>
              </div>
             </div>
             <div class="mb-3" *ngIf="currentForm.get('largeFileShares')?.value && storageAccountData?.properties?.largeFileSharesState!='Enabled'">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  Large file share storage accounts do not have the ability to convert to geo-redundant storage offerings and upgrade is permanent.</label>
              </div>  
            </div>
            <div class="mb-3" *ngIf="selectedRow[5]=='StorageV2' && selectedRow[4]=='Premium'">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  The current combination of subscription, storage account kind, performance, replication and location does not support large file shares.</label>
              </div>  
            </div>
            <div class="mb-3" *ngIf="selectedRow[5]=='BlockBlobStorage'">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  Replication settings cannot be changed for premium block blob storage accounts.</label>
              </div>  
            </div>
          </form>
         </div>
         <div class="fixed-btm-btn flex-custom no-wrap">
           <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('config') || !saveDisable "(click)="saveStorageAccountData('config')">Save</button>
        </div>
     </div>
   </ng-container>
    <!-- encryption -->
    <ng-container *ngIf="currentTemplate == 'encryption'">
      <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Encryption<br> <span class="blur-text">Storage Account | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
           <form [formGroup]="currentForm">
            <div class="mb-3">
              <label class="flex-custom no-wrap bold-600">Encryption Selection</label>
            </div>
             <div class="mb-3">
               <label class="no-wrap">Enable support for customer-managed keys<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Customer-managed key (CMK) support can be limited to blob service 
                and file service only, or to all service types. After the storage account 
                is created, this support cannot be changed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
               <label class="flex-custom no-wrap bold-600">{{storageAccountData?.properties?.encryption?.services?.blob?.enabled && storageAccountData?.properties?.encryption?.services?.file?.enabled ? 'Blobs and files only ' : storageAccountData?.properties?.encryption?.services?.blob?.enabled?'Blobs only':'Files only'}}</label>
             </div>
             <div class="mb-3">
              <label class="flex-custom no-wrap">Infrastructure encryption<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="By default, Azure encrypts storage account data at rest. Infrastructure 
                encryption adds a second layer of encryption to your storage account’s 
                data. This option can only be enabled during the storage account creation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <label class="flex-custom no-wrap bold-600">{{storageAccountData?.properties?.encryption?.requireInfrastructureEncryption ? 'Enabled' : 'Disabled'}}</label>
            </div>
             <div class="mb-3">
               <label class="flex-custom no-wrap">Encryption type</label>
               <div class="flex-custom">
                   <div class="checkbox-cs">
                     <input type="radio" name="encryptionType"  class="form-check-inline" id='Microsoft' (change)="setRadioBtn('Microsoft-managed','encryption','encryptionType')" [checked]="currentForm.get('encryptionType')?.value =='Microsoft-managed'">
                     <label class="value" for="Microsoft">Microsoft-managed keys</label>
                 </div>
                </div>
                <div class="flex-custom">
                 <div class="checkbox-cs">
                     <input type="radio" name="encryptionType" class="form-check-inline" id='Customer' (change)="setRadioBtn('Customer-managed','encryption','encryptionType')" [checked]="currentForm.get('encryptionType')?.value == 'Customer-managed'">
                     <label class="value" for="Customer">Customer-managed keys</label>
                 </div>
               </div>
              </div>
              <ng-container *ngIf="currentForm.get('encryptionType')?.value=='Customer-managed'">
                <div class="mb-3">
                  <label class="flex-custom no-wrap bold-600">Key Selection</label>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Encryption key</label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="encryptionKey"  class="form-check-inline" id='keyVault' (change)="setRadioBtn('keyVault','encryption','encryptionKey')" [checked]="currentForm.get('encryptionKey')?.value =='keyVault'">
                        <label class="value" for="keyVault">Select from key vault</label>
                    </div>
                   </div>
                   <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="encryptionKey" class="form-check-inline" id='keyURI' (change)="setRadioBtn('keyURI','encryption','encryptionKey')" [checked]="currentForm.get('encryptionKey')?.value == 'keyURI'">
                        <label class="value" for="keyURI">Enter key URI</label>
                    </div>
                  </div>
                 </div>
                 <div class="mb-3" *ngIf="currentForm.get('encryptionKey')?.value=='keyVault'">
                  <label class="flex-custom no-wrap bold-600">Key vault and key<span class="mandatory">*</span></label>
                  <label class="flex-custom no-wrap blue-text" (click)="openSubSideSheet('selectKeyVault')">Select a key vault and key</label>
                </div>
                <div class="mb-3" *ngIf="currentForm.get('encryptionKey')?.value=='keyURI'">
                  <label class="flex-custom no-wrap">Key URI<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="
                    The URI specifying the Key vault and Key being used to secure this storage account. Specify a key version to maintain a binding to it and opt out of Automated Key Rotation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.uriNameEmpty|| inputError?.uriInvalidURL" id="keyURI" name="keyURI" formControlName="keyURI" (keyup)="validKeyURI($event,'uri')"/>
                  <p class="text-danger" *ngIf="inputError?.uriNameEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.uriInvalidURL">Invalid Key URI</p>
                 </div>
                 <div class="mb-3" *ngIf="currentForm.get('encryptionKey')?.value=='keyURI'">
                  <label class="flex-custom no-wrap">Subscription<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the subscription which contains 
                    the key vault and key specified by the key URI." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <ng-select [items]="subscriptionList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="sub" [clearable]="false" id="sub" formControlName="sub"></ng-select>
                 </div>
                 <div class="mb-3">
                  <label class="flex-custom no-wrap">Identity type<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="System and User-assigned Managed Identities can be used to authenticate to any Azure service that supports Azure AD authentication including Azure Key Vault. System-assigned is used by default and is enabled behind the scenes." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="identityType"  class="form-check-inline" id='systemAssigned' (change)="setRadioBtn('systemAssigned','encryption','identityType')" [checked]="currentForm.get('identityType')?.value =='systemAssigned'">
                        <label class="value" for="systemAssigned">System-assigned</label>
                    </div>
                   </div>
                   <div class="flex-custom">
                    <div class="checkbox-cs">
                        <input type="radio" name="identityType" class="form-check-inline" id='userAssigned' (change)="setRadioBtn('userAssigned','encryption','identityType')" [checked]="currentForm.get('identityType')?.value == 'userAssigned'">
                        <label class="value" for="userAssigned">User-assigned</label>
                    </div>
                  </div>
                 </div>
                 <div class="mb-3" *ngIf="currentForm.get('identityType')?.value=='userAssigned'">
                  <label class="flex-custom no-wrap bold-600">User-assigned identity<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="User-assigned identity is an identity in Azure 
                    Active Directory that can be used to give access
                    to other Azure resource such as Azure Key Vault." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <label class="flex-custom no-wrap blue-text" (click)="openSubSideSheet('selectIdentity')">Select an identity</label>
                </div>
              </ng-container>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('encryption') || !saveDisable "(click)="saveStorageAccountData('encryption')">Save</button>
         </div>
      </div>
      <ng-container *ngIf="showSubSidesheet && currentSubSheet=='selectKeyVault'">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">Select a key<br> <span class="blur-text">Storage Account | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
        
            <div class="body scroll-y">
                <form [formGroup]="subSideSheetForm">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Subscription</label>
                    <ng-select [items]="subscriptionList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="sub" [clearable]="false" id="sub" formControlName="sub"></ng-select>
                   </div>
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Key store type<span class="mandatory">*</span></label>
                      <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="keyStoreType"  class="form-check-inline" id='keyVaults' (change)="setRadioBtn('keyVault','encryption','keyStoreType')" [checked]="subSideSheetForm.get('keyStoreType')?.value =='keyVault'" >
                          <label class="value" for="keyVaults">Key vault</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="keyStoreType" class="form-check-inline" id='managedHSM' (change)="setRadioBtn('managedHSM','encryption','keyStoreType')" [checked]="subSideSheetForm.get('keyStoreType')?.value == 'managedHSM'">
                          <label class="value" for="managedHSM">Managed HSM</label>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3" *ngIf="subSideSheetForm.get('keyStoreType')?.value=='keyVault'">
                    <label class="flex-custom no-wrap">Key vault<span class="mandatory">*</span></label>
                    <ng-select [items]="keyVaultList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="keyVault" [clearable]="false" id="keyVault" formControlName="keyVault"></ng-select>
                   </div>
                   <div class="mb-3" *ngIf="subSideSheetForm.get('keyStoreType')?.value=='managedHSM'">
                    <label class="flex-custom no-wrap">Managed HSM<span class="mandatory">*</span></label>
                    <ng-select [items]="hsmList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="managedHSM" [clearable]="false" id="managedHSM" formControlName="managedHSM"></ng-select>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Key<span class="mandatory">*</span></label>
                    <ng-select [items]="keyList" [class.disabled]="subSideSheetForm.get('keyStoreType')?.value=='keyVault' && !subSideSheetForm.get('keyVault')?.value || (subSideSheetForm.get('keyStoreType')?.value=='managedHSM' && !subSideSheetForm.get('managedHSM')?.value)" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="key" [clearable]="false" id="key" formControlName="key"></ng-select>
                   </div>
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveEnable || subSideSheetForm.invalid" (click)="saveStorageAccountData('selectedKeyVault')">Add</button>
            </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showSubSidesheet && currentSubSheet=='selectIdentity'">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">Select user assigned manage identities<br> <span class="blur-text">Storage Account | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
        
            <div class="body scroll-y">
                <form [formGroup]="subSideSheetForm">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Subscription</label>
                    <ng-select [items]="subscriptionList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="sub" [clearable]="false" id="sub" formControlName="sub"></ng-select>
                   </div>
                  <div class="" style="margin-bottom:125px ;">
                    <label class="flex-custom no-wrap">User assigned managed identities<span class="mandatory">*</span></label>
                    <img src="assets/icons/search.png" class="icon">
                    <input class="form-control full-width pl-4"  type="text" autocomplete="off"  placeholder="Filter by identity or resource group" id="userIdentities" name="userIdentities" formControlName="userIdentities" (keyup)="searchIdentity($event)"/>
                   </div>
                   <div class="search-expands">
                    <div class="search-result" *ngFor="let list of searchContent">
                        <span class="list" (click)="changeTab('parent',list)"
                        (click)="scrollToPosition(list?.activeSidebar);sidebarScroll(list?.id)">{{list?.value}}</span> 
                        <ng-container *ngFor="let subList of list?.child">
                          <div class="subList"  (click)="changeTab('child',list,subList);sidebarScroll(subList?.id)"
                          (click)="scrollToPosition(subList?.activeSidebar)">{{subList?.value}}</div>
                          <p class="subSubList" *ngFor="let subSubList of subList?.subChild" (click)="changeTab('subChild',list,subList,subSubList)"
                          (click)="scrollToPosition(subSubList?.activeSidebar);sidebarScroll(subSubList?.id)">{{subSubList?.value}}</p>
                        </ng-container>
                    </div>
                    <div class="search-result" *ngIf="!searchContent?.length">
                       <span class="noresult">No results found</span> 
                   </div>
                 </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Selected identity:</label>
                    <label class="flex-custom no-wrap blur-text">No user assigned managed identity selected. Select a user assigned managed identity you want to assign to this resource.</label>
                   </div>
                </form>
            </div>
           
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveEnable  || subSideSheetForm.invalid" (click)="saveAppGatewayData('backendSetting')">Add</button>
            </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- Networking -->
    <ng-container *ngIf="currentTemplate == 'networking'">
      <div class="sidebar-right single add-alert" [class.add-alert]="currentForm.get('allowAccess')?.value != 'selectedNetworks'" [class.sub-sheet]="showSubSidesheet" [class.width-881]="currentForm.get('allowAccess')?.value == 'selectedNetworks'" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Networking<br> <span class="blur-text">Storage Account | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
           <form [formGroup]="currentForm">
             <div class="mb-3">
               <label class="flex-custom no-wrap">Allow access from</label>
               <div class="flex-custom">
                   <div class="checkbox-cs">
                     <input type="radio" name="allowAccess"  class="form-check-inline" id='all' (change)="setRadioBtn('allNetworks','networking','allowAccess')" [checked]="currentForm.get('allowAccess')?.value =='allNetworks'">
                     <label class="value" for="all">All networks  </label>
                 </div>
                 <div class="checkbox-cs">
                     <input type="radio" name="allowAccess" class="form-check-inline" id='selected' (change)="setRadioBtn('selectedNetworks','networking','allowAccess')" [checked]="currentForm.get('allowAccess')?.value == 'selectedNetworks'">
                     <label class="value" for="selected">Selected networks</label>
                 </div>
               </div>
              </div>
              <ng-container *ngIf="currentForm.get('allowAccess')?.value == 'selectedNetworks'">
                <div class="mb-3">
                  <label class="flex-custom no-wrap bold-600">Virtual networks</label>
                </div>
                <div class="flex-custom">
                  <div class="mb-3">
                    <button class="btn-btn-secondary" (click)="openSubSideSheet('addVNet')">
                    <img src="/assets/governance/add-btn.svg" alt="">Add existing virtual network </button>
                  </div>
                  <div class="mb-3">
                    <button class="btn-btn-secondary" (click)="openSubSideSheet('createVNet')">
                    <img src="/assets/governance/add-btn.svg" alt="">Add new virtual network</button>
                  </div>
                </div>
            <div class="custom-fixed-tables">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Virtual Network</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Subnet</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Address range</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Endpoint Status</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Resource Group</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Subscription</span></div></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of storageAccountData?.properties?.networkAcls?.virtualNetworkRules;let i=index">
                            <td isEllipsisActive>{{row?.id.split('/')[8]}}</td>
                            <td isEllipsisActive>{{row?.id.split('/')[10]}}</td>
                            <td isEllipsisActive>{{getSubnetAddressSpace(row.id)}}</td>
                            <td isEllipsisActive>{{getSubnetEndpoint(row.id)}}</td>
                            <td isEllipsisActive>{{row?.id.split('/')[4]}}</td>
                            <td isEllipsisActive>{{getProjectName(row?.id.split('/')[2])}}</td>
                          </tr>
                          <div class="col-md-12 mt-3" *ngIf="!storageAccountData?.properties?.networkAcls?.virtualNetworkRules?.length">
                            <p class="content-14 blur-text">No network selected.</p>
                          </div>
                        </tbody>
                  </table>
                </div>
                <div class="mb-2">
                  <label class="flex-custom no-wrap">Firewall<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add IP ranges to allow access from the internet 
                    or your on-premises networks." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
                 <div class="mb-3">
                  <input type="checkbox"  name="addClientIP" class="form-check-inline"  id='addClientIP'  (change)="saveDisable=true" formControlName="addClientIP" >
                  <label class="value" for="addClientIP">Add your client IP address ('156.212.207.105')<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You may not wish to add your client IP address if the network you  are using 
                    the azure portal from is atypical (home vs. work environment for example)." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                 </div>
                <div class="mb-3">
                  <ng-container formArrayName="ipArray" *ngFor="let tanch of currentForm.get('ipArray')['controls']; let i = index;">
                    <div class="flex-custom no-wrap">
                      <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                       <div class="" style="width:900px;">
                          <label class="flex-custom no-wrap ">Address range</label>
                          <input class="form-control full-width"  type="text" autocomplete="off" id="AddressRange" name="value" formControlName="value" (keyup)="CheckIPAddress($event,'ip')" />
                        </div>
                        <ng-container *ngIf="addressRangeList?.length > 1">
                          <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-12" (click)="removeAddressRangeList(i,tanch)">
                        </ng-container>
                      </div>
                    </div>
                    <p class="text-danger" *ngIf="inputError?.ipError||inputError?.ipNonNullRange">Invalid IP address.</p>
                    <p class="text-danger" *ngIf="inputError?.ipUnique">Two identical address ranges have been specified. Address ranges must be unique.</p>
                    <div class="action-btn" style="margin-top: -4px;" (click)="addAddressRangeList()" *ngIf="i == addressRangeList?.length-1">Add address range</div>
                  </ng-container>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap bold-600">Resource instances<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify resource instances that will have access to your storage account 
                    based on their system-assigned managed identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
                <div class="mb-3">
                  <ng-container formArrayName="instanceArray" *ngFor="let tanch of currentForm.get('instanceArray')['controls']; let i = index;">
                    <div class="flex-custom no-wrap">
                      <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                        <div class="mr-3" style="width:440px;">
                          <label class="flex-custom no-wrap ">Resource type</label>
                          <ng-select [items]="ipPoolList"  bindLabel="name" bindValue="name"  (change)="saveDisable=true" name="resourceType" [clearable]="false" id="resourceType" formControlName="resourceType"></ng-select>
                        </div>
                        <div class="mr-3" style="width:440px;">
                          <label class="flex-custom no-wrap ">Instance name</label>
                          <ng-select [items]="instanceList" [class.disabled]="!tanch.get('resourceType').value"  bindLabel="name" bindValue="name"  (change)="saveDisable=true" name="instanceName" [clearable]="false" id="instanceName" formControlName="instanceName"></ng-select>
                        </div>
                        <ng-container *ngIf="instanceArrayList?.length > 1">
                          <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer mt-12" (click)="removeInstanceList(i,tanch)">
                        </ng-container>
                      </div>
                    </div>
                    <!-- <p class="text-danger" *ngIf="inputError?.ipError"> This IP address is already added in this backend pool.</p> -->
                    <div class="action-btn" style="margin-top: -4px;" (click)="addInstanceArray()" *ngIf="i == instanceArrayList?.length-1">Add instance</div>
                  </ng-container>
                </div>

                <div class="mb-2">
                  <label class="flex-custom no-wrap">Exceptions</label>
                </div>
                 <div class="mb-1">
                  <input type="checkbox"  name="allowAS" class="form-check-inline"  id='allowAS'  (change)="saveDisable=true" formControlName="allowAS" >
                  <label class="value" for="allowAS">Allow Azure services on the trusted services list to access this storage account.</label>
                 </div>
                 <div class="mb-1">
                  <input type="checkbox"  name="storageLogging" class="form-check-inline"  id='storageLogging'  (change)="saveDisable=true" formControlName="storageLogging" >
                  <label class="value" for="storageLogging">Allow read access to storage logging from any network.</label>
                 </div>
                 <div class="mb-3">
                  <input type="checkbox"  name="storageMetrics" class="form-check-inline"  id='storageMetrics'  (change)="saveDisable=true" formControlName="storageMetrics" >
                  <label class="value" for="storageMetrics">Allow read access to storage metrics from any network.</label>
                 </div>
              </ng-container>
              <div class="mb-3">
                <label class="flex-custom no-wrap bold-600">Network Routing<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Determine how you would like to route your traffic as it 
                  travels from its source to an Azure endpoint. Microsoft routing 
                  is recommended for most customers." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Routing preference<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Microsoft network routing will direct your traffic to enter the Microsoft cloud
                  closer to the requesting client. Internet routing will direct your traffic to enter
                  the Microsoft cloud closer to the Azure storage endpoint." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="routingPreference"  class="form-check-inline" id='Microsoft' (change)="setRadioBtn('Microsoft','networking','routingPreference')" [checked]="currentForm.get('routingPreference')?.value =='Microsoft'" [disabled]="selectedRow[5]!='StorageV2'">
                      <label class="value" for="Microsoft"  [class.disabled-icon]="selectedRow[5]!='StorageV2'">Microsoft network routing</label>
                  </div>
                 </div>
                 <div class="flex-custom">
                  <div class="checkbox-cs">
                      <input type="radio" name="routingPreference" class="form-check-inline" id='Internet' (change)="setRadioBtn('Internet','networking','routingPreference')" [checked]="currentForm.get('routingPreference')?.value == 'Internet'" [disabled]="selectedRow[5]!='StorageV2'">
                      <label class="value" for="Internet" [class.disabled-icon]="selectedRow[5]!='StorageV2'">Internet routing</label>
                  </div>
                </div>
               </div>
               <div class="mb-3" *ngIf="selectedRow[5]!='StorageV2'">
                <div class="label-div d-flex blueBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;">
                    The current combination of subscription, storage account kind, performance, replication and location does not support large file shares.</label>
                </div>  
              </div>
              <ng-container *ngIf="selectedRow[5]=='StorageV2'">
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600">Publish route-specific endpoints<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Determine which route-specific public endpoints to publish. 
                    You can find published endpoints under Properties." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
                 <div class="mb-1">
                  <input type="checkbox"  name="networkRouting" class="form-check-inline"  id='networkRouting'  (change)="saveDisable=true" formControlName="networkRouting" >
                  <label class="value" for="networkRouting">Microsoft network routing</label>
                 </div>
                 <div class="mb-1">
                  <input type="checkbox"  name="internetRouting" class="form-check-inline"  id='internetRouting'  (change)="saveDisable=true" formControlName="internetRouting" >
                  <label class="value" for="internetRouting">Internet routing</label>
                 </div>
              </ng-container>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('networking') || !saveDisable "(click)="saveStorageAccountData('networking')">Save</button>
         </div>
      </div>
      <ng-container *ngIf="showSubSidesheet && currentSubSheet=='addVNet'">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">Add Networks<br> <span class="blur-text">Storage Account | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
        
            <div class="body scroll-y">
                <form [formGroup]="subSideSheetForm">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Subscription</label>
                    <ng-select [items]="subscriptionList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="sub" [clearable]="false" id="sub" formControlName="sub"></ng-select>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Virtual network<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only virtual networks in 'East US',
                      'West US' and 'West US 3' will be listed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                   <div class="margin-multi-select">
                    <ng-select class="full-width" [items]="vNetList" groupBy="rg" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" (change)="getSubnetBYVnet($event)" placeholder="0 Selected" name="vNet" [clearable]="false" id="vNet" formControlName="vNet">
                        <ng-template ng-multi-label-tmp let-items="items">
                            <span>{{items.length}} selected</span>
                          </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div>
                              <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected"  [checked]="item$.selected"/> {{item.name}}
                            </div>
                          </ng-template>
                    </ng-select>
                   </div>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Subnets</label>
                 <div class="margin-multi-select">
                  <ng-select class="full-width" [items]="subnetList" groupBy="rg" [multiple]="true" [selectableGroup]="true" (change)="saveEnable=true" [closeOnSelect]="false" bindLabel="name" bindValue="id" placeholder="0 Selected" name="subnet" [clearable]="false" id="subnet" formControlName="subnet">
                      <ng-template ng-multi-label-tmp let-items="items">
                          <span>{{items.length}} selected</span>
                        </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div>
                            <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected"  [checked]="item$.selected"/> {{item.name}}
                          </div>
                        </ng-template>
                  </ng-select>
                 </div>
               </div>
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveEnable || subSideSheetForm.invalid" (click)="saveStorageAccountData('existVnet')">Enable</button>
            </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showSubSidesheet && currentSubSheet=='createVNet'">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">Create Virtual Network<br> <span class="blur-text">Storage Account | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
        
            <div class="body scroll-y">
                <form [formGroup]="subSideSheetForm">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                    <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.vnetNameEmpty|| inputError?.vnetNameMaxLen || inputError?.vnetNameExist|| inputError?.vnetNameValid" id="vNetName" name="vNetName" formControlName="vNetName" (keyup)="validIPName($event,'vnet','vnet')"/>
                    <p class="text-danger" *ngIf="inputError?.vnetNameEmpty">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.vnetNameMaxLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.vnetNameExist">Virtual Network name must be unique</p>
                    <p class="text-danger" *ngIf="inputError?.vnetNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                        and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Address space<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="
                      The virtual network's address range in CIDR notation" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.vnetAdrspc|| inputError?.vnetOverlaping" id="addressSpace" name="addressSpace" formControlName="addressSpace" (keyup)="subnetAddressSpace($event,'vnet');addressSpaceoverlap($event,'addressSpace')"/>
                    <p class="text-danger" *ngIf="inputError?.emptyBox">The value must not be empty. IPv4: null. IPv6: IPv6 Address range cannot be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.vnetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="inputError?.dnsUnique">Address spaces within a virtual network cannot overlap.</p> 
                    <!-- <p class="text-danger" *ngIf="inputError?.vnetNotValidCIDR">Invalid argument: 'The prefix must be between 1 and 32.'.</p> -->
                    <p class="text-danger" *ngIf="inputError?.vnetOverlaping ">Address space {{inputAddress}} overlaps with address space {{vNetOverlap}}. Address spaces within A virtual network cannot overlap. </p>
                   </div>
                  <!-- <div class="mb-3">
                    <label class="flex-custom no-wrap">Subscription<span class="mandatory">*</span></label>
                    <ng-select [items]="subscriptionList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="sub" [clearable]="false" id="sub" formControlName="sub"></ng-select>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Resource group<span class="mandatory">*</span></label>
                    <ng-select [items]="resourceGroupsList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="rg" [clearable]="false" id="rg" formControlName="rg"></ng-select>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Location<span class="mandatory">*</span></label>
                    <ng-select [items]="locationList" bindLabel="id" bindValue="id" (change)="saveEnable=true" name="loc" [clearable]="false" id="loc" formControlName="loc"></ng-select>
                   </div> -->
                   <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600">Subnet</label>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Subnet name<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="
                      The URI specifying the Key vault and Key being used to secure this storage account. Specify a key version to maintain a binding to it and opt out of Automated Key Rotation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.subnetNameEmpty|| inputError?.subnetNameMaxLen|| inputError?.subnetNameValid" id="subnetName" name="subnetName" formControlName="subnetName" (keyup)="validIPName($event,'subnet','subnet')"/>
                    <p class="text-danger" *ngIf="inputError?.subnetNameEmpty">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetNameMaxLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                        and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Address range<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="
                      The subnet's address range in CIDR notation (e.g. 192.168.1.0/24). It must be contained by the address space of the virtual network. The address range of a subnet which is in use can't be edited." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.subnetAdrspc|| inputError?.subnetOverlaping" id="addressRange" name="addressRange" formControlName="addressRange" (keyup)="subnetAddressSpace($event,'subnet');subnetOverlap($event,'subnet')"/>
                    <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                    <!-- <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid CIDR Block.</p> -->
                    <p class="text-danger" *ngIf="inputError?.subnetOverlaping">This subnet address space overlaps with existing {{vNetOverlap}}</p>
                    <p class="text-danger" *ngIf="inputError?.subnetPerfix">The prefix must be smaller than or equal to 29</p>
                    <p class="text-danger" *ngIf="inputError?.subnetAddresNotInVnet">Subnet address range should contained under same virtual network's address spaces</p>
                   </div>
                   <!-- <div class="mb-3">
                    <label class="flex-custom no-wrap">DDoS protection<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure DDoS basic protection is integrated into the Azure platform 
                      by default and at no additional cost. Azure DDoS standard protection 
                      is a premium paid service that offers enhanced DDoS mitigation capabilities 
                      via adaptive tuning, attack notification, and telemetry to protect against 
                      the impacts of a DDoS attack for all protected resources within this 
                      virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="dDosProtection"  class="form-check-inline" id='ddosBasic' (change)="setRadioBtn('Basic','networking','dDosProtection')" [checked]="subSideSheetForm.get('dDosProtection')?.value =='Basic'">
                          <label class="value" for="ddosBasic">Basic</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="dDosProtection" class="form-check-inline" id='ddosStandard' (change)="setRadioBtn('Standard','networking','dDosProtection')" [checked]="subSideSheetForm.get('dDosProtection')?.value == 'Standard'">
                          <label class="value" for="ddosStandard">Standard</label>
                      </div>
                    </div>
                   </div> -->
                   <!-- <ng-container  *ngIf="subSideSheetForm.get('dDosProtection')?.value=='Standard'">
                    <div  class="mb-3">
                      <input type="checkbox"  name="knowResId" class="form-check-inline"  id='knowResId'  (change)="saveEnable=true" formControlName="knowResId" >
                      <label class="value" for="knowResId">I know my resource ID</label>
                    </div>
                    <div class="mb-3" *ngIf="!subSideSheetForm.get('knowResId')?.value">
                      <label class="flex-custom no-wrap">DDoS protection plan resource ID<span class="mandatory">*</span></label>
                      <ng-select [items]="subscriptionList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="dDosResId" [clearable]="false" id="dDosResId" formControlName="dDosResId"></ng-select>
                     </div>
                     <div class="mb-3" *ngIf="subSideSheetForm.get('knowResId')?.value">
                      <label class="flex-custom no-wrap">DDoS protection plan resource ID<span class="mandatory">*</span></label>
                      <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.uriNameEmpty|| inputError?.uriInvalidURL" id="dDosResId" name="dDosResId" formControlName="dDosResId" (keyup)="validKeyURI($event,'uri')"/>
                      <p class="text-danger" *ngIf="inputError?.uriNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.uriInvalidURL">Invalid Key URI</p>
                     </div>
                   </ng-container> -->
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Service endpoint<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The subnet will be created with a service endpoint for 'Microsoft.Storage'." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <label class="flex-custom no-wrap bold-600">Microsoft.Storage</label>
                   </div>
                   <!-- <div class="mb-3">
                    <label class="flex-custom no-wrap">Firewall<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure Firewall is a managed cloud-based network security service 
                      that protects your Azure Virtual Network resources." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="firewall"  class="form-check-inline" id='firewallDisable' (change)="setRadioBtn('no','networking','firewall')" [checked]="subSideSheetForm.get('firewall')?.value ==false">
                          <label class="value" for="firewallDisable">Disabled</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="firewall" class="form-check-inline" id='firewallEnable' (change)="setRadioBtn('yes','networking','firewall')" [checked]="subSideSheetForm.get('firewall')?.value == true">
                          <label class="value" for="firewallEnable">Enabled</label>
                      </div>
                    </div>
                   </div>
                   <ng-container  *ngIf="subSideSheetForm.get('firewall')?.value">
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Firewall name<span class="mandatory">*</span></label>
                      <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.uriNameEmpty|| inputError?.uriInvalidURL" id="firewallName" name="firewallName" formControlName="firewallName" (keyup)="validKeyURI($event,'uri')"/>
                      <p class="text-danger" *ngIf="inputError?.uriNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.uriInvalidURL">Invalid Key URI</p>
                     </div>
                     <div class="mb-3">
                      <label class="flex-custom no-wrap">Firewall subnet address space<span class="mandatory">*</span></label>
                      <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.uriNameEmpty|| inputError?.uriInvalidURL" id="firewallAddressSpace" name="firewallAddressSpace" formControlName="firewallAddressSpace" (keyup)="validKeyURI($event,'uri')"/>
                      <p class="text-danger" *ngIf="inputError?.uriNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.uriInvalidURL">Invalid Key URI</p>
                     </div>
                     <div class="mb-3">
                      <label class="flex-custom no-wrap">Public IP address<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the public IP address to be 
                        used in the frontend IP configuration." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="publicIP"  class="form-check-inline" id='new' (change)="setRadioBtn('new','networking','publicIP')" [checked]="subSideSheetForm.get('publicIP')?.value =='new'">
                            <label class="value" for="new">Create new</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="publicIP" class="form-check-inline" id='exist' (change)="setRadioBtn('exist','networking','publicIP')" [checked]="subSideSheetForm.get('publicIP')?.value == 'exist'">
                            <label class="value" for="exist">Use existing</label>
                        </div>
                      </div>
                     </div>
                     <div class="mb-3" *ngIf="subSideSheetForm.get('publicIP')?.value=='new'">
                      <label class="flex-custom no-wrap">Public IP address name<span class="mandatory">*</span></label>
                      <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.uriNameEmpty|| inputError?.uriInvalidURL" id="publicIPName" name="publicIPName" formControlName="publicIPName" (keyup)="validKeyURI($event,'uri')"/>
                      <p class="text-danger" *ngIf="inputError?.uriNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.uriInvalidURL">Invalid Key URI</p>
                     </div>
                     <div class="mb-3" *ngIf="subSideSheetForm.get('publicIP')?.value=='exist'">
                      <label class="flex-custom no-wrap">Choose public IP address<span class="mandatory">*</span></label>
                      <ng-select [items]="subscriptionList" bindLabel="name" bindValue="id" (change)="saveEnable=true" name="publicIPName" [clearable]="false" id="publicIPName" formControlName="publicIPName"></ng-select>
                     </div>
                     <div class="mb-3">
                      <label class="flex-custom no-wrap">Public IP address SKU</label>
                      <label class="flex-custom no-wrap bold-600">Standard</label>
                     </div>
                   </ng-container> -->
                </form>
            </div>
           
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveEnable  || subSideSheetForm.invalid" (click)="saveStorageAccountData('createNewVnet')">Create</button>
            </div>
        </div>
      </ng-container>
    </ng-container>
     <!-- Data Protection -->
     <ng-container *ngIf="currentTemplate == 'dataProtection'" >
      <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" id="sideSheets">
          <div class="header">
              <div class="d-flex">
                  <h4 class="half-width">Data Protection<br> <span class="blur-text">Storage Account | {{selectedRow[0]}}</span></h4>
                 <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Data protection provides options for recovering your data when it is erroneously modified or deleted.</label>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap bold-600">Recovery</label>
                <div class="custom-fixed-tables mb-3">
                  <table class="table">
                        <thead>
                        </thead>
                        <tbody>
                            <tr class="accordion-toggle cursor-pointer" data-toggle="collapse" [attr.data-target]="'#azureBackup'" *ngIf="selectedRow[4]!='Premium'">
                                <td  style="width:auto ;max-width:none">
                                  <input type="checkbox"  name="azureBackup" class="form-check-inline"  id='enableAzureBackup'  (change)="saveDisable=true" formControlName="azureBackup" >
                                  <!-- [checked]="row.checked" -->
                                  <label class="value" for="enableAzureBackup">Enable operational backup with Azure Backup</label>
                                  <div class="flex-custom">
                                    <label id="azureBackup" class="accordian-body collapse">Azure Backup can help you protect blobs in this storage account and manage the protection at scale.</label>
                                  </div>
                                </td>
                            </tr>
                            <tr class="accordion-toggle cursor-pointer" data-toggle="collapse" [attr.data-target]="'#usePoint'" *ngIf="selectedRow[4]!='Premium'">
                              <td  style="width:auto ;max-width:none">
                                <input type="checkbox"  name="restoreContainer" class="form-check-inline"  id='enableRestoreContainer'  (change)="saveDisable=true" formControlName="restoreContainer" >
                                <label class="value" for="enableRestoreContainer">Enable point-in-time restore for containers</label>
                                <div class="flex-custom">
                                  <label id="usePoint" class="accordian-body collapse">Use point-in-time restore to restore one or more containers to an earlier state. If point-in-time restore is enabled, then versioning, change feed, and blob soft delete must also be enabled.</label>
                                </div>
                              </td>
                            </tr>
                            <tr class="accordion-toggle cursor-pointer" data-toggle="collapse" [attr.data-target]="'#softBlobs'">
                              <td  style="width:auto ;max-width:none">
                                <input type="checkbox"  name="deleteBlobs" class="form-check-inline"  id='enableDeleteBlobs'  (change)="saveDisable=true" formControlName="deleteBlobs" >
                                <label class="value" for="enableDeleteBlobs">Enable soft delete for blobs</label>
                                <div class="flex-custom">
                                  <label id="softBlobs" class="accordian-body collapse">Soft delete enables you to recover blobs that were previously marked for deletion, including
                                    blobs that were overwritten.</label>
                                </div>  
                              </td>
                            </tr>
                            <tr class="accordion-toggle cursor-pointer" data-toggle="collapse" [attr.data-target]="'#softContainer'">
                              <td  style="width:auto ;max-width:none">
                                <input type="checkbox"  name="deleteContainer" class="form-check-inline"  id='enableDeleteContainer'  (change)="saveDisable=true" formControlName="deleteContainer" >
                                <label class="value" for="enableDeleteContainer">Enable soft delete for containers</label>
                                <div class="flex-custom">
                                  <label id="softContainer" class="accordian-body collapse">Soft delete enables you to recover containers that were previously marked for deletion.</label>
                               </div>
                              </td>
                            </tr>
                        </tbody>
                  </table>
                </div>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap bold-600">Tracking</label>
                <div class="custom-fixed-tables mb-3">
                  <table class="table">
                        <thead>
                        </thead>
                        <tbody>
                            <tr class="accordion-toggle cursor-pointer" data-toggle="collapse" [attr.data-target]="'#enableVersion'">
                                <td  style="width:auto ;max-width:none">
                                  <input type="checkbox"  name="blobsVersion" class="form-check-inline"  id='enableBlobsVersion'  (change)="saveDisable=true" formControlName="blobsVersion" >
                                  <!-- [checked]="row.checked" -->
                                  <label class="value" for="enableBlobsVersion">Enable versioning for blobs</label>
                                  <div class="flex-custom">
                                    <label id="enableVersion" class="accordian-body collapse">Use versioning to automatically maintain previous versions of your blobs for recovery 
                                      and restoration.</label>
                                  </div>
                                </td>
                            </tr>
                            <tr class="accordion-toggle cursor-pointer" data-toggle="collapse" [attr.data-target]="'#enableBlob'">
                              <td  style="width:auto ;max-width:none">
                                <input type="checkbox"  name="blobChangeFeed" class="form-check-inline"  id='enableBlobChangeFeed'  (change)="saveDisable=true" formControlName="blobChangeFeed" >
                                <label class="value" for="enableBlobChangeFeed">Enable blob change feed</label>
                                
                                <div class="flex-custom">
                                  <label id="enableBlob" class="accordian-body collapse">Keep track of create, modification, and delete changes to blobs in your account.</label>
                                </div>
                                <ng-container *ngIf="currentForm.get('blobChangeFeed')?.value">
                                  <div class="mb-2">
                                    <div class="flex-custom">
                                      <div class="checkbox-cs">
                                        <input type="radio" name="changeFeedLogs"  class="form-check-inline" id='keepLogs' (change)="setRadioBtn('keep','dataProtection','changeFeedLogs')" [checked]="currentForm.get('changeFeedLogs')?.value =='keep'" >
                                        <label class="value" for="keepLogs">Keep all logs</label>
                                      </div>
                                    </div>
                                    <div>
                                    <div class="checkbox-cs">
                                        <input type="radio" name="changeFeedLogs" class="form-check-inline" id='deleteLogs' (change)="setRadioBtn('delete','dataProtection','changeFeedLogs')" [checked]="currentForm.get('changeFeedLogs')?.value == 'delete'">
                                        <label class="value" for="deleteLogs">Delete change feed logs after (in days)</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="mb-2" *ngIf="currentForm.get('changeFeedLogs')?.value == 'delete'">
                                  <input type="text"  name="changeFeedDays" class="form-check-inline"  id='changeFeedDays'  (change)="saveDisable=true" formControlName="changeFeedDays" >
                                </div>
                                </ng-container>
                              </td>
                            </tr>
                        </tbody>
                  </table>
                </div>
              </div>
             </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('dataProtection') || !saveDisable "(click)="saveStorageAccountData('dataProtection')">Save</button>
         </div>
      </div>
  </ng-container>



<ng-template #storageAccountDetailsTemplate>
  <div class="medium-popup-wrapper width-832" style="width: 832px;">
      <div class="modal-body scroll-y">
      <div class="d-flex mb-3">
          <div class="popup-title">Storage Account</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="tab-list">
        <div class="tab-item" (click)="openSidesheet('configuration',selectedData)">Configuration</div>
        <div class="tab-item" (click)="openSidesheet('networking',selectedData)">Networking</div>
        <div class="tab-item" (click)="openSidesheet('encryption',selectedData)">Encryption</div>
        <div class="tab-item" *ngIf="selectedData[5]!='FileStorage'" (click)="openSidesheet('dataProtection',selectedData)">Data Protection</div>
        <div class="tab-item" (click)="action(selectedData,'deleteStorageAccountData')">Delete</div>
      </div>
      <div class="row row1" >
          <div class="col-md-6" *ngFor="let data of selectedData;let i= index;let col of columns">
              <div class="list-item">
                  <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                  <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                    <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedData[i]:getTags(selectedData[i])}}</div>
                  </ng-container>
                  <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedData[i])}}</div>
                  <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedData[i] | titlecase}}</div>
                  <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedData[i])}}</div>
              </div>
          </div>
      </div>
      </div>
      <div class="submit-sec text-right pad-10-24">
      <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
      </div>
  </div>
</ng-template>

<ng-template #deleteConfirm>
  <div class="medium-popup-wrapper" style="width: 530px;">
    <div class="modal-body">
      <div class="d-flex mb-3">
        <div class="popup-title">{{getResourceTitle()}}</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="dlt-content">
        <p>{{getResourceName()}}</p>
      </div>
      <div class="submit-sec text-right">
        <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
        <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
      </div>
    </div>
  </div>
</ng-template>