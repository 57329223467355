
/* This file contains all the resource interfaces to be used while saving */
export class resourceObject {
  public static virtualMachine ={
    resourceName: null,
    resourceType: null,
    resourceData: {
      VmSize: null
    },
    comments: null,
    publicIPShow:false,
    newDiskShow:false,
    nsgShow:false
  }
  public static virtualNetwork ={
    resourceName: null,
    resourceType: null,
    resourceData: {
      virtualNetworkAddressSpace: null,
      resourceGroup:null
    },
    comments: null
  }
  public static subnet ={
    resourceName: null,
    resourceType: null,
    resourceData: {
      subnetAddressSpace: null
    },
    comments: null
  }

  public static azureADDomain ={
    resourceName: null,
    resourceType: null,
    resourceData: {
      subnetAddressSpace: null
    },
    comments: null
  }

  public static storageAccount ={
    resourceName: null,
    resourceType: null,
    resourceData: {
      performance: 'Standard',
      kind: 'Storage V2',
      redundancy: 'Locally-redundant storage(LRS)',
      Security : 'Enable blob public access',
      minimumTlsVersion :'Version 1.2',
      accessTier : 'Hot',
      subnetAddressSpace: null
    },
    comments: null
  }

  public static windowVirtualDesktop = {
    resourceName: null,
    resourceType: null,
    resourceData: {
      VmSize: null,
      numberOfVMs: null
    },
    comments: null
  }

  public static publicIPObj = {
    SKU:'Basic',
    assignment:'Dynamic',
    routingPreference:'microsoftNetwork'
  }

  public static newDiskObj = {
    sourceType:'None (empty disk)',
    encryptionType:'1',
    enableSharedDisk:'No'
  }

  public static vmNSGObj = {
  }

  public static networkInterfacemodel = {
    resourceName: null,
    resourceType: null,
    resourceData: {
    privateIPAddr: 'Dynamic'
    },
    comments: null
  }

  public static localNetworkGatewaymodel = {
    resourceName: null,
    resourceType: null,
    resourceData: {
    endpoint: 'IPaddress'
    },
    comments: null
  }

  public static loadBalancer = {
    resourceName: null,
    resourceType: null,
    resourceData: {
    type: 'public',
    sku: 'Standard',
    tier: 'Regional',
    IPaddressAssignment: 'Static',
    routingPreference: 'microsoftNetwork',
    publicIP: 'new',
    availabilityZone:'Zone-redundant'
    },
    comments: null
  }

  public static disk = {
    resourceName: null,
    resourceType: null,
    resourceData: {
    sourceType:'None',
    encryptionType:'(default) Encryption at-rest with a platform-managed key',
    esd:'No',
    availabilityZone:'None',
    //maxShares:'None'
    },
    comments: null
  }

  public static logAnalytics = {
    resourceName: null,
    resourceType: null,
    resourceData: {
    sku: 'pergb2018'
    },
    comments: null
  }

  public static applicationInsight = {
    resourceName: null,
    resourceType: null,
    resourceData: {
    resMode: 'classic'
    },
    comments: null
  }

  public static connections = {
    resourceName: null,
    resourceType: null,
    resourceData: {
      connectionType: 'Vnet2Vnet',
      IKEProtocol: 'IKEv2',
      ipsecPolicies: 'Default',
      usePolicyBasedTrafficSelectors: 'Disable',
      dpdTimeoutSeconds: '45',
      saDataSizeKilobytes:'102400000',
      saLifeTimeSeconds:'27000',
      connectionMode: 'Default'
    },
    comments: null
  }


  public static publicIP = {
    resourceName: null,
    resourceType: null,
    resourceData: {
    tier: 'Regional',
    sku: 'Standard',
    IPAddr:'Static',
    rp: 'microsoftNetwork',
    idleTimeout: '4',
    availabilityZone:'No Zone',
    },
    comments: null
  }

  public static lawObj = {
     name : null,
    pricingTier: 'pergb2018'
  }

  public static routeTable = {
    resourceName: null,
    resourceType: null,
    resourceData: {
      propagateGatewayRoutes: 'Yes'
    },
    comments: null
  }


  public static routesObj = {
    name : null,
    nextHopType: 'VirtualNetworkGateway'
 }

 public static subnetsObj = {
  name : null,
}

public static keyVault = {
  resourceName: null,
  resourceType: null,
  resourceData: {
    pricingTier: 'Standard',
    softDeleteRetentionInDays: '90',
    enablePurgeProtection: 'disablePurgeProtection',
    enableRbacAuthorization: 'vaultAccessPolicy'
  },
  comments: null
}

  public static nsg = {
    resourceName: null,
    resourceType: null,
    resourceData: {
    },
    comments: null
  }
  public static availabilitySet = {
    resourceName: null,
    resourceType: null,
    resourceData: {
      faultDomains:2,
      updateDomains:5,
      useManagedDisks:'Aligned'
    },
    comments: null
  }

  public static machineLearning = {
    resourceName: null,
    resourceType: null,
    resourceData: {
      identityType:'systemAssigned',
      accessType:'accessKey',
      encryptionType:'Microsoft',
    },
    comments: null
  }

  public static vmStorageAccount={
    name:null,
    kind:'Storage',
    performance:'Standard'
  }
  static synapseAnalytics={
    resourceName: null,
    resourceType: null,
    resourceData: {
      
    },
    comments: null
  }

}


// export interface virtualMachine{
//     resourceName: string,
//     resourceType: string,
//     comment: string,
//     resourceData: {
//       VmSize: string
//     }
// }

// export interface virtualNetwork{
//     resourceName?: string,
//     resourceType?: string,
//     comment: string,
//     resourceData?: {
//       virtualNetworkAddressSpace?: string
//     }
// }

// export interface subnet{
//     resourceName: string,
//     resourceType: string,
//     comment: string,
//     resourceData: {
//       subnetAddressSpace: string
//     }
// }

// export interface azureADDomain{
//   resourceName: string,
//   resourceType: string,
//   comment: string,
// }

// export interface storageAccount{
//   resourceName: string,
//   resourceType: string,
//   comment: string,
// }