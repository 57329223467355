<div class="introduction">
        <p class="heading" id="0">Alerts   </p>
        <p>This page enables the user to manage security alerts – view and modify existing
            alerts or to create new ones as needed.</p>
            <p class="mb-0" >
             Further, stay informed in real time via SMS text messages and/or email whenever an alert goes for the resources deployed in Azure across various projects. The alert
            indicates a potential error or issue, and relevant information will be shared in the message sent.  
             </p>
             <img src="assets\user-manual\security\Alert-Management.svg" >
             <figcaption class="caption">Alert management dashboard </figcaption>
                <ul class="mb-0" >
                <li>Click an alert in the table to view the details.  </li>
                </ul>
                <img src="assets\user-manual\security\Alert-Management-1.svg" >
                <figcaption class="caption">Alert details</figcaption>
                <img src="assets\user-manual\security\Alert-Management-2.svg" >
                <figcaption class="caption">Alert detail - Act</figcaption>
</div>