<div class="introduction">
   
            <p class="heading" id="0">Invite a User  </p>
            <ul class="mb-0">
                <li>Click <b>Add user</b>.</li>
                <li>Select <b> Invite user.</b> </li>
                <li>Provide the needed inputs in the side sheet.  </li>
                <li>Click <b>Invite.</b> </li>

            </ul>
            <img style="width: 50%;height: 50%;" src="assets\user-manual\governance\Side-sheet(3).svg" >
      
            <figcaption class="caption col-md-6" style="padding-left: 150px;">Invite user side sheet</figcaption>
           
             
</div>
