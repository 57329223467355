import { Component, OnInit } from '@angular/core';
import { LoginMsalService } from "../../services/login-msal.service";
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'app-login-msal',
    templateUrl: './login-msal.component.html',
    styleUrls: ['./login-msal.component.scss']
})
export class LoginMsalComponent implements OnInit {
    logincredentials = this.fb.group({
        UserName: ['', [Validators.required]],
    })
    errMsg: string;

    constructor(private loginmsal: LoginMsalService, private util:UtilService,
private fb: FormBuilder, public router: Router, private loginService: LoginService) { }

    submitLoader = false;
    invalidErr = false;
    redirectMessage = false;

    public adminLogin = false;
    loginInfo = false;
    registerInfo = false;
    public url = environment.a3sUrl;
    showLogIn = true;

    ngOnInit(): void {
        this.redirectMessage = window.location.href != this.url ? true : false;
        // if(this.redirectMessage)  this.util.showLoader();
        // else this.util.hideLoader();

        var search = window.location.hash
        if(search) {
            console.log(search);
            this.showLogIn = false;
            // this.util.showLoader();
            // this.router.navigate(['/a3s']);
        }
    }

    ngDoCheck() {
        var data = { token: '' };
        for (let i = 0; sessionStorage.key(i); i++) {
            if (sessionStorage.key(i).indexOf('login.windows.net-accesstoken') != -1) {
                data['token'] = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['secret'];
                sessionStorage.setItem('data', JSON.stringify(data));
                this.redirectMessage = false;
                sessionStorage.removeItem("redirectToken")
                this.router.navigate(['/a3s'])
                // this.util.hideLoader();
            }
        }
    }


    loginSubmit() {
        localStorage.clear();
        sessionStorage.clear();
        this.submitLoader = true;
        var payload = {
            id: this.logincredentials.value.UserName
        }
        localStorage.setItem('id', this.logincredentials.value.UserName);
        this.loginService.getData(payload).subscribe(res => {
                if(res.isOnboarded){
                    if (res) {
                        this.loginmsal.clientId = res.ClientID;
                        this.loginmsal.tenantId = res.TenantID;
                        localStorage.setItem('cred', JSON.stringify(res));
                    }
                    if (this.loginmsal.clientId != null && this.loginmsal.tenantId != null) {
    
                        this.loginmsal.MSAL_CONFIG.auth.clientId = JSON.parse(localStorage.getItem('cred')).ClientID;
                        this.loginmsal.MSAL_CONFIG.auth.authority = "https://login.microsoftonline.com/" + JSON.parse(localStorage.getItem('cred')).TenantID;
                        if (this.loginmsal.MSAL_CONFIG.auth.clientId != '' && this.loginmsal.MSAL_CONFIG.auth.authority != '') {
                            this.loginmsal.login('loginRedirect');
                        }
                    }
                }
                else{
                    this.util.route(['/onboarding'],false);
                }
            },err => {
                this.submitLoader = false;
                this.invalidErr = true;
                if([400,404].indexOf(err.status) > -1){
                    this.errMsg = 'Invalid email'
                }
                else this.errMsg = 'Server down. please try again'
                console.log(err)
            }
        )

    }

    routeToRegister() {
        window.location.href = "https://azureautomation.ai/contact-us?contact=contactUs";
    }

}
