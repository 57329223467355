import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-policy-governance',
  templateUrl: './enterprise-policy-governance.component.html',
  styleUrls: ['./enterprise-policy-governance.component.scss']
})
export class EnterprisePolicyGovernanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
