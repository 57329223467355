<div class="custom-fixed-tables">
    <table class="table">
        <thead>
          <tr>
            <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appGatewayData',0)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appGatewayData',1)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appGatewayData',2)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appGatewayData',3)"></div></th>
            <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appGatewayData',4)"></div></th>
            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('appGatewayData',5)"></div></th>
            <th style="border-left: none;" class="wid-75"></th>
        </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data">
            <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(appGatewayDetailsTemplate,row,'appGatewayPopup')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive>{{ row[4] | titlecase | displayTxt}}</td>
              <td isEllipsisActive>{{ getTags(row[7]) }}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                      <p class="delete-popup-text">Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('webAppFirewall',row)">
                      <p class="delete-popup-text">Web application firewall</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('backendPool',row)">
                        <p class="delete-popup-text">Backend pools</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('backendSetting',row)">
                        <p class="delete-popup-text">Backend settings</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('frontendIP',row)">
                        <p class="delete-popup-text">Frontend IP Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('listeners',row)">
                        <p class="delete-popup-text">Listeners</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('rules',row)">
                        <p class="delete-popup-text">Rules</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('healthProbes',row)">
                        <p class="delete-popup-text">Health probes</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteAppGatewayData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>

  <ng-container *ngIf="showSidesheet">
    <!-- configuration -->
    <ng-container *ngIf="currentTemplate == 'configuration'">
     <div class="sidebar-right single add-alert" >
         <div class="header">
             <div class="d-flex">
               <h4 class="threeFour-width ">Configuration<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
               <div class="text-right half-width">
                 <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                   <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                 </button>
               </div>
             </div>
         </div>
         <div class="body scroll-y">
          <form [formGroup]="currentForm">
           <div class="mb-3">
               <label class="flex-custom no-wrap">Tier<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Standard_v2 and WAF_v2 tiers support availability zones. Changing
                from the WAF_v2 tier to the Standard_v2 tier is not supported." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
               <ng-select [items]="tierList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="tier" [clearable]="false" id="tier" formControlName="tier"></ng-select>
           </div>
           <div class="mb-3">
            <label class="flex-custom no-wrap">Capacity type</label>
            <div class="flex-custom">
                <div class="checkbox-cs">
                  <input type="radio" name="capacityType"  class="form-check-inline" id='autoscale' (change)="setRadioBtn('autoscale','configuration','capacityType')" [checked]="currentForm.get('capacityType')?.value =='autoscale'">
                  <label class="value" for="autoscale">Autoscale</label>
              </div>
              <div class="checkbox-cs">
                  <input type="radio" name="capacityType" class="form-check-inline" id='manual' (change)="setRadioBtn('manual','configuration','capacityType')" [checked]="currentForm.get('capacityType')?.value == 'manual'">
                  <label class="value" for="manual">Manual</label>
              </div>
            </div>
           </div>
           <div class="mb-3" *ngIf="currentForm.get('capacityType')?.value=='manual'">
            <label class="flex-custom no-wrap">Instance count<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify the instance count of the Application Gateway. A minimum 
              instance count of 2 is recommended for production workloads." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.instanceNameEmpty|| inputError?.instanceInvalidNumber ||inputError?.instanceInvalidRange" id="instanceCount" name="instanceCount" formControlName="instanceCount" (keyup)="validInstance($event,'instance')"/>
            <p class="text-danger" *ngIf="inputError?.instanceNameEmpty">The value must not be empty.</p>
            <p class="text-danger" *ngIf="inputError?.instanceInvalidNumber">The value must be a valid number.</p>
            <p class="text-danger" *ngIf="inputError?.instanceInvalidRange">Capacity for {{currentForm.get('tier')?.value}} Manual should be between 1 and 125.</p>
           </div>
           <div class="mb-3" *ngIf="currentForm.get('capacityType')?.value!='manual'">
            <label class="flex-custom no-wrap">Minimum instance count<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify the minimum instance count of the Application Gateway. 
              High availability is ensured, even if set to zero." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.minInstanceNameEmpty|| inputError?.minInstanceInvalidNumber ||inputError?.InvalidMaxRange || inputError?.minInstanceInvalidRange" id="minInstance" name="minInstance" formControlName="minInstance" (keyup)="validInstance($event,'minInstance')"/>
            <p class="text-danger" *ngIf="inputError?.minInstanceNameEmpty">The value must not be empty.</p>
            <p class="text-danger" *ngIf="inputError?.minInstanceInvalidNumber">The value must be a valid number.</p>
            <p class="text-danger" *ngIf="inputError?.InvalidMaxRange">The minimum instance count should be smaller than the maximum instance count.</p>
            <p class="text-danger" *ngIf="inputError?.minInstanceInvalidRange">The value must be between 0 and 125.</p>
           </div>
           <div class="mb-3" *ngIf="currentForm.get('capacityType')?.value!='manual'">
            <label class="flex-custom no-wrap">Maximum instance count<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify the maximum instance count of the Application Gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.maxInstanceNameEmpty|| inputError?.maxInstanceInvalidNumber ||inputError?.maxInstanceInvalidRange" id="maxInstance" name="maxInstance" formControlName="maxInstance" (keyup)="validInstance($event,'maxInstance')"/>
            <p class="text-danger" *ngIf="inputError?.maxInstanceNameEmpty">The value must not be empty.</p>
            <p class="text-danger" *ngIf="inputError?.maxInstanceInvalidNumber">The value must be a valid number.</p>
            <p class="text-danger" *ngIf="inputError?.maxInstanceInvalidRange">The value must be between 0 and 125.</p>
           </div>
           <div class="mb-3">
            <label class="flex-custom no-wrap">HTTP2<span class="mandatory">*</span></label>
            <div class="flex-custom">
                <div class="checkbox-cs">
                  <input type="radio" name="http2"  class="form-check-inline" id='Disable' (change)="setRadioBtn('no','configuration','http2')" [checked]="currentForm.get('http2')?.value ==false">
                  <label class="value" for="Disable">Disabled</label>
              </div>
              <div class="checkbox-cs">
                  <input type="radio" name="http2" class="form-check-inline" id='Enable' (change)="setRadioBtn('yes','configuration','http2')" [checked]="currentForm.get('http2')?.value == true">
                  <label class="value" for="Enable">Enabled</label>
              </div>
            </div>
          </div>
          </form>
         </div>
         <div class="fixed-btm-btn flex-custom no-wrap">
           <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('config') || !saveDisable "(click)="saveAppGatewayData('config')">Save</button>
        </div>
     </div>
    </ng-container>
    <!-- web App Firewall -->
    <ng-container *ngIf="currentTemplate == 'webAppFirewall'">
      <div class="sidebar-right single" [class.width-652]="currentForm.get('wafStatus')?.value" [class.add-alert]="!currentForm.get('wafStatus')?.value">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Web Application Firewall<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
           <form [formGroup]="currentForm">
            <div class="mb-3">
                <label class="flex-custom no-wrap">Tier<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Standard_v2 and WAF_v2 tiers support availability zones. Changing 
                  from the WAF_v2 tier to the Standard_v2 tier is not supported." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="tierList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="tier" [clearable]="false" id="tier" formControlName="tier"></ng-select>
            </div>
            <ng-container *ngIf="currentForm.get('tier')?.value=='WAF_v2'">
              <div class="mb-3">
                <label class="flex-custom no-wrap">WAF status<span class="mandatory">*</span></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="wafStatus"  class="form-check-inline" id='statusDisable' (change)="setRadioBtn('no','webAppFirewall','wafStatus')" [checked]="currentForm.get('wafStatus')?.value ==false">
                      <label class="value" for="statusDisable">Disabled</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="wafStatus" class="form-check-inline" id='statusEnable' (change)="setRadioBtn('yes','webAppFirewall','wafStatus')" [checked]="currentForm.get('wafStatus')?.value == true">
                      <label class="value" for="statusEnable">Enabled</label>
                  </div>
                </div>
               </div>
               <ng-container *ngIf="currentForm.get('wafStatus')?.value">
                <div class="mb-3">
                  <label class="flex-custom no-wrap">WAF mode<span class="mandatory">*</span></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="wafMode"  class="form-check-inline" id='detection' (change)="setRadioBtn('Detection','webAppFirewall','wafMode')" [checked]="currentForm.get('wafMode')?.value =='Detection'">
                        <label class="value" for="detection">Detection</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="wafMode" class="form-check-inline" id='prevention' (change)="setRadioBtn('Prevention','webAppFirewall','wafMode')" [checked]="currentForm.get('wafMode')?.value == 'Prevention'">
                        <label class="value" for="prevention">Prevention</label>
                    </div>
                  </div>
                 </div>
                 <div class="mb-3">
                  <label class="flex-custom no-wrap bold-600">Exclusions</label>
                  <label class="flex-custom no-wrap">Application gateway will evaluate everything in the request except for the items included in this list.</label>
                </div> 
                <div class="mb-3">
                  <ng-container formArrayName="exclusiveArray" *ngFor="let tanch of currentForm.get('exclusiveArray')['controls']; let i = index;">
                    <div class="mb-1">
                      <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                        <div class="mr-2" style="width:33.3% ;">
                          <label class="flex-custom nowrap">Field</label>
                          <ng-select [items]="fieldList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="field" [clearable]="false" id="field" formControlName="field"></ng-select>
                        </div>
                        <div class="mr-2" style="width:33.3% ;">
                          <label class="flex-custom nowrap">Operator</label>
                          <ng-select [items]="operatorList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="operator" [clearable]="false" id="operator" formControlName="operator"></ng-select>
                        </div>
                        <div class="">
                          <label class="flex-custom nowrap">Selector</label>
                          <input class="form-control full-width" type="text" formControlName="selector"/>
                        </div>
                        <ng-container class="col-md-1 pr-0" *ngIf="exclusiveArrayList?.length > 1">
                          <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeExclusiveList(i)">
                        </ng-container>
                      </div>
                      <span class="action-btn" (click)="addExclusiveArray()" *ngIf="i == exclusiveArrayList?.length-1">Add new</span>
                    </div>
                  </ng-container>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap bold-600">Global Parameters</label>
                  <label class="flex-custom no-wrap">Inspect request body</label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="inspectReqBody"  class="form-check-inline" id='reqBodyON' (change)="setRadioBtn('yes','webAppFirewall','inspectReqBody')" [checked]="currentForm.get('inspectReqBody')?.value ==true">
                        <label class="value" for="reqBodyON">On</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="inspectReqBody" class="form-check-inline" id='reqBodyOFF' (change)="setRadioBtn('no','webAppFirewall','inspectReqBody')" [checked]="currentForm.get('inspectReqBody')?.value == false">
                        <label class="value" for="reqBodyOFF">Off</label>
                    </div>
                  </div>
                </div> 
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Max request body size (KB)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Inspect request body can be any value between 8 and 128 KB." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control full-width" [class.disabled-input]="!currentForm.get('inspectReqBody')?.value"  type="text" autocomplete="off" [class.border-danger]="inputError?.maxReqSizeNameEmpty|| inputError?.maxReqSizeInvalidNumber ||inputError?.maxReqSizeInvalidRange" id="maxReqSize" name="maxReqSize" formControlName="maxReqSize" (keyup)="validInstance($event,'maxReqSize')"/>
                  <p class="text-danger" *ngIf="inputError?.maxReqSizeNameEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.maxReqSizeInvalidNumber">The value must be a valid number.</p>
                  <p class="text-danger" *ngIf="inputError?.maxReqSizeInvalidRange">The value must be between 8 and 128.</p>
                 </div>
                 <div class="mb-3">
                  <label class="flex-custom no-wrap">File upload limit (MB)</label>
                  <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.fileLimitNameEmpty|| inputError?.fileLimitInvalidNumber ||inputError?.fileLimitInvalidRange" id="fileLimit" name="fileLimit" formControlName="fileLimit" (keyup)="validInstance($event,'fileLimit')"/>
                  <p class="text-danger" *ngIf="inputError?.fileLimitNameEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.fileLimitInvalidNumber">The value must be a valid number.</p>
                  <p class="text-danger" *ngIf="inputError?.fileLimitInvalidRange">The value must be between 0 and 100, inclusive.</p>
                 </div>
               </ng-container>
            </ng-container>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('webAppFirewall') || !saveDisable "(click)="saveAppGatewayData('webAppFirewall')">Save</button>
         </div>
      </div>
     </ng-container>
    <!-- Backend Pool -->
    <ng-container *ngIf="currentTemplate == 'backendPool'">
      <div class="sidebar-right single width-652" [class.sub-sheet-400]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Backend Pools<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addBackendPool')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add </button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Rules Associated</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Targets</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of applicationGatewayData?.properties?.backendAddressPools;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.requestRoutingRules ? row.properties.requestRoutingRules.length:'0'}}</td>
                            <td isEllipsisActive>{{row.properties.backendAddresses ? row.properties.backendAddresses.length:'0'}}</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addBackendPool',row)">
                                    <p class="delete-popup-text">Edit</p>
                                  </div>
                                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteBackendPool')">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                           </td>
                          </tr>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert width-400">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">{{isEdit ? 'Edit Backend Pool':'Add Backend Pool'}}<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
          
              <div class="body scroll-y">
                  <form [formGroup]="currentForm">
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">A backend pool is a collection of resources to which your application gateway can send traffic. A backend pool can contain virtual machines, virtual machines scale sets, IP addresses, domain names, or an App Service.</label>
                    </div>
                      <div class="mb-3">
                          <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="poolName" name="poolName" formControlName="poolName" (keyup)="validIPName($event,'frontend','backendPool')"/>
                          <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameExist">Backend pool name must be unique.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                              and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Add backend pool without targets</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="poolTarget"  class="form-check-inline" id='targetYes' (change)="setRadioBtn('yes','backendPool','poolTarget')" [checked]="currentForm.get('poolTarget')?.value ==true">
                              <label class="value" for="targetYes">Yes</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="poolTarget" class="form-check-inline" id='targetNo' (change)="setRadioBtn('no','backendPool','poolTarget')" [checked]="currentForm.get('poolTarget')?.value == false">
                              <label class="value" for="targetNo">No</label>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="!currentForm.get('poolTarget')?.value">
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Backend targets</label>
                          <label class="flex-custom no-wrap bold-600">{{backendTargetArrayList.length-1}} items</label>
                        </div>
                        <div class="mb-3">
                          <ng-container formArrayName="backendTargetArray" *ngFor="let tanch of currentForm.get('backendTargetArray')['controls']; let i = index;">
                            <div class="mb-1">
                              <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                                <div class="mr-2 w-50">
                                  <label class="flex-custom nowrap">Target type</label>
                                  <ng-select [items]="targetTypeList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="targetType" [clearable]="false" id="targetType" formControlName="targetType"></ng-select>
                                </div>
                                <div class="w-50">
                                  <label class="flex-custom nowrap">Target</label>
                                  <input class="form-control full-width" type="text" formControlName="target"/>
                                </div>
                                <ng-container class="col-md-1 pr-0" *ngIf="backendTargetArrayList?.length > 1">
                                  <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeBackendTargetList(i)">
                                </ng-container>
                              </div>
                              <span class="action-btn" (click)="addBackendTargetList()" *ngIf="i == backendTargetArrayList?.length-1">Add new</span>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                  </form>
              </div>
      
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('backendPool') || currentForm.invalid" (click)="saveAppGatewayData('backendPool')">{{isEdit ? 'Save':'Add'}}</button>
              </div>
          </div>
          </ng-container>
      </ng-container>

    <!-- Backend Settings -->
    <ng-container *ngIf="currentTemplate == 'backendSetting'">
      <div class="sidebar-right single width-652" [class.sub-sheet-400]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Backend Settings<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addBackendSetting')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add </button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Port</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Protocol</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Cookie based affinity</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Custom probe</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of applicationGatewayData?.properties?.backendHttpSettingsCollection;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.port}}</td>
                            <td isEllipsisActive>{{row.properties.protocol}}</td>
                            <td isEllipsisActive>{{row.properties.cookieBasedAffinity}}</td>
                            <td isEllipsisActive>{{row.properties.probe ? row.properties.probe.id.split('/')[10] : '--'}}</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addBackendSetting',row)">
                                    <p class="delete-popup-text">Edit</p>
                                  </div>
                                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteBackendSetting')">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                           </td>
                          </tr>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert width-400">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">{{isEdit ? 'Edit Backend Setting':'Add Backend Setting'}}<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
          
              <div class="body scroll-y">
                  <form [formGroup]="currentForm">
                      <div class="mb-3">
                          <label class="flex-custom no-wrap">Backend settings name<span class="mandatory">*</span></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="settingName" name="settingName" formControlName="settingName" (keyup)="validIPName($event,'frontend','backendSetting')"/>
                          <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameExist">Backend setting name must be unique</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                              and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Backend protocol</label>
                        <label class="flex-custom no-wrap bold-600">HTTP</label>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Backend port<span class="mandatory">*</span></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.portNameEmpty|| inputError?.portInvalidNumber|| inputError?.portInvalidRange" id="backendPort" name="backendPort" formControlName="backendPort" (keyup)="validInstance($event,'port')"/>
                        <p class="text-danger" *ngIf="inputError?.portNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidNumber">The value must be a number.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidRange">The value must be between 1 and 65535.</p>
                    
                     </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Additional Settings</label>
                      <label class="flex-custom no-wrap">Cookie-based affinity<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The application gateway can use cookies to keep a user session on the same server.
                        You can enable this feature if the client supports the use of cookies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="cookieAffinity"  class="form-check-inline" id='cookieAffinityYes' (change)="setRadioBtn('yes','backendSetting','cookieAffinity')" [checked]="currentForm.get('cookieAffinity')?.value ==true">
                            <label class="value" for="cookieAffinityYes">Enable</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="cookieAffinity" class="form-check-inline" id='cookieAffinityNo' (change)="setRadioBtn('no','backendSetting','cookieAffinity')" [checked]="currentForm.get('cookieAffinity')?.value == false">
                            <label class="value" for="cookieAffinityNo">Disable</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('cookieAffinity')?.value">
                      <label class="flex-custom no-wrap">Affinity cookie name</label>
                      <input class="form-control full-width" type="text" autocomplete="off" id="affinityName" name="affinityName" formControlName="affinityName"/>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Connection draining<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Connection draining helps gracefully remove backend pool members during planned service 
                        updates. When this feature is enabled, de-registering instances of a backend pool won’t receive 
                        new requests. This applies to backend instances that are explicitly removed from the backend 
                        pool via an API call as well as instances reported unhealthy by health probes." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="connectionDraining"  class="form-check-inline" id='connDrainYes' (change)="setRadioBtn('yes','backendSetting','connectionDraining')" [checked]="currentForm.get('connectionDraining')?.value ==true">
                            <label class="value" for="connDrainYes">Enable</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="connectionDraining" class="form-check-inline" id='connDrainNo' (change)="setRadioBtn('no','backendSetting','connectionDraining')" [checked]="currentForm.get('connectionDraining')?.value == false">
                            <label class="value" for="connDrainNo">Disable</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('connectionDraining')?.value">
                      <label class="flex-custom no-wrap">Drain timeout (seconds)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow existing requests to complete within this timeout." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.drainTimeOutNameEmpty|| inputError?.drainTimeOutInvalidNumber|| inputError?.drainTimeOutInvalidRange" id="drainTimeOut" name="drainTimeOut" formControlName="drainTimeOut" (keyup)="validInstance($event,'drainTimeOut')"/>
                      <p class="text-danger" *ngIf="inputError?.drainTimeOutNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.drainTimeOutInvalidNumber">The value must be a number.</p>
                      <p class="text-danger" *ngIf="inputError?.drainTimeOutInvalidRange">The value must be between 1 and 3600.</p>
                   </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Request time-out (seconds)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The request timeout is the number of seconds that the application gateway will wait to receive 
                        a response from the backend pool before it returns a “connection timed out” error message." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.reqTimeOutNameEmpty|| inputError?.reqTimeOutInvalidNumber|| inputError?.reqTimeOutInvalidRange" id="reqTimeOut" name="reqTimeOut" formControlName="reqTimeOut" (keyup)="validInstance($event,'reqTimeOut')"/>
                      <p class="text-danger" *ngIf="inputError?.reqTimeOutNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.reqTimeOutInvalidNumber">The value must be a number.</p>
                      <p class="text-danger" *ngIf="inputError?.reqTimeOutInvalidRange">The value must be between 1 and 86400.</p>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Override backend path<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This feature allows you to override the path in the URL so that the requests for a specific path 
                      can be routed to another path. For example, if you intend to route requests for contoso.com/images 
                      to default, then enter ‘/’ in this textbox and subsequently attach this HTTP setting to the rule 
                      associated with contoso.com/images." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="backendPath" name="backendPath" [class.border-danger]="inputError?.invalidPath" formControlName="backendPath" (keyup)="validPath($event)"/>
                    <p class="text-danger" *ngIf="inputError?.invalidPath">Backend path must start with /</p>
                  </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Host Name</label>
                      <label class="flex-custom no-wrap">Override with new host name</label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="newHost"  class="form-check-inline" id='newHostYes' (change)="setRadioBtn('yes','backendSetting','newHost')" [checked]="currentForm.get('newHost')?.value ==true">
                            <label class="value" for="newHostYes">Yes</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="newHost" class="form-check-inline" id='newHostNo' (change)="setRadioBtn('no','backendSetting','newHost')" [checked]="currentForm.get('newHost')?.value == false">
                            <label class="value" for="newHostNo">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Host name override</label>
                        <div class="flex-custom">
                          <div class="checkbox-cs" [class.disabled-icon]="!currentForm.get('newHost')?.value">
                            <input type="radio" name="hostOverRide"  class="form-check-inline" id='hostOverRideYes' (change)="setRadioBtn('yes','backendSetting','hostOverRide')" [checked]="currentForm.get('hostOverRide')?.value ==true">
                            <label class="value" for="hostOverRideYes">Pick host name from backend target</label>
                          </div>
                        </div>
                        <div class="flex-custom">
                          <div class="checkbox-cs" [class.disabled-icon]="!currentForm.get('newHost')?.value">
                            <input type="radio" name="hostOverRide" class="form-check-inline" id='hostOverRideNo' (change)="setRadioBtn('no','backendSetting','hostOverRide')" [checked]="currentForm.get('hostOverRide')?.value == false">
                            <label class="value" for="hostOverRideNo">Override with specific domain name</label>
                          </div>
                        </div>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Host name</label>
                      <input class="form-control full-width" type="text" [class.disabled-input]="!currentForm.get('newHost')?.value || currentForm.get('hostOverRide')?.value" autocomplete="off" placeholder="e.g. contoso.com" [class.border-danger]="inputError?.instanceNameEmpty" id="hostName" name="hostName" formControlName="hostName" (keyup)="validInstance($event,'instance')"/>
                      <p class="text-danger" *ngIf="inputError?.instanceNameEmpty">The value must not be empty.</p>
                   </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Use custom probe<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="To use a custom probe, you must first create a health probe." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="useProbe"  class="form-check-inline" id='useCPYes' (change)="setRadioBtn('yes','backendSetting','useProbe')" [checked]="currentForm.get('useProbe')?.value ==true">
                            <label class="value" for="useCPYes">Yes</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="useProbe" class="form-check-inline" id='useCPNo' (change)="setRadioBtn('no','backendSetting','useProbe')" [checked]="currentForm.get('useProbe')?.value == false">
                            <label class="value" for="useCPNo">No</label>
                        </div>
                      </div>
                      <p class="text-danger" *ngIf="!probeList.length && currentForm.get('useProbe')?.value">There are no HTTP probes available. Create a new probe and then try again.</p>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('useProbe')?.value">
                      <label class="flex-custom no-wrap">Custom probe<span class="mandatory">*</span></label>
                      <ng-select [items]="probeList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="probe" [clearable]="false" id="probe" formControlName="probe"></ng-select>
                   </div>
                  </form>
              </div>
      
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('backendSetting') || currentForm.invalid" (click)="saveAppGatewayData('backendSetting')">{{isEdit ? 'Save':'Add'}}</button>
              </div>
          </div>
          </ng-container>
    </ng-container>

    <!-- Frontend IP Configuration -->
    <ng-container *ngIf="currentTemplate == 'frontendIP'">
      <div class="sidebar-right single width-881">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Frontend IP Configurations<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Type</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Status</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>IP address</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Associated Listeners</span></div></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of applicationGatewayData?.properties?.frontendIPConfigurations;let i=index">
                            <td isEllipsisActive>{{row?.properties?.publicIPAddress ? 'Public' : 'Private'}}</td>
                            <td isEllipsisActive>{{row?.properties?.publicIPAddress?.properties?.ipConfiguration ? 'Configured' : 'Not configured'}}</td>
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{getPublicIPAddress(row?.properties?.publicIPAddress)}}</td>
                            <td isEllipsisActive>{{getAssociatedListener(row?.name)}}</td>
                          </tr>
                          <tr>
                            <td isEllipsisActive>Private</td>
                            <td isEllipsisActive>Not configured</td>
                            <td isEllipsisActive>--</td>
                            <td isEllipsisActive>--</td>
                            <td isEllipsisActive>--</td>
                          </tr>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
    </ng-container>
    <!-- Listeners -->
    <ng-container *ngIf="currentTemplate == 'listeners'">
    <div class="sidebar-right single width-652" [class.sub-sheet-400]="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
              <h4 class="threeFour-width ">Listeners<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
              <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body scroll-y">
            <div class="comm-mb">
                <button class="btn-btn-secondary" (click)="openSubSideSheet('addListeners')">
                <img src="/assets/governance/add-btn.svg" alt="">Add </button>
            </div>
          <div class="custom-fixed-tables mb-3">
                <table class="table">
                    <thead>
                        <tr>
                          <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                          <th><div class="flex-custom no-wrap"><span>Protocol</span></div></th>
                          <th><div class="flex-custom no-wrap"><span>Port</span></div></th>
                          <th><div class="flex-custom no-wrap"><span>Associated rule</span></div></th>
                          <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Host name</span></div></th>
                          <th style="border-left: none;" class="wid-75"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of applicationGatewayData?.properties?.httpListeners;let i=index">
                          <td isEllipsisActive>{{row?.name}}</td>
                          <td isEllipsisActive>{{row.properties.protocol}}</td>
                          <td isEllipsisActive>{{row?.properties?.frontendPort ? getPortValue(row?.properties?.frontendPort) : '--'}}</td>
                          <td isEllipsisActive>{{row.properties.requestRoutingRules && row.properties.requestRoutingRules.length ? row.properties.requestRoutingRules[0].id.split('/')[10] : '--'}}</td>
                          <td isEllipsisActive>{{getHostName(row)}}</td>
                          <td class="text-right wid-75">
                            <span>
                              <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                              <mat-menu #action2="matMenu" class="delete-popup" >
                                <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addListeners',row)">
                                  <p class="delete-popup-text">Edit</p>
                                </div>
                                <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteListeners')">
                                    <p class="delete-popup-text">Delete</p>
                                </div>
                              </mat-menu>
                            </span>              
                          </td>
                        </tr>
                      </tbody>
                </table>
          </div>
          <div class="mb-3">
            <label class="flex-custom no-wrap bold-600">SSL Policy<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The SSL policy defines the SSL protocol version and available ciphers. Choose from one 
              of the predefined policies or create a custom security policy to match your organizational 
              security requirements. These policies apply to all HTTPS listeners unless they are overridden 
              by listener specific SSL Policy under SSL settings." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
          </div>
          <div class="mb-3">
            <label class="flex-custom no-wrap">Selected SSL Policy</label>
            <label class="flex-custom no-wrap bold-600">Default</label>
          </div>
          <div class="mb-3">
            <label class="flex-custom no-wrap">Min protocol version</label>
            <label class="flex-custom no-wrap bold-600">TLSv1_0</label>
          </div>
          <div class="mb-3">
            <label class="flex-custom no-wrap">Cipher suites</label>
            <label class="flex-custom no-wrap bold-600 w-50">TLS_ECDHE_RSA_WITH_AES_256_GCM_SHA384
              TLS_ECDHE_RSA_WITH_AES_128_GCM_SHA256
              TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA384
              TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA256
              TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA
              TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA
              TLS_DHE_RSA_WITH_AES_256_GCM_SHA384
              TLS_DHE_RSA_WITH_AES_128_GCM_SHA256
              TLS_DHE_RSA_WITH_AES_256_CBC_SHA
              TLS_DHE_RSA_WITH_AES_128_CBC_SHA
              TLS_RSA_WITH_AES_256_GCM_SHA384
              TLS_RSA_WITH_AES_128_GCM_SHA256
              TLS_RSA_WITH_AES_256_CBC_SHA256
              TLS_RSA_WITH_AES_128_CBC_SHA256
              TLS_RSA_WITH_AES_256_CBC_SHA
              TLS_RSA_WITH_AES_128_CBC_SHA
              TLS_ECDHE_ECDSA_WITH_AES_256_GCM_SHA384
              TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256
              TLS_ECDHE_ECDSA_WITH_AES_256_CBC_SHA384
              TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA256
              TLS_ECDHE_ECDSA_WITH_AES_256_CBC_SHA
              TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA
              TLS_DHE_DSS_WITH_AES_256_CBC_SHA256
              TLS_DHE_DSS_WITH_AES_128_CBC_SHA256
              TLS_DHE_DSS_WITH_AES_256_CBC_SHA
              TLS_DHE_DSS_WITH_AES_128_CBC_SHA
              TLS_RSA_WITH_3DES_EDE_CBC_SHA
              TLS_DHE_DSS_WITH_3DES_EDE_CBC_SHA</label>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showSubSidesheet">
        <div class="sidebar-right single add-alert width-400">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">{{isEdit ? 'Edit Listener':'Add Listener'}}<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
        
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="mb-3">
                        <label class="flex-custom no-wrap">Listener Name<span class="mandatory">*</span> <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use a descriptive name for your listener; you won’t be able to edit the name once the application gateway has been created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="listenerName" name="listenerName" formControlName="listenerName" (keyup)="validIPName($event,'frontend','listener')"/>
                        <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameExist">Listener name must be unique</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                            and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Frontend IP<span class="mandatory">*</span> <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the frontend IP address that you plan to associate with this listener. 
                        The listener will listen to incoming requests on this IP." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="frontendIPList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="frontendIP" [clearable]="false" id="frontendIP" formControlName="frontendIP"></ng-select>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Protocol</label>
                      <label class="flex-custom no-wrap bold-600">HTTP</label>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Port<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enter the port for the listener. You can use well known ports, such as port 80 and 443, 
                        or any port ranging from 1 to 65502 (v1 SKU) or 1 to 65199 (v2 SKU)." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.portNameEmpty|| inputError?.portInvalidNumber|| inputError?.portInvalidRange||inputError?.portInvalid" id="port" name="port" formControlName="port" (keyup)="validInstance($event,'port')"/>
                      <p class="text-danger" *ngIf="inputError?.portNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.portInvalidNumber">The value must be a number.</p>
                      <p class="text-danger" *ngIf="inputError?.portInvalidRange">The value must be between 1 and 65535.</p>
                      <p class="text-danger" *ngIf="inputError?.portInvalid">This listener cannot use the same frontend port as an existing listener.</p>
                    </div>
                    <div class="mb-3" *ngIf="isEdit">
                      <label class="flex-custom no-wrap">Associated rule</label>
                      <label class="flex-custom no-wrap blue-text" (click)="openSidesheet('rules',selectedRow,'fromRules');showSubSidesheet=false">{{currentForm.get('ruleName')?.value}}</label>
                    </div>
                  <div class="mb-3">
                    <label class="flex-custom no-wrap bold-600">Additional Settings</label>
                    <label class="flex-custom no-wrap">Listener type<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If you’re hosting a single site behind this application gateway, choose a basic listener.
                      If you’re configuring more than one web application or multiple subdomains of the same 
                      parent domain, choose a multiple-site listener." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="listenerType"  class="form-check-inline" id='basicType' (change)="setRadioBtn('Basic','listener','listenerType')" [checked]="currentForm.get('listenerType')?.value =='Basic'">
                          <label class="value" for="basicType">Basic</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="listenerType" class="form-check-inline" id='MultiType' (change)="setRadioBtn('Multi-site','listener','listenerType')" [checked]="currentForm.get('listenerType')?.value == 'Multi-site'">
                          <label class="value" for="MultiType">Multi site</label>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="currentForm.get('listenerType')?.value=='Multi-site'">
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Host type</label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="hostType"  class="form-check-inline" id='single' (change)="setRadioBtn('Single','listener','hostType')" [checked]="currentForm.get('hostType')?.value =='Single'">
                            <label class="value" for="single">Single</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="hostType" class="form-check-inline" id='multiple' (change)="setRadioBtn('Multiple','listener','hostType')" [checked]="currentForm.get('hostType')?.value == 'Multiple'">
                            <label class="value" for="multiple">Multiple/Wildcard</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('hostType')?.value=='Single'">
                      <label class="flex-custom no-wrap">Host name<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Application Gateway relies on HTTP 1.1 host headers to host 
                        more than one website on the same public IP address and port." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.singleHostNameEmpty|| inputError?.singleHostInvalidName" id="hostName" name="hostName" formControlName="hostName" (keyup)="validHostName($event,'singleHost')"/>
                      <p class="text-danger" *ngIf="inputError?.singleHostNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.singleHostInvalidName">Enter a valid Internet hostname. For example: contoso.com.</p>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('hostType')?.value=='Multiple'">
                      <label class="flex-custom no-wrap">Host name<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Application Gateway relies on HTTP 1.1 host headers to host 
                        more than one website on the same public IP address and port." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-container formArrayName="hostNameArray" *ngFor="let tanch of currentForm.get('hostNameArray')['controls']; let i = index;">
                        <div class="mb-1">
                          <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                            <div class="full-width">
                              <input class="form-control full-width" type="text" formControlName="name" (keyup)="validHostName($event,'multiHost')"/>
                            </div>
                            <ng-container class="col-md-1 pr-0" *ngIf="hostNameArrayList?.length > 1">
                              <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeHostNameList(i)">
                            </ng-container>
                          </div>
                          <span class="action-btn" (click)="addHostNameList()" *ngIf="i == hostNameArrayList?.length-1">Add new</span>
                        </div>
                      </ng-container>
                      <p class="text-danger" *ngIf="inputError?.multiHostNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.multiHostInvalidName">Enter a valid Internet hostname. For example: contoso.com.</p>
                    </div>
                  </ng-container>
                  
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Error page url</label>
                      <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="errorPgUrl"  class="form-check-inline" id='errorPgUrlYes' (change)="setRadioBtn('yes','listener','errorPgUrl')" [checked]="currentForm.get('errorPgUrl')?.value ==true">
                          <label class="value" for="errorPgUrlYes">Yes</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="errorPgUrl" class="form-check-inline" id='errorPgUrlNo' (change)="setRadioBtn('no','listener','errorPgUrl')" [checked]="currentForm.get('errorPgUrl')?.value == false">
                          <label class="value" for="errorPgUrlNo">No</label>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="currentForm.get('errorPgUrl')?.value">
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Bad gateway - 502</label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.badGatewayInvalidURL" id="badGateway" name="badGateway" formControlName="badGateway" (keyup)="validPageURL($event,'badGateway')"/>
                      <p class="text-danger" *ngIf="inputError?.badGatewayInvalidURL">The URL must point to a HTML file.</p>
                      <p class="text-danger" *ngIf="inputError?.badGatewayInvalidURL">The URL scheme must be HTTP or HTTPS.</p>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Forbidden - 403</label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.forbiddenInvalidURL" id="forbidden" name="forbidden" formControlName="forbidden" (keyup)="validPageURL($event,'forbidden')"/>
                      <p class="text-danger" *ngIf="inputError?.forbiddenInvalidURL">The URL must point to a HTML file.</p>
                      <p class="text-danger" *ngIf="inputError?.forbiddenInvalidURL">The URL scheme must be HTTP or HTTPS.</p>
                    </div>
                  </ng-container>
                  
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('listeners') || currentForm.invalid" (click)="saveAppGatewayData('listeners')">{{isEdit ? 'Save':'Add'}}</button>
            </div>
        </div>
        </ng-container>
    </ng-container>
    <!-- Routing Rules -->
    <ng-container *ngIf="currentTemplate == 'rules'">
      <div class="sidebar-right single width-652" [class.sub-sheet-544]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Rules<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb flex-custom">
                <div class="mr-3" *ngIf="prevSideSheet=='Listener'">
                  <button class="btn-btn-secondary" (click)="openSidesheet('listeners',selectedRow);showSubSidesheet=false">
                  <img src="/assets/actions/back-btn.svg" class="mr-1" alt="">Back to Listener</button>
                </div>
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addRoutingRule')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add routing rule</button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Type</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Listener</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Priority</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of applicationGatewayData?.properties?.requestRoutingRules;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.ruleType}}</td>
                            <td isEllipsisActive>{{row.properties.httpListener ? row.properties.httpListener.id.split('/')[10] : '--'}}</td>
                            <td isEllipsisActive>10</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <!-- <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addRoutingRule',row)">
                                    <p class="delete-popup-text">Edit</p>
                                  </div> -->
                                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteRoutingRule')">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                            </td>
                          </tr>
                        </tbody>
                  </table>
            </div>
          </div>
      </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert width-544">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">{{isEdit ? 'Edit Routing Rule':'Add Routing Rule'}}<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
          
              <div class="body scroll-y">
                    <ng-container *ngIf="currentSheet == 'routingRules'">
                      <form [formGroup]="currentForm">
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Rule name<span class="mandatory">*</span></label>
                        <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="ruleName" name="ruleName" formControlName="ruleName" (keyup)="validIPName($event,'frontend','routingRules')"/>
                        <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameExist">Listener name must be unique</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                            and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                     </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Priority<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="
                        Rule priority defines the order in which the rules are processed. If you don't have priority assigned for all the other rules, this field should be left empty and once you click save, the priority will be assigned automatically." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.priorityNameEmpty|| inputError?.priorityInvalidNumber|| inputError?.priorityInvalidRange" id="priority" name="priority" formControlName="priority" (keyup)="validInstance($event,'priority')"/>
                      <p class="text-danger" *ngIf="inputError?.priorityNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.priorityInvalidNumber">The value must be a number.</p>
                      <p class="text-danger" *ngIf="inputError?.priorityInvalidRange">Priority for a rule must be between 1 to 20000</p>
                    </div>
                    <div class="tabs-section">
                      <div class="main-nav">
                        <ul class="nav-wrap m-0 p-0" >
                          <li class="list-item" [class.active]="currentTab == 'listener'" (click)="changeTab('listener')">
                            <span class="menu-name">Listener</span>
                          </li>
                          <li class="list-item" [class.active]="currentTab == 'backendTargets'" (click)="changeTab('backendTargets')">
                            <span class="menu-name">Backend targets
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ng-conatiner *ngIf="currentTab == 'listener'">
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">A listener “listens” on a specified port and IP address for traffic that uses a specified protocol. If the listener criteria are met, the application gateway will apply this routing rule.</label>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Listener<span class="mandatory">*</span></label>
                        <ng-select [items]="listenerListRules" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="listener" [clearable]="false" id="listener" formControlName="listener"></ng-select>
                        <p class="text-danger" *ngIf="!listenerListRules.length">There are no unassociated listeners available. Create a new listener and then try again.</p>
                      </div>
                    </ng-conatiner>
                    <ng-conatiner *ngIf="currentTab == 'backendTargets'">
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Choose a backend pool to which this routing rule will send traffic. You will also need to specify a set of Backend settings that define the behavior of the routing rule.</label>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Target type</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="targetType"  class="form-check-inline" id='backendPool' (change)="setRadioBtn('backendPool','rules','targetType')" [checked]="currentForm.get('targetType')?.value =='backendPool'">
                              <label class="value" for="backendPool">Backend pool</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="targetType" class="form-check-inline" id='redirection' (change)="setRadioBtn('redirection','rules','targetType')" [checked]="currentForm.get('targetType')?.value == 'redirection'">
                              <label class="value" for="redirection">Redirection</label>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="currentForm.get('targetType')?.value =='backendPool'">
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Backend target<span class="mandatory">*</span> <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the backend target to which this rule should route traffic. If you’re creating a path-based rule, traffic will be sent to this backend target unless you specify otherwise below." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <ng-select [items]="backendTargetList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="backendTarget" [clearable]="false" id="backendTarget" formControlName="backendTarget"></ng-select>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Backend settings<span class="mandatory">*</span> <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Backend setting defines behavior for this rule, including the port and protocol used in the backend and settings such as cookie-based session affinity and connection draining." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <ng-select [items]="backendSettingsList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="backendSetting" [clearable]="false" id="backendSetting" formControlName="backendSetting"></ng-select>
                        </div>
                      </ng-container>
                      <ng-conatainer *ngIf="currentForm.get('targetType')?.value =='redirection'">
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Redirection type</label>
                          <ng-select [items]="redirectionTypeList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="redirectionType" [clearable]="false" id="redirectionType" formControlName="redirectionType"></ng-select>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Redirection target</label>
                            <div class="flex-custom">
                              <div class="checkbox-cs">
                                <input type="radio" name="redirectionTarget"  class="form-check-inline" id='listenerTarget' (change)="setRadioBtn('listener','rules','redirectionTarget')" [checked]="currentForm.get('redirectionTarget')?.value =='listener'">
                                <label class="value" for="listenerTarget">Listener</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="redirectionTarget" class="form-check-inline" id='externalTarget' (change)="setRadioBtn('external','rules','redirectionTarget')" [checked]="currentForm.get('redirectionTarget')?.value == 'external'">
                                <label class="value" for="externalTarget">External site</label>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3" *ngIf="currentForm.get('redirectionTarget')?.value =='listener'">
                          <label class="flex-custom no-wrap">Target listener<span class="mandatory">*</span> </label>
                          <ng-select [items]="listenerList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="targetListener" [clearable]="false" id="targetListener" formControlName="targetListener"></ng-select>
                        </div>
                        <div class="mb-3" *ngIf="currentForm.get('redirectionTarget')?.value =='external'">
                          <label class="flex-custom no-wrap">Target URL<span class="mandatory">*</span></label>
                          <input class="form-control full-width" type="text" autocomplete="off" id="targetUrl" name="targetUrl" formControlName="targetUrl"/>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Include query string</label>
                            <div class="flex-custom">
                              <div class="checkbox-cs">
                                <input type="radio" name="includeQueryString"  class="form-check-inline" id='qStringYes' (change)="setRadioBtn('yes','rules','includeQueryString')" [checked]="currentForm.get('includeQueryString')?.value ==true">
                                <label class="value" for="qStringYes">Yes</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="includeQueryString" class="form-check-inline" id='qStringNo' (change)="setRadioBtn('no','rules','includeQueryString')" [checked]="currentForm.get('includeQueryString')?.value == false">
                                <label class="value" for="qStringNo">No</label>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Include path</label>
                            <div class="flex-custom">
                              <div class="checkbox-cs">
                                <input type="radio" name="includePath"  class="form-check-inline" id='pathYes' (change)="setRadioBtn('yes','rules','includePath')" [checked]="currentForm.get('includePath')?.value ==true">
                                <label class="value" for="pathYes">Yes</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="includePath" class="form-check-inline" id='pathNo' (change)="setRadioBtn('no','rules','includePath')" [checked]="currentForm.get('includePath')?.value ==false">
                                <label class="value" for="pathNo">No</label>
                            </div>
                          </div>
                        </div>
                      </ng-conatainer>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Path-based Routing<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can route traffic from this rule's listener to different backend targets based on the URL path of the request. You can also apply a different set of Backend settings based on the URL path." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      </div>
                      <div class="custom-fixed-tables mb-3">
                        <label class="flex-custom no-wrap">Path based rules</label>
                        <table class="table">
                            <thead>
                                <tr>
                                  <th><div class="flex-custom no-wrap"><span>Path</span></div></th>
                                  <th><div class="flex-custom no-wrap"><span>Target Name</span></div></th>
                                  <th><div class="flex-custom no-wrap"><span>Backend Setting Name</span></div></th>
                                  <th><div class="flex-custom no-wrap"><span>Backend Pool</span></div></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let row of pathBasedRulesList;let i=index">
                                  <td isEllipsisActive>{{row?.path}}</td>
                                  <td isEllipsisActive>{{row?.targetName}}</td>
                                  <td isEllipsisActive>{{row?.backendSettings.split('/')[10]}}</td>
                                  <td isEllipsisActive>{{row?.backendTarget.split('/')[10]}}</td>
                                </tr>
                                <!-- <div class="col-md-12 mt-3" *ngIf="!pathBasedRulesList?.length">
                                  <p class="content-14 blur-text">No Results</p>
                                </div> -->
                              </tbody>
                        </table>
                      </div>
                      <div class="custom-fixed-tables mb-3" *ngIf="redirectRulesList.length">
                        <label class="flex-custom no-wrap">Redirect rules</label>
                        <table class="table">
                            <thead>
                                <tr>
                                  <th><div class="flex-custom no-wrap"><span>Path</span></div></th>
                                  <th><div class="flex-custom no-wrap"><span>Target Name</span></div></th>
                                  <th><div class="flex-custom no-wrap"><span>Redirection type</span></div></th>
                                  <th><div class="flex-custom no-wrap"><span>Redirection target</span></div></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let row of redirectRulesList;let i=index">
                                  <td isEllipsisActive>{{row?.path}}</td>
                                  <td isEllipsisActive>{{row?.targetName}}</td>
                                  <td isEllipsisActive>{{row?.redirectionType}}</td>
                                  <td isEllipsisActive>{{row?.redirectionTarget}}</td>
                                </tr>
                              </tbody>
                        </table>
                      </div>
                      <div class="comm-mb">
                        <button class="btn-btn-secondary" (click)="openSubSideSheet('multipleTarget')">
                        <img src="/assets/governance/add-btn.svg" alt="">Add multiple targets to create a path-based rule</button>
                      </div>
                    </ng-conatiner>
                   </form>
                   </ng-container>
                    <ng-container *ngIf="currentSheet == 'addMultipleTargets'">
                      <form [formGroup]="subSideSheetForm">
                        <div class="comm-mb">
                          <button class="btn-btn-secondary" (click)="currentSheet='routingRules'">
                            <img src="/assets/actions/back-btn.svg" class="mr-1" alt="">Back to routing rules</button>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Target type</label>
                            <div class="flex-custom">
                              <div class="checkbox-cs">
                                <input type="radio" name="targetType"  class="form-check-inline" id='backendPool' (change)="setRadioBtn('backendPool','rules','targetType')" [checked]="subSideSheetForm.get('targetType')?.value =='backendPool'">
                                <label class="value" for="backendPool">Backend pool</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="targetType" class="form-check-inline" id='redirection' (change)="setRadioBtn('redirection','rules','targetType')" [checked]="subSideSheetForm.get('targetType')?.value == 'redirection'">
                                <label class="value" for="redirection">Redirection</label>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Path<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The settings below will be applied to requests matching the URL path you define." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.pathEmpty|| inputError?.invalidPath" id="path" name="path" formControlName="path" (keyup)="validPath($event,'mandatory')"/>
                          <p class="text-danger" *ngIf="inputError?.pathEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.invalidPath">The path must start with a forward slash '/'.</p>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Target name<span class="mandatory">*</span></label>
                          <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="(subSideSheetForm.get('targetName')?.dirty ||  subSideSheetForm.get('targetName')?.touched) && !subSideSheetForm.get('targetName')?.value" id="targetName" name="targetName" formControlName="targetName"/>
                          <p class="text-danger" *ngIf="(subSideSheetForm.get('targetName')?.dirty ||  subSideSheetForm.get('targetName')?.touched) && !subSideSheetForm.get('targetName')?.value">The value must not be empty.</p>
                        </div>
                        <ng-container *ngIf="subSideSheetForm.get('targetType')?.value =='backendPool'">
                          <div class="mb-3">
                            <label class="flex-custom no-wrap">Backend target<span class="mandatory">*</span> <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the backend target to which this rule should route traffic. If you’re creating a path-based rule, traffic will be sent to this backend target unless you specify otherwise below." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <ng-select [items]="backendTargetList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="backendTarget" [clearable]="false" id="backendTarget" formControlName="backendTarget"></ng-select>
                          </div>
                          <div class="mb-3">
                            <label class="flex-custom no-wrap">Backend settings<span class="mandatory">*</span> <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Backend setting defines behavior for this rule, including the port and protocol used in the backend and settings such as cookie-based session affinity and connection draining." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <ng-select [items]="backendSettingsList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="backendSetting" [clearable]="false" id="backendSetting" formControlName="backendSetting"></ng-select>
                          </div>
                        </ng-container>
                        <ng-conatainer *ngIf="subSideSheetForm.get('targetType')?.value =='redirection'">
                          <div class="mb-3">
                            <label class="flex-custom no-wrap">Redirection type</label>
                            <ng-select [items]="redirectionTypeList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="redirectionType" [clearable]="false" id="redirectionType" formControlName="redirectionType"></ng-select>
                          </div>
                          <div class="mb-3">
                            <label class="flex-custom no-wrap">Redirection target</label>
                              <div class="flex-custom">
                                <div class="checkbox-cs">
                                  <input type="radio" name="redirectionTarget"  class="form-check-inline" id='listenerTarget' (change)="setRadioBtn('listener','rules','redirectionTarget')" [checked]="subSideSheetForm.get('redirectionTarget')?.value =='listener'">
                                  <label class="value" for="listenerTarget">Listener</label>
                              </div>
                              <div class="checkbox-cs">
                                  <input type="radio" name="redirectionTarget" class="form-check-inline" id='externalTarget' (change)="setRadioBtn('external','rules','redirectionTarget')" [checked]="subSideSheetForm.get('redirectionTarget')?.value == 'external'">
                                  <label class="value" for="externalTarget">External site</label>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3" *ngIf="subSideSheetForm.get('redirectionTarget')?.value =='listener'">
                            <label class="flex-custom no-wrap">Target listener<span class="mandatory">*</span> </label>
                            <ng-select [items]="listenerList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="targetListener" [clearable]="false" id="targetListener" formControlName="targetListener"></ng-select>
                          </div>
                          <div class="mb-3" *ngIf="subSideSheetForm.get('redirectionTarget')?.value =='external'">
                            <label class="flex-custom no-wrap">Target URL<span class="mandatory">*</span></label>
                            <input class="form-control full-width" type="text" autocomplete="off" id="targetUrl" [class.border-danger]="(subSideSheetForm.get('targetUrl')?.dirty ||  subSideSheetForm.get('targetUrl')?.touched) && !subSideSheetForm.get('targetUrl')?.value" name="targetUrl" formControlName="targetUrl"/>
                            <p class="text-danger" *ngIf="(subSideSheetForm.get('targetUrl')?.dirty ||  subSideSheetForm.get('targetUrl')?.touched) && !subSideSheetForm.get('targetUrl')?.value">The value must not be empty.</p>
                          </div>
                          <div class="mb-3">
                            <label class="flex-custom no-wrap">Include query string</label>
                              <div class="flex-custom">
                                <div class="checkbox-cs">
                                  <input type="radio" name="includeQueryString"  class="form-check-inline" id='qStringYes' (change)="setRadioBtn('yes','rules','includeQueryString')" [checked]="subSideSheetForm.get('includeQueryString')?.value ==true">
                                  <label class="value" for="qStringYes">Yes</label>
                              </div>
                              <div class="checkbox-cs">
                                  <input type="radio" name="includeQueryString" class="form-check-inline" id='qStringNo' (change)="setRadioBtn('no','rules','includeQueryString')" [checked]="subSideSheetForm.get('includeQueryString')?.value == false">
                                  <label class="value" for="qStringNo">No</label>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="flex-custom no-wrap">Include path</label>
                              <div class="flex-custom">
                                <div class="checkbox-cs">
                                  <input type="radio" name="includePath"  class="form-check-inline" id='pathYes' (change)="setRadioBtn('yes','rules','includePath')" [checked]="subSideSheetForm.get('includePath')?.value ==true">
                                  <label class="value" for="pathYes">Yes</label>
                              </div>
                              <div class="checkbox-cs">
                                  <input type="radio" name="includePath" class="form-check-inline" id='pathNo' (change)="setRadioBtn('no','rules','includePath')" [checked]="subSideSheetForm.get('includePath')?.value ==false">
                                  <label class="value" for="pathNo">No</label>
                              </div>
                            </div>
                          </div>
                          </ng-conatainer>
                      </form>
                    </ng-container>
            
              </div>
      
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('rules') " (click)="saveAppGatewayData('rules')">{{isEdit ? 'Save':'Add'}}</button>
              </div>
           </div>
        </ng-container>
    </ng-container>
    <!-- Health Probes -->
    <ng-container *ngIf="currentTemplate == 'healthProbes'">
      <div class="sidebar-right single width-652" [class.sub-sheet-400]="showSubSidesheet && currentSheet!='testProbe'" [class.sub-sheet-652]="showSubSidesheet && currentSheet=='testProbe'">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Health Probes<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addProbe')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add Health probe</button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Protocol</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Host</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Path</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Timeout (seconds)</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of applicationGatewayData?.properties?.probes;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.protocol}}</td>
                            <td isEllipsisActive>{{row?.properties?.host}}</td>
                            <td isEllipsisActive>{{row.properties.path}}</td>
                            <td isEllipsisActive>{{row.properties.timeout}}</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addProbe',row)">
                                    <p class="delete-popup-text">Edit</p>
                                  </div>
                                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteProbe')">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                            </td>
                          </tr>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert" [class.width-400]="currentSheet!='testProbe'" [class.width-652]="currentSheet=='testProbe'">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">{{isEdit ? 'Edit Health Probe':'Add Health Probe'}}<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
              <ng-container *ngIf="currentSheet=='addProbe'">
                <form [formGroup]="currentForm">
                <div class="body scroll-y">
                      <div class="mb-3">
                          <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="probeName" name="probeName" formControlName="probeName" (keyup)="validIPName($event,'frontend','healthProbe')"/>
                          <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameExist">A probe with this name already exists.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                              and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Protocol<span class="mandatory">*</span></label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="protocol"  class="form-check-inline" id='http' (change)="setRadioBtn('Http','probe','protocol')" [checked]="currentForm.get('protocol')?.value =='Http'">
                              <label class="value" for="http">HTTP</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="protocol" class="form-check-inline" id='https' (change)="setRadioBtn('Https','probe','protocol')" [checked]="currentForm.get('protocol')?.value == 'Https'">
                              <label class="value" for="https">HTTPS</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="!currentForm.get('pickHostName')?.value">
                        <label class="flex-custom no-wrap">Host<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This value is the name of the virtual host (different from the VM host name) running on the application server. 
                          The probe is sent to (protocol)://(host name):(port from httpsetting)/urlPath. This is applicable when multi-site 
                          is configured on Application Gateway. If the Application Gateway is configured for a single site, then enter '127.0.0.1'." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.singleHostNameEmpty|| inputError?.singleHostInvalidName" id="hostName" name="hostName" formControlName="hostName" (keyup)="validHostName($event,'singleHost')"/>
                        <p class="text-danger" *ngIf="inputError?.singleHostNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.singleHostInvalidName">This value is neither an IP address nor a fully qualified domain name (FQDN).</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Pick host name from backend settings</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="pickHostName"  class="form-check-inline" id='pickHostNameYes' (change)="setRadioBtn('yes','probe','pickHostName')" [checked]="currentForm.get('pickHostName')?.value ==true">
                              <label class="value" for="pickHostNameYes">Yes</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="pickHostName" class="form-check-inline" id='pickHostNameNo' (change)="setRadioBtn('no','probe','pickHostName')" [checked]="currentForm.get('pickHostName')?.value == false">
                              <label class="value" for="pickHostNameNo">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Pick port from backend settings</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="pickPort"  class="form-check-inline" id='pickPortYes' (change)="setRadioBtn('yes','probe','pickPort')" [checked]="currentForm.get('pickPort')?.value ==true">
                              <label class="value" for="pickPortYes">Yes</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="pickPort" class="form-check-inline" id='pickPortNo' (change)="setRadioBtn('no','probe','pickPort')" [checked]="currentForm.get('pickPort')?.value == false">
                              <label class="value" for="pickPortNo">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="!currentForm.get('pickPort')?.value">
                        <label class="flex-custom no-wrap">Port<span class="mandatory">*</span></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.portNameEmpty|| inputError?.portInvalidNumber|| inputError?.portInvalidRange" id="port" name="port" formControlName="port" (keyup)="validInstance($event,'port')"/>
                        <p class="text-danger" *ngIf="inputError?.portNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidNumber">The value must be a number.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidRange">The value must be between 1 and 65535.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Path<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Relative path of the probe. The valid path starts from '/'" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.invalidPath|| inputError?.pathEmpty" id="path" name="path" formControlName="path" (keyup)="validPath($event,'mandatory')"/>
                        <p class="text-danger" *ngIf="inputError?.pathEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.invalidPath">The path must start with a forward slash '/'.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Interval (seconds)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Probe interval in seconds. This value is the time interval between two consecutive probes." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.intervalNameEmpty|| inputError?.intervalInvalidNumber|| inputError?.intervalInvalidRange" id="interval" name="interval" formControlName="interval" (keyup)="validInstance($event,'interval')"/>
                        <p class="text-danger" *ngIf="inputError?.intervalNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.intervalInvalidNumber">The value must be a number.</p>
                        <p class="text-danger" *ngIf="inputError?.intervalInvalidRange">The value must be between 0 and 86400.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Timeout (seconds)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Probe time-out in seconds. If a valid response is not received within this time-out period, 
                          the probe is marked as failed. Note that the time-out value should not be more than the Interval value." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.reqTimeOutNameEmpty|| inputError?.reqTimeOutInvalidNumber|| inputError?.reqTimeOutInvalidRange" id="timeOut" name="timeOut" formControlName="timeOut" (keyup)="validInstance($event,'reqTimeOut')"/>
                        <p class="text-danger" *ngIf="inputError?.reqTimeOutNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.reqTimeOutInvalidNumber">The value must be a number.</p>
                        <p class="text-danger" *ngIf="inputError?.reqTimeOutInvalidRange">The value must be between 1 and 86400.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Unhealthy threshold<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Probe retry count. The back-end server is marked down after the 
                          consecutive probe failure count reaches the unhealthy threshold." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.thresholdNameEmpty|| inputError?.thresholdInvalidNumber|| inputError?.thresholdInvalidRange" id="threshold" name="threshold" formControlName="threshold" (keyup)="validInstance($event,'threshold')"/>
                        <p class="text-danger" *ngIf="inputError?.thresholdNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.thresholdInvalidNumber">The value must be a number.</p>
                        <p class="text-danger" *ngIf="inputError?.thresholdInvalidRange">The value must be between 0 and 20.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Use probe matching conditions<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="By default, an HTTP(S) response with status code 200-399 is considered healthy. 
                          Custom health probes additionally support two matching criteria. Matching criteria 
                          can be used to optionally modify the default interpretation of what constitutes a healthy response." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="useProbe"  class="form-check-inline" id='useProbeYes' (change)="setRadioBtn('yes','probe','useProbe')" [checked]="currentForm.get('useProbe')?.value ==true">
                              <label class="value" for="useProbeYes">Yes</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="useProbe" class="form-check-inline" id='useProbeNo' (change)="setRadioBtn('no','probe','useProbe')" [checked]="currentForm.get('useProbe')?.value == false">
                              <label class="value" for="useProbeNo">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3"  *ngIf="currentForm.get('useProbe')?.value">
                        <label class="flex-custom no-wrap">HTTP response status code match<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Responses matching HTTP status code or code range(s) entered here will be 
                          considered successful. Enter a comma-separated list of codes (e.g. 200, 201) 
                          or a range of codes (e.g. 220-226)." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="(currentForm.get('codeMatch')?.dirty ||  currentForm.get('codeMatch')?.touched) && !currentForm.get('codeMatch')?.value" id="codeMatch" name="codeMatch" formControlName="codeMatch"/>
                        <p class="text-danger" *ngIf="(currentForm.get('codeMatch')?.dirty ||  currentForm.get('codeMatch')?.touched) && !currentForm.get('codeMatch')?.value">The value must not be empty.</p>
                      </div>
                      <div class="mb-3"  *ngIf="currentForm.get('useProbe')?.value">
                        <label class="flex-custom no-wrap">HTTP response body match<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Responses matching the string entered here will be considered successful. 
                          The match only looks for presence of string in the response body and is 
                          not a full regular expression." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" type="text" autocomplete="off" id="bodyMatch" name="bodyMatch" formControlName="bodyMatch"/>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Backend settings<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Probe will get associated with the backend setting(s) selected here and therefore, will monitor the health 
                          of that backend pool which is associated with the selected backend setting. You can only choose those 
                          backend setting(s) which are not associated with any other custom probe. Note that only those backend 
                          setting(s) are available for association which have the same protocol as the protocol chosen in this probe 
                          configuration and have the same state for the PickHostNameFromBackendSetting switch." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       <div class="margin-multi-select">
                        <ng-select class="full-width" [items]="backendSettingsList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" placeholder="0 Selected" name="backendSettings" [clearable]="false" id="backendSettings" formControlName="backendSettings">
                            <ng-template ng-multi-label-tmp let-items="items">
                                <span>{{items.length}} selected</span>
                              </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div>
                                  <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected"  [checked]="item$.selected"/> {{item.name}}
                                </div>
                              </ng-template>
                        </ng-select>
                       </div>
                    </div>
              </div>
              <div class="fixed-btm-btn flex-custom no-wrap">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="testHealthProbe" class="form-check-inline" id='testHealthProbe' value="testHealthProbe" formControlName="testHealthProbe" (change)="saveDisable=true" >
                  <label class="value" for="testHealthProbe" (change)="saveDisable=true">I want to test the backend health <br>
                    before adding the health probe</label>
                </div>
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('probes') || currentForm.invalid" (click)="saveAppGatewayData('probes')">{{currentForm.get('testHealthProbe')?.value ? 'Test' : isEdit ? 'Save':'Add'}}</button>
               </div>
              </form>
              </ng-container>
              <ng-container *ngIf="currentSheet=='testProbe'">
                <div class="body scroll-y">
                  <div class="comm-mb">
                    <button class="btn-btn-secondary" (click)="currentSheet='addProbe'">
                    <img src="/assets/actions/back-btn.svg" class="mr-1" alt="">Back to probe</button>
                  </div>
                  <div class="custom-fixed-tables mb-3">
                    <table class="table">
                        <thead>
                            <tr>
                              <th><div class="flex-custom no-wrap"><span>Backend Pool</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>HTTP Setting</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>Status</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>Details</span></div></th>
                              <!-- <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Timeout (seconds)</span></div></th>
                              <th style="border-left: none;" class="wid-75"></th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td isEllipsisActive>No results.</td>
                              <td isEllipsisActive></td>
                              <td isEllipsisActive></td>
                              <td isEllipsisActive></td>
                            </tr>
                          </tbody>
                    </table>
                  </div>
              </div>
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary disabled">Save</button>
                  <!-- [class.disabled]="" (click)="saveAppGatewayData('probes')" -->
              </div>
              </ng-container>
          </div>
          </ng-container>
    </ng-container>

</ng-container>

  <ng-template #appGatewayDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">Application Gateway</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" (click)="openSidesheet('configuration',selectedData)">Configuration</div>
            <div class="tab-item" (click)="openSidesheet('webAppFirewall',selectedData)">Web application firewall</div>
            <div class="tab-item" (click)="openSidesheet('backendPool',selectedData)">Backend pools</div>
            <div class="tab-item" (click)="openSidesheet('backendSetting',selectedData)">Backend settings</div>
            <div class="tab-item" (click)="openSidesheet('frontendIP',selectedData)">Frontend IP Configuration</div>
            <div class="tab-item" (click)="openSidesheet('listeners',selectedData)">Listeners</div>
            <div class="tab-item" (click)="openSidesheet('rules',selectedData)">Rules</div>
            <div class="tab-item" (click)="openSidesheet('healthProbes',selectedData)">Health probes</div>
            <div class="tab-item" (click)="action(selectedData,'deleteAppGatewayData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedData;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedData[i]:getTags(selectedData[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedData[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedData[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedData[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #notDeleteTemplate>
    <div class="medium-popup-wrapper"  style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <div class="label-div d-flex warningBox">
            <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
            <label class="" style="padding: 8px 2px 8px 0px !important;color: #703501;">{{getMessage()}}</label>
          </div>  
        </div>
        <div class="submit-sec text-right">
          <button id="secondary" class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
      </div>
    </div>
  </ng-template>