import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-monitoring-sap-hana',
  templateUrl: './application-monitoring-sap-hana.component.html',
  styleUrls: ['./application-monitoring-sap-hana.component.scss']
})
export class ApplicationMonitoringSAPHANAComponent implements OnInit {

  currentTab = 'overview';

  constructor() { }

  ngOnInit(): void {
    $('.main-content').addClass('only-grey-bg');
  }

  ngOnDestroy() {
    $('.main-content').removeClass('only-grey-bg');
  }

}
