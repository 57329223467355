import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { IndianTimeFormat } from 'src/app/pipes/custom.pipe';
import { UtilService } from '../../../services/util.service';
import { SecurityBucketState } from '../../../state/security-bucket.state';
import { SecurityQueries } from '../../security-queries'

@Component({
  selector: 'app-endpoint-security-windows-linux',
  templateUrl: './endpoint-security-windows-linux.component.html',
  styleUrls: ['./endpoint-security-windows-linux.component.scss']
})
export class EndpointSecurityWindowsLinuxComponent implements OnInit  {
  @Select(SecurityBucketState.currentProjectDetail) currentProjectDetail$: Observable<any>;
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  detailsPop:any;
  searchKey:any;
  datasort: string;
  refreshedTime: any = {
    syslogEvent: new Date(),
    rareProcesses: new Date(),
    syslogEventCard: new Date(),
    runningProcesses: new Date(),
    runningProcess: new Date(),
    runningProcessesCard: new Date(),
    vmFailedCard: new Date(),
    devicesWithSecurity: new Date(),
    missingSecurity: new Date(),
    devicesWithFailed: new Date(),
    newProcesesObserved: new Date(),
  }
  options:any ={};
  syslogEvent: any = {
    type:'syslogEvent',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'lasthr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  rareProcesses: any = {
    type:'rareProcesses',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  vmFailedCard: any = {
    type:'vmFailedCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  syslogEventCard: any = {
    type:'syslogEventCard',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'lasthr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  runningProcessesCard: any = {
    type:'runningProcessesCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  runningProcess: any = {
    type:'runningProcess',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'runningProcess',
    xAxis:[],
    yAxis:[],
    showLegend : true,
    options:{},
    legendData:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    max:undefined,
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  devicesWithSecurity:any={
    type:'devicesWithSecurity',
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    },
    showLoader: true,
    tableData:[],
    data:[],
    tempData:[],
    tempTableData:[],
  };
  missingSecurity: any = {
    type:'missingSecurity',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  devicesWithFailed: any = {
    type:'devicesWithFailed',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  newProcesesObserved: any = {
    type:'newProcesesObserved',
    tableData:[],
    tempData:[],
    onlyTable:true,
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  selectedData: any;
  timeFilterList: any=[];
  timeFilterFlag: boolean=false;
  currentProject: any;
  subscriptionInfra: Subscription;
  assignUserList: any = [];
  runningProcessShow= false;
  processDetails:FormGroup;
  processList: any = [];
  runningProcesses: any = {
    type:'runningProcesses',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'Process Started',
    xAxis:[],
    options:{},
    yAxis:[],
    showLegend : true,
    legendData:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    max:undefined,
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };  
  currentSyslogTableData: any = [];

  constructor(private util:UtilService,private modalService: NgbModal,private datePipe:DatePipe,private timeFormat:IndianTimeFormat,private fb:FormBuilder) { }

  async ngOnInit() {
    this.processDetails= this.fb.group({
      process:[null],
      timeRange:['last24hr']
    });
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.subscriptionInfra = this.currentProjectDetail$.subscribe(async (res) => {
      if(res){
        this.currentProject = _.cloneDeep({...res});//res.currentFilteredProject;
        this.triggerFilter(this.currentProject,'project')
        this.getTableData('syslogEvent');
        this.getTableData('syslogEventCard');
        this.getTableData('vmFailedCard');
        this.getRunningProcessesData();
        this.getTableData('rareProcesses');
        this.getTableData('runningProcessesCard');
        this.getDevicesWithSecurityData();
        this.getMissingSecurity();
        this.getDevicesWithFailed();
        this.getTableData('newProcesesObserved');
      }
    });
  }

  ngOnDestroy() {
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

  getTableData(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": this.getTableQuery(type)
    }
    this[type].tableData=[];
    this[type].tempTableData=[];
    this[type].showLoader = true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      if(!res || !res.body) {
        this[type].showLoader = false;
        return 
      }
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempTableData.push(ds);
        });
      })
      this.filterTableData(type);
      this[type].showLoader = false;
    },err=>{
      this[type].showLoader = false;
    })
  }

  getTableQuery(type){
    switch(type){
      case 'syslogEvent':
        return SecurityQueries.syslogEvents.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'rareProcesses':
        return SecurityQueries.rareProcesses.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'runningProcessesCard':
        return SecurityQueries.runningProcesses.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'syslogEventCard':
        return SecurityQueries.syslogEvents.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'vmFailedCard':
        return SecurityQueries.devicesWithFailed.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'newProcesesObserved':
        return SecurityQueries.newProcesesObserved.replace('TIMERANGE',this.util.getLastSeenTimeRangeQuery(this[type].currentFilter))
      case 'devicesWithSecurity':
        return SecurityQueries.devicesWithSecurityForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithSecurity.currentFilter))
      case 'missingSecurity':
        return SecurityQueries.missingSecurityForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.missingSecurity.currentFilter))
      case 'devicesWithFailed':
        return SecurityQueries.devicesWithFailedForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithFailed.currentFilter))
    }
  }

  getDevicesWithSecurityData(){
    this.devicesWithSecurity.showLoader = true;
    this.devicesWithSecurity.tempData = []; 
    Promise.all(this.filterObject.subscriptionList.map(e=>{
      let body ={
        "subscriptions":[e.subscriptionId],
        "query": SecurityQueries.devicesWithSecurity.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithSecurity.currentFilter))
      } 
      return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true)
    })).then((final:any)=>{
      
      final.forEach((res:any)=>{
        if(!res){
          this.devicesWithSecurity.showLoader = false;
          return ;
        } 
        let data =res;
        this.devicesWithSecurity.tempData=data.body.tables;
        this.filterDWS();
        this.getTableData('devicesWithSecurity');
        this.devicesWithSecurity.showLoader = false;
      })
    },err=>{
      this.devicesWithSecurity.showLoader = false;
    })
    
  }

  filterDWS(){
    this.devicesWithSecurity.data=[];
    this.devicesWithSecurity.tempData.forEach(e=>{
      e.rows.forEach(dt=>{
        if(this.filterCheck(dt[1])){
          this.devicesWithSecurity.data.push({
            "computer":dt[0].split('.')[0],
            "securityLogs":dt[2]
          })
        }
      })
    })
    this.devicesWithSecurity.options=this.setDevicesWithSecurityOptions();
  }

  // Charts Options
  setDevicesWithSecurityOptions(){
    let tempData=_.cloneDeep(this.devicesWithSecurity.data);
    let xAxisData = tempData.map(e=>e.computer);
    let legendData = [];
    let series = [];
    let  dObj = {
      name: '',
      type: 'bar',
      barWidth: 70,
      stack: 'total',
      emphasis: {
          focus: 'series'
      },
      data: this.devicesWithSecurity.data.map(e=>e.securityLogs)
    };
    series.push(dObj);
    let options = {
      color: ['#0B70D4','#62C2FB','#FF5959','#656BFD'],
      tooltip: {
          // trigger: 'axis',
          // axisPointer: {            // Use axis to trigger tooltip
          //     type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
          // }
      },
      legend: {
          data: legendData || [],
          bottom: 10,
          left: 'left'
      },
      grid: {
        left: '1%',
        right: '2%',
        bottom: '2%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: xAxisData || [],
        axisTick: {
          show: false
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            return (value.length > 10 ? (value.slice(0,10)+"...") : value )
          }
          // rotate: 30 //If the label names are too long you can manage this by rotating the label.
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#333',
          padding: [5, 0, 0, 0],
          fontSize: 10, 
          formatter: function (params) {
            return params
          },
        },
      },
      series: series
    };
    return options
  }

  getMissingSecurity(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.missingSecurity.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.missingSecurity.currentFilter))
    }
    this.getTableData('missingSecurity');
    this.missingSecurity.yAxis=[];
    this.missingSecurity.data=[];
    this.missingSecurity.tempData=[];
    this.missingSecurity.showLoader = true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.missingSecurity.showLoader= false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.missingSecurity.tempData.push(ds);
        });
      })
      this.filterOptionData('missingSecurity',1);
    },err=>{
      this.missingSecurity.showLoader= false
    })
  }

  getDevicesWithFailed(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.devicesWithFailed.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.devicesWithFailed.currentFilter))
    }
    this.getTableData('devicesWithFailed');
    this.devicesWithFailed.yAxis=[];
    this.devicesWithFailed.data=[];
    this.devicesWithFailed.tempData=[];
    this.devicesWithFailed.showLoader= true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.devicesWithFailed.showLoader= false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.devicesWithFailed.tempData.push(ds);
        });
      })
      this.filterOptionData('devicesWithFailed',1);
    },err=>{
      this.devicesWithFailed.showLoader= false
    })
  }

  filterOptionData(optionName,checkCol){
    this[optionName].data=[];
    this[optionName].yAxis=[];
    this[optionName].tempData.forEach(dt=>{
      if(this.filterCheck(dt[checkCol])){
        this[optionName].yAxis.push(dt[0].split(".")[0])
        this[optionName].data.push(optionName==='updateAvailAcrossOption'?dt[1]:dt[2])
      }
    })
  }

  getRunningProcessesData(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.runningProcesses.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.runningProcesses.currentFilter))
    }
    this.runningProcesses.showLoader = true
    this.runningProcesses.legendData = []
    this.runningProcesses.series = []
    this.runningProcesses.xAxis = []
    this.runningProcesses.tempData=[];
    this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.runningProcesses.showLoader = false
      if(!res || !res.body) return 
      
      res.body.tables.forEach((dt)=>{
        this.runningProcesses.tempData.push(dt);
      })
      this.filterRunningProcesses();
    },err=>{
      this.runningProcesses.showLoader = false
    })
  }

  showRunningProcess(processName) {
    if(this.runningProcesses.series.length && !this.runningProcesses.showLoader) {
      this.runningProcessShow=true;
      this.processDetails.patchValue({
        timeRange:this.runningProcesses.currentFilter.type
      })
      this.getRunningProcessData(processName);
    }
  }

  processChange() {
    this.runningProcess.showLoader = true;
    this.runningProcess.legendData = [];
    this.runningProcess.series = [];
    this.runningProcess.xAxis = [];
    let series=[];
    this.runningProcess.tempData.forEach((ds,index1)=>{
      var timeArray = [];
      ds.rows.forEach((dt)=>{
        if(this.filterCheck(dt[2])) {
          if(this.processDetails.value.process===dt[1]) {
            let findIndex=series.findIndex(d=>d.name==dt[1]);
            if(findIndex>-1){
              series[findIndex].data.push(dt[3]);
            }else {
              let obj = {
                name : dt[1],
                type : 'line',
                showSymbol :false,
                data: [dt[3]]
              }            
              series.push(obj);
            }
            let findTime=timeArray.find(dt=>dt==dt[0]);
            if(!findTime){
              timeArray.push(dt[0]);       
            }
            let findObj=this.runningProcess.legendData.find(d=>d.name==dt[1]);
            if(!findObj){
              this.runningProcess.legendData.push({icon:'circle',name:dt[1]});
            }
          }
        }
      });
     
      let sorted : any = timeArray.sort((a,b)=> {
        var d1 = new Date(b);
        var d2 = new Date(a);
        return d2 > d1 ? 1 : 0;
      })
    
      sorted.forEach((x)=>{
        if(index1 == 0){
          let findXAxis=this.runningProcess.xAxis.find(dt=>dt==this.timeFormat.transform(x));
          if(!findXAxis){
          this.runningProcess.xAxis.push(this.timeFormat.transform(x))
          }
        }
      })
    });
    setTimeout(()=>{
      this.runningProcess.series = series;
      this.runningProcess.options=this.setRunningProcessOption('runningProcess');
      this.runningProcess.showLoader = false;
    },2000)
  }

  getRunningProcessData(processName){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.runningProcesses.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.runningProcess.currentFilter))
    }
    this.runningProcess.showLoader = true
    this.runningProcess.legendData = []
    this.runningProcess.series = []
    this.runningProcess.xAxis = []
    this.runningProcess.tempData=[];
    this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      if(!res || !res.body) {
        this.runningProcess.showLoader = false
        return 
      }
      
      res.body.tables.forEach((dt)=>{
        this.runningProcess.tempData.push(dt);
      })
      this.filterRunningProcess(processName);
      this.runningProcess.showLoader = false
    },err=>{
      this.runningProcess.showLoader = false
    })
  }

  filterCheck(value){
    return (this.filterObject.subscriptionList.find(dtl=>dtl.checked&&value.split("/")[2]&&dtl.subscriptionId===(value.split("/")[2])) && this.filterObject.resourceGroupList.find(dtl=>dtl.checked&&value.split("/")[4]&&dtl.resourceGroupName.toLowerCase()===(value.split("/")[4]).toLowerCase()) && this.filterObject.resourceList.find(dtl=>dtl.checked&&(value.split("/")[value.split("/").length-1])&&dtl.resourceName.toLowerCase()===(value.split("/")[value.split("/").length-1]).toLowerCase()))||(this.filterObject.subscriptionList.find(dtl=>dtl.checked&&dtl.subscriptionId===(value.split("/")[2])) && !value.split("/")[4])
  }

  filterRunningProcess(processName:string): void {
    this.runningProcess.legendData = [];
    this.runningProcess.series = [];
    this.runningProcess.xAxis = [];
    this.processList = [];
    if(this.runningProcess.tempData&&this.runningProcess.tempData.length) {
      this.runningProcess.tempData.forEach((ds,index1)=>{
        var timeArray = [];
        ds.rows.forEach((dt,index)=>{
          if(this.filterCheck(dt[2])){
            if(!this.processList.find(dp=>dp.name===dt[1])){
              this.processList.push({
                name:dt[1]
              })
            }
            let findIndex=this.runningProcess.series.findIndex(d=>d.name==dt[1]);
            if(findIndex>-1){
              this.runningProcess.series[findIndex].data.push(dt[3]);
            }
            if(findIndex>-1||(this.runningProcess.series.length===0&&(!processName||(processName&&processName===dt[1])))) {
              let findTime=timeArray.find(dt=>dt==dt[0]);
              if(!findTime){
                timeArray.push(dt[0]);       
              }
              let findObj=this.runningProcess.legendData.find(d=>d.name==(processName?processName:dt[1]));
              if(!findObj){
                this.runningProcess.legendData.push({icon:'circle',name:(processName?processName:dt[1])});
              }
            }
            if((this.runningProcess.series.length===0&&(!processName||(processName&&processName===dt[1])))){
              this.processDetails.patchValue({
                process:(processName?processName:dt[1])
              })
              let obj = {
                name : (processName?processName:dt[1]),
                type : 'line',
                showSymbol :false,
                data: [dt[3]]
              }            
              this.runningProcess.series.push(obj);
            }
          }
        });
       
        let sorted : any = timeArray.sort((a,b)=> {
          var d1 = new Date(b);
          var d2 = new Date(a);
          return d2 > d1 ? 1 : 0;
        })
      
        sorted.forEach((x)=>{
          if(index1 == 0){
            let findXAxis=this.runningProcess.xAxis.find(dt=>dt==this.timeFormat.transform(x));
            if(!findXAxis){
            this.runningProcess.xAxis.push(this.timeFormat.transform(x))
            }
          }
        })
      });
      this.runningProcess.options=this.setRunningProcessOption('runningProcess');
    }
  }

  filterRunningProcesses(): void {
    this.runningProcesses.legendData = [];
    this.runningProcesses.series = [];
    this.runningProcesses.xAxis = [];
    if(this.runningProcesses.tempData&&this.runningProcesses.tempData.length) {
      this.runningProcesses.tempData.forEach((ds,index1)=>{
        var timeArray = [];
        ds.rows.forEach((dt)=>{
          if(this.filterCheck(dt[2])){
            let findIndex=this.runningProcesses.series.findIndex(d=>d.name==dt[1]);
            if(findIndex>-1){
            this.runningProcesses.series[findIndex].data.push(dt[3]);
            }else{
              let obj = {
                name : dt[1],
                type : 'line',
                showSymbol :false,
                data: [dt[3]]
              }            
              this.runningProcesses.series.push(obj);
            }
            let findTime=timeArray.find(dt=>dt==dt[0]);
            if(!findTime){
              timeArray.push(dt[0]);       
            }
            let findObj=this.runningProcesses.legendData.find(d=>d.name==dt[1]);
            if(!findObj){
              this.runningProcesses.legendData.push({icon:'circle',name:dt[1]});
            }
          }
        });
       
        let sorted : any = timeArray.sort((a,b)=> {
          var d1 = new Date(b);
          var d2 = new Date(a);
          return d2 > d1 ? 1 : 0;
        })
      
        sorted.forEach((x)=>{
          if(index1 == 0){
            let findXAxis=this.runningProcesses.xAxis.find(dt=>dt==this.timeFormat.transform(x));
            if(!findXAxis){
            this.runningProcesses.xAxis.push(this.timeFormat.transform(x))
            }
          }
        })
      });
      this.runningProcesses.options=this.setRunningProcessOption('runningProcesses');
    }
  }

  filterTableData(type): void {
    this[type].tableData=[];
    if(this[type].tempTableData&&this[type].tempTableData.length) {
      this[type].tempTableData.forEach(dt=>{
        let value;
        switch (type) {
          case 'syslogEvent':
          case 'syslogEventCard':
            value=dt[2];
            break;
          case 'vmFailedCard':
            value=dt[1];
            break;
          case 'runningProcessesCard':
            value=dt[2];
            break;
          case 'rareProcesses':
            value=dt[0];
            break;
          case 'newProcesesObserved':
            value=dt[5];
            break;
          case 'devicesWithSecurity':
          case 'missingSecurity':
            value=dt[2];
            break;
          case 'devicesWithFailed':
            value=dt[1];
            break;

          default:
            break;
        }
        if ((type === 'syslogEventCard')&&value && this.filterCheck(value)) {
          this.filterSyslogEventCard(type,dt);
        } else if(type==='runningProcessesCard'&&value && this.filterCheck(value)) {
          this.filterRunningProcessCard(dt);
        } else if(type==='vmFailedCard'&&value && this.filterCheck(value)) {
          this.filterVMFailedCard(dt);
        } else if(value && this.filterCheck(value)){
          this[type].tableData.push(dt);
        }
      })
      if(type === 'syslogEventCard'||type==='vmFailedCard'|| type==='runningProcessesCard') {
        this.filterData(type);
      }
    } else {
      this[type].tableData = [];
    }
  }

  filterRunningProcessCard(dt) {
    let findIndex=this.runningProcessesCard.tableData.findIndex(ds=>ds.name===dt[1]);
    if(findIndex>-1) {
      this.runningProcessesCard.tableData[findIndex].count=this.runningProcessesCard.tableData[findIndex].count+Number(dt[3]);
    } else {
      this.runningProcessesCard.tableData.push({
        name:dt[1],
        count:Number(dt[3])
      })
    }
  }

  filterVMFailedCard(dt) {
    let findIndex=this.vmFailedCard.tableData.findIndex(ds=>ds.name===dt[0]);
    if(findIndex>-1) {
      this.vmFailedCard.tableData[findIndex].count=this.vmFailedCard.tableData[findIndex].count+Number(dt[2]);
    } else {
      this.vmFailedCard.tableData.push({
        name:dt[0],
        count:Number(dt[2])
      })
    }
  }

  filterData(type): void {
    if(type === 'syslogEventCard') {
      const sortData=[];
      let findObj=this[type].tableData.find(dt=>dt.name==='emergency');
      if(findObj) {
        sortData.push(findObj);
      }
      findObj=this[type].tableData.find(dt=>dt.name==='error');
      if(findObj) {
        sortData.push(findObj);
      }
      findObj=this[type].tableData.find(dt=>dt.name==='critical');
      if(findObj) {
        sortData.push(findObj);
      }
      findObj=this[type].tableData.find(dt=>dt.name==='warning');
      if(findObj) {
        sortData.push(findObj);
      }
      findObj=this[type].tableData.find(dt=>dt.name==='alert');
      if(findObj&&sortData.length<4) {
        sortData.push(findObj);
      }
      findObj=this[type].tableData.find(dt=>dt.name==='debug');
      if(findObj&&sortData.length<4) {
        sortData.push(findObj);
      }
      findObj=this[type].tableData.find(dt=>dt.name==='notice');
      if(findObj&&sortData.length<4) {
        sortData.push(findObj);
      }
      findObj=this[type].tableData.find(dt=>dt.name==='informational');
      if(findObj&&sortData.length<4) {
        sortData.push(findObj);
      }
      this[type].tableData=sortData;

    } else if(type === 'runningProcessesCard'||type==='vmFailedCard') {
      const tableData=this[type].tableData;
      tableData.sort((a,b)=>{
        if(a.count>b.count) {
          return -1;
        }

        if(a.count<b.count) {
          return 1
        }
      });
      this[type].tableData=tableData.slice(0,4);
    }
  }

  filterSyslogEventCard(type,data): void {
    const statusName=data[1].toLowerCase();
    const findIndex=this[type].tableData.findIndex(dt=>dt.name===statusName);
    if(findIndex>-1) {
      this[type].tableData[findIndex].count=this[type].tableData[findIndex].count+1;
    } else {
      this[type].tableData.push({
        name:statusName,
        count:1
      })
    }
  }

  onChartClick(event:any): void {
    if(event.seriesName) {
      this.showRunningProcess(event.seriesName);
    }
  }

  setRunningProcessOption(name) {
    let options = {
      color: this[name].color,
      tooltip: this.util.getLineChartTooltip(),
      legend: {
        show: true,
        data: this[name].legendData,
        bottom: 0,
        left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this[name].xAxis,
          axisLabel: {
            // interval: 0,
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
            axisLine: {
              lineStyle: {
                color: '#8995A2',
              }
            },
            splitLine: {
              lineStyle: {
                color: '#8995A2',
              }
            },
          },
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
          },
      },
      series: this[name].series
    };
    return options
  }

  getStatusClass(value){
    if(value) {
      let status = value.toLowerCase()
      switch (status) {
        case 'success': return 'success-chip';
        case 'succeeded': return 'success-chip';
        case 'failed': return 'error-chip';
        case 'emergency': return 'emergency-severity-chip';
        case 'error': return 'error-chip';
        case 'warning': return 'warning-chip';
        case 'informational': return 'informational-chip';
        case 'debug': return 'debug-chip';
        case 'alert': return 'alert-chip';
        case 'notice': return 'notice-chip';
        case 'critical': return 'critical-chip';
        default: return 'error-chip';
      }
    }
  }

  openPopup(type:string, list, template?: TemplateRef<any>): void {
    this.detailsPop=list;
    this.modalService.open(template);
  }
  
  openTablePopup(template?: TemplateRef<any>): void {
    this.modalService.open(template);
  }

  openSyslogTablePopup(list, template?: TemplateRef<any>): void {
    this.currentSyslogTableData = this.syslogEvent.tableData.filter(e=> e[1].toLowerCase() == list.name.toLowerCase())
    this.modalService.open(template);
  }


  downloadDirectTableView(name,tableId){
    let table=document.getElementById(tableId);
    if(table) {
      this.downloadFile(name,table)
    } else {
      this.downloadFileCustom(name);
    }
  }

  downloadFile(name,tableId){
    this.util.generateCSVForTableData(name,tableId);
  }

  downloadFileCustom(name): void {
    let headers = [];
    let fileName = name;
    let dataList = [];
    if (name === 'syslogEvents') {
      fileName = 'Syslog Events';
      headers = ['Host','Severity','Process Name','Time Generated (UTC)'];
    } else if (name === 'rareProcessesInLinuxHost') {
      fileName = 'Rare Processes in Linux Host';
      headers = ['Process Name','Host','Host IP','Process Start Time (UTC)','Process End Time (UTC)','No. of times Process is Active'];
    } else if (name === 'killedKernelProcesses') {
      fileName = 'Killed Kernel Processes';
      headers = ['Host','Host IP','Severity','Process Name','Time Generated (UTC)'];
    } 
    this.util.generateCSVForCustom(fileName, headers, dataList);
  }

  // closeModal() {
  //   this.modalService.dismissAll();
  // }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    list.currentFilter.type = value
    switch(list.type){
      case 'syslogEvent' :this.getTableData('syslogEvent');break;   
      case 'rareProcesses' :this.getTableData('rareProcesses');break;   
      case 'killedKernelProcesses' :this.getTableData('killedKernelProcesses');break;   
      case 'syslogEventCard' :this.getTableData('syslogEventCard');break;   
      case 'runningProcesses' :this.getRunningProcessesData();this.runningProcess.currentFilter.type=value;break;   
      case 'runningProcess' :this.getRunningProcessData(null);break;   
      case 'vmFailedCard' :this.getTableData('vmFailedCard');break; 
      case 'devicesWithSecurity' :this.getDevicesWithSecurityData();break;  
      case 'missingSecurity' :this.getMissingSecurity();break;  
      case 'devicesWithFailed' :this.getDevicesWithFailed();break;  
      case 'newProcesesObserved' :this.getTableData('newProcesesObserved');break;
    }
    this.closeFilter()
  }
  
  applyCustomFilter(){
    this.selectedData.currentFilter.type = 'custom';
    switch(this.selectedData.type){
      case 'syslogEvent' :this.syslogEvent = this.selectedData;this.getTableData('syslogEvent');break; 
      case 'rareProcesses' :this.rareProcesses = this.selectedData;this.getTableData('rareProcesses');break; 
      case 'syslogEventCard' :this.syslogEventCard = this.selectedData;this.getTableData('syslogEventCard');break; 
      case 'runningProcesses' :this.runningProcesses = this.selectedData;this.runningProcess.currentFilter = this.selectedData.currentFilter;this.getRunningProcessesData();break; 
      case 'runningProcess' :this.runningProcess = this.selectedData;this.getRunningProcessData(null);break; 
      case 'vmFailedCard' :this.vmFailedCard = this.selectedData;this.getTableData('vmFailedCard');break; 
      case 'runningProcessesCard' :this.runningProcessesCard = this.selectedData;this.getTableData('runningProcessesCard');break; 
      case 'devicesWithSecurity' :this.devicesWithSecurity = this.selectedData;this.getDevicesWithSecurityData();break;
      case 'missingSecurity' :this.missingSecurity = this.selectedData;this.getMissingSecurity();break;
      case 'devicesWithFailed' :this.devicesWithFailed = this.selectedData;this.getDevicesWithFailed();break;
      case 'newProcesesObserved' :this.newProcesesObserved = this.selectedData;this.getTableData('newProcesesObserved');
      break; 
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 100;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if(!this.timeFilterFlag){
    this.closeFilter();
    }
    this.timeFilterFlag=false;
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.currentFilter.type);
    return findObj&&findObj.name?findObj.name:''
  }

  applyFilterData(){
    this.filterDWS();
    this.filterOptionData('missingSecurity',1);
    this.filterOptionData('devicesWithFailed',1);
    this.filterTableData('syslogEvent');
    this.filterTableData('syslogEventCard');
    this.filterTableData('vmFailedCard');
    this.filterTableData('rareProcesses');
    this.filterTableData('runningProcessesCard');
    this.filterTableData('devicesWithSecurity');
    this.filterTableData('missingSecurity');
    this.filterTableData('devicesWithFailed');
    this.filterTableData('newProcesesObserved');
    this.filterRunningProcesses();
    this.filterRunningProcess(null);
  }


  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    this.applyFilterData();
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    this.applyFilterData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }


  async refreshData(type){
    switch (type) {
      case 'syslogEvent':{
        this.refreshedTime.syslogEvent = new Date();
        this.getTableData('syslogEvent');
        break;
      }
      case 'syslogEventCard':{
        this.refreshedTime.syslogEventCard = new Date();
        this.getTableData('syslogEventCard');
        break;
      }
      case 'vmFailedCard':{
        this.refreshedTime.vmFailedCard = new Date();
        this.getTableData('vmFailedCard');
        break;
      }
      case 'rareProcesses':{
        this.refreshedTime.rareProcesses = new Date();
        this.getTableData('rareProcesses');
        break;
      }
      case 'runningProcesses':{
        this.refreshedTime.runningProcesses = new Date();
        this.getRunningProcessesData();
        break;
      }
      case 'runningProcess':{
        this.refreshedTime.runningProcess = new Date();
        this.getRunningProcessData(null);
        break;
      }
      case 'runningProcessesCard':{
        this.refreshedTime.runningProcessesCard = new Date();
        this.getTableData('runningProcessesCard');
        break;
      }
      case 'devicesWithSecurity':{
        this.refreshedTime.devicesWithSecurity = new Date();
        this.getDevicesWithSecurityData();
        break;
      }
      case 'missingSecurity':{
        this.refreshedTime.missingSecurity = new Date();
        this.getMissingSecurity();
        break;
      }
      case 'devicesWithFailed':{
        this.refreshedTime.devicesWithFailed = new Date();
        this.getDevicesWithFailed();
        break;
      }
      case 'newProcesesObserved':{
        this.refreshedTime.newProcesesObserved = new Date();
        this.getTableData('newProcesesObserved');
        break;
      }
    }
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
   let sorted = tableName == 'currentSyslogTableData' ? this[tableName]:this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

  sortPopup(tableName,index,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName][index];
    sorted.sort((a, b) => {
        switch (value) {
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName][index] = sorted;
 }

}