import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assign-policy',
  templateUrl: './assign-policy.component.html',
  styleUrls: ['./assign-policy.component.scss']
})
export class AssignPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
