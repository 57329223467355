 <div class="introduction">
          <p class="heading" id="4">SAP HANA  </p>
          <ol>
              <li>Click on<b> Create new project.</b>  </li>
              <li>Select source system as <b>SAP HANA.</b>   </li>
              <img src="assets\user-manual\data-&-AI\2.svg" >
              <figcaption class="caption">SAP HANA - source system</figcaption>
              <li>Provide required details for Azure Synapse as the destination and for SAP HANA as source system.   </li>
              <img src="assets\user-manual\data-&-AI\16.svg" >
              <figcaption class="caption">SAP HANA Source and Target details </figcaption>
              <li>Click Next. </li>
              <li>Select table(s) to be migrated and click <b>Next.</b> </li>
              <img src="assets\user-manual\data-&-AI\18.svg" >
              <figcaption class="caption">SAP HANA – Select tables </figcaption>
              <li>Click <b>Next </b>to migrate schema.  </li>
              <li>Click <b>Migrate </b>data. </li>
              <img src="assets\user-manual\data-&-AI\20.svg" >
              <figcaption class="caption">SAP HANA Migrate schema </figcaption>
              <li>Data migration progress will be displayed.  </li>
               <img src="src\assets\user-manual\data & AI\SAP-H-API.svg"> 
               <figcaption class="caption">SAP HANA – Migration Process</figcaption>
              <li>If data migration is successful, Successful migration message will be displayed.  </li>  
              <img src="assets\user-manual\data-&-AI\22.svg" >
              <figcaption class="caption">SAP HANA – Migration success </figcaption>
              <li>In case of error/failure, Failed data migration message will be displayed.  </li>
              <img src="assets\user-manual\data-&-AI\25.svg" >
              <figcaption class="caption">SAP HANA – Migration Failed </figcaption>
              <li>In case of partial migration, Partial data migration message will be displayed.  </li>
              <img src="assets\user-manual\data-&-AI\26.svg" >
              <figcaption class="caption">SAP HANA – Partial Success </figcaption>
              <li>Project summary page will be displayed after migration process is complete.   </li>
              <img src="assets\user-manual\data-&-AI\17.svg" >
              <figcaption class="caption">SAP HANA – Summary  </figcaption>
              <!-- <li>Click <b>View in A3S BI dashboard </b>button to view data in a dashboard. </li>
              <img src="assets\user-manual\data-&-AI\29.svg" >
              <figcaption class="caption">SAP Hana - BI dashboard </figcaption> -->
            
              
          </ol>
    </div>
