<div class="introduction">
 
            <p class="heading" id="9">Endpoint Protection </p>
            <p class="mb-0" >This ensures that all the Virtual Machines in the Azure environment are 
                secure from network security point of view. </p>
                <img class="image mb-3" src="assets\user-manual\security\Endpoint.svg" >

            <p class="heading" style="font-size: 32px;" id="10">VMs with Security Logs   </p>
            <p class="mb-0" >Number of security related logs in the Virtual Machines. </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\1.svg" >
            <figcaption class="caption col-md-6">VMs with Security Logs </figcaption>

            <p class="heading" style="font-size: 32px;" id="11">Updates Available    </p>
            <p class="mb-0" >List of Virtual Machines where Windows updates are available. </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\2.svg" >
            <figcaption class="caption col-md-6">Updates available on VM </figcaption>

            <p class="heading" style="font-size: 32px;" id="12">VMs with Missing Critical Updates   </p>
            <p class="mb-0" >List of Virtual Machines missing critical security updates.  </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\3.svg" >
            <figcaption class="caption col-md-6">Missing critical & security updates </figcaption>

            <p class="heading" style="font-size: 32px;" id="13">VMs with Most Logins  </p>
            <p class="mb-0" >List of Virtual Machines that have multiple successful logins.  </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\4.svg" >
            <figcaption class="caption col-md-6">VMs with most logon</figcaption>

            <p class="heading" style="font-size: 32px;" id="14">VMs with Failed Logins  </p>
            <p class="mb-0" >List of Virtual Machines that have multiple failed logins.  </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\5.svg" >
            <figcaption class="caption col-md-6"> Failed logon </figcaption>

      
</div>     
        