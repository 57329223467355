import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginMsalComponent } from './components/login-msal/login-msal.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { NeverActivate } from './services/never-activate.guard';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { ArchitectureBlueprintComponent } from './infra-deployment-bucket/architecture-blueprint/architecture-blueprint.component';
import { InfraDeploymentBucketComponent } from './infra-deployment-bucket/infra-deployment-bucket.component';
import { DeploymentComponent } from './infra-deployment-bucket/deployment/deployment.component';
import { BusinessContinuityComponent } from './infra-deployment-bucket/business-continuity/business-continuity.component';
import { InfraAssessmentComponent } from './infra-deployment-bucket/infra-assessment/infra-assessment.component'
import { InfraMigrationComponent } from './infra-deployment-bucket/infra-migration/infra-migration.component'
import { InfraDevopsComponent } from './infra-deployment-bucket/infra-devops/infra-devops.component'
import { ManagementComponent } from './infra-deployment-bucket/management/management.component';
import { DedicateComputeComponent } from './infra-deployment-bucket/deployment/dedicate-compute/dedicate-compute.component';
import { AciComponent } from './infra-deployment-bucket/deployment/dedicate-compute/aci/aci.component';
import { AcrComponent } from './infra-deployment-bucket/deployment/dedicate-compute/acr/acr.component';
import { LinuxVmComponent } from './infra-deployment-bucket/deployment/dedicate-compute/linux-vm/linux-vm.component';
import { WindowsVmComponent } from './infra-deployment-bucket/deployment/dedicate-compute/windows-vm/windows-vm.component';
import { CreateNewBlueprintProjectComponent } from './infra-deployment-bucket/architecture-blueprint/create-new-blueprint-project/create-new-blueprint-project.component';
import { DiagramToolComponent } from './infra-deployment-bucket/architecture-blueprint/diagram-tool/diagram-tool.component';
import { ArchitectureBlueprintListComponent } from './infra-deployment-bucket/architecture-blueprint/architecture-blueprint-list/architecture-blueprint-list.component';
import { ArchitectureBlueprintSummaryComponent } from './infra-deployment-bucket/architecture-blueprint/architecture-blueprint-list/architecture-blueprint-summary/architecture-blueprint-summary.component';
import { ApplicationMonitoringBucketComponent } from './application-monitoring-bucket/application-monitoring-bucket.component';
import { ApplicationMonitoringDashboardComponent } from './application-monitoring-bucket/application-monitoring-dashboard/application-monitoring-dashboard.component';
import { ArchitectureDeploymentStatusComponent } from './infra-deployment-bucket/architecture-blueprint/architecture-blueprint-list/architecture-deployment-status/architecture-deployment-status.component';
import { ApplicationMonitoringAlertsComponent } from './application-monitoring-bucket/application-monitoring-alerts/application-monitoring-alerts.component';
import { OverviewManagementComponent } from './infra-deployment-bucket/management/overview-management/overview-management.component';
import { SecurityBucketComponent } from './security-bucket/security-bucket.component';
import { SecurityAlertManagementComponent } from './security-bucket/security-alert-management/security-alert-management.component';
import { SecurityPostureComponent } from './security-bucket/security-posture/security-posture.component';
import { SecurityRecommendationsComponent } from './security-bucket/security-recommendations/security-recommendations.component';
import { ComplianceBucketComponent } from './compliance-bucket/compliance-bucket.component';
import { ComplianceOverviewComponent } from './compliance-bucket/compliance-overview/compliance-overview.component';
import { ComplianceManagePolicyComponent } from './compliance-bucket/compliance-manage-policy/compliance-manage-policy.component';
import { ComplianceReportsComponent } from './compliance-bucket/compliance-reports/compliance-reports.component';
import { ApplicationMonitoringMetricsUsageComponent } from './application-monitoring-bucket/application-monitoring-metrics-usage/application-monitoring-metrics-usage.component';
import { OnboardingProcessComponent } from './components/onboarding-process/onboarding-process.component';
import { ClosePageComponent } from './components/close-page/close-page.component';
import { A3sSupportComponent } from './components/a3s-support/a3s-support.component';
import { AzureSupportComponent } from './components/azure-support/azure-support.component';
import { SapSupportComponent } from './components/sap-support/sap-support.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
import { ActivitiesViewComponent } from './components/activities-view/activities-view.component';
import { CreateA3sSupportTicketComponent } from './components/a3s-support/create-a3s-support-ticket/create-a3s-support-ticket.component';
import { CreateAzureSupportTicketComponent } from './components/azure-support/create-azure-support-ticket/create-azure-support-ticket.component';
import { CreateSapSupportTicketComponent } from './components/sap-support/create-sap-support-ticket/create-sap-support-ticket.component';
import { UserManualComponent} from './components/user-manual/user-manual.component' 
import { ApplicationMonitoringSAPHANAComponent } from './application-monitoring-bucket/application-monitoring-sap-hana/application-monitoring-sap-hana.component';
import { ApplicationMonitoringSapNetweaverComponent } from './application-monitoring-bucket/application-monitoring-sap-netweaver/application-monitoring-sap-netweaver.component';
const routes: Routes = [
    { path: '', redirectTo: 'signIn',pathMatch: 'full' },
    { path: 'msalLoading',  loadChildren: () => import('./msal-module/msal-module.module').then(m => m.MsalModuleModule)},
    { path: 'signIn', component: LoginMsalComponent, data: { title: 'Sign In' } },
    { path: 'a3s',component: NavbarComponent,data: { breadcrumb: 'Home'},
        children: [
            { path: '', redirectTo: 'home',pathMatch: 'full' },
            { path: 'home', canActivate: [NeverActivate, AuthGuard], component: HomeComponent, data: { title: 'Home',breadcrumb: 'Home' } },
            { path: 'diagramTool', canActivate: [NeverActivate,AuthGuard], component: DiagramToolComponent,data: { breadcrumb: 'Architecture Blueprint'},},
            { path: 'accountSettings', canActivate: [NeverActivate, AuthGuard], component: ProfileSettingsComponent, data: {title: 'Account Settings', breadcrumb:  'Account Settings '} },
            { path: 'a3sSupport', component: A3sSupportComponent, data: { title: 'A3S Support',breadcrumb: 'A3S support tickets' } },
            { path: 'a3sSupportCreate', component: CreateA3sSupportTicketComponent, data: { title: 'A3S Support',breadcrumb: 'Create A3S support tickets' } },
            { path: 'azureSupport', component: AzureSupportComponent, data: { title: 'Azure Support' ,breadcrumb: 'Azure support tickets'} },
            { path: 'azureSupportCreate', component: CreateAzureSupportTicketComponent, data: { title: 'Azure Support' ,breadcrumb: 'Create Azure support ticket'} },
            { path: 'sapSupport', component: SapSupportComponent, data: { title: 'Sap Support' ,breadcrumb: 'SAP support tickets'} },
            { path: 'sapSupportCreate', component: CreateSapSupportTicketComponent, data: { title: 'Sap Support' ,breadcrumb: 'Create SAP support tickets'} },
            { path: 'favourites', component: FavouritesComponent, data: { title: 'Favourites',breadcrumb: 'Favourites' } },
            { path: 'activities', component: ActivitiesViewComponent, data: { title: 'Activities',breadcrumb: 'Activities' } },

            { path: 'infraDeploymentBucket', canActivate: [AuthGuard], component: InfraDeploymentBucketComponent, data: { title: 'Infrastructure' },
              children:[ 
                { path: '', redirectTo: 'architectureBlueprint',pathMatch: 'full' },
                { path: 'deployment', canActivate: [NeverActivate,AuthGuard], component: DeploymentComponent,data: { breadcrumb: 'Deployment'},
                  children: [
                    { path: '', redirectTo: 'deployment',pathMatch: 'full' },
                    { path: 'dedicateCompute', canActivate: [AuthGuard], component: DedicateComputeComponent, data: { breadcrumb: 'Dedicate Compute' },
                      children: [
                        { path: 'aci', canActivate: [NeverActivate, AuthGuard], component: AciComponent, data: { breadcrumb: 'Create Azure Container Instance (ACI)' } },
                        { path: 'acr', canActivate: [NeverActivate, AuthGuard], component: AcrComponent, data: { breadcrumb: 'Create Azure Container Registry (ACR)' } },
                        { path: 'linuxVM', canActivate: [NeverActivate, AuthGuard], component: LinuxVmComponent, data: { breadcrumb: 'Create Linux Virtual Machine' } },
                        { path: 'windowsVM', canActivate: [NeverActivate, AuthGuard], component: WindowsVmComponent, data: { breadcrumb: 'Create Windows Virtual Machine' } },
                      ]
                    },
                  ]
                },
                { path: 'businessContinuity', canActivate: [NeverActivate,AuthGuard], component: BusinessContinuityComponent,data: { breadcrumb: 'Business Continuity'}},
                { path: 'assessment', canActivate: [NeverActivate,AuthGuard], component: InfraAssessmentComponent,data: { breadcrumb: 'Assessment'}},
                { path: 'migration', canActivate: [NeverActivate,AuthGuard], component: InfraMigrationComponent,data: { breadcrumb: 'Migration'}},
                { path: 'devops', canActivate: [NeverActivate,AuthGuard], component: InfraDevopsComponent,data: { breadcrumb: 'DevOps'}},
                { path: 'architectureBlueprint', canActivate: [NeverActivate,AuthGuard], component: ArchitectureBlueprintComponent,data: { breadcrumb: ''},
                  children:[
                    { path: '', redirectTo: 'architectureBlueprintList',pathMatch: 'full' },
                    { path: 'architectureBlueprintList', canActivate: [NeverActivate,AuthGuard], component: ArchitectureBlueprintListComponent,data: { breadcrumb: 'Architecture Blueprint'},
                      children:[
                        { path: 'architectureBlueprintSummary', canActivate: [NeverActivate,AuthGuard], component: ArchitectureBlueprintSummaryComponent,data: { breadcrumb: 'Project Summary'}},
                        { path: 'architectureDeploymentStatus', canActivate: [NeverActivate,AuthGuard], component: ArchitectureDeploymentStatusComponent,data: { breadcrumb: 'Deployment status'}},
                        { path: 'createNewProject', canActivate: [NeverActivate,AuthGuard], component: CreateNewBlueprintProjectComponent,data: { breadcrumb: 'Create Project'}},
                      ]
                    },  
                  ]
                },
                { path: 'management', canActivate: [NeverActivate,AuthGuard],  component: ManagementComponent,data: { breadcrumb: 'Infrastructure management'},
                  children:[
                    { path: '', redirectTo: 'overview-mgmt', pathMatch: 'full' },
                    { path: 'overview-mgmt', canActivate: [NeverActivate,AuthGuard], component: OverviewManagementComponent, data: { breadcrumb: ''} },
                  ]
                }
              ]
            },
            
            { path: 'applicationMonitorBucket', canActivate: [AuthGuard], component: ApplicationMonitoringBucketComponent, data: { title: 'Application Health Monitoring' },
              children: [
                { path: '', redirectTo:'dashboard', pathMatch:'full'},
                { path: 'dashboard', canActivate: [NeverActivate,AuthGuard], component: ApplicationMonitoringDashboardComponent,data: { breadcrumb: 'Application Health Monitoring'}},
                { path: 'metricsUsage', canActivate: [NeverActivate,AuthGuard], component: ApplicationMonitoringMetricsUsageComponent,data: { breadcrumb: 'Application Health Monitoring'}},
                // { path: 'usage', canActivate: [NeverActivate,AuthGuard], component: ApplicationMonitoringUsageComponent,data: { breadcrumb: ''}},
                // { path: 'metrics', canActivate: [NeverActivate,AuthGuard], component: ApplicationMonitoringMetricsComponent,data: { breadcrumb: ''}},
                { path: 'alerts', canActivate: [NeverActivate,AuthGuard], component: ApplicationMonitoringAlertsComponent,data: { breadcrumb: 'Application Health Monitoring'}},
                { path: 'saphana', canActivate: [NeverActivate,AuthGuard], component: ApplicationMonitoringSAPHANAComponent,data: { breadcrumb: 'SAP Monitoring'}},
                { path: 'sapNetWeaver', canActivate: [NeverActivate,AuthGuard], component: ApplicationMonitoringSapNetweaverComponent,data: { breadcrumb: 'SAP NetWeaver Monitoring'}},
              ]
            },  
           
          
            { path: 'securityBucket', canActivate: [AuthGuard], component: SecurityBucketComponent, data: { title: 'Security' },
              children: [
                { path: '', redirectTo:'securityPosture', pathMatch:'full'},
                { path: 'securityPosture', canActivate: [AuthGuard], component: SecurityPostureComponent,data: { breadcrumb: 'Security posture'}},
                { path: 'recommendations', canActivate: [AuthGuard], component: SecurityRecommendationsComponent,data: { breadcrumb: 'Recommendations'}},
                { path: 'alertManagement', canActivate: [AuthGuard], component: SecurityAlertManagementComponent,data: { breadcrumb: 'Alert Management'}}
              ]
            },      
            { path: 'complianceBucket', canActivate: [AuthGuard], component: ComplianceBucketComponent, data: { title: 'Compliance' },
              children: [
                { path: '', redirectTo:'overview', pathMatch:'full'},
                { path: 'overview', canActivate: [NeverActivate,AuthGuard], component: ComplianceOverviewComponent,data: { breadcrumb: 'Overview'}},
                { path: 'managePolicy', canActivate: [NeverActivate,AuthGuard], component: ComplianceManagePolicyComponent,data: { breadcrumb: 'Manage Policy'}},
                { path: 'reports', canActivate: [NeverActivate,AuthGuard], component: ComplianceReportsComponent,data: { breadcrumb: 'Reports'}}
              ]
            },
            // {path: 'costMonitoringBucket', canActivate: [AuthGuard], component: CostMonitoringOptimizationBucketComponent, data: { title: 'Cost Monitoring & Optimization' },
            //     children: [
            //       { path: '', redirectTo:'overview', pathMatch:'full'},
            //       { path: 'overview', canActivate: [NeverActivate,AuthGuard], component: CostMonitoringOverviewComponent,data: { breadcrumb: 'Cost overview'}},
            //       { path: 'billing', canActivate: [NeverActivate,AuthGuard], component: CostMonitoringBillingComponent,data: { breadcrumb: 'Billing'}},
            //       { path: 'aiPowered', canActivate: [NeverActivate,AuthGuard], component: CostMonitoringAiPoweredComponent,data: { breadcrumb: 'A3S Recommendations'}},
            //     ]
            // },
            // { path: 'governanceBucket', canActivate: [AuthGuard], component: GovernanceBucketComponent, data: { title: 'Governance' },
            //   children: [
            //     { path: '', redirectTo:'overviewPolicies', pathMatch:'full'},
            //     { path: 'overviewPolicies', canActivate: [NeverActivate,AuthGuard], component: OverviewEnterprisePolicyComponent,data: { breadcrumb: 'Enterprise Policy'}},
            //     // { path: 'overview', canActivate: [NeverActivate,AuthGuard], component: GovernanceOverviewComponent,data: { breadcrumb: 'Governance overview'}},
            //     // { path: 'enterprisePolicy', canActivate: [NeverActivate,AuthGuard], component: EnterprisePolicyComponent,data: { breadcrumb: 'Enterprise policies'}},
            //     { path: 'identityAccessManagement', canActivate: [NeverActivate,AuthGuard], component: IdentityAccessManagementComponent,data: { breadcrumb: 'Identity & Access Management'}},
            //     { path: 'auditingLogging', canActivate: [NeverActivate,AuthGuard], component: AuditingLoggingComponent,data: { breadcrumb: 'Auditing & Logging'}}
            //   ]
            // },
          
          //   { path: 'infraHealthMonitorBucket', canActivate: [AuthGuard], component: InfraHealthMonitoringBucketComponent, data: { title: 'Infrastructure Health Monitoring', breadcrumb: 'Infrastructure Health Monitoring' },
          //   children: [
          //     { path: '', redirectTo:'dashboard', pathMatch:'full'},
          //     { path: 'dashboard', canActivate: [NeverActivate,AuthGuard], component: HealthMonitoringDashboardComponent,data: { breadcrumb: ''},
          //       children:[{ path: 'vmPowerBiDashboard', canActivate: [AuthGuard,PowerBiDeactivateGuard],canDeactivate: [PowerBiDeactivateGuard], component: VmPowerbiDashboardComponent,data: { breadcrumb: 'VM Dashboard'}},
          //     ]},
          //     { path: 'insights', canActivate: [NeverActivate,AuthGuard], component: HealthMonitoringInsightsComponent,data: { breadcrumb: ''}},
          //     { path: 'metrics', canActivate: [NeverActivate,AuthGuard], component: HealthMonitoringMetricsComponent,data: { breadcrumb: ''}},
          //     { path: 'networkMonitoring', canActivate: [NeverActivate,AuthGuard], component: HealthMonitoringNetworkComponent,data: { breadcrumb: ''}},
          //     { path: 'alerts', canActivate: [NeverActivate,AuthGuard], component: HealthMonitoringAlertsComponent,data: { breadcrumb: ''}}
          //   ]
          // },    

            {
              path: 'infraHealthMonitorBucket',
              canActivate: [AuthGuard],
              data: { title: 'Infrastructure Health Monitoring'},
              loadChildren: () => import('./infra-health-monitoring-bucket/infra-health-monitoring-bucket.module').then(m => m.InfraHealthMonitoringBucketModule)
            },
            {
              path: 'dataMigrationBucket',
              canActivate: [AuthGuard],
              data: { title: 'Data & AI' },
              loadChildren: () => import('./data-msg-bucket/data-msg-bucket.module').then(m => m.DataMsgBucketModule)
            },
            {
              path: 'costMonitoringBucket',
              canActivate: [AuthGuard],
              data: { title: 'Cost Monitoring & Optimization' },
              loadChildren: () => import('./cost-monitoring-optimization-bucket/cost-monitoring-optimization-bucket.module').then(m => m.CostMonitoringOptimizationBucketModule)
            },
            {
              path: 'governanceBucket',
              canActivate: [AuthGuard],
              data: { title: 'Governance' },
              loadChildren: () => import('./governance-bucket/governance-bucket.module').then(m => m.GovernanceBucketModule)
            },
            // {
            //   path: 'infraDeploymentBucket',
            //   canActivate: [AuthGuard],
            //   data: { title: 'Infrastructure' },
            //   loadChildren: () => import('./infra-deployment-bucket/infra-deployment-bucket.module').then(m => m.InfraDeploymentBucketModule)
            // },

        ]
    },
    
    { path: 'register', component: RegistrationComponent, data: { title: 'Registration' } },
    { path: 'closePage', component: ClosePageComponent, data: { title: '' } },
    { path: 'onboarding', component: OnboardingProcessComponent, data: { title: 'OnBoarding' } },
    { path: 'UserManual', component:  UserManualComponent, data: { title: 'A3S UserManual' } },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
