<div class="introduction">
   
  

             <p class="heading" id="2">Create New Alert Group </p>  
             <ol class="mb-0" >
                 <li>Click <b>Alerts.</b></li>
                 <li>Click <b>New alert rule. </b> </li>
                 <li>Provide the needed inputs in the Alert rule side sheet. </li>
                 <li>Click <b>Create. </b></li>
             </ol>
             <img style="width:50%"src="assets\user-manual\apps-health-monitoring\Side-sheet-alert.svg" >
             <figcaption class="caption col-md-6" style="padding-left: 150px;"> Create rule side sheet</figcaption>

</div>
