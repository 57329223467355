import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-architecture-blueprint-summary',
  templateUrl: './architecture-blueprint-summary.component.html',
  styleUrls: ['./architecture-blueprint-summary.component.scss']
})
export class ArchitectureBlueprintSummaryComponent implements OnInit {
  data: any={
    projectName:null,
    subscriptionName:null,
    properties:{
      timestamp:null
    },
    status:null
  };
  tableList: any=[];
  datasort: string;
  resourceFailedCount:any=0;
  fromDiagramTool:boolean=false;

  constructor(private util:UtilService,route: ActivatedRoute) { 
    if (route.queryParams['value'].data && JSON.parse(route.queryParams['value'].data)) {
      var data = JSON.parse(route.queryParams['value'].data);
      this.fromDiagramTool=data['fromDiagramTool']?_.cloneDeep(data['fromDiagramTool']):false;
      if(data["projectName"]){
        this.getProjectData(data["projectName"]);
      }
      
      // this.tableList = JSON.parse(this.data.Table_Info);
    }
  }

  ngOnInit(): void {
  }

  getProjectData(projectName){
    this.util.handleRequest('get','a3s_architectureBlueprint_getBlueprintProjectByName',[projectName],null,null,null,false).then(res=>{
      this.data=res;
      this.getResourceList(this.data.projectId);
    });
  }


  getResourceList(projectId){
    this.util.handleRequest('get','a3s_architectureBlueprint_getProjectResourceList',[projectId],null,null,null,false).then(res=>{
      console.log('res',res);
      if(!res.length) return;
      this.tableList = res.map((dt) => {
        dt["resourceGroup"] = JSON.parse(dt.resourceData).resourceGroup;
        return dt;
      });
    })
  }

  routeToDiagram(){
    this.util.route(['/a3s/diagramTool'],true,{ data: JSON.stringify(this.data)});
  }

  routeToListPage(){
    this.util.route(['/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList'],false);
  }

  sortAll(value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this.tableList;
    sorted.sort((a, b) => {
        switch (value) {
            case 'status': {
                let x = a.status.toLowerCase();
                let y = b.status.toLowerCase();
                return (x < y) ? num : ((x > y) ? -num : 0)
            }
            case 'deployedOn': {
                let x = a.deployedOn.toLowerCase();
                let y = b.deployedOn.toLowerCase();
                return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this.tableList = sorted;
}

}
