<div class="dashboard-top">
    <div class="flex-custom no-wrap">
      <div class="">
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <div class="ml-auto">
        <div class="main-nav">
          <ul class="nav-wrap m-0" >
            <li class="list-item" [class.active]="currentTab == 'metrics'" (click)="setTab('metrics')">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83333 7.50001C6.29357 7.50001 6.66667 7.87311 6.66667 8.33334V13.3333C6.66667 13.7936 6.29357 14.1667 5.83333 14.1667C5.3731 14.1667 5 13.7936 5 13.3333V8.33334C5 7.87311 5.3731 7.50001 5.83333 7.50001Z" fill="#8995A2"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.83334C10.4602 5.83334 10.8333 6.20644 10.8333 6.66668V13.3333C10.8333 13.7936 10.4602 14.1667 10 14.1667C9.53976 14.1667 9.16667 13.7936 9.16667 13.3333V6.66668C9.16667 6.20644 9.53976 5.83334 10 5.83334Z" fill="#8995A2"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1667 10C14.6269 10 15 10.3731 15 10.8333V13.3333C15 13.7936 14.6269 14.1667 14.1667 14.1667C13.7064 14.1667 13.3333 13.7936 13.3333 13.3333V10.8333C13.3333 10.3731 13.7064 10 14.1667 10Z" fill="#8995A2"/>
                </svg>  
               <span class="menu-name">Metrics</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'usage'" (click)="setTab('usage')">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 1.52502V0.0250244C8.05 0.275024 10 2.37502 10 4.92502C10 5.48189 9.91731 6.01119 9.75192 6.49246L8.38029 5.82751C8.45508 5.55577 8.5 5.27663 8.5 4.97502C8.5 3.22502 7.2 1.77502 5.5 1.52502Z" fill="#8995A2"/>
                    <path d="M1.5 4.97502C1.5 6.92502 3.05 8.47502 5 8.47502C6.1 8.47502 7.1 7.97502 7.75 7.17502L9.05 7.92502C8.1 9.17502 6.65 9.97502 5 9.97502C2.25 9.97502 0 7.72502 0 4.97502C0 2.49047 1.78068 0.416834 4.164 0.0630662L4.17783 1.58776C2.63847 1.9624 1.5 3.33735 1.5 4.97502Z" fill="#8995A2"/>
                </svg>  
                <span class="menu-name">Usage
              </span>
            </li> 
            <li class="list-item" [class.active]="currentTab == 'alerts'" (click)="setTab('alerts')">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.6431 15.5879H6.35685C5.56255 15.5879 4.85292 15.1769 4.45863 14.4884C4.06532 13.8018 4.06955 12.9843 4.46998 12.3017L8.11961 6.07986C8.51625 5.40359 9.22155 5 10.0064 5H10.0082C10.7937 5.00062 11.4991 5.40539 11.895 6.08274L15.5317 12.3046C15.9307 12.9872 15.934 13.8041 15.5405 14.4899C15.146 15.1775 14.4368 15.5879 13.6431 15.5879ZM10.0065 6.38857C9.86827 6.38857 9.52571 6.42707 9.31729 6.78241L5.66766 13.0043C5.45809 13.3616 5.59497 13.6786 5.66353 13.7983C5.73258 13.9189 5.9384 14.1993 6.35685 14.1993H13.6431C14.0612 14.1993 14.267 13.9193 14.3361 13.7989C14.4047 13.6793 14.5417 13.3627 14.3329 13.0054L10.6962 6.78348C10.4881 6.42735 10.1449 6.38868 10.0071 6.38857C10.0069 6.38857 10.0067 6.38857 10.0065 6.38857ZM9.99998 11.6304C10.4793 11.6304 10.8678 12.019 10.8678 12.4983C10.8678 12.9776 10.4793 13.3662 9.99998 13.3662C9.52068 13.3662 9.13212 12.9776 9.13212 12.4983C9.13212 12.019 9.52068 11.6304 9.99998 11.6304ZM10.6943 10.2727V8.85329C10.6943 8.46984 10.3834 8.15901 9.99998 8.15901C9.61653 8.15901 9.30569 8.46984 9.30569 8.85329V10.2727C9.30569 10.6562 9.61653 10.967 9.99998 10.967C10.3834 10.967 10.6943 10.6562 10.6943 10.2727Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Alerts
            </span>
          </li> 
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  <div class="dashboard-main">
    <div class="governance-overview-container">
      <div class="header-container">
        <h2 class="title">{{currentTab | titlecase}}</h2>
      </div>
   
      <app-application-monitoring-metrics *ngIf="currentTab == 'metrics'" [filterObject1]="filterObject"></app-application-monitoring-metrics>
      <app-application-monitoring-usage *ngIf="currentTab == 'usage'" [filterObject1]="filterObject"></app-application-monitoring-usage>
      <app-application-monitoring-alerts *ngIf="currentTab == 'alerts'"></app-application-monitoring-alerts>
    </div>

  
  </div>