<div class="introduction">
 

            <p class="heading" id="1">Identity Security  </p>
            <p class="mb-0" >Shows the activities performed by identities, including the anomalous ones.  </p>
            <img class="image" src="assets\user-manual\security\Identity-security.svg" >
            <figcaption class="caption">Identity security dashboard </figcaption>

            <p class="heading" style="font-size: 32px;" id="2">Processes Used Explicit Credentials  </p>
            <p class="mb-0" >Shows the number of processes that attempted to login into an account by explicitly specifying credentials of that account. </p>
            <img class="image" style="width:50%"  src="assets\user-manual\security\Tile.svg" >
            <figcaption class="caption col-md-6">Security - Explicit credentials </figcaption>

            <p class="heading" style="font-size: 32px;" id="3">Remotely Logged Accounts  </p>
            <p class="mb-0" >Shows all accounts that were logged in remotely, for example, outside office network using VPN connection, etc. </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\Tile(1).svg" >
            <figcaption class="caption col-md-6">Security - remote logging </figcaption>
            <p class="heading" style="font-size: 32px;" id="4">Top 5 Long Running Processes  </p>
            <p class="mb-0" >List of processes started in a chosen timeframe. </p>
            <img class="image"  src="assets\user-manual\security\Tile(2).svg" >
            <figcaption class="caption">Security - processes started recently</figcaption>
            <p class="heading" style="font-size: 32px;" id="5">Accounts with Failed Logins  </p>
            <p class="mb-0" >List of accounts with failed login and the number of attempts. </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\Tile(3).svg" >
            <figcaption class="caption col-md-6">Security - failed logon </figcaption>
            <p class="heading"  style="font-size: 32px;"id="6">Failed Login Attempts by the Same account </p>
            <p class="mb-0" >Shows the number of failed login attempts by the same account. </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\Tile(4).svg" >
            <figcaption class="caption col-md-6">Failed login attempts </figcaption>
            <p class="heading" style="font-size: 32px;" id="7">Multiple Password Reset </p>
            <p class="mb-0" >Table displays number of times one’s password is reset. First and last password reset time. </p>
            <img class="image"  src="assets\user-manual\security\Row.svg" >
            <figcaption class="caption ">Multiple password resets </figcaption>
            <p class="heading" style="font-size: 32px;" id="8">New Processes Observed  </p>
            <p class="mb-0" >These new processes could begin new programs installed on hosts; however, normally in 
                stable environments, these new processes could provide an indication of an 
                unauthorized/malicious binary that has been installed and run. </p>
                <img class="image"  src="assets\user-manual\security\Tile(5).svg" >
                <figcaption class="caption "> New processes observed</figcaption>
                <p><b>Note:</b></p>
                <ul>
                    <li>Click refresh <img class="ellipis"  src="assets\user-manual\security\Vector.svg" > icon to refresh the information.  </li>
                    <li>Click table <img  class="ellipis" src="assets\user-manual\security\Vector1.svg" > icon to view & download information in tabular format. </li>
                    <li>Click clock <img  class="ellipis" src="assets\user-manual\security\Vector2.svg" > icon to set the time range. </li>
                </ul>
         
       
</div>     
        