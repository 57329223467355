<div class="introduction">
    <p class="heading" id="0">Cost Monitoring & Optimization</p>
        <p class="para">Continual monitoring of the cost incurred by the resources deployed on Azure across 
                 various projects in A3S key is to ensure that your projects are provisioned optimally with needed 
                 resources and that there is no wastage of resources leading to avoidable higher costs. Along with 
                 constant monitoring, A3S also provides the functionality to set budgets, get alerts when cost crosses 
                 a threshold and finally, it provides smart recommendations to optimize resource deployment to save as
                  much cost as possible.  </p>
                  <p>Cost Monitoring & Optimization bucket comprises of the following: </p>
       <ol>
                   <li>Overview. </li>
                   <li>Cost analysis. </li>
                   <li>Optimize.  </li>
                   <li>Budget & Alerts. </li>
                   <li>Billing. </li>
       </ol>
</div>
