
<div class="">
    <div class="header-container">
      <h2 class="title">DevOps</h2>
  </div>
  
   <!-- Development Process -->
   <div class="mt-4 pt-2">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <img src="./assets/comingsoon.png" height="auto" width="350">
        <h4 class="mt-4 text-muted">Coming Soon</h4>
      </div>
  </div>
  </div>
  