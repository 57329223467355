
 <div class="body-content">
  <div class="main col-md-12" >
    <router-outlet></router-outlet>
    <app-breadcrumbs></app-breadcrumbs>

    <div class="header-container">
      <h2 class="title">Azure Support Tickets</h2>
    </div>
    
    <div class="enlist-srch w-50 mt-3">
      <img src="assets/icons/search.png" />
      <input placeholder="Search Tickets" autocomplete="off" (keyup)="search($event.target.value)" [(ngModel)]="searchText" class="form-control"
        type="text" id="projectSearch">
    </div>
    <span class="blur-text">{{ticketList.length == dummyTicketList.length ? getTableText('single') : getTableText('multiple') }}</span>
    
    <div class="custom-fixed-tables">
      <table class="table">
          <thead>
            <tr>
              <th>
                <div class="flex-custom no-wrap">
                  <span>Title</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('problemSummary')">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                    <span>Ticket ID</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('ticketId')">
                </div>
            </th>
              <th>
                  <div class="flex-custom no-wrap">
                      <span>Support Interest</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortAll('supportInterest')">
                  </div>
              </th>              
              <th>
                <div class="flex-custom no-wrap">
                  <span>Status</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('status')">
                </div>
              </th>
             <th>
                  <div class="flex-custom no-wrap">
                      <span>Created By</span>
                      <img class="sort" src="assets/icons/sort.png" (click)="sortAll('createdBy')">
                  </div>
              </th>
              <th style="border-right: none !important;">
                <div class="flex-custom no-wrap">
                  <span>Created on (UTC)</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('createdAt')">
                </div>
              </th>
              <th style="border-left: none !important;" ></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let list of ticketList.reverse()" class="cursor-pointer">
              <td>{{list?.problemSummary}}</td>
              <td>{{list?.ticketId}}</td>
              <td>{{list?.supportInterest}}</td>
              <td>
                <div class="chip" [ngClass]="getChipClass(list?.status)">
                  <img [src]="getStatusChipImg(list?.status)">
                  {{list?.status}}
                </div>
              </td>
              <td>{{list?.createdBy}}</td>
              <td>{{list?.createdAt}} </td>
           </tr>
          </tbody>
        </table>
    </div>
    
    <div class="fixed-btm-btn" >
      <button class="btn btn-btn-primary btn-SM" (click)="route()"><div class="create-plus-btn"> <span class="plus">+</span><span>Create new ticket</span> </div>  </button>
    </div>
  </div>
</div>
 
   