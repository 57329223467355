// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    domain: mode().domain,
    a3sUrl: mode().a3sUrl,
    serverUrl: mode().serverUrl,
    synapseWorkspace: mode().synapseWorkspace,
    powerBI: {
        reportBaseURL: 'https://app.powerbi.com/reportEmbed',
        qnaBaseURL: 'https://app.powerbi.com/qnaEmbed',
        tileBaseURL: 'https://app.powerbi.com/embed',
        groupID: 'b8c53977-2b38-4a36-8dad-f752355d8003',
        reportID: 'cc26df7b-8918-409d-9058-ec9e0c4762ee',
        oJReportID: 'aed57fbb-39d3-4017-a418-585d485ea175'
    }

};


export function mode(): any {
    if (window.location.hostname.indexOf('localhost') > -1) {
      return {
        domain: 'http://localhost:4200',
        a3sUrl: 'http://localhost:4200/signIn',
      //  serverUrl: 'http://localhost:3000/',
        serverUrl: 'https://a3s-backend-node-dev.azurewebsites.net/',
        // serverUrl: 'https://a3s-backend-node.azurewebsites.net/',
        // serverUrl: 'https://a3s-backend-node-qa.azurewebsites.net/',
        synapseWorkspace:{
          server: 'a3s-synapse-workspace-dev',
          rName: 'a3s-dai-dev-rg'
        },
      }
    } 
    else if(window.location.hostname.indexOf('a3s-prod-forntend-dev.azurewebsites.net') > -1){
        return {
            domain: 'https://a3s-prod-forntend-dev.azurewebsites.net',
            a3sUrl: 'https://a3s-prod-forntend-dev.azurewebsites.net/signIn',
            serverUrl: 'https://a3s-backend-node-dev.azurewebsites.net/',
            synapseWorkspace:{
              server: 'a3s-synapse-workspace-dev',
              rName: 'a3s-dai-dev-rg'
            },
          }
    }
    else if(window.location.hostname.indexOf('a3s-prod-forntend-qa.azurewebsites.net') > -1){
        return {
            domain: 'https://a3s-prod-forntend-qa.azurewebsites.net',
            a3sUrl: 'https://a3s-prod-forntend-qa.azurewebsites.net/signIn',
            serverUrl: 'https://a3s-backend-node-qa.azurewebsites.net/',
            synapseWorkspace:{
              server: 'a3s-synapse-workspace-qa',
              rName: 'a3s-dai-qa-rg'
            },
          }
    }
    else{
        return {
            domain: 'https://app.azureautomation.ai',
            a3sUrl: 'https://app.azureautomation.ai/signIn',
            serverUrl: 'https://a3s-backend-node.azurewebsites.net/',
            synapseWorkspace:{
              server: 'a3s-synapse-workspace',
              rName: ' a3s-dai-prod-rg'
            },
          }
    }
  }

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
