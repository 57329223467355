<div class="row">
    <div class="col-md-12 padding-div pr-0">
      <div class="graph-div" style="padding-right: 12px;">
          <div class="heading">
              <div class="d-flex">
                  <div class="about">
                      <h4>Deploy Additional Standards</h4>
                  </div>
                  <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                  matTooltipShowDelay="500" (click)="getAllData()"><img
                      src="/assets/health-monitoring/refresh.svg" alt=""></span>
              </div>
          </div>
          <ng-container *ngIf="!showLoader && deployStandardList?.length">
            <div class="enlist-srch w-50 mt-3" [formGroup]="searchFormGroup">
                <img src="assets/icons/search.png" />
                <input placeholder="Search standard(s)" formControlName="searchText" autocomplete="off"  (keyup)="searchTableData()" class="form-control" type="text" id="projectSearch">
            </div>
            <div class="custom-fixed-tables m-0 mt-3">
            <table class="table">
                <thead>
                <tr>
                    <th class="width-col-30">
                        <div class="flex-custom no-wrap">
                            <span>Name</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deployStandardList','name')">
                        </div>
                    </th>
                    <th style="border-right: none;">
                        <div class="flex-custom no-wrap">
                            <span>Description</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deployStandardList','description')">
                        </div>
                    </th>
                    <th style="border-left: none;" class="width-col-10"></th>
                </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let row of deployStandardList">
                        <td>{{row.name}}</td>
                        <td matTooltip="{{row.description}}" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">{{row.description}}</td>
                        <td>
                            <button class="btn btn-btn-primary pull-right mb-2" *ngIf="!row?.status" (click)="setStandard(row)">Deploy</button>
                            <button class="btn btn-outline pull-right mb-2" *ngIf="row?.status == 'succeeded'" (click)="openConfirmModal(deleteConfirm,row)">Remove</button>
                            <ng-container *ngIf="row?.status == 'failed'">
                                <span class="action-icon pull-right ml-2" matTooltip="{{row?.errorMessage}}" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500"><img  src="/assets/compliance/error-info-2.svg" alt=""></span>
                                <button class="btn btn-outline pull-right mb-2">Redeploy</button>
                            </ng-container>
                            
                            <button class="btn btn-loader pull-right mb-2" *ngIf="['creating','waiting','deploying'].indexOf(row?.status) > -1">
                                <img src="assets/onBoard/btn-loading.svg" class="mr-2">Creating...
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
          </ng-container>
    
          <div class="loader-gif" *ngIf="showLoader">
              <img src="assets/icons/blue-loader.gif"/>
              <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!showLoader && !deployStandardList?.length">
              <img src="/assets/health-monitoring/no-data.svg" alt="">
              <p class="text-14-22 bold-600 mt-3">No data found</p>
          </div>
      </div>
  </div>
</div>


<div class="sidebar-right" *ngIf="showDeployComplainceStandard">
    <div>
        <div class="header">
            <div class="d-flex">
              <h4>Deploy Additional Standards<br> <span class="blur-text">{{currentStandardData?.name}}</span></h4>
              <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showDeployComplainceStandard=false;">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body" [formGroup]="formGroup">
            <div class="form-group">
                <div class="label-div">
                    <label class="text-weight control-label">Basics</label>
                </div>
                <div class="label-div">
                    <label class="control-label">Subscription<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select subscription for the compliance standard." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="subscriptionList" bindLabel="subscriptionName" bindValue="subscriptionId" formControlName="subscription" id="subscription" [clearable]="false" (change)="getManagedIdentityList()">
                    </ng-select>
                </div> 
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label ">Assignment name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The standard name must be unique within the subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" id="assignmentName" autocomplete="off" name="assignmentName" formControlName="assignmentName"  required>
                </div>             
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label">Location<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Managed identity used for the deployment requires location to be selected." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="locationList" id="location" bindLabel="displayName" bindValue="name" formControlName="location"  [clearable]="false">
                    </ng-select>
                </div> 
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label">Lock Assignment</label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-md-3 radio-label mb-0" style="padding-left: 0px !important;"><input type="radio" name="lockAssignment" value="dontLock" formControlName="lockAssignment"><span>Don't Lock</span></label>
                    <label class="col-md-3 radio-label mb-0" style="padding-left: 0px !important;"><input type="radio" name="lockAssignment" value="dontDelete" formControlName="lockAssignment"><span>Do Not Delete</span></label>
                    <label class="col-md-3 radio-label mb-0" style="padding-left: 0px !important;"><input type="radio" name="lockAssignment" value="readOnly" formControlName="lockAssignment"><span>Read Only</span></label>
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label" style="color: #3E4852;">Resources created by artifacts can be modified but cannot be deleted. Not all resource types support locking. Locks may take up to 30 mins to be enforced.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label">Managed Identity<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Managed identity is used to deploy the artifacts specified by the compliance standard definition." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-md-3 radio-label mb-0" style="padding-left: 0px !important;"><input type="radio" name="managedIdentity" value="systemAssigned" formControlName="managedIdentity" (change)="updateValidator()"><span>System assigned</span></label>
                    <label class="col-md-3 radio-label mb-0" style="padding-left: 0px !important;"><input type="radio" name="managedIdentity" value="userAssigned" formControlName="managedIdentity" (change)="updateValidator()"><span>User assigned</span></label>
                </div>  
            </div>
            <div class="form-group" *ngIf="formGroup?.value&&formGroup?.value?.managedIdentity=='systemAssigned'">
                <div class="label-div d-flex" style="background: #F4FBFF;border: 1px solid #4AB5FB;box-sizing: border-box;border-radius: 2px;">
                    <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/blueprint/popup-error-info.png" alt=""></label>
                    <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 8px 4px 0px !important;color: #21262C;">
                        By clicking "Deploy" with a system assigned identity, you agree to grant the Compliance Standard service temporary Owner access to this subscription so that we can properly deploy all Artifacts. We will automatically remove this access when the standard assignment process is finished.</label>
                </div>            
            </div>
            <div class="form-group" *ngIf="formGroup?.value&&formGroup?.value?.managedIdentity=='userAssigned'">
                <div class="label-div">
                    <label class="control-label">User assigned managed identity<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The managed identity is used to deploy or redeploy the resources defined in the compliance standard." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="managedIdentityList" id="managedIdentity" bindLabel="name" bindValue="name" formControlName="userAssignedIdentity"  [clearable]="false" (change)="checkManageIdentityAccess($event)"></ng-select>
                    <p class="text-danger" *ngIf="formGroup.get('userAssignedIdentity')?.errors?.incorrect">This managed identity require owner access on the subscription.</p>
                </div> 
            </div> 


            <div class="form-group">
                <div class="label-div">
                    <label class="control-label" style="font-weight:600;font-size:13px;">Compliance Standard Parameters</label>
                </div>
            </div> 
            <div class="form-group" *ngFor="let data of currentGlobalParameters">
                <div class="label-div">
                    <label class="control-label">{{data?.values?.metadata?.displayName}}<span class="mandatory" *ngIf="data?.isMandatory">*</span><img  *ngIf="data?.values?.metadata?.description" class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="{{data?.values?.metadata?.description}}" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
                <div class="input-box" *ngIf="data?.values?.inputType == 'text'">
                    <input type="text" class="form-control" [(ngModel)]="data.newValue" id="assignmentName" autocomplete="off" name="assignmentName" [ngModelOptions]="{ standalone : true }">
                </div>     
                <div class="input-box margin-multi-select" *ngIf="data?.values?.inputType == 'multiDropdown'">
                    <ng-select [items]="data.values.allowedValues"  id="global_{{i}}" multiple="true" placeholder="0 selected" [selectableGroup]="true" [closeOnSelect]="false" [searchable]="true" name="global_{{i}}" [clearable]="false" [(ngModel)]="data.newValue" [ngModelOptions]="{ standalone : true }">
                        <ng-template ng-multi-label-tmp let-items="items">
                          <span>{{items?.length}} selected</span>
                        </ng-template>
                        <!-- <ng-template ng-header-tmp *ngIf="subscriptionList?.length">
                          <div class="checkbox-cs">
                            <input id="item-all" name="item-all" type="checkbox" class="form-check-inline" (change)="onSelectAll($event,'subscription')" [checked]="checkedAll('subscription')"/> Select All
                          </div>
                        </ng-template> -->
                        <ng-template ng-option-tmp let-item="item"  let-index="index" let-item$="item$">
                          <div class="checkbox-cs">
                            <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }" [checked]="item$.selected"/> {{item}}
                          </div>
                        </ng-template>
                      </ng-select>
                </div>       
                <div class="input-box" *ngIf="data?.values?.inputType == 'dropdown'">
                    <ng-select [items]="data.values.allowedValues" id="global_{{i}}" [clearable]="false" placeholder="0 selected" [(ngModel)]="data.newValue" [ngModelOptions]="{ standalone : true }"></ng-select>
                </div>             
            </div>


            <div class="form-group">
                <div class="label-div">
                    <label class="control-label" style="font-weight:600;font-size:13px;">Artifacts</label>
                </div>
            </div> 
            <div class="custom-fixed-tables m-0 mt-3">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="border-right: none;width: 25px;"></th>
                      <th style="border-left: none;width: 450px;"> 
                          <div class="flex-custom no-wrap">
                              <span>Artifact & Parameter</span>
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Parameter Value</span>
                          </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr class="cursor-pointer" (click)="showData=!showData">
                        <img [src]="showData?'assets/compliance/up-arrow.svg':'assets/compliance/down-arrow.svg'" alt="" style="padding-top: 11px;">
                        <td class="bold-600">Subscription</td>
                      </tr>
                      <ng-container *ngIf="showData">
                        <ng-container *ngFor="let data of currentArtifacts;index as i">
                            <tr id="accordion_policy_0" class="cursor-pointer" (click)="data.showDetail=!data.showDetail">
                                <img [src]="data.showDetail?'assets/compliance/up-arrow.svg':'assets/compliance/down-arrow.svg'" alt="" style="padding-top: 11px;padding-left: 28px;" [ngStyle]="{'visibility':data?.parameters?.length?'visible':'hidden'}">
                                <td class="bold-600">{{data?.artifactDisplayName}}</td>
                            </tr>
                            <ng-container *ngIf="data.showDetail">
                                <tr *ngFor="let par of data?.parameters;index as j">
                                    <img [src]="data.showDetail?'assets/compliance/up-arrow.svg':'assets/compliance/down-arrow.svg'" alt="" style="padding-top: 11px;visibility: hidden">
                                    <td>
                                        {{par?.values?.metadata?.displayName}}<span class="mandatory" *ngIf="par?.isMandatory">*</span><img  *ngIf="par?.values?.metadata?.description" class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="{{par?.values?.metadata?.description}}" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
                                    </td>
                                    <td>
                                        <div class="input-box mb-2" *ngIf="par?.values?.inputType == 'text'">
                                            <input type="text" class="form-control" [(ngModel)]="par.newValue" id="assignmentName" placeholder="Set value(s)" autocomplete="off" name="assignmentName" [ngModelOptions]="{ standalone : true }">
                                        </div>     
                                        <div class="input-box margin-multi-select mb-2" *ngIf="par?.values?.inputType == 'multiDropdown'">
                                            <ng-select [items]="par.values.allowedValues"  id="global_{{i}}" multiple="true" appendTo="body" placeholder="0 selected" [selectableGroup]="true" [closeOnSelect]="false" [searchable]="true" name="global_{{i}}" [clearable]="false" [(ngModel)]="par.newValue" [ngModelOptions]="{ standalone : true }">
                                                <ng-template ng-multi-label-tmp let-items="items">
                                                  <span>{{items?.length}} selected</span>
                                                </ng-template>
                                                <!-- <ng-template ng-header-tmp *ngIf="subscriptionList?.length">
                                                  <div class="checkbox-cs">
                                                    <input id="item-all" name="item-all" type="checkbox" class="form-check-inline" (change)="onSelectAll($event,'subscription')" [checked]="checkedAll('subscription')"/> Select All
                                                  </div>
                                                </ng-template> -->
                                                <ng-template ng-option-tmp let-item="item"  let-index="index" let-item$="item$">
                                                  <div class="checkbox-cs" matTooltip="{{item}}" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
                                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }" [checked]="item$.selected"/> {{item}}
                                                  </div>
                                                </ng-template>
                                              </ng-select>
                                        </div>       
                                        <div class="input-box mb-2" *ngIf="par?.values?.inputType == 'dropdown'">
                                            <ng-select [items]="par.values.allowedValues" id="global_{{i}}" [clearable]="false" placeholder="0 selected" appendTo="body" [(ngModel)]="par.newValue" [ngModelOptions]="{ standalone : true }"></ng-select>
                                        </div>      
                                    </td>
                                </tr>
                            </ng-container>
                          </ng-container>
                      </ng-container>
                  </tbody>
              </table>
              </div>
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="formGroup?.invalid || checkDisabled()" (click)="deployStandard()">Deploy</button>
        </div>
    </div>
</div>


<ng-template #deleteConfirm>
    <div class="medium-popup-wrapper">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">Remove assignment</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>Are you surely want to remove the assignment?</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="removeAssignment()">Remove</button>
        </div>
      </div>
    </div>
</ng-template>