import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AngularMaterialModule } from 'src/app/angular-material/angular-material.module';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-compliance-manage-policy',
  templateUrl: './compliance-manage-policy.component.html',
  styleUrls: ['./compliance-manage-policy.component.scss']
})
export class ComplianceManagePolicyComponent implements OnInit {
  @ViewChild("deleteAssignPolicyTemplate") public deleteAssignPolicyTemplate: TemplateRef<any>;
  @ViewChild("deleteInitiativeTemplate") public deleteInitiativeTemplate: TemplateRef<any>;
  @ViewChild("disableStandardTemplate") public disableStandardTemplate: TemplateRef<any>;
  @ViewChild("enableStandardTemplate") public enableStandardTemplate: TemplateRef<any>;
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  searchKey:any;
  resourceList: any = []; 
  datasort: string;
  alertsData: any={
    tableData:[],
    showLoader:false
  };
  subsName: any;
  subscriptionList: any=[];
  alertResourceAffected: any=[];
  showAlertDetails:boolean=false;
  rowData: any;
  currentTab='ISO';
  azureResource: any={};
  showLoader={
    overall:true
  };
  overallResourceOptions:any;
  subscriptionInfra: Subscription;
  securityCenter={
    showLoader:true,
    tableData:[]
  };
  industryStandards={
    showLoader:true,
    tableData:[]
  }
  assignmentDeaultDetails: any={};
  assignmentModifiedDetails: any={};
  showAssignPolicy:boolean=false;
  assignDetails: any={};
  deleteDetails:any={};
  standardsValue: any={
    showLoader:true,
    tableData:[]
  };
  nonCompliance:any= {};
  scopeSelection:any= {};
  exclusioneSelection:any= {};
  resourceGroupList: any=[];
  exemptionCategoryList:any=[
    {name:"Waiver"},{name:"Mitigated"}
  ]
  showExemption: boolean=false;
  exemptionDetails:any= { };
  exemptionData: any=[];
  
  constructor(private util:UtilService,private modalService: NgbModal) { }

  ngOnInit(): void {
    $('.main-content').addClass('grey-bg');
    // Project Filter Subscription
    this.subscriptionInfra = this.util.getObservable().subscribe((res) => {
      if(res.currentFilteredProject){
        this.triggerFilter(res.currentFilteredProject,'project')
     //   this.currentDashboard = res.currentFilteredProject.currentResourceType ? res.currentFilteredProject.currentResourceType : 'overview'
       // this.currentDashboard == 'overview' ? this.getAllData() : '';
       this.getAllData();
        this.util.setObservable('currentFilteredProject',null)
      }
    });
    // this.getAllApis();
  }

  ngOnDestroy() {
    $('.main-content').removeClass('grey-bg');
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

  async getAllData(){
    this.industryStandards.tableData=[{
      name:'Azure Security Benchmark',
      descrption:'Track Azure Security Benchmark controls in the Compliance Dashboard, based on a recommended set of policies and assessments.',
      flag:'Out of box',
      status:'Disable'
    },
    {
      name:'PCI DSS 3.2.1',
      descrption:'Track PCI-DSS v3.2.1:2018 controls in the Compliance Dashboard, based on a recommended set of policis and assessments.',
      flag:'Out of box',
      status:'Disable'
    },
    {
      name:'ISO 27001',
      descrption:'Track ISO 27001 controls in the Compliance Dashboard, based on a recommended set of policies and assessments.',
      flag:'Out of box',
      status:'Disable'
    },
    {
      name:'SOC TSP',
      descrption:'Track SOC TSP controls in the Compliance Dashboard, based on a recommended set of policies and assessments.',
      flag:'Out of box',
      status:'Disable'
    },
    {
      name:'ISO 27001:2013',
      descrption:'Track ISO 27001:2013 controls in the Compliance Dashboard, based on a recommended set of policies and assessments.',
      flag:'Out of box',
      status:'Disable'
    }];
    this.getAssignmentName();
    this.getStandardsValue();
    this.getAllSubscriptions();
  }

  // getResources(avoidloader?:boolean){
  //   let subsId = 'cb9f6601-c384-4019-a885-22467caeec6d'
  //   return this.util.handleRequest('get','a3s_governance_getResourcesInEnv',[subsId],null,null,null,true).then(res=>{
  //     if(!res || !res.body) return 
  //     console.log('LIST OF RESOURCES',res);
  //     this.resourceList = res.body.value || []
  //     this.resourceList.forEach(e=>{
  //       e['subsName'] = e.id.split('/')[2]
  //       e['rgName'] = e.id.split('/')[4]
  //     })
  //   })
  // }

  filterSubscriptionName(subscriptionId){
    let findObj=this.subscriptionList.find(dt=>dt.subscriptionId==subscriptionId);
    return findObj?findObj.displayName:'--';
  }

  getComplianceStandardData(){ 
    const tempSubList=this.filterSection('subscription');//_.cloneDeep(this.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      //"query": "securityresources  | where type =~ 'microsoft.security/locations/alerts'  | where properties.Status in ('Active')  | where properties.Severity in ('Low', 'Medium', 'High')  | extend SeverityRank = case(    properties.Severity == 'High', 3,    properties.Severity == 'Medium', 2,    properties.Severity == 'Low', 1,    0    )    | extend severity = properties.Severity    | extend displayName = properties.AlertDisplayName    | extend status = properties.Status    | extend id2 = reverse(properties.ResourceIdentifiers[0].AzureResourceId) | extend splited = split(id2,'/')[0] | extend id1 = reverse(splited) | project-rename affectedResource = id1    | extend splited2 = split(id2,'/')[1] | extend id3 = reverse(splited2) | project-rename resourceType = id3    | extend id5 = properties.ResourceIdentifiers[0].AzureResourceId | extend splited3 = split(id5,'/')[4] | project-rename RG = splited3    | extend tolower_affectedResource = tolower(affectedResource)    |extend activityStartTime = properties.StartTimeUtc  | sort by  SeverityRank desc, tostring(properties.SystemAlertId) asc  | project severity,displayName,affectedResource,resourceType,RG,activityStartTime,status,tolower_affectedResource,properties "
      "query":"securityresources | where type == 'microsoft.security/regulatorycompliancestandards/regulatorycompliancecontrols'             | extend standardId = replace( '-', ' ', extract(@'/regulatoryComplianceStandards/([^/]*)', 1, id))            | extend dimensions = parsejson(properties)            | extend state = tolower(dimensions['state'])            | where state != 'unsupported'            | project state, standardId, name            | summarize status_list = make_set(tolower(state)) by standardId, name            | summarize passedControls = countif(status_list !contains 'Failed'), totalControls = dcount(name) by standardId            | order by (passedControls * 1.0 / iff(totalControls == 0, 1, totalControls)) asc"
  }  
  this.securityCenter.tableData=[];
  this.azureResource={};
  this.securityCenter.showLoader=true;
    this.util.handleRequest('post','a3s_compliance_getDataByQuery',[],body,null,null,true).then(res=>{
      this.showLoader.overall=false;
      this.securityCenter.showLoader=false;
      if(!res || !res.body) return 
      console.log('DATA',res);
      this.securityCenter.tableData = res.body.data || [];
      if(this.securityCenter.tableData.length){
        this.azureResource=this.securityCenter.tableData.find(dt=>dt.standardId=="Azure Security Benchmark");
      }
    },err=>{
      this.showLoader.overall=false;
      this.securityCenter.showLoader=false;
    }).catch(err=>{
      this.showLoader.overall=false;
      this.securityCenter.showLoader=false;
    });
  }
  
  getAssignmentName(){ 
    const tempSubList=this.filterSection('subscription');//_.cloneDeep(this.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
  }  
  this.securityCenter.tableData=[];
  this.securityCenter.showLoader=true;
    this.util.handleRequest('post','a3s_compliance_getAssignmentName',[],body,null,null,true).then(async res=>{
      
      if(!res || !res.body){ 
        this.showLoader.overall=false;
        this.securityCenter.showLoader=false;
        return ;
      }
      console.log('DATA',res);
      let assignmentList = res.body.value || [];
      let findObj=assignmentList.find(dt=>dt.properties&&dt.properties.policyDefinitionId=="/providers/Microsoft.Authorization/policySetDefinitions/1f3afdf9-d0c9-4c3d-847f-89da613e70a8");
      if(findObj){
        await this.getModifiedParametersValue(findObj.name);
        this.getDefaultParametersValue("1f3afdf9-d0c9-4c3d-847f-89da613e70a8")
      }else{
        this.showLoader.overall=false;
        this.securityCenter.showLoader=false;
      }

    },err=>{
      this.showLoader.overall=false;
      this.securityCenter.showLoader=false;
    }).catch(err=>{
      this.showLoader.overall=false;
      this.securityCenter.showLoader=false;
    });
  }

  async getModifiedParametersValue(policyAssignmentName){ 
    let tempSubList=this.filterSection('subscription');
    let body ={
      "subscriptions":tempSubList[0].subscriptionId,
      "policyAssignmentName":policyAssignmentName
    } 
    await this.util.handleRequest('post','a3s_compliance_getModifiedParametersValue',[],body,null,null,true).then(res=>{
      
      if(!res || !res.body){ 
        return ;
      }
      console.log('DATA',res);
      this.assignmentModifiedDetails = res.body;
    });
  }

  getDefaultParametersValue(policyDefinitionId){ 
    this.util.handleRequest('get','a3s_compliance_getDefaultParametersValue',[policyDefinitionId],null,null,null,true).then(res=>{
      
      if(!res || !res.body){ 
        this.showLoader.overall=false;
        this.securityCenter.showLoader=false;
        return ;
      }
      console.log('DATA',res);
      let assignmentDeaultDetails = res.body;  
      this.assignmentDeaultDetails=assignmentDeaultDetails;
      this.compareAssignmentDetails();    
      this.calCount(assignmentDeaultDetails);
    },err=>{
      this.showLoader.overall=false;
      this.securityCenter.showLoader=false;
    }).catch(err=>{
      this.showLoader.overall=false;
      this.securityCenter.showLoader=false;
    });
  }

  compareAssignmentDetails() {
   if(this.assignmentModifiedDetails.properties.parameters){
    let keys=Object.keys(this.assignmentModifiedDetails.properties.parameters);
    keys.forEach(dt=>{
      this.assignmentDeaultDetails.properties.parameters[dt]=this.assignmentModifiedDetails.properties.parameters[dt];
    });
   }
  }
  
  calCount(assignmentDetails) {
    let auditCount=0,denyCount=0,disableCount=0,exemptedCount=0;
    let parameters=assignmentDetails.properties.parameters;
    let policyDefinitions=assignmentDetails.properties.policyDefinitions;
    policyDefinitions.forEach(dt=>{
      if(dt.parameters&&dt.parameters.effect&&dt.parameters.effect.value&&dt.parameters.effect.value.split("'")[1]){
        let paramVal=parameters[dt.parameters.effect.value.split("'")[1]];
        if(paramVal.defaultValue=="AuditIfNotExists"||paramVal.defaultValue=="audit"||paramVal.defaultValue=="Audit"){
          auditCount+=1;
        }else if(paramVal.defaultValue=="Disabled"||paramVal.defaultValue=="disabled"){
          disableCount+=1;
        }else if(paramVal.defaultValue=="deny"||paramVal.defaultValue=="Deny"){
          denyCount+=1;
        }else if(paramVal.defaultValue=="exempted"||paramVal.defaultValue=="Exempted"){
          exemptedCount+=1;
        }
      }
    })
    let rowData={
      displayName:assignmentDetails.properties.displayName,
      auditCount,
      disableCount,
      denyCount,
      exemptedCount
    }
    this.securityCenter.tableData=[rowData];
    this.showLoader.overall=false;
    this.securityCenter.showLoader=false;
  }

  getStandardsValue(){ 
    this.standardsValue.tableData = [];
    this.standardsValue.showLoader=true;
    let body ={
      "policySetDefinitions":["cf25b9c1-bd23-4eb6-bd2c-f4f3ac644a5f","03055927-78bd-4236-86c0-f36125a10dc9","3937f550-eedd-4639-9c5e-294358be442e","4c4a5f27-de81-430b-b4e5-9cbd50595a87","1a5bb27d-173f-493e-9568-eb56638dde4d","a169a624-5599-4385-a696-c8d643089fab","3e0c67fc-8c7c-406c-89bd-6b6bdc986a22","89c6cddc-1c73-4ac1-b19c-54d1a15a42f2","d1a462af-7e6d-4901-98ac-61570b4ed22a","b5629c75-5c77-4422-87b9-2509e680f8de","612b5213-9160-4969-8578-1518bd2a000c","179d1daa-458f-4e47-8086-2a68d0d6c38f","d5264498-16f4-418a-b659-fa7ef418175f","e95f5a9f-57ad-4d03-bb0b-b1d16db93693"]
    } 
    this.util.handleRequest('post','a3s_compliance_getStandardValue',[],body,null,null,true).then(res=>{
      this.standardsValue.showLoader=false;
      if(!res || !res.body){ 
        return ;
      }
      console.log('DATA',res);
      this.standardsValue.tableData = res.body||[];
    });
  }

  getExemption(){ 
    let tempSubList=this.filterSection('subscription');
    this.util.handleRequest('get','a3s_compliance_getExemption',[tempSubList[0].subscriptionId,this.assignDetails.name],null,null,null,false).then(res=>{
      
      if(!res || !res.body){ 
        return ;
      }
      console.log('DATA',res);
      this.exemptionData = res.body.value||[];
    });
  }

  addStandard(row){
    this.assignDetails.editFlag=false;
    this.showAssignPolicy=true;
    this.assignDetails=row;
    this.assignDetails.policyEnforcement='enabled';
    this.assignDetails.location="East US";
    let tempSubList=this.filterSection('subscription');
    this.assignDetails.addStandard=true;
    this.assignDetails.subscriptionName=this.filterSubscriptionName(tempSubList[0].subscriptionId);
    this.assignDetails.subscriptionId=tempSubList[0].subscriptionId;
  }

  createAssignment(){
    this.assignDetails.editFlag=false;
    this.showAssignPolicy=true;
    this.assignDetails=_.cloneDeep(this.assignmentDeaultDetails);
    this.assignDetails.policyEnforcement='enabled';
    this.assignDetails.location="East US";
    let tempSubList=this.filterSection('subscription');
    this.assignDetails.subscriptionName=this.filterSubscriptionName(tempSubList[0].subscriptionId);
    this.assignDetails.subscriptionId=tempSubList[0].subscriptionId;
    this.assignDetails.parameterList=[];
    this.assignDetails.complianceList=[];
  }

  editAssignment(row){
    this.showAssignPolicy=true;
    this.assignDetails=_.cloneDeep(this.assignmentDeaultDetails);
    this.assignDetails.policyEnforcement='enabled';
    this.assignDetails.editFlag=true;
    this.assignDetails.location=this.assignDetails.location?this.assignDetails.location:"East US";
    let tempSubList=this.filterSection('subscription');
    this.assignDetails.subscriptionName=this.filterSubscriptionName(tempSubList[0].subscriptionId);
    this.assignDetails.subscriptionId=tempSubList[0].subscriptionId;
    let parameters=this.assignDetails.properties.parameters;
    let policyDefinitions=this.assignDetails.properties.policyDefinitions;
    let list=[];
    let complianceList=[];
    policyDefinitions.forEach(dt=>{
      if(dt.parameters&&dt.parameters.effect&&dt.parameters.effect.value&&dt.parameters.effect.value.split("'")[1]){
        let paramVal=parameters[dt.parameters.effect.value.split("'")[1]];
        if(paramVal.allowedValues){
          list.push({
            defaultValue:paramVal.defaultValue,
            listVal:paramVal.allowedValues,
            metadata:paramVal.metadata,
            paramName:dt.parameters.effect.value.split("'")[1]
          });
          complianceList.push({
            metadata:paramVal.metadata,
            policyDefinitionReferenceId:dt.policyDefinitionReferenceId
          })
        }
      }
    });
    this.assignDetails.parameterList=_.cloneDeep(list);
    this.assignDetails.complianceList=_.cloneDeep(complianceList);
  }

  createExemption(row){
    this.showExemption=true;
    let tempSubList=this.filterSection('subscription');
    this.exemptionDetails=this.exemptionData&&this.exemptionData[0]?this.exemptionData[0]:{
    }
    this.exemptionDetails.subscriptionName=this.filterSubscriptionName(tempSubList[0].subscriptionId);
    this.exemptionDetails.subscriptionId=tempSubList[0].subscriptionId;
    this.exemptionDetails.exemptionCategory='Waiver';
  }  

  saveExemption(){
    this.showExemption=false;
    let tempSubList=this.filterSection('subscription');
    let reqBody={
      "properties": {
          "displayName": this.assignDetails.properties.displayName,//"UK OFFICIAL and UK NHS",
          "policyDefinitionId": this.assignDetails.id,//"/providers/Microsoft.Authorization/policySetDefinitions/3937f550-eedd-4639-9c5e-294358be442e",
        "policyDefinitionReferenceIds": [ // empty array if user selects all policies
            // "useServicePrincipalToProtectSubscriptionsMonitoring",
            // "resolveLogAnalyticsHealthIssuesMonitoring"
        ],
        "exemptionCategory": this.exemptionDetails.exemptionCategory, // Waiver, Mitigated
        "description": this.exemptionDetails.properties.description,//"sample-description", // it should be empty if user does not put anything
        "expiresOn": "2021-08-27T18:30:00Z", // == null if user does not select expiry date
        "metadata": this.exemptionDetails.properties.metadata
      }
    }
    this.util.handleRequest('put','a3s_compliance_saveExemption',[tempSubList[0].subscriptionId,this.assignDetails.name],reqBody,null,null,false).then(res=>{
      if(!res || !res.body){ 
        return ;
      }
      if(this.assignDetails.addStandard){
        this.getStandardsValue();
      }else{
      this.getAssignmentName();
      }
      console.log('DATA',res);
    });
  }

  saveNonCompliance(){
    this.assignDetails.nonComplianceShow=false;
    this.assignDetails.compliance.push({message:this.assignDetails.description});
  }

  async getResourceGroup(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.showAssignPolicy?this.assignDetails.subscriptionId:this.exclusioneSelection.subscription.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,false).then(async res=>{
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];
      });
  }
  
  async chooseScope(){
    this.scopeSelection={
      subscription:{
        subscriptionId:this.assignDetails.subscriptionId,
        displayName:this.assignDetails.subscriptionName
      },
      resourceGroup:this.assignDetails.resourceGroup
    };
    this.assignDetails.scopeShow=true;
    await this.getResourceGroup();
  }

  saveScope(){
    this.assignDetails.subscriptionId=this.scopeSelection.subscription.subscriptionId;
    this.assignDetails.subscriptionName=this.scopeSelection.subscription.displayName;
    this.assignDetails.resourceGroup=this.scopeSelection.resourceGroup
    this.assignDetails.scopeShow=false;
  }

  async chooseExclusions(){
    this.exclusioneSelection={
      subscription:{
        subscriptionId:this.assignDetails.subscriptionId,
        displayName:this.assignDetails.subscriptionName
      }
    };
    await this.getResourceGroup();
    this.exclusioneSelection.nonScopeList=this.assignDetails.nonScope?this.assignDetails.nonScope:[];
    this.assignDetails.exclusionsShow=true;
  }

  addSelectedScope(){
    this.exclusioneSelection.nonScopeList.push({
      subscription:this.exclusioneSelection.subscription,
      resourceGroup:_.cloneDeep(this.exclusioneSelection.resourceGroup),
      resource:_.cloneDeep(this.exclusioneSelection.resource),
    })
  }

  remove(i){
    this.exclusioneSelection.nonScopeList.splice(i,1);
  }

  saveExclusions(){
    this.assignDetails.nonScope=this.exclusioneSelection.nonScopeList;
    this.assignDetails.exclusionsShow=false;
  }

  editMessages(){
    let findObj=this.assignDetails.complianceList.find(dt=>!dt.metadata.policyDefinitionReferenceId);
    if(findObj){
      this.assignDetails.description=findObj.metadata.description;
    }else{
      this.assignDetails.description='';
    }
    this.assignDetails.nonComplianceShow=true;
  }

  saveNonComplianceMessage(){
    this.assignDetails.complianceList[this.nonCompliance.index].metadata.description=this.nonCompliance.description;
    this.assignDetails.nonComplianceShow=false;
  }

  editMessage(i){
    this.nonCompliance={
      description:this.assignDetails.complianceList[i].metadata&&this.assignDetails.complianceList[i].metadata.description?_.cloneDeep(this.assignDetails.complianceList[i].metadata.description):'',
      index:i
    };
  }

  deleteAssigment(row){
    this.deleteDetails=row;
    this.openModal(this.deleteAssignPolicyTemplate);
  }

  deleteInitiativeManual(row){
    this.deleteDetails=row;
    this.openModal(this.deleteInitiativeTemplate);
  }

  disableStandard(row){
    this.deleteDetails=row;
    this.openModal(this.disableStandardTemplate);
  }

  enableStandard(row){
    this.deleteDetails=row;
    this.openModal(this.enableStandardTemplate);
  }

  openModal(template: TemplateRef<any>, showError?: any) {
    this.modalService.open(template);
}

closeModal() {
  this.modalService.dismissAll();
}

  saveAssignPolicy(){
    this.showAssignPolicy=false;
    let tempSubList=this.filterSection('subscription');
    let parameters={};
    this.assignDetails.parameterList.forEach(dt=>{
      parameters[dt.paramName]={
        value:dt.defaultValue
      };
    })
    let compliance=[];
    this.assignDetails.complianceList.forEach(dt=>{
      compliance.push({
        message:dt.metadata.description?dt.metadata.description:dt.description,
        policyDefinitionReferenceId:dt.policyDefinitionReferenceId
      });
    })
    let reqBody={
      "properties": {
          "displayName": this.assignDetails.properties.displayName,//"UK OFFICIAL and UK NHS",
          "policyDefinitionId": this.assignDetails.id,//"/providers/Microsoft.Authorization/policySetDefinitions/3937f550-eedd-4639-9c5e-294358be442e",
          "scope":this.assignDetails.resourceGroup?"/subscriptions/"+this.assignDetails.subscriptionId+'/'+this.assignDetails.resourceGroup:"/subscriptions/"+this.assignDetails.subscriptionId,// "/subscriptions/f1802d20-263d-4900-bc14-a455e5332947",
          "notScopes": [],
          "description": this.assignDetails.properties.description,//"test Description",
          "metadata": {
              "assignedBy": this.assignDetails.properties.metadata.assignedBy,//"Vishal Aggarwal"
          },
          "enforcementMode": "Default",
          "parameters": parameters,
          "nonComplianceMessages": compliance
          // [
          //     {
          //         "message": "dfsvdzxc",
          //         "policyDefinitionReferenceId": null
          //     },
          //     {
          //         "message": "sdfzdasc",
          //         "policyDefinitionReferenceId": "useServicePrincipalToProtectSubscriptionsMonitoring"
          //     },
          //     {
          //         "message": "sdfazCX",
          //         "policyDefinitionReferenceId": "resolveLogAnalyticsHealthIssuesMonitoring"
          //     }
          // ]
      },
      "identity": {
          "type": "SystemAssigned"
      },
      "location": "westus"
  };
    this.util.handleRequest('put','a3s_compliance_saveAssignmentPolicy',[tempSubList[0].subscriptionId,this.assignDetails.name],reqBody,null,null,false).then(res=>{
      if(!res || !res.body){ 
        return ;
      }
      this.getAssignmentName();
      console.log('DATA',res);
    });
  }

  deleteAssignPolicy(){    
    this.util.handleRequest('delete','a3s_compliance_deleteAssignmentPolicy',["1f3afdf9-d0c9-4c3d-847f-89da613e70a8"],null,null,null,false).then(res=>{
      
      if(!res || !res.body){ 
        return ;
      }
      this.getAssignmentName();
      console.log('DATA',res);
    });
  }

  deleteInitiative(){
    let tempSubList=this.filterSection('subscription');
    let policyName='';
    let request=[tempSubList[0].subscriptionId,policyName];
    this.util.handleRequest('delete','a3s_compliance_deleteInitiative',request,null,null,null,false).then(res=>{
      
      if(!res || !res.body){ 
        return ;
      }
     // this.getAssignmentName();
      console.log('DATA',res);
    });
  }


  async getAllSubscriptions(){
    await this.util.handleRequest('get','a3s_compliance_getAllSubscriptions',[],null,null,null,true).then(res=>{
     if(!res || !res.body) return 
     this.subscriptionList=res.body.value||[]
    })
  }


   /******************************************** Filter Functionalities *****************************************/

   triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
    }
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    console.log('Filter Object',this.filterObject)
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    this.getAllData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName];
    sorted.sort((a, b) => {
        switch (value) {
            case 'displayName': {
              let x = a.properties.displayName? a.properties.displayName: ' ';
              let y = b.properties.displayName? b.properties.displayName: ' ';
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName] = sorted;
 }

}