import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { UtilService } from '../../../services/util.service';
import { SecurityBucketState } from '../../../state/security-bucket.state';
import { SecurityQueries } from '../../security-queries'

@Component({
  selector: 'app-identity-security-windows-linux',
  templateUrl: './identity-security-windows-linux.component.html',
  styleUrls: ['./identity-security-windows-linux.component.scss']
})
export class IdentitySecurityWindowsLinuxComponent implements OnInit  {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  detailsPop:any;
  searchKey:any;
  datasort: string;
  refreshedTime: any = {
    failedUserAuthentication: new Date(),
    multiplePasswordReset: new Date(),
    sshBruteForceAttack: new Date(),
    failedUserAuthenticationCard: new Date(),
    multiplePasswordResetCard: new Date(),
    sshBruteForceAttackCard: new Date(),
    processUsedData: new Date(),
    failedLogonCard: new Date(),
    accountWithFailed: new Date()
  }
  options:any ={};
  failedUserAuthentication: any = {
    type:'failedUserAuthentication',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  accountWithFailed: any = {
    type:'accountWithFailed',
    color:['#0B70D5'],
    name:'Top 10',
    showLegend : true,
    legendData:[],
    yAxis:[],
    data:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last3days',
      from:  new Date(new Date().setDate(new Date().getDate() - 3)),
      to: new Date()
    }
  };
  processUsedData = {
    type:'processUsedData',
    data:[],
    tempData:[],
    legendData:[],
    tempTableData:[],
    tableData:[],
    color:['#DB4CB2','#5D43A6', '#19AADE','#FBB040','#1DE4BD','#7D3AC1','#2989E9', '#D40B78', '#FF6F32', '#62C2FB'],
    showLoader : true,
    height:270,
    labelFontSize:25,
    lineHeight:31,
    seriesCenter:['50%','45%'],
    legendType:'scroll',
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  }
  multiplePasswordReset: any = {
    type:'multiplePasswordReset',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  sshBruteForceAttack: any = {
    type:'sshBruteForceAttack',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  failedUserAuthenticationCard: any = {
    type:'failedUserAuthenticationCard',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  multiplePasswordResetCard: any = {
    type:'multiplePasswordResetCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  failedLogonCard: any = {
    type:'failedLogonCard',
    tableData:[],
    tempTableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  selectedData: any;
  timeFilterList: any=[];
  timeFilterFlag: boolean=false;
  currentProject: any;
  subscriptionInfra: Subscription;
  @Select(SecurityBucketState.currentProjectDetail) currentProjectDetail$: Observable<any>;
  assignUserList: any = [];
  
  constructor(private util:UtilService,private modalService: NgbModal,private datePipe:DatePipe) { }

  async ngOnInit() {
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.subscriptionInfra = this.currentProjectDetail$.subscribe(async (res) => {
      if(res){
        this.currentProject = _.cloneDeep({...res});//res.currentFilteredProject;
        this.triggerFilter(this.currentProject,'project')
        await this.getListByPropNameUsingGrpah('users');
        this.getTableData('multiplePasswordReset');
        this.getTableData('failedUserAuthentication');
        this.getTableData('sshBruteForceAttack');
        this.getTableData('multiplePasswordResetCard');
        this.getTableData('failedUserAuthenticationCard');
        this.getTableData('failedLogonCard');
        this.getQueryResult('accountWithFailed');
        this.getProcessUsedData();
      }
    });
  }

  getQueryResult(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.accountWithFailedOption.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))//this.getQuery(type)
    }
    this.getTableData(type);
    this[type].showLoader = true;
    this[type].yAxis=[];
    this[type].data=[];
    this[type].tempData=[];
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this[type].showLoader = false;
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempData.push(ds);
          
        });
      })
      this.filterAllData(type);
    },err=>{
      this[type].showLoader = false;
    })
  }

  async getListByPropNameUsingGrpah(propName){    
    let reqObj={
      fetchPropName:propName
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropNameUsingGrpah',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='users'){
        this.assignUserList=res.value;
       } 
      return  result;
    },err=>{
      if(propName=='users'){
        this.assignUserList=[];
       } 
    });
  }

  ngOnDestroy() {
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

  getProcessUsedData(){
    this.processUsedData.showLoader = true;
    this.processUsedData.data = [];
    this.processUsedData.legendData = [];
    this.processUsedData.tableData=[];
    this.processUsedData.tempData =[];
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    this.getTableData('processUsedData');
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.processUsedData.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this.processUsedData.currentFilter))
    }
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.processUsedData.showLoader = false
      if(!res || !res.body) return 
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this.processUsedData.tempData.push(ds);
          
        });
      })
      this.filterAllData('processUsedData');
    },err=>{
      this.processUsedData.showLoader = false
    })
  }

  filterAllData(type){
    this[type].legendData = [];
    this[type].data=[];
    this[type].yAxis=[];
    this[type].series=[];
    this[type].xAxis=[];
    this[type].tempData.forEach((ds,index1)=>{
      if(type==='processUsedData'&&this.filterCheck(ds[1])){
        let findIndex=this.processUsedData.data.findIndex(dt=>dt.name==ds[0]);
          if(findIndex>-1){
            this.processUsedData.data[findIndex].value=this.processUsedData.data[findIndex].value+ds[2];
          }else{
            this.processUsedData.data.push({value:ds[2],name:ds[0]});
            this.processUsedData.legendData.push({icon:'circle',name:ds[0]})
          }
      } else if(type=='accountWithFailed'&&this.filterCheck(ds[2])){
        this[type].yAxis.push(ds[0]);
        this[type].data.push(ds[3]);
      }
    })
  }

  getTableData(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": this.getTableQuery(type)
    }
    this[type].tableData=[];
    this[type].tempTableData=[];
    this[type].showLoader = true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      if(!res || !res.body) {
        this[type].showLoader = false;
        return 
      }
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempTableData.push(ds);
        });
      })
      this.filterTableData(type);
      this[type].showLoader = false;
    },err=>{
      this[type].showLoader = false;
    })
  }

  getTableQuery(type){
    switch(type){
      case 'failedUserAuthentication':
        return SecurityQueries.failedUserAuthentication.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'sshBruteForceAttack':
        return SecurityQueries.sshBruteForceAttack.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'multiplePasswordReset':
        return SecurityQueries.multiplePasswordReset.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'failedUserAuthenticationCard':
        return SecurityQueries.failedUserAuthentication.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'failedLogonCard':
        return SecurityQueries.accountWithFailedOption.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'multiplePasswordResetCard':
        return SecurityQueries.multiplePasswordReset.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'processUsedData':
        return SecurityQueries.processUsedDataTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
      case 'accountWithFailed':
        return SecurityQueries.accountWithFailedForTable.replace('TIMERANGE',this.util.getInsightsTimeRangeQuery(this[type].currentFilter))
    }
  }

  filterCheck(value){
    return (this.filterObject.subscriptionList.find(dtl=>dtl.checked&&value.split("/")[2]&&dtl.subscriptionId===(value.split("/")[2])) && this.filterObject.resourceGroupList.find(dtl=>dtl.checked&&value.split("/")[4]&&dtl.resourceGroupName.toLowerCase()===(value.split("/")[4]).toLowerCase()) && this.filterObject.resourceList.find(dtl=>dtl.checked&&(value.split("/")[value.split("/").length-1])&&dtl.resourceName.toLowerCase()===(value.split("/")[value.split("/").length-1]).toLowerCase()))||(this.filterObject.subscriptionList.find(dtl=>dtl.checked&&dtl.subscriptionId===(value.split("/")[2])) && !value.split("/")[4])
  }

  filterTableData(type): void {
    this[type].tableData=[];
    if(this[type].tempTableData&&this[type].tempTableData.length){
      this[type].tempTableData.forEach(dt=>{
        let value;
        switch (type) {
          case 'failedUserAuthentication':
          case 'failedUserAuthenticationCard':
            value=dt[8];
            break;
          case 'sshBruteForceAttack':
          case 'sshBruteForceAttackCard':
            value=dt[1];
            break;
          case 'processUsedData':
          case 'accountWithFailed':
          case 'failedLogonCard':
            value=dt[2];
            break;

          default:
            break;
        }
          if(value && this.filterCheck(value)){
            this[type].tableData.push(dt);
          } else if(type === 'multiplePasswordReset'||type === 'multiplePasswordResetCard'){
            let findObj=this.assignUserList.find(user=>user.mail===dt[0]);
            if(findObj){
              dt[8]=findObj.displayName;
              this[type].tableData.push(dt);
            }
          }
      })
      if(type==='failedLogonCard'||type === 'failedUserAuthenticationCard'|| type === 'multiplePasswordResetCard') {
        this.filterDataForCard(type);
      }
    } else {
      this[type].tableData = [];
    }
  }

  filterDataForCard(type): void {
    if(type==='failedLogonCard'||type==='multiplePasswordResetCard') {
      if(this[type].tableData&&this[type].tableData.length){
        this[type].tableData=this[type].tableData.slice(0,4);
      } 
    } else if(type === 'failedUserAuthenticationCard') {
      let duplicateVMCount=[];
      this[type].tableData.forEach(dt=>{
        if(!duplicateVMCount.length) {
          duplicateVMCount.push({name:dt[8].split('/')[dt[8].split('/').length-1], count:1})
        } else {
          let findIndex=duplicateVMCount.findIndex(dVM=>dVM.name.toLowerCase()===dt[8].split('/')[dt[8].split('/').length-1]);
          if(findIndex>-1) {
            duplicateVMCount[findIndex]={...duplicateVMCount[findIndex], count: duplicateVMCount[findIndex].count+1 };
          } else {
            duplicateVMCount.push({name:dt[8].split('/')[dt[8].split('/').length-1], count:1})
          }
        }
      })
      let sorted=duplicateVMCount;
      sorted.sort((a, b) => {
        let x =  a.count;
        let y = b.count;
        return (x < y) ? -1 : ((x > y) ? 1 : 0) ;
      })
      this[type].tableData=sorted;
    }
  }

  getStatusClass(value){
    if(value) {
      let status = value.toLowerCase()
      switch (status) {
        case 'success': return 'success-chip';
        case 'succeeded': return 'success-chip';
        case 'failed': return 'error-chip';
        case 'emergency': return 'emergency-severity-chip';
        case 'error': return 'error-chip';
        case 'warning': return 'warning-chip';
        case 'informational': return 'informational-chip';
        case 'debug': return 'debug-chip';
        case 'alert': return 'alert-chip';
        case 'notice': return 'notice-chip';
        case 'critical': return 'critical-chip';
        default: return 'error-chip';
      }
    }
  }

  openPopup(type:string, list, template?: TemplateRef<any>): void {
    if(type==='sshBruteForceAttack') {
      this.detailsPop=list;
      this.detailsPop[12]=[];
      if(list[6]) {
        let data=JSON.parse(list[6]);
        data.forEach(dt=>{
          this.detailsPop[12].push({
            username:dt
          })
        })
      }
      if(list[7]) {
        let data=JSON.parse(list[7]);
        data.forEach((dt,index)=>{
          this.detailsPop[12][index]= {
            ...this.detailsPop[12][index],
            port:dt
          }
        })
      }
      if(list[5]) {
        let data=JSON.parse(list[5]);
        data.forEach((dt,index)=>{
          this.detailsPop[12][index]= {
            ...this.detailsPop[12][index],
            timeGenerated:dt
          }
        })
      }
      if(list[10]) {
        let data=JSON.parse(list[10]);
        data.forEach((dt,index)=>{
          this.detailsPop[12][index]= {
            ...this.detailsPop[12][index],
            severity:dt
          }
        })
      }
      if(list[8]) {
        let data=JSON.parse(list[8]);
        data.forEach((dt,index)=>{
          this.detailsPop[12][index]= {
            ...this.detailsPop[12][index],
            message:dt
          }
        })
      }
    } else if(type==='multiplePasswordReset') {
      this.detailsPop=list;
      this.detailsPop[9]=[];
      if(list[7]) {
        let data=JSON.parse(list[7]);
        data.forEach(dt=>{
          this.detailsPop[9].push({
            initiatedBy:dt
          })
        })
      }
      if(list[4]) {
        let data=JSON.parse(list[4]);
        data.forEach((dt,index)=>{
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            timeGenerated:dt
          }
        })
      }
      if(list[5]) {
        let data=JSON.parse(list[5]);
        data.forEach((dt,index)=>{
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            result:dt
          }
        })
      }
      if(list[6]) {
        let data=JSON.parse(list[6]);
        data.forEach((dt,index)=>{
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            message:dt
          }
        })
      }
    } else {
      this.detailsPop=list;
    }
    this.modalService.open(template);
  }

  openPopupChart(template?: TemplateRef<any>): void {
    this.modalService.open(template);
  }

  downloadDirectTableView(name,tableId){
    let table=document.getElementById(tableId);
    if(table && tableId === 'tableSSH') {
      this.downloadFileCustom(name);
    }else if(table) {
      this.downloadFile(name,table)
    } else {
      this.downloadFileCustom(name);
    }
  }

  downloadFile(name,tableId){
    this.util.generateCSVForTableData(name,tableId);
  }

  downloadFileCustom(name): void {
    let headers = [];
    let fileName = name;
    let dataList = [];
    if (name == 'MultiplePasswordReset') {
      fileName = 'Multiple Password Reset';
      headers = ['User','Email','Count of Password Resets','First Reset Time (UTC)','Last Reset Time (UTC)'];
    } else if (name == 'SSH Brute Force Attack') {
      headers = ['Source IP','Host','Total Logon Attempts','First Logon Attempt (UTC)','Latest Logon Attempt (UTC)','Time Between Logon Attempts (hours)'];
      this.sshBruteForceAttack.tableData.forEach(data=>{
        const value=[data[0],
        data[1].split('/')[data[1].split('/').length-1],
        data[9],
        this.datePipe.transform(data[3],'MM/dd/yyyy, hh:mm:ss a'),
        this.datePipe.transform(data[4],'MM/dd/yyyy, hh:mm:ss a'),
        data[11]];
        dataList.push(value);
      })
    } else if (name == 'FailedUserAuthentication') {
      fileName = 'Failed User Authentication';
      headers = ['User Account','Source IP','Host','Host IP','Time Generated (UTC)'];
    } else if (name == 'MPRPopup') {
      fileName = 'Multiple Password Reset';
      headers = ['User','Email','Count of Password Resets','Initiated By','Time Generated (UTC)','Result','Message'];
      this.detailsPop[9].forEach(data=>{
        let value=[];
        value.push(this.detailsPop[8]);
        value.push(this.detailsPop[0]);
        value.push(this.detailsPop[1]);
        Object.keys(data).forEach(key=>{
          if(key === 'timeGenerated') {
            value.push(this.datePipe.transform(data[key],'MM/dd/yyyy, hh:mm:ss a'));  
          } else {
            value.push(data[key]);
          }
        })
        dataList.push(value);
      })
    } else if (name == 'sshPopup') {
      fileName = 'SSH Brute Force Attack';
      headers = ['Source IP','Host','Host IP','Total Logon Attempts','Username','Port','Time Generated (UTC)','Severity','Message'];
      this.detailsPop[12].forEach(data=>{
        let value=[];
        value.push(this.detailsPop[0]);
        value.push(this.detailsPop[1].split('/')[this.detailsPop[1].split('/').length-1]);
        value.push(this.detailsPop[2]);
        value.push(this.detailsPop[9]);
        Object.keys(data).forEach(key=>{
          if(key === 'timeGenerated') {
            value.push(this.datePipe.transform(data[key],'MM/dd/yyyy, hh:mm:ss a'));  
          } else {
            value.push(data[key]);
          }
        })
        dataList.push(value);
      })
    }
    this.util.generateCSVForCustom(fileName, headers, dataList);
  }

  // closeModal() {
  //   this.modalService.dismissAll();
  // }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    list.currentFilter.type = value
    switch(list.type){
      case 'failedUserAuthentication' :this.getTableData('failedUserAuthentication');break;   
      case 'multiplePasswordReset' :this.getTableData('multiplePasswordReset');break;   
      case 'sshBruteForceAttack' :this.getTableData('sshBruteForceAttack');break;   
      case 'failedUserAuthenticationCard' :this.getTableData('failedUserAuthenticationCard');break;   
      case 'multiplePasswordResetCard' :this.getTableData('multiplePasswordResetCard');break;   
      case 'failedLogonCard' :this.getTableData('failedLogonCard');break;   
      case 'processUsedData' :this.getProcessUsedData();break; 
      case 'accountWithFailed' :this.getQueryResult('accountWithFailed');break; 
    }
    this.closeFilter()
  }
  
  applyCustomFilter(){
    this.selectedData.currentFilter.type = 'custom';
    switch(this.selectedData.type){
      case 'failedUserAuthentication' :this.failedUserAuthentication = this.selectedData;this.getTableData('failedUserAuthentication');break; 
      case 'multiplePasswordReset' :this.multiplePasswordReset = this.selectedData;this.getTableData('multiplePasswordReset');break; 
      case 'sshBruteForceAttack' :this.sshBruteForceAttack = this.selectedData;this.getTableData('sshBruteForceAttack');break; 
      case 'failedUserAuthenticationCard' :this.failedUserAuthenticationCard = this.selectedData;this.getTableData('failedUserAuthenticationCard');break; 
      case 'multiplePasswordResetCard' :this.multiplePasswordResetCard = this.selectedData;this.getTableData('multiplePasswordResetCard');break; 
      case 'failedLogonCard' :this.failedLogonCard = this.selectedData;this.getTableData('failedLogonCard');break; 
      case 'processUsedData' :this.processUsedData = this.selectedData;this.getProcessUsedData();break; 
      case 'accountWithFailed' :this.accountWithFailed = this.selectedData;this.getQueryResult('accountWithFailed');break; 
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 100;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if(!this.timeFilterFlag){
    this.closeFilter();
    }
    this.timeFilterFlag=false;
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.currentFilter.type);
    return findObj&&findObj.name?findObj.name:''
  }

  applyFilterData(){
    this.filterTableData('failedUserAuthentication');
    this.filterTableData('multiplePasswordReset');
    this.filterTableData('sshBruteForceAttack');
    this.filterTableData('failedUserAuthenticationCard');
    this.filterTableData('multiplePasswordResetCard');
    this.filterTableData('failedLogonCard');
    this.filterAllData('processUsedData');
    this.filterAllData('accountWithFailed');
  }


  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    // this.getAllData()
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    console.log('Filter Object',this.filterObject)
    this.applyFilterData();
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    this.applyFilterData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }


  async refreshData(type){
    switch (type) {
      case 'failedUserAuthentication':{
        this.refreshedTime.failedUserAuthentication = new Date();
        this.getTableData('failedUserAuthentication');
        break;
      }
      case 'multiplePasswordReset':{
        this.refreshedTime.multiplePasswordReset = new Date();
        this.getTableData('multiplePasswordReset');
        break;
      }
      case 'sshBruteForceAttack':{
        this.refreshedTime.sshBruteForceAttack = new Date();
        this.getTableData('sshBruteForceAttack');
        break;
      }
      case 'failedUserAuthenticationCard':{
        this.refreshedTime.failedUserAuthenticationCard = new Date();
        this.getTableData('failedUserAuthenticationCard');
        break;
      }
      case 'multiplePasswordResetCard':{
        this.refreshedTime.multiplePasswordResetCard = new Date();
        this.getTableData('multiplePasswordResetCard');
        break;
      }
      case 'failedLogonCard':{
        this.refreshedTime.failedLogonCard = new Date();
        this.getTableData('failedLogonCard');
        break;
      }
      case 'processUsedData':{
        this.refreshedTime.processUsedData = new Date();
        this.getProcessUsedData();
        break;
      }
      case 'accountWithFailed':{
        this.refreshedTime.accountWithFailed = new Date();
        this.getQueryResult('accountWithFailed');
        break;
      }
    }
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

  sortPopup(tableName,index,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName][index];
    sorted.sort((a, b) => {
        switch (value) {
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName][index] = sorted;
 }

}