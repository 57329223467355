import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  template: `<div echarts [options]="options"></div>`,
})
export class BarChartComponent implements OnChanges {
  options: any = {};
  @Input('data') data: any = {};
  constructor() { }

  ngOnChanges() {
    var data = this.data
    this.options = {
      color: data.color,
      tooltip: {
        // trigger: 'axis',
        // axisPointer: {           
        //     type: 'shadow'        
        // }
      },
      legend: {
        show:false,
        bottom: 0,
        left: 'left',
        data: data.legendData || []
       },
       grid: {
        left: 0,
        right: '2%',
        containLabel: true
      },
      xAxis: {
          type: 'category',
          data: data.xAxis  || [],
          axisTick: {
            show: false
           },
          axisLabel: {
            interval: 0,
            padding: [5, 5, 0, 0],
            fontSize: 10, 
            formatter: function (value) {
              return (value.length > 6 ? (value.slice(0,6)+"...") : value )
            }
            // rotate: 30 //If the label names are too long you can manage this by rotating the label.
          }
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            color: '#333',
            padding: [5, 0, 0, 0],
            fontSize: 10, 
            formatter: function (params) {
              return params + ' %'
            },
          },
      },
      series: [{
        data: data.data || [],
        type: 'bar',
        barWidth: 25,
    }]
    };
  }

}
