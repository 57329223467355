import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-network-security',
  templateUrl: './network-security.component.html',
  styleUrls: ['./network-security.component.scss']
})
export class NetworkSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
