import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, StateToken } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

export interface BudgetList {
  id:string;
  name:string;
  type:string;
  etag?:string;
  properties?:any;
}

export interface SeriesData {
  date:string;
  actualValue:number;
  forecastValue:number;
}

export interface ChartDetails {
  currency: string;
  seriesDetails:SeriesData[];
  last:number;
  past:number;
  maxForecast:number;
}

export interface BudgetRequest {
  subscriptionIds:string[];
  resourceGroup?:string[];
}

export interface subscriptionDetails {
  id:string;
  resourceGroupList?:string[];
}

export interface TimeRange {
  from:string;
  to:string;
}

export interface ChartRequest {
  subscriptionList:subscriptionDetails[];
  granularity:string;
  timePeriod:TimeRange;
  forecastTimePeriod:TimeRange;
}

export interface Threshold {
  thresholdName: string;
  thresholdValue: number;
  thresholdColor: string;
}

export interface ChartOptions {
  option: any;
  currency: string;
  last: number;
  past: number;
  maxForecast: number;
  startDate: string;
  endDate: string;
}

export class BudgetAlertStateModel {
   budgetList: BudgetList[];
   tempBudgetList?: BudgetList[];
   datasort?: string;
   chartData?:ChartDetails;
   chartOptions?:ChartOptions;
}

export class GetBudgetListAction {
  static readonly type = '[BudgetAlert] GetBudgetListAction';
  constructor(readonly payload: BudgetRequest,readonly periods:string) { }
}

export class FilterBudgetListAction {
  static readonly type = '[BudgetAlert] FilterBudgetListAction';
  constructor(readonly payload: BudgetRequest,readonly periods:string) { }
}

export class SortBudgetListAction {
  static readonly type = '[BudgetAlert] SortBudgetListAction';
  constructor(readonly sortFieldName:string) { }
}

export class SearchBudgetAction {
  static readonly type = '[BudgetAlert] SearchBudgetAction';
  constructor(readonly searchTxt:string) { }
}

export class GetChartDataAction {
  static readonly type = '[BudgetAlert] GetChartDataAction';
  constructor(readonly payload:ChartRequest, readonly threshold:Threshold[], readonly resetPeriod:string) { }
}

export class GetChartOptionWithDataAction {
  static readonly type = '[BudgetAlert] GetChartOptionWithDataAction';
  constructor(readonly threshold:Threshold[], readonly resetPeriod:string) { }
}

const BUDGET_ALERT_STATE_TOKEN = new StateToken<BudgetAlertStateModel>('budgetAlert');

@State<BudgetAlertStateModel>({
  name: BUDGET_ALERT_STATE_TOKEN
})
@Injectable()
export class BudgetAlertState {
  constructor(private readonly apiService:ApiService,private http:HttpClient) {}

  @Selector([BudgetAlertState])
  static budgetList(state:BudgetAlertStateModel): BudgetList[] {
    return state?.budgetList
  }
  
  @Selector([BudgetAlertState])
  static tempBudgetList(state:BudgetAlertStateModel): BudgetList[] {
    return state?.tempBudgetList
  }
  
  @Selector([BudgetAlertState])
  static chartData(state:BudgetAlertStateModel): ChartDetails {
    return state?.chartData
  }
  
  @Selector([BudgetAlertState])
  static chartOptions(state:BudgetAlertStateModel): ChartOptions {
    return state?.chartOptions
  }

  @Action(GetBudgetListAction)
  getBudgetList(ctx: StateContext<BudgetAlertStateModel>, action: GetBudgetListAction): Observable<BudgetList[]> {
    let url = this.apiService.getUrl('a3s_costMonitoringOptimization_getBudgets', null);
    return this.http.post<{result:BudgetList[]}>(url, action.payload)
    .pipe(
      tap((response:any) => {
        const budgetList=response.result?response.result.filter(budget=>{
            if(action.payload.subscriptionIds.find(value=>value===budget.id.split('/')[2]) && action.payload.resourceGroup.find(dtl=>dtl.toLowerCase()===(budget.id.split("/")[4]).toLowerCase())){
              return true;
            }else if(action.payload.subscriptionIds.find(value=>value===budget.id.split('/')[2]) && budget.id.split("/")[3]!=='resourceGroups'){
              return true;
            }
          }):[];
        ctx.patchState({
          budgetList,
          tempBudgetList:budgetList
        })
      })
    )
  }

  @Action(FilterBudgetListAction)
  filterBudgetList(ctx: StateContext<BudgetAlertStateModel>, action:FilterBudgetListAction): void {
    if(!ctx.getState()||!ctx.getState().budgetList||!action.payload.subscriptionIds||!action.payload.subscriptionIds.length) {
      ctx.patchState({
        budgetList:[]
      })
    } else {
      const budgetList=ctx.getState().tempBudgetList.filter(budget=>{
        if(action.periods==='All Periods'||(budget.properties&&budget.properties.timeGrain==action.periods)){
          if(action.payload.subscriptionIds.find(value=>value===budget.id.split('/')[2]) && action.payload.resourceGroup.find(dtl=>dtl.toLowerCase()===(budget.id.split("/")[4]).toLowerCase())){
            return true;
          }else if(action.payload.subscriptionIds.find(value=>value===budget.id.split('/')[2]) && budget.id.split("/")[3]!=='resourceGroups'){
            return true;
          }
        }
      });
      ctx.patchState({
        budgetList
      })
    }
  }

  @Action(SortBudgetListAction)
  sortBudgetList(ctx: StateContext<BudgetAlertStateModel>, action:SortBudgetListAction): void {
      let num = 0;
      ctx.getState().datasort === 'desc' ? num = 1 : num = -1;
      let budgetList=[...ctx.getState().budgetList];
      budgetList.sort((a, b) => {
        switch (action.sortFieldName) {
          case 'id': {
            let x = a.id
            let y = b.id
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          case 'timeGrain': {
            let x = a.properties.timeGrain
            let y = b.properties.timeGrain
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          case 'startDate': {
            let x = a.properties?.timePeriod?.startDate 
            let y = b.properties?.timePeriod?.startDate 
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          case 'endDate': {
            let x = a.properties?.timePeriod?.endDate 
            let y = b.properties?.timePeriod?.endDate 
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          case 'amount': {
            let x = a.properties?.amount.toFixed(2) 
            let y = b.properties?.amount.toFixed(2) 
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          case 'forecastSpend': {
            let x = a.properties?.forecastSpend?a.properties?.forecastSpend?.amount.toFixed(2) : '' 
            let y = b.properties?.forecastSpend?b.properties?.forecastSpend?.amount.toFixed(2) :''
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          case 'currentSpend': {
            let x = a.properties?.currentSpend?a.properties?.currentSpend?.amount.toFixed(2) : '' 
            let y = b.properties?.currentSpend?b.properties?.currentSpend?.amount.toFixed(2) :''
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          case 'progress': {
              let x = (!a.properties.amount||(!a.properties.currentSpend||!a.properties.currentSpend.amount))?(0).toFixed(2):((a.properties.currentSpend.amount/a.properties.amount)*100).toFixed(2)
              let y = (!b.properties.amount||(!b.properties.currentSpend||!b.properties.currentSpend.amount))?(0).toFixed(2):((b.properties.currentSpend.amount/b.properties.amount)*100).toFixed(2)
              return (x < y) ? num : ((x > y) ? -num : 0)
          }
          default: {
            let x =  a[action.sortFieldName] ? a[action.sortFieldName]: ' ';
            let y = b[action.sortFieldName] ? b[action.sortFieldName] : ' ';
            return (x < y) ? num : ((x > y) ? -num : 0) ;
          }
          }
        })
        ctx.patchState({
          datasort: ctx.getState().datasort === 'desc' ? 'asc' : 'desc',
          budgetList
        })
  }

  @Action(SearchBudgetAction)
  searchBudget(ctx: StateContext<BudgetAlertStateModel>, action:SearchBudgetAction): void {
    ctx.patchState({
      budgetList:ctx.getState().tempBudgetList.filter(resp => {
          return resp.name.toLowerCase().startsWith(action.searchTxt.toLowerCase())
      })
    })
  }

  @Action(GetChartDataAction)
  getChartData(ctx: StateContext<BudgetAlertStateModel>, action:GetChartDataAction): Observable<ChartDetails> {
    if(action.payload&&action.payload.subscriptionList&&action.payload.subscriptionList.length) {
      let url = this.apiService.getUrl('a3s_costMonitoringOptimization_getGarphicalCostData', null);
      return this.http.post<ChartDetails>(url, action.payload)
      .pipe(
        tap((chartData:ChartDetails) => {
          ctx.patchState({
            chartData
          })
          return ctx.dispatch(new GetChartOptionWithDataAction(action.threshold,action.resetPeriod))
        })
      )
    } else {
      ctx.patchState({
        chartData:null,
        chartOptions:null
      });
    }
  }

  @Action(GetChartOptionWithDataAction)
  getChartOptionWithData(ctx: StateContext<BudgetAlertStateModel>, action:GetChartOptionWithDataAction): void {
    let chartData={...ctx.getState().chartData};
    let xAxisData = chartData.seriesDetails.map(e=>e.date);
    let legendData = [{name:'Actual',icon:'circle'},{name:'Forecast',icon:'circle'}];
    let colorList = ['#0B70D4','#A9D0F6'];//'#62C2FB'
    let series:any = [{
        name:'Actual',
        type:'bar',
        barWidth: 40,
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data:chartData.seriesDetails.map(e=>Number(e.actualValue.toFixed(2)))
      },{
        name:'Forecast',
        type:'bar',
        barWidth: 40,
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data:chartData.seriesDetails.map(e=>Number(e.forecastValue.toFixed(2)))
    }];
    let thresholdValues=[];
    action.threshold.forEach((threshold:Threshold)=>{
      legendData.push({name:threshold.thresholdName, icon:'circle'});
      thresholdValues.push(Number(threshold.thresholdValue));
      colorList.push(threshold.thresholdColor);
      series.push({
        name:threshold.thresholdName,
        type: 'line',
        markLine: {
          data: [
            [{
                xAxis: xAxisData[0],
                yAxis: threshold.thresholdValue,
                symbol: 'none',
                lineStyle: {
                    color: threshold.thresholdColor//'#DF404F'
                },
            },{
                xAxis: xAxisData[xAxisData.length-1],
                yAxis: threshold.thresholdValue,
                symbol: 'none'
            }]
          ]}
        })
    })
    let chartOptions = {
      color:colorList,
      tooltip: {
        show:false
          // trigger: 'axis',
          // axisPointer: {            // Use axis to trigger tooltip
          //     type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
          // }
      },
      legend: {
          data: legendData || [],
          bottom: 10,
          left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        bottom: 62,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: xAxisData || [],
        axisTick: {
          show: false
        },
        axisLabel: {
          // interval: 0,
          formatter: function (value,index) {
            if(action.resetPeriod=='Monthly'||action.resetPeriod=='BillingMonth') {
              let month=xAxisData.filter(date=>moment(date).format('MMM')==moment(value).format('MMM'));
              if(month&&month.length>1&&index!==0){
                return moment(value).format('MMM YYYY')
              }
              return moment(value).format('MMM')
            } else if(action.resetPeriod=='Quarterly'||action.resetPeriod=='BillingQuarter') {
              let splitVal=value.split(" - ");
              return splitVal.map(date=>moment(date).format('MMM YYYY')).join(" - ");
            } else {
              return value
            }
            // (value.length > 10 ? (value.slice(0,10)+"...") : value )
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#333',
          padding: [5, 0, 0, 0],
          fontSize: 10, 
          formatter: function (params) {
            return params
          },
        },
        max: function (value) {          
          return value.max>Math.max(...thresholdValues)?undefined:Math.max(...thresholdValues)+100;
        }
      },
      series: series
    };
    ctx.patchState({
      chartOptions:{
        option:chartOptions,
        currency:chartData.currency,
        last:chartData.last,
        past:chartData.past,
        maxForecast:chartData.maxForecast,
        startDate:action.resetPeriod=='Quarterly'||action.resetPeriod=='BillingQuarter'?xAxisData[0].split(" - ")[0]:action.resetPeriod=='Monthly'||action.resetPeriod=='BillingMonth'?xAxisData[0]:xAxisData[0].split("/")[0],      
        endDate:action.resetPeriod=='Quarterly'||action.resetPeriod=='BillingQuarter'?xAxisData[xAxisData.length-1].split(" - ")[1]:action.resetPeriod=='Monthly'||action.resetPeriod=='BillingMonth'?xAxisData[xAxisData.length-1]:xAxisData[xAxisData.length-1].split("/")[1],      
      }
    })
  }
}
