import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DataMigrationService {

    private url: string = environment.serverUrl;
    private _get_token(): string {
        if (sessionStorage.getItem('data'))
            return `Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`;
        return null;
    }

    constructor(private http: HttpClient) { }

    StartJob(jobName: string, model:any, pipelineName:string) {
        switch (jobName) {
            case 'sourceMetaData': {
                return this.http.post<any>(this.url + `api/a3s/synapse/pipeline/${pipelineName}/run`, model, {//"api/startSourceMetaDataJob"
                    headers: new HttpHeaders({
                        'id': localStorage.getItem('id'),
                        'Authorization': this._get_token()
                    })
                });
            }
            case 'migrateSchema': {
                return this.http.post<any>(this.url + `api/a3s/synapse/pipeline/${pipelineName}/run`, model, {//"api/startMigrateSchemaJob", model, {
                    headers: new HttpHeaders({
                        'id': localStorage.getItem('id'),
                        'Authorization': this._get_token()
                    })
                });
            }
            case 'dataLoader': {
                return this.http.post<any>(this.url + `api/a3s/synapse/pipeline/${pipelineName}/run`, model, {//"api/startDataLoaderJob", model, {
                    headers: new HttpHeaders({
                        'id': localStorage.getItem('id'),
                        'Authorization': this._get_token()
                    })
                });
            }
            case 'sapOddiIngestion': {
                return this.http.post<any>(this.url + "api/runLiveIngestionJob", model, {
                    headers: new HttpHeaders({
                        'id': localStorage.getItem('id'),
                        'Authorization': this._get_token()
                    })
                });
            }
        }

    }

    startSynapsePipeline(){
        let body={name:'oj_sales_predictions'}
        return this.http.post<any>(this.url + "api/runSynapsePipeline", body, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    getJobStatus(id) {
        // let body = { id: id }
        return this.http.get<any>(this.url + `api/a3s/synapse/pipeline/${id}/status`, {//"api/getJobStatus"
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    getPipelineStatus(id) {
        let body = { runId: id }
        return this.http.post<any>(this.url + "api/getSynapsePipelinestatus", body, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    getStatusFromQuery(data) {
        return this.http.post<any>(this.url + "api/getAllTablesStatusFromQueries", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    checkVmDbConnection(data) {
        return this.http.post<any>(this.url + "api/checkVmDbConnection", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    getAllTables(body) {
        return this.http.post<any>(this.url + "api/getAllTables",body, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    getSavedTables() {
        return this.http.get<any>(this.url + "api/getSavedTablesFromQueries", {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    saveConfig(model) {
        return this.http.post<any>(this.url + "api/updateConfig", model, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    saveTables(body) {
        return this.http.post<any>(this.url + "api/saveTargetTables", body, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    getResourceByRg(body){
        return this.http.post<any>(this.url + "api/resources",body, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

    getRgBySubscription(body){
        return this.http.post<any>(this.url + "api/RGList",body, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this._get_token()
            })
        });
    }

}
