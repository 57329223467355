<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':machineLearningmodel.MLSelectKeyShow}">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Machine Learning</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="machineLearningmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(null)">Create new
                    </p>
                </div>
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label for="workspaceName" class="control-label">Workspace name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace name must be unique within your resource group." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.VMname||inputError?.duplicateAi" id="workspaceName" autocomplete="off" [(ngModel)]="machineLearningmodel.resourceName" placeholder="" name="workspaceName" (keyup)="workspaceNameValidation($event,33);">
                <p class="text-danger" *ngIf="inputError?.VMname">Workspace name must be between 3 and 33 characters long. Its first 
                    character has to be alphanumeric, and the rest may contain hyphens and underscores. No whitespace is allowed.</p>
                <p class="text-danger" *ngIf="inputError?.duplicateAi">This workspace name already exists</p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="storageAccount" class="control-label">Storage account<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A storage account is used as the default datastore for the workspace. 
                        You may create a new Azure Storage resource or select an existing one 
                        in your subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="storageAccountList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.storageAccount" name="storageAccount" placeholder="" [clearable]="false">
                        <ng-template ng-label-tmp let-item="item">
                            {{!item?.id?'(new) ':''}}{{item?.name}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{!item?.id?'(new) ':''}}{{item?.name}}
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                    <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [ngbPopover]="createMLStorageAccount" [autoClose]="false" 
                    triggers="manual" [placement]="'bottom left'" popoverClass="my-custom-class" #p="ngbPopover" (click)="p.open()">Create new</p>   -->
                    <!-- container="body" -->
                    <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    popoverTitle="Popover on top"
                    placement="top">Create new</p>  -->
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLStorageAccount"
                    triggers="" placement="bottom" containerClass="custom-popover" #p="bs-popover" (click)="closeOtherPop(undefined,popCR);openPop(p,'createMLStorageAccount');">Create new</p> 
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="keyVault" class="control-label">Key vault<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A key vault is used to store secrets and other sensitive information that is 
                        needed by the workspace. You may create a new Azure Key Vault resource 
                        or select an existing one in your subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="keyVaultList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.keyVault" name="keyVault" placeholder="" [clearable]="false">
                        <ng-template ng-label-tmp let-item="item">
                            {{!item?.id?'(new) ':''}}{{item?.name}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{!item?.id?'(new) ':''}}{{item?.name}}
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                    <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createMLKeyVault()">Create new</p>   -->
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLKeyVault"
                    triggers="" placement="bottom" containerClass="custom-popover" #popKey="bs-popover" [outsideClick]="true" (click)="closeOtherPop(p,popCR);openPop(popKey,'createMLKeyVault')">Create new</p> 
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="applicationInsights" class="control-label">Application insights<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace uses Azure Application Insights to store monitoring information 
                        about your deployed models. You may create a new Azure Application Insights 
                        resource or select an existing one in your subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="applicationInsightsList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.applicationInsights" name="applicationInsights" placeholder="" [clearable]="false">
                        <ng-template ng-label-tmp let-item="item">
                            {{!item?.id?'(new) ':''}}{{item?.name}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{!item?.id?'(new) ':''}}{{item?.name}}
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                    <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createMLApplicationInsights()">Create new</p>   -->
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLApplicationInsights"
                    triggers="" placement="bottom" containerClass="custom-popover" #popAI="bs-popover" [outsideClick]="true" (click)="closeOtherPop(p,popCR);openPop(popAI,'createMLApplicationInsights')">Create new</p> 
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="containerRegistry" class="control-label">Container registry<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A container registry is used to register docker images used in training and deployments. 
                        To minimize costs, a new Azure Container Registry resource is created only after you build
                        your first image. Alternatively, you may choose to create the resource now or select
                        an existing one in your subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="containerRegistryList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.containerRegistry" name="containerRegistry" placeholder="" [clearable]="false"></ng-select>
                </div>  
                <div class="add-another-tag">
                    <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createMLContainerRegistry()">Create new</p>   -->
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLContainerRegistry"
                    triggers="" placement="top" containerClass="custom-popover" container="body" #popCR="bs-popover" (click)="closeOtherPop(p,undefined);openPop(popCR,'createMLContainerRegistry')">Create new</p> 
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="machineLearningmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
            <div *ngIf="showView">
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight">Managed identity<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A managed identity enables Azure resources to authenticate to cloud 
                            services without storing credentials in code. Once enabled, all necessary 
                            permissions can be granted via Azure role-based access control. A workspace 
                            can be given either a system assigned identity or a user assigned identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                        <label for="identityType" class="control-label ">Identity type</label>
                    </div>
                    <div class="input-box radio-flex">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="identityType" [value]="'systemAssigned'" ([ngModel])="machineLearningmodel.resourceData.identityType" [checked]="machineLearningmodel.resourceData.identityType=='systemAssigned'" (change)="machineLearningmodel.resourceData.identityType=$event.target.value;"><span>System assigned identity</span></label>                    
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="identityType" [value]="'userAssigned'" ([ngModel])="machineLearningmodel.resourceData.identityType" [checked]="machineLearningmodel.resourceData.identityType=='userAssigned'" (change)="machineLearningmodel.resourceData.identityType=$event.target.value;"><span>User assigned identity</span></label>                    
                    </div>             
                </div>
                <div class="form-group" *ngIf="machineLearningmodel?.resourceData?.identityType=='userAssigned'">
                    <div class="label-div">
                        <label for="userManagedIdentity" class="control-label">Select user assigned managed identity<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="userManagedIdentityList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.userManagedIdentity" name="userManagedIdentity" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                    <ng-template #createMLUserManagedIdentity>
                        <div class="form-group">
                            <div class="label-div">
                                <label class="control-label text-weight">Create Managed Identity</label>
                                <label for="managedIdentity" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Name of your User Assigned Managed Identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.managedIdentityName||inputError?.managedIdentityDup" id="managedIdentity" autocomplete="off" [(ngModel)]="createMLUMIObj.name" placeholder="" name="managedIdentity" (keyup)="manageIdentityValidation($event,3,128)">
                                <p class="text-danger" *ngIf="inputError?.managedIdentityDup">This managed identity is already existed.</p>
                                <p class="text-danger" *ngIf="inputError?.managedIdentityName">The resource name must begin with a letter or a number 
                                    and with a length between 3 to 128. Can contain '-' and '_'</p>
                                </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLUMIObj.name||inputError?.managedIdentityName||inputError?.managedIdentityDup" (click)="saveSubSideSheet('createMLUserManagedIdentity');popUMI.hide();">Ok</button>
                            <button class="btn btn-outline" (click)="popUMI.hide();">Cancel</button>
                        </div>
                    </ng-template>
                    <div class="add-another-tag">   
                        <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createMLUserManagedIdentity()">Create new</p> -->
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLUserManagedIdentity"
                    triggers="" placement="bottom" containerClass="custom-popover" #popUMI="bs-popover" [outsideClick]="true" (click)="openPop(popUMI,'createMLUserManagedIdentity')">Create new</p> 
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight">Storage account access<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure machine learning allows you to choose between credential-based or identity-based 
                            access when connecting to the default storage account. When using identity-based 
                            authentication, the Storage Blob Data Contributor role must be granted to the 
                            workspace managed identity on the storage account." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                        <label for="accessType" class="control-label ">Storage account access type</label>
                    </div>
                    <div class="input-box radio-flex">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="accessType" [value]="'accessKey'" ([ngModel])="machineLearningmodel.resourceData.accessType" [checked]="machineLearningmodel.resourceData.accessType=='accessKey'" (change)="machineLearningmodel.resourceData.accessType=$event.target.value;"><span>Credential-based access</span></label>                    
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="accessType" [value]="'identity'" ([ngModel])="machineLearningmodel.resourceData.accessType" [checked]="machineLearningmodel.resourceData.accessType=='identity'" (change)="machineLearningmodel.resourceData.accessType=$event.target.value;"><span>Identity-based access</span></label>                    
                    </div>             
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight">Data encryption<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure machine learning service stores metrics and metadata in an Azure Cosmo DB instance where 
                            all data is encrypted ​at rest. By default, the data is encrypted with Microsoft-managed keys. You may 
                            choose to bring your own (customer-managed) keys.​" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                        <label for="encryptionType" class="control-label ">Encryption type</label>
                    </div>
                    <div class="input-box radio-flex">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="encryptionType" [value]="'Microsoft'" ([ngModel])="machineLearningmodel.resourceData.encryptionType" [checked]="machineLearningmodel.resourceData.encryptionType=='Microsoft'" (change)="machineLearningmodel.resourceData.encryptionType=$event.target.value;"><span>Microsoft-managed keys</span></label>                    
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="encryptionType" [value]="'Customer'" ([ngModel])="machineLearningmodel.resourceData.encryptionType" [checked]="machineLearningmodel.resourceData.encryptionType=='Customer'" (change)="machineLearningmodel.resourceData.encryptionType=$event.target.value;"><span>Customer-managed keys</span></label>                    
                    </div>             
                </div>
                <div class="form-group" *ngIf="machineLearningmodel?.resourceData?.encryptionType=='Customer'">
                    <div class="label-div">
                        <label class="control-label" style="margin-bottom: 0px;padding-bottom: 0px !important;">Key vault<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box" style="line-height: 18px !important;" *ngIf="machineLearningmodel?.resourceData?.encrypKeyVault">
                        <label class="control-label" style="padding-left: 0px;">Key vault : <span>{{machineLearningmodel?.resourceData?.encrypKeyVault?.keyVault?.name}}</span></label>
                        <label class="control-label" style="padding-left: 0px;" *ngIf="machineLearningmodel?.resourceData?.encrypKeyVault?.key">Key : <span>{{machineLearningmodel?.resourceData?.encrypKeyVault?.key?.name}}</span></label>
                        <label class="control-label" style="padding-left: 0px;" *ngIf="machineLearningmodel?.resourceData?.encrypKeyVault?.version">Version : <span>{{machineLearningmodel?.resourceData?.encrypKeyVault?.version?.kid.split('/')[machineLearningmodel?.resourceData?.encrypKeyVault?.version?.kid.split('/').length-1]}}</span></label>
                    </div>   
                    <div class="add-another-tag">   
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="selectMLEncrypKeyVault()">Select key</p>
                    </div>
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label class="control-label text-weight">Data impact</label>
                    </div> 
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="highBusiness" id="highBusiness" [(ngModel)]="machineLearningmodel.resourceData.highBusiness" ><span>High business impact workspace</span></label>
                    </div>           
                </div>
                <div class="form-group">
                    <div class="label-div d-flex">
                        <label class="control-label " style="min-width: max-content;">Tag name </label>
                        <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                        </label>
                        <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="machineLearningmodel.resourceData&&machineLearningmodel.resourceData.tagList&&machineLearningmodel.resourceData.tagList.length>1"></label>
                    </div>
                    <div *ngFor="let item of machineLearningmodel.resourceData.tagList;let i = index">
                        <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
    
                        <div class="input-box" style="padding-bottom: 6px;">
                            <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
    
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
    
                        <div class="input-box">
                            <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
    
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="machineLearningmodel.resourceData.tagList.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                        <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                        <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                        <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
    
                    <div class="add-another-tag">
                        <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                    </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="machineLearningmodel.MLSelectKeyShow">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Select a key</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();machineLearningmodel.MLSelectKeyShow=false;">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <!-- <div class="label-div d-flex" *ngIf="keyForMLObj?.keyVault&&!keyList.length&&!versionList.length" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 0px !important;color: #703501;">
                    The operation "List" is not enabled in this key vault's access policy.</label>
            </div> -->
            <div class="form-group">
                <div class="label-div">
                    <label for="keyVault" class="control-label">Key vault<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="keyVaultListForKey" bindLabel="name" [(ngModel)]="keyForMLObj.keyVault" name="keyVault" placeholder="" (change)="keyVaultChangeForKey()" [clearable]="false"></ng-select>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="key" class="control-label">Key<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="keyList" bindLabel="name" [disabled]="!keyForMLObj?.keyVault" [(ngModel)]="keyForMLObj.key"  name="key" placeholder="" (change)="keyChangeForVersion()" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="version" class="control-label">Version<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only the versions meeting following requirement(s) will enabled.
                        Required key type(s): RSA,RSA-HSM,EC,EC-HSM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="versionList" bindLabel="name" [disabled]="!keyForMLObj?.keyVault||!keyForMLObj?.key" [(ngModel)]="keyForMLObj.version" name="version" placeholder="" [clearable]="false">
                        <ng-template ng-label-tmp let-item="item">
                            {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                        </ng-template>
                    </ng-select>
                </div> 
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="!keyForMLObj?.keyVault||!keyForMLObj?.key||!keyForMLObj?.version" (click)="saveSubSideSheet('MLSelectKey')">Select</button>
            </div>
        </div>
    </div>
</div>
<!-- <div class="loader-fs" *ngIf="apiloader">
    <img class="loader"  src="./assets/spinloader.gif">
    </div> -->
<ng-template #createMLStorageAccount>
    <div class="form-group">
        <div class="label-div">
            <label class="control-label text-weight">Create Storage Account</label>
            <label for="nameSA" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
&#8226; Unique across all existing storage account names in Azure
&#8226; Between 3 and 24 characters long
&#8226; Only contain lowercase letters and numbers" matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName||inputError?.duplicateSa" id="nameSA" autocomplete="off" [(ngModel)]="createMLSAObj.name" placeholder="" name="nameSA" (keyup)="storageMLValidation($event,3,24)">
            <p class="text-danger" *ngIf="inputError?.storageAccountName">Storage name must be between 3 and 24 characters and may only 
                contain lowercase letters and numbers.</p>
            <p class="text-danger" *ngIf="inputError?.duplicateSa">Another storage account with this name already exists.</p>
            </div>   
    </div>
    <div class="form-group">
        <div class="label-div">
            <label for="replication" class="control-label">Replication<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The data in your storage account is always replicated to ensure durability 
                and high availability. Choose a replication strategy that best matches you 
                requirements." matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <ng-select [items]="tempStorageSkusList" bindLabel="rdname" bindValue="name" [(ngModel)]="createMLSAObj.replication" name="replication" placeholder="" [clearable]="false"></ng-select>            
        </div>
    </div>
    <div class="d-flex">
        <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLSAObj.name||!createMLSAObj.replication||inputError?.storageAccountName||inputError?.duplicateSa" (click)="saveSubSideSheet('createMLStorageAccount');p.hide();">Save</button>
        <button class="btn btn-outline" (click)="p.hide();">Cancel</button>
    </div>
</ng-template>
<ng-template #createMLKeyVault>
    <div class="form-group">
        <div class="label-div">
            <label class="control-label text-weight">Create Key Vault</label>
            <label for="mlKeyName" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
&#8226; Unique across all existing key vaults in Azure
&#8226; Between 3 and 24 characters long
&#8226; Only contain alphanumeric characters and hyphens
&#8226; Cannot start with a number" matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <input type="text" class="form-control" [class.border-danger]="inputError?.keyVaultNamelen||inputError?.keyVaultName||inputError?.keyVaultDup" id="mlKeyName" autocomplete="off" [(ngModel)]="createMLKeyObj.name" placeholder="" name="mlKeyName" (keyup)="keyMLValidate($event,3,24)">
            <p class="text-danger" *ngIf="inputError?.keyVaultNamelen">The value must be between 3 and 24 characters long.</p>
            <p class="text-danger" *ngIf="inputError?.keyVaultName">Vault name must be between 3-24 alphanumeric characters. 
                The name must begin with a letter, end with a letter or digit, 
                and not contain consecutive hyphens.</p>
            <p class="text-danger" *ngIf="inputError?.keyVaultDup">Another key vault with this name already exists.</p>
            </div>   
    </div>
    <div class="d-flex">
        <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLKeyObj.name||inputError?.keyVaultNamelen||inputError?.keyVaultName||inputError?.keyVaultDup" (click)="saveSubSideSheet('createMLKeyVault');popKey.hide();">Ok</button>
        <button class="btn btn-outline" (click)="popKey.hide();">Cancel</button>
    </div>
</ng-template>
<ng-template #createMLApplicationInsights>
    <div class="form-group">
        <div class="label-div">
            <label class="control-label text-weight">Create Application Insights</label>
            <label for="aiName" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
&#8226; Unique across the resource group
&#8226; Between 1 and 255 characters long
&#8226; Only contain alphanumeric characters, periods, underscores, hyphens, and parenthesis
&#8226; Cannot end with a period" matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <input type="text" class="form-control" [class.border-danger]="inputError?.appInsightName||inputError?.appInsightDup" id="aiName" autocomplete="off" [(ngModel)]="createMLAIObj.name" placeholder="" name="aiName" (keyup)="aiMLValidation($event,255)">
            <p class="text-danger" *ngIf="inputError?.appInsightDup">Application insight name already exists within the same resource group.</p>
            <p class="text-danger" *ngIf="inputError?.appInsightName">The name must contain between 1 to 255 characters inclusive. The name only allows 
                alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot 
                end in a period.</p>
            </div>   
    </div>
    <div class="d-flex">
        <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLAIObj.name||inputError?.appInsightName||inputError?.appInsightDup" (click)="saveSubSideSheet('createMLApplicationInsights');popAI.hide();">Ok</button>
        <button class="btn btn-outline" (click)="popAI.hide();">Cancel</button>
    </div>
</ng-template>
<ng-template #createMLContainerRegistry>
    <div class="form-group">
        <div class="label-div">
            <label class="control-label text-weight">Create Container Registry</label>
            <label for="contaierName" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
&#8226; Unique across all container registries in Azure
&#8226; Between 5 and 50 characters long
&#8226; Only contain alphanumeric characters" matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <input type="text" class="form-control" [class.border-danger]="inputError?.containerRgName||inputError?.containerRgDup" id="contaierName" autocomplete="off" [(ngModel)]="createMLCRObj.name" placeholder="" name="contaierName" (keyup)="containerMLValidation($event,5,50)">
            <p class="text-danger" *ngIf="inputError?.containerRgDup">Another container registry with this name already exists.</p>
            <p class="text-danger" *ngIf="inputError?.containerRgName">Resource names may contain alpha numeric characters 
                only and must be between 5 and 50 characters.</p>
            </div>   
    </div>
    <div class="form-group">
        <div class="label-div">
            <label for="sku" class="control-label">SKU<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="All SKUs provide the same programmatic capabilities. Choosing a higher SKU will provide more performance and scale."  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <ng-select [items]="skuList" bindLabel="name" bindValue="name" [(ngModel)]="createMLCRObj.sku" name="sku" placeholder="" [clearable]="false"></ng-select>            
        </div>
    </div>
    <div class="d-flex">
        <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLCRObj.name||!createMLCRObj.sku||inputError?.containerRgName||inputError?.containerRgDup" (click)="saveSubSideSheet('createMLContainerRegistry');popCR.hide();">Ok</button>
        <button class="btn btn-outline" (click)="popCR.hide();">Cancel</button>
    </div>
</ng-template>