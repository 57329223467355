import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';

@Component({
  selector: 'app-virtual-machine-actions',
  templateUrl: './virtual-machine-actions.component.html',
  styleUrls: ['./virtual-machine-actions.component.scss']
})
export class VirtualMachineActionsComponent implements OnInit {
  @Input() data : any;
  @Input() columns : any;
  @Input() currentProjectData : any;
  @ViewChild("deleteConfirm") deleteConfirmTemplate: TemplateRef<any>;
  @ViewChild("stopVM") stopVMTemplate: TemplateRef<any>;
  @ViewChild('virtualMachineDiskSizePopup') public virtualMachineDiskSizePopup: TemplateRef<any>;
  @ViewChild("vmDetailsTemplate") vmDetailsTemplate: TemplateRef<any>;
  
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  // public min = new Date(this.currentYear,this.currentMonth,this.currentDay+1)
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  vmPublicIpData: any;
  currentTemplate: any;
  currentConnectTab: any = 'RDP'
  currentForm: any;
  vmData: any;
  vmConfigurationData: any;
  vmIpAddressList: any = [];
  timeZoneList: any;
  showSubSidesheet: boolean;
  disableAutoShutdown: boolean = false;
  adminName: any;

  newAzureComputeGallery: any;

  sourceTypeList:any=[
    {name:'None (empty disk)',id:'none'},
    {name:'Snapshot',id:'snapshot'}
  ];
  diskEncryptionTypeList=[
    {id:'EncryptionAtRestWithPlatformKey',name:'(Default) Encryption at-rest with a platform-managed key'},
    {id:'EncryptionAtRestWithCustomerKey',name:'Encryption at-rest with a customer-managed key'},
    {id:'EncryptionAtRestWithPlatformAndCustomerKeys',name:'Double encryption with platform-managed and customer-managed keys'}
  ]

  diskSKUList:any=[
    {id:'Premium_LRS',name:'Premium SSD'},
    {id:'StandardSSD_LRS',name:'Standard SSD'},
    {id:'Standard_LRS',name:'Standard HDD'}
  ];
  maxSharesList=[
    {id:1,name:'1'},
    {id:2,name:'2'},
    {id:3,name:'3'},
    {id:4,name:'4'},
    {id:5,name:'5'}
  ]
  vmDiskSizeList: any = [];
  dummyVMDiskSizeList: any = [];

  inputError= {
    sourceNotValidPortVal : false,
    sourceInvalidPortRange:false,
    sourceOverlap:false,
    managedImageNameEmpty:false,
    managedImageNameLen:false,
    managedImageNameValid:false,
    managedImageNameLastValid:false,
    managedImageNameDupCheck:false,
    newAzureComputeGalleryNameEmpty:false,
    newAzureComputeGalleryNameLen:false,
    newAzureComputeGalleryNameDupCheck:false,
    newAzureComputeGalleryNameValid:false,
    newAzureComputeGalleryNameLastValid:false,
    skuNameEmpty:false,
    skuNameLen:false,
    skuNameValid:false,
    skuNameLastValid:false,
    offerNameEmpty:false,
    offerNameLen:false,
    offerNameValid:false,
    offerNameLastValid:false,
    publisherNameEmpty:false,
    publisherNameLen:false,
    publisherNameValid:false,
    publisherNameLastValid:false,
    diskNameLen:false,
    diskNameDupCheck:false,
    diskNameValid:false,
    diskNameEmpty:false,
    diskNameLastValid:false,
    validVersion:false,
    nameEmpty:false,
    dupCheck:false,
    duplicateResourceName:false,
    VMdescription:false,
    limitNum:false,
    limitNumReplica:false,
    endDateFormat:false,
    validEndDate:false,
    validEmail:false,
  }

  vmDisk:any={
    diskSKU:this.diskSKUList[0],
    performanceTierList:[]
  };
  skuList: any;
  performance: any[];
  saveDisable: boolean;
  popupForm: any;
  diskSizeMsg: boolean;
  diskTier: boolean;
  diskData: any;
  diskName: any;
  encryptionList: any;
  diskEncryption: any;
  storageAccountTypeList : any = [
    {name:'Standard HDD LRS',id:'Standard_LRS'},
    {name:'Premium HDD LRS',id:'Premium_LRS'},
    {name:'Zone-redundant',id:'Zone-redundant'}
  ]
  subscriptionList: any;
  resourceGroupList: any;
  regionList: any;
  showNewAzureComputeGallery: boolean;
  azureComputeGalleryList: any;
  vmImageDefinitionList: any;
  sideSheetForm: FormGroup;
  showExistDataDisk: boolean;
  existDiskData: any;
  selectedType: any;
  currentIndex: any;
  existAllDataList: any;
  subData: any;
  vmImageDefinitionAllList: any;
  generalizedState: any;
  snapshotList: any;


  constructor(
    public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private validatorService : ValidatorsService,
    private _dataMigrationService: DataMigrationService) { }

  ngOnInit(): void {
    
  }

  async action(data,type,index?:any,subData?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'startVM': {
        this.closeModal()
        return this.startVM(data)}
      case 'restartVM': {
        this.closeModal()
        return this.restartVM(data)}
      case 'stopVM': {
        await this.getVMPublicIP(data)
        this.closeModal()
        return this.openModal(this.stopVMTemplate,data)
      }
      case 'powerOffVM':{
        this.closeModal()
        return this.powerOffVM(data)
      }
      case 'deleteVM': {
        this.closeModal()
        return this.openModal(this.deleteConfirmTemplate,data,'deleteVM')}
      case 'deleteDataDisk': {
        this.closeModal()
        return this.openModal(this.deleteConfirmTemplate,data,'deleteDataDisk',index,subData)}
    }
  }
  getResourceTitle(){
    switch(this.selectedType){
      case 'deleteVM': return 'Virtual Machine'
      case 'deleteDataDisk': return 'Data Disk'
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case 'deleteVM': return  this.selectedRow[0]
      case 'deleteDataDisk': return this.subData
    }
  }
  startVM(data){
    let body ={ subscriptionId : data[2],resourceGroupName : data[1],virtualMachineName : data[0] }
    return this.util.handleRequest('post','a3s_management_startVM',[],body,null,null,true).then(res=>{
      this.util.success(data[0] + ' started successfully!')
    },err=>{
      this.util.error(data[0] +' failed to start.')
    })
  }

  restartVM(data){
    let body ={ subscriptionId : data[2],resourceGroupName : data[1],virtualMachineName : data[0] }
    return this.util.handleRequest('post','a3s_management_restartVM',[],body,null,null,true).then(res=>{
      this.util.success(data[0] + ' restarted successfully!')
    },err=>{
      this.util.error(data[0] +' failed to restart.')
    })
  }

  async stopVMAction(data){
    if(this.selectedRow.reserveIp){
     await this.reservePublicIp(data)
    }
    let body ={ subscriptionId : data[2],resourceGroupName : data[1],virtualMachineName : data[0] }
    return this.util.handleRequest('post','a3s_management_stopVM',[],body,null,null,true).then(res=>{
      this.util.success(data[0] + ' stopped successfully!')
      this.closeModal()
    },err=>{
      this.util.error(data[0] +' failed to stop .')
      this.closeModal()
    })
  }

  async getVMPublicIP(data){
    let config =  await this.util.handleRequest('get','a3s_management_getVMConfig',[data[2],data[1],data[0]],null,null,null,true)
    let array = config.body.properties.networkProfile.networkInterfaces
    let nicName;
    if(array.length == 1){
      nicName = array[0].id.split('/')[array[0].id.split('/').length - 1]
    }
    else if(array.length){
      let filter = array.filter(e=> e.primary)
      nicName = filter[0].id.split('/')[filter[0].id.split('/').length - 1]
    }
    let nicData =  await this.util.handleRequest('get','a3s_management_getVMNetworkInterface',[data[2],data[1],nicName],null,null,null,true)
    let publicIpFilter = nicData.body.properties.ipConfigurations.filter(e=> e.properties.primary && e.properties.hasOwnProperty('publicIPAddress'))
    this.vmPublicIpData = publicIpFilter.length ? publicIpFilter[0] : null
    if(this.vmPublicIpData){
      let publicIpData = await this.util.handleRequest('get','a3s_management_getVMPublicIp',[data[2],data[1],this.vmPublicIpData.properties.publicIPAddress.name],null,null,null,true)
      this.vmPublicIpData.publicIpesponse = publicIpData.body
    }
    return this.vmPublicIpData
  }

  async reservePublicIp(data){
    if(this.vmPublicIpData){
      // let publicIpData = await this.util.handleRequest('get','a3s_management_getVMPublicIp',[data[2],data[1],this.vmPublicIpData.properties.publicIPAddress.name],null,null,null,true)
      this.vmPublicIpData.publicIpesponse.properties.deleteOption = 'Detach'
      return this.util.handleRequest('post','a3s_management_updateVMPublicIp',[data[2],data[1],this.vmPublicIpData.properties.publicIPAddress.name],this.vmPublicIpData.publicIpesponse,null,null,true)
    }
    else return this.vmPublicIpData
  }

  powerOffVM(data){
    let body ={ subscriptionId : data[2],resourceGroupName : data[1],virtualMachineName : data[0] }
    return this.util.handleRequest('post','a3s_management_powerOffVM',[],body,null,null,true).then(res=>{
      this.util.success(data[0] + ' powered off successfully!')
    },err=>{
      this.util.error(data[0] +' failed to power off.')
    })
  }
  deleteAction(){
    switch(this.selectedType){
      case 'deleteVM': this.deleteVM(this.selectedRow)
      case 'deleteDataDisk': this.removeDataDisk(this.currentIndex)
    }
  }
  deleteVM(data){
    let subscriptionId = data[2],resourceGroupName = data[1],virtualMachineName = data[0];
    return this.util.handleRequest('delete','a3s_management_deleteVM',[subscriptionId,resourceGroupName,virtualMachineName],null,null,null,true).then(res=>{
      this.util.success(data[0] + ' deleted successfully!')
      this.closeModal()
    },err=>{
      this.util.error(data[0] +' failed to deleted.')
      this.closeModal()
    })
  }


  getStatusClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'running': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      default: return 'st-error';
    }
  }

  openModal(template: TemplateRef<any>,row?:any,type?:any,index?:any,subData?:any) {
    this.selectedRow=row;
    this.subData=subData
    this.selectedType=type;
    this.currentIndex=index;
    // this.columns.push({SubscriptionName:this.getProjectName(row[2])})
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  async openSidesheet(type,data?:any){
    this.showSidesheet = true;
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "dataDisk" : {
        this.sideSheetForm=this.util.getForm('dataDisk')
        this.currentForm = this.util.getForm('createDataDisk')
        await this.getVMData('dataDisk',this.selectedRow) 
        await this.getDataDiskData(this.selectedRow)
        let lunArray  = this.vmData?.properties?.storageProfile?.dataDisks
        if(lunArray.length){
          lunArray.forEach(e=>{
            this.lunArrayList.push(this.util.getForm('lunArray',e))
          })
          this.saveDisable=false
        }
        break;
      } 
      case "connectVM" : {
        this.currentForm = this.util.getForm('connectVM')
        this.getVMConfiguration('connectVM',this.selectedRow)
        break;
      } 
      case "autoShutdown" : {
        this.timeZoneList=this.util.getStatic('timeZoneList');
        this.currentForm = this.util.getForm('autoShutdown');
        this.getAutoShutdownData(this.selectedRow)
        this.saveDisable=false
        break;
      } 
      case 'resetPassword':{
        this.currentForm = this.util.getForm('resetPassword');
        break;
      }
      case 'capture':{
        this.currentForm = this.util.getForm('capture');
        await this.getVMConfiguration('capture',this.selectedRow)
        console.log('VM Config',this.vmConfigurationData)
        await this.getVMData('capture',this.selectedRow)
        // this.regionList=this.util.getStatic('countryList');
        this.subscriptionList = this.util.getCache('subscriptionList');
        !this.subscriptionList.length?this.subscriptionList.push({subscriptionName:this.getProjectName(this.selectedRow[2]),subscriptionId:this.selectedRow[2]}):false
        this.currentForm.get('subscription').setValue(this.subscriptionList[0].subscriptionId)
        await this.getRgBySubscription()
        this.currentForm.get('resourceGroup').setValue(this.selectedRow[1])
        this.currentForm.get('region').setValue(this.getRegionName(this.selectedRow[3]))
        this.replicationArrayList.controls.forEach(e=>{
          e.get('targetRegion').setValue(this.vmData?.location)
          e.get('targetReplicaCount').setValue(1)
          e.get('storageAccountType').setValue("Standard_LRS")
        })
        this.generalizedState=this.vmConfigurationData?.properties?.instanceView?.statuses[1]?.displayStatus.split(' ')[1]
        this.getAzureComputeGalleryList(this.selectedRow)
        break;
      }
    }
    console.log('currentData',this.selectedRow)
  }
  resetForm(){
    this.currentForm.reset()
  }
  resetSideSheetValidation(){
    this.inputError.managedImageNameEmpty=false
    this.inputError.managedImageNameLen=false
    this.inputError.managedImageNameDupCheck=false
    this.inputError.managedImageNameValid=false
    this.inputError.managedImageNameLastValid=false
    this.inputError.publisherNameEmpty=false
    this.inputError.publisherNameLen=false
    this.inputError.publisherNameValid=false
    this.inputError.publisherNameLastValid=false
    this.inputError.offerNameEmpty=false
    this.inputError.offerNameLen=false
    this.inputError.offerNameValid=false
    this.inputError.offerNameLastValid=false
    this.inputError.skuNameEmpty=false
    this.inputError.skuNameLen=false
    this.inputError.skuNameValid=false
    this.inputError.skuNameLastValid=false
  }
  resetValidation(){
    this.inputError={
    sourceNotValidPortVal : false,
    sourceInvalidPortRange:false,
    sourceOverlap:false,
    managedImageNameEmpty:false,
    managedImageNameLen:false,
    managedImageNameValid:false,
    managedImageNameLastValid:false,
    managedImageNameDupCheck:false,
    newAzureComputeGalleryNameEmpty:false,
    newAzureComputeGalleryNameLen:false,
    newAzureComputeGalleryNameDupCheck:false,
    newAzureComputeGalleryNameValid:false,
    newAzureComputeGalleryNameLastValid:false,
    skuNameEmpty:false,
    skuNameLen:false,
    skuNameValid:false,
    skuNameLastValid:false,
    offerNameEmpty:false,
    offerNameLen:false,
    offerNameValid:false,
    offerNameLastValid:false,
    publisherNameEmpty:false,
    publisherNameLen:false,
    publisherNameValid:false,
    publisherNameLastValid:false,
    diskNameLen:false,
    diskNameDupCheck:false,
    diskNameValid:false,
    diskNameEmpty:false,
    diskNameLastValid:false,
    validVersion:false,
    nameEmpty:false,
    dupCheck:false,
    duplicateResourceName:false,
    VMdescription:false,
    limitNum:false,
    limitNumReplica:false,
    endDateFormat:false,
    validEndDate:false,
    validEmail:false,
    }
  } 
  lunArray(){
    this.lunArrayList.push(this.util.getForm('lunArray'))
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  get lunArrayList() {
    return this.sideSheetForm.get('lunArray') as FormArray;
  }
  get tagsList() {
    return this.currentForm.get('tagsArray') as FormArray;
  }

  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }

  setMode(value){
    switch(value){
      case 'resetPwd':{
        this.currentForm.get('userName').setValidators([Validators.required,Validators.minLength(1),Validators.maxLength(64),this.validatorService.userNameValidator])
        this.currentForm.get('password').setValidators([Validators.required,Validators.minLength(12),Validators.maxLength(72)])
        this.currentForm.get('confirmPwd').setValidators(Validators.required)
        this.currentForm.get('sshKey').setValidators(null)
        break;
      }
      case 'resetSSH':{
        this.currentForm.get('userName').setValidators([Validators.required,Validators.minLength(1),Validators.maxLength(64),this.validatorService.userNameValidator])
        this.currentForm.get('password').setValidators(null)
        this.currentForm.get('confirmPwd').setValidators(null)
        this.currentForm.get('sshKey').setValidators([Validators.required,this.validatorService.sshKeyValidator])
        break;
      }
      case 'resetConfig':{
        this.currentForm.get('userName').setValidators(null)
        this.currentForm.get('password').setValidators(null)
        this.currentForm.get('confirmPwd').setValidators(null)
        this.currentForm.get('sshKey').setValidators(null)
        break;
      }
    }
    this.currentForm.get('userName').updateValueAndValidity()
    this.currentForm.get('password').updateValueAndValidity()
    this.currentForm.get('confirmPwd').updateValueAndValidity()
    this.currentForm.get('sshKey').updateValueAndValidity()
    this.currentForm.get('mode').setValue(value)
    this.currentForm.get('mode').updateValueAndValidity()
  }

  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }

  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }

  sortByKey(primaryKey,i){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'vmData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[i];
          let y = b[i];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }

  /*********************************************  ******************************************************/
  getVMData(type?:any,data?:any){
    return this.util.handleRequest('get','a3s_management_getVMData',[data[2],data[1],data[0]],null,null,null,true).then(res=>{
      this.vmData = res.body
      switch(type){
        case 'dataDisk':{
         break;
        }
        default:{
          break;
        }
      }
      console.log('VM Data',this.vmData)
    })
  }

  async getVMConfiguration(type,data){
    this.util.handleRequest('get','a3s_management_getVMConfig',[data[2],data[1],data[0]],null,null,null,true).then(async res=>{
      this.vmConfigurationData = res.body
      let array = res.body.properties.networkProfile.networkInterfaces
      let nicName;
      if(array.length == 1){
        nicName = array[0].id.split('/')[array[0].id.split('/').length - 1]
      }
      else if(array.length){
        let filter = array.filter(e=> e.primary)
        nicName = filter[0].id.split('/')[filter[0].id.split('/').length - 1]
      }
      this.adminName = this.vmConfigurationData.properties.osProfile.adminUsername
      this.currentForm.get('sshPrivateKey').setValue(`chmod 400 ${this.adminName}.pem`)
      // this.currentForm.get('sshPrivatePath').setValue(`~/.ssh/${adminName}`)
      $('#privateKeyPath').attr('placeholder',`~/.ssh/${this.adminName}`);
      switch(type){
        case 'connectVM':{
          let NICData = await this.util.handleRequest('get','a3s_management_getVMNetworkInterface',[data[2],data[1],nicName],null,null,null,true)
          NICData.body.properties.ipConfigurations.forEach(e=>{
            if(e.properties.primary){
              let publicIpValue = e.properties.publicIPAddress ? e.properties.publicIPAddress.properties.ipAddress : null
              let privateIpValue = e.properties.privateIPAddress ? e.properties.privateIPAddress : null
              let publicIp = e.properties.publicIPAddress ? {name : `Public IP address (${e.properties.publicIPAddress.properties.ipAddress})`,value:e.properties.publicIPAddress.properties.ipAddress} : null
              let privateIp = e.properties.privateIPAddress ? {name :`Private IP address (${e.properties.privateIPAddress})` ,value:e.properties.privateIPAddress} : null
              this.vmIpAddressList= publicIp ? [...this.vmIpAddressList,...[privateIp],...[publicIp]] : [...this.vmIpAddressList,...[privateIp]]
              this.currentForm.get('rdpIp').setValue(publicIpValue ? publicIpValue : privateIpValue)
              this.currentForm.get('sshCommand').setValue(`ssh -i <private key path> ${this.adminName}@${publicIpValue ? publicIpValue : privateIpValue}`)
            }
          })
         break;
        }
        default:{
          break;
        }
      }
      console.log('VM Data',this.vmData)
    })
  }

  /******************** Connect VM **************************/
  copyClipboard(event, id) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = $(event.target).siblings('.pw-outer').children('#' + id)[0].value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    console.log($(event.target).parent().children('#' + id).text())
    if ($(event.target).parent().children().hasClass('copy-toaster')) return;
    var dateSpan = document.createElement('span')
    dateSpan.className = 'copy-toaster ml-3'
    dateSpan.textContent = 'Copied!'
    $(event.target).parent().append(dateSpan)
    setTimeout(() => {
      $(event.target).parent().children('.copy-toaster').remove();
    }, 3000);
  }

  updateSSHCommand(event){
    let value1 = event.target.value
    let value2 = this.currentForm.get('sshCommand').value
    let update;
    if(value2.includes('<private key path>')){
      update = value2.replace('<private key path>',value1 ? value1 : '<private key path>')
    }
    else{
      update = value2.split(' ')
      update[2] = update[2] ? value1 : '<private key path>'
      update = update.join(' ')
    }
    this.currentForm.get('sshCommand').setValue(update)
  }

  downloadRDP(){
    let ipAddr = this.currentForm.get('rdpIp').value
    let port = this.currentForm.get('rdpPort').value
    let vmName = this.selectedRow[0]
    let value = `full address:s:${ipAddr}:${port}\nprompt for credentials:i:1\nadministrative session:i:1`
    var blob = new Blob([value],
    { type: "text/plain;charset=utf-8" });
    fs.saveAs(blob, `${vmName}.rdp`);
  }
  portRangeValidation(event,limit,flag){
    let value=event.target.value;
    
    this.inputError[flag+'InvalidPortRange']=false; 
    this.inputError[flag+'Overlap']=false;
    this.inputError[flag+'NotValidPortVal']=false;
    if(value&&value!=""){
      if(value.includes(',-')||value.includes('-,')){
        this.inputError[flag+'NotValidPortVal']=true;
        return;
      }else if(value[value.length-1]==","||value[value.length-1]=="-"){
        this.inputError[flag+'InvalidPortRange']=true;
      }else if(value!="*"&&(!(/^[0-9,-]*$/).test(value))){
        this.inputError[flag+'NotValidPortVal']=true;
      }else if(value>65535 || value<0){
        this.inputError[flag+'NotValidPortVal']=true;
      }
      else{
        let splitArr=value.split(',');
        if(splitArr.length==1){
          let splitArr1=splitArr[0].split('-');
          if(splitArr1.length>1&&(!splitArr1[1]||parseInt(splitArr1[0])>parseInt(splitArr1[1])||splitArr1[0]==splitArr1[1])){
            //Invalid port range specified
            this.inputError[flag+'InvalidPortRange']=true;
            return;
          }else if(splitArr1.length==1&&parseInt(splitArr1[0])>limit){
            this.inputError[flag+'InvalidPortRange']=true;
            return;
          }else if(splitArr1.length>1&&splitArr1[1]&&parseInt(splitArr1[1])>limit){
            this.inputError[flag+'InvalidPortRange']=true;
            return;
          }
        }else if(splitArr.length>1&&!splitArr[1]){
          //Invalid port range specified
          this.inputError[flag+'InvalidPortRange']=true;
          return;
        }else {
          let tempList=[];
          for(let i=0;i<splitArr.length;i++){
            let splitArr1=splitArr[i].split('-');
            if(splitArr1.length>1&&(!splitArr1[1]||parseInt(splitArr1[0])>parseInt(splitArr1[1])||splitArr1[0]==splitArr1[1])){
              //Invalid port range specified
              this.inputError[flag+'InvalidPortRange']=true;
              break;
            }else {//if(splitArr1.length==1){
             this.inputError[flag+'InvalidPortRange']=false; 
             this.inputError[flag+'Overlap']=false;
              for(let j=0;j<tempList.length;j++){
                if(!(tempList[j] instanceof Array)){
                if(splitArr1[0]==tempList[j]){
                  this.inputError[flag+'Overlap']=true;
                  break;
                }else if(splitArr1[1]==tempList[j]){
                  this.inputError[flag+'Overlap']=true;
                  break;
                }else if(parseInt(splitArr1[0])<parseInt(tempList[j])&&splitArr1[1]&&parseInt(splitArr1[1])>parseInt(tempList[j])){
                  this.inputError[flag+'Overlap']=true;
                  break;
                }
              }else if((tempList[j] instanceof Array)){
                  if(splitArr1[0]==tempList[j][0]||(tempList[j][1]&&splitArr1[0]==tempList[j][1])){
                  this.inputError[flag+'Overlap']=true;
                  break;
                  }else if(splitArr1[1]&&(splitArr1[1]==tempList[j][0]||(tempList[j][1]&&splitArr1[1]==tempList[j][1]))){
                  this.inputError[flag+'Overlap']=true;
                  break;
                  }else if(splitArr1[0]&&parseInt(splitArr1[0])>parseInt(tempList[j][0])&&parseInt(splitArr1[0])<parseInt(tempList[j][1])){
                  this.inputError[flag+'Overlap']=true;
                  break;
                  }else if(splitArr1[1]&&parseInt(splitArr1[1])<parseInt(tempList[j][0])&&parseInt(splitArr1[1])>parseInt(tempList[j][1])){
                  this.inputError[flag+'Overlap']=true;
                  break;
                  }
                  else if(splitArr1[1]>tempList[j][0]&&splitArr1[1]<tempList[j][1]){
                    this.inputError[flag+'Overlap']=true;
                    break;
                  }
                  else if(splitArr1[0]>tempList[j][0]&&splitArr1[1]<tempList[j][1]){
                    this.inputError[flag+'Overlap']=true;
                    break;
                  }
                  else{
                    this.inputError[flag+'Overlap']=false;
                  }
                }else{
                  this.inputError[flag+'Overlap']=false;
                }
              }
              if((splitArr1[0]&&parseInt(splitArr1[0])>limit)||(splitArr1[1]&&parseInt(splitArr1[1])>limit)){
                this.inputError[flag+'NotValidPortVal']=true;
              }
            tempList.push(splitArr1.length>1?splitArr1:splitArr1[0]);
            if(this.inputError[flag+'Overlap']||this.inputError[flag+'InvalidPortRange']||this.inputError[flag+'NotValidPortVal']){
               return false;
             }
            }

          }
        }
        this.inputError[flag+'NotValidPortVal']=false;
      }
    }

  }
  /********************************************* Auto-shtdown **************************/
  getAutoShutdownData(data){
    this.util.handleRequest('get','a3s_management_getAutoShutdownData',[data[2],data[1],data[0]],null,null,null,true).then(async res=>{
      this.vmData = res.body
      if(this.vmData.properties.status == 'Disabled'){
        this.currentForm.get('enableAutoShutdown').setValue(false)
      }
      else if(this.vmData.properties.status == 'Enabled'){
        this.currentForm.get('enableAutoShutdown').setValue(true)
        let event = { target : { checked : true}};
        this.toggleTimeValidator(event)
      }
      this.currentForm.get('enableAutoShutdown').updateValueAndValidity()
      await this.util.delay(2000)
      let value = this.getShutdowntimeFormat('24to12',this.vmData.properties.dailyRecurrence.time)
      this.currentForm.get('shutdownTime').setValue(value)
      this.currentForm.get('notifyShutdown').setValue(this.vmData.properties.notificationSettings.status == 'Enabled' ? true : false)
      this.currentForm.get('email').setValue(this.vmData.properties.notificationSettings.emailRecipient)
      this.currentForm.get('timeZone').setValue(this.vmData.properties.timeZoneId)
      console.log('VM Data',this.vmData)
    },err=>{
      this.currentForm.get('enableAutoShutdown').setValue(false)
      this.currentForm.get('timeZone').setValue('UTC')
      this.disableAutoShutdown = true
    })
  }

  getShutdowntimeFormat(type,value){
    if(type == '12to24'){
      value = value.split(':')
      let hr = value[2].includes('p') || value[2].includes('P')
      value = value.splice(0,2)
      value[0]  = hr ? (Number(value[0]) + 12).toString() : value[0]
      return value.join('')
    }
    else if(type == '24to12'){
      let hour = value.slice(0,2)
      let ampm = Number(hour) > 12 ? 'PM' : 'AM'
      let min = value.slice(2,4)
      hour = Number(hour) > 12 ? (Number(hour) - 12).toString() : hour  
      return `${hour}:${min}:00 ${ampm}`
    }
  }
  validEmail(event){
    let val=event.target.value
    this.saveDisable=true
    const regx =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if(val&&!regx.test(val.toLowerCase())){
      this.inputError.validEmail=true
    }else  this.inputError.validEmail=false
  }
  enableAutoShutdownData(data){
    let formData = this.currentForm.getRawValue()
    let body;
    if(this.disableAutoShutdown){
      body ={ 
          "location": data[3], 
          "properties": { 
              "status": "Enabled", 
              "timeZoneId": formData.timeZone, 
              "taskType": "ComputeVmShutdownTask", 
              "notificationSettings": { 
                  "status": formData.notifyShutdown ? "Enabled" : 'Disabled', 
                  "timeInMinutes": 30, 
                  "webhookUrl": null, 
                  "emailRecipient": formData.email ? formData.email : '', 
                  "notificationLocale": "en" 
              }, 
              "targetResourceId": `/subscriptions/${data[2]}/resourceGroups/${data[1]}/providers/Microsoft.Compute/virtualMachines/${data[0]}`, 
              "dailyRecurrence": { 
                  "time": this.getShutdowntimeFormat('12to24',formData.shutdownTime)
              } 
          } 
      } 
    }
    else{
        body ={
          "properties": {
              "status": "Enabled",  // If user wants to enable this feature, else it is Disabled
              "taskType": "ComputeVmShutdownTask",
              "dailyRecurrence": {
                  "time": this.getShutdowntimeFormat('12to24',formData.shutdownTime)// Time should be in this format
              },
              "timeZoneId": formData.timeZone,
              "notificationSettings": {
                  "status": formData.notifyShutdown ? "Enabled" : 'Disabled',
                  "timeInMinutes": 30,
                  "emailRecipient": formData.email ? formData.email : '',
                  "notificationLocale": "en"
              },
              "targetResourceId": `/subscriptions/${data[2]}/resourceGroups/${data[1]}/providers/Microsoft.Compute/virtualMachines/${data[0]}` // ResourceId of VM
          },
          "location": data[3]
      }
    }
  
    this.util.handleRequest('post','a3s_management_enableAutoShutdownData',[data[2],data[1],data[0]],body,null,null,true).then(res=>{
      console.log('VM Data',res)
        // this.closeSidesheet()
      this.util.success(data[0] + ' auto shutdown enabled successfully!')
     },err=>{
       this.util.error(data[0] + ' failed to enable auto shutdown.')
    })
  }

  disableAutoShutdownData(data){
    this.util.handleRequest('delete','a3s_management_disableAutoShutdownData',[data[2],data[1],data[0]],null,null,null,true).then(res=>{
      console.log('VM Data',res)
      // this.closeSidesheet()
    this.util.success(data[0] + ' auto shutdown disabled successfully!')
     },err=>{
      this.util.error(data[0] + ' failed to disable auto shutdown.')
    })
  }
  selectedImage(event){
    this.sideSheetForm= this.util.getForm('captureVMImageDefinition')
    // this.sideSheetForm.get('imageDefinitionName').setValue(event?.name)
    this.sideSheetForm.get('osType').setValue(event?.properties?.osType)
    this.sideSheetForm.get('vmGeneration').setValue(event?.properties?.hyperVGeneration)
    this.sideSheetForm.get('publisher').setValue(event?.properties?.identifier?.publisher)
    this.sideSheetForm.get('offer').setValue(event?.properties?.identifier?.offer)
    this.sideSheetForm.get('sku').setValue(event?.properties?.identifier?.sku)
  }
  captureArmTemplateBody(){
    let rawValue = this.currentForm.getRawValue()
    let body={}
    if(rawValue.shareImageComputeGallery){
      let rawValue1 = this.sideSheetForm.getRawValue()
      let array  = rawValue.replicationArray.filter(e=>{
        if(e.targetRegion&&e.targetRegion!==''&&e.targetReplicaCount&&e.targetReplicaCount!==''&&e.storageAccountType&&e.storageAccountType!=='')
         return e.targetRegion;
          e.targetReplicaCount;
          e.storageAccountType
      })
      let replica={}
      array= array.map(e=> { 
         replica['name']= e.targetRegion
         replica['regionalReplicaCount']=e.targetReplicaCount
         replica['storageAccountType']=e.storageAccountType
      return replica})
      let tagObject={}
      rawValue.tagsArray.forEach(e=>{
        if(e.name&&e.value) tagObject[e.name] = e.value
      })
      body={
        "properties": { 
          "mode": "incremental", 
          "debugSetting": { 
              "detailLevel": "none" 
          }, 
          "templateLink": { 
              "uri": "https://a3sarmtemplate.blob.core.windows.net/armtemplates/VM/CaptureVMImage.json", 
              "contentVersion": "1.0.0.0" 
          }, 
          "parameters":{ 
              "vmResourceGroup": { 
                  "value": rawValue.resourceGroup
              }, 
              "vmName": { 
                 "value": this.selectedRow[0] 
              }, 
              "location": { 
                  "value": this.selectedRow[3] 
              }, 
              "shareimageToAzureComputeGallery": { 
                  "value": rawValue.shareImageComputeGallery
              }, 
              "zoneResilient": { 
                  "value": false 
              }, 
              // "managedImageName": { 
              //     "value": "vm-1-image-20220211121458" 
              // }, 
              "isGalleryNew": { 
                  "value": rawValue.newAzureComputeGallery?true:false 
              }, 
              "galleryName": { 
                  "value": !rawValue.newAzureComputeGallery?rawValue.targetComputeGallery:rawValue.newAzureComputeGallery
              }, 
              "osState": { 
                  "value": rawValue.osState
              }, 
              "imageDefenitionNew": { 
                  "value": rawValue1.imageDefinitionName?true:false 
              }, 
              "imageDefinitionName": { 
                  "value": !rawValue1.imageDefinitionName?rawValue.targetImageDefinition:rawValue1.imageDefinitionName
              }, 
              "publisher": { 
                  "value": rawValue1.publisher
              }, 
              "offer": { 
                  "value":rawValue1.offer
              }, 
              "sku": { 
                  "value": rawValue1.sku
              }, 
              "osType": { 
                  "value": rawValue1.osType
              }, 
              "hyperVGeneration": { 
                  "value": rawValue1.vmGeneration
              }, 
              "versionName": { 
                  "value": rawValue.versionNumber    
              }, 
              "defaultReplicaCount": { 
                  "value": Number(rawValue.defaultReplicaCount) || 1 
              }, 
              "excludedFromLatest": { 
                  "value": rawValue.excludeLatest    
              }, 
              "endOfLife": { 
                  "value":rawValue.endLifeDate || ''            //"2022-02-13T18:30:00Z" 
              },
              "regionReplications": { 
                "value": array
              }, 
              "tags": { 
                "value":tagObject
            } 
          } 
        }
      } 
    }else{
      body={
        "properties": { 
          "mode": "incremental", 
          "debugSetting": { 
              "detailLevel": "none" 
          }, 
          "templateLink": { 
              "uri": "https://a3sarmtemplate.blob.core.windows.net/armtemplates/VM/CaptureVMImage.json", 
              "contentVersion": "1.0.0.0" 
          }, 
          "parameters":{ 
              "vmResourceGroup": { 
                  "value": rawValue.resourceGroup
              }, 
              "vmName": { 
                 "value": this.selectedRow[0] 
              }, 
              "location": { 
                  "value": this.selectedRow[3] 
              }, 
              "shareimageToAzureComputeGallery": { 
                  "value":rawValue.shareImageComputeGallery
              }, 
              "zoneResilient": { 
                  "value": rawValue.zoneResilincy
              }, 
              "managedImageName": { 
                  "value": rawValue.managedImageName
              },  
          } 
        }
      }
    }
  return body
  }
  async saveVMData(type){
    switch(type){
      case 'dataDisk':{
        // await this.getVMData('',this.selectedRow)
        console.log('Request Body',this.vmData)
        if(!this.lunArrayList.controls.length) return;
        let data = this.lunArrayList.controls.some((e,i)=> {
            let lunVal =this.lunArrayList.at(i).get('lun').value
            let diskName =this.lunArrayList.at(i).get('diskName').value
            this.vmData?.properties?.storageProfile?.dataDisks.map(e=>{
              if(e.name==diskName){
                e['lun']=Number(lunVal)
              }});
        })
        delete this.vmData?.resources
        this.UpdateVMData(this.selectedRow,this.vmData,'createDisk')
       break;
      }
      case 'autoShutdown':{
        let data = this.currentForm.getRawValue()
        if(data.enableAutoShutdown){
          this.enableAutoShutdownData(this.selectedRow)
        }
        else{
          this.disableAutoShutdownData(this.selectedRow)
        }
        console.log('Request Body',this.selectedRow)
        console.log('Current Form',this.currentForm.getRawValue())
       break;
      }
      case 'capture':{
        console.log('Request Body',this.vmData)
        let body=this.captureArmTemplateBody()
        this.captureVMData(this.selectedRow,body)
       break;
      }
    }
  }
  captureVMData(data,body){
    this.util.info(`Creating "${this.selectedRow[0]}" image...`)
    this.util.handleRequest('post','a3s_management_captureVM',[data[2],data[1]],body,null,null,true).then(res=>{
      console.log('Capture VM Data',res)
      this.showSubSidesheet = false
      this.closeSidesheet()
      this.resetForm()
      this.resetValidation()
      this.saveDisable=false
      this.util.success(`"${this.selectedRow[0]}" image created successfully!`)
     },err=>{
       this.util.error(`“${this.selectedRow[0]}” image failed to created.`)
     })
   }
   validEndDate(event){
    let value=event.target.value
    let split=value.split('/')
    let tdy= new Date().getTime()
    let val=this.currentForm.get('endLifeDate').value
    let date_regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/ ;
    let date_regex1 = /^\d{1,2}\-\d{1,2}\-\d{4}$/ ;
    let boolean = date_regex.test(value) || date_regex1.test(value)
    if(!boolean||(split[0]>12||split[0]<1)||(split[1]>31||split[1]<1)){
      this.inputError.endDateFormat=true
    }else this.inputError.endDateFormat=false
    let date = new Date(split[2], split[0] - 1, split[1]).getTime();
    if((tdy - date) < 0) this.inputError.validEndDate=false
    //  if(split[0]<=this.currentMonth+1||split[1]<=this.currentDay||split[2]<=this.currentYear) this.inputError.validEndDate=true
     else this.inputError.validEndDate=true
     if(!value){
      this.inputError.endDateFormat=false
      this.inputError.validEndDate=false
     }
   }
  UpdateVMData(data,body,type?:any){
    type=='createDisk'?this.util.info(`Updating Datadisks for “${this.selectedRow[0]}”...`):this.util.info(`Deleting data disk...`)
  return this.util.handleRequest('post','a3s_management_updateVMData',[data[2],data[1],data[0]],body,null,null,true).then(res=>{
     console.log('VM Data',res)
     this.showSubSidesheet = false
     this.closeModal()
     type=='createDisk'?this.util.success(`Datadisks for “${this.selectedRow[0]}” updated successfully!`):   this.util.success(`Data disk deleted successfully!`)
    },err=>{
      type=='createDisk'?this.util.error(`Datadisks for “${this.selectedRow[0]}” failed to be updated.`):this.util.error(`Data disk failed to be deleted.`)
    })
  }

  toggleTimeValidator(event){
    let checked = event.target.checked
    checked ? this.currentForm.get('shutdownTime').setValidators([this.validatorService.timeFormatValidator]) :   this.currentForm.get('shutdownTime').setValidators(null);
    this.currentForm.get('shutdownTime').updateValueAndValidity()
   }

   toggleEmailValidator(event){
    let checked = event.target.checked
    this.saveDisable=true
    checked ? this.currentForm.get('email').setValidators([Validators.email]) :   this.currentForm.get('email').setValidators(null);
    this.currentForm.get('email').updateValueAndValidity()
   }

   /********************************************* Redeploy and Reapply **************************/
   reDeployVM(){
     this.util.info(`Redeploying ${this.selectedRow[0]}...`)
     let body={
      subscriptionId: this.selectedRow[2],
      resourceGroupName: this.selectedRow[1],
      virtualMachineName: this.selectedRow[0]
     }
    this.util.handleRequest('post','a3s_management_redeployVM',[],body,null,null,true).then(res=>{
      this.closeSidesheet()
      this.util.success(this.selectedRow[0] + ' redeployed successfully!')
    },err=>{
      this.util.error('Failed to redeploy ' + this.selectedRow[0] )
    })
   }

   reApplyVM(){
    this.util.info(`Reapplying ${this.selectedRow[0]}...`)
    let body={
      subscriptionId: this.selectedRow[2],
      resourceGroupName: this.selectedRow[1],
      virtualMachineName: this.selectedRow[0]
     }
   this.util.handleRequest('post','a3s_management_reapplyVM',[],body,null,null,true).then(res=>{
     this.closeSidesheet()
     this.util.success(this.selectedRow[0] + ' reapplyed successfully!')
   },err=>{
     this.util.error('Failed to reapply ' + this.selectedRow[0] )
   })
  }

  /********************************************* Reset password **************************/
  resetPassword(){
    let body={ 
      "properties": { 
          "mode": "incremental", 
          "debugSetting": { 
              "detailLevel": "none" 
          }, 
          "templateLink": { 
              "uri": "https://a3sarmtemplate.blob.core.windows.net/armtemplates/VM/VMResetPassword.json", 
              "contentVersion": "1.0.0.0" 
          }, 
          "parameters": { 
              "vmName": { 
                  "value": this.selectedRow[0]  // Provide the VM name 
              }, 
              "vmLocation": { 
                  "value": this.selectedRow[3] // Enter the location of the VM 
              }, 
              "osType": { 
                  "value": this.selectedRow[5] // windows or linux 
              }, 
              // If user want to reset the SSH key for linux VM, then resetPassword and resetConfiguration both should be false 
              "resetPassword": { 
                  "value": this.currentForm.get('mode').value == 'resetPwd' ? true : false  // true if user need to reset passsword  // Applicable to both OS types 
              }, 
              "resetConfiguration": { 
                  "value": this.currentForm.get('mode').value == 'resetConfig' ? true : false// true if user need to reset configuration  // Applicable to both OS types 
              }, 
              "vmAdminUsername": { 
                  "value": this.currentForm.get('userName').value ? this.currentForm.get('userName').value : ''
              }, 
              "password": { 
                  "value": this.currentForm.get('password').value ? this.currentForm.get('password').value : ''  // Don't need this input when resetPassword and resetConfiguration both are false 
              }, 
              "sshKey": {  // Provide the SSH key value 
                  "value": this.currentForm.get('sshKey').value ? this.currentForm.get('sshKey').value : ''
              } 
          } 
  
      } 
  
    } 
    this.util.info(`Resetting ${this.selectedRow[0]} ${this.getResetPwdMode()}...`)
    this.util.handleRequest('post','a3s_management_resetPassword',[this.selectedRow[2],this.selectedRow[1]],body,null,null,true).then(res=>{
      this.closeSidesheet()
      this.util.success(this.selectedRow[0] +' '+this.getResetPwdMode()+' reset successfully!')
    },err=>{
      this.util.error(`${this.selectedRow[0]} ${this.getResetPwdMode()} reset failed.` )
    })
  }

  getResetPwdMode(){
    let value = this.currentForm.get('mode').value
    switch(value){
      case 'resetPwd' : return 'password'
      case 'resetSSH' : return 'SSH public key'
      case 'resetConfig' : return 'configuration'
    }
  }

/********************************************* Data Disk **************************/
async getDiskData(data,type?:any){
  let subsId = data[2]
  let rgName = data[1]
  let diskName = data[0]
  this.util.handleRequest('get','a3s_management_getDiskData',[subsId,rgName,diskName],null,null,null,true).then(res=>{
    this.diskData = res.body
    console.log('Disk Data',this.diskData)
  })
}
async getDataDiskData(data,type?:any){
  let subsId = data[2]
 return  this.util.handleRequest('get','a3s_management_getDataDisk',[subsId],null,null,null,true).then(res=>{
    this.existAllDataList = res.body.value
   this.existDiskData=this.existAllDataList.filter(e=>e.location==this.vmData.location&&e?.properties?.diskState=='Unattached')
    console.log('Disk Data',this.existDiskData)
  })
}
changeExistData(event){

}
UpdateDiskData(data,body,type?:any){
  let subsId = data[2]
  let rgName =  data[1] 
  let diskName =  body.name
  // this.util.info(this.getInProgress(type))
  return this.util.handleRequest('post','a3s_management_updateDiskData',[subsId,rgName,diskName],body,null,null,true).then(res=>{
    console.log('disk Data',res)
    this.showSubSidesheet = false
    this.addDiskInVM('new')
    // this.closeModal()
    // this.closeSidesheet()
    // this.util.success(this.getSuccesText(type))
  },err=>{
    // this.util.error(this.getFailureText(type))
  })
}

setEnableSharedDisk(id){
  let value=id=='yes'?true:false
  this.currentForm.get('enableSharedDisk').setValue(value)
}
initializeForm(){
  this.popupForm=this.formBuilder.group({
    diskSKU:['Premium_LRS'],
    diskSize:[null,[Validators.required]],
    performance:[null],
  })
}
diskSizeNoChanges(){
  this.closeModal()
  this.popupForm.get('diskSKU').setValue('Premium_LRS')
  this.popupForm.get('diskSize').setValue(1024)
}
async getSKUList(data,type,event?:any){
  let subsId = data[2]
  let loc = data[3]
  return this.util.handleRequest('get','a3s_management_getSKUDiskData',[subsId,loc],null,null,null,true).then(async res=>{
    this.skuList = res.body.value
    this.skuList= type=='default'?this.skuList.filter(e=>e.name=='Premium_LRS'): this.skuList.filter(e=>e.name==event.id)
    let sorted=this.skuList.filter(e=>e.resourceType=='disks');
    sorted.sort((a,b)=>{
      let x = Number(a?.capabilities[0].value);
      let y = Number(b?.capabilities[0].value);
      return x-y
    })
    this.skuList=sorted;
    this.skuList.forEach(e=>{
      e['active']=e.capabilities[0].value==1024?true:false
    })
    console.log('SKU List',this.skuList)
    await this.getPerformanceDropdown('default')
  })
}
getPerformanceDropdown(type){
  this.performance=[]
  this.skuList.map(e=>{
    if(e.active){
      this.performance.push({
        name:`${e.size} - ${e.capabilities[2].value} IOPS, ${e.capabilities[4].value} MBps (default)`,
        GiB : Number(e.capabilities[0].value),
        id:e.size, 
      })
    }else{
      this.performance.push({
        name:`${e.size} - ${e.capabilities[2].value} IOPS, ${e.capabilities[4].value} MBps`,
        GiB : Number(e.capabilities[0].value),
        id:e.size, 
      })
    }
  })
    let GIB= type=='default'?1024:Number(type?.capabilities[0]?.value)
    type=='default'?this.popupForm.get('performance').setValue('P30'):false
    if(5000>GIB){
      this.performance=this.performance.filter(e=>5000>=e.GiB && GIB<=e.GiB)
    }else this.performance=this.performance.filter(e=>5000<=e.GiB && GIB<=e.GiB)
 
}
async setsize(list,i){
  this.saveDisable=true
  this.skuList.forEach(e=>e.active=false)
  list.active=true
  let GIB=Number(list?.capabilities[0]?.value)
  this.diskSizeMsg=this.popupForm.get('diskSize')>32767?true:false
  this.popupForm.get('performance').setValue(list.size)
  this.diskTier=list?.size=='P50' || list?.size=='P80' ? true : false
  this.diskTier?this.popupForm.get('performance').disable():this.popupForm.get('performance').enable()
  await this.getPerformanceDropdown(list)
  this.popupForm.get('diskSize').setValue(GIB)
}

validSize(event){
  let value=event.target.value;
  this.saveDisable=true
  // this.diskSizeMsg=this.diskData?.properties?.diskSizeGB>value?true:false
  if(value>0&&value<32768){
    this.diskSizeMsg=false
  }else this.diskSizeMsg=true
 this.skuList.forEach(async e=>{
   e.active=false
   let num=Number(value)
   value= this.sizeRange(num)
   if(value==e.capabilities[0]?.value){
   e.active=true
   this.popupForm.get('performance').setValue(e.size)
   this.diskTier=e?.size=='P50' || e?.size=='P80' ? true : false
   this.diskTier?this.popupForm.get('performance').disable():this.popupForm.get('performance').enable()
   await this.getPerformanceDropdown(e)
   }
  })
}
sizeRange(num){
  if(num>0&&num<=4)  num=4
  if(num>4&&num<=8)  num=8
  if(num>8&&num<=16)  num=16
  if(num>16&&num<=32)  num=32
  if(num>32&&num<=64)  num=64
  if(num>64&&num<=128)  num=128
  if(num>128&&num<=256)  num=256
  if(num>256&&num<=512) num=512
  if(num>512&&num<=1024)  num=1024
  if(num>1024&&num<=2048)  num=2048
  if(num>2048&&num<=4096)  num=4096
  if(num>4096&&num<=8192)  num=8192
  if(num>8192&&num<=16384) num=16384
  if(num>16384&&num<=32767)  num=32767
  return num
}
validIPName(event,limitNum,subType?:any){
  let value =event.target.value;
  this.diskName=value
  this.inputError[subType+'NameDupCheck'] = false;
  // this.inputError[flag+'MaxLen'] = false;
 if(value==''){
  this.inputError[subType+'NameEmpty'] = true;
 }else{ this.inputError[subType+'NameEmpty'] = false;}
    if (value.length > limitNum) {
      this.inputError[subType+'NameLen'] = true;
      return;
    }
    else {
      this.inputError[subType+'NameLen'] = false;
    }
  if(value.length==1){
    if(/^[A-Za-z0-9]*$/.test(value)){
      this.inputError[subType+'NameValid']=false;
    }
    else{
      this.inputError[subType+'NameValid']=true;
    }
  }
  // else this.inputError[flag+'Valid']=false;
  if(/^[A-Za-z0-9_]+$/.test(value[value.length-1]) && !this.inputError[subType+'NameValid']){
    this.inputError[subType+'NameLastValid']=false;
  }
  else{
    this.inputError[subType+'NameLastValid']=true;
  }
  switch(subType){
    case 'disk':{
      this.existAllDataList.forEach(e=>{
        if(e.name==value){
          this.inputError[subType+'NameDupCheck'] = true;
          return;
        }
      })
    }
    case 'newAzureComputeGallery':{
      this.azureComputeGalleryList.forEach(e=>{
        if(e.name==value){
          this.inputError[subType+'NameDupCheck'] = true;
          return;
        }
      })
  }
  case 'managedImage':{
    this.vmImageDefinitionAllList.forEach(e=>{
      if(e.name==value){
        this.inputError[subType+'NameDupCheck'] = true;
        return;
      }
    })
}
}
}
invalidForm(type){
  switch(type){
    case 'RDP': return this.inputError?.sourceNotValidPortVal || this.inputError?.sourceInvalidPortRange || this.inputError?.sourceOverlap 
    case 'targetAzureGallery':return this.inputError?.newAzureComputeGalleryNameValid || this.inputError?.newAzureComputeGalleryNameLastValid || this.inputError?.newAzureComputeGalleryNameDupCheck || this.inputError?.newAzureComputeGalleryNameLen || this.inputError?.newAzureComputeGalleryNameEmpty
    case 'capture':
      if(this.currentForm.get('shareImageComputeGallery')?.value){
      return this.inputError?.nameEmpty || this.inputError?.validVersion || this.inputError?.limitNum || this.inputError?.limitNumReplica || this.inputError?.validEndDate || this.inputError?.endDateFormat
      || !this.currentForm.get('versionNumber')?.value  || !this.currentForm.get('targetComputeGallery')?.value
      }else{
        return this.inputError?.managedImageNameEmpty || this.inputError?.managedImageNameLen || this.inputError?.managedImageNameValid ||this.inputError?.managedImageNameLastValid
      }
    case 'dataDisk': return this.inputError?.nameEmpty || this.inputError?.limitNum || this.inputError?.dupCheck 
    case 'imgDef': return this.inputError?.managedImageNameEmpty||this.inputError?.managedImageNameLen||this.inputError?.managedImageNameDupCheck||this.inputError?.managedImageNameValid ||this.inputError?.managedImageNameLastValid ||
                      this.inputError?.skuNameEmpty||this.inputError?.skuNameLen||this.inputError?.skuNameValid||this.inputError?.skuNameLastValid ||this.inputError?.offerNameEmpty ||this.inputError?.offerNameLen||this.inputError?.offerNameValid||this.inputError?.offerNameLastValid||this.inputError?.publisherNameEmpty ||this.inputError?.publisherNameLen 
                      ||this.inputError?.publisherNameValid ||this.inputError?.publisherNameLastValid 
    }      
}

async getEncryptionList(data,type?:any){
  let subsId = data[2]
  return this.util.handleRequest('get','a3s_management_getDiskEncryptionSets',[subsId],null,null,null,true).then(res=>{
    this.encryptionList = res.body.value
    let loc=this.selectedRow[3]
    this.encryptionList=this.encryptionList.filter(x=>x.location==loc)
    if(type!=''){
      this.encryptionList=this.encryptionList.filter(x=>x.properties.encryptionType==type?.id)
    }
    this.diskEncryption=  this.encryptionList
    console.log('Encryption List',this.encryptionList)
  })
}

  vmDiskSizeListFilter(){
    this.vmDisk.customDiskSizeAdd=undefined; 
    this.vmDisk.performanceTier=undefined; 
    let findIndex=this.vmDiskSizeList.findIndex(dt=>dt.activeRow==true);
    if(findIndex>-1){
      this.vmDiskSizeList[findIndex].activeRow=false;
    }
      this.dummyVMDiskSizeList=this.vmDiskSizeList.filter(dt=>{
        // if(dt.name==this.vmDisk.diskSKU.id){
        //   if(this.currentResourceDtls.sourceType!='Snapshot'&&this.currentResourceDtls.sourceType!='snapshot'){
        //     return true;
        //   }else if((this.currentResourceDtls.sourceType=='Snapshot'||this.currentResourceDtls.sourceType=='snapshot')&&this.currentResourceDtls.snapshot
        //   &&this.currentResourceDtls.snapshot.properties&&this.currentResourceDtls.snapshot.properties.diskSizeGB){
        //     if(dt.capabilities[0].value==this.currentResourceDtls.snapshot.properties.diskSizeGB||dt.capabilities[0].value>this.currentResourceDtls.snapshot.properties.diskSizeGB){
        //     return true;
        //     }
        //   }else{
        //     return true;
        //   }
        // }
        return true
      });
      let num = -1;
      // (this.datasort === 'desc') ? num = 1 : num = -1;
      let sorted = this.dummyVMDiskSizeList;
      sorted.sort((a, b) => {
        let x = Number(a.capabilities[0].value);
        let y = Number(b.capabilities[0].value);
        return (x < y) ? num : ((x > y) ? -num : 0)
      })
      this.dummyVMDiskSizeList = sorted; 
  }
  
  selectedVMDisk(){
    // this.getGIB('new')
    // this.getTier('new')
    let size=  this.popupForm.get('diskSize').value
    this.maxSharesList=[]
    let maxShare=size<=512 ? 3 : size>512 && size<=4096 ? 5:10
    for(let i=1;i<=maxShare;i++){this.maxSharesList.push({id:i,name:`${i}`})}
  }
  getGIB(val?:any){
    // this.currentForm.set('').setValue( val=="default"?'1024 GiB': this.popupForm.get('diskSize').value)
    val=="new"? this.popupForm.get('diskSize').value :this.popupForm.get('diskSize').setValue(1024)
    return this.popupForm.get('diskSize').value +' GiB' 
  }
  getTier(val){
    let tierName=val=="new"? this.popupForm.get('diskSKU').value :this.popupForm.get('diskSKU').setValue('Premium_LRS')
    if(tierName=='Premium_LRS') return 'Premium SSD LRS'
    else if(tierName=='StandardSSD_LRS') return 'Standard SSD LRS'
    else  return 'Standard HDD LRS'
    // return val=="new"?this.popupForm.get('diskSKU').value : 'Premium SSD LRS' 
  } 

  selectDiskSize(list,flag){
    let findIndex=this.vmDiskSizeList.findIndex(dt=>dt.activeRow==true);
    if(findIndex>-1){
      this.vmDiskSizeList[findIndex].activeRow=false;
    }
    list.activeRow=true;
    this.vmDisk.selectVmDiskSizeData=list;
    if(flag){
    this.vmDisk.customDiskSizeAdd=list.capabilities[0].value;
    }
    this.vmDisk.performanceTierList=[];
    if(this.vmDisk.diskSKU.id=='Premium_LRS'){
      let filterList;
      if(parseInt(list.capabilities[0].value)<8192){
        filterList=this.dummyVMDiskSizeList.filter(dt=>(parseInt(dt.capabilities[0].value)>parseInt(list.capabilities[0].value)||parseInt(dt.capabilities[0].value)==parseInt(list.capabilities[0].value))&&parseInt(dt.capabilities[0].value)<8192)
      }else {
        filterList=this.dummyVMDiskSizeList.filter(dt=>(parseInt(dt.capabilities[0].value)>parseInt(list.capabilities[0].value)||parseInt(dt.capabilities[0].value)==parseInt(list.capabilities[0].value))&&(parseInt(dt.capabilities[0].value)>4096))
      }
      this.vmDisk.performanceTierList=filterList.length>1?filterList:[];
    } 
  }

  triggerChangeSize(){
    this.openDiskModal(this.virtualMachineDiskSizePopup);
  }
  addDefault(){
    this.initializeForm()
   this.popupForm.get('diskSKU').setValue('Premium_LRS')
    this.popupForm.get('diskSize').setValue(1024)
    this.currentForm.get('encryptionType').setValue('EncryptionAtRestWithPlatformKey') 
    this.currentForm.get('sourceType').setValue('none') 
    this.getSnapshotList()
  }
  lunValidation(event,index){
    let val=event.target.value
    this.saveDisable=true
    this.inputError.dupCheck=false
    if(!val) this.inputError.nameEmpty=true
    else this.inputError.nameEmpty=false
    if(0<=val&&val<=63) this.inputError.limitNum=false
    else this.inputError.limitNum=true
    this.lunArrayList.controls.some((e,i)=> {
      // if(e.get('lun').value){
        // let input=e.at(index).get('lun').value
        let lunVal =this.lunArrayList.at(i).get('lun').value
         if(index != i && val == lunVal){ this.inputError.dupCheck=true}
         if( this.inputError.dupCheck!=true) {this.inputError.dupCheck=false}
      // }
    })
  }
  async addDiskInVM(type?:any){
    let diskName=type=='new'?this.currentForm.get('name').value:this.sideSheetForm.get('existDataDisk').value
    let dataDiskArray=this.vmData?.properties?.storageProfile?.dataDisks
    let lun=[]
    if(dataDiskArray.length!=0){
      if(dataDiskArray.length>1){
        dataDiskArray.sort((a,b)=>{
          let x = Number(a?.lun);
          let y = Number(b?.lun);
          return y-x})
      }
       lun.push(dataDiskArray[0]?.lun)
    }
    let body={ 
      "name": diskName, 
      "lun":lun.length? lun[0]+1 : 0, 
      "managedDisk": { 
      "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Compute/disks/${diskName}`
      }, 
      "createOption": "attach", 
      "caching": "None", 
      "writeAcceleratorEnabled": false 
      } 
      // let vmBody=this.vmData
      // vmBody['properties'].storageProfile.dataDisks.push(body)
      this.vmData['properties'].storageProfile.dataDisks.push(body)
      this.lunArrayList.push(this.util.getForm('lunArray',body))
      // delete vmBody['resources']
      // this.UpdateVMData(this.selectedRow,vmBody,'createDisk')
      this.showExistDataDisk = false
      this.saveDisable=true
      this.sideSheetForm.get('existDataDisk')?.reset()
      await this.getDataDiskData(this.selectedRow)
      // this.getVMData('',this.selectedRow)
  }
  async createNewDisk(){
    // await this.getDiskData(this.selectedRow,'')
    let diskName=this.currentForm.get('name').value
    let diskSku= this.popupForm.get('diskSKU').value
    let diskSize= this.popupForm.get('diskSize').value
    let body={
        "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Compute/disks/${diskName}`, 
        "name": diskName, 
        "type": "Microsoft.Compute/disks", 
        "location": this.selectedRow[3], 
        "sku": { 
        "name": diskSku ? diskSku : "StandardSSD_LRS" 
        }, 
        "properties": { 
        "diskSizeGB": diskSize ? Number(diskSize) : 1024, 
        "creationData": { 
        "createOption": "empty" 
        }
        } 
    }
    this.UpdateDiskData(this.selectedRow,body,'')
  } 
  openDiskModal(template: TemplateRef<any>, showError?: any) {
    this.modalService.open(template);
  }
  async removeDataDisk(index){
    delete this.vmData?.resources
    this.vmData?.properties?.storageProfile?.dataDisks.splice(index,1)
    this.lunArrayList.removeAt(index)
    await this.UpdateVMData(this.selectedRow,this.vmData,'deleteDisk')
    await this.getDataDiskData(this.selectedRow)
    // await this.getVMData('',this.selectedRow)
  }
  async getSnapshotList(){
    this.util.handleRequest('get','a3s_management_getSnapshotList',[this.selectedRow[2]],null,null,null,true).then(async res=>{
      console.log(res)
    this.snapshotList= res.body.value
    this.snapshotList=this.snapshotList.filter(e=>e.location==this.selectedRow[3])
    })
  }
  /********************************************* Capture **************************/

  setShareImageCompute(value){
    this.saveDisable=true
    if(value == 'yes'){
      // this.currentForm.get('managedImageName').setValidators(null)
      // this.currentForm.get('targetComputeGallery').setValidators([Validators.required])
      // this.currentForm.get('versionNumber').setValidators([Validators.required])
      // this.currentForm.get('defaultReplicaCount').setValidators([Validators.required])
    }
    else{
      // this.saveDisable=true
      // this.currentForm.get('managedImageName').setValidators([Validators.required])
      // this.currentForm.get('targetComputeGallery').setValidators(null)
      // this.currentForm.get('versionNumber').setValidators(null)
      // this.currentForm.get('defaultReplicaCount').setValidators(null)
      this.inputError.managedImageNameEmpty=false;this.inputError.managedImageNameLastValid=false;
      this.inputError.managedImageNameLen=false;this.inputError.managedImageNameValid=false;
      this.currentForm.get('managedImageName').setValue(this.vmData?.properties?.storageProfile?.osDisk?.name)
    }
    this.currentForm.updateValueAndValidity()
    this.currentForm.get('shareImageComputeGallery').setValue( value == 'yes' ? true : false)
  }

  setOsState(value){
    this.saveDisable=true
    this.currentForm.get('osState').setValue(value)
    this.currentForm.get('targetImageDefinition')?.reset()
    this.getVmImageDefinitionList()
  }
  setOsType(value){
    this.sideSheetForm.get('osType').setValue(value)
  }
  setVmGen(value){
    this.sideSheetForm.get('vmGeneration').setValue(value)
  }

  setReplicationCount(event,type,i?:any){
    let value = event.target.value
    let digit=Number(value)
    this.saveDisable=true
    // digit=type=='array'?this.replicationArrayList.at(i).get('targetReplicaCount').value : digit
    // type == 'array' ? this.replicationArrayList.at(i).get('targetReplicaCount').setValue(value) :  this.currentForm.get('defaultReplicaCount').setValue(value)
    if(digit<1||digit>50) {type!='array'?this.inputError.limitNum=true:this.inputError.limitNumReplica=true}
    else{type!='array'?this.inputError.limitNum=false:this.inputError.limitNumReplica=false} 
  }

  get replicationArrayList() {
    return this.currentForm.get('replicationArray') as FormArray;
  }
  removeTag(index) {
    this.replicationArrayList.controls.splice(index, 1);
  }
  addTag(){
    this.tagsList.push(this.util.getForm('addTags'))
  }
  removeTagList(index) {
    this.tagsList.controls.splice(index, 1);
  }
  onToggle(){
   this.showNewAzureComputeGallery = !this.showNewAzureComputeGallery
   this.showExistDataDisk=!this.showExistDataDisk
  }

  addReplication(){
    // this.replicationArrayList.controls.('targetRegion').setValue(this.vmData?.location)
    this.replicationArrayList.push(this.util.getForm('replicationArray',{targetRegion:this.selectedRow[3]}))
  }
  
  removeReplication(index) {
    this.replicationArrayList.controls.splice(index, 1);
  }

  getRgBySubscription(){
    this.currentForm.get('resourceGroup').setValue(null)
    if(!this.currentForm.get('subscription').value) return;
    let subsid = this.currentForm.get('subscription').value
    let body={
        subscriptionId : subsid,
    }
    return this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
        console.log('Resource Group list ',res);
        this.resourceGroupList = res && res.value ? res.value : []
    })
  }

  async getAzureComputeGalleryList(data,type?:any){
    this.util.handleRequest('get','a3s_management_getAzureComputeGalleryList',[data[2]],null,null,null,true).then(async res=>{
      console.log(res)
      this.azureComputeGalleryList = res.body.value
    })
  }
  getGalleryName(event){
    this.azureComputeGalleryList=this.azureComputeGalleryList.filter(e=>e.id.spliy('/')[4].toLowerCase()==event.name.toLowerCase())
  }
  imageDefForm(){
    this.sideSheetForm= this.util.getForm('captureVMImageDefinition')
    let osType=this.vmData?.properties?.storageProfile?.osDisk?.osType
    let vmGen=osType.toLowerCase()=='linux'?'V2':'V1'
    this.sideSheetForm.get('osType').setValue(osType)
    this.sideSheetForm.get('vmGeneration').setValue(vmGen)
    this.sideSheetForm.get('publisher').setValue(this.vmData?.properties?.storageProfile?.imageReference?.publisher)
    this.sideSheetForm.get('offer').setValue(this.vmData?.properties?.storageProfile?.imageReference?.offer)
    this.sideSheetForm.get('sku').setValue(this.vmData?.properties?.storageProfile?.imageReference?.sku)
    this.sideSheetForm.get('osType').disable()
    this.sideSheetForm.get('vmGeneration').disable()
  }
  pushName(type?:any){
    if(type=='imageDefinitionName'){
      let newimageDefinitionName = this.sideSheetForm.get('imageDefinitionName').value
      this.currentForm.get('targetImageDefinition').setValue(newimageDefinitionName)
      this.showSubSidesheet=false
      // this.sideSheetForm.reset()
      this.vmImageDefinitionList.push({name:newimageDefinitionName})
    }else{
      let newAzureComputeGallery = this.currentForm.get('newAzureComputeGallery').value
      this.currentForm.get('targetComputeGallery').setValue(newAzureComputeGallery)
      this.getVmImageDefinitionList()
      this.onToggle()
      this.azureComputeGalleryList.push({name:newAzureComputeGallery})
    }
  }

  async getVmImageDefinitionList(event?:any){
    this.showSubSidesheet?this.sideSheetForm.get('imageDefinitionName')?.reset():false
    this.currentForm.get('targetImageDefinition')?.reset()
    let value =this.currentForm.get('targetComputeGallery')?.value
    //  event.name
    this.util.handleRequest('get','a3s_management_getAzureComputeGalleryData',[this.selectedRow[2],this.selectedRow[1],value],null,null,null,true).then(async res=>{
      console.log(res)
    this.vmImageDefinitionAllList= res.body.value
     let osType=this.vmData?.properties?.storageProfile?.osDisk?.osType
     this.vmImageDefinitionList=this.vmImageDefinitionAllList.filter(e=>e?.properties?.osType==osType&&e?.properties?.osState==this.currentForm.get('osState')?.value)
    //  this.vmImageDefinitionList.map(e=>{
    //    e['nameFormat']=`${e.name} OS type: ${e.properties?.osType}, VM generation: ${e.properties?.hyperVGeneration}`
    //  })
    })
  }
  async getVmValidVersion(event){
    let value=event.target.value
    let regex = /^[0-9]*$/
    let check=value.split('.')
    if(check.length==3 && check[2]!=''){
      this.inputError.validVersion=(/^[0-9]*$/).test(check[0])?false:true
      this.inputError.validVersion=(/^[0-9]*$/).test(check[1])?false:true
      this.inputError.validVersion=(/^[0-9]*$/).test(check[2])?false:true
    }else this.inputError.validVersion=true
    // this.inputError.valideVersion=value.includes(`${regex}.${regex}.${regex}`)?true:false
    this.inputError.nameEmpty=!value?true:false
    // let galleryName = this.currentForm.get('targetComputeGallery').value
    // let defName = this.currentForm.get('targetImageDefinition').value
    // this.util.handleRequest('get','a3s_management_getversionDetails',[this.selectedRow[2],this.selectedRow[1],galleryName,defName,value],null,null,null,true).then(async res=>{
    //   console.log(res)
    // })
  }
}
