<div class="introduction">
    
            <p class="heading" id="0">Add Role Assignment  </p>
            <ul class="mb-0" >
                <li>Click <b>Add role assignment </b>in the Users tile. </li>
                <li>Select the role you want to assign to the user.  </li>
                <li>Select the user(s) to assign the role.  </li>
                <li>Click <b>Assign. </b> </li>

            </ul>
            <img style="width: 50%;height: 50%;" src="assets\user-manual\governance\Side-sheet(4).svg" >
      
            <figcaption class="caption col-md-6" style="padding-left: 150px;">Add role assignment</figcaption>
           
           
             
</div>
