<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
              <h4>Local Network Gateway</h4>
              <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="localNetworkGatewaymodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 8px;"><i class="fa fa-trash" (click)="deleteNewResourceGroup(item,index)"></i></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 25px;"><img src="/assets/Infra/blueprint/palette/edit.svg" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="add-another-tag">
                  <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(false)">Create new
                  </p>
                </div>
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label for="localNetworkGatewayname" class="control-label ">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar || inputError?.duplicateResourceName" id="VNetName" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceName" placeholder="Enter a name" name="VirtualNetworkName" (keyup)="VNetNameValidation($event,80);niDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.VNetName">The length must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateResourceName">There is already a resource with the same name and type within the current resource group.</p>
                    <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>              
            </div>
    
            
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="endpoint" class="control-label ">Endpoint<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the endpoint type for the on-premises VPN device - IP address 
                        or FQDN (Fully Qualified Domain Name)" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="endpoint" [value]="'IPaddress'" ([ngModel])="localNetworkGatewaymodel.resourceData.endpoint" [checked]="localNetworkGatewaymodel.resourceData.endpoint=='IPaddress'" (change)="localNetworkGatewaymodel.resourceData.endpoint=$event.target.value;"><span>IP address</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="endpoint" [value]="'FQDN'" ([ngModel])="localNetworkGatewaymodel.resourceData.endpoint" [checked]="localNetworkGatewaymodel.resourceData.endpoint=='FQDN'" (change)="localNetworkGatewaymodel.resourceData.endpoint=$event.target.value;"><span>FQDN</span></label>                    
                </div>             
            </div>

            <div class="form-group" *ngIf="localNetworkGatewaymodel.resourceData.endpoint=='IPaddress'">
                <div class="label-div">
                    <label for="gatewayIpAddress" class="control-label col-sm-2">IP address<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The public IP address of your local gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.nicValidIPError || inputError?.nicRegisterIPError || inputError?.nicAddrUsedError || !inputError?.nicSubnetRangeError" id="gatewayIpAddress" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.gatewayIpAddress" placeholder="" name="gatewayIpAddress" (keyup)="nicPrivateIpAddress()" required>
                    <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="inputError?.nicValidIPError">Invalid IP address</p>
                    <p class="text-danger" *ngIf="inputError?.nicRegisterIPError">The IP address falls within the reserved IP range of subnet</p>
                    <p class="text-danger" *ngIf="inputError?.nicAddrUsedError">IP is already used</p>
                    <p class="text-danger" *ngIf="!inputError?.nicSubnetRangeError">The IP must be in the subnet</p>
                </div>              
            </div> 

            <div class="form-group" *ngIf="localNetworkGatewaymodel.resourceData.endpoint=='FQDN'">
                <div class="label-div">
                    <label for="fqdn" class="control-label col-sm-2">FQDN<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Fully-qualified domain name (FQDN) of your local gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.nameValid" id="fqdn" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.fqdn" placeholder="" name="fqdn" (keyup)="checkFqdnChar($event)" required>
                    <p class="text-danger" *ngIf="inputError?.nameValid">The specified FQDN is not valid.</p>
                </div>              
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="addressPrefixes" class="control-label ">Address space<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="One or more IP address ranges (in CIDR notation) that define your local network's address space. For example: 192.168.0.0/16. " matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box" *ngFor="let item of localNetworkGatewaymodel.resourceData.virtualNetworkAddressSpace;let i = index">
                    <div class="d-flex">
                        <input type="text" class="form-control" [class.border-danger]="item.VNetAdrspc" id="VNetAdrSpc" autocomplete="off" [(ngModel)]="item.address" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace" (keyup)="VNetAddressSpace($event,item,i)" required>
                        <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete_icon.svg" (click)="deleteAddress(i)" *ngIf="localNetworkGatewaymodel.resourceData.virtualNetworkAddressSpace.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use {{item.validCIDRValue}} address space</p>
                    <p class="text-danger" *ngIf="item.overlooping">Address spaces within a virtual network cannot overlap {{item.overlapingValue}}</p>
                    <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
                </div> 
                <div class="add-another-tag"><p class="blue-text cursor-pointer lbl-btn" (click)="addNewAddress()">Add address space</p></div>
            </div> 

            <div class="form-group">
                <div class="input-box">
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="configureBGP" id="configureBGP" [(ngModel)]="localNetworkGatewaymodel.resourceData.configureBGP" ><span>Configure BGP settings</span></label>
                    <div class="form-group" *ngIf="localNetworkGatewaymodel?.resourceData?.configureBGP">
                        <div class="label-div">
                            <label for="asn" class="control-label col-sm-2">Autonomous system number (ASN)<span class="mandatory">*<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip="A BGP-enabled connection between two network gateways requires 
                                that their ASNs be different. Additionally, the following ASNs are 
                                reserved by Azure: 8075, 8076, 12076 (public), 65515, 65517, 65518, 
                                65519, 65520 (private). You cannot specify these ASNs for your 
                                on-premises VPN devices when connecting to Azure VPN gateways. 
                                While setting up IPsec connectivity from virtual network gateways to 
                                Azure virtual WAN VPN, the ASN for Local Network Gateway is required 
                                to be 65515." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="inputError?.restoredays" id="restoredays" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.asn" placeholder="" name="asn" (keyup)="changeDaysRestore($event)" required>   
                         <p class="text-danger" *ngIf="inputError?.restoredays">Value must be a valid number.</p>
                        </div>    
                        <div class="label-div">
                            <label for="bgpPeeringAddress" class="control-label col-sm-2">BGP peer IP address<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="inputError?.nicValidIPError || inputError?.nicRegisterIPError || inputError?.nicAddrUsedError || !inputError?.nicSubnetRangeError" id="bgpPeeringAddress" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.bgpPeeringAddress" placeholder="" name="bgpPeeringAddress" (keyup)="nicPrivateIpAddress()" required>
                            <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                            <p class="text-danger" *ngIf="inputError?.nicValidIPError">Invalid IP address</p>
                            <p class="text-danger" *ngIf="inputError?.nicRegisterIPError">The IP address falls within the reserved IP range of subnet</p>
                            <p class="text-danger" *ngIf="inputError?.nicAddrUsedError">IP is already used</p>
                            <p class="text-danger" *ngIf="!inputError?.nicSubnetRangeError">The IP must be in the subnet</p>
                        </div>
                        <!-- <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="inputError?.restoredays" id="restoredays" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.bgpPeeringAddress" placeholder="" name="bgpPeeringAddress" (keyup)="changeDaysRestore($event,'restoredays')" required>
                            <p class="text-danger" *ngIf="inputError?.subnetAdrspc">This value must be less than the value (90) that's set for blob soft delete.</p>
                            <p class="text-danger" *ngIf="inputError?.restoredays">The value must be between 1 and 364.</p>
                        </div>           -->
                    </div>
                </div>
            </div>

            
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label ">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="localNetworkGatewaymodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div>
             
            
            <div class="form-group">
              <div class="label-div d-flex">
                <label class="control-label " style="min-width: max-content;">Tag name </label>
                <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                </label>
                <label style="padding-left: 6px !important;width: 18px;"
                  *ngIf="localNetworkGatewaymodel.resourceData&&localNetworkGatewaymodel.resourceData.tagList&&localNetworkGatewaymodel.resourceData.tagList.length>1"></label>
                <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
              </div>
              <div *ngFor="let item of localNetworkGatewaymodel.resourceData.tagList;let i = index">
                <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                  <div class="input-box" style="padding-bottom: 6px;">
                    <input type="text" class="form-control" id="tagName" autocomplete="off"
                      [(ngModel)]="item.tagName" name="tagName" (keyup)="checkTagInput(item,512,i)">
                  </div>

                  <div style="padding-left: 6px;padding-right: 6px;">:</div>

                  <div class="input-box">
                    <input type="text" class="form-control" id="tagValue" autocomplete="off"
                      [(ngModel)]="item.value" name="tagValue" (keyup)="checkTagInput(item,256)">
                  </div>

                  <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                      *ngIf="localNetworkGatewaymodel.resourceData.tagList.length>1"></span>
                </div>
                <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                  512.</p>
                <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                  <>%&\?/.</p>
                <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                  is 256.</p>
              </div>

              <div  class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
              </div>
            </div>
             
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
        </div>
    </div>
</div>   