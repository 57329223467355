<div class="introduction">
   
           
            <p class="heading" id="0">Group Management   </p>
            <ol>
                <li>Click on ellipsis (<img class="ellipis" src="assets\user-manual\Vector.svg" >).  </li>
                <li>Chose the operation that you want to perform:  </li>
                <ol style="list-style-type: lower-alpha;">
                    <li>Manage Owners. </li>
                    <img style="width: 50%;height: 50%;" src="assets\user-manual\governance\Side-sheet(6).svg" >
                    <li>Manage Members. </li>
                    <img style="width: 50%;height: 50%;" src="assets\user-manual\governance\Side-sheet(7).svg" >
                    <li>Edit group. </li>
                    <li>Delete group. </li>
                </ol>

            </ol>
           
           
             
</div>
