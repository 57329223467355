<div class="sidebar-right">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Virtual Network Gateway</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body" [formGroup]="vngForm">
            <div formGroupName="resourceData">
                <div class="form-group" >
                        <div class="label-div">
                            <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" formControlName="resourceGroup"  name="resourceGroup" placeholder="Choose resource group" [clearable]="false">
                            </ng-select>
                        </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="VNetName" class="control-label ">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The virtual network that will be able to send and receive traffic through 
                            this virtual network gateway. To associate a virtual network with 
                            a gateway, it must first contain a valid gateway subnet. A virtual 
                            network can’t be associated with more than one gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="VNetName" autocomplete="off"  formControlName="vnetName" name="VirtualNetworkName" >
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="gatewaySubnetAddress" class="control-label ">Gateway subnet address range<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual network 'dhvk' doesn’t contain a gateway subnet, and one must be configured before creating a virtual network gateway. Specify a subnet address range in CIDR notation which falls within the virtual network’s address space: 10.0.0.0/16. If the gateway is an ExpressRoute type and you plan on creating a VPN gateway to coexist with it, the prefix of the CIDR notation must be 27 or smaller." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="gatewaySubnetAddress" autocomplete="off" formControlName="gatewaySubnetAddress" name="VirtualNetworkName" >
                    </div>              
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="vngForm.controls.resourceName.errors?.invalidName||vngForm.controls.resourceName.errors?.maxlength||vngForm.controls.resourceName.errors?.duplicateName" id="name" autocomplete="off" formControlName="resourceName" placeholder="" name="name">
                    <p class="text-danger" *ngIf="vngForm.controls.resourceName.errors?.invalidName">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="vngForm.controls.resourceName.errors?.maxlength">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="vngForm.controls.resourceName.errors?.duplicateName">Virtual network gateway name should be unique in the selected subscription</p>
                </div>
            </div>
            <div class="form-group" formGroupName="resourceData">
                <div class="label-div">
                    <label for="gatewayType" class="control-label">Gateway type<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='To connect with an existing ExpressRoute circuit, select "ExpressRoute"' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="gatewayType" [value]="'Vpn'" formControlName="gatewayType" (change)="changeGatewayType()"><span>VPN</span></label>
                    <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="gatewayType" [value]="'ExpressRoute'" formControlName="gatewayType" (change)="changeGatewayType()"><span>ExpressRoute</span></label>                    
                </div>                     
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label ">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" [class.border-danger]="vngForm.controls.comments.errors?.maxlength" formControlName="comments"  placeholder="Describe the purpose of the resource"></textarea>
                    <p class="text-danger" *ngIf="vngForm.controls.comments.errors?.maxlength">Description cannot exceed 300 characters.</p>
                </div>                    
            </div>            
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
            
            <div *ngIf="showView" formGroupName="resourceData">
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'">
                    <div class="label-div">
                        <label for="vpnType" class="control-label">VPN type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='The type of VPN you can choose depends on the make and model of your VPN device, and the kind of VPN connection you intend to create. Choose a route-based gateway if you intend to use point-to-site, inter-virtual network, or multiple site-to-site connections; if you are creating a VPN type gateway to coexist with an ExpressRoute gateway; or if you need to use IKEv2. Policy-based gateways support only IKEv1.' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="vpnType" [value]="'RouteBased'" formControlName="vpnType" (change)="vpnType()"><span>Route-based</span></label>
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="vpnType" [value]="'PolicyBased'" formControlName="vpnType" (change)="vpnType()"><span>Policy-based</span></label>                    
                    </div>                     
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="sku" class="control-label">SKU<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The gateway SKU must be VpnGw1, VpnGw2, VpnGw3, VpnGw1AZ, 
                            VpnGw2AZ, VpnGw3Az, or HighPerformance (legacy SKU)." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="tempSKUList" bindLabel="name" bindValue="name" formControlName="sku" name="sku" placeholder="" [clearable]="false" (change)="changeSKU()" required>
                            </ng-select>
                        </div>
                </div> 
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'">
                    <div class="label-div">
                        <label for="generation" class="control-label">Generation<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Generation of VPN gateway, changing Generation or changing SKU across generation is not allowed. Basic and VpnGw1 SKUs are supported on Generation1 only, VpnGw4 and VpnGw5 SKUs are supported on Generation2 only." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="tempGenerationList" bindLabel="name" bindValue="name" formControlName="generation" name="generation"  [clearable]="false">
                        </ng-select>
                    </div>
                </div> 
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType==='Vpn'&&(vngForm.controls.resourceData.value.sku==='VpnGw1'||vngForm.controls.resourceData.value.sku==='VpnGw2'||vngForm.controls.resourceData.value.sku==='VpnGw3'||vngForm.controls.resourceData.value.sku==='VpnGw4'||vngForm.controls.resourceData.value.sku==='VpnGw5')">
                    <div class="label-div">
                        <label class="control-label text-weight">Public IP address</label>
                        <label for="publicAddressIPType" class="control-label">Public IP Address Type</label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="publicAddressIPType" [value]="'Basic'" formControlName="publicAddressIPType" (change)="changePIPSKU();changeIPAddressList();"><span>Basic</span></label>
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="publicAddressIPType" [value]="'Standard'" formControlName="publicAddressIPType" (change)="changePIPSKU();changeIPAddressList();"><span>Standard</span></label>                    
                    </div>                     
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight" *ngIf="!(vngForm.controls.resourceData.value.sku==='VpnGw1'||vngForm.controls.resourceData.value.sku==='VpnGw2'||vngForm.controls.resourceData.value.sku==='VpnGw3'||vngForm.controls.resourceData.value.sku==='VpnGw4'||vngForm.controls.resourceData.value.sku==='VpnGw5')">Public IP address</label>
                        <label for="publicIPType" class="control-label">Public IP address<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='Configure gateway IP configuration' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="publicIPType" [value]="'new'" formControlName="publicIPType" (change)="changePublicIPType()"><span>Create new</span></label>
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="publicIPType" [value]="'existing'" formControlName="publicIPType" (change)="changePublicIPType()"><span>Use existing</span></label>                    
                    </div>                     
                </div>
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.publicIPType=='new'">
                    <div class="label-div">
                        <label for="publicIPAddressName" class="control-label">Public IP address name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="vngForm.controls.resourceData.controls.publicIPAddressName.errors?.invalidName||vngForm.controls.resourceData.controls.publicIPAddressName.errors?.maxlength||vngForm.controls.resourceData.controls.publicIPAddressName.errors?.duplicateName" id="publicIPAddressName" autocomplete="off" formControlName="publicIPAddressName" placeholder="" name="publicIPAddressName">
                        <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.publicIPAddressName.errors?.invalidName">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                        <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.publicIPAddressName.errors?.maxlength">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.publicIPAddressName.errors?.duplicateName">Public IP address name should be unique in the selected subscription</p>
                    </div>
                </div>
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.publicIPType=='new'">
                    <div class="label-div">
                        <label class="control-label">Public IP address SKU</label>
                    </div>
                    <div class="label-div">
                        <label class="control-label text-weight" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'">{{vngForm.controls.resourceData.value.publicIPSKU}}</label>
                        <ng-select *ngIf="vngForm.controls.resourceData.value.gatewayType=='ExpressRoute'" [items]="tempPublicIPSKUList" bindLabel="name" bindValue="name" (change)="ipSKUChange()" formControlName="publicIPSKU"  name="publicIPSKU" placeholder="" [clearable]="false">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.publicIPType=='new'">
                    <div class="label-div">
                        <label for="assignment" class="control-label">Assignment</label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="assignment" [value]="'dynamic'" formControlName="assignment"><span>Dynamic</span></label>
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="assignment" [value]="'static'"  formControlName="assignment"><span>Static</span></label>                    
                    </div>                     
                </div>
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.publicIPType=='existing'">
                    <div class="label-div">
                        <label for="publicIPAddress" class="control-label">Choose public IP address<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="tempPublicIPAddressList" bindLabel="name" formControlName="publicIPAddress"  name="publicIPAddress" placeholder="" [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                {{item?.name}} ({{item?.properties?.ipAddress}})
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                {{item?.name}} ({{item?.properties?.ipAddress}})
                            </ng-template>
                        </ng-select>
                    </div>
                </div> 
                <div class="form-group" *ngIf="(vngForm.controls.resourceData.value.sku.includes('AZ'))&&vngForm.controls.resourceData.value.publicIPType=='new'">
                    <div class="label-div">
                        <label for="availabilityZonePIP" class="control-label">Availability zone<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="availabilityZoneList" bindLabel="name" bindValue="name" formControlName="availabilityZonePIP" name="availabilityZonePIP" placeholder="" (change)="changeAZPIP()" [clearable]="false">
                        </ng-select>
                    </div>
                </div> 
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'">
                    <div class="label-div">
                        <label for="activeMode" class="control-label">Enable active-active mode<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='The gateway SKU must be VpnGw1, VpnGw2, VpnGw3, VpnGw1AZ, 
                            VpnGw2AZ, VpnGw3Az, or HighPerformance (legacy SKU).' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="activeMode" [value]="'enabled'" formControlName="activeMode" (change)="setValidatorForAddressName();changeSecondPublicIPType();"><span>Enabled</span></label>
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="activeMode" [value]="'disabled'" formControlName="activeMode" (change)="setValidatorForAddressName();changeSecondPublicIPType();"><span>Disabled</span></label>                    
                    </div>                     
                </div>
                <div *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'&&vngForm.controls.resourceData.value.activeMode=='enabled'">
                    <div class="form-group">
                        <div class="label-div">
                            <label class="control-label text-weight">Second public IP address</label>
                            <label for="secondPIPType" class="control-label">Second public IP address<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='Configure another gateway IP configuration. SKU, Zone 
                                and Assignment will be same as first public ip address' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box d-flex">
                            <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="secondPIPType" [value]="'new'" formControlName="secondPIPType" (change)="changeSecondPublicIPType()"><span>Create new</span></label>
                            <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="secondPIPType" [value]="'existing'" formControlName="secondPIPType" (change)="changeSecondPublicIPType()"><span>Use existing</span></label>                    
                        </div>                     
                    </div>
                    <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'&&vngForm.controls.resourceData.value.activeMode=='enabled'&&vngForm.controls.resourceData.value.secondPIPType=='new'">
                        <div class="label-div">
                            <label for="secondPIPName" class="control-label">Public IP address name<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="vngForm.controls.resourceData.controls.secondPIPName.errors?.invalidName||vngForm.controls.resourceData.controls.secondPIPName.errors?.maxlength||vngForm.controls.resourceData.controls.secondPIPName.errors?.duplicateName" id="secondPIPName" autocomplete="off" formControlName="secondPIPName" placeholder="" name="secondPIPName">
                            <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.secondPIPName.errors?.invalidName">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                            <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.secondPIPName.errors?.maxlength">The name must be between 1 and 80 characters.</p>
                            <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.secondPIPName.errors?.duplicateName">Active-active mode requires two public IP addresses with different names.</p>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'&&vngForm.controls.resourceData.value.activeMode=='enabled'&&vngForm.controls.resourceData.value.secondPIPType=='existing'">
                        <div class="label-div">
                            <label for="secondPIPAddress" class="control-label">Choose public IP address<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="tempPublicIPAddressList" bindLabel="name" formControlName="secondPIPAddress" name="secondPIPAddress" placeholder="" [clearable]="false">
                                <ng-template ng-label-tmp let-item="item">
                                    {{item?.name}} ({{item?.properties?.ipAddress}})
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    {{item?.name}} ({{item?.properties?.ipAddress}})
                                </ng-template>
                            </ng-select>
                        </div>
                    </div> 
                </div>
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'&&vngForm.controls.resourceData.value.activeMode=='enabled'&&vngForm.controls.resourceData.value.secondPIPType=='new'">
                    <div class="label-div">
                        <label class="control-label">Public IP address SKU</label>
                    </div>
                    <div class="label-div">
                        <label class="control-label text-weight">Standard</label>
                    </div>
                </div>
                <div class="form-group" *ngIf="((vngForm.controls.resourceData.value.gatewayType=='Vpn'&&vngForm.controls.resourceData.value.sku.includes('AZ')))&&vngForm.controls.resourceData.value.secondPIPType=='new'&&vngForm.controls.resourceData.value.activeMode=='enabled'">
                    <div class="label-div">
                        <label for="availabilityZone" class="control-label">Availability zone<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="availabilityZoneList" bindLabel="name" bindValue="name" formControlName="availabilityZone" name="availabilityZone" placeholder="" [clearable]="false">
                        </ng-select>
                    </div>
                </div> 
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'">
                    <div class="label-div">
                        <label for="configureBGP" class="control-label">Configure BGP<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='Can be only configured with Route based VPN Gateway with 
                            supported SKU type of VpnGw1, VpnGw2, VpnGw3' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="configureBGP" [value]="'enabled'" formControlName="configureBGP" (change)="changeConfigureBGP()"><span>Enabled</span></label>
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="configureBGP" [value]="'disabled'" formControlName="configureBGP" (change)="changeConfigureBGP()"><span>Disabled</span></label>                    
                    </div>                     
                </div>
                <div *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'&&vngForm.controls.resourceData.value.configureBGP=='enabled'">
                    <div class="form-group">
                        <div class="label-div">
                            <label for="asn" class="control-label">Autonomous system number (ASN)<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='Azure virtual network gateways are assigned a default ASN of 65515. 
                                Note that a BGP-enabled connection between two network gateways 
                                requires that their ASNs be different. If needed, you can change the 
                                ASN now or after the gateway has been created.' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="vngForm.controls.resourceData.controls.asn.errors?.invalidNumber" id="asn" autocomplete="off" formControlName="asn" placeholder="" name="asn">
                            <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.asn.errors?.invalidNumber">Value must be a valid number.</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="customIPAddress" class="control-label">Custom Azure APIPA BGP IP address<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='This field is reserved for APIPA (Automatic Private IP Addressing). 
                                Azure supports BGP IP in the ranges 169.254.21.* and 169.254.22.*' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="vngForm.controls.resourceData.controls.customIPAddress.errors?.invalidIPAddress" id="customIPAddress" autocomplete="off" formControlName="customIPAddress" placeholder="" name="customIPAddress">
                            <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.customIPAddress.errors?.invalidIPAddress">Invalid IP address.</p>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="vngForm.controls.resourceData.value.gatewayType=='Vpn'&&vngForm.controls.resourceData.value.activeMode=='enabled'&&vngForm.controls.resourceData.value.configureBGP=='enabled'">
                    <div class="label-div">
                        <label for="secondCustomIPAddress" class="control-label">Second Custom Azure APIPA BGP IP address<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip='This field is reserved for APIPA (Automatic Private IP Addressing). 
                            Azure supports BGP IP in the ranges 169.254.21.* and 169.254.22.*' matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="vngForm.controls.resourceData.controls.secondCustomIPAddress.errors?.invalidIPAddress" id="secondCustomIPAddress" autocomplete="off" formControlName="secondCustomIPAddress" placeholder="" name="secondCustomIPAddress">
                        <p class="text-danger" *ngIf="vngForm.controls.resourceData.controls.secondCustomIPAddress.errors?.invalidIPAddress">Invalid IP address.</p>
                    </div>
                </div>
                <div class="form-group" formArrayName="tagList">
                    <div class="label-div d-flex">
                            <label class="control-label " style="min-width: max-content;">Tag name </label>
                            <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                            </label>
                            <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="tagList()&&tagList().controls&&tagList().controls.length>1"></label>
                    </div>
                    <div *ngFor="let item of tagList().controls;let i = index">
                        <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;" [formGroupName]="i">
                            
                            <div class="input-box" style="padding-bottom: 6px;">
                                <input type="text" class="form-control" [id]="'tagVMName'+i" autocomplete="off"
                                formControlName="tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
                            
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
                            
                            <div class="input-box">
                                <input type="text" class="form-control" [id]="'tagVMValue'+i" autocomplete="off"
                                formControlName="value" name="tagVMValue" (keyup)="checkTagInput(item,256,i)">
                            </div>
                            
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="removeTag(i)"
                                *ngIf="tagList().controls.length>1"></span>
                            </div>
                            <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                                512.</p>
                                <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                                    <>%&\?/.</p>
                        <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                        <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
                    <div class="add-another-tag">
                        <p class="blue-text cursor-pointer lbl-btn" (click)="addTags()">Add another tag</p>
                    </div>
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>    
</div>
