
<div class="blueprint-main-body" [class.isBlurred]="show">
    <div class="top-below-nav d-flex">
        <app-breadcrumbs></app-breadcrumbs>
    </div>
    <div class="border-line"></div>
    <div class= "d-flex below-nav">
        <div class="left-sidebar" [class.disabled-icon]="reviewSubmittedCheck" >
            <!-- <div class="resource-grp d-flex text-13-20">
                <div><img src="assets/Infra/blueprint/resourceGroup.svg" alt=""></div>
                <div><p class="blue-text cursor-pointer k-button" (click)="onToggle()">Create resource group</p></div>
                <kendo-popup [anchor]="anchor" (anchorViewportLeave)="showpop = false" *ngIf="showpop">
                    <div class='content'>
                        <div class="rgPopup-form">
                            <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                            <div class="input-box">
                            <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" placeholder="Enter name" name="rgName" required>
                            </div>              
                        </div>
                        <div class="rgPopup-form">
                            <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                            <div class="input-box">
                            <ng-select [items]="resourcesGroupLocationList" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                            </div>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" (click)="createNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Ok</button>
                            <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
                        </div>
                    </div>
                </kendo-popup>
            </div>
            <div class="border-line"></div> -->

            <div class="tool-group">
                <ejs-symbolpalette id="symbolpalette"  width="100%" height="100%" [symbolDragSize]="symbolDragSize"  [expandMode]="expandMode" [palettes]="palettes"  [enableSearch]=true [symbolPreview]='symbolPreview' [getSymbolInfo]='getSymbolInfo' [symbolMargin]="symbolMargin" [symbolHeight]=48 [symbolWidth]=48></ejs-symbolpalette> 
            </div>

            
        </div> 
        
        
        <div class="right-sidebar">
            <div class="tools-palette d-flex">
                <div class="tool" [class.rotateimg180]="paletteHidden" matTooltip="{{paletteStatus}} palette" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="collapseSider()" ><img src="/assets/Infra/blueprint/palette/hidePalette.svg" alt=""></div>
                <div class="tool disabled-icon" matTooltip="Undo" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"><img src="/assets/Infra/blueprint/palette/undo.svg" alt=""></div>
                <div class="tool disabled-icon" matTooltip="Redo" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"><img src="/assets/Infra/blueprint/palette/redo.svg" alt=""></div>
                <div class="vertical-line"></div>
                <div class="tool" [class.disabled-icon]="reviewSubmittedCheck||deleteIconDisable" (click)="delete()" matTooltip="Dismiss" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"><img src="/assets/Infra/blueprint/palette/dismiss.svg" alt=""></div>
                <div class="vertical-line"></div>
                <div class="tool disabled-icon" matTooltip="Cut" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"><img src="/assets/Infra/blueprint/palette/cut.svg" alt=""></div>
                <div class="tool disabled-icon" matTooltip="Copy" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"><img src="/assets/Infra/blueprint/palette/copy.svg" alt=""></div>
                <div class="tool disabled-icon" matTooltip="Paste" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"><img src="/assets/Infra/blueprint/palette/paste.svg" alt=""></div>
                <div class="tool"  [class.disabled-icon]="editSideSheetEnable" matTooltip="Edit" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="editSideSheet()"><img src="/assets/Infra/blueprint/palette/edit.svg" alt=""></div>
                <div class="vertical-line"></div>
                <div class="tool disabled-icon" matTooltip="Text" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"><img src="/assets/Infra/blueprint/palette/text.svg" alt="" ></div>
                <div class="vertical-line"></div>
                <div class="tool" matTooltip="Comments" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="toggleCommentSidebar()"><img src="/assets/Infra/blueprint/palette/comment.svg" alt=""></div>
                <div class="tool" [class.disabled-icon]="reviewSubmittedCheck" matTooltip="Save" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openModalVersionHistory(versionHistoryPopup)"><img src="/assets/Infra/blueprint/palette/save.svg" alt=""></div>
                <div class="tool" [class.disabled-icon]="!versionHistoryList?.length" matTooltip="Version History" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openModalVersionHistory(versionHistoryListPopup)"><img src="/assets/Infra/blueprint/palette/versionHistory.svg" alt=""></div>
                <div class="vertical-line"></div>
                <div class="tool" matTooltip="Download" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="download()"><img src="/assets/Infra/blueprint/palette/download.svg" alt=""></div>
                <input style="display:none" id="imDataFile" class="form-control-file" type="file">
                <div class="tool" [class.disabled-icon]="reviewSubmittedCheck || diagramApproved" (click)="openChooseFile()"><img src="/assets/Infra/blueprint/palette/upload.svg" alt=""></div>
                <div class="tool disabled-icon"><img src="/assets/Infra/blueprint/palette/print.svg" alt=""></div>
                <div class="vertical-line"></div>
                <!-- <div><button class="btn btn-outline" (click)="reviewedNotificationFunc()">temp</button></div>
                <div><button class="btn btn-outline" (click)="commentNotif()">Comment</button></div> -->

                <div class="float-right">
                    <ng-container *ngIf="!diagramApproved">
                        <button class="btn btn-btn-primary mr-8" *ngIf="reviewSubmittedCheck" (click)="diagramApprovedHelper()">Approve</button>
                        <button class="btn btn-btn-primary" [class.disabled]="reviewSubmittedCheck||!groupRd||!groupRd?.virtualNetwork||!groupRd?.virtualNetwork?.length" (click)="checkBeforeSubmitForReview(submitForReview)">Submit for review</button>
                    </ng-container>
                    <ng-container *ngIf="diagramApproved && status !== 'DEPLOYED' && deploymentStatus !== 'started'">
                        <button class="btn btn-outline mr-8" (click)="openModal(enableEdit)">Enable editing</button>
                        <button class="btn btn-btn-primary" [class.disabled]="disableDeployBtn" (click)="deployBlueprint()">Deploy</button>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="notificationObject.show" class="notification-strip" [class.notification-strip-brown]="notificationObject.style=='brown' " [class.notification-strip-blue]="notificationObject.style=='blue' " [class.notification-strip-black]="notificationObject.style=='black' ">
                <p >{{notificationObject.text}}</p>
            </div>
            <div class="notification-strip notification-strip-white" *ngIf="deploymentStatus == 'started'">
                <div class="flex-custom no-wrap">
                    <p>Architecture blueprint is being deployed...</p>
                    <span class="progress ml-3 width-200">
                        <span class="progress-bar progress-bar-success" [style.width]="overallPercentage+'%'"></span>
                    </span>
                    <span class="progress-percent blur-text ml-3">{{overallPercentage}}%</span>
                </div>
            </div>
            <div class="notification-strip notification-strip-blue" *ngIf="deploymentStatus == 'success'">
                <div class="flex-custom no-wrap">
                    <img src="assets/icons/tick.svg" class="ml-3">
                    <p>Architecture blueprint is deployed successfully.</p>
                </div>
            </div>
            <div class="notification-strip notification-strip-red" *ngIf="deploymentStatus == 'failed'">
                <div class="flex-custom no-wrap">
                    <img src="assets/icons/cross-fail.svg" class="ml-3">
                    <p>Architecture blueprint failed to deploy.</p>
                </div>
            </div>

            <div class="container-drag">
                <div class="canvas">
                    <ejs-diagram #diagram id="diagram" width="100%" (collectionChange)="collectionChange($event)" (propertyChange)='connectionChange($event)' (drop)="drop($event)" (dragEnter)="dragEnter($event)" (dragLeave)="dragLeave($event)" (selectionChange)='selectionItems($event)' (positionChange)='nodePositionChange($event)' (sizeChange)='nodeSizeChange($event)'   [snapSettings]='snapSettings'></ejs-diagram>
                </div>
                <div class="drag-icon-middle" *ngIf="isDiagramCanvasEmpty()" [class.hide-drag-drop]="!isDiagramCanvasEmpty()">
                    <div class="drag-icon"><img src="/assets/Infra/blueprint/drag&drop.svg" alt=""></div>
                    <div class="drag-msg"><h4>Drag objects from the left panel and drop on your canvas</h4></div>    
                </div>
            </div>            
           
        </div>

    </div>
</div>
<div class="" *ngIf="show">
    <!-- <app-resource-templates [templateName]="currentTemplate" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-resource-templates> -->
    <app-architecture-resources [resourceName]="currentTemplate" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-architecture-resources>
</div>



<!-- --------------------------------------popups------------------------------------- -->
<ng-template #unsavedChanges>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>You have unsaved changes</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>Do you want to save before you leave this page?</p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-outline" (click)="leavePage()">No</button>
            <button class="btn btn-btn-primary" (click)="savePageData()">Yes</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUseVN>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add a subnet</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>Subnet cannot be added outside a virtual network. Please create a virtual network first, or make sure the subnet is added inside an existing virtual network.</p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUsepublicIP>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUsenetworkInterface>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUseconnections>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUserouteTable>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUsekeyVault>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUsenetworklocalNetworkGateway>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUsenetworkloadBalancer>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUselogAnalytics>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUsestorageAccount>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUseapplicationInsight>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUsedisk>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUsensg>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>This resource cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUseSubnet>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to drop VM</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>Virtual machine cannot be added outside a subnet. You are required to first create virtual network and subnet then VM. </p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToUseGatewaySubnet>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to drop VM</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>Virtual machine cannot be added to a GatewaySubnet.</p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>
<ng-template #unableToUseVNG>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to add this resource</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>Virtual Network Gateway can only be added inside GatewaySubnet.</p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>
<ng-template #unableToUseVNGForGateway>
    <div class="medium-popup-wrapper2 width-510 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Unable to Add Virtual Network Gateway</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>Only one Virtual Network Gateway can be used 
                    for a Virtual Network.</p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-btn-primary" (click)="closeModal()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #submitForReview>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Submit for review</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>Editing the architecture blueprint will be disabled as long as it is under review.</p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-outline" (click)="closeModal()">Cancel</button>
            <button class="btn btn-btn-primary" (click)="submitForReviewHelper()">Submit</button>
        </div>
    </div>
</ng-template>

<ng-template #enableEdit>
    <div class="medium-popup-wrapper2 width-426 ">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Enable editing</h3></div>
                <button class="cursor-pointer" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <p>Once you enable editing, you will not be able to deploy before the architecture blueprint is approved</p>
            </div>
        </div>
        <div class="modal-footer float-right d-flex">
            <button class="btn btn-outline" (click)="closeModal()">Cancel</button>
            <button class="btn btn-btn-primary" (click)="moveBackToPending()">Enable</button>
        </div>
    </div>
</ng-template>


<!-- ////////////////////////////////////// Comments Sidebox ////////////////////////////////////////////// -->
<div class="comment-sidebar" *ngIf="showCommentSection">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Comments</h4>
                <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="toggleCommentSidebar()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
            </div>
        </div>
        <div class="comments-body">
            <div class="search-box">
                <div><textarea name="comment" id="" placeholder="Enter your comment" [(ngModel)]="commentObject.comment"></textarea></div>
                <div class="send-btn float-right"><button class="btn btn-btn-primary" (click)="updateComment()" [class.disabled]="!commentObject?.comment">Post</button></div>
            </div>

            <div class="comments">
                <div class="comments-list-only">
                    <ng-container *ngFor="let comment of commentsList" >
                        <div *ngIf="comment.createdBy==user">
                            <div class="sent-comment">
                                <p class="name">{{comment.createdBy}} 
                                    <span class="float-right" *ngIf="comment.commentId > commentToDeleteId">
                                        <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="profile">
                                        <mat-menu #profile="matMenu" class="delete-popup">
                                          <div class="" mat-menu-item>
                                            <div class="d-flex align-items-center" mat-stroked-button (click)="deleteComment(comment.commentId)" >
                                              <img src="assets/icons/delete.png" class="img-fluid p-2 img-responsive" />
                                              <p class="m-0 delete-popup-text">Delete Comment</p>
                                            </div>
                                          </div>
                                        </mat-menu>
                                      </span>
                                </p>
                                <p class="comment-content grey-back">{{comment.comment}}</p>
                                <p class="time">{{changeTimeFormat(comment.createdDate)}}</p>
                            </div>
                        </div>
                        <div *ngIf="comment.createdBy!=user">
                            <div class="received-comment">
                                <p class="name">{{comment.createdBy}}</p>
                                <p class="comment-content blue-back bold-600" *ngIf="comment.comment == 'Review completed' && comment.createdBy == 'A3S' ">{{comment.comment}}</p>
                                <p class="comment-content blue-back" *ngIf=" !(comment.comment == 'Review completed' && comment.createdBy == 'A3S' ) ">{{comment.comment}}</p>
                                <p class="time">{{changeTimeFormat(comment.createdDate)}}</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
                
            </div>
            
        </div>
    </div>
</div>

<!-- /////////////////////////////////////////////// Version History Popup ///////////////////////////////////////////////////// -->
<ng-template #versionHistoryPopup>
    <div class="version-history-popup width-426 modal-lg " [class.mt-0]="viewVersionHistoryDropdown">
            <div class="modal-header-diagram-tool">
                <h3 class="save-version-heading">Save Changes</h3>
                <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body-diagram-tool">
                <div class="text-left">
                    <!-- <div class="col-12">
                        <input type="text" class="margin-auto" id="title" autocomplete="off" [(ngModel)]="versionHistory.title" name="title" placeholder="Enter your title" required>
                    </div> -->
                    <label for="description" class="desciption-header">Description<span class="mandatory">*</span></label>
                    <textarea class="comment-description" id="description" [customAutofocus]="true" [(ngModel)]="versionHistory.description" name="description" placeholder="Describe the changes you have made" required></textarea>
                </div>
                <div class="bottom-btn-line">
                    <button class="btn btn-btn-primary pull-right" (click)="saveVersionHistory()" [class.disabled]="!versionHistory?.description">Save</button> 
                    <button class="btn btn-outline ml-auto pull-right" (click)="closeModal()">Cancel</button>
                </div>
                
            </div> 
    </div>
</ng-template>

<ng-template #versionHistoryListPopup>
    <div class="version-history-popup width-426 modal-lg" *ngIf="versionHistoryList?.length" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool">
            <h3 class="save-version-heading">Version history</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool-hidden">
            <div *ngFor="let list of versionHistoryShortList">
                <div class="mat-divider">
                    <p class="user-info">Saved by {{ list?.CreatedBy }} on {{ list?.CreatedDate }}</p>
                    <p class="description">{{ list?.blueprintDescription }}</p>
                </div>
            </div>
            <a href="javascript: void(0);" *ngIf="showMore && !fullList" (click)="toggleFullList()" class="blue-text text-12-18">Show more</a>
            <a href="javascript: void(0);" *ngIf="fullList" (click)="toggleFullList()" class="blue-text text-12-18">Show less</a>
            <button class="btn btn-btn-primary pull-right" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #updateResourceDataAlert>
    <div class="version-history-popup width-426 modal-lg" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool" style="margin-right: 8px;">
            <h3 class="save-version-heading">Update Virtual Network</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool">
            <div class="text-left" style="padding-top: 8px;padding-bottom: 16px;">
                <label for="description" style="font-size: 14px;" class="desciption-header">You are changing the details, hence you need to update subnet details. <br><br>If you wish to update, then you will be navigated to subnet details.</label>
            </div>
            <button class="btn btn-btn-primary pull-right" style="margin-left: 8px;" (click)="closeModal();editSideSheet()">Update</button>
            <button class="btn btn-outline ml-auto pull-right" (click)="closeModal()">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #generateNewKeyPair>
    <div class="version-history-popup width-426 modal-lg" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool">
            <h3 class="save-version-heading">Generate New Key Pair</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal();disableDeployBtn=false;">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool">
            <div class="d-flex" style="background: #F4FBFF;         
            border: 1px solid #4AB5FB;
            box-sizing: border-box;
            border-radius: 2px;margin: 10px 0px;">
                <label class="" style="padding: 11.33px 9.33px 0px 9.33px !important;"><img  src="/assets/Infra/blueprint/popup-error-info.png" alt=""></label>
                <label style="font-size: 12px;color: #21262C;padding: 8px 12px 0px 0px;">SSH key pair contains both a public key and a private key. Dynapt doesn’t store the private key. After the SSH key is created, <span style="font-weight: 700;">you won’t be able to download the private key again.</span></label>
            </div>
            <button class="btn btn-btn-primary col-sm-12" style="margin-bottom: 12px;" (click)="closeModal();downloadDeploy();">Download private key and deploy</button>
            <button class="btn btn-outline ml-auto col-sm-12" (click)="closeModal();disableDeployBtn=false;">Return to blueprint</button>
        </div>
    </div>
</ng-template>

<ng-template #virtualMachineSizePopup>
    <div class="virtual-machine-size-popup modal-lg" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool" style="margin-right: 8px;">
            <h3 class="save-version-heading">Select a VM size</h3>
            <button type="button" class="close pull-right margin-close-btn" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool">
            <div class="form-group">
                <div class="label-div">
                    <label for="series" class="control-label" style="padding: 0px; padding-bottom: 6px;">Select series</label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: 40%;font-weight: 400;font-size: 13px;" [items]="vmSeriesList" bindLabel="name" bindValue="name" [(ngModel)]="vmSeries" name="series" placeholder="Choose Series" (change)="vmSizeListFilter()" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="custom-fixed-tables" style="max-height: calc(100vh - 410px);">
                  <table class="fix-width-vms table">
                      <thead>
                        <tr>
                          <th class="table-name">
                            <div class="flex-custom no-wrap">
                              <span>VM size</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortVMSizeData('vmSize')">
                            </div>
                          </th>
                          <th class="status">
                            <div class="flex-custom no-wrap">
                              <span>vCPUs</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortVMSizeData('vCPUs')">
                            </div>
                          </th>
                          <th class="status">
                              <div class="flex-custom no-wrap">
                                <span>RAM (GiB)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortVMSizeData('RAM')">
                              </div>
                          </th>
                          <th class="status">
                            <div class="flex-custom no-wrap">
                              <span>Data disks</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortVMSizeData('dataDisk')">
                            </div>
                        </th>
                          <th style="border-right: solid 1px #8CD4FD;">
                              <div class="flex-custom no-wrap">
                                  <span>Max IOPS</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortVMSizeData('maxIOPS')">
                              </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let list of dummyVMSizeList;let index = index" [ngClass]="{'clicked-row':list.activeRow}">
                          <td (click)="selectVmSize(list);">{{list?.size}}</td>
                          <td (click)="selectVmSize(list);">{{list?.newCapabilities?.vCPUs?.value}}</td>
                          <td (click)="selectVmSize(list);">{{list?.newCapabilities?.MemoryGB?.value}}</td>
                          <td (click)="selectVmSize(list);">{{list?.newCapabilities?.MaxDataDiskCount?.value}}</td>
                          <td (click)="selectVmSize(list);">{{list?.newCapabilities?.UncachedDiskIOPS?.value}}</td>
                        </tr>
                        <div class="col-md-12 mt-3" *ngIf="!dummyVMSizeList?.length">
                          <p class="content-14 blur-text">No data Found</p>
                        </div>
                      </tbody>
                  </table>
              </div>
              <div class="fixed-popup-btm-btn">
                <button class="btn btn-btn-primary pull-right" [class.disabled]="!selectVmSizeData"
                  (click)="closeModal();selectedVmList();">Select</button>
              </div>
        </div>
    </div>
</ng-template>

<ng-template #virtualMachineDiskSizePopup>
    <div class="virtual-machine-size-popup modal-lg" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool" style="margin-right: 8px;">
            <h3 class="save-version-heading">Select a disk size</h3>
            <button type="button" class="close pull-right margin-close-btn" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool" style="max-height: calc(100vh - 240px);">
            <div class="form-group">
                <div class="label-div">
                    <label for="diskSKU" class="control-label" style="padding: 0px; padding-bottom: 6px;">Disk SKU<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Premium SSD disks offer high-performance, low-latency disk support for I/O-intensive applications and production workloads. Standard SSD Disks are a cost effective storage option optimized for workloads that need consistent performance at lower IOPS levels. Use Standard HDD disks for Dev/Test scenarios and less critical workloads at lowest cost." container="body" placement="above" containerClass="disk-tooltip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: 53%;font-weight: 400;font-size: 13px;" [items]="diskSKUList" bindLabel="name" [(ngModel)]="vmDisk.diskSKU" name="diskSKU" placeholder="Choose disk SKU" (change)="vmDiskSizeListFilter()" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="custom-fixed-tables" style="height: calc(100vh - 410px);max-height: 223px; min-height: 136px;">
                  <table class="fix-width-vms table">
                      <thead>
                        <tr>
                          <th class="table-name">
                            <div class="flex-custom no-wrap">
                              <span>Size (GiB)</span>
                            </div>
                          </th>
                          <th class="status">
                            <div class="flex-custom no-wrap">
                              <span>Performance tier</span>
                            </div>
                          </th>
                          <th class="status">
                              <div class="flex-custom no-wrap">
                                <span>Provisioned IOPS</span>
                              </div>
                          </th>
                          <th class="status" style="border-right: solid 1px #8CD4FD;">
                            <div class="flex-custom no-wrap">
                              <span>Provisioned throughput</span>
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let list of dummyVMDiskSizeList;let index = index" [ngClass]="{'clicked-row':list.activeRow}">
                          <td style="width: 25%;" (click)="selectDiskSize(list,true);">{{list?.capabilities[0]?.value}}</td>
                          <td style="width: 25%;" (click)="selectDiskSize(list,true);">{{list?.size}}</td>
                          <td style="width: 25%;" (click)="selectDiskSize(list,true);">{{list?.capabilities[2]?.value}}</td>
                          <td style="width: 25%;" (click)="selectDiskSize(list,true);">{{list?.capabilities[4]?.value}}</td>
                        </tr>
                        <div class="col-md-12 mt-3" *ngIf="!dummyVMDiskSizeList?.length">
                          <p class="content-14 blur-text">No data Found</p>
                        </div>
                      </tbody>
                  </table>
              </div>
              <div class="form-group">
                <div class="label-div">
                    <label for="customDiskSizeAdd" class="control-label" style="padding: 0px; padding-bottom: 6px;">Custom disk size (GiB)<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enter the size of the disk you would like to create." matTooltipClass="nav-tooltips" container="body" placement="above" containerClass="disk-tooltip" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="number" style="width: 53%;" class="form-control remove-arrows" [class.border-danger]="inputError?.VMname" id="customDiskSizeAdd" autocomplete="off" [(ngModel)]="vmDisk.customDiskSizeAdd" placeholder="" name="customDiskSizeAdd" (keyup)="customDiskSizeChange()" required>
                    <p class="text-danger" *ngIf="inputError?.VMname">The value must be between 1 and 32767.</p>
                </div>   
            </div>
              <div class="form-group" *ngIf="vmDisk.diskSKU.id=='Premium_LRS'">
                <div class="label-div">
                    <label for="performanceTier" class="control-label" style="padding: 0px; padding-bottom: 6px;">Performance tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Changing the performance tier allows you to prepare for and meet higher demand without using your disk's bursting capability." container="body" placement="above" containerClass="disk-tooltip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: 53%;font-weight: 400;font-size: 13px;" [items]="vmDisk.performanceTierList" bindLabel="name" [disabled]="vmDisk?.performanceTierList?.length==0&&vmDisk?.customDiskSizeAdd" [(ngModel)]="vmDisk.performanceTier" name="performanceTier" placeholder="Select a performance tier to upgrade" [clearable]="false">
                        <ng-template ng-label-tmp let-item="item">
                            {{item?.size+' - '+item?.capabilities[0]?.value +' IOPS, '+item?.capabilities[2]?.value+' MBps'}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{item?.size+' - '+item?.capabilities[0]?.value +' IOPS, '+item?.capabilities[2]?.value+' MBps'}}
                        </ng-template>
                    </ng-select>
                    <p class="text-muted-msg" *ngIf="vmDisk?.performanceTierList?.length==0&&vmDisk?.customDiskSizeAdd">Upgraded tiers are not available for the selected size.</p>
                </div>   
            </div>
            <div class="fixed-popup-btm-btn">
              <button class="btn btn-btn-primary pull-right"
                [class.disabled]="!vmDisk.selectVmDiskSizeData||!vmDisk.customDiskSizeAdd"
                (click)="closeModal();selectedVMDisk();">Select</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #virtualMachineInboundRulePopup>
    <div class="virtual-machine-size-popup modal-lg" style="width: 478px;" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool">
            <h3 class="save-version-heading">{{vmInboundRule.type=='inboundRules'?'Add inbound security rule':'Add outbound security rule'}}</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool" style="margin: 0px;padding: 16px 24px;">
            <div >
                <!-- style="    height: calc(170vh - 300px);" -->
            <div class="form-group">
                <div class="label-div">
                    <label for="source" class="control-label" style="padding: 0px; padding-bottom: 6px;">Source<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="It specifies the incoming traffic from a specific source IP address range that will be allowed or denied by this rule." container="body" placement="above" containerClass="disk-tooltip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: auto;font-weight: 400;font-size: 13px;" [items]="vmSourceList" bindLabel="name" bindValue="name" [(ngModel)]="vmInboundRule.source" name="source" placeholder="Choose disk SKU" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group" *ngIf="vmInboundRule.source=='IP addresses'">
                <div class="label-div">
                    <label for="addressSpace" class="control-label" style="width: 100%;padding-left: 0px;">Source IP addresses/CIDR ranges<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address (e.g. 192.168.99.0). You can also provide a comma-separated list of IP addresses or address ranges using IPv4." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.sourceInvalidArgument||inputError?.sourceNotValidCIDR||inputError?.sourceOverlaping||inputError?.sourceNonNullRange" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="vmInboundRule.sourceAddressSpace" placeholder="10.0.0.0/24" name="addressSpace" (keyup)="validNICAddressSpace($event,'source')" required>
                    <p class="text-danger" *ngIf="inputError?.sourceInvalidArgument">Invalid argument: 'Malformed IP address</p>
                    <p class="text-danger" *ngIf="inputError?.sourceNotValidCIDR">Invalid CIDR Block.</p>
                    <p class="text-danger" *ngIf="inputError?.sourceOverlaping">Overlaps address space</p>
                    <p class="text-danger" *ngIf="inputError?.sourceNonNullRange">Invalid argument: A non-null address range is required.</p>
                </div>              
            </div> 
              <div class="form-group">
                <div class="label-div">
                    <label for="sourcePortRange" class="control-label" style="padding: 0px; padding-bottom: 6px;">Source port ranges<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a single port, such as 80; a port range, such as 1024-65535; or a comma-separated list of single ports and/or port ranges, such as 80,1024-65535. This specifies on which ports traffic will be allowed or denied by this rule. Provide an asterisk (*) to allow traffic on any port."  container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.sourceNotValidPortVal||inputError?.sourceInvalidPortRange||inputError?.sourceOverlap" id="sourcePortRange" autocomplete="off" [(ngModel)]="vmInboundRule.sourcePortRange" placeholder="" name="sourcePortRange" (keyup)="portRangeValidation($event,65535,'source')" required>
                    <p class="text-danger" *ngIf="inputError?.sourceNotValidPortVal">The port must be a number between 0 and 65535.</p>
                    <p class="text-danger" *ngIf="inputError?.sourceInvalidPortRange">Invalid port range specified.</p>
                    <p class="text-danger" *ngIf="inputError?.sourceOverlap">Overlapping ports are not permitted</p>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="destination" class="control-label" style="padding: 0px; padding-bottom: 6px;">Destination<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="It specifies the outgoing traffic for a specific destination IP address range that will be allowed or denied by this rule." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: auto;font-weight: 400;font-size: 13px;" [items]="destinationList" bindLabel="name" bindValue="name" [(ngModel)]="vmInboundRule.destination" name="destination" placeholder="Select destination" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group" *ngIf="vmInboundRule.destination=='IP addresses'">
                <div class="label-div">
                    <label for="addressSpace" class="control-label col-sm-12" style="width: 100%;padding-left: 0px;">Destination IP addresses/CIDR ranges<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address (e.g. 192.168.99.0). You can also provide a comma-separated list of IP addresses or address ranges using IPv4." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.destInvalidArgument||inputError?.destNotValidCIDR||inputError?.destOverlaping||inputError?.destNonNullRange" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="vmInboundRule.destinationAddressSpace" placeholder="10.0.0.0/24" name="addressSpace" (keyup)="validNICAddressSpace($event,'dest')" required>
                    <p class="text-danger" *ngIf="inputError?.destInvalidArgument">Invalid argument: 'Malformed IP address</p>
                    <p class="text-danger" *ngIf="inputError?.destNotValidCIDR">Invalid CIDR Block.</p>
                    <p class="text-danger" *ngIf="inputError?.destOverlaping">Overlaps address space</p>
                    <p class="text-danger" *ngIf="inputError?.destNonNullRange">Invalid argument: A non-null address range is required.</p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="Service" class="control-label" style="padding: 0px; padding-bottom: 6px;">Service<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The destination filter can be Any, an IP address range, an application security group, or a default tag. It specifies the outgoing traffic for a specific destination IP address range that will be allowed or denied by this rule." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: auto;font-weight: 400;font-size: 13px;" [items]="vmServiceList" bindLabel="name" bindValue="name" [(ngModel)]="vmInboundRule.service" name="Service" placeholder="Select service" (change)="changeVmService()" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="destinationPortRange" class="control-label" style="padding: 0px; padding-bottom: 6px;width: 100%;">Destination port ranges<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a single port, such as 80; a port range, such as 1024-65535; or a comma-separated list of single ports and/or port ranges, such as 80,1024-65535. This specifies on which ports traffic will be allowed or denied by this rule. Provide an asterisk (*) to allow traffic on any port." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.destNotValidPortVal||inputError?.destInvalidPortRange||inputError?.destOverlap" [disabled]="vmInboundRule.disableBasedOnService" id="destinationPortRange" autocomplete="off" [(ngModel)]="vmInboundRule.destinationPortRange" placeholder="" name="destinationPortRange" (keyup)="portRangeValidation($event,65535,'dest')" required>
                    <p class="text-danger" *ngIf="inputError?.destNotValidPortVal">The port must be a number between 0 and 65535.</p>
                    <p class="text-danger" *ngIf="inputError?.destInvalidPortRange">Invalid port range specified.</p>
                    <p class="text-danger" *ngIf="inputError?.destOverlap">Overlapping ports are not permitted</p>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="protocol" class="control-label" style="padding: 0px; padding-bottom: 6px;">Protocol</label>
                </div> 
                <div class="input-box radio-flex">
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="protocol" [disabled]="vmInboundRule.service!='Custom'" [value]="'Any'" ([ngModel])="vmInboundRule.protocol" [checked]="vmInboundRule.protocol=='Any'" (change)="vmInboundRule.protocol=$event.target.value;"><span style="padding-left: 8px;">Any</span></label>
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="protocol" [disabled]="vmInboundRule.service!='Custom'" [value]="'TCP'" ([ngModel])="vmInboundRule.protocol" [checked]="vmInboundRule.protocol=='TCP'" (change)="vmInboundRule.protocol=$event.target.value;"><span style="padding-left: 8px;">TCP</span></label>                    
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="protocol" [disabled]="vmInboundRule.service!='Custom'" [value]="'UDP'" ([ngModel])="vmInboundRule.protocol" [checked]="vmInboundRule.protocol=='UDP'" (change)="vmInboundRule.protocol=$event.target.value;"><span style="padding-left: 8px;">UDP</span></label>                    
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="protocol" [disabled]="vmInboundRule.service!='Custom'" [value]="'ICMP'" ([ngModel])="vmInboundRule.protocol" [checked]="vmInboundRule.protocol=='ICMP'" (change)="vmInboundRule.protocol=$event.target.value;"><span style="padding-left: 8px;">ICMP</span></label>                    
                </div>    
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="action" class="control-label" style="padding: 0px; padding-bottom: 6px;">Action</label>
                </div> 
                <div class="input-box radio-flex">
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="action" [value]="'Allow'" ([ngModel])="vmInboundRule.action" [checked]="vmInboundRule.action=='Allow'" (change)="vmInboundRule.action=$event.target.value;"><span style="padding-left: 8px;">Allow</span></label>
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="action" [value]="'Deny'" ([ngModel])="vmInboundRule.action" [checked]="vmInboundRule.action=='Deny'" (change)="vmInboundRule.action=$event.target.value;"><span style="padding-left: 8px;">Deny</span></label>                       
                </div>    
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="priority" class="control-label" style="padding: 0px; padding-bottom: 6px;">Priority<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Rules are processed in priority order; the lower the number, the higher the priority. We recommend leaving gaps between rules - 100, 200, 300, etc. - so that it's easier to add new rules without having to edit existing rules." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.VMPriorityValid||inputError?.VMPriorityMaxMin||inputError?.VMPriorityDupCheck" id="priority" autocomplete="off" [(ngModel)]="vmInboundRule.priority" placeholder="" name="priority" (keyup)="validateVMPriority($event)" required>
                    <p class="text-danger" *ngIf="inputError?.VMPriorityValid">Value must be a valid number.</p>
                    <p class="text-danger" *ngIf="inputError?.VMPriorityMaxMin">The priority must be between 100 and 4096.</p>
                    <p class="text-danger" *ngIf="inputError?.VMPriorityDupCheck">This priority already used.</p>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label" style="padding: 0px; padding-bottom: 6px;">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.nameLen||inputError?.nameValid ||inputError?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="vmInboundRule.name" placeholder="" name="name" (keyup)="validateInOutBoundName($event,80)" required>
                    <p class="text-danger" *ngIf="inputError?.nameLen">The name cannot be longer than 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="inputError?.nameDupCheck">Name already taken.</p>
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label" style="padding: 0px; padding-bottom: 6px;">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" style="width:100%;"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="vmInboundRule.comments" placeholder="" (keyup)="VmDescription($event,140)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">The description cannot be longer than 140 characters.</p>
                </div>   
            </div>
        </div>
            <div class="fixed-popup-btm-btn">
                <button class="btn btn-btn-primary pull-right" [class.disabled]="disableInOutBound()"  (click)="addInOutBoundRule();closeModal();">{{currentResourceDtls?.updateIndex!=undefined?'Update':'Add'}}</button>
            </div>
        </div>
    </div>
</ng-template>
