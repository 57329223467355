<div class="security-alert-management-container">
  <div class="header-container">
    <h2 class="title">Alert Management</h2>
    <!-- <span>{{currentProject?.projectName}}</span> -->
  </div>
 
   <!-- Filter Section -->
   <div class="custom-filter-wrapper flex-custom">
    <div class="custom-filter">
        <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
     
        <div class="filter-dropdown" id="filter-dropdown" >
            <div class="enlist-srch">
                <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                <img src="assets/icons/search.png">
            </div>
            <div class="filter-content">
                <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                    <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                    <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                </div>
                <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                    <p  class="content-14 blur-text">No filter found</p>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-filter">
        <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
        <div class="filter-dropdown" id="filter-dropdown" >
            <div class="enlist-srch">
                <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                <img src="assets/icons/search.png">
            </div>
            <div class="filter-content">
                <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                    <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                    <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                </div>
                <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                    <p  class="content-14 blur-text">No filter found</p>
                </div>
            </div>
           
        </div>
    </div>
    <div class="custom-filter">
        <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
        <div class="filter-dropdown" id="filter-dropdown" >
            <div class="enlist-srch">
                <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                <img src="assets/icons/search.png">
            </div>
            <div class="filter-content">
                <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                    <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                    <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                </div>
                <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                    <p  class="content-14 blur-text">No filter found</p>
                </div>
            </div>
        </div>
    </div>
    <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
        <img src="/assets/common-icons/close-filter.svg" alt="">
        Clear Filter
    </button>
   </div>


  <div class="dashboard-body">
    <div class="data-wrap tiles">
      <div class="data-card row mt-2">
          <div class="col-sm-12 title-div">
              <label class="title">Alerts</label>
          </div>
          <div class="col-sm-12 card-sections">
          <img src="./assets/security/security-alerts.svg">
          <div class="ml-2 full-width">
            <div class="name-txt">Active alerts</div>
            <div class="val-text">{{filterAlertStatus('Active')}}</div>
          </div>
          <img src="./assets/security/resources-affected.svg">
          <div class="ml-2 full-width">
            <div class="name-txt">Resources affected</div>
            <div class="val-text">{{getAlertResourceAffected()}}</div>
          </div>
          <div class="ml-2 full-width" style="width: 123.59% !important;" *ngIf="alertsData?.tableData?.length">
            <div class="name-txt">Active alerts by severity</div>
            <span class="progress progress-bar-mutliple w-92 mb-2">
              <span class="progress-bar progress-bar-custom-high" [style.width]="filterAlertSeverity('High','perc')+'%'"></span>
              <span class="progress-bar progress-bar-custom-meduim" [style.width]="filterAlertSeverity('Medium','perc')+'%'"></span>
              <span class="progress-bar progress-bar-custom-low" [style.width]="filterAlertSeverity('Low','perc')+'%'"></span>
            </span>
            <div class="severty-legend">
                <span *ngIf="filterAlertSeverity('High','data')?.length"><img src="./assets/security/severty-high.svg">High ({{filterAlertSeverity('High','data')?.length}})</span>
                <span *ngIf="filterAlertSeverity('Medium','data')?.length"><img src="./assets/security/severty-meduim.svg">Meduim ({{filterAlertSeverity('Medium','data')?.length}})</span>
                <span *ngIf="filterAlertSeverity('Low','data')?.length"><img src="./assets/security/severty-low.svg">Low ({{filterAlertSeverity('Low','data')?.length}})</span>
            </div>
          </div>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 padding-div">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Alerts</h4>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('alerts')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('Alerts','table1')" *ngIf="alertsData?.tableData?.length"><img src="assets/security/download_icon.svg" alt=""></span>
                    </div> 
                </div>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="alertsData?.tableData?.length && !alertsData?.showLoader" >
              <table class="table" id="table1">
                <thead>
                  <tr>
                    <th>
                        <div class="flex-custom no-wrap">
                            <span>Severity</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','severity')">
                        </div>
                    </th>
                    <th>
                        <div class="flex-custom no-wrap">
                            <span>Alert title</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','alertDisplayName')">
                        </div>
                    </th>
                    <th>
                        <div class="flex-custom no-wrap">
                            <span>Affected resource</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','compromisedEntity')">
                        </div>
                    </th>
                    <th>
                        <div class="flex-custom no-wrap">
                            <span>Resource type</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','resourceType')">
                        </div>
                    </th>
                    <th>
                      <div class="flex-custom no-wrap">
                          <span>Resource group</span>
                          <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','id')">
                      </div>
                  </th>
                  <th>
                    <div class="flex-custom no-wrap">
                        <span>Start time (UTC)</span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','startTimeUtc')">
                    </div>
                </th>
                  <th>
                    <div class="flex-custom no-wrap">
                        <span>MITRE ATT&CK® tactics<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="MITRE ATT&CK® is a globally-accessible knowledge base of 
                            adversary tactics and techniques based on real-world observations.​" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span>
                        <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','intent')">
                    </div>
                </th>
                    <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Status</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('alertsData','status')">
                        </div>
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngFor="let row of alertsData.tableData">
                      <td isEllipsisActive> <span [ngClass]="row?.properties?.severity=='Medium'?'medium-severity':row?.properties?.severity=='Low'?'low-severity':row?.properties?.severity=='High'?'high-severity':''"></span>{{row?.properties?.severity | displayTxt}}</td>
                      <td isEllipsisActive><a class="cursor-pointer" (click)="openAlertDtls(row)">{{row?.properties?.alertDisplayName}}</a></td>
                      <td isEllipsisActive>{{row?.properties?.compromisedEntity | displayTxt}}</td>
                      <td isEllipsisActive>{{filterResourceType(row?.properties?.resourceIdentifiers)}}</td>
                      <td isEllipsisActive>{{filterRG(row?.id)}}</td>
                      <td isEllipsisActive>{{row?.properties?.startTimeUtc | date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                      <td isEllipsisActive>{{row?.properties?.intent | displayTxt | spaceBetweenCapital}}</td>
                      <td isEllipsisActive>{{row?.properties?.status | displayTxt}}</td>
                  </tr>
                </tbody>
            </table>
            </div>
            <div class="loader-gif" *ngIf="alertsData?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!alertsData?.tableData?.length && !alertsData?.showLoader)">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-16-26 bold-600">No alerts found.</p>
            </div>
        </div>
    </div>
    </div>
  </div>
  <div class="" *ngIf="showAlertDetails">
    <!-- <ng-container [ngTemplateOutlet]="currentTemplate"></ng-container> -->
      <div class="sidebar-right">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Alert</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showAlertDetails=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group row"  *ngIf="currentTab=='alertDetails'">
                    <div class="col-md-2">
                        <div class="label-div">
                            <label class="control-label">Severity</label>
                            <label class="control-label content-val"><strong>{{rowData?.properties?.severity}}</strong></label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="label-div">
                            <label class="control-label">Status</label>
                            <label class="control-label content-val"><strong>{{rowData?.properties?.status}}</strong></label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="label-div">
                            <label class="control-label">Activity time (UTC)</label>
                            <label class="control-label content-val"><strong>{{rowData?.properties?.startTimeUtc | date:'MM/dd/yyyy, hh:mm:ss a'}}</strong></label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="label-div">
                            <label class="control-label">MITRE ATT&CK® tactics</label>
                            <label class="control-label content-val"><strong>{{rowData?.properties?.intent}}</strong></label>
                        </div>
                    </div>
                </div>
                <div class="form-group"  *ngIf="currentTab=='alertDetails'">
                    <div class="label-div">
                        <label class="control-label text-weight">Alert description</label>
                        <label class="control-label" style="padding-top: 10px !important;">{{rowData?.properties?.description}}</label>
                    </div>
                </div>
                <div class="ml-auto">
                    <div class="main-nav">
                      <ul class="nav-wrap m-0" style="padding: 0px;display: flex;">
                        <li class="list-item cursor-pointer" [class.active]="currentTab == 'alertDetails'" (click)="currentTab = 'alertDetails'" style="font-size: 13px;line-height: 20px;margin-right: 24px;">
                          <span class="menu-name">Alert details</span>
                        </li>
                        <li class="list-item cursor-pointer" [class.active]="currentTab == 'takeAction'" [class.disabled]="disableAction(rowData?.properties?.remediationSteps)" (click)="currentTab = 'takeAction';showMore={};" style="font-size: 13px;line-height: 20px;">
                          <span class="menu-name">Take action</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="currentTab=='alertDetails'" class="row">
                    <ng-container *ngFor="let item of rowData?.properties?.extendedProperties | keyvalue">
                        <div class="col-md-4" *ngIf="item.key!='resourceType'&&item.key!='killChainIntent'&&item.key!='end Time UTC'">
                            <div class="form-group">
                                <div class="label-div">
                                    <label class="control-label column-key">{{item.key |titlecase}}</label>
                                    <label class="control-label column-val" [class.showmore]="getShowMore(item.key)" *ngIf="item.key!='investigation steps'">{{filterValue(item)}}</label>
                                    <label class="control-label column-val" [class.showmore]="getShowMore(item.key)" *ngIf="item.key=='investigation steps'&&jsonValue(item)"><a [href]="jsonValue(item).value" target="_blank">{{jsonValue(item).displayValue}}</a></label>
                                    <label class="control-label column-val" [class.showmore]="getShowMore(item.key)" *ngIf="item.key=='investigation steps'&&!jsonValue(item)">{{item.value}}</label>
                                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" *ngIf="(item.key!='investigation steps'&&item.value.length>92)||(item.key=='investigation steps'&&jsonValue(item)&&jsonValue(item).displayValue.length>92)" (click)="changeShow(item.key)">{{checkShow(item.key)?'Show less':'Show more'}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="label-div">
                                <label class="control-label column-key">Detected by</label>
                                <label class="control-label column-val">{{rowData?.properties?.vendorName}}</label>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div *ngIf="currentTab=='alertDetails'">                    
                    <div class="form-group" style="margin-bottom: 8px !important;">
                        <div class="label-div">
                            <label class="control-label text-weight">Related entities</label>
                        </div>
                    </div>
                    <div class="add-another-tag" *ngIf="azureResource.length">
                        <label class="cursor-pointer lbl-btn" *ngIf="!showViewAR" (click)="showViewAR=true;"><img
                            src="/assets/security/down-arrow.svg" style="padding-right: 4px;">Azure resource ({{azureResource.length}})</label>
                        <label class="cursor-pointer lbl-btn" *ngIf="showViewAR" (click)="showViewAR=false;"><img
                            src="/assets/security/up-arrow.svg"> Azure resource ({{azureResource.length}})</label>
                    </div>
                    <div class="custom-fixed-tables m-0 mt-3" *ngIf="azureResource.length&&showViewAR">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Resource ID</span>
                                  </div>
                              </th>
                              <th class="borderBlueRight">
                                  <div class="flex-custom no-wrap">
                                      <span>Subscription ID</span>
                                  </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr *ngFor="let row of azureResource">
                                <td>{{row?.resourceId}}</td>
                                <td style="width:1%">{{row?.resourceId.split('/')[2]}}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                    <div class="add-another-tag" *ngIf="hostResource.length">
                        <label class="cursor-pointer lbl-btn" *ngIf="!showViewHost" (click)="showViewHost=true;"><img
                            src="/assets/security/down-arrow.svg" style="padding-right: 4px;">Host ({{hostResource.length}})</label>
                        <label class="cursor-pointer lbl-btn" *ngIf="showViewHost" (click)="showViewHost=false;"><img
                            src="/assets/security/up-arrow.svg"> Host ({{hostResource.length}})</label>
                    </div>
                    <div class="custom-fixed-tables m-0 mt-3" *ngIf="hostResource.length&&showViewHost">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Name</span>
                                  </div>
                              </th>
                              <th>
                                <div class="flex-custom no-wrap">
                                    <span>Azure ID</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>OS family</span>
                                </div>
                            </th>
                              <th class="borderBlueRight">
                                  <div class="flex-custom no-wrap">
                                      <span>DNS domain</span>
                                  </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr *ngFor="let row of hostResource">
                                <td>{{row?.name}}</td>
                                <td>{{row?.azureID}}</td>
                                <td>{{row?.osFamily}}</td>
                                <td>{{row?.dnsDomain}}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                    <div class="add-another-tag" *ngIf="ipResource.length">
                        <label class="cursor-pointer lbl-btn" *ngIf="!showViewIP" (click)="showViewIP=true;"><img
                            src="/assets/security/down-arrow.svg" style="padding-right: 4px;">Ip ({{azureResource.length}})</label>
                        <label class="cursor-pointer lbl-btn" *ngIf="showViewIP" (click)="showViewIP=false;"><img
                            src="/assets/security/up-arrow.svg"> Ip ({{azureResource.length}})</label>
                    </div>
                    <div class="custom-fixed-tables m-0 mt-3" *ngIf="ipResource.length&&showViewIP">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Address</span>
                                  </div>
                              </th>
                              <th>
                                <div class="flex-custom no-wrap">
                                    <span>State</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>City</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>ASN</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Latitude</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Longitude</span>
                                </div>
                            </th>
                              <th class="borderBlueRight">
                                  <div class="flex-custom no-wrap">
                                      <span>Threat Intelligence</span>
                                  </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr *ngFor="let row of ipResource">
                                <td>{{row?.address}}</td>
                                <td>{{row?.location?.state}}</td>
                                <td>{{row?.location?.city}}</td>
                                <td>{{row?.location?.asn}}</td>
                                <td>{{row?.location?.latitude}}</td>
                                <td>{{row?.location?.longitude}}</td>
                                <td>{{row?.location?.threatIntelligence}}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                    <div class="add-another-tag" *ngIf="networkConnResource.length">
                        <label class="cursor-pointer lbl-btn" *ngIf="!showViewNC" (click)="showViewNC=true;"><img
                            src="/assets/security/down-arrow.svg" style="padding-right: 4px;">Network connection ({{azureResource.length}})</label>
                        <label class="cursor-pointer lbl-btn" *ngIf="showViewNC" (click)="showViewNC=false;"><img
                            src="/assets/security/up-arrow.svg"> Network connection ({{azureResource.length}})</label>
                    </div>
                    <div class="custom-fixed-tables m-0 mt-3" *ngIf="networkConnResource.length&&showViewNC">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Source IP</span>
                                  </div>
                              </th>
                              <th>
                                <div class="flex-custom no-wrap">
                                    <span>Source port</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Destinaion IP</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Destinaion port</span>
                                </div>
                            </th>
                              <th class="borderBlueRight">
                                  <div class="flex-custom no-wrap">
                                      <span>Protocol</span>
                                  </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr *ngFor="let row of networkConnResource">
                                <td>{{row?.sourceAddress?.ip}}</td>
                                <td>{{row?.sourceAddress?.port}}</td>
                                <td>{{row?.destinationIP}}</td>
                                <td>{{row?.destinationPort}}</td>
                                <td>{{row?.protocol}}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="currentTab=='takeAction'">
                    <div class="form-group mitigate-threat">
                        <div class="label-div">
                            <label class="control-label text-weight">Mitigate the threat</label>
                            <label class="control-label">
                                <span *ngFor="let row of rowData?.properties?.remediationSteps">
                                    {{row}} <br>
                                </span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" style="left: 16px;" *ngIf="currentTab=='takeAction'" (click)="currentTab = 'alertDetails'">Alert details</button>
                <button class="btn btn-btn-primary" *ngIf="currentTab=='alertDetails'" (click)="currentTab = 'takeAction';showMore={};" [class.disabled]="disableAction(rowData?.properties?.remediationSteps)">Take action</button>
            </div>
        </div>
    </div>
  </div>
</div>