<div class="introduction">
  
       <p class="heading" id="2">Invoices </p>
       <p class="mb-0">Provides option to download the monthly invoices. </p>
       
       <ol>
           <img src="assets\user-manual\cm-and-optimize\Invoices.svg" >
           <figcaption class="caption">Cost monitoring – invoices </figcaption>
                   <li>Select a project from the left menu.  </li>
                   <li>Click on ellipsis (<img class="ellipis" src="assets\user-manual\Vector.svg" >).  </li>
                   <img src="assets\user-manual\cm-and-optimize\Content.svg"  >
                   <figcaption class="caption">List of invoices</figcaption>
                   <img src="assets\user-manual\cm-and-optimize\Invoices-action.svg" >
                   <figcaption class="caption">Invoices - actions  </figcaption>

                   <li>Choose a download option:  </li>
                   <ol style="list-style-type: lower-alpha;">
                       <li>Download invoice. </li>
                       <li>Download invoice price sheet.  </li>
                       <li>Download Azure price sheet. </li>
                       <li>Prepare Azure usage file. </li>
                   </ol>
                  
       </ol>
      
     
</div>
