import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

   //list to handle loader status and url keys for reference
   private reqQue = new BehaviorSubject([]);
   loaderQue = this.reqQue.asObservable();
   
  constructor(private ngxLoader: NgxUiLoaderService) { }
  queActions(action, value) {
    let tempq = this.reqQue.getValue();
    if (action == "push") {
      tempq.push(value);
    }
    if (action == "pop") {
      if (tempq.indexOf(value) !== -1) {
        tempq.splice(tempq.indexOf(value), 1);
      }
    }
    this.reqQue.next(tempq);
  }

  showLoader(){
    this.ngxLoader.start();
  }

  hideLoader(){
      this.ngxLoader.stop();
  }
}
