<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Disks</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="diskmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(null)">Create new
                    </p>
                </div>
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="disk" class="control-label">Disk name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The identifier for the disk" matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck || inputError?.duplicateResourceName" id="disk" autocomplete="off" [(ngModel)]="diskmodel.resourceName" placeholder="" name="name" (keyup)="vmDiskNameValidation($event,80);diskDuplicateCheck($event)" required>
                <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                <p class="text-danger" *ngIf="inputError?.nameDupCheck">The name must be unique</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">Disk name must be unique in the current resource group.</p>
                </div>              
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="sourceType" class="control-label">Source Type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Create a disk from a snapshot of another disk, a blob in a storage account, or create an empty disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="sTypeList" bindLabel="name" bindValue="id" [(ngModel)]="diskmodel.resourceData.sourceType" name="sourceType" placeholder="" [clearable]="false"></ng-select>
                    <!-- <ng-select [items]="sourceTypeList" bindLabel="name" bindValue="name" [(ngModel)]="newDiskObj.sourceType" name="sourceType" placeholder="Choose" [clearable]="false"></ng-select> -->
                </div>   
            </div>
    
            <div class="form-group" *ngIf="diskmodel.resourceData.sourceType=='snapshot'">
                <div class="label-div">
                    <label for="snapshot" class="control-label">Source snapshot<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a snapshot from another disk to use as the source of this new disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="snapshotList" bindLabel="name" [(ngModel)]="diskmodel.resourceData.snapshot" name="snapshot" placeholder="Choose" [clearable]="false" (change)="changeSanpshot()" ></ng-select>
                </div>   
            </div>
            

            <div class="form-group">
                <div class="label-div">
                    <label for="size" class="control-label">Size<span class="mandatory">*</span></label>
                </div>
                <div class="input-box" style="font-size: 12px;font-weight: 400;" *ngIf="diskmodel.resourceData.newVMDiskSize">
                    <span style="font-size: 13px;font-weight: 600;">{{diskmodel?.resourceData?.newVMDiskSize?.customDiskSize}} GiB</span>
                    <br>
                    <span>{{diskmodel?.resourceData?.newVMDiskSize?.diskSKU?.name}} LRS</span>
                </div> 
                <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="triggerBtn('tempDisk')">Choose size</p>  
                </div>
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="diskmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
            
    <div *ngIf="showView">
        
        <div class="form-group">
        <div class="label-div">
            <label for="availabilityZone" class="control-label">Availablity zone<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can optionally specify an availability zone in which to deploy your Public IP address. Choose Zone-Redundant to create a zone-redundant data path." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <ng-select [items]="azList" bindLabel="name" bindValue="name" [(ngModel)]="diskmodel.resourceData.availabilityZone" name="availabilityZone" placeholder="" [clearable]="false"></ng-select>
        </div>   
    </div>

    

    <div class="form-group">
        <div class="label-div">
            <label for="encryptionType" class="control-label">Encryption Type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure offers server-side encryption with platform-managed keys by default for managed disks. You may optionally choose to use a customer-managed key." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <ng-select [items]="diskEncryptionTypeList" bindLabel="name" [(ngModel)]="diskmodel.resourceData.encryptionType" (change)="changeEncryptionType()" name="encryptionType" placeholder="" [clearable]="false"></ng-select>
            <!-- <ng-select [items]="diskEncryptionTypeList" bindLabel="name" bindValue="id" [(ngModel)]="newDiskObj.encryptionType" name="encryptionType" placeholder="Choose" (change)="changeEncryptionType('newDisk')" [clearable]="false"></ng-select> -->
        </div>   
    </div>

    <div class="form-group" *ngIf="diskmodel.resourceData.encryptionType?.name!='(default) Encryption at-rest with a platform-managed key'">
        <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
            <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
            <label class="" style="padding: 8px 0px !important;color: #703501;">
                Once a customer-managed key is used, 
                you can't change the selection back to 
                a platform-managed key.</label>
        </div>  
    </div>
    <div class="form-group" *ngIf="diskmodel?.resourceData?.encryptionType?.name!='(default) Encryption at-rest with a platform-managed key'">
        <div class="label-div">
            <label for="diskEncryptionSet" class="control-label">Disk encryption set<span class="mandatory">*</span></label>
        </div>
        <div class="input-box">
            <ng-select [items]="diskEncryptionSetListForDisk" bindLabel="name" bindValue="id" [(ngModel)]="diskmodel.resourceData.diskEncryptionSet" name="diskEncryptionSet" placeholder="" [clearable]="false"></ng-select>
        </div>   
    </div>

    <div class="form-group" style="margin-bottom: 10px !important;">
        <div class="label-div">
            <label for="esd" class="control-label ">Enable shared disk<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to be attached to two or more virtual machines, depending on storage type and disk size. When shared disk is enabled host caching is unavailable." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box d-flex">
            <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'Yes'" [disabled]="disabledEnableDiskShare(diskmodel?.resourceData)" ([ngModel])="diskmodel.resourceData.esd" [checked]="diskmodel.resourceData.esd=='Yes'" (change)="diskmodel.resourceData.esd=$event.target.value;changeMaxShareList($event,diskmodel?.resourceData)"><span>Yes</span></label>
            <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'No'" [disabled]="disabledEnableDiskShare(diskmodel?.resourceData)" ([ngModel])="diskmodel.resourceData.esd" [checked]="diskmodel.resourceData.esd=='No'" (change)="diskmodel.resourceData.esd=$event.target.value;changeMaxShareList($event,diskmodel?.resourceData)"><span>No</span></label>                    
        </div>  
        <p class="text-muted-msg" *ngIf="diskmodel?.resourceData?.newVMDiskSize?.selectVmDiskSizeData&&(diskmodel?.resourceData?.newVMDiskSize?.selectVmDiskSizeData?.name!='Premium_LRS'||diskmodel?.resourceData?.newVMDiskSize?.selectVmDiskSizeData?.capabilities[6]?.value==1)">Shared disk not available for the selected size.</p>           
    </div>
    
    <div class="form-group" *ngIf="diskmodel?.resourceData?.esd=='Yes'">
        <div class="label-div">
            <label for="maxShares" class="control-label">Max shares<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The number of max shares a shared disk has 
                depends on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <ng-select [items]="maxSharesList" bindLabel="name" bindValue="name" [(ngModel)]="diskmodel.resourceData.maxShares" name="maxShares" placeholder="Select disk share" [clearable]="false"></ng-select>
        </div>   
    </div>                
                <div class="form-group">
                    <div class="label-div d-flex">
                        <label class="control-label " style="min-width: max-content;">Tag name </label>
                        <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                        </label>
                        <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="diskmodel.resourceData&&diskmodel.resourceData.tagList&&diskmodel.resourceData.tagList.length>1"></label>
                    </div>
                    <div *ngFor="let item of diskmodel.resourceData.tagList;let i = index">
                        <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
    
                        <div class="input-box" style="padding-bottom: 6px;">
                            <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
    
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
    
                        <div class="input-box">
                            <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
    
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="diskmodel.resourceData.tagList.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                        <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                        <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                        <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
    
                    <div class="add-another-tag">
                        <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                    </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>