import { ChangeDetectorRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, Input, OnInit, TemplateRef, SimpleChanges } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { FlowService } from '../../../services/flow.service';
import * as _ from 'lodash';
import { NewResourceGroup, ASResourceErrorModel, ASResouceModel } from '../resources-model';

@Component({
  selector: 'app-availability-set',
  templateUrl: './availability-set.component.html',
  styleUrls: ['./availability-set.component.scss']
})
export class AvailabilitySetComponent implements OnInit {
  @Input() projectData : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  availabilitySetmodel: ASResouceModel = {
    resourceId: null,
    resourceName: null,
    resourceType: 'availabilitySets',
    resourceData: {
      updateDomains:5,
      faultDomains:2,
      useManagedDisks:'Aligned',
      tagList:[{
        tagName:null,
        value:null
      }]
    },
    comments: '',
    createdBy: ''
  };
  show : boolean = false;
  inputError: ASResourceErrorModel = {
    nameLen: false,
    nameValid: false,
    nameDupCheck: false,
    VMdescription: false,
    asUpdateDomainsMaxLen: false,
    asUpdateDomainsLen: false,
    asFaultDomainsMaxLen: false,
    asFaultDomainsLen: false
  }
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  resourceGroupList:any=[];
  showView:boolean=false;
  proxPlacGroupList:any=[];
  availabilitySetList: any=[];
  existingNewVMList: any=[];
  existingNewASList: any=[];
  
  constructor(private util:UtilService,private cdr: ChangeDetectorRef,private flowservice: FlowService,) { }

  async ngOnInit() {   
    await this.getResourceGroupList();
      // if(this.sideSheetViewType=='new'){
      //   this[this.templateName+'model'].resourceData={};
      //   this[this.templateName+'model'].resourceName=null;
      //   this[this.templateName+'model'].resourceData.tagList=[{}];
      // }
      let parentResourceData,resourceObj;
      if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
        this.availabilitySetmodel.resourceData.subnet=this.parentResourceDtls.resourceName;
        parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
        this.availabilitySetmodel.resourceData.vnetName=parentResourceData.vnetName;
        resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
        this.availabilitySetmodel.resourceData.resourceGroup=resourceObj.name;
        this.availabilitySetmodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
        this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
      }
    
      await this.getListByPropName('proximityPlacementGroups',undefined,undefined);
      if(resourceObj&&!resourceObj.resourceGroupId) {
        await this.getListByPropName('availabilitySets',resourceObj,undefined);
      }
      await this.getExistNewlyResourceDetails('virtualMachines');
      await this.getExistNewlyResourceDetails('availabilitySets');

      if(this.sideSheetViewType=='edit'){
        this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null).then(async res=>{
          this.availabilitySetmodel=res;
          this.availabilitySetmodel.resourceData=JSON.parse(res.resourceData);
          if(this.parentResourceDtls['updateRequestFrom']){
            this.availabilitySetmodel.resourceData.vnetName=this.parentResourceDtls.resourceName;
            this.availabilitySetmodel.resourceData.resourceGroup=resourceObj.name;
          }
        });
      }
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType === 'virtualMachines') {
        this.existingNewVMList=res;
      } else if(resourceType === 'availabilitySets') {
        this.existingNewASList=res;
      }
    },err=>{
      if(resourceType === 'virtualMachines') {
        this.existingNewVMList=[];
      } else if(resourceType === 'availabilitySets') {
        this.existingNewASList=[];
      }
    });
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,false).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
      });
  }

  onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.availabilitySetmodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }

      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.availabilitySetmodel.resourceData.resourceGroup=undefined; 
    });
  }

  editNewResourceGroup(item,index){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item);
    this.show = !this.show;
  }

  async getNewResourceGroup(){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addTagVM(){
    this.availabilitySetmodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTagVM(index){
    this.availabilitySetmodel.resourceData.tagList.splice(index,1);
  }

  hideShow(){
    this.showView=!this.showView;
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      let parentResourceData=this.parentResourceDtls&&this.parentResourceDtls.resourceData?JSON.parse(this.parentResourceDtls.resourceData):{};
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name==this.availabilitySetmodel&&this.availabilitySetmodel.resourceData&&this.availabilitySetmodel.resourceData.resourceGroup?this.availabilitySetmodel.resourceData.resourceGroup:parentResourceData.resourceGroup);
      if(propName=='availabilitySets'){
        this.availabilitySetList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      } else if (propName == 'proximityPlacementGroups') {
        this.proxPlacGroupList=res.value.filter(dt=>dt['location']==tempResourceObj.location);;
      }
      return  result;
    },err=>{
    });
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.availabilitySetmodel);
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  checkVmASName(event,limit){
    let value=event.target.value;
    this.inputError.nameLen=false;
    this.inputError.nameValid=false;
    this.inputError.nameDupCheck=false;
    if(value){
      if(value.length>limit){
        this.inputError.nameLen=true;
        return;
      }
      if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
        this.inputError.nameValid = false;
      }
      else{
        this.inputError.nameValid = true;
        return;
      }
  
      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
          this.inputError.nameValid = true;
          return;
        }
      }
    
      if(this.availabilitySetList&&this.availabilitySetList.length>0){
        let findObj=this.availabilitySetList.find((dt)=>dt.name.toLowerCase()==value.toLowerCase()&&dt.id);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.existingNewASList&&this.existingNewASList.length>0&&this.availabilitySetmodel&&this.availabilitySetmodel.resourceName){
        let findObj=this.existingNewASList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase()&&dt.resourceId!=this.availabilitySetmodel.resourceId);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.existingNewVMList){
        let findObj=this.existingNewVMList.find(dt=>{
          if(dt.resourceData) {
            const resourceData=JSON.parse(dt.resourceData);
            if(resourceData.availabilitySet) {
              return resourceData.availabilitySet.name.toLowerCase()==value.toLowerCase();
            }
          }
        });
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
    }
  }

  VmDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMdescription = true;
    }
    else this.inputError.VMdescription = false;
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.availabilitySetList.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  saveResourceDetails(){
    var model = this.availabilitySetmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.availabilitySetmodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  checkUpdateDomains(event,maxLimit){
    let value=event.target.value;
    this.inputError.asUpdateDomainsMaxLen=false;
    this.inputError.asUpdateDomainsLen=false;
    if(value&&value>maxLimit){
      this.inputError.asUpdateDomainsMaxLen=true;
    }else if(!value||value<1){
      this.inputError.asUpdateDomainsLen=true;
    }
  }

  checkfaultDomains(event,modelObj){
    let value=event.target.value;
    this.inputError.asFaultDomainsMaxLen =false;
    this.inputError.asFaultDomainsLen =false;
    if(!value||value<1){
      this.inputError.asFaultDomainsLen =true;
      return;
    }
    if(value&&value==1){
      modelObj.updateDomains=1;
      this.inputError.asUpdateDomainsMaxLen=false;
      this.inputError.asUpdateDomainsLen=false;
    }else if(value&&parseInt(value)>3){
      this.inputError.asFaultDomainsMaxLen =true;
    }
  }

  checkDataDisabled(){
    return !this.availabilitySetmodel.resourceName || !this.availabilitySetmodel.resourceData.resourceGroup ||this.inputError.asUpdateDomainsLen || this.inputError.asUpdateDomainsMaxLen ||this.inputError.asFaultDomainsMaxLen || this.inputError.asFaultDomainsLen;
  }
}
