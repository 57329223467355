import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-design-tool-components',
  templateUrl: './design-tool-components.component.html',
  styleUrls: ['./design-tool-components.component.scss']
})
export class DesignToolComponentsComponent implements OnInit {
  @Input() activeSidebar: string;
  @Output() onChangeSidebar = new EventEmitter<string>();

   projectStatus=[ {value:'Review',activeSidebar:'Apps-Infrastructure-Project-Status-In-Review',Title:'Apps & Infrastructure',type:'subChild'},
  {value:'Approve',activeSidebar:'Apps-Infrastructure-Project-Status-Approved',Title:'Apps & Infrastructure',type:'subChild'},
  {value:'Deploy',activeSidebar:'Apps-Infrastructure-Project-Status-Deployed',Title:'Apps & Infrastructure',type:'subChild'}]

  constructor() { }

  ngOnInit(): void {
  }
redirect(tab){
  this.subMenuSlideDownOpen('Apps-Infrastructure-Project-Status')
  this.onChangeSidebar.emit(tab);
  this.scrollToPosition()
}
subMenuSlideDownOpen(id){
  $('#' + id).addClass('open').siblings().slideDown(300);
 
}
scrollToPosition(){

  $('.user-manual-container').animate({scrollTop:200});
}
}