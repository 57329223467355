 <div class="introduction">
          <p class="heading" id="6">Azure Blob Storage     </p>
          <ol>
              <li>Click on<b> Create new project.</b>  </li>
              <li>Select source system as <b>Azure blob storage.  </b> </li>
              <img src="assets\user-manual\data-&-AI\2.svg" >
              <figcaption class="caption">Storage Blob – source  </figcaption>
              <li>Fill in required details for Azure Synapse as destination and for Blob storage as source system.  </li>
              <img src="assets\user-manual\data-&-AI\File-system-migration(1).svg" >
              <figcaption class="caption">Storage Blob - source details </figcaption>
              <li>Click <b>Next.</b> </li>
              <li>Select file(s) to be migrated. </li>
              <img src="assets\user-manual\data-&-AI\File-system-migration(8).svg" >
              <figcaption class="caption">Storage Blob - select files </figcaption>
              <li>Click <b>Migrate</b> to initiate the migration.  </li>
              <img src="assets\user-manual\data-&-AI\File-system-migration(7).svg" >
              <figcaption class="caption">Storage Blob - migration process </figcaption>
             
              <li>If migration is successful, Successful migration message will be displayed.  </li>  
              <img src="assets\user-manual\data-&-AI\File-system-migration(6).svg" >
              <figcaption class="caption">Storage Blob - migration success </figcaption>
              <li>In case partial migration, Partial migration message will be displayed.  </li>
              <img src="assets\user-manual\data-&-AI\File-system-migration(5).svg" >
              <figcaption class="caption">Storage Blob - partial success </figcaption>
              <li>In case of error/failure, Failure migration message will be displayed. </li>
              <img src="assets\user-manual\data-&-AI\File-system-migration(4).svg" >
              <figcaption class="caption"> Storage Blob – failure </figcaption>
              <li>Access files using file explorer  </li>
              <img src="assets\user-manual\data-&-AI\File-system-migration(3).svg">
              <figcaption class="caption"> Storage Blob - file explorer </figcaption>
              <!-- <img src="assets\user-manual\data-&-AI\File-system-migration(2).svg" > -->
        
                
                
          </ol>
</div>

