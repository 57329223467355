<div class="introduction">

    <p class="heading" id="1" >Queries </p>
    <p>These are grouped by categories as below and the list is displayed in the left menu. 
        You can also search a query using search box (shown in the image above). </p>
    <ol>
        <li>Alerts. </li>
        <li>Availability. </li>
        <li>Diagnostics. </li>
        <li>Errors. </li>
        <li>Health. </li>
        <li>Performance. </li>
        <li>Security. </li>
    </ol>
    <p class="heading" id="2" style="font-size: 30px;">Run a Query </p>
    <ol>
        <li>Browse a query and you will get a tile like this: </li>
        <img style="width:50%;" src="assets\user-manual\infra-health-monitoring\Frame.svg" >
        <li>Select <b>Time range.</b>  </li>
        <img src="assets\user-manual\infra-health-monitoring\Queries-time.svg" >
        <li>Click <b>Run.</b></li>
        <img style="width:50%;" src="assets\user-manual\infra-health-monitoring\Frame1.svg"  >
        <li>As the data is retrieved by the query, it will be displayed in a chart and tabular format as shown in below. </li>
        <img src="assets\user-manual\infra-health-monitoring\2.svg" >
        <img class="mt-0"  src="assets\user-manual\infra-health-monitoring\3.svg" >
    </ol>
   


          
</div>
