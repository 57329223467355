<div class="overview-content">
    <div class="header-container">
        <h2 class="title">Project Health Overview</h2>
        <span class="blur-text" *ngIf="currentDashboard == 'overview'">{{currentProjectData?.projectName}}</span>
    </div>

     <!-- Filter Section -->
     <div class="custom-filter-wrapper flex-custom">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                        <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                        <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Web App<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                        <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                        <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button>
     </div>
      
    <div class="dashboard-body mt-0">
        <app-application-overview *ngIf="currentDashboard == 'overview'" [currentProjectData]="currentProjectData" [filterObject]="filterObject"></app-application-overview>
        <!-- <app-application-monitoring-metrics-usage *ngIf="currentDashboard !== 'overview'" [currentProjectData]="currentProjectData"></app-application-monitoring-metrics-usage> -->
    </div>
</div>



<!-- Pop Up Template

<ng-template #availabilityPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Availability</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Availability test</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Application</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','applicationName')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Location</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','location')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>City</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','city')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Availability(%)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','percentage')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of appAvailablityData?.tableData?.rows">
                          <td>{{row?.name | displayTxt}}</td>
                          <td>{{row?.applicationName | displayTxt}}</td>
                          <td>{{row?.location | displayTxt}}</td>
                          <td>{{row?.city | displayTxt}}</td>
                          <td>{{row?.percentage | percent | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!appAvailablityData?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('availability')" *ngIf="appAvailablityData?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
</ng-template>

<ng-template #failedRequestPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Failed Requests Count</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedRequest','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Failed requests</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedRequest','failedCount')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of failedRequest?.tableData?.rows">
                          <td>{{row?.timestamp | a3sDateTimeFormat| displayTxt}}</td>
                          <td>{{row?.failedCount}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!failedRequest?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('failedRequest')" *ngIf="failedRequest?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #userByCountryPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>User by Country</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Country</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('usersList','dimension')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Users</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('usersList','Users')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of usersList?.tableData?.rows" class="cursor-pointer">
                          <td>{{row?.dimension | displayTxt}}</td>
                          <td>{{row?.Users | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!usersList?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('usersList')" *ngIf="usersList?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #serverRequestPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Server Requests</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverRequests','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Server Requests</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverRequests','count_')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of serverRequests?.tableData?.rows" class="cursor-pointer">
                          <td>{{row?.timestamp | a3sDateTimeFormat | displayTxt}}</td>
                          <td>{{row?.count_ }}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!serverRequests?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('serverRequests')" *ngIf="serverRequests?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #serverPerformancePopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Server Performance</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverPerf','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Server performance(%)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverPerf','performanceCounters/processCpuPercentageTotal_avg')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of serverPerf?.tableData?.rows" class="cursor-pointer">
                          <td>{{row?.timestamp | displayTxt}}</td>
                          <td>{{row['performanceCounters/processCpuPercentageTotal_avg'] | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!serverPerf?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('serverPerf')" *ngIf="serverPerf?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #serverResponsePopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Server Response Time</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverResponseTime','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Response Time(avg)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverResponseTime','avg_duration')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of serverResponseTime?.tableData?.rows" class="cursor-pointer">
                          <td>{{row?.timestamp | a3sDateTimeFormat | displayTxt}}</td>
                          <td>{{row?.avg_duration}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!serverResponseTime?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('serverResponseTime')" *ngIf="serverResponseTime?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #retentionPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Retention</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Used on</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','Cohort')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Users</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','Users')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span><1w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','<1w')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>1w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','+1w')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>2w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','+2w')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>3w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','+3w')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>4w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','+4w')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of retention?.tableData?.rows">
                          <td>{{row?.Cohort | displayTxt}}</td>
                          <td>{{row?.Users | displayTxt}}</td>
                          <td>{{row['<1w'] | displayTxt}}</td>
                          <td>{{row['+1w'] | displayTxt}}</td>
                          <td>{{row['+2w'] | displayTxt}}</td>
                          <td>{{row['+3w'] | displayTxt}}</td>
                          <td>{{row['+4w'] | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!retention?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('retention')" *ngIf="appAvailablityData?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
</ng-template> -->
