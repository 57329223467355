import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-horizontal-bar-chart',
  template: `<div echarts [options]="options"  style="height:300px"></div>`,
})
export class HorizontalBarChartComponent implements OnChanges {
  options: any = {};
  @Input('data') data: any = {};
  @Input('type') type: any;
  constructor(private util:UtilService) { }

  ngOnChanges() {
    var data = this.data
    var type = this.type
    this.options = {
      color: data.color,
      textStyle: {
        fontFamily: 'Inter',
      },
      tooltip:type!='top10resource'? this.util.getHorizontalBarChartTooltip(data):false,
      legend: {
        show:false,
        bottom: 0,
        left: 'left',
        data: data.legendData || []
       },
       grid: {
        top: 10,
        bottom: 45,
        left: 25,
        right: 30,
        containLabel: true
      },
      xAxis: {
          type: 'value',
          minInterval: 1,
          axisTick: {
            show: false
           },
          axisLabel:{
            padding: [5, 0, 0, 0],
            color:'#3E4852',
          }
      },
      yAxis: {
          type: 'category',
          data: data.yAxis  || [],
          axisLine: {
            lineStyle: {
                color: '#EEEFF0'
            }
          },
          axisLabel: {
            padding: [0, 5, 0, 0],
            fontSize: 12, 
            color:'#3E4852',
            formatter: function (value) {
                 let val=type=='top10resource'?  value : (value.length > 10 ? (value.slice(0,10)+"...") : value )
                return val
            }
          },
      },
      series: [{
        data: data.data || [],
        type: 'bar',
        barWidth: 12,
        label: {
                show: true,
                position: 'insideRight',
                color: '#ffffff',
                fontSize: 8,

            },
      }]
    };
  }

}
