<div class="custom-fixed-tables" >
    <table class="table">
        <thead>
          <tr>
            <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('disk',0)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('disk',1)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('disk',2)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('disk',3)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Storage account type</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('disk',4)"></div></th>
            <th><div class="flex-custom no-wrap"><span>Size (GB)</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('disk',5)"></div></th>
            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('disk',6)"></div></th>
            <th style="border-left: none;" class="wid-75"></th>
        </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data" >
              <td isEllipsisActive class="ellips-td blue-text cursor-pointer" (click)="openModal(diskDetailsTemplate,row,'diskPopUp')"><div [title]='row[0]' class="ellips-text">{{ row[0] | displayTxt}}</div></td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td isEllipsisActive>{{ getProjectName(row[2]) | displayTxt }}</td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt}}</td>
              <td isEllipsisActive>{{ row[4] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
              <td isEllipsisActive>{{getTags(row[7]) }}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2" (click)="getDiskData(row,'deleteCondition')"> 
                  <mat-menu #action2="matMenu" class="delete-popup">
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('diskConfiguration',row)">
                      <p class="delete-popup-text">Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('sizeAndPerformance',row)">
                      <p class="delete-popup-text">Change Size and Performance</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('diskNetworking',row)">
                        <p class="delete-popup-text">Networking</p>
                      </div>
                      <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('encryption',row)">
                        <p class="delete-popup-text">Encryption</p>
                      </div>
                    <div class="mat-menu-item" mat-menu-item [class.disabled-icon]="deleteDisable" (click)="openSidesheet('deleteDisk',row)">
                      <p class="delete-popup-text" [class.disabled-icon]="deleteDisable">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>

  <ng-container *ngIf="showSidesheet">

    <!-- Networking Disk -->
    <ng-container *ngIf="currentTemplate == 'diskNetworking'">
        <div class="sidebar-right single add-alert" >
            <div class="header">
                <div class="d-flex">
                    <h4>Networking<br> <span class="blur-text">Disks | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetValidation();resetForm();saveDisable=false">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap bold-600 font-size-13 mb-2">Network Connectivity<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="To access the disk, choose the network connectivity method." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <label class="flex-custom no-wrap mb-2">Connectivity method </label>
                        <div >
                            <div class="checkbox-cs">
                                <input type="radio" name="networkConnectivity"  class="form-check-inline" id='public' (change)="setRadioBtn('public','networking')" [checked]="currentForm.get('networkConnectivity')?.value == 'AllowAll'">
                                <label class="value" for="public">Public endpoint (all networks)</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="networkConnectivity" class="form-check-inline" id='private' (change)="setRadioBtn('private','networking')" [checked]="currentForm.get('networkConnectivity')?.value == 'AllowPrivate'">
                                <label class="value" for="private">Private endpoint (through disk access)</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="networkConnectivity" class="form-check-inline" id='deny' (change)="setRadioBtn('deny','networking')" [checked]="currentForm.get('networkConnectivity')?.value == 'DenyAll'">
                                <label class="value" for="deny">Deny all</label>
                            </div>
                        </div>
                    </div>
                    <div class="comm-mb" *ngIf="currentForm.get('networkConnectivity')?.value =='AllowPrivate' ">
                        <label class="flex-custom no-wrap">Disk access<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Disk access will allow you to protect the disk with a private link. 
                            Select an existing disk access resource." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="diskAccessList"  bindLabel="name" bindValue="id" name="diskAccess" [clearable]="false" id="diskAccess" (change)="saveDisable=true" formControlName="diskAccess"></ng-select>
                      </div>  


                 </form>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || !saveDisable" (click)="saveDisk('networking');saveDisable=false">Save</button>
            </div>
        </div>
    </ng-container>
  <!-- Encryption Disk -->
    <ng-container *ngIf="currentTemplate == 'encryption'">
        <div class="sidebar-right single width-652" >
            <div class="header">
                <div class="d-flex">
                   <h4 class="threeFour-width ">Encryption <br> <span class="blur-text">Disks | {{selectedRow[0]}}</span></h4>
                   <div class="text-right half-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();saveDisable=false">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Encryption type<span class="mandatory">*</span></label>
                        <ng-select [items]="encryptionType"  bindLabel="name" bindValue="id" name="encryptionType" [clearable]="false" id="encryptionType" (change)="getEncryptionList(selectedRow,$event);saveDisable=true" formControlName="encryptionType"></ng-select>
                      </div>  
                    <div class="comm-mb" *ngIf="currentForm.get('encryptionType')?.value !='EncryptionAtRestWithPlatformKey' ">
                        <label class="flex-custom no-wrap">Disk encryption set<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose an existing disk encryption set that 
                            requires access to Key Vault and keys." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="diskEncryption"  bindLabel="name" bindValue="id" name="diskEncryption" [clearable]="false" id="diskEncryption" (change)="saveDisable=true" formControlName="diskEncryption"></ng-select>
                      </div>  


                 </form>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || !saveDisable" (click)="saveDisk('encryption');saveDisable=false">Save</button>
            </div>
          
        </div>
    </ng-container>
    <!-- Configuration Disk -->
    <ng-container *ngIf="currentTemplate == 'diskConfiguration'">
      <div class="sidebar-right single add-alert" >
          <div class="header">
              <div class="d-flex">
                  <h4>Configuration<br> <span class="blur-text">Disks | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetValidation();resetForm();saveDisable=false">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
              <form [formGroup]="currentForm">
                  <div class="comm-mb">
                      <label class="flex-custom no-wrap bold-600 font-size-13 mb-2">Shared Disk<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to be attached to two or more virtual machines, 
                        depending on storage type and disk size. When shared disk is 
                        enabled host caching is unavailable." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <label class="flex-custom no-wrap mb-2">Enable shared disk</label>
                          <div class="checkbox-cs" [class.disabled-icon]="diskStateMsg||diskSkuMsg">
                              <input type="radio" name="sharedDisk"  class="form-check-inline" id='Yes' (change)="setRadioBtn('yes','diskConfiguration')" [checked]="currentForm.get('sharedDisk')?.value == true">
                              <label class="value" for="Yes">Yes</label>
                          <!-- </div>
                          <div class="checkbox-cs" [class.disabled-icon]="diskStateMsg||diskSkuMsg"> -->
                              <input type="radio" name="sharedDisk" class="form-check-inline" id='No' (change)="setRadioBtn('no','diskConfiguration')" [checked]="currentForm.get('sharedDisk')?.value == false">
                              <label class="value" for="No">No</label>
                          </div>
                  </div>
                  <div class="comm-mb" *ngIf="currentForm.get('sharedDisk')?.value">
                      <label class="flex-custom no-wrap">Max shares</label>
                      <ng-select [items]="maxSharesList"  bindLabel="name" bindValue="id" name="maxShares" [clearable]="false" id="maxShares" (change)="saveDisable=true" formControlName="maxShares"></ng-select>
                  </div> 
                  <div class="comm-mb" *ngIf="diskStateMsg">
                    <div class="label-div d-flex warningBox">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        Updating maxShares for disk is permitted only when the disk is detached.</label>
                     </div>  
                    </div>
                    <div class="comm-mb" *ngIf="diskSkuMsg">
                      <div class="label-div d-flex warningBox">
                        <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                        <label class="" style="padding: 8px 0px !important;color: #703501;">
                          Shared disk is only supported on premium SSDs.</label>
                       </div>  
                      </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap bold-600 font-size-13 mb-2">On-demand Bursting<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to burst beyond original provisioned
                      target up to 30,000 IOPS and 1,000 Mbps." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="checkbox-cs" [class.disabled-icon]="diskStateMsg||diskSkuMsg||diskSizeMsg||currentForm.get('sharedDisk')?.value"> 
                      <input type="checkbox" name="demandBursting" class="form-check-inline" id='demandBursting' value="demandBursting" formControlName="onDemandBursting" (click)="saveDisable=true">
                      <label class="value" for="demandBursting" (click)="saveDisable=true"  >Enable on-demand bursting</label>
                    </div>
                  </div> 
                  <div class="comm-mb" *ngIf="diskSizeMsg">
                    <div class="label-div d-flex warningBox">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        On-demand disk bursting is only supported on disk size more than 512 GiB.</label>
                    </div>  
                  </div>
                  <div class="comm-mb" *ngIf="diskSkuMsg">
                  <div class="label-div d-flex warningBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                      On-demand disk bursting is only supported on premium SSDs.</label>
                   </div>  
                  </div>


                </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || !saveDisable" (click)="saveDisk('configuration');saveDisable=false">Save</button>
          </div>
      </div>
    </ng-container>
    <!-- Change Size and Performance Disk -->
    <ng-container *ngIf="currentTemplate == 'sizeAndPerformance'">
      <div class="sidebar-right single width-652" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Size and Performance<br> <span class="blur-text">Disks | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetValidation();resetForm();saveDisable=false">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb" *ngIf="diskSizeMsg">
                  <div class="label-div d-flex warningBox">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                      Reducing disk size is not supported in azure to prevent data loss.</label>
                  </div>  
                  </div>
                  <div class="comm-mb" *ngIf="diskStateMsg">
                    <div class="label-div d-flex warningBox">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        Changes to the disk size can be made only when the disk is unattached or the managing virtual machines(s) are deallocated.</label>
                    </div>  
                    </div>
                  <div class="comm-mb">
                      <label class="flex-custom no-wrap">Disk SKU<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Premium SSD disks offer high-performance, low-latency disk support for
                        I/O-intensive applications and production workloads. Standard SSD Disks 
                        are a cost effective storage option optimized for workloads that need 
                        consistent performance at lower IOPS levels. Use Standard HDD disks for
                         Dev/Test scenarios and less critical workloads at lowest cost." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="diskSKU"  bindLabel="name" bindValue="id" name="diskSKU" [clearable]="false" id="diskSKU" (change)="getSKUList(selectedRow,'change',$event);saveDisable=true" formControlName="diskSKU"></ng-select>
                    </div>  
                    <div class="custom-fixed-tables mb-3">
                          <table class="table">
                              <thead>
                                  <tr>
                                    <th>
                                        <div class="flex-custom no-wrap">
                                            <span>Size (GiB)</span>
                                            <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('size',skuList)">
                                        </div>
                                    </th>
                                    <th>
                                        <div class="flex-custom no-wrap">
                                            <span>Performance tier</span>
                                            <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('size',skuList)">
                                        </div>
                                    </th>
                                    <th>
                                      <div class="flex-custom no-wrap">
                                          <span>Provisioned IOPS</span>
                                          <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('IOPS',skuList)">
                                      </div>
                                  </th>
                                    <th>
                                      <div class="flex-custom no-wrap">
                                          <span>Provisioned throughput</span>
                                          <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('Mbps',skuList)">
                                      </div>
                                  </th>
                                  </tr>
                                </thead>
                                <tbody >
                                  <ng-container *ngFor="let list of skuList,let i=index">
                                      <tr (click)="setsize(list,i);" id="row-{{i}}" [class.active-row]="list?.active">
                                          <td>{{list?.capabilities[0].value}}</td>
                                          <td>{{list?.size}}</td>
                                          <td>{{list?.capabilities[2].value}}</td>
                                          <td>{{list?.capabilities[4].value}}</td>
                                      </tr>
                                    </ng-container>
                                    <div class="col-md-12 mt-3" *ngIf="!skuList?.length">
                                      <p class="content-14 blur-text">No results</p>
                                    </div>
                                </tbody>
                          </table>
                    </div>
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Custom disk size (GiB)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip=" Enter the size of the disk you would like to create." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" name="diskSize" [clearable]="false" id="diskSize" formControlName="diskSize"  autocomplete="off" placeholder=""  (keyup)="validSize($event)">
                      <p class="text-danger" *ngIf="inputError?.sizeGB">The value must be between 1 and 32767.</p>
                    </div> 
                    <div class="comm-mb" *ngIf="currentForm.get('diskSKU')?.value=='Premium_LRS'">
                      <label class="flex-custom no-wrap">Performance tier<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Changing the performance tier allows you to prepare for and meet
                        higher demand without using your disk's bursting capability." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="performance"  bindLabel="name" bindValue="id" name="performance" [clearable]="false" id="performance" (change)="saveDisable=true" formControlName="performance"></ng-select>
                      <p class="text-danger" *ngIf="diskTier">Upgraded tiers are not available for the selected size.</p>
                    </div> 
              </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || invalidForm('sizeAndPerformance')"(click)="saveDisk('sizeAndPerformance');saveDisable=false">Save</button>
              <!-- [class.disabled]="currentForm.invalid || invalidForm('sizeAndPerformance')|| !saveDisable"  -->
          </div>
        
      </div>
    </ng-container>
</ng-container>

<ng-template #deleteConfirm>
    <div class="medium-popup-wrapper">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">Delete Disk</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>Are you sure want to delete {{selectedRow[0]}}?</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">Delete</button>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #diskDetailsTemplate>
  <div class="medium-popup-wrapper width-832">
      <div class="modal-body scroll-y">
      <div class="d-flex mb-3">
          <div class="popup-title">Disk</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="tab-list">
          <div class="tab-item" (click)="closeModal();openSidesheet('diskConfiguration',selectedSideSheetRow)">Configuration</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('sizeAndPerformance',selectedSideSheetRow)">Change Size and Performance</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('diskNetworking',selectedSideSheetRow)">Networking</div>
          <div class="tab-item" (click)="closeModal();openSidesheet('encryption',selectedSideSheetRow)">Encryption</div>
          <div class="tab-item" [class.disabled-icon]="deleteDisable" (click)="closeModal();openSidesheet('deleteDisk',selectedSideSheetRow)">Delete</div>
      </div>
      <div class="row row1" >
          <div class="col-md-6" *ngFor="let data of selectedSideSheetRow;let i= index;let col of columns">
              <div class="list-item">
                  <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name)| firstLetterCaps}}</div>
                  <ng-container *ngIf="col.name!='location'&&col.name!='subscriptionId'">
                    <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedSideSheetRow[i]:getTags(selectedSideSheetRow[i])}}</div>
                  </ng-container>
                  <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedSideSheetRow[i])}}</div>
                  <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedSideSheetRow[i])}}</div>
              </div>
          </div>
      </div>
      </div>
      <div class="submit-sec text-right pad-10-24">
      <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
      </div>
  </div>
</ng-template>