<div class="a3s-filters">
    <p class="heading" id="0">Concept of Filtres</p>
    <p class="para">In line with the concept of Projects, the Filters are used in A3S to provide 
        drill down capability to delve deeper into the Projects. Following filter options are 
        available in the most of A3S functional buckets:</p>
    <ul>

        <li><b>Subscription :</b> in case of a project spanning multiple Subscriptions, this filter option 
            shows Resource Groups and resources from selected Subscription(s). </li>
        <li><b>Resource Group :</b> single/multiple Resource Groups can be selected to focus on specific resources. </li>
        <li><b>Resource Type : </b>if user wants to view only a certain resource type such as Virtual Machines, 
            then resource type filter is useful. </li>
        <li><b>Resource :</b> drill down to an individual resource level within a Resource Type and thereon for example, 
            user can view key metrics & alert information and modify the resource as needed. </li>
    </ul>
</div>

