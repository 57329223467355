
<div class="a3s-functional-buckets">
    <p class="heading" id="0">A3S Functional Buckets</p>
    <p class="para mb-0">For your ease of use, A3S' various Azure cloud management capabilities 
                have been neatly categorized as below: </p>
                <img src="assets\user-manual\function.png">
                <figcaption class="caption">A3S functional hierarchy</figcaption>
</div>


