import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dataops',
  templateUrl: './dataops.component.html',
  styleUrls: ['./dataops.component.scss']
})
export class DataopsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
