import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UtilService } from 'src/app/services/util.service';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-compliance-reports',
  templateUrl: './compliance-reports.component.html',
  styleUrls: ['./compliance-reports.component.scss']
})
export class ComplianceReportsComponent implements OnInit {
  filterObject={
    regionList : [],
    standardList : [],
    industryList : []
  }
  datasort: string;
  currentTab='ISO';
  reportsGroup:any=[];
  reportsDetails: any=[];
  tableData: any=[];
  showLoader:boolean=false;
  
  constructor(private util:UtilService, private http: HttpClient, private loader : LoaderService) { }

  async ngOnInit() {
     this.getReportDetails();
  }

  filterTableData(){
    this.tableData=[];
    this.filterObject.regionList=[{name:'All',check:true}];
    this.filterObject.standardList=[{name:'All',check:true}];
    this.filterObject.industryList=[{name:'All',check:true}];
    this.reportsDetails.forEach(dt=>{
      if(dt.complianceOfferingGroup==this.currentTab){
        this.tableData.push(dt);
        dt.regions.forEach(ds=>{
          let findRegion=this.filterObject.regionList.find(dk=>dk.name==ds);
          if(!findRegion){
            this.filterObject.regionList.push({name:ds,check:true});  
          }
        })
        dt.standards.forEach(ds=>{
          let findStandard=this.filterObject.standardList.find(dk=>dk.name==ds);
          if(!findStandard){
            this.filterObject.standardList.push({name:ds,check:true});  
          }
        })
        dt.industries.forEach(ds=>{
          let findIndustry=this.filterObject.industryList.find(dk=>dk.name==ds);
          if(!findIndustry){
            this.filterObject.industryList.push({name:ds,check:true});  
          }
        })
      }
    })
  }

  getReportDetails(){ 
    this.reportsGroup=[];
    this.tableData=[];
    this.showLoader=true;
    this.util.handleRequest('get','a3s_compliance_getReportDetails',[],null,null,null,true).then(res=>{
      if(!res || !res.body){ 
        this.showLoader=false;
        return 
      }
      console.log('DATA',res);
      this.reportsDetails = res.body || [];
      this.showLoader=false;
      if(this.reportsDetails.length){
        this.reportsDetails.forEach(dt=>{
          let findObj=this.reportsGroup.find(ds=>ds.name==dt.complianceOfferingGroup);
          if(!findObj){
            this.reportsGroup.push({name:dt.complianceOfferingGroup});
          }
        })
        this.currentTab=this.reportsGroup[0].name;
        this.filterTableData();
      }
    });
  }

  downloadFile(item){
    const body={
      fileName:item.fileName.split("+").join("%2B").split(" ").join("%20"),
      fileType:item.reportType=='ZIP'?'zip':item.reportType=='EXCEL'?'xlsx':'pdf',
      token:JSON.parse(sessionStorage.getItem('data')).token
    }
    this.loader.showLoader();
    this.http.post(`https://s2.security.ext.azure.com/api/certificationArtifacts/downloadFile?fileName=${body.fileName}&fileType=${body.fileType}`,{},{
      'headers': {
        'Content-type':'application/json',
        'Authorization':`Bearer ${body.token}`,
      },
      responseType: 'blob' as 'json'
    }).subscribe((response: any)=>{
        console.log(response);
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', `${item.fileName}.${body.fileType}`);
        document.body.appendChild(downloadLink);
        this.loader.hideLoader();
        downloadLink.click();
    });
  }

  search(txt) {
    txt = txt.toLowerCase();
    //this.projectList = [...this.dummyProjectList];
    let list = this.reportsDetails.filter(dt => {
      if(dt.complianceOfferingGroup==this.currentTab){
        return dt.fileName.toLowerCase().includes(txt)
      }
    })
    this.tableData = list;
  }

  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(event,data,type){
    switch(type){
      case 'region':{
        if (event.target.checked) {
          if(data.name=='All'){
            this.filterObject.regionList.map(dt=>{
              dt.check=true;
              return dt;
            })
          }else{
            let count=0;
            this.filterObject.regionList.forEach(dt=>{
              if(dt.check&&dt.name!="All"){
                count+=1;
              }
            })
            if(count==this.filterObject.regionList.length-1){
              this.filterObject.regionList[0].check=true;
            }
          }
          this.tableData=[];
          this.filterObject.standardList=[{name:'All',check:true}];
          this.filterObject.industryList=[{name:'All',check:true}];
          this.reportsDetails.forEach(dt=>{
            if(dt.complianceOfferingGroup==this.currentTab&&data.name=='All'){
              this.tableData.push(dt);
            }else if(dt.complianceOfferingGroup==this.currentTab){
              this.filterObject.regionList.forEach(dm=>{
                if(dm.check){
              let findObj=dt.regions.find(ds=>ds==dm.name);
              if(findObj){
                this.tableData.push(dt);
                dt.standards.forEach(ds=>{
                  let findStandard=this.filterObject.standardList.find(dk=>dk.name==ds);
                  if(!findStandard){
                    this.filterObject.standardList.push({name:ds,check:true});  
                  }
                })
                dt.industries.forEach(ds=>{
                  let findIndustry=this.filterObject.industryList.find(dk=>dk.name==ds);
                  if(!findIndustry){
                    this.filterObject.industryList.push({name:ds,check:true});  
                  }
                })
              }
            }})
            }
          })
       }
       else{
          if(data.name=='All'){
            this.filterObject.regionList.map(dt=>{
              dt.check=false;
              return dt;
            })
          }else{
            this.filterObject.regionList[0].check=false;
          }
          this.tableData=[];
          this.filterObject.standardList=[{name:'All',check:true}];
          this.filterObject.industryList=[{name:'All',check:true}];
          this.filterObject.regionList.forEach(dm=>{if(dm.check){
          this.reportsDetails.forEach(dt=>{
            if(dt.complianceOfferingGroup==this.currentTab&&dm.name=='All'){
              this.tableData.push(dt);
            }else if(dt.complianceOfferingGroup==this.currentTab){
              let findObj=dt.regions.find(ds=>ds==dm.name);
              if(findObj){
                this.tableData.push(dt);
                dt.standards.forEach(ds=>{
                  let findStandard=this.filterObject.standardList.find(dk=>dk.name==ds);
                  if(!findStandard){
                    this.filterObject.standardList.push({name:ds,check:true});  
                  }
                })
                dt.industries.forEach(ds=>{
                  let findIndustry=this.filterObject.industryList.find(dk=>dk.name==ds);
                  if(!findIndustry){
                    this.filterObject.industryList.push({name:ds,check:true});  
                  }
                })
              }
            }
          })
        }})
       }
       break;
      }
      case 'standard':{
        if (event.target.checked) {
          if(data.name=='All'){
            this.filterObject.standardList.map(dt=>{
              dt.check=true;
              return dt;
            })
          }else{
            let count=0;
            this.filterObject.standardList.forEach(dt=>{
              if(dt.check&&dt.name!="All"){
                count+=1;
              }
            })
            if(count==this.filterObject.standardList.length-1){
              this.filterObject.standardList[0].check=true;
            }
          }
          this.tableData=[];
          this.filterObject.industryList=[{name:'All',check:true}];
          this.reportsDetails.forEach(dt=>{
            if(dt.complianceOfferingGroup==this.currentTab&&data.name=='All'){
              this.tableData.push(dt);
            }else if(dt.complianceOfferingGroup==this.currentTab){
              this.filterObject.standardList.forEach(dm=>{
                if(dm.check){
              let findObj=dt.standards.find(ds=>ds==dm.name);
              if(findObj){
                this.tableData.push(dt);
                dt.industries.forEach(ds=>{
                  let findIndustry=this.filterObject.industryList.find(dk=>dk.name==ds);
                  if(!findIndustry){
                    this.filterObject.industryList.push({name:ds,check:true});  
                  }
                })
              }
            }})
            }
          })
       }
       else{
          if(data.name=='All'){
            this.filterObject.standardList.map(dt=>{
              dt.check=false;
              return dt;
            })
          }else{
            this.filterObject.standardList[0].check=false;
          }
          this.tableData=[];
          this.filterObject.industryList=[{name:'All',check:true}];
          this.filterObject.standardList.forEach(dm=>{
            if(dm.check){
          this.reportsDetails.forEach(dt=>{
            if(dt.complianceOfferingGroup==this.currentTab&&dm.name=='All'){
              this.tableData.push(dt);
            }else if(dt.complianceOfferingGroup==this.currentTab){
              let findObj=dt.standards.find(ds=>ds==dm.name);
              if(findObj){
                this.tableData.push(dt);
                dt.industries.forEach(ds=>{
                  let findIndustry=this.filterObject.industryList.find(dk=>dk.name==ds);
                  if(!findIndustry){
                    this.filterObject.industryList.push({name:ds,check:true});  
                  }
                })
              }
            }
          })
        }})
       }
       break;
      }
      case 'industry':{
        if (event.target.checked) {
          if(data.name=='All'){
            this.filterObject.industryList.map(dt=>{
              dt.check=true;
              return dt;
            })
          }else{
            let count=0;
            this.filterObject.industryList.forEach(dt=>{
              if(dt.check&&dt.name!="All"){
                count+=1;
              }
            })
            if(count==this.filterObject.industryList.length-1){
              this.filterObject.industryList[0].check=true;
            }
          }
          this.tableData=[];
          this.reportsDetails.forEach(dt=>{
            if(dt.complianceOfferingGroup==this.currentTab&&data.name=='All'){
              this.tableData.push(dt);
            }else if(dt.complianceOfferingGroup==this.currentTab){
              this.filterObject.industryList.forEach(dm=>{
                if(dm.check){
              let findObj=dt.industries.find(ds=>ds==dm.name);
              if(findObj){
                this.tableData.push(dt);
              }
            }});
            }
          })
       }
       else{
          if(data.name=='All'){
            this.filterObject.industryList.map(dt=>{
              dt.check=false;
              return dt;
            })
          }else{
            this.filterObject.industryList[0].check=false;
          }
          this.tableData=[];
          this.filterObject.industryList.forEach(dm=>{
            if(dm.check){
          this.reportsDetails.forEach(dt=>{
            if(dt.complianceOfferingGroup==this.currentTab&&dm.name=='All'){
              this.tableData.push(dt);
            }else if(dt.complianceOfferingGroup==this.currentTab){
              let findObj=dt.industries.find(ds=>ds==dm.name);
              if(findObj){
                this.tableData.push(dt);
              }
            }
          })
        }})
       }
      break;
      }
    }
   
    console.log('Filter Object',this.filterObject)
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'max': {
              let x = a.properties.score? a.properties.score.max: ' ';
              let y = b.properties.score? b.properties.score.max: ' ';
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

}
