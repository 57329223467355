<div class="Sap-support">
        <p class="heading" id="0">SAP Support</p>
        <p class="mb-0" >You can get SAP certified resources for migration of SAP systems to Azure and thereon for 
            ongoing Basis & Functional support with A3S. </p>
            <img src="assets\user-manual\support\List(2).svg" >
        <ul>
            <li>Click <b>create new ticket</b>.</li>
         <li>Fill the form below to provide your requirements to A3S support team. </li>
         <img src="assets\user-manual\support\Support-Form(2).svg" >
         <li>Click <b>submit</b>. </li>
         <li>Support team will work with you to provide the needed SAP certified resources per your 
             requirements. </li>
        </ul>
</div>

 


 

