import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TimechartService } from '../../../services/timechart.service';
import { UtilService } from '../../../services/util.service';
import { ApplicationMonitoringQueries } from '../../application-monitoring-queries';

@Component({
  selector: 'app-sap-netweaver-availability',
  templateUrl: './sap-netweaver-availability.component.html',
  styleUrls: ['./sap-netweaver-availability.component.scss']
})
export class SapNetweaverAvailabilityComponent implements OnInit {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  searchKey:any;
  subscriptionApp: Subscription;
  timeFilterList: any = [];
  timeFilterFlag: boolean=false;
  datasort: string;
  selectedData: any;
  currentFilter:any= {
    type : 'last48hr',
    from:  new Date(new Date().setDate(new Date().getDate() - 2)),
    to: new Date(),
    timeGranularity:'automatic',
    timeZone:'utc'
  };
  availabilityStatusTrend:any = {
    type:'availabilityStatusTrend',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'',
    xAxis:[],
    options:{},
    yAxis:[],
    showLegend : true,
    refreshTime : new Date(),
    legendData:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    showLoader : true,
    currentFilter: {
      type : 'last48hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 2)),
      to: new Date(),
      timeGranularity:'automatic',
      timeZone:'utc'
    }
  };
  processAvailabilitySID:any = {
    type:'processAvailabilitySID',
    tempData:[],
    tableData:[],
    showLoader : true,
    refreshTime : new Date(),
  };
  processDetails:FormGroup;
  processList: any = [];
  providerList: any=[];
  subscriptionList: any=[];
  timeFilterView=false;
  currentProjectData: any;

  constructor(private util: UtilService,private modalService: NgbModal,private timechartService:TimechartService,private fb:FormBuilder) { }

  ngOnInit(): void {
    this.processDetails= this.fb.group({
      process:[null],
      provider:[null],
      timeRange:['last48hr']
    });
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.subscriptionApp = this.util.getObservable().subscribe(async (res) => {
      if(res.currentFilteredProject){
        this.currentProjectData = res.currentFilteredProject;
        await this.getProviderList();
        this.triggerFilter(res.currentFilteredProject,'project');
        this.timeFilterView=true;
      }
    });
  }

  getProviderList() {
    this.providerList=[];
    return this.util.handleRequest('get','a3s_applicationHealthMonitor_getProviderList',[],null,null,null,true).then(async res=>{
      if(!res || !res.body) {
        return 
      }
      let data=res.body.value||[];
      data=data.filter(dt=>dt.properties.providerSettings.providerType=='SapNetWeaver');
      let ipData : any= data.filter(x=> x.properties.providerSettings.providerType=='SapHana').map(xe=> `'${xe.properties.providerSettings.hostname}'`)
      let ipResponse;
      if(ipData.length){
        ipData = ipData.join(',')
        let body ={ ipAddr : ipData }
        ipResponse = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getVMDetailsByGraphQuery',[],body,null,null,true)
      }
      await Promise.all(data.map(async xe=>{
          xe['resourceName'] = null
          if(xe.properties.providerSettings.providerType=='SapNetWeaver'){
              xe['resourceName'] = xe.properties.providerSettings.sapHostname.split('.')[0]
          }
          else if(xe.properties.providerSettings.providerType=='SapHana'){
              let filterData = ipResponse.body.data.filter(x=> x.privateIP == xe.properties.providerSettings.hostname)
              xe['resourceName'] = filterData.length ? filterData[0].virtualMachine.split('/')[filterData[0].virtualMachine.split('/').length - 1] : null
          }
      }))
      data = data.filter(ex=> this.currentProjectData.resourceData.some(x=> x.resourceName.toLowerCase() == ex.resourceName.toLowerCase()))
      data.forEach(dt=>{
        // let metadata=JSON.parse(dt.properties.metadata);
        let metadata = dt.properties.providerSettings
        if(metadata.sapSid){
          this.providerList.push({
            name:metadata.sapSid,
            groupBy:'All',
            checked:true
          });
        }
      });
      if(this.providerList.length){
        this.processDetails.patchValue({
          provider:this.providerList[0].name
        })
      }
    })
  }

  changeFilter() {
    this.loadAllCardData();
  }

  getChartData(type) {    
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    const filterSubs=tempSubList.filter(dt=>dt.checked);
    if(!filterSubs.length) {
      this[type].tableData=[];
      this[type].tempData=[];
      this[type].showLoader = false;
      this[type].xAxis=[];
      this[type].series=[];
      this[type].legendData=[];
      this[type].averageList=[];
      this[type].average=null;
    } else {
      let filterDate:any =this.util.getCommonIsoDate(this.currentFilter);
      filterDate.timeGranularity=this.currentFilter.timeGranularity;
      let body ={
        "subscriptions":tempSubList.map(dt=>dt.subscriptionId),//["162349c9-37d0-45e1-b2e3-28c4216b7c6b"]
        "query": type=='availabilityStatusTrend'?ApplicationMonitoringQueries[type].replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.currentFilter)).replace('STEP',this.util.getTimeGranularity(filterDate)):ApplicationMonitoringQueries[type].replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.currentFilter))
      }
      this[type].tableData=[];
      this[type].tempData=[];
      this[type].showLoader = true;
      return this.util.handleRequest('post','a3s_applicationHealthMonitor_runLogAnalyticsQuery',[],body,null,null,true).then(res=>{
        if(!res || !res.body) {
          this[type].showLoader = false;
          return 
        }
        res.body.tables.forEach(dt=>{
          dt.rows.forEach(ds=>{
            this[type].tempData.push(ds);
          });
        })
        if(type=='availabilityStatusTrend'){
          this.filterAvailabilityStatusTrend(null);
        } else {
          this.prepareData(type);
        }
        this[type].showLoader = false;
      },err=>{
        this[type].showLoader = false;
      })
    }
  }

  filterAvailabilityStatusTrend(appServerCheck) {
    this.availabilityStatusTrend.showLoader = true;
    this.availabilityStatusTrend.xAxis=[];
    this.availabilityStatusTrend.series=[];
    this.availabilityStatusTrend.legendData=[];
    this.availabilityStatusTrend.averageList=[];
    this.availabilityStatusTrend.average=null;
    var timeArray = [];
    this.availabilityStatusTrend.tempData.forEach((dt,index)=>{
      if(this.checkProviderVal(dt[2])) {
        if(!appServerCheck&&!this.processList.find(dp=>dp.name===dt[1])){
          this.processList.push({
            name:dt[1],
            checked:true
          })
        }
        
        if(appServerCheck&&this.verifyAppServer(dt[1])) {
          let findIndex=this.availabilityStatusTrend.series.findIndex(d=>d.name==dt[1]);
          if(findIndex>-1){
            this.availabilityStatusTrend.series[findIndex].data.push(Number((dt[3]).toFixed(2)));
          } else {
            let obj = {
              name :dt[1],
              type : 'line',
              showSymbol :false,
              data: [Number((dt[3]).toFixed(2))]
            }            
            this.availabilityStatusTrend.series.push(obj);
          }
          let findTime=timeArray.find(ds=>ds==dt[0]);
          if(!findTime){
            timeArray.push(dt[0]);       
          }
          let findObj=this.availabilityStatusTrend.legendData.find(d=>d.name==dt[1]);
          if(!findObj){
            this.availabilityStatusTrend.legendData.push({icon:'circle',name:dt[1]});
          }
        } else if(!appServerCheck) {
          let findIndex=this.availabilityStatusTrend.series.findIndex(d=>d.name==dt[1]);
          if(findIndex>-1){
            this.availabilityStatusTrend.series[findIndex].data.push(Number(dt[3].toFixed(2)));
          } else {
            let obj = {
              name : dt[1],
              type : 'line',
              showSymbol :false,
              data: [Number(dt[3].toFixed(2))]
            }            
            this.availabilityStatusTrend.series.push(obj);
          }
          let findTime=timeArray.find(ds=>ds==dt[0]);
          if(!findTime){
            timeArray.push(dt[0]);       
          }
          let findObj=this.availabilityStatusTrend.legendData.find(d=>d.name==dt[1]);
          if(!findObj){
            this.availabilityStatusTrend.legendData.push({icon:'circle',name:dt[1]});
          }
        }
      }
    });
    let sorted : any = timeArray.sort((a,b)=> {
      var d1 = new Date(b);
      var d2 = new Date(a);
      return d2 > d1 ? 1 : 0;
    })
  
    sorted.forEach((x)=>{
        let findXAxis=this.availabilityStatusTrend.xAxis.find(dt=>dt==moment(x).format('MM/DD/YYYY h:mm A'));
        if(!findXAxis){
        this.availabilityStatusTrend.xAxis.push(moment(x).format('MM/DD/YYYY h:mm A'))
        }
    })
    if(this.availabilityStatusTrend.series.length){
      let count=0;
      this.availabilityStatusTrend.series.forEach(dt=>{
        if(!this.availabilityStatusTrend.color[count]) {
          count=0;
        }
        this.availabilityStatusTrend.averageList.push({
          name:dt.name,
          colorCode:this.availabilityStatusTrend.color[count],
          average:((dt.data.reduce(
            (previousValue, currentValue) => previousValue + currentValue
          ))/dt.data.length).toFixed(2)
        });
        count=count+1;
      })
    }
    this.availabilityStatusTrend.options=this.setChartOption('availabilityStatusTrend');
    this.availabilityStatusTrend.options.legend.show=false;
    this.availabilityStatusTrend.options.grid.bottom=8;
    this.availabilityStatusTrend.showLoader = false;
  }

  checkProviderVal(providerName) {
    return this.processDetails.value.provider == providerName;
  }

  checkedAllAppServer() {
    return this.processList.filter(dt=>dt.checked).length===this.processList.length
  }
  
  verifyAppServer(appServerName) {
    return this.processList.find(dt=>dt.checked&&dt.name==appServerName);
  }

  checkedAll(element) {
    if(element.target.checked){
      this.processList.map(dt=>{
        dt.checked=true;
        return dt
      })
    } else {
      this.processList.map(dt=>{
        dt.checked=false;
        return dt
      })
    }
    this.filterAppServer();
  }

  checkedValue(data,event){
    if(event.target.checked){
      data.checked=true;
    } else {
      data.checked=false;
    }
    this.filterAppServer();
  }

  filterAppServer(){
    if(this.processAvailabilitySID.tempData.length) {
      this.processAvailabilitySID.showLoader=true;
      this.processAvailabilitySID.tableData=[];
      this.processAvailabilitySID.tempData.forEach(dt=>{
        if(this.checkProviderVal(dt[1])&&this.verifyAppServer(dt[4])){
          let findIndex=this.processAvailabilitySID.tableData.findIndex(ds=>ds.sapSystem==dt[2]);
          if(findIndex>-1){
            this.processAvailabilitySID.tableData[findIndex].childData.push(dt);
          } else {
            this.processAvailabilitySID.tableData.push({
              sapSystem:dt[2],
              childData:[dt]
            });
          }
        }
      })
      this.processAvailabilitySID.showLoader=false;
    }
    this.filterAvailabilityStatusTrend(true);
  }

  prepareData(type) {
    this[type].tableData=[];
    if(type=='processAvailabilitySID') {
      this.processAvailabilitySID.tempData.forEach(dt=>{
        if(this.checkProviderVal(dt[1])){
          let findIndex=this.processAvailabilitySID.tableData.findIndex(ds=>ds.sapSystem==dt[2]);
          if(findIndex>-1){
            this.processAvailabilitySID.tableData[findIndex].childData.push(dt);
          } else {
            this.processAvailabilitySID.tableData.push({
              sapSystem:dt[2],
              showDetail:true,
              childData:[dt]
            });
          }
        }
      })
    }
  }

  setChartOption(name) {
    let previousIndex;
    const filterDate =this.util.getCommonIsoDate(this.currentFilter);
    let dateList=this.timechartService.xAxisDateTimeFormatList(filterDate,this.currentFilter.timeZone,this[name]);
    dateList.splice(0,1);
    let timeSpan=this[name].xAxis.length-dateList.length;
    if(timeSpan>2) {
      timeSpan=2
    }
    let options = {
      color: this[name].color,
      tooltip: this.util.getLineChartTooltip(),
      legend: {
        show: true,
        data: this[name].legendData,
        bottom: 0,
        left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap:true,
          axisTick: {
            alignWithLabel: true,
            interval: Number((timeSpan).toFixed()),
          },
          axisLine:{
            onZero:false
          },
          data: this[name].xAxis,
          axisLabel: {
            color: '#626F7B',
            interval: Number((timeSpan).toFixed()),
            padding: [5, 0, 0, 0],
            fontSize: 12, 
            showMinLabel:dateList[0].valueXaxis?true:false,
            fontFamily: 'Inter',
            formatter: function (value, index) { 
              if(index==0) {
                previousIndex=-1;
              }
              previousIndex=previousIndex+1;
              if(dateList[previousIndex]) {
                return dateList[previousIndex].valueXaxis
              }
            }
          },
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
          },
      },
      series: this[name].series
    };
    return options
  }

  openPop(popId){
    popId.show();
  }

  ngOnDestroy() {
    if(this.subscriptionApp) this.subscriptionApp.unsubscribe();
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.type);
    return findObj&&findObj.name?findObj.name:''
  }

  loadAllCardData() {
    this.getChartData('availabilityStatusTrend');
    this.getChartData('processAvailabilitySID');
  }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    this.loadAllCardData();
    this.closeFilter()
  }

  openModalForCustom(value,template: TemplateRef<any>,list?:any) {
    list.currentFilter = value;
    this.openModal(template,list);
  }
  
  applyCustomFilter(){
    if(this.selectedData&&this.selectedData.type=='singleProcess') {
      // this.getSingleProcessesData(null,this.singleProcess.mainType,this.singleProcess.currentFilter);
    }else {
      this.loadAllCardData();
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 9;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = {currentFilter:this.currentFilter}
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    this.loadAllCardData();
    console.log('Filter Object',this.filterObject)
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    // this.getAllData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

  async refreshData(type){
    switch (type) {
      case 'availabilityStatusTrend':{
        this.availabilityStatusTrend.refreshTime = new Date();
        this.getChartData('availabilityStatusTrend');
        break;
      }
      case 'processAvailabilitySID':{
        this.processAvailabilitySID.refreshTime = new Date();
        this.getChartData('processAvailabilitySID');
        break;
      }
    }    
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  getTimeFilterText(filter){
    return this.util.getTimeFilterText(filter)
  }

  getUTCText(filter){
    return this.util.getUTCText(filter)
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

  sortPopup(tableName,index,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName][index];
    sorted.sort((a, b) => {
        switch (value) {
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName][index] = sorted;
 }

}
