import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from "rxjs/operators";
import {FlowService} from "./services/flow.service";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { UtilService } from './services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    onHome;
    onhover = false;
    isLoading = true;
    constructor (public flow:FlowService,private router: Router, private activatedRoute:ActivatedRoute, private titleService: Title,private util:UtilService) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data &&    child.snapshot.data['title']) {
                        return child.snapshot.data['title'];
                    } else {
                        return null;
                    }
                }
                return null;
            })
        ).subscribe( (data: any) => {
            if (data) {
               // this.chatbot()
                this.titleService.setTitle(data);
            }
            this.chatbot();
        });
        
    }

    ngOnInit(){
        // localStorage.setItem('clientID','a9643698-77b6-4e9f-933e-0576310cb7d5')
        // localStorage.setItem('authority','https://login.microsoftonline.com/9be10acf-2a10-42a0-9748-00a93cd07042')
        // var search = window.location.hash
        // if(search) {
        //     console.log(search);
        //     this.router.navigate(['/a3s']);
        // }
        // a816848c-d456-4122-99dc-fc06abdd1a93
        // '6e80a369-bb66-4aa9-9318-9294ad4fcf44'
        // 8d0e8ff4-8429-4628-b536-ce02f61053f0
        this.loadAppInsightsConfig('b17f8d4b-9f72-47db-8816-d9c1e8bfd972')
    }

    chatbot() {
        //window.location.pathname == '/a3s/home' || 
        if (window.location.pathname == '/' || window.location.pathname == '/admin' || window.location.pathname == '/register' || window.location.pathname == '/a3s/dataMigrationBucket') {
            this.onHome = false;
            this.flow.showIframe = false;
        }
        else {
            this.onHome = true;
        }
    }


    loadAppInsightsConfig(key){
        var angularPlugin = new AngularPlugin();
        const appInsights = new ApplicationInsights({ config: {
        instrumentationKey: key,
        enableAutoRouteTracking: true,
        extensions: [angularPlugin],
        extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
        }
        } });
        appInsights.loadAppInsights();
        appInsights.trackPageView()
        appInsights.trackException({exception: new Error("handled exceptions can be logged with this method")});
        appInsights.trackPageViewPerformance({ name: document.title, uri: location.href })
        appInsights.trackEvent({name:"WinGame"});
        appInsights.trackMetric({name: "queueLength", average: 42});

        return this.util.handleRequest('post','a3s_admin_appInsightsPlugin',[key],null,null,null,true)
    }
}

