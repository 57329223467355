

<router-outlet></router-outlet>

<div *ngIf="allowUrl()" class="">
    <div class="header-container"> 
      <h2 class="title">List of projects</h2>
      <span class="content-14 blur-text">Architecture Blueprint</span>
    </div>
  
    <div class="enlist-srch w-50 mt-3">
      <img src="assets/icons/search.png" />
      <input placeholder="Search projects" autocomplete="off"  class="form-control fix-width" (keyup)="search($event.target.value)" type="text" id="projectSearch">
    </div>
    <span class="blur-text">{{projectList.length == dummyProjectList.length ? getTableText('single') : getTableText('multiple') }}</span>
        
  
    <div class="custom-fixed-tables">
      <table class="table fix-width">
          <thead>
            <tr>
              <th>
                <div class="flex-custom no-wrap">
                  <span>Project name</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('project')">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                  <span>Status</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('status')">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                  <span>Subscription</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('subscription')">
                </div>
              </th>
              <th>
                <div class="flex-custom no-wrap">
                  <span>Created By</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('createdBy')">
                </div>
              </th>
              <th style="border-right: none !important;">
                <div class="flex-custom no-wrap">
                  <span>Created on (UTC)</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('timeStamp')">
                </div>
              </th>
              <th style="border-left: none !important;border-right: solid 1px #8CD4FD;">
                <div class="flex-custom no-wrap">
                <span></span>
              </div>
            </th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let list of projectList.reverse()" class="cursor-pointer">
                <ng-container (click)="routeToSummary(list)">
                  <td (click)="routeToSummary(list)">{{list?.projectName }}</td>
                  <td (click)="routeToSummary(list)">{{list?.status | titlecase }}</td>
                  <td (click)="routeToSummary(list)">{{list?.subscriptionName}}</td>
                  <td (click)="routeToSummary(list)">{{list?.createdBy}}</td>
                  <td (click)="routeToSummary(list)">{{list?.createdDate}} </td>
                  <td>
                    <span>
                      <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="profile">
                      <mat-menu #profile="matMenu" class="delete-popup">
                        <div class="mat-menu-item" mat-menu-item (click)="openSnackBar('Project has been deleted', 'Undo', list?.projectId)">
                          <p class="delete-popup-text">Delete</p>
                        </div>
<!-- 
                        <div class="mat-menu-item" mat-menu-item (click)="openSnackBar('Project has been deleted', 'Undo', list?.projectId)">
                          <div class="d-flex align-items-center" mat-stroked-button (click)="openSnackBar('Project has been deleted', 'Undo', list?.projectId)" >
                            <img src="assets/icons/delete.png" style="padding: 0px 8px 0px 8px !important;" class="img-fluid p-2 img-responsive" />
                            <p class="m-0 delete-popup-text" style="font-size: 13px;padding-top: 2px;">Delete project</p>
                          </div>
                        </div> -->
                      </mat-menu>
                    </span>              
                  </td>
                </ng-container>
              </tr>
          </tbody>
      </table>
    </div>
  
  
  
    <div class="fixed-btm-btn" >
      <button class="btn btn-btn-primary" (click)="routeToCreateNewProjectForm()"><div class="create-plus-btn"> <span class="plus">+</span><span>Create new project</span> </div>  </button>
    </div>
  
  
</div>
  
<div *ngIf="noTableData">

  <div class="header-container">
    <h2 class="title">Architecture Blueprint</h2>
    <span class="content-14 blur-text">Architecture Blueprint</span>
  </div>

  <div class="data-migrate-container">
    <div class="start-migrate">
        <div class="data-analytics-nolength-img">
          <img class="full-width" src="assets/Infra/architecture-blueprint.svg">
        </div>            
        <div class="data-analytics-nolength-text">
          <h4>Start Architecture Blueprint Project</h4>
        </div>
        <button class="btn btn-btn-primary btn-SM" (click)="routeToCreateNewProjectForm()">Create new project</button>
    </div>
  </div> 

</div>
