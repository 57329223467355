import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { IBreadCrumb } from '../../../app/@model/model';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadcrumbsComponent implements OnInit {

  public breadcrumbs: IBreadCrumb[];
  constructor(private util:UtilService, private router:Router, private activatedRoute: ActivatedRoute) { 
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    })
  }


   /**
 * Recursively build breadcrumb according to activated route.
 * @param route
 * @param url
 * @param breadcrumbs
 */
buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
  //If no routeConfig is avalailable we are on the root path
  let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
  let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

  // If the route is dynamic route such as ':id', remove it
  const lastRoutePart = path.split('/').pop();
  const isDynamicRoute = lastRoutePart.startsWith(':');
  if(isDynamicRoute && !!route.snapshot) {
    const paramName = lastRoutePart.split(':')[1];
    path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
    label = route.snapshot.params[paramName];
  }

  //In the routeConfig the complete path is not available,
  //so we rebuild it each time
  const nextUrl = path ? `${url}/${path}` : url;
  var self = this;
  const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl,
  };
  // if(nextUrl == '/a3s/diagramTool'){
  //   console.log('nextUrl',nextUrl)
  //   console.log(self.util.getCache('currentProject'))
  //   const breadcrumb: IBreadCrumb = {
  //     label: self.util.getCache('currentProject'),
  //     url: nextUrl,
  // };
  // }
  // Only adding route with non-empty label
  // const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
  const newBreadcrumbs = this.updateBreadcrumbs(nextUrl,breadcrumb,breadcrumbs)
  if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
  }
  return newBreadcrumbs;
}

  updateBreadcrumbs(nextUrl,breadcrumb,breadcrumbs){
    if(nextUrl == '/a3s/diagramTool') {
      breadcrumbs.push({label:'Architecture Blueprint',url:'/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList'});
      breadcrumbs.push({label:this.util.getCache('currentProject'),url:null});
      return breadcrumbs;
    }
    else  return breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
  }

  changeRoute(value){
    this.router.navigate([value]);
  }

}
