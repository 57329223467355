import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchitectureResourcesComponent } from './diagram-tool/architecture-resources/architecture-resources.component';
import { ArchitectureBlueprintComponent } from './architecture-blueprint.component';
import { ArchitectureBlueprintListComponent } from './architecture-blueprint-list/architecture-blueprint-list.component';
import { CreateNewBlueprintProjectComponent } from './create-new-blueprint-project/create-new-blueprint-project.component';
import { DiagramToolComponent } from './diagram-tool/diagram-tool.component';
import { DiagramModule, SymbolPaletteAllModule } from '@syncfusion/ej2-angular-diagrams';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { BreadcrumbsModule } from '../../common/breadcrumbs/breadcrumbs.module';
import { ArchitectureBlueprintSummaryComponent } from './architecture-blueprint-list/architecture-blueprint-summary/architecture-blueprint-summary.component';
import { ArchitectureDeploymentStatusComponent } from './architecture-blueprint-list/architecture-deployment-status/architecture-deployment-status.component';
import { AppRoutingModule } from '../../app-routing.module';
import { ResourcesModule } from '../../common/resources/resources.module'
import { PipesModule } from '../../pipes/pipes.module';
import { DirectiveModule } from 'src/app/directive/directive.module';
// import { InfraDeploymentBucketModuleRoutingModule } from '../infra-deployment-bucket-routing.module';



@NgModule({
  declarations: [
    ArchitectureBlueprintComponent,
    ArchitectureBlueprintListComponent,
    ArchitectureBlueprintSummaryComponent,
    ArchitectureDeploymentStatusComponent,
    ArchitectureResourcesComponent,
    CreateNewBlueprintProjectComponent,
    DiagramToolComponent
  ],
  imports: [
    CommonModule,
    // InfraDeploymentBucketModuleRoutingModule,
    FormsModule,
    NgSelectModule,
    AppRoutingModule,
    DiagramModule,
    SymbolPaletteAllModule,
    AngularMaterialModule,
    BreadcrumbsModule,
    ResourcesModule,
    PipesModule,
    DirectiveModule,
    ReactiveFormsModule
  ],
  providers: [
    TitleCasePipe,
    DatePipe
  ]
})
export class ArchitectureBlueprintModule { }
