import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oracle-server',
  templateUrl: './oracle-server.component.html',
  styleUrls: ['./oracle-server.component.scss']
})
export class OracleServerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
