<div class="introduction">

             <p class="heading" id="2">Sign-ins  </p>
             <ul class="mb-0" 
             >
                 <li>Table displays list of user sign-in details. </li>
                 <li>Click <b>Download</b> icon to download the table in CSV format.  </li>
                 <li>Use <b>Time range</b> to find sign-ins performed in the chosen timeframe.  </li>
             </ul>
             <img src="assets\user-manual\governance\Sign-ins.svg"  >
             <figcaption class="caption">Sign-in events </figcaption>
          
</div>