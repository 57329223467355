<div class="introduction">

    <p class="heading" id="2"  >Average CPU and Average Memory Usage </p>
    <p class="para mb-0">Computational power and memory used by the application.  </p>
    <img src="assets\user-manual\apps-health-monitoring\Tile(1).svg"  >
    <figcaption class="caption col-md-6">Avg CPU usage </figcaption>
    <img src="assets\user-manual\apps-health-monitoring\Tile(2).svg"  >
    <figcaption class="caption col-md-6">Avg memory usage </figcaption>
 
          
</div>
