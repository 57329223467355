import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-new-action',
  templateUrl: './create-new-action.component.html',
  styleUrls: ['./create-new-action.component.scss']
})
export class CreateNewActionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
