<div class="security-recommednation-container">
    <div class="header-container">
      <h2 class="title">Endpoint Security</h2>
    </div>
    
     <div class="custom-filter-wrapper flex-custom">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                        <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                        <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                        <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                        <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button>
    </div>

    <div class="dashboard-body">
      <div class="row" *ngIf="!runningProcessShow">
        <div class="col-md-4 padding-div left">
          <div class="graph-div count-card-height mt-2">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Syslog Events</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.syslogEventCard)}} . {{getTimeFilterVal(syslogEventCard)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('syslogEventCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter1">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="syslogEventCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,syslogEventCard):applyFilter(row.id,syslogEventCard)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="row m-0 mt-3" *ngIf="syslogEventCard?.tableData?.length && !syslogEventCard?.showLoader">
                  <ng-container *ngFor="let item of syslogEventCard?.tableData">
                      <div class="detail-top" [ngClass]="{'col-sm-6':syslogEventCard?.tableData.length>1,'col-sm-12':syslogEventCard?.tableData.length<2}">
                          <div class="event-card cursor-pointer" [ngClass]="item.name" (click)="openSyslogTablePopup(item,syslogEventTablePopup)">
                              <div class="status-flag"></div>
                              <div class="details-values">
                                  <span class="card-name">{{item.name | titlecase}}</span>
                                  <span class="card-count">{{item.count}}</span>
                              </div>
                          </div>
                      </div>
                  </ng-container>
              </div>
              <div class="loader-gif loader-for-card" *ngIf="syslogEventCard?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center count-card-text" *ngIf="(!syslogEventCard?.tableData?.length && !syslogEventCard?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 mt-3">No syslog events found.</p>
              </div>
          </div>
      </div>
        <div class="col-md-4 padding-div pl-4 middle">
          <div class="graph-div count-card-height mt-2">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Most Running Processes</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.runningProcessesCard)}} . {{getTimeFilterVal(runningProcessesCard)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('runningProcessesCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter2')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter2">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="runningProcessesCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,runningProcessesCard):applyFilter(row.id,runningProcessesCard)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="row m-0 mt-3" *ngIf="runningProcessesCard?.tableData?.length && !runningProcessesCard?.showLoader">
                <ng-container *ngFor="let item of runningProcessesCard?.tableData">
                    <div class="detail-top" [ngClass]="{'col-sm-6':runningProcessesCard?.tableData.length>1,'col-sm-12':runningProcessesCard?.tableData.length<2}">
                        <div class="detail-card most-running-card">
                            <span class="card-name" [title]="item.name">{{item.name}}</span>
                            <span class="card-count">{{item.count}}</span>
                        </div>
                    </div>
                </ng-container>
              </div>
              <div class="loader-gif loader-for-card" *ngIf="runningProcessesCard?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center count-card-text" *ngIf="(!runningProcessesCard?.tableData?.length && !runningProcessesCard?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 mt-3">No running processes found.</p>
              </div>
          </div>
      </div>
      <div class="col-md-4 pl-4 right">
        <div class="graph-div count-card-height mt-2">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Top 4 VMs with Failed Logon</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.vmFailedCard)}} . {{getTimeFilterVal(vmFailedCard)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('vmFailedCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <div class="cal-wrap">
                          <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                          matTooltipShowDelay="500" (click)="openFilter('customFilter3')"><img src="/assets/security/clock.svg" alt=""></span>
                          <div class="cal-box"  id="customFilter3">
                              <div class="cal-inner">
                              <div class="select-sec">
                                  <ng-container *ngFor="let row of timeFilterList">
                                      <div class="select-item" [class.active]="vmFailedCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,vmFailedCard):applyFilter(row.id,vmFailedCard)">{{row.name}}</div>
                                  </ng-container>
                              </div>
                              </div>
                          </div>
                      </div>
                  </div> 
                </div>
            </div>
            <div class="row m-0 mt-3" *ngIf="vmFailedCard?.tableData?.length && !vmFailedCard?.showLoader">
              <ng-container *ngFor="let item of vmFailedCard?.tableData">
                  <div class="detail-top" [ngClass]="{'col-sm-6':vmFailedCard?.tableData.length>1,'col-sm-12':vmFailedCard?.tableData.length<2}">
                      <div class="detail-card failed-login-card">
                          <span class="card-name" [title]="item.name">{{item.name}}</span>
                          <span class="card-count">{{item.count}}</span>
                      </div>
                  </div>
              </ng-container>
            </div>
            <div class="loader-gif loader-for-card" *ngIf="vmFailedCard?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center count-card-text" *ngIf="(!vmFailedCard?.tableData?.length && !vmFailedCard?.showLoader)">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 mt-3">No VMs with failed logons found.</p>
            </div>
        </div>
    </div>
      </div>
      <div class="row" *ngIf="!runningProcessShow">
        <div class="col-md-12 right">
          <div class="graph-div card-height">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Syslog Events</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.syslogEvent)}} . {{getTimeFilterVal(syslogEvent)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('syslogEvent')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('syslogEvents','syslogEvent')"><img src="assets/security/download_icon.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter4')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter4">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="syslogEvent?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,syslogEvent):applyFilter(row.id,syslogEvent)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="syslogEvent?.tableData?.length && !syslogEvent?.showLoader">
                <table class="table" id="syslogEvent">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Host</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('syslogEvent',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Severity</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('syslogEvent',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process Name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('syslogEvent',4)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Time Generated (UTC)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('syslogEvent',0)">
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of syslogEvent.tableData" class="cursor-pointer">
                        <ng-container (click)="openPopup('syslogEvent',row,syslogEventPopup)">
                          <td (click)="openPopup('syslogEvent',row,syslogEventPopup)">{{row[2].split('/')[row[2].split('/').length-1]}}</td>
                          <td (click)="openPopup('syslogEvent',row,syslogEventPopup)">
                            <div class="chip" [ngClass]="getStatusClass(row[1])">
                                {{row[1] | titlecase }}
                            </div>
                          </td>
                          <td (click)="openPopup('syslogEvent',row,syslogEventPopup)">{{row[4]}}</td>
                          <td (click)="openPopup('syslogEvent',row,syslogEventPopup)">{{row[0]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        </ng-container>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="loader-gif custom-loader-gif" *ngIf="syslogEvent?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!syslogEvent?.tableData?.length && !syslogEvent?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-16-26 mt-3">No syslog events found 
                    in the selected timeframe.</p>
              </div>
          </div>
      </div>
        <div class="col-md-12 right">
          <div class="graph-div card-height">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Rare Processes in Linux Host</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.rareProcesses)}} . {{getTimeFilterVal(rareProcesses)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('rareProcesses')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('rareProcessesInLinuxHost','rareProcessesInLinuxHost')"><img src="assets/security/download_icon.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter5')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter5">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="rareProcesses?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,rareProcesses):applyFilter(row.id,rareProcesses)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="rareProcesses?.tableData?.length && !rareProcesses?.showLoader">
                <table class="table" id="rareProcessesInLinuxHost">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process Name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('rareProcesses',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Host</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('rareProcesses',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Host IP</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('rareProcesses',2)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process Start Time (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('rareProcesses',4)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process End Time (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('rareProcesses',5)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>No. of times Process is Active</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('rareProcesses',3)">
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of rareProcesses.tableData">
                            <td>{{row[1]}}</td>
                            <td>{{row[0].split('/')[row[0].split('/').length-1]}}</td>
                            <td>{{row[2]}}</td>
                            <td>{{row[4]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                            <td>{{row[5]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                            <td>{{row[3]}}</td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="loader-gif custom-loader-gif" *ngIf="rareProcesses?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!rareProcesses?.tableData?.length && !rareProcesses?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-16-26 mt-3">No rare processes in Linux host found 
                    in the selected timeframe.</p>
              </div>
          </div>
      </div>
      <div class="col-md-12 right">
        <div class="graph-div card-height">
          <div class="heading">
              <div class="d-flex">
                  <div class="about">
                      <h4>Top 5 Most Running Processes</h4>
                      <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.runningProcesses)}} . {{getTimeFilterVal(runningProcesses)}}</p>
                  </div> 
                  <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('runningProcesses')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon ml-2" matTooltip="Running Process View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="showRunningProcess(null)"><img src="/assets/security/drilldown_icon.svg" alt=""></span>
                      <div class="cal-wrap">
                        <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                        matTooltipShowDelay="500" (click)="openFilter('customFilter6')"><img src="/assets/security/clock.svg" alt=""></span>
                        <div class="cal-box"  id="customFilter6">
                            <div class="cal-inner">
                            <div class="select-sec">
                                <ng-container *ngFor="let row of timeFilterList">
                                    <div class="select-item" [class.active]="runningProcesses?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,runningProcesses):applyFilter(row.id,runningProcesses)">{{row.name}}</div>
                                </ng-container>
                            </div>
                            </div>
                        </div>
                    </div>
                  </div>                  
              </div>                
          </div>
          <!-- <app-stacked-common-time-line-chart [data]='runningProcesses' *ngIf="runningProcesses?.series?.length && !runningProcesses?.showLoader"></app-stacked-common-time-line-chart> -->
          <div echarts [options]="runningProcesses?.options" [loading]="true" (chartClick)="onChartClick($event)" *ngIf="runningProcesses?.series?.length && !runningProcesses?.showLoader"></div>
          <div class="loader-gif custom-loader-gif" *ngIf="runningProcesses?.showLoader">
              <img src="assets/icons/blue-loader.gif"/>
              <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!runningProcesses?.series?.length && !runningProcesses?.showLoader">
              <img src="/assets/security/check-not-found.svg" alt="">
              <p class="text-16-26 mt-3">No running processes found 
                in the selected timeframe.</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 right">
        <div class="graph-div card-height">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>VMs with Security Logs</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.devicesWithSecurity)}} . Top 10 . {{getTimeFilterVal(devicesWithSecurity)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('devicesWithSecurity')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openTablePopup(devicesWithSecurityPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                      <div class="cal-wrap">
                      <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                      matTooltipShowDelay="500" (click)="openFilter('customFilter7')"><img src="/assets/security/clock.svg" alt=""></span>
                      <div class="cal-box"  id="customFilter7">
                          <div class="cal-inner">
                          <div class="select-sec">
                              <ng-container *ngFor="let row of timeFilterList">
                                  <div class="select-item" [class.active]="devicesWithSecurity?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,devicesWithSecurity):applyFilter(row.id,devicesWithSecurity)">{{row.name}}</div>
                              </ng-container>
                          </div>
                          </div>
                      </div>
                  </div>
                  </div> 
                </div>
            </div>
            <div echarts [loading]='true' [options]="devicesWithSecurity?.options" *ngIf="devicesWithSecurity?.data.length  && !devicesWithSecurity?.showLoader"></div>
            <div class="loader-gif" *ngIf="devicesWithSecurity?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!devicesWithSecurity?.data.length && !devicesWithSecurity?.showLoader)">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No VMs with security logs found in the selected timeframe.</p>
            </div>
        </div>
    </div>
    <div class="col-md-6 left padding-div">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Missing Critical And Security Updates</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.missingSecurity)}} . Top 10 . {{getTimeFilterVal(missingSecurity)}}</p>
                    </div> 
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" id="missingSecurity" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('missingSecurity')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openTablePopup(missingSecurityPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter8')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter8">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="missingSecurity?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,missingSecurity):applyFilter(row.id,missingSecurity)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>                
            </div>
            <app-horizontal-bar-chart [data]='missingSecurity' *ngIf="missingSecurity.data?.length&&!missingSecurity?.showLoader"></app-horizontal-bar-chart>
            <div class="loader-gif" *ngIf="missingSecurity?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!missingSecurity.data?.length && !missingSecurity?.showLoader">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No missing critical and security updates found in the selected timeframe.</p>
            </div>
        </div>
        </div>
    <div class="col-md-6 pl-4 right">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>VMs with Failed Logon</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.devicesWithFailed)}} . Top 10 . {{getTimeFilterVal(devicesWithFailed)}}</p>
                    </div> 
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" id="devicesWithFailed" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('devicesWithFailed')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openTablePopup(devicesWithFailedPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter9')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter9">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="devicesWithFailed?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,devicesWithFailed):applyFilter(row.id,devicesWithFailed)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>                
            </div>
            <app-horizontal-bar-chart [data]='devicesWithFailed' *ngIf="devicesWithFailed.data?.length&&!devicesWithFailed?.showLoader"></app-horizontal-bar-chart>
            <div class="loader-gif" *ngIf="devicesWithFailed?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!devicesWithFailed.data?.length && !devicesWithFailed?.showLoader">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No VMs with failed logon found in the selected timeframe.</p>
            </div>
        </div>
    </div>
      <div class="col-md-12 right">
        <div class="graph-div card-height mb-4">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>New Processes Observed</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.newProcesesObserved)}} . {{getTimeFilterVal(newProcesesObserved)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('newProcesesObserved')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('New Processes Observed','tableNewP')"><img src="assets/security/download_icon.svg" alt=""></span>
                      <div class="cal-wrap">
                        <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                        matTooltipShowDelay="500" (click)="openFilter('customFilter10')"><img src="/assets/security/clock.svg" alt=""></span>
                        <div class="cal-box"  id="customFilter10">
                            <div class="cal-inner">
                            <div class="select-sec">
                                <ng-container *ngFor="let row of timeFilterList">
                                    <div class="select-item" [class.active]="newProcesesObserved?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,newProcesesObserved):applyFilter(row.id,newProcesesObserved)">{{row.name}}</div>
                                </ng-container>
                            </div>
                            </div>
                        </div>
                    </div>
                  </div> 
                </div>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="newProcesesObserved?.tableData?.length && !newProcesesObserved?.showLoader">
              <table class="table" id="tableNewP">
                  <thead>
                    <tr>
                      <!-- <th>
                          <div class="flex-custom no-wrap">
                              <span>Time generated (UTC)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved','displayNameControl')">
                          </div>
                      </th> -->
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>File name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',0)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Computer</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',1)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Frequency</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',2)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Start time (UTC)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',3)">
                          </div>
                      </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>End time (UTC)</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',4)">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of newProcesesObserved.tableData">
                        <!-- <td>{{row[4]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td> -->
                        <td style="width:34% !important" [title]="row[0]">{{row[0]}}</td>
                        <td>{{row[1]}}</td>
                        <td style="width: 1% !important;">{{row[2]}}</td>
                        <td>{{row[3]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        <td>{{row[4]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="loader-gif" *ngIf="newProcesesObserved?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!newProcesesObserved?.tableData?.length && !newProcesesObserved?.showLoader)">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No new processes observed found in the selected timeframe.</p>
            </div>
        </div>
    </div>
      </div>
      <div class="row" *ngIf="runningProcessShow">
        <div class="col-md-12 d-flex mt-2" [formGroup]="processDetails" style="padding-right: 0px;">
            <div class="d-flex" style="align-items: start;width: 100%;">
                <img src="/assets/security/arrow_left.svg" alt="" style="margin: 0px 4px;cursor: pointer;" (click)="runningProcessShow=false;">
                <label style="font-weight: 400;font-size: 12px;line-height: 18px;cursor: pointer;" (click)="runningProcessShow=false;">Back to dashboard</label>
            </div>
            <ng-select [items]="processList" bindLabel="name" bindValue="name" [closeOnSelect]="true" name="process" placeholder="Process:" (change)="processChange()" style="margin-right: 12px;" [clearable]="false" formControlName="process">
                <ng-template ng-label-tmp let-item="item">
                    Process: {{item?.name}}
                  </ng-template>
            </ng-select>
            <ng-select [items]="timeFilterList" bindLabel="name" bindValue="id" [closeOnSelect]="true" name="timeRange" placeholder="Time Range:" (change)="processDetails.value.timeRange=='custom'?openModal(openCal,runningProcess):applyFilter(processDetails.value.timeRange,runningProcess)" [clearable]="false" formControlName="timeRange">
                <ng-template ng-label-tmp let-item="item">
                    Time Range: {{item?.name}}
                  </ng-template>
            </ng-select>
        </div>
        <div class="col-md-12 padding-div" style="min-height: calc(100vh - 300px);    margin-bottom: 46px;">
            <div class="graph-div running-process-height mb-4">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Running Processes - {{processDetails.value.process}}</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.runningProcess)}} . {{getTimeFilterVal(runningProcess)}}</p>
                      </div> 
                      <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('runningProcess')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div>                  
                  </div>                
              </div>
              <!-- <app-stacked-common-time-line-chart [data]='runningProcess' *ngIf="runningProcess?.series?.length && !runningProcess?.showLoader"></app-stacked-common-time-line-chart> -->
              <div echarts [options]="runningProcess?.options" [loading]="true" *ngIf="runningProcess?.series?.length && !runningProcess?.showLoader"></div>
              <div class="loader-gif custom-loader-gif" *ngIf="runningProcess?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="!runningProcess?.series?.length && !runningProcess?.showLoader">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-16-26 mt-3">No running processes found 
                    in the selected timeframe.</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <ng-template #killedKernelProcessesPopup>
    <div class="medium-popup-wrapper2 width-877">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Killed Kernel Processes</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row details-summary">
                <label class="summary-label col-sm-12">Summary</label>
                <div class="col-sm-3 column-detail">Process Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[4]}}</div>
                <div class="col-sm-3 column-detail">Process ID</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[5]}}</div>
                <div class="col-sm-3 column-detail">Host</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[2].split('/')[detailsPop[2].split('/').length-1]}}</div>
                <div class="col-sm-3 column-detail">Host IP</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[3]}}</div>
                <div class="col-sm-3 column-detail">Severity</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[1]}}</div>
                <div class="col-sm-3 column-detail">Time Generated (UTC)</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[0]|date:'MM/dd/yyyy, hh:mm:ss a'}}</div>
                <div class="col-sm-12 diff-line"></div>
                <div class="col-sm-12 message-details">
                    <span class="title-val">Message</span>
                    <span class="title-details">{{detailsPop[6]}}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #syslogEventPopup>
    <div class="medium-popup-wrapper2 width-877">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Syslog Events</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row details-summary">
                <label class="summary-label col-sm-12">Summary</label>
                <div class="col-sm-3 column-detail">Host</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[2].split('/')[detailsPop[2].split('/').length-1]}}</div>
                <div class="col-sm-3 column-detail">Host IP</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[3]}}</div>
                <div class="col-sm-3 column-detail">Severity</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[1]}}</div>
                <div class="col-sm-3 column-detail">Process Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[4]}}</div>
                <div class="col-sm-3 column-detail">Process ID</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[5]}}</div>
                <div class="col-sm-3 column-detail">Time Generated (UTC)</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[0]|date:'MM/dd/yyyy, hh:mm:ss a'}}</div>
                <div class="col-sm-12 diff-line"></div>
                <div class="col-sm-12 message-details">
                    <span class="title-val">Message</span>
                    <span class="title-details">{{detailsPop[6]}}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>

  <ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>
<ng-template #devicesWithSecurityPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>VMs with Security Logs</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table5>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithSecurity','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Activity</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithSecurity','name')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Security logs</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithSecurity','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of devicesWithSecurity?.tableData">
                          <td style="width: 30% !important;">{{row[0].split('.')[0]}}</td>
                          <td>{{row[1]}}</td>
                          <td style="width: 1% !important;">{{row[3]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!devicesWithSecurity?.tableData.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('VMs with Security Logs',table5)" *ngIf="devicesWithSecurity?.tableData.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #missingSecurityPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Missing Critical And Security Updates</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table3>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('missingSecurity','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Classification</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('missingSecurity','name')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('missingSecurity','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of missingSecurity?.tableData">
                          <td>{{row[0].split('.')[0]}}</td>
                          <td>{{row[1]}}</td>
                          <td>{{row[3]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!missingSecurity?.tableData.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Missing Critical And Security Updates',table3)" *ngIf="missingSecurity?.tableData.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #devicesWithFailedPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>VMs with Failed Logon</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table1>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithFailed','name')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count of Failed Logins</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithFailed','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of devicesWithFailed?.tableData">
                          <td>{{row[0].split('.')[0]}}</td>
                          <td>{{row[2]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!devicesWithFailed?.tableData.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('VMs with Failed Logon',table1)" *ngIf="devicesWithFailed?.tableData.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>

  <ng-template #syslogEventTablePopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Syslog Events</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Host</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('currentSyslogTableData','1')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Severity</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('currentSyslogTableData','1')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process Name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('currentSyslogTableData','4')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process ID</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('currentSyslogTableData','5')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time Generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('currentSyslogTableData','0')">
                            </div>
                        </th>
                        <th class="w-50">
                            <div class="flex-custom no-wrap">
                                <span>Message</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('currentSyslogTableData','6')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of currentSyslogTableData">
                          <td>{{row[2].split('/')[row[2].split('/').length-1]}}</td>
                          <td>
                            <div class="chip" [ngClass]="getStatusClass(row[1])">
                                {{row[1] | titlecase }}
                            </div>
                          </td>
                          <td>{{row[4] | displayTxt}}</td>
                          <td>{{row[5] | displayTxt}}</td>
                          <td>{{row[0] | a3sDateTimeFormat  | displayTxt}}</td>
                          <td matTooltip="{{row[6]}}" matTooltipClass="nav-tooltips" matTooltipShowDelay="500">{{row[6]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!currentSyslogTableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>