<div class="introduction">
    <p class="heading" id="0">Overview</p>
    <label>Click Infrastructure Health Monitoring tile on the homepage. 
        health of the first application deployed under first project.  </label>
 
             <ul>
                 
                 <li>By default, overall health of the first project will be displayed. </li>
                 <img src="assets\user-manual\infra-health-monitoring\Overview.svg">
                 <figcaption class="caption">Infra health monitoring - project overview</figcaption>
                 <li>Projects and resource types under the projects are listed in the left menu.  </li>
                 <img src="assets\user-manual\infra-health-monitoring\Overview.svg">
                 <figcaption class="caption"> Infra health monitoring dashboard</figcaption>
            
                 <li>It is a two-level tree structure as shown in the image above:  </li>
                    <ol style="list-style-type:lower-roman">
                        <li>Level 1: is a list of projects. </li>
                        <li>Level 2: resource type(s) deployed under the project 
                            (for example, Virtual Machine, Storage, etc.). </li>
                    </ol>
              
                 <li>Live dashboards are available for project & resource type and the dashboard with
                      corresponding data will be displayed when a project and resource type is chosen.  </li>
                 <li><b>Threshold icon</b> helps to set the threshold for a metric of a resource. Alert will be 
                     sent when the threshold is crossed.  </li>
                 <li><b>Refresh icon </b>on the tile helps you refresh the chart to view latest data. </li>
                 <li><b>Table icon </b>is to view specific information in tabular format. </li>
                 <li><b>Filters</b> provide a way to drill down to search and view specific information 
                     and filters out the charts not needed in the tile. </li>
  
            </ul>
                           
       <b>Note: Threshold, refresh, table icons are available on those tiles where they are applicable. </b>
          
</div>