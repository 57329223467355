<div class="overview-content">
    <div class="header-container">
      <h2 class="title">System Check</h2>
    </div>
    <div class="d-flex">
        <div class="custom-filter-wrapper flex-custom" style=" width: 100%;">
            <div class="custom-filter">
                <span class="text">Subscription</span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <div class="enlist-srch">
                        <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                        <img src="assets/icons/search.png">
                    </div>
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="custom-dropdown" [formGroup]="filterForm">
                <ng-select [items]="providerList" *ngIf="providerList.length" [searchable]="false" bindLabel="name"
                  bindValue="name" name="provider" formControlName="provider" [closeOnSelect]="true" [clearable]="false"
                  id="provider" (change)="changeFilter()">
                  <ng-template ng-label-tmp let-item="item">
                    Provider: {{item?.name}}
                  </ng-template>
                </ng-select>
              </div> -->
            <div class="custom-dropdown" [formGroup]="filterForm">
                <ng-select [items]="sidList" *ngIf="sidList?.length" [searchable]="false" name="provider" formControlName="sid" [closeOnSelect]="true" [clearable]="false"
                  id="provider" (change)="changeFilter()">
                  <ng-template ng-label-tmp let-item="item">
                    SID: {{item}}
                  </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="main-nav">
        <ul class="nav-wrap m-0" >
        <li class="list-item" [class.active]="currentTab == 'savePoint'" (click)="currentTab='savePoint';loadAllCardData()">
            <span class="menu-name">Save Point</span>
        </li>
        <li class="list-item" [class.active]="currentTab == 'mvcc'" (click)="currentTab='mvcc';loadAllCardData()">
            <span class="menu-name">MVCC</span>
        </li>
        <li class="list-item" [class.active]="currentTab == 'deltaMerge'" (click)="currentTab='deltaMerge';loadAllCardData()">
            <span class="menu-name">Delta Merge</span>
        </li>
        <li class="list-item" [class.active]="currentTab == 'statisticsAlerts'" (click)="currentTab='statisticsAlerts';loadAllCardData()">
            <span class="menu-name">Statistics Alerts</span>
        </li>
        </ul>
    </div>      
    <div class="row custom-padding" *ngIf="currentTab== 'savePoint'">
        <div class="col-md-12 right">
            <div class="graph-div mb-4">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <p>Refreshed: {{refreshedTimeFormat(savePoint?.refreshTime)}} . {{getTimeFilterVal(savePoint?.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('savePoint')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter1">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="savePoint?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,savePoint):applyFilter(row.id,savePoint)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                          </div>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="savePoint?.tableData?.length && !savePoint?.showLoader">
                  <table class="table" id="savePoint">
                      <thead>
                        <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Start time</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('savePoint',1)">
                                </div>
                            </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Blocking Phase</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('savePoint',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Critical Phase</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('savePoint',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>End Time</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('savePoint',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Number Of Savepoint</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('savePoint',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Savepoint Purpose</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('savePoint',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Initiation</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('savePoint',4)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Aggregation</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('savePoint',0)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of savePoint.tableData">
                            <tr>
                                <td>{{row[0]}}</td>
                                <td>{{row[1]}}</td>
                                <td>{{row[2]}}</td>
                                <td>{{row[3]}}</td>
                                <td>{{row[4]}}</td>
                                <td>{{row[5]}}</td>
                                <td>{{row[6]}}</td>
                                <td>{{row[7]}}</td>
                            </tr>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif" *ngIf="savePoint?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!savePoint?.tableData?.length && !savePoint?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row custom-padding" *ngIf="currentTab== 'mvcc'">
        <div class="col-md-12 right">
            <div class="graph-div mb-4">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <p>Refreshed: {{refreshedTimeFormat(mvcc?.refreshTime)}} . {{getTimeFilterVal(mvcc?.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('mvcc')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter2')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter2">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="mvcc?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,mvcc):applyFilter(row.id,mvcc)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                          </div>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="mvcc?.tableData?.length && !mvcc?.showLoader">
                  <table class="table" id="mvcc">
                      <thead>
                        <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Host</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('mvcc',0)">
                                </div>
                            </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Port</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('mvcc',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Name</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('mvcc',2)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Value</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('mvcc',3)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of mvcc.tableData">
                            <tr>
                                <td>{{row[0]}}</td>
                                <td>{{row[1]}}</td>
                                <td>{{row[2]}}</td>
                                <td>{{row[3]}}</td>
                            </tr>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif" *ngIf="mvcc?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!mvcc?.tableData?.length && !mvcc?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row custom-padding" *ngIf="currentTab== 'deltaMerge'">
        <div class="col-md-12 right">
            <div class="graph-div mb-4">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <p>Refreshed: {{refreshedTimeFormat(deltaMerge?.refreshTime)}} . {{getTimeFilterVal(deltaMerge?.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('deltaMerge')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter3')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter3">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="deltaMerge?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,deltaMerge):applyFilter(row.id,deltaMerge)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                          </div>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="deltaMerge?.tableData?.length && !deltaMerge?.showLoader">
                    <table class="table" id="mvcc">
                        <thead>
                          <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Start Time</span>
                                      <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',0)">
                                  </div>
                              </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>End Time</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',1)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Duration (sec)</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',2)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Host</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',3)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Port</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',4)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Count</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',5)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Type</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',6)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Motivation</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',7)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Schema Name</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',8)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Table Name</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',9)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Rows Merged</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',10)">
                                </div>
                            </th>
                            <th class="borderBlueRight">
                              <div class="flex-custom no-wrap">
                                  <span>Last Error</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('deltaMerge',11)">
                              </div>
                          </th>
                          </tr>
                        </thead>
                        <tbody >
                          <ng-container *ngFor="let row of deltaMerge.tableData">
                              <tr>
                                  <td>{{row[0]}}</td>
                                  <td>{{row[1]}}</td>
                                  <td>{{row[2]}}</td>
                                  <td>{{row[3]}}</td>
                                  <td>{{row[4]}}</td>
                                  <td>{{row[5]}}</td>
                                  <td>{{row[6]}}</td>
                                  <td>{{row[7]}}</td>
                                  <td>{{row[8]}}</td>
                                  <td>{{row[9]}}</td>
                                  <td>{{row[10]}}</td>
                                  <td>{{row[11]}}</td>
                              </tr>
                          </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="loader-gif" *ngIf="deltaMerge?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!deltaMerge?.tableData?.length && !deltaMerge?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row custom-padding" *ngIf="currentTab== 'statisticsAlerts'">
        <div class="col-md-12 right">
            <div class="graph-div mb-4">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <p>Refreshed: {{refreshedTimeFormat(statisticsAlerts?.refreshTime)}} . {{getTimeFilterVal(statisticsAlerts?.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <div class="margin-multi-select" *ngIf="ratingList?.length && !statisticsAlerts?.showLoader">
                                <ng-select class="width-170" [items]="ratingList" [closeOnSelect]="false" [selectableGroup]="true" [multiple]="true" [searchable]="false" name="status" [(ngModel)]="currentRating" (change)="filterRating()" [clearable]="false" id="status">
                                    <ng-template ng-multi-label-tmp let-items="items">
                                    <span> Rating: {{items?.length == ratingList?.length ? 'All':items?.length + ' selected'}} </span>
                                    </ng-template>
                                    <ng-template ng-header-tmp *ngIf="ratingList?.length">
                                    <div class="checkbox-cs">
                                        <input id="item-all" name="item-all" type="checkbox" class="form-check-inline" (change)="onSelectAll($event)" [checked]="checkedAll()"/> All
                                    </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item"  let-index="index" let-item$="item$">
                                    <div class="checkbox-cs">
                                        <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }" [checked]="item$.selected"/> {{item}}
                                    </div>
                                    </ng-template>
                                </ng-select>
                            </div>

                          <span class="action-icon ml-3" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('statisticsAlerts')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter4')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter4">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="statisticsAlerts?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,statisticsAlerts):applyFilter(row.id,statisticsAlerts)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                          </div>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="statisticsAlerts?.tableData?.length && !statisticsAlerts?.showLoader">
                    <table class="table" id="mvcc">
                        <thead>
                          <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Date</span>
                                      <img class="sort" src="assets/icons/sort.png" (click)="sortAll('statisticsAlerts',0)">
                                  </div>
                              </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Time</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('statisticsAlerts',1)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Alert Id</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('statisticsAlerts',2)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Rating</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('statisticsAlerts',3)">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Alert Details</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('statisticsAlerts',4)">
                                </div>
                            </th>
                            <th class="borderBlueRight">
                              <div class="flex-custom no-wrap">
                                  <span>Recommendation</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('statisticsAlerts',5)">
                              </div>
                          </th>
                          </tr>
                        </thead>
                        <tbody >
                          <ng-container *ngFor="let row of statisticsAlerts.tableData">
                              <tr>
                                  <td>{{row[0]}}</td>
                                  <td>{{row[1]}}</td>
                                  <td>{{row[2]}}</td>
                                  <!-- <td>{{row[3]}}</td> -->
                                  <td>
                                    <div class="chip" [ngClass]="getStatusClass(row[3])">
                                        {{row[3] | titlecase}}
                                    </div>
                                  </td>
                                  <td>{{row[4]}}</td>
                                  <td>{{row[5]}}</td>
                              </tr>
                          </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="loader-gif" *ngIf="statisticsAlerts?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!statisticsAlerts?.tableData?.length && !statisticsAlerts?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>