import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metrics-page-view',
  templateUrl: './metrics-page-view.component.html',
  styleUrls: ['./metrics-page-view.component.scss']
})
export class MetricsPageViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
