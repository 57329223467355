<div class="introduction">
          <p class="heading" id="0">Delete Project</p>
          <ol class="mb-0" >
              <li>Click ellipsis (<img class="ellipis" src="assets\user-manual\Vector.svg" >) then click delete project.  </li>    
          </ol>
          <img src="assets\user-manual\apps-&-infra\delete-project.png" >
          <figcaption class="caption">Delete project</figcaption>
          <b>Note : Normal user can see only their list of projects, however global/company admin can 
              view all the projects and delete. Normal user needs consent from global admin to delete the 
              project. </b>
   
</div>

