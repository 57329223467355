<!-- <img src="./assets/icons/fab-icon.svg" class="fix-bottom-left" height="64" width="64"> -->

<!-- <div class="row m-0 h-100 login"> -->

  <!-- <div class="col-md-6 px-2 d-flex mh-100 flex-column align-items-center justify-content-around">
    <div class="px-lg-5 w-100 mx-lg-5 px-md-2 mx-sm-0 d-flex align-items-start">
      <img src="./assets/logo.svg" class="px-5 mx-lg-5 px-md-2 mx-sm-0" alt="logo">
    </div>
    <div class="form-area p-lg-5 py-lg-4 mx-lg-5 px-md-2 mx-sm-0">
      <h2 class="title px-5" style="font-size :24px;">Sign In </h2>
      <p class="text-muted px-5">
        Please enter your organization's A3S ID, or contact your admin.
      </p>
      <form [formGroup]="logincredentials" class="form px-5">
        <input type="text" class="form-control w-100" [ngClass]="{'border border-danger' : invalidErr}"
          placeholder="Enter Your A3S ID" formControlName="UserName" (keyup)="invalidErr=false">
        <p *ngIf="invalidErr" class="text-danger pt-1 d-inline-block">Invalid email</p>
        <button type="submit" placeholder="Enter you A3S organization ID" class="btn btn-btn-primary btn-block my-3"
          (click)="loginSubmit()" [disabled]="!logincredentials.valid || invalidErr || submitLoader">
          {{!submitLoader ? "Log In" : "Validating..."}}
        </button>
      </form>
      <p class="mt-3 px-5 text-muted">Don't Have A3S ID? <span (click)="routeToRegister()"
          style="color: #0B70D5">Register</span>
      </p>
    </div>
    <div class="text-secondary w-100 px-lg-5 mx-lg-5 px-md-2 mx-sm-0">
      <p class="text-left mx-lg-5 mx-2 d-flex align-items-center pointer">
        <span>Learn More </span>
        <svg class="ml-3" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.21428 1.28571C5.85924 1.28571 5.57143 0.997897 5.57143 0.642857C5.57143 0.287817 5.85924 0 6.21428 0H11.3571C11.534 0 11.6941 0.0713932 11.8103 0.186927L11.8117 0.188288L11.8131 0.189654C11.874 0.250972 11.9201 0.321519 11.9512 0.396782C11.9827 0.472579 12 0.555692 12 0.642857V5.78571C12 6.14075 11.7122 6.42857 11.3571 6.42857C11.0021 6.42857 10.7143 6.14075 10.7143 5.78571V2.19485L6.026 6.88314C5.77495 7.13419 5.36791 7.13419 5.11686 6.88314C4.86581 6.63209 4.86581 6.22505 5.11686 5.974L9.80515 1.28571H6.21428Z"
            fill="#8995A2" />
          <path
            d="M2.78571 2.14286C1.95729 2.14286 1.28571 2.81443 1.28571 3.64286V9.21428C1.28571 10.0427 1.95729 10.7143 2.78571 10.7143H8.35714C9.18557 10.7143 9.85714 10.0427 9.85714 9.21428V7.71428C9.85714 7.35924 10.145 7.07143 10.5 7.07143C10.855 7.07143 11.1429 7.35924 11.1429 7.71428V9.21428C11.1429 10.7528 9.89565 12 8.35714 12H2.78571C1.24721 12 0 10.7528 0 9.21428V3.64286C0 2.10435 1.24721 0.857143 2.78571 0.857143H4.28571C4.64075 0.857143 4.92857 1.14496 4.92857 1.5C4.92857 1.85504 4.64075 2.14286 4.28571 2.14286H2.78571Z"
            fill="#8995A2" />
        </svg></p>
    </div>
  </div> -->

<!-- The left column with white background is till here -->




  <!-- <div class="col-md-6 d-md-block d-none mh-100 p-5" style="background-color: #DAF1FF;">
    <div class="d-flex flex-column align-items-center justify-content-around">
      <h3 class="m-lg-5 px-lg-5 px-md-2 mx-md-2 text-center">Secure platform for <br> all your cloud needs</h3>
      <img class="px-lg-5 m-lg-5 m-md-3" src="/assets/login-img.svg" height="auto" width="500" />
    </div>
  </div>
</div> -->

<!-- <div class="loader-head" *ngIf="redirectMessage">
  <img class="loader" src="./assets/spinloader.gif">
  <p>Please Wait...</p>
  <p>Publishing your Dashboard</p>
</div> -->


<div class="body" *ngIf="showLogIn">
  <div class="layer">
    
  </div>

  <div class="login-block">
    <div class="header d-flex align-items-start">
      <!-- <div class="d-flex"> -->
        <div class="logo-img" ><img src="./assets/icons/logo-red.svg" alt="logo"></div>
        <div class="header-A3S-name"><img src="/assets/home/AI-powered.svg" alt=""></div>
      <!-- </div> -->
      <!-- <span><img src="./assets/icons/logo-red.svg" alt="logo"></span>
      <span class="a3s-name">
        <h3>AI-Powered Azure Automation</h3>
        <h3>as a service</h3>
      </span> -->
    </div>
    <div class="form-area ">
      <h2 class="title">Sign in </h2>
      <p class="text-muted">
        Please enter your organization's A3S ID, or contact your admin.
      </p>
      <form [formGroup]="logincredentials" class="form">
        <input type="text" class="form-control w-100" [ngClass]="{'border-danger' : invalidErr}"
          placeholder="Enter Your A3S ID" formControlName="UserName" (keyup)="invalidErr=false">
        <p *ngIf="invalidErr" class="text-danger d-inline-block">{{errMsg}}</p>
        <p *ngIf="!invalidErr" class="d-inline-block no-text"></p>
        <button type="submit" placeholder="Enter you A3S organization ID" class="btn btn-btn-primary btn-block"
          (click)="loginSubmit()" [disabled]="!logincredentials.valid || invalidErr">
          {{!submitLoader ? "Log in" : "Validating..."}}
        </button>
      </form>
      <p class="text-muted">Don't Have A3S ID? 
        <span (click)="routeToRegister()" class="registerOption">Register</span>
      </p>
    </div>


    <!-- <div class="tooltip">
      Hover <span class="tooltiptext">Tooltip text</span>
    </div> -->

    <div class="below-logindiv">
      <h2>A3S – Zero Code, 100% Automated, AI-powered Azure Management Platform.</h2>
      <div class="d-flex">
        <div class="options-list tooltip">
          <span class="tooltiptext">Increased productivity with automation across all aspects of Azure, 
            while minimizing manual effort, errors & cost.</span>
          <div class="icon-box"><img src="/assets/home/login-screen/automate.svg" alt=""></div>
          <h3>Automate</h3>
        </div>
        <div class="options-list tooltip">
          <span class="tooltiptext">Making Azure super easy to use with comprehensive, proactive & ongoing monitoring, 
            management & optimization. </span>
          <div class="icon-box"><img src="/assets/home/login-screen/simplify.svg" alt=""></div>
          <h3>Simplify</h3>
        </div>
        <div class="options-list tooltip">
          <span class="tooltiptext">Leveraging cutting-edge AI & ML to make smarter decisions and recommendations 
            for increased productivity with Azure.</span>
          <div class="icon-box"><img src="/assets/home/login-screen/learn.svg" alt=""></div>
          <h3>Learn</h3>
        </div>
        <div class="options-list tooltip">
          <span class="tooltiptext">24x7 relentless support by highly trained Azure certified engineers to provide a truly 
            delightful Managed Services experience.</span>
          <div class="icon-box"><img src="/assets/home/login-screen/support.svg" alt=""></div>
          <h3>Support</h3>
        </div>
        
      </div>
    </div>
  
  </div> 

  <!-- <ng-template #automateTooltip>
    <div>Sample try tooltip</div>
  </ng-template> -->

</div>
