import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identity-security',
  templateUrl: './identity-security.component.html',
  styleUrls: ['./identity-security.component.scss']
})
export class IdentitySecurityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
