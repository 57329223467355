<div class="introduction">
    <p class="heading" id="4">Retail Recommender</p>
    <p>This use case takes input from the user database and recommends the products based on the Collaborative Filtering method. </p>
      <ol>
          <li>Click <b>Data & Analytics -> Create New project -> Select Retail Recommender </b>  </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\RetailRecommender(1).svg" >
          <figcaption class="caption">Retail Recommender – Select use case</figcaption>
          <li>Fill source details to connect to the data source  </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\RetailRecommender(2).svg" >
          <figcaption class="caption">Retail Recommender - Source details </figcaption>
          <li>Prepare data by selecting <b>Table, Prediction column,</b> and <b>Train ratio</b>  </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\RetailRecommender(3).svg" >
          <figcaption class="caption">Retail Recommender – Prepare data </figcaption>
          <li><b>Output page</b> </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\RetailRecommender(4).svg" >
          <figcaption class="caption">Retail Recommender – Output </figcaption>
          <li><b>Summary Page </b> </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\RetailRecommender(5).svg" >
          <figcaption class="caption"> Retail Recommender – Summary </figcaption>
          <li> <b>A3S dashboard page </b> </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\RetailRecommender(6).svg" >
          <figcaption class="caption">Retail Recommender – dashboard </figcaption>
     
      </ol>
  </div>