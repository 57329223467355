<div class="introduction">
    <p class="heading" id="0">Metrics</p>
    <ul>
        <li>To view the health metrics of a resource type under a project, choose and click on a resource type. </li>
        <img src="assets\user-manual\infra-health-monitoring\VMs.svg">
        <figcaption class="caption">Infra health monitoring - resource metrics </figcaption>
        <li>Click refresh <img class="ellipis" src="assets\user-manual\table.svg" >  icon on the tile to refresh the data.  </li>
        <img style="width: 50%;" src="assets\user-manual\infra-health-monitoring\Tile.svg">
        <figcaption class="caption col-md-6">Resource health tile </figcaption>
        <li>Click threshold <img class="ellipis" src="assets\user-manual\refresh.svg" > icon to set custom threshold value. </li>
        <img class="mb-3" src="assets\user-manual\infra-health-monitoring\Tile(1).svg">
        <li>Click table <img class="ellipis" src="assets\user-manual\threshold.svg" > icon to view & download information in tabular format.  </li>
        <img class="mb-3" style="width: 50%;" src="assets\user-manual\infra-health-monitoring\Tile(2).svg">
        <li>Click clock <img class="ellipis" src="assets\user-manual\time.svg" > icon to set the timeframe for which you want to view the data for.  </li>
        <img  style="width: 50%;" src="assets\user-manual\infra-health-monitoring\Tile(2).svg">
        <img class="mb-3" style="width: 51%;" src="assets\user-manual\infra-health-monitoring\Dropdown-Menu.svg">      
       
    
    
 
    
    </ul>

          
</div>
