 <div class="introduction">
          <p class="heading" id="1">Overview</p>
          <p>The page has the following sections: </p>
          <ul class="mb-0">
              <li><b>Left menu:</b> two-level tree structure (shown in the image above):</li>
              <ol>
                <li>Level 1: is list of projects.</li> 
                <li>Level 2: underneath a project is list of the resource types. </li>
              </ol>
        <li><b>Dashboards:</b> at two levels:  </li>
        <ol>
            <li>Project.</li>
            <li>Resource type.  </li>
        </ol>
        <li><b>Filter options:</b> </li>
            <ol>
                <li>Subscription: in case the resources are deployed in multiple subscriptions under a project</li>
                <li>Resource group: to view resources from specific resource groups </li>
                <li>Resource: to manage a specific resource.   </li>
            </ol>
        <li><b>Learn more:</b> to visit knowledge base page. </li>
        <li><b>Main area: </b>resource dropdowns and actions (explained later in detail). </li>
         </ul>
         <img src="assets\user-manual\apps-&-infra\Overview.svg" >
         <figcaption class="caption">Management Tab: Overview </figcaption>
 
      
</div>
