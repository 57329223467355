
 
    <div class="side-bar-container-2 scroll-y">
      <ul class="side-nav">
        <li class="text-right li-collapse-btn">
          <button class="collapse-arrow normal-view" (click)="collapseSider()"></button>
          <button class="collapse-arrow icon-view" tooltip="Expand Panel" container="body" placement="left" containerClass="mgmt-tooltip" (click)="collapseSider()"></button>
  
          <ng-container *ngFor="let menu of complianceMenu;let f = index">
            <li class="menu-item">
              <div class="menu-inner" [class.open]="allowSubMenus(menu)" id="{{menu.menu}}" (click)="triggerMenuClick(menu)" [routerLink]="menu.route"  routerLinkActive="active">
                <div class="menu-icon normal-view"><img [src]="menu?.image" alt="Icon" /></div>
                <div class="menu-icon icon-view" [tooltip]="menu?.value" container="body" placement="left" containerClass="mgmt-tooltip"><img [src]="menu?.image" alt="Icon" /></div>
                <div class="menu-name">{{menu?.value}}</div>
              </div>
              <ul class="submenu" *ngIf="allowSubMenus(menu)" >
                <ng-container *ngFor="let list of projectList;let i = index">
                  <li class="sub-item">
                    <div class="submenu-inner" id="types_{{i}}" [class.active]="currentProject == list.projectName" (click)="setCurrentProject(list,i)">
                      <!-- <div class="sub-icon normal-view"><img src="assets/global-filter/strong.svg" alt="Icon" /></div> -->
                      <!-- <div class="sub-icon icon-view" [tooltip]="list.projectName" container="body" placement="left" containerClass="mgmt-tooltip"><img src="assets/global-filter/strong.svg" alt="Icon" /></div> -->
                      <div class="sub-name">{{list.projectName}}</div>
                    </div>
                    <!-- <ul class="submenu-2">
                      <li class="sub-item-2" *ngFor="let types of list?.resourceTypes;let j = index" [class.active]="currentResourceType == types.resourceType" (click)="setCurrentResourceType(types)">
                        <div class="sub-name-2">{{splitWordByCaps(types?.resourceType) | titlecase}} ({{types?.count}})</div>
                      </li>
                    </ul> -->
                  </li>
                </ng-container>
              </ul>

              <div class="no-access-tooltip-wrapper left-tool" *ngIf="menu?.restrictAccess" id="no-access-tooltip">
                <div class="left-pane">
                  <img src="./assets/common-icons/no-access-info.svg">
                </div>
                <div class="right-pane">
                  <div class="content">
                    <span>You don’t have access to this tab</span>
                    <p>To get access please contant your 
                      administrator.</p>
                  </div>
                  <img class="cursor-pointer" src="assets/Infra/blueprint/close.svg" (click)="menu.restrictAccess = false">
                </div>
              </div>

            </li>
          </ng-container>
    
      </ul>
    </div>



    <ng-container *ngIf="checkA3sAccess()">
      <div class="body-content background-grey" *ngIf="allowUrl('overview')">
        <div class="main ml-24 mr-24" >
            <app-breadcrumbs></app-breadcrumbs>
            <router-outlet></router-outlet>
        </div>
      </div>
      <div class="body-content background-grey" *ngIf="allowUrl('managePolicy')">
        <div class="main ml-24 mr-24" >
            <app-breadcrumbs></app-breadcrumbs>
            <router-outlet></router-outlet>
        </div>
      </div>
      <div class="body-content background-grey" *ngIf="allowUrl('reports')">
        <div class="main ml-24 mr-24" >
            <app-breadcrumbs></app-breadcrumbs>
            <router-outlet></router-outlet>
        </div>
      </div>
    </ng-container>

