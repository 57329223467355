<div class="introduction">
   
    <p class="heading" id="0">Login Steps</p>
    <p class="para">Start by logging in to A3S portal. </p>
    <ul>
         <li>URL to login to the portal is<a style="color: blue;" href="https://app.azureautomation.ai/"> https://app.azureautomation.ai/.</a> </li>
        <img src="assets/user-manual/login.svg" >
        <figcaption class="caption">Login Screen</figcaption>
         <li>Input your organization’s A3S id. For example, a3s@contoso.com.  </li>
        <li>Click <b>Log in.</b> (<b>Note:</b> A3S does not store any credentials, hence you need to login with your Microsoft Azure credentials).  </li>
        <li>On successful login, you will land on the A3S home/landing page.  </li>
        <li>In case of problems, click help icon.  </li>
    </ul>
</div>

