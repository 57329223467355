
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PublicClientApplication, AuthorizationUrlRequest, SilentRequest, AuthenticationResult, Configuration, LogLevel, AccountInfo, InteractionRequiredAuthError, EndSessionRequest, RedirectRequest, PopupRequest } from "@azure/msal-browser";
import { Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';

// import 'rxjs/add/operator/map';
// import 'rxjs/Rx';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { ProgressNotificationMessage } from "../@model/model";

interface myData {
    success: boolean,
    message: string,
    foldername: string
}

interface onDeployment {
    success: boolean,
    message: string
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': 's1ozaxk0ba8n387ic2fxbn30sdwz4617lrdv8mvn'
    })
};

const MonitoringHeaders = {
    headers: new HttpHeaders({
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImppYk5ia0ZTU2JteFBZck45Q0ZxUms0SzRndyIsImtpZCI6ImppYk5ia0ZTU2JteFBZck45Q0ZxUms0SzRndyJ9.eyJhdWQiOiJodHRwczovL21hbmFnZW1lbnQuY29yZS53aW5kb3dzLm5ldC8iLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9lNGUzNDAzOC1lYTFmLTQ4ODItYjZlOC1jY2Q3NzY0NTljYTAvIiwiaWF0IjoxNTk3OTE1MzgzLCJuYmYiOjE1OTc5MTUzODMsImV4cCI6MTU5NzkxOTI4MywiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhRQUFBQUZ2QmlhMU1DOEV1OXJPS3RoRXFRRytTbWxGM3NLKzN3SjhzemVneXNjeXBYREYrTzhralFRZU96NE5KNjFQVzgiLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiZTE0NTZkOWYtOGY1ZC00YjU2LThkM2EtN2QzNmM5YzdkMjhhIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJNYWhlc2h3YXJpIiwiZ2l2ZW5fbmFtZSI6Ik5pbGF5IiwiZ3JvdXBzIjpbImM3NDJhNTg2LTAyNzktNDc3NC05Yzk2LWQzODFjNzk5MDA2ZCIsImVhMjdmZDI0LTdjYzktNDJkMy1iODQ2LTczNTQwYTdiZDFkYSIsImUxMzg5ZGY2LTBhMmItNGYyMC05NGQ3LWU3YWE3YWRkZjAzMiIsIjMxNjAyMDMwLWI4ZGItNDlhMy1hNGM1LTIxOGUzZGE5YTY0MCJdLCJpcGFkZHIiOiIxMDYuMjA3LjcuMjAzIiwibmFtZSI6Ik5pbGF5IE1haGVzaHdhcmkiLCJvaWQiOiI3NmY1NmE5Yy01MjEwLTRhZmUtYjRiNC02NmRmN2QxMzU1MTgiLCJwdWlkIjoiMTAwMzIwMDA3MDNGNERDQyIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6IkZTWnpfUFFublVDd3pGZVdpTHRIZEZYZ2RJR0NuV0lMamtWMUlzV1RWdlUiLCJ0aWQiOiJlNGUzNDAzOC1lYTFmLTQ4ODItYjZlOC1jY2Q3NzY0NTljYTAiLCJ1bmlxdWVfbmFtZSI6Im5pbGF5Lm1haGVzaHdhcmlAY2VsZWJhbHRlY2guY29tIiwidXBuIjoibmlsYXkubWFoZXNod2FyaUBjZWxlYmFsdGVjaC5jb20iLCJ1dGkiOiJxWEg3bHFLd2RVeTNsNVBfNjdNdEFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyJjZjFjMzhlNS0zNjIxLTQwMDQtYTdjYi04Nzk2MjRkY2VkN2MiLCI5Yjg5NWQ5Mi0yY2QzLTQ0YzctOWQwMi1hNmFjMmQ1ZWE1YzMiXSwieG1zX3RjZHQiOjE0ODMxNzg4NjB9.SrS8KN12dIhCHZvdTqXOP3zRKDNWFHoE-izcJWIBr2ZAmSmbvM5S734rjDaFUZH9dbuNtkaNDbhZuevWumtpqkr7V4jmYdEwioSR7Zpwbdx6XcsOPmbbOC9coGpt2TnO482gIso7-t9oVyKctQG288GQUpq3NpJDxmPOonHj15s_UOxxkoU7uZkdABF34YV3mnegrfujSrXpC0FGEJjFfHe__QUh_gQhDn4bhSq1R7yFctV5VN3qz5KDKRBLdtFoxxJSP-ReMiTFxpEafi2FNQ8uwfy8FKzvQ8KwpNAgr2W1LPCniO_qER4CbrCiPwDUB9LMmBKYdysMwrQSf1fwOA'
    })
};

const bearerHeader = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
    }),
}


@Injectable({
    providedIn: 'root'
})

export class FlowService {
    public msalobj: PublicClientApplication;
    public myacc;
    public silentreq;
    preq;
    ie = new Subject<any>();
    customObservable = this.ie.asObservable();
    userId = '';
    AdminEmail = 'gg';
    // url = "https://a3s-backend-node.azurewebsites.net/";
    // url = "http://localhost:3000/"
    url = environment.serverUrl

    // private getToken() = `Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`;

    private get_email(): string {
        let email = "";
        for (let i = 0; sessionStorage.key(i); i++) {
            if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                email = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['username']
            }
        }
        return email;
    }

    getToken(){
        return `Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`;;
    }

    callComponentMethod(value: any) {
        this.ie.next(value);
    }

    onHome = false;
    constructor(public sanatizer: DomSanitizer, private http: HttpClient, private router: Router) {
        this.onHome = window.location.pathname == '/home' ? false : true
        if (localStorage.getItem('UserInfo') != null) {
            var local = JSON.parse(localStorage.getItem('UserInfo'));
            this.userId = local.email;
        }
    }
    homefn(params) {
        this.ie.next(params);
    }
    setID() {
        var local = JSON.parse(localStorage.getItem('UserInfo'));
        // console.log(local.userName,local.email);
        // message.Name=local.userName;

        this.userId = local.email;
    }

    sendresource(resourceinfo) {
        return this.http.post<myData>('https://bbc091a6.ngrok.io/resourcedata', resourceinfo);
    }

    // Add URL API used in Home Component
    addURL(data) {
        return this.http.post<any>(this.url + 'api/addURL', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Delete URL API used in Home Component
    deleteURL(data) {
        return this.http.post<any>(this.url + 'api/deleteURL', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Get URL API used in Home Component
    getURL() {
        // let param = new HttpParams().set("channel", payload);
        return this.http.get<any>(this.url + 'api/getURL', {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response',
        });
    }

    updateTags(data) {
        data.userId = this.userId;
        return this.http.post<any>(this.url + "api/crud-tags", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    deploycsv(fileinfo) {
        console.log('sending...', fileinfo)
        return this.http.post<onDeployment>('https://bbc091a6.ngrok.io/api/sendcsv',
            fileinfo)

    }

    getSchedules() {
        return this.http.get<any>(this.url + "api/getSchedulingData", {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    deleteSchedules(data, scope) {
        data.forEach(item => {
            this.deleteschedule(scope, item).subscribe(
                result => {
                    console.log(result)
                },
                err => {
                    console.log(err);
                }
            );

        })
        return true
    }
    deleteschedule(scope, schedule) {
        return this.http.delete<any>(this.url + "api/deleteData/scheduleName/" + schedule + '?scope=' + scope, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    logoutClearDb() {
        let email = this.get_email();
        return this.http.delete<any>(this.url + "api/logout", {
            headers: new HttpHeaders({
                id: email,
                'Authorization': this.getToken()
            })
        });
    }

    getProfilePhoto() {
        let email = this.get_email();
        return this.http.get<any>(this.url + "api/getProfilePhoto/" + email, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    getProfileInfo() {
        let email = this.get_email();
        return this.http.get<any>(this.url + "api/getUserInfo?mail=" + email, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    ApplyGlobalSchedules(payload) {
        return this.http.put<any>(this.url + "api/updateData/" + payload.key + "?scope=" + payload.scope, {
            headers: new HttpHeaders({
                'Authorization': this.getToken()
            }), responseType: 'text' as 'json'
        });
    }
    setSchedules(payload) {
        let username: any = '';
        for (let i = 0; sessionStorage.key(i); i++) {
            if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                username = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['username']
            }
        }
        let currpayload = {
            Name: payload.name,
            Description: payload.desc,
            'Stop Time': payload['stop'],
            'Start Time': payload['start'],
            Recurrence: payload.Recurrence,
            Timezone: payload.timezone
        }
        return this.http.post<any>(this.url + "api/insertSchedulingData", currpayload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'userid': username,
                'scope': payload.scope,
                'Authorization': this.getToken()
            }), responseType: 'text' as 'json'
        });
    }

    insertSchedule(payload, scope) {
        return this.http.post<any>(this.url + "api/applying-schedules", payload, {
            headers: new HttpHeaders({
                'userid': localStorage.getItem('email'),
                'scope': scope,
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }), responseType: 'text' as 'json'
        });
    }
    AddScheduleGlobally(payload) {
        return this.http.post<any>(this.url + "api/global-schedules", payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }), responseType: 'text' as 'json'
        });
    }
    postFile(fileToUpload: File): Observable<boolean> {

        // formData.append('fileKey', fileToUpload, fileToUpload.name);
        console.log(fileToUpload)
        return this.http.post<any>(this.url + "api/sendcsv", fileToUpload)
    }
    getBearerToken() {
        console.log("Bearer")
        return this.http.get<any>('https://login.microsoftonline.com/e4e34038-ea1f-4882-b6e8-ccd776459ca0/oauth2/token', bearerHeader)
    }
    generatescript(script) {
        //console.log(script)
        return this.http.post<myData>('https://817e5ee7.ngrok.io/api/fetchdata', script)
    }
    getappinsightdata() {
        return this.http.get<any>('https://api.applicationinsights.io/v1/apps/b50c34b7-4f65-49af-b8d3-af81acb7a9f3/query?query=requests | limit 2', httpOptions)
    }
    getProjectName() {
        //List of the Projects using tags
        return this.http.get<any>("https://management.azure.com/subscriptions/3406779e-e0b0-485a-8c7d-7812c9d1456d/resources?$filter=tagName eq 'Project' and tagValue eq 'WeGo'&api-version=2019-10-01", MonitoringHeaders)
    }
    getEnvironment(selectedEnvironment) {

        return this.http.get<any>("https://management.azure.com/subscriptions/3406779e-e0b0-485a-8c7d-7812c9d1456d/resources?$filter=tagName eq 'Environment' and tagValue eq '" + selectedEnvironment + "'&api-version=2019-10-01", MonitoringHeaders)
    }
    getInterface(selectedInterface) {
        return this.http.get<any>("https://management.azure.com/subscriptions/3406779e-e0b0-485a-8c7d-7812c9d1456d/resources?$filter=tagName eq 'Interface' and tagValue eq '" + selectedInterface + "'&api-version=2019-10-01", MonitoringHeaders)
    }
    getBuniessProcess(element) {
        return this.http.get<any>("https://management.azure.com/subscriptions/3406779e-e0b0-485a-8c7d-7812c9d1456d/resources?$filter=tagName eq 'BP' and tagValue eq '" + element + "'&api-version=2019-10-01", MonitoringHeaders)
    }
    getLiveStatus(Infranames) {
        return this.http.post<any>("https://bbc091a6.ngrok.io/api/status/", Infranames)
    }
    getAllStatus() {
        var data;
        data.userId = this.userId;
        return this.http.post<any>(this.url + "api/status/", data)
    }
    //etl APIs
    deploySA(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/fetchdata", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    getAllSetSchedules(data) {
        data.userId = this.userId;
        data.Email = this.get_email();
        return this.http.post<any>(this.url + "api/requestfromDB", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    DeleteAllschedules(data) {
        data.userId = this.userId;
        data.Email = this.get_email();
        return this.http.post<any>(this.url + "api/requestfromDB", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    getIntegration(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/runtimetoken", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    getStorage(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/strtoken", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    getADFStatus(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/adfstatus", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    checkSqlServer(data) {
        return this.http.post<any>(this.url + "api/sqlserveravailable", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    getStorageName(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/stravailable", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }


    deployExcel(data, subid) {
        //data.userId = this.userId
        return this.http.post<any>(this.url + "api/access-excel", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken(),
                'subscriptionid': subid
            })
        });
        // file: 
    }

    // getExcel(url:string,file: File):Observable<HttpEvent<any>>{
    //   let formData = new FormData();
    //   formData.append('upload', file);
    //   // return this.http.post<any>(this.url+"api/subscriptionlist");
    // }

    getAcessfromRefresh() {

    }
    getPolicylist() {
        return this.http.get<any>(this.url + "api/subscriptionlist");
    }

    getResourceGroupList(selectedsubscription) {
        // selectedsubscription.userId=this.userId;
        return this.http.post<any>(this.url + "api/RGList", selectedsubscription, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    getAgentList() {
        return this.http.get<any>(this.url + "api/agents-List");
    }

    createRG(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/createRG", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    checkRG(data) {
        //data.userId=this.userId;
        console.log("checkrgdemo", data)
        return this.http.post<any>(this.url + "api/RGList", {
            "subscriptionId": data.subscriptionID
        }, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    postNetworkData(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/network-components", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    postInfraData(data, flag) {
        //data.userId=this.userId;
        if (flag == 0)
            return this.http.post<any>(this.url + "api/infra-deployment", data, {
                headers: new HttpHeaders({
                    'id': localStorage.getItem('id'),
                    'Authorization': this.getToken()
                })
            });
        if (flag == 1)
            return this.http.post<any>(this.url + "api/iPaaS", data, {
                headers: new HttpHeaders({
                    'id': localStorage.getItem('id'),
                    'Authorization': this.getToken()
                })
            });
    }

    // APIM avability check

    apimData(data) {
        return this.http.post<any>(this.url + "api/ApimAvailability", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    elasticPool(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/elasticPoolAvailability", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    CosmosData(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/cosmosAvailability", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    AddPolicy(payload) {
        payload.userId = this.userId;
        return this.http.post<any>(this.url + "api/addPolicy", payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }
    getLogData(data) {
        data.Email = this.get_email();
        return this.http.post<any>(this.url + "api/requestfromDB", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    getSetSchedules(data) {
        data.Email = this.get_email();
        return this.http.post<any>(this.url + "api/requestfromDB", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    nameCheck(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/checkForTerm", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    virNameCheck(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/vnet-status", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    workspaceNameCheck(data): Observable<any> {
        return this.http.post<any>(this.url + "api/workspace", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    getvnet(data) {
        return this.http.post<any>(this.url + "api/getVnet", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    ipNameCheck(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/ipaddr-status", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    checkVnetStatus(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/vnetpeering-status", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    checkFirewallStatus(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/firewall-status", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    gatewayNameCheck(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/gateway-status", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    circuitNameCheck(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/expressRoute-status", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    s2sNameCheck(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/checkNetComponents", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    FuncNameCheck(data) {
        return this.http.post<any>(this.url + "api/sitesNameAvailabilty", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    orgNameCheck(data) {
        return this.http.post<any>(this.url + "api/validateOrganization", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    submitDevops(data) {
        return this.http.post<any>(this.url + "api/orchestration", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    deleteDevopsData(data) {
        return this.http.post<any>(this.url + "api/deleteDevOpsProject", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    checkdevopsStatus(process) {
        return this.http.get<any>(this.url + "api/getStatus?processId=" + process, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    appCreateCheck(data) {
        return this.http.post<any>(this.url + "api/siteAvailability", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    logicNameCheck(data) {
        return this.http.post<any>(this.url + "api/logicappAvailability", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    getResources(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/resources", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }
    getVM(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + "api/vm-list", data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });

    }
    getAllresourcedata(gettingID) {
        console.log(gettingID.SubscriptionList)
        const bearerTokenHeader = {
            headers: new HttpHeaders({
                'Authorization': this.getToken(),
            })
        };
        let obj = { 'SubscriptionList': gettingID.SubscriptionList }
        console.log(bearerTokenHeader);
        let data = this.http.post<any>(this.url + 'api/resource-list', obj, bearerTokenHeader)
        console.log(data);
        return data;
    }

    DeletePolicy(payload) {
        console.log(payload)
        payload.userId = this.userId;
        return this.http.delete<any>(this.url + 'api/delete-policy/' + payload.policyAssignmentName + '?scope=' + payload.scope, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
        })
    }

    private _notificationMessageSource = new Subject<string>();
    SourceNotification$ = this._notificationMessageSource.asObservable();

    private all_live_notifications: Array<ProgressNotificationMessage> = [];

    get_notification_if_exist(id): ProgressNotificationMessage {
        let _notification = null;
        for (let _i = 0; _i < this.all_live_notifications.length; _i++) {
            const el = this.all_live_notifications[_i];
            if (el._id == id) {
                _notification = el;
                break;
            }
        }
        if (!_notification)
            console.log("Notificaiton ", id, " not found!");
        return _notification;
    }

    // Notification 
    // !! this function is called when we share notification among components!!
    sendnotification(data) {
        // let date = new Date()
        var date_stamp = moment(Date.now()).format('YYYY-MM-DD');
        var time_stamp = moment(Date.now()).format('HH:mm:ss');
        // console.log("Email : ", localStorage.getItem('email'))
        // console.log("Name : ", JSON.parse(localStorage.getItem('UserInfo'))['userName'])
        let payload = {
            "Email": localStorage.getItem('email'),
            "Name": JSON.parse(localStorage.getItem('UserInfo'))['userName'],
            "MessageDate": date_stamp,
            "MessageTime": time_stamp,
            "Message": data['Message']
        }

        // console.log(payload)
        this._notificationMessageSource.next(data);
        return this.http.post<any>(this.url + 'api/sendNotification', payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        }).subscribe((res) => {
            console.log(res)
        });

        // this.notify({ text: message.Message }).subscribe((res) => {
        //   console.log(res);
        //   if (res) {
        //     this._notificationMessageSource.next(message);
        //   }
        // });
        // var local = JSON.parse(localStorage.getItem('UserInfo'));
        // var payloadtoSend = {
        //   "Operation":"SendActivityLog",
        //   "Email":local.email,
        //   "Name":local.userName.match(/\b(\w)/g),
        //   "Activity":message,
        //   "OU":this.OU
        // }

        // // if(!(message[status])){
        // this.submittodb(payloadtoSend).subscribe(result=>{
        // console.log('Successfully submitted to db!')
        // })

    }

    private _progressNotificationMessageSource = new Subject<ProgressNotificationMessage>();
    SourceProgrerssNotifications$ = this._progressNotificationMessageSource.asObservable();

    send_progress_notification(notification: ProgressNotificationMessage) {

        if (!notification.MessageDate)
            notification.MessageDate = moment(Date.now()).format('YYYY-MM-DD');
        if (!notification.MessageTime)
            notification.MessageTime = moment(Date.now()).format('HH:mm:ss');;

        if (!notification.is_navigation) {
            notification.is_navigation = false;
            notification.navigate_to = window.location.pathname;
        }

        if (!notification.saved_in_backend) notification.saved_in_backend = false;
        if (!notification.is_error_occurred) {
            notification.error_message = "";
            notification.is_error_occurred = false;
        }

        let payload = {
            ...notification,
            jobs_complete: notification.progress.jobs_complete,
            total_jobs: notification.progress.total_jobs,
            "Email": localStorage.getItem('email'),
            "Name": JSON.parse(localStorage.getItem('UserInfo'))['userName'],
            "MessageDate": moment(Date.now()).format('YYYY-MM-DD'),
            "MessageTime": moment(Date.now()).format('HH:mm:ss'),
        }

        delete payload.progress;

        // updating the local copy!
        let _index = this.all_live_notifications.findIndex((obj => obj._id == notification._id));
        if (_index >= 0)
            this.all_live_notifications[_index] = notification;
        else this.all_live_notifications.push(notification);

        _index = this.all_live_notifications.findIndex((obj) => obj._id == notification._id);
        // console.log("All live notifications");
        // console.log(this.all_live_notifications);

        // notification shared to all components
        this._progressNotificationMessageSource.next(notification);

        // saving notification to backend
        // !! route is yet to be confirmed!!

        if (this.all_live_notifications && !this.all_live_notifications[_index].saved_in_backend) {
            return this.http.post<any>(this.url + 'api/sendNotification', payload, {
                headers: new HttpHeaders({
                    'id': localStorage.getItem('id'),
                    'Authorization': this.getToken()
                })
            }).subscribe(res => { this.all_live_notifications[_index].saved_in_backend = true; },
                err => { this.all_live_notifications[_index].saved_in_backend = true; }); // update it to false!!
        }
    }

    // !! what is the purpose of this function??
    sendnotification1(message) {
        message.status = "show";
        this._notificationMessageSource.next(message)
    }

    notify(message) {
        message.Email = this.get_email();
        // console.log(message);
        var local = JSON.parse(localStorage.getItem('UserInfo'));
        message.Name = local.userName;
        message.Operation = "SendActivityLog";
        message.userId = this.userId;
        return this.http.post<any>(this.url + 'api/Notify', message, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    iframeSRC: SafeResourceUrl = '';
    showIframe = false;

    async ShowChatbot() {
        let email = this.get_email();
        await this.getAgentList().subscribe((agentList)=> {
            if (email != '') {
                if(agentList && agentList.value && agentList.value.find((dt) => dt.Email === email)) {
                    this.iframeSRC = this.sanatizer.bypassSecurityTrustResourceUrl("https://webchat.botframework.com/embed/a3ssupportchatbot/gemini?b=a3ssupportchatbot&s=s7CGLLVv2_k.tzisJaYURjd3VjlZJGZJwhz_g_SUleE_IfDNUkx62bw&username=" + email);
                } else {
                    var local = JSON.parse(localStorage.getItem('UserInfo'));
                    this.iframeSRC = this.sanatizer.bypassSecurityTrustResourceUrl("https://webchat.botframework.com/embed/a3ssupportchatbot/gemini?b=a3ssupportchatbot&s=s7CGLLVv2_k.tzisJaYURjd3VjlZJGZJwhz_g_SUleE_IfDNUkx62bw&username=" + local.userName);
                }
            }
            this.showIframe = true;
        });
    }

    getDataFromDB(data) {
        data.Email = this.get_email();
        return this.http.post<any>(this.url + 'api/requestfromDB', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    getAdmin(payload) {
        payload.userId = this.userId;
        payload.Email = this.get_email();
        return this.http.post<any>(this.url + "api/requestfromDB", payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    MonitoringOperation(payload) {
        payload.userId = this.userId;
        return this.http.post<any>(this.url + 'api/Monitoring', payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    //Check User API for Global and Guest present in Application and Health Monitoring 
    checkUser() {
        return this.http.get<any>(this.url + 'api/checkUser', {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    // Add User API used in Application and Health Monitoring
    addUsers(data) {
        return this.http.post<any>(this.url + 'api/addUser', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Add Project API used in Application and Health Monitoring
    addProject(data) {
        return this.http.post<any>(this.url + 'api/addProject', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Delete Project API used in Application and Health Monitoring
    deleteProject(data) {
        return this.http.post<any>(this.url + 'api/deleteProject', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Delete User API used in Application and Health Monitoring
    deleteUsers(data) {
        return this.http.post<any>(this.url + 'api/deleteUser', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Get Users List API used in Application and Health Monitoring
    getUsersList() {
        return this.http.get<any>(this.url + 'api/getUser', {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Add Resources API used in Application and Health Monitoring
    addResources(data) {
        return this.http.post<any>(this.url + 'api/addResource', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Delte Resources API used in Application and Health Monitoring
    deleteResources(data) {
        return this.http.post<any>(this.url + 'api/deleteResource', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Get Project API used in Application and Health Monitoring
    getProjectList() {
        return this.http.get<any>(this.url + 'api/getProject', {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Add Project User API used in Application and Health Monitoring
    addProjectUser(payload) {
        return this.http.post<any>(this.url + 'api/addProjectUser', payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Add Resource User API used in Application and Health Monitoring
    addResourceUser(payload) {
        return this.http.post<any>(this.url + 'api/addResourceUser', payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    // Cosmos DB namecheck API used in Infra component
    cosmosNameCheck(data) {
        return this.http.post<any>(this.url + 'api/checkForTerm', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }

    //IAM APIs Starts
    postUserData(payload) {
        payload.userId = this.userId;
        return this.http.post<any>(this.url + 'api/iam-eachuser', payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    userData() {
        //payload.userId=this.userId;
        return this.http.get<any>(this.url + 'api/iam-userslist', {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    userDatafilter(payload) {
        let param = new HttpParams().set("value", payload.value);
        payload.userId = this.userId;
        return this.http.get<any>(this.url + 'api/iam-filteredUsers', {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
            , params: param
        });
    }

    deleteUser(payload) {
        payload.userId = this.userId;
        return this.http.delete<any>(this.url + 'api/iam-removeassignment/' + payload.roleAssignmentName + '?' + 'scope=' + payload.scope, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            // params: payload.roleAssignmentName  
        });
    }

    addUser(payload) {
        payload.userId = this.userId;
        return this.http.post<any>(this.url + 'api/iam-addassignment', payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    //IAM APIs Ends
    deployDevops(payload) {
        //payload.userId=this.userId;
        console.log(payload)
        return this.http.post<any>(this.url + 'api/devops-automation', payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    ValidateInput(Term) {
        //Term.userId=this.userId;
        return this.http.post<any>(this.url + 'api/checkForTerm', Term, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    ValidateAKS(Term) {
        //Term.userId=this.userId;
        return this.http.post<any>(this.url + 'api/AKS-status', Term, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    ValidateASF(Term) {
        //Term.userId=this.userId;
        return this.http.post<any>(this.url + 'api/ASF-status', Term, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    submittodb(data) {
        data.Email = this.get_email();
        delete data.timestamp
        delete data.OU
        // console.log(data)
        return this.http.post<any>(this.url + 'api/inserttodb', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    getMetricAlert(data) {
        // console.log(data)
        // console.log(this.url + 'api/metric-alerts?scope=' + data.scope + '&aggregation=' + data.aggregation + '&' + data.metricName + 'interval=' + data.interval + '&timespan=' + data.timespan)
        const bearerTokenHeader = {
            headers: new HttpHeaders({
                'Authorization': this.getToken(),
            })
        };
        return this.http.get<any>(this.url + 'api/metric-alerts?scope=' + data.scope + '&aggregation=' + data.aggregation + '&' + data.metricName + 'interval=' + data.interval + '&timespan=' + data.timespan, bearerTokenHeader)
    }

    // ---------BCD----------
    getVault(Term) {
        //Term.userId=this.userId;
        return this.http.post<any>(this.url + 'api/existing-vaultlist', Term, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    RSV(Term) {
        //Term.userId=this.userId;
        return this.http.post<any>(this.url + 'api/recovery-vault', Term, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    SQLRet(Term) {
        //Term.userId=this.userId;
        return this.http.post<any>(this.url + 'api/retention-policies', Term, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    SQLDB(Term) {
        //Term.userId=this.userId;
        return this.http.post<any>(this.url + 'api/db-backup', Term, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    vmBackup(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + 'api/vm-backup', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    vmBackupEnable(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + 'api/enable-backup', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    vmReplication(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + 'api/replicate-VM', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })

    }

    SQLDbImport(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + 'api/import-dbBackup', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    checkhealthStatus(data) {
        // data.userId=this.userId;
        console.log(data)
        const bearerTokenHeader = {
            headers: new HttpHeaders({

                'Authorization': this.getToken(),
            })
        };
        return this.http.post<any>(this.url + 'api/getstatestatus', data, bearerTokenHeader)
    }

    sqlVM(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + 'api/sqlonvm-Recovery', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    checkSQL(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + 'api/sqlonvm-status', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    GetRGInfo(data) {
        //data.userId=this.userId;
        return this.http.post<any>(this.url + 'api/appliedPolicyList', data, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        })
    }

    getSavedDataFromDB(payload) {
        payload.userId = this.userId;
        payload.Email = this.get_email();
        return this.http.post<any>(this.url + "api/requestfromDB", payload, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            })
        });
    }

    //cost bucket
    getCostData(payload) {
        payload.userId = this.userId;
        return this.http.post<any>(this.url + "api/consumption-forecast", payload);
    }

    getGraphData(payload) {
        payload.userId = this.userId;
        return this.http.post<any>(this.url + "api/consumption-forecast", payload);
    }

    getBarRGData(payload) {
        payload.userId = this.userId;
        return this.http.post<any>(this.url + "api/consumption-query", payload);
    }

    getBarResData(payload) {
        payload.userId = this.userId;
        return this.http.post<any>(this.url + "api/consumption-query", payload);
    }

    getAzureToken(payload) {
        return new Promise((resolve, reject) => {
            this.http.post(this.url + 'api/token-PowerBI', payload, {
                headers: new HttpHeaders({
                    'Authorization': this.getToken(),
                })
            }).subscribe(s => { resolve(s); });
        });
    }

    get_audit_logs(payload) {
        return this.http.get<any>(this.url + 'api/getRoleAssignments/' + payload.subscriptionId, {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
            observe: 'response'
        });
    }



    // ----------------------------------- DEVOPS STARTER -----------------------------------

    getdevopsprojectlist() {
        return this.http.get<any>(this.url + 'api/getDevOpsProject', {
            headers: new HttpHeaders({
                'id': localStorage.getItem('id'),
                'Authorization': this.getToken()
            }),
        });
    }

    // ----------------------------------- DEVOPS STARTER -----------------------------------


}




// endpoint --------------------




// /api/expressRoute-status




// payload----------


// {
//             "userId":"nilay.maheshwari@celebaltech.com",
//             "subscriptionId":"01ce3075-23cc-4058-9df8-b6888684a736",
//             "resourceGroupName":"Celebal_shubham",
//             "circuitName":"",
// }



// endpoint --------------------




// /




// payload----------


// {
//             "userId":"nilay.maheshwari@celebaltech.com",
//             "subscriptionId":"01ce3075-23cc-4058-9df8-b6888684a736",
//             "resourceGroupName":"Celebal_shubham",
//             "virtualNetworkGatewayConnectionName":"",
// }

