
export class userManualTopics{
    public static content=[{id:1,value:'Introduction',activeSidebar:'Introduction',Title:'',type:'parent'},
                    {id:2,value:'Intended Audience',activeSidebar:'Intended-audience',Title:'',type:'parent'},
                    {id:3,value:'Overview',activeSidebar:'AI-powered-AI-Overview',Title:'AI-powered Azure Automation as a Service',type:'child'},
                    {id:4,value:'Key Capabilities',activeSidebar:'AI-powered-Key-Capabilities',Title:'AI-powered Azure Automation as a Service',type:'child'},
                    {id:5,value:'A3S Functional-Buckets',activeSidebar:'A3S-Functional-Buckets',Title:'',type:'parent'},
                    {id:6,value:'Functional Overview',activeSidebar:'Functional-Overview',Title:'',type:'parent'},
                    {id:7,value:'Concept of Project',activeSidebar:'A3S-Project-Concept-of-Project',Title:'A3S Project',type:'child'},
                    {id:8,value:'User Scenarios',activeSidebar:'A3S-Project-User-Scenarios',Title:'A3S Project',type:'child'},
                    {id:9,value:'Concept of Filters',activeSidebar:'A3S-CFilters',Title:'A3S Filtres',type:'child'},
                  
                    {id:10,value:'Login Steps',activeSidebar:'A3S-LoginSteps',Title:'A3S Login',type:'child'},
                    {id:900,value:'Overview',activeSidebar:'HP-Overview',Title:'Home Page',type:'child'},
                    {id:901,value:'Banner & Task Bar',activeSidebar:'HP-BTB',Title:'Home Page',type:'child'},
                    {id:902,value:'Hamburger Menu',activeSidebar:'HP-Hamburger',Title:'Home Page',type:'child'},
                    {id:903,value:'Logo & Name',activeSidebar:'HP-Logo',Title:'Home Page',type:'child'},
                    {id:904,value:'Search Bar',activeSidebar:'HP-Search',Title:'Home Page',type:'child'},
                    {id:905,value:'Chat ',activeSidebar:'HP-Chat',Title:'Home Page',type:'child'},
                    {id:906,value:'Notifications',activeSidebar:'HP-Notifications',Title:'Home Page',type:'child'},
                    {id:907,value:'Knowledge Center',activeSidebar:'HP-KC',Title:'Home Page',type:'child'},
                    {id:908,value:'User',activeSidebar:'HP-User',Title:'Home Page',type:'child'},
                    {id:909,value:'Log out',activeSidebar:'HP-Log-out',Title:'Home Page',type:'child'},
                    {id:910,value:'Connect Apps',activeSidebar:'HP-Connect',Title:'Home Page',type:'child'},
                    {id:911,value:'Newsletter',activeSidebar:'HP-Newsletter',Title:'Home Page',type:'child'},
                    {id:912,value:'Delete organization account',activeSidebar:'HP-Delete',Title:'Home Page',type:'child'},
                    {id:913,value:'Tiles ',activeSidebar:'HP-Tiles',Title:'Home Page',type:'child'},
                    {id:914,value:'Recent Activity',activeSidebar:'HP-Recent',Title:'Home Page',type:'child'},
                    {id:915,value:'Favorites',activeSidebar:'HP-Favorites',Title:'Home Page',type:'child'},

                    {id:1000,value:'Architecture Blueprints',activeSidebar:'Apps-Infrastructure-Archietecture-Blueprints',Title:'Apps & Infrastructure',type:'child'},
                    {id:10000,value:'Create Project',activeSidebar:'Apps-Infrastructure-Archietecture-Blueprints-Create',Title:'Apps & Infrastructure',type:'subChild'},
                    {id:10001,value:'Delete Project',activeSidebar:'Apps-Infrastructure-Archietecture-Blueprints-Delete',Title:'Apps & Infrastructure',type:'subChild'},
                    {id:1001,value:'Design Tool Components',activeSidebar:'Apps-Infrastructure-Design-Tool-Components',Title:'Apps & Infrastructure',type:'child'},
                    {id:1002,value:'Using Architecture Design Tool',activeSidebar:'Apps-Infrastructure-Using-Architecture-Design-Tool',Title:'Apps & Infrastructure',type:'child'},
                    {id:1003,value:'Project Status',activeSidebar:'Apps-Infrastructure-Project-Status',Title:'Apps & Infrastructure',type:'child'},
                    {id:10030,value:'In Review',activeSidebar:'Apps-Infrastructure-Project-Status-In-Review',Title:'Apps & Infrastructure',type:'subChild'},
                    {id:10031,value:'Approved',activeSidebar:'Apps-Infrastructure-Project-Status-Approved',Title:'Apps & Infrastructure',type:'subChild'},
                    {id:10032,value:'Deployed',activeSidebar:'Apps-Infrastructure-Project-Status-Deployed',Title:'Apps & Infrastructure',type:'subChild'},
                    {id:10033,value:'Failed',activeSidebar:'Apps-Infrastructure-Project-Status-Failed',Title:'Apps & Infrastructure',type:'subChild'},
                    {id:1004,value:'Infrastructure Management',activeSidebar:'Apps-Infrastructure-Infrastructure-Management',Title:'Apps & Infrastructure',type:'child'},
                    {id:10040,value:'Overview',activeSidebar:'Apps-Infrastructure-IM-Overview',Title:'Apps & Infrastructure',type:'subChild'},
                    {id:10041,value:'Resource Management',activeSidebar:'Apps-Infrastructure-IM-RM',Title:'Apps & Infrastructure',type:'subChild'},
                    
                    {id:1100,value:'Data Migration',activeSidebar:'Data-AI-Data-Migration',Title:'Data & AI',type:'child'},
                    {id:11000,value:'Overview',activeSidebar:'Apps-Infrastructure-DM-Overview',Title:'Data & AI',type:'subChild'},
                    {id:11001,value:'SQL Server',activeSidebar:'Apps-Infrastructure-DM-SQL',Title:'Data & AI',type:'subChild'},
                    {id:11002,value:'Oracle Server',activeSidebar:'Apps-Infrastructure-DM-Oracle',Title:'Data & AI',type:'subChild'},
                    {id:11003,value:'SAP Hana',activeSidebar:'Apps-Infrastructure-DM-SAP-Hana',Title:'Data & AI',type:'subChild'},
                    {id:11004,value:'SAP ECC with Full & Delta Load',activeSidebar:'Apps-Infrastructure-DM-SAP-ECC',Title:'Data & AI',type:'subChild'},
                    {id:11005,value:'Azure Blob Storage',activeSidebar:'Apps-Infrastructure-DM-ABS',Title:'Data & AI',type:'subChild'},
                    {id:1101,value:'Data Governance',activeSidebar:'Data-AI-Data-Governance',Title:'Data & AI',type:'child'},
                    {id:11005,value:'Data Estate Scan',activeSidebar:'Data-AI-Data-Gov',Title:'Data & AI',type:'subChild'},
                    {id:1102,value:'Data Analytics',activeSidebar:'Data-AI-Data-Analytics',Title:'Data & AI',type:'child'},
                    {id:11020,value:'Demand Forecasting',activeSidebar:'Apps-Infrastructure-DA-DF',Title:'Data & AI',type:'subChild'},
                    {id:11021,value:'Form Recongnition',activeSidebar:'Apps-Infrastructure-DA-FR',Title:'Data & AI',type:'subChild'},
                   {id:11022,value:'Sales Predictions',activeSidebar:'Apps-Infrastructure-DA-SP',Title:'Data & AI',type:'subChild'},
                   {id:11023,value:'Revenue Prediction',activeSidebar:'Apps-Infrastructure-DA-RP',Title:'Data & AI',type:'subChild'},
                   {id:11024,value:'Product Seasonality Classifier',activeSidebar:'Apps-Infrastructure-DA-PS',Title:'Data & AI',type:'subChild'},
                   {id:11025,value:'Knowledge Mining',activeSidebar:'Apps-Infrastructure-DA-KM',Title:'Data & AI',type:'subChild'},
                   {id:11026,value:'Modern Finance',activeSidebar:'Apps-Infrastructure-DM-MF',Title:'Data & AI',type:'subChild'},
                   {id:1103,value:'DataOps',activeSidebar:'Data-AI-DataOps',Title:'Data & AI',type:'child'},

                   {id:1200,value:'Overview',activeSidebar:'Infrastructure-Health-Monitoring-Overview',Title:'Infrastructure Health Monitoring',type:'child'},
                   {id:1201,value:'Metrics',activeSidebar:'Infrastructure-Health-Monitoring-Metrics',Title:'Infrastructure Health Monitoring',type:'child'},
                  {id:1202,value:'Insights',activeSidebar:'Infrastructure-Health-Monitoring-Insights',Title:'Infrastructure Health Monitoring',type:'child'},
                    {id:12020,value:'Queries',activeSidebar:'IHM-insights-query',Title:'Infrastructure Health Monitoring',type:'subChild'},
                   {id:12021,value:'Custom Query',activeSidebar:'IHM-insights-custom-query',Title:'Infrastructure Health Monitoring',type:'subChild'},
                  {id:1203,value:'Alerts',activeSidebar:'Infrastructure-Health-Monitoring-Alerts',Title:'Infrastructure Health Monitoring',type:'child'},
                  {id:12030,value:'Create New Action Group',activeSidebar:'IHM-alerts-newAction',Title:'Infrastructure Health Monitoring',type:'subChild'},
                  {id:12031,value:'Create New Alert',activeSidebar:'IHM-alerts-custom-New-Alert',Title:'Infrastructure Health Monitoring',type:'subChild'},

                 {id:1300,value:'Overview',activeSidebar:'Application-Health-Monitoring-Overview',Title:'Application Health Monitoring',type:'child'},
                 {id:1301,value:'Metrics',activeSidebar:'Application-Health-Monitoring-metrics',Title:'Application Health Monitoring',type:'child'},
                {id:13010,value:'Availability',activeSidebar:'AHM-Availability',Title:'Application Health Monitoring',type:'subChild'},
                {id:13011,value:'Average CPU and Average Memory Usage',activeSidebar:'AHM-Average',Title:'Application Health Monitoring',type:'subChild'},
                {id:13012,value:'Server Requests',activeSidebar:'AHM-SQ',Title:'Application Health Monitoring',type:'subChild'},
                {id:13013,value:'Response Time',activeSidebar:'AHM-RT',Title:'Application Health Monitoring',type:'subChild'},
                {id:13014,value:'Server Exceptions and Browser Exceptions',activeSidebar:'AHM-Exception',Title:'Application Health Monitoring',type:'subChild'},
                {id:13015,value:'Events',activeSidebar:'AHM-Events',Title:'Application Health Monitoring',type:'subChild'},
                {id:13016,value:'Page Views',activeSidebar:'AHM-page-views',Title:'Application Health Monitoring',type:'subChild'},
                    
                {id:1302,value:'Usage',activeSidebar:'Application-Health-Monitoring-Usage',Title:'Application Health Monitoring',type:'child'},
                {id:13020,value:'Users By Country',activeSidebar:'AHM-Users',Title:'Application Health Monitoring',type:'subChild'},
                 {id:13021,value:'Sessions',activeSidebar:'AHM-Sessions',Title:'Application Health Monitoring',type:'subChild'},
                {id:13022,value:'Events',activeSidebar:'AHM-usageEvents',Title:'Application Health Monitoring',type:'subChild'},
                {id:13023,value:'Overall retention',activeSidebar:'AHM-Overall',Title:'Application Health Monitoring',type:'subChild'},
                {id:13024,value:'Users Returned After # of Weeks',activeSidebar:'AHM-Users-returned',Title:'Application Health Monitoring',type:'subChild'},
                {id:13025,value:'Initiating Events and Returning Events',activeSidebar:'AHM-return-Events',Title:'Application Health Monitoring',type:'subChild'},
                     
                {id:1303,value:'Alerts',activeSidebar:'Application-Health-Monitoring-Alerts',Title:'Application Health Monitoring',type:'child'},
                {id:13030,value:'Create New Action Group',activeSidebar:'AHM-alerts-newAction',Title:'Application Health Monitoring',type:'subChild'},
                {id:13031,value:'Create New Alert',activeSidebar:'AHM-alerts-custom-New-Alert',Title:'Application Health Monitoring',type:'subChild'},

                
                {id:1400,value:'Overview',activeSidebar:'Cost-Monitoring-Optimization-Overview',Title:'Cost Monitoring & Optimization',type:'child'},
                {id:1401,value:'Cost Analysis',activeSidebar:'Cost-Monitoring-Optimization-Cost-Analysis',Title:'Cost Monitoring & Optimization',type:'child'},
                {id:1402,value:'Optimize',activeSidebar:'Cost-Monitoring-Optimization-Optimize',Title:'Cost Monitoring & Optimization',type:'child'},
                {id:1403,value:'Budget & Alerts',activeSidebar:'Cost-Monitoring-Optimization-Budget-Alerts',Title:'Cost Monitoring & Optimization',type:'child'},
                {id:14030,value:'Create Budget and Set Alerts',activeSidebar:'CM-alerts',Title:'Cost Monitoring & Optimization',type:'subChild'},
                {id:1404,value:'Billing',activeSidebar:'Cost-Monitoring-Optimization-Billing',Title:'Cost Monitoring & Optimization',type:'child'},
                {id:14040,value:'Project Cost',activeSidebar:'CM-cost',Title:'Cost Monitoring & Optimization',type:'subChild'},
                {id:14041,value:'Invoices',activeSidebar:'CM-Invoices',Title:'Cost Monitoring & Optimization',type:'subChild'},
                {id:14042,value:'Reservation Transcations',activeSidebar:'CM-RT',Title:'Cost Monitoring & Optimization',type:'subChild'},
                {id:14043,value:'Billing Profiles',activeSidebar:'CM-BP',Title:'Cost Monitoring & Optimization',type:'subChild'},

                   
                {id:1500,value:'Overview',activeSidebar:'Governance-Overview',Title:'Governance',type:'child'},
                {id:1501,value:'Enterprise Policies',activeSidebar:'Governance-Enterprise-Policies',Title:'Governance',type:'child'},
                {id:15010,value:'Assign Policy',activeSidebar:'Gov-policy',Title:'Governance',type:'subChild'},
                {id:15011,value:'Assign Initiatives',activeSidebar:'Gov-Initiate',Title:'Governance',type:'subChild'},
                {id:1502,value:'Identity and Access Management',activeSidebar:'Governance-Identity-and-Access-Management',Title:'Governance',type:'child'},
                {id:15020,value:'Overview',activeSidebar:'IAM-Overview',Title:'Governance',type:'subChild'},
                {id:15021,value:'Add User',activeSidebar:'IAM-addUser',Title:'Governance',type:'subChild'},
                {id:15022,value:'Invite a User',activeSidebar:'IAM-Invite',Title:'Governance',type:'subChild'},
                {id:15023,value:'Add Role Assignment',activeSidebar:'IAM-addRole',Title:'Governance',type:'subChild'},
                {id:15024,value:'User Management',activeSidebar:'IAM-UM',Title:'Governance',type:'subChild'},
                {id:15025,value:'Add Group',activeSidebar:'IAM-addGroup',Title:'Governance',type:'subChild'},
                {id:15026,value:'Group Management',activeSidebar:'IAM-GM',Title:'Governance',type:'subChild'},

                {id:1503,value:'Auditing and Logging',activeSidebar:'Governance-Auditing-and-Logging',Title:'Governance',type:'child'},
                {id:15030,value:'Activity Logs',activeSidebar:'Gov-Activity',Title:'Governance',type:'subChild'},
                {id:15031,value:'Sign-Ins',activeSidebar:'Gov-Sign-Ins',Title:'Governance',type:'subChild'},
                {id:15032,value:'Audit Logs',activeSidebar:'Gov-Audit',Title:'Governance',type:'subChild'},

               
                {id:1600,value:'Overview',activeSidebar:'Security-Overview',Title:'Security',type:'child'},
                {id:16000,value:'Alerts',activeSidebar:'Sec-alerts',Title:'Security',type:'subChild'},
                {id:16001,value:'Overall Secure Score',activeSidebar:'Sec-overall',Title:'Security',type:'subChild'},
                {id:16002,value:'Coverge',activeSidebar:'Sec-coverage',Title:'Security',type:'subChild'},
                {id:16003,value:'Active Recommendations',activeSidebar:'Sec-active',Title:'Security',type:'subChild'},

                {id:1601,value:'Security Posture',activeSidebar:'Security-Security-Posture',Title:'Security',type:'child'},
                {id:16010,value:'Identity Security',activeSidebar:'Sec-IS',Title:'Security',type:'subChild'},
                {id:16011,value:'Endpoint Protection',activeSidebar:'Sec-EP',Title:'Security',type:'subChild'},
                {id:16012,value:'Activities',activeSidebar:'Sec-activities',Title:'Security',type:'subChild'},
                {id:16013,value:'Security Recommendations',activeSidebar:'Sec-Recomm',Title:'Security',type:'subChild'},

                {id:1602,value:'Alerts',activeSidebar:'Security-Alerts',Title:'Security',type:'child'},

                 
                {id:1700,value:'Overview',activeSidebar:'Compliance-Overview',Title:'Compliance',type:'child'},
                {id:17001,value:'Compliance Benchmark',activeSidebar:'Comp-benchmark',Title:'Compliance',type:'subChild'},
                {id:17002,value:'Compliance Status',activeSidebar:'Comp-status',Title:'Compliance',type:'subChild'},
                {id:17003,value:'List of Standards',activeSidebar:'Comp-list',Title:'Compliance',type:'subChild'},
                {id:17004,value:'Deploy Additional Standards',activeSidebar:'Comp-DAS',Title:'Compliance',type:'subChild'},

                {id:1701,value:'Manage Policy',activeSidebar:'Compliance-Manage-Policy',Title:'Compliance',type:'child'},
                {id:17010,value:'Default A3S Security Center policy',activeSidebar:'Comp-default',Title:'Compliance',type:'subChild'},
                {id:17011,value:'Manage Policy',activeSidebar:'Comp-MP',Title:'Compliance',type:'subChild'},

                {id:1702,value:'Industry and Regulatory Standards',activeSidebar:'Compliance-Industry-and-Regulatory-Standards',Title:'Compliance',type:'child'},
                {id:17020,value:'Add Standards',activeSidebar:'Comp-IRS',Title:'Compliance',type:'subChild'},

                {id:1703,value:'Reports',activeSidebar:'Compliance-Reports',Title:'Compliance',type:'child'},


                {id:18,value:'A3S Support',activeSidebar:'A3S-Support',Title:'',type:'parent'},
                {id:19,value:'Azure Support',activeSidebar:'Azure-Support',Title:'',type:'parent'},
                {id:20,value:'SAP Support',activeSidebar:'SAP-Support',Title:'',type:'parent'},]


                public static projectStatus=[ {value:'Review',activeSidebar:'Apps-Infrastructure-Project-Status-In-Review',Title:'Apps & Infrastructure',type:'subChild'},
                {value:'Approve',activeSidebar:'Apps-Infrastructure-Project-Status-Approved',Title:'Apps & Infrastructure',type:'subChild'},
                {value:'Deploy',activeSidebar:'Apps-Infrastructure-Project-Status-Deployed',Title:'Apps & Infrastructure',type:'subChild'}]

// public static filterContent=[{id:1,value:'Introduction',Title:''},
//         {id:2,value:'Intended Audience',Title:''},
//         {id:1,value:'Overview',Title:'AI-powered Azure Automation as a Service'},
//         {id:1,value:'Key Capabilities',Title:'AI-powered Azure Automation as a Service'},
//         {id:1,value:'A3S Functional-Buckets',Title:''},
//         {id:1,value:'Functional Overview',Title:''},
//         {id:1,value:'Concept of Project',Title:'A3S Project'},
//         {id:1,value:'User Scenarios',Title:'A3S Project'},
//         {id:1,value:'Concept of Filters',Title:'A3S Filtres'},
//         {id:1,value:'Introduction',Title:'A3S Login'},
//         {id:1,value:'Introduction',Title:'Home Page'},
//         {id:1,value:'Introduction',Title:'Home Page'},
//         {id:1,value:'Introduction',Title:'Home Page'},
//         {id:1,value:'Introduction',Title:'Home Page'},
//         {id:1,value:'Introduction',Title:'Home Page'},
//         {id:1,value:'Introduction',Title:'Home Page'},



// ]

}
