import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-compliance-bucket',
  templateUrl: './compliance-bucket.component.html',
  styleUrls: ['./compliance-bucket.component.scss']
})
export class ComplianceBucketComponent implements OnInit {
  complianceMenu: any;
  projectList: any = [];
  currentProject: any;
  currentResourceType: any;
  currentProjectData: any;
  currentUrl: any;
  currrentTab: any;

  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    var $target = $(event.target);
    this.complianceMenu.forEach(e=> {
      let element = document.getElementById(e.menu)
      if(!$target.closest(element).length && !$target.closest("#no-access-tooltip").length){
         e.restrictAccess = false
      }
    })
  }

  constructor(private util:UtilService,private router: Router) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event) => {
        this.currentUrl = event['urlAfterRedirects']
    })
    if(!this.currentUrl) this.currentUrl = this.router.url
   }

  ngOnInit(): void {
    this.complianceMenu = this.util.getStatic('complianceMenus');
    console.log(this.complianceMenu);
    this.getBlueprintProject()
  }

  triggerMenuClick(menu){
    this.currrentTab = menu;
    let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
    if(data && data.scope.some(x=> x.id == menu.tabId)) menu['restrictAccess'] = false
    else return menu['restrictAccess'] = true;
    this.closeOthers(menu.menu)
    if(this.allowSubMenus(menu)) {
      this.subMenuOpen(menu.menu)
      this.setCurrentProject(this.projectList[0],0)
    }
  }
  
  async getBlueprintProject(){
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    var createdBy = get_Email.userName;
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){}
    }
    let userProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getAllProjects',[createdBy,userObjectId])
    let orgProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getOrgProjects',[localStorage.getItem('id')])
    let list = [...userProject,...orgProject]
    this.projectList = list.filter(e=> e.subscriptionId && e.status == 'DEPLOYED')
    this.triggerMenuClick(this.complianceMenu[0])
    console.log('Filter Project List',this.projectList)
  }

   getProjectResource(id){
    return this.util.handleRequest('get','a3s_architectureBlueprint_getProjectResourceListForDashboards',[id],null,null,null,true)
   }

  async setCurrentProject(list,index){
    list['currentResourceType'] = null
    this.currentResourceType = null;
    this.currentProject = list.projectName
    if(!list.isManual || (list.isManual && list.isSpecificResource)){
      var resourcesArray = await this.getProjectResource(list.projectId)
      var resourceGroupsArray = []
      resourcesArray.forEach(e=>{
        let rg = JSON.parse(e.resourceData)
        e['resourceGroupName'] = rg.hasOwnProperty('resourceGroup') ? rg.resourceGroup : null
        e['projectId'] = list.projectId
        e['subscriptionId'] = list.subscriptionId
        rg.hasOwnProperty('resourceGroup') ? resourceGroupsArray.push({resourceGroupName:rg.resourceGroup,projectId:list.projectId,resourceType:e.resourceType}) : null
      })
      var uniqueRG = this.util.unique(resourceGroupsArray,['resourceGroupName'])
      list['resourceGroups'] = uniqueRG
      list['resources'] = resourcesArray
      // Get Unique Resource Types and the resource counts
      var uniqueResourceType = this.util.unique(resourcesArray,['resourceType'])
      var filteredResourceType = uniqueResourceType
      filteredResourceType.forEach(e=>{
        var data = resourcesArray.filter(x=> x.resourceType.toLowerCase() == e['resourceType'].toLowerCase())
        e['count'] = data.length
      })
      list['resourceTypes'] = filteredResourceType // Used in side bar under each project
    }
    else if(list.isManual){
      let resourceData = await this.getAllResources(list)
      let resourcesArray = resourceData
      var resourceGroupsArray = []
      resourcesArray.forEach(e=>{
        e['resourceGroupName'] = e.id.split('/')[4]
        e['projectId'] = list.projectId
        e['subscriptionId'] = list.subscriptionId
        resourceGroupsArray.push({resourceGroupName: e['resourceGroupName'],projectId:list.projectId,resourceType:e.resourceType})
      })
      var uniqueRG = this.util.unique(resourceGroupsArray,['resourceGroupName'])
      list['resourceGroups'] = uniqueRG
      list['resources'] = resourcesArray

      // Get Unique Resource Types and the resource counts
      var uniqueResourceType = this.util.unique(resourcesArray,['resourceType'])
      var filteredResourceType = uniqueResourceType
      filteredResourceType.forEach(e=>{
        var data = resourcesArray.filter(x=> x.resourceType.toLowerCase() == e['resourceType'].toLowerCase())
        e['count'] = data.length
      })
      list['resourceTypes'] = filteredResourceType// Used in side bar under each project
    }
  
    this.projectList.forEach((e,i)=>{
      i == index ? '' : $('#types_' + i).siblings().slideUp('fast') // close all sub menus
    })
    this.subMenuOpen('types_'+index) // open sub menus
    this.currentProjectData = list
    await this.util.delay(1000)
    this.util.setObservable('currentFilteredProject',list)
  }


  async getAllResources(list){
    let resources = await this.util.handleRequest('get','a3s_management_getResourceTypes',[list.subscriptionId],null,null,null,true)
    // let types = await this.getAllResourceTypes(list)
    let types = this.util.getStaticResourceTypes()
    var array = []
    types.forEach(x=>{
      resources.body.value.forEach(e=>{
         if(x.type.toLowerCase() == e.type.toLowerCase()){
          e['resourceName']= e.name
          e['resourceType'] =x.name
          array.push(e)
         }
      })
    })
    return array;
  }
  
  setCurrentResourceType(type){
    this.currentResourceType = type.resourceType
    this.currentProjectData['currentResourceType'] = type.resourceType
    this.util.setObservable('currentFilteredProject',this.currentProjectData)
  }

  allowSubMenus(list){
    return ['Overview','Manage Policy'].indexOf(list.value) > -1 ? true : false
  }

  collapseSider() {
    $(".main-content").toggleClass('collapse-sidebar');
  }

  routeToChild(link: any){
    this.util.route([link],false);
  }

  subMenuOpen(id){
    if(!id.includes('types_')){
      $('#overview').removeClass('open').siblings().slideUp(300);
      $('#managePolicy').removeClass('open').siblings().slideUp(300);
      if(this.currentProject){
        let index=this.projectList.findIndex(dt=>dt.projectName==this.currentProject);
        if(index>-1){
          this.setCurrentProject(this.projectList[index],index);
        }else{
          this.setCurrentProject(this.projectList[0],0);
        }
      }else{
        this.setCurrentProject(this.projectList[0],0);
      }
    }
    // else $('#' + id).removeClass('open').siblings().slideUp(300);
    $('#' + id).toggleClass('open').siblings().slideToggle(300);
  }

  closeOthers(id){
    $('#overview').removeClass('open').siblings().slideUp(300);
    $('#managePolicy').removeClass('open').siblings().slideUp(300);
  }

  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }

  getProjectBaseImg(list){
     if(list.length <= 1) return 'assets/global-filter/week.svg'
     else if(list.length <= 5) return 'assets/global-filter/medium.svg'
     else if(list.length > 5) return 'assets/global-filter/strong.svg'
  }

  allowUrl(url){
    return ['/a3s/complianceBucket/'+url].indexOf(this.currentUrl)  > -1 ? true :false;
  }

  checkA3sAccess(){
    let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
    return data && data.scope.some(x=> this.currrentTab ? x.id == this.currrentTab.tabId : false) ? true : false;
  }

}
