import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { outputs } from '@syncfusion/ej2-angular-diagrams/src/diagram/diagram.component';
import * as _ from 'lodash';
import { ActivatedRoute} from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
@Component({
  selector: 'app-user-manual-sidebar',
  templateUrl: './user-manual-sidebar.component.html',
  styleUrls: ['./user-manual-sidebar.component.scss']
})
export class UserManualSidebarComponent implements OnInit {
  @Input() activeSidebar: string;
  @Output() onChangeSidebar = new EventEmitter<string>();
  searchText:any;
  
  filterContent:any;
  sidebarContent= [{id:1,value:'Introduction',activeSidebar:'Introduction',child:[]},
                  {id:2,value:'Intended Audience',activeSidebar:'Intended-audience',child:[]},
                  {id:3,value:'AI-powered Azure Automation as a Service',activeSidebar:'AI-powered',child:[{id:100,value:'Overview',activeSidebar:'AI-powered-AI-Overview'},
                                                                                                                                   {id:101,value:'Key Capabilities',activeSidebar:'AI-powered-Key-Capabilities'}]},
                  {id:4,value:'A3S Functional-Buckets',activeSidebar:'A3S-Functional-Buckets',child:[]},
                  {id:5,value:'Functional Overview',activeSidebar:'Functional-Overview',child:[]},
                  {id:6,value:'A3S Project',activeSidebar:'A3S-Project',child:[{id:600,value:'Concept of Project',activeSidebar:'A3S-Project-Concept-of-Project'},{id:601,value:'User Scenarios',activeSidebar:'A3S-Project-User-Scenarios'}]},
                  {id:7,value:'A3S Filtres',activeSidebar:'A3S-Filtres',child:[{id:700,value:'Concept of Filters',activeSidebar:'A3S-CFilters'}]},
                  {id:8,value:'A3S Login',activeSidebar:'A3S-Login',child:[{id:800,value:'Login Steps',activeSidebar:'A3S-LoginSteps'}]},
                  {id:9,value:'Home Page',activeSidebar:'Home-Page',child:[{id:900,value:'Overview',activeSidebar:'HP-Overview'},
                                                                            {id:901,value:'Banner & Task Bar',activeSidebar:'HP-BTB'},
                                                                            {id:902,value:'Hamburger Menu',activeSidebar:'HP-Hamburger'},
                                                                            {id:903,value:'Logo & Name',activeSidebar:'HP-Logo'},
                                                                            {id:904,value:'Search Bar',activeSidebar:'HP-Search'},
                                                                            {id:905,value:'Chat ',activeSidebar:'HP-Chat'},
                                                                            {id:906,value:'Notifications',activeSidebar:'HP-Notifications'},
                                                                            {id:907,value:'Knowledge Center',activeSidebar:'HP-KC'},
                                                                            {id:908,value:'User',activeSidebar:'HP-User'},
                                                                            {id:909,value:'Log out',activeSidebar:'HP-Log-out'},
                                                                            {id:910,value:'Connect Apps',activeSidebar:'HP-Connect'},
                                                                            {id:911,value:'Newsletter',activeSidebar:'HP-Newsletter'},
                                                                            {id:912,value:'Delete organization account',activeSidebar:'HP-Delete'},
                                                                            {id:913,value:'Tiles ',activeSidebar:'HP-Tiles'},
                                                                            {id:914,value:'Recent Activity',activeSidebar:'HP-Recent'},
                                                                            {id:915,value:'Favorites',activeSidebar:'HP-Favorites'}]},
                  {id:10,value:'Apps & Infrastructure',activeSidebar:'Apps-Infrastructure',
                         child:[{id:1000,value:'Architecture Blueprints',activeSidebar:'Apps-Infrastructure-Archietecture-Blueprints',
                                                                          subChild:[{id:10000,value:'Create Project',activeSidebar:'Apps-Infrastructure-Archietecture-Blueprints-Create'},
                                                                          {id:10001,value:'Delete Project',activeSidebar:'Apps-Infrastructure-Archietecture-Blueprints-Delete'}]},
                                {id:1001,value:'Design Tool Components',activeSidebar:'Apps-Infrastructure-Design-Tool-Components'},
                                {id:1002,value:'Using Architecture Design Tool',activeSidebar:'Apps-Infrastructure-Using-Architecture-Design-Tool'},
                                {id:1003,value:'Project Status',activeSidebar:'Apps-Infrastructure-Project-Status',
                                                                          subChild:[{id:10030,value:'In Review',activeSidebar:'Apps-Infrastructure-Project-Status-In-Review'},
                                                                          {id:10031,value:'Approved',activeSidebar:'Apps-Infrastructure-Project-Status-Approved'},
                                                                          {id:10032,value:'Deployed',activeSidebar:'Apps-Infrastructure-Project-Status-Deployed'},
                                                                          {id:10033,value:'Failed',activeSidebar:'Apps-Infrastructure-Project-Status-Failed'}]},
                                {id:1004,value:'Infrastructure Management',activeSidebar:'Apps-Infrastructure-Infrastructure-Management',
                                                                          subChild:[{id:10040,value:'Overview',activeSidebar:'Apps-Infrastructure-IM-Overview'},
                                                                          {id:10041,value:'Resource Management',activeSidebar:'Apps-Infrastructure-IM-RM'}]}]},
                  {id:11,value:'Data & AI',activeSidebar:'Data-AI',
                        child:[{id:1100,value:'Data Migration',activeSidebar:'Data-AI-Data-Migration',
                                        subChild:[{id:11000,value:'Overview',activeSidebar:'Apps-Infrastructure-DM-Overview'},
                                        {id:11001,value:'SQL Server',activeSidebar:'Apps-Infrastructure-DM-SQL'},
                                        {id:11002,value:'Oracle Server',activeSidebar:'Apps-Infrastructure-DM-Oracle'},
                                        {id:11003,value:'SAP Hana',activeSidebar:'Apps-Infrastructure-DM-SAP-Hana'},
                                        {id:11004,value:'SAP ECC with Full & Delta Load',activeSidebar:'Apps-Infrastructure-DM-SAP-ECC'},
                                        {id:11005,value:'Azure Blob Storage',activeSidebar:'Apps-Infrastructure-DM-ABS'}]},
                              {id:1101,value:'Data Governance',activeSidebar:'Data-AI-Data-Governance',
                              subChild:[{id:11005,value:'Data Estate Scan',activeSidebar:'Data-AI-Data-Gov'}]},

                              {id:1102,value:'Data Analytics',activeSidebar:'Data-AI-Data-Analytics',
                                        subChild:[{id:11020,value:'Demand Forecasting',activeSidebar:'Apps-Infrastructure-DA-DF'},
                                        {id:11021,value:'Form Recongnition',activeSidebar:'Apps-Infrastructure-DA-FR'},
                                        {id:11022,value:'Sales Predictions',activeSidebar:'Apps-Infrastructure-DA-SP'},
                                        {id:11023,value:'Revenue Prediction',activeSidebar:'Apps-Infrastructure-DA-RP'},
                                        {id:11024,value:'Product Seasonality Classifier',activeSidebar:'Apps-Infrastructure-DA-PS'},
                                        {id:11025,value:'Knowledge Mining',activeSidebar:'Apps-Infrastructure-DA-KM'},
                                        {id:11026,value:'Modern Finance',activeSidebar:'Apps-Infrastructure-DM-MF'},
                                        {id:11027,value:'Retail recommender',activeSidebar:'Apps-Infrastructure-DM-RR'}]},
                              {id:1103,value:'DataOps',activeSidebar:'Data-AI-DataOps'}]},
                  {id:12,value:'Infrastructure Health Monitoring',activeSidebar:'Infrastructure-Health-Monitoring',
                      child:[{id:1200,value:'Overview',activeSidebar:'Infrastructure-Health-Monitoring-Overview'},
                      {id:1201,value:'Metrics',activeSidebar:'Infrastructure-Health-Monitoring-Metrics'},
                      {id:1202,value:'Insights',activeSidebar:'Infrastructure-Health-Monitoring-Insights',
                              subChild:[{id:12020,value:'Queries',activeSidebar:'IHM-insights-query'},
                              {id:12021,value:'Custom Query',activeSidebar:'IHM-insights-custom-query'}]},
                      {id:1203,value:'Alerts',activeSidebar:'Infrastructure-Health-Monitoring-Alerts',
                              subChild:[{id:12030,value:'Create New Action Group',activeSidebar:'IHM-alerts-newAction'},
                              {id:12031,value:'Create New Alert',activeSidebar:'IHM-alerts-custom-New-Alert'}]}]},

                  {id:13,value:'Application Health Monitoring',activeSidebar:'Application-Health-Monitoring',
                  child:[{id:1300,value:'Overview',activeSidebar:'Application-Health-Monitoring-Overview'},
        
                  {id:1301,value:'Metrics',activeSidebar:'Application-Health-Monitoring-metrics',
                  subChild:[{id:13010,value:'Availability',activeSidebar:'AHM-Availability'},
                            {id:13011,value:'Average CPU and Average Memory Usage',activeSidebar:'AHM-Average'},
                            {id:13012,value:'Server Requests',activeSidebar:'AHM-SQ'},
                            {id:13013,value:'Response Time',activeSidebar:'AHM-RT'},
                            {id:13014,value:'Server Exceptions and Browser Exceptions',activeSidebar:'AHM-Exception'},
                            {id:13015,value:'Events',activeSidebar:'AHM-Events'},
                            {id:13016,value:'Page Views',activeSidebar:'AHM-page-views'}
                  ]
                },
                  {id:1302,value:'Usage',activeSidebar:'Application-Health-Monitoring-Usage',
                  subChild:[{id:13020,value:'Users By Country',activeSidebar:'AHM-Users'},
                            {id:13021,value:'Sessions',activeSidebar:'AHM-Sessions'},
                            {id:13022,value:'Events',activeSidebar:'AHM-usageEvents'},
                            {id:13023,value:'Overall retention',activeSidebar:'AHM-Overall'},
                            {id:13024,value:'Users Returned After # of Weeks',activeSidebar:'AHM-Users-returned'},
                            {id:13025,value:'Initiating Events and Returning Events',activeSidebar:'AHM-return-Events'}
                          ]
                  },
                  {id:1303,value:'Alerts',activeSidebar:'Application-Health-Monitoring-Alerts',
                              subChild:[{id:13030,value:'Create New Action Group',activeSidebar:'AHM-alerts-newAction'},
                              {id:13031,value:'Create New Alert',activeSidebar:'AHM-alerts-custom-New-Alert'}]}]},

                  {id:14,value:'Cost Monitoring & Optimization',activeSidebar:'Cost-Monitoring-Optimization',
                  child:[{id:1400,value:'Overview',activeSidebar:'Cost-Monitoring-Optimization-Overview'},
                  {id:1401,value:'Cost Analysis',activeSidebar:'Cost-Monitoring-Optimization-Cost-Analysis'},
                  {id:1402,value:'Optimize',activeSidebar:'Cost-Monitoring-Optimization-Optimize'},
                  {id:1403,value:'Budget & Alerts',activeSidebar:'Cost-Monitoring-Optimization-Budget-Alerts',
                  subChild:[{id:14030,value:'Create Budget and Set Alerts',activeSidebar:'CM-alerts'}]},
                  {id:1404,value:'Billing',activeSidebar:'Cost-Monitoring-Optimization-Billing',
                  subChild:[{id:14040,value:'Project Cost',activeSidebar:'CM-cost'},
                             {id:14041,value:'Invoices',activeSidebar:'CM-Invoices'},
                            {id:14042,value:'Reservation Transcations',activeSidebar:'CM-RT'},
                            {id:14043,value:'Billing Profiles',activeSidebar:'CM-BP'}]}]},

                  {id:15,value:'Governance',activeSidebar:'Governance',
                  child:[{id:1500,value:'Overview',activeSidebar:'Governance-Overview'},
                  {id:1501,value:'Enterprise Policies',activeSidebar:'Governance-Enterprise-Policies',
                                  subChild:[{id:15010,value:'Assign Policy',activeSidebar:'Gov-policy'},
                                  {id:15011,value:'Assign Initiatives',activeSidebar:'Gov-Initiate'}]},
                  {id:1502,value:'Identity and Access Management',activeSidebar:'Governance-Identity-and-Access-Management',
                  subChild:[{id:15020,value:'Overview',activeSidebar:'IAM-Overview'},
                  {id:15021,value:'Add User',activeSidebar:'IAM-addUser'},
                  {id:15022,value:'Invite a User',activeSidebar:'IAM-Invite'},
                  {id:15023,value:'Add Role Assignment',activeSidebar:'IAM-addRole'},
                  {id:15024,value:'User Management',activeSidebar:'IAM-UM'},
                  {id:15025,value:'Add Group',activeSidebar:'IAM-addGroup'},
                  {id:15026,value:'Group Management',activeSidebar:'IAM-GM'}]},

                  {id:1503,value:'Auditing and Logging',activeSidebar:'Governance-Auditing-and-Logging',
                  subChild:[{id:15030,value:'Activity Logs',activeSidebar:'Gov-Activity'},
                  {id:15031,value:'Sign-Ins',activeSidebar:'Gov-Sign-Ins'},
                  {id:15032,value:'Audit Logs',activeSidebar:'Gov-Audit'}]}]},

                  {id:16,value:'Security',activeSidebar:'Security',
                  child:[{id:1600,value:'Overview',activeSidebar:'Security-Overview',
                  subChild:[{id:16000,value:'Alerts',activeSidebar:'Sec-alerts'},
                  {id:16001,value:'Overall Secure Score',activeSidebar:'Sec-overall'},
                  {id:16002,value:'Coverge',activeSidebar:'Sec-coverage'},
                  {id:16003,value:'Active Recommendations',activeSidebar:'Sec-active'}]},

                  {id:1601,value:'Security Posture',activeSidebar:'Security-Security-Posture',
                  subChild:[{id:16010,value:'Identity Security',activeSidebar:'Sec-IS'},
                  {id:16011,value:'Endpoint Protection',activeSidebar:'Sec-EP'},
                  {id:16012,value:'Activities',activeSidebar:'Sec-activities'},
                  {id:16013,value:'Security Recommendations',activeSidebar:'Sec-Recomm'}]},

                  {id:1602,value:'Alerts',activeSidebar:'Security-Alerts'}]},

                  {id:17,value:'Compliance',activeSidebar:'Compliance',
                  child:[{id:1700,value:'Overview',activeSidebar:'Compliance-Overview',
                  subChild:[{id:17001,value:'Compliance Benchmark',activeSidebar:'Comp-benchmark'},
                            {id:17002,value:'Compliance Status',activeSidebar:'Comp-status'},
                            {id:17003,value:'List of Standards',activeSidebar:'Comp-list'},
                            {id:17004,value:'Deploy Additional Standards',activeSidebar:'Comp-DAS'}]},

                  {id:1701,value:'Manage Policy',activeSidebar:'Compliance-Manage-Policy',
                  subChild:[{id:17010,value:'Default A3S Security Center policy',activeSidebar:'Comp-default'},
                            {id:17011,value:'Manage Policy',activeSidebar:'Comp-MP'}]},

                  {id:1702,value:'Industry and Regulatory Standards',activeSidebar:'Compliance-Industry-and-Regulatory-Standards',
                  subChild:[{id:17020,value:'Add Standards',activeSidebar:'Comp-IRS'}]},

                  {id:1703,value:'Reports',activeSidebar:'Compliance-Reports'}]},


                  {id:18,value:'A3S Support',activeSidebar:'A3S-Support',child:[]},
                  {id:19,value:'Azure Support',activeSidebar:'Azure-Support',child:[]},
                  {id:20,value:'SAP Support',activeSidebar:'SAP-Support',child:[]},]

  searchContent: any = []
  manualName:any;
  constructor( private route: ActivatedRoute,
                 private util:UtilService,
                 ) {
    // if (route.queryParams['value'].data && JSON.parse(route.queryParams['value'].data)){
    //    this.manualName =JSON.parse(route.queryParams['value'].data)
    //   return
    // }
      
  }

  ngOnInit(): void {
    this.searchContent = _.cloneDeep(this.sidebarContent)
    this.filterContent = _.cloneDeep(this.sidebarContent)
    this.manualName = localStorage.getItem('homeSearchManual') ? JSON.parse(localStorage.getItem('homeSearchManual')) : null;
    console.log('manualName',this.manualName)
    if(this.manualName?.activeSidebar ){
    
    let selectedManual= this.filterContent.filter(e=>  {
     e.child = e.child.filter(x=> {
        x.subChild = x.subChild ? x.subChild.filter(y=>y.activeSidebar.toLowerCase()== this.manualName?.activeSidebar.toLowerCase()? true:false) : []
        return x.activeSidebar.toLowerCase()== this.manualName?.activeSidebar.toLowerCase()|| x.subChild.length ? true : false
      })
      return e.activeSidebar.toLowerCase()== this.manualName?.activeSidebar.toLowerCase() || e.child.length  ? true : false })
   console.log(this.filterContent)
      console.log(selectedManual)
    if(selectedManual?.length)
    setTimeout(()=>{
      this.a3sDocSearch(this.manualName?.type,selectedManual[0],(selectedManual[0]?.child[0]),(selectedManual[0]?.child[0]?.subChild[0]))
    },1000);
    this. sidebarScroll()
    
    }
  }
  a3sDocSearch(type,list,subList?:any,subSubList?:any){
    switch(type){
      case 'parent':{
        this.activeSidebar=list.activeSidebar;
         break;
      }
      case 'child':{
        this.subMenuSlideDownOpen(list.activeSidebar)
        this.activeSidebar=subList.activeSidebar;
        break;
     }
      case 'subChild':{
        this.subMenuSlideDownOpen(list.activeSidebar)
        this.subMenuSlideDownOpen(subList.activeSidebar)
        this.activeSidebar=subSubList.activeSidebar;
        break;
      }
    }
    this.onChangeSidebar.emit(this.activeSidebar);
  }
 

  changeTab(type,list,subList?:any,subSubList?:any){
    $(".search-expand").removeClass('open')
    this.searchText='';
    switch(type){
      case 'parent':{
        this.activeSidebar=list.activeSidebar;
         break;
      }
      case 'child':{
        this.subMenuSlideDownOpen(list.activeSidebar)
        this.activeSidebar=subList.activeSidebar;
        break;
     }
      case 'subChild':{
        this.subMenuSlideDownOpen(list.activeSidebar)
        this.subMenuSlideDownOpen(subList.activeSidebar)
        this.activeSidebar=subSubList.activeSidebar;
        break;
      }
    }
   
    if(this.activeSidebar == 'AI-powered' || this.activeSidebar =='A3S-Project' ||this.activeSidebar =='A3S-Filtres'||
    this.activeSidebar == 'A3S-Login'|| this.activeSidebar == 'Data-AI-Data-Governance'||this.activeSidebar =='Home-Page') 
    return
    else this.onChangeSidebar.emit(this.activeSidebar);
  }

  scrollToPosition(activeSidebar){
    if(activeSidebar == 'AI-powered' || activeSidebar =='A3S-Project' ||activeSidebar =='A3S-Filtres'||
    activeSidebar == 'A3S-Login'|| activeSidebar == 'Data-AI-Data-Governance'||activeSidebar =='Home-Page') 
    return
    $('.user-manual-container').animate({scrollTop:200});

  
  }
  sidebarScroll(){
    // let position =$('#'+id).offset().top
    $('.sidebar-expansion-menu').animate({scrollTop:400});
 }
 

  allowDrop(name){
    return ['AI-powered',
    'A3S-Project',
    'A3S-Filtres',
    'A3S-Login',
    'Home-Page',
    'Apps-Infrastructure',
    'Data-AI',
    'Infrastructure-Health-Monitoring',
    'Application-Health-Monitoring',
    'Cost-Monitoring-Optimization',
    'Governance',
    'Security',
    'Compliance'].indexOf(name) > -1 ? true : false
  }
  
  allowSubDrop(name){
    return ['Apps-Infrastructure-Archietecture-Blueprints',
    'Apps-Infrastructure-Project-Status',
    'Apps-Infrastructure-Infrastructure-Management',
    'Data-AI-Data-Migration',
    'Data-AI-Data-Governance',
    'Data-AI-Data-Analytics',
    'Infrastructure-Health-Monitoring-Insights',
    'Infrastructure-Health-Monitoring-Alerts',
    'Application-Health-Monitoring-metrics',
    'Application-Health-Monitoring-Usage',
    'Application-Health-Monitoring-Alerts',
    'Cost-Monitoring-Optimization-Budget-Alerts',
    'Cost-Monitoring-Optimization-Billing',
    'Governance-Enterprise-Policies',
    'Governance-Identity-and-Access-Management',
    'Governance-Auditing-and-Logging',
    'Security-Overview',
    'Security-Security-Posture',
    'Compliance-Overview',
    'Compliance-Manage-Policy',
    'Compliance-Industry-and-Regulatory-Standards'].indexOf(name) > -1 ? true : false
  }

  subMenuSlideDownOpen(id){
    $('#' + id).addClass('open').siblings().slideDown(300);
   
  }


  subMenuOpen(id){
    $('#' + id).toggleClass('open').siblings().slideToggle(300);
  }

  searchManual(event){
    let searchValue = event.target.value.toLowerCase()
    if(searchValue) $(".search-expand").addClass('open')
    else $(".search-expand").removeClass('open')
    let tempArray = _.cloneDeep(this.sidebarContent)
    let array = tempArray.filter(e=> {
      e.child = e.child.filter(x=> {
        x.subChild = x.subChild ? x.subChild.filter(y=>y.value.toLowerCase().includes(searchValue)? true:false) : []
        return x.value.toLowerCase().includes(searchValue) || x.subChild.length ? true : false
      })
      console.log(e.value +'----'+ e.value.toLowerCase().includes(searchValue))
      return e.value.toLowerCase().includes(searchValue) || e.child.length ? true : false
    })
    console.log('array',array)
    this.searchContent = array
  }

}
