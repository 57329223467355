import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { TimechartService } from 'src/app/services/timechart.service';
import { UtilService } from 'src/app/services/util.service';
import { ApplicationMonitoringQueries } from '../../application-monitoring-queries';

@Component({
  selector: 'app-am-sap-hana-system-check',
  templateUrl: './am-sap-hana-system-check.component.html',
  styleUrls: ['./am-sap-hana-system-check.component.scss']
})
export class AmSapHanaSystemCheckComponent implements OnInit {
  currentTab='savePoint';
  datasort: string;
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  searchKey:any;
  subscriptionApp: Subscription;
  savePoint:any = {
    type:'savePoint',
    tempData:[],
    tableData:[],
    showLoader : true,
    refreshTime : new Date(),
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  mvcc:any = {
    type:'mvcc',
    tempData:[],
    tableData:[],
    showLoader : true,
    refreshTime : new Date(),
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  deltaMerge:any = {
    type:'deltaMerge',
    tempData:[],
    tableData:[],
    showLoader : false,
    refreshTime : new Date(),
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  statisticsAlerts:any = {
    type:'statisticsAlerts',
    tempData:[],
    tableData:[],
    showLoader : false,
    refreshTime : new Date(),
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  providerList: any=[];
  selectedData: any;
  timeFilterList: any=[];
  timeFilterFlag: boolean=false;
  filterForm:FormGroup;
  currentProjectData: any;
  ratingList: any = [];
  currentRating: any;
  sidList: any = []

  constructor(private util:UtilService,private modalService: NgbModal,private timechartService:TimechartService,private fb:FormBuilder) { }

  ngOnInit(): void {
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.filterForm=this.fb.group({
      provider:[''],
      sid:[''],
    })
    this.subscriptionApp = this.util.getObservable().subscribe(async (res) => {
      if(res.currentFilteredProject){
        this.currentProjectData = res.currentFilteredProject;
        await this.getProviderList();
        this.triggerFilter(res.currentFilteredProject,'project')
      }
    });
  }

  getProviderList() {
    this.providerList=[];
    return this.util.handleRequest('get','a3s_applicationHealthMonitor_getProviderList',[],null,null,null,true).then(async res=>{
      if(!res || !res.body) {
        return 
      }
      let data=res.body.value||[];
      data=data.filter(dt=>dt.properties.providerSettings.providerType=='SapHana');
      let ipData : any= data.filter(x=> x.properties.providerSettings.providerType=='SapHana').map(xe=> `'${xe.properties.providerSettings.hostname}'`)
      ipData = ipData.join(',')
      let body ={ ipAddr : ipData }
      let ipResponse = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getVMDetailsByGraphQuery',[],body,null,null,true)
      await Promise.all(data.map(async xe=>{
          xe['resourceName'] = null
          if(xe.properties.providerSettings.providerType=='SapNetWeaver'){
              xe['resourceName'] = xe.properties.providerSettings.sapHostname.split('.')[0]
          }
          else if(xe.properties.providerSettings.providerType=='SapHana'){
              let filterData = ipResponse.body.data.filter(x=> x.privateIP == xe.properties.providerSettings.hostname)
              xe['resourceName'] = filterData.length ? filterData[0].virtualMachine.split('/')[filterData[0].virtualMachine.split('/').length - 1] : null
          }
      }))
      data = data.filter(ex=> this.currentProjectData.resourceData.some(x=> x.resourceName.toLowerCase() == ex.resourceName.toLowerCase()))
      this.providerList=data.map((dt,index)=>{
        if(index==0) {
          dt.checked=true;
        } else {
          dt.checked=false;
        }
        return dt;
      });
      this.filterForm.patchValue({
        provider:this.providerList[0].name
      });
    })
  }

  filterRating(){
    this.statisticsAlerts.tableData = [];
    this.statisticsAlerts.tempData.forEach(e=> {
      if(this.checkSidVal(e[e.length-1]) && this.checkProviderVal(e[e.length-2])&&this.currentRating.some(x=> x.toLowerCase() == e[3].toLowerCase())) {
        this.statisticsAlerts.tableData.push(e);
      }      
    })
  }

  getChartData(type) {    
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    const filterSubs=tempSubList.filter(dt=>dt.checked);
    if(!filterSubs.length) {
      this[type].tableData=[];
      this[type].tempData=[];
      this[type].showLoader = false;
    } else {
      let body ={
        "subscriptions":tempSubList.map(dt=>dt.subscriptionId),//["162349c9-37d0-45e1-b2e3-28c4216b7c6b"]
        "query": ApplicationMonitoringQueries[type].replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      }
      this[type].tableData=[];
      this[type].tempData=[];
      this[type].showLoader = true;
      return this.util.handleRequest('post','a3s_applicationHealthMonitor_runLogAnalyticsQuery',[],body,null,null,true).then(res=>{
        if(!res || !res.body) {
          this[type].showLoader = false;
          return 
        }
        res.body.tables.forEach(dt=>{
          dt.rows.forEach(ds=>{
            this[type].tempData.push(ds);
          });
        })
        if(!this[type].tempData.length) return;
        this.prepareData(type);
        this[type].showLoader = false;
      },err=>{
        this[type].showLoader = false;
      })
    }
  }

  // checkProviderVal(providerName) {
  //   return this.filterForm.value.provider==providerName;
  // }

  checkProviderVal(providerName) {
    // return this.filterForm.value.provider==providerName;
    return this.providerList && this.providerList.length ? this.providerList.some(e=> e.name.toLowerCase() == providerName.toLowerCase()) ? true : false : false;
  }

  checkSidVal(sidName) {
    return this.filterForm.value.sid == sidName;
  }

  changeFilter() {
    this.loadAllCardData();
  }

  prepareData(type) {
    this[type].tableData=[];
    let index = `${this[type].tempData[0].length - 1}`
    let unique = this.util.unique(this[type].tempData,[index])
    let filter = unique.filter((e:any)=> this.providerList.some(x=> x.name.toLowerCase() == e[e.length - 2].toLowerCase()))
    this.sidList = filter.map((e:any)=>e[e.length - 1])
    this.filterForm.patchValue({
      sid: this.filterForm.value.sid ? this.filterForm.value.sid : this.sidList[0]
    });
    this[type].tempData.forEach(dt=>{
      let value,sidValue;
      switch (type) {
        // case 'mvcc':
        //   value=dt[4]
        //   sidValue=dt[4]
        //   break;
        case 'mvcc':
        case 'deltaMerge':
        case 'statisticsAlerts':
        case 'savePoint':
          value=dt[dt.length-2]
          sidValue=dt[dt.length-1]
          break;
      
        default:
          break;
      }
      if(this.checkSidVal(sidValue) && this.checkProviderVal(value)) {
        this[type].tableData.push(dt);
      }
      if(type == 'statisticsAlerts'){
        this.ratingList = []
        let uniqueInvoiceSectionDisplayName : any= this.util.unique(this[type].tableData,['3'])
        uniqueInvoiceSectionDisplayName.forEach(x=> this.ratingList.push(x[3]))
        const selected = this.ratingList.map(item => item);
        this.currentRating = selected
      }
    })
  }

  getStatusClass(value){
    let status = value.toLowerCase()
    switch (status) {
      case 'powerstate/running':
      case 'success':
      case 'running': return 'success-chip';
      case 'deallocated':
      case 'stopped deallocated':
      case 'information':
      case 'crit':
      case 'medium':
      case 'powerstate/deallocated':return 'deallocate-chip';
      case 'powerstate/stopped':
      case 'error':
      case 'err':
      case 'stopped': return 'error-chip';
      case 'medium':
      case 'emerg' : return 'emergency-chip';
      case 'low': return 'ready-chip'
      default: return 'warning-chip';
    }
  }

  onSelectAll(event) {
    let isChecked = event.target.checked
    if(isChecked){
      const selected = this.ratingList.map(item => item);
      this.currentRating = selected
    }
    else this.currentRating = []
  }

  checkedAll(){
    return this.ratingList && this.currentRating ? this.ratingList.length == this.currentRating.length ? true : false : false
  }

  ngOnDestroy() {
    if(this.subscriptionApp) this.subscriptionApp.unsubscribe();
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.type);
    return findObj&&findObj.name?findObj.name:''
  }

  loadAllCardData() {
    switch(this.currentTab){
      case 'mvcc' : return this.getChartData('mvcc');
      case 'savePoint' : return this.getChartData('savePoint');
      case 'deltaMerge' : return this.getChartData('deltaMerge');
      case 'statisticsAlerts' : return this.getChartData('statisticsAlerts');
    }
    // this.getChartData('mvcc');
    // this.getChartData('savePoint');
    // this.getChartData('deltaMerge');
    // this.getChartData('statisticsAlerts');
  }

  /************************************** Time Range Filter *******************************************/
  applyFilter(value,list){
    list.currentFilter.type = value
    switch(list.type){
      case 'mvcc' :this.getChartData('mvcc');break;
      case 'savePoint' :this.getChartData('savePoint');break;
      case 'deltaMerge' :this.getChartData('deltaMerge');break;
      case 'statisticsAlerts' :this.getChartData('statisticsAlerts');break;
    }
    this.closeFilter()
  }

  openModalForCustom(value,template: TemplateRef<any>,list?:any) {
    list.currentFilter = value;
    this.openModal(template,list);
  }
  
  applyCustomFilter(){
    this.selectedData.currentFilter.type = 'custom';
    switch(this.selectedData.type){
      case 'mvcc' :this.mvcc = this.selectedData;this.getChartData('mvcc');break;
      case 'savePoint' :this.savePoint = this.selectedData;this.getChartData('savePoint');break;
      case 'deltaMerge' :this.savePoint = this.selectedData;this.getChartData('deltaMerge');break;
      case 'statisticsAlerts' :this.savePoint = this.selectedData;this.getChartData('statisticsAlerts');break;
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 9;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if(!this.timeFilterFlag){
    this.closeFilter();
    }
    this.timeFilterFlag=false;
  }

  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    this.loadAllCardData();
    console.log('Filter Object',this.filterObject)
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    // this.getAllData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

  async refreshData(type){
    switch (type) {
      case 'savePoint':{
        this.savePoint.refreshTime = new Date();
        this.getChartData('savePoint');
        break;
      }
      case 'mvcc':{
        this.mvcc.refreshTime = new Date();
        this.getChartData('mvcc');
        break;
      }
      case 'deltaMerge':{
        this.deltaMerge.refreshTime = new Date();
        this.getChartData('deltaMerge');
        break;
      }
      case 'statisticsAlerts':{
        this.statisticsAlerts.refreshTime = new Date();
        this.getChartData('statisticsAlerts');
        break;
      }
    }    
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

}
