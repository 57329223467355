import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
/** this guard is used to keep track of running jobs and blocks the routes if needed. */
export class NeverActivate implements CanActivate {

    private _isDataMigrationHappening = new BehaviorSubject<boolean>(true);
    isNavigationBlocked = this._isDataMigrationHappening.asObservable();

    /**
     * functions blocks the routing and Navigation to other routes!!
     * @param value TO BLOCK == false | TO UNBLOCK == TRUE
     */
    update_routing_status(value: boolean) {
        this._isDataMigrationHappening.next(value);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this._isDataMigrationHappening.value) console.log("navigation blocked!");
        return this._isDataMigrationHappening.value;
    }

}
