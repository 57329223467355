import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-sql-database-actions',
  templateUrl: './sql-database-actions.component.html',
  styleUrls: ['./sql-database-actions.component.scss']
})
export class SqlDatabaseActionsComponent implements OnInit {
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("sqlDatabaseDetailsTemplate") sqlDatabaseDetailsTemplate: TemplateRef<any>;
  @ViewChild("sqlHardwareConfigurationPopup") sqlHardwareConfigurationPopup: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    InValidSize:false,
    InvalidSizeRange:false,
    invalidIP:false,
    invalidRuleName:false,
    vnetRuleNameEmpty:false,
    vnetRuleNameLen:false,
    vnetRuleNameInvalid:false,
    dbNameEmpty:false,
    dbNameLen:false,
    dbNameInvalid:false,
    dbNameDup:false,
    invalidRestore:false,
  }
  selectedType: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;
  sqlDatabaseData: any;
  resourceGroupsList: any;
  subscriptionList: { name: any; id: any; }[];

  serviceTierList:any=[
    {name:'General Purpose (scalable compute and storage options)',id:'GeneralPurpose',type:'vCore-based purchasing model'},
    {name:'Hyperscale (on-demand scalable storage)',id:'Hyperscale',type:'vCore-based purchasing model'},
    {name:'Business Critical (High transaction rate and high resiliency)',id:'BusinessCritical',type:'vCore-based purchasing model'},
    {name:'Elastic (This database is part of an elastic pool)',id:'ElasticPool',type:'DTU-based purchasing model'},
    {name:'Basic (For less demanding workloads)',id:'Basic',type:'DTU-based purchasing model'},
    {name:'Standard (For workloads with typical performance requirements)',id:'Standard',type:'DTU-based purchasing model'},
    {name:'Premium (For IO-intensive workloads)',id:'Premium',type:'DTU-based purchasing model'},
  ];
  sqlHardwareConfigurationList=[
    {
        configuration:'Gen5',
        description:'Balanced memory and compute',
        maxVCores:'80',
        maxMemory:'408 GB',
        maxStorage:'4 TB',
        computeCost:'184.09 USD',
        id:'Gen5',
    },
    {
        configuration:'FSv2-series',
        description:'Compute optimized',
        maxVCores:'72',
        maxMemory:'136 GB',
        maxStorage:'4 TB',
        computeCost:'170.49 USD',
        id:'FSv2',
    },
    {
        configuration:'DC-series',
        description:'Enables confidential computing',
        maxVCores:'8',
        maxMemory:'36 GB',
        maxStorage:'3 TB',
        computeCost:'294.90 USD',
        id:'DC',
    },
    {
        configuration:'M-Series',
        description:'Memory optimized',
        maxVCores:'--',
        maxMemory:'--',
        maxStorage:'--',
        computeCost:'--',
        id:'M',
    },
  ]
  vCoresList=this.util.getStatic('vCoresList');
  minVCoresList=this.util.getStatic('minVCoresList');
  dataSizeList=this.util.getStatic('dataSizeList');
  dataSizeRange: any;
  days=[
    {name:0},{name:1},{name:2},{name:3},{name:4},{name:5},{name:6},{name:7},
  ]
  hours=[]
  minutes=[
    {name:0},{name:10},{name:20},{name:30},{name:40},{name:50}
  ]
  sourceTypeList=[
    {name:'Point-in-time',id:'Point-in-time'},
    {name:'Long-term backup retention',id:'Long-term backup retention'},
  ]
  replicasList=[
    {name:'0 Replicas',id:0},
    {name:'1 Replicas',id:1},
    {name:'2 Replicas',id:2},
    {name:'3 Replicas',id:3},
    {name:'4 Replicas',id:4}
  ]
  dtuList: { name: string; id: number; tier: string; }[];
  currentSubSheet: any;
  saveBtnEnable: boolean;
  domainNameList: any;
  sqlServerData: any;
  showKendoPopup: boolean;
  sqlServerFirewallRule: any;
  sqlServerVNEetRule: any;
  subnetList: any;
  vNetList: any;
  elasticPoolList: any;
  serviceTier: any;
  currentTime: Date;
  minDate: any;
  maxDate: Date;
  endpointStatus: any;
  currentIndex: any;
  selectedTableRow: any;
  currentDataSize: number;
  subnetData: any;

   constructor(public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private _dataMigrationService: DataMigrationService,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }

  
  async action(data,type,index?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteSqlDatabaseData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteSqlDatabaseData')
      }
      case 'deleteVNETRule':{
        return this.openModal(this.deleteConfirmPopup,data,'deleteVNETRule',index)
      }
      case 'removeDomainName': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'removeDomainName')
        // let index = this.azureFirewallData?.properties?.ipConfigurations.findIndex(e=> e.name == data.name)
        // this.azureFirewallData?.properties?.ipConfigurations.splice(index,1)
        // this.updateFirewallData(this.selectedRow,this.azureFirewallData,'removePublicIP') 
      }
    }
  }
    // Trigger action of the SQL DB
  
  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case 'computeStorage':{
        this.closeModal()
        this.currentForm = this.util.getForm('computeStorageSqlDB')
        await this.getSQLDatabaseData(this.selectedRow)
        await this.setDefaultValue('computeStorage')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case 'firewallSetting':{
        this.closeModal()
        this.currentForm = this.util.getForm('firewallSettingSqlDB')
        await this.getSQLDatabaseData(this.selectedRow)
        await this.getSQLServerData(this.selectedRow)
        await this.getSQLServerFirewallList(this.selectedRow)
        await this.getSQLServerVNetList(this.selectedRow)
        await this.getSQLServerConnectionPolicyData(this.selectedRow)
        await this.getVNetList(this.selectedRow)
        await this.setDefaultValue('firewallSetting')
        this.saveDisable=false
        this.showSidesheet = true;
        await this.getSQLServerAllowAzureIP(this.selectedRow)
        break;
      }
      case "copyDB" : {
        this.closeModal()
        this.subSideSheetForm = this.util.getForm('copySqlDB')
        await this.getSubscription()
        await this.getRgBySubscription()
        await this.getSQLDatabaseData(this.selectedRow)
        await this.getElasticPoolList(this.selectedRow)
        await this.setDefaultValue('copySqlDB')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "restore" : {
        this.closeModal()
        this.subSideSheetForm = this.util.getForm('restoreSqlDB')
        await this.getSubscription()
        await this.getRgBySubscription()
        await this.getSQLDatabaseData(this.selectedRow)
        await this.getElasticPoolList(this.selectedRow)
        await this.setDefaultValue('copySqlDB')
        this.subSideSheetForm.get('sourceDB').setValue(this.sqlDatabaseData?.name)
        this.subSideSheetForm.get('sourceType').setValue(this.sourceTypeList[0].id)
        this.subSideSheetForm.get('earliestPoint').setValue(this.a3sDateTimeFormat.transform(this.sqlDatabaseData?.properties?.earliestRestoreDate?this.sqlDatabaseData?.properties?.earliestRestoreDate:this.sqlDatabaseData?.properties?.creationDate))
        let setTime=this.a3sDateTimeFormat.transform(new Date())
        this.subSideSheetForm.get('restoreDate').setValue(new Date())
        let setTimeFormatSplit=setTime.split(', ')
        this.subSideSheetForm.get('restoreTime').setValue(setTimeFormatSplit[1])
        this.subSideSheetForm.get('tier').setValue(this.sqlDatabaseData?.sku?.tier)
        this.minDate= this.sqlDatabaseData?.properties?.earliestRestoreDate ? this.sqlDatabaseData?.properties?.earliestRestoreDate : this.sqlDatabaseData?.properties?.creationDate
        this.maxDate=  new Date()
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
    }
  }
  async openSubSideSheet(type,subType?:any){
    this.currentSubSheet = type
    switch(this.currentSubSheet){
      case "outboundNetworking" : {
        this.subSideSheetForm= this.formBuilder.group({
          restrictOutbound : [null],
          domainName : [null],
        })
        this.subSideSheetForm.get('restrictOutbound').setValue(this.sqlServerData.properties.restrictOutboundNetworkAccess=='Disabled'?false:true)
        // this.subSideSheetForm.get('domainName').setValue(this.sqlServerData.properties.fullyQualifiedDomainName)
        // this.domainNameList=[{name:this.sqlServerData.properties.fullyQualifiedDomainName}]
        this.showSubSidesheet = true;
        this.saveBtnEnable=false
        this.showKendoPopup = false
        break;
      }
      case "addVNetRule" : {
        this.subSideSheetForm= this.formBuilder.group({
          ruleName : [null],
          sub : [null],
          vNet : [null],
          subnet : [null],
          ignoreEndpoint : [false],
        })
        await this.getSubscription()
        await this.getVNetList(this.selectedRow)
        this.subSideSheetForm.get('sub').setValue(this.subscriptionList[0].id)
        this.subnetList=[]
        if(this.vNetList.length!=0){
          let subnetArray=this.vNetList[0].properties.subnets
          subnetArray.forEach(e=>{
            this.subnetList.push({
              name:`${e.name}/${e.properties.addressPrefix}`,
              id:e.id,
            })
          })
          this.subSideSheetForm.get('vNet').setValue(this.vNetList[0].id)
          this.subSideSheetForm.get('subnet').setValue(this.subnetList[0].id)
          if(subnetArray[0].properties.serviceEndpoints && subnetArray[0].properties.serviceEndpoints.length){
            subnetArray[0].properties.serviceEndpoints.forEach(e=>{
              if(e.service=="Microsoft.Sql") return this.endpointStatus='Enabled'
            })
            this.endpointStatus=this.endpointStatus=='Enabled'? 'Enabled' : 'Not Enabled'
          }else this.endpointStatus='Not Enabled'
    
        }
        this.inputError.vnetRuleNameEmpty=false
        this.inputError.vnetRuleNameLen=false
        this.inputError.vnetRuleNameInvalid=false
        this.showSubSidesheet = true;
        this.saveBtnEnable=false
        break;
      }
      case "createVNet" : {
        this.subSideSheetForm= this.formBuilder.group({
          vNetName : [null],
          addressSpace : [null],
          sub : [null],
          rg : [null],
          location : [null],
          subnetName : [null],
          addressRange : [null],
        })
        this.showSubSidesheet = true;
        this.saveBtnEnable=false
        break;
      }
      case "configureDB" : {
        this.currentForm= this.util.getForm('computeStorageSqlDB')
        if(subType=='restore'){
          this.serviceTier= this.serviceTierList.filter(e=>e.id!='ElasticPool')
          this.serviceTier.forEach(e=>{
            if((e.id=='Hyperscale' && this.sqlDatabaseData?.sku.tier!='Hyperscale') || (e.id!='Hyperscale' && this.sqlDatabaseData?.sku.tier=='Hyperscale')){
              e['disabled']=true
            }else  e['disabled']=false
          })
        }else{
          this.serviceTier=this.serviceTierList.filter(e=>e.type=='vCore-based purchasing model' && e.id!='ElasticPool')
          this.serviceTier.forEach(e=>{
            if(e.id!=this.sqlDatabaseData?.sku.tier){
              e['disabled']=true
            }else  e['disabled']=false
          })
        }
        await this.setDefaultValue('copyConfig')
        this.showSubSidesheet = true;
        this.saveBtnEnable=false
        break;
      }
    }
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteSqlDatabaseData' :return this.deleteSqlDatabaseData(this.selectedRow)
      case 'deleteVNETRule':return this.deletVNETRule(this.selectedTableRow,this.selectedTableRow.name,'deleteVNETRule',this.currentIndex)
      case 'removeDomainName':return
    }
  }
  
  openModal(template: TemplateRef<any>,row,type?:any,index?:any) {
    row.name ? this.selectedTableRow=row : this.selectedRow=row
    this.selectedType=type;
    this.currentIndex=index
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteSqlDatabaseData':return 'Delete SQL Database'
      case'deleteVNETRule':return 'Delete Rule'
      case'removeDomainName':return 'Delete Domain'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteSqlDatabaseData':return `Are you sure you want to delete “${this.selectedRow[0]}”?` 
      case'deleteVNETRule':return `Are you sure you want to delete “${this.selectedTableRow.name}”?`
      case'removeDomainName':return `Are you sure you want to delete “${this.selectedTableRow.name}”?`
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteSqlDatabaseData':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'online': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      case 'connected': return 'st-success';
      case 'not connected': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  getSuccesText(type){
    switch(type){
      case'computeStorage':return `“${this.selectedRow[0]}” Compute + Storage updated successfully!`
      case'firewallRule':return 'Server firewall rules updated successfully!'
      case'outboundNetworking':return 'Outbound firewall rules updated successfully!'
      case'addVNetRule':return'Server vnet rules updated successfully!'
      case'copyDB':return `“${this.selectedRow[0]}” copy created successfully!`
      case'restoreDB':return `SQL database “${this.selectedRow[0]}” restored successfully!`
      default: return 'Changes saved successfully!'
    }
  }
  
  getFailureText(type){
    switch(type){
      case'computeStorage':return `Updating “${this.selectedRow[0]}” Compute + Storage failed.`
      case'firewallRule':return 'Updating server firewall rules failed.'
      case'outboundNetworking':return 'Updating outbound firewall rules failed.'
      case'addVNetRule':return'Updating server vnet rules failed.'
      case'copyDB':return `Creating “${this.selectedRow[0]}” copy failed.`
      case'restoreDB':return `Restoring “${this.selectedRow[0]}” SQL database failed.`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'computeStorage':return `Updating  “${this.selectedRow[0]}” Compute + Storage...`
      case'firewallRule':return 'Updating server firewall rules...'
      case'outboundNetworking':return 'Updating outbound firewall rules...'
      case'addVNetRule':return'Updating server vnet rules...'
      case'copyDB':return `Creating “${this.selectedRow[0]}” copy...`
      case'restoreDB':return `Restoring SQL database “${this.selectedRow[0]}” ...`
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'sqlDBData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }
  
  invalidForm(type){
    switch(type){
      case'computeStorage':{
        return this.inputError?.InValidSize|| this.inputError?.InvalidSizeRange
      }

      case'firewallSetting':{
        return this.inputError?.invalidIP|| this.inputError?.invalidRuleName
      }
      case'addVNetRule':{
        return this.inputError?.vnetRuleNameEmpty|| this.inputError?.vnetRuleNameLen|| this.inputError?.vnetRuleNameInvalid
      }
      case'copyDB':{
        return this.inputError?.dbNameEmpty|| this.inputError?.dbNameLen|| this.inputError?.dbNameInvalid ||this.inputError?.dbNameDup
      }
      case'restoreDB':{
        return this.inputError?.dbNameEmpty|| this.inputError?.dbNameLen|| this.inputError?.dbNameInvalid ||this.inputError?.dbNameDup|| this.inputError?.invalidRestore
      }
    }
  }

  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      InValidSize:false,
      InvalidSizeRange:false,
      invalidIP:false,
      invalidRuleName:false,
      vnetRuleNameEmpty:false,
      vnetRuleNameLen:false,
      vnetRuleNameInvalid:false,
      dbNameEmpty:false,
      dbNameLen:false,
      dbNameInvalid:false,
      dbNameDup:false,
      invalidRestore:false,
    }
  }

  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'computeStorage':{
      this.saveDisable=true
      this.saveBtnEnable=true
      switch(subType){
        case 'computeTier':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('computeTier').setValue(value)
          this.changeTableData(this.currentForm.get('serviceTier').value)
          this.sqlHardwareConfigurationList.forEach(e=>e['active']=false)
          this.sqlHardwareConfigurationList[0]['active']=true
          this.currentForm.get('hardwareConfig').setValue(this.sqlHardwareConfigurationList[0].configuration)
          this.currentForm.get('maxVCore').setValue(this.sqlHardwareConfigurationList[0].maxVCores)
          this.currentForm.get('maxMemory').setValue(this.sqlHardwareConfigurationList[0].maxMemory)
          if(value){
            this.vCoresList=this.vCoresList.filter(e=>e.id!=1)
          }else {
            this.vCoresList=this.util.getStatic('vCoresList')
            this.hours=[]
            for(let i=0;i<24;i++){
              this.hours.push({name:i})
            }
            this.currentForm.get('hrs').setValue(1)
            this.currentForm.get('days').setValue(0)
            this.currentForm.get('mins').setValue(0)
          }
          if( this.currentForm.get('hardwareConfig').value=='FSv2-series'){
            this.vCoresList=this.vCoresList.filter(e=>e.id<=32)
            this.vCoresList.push({name:'36 vCores',id:36},{name:'72 vCores',id:72},)
           }else{
            this.vCoresList=this.vCoresList.filter(e=>e.id<=this.currentForm.get('maxVCore').value)
           }
          //  this.currentForm.get('vCores').setValue(this.vCoresList[0].id)
           this.currentForm.get('minVCores').setValue(this.minVCoresList[0].id)
          break;
        }
        case 'backupStorage':{
          let value = id 
          this.currentForm.get('backupStorage').setValue(value)
          break;
        }
        case 'databaseZone':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('databaseZone').setValue(value)
          break;
        }
        case 'readScaleOut':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('readScaleOut').setValue(value)
          break;
        }
      }
   }
   case 'firewallSetting':{
    this.saveDisable=true
    switch(subType){
      case 'minTLSversion':{
        let value = id 
        this.currentForm.get('minTLSversion').setValue(value)
        break;
      }
      case 'connectionPolicy':{
        let value = id 
        this.currentForm.get('connectionPolicy').setValue(value)
        break;
      }
      case 'allowAzure':{
        let value = id == 'enable' ? true : false
        this.currentForm.get('allowAzure').setValue(value)
        break;
      }
    }
 }
    case 'copyDB':{
      this.saveDisable=true
      switch(subType){
        case 'backupStorage':{
          let value = id 
          this.subSideSheetForm.get('backupStorage').setValue(value)
          break;
        }
        case 'sqlElasticPool':{
          let value = id == 'yes' ? true : false
          this.subSideSheetForm.get('sqlElasticPool').setValue(value)
          if(value){
            this.subSideSheetForm.get('elasticPool').setValidators(Validators.required)
            this.showSubSidesheet=false
          }else{
            this.subSideSheetForm.get('elasticPool').setValidators(null)
            if(this.sqlDatabaseData.sku.name=='ElasticPool'){
              this.subSideSheetForm.get('hardwareConfig').setValue(this.sqlHardwareConfigurationList[0].configuration)
              this.subSideSheetForm.get('tier').setValidators(this.sqlDatabaseData.sku.tier)
              this.subSideSheetForm.get('vCore').setValue(2)
              // this.subSideSheetForm.get('memory').setValidators(null)
            }
            
          }
          this.subSideSheetForm.get('elasticPool').updateValueAndValidity()
          break;
        }
        case'computeTier':{
          this.saveBtnEnable=true
          let value = id == 'yes' ? true : false
          this.currentForm.get('computeTier').setValue(value)
          this.changeTableData(this.currentForm.get('serviceTier').value)
          this.sqlHardwareConfigurationList.forEach(e=>e['active']=false)
          this.sqlHardwareConfigurationList[0]['active']=true
          this.currentForm.get('hardwareConfig').setValue(this.sqlHardwareConfigurationList[0].configuration)
          this.currentForm.get('maxVCore').setValue(this.sqlHardwareConfigurationList[0].maxVCores)
          this.currentForm.get('maxMemory').setValue(this.sqlHardwareConfigurationList[0].maxMemory)
          if(value){
            this.vCoresList=this.vCoresList.filter(e=>e.id!=1)
          }else {
            this.vCoresList=this.util.getStatic('vCoresList')
          }
          if( this.currentForm.get('hardwareConfig').value=='FSv2-series'){
            this.vCoresList=this.vCoresList.filter(e=>e.id<=32)
            this.vCoresList.push({name:'36 vCores',id:36},{name:'72 vCores',id:72},)
           }else{
            this.vCoresList=this.vCoresList.filter(e=>e.id<=this.currentForm.get('maxVCore').value)
           }
        }
      }
   }
  }
}
denyPublicAccess(event){
  // if (event.target.checked) {
  //    this.currentForm.get('firewallRuleArray').reset()
     this.saveDisable=true
  // }
}
  get firewallRuleArrayList() {
    return this.currentForm.get('firewallRuleArray') as FormArray;
  }
  addFirewallRuleArray(){
    this.firewallRuleArrayList.push(this.util.getForm('addFirewallRuleArray'))
  }
  async removeRule(list,index) {
    if(list.get('name').value!=''){
      await this.deleteFirewallRule(this.selectedRow,list.get('name').value,'deleteFirewallRule',index)
    }
    this.firewallRuleArrayList.controls.splice(index, 1);
  }
  removeVNETRule(list,index){
    this.deletVNETRule(this.selectedRow,list.name,'deleteVNETRule',index)
  }
  removeDomainName(index) {
    this.domainNameList.splice(index, 1);
  }
  pushDomainName(){
    this.saveBtnEnable=true
    this.domainNameList=[]
    this.domainNameList.push({name:this.subSideSheetForm.get('domainName')?.value})
    console.log('Domain names',this.domainNameList)
  }
  getSubnetList(event){
    let index=this.vNetList.findIndex(e=>e.name==event.name)
    let subnetArray=this.vNetList[index].properties.subnets
    this.subnetList=[]
    subnetArray.forEach(e=>{
      this.subnetList.push({
        name:`${e.name}/${e.properties.addressPrefix}`,
        id:e.id,
      })
    })
    this.subSideSheetForm.get('subnet').setValue(this.subnetList[0].id)
    if(subnetArray[0].properties.serviceEndpoints && subnetArray[0].properties.serviceEndpoints.length){
      subnetArray[0].properties.serviceEndpoints.forEach(e=>{
        if(e.service=="Microsoft.Sql") return this.endpointStatus='Enabled'
      })
      this.endpointStatus=this.endpointStatus=='Enabled'? 'Enabled' : 'Not Enabled'
    }else this.endpointStatus='Not Enabled'
  }
  getEndpoint(event){
    let index=this.vNetList.findIndex(e=>e.id==this.subSideSheetForm.get('vNet').value)
    let i=this.vNetList[index].properties.subnets.findIndex(e=>e.id==event.id)
    if(this.vNetList[index].properties.subnets[i].properties.serviceEndpoints && this.vNetList[index].properties.subnets[i].properties.serviceEndpoints.length){
      this.vNetList[index].properties.subnets[i].properties.serviceEndpoints.forEach(e=>{
        if(e.service=="Microsoft.Sql")  return this.endpointStatus='Enabled'
      })
      this.endpointStatus=this.endpointStatus=='Enabled'? 'Enabled' : 'Not Enabled'
    }else this.endpointStatus='Not Enabled'
  }
  getAddressSpace(list){
    let index=this.vNetList.findIndex(e=>e.name==list?.properties?.virtualNetworkSubnetId.split('/')[8])
    return this.vNetList[index].properties.addressSpace.addressPrefixes[0]
  }
  getEndpointStatus(type,data?:any){
    if(type=='create'){
      let status:string;
      let index=this.vNetList.findIndex(e=>e.id==this.subSideSheetForm.get('vNet').value)
      let i=this.vNetList[index].properties.subnets.findIndex(e=>e.id==this.subSideSheetForm.get('subnet').value)
      this.vNetList[index].properties.subnets[i].properties.serviceEndpoints.forEach(e=>{
        if(e.service=="Microsoft.Sql") return status='Enabled'
      })
      return status=='Enabled'? status:'Not Enabled'
    }else{
      let status:string;
      let index=this.vNetList.findIndex(e=>e.name==data?.properties?.virtualNetworkSubnetId.split('/')[8])
      let i=this.vNetList[index].properties.subnets.findIndex(e=>e.name==data?.properties?.virtualNetworkSubnetId.split('/')[10])
      if(this.vNetList[index].properties.subnets[i].properties.serviceEndpoints){
        this.vNetList[index].properties.subnets[i].properties.serviceEndpoints.forEach(e=>{
          if(e.service=="Microsoft.Sql") return status='Enabled'
        })
        return status=='Enabled'? status:'Not Enabled'
      } else return 'Not Enabled'
   
    }

  }
  async setDefaultValue(type){
  switch(type){
    case 'computeStorage':{
      let config=this.sqlDatabaseData
      let allDataSizeList=this.util.getStatic('dataSizeList');
      this.serviceTierList.forEach(e=> e['disabled']=false)
      this.serviceTier=config?.sku?.name=='ElasticPool' ? this.serviceTierList : this.serviceTierList.filter(e=>e.id!='ElasticPool')
      this.currentForm.get('serviceTier').setValue(config?.sku?.tier)
      this.currentForm.get('computeTier').setValue(config?.kind!='v12.0,user,vcore,serverless'?true:false)
      this.currentForm.get('vCores').setValue(config?.sku?.capacity)
      this.currentDataSize=(config?.properties?.maxSizeBytes*9.31)/10000000000 
      this.currentDataSize=Math.round( this.currentDataSize)
      this.serviceTier.forEach(e=>e['disabled']=e.id=='Basic' && this.currentDataSize>5 ? true:false)
      this.currentForm.get('dataMaxSize').setValue(Math.round( this.currentDataSize))
      this.currentForm.get('databaseZone').setValue(config?.properties?.zoneRedundant)
      this.currentForm.get('backupStorage').setValue(config?.properties?.requestedBackupStorageRedundancy)
      this.currentForm.get('readScaleOut').setValue(config?.properties?.readScale=='Disabled'?false:true)
      if(config?.kind!='v12.0,user,vcore,serverless'){
        this.vCoresList=this.vCoresList.filter(e=>e.id!=1)
      }else{
        this.currentForm.get('minVCores').setValue(config?.properties?.minCapacity)
        let min=config?.properties?.autoPauseDelay
        this.hours=[]
        for(let i=0;i<24;i++){
          this.hours.push({name:i})
        }
        if(config?.properties?.autoPauseDelay==-1){
          this.currentForm.get('days').setValue(0)
          this.currentForm.get('hrs').setValue(1)
          this.currentForm.get('mins').setValue(0)
        }else{
          if(min<60){
            this.currentForm.get('days').setValue(0)
            this.currentForm.get('hrs').setValue(0)
            this.currentForm.get('mins').setValue(min)
          }else if(min>=60 && min<1440){
            let hr=Math.trunc(min/60)
            let hrMins=min-(hr*60)
            this.currentForm.get('days').setValue(0)
            this.currentForm.get('hrs').setValue(hr)
            this.currentForm.get('mins').setValue(hrMins)
          }else{
            let days=Math.trunc(min/1440)
            let hr=Math.trunc((min-(days*1440))/60)
            let hrMins=min-(hr*60)
            this.currentForm.get('days').setValue(days)
            this.currentForm.get('hrs').setValue(hr)
            this.currentForm.get('mins').setValue(hrMins)
          }
        }
        this.currentForm.get('autoPauseDelay').setValue(config?.properties?.autoPauseDelay!=-1?true:false)
      }
      await this.changeTableData(config?.sku?.tier)
      if(config?.sku?.tier=='GeneralPurpose'||config?.sku?.tier=='Hyperscale'||config?.sku?.tier=='BusinessCritical'){
        this.sqlHardwareConfigurationList.forEach(e=>e['active']=false)
        this.sqlHardwareConfigurationList.forEach(e=>{
          if(e.id==this.sqlDatabaseData.sku.family){
            e['active']=true
            this.currentForm.get('hardwareConfig').setValue(e.configuration)
            this.currentForm.get('maxVCore').setValue(e.maxVCores)
            this.currentForm.get('maxMemory').setValue(e.maxMemory)
          }
        })
        if(config?.sku?.tier=='Hyperscale'){
          this.currentForm.get('replicas').setValue(config?.properties?.highAvailabilityReplicaCount?config?.properties?.highAvailabilityReplicaCount:1)
          if(this.currentDataSize>5){
            this.serviceTier.forEach(e=>e['disabled']=e.id=='BusinessCritical' || e.type=='DTU-based purchasing model' || e.id=='Basic'? true:false)
          }else this.serviceTier.forEach(e=>e['disabled']=e.id=='BusinessCritical' || e.type=='DTU-based purchasing model'? true:false)
        }
      }else{
        if(config?.sku?.name=='ElasticPool'){
          this.currentForm.get('serviceTier').setValue(config?.sku?.name)
          this.currentForm.get('vCores').setValue(2)
        }else if(config?.sku?.name=='Standard'){
          this.currentDataSize=config?.properties?.maxSizeBytes <=524288000 ? (config?.properties?.maxSizeBytes*9.5367431640625)/10000000000 : this.currentDataSize
          this.dtuList=[
            {name:'10 (S0)',id:10,tier:'S0'},
            {name:'20 (S1)',id:20,tier:'S1'},
            {name:'50 (S2)',id:50,tier:'S2'},
            {name:'100 (S3)',id:100,tier:'S3'},
            {name:'200 (S4)',id:200,tier:'S4'},
            {name:'400 (S6)',id:400,tier:'S6'},
            {name:'800 (S7)',id:800,tier:'S7'},
            {name:'1600 (S9)',id:1600,tier:'S9'},
            {name:'3000 (S12)',id:3000,tier:'12'},
          ]
        }else if(config?.sku?.name=='Premium'){
          this.currentDataSize=config?.properties?.maxSizeBytes <=524288000 ? (config?.properties?.maxSizeBytes*9.5367431640625)/10000000000 : this.currentDataSize
          this.dtuList=[
            {name:'125 (P1)',id:125,tier:'P1'},
            {name:'250 (P2)',id:250,tier:'P2'},
            {name:'500 (P4)',id:500,tier:'P4'},
            {name:'1000 (P6)',id:1000,tier:'P6'},
            {name:'1750 (P11)',id:1750,tier:'P11'},
            {name:'4000 (P15)',id:4000,tier:'P15'},
          ]
        }
        if(config?.sku?.name=='Basic'){
          this.currentDataSize=config?.properties?.maxSizeBytes <=524288000 ? (config?.properties?.maxSizeBytes*9.5367431640625)/10000000000 : this.currentDataSize
          this.dataSizeList=allDataSizeList.filter(e=>e.name<=2)
        }else{
          if(config?.sku?.capacity<=50){
            this.dataSizeList=allDataSizeList.filter(e=>e.name<=250)
          }else if(config?.sku?.capacity>=100 && config?.sku?.capacity<=3000 && config?.sku?.capacity!=1750){
            this.dataSizeList=allDataSizeList.filter(e=>e.name<=1024)
          }else   this.dataSizeList=allDataSizeList
        }
        this.currentForm.get('dtu').setValue(config?.sku?.capacity)
        this.currentForm.get('dataMaxSize').setValue(this.currentDataSize)
      }
      
   break;
    }
    case 'firewallSetting':{
      this.currentForm.get('publicNetworkAccess').setValue(this.sqlServerData.properties.publicNetworkAccess=='Enabled'?false:true)
      this.currentForm.get('minTLSversion').setValue(this.sqlServerData.properties.minimalTlsVersion)
      this.sqlServerFirewallRule.forEach(e=>{
        this.firewallRuleArrayList.push(this.util.getForm('addFirewallRuleArray',{
          name:e.name,
          startIP:e.properties.startIpAddress,
          endIP:e.properties.endIpAddress,
        }))
      })
      this.firewallRuleArrayList.controls.splice(0,1)
   break;
   }
   case 'copySqlDB':{
    let config=this.sqlDatabaseData
       this.subSideSheetForm.get('subscription').setValue(this.selectedRow[2])
       let rgName= this.resourceGroupsList.filter(e=>e.name==this.selectedRow[1])
       this.subSideSheetForm.get('rg').setValue(rgName[0].id)
       this.subSideSheetForm.get('serverName').setValue(this.selectedRow[4])
       this.subSideSheetForm.get('sqlElasticPool').setValue(config.kind.includes('pool') ? true:false)
       config.kind.includes('pool') ? this.subSideSheetForm.get('elasticPool').setValue(config.properties.elasticPoolId) : this.subSideSheetForm.get('elasticPool').reset()
       this.subSideSheetForm.get('backupStorage').setValue(config.properties.requestedBackupStorageRedundancy)
       this.subSideSheetForm.get('hardwareConfig').setValue(config?.sku?.family)
       this.subSideSheetForm.get('vCore').setValue(config?.sku?.capacity)
       let dataSize=(config?.properties?.maxSizeBytes*9.31)/10000000000 
       this.subSideSheetForm.get('memory').setValue(Math.round(dataSize))

    break;
  }
  case 'copyConfig':{
    let config=this.sqlDatabaseData
    // this.vCoresList=await this.util.getStatic('vCoresList');
    this.sqlHardwareConfigurationList=[
      {
          configuration:'Gen5',
          description:'Balanced memory and compute',
          maxVCores:'80',
          maxMemory:'408 GB',
          maxStorage:'4 TB',
          computeCost:'184.09 USD',
          id:'Gen5',
      },
      {
          configuration:'FSv2-series',
          description:'Compute optimized',
          maxVCores:'72',
          maxMemory:'136 GB',
          maxStorage:'4 TB',
          computeCost:'170.49 USD',
          id:'FSv2',
      },
      {
          configuration:'DC-series',
          description:'Enables confidential computing',
          maxVCores:'8',
          maxMemory:'36 GB',
          maxStorage:'3 TB',
          computeCost:'294.90 USD',
          id:'DC',
      },
      {
          configuration:'M-Series',
          description:'Memory optimized',
          maxVCores:'--',
          maxMemory:'--',
          maxStorage:'--',
          computeCost:'--',
          id:'M',
      },
    ]
    this.currentForm.get('serviceTier').setValue(config?.sku?.tier)
    this.currentForm.get('computeTier').setValue(config?.kind!='v12.0,user,vcore,serverless'?true:false)
    this.currentForm.get('vCores').setValue(config?.sku?.capacity)
    this.currentForm.get('readScaleOut').setValue(config?.properties?.readScale=='Disabled'?false:true)
    // this.currentForm.get('readScaleOut').disable()
    let dataSize=(config?.properties?.maxSizeBytes*9.31)/10000000000 
    this.currentForm.get('dataMaxSize').setValue(Math.round(dataSize))
    if(config?.kind!='v12.0,user,vcore,serverless'){
      this.vCoresList=this.vCoresList.filter(e=>e.id!=1)
    }
    if(config?.sku?.tier=='GeneralPurpose'||config?.sku?.tier=='Hyperscale'||config?.sku?.tier=='BusinessCritical'){
      this.sqlHardwareConfigurationList.forEach(e=>{
        if(e.id==this.sqlDatabaseData.sku.family){
          e['active']=true
          this.currentForm.get('hardwareConfig').setValue(e.configuration)
          this.currentForm.get('maxVCore').setValue(e.maxVCores)
          this.currentForm.get('maxMemory').setValue(e.maxMemory)
        }
      })
    }
    this.subSideSheetForm.get('memory').setValue(Math.round(dataSize))
    if(this.sqlDatabaseData.sku.name=='ElasticPool'){
      this.sqlHardwareConfigurationList[0]['active']=true
      this.currentForm.get('hardwareConfig').setValue(this.sqlHardwareConfigurationList[0].configuration)
      this.currentForm.get('maxVCore').setValue(this.sqlHardwareConfigurationList[0].maxVCores)
      this.currentForm.get('maxMemory').setValue(this.sqlHardwareConfigurationList[0].maxMemory)
      this.currentForm.get('vCores').setValue(2)
      this.subSideSheetForm.get('hardwareConfig').setValue(this.sqlHardwareConfigurationList[0].configuration)
      this.subSideSheetForm.get('vCore').setValue(2)
    }else{
      this.subSideSheetForm.get('hardwareConfig').setValue(config?.sku?.family)
      this.subSideSheetForm.get('vCore').setValue(config?.sku?.capacity)
    }
    break;
  }
  }
  }
  async setServiceTier(event){
    this.saveDisable=true
    let allDataSizeList=this.util.getStatic('dataSizeList');
    let currentServiceTier=this.currentForm.get('serviceTier').value
    this.changeTableData(currentServiceTier)
    this.currentForm.get('computeTier').setValue(true)
    if(currentServiceTier=='GeneralPurpose'){
      this.vCoresList=this.vCoresList.filter(e=>e.id!=1)
      this.currentForm.get('dataMaxSize').setValue(32)
      await this.setVCoreListBasedOnConfig(this.vCoresList)
    }else if(currentServiceTier=='Hyperscale'){
      this.vCoresList=this.vCoresList.filter(e=>e.id!=1)
      await this.setVCoreListBasedOnConfig(this.vCoresList)
      this.currentForm.get('replicas').setValue(this.replicasList[1].id)
      // this.currentForm.get('backupStorage').disable()
    }else if(currentServiceTier=='BusinessCritical'){
      this.vCoresList=this.vCoresList.filter(e=>e.id!=1)
      await this.setVCoreListBasedOnConfig(this.vCoresList)
      this.currentForm.get('readScaleOut').setValue(true)
      this.currentForm.get('dataMaxSize').setValue(32)
    }else if(currentServiceTier=='ElasticPool'){
      this.currentForm.get('dataMaxSize').setValue(32)
    }else if(currentServiceTier=='Basic'){
      this.dataSizeList=allDataSizeList.filter(e=>e.name<=2)
      this.currentForm.get('dataMaxSize').setValue(this.dataSizeList[0].name)
    }else if(currentServiceTier=='Standard'){
      this.dtuList=[
        {name:'10 (S0)',id:10,tier:'S0'},
        {name:'20 (S1)',id:20,tier:'S1'},
        {name:'50 (S2)',id:50,tier:'S2'},
        {name:'100 (S3)',id:100,tier:'S3'},
        {name:'200 (S4)',id:200,tier:'S4'},
        {name:'400 (S6)',id:400,tier:'S6'},
        {name:'800 (S7)',id:800,tier:'S7'},
        {name:'1600 (S9)',id:1600,tier:'S9'},
        {name:'3000 (S12)',id:3000,tier:'12'},
      ]
      this.currentForm.get('dtu').setValue(this.dtuList[0].id)
      this.dataSizeList=allDataSizeList.filter(e=>e.name<=250)
      this.currentForm.get('dataMaxSize').setValue(this.dataSizeList[this.dataSizeList.length-1].name)
    }else{
      this.dtuList=[
        {name:'125 (P1)',id:125,tier:'P1'},
        {name:'250 (P2)',id:250,tier:'P2'},
        {name:'500 (P4)',id:500,tier:'P4'},
        {name:'1000 (P6)',id:1000,tier:'P6'},
        {name:'1750 (P11)',id:1750,tier:'P11'},
        {name:'4000 (P15)',id:4000,tier:'P15'},
      ]
      this.currentForm.get('dtu').setValue(this.dtuList[0].id)
      this.dataSizeList=allDataSizeList.filter(e=>e.name<=1024)
      this.currentForm.get('dataMaxSize').setValue(this.dataSizeList[this.dataSizeList.length-1].name)
    }
  }
  openHardwareConfigPopup(){
    this.openModal(this.sqlHardwareConfigurationPopup,this.selectedRow,'sqlHardwareConfigurationPopup')
  }
  setDefHardware(list,i){
    this.sqlHardwareConfigurationList.forEach(e=>e['active']=false)
    list.active=true
  }
  setDataSize(event){
    this.saveDisable=true
    let allDataSizeList=this.util.getStatic('dataSizeList');
    if(event.id<=50){
      this.dataSizeList=allDataSizeList.filter(e=>e.name<=250)
    }else if(event.id>=100 && event.id<=3000 && event.id!=1750){
      this.dataSizeList=allDataSizeList.filter(e=>e.name<=1024)
    }else   this.dataSizeList=allDataSizeList
    this.currentForm.get('dataMaxSize').setValue(this.dataSizeList[this.dataSizeList.length-1].name)
  }
  setMinVCore(event){
     let val= event.id
     this.saveDisable=true
     let allminVCoresList=this.util.getStatic('minVCoresList');
     let index=this.vCoresList.findIndex(e=>e.id==val)
     let filterArr=allminVCoresList.filter(e=>e.id<=val)
     if(val!=1&&val!=2&&val!=4){
      this.minVCoresList=filterArr.splice(index-2,filterArr.length)
     }else return this.minVCoresList=filterArr
     this.currentForm.get('minVCores').setValue(this.minVCoresList[0].id)
  }
  async setHardwareConfig(){
    this.saveDisable=true
    this.saveBtnEnable=true
    this.sqlHardwareConfigurationList.forEach(e=>{
      if(e['active']){
        this.currentForm.get('hardwareConfig').setValue(e.configuration)
        this.currentForm.get('maxVCore').setValue(e.maxVCores)
        this.currentForm.get('maxMemory').setValue(e.maxMemory)
      }
    })
    let allList=this.util.getStatic('vCoresList');
   let allminListt=this.util.getStatic('minVCoresList');
   if(this.currentForm.get('computeTier').value){
    allList=allList.filter(e=>e.id!=1)
   }
    await this.setVCoreListBasedOnConfig(allList)
    this.currentForm.get('vCores').setValue(this.vCoresList[0].id)
    this.currentForm.get('minVCores').setValue(this.minVCoresList[0].id)
    this.closeModal()
  }
  setVCoreListBasedOnConfig(allList){
    if( this.currentForm.get('hardwareConfig').value=='FSv2-series'){
      this.vCoresList=allList.filter(e=>e.id<=32)
      return this.vCoresList.push({name:'36 vCores',id:36},{name:'72 vCores',id:72})
     }else{
      return this.vCoresList=allList.filter(e=>e.id<=this.currentForm.get('maxVCore').value)
     }
  }
  changeTableData(currentServiceTier){
    let index=this.sqlHardwareConfigurationList.findIndex(e=>e.configuration=='FSv2-series')
    let index1=this.sqlHardwareConfigurationList.findIndex(e=>e.configuration=='DC-series')
    if(currentServiceTier=='Hyperscale'){
      this.sqlHardwareConfigurationList[0]['maxVCores']='80'
      this.sqlHardwareConfigurationList[0]['maxMemory']='408 GB'
      this.sqlHardwareConfigurationList[0]['maxStorage']='--'
      this.sqlHardwareConfigurationList[0]['computeCost']='206.32 USD'
      this.sqlHardwareConfigurationList[index1]['maxMemory']='36 GB'
      this.sqlHardwareConfigurationList[index1]['maxVCores']='8'
      this.sqlHardwareConfigurationList[index1]['maxStorage']='--'
      this.sqlHardwareConfigurationList[index1]['computeCost']='339.43 USD'
    this.sqlHardwareConfigurationList[index]['maxVCores']='--'
    this.sqlHardwareConfigurationList[index]['maxStorage']='--'
    this.sqlHardwareConfigurationList[index]['maxMemory']='--'
    this.sqlHardwareConfigurationList[index]['computeCost']='--'
    let val =this.sqlHardwareConfigurationList.splice(index,1)
    this.sqlHardwareConfigurationList.push(val[0])
    }else if(currentServiceTier=='BusinessCritical'){
      this.sqlHardwareConfigurationList[0]['maxVCores']='80'
      this.sqlHardwareConfigurationList[0]['maxStorage']='4 TB'
      this.sqlHardwareConfigurationList[0]['maxMemory']='408 GB'
      this.sqlHardwareConfigurationList[0]['computeCost']='495.99 USD'
      this.sqlHardwareConfigurationList[index1]['maxStorage']='0.75 TB'
      this.sqlHardwareConfigurationList[index1]['maxVCores']='8'
      this.sqlHardwareConfigurationList[index1]['maxMemory']='36 GB'
      this.sqlHardwareConfigurationList[index1]['computeCost']='718.32 USD'
    this.sqlHardwareConfigurationList[index]['maxVCores']='--'
    this.sqlHardwareConfigurationList[index]['maxStorage']='--'
    this.sqlHardwareConfigurationList[index]['maxMemory']='--'
    this.sqlHardwareConfigurationList[index]['computeCost']='--'
    let val =this.sqlHardwareConfigurationList.splice(index,1)
    this.sqlHardwareConfigurationList.push(val[0])
    }else if(!this.currentForm.get('computeTier').value){
      this.sqlHardwareConfigurationList[0]['maxVCores']='40'
      this.sqlHardwareConfigurationList[0]['maxMemory']='120 GB'
      this.sqlHardwareConfigurationList[0]['maxStorage']='4 TB'
      this.sqlHardwareConfigurationList[0]['computeCost']='0.000145 USD'
      this.sqlHardwareConfigurationList[index]['maxVCores']='--'
      this.sqlHardwareConfigurationList[index]['maxStorage']='--'
      this.sqlHardwareConfigurationList[index]['maxMemory']='--'
      this.sqlHardwareConfigurationList[index]['computeCost']='--'
      this.sqlHardwareConfigurationList[index1]['maxVCores']='--'
      this.sqlHardwareConfigurationList[index1]['maxStorage']='--'
      this.sqlHardwareConfigurationList[index1]['maxMemory']='--'
      this.sqlHardwareConfigurationList[index1]['computeCost']='--'
    }else{
      this.sqlHardwareConfigurationList=[
        {
            configuration:'Gen5',
            description:'Balanced memory and compute',
            maxVCores:'80',
            maxMemory:'408 GB',
            maxStorage:'4 TB',
            computeCost:'184.09 USD',
            id:'Gen5',
        },
        {
            configuration:'FSv2-series',
            description:'Compute optimized',
            maxVCores:'72',
            maxMemory:'136 GB',
            maxStorage:'4 TB',
            computeCost:'170.49 USD',
            id:'FSv2',
        },
        {
            configuration:'DC-series',
            description:'Enables confidential computing',
            maxVCores:'8',
            maxMemory:'36 GB',
            maxStorage:'3 TB',
            computeCost:'294.90 USD',
            id:'DC',
        },
        {
            configuration:'M-Series',
            description:'Memory optimized',
            maxVCores:'--',
            maxMemory:'--',
            maxStorage:'--',
            computeCost:'--',
            id:'M',
        },
      ]
    }
    this.sqlHardwareConfigurationList.forEach(e=>e['active']=false)
    this.sqlHardwareConfigurationList[0]['active']=true
    this.currentForm.get('hardwareConfig').setValue(this.sqlHardwareConfigurationList[0].configuration)
    this.currentForm.get('maxVCore').setValue(this.sqlHardwareConfigurationList[0].maxVCores)
    this.currentForm.get('maxMemory').setValue(this.sqlHardwareConfigurationList[0].maxMemory)
  }
  disableHardwareConfigRow(row){
    let currentServiceTier=this.currentForm.get('serviceTier').value
    let compTier=this.currentForm.get('computeTier').value
    return row?.configuration=='M-Series' || (currentServiceTier!='GeneralPurpose' && row?.configuration=='FSv2-series') || (!compTier && row?.configuration!='Gen5')
  }
  setTime(event){
    this.saveDisable=true
      let startTimeFormat=this.a3sDateTimeFormat.transform(event.value)
      this.currentTime= new Date()
      let currentTimeFormat=this.a3sDateTimeFormat.transform(this.currentTime)
      let startTimeFormatSplit=startTimeFormat.split(', ')
      this.subSideSheetForm.get('restoreTime').setValue(startTimeFormatSplit[1])
      if(startTimeFormat<this.subSideSheetForm.get('earliestPoint').value || startTimeFormat>currentTimeFormat) {
        return this.inputError.invalidRestore=true
      }else{
        return this.inputError.invalidRestore=false
      }
  }
  async saveConfigureDB(type){
      let rawValue=this.currentForm.getRawValue()
      this.subSideSheetForm.get('hardwareConfig').setValue(rawValue.hardwareConfig)
      this.subSideSheetForm.get('vCore').setValue(rawValue.vCores)
      this.subSideSheetForm.get('memory').setValue(rawValue.dataMaxSize)
      this.showSubSidesheet=false
      this.subSideSheetForm.get('tier').setValue(rawValue.serviceTier)
  }
  async saveSQLDBData(type,subType?:any){
    switch(type){

      case'computeStorage':{
        let rawValue = this.currentForm.getRawValue()
        let size=(rawValue.dataMaxSize*10000000000)/9.313225746154785 
        size=Math.round(size)
        let body=this.sqlDatabaseData
        if(rawValue.serviceTier=='GeneralPurpose'){
          body = { 
            "id": this.sqlDatabaseData.id, 
            "location": this.sqlDatabaseData.location, 
            "properties": { 
                "maxSizeBytes": `${size}`, 
                "collation": this.sqlDatabaseData?.properties?.collation, 
                "zoneRedundant": rawValue.databaseZone, 
                "licenseType": this.sqlDatabaseData?.properties?.licenseType, 
                "highAvailabilityReplicaCount": 0, 
                "readScale": "Disabled",//this.sqlDatabaseData?.properties?.readScale, 
                "requestedBackupStorageRedundancy": rawValue.backupStorage
            }, 
            "sku": { 
                "name": 'GP_Gen5_2', 
                "tier": "GeneralPurpose" 
             } 
            }
          // body['sku']={
          //   "name": rawValue.hardwareConfig=='Gen5'?'GP_Gen5':rawValue.hardwareConfig=='DC-series'?"GP_DC":'GP_FSv2', 
          //   "tier": "GeneralPurpose", 
          //   "family": rawValue.hardwareConfig=='Gen5'?'Gen5':rawValue.hardwareConfig=='DC-series'?"DC":'FSv2', 
          //   "capacity": rawValue.vCores 
          // }
          // body['properties'].maxSizeBytes= size
          // body['properties'].zoneRedundant= rawValue.databaseZone
          // body['properties'].requestedBackupStorageRedundancy= rawValue.backupStorage
          if(rawValue.computeTier){
            // body['kind']= "v12.0,user,vcore"
            body['sku'].name=rawValue.hardwareConfig=='Gen5'?`GP_Gen5_${rawValue.vCores }`:rawValue.hardwareConfig=='DC-series'?`GP_DC_${rawValue.vCores }`:`GP_FSv2_${rawValue.vCores }`
          }else{
            // body['kind']= "v12.0,user,vcore,serverless"
            // body['sku'].name= rawValue.hardwareConfig=='Gen5'?'GP_S_Gen5':rawValue.hardwareConfig=='DC-series'?"GP_S_DC":'GP_S_FSv2', 
            body['sku'].name=rawValue.hardwareConfig=='Gen5'?`GP_S_Gen5_${rawValue.vCores }`:rawValue.hardwareConfig=='DC-series'?`GP_S_DC_${rawValue.vCores }`:`GP_S_FSv2_${rawValue.vCores }`
            body['properties']['minCapacity']= rawValue.minVCores
            if(rawValue.autoPauseDelay){
              let minutes=(rawValue.days*1440) + (rawValue.hrs*60) +rawValue.mins
              body['properties']['autoPauseDelay']= minutes
            }else body['properties']['autoPauseDelay']=-1
          }
        }else if(rawValue.serviceTier=='Hyperscale'){
              let body=this.sqlDatabaseData
              body['sku']={
                "name": rawValue.hardwareConfig=='Gen5'?'HS_Gen5':"HS_DC", 
                "tier": "Hyperscale", 
                "family": rawValue.hardwareConfig=='Gen5'?'Gen5':"DC", 
                "capacity": rawValue.vCores 
              }
              body['properties']['highAvailabilityReplicaCount']= rawValue.replicas
        }else if(rawValue.serviceTier=='BusinessCritical'){
          // body={ 
          //   "id": this.sqlDatabaseData.id, 
          //   "location":  this.sqlDatabaseData.location, 
          //   "properties": { 
          //       "maxSizeBytes": `${size}`, 
          //       "collation": this.sqlDatabaseData?.properties?.collation, 
          //       "zoneRedundant":  rawValue.databaseZone, 
          //       "licenseType": this.sqlDatabaseData?.properties?.licenseType, 
          //       "highAvailabilityReplicaCount": 1, 
          //       "readScale": rawValue.readScaleOut?'Enabled':'Disabled',
          //       "requestedBackupStorageRedundancy": rawValue.backupStorage
          //   }, 
          //   "sku": { 
          //       "name": rawValue.hardwareConfig=='Gen5'?`BC_Gen5_${rawValue.vCores }`:rawValue.hardwareConfig=='DC-series'?`BC_DC_${rawValue.vCores }`:`BC_FSv2_${rawValue.vCores }`,
          //       "tier": "BusinessCritical" 
          //   } 
          // } 
          body['properties'].maxSizeBytes= size
          body['properties'].zoneRedundant= rawValue.databaseZone
          body['properties'].readScale= rawValue.readScaleOut?'Enabled':'Disabled'
          body['properties'].requestedBackupStorageRedundancy= rawValue.backupStorage
          body['sku']={
            "name": rawValue.hardwareConfig=='Gen5'?'BC_Gen5':"BC_DC", 
            "tier": "BusinessCritical", 
            "family": rawValue.hardwareConfig=='Gen5'?'Gen5':"DC", 
            "capacity": rawValue.vCores 
          }
          
        }else if(rawValue.serviceTier=='ElasticPool'){
          body['sku'].name='ElasticPool'
          body['sku'].capacity=0
          body['properties'].maxSizeBytes= size
          body['properties'].requestedBackupStorageRedundancy= rawValue.backupStorage
        }else if(rawValue.serviceTier=='Basic'){
          size= rawValue.dataMaxSize<1 ? (rawValue.dataMaxSize*10000000000)/9.5367431640625 : size
          body = { 
            "id": this.sqlDatabaseData.id, 
            "location": this.sqlDatabaseData.location, 
            "properties": { 
                "maxSizeBytes": `${size}`, 
                "collation": this.sqlDatabaseData?.properties?.collation, 
                "zoneRedundant": rawValue.databaseZone, 
                "licenseType": this.sqlDatabaseData?.properties?.licenseType, 
                "highAvailabilityReplicaCount": 0, 
                "readScale": this.sqlDatabaseData?.properties?.readScale, 
                "requestedBackupStorageRedundancy": rawValue.backupStorage
            }, 
            "sku": { 
                "name": 'Basic', 
                "tier": "Basic" 
             } 
            }
          // body['properties'].maxSizeBytes= size
          // body['properties'].requestedBackupStorageRedundancy= rawValue.backupStorage
          // body['kind']="v12.0,user",
          // body["sku"]= { 
          //   "name": "Basic", 
          //   "tier": "Basic", 
          //   "capacity": 5 
          // }
        }else if(rawValue.serviceTier=='Standard'){
          size= rawValue.dataMaxSize<1 ? (rawValue.dataMaxSize*10000000000)/9.5367431640625 : size
          let skuName=this.dtuList.filter(e=>e.id==rawValue.dtu)
          body = { 
          "id": this.sqlDatabaseData.id, 
          "location": this.sqlDatabaseData.location, 
          "properties": { 
              "maxSizeBytes": `${size}`, 
              "collation": this.sqlDatabaseData?.properties?.collation, 
              "zoneRedundant": rawValue.databaseZone, 
              "licenseType": this.sqlDatabaseData?.properties?.licenseType, 
              "highAvailabilityReplicaCount": 0, 
              "readScale": this.sqlDatabaseData?.properties?.readScale, 
              "requestedBackupStorageRedundancy": rawValue.backupStorage
          }, 
          "sku": { 
              "name": skuName[0].tier, 
              "tier": "Standard" 
           } 
          }
          // body['properties'].maxSizeBytes= size
          // body['properties'].requestedBackupStorageRedundancy= rawValue.backupStorage
          // body['kind']="v12.0,user",
          // body['sku']={
          //   "name": 'Standard', 
          //   "tier": "Standard", 
          //   "capacity": rawValue.dtu 
          // }
        }else{
          size= rawValue.dataMaxSize<1 ? (rawValue.dataMaxSize*10000000000)/9.5367431640625 : size
          let skuName=this.dtuList.filter(e=>e.id==rawValue.dtu)
          body = { 
            "id": this.sqlDatabaseData.id, 
            "location": this.sqlDatabaseData.location, 
            "properties": { 
                "maxSizeBytes": `${size}`, 
                "collation": this.sqlDatabaseData?.properties?.collation, 
                "zoneRedundant": rawValue.databaseZone, 
                "licenseType": this.sqlDatabaseData?.properties?.licenseType, 
                "highAvailabilityReplicaCount": 1, 
                "readScale": rawValue.readScaleOut?'Enabled':'Disabled', 
                "requestedBackupStorageRedundancy": rawValue.backupStorage
            }, 
            "sku": { 
                "name": skuName[0].tier, 
                "tier": "Premium" 
             } 
            }
          // body['properties'].maxSizeBytes= size
          // body['kind']="v12.0,user",
          // body['properties'].zoneRedundant= rawValue.databaseZone
          // body['properties'].readScale= rawValue.readScaleOut?'Enabled':'Disabled'
          // body['properties'].requestedBackupStorageRedundancy= rawValue.backupStorage
          // body['sku']={
          //   "name": 'Premium', 
          //   "tier": "Premium", 
          //   "capacity": rawValue.dtu 
          // }
        }
        await this.updateSQLDatabaseData(this.selectedRow,body,'computeStorage')
        this.saveDisable=false 
        break;
      }
      case'firewallSetting':{
        let body=this.sqlDatabaseData
        let rawValue = this.currentForm.getRawValue()
        // let subForm= this.subSideSheetForm.getRawValue()
          switch(subType){
            case'firewallRule':{
              this.sqlServerData['properties'].minimalTlsVersion=rawValue.minTLSversion
              this.sqlServerData['properties'].publicNetworkAccess=rawValue.publicNetworkAccess?'Disabled':'Enabled'
              await this.updateSQLServerData(this.selectedRow,this.sqlServerData,'firewallRule')
             if(!rawValue.publicNetworkAccess){
              rawValue.firewallRuleArray.map(async e => {
                if( e && e.name && e.startIP  && e.endIP){
                  let index=this.sqlServerFirewallRule.findIndex(x=>e.name==x.name)
                  if(index==-1){
                    let body={ 
                      "properties": { 
                          "startIpAddress": e.startIP, 
                          "endIpAddress": e.endIP, 
                      }, 
                      "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Sql/servers/${this.selectedRow[4]}/firewallRules/${e.name}`, 
                      "name": e.name, 
                      "type": "Microsoft.Sql/servers/firewallRules" 
                    } 
                    await this.updateFirewallRule(this.selectedRow,body)
                  }
                }
              });
                if(rawValue.allowAzure){
                  let body=  {     
                  "name": "AllowAllWindowsAzureIps", 
                  "properties": { 
                      "startIpAddress": "0.0.0.0", 
                      "endIpAddress": "0.0.0.0" 
                  } 
                  } 
                    await this.updateFirewallRule(this.selectedRow,body)
                }else{
                    await this.deleteFirewallRule(this.selectedRow,'AllowAllWindowsAzureIps')
                }
             }
              let connectionPolicy={ 
                "location": this.selectedRow[3], 
                "properties": {
                    "connectionType": rawValue.connectionPolicy
                }, 
                "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Sql/servers/${this.selectedRow[4]}/connectionPolicies/Default`, 
                "name": "Default", 
                "type": "Microsoft.Sql/servers/connectionPolicies" 
            
              } 
              await this.updateConnectionPolicy(this.selectedRow,connectionPolicy)
              this.saveDisable=false
              break;
            }
            case'outboundNetworking':{
              let subForm= this.subSideSheetForm.getRawValue()
              this.sqlServerData['properties'].restrictOutboundNetworkAccess=subForm.restrictOutbound?'Enabled':'Disabled'
              // this.sqlServerData['properties'].fullyQualifiedDomainName=this.domainNameList[0].name
              await this.updateSQLServerData(this.selectedRow,this.sqlServerData,'outboundNetworking')
              this.saveBtnEnable=false
              break;
            }
            case'addVNetRule':{
              let subForm= this.subSideSheetForm.getRawValue()
              let body={
                "properties": {
                "virtualNetworkSubnetId": subForm.subnet,
                "ignoreMissingVnetServiceEndpoint": subForm.ignoreEndpoint
                }
                }
                if(!subForm.ignoreEndpoint && this.endpointStatus!='Enabled'){
                  let subnetData={subnetName:subForm.subnet.split('/')[10],vnetName:subForm.subnet.split('/')[8]}
                  await this.getSubnetData('',this.selectedRow,subnetData)
                  this.subnetData.properties['serviceEndpoints']=[{service:'Microsoft.Sql'}]
                  await this.UpdateSubnetData(this.selectedRow,this.subnetData,'',subnetData)
                }
                await this.updateVNETRule(this.selectedRow,body,'addVNetRule')
              this.saveBtnEnable=false
              break;
            }
          }
        break;
      }
      case'copyDB':{
        // let rawValue = this.currentForm.getRawValue()
        let form = this.subSideSheetForm.getRawValue()
        let sku:string=''
        if(this.sqlDatabaseData.sku.name=='ElasticPool'){
          let tierId=this.sqlDatabaseData.sku.tier=='GeneralPurpose'?'GP':form.tier=='Hyperscale'?'HS':'BC'
          sku=`${tierId}_${form.hardwareConfig.split('-')[0]}_${form.vCore}`
        }else{
          sku=`${this.sqlDatabaseData.sku.name.split('_')[0]}_${form.hardwareConfig.split('-')[0]}_${form.vCore}`
        }
        let body={
          "properties": {
              "mode": "incremental",
              "debugSetting": {
                  "detailLevel": "none"
              },
              "templateLink": {
                  "uri": "https://a3sarmtemplate.blob.core.windows.net/armtemplates/SQLDB_actionItems/copyDatabase.json",
                  "contentVersion": "1.0.0.0"
              },
              "parameters": {
                  "databaseName": {
                      "Value": form.dbName
                  },
                  "isElasticPool": {
                      "Value": form.sqlElasticPool
                  },
                  "elasticPoolId": {
                      "value": form.sqlElasticPool ? form.elasticPool :''
                  },
                  "requestedBackupStorageRedundancy": {
                      "Value": form.backupStorage
                  },
                  "serverName": {
                      "Value": form.serverName
                  },
                  "location": {
                      "Value": this.selectedRow[3]
                  },
                  "sourceDatabaseId": {
                      "Value": this.sqlDatabaseData.id
                  },
                  "skuName": {
                      "Value": sku
                  },
                  "tier": {
                      "Value": this.sqlDatabaseData.sku.tier
                  },
                  "zoneRedundant": {
                      "Value": false
                  },
                  "licenseType": {
                      "Value": "LicenseIncluded"
                  },
                  "readScaleOut": {
                      "Value": "Disabled"
                  },
                  "numberOfReplicas": {
                      "Value": 0
                  },
                  "minCapacity": {
                      "Value": ""
                  },
                  "autoPauseDelay": {
                      "Value": ""
                  },
                  "secondaryType": {
                      "Value": ""
                  }
              }
          }
      }
      await this.updateSQLDBCopy(this.selectedRow,body,'copyDB')
      this.saveDisable=false
      break;
      }
      case'restoreDB':{
        let form = this.subSideSheetForm.getRawValue()
        let sku:string=''
        if(form.tier=='GeneralPurpose' || form.tier=='Hyperscale' || form.tier=='BusinessCritical'){
          let tierId=form.tier=='GeneralPurpose'?'GP':form.tier=='Hyperscale'?'HS':'BC'
          sku=`${tierId}_${form.hardwareConfig.split('-')[0]}_${form.vCore}`
        }else{
          sku=`${form.tier}__${form.dtu}`
        }
        let body={
          "properties": {
            "mode": "incremental",
            "debugSetting": {
                "detailLevel": "none"
            },
            "templateLink": {
                "uri": "https://a3sarmtemplate.blob.core.windows.net/armtemplates/SQLDB_actionItems/restoreDatabase.json",
                "contentVersion": "1.0.0.0"
            },
            "parameters": {
                "databaseName": {
                    "Value": form.dbName
                },
                "dateTimeString": {
                    "Value": form.restoreDate
                },
                "isElasticPool": {
                    "Value": form.sqlElasticPool
                },
                "elasticPoolId": {
                    "Value": form.sqlElasticPool ? form.elasticPool :''
                },
                "requestedBackupStorageRedundancy": {
                    "Value": form.backupStorage
                },
                "serverName": {
                    "Value": form.serverName
                },
                "location": {
                    "Value": this.selectedRow[3]
                },
                "sourceDatabaseId": {
                    "Value": this.sqlDatabaseData.id
                },
                "skuName": {
                    "Value": sku
                },
                "tier": {
                    "Value": form.tier
                },
                "zoneRedundant": {
                    "Value": false
                },
                "licenseType": {
                    "Value": "LicenseIncluded"
                },
                "readScaleOut": {
                    "Value": "Disabled"
                },
                "numberOfReplicas": {
                    "Value": 0
                },
                "minCapacity": {
                    "Value": ""
                },
                "autoPauseDelay": {
                    "Value": ""
                }
            }
          }
      }
      await this.updateSQLDBRestore(this.selectedRow,body,'restoreDB')
      this.saveDisable=false
      break;
      }
  }
  }
  
  //validation
  dataSizeValidation(event){
    let value=event.target.value;
    this.saveDisable=true
    this.inputError['InvalidSizeRange']=false; 
    this.inputError['InValidSize']=false;
    let vCore = this.currentForm.get('vCores').value
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError['InValidSize']=true;
      }
      if( (vCore==2 || vCore==4) && (value>1024 || value<1)){
        this.inputError['InvalidSizeRange']=true;
        this.dataSizeRange=1024
      }
      else if( (vCore==6 || vCore==8 || vCore==10) && (value>1536 || value<1)){
        this.inputError['InvalidSizeRange']=true;
        this.dataSizeRange=1536
      }
      else if( (vCore==12 || vCore==14 || vCore==16 || vCore==18 || vCore==20) && (value>3072 || value<1)){
        this.inputError['InvalidSizeRange']=true;
        this.dataSizeRange=3072
      }
      else if( (vCore>20) && (value>4096 || value<1)){
        this.inputError['InvalidSizeRange']=true;
        this.dataSizeRange=4096
      } else if( (vCore==1) && (value>512 || value<1)){
        this.inputError['InvalidSizeRange']=true;
        this.dataSizeRange=512
      }
    }
  }
  ruleNameValidation(event){
    let value=event.target.value;
    this.saveDisable=true
    this.inputError['invalidRuleName']=false;
    if(value=='' || value.length>128 || value[value.length-1]=='.' || (/[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/).test(value)) return     this.inputError['invalidRuleName']=true
    this.firewallRuleArrayList.controls.forEach(e=>{
      if(e['name']==value) return  this.inputError['invalidRuleName']=true
    })
  }
  validVNETRuleName(event,limitNum,subType?:any){
    let value =event.target.value;
    this.saveBtnEnable=true
    this.inputError[subType+'NameEmpty'] = false;
    this.inputError[subType+'NameLen'] = false;
    this.inputError[subType+'NameInvalid'] = false;
   if(value==''){
    this.inputError[subType+'NameEmpty'] = true;
   }
      if (value.length > limitNum || value.length<2) {
        this.inputError[subType+'NameLen'] = true;
        return;
      }
      if((value.length==1 && !(/^[A-Za-z0-9]*$/.test(value))) || !(/^[A-Za-z0-9_]+$/.test(value[value.length-1]))){
        this.inputError[subType+'NameInvalid']=true;
      }
  }
  validDBName(event,limitNum,subType?:any){
    let value =event.target.value;
    this.saveDisable=true
    this.inputError[subType+'NameEmpty'] = false;
    this.inputError[subType+'NameLen'] = false;
    this.inputError[subType+'NameInvalid'] = false;
    this.inputError[subType+'NameDup'] = false;
   if(value==''){
    this.inputError[subType+'NameEmpty'] = true;
   }
      if (value.length > limitNum) {
        this.inputError[subType+'NameLen'] = true;
        return;
      }
      if((/[!"`'#%&,:;<>=@{}~\$\*\/\\\?\^\|]+/).test(value) || value[value.length-1]=='.' || value[value.length-1]==' '){
        this.inputError[subType+'NameInvalid']=true;
      }
      this.data.forEach(e=>{
        if(e[4]==this.selectedRow[4] && value.toLowerCase()==e[0].toLowerCase()){
          this.inputError[subType+'NameDup']=true;
        }
      })
  }
  validRestoreTime(event){
    let value=event.target.value;
    this.currentTime= new Date()
    let currentTimeFormat=this.a3sDateTimeFormat.transform(this.currentTime)
    let startTimeFormatSplit=currentTimeFormat.split(', ')
    let restoreDate = this.subSideSheetForm.get('restoreDate').value
    let format=this.a3sDateTimeFormat.transform(restoreDate)
    if(value<startTimeFormatSplit[1] || (restoreDate<this.currentTime && format>this.subSideSheetForm.get('earliestPoint').value)) {
      return this.inputError.invalidRestore=true
    }else{
      return this.inputError.invalidRestore=false
    }
  }
  ipValidation(event,index,type?:any){
      let value=event.target.value;
      this.saveDisable=true
      this.inputError['invalidIP']=false;//sourceInvalidArgument
  
      if(value&&value!=""){
      const splitArComma=value.split(',');
      let ipV4Value;
      let tempArr=[];
      for(let i=0;i<splitArComma.length;i++){
        if(!splitArComma[i]){
         // let inputError="Invalid argument: 'A non-null address range is required.'";
          this.inputError['invalidIP']=true;
          break;
        }else{
          const splitArr=splitArComma[i].split('/');
          if(splitArr.length>1&&!splitArr[1]){
            //Maliform
            this.inputError['invalidIP']=true;
          }else{
            ipV4Value=splitArr[0];
          }
          
          try{
            let ipV4Obj=new this.ipV4(ipV4Value);
           // item.VNetAdrspc=false;
            let vlIPRange;
            if(splitArr.length>1){
            try{
              vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
              //item.VNetAdrspc=false;
              if(vlIPRange.input!=splitArComma[i]){
                //item.notValidCIDR=true;
                //item.validCIDRValue=vlIPRange.input;
                this.inputError['invalidIP']=true;
                break;
              }
            }catch(e){
              //item.VNetAdrspc=true;
              this.inputError['invalidIP']=true;
              break;
            }
          }        
          }catch(e){
         //   item.VNetAdrspc=true;
         this.inputError['invalidIP']=true;
            break;
          }
        }
      }
    }
  // if(index!=''){
  //   if(!this.firewallRuleArrayList.controls.length) return;
  //   let data = this.firewallRuleArrayList.controls.some((e,i)=> {
  //     if(index !== i && e.get('value').value == value && e.get('value').value != '' ){
  //       this.inputError['dnsUnique']=true
  //     }
  //     // else{ this.inputError['dnsUnique']=false}
  //     else if(index !== i &&e.get('value').value != ''){
  //       this.inputError['dnsUnique']=false
  //     }
  //     // return index !== i && e.get('value').value == this.addressSpaceList.at(index).get('value').value
  //   })
  // }
}


  //API's
  getSubscription(){
    return this.subscriptionList=[{
      name:this.getProjectName(this.selectedRow[2]),
      id:this.selectedRow[2],
    }]
  }
  getRgBySubscription(){
    let body={
        subscriptionId : this.selectedRow[2],
    }
    return this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
        console.log('Resource Group list ',res);
        this.resourceGroupsList = res && res.value ? res.value : []
    })
  }

  getSQLDatabaseData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let serverName=data[4]
    let sqlDatabaseName = data[0]
    return this.util.handleRequest('get','a3s_management_getSQLDatabaseData',[subsId,rgName,serverName,sqlDatabaseName],null,null,null,true).then(async res=>{
      this.sqlDatabaseData= res.body
      console.log('SQL Database Data',this.sqlDatabaseData)
    })
  }

  updateSQLDatabaseData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let serverName=data[4]
    let sqlDatabaseName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateSQLDatabaseData',[subsId,rgName,serverName,sqlDatabaseName],body,null,null,true).then(res=>{
      console.log('SQL Database updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  deleteSqlDatabaseData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],serverName = data[4],sqlDatabaseName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteSQLDatabaseData',[subscriptionId,resourceGroupName,serverName,sqlDatabaseName],null,null,null,true).then(async res=>{
      console.log('SQL Database Data Deleted',res)
      this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
  }

  getSQLServerData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let serverName=data[4]
    return this.util.handleRequest('get','a3s_management_getSQLServerData',[subsId,rgName,serverName],null,null,null,true).then(async res=>{
      this.sqlServerData= res.body
      console.log('SQL Server Data',this.sqlServerData)
    })
  }
  updateSQLServerData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let serverName=data[4]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateSQLServerData',[subsId,rgName,serverName],body,null,null,true).then(res=>{
      console.log('SQL Database updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  getSQLServerFirewallList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let serverName=data[4]
    return this.util.handleRequest('get','a3s_management_getSQLServerFirewallList',[subsId,rgName,serverName],null,null,null,true).then(async res=>{
      this.sqlServerFirewallRule= res.body.value
      console.log('SQL Server Firewall server Data',this.sqlServerFirewallRule)
    })
  }
  updateFirewallRule(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let serverName=data[4]
    let ruleName=body.name
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateFirewallRule',[subsId,rgName,serverName,ruleName],body,null,null,true).then(res=>{
      console.log('SQL Firewall Rule updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  deleteFirewallRule(data,body,type?:any,index?:any){
    let subscriptionId = data[2],resourceGroupName = data[1],serverName = data[4],ruleName = body
    type?this.util.info(`Deleting ${ruleName}...`): false
    return this.util.handleRequest('delete','a3s_management_deleteFirewallRule',[subscriptionId,resourceGroupName,serverName,ruleName],null,null,null,true).then(async res=>{
      console.log('Deleted Firewall Rule',res)
      this.closeModal()
      type?this.util.success(ruleName + ' deleted successfully!'):false
      let i=this.sqlServerFirewallRule.findIndex(x=>body==x.name)
      this.sqlServerFirewallRule.splice(i, 1);
  },err=>{
    console.log(err)
    type?this.util.error(ruleName +' failed to deleted.'):false
  })
  }
  getSQLServerVNetList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let serverName=data[4]
    return this.util.handleRequest('get','a3s_management_getSQLServerVNetList',[subsId,rgName,serverName],null,null,null,true).then(async res=>{
      this.sqlServerVNEetRule= res.body.value
      console.log('SQL Server VNet Rule Data',this.sqlServerVNEetRule)
    })
  }
  updateVNETRule(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let serverName=data[4]
    let ruleName=this.subSideSheetForm.get('ruleName').value
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateSQLServerVNETRule',[subsId,rgName,serverName,ruleName],body,null,null,true).then(async res=>{
      console.log('SQL VNET Rule updated',res)
      this.showSubSidesheet = false
      await this.getSQLServerVNetList(this.selectedRow)
      await this.getVNetList(this.selectedRow)
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  deletVNETRule(data,body,type?:any,index?:any){
    let subscriptionId = data.id.split('/')[2],resourceGroupName = data.id.split('/')[4],serverName = data.id.split('/')[8],ruleName = body
    type?this.util.info(`Deleting ${ruleName}...`): false
    return this.util.handleRequest('delete','a3s_management_deletVNETRule',[subscriptionId,resourceGroupName,serverName,ruleName],null,null,null,true).then(async res=>{
      console.log('Deleted Firewall Rule',res)
      this.closeModal()
      type?this.util.success(ruleName + ' deleted successfully!'):false
      let i=this.sqlServerVNEetRule.findIndex(e=>{e.name==body})
      this.sqlServerVNEetRule.splice(i, 1)
  },err=>{
    console.log(err)
    type?this.util.error(ruleName +' failed to deleted.'):false
  })
  }
  getVNetList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    return this.util.handleRequest('get','a3s_management_getVNetList',[subsId,rgName],null,null,null,true).then(async res=>{
      this.vNetList= res.body.value
      console.log('VNet List Data',this.vNetList)
    })
  }
  getSQLServerConnectionPolicyData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let serverName=data[4]
    return this.util.handleRequest('get','a3s_management_getSQLServerConnectionPolicyData',[subsId,rgName,serverName],null,null,null,true).then(async res=>{
      this.currentForm.get('connectionPolicy').setValue(res.body.properties.connectionType)
      console.log('SQL Server Connection Policy Data',res.body)
    })
  }
  updateConnectionPolicy(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let serverName=data[4]
    return this.util.handleRequest('post','a3s_management_changeConnectionPolicy',[subsId,rgName,serverName],body,null,null,true).then(res=>{
      console.log('SQL Firewall Rule updated Data',res)
    },err=>{
      console.log(err)
    })
  }
  getSQLServerAllowAzureIP(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let serverName=data[4]
    return this.util.handleRequest('get','a3s_management_getSQLServerAllowAzureIP',[subsId,rgName,serverName],null,null,null,true).then(async res=>{
      console.log('SQL Server Allow Azure IP Data',res.body)
       this.currentForm.get('allowAzure').setValue(true)
      },err=>{
        this.currentForm.get('allowAzure').setValue(false)
      })
  }

  getElasticPoolList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let serverName=data[4]
    return this.util.handleRequest('get','a3s_management_getSQLServerElasticPoolList',[subsId,rgName,serverName],null,null,null,true).then(async res=>{
      console.log('SQL Server Elastic Pool List',res.body)
      this.elasticPoolList =res.body.value
      },err=>{
        console.log(err)
      })
  }

  updateSQLDBCopy(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    this.currentTime= new Date()
    let timeStamp=data[4]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateSQLDBCopy',[subsId,rgName,timeStamp],body,null,null,true).then(res=>{
      console.log('SQL Firewall Rule updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  updateSQLDBRestore(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    this.currentTime= new Date()
    let timeStamp=data[4] // this.currentTime //this.a3sDateTimeFormat.transform(this.currentTime)
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateSQLDBRestore',[subsId,rgName,timeStamp],body,null,null,true).then(res=>{
      console.log('SQL Firewall Rule updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  async getSubnetData(type,data,subData?:any){ 
    let subsId = data[2]
    let rgName = data[1]
    let subnetName =  subData.subnetName 
    let vnetName = subData.vnetName
    return this.util.handleRequest('get','a3s_management_getSubnetData',[subsId,rgName,vnetName,subnetName],null,null,null,true).then(res=>{
      this.subnetData = res.body
      console.log('Subnet Data',this.subnetData)
    })
  }

  UpdateSubnetData(data,body,type,subData?:any){
    let subsId = data[2]
    let rgName =  data[1]
    let subnetName =  subData.subnetName 
    let vnetName = subData.vnetName
    this.util.handleRequest('post','a3s_management_updateSubnetData',[subsId,rgName,vnetName,subnetName],body,null,null,true).then(res=>{
      console.log('Subnet Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.util.success(this.getSuccesText(type))
    },err=>{
      // this.util.error(this.getFailureText(type))
    })
  }
  
}
