import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-application-gateway-actions',
  templateUrl: './application-gateway-actions.component.html',
  styleUrls: ['./application-gateway-actions.component.scss']
})
export class ApplicationGatewayActionsComponent implements OnInit {
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("appGatewayDetailsTemplate") appGatewayDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  @ViewChild("notDeleteTemplate") notDeleteTemplate: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    instanceNameEmpty:false,
    instanceInvalidNumber:false,
    instanceInvalidRange:false,
    minInstanceNameEmpty:false,
    minInstanceInvalidNumber:false,
    minInstanceInvalidRange:false,
    maxInstanceNameEmpty:false,
    maxInstanceInvalidNumber:false,
    maxInstanceInvalidRange:false,
    InvalidMaxRange:false,
    maxReqSizeNameEmpty:false,
    maxReqSizeInvalidNumber:false,
    maxReqSizeInvalidRange:false,
    fileLimitNameEmpty:false,
    fileLimitInvalidNumber:false,
    fileLimitInvalidRange:false,
    frontendNameEmpty:false,
    frontendNameMaxLen:false,
    frontendNameExist:false,
    frontendNameValid:false,
    portNameEmpty:false,
    portInvalidNumber:false,
    portInvalidRange:false,
    portInvalid:false,
    drainTimeOutNameEmpty:false,
    drainTimeOutInvalidNumber:false,
    drainTimeOutInvalidRange:false,
    reqTimeOutNameEmpty:false,
    reqTimeOutInvalidNumber:false,
    reqTimeOutInvalidRange:false,
    invalidPath:false,
    badGatewayInvalidURL:false,
    forbiddenInvalidURL:false,
    singleHostNameEmpty:false,
    singleHostInvalidName:false,
    multiHostNameEmpty:false,
    multiHostInvalidName:false,
    pathEmpty:false,
    thresholdNameEmpty:false,
    thresholdInvalidNumber:false,
    thresholdInvalidRange:false,
    intervalNameEmpty:false,
    intervalInvalidNumber:false,
    intervalInvalidRange:false,
    priorityNameEmpty:false,
    priorityInvalidNumber:false,
    priorityInvalidRange:false,
  }
  selectedType: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;
  selectedData: any;



  tierList=[
    {id:'Standard_v2',name:'Standard V2'},
    {id:'WAF_v2',name:'WAF V2'},
  ]
  fieldList=[
    {id:'RequestHeaderNames',name:'Request header name'},
    {id:'RequestCookieNames',name:'Request cookie name'},
    {id:'RequestAttributeNames',name:'Request attribute name'},
  ]
  operatorList=[
    {id:'StartsWith',name:'Starts with'},
    {id:'EndsWith',name:'Ends with'},
    {id:'Equals',name:'Equals'},
    {id:'Contains',name:'Contains'},
    {id:'EqualsAny',name:'Equals any'},
  ]
  targetTypeList=[
    {id:'IP address or FQDN',name:'IP address or FQDN'},
    {id:'Virtual machine',name:'Virtual machine'},
    {id:'VMSS',name:'VMSS'},
    {id:'App Services',name:'App Services'},
  ]
  redirectionTypeList=[
    {id:'Permanent',name:'Permanent'},
    {id:'Temporary',name:'Temporary'},
    {id:'Found',name:'Found'},
    {id:'See other',name:'See other'},
  ]
  applicationGatewayData: any;
  currentSubSheet: any;
  isEdit: boolean;
  probeList: any;
  frontendIPList=[
    {id:'Public',name:'Public'},
  ];
  currentTab: any;
  currentSheet: any;
  backendTargetList: any;
  backendSettingsList: any;
  listenerList: any;
  pathBasedRulesList: any[];
  redirectRulesList: any[];
  listenerListRules: any;
  prevSideSheet: any;


  constructor(public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }
  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteAppGatewayData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteAppGatewayData')
      }
      case 'deleteBackendPool': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteBackendPool')
      }
      case 'deleteBackendSetting': {
        this.closeModal()
        if(this.applicationGatewayData?.properties?.backendHttpSettingsCollection.length==1){
          return this.openModal(this.notDeleteTemplate,data,'deleteBackendSetting')
        }else return this.openModal(this.deleteConfirmPopup,data,'deleteBackendSetting')
      }
      case 'deleteListeners': {
        this.closeModal()
        if(this.applicationGatewayData?.properties?.httpListeners.length==1){
          return this.openModal(this.notDeleteTemplate,data,'deleteListeners')
        }else return this.openModal(this.deleteConfirmPopup,data,'deleteListeners')
      }
      case 'deleteRoutingRule': {
        this.closeModal()
        if(this.applicationGatewayData?.properties?.requestRoutingRules.length==1){
          return this.openModal(this.notDeleteTemplate,data,'deleteRoutingRule')
        }else return this.openModal(this.deleteConfirmPopup,data,'deleteRoutingRule')
      }
      case 'deleteProbe': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteProbe')
      }
    }
  }
    // Trigger action of the Connection
  
  async openSidesheet(type,data?:any,subType?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "configuration" : {
        this.closeModal()
        this.currentForm = this.util.getForm('appGatewayConfig')
        await this.getAppGatewayConfig(this.selectedRow)
        await this.setDefaultValue('configuration')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "webAppFirewall" : {
        this.closeModal()
        this.currentForm = this.util.getForm('appGatewayWebAppFirewall')
        await this.getAppGatewayConfig(this.selectedRow)
        await this.setDefaultValue('webAppFirewall')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "backendPool" : {
        this.closeModal()
        await this.getAppGatewayConfig(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "backendSetting" : {
        this.closeModal()
        await this.getAppGatewayConfig(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "frontendIP" : {
        this.closeModal()
        await this.getAppGatewayConfig(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "listeners" : {
        this.closeModal()
        await this.getAppGatewayConfig(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "rules" : {
        this.closeModal()
        this.prevSideSheet = subType=='fromRules' ? 'Listener' : 'None'
        await this.getAppGatewayConfig(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "healthProbes" : {
        this.closeModal()
        await this.getAppGatewayConfig(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
    }
  }
  async openSubSideSheet(type,subType?:any){
    this.currentSubSheet = type
    switch(this.currentSubSheet){
      case "addBackendPool" : {
        this.currentForm = this.util.getForm('appGatewayBackendPool')
        this.isEdit= subType ? true:false
        await this.setDefaultValue('backendPool',subType)
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "addBackendSetting" : {
        this.currentForm = this.util.getForm('appGatewayBackendSetting')
        this.isEdit= subType ? true:false
        await this.setDefaultValue('backendSetting',subType)
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "addListeners" : {
        this.currentForm = this.util.getForm('appGatewayListeners')
        this.isEdit= subType ? true:false
        await this.setDefaultValue('listener',subType)
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "addRoutingRule" : {
        this.currentForm = this.util.getForm('appGatewayRoutingRule')
        this.isEdit= subType ? true:false
        await this.setDefaultValue('rules',subType)
        this.currentSheet='routingRules'
        this.currentTab = 'listener'
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "multipleTarget" : {
        this.subSideSheetForm = this.formBuilder.group({
          targetType:['backendPool'],
          path : [null,[Validators.required]],
          targetName : [null,[Validators.required]],
          backendTarget : [null],
          backendSetting:[null],
          redirectionType:['Permanent'],
          redirectionTarget : ['listener'],
          targetUrl : [null],
          targetListener : [null],
          includeQueryString : [true],
          includePath : [true],
        })
        this.currentSheet='addMultipleTargets'
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "addProbe" : {
        this.currentForm = this.util.getForm('appGatewayProbe')
        this.isEdit= subType ? true:false
        await this.setDefaultValue('probe',subType)
        this.currentSheet='addProbe'
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
    }
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteAppGatewayData' :return this.deleteAppGatewayData(this.selectedData)
      case 'deleteBackendPool' :{
        let index=this.applicationGatewayData.properties.backendAddressPools.findIndex(e=>e.name==this.selectedData.name)
        this.applicationGatewayData?.properties?.backendAddressPools.splice(index,1)
        return this.updateAppGatewayData(this.selectedRow,this.applicationGatewayData,'deleteBackendPool')
      }
      case 'deleteBackendSetting' :{
        let index=this.applicationGatewayData.properties.backendHttpSettingsCollection.findIndex(e=>e.name==this.selectedData.name)
        this.applicationGatewayData?.properties?.backendHttpSettingsCollection.splice(index,1)
        return this.updateAppGatewayData(this.selectedRow,this.applicationGatewayData,'deleteBackendSetting')
      }
      case 'deleteListeners':{
        let index=this.applicationGatewayData.properties.httpListeners.findIndex(e=>e.name==this.selectedData.name)
        this.applicationGatewayData?.properties?.httpListeners.splice(index,1)
        return this.updateAppGatewayData(this.selectedRow,this.applicationGatewayData,'deleteListeners')
      }
      case 'deleteRoutingRule':{
        let index=this.applicationGatewayData.properties.requestRoutingRules.findIndex(e=>e.name==this.selectedData.name)
        this.applicationGatewayData?.properties?.requestRoutingRules.splice(index,1)
        return this.updateAppGatewayData(this.selectedRow,this.applicationGatewayData,'deleteRoutingRule')
      }
      case 'deleteProbe':{
        let index=this.applicationGatewayData.properties.probes.findIndex(e=>e.name==this.selectedData.name)
        this.applicationGatewayData?.properties?.probes.splice(index,1)
        return this.updateAppGatewayData(this.selectedRow,this.applicationGatewayData,'deleteProbe')
      }
    }
  }
  
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedData=row;
    // this.selectedRow=row;
    this.selectedType=type;
    // type=='appServicePopup'?this.getAppServicePlanData(row):false 
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  get exclusiveArrayList() {
    return this.currentForm.get('exclusiveArray') as FormArray;
  }
  removeExclusiveList(index){
    this.saveDisable=true
    this.exclusiveArrayList.controls.splice(index, 1);
  }
  addExclusiveArray(){
    this.exclusiveArrayList.push(this.util.getForm('addExclusiveArray'))
  }

  get backendTargetArrayList() {
    return this.currentForm.get('backendTargetArray') as FormArray;
  }
  removeBackendTargetList(index){
    this.saveDisable=true
    this.backendTargetArrayList.controls.splice(index, 1);
  }
  addBackendTargetList(){
    this.backendTargetArrayList.push(this.util.getForm('addbackendTargetArray'))
  }
  get hostNameArrayList() {
    return this.currentForm.get('hostNameArray') as FormArray;
  }
  removeHostNameList(index){
    this.saveDisable=true
    this.hostNameArrayList.controls.splice(index, 1);
  }
  addHostNameList(){
    this.hostNameArrayList.push(this.util.getForm('addHostNameArray'))
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteAppGatewayData':return 'Delete Application Gateway'
      case'deleteBackendPool':return 'Delete application gateway backend pool'
      case'deleteBackendSetting':return 'Delete application gateway backend setting'
      case'deleteListeners':return 'Delete application gateway Listener'
      case'deleteRoutingRule':return 'Delete Rule'
      case'deleteProbe':return 'Delete Health Probe'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteAppGatewayData':return `Are you sure you want to delete “${this.selectedData[0]}”?` 
      case'deleteBackendPool':return `Are you sure you want to delete “${this.selectedData.name}”?` 
      case'deleteBackendSetting':return `Are you sure you want to delete “${this.selectedData.name}”?` 
      default: return `Are you sure you want to delete “${this.selectedData.name}”?` 
    }
  }
  getMessage(){
    switch(this.selectedType){
      case'deleteBackendSetting':return 'To function, your application gateway needs at least one Backend setting connected to a rule. Please configure a new or existing rule to use a different Backend setting.'
      case'deleteListeners':return 'To function, your application gateway needs at least one Listener connected to a rule. Please configure a new or existing rule to use a different Listener.'
      case'deleteRoutingRule':return 'To function, your application gateway needs at least one Rule connected to a Backend pool. Please configure a new or existing Backend pool to use a different Rule.'
      default: return 
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteAppGatewayData':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      case 'connected': return 'st-success';
      case 'not connected': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  getSuccesText(type){
    switch(type){
      case'config':return `“${this.selectedRow[0]}” configuration updated successfully!`
      case'webAppFirewall':return `“${this.selectedRow[0]}” Web application firewall updated successfully!`
      case'addBackendPool':return `Backend Pool added successfully!`
      case'editBackendPool':return `Backend Pool updated successfully!`
      case'deleteBackendPool':return `“${this.selectedData.name}” deleted successfully!`
      case'addBackendSetting':return `Backend Setting added successfully!`
      case'editBackendSetting':return `Backend Setting updated successfully!`
      case'deleteBackendSetting':return `“${this.selectedData.name}” deleted successfully!`
      case'addListener':return `Listener added successfully!`
      case'editListener':return `Listener updated successfully!`
      case'deleteListeners':return `“${this.selectedData.name}” deleted successfully!`
      case'addRules':return `Routing rule added successfully!`
      case'editRules':return `Routing rule updated successfully!`
      case'deleteRoutingRule':return `“${this.selectedData.name}” deleted successfully!`
      case'addProbe':return `Health probes added successfully!`
      case'editProbe':return `Health probes updated successfully!`
      case'deleteProbe':return `“${this.selectedData.name}” deleted successfully!`
      default: return 'Changes saved successfully!'
    }
  }
  
  getFailureText(type){
    switch(type){
      case'config':return `“${this.selectedRow[0]}” configuration updating failed`
      case'webAppFirewall':return `Failed to update “${this.selectedRow[0]}” Web application firewall.`
      case'addBackendPool':return `Failed to add Backend Pool.`
      case'editBackendPool':return `Failed to update Backend Pool.`
      case'deleteBackendPool':return `“${this.selectedData.name}” failed to be deleted.`
      case'addBackendSetting':return `Failed to add Backend Setting.`
      case'editBackendSetting':return `Failed to update Backend Setting.`
      case'deleteBackendSetting':return `“${this.selectedData.name}” failed to be deleted.`
      case'addListener':return `Failed to add Listener.`
      case'editListener':return `Failed to update Listener.`
      case'deleteListeners':return `“${this.selectedData.name}” failed to be deleted.`
      case'addRules':return `Failed to add Routing rule.`
      case'editRules':return `Failed to update Routing rule.`
      case'deleteRoutingRule':return `“${this.selectedData.name}” failed to be deleted.`
      case'addProbe':return `Failed to add Health probes.`
      case'editProbe':return `Failed to update Health probes.`
      case'deleteProbe':return `“${this.selectedData.name}” failed to be deleted.`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'config':return `Updating “${this.selectedRow[0]}” configuration...`
      case'webAppFirewall':return `Updating “${this.selectedRow[0]}” Web application firewall...`
      case'addBackendPool':return `Adding Backend Pool...`
      case'editBackendPool':return `Updating Backend Pool...`
      case'deleteBackendPool':return `Deleting “${this.selectedData.name}”...`
      case'addBackendSetting':return `Adding Backend Setting...`
      case'editBackendSetting':return `Updating Backend Setting...`
      case'deleteBackendSetting':return `Deleting “${this.selectedData.name}”...`
      case'addListener':return `Adding Listener...`
      case'editListener':return `Updating Listener...`
      case'deleteListeners':return `Deleting “${this.selectedData.name}”...`
      case'addRules':return `Adding Routing rule...`
      case'editRules':return `Updating Routing rule...`
      case'deleteRoutingRule':return `Deleting “${this.selectedData.name}”...`
      case'addProbe':return `Adding Health probes...`
      case'editProbe':return `Updating Health probes...`
      case'deleteProbe':return `Deleting “${this.selectedData.name}”...`
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'appGatewayData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }
  
  invalidForm(type){
    switch(type){
      case'config':{
        return this.inputError.instanceNameEmpty|| this.inputError.instanceInvalidNumber|| this.inputError.instanceInvalidRange
        ||this.inputError.minInstanceNameEmpty || this.inputError.minInstanceInvalidNumber|| this.inputError.minInstanceInvalidRange|| this.inputError.maxInstanceNameEmpty
        ||this.inputError.maxInstanceInvalidNumber || this.inputError.maxInstanceInvalidRange|| this.inputError.InvalidMaxRange
      }
      case'webAppFirewall':{
        return this.inputError.maxReqSizeNameEmpty|| this.inputError.maxReqSizeInvalidNumber|| this.inputError.maxReqSizeInvalidRange
        ||this.inputError.fileLimitNameEmpty || this.inputError.fileLimitInvalidNumber|| this.inputError.fileLimitInvalidRange
      }
      case'backendPool':{
        return this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
      }
      case'backendSetting':{
        return this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
        || this.inputError.portNameEmpty|| this.inputError.portInvalidNumber|| this.inputError.portInvalidRange
        ||this.inputError.drainTimeOutNameEmpty || this.inputError.drainTimeOutInvalidNumber|| this.inputError.drainTimeOutInvalidRange|| this.inputError.reqTimeOutNameEmpty
        ||this.inputError.reqTimeOutInvalidNumber || this.inputError.reqTimeOutInvalidRange|| this.inputError.invalidPath|| this.inputError.instanceNameEmpty||
        (!this.probeList.length && this.currentForm.get('useProbe')?.value)
      }
      case'listeners':{
        return this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
        || this.inputError.portNameEmpty|| this.inputError.portInvalidNumber|| this.inputError.portInvalidRange
        ||this.inputError.badGatewayInvalidURL || this.inputError.forbiddenInvalidURL|| this.inputError.multiHostNameEmpty|| this.inputError.multiHostInvalidName
        ||this.inputError.singleHostNameEmpty || this.inputError.singleHostInvalidName|| this.inputError.portInvalid
      }
      case'rules':{
        if(this.currentSheet=='addMultipleTargets'){
           return this.subSideSheetForm.invalid || this.inputError.pathEmpty|| this.inputError.invalidPath|| ((this.subSideSheetForm.get('targetName')?.dirty ||  this.subSideSheetForm.get('targetName')?.touched) && !this.subSideSheetForm.get('targetName')?.value)
           ||((this.subSideSheetForm.get('targetUrl')?.dirty ||  this.subSideSheetForm.get('targetUrl')?.touched) && !this.subSideSheetForm.get('targetUrl')?.value) 
        }else{
          return this.currentForm.invalid|| this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
          || this.inputError.portNameEmpty|| this.inputError.portInvalidNumber|| this.inputError.portInvalidRange
          ||this.inputError.badGatewayInvalidURL || this.inputError.forbiddenInvalidURL|| this.inputError.multiHostNameEmpty|| this.inputError.multiHostInvalidName
          ||this.inputError.singleHostNameEmpty || this.inputError.singleHostInvalidName|| this.inputError.portInvalid || !this.listenerListRules.length
        }
    
      }
      case'probes':{
        return this.inputError?.frontendNameEmpty|| this.inputError?.frontendNameMaxLen || this.inputError?.frontendNameExist|| this.inputError?.frontendNameValid
        || this.inputError.portNameEmpty|| this.inputError.portInvalidNumber|| this.inputError.portInvalidRange
        ||this.inputError.intervalNameEmpty || this.inputError.intervalInvalidNumber|| this.inputError.intervalInvalidRange|| this.inputError.reqTimeOutNameEmpty
        ||this.inputError.reqTimeOutInvalidNumber || this.inputError.reqTimeOutInvalidRange||this.inputError.thresholdNameEmpty
        ||this.inputError.thresholdInvalidNumber || this.inputError.thresholdInvalidRange||((this.currentForm.get('codeMatch')?.dirty ||  this.currentForm.get('codeMatch')?.touched) && !this.currentForm.get('codeMatch')?.value )
        ||this.inputError.singleHostNameEmpty || this.inputError.singleHostInvalidName|| this.inputError.invalidPath || this.inputError.pathEmpty
      }
    }
  }

  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      instanceNameEmpty:false,
      instanceInvalidNumber:false,
      instanceInvalidRange:false,
      minInstanceNameEmpty:false,
      minInstanceInvalidNumber:false,
      minInstanceInvalidRange:false,
      maxInstanceNameEmpty:false,
      maxInstanceInvalidNumber:false,
      maxInstanceInvalidRange:false,
      InvalidMaxRange:false,
      maxReqSizeNameEmpty:false,
      maxReqSizeInvalidNumber:false,
      maxReqSizeInvalidRange:false,
      fileLimitNameEmpty:false,
      fileLimitInvalidNumber:false,
      fileLimitInvalidRange:false,
      frontendNameEmpty:false,
      frontendNameMaxLen:false,
      frontendNameExist:false,
      frontendNameValid:false,
      portNameEmpty:false,
      portInvalidNumber:false,
      portInvalidRange:false,
      portInvalid:false,
      drainTimeOutNameEmpty:false,
      drainTimeOutInvalidNumber:false,
      drainTimeOutInvalidRange:false,
      reqTimeOutNameEmpty:false,
      reqTimeOutInvalidNumber:false,
      reqTimeOutInvalidRange:false,
      invalidPath:false,
      badGatewayInvalidURL:false,
      forbiddenInvalidURL:false,
      singleHostNameEmpty:false,
      singleHostInvalidName:false,
      multiHostNameEmpty:false,
      multiHostInvalidName:false,
      pathEmpty:false,
      thresholdNameEmpty:false,
      thresholdInvalidNumber:false,
      thresholdInvalidRange:false,
      intervalNameEmpty:false,
      intervalInvalidNumber:false,
      intervalInvalidRange:false,
      priorityNameEmpty:false,
      priorityInvalidNumber:false,
      priorityInvalidRange:false,
    }
  }

  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'configuration':{
      this.saveDisable=true
      switch(subType){
        case 'capacityType':{
          let value = id 
          this.currentForm.get('capacityType').setValue(value)
          break;
        }
        case 'http2':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('http2').setValue(value)
          break;
        }
       }
       break;
      }
      case 'webAppFirewall':{
        this.saveDisable=true
        switch(subType){
          case 'wafMode':{
            let value = id 
            this.currentForm.get('wafMode').setValue(value)
            break;
          }
          case 'inspectReqBody':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('inspectReqBody').setValue(value)
            break;
          }
          case 'wafStatus':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('wafStatus').setValue(value)
            break;
          }
         }
         break;
        }
      case 'backendPool':{
        this.saveDisable=true
        switch(subType){
          case 'poolTarget':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('poolTarget').setValue(value)
            break;
          }
          }
          break;
        }
      case 'backendSetting':{
        this.saveDisable=true
        switch(subType){
          case 'cookieAffinity':{
            let value = id == 'yes' ? true : false 
            this.currentForm.get('cookieAffinity').setValue(value)
            this.currentForm.get('affinityName').setValue('ApplicationGatewayAffinity')
            break;
          }
          case 'connectionDraining':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('connectionDraining').setValue(value)
            this.currentForm.get('drainTimeOut').setValue(60)
            break;
          }
          case 'newHost':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('newHost').setValue(value)
            break;
          }
          case 'hostOverRide':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('hostOverRide').setValue(value)
            break;
          }
          case 'useProbe':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('useProbe').setValue(value)
            break;
          }
          }
          break;
        }
      case 'listener':{
        this.saveDisable=true
        switch(subType){
          case 'listenerType':{
            let value = id 
            this.currentForm.get('listenerType').setValue(value)
            if(value=='Basic'){
              this.inputError['singleHostNameEmpty']=false
              this.inputError['singleHostInvalidName']=false
              this.inputError['multiHostNameEmpty']=false
              this.inputError['multiHostInvalidName']=false
            }
            break;
          }
          case 'hostType':{
            let value = id 
            this.currentForm.get('hostType').setValue(value)
            if(value=='Multiple'){
              this.inputError['singleHostNameEmpty']=false
              this.inputError['singleHostInvalidName']=false
            }else{
              this.inputError['multiHostNameEmpty']=false
              this.inputError['multiHostInvalidName']=false
            }
            break;
          }
          case 'errorPgUrl':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('errorPgUrl').setValue(value)
            if(!value){
              this.currentForm.get('badGateway').reset()
              this.currentForm.get('forbidden').reset()
              this.inputError['badGatewayInvalidURL']=false
              this.inputError['forbiddenInvalidURL']=false
            }
            break;
          }
          }
          break;
        }
      case 'rules':{
        this.saveDisable=true
        switch(subType){
          case 'targetType':{
            let value = id 
            this.currentSheet == 'addMultipleTargets' ? this.subSideSheetForm.get('targetType').setValue(value) : this.currentForm.get('targetType').setValue(value) 
            break;
          }
          case 'redirectionTarget':{
            let value = id 
            this.currentSheet == 'addMultipleTargets' ? this.subSideSheetForm.get('redirectionTarget').setValue(value) : this.currentForm.get('redirectionTarget').setValue(value) 
            break;
          }
          case 'includeQueryString':{
            let value = id == 'yes' ? true : false
            this.currentSheet == 'addMultipleTargets' ? this.subSideSheetForm.get('includeQueryString').setValue(value) : this.currentForm.get('includeQueryString').setValue(value) 
            break;
          }
          case 'includePath':{
            let value = id == 'yes' ? true : false
            this.currentSheet == 'addMultipleTargets' ? this.subSideSheetForm.get('includePath').setValue(value) : this.currentForm.get('includePath').setValue(value) 
            break;
          }
          }
          break;
        }
      case 'probe':{
        this.saveDisable=true
        switch(subType){
          case 'protocol':{
            let value = id 
            this.currentForm.get('protocol').setValue(value)
            break;
          }
          case 'pickHostName':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('pickHostName').setValue(value)
            break;
          }
          case 'pickPort':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('pickPort').setValue(value)
            break;
          }
          case 'useProbe':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('useProbe').setValue(value)
            break;
          }
          }
          break;
        }
   }
  }
  
  async setDefaultValue(type,row?:any){
  switch(type){
   case 'configuration':{
    this.currentForm.get('tier').setValue(this.applicationGatewayData.properties.sku.name)
    if(this.applicationGatewayData.properties.autoscaleConfiguration){
      this.currentForm.get('capacityType').setValue('autoscale')
      this.currentForm.get('minInstance').setValue(this.applicationGatewayData.properties.autoscaleConfiguration.minCapacity)
      this.currentForm.get('maxInstance').setValue(this.applicationGatewayData.properties.autoscaleConfiguration.maxCapacity)
    }else{
      this.currentForm.get('capacityType').setValue('manual')
      this.currentForm.get('instanceCount').setValue(this.applicationGatewayData.properties.sku.capacity)
    }
    this.currentForm.get('http2').setValue(this.applicationGatewayData.properties.enableHttp2)
    break;
    }
    case 'webAppFirewall':{
      this.currentForm.get('tier').setValue(this.applicationGatewayData.properties.sku.name)
      if(this.applicationGatewayData.properties.sku.name=='WAF_v2'){
        if(this.applicationGatewayData.properties?.webApplicationFirewallConfiguration){
          this.currentForm.get('wafStatus').setValue(this.applicationGatewayData.properties.webApplicationFirewallConfiguration.enabled)
          this.currentForm.get('wafMode').setValue(this.applicationGatewayData.properties.webApplicationFirewallConfiguration.firewallMode)
          this.currentForm.get('inspectReqBody').setValue(this.applicationGatewayData.properties.webApplicationFirewallConfiguration.requestBodyCheck)
          this.currentForm.get('maxReqSize').setValue(this.applicationGatewayData.properties.webApplicationFirewallConfiguration.maxRequestBodySizeInKb)
          this.currentForm.get('fileLimit').setValue(this.applicationGatewayData.properties.webApplicationFirewallConfiguration.fileUploadLimitInMb)
          let exclusionsArray  = this.applicationGatewayData.properties.webApplicationFirewallConfiguration.exclusions
            exclusionsArray.forEach(e=>{
              if(e?.matchVariable && e?.selectorMatchOperator && e?.selector){
                this.exclusiveArrayList.insert(0,this.util.getForm('addExclusiveArray',{
                  field:e?.matchVariable,
                  operator:e?.selectorMatchOperator,
                  selector:e?.selector,
                }))
              }
            });
        }else{
          this.currentForm.get('wafStatus').setValue(false)
          this.currentForm.get('wafMode').setValue('Detection')
          this.currentForm.get('inspectReqBody').setValue(true)
        }
      }else{
        this.currentForm.get('wafStatus').setValue(false)
        this.currentForm.get('wafMode').setValue('Detection')
        this.currentForm.get('inspectReqBody').setValue(true)
      }
      break;
      }
    case 'backendPool':{
      if(this.isEdit){
        this.currentForm.get('poolName').setValue(row.name)
        this.currentForm.get('poolTarget').setValue(false)
        let targetArray  = row.properties.backendAddresses
        targetArray.forEach(e=>{
          if(e?.fqdn){
            this.backendTargetArrayList.insert(0,this.util.getForm('addbackendTargetArray',{
              targetType:'IP address or FQDN',
              target:e?.fqdn,
            }))
          }
        });
      }else{
        this.currentForm.get('poolTarget').setValue(false)
      }
      break;
      }
    case 'backendSetting':{
      if(this.isEdit){
        this.currentForm.get('settingName').setValue(row?.name)
        this.currentForm.get('backendPort').setValue(row?.properties?.port)
        this.currentForm.get('cookieAffinity').setValue(row?.properties?.cookieBasedAffinity=='Disabled'?false:true)
        row?.properties?.cookieBasedAffinity!='Disabled' ? this.currentForm.get('affinityName').setValue(row?.properties?.affinityCookieName) :false
        this.currentForm.get('connectionDraining').setValue(row?.properties?.connectionDraining && row?.properties?.connectionDraining.enabled ? true:false)
        row?.properties?.connectionDraining ? this.currentForm.get('drainTimeOut').setValue(row?.properties?.connectionDraining?.drainTimeoutInSec):false
        this.currentForm.get('reqTimeOut').setValue(row?.properties?.requestTimeout)
        this.currentForm.get('backendPath').setValue(row?.properties?.path)
        this.currentForm.get('newHost').setValue(row?.properties?.hostName ? true:false)
        this.currentForm.get('hostOverRide').setValue(row?.properties?.pickHostNameFromBackendAddress)
        this.currentForm.get('hostName').setValue(row?.properties?.hostName)
        this.currentForm.get('useProbe').setValue(row?.properties?.probe ? true :false)
        row?.properties?.probe ? this.currentForm.get('probe').setValue(row?.properties?.probe.id) :false

      }else{
        this.currentForm.get('backendPort').setValue(80)
        this.currentForm.get('cookieAffinity').setValue(false)
        this.currentForm.get('connectionDraining').setValue(false)
        this.currentForm.get('reqTimeOut').setValue(20)
        this.currentForm.get('hostOverRide').setValue(row?.properties?.pickHostNameFromBackendAddress)
        this.currentForm.get('newHost').setValue(false)
        this.currentForm.get('useProbe').setValue(false)
      }
      break;
      }
    case 'listener':{
      if(this.isEdit){
        let portValue=this.applicationGatewayData.properties.frontendPorts.filter(e=>e.id==row.properties.frontendPort.id)[0].properties.port
        this.currentForm.get('listenerName').setValue(row?.name)
        this.currentForm.get('frontendIP').setValue('Public')
        this.currentForm.get('port').setValue(portValue)
        this.currentForm.get('ruleName').setValue(row.properties.requestRoutingRules ? row.properties.requestRoutingRules[0].id.split('/')[10] :'None' )
        if(row.properties.hostNames.length){
          this.currentForm.get('listenerType').setValue('Multi-site')
          this.currentForm.get('hostType').setValue('Multiple')
          let hostNamesArray  = row.properties.hostNames
          hostNamesArray.forEach(e=>{
            this.hostNameArrayList.insert(0,this.util.getForm('addHostNameArray',{
              name:e,
            }))
        });
        }else if(row.properties.hostName){
          this.currentForm.get('listenerType').setValue('Multi-site')
          this.currentForm.get('hostType').setValue('Single')
          this.currentForm.get('hostName').setValue(row.properties.hostName)
        }else{
          this.currentForm.get('listenerType').setValue('Basic')
          this.currentForm.get('hostType').setValue('Single')
        } 
        this.currentForm.get('errorPgUrl').setValue(false)

      }else{
        // this.currentForm.get('port').setValue(80)
        this.currentForm.get('listenerType').setValue('Basic')
        this.currentForm.get('hostType').setValue('Single')
        this.currentForm.get('errorPgUrl').setValue(false)
      }
      break;
    }
    case 'rules':{
      if(this.isEdit){

      }else{
        this.currentForm.get('targetType').setValue('backendPool')
        this.currentForm.get('redirectionTarget').setValue('listener')
        this.currentForm.get('includeQueryString').setValue(true)
        this.currentForm.get('includePath').setValue(true)
        this.currentForm.get('redirectionType').setValue('Permanent')
        this.pathBasedRulesList=[]
        this.redirectRulesList=[]
      }
      break;
      }
    case 'probe':{
      if(this.isEdit){
        this.currentForm.get('probeName').setValue(row?.name)
        this.currentForm.get('protocol').setValue(row?.properties?.protocol)
        this.currentForm.get('pickHostName').setValue(row?.properties?.pickHostNameFromBackendHttpSettings)
        this.currentForm.get('hostName').setValue(row?.properties?.host)
        this.currentForm.get('port').setValue(row?.properties?.port)
        this.currentForm.get('pickPort').setValue(row?.properties?.port ? false :true)
        this.currentForm.get('path').setValue(row?.properties?.path)
        this.currentForm.get('interval').setValue(row?.properties?.interval)
        this.currentForm.get('timeOut').setValue(row?.properties?.timeout)
        this.currentForm.get('threshold').setValue(row?.properties?.unhealthyThreshold)
        this.currentForm.get('useProbe').setValue(row?.properties?.match?.body ?true :false)
        if(row?.properties?.match?.body){
          this.currentForm.get('bodyMatch').setValue(row?.properties?.match?.body)
          this.currentForm.get('codeMatch').setValue(row?.properties?.match?.statusCodes[0])
        }
        if(row?.properties?.backendHttpSettings && row?.properties?.backendHttpSettings.length){
          let temp=[]
          row?.properties?.backendHttpSettings.forEach(e=>{
            temp.push(e.id)
          })
          this.currentForm.get('backendSettings').setValue(temp)
        }
      }else{
        this.currentForm.get('protocol').setValue('Http')
        this.currentForm.get('pickHostName').setValue(false)
        this.currentForm.get('pickPort').setValue(true)
        this.currentForm.get('interval').setValue(30)
        this.currentForm.get('timeOut').setValue(30)
        this.currentForm.get('threshold').setValue(3)
        this.currentForm.get('useProbe').setValue(false)
        this.currentForm.get('codeMatch').setValue('200-399')
      }
      break;
      }
  }
  }
  changeTab(name){
    this.currentTab=name
    this.resetValidation()
    this.saveDisable=false
  }
  validTier(event){
    let value=event.name
    this.saveDisable=true
    this.inputError['invalidTier']=false
    if(this.applicationGatewayData.sku.name=='Standard'&&value=='Basic'){
      this.inputError['invalidTier']=true
    }
  }
  getAssociatedListener(name){
    let listenerName=this.applicationGatewayData.properties?.httpListeners.filter(e=>e.properties?.frontendIPConfiguration?.id.split('/')[10]==name)
    return listenerName[0].name
  }
  getPublicIPAddress(row){
    return row?.properties?.ipAddress ? `${row?.properties.ipAddress} (${row?.name})` : row?.name
  }

  getPortValue(row){
    let temp=this.applicationGatewayData.properties.frontendPorts.filter(e=>e.id==row.id)
    return temp[0].properties.port
  }
  getHostName(row){
    if(row.properties.hostNames.length) return `${row.properties.hostNames[0]}, ${row.properties.hostNames.length-1} more`
    else if(row.properties.hostName) return row.properties.hostName
    else return '--'
  }
  
  async saveAppGatewayData(type){
    switch(type){
      case'config':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.applicationGatewayData
        body.properties.sku={
          "name": rawValue.tier,
          "tier": rawValue.tier
        }
        body['properties'].enableHttp2=rawValue.http2
        if(rawValue.capacityType=='autoscale'){
          body['properties'].autoscaleConfiguration={
            "minCapacity": rawValue.minInstance, 
            "maxCapacity": rawValue.maxInstance 
          }
        }else{
          delete body['properties'].autoscaleConfiguration
          body['properties'].sku['capacity']=rawValue.instanceCount 
        }
     
        await this.updateAppGatewayData(this.selectedRow,body,'config')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'webAppFirewall':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.applicationGatewayData
        body.properties.sku={
          "name": rawValue.tier,
          "tier": rawValue.tier
        }
        if(rawValue.tier=='WAF_v2'){
          if(rawValue.wafStatus){
            let exclusiveArray=[]
            rawValue.exclusiveArray.forEach(e=>{
              exclusiveArray.push({
                "matchVariable": e.field, 
                "selectorMatchOperator": e.operator, 
                "selector": e.selector
              })
            })
            body.properties['webApplicationFirewallConfiguration']={ 
              "enabled": true,   // false if WAF status is disabled. 
              "firewallMode": rawValue.wafMode,    //  value for WAF mode   
              "ruleSetVersion": "3.0", 
              "ruleSetType": "OWASP", 
              "requestBodyCheck": rawValue.inspectReqBody,   // true if inspect request body is on 
              "maxRequestBodySizeInKb": rawValue.maxReqSize,   // Max request body size (KB) 
              "fileUploadLimitInMb": rawValue.fileLimit,     // File upload limit (MB) 
              "exclusions": exclusiveArray
           }
          }else{
            body.properties['webApplicationFirewallConfiguration']={ 
              "enabled": false,   // false if WAF status is disabled. 
              "firewallMode": rawValue.wafMode,    //  value for WAF mode   
              "ruleSetVersion": "3.0", 
              "ruleSetType": "OWASP", 
           }
          }
        }else delete body.properties['webApplicationFirewallConfiguration']
        await this.updateAppGatewayData(this.selectedRow,body,'webAppFirewall')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'backendPool':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.applicationGatewayData
        let type= this.isEdit ? 'editBackendPool' : 'addBackendPool'
        let target= []
        if(!rawValue.poolTarget){
          rawValue.backendTargetArray.forEach(e=>{
            if(e.target){
              target.push({
                "fqdn": e.target, 
              })
            }
          })
        }
       if(this.isEdit){
        let index=body.properties.backendAddressPools.findIndex(e=>e.name==rawValue.poolName)
          body.properties.backendAddressPools[index].properties.backendAddresses=target
       }else{
        let object= { 
          "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/backendAddressPools/${rawValue.poolName}`, 
          "name": rawValue.poolName, 
          "etag": body.etag, 
          "properties": { 
              "backendAddresses": target
          },
          "type": "Microsoft.Network/applicationGateways/backendAddressPools"
        }
        body.properties.backendAddressPools.push(object)
       }
        await this.updateAppGatewayData(this.selectedRow,body,type)
        await this.getAppGatewayConfig(this.selectedRow)
        this.showSubSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'backendSetting':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.applicationGatewayData
        let type= this.isEdit ? 'editBackendSetting' : 'addBackendSetting'
       if(this.isEdit){
        let index=body.properties.backendHttpSettingsCollection.findIndex(e=>e.name==rawValue.settingName)
          body.properties.backendHttpSettingsCollection[index].properties.port=rawValue.backendPort
          body.properties.backendHttpSettingsCollection[index].properties.cookieBasedAffinity=rawValue.cookieAffinity ? 'Enabled':'Disabled'
          if(rawValue.cookieAffinity){
            body.properties.backendHttpSettingsCollection[index].properties['affinityCookieName']=rawValue.affinityName
          }else delete body.properties.backendHttpSettingsCollection[index].properties.affinityCookieName
          if(rawValue.connectionDraining){
            body.properties.backendHttpSettingsCollection[index].properties['connectionDraining']={
              "enabled":rawValue.connectionDraining,
              "drainTimeoutInSec":rawValue.drainTimeOut
            }
          }else delete body.properties.backendHttpSettingsCollection[index].properties['connectionDraining']
          body.properties.backendHttpSettingsCollection[index].properties.requestTimeout=rawValue.reqTimeOut
          body.properties.backendHttpSettingsCollection[index].properties.path=rawValue.backendPath
          if(rawValue.newHost){
            body.properties.backendHttpSettingsCollection[index].properties['hostName']=rawValue.hostName
          }else delete body.properties.backendHttpSettingsCollection[index].properties['hostName']
          body.properties.backendHttpSettingsCollection[index].properties['pickHostNameFromBackendAddress']=rawValue.hostOverRide
          if(rawValue.useProbe){
            body.properties.backendHttpSettingsCollection[index].properties['probe']={
              "id":rawValue.probe
            }
          }else delete body.properties.backendHttpSettingsCollection[index].properties.probe
       }else{
        let object= { 
          "name": rawValue.settingName, 
          "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/backendHttpSettingsCollection/${rawValue.settingName}`, 
          "etag": body.etag, 
          "properties": { 
              "port": rawValue.backendPort, 
              "protocol": "Http", 
              "cookieBasedAffinity": rawValue.cookieAffinity ? "Enabled":"Disabled", 
              "pickHostNameFromBackendAddress": rawValue.hostOverRide, 
              "path":rawValue.backendPath, 
              "requestTimeout": rawValue.reqTimeOut, 
          }, 
          "type": "Microsoft.Network/applicationGateways/backendHttpSettingsCollection" 
       }
       if(rawValue.cookieAffinity) object['properties']['affinityCookieName']=rawValue.affinityName
       if(rawValue.connectionDraining){
        object['properties']['connectionDraining']={
          "enabled":rawValue.connectionDraining,
          "drainTimeoutInSec":rawValue.drainTimeOut
        }
       } 
       if(rawValue.newHost){
        object['properties']['hostName']=rawValue.hostName
       }
       if(rawValue.useProbe){
        object['properties']['probe']={
          "id":rawValue.probe,
        }
       }
        body.properties.backendHttpSettingsCollection.push(object)
       }
        await this.updateAppGatewayData(this.selectedRow,body,type)
        await this.getAppGatewayConfig(this.selectedRow)
        this.showSubSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'listeners':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.applicationGatewayData
        let type= this.isEdit ? 'editListener' : 'addListener'
        let hostNames= []
        if(rawValue.listenerType=='Multi-site' && rawValue.hostType=='Multiple'){
          rawValue.hostNameArray.forEach(e=>{
            if(e.name!=''){
              hostNames.push(e.name)
            }
          })
        }
       if(this.isEdit){
        let index=body.properties.httpListeners.findIndex(e=>e.name==rawValue.listenerName)
        if(rawValue.listenerType=='Multi-site'){
          if(rawValue.hostType=='Multiple'){
            body.properties.httpListeners[index].properties['hostNames']=hostNames
            delete body.properties.httpListeners[index].properties['hostName']
          }  
          else if(rawValue.hostType=='Single'){
            body.properties.httpListeners[index].properties['hostName']=rawValue.hostName  
            delete body.properties.httpListeners[index].properties['hostNames']
          }  
         }else{
          delete body.properties.httpListeners[index].properties['hostNames']
          delete body.properties.httpListeners[index].properties['hostName']
         }
         body.properties.httpListeners[index].properties['frontendPort']={
          "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/frontendPorts/port_${rawValue.port}` 
         }
       }else{
        let object={ 
          "name": rawValue.listenerName, 
          "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/httpListeners/${rawValue.listenerName}`, 
          "etag": body.etag, 
          "properties": { 
              "frontendIPConfiguration": { 
                  "id": body.properties.frontendIPConfigurations[0].id
              }, 
              "frontendPort": { 
                  "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/frontendPorts/port_${rawValue.port}` 
              }, 
              "protocol": "Http", 
          }, 
          "type": "Microsoft.Network/applicationGateways/httpListeners" 
       }
       if(rawValue.listenerType=='Multi-site'){
        if(rawValue.hostType=='Multiple') object['properties']['hostNames']=hostNames
        else object['properties']['hostName']=rawValue.hostName  
       }
       let index=this.applicationGatewayData.properties.frontendPorts.findIndex(e=>e.name==`port_${rawValue.port}`)
       if(index>-1){
        body.properties.frontendPorts[index].properties['httpListeners']=[]
        body.properties.frontendPorts[index].properties['httpListeners'].push({
          id:`/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/httpListeners/${rawValue.listenerName}`
        })
       }else{
        let portObject={
          etag: body.etag,
          id: `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/frontendPorts/port_${rawValue.port}`,
          name: "port_"+rawValue.port,
          properties: {
            httpListeners: [{
              id:`/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/httpListeners/${rawValue.listenerName}`,
            }],
            port: rawValue.port,
          },
          type: "Microsoft.Network/applicationGateways/frontendPorts"
         }
         body.properties.frontendPorts.push(portObject)
       }
        body.properties.httpListeners.push(object)
       }
        await this.updateAppGatewayData(this.selectedRow,body,type)
        await this.getAppGatewayConfig(this.selectedRow)
        this.showSubSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'rules':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.applicationGatewayData
        let type= this.isEdit ? 'editRules' : 'addRules'
        this.pathBasedRulesList=[]
        this.redirectRulesList=[]
        if(this.currentSheet=='addMultipleTargets'){
          let formValue=this.subSideSheetForm.getRawValue()
          if(formValue.targetType=='backendPool'){
            this.pathBasedRulesList.push({
              "path":formValue.path,
              "targetName":formValue.targetName,
              "backendSettings":formValue.backendSetting,
              "backendTarget":formValue.backendTarget,
            })
          }else{
            this.redirectRulesList.push({
              "path":formValue.path,
              "targetName":formValue.targetName,
              "redirectionType":formValue.redirectionType,
              "redirectionTarget":formValue.redirectionTarget,
              "targetListener":formValue.targetListener,
              "targetUrl":formValue.targetUrl,
              "includeQuery":formValue.includeQueryString,
              "includePath":formValue.includePath
            })
          }
        this.currentSheet='routingRules'
        }else{
          if(this.isEdit){
            let index=body.properties.requestRoutingRules.findIndex(e=>e.name==rawValue.ruleName)
              body.properties.requestRoutingRules[index].properties.priority=rawValue.priority
    
           }else{
            let object= { 
              "name": rawValue.ruleName, 
              "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/requestRoutingRules/${rawValue.ruleName}`, 
              "etag": body.etag, 
              "properties": { 
                  "ruleType": "Basic", 
                  "priority":rawValue.priority, 
                  "httpListener": { 
                      "id": rawValue.listener 
                  }, 
              }, 
              "type": "Microsoft.Network/applicationGateways/requestRoutingRules" 
          }
          if(rawValue.targetType=='redirection'){
            let redirectionConfig={ 
              "name": rawValue.ruleName, 
              "properties": { 
                  "includePath": rawValue.includePath, 
                  "includeQueryString": rawValue.includeQueryString, 
                  "redirectType": rawValue.redirectionType, 
                  "targetListener": { 
                      "id": rawValue.targetListener
                  } 
              } 
          } 
          body.properties.redirectConfigurations.push(redirectionConfig)
          object['properties']['redirectConfiguration']={
            "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/redirectConfigurations/${rawValue.ruleName}`
          }
          }else{
            object['properties']['backendHttpSettings']={
              "id":rawValue.backendSetting
            }
            object['properties']['backendAddressPool']={
              "id":rawValue.backendTarget
            }
          }
            body.properties.requestRoutingRules.push(object)
           }
            await this.updateAppGatewayData(this.selectedRow,body,type)
            await this.getAppGatewayConfig(this.selectedRow)
            this.showSubSidesheet=false
            this.saveDisable=false 
        }
    
        break;
      }
      case'probes':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.applicationGatewayData
        let type= this.isEdit ? 'editProbe' : 'addProbe'
        let settingArray=[]
        rawValue.backendSettings.forEach(e=>{
          settingArray.push({id:e})
        })
        if(rawValue.testHealthProbe){
          this.currentSheet='testProbe'
        }else{
          if(this.isEdit){
            let index=body.properties.probes.findIndex(e=>e.name==rawValue.probeName)
              body.properties.probes[index].properties.path=rawValue.path
              body.properties.probes[index].properties.interval=rawValue.interval
              body.properties.probes[index].properties.timeout=rawValue.timeout
              body.properties.probes[index].properties.unhealthyThreshold=rawValue.threshold
              body.properties.probes[index].properties.protocol=rawValue.protocol
              body.properties.probes[index].pickHostNameFromBackendHttpSettings.path=rawValue.pickHostName
              if(!rawValue.pickHostName){
                body.properties.probes[index]['properties']['host']=rawValue.hostName
                body.properties.probes[index]['properties']['backendHttpSettings']=settingArray
              } 
              else{
                delete body.properties.probes[index]['properties']['host']
                delete body.properties.probes[index]['properties']['backendHttpSettings']
              } 
              if(!rawValue.pickPort) body.properties.probes[index]['properties']['port']=rawValue.port
              else delete body.properties.probes[index]['properties']['port']
              if(rawValue.useProbe){
                body.properties.probes[index]['properties']['match']={
                  "body":rawValue.bodyMatch,
                  "statusCodes":[rawValue.codeMatch]
                }
               }else  body.properties.probes[index]['properties']['match']={}
              
           }else{
            let object= { 
              "name": rawValue.probeName, 
              "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/applicationGateways/${this.selectedRow[0]}/probes/${rawValue.probeName}`, 
              "properties": { 
                  "path": rawValue.path, 
                  "interval": rawValue.interval, 
                  "timeout": rawValue.timeOut, 
                  "unhealthyThreshold": rawValue.threshold, 
                  "match": {}, 
                  "minServers": 0, 
                  "protocol": rawValue.protocol,
                  "pickHostNameFromBackendHttpSettings": rawValue.pickHostName,
              } 
          } 
           if(!rawValue.pickHostName){
            object['properties']['host']=rawValue.hostName
            if(rawValue.backendSettings){
              object['properties']['backendHttpSettings']=settingArray
             } 
           } 
           if(!rawValue.pickPort) object['properties']['port']=rawValue.port
           if(rawValue.useProbe){
            object['properties']['match']={
              "body":rawValue.bodyMatch,
              "statusCodes":[rawValue.codeMatch]
            }
           } 
            body.properties.probes.push(object)
           }
           await this.updateAppGatewayData(this.selectedRow,body,type)
           await this.getAppGatewayConfig(this.selectedRow)
           this.showSubSidesheet=false
           this.saveDisable=false 
        }
        break;
      }
    }
  }
  
  //validation
  validIPName(event,type,subType?:any){
    let value =event.target.value;
    this.saveDisable=true
    this.inputError[type+'NameExist'] = false;
    this.inputError[type+'NameEmpty'] = false
    this.inputError[type+'NameMaxLen'] = false;
    this.inputError[type+'NameValid']=false;
   if(value==''){
    this.inputError[type+'NameEmpty'] = true;
   }else{
    if (value.length > 80) {
      this.inputError[type+'NameMaxLen'] = true;
      return;
    }
   }
    if(value.length==1 && !(/^[A-Za-z0-9]*$/.test(value)) || !(/^[A-Za-z0-9_]+$/.test(value[value.length-1]))){
        this.inputError[type+'NameValid']=true;
    }
    switch(subType){
      case 'backendPool':{
        this.applicationGatewayData.properties.backendAddressPools.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'backendSetting':{
        this.applicationGatewayData.properties.backendHttpSettingsCollection.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'listener':{
        this.applicationGatewayData.properties.httpListeners.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'routingRules':{
        this.applicationGatewayData.properties.requestRoutingRules.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'healthProbe':{
        this.applicationGatewayData.properties.probes.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
    }
  }
  validPath(event,type){
    let value=event.target.value
    this.saveDisable=true
    this.inputError.invalidPath=false
    this.inputError.pathEmpty=false
    if(value!='' && value.length && value[0]!='/') return this.inputError.invalidPath=true
    if(type=='mandatory' && value=='') return this.inputError.pathEmpty=true
  }
  validPageURL(event,type){
    let value=event.target.value
    this.saveDisable=true
    if(value!='') this.inputError[type+'InvalidURL']=true
    else  this.inputError[type+'InvalidURL']=false
  }
  validHostName(event,type){
    let value=event.target.value
    this.saveDisable=true
    this.inputError[type+'NameEmpty']=false
    this.inputError[type+'InvalidName']=false
    if(value==''){
      this.inputError[type+'NameEmpty'] = true;
     }
     if(!(/^[A-Za-z0-9.]*$/.test(value)) || value[value.length-1]=='.' ||value[0]=='.'){
      this.inputError[type+'InvalidName']=true;
  }
  }


  validInstance(event,type){
    let value=event.target.value
    this.saveDisable=true
    this.inputError[type+'NameEmpty']=false
    this.inputError[type+'InvalidNumber']=false
    this.inputError[type+'InvalidRange']=false
    this.inputError['InvalidMaxRange']=false
    this.inputError[type+'Invalid']=false
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError[type+'InvalidNumber']=true;
      }
      switch(type){
        case 'instance':{
          if(value>125 || value<1){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'minInstance':{
          if(value>125 || value<0){
            this.inputError[type+'InvalidRange']=true;
          }
          if(Number(value)>Number(this.currentForm.get('maxInstance').value)) return  this.inputError['InvalidMaxRange']=true;
          break;
        }
        case 'maxInstance':{
          if(value>125 || value<0){
            this.inputError[type+'InvalidRange']=true;
          }
          if(Number(value)<Number(this.currentForm.get('minInstance').value)) return  this.inputError['InvalidMaxRange']=true;
          break;
        }
        case 'maxReqSize':{
          if(value>128 || value<8){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'fileLimit':{
          if(value>100 || value<0){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'port':{
          if(value>65535 || value<1){
            this.inputError[type+'InvalidRange']=true;
          }
          if(this.currentTemplate == 'listeners'){
            if(this.isEdit){
              let index=this.applicationGatewayData.properties.httpListeners.findIndex(e=>e.name==this.currentForm.get('listenerName').value)
              this.applicationGatewayData.properties.frontendPorts.forEach(e=>{
                if(e.properties.httpListeners && e.properties.httpListeners.length){
                  if(e.properties.port==Number(value) && e.id != this.applicationGatewayData.properties.httpListeners[index].properties.frontendPort.id) return this.inputError[type+'Invalid']=true;
                }
              })
            }else{
              this.applicationGatewayData.properties.frontendPorts.forEach(e=>{
                if(e.properties.httpListeners && e.properties.httpListeners.length){
                  if(e.properties.port==Number(value)) return this.inputError[type+'Invalid']=true;
                }
              })
            }
          }
          break;
        }
        case 'drainTimeOut':{
          if(value>3600 || value<1){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'reqTimeOut':{
          if(value>86400 || value<1){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'threshold':{
          if(value>20 || value<0){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'interval':{
          if(value>86400 || value<0){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'priority':{
          if(value>20000 || value<1){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
      }
    }else this.inputError[type+'NameEmpty']=true
  }

  //API's

  getAppGatewayConfig(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let appGatewayName = data[0]
    return this.util.handleRequest('get','a3s_management_getAppGatewayConfig',[subsId,rgName,appGatewayName],null,null,null,true).then(async res=>{
      this.applicationGatewayData= res.body
      this.probeList= this.applicationGatewayData?.properties?.probes
      this.backendTargetList=this.applicationGatewayData?.properties?.backendAddressPools
      this.backendSettingsList=this.applicationGatewayData?.properties?.backendHttpSettingsCollection
      this.listenerList=this.applicationGatewayData?.properties?.httpListeners
      this.listenerListRules=this.applicationGatewayData?.properties?.httpListeners.filter(e=>!e?.properties?.requestRoutingRules?.length)
      // this.frontendIPList= this.applicationGatewayData?.properties?.probes
      console.log('Azure Bastion Data',this.applicationGatewayData)
    })
  }
  updateAppGatewayData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let appGatewayName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateAppGatewayData',[subsId,rgName,appGatewayName],body,null,null,true).then(res=>{
      console.log('Azure Bastion Data updated',res)
      this.showSubSidesheet = false
      this.closeModal()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  deleteAppGatewayData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],appGatewayName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteAppGatewayData',[subscriptionId,resourceGroupName,appGatewayName],null,null,null,true).then(async res=>{
      this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
  }

}

