<div class="introduction">
            <p class="heading" id="0">Overview</p>
            <p class="mb-0" >Compliance landing page shows overall view of the compliance status of all Azure Subscriptions
                 that are under your Tenant and are being managed by A3S. 
            </p>
            <img src="assets\user-manual\compliance\Overview.svg" >
            <figcaption class="caption"> Compliance dashboard</figcaption>
</div>


