<div class="dashboard-top">
  <div class="flex-custom no-wrap">
    <div class="">
      <app-breadcrumbs></app-breadcrumbs>
    </div>
    <div class="ml-auto">
      <div class="main-nav">
        <ul class="nav-wrap m-0" >
          <li class="list-item" [class.active]="currentTab == 'overview'" (click)="currentTab = 'overview'">
            <img  class="mr-2" [src]="currentTab == 'overview'?'/assets/security/overview-active.svg':'/assets/security/overview-not-active.svg'" alt="">
            <span class="menu-name">Overview</span>
          </li>
          <li class="list-item" [class.active]="currentTab == 'identity'" (click)="currentTab = 'identity'">
            <img  class="mr-2" [src]="currentTab == 'identity'?'/assets/security/identity-active.svg':'/assets/security/identity-not-active.svg'" alt="">
            <span class="menu-name">Identity</span>
          </li>
          <li class="list-item" [class.active]="currentTab == 'endpoints'" (click)="currentTab = 'endpoints'">
            <img  class="mr-2" [src]="currentTab == 'endpoints'?'/assets/security/endpoint-active.svg':'/assets/security/endpoint-not-active.svg'" alt="">
            <span class="menu-name">Endpoints</span>
          </li>
          <li class="list-item" [class.active]="currentTab == 'activities'" (click)="currentTab = 'activities'">
            <img  class="mr-2" [src]="currentTab == 'activities'?'/assets/security/activities-active.svg':'/assets/security/activities-not-active.svg'" alt="">
            <span class="menu-name">Activities</span>
          </li>
          <!-- <li class="list-item" [class.active]="currentTab == 'application'" (click)="currentTab = 'application'">
            <img  class="mr-2" src="/assets/security/application-security.svg" alt="">
            <span class="menu-name">Application</span>
          </li>
          <li class="list-item" [class.active]="currentTab == 'network'" (click)="currentTab = 'network'">
            <img  class="mr-2" src="/assets/security/network.svg" alt="">
            <span class="menu-name">Network</span>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-main">
 <app-security-overview *ngIf="currentTab == 'overview'"></app-security-overview>
 <app-identity-security *ngIf="currentTab == 'identity'"></app-identity-security>
 <app-endpoints-security *ngIf="currentTab == 'endpoints'"></app-endpoints-security>
 <app-activities-security *ngIf="currentTab == 'activities'"></app-activities-security>
 <app-application-security *ngIf="currentTab == 'application'"></app-application-security>
 <app-network-security *ngIf="currentTab == 'network'"></app-network-security>
</div>