import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-app-service-plan-actions',
  templateUrl: './app-service-plan-actions.component.html',
  styleUrls: ['./app-service-plan-actions.component.scss']
})
export class AppServicePlanActionsComponent implements OnInit {
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("appServiceDetailsTemplate") appServiceDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    InvalidPortRange:false,
    NotValidPortVal:false,
    trafficLimit:false,
    validScaleName:false,
    validNumber:false,
    validCount:false,
    defvalidNumber:false,
    defvalidCount:false,
    minValidInstance:false,
    maxValidInstance:false,
    defValidInstance:false,
    validMinInstance:false,
    validMaxInstance:false,
    validMaximumInstance:false,
    validDefInstance:false,
    defMinValidInstance:false,
    defMaxValidInstance:false,
    defaultDefValidInstance:false,
    defValidMinInstance:false,
    defValidMaxInstance:false,
    defValidMaximumInstance:false,
    defValidDefInstance:false,
  }
  selectedType: any;
  selectedSideSheetRow: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;
  timeZoneList=this.util.getStatic('timeZoneList');
  currentTab: any;
  ResourceGroupsList: any;


  mode=[
    {id:'Disabled',name:'Disabled'},
  ]
  autoScaleData: any;
  disableAutoScaleName: boolean;
  defaultScaleCondition: boolean;
  scaleCondition: boolean;
  appServicePlanData: any;
  appServicePlanSKUList: any;
  appServicePlanAllSKUList: any;
  additionalTierlist: any;
  appSettingBody: any;
  connSettingBody: any;
  currentConnString: any;
  currentAppSetting: any;

  constructor( public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private _dataMigrationService: DataMigrationService,
    private validatorService : ValidatorsService){ }

  ngOnInit(): void {
  }

async action(data,type,subType?:any){
  console.log(type +'Data',data)
  switch(type){
    case 'deleteAppServicePlanData': {
      this.closeModal()
      return this.openModal(this.deleteConfirmPopup,data,'deleteAppServicePlanData')
    }
  }
}
  // Trigger action of the App service 

async openSidesheet(type,data?:any){
  this.currentTemplate = type
  this.selectedRow = data;
  switch(this.currentTemplate){
    case "scaleUp" : {
      this.closeModal()
      this.currentForm = this.util.getForm('appServiceScaleUp')
      await this.getAppServicePlanData(this.selectedRow)
      await this.getAppServicePlanSKUList(this.selectedRow)
      this.saveDisable=false
      this.showSidesheet = true;
      break;
    }
    case "scaleOut" : {
      this.closeModal()
      this.currentForm = this.util.getForm('VMScaleSetScaling')
      this.currentTab = 'configure'
      await this.getRgBySubscription()
      await this.getAppServicePlanData(this.selectedRow)
      await this.getAutoScalingData(this.selectedRow)
      await this.setDefaultValue('scaleOut')
      this.saveDisable=false
      this.showSidesheet = true;
      break;
    }
  }
}
getRgBySubscription(){
  let body={
      subscriptionId : this.selectedRow[2],
  }
  return this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
      console.log('Resource Group list ',res);
      this.ResourceGroupsList = res && res.value ? res.value : []
  })
}
async deleteAction(){
  switch(this.selectedType){
    case 'deleteAppServicePlanData' :return this.deleteAppServicePlanData(this.selectedRow)
  }
}

openModal(template: TemplateRef<any>,row,type?:any) {
  this.selectedRow=row;
  this.selectedType=type;
  type=='appServicePopup'?this.getAppServicePlanData(row):false 
  this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
}

closeModal() {
  this.modalService.dismissAll();
}
closeSidesheet(){
  this.showSidesheet = false
}
close_reset(){
  this.closeSidesheet()
  this.resetForm()
  this.resetValidation()
}
close_subSheet(){
  this.showSubSidesheet = false
  this.resetSubSideSheetForm()
}
getProjectName(val){
  if(this.currentProjectData.subscriptionId == val){
    return val =this.currentProjectData.subscriptionName
  }
}

getResourceTitle(){
  switch(this.selectedType){
    case'deleteAppServicePlanData':return 'Delete App Service plan'
    default: return
  }
}
getResourceName(){
  switch(this.selectedType){
    case'deleteAppServicePlanData':return `Are you sure you want to delete “${this.selectedRow[0]}”?` 
    default: return 'delete '+`"${this.selectedRow[0]}"`
  }
}
getBtnName(){
  switch(this.selectedType){
    case'deleteAppServicePlanData':return 'Delete'
    default: return 'Delete'
  }
}
getTags(val){
  if(!val) return "";
  let obj = {};
  Object.keys(val).forEach(e => {
    obj[e] = val[e] ;
  })
  return JSON.stringify(obj).replace(/["{}]/g, ' ');
}
getAppSkuName(val){
  if(val  == "Standard"){
    return val
  } else{
    return val.replace('V', ' V')
  }
}
getStatusClass(val){
  let status = val.toLowerCase() 
  switch (status) {
    case 'running': return 'st-success';
    case 'deallocated': return 'st-warning';
    case 'deallocating': return 'st-warning';
    case 'starting': return 'st-success';
    case 'stopped': return 'st-error';
    case 'stopping': return 'st-error';
    case 'unknown': return 'st-error';
    default: return 'st-error';
  }
}
getStatusChipClass(val){
  let status = val.toLowerCase()
  switch (status) {
    case 'allow': return 'st-success';
    case 'deallocated': return 'st-warning';
    case 'deny': return 'st-error';
    default: return 'st-error';
  }
}
getStatusChipImg(value) {
  let status = value.toLowerCase()
  switch (status) {
    case 'allow': return 'assets/icons/green-tick.svg'
    case 'deny': return 'assets/icons/error-info.svg';
 }
}

getRegionName(val){
  let trans = this.titleCase.transform(val)
  let ele = trans.replace('us', ' US')
  return ele.replace('2', ' 2')
}
splitWordByCaps(data){
  return this.util.splitWordByCaps(data)
}
splitWordByCapsExceptAbb(data){
  return this.util.splitWordByCapsExceptAbb(data)
}

getSuccesText(type){
  switch(type){
    case'scaleUp':return `“${this.selectedRow[0]}” Scaled up successfully!`
    case'scaleOut':return `“${this.selectedRow[0]}” Scaled out successfully!`
    default: return 'Changes saved successfully!'
  }
}

getFailureText(type){
  switch(type){
    case'scaleUp':return `“${this.selectedRow[0]}” failed to be scaled up.`
    case'scaleOut':return `“${this.selectedRow[0]}” failed to be scaled out.`
    default: return 'Failed to saved.'
  }
}
getInProgress(type){
  switch(type){
    case'scaleUp':return `Scaling up “${this.selectedRow[0]}”...`
    case'scaleOut':return `Scaling out “${this.selectedRow[0]}”...`
    default: return `Saving changes to ${this.selectedRow[0]}...`
  }
}
sortByKey(primaryKey,list){
  let num = 0;
  (this.datasort === 'desc') ? num = 1 : num = -1;
  this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
  switch(primaryKey){
    case 'appServicePlanData':{
      let sorted=this.data;
      sorted.sort((a,b)=>{
        let x = a[list];
        let y = b[list];
        return (x < y) ? num : ((x > y) ? -num : 0)
      })
      this.data=sorted;
      break;
    }
    case 'size':{
      let sorted=this.data;
      sorted.sort((a,b)=>{
        let x = a?.series;
        let y = b?.series;
        return (x < y) ? num : ((x > y) ? -num : 0)
      })
      this.data=sorted;
      break;
    }
  }
}

invalidForm(type){
  switch(type){
      case'scaling':{
    return this.inputError?.validScaleName||this.inputError?.validMinInstance||this.inputError?.minValidInstance||((this.currentForm.get('instanceMin')?.dirty ||  this.currentForm.get('instanceMin')?.touched) && !this.currentForm.get('instanceMin')?.value) ||
    this.inputError?.validMaximumInstance||this.inputError?.validMaxInstance||this.inputError?.maxValidInstance||((this.currentForm.get('instanceMax')?.dirty ||  this.currentForm.get('instanceMax')?.touched) && !this.currentForm.get('instanceMax')?.value) ||
    this.inputError?.validDefInstance||this.inputError?.defValidInstance||((this.currentForm.get('instanceDef')?.dirty ||  this.currentForm.get('instanceDef')?.touched) && !this.currentForm.get('instanceDef')?.value) ||
    this.inputError?.validCount||this.inputError?.validNumber||((this.currentForm.get('scaleInstanceCount')?.dirty ||  this.currentForm.get('scaleInstanceCount')?.touched) && !this.currentForm.get('scaleInstanceCount')?.value) ||
    this.inputError?.defMinValidInstance||this.inputError?.defValidMinInstance||((this.currentForm.get('defInstanceMin')?.dirty ||  this.currentForm.get('defInstanceMin')?.touched) && !this.currentForm.get('defInstanceMin')?.value) ||
    this.inputError?.defValidMaximumInstance|| this.inputError?.defMaxValidInstance||this.inputError?.defValidMaxInstance||((this.currentForm.get('defInstanceMax')?.dirty ||  this.currentForm.get('defInstanceMax')?.touched) && !this.currentForm.get('defInstanceMax')?.value) ||
    this.inputError?.defValidDefInstance||this.inputError?.defaultDefValidInstance||((this.currentForm.get('defInstanceDef')?.dirty ||  this.currentForm.get('defInstanceDef')?.touched) && !this.currentForm.get('defInstanceDef')?.value) ||
    this.inputError?.defvalidNumber||this.inputError?.defvalidCount||((this.currentForm.get('defScaleInstanceCount')?.dirty ||  this.currentForm.get('defScaleInstanceCount')?.touched) && !this.currentForm.get('defScaleInstanceCount')?.value) ||
    this.inputError?.InvalidPortRange||this.inputError?.NotValidPortVal||((this.currentForm.get('overRideInstanceCount')?.dirty ||  this.currentForm.get('overRideInstanceCount')?.touched) && !this.currentForm.get('overRideInstanceCount')?.value)
 }
  }
}
resetForm(){
  this.currentForm.reset()
}
resetSubSideSheetForm(){
  this.subSideSheetForm.reset()
}
resetValidation(){
  this.inputError={
    InvalidPortRange:false,
  NotValidPortVal:false,
  trafficLimit:false,
  validScaleName:false,
  validNumber:false,
  validCount:false,
  defvalidNumber:false,
  defvalidCount:false,
  minValidInstance:false,
  maxValidInstance:false,
  defValidInstance:false,
  validMinInstance:false,
  validMaxInstance:false,
  validMaximumInstance:false,
  validDefInstance:false,
  defMinValidInstance:false,
  defMaxValidInstance:false,
  defaultDefValidInstance:false,
  defValidMinInstance:false,
  defValidMaxInstance:false,
  defValidMaximumInstance:false,
  defValidDefInstance:false,
  }
}
async deletescaleCondition(index){
  this.saveDisable=true
 this.scaleConditionArrayList.controls.splice(index, 1);
 this.scaleCondition= this.scaleConditionArrayList.controls.length==0?false:true
}
get scaleConditionArrayList() {
  return this.currentForm.get('scaleConditonArray') as FormArray;
}
addScaleCondition(){
  if(!this.scaleCondition){
    this.scaleConditionArrayList.controls.length==0?this.scaleConditionArrayList.push(this.util.getForm('addScaleConditonArray')):false
    return this.scaleCondition=true
  }
  this.scaleCondition=true
  this.scaleConditionArrayList.push(this.util.getForm('addScaleConditonArray'))
}
setRadioBtn(id,type,subType?:any,index?:any){
switch(type){
  case 'scaling':{
    this.saveDisable=true
    switch(subType){
      case 'scale':{
        let value = id == 'manual' ? 'manual' : 'custom'
        this.currentForm.get('scale').setValue(value)
        if(value=='custom'){
          this.currentForm.get('autoscaleName').setValidators(Validators.required)
          this.currentForm.get('defScaleConditionName').setValidators(Validators.required)
          // this.resetValidation()
          // this.resetForm()
        }else{
          this.currentForm.get('autoscaleName').setValidators(null)
          this.currentForm.get('defScaleConditionName').setValidators(null)

        }
        this.currentForm.get('autoscaleName').updateValueAndValidity()
        this.currentForm.get('defScaleConditionName').updateValueAndValidity()
        
        break;
      }
      case 'defScaleMode':{
        let value = id == 'yes' ? true : false
        this.currentForm.get('defScaleMode').setValue(value)
        break;
      }
      case 'scaleMode':{
        let value = id == 'yes' ? true : false
        this.scaleConditionArrayList.controls[index].get('scaleMode').setValue(value)
        break;
      }
      case 'schedule':{
        let value = id == 'yes' ? true : false
        this.scaleConditionArrayList.controls[index].get('schedule').setValue(value)
        // formArr.get('schedule').setValue(value)
        break;
      }
    }
  break;}
  
  case 'scaleUp':{
    // this.saveDisable=true
    this.currentForm.get('type').setValue(id)
    if(id=='dev'){
      this.appServicePlanSKUList=this.appServicePlanAllSKUList.filter(dt=>dt.sku.name=='F1' || dt.sku.name=='B1')
      this.appServicePlanSKUList = this.appServicePlanData?.kind=='app'? this.appServicePlanAllSKUList.filter(dt=>dt.sku.name=='F1' || dt.sku.name=='D1' || dt.sku.name=='B1') : this.appServicePlanSKUList
      this.additionalTierlist=this.appServicePlanAllSKUList.filter(dt=>dt.sku.name=='B2' || dt.sku.name=='B3')
    }else if(id=='production'){
      this.appServicePlanSKUList=this.appServicePlanAllSKUList.filter(dt=>dt.sku.tier=='PremiumV3' || dt.sku.tier=='PremiumV2') 
      this.additionalTierlist=this.appServicePlanAllSKUList.filter(dt=>dt.sku.tier=='Standard' || dt.sku.tier=='Premium')
    }else{
      this.appServicePlanSKUList=[]
      this.additionalTierlist=[]
    }
  break;} 
 }
}

async setDefaultValue(type){
switch(type){
 case 'scaleOut':{
  this.currentForm.get('scale').setValue('manual')
  this.currentForm.get('overRideInstanceCount').setValue(this.appServicePlanData?.sku?.capacity)
  this.currentForm.get('mode').setValue('Disabled')
  this.currentForm.get('rg').setValue(this.selectedRow[1])
  this.currentForm.get('defInstanceMin').setValue(2)
  this.currentForm.get('defInstanceMax').setValue(2)
  this.currentForm.get('defInstanceDef').setValue(2)
  this.currentForm.get('defScaleMode').value=true
  if(this.autoScaleData.length){
    this.disableAutoScaleName=true
    this.autoScaleData[0].properties?.enabled ?   this.currentForm.get('scale').setValue('custom') : this.currentForm.get('scale').setValue('manual')
    this.currentForm.get('autoscaleName').setValue(this.autoScaleData[0].name)
    this.currentForm.get('defScaleConditionName').setValue(this.autoScaleData[0].properties.profiles[0].name)
    if(this.autoScaleData[0].properties.profiles[0].capacity.minimum==this.autoScaleData[0].properties.profiles[0].capacity.maximum){
      this.currentForm.get('defScaleMode').setValue(false)
      this.currentForm.get('defScaleInstanceCount').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
      this.currentForm.get('defInstanceMin').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
      this.currentForm.get('defInstanceMax').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
      this.currentForm.get('defInstanceDef').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
    } else{
      this.currentForm.get('defScaleMode').setValue(true)
      this.currentForm.get('defInstanceMin').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
      this.currentForm.get('defInstanceMax').setValue(this.autoScaleData[0].properties.profiles[0].capacity.maximum)
      this.currentForm.get('defInstanceDef').setValue(this.autoScaleData[0].properties.profiles[0].capacity.default)
    }
    if(this.autoScaleData[0].properties.profiles.length>1){
      let scaleConditionArr=this.autoScaleData[0].properties.profiles.filter(e=>e.fixedDate)
      this.scaleCondition=true
      scaleConditionArr.forEach(e=>{
        let startTimeFormat=this.a3sDateTimeFormat.transform(e.fixedDate.start)
        startTimeFormat=startTimeFormat.split(', ')
        let endTimeFormat=this.a3sDateTimeFormat.transform(e.fixedDate.end)
        endTimeFormat=endTimeFormat.split(', ')
        let scaleMode=e.capacity.minimum==e.capacity.maximum?false:true
        this.scaleConditionArrayList.insert(0,this.util.getForm('addScaleConditonArray',{
          scaleConditionName:e.name,
          scaleMode:scaleMode,
          instanceMin:e.capacity.minimum!=e.capacity.maximum?e.capacity.minimum:null,
          instanceMax:e.capacity.minimum!=e.capacity.maximum?e.capacity.maximum:null,
          instanceDef:e.capacity.minimum!=e.capacity.maximum?e.capacity.default:null,
          scaleInstanceCount:e.capacity.minimum==e.capacity.maximum?e.capacity.minimum:null,
          schedule:true,
          timeZone:e.fixedDate.timeZone,
          startDate:e.fixedDate.start,
          startTime:startTimeFormat[1],
          endDate:e.fixedDate.end,
          endTime:endTimeFormat[1],
        }))
      })
      this.scaleConditionArrayList.controls.splice(this.scaleConditionArrayList.controls.length-1,1)
    } else this.scaleCondition=false

  }
  this.defaultScaleCondition=true 
  break;
}
}
}

setTime(event,type,index){
  this.saveDisable=true
  if(type=='start'){
    let startTimeFormat=this.a3sDateTimeFormat.transform(event.value)
    startTimeFormat=startTimeFormat.split(', ')
    this.scaleConditionArrayList.controls[index].get('startTime').setValue(startTimeFormat[1])
  }else{
    let endTimeFormat=this.a3sDateTimeFormat.transform(event.value)
    endTimeFormat=endTimeFormat.split(', ')
    this.scaleConditionArrayList.controls[index].get('endTime').setValue(endTimeFormat[1])
  }
}
setTier(list,i){
  this.saveDisable=true
  this.appServicePlanSKUList.map(e=>e['active']=false)
  this.additionalTierlist.map(e=>e['active']=false)
  list.active=true
  this.currentForm.get('skuName').setValue(list?.sku?.name)
  this.currentForm.get('skuTier').setValue(list?.sku?.tier)
}
async saveAppServiceData(type){
  switch(type){
    case'scaleUp':{
      let body=this.appServicePlanData
      let rawValue = this.currentForm.getRawValue()
          body.sku.name=rawValue.skuName
          body.sku.tier=rawValue.skuTier
          this.updateAppServicePlanData(this.selectedRow,body,'scaleUp')
      this.showSidesheet=false
      this.saveDisable=false 
      break;
    }

    case'scaleOut':{
      let body=this.appServicePlanData
      let rawValue = this.currentForm.getRawValue()
        if(rawValue?.scale=='manual'){
          body.sku.capacity=rawValue.overRideInstanceCount
          this.updateAppServicePlanData(this.selectedRow,body,'scaleOut')
          if(this.autoScaleData.length){
            this.autoScaleData[0].properties.enabled=false
            this.updateAutoScalingData(this.selectedRow,this.autoScaleData[0],'scaling')
           }
          }else{
            let autoScaleBody={
              "location": this.selectedRow[3], 
              "tags": {}, 
              "properties": { 
                  "name": rawValue?.autoscaleName, 
                  "enabled": true, 
                  "predictiveAutoscalePolicy": { 
                      "scaleMode": "Disabled", 
                      "scaleLookAheadTime": null 
                  }, 
                  "targetResourceUri": body.id,  
                  "profiles":[] ,      
                  "notifications": [] 
              } 
            }
            if(this.defaultScaleCondition){
              autoScaleBody.properties["profiles"].push({ 
                  "name": rawValue?.defScaleConditionName, 
                  "capacity": { 
                      "minimum": rawValue?.defScaleMode? rawValue?.defInstanceMin : rawValue?.defScaleInstanceCount, 
                      "maximum": rawValue?.defScaleMode? rawValue?.defInstanceMax : rawValue?.defScaleInstanceCount, 
                      "default": rawValue?.defScaleMode? rawValue?.defInstanceDef : rawValue?.defScaleInstanceCount 
                  }, 
                  "rules": [] 
              })
            }
            if(this.scaleCondition){
              rawValue.scaleConditonArray.forEach(e=>{
                autoScaleBody.properties["profiles"].push({ 
                  "name":e?.scaleConditionName, 
                  "capacity": { 
                      "minimum": e?.scaleMode? e?.instanceMin : e?.scaleInstanceCount, 
                      "maximum": e?.scaleMode? e?.instanceMax : e?.scaleInstanceCount,  
                      "default": e?.scaleMode? e?.instanceDef : e?.scaleInstanceCount, 
                  }, 
                  "rules": [], 
                  "fixedDate": { 
                      "timeZone": "UTC", 
                      "start": e?.startDate, 
                      "end": e?.endDate
                  } 
              })
              })
            }
            this.updateAutoScalingData(this.selectedRow,autoScaleBody,'scaleOut')
          }
   
      this.showSidesheet=false
      this.saveDisable=false 
      break;
    }

  }

}


//validation
portRangeValidation(event,limit,type?:any){
let value=event.target.value;
this.saveDisable=true
this.inputError['InvalidPortRange']=false; 
this.inputError['NotValidPortVal']=false;
if(value&&value!=""){
  if((!(/^[0-9]*$/).test(value))){
    this.inputError['NotValidPortVal']=true;
  }
  if(type=='instanceCount'){
    if(value>1000 || value<1){
      this.inputError['InvalidPortRange']=true;
    }
  }
  else{
    if(value>65535 || value<0){
      this.inputError['InvalidPortRange']=true;
    }
  }
}
}
validAutoscaleName(event){
let value=event.target.value;
this.saveDisable=true
let regex=/\,|\%|\&|\>|\<|\#|\.|\?|\/|\\|\s/;
if(regex.test(value)){
  this.inputError['validScaleName']=true;
}else this.inputError['validScaleName']=false;
}
instanceLimitValidation(event,type,index){
let value=event.target.value;
this.saveDisable=true
this.inputError[type+'ValidInstance']=false; 
this.inputError['validMinInstance']=false;
this.inputError['validMaxInstance']=false;
this.inputError['validMaximumInstance']=false;
this.inputError['validDefInstance']=false;
this.scaleConditionArrayList.controls.forEach((e,i)=>{
      if(i==index&&value&&value!=""){
          if((!(/^[0-9]*$/).test(value))){
            this.inputError[type+'ValidInstance']=true;
          }
          if(type=='min'){
            if(value>1000 || value<1){
              this.inputError['validMinInstance']=true;
            }
            this.inputError['validMaxInstance']=Number(this.currentForm.get('instanceMax').value)<value?true:false
          }else if(type=='max'){
            this.inputError['validMaxInstance']=Number(this.currentForm.get('instanceMin').value)>value?true:false
            this.inputError['validMaximumInstance'] = value>1000?true:false
          }else if(type=='def'){
            if(Number(this.currentForm.get('instanceMax').value)>value||Number(this.currentForm.get('instanceMin').value<value)){
              this.inputError['validDefInstance']=true;
            }
          }
      }
})

}
instanceLimitDefaultValidation(event,type){
let value=event.target.value;
this.saveDisable=true
this.inputError[type+'ValidInstance']=false; 
this.inputError['defValidMinInstance']=false;
this.inputError['defValidMaxInstance']=false;
this.inputError['defValidMaximumInstance']=false;
this.inputError['defValidDefInstance']=false;
if(value&&value!=""){
  if((!(/^[0-9]*$/).test(value))){
    this.inputError[type+'ValidInstance']=true;
  }
  if(type=='defMin'){
    if(value>1000 || value<1){
      this.inputError['defValidMinInstance']=true;
    }
    this.inputError['defValidMaxInstance']=Number(this.currentForm.get('defInstanceMax').value)<value?true:false
  }else if(type=='defMax'){
    this.inputError['defValidMaxInstance']=Number(this.currentForm.get('defInstanceMin').value)>value?true:false
    this.inputError['defValidMaximumInstance'] = value>1000?true:false
  }else if(type=='defaultDef'){
    if(Number(this.currentForm.get('defInstanceMax').value)>value||Number(this.currentForm.get('defInstanceMin').value<value)){
      this.inputError['defValidDefInstance']=true;
    }
  }
}
}
validInstanceCount(event,type){
let value=event.target.value;
this.saveDisable=true
this.inputError[type+'validNumber']=false; 
this.inputError[type+'validCount']=false;
if(value&&value!=""){
  if((!(/^[0-9]*$/).test(value))){
    this.inputError[type+'validNumber']=true;
  }
  if(value>1000 || value<1){
    this.inputError[type+'validCount']=true;
  }
}
}


//API's

getAppServicePlanSKUList(data,type?:any,subData?:any){
  let subsId = data[2]
  let rgName = data[1]
  let appServiceName = data[0]
  return this.util.handleRequest('get','a3s_management_getAppServicePlanSKUList',[subsId,rgName,appServiceName],null,null,null,true).then(async res=>{
    this.appServicePlanAllSKUList= res.body.value
    this.appServicePlanAllSKUList.map(e=>e['active']=false)
    this.appServicePlanAllSKUList.map(e=>{
      if(this.appServicePlanData.sku.name==e.sku.name){
              e['active']=true
      }
    })
    this.appServicePlanSKUList=_.cloneDeep(this.appServicePlanAllSKUList.filter(dt=>dt.sku.tier=='PremiumV3' || dt.sku.tier=='PremiumV2')) 
    this.additionalTierlist=this.appServicePlanAllSKUList.filter(dt=>dt.sku.tier=='Standard' || dt.sku.tier=='Premium')
    console.log('App Service Configuration',this.appServicePlanAllSKUList)
  })
}
getAppServicePlanData(data,type?:any,subData?:any){
  let subsId = data[2]
  let rgName = data[1]
  let appServiceName = data[0]
  return this.util.handleRequest('get','a3s_management_getAppServicePlanData',[subsId,rgName,appServiceName],null,null,null,true).then(async res=>{
    this.appServicePlanData= res.body
    console.log('App Service Configuration',this.appServicePlanData)
  })
}
updateAppServicePlanData(data,body,type?:any){
  let subsId = data[2]
  let rgName =  data[1] 
  let appServiceName = data[0]
  type?this.util.info(this.getInProgress(type)):false
  return this.util.handleRequest('post','a3s_management_updateAppServicePlanData',[subsId,rgName,appServiceName],body,null,null,true).then(res=>{
    console.log('App Service config Data updated',res)
    this.showSubSidesheet = false
    this.closeModal()
    // this.closeSidesheet()
    type?this.util.success(this.getSuccesText(type)):false
  },err=>{
    type?this.util.error(this.getFailureText(type)):false
  })
}

getAutoScalingData(data,type?:any){
  let subsId = data[2]
  let rgName = data[1]
  let vmScaleSetName = data[0]
  return this.util.handleRequest('get','a3s_management_getAutoScalingData',[subsId,rgName,vmScaleSetName],null,null,null,true).then(res=>{
    let autoscaleList = res.body.value
    this.autoScaleData=autoscaleList.filter(e=>e.properties.targetResourceUri.toLowerCase()==this.appServicePlanData.id.toLowerCase())
    this.disableAutoScaleName=false
    console.log('autoscale List',autoscaleList)
  })
}
updateAutoScalingData(data,body,type?:any){
  let subsId = data[2]
  let rgName = data[1]
  let autoScaleName = this.currentForm.get('autoscaleName').value
  this.util.info(this.getInProgress(type))
  return this.util.handleRequest('post','a3s_management_updateAutoScalingData',[subsId,rgName,autoScaleName],body,null,null,true).then(res=>{
    console.log('Updated auto scale data',res)
    this.closeModal()
    this.util.success(this.getSuccesText(type))
  },err=>{
    this.util.error(this.getFailureText(type))
  })
}

deleteAppServicePlanData(data){
  let subscriptionId = data[2],resourceGroupName = data[1],appServicePlanName = data[0]
  this.util.info(`Deleting ${data[0] }...`)
  return this.util.handleRequest('delete','a3s_management_deleteAppServicePlanData',[subscriptionId,resourceGroupName,appServicePlanName],null,null,null,true).then(async res=>{
    this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
}
}

