
<div class="side-bar-container-2 scroll-y">
  <ul class="side-nav">
    <li class="text-right li-collapse-btn">
      <button class="collapse-arrow normal-view" (click)="collapseSider()"></button>
      <button class="collapse-arrow icon-view" tooltip="Expand Panel" container="body" placement="left" containerClass="mgmt-tooltip" (click)="collapseSider()"></button>
      <ng-container *ngFor="let menu of securityMenu;let f = index">
        <li class="menu-item">
          <div class="menu-inner" [class.open]="allowSubMenus(menu)" id="{{menu.menu}}" (click)="triggerMenuClick(menu)" [routerLink]="menu.route"  routerLinkActive="active">
            <div class="menu-icon normal-view"><img [src]="menu?.image" alt="Icon" /></div>
            <div class="menu-icon icon-view" [tooltip]="menu?.value" container="body" placement="left" containerClass="mgmt-tooltip"><img [src]="menu?.image" alt="Icon" /></div>
            <div class="menu-name">{{menu?.value}}</div>
          </div>
          <ul class="submenu" *ngIf="allowSubMenus(menu)" >
            <ng-container *ngFor="let list of allProjects$|async;let i = index">
              <li class="sub-item">
                <div class="submenu-inner" id="types_{{i}}"  (click)="setCurrentProject(list,i)" [routerLink]="menu.route"  routerLinkActive="active1" [class.active]="!currentProject&&i==0||currentProject == list.projectName">                  
                  <!-- <div class="sub-icon normal-view" *ngIf="menu?.menu == 'metrics'"><img [src]="getImage(list)" alt="Icon" /></div>
                  <div class="sub-icon icon-view" *ngIf="menu?.menu == 'metrics'" [tooltip]="list.projectName" container="body" placement="left" containerClass="mgmt-tooltip"><img [src]="getImage(list)" alt="Icon" /></div> -->
                  <div class="sub-name">{{list.projectName}}</div>
                </div>
              </li>
            </ng-container>
          </ul>

          <div class="no-access-tooltip-wrapper left-tool" *ngIf="menu?.restrictAccess" id="no-access-tooltip">
            <div class="left-pane">
              <img src="./assets/common-icons/no-access-info.svg">
            </div>
            <div class="right-pane">
              <div class="content">
                <span>You don’t have access to this tab</span>
                <p>To get access please contant your 
                  administrator.</p>
              </div>
              <img class="cursor-pointer" src="assets/Infra/blueprint/close.svg" (click)="menu.restrictAccess = false">
            </div>
          </div>

        </li>
      </ng-container>
  </ul>
</div>


<ng-container *ngIf="checkA3sAccess()">
  <div class="body-content background-grey" *ngIf="allowUrl('recommendations')">
    <div class="main mr-4 ml-4" >
        <app-breadcrumbs></app-breadcrumbs>
        <router-outlet></router-outlet>
    </div>
  </div>
  <div class="body-content background-grey" *ngIf="allowUrl('alertManagement')">
      <div class="main mr-4 ml-4" >
          <app-breadcrumbs></app-breadcrumbs>
          <router-outlet></router-outlet>
      </div>
  </div>

  <!-- For Security posture -->
  <div class="body-content pt-0" *ngIf="allowUrl('securityPosture')">
      <router-outlet></router-outlet>
  </div>
</ng-container>



