import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }


  timeFormatValidator (control: AbstractControl):{[key: string]: boolean} | null {
    let boolean = !moment(control.value,'h:mm:ss A',true).isValid();
    if(boolean){
      return {'timeFormatValidator': true}
    }
    return null;
  };

  dateFormatValidator (control: AbstractControl): { [key: string]: boolean } | null {
    let date_regex = /^\d{2}\/\d{2}\/\d{4}$/ ;
    let date_regex1 = /^\d{2}\-\d{2}\-\d{4}$/ ;
    let boolean = date_regex.test(control.value) || date_regex1.test(control.value)
    if(!boolean){
      return {'dateFormatValidator': true}
    }
    return null;
  }
  futureDateValidator (control: AbstractControl): { [key: string]: boolean } | null {
    let selectedDate =new Date(control.value).getTime();
    let currentDate = new Date().getTime() ;
    let boolean =(currentDate - selectedDate) < 0 ?true:false
    if(!boolean && selectedDate!=0){
      return {'futureDateValidator': true}
    }
    return null;
  }

  onlyNumberValidator (control: AbstractControl): { [key: string]: boolean } | null {
    let regex = /^[0-9]*$/ ;
    let boolean = regex.test(control.value)
    if(!boolean){
      return {'onlyNumberValidator': true}
    }
    return null;
  }

  formArraySameNameValidator(formArray) {
    return (control: AbstractControl):{[key: string]: boolean} | null => {
      if(!control.value || !formArray.controls.length) return;
      let string = control.value.toLowerCase()
      let boolean = formArray.controls.some((e,ind)=> e.get('value').value.toLowerCase() == string) ? true : false;
      if(boolean){
        return {'formArraySameNameValidator': true}
      }
      return null;
    };
  }

  specialCharacterValidator (control: AbstractControl):{[key: string]: boolean} | null {
    var regex = /^[0-9a-zA-Z\_]+$/
    let boolean = regex.test(control.value)
    if(!boolean){
      return {'specialCharacterValidator': true}
    }
    return null;
  };
  
  smallCharacterValidator (control: AbstractControl):{[key: string]: boolean} | null {
    var regex = /^[0-9a-z\-_]+$/
    let boolean = regex.test(control.value)
    if(!boolean){
      return {'smallCharacterValidator': true}
    }
    return null;
  };

  emailValidator (control: AbstractControl): { [key: string]: boolean } | null {
    const regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(control.value&&!regx.test(control.value.toLowerCase())){
      return {'emailValidator': true};
    }
    return null;
  }
  validateEmail (control: AbstractControl): { [key: string]: boolean } | null {
    const regx =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(control.value&&!regx.test(control.value.toLowerCase())){
      return {'validateEmail': true};
    }
    return null;
  }
  validName (control: AbstractControl): { [key: string]: boolean } | null {
    const regx =/^[A-Za-z0-9]*$/;
    const regx1 =/^[A-Za-z0-9_]+$/;
    if(control.value&&!regx.test(control.value)||!regx1.test(control.value[control.value.length-1])){
      return {'validateName': true};
    }
    return null;
  }

  userNameValidator (control: AbstractControl): { [key: string]: boolean } | null {
    let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    console.log(control)
    let splitValue = control.value ? control.value.split('') : null
    if(splitValue && (splitValue[0]=='_'||splitValue[0]=='-') || (control.value&&regex.test(control.value))){
      return {'userNameValidator': true};
    }
    return null;
  }

  sshKeyValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let startValue = 'ssh-rsa';
    let value = control.value
    if(!value) return;
    let isPresent = value.includes(startValue) ? false : true
    // let regex  = '/ssh-rsa AAAA[0-9A-Za-z+\/]+[=]{0,3} ([^@]+@[^@]+)#/'
    if(isPresent){
      return {'sshKeyValidator': true};
    }
    return null;
  }

  MatchPassword(password: string, confirmPassword: string) {  
    return (formGroup: FormGroup) => {  
      const passwordControl = formGroup.controls[password];  
      const confirmPasswordControl = formGroup.controls[confirmPassword];  
  
      if (!passwordControl || !confirmPasswordControl) {  
        return null;  
      }  
  
      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {  
        return null;  
      }  
  
      if (passwordControl.value !== confirmPasswordControl.value) {  
        confirmPasswordControl.setErrors({ passwordMismatch: true });  
      } else {  
        confirmPasswordControl.setErrors(null);  
      }  
    }  
  }  
  
}
