import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManualComponent } from './user-manual.component';
import { UserManualSidebarComponent } from './user-manual-sidebar/user-manual-sidebar.component';
import { A3sIntroductionComponent } from './a3s-introduction/a3s-introduction.component';
import { IntendedAudienceComponent } from './intended-audience/intended-audience.component';
import { A3sOverviewComponent } from './a3s-overview/a3s-overview.component';
import { A3sKeyCapabilitiesComponent } from './a3s-key-capabilities/a3s-key-capabilities.component';
import { A3sFunctionalBucketsComponent } from './a3s-functional-buckets/a3s-functional-buckets.component';
import { FunctionalOverviewComponent } from './functional-overview/functional-overview.component';
import { A3sSupportComponent } from './a3s-support/a3s-support.component';
import { AzureSupportComponent } from './azure-support/azure-support.component';
import { SapSupportComponent } from './sap-support/sap-support.component';
import { A3sProjectConceptComponent } from './a3s-project-concept/a3s-project-concept.component';
import { A3sUserScenariosComponent } from './a3s-user-scenarios/a3s-user-scenarios.component';
import { A3sFiltresComponent } from './a3s-filtres/a3s-filtres.component';
import { A3sLoginComponent } from './a3s-login/a3s-login.component';
import { A3sHomepageComponent } from './a3s-homepage/a3s-homepage.component';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';
import { DataAIComponent } from './data-ai/data-ai.component';
import { InfrastructureHealthMonitoringComponent } from './infrastructure-health-monitoring/infrastructure-health-monitoring.component';
import { ApplicationHealthMonitoringComponent } from './application-health-monitoring/application-health-monitoring.component';
import { CostMonitoringAndOptimizationComponent } from './cost-monitoring-and-optimization/cost-monitoring-and-optimization.component';
import { GovernanceComponent } from './governance/governance.component';
import { SecurityComponent } from './security/security.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { ComplianceOverviewComponent } from './compliance/compliance-overview/compliance-overview.component';
import { ComplianceManagePolicyComponent } from './compliance/compliance-manage-policy/compliance-manage-policy.component';
import { IndustryAndRegulatoryStandardsComponent } from './compliance/industry-and-regulatory-standards/industry-and-regulatory-standards.component';
import { ComplianceReportsComponent } from './compliance/compliance-reports/compliance-reports.component';
import { ArchitectureBlueprintsComponent } from './infrastructure/architecture-blueprints/architecture-blueprints.component';
import { DesignToolComponentsComponent } from './infrastructure/design-tool-components/design-tool-components.component';
import { UsingArchiDesignToolComponent } from './infrastructure/using-archi-design-tool/using-archi-design-tool.component';
import { ProjectStatusComponent } from './infrastructure/project-status/project-status.component';
import { InfrastructureManagementComponent } from './infrastructure/infrastructure-management/infrastructure-management.component';
import { CreateComponent } from './infrastructure/architecture-blueprints/create/create.component';
import { DeleteComponent } from './infrastructure/architecture-blueprints/delete/delete.component';
import { InReviewComponent } from './infrastructure/project-status/in-review/in-review.component';
import { ApprovedComponent } from './infrastructure/project-status/approved/approved.component';
import { DeployedComponent } from './infrastructure/project-status/deployed/deployed.component';
import { FailedComponent } from './infrastructure/project-status/failed/failed.component';
import { ResourceManagementComponent } from './infrastructure/infrastructure-management/resource-management/resource-management.component';
import { InfrastructureManagementOverviewComponent } from './infrastructure/infrastructure-management/infrastructure-management-overview/infrastructure-management-overview.component';
import { DataMigrationComponent } from './data-ai/data-migration/data-migration.component';
import { DataGovernanceComponent } from './data-ai/data-governance/data-governance.component';
import { DataAnalyticsComponent } from './data-ai/data-analytics/data-analytics.component';
import { DataopsComponent } from './data-ai/dataops/dataops.component';
import { DemandForecastingComponent } from './data-ai/data-analytics/demand-forecasting/demand-forecasting.component';
import { FormRecognitionComponent } from './data-ai/data-analytics/form-recognition/form-recognition.component';
import { SalesPredictionComponent } from './data-ai/data-analytics/sales-prediction/sales-prediction.component';
import { RevenuePredictionComponent } from './data-ai/data-analytics/revenue-prediction/revenue-prediction.component';
import { KnowledgeMiningComponent } from './data-ai/data-analytics/knowledge-mining/knowledge-mining.component';
import { ModernFinanceComponent } from './data-ai/data-analytics/modern-finance/modern-finance.component';
import { ProductSeasonalityComponent } from './data-ai/data-analytics/product-seasonality/product-seasonality.component';
import { DataMigrationOverviewComponent } from './data-ai/data-migration/data-migration-overview/data-migration-overview.component';
import { SqlServerComponent } from './data-ai/data-migration/sql-server/sql-server.component';
import { OracleServerComponent } from './data-ai/data-migration/oracle-server/oracle-server.component';
import { SapHanaComponent } from './data-ai/data-migration/sap-hana/sap-hana.component';
import { SapEccComponent } from './data-ai/data-migration/sap-ecc/sap-ecc.component';
import { AzureBlobStorageComponent } from './data-ai/data-migration/azure-blob-storage/azure-blob-storage.component';
import { AddStandardsComponent } from './compliance/industry-and-regulatory-standards/add-standards/add-standards.component';
import { DefaultA3sSecurityComponent } from './compliance/compliance-manage-policy/default-a3s-security/default-a3s-security.component';
import { ManagePolicyComponent } from './compliance/compliance-manage-policy/manage-policy/manage-policy.component';
import { ComplianceBenchmarkComponent } from './compliance/compliance-overview/compliance-benchmark/compliance-benchmark.component';
import { ComplianceStatusComponent } from './compliance/compliance-overview/compliance-status/compliance-status.component';
import { InfrastructureHealthMonitoringOverviewComponent } from './infrastructure-health-monitoring/infrastructure-health-monitoring-overview/infrastructure-health-monitoring-overview.component';
import { MetricsInfrasHealthMonitoringComponent } from './infrastructure-health-monitoring/metrics-infras-health-monitoring/metrics-infras-health-monitoring.component';
import { InsightsInfraHealthMonitoringComponent } from './infrastructure-health-monitoring/insights-infra-health-monitoring/insights-infra-health-monitoring.component';
import { AlertsInfraHealthMonitoringComponent } from './infrastructure-health-monitoring/alerts-infra-health-monitoring/alerts-infra-health-monitoring.component';
import { OverviewApplicationHealthMonitoringComponent } from './application-health-monitoring/overview-application-health-monitoring/overview-application-health-monitoring.component';
import { MetricsApplicationHealthMonitoringComponent } from './application-health-monitoring/metrics-application-health-monitoring/metrics-application-health-monitoring.component';
import { InsightsApplicationHealthMonitoringComponent } from './application-health-monitoring/insights-application-health-monitoring/insights-application-health-monitoring.component';
import { AlertsApplicationHealthMonitoringComponent } from './application-health-monitoring/alerts-application-health-monitoring/alerts-application-health-monitoring.component';
import { OverviewCmAndOptimizationComponent } from './cost-monitoring-and-optimization/overview-cm-and-optimization/overview-cm-and-optimization.component';
import { CostAnalysisComponent } from './cost-monitoring-and-optimization/cost-analysis/cost-analysis.component';
import { OptimizeCmoComponent } from './cost-monitoring-and-optimization/optimize-cmo/optimize-cmo.component';
import { BudgetAndAlertsComponent } from './cost-monitoring-and-optimization/budget-and-alerts/budget-and-alerts.component';
import { BillingComponent } from './cost-monitoring-and-optimization/billing/billing.component';
import { OverviewGovernanceComponent } from './governance/overview-governance/overview-governance.component';
import { EnterprisePolicyGovernanceComponent } from './governance/enterprise-policy-governance/enterprise-policy-governance.component';
import { IdentityAndAccessManagementComponent } from './governance/identity-and-access-management/identity-and-access-management.component';
import { AuditingAndLoggingComponent } from './governance/auditing-and-logging/auditing-and-logging.component';
import { AlertsSecurityComponent } from './security/alerts-security/alerts-security.component';
import { OverviewSecurityComponent } from './security/overview-security/overview-security.component';
import { SecurityPostureComponent } from './security/security-posture/security-posture.component';
import { FormsModule } from '@angular/forms';
import { ListOfStandardsComponent } from './compliance/compliance-overview/list-of-standards/list-of-standards.component';
import { DeployStandardsComponent } from './compliance/compliance-overview/deploy-standards/deploy-standards.component';
import { OverviewAlertsComponent } from './security/overview-security/overview-alerts/overview-alerts.component';
import { CoverageComponent } from './security/overview-security/coverage/coverage.component';
import { OverallScoreComponent } from './security/overview-security/overall-score/overall-score.component';
import { ActiveRecomComponent } from './security/overview-security/active-recom/active-recom.component';
import { IdentitySecurityComponent } from './security/security-posture/identity-security/identity-security.component';
import { SecRecommComponent } from './security/security-posture/sec-recomm/sec-recomm.component';
import { EndpointComponent } from './security/security-posture/endpoint/endpoint.component';
import { SecActivitiesComponent } from './security/security-posture/sec-activities/sec-activities.component';
import { AssignPolicyComponent } from './governance/enterprise-policy-governance/assign-policy/assign-policy.component';
import { AssignInitiativesComponent } from './governance/enterprise-policy-governance/assign-initiatives/assign-initiatives.component';
import { SignInsComponent } from './governance/auditing-and-logging/sign-ins/sign-ins.component';
import { AuditLogsComponent } from './governance/auditing-and-logging/audit-logs/audit-logs.component';
import { ActivityLogsComponent } from './governance/auditing-and-logging/activity-logs/activity-logs.component';
import { IdenOverviewComponent } from './governance/identity-and-access-management/iden-overview/iden-overview.component';
import { AddUserComponent } from './governance/identity-and-access-management/add-user/add-user.component';
import { InviteUserComponent } from './governance/identity-and-access-management/invite-user/invite-user.component';
import { AddGroupComponent } from './governance/identity-and-access-management/add-group/add-group.component';
import { AddRoleAssignComponent } from './governance/identity-and-access-management/add-role-assign/add-role-assign.component';
import { GroupManagementComponent } from './governance/identity-and-access-management/group-management/group-management.component';
import { UserManagementComponent } from './governance/identity-and-access-management/user-management/user-management.component';
import { BillInvoiceComponent } from './cost-monitoring-and-optimization/billing/bill-invoice/bill-invoice.component';
import { BillingProfileComponent } from './cost-monitoring-and-optimization/billing/billing-profile/billing-profile.component';
import { ReservationTranscationComponent } from './cost-monitoring-and-optimization/billing/reservation-transcation/reservation-transcation.component';
import { CreateSetComponent } from './cost-monitoring-and-optimization/budget-and-alerts/create-set/create-set.component';
import { QueriesComponent } from './infrastructure-health-monitoring/insights-infra-health-monitoring/queries/queries.component';
import { CustomQueryComponent } from './infrastructure-health-monitoring/insights-infra-health-monitoring/custom-query/custom-query.component';
import { CreateNewActionComponent } from './infrastructure-health-monitoring/alerts-infra-health-monitoring/create-new-action/create-new-action.component';
import { CreateNewAlertsComponent } from './infrastructure-health-monitoring/alerts-infra-health-monitoring/create-new-alerts/create-new-alerts.component';
import { AvailabilityComponent } from './application-health-monitoring/metrics-application-health-monitoring/availability/availability.component';
import { AverageComponent } from './application-health-monitoring/metrics-application-health-monitoring/average/average.component';
import { MetricsServerReqComponent } from './application-health-monitoring/metrics-application-health-monitoring/metrics-server-req/metrics-server-req.component';
import { MetricsExceptionComponent } from './application-health-monitoring/metrics-application-health-monitoring/metrics-exception/metrics-exception.component';
import { MetricsResponseTimeComponent } from './application-health-monitoring/metrics-application-health-monitoring/metrics-response-time/metrics-response-time.component';
import { MetricsEventsComponent } from './application-health-monitoring/metrics-application-health-monitoring/metrics-events/metrics-events.component';
import { MetricsPageViewComponent } from './application-health-monitoring/metrics-application-health-monitoring/metrics-page-view/metrics-page-view.component';
import { ApplicationCreateNewActionComponent } from './application-health-monitoring/alerts-application-health-monitoring/application-create-new-action/application-create-new-action.component';
import { ApplicationCreateNewAlertComponent } from './application-health-monitoring/alerts-application-health-monitoring/application-create-new-alert/application-create-new-alert.component';
import { UsersByCountryComponent } from './application-health-monitoring/insights-application-health-monitoring/users-by-country/users-by-country.component';
import { UsageSessionComponent } from './application-health-monitoring/insights-application-health-monitoring/usage-session/usage-session.component';
import { UsageEventsComponent } from './application-health-monitoring/insights-application-health-monitoring/usage-events/usage-events.component';
import { UsageOverallRetentionsComponent } from './application-health-monitoring/insights-application-health-monitoring/usage-overall-retentions/usage-overall-retentions.component';
import { UsersReturnedComponent } from './application-health-monitoring/insights-application-health-monitoring/users-returned/users-returned.component';
import { InitiateReturnEventComponent } from './application-health-monitoring/insights-application-health-monitoring/initiate-return-event/initiate-return-event.component';
import { CostComponent } from './cost-monitoring-and-optimization/billing/cost/cost.component';
import { RetailRecommenderComponent } from './data-ai/data-analytics/retail-recommender/retail-recommender.component';



@NgModule({
  declarations: [
    UserManualComponent, 
    UserManualSidebarComponent, 
    A3sIntroductionComponent, 
    IntendedAudienceComponent, 
    A3sOverviewComponent, 
    A3sKeyCapabilitiesComponent, 
    A3sFunctionalBucketsComponent, 
    FunctionalOverviewComponent, 
    A3sSupportComponent, 
    AzureSupportComponent, 
    SapSupportComponent, 
    A3sProjectConceptComponent, 
    A3sUserScenariosComponent, 
    A3sFiltresComponent, 
    A3sLoginComponent, 
    A3sHomepageComponent, 
    InfrastructureComponent, 
    DataAIComponent, 
    InfrastructureHealthMonitoringComponent, 
    ApplicationHealthMonitoringComponent, 
    CostMonitoringAndOptimizationComponent, 
    GovernanceComponent, 
    SecurityComponent, 
    ComplianceComponent, 
    ComplianceOverviewComponent, 
    ComplianceManagePolicyComponent, 
    IndustryAndRegulatoryStandardsComponent, 
    ComplianceReportsComponent, 
    ArchitectureBlueprintsComponent, 
    DesignToolComponentsComponent, 
    UsingArchiDesignToolComponent, 
    ProjectStatusComponent, 
    InfrastructureManagementComponent, 
    CreateComponent, 
    DeleteComponent, 
    InReviewComponent, 
    ApprovedComponent, 
    DeployedComponent, 
    FailedComponent, 
    ResourceManagementComponent, 
    InfrastructureManagementOverviewComponent, 
    DataMigrationComponent, 
    DataGovernanceComponent, 
    DataAnalyticsComponent, 
    DataopsComponent, 
    DemandForecastingComponent, 
    FormRecognitionComponent, 
    SalesPredictionComponent, 
    RevenuePredictionComponent, 
    KnowledgeMiningComponent, 
    ModernFinanceComponent, 
    ProductSeasonalityComponent, 
    DataMigrationOverviewComponent, 
    SqlServerComponent, 
    OracleServerComponent, 
    SapHanaComponent, 
    SapEccComponent, 
    AzureBlobStorageComponent, 
    AddStandardsComponent, 
    DefaultA3sSecurityComponent, 
    ManagePolicyComponent, 
    ComplianceBenchmarkComponent, 
    ComplianceStatusComponent, 
    InfrastructureHealthMonitoringOverviewComponent, 
    MetricsInfrasHealthMonitoringComponent, 
    InsightsInfraHealthMonitoringComponent, 
    AlertsInfraHealthMonitoringComponent, 
    OverviewApplicationHealthMonitoringComponent, 
    MetricsApplicationHealthMonitoringComponent, 
    InsightsApplicationHealthMonitoringComponent, 
    AlertsApplicationHealthMonitoringComponent, 
    OverviewCmAndOptimizationComponent, 
    CostAnalysisComponent, 
    OptimizeCmoComponent, 
    BudgetAndAlertsComponent, 
    BillingComponent, 
    OverviewGovernanceComponent, 
    EnterprisePolicyGovernanceComponent, 
    IdentityAndAccessManagementComponent, 
    AuditingAndLoggingComponent, 
    AlertsSecurityComponent, 
    OverviewSecurityComponent, 
    SecurityPostureComponent, ListOfStandardsComponent, DeployStandardsComponent, OverviewAlertsComponent, CoverageComponent, OverallScoreComponent, ActiveRecomComponent, IdentitySecurityComponent, SecRecommComponent, EndpointComponent, SecActivitiesComponent, AssignPolicyComponent, AssignInitiativesComponent, SignInsComponent, AuditLogsComponent, ActivityLogsComponent, IdenOverviewComponent, AddUserComponent, InviteUserComponent, AddGroupComponent, AddRoleAssignComponent, GroupManagementComponent, UserManagementComponent, BillInvoiceComponent, BillingProfileComponent, ReservationTranscationComponent, CreateSetComponent, QueriesComponent, CustomQueryComponent, CreateNewActionComponent, CreateNewAlertsComponent, AvailabilityComponent, AverageComponent, MetricsServerReqComponent, MetricsExceptionComponent, MetricsResponseTimeComponent, MetricsEventsComponent, MetricsPageViewComponent, ApplicationCreateNewActionComponent, ApplicationCreateNewAlertComponent, UsersByCountryComponent, UsageSessionComponent, UsageEventsComponent, UsageOverallRetentionsComponent, UsersReturnedComponent, InitiateReturnEventComponent, CostComponent, RetailRecommenderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ]
})
export class UserManualModule  { }
