<div class="introduction">

    
            <p class="heading" id="0">Assign Policy  </p>
            <ul class="mb-0" >
                <li>Click on <b>Assign policy</b>.    </li>
                <li>Select <b>Scope</b> (Subscription).    </li>
                  <li>Exclusion (optional), in case you want to exclude any Resource Group(s). </li>
                  <li>Choose<b>Policy definition</b>  from the list. </li>
                  <li>Provide name for the <b>Assignment</b> and a <b>Description. </b>  </li>
                  <li>Select <b>Policy enforcement</b> (enabled/disabled).   </li>
                    <li>Parameters of the policy varies based on policy definition. Select correct <b>Parameters.</b>  </li>
                    <li>Fill <b>non-compliance messages</b> with meaningful message as it helps in understanding 
                        why a resource is not compliant.  </li>
                        <li>Click <b>Assign</b>. </li>
              </ul>
              <img style="width: 50%;height: 50%;" src="assets\user-manual\governance\Side-sheet.svg" >
              <figcaption class="caption col-md-6" style="padding-left: 150px;">Assign policy side sheet  </figcaption>



</div>
