<ng-container [ngTemplateOutlet]="currentTemplate"></ng-container>

<ng-template #dataDisk>
    <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4>Data disks<br> <span class="blur-text">Virtual Machine | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form>
                <div class="flex-custom no-wrap" *ngFor="let list of vmData?.properties?.storageProfile?.dataDisks;let i = index">
                    <div class="w-50">
                        <label class="flex-custom no-wrap">LUN</label>
                        <input class="form-control half-width" type="text" autocomplete="off" id="LUNId" name="LUNId" [(ngModel)]="list.lun"/>
                    </div>
                    <div class="w-50">
                        <label class="flex-custom no-wrap">Name</label>
                        <span class="text-14-22">{{list?.name}}</span>
                    </div>
                </div>
                <div class="comm-mb">
                    <span class="action-btn" (click)="showSubSidesheet = true">Create and attach a new disk</span>
                </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" (click)="saveVMData('dataDisk')">Save</button>
        </div>
    </div>
    <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4>Create a new disk</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="diskName" name="diskName" formControlName="name"/>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Source type<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Create a disk from a snapshot of another disk, a blob in a storage account, or create an empty disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <ng-select [items]="sourceTypeList" bindLabel="name" bindValue="name" name="sourceType" [clearable]="false" id="sourceType" formControlName="sourceType"></ng-select>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Size<span class="mandatory">*</span></label>
                    <span class="text-12-18 bold-600">1024 GiB</span> <br>
                    <span class="text-12-18">Premium SSD LRS</span> <br>
                    <span class="action-btn">Change size</span>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Encryption type<span class="mandatory">*</span></label>
                    <ng-select [items]="diskEncryptionTypeList" bindLabel="name" bindValue="id" name="encryptionType" [clearable]="false" id="encryptionType" formControlName="encryptionType"></ng-select>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Enable shared disk<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to be attached to two or more virtual machines, depending on storage type and disk size. When shared disk is enabled host caching is unavailable." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="flex-custom no-wrap">
                        <div class="checkbox-cs">
                            <input type="radio" name="enableSharedDisk"  class="form-check-inline" id='yes' (change)="setEnableSharedDisk('yes')">
                            <label class="value" for="yes">Yes</label>
                        </div>
                        <div class="checkbox-cs ml-2">
                            <input type="radio" name="enableSharedDisk" class="form-check-inline" id='no' (change)="setEnableSharedDisk('no')" checked>
                            <label class="value" for="no">No</label>
                        </div>
                        </div>
                </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid">Save</button>
        </div>
    </div>
</ng-template>

<!-- <ng-template #connectVM>
    <div class="sidebar-right single add-alert" >
        <div class="header">
            <div class="d-flex">
                <h4>Connect<br> <span class="blur-text">Virtual Machine | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                 
                <div class="main-nav">
                    <ul class="nav-wrap m-0" style="padding: 0px;display: flex;">
                      <li class="list-item cursor-pointer" [class.active]="currentConnectTab == 'RDP'" (click)="currentConnectTab = 'RDP'">
                        <span class="menu-name">RDP</span>
                      </li>
                      <li class="list-item cursor-pointer" [class.active]="currentConnectTab == 'SSH'" (click)="currentConnectTab = 'SSH'">
                        <span class="menu-name">SSH</span>
                      </li>
                    </ul>
                </div>

                <ng-container *ngIf="currentConnectTab == 'RDP'">
                    <div class="sub-title mt-4">Connect with RDP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="To connect to your virtual machine via RDP, select an IP address,optionally change the port number, and download the RDP file." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></div>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">IP address<span class="mandatory">*</span></label>
                        <ng-select class="full-width" [items]="vmIpAddressList" bindLabel="name" bindValue="value" name="ipAddress" formControlName="rdpIp" [clearable]="false" id="ipAddress"></ng-select>
                    </div>   
    
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Port number<span class="mandatory">*</span></label>
                        <input class="form-control full-width" type="text" autocomplete="off" id="portNumber" formControlName="rdpPort" name="portNumber" />
                    </div>   
                    <button class="btn btn-btn-primary full-width" [class.disabled]="currentForm?.invalid" (click)="downloadRDP()">Download RDP file</button>
                </ng-container>
                <ng-container *ngIf="currentConnectTab == 'SSH'">
                    <div class="sub-title mt-4">Connect via SSH with client</div>
                    
                    <div class="comm-mb">
                        <span class="text-12-18">1. Open the client of your choice, e.g. PuTTY or other clients.</span>
                    </div>
                    <div class="comm-mb">
                        <span class="text-12-18">2. Ensure you have read-only access to the private key.</span>
                        <div class="pw-input">
                            <div class="pw-outer full-width">
                              <input class="form-control full-width" type="text" formControlName="sshPrivateKey" id="privateKey"/>
                            </div>
                            <img class="copy-img tooltip-icon" matTooltip="Copy" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" src="/assets/governance/copy.svg" alt="Icon" (click)="copyClipboard($event,'privateKey')">
                        </div>
                    </div>
                    <div class="comm-mb">
                        <span class="text-12-18">3. Provide a path to your SSH private key file.<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="By default, SSH key pairs are created in the ~/.ssh directory with 
                            a key name of 'id_rsa'. If either the key name or specified directory 
                            were created differently, the private key path and name are required 
                            to successfully connect." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span>
                        <label class="flex-custom no-wrap mt-3">Private key path</label>
                        <input class="form-control full-width" type="text" (blur)="updateSSHCommand()" autocomplete="off" formControlName="sshPrivatePath" id="portNumber" name="portNumber" />
                    </div>
                    <div class="comm-mb">
                        <span class="text-12-18">4. Run the example command below to connect to your VM.</span>
                        <div class="pw-input">
                            <div class="pw-outer full-width">
                              <input class="form-control full-width" type="text" formControlName="sshCommand" id="vmCommand"/>
                            </div>
                            <img class="copy-img tooltip-icon" matTooltip="Copy" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" src="/assets/governance/copy.svg" alt="Icon" (click)="copyClipboard($event,'vmCommand')">
                        </div>
                    </div>
                </ng-container>
              


                <div class="comm-mb" *ngIf="data[4] !== 'running'">
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        Connect is disabled because this virtual machine is deallocated.</label>
                    </div>  
                </div>
                
            </form>
        </div>
    </div>
</ng-template> -->

<!-- <ng-template #autoShutdown>
    <div class="sidebar-right single add-alert">
        <div class="header">
            <div class="d-flex">
               <h4>Auto-shutdown<br> <span class="blur-text">Virtual Machine | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <div class="checkbox-cs"> 
                      <input type="checkbox" name="enable-auto" class="form-check-inline" [class.disabled-icon]="disableAutoShutdown" id='enable-auto' formControlName="enableAutoShutdown" (change)="toggleTimeValidator($event)">
                      <label class="value" for="enable-auto" [class.disabled-icon]="disableAutoShutdown">Enable auto-shutdown <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures your virtual machine to automatically shutdown daily." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    </div>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('enableAutoShutdown').value">
                    <label class="flex-custom no-wrap">Shutdown time <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures the time when the virtual machine shuts down daily." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="time" name="time" placeholder="h:mm:ss A" formControlName="shutdownTime" [class.border-danger]="(currentForm.get('shutdownTime')?.dirty ||  currentForm.get('shutdownTime')?.touched )&& currentForm.get('shutdownTime')?.errors?.timeFormatValidator"/>
                    <p class="text-danger" *ngIf="(currentForm.get('shutdownTime')?.dirty ||  currentForm.get('shutdownTime')?.touched )&& currentForm.get('shutdownTime')?.errors?.timeFormatValidator">Time format must match 'h:mm:ss A'.</p>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('enableAutoShutdown').value">
                    <label class="flex-custom no-wrap">Time zone <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Subscribe for notifications before the virtual machine is shutdown so that you can skip/delay the shutdown." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <ng-select [items]="timeZoneList" bindLabel="time" bindValue="name" name="timeZone" [clearable]="false" id="timeZone" formControlName="timeZone"></ng-select>
                </div>
                <div class="comm-mb"  *ngIf="currentForm.get('enableAutoShutdown').value">
                    <div class="checkbox-cs">
                      <input type="checkbox" name="notify-shutdown" class="form-check-inline" id='notify-shutdown' formControlName="notifyShutdown" (change)="toggleEmailValidator($event)">
                      <label class="value" for="notify-shutdown">Notification before shutdown <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures the time zone in which the auto-shutdown time is." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    </div>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('enableAutoShutdown').value && currentForm.get('notifyShutdown').value">
                    <label class="flex-custom no-wrap">Email <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The email address where notifications will be sent." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="email" name="email" formControlName="email" [class.border-danger]="currentForm.get('email')?.errors?.email"/>
                    <p class="text-danger" *ngIf="currentForm.get('email')?.errors?.email">Please provide a valid email address.</p>
                </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid" (click)="saveVMData('autoShutdown')">Save</button>
        </div>
    </div>
</ng-template> -->

<ng-template #addressSpace>
    <div class="sidebar-right single add-alert">
        <div class="header">
            <div class="d-flex">
               <h4>Address Space<br> <span class="blur-text">Virtual Network | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput();resetValidation()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="" *ngIf="addressSpaceList?.length">
                    <label class="flex-custom no-wrap">Address space</label>
                    <ng-container formArrayName="addressSpaceArray" *ngFor="let list of currentForm.get('addressSpaceArray')['controls']; let i = index;">
                        <div class="notify-wrap" id="address-space-list-{{i}}" [formGroupName]="i">
                            <input class="form-control full-width" type="text" autocomplete="off" id="addressSpace-{{i}}" name="addressSpace-{{i}}" formControlName="value" (keyup)="subnetAddressSpace($event,'addressSpace');addressSpaceoverlap($event,'addressSpace',i);saveDisable=true"/>
                            <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeAddressSpace(i);saveDisable=true" [class.disabled-icon]="addressSpaceList?.length <= 1">
                            <!-- (keyup)="validNICAddressSpace($event,'source')" -->
                        </div>
                        <!-- <p class="text-danger" *ngIf="currentForm.get('value')?.dirty || currentForm.get('value')?.touched checkIPValidation(i,'addressSpace')">Address spaces within a virtual network cannot overlap.</p> -->
                    </ng-container>
                    <!-- <p class="text-danger" *ngIf="checkIPValidation(i,'addressSpace')">Address spaces within a virtual network cannot overlap.</p> -->
                    <p class="text-danger" *ngIf="inputError?.emptyBox">The value must not be empty. IPv4: null. IPv6: IPv6 Address range cannot be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="inputError?.dnsUnique">Address spaces within a virtual network cannot overlap.</p> 
                    <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid argument: 'The prefix must be between 1 and 32.'.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetOverlaping ">Address space {{inputAddress}} overlaps with address space {{vNetOverlap}}. Address spaces within A virtual network cannot overlap. </p>
                    <!-- <p class="text-danger" *ngIf="inputError?.subnetPerfix">Invalid argument: 'The prefix must be between 1 and 32.'.</p> -->
                </div>
                <div class="mt-1">
                    <span class="action-btn" (click)="addAddressSpace()">Add address space</span>
                </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="inputError?.subnetNotValidCIDR||inputError?.subnetAdrspc||inputError?.subnetOverlaping || !saveDisable" (click)="saveVNETData('addressSpace')">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #subnet>
    <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4>Subnet<br> <span class="blur-text">Virtual Network | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <ng-container *ngIf="vnetData?.properties?.subnets?.length" >
                <div class="" >
                    <div class="flex-custom no-wrap mb-2">
                        <div class="w-50">
                            <label class="flex-custom no-wrap blur-text" style="color: #3E4852;">Name</label>
                        </div>
                        <div class="w-50">
                            <label class="flex-custom no-wrap blur-text" style="color: #3E4852;">IPv4</label>
                        </div>
                    </div>
                    <div class="" *ngFor="let list of vnetData?.properties?.subnets;let i = index">
                        <div class="flex-custom no-wrap mb-2">
                            <div class="w-75">
                                <span class="text-12-18">{{list?.name}}</span>
                            </div>
                            <div class="w-50">
                                <span class="text-12-18">{{list?.properties?.addressPrefix | displayTxt}}</span>
                            </div>
                            <img src="assets/app-monitoring/Edit.svg" alt="" class="cursor-pointer ml-2" (click)="showSubSidesheet = true;editSubnet(list);resetValidation()">
                            <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeSubnet(i);saveDisable=true">
                        </div>
                    </div>
                </div>
              </ng-container>
                <span class="action-btn" (click)="showSubSidesheet = true;addDefaultData('vNetSubnet','addSubnet');resetValidation()">Add subnet</span>
                <br>
                <span class="action-btn" [class.disable-text]="showGatewaySubnet" (click)="showSubSidesheet = true;addDefaultData('vNetSubnet','addGatewaySubnet');resetValidation()">Add Gateway subnet</span>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="!saveDisable" (click)="UpdateVNETData(data,vnetData,'')">Save</button>
        </div>
    </div>
    <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4>Subnet</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Virtual network<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width disabled" type="text" autocomplete="off" id="VNetName" name="VirtualNetworkName" formControlName="vnetName"/>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Subnet name<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A subnet is a range of IP addresses in your virtual network, which can be used to isolate virtual machines from each other or from the Internet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="subnetName" name="subnetName" formControlName="subnetName" (keyup)="validIPName($event,80,'ipName','vnetSubnet')"/>
                    <p class="text-danger" *ngIf="inputError?.ipNameMaxLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.ipNameExist">The name you entered matches another subnet within this virtual network. Subnet names must be unique within a virtual network.</p>
                    <p class="text-danger" *ngIf="inputError?.ipNameValid || inputError?.ipNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                        and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Subnet address space<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The subnet's address range in CIDR notation (e.g. 192.168.1.0/24). 
                        It must be contained by the address space of the virtual network. 
                        The address range of a subnet which is in use can't be edited." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="subnetAddressSpace" name="subnetAddressSpace" placeholder="eg. 10.0.0.0/24" formControlName="subnetAddressSpace" (keyup)="subnetAddressSpace($event);subnetOverlap($event)"/>
                    <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid CIDR Block.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetOverlaping">This subnet address space overlaps with existing {{vNetOverlap}}</p>
                    <p class="text-danger" *ngIf="inputError?.subnetPerfix">The prefix must be smaller than or equal to 29</p>
                    <p class="text-danger" *ngIf="inputError?.subnetAddresNotInVnet">Subnet address range should contained under same virtual network's address spaces</p>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Description</label>
                    <textarea name="comment" class="full-width form-control" placeholder="Enter a reason for adding this resource..." formControlName="description"></textarea>
                </div>
                <div class="comm-mb">
                    <div class="divider"></div>
                </div>
                <div class="toggle-sec">
                    <div class="toggle-head comm-mb" id="toggleIcon" (click)="toggle()">{{getToggleText()}}</div>
                    <div class="toggle-cont" id="toggleContent">
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">NAT gateway<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="NAT gateway associated to this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <ng-select class="full-width" [items]="natGetwaysList" bindLabel="name" bindValue="id" name="natGateway" [clearable]="false" id="natGateway" formControlName="natGateway"></ng-select>
                        </div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Network Security Group</label>
                            <ng-select class="full-width" [items]="nsgList" bindLabel="name" bindValue="id" name="nsgName" [clearable]="false" id="nsgName" formControlName="nsg"></ng-select>
                        </div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Route Table</label>
                            <ng-select class="full-width" [items]="routeTablesList" bindLabel="name" bindValue="id" name="routeTable" [clearable]="false" id="routeTable" formControlName="routeTable"></ng-select>
                        </div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Services<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enable one or more service endpoints for this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                           <div class="margin-multi-select">
                            <ng-select class="full-width" [items]="servicesList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="name" name="service" [clearable]="false" id="service" formControlName="services">
                                <ng-template ng-multi-label-tmp let-items="items">
                                    <span>{{items.length}} selected</span>
                                  </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <div>
                                      <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected"  [checked]="item$.selected"/> {{item.name}}
                                      <!-- <label class="value" for="item-{{index}}" [ngModel]="item$.selected" [checked]="item$.selected">{{item.name}}</label> -->
                                    </div>
                                  </ng-template>
                            </ng-select>
                           </div>
                        </div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Delegate subnet to a service<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Designate a subnet to be used by a dedicated service." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <ng-select class="full-width" [items]="dsServiceList" bindLabel="serviceName" bindValue="serviceName" name="delegateSubnet" [clearable]="false" id="delegateSubnet" formControlName="delegatedSubnet" ></ng-select>
                        </div>
                    </div>
                </div>                    
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-outline" (click)="triggerOutput()">Cancel</button>
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid||invalidForm('vnetSubnet')"  (click)="saveVNETData('subnet')">Save</button>
        </div>
    </div>
</ng-template>


<ng-template #ddosProtection>
    <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4>DDoS Protection<br> <span class="blur-text">Virtual Network | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">DDoS protection standard<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A DDoS protection plan is a paid service that offers enhanced DDoS 
                        mitigation capabilities via adaptive tuning, attack notification, and 
                        telemetry to protect against the impacts of a DDoS attack for all 
                        protected resources within this virtual network. Basic DDoS protection 
                        is integrated into the Azure platform by default and at no additional cost." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="flex-custom no-wrap">
                        <div class="checkbox-cs">
                            <input type="radio" name="ddosProtection"  class="form-check-inline" id='disable' (change)="setDdosProtection('disable')" [checked]="currentForm.get('ddosProtection')?.value == false">
                            <label class="value" for="disable">Disable</label>
                        </div>
                        <div class="checkbox-cs ml-2">
                            <input type="radio" name="ddosProtection" class="form-check-inline" id='enable' (change)="setDdosProtection('enable')" [checked]="currentForm.get('ddosProtection')?.value == true">
                            <label class="value" for="enable">Enable</label>
                        </div>
                    </div>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('ddosProtection')?.value == true">
                    <label class="flex-custom no-wrap">DDoS protection plan<span class="mandatory">*</span></label>
                    <ng-select [items]="ddosProtectionPlanList" bindLabel="name" bindValue="id" name="planID" [clearable]="false" id="planID" formControlName="ddosProtectionPlan"></ng-select>
                    <span class="action-btn" (click)="showSubSidesheet = true;initialiseSubForm('ddosProtection')">Create new</span>
                </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid" (click)="saveVNETData('ddosProtection')">Save</button>
        </div>
    </div>
    <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4>DDoS Protection Plan<br> <span class="blur-text">Virtual Network | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentSubForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="name" name="name" formControlName="name" (keyup)="validIPName($event,62,'ipName','ddosName')"/>
                    <p class="text-danger" *ngIf="inputError?.ipNameMaxLen">The name must be between 2 and 64 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.ipNameExist">There is already a resource with the same name and type within the current resource group. </p>
                    <p class="text-danger" *ngIf="inputError?.ipNameValid||inputError?.ipNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                        and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>     
                <div class="comm-mb">
                    <ng-container formArrayName="tagsArray" *ngFor="let tanch of currentSubForm.get('tagsArray')['controls']; let i = index;">
                      <div class="comm-mb ">
                        <div class="notify-wrap" id="tags-list-{{i}}" [formGroupName]="i">
                          <div class="w-50">
                            <label class="flex-custom nowrap">Tag name<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width" type="text" formControlName="name"/>
                          </div>
                          <span class="ml-3 mt-3">:</span>
                          <div class="w-50 ml-3">
                            <label class="flex-custom nowrap">Value<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width" type="text" formControlName="value"/>
                          </div>
                          <ng-container *ngIf="tagsList?.length > 1">
                            <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeTag(i)">
                          </ng-container>
                        </div>
                        <span class="action-btn" (click)="addTag()" *ngIf="i == tagsList?.length-1">Add another tag</span>
                      </div>
                    </ng-container>
                </div>    
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentSubForm.invalid || inputError?.ipNameMaxLen ||inputError?.ipNameExist||inputError?.ipNameValid||inputError?.ipNameLastValid " (click)="createDdosProtectionPlan()">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #dnsServer>
    <div class="sidebar-right single add-alert">
        <div class="header">
            <div class="d-flex">
               <h4>DNS Server<br> <span class="blur-text">Virtual Network | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">DNS servers<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="By default you can use an Azure provided DNS server, or you can specify your own." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="flex-custom no-wrap">
                        <div class="checkbox-cs">
                            <input type="radio" name="dnsServer"  class="form-check-inline" id='default' (change)="setDnsServer('default')" [checked]="currentForm.get('dnsServer')?.value == true">
                            <label class="value" for="default">Default (Azure-provided)</label>
                        </div>
                        <div class="checkbox-cs ml-2">
                            <input type="radio" name="dnsServer" class="form-check-inline" id='custom' (change)="setDnsServer('custom')" [checked]="currentForm.get('dnsServer')?.value == false">
                            <label class="value" for="custom">Custom</label>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="currentForm.get('dnsServer')?.value == false">
                    <div>
                        <label class="flex-custom no-wrap">IP Address</label>
                        <ng-container formArrayName="addressSpaceArray" *ngFor="let list of currentForm.get('addressSpaceArray')['controls']; let i = index;">
                            <div class="notify-wrap" id="address-space-list-{{i}}" [formGroupName]="i">
                                <input class="form-control full-width" type="text" autocomplete="off" id="addressSpace-{{i}}" name="addressSpace-{{i}}" formControlName="value" (keyup)="CheckDnsServer($event,i);saveDisable=true"/>
                                <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeAddressSpace(i)" *ngIf="addressSpaceList?.length > 1" >
                                <!-- [class.disabled-icon]="addressSpaceList?.length <= 1" -->
                            </div>
                            <!-- <p class="text-danger" *ngIf="checkIPValidation(i,'dnsIP')">Each DNS server address must be unique. Invalid IP address.</p> -->
                            <!-- <p class="text-danger" *ngIf="checkValidation(i)">Invalid IP address.</p> -->
                        </ng-container>
                        <p class="text-danger" *ngIf="inputError?.dnsUnique">Each DNS server address must be unique. Invalid IP address.</p> 
                        <p class="text-danger" *ngIf="inputError?.dnsError">Invalid IP address.</p> 
                    </div>
                    <div class=" comm-mb mt-1">
                        <span class="action-btn" (click)="addAddressSpace()">Add another IP address</span>
                    </div>
                </ng-container>
                <div class="comm-mb">
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        Virtual machines within this virtual network must be restarted to utilize the updated DNS server settings.</label>
                    </div>  
                </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || !saveDisable" (click)="saveVNETData('dnsServer')">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #configuration>
    <div class="sidebar-right single add-alert">
        <div class="header">
            <div class="d-flex">
               <h4>Configuration <br> <span class="blur-text">Public IP | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y" >
            <form [formGroup]="currentForm">
                <!-- <div class="comm-mb" *ngIf="warningMessage">
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        The associated virtual machine '{{data[0]}}' may be rebooted</label>
                    </div>  
                </div> -->
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">IP address assignment<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The existing IP address will be updated when changing the allocation type." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="checkbox-cs" style="cursor: initial;" id="disable">
                        <input type="radio"   (click)="changeIP('static') " class="form-check-inline cursor-pointer" id='static' name="ipAssignment" value="static" checked="PublicIPData.properties.publicIPAllocationMethod=='Static'" />
                        <label for="static" class="value" style="cursor: pointer;" >Static</label>
                        <input type="radio"  (click)="changeIP('dynamic')"  class="form-check-inline cursor-pointer" id='dynamic' name="ipAssignment" value="dynamic" checked="PublicIPData.properties.publicIPAllocationMethod=='Dynamic'"  />
                        <label  for="dynamic" class="value" style="cursor: pointer;" >Dynamic</label>
                    </div>
                </div>
                <div class="comm-mb" *ngIf="PublicIPData.properties.publicIPAllocationMethod!='Dynamic'">
                    <label class="flex-custom no-wrap">IP address</label>
                    <span class="text-12-18">{{PublicIPData.properties.ipAddress}}</span>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Idle timeout (minutes) <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Keep a TCP or HTTP connection open without relying on clients to send keep-alive messages." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="timeout" name="timeout" formControlName="idleTimeout" onlyNumber (keyup)="idleTimeValidation($event);saveDisable=true"/>
                    <p class="text-danger" *ngIf="inputError?.idleTimeout">The number must be between 4 and 30 minutes.</p>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">DNS name label <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="An 'A record' that starts with the specified label and resolves to this public IP address will be registered with the Azure-provided DNS servers. Example: mylabel.westus.cloudapp.azure.com." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="dnsName" name="dnsName" formControlName="dnsName" [class.border-danger]="currentForm.get('dnsName')?.errors?.smallCharacterValidator" minlength="3" maxlength="63" (keyup)="dnsValidation($event);dnsLocation(event.target.value);saveDisable=true"/>
                    <p class="blur-text text-right">{{dynamicName}}.{{PublicIPData?.location}}.cloudapp.azure.com</p>
                    <!-- PublicIPData.properties?.dnsSettings?.fqdn *ngIf="PublicIPData.properties?.dnsSettings?.fqdn" -->
                    <p class="text-danger" *ngIf="inputError?.dnsNameFLetter || inputError?.dnsNameLLetter || inputError?.dnsNameLength">The domain name is invalid. It can contain only lowercase letters, numbers, and hyphens. The first character must be a letter. The last character must be a letter or number. The value must be between 3 and 63 characters long. </p>
                    <!-- <p class="text-danger" *ngIf="currentForm.get('dnsName')?.errors?.smallCharacterValidator">The domain name is invalid. It can contain only lowercase letters, numbers and hyphens.</p> -->
                    <p class="text-danger" *ngIf="!dnsNameCheck?.available">DNS name label not available. Try using a different label. </p>
                </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="inputError?.dnsNameFLetter || inputError?.dnsNameLLetter || inputError?.dnsNameLength || !dnsNameCheck?.available || inputError?.idleTimeout ||!saveDisable"(click)="configure()">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #associateIp>
    <div class="sidebar-right single add-alert" >
        <div class="header">
            <div class="d-flex">
                <h4>Associate public IP address <br> <span class="blur-text">Public IP | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap" >Resource type</label>
                    <ng-select [items]="associatePublicIp" bindLabel="value" bindValue="id" name="sourceType" (change)="checkRT()" [clearable]="false" id="sourceType" formControlName="resourceType"></ng-select>
                    <!--  (change)="associate('publicIPAddress')" -->
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('resourceType')?.value == 2">
                    <label class="flex-custom no-wrap">Load balancer<span class="mandatory">*</span></label>
                    <ng-select [items]="LoadBalancerList" bindLabel="name" bindValue="id" name="sourceType" [clearable]="false" id="sourceType" (change)="fetch($event)"  formControlName="loadBalancer"></ng-select>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('resourceType')?.value == 1">
                    <label class="flex-custom no-wrap">Network interface<span class="mandatory">*</span></label>
                    <ng-select [items]="networkInterfaceList" bindLabel="name" bindValue="id" name="sourceType" [clearable]="false" id="sourceType"(change)="fetch($event)" formControlName="networkInterface"></ng-select>
                </div>
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" (click)="savePublicIP()">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #disassociateIp>
    <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet" >
        <div class="header">
            <div class="d-flex">
                <h4>Dissociate <br> <span class="blur-text">Public IP | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body scroll-y" >
             <div style="margin-left: -16px;">
                <div class="flex-custom no-wrap mb-2">
                    <div class="col-md-6"><label class="flex-custom no-wrap">Name:</label></div>
                    <div class="col-md-6"><span class="text-12-18">{{PublicIPData.name}}</span></div>
                </div>
                <div class="flex-custom no-wrap comm-mb">
                    <div class="col-md-6"><label class="flex-custom no-wrap">Associated to:</label></div>
                    <div class="col-md-6"><span class="text-12-18">{{associatedTo}}</span></div>
                </div>
            </div>
                <div class="comm-mb">
                   
                        <button class="btn-btn-secondary dissociateBtn" (click)="action(associatedTo,'dissociatePublicIp')">
                            Dissociate
                        </button>
                </div>
        </div>
    </div>
</ng-template>

<ng-template #inboundSecurityRules>
    <div class="sidebar-right single width-881">
        <div class="header">
            <div class="d-flex">
                <h4 class="half-width">Inbound Security Rules <br> <span class="blur-text">Network Security Group | {{data[0]}}</span></h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <button class="btn-btn-secondary" (click)="openNormalModal(addSecurityRulesPopup,'inbound',false);setDefaultValue()">
                <img src="/assets/governance/add-btn.svg" alt="">
                Add inbound security rules
            </button>
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Priority</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'priority')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'name')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Port</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'port')">
                                </div>
                            </th>
                            <th>
                            <div class="flex-custom no-wrap">
                                <span>Protocol</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'protocol')">
                            </div>
                            </th>
                            <th>
                            <div class="flex-custom no-wrap">
                                <span>Source</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'sourecIP')">
                            </div>
                            </th>
                            <th>
                            <div class="flex-custom no-wrap">
                                <span>Destination</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'destIP')">
                            </div>
                            </th>
                            <th style="border-right: none;">
                            <div class="flex-custom no-wrap">
                                <span>Action</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'action')">
                            </div>
                            </th>
                            <th style="border-left: none;"></th>
                        </tr>
                    </thead>
                      <tbody >
                          <ng-container *ngIf="sortDefaultNsg">
                            <ng-container *ngFor="let list of nsgData?.properties?.defaultSecurityRules" >
                                <tr *ngIf="list?.properties?.direction == 'Inbound'" class="disabled-icon">
                                    <td>{{list?.properties?.priority}}</td>
                                    <td>{{list?.name}}</td>
                                    <td>{{list?.properties?.sourcePortRange == '*' ? 'Any' : list?.properties?.sourcePortRange}}</td>
                                    <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                    <td>{{list?.properties?.sourceAddressPrefix == '*' ? 'Any' : list?.properties?.sourceAddressPrefix}}</td>
                                    <td>{{list?.properties?.destinationAddressPrefix == '*' ? 'Any' : list?.properties?.destinationAddressPrefix}}</td>
                                    <td>
                                        <div class="status-tag" [ngClass]="getStatusClass(list?.properties?.access)">
                                            <img [src]="getStatusChipImg(list?.properties?.access)">
                                            {{list?.properties?.access}}
                                        </div>
                                    </td>
                                    <td>
                                        <span>
                                            <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                            <mat-menu #actionInbound="matMenu" class="delete-popup">
                                              <div class="mat-menu-item" mat-menu-item (click)="editSecurityRules(list,'inbound')">
                                                <p class="delete-popup-text">Edit</p>
                                              </div>
                                              <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteInbound')">
                                                <p class="delete-popup-text">Delete</p>
                                              </div>
                                            </mat-menu>
                                          </span>    
                                    </td>
                                </tr>
                            </ng-container>
                          </ng-container>
                        <ng-container *ngFor="let list of nsgData?.properties?.securityRules">
                            <tr *ngIf="list?.properties?.direction == 'Inbound'">
                                <td>{{list?.properties?.priority}}</td>
                                <td>{{list?.name}}</td>
                                <td>{{getTableArraydata(list,'sourcePort')}}</td>
                                <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                <td>{{getTableArraydata(list,'source')}}</td>
                                <td>{{getTableArraydata(list,'destination')}}</td>
                                <td>
                                    <div class="status-tag" [ngClass]="getStatusClass(list?.properties?.access)">
                                        <img [src]="getStatusChipImg(list?.properties?.access)">
                                        {{list?.properties?.access}}
                                    </div>
                                </td>
                                <td>
                                    <span>
                                        <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                        <mat-menu #actionInbound="matMenu" class="delete-popup">
                                            <div class="mat-menu-item" mat-menu-item (click)="editSecurityRules(list,'inbound')">
                                            <p class="delete-popup-text">Edit</p>
                                            </div>
                                            <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteInbound')">
                                            <p class="delete-popup-text">Delete</p>
                                            </div>
                                        </mat-menu>
                                        </span>    
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!sortDefaultNsg">
                            <ng-container *ngFor="let list of nsgData?.properties?.defaultSecurityRules" >
                                <tr *ngIf="list?.properties?.direction == 'Inbound'" class="disabled-icon">
                                    <td>{{list?.properties?.priority}}</td>
                                    <td>{{list?.name}}</td>
                                    <td>{{list?.properties?.sourcePortRange == '*' ? 'Any' : list?.properties?.sourcePortRange}}</td>
                                    <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                    <td>{{list?.properties?.sourceAddressPrefix == '*' ? 'Any' : list?.properties?.sourceAddressPrefix}}</td>
                                    <td>{{list?.properties?.destinationAddressPrefix == '*' ? 'Any' : list?.properties?.destinationAddressPrefix}}</td>
                                    <td>
                                        <div class="status-tag" [ngClass]="getStatusClass(list?.properties?.access)">
                                            <img [src]="getStatusChipImg(list?.properties?.access)">
                                            {{list?.properties?.access}}
                                        </div>
                                    </td>
                                    <td>
                                        <span>
                                            <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                            <mat-menu #actionInbound="matMenu" class="delete-popup">
                                              <div class="mat-menu-item" mat-menu-item (click)="editSecurityRules(list,'inbound')">
                                                <p class="delete-popup-text">Edit</p>
                                              </div>
                                              <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteInbound')">
                                                <p class="delete-popup-text">Delete</p>
                                              </div>
                                            </mat-menu>
                                          </span>    
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                      </tbody>
                </table>
            </div>
        </div>

        <!-- <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary">Save</button>
        </div> -->
    </div>
</ng-template>

<ng-template #outboundSecurityRules>
    <div class="sidebar-right single width-881">
        <div class="header">
            <div class="d-flex">
                <h4 class="half-width">Outbound Security Rules <br> <span class="blur-text">Network Security Group | {{data[0]}}</span></h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <button class="btn-btn-secondary" (click)="openNormalModal(addSecurityRulesPopup,'outbound',false);setDefaultValue()">
                <img src="/assets/governance/add-btn.svg" alt="">
                Add outbound security rules
            </button>
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Priority</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'priority')">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Name</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'name')">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Port</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'port')">
                              </div>
                          </th>
                          <th>
                            <div class="flex-custom no-wrap">
                                <span>Protocol</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'protocol')">
                            </div>
                          </th>
                          <th>
                            <div class="flex-custom no-wrap">
                                <span>Source</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'sourecIP')">
                            </div>
                          </th>
                          <th>
                            <div class="flex-custom no-wrap">
                                <span>Destination</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'destIP')">
                            </div>
                          </th>
                          <th style="border-right: none;">
                            <div class="flex-custom no-wrap">
                                <span>Action</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('nsg',nsgData,'action')">
                            </div>
                          </th>
                          <th style="border-left: none;"></th>
                        </tr>
                      </thead>
                      <tbody >
                          <ng-container *ngIf="sortDefaultNsg">
                            <ng-container *ngFor="let list of nsgData?.properties?.defaultSecurityRules">
                                <tr *ngIf="list?.properties?.direction == 'Outbound'" class="disabled-icon">
                                    <td>{{list?.properties?.priority}}</td>
                                    <td>{{list?.name}}</td>
                                    <td>{{list?.properties?.sourcePortRange == '*' ? 'Any' : list?.properties?.sourcePortRange}}</td>
                                    <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                    <td>{{list?.properties?.sourceAddressPrefix == '*' ? 'Any' : list?.properties?.sourceAddressPrefix}}</td>
                                    <td>{{list?.properties?.destinationAddressPrefix == '*' ? 'Any' : list?.properties?.destinationAddressPrefix}}</td>
                                    <td>
                                        <div class="status-tag" [ngClass]="getStatusClass(list?.properties?.access)">
                                            <img [src]="getStatusChipImg(list?.properties?.access)">
                                            {{list?.properties?.access}}
                                        </div>
                                    </td>
                                    <td>
                                        <span>
                                            <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                            <mat-menu #actionInbound="matMenu" class="delete-popup">
                                              <div class="mat-menu-item" mat-menu-item (click)="editSecurityRules(list,'inbound')">
                                                <p class="delete-popup-text">Edit</p>
                                              </div>
                                              <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteInbound')">
                                                <p class="delete-popup-text">Delete</p>
                                              </div>
                                            </mat-menu>
                                          </span>    
                                    </td>
                                </tr>
                            </ng-container>
                          </ng-container>
                        <ng-container *ngFor="let list of nsgData?.properties?.securityRules">
                            <tr *ngIf="list?.properties?.direction == 'Outbound'">
                                <td>{{list?.properties?.priority}}</td>
                                <td>{{list?.name}}</td>
                                <td>{{getTableArraydata(list,'sourcePort')}}</td>
                                <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                <td>{{getTableArraydata(list,'source')}}</td>
                                <td>{{getTableArraydata(list,'destination')}}</td>
                                <td>
                                    <div class="status-tag" [ngClass]="getStatusClass(list?.properties?.access)">
                                        <img [src]="getStatusChipImg(list?.properties?.access)">
                                        {{list?.properties?.access}}
                                    </div>
                                </td>
                                <td>
                                    <span>
                                        <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                        <mat-menu #actionInbound="matMenu" class="delete-popup">
                                          <div class="mat-menu-item" mat-menu-item (click)="editSecurityRules(list,'outbound')">
                                            <p class="delete-popup-text">Edit</p>
                                          </div>
                                          <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteInbound')">
                                            <p class="delete-popup-text">Delete</p>
                                          </div>
                                        </mat-menu>
                                      </span>    
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!sortDefaultNsg">
                            <ng-container *ngFor="let list of nsgData?.properties?.defaultSecurityRules">
                                <tr *ngIf="list?.properties?.direction == 'Outbound'" class="disabled-icon">
                                    <td>{{list?.properties?.priority}}</td>
                                    <td>{{list?.name}}</td>
                                    <td>{{list?.properties?.sourcePortRange == '*' ? 'Any' : list?.properties?.sourcePortRange}}</td>
                                    <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                    <td>{{list?.properties?.sourceAddressPrefix == '*' ? 'Any' : list?.properties?.sourceAddressPrefix}}</td>
                                    <td>{{list?.properties?.destinationAddressPrefix == '*' ? 'Any' : list?.properties?.destinationAddressPrefix}}</td>
                                    <td>
                                        <div class="status-tag" [ngClass]="getStatusClass(list?.properties?.access)">
                                            <img [src]="getStatusChipImg(list?.properties?.access)">
                                            {{list?.properties?.access}}
                                        </div>
                                    </td>
                                    <td>
                                        <span>
                                            <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                            <mat-menu #actionInbound="matMenu" class="delete-popup">
                                              <div class="mat-menu-item" mat-menu-item (click)="editSecurityRules(list,'inbound')">
                                                <p class="delete-popup-text">Edit</p>
                                              </div>
                                              <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteInbound')">
                                                <p class="delete-popup-text">Delete</p>
                                              </div>
                                            </mat-menu>
                                          </span>    
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        
                      </tbody>
                </table>
            </div>
        </div>

        <!-- <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary">Save</button>
        </div> -->
    </div>
</ng-template>

<ng-template #networkInterface>
    <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4 >Network Interfaces <br> <span class="blur-text">Network Security Group | {{data[0]}}</span></h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <button class="btn-btn-secondary" (click)="showSubSidesheet = true;associate('nsgAssociate')">
                <img src="/assets/governance/add-btn.svg" alt="">
                Associate
            </button>
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Name</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Action</span>
                              </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let list of nsgData?.properties?.networkInterfaces">
                            <tr>
                                <td>{{list?.nicName}}</td>
                                <td class="float-R">
                                    <span>
                                        <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                        <mat-menu #actionInbound="matMenu" class="delete-popup">
                                          <div class="mat-menu-item" mat-menu-item (click)="action(list,'dissociateNic')">
                                            <p class="delete-popup-text">Dissociate</p>
                                          </div>
                                        </mat-menu>
                                      </span>    
                                </td>
                            </tr>
                          </ng-container>
                      </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4>Associate Network Interface <br> <span class="blur-text">Network Security Group | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Network interface associations<span class="mandatory">*</span></label>
                    <ng-select class="full-width" [items]="networkInterfaceList" bindLabel="name" bindValue="id" name="networkInterface" [clearable]="false" id="networkInterface" (change)="fetch($event)" formControlName="networkInterface"></ng-select>
                </div>                
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid" (click)="showSubSidesheet =  false;saveNSGNICAssociate()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #nsgSubnets>
    <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
               <h4 class="half-width">Subnets <br> <span class="blur-text">Network Security Group | {{data[0]}}</span></h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <button class="btn-btn-secondary" (click)="showSubSidesheet = true;associate('nsgSubnet')">
                <img src="/assets/governance/add-btn.svg" alt="">
                Associate
            </button>
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Name</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Address range</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',1)">
                              </div>
                          </th>
                          <th style="border-right: none;">
                            <div class="flex-custom no-wrap">
                                <span>Virtual network</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',1)">
                            </div>
                        </th>
                        <th style="border-left: none;"></th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let list of nsgData?.properties?.subnets">
                            <tr>
                                <td>{{list?.subnetName}}</td>
                                <td>{{list?.addressRange | displayTxt}}</td>
                                <td>{{list?.vnetName}}</td>
                                <td class="float-R">
                                    <span>
                                        <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                        <mat-menu #actionInbound="matMenu" class="delete-popup">
                                          <div class="mat-menu-item" mat-menu-item (click)="action(list,'dissociateSubnet')">
                                            <p class="delete-popup-text">Dissociate</p>
                                          </div>
                                        </mat-menu>
                                      </span>    
                                </td>
                            </tr>
                          </ng-container>
                      </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
                <h4 >Associate Subnet <br> <span class="blur-text">Network Security Group | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Virtual network</label>
                    <ng-select class="full-width" [items]="existingVNList" bindLabel="name" bindValue="name" (change)="getSubnetList($event)"name="virtualNetwork" [clearable]="false" id="virtualNetwork" formControlName="virtualNetwork"></ng-select>
                </div>      
                <div class="comm-mb" *ngIf="currentForm.get('virtualNetwork').value!=null">
                    <label class="flex-custom no-wrap">Subnet</label>
                    <ng-select class="full-width" [items]="existingSubnetList" bindLabel="name" bindValue="name" name="subnet" [clearable]="false" id="subnet" formControlName="subnet"></ng-select>
                </div>                
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid" (click)="saveNsgSubnet()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #nicNsg>
    <div class="sidebar-right single add-alert" >
        <div class="header">
            <div class="d-flex">
                <h4 >Network Security Group<br> <span class="blur-text">Network Interface | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Network security group<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use network security groups to control access to the network interface." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <ng-select class="full-width" [items]="nsgList" bindLabel="name" bindValue="id" name="nsgList" (change)="saveDisable=true" [clearable]="false" id="nsgList" formControlName="nsgList"></ng-select>
                </div>                   
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || !saveDisable" (click)="saveNic('nicNsg')">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #nicDnsServer>
    <div class="sidebar-right single add-alert" >
        <div class="header">
            <div class="d-flex">
                <h4>DNS Servers<br> <span class="blur-text">Network Interface | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                 <div class="comm-mb">
                    <label class="flex-custom no-wrap">DNS servers</label>
                    <div >
                        <div class="checkbox-cs">
                            <input type="radio" name="dnsServer"  class="form-check-inline" id='default' (change)="setDnsServer('default')" [checked]="currentForm.get('dnsServer')?.value == true">
                            <label class="value" for="default">Inherit from virtual network</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="dnsServer" class="form-check-inline" id='custom' (change)="setDnsServer('custom')" [checked]="currentForm.get('dnsServer')?.value == false">
                            <label class="value" for="custom">Custom</label>
                        </div>
                    </div>
                </div>
                <div class="comm-mb" *ngIf="warningMessage">
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        Updating the DNS servers for this network interface will restart the virtual machine to which it’s attached, and if applicable, any other virtual machines in the same availability set.</label>
                    </div>  
                </div>
                <div *ngIf="currentForm.get('dnsServer')?.value == false">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">DNS server<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="By default you can use an Azure provided DNS server, or you can specify your own." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-container formArrayName="addressSpaceArray" *ngFor="let list of currentForm.get('addressSpaceArray')['controls']; let i = index;">
                            <div class="notify-wrap" id="address-space-list-{{i}}" [formGroupName]="i">
                                <input class="form-control full-width" type="text" autocomplete="off" id="addressSpace-{{i}}" name="addressSpace-{{i}}" formControlName="value" (keyup)="CheckDnsServer($event,i)"/>
                                <!-- <input class="form-control full-width" type="text" formControlName="value" /> -->
                                <!-- <ng-container *ngIf="addressSpaceList?.length  > 1">
                                    <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeAddressSpace(i)">
                                  </ng-container> -->
                                <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeAddressSpace(i);warningMessage=true" [class.disabled-icon]="addressSpaceList?.length <= 1">
                            </div>
                        </ng-container>
                        <p class="text-danger" *ngIf="inputError?.dnsUnique">Each DNS server address must be unique.</p> 
                        <p class="text-danger" *ngIf="inputError?.dnsError">Invalid IP address.</p> 
                        <span class="action-btn" (click)="addAddressSpace()">Add another DNS server</span>
                    </div>
                </div>
                
                <div class="comm-mb">
                    <label class="flex-custom no-wrap" style="font-weight: 600;">Applied DNS servers<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" 
                        matTooltip="If the virtual machine that uses this network interface is part of an availability set, then this 
                        list will have the union of all DNS servers from all network interfaces that are part of the
                        availability set. This property is what is configured on each of those virtual machines." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <label *ngIf="!nicData['properties']?.virtualMachine">No result</label>
                        <ng-container *ngFor="let dns of nicData['properties'].dnsSettings.appliedDnsServers;let i = index;">
                            <label class="flex-custom" *ngIf="nicData['properties']?.virtualMachine">{{ nicData['properties'].dnsSettings.appliedDnsServers[i] }}</label>
                        </ng-container>
                </div>  
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid ||inputError?.dnsError||inputError?.dnsUnique || !warningMessage " (click)="saveNic('nicDns')">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #nicIPConfiguration>
    <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet||editSubSidesheet">
        <div class="header">
            <div class="d-flex">
                <h4 class="half-width">IP Configurations <br> <span class="blur-text">Network Interface | {{data[0]}}</span></h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                   <label class="flex-custom no-wrap mb-2 bold-600" >IP Forwarding Settings</label>
                   <label class="flex-custom no-wrap" >IP forwarding</label>
                   <div class="flex-custom ">
                       <div class="checkbox-cs">
                           <input type="radio" name="ipForwarding"  class="form-check-inline" id='default' (change)="setIpForwarding('disabled')" [checked]="currentForm.get('ipForwarding')?.value == false">
                           <label class="value" for="default">Disabled</label>
                       </div>
                       <div class="checkbox-cs">
                           <input type="radio" name="ipForwarding" class="form-check-inline" id='custom' (change)="setIpForwarding('enabled')" [checked]="currentForm.get('ipForwarding')?.value == true">
                           <label class="value" for="custom">Enabled</label>
                       </div>
                   </div>
               </div>
               <div class="comm-mb">
                   <label class="flex-custom no-wrap bold-600" >Virtual network</label>
                <label class="flex-custom no-wrap">{{ data[4]}}</label> 
               </div>  
               <div class="comm-mb">
                <label class="flex-custom no-wrap mb-2 bold-600">IP Configurations</label>
                <label class="flex-custom no-wrap">Subnet</label>
                <ng-select class="full-width" [items]="existingSubnetList"  groupBy="type" bindLabel="name" bindValue="id" (change)="saveDisable=true"name="subnetIP" [clearable]="false" id="subnetIP" formControlName="subnetIP">
                    <!-- <ng-template ng-optgroup-tmp let-item="item">
                        {{item.type}}
                    </ng-template> -->
                    <!-- <ng-template ng-opt-tmp let-item="item"[class.disable]="item.type=='Cannot be associated with network interface'">
                    </ng-template> -->
                </ng-select>
            </div> 
           </form>
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Name</span>
                                  <img class="sort" src="assets/icons/sort.png">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>IP Version</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Type</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',2)">
                              </div>
                          </th>
                          <th>
                            <div class="flex-custom no-wrap">
                                <span>Private IP address</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',3)">
                            </div>
                          </th>
                          <th style="border-right: none;">
                            <div class="flex-custom no-wrap">
                                <span>Public IP address</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('publicIpData',3)">
                            </div>
                          </th>
                          <th style="border-left: none;"></th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let list of nicData?.properties?.ipConfigurations">
                            <tr>
                                <td>{{list?.name}}</td>
                                <td>{{list?.properties?.privateIPAddressVersion}}</td>
                                <td>{{list?.properties?.primary? 'Primary' : 'Secondary'}}</td>
                                <td>{{list?.properties?.privateIPAddress}} ({{list?.properties?.privateIPAllocationMethod}})</td>
                                <td>{{!list?.properties?.publicIPAddress?'':getpublicIpAddressName(list)}}</td>
                                <td>
                                    <span>
                                        <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                        <mat-menu #actionInbound="matMenu" class="delete-popup">
                                            <!--  -->
                                          <div class="mat-menu-item" mat-menu-item (click)="makePrimary(list)" [class.blur]="list?.properties?.primary || nicData?.properties?.virtualMachine">
                                            <p class="delete-popup-text" [class.blur]="list?.properties?.primary || nicData?.properties?.virtualMachine">Make primary</p>
                                          </div>
                                          <div class="mat-menu-item" mat-menu-item (click)="editSubSidesheet = true;subSideSaveDisable=false;setIPConfiDefault('editIP',list)">
                                            <p class="delete-popup-text">Edit</p>
                                          </div>
                                          <div class="mat-menu-item" mat-menu-item (click)="action(list,'deleteIP')" [class.blur]="list?.properties?.primary">
                                            <p class="delete-popup-text" [class.blur]="list?.properties?.primary">Delete</p>
                                          </div>
                                        </mat-menu>
                                      </span>    
                                </td>
                            </tr>
                        </ng-container>
                      </tbody>
                </table>
            </div> 
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn whiteBtn" (click)="showSubSidesheet = true;setIPConfiDefault('addIP')"><span class="bluePlus">+</span>Add</button>
            <button class="btn btn-btn-primary" [class.disabled]="!saveDisable" (click)="saveNic('ipConfiguration')">Save</button>
        </div>
    </div>
    <div class="sidebar-right single add-alert" style="width: 360px;" *ngIf="showSubSidesheet">
        <div class="header">
            <div class="d-flex">
                <h4 >Add IP Configuration <br> <span class="blur-text">Network Interface | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetForm('nicIP');resetValidation()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                    <input class="form-control full-width" type="text" name="source" [clearable]="false" id="source" formControlName="name" [class.border-danger]="inputError?.ipNameMaxLen || inputError?.ipNameExist || inputError?.ipNameValid || inputError?.ipNameLastValid"  autocomplete="off" placeholder=""   (keyup)="validIPName($event,80,'ipName','ipName');subSideSaveDisable=true" >
                    <p class="text-danger" *ngIf="inputError?.ipNameMaxLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.ipNameExist">An IP configuration with this name already exists on this network interface. </p>
                    <p class="text-danger" *ngIf="inputError?.ipNameValid ||inputError?.ipNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                        and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>      
                <div class="comm-mb">
                    <label class="flex-custom no-wrap" >Type</label>
                    <div class="flex-custom ">
                        <div class="checkbox-cs" id="disable">
                            <input type="radio" name="type"  class="form-check-inline" id='primary' value="primary" (change)="setRadioBtn('primary','type')" [disabled]="infoMessage" [checked]="currentForm.get('type')?.value == true">
                            <label class="value" for="primary">Primary</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="type" class="form-check-inline" id='secondary' value="secondary" (change)="setRadioBtn('secondary','type')" [disabled]="infoMessage" [checked]="currentForm.get('type')?.value == false">
                            <label class="value" for="secondary">Secondary</label>
                        </div>
                    </div>
                </div> 
                <div class="comm-mb" *ngIf="infoMessage">
                    <div class="label-div d-flex blueBox">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;">
                        Primary IP configuration already exists</label>
                    </div>  
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap mb-2 bold-600">Private IP address settings</label>
                    <label class="flex-custom no-wrap" >Allocation</label>
                    <div class="flex-custom ">
                        <div class="checkbox-cs">
                            <input type="radio" name="allocation"  class="form-check-inline" id='dynamic' (change)="setRadioBtn('dynamic','allocation')" [checked]="currentForm.get('allocation')?.value == 'Dynamic'">
                            <label class="value" for="dynamic">Dynamic</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="allocation" class="form-check-inline" id='static' (change)="setRadioBtn('static','allocation')" [checked]="currentForm.get('allocation')?.value == 'Static'">
                            <label class="value" for="static">Static</label>
                        </div>
                    </div>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('allocation').value=='Static'">
                    <label class="flex-custom no-wrap">IP address<span class="mandatory">*</span></label>
                    <input class="form-control full-width" type="text" name="source" [clearable]="false" id="source" formControlName="ipAddress" [class.border-danger]="inputError?.dnsError"  autocomplete="off" placeholder="" maxlength="80"  (keyup)="CheckDnsServer($event,i,'nicIPAddress')" >
                    <p class="text-danger" *ngIf="inputError?.dnsError">Invalid IP address.</p> 
                </div>  
                <div class="comm-mb">
                    <label class="flex-custom no-wrap" >Public IP address</label>
                    <div class="flex-custom ">
                        <div class="checkbox-cs">
                            <input type="radio" name="publicIPAddress"  class="form-check-inline" id='disassociate' (change)="setRadioBtn('disassociate','publicIPAddress')" [checked]="currentForm.get('publicIPAddress')?.value == false">
                            <label class="value" for="disassociate">Disassociate</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="publicIPAddress" class="form-check-inline" id='associate' (change)="setRadioBtn('associate','publicIPAddress')" [checked]="currentForm.get('publicIPAddress')?.value == true">
                            <label class="value" for="associate">Associate</label>
                        </div>
                    </div>
                </div>  
                <div class="comm-mb" *ngIf="currentForm.get('publicIPAddress').value==true" >
                    <label class="flex-custom nowrap">Public IP address<span class="mandatory">*</span></label>
                    <ng-select [items]="publicIPList" groupBy="type" bindLabel="name" bindValue="id" name="publicIPAddressInput" [clearable]="false" id="publicIPAddressInput" formControlName="publicIPAddressInput"></ng-select>
                    <span class="blue-text text-12-18 cursor-pointer" #anchor1 (click)="onToggle('publicIP');setIPConfiDefault('publicIP')">Create New</span>
                    <kendo-popup [anchor]="anchor1" (anchorViewportLeave)="showpublicIP = false" *ngIf="showpublicIP" id="createPublicIP">
                      <!-- <form [formGroup]="publicIPForm"> -->
                        <div class='content kendo-content resourceGroup'>
                          <div class="rgPopup-form">
                            <label for="publicIPName" class="flex-custom" style="font-weight: 600;">Add a public IP address</label>
                              <label for="publicIPName" class="">Name<span class="mandatory">*</span></label>
                              <div>
                                <input type="text" class="form-control"  id="publicIPName" autocomplete="off" [clearable]="false" placeholder="" name="publicIPName" formControlName="publicIPName" (keyup)="validIPName($event,80,'publicIPName','publicIPName')" >
                                <p class="text-danger" *ngIf="inputError?.publicIPNameMaxLen">The name must be between 1 and 80 characters.</p>
                                <p class="text-danger" *ngIf="inputError?.publicIPNameExist">A public IP with this name already exists. </p>
                                <p class="text-danger" *ngIf="inputError?.publicIPNameValid ||inputError?.publicIPNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                                    and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                              </div>              
                          </div>
                          <div class="comm-mb">
                            <label class="flex-custom no-wrap" >SKU</label>
                            <div class="flex-custom ">
                                <div class="checkbox-cs">
                                    <input type="radio" name="sku"  class="form-check-inline" id='basic' (change)="setRadioBtn('basic','sku')" [checked]="currentForm.get('sku')?.value == 'Basic'">
                                    <label class="value" for="basic">Basic</label>
                                </div>
                                <div class="checkbox-cs">
                                    <input type="radio" name="sku" class="form-check-inline" id='standard' (change)="setRadioBtn('standard','sku')" [checked]="currentForm.get('sku')?.value == 'Standard'">
                                    <label class="value" for="standard">Standard</label>
                                </div>
                            </div>
                        </div> 
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap" >Assigment</label>
                            <div class="flex-custom ">
                                <div class="checkbox-cs" >
                                    <input type="radio" name="assigment"  class="form-check-inline" id='dynamicAss' (change)="setRadioBtn('dynamic','assigment')" [disabled]="currentForm.get('sku')?.value == 'Standard'" [checked]="currentForm.get('assigment')?.value == 'Dynamic'">
                                    <label class="value" for="dynamicAss">Dynamic</label>
                                </div>
                                <div class="checkbox-cs">
                                    <input type="radio" name="assigment" class="form-check-inline" id='staticAss' (change)="setRadioBtn('static','assigment')" [disabled]="currentForm.get('sku')?.value == 'Standard'" [checked]="currentForm.get('assigment')?.value == 'Static'">
                                    <label class="value" for="staticAss">Static</label>
                                </div>
                            </div>
                        </div> 
                          <div class="d-flex">
                              <button class="btn btn-btn-primary" [class.disabled]="inputError?.publicIPNameMaxLenIPName || inputError?.publicIPNameValidIPName||inputError?.publicIPNameExist||inputError?.publicIPNameLastValid" (click)="saveNic('addpublicIP')">Ok</button>
                              <button class="btn btn-outline" (click)="onToggle('publicIP')">Cancel</button>
                          </div>
                        </div>
                      <!-- </form> -->
                    </kendo-popup>
                </div>          
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('nicIP') || !subSideSaveDisable" (click)="saveNic('addIP')">Save</button>
        </div>
    </div>
    <div class="sidebar-right single add-alert" style="width: 360px;" *ngIf="editSubSidesheet">
        <div class="header">
            <div class="d-flex">
                <h4 >Edit IP Configuration <br> <span class="blur-text">Network Interface | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="editSubSidesheet = false;resetForm('nicIP');resetValidation()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb" *ngIf="warningMessage">
                    <div class="label-div d-flex "style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;color: #703501;">
                        The virtual machine associated with this network interface will be restarted to utilize the new private IP address. The network interface will be re-provisioned and network configuration settings, including secondary IP addresses, subnet masks, and default gateway, will need to be manually reconfigured within the virtual machine.</label>
                    </div>  
                </div>           
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Name</label>
                    <input class="form-control full-width disabled" type="text" name="source" [clearable]="false" id="source" formControlName="name"  autocomplete="off" placeholder=""  >
                </div>      
                <div class="comm-mb">
                    <label class="flex-custom no-wrap" >Public IP address</label>
                    <div class="flex-custom ">
                        <div class="checkbox-cs">
                            <input type="radio" name="publicIPAddress"  class="form-check-inline" id='disassociate' (change)="setRadioBtn('disassociate','publicIPAddress')" [checked]="currentForm.get('publicIPAddress')?.value == false">
                            <label class="value" for="disassociate">Disassociate</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="publicIPAddress" class="form-check-inline" id='associate' (change)="setRadioBtn('associate','publicIPAddress')" [checked]="currentForm.get('publicIPAddress')?.value == true">
                            <label class="value" for="associate">Associate</label>
                        </div>
                    </div>
                </div>  
                <div class="comm-mb" *ngIf="currentForm.get('publicIPAddress').value==true" >
                    <label class="flex-custom nowrap">Public IP address<span class="mandatory">*</span></label>
                    <ng-select [items]="publicIPList" groupBy="type" bindLabel="name" bindValue="id" name="publicIPAddressInput" [clearable]="false" id="publicIPAddressInput" (change)="subSideSaveDisable=true" formControlName="publicIPAddressInput"></ng-select>
                    <span class="blue-text text-12-18 cursor-pointer" #anchor1 (click)="onToggle('publicIP');setIPConfiDefault('publicIP')">Create New</span>
                    <kendo-popup [anchor]="anchor1" (anchorViewportLeave)="showpublicIP = false" *ngIf="showpublicIP" id="createPublicIP">
                      <!-- <form [formGroup]="publicIPForm"> -->
                        <div class='content kendo-content resourceGroup'>
                          <div class="rgPopup-form">
                            <label for="publicIPName" class="flex-custom" style="font-weight: 600;">Add a public IP address</label>
                              <label for="publicIPName" class="">Name<span class="mandatory">*</span></label>
                              <div>
                                <input type="text" class="form-control"  id="publicIPName" autocomplete="off"  placeholder="" name="publicIPName" formControlName="publicIPName" (keyup)="validIPName($event,80,'publicIPName','publicIPName')" required>
                                <p class="text-danger" *ngIf="inputError?.publicIPNameMaxLen">The name must be between 1 and 80 characters.</p>
                                <p class="text-danger" *ngIf="inputError?.publicIPNameExist">A public IP with this name already exists. </p>
                                <p class="text-danger" *ngIf="inputError?.publicIPNameValid||inputError?.publicIPNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                                    and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                              </div>              
                          </div>
                          <div class="comm-mb">
                            <label class="flex-custom no-wrap" >SKU</label>
                            <div class="flex-custom ">
                                <div class="checkbox-cs">
                                    <input type="radio" name="sku"  class="form-check-inline" id='basic' (change)="setRadioBtn('basic','sku')" [checked]="currentForm.get('sku')?.value == 'Basic'">
                                    <label class="value" for="basic">Basic</label>
                                </div>
                                <div class="checkbox-cs">
                                    <input type="radio" name="sku" class="form-check-inline" id='standard' (change)="setRadioBtn('standard','sku')" [checked]="currentForm.get('sku')?.value == 'Standard'">
                                    <label class="value" for="standard">Standard</label>
                                </div>
                            </div>
                        </div> 
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap" >Assigment</label>
                            <div class="flex-custom ">
                                <div class="checkbox-cs" >
                                    <input type="radio" name="assigment"  class="form-check-inline" id='dynamicAss' (change)="setRadioBtn('dynamic','assigment')" [disabled]="currentForm.get('sku')?.value == 'Standard'" [checked]="currentForm.get('assigment')?.value == 'Dynamic'">
                                    <label class="value" for="dynamicAss">Dynamic</label>
                                </div>
                                <div class="checkbox-cs">
                                    <input type="radio" name="assigment" class="form-check-inline" id='staticAss' (change)="setRadioBtn('static','assigment')" [disabled]="currentForm.get('sku')?.value == 'Standard'" [checked]="currentForm.get('assigment')?.value == 'Static'">
                                    <label class="value" for="staticAss">Static</label>
                                </div>
                            </div>
                        </div> 
                          <div class="d-flex">
                              <button class="btn btn-btn-primary" [class.disabled]="inputError?.publicIPNameMaxLenIPName || inputError?.publicIPNameValidIPName ||inputError?.publicIPNameExist||inputError?.publicIPNameLastValid" (click)="saveNic('addpublicIP')">Ok</button>
                              <button class="btn btn-outline" (click)="onToggle('publicIP')">Cancel</button>
                          </div>
                        </div>
                      <!-- </form> -->
                    </kendo-popup>
                </div>
                <div class="mb-2">
                    <label class="flex-custom no-wrap" style="font-weight: 600;font-size: 13px;line-height: 20px;" >Private IP address settings</label>
                </div>   
                <div class="comm-mb">
                    <label class="flex-custom no-wrap" style="font-weight: 600;" >Virtual network</label>
                 <label class="flex-custom no-wrap">{{ data[4]}}</label> 
                </div> 
                <div class="comm-mb">
                    <label class="flex-custom no-wrap" >Assigment</label>
                    <div class="flex-custom ">
                        <div class="checkbox-cs" >
                            <input type="radio" name="allocation"  class="form-check-inline" id='dynamic' (change)="setRadioBtn('dynamic','allocation')" [checked]="currentForm.get('allocation')?.value == 'Dynamic'">
                            <label class="value" for="dynamic">Dynamic</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="allocation" class="form-check-inline" id='static' (change)="setRadioBtn('static','allocation')"  [checked]="currentForm.get('allocation')?.value == 'Static'">
                            <label class="value" for="static">Static</label>
                        </div>
                    </div>
                </div>
                <div class="comm-mb" >
                    <label class="flex-custom no-wrap">IP address<span class="mandatory">*</span></label>
                    <input class="form-control full-width" [class.disabled]="currentForm.get('allocation').value=='Dynamic'"type="text" name="source" [clearable]="false" id="source" formControlName="ipAddress" [class.border-danger]="inputError?.dnsError"  autocomplete="off" placeholder="" maxlength="80"  (keyup)="CheckDnsServer($event,i,'nicIPAddress');subSideSaveDisable=true" >
                    <!-- <p class="text-danger" *ngIf="inputError?.sourceInvalidArgument">Invalid IP address</p> -->
                    <p class="text-danger" *ngIf="inputError?.dnsError">Invalid IP address.</p> 
                </div>  
            </form>
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="inputError?.dnsError|| inputError?.publicIPNameMaxLenIPName || inputError?.publicIPNameValidIPName ||inputError?.publicIPNameExist ||!subSideSaveDisable" (click)="saveNic('editIP')">Save</button>
        </div>
    </div>
</ng-template>


<!-- Common delete popup -->
<ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="margin-bottom: -50%;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">Delete {{getCurrentResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>Are you sure want to delete "{{getResourceName()}}"?</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">Delete</button>
        </div>
      </div>
    </div>
</ng-template>

<!-- Common dissociate popup -->
<ng-template #dissociateConfirm>
    <div class="medium-popup-wrapper" style="margin-bottom: -50%;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">Dissociate {{getCurrentResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>Are you sure you want to dissociate "{{getResourceName()}}"?</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-btn-primary" (click)="dissociateAction()">Dissociate</button>
        </div>
      </div>
    </div>
</ng-template>


<!-- Change data disk size popup -->
<ng-template #virtualMachineDiskSizePopup>
    <div class="virtual-machine-size-popup modal-lg">
        <div class="modal-header-diagram-tool" style="margin-right: 8px;">
            <h3 class="save-version-heading">Select a disk size</h3>
            <button type="button" class="close pull-right margin-close-btn" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool" style="max-height: calc(100vh - 240px);">
            <div class="form-group">
                <div class="label-div">
                    <label for="diskSKU" class="control-label" style="padding: 0px; padding-bottom: 6px;">Disk SKU<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Premium SSD disks offer high-performance, low-latency disk support for I/O-intensive applications and production workloads. Standard SSD Disks are a cost effective storage option optimized for workloads that need consistent performance at lower IOPS levels. Use Standard HDD disks for Dev/Test scenarios and less critical workloads at lowest cost." container="body" placement="above" containerClass="disk-tooltip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: 53%;font-weight: 400;font-size: 13px;" [items]="diskSKUList" bindLabel="name"  name="diskSKU" placeholder="Choose disk SKU" (change)="vmDiskSizeListFilter()" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="custom-fixed-tables" style="height: calc(100vh - 410px);max-height: 223px; min-height: 136px;">
                  <table class="fix-width-vms table">
                      <thead>
                        <tr>
                          <th class="table-name">
                            <div class="flex-custom no-wrap">
                              <span>Size (GiB)</span>
                            </div>
                          </th>
                          <th class="status">
                            <div class="flex-custom no-wrap">
                              <span>Performance tier</span>
                            </div>
                          </th>
                          <th class="status">
                              <div class="flex-custom no-wrap">
                                <span>Provisioned IOPS</span>
                              </div>
                          </th>
                          <th class="status" style="border-right: solid 1px #8CD4FD;">
                            <div class="flex-custom no-wrap">
                              <span>Provisioned throughput</span>
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let list of dummyVMDiskSizeList;let index = index" [ngClass]="{'clicked-row':list.activeRow}">
                          <td style="width: 25%;" (click)="selectDiskSize(list,true);">{{list?.capabilities[0]?.value}}</td>
                          <td style="width: 25%;" (click)="selectDiskSize(list,true);">{{list?.size}}</td>
                          <td style="width: 25%;" (click)="selectDiskSize(list,true);">{{list?.capabilities[2]?.value}}</td>
                          <td style="width: 25%;" (click)="selectDiskSize(list,true);">{{list?.capabilities[4]?.value}}</td>
                        </tr>
                        <div class="col-md-12 mt-3" *ngIf="!dummyVMDiskSizeList?.length">
                          <p class="content-14 blur-text">No data Found</p>
                        </div>
                      </tbody>
                  </table>
              </div>
              <div class="form-group">
                <div class="label-div">
                    <label for="customDiskSizeAdd" class="control-label" style="padding: 0px; padding-bottom: 6px;">Custom disk size (GiB)<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enter the size of the disk you would like to create." matTooltipClass="nav-tooltips" container="body" placement="above" containerClass="disk-tooltip" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="number" style="width: 53%;" class="form-control remove-arrows" [class.border-danger]="inputError?.VMname" id="customDiskSizeAdd" autocomplete="off" [(ngModel)]="vmDisk.customDiskSizeAdd" placeholder="" name="customDiskSizeAdd" (keyup)="customDiskSizeChange()" required>
                    <p class="text-danger" *ngIf="inputError?.VMname">The value must be between 1 and 32767.</p>
                </div>   
            </div>
              <div class="form-group" *ngIf="vmDisk.diskSKU.id=='Premium_LRS'">
                <div class="label-div">
                    <label for="performanceTier" class="control-label" style="padding: 0px; padding-bottom: 6px;">Performance tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Changing the performance tier allows you to prepare for and meet higher demand without using your disk's bursting capability." container="body" placement="above" containerClass="disk-tooltip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: 53%;font-weight: 400;font-size: 13px;" [items]="vmDisk.performanceTierList" bindLabel="name" [disabled]="vmDisk?.performanceTierList?.length==0&&vmDisk?.customDiskSizeAdd" [(ngModel)]="vmDisk.performanceTier" name="performanceTier" placeholder="Select a performance tier to upgrade" [clearable]="false">
                        <ng-template ng-label-tmp let-item="item">
                            {{item?.size+' - '+item?.capabilities[0]?.value +' IOPS, '+item?.capabilities[2]?.value+' MBps'}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{item?.size+' - '+item?.capabilities[0]?.value +' IOPS, '+item?.capabilities[2]?.value+' MBps'}}
                        </ng-template>
                    </ng-select>
                    <p class="text-muted-msg" *ngIf="vmDisk?.performanceTierList?.length==0&&vmDisk?.customDiskSizeAdd">Upgraded tiers are not available for the selected size.</p>
                </div>   
            </div>
            <div class="fixed-popup-btm-btn">
              <button class="btn btn-btn-primary pull-right"
                [class.disabled]="!vmDisk.selectVmDiskSizeData||!vmDisk.customDiskSizeAdd"
                (click)="closeModal();selectedVMDisk();">Select</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- Add/Edit security rules popup -->
<ng-template #addSecurityRulesPopup>
    <div class="medium-popup-wrapper2 width-478">
      <div class="modal-header">
        <div class="d-flex">
            <div class="heading-content"><h3>{{getSecurityRulesHeader()}}</h3></div>
            <button class="cursor-pointer float-right" (click)="closeModal();resetForm()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="currentForm">
          <div class="comm-mb">
            <label class="flex-custom nowrap">Source<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="It specifies the incoming traffic from a specific source IP address range that will be allowed or denied by this rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <ng-select [items]="vmSourceList" bindLabel="name" bindValue="name" name="source" [clearable]="false" id="source" formControlName="source"></ng-select>
          </div>
          
           <div class="comm-mb" *ngIf="currentForm.get('source').value=='IP addresses'">
                <label class="flex-custom nowrap">Source IP addresses/CIDR ranges<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address (e.g. 192.168.99.0). You can also provide a comma-separated list of IP addresses or address ranges using IPv4." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <input class="form-control full-width" type="text" name="source" [clearable]="false" id="source" formControlName="sourceIP" [class.border-danger]="inputError?.sourceInvalidArgument||inputError?.sourceNotValidCIDR||inputError?.sourceOverlaping||inputError?.sourceNonNullRange"  autocomplete="off" placeholder="10.0.0.0/24"  (keyup)="validNICAddressSpace($event,'source')" >
                <p class="text-danger" *ngIf="inputError?.sourceInvalidArgument">Invalid argument: 'Malformed IP address</p>
                <p class="text-danger" *ngIf="inputError?.sourceNotValidCIDR">Invalid CIDR Block.</p>
                <p class="text-danger" *ngIf="inputError?.sourceOverlaping">Overlaps address space</p>
                <p class="text-danger" *ngIf="inputError?.sourceNonNullRange">Invalid argument: A non-null address range is required.</p>
          </div> 
          <div class="comm-mb">
            <label class="flex-custom nowrap">Source port ranges<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a single port, such as 80; a port range, such as 1024-65535; or a comma-separated list of single ports and/or port ranges, such as 80,1024-65535. This specifies on which ports traffic will be allowed or denied by this rule. Provide an asterisk (*) to allow traffic on any port." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width" type="text" autocomplete="off" id="sourcePortRange" name="sourcePortRange" formControlName="sourcePort" [class.border-danger]="inputError?.sourceNotValidPortVal||inputError?.sourceInvalidPortRange||inputError?.sourceOverlap" (keyup)="portRangeValidation($event,65535,'source')" />
              <p class="text-danger" *ngIf="inputError?.sourceNotValidPortVal">The port must be a number between 0 and 65535.</p>
            <p class="text-danger" *ngIf="inputError?.sourceInvalidPortRange">Invalid port range specified.</p>
            <p class="text-danger" *ngIf="inputError?.sourceOverlap">Overlapping ports are not permitted</p>
        </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Destination<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="It specifies the outgoing traffic for a specific destination IP address range that will be allowed or denied by this rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <ng-select [items]="destinationList" bindLabel="name" bindValue="name" name="destination" [clearable]="false" id="destination" formControlName="destination"></ng-select>
          </div>
          <div class="comm-mb" *ngIf="currentForm.get('destination').value=='IP addresses'">
            <label class="flex-custom nowrap">Destination IP addresses/CIDR ranges<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address (e.g. 192.168.99.0). You can also provide a comma-separated list of IP addresses or address ranges using IPv4." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width" type="text" name="dest" [clearable]="false" id="dest" formControlName="destinationIP" [class.border-danger]="inputError?.destInvalidArgument||inputError?.destNotValidCIDR||inputError?.destOverlaping||inputError?.destNonNullRange"  autocomplete="off" placeholder="10.0.0.0/24"  (keyup)="validNICAddressSpace($event,'dest')" >
            <p class="text-danger" *ngIf="inputError?.destInvalidArgument">Invalid argument: 'Malformed IP address</p>
            <p class="text-danger" *ngIf="inputError?.destNotValidCIDR">Invalid CIDR Block.</p>
            <p class="text-danger" *ngIf="inputError?.destOverlaping">Overlaps address space</p>
            <p class="text-danger" *ngIf="inputError?.destNonNullRange">Invalid argument: A non-null address range is required.</p>
        </div> 
          <div class="comm-mb">
            <label class="flex-custom nowrap">Service<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The destination filter can be Any, an IP address range, an application security group, or a default tag. It specifies the outgoing traffic for a specific destination IP address range that will be allowed or denied by this rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <ng-select [items]="vmServiceList" bindLabel="name" bindValue="name" name="service" [clearable]="false" id="service" formControlName="service" (change)="changeVmService()"></ng-select>
          </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Destination port ranges<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a single port, such as 80; a port range, such as 1024-65535; or a comma-separated list of single ports and/or port ranges, such as 80,1024-65535. This specifies on which ports traffic will be allowed or denied by this rule. Provide an asterisk (*) to allow traffic on any port." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width" type="text" autocomplete="off" id="destinationPortRange" name="destinationPortRange" formControlName="destinationPort" [class.border-danger]="inputError?.destNotValidPortVal||inputError?.destInvalidPortRange||inputError?.destOverlap" (keyup)="portRangeValidation($event,65535,'dest')"/>
            <p class="text-danger" *ngIf="inputError?.destNotValidPortVal">The port must be a number between 0 and 65535.</p>
            <p class="text-danger" *ngIf="inputError?.destInvalidPortRange">Invalid port range specified.</p>
            <p class="text-danger" *ngIf="inputError?.destOverlap">Overlapping ports are not permitted</p>
        </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Protocol</label>
              <div class="checkbox-cs">
                <input type="radio" name="protocol" class="form-check-inline" id='any' (change)="setProtocol('*')" [checked]="currentForm.get('protocol')?.value == '*'">
                <label class="value" for="any">Any</label>
              </div>
              <div class="checkbox-cs">
                <input type="radio" name="protocol"  class="form-check-inline" id='tcp' (change)="setProtocol('TCP')" [checked]="currentForm.get('protocol')?.value == 'TCP'">
                <label class="value" for="tcp">TCP</label>
              </div>
              <div class="checkbox-cs">
                <input type="radio" name="protocol"  class="form-check-inline" id='udp' (change)="setProtocol('UDP')" [checked]="currentForm.get('protocol')?.value == 'UDP'">
                <label class="value" for="udp">UDP</label>
              </div>
              <div class="checkbox-cs">
                <input type="radio" name="protocol"  class="form-check-inline" id='cmp' (change)="setProtocol('ICMP')" [checked]="currentForm.get('protocol')?.value == 'ICMP'">
                <label class="value" for="cmp">ICMP</label>
              </div>
          </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Action</label>
              <div class="checkbox-cs">
                <input type="radio" name="action" class="form-check-inline" id='allow' (change)="setAction('Allow')" [checked]="currentForm.get('action')?.value == 'Allow'">
                <label class="value" for="allow">Allow</label>
              </div>
              <div class="checkbox-cs">
                <input type="radio" name="action"  class="form-check-inline" id='deny' (change)="setAction('Deny')" [checked]="currentForm.get('action')?.value == 'Deny'">
                <label class="value" for="deny">Deny</label>
              </div>
          </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Priority<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Rules are processed in priority order; the lower the number, the higher the priority. We recommend leaving gaps between rules - 100, 200, 300, etc. - so that it's easier to add new rules without having to edit existing rules." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width" type="text" autocomplete="off" id="priority" name="priority" formControlName="priority" [class.border-danger]="inputError?.VMPriorityValid||inputError?.VMPriorityMaxMin||inputError?.VMPriorityDupCheck" (keyup)="validateVMPriority($event)"/>
            <p class="text-danger" *ngIf="inputError?.VMPriorityValid">Value must be a valid number.</p>
            <p class="text-danger" *ngIf="inputError?.VMPriorityMaxMin">The priority must be between 100 and 4096.</p>
            <p class="text-danger" *ngIf="inputError?.VMPriorityDupCheck">The priority you entered matches another security rule within this network security group. Priorities must be unique within existing security rules. </p>
        </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Name<span class="mandatory">*</span></label>
            <input class="form-control full-width" type="text" autocomplete="off" id="name" name="name" formControlName="name" [class.disabled]="isSecurityRulesEdit" [class.border-danger]="inputError?.nameLen||inputError?.nameValid ||inputError?.nameDupCheck" (keyup)="validateInOutBoundName($event,80)"/>
            <p class="text-danger" *ngIf="inputError?.nameLen">The name cannot be longer than 80 characters.</p>
            <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
            <p class="text-danger" *ngIf="inputError?.nameDupCheck">A rule with this name already exists within the network security group. </p>
        </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Description</label>
            <textarea name="comment" class="full-width form-control" formControlName="description" [class.border-danger]="inputError?.VMdescription" (keyup)="VmDescription($event,140)"></textarea>
            <p class="text-danger" *ngIf="inputError?.VMdescription">The description cannot be longer than 140 characters.</p>
          </div>
     
        </form>
      </div>
      <div class="modal-footer d-flex">
        <button class="btn btn-btn-primary ml-auto" [class.disabled]="invalidForm('inOutBound')|| currentForm?.invalid" (click)="saveNSGRuleData('inbound')">{{isSecurityRulesEdit ? 'Save':'Add'}}</button>
      </div>
    </div>
</ng-template>
<!-- <ng-template #addSecurityRulesPopup>   currentForm?.invalid;
    <div class="virtual-machine-size-popup modal-lg" style="width: 478px;" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool">
            <h3 class="save-version-heading">{{vmInboundRule.type=='inboundRules'?'Add inbound security rule':'Add outbound security rule'}}</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool" style="margin: 0px;padding: 16px 24px;">
            <div >
            <div class="form-group">
                <div class="label-div">
                    <label for="source" class="control-label" style="padding: 0px; padding-bottom: 6px;">Source<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="It specifies the incoming traffic from a specific source IP address range that will be allowed or denied by this rule." container="body" placement="above" containerClass="disk-tooltip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: auto;font-weight: 400;font-size: 13px;" [items]="vmSourceList" bindLabel="name" bindValue="name" [(ngModel)]="vmInboundRule.source" name="source" placeholder="Choose disk SKU" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group" *ngIf="vmInboundRule.source=='IP addresses'">
                <div class="label-div">
                    <label for="addressSpace" class="control-label" style="width: 100%;padding-left: 0px;">Source IP addresses/CIDR ranges<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address (e.g. 192.168.99.0). You can also provide a comma-separated list of IP addresses or address ranges using IPv4." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.sourceInvalidArgument||inputError?.sourceNotValidCIDR||inputError?.sourceOverlaping||inputError?.sourceNonNullRange" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="vmInboundRule.sourceAddressSpace" placeholder="10.0.0.0/24" name="addressSpace" (keyup)="validNICAddressSpace($event,'source')" required>
                    <p class="text-danger" *ngIf="inputError?.sourceInvalidArgument">Invalid argument: 'Malformed IP address</p>
                    <p class="text-danger" *ngIf="inputError?.sourceNotValidCIDR">Invalid CIDR Block.</p>
                    <p class="text-danger" *ngIf="inputError?.sourceOverlaping">Overlaps address space</p>
                    <p class="text-danger" *ngIf="inputError?.sourceNonNullRange">Invalid argument: A non-null address range is required.</p>
                </div>              
            </div> 
              <div class="form-group">
                <div class="label-div">
                    <label for="sourcePortRange" class="control-label" style="padding: 0px; padding-bottom: 6px;">Source port ranges<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a single port, such as 80; a port range, such as 1024-65535; or a comma-separated list of single ports and/or port ranges, such as 80,1024-65535. This specifies on which ports traffic will be allowed or denied by this rule. Provide an asterisk (*) to allow traffic on any port."  container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.sourceNotValidPortVal||inputError?.sourceInvalidPortRange||inputError?.sourceOverlap" id="sourcePortRange" autocomplete="off" [(ngModel)]="vmInboundRule.sourcePortRange" placeholder="" name="sourcePortRange" (keyup)="portRangeValidation($event,65535,'source')" required>
                    <p class="text-danger" *ngIf="inputError?.sourceNotValidPortVal">The port must be a number between 0 and 65535.</p>
                    <p class="text-danger" *ngIf="inputError?.sourceInvalidPortRange">Invalid port range specified.</p>
                    <p class="text-danger" *ngIf="inputError?.sourceOverlap">Overlapping ports are not permitted</p>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="destination" class="control-label" style="padding: 0px; padding-bottom: 6px;">Destination<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="It specifies the outgoing traffic for a specific destination IP address range that will be allowed or denied by this rule." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: auto;font-weight: 400;font-size: 13px;" [items]="destinationList" bindLabel="name" bindValue="name" [(ngModel)]="vmInboundRule.destination" name="destination" placeholder="Select destination" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group" *ngIf="vmInboundRule.destination=='IP addresses'">
                <div class="label-div">
                    <label for="addressSpace" class="control-label col-sm-12" style="width: 100%;padding-left: 0px;">Destination IP addresses/CIDR ranges<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address (e.g. 192.168.99.0). You can also provide a comma-separated list of IP addresses or address ranges using IPv4." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.destInvalidArgument||inputError?.destNotValidCIDR||inputError?.destOverlaping||inputError?.destNonNullRange" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="vmInboundRule.destinationAddressSpace" placeholder="10.0.0.0/24" name="addressSpace" (keyup)="validNICAddressSpace($event,'dest')" required>
                    <p class="text-danger" *ngIf="inputError?.destInvalidArgument">Invalid argument: 'Malformed IP address</p>
                    <p class="text-danger" *ngIf="inputError?.destNotValidCIDR">Invalid CIDR Block.</p>
                    <p class="text-danger" *ngIf="inputError?.destOverlaping">Overlaps address space</p>
                    <p class="text-danger" *ngIf="inputError?.destNonNullRange">Invalid argument: A non-null address range is required.</p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="Service" class="control-label" style="padding: 0px; padding-bottom: 6px;">Service<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The destination filter can be Any, an IP address range, an application security group, or a default tag. It specifies the outgoing traffic for a specific destination IP address range that will be allowed or denied by this rule." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <ng-select style="padding: 0px;width: auto;font-weight: 400;font-size: 13px;" [items]="vmServiceList" bindLabel="name" bindValue="name" [(ngModel)]="vmInboundRule.service" name="Service" placeholder="Select service" (change)="changeVmService()" [clearable]="false"></ng-select>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="destinationPortRange" class="control-label" style="padding: 0px; padding-bottom: 6px;width: 100%;">Destination port ranges<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a single port, such as 80; a port range, such as 1024-65535; or a comma-separated list of single ports and/or port ranges, such as 80,1024-65535. This specifies on which ports traffic will be allowed or denied by this rule. Provide an asterisk (*) to allow traffic on any port." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box model-drop-down">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.destNotValidPortVal||inputError?.destInvalidPortRange||inputError?.destOverlap" [disabled]="vmInboundRule.disableBasedOnService" id="destinationPortRange" autocomplete="off" [(ngModel)]="vmInboundRule.destinationPortRange" placeholder="" name="destinationPortRange" (keyup)="portRangeValidation($event,65535,'dest')" required>
                    <p class="text-danger" *ngIf="inputError?.destNotValidPortVal">The port must be a number between 0 and 65535.</p>
                    <p class="text-danger" *ngIf="inputError?.destInvalidPortRange">Invalid port range specified.</p>
                    <p class="text-danger" *ngIf="inputError?.destOverlap">Overlapping ports are not permitted</p>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="protocol" class="control-label" style="padding: 0px; padding-bottom: 6px;">Protocol</label>
                </div> 
                <div class="input-box radio-flex">
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="protocol" [disabled]="vmInboundRule.service!='Custom'" [value]="'Any'" ([ngModel])="vmInboundRule.protocol" [checked]="vmInboundRule.protocol=='Any'" (change)="vmInboundRule.protocol=$event.target.value;"><span style="padding-left: 8px;">Any</span></label>
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="protocol" [disabled]="vmInboundRule.service!='Custom'" [value]="'TCP'" ([ngModel])="vmInboundRule.protocol" [checked]="vmInboundRule.protocol=='TCP'" (change)="vmInboundRule.protocol=$event.target.value;"><span style="padding-left: 8px;">TCP</span></label>                    
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="protocol" [disabled]="vmInboundRule.service!='Custom'" [value]="'UDP'" ([ngModel])="vmInboundRule.protocol" [checked]="vmInboundRule.protocol=='UDP'" (change)="vmInboundRule.protocol=$event.target.value;"><span style="padding-left: 8px;">UDP</span></label>                    
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="protocol" [disabled]="vmInboundRule.service!='Custom'" [value]="'ICMP'" ([ngModel])="vmInboundRule.protocol" [checked]="vmInboundRule.protocol=='ICMP'" (change)="vmInboundRule.protocol=$event.target.value;"><span style="padding-left: 8px;">ICMP</span></label>                    
                </div>    
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="action" class="control-label" style="padding: 0px; padding-bottom: 6px;">Action</label>
                </div> 
                <div class="input-box radio-flex">
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="action" [value]="'Allow'" ([ngModel])="vmInboundRule.action" [checked]="vmInboundRule.action=='Allow'" (change)="vmInboundRule.action=$event.target.value;"><span style="padding-left: 8px;">Allow</span></label>
                    <label class="col-sm-1 radio-label" style="padding-left: 0px !important;"><input type="radio" style="box-shadow: none;" name="action" [value]="'Deny'" ([ngModel])="vmInboundRule.action" [checked]="vmInboundRule.action=='Deny'" (change)="vmInboundRule.action=$event.target.value;"><span style="padding-left: 8px;">Deny</span></label>                       
                </div>    
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="priority" class="control-label" style="padding: 0px; padding-bottom: 6px;">Priority<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Rules are processed in priority order; the lower the number, the higher the priority. We recommend leaving gaps between rules - 100, 200, 300, etc. - so that it's easier to add new rules without having to edit existing rules." container="body" placement="above" containerClass="disk-tooltip"  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.VMPriorityValid||inputError?.VMPriorityMaxMin||inputError?.VMPriorityDupCheck" id="priority" autocomplete="off" [(ngModel)]="vmInboundRule.priority" placeholder="" name="priority" (keyup)="validateVMPriority($event)" required>
                    <p class="text-danger" *ngIf="inputError?.VMPriorityValid">Value must be a valid number.</p>
                    <p class="text-danger" *ngIf="inputError?.VMPriorityMaxMin">The priority must be between 100 and 4096.</p>
                    <p class="text-danger" *ngIf="inputError?.VMPriorityDupCheck">This priority already used.</p>
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label" style="padding: 0px; padding-bottom: 6px;">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" style="width:100%;" [class.border-danger]="inputError?.nameLen||inputError?.nameValid ||inputError?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="vmInboundRule.name" placeholder="" name="name" (keyup)="validateInOutBoundName($event,80)" required>
                    <p class="text-danger" *ngIf="inputError?.nameLen">The name cannot be longer than 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="inputError?.nameDupCheck">Name already taken.</p>
                </div>  
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label" style="padding: 0px; padding-bottom: 6px;">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" style="width:100%;"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="vmInboundRule.comments" placeholder="" (keyup)="VmDescription($event,140)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">The description cannot be longer than 140 characters.</p>
                </div>   
            </div>
        </div>
            <div class="fixed-popup-btm-btn">
                <button class="btn btn-btn-primary pull-right" [class.disabled]="disableInOutBound()"  (click)="addInOutBoundRule();closeModal();">{{currentResourceDtls?.updateIndex!=undefined?'Update':'Add'}}</button>
            </div>
        </div>
    </div>
</ng-template> -->
<ng-template #subnetType>
    <div class="sidebar-right single add-alert" >
        <div class="header">
            <div class="d-flex">
             <h4>Edit Subnet <br> <span class="blur-text">Subnet | {{data[0]}}</span></h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="triggerOutput()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Virtual network<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width disabled" value="" type="text" autocomplete="off" id="VNetName" name="VirtualNetworkName" formControlName="vnetName"/>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Subnet name<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A subnet is a range of IP addresses in your virtual network, which can be used to isolate virtual machines from each other or from the Internet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width disabled" value="" type="text" autocomplete="off" id="subnetName" name="subnetName" formControlName="subnetName"/>
                </div>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Subnet address space<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The subnet's address range in CIDR notation (e.g. 192.168.1.0/24). 
                        It must be contained by the address space of the virtual network. 
                        The address range of a subnet which is in use can't be edited." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="subnetAddressSpace" name="subnetAddressSpace" placeholder="eg. 10.0.0.0/24" formControlName="subnetAddressSpace" [class.border-danger]="inputError?.subnetAdrspc" (keyup)="subnetAddressSpace($event)"/>
                    <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid CIDR Block.</p>
                    <p class="text-danger" *ngIf="inputError?.subnetOverlaping">This subnet address space overlaps with existing {{vNetOverlap}}</p>
                    <p class="text-danger" *ngIf="inputError?.subnetPerfix">The prefix must be smaller than or equal to 29</p>
                    <p class="text-danger" *ngIf="inputError?.subnetAddresNotInVnet">Subnet address range should contained under same virtual network's address spaces</p>
                </div>
                <div class="toggle-sec">
                    <div class="toggle-head comm-mb" id="toggleIcon" (click)="toggle()">{{getToggleText()}}</div>
                    <div class="toggle-cont" id="toggleContent">
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">NAT gateway<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="NAT gateway associated to this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <ng-select class="full-width" [items]="natGetwaysList" bindLabel="name" bindValue="id" name="natGateway"  [clearable]="false" id="natGateway" formControlName="natGateway"></ng-select>
                            <!-- [class.disabled]="!subnetData?.properties?.natGateway" -->
                        </div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Network Security Group</label>
                            <ng-select class="full-width" [items]="nsgList" bindLabel="name" bindValue="id" name="nsgName" [class.disabled]="subnetData?.name=='GatewaySubnet' ||subnetData?.name=='AzureBastionSubnet'||subnetData?.name=='AzureFirewallSubnet'" [clearable]="false" id="nsgName" formControlName="nsg"></ng-select>
                        </div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Route Table</label>
                            <ng-select class="full-width" [items]="routeTablesList" bindLabel="name" bindValue="id" name="routeTable" [clearable]="false" id="routeTable" formControlName="routeTable"></ng-select>
                            <!-- [class.disabled]="!subnetData?.properties?.routeTable" -->
                        </div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Services<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enable one or more service endpoints for this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                           <div class="margin-multi-select">
                            <ng-select class="full-width" [items]="servicesList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="name" name="service" [clearable]="false" id="service" formControlName="services">
                                <ng-template ng-multi-label-tmp let-items="items">
                                    <span>{{items.length}} selected</span>
                                  </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <div >
                                        <!-- class="checkbox-cs" -->
                                      <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> {{item.name}}
                                      <!-- <label class="value" for="item-{{index}}" [ngModel]="item$.selected" [checked]="item$.selected">{{item.name}}</label> -->
                                    </div>
                                  </ng-template>
                            </ng-select>
                           </div>
                        </div>
                        <div class="comm-mb">
                            <label class="flex-custom no-wrap">Delegate subnet to a service<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Designate a subnet to be used by a dedicated service." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <ng-select class="full-width" [items]="dsServiceList" [class.disabled]="subnetData?.properties?.ipConfigurations || subnetData?.properties?.applicationGatewayIPConfigurations" bindLabel="serviceName" bindValue="serviceName"  name="delegateSubnet" [clearable]="false" id="delegateSubnet" formControlName="delegatedSubnet"></ng-select>
                        </div>
                    </div>
                    
                </div>                    
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-outline" (click)="triggerOutput()">Cancel</button>
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid" [class.disabled]="inputError?.subnetAdrspc || inputError?.subnetNotValidCIDR || inputError?.subnetOverlaping || inputError?.subnetPerfix || inputError?.subnetAddresNotInVnet"   (click)="editSubnetList()">Save</button>
        </div>
    </div>
</ng-template>

