import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metrics-server-req',
  templateUrl: './metrics-server-req.component.html',
  styleUrls: ['./metrics-server-req.component.scss']
})
export class MetricsServerReqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
