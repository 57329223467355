import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPv4, IPSubnetwork } from 'ip-matching';
import * as _ from 'lodash';
import { UtilService } from '../../../services/util.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ResourceInputError, ResourceModel } from '../resources-model';

@Component({
  selector: 'app-subnet-resource',
  templateUrl: './subnet-resource.component.html',
  styleUrls: ['./subnet-resource.component.scss']
})
export class SubnetResourceComponent implements OnInit {
  @Input() projectData : any;
  @Input() templateName : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  subnetModel : ResourceModel = {
    resourceId:null,
    resourceName:null,
    resourceType:'subnets',
    resourceData:{
      resourceGroup:null,
      subnetAddressSpace:null,
      subnetType:'subnet',
      vnetName:null,
      natGatway:null,
      networkSecurityGroup:null,
      routeTable:null,
      services:null,
      dsService:'None',
    },
    comments:null,
    createdBy:null,
  };
  resourceGroupList:any=[];
  inputError:ResourceInputError = {
    subnetName: false,
    subnetChar: false,
    subnetAdrspc: false,
    subnetDescription: false,
    subnetDuplicate:false,
    subnetNotValidCIDR:false,
    subnetOverlaping:false,
    subnetAddresNotInVnet:false,
    subnetPerfix:false,
    dsServiceCheck:false,
  }
  natGetwaysList:any=[];
  nsgList:any=[];
  routeTablesList:any=[];
  servicesList:any=[];
  dsServiceList:any=[];
  showView:boolean=false;
  publicIPs:Array<string>=[
    '0.0.0.0/1',
    '127.0.0.0/8',
    '169.254.0.0/16',
    '169.254.0.0/15',
    '168.63.129.16/28',
    '168.63.129.16/29',
    '168.63.129.16/30',
    '168.63.129.16/31',
    '168.63.129.16/32',
    '224.0.0.0/4',
    '224.0.0.0/3',
    '255.255.255.255/32'
  ];
  
  constructor(private util:UtilService,private loader:LoaderService) { }

  async ngOnInit() { 
    const rendomValue=Math.random;
    this.loader.queActions('push',`'${rendomValue}'`);
    await this.getResourceGroupList();
    // if(this.sideSheetViewType=='new'){
    //   this.subnetModel.resourceData={};
    //   this.subnetModel.resourceName=null;
    // }
    // this.subnetModel.resourceData.subnetType='subnet';
    this.subnetModel.resourceData.vnetName=this.parentResourceDtls.resourceName;
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.parentResourceDtls.resourceId],null,null,null,true).then(async res=>{
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==JSON.parse(res.resourceData).resourceGroup);
      this.subnetModel.resourceData.resourceGroup=resourceObj.name;
      await this.getListByPropName('natGateways',undefined,undefined);
      await this.getListByPropName('networkSecurityGroups',undefined,undefined);
      await this.getListByPropName('routeTables',undefined,undefined);
      if(resourceObj&&!resourceObj.resourceGroupId){
        await this.getListByPropName('availableDelegations',resourceObj,resourceObj.location);
        await this.getListByPropName('virtualNetworkAvailableEndpointServices',resourceObj,resourceObj.location);
      } else {
        this.dsServiceList=[{
          serviceName:"None"
        }];
        this.subnetModel.resourceData.dsService='None';
        this.servicesList=[];
        this.subnetModel.resourceData.services=null;
      }
      if(this.sideSheetViewType=='edit'){
        await this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null,null,null,true).then(async res=>{
          this.subnetModel=res;
          this.subnetModel.resourceData=JSON.parse(res.resourceData);
          if(this.parentResourceDtls['updateRequestFrom']){
            this.subnetModel.resourceData.vnetName=this.parentResourceDtls.resourceName;
            this.subnetModel.resourceData.resourceGroup=resourceObj.name;
            let limitField={
              target:{
                value:this.subnetModel.resourceData.subnetAddressSpace
              }
            }
            this.subnetAddressSpace(limitField);
          }
          this.subnetUpdateAdvanceVal();
        });
      }
    })
    this.loader.queActions('pop',`'${rendomValue}'`);
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    const rendomValue=Math.random;
    this.loader.queActions('push',`'${rendomValue}'`);
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
      });
    this.loader.queActions('pop',`'${rendomValue}'`);
  }

  async getNewResourceGroup(){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(async res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  hideShow(){
    this.showView=!this.showView;
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      let parentResourceData=this.parentResourceDtls&&this.parentResourceDtls.resourceData?JSON.parse(this.parentResourceDtls.resourceData):{};
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name==this.subnetModel.resourceData&&this.subnetModel.resourceData.resourceGroup?this.subnetModel.resourceData.resourceGroup:parentResourceData.resourceGroup);
      if(propName=='natGateways'){
        if(res.value&&res.value.length>0)
        result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.natGetwaysList=result;
        this.subnetModel.resourceData.natGatway='None';
      }else if(propName=='networkSecurityGroups'){
        if(this.templateName=='virtualMachine'||this.templateName=='nsg'){
        this.nsgList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
        }else{
        if(res.value&&res.value.length>0)
        result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.nsgList=result;
        this.subnetModel.resourceData.networkSecurityGroup='None';
        }
      }else if(propName=='routeTables'){
        if(res.value&&res.value.length>0)
        result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.routeTablesList=result;
        this.subnetModel.resourceData.routeTable='None';
      }else if(propName=='availableDelegations'){
        let dsResult=[{
          serviceName:"None"
        }];
        if(res.value&&res.value.length>0)
        dsResult.push(...res.value);
        this.dsServiceList=dsResult;
        this.subnetModel.resourceData.dsService='None';
      }else if(propName=='virtualNetworkAvailableEndpointServices'){
        this.servicesList=res.value;
        this.subnetModel.resourceData.services=null;
      }
      return  result;
    },err=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='natGateways'){
        this.natGetwaysList=result;
        this.subnetModel.resourceData.natGatway='None';
      }else if(propName=='networkSecurityGroups'){
        if(this.templateName!='virtualMachine'){
          this.nsgList=[];
          }else{
        this.nsgList=result;
        this.subnetModel.resourceData.networkSecurityGroup='None';
          }
      }else if(propName=='routeTables'){
        this.routeTablesList=result;
        this.subnetModel.resourceData.routeTable='None';
      }else if(propName=='availableDelegations'){
        let dsResult=[{
          serviceName:"None"
        }];
        this.dsServiceList=dsResult;
        this.subnetModel.resourceData.dsService='None';
      }else if(propName=='virtualNetworkAvailableEndpointServices'){
        this.servicesList=[];
        this.subnetModel.resourceData.services=null;
      }
    });
  }

  async clearResourceModel(){
    this.subnetModel={
      resourceId:null,
      resourceName:null,
      resourceType:'subnet',
      resourceData:{
        resourceGroup:null,
        subnetAddressSpace:null,
        subnetType:'subnet',
        vnetName:null,
        natGatway:null,
        networkSecurityGroup:null,
        routeTable:null,
        services:null,
        dsService:null,
      },
      comments:null,
      createdBy:null,
    }
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
      await this.clearResourceModel();
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.subnetModel);
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
      await this.clearResourceModel();
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  isAddressSpace(string){
    return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
  }

  validateOverloopAddress(vlIPRange,ipRangObj){
    if(vlIPRange.range.left.input==ipRangObj.range.left.input){
      return true;
    }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
     return true;
    }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]){
        if(vlIPRange.range.left.parts[2]>ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.right.parts[2]<ipRangObj.range.right.parts[2]){
            return true;
          }
        }else if(vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.left.parts[3]>ipRangObj.range.left.parts[3]){
            if(vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
              return true;
            }
          }
        }
      }
    }else if(vlIPRange.range.left.parts[0]<ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.right.parts[0]>ipRangObj.range.right.parts[0]){
        return true;
      }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]){
        if(vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
          return true;
        }
      }
    }else {
      return false;
    }
  }

  subnetNameValidation(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.subnetName = true;
    }
    else this.inputError.subnetName = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError.subnetChar = false;
    }
    else{
      this.inputError.subnetChar = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError.subnetChar = true;
        return;
      }
    }

    if(this.parentResourceDtls&&this.parentResourceDtls.subnet&&this.parentResourceDtls.subnet.length){
      let findObj=this.parentResourceDtls.subnet.find(dt=>dt.resourceName==value&&this.subnetModel.resourceId!=dt.resourceId);
      if(findObj){
        this.inputError.subnetDuplicate=true;
        return;
      }else{
        this.inputError.subnetDuplicate=false;
      }
    }else{
      this.inputError.subnetDuplicate=false;
    }
  }

  subnetAddressSpace(limitField){
    let value =limitField.target.value
    if(!this.isAddressSpace(value)){
      console.log(value,this.isAddressSpace(value));
      this.inputError.subnetAdrspc=true;
      return;
    }
    this.inputError.subnetAdrspc=false;

    let splitArr=value.split('/');
    try{
      let vlRangeObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
      this.inputError.subnetNotValidCIDR=false;
      if(vlRangeObj.bits>29){
        this.inputError.subnetPerfix=true;
        return;
      }else{
        this.inputError.subnetPerfix=false;
      }
      if(vlRangeObj.input!=value){
        this.inputError.subnetNotValidCIDR=true;
      }else{
        this.inputError.subnetNotValidCIDR=false;
      }
      let resourceData=JSON.parse(this.parentResourceDtls.resourceData);
      resourceData.virtualNetworkAddressSpace.find((dt,index)=>{
        if(dt.address){
          let splitAr=dt.address.split('/');
          let rangeObj=new IPSubnetwork(new IPv4(splitAr[0]),splitAr[1]);
            if(this.validateOverloopAddress(vlRangeObj,rangeObj)){
              this.inputError.subnetAddresNotInVnet=false;
              return true;
            }else if(index==resourceData.virtualNetworkAddressSpace.length-1){
              this.inputError.subnetAddresNotInVnet=true;
            }
        }
      })

      if(this.parentResourceDtls.subnet&&this.parentResourceDtls.subnet.length){
        this.parentResourceDtls.subnet.find(dt=>{
          if(dt.resourceData&&this.subnetModel.resourceId!=dt.resourceId){
            let resourceDataSub=JSON.parse(dt.resourceData);
            if(resourceDataSub.subnetAddressSpace){
              const splitArrSub=resourceDataSub.subnetAddressSpace.split('/');
              let ipRangObj=new IPSubnetwork(new IPv4(splitArrSub[0]),splitArrSub[1]);
            if(this.validateOverloopAddress(vlRangeObj,ipRangObj)){
              this.inputError.subnetOverlaping=true;
              this.subnetModel['overlapingValue']=resourceDataSub.subnetAddressSpace;
              return true;
            }else{
              this.inputError.subnetOverlaping=false;
              this.subnetModel['overlapingValue']='';
            }
            }
          }
        })
      }
    }catch(e){
      this.inputError.subnetNotValidCIDR=true;
      return;
    }
  }


  subnetDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.subnetDescription = true;
    }
    else this.inputError.subnetDescription = false;
  }

  subnetUpdateAdvanceVal(){
    if(this.subnetModel.resourceData.natGatway!='None'){
      let findObj=this.natGetwaysList.find(dt=>dt.id==this.subnetModel.resourceData.natGatway);
      this.subnetModel.resourceData.natGatway=findObj?findObj.id:'None';
    }else if(this.subnetModel.resourceData.networkSecurityGroup!='None'){
      let findObj=this.nsgList.find(dt=>dt.id==this.subnetModel.resourceData.networkSecurityGroup);
      this.subnetModel.resourceData.networkSecurityGroup=findObj?findObj.id:'None';
    }else if(this.subnetModel.resourceData.routeTable!='None'){
      let findObj=this.routeTablesList.find(dt=>dt.id==this.subnetModel.resourceData.routeTable);
      this.subnetModel.resourceData.routeTable=findObj?findObj.id:'None';
    }else if(this.subnetModel.resourceData.dsService!='None'){
      let findObj=this.dsServiceList.find(dt=>dt.serviceName==this.subnetModel.resourceData.dsService.serviceName);
      this.subnetModel.resourceData.dsService=findObj?findObj:'None';
    }else if(this.subnetModel.resourceData.services instanceof Array &&(this.subnetModel.resourceData.services!=null)){
      let services=[];
      this.servicesList.forEach(dt=>{
        let findObj=this.subnetModel.resourceData.services.find(dtl=>dtl==dt.name);
        if(findObj){
          services.push(findObj);
        }
      })
      this.subnetModel.resourceData.services=services;
    }
  }

  saveResourceDetails(){
    var model = this.subnetModel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.subnetModel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  selectedSubnetType(event){
    if(event.target.value=='gatewaySubnet'){
      this.subnetModel.resourceName='GatewaySubnet';
      this.subnetModel.resourceData.networkSecurityGroup='None';
      if(this.parentResourceDtls&&this.parentResourceDtls.subnet&&this.parentResourceDtls.subnet.length){
        let findObj=this.parentResourceDtls.subnet.find(dt=>dt.resourceName==this.subnetModel.resourceName&&dt.resourceId!=this.subnetModel.resourceId);
        if(findObj){
          this.inputError.subnetDuplicate=true;
          //return;
        }else{
          this.inputError.subnetDuplicate=false;
        }
      }else{
        this.inputError.subnetDuplicate=false;
      }
    }else if(event.target.value=='subnet'){
      this.subnetModel.resourceName=null;
      this.subnetModel.resourceData.networkSecurityGroup='None';
    }
    this.subnetModel.resourceData.subnetType=event.target.value;
  }

  validateServiceWithDelegate(){
    let dsService=this.subnetModel.resourceData.dsService;
    if(dsService&&(dsService.name=='Microsoft.BareMetal.AzureVMware'||dsService.name=='Microsoft.BareMetal.CrayServers'||dsService.name=='Microsoft.HardwareSecurityModules.dedicatedHSMs'||dsService.name=='Microsoft.Netapp.volumes')){
      if(this.subnetModel.resourceData.services&&this.subnetModel.resourceData.services.length>0){
        this.inputError.dsServiceCheck=true;
      }else{
        this.inputError.dsServiceCheck=false;
      }		
    }else{
      this.inputError.dsServiceCheck=false;
    }
  }


  checkDataDisabled(){
    return !this.subnetModel.resourceName || !this.subnetModel.resourceData.subnetAddressSpace || this.inputError.subnetAdrspc || this.inputError.subnetChar || this.inputError.subnetName || this.inputError.subnetDuplicate ||this.inputError.subnetAdrspc||this.inputError.subnetNotValidCIDR||this.inputError.subnetOverlaping||this.inputError.subnetPerfix||this.inputError.subnetAddresNotInVnet || this.inputError.dsServiceCheck;
  }
}
