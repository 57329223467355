import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PipesModule } from './pipes/pipes.module';
import { EchartsModule } from './common/echarts/echarts.module';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { CodeEditorModule } from '@ngstack/code-editor';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgCircleProgressModule } from 'ng-circle-progress';

//internal imports
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDateRangeModule } from 'ngx-daterange';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TagInputModule } from 'ngx-chips';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DirectiveModule } from './directive/directive.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPowerBiModule ,NgxPowerBiService} from 'ngx-powerbi';
import { ChartsModule } from 'ng2-charts';
import { AngularMaterialModule } from '../app/angular-material/angular-material.module'
import { NgApexchartsModule } from "ng-apexcharts";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LoginMsalComponent } from './components/login-msal/login-msal.component';
import { TokenInterceptorService} from './services/token-interceptor.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { CdkTreeModule } from '@angular/cdk/tree';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { RegistrationComponent } from './components/registration/registration.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { SimpleTemplateModalComponent } from './common/simple-template-modal/simple-template-modal.component';
import { InfraDeploymentBucketComponent } from './infra-deployment-bucket/infra-deployment-bucket.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DeploymentComponent } from './infra-deployment-bucket/deployment/deployment.component';
import { BusinessContinuityComponent } from './infra-deployment-bucket/business-continuity/business-continuity.component';
import { DedicateComputeComponent } from './infra-deployment-bucket/deployment/dedicate-compute/dedicate-compute.component';
import { AciComponent } from './infra-deployment-bucket/deployment/dedicate-compute/aci/aci.component';
import { AcrComponent } from './infra-deployment-bucket/deployment/dedicate-compute/acr/acr.component';
import { LinuxVmComponent } from './infra-deployment-bucket/deployment/dedicate-compute/linux-vm/linux-vm.component';
import { WindowsVmComponent } from './infra-deployment-bucket/deployment/dedicate-compute/windows-vm/windows-vm.component';
import { DiagramModule, SnappingService, SymbolPaletteAllModule } from '@syncfusion/ej2-angular-diagrams';
import { ResourceTemplatesComponent } from './common/resource-templates/resource-templates.component';
import { ApplicationMonitoringBucketComponent } from './application-monitoring-bucket/application-monitoring-bucket.component';
import { ApplicationMonitoringDashboardComponent } from './application-monitoring-bucket/application-monitoring-dashboard/application-monitoring-dashboard.component';
import { ApplicationMonitoringMetricsComponent } from './application-monitoring-bucket/application-monitoring-metrics/application-monitoring-metrics.component';
import { ApplicationMonitoringUsageComponent } from './application-monitoring-bucket/application-monitoring-usage/application-monitoring-usage.component';
import { ApplicationMonitoringAlertsComponent } from './application-monitoring-bucket/application-monitoring-alerts/application-monitoring-alerts.component';
import { DatePipe } from '@angular/common';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ComplianceBucketComponent } from './compliance-bucket/compliance-bucket.component';
import { ComplianceOverviewComponent } from './compliance-bucket/compliance-overview/compliance-overview.component';
import { ComplianceManagePolicyComponent } from './compliance-bucket/compliance-manage-policy/compliance-manage-policy.component';
import { ComplianceReportsComponent } from './compliance-bucket/compliance-reports/compliance-reports.component';
import { ApplicationOverviewComponent } from './application-monitoring-bucket/application-monitoring-dashboard/application-overview/application-overview.component';
import { ApplicationMonitoringMetricsUsageComponent } from './application-monitoring-bucket/application-monitoring-metrics-usage/application-monitoring-metrics-usage.component';
import { OnboardingProcessComponent } from './components/onboarding-process/onboarding-process.component';
import { ClosePageComponent } from './components/close-page/close-page.component';
import { SapSupportComponent } from './components/sap-support/sap-support.component';
import { AzureSupportComponent } from './components/azure-support/azure-support.component';
import { A3sSupportComponent } from './components/a3s-support/a3s-support.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
import { ActivitiesViewComponent } from './components/activities-view/activities-view.component';
import { CreateA3sSupportTicketComponent } from './components/a3s-support/create-a3s-support-ticket/create-a3s-support-ticket.component';
import { CreateAzureSupportTicketComponent } from './components/azure-support/create-azure-support-ticket/create-azure-support-ticket.component';
import { CreateSapSupportTicketComponent } from './components/sap-support/create-sap-support-ticket/create-sap-support-ticket.component';
import { UserManualModule } from './components/user-manual/user-manual.module';
import { InfraAssessmentComponent } from './infra-deployment-bucket/infra-assessment/infra-assessment.component';
import { InfraMigrationComponent } from './infra-deployment-bucket/infra-migration/infra-migration.component';
import { InfraDevopsComponent } from './infra-deployment-bucket/infra-devops/infra-devops.component';
import { ArchitectureBlueprintModule } from './infra-deployment-bucket/architecture-blueprint/architecture-blueprint.module';
import { ManagementModule } from './infra-deployment-bucket/management/management.module';
import { BreadcrumbsModule } from './common/breadcrumbs/breadcrumbs.module';
import { environment } from '../environments/environment';
import { SecurityBucketModule } from './security-bucket/security-bucket.module';
import { BudgetAlertState } from './state/budget-alert/budget-alert.state';
import { DataGovernanceAccessManagementState } from './state/data-governance-access-management/data-governance-access-management.state';
import { DataGovernanceDdcState } from './state/data-governance-ddc/data-governance-ddc.state';
import { DataGovernanceMonitoringState } from './state/data-governance-monitoring/data-governance-monitoring.state';
import { DataGovernanceOverviewState } from './state/data-governance-overview/data-governance-overview.state';
import { DataGovernanceSecurityState } from './state/data-governance-security/data-governance-security.state';
import { SecurityBucketState } from './state/security-bucket.state';
import { AdditionalStandardsComponent } from './compliance-bucket/compliance-overview/additional-standards/additional-standards.component';
import { ApplicationMonitoringSAPHANAComponent } from './application-monitoring-bucket/application-monitoring-sap-hana/application-monitoring-sap-hana.component';
import { AMSAPHANAOverviewComponent } from './application-monitoring-bucket/application-monitoring-sap-hana/am-sap-hana-overview/am-sap-hana-overview.component';
import { AmSapHanaBackupComponent } from './application-monitoring-bucket/application-monitoring-sap-hana/am-sap-hana-backup/am-sap-hana-backup.component';
import { AmSapHanaSystemCheckComponent } from './application-monitoring-bucket/application-monitoring-sap-hana/am-sap-hana-system-check/am-sap-hana-system-check.component';
import { AmSapHanaDataSizeComponent } from './application-monitoring-bucket/application-monitoring-sap-hana/am-sap-hana-data-size/am-sap-hana-data-size.component';
import { AmSapHanaReplicationComponent } from './application-monitoring-bucket/application-monitoring-sap-hana/am-sap-hana-replication/am-sap-hana-replication.component';
import { ApplicationMonitoringSapNetweaverComponent } from './application-monitoring-bucket/application-monitoring-sap-netweaver/application-monitoring-sap-netweaver.component';
import { SapNetweaverOverviewComponent } from './application-monitoring-bucket/application-monitoring-sap-netweaver/sap-netweaver-overview/sap-netweaver-overview.component';
import { SapNetweaverAvailabilityComponent } from './application-monitoring-bucket/application-monitoring-sap-netweaver/sap-netweaver-availability/sap-netweaver-availability.component';
import { SapNetweaverPerformanceComponent } from './application-monitoring-bucket/application-monitoring-sap-netweaver/sap-netweaver-performance/sap-netweaver-performance.component';
import { SapNetweaverEnqueueStatisticsComponent } from './application-monitoring-bucket/application-monitoring-sap-netweaver/sap-netweaver-enqueue-statistics/sap-netweaver-enqueue-statistics.component';
import { SapNetweaverQueueStatisticsComponent } from './application-monitoring-bucket/application-monitoring-sap-netweaver/sap-netweaver-queue-statistics/sap-netweaver-queue-statistics.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#0B70D5",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#0B70D5",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "#0B70D5",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "Please Wait...",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    LoginMsalComponent,
    RegistrationComponent,
    PopUpComponent,
    ProfileSettingsComponent,
    SimpleTemplateModalComponent,
    InfraDeploymentBucketComponent,
    DeploymentComponent,
    BusinessContinuityComponent,
    DedicateComputeComponent,
    AciComponent,
    AcrComponent,
    LinuxVmComponent,
    WindowsVmComponent,
    ResourceTemplatesComponent,
    ApplicationMonitoringBucketComponent,
    ApplicationMonitoringDashboardComponent,
    ApplicationMonitoringMetricsComponent,
   
    ApplicationMonitoringUsageComponent,
    ApplicationMonitoringAlertsComponent,
    ComplianceBucketComponent,
    ComplianceOverviewComponent,
    ComplianceManagePolicyComponent,
    ComplianceReportsComponent,
    
    ApplicationOverviewComponent,
    ApplicationMonitoringMetricsUsageComponent,
    OnboardingProcessComponent,
    ClosePageComponent,
    SapSupportComponent,
    AzureSupportComponent,
    A3sSupportComponent,
    FavouritesComponent,
    ActivitiesViewComponent,
    CreateA3sSupportTicketComponent,
  
    CreateAzureSupportTicketComponent,
    CreateSapSupportTicketComponent,
    InfraAssessmentComponent,
    InfraMigrationComponent,
    InfraDevopsComponent,
   
    
    
    AdditionalStandardsComponent,
    ApplicationMonitoringSAPHANAComponent,
    AMSAPHANAOverviewComponent,
    AmSapHanaBackupComponent,
    
    AmSapHanaSystemCheckComponent,
    AmSapHanaDataSizeComponent,
    AmSapHanaReplicationComponent,
    ApplicationMonitoringSapNetweaverComponent,
    SapNetweaverOverviewComponent,
    SapNetweaverAvailabilityComponent,
    SapNetweaverPerformanceComponent,
    SapNetweaverEnqueueStatisticsComponent,
    SapNetweaverQueueStatisticsComponent,
  ], 
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CarouselModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
    }),
    DirectiveModule,
    PipesModule,
    EchartsModule,
    NgxEchartsModule.forRoot({
      echarts
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgSelectModule,
    NgxDateRangeModule,
    ReactiveFormsModule,
    Ng2PageScrollModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    AngularMaterialModule,
    ChartsModule,
    TooltipModule.forRoot(),
    DragDropModule,
    ClipboardModule,
    NgxPaginationModule,
    CdkTreeModule,
    NgxPowerBiModule,
    NgApexchartsModule,
    NgMultiSelectDropDownModule,
    NgxDaterangepickerMd.forRoot(),
    BsDropdownModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DiagramModule,
    SymbolPaletteAllModule,
    CodeEditorModule.forRoot(),
    UiSwitchModule,
    PopoverModule.forRoot(),
    TagInputModule,
    UserManualModule,
    ArchitectureBlueprintModule,
    ManagementModule,
    BreadcrumbsModule,
    NgxsModule.forRoot([
      BudgetAlertState,
      DataGovernanceAccessManagementState,
      DataGovernanceDdcState,
      DataGovernanceMonitoringState,
      DataGovernanceOverviewState,
      DataGovernanceSecurityState,
      SecurityBucketState,
    ],
    {
      developmentMode:!environment.production,
      selectorOptions:{
        injectContainerState:false,
        suppressErrors:true
      }
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled:environment.production
    }),
    SecurityBucketModule,
    NgCircleProgressModule.forRoot()
  ],
  providers: [
    {
      provide : HTTP_INTERCEPTORS,
      useClass : TokenInterceptorService,
      multi : true
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BsDatepickerConfig,
    SnappingService,
    TitleCasePipe,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
