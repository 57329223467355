<div class="security-recommednation-container">
    <div class="header-container">
      <h2 class="title">Endpoint Protection</h2>
      <!-- <span>{{currentProject?.projectName}}</span> -->
    </div>
  
     <!-- Filter Section -->
     <div class="custom-filter-wrapper flex-custom">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                        <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                        <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                        <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                        <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button>
    </div>
    
    <div class="dashboard-body">
        <div class="row">
            <div class="col-md-4 padding-div left">
                <div class="graph-div count-card-height mt-2">
                    <div class="heading">
                        <div class="d-flex">
                            <div class="about">
                                <h4>Top 4 Most Running Processes</h4>
                                <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.runningProcessesCard)}} . {{getTimeFilterVal(runningProcessesCard)}}</p>
                            </div>
                            <div class="float-right flex-custom no-wrap">
                              <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('runningProcessesCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                              <div class="cal-wrap">
                                  <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                  matTooltipShowDelay="500" (click)="openFilter('customFilter21')"><img src="/assets/security/clock.svg" alt=""></span>
                                  <div class="cal-box"  id="customFilter21">
                                      <div class="cal-inner">
                                      <div class="select-sec">
                                          <ng-container *ngFor="let row of timeFilterList">
                                              <div class="select-item" [class.active]="runningProcessesCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,runningProcessesCard):applyFilter(row.id,runningProcessesCard)">{{row.name}}</div>
                                          </ng-container>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                          </div> 
                        </div>
                    </div>
                    <div class="row m-0 mt-3" *ngIf="runningProcessesCard?.tableData?.length && !runningProcessesCard?.showLoader">
                      <ng-container *ngFor="let item of runningProcessesCard?.tableData">
                          <div class="detail-top" [ngClass]="{'col-sm-6':runningProcessesCard?.tableData.length>1,'col-sm-12':runningProcessesCard?.tableData.length<2}">
                              <div class="detail-card most-running-card">
                                  <span class="card-name" [title]="item.name">{{item.name}}</span>
                                  <span class="card-count">{{item.count}}</span>
                              </div>
                          </div>
                      </ng-container>
                    </div>
                    <div class="loader-gif loader-for-card" *ngIf="runningProcessesCard?.showLoader">
                        <img src="assets/icons/blue-loader.gif"/>
                        <p>Getting data...</p>
                    </div>
                    <div class="dash-text-center count-card-text card-not-found-case" *ngIf="(!runningProcessesCard?.tableData?.length && !runningProcessesCard?.showLoader)">
                        <img src="/assets/security/check-not-found.svg" alt="">
                        <p class="text-14-22 mt-3">No running processes found.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 padding-div pl-4 middle">
                <div class="graph-div count-card-height mt-2">
                    <div class="heading">
                        <div class="d-flex">
                            <div class="about">
                                <h4>Top 4 Missing Critical Updates</h4>
                                <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.missingSecurityCard)}} . {{getTimeFilterVal(missingSecurityCard)}}</p>
                            </div>
                            <div class="float-right flex-custom no-wrap">
                              <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('missingSecurityCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                              <div class="cal-wrap">
                                  <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                  matTooltipShowDelay="500" (click)="openFilter('customFilter22')"><img src="/assets/security/clock.svg" alt=""></span>
                                  <div class="cal-box"  id="customFilter22">
                                      <div class="cal-inner">
                                      <div class="select-sec">
                                          <ng-container *ngFor="let row of timeFilterList">
                                              <div class="select-item" [class.active]="missingSecurityCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,missingSecurityCard):applyFilter(row.id,missingSecurityCard)">{{row.name}}</div>
                                          </ng-container>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                          </div> 
                        </div>
                    </div>
                    <div class="row m-0 mt-3" *ngIf="missingSecurityCard?.tableData?.length && !missingSecurityCard?.showLoader">
                      <ng-container *ngFor="let item of missingSecurityCard?.tableData">
                          <div class="detail-top" [ngClass]="{'col-sm-6':missingSecurityCard?.tableData.length>1,'col-sm-12':missingSecurityCard?.tableData.length<2}">
                              <div class="detail-card missing-security-card">
                                  <span class="card-name" [title]="item.name">{{item.name}}</span>
                                  <span class="card-count">{{item.count}}</span>
                              </div>
                          </div>
                      </ng-container>
                    </div>
                    <div class="loader-gif loader-for-card" *ngIf="missingSecurityCard?.showLoader">
                        <img src="assets/icons/blue-loader.gif"/>
                        <p>Getting data...</p>
                    </div>
                    <div class="dash-text-center count-card-text card-not-found-case" *ngIf="(!missingSecurityCard?.tableData?.length && !missingSecurityCard?.showLoader)">
                        <img src="/assets/security/check-not-found.svg" alt="">
                        <p class="text-14-22 mt-3">No missing critical updates found.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 pl-4 right">
              <div class="graph-div count-card-height mt-2">
                  <div class="heading">
                      <div class="d-flex">
                          <div class="about">
                              <h4>Top 4 VMs with Failed Logon</h4>
                              <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.vmFailedCard)}} . {{getTimeFilterVal(vmFailedCard)}}</p>
                          </div>
                          <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('vmFailedCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter23')"><img src="/assets/security/clock.svg" alt=""></span>
                                <div class="cal-box"  id="customFilter23">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <ng-container *ngFor="let row of timeFilterList">
                                            <div class="select-item" [class.active]="vmFailedCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,vmFailedCard):applyFilter(row.id,vmFailedCard)">{{row.name}}</div>
                                        </ng-container>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                      </div>
                  </div>
                  <div class="row m-0 mt-3" *ngIf="vmFailedCard?.tableData?.length && !vmFailedCard?.showLoader">
                    <ng-container *ngFor="let item of vmFailedCard?.tableData">
                        <div class="detail-top" [ngClass]="{'col-sm-6':vmFailedCard?.tableData.length>1,'col-sm-12':vmFailedCard?.tableData.length<2}">
                            <div class="detail-card failed-login-card">
                                <span class="card-name" [title]="item.name">{{item.name}}</span>
                                <span class="card-count">{{item.count}}</span>
                            </div>
                        </div>
                    </ng-container>
                  </div>
                  <div class="loader-gif loader-for-card" *ngIf="vmFailedCard?.showLoader">
                      <img src="assets/icons/blue-loader.gif"/>
                      <p>Getting data...</p>
                  </div>
                  <div class="dash-text-center count-card-text card-not-found-case" *ngIf="(!vmFailedCard?.tableData?.length && !vmFailedCard?.showLoader)">
                      <img src="/assets/security/check-not-found.svg" alt="">
                      <p class="text-14-22 mt-3">No VMs with failed logons found.</p>
                  </div>
              </div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-12 right">
              <div class="graph-div">
                  <div class="heading">
                      <div class="d-flex">
                          <div class="about">
                              <h4>VMs with Security Logs</h4>
                              <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.resourceHealth)}} . Top 10 . {{getTimeFilterVal(devicesWithSecurityFilter)}}</p>
                          </div>
                          <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('resourceHealth')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(devicesWithSecurityPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="devicesWithSecurityFilter?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,devicesWithSecurityFilter):applyFilter(row.id,devicesWithSecurityFilter)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div> 
                      </div>
                  </div>
                  <div echarts [loading]='true' [options]="devicesWithSecurityOptions" *ngIf="devicesWithSecurityData?.length  && !showLoader?.decvicesWithSecurity"></div>
                  <div class="loader-gif" *ngIf="showLoader?.decvicesWithSecurity">
                      <img src="assets/icons/blue-loader.gif"/>
                      <p>Getting data...</p>
                  </div>
                  <div class="dash-text-center" *ngIf="(!devicesWithSecurityData?.length && !showLoader?.decvicesWithSecurity)">
                      <img src="/assets/security/check-not-found.svg" alt="">
                      <p class="text-14-22 bold-600 mt-3">No VMs with security logs found in the selected timeframe.</p>
                  </div>
              </div>
          </div>
          </div>
          <div class="row">
            <div class="col-md-12 right">
                <div class="graph-div">
                  <div class="heading">
                      <div class="d-flex">
                          <div class="about">
                              <h4>Top 5 Most Running Processes</h4>
                              <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.processStartedData)}} . {{getTimeFilterVal(processStartedData)}}</p>
                          </div> 
                          <div class="float-right flex-custom no-wrap">
                              <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('processStartedData')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                              <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(processStartedDataPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                              <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter2')"><img src="/assets/security/clock.svg" alt=""></span>
                                <div class="cal-box"  id="customFilter2">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',processStartedData)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',processStartedData)">Last hour</div>
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',processStartedData)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',processStartedData)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',processStartedData)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',processStartedData)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',processStartedData)">Last 3 days</div>
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',processStartedData)">Last 7 days</div>
                                        <div class="select-item" [class.active]="processStartedData?.currentFilter?.type == 'custom'" (click)="openModal(openCal,processStartedData)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                          </div>                  
                      </div>                
                  </div>
                  <app-stacked-common-time-line-chart [data]='processStartedData' *ngIf="processStartedData?.series?.length && !processStartedData?.showLoader"></app-stacked-common-time-line-chart>
                  <div class="loader-gif" *ngIf="processStartedData?.showLoader">
                      <img src="assets/icons/blue-loader.gif"/>
                      <p>Getting data...</p>
                  </div>
                  <div class="dash-text-center" *ngIf="!processStartedData?.series?.length && !processStartedData?.showLoader">
                      <img src="/assets/security/check-not-found.svg" alt="">
                      <p class="text-14-22 bold-600 mt-3">No running processes found in the selected timeframe.</p>
                  </div>
              </div>
              </div>
          </div>
      <div class="row">
        <div class="col-md-6 left padding-div">
            <div class="graph-div">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Updates Available</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.updateAvailAcross)}} . Top 10 . {{getTimeFilterVal(updateAvailAcrossOption)}}</p>
                      </div> 
                      <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" id="updateAvailAcross" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('updateAvailAcross')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(updateAvailAcrossPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter1">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="updateAvailAcrossOption?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,updateAvailAcrossOption):applyFilter(row.id,updateAvailAcrossOption)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                      </div>                  
                  </div>                
              </div>
              <!-- <app-doughnut-chart [data]='coverageResourceData' *ngIf="coverageResourceData?.data?.length && !coverageResourceData?.showLoader"></app-doughnut-chart> -->
              <app-horizontal-bar-chart [data]='updateAvailAcrossOption' *ngIf="updateAvailAcrossOption.data?.length&&!showLoader?.updateAvailAcrossData"></app-horizontal-bar-chart>
              <div class="loader-gif" *ngIf="showLoader?.updateAvailAcrossData">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="!updateAvailAcrossOption.data?.length && !showLoader?.updateAvailAcrossData">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No updates available in the selected timeframe.</p>
              </div>
          </div>
          </div>
        <div class="col-md-6 pl-4 right">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Missing Critical And Security Updates</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.missingSecurity)}} . Top 10 . {{getTimeFilterVal(missingSecurityOption)}}</p>
                    </div> 
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" id="missingSecurity" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('missingSecurity')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(missingSecurityPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter3')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter3">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="missingSecurityOption?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,missingSecurityOption):applyFilter(row.id,missingSecurityOption)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>                
            </div>
            <app-horizontal-bar-chart [data]='missingSecurityOption' *ngIf="missingSecurityOption.data?.length&&!showLoader?.missingSecurityData"></app-horizontal-bar-chart>
            <div class="loader-gif" *ngIf="showLoader?.missingSecurityData">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!missingSecurityOption.data?.length && !showLoader?.missingSecurityData">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No missing critical and security updates found in the selected timeframe.</p>
            </div>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 left padding-div">
            <div class="graph-div">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>VMs with Most Logon</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.devicesWithMost)}} . Top 10 . {{getTimeFilterVal(devicesWithMostOption)}}</p>
                      </div> 
                      <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" id="devicesWithMost" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('devicesWithMost')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(devicesWithMostPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter4')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter4">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="devicesWithMostOption?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,devicesWithMostOption):applyFilter(row.id,devicesWithMostOption)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                      </div>                  
                  </div>                
              </div>
              <!-- <app-doughnut-chart [data]='coverageResourceData' *ngIf="coverageResourceData?.data?.length && !coverageResourceData?.showLoader"></app-doughnut-chart> -->
              <app-horizontal-bar-chart [data]='devicesWithMostOption' *ngIf="devicesWithMostOption.data?.length&&!showLoader?.devicesWithMostData"></app-horizontal-bar-chart>
              <div class="loader-gif" *ngIf="showLoader?.devicesWithMostData">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="!devicesWithMostOption.data?.length && !showLoader?.devicesWithMostData">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No VMs with most logon found in the selected timeframe.</p>
              </div>
          </div>
          </div>
        <div class="col-md-6 pl-4 right">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>VMs with Failed Logon</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.devicesWithFailed)}} . Top 10 . {{getTimeFilterVal(devicesWithFailedOption)}}</p>
                    </div> 
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" id="devicesWithFailed" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('devicesWithFailed')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(devicesWithFailedPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter5')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter5">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="devicesWithFailedOption?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,devicesWithFailedOption):applyFilter(row.id,devicesWithFailedOption)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>                
            </div>
            <app-horizontal-bar-chart [data]='devicesWithFailedOption' *ngIf="devicesWithFailedOption.data?.length&&!showLoader?.devicesWithFailedData"></app-horizontal-bar-chart>
            <div class="loader-gif" *ngIf="showLoader?.devicesWithFailedData">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!devicesWithFailedOption.data?.length && !showLoader?.devicesWithFailedData">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No VMs with failed logon found in the selected timeframe.</p>
            </div>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 right">
            <div class="graph-div mb-4" style="padding-right: 12px;">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>New Processes Observed</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.newProcesesObserved)}} . {{getTimeFilterVal(newProcesesObserved)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('newProcesesObserved')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <span class="action-icon" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('New Processes Observed','tableNewP')"><img src="assets/security/download_icon.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter6')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter6">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="newProcesesObserved?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,newProcesesObserved):applyFilter(row.id,newProcesesObserved)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="newProcesesObserved?.tableData?.length && !newProcesesObserved?.showLoader">
                  <table class="table" id="tableNewP">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>File name</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',0)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Computer</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Frequency</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',2)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Start time (UTC)</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',3)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>End time (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('newProcesesObserved',4)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let row of newProcesesObserved.tableData">
                            <!-- <td>{{row[4]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td> -->
                            <td style="width:34% !important" [title]="row[0]">{{row[0]}}</td>
                            <td>{{row[1]}}</td>
                            <td style="width: 1% !important;">{{row[2]}}</td>
                            <td>{{row[3]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                            <td>{{row[4]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        </tr>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif" *ngIf="newProcesesObserved?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!newProcesesObserved?.tableData?.length && !newProcesesObserved?.showLoader)">
                    <img src="/assets/security/check-not-found.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No new processes observed found in the selected timeframe.</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #devicesWithFailedPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>VMs with Failed Logon</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table1>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithFailedTableData','name')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count of Failed Logins</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithFailedTableData','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of devicesWithFailedTableData">
                          <td>{{row[0].split('.')[0]}}</td>
                          <td>{{row[2]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!devicesWithFailedTableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('VMs with Failed Logon',table1)" *ngIf="devicesWithFailedTableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #devicesWithMostPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>VMs with Most Logon</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table2>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithMostTableData','name')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count of Logins</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithMostTableData','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of devicesWithMostTableData">
                          <td>{{row[0].split('.')[0]}}</td>
                          <td>{{row[2]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!devicesWithMostTableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('VMs with Most Logon',table2)" *ngIf="devicesWithMostTableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #missingSecurityPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Missing Critical And Security Updates</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table3>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('missingSecurityTableData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Classification</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('missingSecurityTableData','name')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('missingSecurityTableData','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of missingSecurityTableData">
                          <td>{{row[0].split('.')[0]}}</td>
                          <td>{{row[1]}}</td>
                          <td>{{row[3]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!missingSecurityTableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Missing Critical And Security Updates',table3)" *ngIf="missingSecurityTableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #updateAvailAcrossPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Updates Available</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table4>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('updateAvailAcrossTableData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>OS version</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('updateAvailAcrossTableData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>No. of Critical Updates</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('updateAvailAcrossTableData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>No. of Security Updates</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('updateAvailAcrossTableData','name')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>No. of Other Updates</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('updateAvailAcrossTableData','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of updateAvailAcrossTableData">
                          <td>{{row[0].split('.')[0]}}</td>
                          <td>{{row[1]}}</td>
                          <td>{{row[2]}}</td>
                          <td>{{row[3]}}</td>
                          <td>{{row[4]}}</td>
                          <!-- <td>{{row[5]}}</td> -->
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!updateAvailAcrossTableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Updates Available',table4)" *ngIf="updateAvailAcrossTableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #devicesWithSecurityPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>VMs with Security Logs</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table5>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithSecurityTableData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Activity</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithSecurityTableData','name')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Security logs</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('devicesWithSecurityTableData','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of devicesWithSecurityTableData">
                          <td style="width: 30% !important;">{{row[0].split('.')[0]}}</td>
                          <td>{{row[1]}}</td>
                          <td style="width: 1% !important;">{{row[3]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!devicesWithSecurityTableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('VMs with Security Logs',table5)" *ngIf="devicesWithSecurityTableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #processStartedDataPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Top 5 Most Running Processes</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table5>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processStartedData',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processStartedData',1)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processStartedData',2)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processStartedData',3)">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of processStartedData.tableData">
                          <td>{{row[0]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                          <td>{{row[1]}}</td>
                          <td>{{row[2]}}</td>
                          <td>{{row[4]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!processStartedData.tableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Top 5 Most Running Processes',table5)" *ngIf="processStartedData.tableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>