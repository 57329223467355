<div class="a3s-support">
        <p class="heading" id="0">A3S Support</p>
        <p class="mb-0" >You can get product related support from trained A3S product engineers, 
            log tickets and chat for instant help.  </p>
            <img src="assets\user-manual\support\List.svg" >
        <ul>
            <li>Click <b>create new ticket</b>.</li>
         <li>Fill the form below to log a ticket with the support team.  </li>
         <img src="assets\user-manual\support\Support-Form.svg" >
         <li>Click <b>submit</b>. </li>
         <li>Support team will work with you to provide resolution within the defined SLA 
             for given severity of the issue.  </li>
        </ul>
</div>
<!-- <img src="assets\user-manual\support\List.svg" >
<figcaption class="caption">Login Screen</figcaption> -->
<!-- <img src="assets\user-manual\support\Support-Form.svg" > -->
