import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { VirtualNetworkResourceComponent } from './virtual-network-resource/virtual-network-resource.component';
import { SubnetResourceComponent } from './subnet-resource/subnet-resource.component';
import { VirtualMachineResourceComponent } from './virtual-machine-resource/virtual-machine-resource.component'
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { NewResourceGroupComponent } from './new-resource-group/new-resource-group.component';
import { NetworkSecurityGroupComponent } from './network-security-group/network-security-group.component';
import { SynapseWorkspaceComponent } from './synapse-workspace/synapse-workspace.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MachineLearningComponent } from './machine-learning/machine-learning.component';
import { AvailabilitySetComponent } from './availability-set/availability-set.component';
import { KeyVaultComponent } from './key-vault/key-vault.component';
import { RouteTableComponent } from './route-table/route-table.component';
import { ConnectionsResourceComponent } from './connections-resource/connections-resource.component';
import { DiskResourceComponent } from './disk-resource/disk-resource.component';
import { LoadBalancerComponent } from './load-balancer/load-balancer.component';
import { LocalNetworkGatewayComponent } from './local-network-gateway/local-network-gateway.component';
import { LogAnalyticsComponent } from './log-analytics/log-analytics.component';
import { ApplicationInsightComponent } from './application-insight/application-insight.component';
import { StorageAccountComponent } from './storage-account/storage-account.component';
import { PublicIpResourceComponent } from './public-ip-resource/public-ip-resource.component';
import { NetworkInterfaceComponent } from './network-interface/network-interface.component';
import { DirectiveModule } from '../../directive/directive.module';
import { VirtualNetworkGatewayComponent } from './virtual-network-gateway/virtual-network-gateway.component';


@NgModule({
  declarations: [
    SubnetResourceComponent,
    VirtualNetworkResourceComponent,
    VirtualMachineResourceComponent,
    NewResourceGroupComponent,
    NetworkSecurityGroupComponent,
    SynapseWorkspaceComponent,
    MachineLearningComponent,
    AvailabilitySetComponent,
    KeyVaultComponent,
    RouteTableComponent,
    ConnectionsResourceComponent,
    DiskResourceComponent,
    LoadBalancerComponent,
    LocalNetworkGatewayComponent,
    LogAnalyticsComponent,
    ApplicationInsightComponent,
    StorageAccountComponent,
    PublicIpResourceComponent,
    NetworkInterfaceComponent,
    VirtualNetworkGatewayComponent
  ],
  exports: [
    SubnetResourceComponent,
    VirtualNetworkResourceComponent,
    VirtualMachineResourceComponent,
    NewResourceGroupComponent,
    NetworkSecurityGroupComponent,
    SynapseWorkspaceComponent,
    MachineLearningComponent,
    AvailabilitySetComponent,
    KeyVaultComponent,
    RouteTableComponent,
    ConnectionsResourceComponent,
    DiskResourceComponent,
    LoadBalancerComponent,
    LocalNetworkGatewayComponent,
    LogAnalyticsComponent,
    ApplicationInsightComponent,
    StorageAccountComponent,
    PublicIpResourceComponent,
    NetworkInterfaceComponent,
    VirtualNetworkGatewayComponent
  ],
  imports: [
    AngularMaterialModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgxSliderModule,
    PopoverModule.forRoot(),
    DirectiveModule,
    ReactiveFormsModule
  ]
})
export class ResourcesModule { }
