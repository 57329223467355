import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auditing-and-logging',
  templateUrl: './auditing-and-logging.component.html',
  styleUrls: ['./auditing-and-logging.component.scss']
})
export class AuditingAndLoggingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
