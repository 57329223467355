import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-azure-blob-storage',
  templateUrl: './azure-blob-storage.component.html',
  styleUrls: ['./azure-blob-storage.component.scss']
})
export class AzureBlobStorageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
