import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit} from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
import { IPv4, IPSubnetwork, matches } from 'ip-matching';
import { LoaderService } from 'src/app/services/loader.service';
import { LBResourceModel, NewResourceGroup, ResourceInputError } from '../resources-model';

@Component({
  selector: 'app-load-balancer',
  templateUrl: './load-balancer.component.html',
  styleUrls: ['./load-balancer.component.scss']
})
export class LoadBalancerComponent implements OnInit {
  @Input() projectData : any;
  @Input() templateName : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();

  loadBalancermodel : LBResourceModel={
    resourceId: null,
    resourceName: null,
    resourceType: 'loadBalancers',
    resourceData: {
      type: 'public',
      sku: 'Standard',
      tier: 'Regional',
      IPaddressAssignment: 'Static',
      routingPreference: 'microsoftNetwork',
      publicIP: 'new',
      subnet:null,
      availabilityZone: 'No Zone',
      virtualNetwork: undefined,
      publicIPaddress: undefined,
      vnetName: null,
      resourceGroup: null,
      vnetResourceGroup: null,
      tagList: [{
        tagName:null,
        value:null
      }]
    },
    comments: null,
    createdBy: null
  };
  show : boolean = false;
  inputError:ResourceInputError= {
    VMname:false,
    VmSpecialChar:false,
    VMdescription:false,
    nicValidIPError: false,
    nicRegisterIPError: false,
    nicAddrUsedError: false,
    nicSubnetRangeError: true,
    duplicateVnet:false,
  }
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  resourceGroupList:any=[];
  showView:boolean=false;
  existingVNList:any=[];
  existingSubnetList:any=[];
  vmPublicIPList:any=[
    {name:'None'}
  ];
  tempVmPublicIPList:any=[];
  zonesList:any=[
    {name:'No Zone'},
    {name:'Zone-reduntant'},
    {name:'1'},
    {name:'2'},
    {name:'3'}
  ];

  zonesListForInternal:any=[
    {name:'Zone-reduntant'},
    {name:'1'},
    {name:'2'},
    {name:'3'}
  ];
  locationCheckList:Array<string>=[
    'eastus2',
    'westus',
    'westeurope',
    'southeastasia',
    'centralus',
    'northeurope',
    'eastasia'
  ];
  existingLBList: any=[];
  existingNewLBList: any=[];
  
  constructor(private util:UtilService,private loader:LoaderService) { }

  async ngOnInit() {
    this.loader.showLoader();
    await this.getResourceGroupList();
    let parentResourceData,resourceObj;
    if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
      this.loadBalancermodel.resourceData.subnet=this.parentResourceDtls.resourceName;
      parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
      this.loadBalancermodel.resourceData.vnetName=parentResourceData.vnetName;
      resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
      this.loadBalancermodel.resourceData.resourceGroup=resourceObj.name;
      this.loadBalancermodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
      this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
    }
    await this.getListByPropName('publicIPAddresses',undefined,undefined);
    await this.getListByPropName('virtualNetworksForLoadBalancer',undefined,undefined);
    await this.getListByPropName('loadBalancers',undefined,undefined);
    this.vmPublicIPList.splice(0,1);
    await this.getExistNewlyResourceDetails('loadBalancers');
    if(this.sideSheetViewType=='edit'){
      await this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null,null,null,true).then(async res=>{
        this.loadBalancermodel=res;
        this.loadBalancermodel.resourceData=JSON.parse(res.resourceData);
        const resGroupObj={name:this.loadBalancermodel.resourceData.resourceGroup};
        const resourceObjTemp=this.resourceGroupList.find(dt=>dt.name==resourceObj.name);
        if(resourceObjTemp&&!resourceObjTemp.resourceGroupId) {
          await this.getListByPropName('subnetByVN',resGroupObj,undefined);
        }
        if(this.parentResourceDtls['updateRequestFrom']){
          this.loadBalancermodel.resourceData.vnetName=this.parentResourceDtls.resourceName;
          this.loadBalancermodel.resourceData.resourceGroup=resourceObj.name;
        }
      });
    }
    this.loader.hideLoader();
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType === 'loadBalancers') {
        this.existingNewLBList=res;
      }
    },err=>{
      if(resourceType === 'loadBalancers') {
        this.existingNewLBList=[];
      }
    });
  }

  async getSubnetList(){
    this.loadBalancermodel.resourceData.subnet=null;
    if(this.loadBalancermodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.loadBalancermodel.resourceData.resourceGroup);
      if(!resourceObj.resourceGroupId){
        this.loader.showLoader();
        await this.getListByPropName('subnetByVN',resourceObj,undefined);
        this.loader.hideLoader();
      }else{
        this.existingSubnetList=[];
      }
    }
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
      });
  }

  onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.loadBalancermodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.loadBalancermodel.resourceData.resourceGroup=undefined; 
    });
  }

  editNewResourceGroup(item){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item);    
    this.show = !this.show;
  }

  async getNewResourceGroup(){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  showGlobalTier(){
    if(this.loadBalancermodel&&this.loadBalancermodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.loadBalancermodel.resourceData.resourceGroup);
      if(resourceObj.resourceGroupLocation||resourceObj.location){
        if(this.locationCheckList.includes(resourceObj.resourceGroupLocation?resourceObj.resourceGroupLocation:resourceObj.location)){
          return true;
        }else{
          return false;
        }
      }
    } 
  }

  addTagVM(){
    this.loadBalancermodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTagVM(index){
    this.loadBalancermodel.resourceData.tagList.splice(index,1);
  }

  hideShow(){
    this.showView=!this.showView;
  }

  changeLoaderSKU(){
    if((this.loadBalancermodel.resourceData.sku=="Basic"||this.loadBalancermodel.resourceData.sku=="Standard")&&this.loadBalancermodel.resourceData.type=="internal"){
      this.loadBalancermodel.resourceData.IPaddressAssignment='Dynamic';
      this.loadBalancermodel.resourceData.tier='Regional';
    }else if((this.loadBalancermodel.resourceData.sku=="Basic")&&this.loadBalancermodel.resourceData.type=="public"){
      this.loadBalancermodel.resourceData.tier='Regional';
      this.loadBalancermodel.resourceData.IPaddressAssignment='Dynamic';
    }
    if(this.loadBalancermodel.resourceData.sku=="Standard"&&this.loadBalancermodel.resourceData.type=="internal"){
      this.loadBalancermodel.resourceData.availabilityZone="Zone-reduntant";
    }else{
      this.loadBalancermodel.resourceData.availabilityZone=null;
    }
  }
  changeLoaderType(){
    if((this.loadBalancermodel.resourceData.sku=="Basic"||this.loadBalancermodel.resourceData.sku=="Standard")&&this.loadBalancermodel.resourceData.type=="internal"){
      this.loadBalancermodel.resourceData.IPaddressAssignment='Dynamic';
      this.loadBalancermodel.resourceData.tier='Regional';
    }else if((this.loadBalancermodel.resourceData.sku=="Basic")&&this.loadBalancermodel.resourceData.type=="public"){
      this.loadBalancermodel.resourceData.tier='Regional';
      this.loadBalancermodel.resourceData.IPaddressAssignment='Dynamic';
    }
    if(this.loadBalancermodel.resourceData.sku=="Standard"&&this.loadBalancermodel.resourceData.type=="internal"){
      this.loadBalancermodel.resourceData.availabilityZone="Zone-reduntant";
      this.loadBalancermodel.resourceData.routingPreference='microsoftNetwork';
    }else{
      this.loadBalancermodel.resourceData.availabilityZone=null;
    }    
  }

  changeLoaderTier(){
    if(this.loadBalancermodel.resourceData.tier=='Global'){
      this.loadBalancermodel.resourceData.IPaddressAssignment='Static';
      this.loadBalancermodel.resourceData.routingPreference='microsoftNetwork';
    }
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName=='virtualNetworksForLoadBalancer'?'virtualNetworks':propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:propName=='subnetByVN'?this.loadBalancermodel.resourceData.virtualNetwork:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      let parentResourceData=this.parentResourceDtls&&this.parentResourceDtls.resourceData?JSON.parse(this.parentResourceDtls.resourceData):{};
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name==this.loadBalancermodel&&this.loadBalancermodel.resourceData&&this.loadBalancermodel.resourceData.resourceGroup?this.loadBalancermodel.resourceData.resourceGroup:parentResourceData.resourceGroup);
      if(propName=='virtualNetworksForLoadBalancer'){
        this.existingVNList=res.value.filter(dt=>dt['location']==tempResourceObj.location);//res.value;
      } else if(propName=='publicIPAddresses'){
        let PIPResult=[{
          name:"None"
        }];
        if(res.value&&res.value.length>0)
        PIPResult.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.vmPublicIPList=PIPResult;
      } else if(propName == 'subnetByVN'){
        this.existingSubnetList=res.value;
      } else if(propName == 'loadBalancers'){
        this.existingLBList=res.value;
      }
      return  result;
    },err=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='publicIPAddresses'){
        let PIPResult=[{
          name:"None"
        }];
        this.vmPublicIPList=PIPResult;
      }
    });
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this[this.templateName+'model']);
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  VmNameValidation(limitField, limitNum) {
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMname = true;
    }
    else this.inputError.VMname = false;

    let regex=/\@|\&|\*|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
    if(value[0]=='_'||value[value.length-1]=='.' || value[value.length-1]=='-'||regex.test(value)){
      this.inputError.VmSpecialChar =true;
      return;
    } 
    this.inputError.VmSpecialChar =false;

    if((/^[0-9]*$/).test(value)){
      this.inputError.VMOnlyNUmbers =true;
      return;
    }

    this.inputError.VMOnlyNUmbers =false;

    this.vmDuplicateCheck(value);
    
  }

  vmDuplicateCheck(value){
    let findObj=this.existingLBList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
    if(this.existingNewLBList&&!findObj){
      findObj=this.existingNewLBList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase()&&dt.resourceId!=this.loadBalancermodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateVnet =true; 
    }else{
      this.inputError.duplicateVnet =false; 
    }
  }

  VmDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMdescription = true;
    }
    else this.inputError.VMdescription = false;
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  isAddressSpace(string){
    return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.loadBalancermodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  loadBalancerPrivateIpAddress(model) {
    this.inputError.nicValidIPError = false;
    this.inputError.nicRegisterIPError = false;
    this.inputError.nicAddrUsedError = false;
    this.inputError.nicSubnetRangeError = true;
    if(model.resourceData.subnet) {
      let dt=model;
        if (dt.resourceData) {
          let resourceData = dt.resourceData;
          if (resourceData.subnet.properties.addressPrefix) {
            let splitArr = resourceData.subnet.properties.addressPrefix.split('/');
            try {
              let val = model.resourceData.privateIPAddrSpace;
              let ipObj = new IPv4(val);
              this.inputError.nicSubnetRangeError = matches(val, resourceData.subnet.properties.addressPrefix);
              if (this.inputError.nicSubnetRangeError) {
                let ipRange 
                ipRange = new IPSubnetwork(new IPv4(splitArr[0]), splitArr[1]);
                if (val == ipRange.range.left.input || val == ipRange.range.right.input) {
                  this.inputError.nicRegisterIPError = true;
                } else if (ipObj.parts[0] == ipRange.range.left.parts[0] && ipObj.parts[1] == ipRange.range.left.parts[1] && ipObj.parts[2] == ipRange.range.left.parts[2] && (ipObj.parts[3] == ipRange.range.left.parts[3] + 1 || ipObj.parts[3] == ipRange.range.left.parts[3] + 2 || ipObj.parts[3] == ipRange.range.left.parts[3] + 3)) {
                  this.inputError.nicRegisterIPError = true;
                }
              }
            } catch (err) {
              this.inputError.nicValidIPError = true;
            }
          }
        }
      
    }
  }

  saveResourceDetails(){
    var model = this.loadBalancermodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.loadBalancermodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  checkDataDisabled(){
    return !this.loadBalancermodel.resourceName||!this.loadBalancermodel.resourceData.resourceGroup || this.inputError.VMname || this.inputError.VmSpecialChar || !this.loadBalancermodel.resourceData.publicIP || !this.loadBalancermodel.resourceData.publicIPaddress;
  }
}
