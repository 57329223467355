<div class="dashboard-top">
    <div class="flex-custom no-wrap">
      <div class="">
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <div class="ml-auto">
        <div class="main-nav">
          <ul class="nav-wrap m-0" >
            <li class="list-item" [class.active]="currentTab == 'overview'" (click)="currentTab = 'overview'">
              <!-- <img  class="mr-2" [src]="currentTab == 'overview'?'/assets/monitor/SAPHANA/overview-active.svg':'/assets/monitor/SAPHANA/overview.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5" y="5" width="4.16667" height="4.16667" rx="1" fill="#8995A2"/>
                <rect x="10.8333" y="5" width="4.16667" height="4.16667" rx="1" fill="#8995A2"/>
                <rect x="5" y="10.833" width="4.16667" height="4.16667" rx="1" fill="#8995A2"/>
                <rect x="10.8333" y="10.833" width="4.16667" height="4.16667" rx="1" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Overview</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'availability'" (click)="currentTab = 'availability'">
              <!-- <img  class="mr-2" [src]="currentTab == 'availability'?'/assets/monitor/SAPHANA/availability-active.svg':'/assets/monitor/SAPHANA/availability.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99998 14.7616C12.6299 14.7616 14.7619 12.6296 14.7619 9.99967C14.7619 7.36975 12.6299 5.23777 9.99998 5.23777C7.37005 5.23777 5.23807 7.36975 5.23807 9.99967C5.23807 12.6296 7.37005 14.7616 9.99998 14.7616ZM9.99998 16.6663C13.6819 16.6663 16.6666 13.6816 16.6666 9.99967C16.6666 6.31778 13.6819 3.33301 9.99998 3.33301C6.31808 3.33301 3.33331 6.31778 3.33331 9.99967C3.33331 13.6816 6.31808 16.6663 9.99998 16.6663Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99998 7.08301C10.4602 7.08301 10.8333 7.4561 10.8333 7.91634V9.16634H12.0833C12.5435 9.16634 12.9166 9.53944 12.9166 9.99967C12.9166 10.4599 12.5435 10.833 12.0833 10.833H9.99998C9.53974 10.833 9.16665 10.4599 9.16665 9.99967V7.91634C9.16665 7.4561 9.53974 7.08301 9.99998 7.08301Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Availability</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'performance'" (click)="currentTab = 'performance'">
              <!-- <img  class="mr-2" [src]="currentTab == 'performance'?'/assets/monitor/SAPHANA/performance-active.svg':'/assets/monitor/SAPHANA/performance.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5893 6.91107C15.9147 7.23651 15.9147 7.76414 15.5893 8.08958L11.4226 12.2562C11.0972 12.5817 10.5695 12.5817 10.2441 12.2562L8.33335 10.3455L5.58928 13.0896C5.26384 13.415 4.7362 13.415 4.41076 13.0896C4.08533 12.7641 4.08533 12.2365 4.41076 11.9111L7.7441 8.57774C8.06954 8.2523 8.59717 8.2523 8.92261 8.57774L10.8334 10.4885L14.4108 6.91107C14.7362 6.58563 15.2638 6.58563 15.5893 6.91107Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Performance</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'enqueueStatistics'" (click)="currentTab = 'enqueueStatistics'">
              <!-- <img  class="mr-2" [src]="currentTab == 'enqueueStatistics'?'/assets/monitor/SAPHANA/enqueue-active.svg':'/assets/monitor/SAPHANA/enqueue.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83333 7.49967C6.29357 7.49967 6.66667 7.87277 6.66667 8.33301V11.6663C6.66667 12.1266 6.29357 12.4997 5.83333 12.4997C5.3731 12.4997 5 12.1266 5 11.6663V8.33301C5 7.87277 5.3731 7.49967 5.83333 7.49967Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.83301C10.4602 5.83301 10.8333 6.2061 10.8333 6.66634V13.333C10.8333 13.7932 10.4602 14.1663 10 14.1663C9.53976 14.1663 9.16667 13.7932 9.16667 13.333V6.66634C9.16667 6.2061 9.53976 5.83301 10 5.83301Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1667 5.83301C14.6269 5.83301 15 6.2061 15 6.66634V13.333C15 13.7932 14.6269 14.1663 14.1667 14.1663C13.7064 14.1663 13.3333 13.7932 13.3333 13.333V6.66634C13.3333 6.2061 13.7064 5.83301 14.1667 5.83301Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Enqueue Statistics</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'queueStatistics'" (click)="currentTab = 'queueStatistics'">
              <!-- <img  class="mr-2" [src]="currentTab == 'queueStatistics'?'/assets/monitor/SAPHANA/queque-active.svg':'/assets/monitor/SAPHANA/queque.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83333 5.83301C6.29357 5.83301 6.66667 6.2061 6.66667 6.66634V13.333C6.66667 13.7932 6.29357 14.1663 5.83333 14.1663C5.3731 14.1663 5 13.7932 5 13.333V6.66634C5 6.2061 5.3731 5.83301 5.83333 5.83301Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.83301C10.4602 5.83301 10.8333 6.2061 10.8333 6.66634V13.333C10.8333 13.7932 10.4602 14.1663 10 14.1663C9.53976 14.1663 9.16667 13.7932 9.16667 13.333V6.66634C9.16667 6.2061 9.53976 5.83301 10 5.83301Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1667 5.83301C14.6269 5.83301 15 6.2061 15 6.66634V13.333C15 13.7932 14.6269 14.1663 14.1667 14.1663C13.7064 14.1663 13.3333 13.7932 13.3333 13.333V6.66634C13.3333 6.2061 13.7064 5.83301 14.1667 5.83301Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Queue Statistics</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  <div class="dashboard-main">
   <app-sap-netweaver-overview *ngIf="currentTab == 'overview'"></app-sap-netweaver-overview>
   <app-sap-netweaver-availability *ngIf="currentTab == 'availability'"></app-sap-netweaver-availability>
   <app-sap-netweaver-performance *ngIf="currentTab == 'performance'"></app-sap-netweaver-performance>
   <app-sap-netweaver-enqueue-statistics *ngIf="currentTab == 'enqueueStatistics'"></app-sap-netweaver-enqueue-statistics>
   <app-sap-netweaver-queue-statistics *ngIf="currentTab == 'queueStatistics'"></app-sap-netweaver-queue-statistics>
  </div>