<div class="introduction">
    <p class="heading" id="0">Overview</p>
    <p class="mb-0" >Application Health Monitoring landing page displays the dashboard with overall 
        health of the first application deployed under first project.  </p>
        <img class="image mb-1" src="assets\user-manual\apps-health-monitoring\Project-Overview.svg" >
        <figcaption class="caption">Application Health Monitoring dashboard </figcaption>
             <ul>
                 <li>By default, Metrics & Usage data is displayed for the first project. </li>
                 <li>Metrics and Usage tabs are placed at right top corner. </li>
                 <img class="image" src="assets\user-manual\apps-health-monitoring\Menu.svg" >
                 <li>Filter options are present above the dashboard, you can use them to define the scope for which you want to view and analyze the data for. </li>
                 <img class="image"style="width: 60%;" src="assets\user-manual\apps-health-monitoring\Filters.svg" >
                 <li>Alerts tab is used to create and manage alerts. </li>
                 <li>Click refresh  <img src="assets\user-manual\apps-health-monitoring\Vector.svg" width="16.67px" height="16.67px"> icon on the tile to refresh the data.  </li>
                 <li>Click table  <img src="assets\user-manual\threshold.svg"  width="16.67px" height="16.67px"> icon to view & download information in tabular format.  </li>
                 <li>Click clock  <img src="assets\user-manual\apps-health-monitoring\Vector2.svg" width="16.67px" height="16.67px"> icon to set the timeframe.  </li>
                 <li>Click threshold  <img src="assets\user-manual\apps-health-monitoring\Vector3.svg" width="16.67px" height="16.67px"> icon to set custom value.  </li>
                 <img class="image" style="width: 50%;"   src="assets\user-manual\apps-health-monitoring\Tile.svg"  >
            </ul>
          
</div>
