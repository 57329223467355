import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retail-recommender',
  templateUrl: './retail-recommender.component.html',
  styleUrls: ['./retail-recommender.component.scss']
})
export class RetailRecommenderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
