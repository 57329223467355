import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-cm-and-optimization',
  templateUrl: './overview-cm-and-optimization.component.html',
  styleUrls: ['./overview-cm-and-optimization.component.scss']
})
export class OverviewCmAndOptimizationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
