import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-a3s-support',
  templateUrl: './a3s-support.component.html',
  styleUrls: ['./a3s-support.component.scss']
})

export class A3sSupportComponent{ 
 
  dummyTicketList : any = [];
  ticketList: any = [];
  datasort: string = 'desc';
  currentUrl: any;
  searchText: any;
  
  

  constructor(private util:UtilService){}
  ngOnInit(): void {
    this.getTicketData()
  }  

  getTableText(type) {
    switch (type) {
        case 'single': return this.ticketList.length + ' ticket(s) are shown';
        case 'multiple': return this.ticketList.length + ' out of ' + this.dummyTicketList.length + ' ticket(s) are shown';
    }
  }

  getTicketData(){
    this.util.handleRequest('get','a3s_supportTicket_getA3sTicket',[],null,null,null,true).then(res=>{
      this.ticketList = res.recordset
      this.dummyTicketList = this.ticketList
      console.log(this.ticketList)
    })
  }

  route(){
   this.util.route(['/a3s/a3sSupportCreate'])
  }

  search(txt) {
    txt = txt.toLowerCase();
    this.ticketList = [...this.dummyTicketList];
    let list = this.ticketList.filter(resp => {
        return resp.problemSummary.toLowerCase().includes(txt)
    })
    this.ticketList = list;
  }

  sortAll(value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this.ticketList;
    sorted.sort((a, b) => {
        switch (value) {
            default: {
              let x = a[value].toLowerCase();
              let y = b[value].toLowerCase();
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
        }
    })
    this.ticketList = sorted;
}


getChipClass(value){
  let status = value.toLowerCase()
  switch (status) {
    case 'success': return 'success-chip';
    case 'error': return 'error-chip';
    case 'open': return 'ready-chip';
    case 'warning': return 'warning-chip';
    default: return 'warning-chip';
  }
}

getStatusChipImg(value) {
  let status = value.toLowerCase()
  switch (status) {
    case 'success': return 'assets/icons/green-tick.svg'
    case 'open': return 'assets/common-icons/openArrow.svg'
    case 'error': return 'assets/icons/error-info.svg';
    case 'warning': return '/assets/health-monitoring/warn.svg';
 }
}

}




