<div class="introduction">
    <p class="heading" id="0">Intended Audience</p>
    <p class="para">A3S is a fully self-service SaaS platform that provides comprehensive, fully 
              automated, zero code cloud management capabilities that enables Azure users to make use of 
              the platform in an easy & seamless manner.  </p>
    <p>A3S' key goal is to enable you to leverage Azure to its fullest extent to modernize your 
               business without the need to spend time & effort to get into technical complexities of the 
               platform or to write code or do manual deployments & thereon worry about ongoing monitoring, 
               management & remediation of the resources, all the while also ensuring strong cyber/network 
               security, data security, access control, and identity management etc.   </p>
    <p>A3S accomplishes this goal in multiple ways depending on your situation:    </p>
        <ol>
            <li><b>Starting with Azure :</b> if you are new to Azure, you’ll find A3S to be an awesome solution 
                with all its intelligent and comprehensive automation that’s backed by relentless 24x7 
                support to enable you to start using Azure for your business without a need to know anything 
                at all about it.</li>
            <li><b>Intermediate Expertise :</b> you’ll find A3S to be an awesome solution to perform activities 
                that you need to use Azure for as you modernize your business, plus continuous monitoring & 
                GRC will give you peace of mind that your Azure deployments are healthy & secure.   
            </li>
            <li><b>Power User :</b> if you are an Azure guru then you’ll find A3S to be an awesome solution 
                that saves you time by taking care of tedious tasks that necessarily don’t need your time &
                 effort and thereon by making sure your deployments are secure, stable & healthy. 
            </li>
            </ol>
            <p>Same dynamics as above applies to various other scenarios where you may have a small team 
                that’s better utilized to focus on solving your business problems, or you have a small IT 
                team that caters to large number of business teams and their voluminous Azure related 
                requests, or you have no team at all – in all these scenarios, A3S will be an awesome 
                solution that does all things related to Azure that need to be done pronto and continually.
            </p>
           
    
        </div>
 