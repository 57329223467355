import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-a3s-homepage',
  templateUrl: './a3s-homepage.component.html',
  styleUrls: ['./a3s-homepage.component.scss']
})
export class A3sHomepageComponent implements OnInit {
  @Input() activeSidebar: string;


  constructor() { }

  ngOnInit(): void {
  }
 
}
