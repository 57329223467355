import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-stacked-bar-chart',
  template: `<div echarts [loading]='true' [options]="options"></div>`,
})
export class VerticalStackedBarChartComponent implements OnChanges {
  options: any = {};
  @Input('data') data: any = [];
  constructor() { }

  ngOnChanges() {
    var data = this.data
    this.options = {
      color: this.data.color,
      tooltip: {
          // trigger: 'axis',
          // axisPointer: {            // Use axis to trigger tooltip
          //     type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
          // }
      },
      legend: {
          data: data.legendData || [],
          bottom: 0,
          left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: data.xAxis || [],
        axisTick: {
          show: false
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            return (value.length > 6 ? (value.slice(0,6)+"...") : value )
          }
          // rotate: 30 //If the label names are too long you can manage this by rotating the label.
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#333',
          padding: [5, 0, 0, 0],
          fontSize: 10, 
          formatter: function (params) {
            return params + ' GB'
          },
        },
      },
      series: data.series
    };
  }

}
