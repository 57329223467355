import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { filter } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-architecture-blueprint-list',
  templateUrl: './architecture-blueprint-list.component.html',
  styleUrls: ['./architecture-blueprint-list.component.scss']
})
export class ArchitectureBlueprintListComponent implements OnInit {

  projectList: any = [];
  dummyProjectList: any = [];
  datasort: string;
  currentUrl: any;

  showList : boolean = true;
  noTableData : boolean = false;

  constructor(private util:UtilService, private _snackBar: MatSnackBar, private router: Router,private loader: LoaderService) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event) => {
        this.currentUrl = event['urlAfterRedirects']
    })
    if(!this.currentUrl) this.currentUrl = this.router.url
   }

  ngOnInit() {
     this.getAllProjectData();
  }


  getAllProjectData(){
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    var createdBy = get_Email.userName;
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){}
    }
    return this.util.handleRequest('get','a3s_architectureBlueprint_getAllProjects',[createdBy,userObjectId],null,null,null,true).then(res=>{
      this.projectList = res || []
      this.dummyProjectList = this.projectList
      this.util.groupedLog(this.dummyProjectList,'Project List')
    })
  }

  search(txt) {
    txt = txt.toLowerCase();
    this.projectList = [...this.dummyProjectList];
    let list = this.projectList.filter(resp => {
        return resp.projectName.toLowerCase().startsWith(txt)
    })
    this.projectList = list;
  }

  getTableText(type) {
    switch (type) {
        case 'single': return this.projectList.length + ' project(s) are shown';
        case 'multiple': return this.projectList.length + ' out of ' + this.dummyProjectList.length + ' project(s) are shown';
    }
  }

  routeToSummary(list){
    this.util.setCache('currentProject',list.projectName);
    // this.util.route(['/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList/architectureBlueprintSummary'],true,{ data: JSON.stringify(list)});
    if(list.status == 'PENDING' || list.status == 'UNDER REVIEW' || list.status == 'APPROVED') this.util.route(['/a3s/diagramTool'],true,{ data: JSON.stringify(list)});
    else this.util.route(['/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList/architectureBlueprintSummary'],true,{ data: JSON.stringify(list)});
  }

  routeToCreateNewProjectForm(){
    this.util.route(['/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList/createNewProject'],true);
  }

  routeToDiagramTool(){
    this.util.route(['/a3s/diagramTool'],true);
    // this.util.route(['/a3s/infraDeploymentBucket/architectureBlueprint/newProject'],true);
  }

  sortAll(value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this.projectList;
    sorted.sort((a, b) => {
        switch (value) {
            case 'project': {
                let x = a.projectName.toLowerCase();
                let y = b.projectName.toLowerCase();
                return (x < y) ? num : ((x > y) ? -num : 0)
            }
            case 'subscription': {
                let x = a.subscriptionName.toLowerCase();
                let y = b.subscriptionName.toLowerCase();
                return (x < y) ? num : ((x > y) ? -num : 0)
            }
            case 'status': {
                let x = a.status.toLowerCase();
                let y = b.status.toLowerCase();
                return (x < y) ? num : ((x > y) ? -num : 0)
            }
            case 'createdBy': {
                let x = a.createdBy.toLowerCase();
                let y = b.createdBy.toLowerCase();
                return (x < y) ? num : ((x > y) ? -num : 0)
            }
            case 'timeStamp': {
              let x = a.createdDate.toLowerCase();
              let y = b.createdDate.toLowerCase();
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
                return;
            }
        }
    })
    this.projectList = sorted;
}

openSnackBar(message: string, action: string, projectId) {
    let timerId = setTimeout(() => {
      this.deleteProject(projectId);
    }, 4000);

    let sb = this._snackBar.open(message, action, {
      duration: 3500,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    sb.onAction().subscribe(() => {
      clearTimeout(timerId);
    });
  }

  deleteProject(projectId){
    this.loader.showLoader();
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteProject',[projectId],null,null,null,true).then(async res=>{
     await this.getAllProjectData();
     this.loader.hideLoader();
    },err=>{
      this.loader.hideLoader();
    })
  }

  allowUrl(){
    return ['/a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList'].indexOf(this.currentUrl)  > -1 ? true :false;
  }
}
