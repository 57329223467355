<app-virtual-network-resource *ngIf="resourceName=='virtualNetworks'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-virtual-network-resource>

<app-subnet-resource *ngIf="resourceName=='subnets'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-subnet-resource>

<app-virtual-machine-resource *ngIf="resourceName=='virtualMachines'|| resourceName=='sqlVirtualMachines'|| resourceName=='linuxVirtualMachines'"  [templateName]="resourceName" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-virtual-machine-resource>

<app-network-security-group *ngIf="resourceName=='networkSecurityGroups'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-network-security-group>

<app-synapse-workspace *ngIf="resourceName=='synapseAnalyticsWorkspaces'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-synapse-workspace>

<app-machine-learning *ngIf="resourceName=='machineLearningWorkspaces'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-machine-learning>

<app-availability-set *ngIf="resourceName=='availabilitySets'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-availability-set>

<app-route-table *ngIf="resourceName=='routeTables'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-route-table>

<app-connections-resource *ngIf="resourceName=='connections'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-connections-resource>

<app-disk-resource *ngIf="resourceName=='disks'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-disk-resource>

<app-load-balancer *ngIf="resourceName=='loadBalancers'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-load-balancer>

<app-local-network-gateway *ngIf="resourceName=='localNetworkGateways'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-local-network-gateway>

<app-log-analytics *ngIf="resourceName=='logAnalyticWorkspaces'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-log-analytics>

<app-application-insight *ngIf="resourceName=='applicationInsights'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-application-insight>

<app-storage-account *ngIf="resourceName=='storageAccounts'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-storage-account>

<app-public-ip-resource *ngIf="resourceName=='publicIPAddress'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-public-ip-resource>

<app-network-interface *ngIf="resourceName=='networkInterfaces'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-network-interface>

<app-virtual-network-gateway *ngIf="resourceName=='virtualNetworkGateways'" [projectData]="projectData" [parentResourceDtls]="parentResourceDtls" [currentResourceDtls]="currentResourceDtls" [sideSheetViewType]="sideSheetViewType" (triggerBtn)="submit($event)"></app-virtual-network-gateway>
