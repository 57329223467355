<div class="introduction">

             <p class="heading">Create New Action Group </p>  
             <ol class="mb-0" >
                 <li>Click <b>Alerts</b> from the left menu. </li>
                 <li>Click <b>New Action group. </b></li>
                 <li>Provide needed inputs in the New Action Group side sheet. </li>
                 <li>Click <b>Create. </b> </li>
             </ol>
             <img style="width:50%;height:50%" src="assets\user-manual\infra-health-monitoring\Side-sheet.svg" >
             <figcaption class="caption col-md-6"> Action group side sheet </figcaption>
     
</div>