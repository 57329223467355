<div class="overview-content">
    <div class="header-container">
        <h2 class="title">Queue Statistics</h2>
    </div>

     <!-- Filter Section -->
     <div class="d-flex">
        <div class="custom-filter-wrapper flex-custom" style=" width: 100%;">
            <div class="custom-filter">
                <span class="text">Subscription</span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <div class="enlist-srch">
                        <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                        <img src="assets/icons/search.png">
                    </div>
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-dropdown" [formGroup]="processDetails">
                <ng-select [items]="providerList" *ngIf="providerList.length" [searchable]="false" bindLabel="name"
                  bindValue="name" name="provider" formControlName="provider" [closeOnSelect]="true" [clearable]="false"
                  id="provider" (change)="changeFilter()">
                  <ng-template ng-label-tmp let-item="item">
                    SID: {{item?.name}}
                  </ng-template>
                </ng-select>
            </div>
            <div class="custom-filter">
                <span class="text">App server</span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngIf="processList?.length>1">
                            <input type="checkbox" class="form-check-inline" name="f_listItem_{{processList.length}}" (change)="checkedAll($event)" [checked]="checkedAllAppServer()">
                            <label class="value" for="f_listItem_{{processList.length}}" id="f_listItemName_{{processList.length}}">All</label>
                        </div>
                        <div class="checkbox-cs" *ngFor="let data of processList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="checkedValue(data,$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.name}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!processList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-select *ngIf="timeFilterView" [items]="timeFilterList" [searchable]="false" bindLabel="name" bindValue="id" [closeOnSelect]="true" name="timeRange" placeholder="Time Range:" style="width: 200px;" (change)="currentFilter.type=='custom'?openModal(openCal,null):applyFilter(currentFilter.type,null)" [(ngModel)]="currentFilter.type" [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
                Time Range: {{item?.name}}
            </ng-template>
        </ng-select>
    </div>
    <div class="row custom-padding">
        <div class="col-md-12 right">
            <div class="graph-div card-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Queue Statistics Summary</h4>
                            <p>Refreshed: {{refreshedTimeFormat(queueStatisticsSummary?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('queueStatisticsSummary')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="queueStatisticsSummary?.tableData?.length && !queueStatisticsSummary?.showLoader">
                  <table class="table">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Queue</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('queueStatisticsSummary',0)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Status</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('queueStatisticsSummary',2)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Free 
                                    Queue (%)</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('queueStatisticsSummary',3)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Average 
                                    Requests in Queue</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('queueStatisticsSummary',3)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Max Requests 
                                    in Queue</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('queueStatisticsSummary',3)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Dispatcher 
                                    Max Queue</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('queueStatisticsSummary',3)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Requests Written </span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('queueStatisticsSummary',3)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Requests 
                                    Read</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('queueStatisticsSummary',1)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of queueStatisticsSummary.tableData">
                            <tr>
                                <td class="elipse-column" [title]="row[0]">{{row[0]||'-'}}</td>
                                <td>
                                    <div class="chip" [ngClass]="getStatusClass(row[1])">
                                        <img [src]="getStatusChipImg(row[1])">
                                        {{row[1] | titlecase}}
                                     </div>
                                </td>
                                <td>{{row[2]||'-'}}</td>
                                <td>{{row[3]||'-'}}</td>
                                <td>{{row[4]||'-'}}</td>
                                <td>{{row[5]||'-'}}</td>
                                <td>{{row[6]||'-'}}</td>
                                <td>{{row[7]||'-'}}</td>
                            </tr>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif custom-loader-gif" *ngIf="queueStatisticsSummary?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!queueStatisticsSummary?.tableData?.length && !queueStatisticsSummary?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 right">
            <div class="graph-div custom-progress-card">
                <div class="heading">
                    <div class="d-flex flex-align-custom">
                        <div class="about">
                            <h4>Queue Length Trend</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(queueLengthTrend?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap" style="align-items: baseline;" [formGroup]="processDetails">
                            <ng-select [items]="workProcessList" *ngIf="workProcessList.length" [searchable]="false" bindLabel="name" placeholder="Queue Type:" style="min-width: 200px;margin-right: 14px;width: max-content;" name="workProcess" formControlName="workProcess" [closeOnSelect]="false" [clearable]="false" id="workProcess" (change)="changeWorkProcess()" [multiple]="true" groupBy="groupBy" [selectableGroup]="true" [selectableGroupAsModel]="false">
                                <!-- <ng-template ng-label-tmp let-item="item">
                                    Queue Type: {{item?.name}}
                                </ng-template> -->
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected"/> All
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div *ngIf="processDetails.value.workProcess.length == 1" class="ng-values-list">
                                        <div class="ng-value" *ngFor="let item of items | slice:0:1">
                                        <!-- <span class="ng-value-icon right" (click)="clear(item)">×</span>   -->
                                        <span class="ng-value-label">Queue Type: {{item.name}}</span>                                    
                                        </div>
                                    </div>
                                    <div *ngIf="processDetails.value.workProcess.length > 1" class="ng-summary-list">
                                        <div class="ng-value">
                                        <span class="ng-value-label">Queue Type: {{ processDetails.value.workProcess.length==workProcessList.length?'All':processDetails.value.workProcess.length+' Selected' }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected"/> {{item.name}}
                                </ng-template>
                            </ng-select>
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('queueLengthTrend')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="queueLengthTrend?.series?.length && !queueLengthTrend?.showLoader">
                    <div class="chart-ht" echarts [options]="queueLengthTrend?.options" [loading]="true"></div>
                    <div class="d-flex" *ngIf="queueLengthTrend?.averageList?.length">
                        <ng-container *ngFor="let item of queueLengthTrend?.averageList" >
                          <div class="bot-strip" [ngStyle]="{'border-color':item.colorCode}">
                              <div class="str-name">{{item.name}}</div>
                              <div class="str-value">{{item.average}}</div>
                          </div>
                        </ng-container>
                    </div>
                </ng-container>
                <div class="loader-gif custom-loader-gif" *ngIf="queueLengthTrend?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!queueLengthTrend?.series?.length && !queueLengthTrend?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        
    
    </div>
</div>

<ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>