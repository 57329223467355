import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TimechartService } from 'src/app/services/timechart.service';
import { UtilService } from 'src/app/services/util.service';
import { ApplicationMonitoringQueries } from '../../application-monitoring-queries';

@Component({
  selector: 'app-am-sap-hana-overview',
  templateUrl: './am-sap-hana-overview.component.html',
  styleUrls: ['./am-sap-hana-overview.component.scss']
})
export class AMSAPHANAOverviewComponent implements OnInit {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  searchKey:any;
  subscriptionApp: Subscription;
  timeFilterList: any = [];
  timeFilterFlag: boolean=false;
  datasort: string;
  selectedData: any;
  refreshedTime: any = {
    highestCPU: new Date(),
    highestMemory: new Date()
  };
  currentFilter:any= {
    type : 'last24hr',
    from:  new Date(new Date().setDate(new Date().getDate() - 1)),
    to: new Date(),
    timeGranularity:'automatic',
    timeZone:'utc'
  };
  hanaInstanceOverview:any = {
    type:'hanaInstanceOverview',
    tempData:[],
    tableData:[],
    showLoader : true,
    refreshTime : new Date(),
  };
  highestCPU:any = {
    type:'highestCPU',
    tempData:[],
    tableData:[],
    showLoader : true,
    refreshTime : new Date(),
  };
  highestMemory:any = {
    type:'highestMemory',
    tempData:[],
    tableData:[],
    showLoader : true,
    refreshTime : new Date(),
  };
  cpuUsage: any={
    type:'cpuUsage',
    showLoader : true,
    name:'CPU Usage',
    options:null,
    xAxis: [],
    data: [],
    tempData:[],
    refreshTime : new Date(),
    legendData: [],
    series: []
  };
  memoryUsage: any={
    type:'memoryUsage',
    showLoader : true,
    name:'Memory Usage',
    options:null,
    xAxis: [],
    data: [],
    tempData:[],
    refreshTime : new Date(),
    legendData: [],
    series: []
  };
  diskData: any={
    type:'diskData',
    showLoader : true,
    options:null,
    name:'Disk Data',
    xAxis: [],
    data: [],
    tempData:[],
    refreshTime : new Date(),
    legendData: [],
    series: []
  };
  diskLog: any={
    type:'diskLog',
    showLoader : true,
    name:'Disk Log',
    options:null,
    xAxis: [],
    data: [],
    tempData:[],
    refreshTime : new Date(),
    legendData: [],
    series: []
  };
  networkIn: any = {
    type:'networkIn',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'Network In',
    xAxis:[],
    options:{},
    yAxis:[],
    showLegend : true,
    refreshTime : new Date(),
    legendData:[],
    tempData:[],
    series:[],
    showLoader : true,
  };
  networkOut: any = {
    type:'networkOut',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'Network Out',
    xAxis:[],
    options:{},
    yAxis:[],
    showLegend : true,
    refreshTime : new Date(),
    legendData:[],
    tempData:[],
    series:[],
    showLoader : true,
  };
  singleProcess: any = {
    type:'singleProcess',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'',
    mainType:'',
    xAxis:[],
    options:{},
    yAxis:[],
    showLegend : true,
    refreshTime : new Date(),
    legendData:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    max:undefined,
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date(),
      timeGranularity:'automatic',
      timeZone:'utc'
    }
  }; 
  singleProcessView=false;
  processDetails:FormGroup;
  processList: any = [];
  providerList: any=[];
  timeFilterView=false;
  currentProjectData: any;

  constructor(private util: UtilService,private modalService: NgbModal,private timechartService:TimechartService,private fb:FormBuilder) { }

  ngOnInit(): void {
    this.processDetails= this.fb.group({
      process:[null],
      timeRange:['last24hr']
    });
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.subscriptionApp = this.util.getObservable().subscribe(async (res) => {
      if(res.currentFilteredProject){
        this.currentProjectData = res.currentFilteredProject;
        console.log('currentProjectData',this.currentProjectData)
        await this.getProviderList();
        this.triggerFilter(res.currentFilteredProject,'project')
        // this.util.setObservable('currentFilteredProject',null);   
        this.timeFilterView=true;
      }
    });
  }

  getProviderList() {
    this.providerList=[];
    return this.util.handleRequest('get','a3s_applicationHealthMonitor_getProviderList',[],null,null,null,true).then( async res=>{
      if(!res || !res.body) {
        return 
      }
      let data=res.body.value||[];
      data = data.filter(dt=>dt.properties.providerSettings.providerType=='SapHana');
      let ipData : any= data.filter(x=> x.properties.providerSettings.providerType=='SapHana').map(xe=> `'${xe.properties.providerSettings.hostname}'`)
      ipData = ipData.join(',')
      let body ={ ipAddr : ipData }
      let ipResponse = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getVMDetailsByGraphQuery',[],body,null,null,true)
      await Promise.all(data.map(async xe=>{
          xe['resourceName'] = null
          if(xe.properties.providerSettings.providerType=='SapNetWeaver'){
              xe['resourceName'] = xe.properties.providerSettings.sapHostname.split('.')[0]
          }
          else if(xe.properties.providerSettings.providerType=='SapHana'){
              let filterData = ipResponse.body.data.filter(x=> x.privateIP == xe.properties.providerSettings.hostname)
              xe['resourceName'] = filterData.length ? filterData[0].virtualMachine.split('/')[filterData[0].virtualMachine.split('/').length - 1] : null
          }
      }))
      data = data.filter(ex=> this.currentProjectData.resourceData.some(x=> x.resourceName.toLowerCase() == ex.resourceName.toLowerCase()))
      this.providerList=data.map(dt=>{
        dt.groupBy='All';
        dt.checked=true;
        return dt;
      });
    })
  }

  getChartData(type) {    
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    const filterSubs=tempSubList.filter(dt=>dt.checked);
    if(!filterSubs.length) {
      this[type].data=[];
      this[type].tempData=[];
      this[type].xAxis=[];
      this[type].series=[];
      this[type].legendData=[];
      this[type].yAxis=[];
      this[type].tableData=[];
      this[type].showLoader = false;
    } else {
      let filterDate:any =this.util.getCommonIsoDate(this.currentFilter);
      filterDate.timeGranularity=this.currentFilter.timeGranularity;
      let body ={
        "subscriptions":tempSubList.map(dt=>dt.subscriptionId),//["162349c9-37d0-45e1-b2e3-28c4216b7c6b"]
        "query": type=='highestCPU'||type=='highestMemory'?ApplicationMonitoringQueries.hanaInstanceOverview.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.currentFilter)):type=='networkIn'||type=='networkOut'?ApplicationMonitoringQueries[type].replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.currentFilter)).replace('STEP',this.util.getTimeGranularity(filterDate)):ApplicationMonitoringQueries[type].replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.currentFilter))
      }
      this[type].data=[];
      this[type].tempData=[];
      this[type].showLoader = true;
      return this.util.handleRequest('post','a3s_applicationHealthMonitor_runLogAnalyticsQuery',[],body,null,null,true).then(res=>{
        if(!res || !res.body) {
          this[type].showLoader = false;
          return 
        }
        res.body.tables.forEach(dt=>{
          dt.rows.forEach(ds=>{
            this[type].tempData.push(ds);
          });
        })
        this.prepareData(type);
        this[type].showLoader = false;
      },err=>{
        this[type].showLoader = false;
      })
    }
  }

  checkProviderVal(providerName) {
    return this.providerList.find(dt=>dt.checked&&dt.name==providerName);
  }

  prepareData(type) {
    if(type==='hanaInstanceOverview'){
      this[type].tableData=[];
      let parentData=this[type].tempData.filter((dt)=>this.checkProviderVal(dt[9])&&dt[8]=="");
      parentData.forEach(dt=>{
        let tempObj={
          name:dt[0],
          kind:dt[2],
          databaseHosted:dt[3],
          role:dt[4],
          status:dt[5],
          CPU:dt[10]?Number(dt[10].toFixed(2)):0,
          memory:dt[11]?Number(dt[11].toFixed(2)):0,
          diskData:dt[12]?Number(dt[12].toFixed(2)):0,
          diskLog:dt[13]?Number(dt[13].toFixed(2)):0,
          networkIn:dt[14]?Number(dt[14].toFixed(2)):0,
          networkOut:dt[15]?Number(dt[15].toFixed(2)):0,
          parentId:dt[8],
          id:dt[7],
          childData:this[type].tempData.filter((ds)=>this.checkProviderVal(ds[9])&&ds[8]==dt[7])
        }
        this[type].tableData.push(tempObj);
        this[type].tableData = this.util.unique(this[type].tableData,['name','kind','databaseHosted','role','status','CPU','memory','diskData','diskLog','networkIn','networkOut'])
      })
    } else if(type=='highestCPU'||type=='highestMemory') {
      this[type].tableData=[];
      let tableData=[];
      this[type].tempData.forEach((dt)=>{
        if(type=='highestCPU'&&dt[10]&&this.checkProviderVal(dt[9])) {
          tableData.push({
            name:dt[0],
            value:Number(dt[10].toFixed(2))
          })
        } else if(type=='highestMemory'&&dt[11]&&this.checkProviderVal(dt[9])) {
          tableData.push({
            name:dt[0],
            value:Number(dt[11].toFixed(2))
          })
        }
      });
      if(type=='highestCPU'||type=='highestMemory'){
        tableData.sort(function (a, b) {
          return a.value - b.value;
        });
        tableData = this.util.unique(tableData,['name'])
        this[type].tableData=tableData.slice(0,2);
      }
    } else if(type !='networkIn'&&type!='networkOut') {
      let colors=['#FA7380','#FFB87B','#06C493','#06C493'];
      this[type].yAxis=[];
      this[type].data=[];
      let indexVal=0;
      this[type].tempData = this.util.unique(this[type].tempData,['0'])
      this[type].tempData.forEach((dt)=>{
        if(this.checkProviderVal(dt[2])) {
          if(!colors[indexVal]) {
            indexVal=0;
          }
          this[type].yAxis.push(dt[0]);
          this[type].data.push({value:Number(dt[3].toFixed(2)),itemStyle: { color:colors[indexVal]}});
          indexVal=indexVal+1;
        }
      })
      this[type].options=this[type].data.length?this.getHorizontalBarOptions(type):null;
    } else {
      this[type].xAxis=[];
      this[type].series=[];
      this[type].legendData=[];
      var timeArray = [];
      this[type].tempData.forEach((dt)=>{
        if(this.checkProviderVal(dt[3])) {
          let findIndex=this[type].series.findIndex(d=>d.name==dt[0]);
          if(findIndex>-1){
            this[type].series[findIndex].data.push(Number(dt[4].toFixed(2)));
          } else {
            let obj = {
              name : dt[0],
              type : 'line',
              showSymbol :false,
              data: [Number(dt[4].toFixed(2))]
            }            
            this[type].series.push(obj);
          }
          let findTime=timeArray.find(ds=>ds==dt[1]);
          if(!findTime){
            timeArray.push(dt[1]);       
          }
          let findObj=this[type].legendData.find(d=>d.name==dt[0]);
          if(!findObj){
            this[type].legendData.push({icon:'circle',name:dt[0]});
          }
        }
      });
      let sorted : any = timeArray.sort((a,b)=> {
        var d1 = new Date(b);
        var d2 = new Date(a);
        return d2 > d1 ? 1 : 0;
      })
    
      sorted.forEach((x)=>{
          let findXAxis=this[type].xAxis.find(dt=>dt==moment(x).format('MM/DD/YYYY h:mm A'));
          if(!findXAxis){
          this[type].xAxis.push(moment(x).format('MM/DD/YYYY h:mm A'))
          }
      })
      this[type].options=this.setChartOption(type);
    }
  }

  filterSingleProcessName(type) {
    switch(type){
      case 'cpuUsageTime': return 'CPU usage (%)';
      case 'memoryUsageTime': return 'Memory usage (%)';
      case 'diskDataTime': return 'Disk data (%)';
      case 'diskLogTime': return 'Disk log (%)';
      case 'networkIn': return 'Network In (KB/s)';
      case 'networkOut': return 'Network Out (KB/s)';
    }
  }
  
  filterAvgName(type) {
    switch(type){
      case 'cpuUsageTime': return 'Avg CPU usage';
      case 'memoryUsageTime': return 'Avg memory Usage';
      case 'diskDataTime': return 'Avg disk data';
      case 'diskLogTime': return 'Avg disk log';
      case 'networkIn': return 'Avg Network In';
      case 'networkOut': return 'Avg Network Out';
    }
  }

  showSingleProcess(processName,type) {
    if(processName) {
      this.singleProcessView=true;
      this.processDetails.patchValue({
        timeRange:this.currentFilter.type
      })
      this.singleProcess.currentFilter=this.currentFilter;
      this.singleProcess.mainType=type;
      this.singleProcess.processName=processName;
      this.singleProcess.name=this.filterSingleProcessName(type);
      this.singleProcess.avgName=this.filterAvgName(type);
      this.getSingleProcessesData(processName,type,this.singleProcess.currentFilter);
    }
  }
  
  onChartClick(event,type) {
    this.singleProcessView=true;
    this.processDetails.patchValue({
      timeRange:this.currentFilter.type
    })
    this.singleProcess.currentFilter=this.currentFilter;
    this.singleProcess.mainType=type;
    this.singleProcess.processName=type=='networkOut'||type=='networkIn'?event.seriesName:event.name;
    this.singleProcess.avgName=this.filterAvgName(type);
    this.singleProcess.name=this.filterSingleProcessName(type);
    this.getSingleProcessesData(this.singleProcess.processName,type,this.singleProcess.currentFilter);
  }

  processChange() {
    this.getSingleProcessesData(this.processDetails.value.process,this.singleProcess.mainType,this.singleProcess.currentFilter);
  }

  setChartOption(name) {
    let previousIndex;
    const filterDate =this.util.getCommonIsoDate(this.currentFilter);
    let dateList=this.timechartService.xAxisDateTimeFormatList(filterDate,this.currentFilter.timeZone,this[name]);
    dateList.splice(0,1);
    let timeSpan=this[name].xAxis.length-dateList.length;
    if(timeSpan>2) {
      timeSpan=2
    }
    let options = {
      color: this[name].color,
      tooltip: this.util.getLineChartTooltip(),
      legend: {
        show: true,
        data: this[name].legendData,
        bottom: 0,
        left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap:true,
          axisTick: {
            alignWithLabel: true,
            interval: Number((timeSpan).toFixed()),
          },
          axisLine:{
            onZero:false
          },
          data: this[name].xAxis,
          axisLabel: {
            color: '#626F7B',
            interval: Number((timeSpan).toFixed()),
            padding: [5, 0, 0, 0],
            fontSize: 12, 
            showMinLabel:dateList[0].valueXaxis?true:false,
            fontFamily: 'Inter',
            formatter: function (value, index) { 
              if(index==0) {
                previousIndex=-1;
              }
              previousIndex=previousIndex+1;
              if(dateList[previousIndex]) {
                return dateList[previousIndex].valueXaxis
              }
            }
          },
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
          },
      },
      series: this[name].series
    };
    return options
  }

  getHorizontalBarOptions(type){
    let options = {
      color: ['#0B70D4'],
      textStyle: {
        fontFamily: 'Inter',
      },
      tooltip: this.util.getSingleBarChartTooltip(),//getHorizontalBarChartTooltip(),
      name:this[type].name,
      grid: {
        top: 10,
        bottom: 10,
        left: 20,
        right: 15,
        containLabel: true
      },
      // grid: {
      //   top: 10,
      //   bottom: 10,
      //   left: 70,
      //   right: 30,
      //   containLabel: true
      // },
      xAxis: {
          type: 'value',
          axisTick: {
            show: false
           },
          axisLabel:{
            padding: [5, 0, 0, 0],
            color:'#3E4852',
        }
      },
      yAxis: {
          type: 'category',
          data: this[type].yAxis  || [],
          axisLine: {
            lineStyle: {
                color: '#EEEFF0'
            }
          },
          axisLabel: {
            padding: [0, 0, 0, 0],
            fontSize: 12,
            align: "right",
            margin: 30, 
            color:'#3E4852',
            formatter: function (value) {
                return (value.length > 20 ? (value.slice(0,20)+"...") : value )
            }
          },
      },
      series: [{
        data: this[type].data || [],
        type: 'bar',
        barWidth: 12,
        name:this[type].name,
        colorBy:'series'
      }]
    };
    return options
  }

  getSingleProcessesData(processName,type,currentFilter){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    const filterSubs=tempSubList.filter(dt=>dt.checked);
    if(!filterSubs.length) {
      this.singleProcess.showLoader = false;
      this.singleProcess.legendData = []
      this.singleProcess.series = []
      this.singleProcess.xAxis = []
      this.singleProcess.tempData=[];
    } else {
      let filterDate:any =this.util.getCommonIsoDate(currentFilter);
      filterDate.timeGranularity=currentFilter.timeGranularity;
      let body ={
        "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
        "query": ApplicationMonitoringQueries[type].replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(currentFilter)).replace('STEP',this.util.getTimeGranularity(filterDate))
      }
      this.singleProcess.showLoader = true
      this.singleProcess.legendData = []
      this.singleProcess.series = []
      this.singleProcess.xAxis = []
      this.singleProcess.tempData=[];
      this.util.handleRequest('post','a3s_applicationHealthMonitor_runLogAnalyticsQuery',[],body,null,null,true).then(res=>{
        
        if(!res || !res.body) {
          this.singleProcess.showLoader = false
          return 
        }
        
        res.body.tables.forEach((dt)=>{
          dt.rows.forEach(ds=>{
            this.singleProcess.tempData.push(ds);
          });
        })
        this.filterSingleProcess(processName,type);
      },err=>{
        this.singleProcess.showLoader = false
      })
    }
  }

  filterSingleProcess(processName,type) {
    this.singleProcess.showLoader = true;
    this.singleProcess.xAxis=[];
    this.singleProcess.series=[];
    this.singleProcess.legendData=[];
    var timeArray = [];
    this.singleProcess.tempData.forEach((dt,index)=>{
      if(this.checkProviderVal(dt[3])) {
        if(!this.processList.find(dp=>dp.name===dt[0])){
          let obj = {name:dt[0]}
          this.processList = [...this.processList,...[obj]]
        }
        
        if(processName==dt[0]){
          this.processDetails.patchValue({
            process:(processName?processName:dt[0])
          })
          let findIndex=this.singleProcess.series.findIndex(d=>d.name==dt[0]);
          if(findIndex>-1){
            this.singleProcess.series[findIndex].data.push(Number(dt[4].toFixed(2)));
          } else {
            let obj = {
              name : dt[0],
              type : 'line',
              showSymbol :false,
              data: [Number(dt[4].toFixed(2))]
            }            
            this.singleProcess.series.push(obj);
          }
          let findTime=timeArray.find(ds=>ds==dt[1]);
          if(!findTime){
            timeArray.push(dt[1]);       
          }
          let findObj=this.singleProcess.legendData.find(d=>d.name==dt[0]);
          if(!findObj){
            this.singleProcess.legendData.push({icon:'circle',name:dt[0]});
          }
        } else if(!processName) {
          if(index==0) {
            processName=dt[0];
          }
          this.processDetails.patchValue({
            process:dt[0]
          })
          let findIndex=this.singleProcess.series.findIndex(d=>d.name==dt[0]);
          if(findIndex>-1){
            this.singleProcess.series[findIndex].data.push(Number(dt[4].toFixed(2)));
          } else {
            let obj = {
              name : dt[0],
              type : 'line',
              showSymbol :false,
              data: [Number(dt[4].toFixed(2))]
            }            
            this.singleProcess.series.push(obj);
          }
          let findTime=timeArray.find(ds=>ds==dt[1]);
          if(!findTime){
            timeArray.push(dt[1]);       
          }
          let findObj=this.singleProcess.legendData.find(d=>d.name==dt[0]);
          if(!findObj){
            this.singleProcess.legendData.push({icon:'circle',name:dt[0]});
          }
        }
      }
    });
    let sorted : any = timeArray.sort((a,b)=> {
      var d1 = new Date(b);
      var d2 = new Date(a);
      return d2 > d1 ? 1 : 0;
    })
  
    sorted.forEach((x)=>{
        let findXAxis=this.singleProcess.xAxis.find(dt=>dt==moment(x).format('MM/DD/YYYY h:mm A'));
        if(!findXAxis){
        this.singleProcess.xAxis.push(moment(x).format('MM/DD/YYYY h:mm A'))
        }
    })
    if(this.singleProcess.series.length){
      this.singleProcess.average=((this.singleProcess.series[0].data.reduce(
        (previousValue, currentValue) => previousValue + currentValue
      ))/this.singleProcess.series[0].data.length).toFixed(2);
    }
    this.singleProcess.options=this.setChartOption('singleProcess');
    this.singleProcess.options.legend.show=false;
    this.singleProcess.options.grid.bottom=8;
    this.singleProcess.showLoader = false;
  }

  onChangeCheckAll(event) {
    if(event&&event.target&&event.target.value) {
      this.providerList=this.providerList.map(dt=>{
        dt.checked=true;
        return dt
      })
    } else {
      this.providerList=this.providerList.map(dt=>{
        dt.checked=false;
        return dt
      })
    }
  }

  checkedAll(){
    return this.providerList && this.providerList.length ? this.providerList.every(e=> e.checked ? e.checked : false) : false
  }

  downloadDirectTableView() {
    let headers = [];
    let fileName = this.singleProcess.name;
    let dataList = [];
    if (this.singleProcess.series&&this.singleProcess.series.length) {
      headers = ['SAP Hana Database', 'Time Generated', this.singleProcess.name];
      this.singleProcess.tempData.forEach(data=>{
        let value=[];
        value.push(data[0]);
        value.push(moment(data[1]).format('MM/dd/yyyy, hh:mm:ss a'));
        value.push(data[4]);
        dataList.push(value);
      })
    }
    this.util.generateCSVForCustom(fileName, headers, dataList);
  }

  timeRangeFilter(singleProcess){
    return this.timeFilterList.find(dt=>dt.id==singleProcess.currentFilter.type)?.name;
  }

  openPop(popId){
    popId.show();
  }

  ngOnDestroy() {
    if(this.subscriptionApp) this.subscriptionApp.unsubscribe();
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.type);
    return findObj&&findObj.name?findObj.name:''
  }

  loadAllCardData() {
    this.getChartData('hanaInstanceOverview');
    this.getChartData('highestCPU');
    this.getChartData('highestMemory');
    this.getChartData('cpuUsage');
    this.getChartData('memoryUsage');
    this.getChartData('diskData');
    this.getChartData('diskLog');
    this.getChartData('networkIn');
    this.getChartData('networkOut');
  }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    if(list&&list.type=='singleProcess') {
      if(value && value.type) {
        this.singleProcess.currentFilter = value;
       } else {
         this.singleProcess.currentFilter.type = value;
       }
      this.getSingleProcessesData(null,this.singleProcess.mainType,this.singleProcess.currentFilter);
    } else {
      this.loadAllCardData();
    }
    this.closeFilter()
  }

  openModalForCustom(value,template: TemplateRef<any>,list?:any) {
    list.currentFilter = value;
    this.openModal(template,list);
  }
  
  applyCustomFilter(){
    if(this.selectedData&&this.selectedData.type=='singleProcess') {
      this.getSingleProcessesData(null,this.singleProcess.mainType,this.singleProcess.currentFilter);
    }else {
      this.loadAllCardData();
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 9;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = !this.singleProcessView?{currentFilter:this.currentFilter}:list
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    this.loadAllCardData();
    console.log('Filter Object',this.filterObject)
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    // this.getAllData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'provider': return this.providerList && this.providerList.length ? this.providerList.some(e=> e.checked) : false
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

  async refreshData(type){
    switch (type) {
      case 'highestCPU':{
        this.highestCPU.refreshTime = new Date();
        this.getChartData('highestCPU');
        break;
      }
      case 'highestMemory':{
        this.highestMemory.refreshTime = new Date();
        this.getChartData('highestMemory');
        break;
      }
      case 'hanaInstanceOverview':{
        this.hanaInstanceOverview.refreshTime = new Date();
        this.getChartData('hanaInstanceOverview');
        break;
      }
      case 'cpuUsage':{
        this.cpuUsage.refreshTime = new Date();
        this.getChartData('cpuUsage');
        break;
      }
      case 'memoryUsage':{
        this.memoryUsage.refreshTime = new Date();
        this.getChartData('memoryUsage');
        break;
      }
      case 'diskData':{
        this.diskData.refreshTime = new Date();
        this.getChartData('diskData');
        break;
      }
      case 'diskLog':{
        this.diskLog.refreshTime = new Date();
        this.getChartData('diskLog');
        break;
      }
      case 'networkIn':{
        this.networkIn.refreshTime = new Date();
        this.getChartData('networkIn');
        break;
      }
      case 'networkOut':{
        this.networkOut.refreshTime = new Date();
        this.getChartData('networkOut');
        break;
      }
      case 'singleProcess':{
        this.singleProcess.refreshTime = new Date();
        this.getSingleProcessesData(this.processDetails.value.process,this.singleProcess.mainType,this.singleProcess.currentFilter);
        break;
      }
    }    
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  getTimeFilterText(filter){
    return this.util.getTimeFilterText(filter)
  }

  getUTCText(filter){
    return this.util.getUTCText(filter)
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

  sortPopup(tableName,index,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName][index];
    sorted.sort((a, b) => {
        switch (value) {
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName][index] = sorted;
 }

}
