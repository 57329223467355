import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import { AllCountries } from '../../common/staticData/allCountries'
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @ViewChild("termsConditionPopup") termsConditionPopupTemplate: TemplateRef<any>;
  isRegistered: boolean = false;
  setCancelled : boolean = false

  // registerForm = this.fb.group({
  //   userName: [{ value:null, disabled: false }, [Validators.required,Validators.pattern(/^[a-zA-Z0-9_ ]*$/)]],
  //   company: [{ value:null, disabled: false }, [Validators.required]],
  //   contact: [{ value:null, disabled: false },[Validators.pattern(/\d+/)]],
  //   userId: [{ value:null, disabled: false }],
  //   tenentId: [{ value: null, disabled: false }, [Validators.required]],
  //   appId: [{ value:null, disabled: false }, [Validators.required]],
  //   appKey:[{ value:null, disabled: false }, [Validators.required]],
  //   adminName:[{ value:null, disabled: false }, [Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
  // });

  registerForm : FormGroup
  countryList: any = [];
  initialFlag: any = {
    "name": "United States",
    "dialCode": "+1",
    "isoCode": "US",
    "flag": "https://www.countryflags.io/US/flat/64.png"
  }
  

  constructor(
    private util:UtilService,
    private fb: FormBuilder,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.clearBrowserStorage()
    this.initializeForm()
    this.countryList = AllCountries.countryListAllIsoData
    this.updateCountryCode(this.initialFlag)
  }

  clearBrowserStorage(){
    localStorage.clear()
    sessionStorage.clear()
  }

  ngAfterViewInit(){
    this.openPopup(this.termsConditionPopupTemplate)
  }
  
  submit(){
    let rawValue = this.registerForm.getRawValue()
    this.util.handleRequest('post','a3s_admin_register',[],rawValue,null,null,true).then(res=>{
      this.isRegistered = true
      this.util.success('Registered successfully!')
    },err=>{
      console.log(err)
      this.util.error('Failed to register.')
    })
  }

  initializeForm(){
    // Validators.pattern(/^[a-zA-Z0-9_ ]*$/)
    this.registerForm = this.fb.group({
      userName : [null,[Validators.required,Validators.maxLength(200)]],
      company : [null,[Validators.required,Validators.maxLength(200)]],
      email : [null,[Validators.required,Validators.email]],
      contact : [null,[Validators.pattern(/\d+/),Validators.maxLength(30)]],
      country : [null],
      flag : [null],
    })
  }

  routeTo(){
    this.util.route(['/signIn'],false);
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  
  openPopup(template?: TemplateRef<any>) {
    this.modalService.open(template);
  }
  
  updateCountryCode(event){
    let code = `(${event.dialCode})  `
    // this.registerForm.get('contact').setValue(code)
    this.registerForm.get('flag').setValue(event.name)
  }

  updateFlag(event){
    this.registerForm.get('flag').setValue(event.name)
    this.updateCountryCode(event)
  }

}
