<div class="introduction">
  <p class="heading" id="7">Modern Finance</p>

  <p>This use case delivers better insights to deliver better business decisions on a timely basis. </p>
    <ol>
        <li>Click <b>Data analytics -> Create new project -> Modern Finance. </b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Modern-Finance.svg" >
        <figcaption class="caption"> Modern finance - use case </figcaption>
        <li>Fill source details to connect to the data source. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Modern-Finance(1).svg" >
        <figcaption class="caption"> Modern finance - source details  </figcaption>
        <li>Prepare the data by selecting <b>Table, Target column,</b> and <b>metrics.</b>  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Modern-Finance(2).svg" >
        <figcaption class="caption"> Modern finance – prepare data </figcaption>
        <li>Output page.  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Modern-Finance(3).svg" >
        <figcaption class="caption">Modern finance – output    </figcaption>
        <li>Project summary page. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Modern-Finance(4).svg" >
        <figcaption class="caption">Modern finance - project summary  </figcaption>
        <li>View in A3S BI dashboard. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Dashboard-mf.svg" >
        <figcaption class="caption">Modern finance - BI dashboard  </figcaption>
    
    </ol>
</div>
