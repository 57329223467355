import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';
import { IPv4, IPSubnetwork } from 'ip-matching';

@Component({
  selector: 'app-storage-account-actions',
  templateUrl: './storage-account-actions.component.html',
  styleUrls: ['./storage-account-actions.component.scss']
})
export class StorageAccountActionsComponent implements OnInit {
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("storageAccountDetailsTemplate") storageAccountDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    daysNameEmpty:false,
    daysInvalidNumber:false,
    hoursNameEmpty:false,
    hoursInvalidNumber:false,
    minsNameEmpty:false,
    minsInvalidNumber:false,
    secNameEmpty:false,
    secInvalidNumber:false,
    uriNameEmpty:false,
    uriInvalidURL:false,
    ipError:false,
    ipNonNullRange:false,
    ipUnique:false,
    vnetNameEmpty:false,
    vnetNameMaxLen:false,
    vnetNameExist:false,
    vnetNameValid:false,
    subnetNameEmpty:false,
    subnetNameMaxLen:false,
    subnetNameValid:false,
    subnetAdrspc:false,
    subnetNotValidCIDR:false,
    subnetOverlaping:false,
    subnetPerfix:false,
    subnetAddresNotInVnet:false,
    emptyBox:false,
    dnsUnique:false,
    vnetAdrspc:false,
    vnetNotValidCIDR:false,
    vnetOverlaping:false,
  }
  selectedType: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;
  selectedData: any;
  minTLSList=[
    {id:'TLS1_0',name:'Version 1.0'},
    {id:'TLS1_1',name:'Version 1.1'},
    {id:'TLS1_2',name:'Version 1.2'},
  ]
  
  replicationList=[
    {id:'LRS',name:'Locally-redundant storage (LRS)'},
    {id:'GRS',name:'Geo-redundant storage (GRS)'},
    {id:'RAGRS',name:'Read-access geo-redundant storage (RA-GRS)'},
  ]
  instanceList=[
    {id:'tenant',name:'All in current tenant'},
    {id:'subscription',name:'All in current subscription'},
    {id:'resourceGroup',name:'All in current resource group'},
  ]
  locationList=[]
  currentSubSheet: any;
  isEdit: boolean;

  currentTab: any;
  currentSheet: any;
  backendTargetList: any;
  backendSettingsList: any;
  listenerList: any;
  pathBasedRulesList: any[];
  redirectRulesList: any[];
  listenerListRules: any;
  prevSideSheet: any;
  storageAccountData: any;
  validMinTLSVersion: string;
  searchContent: any;
  saveEnable: boolean;
  subscriptionList: { name: any; id: any; }[];
  identitiesList: any;
  dataProtectionData: any;
  resourceGroupsList: any;
  vNetList: any;
  subnetList: any[];
  allSubnetList: any[];
  inputAddress: any;
  vNetOverlap: any;
  constructor(
    public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private _dataMigrationService: DataMigrationService,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private validatorService : ValidatorsService
  ) { }

  ngOnInit(): void {
  }
  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteStorageAccountData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteStorageAccountData')
      }
    }
  }
    // Trigger action of the Connection
  
  async openSidesheet(type,data?:any,subType?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "configuration" : {
        this.closeModal()
        this.currentForm = this.util.getForm('stoargeAccountConfig')
        await this.getStorageAccountData(this.selectedRow)
        await this.setDefaultValue('configuration')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "networking" : {
        this.closeModal()
        this.currentForm = this.util.getForm('stoargeAccountNetworking')
        await this.getStorageAccountData(this.selectedRow)
        await this.getSubscription()
        await this.getRgBySubscription()
        await this.getVnetListBySub(this.selectedRow)
        await this.setDefaultValue('networking')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "encryption" : {
        this.closeModal()
        this.currentForm = this.util.getForm('stoargeAccountEncryption')
        await this.getStorageAccountData(this.selectedRow)
        await this.getSubscription()
        await this.setDefaultValue('encryption')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "dataProtection" : {
        this.closeModal()
        this.currentForm = this.util.getForm('stoargeAccountDataProtection')
        await this.getStorageAccountData(this.selectedRow)
        await this.getDataProtectionData(this.selectedRow)
        await this.setDefaultValue('dataProtection')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
    }
  }
  async openSubSideSheet(type,subType?:any){
    this.currentSubSheet = type
    switch(this.currentSubSheet){
      case "selectKeyVault" : {
        this.subSideSheetForm =  this.formBuilder.group({
          sub : [this.subscriptionList[0].id],
          keyStoreType : ['keyVault'],
          keyVault:[null],
          managedHSM:[null],
          key : [null],
        })
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "selectIdentity" : {
        this.subSideSheetForm =  this.formBuilder.group({
          sub : [this.subscriptionList[0].id],
          userIdentities : [null],
        })
        await this.getIdentitieslist(this.selectedRow)
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "addVNet" : {
        this.subSideSheetForm =  this.formBuilder.group({
          sub : [this.subscriptionList[0].id,[Validators.required]],
          vNet : [null,[Validators.required]],
          subnet:[null,[Validators.required]],
        })
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
      case "createVNet" : {
        this.locationList=[{id:this.getRegionName(this.selectedRow[3])}]
        this.subSideSheetForm =  this.formBuilder.group({
          sub : [this.subscriptionList[0].id],
          vNetName : [null],
          addressSpace:[null],
          rg:[this.selectedRow[1]],
          loc : [this.selectedRow[3]],
          subnetName:['default'],
          addressRange:[null],
          dDosProtection : [null],
          knowResId:[null],
          dDosResId:[null],
          firewall : [null],
          firewallName : [null],
          firewallAddressSpace:[null],
          publicIP:[null],
          publicIPName : [null],
        })
        // await this.setDefaultValue('rules',subType)
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
    }
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteStorageAccountData' :return this.deleteStorageAccountData(this.selectedData)
    }
  }
  
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedData=row;
    this.selectedType=type;
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  get instanceArrayList() {
    return this.currentForm.get('instanceArray') as FormArray;
  }
  removeInstanceList(index){
    this.saveDisable=true
    this.instanceArrayList.controls.splice(index, 1);
  }
  addInstanceArray(){
    this.instanceArrayList.push(this.util.getForm('addInstanceArray'))
  }

  get addressRangeList() {
    return this.currentForm.get('ipArray') as FormArray;
  }
  removeAddressRangeList(index){
    this.saveDisable=true
    this.addressRangeList.controls.splice(index, 1);
  }
  addAddressRangeList(){
    this.addressRangeList.push(this.util.getForm('addAddressSpace'))
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteStorageAccountData':return 'Delete Storage Account'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteStorageAccountData':return `Are you sure you want to delete “${this.selectedData[0]}”?` 
      default: return `Are you sure you want to delete “${this.selectedData.name}”?` 
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteStorageAccountData':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      case 'connected': return 'st-success';
      case 'not connected': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  getSuccesText(type){
    switch(type){
      case'config':return `Storage account configuration updated successfully!`
      case'encryption':return `Encryption updated successfully!`
      case'dataProtection':return `Data Protection added successfully!`
      case'networking':return `“${this.selectedRow[0]}” networking updated successfully!`
      case'createNewVnet':return `Virtual network created successfully!`
      case'existVnet':return `Service endpoints enabled successfully!`
      default: return 'Changes saved successfully!'
    }
  }
  
  getFailureText(type){
    switch(type){
      case'config':return `Storage account configuration updating failed`
      case'encryption':return `Updating encryption failed`
      case'dataProtection':return `Adding Data protection failed`
      case'networking':return `Updating “${this.selectedRow[0]}” networking failed`
      case'createNewVnet':return `Creating virtual network failed`
      case'existVnet':return `Failed to enable service endpoints`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'config':return `Updating storage account configuration...`
      case'encryption':return `Updating encryption...`
      case'dataProtection':return `Saving Data Protection...`
      case'networking':return `Updating “${this.selectedRow[0]}” networking...`
      case'createNewVnet':return `Creating virtual network...`
      case'existVnet':return `Enabling service endpoints...`
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'storageAccountData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }
  
  invalidForm(type){
    switch(type){
      case'config':{
        if(this.currentForm.get('allowUpperLimit')?.value){
          return this.inputError.daysNameEmpty
          ||this.inputError.daysInvalidNumber || this.inputError.hoursNameEmpty|| this.inputError.hoursInvalidNumber|| this.inputError.minsNameEmpty
          ||this.inputError.minsInvalidNumber || this.inputError.secNameEmpty|| this.inputError.secInvalidNumber
        }
      }
      case'encryption':{
        if(this.currentForm.get('encryptionType')?.value=='Customer-managed' && this.currentForm.get('encryptionKey')?.value=='keyURI'){
          return this.inputError.uriNameEmpty ||this.inputError.uriInvalidURL
        }
      }
    }
  }

  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      daysNameEmpty:false,
    daysInvalidNumber:false,
    hoursNameEmpty:false,
    hoursInvalidNumber:false,
    minsNameEmpty:false,
    minsInvalidNumber:false,
    secNameEmpty:false,
    secInvalidNumber:false,
    uriNameEmpty:false,
    uriInvalidURL:false,
    vnetNameEmpty:false,
    vnetNameMaxLen:false,
    vnetNameExist:false,
    vnetNameValid:false,
    subnetNameEmpty:false,
    subnetNameMaxLen:false,
    subnetNameValid:false,
    ipError:false,
    ipNonNullRange:false,
    ipUnique:false,
    subnetAdrspc:false,
    subnetNotValidCIDR:false,
    subnetOverlaping:false,
    subnetPerfix:false,
    subnetAddresNotInVnet:false,
    emptyBox:false,
    dnsUnique:false,
    vnetAdrspc:false,
    vnetNotValidCIDR:false,
    vnetOverlaping:false,
    }
  }

  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'config':{
      this.saveDisable=true
      switch(subType){
        case 'performance':{
          let value = id 
          this.currentForm.get('performance').setValue(value)
          break;
        }
        case 'secureTransfer':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('secureTransfer').setValue(value)
          break;
        }
        case 'allowBlob':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('allowBlob').setValue(value)
          break;
        }
        case 'allowAccountKey':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('allowAccountKey').setValue(value)
          break;
        }
        case 'allowUpperLimit':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('allowUpperLimit').setValue(value)
          break;
        }
        case 'defaultAAD':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('defaultAAD').setValue(value)
          break;
        }
        case 'largeFileShares':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('largeFileShares').setValue(value)
          break;
        }
        case 'accessTier':{
          let value = id 
          this.currentForm.get('accessTier').setValue(value)
          break;
        }
       }
       break;
      }
    case 'encryption':{
    
      switch(subType){
        case 'encryptionType':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('encryptionType').setValue(value)
          break;
        }
        case 'encryptionKey':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('encryptionKey').setValue(value)
          this.currentSubSheet=='selectKeyVault' && this.showSubSidesheet ? this.showSubSidesheet=false :null
          break;
        }
        case 'identityType':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('identityType').setValue(value)
          this.currentSubSheet=='selectIdentity'  && this.showSubSidesheet ? this.showSubSidesheet=false :null
          break;
        }
        case 'keyStoreType':{
          let value = id 
          this.saveEnable=true
          this.subSideSheetForm.get('keyStoreType').setValue(value)
          break;
        }
        }
        break;
      }

    case 'dataProtection':{
  
      switch(subType){
        case 'changeFeedLogs':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('changeFeedLogs').setValue(value)
          break;
        }
        case 'encryptionKey':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('encryptionKey').setValue(value)
          this.currentSubSheet=='selectKeyVault' && this.showSubSidesheet ? this.showSubSidesheet=false :null
          break;
        }
        case 'identityType':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('identityType').setValue(value)
          this.currentSubSheet=='selectIdentity'  && this.showSubSidesheet ? this.showSubSidesheet=false :null
          break;
        }
        case 'keyStoreType':{
          let value = id 
          this.saveEnable=true
          this.subSideSheetForm.get('keyStoreType').setValue(value)
          break;
        }
        }
        break;
      }

    case 'networking':{

      switch(subType){
        case 'allowAccess':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('allowAccess').setValue(value)
          break;
        }
        case 'routingPreference':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('routingPreference').setValue(value)
          break;
        }
        case 'identityType':{
          this.saveDisable=true
          let value = id 
          this.currentForm.get('identityType').setValue(value)
          this.currentSubSheet=='selectIdentity'  && this.showSubSidesheet ? this.showSubSidesheet=false :null
          break;
        }
        case 'keyStoreType':{
          let value = id 
          this.saveEnable=true
          this.subSideSheetForm.get('keyStoreType').setValue(value)
          break;
        }
        case 'dDosProtection':{
          let value = id 
          this.saveEnable=true
          this.subSideSheetForm.get('dDosProtection').setValue(value)
          break;
        }
        case 'firewall':{
          let value = id=='yes'?true:false
          this.saveEnable=true
          this.subSideSheetForm.get('firewall').setValue(value)
          break;
        }
        case 'publicIP':{
          let value = id 
          this.saveEnable=true
          this.subSideSheetForm.get('publicIP').setValue(value)
          break;
        }
        }
        break;
      }
   }
  }
  
  async setDefaultValue(type,row?:any){
  switch(type){

    case 'configuration':{
      let body= this.storageAccountData
      this.validMinTLSVersion='same'
        this.currentForm.get('performance').setValue(body?.sku?.tier)
        this.currentForm.get('secureTransfer').setValue(body?.properties?.supportsHttpsTrafficOnly)
        this.currentForm.get('allowBlob').setValue(body?.properties?.allowBlobPublicAccess)
        this.currentForm.get('allowAccountKey').setValue(body?.properties?.allowSharedKeyAccess)
        this.currentForm.get('allowUpperLimit').setValue(body?.properties?.sasPolicy ? true :false)
        this.currentForm.get('defaultAAD').setValue(body?.properties?.defaultToOAuthAuthentication ? body?.properties?.defaultToOAuthAuthentication:false)
        this.currentForm.get('minimumTLSVersion').setValue(body?.properties?.minimumTlsVersion)
        this.currentForm.get('accessTier').setValue(body?.properties?.accessTier)
        this.currentForm.get('replication').setValue(body?.sku?.name.split('_')[1])
        this.currentForm.get('largeFileShares').setValue(body?.properties?.largeFileSharesState && body?.properties?.largeFileSharesState=='Enabled' ? true:false)
        if(body?.properties?.sasPolicy){
          let split=body?.properties?.sasPolicy?.sasExpirationPeriod.split(':')
          let val=split[0].split('.')
          this.currentForm.get('days').setValue(val[0])
          this.currentForm.get('hours').setValue(val[1])
          this.currentForm.get('mins').setValue(split[1])
          this.currentForm.get('seconds').setValue(split[2])
        }else{
          this.currentForm.get('days').setValue(0)
          this.currentForm.get('hours').setValue(0)
          this.currentForm.get('mins').setValue(0)
          this.currentForm.get('seconds').setValue(0)
        }
      break;
      }
    case 'encryption':{
      let body= this.storageAccountData
        this.currentForm.get('encryptionType').setValue('Microsoft-managed')
        this.currentForm.get('encryptionKey').setValue('keyVault')
        this.currentForm.get('identityType').setValue('systemAssigned')
        this.currentForm.get('sub').setValue(this.subscriptionList[0].id)
      break;
      }
      case 'dataProtection':{
        let body=this.dataProtectionData
        this.currentForm.get('restoreContainer').setValue(body?.properties?.restorePolicy?.enabled)
        this.currentForm.get('deleteBlobs').setValue(body?.properties?.deleteRetentionPolicy?.enabled)
        this.currentForm.get('deleteContainer').setValue(body?.properties?.containerDeleteRetentionPolicy?.enabled)
        this.currentForm.get('blobsVersion').setValue(body?.properties?.isVersioningEnabled)
        this.currentForm.get('blobChangeFeed').setValue(body?.properties?.changeFeed?.enabled)
        break;
        }
      case 'networking':{
        let body=this.storageAccountData
        this.currentForm.get('allowAccess').setValue(body?.properties?.networkAcls?.virtualNetworkRules.length ||body?.properties?.networkAcls?.ipRules.length ? 'selectedNetworks':'allNetworks')
        this.currentForm.get('routingPreference').setValue(body?.properties?.routingPreference?.routingChoice=='InternetRouting'?'Internet':'Microsoft')
        this.currentForm.get('networkRouting').setValue(body?.properties?.routingPreference?.publishMicrosoftEndpoints)
        this.currentForm.get('internetRouting').setValue(body?.properties?.routingPreference?.publishInternetEndpoints)
        this.currentForm.get('allowAS').setValue(body?.properties?.networkAcls?.bypass.includes('AzureServices'))
        this.currentForm.get('storageLogging').setValue(body?.properties?.networkAcls?.bypass.includes('Logging'))
        this.currentForm.get('storageMetrics').setValue(body?.properties?.networkAcls?.bypass.includes('Metrics'))
        break;
        }
  }
  }
  changeTab(name){
    this.currentTab=name
    this.resetValidation()
    this.saveDisable=false
  }
  validTLSVersion(event){
    this.saveDisable=true
    if(event.id<this.storageAccountData?.properties?.minimumTlsVersion) return this.validMinTLSVersion='low'
    else if(event.id==this.storageAccountData?.properties?.minimumTlsVersion)return this.validMinTLSVersion='same'
    else return this.validMinTLSVersion='high'
  }
  searchIdentity(event){
    let searchValue = event.target.value.toLowerCase()
    if(searchValue) $(".search-expands").addClass('open')
    else $(".search-expands").removeClass('open')
    let tempArray = _.cloneDeep(this.identitiesList)
    // let array = tempArray.filter(e=> {
    //   e.child = e.child.filter(x=> {
    //     x.subChild = x.subChild ? x.subChild.filter(y=>y.value.toLowerCase().includes(searchValue)? true:false) : []
    //     return x.value.toLowerCase().includes(searchValue) || x.subChild.length ? true : false
    //   })
    //   console.log(e.value +'----'+ e.value.toLowerCase().includes(searchValue))
    //   return e.value.toLowerCase().includes(searchValue) || e.child.length ? true : false
    // })
    console.log('array',tempArray)
    this.searchContent = tempArray
  }
  getSubnetBYVnet(event){
   this.subnetList=[]
   let selectedVnet=this.subSideSheetForm.get('vNet').value
   event.forEach((e,i)=>{
    e.properties?.subnets.forEach(x=>{
      this.subnetList.push(x)
    })
   })
   this.subnetList.map(e=>{
    e['rg']=e.id.split('/')[4]
  })
  }
  getSubnetAddressSpace(id){
    let addressSpace=''
    this.allSubnetList.forEach(e=>{
      if(e.id==id){
        addressSpace= e?.properties?.addressPrefix
      }
    })
    return addressSpace
  }
  getSubnetEndpoint(id){
    let endpoint=''
    this.allSubnetList.forEach(e=>{
      if(e.id==id && e.properties?.serviceEndpoints){
        e.properties?.serviceEndpoints.forEach(x=>{
          endpoint= x?.service=="Microsoft.Storage"?'Enabled':'Not Enabled'
        })
      }else endpoint='Not Enabled'
    })
    return endpoint
  }

  
  async saveStorageAccountData(type){
    switch(type){
      case'config':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.storageAccountData
        body['properties'].supportsHttpsTrafficOnly=rawValue.secureTransfer
        body['properties'].allowBlobPublicAccess=rawValue.allowBlob
        body['properties'].allowSharedKeyAccess=rawValue.allowAccountKey
        body['properties'].minimumTlsVersion=rawValue.minimumTLSVersion
        body['properties'].defaultToOAuthAuthentication=rawValue.defaultAAD
        let replication= body?.sku?.name.split('_')
        body.sku.name=replication[0]+'_'+rawValue.replication
        if(rawValue.allowUpperLimit){
          body['properties']['sasPolicy']={ 
            "sasExpirationPeriod": `${rawValue.days}.${rawValue.hours}:${rawValue.mins}:${rawValue.seconds}`, 
            "expirationAction": "Log" 
        } 
        }else{
          body['properties']['sasPolicy']={ 
            "expirationAction": "Log" 
        } 
        }
        if(this.selectedRow[5]=='StorageV2'){
          body['properties'].largeFileSharesState=rawValue.largeFileShares?'Enabled':'Disabled'
          body['properties'].accessTier=rawValue.accessTier
        }
        await this.updateStorageAccountData(this.selectedRow,body,'config')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'encryption':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.storageAccountData
        if(rawValue.encryptionType=='Microsoft-managed'){
          body.properties.encryption={ 
                        "identity": {}, 
                        "requireInfrastructureEncryption":body?.properties?.encryption?.requireInfrastructureEncryption, 
                        "services": body?.properties?.encryption?.services, 
                        "keySource": "Microsoft.Storage" 
                    }
            delete body['identity']
        }else{
          body.properties.encryption['keySource']= "Microsoft.Keyvault"
          if(rawValue.identityType=='systemAssigned'){
            body.properties.encryption['identity']= { 
              "userAssignedIdentity": null 
            } 
            body['identity']= { 
              "type": "SystemAssigned"
            } 
          }else{
            let formValue=this.subSideSheetForm.getRawValue()
            let id=`/subscriptions/${formValue.sub}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.ManagedIdentity/userAssignedIdentities/${formValue.userIdentities}`
            body.properties.encryption['identity']= { 
              "userAssignedIdentity": id 
            } 
            body['identity']= { 
              "type": "UserAssigned", 
              // "userAssignedIdentities": { 
              //     "/subscriptions/a63effee-d4d3-4057-8859-9d225276bc69/resourceGroups/RG-storage/providers/Microsoft.ManagedIdentity/userAssignedIdentities/identity1": {} 
              // } 
            } 
          }
          if(rawValue.encryptionKey=='keyVault'){
            let formValue=this.subSideSheetForm.getRawValue()
            body.properties.encryption['keyvaultproperties']={ 
              "keyname": "key1", 
              "keyvaulturi": rawValue.keyURI, 
              "keyversion": "" 
          }
          }else{
            body.properties.encryption['keyvaultproperties']={ 
              // "keyname": "key1", 
              "keyvaulturi": rawValue.keyURI, 
              "keyversion": rawValue.sub
          }
          }
        }
        await this.updateStorageAccountData(this.selectedRow,body,'encryption')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'dataProtection':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.dataProtectionData
        if(rawValue.restoreContainer){
          body['properties'].restorePolicy={
            "enabled": rawValue.restoreContainer,
            "days":7
          }
        }else{
          body['properties'].restorePolicy={
            "enabled": rawValue.restoreContainer
          }
        }
        if(rawValue.deleteBlobs){
          body['properties'].deleteRetentionPolicy={
            "enabled": rawValue.deleteBlobs,
            "days":7
          }
        }else{
          body['properties'].deleteRetentionPolicy={
            "enabled": rawValue.deleteBlobs
          }
        }
        if(rawValue.deleteContainer){
          body['properties'].containerDeleteRetentionPolicy={
            "enabled": rawValue.deleteContainer,
            "days":7
          }
        }else{
          body['properties'].containerDeleteRetentionPolicy={
            "enabled": rawValue.deleteContainer
          }
        }
        body['properties'].isVersioningEnabled=rawValue.blobsVersion
        if(rawValue.blobChangeFeed && rawValue.changeFeedLogs=='delete'){
          body['properties'].changeFeed={
            "enabled": rawValue.blobChangeFeed,
            "retentionInDays":7
          }
        }else{
          body['properties'].changeFeed={
            "enabled": rawValue.blobChangeFeed
          }
        }
        await this.updateDataProtectionData(this.selectedRow,body,'dataProtection')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'networking':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.storageAccountData
        let bypass=[]
        rawValue.allowAS ? bypass.push('AzureServices'):false
        rawValue.storageLogging ? bypass.push('Logging'):false
        rawValue.storageMetrics ? bypass.push('Metrics'):false
        !bypass.length?bypass.push('None'):false
        let ipArray=[]
        rawValue.ipArray.forEach(e=>{
          if(e.value!=''){
            ipArray.push({value:e?.value})
          }
        })
        let instanceArray=[]
        rawValue.instanceArray.forEach(e=>{
          if(e.resourceType && e.instanceName){
            instanceArray.push( { 
              "tenantId": "9be10acf-2a10-42a0-9748-00a93cd07042", 
              "resourceId": `/subscriptions/${this.selectedRow[2]}/resourcegroups/${this.selectedRow[1]}/providers/Microsoft.Search/searchServices/${e.instanceName}`
          } )
          }
        })
        let vnetArray=body.properties.networkAcls.virtualNetworkRules || []
        // body.properties.networkAcls.virtualNetworkRules.forEach(e=>{
        //   if(e.value!=''){
        //     vnetArray.push({id:e?.id})
        //   }
        // })
        if(rawValue.allowAccess=='selectedNetworks'){
          body.properties.networkAcls={ 
                    "bypass": bypass.join(', '), 
            "virtualNetworkRules": vnetArray, 
            "ipRules": ipArray, 
            "defaultAction": "Deny", 
            "resourceAccessRules": instanceArray 
             }          
            body.properties.routingPreference={ 
                           "routingChoice": rawValue.routingPreference=='Microsoft'?"MicrosoftRouting":"InternetRouting", 
                            "publishMicrosoftEndpoints": rawValue.networkRouting, 
                            "publishInternetEndpoints": rawValue.internetRouting 
                      }
        }else{
            body.properties.routingPreference={ 
                           "routingChoice": rawValue.routingPreference=='Microsoft'?"MicrosoftRouting":"InternetRouting", 
                            "publishMicrosoftEndpoints": rawValue.networkRouting, 
                            "publishInternetEndpoints": rawValue.internetRouting 
                      }
        }
        await this.updateStorageAccountData(this.selectedRow,body,'networking')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
      case'createNewVnet':{
        let subForm = this.subSideSheetForm.getRawValue()
        let body={
          "properties": {
            "addressSpace": {
              "addressPrefixes": [
                subForm.addressSpace
              ]
            },
            "subnets": [
              {
                "name": subForm.subnetName,
                "properties": {
                  "addressPrefix": subForm.addressRange,
                  "serviceEndpoints": [ 
                    { 
                        "provisioningState": "Succeeded", 
                        "service": "Microsoft.Storage", 
                        "locations": [ this.selectedRow[3]]    		//location should be equal to subnet location 
                    } 
                ], 
                }
              }
            ]
          },
          "location": this.selectedRow[3]
        }
        await this.UpdateVNETData(this.selectedRow,subForm,body,'createNewVnet')
        this.showSubSidesheet=false
        this.saveEnable=false 
        break;
      }
      case'existVnet':{
        let subForm = this.subSideSheetForm.getRawValue()
        // let body=this.storageAccountData?.properties.networkAcls['virtualNetworkRules'] || []
        subForm.subnet.forEach(e=>{
          this.storageAccountData?.properties.networkAcls['virtualNetworkRules'].push({id:e})
        })
        // await this.UpdateVNETData(this.selectedRow,body,'createNewVnet')
        this.showSubSidesheet=false
        this.saveEnable=false 
        break;
      }
    }
  }
  
  //validation
  validIPName(event,type,subType?:any){
    let value =event.target.value;
    this.saveDisable=true
    this.inputError[type+'NameExist'] = false;
    this.inputError[type+'NameEmpty'] = false
    this.inputError[type+'NameMaxLen'] = false;
    this.inputError[type+'NameValid']=false;
   if(value==''){
    this.inputError[type+'NameEmpty'] = true;
   }else{
    if (value.length > 64 && value.length < 2) {
      this.inputError[type+'NameMaxLen'] = true;
      return;
    }
   }
    if(value.length==1 && !(/^[A-Za-z0-9]*$/.test(value)) || !(/^[A-Za-z0-9_]+$/.test(value[value.length-1]))){
        this.inputError[type+'NameValid']=true;
    }
    switch(subType){
      case 'vnet':{
        this.vNetList.forEach(e=>{
          if(e.name==value){
            this.inputError[type+'NameExist'] = true;
            return;
          }
        })
      }
      case 'subnet':{
        this.inputError[type+'NameMaxLen'] = false;
        if (value.length > 80) {
          this.inputError[type+'NameMaxLen'] = true;
          return;
        }
      }
    }
  }

  validHostName(event,type){
    let value=event.target.value
    this.saveDisable=true
    this.inputError[type+'NameEmpty']=false
    this.inputError[type+'InvalidName']=false
    if(value==''){
      this.inputError[type+'NameEmpty'] = true;
     }
     if(!(/^[A-Za-z0-9.]*$/.test(value)) || value[value.length-1]=='.' ||value[0]=='.'){
      this.inputError[type+'InvalidName']=true;
  }
  }
  validKeyURI(event,type){
    let value=event.target.value
    this.saveDisable=true
    this.inputError[type+'NameEmpty']=false
    this.inputError[type+'InvalidURL']=false
    if(value=='') this.inputError[type+'NameEmpty'] = true;
    else{
      let split=value.split('//')
      if(split.length>1 && split[0]=='https:' && split[1]!=''){
        this.inputError[type+'InvalidURL']=false
      }else  this.inputError[type+'InvalidURL']=true
    } 
  }
  CheckIPAddress(event,flag,index?:any){ 
    // this.warningMessage=true
      let value=event.target.value;
      this.inputError[flag+'Error']=false;//sourceInvalidArgument
      this.inputError[flag+'Unique']=false
  
      if(value&&value!=""){
      const splitArComma=value.split(',');
      let ipV4Value;
      let tempArr=[];
      for(let i=0;i<splitArComma.length;i++){
        if(!splitArComma[i]){
         // let inputError="Invalid argument: 'A non-null address range is required.'";
          this.inputError[flag+'Error']=true;
          break;
        }else{
          const splitArr=splitArComma[i].split('/');
          if(splitArr.length>1&&!splitArr[1]){
            //Maliform
            this.inputError[flag+'Error']=true;
          }else{
            ipV4Value=splitArr[0];
          }
          
          try{
            let ipV4Obj=new this.ipV4(ipV4Value);
           // item.VNetAdrspc=false;
            let vlIPRange;
            if(splitArr.length>1){
            try{
              vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
              //item.VNetAdrspc=false;
              if(vlIPRange.input!=splitArComma[i]){
                //item.notValidCIDR=true;
                //item.validCIDRValue=vlIPRange.input;
                this.inputError[flag+'Error']=true;
                break;
              }
            }catch(e){
              //item.VNetAdrspc=true;
              this.inputError[flag+'Error']=true;
              break;
            }
          }        
          }catch(e){
         //   item.VNetAdrspc=true;
         this.inputError[flag+'Error']=true;
            break;
          }
        }
      }
      if(!value.includes('/') && flag=='adrcSpc'){
        this.inputError[flag+'Error']=true;
      }
    }
    
    if(value&&value!=""){
    const splitArComma=value.split(',');
    let ipV4Value;
    let tempArr=[];
    for(let i=0;i<splitArComma.length;i++){
      if(!splitArComma[i]){
       // let inputError="Invalid argument: 'A non-null address range is required.'";
        this.inputError[flag+'NonNullRange']=true;
        break;
      }
    }
  }
  // if(index!=''){
  //   if(!this.addressRangeList.controls.length) return;
  //   let data = this.addressRangeList.controls.some((e,i)=> {
  //     if(index !== i && e.get('value').value == value && e.get('value').value != '' ){
  //       this.inputError[flag+'Unique']=true
  //     }
  //     // else{ this.inputError['dnsUnique']=false}
  //     else if(index !== i &&e.get('value').value != ''){
  //       this.inputError[flag+'Unique']=false
  //     }
  //     return index !== i && e.get('value').value == this.addressRangeList.at(index).get('value').value
  //   })
  // }
  // else{
  //   this.getIPAddressValidation(value)
  // }
}
addressSpaceoverlap(event,type){
  let value =event.target.value
  if(type=='addressSpace' ){
    this.vNetList.forEach(x=>{
      x.properties.addressSpace.addressPrefixes.forEach((e,i)=>{
        let inputSplit=e.split('.')
        let split=value.split('.')
        if(!this.inputError.vnetOverlaping && value.length==11 ){
          this.inputError.vnetOverlaping= e==value ||(split[1] == inputSplit[1] && split[0] == inputSplit[0]) ? true:false
          if(this.inputError.vnetOverlaping){
            this.inputAddress=value
            this.vNetOverlap=e
          }
        }
      })
    })
    if(value==''){
      this.inputError.emptyBox=true
      this.inputError.vnetAdrspc=false
      this.inputError.dnsUnique=false
      this.inputError.vnetNotValidCIDR=false
      this.inputError.vnetOverlaping=false
    }else   this.inputError.emptyBox=false
  }
}
isAddressSpace(string){
  return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
}
subnetValidation(event){
  let value =event.target.value
  let val=value.split('.')
  let vnetRange=this.subSideSheetForm.get('vNetName').value
  let vnetRangeSplit=vnetRange.split('.')
  if(val[0]==vnetRangeSplit[0]&&val[1]==vnetRangeSplit[1]){
    this.inputError.subnetAddresNotInVnet=false
  }else{
    this.inputError.subnetAddresNotInVnet=true
  }
}
subnetOverlap(limitField,type?:any){
  let value =limitField.target.value
  this.inputError[type+'Overlaping']=false
  let vnetRange=this.subSideSheetForm.get('vNetName').value
      let split=vnetRange.split('.')
      let val=value.split('.')
      if(split[1]==val[1]&&split[2]==val[2]){
        this.inputError[type+'Overlaping']=true
      this.vNetOverlap=vnetRange}
}
    subnetAddressSpace(limitField,type?:any){
      let value =limitField.target.value
      if(!this.isAddressSpace(value)){
        console.log(value,this.isAddressSpace(value));
        this.inputError[type+'Adrspc']=true
        return;
      }
      this.inputError[type+'Adrspc']=false
      let splitArr=value.split('/');
      try{
        let vlRangeObj=new IPSubnetwork(new IPv4(splitArr[0]),splitArr[1]);
        this.inputError[type+'NotValidCIDR']=false
        if(vlRangeObj.bits>29){
          this.inputError[type+'Perfix']=true
          return;
        }else{
          this.inputError[type+'Perfix']=false
        }
        if(vlRangeObj.input!=value){
          this.inputError[type+'NotValidCIDR']=true
        }else{
          this.inputError[type+'NotValidCIDR']=false
        }
        // let resourceData=JSON.parse(this.parentResourceDtls.resourceData);
        let vnetRange=this.subSideSheetForm.get('vNetName').value

             let splitAr=vnetRange.split('/');
            let rangeObj=new IPSubnetwork(new IPv4(splitAr[0]),splitAr[1]);
              if(this.validateOverloopAddress(vlRangeObj,rangeObj)){
                this.inputError[type+'AddresNotInVnet']=false
                return true;
              }
          // }
              // let resourceDataSub=JSON.parse(dt.resourceData);
                const splitArrSub=value.split('/');
                let ipRangObj=new IPSubnetwork(new IPv4(splitArrSub[0]),splitArrSub[1]);
              if(this.validateOverloopAddress(vlRangeObj,ipRangObj)){
                this.inputError[type+'Overlaping']=true
                this.vNetOverlap=value;
                return true;
              }else{
                this.inputError[type+'Overlaping']=false
                this.vNetOverlap='';
    }
      }catch(e){
        this.inputError[type+'NotValidCIDR']=true
        return;
      }
  }

    validateOverloopAddress(vlIPRange,ipRangObj){
      if(vlIPRange.range.left.input==ipRangObj.range.left.input){
        return true;
      }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
       return true;
      }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]){
        if(vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]){
          if(vlIPRange.range.left.parts[2]>ipRangObj.range.left.parts[2]){
            if(vlIPRange.range.right.parts[2]<ipRangObj.range.right.parts[2]){
              return true;
            }
          }else if(vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
            if(vlIPRange.range.left.parts[3]>ipRangObj.range.left.parts[3]){
              if(vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
                return true;
              }
            }
          }
        }
      }else if(vlIPRange.range.left.parts[0]<ipRangObj.range.left.parts[0]){
        if(vlIPRange.range.right.parts[0]>ipRangObj.range.right.parts[0]){
          return true;
        }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]){
          if(vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
            return true;
          }
        }
      }else {
        return false;
      }
    }
  validInstance(event,type){
    let value=event.target.value
    this.saveDisable=true
    this.inputError[type+'NameEmpty']=false
    this.inputError[type+'InvalidNumber']=false
    this.inputError[type+'InvalidRange']=false
    this.inputError['InvalidMaxRange']=false
    this.inputError[type+'Invalid']=false
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError[type+'InvalidNumber']=true;
      }
      switch(type){
        case 'instance':{
          if(value>125 || value<1){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'minInstance':{
          if(value>125 || value<0){
            this.inputError[type+'InvalidRange']=true;
          }
          if(Number(value)>Number(this.currentForm.get('maxInstance').value)) return  this.inputError['InvalidMaxRange']=true;
          break;
        }
        case 'maxInstance':{
          if(value>125 || value<0){
            this.inputError[type+'InvalidRange']=true;
          }
          if(Number(value)<Number(this.currentForm.get('minInstance').value)) return  this.inputError['InvalidMaxRange']=true;
          break;
        }
        case 'maxReqSize':{
          if(value>128 || value<8){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
        case 'fileLimit':{
          if(value>100 || value<0){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
   
        case 'drainTimeOut':{
          if(value>3600 || value<1){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
      }
    }else this.inputError[type+'NameEmpty']=true
  }

  //API's
  getSubscription(){
    return this.subscriptionList=[{
      name:this.getProjectName(this.selectedRow[2]),
      id:this.selectedRow[2],
    }]
  }
  getRgBySubscription(){
    let body={
        subscriptionId : this.selectedRow[2],
    }
    return this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
        console.log('Resource Group list ',res);
        this.resourceGroupsList = res && res.value ? res.value : []
    })
  }

  getStorageAccountData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let accountName = data[0]
    return this.util.handleRequest('get','a3s_management_getStorageAccountData',[subsId,rgName,accountName],null,null,null,true).then(async res=>{
      this.storageAccountData= res.body
      console.log('Storage Account Data',this.storageAccountData)
    })
  }
  getIdentitieslist(data,type?:any,subData?:any){
    let subsId = data[2]
    return this.util.handleRequest('get','a3s_management_getIdentitieslist',[subsId],null,null,null,true).then(async res=>{
      this.identitiesList= res.body.value
      console.log('identities List',this.identitiesList)
    })
  }
  getVnetListBySub(data,type?:any,subData?:any){
    let subsId = data[2]
    return this.util.handleRequest('get','a3s_management_getVnetListBySub',[subsId],null,null,null,true).then(async res=>{
      this.vNetList= res.body.value
      this.vNetList.map(e=>{
        e['rg']=e.id.split('/')[4]
      })
      this.allSubnetList=[]
      this.vNetList.forEach(e=>{
        e.properties?.subnets.forEach(x=>{
          this.allSubnetList.push(x)
        })
      })
      console.log('VNET List ',this.vNetList)
    })
  }
  UpdateVNETData(data,form,body,type){
    let vnetName=form.vNetName
    type?this.util.info(this.getInProgress(type)):false
    this.util.handleRequest('post','a3s_management_updateVNETData',[data[2],data[1],vnetName],body,null,null,true).then(res=>{
      console.log('VNET Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      type?this.util.success(this.getSuccesText(type)):false
      this.storageAccountData?.properties.networkAcls?.virtualNetworkRules.push({id:res.body.properties.subnets[0].id})
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  getDataProtectionData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let accountName = data[0]
    return this.util.handleRequest('get','a3s_management_getDataProtectionData',[subsId,rgName,accountName],null,null,null,true).then(async res=>{
      this.dataProtectionData= res.body
      console.log('Storage Data Protection Data',this.dataProtectionData)
    })
  }

  updateStorageAccountData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let accountName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateStorageAccountData',[subsId,rgName,accountName],body,null,null,true).then(res=>{
      console.log('Storage Account Data updated',res)
      this.showSubSidesheet = false
      this.closeModal()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  updateDataProtectionData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let accountName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateDataProtectionData',[subsId,rgName,accountName],body,null,null,true).then(res=>{
      console.log('Storage Data Protection Data updated',res)
      this.showSubSidesheet = false
      this.closeModal()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  deleteStorageAccountData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],accountName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteStorageAccountData',[subscriptionId,resourceGroupName,accountName],null,null,null,true).then(async res=>{
      this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
  }

}
