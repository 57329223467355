import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TimechartService } from 'src/app/services/timechart.service';
import { UtilService } from 'src/app/services/util.service';
import { ApplicationMonitoringQueries } from '../../application-monitoring-queries';

@Component({
  selector: 'app-am-sap-hana-backup',
  templateUrl: './am-sap-hana-backup.component.html',
  styleUrls: ['./am-sap-hana-backup.component.scss']
})
export class AmSapHanaBackupComponent implements OnInit {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  searchKey:any;
  subscriptionApp: Subscription;
  timeFilterList: any = [];
  timeFilterFlag: boolean=false;
  datasort: string;
  selectedData: any;
  providerList: any=[];
  sidList: any=[];
  refreshedTime: any = {
    highestCPU: new Date(),
    highestMemory: new Date()
  };
  currentFilter:any= {
    type : 'last24hr',
    from:  new Date(new Date().setDate(new Date().getDate() - 1)),
    to: new Date(),
    timeGranularity:'automatic',
    timeZone:'utc'
  };
  backupSummary:any = {
    type:'backupSummary',
    tempData:[],
    tableData:[],
    showLoader : false,
    refreshTime : new Date(),
  };
  backupCatalog:any = {
    type:'backupCatalog',
    tempData:[],
    tableData:[],
    tempTableData:[],
    showLoader : false,
    refreshTime : new Date(),
  };
  backupSize:any = {
    type:'backupSize',
    tempData:[],
    tableData:[],
    xAxis:[],
    series:[],
    legendData:[],
    showLoader : false,
    refreshTime : new Date(),
  };
  backupDuration:any = {
    type:'backupDuration',
    tempData:[],
    tableData:[],
    xAxis:[],
    series:[],
    legendData:[],
    showLoader : false,
    refreshTime : new Date(),
  };
  networkThroughput:any = {
    type:'networkThroughput',
    color:['#0B70D4','#7D3AC1'],
    networkIn:[],
    networkOut:[],
    xAxis:[],
    series:[],
    legendData:[],
    showLoader : true,
    refreshTime : new Date(),
    options:{}
  };
  showOtherTiles: boolean=false;
  showDetails: boolean=false;
  backupDetailForm: FormGroup;
  filterForm:FormGroup;
  backupStateList:any=[{name:'All'},{name:'Success'},{name:'Fail'}];
  currentProjectData: any;

  constructor(private util: UtilService,private modalService: NgbModal,private timechartService:TimechartService,private fb:FormBuilder) { }

  ngOnInit(): void {
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.filterForm=this.fb.group({
      provider:[''],
      sid:[''],
      backupState:['All'],
      currentFilter:['last24hr']
    });
    this.backupDetailForm=this.fb.group({
      backupStatus:[''],
      backupItem:[''],
      database:[''],
      startTime:[''],
      endTime:[''],
      duration:[''],
      backupSize:[''],
      backupRate:[''],
      message:[''],
      backupId:[''],
      numberOfFile:[''],
    })
    this.subscriptionApp = this.util.getObservable().subscribe(async (res) => {
      if(res.currentFilteredProject){
        this.currentProjectData = res.currentFilteredProject;
        await this.getProviderList();
        this.triggerFilter(res.currentFilteredProject,'project')
        // this.util.setObservable('currentFilteredProject',null);   
      }
    });
  }

  getProviderList() {
    this.providerList=[];
    return this.util.handleRequest('get','a3s_applicationHealthMonitor_getProviderList',[],null,null,null,true).then(async res=>{
      if(!res || !res.body) {
        return 
      }
      let data=res.body.value||[];
      data=data.filter(dt=>dt.properties.providerSettings.providerType=='SapHana');
      let ipData : any= data.filter(x=> x.properties.providerSettings.providerType=='SapHana').map(xe=> `'${xe.properties.providerSettings.hostname}'`)
      ipData = ipData.join(',')
      let body ={ ipAddr : ipData }
      let ipResponse = await this.util.handleRequest('post','a3s_applicationHealthMonitor_getVMDetailsByGraphQuery',[],body,null,null,true)
      await Promise.all(data.map(async xe=>{
          xe['resourceName'] = null
          if(xe.properties.providerSettings.providerType=='SapNetWeaver'){
              xe['resourceName'] = xe.properties.providerSettings.sapHostname.split('.')[0]
          }
          else if(xe.properties.providerSettings.providerType=='SapHana'){
              let filterData = ipResponse.body.data.filter(x=> x.privateIP == xe.properties.providerSettings.hostname)
              xe['resourceName'] = filterData.length ? filterData[0].virtualMachine.split('/')[filterData[0].virtualMachine.split('/').length - 1] : null
          }
      }))
      data = data.filter(ex=> this.currentProjectData.resourceData.some(x=> x.resourceName.toLowerCase() == ex.resourceName.toLowerCase()))
      this.providerList=data.map(dt=>{
        dt.groupBy='All';
        dt.checked=true;
        return dt;
      });
      this.filterForm.patchValue({
        provider:this.providerList[0].name
      });
    })
  }

  getBackupData(type) {    
    this.showOtherTiles=false;
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    const filterSubs=tempSubList.filter(dt=>dt.checked);
    if(!filterSubs.length) {
      this[type].showLoader = false;
      this[type].data=[];
      this[type].tempData=[];
      this[type].tableData=[];
    } else {
      this.currentFilter.type=this.filterForm.value.currentFilter;
      const filterDate =this.util.getCommonIsoDate(this.currentFilter);
      let body ={
        "subscriptions":tempSubList.map(dt=>dt.subscriptionId),//["162349c9-37d0-45e1-b2e3-28c4216b7c6b"]
        "query": ApplicationMonitoringQueries[type].replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.currentFilter))
      }
      this[type].data=[];
      this[type].tempData=[];
      this[type].tableData=[];
      this[type].showLoader = true;
      return this.util.handleRequest('post','a3s_applicationHealthMonitor_runLogAnalyticsQuery',[],body,null,null,true).then(res=>{
        if(!res || !res.body) {
          this[type].showLoader = false;
          return 
        }
        res.body.tables.forEach(dt=>{
          dt.rows.forEach(ds=>{
            this[type].tempData.push(ds);
          });
        })
        this.prepareData(type);
        this[type].showLoader = false;
      },err=>{
        this[type].showLoader = false;
      })
    }
  }

  getInterval(filterDate) {
    const diffMinutes=moment.duration(moment(filterDate.to).diff(filterDate.from)).as('minutes');
    let step;
    if(diffMinutes<5||diffMinutes==5) {
      step='PT1M';
    } else if(diffMinutes<30||diffMinutes==30) {
      step='PT5M';
    } else if(diffMinutes<60||diffMinutes==60) {
      step='PT15M';
    } else if(diffMinutes<240||diffMinutes==240) {
      step='PT1H';
    } else if(diffMinutes<720||diffMinutes==720) {
      step='PT3H';
    } else if(diffMinutes<1440||diffMinutes==1440) {
      step='PT6H';
    } else if(diffMinutes<2880||diffMinutes==2880) {
      step='PT12H';
    } 
    if(!step) {
      const diffDays=moment.duration(moment(filterDate.to).diff(filterDate.from)).as('days');
      if(diffDays<7||diffDays==7) {
        step='P1D';
      } else if(diffDays>7) {
        step='P1D';
      }
    }
    return step
  }

  getNetworkData() {    
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    const filterSubs=tempSubList.filter(dt=>dt.checked);
    if(!filterSubs.length) {
      this.networkThroughput.xAxis=[];
      this.networkThroughput.series=[];
      this.networkThroughput.legendData=[];
      this.networkThroughput.networkIn=[];
      this.networkThroughput.networkOut=[];
      this.networkThroughput.showLoader = false;
    } else {
      this.currentFilter.type=this.filterForm.value.currentFilter;
      const filterDate =this.util.getCommonIsoDate(this.currentFilter);
      // let parseData=JSON.parse(this.providerList[0].properties.properties)
      let parseData=this.providerList[0].properties.providerSettings
      let body ={
        "subscriptions":tempSubList.map(dt=>dt.subscriptionId),//["162349c9-37d0-45e1-b2e3-28c4216b7c6b"]
        "privateIP":parseData.hostname,
        "timespan":filterDate.from+"/"+filterDate.to,//"2022-05-09T07:12:37.437Z/2022-05-10T07:12:37.437Z",
        "interval":this.getInterval(filterDate)//"PT5M"
      }
      this.networkThroughput.xAxis=[];
      this.networkThroughput.series=[];
      this.networkThroughput.legendData=[];
      this.networkThroughput.networkIn=[];
      this.networkThroughput.networkOut=[];
      this.networkThroughput.showLoader = true;
      return this.util.handleRequest('post','a3s_applicationHealthMonitor_getNetworkThroughput',[],body,null,null,true).then(res=>{
        if(!res || !res.body) {
          this.networkThroughput.showLoader = false;
          return 
        }
        if(res.body.networkOut&&res.body.networkOut.length) {
          this.networkThroughput.networkOut=res.body.networkOut;
        }
        if(res.body.networkIn&&res.body.networkIn.length) {
          this.networkThroughput.networkIn=res.body.networkIn;
        }
        this.prepareNetworkChart();
        this.networkThroughput.showLoader = false;
      },err=>{
        this.networkThroughput.showLoader = false;
      })
    }
  }

  prepareNetworkChart(){
    this.networkThroughput.xAxis=[];
    this.networkThroughput.series=[];
    this.networkThroughput.legendData=[];
    var timeArray = [];
    this.networkThroughput.networkIn.forEach((dt,index)=>{
      let dataVal=this.formatBytesInMB(dt.average);
        if(index==0) {
          let obj = {
            name : 'Network In',
            type : 'line',
            showSymbol :false,
            data: [Number(dataVal)]
          }            
          this.networkThroughput.series.push(obj);
          this.networkThroughput.legendData.push({icon:'circle',name:'Network In'});
        } else {
          this.networkThroughput.series[0].data.push(Number(dataVal));
        }
        let findTime=timeArray.find(ds=>ds==dt.timeStamp);
        if(!findTime){
          timeArray.push(dt.timeStamp);       
        }
    });
    if(this.networkThroughput.series.length){
      this.networkThroughput.NIAvg=((this.networkThroughput.series[0].data.reduce(
        (previousValue, currentValue) => previousValue + currentValue
      ))/this.networkThroughput.series[0].data.length).toFixed(2);
    }
    this.networkThroughput.networkOut.forEach((dt,index)=>{
      let dataVal=this.formatBytesInMB(dt.average);
        if(index==0) {
          let obj = {
            name : 'Network Out',
            type : 'line',
            showSymbol :false,
            data: [Number(dataVal)]
          }            
          this.networkThroughput.series.push(obj);
          this.networkThroughput.legendData.push({icon:'circle',name:'Network Out'});
        } else {
          this.networkThroughput.series[1].data.push(Number(dataVal));
        }
        let findTime=timeArray.find(ds=>ds==dt.timeStamp);
        if(!findTime){
          timeArray.push(dt.timeStamp);       
        }
    });
    if(this.networkThroughput.series.length&&this.networkThroughput.series[1]&&this.networkThroughput.series[1].data){
      this.networkThroughput.NOAvg=((this.networkThroughput.series[1].data.reduce(
        (previousValue, currentValue) => previousValue + currentValue
      ))/this.networkThroughput.series[1].data.length).toFixed(2);
    }
    let sorted : any = timeArray.sort((a,b)=> {
      var d1 = new Date(b);
      var d2 = new Date(a);
      return d2 > d1 ? 1 : 0;
    })
  
    sorted.forEach((x)=>{
        let findXAxis=this.networkThroughput.xAxis.find(dt=>dt==moment(x).format('MM/DD/YYYY h:mm A'));
        if(!findXAxis){
        this.networkThroughput.xAxis.push(moment(x).format('MM/DD/YYYY h:mm A'))
        }
    })
    this.networkThroughput.options=this.setChartOption('networkThroughput');
  }

  prepareBackupChart(type,stateStatus){
    this[type].xAxis=[];
    this[type].series=[];
    this[type].legendData=[];
    var timeArray = [];
    this.backupCatalog.tableData.forEach((dt,index)=>{
      if((stateStatus=='All'||dt.backupStatus.toLowerCase()==stateStatus.toLowerCase())&&((type=='backupDuration'&&dt.backupRateVal)||dt.backupSizeVal)) {
        let dataVal=this.formatBytesInMB(type=='backupDuration'?dt.backupRateVal:dt.backupSizeVal);
        if(!this[type].series.length) {
          let obj = {
            name: dt.backupItem,
            type: 'bar',
            // barWidth: 40,
          //  stack: 'total',
            large: true,
            emphasis: {
                focus: 'series'
            },
            data:[dataVal]
          }            
          this[type].series.push(obj);
          this[type].legendData.push({icon:'circle',name:dt.backupItem});
        } else if(this[type].series.length){
          this[type].series[0].data.push(dataVal);
        }
        let findTime=timeArray.find(ds=>ds==dt.endTime);
        if(!findTime){
          timeArray.push(dt.endTime);       
        }
      }
    });

    if(this[type].series.length&&type=='backupSize'){
      this[type].valueAvg=this.formatBytes((this[type].series[0].data.reduce(
        (previousValue, currentValue) => previousValue + currentValue
      ))/this[type].series[0].data.length).split(' ');
    }else if(this[type].series.length&&type=='backupDuration'){
      this[type].valueAvg=this.formatKbs((this[type].series[0].data.reduce(
        (previousValue, currentValue) => previousValue + currentValue
      ))/this[type].series[0].data.length).split(' ');
    }

    let sorted : any = timeArray.sort((a,b)=> {
      var d1 = new Date(b);
      var d2 = new Date(a);
      return d2 > d1 ? 1 : 0;
    })
  
    sorted.forEach((x)=>{
        let findXAxis=this[type].xAxis.find(dt=>dt==moment(x).format('MM/DD/YYYY h:mm A'));
        if(!findXAxis){
        this[type].xAxis.push(moment(x).format('MM/DD/YYYY h:mm A'))
        }
    })
    this[type].options=this.setPieChartOptions(type);
  }

  checkProviderVal(providerName) {
    // return this.filterForm.value.provider==providerName;
    return this.providerList && this.providerList.length ? this.providerList.some(e=> e.name.toLowerCase() == providerName.toLowerCase()) ? true : false : false;
  }

  checkSidVal(sidName) {
    return this.filterForm.value.sid.toLowerCase() == sidName.toLowerCase();
  }

  changeBackupState(){
    if(this.filterForm.value.backupState){
      this.backupCatalog.tableData=this.filterForm.value.backupState=='All'?this.backupCatalog.tempTableData:this.backupCatalog.tempTableData.filter(dt=>dt.backupStatus.toLowerCase()===this.filterForm.value.backupState.toLowerCase());
      this.prepareBackupChart('backupSize',this.filterForm.value.backupState);
      this.prepareBackupChart('backupDuration',this.filterForm.value.backupState);
    }
  }

  changeFilter() {
    this.loadData();
  }

  prepareData(type){
    this[type].tableData=[];
    let unique = this.util.unique(this[type].tempData,['12'])
    let filter = unique.filter(e=> this.providerList.some(x=> x.name.toLowerCase() == e[11].toLowerCase()))
    this.sidList = filter.map(e=>e[12])
    this.filterForm.patchValue({
      sid: this.filterForm.value.sid ? this.filterForm.value.sid : this.sidList[0]
    });
    this[type].tempData.forEach(dt=>{
      if(this.checkSidVal(dt[12]) && this.checkProviderVal(dt[11])) {
        let findIndex=this[type].tableData.findIndex(ds=>ds.backupItem===dt[2]);
        if(findIndex>-1) {
          this[type].tableData[findIndex].totalBackup=this[type].tableData[findIndex].totalBackup+1;
          if(dt[0]!=='success') {
            this[type].tableData[findIndex].numFailedBackup=this[type].tableData[findIndex].numFailedBackup+1;
            this[type].tableData[findIndex].backupStatus='failed';
          }
          let findChildIndex=this[type].tableData[findIndex].childData.findIndex(ds=>ds.backupItem===dt[1]);
          if(findChildIndex>-1) {
            this[type].tableData[findIndex].childData[findChildIndex].totalBackup=this[type].tableData[findIndex].childData[findChildIndex].totalBackup+1;
            if(dt[0]!=='success') {
              this[type].tableData[findIndex].childData[findChildIndex].numFailedBackup=this[type].tableData[findIndex].childData[findChildIndex].numFailedBackup+1;
              this[type].tableData[findIndex].childData[findChildIndex].backupStatus='failed';
            }
          } else {
            this[type].tableData[findIndex].childData.push({
              backupItem:dt[1],
              backupStatus:dt[0],
              numFailedBackup:dt[0]==='success'?Number(0):Number(1),
              totalBackup:1,
              backupAge:this.convertTimeDuration(dt[5]),
              agePreviousBackup:this.convertTimeDuration(dt[5]),
            })
          }
        } else {
          this[type].tableData.push({
            backupItem:dt[2],
            backupStatus:dt[0],
            numFailedBackup:dt[0]==='success'?Number(0):Number(1),
            totalBackup:1,
            childData:[{
              backupItem:dt[1],
              backupStatus:dt[0],
              numFailedBackup:dt[0]==='success'?Number(0):Number(1),
              totalBackup:1,
              backupAge:this.convertTimeDuration(dt[5]),
              agePreviousBackup:this.convertTimeDuration(dt[5]),
              parentItem:dt[2]
            }]
          });
        }
      }
    });
  }

  convertTimeDuration(value){
    if(value) {
      let data=value.split(':');
      let result="";
      if(Number(data[0])>0) {
        result=result+(Number(data[0]))+" hour(s) "
      }
      if(Number(data[1])>0) {
        result=result+(Number(data[1]))+" minute(s) "
      }
      if(Number(data[2])>0) {
        result=result+(Number(data[2]))+" sec(s) "
      }
      return result
    }

  }

  getStatusClass(value){
    if(value) {
      let status = value.toLowerCase()
      switch (status) {
        case 'success': return 'success-chip';
        case 'succeeded': return 'success-chip';
        case 'failed': return 'error-chip';
        case 'emergency': return 'emergency-severity-chip';
        case 'error': return 'error-chip';
        case 'warning': return 'warning-chip';
        case 'informational': return 'informational-chip';
        case 'debug': return 'debug-chip';
        case 'alert': return 'alert-chip';
        case 'notice': return 'notice-chip';
        case 'critical': return 'critical-chip';
        default: return 'error-chip';
      }
    }
  }

  getStatusChipImg(value) {
    if(value) {
      let status = value.toLowerCase()
      switch (status) {
        case 'succeeded':
        case 'success': return 'assets/icons/green-tick.svg'
        case 'failed':
        case 'Error': return 'assets/icons/error-info.svg';
        default: return 'assets/icons/error-info.svg';
      }
    }
  }

  setChartOption(name) {
    let previousIndex;
    const filterDate =this.util.getCommonIsoDate(this.currentFilter);
    let dateList=this.timechartService.xAxisDateTimeFormatList(filterDate,this.currentFilter.timeZone,this[name]);
    // dateList.splice(0,1);
    // let timeSpan=this[name].xAxis.length-dateList.length;
    // if(timeSpan>2) {
    //   timeSpan=2
    // }
    if(!dateList[0].valueXaxis){
      dateList.splice(0,1);
    }
    let timeSpan=this[name].xAxis.length/dateList.length;
    if(this[name].xAxis.length==dateList.length||this[name].xAxis.length<dateList.length) {
      timeSpan=0;
    }
    let options = {
      color: this[name].color,
      tooltip: this.util.getLineChartTooltip(),
      legend: {
        show: false,
        data: this[name].legendData,
        bottom: 0,
        left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        bottom:'2%',
        containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap:true,
          axisTick: {
            alignWithLabel: true,
            interval: Number((timeSpan).toFixed()),
          },
          data: this[name].xAxis,
          axisLabel: {
            color: '#626F7B',
            interval: Number((timeSpan).toFixed()),
            padding: [5, 0, 0, 0],
            fontSize: 12, 
            showMinLabel:dateList[0].valueXaxis?true:false,
            fontFamily: 'Inter',
            formatter: function (value, index) { 
              if(index==0) {
                previousIndex=-1;
              }
              previousIndex=previousIndex+1;
              if(dateList[previousIndex]) {
                return dateList[previousIndex].valueXaxis
              }
            }
          },
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
          },
      },
      series: this[name].series
    };
    return options
  }

  // Charts Options
  setPieChartOptions(type){
    let previousIndex;
    const filterDate =this.util.getCommonIsoDate(this.currentFilter);
    let dateList=this.timechartService.xAxisDateTimeFormatList(filterDate,this.currentFilter.timeZone,this[type]);
    if(!dateList[0].valueXaxis){
      dateList.splice(0,1);
    }
    // let timeSpan=this[type].xAxis.length-dateList.length;
    let timeSpan=this[type].xAxis.length/dateList.length;
    if(this[type].xAxis.length==dateList.length||this[type].xAxis.length<dateList.length) {
      timeSpan=0;
    }
    // if(timeSpan>2) {
    //   timeSpan=5
    // }
    // if(divisionVal>3){
    //   timeSpan=divisionVal;
    // }
    let options = {
      color: ['#0B70D4','#62C2FB','#FF5959','#656BFD'],
      tooltip: this.util.getLineChartTooltip(),
      legend: {
          show: false,
          data: this[type].legendData || [],
          bottom: 10,
          left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        bottom:'2%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap:true,
        axisTick: {
          alignWithLabel: true,
          interval: Number((timeSpan).toFixed()),
        },
        data: this[type].xAxis,
        axisLabel: {
          color: '#626F7B',
          interval: Number((timeSpan).toFixed()),
          padding: [5, 0, 0, 0],
          fontSize: 12, 
          showMinLabel:dateList[0].valueXaxis?true:false,
          fontFamily: 'Inter',
          formatter: function (value, index) { 
            if(index==0) {
              previousIndex=-1;
            }
            previousIndex=previousIndex+1;
            if(dateList[previousIndex]) {
              return dateList[previousIndex].valueXaxis
            }
          }
        },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#626F7B',
        padding: [5, 0, 0, 0],
        fontSize: 12, fontFamily: 'Inter',
      },
    },
      // yAxis: {
      //   type: 'value',
      //   axisLabel: {
      //     color: '#333',
      //     padding: [5, 0, 0, 0],
      //     fontSize: 10, 
      //     formatter: function (params) {
      //       return params
      //     },
      //   },
      // },
      series: this[type].series
    };

    return options
  }

  /* To copy Text from Textbox */
  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  viewBackupDetails(row) {
    this.backupDetailForm.patchValue({
      ...row
    })
    this.showDetails=true;
  }

  prepareCatalogData(row,list){
    this.backupCatalog.tableData=[];
    this.backupSummary.tempData.forEach(dt=>{
      if(row.backupItem==dt[2]&&list.backupItem==dt[1]) {
        this.backupCatalog.tableData.push({
          backupStatus:dt[0],
          database:dt[2],
          backupItem:dt[1],
          startTime:dt[3],
          endTime:dt[4],
          duration:dt[5],
          backupSize:this.formatBytes(dt[6]),
          backupRate:this.formatKbs(dt[7]),
          backupSizeVal:dt[6],
          backupRateVal:dt[7],
          message:dt[8],
          backupId:dt[9],
          numberOfFile:dt[10],
        })
      }
    })
    this.backupCatalog.tempTableData=_.cloneDeep(this.backupCatalog.tableData);
  }

  viewOtherTiles(row,list) {
    this.showOtherTiles=true;
    this.backupCatalog.showLoader = true;
    this.backupSize.showLoader=true;
    this.backupDuration.showLoader=true;
    this.filterForm.patchValue({
      backupState:'All'
    })
    this.prepareCatalogData(row,list);
    this.prepareBackupChart('backupSize','All');
    this.prepareBackupChart('backupDuration','All');
    this.backupSize.showLoader=false;
    this.backupDuration.showLoader=false;
    this.backupCatalog.showLoader = false;
  }

  loadData(){
    this.getBackupData('backupSummary');
    this.getNetworkData();
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  
  formatBytesInMB(bytes, decimals = 2) {
    if (bytes === 0) return 0;

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    // const sizes = ['Bytes', 'KB', 'MB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, (i>2?2:i))).toFixed(dm));// + ' ' + sizes[i];
  }

  formatKbs(kbps, decimals = 2) {
    if (kbps === 0) return '0 KB/s';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['KB/s', 'MB/s', 'GB/s', 'TB/s', 'PB/s', 'EB/s', 'ZB/s', 'YB/s'];

    const i = Math.floor(Math.log(kbps) / Math.log(k));

    return parseFloat((kbps / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async refreshData(type){
    switch (type) {
      case 'backupSummary':{
        this.backupSummary.refreshTime = new Date();
        this.getBackupData('backupSummary');
        break;
      }
      case 'networkThroughput':{
        this.networkThroughput.refreshTime = new Date();
        this.getNetworkData();
        break;
      }
      case 'backupSize':{
        this.backupSize.refreshTime = new Date();
        this.backupSize.showLoader=true;
        this.prepareBackupChart('backupSize',this.filterForm.value.backupState);
        this.backupSize.showLoader=false;
        break;
      }
      case 'backupDuration':{
        this.backupDuration.refreshTime = new Date();
        this.backupDuration.showLoader=true;
        this.prepareBackupChart('backupDuration',this.filterForm.value.backupState);
        this.backupDuration.showLoader=false;
        break;
      }
      case 'backupCatalog':{
        this.backupCatalog.refreshTime = new Date();
        this.backupCatalog.showLoader=true;
        this.backupCatalog.tableData=[];
        this.backupCatalog.tableData=this.filterForm.value.backupState=='All'?this.backupCatalog.tempTableData:this.backupCatalog.tempTableData.filter(dt=>dt.backupStatus.toLowerCase()===this.filterForm.value.backupState.toLowerCase());
        this.backupCatalog.showLoader=false;
        break;
      }
    }    
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    this.currentFilter.type=value;
    this.loadData();
    this.closeFilter()
  }

  openModalForCustom(value,template: TemplateRef<any>,list?:any) {
    list.currentFilter = value;
    this.openModal(template,list);
  }
  
  applyCustomFilter(){
    this.currentFilter=this.selectedData.currentFilter;
    this.loadData();
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 9;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.currentFilter.type=list;
    this.selectedData = {currentFilter:this.currentFilter};
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any = this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    this.loadData();
    console.log('Filter Object',this.filterObject)
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    // this.getAllData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

}
