
  <div class="side-bar-container-2 scroll-y">
    <ul class="side-nav">
      <li class="text-right li-collapse-btn">
        <button class="collapse-arrow normal-view" (click)="collapseSider()"></button>
        <button class="collapse-arrow icon-view" tooltip="Expand Panel" container="body" placement="left" containerClass="mgmt-tooltip" (click)="collapseSider()"></button>

        <ng-container *ngFor="let menu of applicationMonitorMenu">
          <li class="menu-item">
            <div class="menu-inner" [class.open]="menu.projectList.length" id="{{menu.menu}}" (click)="triggerMenuClick(menu);setCurrentProject(menu.projectList[0],0)" [routerLink]="menu.route"  [class.active]="checkRouteActive(menu?.menu)">
              <div class="menu-icon normal-view"><img [src]="menu?.image" alt="Icon" /></div>
              <div class="menu-icon icon-view" [tooltip]="menu?.value" container="body" placement="left" containerClass="mgmt-tooltip"><img [src]="menu?.image" alt="Icon" /></div>
              <div class="menu-name">{{menu?.value}}</div>
            </div>
            <ul class="submenu" *ngIf="menu.projectList.length">
              <ng-container *ngFor="let list of menu.projectList;index as i">
                <li class="sub-item">
                  <div class="submenu-inner" id="types_{{i}}" [class.active]="currentProject == list.projectName" (click)="setCurrentProject(list,i)">
                    <div class="sub-icon normal-view" *ngIf="menu.menu == 'metricsUsage'"><img [src]="getImage(list)" alt="Icon" /></div>
                    <div class="sub-icon icon-view" [tooltip]="list.projectName" container="body" placement="left" containerClass="mgmt-tooltip" *ngIf="menu.menu == 'metricsUsage'"><img [src]="getImage(list)" alt="Icon" /></div>
                    <div class="sub-name">{{list.projectName}}</div>
                  </div>
                  <ul class="submenu-2">
                    <li class="sub-item-2" *ngFor="let types of list?.resources;let j = index" [class.active]="currentResource == types.resourceName" (click)="setCurrentResourceType(types);routeTo()">
                      <div class="sub-name-2">{{types?.resourceName | titlecase}}</div>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </ul>

            <div class="no-access-tooltip-wrapper left-tool" *ngIf="menu?.restrictAccess" id="no-access-tooltip">
              <div class="left-pane">
                <img src="./assets/common-icons/no-access-info.svg">
              </div>
              <div class="right-pane">
                <div class="content">
                  <span>You don’t have access to this tab</span>
                  <p>To get access please contant your 
                    administrator.</p>
                </div>
                <img class="cursor-pointer" src="assets/Infra/blueprint/close.svg" (click)="menu.restrictAccess = false">
              </div>
            </div>

          </li>
        </ng-container>
  
    </ul>
  </div>


  <ng-container *ngIf="checkA3sAccess()">
    <div class="body-content" *ngIf="!allowUrl()">
      <div class="main ml-24 mr-24">
          <app-breadcrumbs></app-breadcrumbs>
          <router-outlet></router-outlet>
      </div>
    </div>
    <!-- For Metrics & Usage -->
    <div class="body-content pt-0" *ngIf="allowUrl()">
      <router-outlet></router-outlet>
    </div>

  </ng-container>
  
