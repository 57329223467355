<div class="introduction">
    <p class="heading" id="0">Enterprise Policies </p>
    <p class="para mb-0">Enterprise Policies landing page shows the list of Policies and Initiatives. 
        Initiative is a group of individual policies. </p>
        <img src="assets\user-manual\governance\Policies.svg" >  
        <figcaption class="caption">Enterprise policy dashboard </figcaption>   
        <ul>
            <li>Use Filters – Project, Category, Subscription, and Resource Group at the top to define the scope that you are interested in.  </li>
            <li>Assignments by Compliance: shows the number of assigned compliances initiatives (group of policies) by date. For example, ASC Default is a group of 10 policies that are applied to the subscription on a particular date.   </li>
            <img src="assets\user-manual\governance\Tile.svg" >
            <figcaption class="caption"> Assignments by compliance  </figcaption>
              <li>View the list of assigned policies in the Policies table. </li>
              <img src="assets\user-manual\governance\Tile(1).svg" >
              <figcaption class="caption">List of policies  </figcaption>
              <li>View the list of assigned initiatives in the Initiatives table. </li>
              <img src="assets\user-manual\governance\Tile(1).svg" >
          </ul>
</div>
