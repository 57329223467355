<div class="introduction">
    <p class="heading" id="1">Demand Forecasting.</p>
        <p>This use case provides an estimation of what could be the demand in the future. 
            A simple easy to use and visualize the forecast results </p>
    
      <ol>
          <li>Click <b>Data analytics</b> in the left menu.  </li>
          <li>Click <b>Create new project</b> and then select <b>Demand forecasting.</b>  </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Product-Seasonality-Classifier(1).svg" >
          <figcaption class="caption">Demand forecasting - use case </figcaption>
         

          <li>Provide Project name and Source Details to connect to the source system. </li>
          <img src="assets\user-manual\data-&-AI\da-1.svg"  >
          <figcaption class="caption"> Demand forecasting - source details </figcaption>
  
          <li>Prepare data by selecting <b>Table, Prediction column,</b> and <b>Train ration.</b> </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Step-4.svg" >
          <figcaption class="caption">Demand forecasting – prepare data </figcaption>
      
  
          <li>Click <b>Next.</b>  </li>
          <li>Select Choose method as <b>Linear Regression</b>  </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Step-3.svg" >
          <figcaption class="caption">Demand forecasting - analysis method  </figcaption>
       
  
          <li>Click <b>Submit.</b> </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Step-5.svg" >
          <figcaption class="caption">Demand forecasting – output  </figcaption>
       
  
          <li>Click View in <b>A3S BI Dashboard. </b></li>
     
          <img src="assets\user-manual\data-&-AI\SQL-BI.png" >
          <figcaption class="caption">Demand forecasting - BI dashboard  </figcaption>
          <li>View project summary by clicking Finish on the Output page. </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\Project-Summary-DA.svg" >
          <figcaption class="caption">Demand forecasting - Project Summary </figcaption>
          <li>In case of error/failure, Failure message will be displayed. </li>
          <img src="assets\user-manual\data-&-AI\data-analytic\loading.svg" >
          <figcaption class="caption">Demand forecasting - failure </figcaption>
         
      </ol>
  </div>
