 <div class="introduction">
          <p class="heading" id="4">Failed to Deploy </p>
          <ol>
              <li>In case the deployment fails, then error notification will be shown. </li>
              <img src="assets\user-manual\apps-&-infra\Project-summary-success.svg " >
              <figcaption class="caption"> Project deployment failure </figcaption>
              <li>Project status is ‘Failed to deploy’.  </li>
            
          </ol>
         
</div>
