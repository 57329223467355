<div class="security-alert-management-container">
    <div class="header-container">
      <h2 class="title">Manage Policy</h2>
    </div>
    <!-- Filter Section -->
    <div class="custom-filter-wrapper flex-custom ">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button> -->
    </div>
    <div class="dashboard-body">
      <div class="row">
        <div class="col-md-12 padding-div">
          <div class="graph-div mt-0" style="padding-right: 12px;">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Assigned Policies</h4>
                      </div>
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3 custom-default-table"  *ngIf="(!securityCenter?.showLoader)">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Assignment</span>
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Assigned on</span>
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Audit policies</span>
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Deny policies</span>
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Disabled policies</span>
                          </div>
                      </th>
                      <th style="border-right: none;">
                          <div class="flex-custom no-wrap">
                              <span>Exempted policies</span>
                          </div>
                      </th>
                      <th style="border-left: none;"></th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of securityCenter?.tableData">
                        <td>{{row.displayName}}</td>
                        <td>Subscription</td>
                        <td>{{row.auditCount}}</td>
                        <td>{{row.denyCount}}</td>
                        <td>{{row.disableCount}}</td>
                        <td>{{row.exemptedCount}}</td>
                        <td style="width: 1%;">
                            <span>
                              <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                              <mat-menu #action2="matMenu" class="delete-popup">
                                <div class="mat-menu-item" mat-menu-item (click)="editAssignment(row)"><p class="delete-popup-text" style="margin: 0px !important;padding-left: 8px;padding-bottom: 10px !important;">Edit assignment</p></div>
                                <div class="mat-menu-item" mat-menu-item (click)="deleteAssigment(row)"><p class="delete-popup-text" style="margin: 0px !important;padding-left: 8px;padding-bottom: 10px !important;">Delete assignment</p></div>
                                <div class="mat-menu-item" mat-menu-item (click)="createExemption(row)"><p class="delete-popup-text" style="margin: 0px !important;padding-left: 8px;">Create exemption</p></div>
                              </mat-menu>
                            </span>              
                          </td>
                    </tr>
                    <tr *ngIf="!securityCenter?.tableData?.length">No existing Policy assignments.</tr>
                  </tbody>
              </table>
              </div>
              <div *ngIf="(!securityCenter?.tableData?.length && !securityCenter?.showLoader)"><button class="btn btn-btn-primary" (click)="createAssignment()">Assign policy</button></div>
              <div class="loader-gif" *ngIf="securityCenter?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <!-- <div class="dash-text-center" *ngIf="(!securityCenter?.tableData?.length && !securityCenter?.showLoader)">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No data found</p>
              </div> -->
          </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-12 padding-div">
          <div class="graph-div" style="padding-right: 12px;">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Industry & Regulatory standards</h4>
                      </div>
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3 industry-table">
                <table class="table">
                  <tbody>
                    <tr *ngFor="let item of industryStandards.tableData">
                        <td>{{item.name}}</td>
                        <td>{{item.descrption}}</td>
                        <td>{{item.flag}}</td>
                        <td><button class="btn btn-outline pull-right" (click)="item.status=='Disable'?disableStandard(item):item.status=='Enable'?enableStandard(item):deleteInitiativeManual(item)">{{item.status}}</button></td>
                    </tr>
                  </tbody>
              </table>
              </div>
              <!-- <div class="loader-gif" *ngIf="alertsData?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!alertsData?.tableData?.length && !alertsData?.showLoader)">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No data found</p>
              </div> -->
          </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-12 padding-div">
          <div class="graph-div mb-4" style="padding-right: 12px;">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Add Standards</h4>
                      </div>
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="(standardsValue?.tableData?.length && !standardsValue?.showLoader)">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('standardsValue','displayName')">
                          </div>
                      </th>
                      <th style="border-right: none;">
                          <div class="flex-custom no-wrap">
                              <span>Description</span>
                          </div>
                      </th>
                      <th style="border-left: none;"></th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of standardsValue.tableData">
                        <td>{{row?.properties?.displayName}}</td>
                        <td style="width: max-content !important;white-space: normal !important;">Track {{row?.properties?.displayName}} controls in the Compliance Dashboard based on a recommended se of policies and assessments</td>
                        <td><button class="btn btn-outline pull-right" (click)="addStandard(row)">Add</button></td>
                    </tr>
                  </tbody>
              </table>
              </div>
              <div class="loader-gif" *ngIf="standardsValue?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!standardsValue?.tableData?.length && !standardsValue?.showLoader)">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No data found</p>
              </div>
          </div>
      </div>
      </div>
      <div class="sidebar-right" *ngIf="showAssignPolicy"  [ngClass]="{'content-move-sub-sidebar':assignDetails.nonComplianceShow||assignDetails.scopeShow||assignDetails.exclusionsShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>{{assignDetails.editFlag?'Edit Assign Policy':assignDetails.addStandard?'Add Standard':'Assign Policy'}}</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showAssignPolicy=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label">Scope<span class="mandatory">*</span></label>
                    </div>
                    <div>
                        <span>{{assignDetails.subscriptionName}}</span>
                    </div>
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [class.disabled]="assignDetails.editFlag" style="padding-top: 2px;" (click)="chooseScope()">Choose scope</p>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label ">Exclusions (optional)</label>
                    </div>
                    <div class="input-box">
                    </div>  
                    <div class="add-another-tag">
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" style="padding-top: 2px;" (click)="chooseExclusions()">Choose exclusions</p>
                    </div>            
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label ">{{assignDetails.addStandard?'Initiative definition':'Policy definition'}}</label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.disabled]="true" id="policyDefinition" autocomplete="off" [(ngModel)]="assignDetails.properties.displayName" placeholder="" name="policyDefinition" >
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="assignmentName" class="control-label ">Assignment name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Assignment display name" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="assignmentName" autocomplete="off" [(ngModel)]="assignDetails.name" placeholder="" name="assignmentName" required>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label ">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="assignDetails.properties.description"  placeholder="" (keyup)="VNetDescription($event,500)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 512 characters.</p>
                    </div>                    
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="policyEnforcement" class="control-label ">Policy enforcement<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When enforcement mode is disabled, the policy effect isn't enforced
                            (i.e. deny policy won't deny resources). Compliance assessment results 
                            are still available. https://aka.ms/enforcementMode" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="policyEnforcement" [value]="'enabled'" ([ngModel])="assignDetails.policyEnforcement" [checked]="assignDetails.policyEnforcement=='enabled'" (change)="assignDetails.policyEnforcement=$event.target.value;"><span>Enabled</span></label>
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="policyEnforcement" [value]="'disabled'" ([ngModel])="assignDetails.policyEnforcement" [checked]="assignDetails.policyEnforcement=='disabled'" (change)="assignDetails.policyEnforcement=$event.target.value;"><span>Disabled</span></label>                    
                    </div>              
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="assignBy" class="control-label ">Assigned by</label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="assignBy" autocomplete="off" [(ngModel)]="assignDetails.properties.metadata.assignedBy" placeholder="" name="assignBy" required>
                    </div>              
                </div>
                <div class="form-group" *ngFor="let item of assignDetails.parameterList; let i=index;">
                    <div class="label-div">
                        <label class="text-weight control-label" *ngIf="i==0">Parameters</label>
                        <label for="parameters" class="control-label ">{{item.metadata.displayName}}<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="item.listVal" bindLabel="name" [(ngModel)]="item.defaultValue" name="parameters" placeholder="" [clearable]="false">
                        </ng-select>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="text-weight control-label ">Remediation</label>
                    </div>
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="managedIdentity" id="managedIdentity" [(ngModel)]="assignDetails.managedIdentity" ><span>Create a Managed Identity<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only deployIfNotExists or modify policies need a managed identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label">Managed identity location</label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.disabled]="true" id="managedIdentityLocation" autocomplete="off" [(ngModel)]="assignDetails.location" placeholder="" name="managedIdentityLocation" >
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="text-weight control-label ">Permissions</label>
                    </div>
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                        <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                        <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 8px 4px 0px !important;color: #703501;">
                            This policy does not contain any role definitions. deployIfNotExists and modify policies must specify role definitions in order to create the correct role assignments for the managed identity.</label>
                    </div>            
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="text-weight control-label ">Non-compliance messages<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Non-compliance messages help users understand why a resource is not 
                            compliant with the policy. The message will be displayed when a resource 
                            is denied and in the evaluation details of any non-compliant resource." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                    </div>
                    <div class="input-box">
                        
                    </div>  
                    <div class="add-another-tag">
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" style="padding-top: 2px;" (click)="editMessages()">Edit messages</p>
                    </div>             
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" (click)="saveAssignPolicy()">Save</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualNetwork')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
    </div>
      <div class="sidebar-right" *ngIf="assignDetails.nonComplianceShow">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Non-compliance Messages</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="assignDetails.nonComplianceShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label ">Default non-compliance message<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The default non-compliance message will be used for all policy definition references 
                            within the initiative that do not have a policy specific non-compliance message." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.NCDescription" [(ngModel)]="assignDetails.description"  placeholder=""></textarea>
                        <p class="text-danger" *ngIf="inputError?.NCDescription">Description cannot exceed 512 characters.</p>
                    </div>                    
                </div>
                <div class="form-group" *ngFor="let item of assignDetails.complianceList; let i=index;">
                    <div class="label-div">
                        <label class="control-label font-weight">{{item.metadata.displayName}}</label>
                        <span>{{item.policyDefinitionReferenceId}}</span>
                    </div>
                    <div class="label-div">
                        <span>{{item.metadata.description}}</span>
                    </div> 
                    <div class="add-another-tag">
                        <ng-template #nonComplianceDescription>
                            <div class="form-group">
                                <div class="label-div">
                                    <label class="control-label">Non-compliance message</label>
                                </div>
                                <div class="input-box">
                                    <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.NCDescription" [(ngModel)]="nonCompliance.description"  placeholder=""></textarea>
                                    <p class="text-danger" *ngIf="inputError?.NCDescription">Description cannot exceed 512 characters.</p>
                                </div> 
                            </div>
                            <div class="d-flex">
                                <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!nonCompliance.description" (click)="saveNonComplianceMessage();p.hide();">Save</button>
                                <button class="btn btn-outline" (click)="p.hide();">Cancel</button>
                            </div>
                        </ng-template> 
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="nonComplianceDescription"
                        triggers="" placement="bottom" containerClass="custom-popover" #p="bs-popover" [outsideClick]="true" (click)="editMessage(i);p.show();">Edit message</p>
                    </div>            
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-outline pull-left" (click)="assignDetails.nonComplianceShow=false;">Cancel</button>
                <button class="btn btn-btn-primary" (click)="saveNonCompliance()">Save</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualNetwork')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
    </div>
      <div class="sidebar-right" *ngIf="assignDetails.scopeShow">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Scope</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="assignDetails.scopeShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="subscription" class="control-label">Subscription</label>
                    </div>
                    <div class="input-box no-margin">
                        <ng-select class="margin-select" [items]="subscriptionList" bindLabel="displayName" (change)="getResourceGroup()" [(ngModel)]="scopeSelection.subscription" name="subscription" [clearable]="false" required></ng-select>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Resource group</label>
                    </div>
                    <div class="input-box no-margin">
                        <ng-select class="margin-select" [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="scopeSelection.resourceGroup" name="VMsize" placeholder="Choose resource group (optional)" [clearable]="false" required></ng-select>
                    </div>
                </div> 
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-outline pull-left" (click)="assignDetails.scopeShow=false;">Cancel</button>
                <button class="btn btn-btn-primary" (click)="saveScope()">Save</button>
            </div>
        </div>
    </div>
      <div class="sidebar-right" *ngIf="assignDetails.exclusionsShow">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Scope</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="assignDetails.exclusionsShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="subscription" class="control-label">Subscription</label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.disabled]="true" id="subscription" autocomplete="off" [(ngModel)]="exclusioneSelection.subscription.displayName" placeholder="" name="subscription" >
                    </div>  
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Resource group</label>
                    </div>
                    <div class="input-box no-margin">
                        <ng-select class="margin-select" [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="exclusioneSelection.resourceGroup" name="VMsize" placeholder="Choose resource group (optional)" [clearable]="false" required></ng-select>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="resource" class="control-label">Resource</label>
                    </div>
                    <div class="input-box no-margin">
                        <ng-select class="margin-select" [items]="" bindLabel="name" bindValue="name" [(ngModel)]="exclusioneSelection.resource" name="resource" placeholder="Choose resource (optional)" [clearable]="false" required></ng-select>
                    </div>
                </div> 
                <div class="form-group">
                    <button class="btn btn-outline" (click)="addSelectedScope()">Add selected scope</button>
                </div>
                <div class="form-group" *ngFor="let item of exclusioneSelection.nonScopeList; let i=index;">
                    <div class="label-div">
                        <label class="text-weight control-label" *ngIf="i==0">Selected scope</label>
                    </div>
                    <div class="label-div">
                        <label class="control-label">{{item.subscription.displayName}}</label>
                        <label class="control-label">{{item.resourceGroup}}<span *ngIf="item.resource">/{{item.resource}}</span></label>
                    </div>   
                    <div class="add-another-tag">
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" style="padding-top: 2px;" (click)="remove(i)">Remove</p>
                    </div>             
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-outline pull-left " (click)="assignDetails.exclusionsShow=false;">Cancel</button>
                <button class="btn btn-btn-primary"  [class.disabled]="!exclusioneSelection.nonScopeList"   (click)="saveScope()">Save</button>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="showExemption">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Create Exemption</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showExemption=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label">Exemption scope<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Exemption scope can be management group, 
                            subscription, resource group or resource" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div>
                        <span>{{exemptionDetails.subscriptionName}}</span>
                    </div>
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [disabled]="true" style="padding-top: 2px;" (click)="chooseScope()">Choose scope</p>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label ">Assignment name</label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.disabled]="true" id="policyDefinition" autocomplete="off" [(ngModel)]="exemptionDetails.properties.displayName" placeholder="" name="policyDefinition" >
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="assignmentName" class="control-label ">Example name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Exemption display name" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="assignmentName" autocomplete="off" [(ngModel)]="exemptionDetails.name" placeholder="" name="assignmentName" required>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="parameters" class="control-label ">Exemption category<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The reason code of creating policy exemption" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="exemptionCategoryList" bindLabel="name" bindValue="name" [(ngModel)]="exemptionDetails.exemptionCategory" name="parameters" placeholder="" [clearable]="false">
                        </ng-select>
                    </div>     
                    <span *ngIf="exemptionDetails.exemptionCategory=='Waiver'">The exemption is granted because the non-compliance state of the resource is temporarily accepted.</span>         
                    <span *ngIf="exemptionDetails.exemptionCategory=='Mitigated'">The exemption is granted because the policyintent is met through another method.</span>         
                </div>
                <div class="form-group">
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="exemptionDateFlag" id="exemptionDateFlag" [(ngModel)]="exemptionDetails.exemptionDateFlag" ><span>The exemption does not expire<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Check to set an expiration date for the exemption" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>              
                </div>
                <div class="form-group" *ngIf="exemptionDetails.exemptionDateFlag">
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                        <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                        <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 8px 4px 0px !important;color: #703501;">
                            Please be aware the policy assignment will take effect again when the policy exemption expires.</label>
                    </div>            
                </div>
                <div class="form-group" *ngIf="exemptionDetails.exemptionDateFlag">
                    <div class="label-div">
                        <label for="expirationDate" class="control-label ">Expiration date<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="To set when a resource hierarchy or an individual 
                            resource is no longer exempt to an assignment" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box" *ngIf="exemptionDetails.exemptionDateFlag">
                        <input type="text" class="form-control" id="expirationDate" [class.border-danger]="inputError?.dateValid||inputError?.dateFormatValid" autocomplete="off" [(ngModel)]="exemptionDetails.expirationDate" placeholder="MM/DD/YYYY" name="expirationDate" required>
                        <p class="text-danger" *ngIf="inputError?.dateValid">The selected date time must be a date in future.</p>
                        <p class="text-danger" *ngIf="inputError?.dateFormatValid">Date format must match 'MM/DD/YYYY' or 'YYYY-MM-DD'.</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="input-box">
                        <input type="text" class="form-control" id="expirationTime" [class.border-danger]="inputError?.timeValid||inputError?.timeFormatValid" autocomplete="off" [(ngModel)]="exemptionDetails.expirationTime" placeholder="h:mm:ss A" name="expirationTime" required>
                        <p class="text-danger" *ngIf="inputError?.timeValid">The selected date time must be a date in future.</p>
                        <p class="text-danger" *ngIf="inputError?.timeFormatValid">Time format must match 'h:mm A'.</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label ">Exemption description<span></span></label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="exemptionDetails.properties.description"  placeholder="" (keyup)="VNetDescription($event,500)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 512 characters.</p>
                    </div>                    
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="createdBy" class="control-label ">Created by</label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="createdBy" autocomplete="off" [(ngModel)]="exemptionDetails.properties.metadata.assignedBy" placeholder="" name="createdBy" required>
                    </div>              
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-outline pull-left " [class.disabled]="!exemptionDetails.name||!exemptionDetails.exemptionCategory" (click)="showExemption=false;">Cancel</button>
                <button class="btn btn-btn-primary" (click)="saveExemption()">Save</button>
                <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualNetwork')" (click)="triggerBtn('update')">Update</button> -->
            </div>
        </div>
    </div>
    </div>
  </div>
  <ng-template #deleteAssignPolicyTemplate>
    <div class="version-history-popup width-426 modal-lg" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool">
            <h3 class="save-version-heading">Delete assignment</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool">
            <div class="text-left" style="padding-top: 8px;padding-bottom: 16px;">
                <label for="description" style="font-size: 14px;" class="desciption-header">Are you sure want to Delete assignment?</label>
            </div>
            <button class="btn btn-danger pull-right" style="margin-left: 8px;" (click)="deleteAssignPolicy();closeModal();">Delete</button>
            <button class="btn btn-outline ml-auto pull-right" (click)="closeModal()">No</button>
        </div>
    </div>
</ng-template>
  <ng-template #deleteInitiativeTemplate>
    <div class="version-history-popup width-426 modal-lg" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool">
            <h3 class="save-version-heading">Delete</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool">
            <div class="text-left" style="padding-top: 8px;padding-bottom: 16px;">
                <label for="description" style="font-size: 14px;" class="desciption-header">Are you sure you want to delete all assignments for this initiative on this subscription?</label>
            </div>
            <button class="btn btn-danger pull-right" style="margin-left: 8px;" (click)="deleteInitiative();closeModal();">Delete</button>
            <button class="btn btn-outline ml-auto pull-right" (click)="closeModal()">No</button>
        </div>
    </div>
</ng-template>
  <ng-template #disableStandardTemplate>
    <div class="version-history-popup width-426 modal-lg" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool">
            <h3 class="save-version-heading">Disable standard</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool">
            <div class="text-left" style="padding-top: 8px;padding-bottom: 16px;">
                <label for="description" style="font-size: 14px;" class="desciption-header">Are you sure you want to disable “name”?</label>
            </div>
            <button class="btn btn-btn-primary pull-right" style="margin-left: 8px;" (click)="disableStandard();closeModal();">Disable</button>
            <button class="btn btn-outline ml-auto pull-right" (click)="closeModal()">No</button>
        </div>
    </div>
</ng-template>
  <ng-template #enableStandardTemplate>
    <div class="version-history-popup width-426 modal-lg" [class.mt-0]="viewVersionHistoryDropdown">
        <div class="modal-header-diagram-tool">
            <h3 class="save-version-heading">Enable standard</h3>
            <button type="button" class="close pull-right margin-heading" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body-diagram-tool">
            <div class="text-left" style="padding-top: 8px;padding-bottom: 16px;">
                <label for="description" style="font-size: 14px;" class="desciption-header">Are you sure you want to enable “name”?</label>
            </div>
            <button class="btn btn-danger pull-right" style="margin-left: 8px;" (click)="enableStandard();closeModal();">Enable</button>
            <button class="btn btn-outline ml-auto pull-right" (click)="closeModal()">No</button>
        </div>
    </div>
</ng-template>