import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { configurations } from '../../assets/constants/config';
@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(   private alertMsg: ToastrService
    ) { }

  log(txt, modifers?: any) {
    if (modifers) {
      console.log(txt, configurations[modifers]);
    }
    else {
      console.log(txt);
    }
  }

  warn(txt){
    console.warn(txt);
  }
  
  consoleError(txt){
    console.error(txt);
  }
  
  success(txt){
    this.alertMsg.success(txt,'',{timeOut:5000,toastClass:'custom-toast-2',closeButton: true});
  }
  error(txt){
    this.alertMsg.error(txt,'',{timeOut:5000,toastClass:'custom-toast-2',closeButton: true});
  }
  info(txt){
    this.alertMsg.info(txt,'',{timeOut:5000,toastClass:'custom-toast-2',closeButton: true});
  }
  warning(txt){
    this.alertMsg.warning(txt);
  }
  clearToast(){
    this.alertMsg.clear();
  }

  table(data){
    console.table(data);
  }
  groupCollapsed(txt,modifers?:any){
    if (modifers) {
      console.groupCollapsed(txt, configurations[modifers]);
    }
    else {
      console.groupCollapsed(txt);
    }
  }
  
  group(txt,modifers?:any){
    if (modifers) {
      console.group(txt, configurations[modifers]);
    }
    else {
      console.group(txt);
    }
  }

  groupEnd(){
    console.groupEnd();
  }

}
