import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infra-migration',
  templateUrl: './infra-migration.component.html',
  styleUrls: ['./infra-migration.component.scss']
})
export class InfraMigrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
