 <div class="introduction">
          <p class="heading" id="2">Approved  </p>
          <ol>
              <li>A3S team will review and modify the architecture diagram as needed based on Azure best practices.  </li>
              <li>A meeting with the user will be scheduled to review the modifications.  </li>
              <li>Once all questions have been answered and User finalizes the diagram by clicking <b>Approve</b> button.  </li>
              <li>Project status is ‘Approved’.  </li>
              <img src="assets\user-manual\apps-&-infra\Approve-button.svg " >
              <figcaption class="caption">Approve button </figcaption>
             
          </ol>
         
            
    
</div>
