<div class="dashboard-top">
    <div class="flex-custom no-wrap">
      <div class="">
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <div class="ml-auto">
        <div class="main-nav">
          <ul class="nav-wrap m-0" >
            <li class="list-item" [class.active]="currentTab == 'overview'" (click)="currentTab = 'overview'">
              <!-- <img  class="mr-2" [src]="currentTab == 'overview'?'/assets/monitor/SAPHANA/overview-active.svg':'/assets/monitor/SAPHANA/overview.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5" y="5" width="4.16667" height="4.16667" rx="1" fill="#8995A2"/>
                <rect x="10.834" y="5" width="4.16667" height="4.16667" rx="1" fill="#8995A2"/>
                <rect x="5" y="10.833" width="4.16667" height="4.16667" rx="1" fill="#8995A2"/>
                <rect x="10.834" y="10.833" width="4.16667" height="4.16667" rx="1" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Overview</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'backup'" (click)="currentTab = 'backup'">
              <!-- <img  class="mr-2" [src]="currentTab == 'backup'?'/assets/monitor/SAPHANA/backup-active.svg':'/assets/monitor/SAPHANA/backup.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.166 9.16699H5.83268L5.83268 10.8337H14.166V9.16699ZM5.83268 7.50033C4.91221 7.50033 4.16602 8.24652 4.16602 9.16699V10.8337C4.16602 11.7541 4.91221 12.5003 5.83268 12.5003H14.166C15.0865 12.5003 15.8327 11.7541 15.8327 10.8337V9.16699C15.8327 8.24652 15.0865 7.50033 14.166 7.50033H5.83268Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.166 12.5003H5.83268V14.167H14.166V12.5003ZM5.83268 10.8337C4.91221 10.8337 4.16602 11.5799 4.16602 12.5003V14.167C4.16602 15.0875 4.91221 15.8337 5.83268 15.8337H14.166C15.0865 15.8337 15.8327 15.0875 15.8327 14.167V12.5003C15.8327 11.5799 15.0865 10.8337 14.166 10.8337H5.83268Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.166 5.83366H5.83268V7.50033H14.166V5.83366ZM5.83268 4.16699C4.91221 4.16699 4.16602 4.91318 4.16602 5.83366V7.50033C4.16602 8.4208 4.91221 9.16699 5.83268 9.16699H14.166C15.0865 9.16699 15.8327 8.4208 15.8327 7.50033V5.83366C15.8327 4.91318 15.0865 4.16699 14.166 4.16699H5.83268Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Backup</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'replication'" (click)="currentTab = 'replication'">
              <!-- <img  class="mr-2" [src]="'/assets/monitor/SAPHANA/replication.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.334 5.83366H5.00065V7.50033H13.334V5.83366ZM5.00065 4.16699C4.08018 4.16699 3.33398 4.91318 3.33398 5.83366V7.50033C3.33398 8.4208 4.08018 9.16699 5.00065 9.16699H13.334C14.2545 9.16699 15.0007 8.4208 15.0007 7.50033V5.83366C15.0007 4.91318 14.2545 4.16699 13.334 4.16699H5.00065Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0007 12.5003H6.66732V14.167H15.0007V12.5003ZM6.66732 10.8337C5.74684 10.8337 5.00065 11.5799 5.00065 12.5003V14.167C5.00065 15.0875 5.74684 15.8337 6.66732 15.8337H15.0007C15.9211 15.8337 16.6673 15.0875 16.6673 14.167V12.5003C16.6673 11.5799 15.9211 10.8337 15.0007 10.8337H6.66732Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Replication</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'dataSize'" (click)="currentTab = 'dataSize'">
              <!-- <img  class="mr-2" [src]="currentTab == 'dataSize'?'/assets/monitor/SAPHANA/data-size-active.svg':'/assets/monitor/SAPHANA/data-size.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 15.834C9.53981 15.834 9.16672 15.4609 9.16672 15.0007V10.834C9.16672 10.3737 9.53981 10.0007 10 10.0007C10.4603 10.0007 10.8334 10.3737 10.8334 10.834V15.0007C10.8334 15.4609 10.4603 15.834 10 15.834Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.65488 15.3458C9.32945 15.0204 9.32945 14.4928 9.65488 14.1673L10.8334 12.9888C11.1588 12.6634 11.6865 12.6634 12.0119 12.9888C12.3373 13.3143 12.3373 13.8419 12.0119 14.1673L10.8334 15.3458C10.508 15.6713 9.98032 15.6713 9.65488 15.3458Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3452 15.3458C10.6707 15.0204 10.6707 14.4928 10.3452 14.1673L9.16673 12.9888C8.84129 12.6634 8.31366 12.6634 7.98822 12.9888C7.66278 13.3143 7.66278 13.8419 7.98822 14.1673L9.16673 15.3458C9.49217 15.6713 10.0198 15.6713 10.3452 15.3458Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4.16699C9.53981 4.16699 9.16672 4.54009 9.16672 5.00033V12.5003C9.16672 12.9606 9.53981 13.3337 10 13.3337C10.4603 13.3337 10.8334 12.9606 10.8334 12.5003V5.00033C10.8334 4.54009 10.4603 4.16699 10 4.16699Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.65488 4.65513C9.32945 4.98057 9.32945 5.50821 9.65488 5.83364L10.8334 7.01216C11.1588 7.33759 11.6865 7.33759 12.0119 7.01216C12.3373 6.68672 12.3373 6.15908 12.0119 5.83364L10.8334 4.65513C10.508 4.3297 9.98032 4.3297 9.65488 4.65513Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3452 4.65513C10.6707 4.98057 10.6707 5.50821 10.3452 5.83364L9.16673 7.01216C8.84129 7.33759 8.31366 7.33759 7.98822 7.01216C7.66278 6.68672 7.66278 6.15908 7.98822 5.83364L9.16673 4.65513C9.49217 4.3297 10.0198 4.3297 10.3452 4.65513Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16602 10.2441C4.16602 10.7043 4.53911 11.0774 4.99935 11.0774H12.4993C12.9596 11.0774 13.3327 10.7043 13.3327 10.2441C13.3327 9.78385 12.9596 9.41076 12.4993 9.41076H4.99935C4.53911 9.41076 4.16602 9.78385 4.16602 10.2441Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.65416 10.5893C4.97959 10.9147 5.50723 10.9147 5.83267 10.5893L7.01118 9.41075C7.33662 9.08531 7.33662 8.55767 7.01118 8.23223C6.68574 7.9068 6.1581 7.9068 5.83267 8.23223L4.65416 9.41075C4.32872 9.73618 4.32872 10.2638 4.65416 10.5893Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.65416 9.8989C4.97959 9.57346 5.50723 9.57346 5.83267 9.8989L7.01118 11.0774C7.33662 11.4028 7.33662 11.9305 7.01118 12.2559C6.68574 12.5814 6.1581 12.5814 5.83267 12.2559L4.65416 11.0774C4.32872 10.752 4.32872 10.2243 4.65416 9.8989Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.832 10.2441C15.832 10.7043 15.4589 11.0774 14.9987 11.0774H7.4987C7.03846 11.0774 6.66537 10.7043 6.66537 10.2441C6.66537 9.78385 7.03846 9.41076 7.4987 9.41076H14.9987C15.4589 9.41076 15.832 9.78385 15.832 10.2441Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3439 10.5893C15.0185 10.9147 14.4908 10.9147 14.1654 10.5893L12.9869 9.41075C12.6614 9.08531 12.6614 8.55767 12.9869 8.23223C13.3123 7.9068 13.8399 7.9068 14.1654 8.23223L15.3439 9.41075C15.6693 9.73618 15.6693 10.2638 15.3439 10.5893Z" fill="#8995A2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3439 9.8989C15.0185 9.57346 14.4908 9.57346 14.1654 9.8989L12.9869 11.0774C12.6614 11.4028 12.6614 11.9305 12.9869 12.2559C13.3123 12.5814 13.8399 12.5814 14.1654 12.2559L15.3439 11.0774C15.6693 10.752 15.6693 10.2243 15.3439 9.8989Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">Data Size</span>
            </li>
            <li class="list-item" [class.active]="currentTab == 'systemCheck'" (click)="currentTab = 'systemCheck'">
              <!-- <img  class="mr-2" [src]="currentTab == 'dataSize'?'/assets/monitor/SAPHANA/sys-Check-active.svg':'/assets/monitor/SAPHANA/sys-Check.svg'" alt=""> -->
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.522 6.1381C15.9362 6.5449 15.9362 7.20445 15.522 7.61124L9.1584 13.8612C8.74421 14.268 8.07267 14.268 7.65848 13.8612L4.47666 10.7362C4.06247 10.3294 4.06247 9.6699 4.47666 9.2631C4.89085 8.85631 5.56239 8.85631 5.97658 9.2631L8.40844 11.6515L14.0221 6.1381C14.4363 5.73131 15.1078 5.73131 15.522 6.1381Z" fill="#8995A2"/>
              </svg>
              <span class="menu-name">System Check</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  <div class="dashboard-main">
   <app-am-sap-hana-overview *ngIf="currentTab == 'overview'"></app-am-sap-hana-overview>
   <app-am-sap-hana-backup *ngIf="currentTab == 'backup'"></app-am-sap-hana-backup>
   <app-am-sap-hana-replication *ngIf="currentTab == 'replication'"></app-am-sap-hana-replication>
   <app-am-sap-hana-data-size *ngIf="currentTab == 'dataSize'"></app-am-sap-hana-data-size>
   <app-am-sap-hana-system-check *ngIf="currentTab == 'systemCheck'"></app-am-sap-hana-system-check>
  </div>