<div class="overview-content">
    <div class="header-container">
        <h2 class="title">Backup</h2>
    </div>

     <!-- Filter Section -->
     <div class="d-flex" [formGroup]="filterForm">
        <div class="custom-filter-wrapper flex-custom" style=" width: 100%;">
            <div class="custom-filter">
                <span class="text">Subscription</span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <div class="enlist-srch">
                        <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                        <img src="assets/icons/search.png">
                    </div>
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="custom-dropdown">
                <ng-select [items]="providerList" *ngIf="providerList.length" [searchable]="false" bindLabel="name"
                  bindValue="name" name="provider" formControlName="provider" [closeOnSelect]="true" [clearable]="false"
                  id="provider" (change)="changeFilter()">
                  <ng-template ng-label-tmp let-item="item">
                    Provider: {{item?.name}}
                  </ng-template>
                </ng-select>
            </div> -->
            <div class="custom-dropdown">
                <ng-select [items]="sidList" *ngIf="sidList.length" [searchable]="false" name="provider" formControlName="sid" [closeOnSelect]="true" [clearable]="false"
                  id="provider" (change)="changeFilter()">
                  <ng-template ng-label-tmp let-item="item">
                    SID: {{item}}
                  </ng-template>
                </ng-select>
            </div>
        </div>
        <ng-select [items]="backupStateList" *ngIf="showOtherTiles" [searchable]="false" style="width: 200px;    padding-right: 12px;" bindLabel="name" bindValue="name" name="backupState" formControlName="backupState" [closeOnSelect]="true" [clearable]="false" id="backupState" (change)="changeBackupState()">
            <ng-template ng-label-tmp let-item="item">
            Backup State: {{item?.name}}
            </ng-template>
        </ng-select>
        <ng-select [items]="timeFilterList" *ngIf="timeFilterList.length" [searchable]="false" bindLabel="name" bindValue="id" [closeOnSelect]="true" (close)="filterForm.value.currentFilter=='custom'?openModal(openCal,filterForm.value.currentFilter):''" name="timeRange" placeholder="Time Range:" style="width: 200px;" (change)="filterForm.value.currentFilter=='custom'?openModal(openCal,filterForm.value.currentFilter):applyFilter(filterForm.value.currentFilter,null)" formControlName="currentFilter" [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
                Time Range: {{item?.name}}
            </ng-template>
        </ng-select>
    </div>
    <div class="row custom-padding">
        <div class="col-md-12 right">
            <div class="graph-div card-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Backup Summary</h4>
                            <p>Refreshed: {{refreshedTimeFormat(backupSummary?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('backupSummary')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables card-table m-0 mt-3" *ngIf="backupSummary?.tableData?.length && !backupSummary?.showLoader">
                  <table class="table" id="backupSummary">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Backup Item</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupSummary',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Backup Status</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupSummary',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Backup Age</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupSummary',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Age of Previous Successful Backup</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupSummary',4)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Number of Failed Backups</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupSummary',0)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of backupSummary.tableData">
                            <tr>
                                <td>
                                    <span class="cursor-pointer" (click)="row.showDetail=!row.showDetail;"> 
                                    <img  [src]="row.showDetail?'assets/compliance/up-arrow.svg':'assets/compliance/down-arrow.svg'" alt="" style="padding-right: 2px;">
                                    {{row.backupItem}}</span>
                                </td>
                                <td>
                                    <img *ngIf="row.backupStatus==='success'" class="sort" src="assets/monitor/SAPHANA/success.svg">
                                    <img *ngIf="row.backupStatus!=='success'" class="sort" src="assets/monitor/SAPHANA/error.svg">
                                </td>
                                <td></td>
                                <td></td>
                                <td>{{row.numFailedBackup}} out of {{row.totalBackup}}</td>
                            </tr>
                            <ng-container *ngIf="row.childData&&row.childData.length&&row.showDetail">
                                <tr  *ngFor="let list of row.childData" class="cursor-pointer">
                                    <td class="backup-item" (click)="viewOtherTiles(row,list)">{{list.backupItem}}</td>
                                    <td (click)="viewOtherTiles(row,list)">
                                        <img *ngIf="list.backupStatus==='success'" class="sort" src="assets/monitor/SAPHANA/success.svg">
                                        <img *ngIf="list.backupStatus!=='success'" class="sort" src="assets/monitor/SAPHANA/error.svg">
                                    </td>
                                    <td (click)="viewOtherTiles(row,list)">{{list.backupAge}}</td>
                                    <td (click)="viewOtherTiles(row,list)">{{list.agePreviousBackup}}</td>
                                    <td (click)="viewOtherTiles(row,list)">{{list.numFailedBackup}} out of {{list.totalBackup}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif custom-loader-gif" *ngIf="backupSummary?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center custom-NF" *ngIf="(!backupSummary?.tableData?.length && !backupSummary?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="showOtherTiles">
        <div class="col-md-6 left padding-div">
            <div class="graph-div backup-chart-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Backup Size (MB)</h4>
                            <p>Refreshed: {{refreshedTimeFormat(backupSize?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('backupSize')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <!-- <div echarts [loading]='true' [options]="backupSize?.options" *ngIf="backupSize?.series?.length  && !backupSize?.showLoader"></div> -->
                <ng-container *ngIf="backupSize?.series?.length && !backupSize?.showLoader">
                    <div echarts [options]="backupSize?.options" [loading]="true"></div>
                    <div class="bot-strip">
                      <div class="str-name">{{backupSize?.legendData[0]?.name}}</div>
                      <div class="str-value">{{backupSize?.valueAvg[0]}} <span>{{backupSize?.valueAvg[1]}}</span></div>
                    </div>
                </ng-container>
                <div class="loader-gif custom-loader-gif" *ngIf="backupSize?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center custom-NF" *ngIf="(!backupSize?.series?.length && !backupSize?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div backup-chart-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Backup Duration (seconds)</h4>
                            <p>Refreshed: {{refreshedTimeFormat(backupDuration?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('backupDuration')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <!-- <div echarts [loading]='true' [options]="backupDuration?.options" *ngIf="backupDuration?.series?.length  && !backupDuration?.showLoader"></div> -->
                <ng-container *ngIf="backupDuration?.series?.length && !backupDuration?.showLoader">
                    <div echarts [options]="backupDuration?.options" [loading]="true"></div>
                    <div class="bot-strip">
                      <div class="str-name">{{backupDuration?.legendData[0]?.name}}</div>
                      <div class="str-value">{{backupDuration?.valueAvg[0]}} <span>{{backupDuration?.valueAvg[1]}}</span></div>
                    </div>
                </ng-container>
                <div class="loader-gif custom-loader-gif" *ngIf="backupDuration?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center custom-NF" *ngIf="(!backupDuration?.series?.length && !backupDuration?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Network Throughput (MB)</h4>
                            <p>Refreshed: {{refreshedTimeFormat(networkThroughput?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('networkThroughput')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <!-- <div echarts [options]="networkThroughput?.options" [loading]="true" *ngIf="networkThroughput?.series?.length && !networkThroughput?.showLoader"></div> -->
                <ng-container *ngIf="networkThroughput?.series?.length && !networkThroughput?.showLoader">
                    <div echarts [options]="networkThroughput?.options" [loading]="true"></div>
                    <div class="d-flex">
                        <div class="bot-strip">
                          <div class="str-name">Avg. network in</div>
                          <div class="str-value">{{networkThroughput?.NIAvg}} <span>MB</span></div>
                        </div>
                        <div class="bot-strip network-out-avg">
                          <div class="str-name">Avg. network out</div>
                          <div class="str-value">{{networkThroughput?.NOAvg}} <span>MB</span></div>
                        </div>
                    </div>
                </ng-container>
                <div class="loader-gif custom-loader-gif" *ngIf="networkThroughput?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!networkThroughput?.series?.length && !networkThroughput?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 right">
            <div class="graph-div mb-4">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Backup Catalog</h4>
                            <p>Refreshed: {{refreshedTimeFormat(backupCatalog?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('backupCatalog')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="backupCatalog?.tableData?.length && !backupCatalog?.showLoader">
                  <table class="table" id="backupCatalog">
                      <thead>
                        <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Status</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',1)">
                                </div>
                            </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Backup type</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Start Time</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>End Time</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Duration</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Backup Size</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Rate</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Message</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Backup ID</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',4)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Number of File</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('backupCatalog',0)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of backupCatalog.tableData">
                            <tr (click)="viewBackupDetails(row)" class="cursor-pointer">
                                <td>
                                    <div class="chip" [ngClass]="getStatusClass(row?.backupStatus)">
                                        <img [src]="getStatusChipImg(row?.backupStatus)">
                                        {{row?.backupStatus | titlecase}}
                                     </div>
                                </td>
                                <td style="color: #0B70D5;" class="elipse-column" [title]="row.backupItem">{{row.backupItem}}</td>
                                <td [title]="(row.startTime | date:'MM/dd/yyyy, hh:mm:ss a')" class="elipse-column">{{row.startTime | date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                                <td class="elipse-column" [title]="(row.endTime | date:'MM/dd/yyyy, hh:mm:ss a')">{{row.endTime | date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                                <td>{{row.duration}}</td>
                                <td>
                                    <div class="progress-div" *ngIf="row.backupSizeVal">
                                        <span>{{row.backupSize}}</span>
                                        <span class="progress w-92 mb-2">
                                            <span class="progress-bar progress-bar-blue" [style.width]="50+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div class="progress-div" *ngIf="row.backupRateVal">
                                        <span>{{row.backupRate}}</span>
                                        <span class="progress w-92 mb-2">
                                            <span class="progress-bar progress-bar-blue" [style.width]="50+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>{{row.message}}</td>
                                <td>{{row.backupId}}</td>
                                <td>{{row.numberOfFile}}</td>
                            </tr>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif custom-loader-gif" *ngIf="backupCatalog?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!backupCatalog?.tableData?.length && !backupCatalog?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="showDetails">
    <div class="sidebar-right single add-alert">
      <div class="header">
        <div class="d-flex">
          <h4>Details</h4>
          <div class="text-right full-width">
            <button type="button" class="close pull-right" aria-label="Close"
              (click)="showDetails = false;">
              <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
            </button>
          </div>
        </div>
      </div>
  
      <div class="body scroll-y">
        <form [formGroup]="backupDetailForm">
          <div class="mb-3">
            <label class="flex-custom nowrap">status</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="backupStatus" #status>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(status)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">backup_type</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="backupItem" #backuptype>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(backuptype)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">database</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="database" #database>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(database)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">start_time_utc</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="startTime" #starttime>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(starttime)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">end_time_utc</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="endTime" #endtime>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(endtime)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">duration_hms</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="duration" #duration>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(duration)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">backup_size_bytes</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="backupSize" #backupSize>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(backupSize)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">backup_rate_kbps</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="backupRate" #backupRate>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(backupRate)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">message</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="message" #message>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(message)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">backup_id</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="backupId" #backupId>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(backupId)">
            </div>
          </div>
          <div class="mb-3">
            <label class="flex-custom nowrap">number_of_files</label>
            <div class="form-group d-flex">
                <input class="form-control full-width" type="text" readonly formControlName="numberOfFile" #numberOfFile>
                <img src="assets/monitor/SAPHANA/copy.svg" alt="Copy" class="cursor-pointer" style="padding-left: 10px;" (click)="copyInputMessage(numberOfFile)">
            </div>
          </div>
        </form>
      </div>
    </div>
</ng-container> 
  
<ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>