import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-architecture-resources',
  templateUrl: './architecture-resources.component.html',
  styleUrls: ['./architecture-resources.component.scss']
})
export class ArchitectureResourcesComponent implements OnInit {
  @Input() projectData : any;
  @Input() resourceName : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  submit(event){     
    this.triggerBtnvalue.emit(event);
  }

}
