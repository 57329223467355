<kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
    <div class='content vnet-resource-content'>
        <div class="rgPopup-form">
            <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
            <div class="input-box">
            <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
            <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
            <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
            <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
            </div>              
        </div>
        <div class="rgPopup-form">
            <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
            <div class="input-box">
            <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
            </ng-select>
            </div>
        </div>
        <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
            You don't have the access to create a new resource group for this subscription
        </div>
        <div class="d-flex">
            <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
            <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
            <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
        </div>
    </div>
</kendo-popup>
<div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':applicationInsightmodel.lawShow }">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Key Vault</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="keyVaultmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div>  
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                    </p>
                </div>
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="name" class="control-label">Key vault name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Vault name must only contain alphanumeric characters and dashes and cannot start with a number." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="keyVaultmodel.resourceName" placeholder="Enter a name" name="name" (keyup)="laNameValidation($event,3,24);lawDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.laname">Vault name must be between 3 and 24 characters in length.</p>
                    <p class="text-danger" *ngIf="inputError?.laSpecialChar">Vault name must only contain alphanumeric characters and dashes and cannot start with a number. </p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Vault name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateLaw">This name is already in use, or is still being reserved by a vault which was previously soft deleted. Please use a different name.</p>
                    </div>         
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="pricingTier" class="control-label">Pricing tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure Key Vault service is offered in two service tiers: standard and premium. Premium include support for HSM backed keys." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="pricingTier" [value]="'Standard'" ([ngModel])="keyVaultmodel.resourceData.pricingTier" [checked]="keyVaultmodel.resourceData.pricingTier=='Standard'" (change)="keyVaultmodel.resourceData.pricingTier=$event.target.value;" ><span>Standard</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="pricingTier" [value]="'Premium'" ([ngModel])="keyVaultmodel.resourceData.pricingTier" [checked]="keyVaultmodel.resourceData.pricingTier=='Premium'" (change)="keyVaultmodel.resourceData.pricingTier=$event.target.value;"><span>Premium</span></label>                    
                </div> 
            </div>
        
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="keyVaultmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 

            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
    <div *ngIf="showView">

        <div class="form-group">
        <div class="label-div">
            <label for="keys" class="control-label">Keys</label> 
        </div>
        <div class="add-another-tag">
            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="keyVaultmodel.keysShow=true;">Add key</p>             
        </div>  
        </div>

        <div class="form-group">
        <div class="label-div">
            <label for="secrets" class="control-label">Secrets</label> 
        </div>
        <div class="add-another-tag">
            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="keyVaultmodel.secretsShow=true;">Add secret</p>             
        </div>  
        </div>
    
        <div class="form-group">
        <div class="label-div">
            <label for="recovery" class="load">Recovery options</label>
            <label for="enableSoftDelete" class="control-label">Soft-delete<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The ability to turn off soft delete via the Azure Portal has been deprecated. You can create a new key vault with soft delete off for a limited time using 
                CLI / PowerShell / REST API. The ability to create a key vault with soft delete 
                disabled will be fully deprecated by the end of the year." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            <label for="enableSoftDeleteStatus" class="control-label">Enabled</label>           
        </div>
        </div>

        <div class="form-group">
        <div class="input-box">
                <div class="label-div">
                    <label for="softDeleteRetentionInDays" class="control-label col-sm-2">Days to retain deleted vaults<span class="mandatory"><img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip=" It can be configured to between 7 to 90 days. Once it has been set, it cannot be changed or removed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.restoredays" id="restoredays" autocomplete="off" [(ngModel)]="keyVaultmodel.resourceData.softDeleteRetentionInDays" placeholder="" name="softDeleteRetentionInDays" (keyup)="changeDaysRestore($event,'restoredays')" required>
                    <!-- <p class="text-danger" *ngIf="inputError?.subnetAdrspc">This value must be less than the value (90) that's set for blob soft delete.</p> -->
                    <p class="text-danger" *ngIf="inputError?.restoredays">The value must be between 7 and 90.</p>
                </div>              
        </div>
    </div>

    <div class="form-group" style="margin-bottom: 10px !important;">
        <div class="label-div">
            <label for="enablePurgeProtection" class="control-label ">Purge protection<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enabling purge protection on a key vault is an irreversible action. Once the purge protection property has been set to true, it cannot be changed or removed" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box radio-flex">
            <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="enablePurgeProtection" [value]="'disablePurgeProtection'" ([ngModel])="keyVaultmodel.resourceData.enablePurgeProtection" [checked]="keyVaultmodel.resourceData.enablePurgeProtection=='disablePurgeProtection'" (change)="keyVaultmodel.resourceData.enablePurgeProtection=$event.target.value;"><span><b>Disable purge protection</b></span></label>
            <label class="radio-label" style="padding-left: 19px;">Allow key vault and objects to be purged during retention period.</label>
            <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="enablePurgeProtection" [value]="'enablePurgeProtection'" ([ngModel])="keyVaultmodel.resourceData.enablePurgeProtection" [checked]="keyVaultmodel.resourceData.enablePurgeProtection=='enablePurgeProtection'" (change)="keyVaultmodel.resourceData.enablePurgeProtection=$event.target.value;"><span><b>Enable purge protection</b></span></label>                    
            <label class="radio-label" style="padding-left: 19px;">Enforce a mandatory retention period for deleted vaults and vault objects.</label>
        </div>             
    </div>

    <div class="form-group">
        <div class="label-div">
            <label for="accessPolicy" class="load">Access policy</label>
            <label for="enableAccessTo" class="control-label">Enable access to:</label>
        </div>
        <div class="input-box">
            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enabledForDeployment" id="enabledForDeployment" [(ngModel)]="keyVaultmodel.resourceData.enabledForDeployment" ><span>Azure Virtual Machines for deployment<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specifies whether Azure Virtual Machines are permitted to retrieve certificates stored as secrets from the key vault." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enabledForTemplateDeployment" id="enabledForTemplateDeployment" [(ngModel)]="keyVaultmodel.resourceData.enabledForTemplateDeployment" ><span>Azure Resource Manager for template deployment<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specifies whether Azure Resource Manager is permitted to retrieve secrets from the key vault." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enabledForDiskEncryption" id="enabledForDiskEncryption" [(ngModel)]="keyVaultmodel.resourceData.enabledForDiskEncryption"><span>Azure Disk Encryption for volume encryption<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specifies whether Azure Disk Encryption is permitted to retrieve secrets from the vault and unwrap keys." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
    </div>

    <div class="form-group" style="margin-bottom: 10px !important;">
        <div class="label-div">
            <label for="permissionModel" class="control-label ">Permission model</label>
        </div>
        <div class="input-box radio-flex">
            <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="enableRbacAuthorization" [value]="'vaultAccessPolicy'" ([ngModel])="keyVaultmodel.resourceData.enableRbacAuthorization" [checked]="keyVaultmodel.resourceData.enableRbacAuthorization=='vaultAccessPolicy'" (change)="keyVaultmodel.resourceData.enableRbacAuthorization=$event.target.value"><span>Vault access policy</span></label>
            <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="enableRbacAuthorization" [value]="'enablePurgeProtection'" ([ngModel])="keyVaultmodel.resourceData.enableRbacAuthorization" [checked]="keyVaultmodel.resourceData.enableRbacAuthorization=='enablePurgeProtection'" (change)="keyVaultmodel.resourceData.enableRbacAuthorization=$event.target.value"><span>Enable purge protection</span></label>
        </div>             
    </div>

    <div class="form-group">
        <div class="label-div">
            <label for="currentAccessPolicy" class="load">Current access policies</label>
            <label for="user" class="control-label">user</label>
            <label for="userValue" class="load">A3S Team</label>
        </div>
        <div class="add-another-tag">
            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="keyVaultmodel.accessPoliciesShow=true;">Add Access Policy</p>             
        </div>
    </div>
    
                
                <div class="form-group">
                    <div class="label-div d-flex">
                        <label class="control-label " style="min-width: max-content;">Tag name </label>
                        <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                        </label>
                        <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="keyVaultmodel.resourceData&&keyVaultmodel.resourceData.tagList&&keyVaultmodel.resourceData.tagList.length>1"></label>
                        <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                    </div>
                    <div *ngFor="let item of keyVaultmodel.resourceData.tagList;let i = index">
                        <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
    
                        <div class="input-box" style="padding-bottom: 6px;">
                            <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
    
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
    
                        <div class="input-box">
                            <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
    
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="keyVaultmodel.resourceData.tagList.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                        <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                        <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                        <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
    
                    <div class="add-another-tag">
                        <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                    </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('keyVault')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('keyVault')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>

<div class="sidebar-right" *ngIf="applicationInsightmodel.lawShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Log Analytics Workspace</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="applicationInsightmodel.lawShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
               
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="logAnalytics" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace name should include 4-63 letters, digits or '-'. The '-' shouldn't be the first or the last symbol." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="lawObj.name" placeholder="Enter a workspace name" name="logAnalytics" (keyup)="laNameValidation($event,4,63);lawDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.laname">The workspace name must be between 4 and 63 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.laSpecialChar">The workspace name can contain only letters, numbers and '-'. The '-' shouldn't be the first or the last symbol. </p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Workspace name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateLaw">Workspace name must be unique in the current resource group.</p>
                    </div>              
                </div>

                
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('lawObj')" (click)="saveSubSideSheet('lawObj')">Save</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('lawObj')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
</div>