import { Component, HostListener, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    dashboardRoutes: any[] = [];
    isApplicationLoaded: any = false;
    favouriteList: any;

    @HostListener('document:click', ['$event'])
    DocumentClick(event: Event) {
        var $target = $(event.target);
        this.dashboardRoutes.forEach(e=>{
            e.contentList.forEach(x=> {
                let element = document.getElementById(`${x.route}-home`)
                if(!$target.closest(element).length && !$target.closest("#no-access-tooltip").length){
                   x.restrictAccess = false
                }
            })
        })
    }

    constructor(private utilService: UtilService) { }

    async ngOnInit() {
        // adding the static content 
        this.dashboardRoutes = _.cloneDeep(this.utilService.getStatic("dashboard_home_scree_routes"));
    
        this.utilService.getObservable().subscribe((res) => {
            if(res.isApplicationLoaded){
                this.isApplicationLoaded = res.isApplicationLoaded
                console.log('isApplicationLoaded',res.isApplicationLoaded)
            }
        })       

        await this.getResourceTypeStaticData()
        this.getAllFavourites()
        document.body.className = "selector"
    }
    
    ngOnDestroy(){
        document.body.className="";
      }

    routeToFavourites(){
      this.utilService.route(['a3s/favourites'])
    }

    routeToActivities(){
        this.utilService.route(['a3s/activities'])
    }

    routeTo(route){
        this.utilService.route([route])
    }
    
    getResourceTypeStaticData(){
        return this.utilService.handleRequest('get','a3s_static_getByTable',['dbo','resourceTypes'],null,null,null,true).then(res=>{
            if(!res || !res.recordset.length) return;
            this.utilService.setCache('resourceTypeList',res.recordset)
        })
    }

    routeToDashboard(route,list) { // !! route guard and protection!
        let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
        if(data && data.scope.some(x=> x.bucketId == list.bucketId)) list['restrictAccess'] = false
        else return list['restrictAccess'] = true;
        if(route == 'a3s/applicationMonitorBucket'){
            let applicationMonitorMenu= _.cloneDeep(this.utilService.getStatic('applicationMonitorMenus'));
            applicationMonitorMenu.map(dt=>{
            dt.projectList=[];
            return dt;
            })
            let tempList : any[]= this.utilService.getCache('deployedBlueprintProjectList');
            tempList = tempList.filter(e=> e.subscriptionId && e.status == 'DEPLOYED')
            Promise.all(tempList.map(async e=>{
                // let data = e.isManual ? await this.getAllResources(e) : await this.getProjectResource(e.projectId)
                let data;
                if(!e.isManual || (e.isManual && e.isSpecificResource)){
                    data = await this.getProjectResource(e.projectId)
                }
                else if(e.isManual){
                    data = await this.getAllResources(e)
                }
                e['resourceData'] = data
                return {projectData:e,resourceData:data}
            })).then(async final=>{
                await Promise.all(final.map(async e=>{
                    let filter = e.resourceData.filter(e=> this.filterResourceTypes(e))
                    if(filter.length) {                        
                        applicationMonitorMenu.map(dt=>{
                            if(dt.route!='/a3s/applicationMonitorBucket/saphana' && dt.route!='/a3s/applicationMonitorBucket/sapNetWeaver'){
                                dt.projectList.push(e.projectData);
                            }
                            return dt;
                        })
                    }
                    let providerList : any[]=this.utilService.getCache('masterProviderList');
                    let ipData : any= providerList.filter(x=> x.properties.providerSettings.providerType=='SapHana').map(xe=> `'${xe.properties.providerSettings.hostname}'`)
                    let ipResponse;
                    if(ipData.length){
                        ipData = ipData.join(',')
                        let body ={ ipAddr : ipData }
                        ipResponse = await this.utilService.handleRequest('post','a3s_applicationHealthMonitor_getVMDetailsByGraphQuery',[],body,null,null,true)
                    }
                    await Promise.all(providerList.map(async xe=>{
                        if(xe.properties.providerSettings.providerType=='SapNetWeaver'){
                            xe['resourceName'] = xe.properties.providerSettings.sapHostname.split('.')[0]
                        }
                        else if(xe.properties.providerSettings.providerType=='SapHana'){
                            let filterData = ipResponse.body.data.filter(x=> x.privateIP == xe.properties.providerSettings.hostname)
                            xe['resourceName'] = filterData.length ? filterData[0].virtualMachine.split('/')[filterData[0].virtualMachine.split('/').length - 1] : null
                        }
                    }))
                    providerList = providerList.filter(ex=> e.resourceData.some(x=> x.resourceName.toLowerCase() == ex.resourceName.toLowerCase()))
                    this.utilService.setCache('providerList',providerList)
                    if(providerList&&providerList.length&&e.resourceData.find(e=> this.filterSAPResourceTypes(e))) {
                        if(providerList.filter(dt=>dt.properties&&dt.properties.providerSettings.providerType=='SapNetWeaver').length) {
                            applicationMonitorMenu.map(dt=>{
                                if(dt.route=='/a3s/applicationMonitorBucket/sapNetWeaver'){
                                    dt.projectList.push(e.projectData);
                                }
                                return dt;
                            })
                        } 
                        if(providerList.filter(dt=>dt.properties&&dt.properties.providerSettings.providerType=='SapHana').length) {
                            applicationMonitorMenu.map(dt=>{
                                if(dt.route=='/a3s/applicationMonitorBucket/saphana'){
                                    dt.projectList.push(e.projectData);
                                }
                                return dt;
                            })
                        }
                    }
                }))
                  applicationMonitorMenu=applicationMonitorMenu.filter(dt=>dt.projectList.length);
                  this.utilService.setCache('applicationMonitorMenus',applicationMonitorMenu);
                  applicationMonitorMenu.length ? this.utilService.route([applicationMonitorMenu[0].route]) : this.utilService.showSimpleTemplateModal('appHealthAccessDenied')
            },err=>{
                this.utilService.showSimpleTemplateModal('appHealthAccessDenied')
            })
        }
        else{
            this.utilService.route([route])
        }
    }

    async getAllResources(list){
        let resources = await this.utilService.handleRequest('get','a3s_management_getResourceTypes',[list.subscriptionId],null,null,null,true)
        let types = this.utilService.getCache('resourceTypeList')
        var array = []
        types.forEach(x=>{
          resources.body.value.forEach(e=>{
             if(x.azureType.toLowerCase() == e.type.toLowerCase()){
              e['resourceName']= e.name
              e['resourceType'] =x.formattedName
              array.push(e)
             }
          })
        })
        return array;
    }
    
    getProjectResource(id){
        return this.utilService.handleRequest('get','a3s_architectureBlueprint_getProjectResourceListForDashboards',[id],null,null,null,true)
     }

    filterResourceTypes(list){
        let name = list['resourceType'].replace(/\s/g, '').toLowerCase()
        let includedList = ['webapp','appservices']
        return includedList.indexOf(name) > -1 ? true : false
    }

    filterSAPResourceTypes(list){
        let name = list['resourceType'].replace(/\s/g, '').toLowerCase()
        let includedList = ['azuremonitorforsapsolutions']
        return includedList.indexOf(name) > -1 ? true : false
      }

    getAllFavourites(){
        let email = localStorage.getItem('email')
        this.utilService.handleRequest('get','a3s_favourites_all',[email],null,null,null,true).then(res=>{
            let data = res.recordset
            if(!data.length) return;
            this.favouriteList = JSON.parse(data[0].favourites)
            this.favouriteList = this.favouriteList.splice(0,5)
            console.log('favouriteList',this.favouriteList)
        })
      }

}
