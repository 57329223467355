<div class="security-recommednation-container">
    <div class="header-container">
      <h2 class="title">Activities</h2>
      <!-- <span>{{currentProject?.projectName}}</span> -->
    </div>
   
     <!-- Filter Section -->
     <div class="custom-filter-wrapper flex-custom">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                        <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                        <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                        <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                        <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button>
    </div>
    
    <div class="dashboard-body">
        <div class="row">
            <div class="col-md-12 padding-div">
              <div class="graph-div" style="padding-right: 12px;">
                  <div class="heading">
                      <div class="d-flex">
                          <div class="about">
                              <h4>Recent Activities</h4>
                              <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.activityOperations)}} . {{getTimeFilterVal(activityOperations)}}</p>
                          </div>
                          <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('activityOperations')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('Recent Activities','tableRA')"><img src="assets/security/download_icon.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter4')"><img src="/assets/security/clock.svg" alt=""></span>
                                <div class="cal-box"  id="customFilter4">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <ng-container *ngFor="let row of timeFilterList">
                                            <div class="select-item" [class.active]="activityOperations?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,activityOperations):applyFilter(row.id,activityOperations)">{{row.name}}</div>
                                        </ng-container>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                      </div>
                  </div>
                  <div class="custom-fixed-tables m-0 mt-3" *ngIf="activityOperations?.tableData?.length && !activityOperations?.showLoader">
                    <table class="table" id="tableRA">
                        <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activityOperations','displayNameControl')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Resource group</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activityOperations','max')">
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Resource ID</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activityOperations','current')">
                                </div>
                            </th>
                            <th class="borderBlueRight">
                              <div class="flex-custom no-wrap">
                                  <span>Activity Count</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activityOperations','resourceHealthSSD')">
                              </div>
                          </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of activityOperations.tableData">
                              <td [title]="row[0]">{{row[0]}}</td>
                              <td>{{row[1]}}</td>
                              <td [title]="row[2]">{{row[2]}}</td>
                              <td class="count-cl-width">{{row[3]}}</td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="loader-gif" *ngIf="activityOperations?.showLoader">
                      <img src="assets/icons/blue-loader.gif"/>
                      <p>Getting data...</p>
                  </div>
                  <div class="dash-text-center" *ngIf="(!activityOperations?.tableData?.length && !activityOperations?.showLoader)">
                      <img src="/assets/health-monitoring/no-data.svg" alt="">
                      <p class="text-14-22 bold-600 mt-3">No data found</p>
                  </div>
              </div>
          </div>
          </div>
      <div class="row">
        <div class="col-md-6 padding-div">
            <div class="graph-div">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Continuous Activities Performed by Users</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.portOpenedAzure)}} . {{getTimeFilterVal(portOpenedAzure)}}</p>
                      </div> 
                      <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('portOpenedAzure')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="portOpenedAzure?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,portOpenedAzure):applyFilter(row.id,portOpenedAzure)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                      </div>                  
                  </div>                
              </div>
              <app-stacked-common-time-line-chart [data]='portOpenedAzure' *ngIf="portOpenedAzure?.series?.length && !portOpenedAzure?.showLoader"></app-stacked-common-time-line-chart>
              <div class="loader-gif" *ngIf="portOpenedAzure?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="!portOpenedAzure?.series?.length && !portOpenedAzure?.showLoader">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No data found</p>
              </div>
          </div>
        </div>
        <div class="col-md-6 padding-div">
          <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Key Vault Callers</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.keyVaultsOption)}} . Top 10  . {{getTimeFilterVal(keyVaultsData)}}</p>
                        </div> 
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('keyVaultsOption')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(keyVaultPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/security/clock.svg" alt=""></span>
                                <div class="cal-box"  id="customFilter1">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <ng-container *ngFor="let row of timeFilterList">
                                            <div class="select-item" [class.active]="keyVaultsData?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,keyVaultsData):applyFilter(row.id,keyVaultsData)">{{row.name}}</div>
                                        </ng-container>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>                  
                    </div>                
                </div>
                <!-- <app-bar-chart [data]='keyVaultsOption' *ngIf="keyVaultsOption.data?.length && !keyVaultsOption?.showLoader"></app-bar-chart> -->
                <div echarts [loading]='true' [options]="keyVaultsOption" *ngIf="keyVaultsData.data?.length && !keyVaultsData?.showLoader"></div>
                <div class="loader-gif" *ngIf="keyVaultsData?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!keyVaultsData?.data?.length && !keyVaultsData?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 padding-div">
          <div class="graph-div" style="padding-right: 12px;">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Identity who Granted Permissions</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.grantingPermissions)}} . Top 5  . {{getTimeFilterVal(grantingPermissions)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('grantingPermissions')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('Identity who Granted Permissions','tableIdentity')"><img src="assets/security/download_icon.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter2')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter2">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="grantingPermissions?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,grantingPermissions):applyFilter(row.id,grantingPermissions)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="grantingPermissions?.tableData?.length && !grantingPermissions?.showLoader">
                <table class="table" id="tableIdentity">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Identity</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','displayNameControl')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Caller IP address</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','max')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Start time (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','current')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>End time (UTC)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','resourceHealthSSD')">
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of grantingPermissions.tableData">
                          <td [title]="row[0]">{{row[0]}}</td>
                          <td>{{row[1]}}</td>
                          <td>{{row[2]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                          <td>{{row[3]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="loader-gif" *ngIf="grantingPermissions?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!grantingPermissions?.tableData?.length && !grantingPermissions?.showLoader)">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No data found</p>
              </div>
          </div>
      </div>
      <div class="col-md-12 padding-div">
        <div class="graph-div" style="padding-right: 12px;">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Azure Resource With Opened Ports</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.portOpenedT)}}  . {{getTimeFilterVal(portOpenedT)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('portOpenedT')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('Azure Resource With Opened Ports','tablePorts')"><img src="assets/security/download_icon.svg" alt=""></span>
                      <div class="cal-wrap">
                        <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                        matTooltipShowDelay="500" (click)="openFilter('customFilter3')"><img src="/assets/security/clock.svg" alt=""></span>
                        <div class="cal-box"  id="customFilter3" [ngClass]="{'custom-filter-top':!portOpenedT?.tableData?.length}">
                            <div class="cal-inner">
                            <div class="select-sec">
                                <ng-container *ngFor="let row of timeFilterList">
                                    <div class="select-item" [class.active]="portOpenedT?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,portOpenedT):applyFilter(row.id,portOpenedT)">{{row.name}}</div>
                                </ng-container>
                            </div>
                            </div>
                        </div>
                    </div>
                  </div> 
                </div>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="portOpenedT?.tableData?.length && !portOpenedT?.showLoader">
              <table class="table" id="tablePorts">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Time generated (UTC)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','displayNameControl')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','max')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource group</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','current')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Caller IP address</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','PSI')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Caller</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','PSI')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Destination port range</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','PSI')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Direction</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','PSI')">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Protocol</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','PSI')">
                          </div>
                      </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Source port Range</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('secureScoreData','resourceHealthSSD')">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of portOpenedT.tableData">
                        <td>{{row[0]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        <td [title]="row[1]">{{row[1]}}</td>
                        <td>{{row[2]}}</td>
                        <td>{{row[3]}}</td>
                        <td [title]="row[4]">{{row[4]}}</td>
                        <td>{{row[5]}}</td>
                        <td>{{row[6]}}</td>
                        <td>{{row[7]}}</td>
                        <td>{{row[8]}}</td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="loader-gif" *ngIf="portOpenedT?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!portOpenedT?.tableData?.length && !portOpenedT?.showLoader)">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
        </div>
    </div>
      </div>
    </div>
  </div>

  <ng-template #keyVaultPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Key Vault Callers</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #tableId>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Caller IP address</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('keyVaultsData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Resource</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('keyVaultsData','subscriptionId')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Operation name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('keyVaultsData','subscriptionId')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Status code</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('keyVaultsData','subscriptionId')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('keyVaultsData','status')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of keyVaultsData.tableData">
                          <td>{{row[0]}}</td>
                          <td>{{row[1]}}</td>
                          <td>{{row[2]}}</td>
                          <td>{{row[3]}}</td>
                          <td>{{row[5]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!keyVaultsData?.tableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Key Vault Callers',tableId)" *ngIf="keyVaultsData.tableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>