
   
<div class="home-body" [ngClass]="{'background-image':isApplicationLoaded == true}">

    <div class="home-content">
      

    <ng-container *ngIf="!isApplicationLoaded">
    
        <div class="upper-section">
          <div class="tile-section" *ngFor="let item of dashboardRoutes">
            <ngx-skeleton-loader [theme]="{width: '200px','border-radius': '32px',height: '18px','background-color': '#EAF7FF'}"></ngx-skeleton-loader>
            <div class="row">
              <div [class]="'col-xl-'+item.xlargeColVal+' col-lg-'+item.largeColVal+' col-md-6 col-sm-6'"  *ngFor="let dashboard of item.contentList;let i=index;">
                <div class="tile-loader">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px' ,'background-color': '#EAF7FF'}"></ngx-skeleton-loader>
                  <h3 class="m-0">
                    <ngx-skeleton-loader [theme]="{width: '200px','border-radius': '32px',height: '18px','background-color': '#EAF7FF'}"></ngx-skeleton-loader>
                  </h3>
                  <p class="m-0">
                    <ngx-skeleton-loader [theme]="{width: '100%','border-radius': '32px',height: '12px','background-color': '#EAF7FF'}"></ngx-skeleton-loader>
                  </p>
                  <p class="m-0">
                    <ngx-skeleton-loader [theme]="{width: '100%','border-radius': '32px',height: '12px','background-color': '#EAF7FF'}"></ngx-skeleton-loader>
                  </p>
                  <p class="m-0">
                    <ngx-skeleton-loader [theme]="{width: '100%','border-radius': '32px',height: '12px','background-color': '#EAF7FF'}"></ngx-skeleton-loader>
                  </p>
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <div class="lower-half">
          <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="lower-tile">
                  <div class="lower-heading">
                    <ngx-skeleton-loader [theme]="{
                      width: '200px',
                      'border-radius': '32px',
                      height: '18px',
                      'background-color': '#EAF7FF'
                    }"></ngx-skeleton-loader>
                  </div>
                  <div class="recent-body loader" >
                    <div class="image">
                      <ngx-skeleton-loader appearance="circle" [theme]="{ width: '72px', height: '72px','background-color': '#EAF7FF' }">
                      </ngx-skeleton-loader>
                    </div>
                    
                    <ngx-skeleton-loader [theme]="{
                      width: '180px',
                      'border-radius': '32px',
                      height: '18px',
                      'background-color': '#EAF7FF'
                    }"></ngx-skeleton-loader>
                    <div class="sec-p">
                      <ngx-skeleton-loader [theme]="{
                        width: '100px',
                        'border-radius': '32px',
                        height: '18px',
                        'background-color': '#EAF7FF'
                      }"></ngx-skeleton-loader>
                    </div>                      
                  </div>
                </div>                  
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="lower-tile">
                  <div class="lower-heading">
                    <ngx-skeleton-loader [theme]="{
                      width: '200px',
                      'border-radius': '32px',
                      height: '18px',
                      'background-color': '#EAF7FF'
                    }"></ngx-skeleton-loader>
                  </div>
                  <div class="favorite-body loader" >
                      <div class="image">
                        <ngx-skeleton-loader appearance="circle" [theme]="{ width: '72px', height: '72px','background-color': '#EAF7FF' }">
                        </ngx-skeleton-loader>
                      </div>
                      <ngx-skeleton-loader [theme]="{
                        width: '180px',
                        'border-radius': '32px',
                        height: '18px',
                        'background-color': '#EAF7FF'
                      }"></ngx-skeleton-loader>
                      <div class="fav-sec-p">
                        <ngx-skeleton-loader [theme]="{
                          width: '250px',
                          'border-radius': '32px',
                          height: '18px',
                          'background-color': '#EAF7FF'
                        }"></ngx-skeleton-loader>
                      </div>
                      <ngx-skeleton-loader [theme]="{
                        width: '160px',
                        'border-radius': '32px',
                        height: '18px',
                        'background-color': '#EAF7FF'
                      }"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </ng-container>
    

      <ng-container *ngIf="isApplicationLoaded">

        <div class="upper-section">
          <div class="tile-section" *ngFor="let item of dashboardRoutes">
            <h2 class="heading">{{item.groupName}}</h2>
            <div class="row">
              <ng-container *ngFor="let dashboard of item.contentList;let i=index;">
                <div [class]="'col-xl-'+item.xlargeColVal+' col-lg-'+item.largeColVal+' col-md-6 col-sm-6'">
                  <div class="tile" (click)="routeToDashboard(dashboard.route,dashboard)" id="{{dashboard.route}}-home">
                    <div class ="dashboard-image  flex-custom ">
                      <h3 class="title">{{dashboard.Name}}</h3>
                      <img class="ml-auto"  [src]="dashboard.img" [alt]="dashboard.Name">
                    </div>
                    <p class="content">{{dashboard.content}}</p>
                  </div>
                  <div class="no-access-tooltip-wrapper" *ngIf="dashboard?.restrictAccess" id="no-access-tooltip">
                    <div class="left-pane">
                      <img src="./assets/common-icons/no-access-info.svg">
                    </div>
                    <div class="right-pane">
                      <div class="content">
                        <span>You don’t have access to this bucket</span>
                        <p>To get access please contant your 
                          administrator.</p>
                      </div>
                      <img class="cursor-pointer" src="assets/Infra/blueprint/close.svg" (click)="dashboard.restrictAccess = false">
                    </div>
                  </div>
                </div>
              </ng-container>
             </div>
          </div>
        </div>
    

        <div class="lower-half">
          <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="lower-tile">
                  <div class="lower-heading flex-custom no-wrap">
                    <div class="title">Recent Activity</div>
                    <span class="blue-text text-14-22 ml-auto" (click)="routeToActivities()">View all</span>
                  </div>
                  <div class="recent-body" >
                    <div class="image"><img src="assets/icons/recent.svg" alt=""></div>
                    <p class="p-14">No activities yet</p>
                    <div class="sec-p">
                      <p>Once you start using A3S services, your recent activity will be shown here</p>
                    </div>                      
                  </div>
                </div>                  
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="lower-tile">
                  <div class="lower-heading flex-custom no-wrap">
                    <div class="title">Favourites</div>
                    <span class="blue-text text-14-22 ml-auto" (click)="routeToFavourites()">Edit</span>
                  </div>
                 
                  <div class="favorite-body">
                    <ng-container *ngIf="!favouriteList?.length">
                      <div class="image"><img src="assets/icons/favorites.svg" alt=""></div>
                      <p class="p-14">No favourites yet</p>
                      <div class="fav-sec-p">
                        <p>Your favourite services will be shown here</p>
                      </div>
                      <button class="btn btn-outline" (click)="routeToFavourites()">Choose Favourites </button> 
                    </ng-container>
                    <div class="list" *ngFor="let list of favouriteList" (click)="routeTo(list?.route)">
                      <span class="blue-text">{{list?.name}}</span>
                      <span class="blur-text">&nbsp;({{list?.bucketName}})</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </ng-container>

    </div>
</div>





