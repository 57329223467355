import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-region-chart',
  template: `<div echarts [loading]='true' [options]="options" style="height:300px"></div>`,
})
export class RegionChartComponent implements OnChanges {
  options: any = {};
  @Input('data') data: any ={};
  constructor() { }

  ngOnChanges() {
    console.log(this.data);
    var data = this.data.data
    var legendData = this.data.legendData
    var color = this.data.color
    var total = this.data.total
    this.options = {
      color: color,
      textStyle: {
        fontFamily: 'Inter',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ',
        padding: [10],
        textStyle: {
          fontSize: '11',
        },
      },
      legend: {
          bottom: 24,
          left: 8,
          right: 24,
          type: 'scroll',
          data: legendData || []
      },
      series: [
          {
            name: 'Regions',
            type: 'pie',
            radius: ['62%', '100%'],
            // center: ['50%', '50%'],
            top: 10,
            bottom: 67,
            avoidLabelOverlap: true,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              color: '#333B43',
              position: 'center',
              formatter: function (params){
                return `{a|${total}}` + '\n' + '{b|Total}'; // Use sum variable here
              },
              rich: {
                a: {
                    lineHeight: 45,
                    fontSize:45,
                    fontFamily: 'Inter'
                },
                b:{
                  lineHeight: 12,
                  fontSize:12,
                  fontFamily: 'Inter'
                }
              }
            },
            labelLine: {
              show: true,
            },
              data: data
          }
      ]
  };
  }

}
