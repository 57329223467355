import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usage-overall-retentions',
  templateUrl: './usage-overall-retentions.component.html',
  styleUrls: ['./usage-overall-retentions.component.scss']
})
export class UsageOverallRetentionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
