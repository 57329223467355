import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-azure-bastion-actions',
  templateUrl: './azure-bastion-actions.component.html',
  styleUrls: ['./azure-bastion-actions.component.scss']
})
export class AzureBastionActionsComponent implements OnInit {
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("bastionDetailsTemplate") bastionDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    IPsecKBNameEmpty:false,
    IPsecKBInvalidRange:false,
    IPsecKBInvalidNumber:false,
    invalidTier:false
  }
  selectedType: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;



  tierList=[
    {id:'Basic',name:'Basic'},
    {id:'Standard',name:'Standard'},
  ]
  azureBastionData: any;

  constructor(public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }
  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteBastionData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteBastionData')
      }
    }
  }
    // Trigger action of the Connection
  
  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "configuration" : {
        this.closeModal()
        this.currentForm = this.util.getForm('bastionConfig')
        await this.getAzureBastionData(this.selectedRow)
        await this.setDefaultValue('configuration')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
    }
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteBastionData' :return this.deleteAzureBastionData(this.selectedRow)
    }
  }
  
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedRow=row;
    this.selectedType=type;
    // type=='appServicePopup'?this.getAppServicePlanData(row):false 
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteBastionData':return 'Delete Bastion'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteBastionData':return `Are you sure you want to delete “${this.selectedRow[0]}”?` 
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteBastionData':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      case 'connected': return 'st-success';
      case 'not connected': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  getSuccesText(type){
    switch(type){
      case'config':return `“${this.selectedRow[0]}” configuration updated successfully!`
      default: return 'Changes saved successfully!'
    }
  }
  
  getFailureText(type){
    switch(type){
      case'config':return `“${this.selectedRow[0]}” configuration updating failed`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'config':return `Updating “${this.selectedRow[0]}” configuration...`
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'bastionData':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }
  
  invalidForm(type){
    switch(type){
      case'config':{
        return this.inputError.IPsecKBNameEmpty|| this.inputError.IPsecKBInvalidRange|| this.inputError.IPsecKBInvalidNumber
        ||this.inputError.invalidTier
      }
    }
  }

  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      IPsecKBNameEmpty:false,
      IPsecKBInvalidRange:false,
      IPsecKBInvalidNumber:false,
      invalidTier:false
    }
  }

  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'configuration':{

      }
    break;
   }
  }
  
  async setDefaultValue(type){
  switch(type){
   case 'configuration':{
    this.currentForm.get('tier').setValue(this.azureBastionData.sku.name)
    this.currentForm.get('copyPaste').setValue(this.azureBastionData.properties.disableCopyPaste ? false:true)
    this.currentForm.get('ipBasedConn').setValue(this.azureBastionData.properties.enableIpConnect)
    this.currentForm.get('kerberos').setValue(this.azureBastionData.properties.enableKerberos)
    this.currentForm.get('native').setValue(this.azureBastionData.properties.enableTunneling)
    this.currentForm.get('bastionInstance').setValue(this.azureBastionData.properties.scaleUnits)
    break;
    }
  }
  }
  validTier(event){
    let value=event.name
    this.saveDisable=true
    this.inputError['invalidTier']=false
    if(this.azureBastionData.sku.name=='Standard'&&value=='Basic'){
      this.inputError['invalidTier']=true
    }
  }
  
  async saveBastionData(type){
    switch(type){
      case'config':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.azureBastionData
        body['sku']['name']=rawValue.tier
        body['properties'].scaleUnits=rawValue.bastionInstance
        body['properties'].disableCopyPaste=rawValue.copyPaste?false:true
        body['properties'].enableIpConnect=rawValue.ipBasedConn
        body['properties'].enableKerberos=rawValue.kerberos
        body['properties'].enableTunneling=rawValue.native
        await this.updateAzureBastionData(this.selectedRow,body,'config')
        // this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
    }
  }
  
  //validation


  validInstance(event,type){
    let value=event.target.value
    this.saveDisable=true
    this.inputError[type+'NameEmpty']=false
    this.inputError[type+'InvalidNumber']=false
    this.inputError[type+'InvalidRange']=false
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError[type+'InvalidNumber']=true;
      }
      switch(type){
        case 'IPsecKB':{
          if(value!=0 && (value>50 || value<2)){
            this.inputError[type+'InvalidRange']=true;
          }
          break;
        }
      }
    }else this.inputError[type+'NameEmpty']=true
  }

  //API's

  getAzureBastionData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let bastionName = data[0]
    return this.util.handleRequest('get','a3s_management_getAzureBastionData',[subsId,rgName,bastionName],null,null,null,true).then(async res=>{
      this.azureBastionData= res.body
      console.log('Azure Bastion Data',this.azureBastionData)
    })
  }
  updateAzureBastionData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let bastionName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateAzureBastionData',[subsId,rgName,bastionName],body,null,null,true).then(res=>{
      console.log('Azure Bastion Data updated',res)
      this.showSubSidesheet = false
      this.closeModal()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  deleteAzureBastionData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],bastionName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteAzureBastionData',[subscriptionId,resourceGroupName,bastionName],null,null,null,true).then(async res=>{
      this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
  }

}
