<div class="custom-fixed-tables">
    <table class="table">
      <thead>
        <tr>
          <th><div class="flex-custom no-wrap"><span>Name</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('mlWorkspace',0)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Resource Group</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('mlWorkspace',1)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Subscription</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('mlWorkspace',2)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Region</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('mlWorkspace',3)"></div></th>
          <th><div class="flex-custom no-wrap"><span>SKU</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('mlWorkspace',4)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Storage Account</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('mlWorkspace',5)"></div></th>
          <th><div class="flex-custom no-wrap"><span>Key vault</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('mlWorkspace',6)"></div></th>
          <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Tags</span><img class="sort" src="assets/icons/sort.png" (click)="sortByKey('mlWorkspace',7)"></div></th>
          <th style="border-left: none;" class="wid-75"></th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let row of data">
            <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(mlWorkspaceDetailsTemplate,row,'mlWorkspacePopup')">{{ row[0] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
            <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
            <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
            <td isEllipsisActive>{{ row[4] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[5] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[6] | displayTxt}}</td>
            <td isEllipsisActive>{{ getTags(row[7]) }}</td>
            <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('Compute',row)">
                      <p class="delete-popup-text">Compute</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('dataStores',row)">
                      <p class="delete-popup-text">Datastores</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('linkedServices',row)">
                        <p class="delete-popup-text">Linked services</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('dataLabeling',row)">
                        <p class="delete-popup-text">Data labeling</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteMLWorkspaceData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
              </td>
        </tr>
        <div class="col-md-12 mt-3" *ngIf="!data?.length">
          <p class="content-14 blur-text">No data Found</p>
        </div>
      </tbody>
  </table>
  </div>
  <ng-container *ngIf="showSidesheet">
    <!-- configuration -->
    <ng-container *ngIf="currentTemplate == 'configuration'">
     <div class="sidebar-right single add-alert" >
         <div class="header">
             <div class="d-flex">
               <h4 class="threeFour-width ">Configuration<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
               <div class="text-right half-width">
                 <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                   <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                 </button>
               </div>
             </div>
         </div>
         <div class="body scroll-y">
          <form [formGroup]="currentForm">
           <div class="mb-3">
               <label class="flex-custom no-wrap">Tier<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Standard_v2 and WAF_v2 tiers support availability zones. Changing
                from the WAF_v2 tier to the Standard_v2 tier is not supported." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
               <ng-select [items]="tierList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="tier" [clearable]="false" id="tier" formControlName="tier"></ng-select>
           </div>
           <div class="mb-3">
            <label class="flex-custom no-wrap">Capacity type</label>
            <div class="flex-custom">
                <div class="checkbox-cs">
                  <input type="radio" name="capacityType"  class="form-check-inline" id='autoscale' (change)="setRadioBtn('autoscale','configuration','capacityType')" [checked]="currentForm.get('capacityType')?.value =='autoscale'">
                  <label class="value" for="autoscale">Autoscale</label>
              </div>
              <div class="checkbox-cs">
                  <input type="radio" name="capacityType" class="form-check-inline" id='manual' (change)="setRadioBtn('manual','configuration','capacityType')" [checked]="currentForm.get('capacityType')?.value == 'manual'">
                  <label class="value" for="manual">Manual</label>
              </div>
            </div>
           </div>
           <div class="mb-3" *ngIf="currentForm.get('capacityType')?.value=='manual'">
            <label class="flex-custom no-wrap">Instance count<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify the instance count of the Application Gateway. A minimum 
              instance count of 2 is recommended for production workloads." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.instanceNameEmpty|| inputError?.instanceInvalidNumber ||inputError?.instanceInvalidRange" id="instanceCount" name="instanceCount" formControlName="instanceCount" (keyup)="validInstance($event,'instance')"/>
            <p class="text-danger" *ngIf="inputError?.instanceNameEmpty">The value must not be empty.</p>
            <p class="text-danger" *ngIf="inputError?.instanceInvalidNumber">The value must be a valid number.</p>
            <p class="text-danger" *ngIf="inputError?.instanceInvalidRange">Capacity for {{currentForm.get('tier')?.value}} Manual should be between 1 and 125.</p>
           </div>
           <div class="mb-3" *ngIf="currentForm.get('capacityType')?.value!='manual'">
            <label class="flex-custom no-wrap">Minimum instance count<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify the minimum instance count of the Application Gateway. 
              High availability is ensured, even if set to zero." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.minInstanceNameEmpty|| inputError?.minInstanceInvalidNumber ||inputError?.InvalidMaxRange || inputError?.minInstanceInvalidRange" id="minInstance" name="minInstance" formControlName="minInstance" (keyup)="validInstance($event,'minInstance')"/>
            <p class="text-danger" *ngIf="inputError?.minInstanceNameEmpty">The value must not be empty.</p>
            <p class="text-danger" *ngIf="inputError?.minInstanceInvalidNumber">The value must be a valid number.</p>
            <p class="text-danger" *ngIf="inputError?.InvalidMaxRange">The minimum instance count should be smaller than the maximum instance count.</p>
            <p class="text-danger" *ngIf="inputError?.minInstanceInvalidRange">The value must be between 0 and 125.</p>
           </div>
           <div class="mb-3" *ngIf="currentForm.get('capacityType')?.value!='manual'">
            <label class="flex-custom no-wrap">Maximum instance count<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify the maximum instance count of the Application Gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.maxInstanceNameEmpty|| inputError?.maxInstanceInvalidNumber ||inputError?.maxInstanceInvalidRange" id="maxInstance" name="maxInstance" formControlName="maxInstance" (keyup)="validInstance($event,'maxInstance')"/>
            <p class="text-danger" *ngIf="inputError?.maxInstanceNameEmpty">The value must not be empty.</p>
            <p class="text-danger" *ngIf="inputError?.maxInstanceInvalidNumber">The value must be a valid number.</p>
            <p class="text-danger" *ngIf="inputError?.maxInstanceInvalidRange">The value must be between 0 and 125.</p>
           </div>
           <div class="mb-3">
            <label class="flex-custom no-wrap">HTTP2<span class="mandatory">*</span></label>
            <div class="flex-custom">
                <div class="checkbox-cs">
                  <input type="radio" name="http2"  class="form-check-inline" id='Disable' (change)="setRadioBtn('no','configuration','http2')" [checked]="currentForm.get('http2')?.value ==false">
                  <label class="value" for="Disable">Disabled</label>
              </div>
              <div class="checkbox-cs">
                  <input type="radio" name="http2" class="form-check-inline" id='Enable' (change)="setRadioBtn('yes','configuration','http2')" [checked]="currentForm.get('http2')?.value == true">
                  <label class="value" for="Enable">Enabled</label>
              </div>
            </div>
          </div>
          </form>
         </div>
         <div class="fixed-btm-btn flex-custom no-wrap">
           <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('config') || !saveDisable "(click)="saveAppGatewayData('config')">Save</button>
        </div>
     </div>
    </ng-container>
    <!-- web App Firewall -->
    <ng-container *ngIf="currentTemplate == 'webAppFirewall'">
      <div class="sidebar-right single" [class.width-652]="currentForm.get('wafStatus')?.value" [class.add-alert]="!currentForm.get('wafStatus')?.value">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Web Application Firewall<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
           <form [formGroup]="currentForm">
            <div class="mb-3">
                <label class="flex-custom no-wrap">Tier<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Standard_v2 and WAF_v2 tiers support availability zones. Changing 
                  from the WAF_v2 tier to the Standard_v2 tier is not supported." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="tierList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="tier" [clearable]="false" id="tier" formControlName="tier"></ng-select>
            </div>
            <ng-container *ngIf="currentForm.get('tier')?.value=='WAF_v2'">
              <div class="mb-3">
                <label class="flex-custom no-wrap">WAF status<span class="mandatory">*</span></label>
                <div class="flex-custom">
                    <div class="checkbox-cs">
                      <input type="radio" name="wafStatus"  class="form-check-inline" id='statusDisable' (change)="setRadioBtn('no','webAppFirewall','wafStatus')" [checked]="currentForm.get('wafStatus')?.value ==false">
                      <label class="value" for="statusDisable">Disabled</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="wafStatus" class="form-check-inline" id='statusEnable' (change)="setRadioBtn('yes','webAppFirewall','wafStatus')" [checked]="currentForm.get('wafStatus')?.value == true">
                      <label class="value" for="statusEnable">Enabled</label>
                  </div>
                </div>
               </div>
               <ng-container *ngIf="currentForm.get('wafStatus')?.value">
                <div class="mb-3">
                  <label class="flex-custom no-wrap">WAF mode<span class="mandatory">*</span></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="wafMode"  class="form-check-inline" id='detection' (change)="setRadioBtn('Detection','webAppFirewall','wafMode')" [checked]="currentForm.get('wafMode')?.value =='Detection'">
                        <label class="value" for="detection">Detection</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="wafMode" class="form-check-inline" id='prevention' (change)="setRadioBtn('Prevention','webAppFirewall','wafMode')" [checked]="currentForm.get('wafMode')?.value == 'Prevention'">
                        <label class="value" for="prevention">Prevention</label>
                    </div>
                  </div>
                 </div>
                 <div class="mb-3">
                  <label class="flex-custom no-wrap bold-600">Exclusions</label>
                  <label class="flex-custom no-wrap">Application gateway will evaluate everything in the request except for the items included in this list.</label>
                </div> 
                <div class="mb-3">
                  <ng-container formArrayName="exclusiveArray" *ngFor="let tanch of currentForm.get('exclusiveArray')['controls']; let i = index;">
                    <div class="mb-1">
                      <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                        <div class="mr-2" style="width:33.3% ;">
                          <label class="flex-custom nowrap">Field</label>
                          <ng-select [items]="fieldList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="field" [clearable]="false" id="field" formControlName="field"></ng-select>
                        </div>
                        <div class="mr-2" style="width:33.3% ;">
                          <label class="flex-custom nowrap">Operator</label>
                          <ng-select [items]="operatorList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="operator" [clearable]="false" id="operator" formControlName="operator"></ng-select>
                        </div>
                        <div class="">
                          <label class="flex-custom nowrap">Selector</label>
                          <input class="form-control full-width" type="text" formControlName="selector"/>
                        </div>
                        <ng-container class="col-md-1 pr-0" *ngIf="exclusiveArrayList?.length > 1">
                          <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeExclusiveList(i)">
                        </ng-container>
                      </div>
                      <span class="action-btn" (click)="addExclusiveArray()" *ngIf="i == exclusiveArrayList?.length-1">Add new</span>
                    </div>
                  </ng-container>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap bold-600">Global Parameters</label>
                  <label class="flex-custom no-wrap">Inspect request body</label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="inspectReqBody"  class="form-check-inline" id='reqBodyON' (change)="setRadioBtn('yes','webAppFirewall','inspectReqBody')" [checked]="currentForm.get('inspectReqBody')?.value ==true">
                        <label class="value" for="reqBodyON">On</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="inspectReqBody" class="form-check-inline" id='reqBodyOFF' (change)="setRadioBtn('no','webAppFirewall','inspectReqBody')" [checked]="currentForm.get('inspectReqBody')?.value == false">
                        <label class="value" for="reqBodyOFF">Off</label>
                    </div>
                  </div>
                </div> 
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Max request body size (KB)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Inspect request body can be any value between 8 and 128 KB." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control full-width" [class.disabled-input]="!currentForm.get('inspectReqBody')?.value"  type="text" autocomplete="off" [class.border-danger]="inputError?.maxReqSizeNameEmpty|| inputError?.maxReqSizeInvalidNumber ||inputError?.maxReqSizeInvalidRange" id="maxReqSize" name="maxReqSize" formControlName="maxReqSize" (keyup)="validInstance($event,'maxReqSize')"/>
                  <p class="text-danger" *ngIf="inputError?.maxReqSizeNameEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.maxReqSizeInvalidNumber">The value must be a valid number.</p>
                  <p class="text-danger" *ngIf="inputError?.maxReqSizeInvalidRange">The value must be between 8 and 128.</p>
                 </div>
                 <div class="mb-3">
                  <label class="flex-custom no-wrap">File upload limit (MB)</label>
                  <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.fileLimitNameEmpty|| inputError?.fileLimitInvalidNumber ||inputError?.fileLimitInvalidRange" id="fileLimit" name="fileLimit" formControlName="fileLimit" (keyup)="validInstance($event,'fileLimit')"/>
                  <p class="text-danger" *ngIf="inputError?.fileLimitNameEmpty">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.fileLimitInvalidNumber">The value must be a valid number.</p>
                  <p class="text-danger" *ngIf="inputError?.fileLimitInvalidRange">The value must be between 0 and 100, inclusive.</p>
                 </div>
               </ng-container>
            </ng-container>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('webAppFirewall') || !saveDisable "(click)="saveAppGatewayData('webAppFirewall')">Save</button>
         </div>
      </div>
     </ng-container>
    <!-- Backend Pool -->
    <ng-container *ngIf="currentTemplate == 'backendPool'">
      <div class="sidebar-right single width-652" [class.sub-sheet-400]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Backend Pools<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addBackendPool')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add </button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Rules Associated</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Targets</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of applicationGatewayData?.properties?.backendAddressPools;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.requestRoutingRules ? row.properties.requestRoutingRules.length:'0'}}</td>
                            <td isEllipsisActive>{{row.properties.backendAddresses ? row.properties.backendAddresses.length:'0'}}</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addBackendPool',row)">
                                    <p class="delete-popup-text">Edit</p>
                                  </div>
                                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteBackendPool')">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                           </td>
                          </tr>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert width-400">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">{{isEdit ? 'Edit Backend Pool':'Add Backend Pool'}}<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
          
              <div class="body scroll-y">
                  <form [formGroup]="currentForm">
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">A backend pool is a collection of resources to which your application gateway can send traffic. A backend pool can contain virtual machines, virtual machines scale sets, IP addresses, domain names, or an App Service.</label>
                    </div>
                      <div class="mb-3">
                          <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="poolName" name="poolName" formControlName="poolName" (keyup)="validIPName($event,'frontend','backendPool')"/>
                          <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameExist">Backend pool name must be unique.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                              and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Add backend pool without targets</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="poolTarget"  class="form-check-inline" id='targetYes' (change)="setRadioBtn('yes','backendPool','poolTarget')" [checked]="currentForm.get('poolTarget')?.value ==true">
                              <label class="value" for="targetYes">Yes</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="poolTarget" class="form-check-inline" id='targetNo' (change)="setRadioBtn('no','backendPool','poolTarget')" [checked]="currentForm.get('poolTarget')?.value == false">
                              <label class="value" for="targetNo">No</label>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="!currentForm.get('poolTarget')?.value">
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Backend targets</label>
                          <label class="flex-custom no-wrap bold-600">{{backendTargetArrayList.length-1}} items</label>
                        </div>
                        <div class="mb-3">
                          <ng-container formArrayName="backendTargetArray" *ngFor="let tanch of currentForm.get('backendTargetArray')['controls']; let i = index;">
                            <div class="mb-1">
                              <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                                <div class="mr-2 w-50">
                                  <label class="flex-custom nowrap">Target type</label>
                                  <ng-select [items]="targetTypeList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="targetType" [clearable]="false" id="targetType" formControlName="targetType"></ng-select>
                                </div>
                                <div class="w-50">
                                  <label class="flex-custom nowrap">Target</label>
                                  <input class="form-control full-width" type="text" formControlName="target"/>
                                </div>
                                <ng-container class="col-md-1 pr-0" *ngIf="backendTargetArrayList?.length > 1">
                                  <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeBackendTargetList(i)">
                                </ng-container>
                              </div>
                              <span class="action-btn" (click)="addBackendTargetList()" *ngIf="i == backendTargetArrayList?.length-1">Add new</span>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                  </form>
              </div>
      
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('backendPool') || currentForm.invalid" (click)="saveAppGatewayData('backendPool')">{{isEdit ? 'Save':'Add'}}</button>
              </div>
          </div>
          </ng-container>
      </ng-container>

    <!-- Backend Settings -->
    <ng-container *ngIf="currentTemplate == 'compute'">
      <div class="sidebar-right single width-652" [class.sub-sheet-400]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Compute<br> <span class="blur-text">Machine Learning | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addBackendSetting')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add </button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Port</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Protocol</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Cookie based affinity</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Custom probe</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of applicationGatewayData?.properties?.backendHttpSettingsCollection;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.port}}</td>
                            <td isEllipsisActive>{{row.properties.protocol}}</td>
                            <td isEllipsisActive>{{row.properties.cookieBasedAffinity}}</td>
                            <td isEllipsisActive>{{row.properties.probe ? row.properties.probe.id.split('/')[10] : '--'}}</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addBackendSetting',row)">
                                    <p class="delete-popup-text">Edit</p>
                                  </div>
                                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteBackendSetting')">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                           </td>
                          </tr>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert width-400">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">{{isEdit ? 'Edit Backend Setting':'Add Backend Setting'}}<br> <span class="blur-text">Application Gateway | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
          
              <div class="body scroll-y">
                  <form [formGroup]="currentForm">
                      <div class="mb-3">
                          <label class="flex-custom no-wrap">Backend settings name<span class="mandatory">*</span></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="settingName" name="settingName" formControlName="settingName" (keyup)="validIPName($event,'frontend','backendSetting')"/>
                          <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameExist">Backend setting name must be unique</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                              and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Backend protocol</label>
                        <label class="flex-custom no-wrap bold-600">HTTP</label>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Backend port<span class="mandatory">*</span></label>
                        <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.portNameEmpty|| inputError?.portInvalidNumber|| inputError?.portInvalidRange" id="backendPort" name="backendPort" formControlName="backendPort" (keyup)="validInstance($event,'port')"/>
                        <p class="text-danger" *ngIf="inputError?.portNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidNumber">The value must be a number.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidRange">The value must be between 1 and 65535.</p>
                    
                     </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Additional Settings</label>
                      <label class="flex-custom no-wrap">Cookie-based affinity<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The application gateway can use cookies to keep a user session on the same server.
                        You can enable this feature if the client supports the use of cookies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="cookieAffinity"  class="form-check-inline" id='cookieAffinityYes' (change)="setRadioBtn('yes','backendSetting','cookieAffinity')" [checked]="currentForm.get('cookieAffinity')?.value ==true">
                            <label class="value" for="cookieAffinityYes">Enable</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="cookieAffinity" class="form-check-inline" id='cookieAffinityNo' (change)="setRadioBtn('no','backendSetting','cookieAffinity')" [checked]="currentForm.get('cookieAffinity')?.value == false">
                            <label class="value" for="cookieAffinityNo">Disable</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('cookieAffinity')?.value">
                      <label class="flex-custom no-wrap">Affinity cookie name</label>
                      <input class="form-control full-width" type="text" autocomplete="off" id="affinityName" name="affinityName" formControlName="affinityName"/>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Connection draining<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Connection draining helps gracefully remove backend pool members during planned service 
                        updates. When this feature is enabled, de-registering instances of a backend pool won’t receive 
                        new requests. This applies to backend instances that are explicitly removed from the backend 
                        pool via an API call as well as instances reported unhealthy by health probes." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="connectionDraining"  class="form-check-inline" id='connDrainYes' (change)="setRadioBtn('yes','backendSetting','connectionDraining')" [checked]="currentForm.get('connectionDraining')?.value ==true">
                            <label class="value" for="connDrainYes">Enable</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="connectionDraining" class="form-check-inline" id='connDrainNo' (change)="setRadioBtn('no','backendSetting','connectionDraining')" [checked]="currentForm.get('connectionDraining')?.value == false">
                            <label class="value" for="connDrainNo">Disable</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('connectionDraining')?.value">
                      <label class="flex-custom no-wrap">Drain timeout (seconds)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow existing requests to complete within this timeout." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.drainTimeOutNameEmpty|| inputError?.drainTimeOutInvalidNumber|| inputError?.drainTimeOutInvalidRange" id="drainTimeOut" name="drainTimeOut" formControlName="drainTimeOut" (keyup)="validInstance($event,'drainTimeOut')"/>
                      <p class="text-danger" *ngIf="inputError?.drainTimeOutNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.drainTimeOutInvalidNumber">The value must be a number.</p>
                      <p class="text-danger" *ngIf="inputError?.drainTimeOutInvalidRange">The value must be between 1 and 3600.</p>
                   </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Request time-out (seconds)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The request timeout is the number of seconds that the application gateway will wait to receive 
                        a response from the backend pool before it returns a “connection timed out” error message." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.reqTimeOutNameEmpty|| inputError?.reqTimeOutInvalidNumber|| inputError?.reqTimeOutInvalidRange" id="reqTimeOut" name="reqTimeOut" formControlName="reqTimeOut" (keyup)="validInstance($event,'reqTimeOut')"/>
                      <p class="text-danger" *ngIf="inputError?.reqTimeOutNameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.reqTimeOutInvalidNumber">The value must be a number.</p>
                      <p class="text-danger" *ngIf="inputError?.reqTimeOutInvalidRange">The value must be between 1 and 86400.</p>
                   </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Override backend path<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This feature allows you to override the path in the URL so that the requests for a specific path 
                      can be routed to another path. For example, if you intend to route requests for contoso.com/images 
                      to default, then enter ‘/’ in this textbox and subsequently attach this HTTP setting to the rule 
                      associated with contoso.com/images." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="backendPath" name="backendPath" [class.border-danger]="inputError?.invalidPath" formControlName="backendPath" (keyup)="validPath($event)"/>
                    <p class="text-danger" *ngIf="inputError?.invalidPath">Backend path must start with /</p>
                  </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Host Name</label>
                      <label class="flex-custom no-wrap">Override with new host name</label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="newHost"  class="form-check-inline" id='newHostYes' (change)="setRadioBtn('yes','backendSetting','newHost')" [checked]="currentForm.get('newHost')?.value ==true">
                            <label class="value" for="newHostYes">Yes</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="newHost" class="form-check-inline" id='newHostNo' (change)="setRadioBtn('no','backendSetting','newHost')" [checked]="currentForm.get('newHost')?.value == false">
                            <label class="value" for="newHostNo">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Host name override</label>
                        <div class="flex-custom">
                          <div class="checkbox-cs" [class.disabled-icon]="!currentForm.get('newHost')?.value">
                            <input type="radio" name="hostOverRide"  class="form-check-inline" id='hostOverRideYes' (change)="setRadioBtn('yes','backendSetting','hostOverRide')" [checked]="currentForm.get('hostOverRide')?.value ==true">
                            <label class="value" for="hostOverRideYes">Pick host name from backend target</label>
                          </div>
                        </div>
                        <div class="flex-custom">
                          <div class="checkbox-cs" [class.disabled-icon]="!currentForm.get('newHost')?.value">
                            <input type="radio" name="hostOverRide" class="form-check-inline" id='hostOverRideNo' (change)="setRadioBtn('no','backendSetting','hostOverRide')" [checked]="currentForm.get('hostOverRide')?.value == false">
                            <label class="value" for="hostOverRideNo">Override with specific domain name</label>
                          </div>
                        </div>
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Host name</label>
                      <input class="form-control full-width" type="text" [class.disabled-input]="!currentForm.get('newHost')?.value || currentForm.get('hostOverRide')?.value" autocomplete="off" placeholder="e.g. contoso.com" [class.border-danger]="inputError?.instanceNameEmpty" id="hostName" name="hostName" formControlName="hostName" (keyup)="validInstance($event,'instance')"/>
                      <p class="text-danger" *ngIf="inputError?.instanceNameEmpty">The value must not be empty.</p>
                   </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Use custom probe<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="To use a custom probe, you must first create a health probe." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="useProbe"  class="form-check-inline" id='useCPYes' (change)="setRadioBtn('yes','backendSetting','useProbe')" [checked]="currentForm.get('useProbe')?.value ==true">
                            <label class="value" for="useCPYes">Yes</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="useProbe" class="form-check-inline" id='useCPNo' (change)="setRadioBtn('no','backendSetting','useProbe')" [checked]="currentForm.get('useProbe')?.value == false">
                            <label class="value" for="useCPNo">No</label>
                        </div>
                      </div>
                      <p class="text-danger" *ngIf="!probeList.length && currentForm.get('useProbe')?.value">There are no HTTP probes available. Create a new probe and then try again.</p>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('useProbe')?.value">
                      <label class="flex-custom no-wrap">Custom probe<span class="mandatory">*</span></label>
                      <ng-select [items]="probeList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="probe" [clearable]="false" id="probe" formControlName="probe"></ng-select>
                   </div>
                  </form>
              </div>
      
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('backendSetting') || currentForm.invalid" (click)="saveAppGatewayData('backendSetting')">{{isEdit ? 'Save':'Add'}}</button>
              </div>
          </div>
          </ng-container>
    </ng-container>


</ng-container>

  <ng-template #mlWorkspaceDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">Machine Learning Workspace</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" (click)="openSidesheet('compute',selectedData)">Compute</div>
            <div class="tab-item" (click)="openSidesheet('dataStores',selectedData)">Datastores</div>
            <div class="tab-item" (click)="openSidesheet('linkedServices',selectedData)">Linked services</div>
            <div class="tab-item" (click)="openSidesheet('dataLabeling',selectedData)">Data labeling</div>
            <div class="tab-item" (click)="action(selectedData,'deleteMLWorkspaceData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedData;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedData[i]:getTags(selectedData[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedData[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedData[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedData[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>