<div class="introduction">

            <p class="heading" id="15">Activities  </p>
            <p class="mb-0" >Captures and analyzes all the actions performed on Azure for example start/stop of
                 VM, Key Vaults accessed, etc. by users manually or by various applications that have 
                 access to your Azure environment.  </p>
                 <img class="image mb-3" src="assets\user-manual\security\Activities.svg" >
                 
            <p class="heading" style="font-size: 32px;" id="16">Recent Operations  </p>
            <p class="mb-0" >List of recent activities.    </p>
            <img class="image" src="assets\user-manual\security\6.svg" >
            <figcaption class="caption">Recent activities </figcaption>
            <p class="heading" style="font-size: 32px;" id="17">Continuous Activities Performed by Users  </p>
            <p class="mb-0" >Looks for anomalous number of resources creation or deployment activities by same user in Azure. </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\7.svg" >
            <figcaption class="caption col-md-6">Continuous activities performed by users </figcaption>
            <p class="heading" style="font-size: 32px;" id="18">Key Vault Callers </p>
            <p class="mb-0" >List of all entities such as APIs, processes, users, applications accessing Key Vault. </p>
            <img class="image" style="width:50%" src="assets\user-manual\security\8.svg" >
            <figcaption class="caption col-md-6">Key vault callers</figcaption>

            <p class="heading" style="font-size: 32px;" id="19">Users Who Granted Permissions  </p>
            <p class="mb-0" >Shows the most prevalent users who grant access to others on Azure resources and
                 for each account their common source IP address. If an operation is not from this
                  IP address it may be worthy of investigation. </p>
                  <img class="image"  src="assets\user-manual\security\9.svg" >
                  <figcaption class="caption">Users who granted permissions </figcaption>
            <p class="heading" style="font-size: 32px;" id="20">Open Ports  </p>
            <p class="mb-0" >Identifies what ports may have been opened for a relevant Azure Resource in the given time range.  </p>
            <img class="image"  src="assets\user-manual\security\10.svg" >
            <figcaption class="caption">Resources with open ports</figcaption>

        
</div>     
        