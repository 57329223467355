<div class="introduction">

            <p class="heading" id="0">Add Group  </p>
            <ul>
                <li><b>Membership type: </b>Assigned  </li>
                <ol style="list-style-type: lower-alpha;">
                    <li>Click <b>Add group.</b> </li>
                    <li>Provide all the needed inputs in the side sheet. </li>
                    <img style="width: 50%;height: 25%;" src="assets\user-manual\governance\Side-sheet(9).svg" >
                    <figcaption class="caption col-md-6" style="padding-left: 85px;">Group membership – assigned</figcaption>
                    <li>Select <b>Group Type</b>, provide values for <b>Name</b> and <b>Description.</b> </li>
                 
                    <li>Select <b>Owner(s)</b> and <b>Member(s).</b> </li>
                    <li>Click <b>Add.</b> </li>
                </ol>
          
                <li><b>Membership type: </b>Dynamic   </li>
                <ol style="list-style-type: lower-alpha;"> 
                    <li>Click <b>Add group.</b> </li>
                    <li>Provide the needed inputs in the side sheet. </li>
                    <img style="width: 50%;height: 25%;" src="assets\user-manual\governance\Side-sheet(8).svg" >
                    <figcaption class="caption col-md-6" style="padding-left: 85px;">Group membership - dynamic</figcaption>
                    <li>Add Dynamic Membership rules </li>
                    <li>Click <b>Add.</b> </li>
                   
                </ol>
                <img style="width: 50%;height: 50%;" src="assets\user-manual\governance\Side-sheet(10).svg" >
                <figcaption class="caption col-md-6" style="padding-left: 85px;"> Dynamic membership rules</figcaption>

            </ul>
           
          
           
             
</div>
