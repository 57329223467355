<div class="security-recommednation-container">
    <div class="header-container">
      <h2 class="title">Activities</h2>
    </div>
    
     <div class="custom-filter-wrapper flex-custom">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                        <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                        <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                        <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                        <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button>
    </div>

    <div class="dashboard-body">
      <div class="row" *ngIf="!continuesActivitiesShow">
        <div class="col-md-12 padding-div">
          <div class="graph-div card-height mt-2">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Recent Activities<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Shows the recent activities performed by users." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.recentActivities)}} . {{getTimeFilterVal(recentActivities)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('recentActivities')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('recentActivities','recentActivities')"><img src="assets/security/download_icon.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter1">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="recentActivities?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,recentActivities):applyFilter(row.id,recentActivities)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="recentActivities?.tableData?.length && !recentActivities?.showLoader">
                <table class="table" id="recentActivities">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Activity</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('recentActivities',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Category</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('recentActivities',2)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Resource</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('recentActivities',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Performed By</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('recentActivities',3)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Count</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('recentActivities',4)">
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of recentActivities.tableData">
                        <ng-container (click)="openPopup('recentActivities',row,recentActivitiesPopup)">
                          <td (click)="openPopup('recentActivities',row,recentActivitiesPopup)">{{row[0]}}</td>
                          <td (click)="openPopup('recentActivities',row,recentActivitiesPopup)">{{row[2]}}</td>
                          <td (click)="openPopup('recentActivities',row,recentActivitiesPopup)">{{row[1].split('/')[row[1].split('/').length-1]}}</td>
                          <td (click)="openPopup('recentActivities',row,recentActivitiesPopup)">{{row[3].join(', ')}}</td>
                          <td (click)="openPopup('recentActivities',row,recentActivitiesPopup)">{{row[4]}}</td>
                        </ng-container>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="loader-gif" *ngIf="recentActivities?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!recentActivities?.tableData?.length && !recentActivities?.showLoader)">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-16-26 mt-3">No recent activities found 
                    in the selected timeframe.</p>
              </div>
          </div>
      </div>
      <div class="col-md-12 padding-div">
        <div class="graph-div card-height">
          <div class="heading">
              <div class="d-flex">
                  <div class="about">
                      <h4>Continuous Activities Performed by Users<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Shows the identities which are performing activities frequently." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                      <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.continuousActivities)}} . {{getTimeFilterVal(continuousActivities)}}</p>
                  </div> 
                  <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('continuousActivities')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon ml-2" matTooltip="Continuous Activities Performed by User" matTooltipClass="nav-tooltips" (click)="showContinuousActivities(null)" matTooltipShowDelay="500"><img src="/assets/security/drilldown_icon.svg" alt=""></span>
                      <div class="cal-wrap">
                        <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                        matTooltipShowDelay="500" (click)="openFilter('customFilter2')"><img src="/assets/security/clock.svg" alt=""></span>
                        <div class="cal-box"  id="customFilter2">
                            <div class="cal-inner">
                            <div class="select-sec">
                                <ng-container *ngFor="let row of timeFilterList">
                                    <div class="select-item" [class.active]="continuousActivities?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,continuousActivities):applyFilter(row.id,continuousActivities)">{{row.name}}</div>
                                </ng-container>
                            </div>
                            </div>
                        </div>
                    </div>
                  </div>                  
              </div>                
          </div>
          <div echarts [options]="continuousActivities?.options" [loading]="true" (chartClick)="onChartClick($event)" *ngIf="continuousActivities?.series?.length && !continuousActivities?.showLoader"></div>
          <div class="loader-gif" *ngIf="continuousActivities?.showLoader">
              <img src="assets/icons/blue-loader.gif"/>
              <p>Getting data...</p>
          </div>
          <div class="dash-text-center" *ngIf="!continuousActivities?.series?.length && !continuousActivities?.showLoader">
              <img src="/assets/health-monitoring/no-data.svg" alt="">
              <p class="text-16-26 mt-3">No continuous activities performed by users 
                found in the selected timeframe.</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 padding-div">
        <div class="graph-div card-height">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Role Assignment Activities<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Shows the activities for updating the role assignments in the environment." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.roleAssignmentActivities)}} . {{getTimeFilterVal(roleAssignmentActivities)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('roleAssignmentActivities')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('roleAssignmentActivities','roleAssignmentActivities')"><img src="assets/security/download_icon.svg" alt=""></span>
                      <div class="cal-wrap">
                          <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                          matTooltipShowDelay="500" (click)="openFilter('customFilter3')"><img src="/assets/security/clock.svg" alt=""></span>
                          <div class="cal-box"  id="customFilter3">
                              <div class="cal-inner">
                              <div class="select-sec">
                                  <ng-container *ngFor="let row of timeFilterList">
                                      <div class="select-item" [class.active]="roleAssignmentActivities?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,roleAssignmentActivities):applyFilter(row.id,roleAssignmentActivities)">{{row.name}}</div>
                                  </ng-container>
                              </div>
                              </div>
                          </div>
                      </div>
                  </div> 
                </div>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="roleAssignmentActivities?.tableData?.length && !roleAssignmentActivities?.showLoader">
              <table class="table" id="roleAssignmentActivities">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Performed By</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('roleAssignmentActivities',1)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Role</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('roleAssignmentActivities',11)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Role Assigned to</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('roleAssignmentActivities',8)">
                          </div>
                      </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Time Generated (UTC)</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('roleAssignmentActivities',6)">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of roleAssignmentActivities.tableData">
                        <ng-container (click)="openPopup('roleAssignmentActivities',row,roleAssignmentActivitiesPopup)">
                            <td (click)="openPopup('roleAssignmentActivities',row,roleAssignmentActivitiesPopup)">{{row[1]}}</td>
                            <td (click)="openPopup('roleAssignmentActivities',row,roleAssignmentActivitiesPopup)">{{row[11]}}</td>
                            <td (click)="openPopup('roleAssignmentActivities',row,roleAssignmentActivitiesPopup)">{{row[8]}}</td>
                            <td (click)="openPopup('roleAssignmentActivities',row,roleAssignmentActivitiesPopup)">{{row[6]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        </ng-container>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="loader-gif" *ngIf="roleAssignmentActivities?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!roleAssignmentActivities?.tableData?.length && !roleAssignmentActivities?.showLoader)">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-16-26 mt-3">No role assignment activities found 
                    in the selected timeframe.</p>
            </div>
        </div>
    </div>
    <div class="col-md-12 padding-div">
        <div class="graph-div card-height">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Operations on Network Security Groups<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Shows operations performed on Network Security Groups." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.operationsOnNetworkSecurityGroups)}} . {{getTimeFilterVal(operationsOnNetworkSecurityGroups)}}</p>                        
                    </div>
                    <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('operationsOnNetworkSecurityGroups')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('operationsOnNetworkSecurityGroups','operationsOnNetworkSecurityGroups')"><img src="assets/security/download_icon.svg" alt=""></span>
                      <div class="cal-wrap">
                          <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                          matTooltipShowDelay="500" (click)="openFilter('customFilter4')"><img src="/assets/security/clock.svg" alt=""></span>
                          <div class="cal-box"  id="customFilter4">
                              <div class="cal-inner">
                              <div class="select-sec">
                                  <ng-container *ngFor="let row of timeFilterList">
                                      <div class="select-item" [class.active]="operationsOnNetworkSecurityGroups?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,operationsOnNetworkSecurityGroups):applyFilter(row.id,operationsOnNetworkSecurityGroups)">{{row.name}}</div>
                                  </ng-container>
                              </div>
                              </div>
                          </div>
                      </div>
                  </div> 
                </div>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="operationsOnNetworkSecurityGroups?.tableData?.length && !operationsOnNetworkSecurityGroups?.showLoader">
              <table class="table" id="operationsOnNetworkSecurityGroups">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>NSG Name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('operationsOnNetworkSecurityGroups',0)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Resource Group</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('operationsOnNetworkSecurityGroups',0)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Operation Name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('operationsOnNetworkSecurityGroups',1)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Performed By</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('operationsOnNetworkSecurityGroups',3)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Result</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('operationsOnNetworkSecurityGroups',4)">
                          </div>
                      </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Time Generated (UTC)</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('operationsOnNetworkSecurityGroups',2)">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of operationsOnNetworkSecurityGroups.tableData">
                        <ng-container (click)="openPopup('operationsOnNetworkSecurityGroups',row,operationsOnNetworkSecurityGroupsPopup)">
                            <td (click)="openPopup('operationsOnNetworkSecurityGroups',row,operationsOnNetworkSecurityGroupsPopup)">{{row[0].split('/')[8]}}</td>
                            <td (click)="openPopup('operationsOnNetworkSecurityGroups',row,operationsOnNetworkSecurityGroupsPopup)">{{row[0].split('/')[4]}}</td>
                            <td (click)="openPopup('operationsOnNetworkSecurityGroups',row,operationsOnNetworkSecurityGroupsPopup)">{{row[1]}}</td>
                            <td (click)="openPopup('operationsOnNetworkSecurityGroups',row,operationsOnNetworkSecurityGroupsPopup)">{{row[3]}}</td>
                            <td (click)="openPopup('operationsOnNetworkSecurityGroups',row,operationsOnNetworkSecurityGroupsPopup)">{{row[4]}}</td>
                            <td (click)="openPopup('operationsOnNetworkSecurityGroups',row,operationsOnNetworkSecurityGroupsPopup)">{{row[2]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        </ng-container>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="loader-gif" *ngIf="operationsOnNetworkSecurityGroups?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!operationsOnNetworkSecurityGroups?.tableData?.length && !operationsOnNetworkSecurityGroups?.showLoader)">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-16-26 mt-3">No operation on network security groups 
                    found in the selected timeframe.</p>
            </div>
        </div>
    </div>
    <div class="col-md-12 padding-div">
        <div class="graph-div card-height">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Password Reset Activities on Host<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Shows the password reset activities for users and groups on the Linux host." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.passwordResetActivitiesOnHost)}} . {{getTimeFilterVal(passwordResetActivitiesOnHost)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('passwordResetActivitiesOnHost')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('passwordResetActivitiesOnHost','passwordResetActivitiesOnHost')"><img src="assets/security/download_icon.svg" alt=""></span>
                      <div class="cal-wrap">
                          <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                          matTooltipShowDelay="500" (click)="openFilter('customFilter5')"><img src="/assets/security/clock.svg" alt=""></span>
                          <div class="cal-box"  id="customFilter5">
                              <div class="cal-inner">
                              <div class="select-sec">
                                  <ng-container *ngFor="let row of timeFilterList">
                                      <div class="select-item" [class.active]="passwordResetActivitiesOnHost?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,passwordResetActivitiesOnHost):applyFilter(row.id,passwordResetActivitiesOnHost)">{{row.name}}</div>
                                  </ng-container>
                              </div>
                              </div>
                          </div>
                      </div>
                  </div> 
                </div>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="passwordResetActivitiesOnHost?.tableData?.length && !passwordResetActivitiesOnHost?.showLoader">
              <table class="table" id="passwordResetActivitiesOnHost">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Changed by</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('passwordResetActivitiesOnHost',2)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Changed for</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('passwordResetActivitiesOnHost',4)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Host</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('passwordResetActivitiesOnHost',7)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Result</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('passwordResetActivitiesOnHost',10)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Severity</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('passwordResetActivitiesOnHost',1)">
                          </div>
                      </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Time Generated (UTC)</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('passwordResetActivitiesOnHost',0)">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of passwordResetActivitiesOnHost.tableData">
                        <ng-container (click)="openPopup('passwordResetActivitiesOnHost',row,passwordResetActivitiesOnHostPopup)">
                            <td (click)="openPopup('passwordResetActivitiesOnHost',row,passwordResetActivitiesOnHostPopup)">{{row[2]}}</td>
                            <td (click)="openPopup('passwordResetActivitiesOnHost',row,passwordResetActivitiesOnHostPopup)">{{row[4]||row[5]}}</td>
                            <td (click)="openPopup('passwordResetActivitiesOnHost',row,passwordResetActivitiesOnHostPopup)">{{row[7].split('/')[row[7].split('/').length-1]}}</td>
                            <td (click)="openPopup('passwordResetActivitiesOnHost',row,passwordResetActivitiesOnHostPopup)">
                                <div class="chip" [ngClass]="getStatusClass(row[10])">
                                    {{row[10] | titlecase }}
                                </div>
                            </td>
                            <td (click)="openPopup('passwordResetActivitiesOnHost',row,passwordResetActivitiesOnHostPopup)">
                                <div class="chip" [ngClass]="getStatusClass(row[1])">
                                    {{row[1] | titlecase }}
                                </div>
                            </td>
                            <td (click)="openPopup('passwordResetActivitiesOnHost',row,passwordResetActivitiesOnHostPopup)">{{row[0]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        </ng-container>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="loader-gif" *ngIf="passwordResetActivitiesOnHost?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!passwordResetActivitiesOnHost?.tableData?.length && !passwordResetActivitiesOnHost?.showLoader)">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-16-26 mt-3">No password reset activities on host
                    found in the selected timeframe.</p>
            </div>
        </div>
    </div>
    <div class="col-md-12 padding-div">
        <div class="graph-div card-height mb-4">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Activities on Users and Groups<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Shows the activities related to users and groups on the host servers." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.activitiesOnUsersAndGroups)}} . {{getTimeFilterVal(activitiesOnUsersAndGroups)}}</p>
                    </div>
                    <div class="float-right flex-custom no-wrap">
                      <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('activitiesOnUsersAndGroups')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('activitiesOnUsersAndGroups','activitiesOnUsersAndGroups')"><img src="assets/security/download_icon.svg" alt=""></span>
                      <div class="cal-wrap">
                          <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                          matTooltipShowDelay="500" (click)="openFilter('customFilter6')"><img src="/assets/security/clock.svg" alt=""></span>
                          <div class="cal-box"  id="customFilter6">
                              <div class="cal-inner">
                              <div class="select-sec">
                                  <ng-container *ngFor="let row of timeFilterList">
                                      <div class="select-item" [class.active]="activitiesOnUsersAndGroups?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,activitiesOnUsersAndGroups):applyFilter(row.id,activitiesOnUsersAndGroups)">{{row.name}}</div>
                                  </ng-container>
                              </div>
                              </div>
                          </div>
                      </div>
                  </div> 
                </div>
            </div>
            <div class="custom-fixed-tables m-0 mt-3" *ngIf="activitiesOnUsersAndGroups?.tableData?.length && !activitiesOnUsersAndGroups?.showLoader">
              <table class="table" id="activitiesOnUsersAndGroups">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Activity</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activitiesOnUsersAndGroups',0)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Host</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activitiesOnUsersAndGroups',4)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Performed By</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activitiesOnUsersAndGroups',1)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Result</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activitiesOnUsersAndGroups',9)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Severity</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activitiesOnUsersAndGroups',7)">
                          </div>
                      </th>
                      <th class="borderBlueRight">
                        <div class="flex-custom no-wrap">
                            <span>Time Generated (UTC)</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortAll('activitiesOnUsersAndGroups',6)">
                        </div>
                    </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let row of activitiesOnUsersAndGroups.tableData">
                        <ng-container (click)="openPopup('activitiesOnUsersAndGroups',row,activitiesOnUsersAndGroupsPopup)">
                            <td (click)="openPopup('activitiesOnUsersAndGroups',row,activitiesOnUsersAndGroupsPopup)">{{row[0]}}</td>
                            <td (click)="openPopup('activitiesOnUsersAndGroups',row,activitiesOnUsersAndGroupsPopup)">{{row[4].split('/')[row[4].split('/').length-1]}}</td>
                            <td (click)="openPopup('activitiesOnUsersAndGroups',row,activitiesOnUsersAndGroupsPopup)">{{row[1]}}</td>
                            <td (click)="openPopup('activitiesOnUsersAndGroups',row,activitiesOnUsersAndGroupsPopup)">
                                <div class="chip" [ngClass]="getStatusClass(row[9])">
                                    {{row[9] | titlecase }}
                                </div>
                            </td>
                            <td (click)="openPopup('activitiesOnUsersAndGroups',row,activitiesOnUsersAndGroupsPopup)">
                                <div class="chip" [ngClass]="getStatusClass(row[7])">
                                    {{row[7] | titlecase }}
                                </div>
                            </td>
                            <td (click)="openPopup('activitiesOnUsersAndGroups',row,activitiesOnUsersAndGroupsPopup)">{{row[6]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        </ng-container>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div class="loader-gif" *ngIf="activitiesOnUsersAndGroups?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="(!activitiesOnUsersAndGroups?.tableData?.length && !activitiesOnUsersAndGroups?.showLoader)">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-16-26 mt-3">No activities on users and groups
                    found in the selected timeframe.</p>
            </div>
        </div>
    </div>
      </div>
      <div class="row" *ngIf="continuesActivitiesShow">
        <div class="col-md-12 d-flex mt-2" [formGroup]="processDetails" style="padding-right: 0px;">
            <div class="d-flex" style="align-items: start;width: 100%;">
                <img src="/assets/security/arrow_left.svg" alt="" style="margin: 0px 4px;cursor: pointer;" (click)="continuesActivitiesShow=false;">
                <label style="font-weight: 400;font-size: 12px;line-height: 18px;cursor: pointer;" (click)="continuesActivitiesShow=false;">Back to dashboard</label>
            </div>
            <ng-select [items]="processList" bindLabel="name" [closeOnSelect]="true" name="process" placeholder="Process:" groupBy="type" [searchable]="false" (change)="processChange()" style="margin-right: 12px;" [clearable]="false" formControlName="process">
                <ng-template ng-optgroup-tmp let-item="item">
                    {{item.type}}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    {{item?.type}}: {{item?.name}}
                  </ng-template>
            </ng-select>
            <ng-select [items]="timeFilterList" [searchable]="false" bindLabel="name" bindValue="id" [closeOnSelect]="true" name="timeRange" placeholder="Time Range:" (change)="processDetails.value.timeRange=='custom'?openModal(openCal,continuousActivitiesUser):applyFilter(processDetails.value.timeRange,continuousActivitiesUser)" [clearable]="false" formControlName="timeRange">
                <ng-template ng-label-tmp let-item="item">
                    Time Range: {{item?.name}}
                  </ng-template>
            </ng-select>
        </div>
        <div class="col-md-12 padding-div">
            <div class="graph-div running-process-height mb-4">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Continuous Activities Performed by {{processDetails?.value?.process?.type}} - {{processDetails?.value?.process?.name}}</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.continuousActivitiesUser)}} . {{getTimeFilterVal(continuousActivitiesUser)}}</p>
                      </div> 
                      <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('continuousActivitiesUser')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div>                  
                  </div>                
              </div>
              <div echarts [options]="continuousActivitiesUser?.options" [loading]="true" *ngIf="continuousActivitiesUser?.series?.length && !continuousActivitiesUser?.showLoader"></div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="continuousActivitiesUser?.tableData?.length && !continuousActivitiesUser?.showLoader">
                <table class="table" id="continuousActivitiesUser">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Activity</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('continuousActivitiesUser','activity')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Activity Status</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('continuousActivitiesUser','activityStatus')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Resource</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('continuousActivitiesUser','resourceId')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Resource Group</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('continuousActivitiesUser','resourceId')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Time Generated (UTC)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('continuousActivitiesUser','timeGenerated')">
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of continuousActivitiesUser.tableData">
                        <td>{{row.activity}}</td>
                        <td>
                            <div class="chip" [ngClass]="getStatusClass(row.activityStatus)">
                                {{row.activityStatus | titlecase }}
                            </div>
                        </td>
                        <td>{{row.resourceId.split('/')[row.resourceId.split('/').length-1]}}</td>
                        <td>{{row.resourceId.split('/')[4]}}</td>
                        <td>{{row.timeGenerated|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="loader-gif cau-custom-loader" *ngIf="continuousActivitiesUser?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="!continuousActivitiesUser?.series?.length && !continuousActivitiesUser?.showLoader">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-16-26 mt-3">No running processes found 
                    in the selected timeframe.</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <ng-template #activitiesOnUsersAndGroupsPopup>
    <div class="medium-popup-wrapper2 width-877">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Activities on Users and Groups</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row details-summary">
                <label class="summary-label col-sm-12">Summary</label>
                <div class="col-sm-3 column-detail">Activity Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[0]}}</div>
                <div class="col-sm-3 column-detail" *ngIf="detailsPop[0] ==='User added'">New User Name</div>
                <div class="col-sm-7 column-detail" *ngIf="detailsPop[0] ==='User added'">: {{detailsPop[2]}}</div>
                <div class="col-sm-3 column-detail" *ngIf="detailsPop[0] ==='User deleted'">User Name</div>
                <div class="col-sm-7 column-detail" *ngIf="detailsPop[0] ==='User deleted'">: {{detailsPop[10]}}</div>
                <div class="col-sm-3 column-detail" *ngIf="detailsPop[0] ==='Group added'">New Group Name</div>
                <div class="col-sm-7 column-detail" *ngIf="detailsPop[0] ==='Group added'">: {{detailsPop[11]}}</div>
                <div class="col-sm-3 column-detail" *ngIf="detailsPop[0] ==='Group deleted'">Group Name</div>
                <div class="col-sm-7 column-detail" *ngIf="detailsPop[0] ==='Group deleted'">: {{detailsPop[12]}}</div>
                <div class="col-sm-3 column-detail" *ngIf="detailsPop[0] ==='User removed from a group'||detailsPop[0] ==='User added to a group'">User Name</div>
                <div class="col-sm-7 column-detail" *ngIf="detailsPop[0] ==='User removed from a group'||detailsPop[0] ==='User added to a group'">: {{detailsPop[10]}}</div>
                <div class="col-sm-3 column-detail" *ngIf="detailsPop[0] ==='User removed from a group'||detailsPop[0] ==='User added to a group'">Group Name</div>
                <div class="col-sm-7 column-detail" *ngIf="detailsPop[0] ==='User removed from a group'||detailsPop[0] ==='User added to a group'">: {{detailsPop[12]}}</div>
                <div class="col-sm-3 column-detail">Host</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[4].split('/')[detailsPop[4].split('/').length-1]}}</div>
                <div class="col-sm-3 column-detail">Host IP</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[5]}}</div>
                <div class="col-sm-3 column-detail">Process Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[8]}}</div>
                <div class="col-sm-3 column-detail">Performed By</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[1]}}</div>
                <div class="col-sm-3 column-detail">Result</div>
                <div class="col-sm-7 column-detail">: 
                        <div class="chip" [ngClass]="getStatusClass(detailsPop[9])">
                            {{detailsPop[9] | titlecase }}
                        </div>
                </div>
                <div class="col-sm-3 column-detail">Severity</div>
                <div class="col-sm-7 column-detail">: 
                        <div class="chip" [ngClass]="getStatusClass(detailsPop[7])">
                            {{detailsPop[7] | titlecase }}
                        </div>
                </div>
                <div class="col-sm-3 column-detail">Time Generated (UTC)</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[6]|date:'MM/dd/yyyy, hh:mm:ss a'}}</div>
                <div class="col-sm-12 diff-line"></div>
                <div class="col-sm-12 message-details">
                    <span class="title-val">Message</span>
                    <span class="title-details">{{detailsPop[3]}}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #operationsOnNetworkSecurityGroupsPopup>
    <div class="medium-popup-wrapper2 width-877">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Operations on Network Security Groups</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row details-summary">
                <label class="summary-label col-sm-12">Summary</label>
                <div class="col-sm-3 column-detail">NSG Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[0].split('/')[detailsPop[0].split('/').length-1]}}</div>
                <div class="col-sm-3 column-detail">Resource Group</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[0].split('/')[4]}}</div>
                <div class="col-sm-3 column-detail">Operation Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[1]}}</div>
                <div class="col-sm-3 column-detail">Performed By</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[3]}}</div>
                <div class="col-sm-3 column-detail">Result</div>
                <div class="col-sm-7 column-detail">:
                    <div class="chip" [ngClass]="getStatusClass(detailsPop[4])">
                        {{detailsPop[4] | titlecase }}
                    </div>
                </div>
                <div class="col-sm-3 column-detail">Time Generated (UTC)</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[2]|date:'MM/dd/yyyy, hh:mm:ss a'}}</div>
                <div class="col-sm-12 diff-line"></div>
                <ng-container *ngIf="detailsPop[4]!='Failed'&&detailsPop[4]!='failed'">
                    <label class="summary-label col-sm-12">Additional Details</label>
                    <div class="col-sm-3 column-detail">NSG Rule Name</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[5]}}</div>
                    <div class="col-sm-3 column-detail">Priority</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[12]}}</div>
                    <div class="col-sm-3 column-detail">Access</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[6]}}
                    </div>                
                    <div class="col-sm-3 column-detail">Protocol</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[13]}}</div>
                    <div class="col-sm-3 column-detail">Destination Address Prefix</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[7]||jsonParseValue(detailsPop[8])}}</div>
                    <div class="col-sm-3 column-detail">Source Address Prefix</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[14]||jsonParseValue(detailsPop[15])}}</div>
                    <div class="col-sm-3 column-detail">Destination Port Range</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[9]||jsonParseValue(detailsPop[10])}}</div>
                    <div class="col-sm-3 column-detail">Source Port Range</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[16]||jsonParseValue(detailsPop[17])}}</div>
                    <div class="col-sm-3 column-detail">Direction</div>
                    <div class="col-sm-3 column-detail">: {{detailsPop[11]}}</div>
                </ng-container>
                <div class="col-sm-12 message-details" *ngIf="detailsPop[4]=='Failed'||detailsPop[4]=='failed'">
                    <span class="title-val">Message</span>
                    <span class="title-details">{{detailsPop[18]}}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #passwordResetActivitiesOnHostPopup>
    <div class="medium-popup-wrapper2 width-877">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Password Reset Activities on Host</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row details-summary">
                <label class="summary-label col-sm-12">Summary</label>
                <div class="col-sm-3 column-detail">Activity Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[3]}}</div>
                <div class="col-sm-3 column-detail">Changed by</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[2]}}</div>
                <div class="col-sm-3 column-detail">Changed for</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[4]||detailsPop[5]}}</div>
                <div class="col-sm-3 column-detail">Host</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[7].split('/')[detailsPop[7].split('/').length-1]}}</div>
                <div class="col-sm-3 column-detail">Host IP</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[8]}}</div>
                <div class="col-sm-3 column-detail">Result</div>
                <div class="col-sm-7 column-detail">: 
                    <div class="chip" [ngClass]="getStatusClass(detailsPop[10])">
                        {{detailsPop[10] | titlecase }}
                    </div>
                </div>
                <div class="col-sm-3 column-detail">Severity</div>
                <div class="col-sm-7 column-detail">: 
                    <div class="chip" [ngClass]="getStatusClass(detailsPop[1])">
                        {{detailsPop[1] | titlecase }}
                    </div>
                </div>
                <div class="col-sm-3 column-detail">Process Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[9]}}</div>
                <div class="col-sm-3 column-detail">Time Generated (UTC)</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[0]|date:'MM/dd/yyyy, hh:mm:ss a'}}</div>
                <div class="col-sm-12 diff-line"></div>
                <div class="col-sm-12 message-details">
                    <span class="title-val">Message</span>
                    <span class="title-details">{{detailsPop[6]}}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #roleAssignmentActivitiesPopup>
    <div class="medium-popup-wrapper2 width-877">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Role Assignment Activities</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row details-summary">
                <label class="summary-label col-sm-12">Summary</label>
                <div class="col-sm-3 column-detail">Role</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[11]}}</div>
                <div class="col-sm-3 column-detail">Role Type</div>
                <div class="col-sm-7 column-detail">: Built-in</div>
                <div class="col-sm-3 column-detail">Scope</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[3]}} ({{detailsPop[2]}})</div>
                <div class="col-sm-3 column-detail">Role Assigned to</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[8]}}</div>
                <div class="col-sm-3 column-detail">Performed By</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[1]}}</div>
                <div class="col-sm-3 column-detail">Operation Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[0]}}</div>
                <div class="col-sm-12 diff-line"></div>
                <label class="summary-label col-sm-12">Additional Details</label>
                <div class="col-sm-3 column-detail">Activity Status</div>
                <div class="col-sm-7 column-detail">:
                    <div class="chip" [ngClass]="getStatusClass(detailsPop[5])">
                        {{detailsPop[5] | titlecase }}
                    </div>
                </div>
                <div class="col-sm-3 column-detail">Severity</div>
                <div class="col-sm-7 column-detail">:
                    <div class="chip" [ngClass]="getStatusClass(detailsPop[7])">
                        {{detailsPop[7] | titlecase }}
                    </div>
                </div>
                <div class="col-sm-3 column-detail">Category</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[4]}}</div>
                <div class="col-sm-3 column-detail">Time Generated (UTC)</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[6]|date:'MM/dd/yyyy, hh:mm:ss a'}}</div>
                <div class="col-sm-12 diff-line"></div>
                <div class="col-sm-12 message-details">
                    <span class="title-val">Message</span>
                    <span class="title-details">{{detailsPop[10]}}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #recentActivitiesPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Recent Activities</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row multiple-password-poup">
                <div class="col-sm-3">
                    <span class="title-val">Activity</span>
                    <span class="title-details" [title]="detailsPop[0]">{{detailsPop[0]}}</span>
                </div>
                <div class="col-sm-3">
                    <span class="title-val">Category</span>
                    <span class="title-details" [title]="detailsPop[2]">{{detailsPop[2]}}</span>
                </div>
                <div class="col-sm-3">
                    <span class="title-val">Resource</span>
                    <span class="title-details" [title]="detailsPop[1].split('/')[detailsPop[1].split('/').length-1]">{{detailsPop[1].split('/')[detailsPop[1].split('/').length-1]}}</span>
                </div>
                <div class="col-sm-3">
                    <span class="title-val">Resource group</span>
                    <span class="title-details" [title]="detailsPop[1].split('/')[4]">{{detailsPop[1].split('/')[4]}}</span>
                </div>
            </div>
            <div class="custom-fixed-tables">
                <table class="table" #recentActivitiesPopupId>
                    <thead>
                      <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Time Generated (UTC)</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'timeGenerated')">
                              </div>
                          </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Severity </span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'severity')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Activity Status</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'activityStatus')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Performed By</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'performedBy')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of detailsPop[9]">
                          <td>{{row.timeGenerated|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                          <td>
                            <div class="chip" [ngClass]="getStatusClass(row?.severity)">
                                {{row?.severity | titlecase }}
                              </div>
                          </td>
                          <td>{{row.activityStatus}}</td>
                          <td>{{row.performedBy}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!detailsPop[9]?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFileCustom('recentActivitiesPopupId')" *ngIf="detailsPop[9]?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>

  <ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>