import { AfterViewInit, Directive, ElementRef, HostListener, Input,Renderer2 } from "@angular/core";
import { AbstractControl } from '@angular/forms';


@Directive({
    selector: '[onlyNumber]'
  })
  export class OnlynumberDirective {
    @Input() onlyNumber: boolean;
    constructor(private el: ElementRef) { }
    @HostListener('keydown', ['$event']) onkeydown(event) {
      let e = <KeyboardEvent>event;
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode == 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode == 88 && e.ctrlKey === true) ||
        //  Allow: Ctrl+v
        (e.keyCode == 86 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        return
      } else {
        let regex = /\d+/;
        if (regex.test(e.key) == false) {
          return false;
        } else {
          return true
        }
      }
    }
  }
  
  @Directive({
    selector: '[avoidSpace]'
  })
  export class AvoidspaceDirective {
    @Input('avoidSpace') avoidSpace: AbstractControl;
    constructor() { }
    @HostListener('input', ['$event']) onmouseleave(event) {
      let val = event.target.value.replace(/\s+/g, '');
      val = val.trim();
      return this.avoidSpace ? this.avoidSpace.setValue(val) : event.target.value = val;
    }
  }

  
@Directive({
    selector: '[restrict2Dot]'
  })
  export class Restrict2dotDirective {
    @Input('numberLimit') numberLimit: AbstractControl;
    @Input('restrict2Dot') restrict2Dot: AbstractWorker;
    constructor(private el: ElementRef) { }
    @HostListener('input', ['$event']) onkeypress(event) {
      let anotherRegex = /^[0-9_]+(?:\.[0-9_]+)*$/, key;
      if (anotherRegex.test(event.target.value) == false) {
        let str = event.target.value.split('.'), newVal = '';
        if (str.length == 3) {
          str.forEach((x, i) => {
            if ((i + 1) !== 3) {
              newVal = newVal + (x + (i == 0 ? '.' : ''));
            }
            event.target.value = newVal;
          })
        }
        key = this.numberLimit || this.restrict2Dot;
        key ? key.setValue(event.target.value) : '';
        return true;
      }
    }
  }
  

  @Directive({
    selector: '[numberLimit]'
  })
  export class NumberlimitDirective {
    @Input('numberLimit') numberLimit: AbstractControl;
    @Input('maxDigits') maxDigits:number;
    constructor(private el: ElementRef, private renderer: Renderer2) { }
    @HostListener('input', ['$event']) oninput(event) {
      let e = event;
      let regex = /\d+/;
      let value = e.target.value
      let isMinus = false;
      if (e.inputType == 'insertFromPaste') return;
      if (regex.test(e.target.value) == false) {
        return false;
      } else {
        if(e.target.value.indexOf('-') > -1) {
          value = e.target.value.split('-')[1]
          isMinus = true;
        }
        if (value > 100) {
          e.target.value = isMinus ? -100 : 100;
          let event: Event = document.createEvent("Event");
          event.initEvent('input', true, true);
          Object.defineProperty(event, 'target', { value: this.el.nativeElement, enumerable: true });
          (this.el.nativeElement as any)['dispatchEvent'].apply(this.el.nativeElement, [event]);
        } else {
          e.target.value = e.target.value.indexOf('.') > -1 ? e.target.value.split('.')[1].length > ( this.maxDigits ? this.maxDigits:2) ? Number(e.target.value).toLocaleString(undefined, { maximumFractionDigits: ( this.maxDigits ? this.maxDigits:2)  }) : e.target.value : e.target.value;
        }
        return true;
      }
    }
  }

  @Directive({
    selector: '[isEllipsisActive]'
  })
  export class IsEllipsisActiveDirective implements AfterViewInit {
   
    constructor(private elementRef: ElementRef) {}
  
    ngAfterViewInit(): void {
      setTimeout(() => {
        const element = this.elementRef.nativeElement;
        if(element.offsetWidth < element.scrollWidth){
          element.title = element.innerHTML;
        }
      }, 500);
    }
  }
  

  @Directive({
      selector: '[customAutofocus]'
  })
  export class AutofocusDirective
  {
      private _autofocus;
      constructor(private el: ElementRef)
      {
      }
  
      ngOnInit()
      {
          if (this._autofocus || typeof this._autofocus === "undefined")
              this.el.nativeElement.focus();     
      }
  
      @Input() set customAutofocus(condition: boolean)
      {
          this._autofocus = condition != false;
      }
  }