import { Component, OnInit } from '@angular/core';
import {FormBuilder,FormGroup,SelectControlValueAccessor,Validators} from "@angular/forms"
import { UtilService } from 'src/app/services/util.service';
import { AllCountries } from 'src/app/common/staticData/allCountries';



@Component({
  selector: 'app-create-a3s-support-ticket',
  templateUrl: './create-a3s-support-ticket.component.html',
  styleUrls: ['./create-a3s-support-ticket.component.scss']
})
export class CreateA3sSupportTicketComponent implements OnInit {
  showProblemType=false
  supportForm:FormGroup;
  countryList: any = [];
  problemType=[];
  showBuckets=false
  selectedBucket=""

  isTechnicalIssue=false
  isTechnicalIssueSelected=false
  
  numberRegEx = /\-?\d*\.?\d{1,2}/;

  issueType = [
    {id:1,value:'Billing'},
    {id:2,value:'Subscription'},
    {id:3,value:'Technical'},
    ]
  problemTypeCascadeForBilling = [
    {id:1,value:'Add or Update VAT,tax id,PO Number'},
    {id:2,value:'Pricing details'},
    {id:3,value:'Payment methods'},
    {id:4,value:'Make a payment'},
    {id:5,value:'Refund request'},
    {id:6,value:'Cost Management'},
    {id:7,value:'Assistance with bill or usage'},
  ]

problemTypeCascadeForSubscription = [
  {id:1,value:'Transfer ownership of my subscription'},
  {id:2,value:'Re enable my subscription'},
  {id:3,value:'Cancel or switch my subscription'},
  {id:4,value:'unable to access my subscription'},
  {id:5,value:'purchase, signups or upgrade issues'},
  {id:6,value:'Benefits - Offers'},
]

showBucket= [{id:1,value:'Apps and Infrastructure'},
{id:2,value:'Data & AI'},
{id:3,value:'Infrastructure Health Monitoring'},
{id:4,value:'Application Health Monitoring'},
{id:5,value:'Cost Monitoring and Optimization'},
{id:6,value:'Governance'},
{id:5,value:'Security'},
{id:6,value:'Compliance'},
]
showSubBucketForAppsInfrastructure= [{id:1,value:'Architecture Blueprint'},
{id:2,value:'Management'},
]
showSubBucketForDatAI= [{id:1,value:'Data Migration'},
{id:2,value:'Data Governance'},
{id:1,value:'Data Analytics'},
{id:2,value:'DataOps'},
]
showSubBucketForInfrastructureHealthMonitoring= [{id:1,value:'Metrics'},
{id:2,value:'Insights'},
{id:1,value:'Alerts'},
]
showSubBucketForApplicationHealthMonitoring= [{id:1,value:'Metrics & Usage'},
{id:2,value:'Management'},
]
showSubBucketForCostMonitoringandOptimization= [{id:1,value:'Overview'},
{id:2,value:'Billing'},
]
showSubBucketForGovernance= [{id:1,value:'Enterprise Policies'},
{id:2,value:'Identity & Access Management'},
{id:2,value:'Auditing & Logging'},
]
showSubBucketForSecurity= [{id:1,value:'Security Posture'},
{id:2,value:'Recommendations'},
{id:2,value:'Alert Management'},
]
showSubBucketForCompliance= [{id:1,value:'Architecture Blueprint'},
{id:2,value:'Management'},
]

severity = [
      {id:1,value:'Severity A - availability 24/7'},
      {id:2,value:'Severity B - availability business hours or 24/7'},
      {id:3,value:'Severity C - Business hours'},
]



  constructor(private util:UtilService,private fb:FormBuilder) {

    this.supportForm=this.fb.group({
      issueType:[null,Validators.required],
      problemType:[null],
      showBucket:[null],
      showSubBucket:[null],
      problemSummary:[null,[Validators.required,Validators.maxLength(140)]],
      issueDetails:[null,[Validators.required,Validators.maxLength(20000)]],
      severity:[null,Validators.required],
      firstName:[null,[Validators.required,Validators.maxLength(65)]],
      lastName:[null,[Validators.required,Validators.maxLength(65)]],
      email:[null,[Validators.required,Validators.maxLength(200),Validators.email]],
      phoneNumber:[null,[Validators.required,Validators.minLength(6),Validators.maxLength(30),Validators.pattern(this.numberRegEx)]],
      country:[null,Validators.required],      
    })
    this.supportForm.controls.issueType.valueChanges.subscribe((data)=>{
      console.log(data)
      if(data=="Technical"){
        this.isTechnicalIssue=true
        console.log(true)
      }
      else{
        this.isTechnicalIssue=false
        console.log(false)
      }
      
     })
    this.supportForm.controls.showBucket.valueChanges.subscribe((data)=>{
      console.log(data)
     this.selectedBucket=data
     if (data)
    {
      this.isTechnicalIssueSelected=true
    }
    else{
      this.isTechnicalIssueSelected=false
    }


    })
  }


  get supportFormControls() {
    return this.supportForm.controls;
  }
  ngOnInit(): void {
    this.countryList = AllCountries.countryListAllIsoData
    
  }  

  submit(){
    let rawValue = this.supportForm.getRawValue()
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    var createdBy = get_Email.userName;
    rawValue['status'] = "open"
    rawValue['createdBy']= createdBy
    this.util.handleRequest('post','a3s_supportTicket_updateA3sTicket',[],rawValue,null,null,true).then(res=>{
      this.util.route(['/a3s/a3sSupport'])
      this.util.success('A3S support ticket submitted successful!')
    },err=>{
      this.util.error('Failed to submit A3S support!')
    })
  }
  changeissuetype(el) {
    console.log(el)
    if(el.value == "Billing"){
        this.problemType = this.problemTypeCascadeForBilling;
        this.supportForm.get('problemType').setValidators([Validators.required])
        this.supportForm.get('showBucket').setValidators(null)
        this.supportForm.get('showSubBucket').setValidators(null)
    }
    else if(el.value == "Subscription"){
      this.problemType = this.problemTypeCascadeForSubscription;
      this.supportForm.get('problemType').setValidators([Validators.required])
        this.supportForm.get('showBucket').setValidators(null)
        this.supportForm.get('showSubBucket').setValidators(null)
    }
    else{
      this.problemType=[];
      this.supportForm.get('problemType').setValidators(null)
      this.supportForm.get('showBucket').setValidators([Validators.required])
      this.supportForm.get('showSubBucket').setValidators([Validators.required])
    }
      this.supportForm.updateValueAndValidity()
    
     
    
   }
}
