<div class="introduction">
          <p class="heading" id="2">Resource Management </p>
          <label>Follow these steps to navigate the management page: </label>
          <ol>
              <li>Click <b>Infrastructure deployment and management</b> on the homepage. </li>
              <li>Click <b>Management</b> from the left menu. </li> 
              <img src="assets\user-manual\apps-&-infra\Overview(1).svg" >
              <figcaption class="caption">Management Tab overview </figcaption>
              <li>Click a project and view its dashboard.  </li>
              <li>To view resources of same type, click resource type (for example, Virtual Machines).  </li>
              <li>Click specific resource to obtain its details and to take actions to modify that resource, for example to view details and to modify a specific Virtual Machine. .</li>
              <img src="assets\user-manual\apps-&-infra\resource-detail.png" >
              <figcaption class="caption">Resource details </figcaption>
              <li>Click on ellipsis (<img class="ellipis" src="assets\user-manual\Vector.svg">) 
                to find list of actions that can be performed for a resource (actions are used to modify the resource configuration). </li>
              <li>Actions either require single click or a side sheet to be filled with necessary inputs. </li>
              <img src="assets\user-manual\apps-&-infra\Overview(2).svg" >
              <figcaption class="caption">Actions </figcaption>
              <li>Use filters at the top to drill down further into the project to subscriptions & resource groups.  </li>
          </ol> 
          
</div>
