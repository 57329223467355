<div class="introduction">

            <p class="heading" id="0">Manage Policy </p>
            <p class="mb-0" >Modify and existing assigned policy. </p>
            <img src="assets\user-manual\compliance\Manage-Policy-2.svg">
            <figcaption class="caption mb-3">Edit policy </figcaption>

            <p class="heading" id="1" style="font-size: 32px;">Assign New Policies </p>
            <ul class="mb-0" >
                <li>Click <b>Assign Policy</b>.  </li>
                <img src="assets\user-manual\compliance\Assign-Policy.svg">
                <figcaption class="caption">Assign policy</figcaption>
                <li>Select scope for the policy. </li>
                <img src="assets\user-manual\compliance\Manage-Policy-3.svg">
                <figcaption class="caption">Assign policy scope </figcaption>
                <li>Add exclusion (it’s optional). </li>
                <img src="assets\user-manual\compliance\Manage-Policy-0.svg">
                <figcaption class="caption">Assign policy – exclusion</figcaption>
                <li>Provide <b>Assignment</b> name.</li>
                <li>Select <b>Policy enforcement</b> (Enabled/Disabled). </li>
                <li>Write Non-compliance message. </li>
            </ul>
            <img src="assets\user-manual\compliance\Manage-Policy-1.svg">
            <figcaption class="caption mb-3">Assign policy - non-compliance message </figcaption>

            <p class="heading" id="2" style="font-size: 32px;">Create Exemption in the Policy  </p>
            <img src="assets\user-manual\compliance\Create-Exemption.svg">
            <figcaption class="caption">Create exemption </figcaption>
         
    
</div>
