import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-budget-and-alerts',
  templateUrl: './budget-and-alerts.component.html',
  styleUrls: ['./budget-and-alerts.component.scss']
})
export class BudgetAndAlertsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
