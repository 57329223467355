<div class="security-recommednation-container">
    <div class="header-container">
      <h2 class="title">Identity Security</h2>
    </div>
    
     <div class="custom-filter-wrapper flex-custom">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                        <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                        <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                        <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                        <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button>
    </div>

    <div class="dashboard-body">
      <div class="row">
        <div class="col-md-4 padding-div left">
          <div class="graph-div count-card-height mt-2">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Failed User Authentication</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.failedUserAuthenticationCard)}} . {{getTimeFilterVal(failedUserAuthenticationCard)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('failedUserAuthenticationCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter1">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="failedUserAuthenticationCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,failedUserAuthenticationCard):applyFilter(row.id,failedUserAuthenticationCard)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="row m-0 mt-3" *ngIf="failedUserAuthenticationCard?.tableData?.length && !failedUserAuthenticationCard?.showLoader">
                  <ng-container *ngFor="let item of failedUserAuthenticationCard?.tableData">
                      <div class="detail-top" [ngClass]="{'col-sm-6':failedUserAuthenticationCard?.tableData.length>1,'col-sm-12':failedUserAuthenticationCard?.tableData.length<2}">
                          <div class="detail-card">
                              <span class="card-name" [title]="item.name">{{item.name}}</span>
                              <span class="card-count">{{item.count}}</span>
                          </div>
                      </div>
                  </ng-container>
              </div>
              <div class="loader-gif loader-for-card" *ngIf="failedUserAuthenticationCard?.showLoader||failedUserAuthenticationCard?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center count-card-text" *ngIf="(!failedUserAuthenticationCard?.tableData?.length && !failedUserAuthenticationCard?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 mt-3">No authentication failure found.</p>
              </div>
          </div>
      </div>
        <div class="col-md-4 padding-div pl-4 middle">
          <div class="graph-div count-card-height mt-2">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Top 4 Multiple Password Reset</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.multiplePasswordResetCard)}} . {{getTimeFilterVal(multiplePasswordResetCard)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('multiplePasswordResetCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter2')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter2">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="multiplePasswordResetCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,multiplePasswordResetCard):applyFilter(row.id,multiplePasswordResetCard)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="row m-0 mt-3" *ngIf="multiplePasswordResetCard?.tableData?.length && !multiplePasswordResetCard?.showLoader">
                <ng-container *ngFor="let item of multiplePasswordResetCard?.tableData">
                    <div class="detail-top" [ngClass]="{'col-sm-6':multiplePasswordResetCard?.tableData.length>1,'col-sm-12':multiplePasswordResetCard?.tableData.length<2}">
                        <div class="detail-card mutliple-password-card">
                            <span class="card-name" [title]="item[0]">{{item[0]}}</span>
                            <span class="card-count">{{item[1]}}</span>
                        </div>
                    </div>
                </ng-container>
              </div>
              <div class="loader-gif loader-for-card" *ngIf="multiplePasswordResetCard?.showLoader||multiplePasswordResetCard?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center count-card-text" *ngIf="(!multiplePasswordResetCard?.tableData?.length && !multiplePasswordResetCard?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 mt-3">No password reset found.</p>
              </div>
          </div>
      </div>
        <div class="col-md-4 pl-4 right">
          <div class="graph-div count-card-height mt-2">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Top 4 SSH Brute Force Attacks</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.sshBruteForceAttackCard)}} . {{getTimeFilterVal(sshBruteForceAttackCard)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('sshBruteForceAttackCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter3')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter3">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="sshBruteForceAttackCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,sshBruteForceAttackCard):applyFilter(row.id,sshBruteForceAttackCard)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="row m-0 mt-3" *ngIf="sshBruteForceAttackCard?.tableData?.length && !sshBruteForceAttackCard?.showLoader">
                <ng-container *ngFor="let item of sshBruteForceAttackCard?.tableData">
                    <div class="detail-top" [ngClass]="{'col-sm-6':sshBruteForceAttackCard?.tableData.length>1,'col-sm-12':sshBruteForceAttackCard?.tableData.length<2}">
                        <div class="detail-card ssh-card">
                            <span class="card-name" [title]="item[0]">{{item[0]}}</span>
                            <span class="card-count">{{item[9]}}</span>
                        </div>
                    </div>
                </ng-container>
              </div>
              <div class="loader-gif loader-for-card" *ngIf="sshBruteForceAttackCard?.showLoader||sshBruteForceAttackCard?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center count-card-text" *ngIf="(!sshBruteForceAttackCard?.tableData?.length && !sshBruteForceAttackCard?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 mt-3">No SSH brute force attack detected.</p>
              </div>
          </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-12 right">
          <div class="graph-div card-height">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Failed User Authentication<img class="tooltip-icon" src="/assets/Infra/info.svg" alt=""
                            matTooltip="Identifies the authentication failure by a user present in the Linux host."
                            matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.failedUserAuthentication)}} . {{getTimeFilterVal(failedUserAuthentication)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('failedUserAuthentication')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('FailedUserAuthentication','tableFailedUA')"><img src="assets/security/download_icon.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter4')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter4">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="failedUserAuthentication?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,failedUserAuthentication):applyFilter(row.id,failedUserAuthentication)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="failedUserAuthentication?.tableData?.length && !failedUserAuthentication?.showLoader">
                <table class="table" id="tableFailedUA">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>User Account</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedUserAuthentication',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Source IP</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedUserAuthentication',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Host</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedUserAuthentication',8)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Host IP</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedUserAuthentication',7)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Time Generated (UTC)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedUserAuthentication',3)">
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of failedUserAuthentication.tableData" class="cursor-pointer">
                        <ng-container (click)="openPopup('failedUserAuthentication',row,failedLoginAttemptsPopup)">
                          <td (click)="openPopup('failedUserAuthentication',row,failedLoginAttemptsPopup)">{{row[1]}}</td>
                          <td (click)="openPopup('failedUserAuthentication',row,failedLoginAttemptsPopup)">{{row[0]}}</td>
                          <td (click)="openPopup('failedUserAuthentication',row,failedLoginAttemptsPopup)">{{row[8].split('/')[row[8].split('/').length-1]}}</td>
                          <td (click)="openPopup('failedUserAuthentication',row,failedLoginAttemptsPopup)">{{row[7]}}</td>
                          <td (click)="openPopup('failedUserAuthentication',row,failedLoginAttemptsPopup)">{{row[3]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        </ng-container>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="loader-gif custom-loader-gif" *ngIf="failedUserAuthentication?.showLoader||failedUserAuthentication?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!failedUserAuthentication?.tableData?.length && !failedUserAuthentication?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-16-26 mt-3">No authentication failures found 
                    in the selected timeframe.</p>
              </div>
          </div>
      </div>
        <div class="col-md-12 right">
          <div class="graph-div card-height">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>SSH Brute Force Attack<img class="tooltip-icon" src="/assets/Infra/info.svg" alt=""
                            matTooltip="Identifies SSH brute force attack from unknown users to the Linux hosts from a source IP."
                            matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.sshBruteForceAttack)}} . {{getTimeFilterVal(sshBruteForceAttack)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('sshBruteForceAttack')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('SSH Brute Force Attack','tableSSH')"><img src="assets/security/download_icon.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter5')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter5">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="sshBruteForceAttack?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,sshBruteForceAttack):applyFilter(row.id,sshBruteForceAttack)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="sshBruteForceAttack?.tableData?.length && !sshBruteForceAttack?.showLoader">
                <table class="table" id="tableSSH">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Source IP</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('sshBruteForceAttack',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Host</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('sshBruteForceAttack',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Total Logon Attempts</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('sshBruteForceAttack',9)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>First Logon Attempt (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('sshBruteForceAttack',3)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Latest Logon Attempt (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('sshBruteForceAttack',4)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Time Between Logon Attempts (hours)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('sshBruteForceAttack',11)">
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of sshBruteForceAttack.tableData" class="cursor-pointer">
                        <ng-container (click)="openPopup('sshBruteForceAttack',row,sshPopup)">
                            <td (click)="openPopup('sshBruteForceAttack',row,sshPopup)">{{row[0]}}</td>
                            <td (click)="openPopup('sshBruteForceAttack',row,sshPopup)">{{row[1].split('/')[row[1].split('/').length-1]}}</td>
                            <td (click)="openPopup('sshBruteForceAttack',row,sshPopup)">{{row[9]}}</td>
                            <td (click)="openPopup('sshBruteForceAttack',row,sshPopup)">{{row[3]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                            <td (click)="openPopup('sshBruteForceAttack',row,sshPopup)">{{row[4]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                            <td (click)="openPopup('sshBruteForceAttack',row,sshPopup)">{{row[11]}}</td>
                        </ng-container>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="loader-gif custom-loader-gif" *ngIf="sshBruteForceAttack?.showLoader||sshBruteForceAttack?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!sshBruteForceAttack?.tableData?.length && !sshBruteForceAttack?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-16-26 mt-3">No SSH brute force attack detected 
                    in the selected timeframe..</p>
              </div>
          </div>
      </div>
        <div class="col-md-12 right">
          <div class="graph-div card-height mb-4">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Multiple Password Reset<img class="tooltip-icon" src="/assets/Infra/info.svg" alt=""
                            matTooltip="Shows failed and successful password reset attempts on the Azure AD user."
                            matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.multiplePasswordReset)}} . {{getTimeFilterVal(multiplePasswordReset)}}</p>
                      </div>
                      <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('multiplePasswordReset')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('MultiplePasswordReset','tableMultiple')"><img src="assets/security/download_icon.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter6')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter6">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="multiplePasswordReset?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,multiplePasswordReset):applyFilter(row.id,multiplePasswordReset)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  </div>
              </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="multiplePasswordReset?.tableData?.length && !multiplePasswordReset?.showLoader">
                <table class="table" id="tableMultiple">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>User</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',8)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Email</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Count of Password Resets</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>First Reset Time (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',2)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Last Reset Time (UTC)</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',3)">
                          </div>
                      </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of multiplePasswordReset.tableData" class="cursor-pointer">
                        <ng-container (click)="openPopup('multiplePasswordReset',row,multiplePasswordPopup)">
                            <td (click)="openPopup('multiplePasswordReset',row,multiplePasswordPopup)">{{row[8]}}</td>
                            <td (click)="openPopup('multiplePasswordReset',row,multiplePasswordPopup)">{{row[0]}}</td>
                            <td (click)="openPopup('multiplePasswordReset',row,multiplePasswordPopup)">{{row[1]}}</td>
                            <td (click)="openPopup('multiplePasswordReset',row,multiplePasswordPopup)">{{row[2]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                            <td (click)="openPopup('multiplePasswordReset',row,multiplePasswordPopup)">{{row[3]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                        </ng-container>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div class="loader-gif custom-loader-gif" *ngIf="multiplePasswordReset?.showLoader||multiplePasswordReset?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!multiplePasswordReset?.tableData?.length && !multiplePasswordReset?.showLoader)">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-16-26 mt-3">No password reset attempts were made 
                    in the selected timeframe.</p>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>

  <ng-template #multiplePasswordPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Multiple Password Reset</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row multiple-password-poup">
                <div class="col-sm-3">
                    <span class="title-val">User</span>
                    <span class="title-details">{{detailsPop[8]}}</span>
                </div>
                <div class="col-sm-3">
                    <span class="title-val">Email</span>
                    <span class="title-details">{{detailsPop[0]}}</span>
                </div>
                <div class="col-sm-6">
                    <span class="title-val">Count of Password Reset</span>
                    <span class="title-details">{{detailsPop[1]}}</span>
                </div>
            </div>
            <div class="custom-fixed-tables">
                <table class="table" #table1>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Initiated By</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'initiatedBy')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time Generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'timeGenerated')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Result</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'result')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Message</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'message')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of detailsPop[9]">
                          <td>{{row.initiatedBy}}</td>
                          <td>{{row.timeGenerated|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                          <td>
                            <div class="chip" [ngClass]="getStatusClass(row?.result)">
                                {{row?.result | titlecase }}
                              </div>
                          </td>
                          <td>{{row.message}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!detailsPop[9]?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFileCustom('MPRPopup')" *ngIf="detailsPop[9]?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #sshPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>SSH Brute Force Attack</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row multiple-password-poup">
                <div class="col-sm-3">
                    <span class="title-val">Source IP</span>
                    <span class="title-details">{{detailsPop[0]}}</span>
                </div>
                <div class="col-sm-3">
                    <span class="title-val">Host</span>
                    <span class="title-details">{{detailsPop[1].split('/')[detailsPop[1].split('/').length-1]}}</span>
                </div>
                <div class="col-sm-3">
                    <span class="title-val">Host IP</span>
                    <span class="title-details">{{detailsPop[2]}}</span>
                </div>
                <div class="col-sm-3">
                    <span class="title-val">Total Logon Attempts</span>
                    <span class="title-details">{{detailsPop[9]}}</span>
                </div>
            </div>
            <div class="custom-fixed-tables">
                <table class="table" #tableSSH1>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Username</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',12,'username')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Port</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',12,'port')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time Generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',12,'timeGenerated')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Severity </span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',12,'severity')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Message</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',12,'message')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of detailsPop[12]">
                          <td>{{row.username}}</td>
                          <td>{{row.port}}</td>
                          <td>{{row.timeGenerated|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                          <td>
                            <div class="chip" [ngClass]="getStatusClass(row?.severity)">
                                {{row?.severity | titlecase }}
                              </div>
                          </td>
                          <td class="ssh-message">{{row.message}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!detailsPop[12]?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFileCustom('sshPopup')" *ngIf="detailsPop[12]?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #failedLoginAttemptsPopup>
    <div class="medium-popup-wrapper2 width-877">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Failed User Authentication</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row details-summary">
                <label class="summary-label col-sm-12">Summary</label>
                <div class="col-sm-3 column-detail">User Account</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[1]}}</div>
                <div class="col-sm-3 column-detail">User Type</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[2]}}</div>
                <div class="col-sm-3 column-detail">Source IP</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[0]}}</div>
                <div class="col-sm-3 column-detail">Severity</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[9]}}</div>
                <div class="col-sm-3 column-detail">Host</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[8].split('/')[detailsPop[8].split('/').length-1]}}</div>
                <div class="col-sm-3 column-detail">Host IP</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[7]}}</div>
                <div class="col-sm-3 column-detail">Process Name</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[6]}}</div>
                <div class="col-sm-3 column-detail">Process ID</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[5]}}</div>
                <div class="col-sm-3 column-detail">Time Generated (UTC)</div>
                <div class="col-sm-7 column-detail">: {{detailsPop[3]|date:'MM/dd/yyyy, hh:mm:ss a'}}</div>
                <div class="col-sm-12 diff-line"></div>
                <div class="col-sm-12 message-details">
                    <span class="title-val">Message</span>
                    <span class="title-details">{{detailsPop[4]}}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>

  <ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>