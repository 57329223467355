<router-outlet></router-outlet>
<div *ngIf="allowUrl()">
    <div class="header-container" >
        <h2 class="title">Dedicated Compute</h2>
        <span class="content-14 blur-text">Deployment</span>
    </div>
    
    <div class="dedicated-compute-body">
        <div class="dcRow d-flex" *ngFor="let item of dedicatedComputeMenus">
            <ng-container (click)="routeToChild(item?.route)">
                <div class="dc-img">
                    <img [src]="item?.image" alt="">
                </div>
                <div class="dc-options-details">
                    <h3 class="bold-600">{{item?.value}}</h3>
                    <p>{{item?.details}}</p>
                </div>
            </ng-container>
            
        </div>
    
    </div>
</div>


