import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { SecurityBucketState } from 'src/app/state/security-bucket.state';

@Component({
  selector: 'app-security-alert-management',
  templateUrl: './security-alert-management.component.html',
  styleUrls: ['./security-alert-management.component.scss']
})
export class SecurityAlertManagementComponent implements OnInit {
  masterFilterObject:any;
  searchFilterObject: any;
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }

  searchKey:any;
  resourceList: any = []; 
  datasort: string;
  alertsData: any={
    tableData:[],
    showLoader:true
  };
  subsName: any;
  subscriptionList: any=[];
  alertResourceAffected: any=[];
  showAlertDetails:boolean=false;
  rowData: any;
  currentTab='alertDetails';
  azureResource: any=[];
  ipResource: any=[];
  networkConnResource: any=[];
  hostResource: any=[];
  showMore: any={};
  subscriptionInfra: Subscription;
  currentProject: any;
  tempAlertData: any=[];
  @Select(SecurityBucketState.currentProjectDetail) currentProjectDetail$: Observable<any>;
  
  constructor(private util:UtilService) { }

  async ngOnInit() {
    $('.main-content').addClass('background-grey');
    this.subscriptionInfra = this.currentProjectDetail$.subscribe((res) => {
      if(res){
        this.currentProject = _.cloneDeep({...res});//res.currentFilteredProject;
        this.triggerFilter(this.currentProject,'project')
        // this.util.setObservable('currentFilteredProject',null)
        this.getAlertsResult();
      }
    });
  }

  ngOnDestroy() {
    $('.main-content').removeClass('background-grey');
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

  // getAlertsData(){ 
  //   const tempSubList=_.cloneDeep(this.subscriptionList);
  //   let body ={
  //     "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
  //     //"query": "securityresources  | where type =~ 'microsoft.security/locations/alerts'  | where properties.Status in ('Active')  | where properties.Severity in ('Low', 'Medium', 'High')  | extend SeverityRank = case(    properties.Severity == 'High', 3,    properties.Severity == 'Medium', 2,    properties.Severity == 'Low', 1,    0    )    | extend severity = properties.Severity    | extend displayName = properties.AlertDisplayName    | extend status = properties.Status    | extend id2 = reverse(properties.ResourceIdentifiers[0].AzureResourceId) | extend splited = split(id2,'/')[0] | extend id1 = reverse(splited) | project-rename affectedResource = id1    | extend splited2 = split(id2,'/')[1] | extend id3 = reverse(splited2) | project-rename resourceType = id3    | extend id5 = properties.ResourceIdentifiers[0].AzureResourceId | extend splited3 = split(id5,'/')[4] | project-rename RG = splited3    | extend tolower_affectedResource = tolower(affectedResource)    |extend activityStartTime = properties.StartTimeUtc  | sort by  SeverityRank desc, tostring(properties.SystemAlertId) asc  | project severity,displayName,affectedResource,resourceType,RG,activityStartTime,status,tolower_affectedResource,properties "
  //     "query":"securityresources | where type =~ 'microsoft.security/locations/alerts' | where properties.Status in ('Active') |where properties.Severity in ('Low', 'Medium', 'High') | extend SeverityRank = case(   properties.Severity == 'High', 3,   properties.Severity == 'Medium', 2,   properties.Severity == 'Low', 1,   0   ) | extend severity = properties.Severity | extend displayName = properties.AlertDisplayName | extend status = properties.Status | extend id2 = reverse(properties.ResourceIdentifiers[0].AzureResourceId) | extend splited = split(id2,'/')[0] | extend id1 = reverse(splited) | project-rename affectedResource = id1 | extend splited2 = split(id2,'/')[1] | extend id3 = reverse(splited2) | project-rename resourceType = id3 | extend id5 = properties.ResourceIdentifiers[0].AzureResourceId | extend splited3 = split(id5,'/')[4] | project-rename RG = splited3 | extend tolower_affectedResource = tolower(affectedResource) |extend activityStartTime = properties.StartTimeUtc | sort by  SeverityRank desc, tostring(properties.SystemAlertId) asc | project severity,displayName,affectedResource,resourceType,RG,activityStartTime,status,tolower_affectedResource,mitreAttact = properties.Intent "
  // }  
  //   this.util.handleRequest('post','a3s_security_getDataByQuery',[],body,null,null,true).then(res=>{
  //     if(!res || !res.body) return 
  //     console.log('DATA',res);
  //     let tableData = res.body.data || [];
  //     if(tableData.length){
  //       const result = [];
  //       const map = new Map();
  //       for (const item of tableData) {
  //         if (!map.has(item.tolower_affectedResource)) {
  //           map.set(item.tolower_affectedResource, true); 
  //           result.push(item);
  //         }
  //       }
  //       this.alertResourceAffected = result;
  //     }
  //   });
  // }

  filterAlertSeverity(severity,flag){
   const filterData= this.alertsData.tableData.filter(dt=>dt.properties.severity==severity);
   let perc=((filterData.length/this.alertsData.tableData.length)*100);
    return flag=='perc'?isNaN(perc)?0:perc:filterData;
  }

  filterAlertStatus(status){
    const filterData= this.alertsData.tableData.filter(dt=>dt.properties.status==status);
    return filterData.length
  }

  filterResourceType(resourceIdentifiers){
    if(resourceIdentifiers.length){
      let tempData=_.cloneDeep(resourceIdentifiers);
      return tempData.map(dt=>dt.type).join(", ");
    }else{
      return '';
    }
  }

  filterRG(rowId){
    let value=rowId.split("/");
    return value[4];
  }
  
  async getAlertsResult(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
    }
    this.alertsData.showLoader = true;
    this.alertsData.tableData = [];
    await this.util.handleRequest('post','a3s_security_getAlertsData',[],body,null,null,true).then(res=>{
      if(!res || !res.body) {
       this.alertsData.showLoader = false;
       return 
      }
     this.tempAlertData=res.body.value||[];
     this.filterAlertData();
     this.alertsData.showLoader = false;
    },err=>{
      this.alertsData.showLoader = false
    })
  }
  
  getAlertResourceAffected(){
     const result = [];
     const map = new Map();
     for (const item of this.alertsData.tableData) {
       if (item.properties&&item.properties.compromisedEntity&&!map.has(item.properties.compromisedEntity)) {
         map.set(item.properties.compromisedEntity, true); 
         result.push(item);
       }
     }
     return result.length;
  }

  filterAlertData(){
    this.alertsData.tableData=this.tempAlertData.filter((dt)=>{
      return this.filterObject.subscriptionList.find(dtl=>dtl.checked&&dtl.subscriptionId===(dt.id.split("/")[2])) && this.filterObject.resourceGroupList.find(dtl=>dtl.checked&&dtl.resourceGroupName.toLowerCase()===this.filterRG(dt.id).toLowerCase()) && this.filterObject.resourceList.find(dtl=>dtl.checked&&dtl.resourceName.toLowerCase()===dt.properties?.compromisedEntity.toLowerCase())
    })
  }

  openAlertDtls(rowDtls){
    this.showAlertDetails=true;
    this.currentTab = 'alertDetails';
    this.rowData=rowDtls;
    this.azureResource=rowDtls.properties.entities.filter(dt=>dt.type=="azure-resource");
    this.ipResource=rowDtls.properties.entities.filter(dt=>dt.type=="ip");
    this.networkConnResource=rowDtls.properties.entities.filter(dt=>dt.type=="network-connection");
    this.hostResource=rowDtls.properties.entities.filter(dt=>dt.type=="host");
  }

  filterValue(item){
    if(item.key=='activity end time (UTC)'||item.key=='activity start time (UTC)'){
      return moment(item.value).format('MM/DD/YYYY hh:mm:ss A');
    }else{
      return item.value
    }
  }

  getShowMore(itemKey){
    let val=itemKey.split(" ").join('_');
   return this.showMore[val];
  }

  checkShow(itemKey){
    let val=itemKey.split(" ").join('_');
   return this.showMore[val];
  }

  changeShow(itemKey){
    let val=itemKey.split(" ").join('_');
    this.showMore[val]=!this.showMore[val];
  }

  jsonValue(item){
    try{
      return JSON.parse(item.value)
    }catch{
      return false
    }
  }

  disableAction(remediationSteps){
    try{
      if(remediationSteps&&remediationSteps.length){
        JSON.parse(remediationSteps[0]);
        return true
      }else{
        return true;
      }
    }catch{
      return false
    }
  }

  async refreshData(type){
    switch (type) {
      case 'alerts':{
        this.getAlertsResult();
        break;
      }
    }
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  downloadDirectTableView(name,tableId){
    let table=document.getElementById(tableId);
    this.downloadFile(name,table)
  }

  downloadFile(name,tableId){
    this.util.generateCSVForTableData(name,tableId);
  }


  /******************************************** Filter Functionalities *****************************************/

   triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    console.log('Filter Object',this.filterObject);
    this.filterAlertData();
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    this.filterAlertData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'max': {
              let x = a.properties.score? a.properties.score.max: ' ';
              let y = b.properties.score? b.properties.score.max: ' ';
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

}
