import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-new-alerts',
  templateUrl: './create-new-alerts.component.html',
  styleUrls: ['./create-new-alerts.component.scss']
})
export class CreateNewAlertsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
