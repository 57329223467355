
 <div class="introduction">
  <p class="heading" id="4">Revenue Prediction </p>
  <p>This use case estimates the revenue prediction based on the factor's dependent on it over a certain period.</p>
    <ol>
        <li>Click <b>Data analytics -> Create project -> Revenue Predictions.</b>  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePredictions.svg" >
        <figcaption class="caption">Revenue prediction - use case </figcaption>
        <li>Provide source details to connect to source system.  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePredictions(1).svg" >
        <figcaption class="caption">Revenue prediction - source details </figcaption>
        <li>Prepare data by selecting <b>Table, Prediction column,</b> and <b>Train ration ( give value : 10 for demo)</b>  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePredictions(2).svg" >
        <figcaption class="caption">Revenue prediction - prepare data </figcaption>
        <li>Select Choose method as <b>Linear Regression</b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePredictions(3).svg" >
        <figcaption class="caption">Revenue prediction - analysis method </figcaption>
        
        <li>Click <b>Finish</b> or View in <b>A3S BI Dashboard.</b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePredictions(4).svg" >
        <figcaption class="caption"> Revenue prediction - output </figcaption>
        <li> Project summary page.  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePredictions(6).svg" >
        <figcaption class="caption">Revenue prediction - project summary </figcaption>
        <li>Output in the A3S BI Dashboard. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePrediction(5).svg" >
        <figcaption class="caption"> Revenue prediction - BI dashboard </figcaption>
        <li>In case of error/failure, Failure message will be displayed. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePredictions(7).svg" >
        <figcaption class="caption">Revenue prediction - failure </figcaption>
   
    </ol>
</div>
