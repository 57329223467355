<div class="introduction">
  <p class="heading" id="3">Sales Predictions.</p>

  <p>This use cases takes input from the past sales data to predict future sales performance and
     visualize them in a time series graph </p>
    <ol>
        <li>Click <b>Data analytics -> Create new project -> Sales Predictions.</b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\RevenuePredictions.svg">
        <figcaption class="caption">Sales Predictions – use case</figcaption>
        <li>Provide Project name and Source Details to connect to the source system. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Sales-Predictions(2).svg" >
        <figcaption class="caption">Sales prediction - source details </figcaption>
        <li>Prepare data by selecting <b>Table, Prediction column,</b> and <b>Train ration ( put value : 10 for demo)</b> </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Sales-Predictions(3).svg" >
        <figcaption class="caption">Sales prediction - prepare data </figcaption>
        <li>Select Choose method as <b>Linear Regression</b>  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Sales-Predictions(4).svg" >
        <figcaption class="caption">Sales predictions - analysis method </figcaption>
        <li>Click <b>Submit.</b>  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Sales-Predictions(5).svg" >
        <figcaption class="caption">Sales predictions - output </figcaption>
        <li>Click View <b>in A3S BI dashboard.</b>  </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Sales-Predictions(6).svg" >
        <figcaption class="caption">Sales predictions - BI dashboard </figcaption>
        <li>View project summary by clicking Finish on the Output page. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Sales-Predictions(7).svg" >
        <figcaption class="caption">Sales predictions - project summary </figcaption>
        <li>In case of error/failure, Failure message will be displayed. </li>
        <img src="assets\user-manual\data-&-AI\data-analytic\Sales-Predictions-fail.svg" >
        <figcaption class="caption">Sales predictions - failure  </figcaption>

     
    
    </ol>
</div>
