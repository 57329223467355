import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimechartService {

  constructor() { }

  xAxisDateTimeFormatList(filterDate,timeZone,dataObj,type?:any) {
    const list=[{name:'15 sec',value:0.15},
      {name:'30 sec',value:0.30},
      {name:'1 min',value:1},
      {name:'2 min',value:2},
      {name:'5 min',value:5},
      {name:'15 min',value:15},
      {name:'30 min',value:30},
      {name:'1hr',value:60},
      {name:'3hr',value:180},
      {name:'6hr',value:360},
      {name:'12hr',value:720},
      {name:'1D',value:1440},
      {name:'3D',value:4320},
      {name:'7D',value:10080},
      {name:'15D',value:21600},
      {name:'1Month',value:43200},
      {name:'3Months',value:129600},
      {name:'6Months',value:259200},
      {name:'1 Year',value:525600}
    ];
    const diffMins=moment.duration(moment(filterDate.to).diff(filterDate.from)).as('minutes');
    const diff=type=='insights'?Number(diffMins/8):Number(diffMins/4)
    const data=list.find(dt=>dt.value>diff||dt.value==diff);
    let dateList = [];    
    let compareVal = moment(filterDate.from).format('YYYY-MM-DD'+' 00:00');
    let firstVal;
    while (moment(filterDate.from).valueOf() > moment(compareVal).valueOf()||moment(filterDate.from).valueOf() == moment(compareVal).valueOf()) {
      compareVal = moment(compareVal).add(data.value, 'minutes').format('YYYY-MM-DD hh:mm A');
      firstVal = moment(compareVal).format('YYYY-MM-DD hh:mm A');
    }
    
    while(moment(filterDate.to).valueOf()> moment(firstVal).valueOf()) {
      let tempDate=timeZone ==='local'?moment(firstVal).format('MM/DD/YYYY hh:mm A Z'):moment(firstVal).format('MM/DD/YYYY hh:mm A');
      if(diffMins<2881) {
        let tempVal={
          matchVal:timeZone ==='local'?moment(firstVal).local().format('MM/DD/YYYY hh:mm A'):moment(firstVal).format('MM/DD/YYYY hh:mm A'),
          valueXaxis:moment(tempDate).format('hh:mm A'),
          format:'hh:mm A'
        }
        if(tempVal.valueXaxis== '12:00 AM') {
          tempVal.valueXaxis = moment(tempDate).format('ddd D');
          tempVal.format = 'ddd D';
        }
        dateList.push(tempVal);
      } else if(diffMins<43201) {
        let format='ddd D';
        let val=timeZone ==='local'?moment(firstVal).local().format('ddd D'):moment(firstVal).format('ddd D');
          if(dateList.length&&val===dateList[dateList.length-1].valueXaxis) {
            val = timeZone ==='local'?moment(firstVal).local().format('hh:mm A'):moment(firstVal).format('hh:mm A');
            format='hh:mm A'
          }
        let tempVal={
          matchVal:timeZone ==='local'?moment(firstVal).local().format('MM/DD/YYYY hh:mm A'):moment(firstVal).format('MM/DD/YYYY hh:mm A'),
          valueXaxis:val,
          format:format
        }
        dateList.push(tempVal);
      } else if(diffMins<525600) {
        let format='MMM D';
        let val=timeZone ==='local'?moment(firstVal).local().format('MMM D'):moment(firstVal).format('MMM D');
        if(dateList.length&&val===dateList[dateList.length-1].valueXaxis) {
          val = timeZone ==='local'?moment(firstVal).local().format('YYYY MMMM D'):moment(firstVal).format('YYYY MMMM D');
          format='YYYY MMMM D'
        }
        let tempVal={
          matchVal:timeZone ==='local'?moment(firstVal).local().format('MM/DD/YYYY hh:mm A'):moment(firstVal).format('MM/DD/YYYY hh:mm A'),
          valueXaxis:val,
          format:format
        }
        dateList.push(tempVal);
      }
      firstVal=moment(dateList[dateList.length - 1].matchVal).add(data.value, 'm').format('YYYY-MM-DD hh:mm A');
    }
    if(dateList[0].matchVal!=moment(filterDate.from).format('MM/DD/YYYY hh:mm A')) {
      dateList.splice(0,0,{valueXaxis:''});
    }
    return dateList;
  }
}
