<div class="favourite-body">
    <div class="container">
        <app-breadcrumbs></app-breadcrumbs>

        <div class="header-container">
            <h2 class="title">Favorites</h2>
        </div>

        <div class="content">
            <div class="row">
                <div class="col-md-6" *ngFor="let list of bucketList;index as i">
                    <div class="title text-14-22 bold-600">{{list?.name}}</div>
                    <div class="wrapper" *ngFor="let tab of list?.tabs; index as j">
                        <input type="checkbox" id="tab_{{i}}_{{j}}"  name="tab{{i}}_{{j}}" [checked]="tab.checked" (change)="UpdateArray($event,tab)">
                        <label for="tab_{{i}}_{{j}}" id="tab_label_{{i}}_{{j}}">{{tab?.name}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>