<div class="introduction">
  
            <p class="heading" id="0">Default A3S Security Center Policy </p>
            <p class="mb-0" >Table shows the A3S Security Center policy details. </p>
            <img src="assets\user-manual\compliance\a3s-security.svg">
            <figcaption class="caption">Default Security Center policy </figcaption>
            <ul class="mb-0" >
                <li>Click ellipsis (<img class="ellipis" src="assets\user-manual\Vector.svg" >) to edit or delete assignment or create exemption. </li>
            </ul>
            <img style="width: 50%;" src="assets\user-manual\compliance\delete-dialog.svg" >
            <figcaption class="caption col-md-6">Unassign policy </figcaption>
       
          
         
    
</div>
