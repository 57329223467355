<div class="security-alert-management-container">
    <div class="header-container">
      <h2 class="title">Reports</h2>
    </div>
    <div class="dashboard-body">
      <div class="row">
        <div class="col-md-12 padding-div">
          <div class="graph-div" style="padding-right: 12px;">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Audit Reports</h4>
                      </div>
                  </div>
              </div>
              <div class="ml-auto" *ngIf="(reportsDetails?.length && !showLoader)">
                <div class="main-nav">
                  <ul class="nav-wrap m-0 custom-nav">
                    <li class="list-item cursor-pointer" *ngFor="let item of reportsGroup" [class.active]="currentTab == item.name" (click)="currentTab = item.name;filterTableData()">
                      <span class="menu-name">{{item.name}}</span>
                    </li>
                    <!-- <li class="list-item cursor-pointer" [class.active]="currentTab == 'ISO'" (click)="currentTab = 'ISO'" style="font-size: 13px;line-height: 20px;">
                      <span class="menu-name">ISO</span>
                    </li>
                    <li class="list-item cursor-pointer" [class.active]="currentTab == 'SOC'" (click)="currentTab = 'SOC'" style="font-size: 13px;line-height: 20px;">
                      <span class="menu-name">SOC</span>
                    </li>
                    <li class="list-item cursor-pointer" [class.active]="currentTab == 'PCI'" (click)="currentTab = 'PCI'" style="font-size: 13px;line-height: 20px;">
                      <span class="menu-name">PCI</span>
                    </li>
                    <li class="list-item cursor-pointer" [class.active]="currentTab == 'HITRUST'" (click)="currentTab = 'HITRUST'" style="font-size: 13px;line-height: 20px;">
                      <span class="menu-name">HITRUST</span>
                    </li>
                    <li class="list-item cursor-pointer" [class.active]="currentTab == 'us_goverment'" (click)="currentTab = 'us_goverment'" style="font-size: 13px;line-height: 20px;">
                      <span class="menu-name">US Government</span>
                    </li>
                    <li class="list-item cursor-pointer" [class.active]="currentTab == 'industry_regional'" (click)="currentTab = 'industry_regional'" style="font-size: 13px;line-height: 20px;">
                      <span class="menu-name">Industry & Regional</span> 
                    </li>-->
                  </ul>
                </div>
              </div>
              <div class="d-flex" *ngIf="(reportsDetails?.length && !showLoader)">
                <div class="enlist-srch main-search">
                    <img src="assets/icons/search.png" />
                    <input placeholder="Search to filter items" autocomplete="off" class="form-control" (keyup)="search($event.target.value)" type="text" id="projectSearch">
                    <!-- (keyup)="search($event.target.value)" [(ngModel)]="searchText"  -->
                </div>
                <div class="custom-filter-wrapper">
                    <div class="custom-filter">
                        <span class="text">Region</span>
                        <div class="filter-dropdown" id="filter-dropdown" >
                            <div class="checkbox-cs" *ngFor="let data of filterObject?.regionList; index as i">
                                <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}" [(ngModel)]="data.check"  name="f_listItem_{{i}}" (change)="triggerFilter($event,data,'region')">
                                <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data.name}}</label>
                            </div>
                            <div  class="mt-3" *ngIf="!filterObject?.regionList?.length">
                                <p  class="content-14 blur-text">No filter found</p>
                            </div>
                        </div>
                    </div>
                    <div class="custom-filter">
                        <span class="text">Regulatory standard</span>
                        <div class="filter-dropdown" id="filter-dropdown" >
                            <div class="checkbox-cs" *ngFor="let data of filterObject?.standardList; index as i">
                                <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}" [(ngModel)]="data.check"  name="f_listItem_{{i}}" (change)="triggerFilter($event,data,'standard')">
                                <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data.name}}</label>
                            </div>
                            <div class="mt-3" *ngIf="!filterObject?.standardList?.length">
                                <p  class="content-14 blur-text">No filter found</p>
                            </div>
                        </div>
                    </div>
                    <div class="custom-filter">
                        <span class="text">Industry</span>
                        <div class="filter-dropdown industry-filter" id="filter-dropdown" >
                            <div class="checkbox-cs" *ngFor="let data of filterObject?.industryList; index as i">
                                <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}" [(ngModel)]="data.check" name="f_listItem_{{i}}" (change)="triggerFilter($event,data,'industry')">
                                <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data.name}}</label>
                            </div>
                            <div  class="mt-3" *ngIf="!filterObject?.industryList?.length">
                                <p  class="content-14 blur-text">No filter found</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
              <div class="custom-fixed-tables m-0 mt-3" *ngIf="(reportsDetails?.length && !showLoader)">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Title</span>
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Download</span>
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Description</span>
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Standard</span>
                          </div>
                      </th>
                      <th class="borderBlueRight">
                          <div class="flex-custom no-wrap">
                              <span>Date</span>
                          </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let item of tableData">
                        <td [title]="item.fileName">{{item.fileName}}</td>
                        <td class="width-col-3"><a class="cursor-pointer" (click)="downloadFile(item)">Download</a></td>
                        <td [title]="item.fileDescription">{{item.fileDescription}}</td>
                        <td>{{item.standards.join(', ')}}</td>
                        <td class="width-col-11">{{item.reportDate|date}}</td>
                    </tr>
                  </tbody>
              </table>
              </div>
              <div class="loader-gif" *ngIf="showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="(!reportsDetails?.length && !showLoader)">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No data found</p>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>