<div class="introduction">
          <p class="heading" id="0">Data and AI</p>
          <p class="para">The Data & AI functional bucket enables modernization of data estates with 
              automated data migration and analytics while also providing comprehensive automated data 
              governance and DataOps capabilities. </p>
              <p>Data & AI has following sub-sections: </p>
            <ol>
                <li>Data Migration.</li>
                <li>Data Governance.</li>
                <li>Data Analytics</li>
                <li>DataOps.</li>
            </ol>
</div>
