<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
                <h4>Virtual Network</h4>
                <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box no-margin">
                    <ng-select class="margin-select" [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="VNetmodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <!-- <div [ngStyle]="{'width':item?.resourceGroupId?'84%':'auto'}"> -->
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item)"></span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" style="padding-top: 2px;" #anchor
                    (click)="onToggle(null)">Create new</p>
                </div>
            </div> 
            <div class="form-group">
                <div class="label-div">
                    <label for="VMname" class="control-label ">Enter virtual network name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual networks are logically isolated from each other in Azure. You can configure their IP address ranges, subnets, route tables, gateways, and security settings, much like a traditional network in your data center. Virtual machines in the same virtual network can access each other by default." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar" id="VNetName" autocomplete="off" [(ngModel)]="VNetmodel.resourceName" placeholder="Enter virtual network name" name="VirtualNetworkName" (keyup)="VNetNameValidation($event,80)" required>
                    <p class="text-danger" *ngIf="inputError?.VNetName">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateVnet">This name is already taken.</p>
                    <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>              
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="VMsize" class="control-label ">Virtual network address space<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The virtual network's address space specified as one or more address prefixes in CIDR notation (e.g. 10.0.0.0/16)." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box" *ngFor="let item of VNetmodel.resourceData.virtualNetworkAddressSpace;let i = index">
                    <div class="d-flex">
                        <input type="text" class="form-control" [class.border-danger]="item.VNetAdrspc" id="VNetAdrSpc" autocomplete="off" [(ngModel)]="item.address" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace" (keyup)="VNetAddressSpace($event,item,i)" required>
                        <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete.png" (click)="deleteAddress(i)" *ngIf="VNetmodel.resourceData.virtualNetworkAddressSpace.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
                    <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use {{item.validCIDRValue}} address space</p>
                    <p class="text-danger" *ngIf="item.overlooping">overlaps with address space {{item.overlapingValue}}</p>
                    <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
                </div> 
                <div class="add-another-tag"><p class="blue-text cursor-pointer lbl-btn" (click)="addNewAddress()">Add another address space</p></div>
            </div> 
            <div class="form-group">

                <div class="label-div d-flex">
                <label class="control-label " style="min-width: max-content;">Tag name </label>
                <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                </label>
                <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="VNetmodel.resourceData&&VNetmodel.resourceData.tagList&&VNetmodel.resourceData.tagList.length>1"></label>
                <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                </div>
                <div *ngFor="let item of VNetmodel.resourceData.tagList;let i = index">
                <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                    <input type="text" class="form-control" id="tagName" autocomplete="off"
                        [(ngModel)]="item.tagName" name="tagName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                    <input type="text" class="form-control" id="tagValue" autocomplete="off"
                        [(ngModel)]="item.value" name="tagValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTag(i)"
                        *ngIf="VNetmodel.resourceData.tagList.length>1"></span>
                </div>
                <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div  class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" (click)="addTag()">Add another tag</p>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label ">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="VNetmodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
        </div>
    </div>
</div>