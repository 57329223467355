<kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
    <div class='content vnet-resource-content'>
        <div class="rgPopup-form">
            <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
            <div class="input-box">
            <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" name="rgName" [customAutofocus]="true" required>
            <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
            <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
            <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
            </div>              
        </div>
        <div class="rgPopup-form">
            <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
            <div class="input-box">
            <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
            </ng-select>
            </div>
        </div>
        <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
            You don't have the access to create a new resource group for this subscription
        </div>
        <div class="d-flex">
            <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable()">Save</button>
            <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable()">Update</button>
            <button class="btn btn-outline" (click)="cancel()">Cancel</button>
        </div>
    </div>
</kendo-popup>