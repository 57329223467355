import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { TileData } from '../data-governance-overview/data-governance-overview.state';

export class DataGovernanceDdcStateModel {
  datasort?:string;
  dataMaskingPolicies?:TileData;
  dataMaskingRules?:TileData;
  currentSensitivityLabels?:TileData;
  recommendedSensitivityLabels?:TileData;
  classificationOverview?:TileData;
  classificationOverviewGroup?:{
    model?: {
      schema:any,
      table:any,
      informationType:any,
      label:any,
      searchTxt:string
    }
  };
}

export interface TileDetail {
  tileName: string;
  tileURLVal: string;
}

export interface TileRequest {
  subscriptionId: string;
  resourceGroup: string;
  workspaceName: string;
  sqlPoolName?: string;
  tileList: TileDetail[];
}

export class GetTilesDataDDCAction {
  static readonly type = '[DataGovernanceDdc] getTilesDataDDC';

  constructor(readonly payload: TileRequest) { }
}

export class SortTilesDataDDCAction {
  static readonly type = '[DataGovernanceDdc] sortTilesDataDDC';

  constructor(readonly tileName: string, readonly fieldName: string) { }
}

const DATA_GOVERNANCE_DDC_STATE_TOKEN = new StateToken<DataGovernanceDdcState>('dataGovernanceDdc');

@State<DataGovernanceDdcStateModel>({
  name: DATA_GOVERNANCE_DDC_STATE_TOKEN,
  defaults:{
    dataMaskingPolicies:{
      value:[],
      refreshedTime:new Date()
    },
    dataMaskingRules:{
      value:[],
      refreshedTime:new Date()
    },
    currentSensitivityLabels:{
      value:[],
      refreshedTime:new Date()
    },
    recommendedSensitivityLabels:{
      value:[],
      refreshedTime:new Date()
    },
    classificationOverview:{
      value:[],
      refreshedTime:new Date()
    },
    classificationOverviewGroup:{
      model: undefined,
      // dirty: false,
      // status: '',
      // errors: {}
    }
  }
})
@Injectable()
export class DataGovernanceDdcState {
  constructor(private readonly apiService:ApiService,private http:HttpClient) {}

  @Selector([DataGovernanceDdcState])
  static dataMaskingPolicies(state:DataGovernanceDdcStateModel): TileData {
    return state?.dataMaskingPolicies
  }
  
  @Selector([DataGovernanceDdcState])
  static dataMaskingRules(state:DataGovernanceDdcStateModel): TileData {
    return state?.dataMaskingRules
  }
  
  @Selector([DataGovernanceDdcState])
  static currentSensitivityLabels(state:DataGovernanceDdcStateModel): TileData {
    return state?.currentSensitivityLabels
  }
  
  @Selector([DataGovernanceDdcState])
  static recommendedSensitivityLabels(state:DataGovernanceDdcStateModel): TileData {
    return state?.recommendedSensitivityLabels
  }
  
  @Selector([DataGovernanceDdcState])
  static classificationOverview(state:DataGovernanceDdcStateModel): TileData {
    return state?.classificationOverview
  }
  
  @Selector([DataGovernanceDdcState])
  static classificationOverviewGroupModel(state:DataGovernanceDdcStateModel): any {
    return state?.classificationOverviewGroup?.model
  }
  
  @Selector([DataGovernanceDdcState.classificationOverviewGroupModel])
  static classificationOverviewValue(model:any): any {
    return model
  }

  @Action(GetTilesDataDDCAction)
  getTilesDataDDC(ctx: StateContext<DataGovernanceDdcStateModel>, action: GetTilesDataDDCAction) {
    let url = this.apiService.getUrl('a3s_dataGovernanceDashboard_getTilesDataForDDC');
    return this.http.post<any[]>(url,action.payload)
    .pipe(
      tap((tileDatas:any[]) => {
        let results={};
        tileDatas.forEach(tileData=>{
          const findObj=action.payload.tileList.find(tile=>tileData[tile.tileName]);
          results={
            ...results,
            [findObj.tileName]:{
              value:tileData[findObj.tileName].value?tileData[findObj.tileName].value:tileData[findObj.tileName]?[tileData[findObj.tileName]]:[],
              refreshedTime:new Date()
            }           
          }
        })
        ctx.patchState({
          ...results
        })
      }));
  }
  
  @Action(SortTilesDataDDCAction)
  sortTilesDataDDC(ctx: StateContext<DataGovernanceDdcStateModel>, action: SortTilesDataDDCAction) {
      let num = 0;
      (ctx.getState().datasort === 'desc') ? num = 1 : num = -1;
      let value = [...ctx.getState()[action.tileName].value];
      let fieldName=action.fieldName;
      value.sort((a, b) => {
        switch (fieldName) {
          case 'name': {
            let x = a.name ? a.name : ''
            let y = b.name ? b.name : ''
            return (x < y) ? num : ((x > y) ? -num : 0)
          }
          default: {
            let val1,val2;
            fieldName.split('.').forEach(dt=>{
              if(val1!=undefined){
                val1=val1[dt]
              }else {
                val1=a[dt]
              }
              if(val2!=undefined){
                val2=val2[dt]
              }else {
                val2=b[dt]
              }
            })
            let x = val1 ? val1 : ' ';
            let y = val2 ? val2 : ' ';
            return (x < y) ? num : ((x > y) ? -num : 0);
          }
        }
      })
      ctx.patchState({
        [action.tileName]:{
          ...ctx.getState()[action.tileName],
          value
        },
        datasort: ctx.getState().datasort === 'desc' ? 'asc' : 'desc'
      })
  }
}
