<div class="introduction">
          <p class="heading" id="0">Infrastructure Management </p>
          <label>This bucket is for managing the resources deployed on Azure for various projects.
               Resource management is done in context of a project per these 2 scenarios: </label>
          <ol>
              <li>If the architecture is deployed using Architecture Blueprint design tool, then same project and its content will be displayed. </li>
              <li>If customer has existing resources and signed up for A3S services, then a new project
                   will be created by A3S team as part of the customer onboarding process. </li>
            </ol>
</div>
