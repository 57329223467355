<div class="introduction">
   
             <p class="heading" id="1">Activity Logs</p>
             <p class="mb-0" >Provides overview of all the activities that occurred in Azure at tenant level. </p>
             <ul class="mb-0" >
                 <img src="assets\user-manual\governance\Activities.svg" >
                 <figcaption class="caption">Activity Logs dashboard  </figcaption>
                 <li><b>Activities</b>shows the count of Directory activities and actions performed by users.   </li>
                 <li><b>Activities by Severity </b>shows the count and severity of the activities.  </li>
                 <li><b>Activity Logs</b>table displays list of activities in detail.   </li>
                 <ul style="list-style-type: circle;">
                     <li>Click <b>Download</b>  button to download the table in CSV format.  </li>
                     <li>Use <b>Time range</b> to find activities performed in the chosen timeframe. </li>
                 </ul>
             </ul>
             <img src="assets\user-manual\governance\Activities-name.svg" >

             <figcaption class="caption">Activity details       </figcaption>
          
          
</div>