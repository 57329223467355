import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleTemplateModalComponent } from '../common/simple-template-modal/simple-template-modal.component';
import { PopUpComponent } from '../components/pop-up/pop-up.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  ngbModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
    windowClass: null,
  }; 
  constructor(private modal: NgbModal) { }

  showModal(title?: any, body?: any) {
    const modalRef = this.modal.open(PopUpComponent);
    modalRef.componentInstance.headerText = title;
    modalRef.componentInstance.message = body;
    return modalRef;
  }

  showSimpleTemplateModal(type?: any) {
    const modalRef = this.modal.open(SimpleTemplateModalComponent);
    modalRef.componentInstance.type = type;    
    return modalRef;
  }

  openModal(template: TemplateRef<any>,className?:any) {
    this.ngbModalOptions.windowClass = className
    this.modal.open(template,this.ngbModalOptions);
  }

  closeModal() {
    this.modal.dismissAll();
  }
}
