<div class="introduction">

             <p class="heading" id="3">Audit Logs </p>
             <ul class="mb-0" 
             >
                 <li>Table displays list of audit log details. </li>
                 <li>Click <b>Download</b> icon to download the table in CSV format.  </li>
                 <li>Use <b>Time range</b> to find audit logs in the chosen timeframe. </li>
             </ul>
             <img src="assets\user-manual\governance\Audit-Logs.svg" >
          
             <figcaption class="caption">Audit logs </figcaption>
</div>