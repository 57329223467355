import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPSubnetwork, IPv4 } from 'ip-matching';
import * as _ from 'lodash';
import { LoginMsalService } from 'src/app/services/login-msal.service';
import { LoginService } from 'src/app/services/login.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-onboarding-process',
  templateUrl: './onboarding-process.component.html',
  styleUrls: ['./onboarding-process.component.scss']
})
export class OnboardingProcessComponent implements OnInit {
  ipSubnetWork:any=IPSubnetwork;
  ipV4:any=IPv4;
  publicIPs:any=[
    '0.0.0.0/1',
    '127.0.0.0/8',
    '169.254.0.0/16',
    '169.254.0.0/15',
    '168.63.129.16/28',
    '168.63.129.16/29',
    '168.63.129.16/30',
    '168.63.129.16/31',
    '168.63.129.16/32',
    '224.0.0.0/4',
    '224.0.0.0/3',
    '255.255.255.255/32'
  ];

  showAccessTokenSteps: any = false
  showGrantPermissionSteps: any = false
  onboardingStepper: any = [];
  step: any = 0;
  isServicePrincipalCreated: boolean = false;
  currentForm: FormGroup;
  rgForm: FormGroup;
  logAnalyticsForm: FormGroup;
  isAccessTokenError: boolean = false;
  isCreating: any = false;
  isPermissiongranted: boolean;
  servicePrincipalData: any;
  interval: NodeJS.Timeout;
  subscriptionList: any = [];
  resourceGroupList: any = [];
  workspaceList: any = [];
  vmList: any = [];
  appInsightsList: any = [];
  selectedSubscriptionList: any = [];
  showRg: boolean = false;
  resourcesGroupLocationList: any = [];
  
  inputError: any ={
    duplicateResourceGroupName : false,
    maxLenResourceGroupName : false,
    validResourceGroupName : false,
    validLogAnalyticsName: false,
    validResourceName: false,
    isSameLogAnalyticsName: false
  };
  showLog: boolean = false;
  showVnet: boolean = false;
  showSubnet: boolean = false;
  showLoader: boolean = false;
  currentIndex: any;
  logAnalyticsData: any;
  regionList: any = [
    {id:1,value:'East US 2'},
    {id:2,value:'West US 2'},
    {id:3,value:'East US'},
    {id:4,value:'West Europe'},
    {id:5,value:'North Europe'},
  ]
  vnetList: any = [];
  subnetList: any = [];
  virtualNetworkForm: FormGroup;
  subnetForm: FormGroup;
  masterVnetList: any = [];
  constructor(private util:UtilService, private fb: FormBuilder,private loginmsal: LoginMsalService, private loginService: LoginService) { }

  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    var $target = $(event.target);
    if(!$target.closest('#accessToken').length && $('#accessToken').is(":visible") && event.target['innerText'] !== 'How to generate access token?') {
      this.showAccessTokenSteps = false
    }   
    if(!$target.closest('#createRg').length && $('#createRg').is(":visible") && event.target['innerText'] !== 'Create New' && !$target.closest('.ng-option').length) {
      this.showRg = false
    }   
    if(!$target.closest('#createLa').length && $('#createLa').is(":visible") && event.target['innerText'] !== 'Create New' && !$target.closest('.ng-option').length) {
      this.showLog = false
    }     
    
    if(!$target.closest('#createVnet').length && $('#createVnet').is(":visible") && event.target['innerText'] !== 'Create New' && !$target.closest('.ng-option').length) {
      this.showVnet = false
    }    

    if(!$target.closest('#createSubnet').length && $('#createSubnet').is(":visible") && event.target['innerText'] !== 'Create New' && !$target.closest('.ng-option').length) {
      this.showSubnet = false
    }    
  }
  
  ngOnInit(): void {
    this.onboardingStepper=_.cloneDeep(this.util.getStatic('onboardingStepper'))
    this.resourcesGroupLocationList = this.util.getStatic('countryList');
    if(localStorage.getItem('isOnboarding')){
      this.servicePrincipalData = localStorage.getItem('servicePrincipalData') ? JSON.parse(localStorage.getItem('servicePrincipalData')) : null
      this.onboardingStepper[this.step].isCompleted=true;
      this.onboardingStepper[this.step].isError=false;
      this.intializeAccessSubscriptionForm()
      this.step = 1
      this.setAccessToken()
      setTimeout(() => {
        this.getAllSubscription()
      }, 500);
    }
    else this.intializeServicePrincipalForm()
  }

  intializeServicePrincipalForm(){
    this.currentForm = this.fb.group({
      accessToken : [null,[Validators.required]],
    })
  }

  intializeAccessSubscriptionForm(){
    this.currentForm = this.fb.group({
      subscription : [null,[Validators.required]],
      enableCostMonitoring : [null],
    })
  }

  intializeResourceGroup(){
    // ,Validators.pattern('/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/')
    this.rgForm = this.fb.group({
      rgName : [null,[Validators.required,Validators.maxLength(90)]],
      location : [null,[Validators.required]],
      isEdit : false,
    })
  }

  intializeNewLogAnalyticsForm(){
    this.logAnalyticsForm = this.fb.group({
      name : [null,[Validators.required,Validators.minLength(3),Validators.maxLength(63)]],
      isEdit : false,
    })
  }

  intializeNewVirtualNetworkForm(){
    this.virtualNetworkForm = this.fb.group({
      name : [null,[Validators.required]],
      addressSpaceList: this.fb.array([this.addAddressSpaceFormArray()]),
      isEdit : false,
    })
  }

  addAddressSpaceFormArray(data?:any){
    let tempForm = this.fb.group({
      addressSpace :[data ? data : null,[Validators.required]],
    })
    return tempForm;
  }

  get addressList(){
    return this.virtualNetworkForm.get('addressSpaceList') as FormArray
  }

  addNewAddress(){
    this.addressList.push(this.addAddressSpaceFormArray())
  }

  removeNewAddress(index) {
    this.addressList.controls.splice(index, 1);
  }

  intializeNewSubnetForm(){
    this.subnetForm = this.fb.group({
      name : [null,[Validators.required]],
      address: [null,[Validators.required]],
      isEdit : false,
    })
  }


  intializeLogAnalyticsForm(){
    this.currentForm = this.fb.group({
      subscription : [null,[Validators.required]],
      resourceGroup : [null,[Validators.required]],
      logAnalytics : [null,[Validators.required]],
      virtualMachine : [null],
    })
  }

  intializeAppInsightsForm(){
    this.currentForm = this.fb.group({
      subscription : [null,[Validators.required]],
      appInsights : [null],
    })
  }

  intializeSecurityCenterForm(){
    this.currentForm = this.fb.group({
      subscription : [null,[Validators.required]],
      enable : [null],
      enableSentinal : [null],
    })
  }

  intializeAMSForm(){
    this.currentForm = this.fb.group({
      enableAMS : [null],
      subscription : [null],
      resourceGroup : [null],
      resource : [null,[Validators.minLength(6),Validators.maxLength(30)]],
      region : [null],
      virtualNetwork : [null],
      subnet : [null],
      logAnalytics : [this.logAnalyticsData && this.logAnalyticsData.logAnalytics ? this.logAnalyticsData.logAnalytics : null],
      enableMSShare : [false]
    })
  }

  setAMSValidation(event){
    let checked = event.target.checked
    this.currentForm.get('subscription').setValidators(checked ? [Validators.required] : null)
    this.currentForm.get('resourceGroup').setValidators(checked ? [Validators.required] : null)
    this.currentForm.get('resource').setValidators(checked ? [Validators.required,Validators.minLength(6),Validators.maxLength(30)] : [Validators.minLength(6),Validators.maxLength(30)])
    this.currentForm.get('region').setValidators(checked ? [Validators.required] : null)
    this.currentForm.get('virtualNetwork').setValidators(checked ? [Validators.required] : null)
    this.currentForm.get('subnet').setValidators(checked ? [Validators.required] : null)
    this.currentForm.get('logAnalytics').setValidators(checked ? [Validators.required] : null)
  }

  getClass(steps) {
    if (steps.isCompleted) return 'success';
    else if (steps.isError) return 'error'
    else if (this.step >= steps.limit) return 'active'
  }

  setAccessToken(){
    var data = { token: '' };
    for (let i = 0; sessionStorage.key(i); i++) {
        if (sessionStorage.key(i).indexOf('login.windows.net-accesstoken') != -1) {
            data['token'] = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['secret'];
            data['homeAccountId'] = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['homeAccountId']
            data['userId'] = data['homeAccountId'].split('.')[0]
            sessionStorage.setItem('data', JSON.stringify(data));
        }
    }
  }

  async moveToNextStep() {
    switch(this.step){
      case 0: {
        if(!this.isServicePrincipalCreated){
          this.createServicePrincipal()
          // this.isServicePrincipalCreated = true
        }
        else if(this.isServicePrincipalCreated && this.isPermissiongranted){
          localStorage.setItem('isOnboarding','true')
          this.util.route(['msalLoading'])
        }
        else{
          this.onboardingStepper[this.step].isCompleted=true;
          this.onboardingStepper[this.step].isError=false;
          this.getAllSubscription()
          this.step = this.step + 1;
        }
        break;
      }
      case 1:{
        let array = this.currentForm.get('subscription').value
        this.selectedSubscriptionList = this.subscriptionList.filter(e => array.indexOf(e.subscriptionId) > -1)
        this.showLoader = true;
        this.grantSubscriptionPermission()
        await this.util.delay(500)
        await this.deleteA3sOnboardData('subscription')
        let isEnable = this.currentForm.get('enableCostMonitoring').value
        if(isEnable){
          await this.enableCostMonitoring()
          await this.util.delay(500)
          await this.UpdateCostMonitoringEnabled()
        }
        this.UpdateSubscription()
        await this.util.delay(500)
        this.checkVmDataAccess()
        this.intializeLogAnalyticsForm()
        this.onboardingStepper[this.step].isCompleted=true;
        this.onboardingStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 2:{
        this.showLoader = true;
        this.logAnalyticsData = this.currentForm.getRawValue()
        await this.registerResourceProvider(this.currentForm.get('subscription').value,'operationalInsights')
        await this.util.delay(500)
        await this.createNewResourceGroups()
        await this.util.delay(500)
        await this.createNewLogAnalytics()
        await this.util.delay(500)
        await this.logAnalyticsSetup()
        await this.util.delay(500)
        await this.logAnalyticsSubscriptionEnable()
        await this.util.delay(500)
        await this.UpdateLogAnalyticsEnabled()
        await this.deleteA3sOnboardData('logAnalytics')
        this.connectVmToLogAnalytics()
        this.updateLogAnalyticsTable()
        this.intializeAppInsightsForm()
        this.onboardingStepper[this.step].isCompleted=true;
        this.onboardingStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 3:{
        this.showLoader = true;
        await this.deleteA3sOnboardData('applicationInsights')
        await this.createAppInsightsKey()
        this.intializeSecurityCenterForm()
        this.onboardingStepper[this.step].isCompleted=true;
        this.onboardingStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 4:{
        this.showLoader = true;
        await this.deleteA3sOnboardData('securityCenter')
        let isEnable = this.currentForm.get('enable').value
        if(isEnable){
          await this.updateSecurityCenter()
        }
        if(this.currentForm.get('enableSentinal').value) {
          await this.registerResourceProvider(this.logAnalyticsData.subscription,'securityInsights')
          await this.util.delay(2000)
          this.enabledSentinal()
        }
        this.showLoader = false;
        this.intializeAMSForm()
        this.intializeNewVirtualNetworkForm()
        this.intializeNewSubnetForm()
        this.onboardingStepper[this.step].isCompleted=true;
        this.onboardingStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 5:{
        this.showLoader = true;
        if(this.currentForm.get('enableAMS').value) {
          await this.deleteA3sOnboardData('ams')
          await this.createNewResourceGroups()
          await this.createNewLogAnalytics2()
          await this.util.delay(500)
          await this.registerResourceProvider(this.currentForm.get('subscription').value,'logAnalyticsService')
          await this.util.delay(500)
          await this.registerResourceProvider(this.currentForm.get('subscription').value,'keyVaultService')
          await this.util.delay(500)
          await this.registerResourceProvider(this.currentForm.get('subscription').value,'computeService')
          await this.util.delay(500)
          await this.registerResourceProvider(this.currentForm.get('subscription').value,'networkService')
          await this.util.delay(500)
          await this.registerResourceProvider(this.currentForm.get('subscription').value,'managedIdentityService')
          await this.util.delay(500)
          await this.registerResourceProvider(this.currentForm.get('subscription').value,'accountService')
          await this.util.delay(2000)
          await this.createAMS()
          await this.updateAMSTable()
        }
        await this.updateCompanyOnboarded()
        this.addInitialAdminToA3s()
      }
    }
  }

  async skip(){
    switch(this.step){
      case 2:{
        this.deleteA3sOnboardData('logAnalytics')
        this.intializeAppInsightsForm()
        this.onboardingStepper[this.step].isCompleted=true;
        this.onboardingStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 3:{
        this.deleteA3sOnboardData('applicationInsights')
        this.intializeSecurityCenterForm()
        this.onboardingStepper[this.step].isCompleted=true;
        this.onboardingStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 4:{
        this.deleteA3sOnboardData('securityCenter')
        this.intializeAMSForm()
        this.onboardingStepper[this.step].isCompleted=true;
        this.onboardingStepper[this.step].isError=false;
        this.step = this.step + 1;
        break;
      }
      case 5:{
        await this.updateCompanyOnboarded()
        this.addInitialAdminToA3s()
        break;
      }
    }
  }

  createServicePrincipal(){
    let body = { accessToken : this.currentForm.get('accessToken').value.trim()}
    this.isCreating = true
    return this.util.handleRequest('post','a3s_admin_createServicePrincipal',[],body,null,null,true).then(async res=>{
      this.isServicePrincipalCreated = true
      this.isCreating = false
      this.servicePrincipalData = res
      localStorage.setItem('servicePrincipalData',JSON.stringify(this.servicePrincipalData))
      localStorage.setItem('clientID',this.servicePrincipalData.servicePrincipal.body.appId)
      localStorage.setItem('tenantID',this.servicePrincipalData.servicePrincipal.body.appOwnerOrganizationId)
      localStorage.setItem('authority',`https://login.microsoftonline.com/${this.servicePrincipalData.servicePrincipal.body.appOwnerOrganizationId}`)
      await this.UpdateCompanyDetails()
      this.UpdateServicePrincipal()
      this.checkPermissionGranted()
    },err=>{
      this.isCreating = false
      this.isServicePrincipalCreated = false
      this.isAccessTokenError = true
    })
  }

  deleteA3sOnboardData(type){
   switch(type){
     case 'subscription' : return this.util.handleRequest('delete','a3s_admin_deleteSubscription',[],null,null,null,true)
     case 'logAnalytics' : return this.util.handleRequest('delete','a3s_admin_deleteLogAnalytics',[],null,null,null,true)
     case 'applicationInsights' : return this.util.handleRequest('delete','a3s_admin_deleteApplicationInsights',[],null,null,null,true)
     case 'securityCenter' : return this.util.handleRequest('delete','a3s_admin_deleteSecurityCenterTable',[],null,null,null,true)
     case 'ams' : return this.util.handleRequest('delete','a3s_admin_deleteAMSTable',[],null,null,null,true)
   }
  }

  UpdateCompanyDetails(){
    let body = { 
      clientId : this.servicePrincipalData.servicePrincipal.body.appId,
      clientSecret: this.servicePrincipalData.password.body.secretText,
      tenantId: this.servicePrincipalData.servicePrincipal.body.appOwnerOrganizationId,
      objectId: this.servicePrincipalData.servicePrincipal.body.id
    }
    return this.util.handleRequest('post','a3s_admin_updateCompanyDetails',[],body,null,null,true)
  }

  UpdateServicePrincipal(){
    let body = { 
      isServicePrincipalCreated: true
    }
    this.util.handleRequest('post','a3s_admin_updateServicePrincipal',[],body,null,null,true)
  }

  UpdateSubscription(){
    return Promise.all(this.selectedSubscriptionList.map(e=>{
      let body = { 
        subscriptionId: e.subscriptionId,
        subscriptionName: e.displayName
      }
      return this.util.handleRequest('post','a3s_admin_updateSubscription',[],body,null,null,true)
    })).then(final=>{
      // this.showLoader = false;
    })
  }

  checkVmDataAccess(){
    this.interval = setInterval(() => {
      let body = {
        subsList : this.selectedSubscriptionList.map(e=>e.subscriptionId)
      }
      this.util.handleRequest('post','a3s_admin_getVirtualMachineRunningList',[],body,null,null,true).then(res=>{
        this.showLoader = false
        clearInterval(this.interval)
        if(!res.body) return
        this.vmList = res.body.data
      })
    },10000)
  }

  checkPermissionGranted(){
    this.interval = setInterval(() => {
      let body = { 
        accessToken : this.currentForm.get('accessToken').value.trim(),
        id: this.servicePrincipalData.servicePrincipal.body.id
      }
      this.util.handleRequest('post','a3s_admin_checkPermissionGranted',[],body,null,null,true).then(res=>{
        console.log(res)
        if(res.body.value.length) {
          clearInterval(this.interval)
          this.isPermissiongranted = true
        }
      },err=>{
        this.isPermissiongranted = false
      })
    },3000)
  }

  UpdateCostMonitoringEnabled(){
    let body = { 
      isCostMonitoringEnabled: true
    }
    this.util.handleRequest('post','a3s_admin_costMonitoringEnabled',[],body,null,null,true)
  }

  updateCompanyOnboarded(){
    return this.util.handleRequest('post','a3s_admin_updateCompanyOnboarded',[],null,null,null,true)
  }

  async addInitialAdminToA3s(){
    // let orgDetails = await this.util.handleRequest('get','a3s_onboarded_orgDetails',[],null,null,null,true)
    let staticModule = await this.util.handleRequest('get','a3s_static_getByTable',['user_management','module'],null,null,null,true)
    // let userInfo = await this.util.handleRequest('get','a3s_azureDetails_userByEmail',[orgDetails.recordset[0].userId],null,null,null,true)
    let userInfo = await this.get_user_details()
    let body ={
      userId : userInfo.localAccountId,
      roleId : 1,
      moduleId: staticModule.recordset
    }
    body['userName'] = userInfo.name
    body['email'] = userInfo.username
    body['createdBy'] = userInfo.name
    body['lastModifiedBy'] = userInfo.name
    this.util.info('Adding role...')
    return this.util.handleRequest('post','a3s_a3sAccess_addUserRoles',[],body,null,null,true).then(res=>{
      this.showLoader = false;
      this.loginSubmit()
      this.util.success('Role added successfully!');
    },err=>{
      this.showLoader = false;
      this.loginSubmit()
      this.util.error('Failed to add role.');
    })
  }

  get_user_details() {
    let user_details;
    for (let i = 0; sessionStorage.key(i); i++) {
        if (sessionStorage.key(i).indexOf('login.windows.net-' + localStorage.getItem('tenantID')) != -1) {
            user_details = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))
        }
    }
    return user_details;
  }

  onToggle(type){
    switch(type){
      case 'resourceGroup' : {
        this.intializeResourceGroup()
        return this.showRg = !this.showRg
      }
      case 'logAnalytics' : {
        this.intializeNewLogAnalyticsForm()
        this.inputError.validLogAnalyticsName = false;
        this.inputError.isSameLogAnalyticsName = false;
        return this.showLog = !this.showLog
      }
      case 'virtualNetwork' : {
        this.intializeNewVirtualNetworkForm()
        return this.showVnet = !this.showVnet
      }
      case 'subnet' : {
        this.intializeNewSubnetForm()
        return this.showSubnet = !this.showSubnet
      }
    }
  
  }

  loginSubmit() {
    var payload = { id: localStorage.getItem('id') }
    this.loginService.getData(payload).subscribe(
        res => {
            if(res.isOnboarded){
                if (res) {
                    this.loginmsal.clientId = res.ClientID;
                    this.loginmsal.tenantId = res.TenantID;
                    localStorage.setItem('cred', JSON.stringify(res));
                }
                if (this.loginmsal.clientId != null && this.loginmsal.tenantId != null) {

                    this.loginmsal.MSAL_CONFIG.auth.clientId = JSON.parse(localStorage.getItem('cred')).ClientID;
                    this.loginmsal.MSAL_CONFIG.auth.authority = "https://login.microsoftonline.com/" + JSON.parse(localStorage.getItem('cred')).TenantID;
                    if (this.loginmsal.MSAL_CONFIG.auth.clientId != '' && this.loginmsal.MSAL_CONFIG.auth.authority != '') {
                        this.loginmsal.login('loginRedirect');
                    }
                }
            }
            else{
                this.util.route(['/onboarding'],false);
            }
        })
  }

  openAzurePortal(){
    window.open(
      `https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/CallAnAPI/appId/${this.servicePrincipalData.servicePrincipal.body.appId}/objectId/${this.servicePrincipalData.servicePrincipal.body.id}/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADMyOrg/servicePrincipalCreated/true`,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  grantPermission(){
    window.open(
      `https://login.microsoftonline.com/${this.servicePrincipalData.servicePrincipal.body.appOwnerOrganizationId}/v2.0/adminconsent?client_id=${this.servicePrincipalData.servicePrincipal.body.appId}&state=12345&redirect_uri=${environment.domain}/closePage&scope=https://graph.microsoft.com/.default`,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  openGraphExplorer(){
    window.open(
      'https://developer.microsoft.com/en-us/graph/graph-explorer',
      '_blank' // <- This is what makes it open in a new window.
    );
  }


  getAllSubscription(){
    this.util.handleRequest('get','a3s_subscription_getOnboardAll',[],null,null,null,true).then(res=>{
      if(!res.body) return
      this.subscriptionList = res.body.value.filter(e=> e.state == 'Enabled')
      console.log('User access subscription',this.subscriptionList)
    })
  }

  getRgBySubscription(){
    let subsId = this.currentForm.get('subscription').value || ''
    if(!subsId) return;
    this.util.handleRequest('get','a3s_resourceGroup_getRgBySubscription',[subsId],null,null,null,true).then(res=>{
      if(!res.body) return
      this.resourceGroupList = res.body.value
    })
  }

  getAllVirtualNetwork(){
    let subsId = this.currentForm.get('subscription').value || ''
    if(!subsId) return;
    this.util.handleRequest('get','a3s_admin_getAllVnet',[subsId],null,null,null,true).then(res=>{
      if(!res.body) return
      this.vnetList = res.body.value
      this.masterVnetList = this.vnetList
    })
  }

  getAllSubnet(){
    let subsId = this.currentForm.get('subscription').value || ''
    let vnet = this.currentForm.get('virtualNetwork').value || null
    let rg = this.vnetList.filter(e=> e.name == vnet)[0].id.split('/')[4]
    if(!subsId || !rg || !vnet) return;
    this.util.handleRequest('get','a3s_admin_getAllSubnet',[subsId,rg,vnet],null,null,null,true).then(res=>{
      if(!res.body) return
      this.subnetList = res.body.value
    })
  }

  getAllLogAnalytics(){
    let subsId = this.currentForm.get('subscription').value || ''
    if(!subsId) return;
    this.util.handleRequest('get','a3s_admin_getAllLogAnalytics',[subsId],null,null,null,true).then(res=>{
      if(!res.body) return
      this.workspaceList = res.body.value
    })
  }

  getWorkspaceByRg(){
    let subsId = this.currentForm.get('subscription').value
    let rg = this.currentForm.get('resourceGroup').value
    if(!subsId || !rg) return;
    this.util.handleRequest('get','a3s_admin_getLogAnalyticsWorkspace',[subsId,rg],null,null,null,true).then(res=>{
      if(!res.body) return
      this.workspaceList = res.body.value
    },err=>{
      this.workspaceList = []
    })
  }

  getVirtualMachine(){
    let body = {
      subsList : this.selectedSubscriptionList.map(e=>e.subscriptionId)
    }
    this.util.handleRequest('post','a3s_admin_getVirtualMachineRunningList',[],body,null,null,true).then(res=>{
      if(!res.body) return
      this.vmList = res.body.data
    })
  }

  grantSubscriptionPermission(){
    let subsId = this.currentForm.get('subscription').value
    return Promise.all(subsId.map(e=>{
      let body = {
        Properties:{
          PrincipalId : this.servicePrincipalData.servicePrincipal.body.id,
          UserId :  sessionStorage.getItem('data') && JSON.parse(sessionStorage.getItem('data'))['token'] ? JSON.parse(sessionStorage.getItem('data'))['userId'] : null,
          RoleDefinitionId:`/subscriptions/${e}/providers/Microsoft.Authorization/roleDefinitions/8e3af657-a8ff-443c-a75c-2fe8c4bcb635`,
          Scope:`/subscriptions/${e}`
        }
      }
      return this.util.handleRequest('put','a3s_admin_grantUserAccesss',[e],body,null,null,true)
    })).then(final=>{
      console.log('Subscription assigned',final)
    })
  }

  enableCostMonitoring(){
      let body = {
        ServicePrincipal : this.servicePrincipalData.servicePrincipal.body.id,
      }
      return this.util.handleRequest('post','a3s_admin_enableCostMonitoring',[],body,null,null,true)
  }

  filterVnetByRegion(){
    let region = this.currentForm.get('region').value
    this.vnetList = [...this.masterVnetList.filter(e=> e.location.replace(/\s/g, '').toLowerCase() == region.replace(/\s/g, '').toLowerCase())]
  }

  createAMS(){
    let subsId = this.currentForm.get('subscription').value
    let rg = this.currentForm.get('resourceGroup').value
    let region = this.currentForm.get('region').value
    let workspaceData = this.workspaceList.filter(e=> e.name == this.currentForm.get('logAnalytics').value)
    let vnetData = this.vnetList.filter(e=> e.name == this.currentForm.get('virtualNetwork').value)
    let vnetAddressSpace = vnetData.length && vnetData[0].hasOwnProperty('addressList') ? vnetData[0].addressList : []
    let subnetData = this.subnetList.filter(e=> e.name == this.currentForm.get('subnet').value)
    let subnetAddressSpace = subnetData.length && subnetData[0].hasOwnProperty('address') ? subnetData[0].address : null
    if(!subsId || !rg) return;
    let body ={
      laResourceId: workspaceData.length ? workspaceData[0].id : '',
      resourceName:this.currentForm.get('resource').value,
      region:region.replace(/\s/g, '').toLowerCase(),
      isNewVnet:  vnetData.length  && vnetData[0].hasOwnProperty('isNew') ?  vnetData[0]['isNew'] : false,
      vnetAddressList: JSON.stringify(vnetAddressSpace),
      subnetAddress: subnetAddressSpace,
      vnetRg: vnetData.length ? (vnetData[0].hasOwnProperty('isNew') ? rg : vnetData[0].id.split('/')[4]) : '',
      vnetName:this.currentForm.get('virtualNetwork').value,
      isNewSubnet:subnetData.length  && subnetData[0].hasOwnProperty('isNew') ?  subnetData[0]['isNew'] : false,
      subnetName:this.currentForm.get('subnet').value,
      laWorkspaceId: workspaceData.length ?  workspaceData[0].properties.customerId : '',
      enableMsShare:this.currentForm.get('enableMSShare').value
    }
   return this.util.handleRequest('post','a3s_admin_createAMS',[subsId,rg],body,null,null,true).then(async res=>{
     let body1= { isAMSEnabled : true}
     this.util.handleRequest('post','a3s_admin_updateAMSEnabled',[],body1,null,null,true)
   })
  }

 enabledSentinal(){
   let body ={
     onboardedSubscription : this.selectedSubscriptionList || [],
     laSubscription: this.logAnalyticsData.subscription,
     laResourceGroup: this.logAnalyticsData.resourceGroup,
     laLocation: this.workspaceList.filter(x=> x.name == this.logAnalyticsData.logAnalytics)[0].location,
     laResourceId: this.workspaceList.filter(x=> x.name == this.logAnalyticsData.logAnalytics)[0].id,
     laName: this.logAnalyticsData.logAnalytics,
   }
  return this.util.handleRequest('post','a3s_admin_enableSentinal',[],body,null,null,true).then(res=>{
    let body1= { isAzureSentinalEnabled : true}
    this.util.handleRequest('post','a3s_admin_updateAzureSentinalEnabled',[],body1,null,null,true)
  })
 }

 updateAMSTable(){
  let subsId = this.currentForm.get('subscription').value || []
  let region = this.currentForm.get('region').value
  let body = { 
    resourceName:this.currentForm.get('resource').value,
    resourceGroupName: this.currentForm.get('resourceGroup').value,
    amsResourceId:`/subscriptions/${subsId}/resourceGroups/${this.currentForm.get('resourceGroup').value}/providers/Microsoft.HanaOnAzure/sapMonitors/${this.currentForm.get('resource').value}`,
    vnetName:this.currentForm.get('virtualNetwork').value,
    subnetName:this.currentForm.get('subnet').value,
    subnetId:this.subnetList.filter(e=> e.name == this.currentForm.get('subnet').value)[0].id,
    isSameLogAnalytics: this.logAnalyticsData && this.logAnalyticsData.logAnalytics && this.logAnalyticsData.logAnalytics == this.currentForm.get('logAnalytics').value ? true : false,
    logAnalyticsName:this.currentForm.get('logAnalytics').value,
    logAnalyticsResourceId:this.workspaceList.filter(e=> e.name == this.currentForm.get('logAnalytics').value)[0].id,
    logAnalyticsWorkspaceId:this.workspaceList.filter(e=> e.name == this.currentForm.get('logAnalytics').value)[0].properties.customerId,
    region:region.replace(/\s/g, '').toLowerCase()
  }
  return this.util.handleRequest('post','a3s_admin_updateAMSTable',[],body,null,null,true)
}
 
  updateSecurityCenter(){
    let subsId = this.currentForm.get('subscription').value || []
    let isEnable = this.currentForm.get('enable').value
    return Promise.all(subsId.map(e=>{
      let body = [ 
        { 
            "bundleName": "VirtualMachines", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
    
        { 
    
            "bundleName": "AppServices", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "SqlServers", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "SqlServerVirtualMachines", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "OpenSourceRelationalDatabases", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "StorageAccounts", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "KubernetesService", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "ContainerRegistry", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "KeyVaults", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "Arm", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        }, 
        { 
            "bundleName": "Dns", 
            "subscriptionId": e, 
            "selectedPricingTier": isEnable ? 2 : 0, 
            "pricingOption": [], 
            "remainingTrialDays": -1 
        } 
    
    ] 
      return this.util.handleRequest('post','a3s_admin_updateSecurityCenter',[e],body,null,null,true)
    })).then(final=>{
      console.log('Updated security center',final)
      this.updateSecurityCenterTable()
    })
  }
  
  updateSecurityCenterTable(){
    let subsId = this.currentForm.get('subscription').value || []
    let isEnable = this.currentForm.get('enable').value
    return Promise.all(subsId.map(e=>{
      let body = { 
        subscriptionId: e,
        subscriptionName: this.selectedSubscriptionList.filter(x=> x.subscriptionId == e)[0].displayName,
        isEnabled:isEnable ?  true : false
      }
      return this.util.handleRequest('post','a3s_admin_updateSecurityCenterTable',[],body,null,null,true)
    })).then(final=>{
      console.log('Updated security center',final)
    })
  }

  getAppInsights(){
    let subsId = this.currentForm.get('subscription').value || []
    return Promise.all(subsId.map(e=>{
      return this.util.handleRequest('get','a3s_admin_getAppInsightsList',[e],null,null,null,true)
    })).then(final=>{
      this.appInsightsList = []
      final.forEach(fi=>{
        fi['body'].value.forEach(e=>{
          this.appInsightsList.push(e)
        })
      })
      console.log('appInsightsList',this.appInsightsList)
    })
  }

  createAppInsightsKey(){
    let data = this.currentForm.get('appInsights').value || []
    console.log('appInsights',data)
    return Promise.all(data.map(e=>{
      let body ={ name: e.split('/')[e.split('/').length - 1] ,resourceID : e }
      return this.util.handleRequest('post','a3s_admin_createAppInsightsKey',[],body,null,null,true)
    })).then(final=>{
      final.forEach(e=>{
        let data = this.appInsightsList.filter(x=> x.name == e['body']['id'].split('/')[8])
        e['appData'] = data.length ? data[0] : null
        this.updateAppInsightsTable(e)
      })
      this.showLoader = false;
      console.log('Application insights created',final)
    },err=>{
      this.showLoader = false;
    })
  }

  updateAppInsightsTable(final){
    let subsId = final.appData.id.split('/')[2]
    let body = { 
      name: final.appData.name,
      resourceId: final.appData.id,
      instrumentationKey: final.appData.properties.InstrumentationKey,
      applicationId: final.appData.properties.AppId,
      applicationKey: final.body.apiKey,
      subscriptionId: subsId,
      subscriptionName: this.selectedSubscriptionList.filter(e=> e.subscriptionId == subsId)[0].displayName,
    }
    return this.util.handleRequest('post','a3s_admin_updateApplicationInsights',[],body,null,null,true)
  }

  UpdateLogAnalyticsEnabled(){
    let body = { 
      isLogAnalyticsCreated: true
    }
    this.util.handleRequest('post','a3s_admin_updateLogAnalyticsCreated',[],body,null,null,true)
  }

  logAnalyticsSetup(){
    let subsId = this.currentForm.get('subscription').value
    let rg = this.currentForm.get('resourceGroup').value
    let workspace = this.currentForm.get('logAnalytics').value
    if(!subsId || !rg || !workspace) return;
    return this.util.handleRequest('put','a3s_admin_logAnalyticsSetup',[subsId,rg,workspace],null,null,null,true).catch(err=> this.showLoader = false)
  }

  async logAnalyticsSubscriptionEnable(){
    let subsId = this.currentForm.get('subscription').value
    let rg = this.currentForm.get('resourceGroup').value
    let workspace = this.currentForm.get('logAnalytics').value
    if(!rg || !workspace) return;
    let enableData = await this.util.handleRequest('put','a3s_admin_checkLogAnalyticsSubscriptionEnabled',[subsId,rg,workspace],null,null,null,true)
    this.selectedSubscriptionList.forEach(async e=>{
      let data = enableData & enableData.body.value.length ? enableData.body.value.filter(x=> x.properties.linkedResourceId.split('/')[2] == e.subscriptionId) : []
      if(!data.length) this.util.handleRequest('put','a3s_admin_logAnalyticsSubscriptionEnable',[subsId,e.subscriptionId,rg,workspace],null,null,null,true)
    })
  }

  connectVmToLogAnalytics(){
    let subsId = this.currentForm.get('subscription').value
    let rg = this.currentForm.get('resourceGroup').value
    let workspace = this.currentForm.get('logAnalytics').value
    if(!subsId || !rg || !workspace) return;
    let virtualMachine = this.currentForm.get('virtualMachine').value || []
    virtualMachine.forEach(e=>{
      let data = this.vmList.filter(x=> x.VM_name == e)
      let body ={ 
        resourceID : data[0].resourecId,
        location : data[0].VM_location,
        workspaceId: this.workspaceList.filter(x=> x.name == workspace)[0].properties.customerId
       }
       let url = data[0].operatingSystem == 'Windows' ? 'a3s_admin_connectVmLogAnalytics' : 'a3s_admin_connectLinuxVmToLogAnalytics'
      this.util.handleRequest('post',url,[subsId,rg,workspace,e],body,null,null,true).catch(err=> this.showLoader = false)
    })
    
  }

  updateLogAnalyticsTable(){
    let subsId = this.currentForm.get('subscription').value
    let virtualMachine = this.currentForm.get('virtualMachine').value || []
    let workspace = this.currentForm.get('logAnalytics').value
    virtualMachine.forEach(e=>{
      let data = this.vmList.filter(x=> x.VM_name == e)
      let body = { 
        subscriptionId: subsId,
        subscriptionName: this.selectedSubscriptionList.filter(e=>e.subscriptionId == subsId)[0].displayName,
        name: workspace,
        workspaceId: this.workspaceList.filter(x=> x.name == workspace)[0].properties.customerId,
        vmResourceId: data[0].resourecId,
      }
      this.util.handleRequest('post','a3s_admin_updateLogAnalytics',[],body,null,null,true)
    })
    if(!virtualMachine.length) {
      let body = { 
        subscriptionId: subsId,
        subscriptionName: this.selectedSubscriptionList.filter(e=>e.subscriptionId == subsId)[0].displayName,
        name: workspace,
        workspaceId: this.workspaceList.filter(x=> x.name == workspace)[0].properties.customerId,
        vmResourceId: null,
      }
      this.util.handleRequest('post','a3s_admin_updateLogAnalytics',[],body,null,null,true)
    }
    this.showLoader = false
   
  }

  createNewLogAnalytics(){
    let subsId = this.currentForm.get('subscription').value
    let rg = this.currentForm.get('resourceGroup').value
    return Promise.all(this.workspaceList.map(x=>{
      if(!x.isNew) return;
      let location = this.resourceGroupList.filter(e=> e.name == rg)[0].location
      let body = { 
        "properties": { 
          "sku": { 
            "name": "PerGB2018" 
          }, 
          "retentionInDays": 30 
        }, 
        "location": location, // location same as that of RG 
        "tags": {} 
      } 
      return this.util.handleRequest('put','a3s_admin_createNewLogAnalyticsWorkspace',[subsId,rg,x.name],body,null,null,true)
      })).then(async final=>{
        await this.getWorkspaceByRg()
        console.log('All new log analytics created')
      })
  }

  createNewLogAnalytics2(){
    let subsId = this.currentForm.get('subscription').value
    let rg = this.currentForm.get('resourceGroup').value
    return Promise.all(this.workspaceList.map(x=>{
      if(!x.isNew) return;
      let location = this.resourceGroupList.filter(e=> e.name == rg)[0].location
      let body = { 
        "properties": { 
          "sku": { 
            "name": "PerGB2018" 
          }, 
          "retentionInDays": 30 
        }, 
        "location": location, // location same as that of RG 
        "tags": {} 
      } 
      return this.util.handleRequest('put','a3s_admin_createNewLogAnalyticsWorkspace',[subsId,rg,x.name],body,null,null,true)
      })).then(async final=>{
        await this.getAllLogAnalytics()
        console.log('All new log analytics created')
      })
  }

  async registerResourceProvider(subsId,type){
    switch(type){
      case 'operationalInsights': return this.util.handleRequest('post','a3s_admin_registerResourceProvider',[subsId,'operationalInsights'],null,null,null,true)
      case 'securityInsights': return this.util.handleRequest('post','a3s_admin_registerResourceProvider',[subsId,'securityInsights'],null,null,null,true)
      case 'logAnalyticsService': return this.util.handleRequest('post','a3s_admin_registerResourceProvider',[subsId,'logAnalyticsService'],null,null,null,true)
      case 'keyVaultService': return this.util.handleRequest('post','a3s_admin_registerResourceProvider',[subsId,'keyVaultService'],null,null,null,true)
      case 'computeService': return this.util.handleRequest('post','a3s_admin_registerResourceProvider',[subsId,'computeService'],null,null,null,true)
      case 'networkService': return this.util.handleRequest('post','a3s_admin_registerResourceProvider',[subsId,'networkService'],null,null,null,true)
      case 'managedIdentityService': return this.util.handleRequest('post','a3s_admin_registerResourceProvider',[subsId,'managedIdentityService'],null,null,null,true)
      case 'accountService': return this.util.handleRequest('post','a3s_admin_registerResourceProvider',[subsId,'accountService'],null,null,null,true)
    }
  }

  createNewResourceGroups(){
    let subsId = this.currentForm.get('subscription').value
    // let rg = this.rgForm.get('rgName').value 
    return Promise.all(this.resourceGroupList.map(e=>{
      if(!e.isNew) return;
      let body = { 
        "location": e.location
      } 
       return this.util.handleRequest('put','a3s_resourceGroup_create',[subsId,e.name],body,null,null,true)
    })).then(final=>{
      console.log('All new RG created')
    })
  }

  addRgToExisting(){
    let rg = this.rgForm.get('rgName').value 
    if(this.rgForm.get('isEdit').value){
       this.resourceGroupList[this.currentIndex].name = rg
       this.resourceGroupList[this.currentIndex].location = this.rgForm.get('location').value
    }
    else{
      let newRg : any = [{name: rg,isNew:true,location: this.rgForm.get('location').value}]
      this.resourceGroupList = [...this.resourceGroupList,...newRg]
    }
    this.currentForm.get('resourceGroup').setValue(null)
    this.currentForm.get('resourceGroup').setValue(rg)
    setTimeout(() => {
      this.currentForm.get('resourceGroup').updateValueAndValidity()
    }, 50);
    this.onToggle('resourceGroup')
  }

  removeNewResourceGroup(item,index){
    this.resourceGroupList.splice(index,1)
    this.resourceGroupList = [...this.resourceGroupList]
    this.currentForm.get('resourceGroup').setValue(null)
  }

  editNewResourceGroup(item,index){
    this.rgForm.get('isEdit').setValue(true)
    this.currentIndex = index
    this.rgForm.get('rgName').setValue(item.name)
    this.rgForm.get('location').setValue(item.location)
    this.rgForm.updateValueAndValidity();
    this.showRg = true
  }

  addLogAnlayticsToExisting(){
    let name = this.logAnalyticsForm.get('name').value 
    if(this.logAnalyticsForm.get('isEdit').value){
      this.workspaceList[this.currentIndex].name = name
   }
   else{
    let newla : any = [{name: name,isNew:true}]
    this.workspaceList =  [...this.workspaceList,...newla]
   }
   this.currentForm.get('logAnalytics').setValue(null)
    this.currentForm.get('logAnalytics').setValue(name)
    setTimeout(() => {
      this.currentForm.get('logAnalytics').updateValueAndValidity()
    }, 50);
    this.onToggle('logAnalytics')
  }

  removeNewLogAnlaytics(item,index){
    this.workspaceList.splice(index,1)
    this.workspaceList = [...this.workspaceList]
    this.currentForm.get('logAnalytics').setValue(null)
  }

  editNewLogAnlaytics(item,index){
    this.logAnalyticsForm.get('isEdit').setValue(true)
    this.currentIndex = index
    this.logAnalyticsForm.get('name').setValue(item.name)
    this.logAnalyticsForm.updateValueAndValidity();
    this.showLog = true
  }

  addVnetToExisting(){
    let vnetName = this.virtualNetworkForm.get('name').value 
    if(this.virtualNetworkForm.get('isEdit').value){
       this.vnetList[this.currentIndex].name = vnetName
       this.vnetList[this.currentIndex].addressList = this.addressList.getRawValue().map(e=> e.addressSpace)
    }
    else{
      let newRg : any = [{name: vnetName,isNew:true,addressList: this.addressList.getRawValue().map(e=> e.addressSpace)}]
      this.vnetList = [...this.vnetList,...newRg]
    }
    this.currentForm.get('virtualNetwork').setValue(null)
    this.currentForm.get('virtualNetwork').setValue(vnetName)
    setTimeout(() => {
      this.currentForm.get('virtualNetwork').updateValueAndValidity()
    }, 50);
    this.onToggle('virtualNetwork')
  }

  removeNewVnet(item,index){
    this.vnetList.splice(index,1)
    this.vnetList = [...this.vnetList]
    this.currentForm.get('virtualNetwork').setValue(null)
  }

  editNewVnet(item,index){
    this.virtualNetworkForm = this.fb.group({
      name : [null,[Validators.required]],
      addressSpaceList: this.fb.array([]),
      isEdit : false,
    })
    this.virtualNetworkForm.get('isEdit').setValue(true)
    this.currentIndex = index
    this.virtualNetworkForm.get('name').setValue(item.name)
    item.addressList.forEach(e=>{
      this.addressList.push(this.addAddressSpaceFormArray(e))
    })
    this.virtualNetworkForm.updateValueAndValidity();
    this.showVnet = true
  }

  addSubnetToExisting(){
    let subnetName = this.subnetForm.get('name').value 
    if(this.subnetForm.get('isEdit').value){
       this.subnetList[this.currentIndex].name = subnetName
       this.subnetList[this.currentIndex].address = this.subnetForm.get('address').value
    }
    else{
      let newRg : any = [{name: subnetName,isNew:true,address: this.subnetForm.get('address').value}]
      this.subnetList = [...this.subnetList,...newRg]
    }
    this.currentForm.get('subnet').setValue(null)
    this.currentForm.get('subnet').setValue(subnetName)
    setTimeout(() => {
      this.currentForm.get('subnet').updateValueAndValidity()
    }, 50);
    this.onToggle('subnet')
  }

  removeNewSubnet(item,index){
    this.subnetList.splice(index,1)
    this.subnetList = [...this.subnetList]
    this.currentForm.get('subnet').setValue(null)
  }

  editNewSubnet(item,index){
    this.subnetForm.get('isEdit').setValue(true)
    this.currentIndex = index
    this.subnetForm.get('name').setValue(item.name)
    this.subnetForm.get('address').setValue(item.address)
    this.subnetForm.updateValueAndValidity();
    this.showSubnet = true
  }


  resetValue(type){
    switch(type){
      case 'subscription' : {
        this.currentForm.get('resourceGroup').reset()
        this.currentForm.get('resourceGroup').updateValueAndValidity()
        this.currentForm.get('logAnalytics').reset()
        this.currentForm.get('logAnalytics').updateValueAndValidity()
        this.workspaceList = []
        break;
      }
      case 'resourceGroup' : {
        this.currentForm.get('logAnalytics').reset()
        this.currentForm.get('logAnalytics').updateValueAndValidity()
        break;
      }
      case 'amsSubscription' : {
        this.currentForm.get('resourceGroup').reset()
        this.currentForm.get('resourceGroup').updateValueAndValidity()
        this.currentForm.get('logAnalytics').reset()
        this.currentForm.get('logAnalytics').updateValueAndValidity()
        this.currentForm.get('virtualNetwork').reset()
        this.currentForm.get('virtualNetwork').updateValueAndValidity()
        this.currentForm.get('subnet').reset()
        this.currentForm.get('subnet').updateValueAndValidity()
        this.workspaceList = []
        break;
      }
      case 'amsVirtualNetwork' : {
        this.currentForm.get('subnet').reset()
        this.currentForm.get('subnet').updateValueAndValidity()
        break;
      }
    }
  }

  setMS(value){
    value == 'yes' ? this.currentForm.get('enableMSShare').setValue(true) :this.currentForm.get('enableMSShare').setValue(false)
  }

  validateResourceGroupName(event, limitNum){
    let value =event.target.value;
    if (value.length > limitNum) {
      this.inputError.maxLenResourceGroupName = true;
      return;
    }
    else {
      this.inputError.maxLenResourceGroupName = false;
    }
    let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(value[value.length-1]=='.'||regex.test(value)){
      this.inputError.validResourceGroupName=true;
      return;
    }else{
      this.inputError.validResourceGroupName=false;
    }

    if(this.resourceGroupList&&this.resourceGroupList.length){
      let findObj=this.resourceGroupList.find(dt=>dt.name==value&&!dt.newResourceGroupEdit);
      this.inputError.duplicateResourceGroupName = findObj?true:false;
    }else {
      this.inputError.duplicateResourceGroupName = false;
    }
  }

  validateLaName(event){
    let value =event.target.value;
    let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(value[value.length-1]=='.'||regex.test(value)){
      this.inputError.validLogAnalyticsName=true;
      return;
    }else{
      this.inputError.validLogAnalyticsName=false;
    }
    if(this.workspaceList.some(e=> e.name.toLowerCase() == value.toLowerCase())){
      this.inputError.isSameLogAnalyticsName = true;
      return
    }
    else  this.inputError.isSameLogAnalyticsName = false;
  }

  async ValidateResourceName(event){
    let value =event.target.value;
    let subsId = this.currentForm.get('subscription').value
    let rg = this.currentForm.get('resourceGroup').value
    if(!subsId || !rg) return;
    await this.util.delay(500)
    this.util.handleRequest('get','a3s_admin_getAMSResourceByName',[subsId,rg,value],null,null,null,true).then(res=>{
      this.inputError.validResourceName = true
    },err=>{
      this.inputError.validResourceName = false
    })
  }

  triggerWhenStopTyping(event){
    let typingTimer : any;
    var doneTypingInterval = 5000; 
    clearTimeout(typingTimer);
    typingTimer = setTimeout(async () => {
      await this.util.delay(200)
      this.ValidateResourceName(event)
    }, doneTypingInterval);
  }

  VNetNameValidation(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VNetName = true;
    }
    else this.inputError.VNetName = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError.VNetChar = false;
    }
    else{
      this.inputError.VNetChar = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError.VNetChar = true;
        return;
      }
    }

    let regex=/\`|\ |\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(regex.test(value)){
      this.inputError.VNetChar=true;
      return;
    }else{
      this.inputError.VNetChar=false;
    }

    let findObj=this.vnetList.find(dt=>dt.name==value);
    if(findObj){
      this.inputError.duplicateVnet=true;
      return;
    }
    // else if(this.vnetList&&this.vnetList.length){
    //   let findObjN=this.vnetList.find(dt=>dt.resourceName==value&&dt.resourceId!=this.VNetmodel.resourceId);
    //   if(findObjN){
    //     this.inputError.duplicateVnet=true;
    //     return;
    //   }else{
    //     this.inputError.duplicateVnet=false;
    //   }
    // }
    else{
      this.inputError.duplicateVnet=false;
    }
  }

  VNetAddressSpace(limitField,item,index){
    let value =limitField.target.value
    if(!this.isAddressSpace(value)){
      console.log(value,this.isAddressSpace(value));
      item.VNetAdrspc=true;
      return;
    }
    item.VNetAdrspc=false;
    this.validateAddress(item,index);
  }

  validateAddress(item,index){
    const splitAr=item.value.addressSpace.split('/');
    let vlIPRange;
    try{
      vlIPRange=new this.ipSubnetWork(new this.ipV4(splitAr[0]),splitAr[1]);
      item.VNetAdrspc=false;
    }catch(e){
      item.VNetAdrspc=true;
      return false;
    }
    if(vlIPRange.input!=item.value.addressSpace){
      item.notValidCIDR=true;
      item.validCIDRValue=vlIPRange.input;
      return false;
    }else{
      item.notValidCIDR=false;
      item.validCIDRValue=null;
    }

    if(this.publicIPs.includes(item.value.addressSpace)){
     item.publicAddress=true;
     return false;
    }else{
    item.publicAddress=false;
    }

    if(vlIPRange.range.left.parts[0]!="10"&&vlIPRange.range.left.parts[0]!="192"&&vlIPRange.range.left.parts[0]!="172"){
      item.publicAddress=true;
     return false;
    }else if(vlIPRange.range.left.parts[0]=="192"&&vlIPRange.range.left.parts[1]!="168"){
      item.publicAddress=true;
      return false;
    }else if(vlIPRange.range.left.parts[0]=="172"&&vlIPRange.range.left.parts[1]!="16"){
      item.publicAddress=true;
      return false;
    }else{
      item.publicAddress=false;
      }
    if(this.vnetList&&this.vnetList.length){
      let result=this.vnetList.find(dt=>{
        if(dt.properties && dt.properties.addressSpace && dt.properties.addressSpace.addressPrefixes.length){
          let addressPrefixes=dt.properties.addressSpace.addressPrefixes;
          return addressPrefixes.find(vl=>{
            const splitArr=vl.split('/');
           let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
           if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
             item.overlapingValue=vl;
             return true
           }

          })
        } else if(dt.isNew&& dt.addressList) {
          return dt.addressList.find(vl=>{
            const splitArr=vl.split('/');
            let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
            if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
              item.overlapingValue=vl;
              return true
            }
          })
        }
      })
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

    // if(this.existingNewVNList&&this.existingNewVNList.length){
    //   let result=this.existingNewVNList.find((dt)=>{
    //     try {
    //       if(dt.resourceData&&dt.resourceId!=this.VNetmodel.resourceId){
    //         let resourceData=JSON.parse(dt.resourceData);
    //        return resourceData.virtualNetworkAddressSpace.find(dtl=>{
    //           if(dtl.address){
    //             const splitArr=dtl.address.split('/');
    //               let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
    //             if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
    //               item.overlapingValue=dtl.address;
    //               return true
    //             }
    //           }
    //         })
    //       }
    //     } catch (error) {}
    //   });
    //   if(result){
    //     item.overlooping=true;
    //     return true;
    //   }else{
    //     item.overlooping=false;
    //   }
    // }

    if(this.virtualNetworkForm.value && this.virtualNetworkForm.value.addressSpaceList && this.virtualNetworkForm.value.addressSpaceList.length){
      let result=this.virtualNetworkForm.value.addressSpaceList.find((dt,i)=>{
        try {
          if(dt.addressSpace&&i!=index){
            const splitArr=dt.addressSpace.split('/');
              let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
              if(vlIPRange.input==ipRangObj.input){
                item.overlapingValue=dt.addressSpace;
                return true
              }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
                item.overlapingValue=dt.addressSpace;
                return true
              }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]==ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[2]==ipRangObj.range.right.parts[2]&&vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
                item.overlapingValue=dt.addressSpace;
                return true
              }else if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
              item.overlapingValue=dt.addressSpace;
              return true
            }
          }
        } catch (error) {}
      });
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

  }

  validateOverloopAddress(vlIPRange,ipRangObj){
    if(vlIPRange.range.left.input==ipRangObj.range.left.input){
      return true;
    }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
     return true;
    }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]){
        if(vlIPRange.range.left.parts[2]>ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.right.parts[2]<ipRangObj.range.right.parts[2]){
            return true;
          }
        }else if(vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.left.parts[3]>ipRangObj.range.left.parts[3]){
            if(vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
              return true;
            }
          }
        }
      }
      // if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]&&vlIPRange.range.left.parts[4]==ipRangObj.range.left.parts[4]){
      //   return true
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]<ipRangObj.range.left.parts[1]&&vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]!=ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
      //   return true
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
      //   return true
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]<ipRangObj.range.left.parts[1]&&vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]!=ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
      //   return true
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
      //    return true;
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]<ipRangObj.range.left.parts[2]&&vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]==ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[2]!=ipRangObj.range.right.parts[2]&&vlIPRange.range.right.parts[2]>ipRangObj.range.right.parts[2]){
      //   return true;
      // }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]&&vlIPRange.range.left.parts[3]<ipRangObj.range.left.parts[3]&&vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]==ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[2]==ipRangObj.range.right.parts[2]&&vlIPRange.range.right.parts[3]!=ipRangObj.range.right.parts[3]&&vlIPRange.range.right.parts[3]>ipRangObj.range.right.parts[3]){
      //   return true;
      // }
    }else if(vlIPRange.range.left.parts[0]<ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.right.parts[0]>ipRangObj.range.right.parts[0]){
        return true;
      }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]){
        if(vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
          return true;
        }
      }
    }else {
      return false;
    }
  }

  subnetNameValidation(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.subnetName = true;
    }
    else this.inputError.subnetName = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError.subnetChar = false;
    }
    else{
      this.inputError.subnetChar = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError.subnetChar = true;
        return;
      }
    }

    if(this.subnetList&&this.subnetList.length) {
      this.subnetList.find((dt) => {
        if((dt.name&&!dt.isEdit)||(dt.isEdit&&dt.name!=this.subnetForm.value.name)){
          if(dt.name===value){
            this.inputError.subnetDuplicate=true;
            return true;
          }else {
            this.inputError.subnetDuplicate=false;
          }
        }
      })
    } else {
      this.inputError.subnetDuplicate=false;
    }

    // if(this.parentResourceDtls&&this.parentResourceDtls.subnet&&this.parentResourceDtls.subnet.length){
    //   let findObj=this.parentResourceDtls.subnet.find(dt=>dt.resourceName==value&&this.subnetModel.resourceId!=dt.resourceId);
    //   if(findObj){
    //     this.inputError.subnetDuplicate=true;
    //     return;
    //   }else{
    //     this.inputError.subnetDuplicate=false;
    //   }
    // }
    // else{
    //   this.inputError.subnetDuplicate=false;
    // }
  }

  subnetAddressSpace(limitField){
    let value =limitField.target.value
    if(!this.isAddressSpace(value)){
      console.log(value,this.isAddressSpace(value));
      this.inputError.subnetAdrspc=true;
      return;
    }
    this.inputError.subnetAdrspc=false;

    let splitArr=value.split('/');
    try{
      let vlRangeObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
      this.inputError.subnetNotValidCIDR=false;
      if(vlRangeObj.bits>29){
        this.inputError.subnetPerfix=true;
        return;
      }else{
        this.inputError.subnetPerfix=false;
      }
      if(vlRangeObj.input!=value){
        this.inputError.subnetNotValidCIDR=true;
      }else{
        this.inputError.subnetNotValidCIDR=false;
      }
      
      if(this.vnetList&&this.vnetList.length) {
        this.vnetList.find((dt,index)=>{
          if(this.currentForm.value.virtualNetwork === dt.name &&dt.properties && dt.properties.addressSpace&&dt.properties.addressSpace.addressPrefixes.length){
            let addressPrefixes=dt.properties.addressSpace.addressPrefixes;
            let resultVal = addressPrefixes.find(vl=>{
                  let splitAr=vl.split('/');
                  let rangeObj=new this.ipSubnetWork(new this.ipV4(splitAr[0]),splitAr[1]);
                  if(this.validateOverloopAddress(vlRangeObj,rangeObj)){
                    this.inputError.subnetAddresNotInVnet=false;
                    return true;
                  }else if(index==this.vnetList.length-1){
                    this.inputError.subnetAddresNotInVnet=true;
                  }
            })
            if(resultVal){
              return true;
            }
          } else if(this.currentForm.value.virtualNetwork === dt.name &&dt.isNew&&dt.addressList&&dt.addressList.length){
            let resultVal = dt.addressList.find(vl=>{
                  let splitAr=vl.split('/');
                  let rangeObj=new this.ipSubnetWork(new this.ipV4(splitAr[0]),splitAr[1]);
                  if(this.validateOverloopAddress(vlRangeObj,rangeObj)){
                    this.inputError.subnetAddresNotInVnet=false;
                    return true;
                  }else if(index==this.vnetList.length-1){
                    this.inputError.subnetAddresNotInVnet=true;
                  }
            })
            if(resultVal){
              return true;
            }
          }
        })        
      }

      if(this.vnetList&&this.vnetList.length) {
          this.vnetList.find((dt,index)=>{
          if(this.currentForm.value.virtualNetwork === dt.name &&dt.properties&&dt.properties.subnet&&dt.properties.subnet.length){
            let subnetRes = dt.properties.subnet.find(vl=>{
              if(vl.properties.addressPrefix){
                  const splitArrSub=vl.properties.addressPrefix.split('/');
                  let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArrSub[0]),splitArrSub[1]);
                if(this.validateOverloopAddress(vlRangeObj,ipRangObj)){
                  this.inputError.subnetOverlaping=true;
                  //this.subnetModel['overlapingValue']=vl.properties.addressPrefix;
                  return true;
                }else{
                  this.inputError.subnetOverlaping=false;
                  //this.subnetModel['overlapingValue']='';
                }
              }
            })
            if(subnetRes){
              return true;
            } else if(this.currentForm.value.virtualNetwork === dt.name){
              let subnetRes2 = this.subnetList.find(vl=>{
                if((vl.isNew&&vl.address&&!vl.isEdit)||(vl.isEdit&&vl.name!==this.subnetForm.value.name)){
                    const splitArrSub=vl.address.split('/');
                    let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArrSub[0]),splitArrSub[1]);
                  if(this.validateOverloopAddress(vlRangeObj,ipRangObj)){
                    this.inputError.subnetOverlaping=true;
                    //this.subnetModel['overlapingValue']=vl.address;
                    return true;
                  }else{
                    this.inputError.subnetOverlaping=false;
                    //this.subnetModel['overlapingValue']='';
                  }
                }
              })
              if(subnetRes2){
                return true;
              }
            }
          }else if(this.currentForm.value.virtualNetwork === dt.name &&dt.isNew){
            let subnetRes = this.subnetList.find(vl=>{
              if((vl.isNew&&vl.address&&!vl.isEdit)||(vl.isEdit&&vl.name!==this.subnetForm.value.name)){
                  const splitArrSub=vl.address.split('/');
                  let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArrSub[0]),splitArrSub[1]);
                if(this.validateOverloopAddress(vlRangeObj,ipRangObj)){
                  this.inputError.subnetOverlaping=true;
                  //this.subnetModel['overlapingValue']=vl.address;
                  return true;
                }else{
                  this.inputError.subnetOverlaping=false;
                  //this.subnetModel['overlapingValue']='';
                }
              }
            })
            if(subnetRes){
              return true;
            }
          }
        });
      }
    }catch(e){
      this.inputError.subnetNotValidCIDR=true;
      return;
    }
  }

  isAddressSpace(string){
    return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.displayName.toLocaleLowerCase().indexOf(term) > -1 || item.subscriptionId.toLocaleLowerCase().indexOf(term) > -1;
   }

  onSelectAll(event,type) {
    let isChecked = event.target.checked

    switch(type){
      case 'subscription':{
        if(isChecked){
          const selected = this.subscriptionList.map(item => item.subscriptionId);
          this.currentForm.get('subscription').patchValue(selected);
        }
        else this.currentForm.get('subscription').patchValue([]);
        break;
      }
      case 'subscriptionSelected':{
        if(isChecked){
          const selected = this.selectedSubscriptionList.map(item => item.subscriptionId);
          this.currentForm.get('subscription').patchValue(selected);
        }
        else this.currentForm.get('subscription').patchValue([]);
        break;
      }
      case 'applicationInsights':{
        if(isChecked){
          const selected = this.appInsightsList.map(item => item.id);
          this.currentForm.get('appInsights').patchValue(selected);
        }
        else this.currentForm.get('appInsights').patchValue([]);
        break;
      }
      case 'virtualMachine':{
        if(isChecked){
          const selected = this.vmList.map(item => item.VM_name);
          this.currentForm.get('virtualMachine').patchValue(selected);
        }
        else this.currentForm.get('virtualMachine').patchValue([]);
        break;
      }
    }
   
  }

  checkedAll(type){
    switch(type){
      case 'subscription': return this.subscriptionList && this.currentForm.get('subscription').value ? this.subscriptionList.length == this.currentForm.get('subscription').value.length ? true : false : false
      case 'subscriptionSelected': return this.selectedSubscriptionList && this.currentForm.get('subscription').value ? this.selectedSubscriptionList.length == this.currentForm.get('subscription').value.length ? true : false : false
      case 'applicationInsights': return this.appInsightsList && this.currentForm.get('appInsights').value ? this.appInsightsList.length == this.currentForm.get('appInsights').value.length ? true : false : false
      case 'virtualMachine': return this.vmList && this.currentForm.get('virtualMachine').value ? this.vmList.length == this.currentForm.get('virtualMachine').value.length ? true : false : false
    }
    
  }
}
