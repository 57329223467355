import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-governance',
  templateUrl: './data-governance.component.html',
  styleUrls: ['./data-governance.component.scss']
})
export class DataGovernanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
