<div class="custom-fixed-tables">
    <table class="table">
      <thead>
        <tr>
          <th>
              <div class="flex-custom no-wrap">
                  <span>Name</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSet',0)">
              </div>
          </th>
          <th>
              <div class="flex-custom no-wrap">
                  <span>Resource Group</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSet',1)">
              </div>
          </th>
          <th>
              <div class="flex-custom no-wrap">
                  <span>Subscription</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSet',2)">
              </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>Region</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSet',3)">
            </div>
        </th>
        <th>
          <div class="flex-custom no-wrap">
              <span>Operating System</span>
              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSet',4)">
            </div>
        </th>
        <th>
          <div class="flex-custom no-wrap">
              <span>Instances</span>
              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSet',5)">
            </div>
        </th>
        <th style="border-right: none;">
          <div class="flex-custom no-wrap">
              <span>Tags</span>
              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSet',6)">
          </div>
        </th>
        <th style="border-left: none;" class="wid-75"></th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let row of data" >
            <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(vmScaleSetDetailsTemplate,row,'vmPopup')">{{ row[0] | displayTxt}}</td>
            <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
            <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
            <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
            <td isEllipsisActive>{{ row[4] | titlecase | displayTxt }}</td>
            <td isEllipsisActive>{{ row[5] }}</td>
            <td isEllipsisActive>{{getTags(row[6])}}</td>
            <td class="text-right wid-75">
              <span>
                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2" (click)="getOverviewCondition(row)">
                <mat-menu #action2="matMenu" class="delete-popup" >
                  <div class="mat-menu-item" *ngIf="!disableCondition" [class.disabled-icon]="disableStart &&!bothEnable" mat-menu-item (click)="action(row,'startVMScaleSet')">
                    <p class="delete-popup-text" >Start</p>
                  </div>
                  <div class="mat-menu-item" *ngIf="!disableCondition" [class.disabled-icon]="disableStop &&!bothEnable"mat-menu-item (click)="action(row,'restartVMScaleSet')">
                    <p class="delete-popup-text" >Restart</p>
                  </div>
                  <div class="mat-menu-item" *ngIf="!disableCondition" [class.disabled-icon]="disableStop &&!bothEnable"mat-menu-item (click)="action(row,'stopVMScaleSet')">
                    <p class="delete-popup-text">Stop</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('instances',row)">
                    <p class="delete-popup-text">Instances</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('networking',row)">
                    <p class="delete-popup-text">Networking</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('scaling',row)">
                    <p class="delete-popup-text">Scaling</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('size',row)">
                    <p class="delete-popup-text">Size</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                    <p class="delete-popup-text">Configuration</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('disk',row)">
                    <p class="delete-popup-text">Disk</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('upgradePolicy',row)">
                    <p class="delete-popup-text">Upgrade Policy</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('healthAndRepair',row)">
                    <p class="delete-popup-text">Health and Repair</p>
                  </div>
                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteVMScaleSetData')">
                      <p class="delete-popup-text">Delete</p>
                  </div>
                </mat-menu>
              </span>              
            </td>
        </tr>
        <div class="col-md-12 mt-3" *ngIf="!data?.length">
          <p class="content-14 blur-text">No data Found</p>
        </div>
      </tbody>
  </table>
</div>
<ng-container *ngIf="showSidesheet">


  <!-- VM Scale Set Instance-->
  <ng-container *ngIf="currentTemplate == 'instances'">
    <div class="sidebar-right single" [class.width-652]="disableCondition" [class.width-881]="!disableCondition" [class.sub-sheet]="showSubSidesheet">
      <div class="header">
          <div class="d-flex">
             <h4 class="half-width">Instances <br> <span class="blur-text">Virtual machine Scale Set | {{selectedRow[0]}}</span></h4>
             <div class="text-right half-width">
              <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
              </button>
            </div>
          </div>
      </div>
      
      <div class="body scroll-y">
        <form [formGroup]="currentForm">
          <div class="mb-2">
            <div class="flex-custom no-wrap" [class.disabled-icon]="!instanceId?.length">
              <label class="flex-custom cursor-pointer" [class.disabled-icon]="disableStart &&!bothEnable" (click)="action(selectedRow,'startVMScaleSet','instance')"><img class="mr-2" src="assets/actions/blueStart.svg">Start</label>
              <label class="flex-custom cursor-pointer" [class.disabled-icon]="disableStop &&!bothEnable" (click)="action(selectedRow,'restartVMScaleSet','instance')"><img class="mr-2 ml-3" src="assets/actions/blueRestart.svg">Restart</label>
              <label class="flex-custom cursor-pointer" [class.disabled-icon]="disableStop &&!bothEnable" (click)="action(selectedRow,'stopVMScaleSet','instance')"><img class="mr-2 ml-3" src="assets/actions/blueStop.svg">Stop</label>
              <label class="flex-custom cursor-pointer" [class.disabled-icon]="" (click)="action(selectedRow,'reimageVMScaleSet')" *ngIf="!disableCondition"><img class="mr-2 ml-3" src="assets/actions/blueReimage.svg">Reimage</label>
              <label class="flex-custom cursor-pointer" (click)="action(selectedRow,'deleteVMScaleSetInstance')"><img class="mr-2 ml-3" src="assets/actions/blueDelete.svg">Delete</label>
              <label class="flex-custom cursor-pointer" (click)="action(selectedRow,'upgradeVMScaleSet')" *ngIf="!disableCondition"><img class="mr-2 ml-3" src="assets/actions/blueUpgrade.svg">Upgrade</label>
              <label class="flex-custom cursor-pointer" [class.disabled-icon]="instanceId.length!=1" (click)="action(selectedRow,'protectionPolicy')" *ngIf="!disableCondition"><img class="mr-2 ml-3" src="assets/actions/blueKey.svg">Protection policy</label>
            </div>
          </div>    
          <div class="custom-fixed-tables" *ngIf="disableCondition">
              <table class="table">
                  <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer Name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Status</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Provisioning State</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <ng-container *ngFor="let list of vmscaleSetVMData;let i=index">
                          <tr>
                              <td>
                                <input type="checkbox"  name="instanceName" class="form-check-inline"  id='instanceName_{{i}}'  (change)="enableAction(list?.body,$event,i,'flexible')" formControlName="instanceID" [checked]="list?.body?.checked">
                                <label class="value" for="instanceName_{{i}}">{{list?.body?.name}}</label>
                              </td>
                              <td>{{list?.body?.properties?.osProfile?.computerName | displayTxt}}</td>
                              <td class="status-tag ml-12 mt-2 mb-2 mr-1" [ngClass]="getStatusClass(list?.body?.properties?.instanceView)">{{getStatusName(list?.body?.properties?.instanceView) | titlecase | displayTxt}}</td>
                              <td>{{list?.body?.properties?.instanceView?.statuses[0]?.code.split('/')[1] | displayTxt}}</td>
                          </tr>
                        </ng-container>
                    </tbody>
              </table>
          </div>
          <div class="custom-fixed-tables mt-0" *ngIf="!disableCondition">
            <table class="table">
                <thead>
                    <tr>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Computer Name</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Status</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                          </div>
                      </th>
                      <th >
                          <div class="flex-custom no-wrap">
                              <span>Health state</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Provisioning State</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                          </div>
                      </th>
                      <th>
                          <div class="flex-custom no-wrap">
                              <span>Protection Policy</span>
                              <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                          </div>
                      </th>
                      <th>
                        <div class="flex-custom no-wrap">
                            <span>Latest Model</span>
                            <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vmScaleSetinstance',1)">
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    <ng-container *ngFor="let data of vmscaleSetVMData;let i= index"> 
                        <tr>
                            <td>
                              <input type="checkbox"  name="instanceName" class="form-check-inline"  id='instanceName_{{i}}'  (change)="enableAction(data?.body,$event,i)" formControlName="instanceID" [checked]="data?.body?.checked">
                              <label class="value" for="instanceName_{{i}}">{{data?.body?.name}}</label>
                            </td>
                            <td>{{data?.body?.computerName | displayTxt}}</td>
                            <td class="status-tag ml-12 mt-2 mb-2 mr-1" [ngClass]="getStatusClass(data?.body)">{{getStatusName(data?.body) | titlecase | displayTxt}}</td>
                            <td ></td>
                            <td>{{data?.body?.statuses[0]?.code.split('/')[1] | displayTxt}}</td>
                            <td ></td>
                            <td >{{data?.body?.latestModel ?'Yes':'No'}}</td>
                        </tr>
                      </ng-container>
                  </tbody>
            </table>
          </div>
        </form>
      </div>
  </div>
  <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
    <div class="header">
        <div class="d-flex">
          <h4>Protection Policy<br> <span class="blur-text">VM Scale Set | {{selectedRow[0]}}</span></h4>
           <div class="text-right full-width">
            <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetSubSideSheetForm()">
              <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
            </button>
          </div>
        </div>
    </div>

    <div class="body scroll-y">
        <form [formGroup]="subSideSheetForm">
            <div class="comm-mb">
              <label class="flex-custom no-wrap">Instance protection<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Instance protection allows for specific instances to be treated differently 
                and not be modified with the other instances in the scale set." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <ng-select [items]="instanceProtectionList"  bindLabel="name" bindValue="id" name="instanceProtection" [clearable]="false" id="instanceProtection" (change)="saveDisable=true" formControlName="instanceProtection"></ng-select>
            </div> 
        </form>
    </div>

    <div class="fixed-btm-btn flex-custom no-wrap">
        <button class="btn btn-btn-primary" [class.disabled]="!saveDisable" (click)="addInstanceProtection()">Save</button>
    </div>
  </div>
  </ng-container>
  <!-- Upgrade Policy -->
  <ng-container *ngIf="currentTemplate == 'upgradePolicy'">
    <div class="sidebar-right single add-alert">
        <div class="header">
            <div class="d-flex">
          <h4>Upgrade Policy <br> <span class="blur-text">VM Scale Set | {{selectedRow[0]}}</span></h4>
              <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
          <form [formGroup]="currentForm">
              <div class="mb-3">
                  <label class="flex-custom no-wrap">Set the upgrade policy to determine how VM instances are brought up-to-date with the latest scale set model.</label>
              </div>
              <div class="comm-mb">
                  <label class="flex-custom no-wrap">Upgrade mode</label>
                  <ng-select [items]="upgradeModeList" bindLabel="name" bindValue="id" [class.disabled]="disableCondition" (change)="saveDisable=true" name="upgradeMode" [clearable]="false" id="upgradeMode" formControlName="upgradeMode"></ng-select>
              </div>
              <div class="comm-mb" *ngIf="disableCondition">
                <div class="label-div d-flex blueBox">
                  <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                  <label class="" style="padding: 8px 0px !important;">
                    Upgrade modes are not supported for Virtual machine scale sets with flexible orchestration mode.</label>
                </div>  
            </div>
              <ng-container *ngIf="currentForm.get('upgradeMode').value=='Rolling'">
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Rolling upgrade batch size %</label>
                  <input class="form-control full-width disabled" type="text" autocomplete="off" id="rollingUpgrade" name="rollingUpgrade" formControlName="rollingUpgrade" />
          
                </div>
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Pause time between batches (sec)</label>
                  <input class="form-control full-width disabled" type="text" autocomplete="off" id="pauseTime" name="pauseTime" formControlName="pauseTime"/>
                </div>
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Max unhealthy instance %</label>
                  <input class="form-control full-width disabled" type="text" autocomplete="off" id="maxInstance" name="maxInstance" formControlName="maxInstance" />
                </div>
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Max unhealthy upgraded %</label>
                  <input class="form-control full-width disabled" type="text" autocomplete="off" id="maxUpgrade" name="maxUpgrade" formControlName="maxUpgrade"/>
                </div>
                <div class="comm-mb">
                  <div class="checkbox-cs"> 
                    <input type="checkbox" name="prioritize " class="form-check-inline disabled-icon"  id='prioritize' formControlName="prioritize">
                    <label class="value disabled-icon" for="prioritize">Prioritize unhealthy instances<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Upgrade all unhealthy instances in a scale set before any healthy instances" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  </div>
               </div>
              <div class="comm-mb">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="zoneUpgrade" class="form-check-inline disabled-icon"  id='zoneUpgrade' formControlName="zoneUpgrade">
                  <label class="value disabled-icon" for="zoneUpgrade">Enable cross-zone upgrade<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow VMSS to ignore Availability Zone boundaries when creating upgrade batches" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
              </div>
              </ng-container>
          </form>
      </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable" (click)="saveVMScaleSetData('upgradePolicy')">Save</button>
        </div>
    </div>
  </ng-container>
    <!-- Health and Repair -->
    <ng-container *ngIf="currentTemplate == 'healthAndRepair'">
      <div class="sidebar-right single add-alert">
          <div class="header">
              <div class="d-flex">
            <h4>Health and Repair<br> <span class="blur-text">VM Scale Set | {{selectedRow[0]}}</span></h4>
                <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Enable application health monitoring<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enable health monitoring for the scale set. Health monitoring 
                    is required for platform managed infrastructure and OS upgrades." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="appHealth"  class="form-check-inline" id='enableAHM' (change)="setRadioBtn('yes','healthAndRepair','AHM')" [checked]="currentForm.get('appHealth')?.value ==true">
                        <label class="value" for="enableAHM">Enabled</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="appHealth" class="form-check-inline" id='disableAHM' (change)="setRadioBtn('no','healthAndRepair','AHM')" [checked]="currentForm.get('appHealth')?.value == false">
                        <label class="value" for="disableAHM">Disabled</label>
                    </div>
                  </div>
              </div>
              <ng-container *ngIf="currentForm.get('appHealth').value">
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Application health monitor<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose whether you want to monitor application health using a custom
                    Load Balancer probe, or the Application Health extension." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <ng-select [items]="AHMList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="AHM" [clearable]="false" id="AHM" formControlName="AHM"></ng-select>
                </div>
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Protocal<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The network protocol used by your application to report health. Protocol 
                    options are HTTP or TCP. Use a custom probe on a Standard SKU Load Balancer 
                    to enable HTTPS." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <ng-select [items]="protocalList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="protocal" [clearable]="false" id="protocal" formControlName="protocal"></ng-select>
                </div>
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Port number<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The network port used to monitor application health." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.InvalidPortRange||inputError?.NotValidPortVal||((currentForm.get('portNumber')?.dirty ||  currentForm.get('portNumber')?.touched) && !currentForm.get('portNumber')?.value)" id="portNumber" name="portNumber" formControlName="portNumber" (keyup)="portRangeValidation($event,65535)"/>
                  <p class="text-danger" *ngIf="(currentForm.get('portNumber')?.dirty ||  currentForm.get('portNumber')?.touched) && !currentForm.get('portNumber')?.value">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.InvalidPortRange">The port must be a number between 0 and 65535.</p>
                  <p class="text-danger" *ngIf="inputError?.NotValidPortVal">The value must be a valid number.</p>
                </div>
                <div class="comm-mb" *ngIf="currentForm.get('protocal').value!='tcp'">
                  <label class="flex-custom no-wrap">Path<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The application endpoint path used to report application health." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control full-width " type="text" [class.border-danger]="(currentForm.get('path')?.dirty ||  currentForm.get('path')?.touched) && !currentForm.get('path')?.value" autocomplete="off" id="path" name="path" formControlName="path" />
                  <p class="text-danger" *ngIf="(currentForm.get('path')?.dirty ||  currentForm.get('path')?.touched) && !currentForm.get('path')?.value">The value must not be empty.</p>
                </div>
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Enable automatic repairs<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Delete unhealthy instances automatically and create new 
                    ones with the latest instance model settings." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                 <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="automaticRepair"  class="form-check-inline" id='enableAutomaticRepair' (change)="setRadioBtn('yes','healthAndRepair','automaticRepair')" [checked]="currentForm.get('automaticRepair')?.value == true">
                          <label class="value" for="enableAutomaticRepair">On</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="automaticRepair" class="form-check-inline" id='disableAutomaticRepair' (change)="setRadioBtn('no','healthAndRepair','automaticRepair')" [checked]="currentForm.get('automaticRepair')?.value == false">
                          <label class="value" for="disableAutomaticRepair">Of</label>
                      </div>
                </div>
              </div>
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Grace period (min)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The amount of time for which automatic repairs are suspended due 
                    to a state change on the VM. The grace time starts after the state 
                    change has completed. This helps avoid premature or accidental repairs." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <input class="form-control full-width" [class.disabled]="!currentForm.get('automaticRepair').value" type="text" [class.border-danger]="inputError?.gracePeriodVal||inputError?.gracePeriodRange||((currentForm.get('gracePeriod')?.dirty ||  currentForm.get('gracePeriod')?.touched) && !currentForm.get('gracePeriod')?.value)" autocomplete="off" id="gracePeriod" name="gracePeriod" formControlName="gracePeriod" (keyup)="gracePeriodValidation($event)"/>
                  <p class="text-danger" *ngIf="(currentForm.get('gracePeriod')?.dirty ||  currentForm.get('gracePeriod')?.touched) && !currentForm.get('gracePeriod')?.value">The value must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.gracePeriodVal">The value must be a valid number.</p>
                  <p class="text-danger" *ngIf="inputError?.gracePeriodRange">The value must be between 10 and 90.</p>
                </div>
                <div class="mb-3" *ngIf="currentForm.get('automaticRepair').value">
                  <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #703501;">
                      Existing instances without the health extension have been detected. Please upgrade these instances in the Instances tab after enabling application health monitoring to apply the extension.</label>
                  </div>  
                </div> 
              </ng-container>
               
            </form>
        </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary"   [class.disabled]="currentForm.invalid || invalidForm('healthAndRepair')||!saveDisable" (click)="saveVMScaleSetData('healthAndRepair')">Save</button>
          </div>
      </div>
    </ng-container>
    <!-- Disk size -->
    <ng-container *ngIf="currentTemplate == 'size'">
      <div class="sidebar-right single width-652" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Size<br> <span class="blur-text">VM Scale Set | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
                <div class="comm-mb half-width">
                      <label class="flex-custom no-wrap">Selected Series<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Premium SSD disks offer high-performance, low-latency disk support for
                        I/O-intensive applications and production workloads. Standard SSD Disks 
                        are a cost effective storage option optimized for workloads that need 
                        consistent performance at lower IOPS levels. Use Standard HDD disks for
                         Dev/Test scenarios and less critical workloads at lowest cost." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="vmSeriesList"  bindLabel="name" bindValue="name" name="sizeSeries" [clearable]="false" id="sizeSeries" (change)="vmSizeListFilter()" formControlName="sizeSeries"></ng-select>
                </div>  
                    <div class="custom-fixed-tables mb-3">
                          <table class="table">
                              <thead>
                                  <tr>
                                    <th>
                                        <div class="flex-custom no-wrap">
                                            <span>VM size</span>
                                            <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('size',dummyVMSizeList)">
                                        </div>
                                    </th>
                                    <th>
                                        <div class="flex-custom no-wrap">
                                            <span>vCPUs</span>
                                            <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vCPU',dummyVMSizeList)">
                                        </div>
                                    </th>
                                     <th>
                                      <div class="flex-custom no-wrap">
                                          <span>RAM (GiB)</span>
                                          <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('RAM',dummyVMSizeList)">
                                      </div>
                                    </th>
                                      <th>
                                        <div class="flex-custom no-wrap">
                                            <span>Data disks</span>
                                            <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('dataDisk',dummyVMSizeList)">
                                        </div>
                                    </th>
                                      <th>
                                        <div class="flex-custom no-wrap">
                                            <span>Max IOPS</span>
                                            <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('Max',dummyVMSizeList)">
                                        </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody >
                                  <ng-container *ngFor="let list of dummyVMSizeList,let i=index">
                                      <tr (click)="setsize(list,i);" id="row-{{i}}" [class.active-row]="list?.active">
                                          <td>{{list?.series}}</td>
                                          <td>{{list?.capacity?.vCPUs}}</td>
                                          <td>{{list?.capacity?.Ram}}</td>
                                          <td>{{list?.capacity?.maxDataDiskCount}}</td>
                                          <td>{{list?.capacity?.maximum}}</td>
                                      </tr>
                                    </ng-container>
                                    <div class="col-md-12 mt-3" *ngIf="!dummyVMSizeList?.length">
                                      <p class="content-14 blur-text">No results</p>
                                    </div>
                                </tbody>
                          </table>
                    </div> 
                    <div class="comm-mb">
                      <div class="label-div d-flex blueBox">
                        <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                        <label class="" style="padding: 8px 0px !important;">
                          Prices presented are estimates in your local currency that include only Azure infrastructure costs and any discounts for the subscription and location. The prices don't include any applicable software costs. Final charges will appear in your local currency in cost analysis and billing views.</label>
                      </div>  
                    </div>
              </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || invalidForm('')"(click)="saveVMScaleSetData('size')">Resize</button>
          </div>
      </div>
    </ng-container>
    <!-- Configuration -->
    <ng-container *ngIf="currentTemplate == 'configuration'">
      <div class="sidebar-right single add-alert">
          <div class="header">
              <div class="d-flex">
            <h4>Configuration<br> <span class="blur-text">VM Scale Set | {{selectedRow[0]}}</span></h4>
                <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-3">
                  <label class="flex-custom no-wrap">Enable instance termination notification<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Opt in to receive instance termination notifications through the Azure 
                    Metadata Service and set a pre-defined delay timeout to the terminate operation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="enableTermination"  class="form-check-inline" id='enableTermination' (change)="setRadioBtn('yes','configuration','enableTermination')" [checked]="currentForm.get('enableTermination')?.value ==true">
                        <label class="value" for="enableTermination">On</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="enableTermination" class="form-check-inline" id='disableTermination' (change)="setRadioBtn('no','configuration','enableTermination')" [checked]="currentForm.get('enableTermination')?.value == false">
                        <label class="value" for="disableTermination">Off</label>
                    </div>
                  </div>
              </div>
              <div class="mb-3" *ngIf="currentForm.get('enableTermination').value">
                <label class="flex-custom no-wrap">Termination delay (minutes)</label>
                <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.InvalidPortRange||inputError?.NotValidPortVal||((currentForm.get('terminationDelay')?.dirty ||  currentForm.get('terminationDelay')?.touched) && !currentForm.get('terminationDelay')?.value)" id="terminationDelay" name="terminationDelay" formControlName="terminationDelay" (keyup)="portRangeValidation($event,15,'terminationDelay')"/>
                <p class="text-danger" *ngIf="(currentForm.get('terminationDelay')?.dirty ||  currentForm.get('terminationDelay')?.touched) && !currentForm.get('terminationDelay')?.value">The value must not be empty.</p>
                <p class="text-danger" *ngIf="inputError?.InvalidPortRange">The value must be between 5 and 15.</p>
                <p class="text-danger" *ngIf="inputError?.NotValidPortVal">The value must be a valid number.</p>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap bold-600">Overprovisioning<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="With overprovisioning turned on, the scale set actually spins up more VMs than you asked for, 
                  then deletes the extra VMs once the requested number of VMs are successfully provisioned. 
                  Overprovisioning improves provisioning success rates and reduces deployment time. You are 
                  not billed for the extra VMs, and they do not count toward your quota limits." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Enable overprovisioning</label>
                <div class="flex-custom" [class.disabled-icon]="disableCondition">
                    <div class="checkbox-cs">
                      <input type="radio" name="enableProvisioning"  class="form-check-inline" id='enableProvisioning' (change)="setRadioBtn('yes','configuration','enableProvisioning')" [checked]="currentForm.get('enableProvisioning')?.value ==true">
                      <label class="value" for="enableProvisioning">On</label>
                  </div>
                  <div class="checkbox-cs">
                      <input type="radio" name="enableProvisioning" class="form-check-inline" id='disableProvisioning' (change)="setRadioBtn('no','configuration','enableProvisioning')" [checked]="currentForm.get('enableProvisioning')?.value == false">
                      <label class="value" for="disableProvisioning">Off</label>
                  </div>
                </div>
            </div>
            <div class="comm-mb" *ngIf="disableCondition">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  Overprovisioning is not supported for Virtual machine scale sets with flexible orchestration mode.</label>
              </div>  
            </div>
            <div class="mb-3">
              <label class="flex-custom no-wrap">Proximity Placement Group<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A proximity placement group is a logical grouping used to make sure that Azure compute 
                resources are physically located close to each other. Proximity placement groups are 
                useful for workloads where low latency is a requirement." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <ng-select [items]="proximityGroupList" [class.disabled]="disableCondition ||(disableStart&&!bothEnable)" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="proximityGroup" [clearable]="false" id="proximityGroup" formControlName="proximityGroup"></ng-select>
            </div>
            <div class="comm-mb" *ngIf="disableCondition">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  Proximity placement group configuration cannot be changed in virtual machine scale sets with flexible orchestration mode</label>
              </div>  
            </div>
            <!-- <div class="mb-3">
              <label class="flex-custom no-wrap bold-600">Host<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure Dedicated Hosts allow you to provision and manage a physical server within our data centers 
                that are dedicated to your Azure subscription. A dedicated host gives you assurance that only VMs
                from your subscription are on the host, flexibility to choose VMs from your subscription that will be 
                provisioned on the host, and the control of platform maintenance at the level of the host." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            </div>
            <div class="mb-3">
              <label class="flex-custom no-wrap">Host group<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select a host group, then choose a host from within that group. The host group must be in the same 
                region and availability zone as the VM you are creating." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              <ng-select [items]="hostGroupList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="hostGroup" [clearable]="false" id="hostGroup" formControlName="hostGroup"></ng-select>
            </div>
            <div class="comm-mb" *ngIf="disableCondition">
              <div class="label-div d-flex blueBox">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                <label class="" style="padding: 8px 0px !important;">
                  Azure dedicated hosts are not available for scale sets with flexible orchestration mode.</label>
              </div>  
            </div>
            <ng-container *ngIf="!disableCondition">
              <div class="mb-3">
                <label class="flex-custom no-wrap bold-600">Capacity Reservations<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Capacity reservations allow you to reserve capacity for your virtual machine needs. You get the same 
                  SLA as normal virtual machines with the security of reserving the capacity ahead of time." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              </div>
              <div class="mb-3">
                <label class="flex-custom no-wrap">Capacity reservations groups<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select a capacity reservation group in order to consume on-demand capacity reservation. 
                  The capacity reservation group must be in the same region and have reservations for the 
                  specific virtual machine size in the region or in an availability zone." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <ng-select [items]="capacityGroupList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="capacityGroup" [clearable]="false" id="capacityGroup" formControlName="capacityGroup"></ng-select>
              </div>
            </ng-container> -->
            <!-- <ng-container *ngIf="disableCondition">
              <div class="mb-3">
                <label class="flex-custom no-wrap bold-600">Trusted launch<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The different levels of security available for your virtual machines. 
                  Standard offers basic protection at no extra costs. Trusted launch 
                  virtual machines provide additional security features on Gen 2 virtual 
                  machines to protect against persistent and advanced attacks. 
                  Confidential virtual machines include additional confidentiality protections 
                  for isolated machines or encrypted data on Gen 2 virtual machines." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
              </div>
              <div class="mb-2">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="secureBoot" class="form-check-inline"  id='secureBoot' formControlName="secureBoot">
                  <label class="value" for="secureBoot">Secure boot<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Secure boot helps protect your VMs against boot kits, rootkits, and kernel-level malware." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
              </div>
              <div class="mb-3">
                <div class="checkbox-cs"> 
                  <input type="checkbox" name="vTPM" class="form-check-inline"  id='vTPM' formControlName="vTPM">
                  <label class="value" for="vTPM">vTPM<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual Trusted Platform Module (vTPM) is TPM2.0 compliant and validates your 
                    VM boot integrity apart from securely storing keys and secrets." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                </div>
              </div>
            </ng-container> -->
      
          </form>
        </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary"   [class.disabled]="currentForm.invalid || invalidForm('configuration')||!saveDisable" (click)="saveVMScaleSetData('configuration')">Save</button>
          </div>
      </div>
    </ng-container>
    <!-- disk -->
    <ng-container *ngIf="currentTemplate == 'disk'">
      <div class="sidebar-right single width-881">
        <div class="header">
            <div class="d-flex">
               <h4 class="half-width">Disks <br> <span class="blur-text">Virtual machine Scale Set | {{selectedRow[0]}}</span></h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        
        <div class="body scroll-y">
          <form [formGroup]="currentForm">
            <div class="comm-mb">
              <label class="flex-custom no-wrap bold-600">OS Disk</label>
            </div>    
            <div class="custom-fixed-tables" style="overflow: initial;" >
              <table class="table">
                  <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Image Reference</span>
                                <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The platform image from the Azure Marketplace from 
                                which instances in this virtual machine scale set are created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Storage Type</span>
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Size (GiB)</span>
                            </div>
                        </th>
                        <th >
                            <div class="flex-custom no-wrap">
                                <span>Max IOPS</span>
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Max Throughput (MBps)</span>
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Encryption</span>
                                <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="SSE with PMK is server-side encryption with a platform-managed key. 
                                This is enabled by default on all managed disks. SSE with CMK is server-side
                                encryption with a customer-managed key. ADE is Azure disk encryption." matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
                            </div>
                        </th>
                        <th>
                          <div class="flex-custom no-wrap">
                              <span>Host Caching</span>
                              <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="With disk caching enabled on the premium storage disks, VMs can achieve
                              higher levels of performance that exceed the underlying disk performance." matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <ng-container > 
                        <!-- *ngFor="let data of vmscaleSetVMData;let i= index" -->
                          <tr>
                              <td>{{vmscaleSetData?.properties?.virtualMachineProfile?.storageProfile?.imageReference?.publisher}}</td>
                              <td>{{vmscaleSetData?.properties?.virtualMachineProfile?.storageProfile?.osDisk?.managedDisk?.storageAccountType}}</td>
                              <td>{{vmscaleSetData?.properties?.virtualMachineProfile?.storageProfile?.osDisk?.diskSizeGB}}</td>
                              <td >--</td>
                              <td>--</td>
                              <td >--</td>
                              <td>
                                 <ng-select [items]="hostCatchingList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="hostCatching" [clearable]="false" id="hostCatching" formControlName="hostCatching"></ng-select>
                              </td>
                          </tr>
                        </ng-container>
                    </tbody>
              </table>
            </div>
            <div class="comm-mb">
              <label class="flex-custom no-wrap bold-600">Data Disks</label>
            </div>    
            <button class="btn-btn-secondary" (click)="adddisk()">
              <img src="/assets/governance/add-btn.svg" alt="">
              Create and attach a new disk
          </button>
            <div class="custom-fixed-tables" style="min-height: 355px;" >
              <table class="table">
                  <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>LUN</span>
                                <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Logical unit number of the data disk. This value is used to identify 
                                data disks within the VM and therefore must be unique for each data 
                                disk attached to a VM." matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Storage Type</span>
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Size (GiB)</span>
                            </div>
                        </th>
                        <th >
                            <div class="flex-custom no-wrap">
                                <span>Max IOPS</span>
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Max throughput (MBps)</span>
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Encryption</span>
                                <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="SSE with PMK is server-side encryption with a platform-managed key. 
                                This is enabled by default on all managed disks. SSE with CMK is server-side
                                encryption with a customer-managed key. ADE is Azure disk encryption." matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
                            </div>
                        </th>
                        <th style="border-right: none;">
                          <div class="flex-custom no-wrap">
                              <span>Host caching</span>
                              <img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="With disk caching enabled on the premium storage disks, VMs can achieve
                              higher levels of performance that exceed the underlying disk performance." matTooltipClass="nav-tooltips" matTooltipShowDelay="500">
                          </div>
                        </th>
                        <th style="border-left: none;"></th>
                      </tr>
                    </thead>
                    <tbody >
                  
                        <ng-container formArrayName="vmScaleSetDataDiskArray" *ngFor="let list of currentForm.get('vmScaleSetDataDiskArray')['controls']; let i = index;">
                          <ng-container *ngIf="showDataDiskTable">
                          <tr [formGroupName]="i">
                            <td>
                              <ng-select [items]="list.get('lunList').value" bindLabel="name" bindValue="id" (change)="filterLUNList($event,i)" name="lun" [clearable]="false" id="lun" formControlName="lun"></ng-select>
                            </td>
                            <td>
                              <ng-select [items]="storageTypeList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="storageType" [clearable]="false" id="storageType" formControlName="storageType"></ng-select>
                            </td>
                            <td>
                              <input type="text" style="height: 32px;padding: 5px;" [class.border-danger]="inputError?.InvalidPortRange||inputError?.NotValidPortVal" name="size" class="form-check-inline width-58"  id='instanceName_{{i}}'  formControlName="size" (keyup)="portRangeValidation($event,32767,'size')">
                            </td>
                            <!-- ||((list.get('size')?.dirty ||  list.get('size')?.touched) && !list.get('size')?.value)" -->
                            <td >--</td>
                            <td >--</td>
                            <td >
                              <ng-select [items]="encryptionList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="encryption" [clearable]="false" id="encryption" formControlName="encryption"></ng-select>
                            </td>
                            <td>
                              <ng-select [items]="hostCatchingList" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="hostCatchingDisk" [clearable]="false" id="hostCatchingDisk" formControlName="hostCatchingDisk"></ng-select>
                            </td>
                            <td>
                              <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer mt-2" (click)="deleteDataDisk(i,list.get('lun').value)"> 
                            </td>        
                          </tr>
                        </ng-container>
                       </ng-container>
                 
                     
                    </tbody>
              </table>
              <!-- <p class="text-danger" *ngIf="(currentForm.get('size')?.dirty ||  currentForm.get('size')?.touched) && !currentForm.get('size')?.value">The value must not be empty.</p> -->
              <p class="text-danger" *ngIf="inputError?.lunError">The LUN number should not be same.</p>
              <p class="text-danger" *ngIf="inputError?.InvalidPortRange">The value must be between 4 and 32767.</p>
              <p class="text-danger" *ngIf="inputError?.NotValidPortVal">The value must be a valid number.</p>
            </div>
          </form>
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-btn-primary"   [class.disabled]="currentForm.invalid || invalidForm('disk')||!saveDisable" (click)="saveVMScaleSetData('disk')">Save</button>
       </div>
      </div>
    </ng-container>
      <!-- Scaling -->
      <ng-container *ngIf="currentTemplate == 'scaling'">
        <div class="sidebar-right single width-652" >
            <div class="header">
                <div class="d-flex">
                  <h4 class="threeFour-width ">Scaling<br> <span class="blur-text">VM Scale Set | {{selectedRow[0]}}</span></h4>
                  <div class="text-right half-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
             <form [formGroup]="currentForm">
              <div class="tabs-section">
                <div class="main-nav">
                  <ul class="nav-wrap m-0 p-0" >
                    <li class="list-item" [class.active]="currentTab == 'configure'" (click)="changeTab('configure')">
                      <span class="menu-name">Configure</span>
                    </li>
                    <li class="list-item" [class.active]="currentTab == 'scaleInPolicy'" (click)="changeTab('scaleInPolicy')">
                      <span class="menu-name">Scale-In Policy
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <ng-container *ngIf="currentTab =='configure'">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Choose how to scale your resource</label>
                    <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="scale"  class="form-check-inline" id='scale' (change)="setRadioBtn('manual','scaling','scale')" [checked]="currentForm.get('scale')?.value =='manual'">
                          <label class="value bold-600" for="scale">Manual scale<br><span style="font-weight: 400;">Maintain a fixed instance count</span></label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="customScale" class="form-check-inline" id='customScale' (change)="setRadioBtn('custom','scaling','scale')" [checked]="currentForm.get('scale')?.value == 'custom'">
                          <label class="value bold-600" for="customScale">Custom autoscale<br><span style="font-weight: 400;">Scale on any schedule, based on any metrics</span></label>
                      </div>
                    </div>
                </div>
                <ng-container *ngIf="currentForm.get('scale').value=='manual'">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap bold-600">Override Condition</label>
                  </div>
                  <div class="mb-3" >
                    <label class="flex-custom no-wrap">Instance count<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Indicates desired capacity" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control half-width" type="text" autocomplete="off" [class.border-danger]="inputError?.InvalidPortRange||inputError?.NotValidPortVal||((currentForm.get('overRideInstanceCount')?.dirty ||  currentForm.get('overRideInstanceCount')?.touched) && !currentForm.get('overRideInstanceCount')?.value)" id="overRideInstanceCount" name="overRideInstanceCount" formControlName="overRideInstanceCount" (keyup)="portRangeValidation($event,1000,'instanceCount')"/>
                    <p class="text-danger" *ngIf="(currentForm.get('overRideInstanceCount')?.dirty ||  currentForm.get('overRideInstanceCount')?.touched) && !currentForm.get('overRideInstanceCount')?.value">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.InvalidPortRange||inputError?.NotValidPortVal">The value must be between 1 and 1000.</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="currentForm.get('scale').value=='custom'">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Autoscale setting name<span class="mandatory">*</span></label>
                    <input class="form-control full-width " [class.disabled-input]="disableAutoScaleName" type="text" autocomplete="off" [class.border-danger]="inputError?.validScaleName" id="autoscaleName" name="autoscaleName" formControlName="autoscaleName" (keyup)="validAutoscaleName($event)"/>
                    <p class="text-danger" *ngIf="inputError?.validScaleName">The autoscale name may not contain /, %, &, >, <, #, ., ?, or \.</p>
                  </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Resource group</label>
                    <ng-select [items]="ResourceGroupsList" class="disabled"  bindLabel="name" bindValue="name" name="rg" [clearable]="false" id="rg" (change)="saveDisable=true" formControlName="rg"></ng-select>
                  </div>
                  <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600">Predictive autoscale (public preview)</label>
                  </div> 
                  <div class="mb-3">
                    <div class="flex-custom">
                      <div class="comm-mb mr-3 width-300">
                        <label class="flex-custom no-wrap ">Mode</label>
                        <ng-select [items]="mode"  class="disabled" bindLabel="name" bindValue="id" name="mode" [clearable]="false" id="mode" formControlName="mode"></ng-select>
                      </div>
                      <div class="mb-4">
                        <label class="flex-custom no-wrap">Pre-launch setup of instances (minutes)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify the time by which instances are launched in advance." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control disabled-input width-300" type="text" autocomplete="off" id="preLaunchSetup" name="preLaunchSetup" formControlName="preLaunchSetup" />
                      </div>
                    </div>
                 </div>
                 <div class="mb-3" *ngIf="defaultScaleCondition==false">
                  <button class="btn-btn-secondary" (click)="defaultScaleCondition=true">
                    <img src="/assets/governance/add-btn.svg" alt="">
                    Add a default scale condition
                   </button>
                 </div>
                <ng-container *ngIf="defaultScaleCondition">
                  <div class="mb-3">
                    <div class="flex-custom">
                      <label class="flex-custom no-wrap bold-600 mr-auto">Default Scale Condition<span class="mandatory">*</span></label>
                      <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer ml-2 mt-4" *ngIf="scaleCondition" (click)="defaultScaleCondition=false"> 
                    </div>
                   </div>
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Scale condition name<span class="mandatory">*</span></label>
                    <input class="form-control full-width " type="text" autocomplete="off" id="defScaleConditionName" name="defScaleConditionName" formControlName="defScaleConditionName" (keyup)="saveDisable=true"/>
                  </div>
                  <div class="comm-mb">
                    <div class="label-div d-flex blueBox">
                      <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                      <label class="" style="padding: 8px 0px !important;">
                        The very last or default recurrence rule cannot be deleted. Instead, you can disable autoscale to turn off autoscale.</label>
                    </div>  
                  </div>
                  <div class="mb-3">
                      <label class="flex-custom no-wrap">Scale mode</label>
                      <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="defScaleMode"  class="form-check-inline" id='metricScale' (change)="setRadioBtn('yes','scaling','defScaleMode')" [checked]="currentForm.get('defScaleMode')?.value ==true">
                            <label class="value" for="metricScale">Scale based on a metric</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="defScaleMode" class="form-check-inline" id='instanceCountScale' (change)="setRadioBtn('no','scaling','defScaleMode')" [checked]="currentForm.get('defScaleMode')?.value == false">
                            <label class="value" for="instanceCountScale">Scale to a specific instance count</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('defScaleMode').value">
                      <label class="flex-custom no-wrap bold-600">Instance Limits</label>
                      <div class="flex-custom">
                        <div class="mb-3 mr-2">
                          <label class="flex-custom no-wrap">Minimum<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify minimum instance count" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.defMinValidInstance||inputError?.defValidMinInstance||((currentForm.get('defInstanceMin')?.dirty ||  currentForm.get('defInstanceMin')?.touched) && !currentForm.get('defInstanceMin')?.value)" id="defInstanceMin" name="defInstanceMin" formControlName="defInstanceMin" (keyup)="instanceLimitDefaultValidation($event,'defMin')"/>
                        </div>
                        <div class="mb-3 mr-2">
                          <label class="flex-custom no-wrap">Maximum<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify maximum instance count" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.defMaxValidInstance|| inputError?.defValidMaximumInstance||inputError?.defValidMaxInstance||((currentForm.get('defInstanceMax')?.dirty ||  currentForm.get('defInstanceMax')?.touched) && !currentForm.get('defInstanceMax')?.value)" id="defInstanceMax" name="defInstanceMax" formControlName="defInstanceMax" (keyup)="instanceLimitDefaultValidation($event,'defMax')"/>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Default<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="In case there is a problem reading the resource metrics and the current 
                            capacity is below the default capacity, then to ensure the availability of 
                            the resource, Autoscale will scale out to the default.If the current capacity 
                            is already higher than the default capacity, Autoscale will not scale in." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.defValidDefInstance||inputError?.defaultDefValidInstance||((currentForm.get('defInstanceDef')?.dirty ||  currentForm.get('defInstanceDef')?.touched) && !currentForm.get('defInstanceDef')?.value)" id="defInstanceDef" name="defInstanceDef" formControlName="defInstanceDef" (keyup)="instanceLimitDefaultValidation($event,'defaultDef')"/>
                        </div>
                      </div>
                      <p class="text-danger" *ngIf="((currentForm.get('defInstanceMin')?.dirty ||  currentForm.get('defInstanceMin')?.touched) && !currentForm.get('defInstanceMin')?.value) || ((currentForm.get('defInstanceDef')?.dirty
                      ||  currentForm.get('defInstanceDef')?.touched) && !currentForm.get('defInstanceDef')?.value) || ((currentForm.get('defInstanceMax')?.dirty ||  currentForm.get('defInstanceMax')?.touched) && !currentForm.get('defInstanceMax')?.value)">The value must not be empty.</p>
                     <p class="text-danger" *ngIf="inputError?.defValidMinInstance || inputError?.defValidMaximumInstance">'Standard_B1s' SKU has minimum instance count of 0, and can scale out to 1000 instances. If you need to 
                       further scale out your instances, consider scaling up to a larger SKU.</p>
                     <p class="text-danger" *ngIf="inputError?.defMinValidInstance || inputError?.defMaxValidInstance || inputError?.defaultDefValidInstance">The value must be a valid number.</p>
                     <p class="text-danger" *ngIf="inputError?.defValidMaxInstance">Instance maximum value cannot be lower than minimum value.</p>
                     <p class="text-danger" *ngIf="inputError?.defValidDefInstance">Instance default value should be within min/max value.</p>
                   </div>
                   <div class="mb-3" *ngIf="!currentForm.get('defScaleMode').value">
                    <label class="flex-custom no-wrap">Instance count</label>
                    <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.defvalidNumber||inputError?.defvalidCount||((currentForm.get('defScaleInstanceCount')?.dirty ||  currentForm.get('defScaleInstanceCount')?.touched) && !currentForm.get('defScaleInstanceCount')?.value)" id="defScaleInstanceCount" name="defScaleInstanceCount" formControlName="defScaleInstanceCount" (keyup)="validInstanceCount($event,'def')"/>
                    <p class="text-danger" *ngIf="(currentForm.get('defScaleInstanceCount')?.dirty ||  currentForm.get('defScaleInstanceCount')?.touched) && !currentForm.get('defScaleInstanceCount')?.value">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.defvalidCount">The value must be between 1 and 1000.</p>
                    <p class="text-danger" *ngIf="inputError?.defvalidNumber">The value must be a valid number.</p>
                  </div>
                   <div class="mb-3">
                    <label class="flex-custom no-wrap">Schedule</label>
                    <label class="flex-custom no-wrap bold-600">This scale condition is executed when none of the other scale condition(s) match</label>
                  </div>
                </ng-container>
               
                <ng-container *ngIf="scaleCondition">
                 <ng-container formArrayName="scaleConditonArray" *ngFor="let list of currentForm.get('scaleConditonArray')['controls']; let i = index;">
                  <div [formGroupName]="i">
                  <div class="mb-3">
                    <div class="flex-custom">
                      <label class="flex-custom no-wrap bold-600 mr-auto">Scale Condition<span class="mandatory">*</span></label>
                      <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer ml-2 mt-4" *ngIf="defaultScaleCondition || scaleConditionArrayList.length>1"  (click)="deletescaleCondition(i)"> 
                    </div>
                    <!-- *ngIf="defaultScaleCondition" -->
                    </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Scale condition name<span class="mandatory">*</span></label>
                      <input class="form-control full-width " type="text" autocomplete="off" id="scaleConditionName_{{i}}" name="scaleConditionName_{{i}}" formControlName="scaleConditionName" (keyup)="saveDisable=true"/>
                    </div>
                    <div class="comm-mb">
                      <div class="label-div d-flex blueBox">
                        <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
                        <label class="" style="padding: 8px 0px !important;">
                          The very last or default recurrence rule cannot be deleted. Instead, you can disable autoscale to turn off autoscale.</label>
                      </div>  
                    </div>
                    <div class="mb-3">
                        <label class="flex-custom no-wrap">Scale mode</label>
                        <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="scaleMode"  class="form-check-inline" id='metricScale_{{i}}' (change)="setRadioBtn('yes','scaling','scaleMode',list,i)" [checked]="list.get('scaleMode')?.value ==true">
                              <label class="value" for="metricScale_{{i}}">Scale based on a metric</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="scaleMode" class="form-check-inline" id='instanceCountScale_{{i}}' (change)="setRadioBtn('no','scaling','scaleMode',list,i)" [checked]="list.get('scaleMode')?.value == false">
                              <label class="value" for="instanceCountScale_{{i}}">Scale to a specific instance count</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="list.get('scaleMode').value">
                        <label class="flex-custom no-wrap bold-600">Instance Limits</label>
                        <div class="flex-custom">
                          <div class="mb-3 mr-2">
                            <label class="flex-custom no-wrap">Minimum<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify minimum instance count" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.validMinInstance||inputError?.minValidInstance||((list.get('instanceMin')?.dirty ||  list.get('instanceMin')?.touched) && !list.get('instanceMin')?.value)" id="instanceMin_{{i}}" name="instanceMin_{{i}}" formControlName="instanceMin" (keyup)="instanceLimitValidation($event,'min',i)"/>
                          </div>
                          <div class="mb-3 mr-2">
                            <label class="flex-custom no-wrap">Maximum<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specify maximum instance count" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.validMaxInstance|| inputError?.validMaximumInstance||inputError?.maxValidInstance||((list.get('instanceMax')?.dirty ||  list.get('instanceMax')?.touched) && !list.get('instanceMax')?.value)" id="instanceMax_{{i}}" name="instanceMax_{{i}}" formControlName="instanceMax" (keyup)="instanceLimitValidation($event,'max',i)"/>
                          </div>
                          <div class="mb-3">
                            <label class="flex-custom no-wrap">Default<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="In case there is a problem reading the resource metrics and the current 
                              capacity is below the default capacity, then to ensure the availability of 
                              the resource, Autoscale will scale out to the default.If the current capacity 
                              is already higher than the default capacity, Autoscale will not scale in." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.validDefInstance||inputError?.defValidInstance||((list.get('instanceDef')?.dirty ||  list.get('instanceDef')?.touched) && !list.get('instanceDef')?.value)" id="instanceDef_{{i}}" name="instanceDef_{{i}}" formControlName="instanceDef" (keyup)="instanceLimitValidation($event,'def',i)"/>
                          </div>
                        </div>
                        <p class="text-danger" *ngIf="((list.get('instanceMin')?.dirty ||  list.get('instanceMin')?.touched) && !list.get('instanceMin')?.value) || ((list.get('instanceDef')?.dirty
                          ||  list.get('instanceDef')?.touched) && !list.get('instanceDef')?.value) || ((list.get('instanceMax')?.dirty ||  list.get('instanceMax')?.touched) && !list.get('instanceMax')?.value)">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.validMinInstance || inputError?.validMaximumInstance">'Standard_B1s' SKU has minimum instance count of 0, and can scale out to 1000 instances. If you need to 
                          further scale out your instances, consider scaling up to a larger SKU.</p>
                        <p class="text-danger" *ngIf="inputError?.minValidInstance || inputError?.maxValidInstance || inputError?.defValidInstance">The value must be a valid number.</p>
                        <p class="text-danger" *ngIf="inputError?.validMaxInstance">Instance maximum value cannot be lower than minimum value.</p>
                        <p class="text-danger" *ngIf="inputError?.validDefInstance">Instance default value should be within min/max value.</p>
                      </div>
                      <div class="mb-3" *ngIf="!list.get('scaleMode').value">
                      <label class="flex-custom no-wrap">Instance count</label>
                      <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.validCount||inputError?.validNumber||((list.get('scaleInstanceCount')?.dirty ||  list.get('scaleInstanceCount')?.touched) && !list.get('scaleInstanceCount')?.value)" id="scaleInstanceCount_{{i}}" name="scaleInstanceCount_{{i}}" formControlName="scaleInstanceCount" (keyup)="validInstanceCount($event,'')"/>
                      <p class="text-danger" *ngIf="(list.get('scaleInstanceCount')?.dirty ||  list.get('scaleInstanceCount')?.touched) && !list.get('scaleInstanceCount')?.value">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.validCount">The value must be between 1 and 1000.</p>
                      <p class="text-danger" *ngIf="inputError?.validNumber">The value must be a valid number.</p>
                    </div>
                  <div class="mb-3">
                      <label class="flex-custom no-wrap">Schedule</label>
                    <div class="flex-custom">
                      <div class="checkbox-cs">
                        <input type="radio" name="schedule"  class="form-check-inline" id='specificSchedule_{{i}}' (change)="setRadioBtn('yes','scaling','schedule',list,i)" checked>
                        <label class="value" for="specificSchedule_{{i}}">Specify start/end dates</label>
                      </div>
                      <!-- [checked]="list.get('schedule')?.value == false" -->
                      <div class="checkbox-cs">
                        <input type="radio" name="schedule" class="form-check-inline" id='repeatSchedule_{{i}}' (change)="setRadioBtn('no','scaling','schedule',list,i)" >
                        <label class="value" for="repeatSchedule_{{i}}">Repeat specific days</label>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3" *ngIf="!list.get('schedule').value">
                  <div class="flex-custom">
                    <input type="checkbox" name="monday_{{i}}" class="form-check-inline"  id='monday_{{i}}' formControlName="monday">
                    <label class="value mr-4" for="monday_{{i}}">monday</label>
                    <input type="checkbox" name="tuesday_{{i}}" class="form-check-inline"  id='tuesday_{{i}}' formControlName="tuesday">
                    <label class="value mr-4" for="tuesday_{{i}}">Tuesday</label>
                    <input type="checkbox" name="wednesday_{{i}}" class="form-check-inline"  id='wednesday_{{i}}' formControlName="wednesday">
                    <label class="value mr-4" for="wednesday_{{i}}">Wednesday</label>
                    <input type="checkbox" name="thursday_{{i}}" class="form-check-inline"  id='thursday_{{i}}' formControlName="thursday">
                    <label class="value mr-4" for="thursday_{{i}}">Thursday</label>
                    <input type="checkbox" name="friday_{{i}}" class="form-check-inline"  id='friday_{{i}}' formControlName="friday">
                    <label class="value mr-4" for="friday_{{i}}">Friday</label>
                    <input type="checkbox" name="saturday_{{i}}" class="form-check-inline"  id='saturday_{{i}}' formControlName="saturday">
                    <label class="value mr-5" for="saturday_{{i}}">Saturday</label>
                    <input type="checkbox" name="sunday_{{i}}" class="form-check-inline"  id='sunday_{{i}}' formControlName="sunday">
                    <label class="value" for="sunday_{{i}}">Sunday</label>
                  </div>
                </div>
                    
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Timezone</label>
                    <ng-select [items]="timeZoneList"  bindLabel="time" bindValue="name" name="timeZone_{{i}}" (change)="saveDisable=true" [clearable]="false" id="timeZone_{{i}}" formControlName="timeZone"></ng-select>
                  </div>
                  <div class="mb-3">
                    <div class="flex-custom">
                      <div class="mb-3 mr-3 width-300">
                        <label class="flex-custom no-wrap ">Start date</label>
                        <input  [owlDateTimeTrigger]="dt1" class="form-control full-width" (dateTimeChange)="setTime($event,'start',i)"  [owlDateTime]="dt1" placeholder="MM/DD/YYYY" formControlName="startDate">
                        <owl-date-time [pickerType]="'calendar'"  #dt1></owl-date-time>
                      </div>
                      <div class="mb-3 mt-12">
                        <input class="form-control width-300" type="text" autocomplete="off" (keyup)="saveDisable=true"  id="startTime_{{i}}" name="startTime_{{i}}" placeholder="h:mm:ss A" formControlName="startTime" />
                      </div>
                    </div>
                    <!-- <p class="text-danger" *ngIf="inputError?.endDateFormat">Date format must match 'MM/DD/YYYY' or 'YYYY-MM-DD'.</p> -->
                  </div>
                  <div class="mb-3">
                  <div class="flex-custom">
                    <div class="comm-mb mr-3 width-300">
                      <label class="flex-custom no-wrap ">End date</label>
                      <input [owlDateTimeTrigger]="dt2" class="form-control full-width" (dateTimeChange)="setTime($event,'end',i)" [owlDateTime]="dt2" placeholder="MM/DD/YYYY" formControlName="endDate">
                      <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                    </div>
                    <div class="mb-3 mt-12">
                      <input class="form-control width-300" type="text" autocomplete="off" id="endTime_{{i}}" name="endTime_{{i}}" placeholder="h:mm:ss A" formControlName="endTime" (keyup)="saveDisable=true" />
                    </div>
                  </div>
                  <!-- <p class="text-danger" *ngIf="inputError?.endDateFormat">Date format must match 'MM/DD/YYYY' or 'YYYY-MM-DD'.</p> -->
                </div>
                  <div class="mb-3" *ngIf="!defaultScaleCondition">
                    <label class="flex-custom no-wrap">Schedule</label>
                    <label class="flex-custom no-wrap bold-600">This scale condition is executed when none of the other scale condition(s) match</label>
                  </div>
                 </div>
                </ng-container>
                </ng-container>
                  <button class="btn-btn-secondary" (click)="addScaleCondition()">
                    <img src="/assets/governance/add-btn.svg" alt="">
                    Add a scale condition
                   </button>
                   <!-- *ngIf="!scaleCondition" -->
                </ng-container>             
            </ng-container>
            <ng-container *ngIf="currentTab =='scaleInPolicy'">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Configure the order in which virtual machines are selected for deletion during a scale-in operation.</label>
                  </div>
                  <div class="comm-mb">
                        <label class="flex-custom no-wrap">Scale-In Policy</label>
                        <ng-select [items]="scaleInPolicyList"  bindLabel="name" bindValue="id" name="scaleInPolicy" [clearable]="false" id="scaleInPolicy" (change)="saveDisable=true" formControlName="scaleInPolicy"></ng-select>
                  </div>  
            </ng-container>
             </form>
            </div>
            <!-- <div class="fixed-btm-btn flex-custom no-wrap" *ngIf="currentTab == 'configure'">
                <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || invalidForm('')"(click)="saveVMScaleSetData('scalingConfigure')">Save</button>
            </div> -->
            <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || !saveDisable "(click)="saveVMScaleSetData('scaling')">Save</button>
           </div>
           <!-- || invalidForm('scaling') -->
        </div>
      </ng-container>
    <!-- Networking -->
      <ng-container *ngIf="currentTemplate == 'networking'">
        <div class="sidebar-right single width-881" [class.sub-sheet]="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
                 <h4 class="half-width">Networking <br> <span class="blur-text">Virtual machine Scale Set | {{selectedRow[0]}}</span></h4>
                 <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="comm-mb">
                <button class="btn-btn-secondary" [class.disabled-icon]="vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.length>1 || (disableStart &&!bothEnable)"  (click)="openNICSideSheet()">
                  <img src="/assets/governance/add-btn.svg" alt="">
                  Add network interface
              </button>
              </div>  
              <div class="tabs-section" >
                <div class="main-nav">
                  <ul class="nav-wrap m-0 p-0" >
                    <ng-container *ngFor="let nicList of vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations; let i = index;">
                    <li class="list-item" [class.active]="currentNICTab == nicList?.name" (click)="changeNetworkingTab(nicList?.name,'NIC')">
                      <span class="menu-name">{{nicList?.name}}</span>
                    </li>
                  </ng-container>
                  </ul>
                </div>
              </div>  
               <!-- <ng-container *ngIf="currentNICTab == 'nicList?.name'"> -->
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">IP configuration<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose an IP configuration for your network interface." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                  <ng-select [items]="ipConfigList" class="half-width" bindLabel="name" bindValue="id" name="ipConfig" [clearable]="false" id="ipConfig" (change)="changeIPConfig($event)" formControlName="ipConfig"></ng-select>
                </div>    
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Virtual network/subnet</label>
                  <label class="flex-custom no-wrap bold-600">{{currentForm.get('vNet').value}}/{{currentForm.get('subnet').value}}</label>
                </div>
                <div class="comm-mb">
                  <label class="flex-custom no-wrap">Accelerated networking</label>
                  <label class="flex-custom no-wrap bold-600">{{nicList?.properties?.enableAcceleratedNetworking?'Enabled':'Disabled'}}</label>
                </div>
               <!-- </ng-container> -->
 
              <div class="tabs-section">
                <div class="main-nav">
                  <ul class="nav-wrap m-0 p-0" >
                    <li class="list-item" [class.active]="currentTab == 'inbound'" (click)="changeNetworkingTab('inbound','')">
                      <span class="menu-name">Inbound port rules</span>
                    </li>
                    <li class="list-item" [class.active]="currentTab == 'outbound'" (click)="changeNetworkingTab('outbound','')">
                      <span class="menu-name">Outbound port rules</span>
                    </li>
                    <li class="list-item" [class.active]="currentTab == 'loadBalancing'" (click)="changeNetworkingTab('loadBalancing','')">
                      <span class="menu-name">Load balancing</span>
                    </li>
                  </ul>
                </div>
              </div>  
              <ng-container *ngIf="currentTab == 'inbound'">
                <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openNormalModal(addSecurityRulesPopup,'inbound',false)">
                    <img src="/assets/governance/add-btn.svg" alt="">
                    Add inbound port rules
                </button>
                </div>  
                <div class="custom-fixed-tables" style="min-height: calc(100vh - 595px);" >
                  <table class="table">
                      <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Priority</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Port</span>
                                </div>
                            </th>
                            <th >
                                <div class="flex-custom no-wrap">
                                    <span>Protocol</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Source</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Destination</span>
                                </div>
                            </th>
                            <th style="border-right: none;">
                              <div class="flex-custom no-wrap">
                                  <span>Action</span>
                              </div>
                            </th>
                            <th style="border-left: none;"></th>
                          </tr>
                        </thead>
                        <tbody >

                        <ng-container *ngFor="let list of nsgData?.properties?.securityRules">
                            <tr *ngIf="list?.properties?.direction == 'Inbound'">
                                <td>{{list?.properties?.priority}}</td>
                                <td>{{list?.name}}</td>
                                <td>{{getTableArraydata(list,'sourcePort')}}</td>
                                <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                <td>{{getTableArraydata(list,'source')}}</td>
                                <td>{{getTableArraydata(list,'destination')}}</td>
                                <td>
                                    <div class="status-tag" [ngClass]="getStatusChipClass(list?.properties?.access)">
                                        <img [src]="getStatusChipImg(list?.properties?.access)">
                                        {{list?.properties?.access}}
                                    </div>
                                </td>
                                <td>   
                                  <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer" (click)="deleteRule(list,'inbound')"> 
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let list of nsgData?.properties?.defaultSecurityRules">
                          <tr *ngIf="list?.properties?.direction == 'Inbound'">
                              <td>{{list?.properties?.priority}}</td>
                              <td>{{list?.name}}</td>
                              <td>{{list?.properties?.sourcePortRange == '*' ? 'Any' : list?.properties?.sourcePortRange}}</td>
                              <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                              <td>{{list?.properties?.sourceAddressPrefix == '*' ? 'Any' : list?.properties?.sourceAddressPrefix}}</td>
                              <td>{{list?.properties?.destinationAddressPrefix == '*' ? 'Any' : list?.properties?.destinationAddressPrefix}}</td>
                              <td>
                                  <div class="status-tag" [ngClass]="getStatusChipClass(list?.properties?.access)">
                                      <img [src]="getStatusChipImg(list?.properties?.access)">
                                      {{list?.properties?.access}}
                                  </div>
                              </td>
                              <td>
                                <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer disabled-icon" > 
                              </td>
                          </tr>
                       </ng-container>
                        
                      </tbody>
                  </table>
                </div>
              </ng-container>

              <ng-container *ngIf="currentTab == 'outbound'">
                <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openNormalModal(addSecurityRulesPopup,'outbound',false)">
                    <img src="/assets/governance/add-btn.svg" alt="">
                    Add outbound port rules
                </button>
                </div>  
                <div class="custom-fixed-tables" style="min-height: calc(100vh - 595px);" >
                  <table class="table">
                      <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Priority</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Port</span>
                                </div>
                            </th>
                            <th >
                                <div class="flex-custom no-wrap">
                                    <span>Protocol</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Source</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Destination</span>
                                </div>
                            </th>
                            <th style="border-right: none;">
                              <div class="flex-custom no-wrap">
                                  <span>Action</span>
                              </div>
                            </th>
                            <th style="border-left: none;"></th>
                          </tr>
                        </thead>
                        <tbody >
                            <ng-container *ngFor="let list of nsgData?.properties?.securityRules">
                                <tr *ngIf="list?.properties?.direction == 'Outbound'">
                                    <td>{{list?.properties?.priority}}</td>
                                    <td>{{list?.name}}</td>
                                    <td>{{getTableArraydata(list,'sourcePort')}}</td>
                                    <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                    <td>{{getTableArraydata(list,'source')}}</td>
                                    <td>{{getTableArraydata(list,'destination')}}</td>
                                    <td>
                                        <div class="status-tag" [ngClass]="getStatusChipClass(list?.properties?.access)">
                                            <img [src]="getStatusChipImg(list?.properties?.access)">
                                            {{list?.properties?.access}}
                                        </div>
                                    </td>
                                    <td>   
                                      <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer" (click)="deleteRule(list,'outbound')"> 
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let list of nsgData?.properties?.defaultSecurityRules">
                              <tr *ngIf="list?.properties?.direction == 'Outbound'" >
                                  <td>{{list?.properties?.priority}}</td>
                                  <td>{{list?.name}}</td>
                                  <td>{{list?.properties?.sourcePortRange == '*' ? 'Any' : list?.properties?.sourcePortRange}}</td>
                                  <td>{{list?.properties?.protocol == '*' ? 'Any' : list?.properties?.protocol}}</td>
                                  <td>{{list?.properties?.sourceAddressPrefix == '*' ? 'Any' : list?.properties?.sourceAddressPrefix}}</td>
                                  <td>{{list?.properties?.destinationAddressPrefix == '*' ? 'Any' : list?.properties?.destinationAddressPrefix}}</td>
                                  <td>
                                      <div class="status-tag" [ngClass]="getStatusChipClass(list?.properties?.access)">
                                          <img [src]="getStatusChipImg(list?.properties?.access)">
                                          {{list?.properties?.access}}
                                      </div>
                                  </td>
                                  <td>
                                    <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer disabled-icon"> 
                                  </td>
                              </tr>
                            </ng-container>
                    </tbody>
                  </table>
                </div>
              </ng-container>

              <ng-container *ngIf="currentTab == 'loadBalancing'">
                <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openLBSideSheet()">
                    <img src="/assets/governance/add-btn.svg" alt="">
                    Add load balancing
                </button>
                </div>  
                <div class="custom-fixed-tables" style="min-height: calc(100vh - 595px);" >
                  <table class="table">
                      <thead>
                          <tr>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Name</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Type</span>
                                </div>
                            </th>
                            <th>
                                <div class="flex-custom no-wrap">
                                    <span>Frontend IP Address</span>
                                </div>
                            </th>
                            <th >
                                <div class="flex-custom no-wrap">
                                    <span>Frontend DNS Address</span>
                                </div>
                            </th>
                            <!-- <th style="border-right: none;"> -->
                              <th>
                              <div class="flex-custom no-wrap">
                                  <span>Backend pool</span>
                              </div>
                            </th>
                            <!-- <th style="border-left: none;"></th> -->
                          </tr>
                        </thead>
                        <tbody >
                          <ng-container *ngFor="let list of loadBalancerList;let i= index"> 
                              <tr>
                                  <td >{{list?.id.split('/')[8]}}</td>
                                  <td>Load balancer</td>
                                  <td >--</td>
                                  <td >--</td>
                                  <td >{{list?.id.split('/')[10]}}</td>
                                  <!-- <td class="disabled-icon">   
                                    <img src="assets/app-monitoring/Delete.svg" alt="" style="height:16.67px"  class="cursor-pointer" (click)="deleteRule(list,'deleteLB')"> 
                                  </td> -->
                              </tr>
                            </ng-container>
                        </tbody>
                  </table>
                </div>
              </ng-container>

            </form>
          </div>
          <!-- <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary"   [class.disabled]="currentForm.invalid || invalidForm('networking')||!saveDisable" (click)="saveVMScaleSetData('networking')">Save</button>
         </div> -->
        </div>

        <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet && !showNICSidesheet">
          <div class="header">
              <div class="d-flex">
                 <h4>Add Load Balancing<br> <span class="blur-text">VM Scale Set | {{selectedRow[0]}}</span></h4>
                 <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;popUpForm.reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
              <form [formGroup]="popUpForm">
                  <div class="comm-mb">
                      <label class="flex-custom no-wrap">Update the virtual machine scale set model to place new virtual machine instances in the backend pool of an existing Azure load balancing solution. New instances will be added to the load balancer (or application gateway) backend pool. To add an existing instance to the load balancer or application gateway, set upgrade policy to Rolling or Automatic, or update the individual instance manually.</label>
                    </div>
                  <div class="comm-mb">
                      <label class="flex-custom no-wrap">Load balancing options<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the type of load balancer you will 
                        use to route traffic to this virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="lbOptionsList" class="disabled" bindLabel="name" bindValue="id" name="lbOptions" [clearable]="false" id="lbOptions" formControlName="lbOptions"></ng-select>
                  </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Select a load balancer<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select a load balancer that will route traffic to this 
                      virtual machine. Azure Load Balancer (standard SKU) 
                      is supported. To connect this virtual machine to a Basic 
                      load balancer, go to the Load Balancer and add the virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    <ng-select [items]="lbSelectorList" bindLabel="name" bindValue="id" name="lbSelector" [clearable]="false" id="lbSelector" formControlName="lbSelector" placeholder="" (change)="changeLBName($event)"></ng-select>
                </div>
                <ng-container *ngIf="popUpForm.get('lbSelector').value">
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Backend pool</label>
                    <div class="flex-custom no-wrap">
                        <div class="checkbox-cs">
                            <input type="radio" name="backendPool"  class="form-check-inline" id='new' (change)="setRadioBtn('new','networking')" [checked]="popUpForm.get('backendPool')?.value =='new'">
                            <label class="value" for="new">Create new</label>
                        </div>
                        <div class="checkbox-cs ml-2">
                            <input type="radio" name="backendPool" class="form-check-inline" id='exist' (change)="setRadioBtn('exist','networking')" [checked]="popUpForm.get('backendPool')?.value =='exist'">
                            <label class="value" for="exist">Use existing</label>
                        </div>
                    </div>
                </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Select a backend pool<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Network traffic that arrives on the load balancer is routed to
                      backend pool instances, according to rules and health probes." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                      <input *ngIf="popUpForm.get('backendPool').value=='new'" class="form-control" type="text" autocomplete="off" id="poolName" name="poolName" placeholder="" formControlName="poolName" (keyup)="poolValidation($event,'text')" />
                      <ng-select *ngIf="popUpForm.get('backendPool').value=='exist'" [items]="backendPoolList" bindLabel="name" bindValue="id" name="poolName" (change)="poolValidation($event)" [clearable]="false" id="poolName" formControlName="poolName"></ng-select>
                      <p class="text-danger" *ngIf="inputError?.poolValid">This virtual machine is already associated with this backend pool.</p>
                      <p class="text-danger" *ngIf="inputError?.poolExistValid">This backend pool name is already exist in this load balancer.</p>
                  </div>
                </ng-container>
              </form>
          </div>
  
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="popUpForm.invalid || inputError?.poolValid || inputError?.poolExistValid" (click)="addBackendPool()">Save</button>
          </div>
        </div>

        <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet && showNICSidesheet">
          <div class="header">
              <div class="d-flex">
                 <h4>Create Network Interface<br> <span class="blur-text">VM Scale Set | {{selectedRow[0]}}</span></h4>
                 <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;popUpForm.reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
              <form [formGroup]="popUpForm">
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                  <input class="form-control full-width " type="text" autocomplete="off" [class.border-danger]="inputError?.nicNameEmpty || inputError?.nicNameLen||inputError?.nicNameDupCheck||inputError?.nicNameValid||inputError?.nicNameLastValid" id="nicName" name="nicName" formControlName="nicName" (keyup)="validIPName($event,80,'nic')"/>
                  <p class="text-danger" *ngIf="inputError?.nicNameEmpty">The name must not be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.nicNameLen">The name must be between 1 and 80 characters.</p>
                  <p class="text-danger" *ngIf="inputError?.nicNameDupCheck">A Network interface with this name already exists. </p>
                  <p class="text-danger" *ngIf="inputError?.nicNameValid ||inputError?.nicNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                      and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>
                  <div class="comm-mb">
                      <label class="flex-custom no-wrap">Virtual network<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the type of load balancer you will 
                        use to route traffic to this virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="vNetList" class="disabled" bindLabel="name" bindValue="id" name="vNet" [clearable]="false" id="vNet" formControlName="vNet"></ng-select>
                  </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Subnet<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Subnets which are not in a 'succeeded' provisioning state will be disabled." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    <ng-select [items]="subnetList" bindLabel="name" bindValue="id" name="subnet" [clearable]="false" id="subnet" formControlName="subnet" placeholder="" (change)="changeLBName($event)"></ng-select>
                </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">NIC network security group<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A network security group contains security rules that allow or deny inbound network 
                      traffic to, or outbound network traffic from, the virtual machine. To simplify management 
                      of security rules, it's recommended that you associate a network security group to individual 
                      subnets, rather than individual network interfaces within the subnet, whenever possible." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                    <div class="flex-custom no-wrap">
                        <div class="checkbox-cs">
                            <input type="radio" name="NICNSG"  class="form-check-inline" id='none' (change)="setRadioBtn('none','networkingNIC','networkingNSG')" [checked]="popUpForm.get('NICNSG')?.value =='None'">
                            <label class="value" for="none">None</label>
                        </div>
                    </div>
                    <div class="flex-custom no-wrap">
                        <div class="checkbox-cs">
                            <input type="radio" name="NICNSG" class="form-check-inline" id='basic' (change)="setRadioBtn('basic','networkingNIC','networkingNSG')" [checked]="popUpForm.get('NICNSG')?.value =='Basic'">
                            <label class="value" for="basic">Basic</label>
                        </div>
                    </div>
                    <div class="flex-custom no-wrap">
                      <div class="checkbox-cs">
                          <input type="radio" name="NICNSG" class="form-check-inline" id='advanced' (change)="setRadioBtn('advanced','networkingNIC','networkingNSG')" [checked]="popUpForm.get('NICNSG')?.value =='Advanced'">
                          <label class="value" for="advanced">Advanced</label>
                      </div>
                  </div>
                </div>
                <ng-container *ngIf="popUpForm.get('NICNSG').value=='Basic'">
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Public inbound ports<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Subnets which are not in a 'succeeded' provisioning state will be disabled." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    <div class="flex-custom no-wrap">
                        <div class="checkbox-cs">
                            <input type="radio" name="publicInbound"  class="form-check-inline" id='noneIP' (change)="setRadioBtn('none','networkingNIC','publicInbound')" [checked]="popUpForm.get('publicInbound')?.value =='None'">
                            <label class="value" for="noneIP">None</label>
                        </div>
                    </div>
                    <div class="flex-custom no-wrap">
                        <div class="checkbox-cs">
                            <input type="radio" name="publicInbound" class="form-check-inline" id='allow' (change)="setRadioBtn('allow','networkingNIC','publicInbound')" [checked]="popUpForm.get('publicInbound')?.value =='Allow'">
                            <label class="value" for="allow">Allow selected ports</label>
                        </div>
                    </div>
                  </div>
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Select inbound ports</label>
                   <div class="margin-multi-select">
                    <ng-select class="full-width" [items]="inboundPortList" [multiple]="true"  [class.disabled]="popUpForm.get('publicInbound')?.value =='None'" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="selectInbound" [clearable]="false" id="selectInbound" formControlName="selectInbound">
                        <ng-template ng-multi-label-tmp let-items="items">
                            <span>{{items.length}} selected</span>
                          </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div>
                              <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected"  [checked]="item$.selected"/> {{item.name}}
                            </div>
                          </ng-template>
                    </ng-select>
                   </div>
                 </div>
                </ng-container>

                <ng-container *ngIf="popUpForm.get('NICNSG').value=='Advanced'">
                  <div class="comm-mb">
                    <label class="flex-custom no-wrap">Configure network security group<span class="mandatory">*</span></label>
                    <ng-select [items]="nsgList" bindLabel="name" bindValue="id" name="configNSG" [clearable]="false" id="configNSG" formControlName="configNSG" placeholder=""></ng-select>
                    <span class="blue-text text-12-18 cursor-pointer" #anchor1 (click)="onToggle('nsg');popUpForm.get('nsgName').reset()">Create New</span>
                    <kendo-popup [anchor]="anchor1" (anchorViewportLeave)="showNsgPopup = false" *ngIf="showNsgPopup" id="createNSG">
                        <div class='content kendo-content resourceGroup'>
                          <div class="rgPopup-form mb-3">
                            <label for="nsgName" class="flex-custom bold-600 mb-2">Create netwrok security group</label>
                              <label for="nsgPName" class="">Name<span class="mandatory">*</span></label>
                              <div>
                                <input type="text" class="form-control"  id="nsgName" autocomplete="off" [class.border-danger]="inputError?.nsgNameEmpty || inputError?.nsgNameLen||inputError?.nsgNameDupCheck||inputError?.nsgNameValid||inputError?.nsgNameLastValid" [clearable]="false" placeholder="" name="nsgName" formControlName="nsgName" (keyup)="validIPName($event,80,'nsg')" >
                                <p class="text-danger" *ngIf="inputError?.nsgNameEmpty">The name must not be empty.</p>
                                <p class="text-danger" *ngIf="inputError?.nsgNameLen">The name cannot be longer than 80 characters.</p>
                                <p class="text-danger" *ngIf="inputError?.nsgNameDupCheck">A NSG with this name already exists. </p>
                                <p class="text-danger" *ngIf="inputError?.nsgNameValid ||inputError?.nsgNameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                                    and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                              </div>              
                          </div>
                          <div class="d-flex">
                              <button class="btn btn-btn-primary mr-2" [class.disabled]="inputError?.nsgNameEmpty || inputError?.nsgNameLen || inputError?.nsgNameDupCheck||inputError?.nsgNameValid||inputError?.nsgNameLastValid" (click)="createNSG()">Ok</button>
                              <button class="btn btn-outline" (click)="onToggle('nsg')">Cancel</button>
                          </div>
                        </div>
                    </kendo-popup>
                  </div>
                </ng-container>
            
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Public IP address<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A network security group contains security rules that allow or deny inbound network 
                  traffic to, or outbound network traffic from, the virtual machine. To simplify management 
                  of security rules, it's recommended that you associate a network security group to individual 
                  subnets, rather than individual network interfaces within the subnet, whenever possible." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                <div class="flex-custom no-wrap">
                    <div class="checkbox-cs">
                        <input type="radio" name="publicIP"  class="form-check-inline" id='no' (change)="setRadioBtn('no','networkingNIC','publicIP')" [checked]="popUpForm.get('publicIP')?.value ==false">
                        <label class="value" for="no">Disabled</label>
                    </div>
                </div>
                <div class="flex-custom no-wrap">
                    <div class="checkbox-cs">
                        <input type="radio" name="publicIP" class="form-check-inline" id='yes' (change)="setRadioBtn('yes','networkingNIC','publicIP')" [checked]="popUpForm.get('publicIP')?.value ==true">
                        <label class="value" for="yes">Enabled</label>
                    </div>
                </div>
              </div>
              <div class="comm-mb">
                <label class="flex-custom no-wrap">Accelerated networking</label>
                <div class="flex-custom no-wrap">
                  <div class="checkbox-cs">
                      <input type="radio" name="acceleratedNetworking"  class="form-check-inline" id='disable' (change)="setRadioBtn('no','networkingNIC','acceleratedNetworking')" checked disabled>
                      <label class="value" for="disable">Disabled</label>
                  </div>
              </div>
              <div class="flex-custom no-wrap">
                  <div class="checkbox-cs">
                      <input type="radio" name="acceleratedNetworking" class="form-check-inline" id='enable' (change)="setRadioBtn('yes','networkingNIC','acceleratedNetworking')" disabled>
                      <label class="value" for="enable">Enabled</label>
                  </div>
              </div>
              </div>
              </form>
          </div>
  
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary disabled" [class.disabled]="popUpForm.invalid || invalidForm('addNIC') " (click)="addNICtoVM()">Save</button>
          </div>
        </div>
      </ng-container>
  



</ng-container>

<ng-template #vmScaleSetDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">VM Scale Set</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" *ngIf="!disableCondition" [class.disabled-icon]="disableCondition||disableStart" (click)="action(selectedRow,'startVMScaleSet')">Start</div>
            <div class="tab-item" *ngIf="!disableCondition" [class.disabled-icon]="disableCondition||!disableStart" (click)="action(selectedRow,'restartVMScaleSet')">Restart</div>
            <div class="tab-item" *ngIf="!disableCondition" [class.disabled-icon]="disableCondition||!disableStart" (click)="action(selectedRow,'stopVMScaleSet')">Stop</div>
            <div class="tab-item" (click)="openSidesheet('instance',selectedRow)">Instances</div>
            <div class="tab-item" (click)="openSidesheet('networking',selectedRow)">Networking</div>
            <div class="tab-item" (click)="openSidesheet('scaling',selectedRow)">Scaling</div>
            <div class="tab-item" (click)="openSidesheet('size',selectedRow)">Size</div>
            <div class="tab-item" (click)="openSidesheet('configuration',selectedRow)">Configuration</div>
            <div class="tab-item" (click)="openSidesheet('disk',selectedRow)">Disk</div>
            <div class="tab-item" (click)="openSidesheet('upgradePolicy',selectedRow)">Upgrade Policy</div>
            <div class="tab-item" (click)="openSidesheet('healthAndRepair',selectedRow)">Health and Repair</div>
            <div class="tab-item" (click)="action(selectedRow,'deleteVMScaleSetData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedRow;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedRow[i]:getTags(selectedRow[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedRow[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedRow[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedRow[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #vmScaleSetConfirm>
    <div class="medium-popup-wrapper"  style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-btn-primary" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #addSecurityRulesPopup>
    <div class="medium-popup-wrapper2 width-478">
      <div class="modal-header">
        <div class="d-flex">
            <div class="heading-content"><h3>{{getSecurityRulesHeader()}}</h3></div>
            <button class="cursor-pointer float-right" (click)="closeModal();popUpForm.reset()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="popUpForm">
          <div class="comm-mb">
            <label class="flex-custom nowrap">Source<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="It specifies the incoming traffic from a specific source IP address range that will be allowed or denied by this rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <ng-select [items]="vmSourceList" bindLabel="name" bindValue="name" name="source" [clearable]="false" id="source" formControlName="source"></ng-select>
          </div>
          
           <div class="comm-mb" *ngIf="popUpForm.get('source').value=='IP addresses'">
                <label class="flex-custom nowrap">Source IP addresses/CIDR ranges<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address (e.g. 192.168.99.0). You can also provide a comma-separated list of IP addresses or address ranges using IPv4." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                <input class="form-control full-width" type="text" name="source" [clearable]="false" id="source" formControlName="sourceIP" [class.border-danger]="inputError?.sourceInvalidArgument||inputError?.sourceNotValidCIDR||inputError?.sourceOverlaping||inputError?.sourceNonNullRange"  autocomplete="off" placeholder="10.0.0.0/24"  (keyup)="validNICAddressSpace($event,'source')" >
                <p class="text-danger" *ngIf="inputError?.sourceInvalidArgument">Invalid argument: 'Malformed IP address</p>
                <p class="text-danger" *ngIf="inputError?.sourceNotValidCIDR">Invalid CIDR Block.</p>
                <p class="text-danger" *ngIf="inputError?.sourceOverlaping">Overlaps address space</p>
                <p class="text-danger" *ngIf="inputError?.sourceNonNullRange">Invalid argument: A non-null address range is required.</p>
          </div> 
          <div class="comm-mb">
            <label class="flex-custom nowrap">Source port ranges<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a single port, such as 80; a port range, such as 1024-65535; or a comma-separated list of single ports and/or port ranges, such as 80,1024-65535. This specifies on which ports traffic will be allowed or denied by this rule. Provide an asterisk (*) to allow traffic on any port." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width" type="text" autocomplete="off" id="sourcePortRange" name="sourcePortRange" formControlName="sourcePort" [class.border-danger]="inputError?.sourceNotValidPortVal||inputError?.sourceInvalidPortRange||inputError?.sourceOverlap" (keyup)="portRangePopUpValidation($event,65535,'source')" />
              <p class="text-danger" *ngIf="inputError?.sourceNotValidPortVal">The port must be a number between 0 and 65535.</p>
            <p class="text-danger" *ngIf="inputError?.sourceInvalidPortRange">Invalid port range specified.</p>
            <p class="text-danger" *ngIf="inputError?.sourceOverlap">Overlapping ports are not permitted</p>
        </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Destination<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="It specifies the outgoing traffic for a specific destination IP address range that will be allowed or denied by this rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <ng-select [items]="destinationList" bindLabel="name" bindValue="name" name="destination" [clearable]="false" id="destination" formControlName="destination"></ng-select>
          </div>
          <div class="comm-mb" *ngIf="popUpForm.get('destination').value=='IP addresses'">
            <label class="flex-custom nowrap">Destination IP addresses/CIDR ranges<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address (e.g. 192.168.99.0). You can also provide a comma-separated list of IP addresses or address ranges using IPv4." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width" type="text" name="dest" [clearable]="false" id="dest" formControlName="destinationIP" [class.border-danger]="inputError?.destInvalidArgument||inputError?.destNotValidCIDR||inputError?.destOverlaping||inputError?.destNonNullRange"  autocomplete="off" placeholder="10.0.0.0/24"  (keyup)="validNICAddressSpace($event,'dest')" >
            <p class="text-danger" *ngIf="inputError?.destInvalidArgument">Invalid argument: 'Malformed IP address</p>
            <p class="text-danger" *ngIf="inputError?.destNotValidCIDR">Invalid CIDR Block.</p>
            <p class="text-danger" *ngIf="inputError?.destOverlaping">Overlaps address space</p>
            <p class="text-danger" *ngIf="inputError?.destNonNullRange">Invalid argument: A non-null address range is required.</p>
        </div> 
          <div class="comm-mb">
            <label class="flex-custom nowrap">Service<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The destination filter can be Any, an IP address range, an application security group, or a default tag. It specifies the outgoing traffic for a specific destination IP address range that will be allowed or denied by this rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <ng-select [items]="vmServiceList" bindLabel="name" bindValue="name" name="service" [clearable]="false" id="service" formControlName="service" (change)="changeVmService()"></ng-select>
          </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Destination port ranges<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide a single port, such as 80; a port range, such as 1024-65535; or a comma-separated list of single ports and/or port ranges, such as 80,1024-65535. This specifies on which ports traffic will be allowed or denied by this rule. Provide an asterisk (*) to allow traffic on any port." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width" type="text" autocomplete="off" id="destinationPortRange" name="destinationPortRange" formControlName="destinationPort" [class.border-danger]="inputError?.destNotValidPortVal||inputError?.destInvalidPortRange||inputError?.destOverlap" (keyup)="portRangePopUpValidation($event,65535,'dest')"/>
            <p class="text-danger" *ngIf="inputError?.destNotValidPortVal">The port must be a number between 0 and 65535.</p>
            <p class="text-danger" *ngIf="inputError?.destInvalidPortRange">Invalid port range specified.</p>
            <p class="text-danger" *ngIf="inputError?.destOverlap">Overlapping ports are not permitted</p>
        </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Protocol</label>
              <div class="checkbox-cs">
                <input type="radio" name="protocol" class="form-check-inline" id='any' (change)="setProtocol('*')" [checked]="popUpForm.get('protocol')?.value == '*'">
                <label class="value" for="any">Any</label>
              </div>
              <div class="checkbox-cs">
                <input type="radio" name="protocol"  class="form-check-inline" id='tcp' (change)="setProtocol('TCP')" [checked]="popUpForm.get('protocol')?.value == 'TCP'">
                <label class="value" for="tcp">TCP</label>
              </div>
              <div class="checkbox-cs">
                <input type="radio" name="protocol"  class="form-check-inline" id='udp' (change)="setProtocol('UDP')" [checked]="popUpForm.get('protocol')?.value == 'UDP'">
                <label class="value" for="udp">UDP</label>
              </div>
              <div class="checkbox-cs">
                <input type="radio" name="protocol"  class="form-check-inline" id='cmp' (change)="setProtocol('ICMP')" [checked]="popUpForm.get('protocol')?.value == 'ICMP'">
                <label class="value" for="cmp">ICMP</label>
              </div>
          </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Action</label>
              <div class="checkbox-cs">
                <input type="radio" name="action" class="form-check-inline" id='allow' (change)="setAction('Allow')" [checked]="popUpForm.get('action')?.value == 'Allow'">
                <label class="value" for="allow">Allow</label>
              </div>
              <div class="checkbox-cs">
                <input type="radio" name="action"  class="form-check-inline" id='deny' (change)="setAction('Deny')" [checked]="popUpForm.get('action')?.value == 'Deny'">
                <label class="value" for="deny">Deny</label>
              </div>
          </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Priority<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Rules are processed in priority order; the lower the number, the higher the priority. We recommend leaving gaps between rules - 100, 200, 300, etc. - so that it's easier to add new rules without having to edit existing rules." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
            <input class="form-control full-width" type="text" autocomplete="off" id="priority" name="priority" formControlName="priority" [class.border-danger]="inputError?.VMPriorityValid||inputError?.VMPriorityMaxMin||inputError?.VMPriorityDupCheck" (keyup)="validateVMPriority($event)"/>
            <p class="text-danger" *ngIf="inputError?.VMPriorityValid">Value must be a valid number.</p>
            <p class="text-danger" *ngIf="inputError?.VMPriorityMaxMin">The priority must be between 100 and 4096.</p>
            <p class="text-danger" *ngIf="inputError?.VMPriorityDupCheck">The priority you entered matches another security rule within this network security group. Priorities must be unique within existing security rules. </p>
        </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Name<span class="mandatory">*</span></label>
            <input class="form-control full-width" type="text" autocomplete="off" id="name" name="name" formControlName="name" [class.disabled]="isSecurityRulesEdit" [class.border-danger]="inputError?.nameLen||inputError?.nameValid ||inputError?.nameDupCheck" (keyup)="validateInOutBoundName($event,80)"/>
            <p class="text-danger" *ngIf="inputError?.nameLen">The name cannot be longer than 80 characters.</p>
            <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
            <p class="text-danger" *ngIf="inputError?.nameDupCheck">A rule with this name already exists within the network security group. </p>
        </div>
          <div class="comm-mb">
            <label class="flex-custom nowrap">Description</label>
            <textarea name="comment" class="full-width form-control" formControlName="description" [class.border-danger]="inputError?.VMdescription" (keyup)="VmDescription($event,140)"></textarea>
            <p class="text-danger" *ngIf="inputError?.VMdescription">The description cannot be longer than 140 characters.</p>
          </div>
     
        </form>
      </div>
      <div class="modal-footer d-flex">
        <button class="btn btn-btn-primary ml-auto" [class.disabled]="invalidForm('inOutBound')|| popUpForm?.invalid" (click)="saveNSGRuleData('inbound')">Add</button>
      </div>
    </div>
</ng-template>