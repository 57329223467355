<div class="security-recommednation-container">
    <div class="header-container">
      <h2 class="title">Identity Security</h2>
      <!-- <span>{{currentProject?.projectName}}</span> -->
    </div>
    
     <!-- Filter Section -->
     <div class="custom-filter-wrapper flex-custom">
        <div class="custom-filter">
            <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
         
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                        <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                        <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                    </div>
                    <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group<div class="filter-notify" *ngIf="checkFilterApplied('resourceGroup')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceGroupList')"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                        <input type="checkbox" class="form-check-inline" id="rg_listItem_{{i}}"  name="rg_listItem_{{i}}" (change)="triggerFilter(data,'resourceGroup',$event)" [checked]="data.checked">
                        <label class="value" for="rg_listItem_{{i}}" id="rg_listItemName_{{i}}">{{data?.resourceGroupName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource<div class="filter-notify" *ngIf="checkFilterApplied('resource')"></div></span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'resourceList')"  placeholder="Search resource"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="filter-content">
                    <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceList; index as i">
                        <input type="checkbox" class="form-check-inline" id="r_listItem_{{i}}"  name="r_listItem_{{i}}" (change)="triggerFilter(data,'resource',$event)" [checked]="data.checked">
                        <label class="value" for="r_listItem_{{i}}" id="r_listItemName_{{i}}">{{data?.resourceName}}</label>
                    </div>
                    <div  class="mt-3" *ngIf="!filterObject?.resourceList?.length">
                        <p  class="content-14 blur-text">No filter found</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="clear-filter-btn" (click)="clearAllFilter()" *ngIf="checkFilterApplied('all')">
            <img src="/assets/common-icons/close-filter.svg" alt="">
            Clear Filter
        </button>
    </div>

    <div class="dashboard-body">
        <div class="row">
            <div class="col-md-4 padding-div left">
              <div class="graph-div count-card-height mt-2">
                  <div class="heading">
                      <div class="d-flex">
                          <div class="about">
                              <h4>Top 4 Processes Attempted 
                                Logon using Explicit Credentials</h4>
                              <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.processAttemptedCard)}} . {{getTimeFilterVal(processAttemptedCard)}}</p>
                          </div>
                          <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('processAttemptedCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter20')"><img src="/assets/security/clock.svg" alt=""></span>
                                <div class="cal-box"  id="customFilter20">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <ng-container *ngFor="let row of timeFilterList">
                                            <div class="select-item" [class.active]="processAttemptedCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,processAttemptedCard):applyFilter(row.id,processAttemptedCard)">{{row.name}}</div>
                                        </ng-container>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                      </div>
                  </div>
                  <div class="row m-0 mt-3" *ngIf="processAttemptedCard?.tableData?.length && !processAttemptedCard?.showLoader">
                    <ng-container *ngFor="let item of processAttemptedCard?.tableData">
                        <div class="detail-top" [ngClass]="{'col-sm-6':processAttemptedCard?.tableData.length>1,'col-sm-12':processAttemptedCard?.tableData.length<2}">
                            <div class="detail-card processes-attamepted-card">
                                <span class="card-name" [title]="item[0]">{{item[0]}}</span>
                                <span class="card-count">{{item[2]}}</span>
                            </div>
                        </div>
                    </ng-container>
                  </div>
                  <div class="loader-gif loader-for-card" *ngIf="processAttemptedCard?.showLoader">
                      <img src="assets/icons/blue-loader.gif"/>
                      <p>Getting data...</p>
                  </div>
                  <div class="dash-text-center count-card-text card-not-found-case" *ngIf="(!processAttemptedCard?.tableData?.length && !processAttemptedCard?.showLoader)">
                      <img src="/assets/security/check-not-found.svg" alt="">
                      <p class="text-14-22 mt-3">No processes attamepted logon detected.</p>
                  </div>
              </div>
          </div>
            <div class="col-md-4 padding-div pl-4 middle">
              <div class="graph-div count-card-height mt-2">
                  <div class="heading">
                      <div class="d-flex">
                          <div class="about">
                              <h4>Top 4  Accounts with Failed Logon</h4>
                              <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.failedLogonCard)}} . {{getTimeFilterVal(failedLogonCard)}}</p>
                          </div>
                          <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('failedLogonCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter21')"><img src="/assets/security/clock.svg" alt=""></span>
                                <div class="cal-box"  id="customFilter21">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <ng-container *ngFor="let row of timeFilterList">
                                            <div class="select-item" [class.active]="failedLogonCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,failedLogonCard):applyFilter(row.id,failedLogonCard)">{{row.name}}</div>
                                        </ng-container>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                      </div>
                  </div>
                  <div class="row m-0 mt-3" *ngIf="failedLogonCard?.tableData?.length && !failedLogonCard?.showLoader">
                    <ng-container *ngFor="let item of failedLogonCard?.tableData">
                        <div class="detail-top" [ngClass]="{'col-sm-6':failedLogonCard?.tableData.length>1,'col-sm-12':failedLogonCard?.tableData.length<2}">
                            <div class="detail-card mutliple-password-card">
                                <span class="card-name" [title]="item[0]">{{item[0]}}</span>
                                <span class="card-count">{{item[3]}}</span>
                            </div>
                        </div>
                    </ng-container>
                  </div>
                  <div class="loader-gif loader-for-card" *ngIf="failedLogonCard?.showLoader">
                      <img src="assets/icons/blue-loader.gif"/>
                      <p>Getting data...</p>
                  </div>
                  <div class="dash-text-center count-card-text card-not-found-case" *ngIf="(!failedLogonCard?.tableData?.length && !failedLogonCard?.showLoader)">
                      <img src="/assets/security/check-not-found.svg" alt="">
                      <p class="text-14-22 mt-3">No account with failed logon detected.</p>
                  </div>
              </div>
          </div>
            <div class="col-md-4 pl-4 right">
              <div class="graph-div count-card-height mt-2">
                  <div class="heading">
                      <div class="d-flex">
                          <div class="about">
                              <h4>Top 4 Multiple Password Reset</h4>
                              <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.multiplePasswordResetCard)}} . {{getTimeFilterVal(multiplePasswordResetCard)}}</p>
                          </div>
                          <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('multiplePasswordResetCard')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter9')"><img src="/assets/security/clock.svg" alt=""></span>
                                <div class="cal-box"  id="customFilter9">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <ng-container *ngFor="let row of timeFilterList">
                                            <div class="select-item" [class.active]="multiplePasswordResetCard?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,multiplePasswordResetCard):applyFilter(row.id,multiplePasswordResetCard)">{{row.name}}</div>
                                        </ng-container>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                      </div>
                  </div>
                  <div class="row m-0 mt-3" *ngIf="multiplePasswordResetCard?.tableData?.length && !multiplePasswordResetCard?.showLoader">
                    <ng-container *ngFor="let item of multiplePasswordResetCard?.tableData">
                        <div class="detail-top" [ngClass]="{'col-sm-6':multiplePasswordResetCard?.tableData.length>1,'col-sm-12':multiplePasswordResetCard?.tableData.length<2}">
                            <div class="detail-card ssh-card">
                                <span class="card-name" [title]="item[0]">{{item[0]}}</span>
                                <span class="card-count">{{item[1]}}</span>
                            </div>
                        </div>
                    </ng-container>
                  </div>
                  <div class="loader-gif loader-for-card" *ngIf="multiplePasswordResetCard?.showLoader||multiplePasswordResetCard?.showLoader">
                      <img src="assets/icons/blue-loader.gif"/>
                      <p>Getting data...</p>
                  </div>
                  <div class="dash-text-center count-card-text card-not-found-case" *ngIf="(!multiplePasswordResetCard?.tableData?.length && !multiplePasswordResetCard?.showLoader)">
                      <img src="/assets/security/check-not-found.svg" alt="">
                      <p class="text-14-22 mt-3">No multiple password reset detected.</p>
                  </div>
              </div>
          </div>
          </div>
      <div class="row">
        <div class="col-md-6 padding-div left">
            <div class="graph-div">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Processes Attempted Logon using Explicit Credentials</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.processUsedData)}} . {{getTimeFilterVal(processUsedData)}}</p>
                      </div> 
                      <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('processUsedData')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(processUsedDataPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',processUsedData)">Last 30 minutes</div>
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',processUsedData)">Last hour</div>
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',processUsedData)">Last 4 hours</div>
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',processUsedData)">Last 12 hours</div>
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',processUsedData)">Last 24 hours</div>
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',processUsedData)">Last 48 hours</div>
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',processUsedData)">Last 3 days</div>
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',processUsedData)">Last 7 days</div>
                                    <div class="select-item" [class.active]="processUsedData?.currentFilter?.type == 'custom'" (click)="openModal(openCal,processUsedData)">Custom</div>
                                </div>
                                </div>
                            </div>
                        </div>
                      </div>                  
                  </div>                
              </div>
              <app-doughnut-chart [data]='processUsedData' *ngIf="processUsedData?.data?.length && !processUsedData?.showLoader"></app-doughnut-chart>
              <div class="loader-gif" *ngIf="processUsedData?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="!processUsedData?.data?.length && !processUsedData?.showLoader">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No processes attamepted logon found in the selected timeframe.</p>
              </div>
          </div>
          </div>
        <div class="col-md-6 pl-4 right">
          <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Remotely Logged Accounts</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.remotedLoggedAcc)}} . Top 10 . {{getTimeFilterVal(remotedLoggedAccOption)}}</p>
                    </div> 
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('remotedLoggedAcc')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(remotedLoggedAccPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter1">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="remotedLoggedAccOption?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,remotedLoggedAccOption):applyFilter(row.id,remotedLoggedAccOption)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>                
            </div>
            <app-horizontal-bar-chart [data]='remotedLoggedAccOption' *ngIf="remotedLoggedAccOption.data?.length&&!remotedLoggedAccOption?.showLoader"></app-horizontal-bar-chart>
            <div class="loader-gif" *ngIf="remotedLoggedAccOption?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!remotedLoggedAccOption?.data?.length && !remotedLoggedAccOption?.showLoader">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No remotely logged accounts found in the selected timeframe.</p>
            </div>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 padding-div left">
            <div class="graph-div">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>Accounts With Failed Logons</h4>
                          <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.accountWithFailed)}} . Top 10 . {{getTimeFilterVal(accountWithFailedOption)}}</p>
                      </div> 
                      <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('accountWithFailed')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(accountWithFailedPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                          <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter3')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter3">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="accountWithFailedOption?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,accountWithFailedOption):applyFilter(row.id,accountWithFailedOption)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                      </div>                  
                  </div>                
              </div>
              <!-- <app-doughnut-chart [data]='coverageResourceData' *ngIf="coverageResourceData?.data?.length && !coverageResourceData?.showLoader"></app-doughnut-chart> -->
              <app-horizontal-bar-chart [data]='accountWithFailedOption' *ngIf="accountWithFailedOption.data?.length&&!accountWithFailedOption?.showLoader"></app-horizontal-bar-chart>
              <div class="loader-gif" *ngIf="accountWithFailedOption?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="!accountWithFailedOption.data?.length && !accountWithFailedOption?.showLoader">
                  <img src="/assets/security/check-not-found.svg" alt="">
                  <p class="text-14-22 bold-600 mt-3">No accounts with failed logo found in the selected timeframe.</p>
              </div>
          </div>
          </div>
        <div class="col-md-6 pl-4 right">
        <div class="graph-div">
            <div class="heading">
                <div class="d-flex">
                    <div class="about">
                        <h4>Failed Login Attempts by the Same Account</h4>
                        <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.failedLoginAttempts)}} . Top 10 . {{getTimeFilterVal(failedLoginAttemptsOption)}}</p>
                    </div> 
                    <div class="float-right flex-custom no-wrap">
                        <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('failedLoginAttempts')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(failedLoginAttemptsPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                        <div class="cal-wrap">
                            <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                            matTooltipShowDelay="500" (click)="openFilter('customFilter4')"><img src="/assets/security/clock.svg" alt=""></span>
                            <div class="cal-box"  id="customFilter4">
                                <div class="cal-inner">
                                <div class="select-sec">
                                    <ng-container *ngFor="let row of timeFilterList">
                                        <div class="select-item" [class.active]="failedLoginAttemptsOption?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,failedLoginAttemptsOption):applyFilter(row.id,failedLoginAttemptsOption)">{{row.name}}</div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>                
            </div>
            <app-horizontal-bar-chart [data]='failedLoginAttemptsOption' *ngIf="failedLoginAttemptsOption.data?.length&&!failedLoginAttemptsOption?.showLoader"></app-horizontal-bar-chart>
            <div class="loader-gif" *ngIf="failedLoginAttemptsOption?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!failedLoginAttemptsOption.data?.length && !failedLoginAttemptsOption?.showLoader">
                <img src="/assets/security/check-not-found.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No failed login attempts by the same account found in the selected timeframe.</p>
            </div>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 right">
            <div class="graph-div card-height mb-4">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Multiple Password Reset<img class="tooltip-icon" src="/assets/Infra/info.svg" alt=""
                              matTooltip="Shows failed and successful password reset attempts on the Azure AD user."
                              matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime?.multiplePasswordReset)}} . {{getTimeFilterVal(multiplePasswordReset)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('multiplePasswordReset')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <span class="action-icon download-icon-security" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView('MultiplePasswordReset','tableMultiple')"><img src="assets/security/download_icon.svg" alt=""></span>
                          <div class="cal-wrap">
                              <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                              matTooltipShowDelay="500" (click)="openFilter('customFilter6')"><img src="/assets/security/clock.svg" alt=""></span>
                              <div class="cal-box"  id="customFilter6">
                                  <div class="cal-inner">
                                  <div class="select-sec">
                                      <ng-container *ngFor="let row of timeFilterList">
                                          <div class="select-item" [class.active]="multiplePasswordReset?.currentFilter?.type ==row.id" (click)="row.id=='custom'?openModal(openCal,multiplePasswordReset):applyFilter(row.id,multiplePasswordReset)">{{row.name}}</div>
                                      </ng-container>
                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="multiplePasswordReset?.tableData?.length && !multiplePasswordReset?.showLoader">
                  <table class="table" id="tableMultiple">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>User</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',8)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Email</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',0)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Count of Password Resets</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>First Reset Time (UTC)</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',2)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Last Reset Time (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('multiplePasswordReset',3)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let row of multiplePasswordReset.tableData" class="cursor-pointer">
                          <ng-container (click)="openPopupCustom('multiplePasswordReset',row,multiplePasswordPopup)">
                              <td (click)="openPopupCustom('multiplePasswordReset',row,multiplePasswordPopup)">{{row[8]}}</td>
                              <td (click)="openPopupCustom('multiplePasswordReset',row,multiplePasswordPopup)">{{row[0]}}</td>
                              <td (click)="openPopupCustom('multiplePasswordReset',row,multiplePasswordPopup)">{{row[1]}}</td>
                              <td (click)="openPopupCustom('multiplePasswordReset',row,multiplePasswordPopup)">{{row[2]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                              <td (click)="openPopupCustom('multiplePasswordReset',row,multiplePasswordPopup)">{{row[3]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                          </ng-container>
                        </tr>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif custom-loader-gif" *ngIf="multiplePasswordReset?.showLoader||multiplePasswordReset?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!multiplePasswordReset?.tableData?.length && !multiplePasswordReset?.showLoader)">
                    <img src="/assets/security/check-not-found.svg" alt="">
                    <p class="text-16-26 mt-3">No password reset attempts were made 
                      in the selected timeframe.</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #multiplePasswordPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Multiple Password Reset</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row multiple-password-poup">
                <div class="col-sm-3">
                    <span class="title-val">User</span>
                    <span class="title-details">{{detailsPop[8]}}</span>
                </div>
                <div class="col-sm-3">
                    <span class="title-val">Email</span>
                    <span class="title-details">{{detailsPop[0]}}</span>
                </div>
                <div class="col-sm-6">
                    <span class="title-val">Count of Password Reset</span>
                    <span class="title-details">{{detailsPop[1]}}</span>
                </div>
            </div>
            <div class="custom-fixed-tables">
                <table class="table" #table1>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Initiated By</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'initiatedBy')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time Generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'timeGenerated')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Result</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'result')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Message</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortPopup('detailsPop',9,'message')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of detailsPop[9]">
                          <td>{{row.initiatedBy}}</td>
                          <td>{{row.timeGenerated|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                          <td>
                            <div class="chip" [ngClass]="getStatusClass(row?.result)">
                                {{row?.result | titlecase }}
                              </div>
                          </td>
                          <td>{{row.message}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!detailsPop[9]?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFileCustom('MPRPopup')" *ngIf="detailsPop[9]?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #remotedLoggedAccPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Remotely Logged Accounts</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table1>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Account</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('remotedLoggedAccOption',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('remotedLoggedAccOption',1)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count of Remote Logon</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('remotedLoggedAccOption',2)">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of remotedLoggedAccOption.tableData">
                          <td>{{filterData(row[0])}}</td>
                          <td>{{row[1].split('.')[0]}}</td>
                          <td>{{row[3]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!remotedLoggedAccOption?.tableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Remotely Logged Accounts',table1)" *ngIf="remotedLoggedAccOption.tableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #accountWithFailedPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Accounts With Failed Logons</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table2>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Account</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('accountWithFailedOption',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('accountWithFailedOption',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Count of Failed Logins</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('accountWithFailedOption',2)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Latest failed login (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('accountWithFailedOption',3)">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of accountWithFailedOption.tableData">
                        <td [title]="row[0]">{{row[0]}}</td>
                        <td>{{row[1]}}</td>
                        <td>{{row[3]}}</td>
                        <td>{{row[4]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!accountWithFailedOption.tableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Accounts With Failed Logons',table2)" *ngIf="accountWithFailedOption.tableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #failedLoginAttemptsPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Failed Login Attempts by the Same Account</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table3>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Account</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedLoginAttemptsOption',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedLoginAttemptsOption',1)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Count of Failed Attempts</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedLoginAttemptsOption',4)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>IP count</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedLoginAttemptsOption',2)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>IP addresses</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedLoginAttemptsOption',3)">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of failedLoginAttemptsOption.tableData">
                        <td [title]="row[0]">{{row[0]}}</td>
                        <td>{{row[1]}}</td>
                        <td>{{row[5]}}</td>
                        <td>{{row[3]}}</td>
                        <td [title]="getJsonData(row[4])">{{getJsonData(row[4])}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!failedLoginAttemptsOption.tableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Failed Login Attempts by the Same Account',table3)" *ngIf="failedLoginAttemptsOption.tableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #processUsedDataPopup>
    <div class="medium-popup-wrapper2 width-1120">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Processes Attempted Logon using Explicit Credentials</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table4>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processUsedData',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processUsedData',1)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count of Logins</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processUsedData',2)">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of processUsedData.tableData">
                          <td>{{row[0]}}</td>
                          <td>{{row[1]}}</td>
                          <td>{{row[3]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!processUsedData.tableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Processes Attempted Logon using Explicit Credentials',table4)" *ngIf="processUsedData.tableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>
  <ng-template #processStartedDataPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Top 5 Most Running Processes</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table" #table5>
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processStartedData',0)">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Process</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processStartedData',1)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Computer</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processStartedData',2)">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Count</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('processStartedData',3)">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of processStartedData.tableData">
                          <td>{{row[0]|date:'MM/dd/yyyy, hh:mm:ss a'}}</td>
                          <td>{{row[1]}}</td>
                          <td>{{row[2]}}</td>
                          <td>{{row[4]}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!processStartedData.tableData?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('Top 5 Most Running Processes',table5)" *ngIf="processStartedData.tableData?.length"><img src="assets/security/download.svg" alt="">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
  </ng-template>

  <ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>