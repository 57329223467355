<!-- Usermanual-sidebar -->
<div class="usermanual-fixed-sidebar">
   <div class="searchbox">
      <img src="assets/icons/search.png">
      <input type="text"
      [(ngModel)]="searchText"  placeholder="Search"  class="manualsearch" autocomplete="off" 
      (keyup)="searchManual($event)">
     
   </div>
   
   <ul class="sidebar-expansion-menu"  id="foo" >
  
      <ng-container *ngFor = "let sidebar of sidebarContent " >
         <li class="sideMenu" >
            <div class="sideMenuActive" 
             [class.active-header]='activeSidebar == sidebar?.activeSidebar'
             [class.drop]="allowDrop(sidebar?.activeSidebar)"
              (click)="changeTab('parent',sidebar)"
              (click)="subMenuOpen(sidebar?.activeSidebar)"
              (click)="scrollToPosition(sidebar?.activeSidebar)"
              id="{{sidebar?.activeSidebar}}">

              <div class="sidebarName"  >{{sidebar?.value}}</div>
            </div>
            

             
            <ul class="subMenu"  >
               <ng-container *ngFor = "let childComponent of sidebar?.child">
                  <li class="subMenu-list">
                     <div  class="sideMenu-inner"  [class.active-header]='activeSidebar == childComponent?.activeSidebar'
                     [class.sub-drop]="allowSubDrop(childComponent?.activeSidebar)"
                     (click)="changeTab('child',sidebar,childComponent)"
                     (click)="scrollToPosition(childComponent?.activeSidebar)" 
                     (click)="subMenuOpen(childComponent?.activeSidebar)"
                     id="{{childComponent?.activeSidebar}}">
                        <div class="subMenuName" >{{childComponent?.value}}</div>
                     </div>
                     <ul class="subMenu"  >
                        <ng-container *ngFor = "let childSubComponent of childComponent?.subChild">
                           <li class="subMenu-list">
                              <div  class="sideSubMenu-inner" 
                               [class.active-header]='activeSidebar == childSubComponent?.activeSidebar'
                               (click)="changeTab('subChild',sidebar,childComponent,childSubComponent)"
                               (click)="scrollToPosition(childSubComponent?.activeSidebar)"  >
                                 <div class="subMenuName" >{{childSubComponent?.value}}</div>
                              </div>
                              <!-- (click)="changeTab(childComponent?.activeSidebar)"
                              -->
         
                           </li>
                        </ng-container>
                
                     </ul>

                  </li>
               </ng-container>
       
            </ul>
         
         </li>
      </ng-container>
   </ul>

</div>
<div class="search-expand">
   <div class="search-result" *ngFor="let list of searchContent">
       <span class="list" (click)="changeTab('parent',list)"
       (click)="scrollToPosition(list?.activeSidebar);sidebarScroll(list?.id)">{{list?.value}}</span> 
       <ng-container *ngFor="let subList of list?.child">
         <div class="subList"  (click)="changeTab('child',list,subList);sidebarScroll(subList?.id)"
         (click)="scrollToPosition(subList?.activeSidebar)">{{subList?.value}}</div>
         <p class="subSubList" *ngFor="let subSubList of subList?.subChild" (click)="changeTab('subChild',list,subList,subSubList)"
         (click)="scrollToPosition(subSubList?.activeSidebar);sidebarScroll(subSubList?.id)">{{subSubList?.value}}</p>
       </ng-container>
     
   </div>

   <div class="search-result" *ngIf="!searchContent?.length">
      <span class="noresult">No results found</span> 
  </div>

</div>
