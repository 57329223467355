import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface onsubmit {
    UserName: string,
    Email: string,
    Token: string,
    Id: string
    UserObjectId: string
}
interface subscription {
    subscription: string
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token')
    })
};

const httpOptions1 = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
    })
};

@Injectable({
    providedIn: 'root'
})

export class LoginService {
    constructor(private router: Router, private http: HttpClient) { }
    subsID: any;
    // url = "https://a3s-backend-node.azurewebsites.net/";
    // url = "http://localhost:3000/"

    url = environment.serverUrl
    loginUser(info) {
        // console.log('sendingto the API', info)
        return this.http.post<any>(this.url + 'api/login',
            info
        )
    }

    getsubscriptions(subsinfo) {
        // console.log('now sending for subs request -->',subsinfo)
        return this.http.post<subscription>('https://usermodulefunc.azurewebsites.net/api/Utility?code=rHJ9OXWkA/EI1A0Lf8P20qI5xe1Cpvuoo/czA6Gv9EfzgFk5sAlBNA==', subsinfo
        )
    }

    getresourcegroups(current_sub) {
        return this.http.get<any>('https://management.azure.com/subscriptions/' + current_sub + '/resourcegroups?api-version=2019-10-01', httpOptions)
    }

    getToken(payload) {
        // console.log('payload');
        console.log(this.http.post<any>('https://login.microsoftonline.com/e4e34038-ea1f-4882-b6e8-ccd776459ca0/oauth2/v2.0/token', payload, httpOptions1));
        return this.http.post<any>('https://login.microsoftonline.com/e4e34038-ea1f-4882-b6e8-ccd776459ca0/oauth2/v2.0/token', payload, httpOptions1)
    }

    getData(value) {
        // console.log(value)
        return this.http.get<any>(this.url + 'api/validate-id', {
            headers: new HttpHeaders({
                'id': value.id,
            })
        });
    }

    getRGData(value) {
        var data = { token: '' };
        // while(data['token']==''){
        for (let i = 0; sessionStorage.key(i); i++) {
            // console.log(sessionStorage.key(i).indexOf('login.windows.net-accesstoken'), sessionStorage.key(i))
            if (sessionStorage.key(i).indexOf('login.windows.net-accesstoken') != -1) {
                data['token'] = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['secret'];
            }
            // else if(JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['username']!=null){
            //   data['userName']=JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['username'];
            //   data['name']=JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['name'];
            // }
            // console.log(sessionStorage.key(i), data['token']);
        }
        // console.log(data);
        sessionStorage.setItem('data', JSON.stringify(data));
        // console.log(value)
        if (JSON.parse(sessionStorage.getItem('data'))['token']) {
            return this.http.get<any>(this.url + 'api/Resources-list', {
                headers: new HttpHeaders({
                    'id': value,
                    'Authorization': JSON.parse(sessionStorage.getItem('data'))['token'],
                })
            });
        }
        else {
            localStorage.clear();
            this.router.navigate([''])
        }
    }
}

