import { Injectable,  Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import {LoginMsalService} from './login-msal.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{
  constructor(private injector : Injector) { 
  }
    
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!req.headers.get('ignoreInterceptor') || req.headers.get('ignoreInterceptor') == 'false') {
      let login = this.injector.get(LoginMsalService);
      // Clone the request to add the new header
      let tenantId = localStorage.getItem('cred') && JSON.parse(localStorage.getItem('cred'))  ? JSON.parse(localStorage.getItem('cred'))['TenantID'] : 'null'
      let a3sId = localStorage.getItem('id')  ? localStorage.getItem('id') : 'null'
      let authorization =sessionStorage.getItem('data') && JSON.parse(sessionStorage.getItem('data'))['token'] ? `Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}` : 'null'
  
      let clonedRequest = req.clone({setHeaders: { 'id': a3sId ,'tenantId': tenantId ,Authorization: authorization}});
      // if(sessionStorage.getItem('data') && JSON.parse(sessionStorage.getItem('data'))['token']){
      //  clonedRequest = req.clone({
      //     setHeaders: { id: localStorage.getItem('id'),Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}` } 
      //  });
      // }
    
      login.getnewToken()
      // Pass the cloned request instead of the original request to the next handle
      return next.handle(clonedRequest);
    }
    else{
      const newRequest = req.clone();
      return next.handle(newRequest);
    }
   
  }
  // intercept(req,next){
   
    
    
  //   let tokenizedReq = 'Done'
  //   return next.handle(tokenizedReq)
  // }

}
