<div class="introduction">
           <div *ngIf="activeSidebar=='HP-Overview'">
            <p class="heading" id="homePage-0">Overview </p>
            <p>Home page shows all the functional buckets of A3S in a manner that is easy to navigate and access the required functionality. </p>
           <p>The interface has the following components:</p>
            <ol class="mb-0" >
                <li>Banner & task bar. </li>   
                <li>Tile for each functional bucket. </li> 
                <li>Recent activities. </li>
                <li>Favorites. </li>
            </ol>
            <img src="assets/user-manual/Home.svg" >
        </div>
        <div *ngIf="activeSidebar=='HP-BTB'">
            <p class="heading" id="homePage-1">Banner & Task Bar </p>
            <span>The task bar cum banner consists of the following (shown in image – task bar): </span>
            <ol class="mb-0" >
                <li>Logo & name.  </li>   
                <li>Search bar.  </li> 
                <li>Chat icon.  </li>
                <li>Notification icon.  </li>
                <li>Knowledge center icon.   </li>
                <li>User icon.  </li>
            </ol>
            <img src="assets/user-manual/Group1.png" >
            <figcaption class="caption">Banner & Task bar</figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-Hamburger'">
            <p class="heading" id="homePage-2">Hamburger Menu </p>
            <p class="mb-0" >Click it to find the list of functional buckets. </p>
            <img src="assets/user-manual/Group1.png" >
            <figcaption class="caption">Banner & Task bar</figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-Logo'">
            <p class="heading" id="homePage-3">Logo & Name   </p>
            <p class="mb-0" >Click it to reach home page from any other page.  </p>
            <img src="assets/user-manual/Group1.png" >
            <figcaption class="caption">Banner & Task bar</figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-Search'">
            <p class="heading" id="homePage-4">Search Bar  </p>
            <p class="mb-0" >Use this to search projects, A3S knowledge base, and Azure documentation.  </p>
            <img src="assets/user-manual/home-page/Home-searchbar.svg"  >
            <figcaption class="caption">Search bar </figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-Chat'">
            <p class="heading" id="homePage-5">Chat  </p>
            <p class="mb-0" >In case of any issues related to A3S or to raise a ticket with support engineer, initiate the chat by clicking this button. </p>
            <img src="assets/user-manual/home-page/Home-chat.svg" >
            <figcaption class="caption">Chat </figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-Notifications'">
            <p class="heading" id="homePage-6">Notifications</p>
            <p class="mb-0" >Click notification icon to view status of the ongoing actions.  </p>
            <img src="assets/user-manual/Group1.png" >
            <figcaption class="caption">Banner & Task bar</figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-KC'">
            <p class="heading" id="homePage-7">Knowledge Center </p>
            <p class="mb-0" >To navigate to the knowledge center page and to read through articles. </p>
            <img src="assets/user-manual/Group1.png" >
            <figcaption class="caption">Banner & Task bar</figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-User'">
            <p class="heading" id="homePage-8">User </p>
            <p class="mb-0" >To view user details such as, username, roles, and responsibilities, etc.   </p>
            <img src="assets/user-manual/Account-Settings.svg" >
            <figcaption class="caption">Account details  </figcaption>
            <p>Important action items on the account details page as shown above:</p>
            <ol>
                <li>Log out. </li>
                <li>Connect Apps. </li>
                <li>Newsletter.  </li>
                <li>Delete organization account.  </li>
            </ol>
        </div>

        <div *ngIf="activeSidebar=='HP-Log-out'">
            <p class="heading" id="homePage-9">Log out</p>
            <p class="mb-0" >It logs you out of the portal.   </p>
            <img src="assets/user-manual/Account-Settings.svg" >
            <figcaption class="caption">Account details  </figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-Connect'">
            <p class="heading" id="homePage-10">Connect Apps </p>
            <ol class="mb-0" >
                <li>Click Connect and Microsoft Teams application will be launched.  </li>
                <li>Sign into Teams and A3S Teams bot will appear. </li>
                <li>Start chatting with the bot to accomplish majority of A3S tasks as well as raise a ticket in case of any issues.   </li>
            </ol>
            <img src="assets/user-manual/Account-Settings.svg" >
            <figcaption class="caption">Account details  </figcaption>
        </div>
        <div *ngIf="activeSidebar=='HP-Newsletter'">
            <p class="heading" id="homePage-11">Newsletter </p>
            <p class="mb-0" >A3S newsletters will contain version release, feature updates, modifications in the functional buckets of the product, blog posts updates, etc.             </p>
            <img src="assets/user-manual/Account-Settings.svg" >
            <figcaption class="caption">Account details  </figcaption>
        </div>

         <div *ngIf="activeSidebar=='HP-Delete'">
            <p class="heading" id="homePage-12">Delete organization account </p>
            <p class="mb-0" >Use it to delete the organization account.   </p>
            <img src="assets/user-manual/Account-Settings.svg" >
            <figcaption class="caption">Account details  </figcaption>
            <b>Note : this option is available to global/organization administrators only.  </b>
        </div>

        <div *ngIf="activeSidebar=='HP-Tiles'">
            <p class="heading" id="homePage-13">Tiles </p>
            <p>To view user details such as, username, roles, and responsibilities, etc.   </p>
            <p>There are eleven buckets in A3S which cover various aspects of the services provided under single umbrella. </p>
            <ol>
                <li>Apps & Infrastructure. </li>
                <li>Data & AI.  </li>
                <li>Infrastructure Health 
                    Monitoring.   </li>
                <li>Application Health Monitoring.   </li>
                <li>Cost Monitoring and Optimization.  </li>
                <li>Governance.  </li>
                <li>Security.  </li>
                <li>Compliance. </li>
                <li>A3S Support.  </li>
                <li>Azure Support.  </li>
                <li>SAP Support.  </li>
            </ol>
            <label class="mb-0" >Each tile navigates to respective bucket’s home/landing page. </label>
            <img src="assets/user-manual/Home.svg">
            <figcaption class="caption"> Home screen tiles </figcaption>
        </div>

        <div *ngIf="activeSidebar=='HP-Recent'">
            <p class="heading" id="homePage-14">Recent Activity  </p>
            <p class="mb-0" >Shows the list of the recent activity performed in the portal by the user.    </p>
            <img src="assets/user-manual/Home.svg">
            <figcaption class="caption"> Home screen tiles </figcaption>
        </div>

        <div *ngIf="activeSidebar=='HP-Favorites'">
            <p class="heading" id="homePage-15">Favorites  </p>
            <p class="mb-0" >Shows the list of frequently executed actions or various dashboards etc. viewed by the user.    </p>
            <img src="assets/user-manual/Home.svg">
            <figcaption class="caption"> Home screen tiles </figcaption>
        </div>

           
</div>
