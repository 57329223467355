export class HealthThreshold {
    public static  webApp ={
        availability :{
            type : 'availability',
            threshold: 100,
            operator: '!=',
            unit: '%'
        },
        serverResponse :{
            type : 'serverResponse',
            threshold: 2000,
            operator: '>',
            unit: 'ms'
        },
        failedRequest :{
            type : 'failedRequest',
            threshold: 10,
            operator: '>',
            unit: 'count'
        },
        cpuUsage :{
            type : 'cpuUsage',
            threshold: 80,
            operator: '>',
            unit: '%'
        },
        memoryAvailable :{
            type : 'memoryAvailable',
            threshold: 100,
            operator: '<',
            unit: 'mb'
        },
    } 
}