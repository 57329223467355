import { Component, HostListener, OnInit } from '@angular/core';
import { UtilService } from '../services/util.service';
import { Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import * as _ from 'lodash';
@Component({
  selector: 'app-infra-deployment-bucket',
  templateUrl: './infra-deployment-bucket.component.html',
  styleUrls: ['./infra-deployment-bucket.component.scss']
})
export class InfraDeploymentBucketComponent implements OnInit {
  infraDeploymentMenu: any = [];
  liId: '#demo';
  managementSideMenus: any = [];
  currentMenu: any = 'Overview';

  projectList: any = [];
  currentProject: any;
  currentResourceType: any;
  currentProjectData: any;
  subnetData: any;
  masterCurrentProjectData: any;
  currrentTab: any;


  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    var $target = $(event.target);
    this.infraDeploymentMenu.forEach(e=> {
      let element = document.getElementById(e.menu)
      if(!$target.closest(element).length && !$target.closest("#no-access-tooltip").length){
         e.restrictAccess = false
      }
    })
  }

  constructor(private util:UtilService,private router: Router,private titlecasePipe:TitleCasePipe) {
   }

  async ngOnInit(){
    this.infraDeploymentMenu = this.util.getStatic('appsInfraMenus');
    await this.getResourceTypeStaticData()
    this.getBlueprintProject()
  }

  getResourceTypeStaticData(){
    return this.util.handleRequest('get','a3s_static_getByTable',['dbo','resourceTypes'],null,null,null,true).then(res=>{
      if(!res || !res.recordset.length) return;
      this.util.setCache('resourceTypeList',res.recordset)
    })
  }

  triggerMenuClick(menu){
    this.currrentTab = menu;
    let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
    if(data && data.scope.some(x=> x.id == menu.tabId)) menu['restrictAccess'] = false
    else return menu['restrictAccess'] = true;
    this.closeAllSubTabs()
    if(this.allowSubMenus(menu)) {
      this.subMenuOpen(menu.menu)
      this.setCurrentProject(this.projectList[0],0)
    }
  }


  collapseSider() {
    $(".main-content").toggleClass('collapse-sidebar');
  }

  routeToChild(link: any){
    this.util.route([link],false);
  }

  subMenuOpen(id){
    $('#' + id).toggleClass('open').siblings().slideToggle(300);
  }

  allowSubMenus(list){
    return list.value == 'Management'
  }

  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }

  getMenuImage(type){
    switch(type){
      case 'Compute': return 'assets/Infra/mgmt/compute.svg';
      case 'Storage': return 'assets/Infra/mgmt/storage.svg';
      case 'Network': return 'assets/Infra/mgmt/networking.svg';
      case 'Analytics': return 'assets/Infra/mgmt/analytics.svg';
      case 'Web': return 'assets/Infra/mgmt/web.svg';
      case 'Security': return 'assets/Infra/mgmt/security.svg';
      case 'AADDS': return 'assets/Infra/mgmt/compute.svg';
      default: return 'assets/Infra/mgmt/compute.svg'
    }
  }

  setCurrentMenu(menu){
    this.currentMenu = menu
    this.util.setObservable('currentMgtSubMenu',menu)
  }

  checkA3sAccess(){
    let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
    return data && data.scope.some(x=> this.currrentTab ? x.id == this.currrentTab.tabId : false) ? true : false;
  }
  
  /******************************************* Project Functions ************************************/
  async getBlueprintProject(){
    var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
    var createdBy = get_Email.userName;
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){}
    }
    let userProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getAllProjects', [createdBy,userObjectId])
    let orgProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getOrgProjects',[localStorage.getItem('id')])
    let list = [...userProject,...orgProject]
    this.projectList = list.filter(e=> e.subscriptionId && e.status == 'DEPLOYED')
    this.triggerMenuClick(this.infraDeploymentMenu[2])
    console.log('Filter Project List',this.projectList)
  }

  async getProjectResource(id){
    let resource = await this.util.handleRequest('get','a3s_architectureBlueprint_getProjectResourceListForDashboards',[id],null,null,null,true)
    let staticResource = this.util.getStatic('resourceTypeList')
    resource.forEach(e=>{
      let name = staticResource.filter(x=> e.resourceType.replace(/\s/g, '').toLowerCase() == x.resourceType.toLowerCase())
      e.resourceType = name.length ? name[0].rootResourceType : e.resourceType
    })
    return resource;
 }

  async setCurrentProject(list,index){
    list['currentResourceType'] = null
    this.currentResourceType = null;
    if(!list.isManual || (list.isManual && list.isSpecificResource)){
      var resourcesArray = await this.getProjectResource(list.projectId)
      var resourceGroupsArray = []
      resourcesArray.forEach(e=>{
        let rg = JSON.parse(e.resourceData)
        e['resourceGroupName'] = rg.hasOwnProperty('resourceGroup') ? rg.resourceGroup : null
        e['projectId'] = list.projectId
        e['subscriptionId'] = list.subscriptionId
        e['resourceType'] = this.titlecasePipe.transform(this.splitWordByCaps(e.resourceType))
        rg.hasOwnProperty('resourceGroup') ? resourceGroupsArray.push({resourceGroupName:rg.resourceGroup,projectId:list.projectId,resourceType:e.resourceType}) : null
      })
      var uniqueRG = this.util.unique(resourceGroupsArray,['resourceGroupName'])
      list['resourceGroups'] = uniqueRG
      list['allResourceGroups'] = resourceGroupsArray
      list['resources'] = resourcesArray
      // Get Unique Resource Types and the resource counts
      var uniqueResourceType = this.util.unique(resourcesArray,['resourceType'])
      var filteredResourceType = uniqueResourceType.filter(e=> this.filterResourceTypes(e))
      filteredResourceType.forEach(e=>{
        var data = resourcesArray.filter(x=> x.resourceType.toLowerCase() == e['resourceType'].toLowerCase())
        e['count'] = data.length
      })
      list['resourceTypes'] = filteredResourceType // Used in side bar under each project
    }
    else if(list.isManual){
      let resourceData = await this.getAllResources(list)
      let resourcesArray = resourceData
      var resourceGroupsArray = []
      resourcesArray.forEach(e=>{
        e['resourceGroupName'] = e.id.split('/')[4]
        e['projectId'] = list.projectId
        e['subscriptionId'] = list.subscriptionId
        e['resourceType'] = this.titlecasePipe.transform(this.splitWordByCaps(e.resourceType))
        e['resourceName'] = e.resourceName.includes('/')?e.id.split('/')[10]:e.resourceName
        resourceGroupsArray.push({resourceGroupName: e['resourceGroupName'],projectId:list.projectId,resourceType:e.resourceType})
      })
      resourcesArray=resourcesArray.filter(e=>{
        if(e.type=='Microsoft.Sql/servers/databases'){
          return e.resourceName!='master'
        }else return e
      })
      var uniqueRG = this.util.unique(resourceGroupsArray,['resourceGroupName'])
      list['resourceGroups'] = uniqueRG
      list['allResourceGroups'] = resourceGroupsArray
      list['resources'] = resourcesArray
      // Get Unique Resource Types and the resource counts
      var uniqueResourceType = this.util.unique(resourcesArray,['resourceType'])
      let filteredResourceType :any = uniqueResourceType.filter(e=> this.filterResourceTypes(e))
      filteredResourceType.forEach(e=>{
        var data = resourcesArray.filter(x=> x.resourceType.toLowerCase() == e['resourceType'].toLowerCase())
        e['count'] = data.length
      })
      list['resourceTypes'] = filteredResourceType // Used in side bar under each project

    }
   
    if(this.currentProject == list.projectName) {
      this.currentProjectData = list
      this.masterCurrentProjectData = _.cloneDeep(this.currentProjectData) 
      await this.util.delay(1000)
      return this.util.setObservable('currentFilteredProject',list)
    }

    this.currentProject = list.projectName

    this.projectList.forEach((e,i)=>{
      i == index ? '' : $('#types_' + i).siblings().slideUp('fast') // close all sub menus
    })
    this.subMenuOpen('types_'+index) // open sub menus

    this.currentProjectData = list
    this.masterCurrentProjectData = _.cloneDeep(this.currentProjectData) 
    await this.util.delay(1000)
    this.util.setObservable('currentFilteredProject',list)
  }

  async setCurrentResourceType(type){
    this.currentResourceType = type.resourceType
    this.currentProjectData['currentResourceType'] =  await this.getResourceTypeName(type)
    let filterRg  = this.masterCurrentProjectData['allResourceGroups'].filter(e=> e.resourceType == type.resourceType)
    this.currentProjectData['resourceGroups'] = this.util.unique(filterRg,['resourceGroupName'])
    this.currentProjectData['resources'] = this.masterCurrentProjectData['resources'].filter(e=> e.resourceType == type.resourceType)
    this.util.setObservable('currentFilteredProject',this.currentProjectData)
  }

  filterResourceTypes(list){
    let name = list['resourceType'].replace(/\s/g, '').toLowerCase()
    let includedList = ['virtualmachines','virtualnetworks','subnet','subnets','networkinterfaces','networksecuritygroups','publicipaddresses','publicipaddress',
    'loadbalancers','sqlvirtualmachines','virtualnetworkgateways','localnetworkgateways','connections','storageaccounts',
    'virtualmachinescalesets','applicationgateways','sqldatabases','sqlserver','firewallpolicy','firewalls','keyvaults','disks','appserviceplans',
    'appservices','routetables','bastions','loganalyticworkspaces','applicationinsights','machinelearningworkspaces','synapseanalyticsworkspaces',
    'cosmosdb','adlsgen1','appServicecertificates','appServiceenvironments','azurestaticapps','cdnprofiles','workbook','automationaccounts',
    'dnszones','availabilitysets','hostpools','networkwatchers']
    return includedList.indexOf(name) > -1 ? true : false
  }


  async getAllResources(list){
    let resources = await this.util.handleRequest('get','a3s_management_getResourceTypes',[list.subscriptionId],null,null,null,true)
    let types = this.util.getCache('resourceTypeList')
    var array = []
    types.forEach(x=>{
      resources.body.value.forEach(e=>{
        if(x.azureType.toLowerCase() == e.type.toLowerCase()){
          e['resourceName']= e.name
          e['resourceType'] =x.formattedName
          array.push(e)
         }
      })
    })
    await this.getManagementSubnet(list.subscriptionId)
    resources.body.value.forEach(e=>{
      this.subnetData.forEach(element => {
        if(e.id.includes('virtualNetworks')&&e.id.includes(element[1])){
       array.push({id: e.id,resourceGroupName:element[2],resourceType:'subnet',type:'Microsoft.Network/subnets',name:element[0],resourceName:element[0],location: e.location,length:this.subnetData.length})
        }
      });
    })
    return array;
  }

  closeAllSubTabs(){
    $('#management').removeClass('open').siblings().slideUp('fast')
    this.projectList.forEach((e,i)=>{
      $('#types_' + i).removeClass('open').siblings().slideUp('fast') // close all sub menus
    })
  }

  async getName(list){
    let allTypes=this.util.getStaticResourceTypes()
      let lowerCase= list?.resourceType.toLowerCase()
      switch(lowerCase){
        case 'virtualprivatenetworkgateway' :lowerCase='Virtual Network Gateway';break;
        case 'virtualmachinescalesets' : lowerCase='VM Scale Set';break;
        case 'virtualmachinescaleset' : lowerCase='VM Scale Set';break;
        case 'vmscalesets' :lowerCase='VM Scale Set';break;
        case 'publicipaddresses' :lowerCase='Public IP Address';break;
        case 'publicip' : lowerCase='Public IP Address';break;
        case 'networkinterface' : lowerCase= 'Network Interface';break;
        case 'networkinterfaces' : lowerCase= 'Network Interface';break;
        case 'virtualnetworks' : lowerCase= 'Virtual Network';break;
        case 'networksecuritygroups' : lowerCase= 'Network Security Group';break;
        case 'localnetworkgateways' : lowerCase= 'Local Network Gateway';break;
        case 'subnets' : lowerCase= 'Subnet';break;
        case 'publicipaddress' : lowerCase= 'Public IP Address';break;
        case 'sqlvirtualmachines' : lowerCase= 'SQL Virtual Machine';break;
        case 'sqlvirtualmachine' : lowerCase= 'SQL Virtual Machine';break;
        case 'sqldatabase' : lowerCase= 'SQL Databases';break;
        case 'sqlserver' : lowerCase= 'SQL Servers';break;
        case 'sqldatabases' : lowerCase= 'SQL Databases';break;
        case 'sqlservers' : lowerCase= 'SQL Servers';break;
        case 'dnszone' : lowerCase= 'DNS Zone';break;
        case 'cdnprofile' : lowerCase= 'CDN Profile';break;
        case 'dedicatedsqlpool' : lowerCase= 'Dedicated SQL Pool';break;
        case 'virtualmachines' : lowerCase= 'Virtual Machines';break;
        case 'disks' : lowerCase= 'Disk';break;
        case 'loadbalancers' : lowerCase= 'Load Balancers';break;
        case 'storageaccount' :  lowerCase= 'Storage Accounts';break;
        case 'storageaccounts' :  lowerCase= 'Storage Accounts';break;
        case 'appserviceplans' :  lowerCase= 'App Service Plans';break;
        case 'applicationinsights' :  lowerCase= 'Application Insights';break;
        case 'networkwatchers' :  lowerCase= 'Network Watchers';break;
        case 'azuresynapses' :  lowerCase= 'Azure Synapses';break;
        case 'routetables' :  lowerCase= 'Route Tables';break;
        case 'applicationgateways' :  lowerCase= 'Application Gateways';break;
        case 'machinelearningworkspaces' :  lowerCase= 'Machine Learning Workspaces';break;
        default: {  
         let name=this.splitWordByCaps(list?.resourceType)
          lowerCase=this.titlecasePipe.transform(name)
          }
        }
      let resourceArr= allTypes.filter(e=>e.singular.replace(/\s/g, '').toLowerCase()==lowerCase.replace(/\s/g, '').toLowerCase())
      if(resourceArr.length){
        if(list?.count==1) return resourceArr[0].singular
        else return resourceArr[0].plural
      }else return lowerCase
  }

  getResourceTypeName(list){
    let mergename = list.resourceType.replace(/\s/g, '').toLowerCase()
    if(list?.count==1){
      switch(mergename){
        case 'virtualprivatenetworkgateway' : return 'Virtual Network Gateway'
        case 'virtualnetworkgateways' : return 'Virtual Network Gateway'
        case 'virtualmachinescalesets' : return 'VM Scale Set'
        case 'virtualmachinescaleset' : return 'VM Scale Set'
        case 'vmscalesets' : return 'VM Scale Set'
        case 'publicipaddresses' : return 'Public IP Address'
        case 'publicip' : return 'Public IP Address'
        case 'networkinterface' : return 'Network Interface'
        case 'networkinterfaces' : return 'Network Interface'
        case 'virtualnetworks' : return 'Virtual Network'
        case 'networksecuritygroups' : return 'Network Security Group'
        case 'localnetworkgateways' : return 'Local Network Gateway'
        case 'subnets' : return 'Subnet'
        case 'publicipaddress' : return 'Public IP Address'
        case 'sqlvirtualmachines' : return 'SQL Virtual Machine'
        case 'sqlvirtualmachine' : return 'SQL Virtual Machine'
        case 'sqldatabase' : return 'SQL Database'
        case 'sqlserver' : return 'SQL Server'
        case 'sqldatabases' : return 'SQL Database'
        case 'sqlservers' : return 'SQL Server'
        case 'dnszone' : return 'DNS Zone'
        case 'cdnprofile' : return 'CDN Profile'
        case 'dedicatedsqlpool' : return 'Dedicated SQL Pool'
        case 'virtualmachines' : return 'Virtual Machine'
        case 'disks' : return 'Disk'
        case 'loadbalancers' : return 'Load Balancer'
        case 'storageaccount' :  return 'Storage Account'
        case 'storageaccounts' :  return 'Storage Account'
        case 'appserviceplans' :  return 'App Service Plan'
        case 'applicationinsights' :  return 'Application Insight'
        case 'networkwatchers' :  return 'Network Watcher'
        case 'azuresynapses' :  return 'Azure Synapse'
        case 'routetables' :  return 'Route Table'
        case 'connections' :  return 'Connection'
        case 'applicationgateways' :  return 'Application Gateway'
        case 'machinelearningworkspaces' :  return 'Machine Learning Workspace'
        case 'datafactories' :  return 'Data Factory'
        case 'apachesparkpools' :  return 'Apache Spark Pool'
        case 'containerregistries' :  return 'Container Registry'
        case 'containerinstances' :  return 'Container Instance'
        case 'cognitiveservices' :  return 'Cognitive Service'
        default: {  let name=this.splitWordByCaps(list.resourceType)
        return this.titlecasePipe.transform(name)
        }
      }
    }else{
      switch(mergename){
        case 'virtualprivatenetworkgateway' : return 'Virtual Network Gateways'
        case 'virtualnetworkgateway' : return 'Virtual Network Gateways'
        case 'virtualmachinescalesets' : return 'VM Scale Sets'
        case 'virtualmachinescaleset' : return 'VM Scale Sets'
        case 'vmscaleset' : return 'VM Scale Sets'
        case 'vmscalesets' : return 'VM Scale Sets'
        case 'publicipaddresses' : return 'Public IP Addresses'
        case 'publicip' : return 'Public IP Addresses'
        case 'networkinterface' : return 'Network Interfaces'
        case 'virtualnetwork' : return 'Virtual Networks'
        case 'networksecuritygroup' : return 'Network Security Groups'
        case 'localnetworkgateway' : return 'Local Network Gateways'
        case 'subnet' : return 'Subnets'
        case 'publicipaddress' : return 'Public IP Addresses'
        case 'sqlvirtualmachines' : return 'SQL Virtual Machines'
        case 'sqlvirtualmachine' : return 'SQL Virtual Machines'
        case 'sqldatabase' : return 'SQL Databases'
        case 'sqlserver' : return 'SQL Servers'
        case 'sqldatabases' : return 'SQL Databases'
        case 'sqlservers' : return 'SQL Servers'
        case 'dnszone' : return 'DNS Zones'
        case 'cdnprofile' : return 'CDN Profiles'
        case 'dedicatedsqlpool' : return 'Dedicated SQL Pools'
        case 'virtualmachine' : return 'Virtual Machines'
        case 'disk' : return 'Disks'
        case 'loadbalancer' : return 'Load Balancers'
        case 'storageaccount' :  return 'Storage Accounts'
        case 'appserviceplan' :  return 'App Service Plans'
        case 'appservice' :  return 'App Services'
        case 'applicationinsight' :  return 'Application Insights'
        case 'networkwatcher' :  return 'Network Watchers'
        case 'azuresynapse' :  return 'Azure Synapses'
        case 'routetable' :  return 'Route Tables'
        case 'connection' :  return 'Connections'
        case 'applicationgateway' :  return 'Application Gateways'
        case 'machinelearningworkspace' :  return 'Machine Learning Workspaces'
        case 'datafactory' :  return 'Data Factories'
        case 'apachesparkpool' :  return 'Apache Spark Pools'
        case 'containerregistry' :  return 'Container Registries'
        case 'containerinstance' :  return 'Container Instances'
        case 'cognitiveservice' :  return 'Cognitive Services'
        default: {  let name=this.splitWordByCaps(list.resourceType)
        return this.titlecasePipe.transform(name)
        }
      }
    }
   
  }

  async getManagementSubnet(subId){
    this.subnetData = []
    let subsArray = subId
    let body ={
        "subscriptions": [subsArray],
        "query": "Resources | where type == 'microsoft.network/virtualnetworks' | mv-expand subnet=properties.subnets | extend subnetName = subnet.name | extend addressPrefix = subnet.properties.addressPrefix | extend delegations = subnet.properties.delegations[0].properties.serviceName | extend nsg = subnet.properties.networkSecurityGroup.id | extend id2 = reverse(nsg) | extend splited = split(id2,'/')[0] | extend id1 = reverse(splited) | project-rename networkSecurityGroup = id1 | project subnetName, name, resourceGroup, addressPrefix, delegations, networkSecurityGroup, tags | project-rename vnetName = name | order by vnetName asc "
    }
    
    return this.util.handleRequest('post','a3s_management_getDataByQueries',[],body,null,null,true).then(res=>{
      if(!res || !res.body) return 
      this.subnetData = res.body.data.rows

    })
  }
}
