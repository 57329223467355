import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';


@Component({
  selector: 'app-sql-vm-actions',
  templateUrl: './sql-vm-actions.component.html',
  styleUrls: ['./sql-vm-actions.component.scss']
})
export class SqlVmActionsComponent implements OnInit {

  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("sqlVMDetailsTemplate") sqlVMDetailsTemplate: TemplateRef<any>;
  @ViewChild("sqlHardwareConfigurationPopup") sqlHardwareConfigurationPopup: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  @ViewChild("storageConfigurationConfirm") storageConfigurationConfirmPopup: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    InValidSize:false,
    InvalidSizeRange:false,
    invalidIP:false,
    invalidRuleName:false,
    vnetRuleNameEmpty:false,
    vnetRuleNameLen:false,
    vnetRuleNameInvalid:false,
    dbNameEmpty:false,
    dbNameLen:false,
    dbNameInvalid:false,
    dbNameDup:false,
    invalidRestore:false,
    filesCountEmpty:false,
    filesCountInvalidNumber:false,
    filesCountInvalidSizeRange:false,
    initialSizeEmpty:false,
    initialSizeInvalidNumber:false,
    initialSizeInvalidSizeRange:false,
    autogrowthEmpty:false,
    autogrowthInvalidNumber:false,
  }
  selectedType: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;
  sqlVMData: any;
  currentSubSheet: any;
  saveBtnEnable: boolean;
  sqlVmExtensionData: any;

  editionList=[
    {name:'Developer'},
    {name:'Express'},
    {name:'Standard'},
    {name:'Enterprise'},
    {name:'Web'},
  ]
  warningMsg: boolean;
  errorMsg: boolean;
  daysList=this.util.getStatic('dayOfWeeks');
  localTimeList=this.util.getStatic('localTimeList');
  skuList: any;
  performanceTier: any[];
  vmData: any;
  vmSizeData: any;
  currentVMSize: any;
  remDiskCount: any;
  tempDBList: any;
  vmStatus: any;
  freeSizeD: any;
  totalSizeD: any;
  disableAction: boolean;
  freeSizeF: number;
  totalSizeF: number;
  freeSizeG: number;
  totalSizeG: number;


   constructor(public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private _dataMigrationService: DataMigrationService,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }

  
  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'deleteSqlVMData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteSqlVMData')
      }
      case'storageData':{
        this.closeModal()
        return this.openModal(this.storageConfigurationConfirmPopup,this.selectedRow,'storageData')
      }
      case'storageLog':{
        this.closeModal()
        return this.openModal(this.storageConfigurationConfirmPopup,this.selectedRow,'storageLog')
      }
      case'storageTempDB':{
        this.closeModal()
        return this.openModal(this.storageConfigurationConfirmPopup,this.selectedRow,'storageTempDB')
      }
    }
  }
    // Trigger action of the SQL DB
  
  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case 'configure':{
        this.closeModal()
        this.currentForm = this.util.getForm('configureSqlVM')
        await this.getSQLVMData(this.selectedRow)
        await this.setDefaultValue('configure')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case 'additionalFeatures':{
        this.closeModal()
        this.currentForm = this.util.getForm('additionalFeaturesSqlVM')
        await this.getSQLVMData(this.selectedRow)
        await this.getSQLVMExtension(this.selectedRow)
        this.currentForm.get('sqlServerMLService').setValue(this.sqlVmExtensionData?.properties?.settings?.ServerConfigurationsManagementSettings ? this.sqlVmExtensionData?.properties?.settings?.ServerConfigurationsManagementSettings?.AdditionalFeaturesServerConfigurations?.IsRServicesEnabled:false)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case 'patching':{
        this.closeModal()
        this.currentForm = this.util.getForm('patchingSqlVM')
        await this.getSQLVMData(this.selectedRow)
        await this.getSQLVMExtension(this.selectedRow)
        await this.setDefaultValue('patching')
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case 'storageConfiguration':{
        this.closeModal()
        await this.getSQLVMData(this.selectedRow)
        await this.getSQLVMExtension(this.selectedRow)
        await this.getVMData(this.selectedRow)
        await this.getVMSizeList(this.selectedRow)
        this.showSidesheet = true;
        break;
      }
    }
  }
  async openSubSideSheet(type,subType?:any){
    this.currentSubSheet = type
    switch(this.currentSubSheet){
      case "data" : {
        this.currentForm= this.formBuilder.group({
          disk : [null],
          diskCount : [null],
          Gib : [null],
          IOPS : [null],
          MBps : [null],
        })
        await this.getSKUList(this.selectedRow,'')
        await this.setDefaultValue('dataLog')
        this.showSubSidesheet=true
        this.saveDisable=true
        break;
      }
      case "log" : {
        this.currentForm= this.formBuilder.group({
          disk : [null],
          diskCount : [null],
          Gib : [null],
          IOPS : [null],
          MBps : [null],
        })
        await this.getSKUList(this.selectedRow,'')
        await this.setDefaultValue('dataLog')
        this.showSubSidesheet=true
        this.saveDisable=true
        break;
      }
      case "tempDB" : {
        this.currentForm= this.formBuilder.group({
          configureTempDB : [false],
          initialSize : [8],
          filesCount : [4],
          autogrowth : [64],
        })
           // await this.setDefaultValue('patching')
        this.showSubSidesheet=true
        this.saveDisable=false
        break;
      }
    }
  }
  async deleteAction(){
    switch(this.selectedType){
      case 'deleteSqlVMData' :return this.deleteSqlVMData(this.selectedRow)
    }
  }
  
  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedRow=row;
    this.selectedType=type;
    type=='sqlVMPopup' ? this.getVMStatus(this.selectedRow) : false
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  close_subSheet(){
    this.showSubSidesheet = false
    this.resetSubSideSheetForm()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteSqlVMData':return 'Delete SQL Virtual Machine'
      case'storageData':return 'Extended Data Drive'
      case'storageLog':return 'Extended Log Drive'
      case'storageTempDB':return 'tempdb Configuration'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteSqlVMData':return `Are you sure you want to delete “${this.selectedRow[0]}”?` 
      case'storageData':return `This will extend the SQL virtual machine storage Data drive (F) with the following disks`
      case'storageLog':return 'This will extend the SQL virtual machine storage Log drive (G) with the following disks'
      case'storageTempDB':return `This will re-configure tempdb data files with the folowing settings:`
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteSqlVMData':return 'Delete'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getAppSkuName(val){
    if(val  == "Standard"){
      return val
    } else{
      return val.replace('V', ' V')
    }
  }
  getStatusClass(val){
    let status = val.toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'online': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      case 'connected': return 'st-success';
      case 'not connected': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }
  getSuccesText(type){
    switch(type){
      case'configureSQLVM':return `“${this.selectedRow[0]}” configuration updated successfully!`
      case'additionalFeaturesSQLVM':return `“${this.selectedRow[0]}” additional features updated successfully!`
      case'patching':return `“${this.selectedRow[0]}” patching updated successfully!`
      case'storageData':return`“${this.selectedRow[0]}” data drive updated successfully!`
      case'storageLog':return `“${this.selectedRow[0]}” log drive updated successfully!`
      case'storageTempDB':return `“${this.selectedRow[0]}” tempdb updated successfully!`
      default: return 'Changes saved successfully!'
    }
  }
  
  getFailureText(type){
    switch(type){
      case'configureSQLVM':return `“${this.selectedRow[0]}” configuration updating failed`
      case'additionalFeaturesSQLVM':return `Failed to update “${this.selectedRow[0]}” additional features.`
      case'patching':return `Failed to update “${this.selectedRow[0]}” patching.`
      case'storageData':return`Failed to update “${this.selectedRow[0]}” data drive.`
      case'storageLog':return `Failed to update “${this.selectedRow[0]}” log drive.`
      case'storageTempDB':return `Failed to update “${this.selectedRow[0]}” tempdb.`
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'configureSQLVM':return `Updating “${this.selectedRow[0]}” configuration...`
      case'additionalFeaturesSQLVM':return `Updating “${this.selectedRow[0]}” additional features...`
      case'patching':return `Updating “${this.selectedRow[0]}” patching...`
      case'storageData':return `Updating “${this.selectedRow[0]}” data drive...`
      case'storageLog':return `Updating “${this.selectedRow[0]}” log drive...`
      case'storageTempDB':return `Updating “${this.selectedRow[0]}” tempdb...`
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'SqlVirtualMachines':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
    }
  }
  
  invalidForm(type){
    switch(type){
      case'patching':{
        return this.inputError?.InValidSize|| this.inputError?.InvalidSizeRange
      }
      case'storageTempDB':{
        return this.inputError?.filesCountEmpty|| this.inputError?.filesCountInvalidNumber || this.inputError?.filesCountInvalidSizeRange|| this.inputError?.initialSizeEmpty ||
        this.inputError?.initialSizeInvalidNumber|| this.inputError?.initialSizeInvalidSizeRange || this.inputError?.autogrowthEmpty|| this.inputError?.autogrowthInvalidNumber
      }
    }
  }

  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError={
      InValidSize:false,
      InvalidSizeRange:false,
      invalidIP:false,
      invalidRuleName:false,
      vnetRuleNameEmpty:false,
      vnetRuleNameLen:false,
      vnetRuleNameInvalid:false,
      dbNameEmpty:false,
      dbNameLen:false,
      dbNameInvalid:false,
      dbNameDup:false,
      invalidRestore:false,
      filesCountEmpty:false,
      filesCountInvalidNumber:false,
      filesCountInvalidSizeRange:false,
      initialSizeEmpty:false,
      initialSizeInvalidNumber:false,
      initialSizeInvalidSizeRange:false,
      autogrowthEmpty:false,
      autogrowthInvalidNumber:false,
    }
  }

  setRadioBtn(id,type,subType?:any,index?:any){
  switch(type){
    case 'configure' :{
      this.saveDisable=true
      let value = id 
      this.currentForm.get('serverLicense').setValue(value)
      if(value=='HA/DR' && (this.currentForm.get('edition').value!='Standard' && this.currentForm.get('edition').value!='Enterprise')) return this.errorMsg=true
      else if(value=='AHUB' && (this.currentForm.get('edition').value!='Standard' && this.currentForm.get('edition').value!='Enterprise')) return this.errorMsg=true
      else  return this.errorMsg=false
      break;
    }
    case 'additionalFeatures' :{
      this.saveDisable=true
      let value = id == 'yes' ? true : false
      this.currentForm.get('sqlServerMLService').setValue(value)
      break;
    }
    case 'patching' :{
      this.saveDisable=true
      let value = id == 'yes' ? true : false
      this.currentForm.get('automatedPatching').setValue(value)
      break;
    }
    case 'storageConfiguration' :{
      let value = id == 'yes' ? true : false
      this.currentForm.get('configureTempDB').setValue(value)
      if(value){
        this.saveDisable=true
        this.currentForm.get('filesCount').setValidators(Validators.required)
        this.currentForm.get('initialSize').setValidators(Validators.required)
        this.currentForm.get('autogrowth').setValidators(Validators.required)
      }else{
        this.saveDisable=false
        this.currentForm.get('filesCount').setValidators(null)
        this.currentForm.get('initialSize').setValidators(null)
        this.currentForm.get('autogrowth').setValidators(null)
      }
      this.currentForm.get('filesCount').updateValueAndValidity()
      this.currentForm.get('initialSize').updateValueAndValidity()
      this.currentForm.get('autogrowth').updateValueAndValidity()
      break;
    }
  }
}

  async setDefaultValue(type){
  switch(type){
   case 'configure':{
    let config=this.sqlVMData
    this.errorMsg=false
    this.warningMsg=false
    this.currentForm.get('serverLicense').setValue(config?.properties?.sqlServerLicenseType)
    this.currentForm.get('edition').setValue(config?.properties?.sqlImageSku)
    break;
   }
   case'patching':{
    this.currentForm.get('automatedPatching').setValue(this.sqlVmExtensionData?.properties?.settings?.AutoPatchingSettings ?this.sqlVmExtensionData?.properties?.settings?.AutoPatchingSettings?.Enable: false)
    if(this.sqlVmExtensionData?.properties?.settings?.AutoPatchingSettings && this.sqlVmExtensionData?.properties?.settings?.AutoPatchingSettings?.Enable){
      this.currentForm.get('schedule').setValue(this.sqlVmExtensionData?.properties?.settings?.AutoPatchingSettings?.DayOfWeek)
      this.currentForm.get('windowDuration').setValue(this.sqlVmExtensionData?.properties?.settings?.AutoPatchingSettings?.MaintenanceWindowDuration)
      this.currentForm.get('startHour').setValue(Number(this.sqlVmExtensionData?.properties?.settings?.AutoPatchingSettings?.MaintenanceWindowStartingHour))
    }else{
      this.currentForm.get('schedule').setValue('Sunday')
      this.currentForm.get('windowDuration').setValue(60)
      this.currentForm.get('startHour').setValue(2)
    }
   }
   case 'dataLog':{
    let def=this.performanceTier.filter(e=>e.id=='P30')
    this.errorMsg=false
    this.currentForm.get('disk').setValue(def[0].id)
    this.currentForm.get('diskCount').setValue(1)
    this.currentForm.get('Gib').setValue(def[0]?.Gib)
    this.currentForm.get('IOPS').setValue(def[0].IOPS)
    this.currentForm.get('MBps').setValue(def[0]?.MBps)
    break;
   }
  }
  }
  setEdition(event){
    this.warningMsg=true
    this.errorMsg=false
    this.saveDisable=true
    if(this.currentForm.get('serverLicense').value=='AHUB' && (event.name!='Standard' && event.name!='Enterprise')){
      this.errorMsg=true
    }else if(this.currentForm.get('serverLicense').value=='HA/DR' && (event.name!='Standard' && event.name!='Enterprise')){
      this.errorMsg=true
    }
  }
  getStorageDetails(event){
    this.saveDisable=true
    this.currentForm.get('diskCount').setValue(1)
    this.currentForm.get('Gib').setValue(event?.Gib)
    this.currentForm.get('IOPS').setValue(event?.IOPS)
    this.currentForm.get('MBps').setValue(event?.MBps)
  }

  async saveSQLVMData(type,subType?:any){
    switch(type){
      case'configure':{
        let body=this.sqlVMData
        let rawValue=this.currentForm.getRawValue()
        body['properties'].sqlServerLicenseType=rawValue.serverLicense
        body['properties'].sqlImageSku=rawValue.edition
        this.updateSQLVMData(this.selectedRow,body,'configureSQLVM')
        break;
      }
      case'additionalFeatures':{
        let body=this.sqlVmExtensionData
        let rawValue=this.currentForm.getRawValue()
        body['properties'].settings['ServerConfigurationsManagementSettings']={
          "AdditionalFeaturesServerConfigurations": {
            "IsRServicesEnabled": rawValue.sqlServerMLService
            }
        }
        this.updateSQLVMExtension(this.selectedRow,body,'additionalFeaturesSQLVM')
        break;
      }
      case'patching':{
        let body=this.sqlVmExtensionData
        let rawValue=this.currentForm.getRawValue()
        if(rawValue.automatedPatching){
          body['properties'].settings['AutoPatchingSettings']={ 
            "Enable": true, 
            "DayOfWeek": rawValue.schedule, 
            "MaintenanceWindowStartingHour": rawValue.startHour, 
            "MaintenanceWindowDuration": rawValue.windowDuration 
          }
        }else{
          body['properties'].settings['AutoPatchingSettings']={ 
            "Enable": false, 
          }
        }
        this.updateSQLVMExtension(this.selectedRow,body,'patching')
        break;
      }
      case 'storageConfiguration':{
        switch(this.selectedType){
          case'storageData':{
            let body=this.sqlVmExtensionData
            let rawValue=this.currentForm.getRawValue()
            let lunArr=[]
            await this.getVMData(this.selectedRow)
            for(let i=0;i<rawValue.diskCount;i++){
              let num= this.vmData.properties.storageProfile.dataDisks.length + i
                let diskName=`${this.sqlVMData.name}_DataDisk_${num}`
                let diskSize= this.currentForm.get('Gib').value
                // let body={
                //     "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Compute/disks/${diskName}`, 
                //     "name": diskName, 
                //     "type": "Microsoft.Compute/disks", 
                //     "location": this.selectedRow[3], 
                //     "sku": { 
                //     "name": "Premium_LRS" 
                //     }, 
                //     "properties": { 
                //     "diskSizeGB": Number(diskSize), 
                //     "creationData": { 
                //     "createOption": "empty" 
                //     }
                //     } 
                // }
                // await this.UpdateDiskData(this.selectedRow,body,'')
                let dataDiskArray=this.vmData?.properties?.storageProfile?.dataDisks
                let lun=[]
                if(dataDiskArray.length!=0 && dataDiskArray.length>1){
                    dataDiskArray.sort((a,b)=>{
                      let x = Number(a?.lun);
                      let y = Number(b?.lun);
                      return y-x})
                  }
                   lun.push(dataDiskArray[0]?.lun)
                
                
                let vmBody={ 
                  "name": diskName, 
                  "lun":lun.length? lun[0]+1 : 0, 
                  "managedDisk": { 
                    "storageAccountType": "Premium_LRS",
                  // "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Compute/disks/${diskName}`
                  }, 
                  "createOption": "Empty",
                  "deleteOption": "Detach",
                  "toBeDetached": false,
                  "diskSizeGB": Number(diskSize), 
                  "caching": "None", 
                  // "writeAcceleratorEnabled": false 
                  } 
                  this.vmData['properties'].storageProfile.dataDisks.push(vmBody)
                  delete this.vmData?.resources
                  await this.UpdateVMData(this.selectedRow,this.vmData)
                  lunArr.push(vmBody.lun)
            }
  
            body['properties'].settings['ServerConfigurationsManagementSettings']['SQLStorageUpdateSettingsV2']['SQLDataSettings']={
              // "defaultFilePath": "F:\\folderpath\\", 
              "LUNs": lunArr
            }
            this.updateSQLVMExtension(this.selectedRow,body,'storageData')
            break;
          }
          case'storageLog':{
            let body=this.sqlVmExtensionData
            let rawValue=this.currentForm.getRawValue()
            let lunArr=[]
            await this.getVMData(this.selectedRow)
            for(let i=0;i<rawValue.diskCount;i++){
              let num= this.vmData.properties.storageProfile.dataDisks.length + i
                let diskName=`${this.sqlVMData.name}_DataDisk_${num}`
                let diskSize= this.currentForm.get('Gib').value
                if(rawValue.diskCount>1){
                  diskSize=diskSize/rawValue.diskCount
                }
                // let body={
                //     "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Compute/disks/${diskName}`, 
                //     "name": diskName, 
                //     "type": "Microsoft.Compute/disks", 
                //     "location": this.selectedRow[3], 
                //     "sku": { 
                //     "name": "Premium_LRS" 
                //     }, 
                //     "properties": { 
                //     "diskSizeGB": Number(diskSize), 
                //     "creationData": { 
                //     "createOption": "empty" 
                //     }
                //     } 
                // }
                // await this.UpdateDiskData(this.selectedRow,body,'')
                let dataDiskArray=this.vmData?.properties?.storageProfile?.dataDisks
                let lun=[]
                if(dataDiskArray.length!=0 && dataDiskArray.length>1){
                    dataDiskArray.sort((a,b)=>{
                      let x = Number(a?.lun);
                      let y = Number(b?.lun);
                      return y-x})
                  }
                   lun.push(dataDiskArray[0]?.lun)
                
                
                let vmBody={ 
                  "name": diskName, 
                  "lun":lun.length? lun[0]+1 : 0, 
                  "managedDisk": { 
                    "storageAccountType": "Premium_LRS",
                    // "id": `/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Compute/disks/${diskName}`
                  }, 
                  "createOption": "Empty", 
                  "deleteOption": "Detach",
                  "toBeDetached": false,
                  "diskSizeGB": Number(diskSize), 
                  "caching": "None", 
                  } 
                  this.vmData['properties'].storageProfile.dataDisks.push(vmBody)
                  delete this.vmData?.resources
                  await this.UpdateVMData(this.selectedRow,this.vmData)
                  lunArr.push(vmBody.lun)
              }

            body['properties'].settings['ServerConfigurationsManagementSettings']['SQLStorageUpdateSettingsV2']['SQLLogSettings']={
              //      "defaultFilePath": "G:\\folderpath\\",
              "LUNs": lunArr
            }
            this.updateSQLVMExtension(this.selectedRow,body,'storageLog')
            break;
          }
          case'storageTempDB':{
            let body=this.sqlVmExtensionData
            let rawValue=this.currentForm.getRawValue()
            if(body['properties'].settings.ServerConfigurationsManagementSettings){
              body['properties'].settings['ServerConfigurationsManagementSettings']['SQLStorageUpdateSettingsV2']['SQLTempDbSettings']['DataFileCount']=rawValue.filesCount,
              body['properties'].settings['ServerConfigurationsManagementSettings']['SQLStorageUpdateSettingsV2']['SQLTempDbSettings']['DataFileSize']=rawValue.initialSize,
              body['properties'].settings['ServerConfigurationsManagementSettings']['SQLStorageUpdateSettingsV2']['SQLTempDbSettings']['DataGrowth']=rawValue.autogrowth
            }else{
             body['properties'].settings['ServerConfigurationsManagementSettings']={
              'SQLStorageUpdateSettingsV2':{
                "SQLTempDbSettings":{
                  "DataFileCount":rawValue.filesCount, 
                  "DataFileSize":rawValue.initialSize,
                  "DataGrowth":rawValue.autogrowth,
                }
               }
            }
          }
            this.updateSQLVMExtension(this.selectedRow,body,'storageTempDB')
            break;
          }
        }
        break;
      }
  }
  }
 
  
  //validation
  dataSizeValidation(event){
    let value=event.target.value;
    this.saveDisable=true
    this.inputError['InvalidSizeRange']=false; 
    this.inputError['InValidSize']=false;
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError['InValidSize']=true;
      }
      if(value<30 || value>180) return this.inputError['InvalidSizeRange']=true;
    }else{
      this.inputError['InvalidSizeRange']=true;
      this.inputError['InValidSize']=true;
    }
  }

  ValidDiskcount(event){
    let value=event.target.value;
    this.saveDisable=true
    this.errorMsg=false
    let val=this.performanceTier.filter(e=>e.id==this.currentForm.get('disk').value)
    this.currentForm.get('Gib').setValue(val[0].Gib*value)
    this.currentForm.get('IOPS').setValue(val[0].IOPS*value)
    this.currentForm.get('MBps').setValue(val[0].MBps*value)
    if(value>this.remDiskCount || value=='' ||value==0) return this.errorMsg=true
  }

  tempDBValidation(event,type){
    let value=event.target.value;
    this.saveDisable=true
    this.inputError[type+'Empty']=false;
    this.inputError[type+'InvalidNumber']=false;
    this.inputError[type+'InvalidSizeRange']=false;
    if(value&&value!=""){
      if((!(/^[0-9]*$/).test(value))){
        this.inputError[type+'InvalidNumber']=true;
      }
      if(type=='filesCount' && value<4) return this.inputError['filesCountInvalidSizeRange']=true;
      if(type=='initialSize' && value<8) return this.inputError['initialSizeInvalidSizeRange']=true;
    }else{
      this.inputError[type+'Empty']=true;
    }
  }


  //API's
  // getSubscription(){
  //   return this.subscriptionList=[{
  //     name:this.getProjectName(this.selectedRow[2]),
  //     id:this.selectedRow[2],
  //   }]
  // }
  // getRgBySubscription(){
  //   let body={
  //       subscriptionId : this.selectedRow[2],
  //   }
  //   return this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
  //       console.log('Resource Group list ',res);
  //       this.resourceGroupsList = res && res.value ? res.value : []
  //   })
  // }

  getSQLVMData(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let sqlVMName = data[0]
    return this.util.handleRequest('get','a3s_management_getSQLVMData',[subsId,rgName,sqlVMName],null,null,null,true).then(async res=>{
      this.sqlVMData= res.body
      console.log('SQL Database Data',this.sqlVMData)
    })
  }

  getVMData(data?:any){
    let vmName=this.sqlVMData.properties.virtualMachineResourceId.split('/')[8]
    return this.util.handleRequest('get','a3s_management_getVMData',[data[2],data[1],vmName],null,null,null,true).then(res=>{
      this.vmData = res.body
      this.currentVMSize=  this.vmData?.properties?.hardwareProfile?.vmSize
      console.log('VM Data',this.vmData)
    })
  }
  getVMStatus(data,type?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vmScaleSetName = data[0]
    return this.util.handleRequest('get','a3s_management_getVMScaleSetVMStatus',[subsId,rgName,vmScaleSetName],null,null,null,true).then(res=>{
      this.vmStatus = res.body
      let status=this.vmStatus?.properties?.instanceView?.statuses[1]?.code.split('/')[1].toLowerCase()
      this.disableAction=status!='running'&&status!='starting'?true:false
      console.log('VM Status',this.vmStatus)
    })
  }
  UpdateVMData(data,body,type?:any){
  return this.util.handleRequest('post','a3s_management_updateVMData',[data[2],data[1],data[0]],body,null,null,true).then(res=>{
     console.log('VM Data',res)
     this.showSubSidesheet = false
     this.closeModal()
    },err=>{

    })
  }
  getVMSizeList(data,type?:any){
    let subsId = data[2]
    let location = data[3]
    return this.util.handleRequest('get','a3s_management_getVMSize',[subsId,location],null,null,null,true).then(res=>{
      this.vmSizeData= res.body.value
      let temp=this.vmSizeData.filter(e=>e.name==this.currentVMSize)
      let maxCount=temp[0].maxDataDiskCount
      this.remDiskCount=maxCount-this.vmData?.properties?.storageProfile?.dataDisks.length
      console.log('VM Size List',this.vmSizeData)
    })
  }

  updateSQLVMData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let sqlVMName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateSQLVMData',[subsId,rgName,sqlVMName],body,null,null,true).then(res=>{
      console.log('SQL Database updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }

  deleteSqlVMData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],sqlVMName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteSQLVMData',[subscriptionId,resourceGroupName,sqlVMName],null,null,null,true).then(async res=>{
      console.log('SQL Database Data Deleted',res)
      this.closeModal()
      let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
      this.data.splice(index,1)
    this.util.success(data[0] + ' deleted successfully!')
  },err=>{
    this.util.error(data[0] +' failed to deleted.')
  })
  }

  getSQLVMExtension(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let sqlVMName=data[0]
    return this.util.handleRequest('get','a3s_management_getSQLVMExtension',[subsId,rgName,sqlVMName],null,null,null,true).then(async res=>{
      this.sqlVmExtensionData= res.body
      this.tempDBList=JSON.parse(this.sqlVmExtensionData.properties.instanceView.substatuses[15].message)
      let check=this.tempDBList.Storage.TempFilesStatuses
      let driveList=this.tempDBList.Storage.VolumesList
      // tempDM size
      let tempDb=driveList.filter(e=>e.DriveLetter=='D')
      let freeSpace=tempDb[0].FreeSizeInMiB*0.0009765625
      this.freeSizeD=Math.round(freeSpace)
      let totalSpace=tempDb[0].TotalSizeInMiB*0.0009765625
      this.totalSizeD=Math.round(totalSpace)
      // data drive
      let dataDrive=driveList.filter(e=>e.DriveLetter=='F')
      let freeSpaceData=dataDrive[0].FreeSizeInMiB*0.0009765625
      this.freeSizeF=Math.round(freeSpaceData)
      let totalSpaceData=dataDrive[0].TotalSizeInMiB*0.0009765625
      this.totalSizeF=Math.round(totalSpaceData)
      // log drive
      let logDrive=driveList.filter(e=>e.DriveLetter=='G')
      let freeSpaceLog=logDrive[0].FreeSizeInMiB*0.0009765625
      this.freeSizeG=Math.round(freeSpaceLog)
      let totalSpaceLog=logDrive[0].TotalSizeInMiB*0.0009765625
      this.totalSizeG=Math.round(totalSpaceLog)
      console.log('SQL Server Data',this.sqlVmExtensionData)
    })
  }
  updateSQLVMExtension(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let sqlVMName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateSQLVMExtension',[subsId,rgName,sqlVMName],body,null,null,true).then(res=>{
      console.log('SQL Database updated Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type?this.util.error(this.getFailureText(type)):false
    })
  }
  UpdateDiskData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let diskName =  body.name
    // this.util.info(this.getInProgress(type))
    return this.util.handleRequest('post','a3s_management_updateDiskData',[subsId,rgName,diskName],body,null,null,true).then(res=>{
      console.log('disk Data',res)
      this.showSubSidesheet = false
      // this.closeModal()
      // this.closeSidesheet()
      // this.util.success(this.getSuccesText(type))
    },err=>{
      // this.util.error(this.getFailureText(type))
    })
  }

  async getSKUList(data,type,event?:any){
    let subsId = data[2]
    let loc = data[3]
    return this.util.handleRequest('get','a3s_management_getSKUDiskData',[subsId,loc],null,null,null,true).then(async res=>{
      this.skuList = res.body.value
      this.skuList= this.skuList.filter(e=>e.name=='Premium_LRS')
      let sorted=this.skuList.filter(e=>e.resourceType=='disks');
      sorted.sort((a,b)=>{
        let x = Number(a?.capabilities[0].value);
        let y = Number(b?.capabilities[0].value);
        return x-y
      })
      this.skuList=sorted;
      // this.skuList.forEach(e=>{
      //   let num=this.diskData?.properties?.diskSizeGB
      //   num=this.sizeRange(num)
      //   e['active']=e.capabilities[0].value==num ?true:false
      //   // && e.tier==this.diskData?.sku?.tier
      // })
      console.log('SKU List',this.skuList)
      this.performanceTier=[]
      this.skuList.map(e=>{
        // if(e.active){
        //   this.performanceTier.push({
        //     name:`${e.size} - ${e.capabilities[2].value} IOPS, ${e.capabilities[4].value} MBps (default)`,
        //     Gib : Number(e.capabilities[0].value),
        //     id:e.size, 
        //   })
        // }else{
          this.performanceTier.push({
            name:`${e.capabilities[0].value} Gib, Premium SSD, (${e.size})`,
            Gib :e.capabilities[0].value,
            IOPS : e.capabilities[4].value,
            MBps : e.capabilities[6].value,
            id:e.size, 
          })
        // }
      })
      this.performanceTier= this.performanceTier.filter(e=>e.id!='P1')
    })
  }


  
}

