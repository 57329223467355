

<ng-container [ngTemplateOutlet]="currentTemplate"></ng-container>

<!-- Template 1 -->
<ng-template #virtualMachine>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':virtualMachinemodel.publicIPShow||virtualMachinemodel.newDiskShow||virtualMachinemodel.nsgShow||virtualMachinemodel.availabilitySetShow||virtualMachinemodel.storageAccountShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4><span *ngIf="virtualMachinemodel.resourceType=='SQLVM'">SQL </span><span *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachine'">Linux </span>Virtual Machine</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="virtualMachinemodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div> 
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="VNetName" class="control-label ">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="VNetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="virtualMachinemodel.resourceData.vnetName" name="VirtualNetworkName" disabled>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="SubnetName" class="control-label ">Subnet<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="SubnetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="virtualMachinemodel.resourceData.subnet" name="SubnetName" disabled>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMname" class="control-label">Virtual machine name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="All virtual machines in a subscription must have the distinct name." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar || inputError?.VMOnlyNUmbers || inputError?.duplicateVnet" id="VMname" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceName" placeholder="Enter virtual machine name" name="projectName" (keyup)="VmNameValidation($event,65)" required>
                    <p class="text-danger" *ngIf="inputError?.VMname">The value must be between 1 and 64 characters long.</p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Azure resource names cannot contain special characters \/""[]:|<>+=;,?*@&,
                        whitespace, or begin with '_' or end with '.' or '-'</p>
                    <p class="text-danger" *ngIf="inputError?.VMOnlyNUmbers">Virtual machine name cannot contain only numbers.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateVnet">This name is already taken.</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Virtual machine size<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select a VM size to support the workload that you want to run. The size that you choose then determines factors such as processing power, memory, and storage capacity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" *ngIf="virtualMachinemodel.resourceData&&virtualMachinemodel.resourceData.selectedVMSize" style="font-size: 12px;font-weight: 400;">
                        <span style="font-size: 13px;font-weight: 600;">{{virtualMachinemodel?.resourceData?.selectedVMSize?.name}}</span>
                        <br>
                          <span> {{virtualMachinemodel?.resourceData?.selectedVMSize?.capabilities[2]?.value}}</span> CPU -
                          <span> {{virtualMachinemodel?.resourceData?.selectedVMSize?.capabilities[5]?.value}}</span> RAM 
                          <span *ngIf="virtualMachinemodel?.resourceData?.selectedVMSize?.newCapabilities?.UncachedDiskIOPS?.value">- {{virtualMachinemodel?.resourceData?.selectedVMSize?.newCapabilities?.UncachedDiskIOPS?.value}} Max IOPS</span>
                    </div> 
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="triggerBtn('vmSize')">Choose size</p> 
                </div>            
                </div> 
                <div class="form-group" style="margin-bottom: 10px !important;"  *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachine'">
                    <div class="label-div">
                        <label for="authType" class="control-label ">Authentication type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose whether the administrator account will use 
                            username/password or SSH keys for authentication." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box radio-flex">
                        <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="authType" [value]="'ssh'" ([ngModel])="virtualMachinemodel.resourceData.authType" [checked]="virtualMachinemodel.resourceData.authType=='ssh'" (change)="virtualMachinemodel.resourceData.authType=$event.target.value;"><span>SSH</span></label>
                        <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="authType" [value]="'password'" ([ngModel])="virtualMachinemodel.resourceData.authType" [checked]="virtualMachinemodel.resourceData.authType=='password'" (change)="virtualMachinemodel.resourceData.authType=$event.target.value;"><span>Password</span></label>                                    
                    </div>             
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="adminUsername" class="control-label">Admin username<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The administrator username for the VM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VMAdminReserv || inputError?.VmAdminSpecialChar || inputError?.VMAdminUsername" id="adminUsername" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.adminUsername" placeholder="" name="adminUsername" (keyup)="VmAdminUsernameValidation($event,20)">
                    <p class="text-danger" *ngIf="inputError?.VMAdminReserv">Usernames must not include reserved words.</p>
                    <p class="text-danger" *ngIf="inputError?.VmAdminSpecialChar">Username must only contain letters, numbers, hyphens, and underscores and may not start with a hyphen or number.</p>
                    <p class="text-danger" *ngIf="inputError?.VMAdminUsername">The value is in between 1 and 20 characters long.</p>
                    
                    </div>              
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceType!='linuxVirtualMachine'||virtualMachinemodel.resourceData.authType=='password'">
                    <div class="label-div">
                        <label for="adminPassword" class="control-label">Admin password<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The administrator password for the VM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="password" class="form-control" [class.border-danger]="inputError?.VMAdminPassword || inputError?.VmAdminPasswordLen" id="adminPassword" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.adminPassword" placeholder="" name="adminPassword" (keyup)="VmAdminPasswordValidation($event,123,12)">
                    <p class="text-danger" *ngIf="inputError?.VMAdminPassword">Password must have 3 of the following: 1 lower case character, 1 upper case character, 1 number, and 1 special character.</p>
                    <p class="text-danger" *ngIf="inputError?.VmAdminPasswordLen">The value must be between 12 and 123 characters long.</p>
                    </div>              
                </div>
                <div class="form-group"  *ngIf="virtualMachinemodel.resourceType!='linuxVirtualMachine'||virtualMachinemodel.resourceData.authType=='password'">
                    <div class="label-div">
                        <label for="adminConfirmPassword" class="control-label">Confirm password<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="password" class="form-control" [class.border-danger]="inputError?.VMAdminPasswordMatch" id="adminConfirmPassword" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.adminConfirmPassword" placeholder="" name="adminConfirmPassword" (keyup)="VMAdminConfirmPassword($event)">
                    <p class="text-danger" *ngIf="inputError?.VMAdminPasswordMatch">Password and confirm password must match.</p>
                    </div>              
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachine'&&virtualMachinemodel.resourceData.authType=='ssh'">
                    <div class="label-div">
                        <label for="sshPublicKey" class="control-label">SSH public key source<span></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="sshPublicKeyList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.sshPublicKey" name="sshPublicKey" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachine'&&virtualMachinemodel.resourceData.authType=='ssh'&&virtualMachinemodel.resourceData.sshPublicKey=='genNewKeyPair'">
                    <div class="label-div">
                        <label for="keyPairName" class="control-label">Key pair name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.keyPairNameSpChar||inputError?.keyPairNameDup" id="keyPairName" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.keyPairName" placeholder="" name="keyPairName" (keyup)="keyPairNameValidation($event)" required>
                        <p class="text-danger" *ngIf="inputError?.keyPairNameSpChar">Azure resource names cannot contain special characters \/""[]:|<>+=;,?*@&,
                            whitespace, or begin with '_' or end with '.' or '-'</p>
                        <p class="text-danger" *ngIf="inputError?.keyPairNameDup">SSH key pair name must be unique.</p>
                        </div>    
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachine'&&virtualMachinemodel.resourceData.authType=='ssh'&&virtualMachinemodel.resourceData.sshPublicKey=='useExistKeyAzure'">
                    <div class="label-div">
                        <label for="storedKeys" class="control-label">Stored Keys<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="storedKeysList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.storedKeys" name="storedKeys" placeholder="" [clearable]="false"></ng-select>  
                    </div>
                </div>
                <div class="form-group" *ngIf="virtualMachinemodel.resourceType=='linuxVirtualMachine'&&virtualMachinemodel.resourceData.authType=='ssh'&&virtualMachinemodel.resourceData.sshPublicKey=='useExistPublicKey'">
                    <div class="label-div">
                        <label for="publicKey" class="control-label">SSH public key<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provide an RSA public key in the single-line format (starting with ssh-rsa\) 
                            or the multi-line PEM format. You can generate SSH keys using ssh-keygen on Linux 
                            and OS X, or PuTTYGen on Windows." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <textarea class="form-control" [class.border-danger]="inputError?.publicKeyValid||inputError?.sshKeyBase64Valid" id="publicKey" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.publicKey" placeholder="" name="publicKey" (keyup)="publicKeyValidation($event)" required></textarea>
                        <p class="text-danger" *ngIf="inputError?.publicKeyValid">Multiline SSH key must begin with '---- BEGIN SSH2 PUBLIC KEY ----' 
                            and end with '---- END SSH2 PUBLIC KEY ----'.</p>
                        <p class="text-danger" *ngIf="inputError?.sshKeyBase64Valid">SSH key must be encoded in base64.</p>
                        </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="virtualMachinemodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
                <div *ngIf="showView">
                    <div class="form-group">
                        <div class="label-div">
                            <label for="availabilityOption" class="control-label">Availablity options<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="An Availability Zone is a physically separate zone, within an Azure region. 
                                An availability set is a logical grouping of VMs that allows Azure to 
                                understand how your application is built to provide for redundancy
                                 and availability." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="virtualMachinemodel.resourceData.availabilityOptionList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.availabilityOption" name="availabilityOption" placeholder="Choose" [clearable]="false" (change)="changeAvailablity()" ></ng-select>
                        </div>   
                    </div>
                    <div class="form-group" *ngIf="virtualMachinemodel.resourceData.availabilityOption=='availabilityZone'">
                        <div class="label-div">
                            <label for="availabilityZone" class="control-label">Availablity zone<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can optionally specify an availability zone in which to deploy your VM. 
                                If you choose to do so, your managed disk and public IP (if you have one) 
                                will be created in the same availability zone as your virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="virtualMachinemodel.resourceData.tempAvailabilityZoneList" bindLabel="name" bindValue="name" [(ngModel)]="virtualMachinemodel.resourceData.availabilityZone" name="availabilityZone" placeholder="" [clearable]="false" (change)="changeAvailablity()" ></ng-select>
                        </div>   
                    </div>
                    <div class="form-group" *ngIf="virtualMachinemodel.resourceData.availabilityOption=='availabilitySet'">
                        <div class="label-div">
                            <label for="availabilitySet" class="control-label">Availablity set<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="An availability set is a logical grouping of VMs that allows Azure to understand how your application is built to provide for redundancy and availability. We recommended that two or more VMs are created within an availability set to provide for a highly available application and to meet the 99.95% Azure SLA" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="availabilitySetList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.availabilitySet" name="availabilitySet" placeholder="" [clearable]="false"></ng-select>
                        </div> 
                        <div class="add-another-tag">
                            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewVmAS()">Create new</p>  
                        </div>  
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="image" class="control-label">Image<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the base operating system or application for the VM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="imageList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.image" name="image" placeholder="" [clearable]="false"></ng-select>
                        </div>   
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="osDiskType" class="control-label">OS disk type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can choose between Azure managed disks 
                                types to support your workload or scenario." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="osDiskTypeList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.osDiskType" name="osDiskType" placeholder="Choose" [clearable]="false"></ng-select>
                        </div>   
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="osDiskType" class="control-label">Disk encryption type</label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="diskEncryptionTypeList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.diskEncryptionType" name="osDiskType" placeholder="Choose" (change)="changeEncryptionType('newVm')" [clearable]="false"></ng-select>
                        </div>   
                    </div>
                    <div class="form-group" *ngIf="virtualMachinemodel.resourceData.diskEncryptionType!='1'">
                        <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                            <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                            <label class="" style="padding: 8px 0px !important;color: #703501;">
                                Once a customer-managed key is used, 
                                you can't change the selection back to 
                                a platform-managed key.</label>
                        </div>  
                    </div>
                    <div class="form-group" *ngIf="virtualMachinemodel.resourceData.diskEncryptionType!='1'">
                        <div class="label-div">
                            <label for="diskEncryptionSet" class="control-label">Disk encryption set<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="diskEncryptionSetList" bindLabel="name" bindValue="id" [(ngModel)]="virtualMachinemodel.resourceData.diskEncryptionSet" name="diskEncryptionSet" placeholder="" [clearable]="false"></ng-select>
                        </div>   
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="diskName" class="control-label">Data disks</label>
                        </div>
                        <div class="label-div d-flex" *ngIf="virtualMachinemodel.resourceData.newDiskList&&virtualMachinemodel.resourceData.newDiskList.length>0">
                          <label class="control-label " style="min-width: max-content;width: 90px;">LUN</label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Name
                          </label>
                        </div>
                        <div *ngFor="let item of virtualMachinemodel.resourceData.newDiskList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;width: 74px;">
                              <input type="text" class="form-control" id="LUNNum#{{i}}" autocomplete="off" [class.border-danger]="item?.maxLUNNumLen || item?.VMOnlyNUmbers || item?.validEmpty" [(ngModel)]="item.LUNNum" name="LUNNum#{{i}}" (keyup)="checkLUNNumInput(item,64,i)">
                            </div>
      
                            <div style="padding-right: 30px;"></div>
      
                            <div class="input-box" style="width: 52%">
                                <!-- <ng-select [items]="virtualMachinemodel.resourceData.newDiskList" [closeOnSelect]="true" bindLabel="name" [(ngModel)]="item.diskName" name="diskName" placeholder="Choose" [clearable]="false">
                                </ng-select> -->
                                <label class="control-label " style="padding-left: 6.5px !important;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                padding-top: 4px !important;">{{item.diskName}}
                                </label>
                            </div>
      
                            <span><img src="/assets/icons/edit.png" (click)="editDisk(i)"></span>
                            <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" (click)="deleteDisk(i)"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxLUNNumLen">LUN must be less than 64.</p>
                          <p class="text-danger" *ngIf="item.VMOnlyNUmbers">Value can be numbers only</p>
                          <p class="text-danger" *ngIf="item.validEmpty">Value can't be empty</p>
                        </div>
      
                        <div class="add-another-tag">
                            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewDisk()">Create and attach a new disk</p>             
                        </div>
                      </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="publicIp" class="control-label">Public IP<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use a public IP address if you want to communicate
                                with the virtual machine from outside the virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="tempVmPublicIPList" [closeOnSelect]="true" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.publicIp" name="publicIp" placeholder="Choose" [clearable]="false">
                            </ng-select>
                        </div> 
                        <div class="add-another-tag">
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewPublicIP()">Create new</p>  
                    </div>
                    </div>
                    <div class="form-group" style="margin-bottom: 10px !important;">
                        <div class="label-div">
                            <label for="NIC" class="control-label ">NIC network security group<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A network security group contains security rules that allow or deny inbound 
                                network traffic to, or outbound network traffic from, the virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box radio-flex">
                            <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="NIC" [value]="'None'" ([ngModel])="virtualMachinemodel.resourceData.nicNSG" [checked]="virtualMachinemodel.resourceData.nicNSG=='None'" (change)="virtualMachinemodel.resourceData.nicNSG=$event.target.value;"><span>None</span></label>
                            <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="NIC" [value]="'Basic'" ([ngModel])="virtualMachinemodel.resourceData.nicNSG" [checked]="virtualMachinemodel.resourceData.nicNSG=='Basic'" (change)="virtualMachinemodel.resourceData.nicNSG=$event.target.value;"><span>Basic</span></label>                    
                            <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="NIC" [value]="'Advanced'" ([ngModel])="virtualMachinemodel.resourceData.nicNSG" [checked]="virtualMachinemodel.resourceData.nicNSG=='Advanced'" (change)="virtualMachinemodel.resourceData.nicNSG=$event.target.value;"><span>Advanced</span></label>                    
                        </div>             
                    </div>
                    <div class="form-group" *ngIf="virtualMachinemodel.resourceData.nicNSG=='Advanced'">
                        <div class="label-div">
                            <label for="VMNSG" class="control-label">Network Security Group<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <ng-select class="margin-select" [items]="nsgList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.networkSecurityGroup" name="VMNSG" placeholder="Choose Network Security Group" [clearable]="false"></ng-select>
                        </div>
                        <div class="add-another-tag">   
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewNSG()">Create new</p>
                    </div>
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label class="control-label text-weight">Monitoring</label>
                            <label for="bootDiagnostics" class="control-label ">Boot diagnostics<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use this feature to troubleshoot boot failures for custom or platform images." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box radio-flex">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;padding-bottom: 16px;"><input type="radio" name="bootDiagnostics" [value]="'EWMSA'" ([ngModel])="virtualMachinemodel.resourceData.bootDiagnostics" [checked]="virtualMachinemodel.resourceData.bootDiagnostics=='EWMSA'" (change)="virtualMachinemodel.resourceData.bootDiagnostics=$event.target.value;"><span>Enable with managed storage account (recommended)</span></label>
                            <label class="col-sm-11 radio-label" style="padding-left: 0px !important;"><input type="radio" name="bootDiagnostics" [value]="'EWCSA'" ([ngModel])="virtualMachinemodel.resourceData.bootDiagnostics" [checked]="virtualMachinemodel.resourceData.bootDiagnostics=='EWCSA'" (change)="virtualMachinemodel.resourceData.bootDiagnostics=$event.target.value;"><span>Enable with custom storage account</span></label>                    
                            <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="bootDiagnostics" [value]="'Disable'" ([ngModel])="virtualMachinemodel.resourceData.bootDiagnostics" [checked]="virtualMachinemodel.resourceData.bootDiagnostics=='Disable'" (change)="virtualMachinemodel.resourceData.bootDiagnostics=$event.target.value;"><span>Disable</span></label>                    
                        </div>             
                    </div>
                    <div class="form-group" style="margin-bottom: 10px !important;">
                        <div class="label-div">
                        </div>
                        <div class="input-box">
                            <label class="col-sm-10 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enableOSGuestDiagnostics" id="enableOSGuestDiagnostics" [(ngModel)]="virtualMachinemodel.resourceData.enableOSGuestDiagnostics" ><span>Enable OS guest diagnostics<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use your corporate Active Directory credentials to log in to the VM, 
                                enforce MFA, and enable access via RBAC roles." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>             
                    </div>
                    <div class="form-group" *ngIf="virtualMachinemodel?.resourceData?.enableOSGuestDiagnostics||virtualMachinemodel.resourceData.bootDiagnostics=='EWCSA'">
                        <div class="label-div">
                            <label for="DSA" class="control-label">Diagnostics storage account<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Get metrics every minute for your virtual machine. You can 
                                use them to create alerts and stay informed on your applications." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="storageAccountList" bindLabel="name" [(ngModel)]="virtualMachinemodel.resourceData.diagnosticsStorageAccount" name="DSA" placeholder="Choose" [clearable]="false"></ng-select>
                        </div>   
                        <div class="add-another-tag">   
                            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewStorageAcount()">Create new</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label class="control-label text-weight">Identity</label>
                        </div> 
                        <div class="input-box">
                            <label class="col-sm-11 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="systemAssignedManagedIdentity" id="systemAssignedManagedIdentity" [disabled]="virtualMachinemodel.resourceData.loginWithAzureAD" [(ngModel)]="virtualMachinemodel.resourceData.systemAssignedManagedIdentity" ><span>System assigned managed identity<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A system assigned managed identity enables Azure resources to
                                authenticate to cloud services (e.g. Azure Key Vault) without storing
                                credentials in code. Once enabled, all necessary permissions can be
                                granted via Azure role-based access control. The lifecycle of this type 
                                of managed identity is tied to the lifecycle of this resource. Additionally,
                                each resource (e.g. Virtual Machine) can only have one system 
                                assigned managed identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="label-div  d-flex" *ngIf="virtualMachinemodel.resourceData.loginWithAzureAD" style="background: #F4FBFF;border: 1px solid #4AB5FB;box-sizing: border-box;border-radius: 2px;margin: 10px 0px;">
                            <label class="" style="padding: 11.33px 9.33px 0px 9.33px !important;"><img
                                src="/assets/Infra/blueprint/popup-error-info.png" alt=""></label>
                            <label style="font-size: 12px;color: #21262C;padding: 8px 12px 0px 0px;">System managed identity must be on to login with Azure AD credentials.</label>
                          </div>           
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label class="control-label text-weight">Azure AD</label>
                        </div> 
                        <div class="input-box">
                            <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="loginWithAzureAD" id="loginWithAzureAD" [disabled]="disabledLoginAD()" [(ngModel)]="virtualMachinemodel.resourceData.loginWithAzureAD" (change)="virtualMachinemodel.resourceData.loginWithAzureAD?virtualMachinemodel.resourceData.systemAssignedManagedIdentity=true:''" ><span>Login with Azure AD<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use your corporate Active Directory credentials to log in 
                                to the VM, enforce MFA, and enable access via RBAC roles." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>  
                        <div class="label-div  d-flex" *ngIf="!disabledLoginAD()"  style="background: #F4FBFF;border: 1px solid #4AB5FB;box-sizing: border-box;border-radius: 2px;">
                            <label class="" style="padding: 11.33px 9.33px 0px 9.33px !important;"><img
                                src="/assets/Infra/blueprint/popup-error-info.png" alt=""></label>
                            <label style="font-size: 12px;color: #21262C;padding: 8px 12px 0px 0px;">RBAC role assignment of Virtual Machine Administrator Login or Virtual Machine 
                                User Login is required when using Azure AD login.</label>
                          </div>   
                          <div class="label-div d-flex" *ngIf="disabledLoginAD()" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                            <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                            <label class="" style="padding: 8px 0px !important;color: #703501;">
                                This image does not support Login with Azure AD</label>
                        </div>       
                    </div>
                    <div class="form-group" style="margin-bottom: 10px !important;">
                        <div class="label-div">
                            <label class="control-label text-weight">Auto-shutdown</label>
                        </div> 
                        <div class="input-box">
                            <label class="col-sm-8 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="autoShutdownStatus" id="autoShutdownStatus" [(ngModel)]="virtualMachinemodel.resourceData.autoShutdownStatus" ><span>Enable auto-shutdown<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures your virtual machine to automatically shutdown daily." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>           
                    </div>
                    <div class="form-group"  *ngIf="virtualMachinemodel.resourceData.autoShutdownStatus">
                        <div class="label-div">
                            <label for="autoShutdownTime" class="control-label">Shutdown time<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures the time when the virtual machine shuts down daily." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                        </div> 
                        <div class="input-box">
                            <input type="text" class="form-control" [class.border-danger]="inputError?.validTimeFormat" id="autoShutdownTime" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.autoShutdownTime" placeholder="h:mm:ss A" name="autoShutdownTime" (keyup)="autoShutdownTimeValidation($event)" required>
                        <p class="text-danger" *ngIf="inputError?.validTimeFormat">Time format must match 'h:mm:ss A'.</p>
                        </div>    
                    </div>
                    <div class="form-group"  *ngIf="virtualMachinemodel.resourceData.autoShutdownStatus">
                        <div class="label-div">
                            <label for="timeZone" class="control-label">Time zone<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures the time zone in which the auto-shutdown time is." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                        </div> 
                        <div class="input-box">
                            <ng-select class="margin-select" [items]="timeZoneList" bindLabel="time" bindValue="name" [(ngModel)]="virtualMachinemodel.resourceData.timeZone" name="timeZone" placeholder="" [clearable]="false"></ng-select>
                        </div>         
                    </div>
                    <div class="form-group" *ngIf="virtualMachinemodel.resourceData.autoShutdownStatus" style="margin-bottom: 10px !important;">
                        <div class="input-box">
                            <label class="col-sm-10 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="autoShutdownNotificationStatus" id="autoShutdownNotificationStatus" [(ngModel)]="virtualMachinemodel.resourceData.autoShutdownNotificationStatus" ><span>Notification before shutdown<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Subscribe for notifications before the virtual machine is 
                                shutdown so that you can skip/delay the shutdown." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>           
                    </div>
                    <div class="form-group" *ngIf="virtualMachinemodel.resourceData.autoShutdownStatus&&virtualMachinemodel.resourceData.autoShutdownNotificationStatus">
                        <div class="label-div">
                            <label for="emailVM" class="control-label">Email<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The email address where notifications will be sent." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                        </div> 
                        <div class="input-box">
                            <input type="email" class="form-control" [class.border-danger]="emailVM?.errors?.email||emailVM?.invalid" id="emailVM" autocomplete="off" [(ngModel)]="virtualMachinemodel.resourceData.email" placeholder="" name="emailVM" email #emailVM="ngModel" required>
                        <p class="text-danger" *ngIf="emailVM?.errors?.email||emailVM?.invalid">Please provide a valid email address.</p>
                        </div>          
                    </div>
                    <div class="form-group">

                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="virtualMachinemodel.resourceData&&virtualMachinemodel.resourceData.tagList&&virtualMachinemodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of virtualMachinemodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="virtualMachinemodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="virtualMachinemodel.publicIPShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Public IP</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.publicIPShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="publicIPObj?.nameLen || publicIPObj?.nameValid || publicIPObj?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="publicIPObj.name" placeholder="" name="name" (keyup)="vmPublicIPValidation($event,80)" required>
                    <p class="text-danger" *ngIf="publicIPObj?.nameLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="publicIPObj?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="publicIPObj?.nameDupCheck">This public IP is already there in subscription</p>
                    </div>              
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="SKU" class="control-label ">SKU<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Standard: Always use static allocation method.&#13;Basic: Assigned with the static or dynamic allocation method." matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="SKU" [value]="'Basic'" ([ngModel])="publicIPObj.SKU" [checked]="publicIPObj.SKU=='Basic'" (change)="changeSKU($event)"><span>Basic</span></label>
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="SKU" [value]="'Standard'" ([ngModel])="publicIPObj.SKU" [checked]="publicIPObj.SKU=='Standard'" (change)="changeSKU($event)"><span>Standard</span></label>                    
                    </div>             
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="assignment" class="control-label ">Assignment</label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;" *ngIf="publicIPObj.SKU=='Basic'"><input type="radio" name="assignment" [value]="'Dynamic'" ([ngModel])="publicIPObj.assignment" [checked]="publicIPObj.assignment=='Dynamic'" (change)="publicIPObj.assignment=$event.target.value;"><span>Dynamic</span></label>
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="assignment" [value]="'Static'" ([ngModel])="publicIPObj.assignment" [checked]="publicIPObj.assignment=='Static'" (change)="publicIPObj.assignment=$event.target.value;"><span>Static</span></label>                    
                    </div>             
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="publicIPObj.SKU=='Standard'">
                    <div class="label-div">
                        <label for="routingPreference" class="control-label ">Routing preference<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure routing preference enables you to choose how your traffic routes between Azure and the Internet. You can choose to route traffic either via the Microsoft network, or, via the ISP network (public internet)" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="routingPreference" [value]="'microsoftNetwork'" ([ngModel])="publicIPObj.routingPreference" [checked]="publicIPObj.routingPreference=='microsoftNetwork'" (change)="publicIPObj.routingPreference=$event.target.value;"><span>Microsoft network</span></label>
                        <label class="col-sm-3 radio-label" style="padding-left: 0px !important;"><input type="radio" name="routingPreference" [value]="'Internet'" ([ngModel])="publicIPObj.routingPreference" [checked]="publicIPObj.routingPreference=='Internet'" (change)="publicIPObj.routingPreference=$event.target.value;"><span>Internet</span></label>                    
                    </div>             
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('publicIp')" (click)="saveSubSideSheet('publicIp')">Ok</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="virtualMachinemodel.newDiskShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Create a new disk</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.newDiskShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="newDiskObj?.nameLen || newDiskObj?.nameValid || newDiskObj?.duplicateName" id="name" autocomplete="off" [(ngModel)]="newDiskObj.name" placeholder="" name="name" (keyup)="vmDiskNameValidation($event,80)" required>
                    <p class="text-danger" *ngIf="newDiskObj?.nameLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="newDiskObj?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="newDiskObj?.duplicateName">This disk is already there in subscription</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="sourceType" class="control-label">Source type<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Create a disk from a snapshot of another disk, 
                            a blob in a storage account, or create an empty disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="sourceTypeList" bindLabel="name" bindValue="name" [(ngModel)]="newDiskObj.sourceType" name="sourceType" placeholder="Choose" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group" *ngIf="newDiskObj.sourceType=='Snapshot'">
                    <div class="label-div">
                        <label for="snapshot" class="control-label">Snapshot<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a snapshot from another disk to use as the source of this new disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="snapshotList" bindLabel="name" [(ngModel)]="newDiskObj.snapshot" name="snapshot" placeholder="Choose" [clearable]="false" (change)="changeSanpshot(newDiskObj)" ></ng-select>
                    </div>   
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="size" class="control-label">Size<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box" style="font-size: 12px;font-weight: 400;" *ngIf="newDiskObj.newVMDiskSize">
                        <span style="font-size: 13px;font-weight: 600;">{{newDiskObj?.newVMDiskSize?.customDiskSize}} GiB</span>
                        <br>
                        <span>{{newDiskObj?.newVMDiskSize?.diskSKU?.name}} LRS</span>
                    </div> 
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="triggerBtn('newDisk')">{{newDiskObj?.newVMDiskSize?.customDiskSize?"Change ":"Choose "}}size</p>  
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="encryptionType" class="control-label">Encryption type<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="diskEncryptionTypeList" bindLabel="name" bindValue="id" [(ngModel)]="newDiskObj.encryptionType" name="encryptionType" placeholder="Choose" (change)="changeEncryptionType('newDisk')" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group" *ngIf="newDiskObj.encryptionType!='1'">
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                        <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                        <label class="" style="padding: 8px 0px !important;color: #703501;">
                            Once a customer-managed key is used, 
                            you can't change the selection back to 
                            a platform-managed key.</label>
                    </div>  
                </div>
                <div class="form-group" *ngIf="newDiskObj.encryptionType!='1'">
                    <div class="label-div">
                        <label for="diskEncryptionSet" class="control-label">Disk encryption set<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="diskEncryptionSetListForDisk" bindLabel="name" bindValue="id" [(ngModel)]="newDiskObj.diskEncryptionSet" name="diskEncryptionSet" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="esd" class="control-label ">Enable shared disk<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to be attached to two or more virtual machines, 
                            depending on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'Yes'" [disabled]="disabledEnableDiskShare(newDiskObj)" ([ngModel])="newDiskObj.enableSharedDisk" [checked]="newDiskObj.enableSharedDisk=='Yes'" (change)="newDiskObj.enableSharedDisk=$event.target.value;changeMaxShareList($event,newDiskObj)"><span>Yes</span></label>
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'No'" [disabled]="disabledEnableDiskShare(newDiskObj)" ([ngModel])="newDiskObj.enableSharedDisk" [checked]="newDiskObj.enableSharedDisk=='No'" (change)="newDiskObj.enableSharedDisk=$event.target.value;changeMaxShareList($event,newDiskObj)"><span>No</span></label>                    
                    </div>  
                    <!-- <p class="text-muted-msg" *ngIf="newDiskObj?.newVMDiskSize?.selectVmDiskSizeData&&(newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.name!='Premium_LRS'||newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.capabilities[6]?.value==1)">Shared disk not available for the selected size.</p>            -->
                </div>
                <div class="form-group" *ngIf="newDiskObj?.newVMDiskSize?.selectVmDiskSizeData&&(newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.name!='Premium_LRS'||newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.capabilities[6]?.value==1)">
                    <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                        <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                        <label class="" style="padding: 8px 0px !important;color: #703501;">
                            Shared disk not available for the selected size.</label>
                    </div>  
                </div>
                <div class="form-group" *ngIf="newDiskObj.enableSharedDisk=='Yes'">
                    <div class="label-div">
                        <label for="maxShares" class="control-label">Max shares<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The number of max shares a shared disk has 
                            depends on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="maxSharesList" bindLabel="name" bindValue="name" [(ngModel)]="newDiskObj.maxShares" name="maxShares" placeholder="Select disk share" [clearable]="false"></ng-select>
                    </div>   
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="newDiskObj.updateIndex==undefined" [class.disabled]="checkDataDisabled('newDisk')" (click)="saveSubSideSheet('newDisk')">Ok</button>
                    <button class="btn btn-btn-primary" *ngIf="newDiskObj.updateIndex!=undefined" [class.disabled]="checkDataDisabled('newDisk')" (click)="updateSubSideSheet('newDisk')">Update</button>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="virtualMachinemodel.nsgShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Create network security group</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.nsgShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="vmNSGObj.name" placeholder="" name="name" (keyup)="checkNSGName($event,80)" required>
                    <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="inputError?.nameDupCheck">This network security group is already there in subscription</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="inboundRules" class="control-label">Inbound rules<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add rules to allow or deny traffic to this virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" style="font-size: 12px;font-weight: 400;">
                        <div style="border-left: solid 2px #2989E9;padding-left: 8px;margin-bottom: 8px;" *ngFor="let item of vmNSGObj.vmInBoundRule;let i=index">
                            <span>{{item?.priority}}</span>: <span>{{item?.name}}</span>
                            <span class="pull-right" style="cursor: pointer;" (click)="deleteRules('inboundRules',i)"><img src="/assets/icons/delete_new.png" alt=""></span>
                            <span class="pull-right" style="padding-right: 11.33px;cursor: pointer;" (click)="editRules('inboundRules',i)"><img src="/assets/icons/edit.png" alt=""></span>
                            <br>
                            <span>{{item?.source}}</span>
                            <br>
                            <span>{{item?.service}}</span><span>({{item?.protocol}}/{{item?.destinationPortRange}})</span>
                        </div>
                    </div>      
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="vmNSGObj.updateIndex=undefined;triggerBtn('inboundRules')">Add an inbound rule</p>  
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="outboundRules" class="control-label">Outbound rules<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add rules to allow or deny traffic originating from this virtual machine." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" style="font-size: 12px;font-weight: 400;">
                        <div style="border-left: solid 2px #2989E9;padding-left: 8px;margin-bottom: 8px;" *ngFor="let item of vmNSGObj.vmOutBoundRule;let i=index">
                            <span>{{item?.priority}}</span>: <span>{{item?.name}}</span>
                            <span class="pull-right" style="cursor: pointer;" (click)="deleteRules('outboundRules',i)"><img src="/assets/icons/delete_new.png" alt=""></span>
                            <span class="pull-right" style="padding-right: 11.33px;cursor: pointer;" (click)="editRules('outboundRules',i)"><img src="/assets/icons/edit.png" alt=""></span>                            
                            <br>
                            <span>{{item?.source}}</span>
                            <br>
                            <span>{{item?.service}}</span><span>({{item?.protocol}}/{{item?.destinationPortRange}})</span>
                        </div>
                    </div>  
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="vmNSGObj.updateIndex=undefined;triggerBtn('outboundRules')">Add an outbound rule</p>  
                    </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('nsg')" (click)="saveSubSideSheet('nsg')">Ok</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="virtualMachinemodel.availabilitySetShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Availability set</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.availabilitySetShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="vmASObj.name" placeholder="" name="name" (keyup)="checkVmASName($event,80)" required>
                    <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="inputError?.nameDupCheck">This availability set is already there in subscription</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="faultDomains" class="control-label">Fault domains<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the same fault domain share 
                            a common power source and physical network switch." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="number" class="form-control remove-arrows" id="faultDomains" [class.border-danger]="inputError?.asFaultDomainsLen||inputError?.asFaultDomainsMaxLen" autocomplete="off" [(ngModel)]="vmASObj.faultDomains" placeholder="" name="faultDomains" (keyup)="checkfaultDomains($event,vmASObj)" required>
                    <p class="text-danger" *ngIf="inputError?.asFaultDomainsLen">Fault domains must be at least 1</p>
                    <p class="text-danger" *ngIf="inputError?.asFaultDomainsMaxLen">Fault domains can not be at more than 3
              </p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="updateDomains" class="control-label">Update domains<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the same update domain will be restarted 
                            together during planned maintenance. Azure never restarts 
                            more than one update domain at a time" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="number" class="form-control remove-arrows" [class.border-danger]="inputError?.asUpdateDomainsLen || inputError?.asUpdateDomainsMaxLen" [disabled]="vmASObj.faultDomains==1" id="updateDomains" autocomplete="off" [(ngModel)]="vmASObj.updateDomains" placeholder="" name="updateDomains" (keyup)="checkUpdateDomains($event,20)" required>
                    <p class="text-danger" *ngIf="inputError?.asUpdateDomainsLen">Update domains must be at least 1</p>
                    <p class="text-danger" *ngIf="inputError?.asUpdateDomainsMaxLen">Update domains can not be at more than 20</p>
                    <p class="text-muted-msg" *ngIf="vmASObj.faultDomains==1">The update domain count must be 1 when fault domain count is 1.</p>
                    </div>              
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('vmASObj')" (click)="saveSubSideSheet('vmASObj')">Ok</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="virtualMachinemodel.storageAccountShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Storage Account</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();virtualMachinemodel.storageAccountShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName || inputError?.storageAccountChar || inputError?.duplicateSa" id="storageAccountName" autocomplete="off" [(ngModel)]="vmStorageAccount.name" placeholder="Enter storage account name" name="StorageAccountName" (keyup)="storageAccountNameValidation($event,3,24);" required>
                        <p class="text-danger" *ngIf="inputError?.storageAccountName">The name must be between 3 and 24 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.storageAccountChar">The field can contain only lowercase letters and numbers.</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateSa">The storage account name is already taken.</p>
                    </div>                
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="kind" class="control-label">Account kind<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="General purpose storage accounts provide storage for blobs, 
                            files, tables, and queues in a unified account." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="accountKindList" bindLabel="name" bindValue="id" [(ngModel)]="vmStorageAccount.kind" name="kind" (change)="filterStorageSkus(vmStorageAccount.performance,vmStorageAccount.kind);" placeholder="" [clearable]="false">
                        </ng-select>
                    </div>   
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="performance" class="control-label ">Performance<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Standard storage accounts are backed by magnetic drives 
                            and provide the lowest cost per GB. " matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="performance" [value]="'Standard'" ([ngModel])="vmStorageAccount.performance" [disabled]="true" [checked]="vmStorageAccount.performance=='Standard'" (change)="vmStorageAccount.performance=$event.target.value;filterStorageSkus(vmStorageAccount.performance,vmStorageAccount.kind);"><span>Standard</span></label>
                        <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="performance" [value]="'Premium'" ([ngModel])="vmStorageAccount.performance" [disabled]="true" [checked]="vmStorageAccount.performance=='Premium'" (change)="vmStorageAccount.performance=$event.target.value;filterStorageSkus(vmStorageAccount.performance,vmStorageAccount.kind);"><span>Premium</span></label>
                    </div>             
                </div>
        
                <div class="form-group">
                    <div class="label-div">
                        <label for="redundancy" class="control-label">Replication<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The data in your Azure storage account is always replicated 
                            to ensure durability and high availability. " matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="tempStorageSkusList" bindLabel="rdname" bindValue="name" [(ngModel)]="vmStorageAccount.redundancy" name="redundancy" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('vmStorageAccount')" (click)="saveSubSideSheet('vmStorageAccount')">Ok</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualMachine')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="loader-fs" *ngIf="apiloader">
        <img class="loader"  src="./assets/spinloader.gif">
      </div>
</ng-template>

   
<ng-template #virtualNetwork>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-disabled':show}">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Virtual Network</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box no-margin">
                        <ng-select class="margin-select" [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="VNetmodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <!-- <div [ngStyle]="{'width':item?.resourceGroupId?'84%':'auto'}"> -->
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" style="padding-top: 2px;" #anchor
                        (click)="onToggle()">Create new</p>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMname" class="control-label ">Enter virtual network name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual networks are logically isolated from each other in Azure. You can configure their IP address ranges, subnets, route tables, gateways, and security settings, much like a traditional network in your data center. Virtual machines in the same virtual network can access each other by default." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar" id="VNetName" autocomplete="off" [(ngModel)]="VNetmodel.resourceName" placeholder="Enter virtual network name" name="VirtualNetworkName" (keyup)="VNetNameValidation($event,80)" required>
                        <p class="text-danger" *ngIf="inputError?.VNetName">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateVnet">This name is already taken.</p>
                        <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label ">Virtual network address space<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The virtual network's address space specified as one or more address prefixes in CIDR notation (e.g. 10.0.0.0/16)." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box" *ngFor="let item of VNetmodel.resourceData.virtualNetworkAddressSpace;let i = index">
                        <div class="d-flex">
                            <input type="text" class="form-control" [class.border-danger]="item.VNetAdrspc" id="VNetAdrSpc" autocomplete="off" [(ngModel)]="item.address" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace" (keyup)="VNetAddressSpace($event,item,i)" required>
                            <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete.png" (click)="deleteAddress(VNetmodel,i)" *ngIf="VNetmodel.resourceData.virtualNetworkAddressSpace.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
                        <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use {{item.validCIDRValue}} address space</p>
                        <p class="text-danger" *ngIf="item.overlooping">overlaps with address space {{item.overlapingValue}}</p>
                        <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
                    </div> 
                    <div class="add-another-tag"><p class="blue-text cursor-pointer lbl-btn" (click)="addNewAddress(VNetmodel)">Add another address space</p></div>
                </div> 
                <div class="form-group">

                  <div class="label-div d-flex">
                    <label class="control-label " style="min-width: max-content;">Tag name </label>
                    <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                    </label>
                    <label style="padding-left: 6px !important;width: 18px;"
                      *ngIf="VNetmodel.resourceData&&VNetmodel.resourceData.tagList&&VNetmodel.resourceData.tagList.length>1"></label>
                    <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                  </div>
                  <div *ngFor="let item of VNetmodel.resourceData.tagList;let i = index">
                    <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                      <div class="input-box" style="padding-bottom: 6px;">
                        <input type="text" class="form-control" id="tagName" autocomplete="off"
                          [(ngModel)]="item.tagName" name="tagName" (keyup)="checkTagInput(item,512,i)">
                      </div>

                      <div style="padding-left: 6px;padding-right: 6px;">:</div>

                      <div class="input-box">
                        <input type="text" class="form-control" id="tagValue" autocomplete="off"
                          [(ngModel)]="item.value" name="tagValue" (keyup)="checkTagInput(item,256)">
                      </div>

                      <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTag(i)"
                          *ngIf="VNetmodel.resourceData.tagList.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                      512.</p>
                    <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                      <>%&\?/.</p>
                    <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                    <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                      is 256.</p>
                  </div>

                  <div  class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" (click)="addTag()">Add another tag</p>
                  </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label ">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="VNetmodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('virtualNetwork')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('virtualNetwork')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
       
</ng-template> 


<ng-template #subnet>
    <div class="sidebar-right">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Subnet</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="VNetName" class="control-label ">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" id="VNetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="subnetModel.resourceData.vnetName" name="VirtualNetworkName" disabled>
                    </div>              
                </div>
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="subnetType" class="control-label ">Subnet type</label>
                    </div>
                    <div class="input-box d-flex">
                        <!-- col-sm-6 d-flex"> -->
                        <!-- <div class="box-radio full-width">
                            <input type="radio" value="subnet" [(ngModel)]="subnetModel.resourceData.subnetType" checked>
                            <label for="subnet" >Subnet</label>
                            <input type="radio" value="gatewaySubnet" [(ngModel)]="subnetModel.resourceData.subnetType">
                            <label for="gatewaySubnet" >Gateway subnet</label>
                        </div>                   -->
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="subnetType" [value]="'subnet'" ([ngModel])="subnetModel.resourceData.subnetType" [checked]="subnetModel.resourceData.subnetType=='subnet'" (change)="selectedSubnetType($event)" ><span>Subnet</span></label>
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="subnetType" [value]="'gatewaySubnet'" ([ngModel])="subnetModel.resourceData.subnetType" [checked]="subnetModel.resourceData.subnetType=='gatewaySubnet'" (change)="selectedSubnetType($event)"><span>GatewaySubnet</span></label>                    
                    </div>             
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMname" class="control-label col-sm-2">Subnet name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A subnet is a range of IP addresses in your virtual network, which can be used to isolate virtual machines from each other or from the Internet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.subnetName || inputError?.subnetChar" id="SubnetName" autocomplete="off" [disabled]="subnetModel.resourceData.subnetType=='gatewaySubnet'" [(ngModel)]="subnetModel.resourceName" placeholder="Enter subnet name" name="SubnetName" (keyup)="subnetNameValidation($event,80)" required>
                        <p class="text-danger" *ngIf="inputError?.subnetName">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.subnetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                        <p class="text-danger" *ngIf="inputError?.subnetDuplicate">Subnet names must be unique within a virtual network.</p>
                      </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label col-sm-2">Subnet address space<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The subnet's address range in CIDR notation (e.g. 192.168.1.0/24). 
                            It must be contained by the address space of the virtual network. 
                            The address range of a subnet which is in use can't be edited." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.subnetAdrspc" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="subnetModel.resourceData.subnetAddressSpace" placeholder="eg. 10.0.0.0/24" name="SubnetAddressSpace" (keyup)="subnetAddressSpace($event)" required>
                        <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                        <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid CIDR Block.</p>
                        <p class="text-danger" *ngIf="inputError?.subnetOverlaping">This subnet address space overlaps with existing {{subnetModel.overlapingValue}}</p>
                        <p class="text-danger" *ngIf="inputError?.subnetPerfix">The prefix must be smaller than or equal to 29</p>
                        <p class="text-danger" *ngIf="inputError?.subnetAddresNotInVnet">Subnet address range should contained under same virtual network's address spaces</p>
                    </div>              
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label col-sm-2">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.subetDescription" [(ngModel)]="subnetModel.comments" placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.subetDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div>
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
                <div *ngIf="showView">
                    <div class="form-group">
                        <div class="label-div">
                            <label for="NAT" class="control-label">NAT gateway<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="NAT gateway associated to this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select class="margin-select" [items]="natGetwaysList" bindLabel="name" bindValue="id" [(ngModel)]="subnetModel.resourceData.natGatway" name="NAT" placeholder="Choose NAT Gateway" [clearable]="false"></ng-select>
                        </div>   
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="NSG" class="control-label">Network Security Group</label>
                        </div>
                        <div class="input-box">
                            <ng-select class="margin-select" [items]="nsgList" bindLabel="name" bindValue="id" [disabled]="subnetModel.resourceData.subnetType=='gatewaySubnet'" [(ngModel)]="subnetModel.resourceData.networkSecurityGroup" name="NSG" placeholder="Choose Network Security Group" [clearable]="false"></ng-select>
                        </div>   
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="routeTable" class="control-label">Route Table</label>
                        </div>
                        <div class="input-box">
                            <ng-select class="margin-select" [items]="routeTablesList" bindLabel="name" bindValue="id" [(ngModel)]="subnetModel.resourceData.routeTable" name="routeTable" placeholder="Choose route table" [clearable]="false"></ng-select>
                        </div>   
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="Services" class="control-label">Services<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enable one or more service endpoints for this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                        </div>
                        <div class="input-box margin-multi-select">
                            <ng-select class="" [items]="servicesList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" (change)="validateServiceWithDelegate()" [(ngModel)]="subnetModel.resourceData.services" name="Services" placeholder="Choose Services" [clearable]="false">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                                </ng-template>
                            </ng-select>
                        </div>      
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label for="DSService" class="control-label">Delegate subnet to a service<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Designate a subnet to be used by a dedicated service." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select class="margin-select" [items]="dsServiceList" bindLabel="serviceName"  [(ngModel)]="subnetModel.resourceData.dsService" (change)="validateServiceWithDelegate()" name="DSService" placeholder="Choose Delegate subnet" [clearable]="false"></ng-select>
                            <p class="text-danger" *ngIf="inputError?.dsServiceCheck">Service endpoints cannot be used in a subnet with the selected delegation.</p>
                        </div>   
                    </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('subnet')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('subnet')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>

</ng-template>


<ng-template #azureADDomain>
    <div class="sidebar-right">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Azure AD Domain Services</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMname" class="control-label col-sm-2">DNS domain name<span class="mandatory"> * <img src="/assets/Infra/info.svg" alt="" matTooltip="Enter the name of a domain that your organization owns" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.azureADDomainName" id="SubnetName" autocomplete="off" [(ngModel)]="azureADDomainModel.resourceName" placeholder="Enter DNS domain name" name="SubnetName" (keyup)="azureADDomainNameValidation($event,15)" required>
                        <p class="text-danger" *ngIf="inputError?.azureADDomainName">The prefix cannot be longer than 15 characters.</p>
                      </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label col-sm-2">Description </label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.azureADDomainDescription" [(ngModel)]="azureADDomainModel.comments" placeholder="Enter a reason for adding this resource..." (keyup)="azureADDomainDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.azureADDomainDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('azureADDomain')" (click)="triggerBtn()">Save</button>
                </div>
            </div>
        </div>
    </div>

</ng-template>


<ng-template #windowsVirtualDesktop>
    <div class="sidebar-right">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Windows Virtual Desktop</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMname" class="control-label ">Windows virtual desktop<span class="mandatory"> * </span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" id="VMname" autocomplete="off" [(ngModel)]="WVDmodel.resourceName" placeholder="Enter windows virtual desktop name" name="projectName" required>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="WVDsize" class="control-label">Virtual machine size<span class="mandatory"> * <img src="/assets/Infra/info.svg" alt="" matTooltip="Select a VM size to support the workload that you want to run. The size that you choose then determines factors such as processing power, memory, and storage capacity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select class="form-control" [items]="VmSizes" bindLabel="value" bindValue="value" [(ngModel)]="WVDmodel.resourceData.VmSize" name="WVDsize" placeholder="Choose virtual machine size" [clearable]="false" required></ng-select>
                    </div>   
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="NumberVm" class="control-label">Number of VMs<span class="mandatory"> * </span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.WVDsize" id="VMname" autocomplete="off" [(ngModel)]="WVDmodel.resourceData.numberOfVMs" placeholder="Enter number of VMs" name="projectName" (keyup)="WvdVmNumberValidation($event,500)" required>
                        <p class="text-danger" *ngIf="inputError?.WVDsize">The value must be between 0 and 500.</p>
                    </div>           
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description <span class="mandatory"></span></label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.WVDdescription" [(ngModel)]="WVDmodel.comments" placeholder="Enter a reason for adding this resource..." (keyup)="WVDdescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.WVDdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('windowsVirtualDesktop')" (click)="triggerBtn()">Save</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #networkInterface>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="form-control" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-disabled':show}">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Network Interfaces</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="networkInterfacemodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 8px;"><i class="fa fa-trash" (click)="deleteNewResourceGroup(item,index)"></i></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 25px;"><img src="/assets/Infra/blueprint/palette/edit.svg" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="NIname" class="control-label ">Network interface name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar || inputError?.duplicateNi" id="VNetName" autocomplete="off" [(ngModel)]="networkInterfacemodel.resourceName" placeholder="Enter network interface name" name="VirtualNetworkName" (keyup)="VNetNameValidation($event,80);niDuplicateCheck($event)" required>
                        <p class="text-danger" *ngIf="inputError?.VNetName">The length must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateNi">There is already a resource with the same name and type within the current resource group.</p>
                        <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>              
                </div>
                
                    <div class="form-group">
                        <div class="label-div">
                            <label for="VNetName" class="control-label ">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" id="VNetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="networkInterfacemodel.resourceData.vnetName" name="VirtualNetworkName" disabled>
                        </div>              
                    </div>
                  
                
                    <div class="form-group">
                        <div class="label-div">
                            <label for="subnetName" class="control-label ">Subnet<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <input type="text" class="form-control" id="subnetName" autocomplete="off" [class.disabled]="true" [(ngModel)]="networkInterfacemodel.resourceData.subnet" name="subnetName" disabled>
                        </div>              
                    </div>
                    
                
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="privateIPAddr" class="control-label ">Private IP address assignment</label>
                    </div>
                    <div class="input-box d-flex">
                        <!-- col-sm-6 d-flex"> -->
                        <!-- <div class="box-radio full-width">
                           <input type="radio" value="subnet" [(ngModel)]="subnetModel.resourceData.subnetType" checked>
                           <label for="subnet" >Subnet</label>
                           <input type="radio" value="gatewaySubnet" [(ngModel)]="subnetModel.resourceData.subnetType">
                            <label for="gatewaySubnet" >Gateway subnet</label>
                        </div>                   -->
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="privateIPAddr" [value]="'Dynamic'" ([ngModel])="networkInterfacemodel.resourceData.privateIPAddr" [checked]="networkInterfacemodel.resourceData.privateIPAddr=='Dynamic'" (change)="networkInterfacemodel.resourceData.privateIPAddr=$event.target.value;assignPrivateIPToDynamicNIC()" ><span>Dynamic</span></label>
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="privateIPAddr" [value]="'Static'" ([ngModel])="networkInterfacemodel.resourceData.privateIPAddr" [checked]="networkInterfacemodel.resourceData.privateIPAddr=='Static'" (change)="networkInterfacemodel.resourceData.privateIPAddr=$event.target.value;assignPrivateIPToDynamicNIC()"><span>Static</span></label>                    
                    </div>             
                </div>
                <div class="form-group" *ngIf="networkInterfacemodel.resourceData.privateIPAddr=='Static'">
                    <div class="label-div">
                        <label for="privateIPAddrSpace" class="control-label col-sm-2">Private IP address</label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.nicValidIPError || inputError?.nicRegisterIPError || inputError?.nicAddrUsedError || !inputError?.nicSubnetRangeError" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="networkInterfacemodel.resourceData.privateIPAddrSpace" placeholder="" name="privateIPAddrSpace" (keyup)="nicPrivateIpAddress(networkInterfacemodel)" required>
                        <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                        <p class="text-danger" *ngIf="inputError?.nicValidIPError">Invalid IP address</p>
                        <p class="text-danger" *ngIf="inputError?.nicRegisterIPError">The IP address falls within the reserved IP range of subnet</p>
                        <p class="text-danger" *ngIf="inputError?.nicAddrUsedError">IP is already used</p>
                        <p class="text-danger" *ngIf="!inputError?.nicSubnetRangeError">The IP must be in the subnet</p>
                    </div>              
                </div> 
                
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Network security group name<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use network security tools to control access to the network interface." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="nsgList" bindLabel="name" bindValue="id" [(ngModel)]="networkInterfacemodel.resourceData.nsg" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                        </ng-select>
                    </div>  
                       </div>
                 
                
                <div class="form-group">

                  <div class="label-div d-flex">
                    <label class="control-label " style="min-width: max-content;">Tag name </label>
                    <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                    </label>
                    <label style="padding-left: 6px !important;width: 18px;"
                      *ngIf="networkInterfacemodel.resourceData&&networkInterfacemodel.resourceData.tagList&&networkInterfacemodel.resourceData.tagList.length>1"></label>
                    <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                  </div>
                  <div *ngFor="let item of networkInterfacemodel.resourceData.tagList;let i = index">
                    <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                      <div class="input-box" style="padding-bottom: 6px;">
                        <input type="text" class="form-control" id="tagName" autocomplete="off"
                          [(ngModel)]="item.tagName" name="tagName" (keyup)="checkTagInput(item,512,i)">
                      </div>

                      <div style="padding-left: 6px;padding-right: 6px;">:</div>

                      <div class="input-box">
                        <input type="text" class="form-control" id="tagValue" autocomplete="off"
                          [(ngModel)]="item.value" name="tagValue" (keyup)="checkTagInput(item,256)">
                      </div>

                      <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                          *ngIf="networkInterfacemodel.resourceData.tagList.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                      512.</p>
                    <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                      <>%&\?/.</p>
                    <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                    <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                      is 256.</p>
                  </div>

                  <div  class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                  </div>
                </div>
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label ">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="networkInterfacemodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('networkInterface')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('networkInterface')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>       
</ng-template> 

<ng-template #publicIP>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':virtualMachinemodel.publicIPShow||virtualMachinemodel.newDiskShow||virtualMachinemodel.nsgShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Public IP</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="publicIPmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div> 
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="publicIP" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar" id="VMname" autocomplete="off" [(ngModel)]="publicIPmodel.resourceName" placeholder="Enter Public IP name" name="projectName" (keyup)="VmNameValidation($event,65)" required>
                    <p class="text-danger" *ngIf="inputError?.VMname">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens. </p>
                    </div>              
                </div>

                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="sku" class="control-label ">SKU<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Basic: Assigned with the static or 'dynamic allocation' method.&#13;Standard: Always use static allocation method." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="sku" [value]="'Basic'" ([ngModel])="publicIPmodel.resourceData.sku" [checked]="publicIPmodel.resourceData.sku=='Basic'" (change)="publicIPmodel.resourceData.sku=$event.target.value;publicIPodel.resourceData.IPAddr='Dynamic'" ><span>Basic</span></label>
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="sku" [value]="'Standard'" ([ngModel])="publicIPmodel.resourceData.sku" [checked]="publicIPmodel.resourceData.sku=='Standard'" (change)="publicIPmodel.resourceData.sku=$event.target.value;publicIPodel.resourceData.IPAddr='Static'"><span>Standard</span></label>                    
                    </div>             
                </div>
            
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="publicIPmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
        <div *ngIf="showView">

            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="tier" class="control-label ">Tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="tier" [value]="'Regional'" [disabled]="disabledTier()" ([ngModel])="publicIPmodel.resourceData.tier" [checked]="publicIPmodel.resourceData.tier=='Regional'" (change)="publicIPmodel.resourceData.tier=$event.target.value" ><span>Regional</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="tier" [value]="'Global'" [disabled]="disabledTier()" ([ngModel])="publicIPmodel.resourceData.tier" [checked]="publicIPmodel.resourceData.tier=='Global'" (change)="publicIPmodel.resourceData.tier=$event.target.value"><span>Global</span></label>                    
                </div>             
            </div>

                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="IPAddr" class="control-label ">IP address assignment</label>
                    </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPAddr" [value]="'Dynamic'" [disabled]="publicIPmodel.resourceData.sku=='Standard'" ([ngModel])="publicIPmodel.resourceData.IPAddr" [checked]="publicIPmodel.resourceData.IPAddr=='Dynamic'" (change)="publicIPmodel.resourceData.IPAddr=$event.target.value" ><span>Dynamic</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPAddr" [value]="'Static'" [disabled]="publicIPmodel.resourceData.sku=='Standard'" ([ngModel])="publicIPmodel.resourceData.IPAddr" [checked]="publicIPmodel.resourceData.IPAddr=='Static'" (change)="publicIPmodel.resourceData.IPAddr=$event.target.value"><span>Static</span></label>                    
                </div>             
            </div>

            <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="publicIPmodel.resourceData.sku=='Standard'">
                <div class="label-div">
                    <label for="rp" class="control-label ">Routing Preference<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Determines how your traffic routes between Azure and the internet. Selecting Microsoft global network delivers traffic via Microsoft global network closest to user. Internet route option uses transit ISP network. Egress data transfer price varies based on the routing selection. Routing preference option of a Public IP can't be changed once created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="rp" [value]="'microsoftNetwork'" [disabled]="publicIPmodel.resourceData.tier=='Global'" ([ngModel])="publicIPmodel.resourceData.rp" [checked]="publicIPmodel.resourceData.rp=='microsoftNetwork'" (change)="publicIPmodel.resourceData.rp=$event.target.value" ><span>Microsoft network</span></label>
                    <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="rp" [value]="'internet'" [disabled]="publicIPmodel.resourceData.tier=='Global'" ([ngModel])="publicIPmodel.resourceData.rp" [checked]="publicIPmodel.resourceData.rp=='internet'" (change)="publicIPmodel.resourceData.rp=$event.target.value;publicIPmodel.resourceData.availabilityZone='Zone-redundant'"><span>Internet</span></label>                    
                </div>             
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="idleTimeout" class="control-label">Idle timeout (minutes)<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Keep a TCP or HTTP connection open without relying on clients to send keep-alive messages." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.limitidletimeout||inputError?.limitidletimeoutNum" id="idleTimeout" autocomplete="off" [(ngModel)]="publicIPmodel.resourceData.idleTimeout" placeholder="4" name="idleTimeout" (keyup)="changeIdleTimeout($event)">
                <p class="text-danger" *ngIf="inputError?.limitidletimeout">The value must be between 4 and 30 minutes.</p>
                <p class="text-danger" *ngIf="inputError?.limitidletimeoutNum">Value must be a valid number.</p>
                </div>              
            </div>

            <div class="form-group">
                <div class="label-div">
                    <label for="dns" class="control-label">DNS name label<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="An 'A Record' that starts with the specified label and resolves to this Public IP address will be registered with the Azure-provided DNS servers.&#013;Example: mylabel.westus.cloudapp.azure.com." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.dnsname || inputError?.dnsSpecialChar" id="dns" autocomplete="off" [(ngModel)]="publicIPmodel.resourceData.dns" placeholder="" name="dns">
                <span class="dns-helper-label">.{{publicIPmodel?.resourceData?.dnslocation}}.cloudapp.azure.com</span>
                <p class="text-danger" *ngIf="inputError?.dnsname">The domain name is invalid. It can contain only lowercase letters, numbers and hyphens. The first character must be a letter. The last character must be a letter or number.</p>
                <p class="text-danger" *ngIf="inputError?.dnsChar">The value must be between 3 and 63 characters long.</p>
                <p class="text-danger" *ngIf="inputError?.dnslabel">The domain name label is invalid. It must confirm to the following 
                    regular expression.</p>
                </div>              
            </div>

            <div class="form-group" *ngIf="publicIPmodel.resourceData.tier!=='Global'">
            <div class="label-div">
                <label for="availabilityZone" class="control-label">Availablity zone<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can optionally specify an availability zone in which to deploy your Public IP address. Choose Zone-Redundant to create a zone-redundant data path." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="azIPList" bindLabel="name" bindValue="name" [disabled]="publicIPmodel.resourceData.sku=='Standard' && publicIPmodel.resourceData.rp=='internet' " [disabled]="publicIPmodel.resourceData.sku=='Basic'" [(ngModel)]="publicIPmodel.resourceData.availabilityZone" name="availabilityZone" placeholder="" [clearable]="false"></ng-select>
            </div>   
        </div>
                   
                    <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="publicIPmodel.resourceData&&publicIPmodel.resourceData.tagList&&publicIPmodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of publicIPmodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="publicIPmodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('publicIPmodel')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('publicIPmodel')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>
    
</ng-template>


<!-- <ng-template #storageAccount>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
                <div class='content vnet-resource-content'>
                    <div class="rgPopup-form">
                        <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                        <div class="input-box">
                        <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                        <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                        <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                        </div>              
                    </div>
                    <div class="rgPopup-form">
                        <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                        <div class="input-box">
                        <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                        </ng-select>
                        </div>
                    </div>
                    <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                        You don't have the access to create a new resource group for this subscription
                    </div>
                    <div class="d-flex">
                        <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                        <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                        <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
                    </div>
                </div>
     </kendo-popup>
 <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':virtualMachinemodel.publicIPShow||virtualMachinemodel.newDiskShow||virtualMachinemodel.nsgShow}">
                <div>
                    <div class="header">
                        <div class="d-flex">
                           <h4>Storage Account</h4>
                           <div class="text-right full-width">
                            <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                              <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                            </button>
                          </div>
                        </div>
                    </div>
                    <div class="body">
                        <div class="form-group">
                            <div class="label-div">
                                <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                            </div>
                            <div class="input-box">
                                <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="publicIPmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        {{item.name}}
                                        <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                        <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                                    </ng-template>
                                </ng-select>
                            </div>  
                            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new</p>        
                        </div> 
            
                <div class="form-group">
                    <div class="label-div">
                        <label for="storageAccountName" class="control-label col-sm-2">Storage account name<span class="mandatory"> * <img src="/assets/Infra/info.svg" alt="" matTooltip="The name must be unique across all existing storage account names in Azure. It must be 3 to 24 characters long, and can contain only lowercase letters and numbers." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName || inputError?.storageAccountChar" id="storageAccountName" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceName" placeholder="Enter storage account name" name="StorageAccountName" (keyup)="storageAccountNameValidation($event,3,24)" required>
                        <p class="text-danger" *ngIf="inputError?.storageAccountName">The name must be between 3 and 24 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.storageAccountChar">The field can contain only lowercase letters and numbers.</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label col-sm-2">Description </label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.storageAccountDescription" [(ngModel)]="storageAccountmodel.comments" placeholder="Enter a reason for adding this resource..." (keyup)="storageAccountDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.storageAccountDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
        <div *ngIf="showView">
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="Performance" class="control-label ">Performance</label>
                </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPAddr" [value]="'Dynamic'" [disabled]="publicIPmodel.resourceData.sku=='Standard'" ([ngModel])="publicIPmodel.resourceData.IPAddr" [checked]="publicIPmodel.resourceData.IPAddr=='Dynamic'" (change)="publicIPmodel.resourceData.IPAddr=$event.target.value" ><span>Dynamic</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPAddr" [value]="'Static'" [disabled]="publicIPmodel.resourceData.sku=='Standard'" ([ngModel])="publicIPmodel.resourceData.IPAddr" [checked]="publicIPmodel.resourceData.IPAddr=='Static'" (change)="publicIPmodel.resourceData.IPAddr=$event.target.value"><span>Static</span></label>                    
            </div>             
         </div>


        </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('storageAccount')" (click)="triggerBtn()">Save</button>
                </div>
 </div>
        
    
</ng-template> -->

<ng-template #storageAccount>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Storage Account</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="storageAccountmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="storageAccountName" class="control-label col-sm-2">Storage account name<span class="mandatory"> * <img src="/assets/Infra/info.svg" alt="" matTooltip="The name must be unique across all existing storage account names in Azure. It must be 3 to 24 characters long, and can contain only lowercase letters and numbers." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName || inputError?.storageAccountChar || inputError?.duplicateSa" id="storageAccountName" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceName" placeholder="Enter storage account name" name="StorageAccountName" (keyup)="storageAccountNameValidation($event,3,24);" required>
                        <p class="text-danger" *ngIf="inputError?.storageAccountName">The name must be between 3 and 24 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.storageAccountChar">The field can contain only lowercase letters and numbers.</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateSa">The storage account name is already taken.</p>
                    </div>              
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label col-sm-2">Description </label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.storageAccountDescription" [(ngModel)]="storageAccountmodel.comments" placeholder="Enter a reason for adding this resource..." (keyup)="storageAccountDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.storageAccountDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>

                <div *ngIf="showView">

                    <div class="form-group" style="margin-bottom: 10px !important;">
                        <div class="label-div">
                            <label for="performance" class="control-label ">Performance<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Premium: optimized for high transaction rates and single-digit consistent storage latency.Standard: optimized for high capacity and high throughput" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box radio-flex">
                            <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="performance" [value]="'Standard'" ([ngModel])="storageAccountmodel.resourceData.performance" [checked]="storageAccountmodel.resourceData.performance=='Standard'" (change)="storageAccountmodel.resourceData.performance=$event.target.value;storageAccountmodel.resourceData.kind='StorageV2';filterStorageSkus(storageAccountmodel.resourceData.performance,storageAccountmodel.resourceData.kind);"><span><b>Standard</b></span></label>
                            <label class="radio-label" style="padding-left: 19px;">Recommended for most scenarios(general-purpose v2 account)</label>
                            <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="performance" [value]="'Premium'" ([ngModel])="storageAccountmodel.resourceData.performance" [checked]="storageAccountmodel.resourceData.performance=='Premium'" (change)="storageAccountmodel.resourceData.performance=$event.target.value;storageAccountmodel.resourceData.kind='PageBlobStorage';filterStorageSkus(storageAccountmodel.resourceData.performance,storageAccountmodel.resourceData.kind);"><span><b>Premium</b></span></label>                    
                            <label class="radio-label" style="padding-left: 19px;">Recommended for scenarios that require low latency</label>
                        </div>             
                    </div>

                    <div class="form-group" style="margin-bottom: 10px !important;">
                        <div class="label-div">
                            <label for="kind" class="control-label">Account type<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose an account type that matches your storage needs and optimizes your costs" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="storageAccountmodel.resourceData.performance=='Standard'?acntTypeListForStand:acntTypeListForPerm" bindLabel="name" bindValue="id" [disabled]="storageAccountmodel.resourceData.performance=='Standard'" [(ngModel)]="storageAccountmodel.resourceData.kind" name="kind" (change)="filterStorageSkus(storageAccountmodel.resourceData.performance,storageAccountmodel.resourceData.kind);" placeholder="" [clearable]="false">
                                <ng-template ng-label-tmp let-item="item">
                                    {{item?.name}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <b>{{item?.name}} :</b><br>{{item?.discription}}
                                </ng-template>
                            </ng-select>
                        </div>   
                    </div>
            
                    <div class="form-group">
                        <div class="label-div">
                            <label for="redundancy" class="control-label">Redundancy<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The data in your Azure storage account is always replicated to ensure durability and high availability. Choose a replication strategy that matches your durability requirements." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <!-- storageAccountmodel.resourceData.performance=='Standard'?redncyListForStand:storageAccountmodel.resourceData.kind=='PageBlobStorage'?redncyListForPerm:redncyListForOther -->
                            <ng-select [items]="tempStorageSkusList" bindLabel="rdname" bindValue="name" [(ngModel)]="storageAccountmodel.resourceData.redundancy" name="redundancy" placeholder="" [clearable]="false"></ng-select>
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;" *ngIf="storageAccountmodel.resourceData.performance=='Standard'&&(storageAccountmodel.resourceData.redundancy=='GRS'||storageAccountmodel.resourceData.redundancy=='GZRS')"><input type="checkbox" name="readAccessEnabled" id="readAccessEnabled" [(ngModel)]="storageAccountmodel.resourceData.readAccessEnabled" ><span>Make read access to data available in the event of regional availability</span></label>
                        </div>   
                        <!-- <section class="redun-check">
                            <mat-checkbox class="" [color]="'primary'">Make read access to data available in the event of regional availability</mat-checkbox>
                          </section> -->
                    </div>
                    

                      <div class="form-group">
                        <div class="label-div">
                            <label for="Security" class="control-label">Security<span class="mandatory"></span></label>
                        </div>
                        <div class="input-box">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="secureTransferEnable" id="secureTransferEnable" [(ngModel)]="storageAccountmodel.resourceData.secureTransferEnable" ><span>Enable secure transfer<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The secure transfer option enhances the security of your storage account by only allowing requests to the storage account by secure connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="infrastructureEncryptionEnabled" id="infrastructureEncryptionEnabled" [(ngModel)]="storageAccountmodel.resourceData.infrastructureEncryptionEnabled" ><span>Enable infrastructure encryption<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="By default, Azure encrypts storage account data at rest. Infrastructure encryption adds a second layer of encryption to your storage account’s data." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="keyAccessEnabled" id="keyAccessEnabled" [(ngModel)]="storageAccountmodel.resourceData.keyAccessEnabled" ><span>Enable storage account key access<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When storage account key access is disabled, any requests to the account that are authorized with Shared Key, including shared access signatures (SAS), will be denied." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="allowBlobPublicAccess" id="allowBlobPublicAccess" [(ngModel)]="storageAccountmodel.resourceData.allowBlobPublicAccess" ><span>Enable blob public access<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If public access is disallowed for a blob, containers in that blob cannot besubsequently configured for public access." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <!-- <section class="redun-check">
                            <mat-checkbox class="" [color]="'primary'"></mat-checkbox>
                            <mat-checkbox class="" [color]="'primary'"></mat-checkbox>
                            <mat-checkbox class="" [color]="'primary'"></mat-checkbox>
                          </section> -->
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="label-div">
                            <label for="minimumTlsVersion" class="control-label">Minimum TLS version<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set the minimum TLS version needed by applications using your storage account's data." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="minTlsvList" bindLabel="name" bindValue="id" [(ngModel)]="storageAccountmodel.resourceData.minimumTlsVersion" name="minimumTlsVersion" placeholder="" [clearable]="false"></ng-select>
                        </div>   
                    </div>

                    <div class="form-group" style="margin-bottom: 10px !important;">
                        <div class="label-div">
                            <label for="bStorage" class="control-label " style="font-weight: 600;font-size: 13px;margin-bottom: 19px;">Blob storage</label>
                            <label for="accessTier" class="control-label ">Access tier</label>
                        </div>
                        <div class="input-box radio-flex">
                            <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="accessTier" [value]="'Hot'" ([ngModel])="storageAccountmodel.resourceData.accessTier" [checked]="storageAccountmodel.resourceData.accessTier=='Hot'" (change)="storageAccountmodel.resourceData.accessTier=$event.target.value"><span><b>Hot:</b></span></label>
                            <label class="radio-label" style="padding-left: 18px">Frequently accessed data and day-to-day usage scenarios</label>
                            <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="accessTier" [value]="'Cool'" ([ngModel])="storageAccountmodel.resourceData.accessTier" [checked]="storageAccountmodel.resourceData.accessTier=='Cool'" (change)="storageAccountmodel.resourceData.accessTier=$event.target.value"><span><b>Cool:</b></span></label>
                            <label class="radio-label" style="padding-left: 19px">Infrequently accessed data and backup scenarios</label>
                        </div>             
                    </div>

                    <div class="form-group">
                        <div class="label-div">
                            <label for="aFiles" class="control-label">Azure Files<span class="mandatory"></span></label>
                        </div>
                        <div class="input-box">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="largeFileSharesState" id="largeFileSharesState" [(ngModel)]="storageAccountmodel.resourceData.largeFileSharesState" [disabled]="disabledCheckBoxSA('largeFile')" [disabled]="disabledCheckBoxSA('largeFiles')"><span>Enable large file shares<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Provides file share support up to a maximum of 100 TiB." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <!-- <section class="redun-check">
                            <mat-checkbox class="redun-check">Enable secure transfer<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The secure transfer option enhances the security of your storage account by only allowing requests to the storage account by secure connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></mat-checkbox>
                        </section> -->
                    </div>

                    <div class="form-group">
                        <div class="label-div">
                            <label for="tAq" class="control-label">Tables and Queues<span class="mandatory"></span></label>
                        </div>
                        <div class="input-box">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="customerManagedKeysEnabled" id="customerManagedKeysEnabled" [(ngModel)]="storageAccountmodel.resourceData.customerManagedKeysEnabled" ><span>Enable support for customer-managed keys<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use point-in-time restore to restore one or more containers to an earlier state. If point-in-time restore is enabled, then versioning, change feed, and blob soft delete must also be enabled" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <!-- <section class="redun-check">
                            <mat-checkbox class="redun-check"></mat-checkbox>
                          </section> -->
                    </div>

                    <div class="form-group">
                        <div class="label-div">
                            <label for="recovery" class="control-label">Recovery<span class="mandatory"></span></label>
                        </div>
                        <div class="input-box">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isContainerRestoreEnabled" id="isContainerRestoreEnabled" [disabled]="disabledCheckBoxSA('point')" [(ngModel)]="storageAccountmodel.resourceData.isContainerRestoreEnabled" ><span style="margin-right: -16px;">Enable point-in-time restore for containers<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When turned on, enables support for using customer-managed keys (CMKs) to encrypt your tables and queues" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            <div class="form-group" *ngIf="storageAccountmodel?.resourceData?.isContainerRestoreEnabled">
                                <div class="label-div">
                                    <label for="restoredays" class="control-label col-sm-2">Maximum restore point (days ago)<span class="mandatory">*<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip=" Set the oldest possible restore point. For example, if you choose 30 days ago, then the earliest date for a restore on May 31 is May 1." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                                </div>
                                <div class="input-box">
                                    <input type="text" class="form-control" [class.border-danger]="inputError?.restoredays" id="restoredays" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceData.recovery" placeholder="" name="recovery" (keyup)="changeDaysRestore($event,'restoredays')" required>
                                    <p class="text-danger" *ngIf="inputError?.subnetAdrspc">This value must be less than the value (90) that's set for blob soft delete.</p>
                                    <p class="text-danger" *ngIf="inputError?.restoredays">The value must be between 1 and 364.</p>
                                </div>              
                            </div>

                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isContainerSoftDeleteEnabled" id="isContainerSoftDeleteEnabled" [disabled]="disabledCheckBoxSA('soft')" [(ngModel)]="storageAccountmodel.resourceData.isContainerSoftDeleteEnabled" ><span style="margin-right: -16px;">Enable soft delete for containers<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Soft delete enables you to recover containers that were previously marked for deletion." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            <div class="form-group" *ngIf="storageAccountmodel?.resourceData?.isContainerSoftDeleteEnabled">
                                <div class="label-div">
                                    <label for="restoredays" class="control-label col-sm-2">Days to retain deleted containers<span class="mandatory">*<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip="Set the number of days that a container marked for deletion persists until it's permanently deleted." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                                </div>
                                <div class="input-box">
                                    <input type="text" class="form-control" [class.border-danger]="inputError?.shareSoftDeleteRetentionDays" id="shareSoftDeleteRetentionDays" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceData.containerSoftDeleteRetentionDays" placeholder="" name="shareSoftDeleteRetentionDays" (keyup)="checkSoftDelete($event,'shareSoftDeleteRetentionDays')" required>
                                    <p class="text-danger" *ngIf="inputError?.shareSoftDeleteRetentionDays">The value must be between 1 and 365.</p>
                                </div>
                            </div>

                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isBlobSoftDeleteEnabled" id="isBlobSoftDeleteEnabled" [disabled]="disabledCheckBoxSA('soft')" [(ngModel)]="storageAccountmodel.resourceData.isBlobSoftDeleteEnabled" ><span>Enable soft delete for blobs<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Soft delete enables you to recover blobs that were previously marked for deletion." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            <div class="form-group" *ngIf="storageAccountmodel?.resourceData?.isBlobSoftDeleteEnabled">
                                <div class="label-div">
                                    <label for="blobSoftDeleteRetentionDays" class="control-label col-sm-2">Days to retain deleted blobs<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set the number of days that a blob marked for deletion
                                        persists until it's permanently deleted." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                                </div>
                                <div class="input-box">
                                    <input type="text" class="form-control" [class.border-danger]="inputError?.blobSoftDeleteRetentionDays" id="blobSoftDeleteRetentionDays" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceData.blobSoftDeleteRetentionDays" placeholder="" name="blobSoftDeleteRetentionDays" (keyup)="checkSoftDelete($event,'blobSoftDeleteRetentionDays')" required>
                                    <p class="text-danger" *ngIf="inputError?.blobSoftDeleteRetentionDays">The value must be between 1 and 365.</p>
                                </div>              
                            </div>
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isShareSoftDeleteEnabled" id="isShareSoftDeleteEnabled" [disabled]="disabledCheckBoxSA('largeFile')" [(ngModel)]="storageAccountmodel.resourceData.isShareSoftDeleteEnabled" ><span>Enable soft delete for file shares<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Soft delete enables you to recover file shares that were previously marked for deletion" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            <div class="form-group" *ngIf="storageAccountmodel?.resourceData?.isShareSoftDeleteEnabled">
                                <div class="label-div">
                                    <label for="shareSoftDeleteRetentionDays" class="control-label col-sm-2">Days to retain deleted file shares<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set the number of days that a file share marked for 
                                        deletion persists until it's permanently deleted." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                                </div>
                                <div class="input-box">
                                    <input type="text" class="form-control" [class.border-danger]="inputError?.shareSoftDeleteRetentionDays" id="shareSoftDeleteRetentionDays" autocomplete="off" [(ngModel)]="storageAccountmodel.resourceData.shareSoftDeleteRetentionDays" placeholder="" name="shareSoftDeleteRetentionDays" (keyup)="checkSoftDelete($event,'shareSoftDeleteRetentionDays')" required>
                                    <p class="text-danger" *ngIf="inputError?.shareSoftDeleteRetentionDays">The value must be between 1 and 365.</p>
                                </div>              
                            </div>  
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="label-div">
                            <label for="tracking" class="control-label">Tracking<span class="mandatory"></span></label>
                        </div>
                        <div class="input-box">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="isVersioningEnabled" id="isVersioningEnabled" [disabled]="disabledCheckBoxSA('tracking')" [disabled]="disabledCheckBoxSA('trackings')" [(ngModel)]="storageAccountmodel.resourceData.isVersioningEnabled" ><span>Enable versioning for blobs<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use versioning to automatically maintain previous versions of your blobs for recovery and restoration" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="changeFeed" id="changeFeed" [disabled]="disabledCheckBoxSA('tracking')" [disabled]="disabledCheckBoxSA('trackings')" [(ngModel)]="storageAccountmodel.resourceData.changeFeed" ><span>Enable blob change feed<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Keep track of create, modification, and delete changes to blobs in your account" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <!-- <section class="redun-check">
                            <mat-checkbox class="redun-check"></mat-checkbox>
                            <mat-checkbox class="redun-check"></mat-checkbox>
                          </section> -->
                    </div>
                   
                    <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value</label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="storageAccountmodel.resourceData&&storageAccountmodel.resourceData.tagList&&storageAccountmodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of storageAccountmodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="storageAccountmodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <!-- <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('storageAccount')" (click)="triggerBtn()">Save</button>
                </div> -->

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('storageAccount')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('storageAccount')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>   
</ng-template>

<ng-template #logAnalytics>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':virtualMachinemodel.publicIPShow||virtualMachinemodel.newDiskShow||virtualMachinemodel.nsgShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Log Analytics Workspace</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="logAnalyticsmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div> 
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="logAnalytics" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace name should include 4-63 letters, digits or '-'. The '-' shouldn't be the first or the last symbol." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="logAnalyticsmodel.resourceName" placeholder="Enter a workspace name" name="logAnalytics" (keyup)="laNameValidation($event,4,63);lawDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.laname">The workspace name must be between 4 and 63 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.laSpecialChar">The workspace name can contain only letters, numbers and '-'. The '-' shouldn't be the first or the last symbol. </p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Workspace name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateLaw">Workspace name must be unique in the current resource group.</p>
                </div>              
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="sku" class="control-label">Pricing tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The cost of your workspace depends on the pricing tier and what solutions you use. You can change to a Capacity Reservation tier after your workspace is created" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="pTierList" bindLabel="name" bindValue="id" [(ngModel)]="logAnalyticsmodel.resourceData.sku" name="sku" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>

            
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="logAnalyticsmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                
                       <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="logAnalyticsmodel.resourceData&&logAnalyticsmodel.resourceData.tagList&&logAnalyticsmodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of logAnalyticsmodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="logAnalyticsmodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('logAnalytics')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('logAnalytics')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
    </div>
</ng-template>


<ng-template #applicationInsight>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':applicationInsightmodel.lawShow }">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Application Insights</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="applicationInsightmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="applicationInsight" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a unique name for your application." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar || inputError?.duplicateAi" id="applicationInsight" autocomplete="off" [(ngModel)]="applicationInsightmodel.resourceName" placeholder="Enter a name" name="applicationInsight" (keyup)="VmNameValidation($event,255);aiDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.duplicateAi">The name must be unique in the current resource group.</p>
                    <!-- <p class="text-danger" *ngIf="inputError?.duplicateAi">The name must be unique in the current resource group.</p> -->
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar || inputError?.VMname">The name must contain between 1 to 255 characters inclusive. The name only allows alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                    </div>              
                </div>

            
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="applicationInsightmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
        <div *ngIf="showView">
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="resMode" class="control-label ">Resource mode<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose classic or specify a workspace." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="resMode" [value]="'classic'" ([ngModel])="applicationInsightmodel.resourceData.resMode" [checked]="applicationInsightmodel.resourceData.resMode=='classic'" (change)="applicationInsightmodel.resourceData.resMode=$event.target.value;" ><span>Classic</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="resMode" [value]="'workspaceBased'" ([ngModel])="applicationInsightmodel.resourceData.resMode" [checked]="applicationInsightmodel.resourceData.resMode=='workspaceBased'" (change)="applicationInsightmodel.resourceData.resMode=$event.target.value;"><span>Workspace-based</span></label>                    
                </div>             
            </div>

            <div class="form-group" *ngIf="applicationInsightmodel.resourceData.resMode=='workspaceBased'">
            <div class="label-div">
                <label for="logaWorkspace" class="control-label">Log analytics workspace<span class="mandatory">*</span></label> 
            </div>
            <div class="input-box">
                <ng-select [items]="lawList" bindLabel="name" [(ngModel)]="applicationInsightmodel.resourceData.logaWorkspace" name="logaWorkspace" placeholder="" [clearable]="false"></ng-select>
            </div>   
            <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="applicationInsightmodel.lawShow=true;">Create new</p>             
            </div>  
           </div>
        

                   
                    <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="applicationInsightmodel.resourceData&&applicationInsightmodel.resourceData.tagList&&applicationInsightmodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of applicationInsightmodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="applicationInsightmodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('applicationInsight')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('applicationInsight')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="applicationInsightmodel.lawShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Log Analytics Workspace</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="applicationInsightmodel.lawShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
               
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="logAnalytics" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace name should include 4-63 letters, digits or '-'. The '-' shouldn't be the first or the last symbol." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="lawObj.name" placeholder="Enter a workspace name" name="logAnalytics" (keyup)="laNameValidation($event,4,63);lawDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.laname">The workspace name must be between 4 and 63 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.laSpecialChar">The workspace name can contain only letters, numbers and '-'. The '-' shouldn't be the first or the last symbol. </p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Workspace name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateLaw">Workspace name must be unique in the current resource group.</p>
                    </div>              
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="pricingTier" class="control-label">Pricing tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The cost of your workspace depends on the pricing tier and what solutions you use. You can change to a Capacity Reservation tier after your workspace is created" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="pTierList" bindLabel="name" bindValue="id" [(ngModel)]="lawObj.pricingTier" name="pricingTier" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('lawObj')" (click)="saveSubSideSheet('lawObj')">Save</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('lawObj')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
    </div>
</ng-template>

<ng-template #disk>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':virtualMachinemodel.publicIPShow||virtualMachinemodel.newDiskShow||virtualMachinemodel.nsgShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Disks</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="diskmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="disk" class="control-label">Disk name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The identifier for the disk" matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="newDiskObj?.nameLen || newDiskObj?.nameValid || newDiskObj?.duplicateName || inputError?.duplicateDisk" id="disk" autocomplete="off" [(ngModel)]="diskmodel.resourceName" placeholder="" name="name" (keyup)="vmDiskNameValidation($event,80);diskDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="newDiskObj?.nameLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="newDiskObj?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="newDiskObj?.duplicateName">The name must be unique</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateDisk">Disk name must be unique in the current resource group.</p>
                    </div>              
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="sourceType" class="control-label">Source Type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Create a disk from a snapshot of another disk, a blob in a storage account, or create an empty disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="sTypeList" bindLabel="name" bindValue="id" [(ngModel)]="diskmodel.resourceData.sourceType" name="sourceType" placeholder="" [clearable]="false"></ng-select>
                        <!-- <ng-select [items]="sourceTypeList" bindLabel="name" bindValue="name" [(ngModel)]="newDiskObj.sourceType" name="sourceType" placeholder="Choose" [clearable]="false"></ng-select> -->
                    </div>   
                </div>
        
                <div class="form-group" *ngIf="diskmodel.resourceData.sourceType=='snapshot'">
                    <div class="label-div">
                        <label for="snapshot" class="control-label">Source snapshot<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a snapshot from another disk to use as the source of this new disk." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="snapshotList" bindLabel="name" [(ngModel)]="diskmodel.resourceData.snapshot" name="snapshot" placeholder="Choose" [clearable]="false" (change)="changeSanpshot(diskmodel)" ></ng-select>
                    </div>   
                </div>
                

                <div class="form-group">
                    <div class="label-div">
                        <label for="size" class="control-label">Size<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box" style="font-size: 12px;font-weight: 400;" *ngIf="diskmodel.resourceData.newVMDiskSize">
                        <span style="font-size: 13px;font-weight: 600;">{{diskmodel?.resourceData?.newVMDiskSize?.customDiskSize}} GiB</span>
                        <br>
                        <span>{{diskmodel?.resourceData?.newVMDiskSize?.diskSKU?.name}} LRS</span>
                    </div> 
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="triggerBtn('tempDisk')">Choose size</p>  
                    </div>
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="publicIPmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
        <div *ngIf="showView">
            
            <div class="form-group">
            <div class="label-div">
                <label for="availabilityZone" class="control-label">Availablity zone<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can optionally specify an availability zone in which to deploy your Public IP address. Choose Zone-Redundant to create a zone-redundant data path." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="azList" bindLabel="name" bindValue="name" [(ngModel)]="diskmodel.resourceData.availabilityZone" name="availabilityZone" placeholder="" [clearable]="false"></ng-select>
            </div>   
        </div>

        

        <div class="form-group">
            <div class="label-div">
                <label for="encryptionType" class="control-label">Encryption Type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure offers server-side encryption with platform-managed keys by default for managed disks. You may optionally choose to use a customer-managed key." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="diskEncryptionTypeList" bindLabel="name" [(ngModel)]="diskmodel.resourceData.encryptionType" (change)="changeEncryptionType('disk')" name="encryptionType" placeholder="" [clearable]="false"></ng-select>
                <!-- <ng-select [items]="diskEncryptionTypeList" bindLabel="name" bindValue="id" [(ngModel)]="newDiskObj.encryptionType" name="encryptionType" placeholder="Choose" (change)="changeEncryptionType('newDisk')" [clearable]="false"></ng-select> -->
            </div>   
        </div>

        <div class="form-group" *ngIf="diskmodel.resourceData.encryptionType?.name!='(default) Encryption at-rest with a platform-managed key'">
            <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                <label class="" style="padding: 8px 0px !important;color: #703501;">
                    Once a customer-managed key is used, 
                    you can't change the selection back to 
                    a platform-managed key.</label>
            </div>  
        </div>
        <div class="form-group" *ngIf="diskmodel?.resourceData?.encryptionType?.name!='(default) Encryption at-rest with a platform-managed key'">
            <div class="label-div">
                <label for="diskEncryptionSet" class="control-label">Disk encryption set<span class="mandatory">*</span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="diskEncryptionSetListForDisk" bindLabel="name" bindValue="id" [(ngModel)]="diskmodel.resourceData.diskEncryptionSet" name="diskEncryptionSet" placeholder="" [clearable]="false"></ng-select>
            </div>   
        </div>

        <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="esd" class="control-label ">Enable shared disk<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to be attached to two or more virtual machines, depending on storage type and disk size. When shared disk is enabled host caching is unavailable." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'Yes'" [disabled]="disabledEnableDiskShare(diskmodel?.resourceData)" ([ngModel])="diskmodel.resourceData.esd" [checked]="diskmodel.resourceData.esd=='Yes'" (change)="diskmodel.resourceData.esd=$event.target.value;changeMaxShareList($event,diskmodel?.resourceData)"><span>Yes</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'No'" [disabled]="disabledEnableDiskShare(diskmodel?.resourceData)" ([ngModel])="diskmodel.resourceData.esd" [checked]="diskmodel.resourceData.esd=='No'" (change)="diskmodel.resourceData.esd=$event.target.value;changeMaxShareList($event,diskmodel?.resourceData)"><span>No</span></label>                    
            </div>  
            <p class="text-muted-msg" *ngIf="diskmodel?.resourceData?.newVMDiskSize?.selectVmDiskSizeData&&(diskmodel?.resourceData?.newVMDiskSize?.selectVmDiskSizeData?.name!='Premium_LRS'||diskmodel?.resourceData?.newVMDiskSize?.selectVmDiskSizeData?.capabilities[6]?.value==1)">Shared disk not available for the selected size.</p>           
        </div>
        
        <div class="form-group" *ngIf="diskmodel?.resourceData?.esd=='Yes'">
            <div class="label-div">
                <label for="maxShares" class="control-label">Max shares<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The number of max shares a shared disk has 
                    depends on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="maxSharesList" bindLabel="name" bindValue="name" [(ngModel)]="diskmodel.resourceData.maxShares" name="maxShares" placeholder="Select disk share" [clearable]="false"></ng-select>
            </div>   
        </div>

        <!-- <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="esd" class="control-label ">Enable shared disk<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow this disk to be attached to two or more virtual machines, 
                    depending on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'Yes'" [disabled]="disabledEnableDiskShare()" ([ngModel])="newDiskObj.enableSharedDisk" [checked]="newDiskObj.enableSharedDisk=='Yes'" (change)="newDiskObj.enableSharedDisk=$event.target.value;changeMaxShareList($event)"><span>Yes</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="esd" [value]="'No'" [disabled]="disabledEnableDiskShare()" ([ngModel])="newDiskObj.enableSharedDisk" [checked]="newDiskObj.enableSharedDisk=='No'" (change)="newDiskObj.enableSharedDisk=$event.target.value;changeMaxShareList($event)"><span>No</span></label>                    
            </div>  
            <p class="text-muted-msg" *ngIf="newDiskObj?.newVMDiskSize?.selectVmDiskSizeData&&(newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.name!='Premium_LRS'||newDiskObj?.newVMDiskSize?.selectVmDiskSizeData?.capabilities[6]?.value==1)">Shared disk not available for the selected size.</p>           
        </div>
        <div class="form-group" *ngIf="newDiskObj.enableSharedDisk=='Yes'">
            <div class="label-div">
                <label for="maxShares" class="control-label">Max shares<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The number of max shares a shared disk has 
                    depends on storage type and disk size." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="maxSharesList" bindLabel="name" bindValue="name" [(ngModel)]="newDiskObj.maxShares" name="maxShares" placeholder="Select disk share" [clearable]="false"></ng-select>
            </div>   
        </div> -->

                   
                    <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="diskmodel.resourceData&&diskmodel.resourceData.tagList&&diskmodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of diskmodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="diskmodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('disk')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('disk')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #nsg>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':virtualMachinemodel.publicIPShow||virtualMachinemodel.newDiskShow||virtualMachinemodel.nsgShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Network Security Group</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="nsgmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck" id="name" autocomplete="off" [(ngModel)]="nsgmodel.resourceName" placeholder="" name="name" (keyup)="checkNSGName($event,80)" required>
                    <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    <p class="text-danger" *ngIf="inputError?.nameDupCheck">NSG name must be unique in the current resource group.</p>
                    </div>              
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="inboundRules" class="control-label">Inbound rules<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add rules to allow or deny incoming traffic." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" style="font-size: 12px;font-weight: 400;">
                        <div style="border-left: solid 2px #2989E9;padding-left: 8px;margin-bottom: 8px;" *ngFor="let item of nsgmodel.resourceData.vmInBoundRule;let i=index">
                            <span>{{item?.priority}}</span>: <span>{{item?.name}}</span>
                            <span class="pull-right"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteRules('inboundRules',i)"></span>
                            <span class="pull-right" style="padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editRules('inboundRules',i)"></span>
                            <br>
                            <span>{{item?.source}}</span>
                            <br>
                            <span>{{item?.service}}</span><span>({{item?.protocol}}/{{item?.destinationPortRange}})</span>
                        </div>
                    </div>      
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="nsgmodel.resourceData.updateIndex=undefined;triggerBtn('inboundRules')">Add an inbound rule</p>  
                    </div>
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="outboundRules" class="control-label">Outbound rules<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Add rules to allow or deny outgoing traffic." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" style="font-size: 12px;font-weight: 400;">
                        <div style="border-left: solid 2px #2989E9;padding-left: 8px;margin-bottom: 8px;" *ngFor="let item of nsgmodel.resourceData.vmOutBoundRule;let i=index">
                            <span>{{item?.priority}}</span>: <span>{{item?.name}}</span>
                            <span class="pull-right"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteRules('outboundRules',i)"></span>
                            <span class="pull-right" style="padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editRules('outboundRules',i)"></span>                            
                            <br>
                            <span>{{item?.source}}</span>
                            <br>
                            <span>{{item?.service}}</span><span>({{item?.protocol}}/{{item?.destinationPortRange}})</span>
                        </div>
                    </div>  
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="nsgmodel.resourceData.updateIndex=undefined;triggerBtn('outboundRules')">Add an outbound rule</p>  
                    </div>
                </div>
                
                <div class="form-group">
                    <div class="label-div d-flex">
                      <label class="control-label " style="min-width: max-content;">Tag name </label>
                      <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                      </label>
                      <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="nsgmodel.resourceData&&nsgmodel.resourceData.tagList&&nsgmodel.resourceData.tagList.length>1"></label>
                      <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                    </div>
                    <div *ngFor="let item of nsgmodel.resourceData.tagList;let i = index">
                      <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
  
                        <div class="input-box" style="padding-bottom: 6px;">
                          <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
  
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
  
                        <div class="input-box">
                          <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
  
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="nsgmodel.resourceData.tagList.length>1"></span>
                      </div>
                      <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                      <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                      <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                      <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
  
                    <div class="add-another-tag">
                      <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                  </div>
                
            
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="nsgmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('nsgsr')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('nsgsr')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #availabilitySet>
  <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
    <div class='content vnet-resource-content'>
      <div class="rgPopup-form">
        <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
        <div class="input-box">
          <input type="text" class="form-control" id="rgName" autocomplete="off"
            [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)"
            placeholder="Enter name" name="rgName" required>
          <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90
            characters</p>
          <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already
            exists in the selected subscription.</p>
          <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric
            characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
        </div>
      </div>
      <div class="rgPopup-form">
        <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
        <div class="input-box">
          <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value"
            [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation"
            placeholder="Choose resource group location" [clearable]="false" required>
          </ng-select>
        </div>
      </div>
      <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
        You don't have the access to create a new resource group for this subscription
      </div>
      <div class="d-flex">
        <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()"
          [class.disabled]="checkDisable('rg-popup')">Save</button>
        <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit"
          (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
        <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
      </div>
    </div>
  </kendo-popup>
  <div class="sidebar-right">
    <div>
      <div class="header">
        <div class="d-flex">
          <h4>Availability set</h4>
          <div class="text-right full-width">
            <button type="button" class="close pull-right" aria-label="Close" (click)="close();">
              <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
            </button>
          </div>
        </div>
      </div>
      <div class="body">
          <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="availabilitySetmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div> 
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new</p>        
                    </div>
                </div> 
        <div class="form-group">
          <div class="label-div">
            <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
          </div>
          <div class="input-box">
            <input type="text" class="form-control"
              [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck" id="name"
              autocomplete="off" [(ngModel)]="availabilitySetmodel.resourceName" placeholder="" name="name"
              (keyup)="checkVmASName($event,80)" required>
            <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
            <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a
              letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
            <p class="text-danger" *ngIf="inputError?.nameDupCheck">This availability set is already there in
              subscription</p>
          </div>
        </div>
        <div class="add-another-tag">
          <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img
              src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
          <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img
              src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
        </div>

        <div *ngIf="showView">
          <div class="form-group">
            <div class="label-div">
              <label for="faultDomains" class="control-label">Fault domains<span class="mandatory"><img
                    class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the same fault domain share 
                            a common power source and physical network switch." matTooltipClass="nav-tooltips"
                    matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
              <input type="number" class="form-control remove-arrows" id="faultDomains" autocomplete="off"
                [class.border-danger]="inputError?.asFaultDomainsLen || inputError?.asFaultDomainsMaxLen"
                [(ngModel)]="availabilitySetmodel.resourceData.faultDomains" placeholder="" name="faultDomains"
                (keyup)="checkfaultDomains($event,availabilitySetmodel.resourceData)" required>
              <p class="text-danger" *ngIf="inputError?.asFaultDomainsLen">Fault domains must be at least 1</p>
              <p class="text-danger" *ngIf="inputError?.asFaultDomainsMaxLen">Fault domains can not be at more than 3
              </p>
            </div>
          </div>
          <div class="form-group">
            <div class="label-div">
              <label for="updateDomains" class="control-label">Update domains<span class="mandatory"><img
                    class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the same update domain will be restarted 
                            together during planned maintenance. Azure never restarts 
                            more than one update domain at a time" matTooltipClass="nav-tooltips"
                    matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
              <input type="number" class="form-control remove-arrows"
                [class.border-danger]="inputError?.asUpdateDomainsLen || inputError?.asUpdateDomainsMaxLen"
                [disabled]="availabilitySetmodel?.resourceData?.faultDomains==1" id="updateDomains" autocomplete="off"
                [(ngModel)]="availabilitySetmodel.resourceData.updateDomains" placeholder="" name="updateDomains"
                (keyup)="checkUpdateDomains($event,20)" required>
              <p class="text-danger" *ngIf="inputError?.asUpdateDomainsLen">Update domains must be at least 1</p>
              <p class="text-danger" *ngIf="inputError?.asUpdateDomainsMaxLen">Update domains can not be at more than 20
              </p>
              <p class="text-muted-msg" *ngIf="availabilitySetmodel?.resourceData?.faultDomains==1">The update domain count must be 1 when fault
                domain count is 1.</p>
            </div>
          </div>
          <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="useManagedDisks" class="control-label ">Use managed disks<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set this to 'Yes' if you plan to create virtual machines in this 
                    availability set with managed disks." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-5 radio-label" style="padding-left: 0px !important;"><input type="radio" name="useManagedDisks" [value]="'Classic'" ([ngModel])="availabilitySetmodel.resourceData.useManagedDisks" [checked]="availabilitySetmodel.resourceData.useManagedDisks=='Classic'" (change)="availabilitySetmodel.resourceData.useManagedDisks=$event.target.value;"><span>No (Classic)</span></label>
                <label class="col-sm-5 radio-label" style="padding-left: 0px !important;"><input type="radio" name="useManagedDisks" [value]="'Aligned'" ([ngModel])="availabilitySetmodel.resourceData.useManagedDisks" [checked]="availabilitySetmodel.resourceData.useManagedDisks=='Aligned'" (change)="availabilitySetmodel.resourceData.useManagedDisks=$event.target.value;"><span>Yes (Aligned)</span></label>                    
            </div>             
        </div>
        <div class="form-group">
            <div class="label-div">
                <label for="proxPlacGroup" class="control-label ">Proximity placement group<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A proximity placement group is a logical grouping used to make 
                    sure that Azure compute resources are physically located 
                    close to each other. Proximity placement groups are useful
                     for workloads where low latency is a requirement." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="proxPlacGroupList" class="no-data"  notFoundText="No proximity placement group" bindLabel="name" bindValue="id" [(ngModel)]="availabilitySetmodel.resourceData.proxPlacGroup" name="proxPlacGroup" placeholder="" [clearable]="false"></ng-select>
            </div>             
        </div>
          <div class="form-group">
            <div class="label-div d-flex">
              <label class="control-label " style="min-width: max-content;">Tag name </label>
              <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
              </label>
              <label style="padding-left: 6px !important;width: 18px;"
                *ngIf="availabilitySetmodel.resourceData&&availabilitySetmodel.resourceData.tagList&&availabilitySetmodel.resourceData.tagList.length>1"></label>
              <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
            </div>
            <div *ngFor="let item of availabilitySetmodel.resourceData.tagList;let i = index">
              <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                <div class="input-box" style="padding-bottom: 6px;">
                  <input type="text" class="form-control" id="tagVMName" autocomplete="off" [(ngModel)]="item.tagName"
                    name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                </div>

                <div style="padding-left: 6px;padding-right: 6px;">:</div>

                <div class="input-box">
                  <input type="text" class="form-control" id="tagVMValue" autocomplete="off" [(ngModel)]="item.value"
                    name="tagVMValue" (keyup)="checkTagInput(item,256)">
                </div>

                <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                    *ngIf="availabilitySetmodel.resourceData.tagList.length>1"></span>
              </div>
              <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                512.</p>
              <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                <>%&\?/.</p>
              <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
              <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                is 256.</p>
            </div>

            <div class="add-another-tag">
              <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
            </div>
          </div>

          <div class="form-group">
            <div class="label-div">
              <label for="comment" class="control-label">Description</label>
            </div>
            <div class="input-box">
              <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VMdescription"
                [(ngModel)]="availabilitySetmodel.comments" placeholder="Describe the purpose of the resource"
                (keyup)="VmDescription($event,300)"></textarea>
              <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
            </div>
          </div>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
          <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'"
            [class.disabled]="checkDataDisabled('availabilitySet')" (click)="triggerBtn('save')">Save</button>
          <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'"
            [class.disabled]="checkDataDisabled('availabilitySet')" (click)="triggerBtn('update')">Update</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadBalancer>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':virtualMachinemodel.publicIPShow||virtualMachinemodel.newDiskShow||virtualMachinemodel.nsgShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Load Balancer</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="loadBalancermodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="loadBalancer" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar" id="VMname" autocomplete="off" [(ngModel)]="loadBalancermodel.resourceName" placeholder="Enter load balancer name" name="projectName" (keyup)="VmNameValidation($event,65)" required>
                    <p class="text-danger" *ngIf="inputError?.VMname">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens. </p>
                    </div>              
                </div>

                <!-- <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div> -->
        <!-- <div *ngIf="showView"> -->

            <!-- <div class="form-group">
                <div class="label-div">
                    
                </div>
                </div> -->

                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="instanceDetails" class="load">Instance details<span class="mandatory"></span></label>
                        <label for="type" class="control-label ">Type<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can use internal load balancers to balance traffic from private IP addresses. Public load balancers can balance traffic originating from public IP addresses." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="type" [value]="'internal'" ([ngModel])="loadBalancermodel.resourceData.type" [checked]="loadBalancermodel.resourceData.type=='internal'" (change)="loadBalancermodel.resourceData.type=$event.target.value;changeLoaderType()" ><span>Internal</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="type" [value]="'public'" ([ngModel])="loadBalancermodel.resourceData.type" [checked]="loadBalancermodel.resourceData.type=='public'" (change)="loadBalancermodel.resourceData.type=$event.target.value;changeLoaderType()"><span>Public</span></label>                    
                </div>             
            </div>

            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="sku" class="control-label ">SKU<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a Standard load balancer for up to 1000 instances, greater backend pool flexibility, HA ports, and zonal and zone-redundant scenarios." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="sku" [value]="'Standard'" ([ngModel])="loadBalancermodel.resourceData.sku" [checked]="loadBalancermodel.resourceData.sku=='Standard'" (change)="loadBalancermodel.resourceData.sku=$event.target.value;changeLoaderSKU()"><span>Standard</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="sku" [value]="'Basic'" ([ngModel])="loadBalancermodel.resourceData.sku" [checked]="loadBalancermodel.resourceData.sku=='Basic'" (change)="loadBalancermodel.resourceData.sku=$event.target.value;changeLoaderSKU()"><span>Basic</span></label>                    
                </div>             
            </div>

            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="tier" class="control-label ">Tier</label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="tier" [value]="'Regional'" [disabled]="loadBalancermodel.resourceData.sku=='Basic'||(loadBalancermodel.resourceData.sku=='Standard'&&loadBalancermodel.resourceData.type=='internal')" ([ngModel])="loadBalancermodel.resourceData.tier" [checked]="loadBalancermodel.resourceData.tier=='Regional'" (change)="loadBalancermodel.resourceData.tier=$event.target.value;changeLoaderTier()"><span>Regional</span></label>
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;" *ngIf="showGlobalTier()"><input type="radio" name="tier" [value]="'Global'" [disabled]="loadBalancermodel.resourceData.sku=='Basic'||(loadBalancermodel.resourceData.sku=='Standard'&&loadBalancermodel.resourceData.type=='internal')" ([ngModel])="loadBalancermodel.resourceData.tier" [checked]="loadBalancermodel.resourceData.tier=='Global'" (change)="loadBalancermodel.resourceData.tier=$event.target.value;changeLoaderTier()"><span>Global</span></label>                    
                </div>             
            </div>

            <div class="form-group" *ngIf="loadBalancermodel.resourceData.tier=='Global'">
                <div class="label-div d-flex" style="background: #F4FBFF;border: 1px solid #62C2FB;;box-sizing: border-box;border-radius: 2px;">
                    <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/blueprint/popup-error-info.png" alt=""></label>
                    <label class="" style="padding: 8px 0px !important;color: #0B70D4;">
                        A cross-region (global) load balancer can only be deployed
                         to a home region. Make sure 
                         you have selected a valid region.</label>
                </div>  
            </div>
    
            <div class="form-group"  *ngIf="loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard')">
                <div class="label-div">
                    <label for="instanceDetails" class="load">Configure virtual network</label>
                </div>
               
                <div class="label-div">
                    <label for="virtualNetwork" class="control-label">Virtual network<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="existingVNList" bindLabel="name" bindValue="name" [(ngModel)]="loadBalancermodel.resourceData.virtualNetwork" name="virtualNetwork" placeholder="" (change)="getSubnetList()" [clearable]="false" required>
                    </ng-select>
                </div>   
            </div>
            <div class="form-group"  *ngIf="loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard')"> 
                <div class="label-div">
                    <label for="subnet" class="control-label">Subnet<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The resource will reside in this subnet." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="existingSubnetList" bindLabel="name" [(ngModel)]="loadBalancermodel.resourceData.subnet" name="subnet" placeholder="" [clearable]="false" required>
                        <ng-template ng-label-tmp let-item="item">
                            {{item?.name}} ({{item?.properties?.addressPrefix}})
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{item?.name}} ({{item?.properties?.addressPrefix}})
                        </ng-template>
                    </ng-select>
                </div>  
            </div>
                

            <!-- <div class="form-group" *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))">
                <div class="label-div">
                    
                </div>
                </div> -->

            <div class="form-group" *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))">
                <div class="label-div">
                    <label for="publicIPAddress" class="load">Public IP address</label>
                    <label for="publicIP" class="control-label">Public IP address<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose the public IP address to be used in the frontend IP configuration." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="publicIP" [value]="'new'" ([ngModel])="loadBalancermodel.resourceData.publicIP" [checked]="loadBalancermodel.resourceData.publicIP=='new'" (change)="loadBalancermodel.resourceData.publicIP=$event.target.value"><span>Create new</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="publicIP" [value]="'existing'" ([ngModel])="loadBalancermodel.resourceData.publicIP" [checked]="loadBalancermodel.resourceData.publicIP=='existing'" (change)="loadBalancermodel.resourceData.publicIP=$event.target.value"><span>Use existing</span></label>                    
                </div>           
            </div>

            <div class="form-group" *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))&&loadBalancermodel.resourceData.publicIP=='existing'&&loadBalancermodel.resourceData.tier!='Global'">
                <div class="label-div">
                    <label for="publicIPaddress" class="control-label col-sm-2">Public IP address name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="vmPublicIPList" bindLabel="name" bindValue="name" [(ngModel)]="loadBalancermodel.resourceData.publicIPaddress" name="publicIPaddress" placeholder="" [clearable]="false" required>
                    </ng-select>
                </div>             
            </div> 
           

            <div class="form-group" *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))&&(loadBalancermodel.resourceData.publicIP=='new'||loadBalancermodel.resourceData.tier=='Global')">
                <div class="label-div">
                    <label for="publicIPAddressName" class="control-label">Public IP address name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar" id="VMname" autocomplete="off" [(ngModel)]="loadBalancermodel.resourceData.publicIPaddressName" placeholder="" name="projectName" (keyup)="VmNameValidation($event,65)" required>
                <p class="text-danger" *ngIf="inputError?.VMname">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="inputError?.VmSpecialChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens. </p>
                </div>              
            </div>

            <div class="form-group"  *ngIf="!(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))&&(loadBalancermodel.resourceData.publicIP=='new'||loadBalancermodel.resourceData.tier=='Global')">
                <div class="label-div">
                    <label for="publicIPAddresssku" class="control-label">Public IP address SKU</label>
                    <label for="standard" class="load">{{loadBalancermodel.resourceData.sku}}</label>
                </div>
                </div>

            <div class="form-group"  *ngIf="(loadBalancermodel.resourceData.type=='internal' && (loadBalancermodel.resourceData.sku=='Basic'||loadBalancermodel.resourceData.sku=='Standard'))||(loadBalancermodel.resourceData.publicIP=='new'||loadBalancermodel.resourceData.tier=='Global')" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="IPaddressAssignment" class="control-label ">IP address assignment</label>
                </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPaddressAssignment" [value]="'Dynamic'" [disabled]="loadBalancermodel.resourceData.tier=='Global'" [disabled]="loadBalancermodel.resourceData.type=='public'&&loadBalancermodel.resourceData.sku=='Standard'" ([ngModel])="loadBalancermodel.resourceData.IPaddressAssignment" [checked]="loadBalancermodel.resourceData.IPaddressAssignment=='Dynamic'" (change)="loadBalancermodel.resourceData.IPaddressAssignment=$event.target.value" ><span>Dynamic</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPaddressAssignment" [value]="'Static'" [disabled]="loadBalancermodel.resourceData.tier=='Global'" [disabled]="loadBalancermodel.resourceData.type=='public'&&loadBalancermodel.resourceData.sku=='Standard'" ([ngModel])="loadBalancermodel.resourceData.IPaddressAssignment" [checked]="loadBalancermodel.resourceData.IPaddressAssignment=='Static'" (change)="loadBalancermodel.resourceData.IPaddressAssignment=$event.target.value"><span>Static</span></label>                    
            </div>             
        </div>

        <div class="form-group" *ngIf="loadBalancermodel.resourceData.publicIP=='new'&&loadBalancermodel.resourceData.IPaddressAssignment=='Static'&&loadBalancermodel.resourceData.tier!='Global'&&loadBalancermodel.resourceData.type!='public'">
            <div class="label-div">
                <label for="privateIPAddrSpace" class="control-label col-sm-2">Private IP address</label>
            </div>
            <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.nicValidIPError || inputError?.nicRegisterIPError || inputError?.nicAddrUsedError || !inputError?.nicSubnetRangeError" id="SubnetAdrSpc" autocomplete="off" [(ngModel)]="loadBalancermodel.resourceData.privateIPAddrSpace" placeholder="" name="privateIPAddrSpace" (keyup)="loadBalancerPrivateIpAddress(loadBalancermodel)" required>
                <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                <p class="text-danger" *ngIf="inputError?.nicValidIPError">Invalid IP address</p>
                <p class="text-danger" *ngIf="inputError?.nicRegisterIPError">The IP address falls within the reserved IP range of subnet</p>
                <p class="text-danger" *ngIf="inputError?.nicAddrUsedError">IP is already used</p>
                <p class="text-danger" *ngIf="!inputError?.nicSubnetRangeError">The IP must be in the subnet</p>
            </div>              
        </div> 
        

            <!--  // *ngIf="loadBalancermodel.resourceData.type=='internal' && loadBalancermodel.resourceData.type=='public' && loadBalancermodel.resourceData.sku=='Standard'" -->
            <div class="form-group" *ngIf="((loadBalancermodel.resourceData.type=='internal' && loadBalancermodel.resourceData.sku=='Standard')||(loadBalancermodel.resourceData.publicIP=='new'&&loadBalancermodel.resourceData.sku!='Basic'))&&loadBalancermodel.resourceData.tier!='Global'">
            <div class="label-div">
                <label for="availabilityZone" class="control-label">Availablity zone</label>
            </div>
            <div class="input-box">
                <ng-select [items]="loadBalancermodel.resourceData.type=='internal'?zonesListForInternal:zonesList" bindLabel="name" bindValue="name" [disabled]="loadBalancermodel.resourceData.routingPreference=='internet'" [(ngModel)]="loadBalancermodel.resourceData.availabilityZone" name="availabilityZone" placeholder="" [clearable]="false"></ng-select>
            </div>   
        </div>

        <!-- // *ngIf="!loadBalancermodel.resourceData.sku=='Basic' && !loadBalancermodel.resourceData.publicIP=='existing'" -->
        <div class="form-group" *ngIf="(loadBalancermodel.resourceData.publicIP=='new'&&(loadBalancermodel.resourceData.type=='public'&&loadBalancermodel.resourceData.sku=='Standard'))" style="margin-bottom: 10px !important;"> 
        <div class="label-div">
                <label for="routingPreference" class="control-label ">Routing preference<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Determines how your traffic routes between Azure and the Internet. 
                    Selecting Microsoft global network delivers traffic via Microsoft global network closest to user. Internet route option uses transit ISP network. Egress data transfer price varies based on the routing selection. Routing preference option of a Public IP can’t be changed once created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="routingPreference" [value]="'microsoftNetwork'" [disabled]="loadBalancermodel.resourceData.tier=='Global'" ([ngModel])="loadBalancermodel.resourceData.routingPreference" [checked]="loadBalancermodel.resourceData.routingPreference=='microsoftNetwork'" (change)="loadBalancermodel.resourceData.routingPreference=$event.target.value;"><span>Microsoft network</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="routingPreference" [value]="'internet'" [disabled]="loadBalancermodel.resourceData.tier=='Global'" ([ngModel])="loadBalancermodel.resourceData.routingPreference" [checked]="loadBalancermodel.resourceData.routingPreference=='internet'" (change)="loadBalancermodel.resourceData.routingPreference=$event.target.value;"><span>Internet</span></label>                    
            </div>             
        </div>

        <div class="form-group">
            <div class="label-div">
                <label for="comment" class="control-label">Description</label>
            </div>
            <div class="input-box">
                <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="loadBalancermodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
            </div>                    
        </div> 


                    <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="loadBalancermodel.resourceData&&loadBalancermodel.resourceData.tagList&&loadBalancermodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of loadBalancermodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="loadBalancermodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                <!-- </div> -->

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('loadBalancer')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('loadBalancer')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>
    
</ng-template>

<ng-template #localNetworkGateway>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="form-control" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-disabled':show}">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Local Network Gateway</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="localNetworkGatewaymodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 8px;"><i class="fa fa-trash" (click)="deleteNewResourceGroup(item,index)"></i></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 25px;"><img src="/assets/Infra/blueprint/palette/edit.svg" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="localNetworkGatewayname" class="control-label ">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar || inputError?.duplicateNi" id="VNetName" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceName" placeholder="Enter a name" name="VirtualNetworkName" (keyup)="VNetNameValidation($event,80);niDuplicateCheck($event)" required>
                        <p class="text-danger" *ngIf="inputError?.VNetName">The length must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateNi">There is already a resource with the same name and type within the current resource group.</p>
                        <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>              
                </div>
        
                
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="endpoint" class="control-label ">Endpoint<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the endpoint type for the on-premises VPN device - IP address 
                            or FQDN (Fully Qualified Domain Name)" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="endpoint" [value]="'IPaddress'" ([ngModel])="localNetworkGatewaymodel.resourceData.endpoint" [checked]="localNetworkGatewaymodel.resourceData.endpoint=='IPaddress'" (change)="localNetworkGatewaymodel.resourceData.endpoint=$event.target.value;assignPrivateIPToDynamicNIC()"><span>IP address</span></label>
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="endpoint" [value]="'FQDN'" ([ngModel])="localNetworkGatewaymodel.resourceData.endpoint" [checked]="localNetworkGatewaymodel.resourceData.endpoint=='FQDN'" (change)="localNetworkGatewaymodel.resourceData.endpoint=$event.target.value;assignPrivateIPToDynamicNIC()"><span>FQDN</span></label>                    
                    </div>             
                </div>

                <div class="form-group" *ngIf="localNetworkGatewaymodel.resourceData.endpoint=='IPaddress'">
                    <div class="label-div">
                        <label for="gatewayIpAddress" class="control-label col-sm-2">IP address<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The public IP address of your local gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.nicValidIPError || inputError?.nicRegisterIPError || inputError?.nicAddrUsedError || !inputError?.nicSubnetRangeError" id="gatewayIpAddress" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.gatewayIpAddress" placeholder="" name="gatewayIpAddress" (keyup)="nicPrivateIpAddress(networkInterfacemodel)" required>
                        <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                        <p class="text-danger" *ngIf="inputError?.nicValidIPError">Invalid IP address</p>
                        <p class="text-danger" *ngIf="inputError?.nicRegisterIPError">The IP address falls within the reserved IP range of subnet</p>
                        <p class="text-danger" *ngIf="inputError?.nicAddrUsedError">IP is already used</p>
                        <p class="text-danger" *ngIf="!inputError?.nicSubnetRangeError">The IP must be in the subnet</p>
                    </div>              
                </div> 

                <div class="form-group" *ngIf="localNetworkGatewaymodel.resourceData.endpoint=='FQDN'">
                    <div class="label-div">
                        <label for="fqdn" class="control-label col-sm-2">FQDN<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Fully-qualified domain name (FQDN) of your local gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.nameValid" id="fqdn" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.fqdn" placeholder="" name="fqdn" (keyup)="checkFqdnChar($event)" required>
                        <p class="text-danger" *ngIf="inputError?.nameValid">The specified FQDN is not valid.</p>
                    </div>              
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="addressPrefixes" class="control-label ">Address space<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="One or more IP address ranges (in CIDR notation) that define your local network's address space. For example: 192.168.0.0/16. " matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" *ngFor="let item of localNetworkGatewaymodel.resourceData.virtualNetworkAddressSpace;let i = index">
                        <div class="d-flex">
                            <input type="text" class="form-control" [class.border-danger]="item.VNetAdrspc" id="VNetAdrSpc" autocomplete="off" [(ngModel)]="item.address" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace" (keyup)="VNetAddressSpace($event,item,i)" required>
                            <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete_icon.svg" (click)="deleteAddress(localNetworkGatewaymodel,i)" *ngIf="localNetworkGatewaymodel.resourceData.virtualNetworkAddressSpace.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
                        <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use {{item.validCIDRValue}} address space</p>
                        <p class="text-danger" *ngIf="item.overlooping">Address spaces within a virtual network cannot overlap {{item.overlapingValue}}</p>
                        <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
                    </div> 
                    <div class="add-another-tag"><p class="blue-text cursor-pointer lbl-btn" (click)="addNewAddress(localNetworkGatewaymodel)">Add address space</p></div>
                </div> 

                <div class="form-group">
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="configureBGP" id="configureBGP" [(ngModel)]="localNetworkGatewaymodel.resourceData.configureBGP" ><span>Configure BGP settings</span></label>
                        <div class="form-group" *ngIf="localNetworkGatewaymodel?.resourceData?.configureBGP">
                            <div class="label-div">
                                <label for="asn" class="control-label col-sm-2">Autonomous system number (ASN)<span class="mandatory">*<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip="A BGP-enabled connection between two network gateways requires 
                                    that their ASNs be different. Additionally, the following ASNs are 
                                    reserved by Azure: 8075, 8076, 12076 (public), 65515, 65517, 65518, 
                                    65519, 65520 (private). You cannot specify these ASNs for your 
                                    on-premises VPN devices when connecting to Azure VPN gateways. 
                                    While setting up IPsec connectivity from virtual network gateways to 
                                    Azure virtual WAN VPN, the ASN for Local Network Gateway is required 
                                    to be 65515." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.restoredays" id="restoredays" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.asn" placeholder="" name="asn" (keyup)="changeDaysRestore($event,'restoredays')" required>   
                             <p class="text-danger" *ngIf="inputError?.restoredays">Value must be a valid number.</p>
                            </div>    
                            <div class="label-div">
                                <label for="bgpPeeringAddress" class="control-label col-sm-2">BGP peer IP address<span class="mandatory">*</span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.nicValidIPError || inputError?.nicRegisterIPError || inputError?.nicAddrUsedError || !inputError?.nicSubnetRangeError" id="bgpPeeringAddress" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.bgpPeeringAddress" placeholder="" name="bgpPeeringAddress" (keyup)="nicPrivateIpAddress(networkInterfacemodel)" required>
                                <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                                <p class="text-danger" *ngIf="inputError?.nicValidIPError">Invalid IP address</p>
                                <p class="text-danger" *ngIf="inputError?.nicRegisterIPError">The IP address falls within the reserved IP range of subnet</p>
                                <p class="text-danger" *ngIf="inputError?.nicAddrUsedError">IP is already used</p>
                                <p class="text-danger" *ngIf="!inputError?.nicSubnetRangeError">The IP must be in the subnet</p>
                            </div>
                            <!-- <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.restoredays" id="restoredays" autocomplete="off" [(ngModel)]="localNetworkGatewaymodel.resourceData.bgpPeeringAddress" placeholder="" name="bgpPeeringAddress" (keyup)="changeDaysRestore($event,'restoredays')" required>
                                <p class="text-danger" *ngIf="inputError?.subnetAdrspc">This value must be less than the value (90) that's set for blob soft delete.</p>
                                <p class="text-danger" *ngIf="inputError?.restoredays">The value must be between 1 and 364.</p>
                            </div>           -->
                        </div>
                    </div>
                </div>

                
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label ">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="localNetworkGatewaymodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div>
                 
                
                <div class="form-group">
                  <div class="label-div d-flex">
                    <label class="control-label " style="min-width: max-content;">Tag name </label>
                    <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                    </label>
                    <label style="padding-left: 6px !important;width: 18px;"
                      *ngIf="localNetworkGatewaymodel.resourceData&&localNetworkGatewaymodel.resourceData.tagList&&localNetworkGatewaymodel.resourceData.tagList.length>1"></label>
                    <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                  </div>
                  <div *ngFor="let item of localNetworkGatewaymodel.resourceData.tagList;let i = index">
                    <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                      <div class="input-box" style="padding-bottom: 6px;">
                        <input type="text" class="form-control" id="tagName" autocomplete="off"
                          [(ngModel)]="item.tagName" name="tagName" (keyup)="checkTagInput(item,512,i)">
                      </div>

                      <div style="padding-left: 6px;padding-right: 6px;">:</div>

                      <div class="input-box">
                        <input type="text" class="form-control" id="tagValue" autocomplete="off"
                          [(ngModel)]="item.value" name="tagValue" (keyup)="checkTagInput(item,256)">
                      </div>

                      <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                          *ngIf="localNetworkGatewaymodel.resourceData.tagList.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                      512.</p>
                    <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                      <>%&\?/.</p>
                    <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                    <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                      is 256.</p>
                  </div>

                  <div  class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                  </div>
                </div>
                 
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('localNetworkGateway')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('localNetworkGateway')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>       
</ng-template> 

<ng-template #connections>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="form-control" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-disabled':show}">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Connection</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="connectionsmodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 8px;"><i class="fa fa-trash" (click)="deleteNewResourceGroup(item,index)"></i></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 25px;"><img src="/assets/Infra/blueprint/palette/edit.svg" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 

                <div class="form-group">
                    <div class="label-div">
                        <label for="connectionType" class="control-label">Connection type<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use a VNet-to-VNet connection to connect two Azure virtual networks.Use a site-to-site connection to connect your on-premises network with your Azure virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="connectionTypeList" bindLabel="name" bindValue="id" [(ngModel)]="connectionsmodel.resourceData.connectionType" name="connectionType" placeholder="Choose" [clearable]="false"></ng-select>
                    </div>   
                </div>

                <div class="form-group" *ngIf="connectionsmodel.resourceData.connectionType=='IPsec'">
                    <div class="label-div">
                        <label for="connectionName" class="control-label ">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar || inputError?.duplicateConnName" id="VNetName" autocomplete="off" [(ngModel)]="connectionsmodel.resourceName" placeholder="Enter a name" name="connectionName" (keyup)="VNetNameValidation($event,80);connNameDuplicateCheck($event,'duplicateConnName')" required>
                        <p class="text-danger" *ngIf="inputError?.VNetName">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateConnName">There is already a resource with the same name and type within the current resource group.</p>
                        <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>

                    <div class="label-div">
                        <label for="localNetworkGatewayName" class="control-label">Virtual network gateway<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The first Azure virtual network that will be used by this connection. Traffic will flow from this virtual network to the second virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="virtualNetworkGatewayList" bindLabel="name" [(ngModel)]="connectionsmodel.resourceData.localNetworkGatewayName" name="localNetworkGatewayName" placeholder="" (change)="getSubnetList()" [clearable]="false" required>
                        </ng-select>
                    </div>  
               
                    <div class="label-div">
                            <label for="localNetworkGatewayRG" class="control-label">Local network gateway<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The local network that will be used by the connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="localNetworkGatewayList" bindLabel="name" [(ngModel)]="connectionsmodel.resourceData.localNetworkGatewayRG" name="localNetworkGatewayRG" placeholder="" [clearable]="false" required>
                            </ng-select>
                        </div>  
                </div>


                <div class="form-group" *ngIf="connectionsmodel?.resourceData?.connectionType!=='IPsec'">
                    <div class="input-box">
                        <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="establishBidirectional" id="establishBidirectional" [(ngModel)]="connectionsmodel.resourceData.establishBidirectional"><span>Establish bidirectional connectivity<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip="Connections are unidirectional, and traffic will only flow from the first virtual network gateway to the second one. To establish bidirectional connectivity, check this option, and two connections will be created so that traffic can flow in both directions." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        <div class="form-group" *ngIf="connectionsmodel?.resourceData?.establishBidirectional">
            
                            <div class="label-div">
                                <label for="connectionName" class="control-label ">First connection name<span class="mandatory">*</span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.VNetNameFirstConn || inputError?.VNetCharFirstConn || inputError?.duplicateConnNameF" id="VNetName" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.connectionName" placeholder="Enter a name" name="connectionName" (keyup)="validationFirstConn($event,80);connNameIPsecDuplicateCheck($event,'connectionName','duplicateConnNameF')" required>
                                <p class="text-danger" *ngIf="inputError?.VNetNameFirstConn">The name must be between 1 and 80 characters.</p>
                                <p class="text-danger" *ngIf="inputError?.duplicateConnNameF">There is already a resource with the same name and type within the current resource group.</p>
                                <p class="text-danger" *ngIf="inputError?.VNetCharFirstConn">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                            </div>

                            <div class="label-div">
                                <label for="connectionReverseName" class="control-label ">Second connection name<span class="mandatory">*</span></label>
                            </div>
                            <div class="input-box">
                                <input type="text" class="form-control" [class.border-danger]="inputError?.VNetNameSecondConn || inputError?.VNetCharSecondConn || inputError?.duplicateConnNameSecondConn" id="VNetName" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.connectionReverseName" placeholder="Enter a name" name="connectionName" (keyup)="validationSecondConn($event,80);connNameIPsecDuplicateCheck($event,'connectionReverseName','duplicateConnNameSecondConn')" required>
                                <p class="text-danger" *ngIf="inputError?.VNetNameSecondConn">The name must be between 1 and 80 characters.</p>
                                <p class="text-danger" *ngIf="inputError?.duplicateConnNameSecondConn">There is already a resource with the same name and type within the current resource group.</p>
                                <p class="text-danger" *ngIf="inputError?.VNetCharSecondConn">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="form-group" *ngIf="!connectionsmodel?.resourceData?.establishBidirectional && connectionsmodel?.resourceData?.connectionType!=='IPsec'">
                    <div class="label-div">
                        <label for="connectionName" class="control-label ">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.VNetName || inputError?.VNetChar || inputError?.duplicateConnName" id="VNetName" autocomplete="off" [(ngModel)]="connectionsmodel.resourceName" placeholder="Enter a name" name="connectionName" (keyup)="VNetNameValidation($event,80);connNameDuplicateCheck($event,'duplicateConnName')" required>
                        <p class="text-danger" *ngIf="inputError?.VNetName">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateConnName">There is already a resource with the same name and type within the current resource group.</p>
                        <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>              
                </div>
        
                <div class="form-group" *ngIf="connectionsmodel?.resourceData?.connectionType!=='IPsec'">
                    <div class="label-div">
                        <label for="virtualNetworkGatewayName" class="control-label">First virtual network gateway<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The first Azure virtual network that will be used by this connection.Traffic will flow from this virtual network to the second virtual network." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="virtualNetworkGatewayList" bindLabel="name" [(ngModel)]="connectionsmodel.resourceData.virtualNetworkGatewayName" name="virtualNetworkGatewayName" placeholder="" (change)="getSubnetList()" [clearable]="false" required>
                        </ng-select>
                    </div>  
                </div>

                <div class="form-group" *ngIf="connectionsmodel?.resourceData?.connectionType!=='IPsec'">
                        <div class="label-div">
                            <label for="virtualNetworkGatewayRG" class="control-label">Second virtual network gateway<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The second Azure virtual network that will be used by this connection.Traffic will flow from the first virtual network to this one." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="virtualNetworkGatewayList" bindLabel="name" [(ngModel)]="connectionsmodel.resourceData.virtualNetworkGatewayRG" name="virtualNetworkGatewayRG" placeholder="" [clearable]="false" required>
                            </ng-select>
                        </div>  
                </div>


                <div class="form-group">
                    <div class="label-div">
                        <label for="sharedKey" class="control-label ">Shared key (PSK)<span class="mandatory">*<img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip="A mixture of letters and numbers, used to establish encryption for the connection.The same shared key must be used in both the virtual network and local network gateways.If your gateway device doesn't provide one, you can make one up here and provide it to your device." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.sharedKeyName || inputError?.sharedKeyChar" id="sharedKey" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.sharedKey" placeholder="" name="sharedKey" (keyup)="sharedKeyNameValidation($event,1,128);" required>
                        <p class="text-danger" *ngIf="inputError?.sharedKeyName">A shared key must contain between 1 to 128 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.sharedKeyChar">The field can contain only letters and numbers.</p>
                    </div>              
                </div>
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label ">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="connectionsmodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div>
                 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
           <div *ngIf="showView">

            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="IKEProtocol" class="control-label ">IKE Protocol<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IKE protocol used by this connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IKEProtocol" [value]="'IKEv1'" [disabled]="connectionsmodel.resourceData.connectionType=='IPsec'" ([ngModel])="connectionsmodel.resourceData.IKEProtocol" [checked]="connectionsmodel.resourceData.IKEProtocol=='IKEv1'" (change)="connectionsmodel.resourceData.IKEProtocol=$event.target.value;assignPrivateIPToDynamicNIC()"><span>IKEv1</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IKEProtocol" [value]="'IKEv2'" [disabled]="connectionsmodel.resourceData.connectionType=='IPsec'" ([ngModel])="connectionsmodel.resourceData.IKEProtocol" [checked]="connectionsmodel.resourceData.IKEProtocol=='IKEv2'" (change)="connectionsmodel.resourceData.IKEProtocol=$event.target.value;assignPrivateIPToDynamicNIC()"><span>IKEv2</span></label>                    
                </div>             
            </div>

            <div class="form-group">
                <div class="input-box">
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="useLocalAzureIpAddress" id="useLocalAzureIpAddress" [(ngModel)]="connectionsmodel.resourceData.useLocalAzureIpAddress" ><span>Use Azure Private IP Address<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Checking this option allows you to use Azure private IPs to establish an IPsec VPN connection" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
            </div>

            <div class="form-group">
                <div class="input-box">
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enableBgp" id="enableBgp" [(ngModel)]="connectionsmodel.resourceData.enableBgp" ><span>Enable BGP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Border gateway protocol" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
            </div>
           
            <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="connectionsmodel?.resourceData?.IKEProtocol!=='IKEv1'">
                <div class="label-div">
                    <label for="ipsecPolicies" class="control-label ">IPsec / IKE policy<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IPsec / IKE policy will be negotiated while dialing the connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="ipsecPolicies" [value]="'Default'" ([ngModel])="connectionsmodel.resourceData.ipsecPolicies" [checked]="connectionsmodel.resourceData.ipsecPolicies=='Default'" (change)="connectionsmodel.resourceData.ipsecPolicies=$event.target.value;"><span>Default</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="ipsecPolicies" [value]="'Custom'" ([ngModel])="connectionsmodel.resourceData.ipsecPolicies" [checked]="connectionsmodel.resourceData.ipsecPolicies=='Custom'" (change)="connectionsmodel.resourceData.ipsecPolicies=$event.target.value;"><span>Custom</span></label>                    
                </div>             
            </div>

            <div *ngIf="connectionsmodel.resourceData.ipsecPolicies=='Custom'">
                <div class="form-group">
                    <div class="label-div">
                        <label for="IKEPhase1" class="control-label ">IKE Phase 1<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IKE Phase 1" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>

                    <div class="label-div">
                        <label for="ikeEncryption" class="control-label">Encryption<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="ikeEncryptionList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.ikeEncryption" name="ikeEncryption" placeholder="" [clearable]="false"></ng-select>
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="ikeIntegrity" class="control-label">Integrity/PRF<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="ikeIntegrityList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.ikeIntegrity" name="ikeIntegrity" placeholder="" [clearable]="false"></ng-select>
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="dhGroup" class="control-label">DH Group<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="dhGroupList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.dhGroup" name="dhGroup" placeholder="" [clearable]="false"></ng-select>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="IKEPhase2(IPsec)" class="control-label ">IKE Phase 2(IPsec)<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="KE phase 2 policy will be used for IPsec Quick Mode (QM) SA creation." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>

                    <div class="label-div">
                        <label for="ipsecEncryption" class="control-label">IPsec Encryption<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="ipsecEncryptionList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.ipsecEncryption" name="ipsecEncryption" placeholder="" [clearable]="false"></ng-select>
                    </div>      
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="ipsecIntegrity" class="control-label">IPsec Integrity/PRF<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="ipsecIntegrityList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.ipsecIntegrity" name="ipsecIntegrity" placeholder="" [clearable]="false"></ng-select>
                    </div>      
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="pfsGroup" class="control-label">PFS Group<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="pfsGroupList" bindLabel="name" bindValue="name" [(ngModel)]="connectionsmodel.resourceData.pfsGroup" name="pfsGroup" placeholder="" [clearable]="false"></ng-select>
                    </div>      
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="saDataSizeKilobytes" class="control-label">IPsec SA lifetime in KiloBytes<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The IPsec(QM) SA will expire after this much kilobytes of data is transported over the connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.saDataSizelimit||inputError?.saDataTimeNum" id="saDataSizeKilobytes" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.saDataSizeKilobytes" placeholder="" name="saDataSizeKilobytes" (keyup)="changesaDataSizeLimit($event)">
                    <p class="text-danger" *ngIf="inputError?.saDataSizelimit">SA life time in kilobytes must be between 1024 and 2147483647.</p>
                    <p class="text-danger" *ngIf="inputError?.saDataTimeNum">Value must be a valid number.</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="saLifeTimeSeconds" class="control-label">IPsec SA lifetime in seconds<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The IPsec(QM) SA will expire after this time." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.saLifeTimeLimit||inputError?.saLifeTimeNum" id="saLifeTimeSeconds" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.saLifeTimeSeconds" placeholder="" name="saLifeTimeSeconds" (keyup)="changesaLifeTimeLimit($event)">
                    <p class="text-danger" *ngIf="inputError?.saLifeTimeLimit">SA life time in seconds must be between 300 and 172799.</p>
                    <p class="text-danger" *ngIf="inputError?.saLifeTimeNum">Value must be a valid number.</p>
                    </div>
                </div>
            </div>

            <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="connectionsmodel?.resourceData?.IKEProtocol!=='IKEv1'">
                <div class="label-div">
                    <label for="usePolicyBasedTrafficSelectors" class="control-label ">Use policy based traffic selectors<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Setting Use policy based traffic selector to true on a connection will configure the Azure VPN gateway to connect to policy-based VPN firewall on premises." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="usePolicyBasedTrafficSelectors" [value]="'Enable'" ([ngModel])="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors" [checked]="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors=='Enable'" (change)="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors=$event.target.value;assignPrivateIPToDynamicNIC()"><span>Enable</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="usePolicyBasedTrafficSelectors" [value]="'Disable'" ([ngModel])="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors" [checked]="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors=='Disable'" (change)="connectionsmodel.resourceData.usePolicyBasedTrafficSelectors=$event.target.value;assignPrivateIPToDynamicNIC()"><span>Disable</span></label>                    
                </div>             
            </div>

            <div class="form-group" *ngIf="connectionsmodel?.resourceData?.IKEProtocol!=='IKEv1'">
                <div class="label-div">
                    <label for="dpdTimeoutSeconds" class="control-label">DPD timeout in seconds<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Dead Peer Detection Timeout of the connection in seconds" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.dpdtimeout||inputError?.dpdtimeoutNum" id="dpdTimeoutSeconds" autocomplete="off" [(ngModel)]="connectionsmodel.resourceData.dpdTimeoutSeconds" placeholder="" name="idleTimeout" (keyup)="changedpdTimeout($event)">
                <p class="text-danger" *ngIf="inputError?.dpdtimeout">DPD timeout in seconds must be between 9 and 3600.</p>
                <p class="text-danger" *ngIf="inputError?.dpdtimeoutNum">Value must be a valid number.</p>
                </div>              
            </div>

            <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="connectionsmodel?.resourceData?.IKEProtocol!=='IKEv1'">
                <div class="label-div">
                    <label for="connectionMode" class="control-label">Connection mode<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Connection mode is used to decide which gateway can initiate the connection.
                        When this value is set to:
                        Default: both Azure and on-premise VPN gateway can initiate the connection.
                        ResponderOnly: Azure VPN gateway will never initiate the connection. On-premise 
                        VPN gateway must initiate the connection.
                        InitiatorOnly: Azure VPN gateway will initiate the connection and reject any connection
                        attempts from on-premise VPN gateway." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box radio-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="connectionMode" [value]="'Default'" ([ngModel])="connectionsmodel.resourceData.connectionMode" [disabled]="connectionsmodel.resourceData.connectionType=='Vnet2Vnet'" [checked]="connectionsmodel.resourceData.connectionMode=='Default'" (change)="connectionsmodel.resourceData.connectionMode=$event.target.value;"><span>Default</span></label>
                    <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="connectionMode" [value]="'InitiatorOnly'" ([ngModel])="connectionsmodel.resourceData.connectionMode" [disabled]="connectionsmodel.resourceData.connectionType=='Vnet2Vnet'" [checked]="connectionsmodel.resourceData.connectionMode=='InitiatorOnly'" (change)="connectionsmodel.resourceData.connectionMode=$event.target.value;"><span>InitiatorOnly</span></label>
                    <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="connectionMode" [value]="'ResponderOnly'" ([ngModel])="connectionsmodel.resourceData.connectionMode" [disabled]="connectionsmodel.resourceData.connectionType=='Vnet2Vnet'" [checked]="connectionsmodel.resourceData.connectionMode=='ResponderOnly'" (change)="connectionsmodel.resourceData.connectionMode=$event.target.value;"><span>ResponderOnly</span></label>
                </div>             
            </div>    

           
                <div class="form-group">
                  <div class="label-div d-flex">
                    <label class="control-label " style="min-width: max-content;">Tag name </label>
                    <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                    </label>
                    <label style="padding-left: 6px !important;width: 18px;"
                      *ngIf="connectionsmodel.resourceData&&connectionsmodel.resourceData.tagList&&connectionsmodel.resourceData.tagList.length>1"></label>
                    <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                  </div>
                  <div *ngFor="let item of connectionsmodel.resourceData.tagList;let i = index">
                    <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                      <div class="input-box" style="padding-bottom: 6px;">
                        <input type="text" class="form-control" id="tagName" autocomplete="off"
                          [(ngModel)]="item.tagName" name="tagName" (keyup)="checkTagInput(item,512,i)">
                      </div>

                      <div style="padding-left: 6px;padding-right: 6px;">:</div>

                      <div class="input-box">
                        <input type="text" class="form-control" id="tagValue" autocomplete="off"
                          [(ngModel)]="item.value" name="tagValue" (keyup)="checkTagInput(item,256)">
                      </div>

                      <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                          *ngIf="connectionsmodel.resourceData.tagList.length>1"></span>
                    </div>
                    <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                      512.</p>
                    <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                      <>%&\?/.</p>
                    <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                    <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                      is 256.</p>
                  </div>

                  <div  class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                  </div>
                </div>
                 
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('connections')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('connections')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>    
 </div>   
</ng-template> 

<ng-template #routeTable>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':routeTablemodel.routesShow||routeTablemodel.subnetsShow }">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Route Table</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="routeTablemodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="Name" class="control-label">Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar || inputError?.duplicateAi" id="routeTable" autocomplete="off" [(ngModel)]="routeTablemodel.resourceName" placeholder="Enter a name" name="routeTable" (keyup)="VmNameValidation($event,255);aiDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.duplicateAi">The name must be unique in the current resource group.</p>
                    <!-- <p class="text-danger" *ngIf="inputError?.duplicateAi">The name must be unique in the current resource group.</p> -->
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar || inputError?.VMname">The name must contain between 1 to 255 characters inclusive. The name only allows alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                    </div>              
                </div>

            
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="routeTablemodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
        <div *ngIf="showView">
                <div class="form-group" style="margin-bottom: 10px !important;">
                    <div class="label-div">
                        <label for="propagateGatewayRoutes" class="control-label ">Propagate gateway routes<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select \'no\', to prevent the propagation of on-premises routes to the network interfaces in associated subnets." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="propagateGatewayRoutes" [value]="'Yes'" ([ngModel])="routeTablemodel.resourceData.propagateGatewayRoutes" [checked]="routeTablemodel.resourceData.propagateGatewayRoutes=='Yes'" (change)="routeTablemodel.resourceData.propagateGatewayRoutes=$event.target.value;" ><span>Yes</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="propagateGatewayRoutes" [value]="'No'" ([ngModel])="routeTablemodel.resourceData.propagateGatewayRoutes" [checked]="routeTablemodel.resourceData.propagateGatewayRoutes=='No'" (change)="routeTablemodel.resourceData.propagateGatewayRoutes=$event.target.value;"><span>No</span></label>                    
                </div>             
            </div>

            <div class="form-group">
            <div class="label-div">
                <label for="routes" class="control-label">Route</label> 
            </div>
            <div class="input-box">
                <ng-select [items]="routesList" bindLabel="name" [(ngModel)]="routeTablemodel.resourceData.routes" name="routes" placeholder="" [clearable]="false"></ng-select>
            </div>   
            <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="routeTablemodel.routesShow=true;">Add route</p>             
            </div>  
           </div>

           <div class="form-group">
            <div class="label-div">
                <label for="routes" class="control-label">Subnet</label> 
            </div>
            <div class="input-box">
                <ng-select [items]="subnetsList" bindLabel="name" [(ngModel)]="routeTablemodel.resourceData.subnets" name="subnets" placeholder="" [clearable]="false"></ng-select>
            </div>   
            <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="routeTablemodel.subnetsShow=true;">Associate subnet</p>             
            </div>  
           </div>
 
        
                    <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="routeTablemodel.resourceData&&routeTablemodel.resourceData.tagList&&routeTablemodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of routeTablemodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="routeTablemodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('routeTable')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('routeTable')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="routeTablemodel.routesShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Add Route</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="routeTablemodel.routesShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                               
                <div class="form-group">
                    <div class="label-div">
                        <label for="name" class="control-label">Route Name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="routesObj.name" placeholder="Enter a name" name="name" (keyup)="laNameValidation($event,1,80);lawDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.laname">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.laSpecialChar">The name can contain only letters, numbers and '-'. The '-' shouldn't be the first or the last symbol. </p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">The name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateLaw">The name must be unique in the current resource group.</p>
                    </div>              
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="addressPrefix" class="control-label ">Address prefix<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The destination IP address range in CIDR notation that this route applies to. If the destination IP address of a packet falls in this range, it matches this route." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" *ngFor="let item of VNetmodel.resourceData.virtualNetworkAddressSpace;let i = index">
                        <div class="d-flex">
                            <input type="text" class="form-control" [class.border-danger]="item.VNetAdrspc" id="VNetAdrSpc" autocomplete="off" [(ngModel)]="routesObj.addressPrefix" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace" (keyup)="VNetAddressSpace($event,item,i)" required>
                            <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete.png" (click)="deleteAddress(VNetmodel,i)" *ngIf="VNetmodel.resourceData.virtualNetworkAddressSpace.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
                        <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use {{item.validCIDRValue}} address space</p>
                        <p class="text-danger" *ngIf="item.overlooping">overlaps with address space {{item.overlapingValue}}</p>
                        <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
                    </div> 
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="nextHopType" class="control-label">Next hop type<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The next hop handles the matching packets for this route. It can be the virtual network, the virtual network gateway, the Internet, a virtual appliance, or none." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="nextHopTypeList" bindLabel="name" bindValue="id" [(ngModel)]="routesObj.nextHopType" name="nextHopType" placeholder="" [clearable]="false"></ng-select>
                    </div>   
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="nextHopIpAddress" class="control-label">Next hop address<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The IP address of the next hop. This can be set only when the 'Next hop type' is 'Virtual appliance'." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" *ngFor="let item of VNetmodel.resourceData.virtualNetworkAddressSpace;let i = index">
                        <div class="d-flex">
                            <input type="text" class="form-control" [class.border-danger]="item.VNetAdrspc" id="VNetAdrSpc" autocomplete="off" [disabled]="routesobj.nextHopType=='VirtualNetworkGateway' && 'VirtualNetwork' && 'Internet' && 'None'" [(ngModel)]="routesObj.nextHopIpAddress" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace" (keyup)="VNetAddressSpace($event,item,i)" required>
                            <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete.png" (click)="deleteAddress(VNetmodel,i)" *ngIf="VNetmodel.resourceData.virtualNetworkAddressSpace.length>1"></span>
                        </div>
                        <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
                        <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use {{item.validCIDRValue}} address space</p>
                        <p class="text-danger" *ngIf="item.overlooping">overlaps with address space {{item.overlapingValue}}</p>
                        <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
                    </div>
                </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('routesObj')" (click)="saveSubSideSheet('routesObj')">Save</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('lawObj')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
    </div>

    <div class="sidebar-right" *ngIf="routeTablemodel.subnetsShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Associate Subnet</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="routeTablemodel.subnetsShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                               
                <div class="form-group">
                    <div class="label-div">
                        <label for="VNetName" class="control-label ">Virtual network<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only virtual networks in the currently selected subscription and location are listed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="existingVNList" bindLabel="name" [(ngModel)]="subnetsObj.VNetName" name="VNetName" placeholder="" (change)="getVirtualNetworkList()" [clearable]="false" required>
                        </ng-select>
                    </div>              
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="SubnetName" class="control-label">Subnet<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="existingSubnetList" bindLabel="name" [(ngModel)]="subnetsObj.subnetName" name="subnetName" placeholder="" (change)="getSubnetList()" [clearable]="false" required>
                        </ng-select>
                    </div>              
                </div>
               
                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('subnetsObj')" (click)="saveSubSideSheet('subnetsObj')">Save</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('lawObj')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
    </div>
    </div>
</ng-template>

<ng-template #keyVault>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':applicationInsightmodel.lawShow }">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Key Vault</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="keyVaultmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="name" class="control-label">Key vault name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Vault name must only contain alphanumeric characters and dashes and cannot start with a number." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="keyVaultmodel.resourceName" placeholder="Enter a name" name="name" (keyup)="laNameValidation($event,3,24);lawDuplicateCheck($event)" required>
                        <p class="text-danger" *ngIf="inputError?.laname">Vault name must be between 3 and 24 characters in length.</p>
                        <p class="text-danger" *ngIf="inputError?.laSpecialChar">Vault name must only contain alphanumeric characters and dashes and cannot start with a number. </p>
                        <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Vault name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                        <p class="text-danger" *ngIf="inputError?.duplicateLaw">This name is already in use, or is still being reserved by a vault which was previously soft deleted. Please use a different name.</p>
                        </div>         
                </div>

                <div class="form-group">
                    <div class="label-div">
                        <label for="pricingTier" class="control-label">Pricing tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure Key Vault service is offered in two service tiers: standard and premium. Premium include support for HSM backed keys." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="pricingTier" [value]="'Standard'" ([ngModel])="keyVaultmodel.resourceData.pricingTier" [checked]="keyVaultmodel.resourceData.pricingTier=='Standard'" (change)="keyVaultmodel.resourceData.pricingTier=$event.target.value;" ><span>Standard</span></label>
                        <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="pricingTier" [value]="'Premium'" ([ngModel])="keyVaultmodel.resourceData.pricingTier" [checked]="keyVaultmodel.resourceData.pricingTier=='Premium'" (change)="keyVaultmodel.resourceData.pricingTier=$event.target.value;"><span>Premium</span></label>                    
                    </div> 
                </div>
            
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="keyVaultmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 

                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
        <div *ngIf="showView">

            <div class="form-group">
            <div class="label-div">
                <label for="keys" class="control-label">Keys</label> 
            </div>
            <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="keyVaultmodel.keysShow=true;">Add key</p>             
            </div>  
           </div>

           <div class="form-group">
            <div class="label-div">
                <label for="secrets" class="control-label">Secrets</label> 
            </div>
            <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="keyVaultmodel.secretsShow=true;">Add secret</p>             
            </div>  
           </div>
        
           <div class="form-group">
            <div class="label-div">
                <label for="recovery" class="load">Recovery options</label>
                <label for="enableSoftDelete" class="control-label">Soft-delete<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The ability to turn off soft delete via the Azure Portal has been deprecated. You can create a new key vault with soft delete off for a limited time using 
                    CLI / PowerShell / REST API. The ability to create a key vault with soft delete 
                    disabled will be fully deprecated by the end of the year." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                <label for="enableSoftDeleteStatus" class="control-label">Enabled</label>           
            </div>
           </div>

           <div class="form-group">
            <div class="input-box">
                    <div class="label-div">
                        <label for="softDeleteRetentionInDays" class="control-label col-sm-2">Days to retain deleted vaults<span class="mandatory"><img class="tooltip-icon"  src="/assets/Infra/info.svg" alt="" matTooltip=" It can be configured to between 7 to 90 days. Once it has been set, it cannot be changed or removed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.restoredays" id="restoredays" autocomplete="off" [(ngModel)]="keyVaultmodel.resourceData.softDeleteRetentionInDays" placeholder="" name="softDeleteRetentionInDays" (keyup)="changeDaysRestore($event,'restoredays')" required>
                        <!-- <p class="text-danger" *ngIf="inputError?.subnetAdrspc">This value must be less than the value (90) that's set for blob soft delete.</p> -->
                        <p class="text-danger" *ngIf="inputError?.restoredays">The value must be between 7 and 90.</p>
                    </div>              
            </div>
        </div>

        <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="enablePurgeProtection" class="control-label ">Purge protection<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enabling purge protection on a key vault is an irreversible action. Once the purge protection property has been set to true, it cannot be changed or removed" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box radio-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="enablePurgeProtection" [value]="'disablePurgeProtection'" ([ngModel])="keyVaultmodel.resourceData.enablePurgeProtection" [checked]="keyVaultmodel.resourceData.enablePurgeProtection=='disablePurgeProtection'" (change)="keyVaultmodel.resourceData.enablePurgeProtection=$event.target.value;"><span><b>Disable purge protection</b></span></label>
                <label class="radio-label" style="padding-left: 19px;">Allow key vault and objects to be purged during retention period.</label>
                <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="enablePurgeProtection" [value]="'enablePurgeProtection'" ([ngModel])="keyVaultmodel.resourceData.enablePurgeProtection" [checked]="keyVaultmodel.resourceData.enablePurgeProtection=='enablePurgeProtection'" (change)="keyVaultmodel.resourceData.enablePurgeProtection=$event.target.value;"><span><b>Enable purge protection</b></span></label>                    
                <label class="radio-label" style="padding-left: 19px;">Enforce a mandatory retention period for deleted vaults and vault objects.</label>
            </div>             
        </div>

        <div class="form-group">
            <div class="label-div">
                <label for="accessPolicy" class="load">Access policy</label>
                <label for="enableAccessTo" class="control-label">Enable access to:</label>
            </div>
            <div class="input-box">
                <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enabledForDeployment" id="enabledForDeployment" [(ngModel)]="keyVaultmodel.resourceData.enabledForDeployment" ><span>Azure Virtual Machines for deployment<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specifies whether Azure Virtual Machines are permitted to retrieve certificates stored as secrets from the key vault." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enabledForTemplateDeployment" id="enabledForTemplateDeployment" [(ngModel)]="keyVaultmodel.resourceData.enabledForTemplateDeployment" ><span>Azure Resource Manager for template deployment<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specifies whether Azure Resource Manager is permitted to retrieve secrets from the key vault." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="enabledForDiskEncryption" id="enabledForDiskEncryption" [(ngModel)]="keyVaultmodel.resourceData.enabledForDiskEncryption"><span>Azure Disk Encryption for volume encryption<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Specifies whether Azure Disk Encryption is permitted to retrieve secrets from the vault and unwrap keys." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
        </div>

        <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="permissionModel" class="control-label ">Permission model</label>
            </div>
            <div class="input-box radio-flex">
                <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="enableRbacAuthorization" [value]="'vaultAccessPolicy'" ([ngModel])="keyVaultmodel.resourceData.enableRbacAuthorization" [checked]="keyVaultmodel.resourceData.enableRbacAuthorization=='vaultAccessPolicy'" (change)="keyVaultmodel.resourceData.enableRbacAuthorization=$event.target.value"><span>Vault access policy</span></label>
                <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin:0px;"><input type="radio" name="enableRbacAuthorization" [value]="'enablePurgeProtection'" ([ngModel])="keyVaultmodel.resourceData.enableRbacAuthorization" [checked]="keyVaultmodel.resourceData.enableRbacAuthorization=='enablePurgeProtection'" (change)="keyVaultmodel.resourceData.enableRbacAuthorization=$event.target.value"><span>Enable purge protection</span></label>
            </div>             
        </div>

        <div class="form-group">
            <div class="label-div">
                <label for="currentAccessPolicy" class="load">Current access policies</label>
                <label for="user" class="control-label">user</label>
                <label for="userValue" class="load">A3S Team</label>
            </div>
            <div class="add-another-tag">
                <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="keyVaultmodel.accessPoliciesShow=true;">Add Access Policy</p>             
            </div>
        </div>
       
                   
                    <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="keyVaultmodel.resourceData&&keyVaultmodel.resourceData.tagList&&keyVaultmodel.resourceData.tagList.length>1"></label>
                          <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                        </div>
                        <div *ngFor="let item of keyVaultmodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="keyVaultmodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('keyVault')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('keyVault')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>

 <div class="sidebar-right" *ngIf="applicationInsightmodel.lawShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Log Analytics Workspace</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="applicationInsightmodel.lawShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
               
                
                <div class="form-group">
                    <div class="label-div">
                        <label for="logAnalytics" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace name should include 4-63 letters, digits or '-'. The '-' shouldn't be the first or the last symbol." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.laname || inputError?.laSpecialChar || inputError?.VmSpecialChar || inputError?.duplicateLaw" id="logAnalytics" autocomplete="off" [(ngModel)]="lawObj.name" placeholder="Enter a workspace name" name="logAnalytics" (keyup)="laNameValidation($event,4,63);lawDuplicateCheck($event)" required>
                    <p class="text-danger" *ngIf="inputError?.laname">The workspace name must be between 4 and 63 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.laSpecialChar">The workspace name can contain only letters, numbers and '-'. The '-' shouldn't be the first or the last symbol. </p>
                    <p class="text-danger" *ngIf="inputError?.VmSpecialChar">Workspace name cannot contain special characters \/""[]:|<>+=;,?*@&,</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateLaw">Workspace name must be unique in the current resource group.</p>
                    </div>              
                </div>

                
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="checkDataDisabled('lawObj')" (click)="saveSubSideSheet('lawObj')">Save</button>
                    <!-- <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('lawObj')" (click)="triggerBtn('update')">Update</button> -->
                </div>
            </div>
    </div>
</ng-template>

<!-- Machine Learning Side Sheet Template -->
<ng-template #machineLearning>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':machineLearningmodel.MLSelectKeyShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Machine Learning</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="machineLearningmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div> 
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label for="workspaceName" class="control-label">Workspace name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace name must be unique within your resource group." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.VMname||inputError?.duplicateAi" id="workspaceName" autocomplete="off" [(ngModel)]="machineLearningmodel.resourceName" placeholder="" name="workspaceName" (keyup)="workspaceNameValidation($event,33);">
                    <p class="text-danger" *ngIf="inputError?.VMname">Workspace name must be between 3 and 33 characters long. Its first 
                        character has to be alphanumeric, and the rest may contain hyphens and underscores. No whitespace is allowed.</p>
                    <p class="text-danger" *ngIf="inputError?.duplicateAi">This workspace name already exists</p>
                    </div>              
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="storageAccount" class="control-label">Storage account<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A storage account is used as the default datastore for the workspace. 
                            You may create a new Azure Storage resource or select an existing one 
                            in your subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="storageAccountList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.storageAccount" name="storageAccount" placeholder="" [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                {{!item?.id?'(new) ':''}}{{item?.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                {{!item?.id?'(new) ':''}}{{item?.name}}
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                        <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [ngbPopover]="createMLStorageAccount" [autoClose]="false" 
                        triggers="manual" [placement]="'bottom left'" popoverClass="my-custom-class" #p="ngbPopover" (click)="p.open()">Create new</p>   -->
                        <!-- container="body" -->
                        <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                        popoverTitle="Popover on top"
                        placement="top">Create new</p>  -->
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLStorageAccount"
                        triggers="" placement="bottom" containerClass="custom-popover" #p="bs-popover" (click)="closeOtherPop(undefined,popCR);openPop(p,'createMLStorageAccount');">Create new</p> 
                    </div>  
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="keyVault" class="control-label">Key vault<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A key vault is used to store secrets and other sensitive information that is 
                            needed by the workspace. You may create a new Azure Key Vault resource 
                            or select an existing one in your subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="keyVaultList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.keyVault" name="keyVault" placeholder="" [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                {{!item?.id?'(new) ':''}}{{item?.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                {{!item?.id?'(new) ':''}}{{item?.name}}
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                        <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createMLKeyVault()">Create new</p>   -->
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLKeyVault"
                        triggers="" placement="bottom" containerClass="custom-popover" #popKey="bs-popover" [outsideClick]="true" (click)="closeOtherPop(p,popCR);openPop(popKey,'createMLKeyVault')">Create new</p> 
                    </div>  
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="applicationInsights" class="control-label">Application insights<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The workspace uses Azure Application Insights to store monitoring information 
                            about your deployed models. You may create a new Azure Application Insights 
                            resource or select an existing one in your subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="applicationInsightsList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.applicationInsights" name="applicationInsights" placeholder="" [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                {{!item?.id?'(new) ':''}}{{item?.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                {{!item?.id?'(new) ':''}}{{item?.name}}
                            </ng-template>
                        </ng-select>
                    </div>  
                    <div class="add-another-tag">
                        <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createMLApplicationInsights()">Create new</p>   -->
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLApplicationInsights"
                        triggers="" placement="bottom" containerClass="custom-popover" #popAI="bs-popover" [outsideClick]="true" (click)="closeOtherPop(p,popCR);openPop(popAI,'createMLApplicationInsights')">Create new</p> 
                    </div>  
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="containerRegistry" class="control-label">Container registry<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A container registry is used to register docker images used in training and deployments. 
                            To minimize costs, a new Azure Container Registry resource is created only after you build
                            your first image. Alternatively, you may choose to create the resource now or select
                            an existing one in your subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="containerRegistryList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.containerRegistry" name="containerRegistry" placeholder="" [clearable]="false"></ng-select>
                    </div>  
                    <div class="add-another-tag">
                        <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createMLContainerRegistry()">Create new</p>   -->
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLContainerRegistry"
                        triggers="" placement="top" containerClass="custom-popover" container="body" #popCR="bs-popover" (click)="closeOtherPop(p,undefined);openPop(popCR,'createMLContainerRegistry')">Create new</p> 
                    </div>  
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="machineLearningmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div> 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
                <div *ngIf="showView">
                    <div class="form-group">
                        <div class="label-div">
                            <label class="control-label text-weight">Managed identity<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A managed identity enables Azure resources to authenticate to cloud 
                                services without storing credentials in code. Once enabled, all necessary 
                                permissions can be granted via Azure role-based access control. A workspace 
                                can be given either a system assigned identity or a user assigned identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                            <label for="identityType" class="control-label ">Identity type</label>
                        </div>
                        <div class="input-box radio-flex">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="identityType" [value]="'systemAssigned'" ([ngModel])="machineLearningmodel.resourceData.identityType" [checked]="machineLearningmodel.resourceData.identityType=='systemAssigned'" (change)="machineLearningmodel.resourceData.identityType=$event.target.value;"><span>System assigned identity</span></label>                    
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="identityType" [value]="'userAssigned'" ([ngModel])="machineLearningmodel.resourceData.identityType" [checked]="machineLearningmodel.resourceData.identityType=='userAssigned'" (change)="machineLearningmodel.resourceData.identityType=$event.target.value;"><span>User assigned identity</span></label>                    
                        </div>             
                    </div>
                    <div class="form-group" *ngIf="machineLearningmodel?.resourceData?.identityType=='userAssigned'">
                        <div class="label-div">
                            <label for="userManagedIdentity" class="control-label">Select user assigned managed identity<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box">
                            <ng-select [items]="userManagedIdentityList" bindLabel="name" [(ngModel)]="machineLearningmodel.resourceData.userManagedIdentity" name="userManagedIdentity" placeholder="" [clearable]="false"></ng-select>
                        </div>   
                        <ng-template #createMLUserManagedIdentity>
                            <div class="form-group">
                                <div class="label-div">
                                    <label class="control-label text-weight">Create Managed Identity</label>
                                    <label for="managedIdentity" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Name of your User Assigned Managed Identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                                </div>
                                <div class="input-box">
                                    <input type="text" class="form-control" [class.border-danger]="inputError?.managedIdentityName||inputError?.managedIdentityDup" id="managedIdentity" autocomplete="off" [(ngModel)]="createMLUMIObj.name" placeholder="" name="managedIdentity" (keyup)="manageIdentityValidation($event,3,128)">
                                    <p class="text-danger" *ngIf="inputError?.managedIdentityDup">This managed identity is already existed.</p>
                                    <p class="text-danger" *ngIf="inputError?.managedIdentityName">The resource name must begin with a letter or a number 
                                        and with a length between 3 to 128. Can contain '-' and '_'</p>
                                    </div>   
                            </div>
                            <div class="d-flex">
                                <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLUMIObj.name||inputError?.managedIdentityName||inputError?.managedIdentityDup" (click)="saveSubSideSheet('createMLUserManagedIdentity');popUMI.hide();">Ok</button>
                                <button class="btn btn-outline" (click)="popUMI.hide();">Cancel</button>
                            </div>
                        </ng-template>
                        <div class="add-another-tag">   
                            <!-- <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createMLUserManagedIdentity()">Create new</p> -->
                            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createMLUserManagedIdentity"
                        triggers="" placement="bottom" containerClass="custom-popover" #popUMI="bs-popover" [outsideClick]="true" (click)="openPop(popUMI,'createMLUserManagedIdentity')">Create new</p> 
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label class="control-label text-weight">Storage account access<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure machine learning allows you to choose between credential-based or identity-based 
                                access when connecting to the default storage account. When using identity-based 
                                authentication, the Storage Blob Data Contributor role must be granted to the 
                                workspace managed identity on the storage account." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                            <label for="accessType" class="control-label ">Storage account access type</label>
                        </div>
                        <div class="input-box radio-flex">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="accessType" [value]="'accessKey'" ([ngModel])="machineLearningmodel.resourceData.accessType" [checked]="machineLearningmodel.resourceData.accessType=='accessKey'" (change)="machineLearningmodel.resourceData.accessType=$event.target.value;"><span>Credential-based access</span></label>                    
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="accessType" [value]="'identity'" ([ngModel])="machineLearningmodel.resourceData.accessType" [checked]="machineLearningmodel.resourceData.accessType=='identity'" (change)="machineLearningmodel.resourceData.accessType=$event.target.value;"><span>Identity-based access</span></label>                    
                        </div>             
                    </div>
                    <div class="form-group">
                        <div class="label-div">
                            <label class="control-label text-weight">Data encryption<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure machine learning service stores metrics and metadata in an Azure Cosmo DB instance where 
                                all data is encrypted ​at rest. By default, the data is encrypted with Microsoft-managed keys. You may 
                                choose to bring your own (customer-managed) keys.​" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></label>
                            <label for="encryptionType" class="control-label ">Encryption type</label>
                        </div>
                        <div class="input-box radio-flex">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="encryptionType" [value]="'Microsoft'" ([ngModel])="machineLearningmodel.resourceData.encryptionType" [checked]="machineLearningmodel.resourceData.encryptionType=='Microsoft'" (change)="machineLearningmodel.resourceData.encryptionType=$event.target.value;"><span>Microsoft-managed keys</span></label>                    
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="encryptionType" [value]="'Customer'" ([ngModel])="machineLearningmodel.resourceData.encryptionType" [checked]="machineLearningmodel.resourceData.encryptionType=='Customer'" (change)="machineLearningmodel.resourceData.encryptionType=$event.target.value;"><span>Customer-managed keys</span></label>                    
                        </div>             
                    </div>
                    <div class="form-group" *ngIf="machineLearningmodel?.resourceData?.encryptionType=='Customer'">
                        <div class="label-div">
                            <label class="control-label" style="margin-bottom: 0px;padding-bottom: 0px !important;">Key vault<span class="mandatory">*</span></label>
                        </div>
                        <div class="input-box" style="line-height: 18px !important;" *ngIf="machineLearningmodel?.resourceData?.encrypKeyVault">
                            <label class="control-label" style="padding-left: 0px;">Key vault : <span>{{machineLearningmodel?.resourceData?.encrypKeyVault?.keyVault?.name}}</span></label>
                            <label class="control-label" style="padding-left: 0px;" *ngIf="machineLearningmodel?.resourceData?.encrypKeyVault?.key">Key : <span>{{machineLearningmodel?.resourceData?.encrypKeyVault?.key?.kid.split('/')[machineLearningmodel?.resourceData?.encrypKeyVault?.key?.kid.split('/').length-1]}}</span></label>
                            <label class="control-label" style="padding-left: 0px;" *ngIf="machineLearningmodel?.resourceData?.encrypKeyVault?.version">Version : <span>{{machineLearningmodel?.resourceData?.encrypKeyVault?.version?.kid.split('/')[machineLearningmodel?.resourceData?.encrypKeyVault?.version?.kid.split('/').length-1]}}</span></label>
                        </div>   
                        <div class="add-another-tag">   
                            <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="selectMLEncrypKeyVault()">Select key</p>
                        </div>
                    </div>
                    <div class="form-group" style="margin-bottom: 10px !important;">
                        <div class="label-div">
                            <label class="control-label text-weight">Data impact</label>
                        </div> 
                        <div class="input-box">
                            <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="highBusiness" id="highBusiness" [(ngModel)]="machineLearningmodel.resourceData.highBusiness" ><span>High business impact workspace</span></label>
                        </div>           
                    </div>
                    <div class="form-group">
                        <div class="label-div d-flex">
                          <label class="control-label " style="min-width: max-content;">Tag name </label>
                          <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                          </label>
                          <label style="padding-left: 6px !important;width: 18px;"
                            *ngIf="machineLearningmodel.resourceData&&machineLearningmodel.resourceData.tagList&&machineLearningmodel.resourceData.tagList.length>1"></label>
                        </div>
                        <div *ngFor="let item of machineLearningmodel.resourceData.tagList;let i = index">
                          <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
      
                            <div class="input-box" style="padding-bottom: 6px;">
                              <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                                [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                            </div>
      
                            <div style="padding-left: 6px;padding-right: 6px;">:</div>
      
                            <div class="input-box">
                              <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                                [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                            </div>
      
                            <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                                *ngIf="machineLearningmodel.resourceData.tagList.length>1"></span>
                          </div>
                          <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                            512.</p>
                          <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                            <>%&\?/.</p>
                          <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                          <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                            is 256.</p>
                        </div>
      
                        <div class="add-another-tag">
                          <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                        </div>
                      </div>
                </div>

                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('machineLearning')" (click)="triggerBtn('save')">Save</button>
                    <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('machineLearning')" (click)="triggerBtn('update')">Update</button>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-right" *ngIf="machineLearningmodel.MLSelectKeyShow">
        <div>
            <div class="header">
                <div class="d-flex">
                   <h4>Select a key</h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();machineLearningmodel.MLSelectKeyShow=false;">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="label-div d-flex" *ngIf="keyForMLObj?.keyVault&&!keyList.length&&!versionList.length" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                    <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 0px !important;color: #703501;">
                        The operation "List" is not enabled in this key vault's access policy.</label>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="keyVault" class="control-label">Key vault<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="keyVaultListForKey" bindLabel="name" [(ngModel)]="keyForMLObj.keyVault" name="keyVault" placeholder="" (change)="keyVaultChangeForKey()" [clearable]="false"></ng-select>
                    </div> 
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="key" class="control-label">Key<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="keyList" bindLabel="name" [disabled]="keyForMLObj?.keyVault&&!keyList.length" [(ngModel)]="keyForMLObj.key" (change)="keyChangeForVersion()" name="key" placeholder="" [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                            </ng-template>
                        </ng-select>
                    </div> 
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="version" class="control-label">Version<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only the versions meeting following requirement(s) will enabled.
                            Required key type(s): RSA,RSA-HSM,EC,EC-HSM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="versionList" bindLabel="name" [disabled]="keyForMLObj?.keyVault&&!versionList.length" [(ngModel)]="keyForMLObj.version" name="version" placeholder="" [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                            </ng-template>
                        </ng-select>
                    </div> 
                </div>
                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary" [class.disabled]="!keyForMLObj?.keyVault||!keyForMLObj?.key||!keyForMLObj?.version" (click)="saveSubSideSheet('MLSelectKey')">Select</button>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="loader-fs" *ngIf="apiloader">
        <img class="loader"  src="./assets/spinloader.gif">
      </div> -->
    <ng-template #createMLStorageAccount>
        <div class="form-group">
            <div class="label-div">
                <label class="control-label text-weight">Create Storage Account</label>
                <label for="nameSA" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
&#8226; Unique across all existing storage account names in Azure
&#8226; Between 3 and 24 characters long
&#8226; Only contain lowercase letters and numbers" matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName||inputError?.duplicateSa" id="nameSA" autocomplete="off" [(ngModel)]="createMLSAObj.name" placeholder="" name="nameSA" (keyup)="storageMLValidation($event,3,24)">
                <p class="text-danger" *ngIf="inputError?.storageAccountName">Storage name must be between 3 and 24 characters and may only 
                    contain lowercase letters and numbers.</p>
                <p class="text-danger" *ngIf="inputError?.duplicateSa">Another storage account with this name already exists.</p>
                </div>   
        </div>
        <div class="form-group">
            <div class="label-div">
                <label for="replication" class="control-label">Replication<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The data in your storage account is always replicated to ensure durability 
                    and high availability. Choose a replication strategy that best matches you 
                    requirements." matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="tempStorageSkusList" bindLabel="rdname" bindValue="name" [(ngModel)]="createMLSAObj.replication" name="replication" placeholder="" [clearable]="false"></ng-select>            
            </div>
        </div>
        <div class="d-flex">
            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLSAObj.name||!createMLSAObj.replication||inputError?.storageAccountName||inputError?.duplicateSa" (click)="saveSubSideSheet('createMLStorageAccount');p.hide();">Save</button>
            <button class="btn btn-outline" (click)="p.hide();">Cancel</button>
        </div>
        <!-- <div class="form-group">
            <div class="label-div">
                <label class="control-label text-weight">Create Storage Account</label>
                <label for="identityType" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
                    Unique across all existing storage account names in Azure
                    Between 3 and 24 characters long
                    Only contain lowercase letters and numbers" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.VMname" id="workspaceName" autocomplete="off" [(ngModel)]="machineLearningmodel.resourceName" placeholder="" name="workspaceName" (keyup)="VmNameValidation($event,65)">
                <p class="text-danger" *ngIf="inputError?.VMname">Workspace name must be between 3 and 33 characters long. Its first 
                    character has to be alphanumeric, and the rest may contain hyphens and underscores. No whitespace is allowed.</p>
                </div>             
        </div>
        <div class="form-group">
            <div class="label-div">
                <label for="identityType" class="control-label">Replication<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The data in your storage account is always replicated to ensure durability 
                    and high availability. Choose a replication strategy that best matches you 
                    requirements." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="keyVaultList" bindLabel="name" [(ngModel)]="machineLearningmodel.keyVault" name="keyVault" placeholder="" [clearable]="false"></ng-select>            
            </div>
        </div>
        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('machineLearning')" (click)="triggerBtn('save')">Save</button>
            <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('machineLearning')" (click)="triggerBtn('update')">Update</button>
        </div> -->
    </ng-template>
    <ng-template #createMLKeyVault>
        <div class="form-group">
            <div class="label-div">
                <label class="control-label text-weight">Create Key Vault</label>
                <label for="mlKeyName" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
&#8226; Unique across all existing key vaults in Azure
&#8226; Between 3 and 24 characters long
&#8226; Only contain alphanumeric characters and hyphens
&#8226; Cannot start with a number" matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.keyVaultNamelen||inputError?.keyVaultName||inputError?.keyVaultDup" id="mlKeyName" autocomplete="off" [(ngModel)]="createMLKeyObj.name" placeholder="" name="mlKeyName" (keyup)="keyMLValidate($event,3,24)">
                <p class="text-danger" *ngIf="inputError?.keyVaultNamelen">The value must be between 3 and 24 characters long.</p>
                <p class="text-danger" *ngIf="inputError?.keyVaultName">Vault name must be between 3-24 alphanumeric characters. 
                    The name must begin with a letter, end with a letter or digit, 
                    and not contain consecutive hyphens.</p>
                <p class="text-danger" *ngIf="inputError?.keyVaultDup">Another key vault with this name already exists.</p>
                </div>   
        </div>
        <div class="d-flex">
            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLKeyObj.name||inputError?.keyVaultNamelen||inputError?.keyVaultName||inputError?.keyVaultDup" (click)="saveSubSideSheet('createMLKeyVault');popKey.hide();">Ok</button>
            <button class="btn btn-outline" (click)="popKey.hide();">Cancel</button>
        </div>
    </ng-template>
    <ng-template #createMLApplicationInsights>
        <div class="form-group">
            <div class="label-div">
                <label class="control-label text-weight">Create Application Insights</label>
                <label for="aiName" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
&#8226; Unique across the resource group
&#8226; Between 1 and 255 characters long
&#8226; Only contain alphanumeric characters, periods, underscores, hyphens, and parenthesis
&#8226; Cannot end with a period" matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.appInsightName||inputError?.appInsightDup" id="aiName" autocomplete="off" [(ngModel)]="createMLAIObj.name" placeholder="" name="aiName" (keyup)="aiMLValidation($event,255)">
                <p class="text-danger" *ngIf="inputError?.appInsightDup">Application insight name already exists within the same resource group.</p>
                <p class="text-danger" *ngIf="inputError?.appInsightName">The name must contain between 1 to 255 characters inclusive. The name only allows 
                    alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot 
                    end in a period.</p>
                </div>   
        </div>
        <div class="d-flex">
            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLAIObj.name||inputError?.appInsightName||inputError?.appInsightDup" (click)="saveSubSideSheet('createMLApplicationInsights');popAI.hide();">Ok</button>
            <button class="btn btn-outline" (click)="popAI.hide();">Cancel</button>
        </div>
    </ng-template>
    <ng-template #createMLContainerRegistry>
        <div class="form-group">
            <div class="label-div">
                <label class="control-label text-weight">Create Container Registry</label>
                <label for="contaierName" class="control-label">Name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The name must meet the following requirements:
&#8226; Unique across all container registries in Azure
&#8226; Between 5 and 50 characters long
&#8226; Only contain alphanumeric characters" matTooltipClass="nav-tooltips nav-tooltips-preline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.containerRgName||inputError?.containerRgDup" id="contaierName" autocomplete="off" [(ngModel)]="createMLCRObj.name" placeholder="" name="contaierName" (keyup)="containerMLValidation($event,5,50)">
                <p class="text-danger" *ngIf="inputError?.containerRgDup">Another container registry with this name already exists.</p>
                <p class="text-danger" *ngIf="inputError?.containerRgName">Resource names may contain alpha numeric characters 
                    only and must be between 5 and 50 characters.</p>
                </div>   
        </div>
        <div class="form-group">
            <div class="label-div">
                <label for="sku" class="control-label">SKU<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="All SKUs provide the same programmatic capabilities. Choosing a higher SKU will provide more performance and scale."  matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
                <ng-select [items]="skuList" bindLabel="name" bindValue="name" [(ngModel)]="createMLCRObj.sku" name="sku" placeholder="" [clearable]="false"></ng-select>            
            </div>
        </div>
        <div class="d-flex">
            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createMLCRObj.name||!createMLCRObj.sku||inputError?.containerRgName||inputError?.containerRgDup" (click)="saveSubSideSheet('createMLContainerRegistry');popCR.hide();">Ok</button>
            <button class="btn btn-outline" (click)="popCR.hide();">Cancel</button>
        </div>
    </ng-template>
    
</ng-template>

<ng-template #synapseAnalytics>
    <kendo-popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
        <div class='content vnet-resource-content'>
            <div class="rgPopup-form">
                <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
                <div class="input-box">
                <input type="text" class="form-control"  id="rgName" autocomplete="off" [(ngModel)]="newResourceGroup.resourceGroupName" (keyup)="validateResourceName($event,90)" placeholder="Enter name" name="rgName" required>
                <p class="text-danger" *ngIf="inputError?.maxLenResourceName">Resource group names only allow up to 90 characters</p>
                <p class="text-danger" *ngIf="inputError?.duplicateResourceName">A resource group with the same name already exists in the selected subscription.</p>
                <p class="text-danger" *ngIf="inputError?.validResourceName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                </div>              
            </div>
            <div class="rgPopup-form">
                <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
                <div class="input-box">
                <ng-select [items]="resourcesGroupLocationList" class="form-control" bindLabel="key" bindValue="value" [(ngModel)]="newResourceGroup.resourceGroupLocation" name="resourceGroupLocation" placeholder="Choose resource group location" [clearable]="false" required>
                </ng-select>
                </div>
            </div>
            <div class="rgPopup-form error" *ngIf="rgSubscriptionCheck">
                You don't have the access to create a new resource group for this subscription
            </div>
            <div class="d-flex">
                <button class="btn btn-btn-primary" *ngIf="!newResourceGroup.newResourceGroupEdit" (click)="createNewRgHelper()" [class.disabled]="checkDisable('rg-popup')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="newResourceGroup.newResourceGroupEdit" (click)="updateNewResourceGroup()" [class.disabled]="checkDisable('rg-popup')">Update</button>
                <button class="btn btn-outline" (click)="onToggle()">Cancel</button>
            </div>
        </div>
    </kendo-popup>
    <div class="sidebar-right" [ngClass]="{'content-move-sub-sidebar':synapseAnalyticsmodel.SADedicatedPoolShow||synapseAnalyticsmodel.SAApacheSprakPoolShow||synapseAnalyticsmodel.SAAzureADTenantShow}">
        <div>
            <div class="header">
                <div class="d-flex">
                  <h4>Synapse Workspace</h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <div class="label-div">
                        <label for="VMsize" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="synapseAnalyticsmodel.resourceData.resourceGroup" name="VMsize" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 8px;"><i class="fa fa-trash" (click)="deleteNewResourceGroup(item,index)"></i></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 25px;"><img src="/assets/Infra/blueprint/palette/edit.svg" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="add-another-tag">
                      <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle()">Create new
                      </p>
                    </div>
                </div> 
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight">Workspace details</label>
                        <label for="workspaceName" class="control-label">Workspace name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.workspaceMaxLen||inputError?.workspaceNameCheck||inputError?.workspaceStartCheck||inputError?.workspaceEndCheck||inputError?.workspacePartText||inputError?.workspaceNameUnqiue" id="workspaceName" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceName" placeholder="" name="workspaceName" (keyup)="validateWorksapceName($event,50)">
                        <p class="text-danger" *ngIf="inputError?.workspaceMaxLen">Workspace name must be between 1 and 50 characters long.</p>
                        <p class="text-danger" *ngIf="inputError?.workspaceNameCheck">Workspace name must contain only lowercase letters or numbers or hyphens.</p>
                        <p class="text-danger" *ngIf="inputError?.workspaceStartCheck">Workspace name must start with a letter or a number.</p>
                        <p class="text-danger" *ngIf="inputError?.workspaceEndCheck">Workspace name must end with a letter or a number.</p>
                        <p class="text-danger" *ngIf="inputError?.workspacePartText">Workspace name must not contain '-ondemand' word.</p>
                        <p class="text-danger" *ngIf="inputError?.workspaceNameUnqiue">Workspace name must be unique.</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="dataLakeStorageGen" class="control-label">Select Data Lake Storage Gen2<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This account will be the primary storage account for the workspace, 
                            holding catalog data and metadata associated with the workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box d-flex">
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="dataLakeStorageGen" [value]="'subscriptions'" ([ngModel])="synapseAnalyticsmodel.resourceData.dataLakeStorageGen" [checked]="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'" (change)="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=$event.target.value;"><span>From subscription</span></label>
                        <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="dataLakeStorageGen" [value]="'manually'" ([ngModel])="synapseAnalyticsmodel.resourceData.dataLakeStorageGen" [checked]="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'" (change)="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=$event.target.value;"><span>Manually via URL</span></label>                    
                    </div>                     
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="accountName" class="control-label">Account name<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only Data Lake Storage Gen2 accounts with Hierarchical 
                            Name Space (HNS) enabled are listed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    </div>
                    <div class="input-box" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'">
                        <ng-select [items]="storageAccountList" bindLabel="name" [(ngModel)]="synapseAnalyticsmodel.resourceData.storageAccount" name="accountName" (change)="changeStorageAccountSA()" placeholder="" [clearable]="false">
                        </ng-select>
                    </div>  
                    <div class="input-box" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'">
                        <input type="text" class="form-control" [class.border-danger]="!inputError?.accountNameCheck||inputError?.accountNameValid" id="accountName" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.accountNameVal" placeholder="" name="accountName" (keyup)="validateAccountNameSA($event,3,24)">
                        <span class="dns-helper-label">.dfs.core.windows.net</span>
                        <p class="text-danger" *ngIf="!inputError?.accountNameCheck">There was no storage account found with this name.</p>
                        <p class="text-danger" *ngIf="inputError?.accountNameValid">Account name can contain only lowercase letters 
                            and numbers. Name must be between 3 and 24 characters.</p>
                    </div>
                    <div class="add-another-tag" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'">
                        <ng-template #createSAAccount>
                            <div class="form-group">
                                <div class="label-div">
                                    <label class="control-label text-weight">Data Lake Storage Gen2 account</label>
                                    <label for="nameSAAccount" class="control-label">Name<span class="mandatory">*</span></label>
                                </div>
                                <div class="input-box">
                                    <input type="text" class="form-control" [class.border-danger]="inputError?.storageAccountName||inputError?.duplicateSa" id="nameSAAccount" autocomplete="off" [(ngModel)]="createSAAccObj.name" placeholder="" name="nameSAAccount" (keyup)="storageMLValidation($event,3,24)">
                                    <p class="text-danger" *ngIf="inputError?.storageAccountName">Storage account name must be between 3 and 24 characters
                                        in length and use numbers and lower-case letters only.</p>
                                    <p class="text-danger" *ngIf="inputError?.duplicateSa">This name already exists.</p>
                                    </div>   
                            </div>
                            <div class="d-flex">
                                <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createSAAccObj.name||inputError?.storageAccountName||inputError?.duplicateSa" (click)="saveSubSideSheet('createSAAccount');p.hide();">Save</button>
                                <button class="btn btn-outline" (click)="p.hide();">Cancel</button>
                            </div>
                        </ng-template> 
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createSAAccount"
                        triggers="" placement="bottom" containerClass="custom-popover" #p="bs-popover" [outsideClick]="true" (click)="closeOtherPop(undefined,popFS);openPop(p,'createSAAccount');">Create new</p> 
                    </div>  
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="fileSystemName" class="control-label">File system name<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'">
                        <ng-select [items]="fileSystemNameList" bindLabel="name" [(ngModel)]="synapseAnalyticsmodel.resourceData.fileSystemName" name="fileSystemName" placeholder="" [clearable]="false">
                        </ng-select>
                    </div>  
                    <div class="input-box" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.fileNameCheck||inputError?.fileNameLen||inputError?.fileNameEndCheck||inputError?.fileNameStartCheck||inputError?.fileNameHypensCheck" id="fileSystemName" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.fileSystemNameVal" placeholder="" name="fileSystemName" (keyup)="validateFileSystemName($event,3,63)">
                        <p class="text-danger" *ngIf="inputError?.fileNameCheck">File system name must contain only lowercase letters, numbers, or hyphens.</p>
                        <p class="text-danger" *ngIf="inputError?.fileNameLen">File system name must be between 3 and 63 characters long.</p>
                        <p class="text-danger" *ngIf="inputError?.fileNameEndCheck">File system name must end with a letter or a number.</p>
                        <p class="text-danger" *ngIf="inputError?.fileNameStartCheck">File system name must start with a letter or a number.</p>
                        <p class="text-danger" *ngIf="inputError?.fileNameHypensCheck">File system name must not contain two consecutive hyphens.</p>
                    </div>
                    <div class="add-another-tag" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'">
                        <ng-template #createSAFile>
                            <div class="form-group">
                                <div class="label-div">
                                    <label class="control-label text-weight">Data Lake Storage Gen2 file system</label>
                                    <label for="nameFile" class="control-label">Name<span class="mandatory">*</span></label>
                                </div>
                                <div class="input-box">
                                    <input type="text" class="form-control" [class.border-danger]="inputError?.fileNameValidation||inputError?.fileNameUnqiue||inputError?.fileNameEnd||inputError?.fileNameStart||inputError?.fileNameHypens||inputError?.fileNameLenCheck" id="nameFile" autocomplete="off" [(ngModel)]="createSAFileObj.name" placeholder="" name="nameFile" (keyup)="validateFileSystemNamePop($event,3,63)">
                                    <p class="text-danger" *ngIf="inputError?.fileNameValidation">File system name must contain only lowercase letters, numbers, or hyphens.</p>
                                    <p class="text-danger" *ngIf="inputError?.fileNameEnd">File system name must end with a letter or a number.</p>
                                    <p class="text-danger" *ngIf="inputError?.fileNameUnqiue">File system name already exist.</p>
                                    <p class="text-danger" *ngIf="inputError?.fileNameStart">File system name must start with a letter or a number.</p>
                                    <p class="text-danger" *ngIf="inputError?.fileNameHypens">File system name must not contain two consecutive hyphens.</p>
                                    <p class="text-danger" *ngIf="inputError?.fileNameLenCheck">File system name must be between 3 and 63 characters long.</p>
                                    </div>   
                            </div>
                            <div class="d-flex">
                                <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!createSAFileObj.name||inputError?.fileNameValidation||inputError?.fileNameUnqiue||inputError?.fileNameEnd||inputError?.fileNameStart||inputError?.fileNameHypens||inputError?.fileNameLenCheck" (click)="saveSubSideSheet('createSAFileObj');popFS.hide();">Save</button>
                                <button class="btn btn-outline" (click)="popFS.hide();">Cancel</button>
                            </div>
                        </ng-template> 
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="createSAFile"
                        triggers="" placement="bottom" containerClass="custom-popover" #popFS="bs-popover" [outsideClick]="true" (click)="closeOtherPop(undefined,p);openPop(popFS,'createSAFile');">Create new</p> 
                    </div>  
                </div>
                <div class="form-group">
                <div class="label-div d-flex" *ngIf="synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;">
                    <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 8px 4px 0px !important;color: #703501;">
                        Additional configuration is required. After you create your workspace, perform these tasks:
 <br> &#8226; Assign other users to the <span class="text-weight-12">Contributor</span> role on workspace
 <br> &#8226; Assign other users the appropriate Synapse RBAC roles using Synapse Studio
<span *ngIf="showMore">
<br><br>Contact an <span class="text-weight-12">Owner</span> of the storage account, and ask them to perform the following tasks:
<br> &#8226; Assign the workspace MSI to the <span class="text-weight-12">Storage Blob Data Contributor</span> role on the storage account
<br> &#8226; Assign you and other users to the <span class="text-weight-12">Storage Blob Data Contributor</span> role on the storage account
<br>
Once these tasks are complete, the following Spark features can be used: (1) Spark Library Management, (2) Read and Write data to SQL pool databases via the Spark SQL connector, and (3) Create Spark databases and tables</span>
<div class="add-another-tag">
    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="showMore=!showMore"><span *ngIf="!showMore">Show more<img style="padding-left: 4px;" src="/assets/Infra/blueprint/arrow_down_show_more.svg" alt=""></span>
        <span *ngIf="showMore">Show less<img style="padding-left: 4px;" src="/assets/Infra/blueprint/arrow_up_show_less.svg" alt=""></span></p> 
</div></label>
                </div>
            </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="assignUser" class="control-label">Assign users Storage Blob Contributor access 
                            to Data Lake Storage Gen2<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="assignUserList" [multiple]="true" bindLabel="displayName" [closeOnSelect]="false" [(ngModel)]="synapseAnalyticsmodel.resourceData.assignUser" name="assignUser" placeholder="" [clearable]="false">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div *ngIf="synapseAnalyticsmodel.resourceData.assignUser.length == 1" class="ng-values-list">
                                  <div class="ng-value" *ngFor="let item of items | slice:0:1">
                                  <span class="ng-value-icon right" (click)="clear(item)">×</span>  
                                  <span class="ng-value-label">{{item.displayName}}</span>                                    
                                  </div>
                                </div>
                                <div *ngIf="synapseAnalyticsmodel.resourceData.assignUser.length > 1" class="ng-summary-list">
                                  <div class="ng-value">
                                    <span class="ng-value-label">{{ synapseAnalyticsmodel.resourceData.assignUser.length }} Selected</span>
                                  </div>
                                </div>
                              </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.displayName}}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="assignApplication" class="control-label">Assign application Storage Blob Contributor 
                            access to Data Lake Storage Gen2<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="assignApplicationList" [multiple]="true" bindLabel="displayName" [closeOnSelect]="false" [(ngModel)]="synapseAnalyticsmodel.resourceData.assignApplication" name="assignApplication" placeholder="" [clearable]="false">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div *ngIf="synapseAnalyticsmodel.resourceData.assignApplication.length == 1" class="ng-values-list">
                                  <div class="ng-value" *ngFor="let item of items | slice:0:1">
                                  <span class="ng-value-icon right" (click)="clear(item)">×</span>  
                                  <span class="ng-value-label">{{item.displayName}}</span>                                    
                                  </div>
                                </div>
                                <div *ngIf="synapseAnalyticsmodel.resourceData.assignApplication.length > 1" class="ng-summary-list">
                                  <div class="ng-value">
                                    <span class="ng-value-label">{{ synapseAnalyticsmodel.resourceData.assignApplication.length }} Selected</span>
                                  </div>
                                </div>
                              </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.displayName}}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label class="control-label text-weight">Security</label>
                        <label class="control-label " style="color: #3E4852;">If you don't provide a password, one will be 
                            automatically generated. You can change 
                            the password later.</label>
                        <label for="adminUsername" class="control-label ">Admin username<span class="mandatory">*</span></label>
                    </div>
                    <div class="input-box">
                        <input type="text" class="form-control" [class.border-danger]="inputError?.adminStartValid||inputError?.adminLenValid||inputError?.adminValidChar||inputError?.adminReservValid" id="adminUsername" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.adminUsername" placeholder="" name="adminUsername" (keyup)="validateAdminUsername($event,128)">
                        <p class="text-danger" *ngIf="inputError?.adminStartValid">Your login name must not start with numbers or symbols</p>
                        <p class="text-danger" *ngIf="inputError?.adminReservValid">Your login name must not contain a SQL Identifier or a typical 
                            system name (like admin, administrator, sa, root, dbmanager, 
                            loginmanager, etc.) or a built-in database user or role (like dbo, guest, public, etc.)</p>
                        <p class="text-danger" *ngIf="inputError?.adminValidChar">Your login name must not include non-alphanumeric characters.</p>
                        <p class="text-danger" *ngIf="inputError?.adminLenValid">Your login name must be between 1 and 128 characters long.</p>
                    </div>             
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="adminPassword" class="control-label ">Password</label>
                    </div>
                    <div class="input-box">
                        <input type="password" class="form-control" [class.border-danger]="inputError?.adminPLenValid||inputError?.adminPValid||inputError?.adminPNameValid" id="adminPassword" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.adminPassword" placeholder="" name="adminPassword" (keyup)="validateAdminPassword($event,8,128)">
                        <p class="text-danger" *ngIf="inputError?.adminPLenValid">Your password must be between 8 and 128 characters long.</p>
                        <p class="text-danger" *ngIf="inputError?.adminPValid">Your password must contain characters from three of the following 
                            categories – English uppercase letters, English lowercase letters, 
                            numbers (0-9), and non-alphanumeric characters (!, $, #, %, etc.).</p>
                        <p class="text-danger" *ngIf="inputError?.adminPNameValid">Your password cannot contain all or part of the login name. Part of a login name is defined as three or more consecutive alphanumeric characters.</p>
                    </div>             
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="adminConfirm" class="control-label ">Confirm password</label>
                    </div>
                    <div class="input-box">
                        <input type="password" class="form-control" [class.border-danger]="inputError?.adminPConfirmValid" id="adminConfirm" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.adminConfirm" placeholder="" name="adminConfirm" (keyup)="validateAdminConfrimPassword($event)">
                        <p class="text-danger" *ngIf="inputError?.adminPConfirmValid">Password and confirm password must match.</p>
                    </div>             
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="dedicatedSQLPool" class="control-label">Dedicated SQL pool<span class="mandatory">*</span></label>
                    </div>
                    <div class="d-flex" *ngFor="let item of synapseAnalyticsmodel.resourceData.dedicatedSQLPool;let i = index">
                        <div class="label-div w-92">
                            <label class="control-label"><span class="text-weight">{{item?.name}}</span><br><span>{{item?.performanceLevel}}</span></label>
                            <!-- <label class="control-label"></label> -->
                        </div>
                            <span><img src="/assets/icons/edit.png" class="p-blue-btn" (click)="editDedicateSqlPool(i)"></span>
                            <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" class="p-blue-btn" (click)="deleteDedicateSqlPool(i)"></span>
                    </div>  
                    <div class="add-another-tag">
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewDSP();">Create new</p> 
                    </div>  
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="apacheSparkPool" class="control-label">Apache spark pool<span class="mandatory">*</span></label>
                    </div>
                    <div class="d-flex" *ngFor="let item of synapseAnalyticsmodel.resourceData.apacheSparkPool;let i = index">
                        <!-- <ng-select [items]="apacheSparkPoolList" bindLabel="name" [(ngModel)]="synapseAnalyticsmodel.resourceData.apacheSparkPool" name="apacheSparkPool" placeholder="" [clearable]="false">
                        </ng-select> -->
                        <div class="label-div w-92">
                            <label class="control-label"><span class="text-weight">{{item?.name}}</span><br><span>{{item?.nodeSize?.name}} - {{item?.numberNodes}} nodes</span></label>
                        </div>
                            <span><img src="/assets/icons/edit.png" class="p-blue-btn" (click)="editApacheSparkPool(i)"></span>
                            <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" class="p-blue-btn" (click)="deleteApacheSparkPool(i)"></span>
                    </div>  
                    <div class="add-another-tag">
                        <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="createNewASP();">Create new</p> 
                    </div>  
                </div>
                <div class="form-group">
                    <div class="label-div">
                        <label for="comment" class="control-label ">Description</label>
                    </div>
                    <div class="input-box">
                        <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VNetDescription" [(ngModel)]="connectionsmodel.comments"  placeholder="Enter a reason for adding this resource..." (keyup)="VNetDescription($event,300)"></textarea>
                        <p class="text-danger" *ngIf="inputError?.VNetDescription">Description cannot exceed 300 characters.</p>
                    </div>                    
                </div>
                 
                <div class="add-another-tag">
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                    <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
                </div>
           <div *ngIf="showView">
            <div class="form-group">
                <div class="label-div">
                    <label for="managedResourceGroup" class="control-label ">Managed resource group<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Managed resource group is a container that holds ancillary resources 
                        created by Azure Synapse Analytics for your workspace. By default,
                        a managed resource group is created for you when your workspace is 
                        created. Optionally, you can specify the name of the resource group that 
                        will be created by Azure Synapse Analytics to satisfy your organization’s 
                        resource group name policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.managedLenValid||inputError?.managedValid||inputError?.managedPermValid||inputError?.managedDupValid" id="managedResourceGroup" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.managedResourceGroup" placeholder="" name="managedResourceGroup" (keyup)="validateManagedResourceGroup($event,90)">
                    <p class="text-danger" *ngIf="inputError?.managedLenValid">Managed resource group names only allow up to 90 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.managedValid">Managed resource group names only allow alphanumeric characters, 
                        periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
                    <p class="text-danger" *ngIf="inputError?.managedPermValid">You do not have permissions to create a resource group in the 
                        selected subscription Dynapt Enterprise Subscription.</p>
                    <p class="text-danger" *ngIf="inputError?.managedDupValid">Managed resource group name must be unique in the selected subscription.</p>
                </div>             
            </div>
            <div class="form-group">
                <div class="input-box">
                    <label class="col-sm-12 radio-label"  style="padding-left: 0px !important; margin-bottom: 24px !important;"><input type="checkbox" name="allowPipelines" id="allowPipelines" [(ngModel)]="synapseAnalyticsmodel.resourceData.allowPipelines" ><span>Allow pipelines (running as workspace's 
                        system assigned identity) to access SQL pools.<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="CONTROL permissions to SQL pools will be 
                        granted to the system assigned identity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>           
            </div>
            <div class="form-group">
                <div class="label-div"><label class="control-label text-weight">Workspace encryption</label></div>
                <div class="label-div d-flex" style="background: #FFF9F5;border: 1px solid #C26204;box-sizing: border-box;border-radius: 2px;margin-bottom: 24px !important;">
                    <label class="" style="padding: 10px 8px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                    <label class="" style="font-weight:400 !important;font-size:12px !important;padding: 8px 0px !important;color: #703501;">
                        Double encryption configuration cannot be changed after opting into using a customer-managed key at the time of workspace creation.</label>
                </div>
                <div class="label-div">
                    <label for="doubleEncryp" class="control-label ">Double encryption using a customer-managed
                        key<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose to encrypt all data at rest in the workspace 
                            with a key managed by you (customer-managed key).
                            This will provide double encryption with encryption at 
                            the infrastructure layer that uses platform-managed keys." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="doubleEncryp" [value]="'enable'" ([ngModel])="synapseAnalyticsmodel.resourceData.doubleEncryp" [checked]="synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'" (change)="synapseAnalyticsmodel.resourceData.doubleEncryp=$event.target.value;"><span>Enable</span></label>
                    <label class="col-sm-3 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="doubleEncryp" [value]="'disable'" ([ngModel])="synapseAnalyticsmodel.resourceData.doubleEncryp" [checked]="synapseAnalyticsmodel.resourceData.doubleEncryp=='disable'" (change)="synapseAnalyticsmodel.resourceData.doubleEncryp=$event.target.value;"><span>Disable</span></label>                    
                </div>                     
            </div>
            <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'">
                <div class="label-div">
                    <label for="encryptionKey" class="control-label ">Encryption key<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can use your own key by selecting an existing 
                        key or creating a new one in Key Vault." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-5 radio-label" style="padding-left: 0px !important;"><input type="radio" name="encryptionKey" [value]="'key'" ([ngModel])="synapseAnalyticsmodel.resourceData.encryptionKey" [checked]="synapseAnalyticsmodel.resourceData.encryptionKey=='key'" (change)="synapseAnalyticsmodel.resourceData.encryptionKey=$event.target.value;"><span>Select a key</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="encryptionKey" [value]="'identifier'" ([ngModel])="synapseAnalyticsmodel.resourceData.encryptionKey" [checked]="synapseAnalyticsmodel.resourceData.encryptionKey=='identifier'" (change)="synapseAnalyticsmodel.resourceData.encryptionKey=$event.target.value;"><span>Enter a key identifier</span></label>                    
                </div>                     
            </div>
            <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'&&synapseAnalyticsmodel.resourceData.encryptionKey=='key'">
                <div class="label-div">
                    <label for="encryptionKey" class="control-label ">Key vault and key<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The encryption key must be in an Azure Key Vault 
                        located in the same region as the Synapse workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div *ngIf="synapseAnalyticsmodel.resourceData.keyVault"><span>Key: {{synapseAnalyticsmodel.resourceData.keyVault}}</span></div>    
                <div class="add-another-tag">
                    <ng-template #selectKeyVault>
                        <div class="form-group">
                            <div class="label-div">
                                <label for="keyVault" class="control-label">Key vault<span class="mandatory">*</span></label>
                            </div>
                            <div class="input-box">
                                <ng-select [items]="keyVaultList" bindLabel="name" [(ngModel)]="keyForMLObj.keyVault" name="keyVault" placeholder="" (change)="keyVaultChangeForKey()" [clearable]="false">
                                </ng-select>
                            </div>   
                        </div>
                        <div class="form-group">
                            <div class="label-div">
                                <label for="key" class="control-label">Key<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Only the keys meeting the following requirement(s)
                                    will be shown. Required key type(s): RSA,RSA-HSM" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                            </div>
                            <div class="input-box">
                                <ng-select [items]="keyList" bindLabel="name" [(ngModel)]="keyForMLObj.key" name="key" placeholder="" [clearable]="false">
                                    <ng-template ng-label-tmp let-item="item">
                                        {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                        {{item?.kid.split('/')[item?.kid.split('/').length-1]}}
                                    </ng-template>
                                </ng-select>
                            </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" style="margin-right: 8px;" [class.disabled]="!keyForMLObj.keyVault||!keyForMLObj.key" (click)="saveSubSideSheet('selectKeyVault');popKV.hide();">Save</button>
                            <button class="btn btn-outline" (click)="popKV.hide();">Cancel</button>
                        </div>
                    </ng-template> 
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="selectKeyVault"
                    triggers="" placement="top" containerClass="custom-popover" #popKV="bs-popover" (click)="closeOtherPop(undefined,popKV);openPop(popKV,'selectKeyVault');">Select key vault and key</p> 
                </div>                  
            </div>
            <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'&&synapseAnalyticsmodel.resourceData.encryptionKey=='identifier'">
                <div class="label-div">
                    <label for="keyIdentifier" class="control-label ">Key identifier<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.keyIdentifierReq||inputError?.keyIdentifierValid" id="keyIdentifier" autocomplete="off" [(ngModel)]="synapseAnalyticsmodel.resourceData.keyIdentifier" placeholder="" name="keyIdentifier">
                    <p class="text-danger" *ngIf="inputError?.keyIdentifierReq">Key identifier is required</p>
                    <p class="text-danger" *ngIf="inputError?.keyIdentifierValid">Please enter a valid key identifier</p>
                </div>                     
            </div>
            <div class="form-group">
                <div class="label-div"><label class="control-label text-weight">Networking</label></div>
                <div class="input-box">
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="checkbox" name="allowConnections" id="allowConnections" [(ngModel)]="synapseAnalyticsmodel.resourceData.allowConnections" ><span>Allow connections from all IP addresses<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Allow connections from all IP addresses to your workspace's endpoints. 
                        You can restrict this to just Azure datacenter IP addresses and/or 
                        specific IP address ranges after creating the workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="checkbox" name="virtualNetwork" id="virtualNetwork" [(ngModel)]="synapseAnalyticsmodel.resourceData.virtualNetwork" ><span>Enable managed virtual network<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose this option to ensure that connections from your workspace 
                            to your data sources use Azure Private Links." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>                    
            </div>
            <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData?.virtualNetwork">
                <div class="label-div">
                    <label for="allowOutbound" class="control-label ">Allow outbound data traffic only to 
                        approved targets<span></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-3 radio-label" style="padding-left: 0px !important;"><input type="radio" name="allowOutbound" [value]="'yes'" ([ngModel])="synapseAnalyticsmodel.resourceData.allowOutbound" [checked]="synapseAnalyticsmodel.resourceData.allowOutbound=='yes'" (change)="synapseAnalyticsmodel.resourceData.allowOutbound=$event.target.value;"><span>Yes</span></label>
                    <label class="col-sm-3 radio-label" style="padding-left: 0px !important;"><input type="radio" name="allowOutbound" [value]="'no'" ([ngModel])="synapseAnalyticsmodel.resourceData.allowOutbound" [checked]="synapseAnalyticsmodel.resourceData.allowOutbound=='no'" (change)="synapseAnalyticsmodel.resourceData.allowOutbound=$event.target.value;"><span>No</span></label>                    
                </div>                     
            </div>
            <div class="form-group" *ngIf="synapseAnalyticsmodel.resourceData?.virtualNetwork&&synapseAnalyticsmodel.resourceData?.allowOutbound=='yes'">
                <div class="label-div">
                    <label for="allowOutbound" class="control-label ">Azure AD tenants<span></span></label>
                </div>
                <div class="d-flex" *ngFor="let item of synapseAnalyticsmodel.resourceData.apacheSparkPool;let i = index">
                    <!-- <ng-select [items]="apacheSparkPoolList" bindLabel="name" [(ngModel)]="synapseAnalyticsmodel.resourceData.apacheSparkPool" name="apacheSparkPool" placeholder="" [clearable]="false">
                    </ng-select> -->
                    <div class="label-div">
                        <label class="control-label"><span class="text-weight">{{item?.name}}</span><br><span>{{item?.nodeSize?.name}} - {{item?.numberNodes}} nodes</span></label>
                    </div>
                        <span><img src="/assets/icons/edit.png" class="p-blue-btn" (click)="editApacheSparkPool(i)"></span>
                        <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" class="p-blue-btn" (click)="deleteApacheSparkPool(i)"></span>
                </div> 
                <div class="d-flex" *ngFor="let item of synapseAnalyticsmodel.resourceData.azureADTenants;let i = index">
                    <div class="label-div">
                        <label class="control-label"><span class="text-weight">{{item?.tenantId?.displayName||'(Unavailable)'}}</span><br><span>{{item?.tenantId?.tenantId||item.tenantVal}}</span></label>
                    </div> 
                    <span><img src="/assets/icons/edit.png" (click)="editTenantSA(i)"></span>
                    <span style="padding-left: 11.33px;"><img src="/assets/icons/delete_new.png" (click)="deleteTenantSA(i)"></span>
                </div>   
                <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" (click)="addTenants();">Add tenant</p> 
                </div>                   
            </div>
            <div class="form-group">
                <div class="label-div d-flex">
                  <label class="control-label " style="min-width: max-content;">Tag name </label>
                  <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                  </label>
                  <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="synapseAnalyticsmodel.resourceData&&synapseAnalyticsmodel.resourceData.tagList&&synapseAnalyticsmodel.resourceData.tagList.length>1"></label>
                </div>
                <div *ngFor="let item of synapseAnalyticsmodel.resourceData.tagList;let i = index">
                  <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                      <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                        [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                      <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                        [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                        *ngIf="synapseAnalyticsmodel.resourceData.tagList.length>1"></span>
                  </div>
                  <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                  <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                  <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                  <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div class="add-another-tag">
                  <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                </div>
              </div>
           </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled('synapseAnalytics')" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled('synapseAnalytics')" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>    
 </div>  


<div class="sidebar-right" *ngIf="synapseAnalyticsmodel.SADedicatedPoolShow">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Dedicated SQL pool</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();synapseAnalyticsmodel.SADedicatedPoolShow=false;">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="poolName" class="control-label">Dedicated SQL pool name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.dedicatedNameValid||inputError?.dedicatedNameCheck||inputError?.dedicatedNameLen||inputError?.dedicatedNameReserved||inputError?.dedicatedNameDup" id="poolName" autocomplete="off" [(ngModel)]="SADedicatedPoolObj.name" placeholder="" name="poolName" (keyup)="validateDPName($event,60)">
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameCheck">Dedicated SQL pool name should not match special patterns.</p>
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameLen">The value has a length of at most 60.</p>
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameReserved">Dedicated SQL pool name should not contain reserved words.</p>
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameDup">No dedicated SQL pool with the same name exists in the workspace.</p>
                        <p class="text-danger" *ngIf="inputError?.dedicatedNameValid">Your dedicated SQL pool name can't end with '.' or ' ', can't contain '<,>,*,%,&,:,\,/,?,@,-' or control characters</p>
                </div> 
            </div>
            <div class="form-group" *ngIf="SADedicatedPoolObj.visibleGeoRedundantOption">
                <div class="label-div">
                    <label for="geoReduandant" class="control-label">Geo-redundant<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Replicates data and backups to a paired region for data protection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="geoReduandant" [value]="'yes'" ([ngModel])="SADedicatedPoolObj.geoReduandant" [checked]="SADedicatedPoolObj.geoReduandant=='yes'" (change)="SADedicatedPoolObj.geoReduandant=$event.target.value;"><span>Yes</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;"><input type="radio" name="geoReduandant" [value]="'no'" ([ngModel])="SADedicatedPoolObj.geoReduandant" [checked]="SADedicatedPoolObj.geoReduandant=='no'" (change)="SADedicatedPoolObj.geoReduandant=$event.target.value;"><span>No</span></label>                    
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="performanceLevel" class="control-label">Performance level<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Choose a dedicated SQL pool performance level that best fits your analytics needs." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="performanceLevelList" bindLabel="name" bindValue="name" [(ngModel)]="SADedicatedPoolObj.performanceLevel" name="performanceLevel" placeholder="" (change)="performanceLevelChange()" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            
            <div class="form-group">
                <div class="label-div">
                    <label for="estimatedPrice" class="control-label">Estimated price<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="While in preview, dedicated SQL pool has a preview discount. Any changes in pricing will
                        be announced at a future date. The preview price shown is an estimate and does not 
                        reflect enterprise agreements, credits, or discounts." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <span style="font-weight: 600;">Est. Cost Per Hour</span><br>
                    <span>{{SADedicatedPoolObj?.priceDetails?.amount}} USD</span>
                </div>
                <div class="add-another-tag">
                    <ng-template #viewPriceDtls>
                        <div class="form-group">
                            <div class="label-div">
                                <label class="control-label text-weight">Cost summary</label>
                            </div>
                            <div style="height: 1px;background: #FFFFFF;border: 1px solid #EEEFF0;box-sizing: border-box;margin-bottom: 8px;"></div>   
                            <div class="label-div d-flex">
                                <label class="">Cost per 100 DWUs (USD)</label>
                                <label class="label-right-cost pull-right">1.51</label>
                            </div>   
                            <div class="label-div d-flex">
                                <label class="">100 DWUs selected</label>
                                <label class="label-right-cost pull-right">X {{SADedicatedPoolObj?.priceDetails?.count}}</label>
                            </div> 
                            <div style="height: 1px;background: #FFFFFF;border: 1px solid #EEEFF0;box-sizing: border-box;margin-bottom: 8px;"></div>  
                            <div class="label-div d-flex">
                                <label class=" text-weight-12">EST. Cost per hour</label>
                                <label class="label-right-cost text-weight-12 pull-right">X {{SADedicatedPoolObj?.priceDetails?.amount}} USD</label>
                            </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" (click)="popView.hide();">Ok</button>
                        </div>
                    </ng-template> 
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="viewPriceDtls"
                    triggers="" placement="bottom" containerClass="custom-popover" #popView="bs-popover" [outsideClick]="true" (click)="popView.show();">View pricing details</p> 
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div d-flex">
                  <label class="control-label " style="min-width: max-content;">Tag name </label>
                  <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                  </label>
                  <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="SADedicatedPoolObj&&SADedicatedPoolObj.tagList&&SADedicatedPoolObj.tagList.length>1"></label>
                </div>
                <div *ngFor="let item of SADedicatedPoolObj.tagList;let i = index">
                  <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                      <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                        [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                      <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                        [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagSA(i,'SADedicatedPoolObj')"
                        *ngIf="SADedicatedPoolObj.tagList.length>1"></span>
                  </div>
                  <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                  <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                  <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                  <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div class="add-another-tag">
                  <p class="blue-text cursor-pointer lbl-btn" (click)="addTagSA('SADedicatedPoolObj')">Add another tag</p>
                </div>
              </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="SADedicatedPoolObj.updateIndex==undefined" [class.disabled]="!SADedicatedPoolObj?.name ||inputError?.dedicatedNameValid||inputError?.dedicatedNameCheck||inputError?.dedicatedNameLen||inputError?.dedicatedNameReserved||inputError?.dedicatedNameDup" (click)="saveSubSideSheet('SADedicatedPool')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="SADedicatedPoolObj.updateIndex!=undefined" [class.disabled]="!SADedicatedPoolObj?.name ||inputError?.dedicatedNameValid||inputError?.dedicatedNameCheck||inputError?.dedicatedNameLen||inputError?.dedicatedNameReserved||inputError?.dedicatedNameDup" (click)="updateSubSideSheet('SADedicatedPool')">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="synapseAnalyticsmodel.SAApacheSprakPoolShow">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Apache Spark pool</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();synapseAnalyticsmodel.SAApacheSprakPoolShow=false;">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="poolName" class="control-label">Apache Spark pool name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" [class.border-danger]="inputError?.apacheNameValid||inputError?.apacheNameStart||inputError?.apacheNameEnd||inputError?.apacheNameUnique||inputError?.apacheNameReserved||inputError?.apacheNameLen" id="poolName" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.name" placeholder="" name="poolName" (keyup)="validateASPName($event,15)">
                        <p class="text-danger" *ngIf="inputError?.apacheNameValid">Name must contain only letters or numbers.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameStart">Name must start with a letter.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameEnd">Name must end with a letter or a number.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameUnique">Name must be unique within the workspace.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameReserved">Name must not be a reserved name.</p>
                        <p class="text-danger" *ngIf="inputError?.apacheNameLen">Name must be between 1 and 15 characters long.</p>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight-12" style=" padding-bottom: 0px !important;margin-bottom: 0px !important;">Node size family</label>
                    <label>MemoryOptimized</label>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="nodeSize" class="control-label">Node size<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="nodeSizeList" bindLabel="name" [(ngModel)]="SAApacheSparkPoolObj.nodeSize" name="nodeSize" placeholder="" (change)="nodeSizeChange()" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="autoscale" class="control-label">Autoscale<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If enabled, your Apache Spark pool will automatically scale up 
                        and down based on the amount of activity." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="autoscale" [value]="'enable'" ([ngModel])="SAApacheSparkPoolObj.autoscale" [checked]="SAApacheSparkPoolObj.autoscale=='enable'" (change)="SAApacheSparkPoolObj.autoscale=$event.target.value;"><span>Enabled</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="autoscale" [value]="'disable'" ([ngModel])="SAApacheSparkPoolObj.autoscale" [checked]="SAApacheSparkPoolObj.autoscale=='disable'" (change)="SAApacheSparkPoolObj.autoscale=$event.target.value;"><span>Disabled</span></label>                    
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="numberNodes" class="control-label">Number of nodes<span class="mandatory">*</span></label>
                </div>
                <div class="input-box d-flex">
                    <input type="text" style="max-width:48px !important" class="form-control" id="numberNodes" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.numberNodes" (change)="silderValueChange()" placeholder="" name="numberNodes">
                    <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options" (userChange)="changeValSider()"></ngx-slider>
                    <input type="text" style="max-width:48px !important" class="form-control pull-right" id="maxNumberNodes" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.maxNumberNodes" (change)="silderValueChange()" placeholder="" name="numberNodes">
                </div> 
            </div>
            
            <div class="form-group">
                <div class="label-div">
                    <label for="estimatedPrice" class="control-label">Estimated price<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="While in preview, dedicated SQL pool has a preview discount. Any changes in pricing will
                        be announced at a future date. The preview price shown is an estimate and does not 
                        reflect enterprise agreements, credits, or discounts." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div style="display: grid;">
                    <span class="text-weight-12">Est. Cost Per Hour</span>
                    <span style="font-size: 12px;line-height: 18px;">{{SAApacheSparkPoolObj?.priceDetails?.amountMin}} to {{SAApacheSparkPoolObj?.priceDetails?.amountMax}} USD</span>
                </div>
                <div class="add-another-tag">
                    <ng-template #viewPriceDtls>
                        <div class="form-group" style="margin-bottom: 16px !important;">
                            <div class="label-div">
                                <label class="control-label text-weight">Cost summary</label>
                            </div>   
                            <div style="height: 1px;background: #FFFFFF;border: 1px solid #EEEFF0;box-sizing: border-box;margin-bottom: 8px;"></div>
                            <div class="label-div d-flex">
                                <label class="">Cost per Node ({{SAApacheSparkPoolObj.nodeSize.vcores}} vCores) (USD)</label>
                                <label class="label-right-cost pull-right">{{SAApacheSparkPoolObj.nodeSize.amount}}</label>
                            </div>   
                            <div class="label-div d-flex">
                                <label class="">Node ({{SAApacheSparkPoolObj.nodeSize.vcores}} vCores) selected</label>
                                <label class="label-right-cost pull-right">X {{SAApacheSparkPoolObj.numberNodes}} to {{SAApacheSparkPoolObj.maxNumberNodes}}</label>
                            </div>  
                            <div style="height: 1px;background: #FFFFFF;border: 1px solid #EEEFF0;box-sizing: border-box;margin-bottom: 8px;"></div> 
                            <div class="label-div d-flex">
                                <label class=" text-weight-12">EST. Cost per hour</label>
                                <label class="label-right-cost text-weight-12 pull-right">{{SAApacheSparkPoolObj?.priceDetails?.amountMin}} to {{SAApacheSparkPoolObj?.priceDetails?.amountMax}} USD</label>
                            </div>   
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-btn-primary" (click)="popView.hide();">Ok</button>
                        </div>
                    </ng-template> 
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" [popover]="viewPriceDtls"
                    triggers="" placement="bottom" containerClass="custom-popover" #popView="bs-popover" [outsideClick]="true" (click)="popView.show();">View pricing details</p> 
                </div>   
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Automatic Pausing</label>
                    <label for="automaticPausing" class="control-label">Automatic Pausing<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If enabled, the Apache Spark pool will automatically 
                        pause after a specified amount of idle time." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="automaticPausing" [value]="'enable'" ([ngModel])="SAApacheSparkPoolObj.automaticPausing" [checked]="SAApacheSparkPoolObj.automaticPausing=='enable'" (change)="SAApacheSparkPoolObj.automaticPausing=$event.target.value;"><span>Enabled</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="automaticPausing" [value]="'disable'" ([ngModel])="SAApacheSparkPoolObj.automaticPausing" [checked]="SAApacheSparkPoolObj.automaticPausing=='disable'" (change)="SAApacheSparkPoolObj.automaticPausing=$event.target.value;SAApacheSparkPoolObj.numberMinutes=15;"><span>Disabled</span></label>                    
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="numberMinutes" class="control-label">Number of minutes idle<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <input type="text" [disabled]="SAApacheSparkPoolObj.automaticPausing=='disable'" [class.border-danger]="inputError?.numberMinutesValid||inputError?.numberMinutesLen||inputError?.numberMinutesIdle" class="form-control" id="numberMinutes" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.numberMinutes" placeholder="" name="numberMinutes" (keyup)="validateASPNumberMinu($event,5,10080)">
                    <p class="text-danger" *ngIf="inputError?.numberMinutesValid">Value must be a valid number.</p>
                    <p class="text-danger" *ngIf="inputError?.numberMinutesLen">Please enter a value between 5 and 10080</p>
                    <p class="text-danger" *ngIf="inputError?.numberMinutesIdle">Enter number of idle minutes before pool is automatically paused.</p>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Component versions</label>
                    <label for="apacheSpark" class="control-label">Apache Spark<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="apacheSparkList" bindLabel="name" bindValue="id" [(ngModel)]="SAApacheSparkPoolObj.apacheSpark" name="apacheSpark" placeholder="" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">Python<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'3.6':'3.6'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">Scala<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'2.11.12':'2.12.12'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">Java<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'1.8.0_272':'1.8.0_282'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">.NET Core<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'3.1':'3.1'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">.NET for Apache Spark<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'1.0':'1.1.1'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group apache-details">
                <div class="label-div">
                    <label for="apacheSpark" class="control-label">Delta Lake<br>
                        <span>{{SAApacheSparkPoolObj?.apacheSpark=='2.4'?'0.6':'0.8'}}</span>
                    </label>
                </div> 
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Spark configuration<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Upload a Spark configuration file to specify additional 
                        properties on the Spark pool. This will be referenced to 
                        configure Spark applications upon job submission." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                    <label for="packages" class="control-label">File upload</label>
                </div> 
                <div class="input-box">
                    <input type="file" class="form-control" id="fileUpload" autocomplete="off" [(ngModel)]="SAApacheSparkPoolObj.fileUpload" placeholder="" name="fileUpload">
                </div>
                <div class="">
                    <button class="btn btn-btn-primary" [class.disabled]="!SAApacheSparkPoolObj.fileUpload">Upload</button>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label class="control-label text-weight">Packages</label>
                    <label for="packages" class="control-label">Allow session level packages<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This setting allows users to add or update packages 
                        during a notebook or batch session." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="packages" [value]="'enable'" ([ngModel])="SAApacheSparkPoolObj.packages" [checked]="SAApacheSparkPoolObj.packages=='enable'" (change)="SAApacheSparkPoolObj.packages=$event.target.value;"><span>Enabled</span></label>
                    <label class="col-sm-2 radio-label" style="padding-left: 0px !important;margin-bottom: 0px !important;"><input type="radio" name="packages" [value]="'disable'" ([ngModel])="SAApacheSparkPoolObj.packages" [checked]="SAApacheSparkPoolObj.packages=='disable'" (change)="SAApacheSparkPoolObj.packages=$event.target.value;"><span>Disabled</span></label>                    
                </div>
            </div>
            <div class="form-group">
                <div class="label-div d-flex">
                  <label class="control-label " style="min-width: max-content;">Tag name </label>
                  <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                  </label>
                  <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="SAApacheSparkPoolObj&&SAApacheSparkPoolObj.tagList&&SAApacheSparkPoolObj.tagList.length>1"></label>
                </div>
                <div *ngFor="let item of SAApacheSparkPoolObj.tagList;let i = index">
                  <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                      <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                        [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                      <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                        [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagSA(i,'SAApacheSparkPoolObj')"
                        *ngIf="SAApacheSparkPoolObj.tagList.length>1"></span>
                  </div>
                  <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                  <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                  <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                  <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div class="add-another-tag">
                  <p class="blue-text cursor-pointer lbl-btn" (click)="addTagSA('SAApacheSparkPoolObj')">Add another tag</p>
                </div>
              </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="SAApacheSparkPoolObj.updateIndex==undefined" [class.disabled]="checkDataDisabled('SAApacheSprakPool')" (click)="saveSubSideSheet('SAApacheSprakPool')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="SAApacheSparkPoolObj.updateIndex!=undefined" [class.disabled]="checkDataDisabled('SAApacheSprakPool')" (click)="updateSubSideSheet('SAApacheSprakPool')">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="sidebar-right" *ngIf="synapseAnalyticsmodel.SAAzureADTenantShow">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Select Azure AD tenants</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeSubSideSheet();synapseAnalyticsmodel.SAAzureADTenantShow=false;">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">            
            <div class="form-group">
                <div class="input-box">
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="autoscale" [value]="'azure'" ([ngModel])="SAAzureADTenantObj.autoscale" [checked]="SAAzureADTenantObj.autoscale=='azure'" (change)="SAAzureADTenantObj.autoscale=$event.target.value;"><span>Select by Azure AD tenant name</span></label>
                    <label class="col-sm-12 radio-label" style="padding-left: 0px !important;"><input type="radio" name="autoscale" [value]="'manually'" ([ngModel])="SAAzureADTenantObj.autoscale" [checked]="SAAzureADTenantObj.autoscale=='manually'" (change)="SAAzureADTenantObj.autoscale=$event.target.value;"><span>Manually via tenant ID</span></label>                    
                </div> 
            </div>
            <div class="form-group" *ngIf="SAAzureADTenantObj.autoscale=='azure'">
                <div class="label-div">
                    <label for="tenant" class="control-label">Tenants<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure AD tenants that you can add as
                        approved tenants for the workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="tenantList" bindLabel="displayName" [(ngModel)]="SAAzureADTenantObj.tenantId" name="tenant" placeholder="" [clearable]="false">
                    </ng-select>
                </div> 
            </div>
            <div class="form-group" *ngIf="SAAzureADTenantObj.autoscale=='manually'">
                <div class="label-div">
                    <label for="tenantVal" class="control-label">Tenant ID<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Azure AD tenants that you can add as
                        approved tenants for the workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <input type="text" class="form-control" id="tenantVal" [class.border-danger]="inputError?.tenantFormat||inputError?.tenantValid" autocomplete="off" [(ngModel)]="SAAzureADTenantObj.tenantVal" placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" (keyup)="validateTenant($event)" name="tenantVal">
                    <p class="text-danger" *ngIf="inputError?.tenantFormat">Must be in the format of 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.</p>
                    <p class="text-danger" *ngIf="inputError?.tenantValid">Only lowercase letters or numbers or hyphens are allowed.</p>
                </div> 
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="SAAzureADTenantObj.updateIndex==undefined" [class.disabled]="(SAAzureADTenantObj.autoscale=='azure'&&!SAAzureADTenantObj.tenantId)||(SAAzureADTenantObj.autoscale=='manually'&&!SAAzureADTenantObj.tenantVal)" (click)="saveSubSideSheet('SAAzureADTenant')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="SAAzureADTenantObj.updateIndex!=undefined" [class.disabled]="(SAAzureADTenantObj.autoscale=='azure'&&!SAAzureADTenantObj.tenantId)||(SAAzureADTenantObj.autoscale=='manually'&&!SAAzureADTenantObj.tenantVal)" (click)="updateSubSideSheet('SAAzureADTenant')">Update</button>
            </div>
        </div>
    </div>
</div>
</ng-template>