<div class="custom-fixed-tables">
    <table class="table">
        <thead>
          <tr>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Name</span>
                    <img class="sort" src="assets/icons/sort.png"(click)="sortByKey('loadBalancersData',0)" >
                </div>
            </th>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Resource Group</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('loadBalancersData',1)">
                </div>
            </th>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Subscription</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('loadBalancersData',2)">
                </div>
            </th>
            <th>
              <div class="flex-custom no-wrap">
                  <span>Region</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('loadBalancersData',3)">
              </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>SKU</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('loadBalancersData',4)">
            </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>Type</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('loadBalancersData',5)">
            </div>
          </th>
          <th style="border-right: none;">
            <div class="flex-custom no-wrap">
                <span>Tags</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('loadBalancersData',6)">
            </div>
          </th>
          <th style="border-left: none;" class="wid-75"></th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data">
              <td isEllipsisActive  class="blue-text cursor-pointer" (click)="openModal(loadBalancerDetailsTemplate,row,'loadBalancerPopup')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive>{{ row[4] | titlecase |  displayTxt}}</td>
              <td isEllipsisActive>{{ row[5] | titlecase |  displayTxt}}</td>
              <td isEllipsisActive>{{ getTags(row[6]) }}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('frontendIP',row)">
                      <p class="delete-popup-text">Frontend IP Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('backendPool',row)">
                      <p class="delete-popup-text">Backend Pools</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('healthProbes',row)">
                      <p class="delete-popup-text">Health Probes</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('lbRules',row)">
                        <p class="delete-popup-text">Load Balancing Rules</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item *ngIf="row[4] != 'Gateway'" (click)="openSidesheet('inboundRules',row)">
                        <p class="delete-popup-text" >Inbound NAT Rules</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item *ngIf="row[5] != 'Internal' && row[4] !='Basic'" (click)="openSidesheet('outboundRules',row)">
                        <p class="delete-popup-text" >Outbound Rules</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteLBData')">
                        <p class="delete-popup-text">Delete</p>
                    </div>
                  </mat-menu>
                </span>              
             </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>

  <ng-container *ngIf="showSidesheet">
      <!--Backend pools -->
      <ng-container *ngIf="currentTemplate == 'backendPool'">
        <div class="sidebar-right single width-881" [class.sub-sheet-652]="showSubSidesheet" >
            <div class="header">
                <div class="d-flex">
                  <h4 class="threeFour-width ">Backend Pools<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                  <div class="text-right half-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
                <div class="comm-mb">
                    <button class="btn-btn-secondary" (click)="openSubSideSheet('addBackendPool')">
                    <img src="/assets/governance/add-btn.svg" alt="">Add </button>
                </div>
              <div class="custom-fixed-tables mb-3">
                    <table class="table">
                        <thead>
                            <tr>
                              <th style="border-right: none;width: 25px;"></th>
                              <th style="border-left: none;"><div class="flex-custom no-wrap"><span>Backend Pool</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>Resource Name</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>Resource Status</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>IP Address</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>Network Interface</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>Availability Zone</span></div></th>
                              <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Rules Count</span></div></th>
                              <th style="border-left: none;" class="wid-75"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let row of loadBalancerData?.properties?.backendAddressPools;index as i">
                              <tr class="accordion-toggle cursor-pointer" data-toggle="collapse" [attr.data-target]="'#accordion_'+i">
                                  <td class="bold-600" [title]="row?.name">{{row?.name | displayTxt}}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                              </tr>
                              <tr id="accordion_{{i}}" class="accordian-body collapse">
                                  <td colspan="9" style="padding: 0px;">
                                      <div class="custom-fixed-tables">
                                          <table class="table">
                                              <thead>
                                                  <!-- <tr>
                                                      <th>
                                                          <div class="flex-custom no-wrap">
                                                              <span>Service</span>
                                                              <img class="sort" src="assets/icons/sort.png">
                                                          </div>
                                                      </th>
                                                      <th>
                                                          <div class="flex-custom no-wrap">
                                                              <span>Tier</span>
                                                              <img class="sort" src="assets/icons/sort.png">
                                                          </div>
                                                      </th>
                                                      <th>
                                                          <div class="flex-custom no-wrap">
                                                              <span>Meter</span>
                                                              <img class="sort" src="assets/icons/sort.png">
                                                          </div>
                                                      </th>
                                                      <th>
                                                          <div class="flex-custom no-wrap">
                                                              <span>Part #</span>
                                                              <img class="sort" src="assets/icons/sort.png">
                                                          </div>
                                                      </th>
                                                      <th>
                                                          <div class="flex-custom no-wrap">
                                                              <span>Total</span>
                                                              <img class="sort" src="assets/icons/sort.png">
                                                          </div>
                                                      </th>
                                                  </tr> -->
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td style="width:25px ;"></td>
                                                      <td>{{row?.name | displayTxt}}</td>
                                                      <td>{{row.properties.loadBalancerBackendAddresses.length ? nicData.body.properties.virtualMachine.id.split('/')[8] : ' '}}</td>
                                                      <td>{{row.properties.loadBalancerBackendAddresses.length  ? 'Deallocated':' '}}</td>
                                                      <!-- <td>{{this.vmData?.body?.properties?.instanceView?.statuses[1]?.displayStatus.split(' ')[1] | titlecase}}</td> -->
                                                      <td>{{row.properties.loadBalancerBackendAddresses.length ? nicData.body.properties.ipConfigurations[0].properties.privateIPAddress : ' '}}</td>
                                                      <td >{{row.properties.loadBalancerBackendAddresses.length ? nicData.body.name : ' '}}</td>
                                                      <td ></td>
                                                      <td class="text-center">{{getRuleCount(row,'backendPool')}}</td>
                                                      <td class="text-right wid-75">
                                                        <span>
                                                          <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                                          <mat-menu #action2="matMenu" class="delete-popup" >
                                                            <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addBackendPool',row)">
                                                              <p class="delete-popup-text">Edit</p>
                                                            </div>
                                                            <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteBackendPool')">
                                                                <p class="delete-popup-text">Delete</p>
                                                            </div>
                                                          </mat-menu>
                                                        </span>              
                                                     </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </td>
                              </tr>
                          </ng-container>
                          <div class="col-md-12 mt-3" *ngIf="!loadBalancerData?.properties?.backendAddressPools?.length">
                              <p class="content-14 blur-text">No data Found</p>
                          </div>
                          </tbody>
                    </table>
              </div>
            </div>
          </div>
          <ng-container *ngIf="showSubSidesheet">
            <div class="sidebar-right single width-652">
              <div class="header">
                  <div class="d-flex">
                    <h4 class="threeFour-width ">{{isEdit?'Edit Backend Pool':'Add Backend Pool'}}<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                    <div class="text-right half-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
              <div class="body scroll-y">
                <form [formGroup]="currentForm">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                    <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="poolName" name="poolName" formControlName="poolName" (keyup)="validIPName($event,'frontend','backendPool')"/>
                    <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                    <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                    <p class="text-danger" *ngIf="inputError?.frontendNameExist">Backend Pool name must be unique in Load Balancer</p>
                    <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                        and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                </div>
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Virtual network</label>
                  <label *ngIf="loadBalancerData?.properties?.backendAddressPools?.length" class="flex-custom no-wrap bold-600">{{vNetName.split('/')[8]}}</label>
                  <ng-select *ngIf="!loadBalancerData?.properties?.backendAddressPools?.length" [items]="vNetList"  bindLabel="name" bindValue="id" name="vNet" [clearable]="false" id="vNet" formControlName="vNet"></ng-select>
                </div>
                <ng-container *ngIf="loadBalancerData?.properties?.backendAddressPools?.length || currentForm.get('vNet').value">
                  <div class="mb-3">
                    <label class="flex-custom no-wrap">Backend Pool Configuration</label>
                      <div class="flex-custom">
                        <div class="checkbox-cs">
                          <input type="radio" name="poolConfig"  class="form-check-inline" id='nic' (change)="setRadioBtn('nic','backendPool','poolConfig')" [checked]="currentForm.get('poolConfig')?.value =='nic'">
                          <label class="value" for="nic">NIC</label>
                      </div>
                      <div class="checkbox-cs">
                          <input type="radio" name="poolConfig" class="form-check-inline" id='ip' (change)="setRadioBtn('ip','backendPool','poolConfig')" [checked]="currentForm.get('poolConfig')?.value == 'ip'">
                          <label class="value" for="ip">IP Address</label>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="currentForm.get('poolConfig').value=='nic'">
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Virtual Machines</label>
                      <!-- <label class="flex-custom no-wrap">You can only attach virtual machines in eastus that have a standard SKU public IP configuration or no public IP configuration. All IP configurations must be on the same virtual network.</label> -->
                    </div>
                    <div class="mb-2 flex-custom">
                      <button class="btn-btn-secondary mr-3" [class.disabled-icon]="!vmNicList?.length" (click)="openModal(addVMtoPool,selectedRow,'openVmtoPool')">
                      <img src="/assets/governance/add-btn.svg" class="mr-2" alt="">Add </button>
                      <button class="btn-btn-secondary" [class.disabled-icon]="!vmPoolList?.length" (click)="removeVMPool('openVmtoPool')">
                      <img src="/assets/actions/blueClose.svg" class="mr-2" alt="">Remove</button>
                    </div>
                   <div class="custom-fixed-tables mb-3">
                      <table class="table">
                          <thead>
                              <tr>
                                <th><div class="flex-custom no-wrap"><span>Virtual Machine</span></div></th>
                                <th><div class="flex-custom no-wrap"><span>IP Configuration</span></div></th>
                                <th><div class="flex-custom no-wrap"><span>Availability Set</span></div></th>
                              </tr>
                            </thead>
                            <tbody >
                              <tr *ngFor="let row of vmPoolList;let i=index" >
                                <td isEllipsisActive>
                                  <input type="checkbox"  name="vmPool" class="form-check-inline"  id='vmPool_{{i}}'  (change)="enableAction(row,$event,'vmPool')" formControlName="vmPool" [checked]="row.checked">
                                  <label class="value" for="vmPool_{{i}}">{{row.name}}</label>
                                </td>
                                <td isEllipsisActive>{{row.ipConfig}}</td>
                                <td isEllipsisActive>set</td>
                              </tr>
                              <!-- <ng-conatiner  formArrayName="vmPool" *ngFor="let list of currentForm.get('vmPool')['controls']; let i = index;">
                                <tr [formGroupName]="i">
                                  *ngFor="let row of vmPoolList;let i=index" 
                                  <td isEllipsisActive>
                                    <input type="checkbox"  name="instanceName" class="form-check-inline"  id='instanceName_{{i}}'  (change)="enableActio(data?.body,$event,i)" formControlName="checked" [checked]="list.get('checked').value">
                                    <label class="value" for="instanceName_{{i}}">{{list.get('name').value}}</label>
                                  </td>
                                  <td isEllipsisActive>con</td>
                                  <td isEllipsisActive>set</td>
                                </tr>
                              </ng-conatiner> -->
                              <div class="col-md-12 mt-3" *ngIf="!vmPoolList?.length">
                                <p class="content-14 blur-text">No virtual machine is found.</p>
                              </div>
                            </tbody>
                      </table>
                   </div>
                    <div class="mb-3">
                      <label class="flex-custom no-wrap bold-600">Virtual Machine Scale Sets</label>
                      <!-- <label class="flex-custom no-wrap">Virtual Machine Scale Sets must be in same location as Load Balancer. Only IP configurations that have the same SKU (Basic/Standard) as the Load Balancer can be selected. All of the IP configurations have to be in the same Virtual Network.</label> -->
                    </div>
                    <div class="mb-2 flex-custom">
                      <button class="btn-btn-secondary mr-3" [class.disabled-icon]="!vmSetNicList?.length" (click)="openModal(addVMtoPool,selectedRow,'openVmScaleSetPool')">
                      <img src="/assets/governance/add-btn.svg" class="mr-2" alt="">Add </button>
                      <button class="btn-btn-secondary" [class.disabled-icon]="!vmScaleSetList?.length" (click)="removeVMPool('openVmScaleSetPool')">
                      <img src="/assets/actions/blueClose.svg" class="mr-2" alt="">Remove</button>
                    </div>
                   <div class="custom-fixed-tables mb-3">
                      <table class="table">
                          <thead>
                              <tr>
                                <th><div class="flex-custom no-wrap"><span>Virtual Machine Scale Set</span></div></th>
                                <th><div class="flex-custom no-wrap"><span>IP Configuration</span></div></th>
                                <th><div class="flex-custom no-wrap"><span>Availability Set</span></div></th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of vmScaleSetList;let i=index">
                                  <!--  -->
                                  <td isEllipsisActive>
                                    <input type="checkbox"  name="vmScaleSet" class="form-check-inline"  id='vmScaleSet_{{i}}'  (change)="enableAction(row,$event,'vmScaleSetPool')" formControlName="vmScaleSet" [checked]="row.checked">
                                    <label class="value" for="vmScaleSet_{{i}}">{{row.name}}</label>
                                  </td>
                                  <td isEllipsisActive>{{row.ipConfig}}</td>
                                  <td isEllipsisActive>-</td>
                                </tr>
                              <!-- <ng-conatiner  formArrayName="vmScaleSet" *ngFor="let list of currentForm.get('vmScaleSet')['controls']; let i = index;">
                                <tr [formGroupName]="i">
                                  <td isEllipsisActive>
                                    <input type="checkbox"  name="name" class="form-check-inline"  id='name_{{i}}'  (change)="enableActio(data?.body,$event,i)" formControlName="checked" [checked]="list.get('checked').value">
                                    <label class="value" for="name_{{i}}">{{list.get('name').value}}</label>
                                  </td>
                                  <td isEllipsisActive>con</td>
                                  <td isEllipsisActive>set</td>
                                </tr>
                              </ng-conatiner> -->
                              <div class="col-md-12 mt-3" *ngIf="!vmScaleSetList?.length">
                                <p class="content-14 blur-text">No virtual machine sacle set is found</p>
                              </div>
                            </tbody>
                      </table>
                   </div>
                  </ng-container>
                 <ng-container *ngIf="currentForm.get('poolConfig').value=='ip'">
                  <div class="mb-3">
                    <ng-container formArrayName="ipArray" *ngFor="let tanch of currentForm.get('ipArray')['controls']; let i = index;">
                      <div class="flex-custom no-wrap">
                        <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                          <div class="mb-12 mr-3" style="width:295px;">
                            <label class="flex-custom no-wrap ">IP address</label>
                            <ng-select [items]="ipPoolList"  bindLabel="name" bindValue="name"  (change)="validIPAddressPool($event,i,tanch)" (keyup)="pushIPAddress($event,tanch)"name="ipAddress" [clearable]="false" id="ipAddress" formControlName="ipAddress"></ng-select>
                          </div>
                          <div class="mb-12 mr-3" style="width:295px;">
                            <label class="flex-custom no-wrap ">Resource Name</label>
                            <input class="form-control full-width disabled-input"  type="text" autocomplete="off" id="resourceName" name="resourceName" formControlName="resourceName" />
                          </div>
                          <ng-container *ngIf="ipArrayList?.length > 1">
                            <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer" (click)="removeIPPool(i,tanch)">
                          </ng-container>
                        </div>
                      </div>
                      <p class="text-danger" *ngIf="inputError?.ipError"> This IP address is already added in this backend pool.</p>
                      <div class="action-btn" style="margin-top: -4px;" (click)="ipArray()" *ngIf="i == ipArrayList?.length-1">Add new</div>
                    </ng-container>
                  </div>
                 </ng-container>
                </ng-container>
                </form>
              </div>
        
                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('backendPool') || currentForm.invalid" (click)="saveLBConfig('backendPool')">Add</button>
                </div>
            </div>
            </ng-container>
      </ng-container>
    <!-- Health Probes -->
    <ng-container *ngIf="currentTemplate == 'healthProbes'">
      <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Health Probes<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addHealthProbes')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add </button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Protocol</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Port</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Used By</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of loadBalancerData?.properties?.probes;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.protocol}}</td>
                            <td isEllipsisActive> {{row.properties.port}}</td>
                            <td isEllipsisActive> Not used</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <div class="mat-menu-item" mat-menu-item (click)="deleteProbes(row)">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                           </td>
                          </tr>
                          <div class="col-md-12 mt-3" *ngIf="!loadBalancerData?.properties?.probes?.length">
                            <p class="content-14 blur-text">No results.</p>
                          </div>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">Add Health Probe<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
          
              <div class="body scroll-y">
                  <form [formGroup]="currentForm">
                      <div class="mb-3">
                          <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                          <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="probeName" name="probeName" formControlName="probeName" (keyup)="validIPName($event,'frontend','probe')"/>
                          <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameExist">The probe name must be unique within the set of probes used by the load balancer.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                              and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Protocol<span class="mandatory">*</span></label>
                        <ng-select [items]="protocolList"  bindLabel="name" bindValue="name" (change)="pathvalidator($event)" name="protocol" [clearable]="false" id="protocol" formControlName="protocol"></ng-select>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Port<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The destination port for the health signal." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.portInvalidEmpty|| inputError?.portInvalidNumber || inputError?.portInvalidSizeRange" id="port" name="port" formControlName="port" (keyup)="validProbes($event,'port')"/>
                        <p class="text-danger" *ngIf="inputError?.portInvalidEmpty">  The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidNumber">The value must be a valid number.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidSizeRange">The value must be between 1 and 65535, inclusive.</p>
                        <p class="text-danger" *ngIf="inputError?.portInvalidSizeRange">Only positive whole numbers allowed.</p>
                      </div>
                      <div class="mb-3" *ngIf="currentForm.get('protocol').value!='TCP'">
                        <label class="flex-custom no-wrap">Path<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The URI used for requesting health 
                          status from the backend endpoint." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.pathInValidEmpty" id="path" name="path" formControlName="path" (keyup)="validProbes($event,'path')"/>
                        <p class="text-danger" *ngIf="inputError?.pathInvalidEmpty">The value must not be empty.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Interval<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The amount of time between probe attempts." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.intervalInvalidEmpty|| inputError?.intervalInvalidNumber || inputError?.intervalInvalidSizeRange" id="interval" name="interval" formControlName="interval" (keyup)="validProbes($event,'interval')"/>
                        <p class="blur-text text-right">Seconds</p>
                        <p class="text-danger" *ngIf="inputError?.intervalInvalidEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.intervalInvalidNumber">The value must be a valid number.</p>
                        <p class="text-danger" *ngIf="inputError?.intervalInvalidSizeRange">The value must be between 5 and 2147483646, inclusive.</p>
                        <p class="text-danger" *ngIf="inputError?.intervalInvalidSizeRange">Only positive whole numbers allowed.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Unhealthy threshold<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The number of consecutive probe failures that must occur 
                          before a virtual machine is considered unhealthy." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.thresholdInvalidEmpty|| inputError?.thresholdInvalidNumber || inputError?.thresholdInvalidSizeRange" id="threshold" name="threshold" formControlName="threshold" (keyup)="validProbes($event,'threshold')"/>
                        <p class="blur-text text-right">Consecutive failures</p>
                        <p class="text-danger" *ngIf="inputError?.thresholdInvalidEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.thresholdInvalidNumber">The value must be a valid number.</p>
                        <p class="text-danger" *ngIf="inputError?.thresholdInvalidSizeRange">The value must be between 2 and 429496729, inclusive.</p>
                        <p class="text-danger" *ngIf="inputError?.thresholdInvalidSizeRange">Only positive whole numbers allowed.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Used by<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The list of load balancing rules using this probe." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <label class="flex-custom no-wrap bold-600">Not used</label>
                      </div>
                  </form>
              </div>
      
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('healthProbes') || currentForm.invalid" (click)="saveLBConfig('healthProbes')">Add</button>
              </div>
          </div>
          </ng-container>
    </ng-container>
    <!-- Frontend IP Configuration -->
    <ng-container *ngIf="currentTemplate == 'frontendIP'">
    <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" >
        <div class="header">
            <div class="d-flex">
              <h4 class="threeFour-width ">Frontend IP Configuration<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
              <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body scroll-y">
            <div class="comm-mb">
                <button class="btn-btn-secondary" (click)="openSubSideSheet('addFrontendIP')">
                <img src="/assets/governance/add-btn.svg" alt="">Add </button>
            </div>
          <div class="custom-fixed-tables mb-3">
                <table class="table">
                    <thead>
                        <tr>
                          <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                          <th><div class="flex-custom no-wrap"><span>IP Address</span></div></th>
                          <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Rule Count</span></div></th>
                          <th style="border-left: none;" class="wid-75"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of loadBalancerData?.properties?.frontendIPConfigurations;let i=index">
                          <td isEllipsisActive>{{row?.name}}</td>
                          <td isEllipsisActive>{{row.properties.privateIPAddress ? row.properties.privateIPAddress : getPublicIPAddress(row)}}</td>
                          <td isEllipsisActive>{{getRuleCount(row,'frontendIP')}}</td>
                          <!-- {{loadBalancerData?.properties.loadBalancingRules.length+loadBalancerData?.properties.inboundNatRules.length+loadBalancerData?.properties.outboundRules.length}} -->
                          <td class="text-right wid-75">
                            <span>
                              <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                              <mat-menu #action2="matMenu" class="delete-popup" >
                                <div class="mat-menu-item" mat-menu-item (click)="openSubSideSheet('addFrontendIP',row)">
                                  <p class="delete-popup-text">Edit</p>
                                </div>
                                <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteFrontendIP')">
                                    <p class="delete-popup-text">Delete</p>
                                </div>
                              </mat-menu>
                            </span>              
                         </td>
                        </tr>
                      </tbody>
                </table>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showSubSidesheet">
        <div class="sidebar-right single add-alert">
            <div class="header">
                <div class="d-flex">
              <h4 class="threeFour-width">{{isEdit ? 'Edit Frontend IP Configuration':'Add Frontend IP Configuration'}}<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
        
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="mb-3">
                        <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                        <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="frontendIPName" name="frontendIPName" formControlName="frontendIPName" (keyup)="validIPName($event,'frontend','frontend')"/>
                        <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameExist">Frontend Ip name must be unique in Load Balancer</p>
                        <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                            and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>
                    <ng-container *ngIf="selectedRow[5] == 'Internal'">
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Virtual network</label>
                        <label class="flex-custom no-wrap bold-600">{{vNetName.split('/')[8]}}</label>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Subnet<span class="mandatory">*</span></label>
                        <ng-select [items]="subnetList"  bindLabel="name" (change)="saveDisable=true" bindValue="id" name="subnet" [clearable]="false" id="subnet" formControlName="subnet"></ng-select>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap" >Assigment</label>
                        <div class="flex-custom ">
                            <div class="checkbox-cs" >
                                <input type="radio" name="publicIPAssignment"  class="form-check-inline" id='dynamicAss' (change)="setRadioBtn('Dynamic','frontendIP','assigment')"  [checked]="currentForm.get('publicIPAssignment')?.value == 'Dynamic'" >
                                <label class="value" for="dynamicAss">Dynamic</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="publicIPAssignment" class="form-check-inline" id='staticAss' (change)="setRadioBtn('Static','frontendIP','assigment')"  [checked]="currentForm.get('publicIPAssignment')?.value == 'Static'" >
                                <label class="value" for="staticAss">Static</label>
                            </div>
                        </div>
                      </div> 
                      <div class="mb-3" *ngIf="currentForm.get('publicIPAssignment')?.value == 'Static' || (currentForm.get('publicIPAssignment')?.value == 'Dynamic' && isEdit)">
                        <label class="flex-custom no-wrap">IP address</label>
                        <input class="form-control full-width" type="text" [class.disabled-input]="currentForm.get('publicIPAssignment')?.value == 'Dynamic'" autocomplete="off" [class.border-danger]="inputError?.ipError" id="publicIPAddress" name="publicIPAddress" formControlName="publicIPAddress" (keyup)="invalidIP($event)"/>
                        <!-- invalidIP($event) -->
                        <p class="text-danger" *ngIf="inputError?.ipError">Invalid IP address.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Availability zone<span class="mandatory">*</span></label>
                        <ng-select [items]="availabilityZoneList" [class.disabled]="isEdit" bindLabel="name" bindValue="name" (change)="saveDisable=true" name="publicIPZone" [clearable]="false" id="publicIPZone" formControlName="publicIPZone"></ng-select>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="selectedRow[5] != 'Internal'">
                      <div class="mb-3" *ngIf="!isEdit">
                        <label class="flex-custom no-wrap">IP type</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="ipType"  class="form-check-inline" id='ipAddress' (change)="setRadioBtn('yes','frontendIP','ipType')" [checked]="currentForm.get('ipType')?.value ==true">
                              <label class="value" for="ipAddress">IP address</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="ipType" class="form-check-inline" id='ipPrefix' (change)="setRadioBtn('no','frontendIP','ipType')" [checked]="currentForm.get('ipType')?.value == false">
                              <label class="value" for="ipPrefix">IP prefix</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="isEdit">
                        <label class="flex-custom no-wrap">IP type</label>
                        <label class="flex-custom no-wrap bold-600">{{currentForm.get('ipType')?.value ? 'Public IP':'Public IP prefix'}}</label>
                      </div>
                      <ng-container *ngIf="currentForm.get('ipType')?.value">
                        <div class="mb-3" >
                          <label class="flex-custom nowrap">Public IP address<span class="mandatory">*</span></label>
                          <ng-select [items]="publicIPList" groupBy="type" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="publicIP" [clearable]="false" id="publicIP"  formControlName="publicIP"></ng-select>
                          <span class="blue-text text-12-18 cursor-pointer" #anchor1 (click)="showPopup=true">Create New</span>
                          <kendo-popup [anchor]="anchor1" (anchorViewportLeave)="showPopup = false" *ngIf="showPopup" id="createPublicIP">
                              <div class='content kendo-content resourceGroup'>
                                <div class="rgPopup-form mb-3">
                                  <label for="publicIPName" class="flex-custom bold-600">Add a public IP address</label>
                                    <label for="publicIPName" class="">Name<span class="mandatory">*</span></label>
                                    <div>
                                      <input type="text" class="form-control"  id="publicIPName" autocomplete="off"  placeholder="" [class.border-danger]="inputError?.publicIPNameEmpty|| inputError?.publicIPNameMaxLen || inputError?.publicIPNameExist|| inputError?.publicIPNameValid" name="publicIPName" formControlName="publicIPName" (keyup)="validIPName($event,'publicIP','publicIPName')" required>
                                      <p class="text-danger" *ngIf="inputError?.publicIPNameEmpty">The value must not be empty.</p>
                                      <p class="text-danger" *ngIf="inputError?.publicIPNameMaxLen">The name must be between 1 and 80 characters.</p>
                                      <p class="text-danger" *ngIf="inputError?.publicIPNameExist">A public IP with this name already exists. </p>
                                      <p class="text-danger" *ngIf="inputError?.publicIPNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                                          and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                                    </div>              
                                </div>
                                <div class="mb-3">
                                  <label class="flex-custom no-wrap" >SKU</label>
                                  <div class="flex-custom ">
                                      <div class="checkbox-cs">
                                          <input type="radio" name="publicIPSKU"  class="form-check-inline" id='basic' (change)="setRadioBtn('Basic','frontendIP','sku')" [checked]="currentForm.get('publicIPSKU')?.value == 'Basic'" disabled>
                                          <label class="value" for="basic">Basic</label>
                                      </div>
                                      <div class="checkbox-cs">
                                          <input type="radio" name="publicIPSKU" class="form-check-inline" id='standard' (change)="setRadioBtn('Standard','frontendIP','sku')" [checked]="currentForm.get('publicIPSKU')?.value == 'Standard'" disabled>
                                          <label class="value" for="standard">Standard</label>
                                      </div>
                                  </div>
                              </div> 
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Tier</label>
                                <div class="flex-custom ">
                                    <div class="checkbox-cs" >
                                        <input type="radio" name="publicIPTier"  class="form-check-inline" id='regional' (change)="setRadioBtn('Regional','frontendIP','tier')" [checked]="currentForm.get('publicIPTier')?.value == 'Regional'" disabled>
                                        <label class="value" for="regional">Regional</label>
                                    </div>
                                    <div class="checkbox-cs">
                                        <input type="radio" name="publicIPTier" class="form-check-inline" id='global' (change)="setRadioBtn('Global','frontendIP','tier')"  [checked]="currentForm.get('publicIPTier')?.value == 'Global'" disabled>
                                        <label class="value" for="global">Global</label>
                                    </div>
                                </div>
                              </div> 
                              <div class="mb-3">
                                  <label class="flex-custom no-wrap" >Assigment</label>
                                  <div class="flex-custom ">
                                      <div class="checkbox-cs" >
                                          <input type="radio" name="publicIPAssignment"  class="form-check-inline" id='dynamicAss' (change)="setRadioBtn('Dynamic','frontendIP','assigment')"  [checked]="currentForm.get('publicIPAssignment')?.value == 'Dynamic'" disabled>
                                          <label class="value" for="dynamicAss">Dynamic</label>
                                      </div>
                                      <div class="checkbox-cs">
                                          <input type="radio" name="publicIPAssignment" class="form-check-inline" id='staticAss' (change)="setRadioBtn('Static','frontendIP','assigment')"  [checked]="currentForm.get('publicIPAssignment')?.value == 'Static'" disabled>
                                          <label class="value" for="staticAss">Static</label>
                                      </div>
                                  </div>
                              </div> 
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Availability zone<span class="mandatory">*</span></label>
                                <ng-select [items]="availabilityZoneList"  bindLabel="name" bindValue="name" (change)="saveDisable=true" name="publicIPZone" [clearable]="false" id="publicIPZone" formControlName="publicIPZone"></ng-select>
                              </div>
                              <div class="mb-3">
                                <label class="flex-custom no-wrap" >Route preference<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Determines how your traffic routes between Azure and the Internet. Selecting Microsoft 
                                  global network delivers traffic via Microsoft global network closest to user. Internet route 
                                  option uses transit ISP network. Egress data transfer price varies based on the routing 
                                  selection. Routing preference option of a Public IP can’t be changed once created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                                <div class="flex-custom ">
                                    <div class="checkbox-cs" >
                                        <input type="radio" name="publicIPRoutePrefer"  class="form-check-inline" id='msNetwork' (change)="setRadioBtn('MSNetwork','frontendIP','routePrefer')"  [checked]="currentForm.get('publicIPRoutePrefer')?.value == 'MSNetwork'">
                                        <label class="value" for="msNetwork">Microsfot network</label>
                                    </div>
                                    <div class="checkbox-cs">
                                        <input type="radio" name="publicIPRoutePrefer" class="form-check-inline" id='internet' (change)="setRadioBtn('Internet','frontendIP','routePrefer')"  [checked]="currentForm.get('publicIPRoutePrefer')?.value == 'Internet'">
                                        <label class="value" for="internet">Internet</label>
                                    </div>
                                </div>
                              </div> 
                                <div class="d-flex">
                                    <button class="btn btn-btn-primary mr-12" [class.disabled]="inputError?.publicIPNameEmpty|| inputError?.publicIPNameMaxLen || inputError?.publicIPNameExist|| inputError?.publicIPNameValid" (click)="createPublicIP();showPopup = false">Ok</button>
                                    <button class="btn btn-outline" (click)="showPopup = false">Cancel</button>
                                </div>
                              </div>
                          </kendo-popup>
                       </div>
                      </ng-container>
                      <ng-container *ngIf="!currentForm.get('ipType')?.value">
                        <div class="mb-3" >
                          <label class="flex-custom nowrap">Public IP prefix<span class="mandatory">*</span></label>
                          <ng-select [items]="publicIpPrefixList" groupBy="type" bindLabel="name" bindValue="id" (change)="saveDisable=true" name="publicPrefix" [clearable]="false" id="publicPrefix"  formControlName="publicPrefix"></ng-select>
                          <span class="blue-text text-12-18 cursor-pointer" #anchor1 (click)="showPopup=true">Create New</span>
                          <kendo-popup [anchor]="anchor1" (anchorViewportLeave)="showPopup = false" *ngIf="showPopup" id="createPublicIP">
                              <div class='content kendo-content resourceGroup'>
                                <div class="rgPopup-form mb-3">
                                  <label for="publicIPName" class="flex-custom bold-600">Add a Public IP Prefix</label>
                                    <label for="publicIPName" class="">Name<span class="mandatory">*</span></label>
                                    <div>
                                      <input type="text" class="form-control"  id="publicIPName" autocomplete="off"  placeholder="" [class.border-danger]="inputError?.ipPrefixNameEmpty|| inputError?.ipPrefixNameMaxLen || inputError?.ipPrefixNameExist|| inputError?.ipPrefixNameValid" name="publicIPName" formControlName="publicIPName" (keyup)="validIPName($event,'ipPrefix','ipPrefix')" required>
                                      <p class="text-danger" *ngIf="inputError?.ipPrefixNameEmpty">The value must not be empty.</p>
                                      <p class="text-danger" *ngIf="inputError?.ipPrefixNameMaxLen">The name must be between 1 and 80 characters.</p>
                                      <p class="text-danger" *ngIf="inputError?.ipPrefixNameExist">A public IP prefix with this name already exists. </p>
                                      <p class="text-danger" *ngIf="inputError?.ipPrefixNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                                          and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                                    </div>              
                                </div>
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">SKU</label>
                                <label class="flex-custom no-wrap bold-600">Standard</label>
                              </div> 
                              <div class="mb-3">
                                  <label class="flex-custom no-wrap">IP version</label>
                                  <label class="flex-custom no-wrap bold-600">IPv4</label>
                              </div> 
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Prefix size<span class="mandatory">*</span></label>
                                <ng-select [items]="prefixSizeList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="prefixSize" [clearable]="false" id="prefixSize" formControlName="prefixSize"></ng-select>
                              </div>
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Availability zone<span class="mandatory">*</span></label>
                                <ng-select [items]="availabilityZoneList"  bindLabel="name" bindValue="name" (change)="saveDisable=true" name="publicIPZone" [clearable]="false" id="publicIPZone" formControlName="publicIPZone"></ng-select>
                              </div>
                                <div class="d-flex">
                                    <button class="btn btn-btn-primary mr-12" [class.disabled]="inputError?.ipPrefixNameEmpty|| inputError?.ipPrefixNameMaxLen || inputError?.ipPrefixNameExist|| inputError?.ipPrefixNameValid" (click)="createPublicIpPrefix();showPopup = false">Ok</button>
                                    <button class="btn btn-outline" (click)="showPopup = false">Cancel</button>
                                </div>
                              </div>
                          </kendo-popup>
                       </div>
                      </ng-container>
                        <div class="mb-3">
                            <label class="flex-custom no-wrap">Gateway Load balancer<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The ARM ID of the Gateway Load Balancer you 
                                want to chain to your frontend IP Configuration." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <ng-select [items]="gatewayLBList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="gatewayLB" [clearable]="false" id="gatewayLB" formControlName="gatewayLB"></ng-select>
                        </div>
                    </ng-container>
                    <div class="mb-3"  *ngIf="isEdit">
                      <label class="flex-custom no-wrap">Used By</label>
                    </div>
                    <div class="custom-fixed-tables"  *ngIf="isEdit">
                      <table class="table">
                          <thead>
                              <tr>
                                <th> <div class="flex-custom no-wrap"><span>Name</span></div></th>
                                <th> <div class="flex-custom no-wrap"><span>Type</span></div></th>
                              </tr>
                            </thead>
                            <tbody >
                                  <tr>
                                    <td isEllipsisActive style="max-width: 118px;"> Not used</td>
                                    <td isEllipsisActive></td>
                                      <!-- <td isEllipsisActive style="max-width: 118px;">{{currentForm.get('frontendIPName').value}}</td>
                                      <td isEllipsisActive>Public IP</td> -->
                                  </tr>
                            </tbody>
                      </table>
                  </div>
                </form>
            </div>
    
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('frontendIP') || currentForm.invalid" (click)="saveLBConfig('frontendIP')">Add</button>
            </div>
        </div>
        </ng-container>
    </ng-container>
      <!-- Load Balancing Rules -->
      <ng-container *ngIf="currentTemplate == 'lbRules'">
        <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" >
            <div class="header">
                <div class="d-flex">
                  <h4 class="threeFour-width ">Load Balancing Rules<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                  <div class="text-right half-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
                <div class="comm-mb">
                    <button class="btn-btn-secondary" (click)="openSubSideSheet('addLBRules')">
                    <img src="/assets/governance/add-btn.svg" alt="">Add </button>
                </div>
              <div class="custom-fixed-tables mb-3">
                    <table class="table">
                        <thead>
                            <tr>
                              <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>Load Balancing Rule</span></div></th>
                              <th><div class="flex-custom no-wrap"><span>Backend Pool</span></div></th>
                              <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Health Probe</span></div></th>
                              <th style="border-left: none;" class="wid-75"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of loadBalancerData?.properties?.loadBalancingRules;let i=index">
                              <td isEllipsisActive>{{row?.name}}</td>
                              <td isEllipsisActive>{{row?.name}} ({{row?.properties?.protocol}}/{{row?.properties?.frontendPort}} to {{row?.properties?.protocol}}/{{row?.properties?.backendPort}})</td>
                              <td isEllipsisActive> {{row.properties.backendAddressPool.id.split('/')[10]}}</td>
                              <td isEllipsisActive>{{row.properties.probe.id.split('/')[10]}}</td>
                              <td class="text-right wid-75">
                                <span>
                                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                  <mat-menu #action2="matMenu" class="delete-popup" >
                                    <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteLBRules')">
                                        <p class="delete-popup-text">Delete</p>
                                    </div>
                                  </mat-menu>
                                </span>              
                             </td>
                            </tr>
                            <div class="col-md-12 mt-3" *ngIf="!loadBalancerData?.properties?.loadBalancingRules?.length">
                              <p class="content-14 blur-text">No results.</p>
                            </div>
                          </tbody>
                    </table>
              </div>
            </div>
          </div>
          <ng-container *ngIf="showSubSidesheet">
            <div class="sidebar-right single add-alert">
                <div class="header">
                    <div class="d-flex">
                  <h4 class="threeFour-width">Add Load Balancing Rule<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                      <div class="text-right full-width">
                        <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                          <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                        </button>
                      </div>
                    </div>
                </div>
            
                <div class="body scroll-y">
                    <form [formGroup]="currentForm">
                        <div class="mb-3">
                            <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                            <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="lbRuleName" name="lbRuleName" formControlName="lbRuleName" (keyup)="validIPName($event,'frontend','rules')"/>
                            <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                            <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                            <p class="text-danger" *ngIf="inputError?.frontendNameExist">The name you entered matches another inbound NAT rule or loadbalancing rule used by this load balancer. The name of each inbound NAT rule and loadbalancing rule on a load balancer must be unique.</p>
                            <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                                and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Frontend IP address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can only select frontend IP addresses that have one public IP and are not associated 
                            to any inbound rule that loadbalances across all ports and protocols. Frontend IP addresses 
                            that are part of an IP Prefix or are associated to inbound rule that loadbalances across all ports 
                            and protocols are not shown." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <ng-select [items]="frontendIPList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="frontendIP" [clearable]="false" id="frontendIP" formControlName="frontendIP"></ng-select>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Backend pool<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The virtual machines in the selected backend pool will be 
                            the target for the load balanced traffic of this rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                         <div class="margin-multi-select">
                          <ng-select class="full-width" [items]="backendPoolsList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" placeholder="0 Selected" name="backendPool" [clearable]="false" id="backendPool" formControlName="backendPool">
                              <ng-template ng-multi-label-tmp let-items="items">
                                  <span>{{items.length}} selected</span>
                                </ng-template>
                              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div>
                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected"  [checked]="item$.selected"/> {{item.name}}
                                  </div>
                                </ng-template>
                          </ng-select>
                         </div>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Protocol</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="protocolLBRules"  class="form-check-inline" id='TCP' (change)="setRadioBtn('Tcp','lbRules','protocolLBRules')" [checked]="currentForm.get('protocolLBRules')?.value =='Tcp'">
                              <label class="value" for="TCP">TCP</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="protocolLBRules" class="form-check-inline" id='UDP' (change)="setRadioBtn('Udp','lbRules','protocolLBRules')" [checked]="currentForm.get('protocolLBRules')?.value == 'Udp'">
                              <label class="value" for="UDP">UDP</label>
                          </div>
                        </div>
                      </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Port<span class="mandatory">*</span></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.portLBInvalidEmpty|| inputError?.portLBInvalidNumber || inputError?.portLBnvalidSizeRange" id="frontendPort" name="frontendPort" formControlName="frontendPort" (keyup)="validProbes($event,'portLB')"/>
                          <p class="text-danger" *ngIf="inputError?.portLBInvalidEmpty">  The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.portLBInvalidNumber">The value must be a valid number.</p>
                          <p class="text-danger" *ngIf="inputError?.portLBInvalidSizeRange">The value must be between 0 and 65534, inclusive.</p>
                          <p class="text-danger" *ngIf="inputError?.portLBInvalidSizeRange">Only positive whole numbers allowed.</p>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Backend port<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The virtual machines in the selected backend pool will 
                            be the target for the load balanced traffic of this rule." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.backendPortInvalidEmpty|| inputError?.backendPortInvalidNumber || inputError?.backendPortInvalidSizeRange" id="backendPort" name="backendPort" formControlName="backendPort" (keyup)="validProbes($event,'backendPort')"/>
                          <p class="text-danger" *ngIf="inputError?.backendPortInvalidEmpty">  The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.backendPortInvalidNumber">The value must be a valid number.</p>
                          <p class="text-danger" *ngIf="inputError?.backendPortInvalidSizeRange">The value must be between 0 and 65534, inclusive.</p>
                          <p class="text-danger" *ngIf="inputError?.backendPortInvalidSizeRange">Only positive whole numbers allowed.</p>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Health probe<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The selected probe is used by this rule to determine 
                            which virtual machines in the backend pool are healthy 
                            and can receive load balanced traffic." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <ng-select [items]="probesList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="probe" [clearable]="false" id="probe" formControlName="probe"></ng-select>
                          <span class="blue-text text-12-18 cursor-pointer" #anchor1 (click)="setDefaultValue('addHealthProbes');showPopup=true">Create New</span>
                          <kendo-popup [anchor]="anchor1" (anchorViewportLeave)="showPopup = false" *ngIf="showPopup" id="createProbe">
                            <div class='content kendo-content resourceGroup'>
                                <div class="rgPopup-form mb-3">
                                  <label for="createProbe" class="flex-custom bold-600">Add Health Probe</label>
                                    <label for="createProbe" class="">Name<span class="mandatory">*</span></label>
                                    <div>
                                      <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.publicIPNameEmpty|| inputError?.publicIPNameMaxLen || inputError?.publicIPNameExist|| inputError?.publicIPNameValid" id="probeName" name="probeName" formControlName="probeName" (keyup)="validIPName($event,'publicIP','probe')"/>
                                      <p class="text-danger" *ngIf="inputError?.publicIPNameEmpty">The value must not be empty.</p>
                                      <p class="text-danger" *ngIf="inputError?.publicIPNameMaxLen">The name must be between 1 and 80 characters.</p>
                                      <p class="text-danger" *ngIf="inputError?.publicIPNameExist">The probe name must be unique within the set of probes used by the load balancer.</p>
                                      <p class="text-danger" *ngIf="inputError?.publicIPNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                                          and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                                    </div>              
                                </div>
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Protocol<span class="mandatory">*</span></label>
                                <ng-select [items]="protocolList"  bindLabel="name" bindValue="name" (change)="pathvalidator($event)" name="protocol" [clearable]="false" id="protocol" formControlName="protocol"></ng-select>
                              </div>
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Port<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The destination port for the health signal." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                                <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.portInvalidEmpty|| inputError?.portInvalidNumber || inputError?.portInvalidSizeRange" id="port" name="port" formControlName="port" (keyup)="validProbes($event,'port')"/>
                                <p class="text-danger" *ngIf="inputError?.portInvalidEmpty">  The value must not be empty.</p>
                                <p class="text-danger" *ngIf="inputError?.portInvalidNumber">The value must be a valid number.</p>
                                <p class="text-danger" *ngIf="inputError?.portInvalidSizeRange">The value must be between 1 and 65535, inclusive.</p>
                                <p class="text-danger" *ngIf="inputError?.portInvalidSizeRange">Only positive whole numbers allowed.</p>
                              </div>
                              <div class="mb-3" *ngIf="currentForm.get('protocol').value!='TCP'">
                                <label class="flex-custom no-wrap">Path<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The URI used for requesting health 
                                  status from the backend endpoint." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                                <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.pathInValidEmpty" id="path" name="path" formControlName="path" (keyup)="validProbes($event,'path')"/>
                                <p class="text-danger" *ngIf="inputError?.pathInvalidEmpty">The value must not be empty.</p>
                              </div>
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Interval<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The amount of time between probe attempts." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                                <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.intervalInvalidEmpty|| inputError?.intervalInvalidNumber || inputError?.intervalInvalidSizeRange" id="interval" name="interval" formControlName="interval" (keyup)="validProbes($event,'interval')"/>
                                <p class="blur-text text-right">Seconds</p>
                                <p class="text-danger" *ngIf="inputError?.intervalInvalidEmpty">The value must not be empty.</p>
                                <p class="text-danger" *ngIf="inputError?.intervalInvalidNumber">The value must be a valid number.</p>
                                <p class="text-danger" *ngIf="inputError?.intervalInvalidSizeRange">The value must be between 5 and 2147483646, inclusive.</p>
                                <p class="text-danger" *ngIf="inputError?.intervalInvalidSizeRange">Only positive whole numbers allowed.</p>
                              </div>
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Unhealthy threshold<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The number of consecutive probe failures that must occur 
                                  before a virtual machine is considered unhealthy." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                                <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.thresholdInvalidEmpty|| inputError?.thresholdInvalidNumber || inputError?.thresholdInvalidSizeRange" id="threshold" name="threshold" formControlName="threshold" (keyup)="validProbes($event,'threshold')"/>
                                <p class="blur-text text-right">Consecutive failures</p>
                                <p class="text-danger" *ngIf="inputError?.thresholdInvalidEmpty">The value must not be empty.</p>
                                <p class="text-danger" *ngIf="inputError?.thresholdInvalidNumber">The value must be a valid number.</p>
                                <p class="text-danger" *ngIf="inputError?.thresholdInvalidSizeRange">The value must be between 2 and 429496729, inclusive.</p>
                                <p class="text-danger" *ngIf="inputError?.thresholdInvalidSizeRange">Only positive whole numbers allowed.</p>
                              </div>
                              <div class="mb-3">
                                <label class="flex-custom no-wrap">Used by<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The list of load balancing rules using this probe." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                                <label class="flex-custom no-wrap bold-600">Not used</label>
                              </div>
                              <div class="d-flex">
                                <button class="btn btn-btn-primary mr-12" [class.disabled]="invalidForm('healthProbes') || subSideSheetForm.invalid " (click)="saveLBConfig('healthProbes','subForm')">Ok</button>
                                <button class="btn btn-outline" (click)="showPopup = false">Cancel</button>
                             </div>
                           </div>
                          </kendo-popup>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Session persistence<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="getToolTip()" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <ng-select [items]="sessionPersistenceList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="session" [clearable]="false" id="session" formControlName="session"></ng-select>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Idle timeout (minutes)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Keep a TCP or HTTP connection open without relying on 
                            clients to send keep-alive messages." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.idleTimeoutEmpty|| inputError?.idleTimeoutNumber || inputError?.idleTimeoutRange" id="idealTimeout" name="idealTimeout" formControlName="idealTimeout" (keyup)="idleTimeValidation($event)"/>
                          <p class="text-danger" *ngIf="inputError?.idleTimeoutEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.idleTimeoutNumber">The value must be a valid number.</p>
                          <p class="text-danger" *ngIf="inputError?.idleTimeoutRange">The value must be between 4 and 30, inclusive.</p>
                          <p class="text-danger" *ngIf="inputError?.idleTimeoutRange">Only positive whole numbers allowed.</p>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">TCP reset</label>
                            <div class="flex-custom">
                              <div class="checkbox-cs">
                                <input type="radio" name="tcpReset"  class="form-check-inline" id='tcpResetDisable' (change)="setRadioBtn('no','lbRules','tcpReset')" [checked]="currentForm.get('tcpReset')?.value ==false">
                                <label class="value" for="tcpResetDisable">Disabled</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="tcpReset" class="form-check-inline" id='tcpResetEnable' (change)="setRadioBtn('yes','lbRules','tcpReset')" [checked]="currentForm.get('tcpReset')?.value == true">
                                <label class="value" for="tcpResetEnable">Enabled</label>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Floating IP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Without Floating IP, Azure exposes a traditional load 
                            balancing IP address mapping scheme for ease of use 
                            (the VM instances' IP). Enabling Floating IP changes the 
                            IP address mapping to the Frontend IP of the load balancer
                            to allow for additional flexibility." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <div class="flex-custom">
                              <div class="checkbox-cs">
                                <input type="radio" name="floatingIP"  class="form-check-inline" id='floatingIPDisable' (change)="setRadioBtn('no','lbRules','floatingIP')" [checked]="currentForm.get('floatingIP')?.value ==false">
                                <label class="value" for="floatingIPDisable">Disabled</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="floatingIP" class="form-check-inline" id='floatingIPEnable' (change)="setRadioBtn('yes','lbRules','floatingIP')" [checked]="currentForm.get('floatingIP')?.value == true">
                                <label class="value" for="floatingIPEnable">Enabled</label>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="no-wrap">Outbound source network address translation (SNAT)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Configures outbound SNAT for the instances in the backend
                            pool to use the public IP address specified in the frontend." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <div class="flex-custom">
                              <div class="checkbox-cs">
                                <input type="radio" name="outboundNetwork"  class="form-check-inline" id='recommend' (change)="setRadioBtn('yes','lbRules','outboundNetwork')" [checked]="currentForm.get('outboundNetwork')?.value ==true">
                                <label class="value" for="recommend">(Recommended) Use outbound rules<br> to provide backend pool members access<br> to the internet</label>
                              </div>
                            </div>
                            <div class="flex-custom">
                            <div class="checkbox-cs">
                                <input type="radio" name="outboundNetwork" class="form-check-inline" id='default' (change)="setRadioBtn('no','lbRules','outboundNetwork')" [checked]="currentForm.get('outboundNetwork')?.value == false">
                                <label class="value" for="default">Use default outbound access. This is not<br>
                                  recommended because it can cause SNAT<br> 
                                  port exhaustion.</label>
                            </div>
                          </div>
                        </div>
                        
                    </form>
                </div>
        
                <div class="fixed-btm-btn flex-custom no-wrap">
                    <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('lbRules') || currentForm.invalid" (click)="saveLBConfig('lbRules')">Add</button>
                </div>
            </div>
            </ng-container>
      </ng-container>
    <!-- inbound Rules-->
    <ng-container *ngIf="currentTemplate == 'inboundRules'">
      <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Inbound NAT Rules<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addInboundRules')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add </button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Frontend IP</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Frontend Port/Range</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Target</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Service</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of loadBalancerData?.properties?.inboundNatRules;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.frontendIPConfiguration.id.split('/')[10]}}</td>
                            <td isEllipsisActive> {{row.properties.frontendPort}}</td>
                            <td isEllipsisActive>-</td>
                            <td isEllipsisActive>Custom ({{row.properties.protocol}}/{{row.properties.backendPort}})</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteInboundRules')">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                           </td>
                          </tr>
                          <div class="col-md-12 mt-3" *ngIf="!loadBalancerData?.properties?.inboundNatRules?.length">
                            <p class="content-14 blur-text">No results.</p>
                          </div>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">Add Inbound NAT Rule<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
          
              <div class="body scroll-y">
                  <form [formGroup]="currentForm">
                      <div class="mb-3">
                          <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                          <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="inboundName" name="inboundName" formControlName="inboundName" (keyup)="validIPName($event,'frontend','rules')"/>
                          <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameExist">The name you entered matches another inbound NAT rule or loadbalancing rule used by this load balancer. The name of each inbound NAT rule and loadbalancing rule on a load balancer must be unique.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                              and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Type</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="type"  class="form-check-inline" id='azureVM' (change)="setRadioBtn('azureVM','inboundNatRules','type')" [checked]="currentForm.get('type')?.value =='azureVM'">
                              <label class="value" for="azureVM">Azure virtual machine</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="type" class="form-check-inline" id='backendPool' (change)="setRadioBtn('backendPool','inboundNatRules','type')" [checked]="currentForm.get('type')?.value == 'backendPool'">
                              <label class="value" for="backendPool">Backend pool</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">{{currentForm.get('type').value!='backendPool'?'Target virtual machine':'Target backend pool'}}</label>
                        <ng-select *ngIf="currentForm.get('type').value!='backendPool'" [items]="targetVMList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="targetVM" [clearable]="false" id="targetVM" formControlName="targetVM"></ng-select>
                        <ng-select *ngIf="currentForm.get('type').value=='backendPool'" [items]="backendPoolsList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="targetVM" [clearable]="false" id="targetVM" formControlName="targetVM"></ng-select>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Frontend IP Address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can only select frontend IP addresses that have one public IP 
                          and are not associated to any inbound rule that loadbalances across 
                          all ports and protocols. Frontend IP addresses that are part of an 
                          IP Prefix or are associated to inbound rule that loadbalances across 
                          all ports and protocols are not shown." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="frontendIPList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="frontendIP" [clearable]="false" id="frontendIP" formControlName="frontendIP"></ng-select>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">{{currentForm.get('type').value=='backendPool'?'Frontend port range start':'Frontend port'}}<span class="mandatory">*</span></label>
                        <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.portLBInvalidEmpty|| inputError?.portLBInvalidNumber || inputError?.portLBInvalidSizeRange" id="frontendPort" name="frontendPort" formControlName="frontendPort" (keyup)="validProbes($event,'portLB')"/>
                        <p class="text-danger" *ngIf="inputError?.portLBInvalidEmpty">  The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.portLBInvalidNumber">The value must be a valid number.</p>
                          <p class="text-danger" *ngIf="inputError?.portLBInvalidSizeRange">The value must be between 0 and 65534, inclusive.</p>
                          <p class="text-danger" *ngIf="inputError?.portLBInvalidSizeRange">Only positive whole numbers allowed.</p>
                      </div>
                      <div class="mb-3" *ngIf="currentForm.get('type').value!='backendPool'">
                        <label class="flex-custom no-wrap">Service tag<span class="mandatory">*</span></label>
                        <ng-select [items]="serviceTagList"  bindLabel="name" bindValue="name" (change)="saveDisable=true" name="serviceTag" [clearable]="false" id="serviceTag" formControlName="serviceTag"></ng-select>
                      </div>
                      <div class="mb-3" *ngIf="currentForm.get('type').value=='backendPool'">
                        <label class="flex-custom no-wrap">Current number of machines in backend pool</label>
                        <label class="flex-custom no-wrap bold-600">0</label>
                      </div>
                      <div class="mb-3" *ngIf="currentForm.get('type').value=='backendPool'">
                        <label class="flex-custom">Maximum number of machines in backend pool<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Maximum number of instances in the backend pool in the event of scaling out." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.machineCountInvalidEmpty|| inputError?.machineCountInvalidNumber || inputError?.machineCountInvalidSizeRange" id="poolMachinesCount" name="poolMachinesCount" formControlName="poolMachinesCount" (keyup)="validProbes($event,'machineCount')"/>
                        <p class="text-danger" *ngIf="inputError?.machineCountInvalidEmpty">  The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.machineCountInvalidNumber">The value must be a valid number.</p>
                        <p class="text-danger" *ngIf="inputError?.machineCountInvalidSizeRange">The value must be between 1 and 65535, inclusive.</p>
                        <p class="text-danger" *ngIf="inputError?.machineCountInvalidSizeRange">Only positive whole numbers allowed.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Backend port<span class="mandatory">*</span></label>
                        <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.backendPortInvalidEmpty|| inputError?.backendPortInvalidNumber || inputError?.backendPortInvalidSizeRange" id="backendPort" name="backendPort" formControlName="backendPort" (keyup)="validProbes($event,'backendPort')"/>
                        <p class="text-danger" *ngIf="inputError?.backendPortInvalidEmpty">  The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.backendPortInvalidNumber">The value must be a valid number.</p>
                          <p class="text-danger" *ngIf="inputError?.backendPortInvalidSizeRange">The value must be between 0 and 65534, inclusive.</p>
                          <p class="text-danger" *ngIf="inputError?.backendPortInvalidSizeRange">Only positive whole numbers allowed.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Protocol</label>
                          <div class="flex-custom">
                            <div class="checkbox-cs">
                              <input type="radio" name="protocol"  class="form-check-inline" id='TCP' (change)="setRadioBtn('Tcp','inboundNatRules','protocol')" [checked]="currentForm.get('protocol')?.value =='Tcp'">
                              <label class="value" for="TCP">TCP</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="protocol" class="form-check-inline" id='UDP' (change)="setRadioBtn('Udp','inboundNatRules','protocol')" [checked]="currentForm.get('protocol')?.value == 'Udp'">
                              <label class="value" for="UDP">UDP</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="currentForm.get('protocol').value!='Udp'">
                        <div class="checkbox-cs"> 
                          <input type="checkbox" name="tcpReset" class="form-check-inline" id='tcpReset' value="tcpReset" formControlName="tcpReset" (change)="saveDisable=true" >
                          <label class="value" for="tcpReset" (change)="saveDisable=true" >Enable TCP Reset<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Send TCP reset packet when idle timeout is reached." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="currentForm.get('protocol').value!='Udp'">
                        <label class="flex-custom no-wrap">Idle timeout (minutes)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Keep a TCP or HTTP connection open without relying 
                          on clients to send keep-alive messages." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.idleTimeoutEmpty|| inputError?.idleTimeoutNumber || inputError?.idleTimeoutRange" id="idealTimeout" name="idealTimeout" formControlName="idealTimeout" (keyup)="idleTimeValidation($event)"/>
                        <p class="text-danger" *ngIf="inputError?.idleTimeoutEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.idleTimeoutNumber">The value must be a valid number.</p>
                        <p class="text-danger" *ngIf="inputError?.idleTimeoutRange">The value must be between 4 and 30, inclusive.</p>
                        <p class="text-danger" *ngIf="inputError?.idleTimeoutRange">Only positive whole numbers allowed.</p>
                      </div>
                      <div class="mb-3">
                        <div class="checkbox-cs"> 
                          <input type="checkbox" name="floatingIP" class="form-check-inline" id='floatingIP' value="floatingIP" formControlName="floatingIP" (change)="saveDisable=true" >
                          <label class="value" for="floatingIP" (change)="saveDisable=true" >Enable Floating IP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Without Floating IP, Azure exposes a traditional load balancing IP address 
                            mapping scheme for ease of use (the VM instances' IP). Enabling Floating IP 
                            changes the IP address mapping to the Frontend IP of the load balancer to 
                            allow for additional flexibility." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        </div>
                      </div>
                  </form>
              </div>
      
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('inboundRules') || currentForm.invalid" (click)="saveLBConfig('inboundRules')">Add</button>
              </div>
          </div>
          </ng-container>
    </ng-container>
     <!-- OutBound rules -->
     <ng-container *ngIf="currentTemplate == 'outboundRules'">
      <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">Outbound Rules<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="close_reset()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
              <div class="comm-mb">
                  <button class="btn-btn-secondary" (click)="openSubSideSheet('addOutboundRules')">
                  <img src="/assets/governance/add-btn.svg" alt="">Add </button>
              </div>
            <div class="custom-fixed-tables mb-3">
                  <table class="table">
                      <thead>
                          <tr>
                            <th><div class="flex-custom no-wrap"><span>Name</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Frontend</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Backend</span></div></th>
                            <th><div class="flex-custom no-wrap"><span>Protocols</span></div></th>
                            <th style="border-right: none;"><div class="flex-custom no-wrap"><span>Ports Per Instance</span></div></th>
                            <th style="border-left: none;" class="wid-75"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of loadBalancerData?.properties?.outboundRules;let i=index">
                            <td isEllipsisActive>{{row?.name}}</td>
                            <td isEllipsisActive>{{row.properties.frontendIPConfigurations[0].id.split('/')[10]}}</td>
                            <td isEllipsisActive> {{row.properties.backendAddressPool.id.split('/')[10]}}</td>
                            <td isEllipsisActive> {{row.properties.protocol}}</td>
                            <td isEllipsisActive> {{row.properties.allocatedOutboundPorts}}</td>
                            <td class="text-right wid-75">
                              <span>
                                <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2">
                                <mat-menu #action2="matMenu" class="delete-popup" >
                                  <div class="mat-menu-item" mat-menu-item (click)="action(row,'deleteOutboundRules')">
                                      <p class="delete-popup-text">Delete</p>
                                  </div>
                                </mat-menu>
                              </span>              
                           </td>
                          </tr>
                          <div class="col-md-12 mt-3" *ngIf="!loadBalancerData?.properties?.outboundRules?.length">
                            <p class="content-14 blur-text">No results.</p>
                          </div>
                        </tbody>
                  </table>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showSubSidesheet">
          <div class="sidebar-right single add-alert">
              <div class="header">
                  <div class="d-flex">
                <h4 class="threeFour-width">Add Outbound Rules<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></h4>
                    <div class="text-right full-width">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation()">
                        <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                      </button>
                    </div>
                  </div>
              </div>
          
              <div class="body scroll-y">
                  <form [formGroup]="currentForm">
                      <div class="mb-3">
                          <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                          <input class="form-control full-width" [class.disabled-input]="isEdit" type="text" autocomplete="off" [class.border-danger]="inputError?.frontendNameEmpty|| inputError?.frontendNameMaxLen || inputError?.frontendNameExist|| inputError?.frontendNameValid" id="outboundName" name="outboundName" formControlName="outboundName" (keyup)="validIPName($event,'frontend','outboundRule')"/>
                          <p class="text-danger" *ngIf="inputError?.frontendNameEmpty">The value must not be empty.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameMaxLen">The name must be between 1 and 80 characters.</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameExist">Outbound rule name must be unique in Load Balancer</p>
                          <p class="text-danger" *ngIf="inputError?.frontendNameValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                              and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Frontend IP Address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select up to 16 IP addresses to be used by outbound traffic. 
                          Select more IP addresses to increase the number of ports per 
                          instance and the maximum number of backend instances." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                       <div class="margin-multi-select">
                        <ng-select class="full-width" [items]="frontendIPList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" placeholder="0 Selected" name="frontendIP" [clearable]="false" id="frontendIP" formControlName="frontendIP">
                            <ng-template ng-multi-label-tmp let-items="items">
                                <span>{{items.length}} selected</span>
                              </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div>
                                  <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected"  [checked]="item$.selected"/> {{item.name}}
                                </div>
                              </ng-template>
                        </ng-select>
                       </div>
                     </div>
                     <div class="mb-3">
                      <label class="flex-custom no-wrap">Protocol</label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="protocol"  class="form-check-inline" id='All' (change)="setRadioBtn('All','inboundNatRules','protocol')" [checked]="currentForm.get('protocol')?.value =='All'">
                            <label class="value" for="All">All</label>
                         </div>
                          <div class="checkbox-cs">
                            <input type="radio" name="protocol"  class="form-check-inline" id='TCP' (change)="setRadioBtn('Tcp','inboundNatRules','protocol')" [checked]="currentForm.get('protocol')?.value =='Tcp'">
                            <label class="value" for="TCP">TCP</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="protocol" class="form-check-inline" id='UDP' (change)="setRadioBtn('Udp','inboundNatRules','protocol')" [checked]="currentForm.get('protocol')?.value == 'Udp'">
                            <label class="value" for="UDP">UDP</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('protocol')?.value!='Udp'">
                      <label class="flex-custom no-wrap">Idle timeout (minutes)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Keep a TCP or HTTP connection open without relying on 
                        clients to send keep-alive messages." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" autocomplete="off" [class.border-danger]="inputError?.idleTimeoutEmpty|| inputError?.idleTimeoutNumber || inputError?.idleTimeoutRange" id="idealTimeout" name="idealTimeout" formControlName="idealTimeout" (keyup)="idleTimeValidation($event)"/>
                      <p class="text-danger" *ngIf="inputError?.idleTimeoutEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.idleTimeoutNumber">The value must be a valid number.</p>
                      <p class="text-danger" *ngIf="inputError?.idleTimeoutRange">The value must be between 4 and 30, inclusive.</p>
                      <p class="text-danger" *ngIf="inputError?.idleTimeoutRange">Only positive whole numbers allowed.</p>
                    </div>
                    <div class="mb-3" *ngIf="currentForm.get('protocol')?.value!='Udp'">
                      <label class="flex-custom no-wrap">TCP reset<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Send TCP reset packet when idle timeout is reached." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom">
                          <div class="checkbox-cs">
                            <input type="radio" name="tcpReset"  class="form-check-inline" id='tcpResetDisable' (change)="setRadioBtn('yes','lbRules','tcpReset')" [checked]="currentForm.get('tcpReset')?.value ==true">
                            <label class="value" for="tcpResetDisable">Enabled</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="tcpReset" class="form-check-inline" id='tcpResetEnable' (change)="setRadioBtn('no','lbRules','tcpReset')" [checked]="currentForm.get('tcpReset')?.value == false">
                            <label class="value" for="tcpResetEnable">Disabled</label>
                        </div>
                      </div>
                    </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Backend pool<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This rule applies to all instances in the backend pool." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="backendPoolsList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="backendPool" [clearable]="false" id="backendPool" formControlName="backendPool"></ng-select>
                      </div>
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Port allocation<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When using default port allocation, Azure may drop existing connections 
                          when you scale out. Manually allocate ports to avoid dropped connections." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="portAllocationList"  bindLabel="name" bindValue="id" (change)="saveDisable=true" name="portAllocation" [clearable]="false" id="portAllocation" formControlName="portAllocation"></ng-select>
                      </div>
                      <ng-container *ngIf="currentForm.get('portAllocation')?.value=='manual'">
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Outbound ports choose by<span class="mandatory">*</span></label>
                          <ng-select [items]="outboundPortList"  bindLabel="name" bindValue="id" (change)="outboundPort($event)" name="outboundPort" [clearable]="false" id="outboundPort" formControlName="outboundPort"></ng-select>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Ports per instance<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Each backend instance can use up to this many ports for connections." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                          <label class="flex-custom no-wrap bold-600" *ngIf="currentForm.get('outboundPort')?.value!='portInstance'">{{currentForm.get('portInstance')?.value}}</label>
                          <ng-conatiner *ngIf="currentForm.get('outboundPort')?.value=='portInstance'">
                            <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.portLBInvalidEmpty|| inputError?.portLBInvalidNumber || inputError?.portLBInvalidSizeRange || inputError?.InvalidPortInstance" id="portInstance" name="portInstance" formControlName="portInstance" (keyup)="validPortInstance($event,'portLB')"/>
                            <p class="text-danger" *ngIf="inputError?.portLBInvalidEmpty">  The value must not be empty.</p>
                            <p class="text-danger" *ngIf="inputError?.portLBInvalidNumber">The value must be a valid number.</p>
                            <p class="text-danger" *ngIf="inputError?.portLBInvalidSizeRange">The number of ports per instance must be a number divisible by 8.</p>
                            <p class="text-danger" *ngIf="inputError?.portLBInvalidSizeRange">Only positive whole numbers allowed.</p>
                            <p class="text-danger" *ngIf="inputError?.InvalidPortInstance">'Maximum number of backend instances' * 'Ports per instance' 
                              should be less than or equal to 'Available Frontend Ports'.</p>
                          </ng-conatiner>
                          
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Available Frontend Ports</label>
                          <label class="flex-custom no-wrap bold-600">0</label>
                        </div>
                        <div class="mb-3">
                          <label class="flex-custom no-wrap">Maximum number of backend instances<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Estimate the maximum number of backend pool instances. You cannot scale your 
                            backend pool above this number of instances. Increasing the number of instances 
                            decreases the number of ports per instance unless you also add more frontend 
                            IP addresses." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <label class="flex-custom no-wrap bold-600" *ngIf="currentForm.get('outboundPort')?.value!='backendInstance'">{{currentForm.get('backendInstance')?.value}}</label>
                            <ng-container *ngIf="currentForm.get('outboundPort')?.value=='backendInstance'">
                              <input class="form-control full-width"  type="text" autocomplete="off" [class.border-danger]="inputError?.backendPortInvalidEmpty|| inputError?.backendPortInvalidNumber || inputError?.backendPortInvalidSizeRange ||inputError?.InvalidPortInstance" id="backendInstance" name="backendInstance" formControlName="backendInstance" (keyup)="validPortInstance($event,'backendPort')"/>
                              <p class="text-danger" *ngIf="inputError?.backendPortInvalidEmpty">  The value must not be empty.</p>
                              <p class="text-danger" *ngIf="inputError?.backendPortInvalidNumber">The value must be a valid number.</p>
                              <p class="text-danger" *ngIf="inputError?.backendPortInvalidSizeRange">The maximum number of backend instances cannot be more than 1000.</p>
                              <p class="text-danger" *ngIf="inputError?.backendPortInvalidSizeRange">Only positive whole numbers allowed.</p>
                              <p class="text-danger" *ngIf="inputError?.InvalidPortInstance">'Maximum number of backend instances' * 'Ports per instance' 
                                should be less than or equal to 'Available Frontend Ports'.</p>
                            </ng-container>
                          
                        </div>
                      </ng-container>
                      <div class="mb-3" *ngIf="currentForm.get('portAllocation')?.value=='default'">
                        <div class="label-div d-flex warningBox">
                          <label class="" style="padding: 11.33px 9.33px !important;"><img  src="/assets/Infra/error-info.png" alt=""></label>
                          <label class="" style="padding: 8px 0px !important;color: #703501;">
                            Azure may drop existing connections when you scale out. Manually allocate ports to avoid dropped connections.</label>
                         </div>  
                      </div>
                  </form>
              </div>
      
              <div class="fixed-btm-btn flex-custom no-wrap">
                  <button class="btn btn-btn-primary"   [class.disabled]="!saveDisable || invalidForm('outboundRules') || currentForm.invalid" (click)="saveLBConfig('outboundRules')">Add</button>
              </div>
          </div>
          </ng-container>
    </ng-container>
 </ng-container>

  <ng-template #loadBalancerDetailsTemplate>
    <div class="medium-popup-wrapper width-832" style="width: 832px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">Load Balancer</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" (click)="openSidesheet('frontendIP',selectedData)">Frontend IP Configuration</div>
            <div class="tab-item" (click)="openSidesheet('backendPool',selectedData)">Backend Pools</div>
            <div class="tab-item" (click)="openSidesheet('healthProbes',selectedData)">Health Probes</div>
            <div class="tab-item" (click)="openSidesheet('lbRules',selectedData)">Load Balancing Rules</div>
            <div class="tab-item" *ngIf="selectedData[4] != 'Gateway'" (click)="openSidesheet('inboundRules',selectedData)">Inbound NAT Rules</div>
            <div class="tab-item" *ngIf="selectedData[4] != 'Internal' && selectedData[4] !='Basic'" (click)="openSidesheet('outboundRules',selectedData)">Outbound Rules</div>
            <div class="tab-item" (click)="action(selectedData,'deleteLBData')">Delete</div>
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedData;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name) | firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='status'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedData[i]:getTags(selectedData[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedData[i])}}</div>
                    <div *ngIf="col.name=='status'" class="value-text"><span class="colen">:</span>{{selectedData[i] | titlecase}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedData[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper" style="width: 530px;">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>{{getResourceName()}}</p>
          <p class="mt-3" *ngIf="selectedType=='deleteFrontendIP'">If you don't want the resources below deleted or the virtual machine scale sets updated, then you should not delete this frontend IP configuration</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getBtnName()}}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #addVMtoPool>
    <div class="medium-popup-wrapper width-832" style="width: 832px;padding:16px 24px;">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">{{selectedType=='openVmtoPool'?'Add Virtual Machines to Backend Pool':'Add Virtual Machine Scale Sets to Backend Pool'}}<br> <span class="blur-text">Load Balancer | {{selectedRow[0]}}</span></div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="subSideSheetForm">
          <div class="custom-fixed-tables">
            <table class="table" >
                <thead>
                  <tr>
                    <th><div class="flex-custom no-wrap"><span>Resource Name</span></div></th>
                    <th><div class="flex-custom no-wrap"><span>Resource Group</span></div></th>
                    <th><div class="flex-custom no-wrap"><span>IP Configuration</span></div></th>
                    <th><div class="flex-custom no-wrap"><span>Availability Set</span></div></th>
                    <th><div class="flex-custom no-wrap"><span>Tags</span></div></th>
                    <th><div class="flex-custom no-wrap"><span>Notes</span></div></th>    
                  </tr>
                </thead>
                <tbody > 
                  <ng-container *ngIf="selectedType=='openVmtoPool'">
                    <tr *ngFor="let row of vmNicList; let i = index;">
                      <td isEllipsisActive>
                        <input type="checkbox"  name="poolName" class="form-check-inline"  id='poolName_{{i}}'  (change)="enableAction(row,$event,i)" formControlName="poolName" [checked]="row?.checked">
                        <label class="value" for="poolName_{{i}}">{{row?.name}}</label>
                      </td>
                      <td isEllipsisActive>{{row?.id.split('/')[4]}}</td>
                      <td isEllipsisActive>{{row?.ipConfig}} ({{row?.privateIPAddress}})</td>
                      <td isEllipsisActive>-</td>
                      <td isEllipsisActive>-</td>
                      <td isEllipsisActive>-</td>
                    </tr>
                    <!-- <ng-conatiner  formArrayName="resourceName" *ngFor="let list of subSideSheetForm.get('resourceName')['controls']; let i = index;let row of vmNicList">
                      <tr >
                        <td isEllipsisActive [formGroupName]="i">
                          <input type="checkbox"  name="name" class="form-check-inline"  id='name_{{i}}'  (change)="enableAction(data?.body,$event,i)" formControlName="name" [checked]="data?.body?.checked">
                          <label class="value" for="name_{{i}}">{{row?.name}}</label>
                        </td>
                        <td isEllipsisActive>{{row?.id.split('/')[4]}}</td>
                        <td isEllipsisActive>{{row?.ipConfig}} ({{row?.privateIPAddress}})</td>
                        <td isEllipsisActive>-</td>
                        <td isEllipsisActive>-</td>
                        <td isEllipsisActive>-</td>
                      </tr>
                    </ng-conatiner> -->
                    <div class="col-md-12 mt-3" *ngIf="!vmNicList?.length">
                      <p class="content-14 blur-text">No data Found</p>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="selectedType=='openVmScaleSetPool'">
                    <tr *ngFor="let row of vmSetNicList; let i = index;">
                      <td isEllipsisActive>
                        <input type="checkbox"  name="poolName" class="form-check-inline"  id='poolName_{{i}}'  (change)="enableAction(row,$event,i)" formControlName="poolName" [checked]="data?.body?.checked">
                        <label class="value" for="poolName_{{i}}">{{row?.name}}</label>
                      </td>
                      <td isEllipsisActive>{{row?.id.split('/')[4]}}</td>
                      <td isEllipsisActive>{{row?.ipConfig}}</td>
                      <td isEllipsisActive>-</td>
                      <td isEllipsisActive>-</td>
                      <td isEllipsisActive>-</td>
                    </tr>
                    <!-- <ng-conatiner  formArrayName="resourceName" *ngFor="let list of subSideSheetForm.get('resourceName')['controls']; let i = index;let row of vmSetNicList">
                      <tr [formGroupName]="i">
                        <td isEllipsisActive>
                          <input type="checkbox"  name="name" class="form-check-inline"  id='name_{{i}}'  (change)="enableAction(data?.body,$event,i)" formControlName="name" [checked]="data?.body?.checked">
                          <label class="value" for="name_{{i}}">{{row?.name}}</label>
                        </td>
                        <td isEllipsisActive>{{row?.id.split('/')[4]}}</td>
                        <td isEllipsisActive>{{row?.ipConfig}} ({{row?.privateIPAddress}})</td>
                        <td isEllipsisActive>-</td>
                        <td isEllipsisActive>-</td>
                        <td isEllipsisActive>-</td>
                      </tr>
                    </ng-conatiner> -->
                    <div class="col-md-12 mt-3" *ngIf="!vmSetNicList?.length">
                      <p class="content-14 blur-text">No data Found</p>
                    </div>
                  </ng-container>
                </tbody>
            </table>
          </div>
         </form>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="addVmtoPool()">Add</button>
        </div>
    </div>
  </ng-template>