import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { IndianTimeFormat } from 'src/app/pipes/custom.pipe';
import { TimechartService } from 'src/app/services/timechart.service';
import { UtilService } from '../../../services/util.service';
import { SecurityBucketState } from '../../../state/security-bucket.state';
import { SecurityQueries } from '../../security-queries'

@Component({
  selector: 'app-activities-security',
  templateUrl: './activities-security.component.html',
  styleUrls: ['./activities-security.component.scss']
})
export class ActivitiesSecurityComponent implements OnInit  {
  @Select(SecurityBucketState.currentProjectDetail) currentProjectDetail$: Observable<any>;
  masterFilterObject:any;
  searchFilterObject: any;
  filterWindowVM: any=[];
  filterLinuxVM: any=[];
  filterObject={
    projectList : [],
    subscriptionList : [],
    resourceGroupList : [],
    resourceList : []
  }
  detailsPop:any;
  searchKey:any;
  datasort: string;
  refreshedTime: any = {
    recentActivities: new Date(),
    roleAssignmentActivities: new Date(),
    operationsOnNetworkSecurityGroups: new Date(),
    passwordResetActivitiesOnHost: new Date(),
    activitiesOnUsersAndGroups: new Date(),
    continuousActivities: new Date(),
    continuousActivitiesUser: new Date(),
  }
  options:any ={};
  recentActivities: any = {
    type:'recentActivities',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  roleAssignmentActivities: any = {
    type:'roleAssignmentActivities',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  operationsOnNetworkSecurityGroups: any = {
    type:'operationsOnNetworkSecurityGroups',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last7days',
      from:  new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  };
  passwordResetActivitiesOnHost: any = {
    type:'passwordResetActivitiesOnHost',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  activitiesOnUsersAndGroups: any = {
    type:'activitiesOnUsersAndGroups',
    tempTableData:[],
    tableData:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };
  continuousActivities: any = {
    type:'continuousActivities',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'Continuous Activities Performed by Users',
    xAxis:[],
    options:{},
    yAxis:[],
    showLegend : true,
    legendData:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };  
  continuousActivitiesUser: any = {
    type:'continuousActivitiesUser',
    color:['#7D3AC1', '#DB4CB2', '#19AADE','#1DE4BD','#FBB040'],
    name:'Continuous Activities Performed by Users',
    xAxis:[],
    options:{},
    yAxis:[],
    showLegend : true,
    legendData:[],
    tempData:[],
    tableData:[],
    tempTableData:[],
    series:[],
    showLoader : true,
    currentFilter: {
      type : 'last24hr',
      from:  new Date(new Date().setDate(new Date().getDate() - 1)),
      to: new Date()
    }
  };  
  selectedData: any;
  timeFilterList: any=[];
  timeFilterFlag: boolean=false;
  currentProject: any;
  subscriptionInfra: Subscription;
  assignUserList: any = [];
  roleDefinitionsList: any = [];
  assignApplicationList: any = [];
  groupsList: any = [];
  applicationsList: any = [];
  continuesActivitiesShow=false;
  processDetails: FormGroup;
  processList: any=[];
  
  constructor(private util:UtilService,private modalService: NgbModal,private datePipe:DatePipe,private timeFormat:IndianTimeFormat,private fb:FormBuilder,private timechartService:TimechartService) { }

  async ngOnInit() {
    this.processDetails= this.fb.group({
      process:[null],
      timeRange:['last24hr']
    });
    this.timeFilterList=this.util.getStatic('timeFilterList');
    this.subscriptionInfra = this.currentProjectDetail$.subscribe(async (res) => {
      if(res){
        this.currentProject = _.cloneDeep({...res});
        this.filterWindowVM=this.currentProject.resources.filter(dt=>dt.resourceType=="virtualMachines"||dt.resourceType=="Virtual Machines");
        this.filterLinuxVM=this.currentProject.resources.filter(dt=>dt.resourceType=="linuxVirtualMachines"||dt.resourceType=='Linux Virtual Machines');
        this.triggerFilter(this.currentProject,'project');
        await this.getListByPropName('roleDefinitions',this.filterObject.subscriptionList[0].subscriptionId);
        await this.getListByPropNameUsingGrpah('users');
        await this.getListByPropNameUsingGrpah('servicePrincipals');
        await this.getListByPropNameUsingGrpah('groups');
        await this.getListByPropNameUsingGrpah('applications');
        this.getTableData('recentActivities');
        this.getTableData('roleAssignmentActivities');
        this.getTableData('operationsOnNetworkSecurityGroups');
        this.getTableData('passwordResetActivitiesOnHost');
        this.getTableData('activitiesOnUsersAndGroups');
        this.getContinuousActivitiesData();
      }
    });
  }

  ngOnDestroy() {
    if(this.subscriptionInfra) this.subscriptionInfra.unsubscribe();
  }

  async getListByPropName(propName,subscriptionId){    
    let reqObj={
      subscriptionId,
      fetchPropName:propName,
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='roleDefinitions'){
        this.roleDefinitionsList=res.value;
      }
      return  result;
    },err=>{
    });
  }

  async getListByPropNameUsingGrpah(propName){
    
    let reqObj={
      fetchPropName:propName
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropNameUsingGrpah',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='users'){
        this.assignUserList=res.value;
      }else if(propName=='servicePrincipals'){
          this.assignApplicationList=res.value;
      }else if(propName=='groups'){
        this.groupsList=res.value;
      }else if(propName=='applications'){
        this.applicationsList=res.value;
      }
      return  result;
    },err=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='users'){
        this.assignUserList=[];
      } else if(propName=='servicePrincipals'){
          this.assignApplicationList=[];
      } else if(propName=='groups'){
        this.groupsList=[];
      } else if(propName=='applications'){
        this.applicationsList=[];
      }
    });
  }

  getTableData(type){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": this.getTableQuery(type)
    }
    this[type].tableData=[];
    this[type].tempTableData=[];
    this[type].showLoader = true;
    return this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      if(!res || !res.body) {
        this[type].showLoader = false;
        return 
      }
      res.body.tables.forEach(dt=>{
        dt.rows.forEach(ds=>{
          this[type].tempTableData.push(ds);
        });
      })
      this.filterTableData(type);
      this[type].showLoader = false;
    },err=>{
      this[type].showLoader = false;
    })
  }

  getTableQuery(type){
    switch(type){
      case 'recentActivities':
        return SecurityQueries.recentActivities.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'roleAssignmentActivities':
        return SecurityQueries.roleAssignmentActivities.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'operationsOnNetworkSecurityGroups':
        return SecurityQueries.operationsOnNetworkSecurityGroups.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'passwordResetActivitiesOnHost':
        return SecurityQueries.passwordResetActivitiesOnHost.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
      case 'activitiesOnUsersAndGroups':
        return SecurityQueries.activitiesOnUsersAndGroups.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this[type].currentFilter))
    }
  }

  filterCheck(value) {
    return (this.filterObject.subscriptionList.find(dtl=>dtl.checked&&value.split("/")[2]&&dtl.subscriptionId===(value.split("/")[2])) && this.filterObject.resourceGroupList.find(dtl=>dtl.checked&&value.split("/")[4]&&dtl.resourceGroupName.toLowerCase()===(value.split("/")[4]).toLowerCase()) && this.filterObject.resourceList.find(dtl=>dtl.checked&&(value.split("/")[value.split("/").length-1])&&dtl.resourceName.toLowerCase()===(value.split("/")[value.split("/").length-1]).toLowerCase()))||(this.filterObject.subscriptionList.find(dtl=>dtl.checked&&dtl.subscriptionId===(value.split("/")[2])) && !value.split("/")[4])
  }

  filterCheckOperation(value) {
    return (this.filterObject.subscriptionList.find(dtl=>dtl.checked&&value.split("/")[2]&&dtl.subscriptionId===(value.split("/")[2])) && this.filterObject.resourceGroupList.find(dtl=>dtl.checked&&value.split("/")[4]&&dtl.resourceGroupName.toLowerCase()===(value.split("/")[4]).toLowerCase()) && this.filterObject.resourceList.find(dtl=>dtl.checked&&(value.split("/")[8])&&dtl.resourceName.toLowerCase()===(value.split("/")[8]).toLowerCase()))||(this.filterObject.subscriptionList.find(dtl=>dtl.checked&&dtl.subscriptionId===(value.split("/")[2])) && !value.split("/")[4])
  }

  filterTableData(type): void {
    this[type].tableData=[];
    if(this[type].tempTableData&&this[type].tempTableData.length) {
      this[type].tempTableData.forEach(dt=>{
        let value;
        switch (type) {
          case 'recentActivities':
            value=dt[1];
            break;
          case 'roleAssignmentActivities':
            value=dt[2];
            break;
          case 'operationsOnNetworkSecurityGroups':
            value=dt[0];
            break;
          case 'passwordResetActivitiesOnHost':
            value=dt[7];
            break;
          case 'activitiesOnUsersAndGroups':
            value=dt[4];
            break;

          default:
            break;
        }
        if(value && type==='roleAssignmentActivities') {
          let checkVal=false;
          if(dt[2]=='Subscription'&&this.filterObject.subscriptionList.find(filterObj=>filterObj.subscriptionId===dt[3])) {
            checkVal=true;
            dt[3]=this.filterObject.subscriptionList.find(filterObj=>filterObj.subscriptionId===dt[3]).subscriptionName;
          } else if ( dt[2]=='Resource Group'&&this.filterObject.resourceGroupList.find(filterObj=>filterObj.resourceGroupName.toLowerCase()===dt[3].toLowerCase())) {
            checkVal=true;
          } else if ( this.filterObject.resourceList.find(filterObj=>filterObj.resourceName.toLowerCase()===dt[3].toLowerCase())) {
            checkVal=true;
          }
          if(checkVal) {
            dt[1]=this.filterUserName(dt[1]);
            dt[8]=this.filterUserName(dt[8]);
            dt[11]=this.filterRole(dt[11]);
            this[type].tableData.push(dt);
          }
        } else if(value && type==='operationsOnNetworkSecurityGroups' && this.filterCheckOperation(value)) {
          dt[3]=this.filterUserName(dt[3]);
          this[type].tableData.push(dt);
        } else if(value && this.filterCheck(value)) {
          if(type==='activitiesOnUsersAndGroups') {
            dt[1]=this.filterUserName(dt[1]);
            dt[2]=this.filterUserName(dt[2]);
            dt[10]=this.filterUserName(dt[10]);
            dt[11]=this.filterUserName(dt[11]);
            dt[12]=this.filterUserName(dt[12]);
          } else if(type==='recentActivities'){
            if(dt[3]) {
              let ids=JSON.parse(dt[3]);
              ids=ids.map(id=>this.filterUserName(id));
              dt[3]=ids;
            }
          } else if (type==='passwordResetActivitiesOnHost') {
            dt[2]=this.filterUserName(dt[2]);
            dt[4]=this.filterUserName(dt[4]);
            dt[5]=this.filterUserName(dt[5]);
          }
          this[type].tableData.push(dt);
        }
      })
    } else {
      this[type].tableData = [];
    }
  }

  jsonParseValue(value) {
    if(value&&JSON.parse(value)&&JSON.parse(value).length) {
      return JSON.parse(value).join(', ');
    }
  }

  filterRole(id) {
    let findObj=this.roleDefinitionsList.find(dt=>dt.name===id);
    if(findObj&&findObj.properties) {
      return findObj.properties.roleName
    } else {
      return id
    }
  }

  filterUserName(id) {
    let findObj=this.assignUserList.find(dt=>dt.id===id);
    if(!findObj) {
      findObj=this.applicationsList.find(dt=>dt.id===id);
    } 
    if(!findObj) {
      findObj=this.groupsList.find(dt=>dt.id===id);
    } 
    if(!findObj) {
      findObj=this.assignApplicationList.find(dt=>dt.id===id);
    }

    if(findObj&&findObj.displayName) {
      return findObj.displayName
    } else {
      return id;
    }
  }

  showContinuousActivities(seriesName) {
    if(this.continuousActivities.series.length && !this.continuousActivities.showLoader) {
      this.continuesActivitiesShow=true;
      this.processDetails.patchValue({
        timeRange:this.continuousActivities.currentFilter.type
      });
      this.continuousActivitiesUser.currentFilter=_.cloneDeep(this.continuousActivities.currentFilter);
      this.getContinuousActivities(seriesName);
    }
  }

  processChange() {
    this.continuousActivitiesUser.showLoader = true;
    this.continuousActivitiesUser.legendData = [];
    this.continuousActivitiesUser.series = [];
    this.continuousActivitiesUser.xAxis = [];
    this.continuousActivitiesUser.tempTableData=[];
    this.continuousActivitiesUser.tableData=[];
    let series=[];
    if(this.continuousActivitiesUser.tempData&&this.continuousActivitiesUser.tempData.length) {
      this.continuousActivitiesUser.tempData.forEach((ds,index1)=>{
        var timeArray = [];
        ds.rows.forEach((dt,index)=>{
          let filterCheck=false;
          let resourceIds=JSON.parse(dt[4]);
          resourceIds.forEach(rId=>{
            if(!filterCheck&&this.filterCheck(rId)) {
              filterCheck=true;
            }
          })
          if(index==0){
            timeArray=JSON.parse(dt[2]);    
          }
          dt[0]=this.filterUserName(dt[0]);
          if(filterCheck&&this.processDetails.value.process.name===dt[0]){
            let findIndex=series.findIndex(d=>d.name==dt[0]);
            if(findIndex>-1){
              let dataList=JSON.parse(dt[1]);
              dataList.forEach(dtl=>{
                series[findIndex].data.push(dtl);
              })
            }else{
              let obj = {
                name : dt[0],
                type : 'line',
                showSymbol :false,
                data: JSON.parse(dt[1])
              }            
              series.push(obj);
            }
            // let findTime=timeArray.find(dt=>dt==dt[0]);
            // if(index==0){
            //   timeArray=JSON.parse(dt[2]);    
            // }
            // let findObj=this.continuousActivitiesUser.legendData.find(d=>d.name==dt[0]);
            // if(!findObj){
            //   this.continuousActivitiesUser.legendData.push({icon:'circle',name:dt[0]});
            // }
            this.continuousActivitiesUser.tempTableData=[];
              if(dt[3]) {
                let data=JSON.parse(dt[3]);
                data.forEach(dt=>{
                  this.continuousActivitiesUser.tempTableData.push({
                    activity:dt
                  })
                })
              }
              if(dt[5]) {
                let data=JSON.parse(dt[5]);
                data.forEach((dt,index)=>{
                  this.continuousActivitiesUser.tempTableData[index]= {
                    ...this.continuousActivitiesUser.tempTableData[index],
                    activityStatus:dt
                  }
                })
              }
              if(dt[4]) {
                let data=JSON.parse(dt[4]);
                data.forEach((dt,index)=>{
                  this.continuousActivitiesUser.tempTableData[index]= {
                    ...this.continuousActivitiesUser.tempTableData[index],
                    resourceId:dt
                  }
                })
              }
              if(dt[7]) {
                let data=JSON.parse(dt[7]);
                data.forEach((dt,index)=>{
                  this.continuousActivitiesUser.tempTableData[index]= {
                    ...this.continuousActivitiesUser.tempTableData[index],
                    timeGenerated:dt
                  }
                })
              }
          }
        });
       
        let sorted : any = timeArray.sort((a,b)=> {
          var d1 = new Date(b);
          var d2 = new Date(a);
          return d2 > d1 ? 1 : 0;
        })
      
        sorted.forEach((x)=>{
          if(index1 == 0){
            let findXAxis=this.continuousActivitiesUser.xAxis.find(dt=>dt==moment(x).format('MM/DD/YYYY h:mm A'));
            if(!findXAxis){
            this.continuousActivitiesUser.xAxis.push(moment(x).format('MM/DD/YYYY h:mm A'))
            }
          }
        })
      });
    }
    setTimeout(()=>{
      this.continuousActivitiesUser.series = series;
      this.continuousActivitiesUser.options=this.setChartOption('continuousActivitiesUser');
      this.continuousActivitiesUser.tableData=this.continuousActivitiesUser.tempTableData.filter(dt=>this.filterCheck(dt.resourceId));
      this.continuousActivitiesUser.showLoader = false;
    },2000)
  }

  onChartClick(event) {
    if(event.seriesName) {
      this.continuousActivitiesUser.currentFilter=_.cloneDeep(this.continuousActivities.currentFilter);
      this.showContinuousActivities(event.seriesName);
    }
  }

  getContinuousActivities(seriesName){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.continuousActivities.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.continuousActivitiesUser.currentFilter)).replace('STEP',this.getTimeGranularity('continuousActivitiesUser'))
    }
    this.continuousActivitiesUser.showLoader = true
    this.continuousActivitiesUser.legendData = []
    this.continuousActivitiesUser.series = []
    this.continuousActivitiesUser.xAxis = []
    this.continuousActivitiesUser.tempData=[];
    this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      
      if(!res || !res.body) {
        this.continuousActivitiesUser.showLoader = false;
        return 
      }
      
      res.body.tables.forEach((dt)=>{
        this.continuousActivitiesUser.tempData.push(dt);
      })
      this.filtercontinuousActivitiesUser(seriesName);
      setTimeout(()=>{
        this.continuousActivitiesUser.showLoader = false;
      },2000)
    },err=>{
      this.continuousActivitiesUser.showLoader = false
    })
  }

  filtercontinuousActivitiesUser(seriesName): void {
    this.continuousActivitiesUser.legendData = [];
    this.continuousActivitiesUser.series = [];
    this.continuousActivitiesUser.xAxis = [];
    this.continuousActivitiesUser.tableData=[];
    this.processList=[];
    if(this.continuousActivitiesUser.tempData&&this.continuousActivitiesUser.tempData.length) {
      this.continuousActivitiesUser.tempData.forEach((ds,index1)=>{
        var timeArray = [];
        ds.rows.forEach((dt,index)=>{
          let filterCheck=false;
          let resourceIds=JSON.parse(dt[4]);
          resourceIds.forEach(rId=>{
            if(!filterCheck&&this.filterCheck(rId)) {
              filterCheck=true;
            }
          })
          if(index==0){
            timeArray=JSON.parse(dt[2]);    
          }
          if(filterCheck){
            let findObj=this.assignUserList.find(dk=>dk.id===dt[0]);
            let type='User';
            if(!findObj) {
              findObj=this.applicationsList.find(dk=>dk.id===dt[0]);
              type='Application';
            } 
            if(!findObj) {
              findObj=this.groupsList.find(dk=>dk.id===dt[0]);
              type='Group';
            } 
            if(!findObj) {
              findObj=this.assignApplicationList.find(dk=>dk.id===dt[0]);
              type=findObj?findObj.servicePrincipalType:'Application';
            }

            if(findObj&&findObj.displayName) {
              dt[0]=findObj.displayName
            } else {
              type='User';
            }
            if(!this.processList.find(dp=>dp.name===dt[0])){
              this.processList.push({
                name:dt[0],
                type
              })
            }
            let findIndex=this.continuousActivitiesUser.series.findIndex(d=>d.name==dt[0]);
            if(findIndex>-1){
              let dataList=JSON.parse(dt[1]);
              dataList.forEach(dtl=>{
                this.continuousActivitiesUser.series[findIndex].data.push(dtl);
              })
            }else if((this.continuousActivitiesUser.series.length===0&&(!seriesName||(seriesName&&seriesName===dt[0])))){
              let obj = {
                name : dt[0],
                type : 'line',
                showSymbol :false,
                data: JSON.parse(dt[1])
              }    
              this.processDetails.patchValue({
                process:this.processList.find(dp=>dp.name===(seriesName?seriesName:dt[0]))
              })        
              this.continuousActivitiesUser.series.push(obj);
              this.continuousActivitiesUser.tableData=[];
              if(dt[3]) {
                let data=JSON.parse(dt[3]);
                data.forEach(dt=>{
                  this.continuousActivitiesUser.tableData.push({
                    activity:dt
                  })
                })
              }
              if(dt[5]) {
                let data=JSON.parse(dt[5]);
                data.forEach((dt,index)=>{
                  this.continuousActivitiesUser.tableData[index]= {
                    ...this.continuousActivitiesUser.tableData[index],
                    activityStatus:dt
                  }
                })
              }
              if(dt[4]) {
                let data=JSON.parse(dt[4]);
                data.forEach((dt,index)=>{
                  this.continuousActivitiesUser.tableData[index]= {
                    ...this.continuousActivitiesUser.tableData[index],
                    resourceId:dt
                  }
                })
              }
              if(dt[7]) {
                let data=JSON.parse(dt[7]);
                data.forEach((dt,index)=>{
                  this.continuousActivitiesUser.tableData[index]= {
                    ...this.continuousActivitiesUser.tableData[index],
                    timeGenerated:dt
                  }
                })
              }
              this.continuousActivitiesUser.tableData=this.continuousActivitiesUser.tableData.filter(dt=>this.filterCheck(dt.resourceId));
            }
            // let findObj=this.continuousActivitiesUser.legendData.find(d=>d.name==(seriesName?seriesName:dt[0]));
            // if(!findObj){
            //   this.continuousActivitiesUser.legendData.push({icon:'circle',name:(seriesName?seriesName:dt[0])});
            // }
          }
        });
       
        let sorted : any = timeArray.sort((a,b)=> {
          var d1 = new Date(b);
          var d2 = new Date(a);
          return d2 > d1 ? 1 : 0;
        })
      
        sorted.forEach((x)=>{
          if(index1 == 0){
            let findXAxis=this.continuousActivitiesUser.xAxis.find(dt=>dt==moment(x).format('MM/DD/YYYY h:mm A'));
            if(!findXAxis){
            this.continuousActivitiesUser.xAxis.push(moment(x).format('MM/DD/YYYY h:mm A'))
            }
          }
        })
      });
      this.continuousActivitiesUser.options=this.setChartOption('continuousActivitiesUser');
    }
  }

  getTimeGranularity(name) {
    //iff(diff <= 1min, 15sec, iff(diff <= 5min, 1min, iff(diff <= 30min , 5min, iff(diff <= 1hr, 15min, iff(diff <= 4hr, 1hr, iff(diff <= 12hr, 3hr,iff(diff<=24hr, 6hr, iff(diff<=48hr, 12hr, iff(diff <= 7d, 1d,iff(diff <= 15d,3d,iff(diff <= 30d, 7d, iff(diff <= 60d, 15d, 30d))))))))))))
    const filterDate =this.util.getCommonIsoDate(this[name].currentFilter);
    const diffMinutes=moment.duration(moment(filterDate.to).diff(filterDate.from)).as('minutes');
    let step;
    if(diffMinutes<1||diffMinutes==1) {
      step='15sec';
    } else if(diffMinutes<5||diffMinutes==5) {
      step='1min';
    } else if(diffMinutes<30||diffMinutes==30) {
      step='5min';
    } else if(diffMinutes<60||diffMinutes==60) {
      step='15min';
    } else if(diffMinutes<240||diffMinutes==240) {
      step='1hr';
    } else if(diffMinutes<720||diffMinutes==720) {
      step='3hr';
    } else if(diffMinutes<1440||diffMinutes==1440) {
      step='6hr';
    } else if(diffMinutes<2880||diffMinutes==2880) {
      step='12hr';
    } 
    if(!step) {
      const diffDays=moment.duration(moment(filterDate.to).diff(filterDate.from)).as('days');
      if(diffDays<7||diffDays==7) {
        step='1d';
      } else if(diffDays<15||diffDays==15) {
        step='3d';
      } else if(diffDays<30||diffDays==30) {
        step='7d';
      } else if(diffDays<60||diffDays==60) {
        step='15d';
      } else if(diffDays>60) {
        step='30d';
      }
    }
    return step
  }

  getContinuousActivitiesData(){
    const tempSubList=_.cloneDeep(this.filterObject.subscriptionList);    
    let body ={
      "subscriptions":tempSubList.map(dt=>dt.subscriptionId),
      "query": SecurityQueries.continuousActivities.replace('TIMERANGE',this.util.getIdentitySecurityTimeRangeQuery(this.continuousActivities.currentFilter)).replace('STEP',this.getTimeGranularity('continuousActivities'))
    }
    this.continuousActivities.showLoader = true
    this.continuousActivities.legendData = []
    this.continuousActivities.series = []
    this.continuousActivities.xAxis = []
    this.continuousActivities.tempData=[];
    this.util.handleRequest('post','a3s_security_getWorkspaceLogData',[],body,null,null,true).then(res=>{
      this.continuousActivities.showLoader = false
      if(!res || !res.body) return 
      
      res.body.tables.forEach((dt)=>{
        this.continuousActivities.tempData.push(dt);
      })
      this.filterContinuousActivities();
    },err=>{
      this.continuousActivities.showLoader = false
    })
  }

  filterContinuousActivities(): void {
    this.continuousActivities.legendData = [];
    this.continuousActivities.series = [];
    this.continuousActivities.xAxis = [];
    if(this.continuousActivities.tempData&&this.continuousActivities.tempData.length) {
      this.continuousActivities.tempData.forEach((ds,index1)=>{
        var timeArray = [];
        ds.rows.forEach((dt,index)=>{
          let filterCheck=false;
          let resourceIds=JSON.parse(dt[4]);
          resourceIds.forEach(rId=>{
            if(!filterCheck&&this.filterCheck(rId)) {
              filterCheck=true;
            }
          })
          if(index==0){
            timeArray=JSON.parse(dt[2]);    
          }
          if(filterCheck){
            dt[0]=this.filterUserName(dt[0]);
            let findIndex=this.continuousActivities.series.findIndex(d=>d.name==dt[0]);
            if(findIndex>-1){
              let dataList=JSON.parse(dt[1]);
              dataList.forEach(dtl=>{
                this.continuousActivities.series[findIndex].data.push(dtl);
              })
            }else{
              let obj = {
                name : dt[0],
                type : 'line',
                showSymbol :false,
                data: JSON.parse(dt[1])
              }            
              this.continuousActivities.series.push(obj);
            }
            // let findTime=timeArray.find(dt=>dt==dt[0]);
            // if(index==0){
            //   timeArray=JSON.parse(dt[2]);    
            // }
            let findObj=this.continuousActivities.legendData.find(d=>d.name==dt[0]);
            if(!findObj){
              this.continuousActivities.legendData.push({icon:'circle',name:dt[0]});
            }
          }
        });
       
        let sorted : any = timeArray.sort((a,b)=> {
          var d1 = new Date(b);
          var d2 = new Date(a);
          return d2 > d1 ? 1 : 0;
        })
      
        sorted.forEach((x)=>{
          if(index1 == 0){
            let findXAxis=this.continuousActivities.xAxis.find(dt=>dt==moment(x).format('MM/DD/YYYY h:mm A'));
            if(!findXAxis){
            this.continuousActivities.xAxis.push(moment(x).format('MM/DD/YYYY h:mm A'))
            }
          }
        })
      });
      this.continuousActivities.options=this.setChartOption('continuousActivities');
    }
  }

  setChartOption(name) {
    let previousIndex;
    const filterDate =this.util.getCommonIsoDate(this[name].currentFilter);
    let dateList=this.timechartService.xAxisDateTimeFormatList(filterDate,this[name].currentFilter.timeZone,this[name]);
    dateList.splice(0,1);
    let timeSpan=this[name].xAxis.length-dateList.length;
    if(timeSpan>2) {
      timeSpan=2
    }
    let options = {
      color: this[name].color,
      tooltip: this.util.getLineChartTooltip(),
      legend: {
        show: true,
        data: this[name].legendData,
        bottom: 0,
        left: 'left'
      },
      grid: {
        left: 0,
        right: '2%',
        containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap:true,
          axisTick: {
            alignWithLabel: true,
            interval: Number((timeSpan).toFixed()),
          },
          data: this[name].xAxis,
          axisLabel: {
            color: '#626F7B',
            interval: Number((timeSpan).toFixed()),
            padding: [5, 0, 0, 0],
            fontSize: 12, 
            showMinLabel:dateList[0].valueXaxis?true:false,
            fontFamily: 'Inter',
            formatter: function (value, index) { 
              if(index==0) {
                previousIndex=-1;
              }
              previousIndex=previousIndex+1;
              if(dateList[previousIndex]) {
                return dateList[previousIndex].valueXaxis
              }
            }
          },
          // axisLabel: {
          //   color: '#626F7B',
          //   padding: [5, 0, 0, 0],
          //   fontSize: 12, fontFamily: 'Inter',
          //   axisLine: {
          //     lineStyle: {
          //       color: '#8995A2',
          //     }
          //   },
          //   splitLine: {
          //     lineStyle: {
          //       color: '#8995A2',
          //     }
          //   },
          // },
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            color: '#626F7B',
            padding: [5, 0, 0, 0],
            fontSize: 12, fontFamily: 'Inter',
          },
      },
      series: this[name].series
    };
    return options
  }

  getStatusClass(value){
    if(value) {
      let status = value.toLowerCase()
      switch (status) {
        case 'success': return 'success-chip';
        case 'succeeded': return 'success-chip';
        case 'failed': return 'error-chip';
        case 'emergency': return 'emergency-severity-chip';
        case 'error': return 'error-chip';
        case 'warning': return 'warning-chip';
        case 'informational': return 'informational-chip';
        case 'debug': return 'debug-chip';
        case 'alert': return 'alert-chip';
        case 'notice': return 'notice-chip';
        case 'critical': return 'critical-chip';
        case 'started': return 'notice-chip';
        case 'accepted': return 'debug-chip';
        default: return 'error-chip';
      }
    }
  }

  openPopup(type:string, list, template?: TemplateRef<any>): void {
    this.detailsPop=list;
    if(type==='recentActivities') {
      this.detailsPop[9]=[];
      if(list[5]) {
        let data=JSON.parse(list[5]);
        data.forEach(dt=>{
          this.detailsPop[9].push({
            activityStatus:dt
          })
        })
      }
      if(list[6]) {
        let data=JSON.parse(list[6]);
        data.forEach((dt,index)=>{
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            severity:dt
          }
        })
      }
      if(list[7]) {
        let data=JSON.parse(list[7]);
        data.forEach((dt,index)=>{
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            timeGenerated:dt
          }
        })
      }
      if(list[8]) {
        let data=JSON.parse(list[8]);
        data.forEach((dt,index)=>{
          dt=this.filterUserName(dt);
          this.detailsPop[9][index]= {
            ...this.detailsPop[9][index],
            performedBy:dt
          }
        })
      }
    }
    this.modalService.open(template);
  }

  downloadDirectTableView(name,tableId){
    let table=document.getElementById(tableId);
    if(table) {
      this.downloadFile(name,table)
    } else {
      this.downloadFileCustom(name);
    }
  }

  downloadFile(name,tableId){
    this.util.generateCSVForTableData(name,tableId);
  }

  downloadFileCustom(name): void {
    let headers = [];
    let fileName = name;
    let dataList = [];
    if (name === 'recentActivities') {
      fileName = 'Recent Activities';
      headers = ['Activity','Category','Resource','Performed By','Count'];
    } else if (name === 'roleAssignmentActivities') {
      fileName = 'Role Assignment Activities';
      headers = ['Performed By','Role','Role Assigned to','Time Generated (UTC)'];
    } else if (name === 'operationsOnNetworkSecurityGroups') {
      fileName = 'Operations on Network Security Groups';
      headers = ['NSG Name','Resource Group','Operation Name','Performed By','Result','Time Generated (UTC)'];
    } else if (name === 'passwordResetActivitiesOnHost') {
      fileName = 'Password Reset Activities on Host';
      headers = ['Changed by','Changed for','Host','Result','Severity','Time Generated (UTC)'];
    } else if (name === 'activitiesOnUsersAndGroups') {
      fileName = 'Activities on Users and Groups';
      headers = ['Activity','Host','Performed By','Result','Severity','Time Generated (UTC)'];
    } else if (name === 'recentActivitiesPopupId') {
      fileName = 'Recent Activities';
      headers = ['Activity','Category','Resource','Resource group','Time Generated (UTC)','Severity','Activity Status','Performed By'];
      this.detailsPop[9].forEach(data=>{
        let value=[];
        value.push(this.detailsPop[0]);
        value.push(this.detailsPop[2]);
        value.push(this.detailsPop[1].split('/')[this.detailsPop[1].split('/').length-1]);
        value.push(this.detailsPop[1].split('/')[4]);
        Object.keys(data).forEach(key=>{
          if(key === 'timeGenerated') {
            value.push(this.datePipe.transform(data[key],'MM/dd/yyyy, hh:mm:ss a'));  
          } else {
            value.push(data[key]);
          }
        })
        dataList.push(value);
      })
    } 
    this.util.generateCSVForCustom(fileName, headers, dataList);
  }

  // closeModal() {
  //   this.modalService.dismissAll();
  // }

  /************************************** Time Range Filter ******************************************8*/
  applyFilter(value,list){
    list.currentFilter.type = value
    switch(list.type){
      case 'recentActivities' :this.getTableData('recentActivities');break;
      case 'roleAssignmentActivities' :this.getTableData('roleAssignmentActivities');break;
      case 'operationsOnNetworkSecurityGroups' :this.getTableData('operationsOnNetworkSecurityGroups');break;
      case 'passwordResetActivitiesOnHost' :this.getTableData('passwordResetActivitiesOnHost');break;
      case 'activitiesOnUsersAndGroups' :this.getTableData('activitiesOnUsersAndGroups');break;
      case 'continuousActivities' :this.getContinuousActivitiesData();break;
      case 'continuousActivitiesUser' :this.getContinuousActivities(null);break;
    }
    this.closeFilter()
  }
  
  applyCustomFilter(){
    this.selectedData.currentFilter.type = 'custom';
    switch(this.selectedData.type){
      case 'recentActivities' :this.recentActivities = this.selectedData;this.getTableData('recentActivities');break;
      case 'roleAssignmentActivities' :this.roleAssignmentActivities = this.selectedData;this.getTableData('roleAssignmentActivities');break;
      case 'operationsOnNetworkSecurityGroups' :this.operationsOnNetworkSecurityGroups = this.selectedData;this.getTableData('operationsOnNetworkSecurityGroups');break;
      case 'passwordResetActivitiesOnHost' :this.passwordResetActivitiesOnHost = this.selectedData;this.getTableData('passwordResetActivitiesOnHost');break;
      case 'activitiesOnUsersAndGroups' :this.activitiesOnUsersAndGroups = this.selectedData;this.getTableData('activitiesOnUsersAndGroups');break;
      case 'continuousActivities' :this.continuousActivities = this.selectedData;this.getContinuousActivitiesData();break;
      case 'continuousActivitiesUser' :this.continuousActivitiesUser = this.selectedData;this.getContinuousActivities(null);break;
    }
    this.closeModal()
  }

  openFilter(id){
    this.closeFilter();
    $('#'+id).toggleClass('open')
    this.timeFilterFlag=true;
  }

  closeFilter(){
    for(let i = 1;i < 9;i++){
      $('#customFilter').removeClass('open')
      $('#customFilter'+i).removeClass('open')
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.closeFilter();
  }

  openModal(template: TemplateRef<any>,list?:any) {
    this.selectedData = list
    this.modalService.open(template, { windowClass: 'custom-popup calendar-popup' });
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if(!this.timeFilterFlag){
    this.closeFilter();
    }
    this.timeFilterFlag=false;
  }

  getTimeFilterVal(modelObj){
    let findObj=this.timeFilterList.find(dt=>dt.id==modelObj.currentFilter.type);
    return findObj&&findObj.name?findObj.name:''
  }

  applyFilterData(){
    this.filterTableData('recentActivities');
    this.filterTableData('roleAssignmentActivities');
    this.filterTableData('operationsOnNetworkSecurityGroups');
    this.filterTableData('passwordResetActivitiesOnHost');
    this.filterTableData('activitiesOnUsersAndGroups');
    this.filterContinuousActivities();
    this.filtercontinuousActivitiesUser(null);
  }


  /******************************************** Filter Functionalities *****************************************/

  triggerFilter(data,type,event?:any){
    switch(type){
      case 'project':{
        this.filterObject.projectList = []
        this.filterObject.subscriptionList = []
        this.filterObject.resourceGroupList = []
        this.filterObject.resourceList = []
        this.filterObject.projectList.push(data)
        this.filterObject.projectList.forEach(data=>{
          data.checked = true
          this.filterObject.subscriptionList.push(data)
          this.filterObject.resourceGroupList =  [...data.resourceGroups]
          this.filterObject.resourceList = [...data.resources]
        })
        this.filterObject.resourceGroupList.forEach(e=> e.checked = true)
        this.filterObject.resourceList.forEach(e=> e.checked = true)
        this.masterFilterObject = _.cloneDeep(this.filterObject)
       break;
      }
      case 'subscription':{
        if (event.target.checked) {
          var filterRG = this.masterFilterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(e=> e.checked = true)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceGroupList = [...filterRG];
          this.filterObject.resourceList = [...filterResource];
          data.checked = true
        }
        else{
          var filterRG : any = this.filterObject.resourceGroupList.filter(e=> e.projectId == data.projectId)
          filterRG.forEach(x=>{
            let index = this.filterObject.resourceGroupList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceGroupList.splice(index,1)
          })
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.projectId == x.projectId)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resourceGroup':{
        if (event.target.checked) {
          var filterResource = this.masterFilterObject.resourceList.filter(e=> e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(e=> e.checked = true)
          this.filterObject.resourceList = [...this.filterObject.resourceList,...filterResource];
          data.checked = true
        }
        else{
          var filterResource : any= this.filterObject.resourceList.filter(e=> e.projectId == data.projectId && e.resourceGroupName == data.resourceGroupName)
          filterResource.forEach(x=>{
            let index = this.filterObject.resourceList.findIndex(e=> e.resourceGroupName == x.resourceGroupName)
            this.filterObject.resourceList.splice(index,1)
          })
          data.checked = false
        }
        break;
      }
      case 'resource':{
        if (event.target.checked) {
          data.checked = true
        }
        else  data.checked = false
      }
    }
    this.filterObject = _.cloneDeep(this.filterObject)
    this.searchFilterObject = _.cloneDeep(this.filterObject)
    this.applyFilterData();
  }

  filterSection(type,array?:any,objectName?:any){
    switch(type){
      case 'subscription':{
        return this.searchFilterObject.subscriptionList.filter(e=> e.checked)
      }
      case 'resourceGroup' :{
        if(this.searchFilterObject.resourceGroupList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceGroupList.some(x=> x.resourceGroupName == e[objectName] && x.checked))
        }
        else return array;
      }
      case 'resource':{
        if(this.searchFilterObject.resourceList.some(e=> e.checked)){
          return array.filter(e=> this.searchFilterObject.resourceList.some(x=> x.resourceName == e[objectName] && x.checked))
        }
        else return array;
      }
    }
    
  }

  clearAllFilter(){
    this.searchFilterObject.subscriptionList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceGroupList.forEach(e=> e.checked = false)
    this.searchFilterObject.resourceList.forEach(e=> e.checked = false)
    this.filterObject = this.searchFilterObject
    this.searchKey = null
    this.applyFilterData();
  }

  checkFilterApplied(type){
    switch(type){
      case 'subscription': return this.searchFilterObject.subscriptionList.some(e=> e.checked)
      case 'resourceGroup': return this.searchFilterObject.resourceGroupList.some(e=> e.checked)
      case 'resource': return this.searchFilterObject.resourceList.some(e=> e.checked)
      case 'all': return this.searchFilterObject.subscriptionList.some(e=> e.checked) || this.searchFilterObject.resourceGroupList.some(e=> e.checked) || this.searchFilterObject.resourceList.some(e=> e.checked)
    }
  }

  searchFilter(txt,objectName) {
    txt = txt.toLowerCase();
    this.filterObject[objectName] = [...this.searchFilterObject[objectName]];
    let list = this.filterObject[objectName].filter(resp => {
      switch(objectName){
        case 'subscriptionList' : return resp.subscriptionName.toLowerCase().includes(txt)
        case 'resourceGroupList' : return resp.resourceGroupName.toLowerCase().includes(txt)
        case 'resourceList' : return resp.resourceName.toLowerCase().includes(txt)
      }
    })
    this.filterObject[objectName] = list;
  }


  async refreshData(type){
    switch (type) {
      case 'recentActivities':{
        this.refreshedTime.recentActivities = new Date();
        this.getTableData('recentActivities');
        break;
      }
      case 'roleAssignmentActivities':{
        this.refreshedTime.roleAssignmentActivities = new Date();
        this.getTableData('roleAssignmentActivities');
        break;
      }
      case 'operationsOnNetworkSecurityGroups':{
        this.refreshedTime.operationsOnNetworkSecurityGroups = new Date();
        this.getTableData('operationsOnNetworkSecurityGroups');
        break;
      }
      case 'passwordResetActivitiesOnHost':{
        this.refreshedTime.passwordResetActivitiesOnHost = new Date();
        this.getTableData('passwordResetActivitiesOnHost');
        break;
      }
      case 'activitiesOnUsersAndGroups':{
        this.refreshedTime.activitiesOnUsersAndGroups = new Date();
        this.getTableData('activitiesOnUsersAndGroups');
        break;
      }
      case 'continuousActivities':{
        this.refreshedTime.continuousActivities = new Date();
        this.getContinuousActivitiesData()
        break;
      }
      case 'continuousActivitiesUser':{
        this.refreshedTime.continuousActivitiesUser = new Date();
        this.getContinuousActivities(null)
        break;
      }
    }
  }

  refreshedTimeFormat(time){
    return this.util.refreshedTimeFormat(time);
  }

  sortAll(tableName,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName].tableData;
    sorted.sort((a, b) => {
        switch (value) {
            case 'unhealthyResourceCount': {
              let x = a.properties.unhealthyResourceCount? a.properties.unhealthyResourceCount: 0;
              let y = b.properties.unhealthyResourceCount? b.properties.unhealthyResourceCount: 0;
              return (x < y) ? num : ((x > y) ? -num : 0)
            }
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName].tableData = sorted;
 }

  sortPopup(tableName,index,value) {
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    let sorted = this[tableName][index];
    sorted.sort((a, b) => {
        switch (value) {
            default: {
              let x =  a[value] ? a[value]: ' ';
              let y = b[value] ? b[value] : ' ';
              return (x < y) ? num : ((x > y) ? -num : 0) ;
            }
        }
    })
    this[tableName][index] = sorted;
 }

}