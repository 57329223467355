import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metrics-exception',
  templateUrl: './metrics-exception.component.html',
  styleUrls: ['./metrics-exception.component.scss']
})
export class MetricsExceptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
