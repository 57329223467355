<div class="custom-fixed-tables">
    <table class="table">
        <thead>
          <tr>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Name</span>
                    <img class="sort" src="assets/icons/sort.png"(click)="sortByKey('vpnGateway',0)" >
                </div>
            </th>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Resource Group</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vpnGateway',1)">
                </div>
            </th>
            <th>
                <div class="flex-custom no-wrap">
                    <span>Subscription</span>
                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vpnGateway',2)">
                </div>
            </th>
            <th>
              <div class="flex-custom no-wrap">
                  <span>Region</span>
                  <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vpnGateway',3)">
              </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>Virtual Network</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vpnGateway',4)">
            </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>Gateway Type</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vpnGateway',5)">
            </div>
          </th>
          <th>
            <div class="flex-custom no-wrap">
                <span>SKU</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vpnGateway',6)">
            </div>
          </th>
          <th style="border-right: none;">
            <div class="flex-custom no-wrap">
                <span>Tags</span>
                <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('vpnGateway',7)">
            </div>
          </th>
          <th style="border-left: none;" class="wid-75"></th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of data" >
              <td isEllipsisActive class="blue-text cursor-pointer" (click)="openModal(vpnGatewayDetailsTemplate,row,'vpnGatewayPopUp')">{{ row[0] | displayTxt}}</td>
              <td isEllipsisActive>{{ row[1] | displayTxt}}</td>
              <td isEllipsisActive class="ellips-td"><div [title]='row[2]' class="ellips-text">{{ getProjectName(row[2]) }}</div></td>
              <td isEllipsisActive>{{ getRegionName(row[3]) | displayTxt }}</td>
              <td isEllipsisActive class="whitespace-no">{{ row[4] | displayTxt }}</td>
              <td isEllipsisActive>{{ row[5] | displayTxt }}</td>
              <td isEllipsisActive>{{ row[6] | displayTxt }}</td>
              <td isEllipsisActive>{{getTags(row[7])}}</td>
              <td class="text-right wid-75">
                <span>
                  <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="action2" (click)="getVPNGatewayData(row,'disableCondition')">
                  <mat-menu #action2="matMenu" class="delete-popup" >
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('configuration',row)">
                      <p class="delete-popup-text">Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('connection',row)">
                      <p class="delete-popup-text">Connections</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item *ngIf="disableCondition && !point2siteCondition" (click)="openSidesheet('point-to-site',row)">
                      <p class="delete-popup-text">Point-to-Site Configuration</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item *ngIf="disableCondition && !natRuleCondition" (click)="openSidesheet('natRule',row)">
                      <p class="delete-popup-text">NAT Rules (Preview)</p>
                    </div>
                    <div class="mat-menu-item" mat-menu-item (click)="openSidesheet('deleteVPNGateway',row)">
                        <p class="delete-popup-text">Delete</p>
                      </div>
                  </mat-menu>
                </span>              
              </td>
          </tr>
          <div class="col-md-12 mt-3" *ngIf="!data?.length">
            <p class="content-14 blur-text">No data Found</p>
          </div>
        </tbody>
    </table>
  </div>
  <ng-container *ngIf="showSidesheet">

    <!-- Networking Disk -->
    <ng-container *ngIf="currentTemplate == 'configuration'">
        <div class="sidebar-right single add-alert" [class.sub-sheet]="showSubSidesheet" >
            <div class="header">
                <div class="d-flex">
                    <h4>Configuration<br> <span class="blur-text">Virtual Network Gateway | {{selectedRow[0]}}</span></h4>
                   <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetValidation();resetForm();saveDisable=false">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
    
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                  <div class="mb-3" *ngIf="vpnGatewayData?.properties?.gatewayType!='ExpressRoute'">
                    <label class="flex-custom no-wrap">Generation<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Generation of VPN gateway, changing Generation or changing 
                      SKU across generation is not allowed. Basic and VpnGw1 SKUs 
                      are supported on Generation1 only, VpnGw4 and VpnGw5 SKUs 
                      are supported on Generation2 only." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <label class="bold-600">{{vpnGatewayData?.properties?.vpnGatewayGeneration}}</label>
                  </div>
                    <div class="mb-3">
                        <label class="flex-custom no-wrap">SKU<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" [matTooltip]="skuToolTip" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="skuList"  bindLabel="name" bindValue="id" name="sku" [clearable]="false" id="sku" formControlName="sku" (change)="conditionForActiveMode($event);saveDisable=true"></ng-select>
                      </div>  
                      <div class="mb-3">
                        <label class="flex-custom no-wrap mb-2">Active-active mode</label>
                            <div class="checkbox-cs">
                                <input type="radio" name="activeMode"  class="form-check-inline" id='enableActive' (change)="setRadioBtn('yes','active-mode')" [disabled]="vpnGatewayData?.properties?.vpnType!='RouteBased'||currentForm.get('sku').value=='Standard'||currentForm.get('sku').value=='Basic' ||disableActiveMode" [checked]="currentForm.get('activeMode')?.value == true">
                                <label class="value" for="enableActive">Enable</label>
                                <input type="radio" name="activeMode" class="form-check-inline" id='disableActive' (change)="setRadioBtn('no','active-mode')" [disabled]="vpnGatewayData?.properties?.vpnType!='RouteBased'|| currentForm.get('sku').value=='Standard'||currentForm.get('sku').value=='Basic' || disableActiveMode" [checked]="currentForm.get('activeMode')?.value == false">
                                <label class="value" for="disableActive">Disable</label>
                            </div>
                      </div>
                      <div class="mb-3" *ngIf="currentForm.get('activeMode')?.value&&!vpnGatewayData?.properties?.activeActive">
                        <label class="flex-custom no-wrap">Second public IP address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" [matTooltip]="" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="publicIPList"  bindLabel="name" bindValue="id" name="publicIp" [clearable]="false" id="publicIp" formControlName="publicIp2" (change)="getpublicIPConfig($event);saveDisable=true"></ng-select>
                        <span class="action-btn"  (click)="publicIPForm()">Create new</span>
                      </div>  
                      <div class="mb-3" *ngIf="!vpnGatewayData?.properties?.activeActive&&currentForm.get('activeMode')?.value && vpnGatewayData?.properties?.vpnClientConfiguration && (currentForm.get('sku')?.value=='VpnGw2AZ'||currentForm.get('sku')?.value=='VpnGw3AZ')">
                        <label class="flex-custom no-wrap">Third public IP address<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" [matTooltip]="" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="publicIPList"  bindLabel="name" bindValue="id" name="publicIp3" [clearable]="false" id="publicip3" formControlName="publicIp3" (change)="getpublicIPConfig($event);saveDisable=true"></ng-select>
                      </div>  
                      <div class="mb-3" *ngIf="vpnGatewayData?.properties?.vpnType=='RouteBased' && (currentForm.get('sku').value!='Basic'&& currentForm.get('sku').value!='Standard'&& currentForm.get('sku').value!='HighPerformance')">
                        <label class="flex-custom no-wrap mb-2">Gateway Private IPs</label>
                            <div class="checkbox-cs">
                                <input type="radio" name="gatewayIP"  class="form-check-inline" id='enableIP' (change)="setRadioBtn('yes','gatewayIP')" [checked]="currentForm.get('gatewayIP')?.value == true">
                                <label class="value" for="enableIP">Enable</label>
                                <input type="radio" name="gatewayIP" class="form-check-inline" id='disableIP' (change)="setRadioBtn('no','gatewayIP')" [checked]="currentForm.get('gatewayIP')?.value == false">
                                <label class="value" for="disableIP">Disable</label>
                            </div>
                      </div>
                      <div class="mb-3">
                        <div class="checkbox-cs"> 
                          <input type="checkbox" name="configBGP" class="form-check-inline" id='configBGP' formControlName="configBGP" [class.disabled-icon]="vpnGatewayData?.properties?.vpnType!='RouteBased'||currentForm.get('sku').value=='Basic'" (change)="saveDisable=true">
                          <label class="value" for="ipAddress" >Configure BGP<img class="tooltip-icon ml-2" src="/assets/Infra/info.svg" alt="" matTooltip="Can be only configured with Route based VPN Gateway 
                            with supported SKU type of VpnGw1, VpnGw2, VpnGw3" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        </div>
                    </div>
                    <ng-container *ngIf="currentForm.get('configBGP').value">
                          <div class="comm-mb">
                              <label class="flex-custom no-wrap">Autonomous system number (ASN)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A BGP-enabled connection between two network gateways requires that their 
                                  ASNs be different. Additionally, the following ASNs are reserved by Azure: 8075, 
                                  8076, 12076 (public), 65515, 65517, 65518, 65519, 65520" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                              <input class="form-control full-width" type="text" name="asn" [clearable]="false" id="asn" formControlName="asn"   autocomplete="off" placeholder="" [class.border-danger]="inputError?.asnValue||inputError?.asnValidNum||inputError?.asnError" (keyup)="asnValidation($event);saveDisable=true" >
                              <p class="text-danger" *ngIf="inputError?.asnValue">The value must not be empty.</p>
                              <p class="text-danger" *ngIf="inputError?.asnValidNum">The value must be a valid number.</p>
                              <p class="text-danger" *ngIf="inputError?.asnError"> ASN {{asnNumber}} is reserved</p>
                            </div>
                          <div class="mb-3" *ngIf="!currentForm.get('activeMode')?.value">
                            <label class="flex-custom no-wrap">BGP peer IP address</label>
                            <label class="bold-600">{{vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses[0]?.defaultBgpIpAddresses[0]}}</label>
                        </div>
                        <div class="mb-3" *ngIf=" currentForm.get('activeMode')?.value">
                          <label class="flex-custom no-wrap">Public IP Address</label>
                          <label class="bold-600">{{vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses[0]?.tunnelIpAddresses[0]}}</label>
                      </div>
                      <div class="mb-3" *ngIf=" currentForm.get('activeMode')?.value">
                        <label class="flex-custom no-wrap">Default Azure BGP peer IP address</label>
                        <label class="bold-600">{{vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses[0]?.defaultBgpIpAddresses[0]}}</label>
                      </div>
                          <div class="comm-mb" >
                              <label class="flex-custom no-wrap">Custom Azure APIPA BGP IP address<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This field is reserved for APIPA (Automatic Private IP Addressing). 
                                Azure supports BGP IP in the ranges 169.254.21.* and 169.254.22.*" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                              <ng-container formArrayName="customAzureIPArray" *ngFor="let tanch of currentForm.get('customAzureIPArray')['controls']; let i = index;">
                                <div class="mb-1">
                                  <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                                      <input class="form-control full-width" type="text" formControlName="value" (keyup)="azureBGPValidation($event,'def')"/>
                                  
                                    <ng-container *ngIf="customAzureIPList?.length > 1">
                                      <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeCustomAzureIP(i)">
                                    </ng-container>
                                  </div>
                                  <div class="action-btn" style="margin-top: -4px;" (click)="addCustomAzureIP()" *ngIf="i == customAzureIPList?.length-1">Add another address</div>
                                </div>
                              </ng-container>
                              <p class="text-danger" *ngIf="inputError?.defCustomAzureIP">Azure supports APIPA BGP IP in the ranges 169.254.21.* and 169.254.22.* <br>Invalid IP address.</p>
                            </div>
                          <div class="mb-3" *ngIf=" currentForm.get('activeMode')?.value">
                            <label class="flex-custom no-wrap">Second Public IP Address</label>
                            <label class="bold-600">{{vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses.length>1?vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses[1]?.tunnelIpAddresses[0]:'--'}}</label>
                        </div>
                        <div class="mb-3" *ngIf=" currentForm.get('activeMode')?.value">
                          <label class="flex-custom no-wrap">Second Default Azure BGP peer IP address</label>
                          <label class="bold-600">{{vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses.length>1?vpnGatewayData?.properties?.bgpSettings?.bgpPeeringAddresses[1]?.defaultBgpIpAddresses[0]:'--'}}</label>
                      </div>
                        <div class="comm-mb" *ngIf=" currentForm.get('activeMode')?.value">
                            <label class="flex-custom no-wrap">Second Custom Azure APIPA BGP IP address<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="This field is reserved for APIPA (Automatic Private IP Addressing). 
                              Azure supports BGP IP in the ranges 169.254.21.* and 169.254.22.*" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <ng-container formArrayName="secCustomAzureIPArray" *ngFor="let tanch of currentForm.get('secCustomAzureIPArray')['controls']; let i = index;">
                              <div class="mb-1">
                                <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                                    <input class="form-control full-width" type="text" formControlName="value" (keyup)="azureBGPValidation($event,'sec')"/>
                                
                                  <ng-container *ngIf="secCustomAzureIPList?.length > 1">
                                    <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2" (click)="removeSecCustomAzureIP(i)">
                                  </ng-container>
                                </div>
                                <div class="action-btn" style="margin-top: -4px;" (click)="addSecCustomAzureIP()" *ngIf="i == secCustomAzureIPList?.length-1">Add another address</div>
                              </div>
                            </ng-container>
                            <p class="text-danger" *ngIf="inputError?.secCustomAzureIP">Azure supports APIPA BGP IP in the ranges 169.254.21.* and 169.254.22.* <br>Invalid IP address.</p>
                        </div>    
                  </ng-container>
                 </form>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid ||invalidForm('vpnConfiguration') ||!saveDisable" (click)="saveVPNGatewayData('vpnConfiguration')">Save</button>
            </div>
        </div>
       <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
          <div class="header">
              <div class="d-flex">
                <h4>Create public IP address<br> <span class="blur-text">Virtual Network Gateway | {{selectedRow[0]}}</span></h4>
                 <div class="text-right full-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
  
          <div class="body scroll-y">
              <form [formGroup]="subSideSheetForm">
                  <div class="comm-mb">
                      <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                      <input class="form-control full-width" type="text" autocomplete="off" id="publicIPName" name="publicIPName" formControlName="publicIPName" (keyup)="validIPName($event,80,'publicIP')"/>
                      <p class="text-danger" *ngIf="inputError?.nameEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.nameMaxLen">The name must be between 1 and 80 characters.</p>
                      <p class="text-danger" *ngIf="inputError?.nameExist">{{connectionName}} already exists in the resource group</p>
                      <p class="text-danger" *ngIf="inputError?.nameValid ||inputError?.nameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                          and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div>
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap" >SKU<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A Public IP's SKU must match the SKU of 
                        the Load Balancer with which it is used." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <div class="flex-custom ">
                          <div class="checkbox-cs" id="disable">
                              <input type="radio" name="IPsku"  class="form-check-inline" id='basic' value="basic" (change)="setRadioBtn('basic','sku')" checked>
                              <label class="value" for="basic">Basic</label>
                          </div>
                          <div class="checkbox-cs">
                              <input type="radio" name="IPsku" class="form-check-inline" id='standard' value="standard" (change)="setRadioBtn('standard','sku')">
                              <label class="value" for="standard">Standard</label>
                          </div>
                      </div>
                  </div> 
                  <div class="comm-mb" *ngIf="subSideSheetForm.get('IPsku').value=='Standard'">
                    <label class="flex-custom no-wrap" >Routing preference<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Determines how your traffic routes between Azure 
                      and the Internet. Selecting Microsoft global network 
                      delivers traffic via Microsoft global network closest 
                      to user. Internet route option uses transit ISP network. 
                      Egress data transfer price varies based on the routing 
                      selection. Routing preference option of a Public IP can’t 
                      be changed once created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="flex-custom ">
                        <div class="checkbox-cs">
                            <input type="radio" name="routing"  class="form-check-inline" id='internet' value="internet" (change)="setRadioBtn('internet','routing')" checked>
                            <label class="value" for="internet">Internet</label>
                        </div>
                        <div class="checkbox-cs">
                            <input type="radio" name="routing" class="form-check-inline" id='microsoftNetwork' value="microsoftNetwork" (change)="setRadioBtn('microsoftNetwork','routing')" >
                            <label class="value" for="microsoftNetwork">Microsoft network</label>
                        </div>
                    </div>
                </div> 
              </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" [class.disabled]="subSideSheetForm.invalid || invalidForm('createPublicIP')" (click)="createNewPublicIP()">Save</button>
          </div>
      </div> 
    </ng-container>
    <!-- VPN Connection -->
      <ng-container *ngIf="currentTemplate == 'connection'" >
        <div class="sidebar-right single width-652" [class.sub-sheet]="showSubSidesheet" id="sideSheets">
            <div class="header">
                <div class="d-flex"> 
                    <h4 class="half-width">Connections<br> <span class="blur-text">Virtual network gateway | {{selectedRow[0]}}</span></h4>
                  <div class="text-right half-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
              <button class="btn-btn-secondary" (click)="setDefaultValue('addConnection')">
                <img src="/assets/governance/add-btn.svg" alt="">
                Add connection 
            </button>
              <div class="custom-fixed-tables">
                    <table class="table">
                        <thead>
                            <tr>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Name</span>
                                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('name',connectionList)">
                                  </div>
                              </th>
                              <th>
                                  <div class="flex-custom no-wrap">
                                      <span>Status</span>
                                      <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('status',connectionList)">
                                  </div>
                              </th>
                              <th>
                                <div class="flex-custom no-wrap">
                                    <span>Connection type</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('connectionType',connectionList)">
                                </div>
                            </th>
                              <th style="border-right: none;">
                                <div class="flex-custom no-wrap">
                                    <span>Peer</span>
                                    <img class="sort" src="assets/icons/sort.png" (click)="sortByKey('VPN',connectionList)">
                                </div>
                            </th>
                            <th style="border-left: none;"></th>
                            </tr>
                          </thead>
                          <tbody >
                            <ng-container *ngFor="let list of connectionList">
                                <tr>
                                    <td>{{list?.name}}</td>
                                    <td>{{splitWordByCaps(list?.properties?.connectionStatus)}}</td>
                                    <td>{{list?.properties?.connectionType=='Vnet2Vnet'?'Vnet-to-Vnet':list?.properties?.connectionType=='IPsec'?'Site-to-Site (IPsec)':'Express Route'}}</td>
                                    <td>{{getPeerName(list?.properties)}}</td>
                                    <td>
                                        <span >
                                            <img  src="assets/icons/option_btn.png" class="cursor-pointer dot-delete" [matMenuTriggerFor]="actionInbound">
                                            <mat-menu #actionInbound="matMenu" class="delete-popup" id="matMenu">
                                              <div class="mat-menu-item" mat-menu-item (click)="openModal(deleteConfirmPopup,list,'deleteConnection')">
                                                <p class="delete-popup-text">Remove</p>
                                              </div>
                                            </mat-menu>
                                          </span>    
                                    </td>
                                </tr>
                              </ng-container>
                              <div class="col-md-12 mt-3" *ngIf="!connectionList?.length">
                                <p class="content-14 blur-text">No results</p>
                              </div>
                          </tbody>
                    </table>
                </div>
            </div>
            <div class="fixed-btm-btn flex-custom no-wrap">
              <button class="btn btn-btn-primary" (click)="closeSidesheet()">Done</button>
          </div>
          <div class="sidebar-right single add-alert" *ngIf="showSubSidesheet">
            <div class="header">
                <div class="d-flex">
                    <h4 >Add Connection <br> <span class="blur-text">Virtual network gateway | {{selectedRow[0]}}</span></h4>
                  <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="showSubSidesheet = false;resetValidation();resetForm();">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="body scroll-y">
                <form [formGroup]="currentForm">
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap">Name<span class="mandatory">*</span></label>
                        <input class="form-control full-width" type="text" name="source" [clearable]="false" id="source" formControlName="name" [class.border-danger]="inputError?.nameValid ||inputError?.nameLastValid || inputError?.nameExist ||inputError?.nameMaxLen ||inputError?.nameEmpty"  autocomplete="off" placeholder=""   (keyup)="validIPName($event,80,'connectionName')" >
                        <p class="text-danger" *ngIf="inputError?.nameEmpty">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.nameMaxLen">The name must be between 1 and 80 characters.</p>
                        <p class="text-danger" *ngIf="inputError?.nameExist">{{connectionName}} already exists in the resource group</p>
                        <p class="text-danger" *ngIf="inputError?.nameValid ||inputError?.nameLastValid">The name must begin with a letter or number, end with a letter, number or underscore, 
                            and may contain only letters, numbers, underscores, periods, or hyphens.</p>
                    </div> 
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Connection type<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Use a VNet-to-VNet connection to connect two Azure virtual networks. 
                        Use a site-to-site connection to connect your on-premises network with 
                        your Azure virtual network. Use an ExpressRoute connection to connect 
                        to an Azure virtual network to an existing circuit." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <ng-select [items]="connectionType"  bindLabel="name" bindValue="id" name="connectionType" [clearable]="false" id="VPN" formControlName="connectionType"></ng-select>
                    </div>   
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">{{currentForm.get('connectionType').value=='Vnet2Vnet'?'First virtual network gateway':'Virtual network gateway'}}<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Azure virtual network that will be used by this connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="vpn1List" class="disabled" bindLabel="name" bindValue="id" name="vpnName" [clearable]="false" id="VPN" formControlName="vpnName"></ng-select>
                    </div>  
                    <div class="comm-mb" *ngIf="currentForm.get('connectionType').value=='Vnet2Vnet'">
                      <label class="flex-custom no-wrap">Second virtual network gateway<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="The Azure virtual network that will be used by this connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="vpnList"  bindLabel="name" bindValue="id" name="vpnName" [clearable]="false" id="VPN" (change)="getVPNConfig($event);saveDisable=true" formControlName="vpnName2"></ng-select>
                    </div> 
                    <!-- groupBy="type" -->
                    <div class="comm-mb" *ngIf="currentForm.get('connectionType').value=='IPsec'">
                      <label class="flex-custom no-wrap">Local network gateway<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A connection for this local network gateway is being added." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <ng-select [items]="lngList"  bindLabel="name" bindValue="id" name="localNetName" [clearable]="false" id="LNG" placeholder="" (change)="getVPNConfig($event);saveDisable=true" formControlName="localNetName"></ng-select>
                      <!-- <input class="form-control full-width" type="text" name="localNetName" [clearable]="false" id="LNG" formControlName="localNetName"   autocomplete="off"   > -->
                    </div>  
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Shared key (PSK)<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A mixture of letters and numbers, used to establish encryption for the connection.
                        The same shared key must be used in both the virtual network and local network
                        gateways. If your gateway device doesn't provide one, you can make one up here 
                        and provide it to your device." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width" type="text" name="sharedKey" [clearable]="false" id="sharedKey" formControlName="sharedKey"   autocomplete="off" placeholder=""  (keyup)="sharedKeyValidation($event)">
                      <p class="text-danger" *ngIf="inputError?.sharedKeyEmpty">The value must not be empty.</p>
                      <p class="text-danger" *ngIf="inputError?.sharedKeyMaxLen">A shared key must contain between 1 to 128 characters. </p>
                      <p class="text-danger" *ngIf="inputError?.sharedKeyASCII">Invalid shared key configured. The shared key should be a combination of printable ASCII characters and should be between 1 to 128 in length. </p>
                    </div>      
                    <div class="comm-mb">
                      <div class="checkbox-cs"> 
                        <input type="checkbox" name="enable-auto" class="form-check-inline" id='ipAddress' formControlName="ipAddress" >
                        <label class="value" for="ipAddress" >Use Azure Private IP Address<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Checking this option allows you to use Azure private IPs to establish an IPsec VPN connection. 
                          Support for private IPs must be set on the VPN gateway for this option to work. It is only supported on AZ SKUs" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      </div>
                  </div>
                  <div class="comm-mb">
                    <div class="checkbox-cs"> 
                      <input type="checkbox" name="enable-auto" class="form-check-inline" id='BGP'[class.disabled-icon]="disable" formControlName="BGP" >
                      <label class="value" for="BGP" [class.disabled-icon]="disable">Enable BGP<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Border gateway protocol" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    </div>
                    <!-- disabled="vpnList?.properties?.sku?.name=='Basic'" -->
                </div>
                <div class="comm-mb">
                  <div class="checkbox-cs" *ngIf="currentForm.get('BGP').value"> 
                    <input type="checkbox" name="enable-auto" class="form-check-inline disabled-icon" id='customBGP' formControlName="customBGP" disabled>
                    <label class="value disabled-icon" for="customBGP" >Enable Custom BGP Addresses</label>
                  </div>
              </div>
                    <div class="comm-mb">
                        <label class="flex-custom no-wrap" >IKE Protocol<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="IKE protocol used by this connection." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="flex-custom ">
                            <div class="checkbox-cs" id="disable">
                                <input type="radio" name="IKEProtocol"  class="form-check-inline" id='1' value="1" (change)="setRadioBtn('1','protocol')" [disabled]="infoMessage" >
                                <label class="value" for="1">IKEv1</label>
                            </div>
                            <div class="checkbox-cs">
                                <input type="radio" name="IKEProtocol" class="form-check-inline" id='2' value="2" (change)="setRadioBtn('2','protocol')" [disabled]="infoMessage" checked>
                                <label class="value" for="2">IKEv2</label>
                            </div>
                        </div>
                    </div>    
                    <div class="comm-mb" *ngIf="vpnGatewayData?.properties?.natRules.length">
                      <label class="flex-custom no-wrap">Ingress NAT Rules</label>
                      <div class="margin-multi-select">
                        <ng-select class="full-width" [items]="ingressList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="ingressNAT" [clearable]="false" id="ingressNAT" formControlName="ingressNAT">
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="flex-custom">
                                  <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> 
                                  <label class="value" for="item-{{index}}" [ngModel]="item$.selected" [checked]="item$.selected">{{item.name}}</label>
                                </div>
                              </ng-template>
                        </ng-select>
                      </div>
                    </div> 
                    <div class="comm-mb" *ngIf="vpnGatewayData?.properties?.natRules.length">
                      <label class="flex-custom no-wrap">Egress NAT Rules</label>
                      <div class="margin-multi-select">
                        <ng-select class="full-width" [items]="egressList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="egressNAT" [clearable]="false" id="egressNAT" formControlName="egressNAT">
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div class="flex-custom">
                                  <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> 
                                  <label class="value" for="item-{{index}}" [ngModel]="item$.selected" [checked]="item$.selected">{{item.name}}</label>
                                </div>
                              </ng-template>
                        </ng-select>
                      </div>
                    </div> 
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Subscription<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When adding a connection for an existing peer, the subscription can't be changed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width disabled" type="text" name="sub" [clearable]="false" id="sub" formControlName="sub"   autocomplete="off" placeholder=""  >
                    </div>       
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Resource group<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When adding a connection for an existing peer, the resource group can't be changed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width disabled" type="text" name="rg" [clearable]="false" id="rg" formControlName="rg"   autocomplete="off" placeholder=""  >
                    </div>  
                    <div class="comm-mb">
                      <label class="flex-custom no-wrap">Location<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="When adding a connection for an existing peer, the location can't be changed." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                      <input class="form-control full-width disabled" type="text" name="location" [clearable]="false" id="location" formControlName="location"   autocomplete="off" placeholder=""  >
                    </div>  
                </form>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('addConnection')" (click)="saveVPNGatewayData('addConnection')">Save</button>
            </div>
        </div>
        </div>
      </ng-container>
    <!--VPN Point2Site-->
      <ng-container *ngIf="currentTemplate == 'point-to-site'">
        <div class="sidebar-right single width-652">
          <div class="header">
              <div class="d-flex"> 
                  <h4 class="half-width">Point-to-site<br> <span class="blur-text">Virtual network gateway | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetValidation()">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="comm-mb">
                  <label class="flex-custom no-wrap">Address pool<span class="mandatory">*</span></label>
                  <input class="form-control full-width" type="text" name="addressPool" [clearable]="false" id="addressPool" formControlName="addressPool" [class.border-danger]="inputError?.nameEmpty ||inputError?.subnetAdrspc || inputError?.subnetNotValidCIDR "  autocomplete="off" placeholder=""   (keyup)="addressSpace($event,'addressSpace');saveDisable=true" >
                  <p class="text-danger" *ngIf="inputError?.nameEmpty">The value must not be empty. IPv4: null. IPv6: IPv6 Address range cannot be empty.</p>
                  <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
                  <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid argument: 'The prefix must be between 1 and 32.'.</p>
              </div> 
              <div class="comm-mb" *ngIf="vpnGatewayData?.properties?.sku?.name!='Basic'">
                <label class="flex-custom no-wrap">Tunnel type</label>
                  <ng-select [items]="tunnelTypeList"  bindLabel="name" bindValue="id" name="tunnelType" [clearable]="false" id="tunnelType" formControlName="tunnelType"></ng-select>
              </div>  
              <div class="comm-mb" *ngIf="vpnGatewayData?.properties?.sku?.name!='Basic'">
                <label class="flex-custom no-wrap">Authentication type<span class="mandatory">*</span></label>
              <div class="margin-multi-select">
                <ng-select class="full-width" [items]="authenticationTypeList" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="name" bindValue="id" name="authenticationType" placeholder="0 Selected" (change)="getAuthenticationType()" [clearable]="false" id="authenticationType" formControlName="authenticationType">
                    <ng-template ng-multi-label-tmp let-items="items">
                        <span>{{items.length}} selected</span>
                      </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div >
                          <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [checked]="item$.selected"/> {{item.name}}
                        </div>
                      </ng-template>
                </ng-select>
              </div>
            </div> 
            <ng-container *ngIf="certificates || vpnGatewayData?.properties?.sku?.name=='Basic'">
                  <div class="mb-2">
                    <label class="flex-custom no-wrap bold-600 mb-2">Root Certificates<span class="mandatory">*</span></label>
                    <ng-container formArrayName="rootCertificatesArray" *ngFor="let tanch of currentForm.get('rootCertificatesArray')['controls']; let i = index;">
                      <div class="mb-1">
                        <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                          <div class="w-50">
                            <label class="flex-custom nowrap">Name</label>
                            <input class="form-control full-width" type="text" formControlName="name"/>
                          </div>
                          <div class="w-50 ml-3">
                            <label class="flex-custom nowrap">Public certificate data</label>
                            <input class="form-control full-width" type="text" formControlName="value"/>
                          </div>
                          <ng-container *ngIf="rootCertificatesList?.length > 1">
                            <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeRootCertificates(i)">
                          </ng-container>
                        </div>
                        <span class="action-btn" (click)="addRootCertificates()" *ngIf="i == rootCertificatesList?.length-1">Add certificate</span>
                      </div>
                    </ng-container>
                </div>
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600 mb-2">Revoked Certificates<span class="mandatory">*</span></label>
                  <ng-container formArrayName="revokedCertificatesArray" *ngFor="let tanch of currentForm.get('revokedCertificatesArray')['controls']; let i = index;">
                    <div class="mb-1">
                      <div class="notify-wrap mb-2" id="tags-list-{{i}}" [formGroupName]="i">
                        <div class="w-50">
                          <label class="flex-custom nowrap">Name</label>
                          <input class="form-control full-width" type="text" formControlName="name"/>
                        </div>
                        <div class="w-50 ml-3">
                          <label class="flex-custom nowrap">Thumbprint</label>
                          <input class="form-control full-width" type="text" formControlName="value"/>
                          <p class="text-danger mb-0" *ngIf="tanch.get('value').value && tanch.get('value').value.length!=40">Invalid certificate thumbprint format.</p>
                        </div>
                        <ng-container *ngIf="revokedCertificatesList?.length > 1">
                          <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-2 mt-3" (click)="removeRevokedCertificates(i)">
                        </ng-container>
                      </div>
                      <span class="action-btn" (click)="addRevokedCertificates()" *ngIf="i == revokedCertificatesList?.length-1">Add certificate to revoke</span>
                    </div>
                  </ng-container>
                </div>
          </ng-container>
          <ng-container *ngIf="radiusAuth">
                      <div class="mb-2">
                        <label class="flex-custom no-wrap bold-600">RADIUS Authentication<span class="mandatory">*</span></label>
                    </div> 
                      <div class="mb-3">
                        <label class="flex-custom no-wrap">Primary Server IP address<span class="mandatory">*</span></label>
                        <input class="form-control full-width" type="text" name="primaryServerIP" [clearable]="false" id="primaryServerIP" formControlName="primaryServerIP"  autocomplete="off" placeholder="" (keyup)="CheckDnsServer($event,i,'primary')"  >
                        <p class="text-danger" *ngIf="!currentForm.get('primaryServerIP').value">The value must not be empty.</p>
                        <p class="text-danger" *ngIf="inputError?.primaryIPError">Invalid IP address</p>
                        <p class="text-danger" *ngIf="inputError?.sameIPError">IP address for primary and secondary radius server canot be the same.</p>
                      </div> 
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Primary Server secret<span class="mandatory">*</span></label>
                      <input class="form-control full-width" type="text" name="primaryServerSecret" [clearable]="false" id="primaryServerSecret" formControlName="primaryServerSecret"  autocomplete="off" placeholder="" >
                      <p class="text-danger" *ngIf="!currentForm.get('primaryServerSecret').value">The value must not be empty.</p>
                    </div> 
                    <div class="mb-3">
                      <label class="flex-custom no-wrap">Secondary Server IP address (optional)</label>
                      <input class="form-control full-width" type="text" name="secServerIP" [clearable]="false" id="secServerIP" formControlName="secServerIP" [class.border-danger]="inputError?.nameValid"  autocomplete="off" placeholder=""   (keyup)="CheckDnsServer($event,i,'sec')" >
                      <p class="text-danger" *ngIf="inputError?.secIPError">Invalid IP address</p>
                      <p class="text-danger" *ngIf="inputError?.sameIPError">IP address for primary and secondary radius server canot be the same.</p>
                    </div> 
                  <div class="mb-3">
                      <label class="flex-custom no-wrap">Secondary Server secret (optional)</label>
                      <input class="form-control full-width" type="text" name="secServerSecret" [clearable]="false" id="secServerSecret" formControlName="secServerSecret" [class.border-danger]="inputError?.secIPValue"  autocomplete="off" placeholder="">
                      <p class="text-danger" *ngIf="currentForm.get('secServerIP').value && !currentForm.get('secServerSecret').value">Secondary radius server secret cannot be empty when 
                        the secondary radius server IP address is specified.</p>
                    </div> 
        </ng-container>
        <ng-container *ngIf="azureDictionary">
                <div class="mb-2">
                  <label class="flex-custom no-wrap bold-600">Azure Active Directory<span class="mandatory">*</span></label>
              </div> 
                <div class="mb-3">
                  <label class="flex-custom no-wrap">Tenant<span class="mandatory">*</span></label>
                  <input class="form-control full-width" type="text" name="tenant" [clearable]="false" id="tenant" formControlName="tenant" [class.border-danger]="inputError?.nameValid"  autocomplete="off" placeholder=""   (keyup)="validTenant($event)" >
                  <p class="text-danger" *ngIf="inputError?.validTenant">Please provide a valid tenant example: https://login.microsoftonline.com/3017a964-5cad-4f7b-9ba7-e2b05897e650/</p>
                </div> 
              <div class="mb-3">
                <label class="flex-custom no-wrap">Audience<span class="mandatory">*</span></label>
                <input class="form-control full-width" type="text" name="audience" [clearable]="false" id="audience" formControlName="audience"  autocomplete="off" placeholder="">
                <p class="text-danger" *ngIf="!currentForm.get('audience').value">The value must not be empty.</p>
              </div> 
              <div class="mb-3">
                <label class="flex-custom no-wrap">Issuer</label>
                <input class="form-control full-width" type="text" name="issuer" [clearable]="false" id="issuer" formControlName="issuer"  autocomplete="off" placeholder="">
                <p class="text-danger" *ngIf="!currentForm.get('issuer').value">The value must not be empty.</p>
              </div>
        </ng-container>
      </form>
      </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="deleteP2S btn-btn-secondary" *ngIf="(vpnGatewayData?.properties?.vpnClientConfiguration && showDeleteButton)||showDeleteBtn" (click)="openModal(deleteConfirmPopup,'','deletePoint2Site')"><img src="assets/app-monitoring/Delete.svg" class="mr-1" alt="">Delete configuration</button>
            <button class="btn btn-btn-primary" [class.disabled]="currentForm.invalid || invalidForm('point2Site')" (click)="saveVPNGatewayData('point2site')">Save</button>
          </div>
      
      </div>
      </ng-container>
    <!-- Change Size and Performance Disk -->
    <ng-container *ngIf="currentTemplate == 'natRule'">
      <div class="sidebar-right single width-881" >
          <div class="header">
              <div class="d-flex">
                <h4 class="threeFour-width ">NAT Rules<br> <span class="blur-text">Virtual Network Gateway  | {{selectedRow[0]}}</span></h4>
                <div class="text-right half-width">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="closeSidesheet();resetValidation();resetForm();saveDisable=false">
                    <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                  </button>
                </div>
              </div>
          </div>
          <div class="body scroll-y">
            <form [formGroup]="currentForm">
              <div class="mb-3">
                <label class="flex-custom no-wrap" >Enable Bgp Route Translation</label>
                <div class="flex-custom ">
                    <div class="checkbox-cs">
                        <input type="radio" name="routeTranslation"  class="form-check-inline" id='enable' value="enable" (change)="setRadioBtn('enable','routeTranslation')" [checked]="currentForm.get('routeTranslation')?.value == true">
                        <label class="value" for="enable">Enable</label>
                    </div>
                    <div class="checkbox-cs">
                        <input type="radio" name="routeTranslation" class="form-check-inline" id='disable' value="disable" (change)="setRadioBtn('disable','routeTranslation')" [checked]="currentForm.get('routeTranslation')?.value == false">
                        <label class="value" for="disable">Disbale</label>
                    </div>
                </div>
             </div> 
             <div class="comm-mb">
              <label class="flex-custom no-wrap">Add, edit and delete NAT rules below. NAT rules are only applied to the connections that you associate via the respective connection configurations.</label>
             </div>  
             <div class="mb-2">
              <ng-container >
                <div>
                  <div class="flex-custom no-wrap">
                    <div style="width: 45%;">
                        <label class="flex-custom no-wrap blur-text">Name</label>
                    </div>
                    <div style="width: 45%;">
                        <label class="flex-custom no-wrap blur-text">Type</label>
                    </div>
                    <div style="width: 45%;">
                      <label class="flex-custom no-wrap blur-text">IP Configuration ID</label>
                    </div>
                    <div style="width: 45%;">
                      <label class="flex-custom no-wrap blur-text">Mode</label>
                    </div>
                    <div style="width: 45%;">
                      <label class="flex-custom no-wrap blur-text">Internal Mappings</label>
                    </div>
                    <div style="width: 45%;">
                      <label class="flex-custom no-wrap blur-text">External Mappings</label>
                    </div>
                    <div style="width: 58%;">
                      <label class="flex-custom no-wrap blur-text">Connection Associations</label>
                    </div>
                    <div style="width: 10%;" *ngIf="vpnNATRuleList?.length > 1">
                    </div>
                </div>
                <ng-container formArrayName="vpnNATRuleArray" *ngFor="let tanch of currentForm.get('vpnNATRuleArray')['controls']; let i = index;">
                  <div class="flex-custom no-wrap">
                    <div class="notify-wrap mb-2" style="width:97%" id="tags-list-{{i}}" [formGroupName]="i">
                      <div class="w-50">
                        <input class="form-control full-width" type="text" formControlName="name" (keyup)="natRuleNameValid($event,i)"/>
                      </div>
                      <div class="w-50 ml-3">
                        <ng-select [items]="natTypeList"  bindLabel="name" bindValue="id" name="type" [clearable]="false" id="type" formControlName="type" (change)="tanch.get('IPConfig').reset()"></ng-select>
                      </div>
                      <div class="w-50 ml-3">
                        <ng-select [items]="natIPConfigList" [class.disabled]="tanch.get('type').value!='Dynamic'"  bindLabel="name" bindValue="id" name="IPConfig" [clearable]="false" id="IPConfig" formControlName="IPConfig"></ng-select>
                      </div>
                      <div class="w-50 ml-3">
                        <ng-select [items]="natModeList"  bindLabel="name" bindValue="id" name="mode" [clearable]="false" id="mode" formControlName="mode"></ng-select>
                      </div>
                      <div class="w-50 ml-3">
                        <input class="form-control full-width" type="text" formControlName="internalMapping" (keyup)="validNATAddressSpace($event,'in',i)" />
                      </div>
                      <div class="w-50 ml-3">
                        <input class="form-control full-width" type="text" formControlName="externalMapping" (keyup)="validNATAddressSpace($event,'ex',i)" />
                      </div>
                      <div class="ml-3" style="width: 60%;">
                         <label class="connection-item" (click)=" close_reset();openSidesheet('connection',selectedRow)">{{getConnectionCount(tanch.get('name').value)}} Connections Linked</label>
                      </div>
                      <ng-container *ngIf="vpnNATRuleList?.length > 1">
                        <img src="assets/app-monitoring/Delete.svg" alt="" class="cursor-pointer ml-3" (click)="removeVPNNATRule(i,tanch)">
                      </ng-container>
                    </div>
                  </div>
                  <p class="text-danger" *ngIf="(tanch.get('externalMapping').value||tanch.get('internalMapping').value)&&!tanch.get('name').value">The NAT Rule Name should not be empty.</p>
                  <p class="text-danger" *ngIf="i == currentIndex && inputError?.uniqueNATname">Each NAT Rule must have a unique name.</p>
                  <p class="text-danger" *ngIf="tanch.get('name').value&&!tanch.get('type').value">The NAT Type should not be empty.</p>
                  <p class="text-danger" *ngIf="tanch.get('name').value&&!tanch.get('mode').value">The NAT Mode should not be empty.</p>
                  <p class="text-danger" *ngIf="tanch.get('name').value&&!tanch.get('internalMapping').value">The NAT Internal Mappings should not be empty.</p>
                  <p class="text-danger" *ngIf="tanch.get('name').value&&!tanch.get('externalMapping').value">The NAT External Mapping should not be empty.</p>
                  <p class="text-danger" *ngIf="i == currentIndex &&(inputError?.exAdrspc || inputError?.exNotValidCIDR || inputError?.inAdrspc || inputError?.inNotValidCIDR)">Invalid argument: Malformed IP address.</p>
                  <div class="action-btn" style="margin-top: -4px;" (click)="addVPNNATRule()" *ngIf="i == vpnNATRuleList?.length-1">Add new</div>
                </ng-container>
                </div>
              </ng-container>
          </div>
           </form>
          </div>
          <div class="fixed-btm-btn flex-custom no-wrap">
            <div class="mt-1 blueBox" style="width: 60%;">
              <label class="" style="padding: 8.33px 9.33px !important;"><img  src="/assets/Infra/blue-info.svg" alt=""></label>
              <label class="" style="padding: 8px 0px !important;">
                Updating a Virtual Network Gateway with NAT rule(s) can take 10 minutes or more.</label>
            </div>  
              <button class="btn btn-btn-primary" [class.disabled]="!saveDisable || invalidForm('natRule')"(click)="saveVPNGatewayData('natRule')">Save</button>
          </div>
        
      </div>
    </ng-container>
</ng-container>

  <ng-template #deleteConfirm>
    <div class="medium-popup-wrapper">
      <div class="modal-body">
        <div class="d-flex mb-3">
          <div class="popup-title">{{getCurrentResourceTitle()}}</div>
          <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dlt-content">
          <p>Are you sure want to {{getResourceName()}}?</p>
        </div>
        <div class="submit-sec text-right">
          <button id="primary" class="btn btn-outline" (click)="closeModal()">No</button>
          <button id="secondary" class="btn btn-red" (click)="deleteAction()">{{getButtonName()}}</button>
        </div>
      </div>
    </div>
</ng-template>

  <ng-template #vpnGatewayDetailsTemplate>
    <div class="medium-popup-wrapper width-832">
        <div class="modal-body scroll-y">
        <div class="d-flex mb-3">
            <div class="popup-title">Virtual Network Gateway</div>
            <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="tab-list">
            <div class="tab-item" (click)="openSidesheet('configuration',selectedSideSheetRow)">Configuration</div>
            <div class="tab-item" (click)="openSidesheet('connection',selectedSideSheetRow)">Connections</div>
            <div class="tab-item" *ngIf="disableCondition && !point2siteCondition"  (click)="openSidesheet('point-to-site',selectedSideSheetRow)">Point-to-Site Configuration</div>
            <div class="tab-item" *ngIf="disableCondition && !natRuleCondition"  (click)="openSidesheet('natRule',selectedSideSheetRow)">NAT Rules (Preview)</div>
            <div class="tab-item" (click)="openSidesheet('deleteVPNGateway',selectedSideSheetRow)">Delete</div>
            <!-- [class.disabled-icon]="!disableCondition || point2siteCondition"
            [class.disabled-icon]="!disableCondition || natRuleCondition" -->
        </div>
        <div class="row row1" >
            <div class="col-md-6" *ngFor="let data of selectedSideSheetRow;let i= index;let col of columns">
                <div class="list-item">
                    <div class="label-text">{{col.name=='subscriptionId'?'Subscription': splitWordByCapsExceptAbb(col.name)| firstLetterCaps}}</div>
                    <ng-container *ngIf="col.name!='location'&&col.name!='subscriptionId'">
                      <div class="value-text"><span class="colen">:</span>{{col.name != 'tags'?selectedSideSheetRow[i]:getTags(selectedSideSheetRow[i])}}</div>
                    </ng-container>
                    <div *ngIf="col.name=='subscriptionId'" class="value-text"><span class="colen">:</span>{{getProjectName(selectedSideSheetRow[i])}}</div>
                    <div *ngIf="col.name=='location'" class="value-text"><span class="colen">:</span>{{getRegionName(selectedSideSheetRow[i])}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="submit-sec text-right pad-10-24">
        <button id="primary" class="btn btn-primary done-width" (click)="closeModal()">Done</button>
        </div>
    </div>
  </ng-template>