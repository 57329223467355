<div class="overview-content">
    <div class="header-container">
        <h2 class="title">Overview</h2>
    </div>

     <!-- Filter Section -->
     <div class="d-flex" *ngIf="!singleProcessView">
        <div class="custom-filter-wrapper flex-custom" style=" width: 100%;">
            <div class="custom-filter">
                <span class="text">Subscription<div class="filter-notify" *ngIf="checkFilterApplied('subscription')"></div></span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <div class="enlist-srch">
                        <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                        <img src="assets/icons/search.png">
                    </div>
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-filter">
                <span class="text">Provider<div class="filter-notify" *ngIf="checkFilterApplied('provider')"></div></span>
            
                <div class="filter-dropdown" id="filter-dropdown" >
                    <!-- <div class="enlist-srch">
                        <input class="form-control" [(ngModel)]="searchKey" (keyup)="searchFilter($event.target.value,'subscriptionList')" placeholder="Search subscription"/>
                        <img src="assets/icons/search.png">
                    </div> -->
                    <div class="filter-content">
                        <div class="checkbox-cs" *ngIf="providerList?.length>1">
                            <input type="checkbox" class="form-check-inline" #allCheck  name="f_listItem_{{providerList.length}}" (change)="onChangeCheckAll(allCheck)" [checked]="checkedAll()">
                            <label class="value" for="f_listItem_{{providerList.length}}" id="f_listItemName_{{providerList.length}}">All</label>
                        </div>
                        <div class="checkbox-cs" *ngFor="let data of providerList; index as i">
                            <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter(data,'subscription',$event)" [checked]="data.checked">
                            <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.name}}</label>
                        </div>
                        <div class="mt-3" *ngIf="!providerList?.length">
                            <p  class="content-14 blur-text">No filter found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-select *ngIf="timeFilterView" [items]="timeFilterList" [searchable]="false" bindLabel="name" bindValue="id" [closeOnSelect]="true" (close)="currentFilter.type=='custom'?openModal(openCal,null):''" name="timeRange" placeholder="Time Range:" style="width: 200px;" (change)="currentFilter.type=='custom'?openModal(openCal,null):applyFilter(currentFilter.type,null)" [(ngModel)]="currentFilter.type" [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
                Time Range: {{item?.name}}
            </ng-template>
        </ng-select>
    </div>
    <div class="row custom-padding" *ngIf="!singleProcessView">
        <div class="col-md-6 padding-div middle">
            <div class="graph-div custom-progress-card">
                <div class="heading">
                    <div class="d-flex flex-align-custom">
                        <img src="/assets/monitor/SAPHANA/highest-cpu.svg" alt="">
                        <div class="about">
                            <h4>Highest CPU</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(highestCPU?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('highestCPU')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3" *ngIf="highestCPU?.tableData?.length && !highestCPU?.showLoader">
                    <ng-container *ngFor="let item of highestCPU?.tableData">
                        <div class="detail-top" [ngClass]="{'col-sm-6':highestCPU?.tableData.length>1,'col-sm-12':highestCPU?.tableData.length==1}">
                            <div class="event-card" [ngClass]="{'red':item.value>80,'yellow':item.value>60,'green':item.value<60||item.value==60}">
                                <circle-progress
                                    [percent]="item.value"
                                    [maxPercent]="100"
                                    [space]="-4"
                                    [radius]="18"
                                    [outerStrokeWidth]="4"
                                    [innerStrokeWidth]="4"
                                    [outerStrokeColor]="item.value>80?'#DF404F':item.value>60?'#EE9244':'#06C493'"
                                    [innerStrokeColor]="item.value>80?'#F9DCDF':item.value>60?'#FADEC9':'#D3F8ED'"
                                    [imageSrc]="item.value>80?'assets/monitor/SAPHANA/arrow_angle_up.svg':'assets/monitor/SAPHANA/arrow_angle_down.svg'"
                                    [imageHeight]="30" 
                                    [imageWidth]="30"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [showTitle]="false"
                                    [showSubtitle]="false"
                                    [showUnits]="false"
                                    [showImage]="true"
                                    [showBackground]="false"
                                    [showInnerStroke]="true"
                                ></circle-progress>
                                <div class="details-values">
                                    <span class="card-name">{{item.name}}</span>
                                    <span class="card-count">{{item.value}}<span>%</span></span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="loader-gif custom-loader-gif" *ngIf="highestCPU?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!highestCPU?.tableData?.length && !highestCPU?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div custom-progress-card">
                <div class="heading">
                    <div class="d-flex flex-align-custom">
                        <img src="/assets/monitor/SAPHANA/highest-memory.svg" alt="">
                        <div class="about">
                            <h4>Highest Memory</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(highestMemory?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('highestMemory')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3" *ngIf="highestMemory?.tableData?.length && !highestMemory?.showLoader">
                    <ng-container *ngFor="let item of highestMemory?.tableData">
                        <div class="detail-top" [ngClass]="{'col-sm-6':highestMemory?.tableData.length>1,'col-sm-12':highestMemory?.tableData.length==1}">
                            <div class="event-card" [ngClass]="{'red':item.value>80,'yellow':item.value>60,'green':item.value<60||item.value==60}">
                                <circle-progress
                                    [percent]="item.value"
                                    [maxPercent]="100"
                                    [space]="-4"
                                    [radius]="18"
                                    [outerStrokeWidth]="4"
                                    [innerStrokeWidth]="4"
                                    [outerStrokeColor]="item.value>80?'#DF404F':item.value>60?'#EE9244':'#06C493'"
                                    [innerStrokeColor]="item.value>80?'#F9DCDF':item.value>60?'#FADEC9':'#D3F8ED'"
                                    [imageSrc]="item.value>80?'assets/monitor/SAPHANA/arrow_angle_up.svg':'assets/monitor/SAPHANA/arrow_angle_down.svg'"
                                    [imageHeight]="30" 
                                    [imageWidth]="30"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [showTitle]="false"
                                    [showSubtitle]="false"
                                    [showUnits]="false"
                                    [showImage]="true"
                                    [showBackground]="false"
                                    [showInnerStroke]="true"
                                ></circle-progress>
                                <div class="details-values">
                                    <span class="card-name">{{item.name}}</span>
                                    <span class="card-count">{{item.value}}<span>%</span></span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="loader-gif custom-loader-gif" *ngIf="highestMemory?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!highestMemory?.tableData?.length && !highestMemory?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-12 right">
            <div class="graph-div card-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>HANA Instance Overview</h4>
                            <p>Refreshed: {{refreshedTimeFormat(hanaInstanceOverview?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('hanaInstanceOverview')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                      </div> 
                    </div>
                </div>
                <div class="custom-fixed-tables m-0 mt-3" *ngIf="hanaInstanceOverview?.tableData?.length && !hanaInstanceOverview?.showLoader">
                  <table class="table" id="hanaInstanceOverview">
                      <thead>
                        <tr>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Name</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Kind</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',1)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Database Hosted</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Role</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Status </span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>CPU</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Memory</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Disk (Data)</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Disk (Log)</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',4)">
                              </div>
                          </th>
                          <th>
                              <div class="flex-custom no-wrap">
                                  <span>Network (In)</span>
                                  <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',4)">
                              </div>
                          </th>
                          <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Network (Out) </span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('hanaInstanceOverview',0)">
                            </div>
                        </th>
                        </tr>
                      </thead>
                      <tbody >
                        <ng-container *ngFor="let row of hanaInstanceOverview.tableData">
                            <tr>
                                <td class="elipse-column" [title]="row.name">
                                    <span class="cursor-pointer" (click)="row.showDetail=!row.showDetail;"> 
                                    <img  [src]="row.showDetail?'assets/compliance/up-arrow.svg':'assets/compliance/down-arrow.svg'" alt="" style="padding-right: 2px;">
                                    {{row.name}}</span>
                                </td>
                                <td class="elipse-column-small" [title]="row.kind">{{row.kind}}</td>
                                <td class="elipse-column" [title]="row.databaseHosted">{{row.databaseHosted}}</td>
                                <td>{{row.role}}</td>
                                <td>
                                    <img *ngIf="row.status==='yes'" class="sort" src="assets/monitor/SAPHANA/success.svg">
                                    <img *ngIf="row.status!=='yes'" class="sort" src="assets/monitor/SAPHANA/error.svg">
                                </td>
                                <td>
                                    <div class="progress-div">
                                        <span style="min-width:30px;max-width: 40px;">{{row.CPU?row.CPU+'%':''}}</span>
                                        <span class="progress w-92 mb-2" style="margin-top: 4px;">
                                            <span class="progress-bar progress-bar-cpu" [style.width]="row.CPU+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div class="progress-div">
                                        <span style="min-width:30px;max-width: 40px;">{{row.memory?row.memory+'%':''}}</span>
                                        <span class="progress w-92 mb-2" style="margin-top: 4px;">
                                            <span class="progress-bar progress-bar-memory" [style.width]="row.memory+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div class="progress-div">
                                        <span style="min-width:30px;max-width: 40px;">{{row.diskData?row.diskData+'%':''}}</span>
                                        <span class="progress w-92 mb-2" style="margin-top: 4px;">
                                            <span class="progress-bar progress-bar-data" [style.width]="row.diskData+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div class="progress-div">
                                        <span style="min-width:30px;max-width: 40px;">{{row.diskLog?row.diskLog+'%':''}}</span>
                                        <span class="progress w-92 mb-2" style="margin-top: 4px;">
                                            <span class="progress-bar progress-bar-log" [style.width]="row.diskLog+'%'"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>{{row.networkIn?row.networkIn+'kB/s':''}}</td>
                                <td>{{row.networkOut?row.networkOut+'kB/s':''}}</td>
                            </tr>
                            <ng-container *ngIf="row.childData&&row.childData.length&&row.showDetail">
                                <tr *ngFor="let list of row.childData">
                                    <td class="elipse-column backup-item" [title]="list[0]">{{list[0]}}</td>
                                    <td class="elipse-column-small" [title]="list[2]">{{list[2]}}</td>
                                    <td class="elipse-column" [title]="list[3]">{{list[3]}}</td>
                                    <td class="elipse-column" [title]="list[4]">{{list[4]}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                      </tbody>
                  </table>
                </div>
                <div class="loader-gif" *ngIf="hanaInstanceOverview?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="(!hanaInstanceOverview?.tableData?.length && !hanaInstanceOverview?.showLoader)">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 middle padding-div">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>CPU Usage (%)</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(cpuUsage?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('cpuUsage')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="CPU Usage view" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="showSingleProcess(cpuUsage?.yAxis[0],'cpuUsageTime')"><img src="/assets/security/drilldown_icon.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="cpuUsage.options" (chartClick)="onChartClick($event,'cpuUsageTime')" *ngIf="cpuUsage.data && cpuUsage.data.length && !cpuUsage?.showLoader"></div>
            <div class="loader-gif" *ngIf="cpuUsage?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!cpuUsage?.data.length && !cpuUsage?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Memory Usage (%)</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(memoryUsage?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('memoryUsage')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Memory Usage view" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="showSingleProcess(memoryUsage?.yAxis[0],'memoryUsageTime')"><img src="/assets/security/drilldown_icon.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="memoryUsage.options" (chartClick)="onChartClick($event,'memoryUsageTime')" *ngIf="memoryUsage.data && memoryUsage.data.length  && !memoryUsage?.showLoader"></div>
            <div class="loader-gif" *ngIf="memoryUsage?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!memoryUsage?.data.length && !memoryUsage?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
            </div>
        </div>
        <div class="col-md-6 middle padding-div">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Disk Data (%)</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(diskData?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('diskData')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Disk Data view" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="showSingleProcess(diskData?.yAxis[0],'diskDataTime')"><img src="/assets/security/drilldown_icon.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="diskData.options" (chartClick)="onChartClick($event,'diskDataTime')" *ngIf="diskData.data && diskData.data.length  && !diskData?.showLoader"></div>
            <div class="loader-gif" *ngIf="diskData?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!diskData?.data.length && !diskData?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Disk Log (%)</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(diskLog?.refreshTime)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('diskLog')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Disk Log view" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="showSingleProcess(diskLog?.yAxis[0],'diskLogTime')"><img src="/assets/security/drilldown_icon.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="diskLog.options" (chartClick)="onChartClick($event,'diskLogTime')" *ngIf="diskLog.data && diskLog.data.length  && !diskLog?.showLoader"></div>
            <div class="loader-gif" *ngIf="diskLog?.showLoader">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Getting data...</p>
            </div>
            <div class="dash-text-center" *ngIf="!diskLog?.data.length && !diskLog?.showLoader">
                <img src="/assets/health-monitoring/no-data.svg" alt="">
                <p class="text-14-22 bold-600 mt-3">No data found</p>
            </div>
            </div>
        </div>
        <div class="col-md-6 middle padding-div">
            <div class="graph-div custom-card-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Network In (KB/s)</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(networkIn?.refreshTime)}} . {{getTimeFilterVal(currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('networkIn')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Network In view" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="showSingleProcess(networkIn?.legendData[0]?.name,'networkIn')"><img src="/assets/security/drilldown_icon.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div echarts [options]="networkIn?.options" [loading]="true" (chartClick)="onChartClick($event,networkIn.type)" *ngIf="networkIn?.series?.length && !networkIn?.showLoader"></div>
                <div class="loader-gif" *ngIf="networkIn?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!networkIn?.series?.length && !networkIn?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div custom-card-height">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Network Out (KB/s)</h4>
                            <p>Refreshed:{{ refreshedTimeFormat(networkOut?.refreshTime)}} . {{getTimeFilterVal(currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('networkOut')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Network Out view" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="showSingleProcess(networkOut?.legendData[0]?.name,'networkOut')"><img src="/assets/security/drilldown_icon.svg" alt=""></span>
                        </div>
                    </div>
                </div>
                <div echarts [options]="networkOut?.options" [loading]="true" (chartClick)="onChartClick($event,networkOut.type)" *ngIf="networkOut?.series?.length && !networkOut?.showLoader"></div>
                <div class="loader-gif" *ngIf="networkOut?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!networkOut?.series?.length && !networkOut?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-16-26 mt-3">No data found</p>
                </div>
            </div>
        </div>
    
    </div>
    <div class="row" *ngIf="singleProcessView">
        <div class="col-md-12 d-flex mt-2" [formGroup]="processDetails" style="padding-right: 0px;">
            <div class="d-flex" style="align-items: start;width: 100%;">
                <img src="/assets/security/arrow_left.svg" alt="" style="margin: 0px 4px;cursor: pointer;" (click)="singleProcessView=false;">
                <label style="font-weight: 400;font-size: 12px;line-height: 18px;cursor: pointer;" (click)="singleProcessView=false;">Back to dashboard</label>
            </div>
            <ng-select [items]="processList" bindLabel="name" bindValue="name" [closeOnSelect]="true" name="process" placeholder="CPU:" (change)="processChange()" style="margin-right: 12px;" [clearable]="false" formControlName="process">
                <ng-template ng-label-tmp let-item="item">
                    {{singleProcess?.name}}: {{item?.name}}
                  </ng-template>
            </ng-select>
            <!-- <ng-select [items]="timeFilterList" bindLabel="name" bindValue="id" [closeOnSelect]="true" name="timeRange" placeholder="Time Range:" (change)="processDetails.value.timeRange=='custom'?openModal(openCal,singleProcess):applyFilter(processDetails.value.timeRange,singleProcess)" [clearable]="false" formControlName="timeRange">
                <ng-template ng-label-tmp let-item="item">
                    Time Range: {{item?.name}}
                  </ng-template>
            </ng-select> -->
            <button class="action-icon ml-2 btn-time-range" matTooltip="Time range" matTooltipClass="nav-tooltips"
            matTooltipShowDelay="500" [popover]="timeOptions" triggers="" placement="bottom" containerClass="custom-popover time-options custom-position" #popTime="bs-popover" [adaptivePosition]="false" [outsideClick]="true" (click)="openPop(popTime)"
            >Time Range: {{timeRangeFilter(singleProcess)}}</button>
            <ng-template #timeOptions>
                <app-time-options id="customFilter1" [popId]="popTime" optionFor="singleProcess" [timeData]="singleProcess?.currentFilter"
                [showAll]="true" (applyFilterEvent)="applyFilter($event,singleProcess)" 
                (openModalForCustomEvent)="openModalForCustom($event,openCal,singleProcess)"></app-time-options>
            </ng-template>
        </div>
        <div class="col-md-12 padding-div right" style="min-height: calc(100vh - 300px);padding-top: 16px;">
            <div class="graph-div single-process-height mb-4">
              <div class="heading">
                  <div class="d-flex">
                      <div class="about">
                          <h4>{{singleProcess?.name}}</h4>
                          <p>Refreshed: {{refreshedTimeFormat(singleProcess?.refreshTime)}} . {{getTimeFilterText(singleProcess?.currentFilter)}} . {{getUTCText(singleProcess?.currentFilter)}}</p>
                      </div> 
                      <div class="float-right flex-custom no-wrap">
                          <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('singleProcess')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                          <span class="action-icon download-icon-single-process" matTooltip="Download CSV" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="downloadDirectTableView()"><img src="assets/security/download_icon.svg" alt=""></span>
                      </div>                  
                  </div>                
              </div>
              <ng-container *ngIf="singleProcess?.series?.length && !singleProcess?.showLoader">
                  <div class="chart-ht-single-process" echarts [options]="singleProcess?.options" [loading]="true"></div>
                  <div class="bot-strip">
                    <div class="str-name">{{singleProcess?.avgName}}</div>
                    <div class="str-value">{{singleProcess?.average}} <span>{{singleProcess?.mainType=='networkIn'||singleProcess?.mainType=='networkOut'?' KB/s':' %'}}</span></div>
                  </div>
              </ng-container>
              <div class="loader-gif" *ngIf="singleProcess?.showLoader">
                  <img src="assets/icons/blue-loader.gif"/>
                  <p>Getting data...</p>
              </div>
              <div class="dash-text-center" *ngIf="!singleProcess?.series?.length && !singleProcess?.showLoader">
                  <img src="/assets/health-monitoring/no-data.svg" alt="">
                  <p class="text-16-26 mt-3">No data found</p>
              </div>
            </div>
          </div>
      </div>
</div>

<ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>