import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-migration-overview',
  templateUrl: './data-migration-overview.component.html',
  styleUrls: ['./data-migration-overview.component.scss']
})
export class DataMigrationOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
