<div class="navbar">
    <a class="navbar-brand">              
        <img src="assets/common-icons/brand-icon.svg" width="30" height="30" class="d-inline-block" alt="">
        <span class="brand-name">AI Powered Azure Automation </span>
    </a>
</div>


<div class="onboarding-body">
    <div class="header-container">
        <h2 class="title">Complete your A3S Registration</h2>
     </div> 

     <div class="process-wrap">
        <div class="process-stepper-container">
            <ng-container *ngFor="let steps of onboardingStepper">
              <div class="stepper-header">
                <div class="stepper-icon" [ngClass]="getClass(steps)">
                  <div class="stepper-content">
                    <span *ngIf="!steps?.isCompleted && !steps?.isError">{{steps?.step}}</span>
                    <img src="assets/icons/tick.svg" *ngIf="steps?.isCompleted"> 
                    <img src="assets/icons/cross-fail.svg" *ngIf="steps?.isError">
                  </div>
                </div>
                <div class="stepper-label" [class.active]="step == steps?.limit && !steps?.isError" [class.bold-600]="steps?.isError || steps?.isCompleted">
                  {{steps?.value}}</div>
              </div>
              <div class="stepper-horizontal-line" [class.active]="step >= steps?.limit + 1"></div>
            </ng-container>
        </div>

        <div class="process-detail">

            <ng-container *ngIf="step == 0 && !showLoader">
                <h5 class="text-14-22 mb-0">Service Principal</h5>
                <span class="text-12-18">Service principal allows A3S to create, manage, & monitor resources in your Azure subscription.</span>
                <ng-container *ngIf="!isServicePrincipalCreated">
                   <div class="info">
                     <span class="text-12-18">Provide access token to authenticate user to create A3S service principal. </span>
                     <span class="blue-text text-12-18 flex-custom cursor-pointer" (click)="showAccessTokenSteps = true">How to generate access token?</span>
                    </div>
                    <form [formGroup]="currentForm">
                        <div class="form-group">
                            <label for="token" class="text-12-18">Access token<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Generate access token to create A3S service principal in your Azure subscription." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                            <textarea class="form-control half-width" id="token" formControlName="accessToken" (keyup)="isAccessTokenError=false" [ngClass]="{'border-danger' : isAccessTokenError}" [class.disabled]="isCreating"></textarea>
                            <p class="text-danger" *ngIf="isAccessTokenError">Access token is either invalid or expired.</p>
                        </div>
                     </form>
                </ng-container>
                 <div class="info" *ngIf="isServicePrincipalCreated">
                     <span class="text-12-18">You will not be able to proceed to next step without granting admin consent.</span>
                     <!-- <span class="blue-text text-12-18 cursor-pointer" (click)="showGrantPermissionSteps = true">&nbsp;How to grant admin consent?</span> -->
                     <button class="btn btn-outline flex-custom mt-3" (click)="grantPermission()" *ngIf="!isPermissiongranted">Go to azure and grant admin consent</button>
                     <div class="consent-success" *ngIf="isPermissiongranted">
                        <img src="../../../assets/icons/green-tick.svg">
                        <span>Admin consent granted successfully.</span>
                     </div>
                 </div>
            </ng-container>

            <ng-container *ngIf="step == 1 && !showLoader">
                <h5 class="text-14-22 mb-0">Give A3S Access to your Subscriptions and Billing Accounts</h5>
                <form class="mt-3" [formGroup]="currentForm">
                    <div class="form-group row">
                        <label for="subscription" class="control-label col-sm-2">Subscription<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select subscription(s) to provide A3S with the access." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="col-sm-6 margin-multi-select">
                            <ng-select [items]="subscriptionList" bindLabel="displayName" bindValue="subscriptionId" multiple="true" [selectableGroup]="true" [closeOnSelect]="false" [searchable]="true" name="subscription" [clearable]="false" id="subscription" formControlName="subscription">
                                <ng-template ng-multi-label-tmp let-items="items">
                                  <span>{{items?.length}} selected</span>
                                </ng-template>
                                <ng-template ng-header-tmp *ngIf="subscriptionList?.length">
                                  <div class="checkbox-cs">
                                    <input id="item-all" name="item-all" type="checkbox" class="form-check-inline" (change)="onSelectAll($event,'subscription')" [checked]="checkedAll('subscription')"/> Select All
                                  </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item"  let-index="index" let-item$="item$">
                                  <div class="checkbox-cs">
                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }" [checked]="item$.selected"/> {{item?.displayName}} ({{item?.subscriptionId}})
                                  </div>
                                </ng-template>
                              </ng-select>
                        </div>
                    </div>
                    <div class="checkbox-cs mb-3">
                      <input type="checkbox" name="costMonitoring" value="enableCost" class="form-check-inline" id='costMonitoring' formControlName="enableCostMonitoring">
                      <label class="value" for="costMonitoring">Enable Cost Monitoring</label>
                    </div>
                    <div class="info-box col-sm-5">
                        <img src="assets/onBoard/info.svg">
                        <span>Check the box to provide A3S with cost consumption based on billing account(s).</span>
                    </div>
                </form>
            </ng-container>


            <ng-container *ngIf="step == 2 && !showLoader">
                <h5 class="text-14-22 mb-0">Setup Log Analytics workspace </h5>
                <span class="text-12-18">Log analytics workspace allows A3S to monitor resources in your subscription</span>

                <form class="mt-3" [formGroup]="currentForm">
                    <div class="form-group row">
                        <label for="subscription" class="control-label col-sm-2">Subscription<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select the subscription for Log Analytics Workspace." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="col-sm-6">
                        <ng-select [items]="selectedSubscriptionList"  bindLabel="displayName" bindValue="subscriptionId"  name="subscription" (change)="getRgBySubscription();resetValue('subscription')"  [clearable]="false" formControlName="subscription" required>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <span>{{item?.displayName}} ({{item?.subscriptionId}})</span>
                          </ng-template>
                        </ng-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="subscription" class="control-label col-sm-2">Resource group<span class="mandatory">*</span></label>
                        <div class="col-sm-6">
                            <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name"  name="resourceGroup" (change)="getWorkspaceByRg();resetValue('resourceGroup')" [clearable]="false" formControlName="resourceGroup" required>
                              <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.isNew" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="removeNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.isNew" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                              </ng-template>
                            </ng-select>
                            <span class="blue-text text-12-18 cursor-pointer" #anchor1 (click)="onToggle('resourceGroup')">Create New</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="subscription" class="control-label col-sm-2">Log Analytics workspace<span class="mandatory">*</span></label>
                        <div class="col-sm-6">
                            <ng-select [items]="workspaceList" bindLabel="name" bindValue="name"  name="logAnalytics" [clearable]="false" formControlName="logAnalytics" required>
                              <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.isNew" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="removeNewLogAnlaytics(item,index)"></span>
                                <span *ngIf="item.isNew" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewLogAnlaytics(item,index)"></span>
                              </ng-template>
                            </ng-select>
                            <span class="blue-text text-12-18 cursor-pointer"  #anchor2 (click)="onToggle('logAnalytics')">Create New</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="subscription" class="control-label col-sm-2">Select Virtual Machines<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select all those virtual machines which needs to be monitored in Infrastructure Health Bucket." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="col-sm-6 margin-multi-select">
                            <ng-select [items]="vmList" bindLabel="VM_name" bindValue="VM_name" multiple="true" [selectableGroup]="true" [closeOnSelect]="false" name="subscription" [clearable]="false" id="subscription" formControlName="virtualMachine">
                                <ng-template ng-multi-label-tmp let-items="items">
                                  <span>{{items.length}} selected</span>
                                </ng-template>
                                <ng-template ng-header-tmp *ngIf="vmList?.length">
                                  <div class="checkbox-cs">
                                    <input id="item-all" name="item-all" type="checkbox" class="form-check-inline" (change)="onSelectAll($event,'virtualMachine')" [checked]="checkedAll('virtualMachine')"/> Select All
                                  </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="checkbox-cs">
                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }" [checked]="item$.selected"/> {{item.VM_name}}
                                  </div>
                                </ng-template>
                              </ng-select>
                        </div>
                    </div>
                </form>
            </ng-container>


            <ng-container *ngIf="step == 3 && !showLoader">
                <h5 class="text-14-22 mb-0">Setup Application Insights</h5>
                <span class="text-12-18">Application insights allows A3S to monitor application in your Azure subscription.</span>

                <form class="mt-3" [formGroup]="currentForm">
                    <div class="form-group row">
                        <label for="subscription" class="control-label col-sm-2">Subscription<span class="mandatory">*</span></label>
                        <div class="col-sm-6 margin-multi-select">
                            <ng-select [items]="selectedSubscriptionList" bindLabel="displayName" bindValue="subscriptionId" (change)="getAppInsights()" multiple="true" [selectableGroup]="true" [closeOnSelect]="false" name="subscription" [clearable]="false" id="subscription" formControlName="subscription">
                                <ng-template ng-multi-label-tmp let-items="items">
                                  <span>{{items.length}} selected</span>
                                </ng-template>
                                <ng-template ng-header-tmp *ngIf="selectedSubscriptionList?.length">
                                  <div class="checkbox-cs">
                                    <input id="item-all" name="item-all" type="checkbox" class="form-check-inline" (change)="onSelectAll($event,'subscriptionSelected')" [checked]="checkedAll('subscriptionSelected')"/> Select All
                                  </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item"  let-index="index" let-item$="item$">
                                  <div class="checkbox-cs">
                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }" [checked]="item$.selected"/> {{item?.displayName}} ({{item?.subscriptionId}})
                                  </div>
                                </ng-template>
                              </ng-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="subscription" class="control-label col-sm-2">Application Insights</label>
                        <div class="col-sm-6 margin-multi-select">
                            <ng-select [items]="appInsightsList" bindLabel="name" bindValue="id" multiple="true" [selectableGroup]="true" [closeOnSelect]="false" name="subscription" [clearable]="false" id="subscription" formControlName="appInsights">
                                <ng-template ng-multi-label-tmp let-items="items">
                                  <span>{{items.length}} selected</span>
                                </ng-template>
                                <ng-template ng-header-tmp *ngIf="appInsightsList?.length">
                                  <div class="checkbox-cs">
                                    <input id="item-all" name="item-all" type="checkbox" class="form-check-inline" (change)="onSelectAll($event,'applicationInsights')" [checked]="checkedAll('applicationInsights')"/> Select All
                                  </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div class="checkbox-cs">
                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }" [checked]="item$.selected"/> {{item.name}}
                                  </div>
                                </ng-template>
                              </ng-select>
                        </div>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="step == 4 && !showLoader">
                <h5 class="text-14-22 mb-0">Security</h5>
                <span class="text-12-18">Enabling Defender for Cloud and Azure Sentinel allows A3S to monitor security and compliance data in your Azure subscription(s).</span>

                <form class="mt-3" [formGroup]="currentForm">
                    <div class="form-group row">
                        <label for="subscription" class="control-label col-sm-2">Subscription<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Select Azure subscriptions for which you need to enable Defender for Cloud." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                        <div class="col-sm-6 margin-multi-select">
                            <ng-select [items]="selectedSubscriptionList" bindLabel="displayName" bindValue="subscriptionId" multiple="true" [selectableGroup]="true" [closeOnSelect]="false" name="subscription" [clearable]="false" id="subscription" formControlName="subscription">
                                <ng-template ng-multi-label-tmp let-items="items">
                                  <span>{{items.length}} selected</span>
                                </ng-template>
                                <ng-template ng-header-tmp *ngIf="selectedSubscriptionList?.length">
                                  <div class="checkbox-cs">
                                    <input id="item-all" name="item-all" type="checkbox" class="form-check-inline" (change)="onSelectAll($event,'subscriptionSelected')" [checked]="checkedAll('subscriptionSelected')"/> Select All
                                  </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item"  let-index="index" let-item$="item$">
                                  <div class="checkbox-cs">
                                    <input id="item-{{index}}" name="item-{{index}}" type="checkbox" class="form-check-inline" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }" [checked]="item$.selected"/> {{item?.displayName}} ({{item?.subscriptionId}})
                                  </div>
                                </ng-template>
                              </ng-select>
                        </div>
                    </div>
                    <div class="checkbox-cs">
                        <input type="checkbox" name="securityCenter" value="automate" class="form-check-inline" id='securityCenter' formControlName="enable">
                        <label class="value" for="securityCenter">Enable Defender for Cloud</label>
                    </div>
                    <div class="checkbox-cs" [class.disabled-icon]="!logAnalyticsData?.logAnalytics">
                      <input type="checkbox" name="sentinal" value="sentinal" class="form-check-inline" id='sentinal' formControlName="enableSentinal">
                      <label class="value" for="sentinal">Enable Azure Sentinel</label>
                  </div>
                </form>
            </ng-container>

            <ng-container *ngIf="step == 5 && !showLoader">
              <h5 class="text-14-22 mb-0">Azure Monitor for SAP solutions</h5>

              <form class="mt-3" [formGroup]="currentForm" style="margin-bottom: 60px;">
                  <div class="checkbox-cs">
                    <input type="checkbox" name="ams" value="ams" class="form-check-inline" id='ams' formControlName="enableAMS" (change)="setAMSValidation($event)">
                    <label class="value" for="ams">Enable AMS</label>
                </div>

                <ng-container *ngIf="currentForm.get('enableAMS').value">
                  <span class="text-12-18">Provide below inputs to onboard AMS.</span>

                  <div class="form-group row mt-3">
                    <label for="subscription" class="control-label col-sm-2">Subscription<span class="mandatory">*</span></label>
                    <div class="col-sm-6">
                    <ng-select [items]="selectedSubscriptionList"  bindLabel="displayName" bindValue="subscriptionId"  name="subscription" (change)="getRgBySubscription();getAllVirtualNetwork();getAllLogAnalytics();resetValue('amsSubscription')"  [clearable]="false" formControlName="subscription" required>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{item?.displayName}} ({{item?.subscriptionId}})</span>
                      </ng-template>
                    </ng-select>
                    </div>
                  </div>
                  <div class="form-group row">
                      <label for="subscription" class="control-label col-sm-2">Resource group<span class="mandatory">*</span></label>
                      <div class="col-sm-6">
                          <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name"  name="resourceGroup"  [clearable]="false" formControlName="resourceGroup" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                              {{item.name}}
                              <span *ngIf="item.isNew" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="removeNewResourceGroup(item,index)"></span>
                              <span *ngIf="item.isNew" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                          </ng-select>
                          <span class="blue-text text-12-18 cursor-pointer" #anchor1 (click)="onToggle('resourceGroup')">Create New</span>
                      </div>
                  </div>
                  <div class="form-group row">
                    <label for="subscription" class="control-label col-sm-2">Resource name<span class="mandatory">*</span></label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control"  id="resourceName" autocomplete="off" name="resourceName" formControlName="resource" (keyup)="ValidateResourceName($event)" required>
                      <p class="text-danger mb-0" *ngIf="currentForm?.controls?.resource?.errors?.minlength || currentForm?.controls?.resource?.errors?.maxLength">The value is in between 6 and 30 characters long.</p>
                      <p class="text-danger mb-0" *ngIf="inputError.validResourceName">Resource name must be unique.</p>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="subscription" class="control-label col-sm-2">Region<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Region in which to create SAP Monitor (typically, the same as the SAP environment); while in preview, not all regions are available" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="col-sm-6">
                      <ng-select [items]="regionList"  bindLabel="value" bindValue="value"  name="region" [clearable]="false" (change)="filterVnetByRegion()" formControlName="region" required>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <span>{{item?.value}}</span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="subscription" class="control-label col-sm-2">Virtual network<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual network to create Azure Monitor for SAP Solutions (preview) into which is connected to the SAP environment." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="col-sm-6">
                        <ng-select [items]="vnetList" bindLabel="name" bindValue="name"  name="amsVnet" (change)="getAllSubnet();resetValue('amsVirtualNetwork')" [clearable]="false" formControlName="virtualNetwork" required>
                          <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.isNew" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="removeNewVnet(item,index)"></span>
                            <span *ngIf="item.isNew" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewVnet(item,index)"></span>
                          </ng-template>
                        </ng-select>
                        <span class="blue-text text-12-18 cursor-pointer" #anchor3 (click)="onToggle('virtualNetwork')">Create New</span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="subscription" class="control-label col-sm-2">Subnet<span class="mandatory">*</span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Subnet in which to create SAP Monitor; please ensure connectivity from this subnet into the SAP environment" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="col-sm-6">
                        <ng-select [items]="subnetList" bindLabel="name" bindValue="name"  name="amsSubnet"  [clearable]="false" formControlName="subnet" required>
                          <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.isNew" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="removeNewSubnet(item,index)"></span>
                            <span *ngIf="item.isNew" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewSubnet(item,index)"></span>
                          </ng-template>
                        </ng-select>
                        <span class="blue-text text-12-18 cursor-pointer" #anchor4 (click)="onToggle('subnet')">Create New</span>
                    </div>
                   </div>

                   <div class="form-group row">
                    <label for="subscription" class="control-label col-sm-2">Log Analytics<span class="mandatory">*</span></label>
                    <div class="col-sm-6">
                        <ng-select [items]="workspaceList" bindLabel="name" bindValue="name"  name="amsLogAnalytics" [clearable]="false" formControlName="logAnalytics" required>
                          <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.isNew" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="removeNewLogAnlaytics(item,index)"></span>
                            <span *ngIf="item.isNew" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewLogAnlaytics(item,index)"></span>
                          </ng-template>
                        </ng-select>
                        <span class="blue-text text-12-18 cursor-pointer" #anchor2 (click)="onToggle('logAnalytics')">Create New</span>
                    </div>
                   </div>

                   <div class="form-group row">
                    <label for="subscription" class="control-label col-sm-2">Share data with microsoft support<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Enable data sharing with Microsoft to receive better support" matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <div class="col-sm-6">
                      <div class="flex-custom no-wrap">
                        <div class="checkbox-cs rounded">
                          <input type="radio" name="enabledMS"  class="form-check-inline" id='yes' (change)="setMS('yes')">
                          <label class="value" for="yes">Share</label>
                        </div>
                        <div class="checkbox-cs rounded ml-2">
                          <input type="radio" name="enabledMS" class="form-check-inline" id='no' (change)="setMS('no')" checked>
                          <label class="value" for="no">Do not share</label>
                        </div>
                      </div>
                    </div>
                   </div>
                </ng-container>
               


              </form>
          </ng-container>

          
            <div *ngIf="showLoader">
              <div class="loader-gif">
                <img src="assets/icons/blue-loader.gif"/>
                <p>Please wait a few moments. </p>
              </div> 
            </div> 

        </div>
     </div>


     <div class="fixed-btm-btn flex-custom no-wrap full-width" *ngIf="!showLoader">
        <button class="btn btn-btn-primary" (click)="moveToNextStep()" *ngIf="step !== 5 && !isCreating" [class.disabled]="currentForm.invalid || (isServicePrincipalCreated && !isPermissiongranted)">Next</button>
        <button class="btn btn-loader" *ngIf="isCreating">
            <img src="assets/onBoard/btn-loading.svg" class="mr-2">Loading...
        </button>
        <button class="btn btn-btn-primary" (click)="moveToNextStep()" *ngIf="step == 5" [class.disabled]="currentForm.invalid">Finish</button>
        <!-- <button class="btn btn-btn-primary" (click)="grantSubscriptionPermission()">Test</button> -->
        <button class="btn btn-outline ml-3" (click)="skip()" *ngIf="step > 1 && step < 5">Skip</button>
     </div>
</div>



<ng-container *ngIf="showAccessTokenSteps == true">
    <div class="sidebar-right single width-985" id="accessToken">
        <div class="header">
            <div class="d-flex">
               <h4 class="half-width">How to Generate Access Token?</h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showAccessTokenSteps = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <div class="step">
                <span class="text-14-22 bold-600 flex-custom">Step 1</span>
                <span class="text-14-22 flex-custom">Sign in the <span class="blue-text cursor-pointer" (click)="openGraphExplorer()">&nbsp;Graph Explorer</span></span>
                <img src="assets/onBoard/accessToken1.svg" alt="">
            </div>

            <div class="step">
              <span class="text-14-22 bold-600 flex-custom">Step 2</span>
              <span class="text-14-22 flex-custom">Click on Modify permissions (Preview) and give admin consent to "Directory.AccessAsUser.All" permission.</span>
              <img src="assets/onBoard/accessToken2.svg" alt="">
            </div>

            <div class="step">
              <span class="text-14-22 bold-600 flex-custom">Step 3</span>
              <span class="text-14-22 flex-custom">Accept the permissions</span>
              <img src="assets/onBoard/accessToken3.svg" alt="">
            </div>
           
            <div class="step">
                <span class="text-14-22 bold-600 flex-custom">Step 4</span>
                <span class="text-14-22 flex-custom">Click on Access Token</span>
                <img src="assets/onBoard/accessToken4.svg" alt="">
            </div>

            <div class="step">
                <span class="text-14-22 bold-600 flex-custom">Step 5</span>
                <span class="text-14-22 flex-custom">Copy this Access Token</span>
                <img src="assets/onBoard/accessToken5.svg" alt="">
            </div>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" (click)="showAccessTokenSteps = false">Done</button>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="showGrantPermissionSteps == true">
    <div class="sidebar-right single width-985" id="grantPermission">
        <div class="header">
            <div class="d-flex">
               <h4 class="half-width">How to Grant Admin Consent?</h4>
               <div class="text-right half-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showGrantPermissionSteps = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <div class="step">
                <span class="text-14-22 bold-600 flex-custom">Step 1</span>
                <span class="text-14-22 flex-custom">Click on button to grand admin consent for the organization.</span>
                <img src="assets/onBoard/grantPermission1.svg" alt="">
            </div>
           
            <div class="step">
                <span class="text-14-22 bold-600 flex-custom">Step 2</span>
                
                <span class="text-14-22 flex-custom">Click on <span class="bold-600">&nbsp;“Yes”&nbsp;</span> to confirm the step.</span>
                <img src="assets/onBoard/grantPermission2.svg" alt="">
            </div>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-btn-primary" (click)="showGrantPermissionSteps = false">Done</button>
        </div>
    </div>
</ng-container>

<kendo-popup [anchor]="anchor1" (anchorViewportLeave)="showRg = false" *ngIf="showRg" id="createRg">
  <form [formGroup]="rgForm">
    <div class='content kendo-content resourceGroup'>
      <div class="rgPopup-form">
          <label for="rgName" class="">Resource group name<span class="mandatory">*</span></label>
          <div class="input-box">
            <input type="text" class="form-control"  id="rgName" autocomplete="off"  placeholder="Enter name" name="rgName" formControlName="rgName" (keyup)="validateResourceGroupName($event,90)" required>
            <p class="text-danger" *ngIf="rgForm.get('rgName')?.errors?.maxlength || inputError?.maxLenResourceGroupName">Resource group names only allow up to 90 characters</p>
            <p class="text-danger" *ngIf="inputError?.duplicateResourceGroupName">A resource group with the same name already exists in the selected subscription.</p>
            <p class="text-danger" *ngIf="inputError?.validResourceGroupName">Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.</p>
          </div>              
      </div>
      <div class="rgPopup-form">
          <label for="rgLocation" class="">Resource group location<span class="mandatory">*</span></label>
          <div class="input-box">
            <ng-select [items]="resourcesGroupLocationList" class="form-control" bindLabel="key" bindValue="value"  name="resourceGroupLocation" formControlName="location" placeholder="Choose resource group location" [clearable]="false" required>
            </ng-select>
          </div>
      </div>
      <div class="d-flex">
          <button class="btn btn-btn-primary" [class.disabled]="rgForm?.invalid || inputError?.duplicateResourceGroupName || inputError?.validResourceGroupName" (click)="addRgToExisting()">Save</button>
          <button class="btn btn-outline" (click)="onToggle('resourceGroup')">Cancel</button>
      </div>
    </div>
  </form>
</kendo-popup>

<kendo-popup [anchor]="anchor2" (anchorViewportLeave)="showLog = false" *ngIf="showLog" id="createLa">
  <form [formGroup]="logAnalyticsForm">
    <div class='content kendo-content logAnalytics'>
      <div class="rgPopup-form">
          <h3 class="text-14-22">Create Log Analytics Workspace</h3>
          <label for="rgName" class="">Name<span class="mandatory">*</span></label>
          <div class="input-box">
            <input type="text" class="form-control"  id="rgName" autocomplete="off"  placeholder="Enter name" name="rgName" formControlName="name" (keyup)="validateLaName($event)" required>
            <p class="text-danger" *ngIf="logAnalyticsForm.get('name')?.errors?.maxlength || logAnalyticsForm.get('name')?.errors?.minlength">The workspace name must be between 4 and 63 characters.</p>
            <p class="text-danger" *ngIf="inputError?.validLogAnalyticsName">The workspace name can contain only letters, numbers and '-'.</p>
            <p class="text-danger" *ngIf="inputError?.isSameLogAnalyticsName">Resource name must be unique.</p>
            <!-- The '-' shouldn't be the first or the last symbol. -->
          </div>              
      </div>
      <div class="d-flex">
          <button class="btn btn-btn-primary" [class.disabled]="logAnalyticsForm?.invalid || inputError?.validLogAnalyticsName || inputError?.isSameLogAnalyticsName" (click)="addLogAnlayticsToExisting()">Ok</button>
          <button class="btn btn-outline" (click)="onToggle('logAnalytics')">Cancel</button>
      </div>
  </div>
  </form>
</kendo-popup>

<kendo-popup [anchor]="anchor3" (anchorViewportLeave)="showVnet = false" *ngIf="showVnet" id="createVnet">
  <form [formGroup]="virtualNetworkForm">
    <div class='content kendo-content'>
      <div class="rgPopup-form">
          <h3 class="text-14-22">Create Virtual Network</h3>
          <label for="vnet" class="">Virtual network name <span class="mandatory">*</span></label>
          <div class="input-box">
            <input type="text" class="form-control"  id="vnet" autocomplete="off" name="vnet" formControlName="name" (keyup)="VNetNameValidation($event,80)" required>
            <p class="text-danger" *ngIf="inputError?.VNetName">The name must be between 1 and 80 characters.</p>
            <p class="text-danger" *ngIf="inputError?.duplicateVnet">This name is already taken.</p>
            <p class="text-danger" *ngIf="inputError?.VNetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
          </div>  
          <label for="rgName" class="">Virtual network address space <span class="mandatory">*</span></label>
          <ng-container formArrayName="addressSpaceList" *ngFor="let item of virtualNetworkForm.get('addressSpaceList')['controls']; let i = index;">
            <div class="input-box" [formGroupName]="i">
              <div class="flex-custom no-wrap">
                <input type="text" class="form-control" id="VNetAdrSpc_{{i}}" autocomplete="off" formControlName="addressSpace" placeholder="0.0.0.0/0" name="VirtualNetworkAddressSpace_{{i}}" (keyup)="VNetAddressSpace($event,item,i)" required>
                <span style="padding-left: 5px;padding-bottom: 13px;"><img src="/assets/icons/delete.png" (click)="removeNewAddress(i)" *ngIf="addressList?.length > 1"></span>
              </div>
              <p class="text-danger" *ngIf="item.VNetAdrspc">Invalid argument: Malformed IP address.</p>
              <p class="text-danger" *ngIf="item.notValidCIDR">Invalid CIDR Block. Instead, use  address space</p>
              <p class="text-danger" *ngIf="item.overlooping">overlaps with address space </p>
              <p class="text-danger" *ngIf="item.publicAddress">The address range must be contained in one of the private IP address spaces: 192.168.0.0/16, 172.16.0.0/12, or 10.0.0.0/8</p>
            </div>     
          </ng-container>       
          <span class="blue-text text-12-18 cursor-pointer" (click)="addNewAddress()">Add another address space</span>
      </div>
      <div class="d-flex">
          <button class="btn btn-btn-primary" [class.disabled]="virtualNetworkForm?.invalid || inputError?.validLogAnalyticsName" (click)="addVnetToExisting()">Ok</button>
          <button class="btn btn-outline" (click)="onToggle('virtualNetwork')">Cancel</button>
      </div>
  </div>
  </form>
</kendo-popup>

<kendo-popup [anchor]="anchor4" (anchorViewportLeave)="showSubnet = false" *ngIf="showSubnet" id="createSubnet">
  <form [formGroup]="subnetForm">
    <div class='content kendo-content'>
      <div class="rgPopup-form">
          <h3 class="text-14-22">Create Subnet</h3>
          <label for="subnetName" class="">Subnet name<span class="mandatory">*</span></label>
          <div class="input-box">
            <input type="text" class="form-control"  id="subnetName" autocomplete="off"  placeholder="Enter name" name="subnetName" formControlName="name" (keyup)="subnetNameValidation($event,80)" required>
            <p class="text-danger" *ngIf="inputError?.subnetName">The name must be between 1 and 80 characters.</p>
            <p class="text-danger" *ngIf="inputError?.subnetChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
            <p class="text-danger" *ngIf="inputError?.subnetDuplicate">Subnet names must be unique within a virtual network.</p>
          </div>  
          <label for="SubnetAddressSpace" class="">Address prefix<span class="mandatory">*</span></label>
          <div class="input-box">
            <input type="text" class="form-control"  id="SubnetAddressSpace" autocomplete="off"  placeholder="eg. 10.0.0.0/24" name="SubnetAddressSpace" formControlName="address" (keyup)="subnetAddressSpace($event)" required>
            <p class="text-danger" *ngIf="inputError?.subnetAdrspc">Invalid argument: Malformed IP address.</p>
            <p class="text-danger" *ngIf="inputError?.subnetNotValidCIDR">Invalid CIDR Block.</p>
            <p class="text-danger" *ngIf="inputError?.subnetOverlaping">This subnet address space overlaps with existing</p>
            <p class="text-danger" *ngIf="inputError?.subnetPerfix">The prefix must be smaller than or equal to 29</p>
            <p class="text-danger" *ngIf="inputError?.subnetAddresNotInVnet">Subnet address range should contained under same virtual network's address spaces</p>
          </div>              
      </div>
      <div class="d-flex">
          <button class="btn btn-btn-primary" [class.disabled]="subnetForm?.invalid || inputError?.subnetName || inputError?.subnetChar || inputError?.subnetDuplicate ||   inputError?.subnetAdrspc || inputError?.subnetNotValidCIDR || inputError?.subnetOverlaping || inputError?.subnetPerfix ||inputError?.subnetAddresNotInVnet" (click)="addSubnetToExisting()">Ok</button>
          <button class="btn btn-outline" (click)="onToggle('subnet')">Cancel</button>
      </div>
  </div>
  </form>
</kendo-popup>