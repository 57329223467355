import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as fs from 'file-saver';
import * as _ from 'lodash';
import { ValidatorsService } from 'src/app/services/validators.service';
import { FormArray,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResourceInputError } from 'src/app/common/resources/resources-model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { LayoutModule } from '@angular/cdk/layout';
import { IPv4, IPSubnetwork } from 'ip-matching';
import { A3sDateTimeFormat,twoDecimal } from 'src/app/pipes/custom.pipe';

@Component({
  selector: 'app-vm-scale-set-actions',
  templateUrl: './vm-scale-set-actions.component.html',
  styleUrls: ['./vm-scale-set-actions.component.scss']
})
export class VmScaleSetActionsComponent implements OnInit {
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  @Input() data : any;
  @Input() currentProjectData : any;
  @Input() columns : any;
  @ViewChild("vmScaleSetDetailsTemplate") vmScaleSetDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteConfirm") deleteConfirmPopup: TemplateRef<any>;
  @ViewChild("vmScaleSetConfirm") vmScaleSetActionPopup: TemplateRef<any>;
  @ViewChild("addSecurityRulesPopup") addSecurityRulesPopupTemplate: TemplateRef<any>;
  datasort: string;
  selectedRow: any;
  showSidesheet: boolean;
  currentTemplate: any;
  currentForm: any;
  showSubSidesheet: boolean;
  inputError={
    InvalidPortRange:false,
    NotValidPortVal:false,
    gracePeriodRange:false,
    gracePeriodVal:false,
    validScaleName:false,
    validNumber:false,
    validCount:false,
    defvalidNumber:false,
    defvalidCount:false,
    minValidInstance:false,
    maxValidInstance:false,
    defValidInstance:false,
    validMinInstance:false,
    validMaxInstance:false,
    validMaximumInstance:false,
    validDefInstance:false,
    defMinValidInstance:false,
    defMaxValidInstance:false,
    defaultDefValidInstance:false,
    defValidMinInstance:false,
    defValidMaxInstance:false,
    defValidMaximumInstance:false,
    defValidDefInstance:false,

    sourceInvalidArgument:false,
    sourceInvalidPortRange:false,
    sourceNotValidCIDR:false,
    sourceNotValidPortVal:false,
    sourceOverlaping:false,
    sourceOverlap:false,
    destInvalidArgument:false,
    destInvalidPortRange:false,
    destNotValidCIDR:false,
    destNotValidPortVal:false,
    destOverlaping:false,
    destOverlap:false,
    VMPriorityValid:false,
    VMPriorityMaxMin:false,
    VMPriorityDupCheck:false,
    nameDupCheck:false,
    VMdescription:false,
    nameLen:false,
    nameValid:false,
    poolValid:false,
    poolExistValid:false,
    lunError:false,
    nicNameLen:false,
    nicNameDupCheck:false,
    nicNameValid:false,
    nicNameEmpty:false,
    nicNameLastValid:false,
    nsgNameLen:false,
    nsgNameDupCheck:false,
    nsgNameValid:false,
    nsgNameEmpty:false,
    
    nsgNameLastValid:false,
  }
  selectedType: any;
  selectedSideSheetRow: any;
  saveDisable: boolean;
  subSideSheetForm: FormGroup;

  vmscaleSetData: any;
  disableCondition: boolean;
  vmscaleSetVMList: any;
  disableStart: boolean;
  disableStop: boolean;
  vmscaleSetVMData: any;
  vmSeriesList:any=this.util.getStatic('vmSizeList');
  timeZoneList=this.util.getStatic('timeZoneList');
  instanceProtectionList:any=[
    {name:'None',id:'none'},
    {name:'Protect from scale-in',id:'scaleIn'},
    {name:'Protect from scale set actions',id:'scaleSetAction'}
  ];
  upgradeModeList:any=[
    {name:'Automatic - Instances will start upgrading immediately in random order',id:'Automatic'},
    {name:'Manual - Existing instances must be manually upgraded',id:'Manual'},
   // {name:'Rolling - Upgrades roll out in batches with optional pause',id:'Rolling'}
  ];
  AHMList:any=[
    {name:'Application health extension',id:'extension'},
    //{name:'Load balancer probe',id:'probe'},

  ];
  protocalList:any=[
    {name:'HTTP',id:'http'},
    {name:'HTTPS',id:'https'},
    {name:'TCP',id:'tcp'}
  ];
  encryptionList=[
    {id:'EncryptionAtRestWithPlatformKey',name:'(Default) Encryption at-rest with a platform-managed key'},
    {id:'EncryptionAtRestWithCustomerKey',name:'Encryption at-rest with a customer-managed key'},
    {id:'EncryptionAtRestWithPlatformAndCustomerKeys',name:'Double encryption with platform-managed and customer-managed keys'}
  ]
  hostCatchingList:any=[
    {name:'None',id:'None'},
    {name:'Read-only',id:'ReadOnly'},
    {name:'Read/Write',id:'ReadWrite'}
  ];
  storageTypeList=[
    {id:'Premium_LRS',name:'Premium SSD'},
    {id:'StandardSSD_LRS',name:'Standard SSD'},
    {id:'Standard_LRS',name:'Standard HDD'}
  ]
  scaleInPolicyList=[
    {id:'Default',name:'Default - Balance across availability zones and fault domain, then delete virtual machine with highest instance ID'},
    {id:'NewestVM',name:'NewestVM - Balance across available zones,then delete the newest created virtual machine'},
    {id:'OldestVM',name:'OldestVM - Balance across available zones,then delete the oldest created virtual machine'}
  ];
  mode=[
    {id:'Disabled',name:'Disabled'},
  ]
  lbOptionsList=[
    {id:'Azure load balancer',name:'Azure load balancer'},
  ]
  instanceId: any[];
  bothEnable: boolean;
  vmscaleSetHealthStateData: any;
  proximityGroupList: any;
  lunList: any[];
  skuSeriesList: any;
  dummyVMSizeList: any;
  currentTab: any;
  ResourceGroupsList: any;
  defaultScaleCondition: any;
  scaleCondition: any;
  currentNICTab: any;
  ipConfigList: any[];
  disableAutoScaleName: boolean;
  isSecurityRulesEdit: any;
  currentSecurityRules: any;
  popUpForm: FormGroup;
  nsgData: any;

  vmSourceList:any=[
    {name:'Any'},
    {name:'IP addresses'}
  ];

  destinationList:any=[
    {name:'Any'},
    {name:'IP addresses'},
    {name:'VirtualNetwork'}
  ];
  inboundPortList:any=[
    {name:'HTTP (80)',id:'HTTPS'},
    {name:'HTTPS (443)',id:'HTTPS'},
    {name:'SSH (22)',id:'SSH'},
    {name:'RDP (3389)',id:'RDP'},
  ]
  vmServiceList:any=[
    {name:'HTTP'},
    {name:'HTTPS'},
    {name:'SSH'},
    {name:'RDP'},
    {name:'MS SQL'},
    {name:'MySQL'},
    {name:'Custom'},
    {name:'DNS(TCP)'},
    {name:'DNS(UDP)'}
  ];
  selectedNIC: any;
  lbSelectorList: any;
  backendPoolList: any;
  loadBalancerList: any;
  vmLoadBalancerData: any;
  showDataDiskTable: boolean;
  showNICSidesheet: boolean;
  showNsgPopup: boolean;
  vNetList: any;
  subnetList: { name: any; id: any; }[];
  nsgList: any;
  vmSizeData: any;
  autoScaleData: any;
  constructor(    public util:UtilService,
    private titleCase:TitleCasePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private a3sDateTimeFormat:A3sDateTimeFormat,
    private _dataMigrationService: DataMigrationService,
    private validatorService : ValidatorsService) { }

  ngOnInit(): void {
  }
   async getOverviewCondition(data){
      this.disableStart=false
      this.disableStop=false
      await this.getVMScaleSetData(data,'disableCondition')
      await this.getVMScaleSetVMList(data)
    }
  async action(data,type,subType?:any){
    console.log(type +'Data',data)
    switch(type){
      case 'startVMScaleSet': {
        this.closeModal()
        subType=='instance'?await this.getStartInstanceVM(data):await this.startVMScaleSet(data,'vmScaleSet')
        break;
      }
      case 'restartVMScaleSet': {
        this.closeModal()
        subType=='instance'?await this.openModal(this.vmScaleSetActionPopup,this.selectedRow,'restartVMScaleSet'):await this.restartVMScaleSet(data,'vmScaleSet')
        break;
      }
      case 'stopVMScaleSet': {
        this.closeModal()
        subType=='instance'?await this.openModal(this.deleteConfirmPopup,this.selectedRow,'stopVMScaleSet'):await this.stopVMScaleSet(data,'vmScaleSet')
        break;
      }
      case 'reimageVMScaleSet': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'reimageVMScaleSet')
      }
      case 'upgradeVMScaleSet': {
        this.closeModal()
        return this.openModal(this.vmScaleSetActionPopup,data,'upgradeVMScaleSet')
      }
      case 'deleteVMScaleSetInstance': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteVMScaleSetInstance')
      }
      case 'deleteVMScaleSetData': {
        this.closeModal()
        return this.openModal(this.deleteConfirmPopup,data,'deleteVMScaleSetData')
      }
      case 'protectionPolicy': {
        this.closeModal()
          this.subSideSheetForm=this.formBuilder.group({
            instanceProtection : [null],
          })
          this.instanceId.forEach((e,i)=>{
            this.vmscaleSetVMList.forEach((x,ind)=>{
                if(e==x.instanceId){
                  let policy=x?.properties?.protectionPolicy?.protectFromScaleIn?'scaleIn':x?.properties?.protectionPolicy?.protectFromScaleSetActions?'scaleSetAction':'none'
                  this.subSideSheetForm.get('instanceProtection').setValue(policy)
                }
            })
          })
        this.showSubSidesheet = true
      }
      break;
    }
  }
    // condition to start the VM Based on orchestration mode
  getStartInstanceVM(data){
    if(this.disableCondition){
          this.instanceId.forEach(e=>{
            let body ={ subscriptionId : data[2],resourceGroupName : data[1],virtualMachineName : e }
            return this.util.handleRequest('post','a3s_management_startVM',[],body,null,null,true).then(res=>{
              this.util.success(e + ' started successfully!')
            },err=>{
              this.util.error(e +' failed to start.')
            })
          })
    } else return this.startVMScaleSet(data,'vmScaleSetInstance')
    this.getOverviewCondition(data)
    this.resetForm()
  }

  restartVM(data){
    this.instanceId.forEach(e=>{
        let body ={ subscriptionId : data[2],resourceGroupName : data[1],virtualMachineName : e }
        return this.util.handleRequest('post','a3s_management_restartVM',[],body,null,null,true).then(res=>{
          this.util.success(e + ' restarted successfully!')
        },err=>{
          this.util.error(e +' failed to restart.')
        })
    })
  }
  stopVMAction(data){
    this.instanceId.forEach(e=>{
        let body ={ subscriptionId : data[2],resourceGroupName : data[1],virtualMachineName : e }
        return this.util.handleRequest('post','a3s_management_stopVM',[],body,null,null,true).then(res=>{
          this.util.success(e + ' stopped successfully!')
          this.closeModal()
        },err=>{
          this.util.error(e +' failed to stop .')
          this.closeModal()
        })
     })
  }
  deleteVM(data){
    this.instanceId.forEach(e=>{
      let subscriptionId = data[2],resourceGroupName = data[1],virtualMachineName = e;
      return this.util.handleRequest('delete','a3s_management_deleteVM',[subscriptionId,resourceGroupName,virtualMachineName],null,null,null,true).then(res=>{
        this.util.success(e + ' deleted successfully!')
        this.closeModal()
      },err=>{
        this.util.error(e +' failed to deleted.')
        this.closeModal()
      })
   })
  }
//API for uniform mode.
  startVMScaleSet(data,type?:any){
    let  subscriptionId =data[2],resourceGroupName =data[1],vmScaleSetName =data[0] 
    let body ={
      "type":type,
      "instanceBody":{
        "instanceIds": this.showSidesheet?this.instanceId:[]
      }
    }
    return this.util.handleRequest('post','a3s_management_startVMScaleSet',[subscriptionId,resourceGroupName,vmScaleSetName],body,null,null,true).then(res=>{
      this.util.success(data[0] + ' started successfully!')
    },err=>{
      this.util.error(data[0] +' failed to start.')
    })
  }

  restartVMScaleSet(data,type?:any){
    let  subscriptionId =data[2],resourceGroupName =data[1],vmScaleSetName =data[0] 
    let body ={
      "type":type,
      "instanceBody":{
        "instanceIds":  this.showSidesheet?this.instanceId:[]
      }
    }
    return this.util.handleRequest('post','a3s_management_restartVMScaleSet',[subscriptionId,resourceGroupName,vmScaleSetName],body,null,null,true).then(res=>{
      this.util.success(data[0] + ' restarted successfully!')
      this.closeModal()
    },err=>{
      this.util.error(data[0] +' failed to restart.')
      this.closeModal()
    })
  }

  stopVMScaleSet(data,type?:any){
  let  subscriptionId =data[2],resourceGroupName =data[1],vmScaleSetName =data[0] 
  let body ={
    "type":type,
    "instanceBody":{
      "instanceIds":  this.showSidesheet?this.instanceId:[]
    }
   }
    return this.util.handleRequest('post','a3s_management_stopVMScaleSet',[subscriptionId,resourceGroupName,vmScaleSetName],body,null,null,true).then(res=>{
      this.util.success(data[0] + ' stopped successfully!')
      this.closeModal()
    },err=>{
      this.util.error(data[0] +' failed to stop .')
      this.closeModal()
    })
  }

  deleteVMScaleSetInstance(data,type?:any){
    let  subscriptionId =data[2],resourceGroupName =data[1],vmScaleSetName =data[0] 
    let body ={
        "instanceIds": this.instanceId
    }
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('post','a3s_management_deleteInstanceVMScaleSet',[subscriptionId,resourceGroupName,vmScaleSetName],body,null,null,true).then(res=>{
      this.util.success(data[0] + ' deleted successfully!')
      this.closeModal()
    },err=>{
      this.util.error(data[0] +' failed to deleted.')
      this.closeModal()
    })
  }

  reimageVMScaleSet(data,type?:any){
    let  subscriptionId =data[2],resourceGroupName =data[1],vmScaleSetName =data[0] 
    let body ={
      "instanceIds": this.instanceId
    }
    this.util.info(`Reimaging VM “${data[0]}”...`)
      return this.util.handleRequest('post','a3s_management_reimageVMScaleSet',[subscriptionId,resourceGroupName,vmScaleSetName],body,null,null,true).then(res=>{
        this.util.success(`VM “${data[0]}” reimaged successfully!`)
        this.closeModal()
      },err=>{
        this.util.error(`VM “${data[0]}” failed to reimaged.`)
        this.closeModal()
      })
  }

  upgradeVMScaleSet(data,type?:any){
    let  subscriptionId =data[2],resourceGroupName =data[1],vmScaleSetName =data[0] 
    let body ={
        "instanceIds": this.instanceId
    }
    this.util.info(`Upgrading VM “${data[0]}”...`)
    return this.util.handleRequest('post','a3s_management_upgradeVMScaleSet',[subscriptionId,resourceGroupName,vmScaleSetName],body,null,null,true).then(res=>{
      this.util.success(`VM “${data[0]}” upgraded successfully!`)
      this.closeModal()
    },err=>{
      this.util.error(`VM “${data[0]}” failed to upgraded.`)
      this.closeModal()
    })
  }

  async addInstanceProtection(){
    let instanceProtection = this.subSideSheetForm.get('instanceProtection').value 
    let body={
     "properties":{ 
       "protectionPolicy": { 
           "protectFromScaleIn": instanceProtection=='scaleIn'?true:false,  
           "protectFromScaleSetActions": instanceProtection=='scaleSetAction'?true:false 
           } 
       } 
    }
   await  this.updateProtectionPolicy(this.selectedRow,body)
    this.showSubSidesheet=false
  }

  updateProtectionPolicy(data,body,type?:any){
    let  subscriptionId =data[2],resourceGroupName =data[1],vmScaleSetName =data[0]
    let instanceID=this.instanceId[0]
    this.util.info(`Applying protection policy to VM “${data[0]}”...`)
    return this.util.handleRequest('post','a3s_management_protectionPolicyVMScaleSet',[subscriptionId,resourceGroupName,vmScaleSetName,instanceID],body,null,null,true).then(res=>{
      this.util.success(`Protection policy applied to VM “${data[0]}” successfully!`)
    },err=>{
      this.util.error(`Protection policy failed to be applied to VM “${data[0]}”.`)
    })
  }

  async openSidesheet(type,data?:any){
    this.currentTemplate = type
    this.selectedRow = data;
    switch(this.currentTemplate){
      case "instances" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetInstance')
        this.showSidesheet = true
        break;
      } 
      case "upgradePolicy" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetUpgradePolicy')
        var def =this.vmscaleSetData?.properties?.upgradePolicy ? this.vmscaleSetData?.properties?.upgradePolicy?.mode:null
        this.currentForm.get('upgradeMode').setValue(def)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      } 
      case "healthAndRepair" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetHealthAndRepair')
        await this.getVMScaleSetHealthState(this.selectedRow)
        this.saveDisable=false
        this.showSidesheet = true;
        break;
      } 
      case "size" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetSize')
        await this.getSkuSeriesList(this.selectedRow)
        await this.getVMSizeList(this.selectedRow)
        this.currentForm.get('sizeSeries').setValue(this.vmSeriesList[0].name)
        let sizeList=this.vmSeriesList.find(dt=>dt.name==this.currentForm.get('sizeSeries').value).sizeList;
        this.dummyVMSizeList=this.skuSeriesList.filter(e=> sizeList.some(x=>e.sku.name.includes(x)))
        this.dummyVMSizeList.map(e=>{
          if(this.vmscaleSetData.sku.name==e.sku.name){
                  e['active']=true
          }
          this.vmSizeData.forEach(x=>{
            if(e.sku.name==x.name){
              let split=e?.sku?.name.split('_')
              split=split.slice(1)
              e['series']=split.join('_')
              e.capacity['maxDataDiskCount']=x.maxDataDiskCount
              let GIb=(x.memoryInMB/1024).toFixed()
              e.capacity['Ram']=GIb
              e.capacity['vCPUs']=x.numberOfCores
            }
          })
        })
       this.saveDisable=false
        this.showSidesheet = true;
        break;
      }  
      case "configuration" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetConfiguration')
        await this.getProximityPlacementGroupsList(this.selectedRow)
        await this.setDefaultValue('configuration')
       this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "disk" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetDisk')
        await this.setDefaultValue('disk')
        break;
      } 
      case "scaling" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetScaling')
        this.currentTab = 'configure'
        await this.getRgBySubscription()
        await this.getAutoScalingData(this.selectedRow)
        await this.setDefaultValue('scaling')
       this.saveDisable=false
        this.showSidesheet = true;
        break;
      }
      case "networking" : {
        this.closeModal()
        this.currentForm = this.util.getForm('VMScaleSetNetworking')
        this.popUpForm=this.util.getForm('inboundSecurityRules')
        await this.getVMScaleSetData(data,'')
        let param=[
          this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[0].properties.networkSecurityGroup.id.split('/')[2],
          this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[0].properties.networkSecurityGroup.id.split('/')[4],
          this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[0].properties.networkSecurityGroup.id.split('/')[8]
        ]
        await this.getNSGData('',param)
        await this.setDefaultValue('networking')
        await this.getLoadBalancerList(this.selectedRow)
       this.saveDisable=false
       this.showSubSidesheet = false;
        this.showSidesheet = true;
        break;
      }
    }
  }

  async deleteAction(){
    switch(this.selectedType){
      case 'deleteVMScaleSetData' :return this.deleteVMScaleSetData(this.selectedRow)
      case'restartVMScaleSet' :{
        this.disableCondition? await this.restartVM(this.selectedRow)  :await  this.restartVMScaleSet(this.selectedRow,'vmScaleSetInstance');
        this.getOverviewCondition(this.selectedRow)
        break;
      }
      case'stopVMScaleSet' :{
        this.disableCondition? await this.stopVMAction(this.selectedRow)  :await   this.stopVMScaleSet(this.selectedRow,'vmScaleSetInstance');
        this.getOverviewCondition(this.selectedRow)
        break;
      }
      case'deleteVMScaleSetInstance' :{
        this.disableCondition? await this.deleteVM(this.selectedRow)  :await  this.deleteVMScaleSetInstance(this.selectedRow);
        this.getOverviewCondition(this.selectedRow)
        break;
      }
      case'upgradeVMScaleSet' :return  this.upgradeVMScaleSet(this.selectedRow)
      case'reimageVMScaleSet' :return  this.reimageVMScaleSet(this.selectedRow)
    }
    this.resetForm()
  }

  openModal(template: TemplateRef<any>,row,type?:any) {
    this.selectedRow=row;
    this.selectedType=type;
    type=='vmPopup'?this.getVMScaleSetData(row,'disableCondition'):false 
    this.modalService.open(template,  { windowClass: 'mgmt-popup confirm-popup' });
  }
  async deleteDataDisk(index,Lun?:any){
    this.saveDisable=true
    this.dataDiskArrayList.value.map((e,i) => {
      if(i!=index&&e?.lun==Lun){
          this.inputError.lunError=false
      }})
   this.dataDiskArrayList.controls.splice(index, 1);
  }
  async deletescaleCondition(index){
    this.saveDisable=true
   this.scaleConditionArrayList.controls.splice(index, 1);
   this.scaleCondition= this.scaleConditionArrayList.controls.length==0?false:true
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  get scaleConditionArrayList() {
    return this.currentForm.get('scaleConditonArray') as FormArray;
  }
  get dataDiskArrayList() {
    return this.currentForm.get('vmScaleSetDataDiskArray') as FormArray;
  }
  adddisk(){
    !this.showDataDiskTable?this.deleteDataDisk(0):false
    this.showDataDiskTable=true
    let tempForm=this.util.getForm('addVMScaleSetDataDiskArray')
    tempForm.get('lunList').setValue(this.lunList)
    this.dataDiskArrayList.push(tempForm)

  }
  addScaleCondition(){
    if(!this.scaleCondition){
      this.scaleConditionArrayList.controls.length==0?this.scaleConditionArrayList.push(this.util.getForm('addScaleConditonArray')):false
      return this.scaleCondition=true
    }
    this.scaleCondition=true
    this.scaleConditionArrayList.push(this.util.getForm('addScaleConditonArray'))
  }
  closeSidesheet(){
    this.showSidesheet = false
  }
  close_reset(){
    this.closeSidesheet()
    this.resetForm()
    this.resetValidation()
  }
  getProjectName(val){
    if(this.currentProjectData.subscriptionId == val){
      return val =this.currentProjectData.subscriptionName
    }
  }
  changeTab(name){
    this.currentTab=name
    // this.resetForm()
    this.resetValidation()
    if(this.currentTab=='scaleInPolicy'){
    this.currentForm.get('scaleInPolicy').setValue(this.vmscaleSetData?.properties?.scaleInPolicy?.rules[0])
    }
    this.saveDisable=false
  }
  changeNetworkingTab(name,type){
    if(type=='NIC'){
      this.currentNICTab=name
      this.selectedNIC=this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.filter(e=>e.name==this.currentNICTab)
      this.currentForm.get('vNet').setValue(this.selectedNIC[0].properties?.ipConfigurations[0]?.properties?.subnet?.id.split('/')[8])
      this.currentForm.get('subnet').setValue(this.selectedNIC[0].properties?.ipConfigurations[0]?.properties?.subnet?.id.split('/')[10])
      this.ipConfigList=[]
      this.selectedNIC[0].properties.ipConfigurations.map(e=>{
        if(e?.properties?.primary){
          this.ipConfigList.push({
            name:`${e.name} (primary)`,
            id:e.name,
          })
        }else{
          this.ipConfigList.push({
            name:e.name,
            id:e.name,
          })
        }
      
      })
      this.currentForm.get('ipConfig').setValue(this.selectedNIC[0].properties?.ipConfigurations[0].name)
      let param=[
        this.selectedNIC[0].properties.networkSecurityGroup.id.split('/')[2],
        this.selectedNIC[0].properties.networkSecurityGroup.id.split('/')[4],
        this.selectedNIC[0].properties.networkSecurityGroup.id.split('/')[8]
      ]
      this.getNSGData('',param)
    }else{
      this.currentTab=name
      this.showSubSidesheet=false
      switch(name){
        case 'inbound' :{
          this.popUpForm=this.util.getForm('inboundSecurityRules')
          break;
        }
        case 'outbound' :{
          this.popUpForm=this.util.getForm('outboundSecurityRules')
          break;
        }
        case 'loadBalancing' :{
          this.popUpForm=this.util.getForm('VMScaleSetLoadBalncer')
          let ipName = this.currentForm.get('ipConfig').value
          let nicIndex=_.cloneDeep(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.findIndex(e=>e.name==this.currentNICTab))
          let ipConfigIndex=_.cloneDeep(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations.findIndex(e=>e.name==ipName))
          this.loadBalancerList=this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations[ipConfigIndex].properties['loadBalancerBackendAddressPools']
          this.vmLoadBalancerData=this.lbSelectorList.filter(e=>this.loadBalancerList.some(x=>e.name.toLowerCase()==x.id.split('/')[8].toLowerCase()))
          console.log('Backend list',this.loadBalancerList )
          break;
        }
      }
    }
 
  }
  getResourceTitle(){
    switch(this.selectedType){
      case'deleteVMScaleSetData':return 'Delete Virtual Machine Scale Set'
      case'restartVMScaleSet' : return'Restart Virtual Machine Instance(s)'
      case'stopVMScaleSet' : return'Deallocate Virtual Machine Instance(s)'
      case'reimageVMScaleSet' : return'Reimage Virtual Machine Instance(s)'
      case'upgradeVMScaleSet' : return'Upgrade Virtual Machine Instance(s)'
      case'deleteVMScaleSetInstance' : return'Delete Virtual Machine Instance(s)'
      default: return
    }
  }
  getResourceName(){
    switch(this.selectedType){
      case'deleteVMScaleSetData':return `Are you sure you want to delete “${this.selectedRow[0]}”?` 
      case'restartVMScaleSet':return 'This Action will shut down the instances and restart them. If an instance doesn’t gracefully shut down within 5 minutes, it will be powered off before being restarted. Do you want to restart the selected instances?'
      case'stopVMScaleSet':return 'This Action will shut down the selected instances. If an instance doesn’t gracefully shut down within 5 minutes, it will be powered off. Do you want to deallocate the selected instances?'
      case'reimageVMScaleSet':return'This Action will reimage the selected instances. restoring them to their initial state. The instances will be restarted, and any local data will be lost. Do you want to reimage the selected instances?'
      case'upgradeVMScaleSet':return'This Action will manually upgrade the selected instances to the latest model. While upgrading, the instances may be restarted. Do you want to upgrade the selected instances?'
      case'deleteVMScaleSetInstance':return 'This Action will delete the instances, including thier disks. Do you want to delete the selected instances?' 
      default: return 'delete '+`"${this.selectedRow[0]}"`
    }
  }
  getBtnName(){
    switch(this.selectedType){
      case'deleteVMScaleSetData':return 'Delete'
      case'restartVMScaleSet':return 'Restart'
      case'stopVMScaleSet':return 'Deallocate'
      case'deleteVMScaleSetInstance':return 'Delete'
      case'reimageVMScaleSet':return 'Reimage'
      case'upgradeVMScaleSet':return 'Upgrade'
      default: return 'Delete'
    }
  }
  getTags(val){
    if(!val) return "";
    let obj = {};
    Object.keys(val).forEach(e => {
      obj[e] = val[e] ;
    })
    return JSON.stringify(obj).replace(/["{}]/g, ' ');
  }
  getStatusClass(val){
    let status = val?.statuses.length<2 ? 'running':val?.statuses[1]?.code.split('/')[1].toLowerCase() 
    switch (status) {
      case 'running': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deallocating': return 'st-warning';
      case 'starting': return 'st-success';
      case 'stopped': return 'st-error';
      case 'stopping': return 'st-error';
      case 'unknown': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipClass(val){
    let status = val.toLowerCase()
    switch (status) {
      case 'allow': return 'st-success';
      case 'deallocated': return 'st-warning';
      case 'deny': return 'st-error';
      default: return 'st-error';
    }
  }
  getStatusChipImg(value) {
    let status = value.toLowerCase()
    switch (status) {
      case 'allow': return 'assets/icons/green-tick.svg'
      case 'deny': return 'assets/icons/error-info.svg';
   }
  }
  getStatusName(val){
    let name = val?.statuses.length<2 ? 'updating':val?.statuses[1]?.code.split('/')[1].toLowerCase() 
    return name
  }
  getRegionName(val){
    let trans = this.titleCase.transform(val)
    let ele = trans.replace('us', ' US')
    return ele.replace('2', ' 2')
  }
  getTableArraydata(data,type){
    switch(type) {
      case'source':{if(data?.properties?.sourceAddressPrefix){
        return data?.properties?.sourceAddressPrefix=='*'?'Any': data?.properties?.sourceAddressPrefix}
        else if(data?.properties?.sourceAddressPrefixes.length){ return data?.properties?.sourceAddressPrefixes.join(', ')}
      }
      case'destination':{if(data?.properties?.destinationAddressPrefix){
        return data?.properties?.destinationAddressPrefix=='*'?'Any': data?.properties?.destinationAddressPrefix}
        else if(data?.properties?.destinationAddressPrefixes.length){ return data?.properties?.destinationAddressPrefixes.join(', ')}
      }
      case'sourcePort':{if(data?.properties?.destinationPortRange){
        return data?.properties?.destinationPortRange=='*'?'Any': data?.properties?.destinationPortRange}
        else if(data?.properties?.destinationPortRanges.length){ return data?.properties?.destinationPortRanges.join(', ')}
      }
    }
  }

  splitWordByCaps(data){
    return this.util.splitWordByCaps(data)
  }
  splitWordByCapsExceptAbb(data){
    return this.util.splitWordByCapsExceptAbb(data)
  }

  getSuccesText(type){
    switch(type){
      case'upgradePolicy':return `${this.selectedRow[0]} policy upgraded successfully!` 
      case'healthAndRepair':return `“${this.selectedRow[0]}” health and repair updated successfully!` 
      case'configuration':return `Virtual machine scale set “${this.selectedRow[0]}” configuration updated successfully!`
      case'disk':return `Data Disks for “${this.selectedRow[0]}” updated successfully!`
      case'size':return `Virtual machine “${this.selectedRow[0]}” resized successfully!`
      case'scaling':return 'Autoscale added successfully!'
      case'networking':return `Resizing virtual machine “${this.selectedRow[0]}”...`
      case'inbound':return 'Inbound Security Rule added successfully!'
      case'outbound':return 'Outbound Security Rule added successfully!'
      case'loadBalancer':return 'Load balancing added successfully!'
      case'addNic':return 'Network interface added successfully!'
      default: return 'Changes saved successfully!'
    }
  }

  getFailureText(type){
    switch(type){
      case'upgradePolicy':return `${this.selectedRow[0]} policy failed to upgrade.`
      case'healthAndRepair':return `“${this.selectedRow[0]}” health and repair failed to update.` 
      case'configuration':return `Virtual machine scale set “${this.selectedRow[0]}” configuration failed to udate.`
      case'disk':return `“${this.selectedRow[0]}”  Disks failed to update.`
      case'size':return `Virtual machine “${this.selectedRow[0]}” resizing failed.`
      case'scaling':return 'Autoscale failed to be added.'
      case'networking':return `Resizing virtual machine “${this.selectedRow[0]}”...`
      case'inbound':return 'Inbound Security Rule failed to be added.'
      case'outbound':return 'Outbound Security Rule failed to be added.'
      case'loadBalancer':return 'Load balancing failed to be added.'
      case'addNic':return 'Network interface failed to be added.'
      default: return 'Failed to saved.'
    }
  }
  getInProgress(type){
    switch(type){
      case'upgradePolicy':return `Upgrading ${this.selectedRow[0]} policy ...`
      case'healthAndRepair':return `Updating “${this.selectedRow[0]}” health and repair...` 
      case'configuration':return `Updating virtual machine scale set “${this.selectedRow[0]}” configuration...`
      case'disk':return `Updating Disks for “${this.selectedRow[0]}”...`
      case'size':return `Resizing virtual machine “${this.selectedRow[0]}”...`
      case'scaling':return 'Adding autoscale...'
      case'networking':return `Resizing virtual machine “${this.selectedRow[0]}”...`
      case'inbound':return 'Adding Inbound Security Rule...'
      case'outbound':return 'Adding Outbound Security Rule...'
      case'loadBalancer':return 'Adding load balancing...'
      case'addNic':return 'Adding network interface...'
      default: return `Saving changes to ${this.selectedRow[0]}...`
    }
  }
  sortByKey(primaryKey,list){
    let num = 0;
    (this.datasort === 'desc') ? num = 1 : num = -1;
    this.datasort = this.datasort === 'desc' ? 'asc' : 'desc';
    switch(primaryKey){
      case 'vmScaleSet':{
        let sorted=this.data;
        sorted.sort((a,b)=>{
          let x = a[list];
          let y = b[list];
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.data=sorted;
        break;
      }
      case 'size':{
        let sorted=this.dummyVMSizeList;
        sorted.sort((a,b)=>{
          let x = a?.series;
          let y = b?.series;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.dummyVMSizeList=sorted;
        break;
      }
      case 'vCPU':{
        let sorted=this.dummyVMSizeList;
        sorted.sort((a,b)=>{
          let x = a?.capacity?.vCPUs;
          let y = b?.capacity?.vCPUs;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.dummyVMSizeList=sorted;
        break;
      }
      case 'RAM':{
        let sorted=this.dummyVMSizeList;
        sorted.sort((a,b)=>{
          let x = a?.capacity?.Ram;
          let y = b?.capacity?.Ram;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.dummyVMSizeList=sorted;
        break;
      }
      case 'dataDisk':{
        let sorted=this.dummyVMSizeList;
        sorted.sort((a,b)=>{
          let x = a?.capacity?.maxDataDiskCount;
          let y = b?.capacity?.maxDataDiskCount;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.dummyVMSizeList=sorted;
        break;
      }
      case 'Max':{
        let sorted=this.dummyVMSizeList;
        sorted.sort((a,b)=>{
          let x = a?.capacity?.maximum;
          let y = b?.capacity?.maximum;
          return (x < y) ? num : ((x > y) ? -num : 0)
        })
        this.dummyVMSizeList=sorted;
        break;
      }

    }
  }

  invalidForm(type){
    switch(type){
      case'healthAndRepair':{
         return this.inputError?.gracePeriodRange||this.inputError?.gracePeriodVal||this.inputError?.InvalidPortRange||this.inputError?.NotValidPortVal
      }
      case'configuration':{
        return this.inputError?.InvalidPortRange||this.inputError?.NotValidPortVal||((this.currentForm.get('terminationDelay')?.dirty ||  this.currentForm.get('terminationDelay')?.touched) && !this.currentForm.get('terminationDelay')?.value)
     }
     case'scaling':{
      return this.inputError?.validScaleName||this.inputError?.validMinInstance||this.inputError?.minValidInstance||((this.currentForm.get('instanceMin')?.dirty ||  this.currentForm.get('instanceMin')?.touched) && !this.currentForm.get('instanceMin')?.value) ||
      this.inputError?.validMaximumInstance||this.inputError?.validMaxInstance||this.inputError?.maxValidInstance||((this.currentForm.get('instanceMax')?.dirty ||  this.currentForm.get('instanceMax')?.touched) && !this.currentForm.get('instanceMax')?.value) ||
      this.inputError?.validDefInstance||this.inputError?.defValidInstance||((this.currentForm.get('instanceDef')?.dirty ||  this.currentForm.get('instanceDef')?.touched) && !this.currentForm.get('instanceDef')?.value) ||
      this.inputError?.validCount||this.inputError?.validNumber||((this.currentForm.get('scaleInstanceCount')?.dirty ||  this.currentForm.get('scaleInstanceCount')?.touched) && !this.currentForm.get('scaleInstanceCount')?.value) ||
      this.inputError?.defMinValidInstance||this.inputError?.defValidMinInstance||((this.currentForm.get('defInstanceMin')?.dirty ||  this.currentForm.get('defInstanceMin')?.touched) && !this.currentForm.get('defInstanceMin')?.value) ||
      this.inputError?.defValidMaximumInstance|| this.inputError?.defMaxValidInstance||this.inputError?.defValidMaxInstance||((this.currentForm.get('defInstanceMax')?.dirty ||  this.currentForm.get('defInstanceMax')?.touched) && !this.currentForm.get('defInstanceMax')?.value) ||
      this.inputError?.defValidDefInstance||this.inputError?.defaultDefValidInstance||((this.currentForm.get('defInstanceDef')?.dirty ||  this.currentForm.get('defInstanceDef')?.touched) && !this.currentForm.get('defInstanceDef')?.value) ||
      this.inputError?.defvalidNumber||this.inputError?.defvalidCount||((this.currentForm.get('defScaleInstanceCount')?.dirty ||  this.currentForm.get('defScaleInstanceCount')?.touched) && !this.currentForm.get('defScaleInstanceCount')?.value) ||
      this.inputError?.InvalidPortRange||this.inputError?.NotValidPortVal||((this.currentForm.get('overRideInstanceCount')?.dirty ||  this.currentForm.get('overRideInstanceCount')?.touched) && !this.currentForm.get('overRideInstanceCount')?.value)
   }
   case'inOutBound':{
    return this.inputError.sourceInvalidArgument||this.inputError.sourceInvalidPortRange||this.inputError.sourceNotValidCIDR||this.inputError.sourceNotValidPortVal
    ||this.inputError.sourceOverlaping||this.inputError.sourceOverlap || this.inputError.destInvalidArgument||this.inputError.destInvalidPortRange
    ||this.inputError.destNotValidCIDR||this.inputError.destNotValidPortVal ||this.inputError.destOverlaping||this.inputError.destOverlap
    ||this.inputError.VMPriorityValid||this.inputError.VMPriorityMaxMin ||this.inputError.VMPriorityDupCheck||this.inputError.nameDupCheck;
  }
   case'disk':{
    return this.inputError?.InvalidPortRange||this.inputError?.NotValidPortVal|| this.inputError?.lunError
  }
   case'addNIC':{
    return this.inputError?.nsgNameEmpty || this.inputError?.nsgNameLen || this.inputError?.nsgNameDupCheck||this.inputError?.nsgNameValid||this.inputError?.nsgNameLastValid ||
    this.inputError?.nicNameEmpty || this.inputError?.nicNameLen || this.inputError?.nicNameDupCheck||this.inputError?.nicNameValid||this.inputError?.nicNameLastValid
  }

    }
  }
  resetForm(){
    this.currentForm.reset()
  }
  resetSubSideSheetForm(){
    this.subSideSheetForm.reset()
  }
  resetValidation(){
    this.inputError.InvalidPortRange=false
    this.inputError.NotValidPortVal=false
    this.inputError.gracePeriodRange=false
    this.inputError.gracePeriodVal=false
    this.inputError.validScaleName=false
    this.inputError.validNumber=false
    this.inputError.validCount=false
    this.inputError.defvalidNumber=false
    this.inputError.defvalidCount=false
    this.inputError.minValidInstance=false
    this.inputError.maxValidInstance=false
    this.inputError.defValidInstance=false
    this.inputError.validMinInstance=false
    this.inputError.validMaxInstance=false
    this.inputError.validMaximumInstance=false,
    this.inputError.validDefInstance=false
    this.inputError.defMinValidInstance=false
    this.inputError.defMaxValidInstance=false
    this.inputError.defaultDefValidInstance=false
    this.inputError.defValidMinInstance=false
    this.inputError.defValidMaxInstance=false
    this.inputError.defValidMaximumInstance=false
    this.inputError.defValidDefInstance=false

    this.inputError.sourceInvalidArgument=false
    this.inputError.sourceInvalidPortRange=false
    this.inputError.sourceNotValidCIDR=false,
    this.inputError.sourceNotValidPortVal=false
    this.inputError.sourceOverlaping=false
    this.inputError.sourceOverlap=false
    this.inputError.destInvalidArgument=false
    this.inputError.destInvalidPortRange=false
    this.inputError.destNotValidCIDR=false
    this.inputError.destNotValidPortVal=false
    this.inputError.destOverlaping=false
    this.inputError.destOverlap=false
    this.inputError.VMPriorityValid=false
    this.inputError.VMPriorityMaxMin=false
    this.inputError.VMPriorityDupCheck=false
    this.inputError.nameDupCheck=false
    this.inputError.VMdescription=false
    this.inputError.nameLen=false,
    this.inputError.nameValid=false
    this.inputError.poolValid=false
    this.inputError.poolExistValid=false
    this.inputError.lunError=false
  }
  enableAction(body,event,index,type?:any){
    if (event.target.checked) {
      body.checked = true
    }
    else  body.checked = false
    console.log('Check',this.vmscaleSetVMData)
    this.instanceId=[]
    this.disableStart=false
    this.disableStop=false
    if(type!='flexible'){
      this.vmscaleSetVMData.forEach(e=>{
        if(e.body.checked){
          let status=e.body.statuses[1]?.code.split('/')[1].toLowerCase()
          !this.disableStart ?this.disableStart=status=='running'||status=='starting'?true:false :false
          !this.disableStop? this.disableStop=status!='running'&& status!='starting'?true:false :false
         return this.instanceId.push(e.body.instanceId)
        }
      })
    }else {
      this.vmscaleSetVMData.forEach(e=>{
        if(e.body.checked){
          let status=e.body['properties']?.instanceView?.statuses[1]?.code.split('/')[1].toLowerCase()
          !this.disableStart ?this.disableStart=status=='running'||status=='starting'?true:false :false
          !this.disableStop? this.disableStop=status!='running'&& status!='starting'?true:false :false
         return this.instanceId.push(e.body.name)
        }
      })
    }
    if(this.disableStart&&this.disableStop) return this.bothEnable=true
    else return this.bothEnable=false
  }
 
  setRadioBtn(id,type,subType?:any,formArr?:any,index?:any){
  switch(type){
    case 'healthAndRepair':{
      this.saveDisable=true
      switch(subType){
        case 'AHM':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('appHealth').setValue(value)
          if(value){
            this.currentForm.get('AHM').setValidators(Validators.required)
            this.currentForm.get('protocal').setValidators(Validators.required)
            this.currentForm.get('portNumber').setValidators(Validators.required)
            this.currentForm.get('path').setValidators(Validators.required)
            this.currentForm.get('gracePeriod').setValidators(Validators.required)
          }else{
            this.currentForm.get('AHM').setValidators(null)
            this.currentForm.get('protocal').setValidators(null)
            this.currentForm.get('portNumber').setValidators(null)
            this.currentForm.get('path').setValidators(null)
            this.currentForm.get('gracePeriod').setValidators(null)
          }
          this.currentForm.get('AHM').updateValueAndValidity()
          this.currentForm.get('protocal').updateValueAndValidity()
          this.currentForm.get('portNumber').updateValueAndValidity()
          this.currentForm.get('path').updateValueAndValidity()
          this.currentForm.get('gracePeriod').updateValueAndValidity()
        }
        case 'automaticRepair':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('automaticRepair').setValue(value)
          !value &&  !this.currentForm.get('gracePeriod').value ? this.currentForm.get('gracePeriod').setValue(10):false
        }
      }
      break;}

    case 'configuration':{
        this.saveDisable=true
        switch(subType){
          case 'enableTermination':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('enableTermination').setValue(value)
            !value? this.resetValidation():false
            break;
          }
          case 'enableProvisioning':{
            let value = id == 'yes' ? true : false
            this.currentForm.get('enableProvisioning').setValue(value)
            break;
          }
        }
      break;}  

    case 'scaling':{
      this.saveDisable=true
      switch(subType){
        case 'scale':{
          let value = id == 'manual' ? 'manual' : 'custom'
          this.currentForm.get('scale').setValue(value)
          if(value=='custom'){
            this.currentForm.get('autoscaleName').setValidators(Validators.required)
            this.currentForm.get('defScaleConditionName').setValidators(Validators.required)
            // this.resetValidation()
            // this.resetForm()
          }else{
            this.currentForm.get('autoscaleName').setValidators(null)
            this.currentForm.get('defScaleConditionName').setValidators(null)

          }
          this.currentForm.get('autoscaleName').updateValueAndValidity()
          this.currentForm.get('defScaleConditionName').updateValueAndValidity()
          
          break;
        }
        case 'defScaleMode':{
          let value = id == 'yes' ? true : false
          this.currentForm.get('defScaleMode').setValue(value)
          break;
        }
        case 'scaleMode':{
          let value = id == 'yes' ? true : false
          this.scaleConditionArrayList.controls[index].get('scaleMode').setValue(value)
          break;
        }
        case 'schedule':{
          let value = id == 'yes' ? true : false
          this.scaleConditionArrayList.controls[index].get('schedule').setValue(value)
          // formArr.get('schedule').setValue(value)
          break;
        }
      }
    break;} 

    case 'networking':{
      this.saveDisable=true
      this.resetValidation()
      this.popUpForm.get('poolName').reset()
      let value = id == 'new' ? 'new' : 'exist'
      this.popUpForm.get('backendPool').setValue(value)
    break;} 

    case 'networkingNIC':{
      this.saveDisable=true
      switch(subType){
        case 'networkingNSG':{
          this.saveDisable=true
          let value = id == 'none' ? 'None' :id == 'basic'?'Basic':'Advanced'
          this.popUpForm.get('NICNSG').setValue(value)
          if(value=='Basic'){
            this.popUpForm.get('publicInbound').setValue('None')
            this.popUpForm.get('selectInbound').reset()
          }
          break;
        } 
        case 'publicInbound':{
          let value = id == 'none' ? 'None':'Allow'
          this.popUpForm.get('publicInbound').setValue(value)
          if(value=='None'){
            this.popUpForm.get('selectInbound').reset()
          }
          break;
        }
        case 'publicIP':{
          let value = id == 'yes' ? true : false
          this.popUpForm.get('publicIP').setValue(value)
          break;
        }
        case 'acceleratedNetworking':{
          let value = id == 'yes' ? true : false
          this.popUpForm.get('acceleratedNetworking').setValue(value)
          break;
        }
      }
    break;} 

   }
  }

  async setDefaultValue(type){
  switch(type){
    case'healthAndRepair':{
       if(this.vmscaleSetHealthStateData){
        this.currentForm.get('appHealth').setValue(this.vmscaleSetHealthStateData?.properties?.settings?.protocol?true:false)
        this.currentForm.get('AHM').setValue(this.vmscaleSetHealthStateData?.properties?.type.includes('ApplicationHealth')?'extension':'probe')
        this.currentForm.get('protocal').setValue(this.vmscaleSetHealthStateData?.properties?.settings?.protocol)
        this.currentForm.get('portNumber').setValue(this.vmscaleSetHealthStateData?.properties?.settings?.port)
        this.currentForm.get('path').setValue(this.vmscaleSetHealthStateData?.properties?.settings?.requestPath)
      }
        this.currentForm.get('automaticRepair').setValue(this.vmscaleSetData?.properties?.automaticRepairsPolicy?.enabled)
        let min =this.vmscaleSetData?.properties?.automaticRepairsPolicy?.gracePeriod.split('')
        min ? this.currentForm.get('gracePeriod').setValue(min[2]+min[3]):this.currentForm.get('gracePeriod').setValue(10)
      break;
    }
    case 'configuration':{
      if(this.vmscaleSetData?.properties?.virtualMachineProfile?.scheduledEventsProfile&&this.vmscaleSetData?.properties?.virtualMachineProfile?.scheduledEventsProfile?.terminateNotificationProfile){
        let terminationMins=this.vmscaleSetData?.properties?.virtualMachineProfile?.scheduledEventsProfile?.terminateNotificationProfile?.notBeforeTimeout.split('')
        terminationMins=terminationMins.filter(e=>(/^[0-9]*$/).test(e))
        this.currentForm.get('enableTermination').setValue(this.vmscaleSetData?.properties?.virtualMachineProfile?.scheduledEventsProfile?.terminateNotificationProfile?.enable)
        this.currentForm.get('terminationDelay').setValue(terminationMins.length==2?terminationMins[0]+terminationMins[1]:terminationMins[0])
      }else{
        this.currentForm.get('enableTermination').setValue(false)
        this.currentForm.get('terminationDelay').setValue('7')
      }
      this.currentForm.get('proximityGroup').setValue(this.vmscaleSetData?.properties?.proximityPlacementGroup?this.vmscaleSetData?.properties?.proximityPlacementGroup.id:'None')
      this.currentForm.get('enableProvisioning').setValue(this.disableCondition?false:this.vmscaleSetData?.properties?.overprovision)
      this.currentForm.get('secureBoot').setValue(this.disableCondition?true:false)
      this.currentForm.get('vTPM').setValue(this.disableCondition?true:false)
      break;
    }
    case 'disk':{
      this.currentForm.get('hostCatching')?.setValue(this.vmscaleSetData?.properties?.virtualMachineProfile?.storageProfile?.osDisk?.caching)
      this.lunList=[]
      for(let i=0;i<64;i++){
        this.lunList.push({name:i,id:`${i}`})
      }
      let dataDiskArray  = this.vmscaleSetData?.properties?.virtualMachineProfile?.storageProfile?.dataDisks
      if(dataDiskArray&&dataDiskArray.length){
        this.showDataDiskTable=true
        dataDiskArray.forEach((e,i)=>{
          // let filterLUN = this.lunList.filter(x=>x.name!=e.lun)
          this.dataDiskArrayList.insert(0,this.util.getForm('addVMScaleSetDataDiskArray',{
            lunList:this.lunList,
            lun:e?.lun.toString(),
            storageType:e?.managedDisk?.storageAccountType,
            size:e?.diskSizeGB,
           encryption:'EncryptionAtRestWithPlatformKey',
            hostCatchingDisk:e?.caching,
          }))
        })
        this.dataDiskArrayList.controls.splice(this.dataDiskArrayList.controls.length-1, 1)
      }else this.showDataDiskTable=false
      this.saveDisable=false
      this.showSidesheet = true;
      break;
    }
    case 'scaling':{
      this.currentForm.get('scale').setValue('manual')
      this.currentForm.get('overRideInstanceCount').setValue(this.vmscaleSetData?.sku?.capacity)
      this.currentForm.get('mode').setValue('Disabled')
      this.currentForm.get('rg').setValue(this.selectedRow[1])
      this.currentForm.get('defInstanceMin').setValue(2)
      this.currentForm.get('defInstanceMax').setValue(2)
      this.currentForm.get('defInstanceDef').setValue(2)
      this.currentForm.get('defScaleMode').value=true
      if(this.autoScaleData.length){
        this.disableAutoScaleName=true
        this.autoScaleData[0].properties?.enabled ?   this.currentForm.get('scale').setValue('custom') : this.currentForm.get('scale').setValue('manual')
        this.currentForm.get('autoscaleName').setValue(this.autoScaleData[0].name)
        this.currentForm.get('defScaleConditionName').setValue(this.autoScaleData[0].properties.profiles[0].name)
        if(this.autoScaleData[0].properties.profiles[0].capacity.minimum==this.autoScaleData[0].properties.profiles[0].capacity.maximum){
          this.currentForm.get('defScaleMode').setValue(false)
          this.currentForm.get('defScaleInstanceCount').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
          this.currentForm.get('defInstanceMin').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
          this.currentForm.get('defInstanceMax').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
          this.currentForm.get('defInstanceDef').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
        } else{
          this.currentForm.get('defScaleMode').setValue(true)
          this.currentForm.get('defInstanceMin').setValue(this.autoScaleData[0].properties.profiles[0].capacity.minimum)
          this.currentForm.get('defInstanceMax').setValue(this.autoScaleData[0].properties.profiles[0].capacity.maximum)
          this.currentForm.get('defInstanceDef').setValue(this.autoScaleData[0].properties.profiles[0].capacity.default)
        }
        if(this.autoScaleData[0].properties.profiles.length>1){
          let scaleConditionArr=this.autoScaleData[0].properties.profiles.filter(e=>e.fixedDate)
          this.scaleCondition=true
          scaleConditionArr.forEach(e=>{
            let startTimeFormat=this.a3sDateTimeFormat.transform(e.fixedDate.start)
            startTimeFormat=startTimeFormat.split(', ')
            let endTimeFormat=this.a3sDateTimeFormat.transform(e.fixedDate.end)
            endTimeFormat=endTimeFormat.split(', ')
            let scaleMode=e.capacity.minimum==e.capacity.maximum?false:true
            this.scaleConditionArrayList.insert(0,this.util.getForm('addScaleConditonArray',{
              scaleConditionName:e.name,
              scaleMode:scaleMode,
              instanceMin:e.capacity.minimum!=e.capacity.maximum?e.capacity.minimum:null,
              instanceMax:e.capacity.minimum!=e.capacity.maximum?e.capacity.maximum:null,
              instanceDef:e.capacity.minimum!=e.capacity.maximum?e.capacity.default:null,
              scaleInstanceCount:e.capacity.minimum==e.capacity.maximum?e.capacity.minimum:null,
              schedule:true,
              timeZone:e.fixedDate.timeZone,
              startDate:e.fixedDate.start,
              startTime:startTimeFormat[1],
              endDate:e.fixedDate.end,
              endTime:endTimeFormat[1],
            }))
          })
          this.scaleConditionArrayList.controls.splice(this.scaleConditionArrayList.controls.length-1,1)
        } else this.scaleCondition=false

      }
      this.defaultScaleCondition=true 
      break;
    }
    case 'networking':{
      this.ipConfigList=[]
      this.currentNICTab=this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[0].name
      this.currentTab = 'inbound'
      this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[0].properties.ipConfigurations.map(e=>{
        if(e?.properties?.primary){
          this.ipConfigList.push({
            name:`${e.name} (primary)`,
            id:e.name,
          })
        }else{
          this.ipConfigList.push({
            name:e.name,
            id:e.name,
          })
        }
      })
      this.currentForm.get('vNet').setValue(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[0].properties?.ipConfigurations[0]?.properties?.subnet?.id.split('/')[8])
      this.currentForm.get('subnet').setValue(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[0].properties?.ipConfigurations[0]?.properties?.subnet?.id.split('/')[10])
      this.currentForm.get('ipConfig').setValue(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[0].properties?.ipConfigurations[0].name)


      break;
    }


  }
  }
  changeIPConfig(event){
    let selectedIP=this.selectedNIC[0].properties?.ipConfigurations.filter(e=>e.name==event.name)
    this.currentForm.get('vNet').setValue(this.selectedNIC[0].properties?.ipConfigurations[0]?.properties?.subnet?.id.split('/')[8])
    this.currentForm.get('subnet').setValue(this.selectedNIC[0].properties?.ipConfigurations[0]?.properties?.subnet?.id.split('/')[10])
  }
  filterLUNList(event,i){
     this.saveDisable=true
    //  if(this.inputError.lunError){
    //  }
     this.inputError.lunError=false
       this.dataDiskArrayList.value.map((e,index) => {
       if(i!=index&&e.lun!=''){
         if(e.lun==event.name){
          !this.inputError.lunError?this.inputError.lunError=true :false
         }
       }})
    //  let val=[]
    //  for(let i=0;i<64;i++){
    //    val.push({name:i,id:`${i}`})
    //  }
    //  this.dataDiskArrayList.value.map((e,index) => {
    //    this.lunList=val
    //    if(i!=index){
    //     this.lunList= this.lunList.filter(x=>x.name!=event.name)
    //    }
    //    e.lunList=this.lunList
    //  });
  }
  openLBSideSheet(){
    this.showSubSidesheet=true;
    this.showNICSidesheet=false;
    this.popUpForm.reset()
    this.popUpForm.get('lbOptions').setValue('Azure load balancer')
  }
 async openNICSideSheet(){
    this.popUpForm.reset()
    this.popUpForm = this.util.getForm('VMScaleSetNIC')
    this.vNetList=[{
      name:this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations[0].properties.ipConfigurations[0].properties.subnet.id.split('/')[8],
      id:this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations[0].properties.ipConfigurations[0].properties.subnet.id.split('/')[8],
    }]
    this.subnetList=[{
      name:this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations[0].properties.ipConfigurations[0].properties.subnet.id.split('/')[10],
      id:this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations[0].properties.ipConfigurations[0].properties.subnet.id,
    }]
    this.popUpForm.get('vNet').setValue(this.vNetList[0].id)
    this.popUpForm.get('subnet').setValue(this.subnetList[0].id)
    this.popUpForm.get('NICNSG').setValue('None')
    this.popUpForm.get('publicIP').setValue(false)
    await this.getNSGList(this.selectedRow)
    this.showSubSidesheet=true;
    this.showNICSidesheet=true;

  }
  setsize(list,i){
    this.saveDisable=true
    this.dummyVMSizeList.map(e=>e['active']=false)
    list.active=true
    this.currentForm.get('skuName').setValue(list?.sku?.name)
    this.currentForm.get('skuCapacity').setValue(list?.capacity?.defaultCapacity)
  }
  vmSizeListFilter(){
    //let splitArr=this.vmSeries.split(' ');
    let sizeSeries=this.currentForm.get('sizeSeries').value
    let sizeList=this.vmSeriesList.find(dt=>dt.name==sizeSeries).sizeList;
    this.dummyVMSizeList=this.skuSeriesList.filter(e=>sizeList.some(x=>e.sku.name.includes(x)))
    this.dummyVMSizeList.map(e=>{
      this.vmSizeData.forEach(x=>{
        if(e.sku.name==x.name){
          let split=e?.sku?.name.split('_')
          split=split.slice(1)
          e['series']=split.join('_')
          e.capacity['maxDataDiskCount']=x.maxDataDiskCount
          let GIb=(x.memoryInMB/1024).toFixed()
          e.capacity['Ram']=GIb
          e.capacity['vCPUs']=x.numberOfCores
        }
      })
    })
    // this.dummyVMSizeList=this.skuSeriesList.filter(dt=>{
      // let findObj=dt.restrictions.find(ds=>ds.type=="Location");
      // if(!findObj){
      //   dt.newCapabilities={};
      //   dt.newCapabilities.vCPUs=dt.capabilities.find(ds=>ds.name=='vCPUs');
      //   dt.newCapabilities.MemoryGB=dt.capabilities.find(ds=>ds.name=='MemoryGB');
      //   dt.newCapabilities.MaxDataDiskCount=dt.capabilities.find(ds=>ds.name=='MaxDataDiskCount');
      //   dt.newCapabilities.UncachedDiskIOPS=dt.capabilities.find(ds=>ds.name=='UncachedDiskIOPS');
      //   if(sizeList.includes(dt.size)){
      //     if(!dt.newCapabilities.UncachedDiskIOPS&&dt.newCapabilities.MaxDataDiskCount&&dt.newCapabilities.MaxDataDiskCount.value){
      //       dt.newCapabilities.UncachedDiskIOPS={
      //         name:"UncachedDiskIOPS",
      //         value:parseInt(dt.newCapabilities.MaxDataDiskCount.value)*500
      //       }
      //     }
      //     return dt;
      //   }
      // }
    // });
    // this.vmDisk.selectVmDiskSizeData=undefined;
    // this.vmDisk.customDiskSize=undefined;   
}
openNormalModal(template: TemplateRef<any>,name?:any,isEdit?:any){
  this.currentSecurityRules = name 
  this.isSecurityRulesEdit = isEdit
  this.modalService.open(template);
  this.setDefaultBoundRule()
}
getSecurityRulesHeader(){
  switch (this.currentSecurityRules) {
    case 'inbound': return this.isSecurityRulesEdit ? 'Inbound Security Rules' : 'Add Inbound Security Rules' ;
    case 'outbound': {
      return this.isSecurityRulesEdit ? 'Outbound Security Rules' : 'Add Outbound Security Rules'} ;
  }
}
deleteRule(list,type){
  switch(type){
    case 'inbound' :{
      let index = this.nsgData.properties.securityRules.findIndex(e=> e.name == list.name)
      this.nsgData.properties.securityRules.splice(index,1)
      this.UpdateNSGData(this.nsgData.id,this.nsgData,'deleteInbound')
      break;
    }
    case 'outbound' : {
      let index = this.nsgData.properties.securityRules.findIndex(e=> e.name == list.name)
      this.nsgData.properties.securityRules.splice(index,1)
      this.UpdateNSGData(this.nsgData.id,this.nsgData,'deleteOutbound')
      break;
    }
    case 'deleteNIC' : {
      let index = this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.findIndex(e=> e.name == list.name)
      this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.splice(index,1)
      this.updateVMScaleSetData(this.selectedRow,this.vmscaleSetData,'deleteNIC')
      break;
    }
    case 'deleteLB' : {
      let ipName = this.currentForm.get('ipConfig').value
      let nicIndex=_.cloneDeep(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.findIndex(e=>e.name==this.currentNICTab))
      let ipConfigIndex=_.cloneDeep(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations.findIndex(e=>e.name==ipName))
      let index = this.loadBalancerList.findIndex(e=> e.id == list.id)
      this.loadBalancerList.splice(index,1)
      this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations[ipConfigIndex].properties['loadBalancerBackendAddressPools']=this.loadBalancerList
      this.updateVMScaleSetData(this.selectedRow,this.vmscaleSetData,'deleteLBNetworking')
      break;
    }
  }
}
setDefaultBoundRule(){
  this.resetValidation()
 let sorted =_.cloneDeep(this.nsgData.properties.securityRules) 
 sorted.sort((a,b)=>{
        let x = a?.properties?.priority;
        let y = b?.properties?.priority;
        return y-x
      })
  let prio= sorted.length ? sorted[0].properties?.priority + 10 :100
  this.popUpForm.get('source').setValue('Any')
  this.popUpForm.get('destination').setValue('Any')
  this.popUpForm.get('sourcePort').setValue('*')
  this.popUpForm.get('destinationPort').setValue('8080')
  this.popUpForm.get('service').setValue('Custom')
  this.popUpForm.get('action').setValue('Allow')
  this.popUpForm.get('protocol').setValue('*')
  this.popUpForm.get('priority').setValue(prio)
  this.popUpForm.get('name').setValue('Port_8080')
  sorted.forEach(e=>{e.name=='Port_8080'? this.popUpForm.get('name').setValue(''):false})
}
setTime(event,type,index){
  this.saveDisable=true
  if(type=='start'){
    let startTimeFormat=this.a3sDateTimeFormat.transform(event.value)
    startTimeFormat=startTimeFormat.split(', ')
    this.scaleConditionArrayList.controls[index].get('startTime').setValue(startTimeFormat[1])
  }else{
    let endTimeFormat=this.a3sDateTimeFormat.transform(event.value)
    endTimeFormat=endTimeFormat.split(', ')
    this.scaleConditionArrayList.controls[index].get('endTime').setValue(endTimeFormat[1])
  }
}
async saveNSGRuleData(type){
  switch(type){
    case 'inbound':{
      let rawValue = this.popUpForm.getRawValue()
      let sourceIP=rawValue.source=='IP addresses'?rawValue.sourceIP.split(','):false
      let destinationIP=rawValue.destination=='IP addresses'?rawValue.destinationIP.split(','):false
      let sourcePort=rawValue.sourcePort.split(',')
      let destinationPort=rawValue.destinationPort.split(',')
      let body ={
              "name": rawValue.name,
              "properties": {
                "protocol":rawValue.protocol,
                "destinationAddressPrefix": rawValue.destination=="VirtualNetwork"?rawValue.destination:'*',
                "sourceAddressPrefix": rawValue.source=='Any'? '*':rawValue.source,
                "access": rawValue.action,
                "destinationPortRange": rawValue.destinationPort=='Any'?'*':rawValue.destinationPort,
                "sourcePortRange": rawValue.sourcePort=='Any'?'*':rawValue.sourcePort,
                "priority": rawValue.priority,
                "direction": this.currentSecurityRules == 'inbound' ? "Inbound" : "Outbound",
                "description":rawValue.description,
                "sourceAddressPrefixes":[],
                "destinationAddressPrefixes":[],
                "destinationPortRanges":[],
                "sourcePortRanges":[]
              }
            }
            if(rawValue.source=='IP addresses' && sourceIP.length==1){
              body['properties'].sourceAddressPrefix = rawValue.sourceIP;
            }
            else if(rawValue.source=='IP addresses'&&  sourceIP.length!=1){
              body['properties'].sourceAddressPrefixes = sourceIP;
              body['properties'].sourceAddressPrefix = '';
            }
            if(rawValue.destination=='IP addresses'  && destinationIP.length==1){
              body['properties'].destinationAddressPrefix = rawValue.destinationIP=='Any'?'*':rawValue.destinationIP;
            }
            else if(rawValue.destination=='IP addresses'&& destinationIP.length!=1){
              body['properties'].destinationAddressPrefixes = destinationIP;
              body['properties'].destinationAddressPrefix = '';
            }
            if(sourcePort.length==1){
              body['properties'].sourcePortRange = rawValue.sourcePort=='Any'?'*':rawValue.sourcePort;
            }
            else if(sourcePort.length!=1){
              body['properties'].sourcePortRanges = sourcePort;
              body['properties'].sourcePortRange = '';
            }
            if(destinationPort.length==1){
              body['properties'].destinationPortRange = rawValue.destinationPort=='Any'?'*':rawValue.destinationPort;
            }
            else if(destinationPort.length!=1){
              body['properties'].destinationPortRanges = destinationPort;
              body['properties'].destinationPortRange = '';
            }
            await this.UpdateNSGRuleData(this.nsgData.id,body,this.currentSecurityRules)
            await this.util.delay(1000)
            await this.getNSGData('update',this.nsgData.id)
            break;
      }
    }
 }
  async saveVMScaleSetData(type){
    switch(type){
      case'upgradePolicy':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.vmscaleSetData
        body['properties'].upgradePolicy={
                   "mode": rawValue.upgradeMode 
        }   
          this.updateVMScaleSetData(this.selectedRow,body,'upgradePolicy')
          this.saveDisable=false 
        break;
      }
      case'healthAndRepair':{
        let rawValue = this.currentForm.getRawValue()
        let vmBody=this.vmscaleSetData
        let body=this.vmscaleSetHealthStateData
        if(rawValue?.appHealth){
          body={
              "properties":{
                "autoUpgradeMinorVersion": false, 
                "publisher": "Microsoft.ManagedServices", 
                "type": "ApplicationHealthWindows", 
                "typeHandlerVersion": "1.0", 
                "settings": { 
                    "protocol": rawValue?.protocal, 
                    "port": Number(rawValue?.portNumber), 
                    "requestPath": rawValue?.path 
              }
                          
           }           
          } 
          vmBody['properties']['automaticRepairsPolicy']={
            "enabled":rawValue?.automaticRepair,
            "gracePeriod":"PT"+rawValue?.gracePeriod+"M" ,
          }
          // vmBody['properties'].automaticRepairsPolicy.enabled=  rawValue?.automaticRepair
          // vmBody['properties'].automaticRepairsPolicy.gracePeriod=  "PT"+rawValue?.gracePeriod+"M" // "PT30M" 
           await this.updateVMScaleSetHealthState(this.selectedRow,body)
           await this.updateVMScaleSetData(this.selectedRow,vmBody,'healthAndRepair')
        }else {
          delete vmBody['properties'].automaticRepairsPolicy
          // vmBody['properties'].automaticRepairsPolicy.gracePeriod=  "PT10M" 
          await this.updateVMScaleSetData(this.selectedRow,vmBody,'healthAndRepair') 
          await this.deleteVMScaleSetHealthState(this.selectedRow)
        } 
     
        break;

      }
      case'configuration':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.vmscaleSetData
        !this.disableCondition ? body['properties'].overprovision=rawValue.enableProvisioning :false
        body['properties'].proximityPlacementGroup=rawValue.proximityGroup&&rawValue.proximityGroup!='None'?{"id":rawValue.proximityGroup}:{}
        body['properties'].virtualMachineProfile['scheduledEventsProfile']= { 
                    "terminateNotificationProfile": { 
                        "enable": rawValue.enableProvisioning ,  
                        "notBeforeTimeout": rawValue.enableProvisioning?'PT'+rawValue?.terminationDelay+'M':'PT7M' 
                        }              
           } 
          this.updateVMScaleSetData(this.selectedRow,body,'configuration')
          this.saveDisable=false 
        break;
      }
      case'size':{
        let rawValue = this.currentForm.getRawValue()
        let body=this.vmscaleSetData
        body.sku.name=rawValue.skuName
        //body.sku.capacity=rawValue.skuCapacity
          this.updateVMScaleSetData(this.selectedRow,body,'size')
          this.saveDisable=false 
        break;
      }
      case'disk':{
        let rawValue = this.currentForm.getRawValue()
        let diskDataArray  = []
        rawValue.vmScaleSetDataDiskArray.forEach(e=>{
          if(e.lun==0||(e.lun&&e.lun!='')) return diskDataArray.push({
            "lun": Number(e.lun),   
            "createOption": 'Empty', 
            "caching":e.hostCatchingDisk,
            "managedDisk":{
              "storageAccountType":e.storageType
            },
            "diskSizeGB":Number(e.size)
          })
        })
        let body=this.vmscaleSetData
        body['properties'].virtualMachineProfile.storageProfile['dataDisks']=diskDataArray
        body['properties'].virtualMachineProfile.storageProfile.osDisk.caching=rawValue.hostCatching
          this.updateVMScaleSetData(this.selectedRow,body,'disk')
          this.saveDisable=false 
        break;
      }
      case'scaling':{
        let body=this.vmscaleSetData
        let rawValue = this.currentForm.getRawValue()
        if(this.currentTab=='configure'){
            if(rawValue?.scale=='manual'){
              body.sku.capacity=rawValue.overRideInstanceCount
              this.updateVMScaleSetData(this.selectedRow,body)
              if(this.autoScaleData.length){
                this.autoScaleData[0].properties.enabled=false
                this.updateAutoScalingData(this.selectedRow,this.autoScaleData[0],'scaling')
              }
            }else{
              let autoScaleBody={
                "location": this.selectedRow[3], 
                "tags": {}, 
                "properties": { 
                    "name": rawValue?.autoscaleName, 
                    "enabled": true, 
                    "predictiveAutoscalePolicy": { 
                        "scaleMode": "Disabled", 
                        "scaleLookAheadTime": null 
                    }, 
                    "targetResourceUri": body.id,  
                    "profiles":[] ,      
                    "notifications": [] 
                } 
              }
              if(this.defaultScaleCondition){
                autoScaleBody.properties["profiles"].push({ 
                    "name": rawValue?.defScaleConditionName, 
                    "capacity": { 
                        "minimum": rawValue?.defScaleMode? rawValue?.defInstanceMin : rawValue?.defScaleInstanceCount, 
                        "maximum": rawValue?.defScaleMode? rawValue?.defInstanceMax : rawValue?.defScaleInstanceCount, 
                        "default": rawValue?.defScaleMode? rawValue?.defInstanceDef : rawValue?.defScaleInstanceCount 
                    }, 
                    "rules": [] 
                })
              }
              if(this.scaleCondition){
                rawValue.scaleConditonArray.forEach(e=>{
                  autoScaleBody.properties["profiles"].push({ 
                    "name":e?.scaleConditionName, 
                    "capacity": { 
                        "minimum": e?.scaleMode? e?.instanceMin : e?.scaleInstanceCount, 
                        "maximum": e?.scaleMode? e?.instanceMax : e?.scaleInstanceCount,  
                        "default": e?.scaleMode? e?.instanceDef : e?.scaleInstanceCount, 
                    }, 
                    "rules": [], 
                    "fixedDate": { 
                        "timeZone": "UTC", 
                        "start": e?.startDate, 
                        "end": e?.endDate
                    } 
                })
                })
              }
              this.updateAutoScalingData(this.selectedRow,autoScaleBody,'scaling')
            }
        }else{
           body['properties'].scaleInPolicy={
              "rules": [rawValue?.scaleInPolicy] 
           }
           this.updateVMScaleSetData(this.selectedRow,body,'scaling')
        }
        this.showSidesheet=false
        this.saveDisable=false 
        break;
      }
  
   
    }
  
  }
//validation
portRangeValidation(event,limit,type?:any){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError['InvalidPortRange']=false; 
  this.inputError['NotValidPortVal']=false;
  if(value&&value!=""){
    if((!(/^[0-9]*$/).test(value))){
      this.inputError['NotValidPortVal']=true;
    }
    if(type=='terminationDelay'){
      if(value>15 || value<5){
        this.inputError['InvalidPortRange']=true;
      }
    }else if (type=='size'){
      if(value>32767 || value<4){
        this.inputError['InvalidPortRange']=true;
      }
    }
    else if(type=='instanceCount'){
      if(value>1000 || value<1){
        this.inputError['InvalidPortRange']=true;
      }
    }
    else{
      if(value>65535 || value<0){
        this.inputError['InvalidPortRange']=true;
      }
    }
  }
}
portRangePopUpValidation(event,limit,flag){
  let value=event.target.value;
  
  this.inputError[flag+'InvalidPortRange']=false; 
  this.inputError[flag+'Overlap']=false;
  this.inputError[flag+'NotValidPortVal']=false;
  if(value&&value!=""){
    if(value.includes(',-')||value.includes('-,')){
      this.inputError[flag+'NotValidPortVal']=true;
      return;
    }else if(value[value.length-1]==","||value[value.length-1]=="-"){
      this.inputError[flag+'InvalidPortRange']=true;
    }else if(value!="*"&&(!(/^[0-9,-]*$/).test(value))){
      this.inputError[flag+'NotValidPortVal']=true;
    }else if(value>65535 || value<0){
      this.inputError[flag+'NotValidPortVal']=true;
    }
    else{
      let splitArr=value.split(',');
      if(splitArr.length==1){
        let splitArr1=splitArr[0].split('-');
        if(splitArr1.length>1&&(!splitArr1[1]||parseInt(splitArr1[0])>parseInt(splitArr1[1])||splitArr1[0]==splitArr1[1])){
          //Invalid port range specified
          this.inputError[flag+'InvalidPortRange']=true;
          return;
        }else if(splitArr1.length==1&&parseInt(splitArr1[0])>limit){
          this.inputError[flag+'InvalidPortRange']=true;
          return;
        }else if(splitArr1.length>1&&splitArr1[1]&&parseInt(splitArr1[1])>limit){
          this.inputError[flag+'InvalidPortRange']=true;
          return;
        }
      }else if(splitArr.length>1&&!splitArr[1]){
        //Invalid port range specified
        this.inputError[flag+'InvalidPortRange']=true;
        return;
      }else {
        let tempList=[];
        for(let i=0;i<splitArr.length;i++){
          let splitArr1=splitArr[i].split('-');
          if(splitArr1.length>1&&(!splitArr1[1]||parseInt(splitArr1[0])>parseInt(splitArr1[1])||splitArr1[0]==splitArr1[1])){
            //Invalid port range specified
            this.inputError[flag+'InvalidPortRange']=true;
            break;
          }else {//if(splitArr1.length==1){
           this.inputError[flag+'InvalidPortRange']=false; 
           this.inputError[flag+'Overlap']=false;
            for(let j=0;j<tempList.length;j++){
              if(!(tempList[j] instanceof Array)){
              if(splitArr1[0]==tempList[j]){
                this.inputError[flag+'Overlap']=true;
                break;
              }else if(splitArr1[1]==tempList[j]){
                this.inputError[flag+'Overlap']=true;
                break;
              }else if(parseInt(splitArr1[0])<parseInt(tempList[j])&&splitArr1[1]&&parseInt(splitArr1[1])>parseInt(tempList[j])){
                this.inputError[flag+'Overlap']=true;
                break;
              }
            }else if((tempList[j] instanceof Array)){
                if(splitArr1[0]==tempList[j][0]||(tempList[j][1]&&splitArr1[0]==tempList[j][1])){
                this.inputError[flag+'Overlap']=true;
                break;
                }else if(splitArr1[1]&&(splitArr1[1]==tempList[j][0]||(tempList[j][1]&&splitArr1[1]==tempList[j][1]))){
                this.inputError[flag+'Overlap']=true;
                break;
                }else if(splitArr1[0]&&parseInt(splitArr1[0])>parseInt(tempList[j][0])&&parseInt(splitArr1[0])<parseInt(tempList[j][1])){
                this.inputError[flag+'Overlap']=true;
                break;
                }else if(splitArr1[1]&&parseInt(splitArr1[1])<parseInt(tempList[j][0])&&parseInt(splitArr1[1])>parseInt(tempList[j][1])){
                this.inputError[flag+'Overlap']=true;
                break;
                }
                else if(splitArr1[1]>tempList[j][0]&&splitArr1[1]<tempList[j][1]){
                  this.inputError[flag+'Overlap']=true;
                  break;
                }
                else if(splitArr1[0]>tempList[j][0]&&splitArr1[1]<tempList[j][1]){
                  this.inputError[flag+'Overlap']=true;
                  break;
                }
                else{
                  this.inputError[flag+'Overlap']=false;
                }
              }else{
                this.inputError[flag+'Overlap']=false;
              }
            }
            if((splitArr1[0]&&parseInt(splitArr1[0])>limit)||(splitArr1[1]&&parseInt(splitArr1[1])>limit)){
              this.inputError[flag+'NotValidPortVal']=true;
            }
          tempList.push(splitArr1.length>1?splitArr1:splitArr1[0]);
          if(this.inputError[flag+'Overlap']||this.inputError[flag+'InvalidPortRange']||this.inputError[flag+'NotValidPortVal']){
             return false;
           }
          }

        }
      }
      this.inputError[flag+'NotValidPortVal']=false;
    }
  }

}
instanceLimitValidation(event,type,index){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError[type+'ValidInstance']=false; 
  this.inputError['validMinInstance']=false;
  this.inputError['validMaxInstance']=false;
  this.inputError['validMaximumInstance']=false;
  this.inputError['validDefInstance']=false;
  this.scaleConditionArrayList.controls.forEach((e,i)=>{
        if(i==index&&value&&value!=""){
            if((!(/^[0-9]*$/).test(value))){
              this.inputError[type+'ValidInstance']=true;
            }
            if(type=='min'){
              if(value>1000 || value<1){
                this.inputError['validMinInstance']=true;
              }
              this.inputError['validMaxInstance']=Number(this.currentForm.get('instanceMax').value)<value?true:false
            }else if(type=='max'){
              this.inputError['validMaxInstance']=Number(this.currentForm.get('instanceMin').value)>value?true:false
              this.inputError['validMaximumInstance'] = value>1000?true:false
            }else if(type=='def'){
              if(Number(this.currentForm.get('instanceMax').value)>value||Number(this.currentForm.get('instanceMin').value<value)){
                this.inputError['validDefInstance']=true;
              }
            }
        }
  })
 
}
instanceLimitDefaultValidation(event,type){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError[type+'ValidInstance']=false; 
  this.inputError['defValidMinInstance']=false;
  this.inputError['defValidMaxInstance']=false;
  this.inputError['defValidMaximumInstance']=false;
  this.inputError['defValidDefInstance']=false;
  if(value&&value!=""){
    if((!(/^[0-9]*$/).test(value))){
      this.inputError[type+'ValidInstance']=true;
    }
    if(type=='defMin'){
      if(value>1000 || value<1){
        this.inputError['defValidMinInstance']=true;
      }
      this.inputError['defValidMaxInstance']=Number(this.currentForm.get('defInstanceMax').value)<value?true:false
    }else if(type=='defMax'){
      this.inputError['defValidMaxInstance']=Number(this.currentForm.get('defInstanceMin').value)>value?true:false
      this.inputError['defValidMaximumInstance'] = value>1000?true:false
    }else if(type=='defaultDef'){
      if(Number(this.currentForm.get('defInstanceMax').value)>value||Number(this.currentForm.get('defInstanceMin').value<value)){
        this.inputError['defValidDefInstance']=true;
      }
    }
  }
}
gracePeriodValidation(event){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError['gracePeriodRange']=false; 
  this.inputError['gracePeriodVal']=false;
  if(value&&value!=""){
    if((!(/^[0-9]*$/).test(value))){
      this.inputError['gracePeriodVal']=true;
    }else if(value>90 || value<10){
      this.inputError['gracePeriodRange']=true;
    }
  }
}
validAutoscaleName(event){
  let value=event.target.value;
  this.saveDisable=true
  let regex=/\,|\%|\&|\>|\<|\#|\.|\?|\/|\\|\s/;
  if(regex.test(value)){
    this.inputError['validScaleName']=true;
  }else this.inputError['validScaleName']=false;
}
validInstanceCount(event,type){
  let value=event.target.value;
  this.saveDisable=true
  this.inputError[type+'validNumber']=false; 
  this.inputError[type+'validCount']=false;
  if(value&&value!=""){
    if((!(/^[0-9]*$/).test(value))){
      this.inputError[type+'validNumber']=true;
    }
    if(value>1000 || value<1){
      this.inputError[type+'validCount']=true;
    }
  }
}
validateInOutBoundName(event,limit){
  let value=event.target.value;
  let existNsgData = this.nsgData.properties.securityRules
  this.inputError.nameLen=false;
  this.inputError.nameValid=false;
  this.inputError.nameDupCheck=false;
  if(value){
    if(value.length>limit){
      this.inputError.nameLen=true;
      return;
    }
    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError.nameValid = false;
    }
    else{
      this.inputError.nameValid = true;
      return;
    }

    for(let input in value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError.nameValid = true;
        return;
      }
    }
    let findObj=existNsgData.find((dt,index)=>dt.name==value)
            if(findObj){
              this.inputError.nameDupCheck=true;
              return;
            }
  }
}
VmDescription(limitField, limitNum){
  let value =limitField.target.value
  if (value.length > limitNum) {
      this.inputError.VMdescription = true;
  }
  else this.inputError.VMdescription = false;
}
isCharALetter(char){
  return (/[a-zA-Z]/).test(char);
}
isCharANumber(char){
  return (/[0-9]/).test(char);
}
poolValidation(event,type?:any){
  this.inputError.poolExistValid=false,
  this.inputError.poolValid=false
  if(type=='text'){
   let val=event.target.value
   let array=this.backendPoolList.filter(e=>e.name==val)
   this.inputError.poolExistValid=array.length?true:false
  }else{
    let array=this.loadBalancerList.filter(e=>e.id==event.id)
    this.inputError.poolValid=array.length?true:false
  }
}
validateVMPriority(event){
  let value=event.target.value;
  let existNsgData = this.nsgData.properties.securityRules
  this.inputError.VMPriorityValid=false;
  this.inputError.VMPriorityMaxMin=false;
  this.inputError.VMPriorityDupCheck=false;
  if(value){
    if(!(/^[0-9]*$/).test(value)){
      this.inputError.VMPriorityValid=true;
      return;
    }else if(parseInt(value)<100||parseInt(value)>4096){
      this.inputError.VMPriorityMaxMin=true;
    }
    let inboundCheck =existNsgData.filter(e=>e.properties.direction=='Inbound')
     if(inboundCheck && inboundCheck.length && this.currentSecurityRules=='inbound'){
      
      let findObj=inboundCheck.find((dt,index)=>dt.properties.priority==value);
      if(findObj){
        this.inputError.VMPriorityDupCheck=true;
        // return;
      }
      let name=this.currentForm.get('name').value
      let findname=inboundCheck.find((dt,index)=>dt.name==name);
      if(value==findname.properties.priority){
        this.inputError.VMPriorityDupCheck=false;
      }

    }
    let outnboundCheck =existNsgData.filter(e=>e.properties.direction=='Outbound')
    if(outnboundCheck && outnboundCheck.length && this.currentSecurityRules=='outbound'){
      let findObj=outnboundCheck.find((dt,index)=>dt.properties.priority==value);
      if(findObj){
        this.inputError.VMPriorityDupCheck=true;
        return;
      }
    }
  }
}
getRgBySubscription(){
  let body={
      subscriptionId : this.selectedRow[2],
  }
  return this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
      console.log('Resource Group list ',res);
      this.ResourceGroupsList = res && res.value ? res.value : []
  })
}
setProtocol(value){
  this.popUpForm.get('protocol').setValue(value)
}

setAction(value){
  this.popUpForm.get('action').setValue(value)
}
validNICAddressSpace(event,flag){
  let value=event.target.value;
  this.inputError[flag+'InvalidArgument']=false;//sourceInvalidArgument
  this.inputError[flag+'NotValidCIDR']=false;//sourceNotValidCIDR
  this.inputError[flag+'Overlaping']=false;//sourceOverlaping
  this.inputError[flag+'NonNullRange']=false;

  if(value&&value!=""){
  const splitArComma=value.split(',');
  let ipV4Value;
  let tempArr=[];
  for(let i=0;i<splitArComma.length;i++){
    if(!splitArComma[i]){
     // let inputError="Invalid argument: 'A non-null address range is required.'";
      this.inputError[flag+'NonNullRange']=true;
      break;
    }else{
      const splitArr=splitArComma[i].split('/');
      if(splitArr.length>1&&!splitArr[1]){
        //Maliform
        this.inputError[flag+'NotValidCIDR']=true;
      }else{
        ipV4Value=splitArr[0];
      }
      
      try{
        let ipV4Obj=new this.ipV4(ipV4Value);
       // item.VNetAdrspc=false;
        let vlIPRange;
        if(splitArr.length>1){
        try{
          vlIPRange=new this.ipSubnetWork(ipV4Obj,splitArr[1]);
          //item.VNetAdrspc=false;
          if(vlIPRange.input!=splitArComma[i]){
            //item.notValidCIDR=true;
            //item.validCIDRValue=vlIPRange.input;
            this.inputError[flag+'NotValidCIDR']=true;
            break;
          }else{
            //item.notValidCIDR=false;
            //item.validCIDRValue=null;
            for(let t=0;t<tempArr.length;t++){
              if(tempArr[t].includes("/")){
                let split2=tempArr[t].split('/');
                let range=new this.ipSubnetWork(new this.ipV4(split2[0]),split2[1]);
                if(splitArr.length==1){
                  let splitIPv4=ipV4Value.split('.');
                  if(ipV4Value==range.input){
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                  if(splitIPv4[0]>range.range.left.parts[0]&&splitIPv4[0]<range.range.right.parts[0]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]>range.range.left.parts[1]&&splitIPv4[1]<range.range.right.parts[1]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]>range.range.left.parts[2]&&splitIPv4[2]<range.range.right.parts[2]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]==range.range.left.parts[2]&&splitIPv4[3]>range.range.left.parts[3]&&splitIPv4[3]<range.range.right.parts[3]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                }else{
                  if(vlIPRange.input==range.input){
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                  if(range.range.left.parts[0]>vlIPRange.range.left.parts[0]&&range.range.right.parts[0]<vlIPRange.range.right.parts[0]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]>vlIPRange.range.left.parts[1]&&range.range.right.parts[1]<vlIPRange.range.right.parts[1]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]>vlIPRange.range.left.parts[2]&&range.range.right.parts[2]<vlIPRange.range.right.parts[2]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]==vlIPRange.range.left.parts[2]&&range.range.left.parts[3]>vlIPRange.range.left.parts[3]&&range.range.right.parts[3]<vlIPRange.range.right.parts[3]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                }
              }else if(vlIPRange&&(vlIPRange.range.left.input==tempArr[t]||vlIPRange.range.right.input==tempArr[t])){
                //overlapping
                this.inputError[flag+'Overlaping']=true;
                break;
              }else if(splitArr.length==1&&(ipV4Value==tempArr[t])){
                //overlapping
                this.inputError[flag+'Overlaping']=true;
                break;
              }
            }
            if(this.inputError[flag+'Overlaping']){
              return false;
            }
            tempArr.push(splitArComma[i]);
          }
        }catch(e){
          //item.VNetAdrspc=true;
          this.inputError[flag+'NotValidCIDR']=true;
          break;
        }
      }else{
        for(let t=0;t<tempArr.length;t++){
              if(tempArr[t].includes("/")){
                let split2=tempArr[t].split('/');
                let range=new this.ipSubnetWork(new this.ipV4(split2[0]),split2[1]);
                if(splitArr.length==1){
                  let splitIPv4=ipV4Value.split('.');
                  if(ipV4Value==range.input){
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                  if(splitIPv4[0]>range.range.left.parts[0]&&splitIPv4[0]<range.range.right.parts[0]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]>range.range.left.parts[1]&&splitIPv4[1]<range.range.right.parts[1]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]>range.range.left.parts[2]&&splitIPv4[2]<range.range.right.parts[2]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(splitIPv4[0]==range.range.left.parts[0]&&splitIPv4[1]==range.range.left.parts[1]&&splitIPv4[2]==range.range.left.parts[2]&&splitIPv4[3]>range.range.left.parts[3]&&splitIPv4[3]<range.range.right.parts[3]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                }else{
                  if(vlIPRange.input==range.input){
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                  if(range.range.left.parts[0]>vlIPRange.range.left.parts[0]&&range.range.right.parts[0]<vlIPRange.range.right.parts[0]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]>vlIPRange.range.left.parts[1]&&range.range.right.parts[1]<vlIPRange.range.right.parts[1]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]>vlIPRange.range.left.parts[2]&&range.range.right.parts[2]<vlIPRange.range.right.parts[2]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }else if(range.range.left.parts[0]==vlIPRange.range.left.parts[0]&&range.range.left.parts[1]==vlIPRange.range.left.parts[1]&&range.range.left.parts[2]==vlIPRange.range.left.parts[2]&&range.range.left.parts[3]>vlIPRange.range.left.parts[3]&&range.range.right.parts[3]<vlIPRange.range.right.parts[3]){
                    //overlapping
                    this.inputError[flag+'Overlaping']=true;
                    break;
                  }
                }
              }else if(vlIPRange&&(vlIPRange.range.left.input==tempArr[t]||vlIPRange.range.right.input==tempArr[t])){
                //overlapping
                this.inputError[flag+'Overlaping']=true;
                break;
              }else if(splitArr.length==1&&(ipV4Value==tempArr[t])){
                //overlapping
                this.inputError[flag+'Overlaping']=true;
                break;
              }
            }
            if(this.inputError[flag+'Overlaping']){
              return false;
            }
        tempArr.push(ipV4Value);
      }        
      }catch(e){
     //   item.VNetAdrspc=true;
     this.inputError[flag+'InvalidArgument']=true;
        break;
      }
    }
  }
}
}
validIPName(event,limitNum,subType?:any){
  let value =event.target.value;
  // this.diskName=value
  this.inputError[subType+'NameDupCheck'] = false;
  this.inputError[subType+'NameEmpty'] = false;
  this.inputError[subType+'NameLen'] = false;
  this.inputError[subType+'NameValid'] = false;
  this.inputError[subType+'NameLastValid'] = false;
 if(value==''){
  this.inputError[subType+'NameEmpty'] = true;
 }else{ this.inputError[subType+'NameEmpty'] = false;}
    if (value.length > limitNum) {
      this.inputError[subType+'NameLen'] = true;
      return;
    }
    else {
      this.inputError[subType+'NameLen'] = false;
    }
  if(value.length==1){
    if(/^[A-Za-z0-9]*$/.test(value)){
      this.inputError[subType+'NameValid']=false;
    }
    else{
      this.inputError[subType+'NameValid']=true;
    }
  }
  // else this.inputError[flag+'Valid']=false;
  if(/^[A-Za-z0-9_]+$/.test(value[value.length-1]) && !this.inputError[subType+'NameValid']){
    this.inputError[subType+'NameLastValid']=false;
  }
  else{
    this.inputError[subType+'NameLastValid']=true;
  }
  switch(subType){
    case 'nic':{
      this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations.forEach(e=>{
        if(e.name==value){
          this.inputError[subType+'NameDupCheck'] = true;
          return;
        }
      })
    }
    case 'nsg':{
      this.nsgList.forEach(e=>{
        if(e.name==value){
          this.inputError[subType+'NameDupCheck'] = true;
          return;
        }
    })
  }
}
}
onToggle(type){
  switch(type){
    case 'nsg' : {
      return this.showNsgPopup = !this.showNsgPopup
    }
  }
}
createNSG(){
  let nsgName=this.popUpForm.get('nsgName').value
  let nsgID=`/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/networkSecurityGroups/${nsgName}`
  let nsg=[nsgName,nsgID]
  this.getNSGList(this.selectedRow,'newNSG',nsg)

  this.onToggle('nsg')
}
changeVmService(){
  if(this.popUpForm.get('service').value=='HTTP'){
    this.popUpForm.get('destinationPort').setValue('80');
    this.popUpForm.get('protocol').setValue('TCP');
    // this.vmInboundRule.disableBasedOnService=true;
  }else if(this.popUpForm.get('service').value=='HTTPS'){
    this.popUpForm.get('destinationPort').setValue('443');
    this.popUpForm.get('protocol').setValue('TCP');
 
  }else if(this.popUpForm.get('service').value=='SSH'){
    this.popUpForm.get('destinationPort').setValue('22');
    this.popUpForm.get('protocol').setValue('TCP');

  }else if(this.popUpForm.get('service').value=='RDP'){
    this.popUpForm.get('destinationPort').setValue('3389');
    this.popUpForm.get('protocol').setValue('TCP');
 
  }else if(this.popUpForm.get('service').value=='MS SQL'){
    this.popUpForm.get('destinationPort').setValue('1433');
    this.popUpForm.get('protocol').setValue('TCP');

  }else if(this.popUpForm.get('service').value=='MySQL'){
    this.popUpForm.get('destinationPort').setValue('3306');
    this.popUpForm.get('protocol').setValue('TCP');

  }else if(this.popUpForm.get('service').value=='DNS(TCP)'||this.popUpForm.get('service').value=='DNS(UDP)'){
    this.popUpForm.get('destinationPort').setValue('53');
    this.popUpForm.get('protocol').setValue('TCP');

  }else if(this.popUpForm.get('service').value=='Custom'){
    this.popUpForm.get('destinationPort').setValue('8080');
    this.popUpForm.get('protocol').setValue('*');

  }else{
    this.popUpForm.get('destinationPort').setValue(undefined);
    this.popUpForm.get('protocol').setValue('*');
    // this.vmInboundRule.disableBasedOnService=false;
  }
}
async addBackendPool(){
  let lbData=this.lbSelectorList.filter(e=>e.id==this.popUpForm.get('lbSelector').value)
  let rawValue=this.popUpForm.getRawValue()
  let subsId,rg,lbname
  if(rawValue.backendPool=='new'){
    subsId= rawValue.lbSelector.split('/')[2]
    rg= rawValue.lbSelector.split('/')[4]
    lbname= rawValue.lbSelector.split('/')[8]
    lbData[0].properties['backendAddressPools'].push({
        "name": rawValue.poolName, 
        "id": `/subscriptions/${subsId}/resourceGroups/${rg}/providers/Microsoft.Network/loadBalancers/${lbname}/backendAddressPools/${rawValue.poolName}`
    })
    await this.updateLBConfig(subsId,lbData[0],rg,lbname,'loadBalancer')
  }
    let ipName = this.currentForm.get('ipConfig').value
    let nicIndex=_.cloneDeep(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.findIndex(e=>e.name==this.currentNICTab))
    let ipConfigIndex=_.cloneDeep(this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations.findIndex(e=>e.name==ipName))
    if(  !this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations[ipConfigIndex].properties.loadBalancerBackendAddressPools){
      this.vmscaleSetData.properties.virtualMachineProfile.networkProfile.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations[ipConfigIndex].properties['loadBalancerBackendAddressPools']=[]
    }
    if(rawValue.backendPool=='new'){
      this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations[ipConfigIndex].properties['loadBalancerBackendAddressPools'].push({
        "id": `/subscriptions/${subsId}/resourceGroups/${rg}/providers/Microsoft.Network/loadBalancers/${lbname}/backendAddressPools/${rawValue.poolName}`
      })
    }else{
      this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations[nicIndex].properties.ipConfigurations[ipConfigIndex].properties['loadBalancerBackendAddressPools'].push({
      "id":rawValue.poolName
      })
    }
    await this.updateVMScaleSetData(this.selectedRow,this.vmscaleSetData,'loadBalancer')
    this.changeNetworkingTab('loadBalancing','')
}
async addNICtoVM(){
  let rawValue=this.popUpForm.getRawValue()
  let nsgName=this.nsgList.filter(e=>e.id==rawValue.configNSG)
  if(rawValue.NICNSG=='Basic'||nsgName[0]?.name.includes('New')){
    let nsgBody={
      "location": this.selectedRow[3], 
      "properties": { 
          "securityRules": []
      } 
  } 
  await this.UpdateNSGData(this.selectedRow,nsgBody,'createNewNSG')
  }
  let body= { 
              "name": rawValue.nicName, 
               "properties": { 
                        "primary": false, 
                         "enableAcceleratedNetworking": false, 
                          "dnsSettings": { 
                              "dnsServers": [] 
                          }, 
                          "enableIPForwarding": rawValue?.publicIP, 
                          "ipConfigurations": [ 
                               { 
                                 "name": rawValue.nicName+"-defaultIpConfiguration", 
                                 "properties": { 
                                 "primary": true, 
                                 "subnet": { 
                                     "id": rawValue.subnet
                                  }, 
                                "privateIPAddressVersion": "IPv4" 
                            } 
                         } 
                      ] 
                   } 
      } 
      if(rawValue.NICNSG!="None"){
        body['properties']['networkSecurityGroup']={
          "id":rawValue.NICNSG=='Basic'?`/subscriptions/${this.selectedRow[2]}/resourceGroups/${this.selectedRow[1]}/providers/Microsoft.Network/networkSecurityGroups/${"basicNsg"+rawValue.nicName}`:rawValue?.configNSG
         }
        }else delete  body['properties']['networkSecurityGroup']
  this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.push(body)
  this.updateVMScaleSetData(this.selectedRow,this.vmscaleSetData,'addNic')
}
  //API's
   getVMScaleSetData(data,type,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vmScaleSetName = data[0]
    return this.util.handleRequest('get','a3s_management_getVMScaleSetData',[subsId,rgName,vmScaleSetName],null,null,null,true).then(async res=>{
      this.vmscaleSetData = res.body
      switch(type){
        case'disableCondition':{
         this.disableCondition=this.vmscaleSetData?.properties?.orchestrationMode=='Flexible'?true:false
        }
      }
      console.log('VM Scale Set Data',this.vmscaleSetData)
    })
  }
  getVMScaleSetVMList(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vmScaleSetName = data[0]
    this.instanceId=[]
    return this.util.handleRequest('get','a3s_management_getVMScaleSetVMList',[subsId,rgName,vmScaleSetName],null,null,null,true).then(res=>{
      this.vmscaleSetVMList= res.body.value
      console.log('VM List',this.vmscaleSetVMList)
     if(this.vmscaleSetData?.properties?.orchestrationMode=='Flexible') {
      Promise.all(this.vmscaleSetVMList.map(async e=>{
        let vmName=e.name 
        return this.util.handleRequest('get','a3s_management_getVMScaleSetVMStatus',[subsId,rgName,vmName],null,null,null,true)
      })).then(final=>{
        final.forEach(res=>{
          let status=res['body']?.properties?.instanceView?.statuses[1]?.code.split('/')[1].toLowerCase()
          !this.disableStart? this.disableStart=status=='running'||status=='starting'?true:false :false
          !this.disableStop? this.disableStop=status!='running'&&status!='starting'?true:false :false
        })
        this.vmscaleSetVMData=final
        console.log('VM List data',final)
        if(this.disableStart&&this.disableStop) return this.bothEnable=true
        else return this.bothEnable=false
      })
    
     } else {
      Promise.all(this.vmscaleSetVMList.map(async e=>{
        let instanceID=e.instanceId 
        return this.util.handleRequest('get','a3s_management_getVMStatusWithInstanceID',[subsId,rgName,vmScaleSetName,instanceID],null,null,null,true)
      })).then(final=>{
        final.map((res,ind)=>{
          !this.disableStart? this.disableStart=res['body']?.statuses.length<2?true:false :false
          let status=res['body']?.statuses[1]?.code.split('/')[1].toLowerCase()
          !this.disableStart? this.disableStart=status=='running'||status=='starting'?true:false :false
          !this.disableStop? this.disableStop=status!='running'&&status!='starting'?true:false :false
          this.vmscaleSetVMList.map((list,i)=>{
           // list?.properties?.osProfile?.computerName.toLowerCase()==res['body']?.computerName.toLowerCase() ||
            if( ind==i){
              res['body'].name=list?.name
              res['body'].computerName=list?.properties?.osProfile?.computerName
              res['body'].instanceId=list?.instanceId
              res['body'].latestModel=list?.properties?.latestModelApplied
              res['body'].checked=false
            }
          })
        })
        this.vmscaleSetVMData=final
        console.log('VM List data',this.vmscaleSetVMData)
        console.log('VM List',this.vmscaleSetVMList)
        if(this.disableStart&&this.disableStop) return this.bothEnable=true
        else return this.bothEnable=false
      })
   
     }
    })
  }

  // getVMScaleSetVMStatus(data,type?:any){
  //   let subsId = data[2]
  //   let rgName = data[1]
  //   let vmScaleSetName = data[0]
  //   return this.util.handleRequest('get','a3s_management_getVMScaleSetVMStatus',[subsId,rgName,vmScaleSetName],null,null,null,true).then(res=>{
  //     this.vmscaleSetData = res.body
  //     console.log('VM Status',this.vmscaleSetData)
  //   })
  // }
  // getInstanceViewStatus(data,type?:any){
  //   let subsId = data[2]
  //   let rgName = data[1]
  //   let vmScaleSetName = data[0]
  //   return this.util.handleRequest('get','a3s_management_getInstanceViewStatus',[subsId,rgName,vmScaleSetName],null,null,null,true).then(res=>{
  //     this.vmscaleSetData = res.body
  //     console.log('VM List',this.vmscaleSetData)
  //   })
  // }
  updateVMScaleSetData(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let vmScaleSetName = data[0]
    type?this.util.info(this.getInProgress(type)):false
    return this.util.handleRequest('post','a3s_management_updateVMScaleSetData',[subsId,rgName,vmScaleSetName],body,null,null,true).then(res=>{
      console.log('VPN Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      type?this.util.success(this.getSuccesText(type)):false
    },err=>{
      if(type=='addNic'){
        let index = this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.findIndex(e=> e.name== this.popUpForm.get('nicName').value)
        this.vmscaleSetData?.properties?.virtualMachineProfile?.networkProfile?.networkInterfaceConfigurations.splice(index,1)
      }
      type?this.util.error(this.getFailureText(type)):false
    })
  }
 
  deleteVMScaleSetData(data){
    let subscriptionId = data[2],resourceGroupName = data[1],vmScaleSetName = data[0]
    this.util.info(`Deleting ${data[0] }...`)
    return this.util.handleRequest('delete','a3s_management_deleteVMScaleSetData',[subscriptionId,resourceGroupName,vmScaleSetName],null,null,null,true).then(async res=>{
      this.closeModal()
        let index = this.data.findIndex(e=> e[0] == this.selectedRow[0])
        this.data.splice(index,1)
      this.util.success(data[0] + ' deleted successfully!')
    },err=>{
      this.util.error(data[0] +' failed to deleted.')
    })
  }
  getVMScaleSetHealthState(data,type?:any,subData?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vmScaleSetName = data[0]
    return this.util.handleRequest('get','a3s_management_getVMScaleSetHealthState',[subsId,rgName,vmScaleSetName],null,null,null,true).then(async res=>{
      this.vmscaleSetHealthStateData = res.body
      this.setDefaultValue('healthAndRepair')
      console.log('VM Scale Set Healt State Data',this.vmscaleSetHealthStateData)
    },err=>{
      this.vmscaleSetHealthStateData =[]
      this.currentForm.get('appHealth').setValue(false)
      this.currentForm.get('AHM').setValue('extension')
      this.currentForm.get('protocal').setValue('http')
      this.currentForm.get('portNumber').setValue(80)
      this.currentForm.get('path').setValue('/')
      this.currentForm.get('automaticRepair').setValue(false)
      this.currentForm.get('gracePeriod').setValue(10)
      this.showSidesheet=true
      console.log('error',err)
    })
  }
  updateVMScaleSetHealthState(data,body,type?:any){
    let subsId = data[2]
    let rgName =  data[1] 
    let vmScaleSetName = data[0]
    //this.util.info(this.getInProgress(type))
    return this.util.handleRequest('post','a3s_management_updateVMScaleSetHealthState',[subsId,rgName,vmScaleSetName],body,null,null,true).then(res=>{
      console.log('Updated Health State',res)
      this.showSubSidesheet = false
      this.closeModal()
      // this.closeSidesheet()
      //this.util.success(this.getSuccesText(type))
    },err=>{
     // this.util.error(this.getFailureText(type))
    })
  }
 
  deleteVMScaleSetHealthState(data){
    let subscriptionId = data[2],resourceGroupName = data[1],vmScaleSetName = data[0]
    return this.util.handleRequest('delete','a3s_management_deleteVMScaleSetHealthState',[subscriptionId,resourceGroupName,vmScaleSetName],null,null,null,true).then(async res=>{
    },err=>{
     console.log('error',err)
    })
  }

  getProximityPlacementGroupsList(data,type?:any){
    let subsId = data[2]
    return this.util.handleRequest('get','a3s_management_getProximityPlacementGroupsList',[subsId],null,null,null,true).then(res=>{
      this.proximityGroupList = res.body.value
      this.proximityGroupList=this.proximityGroupList.filter(e=>e.location==this.selectedRow[3])
      this.proximityGroupList.unshift({name:'None',id:'None'})
      console.log('Proximity Group List',this.proximityGroupList)
    })
  }
  getSkuSeriesList(data,type?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vmScaleSetName = data[0]
    return this.util.handleRequest('get','a3s_management_getSKUSeriesList',[subsId,rgName,vmScaleSetName],null,null,null,true).then(res=>{
      this.skuSeriesList = res.body.value
      let check= _.groupBy(this.skuSeriesList, "sku.name");
      console.log('Proximity Group List',this.proximityGroupList)
    })
  }
  getVMSizeList(data,type?:any){
    let subsId = data[2]
    let location = data[3]
    return this.util.handleRequest('get','a3s_management_getVMSize',[subsId,location],null,null,null,true).then(res=>{
      this.vmSizeData= res.body.value
      console.log('VM Size List',this.vmSizeData)
    })
  }
  getAutoScalingData(data,type?:any){
    let subsId = data[2]
    let rgName = data[1]
    let vmScaleSetName = data[0]
    return this.util.handleRequest('get','a3s_management_getAutoScalingData',[subsId,rgName,vmScaleSetName],null,null,null,true).then(res=>{
      let autoscaleList = res.body.value
      this.autoScaleData=autoscaleList.filter(e=>e.properties.targetResourceUri.toLowerCase()==this.vmscaleSetData.id.toLowerCase())
      this.disableAutoScaleName=false
      console.log('autoscale List',autoscaleList)
    })
  }
  updateAutoScalingData(data,body,type?:any){
    let subsId = data[2]
    let rgName = data[1]
    let autoScaleName = this.currentForm.get('autoscaleName').value
    this.util.info(this.getInProgress(type))
    return this.util.handleRequest('post','a3s_management_updateAutoScalingData',[subsId,rgName,autoScaleName],body,null,null,true).then(res=>{
      console.log('Updated auto scale data',res)
      this.closeModal()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  async getNSGData(type,data){
    let subsId,rg,nsgName
    if(type!='update'){
      subsId=data[0]
      rg=data[1]
      nsgName=data[2]
    }else{
      subsId=data.split('/')[2]
      rg=data.split('/')[4]
      nsgName=data.split('/')[8]
    }

    return this.util.handleRequest('get','a3s_management_getNSGData',[subsId,rg,nsgName],null,null,null,true).then(res=>{
      this.nsgData = res.body
      let sorted = this.nsgData.properties.securityRules
      sorted.sort((a,b)=>{
             let x = a?.properties?.priority;
             let y = b?.properties?.priority;
             return x-y
           })
      this.nsgData.properties.securityRules = sorted
      console.log('NSG Data',this.nsgData)
    })
  }
  UpdateNSGData(data,body,type?:any){
    let subsId,rg,nsgName
    if(type=='createNewNSG'){
      let rawValue=this.popUpForm.getRawValue()
      subsId=data[2]
      rg=data[1]
      nsgName=  rawValue.NICNSG=='Basic'?"basicNsg"+rawValue.nicName:rawValue.nsgName
    }else{
      subsId=data.split('/')[2]
      rg=data.split('/')[4]
      nsgName=data.split('/')[8]
    }
    return this.util.handleRequest('post','a3s_management_updateNSGData',[subsId,rg,nsgName],body,null,null,true).then(res=>{
      console.log('NSG Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      type!='createNewNSG'?this.util.success(this.getSuccesText(type)):false
    },err=>{
      type!='createNewNSG'?this.util.error(this.getFailureText(type)):false
    })
  }
  UpdateNSGRuleData(data,body,type){
    let subsId=data.split('/')[2]
    let rg=data.split('/')[4]
    let nsgName=data.split('/')[8]
    let ruleName = body.name
    this.util.info(this.getInProgress(type))
    this.util.handleRequest('post','a3s_management_updateNSGRuleData',[subsId,rg,nsgName,ruleName],body,null,null,true).then(res=>{
      console.log('NSG Rule Data',res)
      this.showSubSidesheet = false
      this.closeModal()
      this.util.success(this.getSuccesText(type))
    },err=>{
      this.util.error(this.getFailureText(type))
    })
  }
  getNSGList(data,type?:any,newNSG?:any){
    let subsId = data[2]
    let rg=data[1]
    return this.util.handleRequest('get','a3s_management_getNSGlistBsRG',[subsId,rg],null,null,null,true).then(res=>{
      this.nsgList= res.body.value
      this.nsgList=  this.nsgList.filter(e=>e.location==this.selectedRow[3])
      if(type=='newNSG'){
        this.nsgList.push({
          name:`(New) ${newNSG[0]}`,
          id:newNSG[1],
        })
        this.popUpForm.get('configNSG').setValue(newNSG[1]);
      }
      console.log('NSG List based on RG',this.nsgList)
    })
  }
  getLoadBalancerList(data,type?:any){
    let subsId = data[2]
    return this.util.handleRequest('get','a3s_management_getLBList',[subsId],null,null,null,true).then(res=>{
      this.lbSelectorList= res.body.value
      console.log('LB List',this.lbSelectorList)
    })
  }
  getLBConfig(subId,rgName,IpName){
    // let subsId = data[2]
    // let rgName = data[1]
    let LBName = this.popUpForm.get('lbSelector').value
    this.util.handleRequest('get','a3s_management_fetchLBConfig',[subId,rgName,LBName],null,null,null,true).then(res=>{
      let data= res.body
    
      console.log('LB Data',data)
    })
  }
  changeLBName(event){
    this.popUpForm.get('backendPool').setValue('new')
  let filter= this.lbSelectorList.filter(e=>e.id==event.id)
  this.backendPoolList=filter[0]?.properties?.backendAddressPools
  }

  async updateLBConfig(subId,body,rgName,nicName,type){
   return this.util.handleRequest('post','a3s_management_updateLBPublicIP',[subId,rgName,nicName],body,null,null,true).then(res=>{
      console.log('LB Data',res)
    },err=>{
      console.log('error',err)
    })
  }

}
