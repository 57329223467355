<div class="introduction">
          <p class="heading" id="1">Overview </p>
          <ul>
              <li>Click ‘Data & AI’ tile on the A3S portal homepage of A3S and choose Data migration from the left menu. </li>
              <li>List of all the projects will be displayed. </li>
              <li>Click an existing project to view the project summary. </li>
              <img src="assets\user-manual\data-&-AI\1.svg" >
              <figcaption class="caption">Data migration project List </figcaption>
              <li>Click on ellipsis (<img class="eclipise" src="assets\user-manual\Vector.svg" >) and thereon click delete project, if needed</li>
              
          </ul>
 
</div>
