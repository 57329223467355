import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate  {
  constructor(private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean{
      if(JSON.parse(sessionStorage.getItem('data'))!==null && localStorage.getItem('cred') != null){
        if(JSON.parse(sessionStorage.getItem('data'))['token'].length>1)
      return true;
      }
      else{
        this.router.navigate(['']);
        return false;
      }
    }
}
