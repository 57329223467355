<div class="header-container">
    <h2 class="title">Create Windows Virtual Machine</h2>
    <span class="content-14 blur-text">Deployment</span>
</div>
<div class="windows-vm-body">

    <div *ngIf="state == 'init'">
        <div class="process-stepper-container">
              <ng-container *ngFor="let steps of windowsVmStepper">
                <div class="stepper-header">
                  <div class="stepper-icon" [ngClass]="getClass(steps)">
                    <div class="stepper-content">
                      <span *ngIf="!steps?.isCompleted && !steps?.isError">{{steps?.step}}</span>
                      <img src="assets/icons/tick.svg" *ngIf="steps?.isCompleted"> 
                      <!-- <img src="assets/icons/cross-fail.svg" *ngIf="steps?.isCompleted"> -->
                      <img src="assets/icons/cross-fail.svg" *ngIf="steps?.isError">
                    </div>
                  </div>
                  <div class="stepper-label" [class.active]="step == steps?.limit && !steps?.isError" [class.bold-600]="steps?.isError || steps?.isCompleted">
                    {{steps?.value}}</div>
                </div>
                <div class="stepper-horizontal-line" [class.active]="step >= steps?.limit + 1"></div>
              </ng-container>
        </div>
      </div>

      <ng-container [ngSwitch]="currentProcess">
        <ng-container *ngSwitchCase="'submitBasicInfo'">

            <form action="#" class="" #sourceForm="ngForm">
                <div class="upper-section">
        
                    <!-- <div class="info mb-3">
                    <span class="sql-data-head bold-600">Project details</span>
                    </div>
                 -->
                
                    <div class="form-group row">
                        <label for="lname" class="control-label col-sm-2">Subscription<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                        <div class="col-sm-6">
                        <ng-select [items]="subscriptions" (change)="getRgBySubscription()" (open)="rotateDownArrow()" bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.subscription" name="subscription" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email" class="control-label col-sm-2">Resource group<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                        <div class="col-sm-6">
                        <ng-select [items]="ResourceGroups" (change)="getResourceByRg()" bindLabel="name" bindValue="name" [(ngModel)]="model.resourceGroup" name="resourceGroup" placeholder="West US" [clearable]="false" required></ng-select>
                        <p class="blue-text text-12-18 p-blue-btn mb-0" mat-button [matMenuTriggerFor]="menu" #menuTrigger>Create new</p>
                        <mat-menu #menu="matMenu" class="new-resource-group-popup">
                            <!-- <div > -->
                                <div class="form-group">
                                    <label for="fname">Resource group name<span class="mandatory">*</span></label>
                                    <div class="input-box">
                                    <input type="text" class="form-control" [class.border-danger]="inputError?.project" id="fname" autocomplete="off" [(ngModel)]="model.resourceGroupName" placeholder="Enter name" name="projectName" required>
                                    </div>              
                                </div>
                                <div class="form-group">
                                    <label for="email">Resource group location<span class="mandatory">*</span></label>
                                    <div class="input-box">
                                    <ng-select [items]="ResourceGroups" (change)="getResourceByRg()" bindLabel="name" bindValue="name" [(ngModel)]="model.resourceGroupLocation" name="resourceGroup" placeholder="None (empty disk)" [clearable]="false" required></ng-select>
                                    </div>
                                </div>
                            <!-- </div>                             -->
                        </mat-menu>
                        </div>
                        
                    </div>
                    <div class="form-group row">
                      <label for="password" class="control-label col-sm-2">Resource group location<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                      <div class="col-sm-6">
                        <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.resourceGroupLocation" name="resource" placeholder="Choose resource" [clearable]="false" required></ng-select>
                      </div>
                    </div>
                </div>
            </form>

        </ng-container>

        <ng-container *ngSwitchCase="'selectDiskType'" >

            <div class="disk-form-section">
                <div class="form-group row">
                    <label for="lname" class="control-label col-sm-2">OS disk type<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6">
                    <ng-select [items]="subscriptions" (change)="getRgBySubscription()" (open)="rotateDownArrow()" bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.osDiskType" name="subscription" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="control-label col-sm-2">Encryption type<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6">
                    <ng-select [items]="ResourceGroups" (change)="getResourceByRg()" bindLabel="name" bindValue="name" [(ngModel)]="model.encryptionType" name="resourceGroup" placeholder="West US" [clearable]="false" required></ng-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="control-label col-sm-2">Enable Ultra Disk compatibility<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6 mt-2">
                        <input type="checkbox" [(ngModel)]="model.enableUltraDisk" height="14px" width="14px" disabled>
                        <div><span class="blur-text mt-2 text-12-18">Ultra disk is available only for Availability Zones in east US.</span></div>
                    </div>
                </div>
    
                <div class="form-group mt-2 text-12-18 new-disk-option">
                    <p class="details">You can add and configure additional data disks for your virtual machine. This VM also comes with a temporary disk.</p>
                    <!-- <p><a href="javascript:void(0);" class="blue-text text-12-18" (click)="toggleVMsideSheet()">Create and attach a new disk</a></p>   -->
                    <p class="blue-text text-12-18 p-blue-btn" (click)="toggleVMsideSheet()" cursor="pointer">Create and attach a new disk</p>              
                </div>
                
            </div>            

        </ng-container>
    
        <ng-container *ngSwitchCase="'submitNetworkInfo'">
            <form action="#" class="mt-3" #sourceForm="ngForm">
              <div class="upper-section">
              <div class="form-group row">
                  <label for="lname" class="control-label col-sm-2">Virtual Network<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                  <ng-select [items]="subscriptions" (change)="getRgBySubscription()" bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.virtualNetwork" name="subscription" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                  <a href="" class="blue-text text-12-18" >Create new</a>
                  </div>
              </div>
              <div class="form-group row">
                  <label for="email" class="control-label col-sm-2">Virtual network address space<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="ResourceGroups" bindLabel="name" bindValue="name" [(ngModel)]="model.virtualNetworkAddressSpace" name="resourceGroup" placeholder="Choose resource group" [clearable]="false" required></ng-select>
                  </div>
              </div>
              <div class="form-group row">
                <label for="password" class="control-label col-sm-2">Virtual network location<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                  <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.virtualNetworkLocation" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label for="password" class="control-label col-sm-2">Subnet<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                  <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnet" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label for="password" class="control-label col-sm-2">Subnet address space<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                  <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnetAddressSpace" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                </div>
              </div>
              </div>
          </form>
          </ng-container>

          <ng-container *ngSwitchCase="'submitVmInfo'">
            <form action="#" class="mt-3" #sourceForm="ngForm">
              <div class="upper-section">
                <div class="form-group row">
                    <label for="lname" class="control-label col-sm-2">Virtual machine name<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6">
                    <ng-select [items]="subscriptions" (change)="getRgBySubscription()" bindLabel="SubscriptionName" bindValue="SubscriptionName" [(ngModel)]="model.virtualNetwork" name="subscription" placeholder="Choose subscription" [clearable]="false" required></ng-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="control-label col-sm-2">Virtual machine location <span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                    <div class="col-sm-6">
                      <ng-select [items]="ResourceGroups" bindLabel="name" bindValue="name" [(ngModel)]="model.virtualNetworkAddressSpace" name="resourceGroup" placeholder="Choose resource group" [clearable]="false" required></ng-select>
                    </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Virtual machine size<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.virtualNetworkLocation" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Computer name<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnet" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">User name<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnetAddressSpace" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Password<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnetAddressSpace" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="control-label col-sm-2">Confirm password<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                  <div class="col-sm-6">
                    <ng-select [items]="resourcesList" bindLabel="name" bindValue="name" [(ngModel)]="model.subnetAddressSpace" name="resource" placeholder="Choose resource group location" [clearable]="false" required></ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="horizontal-line"></div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 right-align">
                  <a href="">+ Add another virtual machine</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </form>
        </ng-container>
    
</ng-container>

<div class="fixed-btm-btn flex-custom no-wrap">
    <button class="btn btn-outline back-btn-analytics"
    *ngIf="currentProcess == 'selectDiskType' || currentProcess=='submitNetworkInfo' || currentProcess=='submitVmInfo'"
    (click)="goToPreviousStep()">Back</button>
      <button class="btn btn-btn-primary"
        *ngIf="currentProcess == 'submitBasicInfo' || currentProcess == 'selectDiskType' || currentProcess=='submitNetworkInfo'"
        (click)="moveToNextStep()">Next</button>
      <button class="btn btn-btn-primary" *ngIf="currentProcess == 'submitVmInfo'" [class.disabled]="checkDisable()"
        (click)="moveToNextStep()">Deploy</button>
  
  
      <button class="btn btn-btn-primary" *ngIf="currentProcess == 'submitInfo'" (click)="routeToListPage()">Finish</button>
  
      <button class="btn btn-no-outline ml-auto" *ngIf="currentProcess == 'submitBasicInfo' || currentProcess == 'selectDiskType' || currentProcess=='submitNetworkInfo'" (click)="openModal(cancelAnalytics)">Cancel</button>
  </div>


<div class="sidebar-right" *ngIf="createVM">
    <div>
        <div class="header">
            <div class="d-flex">
                <h3>Create a New Disk</h3>
                <div class="text-right full-width">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="toggleVMsideSheet()">
                      <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                    </button>
                  </div>
            </div>
        </div>

        <div class="body">
            <div class="form-group">
                    <label for="fname" class="control-label col-sm-2">Name<span class="mandatory">*</span></label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [class.border-danger]="inputError?.project" id="fname" autocomplete="off" [(ngModel)]="model.newDisk.name" placeholder="Enter name" name="projectName" required>
                </div>              
            </div>
            <div class="form-group ">
                <label for="email" class="control-label col-sm-2">Source type<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                <ng-select [items]="ResourceGroups" (change)="getResourceByRg()" bindLabel="name" bindValue="name" [(ngModel)]="model.newDisk.sourceType" name="resourceGroup" placeholder="None (empty disk)" [clearable]="false" required></ng-select>
                </div>
            </div>
            <!-- *ngIf="model.newDisk.sourceType=='blob' " -->
            <div class="form-group" >
                <label for="fname" class="control-label col-sm-2">Storage blob<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                <input type="text" class="form-control" [class.border-danger]="inputError?.project" id="fname" autocomplete="off" [(ngModel)]="model.newDisk.name" placeholder="Enter storage blob name" name="projectName" required>
                </div>              
            </div>
            
            <div class="form-group ">
                <label for="email" class="control-label col-sm-2">Size<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="disk-size">
                    <p class="text-13-20 bold-600">1024 GB</p>
                    <p class="text-13-20">Premium SSD LRS</p>
                    <p><a href="" class="blue-text text-12-18">Change size</a></p>
                </div>
            </div>
            <div class="form-group ">
                <label for="email" class="control-label col-sm-2">Encryption type<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                <ng-select [items]="ResourceGroups" (change)="getResourceByRg()" bindLabel="name" bindValue="name" [(ngModel)]="model.newDisk.encryptionType" name="resourceGroup" placeholder="None (empty disk)" [clearable]="false" required></ng-select>
                </div>
            </div>
            <div class="form-group ">
                <label for="email" class="control-label col-sm-2">Disk encryption set<span class="mandatory">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6">
                <ng-select [items]="ResourceGroups" (change)="getResourceByRg()" bindLabel="name" bindValue="name" [(ngModel)]="model.newDisk.diskEncryptionType" name="resourceGroup" placeholder="Choose disk encryption set" [clearable]="false" required></ng-select>
                </div>
            </div>

            <div class="form-group ">
                <label for="email" class="control-label col-sm-2">Enable shared disk<span class="mandatory" margin-bottom="8px">* <img src="/assets/Infra/info.svg" alt=""></span></label>
                <div class="col-sm-6 d-flex">
                    <label class="radio-label"><input type="radio" value="Yes" [(ngModel)]="model.newDisk.enableSharedDisk"><span>Yes</span></label>
                    <label class="radio-label"><input type="radio" value="No" [(ngModel)]="model.newDisk.enableSharedDisk" checked><span>No</span></label>                    
                </div>
            </div>


        </div>


        <div class="fixed-btm-btn flex-custom no-wrap">
            <button class="btn btn-outline">Cancel</button>
            <button class="btn btn-btn-primary">OK</button>
        </div>
    </div>
</div>

</div>