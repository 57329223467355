import { environment } from "../../environments/environment"

export class ApiConstants {
    public static url = {
        a3s:{
            server_url:environment.serverUrl,
            admin:{
                a3s_admin_register:['api/registration'],
                a3s_admin_createServicePrincipal:['api/createServicePrincipal'],
                a3s_admin_checkPermissionGranted:['api/checkPermissionGranted'],
                a3s_admin_updateCompanyOnboarded:['api/updateCompanyOnboarded'],
                a3s_admin_updateCompanyDetails:['api/updateCompanyDetails'],
                a3s_admin_getLogAnalyticsWorkspace:['api/subscription/{{param1}}/resourceGroup/{{param2}}/logAnalytics'],
                a3s_admin_createNewLogAnalyticsWorkspace:['api/subscription/{{param1}}/resourceGroup/{{param2}}/logAnalytics/{{param3}}/create'],
                a3s_admin_grantUserAccesss:['api/subscription/{{param1}}/grantUserAccesss'],
                a3s_admin_updateSecurityCenter:['api/subscription/{{param1}}/securityCenter/update'],
                a3s_admin_getAppInsightsList:['api/subscription/{{param1}}/appInsights/all'],
                a3s_admin_getVirtualMachineList:['api/subscription/{{param1}}/resourceGroup/{{param2}}/virtualMachine/all'],
                a3s_admin_getVirtualMachineRunningList:['api/virtualMachine/running/all'],
                a3s_admin_logAnalyticsSetup:['api/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/logAnalyticsWorkspaceSetup'],
                a3s_admin_logAnalyticsSubscriptionEnable:['api/subscription/{{param1}}/{{param2}}/resourceGroup/{{param3}}/workspace/{{param4}}/logAnalyticsSubscriptionEnable'],
                a3s_admin_checkLogAnalyticsSubscriptionEnabled:['api/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/checkLogAnalyticsSubscriptionEnabled'],
                a3s_admin_connectVmLogAnalytics:['api/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/virtualMachine/{{param4}}/connectVmToLogAnalytics'],
                a3s_admin_connectLinuxVmToLogAnalytics:['api/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/virtualMachine/{{param4}}/connectLinuxVmToLogAnalytics'],
                a3s_admin_createAppInsightsKey:['api/createAppInsightsKey'],
                a3s_admin_enableSentinal:['api/azure/sentinal/update'],
                a3s_admin_enableCostMonitoring:['api/azure/costMonitoring/enable'],
                a3s_admin_registerResourceProvider:['api/azure/subscription/{{param1}}/resourceProvider/{{param2}}/register'],
                a3s_admin_getAllVnet:['api/azure/subscription/{{param1}}/virtualNetwork/all'],
                a3s_admin_getAllSubnet:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/virtualNetwork/{{param3}}/subnet/all'],
                a3s_admin_getAllLogAnalytics:['api/azure/subscription/{{param1}}/logAnalytics/all'],
                a3s_admin_createAMS:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/createAMS'],
                a3s_admin_getAMSResourceByName:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/amsResource/{{param3}}'],


                a3s_admin_updateServicePrincipal:['api/update/servicePrincipal'],
                a3s_admin_updateLogAnalyticsCreated:['api/update/logAnalyticsCreated'],
                a3s_admin_costMonitoringEnabled:['api/update/costMonitoringEnabled'],
                a3s_admin_updateSubscription:['api/update/subscription'],
                a3s_admin_updateLogAnalytics:['api/update/logAnalytics'],
                a3s_admin_updateApplicationInsights:['api/update/applicationInsights'],
                a3s_admin_updateSecurityCenterTable:['api/update/securityCenter'],
                a3s_admin_updateAzureSentinalEnabled:['api/a3s/update/updateAzureSentinalEnabled'],
                a3s_admin_updateAMSEnabled:['api/a3s/update/updateAMSEnabled'],
                a3s_admin_updateAMSTable:['api/a3s/update/AMS'],

                a3s_admin_deleteSubscription:['api/a3s/subscription'],
                a3s_admin_deleteLogAnalytics:['api/a3s/logAnalytics'],
                a3s_admin_deleteApplicationInsights:['api/a3s/applicationInsights'],
                a3s_admin_deleteSecurityCenterTable:['api/a3s/securityCenter'],
                a3s_admin_deleteAMSTable:['api/a3s/AMS'],

                a3s_admin_appInsightsPlugin:['api/appInsights/{{param1}}/plugin'],
            },
            static:{
                a3s_static_getByTable:['api/a3s/staticData/schema/{{param1}}/table/{{param2}}'],
                a3s_static_getLocation:['api/azure/location/all'],
            },
            subscription:{
                a3s_subscription_getAll:['api/getAllSubscription'],
                a3s_subscription_getOnboardedA3s:['api/a3s/subscription/onboard/all'],
                a3s_subscription_getOnboardAll:['api/subscription/onboard/all'],
                a3s_subscription_all:['api/subscription/all'],
                a3s_subscription_getById:['api/getSubscriptionById/{{param1}}'],
            },
            onboarded:{
                a3s_onboarded_logAnalytics:['api/a3s/logAnalytics/onboard/all'],
                a3s_onboarded_applicationInsights:['api/a3s/applicationInsights/onboard/all'],
                a3s_onboarded_applicationInsightsBySubs:['api/a3s/applicationInsights/onboard/subscription/{{param1}}'],
                a3s_onboarded_securityCenter:['api/a3s/securityCenter/onboard/all'],
                a3s_onboarded_orgDetails:['api/a3s/organization/onboard/details'],
            },
            workspace:{
                a3s_workspace_getById:['api/getWorkspaceBySubsId/{{param1}}'],
            },
            favourites:{
                a3s_favourites_all:['api/favourites/{{param1}}/all'],
                a3s_favourites_add:['api/favourites/add'],
            },
            powerBi:{
                a3s_powerBi_getStatus:['api/aiPowered/powerBi/getStatus'],
                a3s_powerBi_triggerAction:['api/aiPowered/powerBi/triggerAction/{{param1}}'],
            },
            a3sAccess:{
                a3s_a3sAccess_userDetails:['api/a3s/access/user/{{param1}}/details'],
                a3s_a3sAccess_getAllUserRoles:['api/a3s/access/roles/all'],
                a3s_a3sAccess_addUserRoles:['api/a3s/access/addRoles'],
                a3s_a3sAccess_updateUserRoles:['api/a3s/access/updateA3sRoles'],
                a3s_a3sAccess_deleteUserRoles:['api/a3s/access/user/{{param1}}/roles/delete']
            },
            azureDetails:{
                a3s_azureDetails_userByEmail:['api/getUserInfo?mail={{param1}}'],
            },
            supportTicket:{
                a3s_supportTicket_updateAzureTicket:['api/azure/supportTicket/create'],
                a3s_supportTicket_getAzureTicket:['api/azure/supportTicket/all'],
                a3s_supportTicket_updateSapTicket:['api/sap/supportTicket/create'],
                a3s_supportTicket_getSapTicket:['api/sap/supportTicket/all'],
                a3s_supportTicket_updateA3sTicket:['api/a3s/supportTicket/create'],
                a3s_supportTicket_getA3sTicket:['api/a3s/supportTicket/all'],
            },
            accounts:{
                a3s_accounts_deleteAccRequest:['api/deleteAccRequest'],
                a3s_accounts_getUserRoleList:['api/getUserRoleDtls/{{param1}}'],
            },
            resourceGroup:{
                a3s_resourceGroup_getRgBySubscription:['api/subscription/{{param1}}/resourceGroup'],
                a3s_resourceGroup_create:['api/subscription/{{param1}}/resourceGroup/{{param2}}/create'],
            },
            resource:{
                a3s_resource_getResourceByRg:['api/subscription/{{param1}}/resourceGroup/{{param2}}/resources'],
                a3s_resource_getResourceBySubscription:['api/resource-list'],
                a3s_resource_getAllResourceByType:['api/healthMonitor/getAllResourceByType/{{param1}}/{{param2}}'],
                a3s_resource_getAllVMResource:['api/healthMonitor/getAllVMResource/{{param1}}'],
                a3s_resource_getAllFileShareResource:['api/healthMonitor/getAllFileShareResource/{{param1}}/{{param2}}/{{param3}}'],
                a3s_resource_getAllKeyVaultResource:['api/healthMonitor/getAllKeyVaultResource/{{param1}}'],
                a3s_resource_getListByPropName:['api/getListByPropName'],
                a3s_resource_getListByPropNameUsingGrpah:['api/getListByPropNameUsingGrpah'],
            },
            logAnalytics:{
                a3s_logAnalytics_dashboard:['api/logAnalytics/{{param1}}/{{param2}}'],
                a3s_logAnalytics_customQueryScope:['api/customQueryScope/{{param1}}/{{param2}}/{{param3}}'],
                a3s_logAnalytics_customQuery:['api/customQuery/{{param1}}/{{param2}}/{{param3}}'],
                a3s_logAnalytics_getResources:['api/getResources/{{param1}}'],
                a3s_logAnalytics_getVm:['api/getVm'],
                a3s_logAnalytics_getAllVm:['api/getAllVm'],
                a3s_logAnalytics_getAlerts:['api/getAlerts/{{param1}}'],
                a3s_logAnalytics_getAlertsMetrics:['api/getAlertsMetrics/{{param1}}'],
            },
            infraHealthDashboards:{
                a3s_infraHealthDashboards_keyVault:['api/keyVault/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_appServicePlan:['api/appServicePlan/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_loadBalancer:['api/loadBalancer/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_sqlDatabase:['api/sqlDatabase/dashboard'],
                a3s_infraHealthDashboards_sqlDatabaseChart:['api/sqlDatabase/charts/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_networkInterface:['api/networkInterface/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_networkInterfaceHealthTable:['api/networkInterface/getHealthTable'],
                a3s_infraHealthDashboards_disk:['api/disk/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_diskHealthTable:['api/disk/getHealthTable'],
                a3s_infraHealthDashboards_webApp:['api/webApp/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_virtualMachine:['api/virtualMachine/dashboard'],
                a3s_infraHealthDashboards_virtualMachineNetwork:['api/virtualMachine/network/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_virtualMachineHealthTable:['api/virtualMachine/getHealthTable'],
                a3s_infraHealthDashboards_vpnGateway:['api/vpnGateway/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_expressRouteGateway:['api/expressRouteGateway/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_static_pointToSite:['api/vpnGateway/dashboard/static/pointToSite'],
                a3s_infraHealthDashboards_static_connectionData:['api/vpnGateway/dashboard/static/ConnectionData'],
                a3s_infraHealthDashboards_applicationGateway:['api/applicationGateway/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_staticTableData:['api/applicationGateway/dashboard/static/unhealthyBackend/tableData'],
                a3s_infraHealthDashboards_vmScaleSet:['api/vmScaleSet/dashboard/{{param1}}/{{param2}}/{{param3}}'],
                a3s_infraHealthDashboards_vmScaleSet_instance:['api/vmScaleSet/instance/dashboard/{{param1}}'],
                a3s_infraHealthDashboards_createNSGFlowLog:['api/networkMonitorning/createNSGFlowLog'],
                a3s_infraHealthDashboards_createSAForNSGFlowLog:['api/networkMonitorning/createSAForNSGFlowLog']
            },
            applicationHealthMonitor:{
                a3s_applicationHealthMonitor_getData:['api/healthMonitor/getinsights/{{param1}}/{{param2}}'],
                a3s_applicationHealthMonitor_getWebApp:['api/healthMonitor/getWebAppsBySubscription/{{param1}}'],
                a3s_applicationHealthMonitor_getMemoryAvailable:['api/healthMonitor/getMemoryAvailable'],
                a3s_applicationHealthMonitor_getMemoryAvailableChart:['api/healthMonitor/getMemoryAvailableChart'],
                a3s_applicationHealthMonitor_getAlertList:['api/healthMonitor/getAlertList'],
                a3s_applicationHealthMonitor_getAlertByName:['api/healthMonitor/getAlertByName/{{param1}}/{{param2}}/{{param3}}'],
                a3s_applicationHealthMonitor_createAlert:['api/healthMonitor/createAlert/{{param1}}/{{param2}}/{{param3}}'],
                a3s_applicationHealthMonitor_deleteAlert:['api/healthMonitor/deleteAlert/{{param1}}/{{param2}}/{{param3}}'],
                a3s_applicationHealthMonitor_deployActionGroupARMTemplate:['api/healthMonitor/deployActionGroupARMTemplate/{{param1}}/{{param2}}/{{param3}}'],
                a3s_applicationHealthMonitor_deployAlertARMTemplate:['api/healthMonitor/deployAlertARMTemplate/{{param1}}/{{param2}}/{{param3}}'],
                a3s_applicationHealthMonitor_getDeployActionGroupStatus:['api/healthMonitor/getDeployActionGroupStatus/{{param1}}/{{param2}}/{{param3}}'],
                a3s_applicationHealthMonitor_getDeployAlertStatus:['api/healthMonitor/getDeployAlertStatus/{{param1}}/{{param2}}/{{param3}}'],
                a3s_applicationHealthMonitor_getAllActionGroup:['api/healthMonitor/getAllActionGroup/{{param1}}'],
                a3s_applicationHealthMonitor_getAllActionGroupByRG:['api/healthMonitor/getAllActionGroupByRG/{{param1}}/{{param2}}'],
                a3s_applicationHealthMonitor_getAvailableMetricsByResourceId:['api/healthMonitor/getAvailableMetricsByResourceId'],
                a3s_applicationHealthMonitor_runLogAnalyticsQuery:['api/healthMonitor/runLogAnalyticsQuery'],
                a3s_applicationHealthMonitor_getProviderList:['api/healthMonitor/getProviderList'],
                a3s_applicationHealthMonitor_getNetworkThroughput:['api/healthMonitor/getNetworkThroughput'],
                a3s_applicationHealthMonitor_getVMDetailsByGraphQuery:['api/healthMonitor/getVMDetailsByGraphQuery']
            },
            governance:{
                a3s_governance_getPolicyList:['api/governance/getPolicyList'],
                a3s_governance_getActivityLogs:['api/governance/getActivityLogs/{{param1}}/{{param2}}'],
                a3s_governance_getComplianceData:['api/governance/getComplianceData/{{param1}}'],
                a3s_governance_getAuditLogs:['api/governance/getAuditLogs'],
                a3s_governance_getAdminList:['api/governance/getAdminList'],
                a3s_governance_getNCompliancePolicyData:['api/governance/getNCompliancePolicyData'],
                a3s_governance_getComplianceTotalData:['api/governance/getComplianceTotalData'],
                a3s_governance_getResourcesInEnv:['api/governance/getResourcesInEnv/{{param1}}'],
                a3s_governance_getComplianceAssignments:['api/governance/getComplianceAssignments/{{param1}}'],
                a3s_governance_getBuildInPolicy:['api/governance/getBuildInPolicy/{{param1}}'],
                a3s_governance_getBuildInInitiatives:['api/governance/getBuildInInitiatives/{{param1}}'],
                a3s_governance_assignPolicyInitiatives:['api/governance/assignPolicyInitiatives/{{param1}}/{{param2}}'],

                a3s_governance_getPaginationData:['api/governance/getPaginationData'],
                a3s_governance_getAllUsers:['api/governance/getAllUsers'],
                a3s_governance_searchUsers:['api/governance/searchUsers/{{param1}}'],
                a3s_governance_searchGroups:['api/governance/searchGroups/{{param1}}'],
                a3s_governance_resetUserPassword:['api/governance/resetUserPassword/{{param1}}'],
                a3s_governance_getAllGroups:['api/governance/getAllGroups'],
                a3s_governance_getAllroles:['api/governance/getAllroles'],
                a3s_governance_deleteUser:['api/governance/deleteUser/{{param1}}'],
                a3s_governance_deleteGroups:['api/governance/deleteGroups/{{param1}}'],
                a3s_governance_getAllGroupsByUser:['api/governance/getAllGroupsByUser/{{param1}}'],
                a3s_governance_getAllMembersByUser:['api/governance/getAllMembersByUser/{{param1}}'],
                a3s_governance_getAllOwnersByUser:['api/governance/getAllOwnersByUser/{{param1}}'],
                a3s_governance_addRoleToUser:['api/governance/addRoleToUser'],
                a3s_governance_deleteRoleFromUser:['api/governance/deleteRoleFromUser/{{param1}}/{{param2}}'],
                a3s_governance_addUserToGroup:['api/governance/addUserToGroup/{{param1}}'],
                a3s_governance_deleteUserFromGroup:['api/governance/deleteUserFromGroup/{{param1}}/{{param2}}'],
                a3s_governance_addOwnerToGroup:['api/governance/addOwnerToGroup'],
                a3s_governance_deleteOwnerFromGroup:['api/governance/deleteOwnerFromGroup/{{param1}}/{{param2}}'],

                a3s_governance_getAllActivityLogs:['api/governance/auditLog/getAllActivityLogs'],
                a3s_governance_getSignIns:['api/governance/auditLog/getSignIns'],
                a3s_governance_getAllAuditLogs:['api/governance/auditLog/getAuditLogs'],
                
                a3s_governance_addUser:['api/governance/addUser']
            },
            management:{
                a3s_management_getResourceTypes:['api/management/getResourceTypes/{{param1}}'],
                a3s_management_getDataByQueries:['api/management/getDataByQueries'],
                a3s_management_getListByPropName:['api/management/getListByPropName'],

                a3s_management_startVM:['api/management/startVM'],
                a3s_management_connectVM:['api/management/connectVM'],
                a3s_management_restartVM:['api/management/restartVM'],
                a3s_management_stopVM:['api/management/stopVM'],
                a3s_management_powerOffVM:['api/management/powerOffVM'],
                a3s_management_deleteVM:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteVM'],
                a3s_management_getVMData:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMData'],
                a3s_management_getVMConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMConfig'],
       
                a3s_management_getVMPublicIp:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMPublicIp'],
                a3s_management_updateVMPublicIp:['api/management/{{param1}}/{{param2}}/{{param3}}/updateVMPublicIp'],
                a3s_management_getVMNetworkInterface:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMNetworkInterface'],
                a3s_management_updateVMData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateVMData'],
                a3s_management_getAutoShutdownData:['api/management/{{param1}}/{{param2}}/{{param3}}/getAutoShutdownData'],
                a3s_management_enableAutoShutdownData:['api/management/{{param1}}/{{param2}}/{{param3}}/enableAutoShutdownData'],
                a3s_management_disableAutoShutdownData:['api/management/{{param1}}/{{param2}}/{{param3}}/disableAutoShutdownData'],
                a3s_management_redeployVM:['api/management/virtualMachine/redeployVM'],
                a3s_management_reapplyVM:['api/management/virtualMachine/reapplyVM'],
                a3s_management_resetPassword:['api/management/virtualMachine/{{param1}}/{{param2}}/resetPassword'],
                a3s_management_captureVM:['api/management/virtualMachine/{{param1}}/{{param2}}/captureVM'],
                a3s_management_getDataDisk:['api/management/virtualMachine/{{param1}}/getDataDisk'],
                a3s_management_getAzureComputeGalleryList:['api/management/virtualMachine/{{param1}}/getAzureComputeGalleryList'],
                a3s_management_getAzureComputeGalleryData:['api/management/virtualMachine/{{param1}}/{{param2}}/{{param3}}/getAzureComputeGalleryData'],
                a3s_management_forceDeleteVM:['api/management/virtualMachine/{{param1}}/{{param2}}/{{param3}}/forceDeleteVM'],
                a3s_management_getversionDetails:['api/management/virtualMachine/{{param1}}/{{param2}}/{{param3}}/{{param4}}/{{param5}}/getversionDetails'],
                a3s_management_getSnapshotList:['api/management/{{param1}}/getSnapshotList'],

                a3s_management_getVNETData:['api/management/{{param1}}/{{param2}}/{{param3}}/getVNETData'],
                a3s_management_updateVNETData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateVNETData'],
                a3s_management_deleteVNET:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteVNET'],
                a3s_management_deleteSubnet:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/deleteSubnet'],
                a3s_management_createDDOSProtectionPlan:['api/management/{{param1}}/{{param2}}/createDDOSProtectionPlan'],
                a3s_management_getAllDDOSProtectionPlan:['api/management/{{param1}}/getAllDDOSProtectionPlan'],
                a3s_management_createNewSubnet:['api/management/{{param1}}/{{param2}}/{{param3}}/createNewSubnet'],
                // NSG
                a3s_management_getNSGData:['api/management/{{param1}}/{{param2}}/{{param3}}/getNSGData'],
                a3s_management_updateNSGData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateNSGData'],
                a3s_management_deleteNSG:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteNSG'],
                a3s_management_getNSGRuleData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/getNSGActionData'],
                a3s_management_updateNSGRuleData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/updateNSGActionData'],
                a3s_management_deleteNSGRule:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/deleteNSGAction'],
              
                a3s_management_getSubnetListData:['api/management/{{param1}}/{{param2}}/{{param3}}/getSubnetListData'],
                 a3s_management_getIPAddressValidation:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/getIPAddressValidation'],
                a3s_management_getPublicIPlistData:['api/management/{{param1}}/getPublicIPList'],
                a3s_management_getNICData:['api/management/{{param1}}/{{param2}}/{{param3}}/getNICData'],
                a3s_management_updateNICData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateNICData'],
                a3s_management_deleteNICData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteNICData'],
             // Subnet
                a3s_management_getSubnetData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/getSubnetData'],
                a3s_management_updateSubnetData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/updateSubnetData'],
                a3s_management_getNATGatewayData:['api/management/{{param1}}/getNATGatewayData'],
                a3s_management_getRouteTableData:['api/management/{{param1}}/getRouteTableData'],
                a3s_management_getNSGlistData:['api/management/{{param1}}/getNSGlistData'],
                a3s_management_getServiceEndpointData:['api/management/{{param1}}/{{param2}}/getServiceEndpointData'],
                a3s_management_getDelegateSubnetData:['api/management/{{param1}}/{{param2}}/{{param3}}/getDelegateSubnetData'],
                a3s_management_deleteSubnetData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/deleteSubnetData'],
      
            // Public Ip Address
                a3s_management_getPublicIPAddress:['api/management/{{param1}}/{{param2}}/{{param3}}/getPublicIPAddress'],
                a3s_management_getDNSNameValidation:['api/management/{{param1}}/{{param2}}/{{param3}}/getDNSNameValidation'],
                a3s_management_updatePublicIPAddress:['api/management/{{param1}}/{{param2}}/{{param3}}/updatePublicIPAddress'],
                a3s_management_updateConfigPublicIPAddress:['api/management/{{param1}}/{{param2}}/{{param3}}/updateConfigPublicIPAddress'],
                a3s_management_getNICList:['api/management/{{param1}}/getNICList'],
                a3s_management_fetchNICConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/fetchNICConfig'],
                a3s_management_updateNICPublicIP:['api/management/{{param1}}/{{param2}}/{{param3}}/updateNICPublicIP'],
                a3s_management_getLBList:['api/management/{{param1}}/getLBList'],
                a3s_management_fetchLBConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/fetchLBConfig'],
                a3s_management_updateLBPublicIP:['api/management/{{param1}}/{{param2}}/{{param3}}/updateLBPublicIP'],
                a3s_management_deletePublicIP:['api/management/{{param1}}/{{param2}}/{{param3}}/deletePublicIP'],
                // Local Network Gateway
                a3s_management_getLNGData:['api/management/{{param1}}/{{param2}}/{{param3}}/getLNGData'],
                a3s_management_getConnectionList:['api/management/{{param1}}/{{param2}}/getConnectionList'],
                a3s_management_getVPNList:['api/management/{{param1}}/getVPNList'],
                a3s_management_updateLNGData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateLNGData'],
                a3s_management_getConnectionData:['api/management/{{param1}}/{{param2}}/{{param3}}/getConnectionData'],
                a3s_management_updateConnectionData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateConnectionData'],
                a3s_management_deleteLNGData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteLNGData'],
                a3s_management_deleteConnection:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteConnection'],
                // Disk
                a3s_management_getDiskData:['api/management/{{param1}}/{{param2}}/{{param3}}/getDiskData'],
                a3s_management_getSKUDiskData:['api/management/{{param1}}/{{param2}}/getSKUDiskData'],
                a3s_management_getDiskEncryptionSets:['api/management/{{param1}}/getDiskEncryptionSets'],
                a3s_management_getDiskAccessData:['api/management/{{param1}}/getDiskAccessData'],
                a3s_management_checkDiskVMData:['api/management/{{param1}}/{{param2}}/{{param3}}/checkDiskVMData'],
                a3s_management_updateDiskData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateDiskData'],
                a3s_management_deleteDiskData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteDiskData'],
                // VPN Gateway
                a3s_management_getVPNGatewayData:['api/management/{{param1}}/{{param2}}/{{param3}}/getVPNGatewayData'],
                a3s_management_getVPNGatewayAllConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/getVPNGatewayAllConfig'],
                a3s_management_CheckNatRuleToConnection:['api/management/{{param1}}/{{param2}}/{{param3}}/CheckNatRuleToConnection'],
                a3s_management_getVPNGatewayList:['api/management/{{param1}}/{{param2}}/getVPNGatewayList'],
                a3s_management_getLocalNetworkGatewayList:['api/management/{{param1}}/{{param2}}/getLocalNetworkGatewayList'],
                a3s_management_updateVPNGatewayData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateVPNGatewayData'],
                a3s_management_deleteVPNGatewayData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteVPNGatewayData'],
                // VM Scale Set
                a3s_management_getVMScaleSetData:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMScaleSetData'],
                a3s_management_getInstanceViewStatus:['api/management/{{param1}}/{{param2}}/{{param3}}/getInstanceViewStatus'],
                a3s_management_getVMScaleSetVMList:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMScaleSetVMList'],
                a3s_management_getVMScaleSetVMStatus:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMScaleSetVMStatus'],
                a3s_management_getVMStatusWithInstanceID:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/getVMStatusWithInstanceID'],
                a3s_management_updateVMScaleSetData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateVMScaleSetData'],
                a3s_management_deleteVMScaleSetData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteVMScaleSetData'],
                a3s_management_startVMScaleSet:['api/management/{{param1}}/{{param2}}/{{param3}}/startVMScaleSet'],
                a3s_management_restartVMScaleSet:['api/management/{{param1}}/{{param2}}/{{param3}}/restartVMScaleSet'],
                a3s_management_stopVMScaleSet:['api/management/{{param1}}/{{param2}}/{{param3}}/stopVMScaleSet'],
                a3s_management_reimageVMScaleSet:['api/management/{{param1}}/{{param2}}/{{param3}}/reimageVMScaleSet'],
                a3s_management_deleteInstanceVMScaleSet:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteInstanceVMScaleSet'],
                a3s_management_upgradeVMScaleSet:['api/management/{{param1}}/{{param2}}/{{param3}}/upgradeVMScaleSet'],
                a3s_management_protectionPolicyVMScaleSet:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/protectionPolicyVMScaleSet'],
                a3s_management_getVMScaleSetHealthState:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMScaleSetHealthState'],
                a3s_management_updateVMScaleSetHealthState:['api/management/{{param1}}/{{param2}}/{{param3}}/updateVMScaleSetHealthState'],
                a3s_management_deleteVMScaleSetHealthState:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteVMScaleSetHealthState'],
                a3s_management_getProximityPlacementGroupsList:['api/management/{{param1}}/getProximityPlacementGroupsList'],
                a3s_management_getSKUSeriesList:['api/management/{{param1}}/{{param2}}/{{param3}}/getSKUSeriesList'],
                a3s_management_getAutoScalingData:['api/management/{{param1}}/{{param2}}/getAutoScalingData'],
                a3s_management_updateAutoScalingData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateAutoScalingData'],
                a3s_management_getVMScaleSetNetworkingList:['api/management/{{param1}}/{{param2}}/{{param3}}/getVMScaleSetNetworkingList'],
                a3s_management_getNSGlistBsRG:['api/management/{{param1}}/{{param2}}/getNSGlistBsRG'],
                a3s_management_getVMSize:['api/management/{{param1}}/{{param2}}/getVMSize'],
                // App service
                a3s_management_getAppServiceData:['api/management/{{param1}}/{{param2}}/{{param3}}/getAppServiceData'],
                a3s_management_updateAppServiceData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateAppServiceData'],
                a3s_management_deleteAppServiceData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteAppServiceData'],
                a3s_management_startAppService:['api/management/{{param1}}/{{param2}}/{{param3}}/startAppService'],
                a3s_management_restartAppService:['api/management/{{param1}}/{{param2}}/{{param3}}/restartAppService'],
                a3s_management_stopAppService:['api/management/{{param1}}/{{param2}}/{{param3}}/stopAppService'],
                a3s_management_getAppSettingList:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/getAppSettingList'],
                a3s_management_updateAppServiceConfigType:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/updateAppServiceConfigType'],
                a3s_management_getAppServiceConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/getAppServiceConfig'],
                a3s_management_updateAppServiceConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/updateAppServiceConfig'],
                a3s_management_getAppServiceConfigData:['api/management/{{param1}}/{{param2}}/{{param3}}/getAppServiceConfigData'],
                a3s_management_getAppServiceSlotList:['api/management/{{param1}}/{{param2}}/{{param3}}/getAppServiceSlotList'],
                a3s_management_updateAppServiceSlot:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/updateAppServiceSlot'],
                a3s_management_getAppServiceStackList:['api/management/getAppServiceStackList'],
                a3s_management_getAppSettingsList:['api/management/{{param1}}/{{param2}}/{{param3}}/getAppSettingsList'],
                a3s_management_getConnStringList:['api/management/{{param1}}/{{param2}}/{{param3}}/getConnStringList'],
                //App Service Plan
                a3s_management_getAppServicePlanSKUList:['api/management/{{param1}}/{{param2}}/{{param3}}/getAppServicePlanSKUList'],
                a3s_management_getAppServicePlanData:['api/management/{{param1}}/{{param2}}/{{param3}}/getAppServicePlanData'],
                a3s_management_updateAppServicePlanData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateAppServicePlanData'],
                a3s_management_deleteAppServicePlanData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteAppServicePlanData'],
                //Connection
                a3s_management_updateConnectionSharedKey:['api/management/{{param1}}/{{param2}}/{{param3}}/updateConnectionSharedKey'],
                //Firewall
                a3s_management_getFirewallData:['api/management/{{param1}}/{{param2}}/{{param3}}/getFirewallData'],
                a3s_management_updateFirewallData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateFirewallData'],
                a3s_management_deleteFirewallData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteFirewallData'],
                a3s_management_getFirewallPolicyList:['api/management/{{param1}}/getFirewallPolicyList'],
                // SQL Database
                a3s_management_getSQLDatabaseData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/getSQLDatabaseData'],
                a3s_management_updateSQLDatabaseData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/updateSQLDatabaseData'],
                a3s_management_deleteSQLDatabaseData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/deleteSQLDatabaseData'],
                a3s_management_updateFirewallRule:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/updateFirewallRule'],
                a3s_management_deleteFirewallRule:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/deleteFirewallRule'],
                a3s_management_getSQLServerData:['api/management/{{param1}}/{{param2}}/{{param3}}/getSQLServerData'],
                a3s_management_updateSQLServerData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateSQLServerData'],
                a3s_management_changeConnectionPolicy:['api/management/{{param1}}/{{param2}}/{{param3}}/changeConnectionPolicy'],
                a3s_management_exportSQLDatabaseData:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/exportSQLDatabaseData'],
                a3s_management_getSQLServerFirewallList:['api/management/{{param1}}/{{param2}}/{{param3}}/getSQLServerFirewallList'],
                a3s_management_getSQLServerVNetList:['api/management/{{param1}}/{{param2}}/{{param3}}/getSQLServerVNetList'],
                a3s_management_updateSQLServerVNETRule:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/updateSQLServerVNETRule'],
                a3s_management_deletVNETRule:['api/management/{{param1}}/{{param2}}/{{param3}}/{{param4}}/deletVNETRule'],
                a3s_management_getVNetList:['api/management/{{param1}}/{{param2}}/getVNetList'],
                a3s_management_getSQLServerConnectionPolicyData:['api/management/{{param1}}/{{param2}}/{{param3}}/getSQLServerConnectionPolicyData'],
                a3s_management_getSQLServerAllowAzureIP:['api/management/{{param1}}/{{param2}}/{{param3}}/getSQLServerAllowAzureIP'],
                a3s_management_updateSQLDBCopy:['api/management/{{param1}}/{{param2}}/{{param3}}/updateSQLDBCopy'],
                a3s_management_updateSQLDBRestore:['api/management/{{param1}}/{{param2}}/{{param3}}/updateSQLDBRestore'],
                a3s_management_getSQLServerElasticPoolList:['api/management/{{param1}}/{{param2}}/{{param3}}/getSQLServerElasticPoolList'],
                // SQL VM
                a3s_management_getSQLVMData:['api/management/{{param1}}/{{param2}}/{{param3}}/getSQLVMData'],
                a3s_management_updateSQLVMData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateSQLVMData'],
                a3s_management_deleteSQLVMData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteSQLVMData'],
                a3s_management_getSQLVMExtension:['api/management/{{param1}}/{{param2}}/{{param3}}/getSQLVMExtension'],
                a3s_management_updateSQLVMExtension:['api/management/{{param1}}/{{param2}}/{{param3}}/updateSQLVMExtension'],
                // Load Balancer
                a3s_management_getLoadBalancerData:['api/management/{{param1}}/{{param2}}/{{param3}}/getLoadBalancerData'],
                a3s_management_updateLoadBalancerData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateLoadBalancerData'],
                a3s_management_updateLBData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateLBData'],
                a3s_management_deleteLoadBalancerData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteLoadBalancerData'],
                a3s_management_getLoadBalancerList:['api/management/{{param1}}/{{param2}}/getLoadBalancerList'],
                a3s_management_getVMList:['api/management/{{param1}}/{{param2}}/getVMList'],
                a3s_management_getVMScaleSetList:['api/management/{{param1}}/getVMScaleSetList'],
                a3s_management_getNICConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/getNICConfig'],
                a3s_management_getPublicIpPrefixList:['api/management/{{param1}}/{{param2}}/getPublicIpPrefixList'],
                a3s_management_createPublicIPPrefix:['api/management/{{param1}}/{{param2}}/{{param3}}/createPublicIPPrefix'],
                // Azure bastion
                a3s_management_getAzureBastionData:['api/management/{{param1}}/{{param2}}/{{param3}}/getAzureBastionData'],
                a3s_management_updateAzureBastionData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateAzureBastionData'],
                a3s_management_deleteAzureBastionData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteAzureBastionData'],
                // Applicaton Gateway
                a3s_management_getAppGatewayData:['api/management/{{param1}}/{{param2}}/{{param3}}/getAppGatewayData'],
                a3s_management_updateAppGatewayData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateAppGatewayData'],
                a3s_management_deleteAppGatewayData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteAppGatewayData'],
                a3s_management_getAppGatewayConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/getAppGatewayConfig'],
                a3s_management_updateAppGatewayConfig:['api/management/{{param1}}/{{param2}}/{{param3}}/updateAppGatewayConfig'],
                // Storage Account
                a3s_management_getStorageAccountData:['api/management/{{param1}}/{{param2}}/{{param3}}/getStorageAccountData'],
                a3s_management_updateStorageAccountData:['api/management/{{param1}}/{{param2}}/{{param3}}/updateStorageAccountData'],
                a3s_management_deleteStorageAccountData:['api/management/{{param1}}/{{param2}}/{{param3}}/deleteStorageAccountData'],
                a3s_management_checkHnsOnValidation:['api/management/{{param1}}/{{param2}}/{{param3}}/checkHnsOnValidation'],
                a3s_management_updgradeHnsOn:['api/management/{{param1}}/{{param2}}/{{param3}}/updgradeHnsOn'],
                a3s_management_getIdentitieslist:['api/management/{{param1}}/getIdentitieslist'],
                a3s_management_getEncryptionScope:['api/management/{{param1}}/{{param2}}/{{param3}}/getEncryptionScope'],
                a3s_management_updateEncryptionScope:['api/management/{{param1}}/{{param2}}/{{param3}}/updateEncryptionScope'],
                a3s_management_getThreatSettings:['api/management/{{param1}}/{{param2}}/{{param3}}/getThreatSettings'],
                a3s_management_updateThreatSettings:['api/management/{{param1}}/{{param2}}/{{param3}}/updateThreatSettings'],
                a3s_management_getDataProtectionData:['api/management/{{param1}}/{{param2}}/{{param3}}/getDataProtectionData'],
                a3s_management_updateDataProtectionData:['api/management/{{param1}}/{{param2}}/{{param3}}/UpdateDataProtectionData'],
                a3s_management_getVnetListBySub:['api/management/{{param1}}/getVnetListBySub'],
            },
            notifications:{
                a3s_notifications_createNotification:['api/createNotification'],
                a3s_notifications_getAllNotifications:['api/getAllNotifications/{{param1}}'],
                a3s_notifications_updateNotificationViewedStatus:['api/updateViewNotification/{{param1}}'],
                a3s_notifications_updateNotificationAllViewed:['api/updateNotificationAllViewed'],
                a3s_notifications_updateNotificationProgressPrecentage:['api/updateNotificationProgressPrecentage'],
                a3s_notifications_updateNotification:['api/updateNotification'],
            },
            architectureBlueprint:{
                a3s_architectureBlueprint_getAllProjects:['api/getAllBlueprintProjects/{{param1}}/{{param2}}'],
                a3s_architectureBlueprint_getAllProjectsForFilter:['api/getAllBlueprintProjectsForFilter'],
                a3s_architectureBlueprint_getOrgProjects:['api/getOrgProjects/{{param1}}'],
                a3s_architectureBlueprint_addproject:['api/addBlueprintProject'],
                a3s_architectureBlueprint_deleteProject:['api/deleteBlueprintProject/{{param1}}'],
                a3s_architectureBlueprint_createResource:['api/createResource/{{param1}}'],
                a3s_architectureBlueprint_createSubResource:['api/createSubResource/{{param1}}'],
                a3s_architectureBlueprint_getProjectResourceList:['api/getProjectResourceList/{{param1}}'],
                a3s_architectureBlueprint_getProjectResourceListForDashboards:['api/getProjectResourceListForDashboards/{{param1}}'],
                a3s_architectureBlueprint_getAllVersionHistory:['api/getVersionHistory/{{param1}}'],
                a3s_architectureBlueprint_updateVersionHistory:['api/updateVersionHistory'],
                a3s_architectureBlueprint_updateCommentList:['api/updateCommentList'],
                a3s_architectureBlueprint_getCommentList:['api/getCommentList/{{param1}}'],
                a3s_architectureBlueprint_deleteComment:['api/deleteComment/{{param1}}'],
                a3s_architectureBlueprint_submitForReview:['api/updateBlueprintStatus'],
                a3s_architectureBlueprint_deleteResourcesByResourceIDs:['api/deleteResourcesByResourceIDs/{{param1}}'],
                a3s_architectureBlueprint_deploy:['api/deploy'],
                a3s_architectureBlueprint_getDeploymentStatus:['api/getDeploymentStatus'],
                a3s_architectureBlueprint_updateParametersJSON:['api/updateParametersJSON/{{param1}}'],
                a3s_architectureBlueprint_getResourceById:['api/getResourceById/{{param1}}'],
                a3s_architectureBlueprint_getResourceByParentResourceId:['api/getResourceByParentResourceId/{{param1}}'],
                a3s_architectureBlueprint_getBlueprintProjectByName:['api/getBlueprintProjectByName/{{param1}}'],
                a3s_architectureBlueprint_getResourceGroupsWithRoleByID:['api/getResourceGroupsWithRoleByID'],
                a3s_architectureBlueprint_getSubscriptionListWithRole:['api/getSubscriptionListWithRole'],
                a3s_architectureBlueprint_updateResourceList:['api/updateResourceList/{{param1}}'],
                a3s_architectureBlueprint_addNewResourceGroup:['api/addNewResourceGroup'],
                a3s_architectureBlueprint_deleteNewResourceGroup:['api/deleteNewResourceGroup/{{param1}}'],
                a3s_architectureBlueprint_updateNewResourceGroup:['api/updateNewResourceGroup'],
                a3s_architectureBlueprint_getNewResourceGroupList:['api/getNewResourceGroupList/{{param1}}'],
                a3s_architectureBlueprint_saveNewResourceGroup:['api/saveNewResourceGroup/{{param1}}'],
                a3s_architectureBlueprint_updateBlueprintProjectResourceData:['api/updateBlueprintProjectResourceData/{{param1}}'],
                a3s_architectureBlueprint_updateDeploymetDetailByProjectId:['api/updateDeploymetDetailByProjectId/{{param1}}'],     
                a3s_architectureBlueprint_checkStorageName:['api/checkStorageName'],  
                a3s_architectureBlueprint_checkSynapseWorkspaceName:['api/checkSynapseWorkspaceName'],  
                a3s_architectureBlueprint_checkKeyName:['api/checkKeyName'],  
                a3s_architectureBlueprint_generateKeyPairForVM:['api/generateKeyPairForVM'],
                a3s_architectureBlueprint_updateMultipleResourceData:['api/updateMultipleResourceData'],        
                a3s_architectureBlueprint_getResourcesDetail:['api/getResourcesDetail/{{param1}}/{{param2}}']          
            },
            serviceDeployment:{
                a3s_serviceDeployment_createRG:['api/createResourceGroup'],
            },
            dataMigration:{
                a3s_dataMigration_allProject:['api/getAllProjects'],
                a3s_dataMigration_getProject:['api/getProjectsInfo/{{param1}}'],
                a3s_dataMigration_updateProject:['api/updateTableDetails/{{param1}}'],
                a3s_dataMigration_addProject:['api/AddProjectInformation'],
                a3s_dataMigration_deleteProject:['api/deleteProject/{{param1}}'],
                a3s_dataMigration_getTableDetails:['api/getTableDetails'],
            },
            dataverse:{
                a3s_dataverse_getAllTables:['api/dataverse/getAllTables'],
            },
            blobStorage:{
                a3s_blobStorage_updateBlobStorageConfig:['api/updateBlobStorageConfig'],
                a3s_blobStorage_getAllBlobDirectories:['api/getAllBlobDirectories'],
                a3s_blobStorage_createBlobMetaData:['api/createBlobMetaData'],
                a3s_blobStorage_selectFiles:['api/selectFiles'],
                a3s_blobStorage_enableFiles:['api/enableFiles'],
                a3s_blobStorage_migrateFiles:['api/migrateFiles'],
                a3s_blobStorage_getBlobStorageOutput:['api/getBlobStorageOutput'],
                a3s_blobStorage_getContainerList:['api/getContainerList'],
            },
            storageAccountDtls:{
                a3s_storageAccountDtls_getStorageAccounts:['api/getStorageAccounts'],
                a3s_storageAccountDtls_getContainers:['api/getContainers'],
            },
            dataAnalytics:{
                a3s_dataAnalytics_addproject:['api/addAnalyticsProject'],
                a3s_dataAnalytics_allProjects:['api/getAllAnalyticsProjects'],
                a3s_dataAnalytics_deleteProject:['api/deleteAnalyticsProject/{{param1}}'],
                a3s_dataAnalytics_updateProject:['api/updateProject'],
                a3s_dataAnalytics_getOutputTables:['api/getAnalyticsOutputTables'],
                a3s_dataAnalytics_updateConfig:['api/updateAnalyticsConfig'],
                a3s_dataAnalytics_getTableColumns:['api/getTableColumns'],
                a3s_dataAnalytics_getInputTableData:['api/getInputTableData'],
                a3s_dataAnalytics_getAllSavedTables:['api/getAllSavedTables'],
                a3s_dataAnalytics_getAnalyticsProjectById:['api/getAnalyticsProjectById/{{param1}}'],
                a3s_dataAnalytics_getSearchResultForKM:['api/a3s/knowledgeMining/search'],
                a3s_dataAnalytics_checkStorageAuth:['api/a3s/storageAccount/validate'],
                a3s_dataAnalytics_getLabelsForKM:['api/a3s/knowledgeMining/labels/all'],
                a3s_dataAnalytics_getOutputForKM:['api/a3s/knowledgeMining/useCase/{{param1}}/project/{{param2}}/output'],
                // a3s_dataAnalytics_saveSelectedLabels:['api/saveSelectedLabels'],
                a3s_dataAnalytics_runSynapsePipeline:['api/a3s/synapse/pipeline/{{param1}}/run'],
                a3s_dataAnalytics_synapsePipelineStatus:['api/a3s/synapse/pipeline/{{param1}}/status'],
                a3s_dataAnalytics_getOutputTableData:['api/getOutputTableData/{{param1}}/{{param2}}'],
                a3s_dataAnalytics_getDashboardOutputForMF:['api/getDashboardOutputForMF/{{param1}}'],
                a3s_dataAnalytics_getRetailDashboard:['api/retailRecommeder/dashboard/{{param1}}'],
            },
            dataOps:{
                a3s_dataOps_addProject:['api/dataOps/project/create'],
                a3s_dataOps_updateProject:['api/dataOps/project/update'],
                a3s_dataOps_deleteProject:['api/dataOps/project/{{param1}}'],
                a3s_dataOps_projectById:['api/dataOps/project/{{param1}}'],
                a3s_dataOps_getAllProject:['api/dataOps/project/all'],
                a3s_dataOps_getSynapseWorkspace:['api/dataOps/subscription/{{param1}}/resourceGroup/{{param2}}/synapseWorkspace'],
                a3s_dataOps_getProfiles:['api/dataOps/workspaceDeployment/getProfiles'],
                a3s_dataOps_validatePatToken:['api/dataOps/workspaceDeployment/patToken/validate'],
                a3s_dataOps_getProjects:['api/dataOps/workspaceDeployment/{{param1}}/getProjects'],
                a3s_dataOps_getOrganization:['api/dataOps/workspaceDeployment/getOrganization'],
                a3s_dataOps_getRepo:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/getRepo'],
                a3s_dataOps_getBranch:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/{{param3}}/getBranch'],
                a3s_dataOps_getVariableGroupDetails:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/getVariableGroupDetails'],
                a3s_dataOps_updateVariableGroup:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/updateVariableGroup'],
                a3s_dataOps_getArtifacts:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/{{param3}}/{{param4}}/getArtifacts'],
                a3s_dataOps_selectPipeline:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/selectPipeline'],
                a3s_dataOps_selectBuild:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/{{param3}}/selectBuild'],
                a3s_dataOps_getAllBuilds:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/getAllBuilds'],
                a3s_dataOps_getBuildById:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/{{param3}}/getBuildById'],
                a3s_dataOps_createBuild:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/{{param3}}/createBuild'],
                a3s_dataOps_createRelease:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/{{param3}}/createRelease'],
                a3s_dataOps_getRelease:['api/dataOps/workspaceDeployment/{{param1}}/{{param2}}/{{param3}}/getRelease'],
            },
            dataGovernance:{
                a3s_dataGovernance_addproject:['api/addDataGovernanceProject'],
                a3s_dataGovernance_allProjects:['api/getAllDataGovernanceProjects'],
                a3s_dataGovernance_projectById:['api/getDataGovernanceProjectById/{{param1}}'],
                a3s_dataGovernance_deleteProject:['api/deleteDataGovernanceProject/{{param1}}'],
                a3s_dataGovernance_updateProject:['api/updateProject'],
                a3s_dataGovernance_registerResource:['api/registerStorageAccountForScan/{{param1}}'],
                a3s_dataGovernance_scanRegisteredSources:['api/scanRegisteredSources/{{param1}}'],
                a3s_dataGovernance_runScanRegisteredSources:['api/runScanRegisteredSources/{{param1}}'],
                a3s_dataGovernance_getJobScanStatus:['api/getJobScanStatus/{{param1}}'],
                a3s_dataGovernance_getAllSavedTables:['api/getAllSavedTables'],
                a3s_dataGovernance_getdataGovernanceProjectById:['api/getdataGovernanceProjectById/{{param1}}'],


                a3s_dataGovernance_getSqlPoolList:['api/azure/workspace/{{param1}}/getSqlPoolList'],
                a3s_dataGovernance_getSqlPoolDetails:['api/azure/workspace/{{param1}}/sqlPool/{{param2}}/getSqlPoolDetails'],
                a3s_dataGovernance_getSqlPoolUsage:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/getSqlPoolUsage'],
                a3s_dataGovernance_getRestorePoints:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/getRestorePoints'],
                a3s_dataGovernance_getBigDataSparkPool:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/getBigDataSparkPool'],
                a3s_dataGovernance_getSparkPool:['api/azure/workspace/{{param1}}/getSparkPool'],
                a3s_dataGovernance_getSparkJobs:['api/azure/workspace/{{param1}}/getSparkJobs'],
                a3s_dataGovernance_getNotebooks:['api/azure/workspace/{{param1}}/getNotebooks'],
                a3s_dataGovernance_getPipelines:['api/azure/workspace/{{param1}}/getPipelines'],
                a3s_dataGovernance_getLibraries:['api/azure/workspace/{{param1}}/getLibraries'],
                a3s_dataGovernance_getIRs:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/getIRs'],
                a3s_dataGovernance_getSparkBatchJobs:['api/azure/workspace/{{param1}}/sqlPool/{{param2}}/getSparkBatchJobs'],


                a3s_dataGovernance_getSqlPoolAdmin:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPoolAdmin'],
                a3s_dataGovernance_getADOnlyAuthentication:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/adAuthentication'],
                a3s_dataGovernance_getWorkspaceADAdmin:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/adAdmin'],
                a3s_dataGovernance_getRBACRoleDefinition:['api/azure/workspace/{{param1}}/rbacRoleDefintion'],
                a3s_dataGovernance_getSynapseRoleDefinition:['api/azure/workspace/{{param1}}/synapseRoleDefintion'],
                a3s_dataGovernance_getSynapseRoleAssignment:['api/azure/workspace/{{param1}}/synapseRoleAssignment'],
                a3s_dataGovernance_getRBACScopes:['api/azure/workspace/{{param1}}/rbacScopes'],

                a3s_dataGovernance_getDataMaskingPolicies:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/dataMaskingPolicies'],
                a3s_dataGovernance_getDataMaskingRules:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/dataMaskingRules'],

                a3s_dataGovernance_getVulnerabilityAssessment:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/vulnerabilityAssessment'],
                a3s_dataGovernance_getVulnerabilityScans:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/vulnerabilityScan'],
                a3s_dataGovernance_getTransparentDataEncryption:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/transparentDataEncryption'],
                a3s_dataGovernance_getPrivateEndpointConnection:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/privateEndpointConnection'],
                a3s_dataGovernance_getSecurityPolicies:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/securityPolicies'],
                a3s_dataGovernance_getBlobAuditingPolicies:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/blobAuditingPolicies'],
                a3s_dataGovernance_getSensitiveLabels:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/sensitiveLabels'],
                a3s_dataGovernance_getRecommendedSensitiveLabels:['api/azure/subscription/{{param1}}/resourceGroup/{{param2}}/workspace/{{param3}}/sqlPool/{{param4}}/recommendSensitiveLabels'],
            },
            dataGovernanceDashboard: {
                a3s_dataGovernanceDashboard_getTilesData:['api/dataGovernance/getTilesData/{{param1}}/{{param2}}'],
                a3s_dataGovernanceDashboard_getRoleAssignmentsTileData:['api/dataGovernance/getRoleAssignmentsTileData/{{param1}}'],
                a3s_dataGovernanceDashboard_getTilesDataForDDC:['api/dataGovernance/getTilesDataForDDC'],
                a3s_dataGovernanceDashboard_getWorkspaceAdministrator:['api/dataGovernance/getWorkspaceAdministrator'],
                a3s_dataGovernanceDashboard_getTilesDataBySprakPoolName:['api/dataGovernance/getTilesDataBySprakPoolName'],
                a3s_dataGovernanceDashboard_getTableListBySQLPool:['api/dataGovernance/getTableListBySQLPool'],
                a3s_dataGovernanceDashboard_getCloumnListBySQLPool:['api/dataGovernance/getCloumnListBySQLPool'],
                a3s_dataGovernanceDashboard_getWorkloadClassifierBySQLPool:['api/dataGovernance/getWorkloadClassifierBySQLPool'],
            },
            formRecognition:{
                a3s_formRecognition_getDirectory:['api/getAllDirectories'],
                a3s_formRecognition_updateFormRecognitionConfig:['api/updateFormRecognitionConfig'],
                a3s_formRecognition_createMetaDataJson:['api/frCreateMetaDataJson'],
                a3s_formRecognition_updateSelectedFiles:['api/updatePathIntoJson'],
                a3s_formRecognition_enableFiles:['api/frEnableFiles'],
                a3s_formRecognition_finalAnalytics:['api/finalAnalytics'],
                a3s_formRecognition_getFrAnalyticsOutput:['api/getFrAnalyticsOutput'],
                a3s_formRecognition_getFrJobStatus:['api/getFrJobStatus/{{param1}}'],
                a3s_formRecognition_getFrDashboardData:['api/getFrDashboardData/{{param1}}'],
                a3s_formRecognition_getDataLabels:['api/getDataLabels'],
                a3s_formRecognition_updateDataLabels:['api/updateDataLabelIntoJson'],
                a3s_formRecognition_startA3SSparkpool:['api/startA3SSparkpool'],
            },
            security:{
                a3s_security_getDataByQuery:['api/security/getDataByQuery'],
                a3s_security_getWorkspaceLogData:['api/security/runLogAnalyticsQuery'],
                a3s_security_getSecureScoreControls:['api/security/getSecureScoreControls'],
                a3s_security_getAllSubscriptions:['api/security/getAllSubscriptions'],
                a3s_security_getAlertsData:['api/security/getAlertsData'],
            },
            compliance:{
                a3s_compliance_getDataByQuery:['api/compliance/getDataByQuery'],
                a3s_compliance_getAssignmentName:['api/compliance/getAssignmentName'],
                a3s_compliance_getModifiedParametersValue:['api/compliance/getModifiedParametersValue'],
                a3s_compliance_getDefaultParametersValue:['api/compliance/getDefaultParametersValue/{{param1}}'],
                a3s_compliance_getReportDetails:['api/compliance/getReportDetails'],
                a3s_compliance_downloadReportFile:['api/compliance/downloadReportFile'],
                a3s_compliance_getStandardValue:['api/compliance/getStandardValue'],
                a3s_compliance_getControlNameList:['api/compliance/getControlNameList/{{param1}}'],
                a3s_compliance_getAllSubscriptions:['api/compliance/getAllSubscriptions'],
                a3s_compliance_downloadStandardReportFile:['api/compliance/downloadStandardReportFile/{{param1}}'],
                a3s_compliance_deleteAssignmentPolicy:['api/compliance/deleteAssignmentPolicy/{{param1}}'],
                a3s_compliance_deleteInitiative:['api/compliance/deleteInitiative/{{param1}}/{{param2}}'],
                a3s_compliance_saveAssignmentPolicy:['api/compliance/saveAssignmentPolicy/{{param1}}/{{param2}}'],
                a3s_compliance_getExemption:['api/compliance/getExemption/{{param1}}/{{param2}}'],
                a3s_compliance_saveExemption:['api/compliance/saveExemption/{{param1}}/{{param2}}'],

                a3s_compliance_additionalStandardsList:['api/compliance/additionalStandards/subscription/{{param1}}/all'],
                a3s_compliance_getManagedIdentityList:['api/compliance/additionalStandards/subscription/{{param1}}/manageIdentity/all'],
                a3s_compliance_getAccessDetails:['api/compliance/additionalStandards/subscription/{{param1}}/manageIdentity/{{param2}}/getAccessDetails'],
                a3s_compliance_additionalStandardsDeploy:['api/compliance/additionalStandards/deploy'],
                a3s_compliance_removeStandard:['api/compliance/additionalStandards/subscription/{{param1}}/blueprint/{{param2}}/assignment/{{param3}}/remove'],
                a3s_compliance_getDeployFailed:['api/compliance/additionalStandards/subscription/{{param1}}/blueprintAssignment/{{param2}}/getDeployFailed'],
            },
            costMonitoringOptimization:{
                //new api
                /*Overview*/
                a3s_costMonitoringOptimization_addSpendingRateAndRest:['api/costMonitoring/subscription/{{param1}}/query'],
                a3s_costMonitoringOptimization_spendingForecast:['api/costMonitoring/subscription/{{param1}}/spendingForecast'],
                /*Analysis*/
                //resource//
                a3s_costMonitoringOptimization_costAnalysisByQuery:['api/costMonitoring/subscription/{{param1}}/query'],
                //Subscription//
                a3s_costMonitoringOptimization_costAnalysisSubscription:['api/costMonitoring/billingAccount/{{param1}}/query'],
                a3s_costMonitoringOptimization_getBillingAccount:['api/costMonitoring/billingAccount'],
                /*Optimize*/
                a3s_costMonitoringOptimization_getRecommendations:['api/costMonitoringOptimization/getRecommendations/{{param1}}'],
                a3s_costMonitoringOptimization_getRecommendationType:['api/costMonitoringOptimization/getRecommendationType'],
                a3s_costMonitoringOptimization_createAlert:['api/costMonitoringOptimization/createAlert/{{param1}}/{{param2}}/{{param3}}'],

                //end
                a3s_costMonitoringOptimization_getOverviewDetails:['api/costMonitoringOptimization/getOverviewDetails'],
                a3s_costMonitoringOptimization_getSubscriptionsList:['api/costMonitoringOptimization/getSubscriptionsList'],
                a3s_costMonitoringOptimization_getSpendingForecast:['api/costMonitoringOptimization/getSpendingForecast/{{param1}}'],
                a3s_costMonitoringOptimization_costDetailByBillingAccount:['api/costMonitoringOptimization/costDetailByBillingAccount/{{param1}}'],
                a3s_costMonitoringOptimization_getBudgets:['api/costMonitoringOptimization/getBudgets'],
                a3s_costMonitoringOptimization_getBudget:['api/costMonitoringOptimization/getBudget'],
                a3s_costMonitoringOptimization_createBudget:['api/costMonitoringOptimization/createBudget/{{param1}}'],
                a3s_costMonitoringOptimization_deleteBudget:['api/costMonitoringOptimization/deleteBudget/{{param1}}'],
                a3s_costMonitoringOptimization_getReservationTransList:['api/costMonitoringOptimization/getReservationTransList/{{param1}}/{{param2}}/{{param3}}'],
                a3s_costMonitoringOptimization_getInvoiceSectionList:['api/costMonitoringOptimization/getInvoiceSectionList/{{param1}}'],
                a3s_costMonitoringOptimization_getInvoiceSectionCharges:['api/costMonitoringOptimization/getInvoiceSectionCharges/{{param1}}'],
                a3s_costMonitoringOptimization_addInvoiceSection:['api/costMonitoringOptimization/addInvoiceSection/{{param1}}/{{param2}}/{{param3}}'],
                a3s_costMonitoringOptimization_getGarphicalCostData:['api/costMonitoringOptimization/getGarphicalCostData'],

                //Billings
                a3s_costMonitoringOptimization_getBillingAccountListWithDetail:['api/costMonitoring/billingAccount/detailed/all'],
                a3s_costMonitoringOptimization_getBillingProfileList:['api/costMonitoring/billingAccount/{{param1}}/billingProfiles'],
                a3s_costMonitoringOptimization_getInvoiceList:['api/costMonitoring/billingAccount/{{param1}}/billingProfiles/{{param2}}/invoices/all'],
                a3s_costMonitoringOptimization_getReservedTransactionList:['api/costMonitoring/billingAccount/{{param1}}/billingProfiles/{{param2}}/reservedTransaction/all'],
                a3s_costMonitoringOptimization_downloadInvoices:['api/costMonitoring/invoices/download'],
                a3s_costMonitoringOptimization_getProjectCostData:['api/costMonitoring/subscription/{{param1}}/getProjectCostData/all'],
                // a3s_costMonitoringOptimization_addBillingProfile:['api/costMonitoringOptimization/addBillingProfile/{{param1}}/{{param2}}'],
            },
            thresholds: {
                a3s_thresholds_getKeyVaults:['api/getThresholds/{{param1}}'],
                a3s_thresholds_insertKeyVaults:['api/insertThresholds'],
            }
        }
    }
}
