import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PublicClientApplication, AuthorizationUrlRequest, SilentRequest, AuthenticationResult, Configuration, LogLevel, AccountInfo, InteractionRequiredAuthError, EndSessionRequest, RedirectRequest, PopupRequest } from "@azure/msal-browser";
import { FlowService } from './flow.service';
import { environment } from 'src/environments/environment';
//  var url = 'https://app.azureautomation.ai';
// var url = 'http://localhost:4200';
var url = environment.a3sUrl
@Injectable({
    providedIn: 'root'
})


export class LoginMsalService {

    clientId: any;
    tenantId: any;
    MSAL_CONFIG: Configuration = {
        auth: {
            clientId: '',
            authority: '',
            redirectUri: url,
            postLogoutRedirectUri: url
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                }
            }
        }
    };
    GRAPH_CONFIG = {
        GRAPH_ME_ENDPT: "https://graph.microsoft.com/v1.0/me",
        GRAPH_MAIL_ENDPT: "https://graph.microsoft.com/v1.0/me/messages"
    };
    private myMSALObj: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
    private account: AccountInfo; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
    private loginRedirectRequest: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
    private loginRequest: PopupRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html
    private profileRedirectRequest: RedirectRequest;
    private profileRequest: PopupRequest;
    private mailRedirectRequest: RedirectRequest;
    private mailRequest: PopupRequest;
    private silentProfileRequest: SilentRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_silentrequest_.html
    private silentMailRequest: SilentRequest;

    constructor(private http: HttpClient, private flow: FlowService) {
        this.setRequestObjects();
        if (localStorage.getItem('cred') && JSON.parse(localStorage.getItem('cred')).ClientID && JSON.parse(localStorage.getItem('cred')).TenantID) {
            this.MSAL_CONFIG.auth.clientId = JSON.parse(localStorage.getItem('cred')).ClientID;
            this.MSAL_CONFIG.auth.authority = "https://login.microsoftonline.com/" + JSON.parse(localStorage.getItem('cred')).TenantID;
            this.account = null;
            this.myMSALObj = new PublicClientApplication(this.MSAL_CONFIG);
            setTimeout(() => { this.loadAuthModule() }, 200);
        }
    }


    /**
     * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
     * TODO: Add account chooser code
     * 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

    private setRequestObjects(): void {
        this.loginRequest = {
            scopes: ["https://management.core.windows.net//user_impersonation"]
        };

        this.loginRedirectRequest = {
            ...this.loginRequest,
            redirectStartPage: url
        };

        this.profileRequest = {
            scopes: ["User.Read"]
        };

        this.profileRedirectRequest = {
            ...this.profileRequest,
            redirectStartPage: url
        };
    }

    private getAccount(): AccountInfo {
        // need to call getAccount here?
        const currentAccounts = this.myMSALObj.getAllAccounts();
        if (currentAccounts === null) {
            console.log("No accounts detected");
            return null;
        }

        if (currentAccounts.length > 1) {
            // Add choose account code here
            // console.log("Multiple accounts detected, need to add choose account code.");
            return currentAccounts[0];
        } else if (currentAccounts.length === 1) {
            return currentAccounts[0];
        }
    }

    /**
     * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
     * 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
     */
    loadAuthModule(): void {
        this.myMSALObj.handleRedirectPromise().then((resp: AuthenticationResult) => {
            this.handleResponse(resp);
        }).catch(console.error);
    }

    /**
     * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
     * @param response 
     */
    handleResponse(response: AuthenticationResult) {
        if (response !== null) {
            this.account = response.account;
        } else {
            this.account = this.getAccount();
        }

        if (this.account) {
            sessionStorage.setItem("redirectToken", "yes")
            //    console.log(this.account,'Account')
        }
    }


    /**
     * Calls loginPopup or loginRedirect based on given signInType.
     * @param signInType 
     */
    login(signInType: string): void {
        this.MSAL_CONFIG.auth.clientId = JSON.parse(localStorage.getItem('cred')).ClientID;
        this.MSAL_CONFIG.auth.authority = "https://login.microsoftonline.com/" + JSON.parse(localStorage.getItem('cred')).TenantID;
        this.myMSALObj = new PublicClientApplication(this.MSAL_CONFIG);
        if (signInType === "loginPopup") {
            this.myMSALObj.loginPopup(this.loginRequest).then((resp: AuthenticationResult) => {
                this.handleResponse(resp);
            }).catch(console.error);
        } else if (signInType === "loginRedirect") {
            this.myMSALObj.loginRedirect(this.loginRedirectRequest);


        }
    }

    /**
     * Logs out of current account.
     */
    logout(): void {
        this.flow.logoutClearDb().subscribe(
            result => {
                sessionStorage.removeItem('data');
                sessionStorage.removeItem('redirectToken');
                // console.log(this.account);
                const logOutRequest: EndSessionRequest = {
                    account: this.account,
                    postLogoutRedirectUri: url,
                    authority: "https://login.microsoftonline.com/" + JSON.parse(localStorage.getItem('cred')).TenantID,
                    correlationId: "/"
                };
                localStorage.clear();
                this.myMSALObj.logout(logOutRequest);
            },
            err => {

                sessionStorage.removeItem('data');
                sessionStorage.removeItem('redirectToken');
                // console.log(this.account);
                const logOutRequest: EndSessionRequest = {
                    account: this.account,
                    postLogoutRedirectUri: url,
                    authority: "https://login.microsoftonline.com/" + JSON.parse(localStorage.getItem('cred')).TenantID,
                    correlationId: "/"
                };
                localStorage.clear();
                this.myMSALObj.logout(logOutRequest);
            }
        )

    }

    /**
     * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
     */
    async getProfileTokenRedirect(): Promise<string> {
        this.silentProfileRequest.account = this.account;
        return this.getTokenRedirect(this.silentProfileRequest, this.profileRedirectRequest);
    }

    /**
     * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
     */
    async getProfileTokenPopup(): Promise<string> {
        this.silentProfileRequest.account = this.account;
        return this.getTokenPopup(this.silentProfileRequest, this.profileRequest);
    }

    /**
     * Gets the token to read mail data from MS Graph silently, or falls back to interactive redirect.
     */
    async getMailTokenRedirect(): Promise<string> {
        this.silentMailRequest.account = this.account;
        return this.getTokenRedirect(this.silentMailRequest, this.mailRedirectRequest);
    }

    /**
     * Gets the token to read mail data from MS Graph silently, or falls back to interactive popup.
     */
    async getMailTokenPopup(): Promise<string> {
        this.silentMailRequest.account = this.account;
        return this.getTokenPopup(this.silentMailRequest, this.mailRequest);
    }

    /**
     * Gets a token silently, or falls back to interactive popup.
     */
    private async getTokenPopup(silentRequest: SilentRequest, interactiveRequest: PopupRequest): Promise<string> {
        try {
            const response: AuthenticationResult = await this.myMSALObj.acquireTokenSilent(silentRequest);
            return response.accessToken;
        } catch (e) {
            // console.log("silent token acquisition fails.");
            if (e instanceof InteractionRequiredAuthError) {
                // console.log("acquiring token using redirect");
                return this.myMSALObj.acquireTokenPopup(interactiveRequest).then((resp) => {
                    return resp.accessToken;
                }).catch((err) => {
                    console.error(err);
                    return null;
                });
            } else {
                console.error(e);
            }
        }
    }

    getnewToken() {
        if (JSON.parse(sessionStorage.getItem('data')) !== null) {
            if (JSON.parse(sessionStorage.getItem('data'))['token'].length > 1) {
                let username: any = '';
                for (let i = 0; sessionStorage.key(i); i++) {
                    if (localStorage.getItem('cred') && JSON.parse(localStorage.getItem('cred')) && sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                        username = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['username']
                    }
                }
                if (username != '') {
                    let currentAccount = this.myMSALObj.getAccountByUsername(username);
                    let silentRequest = {
                        scopes: ["https://management.core.windows.net//user_impersonation"],
                        account: currentAccount,
                        forceRefresh: false
                    };
                    var request = {
                        scopes: ["https://management.core.windows.net//user_impersonation"],
                        loginHint: currentAccount.username // For v1 endpoints, use upn from idToken claims
                    };

                    this.getTokenRedirect(silentRequest, request)
                }
            }
        }
    }

    private async getTokenRedirect(silentRequest, interactiveRequest): Promise<string> {
        var data = { token: '' };
        try {
            const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
            data['token'] = response.accessToken;
            //   console.log('access Token',response.accessToken)    
            sessionStorage.setItem('data', JSON.stringify(data));
            return response.accessToken;
        } catch (e) {
            //   console.log("silent token acquisition fails.");
            if (e instanceof InteractionRequiredAuthError) {
                //   console.log("acquiring token using redirect");
                this.myMSALObj.acquireTokenRedirect(interactiveRequest).catch(console.error);
            } else {
                console.error(e);
            }
        }
    }
}
