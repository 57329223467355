import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IPv4, IPSubnetwork } from 'ip-matching';
import * as _ from 'lodash';
import * as moment from 'moment';
import { resourceObject } from 'src/app/@model/resource-model';
import { LoaderService } from 'src/app/services/loader.service';
import { UtilService } from '../../../services/util.service';
import { NewResourceGroup, ResourceInputError, VMResourceModel } from '../resources-model';

@Component({
  selector: 'app-virtual-machine-resource',
  templateUrl: './virtual-machine-resource.component.html',
  styleUrls: ['./virtual-machine-resource.component.scss']
})
export class VirtualMachineResourceComponent implements OnInit {
  @Input() projectData : any;
  @Input() templateName : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  virtualMachinemodel : VMResourceModel = {
    resourceId:null,
    resourceName:null,
    resourceType:'virtualMachines',
    resourceData:{
      vnetName:null,
      subnet:null,
      selectedVMSize:null,
      resourceGroup:null,
      tagList:[{
        tagName:null,
        value:null
      }],
      authType:null,
      adminUsername:null,
      adminPassword:null,
      adminConfirmPassword:null,
      sshPublicKey:null,
      keyPairName:null,
      storedKeys:null,
      publicKey:null,
      availabilityOptionList:null,
      availabilityOption:null,
      tempAvailabilityZoneList:null,
      availabilityZone:null,
      availabilitySet:null,
      image:null,
      osDiskType:null,
      diskEncryptionType:null,
      diskEncryptionSet:null,
      newDiskList:null,
      publicIp:null,
      nicNSG:null,
      networkSecurityGroup:null,
      bootDiagnostics:null,
      enableOSGuestDiagnostics:null,
      diagnosticsStorageAccount:null,
      loginWithAzureAD:null,
      systemAssignedManagedIdentity:null,
      autoShutdownStatus:null,
      autoShutdownTime:null,
      timeZone:null,
      autoShutdownNotificationStatus:null,
      email:null,
      VmSize:null,
      vmPrivateIpAddress:null,
      redundancy:null
    },
    comments:null,
    createdBy:null,
    publicIPShow:false,
    newDiskShow:false,
    nsgShow:false,
    availabilitySetShow:false,
    storageAccountShow:false,
  };
  show : boolean = false;
  VmSizes : any = [];
  storageSkusList:any=[];
  tempStorageSkusList:any=[];
  resourcesGroupLocationList: any = [];
  inputError:ResourceInputError = {
    VMname:false,
    VmSpecialChar:false,
    VMOnlyNUmbers:false,
    duplicateVnet:false,
    VMAdminReserv:false,
    VmAdminSpecialChar:false,
    VMAdminUsername:false,
    VMAdminPassword:false,
    VmAdminPasswordLen:false,
    VMAdminPasswordMatch:false,
    keyPairNameSpChar:false,
    keyPairNameDup:false,
    publicKeyValid:false,
    sshKeyBase64Valid:false,
    VMdescription:false,
    validTimeFormat:false,
    nameLen:false,
    nameValid:false,
    nameDupCheck:false,
    asFaultDomainsLen:false,
    asFaultDomainsMaxLen:false,
    asUpdateDomainsLen:false,
    asUpdateDomainsMaxLen:false,
    storageAccountName:false,
    storageAccountChar:false,
    duplicateSa:false,
  }
  newResourceGroup: NewResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
    newResourceGroupEdit:false,
  };
  resourceGroupList:any=[];
  nsgList:any=[];
  showView:boolean=false;
  previousDiskSize:any;
  subnetsObj:any=resourceObject.subnetsObj;
  publicIPObj:any=resourceObject.publicIPObj;
  newDiskObj:any=resourceObject.newDiskObj;
  vmNSGObj:any=resourceObject.vmNSGObj;
  vmStorageAccount:any=resourceObject.vmStorageAccount;
  vmASObj:any={
    faultDomains:2,
    updateDomains:5
  };
  availabilityOptionList=[];
  availabilityOptionWithoutZoneList:any=[
    {name:'No infrastructure redundancy required',id:'None'},
    {name:'Availability set',id:'availabilitySet'}
  ];

  availabilitySetList:any=[];
  osDiskTypeList:any=[
    {name:'Premium SSD',id:'Premium_LRS'},
    {name:'Standard SSD',id:'StandardSSD_LRS'},
    {name:'Standard HDD',id:'Standard_LRS'}
  ];
  vmPublicIPList:any=[
    {name:'None'}
  ];
  tempVmPublicIPList:any=[];
  availabilityZoneList:any=[
    {name:'1'},
    {name:'2'},
    {name:'3'}
  ];
  tempAvailabilityZoneList:any=[];
  accountKindList:Array<{name:string,id:string}>=[
    {name:'StorageV2 (general purpose v2)',id:'StorageV2'},
    {name:'Storage (general purpose v1)',id:'Storage'}
  ]
  saList:any=[];
  diskList:any=[];
  imageList:Array<{}>=[];
  diskEncryptionTypeList:any=[
    {name:'(default) Encryption at-rest with a platform-managed key',id:'1'},
    {name:'Encryption at-rest with a customer-managed key',id:'2',"encryptionType": "EncryptionAtRestWithCustomerKey"},
    {name:'Double encryption with platform-managed and customer-managed keys',id:'3',"encryptionType": "EncryptionAtRestWithPlatformAndCustomerKeys"}
  ];
  sourceTypeList:any=[
    {name:'None (empty disk)'},
    {name:'Snapshot'}
  ];
  maxSharesList:any=[];
  sshPublicKeyList:Array<{name:string,id:string}>=[
    {name:'Generate new key pair',id:'genNewKeyPair'},
    {name:'Use existing key stored in Azure',id:'useExistKeyAzure'},
    {name:'Use existing public key',id:'useExistPublicKey'}
  ];
  storedKeysList:any=[];
  snapshotList:any=[];
  virtualMachinesList:any=[];
  diskEncryptionMainSetList:any=[];
  diskEncryptionSetList:any=[];
  diskEncryptionSetListForDisk:any=[];
  storageAccountList:any=[];
  timeZoneList:Array<{name:string,time:string}>=[];
  existingNewVMList:any=[];
  existingNewPIPList: any=[];
  existingNewNSGList: any=[];
  existingNewASList: any=[];
  existingNewSAList: any=[];
  existingNewDiskList: any=[];
  subResourcesList: any = [];
  disallowedUsernameList: any=[];
  
  constructor(private util:UtilService,private loader:LoaderService) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if(changes.currentResourceDtls&&changes.currentResourceDtls.currentValue&&changes.currentResourceDtls.currentValue.resourceData){
      if(changes.currentResourceDtls.currentValue.resourceData.selectedVMSize){
      this.virtualMachinemodel.resourceData.selectedVMSize=changes.currentResourceDtls.currentValue.resourceData.selectedVMSize;
      }
      this.virtualMachinemodel.resourceData.availabilityOptionList=changes.currentResourceDtls.currentValue.resourceData.availabilityOptionList;
      this.virtualMachinemodel.resourceData.tempAvailabilityZoneList=changes.currentResourceDtls.currentValue.resourceData.tempAvailabilityZoneList;
      this.virtualMachinemodel.resourceData.availabilityZone=changes.currentResourceDtls.currentValue.resourceData.availabilityZone;
      this.virtualMachinemodel.resourceData.availabilityOption=changes.currentResourceDtls.currentValue.resourceData.availabilityOption;
    }
    if(changes.currentResourceDtls&&changes.currentResourceDtls.currentValue&&changes.currentResourceDtls.currentValue.newVMDiskSize){
    this.newDiskObj.newVMDiskSize=changes.currentResourceDtls.currentValue.newVMDiskSize;
    }
    if(changes.currentResourceDtls&&changes.currentResourceDtls.currentValue&&(changes.currentResourceDtls.currentValue.vmInBoundRule||changes.currentResourceDtls.currentValue.vmOutBoundRule)){
      this.vmNSGObj.vmInBoundRule=changes.currentResourceDtls.currentValue.vmInBoundRule;
      this.vmNSGObj.vmOutBoundRule=changes.currentResourceDtls.currentValue.vmOutBoundRule;
      }
  }

  async ngOnInit() {
    this.loader.showLoader();
    console.log('templateName',this.templateName)
    this.resourcesGroupLocationList = this.util.getStatic('countryList');
    this.disallowedUsernameList = this.util.getStatic('disallowedUsernameList');
    await this.getResourceGroupList();
    if(this.templateName=='sqlVirtualMachines'){
      this.virtualMachinemodel.resourceType='sqlVirtualMachines';
      this.imageList=this.util.getStatic('sqlVMImageList');
    }else if(this.templateName=='linuxVirtualMachines'){
      this.virtualMachinemodel.resourceType='linuxVirtualMachines';
      this.imageList=this.util.getStatic('linuxVMImageList');
    }else{
      this.imageList=this.util.getStatic('vmImageList');
    }
    let parentResourceData,resourceObj;
    if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
      this.virtualMachinemodel.resourceData.subnet=this.parentResourceDtls.resourceName;
      parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
      this.virtualMachinemodel.resourceData.vnetName=parentResourceData.vnetName;
      resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
      this.virtualMachinemodel.resourceData.resourceGroup=resourceObj.name;
      this.virtualMachinemodel.resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
      this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
    }      
    this.virtualMachinemodel.resourceData.osDiskType=this.osDiskTypeList[0].id;
    this.virtualMachinemodel.resourceData.nicNSG='Basic';
    this.virtualMachinemodel.resourceData.publicIp={name:'None'};
    this.virtualMachinemodel.resourceData.availabilityOption='None';
    this.virtualMachinemodel.resourceData.bootDiagnostics='EWMSA';
    this.virtualMachinemodel.resourceData.diskEncryptionType=this.diskEncryptionTypeList[0].id;
    this.virtualMachinemodel.resourceData.image=this.imageList[0];
    this.virtualMachinemodel.resourceData.sshPublicKey=this.sshPublicKeyList[0].id;
    this.virtualMachinemodel.resourceData.resourceGroupLocation=resourceObj?resourceObj.location:null;
    this.virtualMachinemodel.resourceData.authType='ssh';
    this.virtualMachinemodel.resourceData.autoShutdownStatus=true;
    this.virtualMachinemodel.resourceData.autoShutdownNotificationStatus=true;
    this.timeZoneList=this.util.getStatic('timeZoneList');
    this.virtualMachinemodel.resourceData.timeZone='UTC';
    this.virtualMachinemodel.resourceData.email= localStorage.getItem('email');
    this.virtualMachinemodel.resourceData.autoShutdownTime='7:00:00 PM';
    await this.getListByPropName('publicIPAddresses',undefined,undefined);
    await this.getListByPropName('snapshots',undefined,undefined);
    // await this.getListByPropName('disk',resourceObj,undefined);
    await this.getListByPropName('virtualMachines',undefined,undefined);
    await this.getListByPropName('diskEncryptionSets',undefined,undefined);
    await this.getListByPropName('storageAccounts',undefined,undefined);
    await this.getListByPropName('networkSecurityGroups',undefined,undefined);
    await this.getListByPropName('sshPublicKeys',undefined,undefined); 
    if(resourceObj && resourceObj.location) {
      await this.getListByPropName('virtualMachineSizeList',resourceObj,resourceObj.location);
      await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
    }
    if(!resourceObj.resourceGroupId){
      await this.getListByPropName('availabilitySets',resourceObj,undefined);
    } else {}
    this.virtualMachinemodel.resourceData.availabilityOptionList=this.availabilityOptionWithoutZoneList;
    this.virtualMachinemodel.resourceData.tempAvailabilityZoneList=this.availabilityZoneList;
    this.tempVmPublicIPList=this.vmPublicIPList;
    this.assignPrivateIPToVM();
    await this.getExistNewlyResourceDetails('virtualMachines');
    await this.getExistNewlyResourceDetails('publicIPAddress');
    await this.getExistNewlyResourceDetails('networkSecurityGroups');
    await this.getExistNewlyResourceDetails('availabilitySets');
    await this.getExistNewlyResourceDetails('storageAccounts');
    await this.getExistNewlyResourceDetails('disks');
    
    if(this.sideSheetViewType=='edit'){
      this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null).then(async res=>{
        this.virtualMachinemodel=res;
        this.virtualMachinemodel.resourceData=JSON.parse(res.resourceData);
        if (this.virtualMachinemodel.resourceData.networkSecurityGroup && this.virtualMachinemodel.resourceData.networkSecurityGroup.name) {
          let findObj=this.nsgList.find(dt => dt.name == this.virtualMachinemodel.resourceData.networkSecurityGroup.name);
          if(!findObj){
            this.nsgList.push(this.virtualMachinemodel.resourceData.networkSecurityGroup);
          }
        }
        if (this.virtualMachinemodel.resourceData.publicIp && this.virtualMachinemodel.resourceData.publicIp.name) {
          let findObj=this.tempVmPublicIPList.find(dt => dt.name == this.virtualMachinemodel.resourceData.publicIp.name);
          if(!findObj){
            this.tempVmPublicIPList.push(this.virtualMachinemodel.resourceData.publicIp);
          }
        }
        if (this.virtualMachinemodel.resourceData.diagnosticsStorageAccount && this.virtualMachinemodel.resourceData.diagnosticsStorageAccount.name) {
          let findObj=this.storageAccountList.find(dt => dt.name == this.virtualMachinemodel.resourceData.diagnosticsStorageAccount.name);
          if(!findObj){
            this.storageAccountList.push(this.virtualMachinemodel.resourceData.diagnosticsStorageAccount);
          }
        }
        if(this.parentResourceDtls['updateRequestFrom']){
          this.virtualMachinemodel.resourceData.vnetName=this.parentResourceDtls.resourceName;
          this.virtualMachinemodel.resourceData.resourceGroup=resourceObj.name;
        }
      });
      this.util.handleRequest('get','a3s_architectureBlueprint_getResourceByParentResourceId',[this.currentResourceDtls.resourceId],null).then(async res=>{
        this.subResourcesList=res?res:[];
      });
    }

    this.loader.hideLoader();    
  }

  async getExistNewlyResourceDetails(resourceType) {
    await this.util.handleRequest('get','a3s_architectureBlueprint_getResourcesDetail',[this.projectData.projectId,resourceType],null,null,null,true).then(async res=>{
      if(!res){
        return;
      }
      if(resourceType==='virtualMachines'){
        this.existingNewVMList=res;
      }
      if(resourceType==='publicIPAddress'){
        this.existingNewPIPList=res;
      }
      if(resourceType==='networkSecurityGroups'){
        this.existingNewNSGList=res;
      }
      if(resourceType==='availabilitySets'){
        this.existingNewASList=res;
      }
      if(resourceType==='storageAccounts'){
        this.existingNewSAList=res;
      }
      if(resourceType==='disks'){
        this.existingNewDiskList=res;
      }
    },err=>{
    });
  }

  assignPrivateIPToVM(){
    if(this.sideSheetViewType=='new'){
      if(this.parentResourceDtls.resourceData){
        let resourceData = JSON.parse(this.parentResourceDtls.resourceData);
        if(resourceData.subnetAddressSpace){
          let splitArr=resourceData.subnetAddressSpace.split('/');
          let ipRange;
          ipRange = new IPSubnetwork(new IPv4(splitArr[0]), splitArr[1]);
          if(ipRange){            
            for(let i=4;i<ipRange.range.right.parts[3];i++){
              let ipVal=_.cloneDeep(ipRange.range.left.parts);
            ipVal[3]=ipRange.range.left.parts[3]+i;
            let latestIP=ipVal.join('.');
            let findObjIP;
            if (this.parentResourceDtls.devices) {
              findObjIP=this.parentResourceDtls.devices.find(ds => {
                if (ds.resourceData) {
                  let resourceData = JSON.parse(ds.resourceData);
                  if (resourceData.privateIPAddrSpace && resourceData.privateIPAddrSpace == latestIP) {
                    return true;
                  }
                  else if (resourceData.vmPrivateIpAddress && resourceData.vmPrivateIpAddress == latestIP) {
                    return true;
                  }
                }
              })
            }
            if(!findObjIP){
            this.virtualMachinemodel.resourceData.vmPrivateIpAddress=latestIP;
            break;
            }
          }
          }            
        }
      }
    }
  }

  async resourceGroupChange(){
    if(this.virtualMachinemodel&&this.virtualMachinemodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.virtualMachinemodel.resourceData.resourceGroup);
      if(!resourceObj.resourceGroupId){
        this.loader.showLoader();
        await this.getListByPropName('availabilitySets',resourceObj,undefined);
        this.loader.hideLoader();
        this.virtualMachinemodel.resourceData.availabilitySet=undefined;
      }else{
        this.availabilitySetList=[];
        this.virtualMachinemodel.resourceData.availabilitySet=undefined;
      }
    }
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
      });
  }

  onToggle(eventValue): void {
    if(eventValue) {
      this.newResourceGroup=eventValue;
      if(!this.newResourceGroup.newResourceGroupEdit) {
        let tempResGroup=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        let tempRes=_.cloneDeep(this.newResourceGroup);
        tempRes['name']=this.newResourceGroup.resourceGroupName;
        tempRes['location']=this.newResourceGroup.resourceGroupLocation;
        tempRes['resourceGroupId']=this.newResourceGroup.resourceGroupId;
        tempResGroup.push(tempRes);
        this.resourceGroupList=tempResGroup;
      } else if (this.newResourceGroup.newResourceGroupEdit) {
        let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==this.newResourceGroup.resourceGroupId);
        let tempRes=_.cloneDeep(this.resourceGroupList);
        this.resourceGroupList=[];
        tempRes[indexR]['name']=this.newResourceGroup.resourceGroupName;
        tempRes[indexR]['location']=this.newResourceGroup.resourceGroupLocation;
        this.resourceGroupList=tempRes;
      }
      this.virtualMachinemodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.show = !this.show;
    this.newResourceGroup= {
      subscriptionId: null,
      resourceGroupName: null,
      resourceGroupLocation : null,
      subscription:null,
      newResourceGroupEdit:false,
    }
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }

      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      this.virtualMachinemodel.resourceData.resourceGroup=undefined;
      
    });
  }

  editNewResourceGroup(item,index){
    item.newResourceGroupEdit=true;
    this.newResourceGroup=_.cloneDeep(item); 
    this.show = !this.show;
  }

  async getNewResourceGroup(){
    await this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,true).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addTagVM(){
    this.virtualMachinemodel.resourceData.tagList.push({
      tagName:null,
      value:null
    });
  }

  deleteTagVM(index){
    this.virtualMachinemodel.resourceData.tagList.splice(index,1);
  }

  deleteDisk(index){
    this.virtualMachinemodel.resourceData.newDiskList.splice(index,1);
  }

  editDisk(index){
    this.newDiskObj=this.virtualMachinemodel.resourceData.newDiskList[index];
    this.newDiskObj.updateIndex=index;
    this.virtualMachinemodel.newDiskShow=true;
    this.previousDiskSize=this.newDiskObj.newVMDiskSize.customDiskSize;
  }

  createNewDisk(){
    this.newDiskObj={
      sourceType:'None (empty disk)',
      encryptionType:'1',
      enableSharedDisk:'No'
    };
    this.previousDiskSize=undefined;
    this.virtualMachinemodel.newDiskShow=true;
  }

  createNewPublicIP(){
    if(this.vmPublicIPList&&this.vmPublicIPList.length>0){
      let findObj=this.vmPublicIPList.find(dt=>dt.createNew);
      if(findObj){
        this.publicIPObj=findObj;
      }else{
        this.publicIPObj = {
          SKU:'Basic',
          assignment:'Static',
          routingPreference:'microsoftNetwork'
        }
      }
    }else{
      this.publicIPObj = {
        SKU:'Basic',
        assignment:'Static',
        routingPreference:'microsoftNetwork'
      }
    }
    this.virtualMachinemodel.publicIPShow=true;
  }

  createNewVmAS(){
    if(this.availabilitySetList&&this.availabilitySetList.length>0){
      let findObj=this.availabilitySetList.find(dt=>dt.createNew);
      if(findObj){
        this.vmASObj=findObj;
      }
    }else{
      this.vmASObj = {
        faultDomains:2,
        updateDomains:5
      }
    }
    this.virtualMachinemodel.availabilitySetShow=true;
  }

  createNewNSG(){
    if(this.nsgList&&this.nsgList.length>0){
      let findObj=this.nsgList.find(dt=>dt.createNew);
      if(findObj){
        this.vmNSGObj=findObj;
      }else{
        this.vmNSGObj={};
      }
    }else{
      this.vmNSGObj={};
    }
    this.virtualMachinemodel.nsgShow=true;
  }

  createNewStorageAcount(){
    if(this.storageAccountList&&this.storageAccountList.length>0){
      let findObj=this.storageAccountList.find(dt=>dt.createNew);
      if(findObj){
        this.vmStorageAccount=findObj;
      }else{
        this.vmStorageAccount = {
          kind: 'Storage',
          performance: 'Standard'
        };
      }
    }else{
      this.vmStorageAccount = {
        kind: 'Storage',
        performance: 'Standard'
      };
    }
    this.filterStorageSkus(this.vmStorageAccount.performance, this.vmStorageAccount.kind);
    this.virtualMachinemodel.storageAccountShow=true;
  }

  hideShow(){
    this.showView=!this.showView;
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj,null,null,true).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      let parentResourceData=this.parentResourceDtls&&this.parentResourceDtls.resourceData?JSON.parse(this.parentResourceDtls.resourceData):{};
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name==this.virtualMachinemodel&&this.virtualMachinemodel.resourceData&&this.virtualMachinemodel.resourceData.resourceGroup?this.virtualMachinemodel.resourceData.resourceGroup:parentResourceData.resourceGroup);
      if(propName=='networkSecurityGroups'){
        this.nsgList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }else if(propName=='virtualMachineSizeList'){
        this.VmSizes=res.value;
      }else if(propName=='storageSkus'){
        this.storageSkusList=res.value;
      }else if(propName=='publicIPAddresses'){
        let PIPResult=[{
          name:"None"
        }];
        if(res.value&&res.value.length>0)
        PIPResult.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.vmPublicIPList=PIPResult;
      }else if(propName=='virtualMachines'){
        this.virtualMachinesList=res.value;
      }else if(propName=='snapshots'){
        this.snapshotList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }else if(propName=='availabilitySets'){
        this.availabilitySetList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }else if(propName=='diskEncryptionSets'){
        this.diskEncryptionMainSetList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }else if(propName=='storageAccounts'){
        this.storageAccountList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      } else if (propName == 'sshPublicKeys') {
        this.storedKeysList = res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }
      return  result;
    },err=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='networkSecurityGroups'){
        this.nsgList=result;
      }else if(propName=='publicIPAddresses'){
        let PIPResult=[{
          name:"None"
        }];
        this.vmPublicIPList=PIPResult;
      }
    });
  }

  async clearResourceModel(){
    this.virtualMachinemodel.resourceName=null;
    this.virtualMachinemodel.resourceData.VmSize=null;
    this.virtualMachinemodel.comments=null;
  }

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
      await this.clearResourceModel();
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      modalDtls=_.cloneDeep(this.virtualMachinemodel);
      modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
      await this.clearResourceModel();
    }else if(flag=='vmSize'||flag=='newDisk'||flag=='inboundRules'||flag=='outboundRules'){
      this.triggerBtnvalue.emit({value:false,data:flag=='newDisk'?this.newDiskObj:flag=='inboundRules'||flag=='outboundRules'?(this.templateName=='nsg'?this.virtualMachinemodel.resourceData:this.vmNSGObj):this.virtualMachinemodel,vmSizeList:this.VmSizes,type:flag})
    }
    else if(flag=='tempDisk'){
      this.triggerBtnvalue.emit({value:false,data:this.virtualMachinemodel.resourceData,vmSizeList:this.VmSizes,type:flag})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  changeSKU(event){
    if(event.target.value=='Standard'){
    this.publicIPObj.SKU='Standard';
    this.publicIPObj.assignment='Static';
    this.publicIPObj.routingPreference='microsoftNetwork';
    }else if(event.target.value=='Basic'){
      this.publicIPObj.SKU='Basic';
      this.publicIPObj.assignment='Dynamic';
      this.publicIPObj.routingPreference='microsoftNetwork';
      }
  }

  checkNSGName(event,limit){
    let value=event.target.value;
    this.inputError.nameLen=false;
    this.inputError.nameValid=false;
    this.inputError.nameDupCheck=false;
    if(value){
      if(value.length>limit){
        this.inputError.nameLen=true;
        return;
      }
      if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
        this.inputError.nameValid = false;
      }
      else{
        this.inputError.nameValid = true;
        return;
      }
  
      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
          this.inputError.nameValid = true;
          return;
        }
      }
    
      if(this.nsgList&&this.nsgList.length>0){
        let findObj=this.nsgList.find((dt,index)=>dt.name.toLowerCase()==value.toLowerCase()&&dt.id);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.existingNewNSGList){
        let findObj=this.existingNewNSGList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase());
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.existingNewVMList){
        let findObj=this.existingNewVMList.find(dt=>{
          if(dt.resourceData&&dt.resourceId!==this.virtualMachinemodel.resourceId) {
            const resourceData=JSON.parse(dt.resourceData);
            if(resourceData.networkSecurityGroup) {
              return resourceData.networkSecurityGroup.name.toLowerCase()==value.toLowerCase();
            }
          }
        });
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
    }
  }

  checkVmASName(event,limit){
    let value=event.target.value;
    this.inputError.nameLen=false;
    this.inputError.nameValid=false;
    this.inputError.nameDupCheck=false;
    if(value){
      if(value.length>limit){
        this.inputError.nameLen=true;
        return;
      }
      if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
        this.inputError.nameValid = false;
      }
      else{
        this.inputError.nameValid = true;
        return;
      }
  
      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
          this.inputError.nameValid = true;
          return;
        }
      }
    
      if(this.availabilitySetList&&this.availabilitySetList.length>0){
        let findObj=this.availabilitySetList.find((dt)=>dt.name.toLowerCase()==value.toLowerCase()&&dt.id);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.existingNewASList&&this.existingNewASList.length>0&&this.virtualMachinemodel&&this.virtualMachinemodel.resourceName){
        let findObj=this.existingNewASList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase());
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.existingNewVMList){
        let findObj=this.existingNewVMList.find(dt=>{
          if(dt.resourceData&&dt.resourceId!==this.virtualMachinemodel.resourceId) {
            const resourceData=JSON.parse(dt.resourceData);
            if(resourceData.availabilitySet) {
              return resourceData.availabilitySet.name.toLowerCase()==value.toLowerCase();
            }
          }
        });
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
    }
  }

  changeAvailablity(){
    if(this.virtualMachinemodel.resourceData.availabilityOption&&this.virtualMachinemodel.resourceData.availabilityOption=='availabilityZone'
    &&this.virtualMachinemodel.resourceData.availabilityZone){
      this.tempVmPublicIPList=this.vmPublicIPList.filter(dt=>!dt.zones||dt.zones.length==0||(dt.zones.findIndex(zt=>zt==this.virtualMachinemodel.resourceData.availabilityZone)>-1));
    }else{
      this.tempVmPublicIPList=this.vmPublicIPList;
    }
    if(this.tempVmPublicIPList&&this.tempVmPublicIPList.length>0){
      this.virtualMachinemodel.resourceData.publicIp=this.tempVmPublicIPList[0];
    }
  }

  changeSanpshot(modelObj){
    if(modelObj.snapshot&&modelObj.snapshot.properties&&modelObj.snapshot.properties.diskSizeGB
      &&modelObj.newVMDiskSize&&modelObj.newVMDiskSize.customDiskSize&&modelObj.newVMDiskSize.customDiskSize<modelObj.snapshot.properties.diskSizeGB){
      modelObj.newVMDiskSize=undefined;
    }
  }

  validateResourceName(event, limitNum){
    let value =event.target.value;
    if (value.length > limitNum) {
      this.inputError.maxLenResourceName = true;
      return;
    }
    else {
      this.inputError.maxLenResourceName = false;
    }

    let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(value[value.length-1]=='.'||regex.test(value)){
      this.inputError.validResourceName=true;
      return;
    }else{
      this.inputError.validResourceName=false;
    }

    if(this.resourceGroupList&&this.resourceGroupList.length){
      let findObj=this.resourceGroupList.find(dt=>dt.name==value&&!dt.newResourceGroupEdit);
      this.inputError.duplicateResourceName = findObj?true:false;
    }else {
      this.inputError.duplicateResourceName = false;
    }
  }

  VmNameValidation(limitField, limitNum) {
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMname = true;
    }
    else this.inputError.VMname = false;

    let regex=/\@|\&|\*|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
    if(value[0]=='_'||value[value.length-1]=='.' || value[value.length-1]=='-'||regex.test(value)){
      this.inputError.VmSpecialChar =true;
      return;
    } 
    this.inputError.VmSpecialChar =false;

    if((/^[0-9]*$/).test(value)){
      this.inputError.VMOnlyNUmbers =true;
      return;
    }

    this.inputError.VMOnlyNUmbers =false;

    this.vmDuplicateCheck(value);
    
  }

  autoShutdownTimeValidation(event){
    let value=event.target.value;
    this.inputError['validTimeFormat']=!moment(value,'h:mm:ss A',true).isValid();
  }

  keyPairNameValidation(limitField){
    let value =limitField.target.value;
    this.inputError.keyPairNameSpChar = false;
    this.inputError['keyPairNameDup']=false;
    if(value){
      let regex=/\@|\&|\*|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
      if(value[0]=='_'||value[value.length-1]=='.' || value[value.length-1]=='-'||regex.test(value)){
        this.inputError.keyPairNameSpChar =true;
        return
      } 

      let findObj=this.storedKeysList.find(dt=>dt.name==value);
      if(findObj){
        this.inputError['keyPairNameDup']=true;
      }else{
        if(this.parentResourceDtls.devices){
          this.parentResourceDtls.devices.find(dt=>{
            let resourceData = JSON.parse(dt.resourceData);
            if(resourceData.keyPairName.toLowerCase()==value.toLowerCase()&&dt.resourceId!=this.virtualMachinemodel.resourceId){
              this.inputError['keyPairNameDup']=true;
              return true;
            }
          });
        }
      }
    }
  }

  publicKeyValidation(limitField){
    let value =limitField.target.value;
    this.inputError.publicKeyValid = false;
    this.inputError['sshKeyBase64Valid'] = false;
    if(value){
      if(value.includes("ssh-rsa AAAAB3NzaC1yc2EAAAAD")){
        this.inputError.publicKeyValid = false;
        try{
          let valTest=value.split(" ")[1];
          if(valTest[valTest.length-1]=="="){
            this.inputError.publicKeyValid = false;
          }else{
            this.inputError.publicKeyValid = true;
            return;
          }
          window.atob(valTest);
          this.inputError['sshKeyBase64Valid'] = false;
        }catch(err){
          this.inputError['sshKeyBase64Valid'] = true;
        }
      }else{
        this.inputError.publicKeyValid = true;
      } 
    }
  }

  VmAdminUsernameValidation(limitField, limitNum){
    let value =limitField.target.value;
    this.inputError.VMAdminUsername = false;
    this.inputError.VmAdminSpecialChar =false;
    this.inputError.VMAdminReserv =false;
    if(value){
      if (value.length > limitNum) {
          this.inputError.VMAdminUsername = true;
      }
      else this.inputError.VMAdminUsername = false;

      let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      if(value[0]=='_'||value[0]=='-'||regex.test(value)){
        this.inputError.VmAdminSpecialChar =true;
        return;
      } 
      this.inputError.VmAdminSpecialChar =false;

      if(this.disallowedUsernameList.find(dt=>dt.name.toLowerCase()===value.toLowerCase())){
        this.inputError.VMAdminReserv =true;
        return;
      }
    }

    this.inputError.VMAdminReserv =false;
  }

  VmAdminPasswordValidation(limitField, limitNum,minLimit){
    let value =limitField.target.value;
    this.inputError.VmAdminPasswordLen = false;
    this.inputError.VMAdminPassword =false;
    this.virtualMachinemodel.resourceData.adminConfirmPassword="";
    if(value){
      if (value.length > limitNum||value.length<minLimit) {
          this.inputError.VmAdminPasswordLen = true;
      }
      else this.inputError.VmAdminPasswordLen = false;

      let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\_|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      let count=0;
      if((/[A-Z]/).test(value)){
        count=count+1;
      }
      if((/[a-z]/).test(value)){
        count=count+1;
      }
      if((/[0-9]/).test(value)){
        count=count+1;
      }
      if(!regex.test(value)){
        count=count+1;
      }
      if(count<3){
        this.inputError.VMAdminPassword =true;
        return;
      } 
      this.inputError.VMAdminPassword =false;
    }
  }

  VMAdminConfirmPassword(field){
    let value =field.target.value;
    if(value&&value!=this.virtualMachinemodel.resourceData.adminPassword){
    this.inputError.VMAdminPasswordMatch=true;
    }else{
      this.inputError.VMAdminPasswordMatch=false;
    }
  }

  checkLUNNumInput(item,limit,i){
    item.maxLUNNumLen=false;
    item.VMOnlyNUmbers =false;
    item.validEmpty=false;
    if(!item.LUNNum){
      item.validEmpty=true;
      return;
    }
    if(item.LUNNum&&(/^[0-9]*$/).test(item.LUNNum)){
      item.VMOnlyNUmbers =true;
      return;
    }
    if(item.LUNNum&&item.LUNNum>limit){
    item.maxLUNNumLen=true;
    return;
    }
  }

  vmDuplicateCheck(value){
    let findObj=this.virtualMachinesList.find(dt=>dt.name.toLowerCase()==value.toLowerCase());
    if(this.existingNewVMList&&!findObj){
      findObj=this.existingNewVMList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase()&&dt.resourceId!=this.virtualMachinemodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateVnet =true; 
    }else{
      this.inputError.duplicateVnet =false; 
    }
  }

  VmDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMdescription = true;
    }
    else this.inputError.VMdescription = false;
  }

  vmDiskNameValidation(limitField,limitNum){
    let value =limitField.target.value;
    this.newDiskObj.nameLen = false;
    this.newDiskObj.nameDupCheck = false;
    this.newDiskObj.duplicateName = false;
    if (value.length > limitNum) {
        this.newDiskObj.nameLen = true;
    }
    else this.newDiskObj.nameLen = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.newDiskObj.nameValid = false;
    }
    else{
      this.newDiskObj.nameValid = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.newDiskObj.nameValid = true;
        return;
      }
    } 
    if(this.virtualMachinemodel.resourceData.newDiskList&&this.virtualMachinemodel.resourceData.newDiskList.length){
      let findObj=this.virtualMachinemodel.resourceData.newDiskList.find((dt,index)=>dt.diskName.toLowerCase()==value.toLowerCase()&&this.newDiskObj.updateIndex!=index);
      if(findObj){
        this.newDiskObj.duplicateName=true;
        return;
      }
    }
    if(this.existingNewDiskList) {
      let findObj=this.existingNewDiskList.find(dt=>dt.resourceName.toLowerCase()===value.toLowerCase());
      if(findObj){
        this.newDiskObj.duplicateName=true;
        return;
      }
    }
    if(this.existingNewVMList){
      let findObj=this.existingNewVMList.find(dt=>{
        if(dt.resourceData&&dt.resourceId!==this.virtualMachinemodel.resourceId) {
          const resourceData=JSON.parse(dt.resourceData);
          if(resourceData.newDiskList) {
            return resourceData.newDiskList.find((dt)=>dt.diskName.toLowerCase()==value.toLowerCase());
          }
        }
      });
      if(findObj){
        this.newDiskObj.duplicateName=true;
        return;
      }
    }
  }

  filterStorageSkus(performance,kind){
    let kindVal=_.cloneDeep(kind);
    if(kind=='PageBlobStorage'){
      kindVal='StorageV2'
    }
    this.tempStorageSkusList=this.storageSkusList.filter(dt=>dt.tier==performance&&dt.kind==kindVal);
    this.tempStorageSkusList.map(dt=>{
      let findObj=this.util.getStatic('redundancy').find(ds=>ds.id==dt.name.split("_")[1]);
      if(findObj){
        dt.rdname=findObj.name;
      }
      return dt;
    });
    let findObj=this.tempStorageSkusList.find(dt=>dt.name.includes('_LRS'));
    if(this.virtualMachinemodel&&this.virtualMachinemodel.resourceData){
    this.virtualMachinemodel.resourceData.redundancy= findObj?findObj.name:'Standard_LRS';
    }
    if(this.vmStorageAccount){
    this.vmStorageAccount.redundancy= findObj?findObj.name:'Standard_LRS';
    }
  }

  vmPublicIPValidation(limitField,limitNum){
    let value =limitField.target.value;
    this.publicIPObj.nameLen = false;
    this.publicIPObj.nameDupCheck = false;
    this.publicIPObj.nameValid = false;
    if (value.length > limitNum) {
        this.publicIPObj.nameLen = true;
    }
    else this.publicIPObj.nameLen = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.publicIPObj.nameValid = false;
    }
    else{
      this.publicIPObj.nameValid = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.publicIPObj.nameValid = true;
        return;
      }
    }    

    if(this.tempVmPublicIPList&&this.tempVmPublicIPList.length){
      let findObj=this.tempVmPublicIPList.find(dt=>dt.name.toLowerCase()==value.toLowerCase()&&dt.id);
        if(findObj){
          this.publicIPObj.nameDupCheck = true;
          return;
        }
    }
    if(this.existingNewPIPList){
      let findObj=this.existingNewPIPList.find(dt=>dt.resourceName.toLowerCase()==value.toLowerCase());
      if(findObj){
        this.publicIPObj.nameDupCheck = true;
        return;
      }
    }
    if(this.existingNewVMList){
      let findObj=this.existingNewVMList.find(dt=>{
        if(dt.resourceData&&dt.resourceId!==this.virtualMachinemodel.resourceId) {
          const resourceData=JSON.parse(dt.resourceData);
          if(resourceData.publicIp) {
            return resourceData.publicIp.name.toLowerCase()==value.toLowerCase();
          }
        }
      });
      if(findObj){
        this.publicIPObj.nameDupCheck=true;
        return;
      }
    }
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.virtualMachinemodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }

  storageAccountNameValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.storageAccountName = true;
        return
    }
    else this.inputError.storageAccountName = false;

    for(let input of value){
      if(!(this.isCharALetter(input) || this.isCharANumber(input))){
        this.inputError.storageAccountChar = true;
        return;
      }
    }
    this.inputError.storageAccountChar = false;
    this.saDuplicateCheck(limitField)
  }

  saDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateSa =false; 
    if(value){
   let findObj=this.storageAccountList.find(dt=>dt.name==value);
    if(this.existingNewSAList&&!findObj){
      findObj=this.existingNewSAList.find(dt=>dt.resourceName==value);
    }
    if(this.existingNewVMList&&!findObj){
      findObj=this.existingNewVMList.find(dt=>{
        if(dt.resourceData&&dt.resourceId!==this.virtualMachinemodel.resourceId) {
          const resourceData=JSON.parse(dt.resourceData);
          if(resourceData.diagnosticsStorageAccount) {
            return resourceData.diagnosticsStorageAccount.name===value;
          }
        }
      });
    }
    if(findObj){
      this.inputError.duplicateSa =true; 
      return;
    }else{
      this.inputError.duplicateSa =false; 
    }

    this.checkStorageNameAvail(event,'duplicateSa');
  }
  }

  async checkStorageNameAvail(event,flagCheck){    
    let value=event.target.value;
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      name:value
    }
    await this.util.handleRequest('post','a3s_architectureBlueprint_checkStorageName',[],reqObj,null,null,true).then(res=>{
      if(res&&res.nameAvailable){
        this.inputError[flagCheck] =false; 
      }else{
        this.inputError[flagCheck] =true; 
      }
    })
  }

  saveSubResourceDetails(resourceId) {
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName'];
    let modalDtls:any={
      parentResourceId:resourceId,
      subResourceList:[{
        createdBy: userName,
        resourceName:this.virtualMachinemodel.resourceName+"-nic",
        resourceType:'networkInterfaces',
        resourceData:{
          resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
        },
      }]
    };
    if(this.virtualMachinemodel.resourceData.publicIp && this.virtualMachinemodel.resourceData.publicIp.name!='None') {
      modalDtls.subResourceList.push({
          createdBy: userName,
          resourceName:this.virtualMachinemodel.resourceData.publicIp.name,
          resourceType:'publicIPAddress',
          resourceData:{
            ...this.virtualMachinemodel.resourceData.publicIp,
            resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
          },
      })
    }
    if(this.virtualMachinemodel.resourceData.nicNSG=='Advanced'&&this.virtualMachinemodel.resourceData.networkSecurityGroup) {
      modalDtls.subResourceList.push({
          createdBy: userName,
          resourceName:this.virtualMachinemodel.resourceData.networkSecurityGroup.name,
          resourceType:'networkSecurityGroups',
          resourceData:{
            ...this.virtualMachinemodel.resourceData.networkSecurityGroup,
            resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
          },
      })
    } else if(this.virtualMachinemodel.resourceData.nicNSG=='Basic') {
      modalDtls.subResourceList.push({
        createdBy: userName,
        resourceName:this.virtualMachinemodel.resourceName+"-nsg",
        resourceType:'networkSecurityGroups',
        resourceData:{
          resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
        },
      })
    }

    if(this.virtualMachinemodel.resourceData.newDiskList&&this.virtualMachinemodel.resourceData.newDiskList.length) {
      this.virtualMachinemodel.resourceData.newDiskList.forEach(dt=>{
        modalDtls.subResourceList.push({
            createdBy: userName,
            resourceName:dt.diskName,
            resourceType:'disks',
            resourceData:{
              LUNNum:dt.LUNNum,
              sourceType:dt.sourceType,
              snapshot:dt.sourceType=='Snapshot'?dt.snapshot:undefined,
              size:dt.newVMDiskSize.customDiskSize,
              sizeLRS:dt.newVMDiskSize.diskSKU.name,
              encryptionType:dt.encryptionType,
              diskEncryptionSet:dt.encryptionType!='1'?dt.diskEncryptionSet:undefined,
              enableSharedDisk:dt.enableSharedDisk,
              maxShares:dt.enableSharedDisk=='Yes'?dt.maxShares:undefined,
              resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
            },
        })
      });
    } else {
      modalDtls.subResourceList.push({
        createdBy: userName,
        resourceName:this.virtualMachinemodel.resourceName+"-osdisk",
        resourceType:'disks',
        resourceData:{
          resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
        },
      })
    }
    if((this.virtualMachinemodel.resourceData.enableOSGuestDiagnostics||this.virtualMachinemodel.resourceData.bootDiagnostics=='EWCSA')&&this.virtualMachinemodel.resourceData.diagnosticsStorageAccount&&this.virtualMachinemodel.resourceData.diagnosticsStorageAccount.createNew) {
      modalDtls.subResourceList.push({
          createdBy: userName,
          resourceName:this.virtualMachinemodel.resourceData.diagnosticsStorageAccount.name,
          resourceType:'storageAccounts',
          resourceData:{
            ...this.virtualMachinemodel.resourceData.diagnosticsStorageAccount,
            resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
          }
      });
    } else if((this.virtualMachinemodel.resourceData.enableOSGuestDiagnostics||this.virtualMachinemodel.resourceData.bootDiagnostics=='EWCSA')&&this.virtualMachinemodel.resourceData.diagnosticsStorageAccount) {
      modalDtls.subResourceList.push({
          createdBy: userName,
          resourceName:this.virtualMachinemodel.resourceData.diagnosticsStorageAccount.name,
          resourceType:'storageAccounts',
          resourceData:{
            ...this.virtualMachinemodel.resourceData.diagnosticsStorageAccount,
            resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
          },
      })
    }
    if(this.virtualMachinemodel.resourceData.availabilityOption=='availabilitySet'&&this.virtualMachinemodel.resourceData.availabilitySet) {
      modalDtls.subResourceList.push({
          createdBy: userName,
          resourceName:this.virtualMachinemodel.resourceData.availabilitySet.name,
          resourceType:'availabilitySets',
          resourceData:{
            ...this.virtualMachinemodel.resourceData.availabilitySet,
            resourceGroup:this.virtualMachinemodel.resourceData.resourceGroup
          },
      })
    }
    if(modalDtls.subResourceList&&modalDtls.subResourceList.length) {
      this.util.handleRequest('put','a3s_architectureBlueprint_createSubResource',[this.projectData.projectId],modalDtls).then(res=>{
        console.log('Successfully Sub resources Saved',res)
      })
    }
  }

  saveResourceDetails() {
    var model = this.virtualMachinemodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName;
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved');
      this.saveSubResourceDetails(res.resourceId);
    })

  }

  updateResourceDtls(){
    var model = this.virtualMachinemodel;
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName;
    this.saveSubResourceDetails(model.resourceId);
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  deleteRules(type,i){
    if(type=='inboundRules'){
      this.vmNSGObj.vmInBoundRule.splice(i,1);
    }else if(type=='outboundRules'){
      this.vmNSGObj.vmOutBoundRule.splice(i,1);
    }
  }

  editRules(type,i){
      this.vmNSGObj.updateIndex=i;
      this.triggerBtn(type);
  }

  changeEncryptionType(flagFrom){
    if(flagFrom=='newDisk'){
      let findObj=this.diskEncryptionTypeList.find(dt=>dt.id==this.newDiskObj.encryptionType);
      if(findObj&&findObj.encryptionType){
        this.diskEncryptionSetListForDisk=this.diskEncryptionMainSetList.filter(dt=>dt.properties&&dt.properties.encryptionType==findObj.encryptionType);
      }else{
        this.diskEncryptionSetListForDisk=[];
      }
    }else if(flagFrom=='newVm'){
      let findObj=this.diskEncryptionTypeList.find(dt=>dt.id==this.virtualMachinemodel.resourceData.diskEncryptionType);
      if(findObj&&findObj.encryptionType){
        this.diskEncryptionSetList=this.diskEncryptionMainSetList.filter(dt=>dt.properties&&dt.properties.encryptionType==findObj.encryptionType);
      }else{
        this.diskEncryptionSetList=[];
      }
    }
  }

  changeMaxShareList(event,modelObj){
    this.maxSharesList=[];
    if(event.target.value=='Yes'&&modelObj&&modelObj.newVMDiskSize&&modelObj.newVMDiskSize.selectVmDiskSizeData){
      let maxShare=modelObj.newVMDiskSize.selectVmDiskSizeData.capabilities[6].value;
      for(let i=2;i<=parseInt(maxShare);i++){
        this.maxSharesList.push({name:i});
      }
  }
}

  disabledEnableDiskShare(modelObj){
    if(modelObj&&modelObj.newVMDiskSize&&modelObj.newVMDiskSize.selectVmDiskSizeData&&(modelObj.newVMDiskSize.selectVmDiskSizeData.name!='Premium_LRS'||modelObj.newVMDiskSize.selectVmDiskSizeData.capabilities[6].value==1)){
      modelObj.enableSharedDisk='No';
      modelObj.esd='No';
      this.previousDiskSize=modelObj.newVMDiskSize.customDiskSize;
      return true;
    }else if(modelObj&&modelObj.newVMDiskSize&&modelObj.newVMDiskSize.selectVmDiskSizeData){
      if(modelObj.newVMDiskSize.customDiskSize!=this.previousDiskSize){
        modelObj.enableSharedDisk='No';
        modelObj.esd='No';
        this.previousDiskSize=modelObj.newVMDiskSize.customDiskSize;
      }
      return false;
    }else {
      return false;
    }
  }

  checkUpdateDomains(event,maxLimit){
    let value=event.target.value;
    this.inputError.asUpdateDomainsMaxLen=false;
    this.inputError.asUpdateDomainsLen=false;
    if(value&&value>maxLimit){
      this.inputError.asUpdateDomainsMaxLen=true;
    }else if(!value||value<1){
      this.inputError.asUpdateDomainsLen=true;
    }
  }

  checkfaultDomains(event,modelObj){
    let value=event.target.value;
    this.inputError['asFaultDomainsMaxLen']=false;
    this.inputError['asFaultDomainsLen']=false;
    if(!value||value<1){
      this.inputError['asFaultDomainsLen']=true;
      return;
    }
    if(value&&value==1){
      modelObj.updateDomains=1;
      this.inputError.asUpdateDomainsMaxLen=false;
      this.inputError.asUpdateDomainsLen=false;
    }else if(value&&parseInt(value)>3){
      this.inputError['asFaultDomainsMaxLen']=true;
    }
  }

  updateSubSideSheet(type){
    switch (type) {
      case 'newDisk':{
        let tempList=_.cloneDeep(this.virtualMachinemodel.resourceData.newDiskList);
        this.virtualMachinemodel.resourceData.newDiskList=[];
        tempList[this.newDiskObj.updateIndex]=this.newDiskObj;
        tempList[this.newDiskObj.updateIndex].diskName=_.cloneDeep(this.newDiskObj.name);
        tempList[this.newDiskObj.updateIndex].updateIndex=undefined;
        this.virtualMachinemodel.resourceData.newDiskList=_.cloneDeep(tempList);
       // this.virtualMachinemodel.resourceData.newDiskList.push(this.newDiskObj);
        this.virtualMachinemodel.newDiskShow=false;
        return;
      }
    }
  }

  saveSubSideSheet(type){
    switch(type){
      case 'publicIp':{
        let tempList=_.cloneDeep(this.vmPublicIPList);
        this.publicIPObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.publicIPObj;
          }else{
            tempList.push(this.publicIPObj);
          }
        }else{
          tempList.push(this.publicIPObj);
        }
        this.vmPublicIPList=[];
        this.vmPublicIPList=tempList;
        this.tempVmPublicIPList=[];
        this.changeAvailablity();
        this.virtualMachinemodel.resourceData.publicIp=_.cloneDeep(this.publicIPObj);
        this.virtualMachinemodel.publicIPShow=false;
        this.publicIPObj=resourceObject.publicIPObj;
        return;
      };
      case 'newDisk':{
        if(!this.virtualMachinemodel.resourceData.newDiskList){
          this.virtualMachinemodel.resourceData.newDiskList=[];
          this.newDiskObj.LUNNum=0;
        }else if(this.virtualMachinemodel.resourceData.newDiskList.length>0){
          let filterArr=_.cloneDeep(this.virtualMachinemodel.resourceData.newDiskList.filter(dt=>dt.LUNNum!==''&&dt.LUNNum!=null&&dt.LUNNum!=undefined));
          this.newDiskObj.LUNNum=filterArr.length?Number(Math.max(...filterArr.map(dt=>dt.LUNNum)))+1:0;
        }
        if(!this.newDiskObj.LUNNum){
          this.newDiskObj.LUNNum=0;
        }
        let tempList=_.cloneDeep(this.virtualMachinemodel.resourceData.newDiskList);
        this.virtualMachinemodel.resourceData.newDiskList=[];
        tempList.push(this.newDiskObj);
        tempList[tempList.length-1].diskName=_.cloneDeep(this.newDiskObj.name);
        this.virtualMachinemodel.resourceData.newDiskList=tempList;
       // this.virtualMachinemodel.resourceData.newDiskList.push(this.newDiskObj);
        this.virtualMachinemodel.newDiskShow=false;
        return;
      };
      case 'nsg':{
        if(!this.nsgList){
          this.nsgList=[];
        }
        //this.nsgList.push(this.vmNSGObj);
        let tempList=_.cloneDeep(this.nsgList);
        this.vmNSGObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.vmNSGObj;
          }else{
            tempList.push(this.vmNSGObj);
          }
        }else{
          tempList.push(this.vmNSGObj);
        }
        this.nsgList=[];
        this.nsgList=tempList;
        this.virtualMachinemodel.resourceData.networkSecurityGroup=_.cloneDeep(this.vmNSGObj);
        this.virtualMachinemodel.nsgShow=false;
        this.vmNSGObj={};
        return;
      }
      case 'vmASObj':{
        let tempList=_.cloneDeep(this.availabilitySetList);
        this.vmASObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.vmASObj;
          }else{
            tempList.push(this.vmASObj);
          }
        }else{
          tempList.push(this.vmASObj);
        }
        this.availabilitySetList=[];
        this.availabilitySetList=tempList;
        this.virtualMachinemodel.resourceData.availabilitySet=_.cloneDeep(this.vmASObj);
        this.virtualMachinemodel.availabilitySetShow=false;
        return;
      }
      case 'vmStorageAccount':{
        let tempList=_.cloneDeep(this.storageAccountList);
        this.vmStorageAccount.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.vmStorageAccount;
          }else{
            tempList.push(this.vmStorageAccount);
          }
        }else{
          tempList.push(this.vmStorageAccount);
        }
        this.storageAccountList=[];
        this.storageAccountList=tempList;
        this.virtualMachinemodel.resourceData.diagnosticsStorageAccount=_.cloneDeep(this.vmStorageAccount);
        this.virtualMachinemodel.storageAccountShow=false;
        return;
      }
    }
  }

  disabledLoginAD(){
    if(this.virtualMachinemodel.resourceData.image.restricitLoginAzureAD){
      this.virtualMachinemodel.resourceData.loginWithAzureAD=false;
      return true;
    }else{
      return false
    }
  }


  checkDataDisabled(name){
    switch(name){
      case 'virtualMachine': return !this.virtualMachinemodel.resourceName || !this.virtualMachinemodel.resourceData.selectedVMSize || this.inputError.VmSpecialChar || this.inputError.VMname||this.inputError.duplicateVnet||this.inputError.VMOnlyNUmbers ||this.inputError.VMAdminUsername || this.inputError.VmAdminSpecialChar||this.inputError.VMAdminReserv||this.inputError.VMAdminPassword||this.inputError.VmAdminPasswordLen||this.inputError.VMAdminPasswordMatch
      ||(this.virtualMachinemodel.resourceData.availabilityOption=='availabilityZone'&&!this.virtualMachinemodel.resourceData.availabilityZone)||(this.virtualMachinemodel.resourceData.availabilityOption=='availabilitySet'&&!this.virtualMachinemodel.resourceData.availabilitySet)||(this.virtualMachinemodel.resourceData.diskEncryptionType!='1'&&!this.virtualMachinemodel.resourceData.diskEncryptionSet)||(this.virtualMachinemodel.resourceData.nicNSG=='Advanced'&&!this.virtualMachinemodel.resourceData.networkSecurityGroup)
      ||(this.virtualMachinemodel.resourceData.bootDiagnostics=='EWCSA'&&!this.virtualMachinemodel.resourceData.diagnosticsStorageAccount)
      ||!this.virtualMachinemodel.resourceData.adminUsername||(this.virtualMachinemodel.resourceType!='linuxVirtualMachine'||this.virtualMachinemodel.resourceData.authType=='password')&&!this.virtualMachinemodel.resourceData.adminPassword||(this.virtualMachinemodel.resourceType!='linuxVirtualMachine'||this.virtualMachinemodel.resourceData.authType=='password')&&!this.virtualMachinemodel.resourceData.adminConfirmPassword
      ||this.inputError.VMAdminReserv || this.inputError.VmAdminSpecialChar || this.inputError.VMAdminUsername||((this.virtualMachinemodel.resourceType!='linuxVirtualMachine'||this.virtualMachinemodel.resourceData.authType=='password')&&(this.inputError.VMAdminPassword || this.inputError.VmAdminPasswordLen||this.inputError.VMAdminPasswordMatch))
      ||this.virtualMachinemodel.resourceType=='linuxVirtualMachine'&&this.virtualMachinemodel.resourceData.authType=='ssh'&&((this.virtualMachinemodel.resourceData.sshPublicKey=='genNewKeyPair'&&(!this.virtualMachinemodel.resourceData.keyPairName||this.inputError.keyPairNameSpChar||this.inputError['keyPairNameDup']))||(this.virtualMachinemodel.resourceData.sshPublicKey=='useExistKeyAzure'&&!this.virtualMachinemodel.resourceData.storedKeys)||(this.virtualMachinemodel.resourceData.sshPublicKey=='useExistPublicKey'&&(!this.virtualMachinemodel.resourceData.publicKey||this.inputError.publicKeyValid||this.inputError['sshKeyBase64Valid'])))
      ||this.virtualMachinemodel.resourceData.autoShutdownStatus&&(!this.virtualMachinemodel.resourceData.autoShutdownTime||this.inputError['validTimeFormat']||(this.virtualMachinemodel.resourceData.autoShutdownNotificationStatus&&!this.virtualMachinemodel.resourceData.email));
      case 'publicIp': return !this.publicIPObj.name;
      case 'newDisk': return !this.newDiskObj.name||!this.newDiskObj.sourceType||(this.newDiskObj.sourceType=='Snapshot'&&!this.newDiskObj.snapshot)||!this.newDiskObj.newVMDiskSize||!this.newDiskObj.encryptionType||(this.newDiskObj.encryptionType!='1'&&this.newDiskObj.diskEncryptionSet);
      case 'nsg': return !this.vmNSGObj.name;
      case 'vmASObj': return !this.vmASObj.name||this.inputError.nameLen||this.inputError.nameValid||this.inputError.nameDupCheck||this.inputError.asUpdateDomainsLen||this.inputError.asUpdateDomainsMaxLen;
      case 'vmStorageAccount': return !this.vmStorageAccount.name||this.inputError.storageAccountName||this.inputError.storageAccountChar||this.inputError.duplicateSa;
    }
  }
}
