<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right" [ngClass]="{'content-disabled':show}">
    <div>
        <div class="header">
            <div class="d-flex">
               <h4>Public IP</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <div class="label-div">
                    <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" (change)="resourceGroupChange()" [(ngModel)]="publicIPmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            {{item.name}}
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                            <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                        </ng-template>
                    </ng-select>
                </div> 
                <div class="add-another-tag">
                  <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(false)">Create new
                  </p>
                </div>
            </div> 
            
            <div class="form-group">
                <div class="label-div">
                    <label for="publicIP" class="control-label">Name<span class="mandatory">*</span></label>
                </div>
                <div class="input-box">
                <input type="text" class="form-control" [class.border-danger]="inputError?.VMname || inputError?.VmSpecialChar ||inputError?.duplicateVnet" id="VMname" autocomplete="off" [(ngModel)]="publicIPmodel.resourceName" placeholder="Enter Public IP name" name="projectName" (keyup)="VmNameValidation($event,65)" required>
                <p class="text-danger" *ngIf="inputError?.VMname">The name must be between 1 and 80 characters.</p>
                <p class="text-danger" *ngIf="inputError?.VmSpecialChar">The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens. </p>
                <p class="text-danger" *ngIf="inputError?.duplicateVnet">Public IP already exist.</p>
                </div>              
            </div>

            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="sku" class="control-label ">SKU<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Basic: Assigned with the static or 'dynamic allocation' method.&#13;Standard: Always use static allocation method." matTooltipClass="nav-tooltips tooltip-newline" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="sku" [value]="'Basic'" ([ngModel])="publicIPmodel.resourceData.sku" [checked]="publicIPmodel.resourceData.sku=='Basic'" (change)="publicIPmodel.resourceData.sku=$event.target.value;publicIPmodel.resourceData.IPAddr='Dynamic'" ><span>Basic</span></label>
                    <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="sku" [value]="'Standard'" ([ngModel])="publicIPmodel.resourceData.sku" [checked]="publicIPmodel.resourceData.sku=='Standard'" (change)="publicIPmodel.resourceData.sku=$event.target.value;publicIPmodel.resourceData.IPAddr='Static'"><span>Standard</span></label>                    
                </div>             
            </div>
        
            <div class="form-group">
                <div class="label-div">
                    <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                    <textarea name="comment" class="comment-box"  [class.border-danger]="inputError?.VMdescription" [(ngModel)]="publicIPmodel.comments" placeholder="Describe the purpose of the resource" (keyup)="VmDescription($event,300)"></textarea>
                    <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>                    
            </div> 
            <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
                <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
            </div>
            
    <div *ngIf="showView">

        <div class="form-group" style="margin-bottom: 10px !important;">
            <div class="label-div">
                <label for="tier" class="control-label ">Tier<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="tier" [value]="'Regional'" [disabled]="disabledTier()" ([ngModel])="publicIPmodel.resourceData.tier" [checked]="publicIPmodel.resourceData.tier=='Regional'" (change)="publicIPmodel.resourceData.tier=$event.target.value" ><span>Regional</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="tier" [value]="'Global'" [disabled]="disabledTier()" ([ngModel])="publicIPmodel.resourceData.tier" [checked]="publicIPmodel.resourceData.tier=='Global'" (change)="publicIPmodel.resourceData.tier=$event.target.value"><span>Global</span></label>                    
            </div>             
        </div>

            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="IPAddr" class="control-label ">IP address assignment</label>
                </div>
            <div class="input-box d-flex">
                <label class="col-sm-4 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPAddr" [value]="'Dynamic'" [disabled]="publicIPmodel.resourceData.sku=='Standard'" ([ngModel])="publicIPmodel.resourceData.IPAddr" [checked]="publicIPmodel.resourceData.IPAddr=='Dynamic'" (change)="publicIPmodel.resourceData.IPAddr=$event.target.value" ><span>Dynamic</span></label>
                <label class="col-sm-7 radio-label" style="padding-left: 0px !important;"><input type="radio" name="IPAddr" [value]="'Static'" [disabled]="publicIPmodel.resourceData.sku=='Standard'" ([ngModel])="publicIPmodel.resourceData.IPAddr" [checked]="publicIPmodel.resourceData.IPAddr=='Static'" (change)="publicIPmodel.resourceData.IPAddr=$event.target.value"><span>Static</span></label>                    
            </div>             
        </div>

        <div class="form-group" style="margin-bottom: 10px !important;" *ngIf="publicIPmodel.resourceData.sku=='Standard'">
            <div class="label-div">
                <label for="rp" class="control-label ">Routing Preference<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Determines how your traffic routes between Azure and the internet. Selecting Microsoft global network delivers traffic via Microsoft global network closest to user. Internet route option uses transit ISP network. Egress data transfer price varies based on the routing selection. Routing preference option of a Public IP can't be changed once created." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box d-flex">
                <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="rp" [value]="'microsoftNetwork'" [disabled]="publicIPmodel.resourceData.tier=='Global'" ([ngModel])="publicIPmodel.resourceData.rp" [checked]="publicIPmodel.resourceData.rp=='microsoftNetwork'" (change)="publicIPmodel.resourceData.rp=$event.target.value" ><span>Microsoft network</span></label>
                <label class="col-sm-6 radio-label" style="padding-left: 0px !important;"><input type="radio" name="rp" [value]="'internet'" [disabled]="publicIPmodel.resourceData.tier=='Global'" ([ngModel])="publicIPmodel.resourceData.rp" [checked]="publicIPmodel.resourceData.rp=='internet'" (change)="publicIPmodel.resourceData.rp=$event.target.value;publicIPmodel.resourceData.availabilityZone='Zone-redundant'"><span>Internet</span></label>                    
            </div>             
        </div>

        <div class="form-group">
            <div class="label-div">
                <label for="idleTimeout" class="control-label">Idle timeout (minutes)<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Keep a TCP or HTTP connection open without relying on clients to send keep-alive messages." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
            <input type="text" class="form-control" [class.border-danger]="inputError?.limitidletimeout||inputError?.limitidletimeoutNum" id="idleTimeout" autocomplete="off" [(ngModel)]="publicIPmodel.resourceData.idleTimeout" placeholder="4" name="idleTimeout" (keyup)="changeIdleTimeout($event)">
            <p class="text-danger" *ngIf="inputError?.limitidletimeout">The value must be between 4 and 30 minutes.</p>
            <p class="text-danger" *ngIf="inputError?.limitidletimeoutNum">Value must be a valid number.</p>
            </div>              
        </div>

        <div class="form-group">
            <div class="label-div">
                <label for="dns" class="control-label">DNS name label<span class="mandatory"><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="An 'A Record' that starts with the specified label and resolves to this Public IP address will be registered with the Azure-provided DNS servers.&#013;Example: mylabel.westus.cloudapp.azure.com." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
            </div>
            <div class="input-box">
            <input type="text" class="form-control"  id="dns" autocomplete="off" [(ngModel)]="publicIPmodel.resourceData.dns" placeholder="" name="dns">
            <span class="dns-helper-label">.{{publicIPmodel?.resourceData?.dnslocation}}.cloudapp.azure.com</span>
            <!--[class.border-danger]="inputError?.dnsname || inputError?.dnsSpecialChar" <p class="text-danger" *ngIf="inputError?.dnsname">The domain name is invalid. It can contain only lowercase letters, numbers and hyphens. The first character must be a letter. The last character must be a letter or number.</p>
            <p class="text-danger" *ngIf="inputError?.dnsChar">The value must be between 3 and 63 characters long.</p>
            <p class="text-danger" *ngIf="inputError?.dnslabel">The domain name label is invalid. It must confirm to the following 
                regular expression.</p> -->
            </div>              
        </div>

        <div class="form-group" *ngIf="publicIPmodel.resourceData.tier!=='Global'">
        <div class="label-div">
            <label for="availabilityZone" class="control-label">Availablity zone<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="You can optionally specify an availability zone in which to deploy your Public IP address. Choose Zone-Redundant to create a zone-redundant data path." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
        </div>
        <div class="input-box">
            <ng-select [items]="azIPList" bindLabel="name" bindValue="name" [disabled]="publicIPmodel.resourceData.sku=='Standard' && publicIPmodel.resourceData.rp=='internet' " [disabled]="publicIPmodel.resourceData.sku=='Basic'" [(ngModel)]="publicIPmodel.resourceData.availabilityZone" name="availabilityZone" placeholder="" [clearable]="false"></ng-select>
        </div>   
    </div>
               
                <div class="form-group">
                    <div class="label-div d-flex">
                      <label class="control-label " style="min-width: max-content;">Tag name </label>
                      <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                      </label>
                      <label style="padding-left: 6px !important;width: 18px;"
                        *ngIf="publicIPmodel.resourceData&&publicIPmodel.resourceData.tagList&&publicIPmodel.resourceData.tagList.length>1"></label>
                      <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                    </div>
                    <div *ngFor="let item of publicIPmodel.resourceData.tagList;let i = index">
                      <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">
  
                        <div class="input-box" style="padding-bottom: 6px;">
                          <input type="text" class="form-control" id="tagVMName" autocomplete="off"
                            [(ngModel)]="item.tagName" name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                        </div>
  
                        <div style="padding-left: 6px;padding-right: 6px;">:</div>
  
                        <div class="input-box">
                          <input type="text" class="form-control" id="tagVMValue" autocomplete="off"
                            [(ngModel)]="item.value" name="tagVMValue" (keyup)="checkTagInput(item,256)">
                        </div>
  
                        <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                            *ngIf="publicIPmodel.resourceData.tagList.length>1"></span>
                      </div>
                      <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                        512.</p>
                      <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                        <>%&\?/.</p>
                      <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                      <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                        is 256.</p>
                    </div>
  
                    <div class="add-another-tag">
                      <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                    </div>
                  </div>
            </div>

            <div class="fixed-btm-btn flex-custom no-wrap">
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
                <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'" [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
            </div>
        </div>
    </div>
</div>