import { ChangeDetectorRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, Input, OnInit, TemplateRef, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from 'src/app/services/util.service';
import { resourceObject } from '../../@model/resource-model';
import { FlowService } from '../../services/flow.service';
import * as _ from 'lodash';
import { IPv4, IPSubnetwork, matches } from 'ip-matching';
import { count, find } from 'rxjs/operators';
import * as moment from 'moment';
import { LabelType, Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-resource-templates',
  templateUrl: './resource-templates.component.html',
  styleUrls: ['./resource-templates.component.scss']
})
export class ResourceTemplatesComponent implements OnInit {
  @Input() projectData : any;
  @Input() templateName : any;
  @Input() parentResourceDtls: any;
  @Input() currentResourceDtls: any;
  @Input() sideSheetViewType:any;
  @ViewChild("virtualNetwork") virtualNetworkTemplate: TemplateRef<any>;
  @ViewChild("virtualMachine") virtualMachineTemplate: TemplateRef<any>;
  @ViewChild("subnet") subnetTemplate: TemplateRef<any>;
  @ViewChild("azureADDomain") azureADDomainTemplate: TemplateRef<any>;
  @ViewChild("storageAccount") storageAccountTemplate: TemplateRef<any>;
  @ViewChild("windowsVirtualDesktop") WVDTemplate: TemplateRef<any>;
  @ViewChild("networkInterface") networkInterfaceTemplate: TemplateRef<any>;
  @ViewChild("keyVault") keyVaultTemplate: TemplateRef<any>;
  @ViewChild("routeTable") routeTableTemplate: TemplateRef<any>;
  @ViewChild("connections") connectionsTemplate: TemplateRef<any>;
  @ViewChild("logAnalytics") logAnalyticsTemplate: TemplateRef<any>;
  @ViewChild("applicationInsight") applicationInsightTemplate: TemplateRef<any>;
  @ViewChild("disk") diskTemplate: TemplateRef<any>;
  @ViewChild("publicIP") publicIPTemplate: TemplateRef<any>;
  @ViewChild("nsg") nsgTemplate: TemplateRef<any>;
  @ViewChild("availabilitySet") availabilitySetTemplate: TemplateRef<any>;
  @ViewChild("loadBalancer") loadBalancerTemplate: TemplateRef<any>;
  @ViewChild("localNetworkGateway") localNetworkGatewayTemplate: TemplateRef<any>;
  @ViewChild("machineLearning") machineLearningTemplate: TemplateRef<any>;
  @ViewChild("synapseAnalytics") synapseAnalyticsTemplate: TemplateRef<any>;
  @Output('triggerBtn') triggerBtnvalue :any = new EventEmitter<any>();
  
  currentTemplate :TemplateRef<any>;
  virtualMachinemodel : any = resourceObject.virtualMachine;
  VNetmodel : any =  resourceObject.virtualNetwork;
  subnetModel : any =  resourceObject.subnet;
  azureADDomainModel : any = resourceObject.azureADDomain;
  storageAccountmodel : any = resourceObject.storageAccount;
  WVDmodel : any = resourceObject.windowVirtualDesktop;
  networkInterfacemodel : any = resourceObject.networkInterfacemodel;
  keyVaultmodel : any = resourceObject.keyVault;
  routeTablemodel : any = resourceObject.routeTable;
  connectionsmodel : any = resourceObject.connections;
  localNetworkGatewaymodel : any = resourceObject.localNetworkGatewaymodel;
  loadBalancermodel : any = resourceObject.loadBalancer;
  nsgmodel : any = resourceObject.nsg;
  logAnalyticsmodel : any = resourceObject.logAnalytics;
  applicationInsightmodel : any = resourceObject.applicationInsight;
  diskmodel : any = resourceObject.disk;
  publicIPmodel : any = resourceObject.publicIP;
  availabilitySetmodel: any=resourceObject.availabilitySet;
  machineLearningmodel: any=resourceObject.machineLearning;
  synapseAnalyticsmodel: any=resourceObject.synapseAnalytics;
  show : boolean = false;
  ipV4:any=IPv4;
  ipSubnetWork:any=IPSubnetwork;
  ipMatches:any=matches;
  VmSizes : any = [];
  storageSkusList:any=[];
  tempStorageSkusList:any=[];
  resourcesGroupLocationList: any = [];
  inputError= {
    VMname : false,
    VmSpecialChar : false,
    VMdescription : false,
    VNetName : false,
    VNetChar: false,
    VNetAdrspc: false,
    VNetDescription: false,
    subnetName: false,
    subnetChar: false,
    subnetAdrspc: false,
    subnetDescription: false,
    storageAccountName: false,
    storageAccountChar: false,
    sharedKeyName: false,
    sharedKeyChar: false,
    storageAccountDescription: false,
    azureADDomainName: false,
    azureADDomainDescription: false,
    WVDsize: false,
    WVDdescription: false,
    duplicateVnet:false,
    maxLenResourceName:false,
    duplicateResourceName:false,
    validResourceName:false,
    subnetDuplicate:false,
    subnetNotValidCIDR:false,
    subnetOverlaping:false,
    subnetAddresNotInVnet:false,
    subnetPerfix:false,
    dsServiceCheck:false,
    VMOnlyNUmbers:false,
    VMAdminUsername:false,
    VmAdminSpecialChar:false,
    VMAdminReserv:false,
    VMAdminPassword:false,
    VmAdminPasswordLen:false,
    VMAdminPasswordMatch:false,
    nameLen:false,
    nameValid:false,
    nameDupCheck:false,
    limitidletimeout:false,
    saLifeTimeLimit:false,
    saDataSizelimit:false,
    dpdtimeout:false,
    restoredays:false,
    nicValidIPError : false,
    nicRegisterIPError : false,
    nicAddrUsedError : false,
    nicSubnetRangeError : true,
    duplicateNi : false,
    duplicateConnName : false,
    duplicateSa : false,
    duplicateAi : false,
    duplicateDisk : false,
    duplicateLaw : false,
    laname : false,
    laSpecialChar : false,
    asUpdateDomainsLen:false,
    asUpdateDomainsMaxLen:false,
    keyPairNameSpChar:false,
    publicKeyValid:false,
    accountNameCheck:true
  }
  newResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
    subscription:null,
  }
  resourceGroupList:any=[];
  natGetwaysList:any=[];
  nsgList:any=[];
  routeTablesList:any=[];
  servicesList:any=[];
  dsServiceList:any=[];
  showView:boolean=false;
  existingVNList:any=[];
  existingSubnetList:any=[];
  virtualNetworkGatewayList:any=[];
  localNetworkGatewayList:any=[];
  existingNewVNList:any=[];
  existingVNListForName:any=[];
  previousDiskSize:any;
  //newResourceGroupEdit:boolean=false;
  lawObj:any=resourceObject.lawObj;
  routesObj:any=resourceObject.routesObj;
  subnetsObj:any=resourceObject.subnetsObj;
  publicIPObj:any=resourceObject.publicIPObj;
  newDiskObj:any=resourceObject.newDiskObj;
  vmNSGObj:any=resourceObject.vmNSGObj;
  vmStorageAccount:any=resourceObject.vmStorageAccount;
  vmASObj:any={
    faultDomains:2,
    updateDomains:5
  };
  proxPlacGroupList:any=[];
  publicIPs:any=[
    '0.0.0.0/1',
    '127.0.0.0/8',
    '169.254.0.0/16',
    '169.254.0.0/15',
    '168.63.129.16/28',
    '168.63.129.16/29',
    '168.63.129.16/30',
    '168.63.129.16/31',
    '168.63.129.16/32',
    '224.0.0.0/4',
    '224.0.0.0/3',
    '255.255.255.255/32'
  ];

  locationCheckList:Array<string>=[
    'eastus2',
    'westus',
    'westeurope',
    'southeastasia',
    'centralus',
    'northeurope',
    'eastasia'
  ];

  availabilityOptionMainList:any=[
    {name:'No infrastructure redundancy required',id:'None'},
    {name:'Availablity zone',id:'availabilityZone'},
    {name:'Availability set',id:'availabilitySet'}
  ];
  availabilityOptionList=[];

  availabilityOptionWithoutZoneList:any=[
    {name:'No infrastructure redundancy required',id:'None'},
    {name:'Availability set',id:'availabilitySet'}
  ];

  availabilitySetList:any=[];

  osDiskTypeList:any=[
    {name:'Premium SSD',id:'Premium_LRS'},
    {name:'Standard SSD',id:'StandardSSD_LRS'},
    {name:'Standard HDD',id:'Standard_LRS'}
  ];

  vmPublicIPList:any=[
    {name:'None'}
  ];
  tempVmPublicIPList:any=[];

  availabilityZoneList:any=[
    {name:'1'},
    {name:'2'},
    {name:'3'}
  ];

  azIPList:any=[
    {name:'No Zone'},
    {name:'Zone-reduntant'},
    {name:'3'},
    {name:'2'},
    {name:'1'}
  ];

  azList:any=[
    {name:'None'},
    {name:'3'},
    {name:'2'},
    {name:'1'}
  ];

  zonesList:any=[
    {name:'No Zone'},
    {name:'Zone-reduntant'},
    {name:'1'},
    {name:'2'},
    {name:'3'}
  ];

  zonesListForInternal:any=[
    {name:'Zone-reduntant'},
    {name:'1'},
    {name:'2'},
    {name:'3'}
  ];

  sTypeList:any=[
    {name:'None',id:'None'},
    {name:'Snapshot',id:'snapshot'}
  ];

  mSharesList:any=[
    {name:'None',id:'0'},
    {name:'2',id:'2'},
    {name:'3',id:'3'},
    {name:'4',id:'4'},
    {name:'5',id:'5'}
  ]


  tempAvailabilityZoneList:any=[];

  acntTypeListForStand:any=[
    {name:'Storage V2',id:'StorageV2'}
  ];

  accountKindList:Array<{name:string,id:string}>=[
    {name:'StorageV2 (general purpose v2)',id:'StorageV2'},
    {name:'Storage (general purpose v1)',id:'Storage'}
  ]

  acntTypeListForPerm:any=[
    {name:'Block blobs', discription: 'Best for high transaction rates or low storage letancy',id:'BlockBlobStorage'},
    {name:'File shares',discription: 'Best for enterprise or high-performance applications that need to scale',id:'FileStorage'},
    {name:'Page blobs',discription: 'Best for random read and write operations',id:'PageBlobStorage'}
  ];

  pTierList:any=[
    {name:'Pay-as-you-go (Per GB 2018)',id:'pergb2018'},
    {name:'Free (Legacy trial tier)', id:'free'},
    {name:'Per Node (Legacy OMS tier)',id:'pernode'},
    {name:'Standalone (Legacy per GB tier)',id:'standalone'},
  ];

  lawList:any=[
  ];

  aiList:any=[
  ];

  saList:any=[
  ];

  diskList:any=[
  ];

  redncyListForStand:any=[
    {name:'Locally-redundant storage (LRS)',id:'LRS'},
    {name:'Geo-redundant storage (GRS)',id:'GRS'},
    {name:'Zone-redundant storage (ZRS)',id:'ZRS'},
    {name:'Geo-zone-redundant storage (GZRS)',id:'GZRS'}
  ];

  redncyListForOther:any=[
    {name:'Locally-redundant storage (LRS)',id:'LRS'},
    {name:'Zone-redundant storage (ZRS)',id:'ZRS'}
  ];

  redncyListForPerm:any=[
    {name:'Locally-redundant storage (LRS)',id:'LRS'}
  ];

  minTlsvList:any=[
    {name:'Version 1.0',id:"TLS1_0"},
    {name:'Version 1.1',id:"TLS1_1"},
    {name:'Version 1.2',id:"TLS1_2"}
  ];

  imageList:Array<{}>=[];

  diskEncryptionTypeList:any=[
    {name:'(default) Encryption at-rest with a platform-managed key',id:'1'},
    {name:'Encryption at-rest with a customer-managed key',id:'2',"encryptionType": "EncryptionAtRestWithCustomerKey"},
    {name:'Double encryption with platform-managed and customer-managed keys',id:'3',"encryptionType": "EncryptionAtRestWithPlatformAndCustomerKeys"}
  ];

  sourceTypeList:any=[
    {name:'None (empty disk)'},
    {name:'Snapshot'}
  ];

  connectionTypeList:any=[
    {name:'VNet-to-VNet',id:'Vnet2Vnet'},
    {name:'Site-to-site (IPsec)',id:'IPsec'}
  ];

  ikeEncryptionList:any=[
    {name:'AES128'},
    {name:'AES192'},
    {name:'AES256'},
    {name:'GCMAES128'},
    {name:'GCMAES256'}
  ];

  ikeIntegrityList:any=[
    {name:'SHA1'},
    {name:'SHA256'},
    {name:'SHA384'},
    {name:'MD5'} 
  ];

  dhGroupList:any=[
    {name:'DHGroup1'},
    {name:'DHGroup14'},
    {name:'DHGroup2'},
    {name:'DHGroup2048'} ,
    {name:'DHGroup24'},
    {name:'ECP256'},
    {name:'ECP384'},
    {name:'None'} 
  ];

  ipsecEncryptionList:any=[
    {name:'None'},
    {name:'AES128'},
    {name:'AES192'},
    {name:'AES256'} ,
    {name:'DES'},
    {name:'DES3'},
    {name:'GCMAES128'},
    {name:'GCMAES192'} ,
    {name:'GCMAES256'} 
  ];

  ipsecIntegrityList:any=[
    {name:'GCMAES128'},
    {name:'GCMAES192'},
    {name:'GCMAES256'},
    {name:'MD5'} ,
    {name:'SHA1'},
    {name:'SHA256'}
  ];

  pfsGroupList:any=[
    {name:'ECP256'},
    {name:'PFS2048'},
    {name:'ECP384'},
    {name:'None'} ,
    {name:'PFS24'},
    {name:'PFS2'},
    {name:'PFS1'}
  ];

  nextHopTypeList:any=[
    {name:'Virtual network gateway',id:'VirtualNetworkGateway'},
    {name:'Virtual network',id:'VirtualNetwork'},
    {name:'Internet',id:'Internet'},
    {name:'Virtual appliance',id:'VirtualAppliance'},
    {name:'None',id:'None'}
  ]

  routesList:any=[
  ]

  subnetsList:any=[
  ]

  maxSharesList:any=[
  ]

  sshPublicKeyList:Array<{name:string,id:string}>=[
    {name:'Generate new key pair',id:'genNewKeyPair'},
    {name:'Use existing key stored in Azure',id:'useExistKeyAzure'},
    {name:'Use existing public key',id:'useExistPublicKey'}
  ]

  skuList:any=[
    {name:'Basic'},
    {name:'Standard'},
    {name:'Premium'},
  ]

  nodeSizeList:any=[
    {name:'Small (4 vCores / 32 GB)',id:'Small',amount:0.58,vcores:4},
    {name:'Medium (8 vCores / 64 GB)',id:'Medium',amount:1.16,vcores:8},
    {name:'Large (16 vCores / 128 GB)',id:'Large',amount:2.32,vcores:16},
    {name:'XLarge (32 vCores / 256 GB)',id:'XLarge',amount:4.64,vcores:32},
    {name:'XXLarge (64 vCores / 512 GB)',id:'XXLarge',amount:9.27,vcores:64},
  ]
  
  apacheSparkList:any=[
    {name:'2.4',id:'2.4'},
    {name:'3.0 (preview)',id:'3.0'}
  ]

  performanceLevelList:any=[];

  storedKeysList:any=[];

  snapshotList:any=[];
  virtualMachinesList:any=[];
  networkInterfaceList:any=[];
  diskEncryptionMainSetList:any=[];
  diskEncryptionSetList:any=[];
  diskEncryptionSetListForDisk:any=[];
  storageAccountList:any=[];
  timeZoneList:Array<{name:string,time:string}>=[];
  apiloader:any=false;
  keyVaultList:any=[];
  keyVaultListForKey:any=[];
  applicationInsightsList:any=[];
  containerRegistryList:any=[];
  userManagedIdentityList:any=[];
  keyForMLObj: any={};
  createMLSAObj: any={};
  createMLKeyObj: any={};
  createMLAIObj: any={};
  createMLCRObj: any={};
  createMLUMIObj: any={};
  versionList: any[];
  keyList: any[];
  fileSystemNameList: any=[];
  assignUserList: any=[];
  assignApplicationList: any=[];
  createSAAccObj: any={};
  createSAFileObj:any= {};
  SADedicatedPoolObj: any={};
  SAApacheSparkPoolObj:any= {};
  value: number = 3;
  highValue: number = 10;
  options: Options = {
    floor: 3,
    ceil: 200,
    translate:(value: number, label: LabelType): any => {
      return ""
  }
  }
  SAAzureADTenantObj:any= {};
  tenantList: any=[];
  
  constructor(private util:UtilService,private cdr: ChangeDetectorRef,private flowservice: FlowService,) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if(this.templateName=='virtualMachine'&&changes.currentResourceDtls&&changes.currentResourceDtls.currentValue&&changes.currentResourceDtls.currentValue.resourceData){
      if(changes.currentResourceDtls.currentValue.resourceData.selectedVMSize){
      this.virtualMachinemodel.resourceData.selectedVMSize=changes.currentResourceDtls.currentValue.resourceData.selectedVMSize;
      }
      this.virtualMachinemodel.resourceData.availabilityOptionList=changes.currentResourceDtls.currentValue.resourceData.availabilityOptionList;
      this.virtualMachinemodel.resourceData.tempAvailabilityZoneList=changes.currentResourceDtls.currentValue.resourceData.tempAvailabilityZoneList;
      this.virtualMachinemodel.resourceData.availabilityZone=changes.currentResourceDtls.currentValue.resourceData.availabilityZone;
      this.virtualMachinemodel.resourceData.availabilityOption=changes.currentResourceDtls.currentValue.resourceData.availabilityOption;
      //if(changes.currentResourceDtls.currentValue.newVMDiskSize){
        
     // }
      //if(changes.currentResourceDtls.currentValue.vmInOutBoundRule){
        //if(changes.currentResourceDtls.currentValue.vmInOutBoundRule.type=='inboundRules'){
         // this.vmNSGObj.inboundRules.push(changes.currentResourceDtls.currentValue.resourceData.vmInOutBoundRule);
        //}
        //if(changes.currentResourceDtls.currentValue.vmInOutBoundRule.type=='outboundRules') {
          //this.vmNSGObj.outboundRules.push(changes.currentResourceDtls.currentValue.resourceData.vmInOutBoundRule);
        //}

     // }
    }
    if(this.templateName=='virtualMachine'&&changes.currentResourceDtls&&changes.currentResourceDtls.currentValue&&changes.currentResourceDtls.currentValue.newVMDiskSize){
    this.newDiskObj.newVMDiskSize=changes.currentResourceDtls.currentValue.newVMDiskSize;
    }
    if(this.templateName=='virtualMachine'&&changes.currentResourceDtls&&changes.currentResourceDtls.currentValue&&(changes.currentResourceDtls.currentValue.vmInBoundRule||changes.currentResourceDtls.currentValue.vmOutBoundRule)){
      this.vmNSGObj.vmInBoundRule=changes.currentResourceDtls.currentValue.vmInBoundRule;
      this.vmNSGObj.vmOutBoundRule=changes.currentResourceDtls.currentValue.vmOutBoundRule;
      }
  }

  async ngOnInit() {
    this.apiloader=true;
    console.log('templateName',this.templateName)
    //this.VmSizes = this.util.getStatic('vmSizeList');
    //var RGPayload = { "subscriptionId":this.projectData.subscriptionId }
    this.resourcesGroupLocationList = this.util.getStatic('countryList');
    this.performanceLevelList = this.util.getStatic('performanceLevelList');
    //this.countryList = this.resourcesGroupLocationList;
    
    
    await this.getResourceGroupList();
    await this.getListByPropName('virtualNetworks',undefined,undefined);
    if(this.templateName=='virtualNetwork'){
      this.existingNewVNList=this.parentResourceDtls;
      if(this.sideSheetViewType=='new'){
        this.VNetmodel.resourceData={};
      }
      this.VNetmodel.resourceData.virtualNetworkAddressSpace=[{}];
      this.VNetmodel.resourceData.tagList=[{}];
      if(this.sideSheetViewType=='edit'){
        this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null).then(async res=>{
          this.VNetmodel=res;
          if(this.VNetmodel.comments=='null'){
            this.VNetmodel.comments=null;
          }
          this.VNetmodel.resourceData=JSON.parse(res.resourceData);
        });
      }
    }else if(this.templateName=='subnet'){
      if(this.sideSheetViewType=='new'){
        this.subnetModel.resourceData={};
        this.subnetModel.resourceName=null;
      }
      this.subnetModel.resourceData.subnetType='subnet';
      this.subnetModel.resourceData.vnetName=this.parentResourceDtls.resourceName;
      this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.parentResourceDtls.resourceId],null).then(async res=>{
        console.log('Resource ',res);
        let resourceObj=this.resourceGroupList.find(dt=>dt.name==JSON.parse(res.resourceData).resourceGroup);
        this.subnetModel.resourceData.resourceGroup=resourceObj.name;
        await this.getListByPropName('natGateways',undefined,undefined);
        await this.getListByPropName('networkSecurityGroups',undefined,undefined);
        await this.getListByPropName('routeTables',undefined,undefined);
        await this.getListByPropName('availableDelegations',resourceObj,resourceObj.location);
        await this.getListByPropName('virtualNetworkAvailableEndpointServices',resourceObj,resourceObj.location);
        if(this.sideSheetViewType=='edit'){
          this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null).then(async res=>{
            this.subnetModel=res;
            this.subnetModel.resourceData=JSON.parse(res.resourceData);
            if(this.parentResourceDtls['updateRequestFrom']){
              this.subnetModel.resourceData.vnetName=this.parentResourceDtls.resourceName;
              this.subnetModel.resourceData.resourceGroup=resourceObj.name;
              let limitField={
                target:{
                  value:this.subnetModel.resourceData.subnetAddressSpace
                }
              }
              this.subnetAddressSpace(limitField);
            }
            this.subnetUpdateAdvanceVal();
          });
        }
      })
    }else {
      if(this.templateName=='SQLVM'){
        this.templateName='virtualMachine';
        this.imageList=this.util.getStatic('sqlVMImageList');
      }else if(this.templateName=='linuxVirtualMachine'){
        this.templateName='virtualMachine';
        this.imageList=this.util.getStatic('linuxVMImageList');
      }else{
        this.imageList=this.util.getStatic('vmImageList');
      }
      if(this.sideSheetViewType=='new'){
        this[this.templateName+'model'].resourceData={};
        this[this.templateName+'model'].resourceName=null;
        this[this.templateName+'model'].resourceData.tagList=[{}];
      }
      let parentResourceData,resourceObj;
      if(this.parentResourceDtls&&this.parentResourceDtls.resourceName){
        this[this.templateName+'model'].resourceData.subnet=this.parentResourceDtls.resourceName;
        parentResourceData=JSON.parse(this.parentResourceDtls.resourceData);
        this[this.templateName+'model'].resourceData.vnetName=parentResourceData.vnetName;
        resourceObj=this.resourceGroupList.find(dt=>dt.name==parentResourceData.resourceGroup);
        this[this.templateName+'model'].resourceData.resourceGroup=resourceObj.name;
        this[this.templateName+'model'].resourceData.vnetResourceGroup=parentResourceData.resourceGroup;
        this.resourceGroupList=this.resourceGroupList.filter(dt=>dt.location==resourceObj.location);
      }
      if(this.templateName=='virtualMachine'){        
        this.virtualMachinemodel.resourceData.osDiskType=this.osDiskTypeList[0].id;
        this.virtualMachinemodel.resourceData.nicNSG='Basic';
        this.virtualMachinemodel.resourceData.publicIp={name:'None'};
        this.virtualMachinemodel.resourceData.availabilityOption='None';
        this.virtualMachinemodel.resourceData.bootDiagnostics='EWMSA';
        this.virtualMachinemodel.resourceData.diskEncryptionType=this.diskEncryptionTypeList[0].id;
        this.virtualMachinemodel.resourceData.image=this.imageList[0];
        this.virtualMachinemodel.resourceData.sshPublicKey=this.sshPublicKeyList[0].id;
        this.virtualMachinemodel.resourceData.resourceGroupLocation=resourceObj.location;
        this.virtualMachinemodel.resourceData.authType='ssh';
        this.virtualMachinemodel.resourceData.autoShutdownStatus=true;
        this.virtualMachinemodel.resourceData.autoShutdownNotificationStatus=true;
        this.timeZoneList=this.util.getStatic('timeZoneList');
        this.virtualMachinemodel.resourceData.timeZone='UTC';
        this.virtualMachinemodel.resourceData.email= localStorage.getItem('email');
        this.virtualMachinemodel.resourceData.autoShutdownTime='7:00:00 PM';
      await this.getListByPropName('virtualMachineSizeList',resourceObj,resourceObj.location);
      await this.getListByPropName('publicIPAddresses',undefined,undefined);
      await this.getListByPropName('snapshots',undefined,undefined);
      // await this.getListByPropName('disk',resourceObj,undefined);
      await this.getListByPropName('virtualMachines',undefined,undefined);
      await this.getListByPropName('diskEncryptionSets',undefined,undefined);
      await this.getListByPropName('availabilitySets',resourceObj,undefined);
      await this.getListByPropName('storageAccounts',undefined,undefined);
      await this.getListByPropName('networkSecurityGroups',undefined,undefined);
      await this.getListByPropName('sshPublicKeys',undefined,undefined);      
      await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
      this.virtualMachinemodel.resourceData.availabilityOptionList=this.availabilityOptionWithoutZoneList;
      this.virtualMachinemodel.resourceData.tempAvailabilityZoneList=this.availabilityZoneList;
      this.tempVmPublicIPList=this.vmPublicIPList;
      this.assignPrivateIPToVM();
      }
      if(this.templateName=='networkInterface')
      {
        //this.networkInterfacemodel.resourceData.privateIPAddr= 'Dynamic';
        await this.getListByPropName('networkSecurityGroups',undefined,undefined); 
        this.networkInterfacemodel.resourceData.nsg=this.nsgList[0].id;
        this.networkInterfacemodel.resourceData.privateIPAddr= 'Dynamic';
        this.assignPrivateIPToDynamicNIC();
      }
      if(this.templateName=='keyVault')
      {
        this.keyVaultmodel.resourceData.pricingTier= 'Standard';
        this.keyVaultmodel.resourceData.softDeleteRetentionInDays= '90';
        this.keyVaultmodel.resourceData.enablePurgeProtection= 'disablePurgeProtection';
        this.keyVaultmodel.resourceData.enableRbacAuthorization= 'vaultAccessPolicy';
      }
      if(this.templateName=='routeTable')
      {  
        this.routeTablemodel.resourceData.propagateGatewayRoutes= 'Yes';
        
        
      }

      if(this.templateName=='connections')
      {
        this.connectionsmodel.resourceData.connectionType= 'Vnet2Vnet';
        this.connectionsmodel.resourceData.IKEProtocol= 'IKEv2';
        this.connectionsmodel.resourceData.ipsecPolicies= 'Default';
        this.connectionsmodel.resourceData.usePolicyBasedTrafficSelectors= 'Disable';
        this.connectionsmodel.resourceData.connectionMode= 'Default';
        this.connectionsmodel.resourceData.dpdTimeoutSeconds= '45';
        this.connectionsmodel.resourceData.saDataSizeKilobytes= '102400000';
        this.connectionsmodel.resourceData.saLifeTimeSeconds= '27000';
        await this.getListByPropName('virtualNetworkGateways',undefined,undefined);
        await this.getListByPropName('localNetworkGateways',undefined,undefined);
      }
      if(this.templateName=='localNetworkGateway')
      {
        this.localNetworkGatewaymodel.resourceData.endpoint= 'IPaddress';
        this.localNetworkGatewaymodel.resourceData.virtualNetworkAddressSpace=[{}];
      }
      if(this.templateName=='loadBalancer')
      {
        this.loadBalancermodel.resourceData.type= 'public';
        this.loadBalancermodel.resourceData.sku= 'Standard';
        this.loadBalancermodel.resourceData.tier= 'Regional';
        this.loadBalancermodel.resourceData.IPaddressAssignment= 'Static';
        this.loadBalancermodel.resourceData.routingPreference= 'microsoftNetwork';
        this.loadBalancermodel.resourceData.publicIP= 'new';
        this.loadBalancermodel.resourceData.subnet=null;
        this.loadBalancermodel.resourceData.availabilityZone= 'No Zone';
        await this.getListByPropName('publicIPAddresses',undefined,undefined);
        await this.getListByPropName('virtualNetworksForLoadBalancer',undefined,undefined);
        this.vmPublicIPList.splice(0,1);
        //await this.getListByPropName('subnetByVN',undefined,undefined);
        //await this.getListByPropName('networkSecurityGroups',undefined,undefined); 
       // this.networkInterfacemodel.resourceData.nsg=this.nsgList[0].id;
       // this.networkInterfacemodel.resourceData.privateIPAddr= 'Dynamic';
        //this.assignPrivateIPToDynamicNIC();
      }
      if(this.templateName=='nsg')
      {
         
        // this.nsgmodel.resourceData.nsg=this.nsgList[0].id;
        // this.nsgmodel.resourceData.privateIPAddr= 'Dynamic';
        await this.getListByPropName('networkSecurityGroups',undefined,undefined);
        
      }
      if(this.templateName=='storageAccount')
      {
         
        this.storageAccountmodel.resourceData.performance= 'Standard';
        this.storageAccountmodel.resourceData.kind= 'StorageV2';
        this.storageAccountmodel.resourceData.redundancy= 'Standard_LRS';
        this.storageAccountmodel.resourceData.readAccessEnabled=true;
        //this.storageAccountmodel.resourceData.infrastructureEncryptionEnabled=false;
        this.storageAccountmodel.resourceData.allowBlobPublicAccess=true;
        this.storageAccountmodel.resourceData.secureTransferEnable=true;
        this.storageAccountmodel.resourceData.keyAccessEnabled=true;
      //  this.storageAccountmodel.resourceData.redundancy= 'Enable blob public access';
        this.storageAccountmodel.resourceData.minimumTlsVersion= 'TLS1_2';
        this.storageAccountmodel.resourceData.accessTier= 'Hot';
        this.storageAccountmodel.resourceData.recovery=6;
        this.storageAccountmodel.resourceData.containerSoftDeleteRetentionDays=7;
        this.storageAccountmodel.resourceData.blobSoftDeleteRetentionDays=7;
        this.storageAccountmodel.resourceData.shareSoftDeleteRetentionDays=7;
        await this.getListByPropName('storageAccounts',undefined,undefined);
        await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
        this.filterStorageSkus(this.storageAccountmodel.resourceData.performance,this.storageAccountmodel.resourceData.kind);        
        
      }
      if(this.templateName=='publicIP')
      {
        this.publicIPmodel.resourceData.IPAddr='Static';
        this.publicIPmodel.resourceData.tier='Regional';
        this.publicIPmodel.resourceData.sku= 'Standard';
        this.publicIPmodel.resourceData.rp= 'microsoftNetwork';
        this.publicIPmodel.resourceData.idleTimeout= '4';
        this.publicIPmodel.resourceData.availabilityZone= 'No Zone';
        if(resourceObj.location){
          this[this.templateName+'model'].resourceData.dnslocation=resourceObj.location;
        }
      }
      if(this.templateName=='logAnalytics')
      {
        this.logAnalyticsmodel.resourceData.sku='pergb2018';
        await this.getListByPropName('workspaces',undefined,undefined);
        if(resourceObj.location){
          this[this.templateName+'model'].resourceData.dnslocation=resourceObj.location;
          
        }
      }
      if(this.templateName=='applicationInsight')
      {
        this.applicationInsightmodel.resourceData.resMode='classic';
        await this.getListByPropName('workspaces',undefined,undefined);
        await this.getListByPropName('components',resourceObj,undefined);
      }
      if(this.templateName=='disk')
      {
        this.diskmodel.resourceData.esd='No';
        this.diskmodel.resourceData.encryptionType= this.diskEncryptionTypeList[0];
        this.diskmodel.resourceData.sourceType= 'None';
        this.diskmodel.resourceData.availabilityZone= 'None';    
        await this.getListByPropName('diskEncryptionSets',undefined,undefined);
        await this.getListByPropName('snapshots',undefined,undefined);
        await this.getListByPropName('disks',resourceObj,undefined); 
        await this.getListByPropName('virtualMachineSizeList',resourceObj,resourceObj.location);
        //let list=this.VmSizes.find(dt=>dt.resourceType=="disks"&&dt.size=="P30");
       // this.diskmodel.resourceData.newVMDiskSize={
         // customDiskSize:1024,
      //     diskSKU:{
      //       name:'Premium SSD',
      //       id:'Premium_LRS'
      //     },
      //     selectVmDiskSizeData:list
      //   };
      //   this.previousDiskSize=this.diskmodel.resourceData.newVMDiskSize.customDiskSize;   
     }
      if(this.templateName=="availabilitySet"){
        this.availabilitySetmodel.resourceData.faultDomains=2;
        this.availabilitySetmodel.resourceData.updateDomains=5;
        this.availabilitySetmodel.resourceData.useManagedDisks='Aligned';
        await this.getListByPropName('proximityPlacementGroups',undefined,undefined);
        await this.getListByPropName('availabilitySets',resourceObj,undefined);
      }
      if(this.templateName=="machineLearning"){
        this.machineLearningmodel.resourceData.identityType='systemAssigned';
        this.machineLearningmodel.resourceData.accessType='accessKey';
        this.machineLearningmodel.resourceData.encryptionType='Microsoft';
        await this.getListByPropName('storageAccountsForML',undefined,undefined);
        await this.getListByPropName('registries',undefined,undefined);
        await this.getListByPropName('components',undefined,undefined);
        await this.getListByPropName('vaults',undefined,undefined);
        await this.getListByPropName('userAssignedIdentities',undefined,undefined);
        if(resourceObj){
          await this.getListByPropName('workspaces',resourceObj,undefined);
          await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
        }
        this.machineLearningmodel.resourceData.containerRegistry=this.containerRegistryList[0];
        let keys=Object.keys(sessionStorage);
        let userObjectId;
        for(let i=0;i<keys.length;i++){
          try{
            if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
              userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
              break;
            }
          }catch(e){
            //console.log(e);
          }
        }
        this.machineLearningmodel.resourceData.userObjectId=userObjectId;
      }

      if(this.templateName=="synapseAnalytics"){
         this.synapseAnalyticsmodel.resourceData.dataLakeStorageGen='subscriptions';
         this.synapseAnalyticsmodel.resourceData.adminUsername='sqladminuser';
         this.synapseAnalyticsmodel.resourceData.doubleEncryp='disable';
         this.synapseAnalyticsmodel.resourceData.allowConnections=true;
         this.synapseAnalyticsmodel.resourceData.allowOutbound='no';
         this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=[];
         this.synapseAnalyticsmodel.resourceData.apacheSparkPool=[];
         this.synapseAnalyticsmodel.resourceData.azureADTenants=[];
         await this.getListByPropName('storageAccountsForSA',undefined,undefined);
         await this.getListByPropNameUsingGrpah('users');
         await this.getListByPropNameUsingGrpah('applications');
         await this.getListByPropName('vaults',undefined,undefined);
         await this.getListByPropName('tenants',undefined,undefined);         
        // await this.getListByPropName('registries',undefined,undefined);
        // await this.getListByPropName('components',undefined,undefined);
        // await this.getListByPropName('vaults',undefined,undefined);
        // await this.getListByPropName('userAssignedIdentities',undefined,undefined);
        // if(resourceObj){
        //   await this.getListByPropName('workspaces',resourceObj,undefined);
        //   await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
        // }
        // this.machineLearningmodel.resourceData.containerRegistry=this.containerRegistryList[0];
        let keys=Object.keys(sessionStorage);
        let userObjectId;
        for(let i=0;i<keys.length;i++){
          try{
            if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
              userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
              break;
            }
          }catch(e){
          }
        }
        this.synapseAnalyticsmodel.resourceData.userObjectId=userObjectId;
        this.synapseAnalyticsmodel.resourceData.a3sAppObjectId="051a67ff-bfd8-44d7-b7b1-cf06dfb66497";
      }

      if(this.sideSheetViewType=='edit'){
        this.util.handleRequest('get','a3s_architectureBlueprint_getResourceById',[this.currentResourceDtls.resourceId],null).then(async res=>{
          this[this.templateName+'model']=res;
          this[this.templateName+'model'].resourceData=JSON.parse(res.resourceData);
          if(this.templateName=='virtualMachine'){
            if (this[this.templateName + 'model'].resourceData.networkSecurityGroup && this[this.templateName + 'model'].resourceData.networkSecurityGroup.name) {
              let findObj=this.nsgList.find(dt => dt.name == this[this.templateName + 'model'].resourceData.networkSecurityGroup.name);
              if(!findObj){
                this.nsgList.push(this[this.templateName + 'model'].resourceData.networkSecurityGroup);
              }
            }
            if (this[this.templateName + 'model'].resourceData.publicIp && this[this.templateName + 'model'].resourceData.publicIp.name) {
              let findObj=this.tempVmPublicIPList.find(dt => dt.name == this[this.templateName + 'model'].resourceData.publicIp.name);
              if(!findObj){
                this.tempVmPublicIPList.push(this[this.templateName + 'model'].resourceData.publicIp);
              }
            }
            if (this[this.templateName + 'model'].resourceData.diagnosticsStorageAccount && this[this.templateName + 'model'].resourceData.diagnosticsStorageAccount.name) {
              let findObj=this.storageAccountList.find(dt => dt.name == this[this.templateName + 'model'].resourceData.diagnosticsStorageAccount.name);
              if(!findObj){
                this.storageAccountList.push(this[this.templateName + 'model'].resourceData.diagnosticsStorageAccount);
              }
            }
          }
          if(this.templateName=='loadBalancer'){
            let resGroupObj={name:this[this.templateName+'model'].resourceData.resourceGroup};
            await this.getListByPropName('subnetByVN',resGroupObj,undefined);
          }
          if(this.parentResourceDtls['updateRequestFrom']){
            this[this.templateName+'model'].resourceData.vnetName=this.parentResourceDtls.resourceName;
            this[this.templateName+'model'].resourceData.resourceGroup=resourceObj.name;
          }
        });
      }
      this.apiloader=false;
    }
  }

  async getVirtualNetworkList(){
    if(this.VNetmodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.VNetmodel.resourceData.resourceGroup);
      if(!resourceObj.resourceGroupId){
      await this.getListByPropName('virtualNetworks',resourceObj,undefined);
      }else{
        this.existingVNListForName=[];
      }
    }
  }

  async getSubnetList(){
    this.loadBalancermodel.resourceData.subnet=null;
    if(this.loadBalancermodel.resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this.loadBalancermodel.resourceData.resourceGroup);
      if(!resourceObj.resourceGroupId){
      await this.getListByPropName('subnetByVN',resourceObj,undefined);
      }else{
        this.existingSubnetList=[];
      }
    }
  }

  async resourceGroupChange(){
    if(this.templateName=='virtualMachine'){
      if(this[this.templateName+'model']&&this[this.templateName+'model'].resourceData.resourceGroup){
        let resourceObj=this.resourceGroupList.find(dt=>dt.name==this[this.templateName+'model'].resourceData.resourceGroup);
        if(!resourceObj.resourceGroupId){
          await this.getListByPropName('availabilitySets',resourceObj,undefined);
          this[this.templateName+'model'].resourceData.availabilitySet=undefined;
        }else{
          this.availabilitySetList=[];
          this[this.templateName+'model'].resourceData.availabilitySet=undefined;
        }
      }
    }else if(this.templateName=='publicIP'){
      if(this[this.templateName+'model']&&this[this.templateName+'model'].resourceData.resourceGroup){
        let resourceObj=this.resourceGroupList.find(dt=>dt.name==this[this.templateName+'model'].resourceData.resourceGroup);
        if(resourceObj.resourceGroupLocation||resourceObj.location){
          this[this.templateName+'model'].resourceData.dnslocation=resourceObj.resourceGroupLocation?resourceObj.resourceGroupLocation:resourceObj.location;
        }
      } 
    }else if(this.templateName=="machineLearning"){
      if(this[this.templateName+'model']&&this[this.templateName+'model'].resourceData.resourceGroup){
        let resourceObj=this.resourceGroupList.find(dt=>dt.name==this[this.templateName+'model'].resourceData.resourceGroup);
        if(resourceObj.location){
          await this.getListByPropName('workspaces',resourceObj,undefined);
          await this.getListByPropName('storageSkus',resourceObj,resourceObj.location);
        }else{
          this.lawList=[];
        }
      }
    }
    else{
    await this.getVirtualNetworkList();
    }
  }

  disabledTier(){
    if(!this.locationCheckList.includes(this.publicIPmodel.resourceData.dnslocation)){
      this.publicIPmodel.resourceData.tier='Regional';
      return true;
    }else if(this.publicIPmodel.resourceData.sku=='Basic'){
      return true
    }else{
      return false;
    }
  }

  showGlobalTier(){
    if(this[this.templateName+'model']&&this[this.templateName+'model'].resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this[this.templateName+'model'].resourceData.resourceGroup);
      if(resourceObj.resourceGroupLocation||resourceObj.location){
        if(this.locationCheckList.includes(resourceObj.resourceGroupLocation?resourceObj.resourceGroupLocation:resourceObj.location)){
          return true;
        }else{
          return false;
        }
      }
    } 
  }

  async getResourceGroupList(){
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        //console.log(e);
      }
    }
    let reqObj={  
      subscriptionId : this.projectData.subscriptionId,
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    
    await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,false).then(async res=>{
        //Get Resource Group Name
        console.log('RGList', res)
        this.resourceGroupList=res? res : [];//M32254290
        await this.getNewResourceGroup();
        if(this.projectData.resourceGroup&&this.projectData.resourceGroup!='null'){
        this.VNetmodel.resourceData.resourceGroup=this.projectData.resourceGroup;        
        }
      });
  }


  ngAfterViewInit(){
    switch(this.templateName){
      case "virtualNetwork" : {
        this.currentTemplate = this.virtualNetworkTemplate;
        this.VNetmodel.resourceType="virtualNetwork";
        console.log('current model',this.VNetmodel);
        break;
      } 
      case "virtualMachine" : {
        this.currentTemplate = this.virtualMachineTemplate;
        this[this.templateName+'model'].resourceType="virtualMachine";
        console.log('current model',this[this.templateName+'model'])
        break;
      } 
      case "subnet" : {
        this.currentTemplate = this.subnetTemplate;
        this.subnetModel.resourceType="subnet";
        console.log('current model',this.subnetModel)
        break;
      } 
      case "SQLVM" : {
        this.currentTemplate = this.virtualMachineTemplate;
        this.virtualMachinemodel.resourceType="SQLVM";
        console.log('current model',this.virtualMachinemodel)
        break;
      } 
      case "linuxVirtualMachine" : {
        this.currentTemplate = this.virtualMachineTemplate;
        this.virtualMachinemodel.resourceType="linuxVirtualMachine";
        console.log('current model',this.virtualMachinemodel)
        break;
      }
      case "azureADDomain" : {
        this.currentTemplate = this.azureADDomainTemplate;
        this.azureADDomainModel.resourceType="azureADDomain";
        console.log('current model',this.azureADDomainModel)
        break;
      }
      case "storageAccount" : {
        this.currentTemplate = this.storageAccountTemplate;
        this.storageAccountmodel.resourceType="storageAccount";
        console.log('current model',this.storageAccountmodel)
        break;
      } 
      case "windowsVirtualDesktop" : {
        this.currentTemplate = this.WVDTemplate;
        this.WVDmodel.resourceType="windowsVirtualDesktop";
        console.log('current model',this.WVDmodel)
        break;
      }
      
      case "networkInterface" : {
        this.currentTemplate = this.networkInterfaceTemplate;
        this[this.templateName+'model'].resourceType="networkInterface";
        console.log('current model',this[this.templateName+'model'])
        break;
    }
    case "keyVault" : {
      this.currentTemplate = this.keyVaultTemplate;
      this[this.templateName+'model'].resourceType="keyVault";
      console.log('current model',this[this.templateName+'model'])
      break;
  }
    case "routeTble" : {
      this.currentTemplate = this.routeTableTemplate;
      this[this.templateName+'model'].resourceType="routeTable";
      console.log('current model',this[this.templateName+'model'])
      break;
  }
     case "connections" : {
      this.currentTemplate = this.connectionsTemplate;
      this[this.templateName+'model'].resourceType="connections";
      console.log('current model',this[this.templateName+'model'])
      break;
  }
    case "localNetworkGateway" : {
      this.currentTemplate = this.localNetworkGatewayTemplate;
      this[this.templateName+'model'].resourceType="localNetworkGateway";
      console.log('current model',this[this.templateName+'model'])
      break;
  }
    case "loadBalancer" : {
      this.currentTemplate = this.loadBalancerTemplate;
      this[this.templateName+'model'].resourceType="loadBalancer";
      console.log('current model',this[this.templateName+'model'])
      break;
  }
    case "nsg" : {
      this.currentTemplate = this.nsgTemplate;
      this[this.templateName+'model'].resourceType="nsg";
      console.log('current model',this[this.templateName+'model'])
      break;
  }
    case "publicIP" : {
      this.currentTemplate = this.publicIPTemplate;
      this[this.templateName+'model'].resourceType="publicIP";
      console.log('current model',this[this.templateName+'model'])
      break;
  }
  case "logAnalytics" : {
    this.currentTemplate = this.logAnalyticsTemplate;
    this[this.templateName+'model'].resourceType="logAnalytics";
    console.log('current model',this[this.templateName+'model'])
    break;
  }
  case "applicationInsight" : {
    this.currentTemplate = this.applicationInsightTemplate;
    this[this.templateName+'model'].resourceType="applicationInsight";
    console.log('current model',this[this.templateName+'model'])
    break;
  }
  case "disk" : {
    this.currentTemplate = this.diskTemplate;
    this[this.templateName+'model'].resourceType="disk";
    console.log('current model',this[this.templateName+'model'])
    break;
  }
  default : {
    this.currentTemplate = this[this.templateName+'Template'];
    this[this.templateName+'model'].resourceType=this.templateName;
    console.log('current model',this[this.templateName+'model'])
    break;
  }


  }
    this.cdr.detectChanges();
    console.log(this.currentTemplate)
  }

  public onToggle(): void {
    this.show = !this.show;
    this.newResourceGroup.resourceGroupName= null;
    this.newResourceGroup.resourceGroupLocation = null;
  }

  async createNewRgHelper(){
    this.newResourceGroup['newResourceGroupEdit']=false;
    await this.createNewResourceGroup();
    
    if(this.templateName!='virtualNetowrk'&&this.templateName!='subnet'&&this[this.templateName+'model']){
      this[this.templateName+'model'].resourceData.resourceGroup=this.newResourceGroup.resourceGroupName;
    }else{
      this.VNetmodel.resourceData.resourceGroup=this.newResourceGroup.resourceGroupName; 
    }
    this.onToggle();
    //this.resourceGroups.push(this.newResourceGroup.resourceGroupName);
    //await this.getResourceGroupList();    
    
    // this.model.resourceGroup = this.newResourceGroup.resourceGroupName;
    // this.model.resourceGroupLocation = this.newResourceGroup.resourceGroupLocation;
    
  }

  async createNewResourceGroup(){
    this.newResourceGroup.subscriptionId=this.projectData.subscriptionId;
    this.newResourceGroup.subscription=this.projectData.subscription;
    console.log(this.newResourceGroup);
    let result  = _.cloneDeep(this.newResourceGroup);
    result['status']='Pending';
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    result['createdBy'] = userName;
    result['projectId']=this.projectData.projectId;
    this.util.handleRequest('post','a3s_architectureBlueprint_addNewResourceGroup',[],result,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      let tempRes=_.cloneDeep(result);
      tempRes['name']=result.resourceGroupName;
      tempRes['location']=result.resourceGroupLocation;
      tempRes['resourceGroupId']=res.resourceGroupId;
      tempResGroup.push(tempRes);
      this.resourceGroupList=tempResGroup;
      return res;
    })
  }

  deleteNewResourceGroup(selectedObj,index){    
    this.util.handleRequest('delete','a3s_architectureBlueprint_deleteNewResourceGroup',[selectedObj.resourceGroupId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }

      let tempRes=_.cloneDeep(this.resourceGroupList);
      tempRes.splice(index,1);
      this.resourceGroupList=[];
      this.resourceGroupList=tempRes;
      if(this.templateName!='virtualNetowrk'&&this.templateName!='subnet'&&this[this.templateName+'model']){
        this[this.templateName+'model'].resourceData.resourceGroup=undefined;
      }else{
        this.VNetmodel.resourceData.resourceGroup=undefined; 
      }
      
    });
  }

  editNewResourceGroup(item,index){
    item.newResourceGroupEdit=true;
    this.onToggle();
    this.newResourceGroup=_.cloneDeep(item);
    
  }
  

  updateNewResourceGroup(index){
    let request=_.cloneDeep(this.newResourceGroup);    
    this.util.handleRequest('put','a3s_architectureBlueprint_updateNewResourceGroup',[],request,null,null,false).then(async res=>{
      if(!res){
        return false;
      }

      let indexR=this.resourceGroupList.findIndex(dt=>dt.resourceGroupId==request['resourceGroupId']);
      let tempRes=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      tempRes[indexR]['name']=request.resourceGroupName;
      tempRes[indexR]['location']=request.resourceGroupLocation;
      this.newResourceGroup['newResourceGroupEdit']=false;
      this.resourceGroupList=tempRes;
      //await this.getNewResourceGroup();
      if(this.templateName!='virtualNetowrk'&&this.templateName!='subnet'&&this[this.templateName+'model']){
        this[this.templateName+'model'].resourceData.resourceGroup=request.resourceGroupName;
      }else{
        this.VNetmodel.resourceData.resourceGroup=request.resourceGroupName; 
      }
      this.onToggle();
    });
  }

  async getNewResourceGroup(){
    this.util.handleRequest('get','a3s_architectureBlueprint_getNewResourceGroupList',[this.projectData.projectId],null,null,null,false).then(res=>{
      if(!res){
        return false;
      }
      let tempResGroup=_.cloneDeep(this.resourceGroupList);
      this.resourceGroupList=[];
      res.forEach(dt=>{
        dt['name']=dt.resourceGroupName;
        dt['location']=dt.resourceGroupLocation;
        tempResGroup.push(dt);
      });
      this.resourceGroupList=tempResGroup;
    });
  }

  addNewAddress(modelObj){
    modelObj.resourceData.virtualNetworkAddressSpace.push({});
  }

  deleteAddress(modelObj,index){
    modelObj.resourceData.virtualNetworkAddressSpace.splice(index,1);
  }

  addTag(){
    this.VNetmodel.resourceData.tagList.push({});
  }

  addTagVM(){
    this[this.templateName+'model'].resourceData.tagList.push({});
  }

  deleteTag(index){
    this.VNetmodel.resourceData.tagList.splice(index,1);
  }

  deleteTagVM(index){
    this[this.templateName+'model'].resourceData.tagList.splice(index,1);
  }

  deleteDisk(index){
    this.virtualMachinemodel.resourceData.newDiskList.splice(index,1);
  }

  editDisk(index){
    this.newDiskObj=this.virtualMachinemodel.resourceData.newDiskList[index];
    this.newDiskObj.updateIndex=index;
    this.virtualMachinemodel.newDiskShow=true;
    this.previousDiskSize=this.newDiskObj.newVMDiskSize.customDiskSize;
  }

  createNewDisk(){
    this.newDiskObj={
      sourceType:'None (empty disk)',
      encryptionType:'1',
      enableSharedDisk:'No'
    };
    this.previousDiskSize=undefined;
    this.virtualMachinemodel.newDiskShow=true;
  }

  createNewPublicIP(){
    if(this.vmPublicIPList&&this.vmPublicIPList.length>0){
      let findObj=this.vmPublicIPList.find(dt=>dt.createNew);
      if(findObj){
        this.publicIPObj=findObj;
      }else{
        this.publicIPObj = {
          SKU:'Basic',
          assignment:'Static',
          routingPreference:'microsoftNetwork'
        }
      }
    }else{
      this.publicIPObj = {
        SKU:'Basic',
        assignment:'Static',
        routingPreference:'microsoftNetwork'
      }
    }
    this.virtualMachinemodel.publicIPShow=true;
  }

  createNewVmAS(){
    if(this.availabilitySetList&&this.availabilitySetList.length>0){
      let findObj=this.availabilitySetList.find(dt=>dt.createNew);
      if(findObj){
        this.vmASObj=findObj;
      }
    }else{
      this.vmASObj = {
        faultDomains:2,
        updateDomains:5
      }
    }
    this.virtualMachinemodel.availabilitySetShow=true;
  }

  createNewNSG(){
    if(this.nsgList&&this.nsgList.length>0){
      let findObj=this.nsgList.find(dt=>dt.createNew);
      if(findObj){
        this.vmNSGObj=findObj;
      }else{
        this.vmNSGObj={};
      }
    }else{
      this.vmNSGObj={};
    }
    this.virtualMachinemodel.nsgShow=true;
  }

  createNewStorageAcount(){
    if(this.storageAccountList&&this.storageAccountList.length>0){
      let findObj=this.storageAccountList.find(dt=>dt.createNew);
      if(findObj){
        this.vmStorageAccount=findObj;
      }else{
        this.vmStorageAccount = {
          kind: 'Storage',
          performance: 'Standard'
        };
      }
    }else{
      this.vmStorageAccount = {
        kind: 'Storage',
        performance: 'Standard'
      };
    }
    this.filterStorageSkus(this.vmStorageAccount.performance, this.vmStorageAccount.kind);
    this.virtualMachinemodel.storageAccountShow=true;
  }

  hideShow(){
    this.showView=!this.showView;
  }

  changeLoaderSKU(){
    if((this.loadBalancermodel.resourceData.sku=="Basic"||this.loadBalancermodel.resourceData.sku=="Standard")&&this.loadBalancermodel.resourceData.type=="internal"){
      this.loadBalancermodel.resourceData.IPaddressAssignment='Dynamic';
      this.loadBalancermodel.resourceData.tier='Regional';
    }else if((this.loadBalancermodel.resourceData.sku=="Basic")&&this.loadBalancermodel.resourceData.type=="public"){
      this.loadBalancermodel.resourceData.tier='Regional';
      this.loadBalancermodel.resourceData.IPaddressAssignment='Dynamic';
    }
    if(this.loadBalancermodel.resourceData.sku=="Standard"&&this.loadBalancermodel.resourceData.type=="internal"){
      this.loadBalancermodel.resourceData.availabilityZone="Zone-reduntant";
    }else{
      this.loadBalancermodel.resourceData.availabilityZone=null;
    }
  }
  changeLoaderType(){
    if((this.loadBalancermodel.resourceData.sku=="Basic"||this.loadBalancermodel.resourceData.sku=="Standard")&&this.loadBalancermodel.resourceData.type=="internal"){
      this.loadBalancermodel.resourceData.IPaddressAssignment='Dynamic';
      this.loadBalancermodel.resourceData.tier='Regional';
    }else if((this.loadBalancermodel.resourceData.sku=="Basic")&&this.loadBalancermodel.resourceData.type=="public"){
      this.loadBalancermodel.resourceData.tier='Regional';
      this.loadBalancermodel.resourceData.IPaddressAssignment='Dynamic';
    }
    if(this.loadBalancermodel.resourceData.sku=="Standard"&&this.loadBalancermodel.resourceData.type=="internal"){
      this.loadBalancermodel.resourceData.availabilityZone="Zone-reduntant";
      this.loadBalancermodel.resourceData.routingPreference='microsoftNetwork';
    }else{
      this.loadBalancermodel.resourceData.availabilityZone=null;
    }    
  }

  changeLoaderTier(){
    if(this.loadBalancermodel.resourceData.tier=='Global'){
      this.loadBalancermodel.resourceData.IPaddressAssignment='Static';
      this.loadBalancermodel.resourceData.routingPreference='microsoftNetwork';
    }
  }

  async getListByPropName(propName,resourceObj,selectedObj){
    
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      resourceGroupName:!selectedObj&&resourceObj?resourceObj.name:undefined,
      location:selectedObj?selectedObj:undefined,
      fetchPropName:propName=='storageAccountsForML'||propName=='storageAccountsForSA'?'storageAccounts':propName=='virtualNetworksForLoadBalancer'?'virtualNetworks':propName,
      token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      virtualtNetworkName:propName=='subnetByVN'?this.loadBalancermodel.resourceData.virtualNetwork:undefined
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      let parentResourceData=this.parentResourceDtls&&this.parentResourceDtls.resourceData?JSON.parse(this.parentResourceDtls.resourceData):{};
      let tempResourceObj=this.resourceGroupList.find(dt=>dt.name==this.subnetModel&&this.subnetModel.resourceData&&this.subnetModel.resourceData.resourceGroup?this.subnetModel.resourceData.resourceGroup:(this[this.templateName+'model']?this[this.templateName+'model'].resourceData.resourceGroup:parentResourceData.resourceGroup));
      if(propName=='natGateways'){
        if(res.value&&res.value.length>0)
        result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.natGetwaysList=result;
        this.subnetModel.resourceData.natGatway='None';
      }else if(propName=='networkSecurityGroups'){
        if(this.templateName=='virtualMachine'||this.templateName=='nsg'){
        this.nsgList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
        }else{
        if(res.value&&res.value.length>0)
        result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.nsgList=result;
        this.subnetModel.resourceData.networkSecurityGroup='None';
        }
      }else if(propName=='routeTables'){
        if(res.value&&res.value.length>0)
        result.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.routeTablesList=result;
        this.subnetModel.resourceData.routeTable='None';
      }else if(propName=='availableDelegations'){
        let dsResult=[{
          serviceName:"None"
        }];
        if(res.value&&res.value.length>0)
        dsResult.push(...res.value);
        this.dsServiceList=dsResult;
        this.subnetModel.resourceData.dsService='None';
      }else if(propName=='virtualNetworkAvailableEndpointServices'){
        this.servicesList=res.value;
        this.subnetModel.resourceData.services=null;
      }else if(propName=='virtualNetworks'&&!resourceObj){
        this.existingVNList=res.value;
      }else if(propName=='virtualNetworksForLoadBalancer'){
        this.existingVNList=res.value.filter(dt=>dt['location']==tempResourceObj.location);//res.value;
      }else if(propName=='virtualNetworks'&&resourceObj){
        this.existingVNListForName=res.value;
      }else if(propName=='virtualMachineSizeList'){
        this.VmSizes=res.value;
      }else if(propName=='storageSkus'){
        this.storageSkusList=res.value;
      }else if(propName=='publicIPAddresses'){
        let PIPResult=[{
          name:"None"
        }];
        if(res.value&&res.value.length>0)
        PIPResult.push(...res.value.filter(dt=>dt['location']==tempResourceObj.location));
        this.vmPublicIPList=PIPResult;
      }else if(propName=='virtualMachines'){
        this.virtualMachinesList=res.value;
       } else if(propName=='networkInterface'){
          this.networkInterfaceList=res.value;
      }else if(propName=='snapshots'){
        this.snapshotList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }else if(propName=='disk'){
        // if(!this.virtualMachinemodel.resourceData.newDiskList&&res.value.length>0){
        //   this.virtualMachinemodel.resourceData.newDiskList=[]; 
        // }else if(this.virtualMachinemodel.resourceData.newDiskList&&this.virtualMachinemodel.resourceData.newDiskList.length>0){
        //   let tempList=_.cloneDeep(this.virtualMachinemodel.resourceData.newDiskList);
        //   if(res.value&&res.value.length>0)
        //   tempList.push(...res.value);
        //   this.virtualMachinemodel.resourceData.newDiskList=tempList;
        // }
      }else if(propName=='availabilitySets'){
        this.availabilitySetList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }else if(propName=='diskEncryptionSets'){
        this.diskEncryptionMainSetList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }else if(propName=='storageAccounts'){
        this.storageAccountList=res.value.filter(dt=>dt['location']==tempResourceObj.location);
      }else if(propName=='workspaces'){
        this.lawList=res.value;
      }else if(propName=='components'){
        this.aiList=res.value;
      } else if (propName == 'storage') {
        this.saList = res.value;
      } else if (propName == 'disks') {
        this.diskList = res.value;
      } else if (propName == 'sshPublicKeys') {
        this.storedKeysList = res.value.filter(dt=>dt['location']==tempResourceObj.location);
      } else if (propName == 'proximityPlacementGroups') {
        this.proxPlacGroupList=res.value.filter(dt=>dt['location']==tempResourceObj.location);;
       }else if(propName == 'subnetByVN'){
        this.existingSubnetList=res.value;
       }else if(propName == 'virtualNetworkGateways'){
        this.virtualNetworkGatewayList=res.value;
       }else if(propName == 'localNetworkGateways'){
        this.localNetworkGatewayList=res.value;
       }else if(propName == 'vaults'){
        this.keyVaultList=res.value;
        this.keyVaultListForKey=_.cloneDeep(res.value);
       }else if(propName == 'components'){
        this.applicationInsightsList=res.value;
       }else if(propName == 'registries'){
        let PIPResult=[{
          name:"None"
        }];
        if(res.value&&res.value.length>0)
        PIPResult.push(...res.value);
        this.containerRegistryList=PIPResult;
       }else if(propName == 'storageAccountsForML'){
        this.storageAccountList=res.value.filter(dt=>!dt.properties.isHnsEnabled);
       }else if(propName == 'userAssignedIdentities'){
        this.userManagedIdentityList=res.value;
       }else if(propName=='storageAccountsForSA'){
        this.storageAccountList=res.value.filter(dt=>dt.properties&&dt.properties.isHnsEnabled);
       }else if(propName=='tenants'){
        this.tenantList=res.value;
       }
      return  result;
    },err=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='natGateways'){
        this.natGetwaysList=result;
        this.subnetModel.resourceData.natGatway='None';
      }else if(propName=='networkSecurityGroups'){
        if(this.templateName!='virtualMachine'){
          this.nsgList=[];
          }else{
        this.nsgList=result;
        this.subnetModel.resourceData.networkSecurityGroup='None';
          }
      }else if(propName=='routeTables'){
        this.routeTablesList=result;
        this.subnetModel.resourceData.routeTable='None';
      }else if(propName=='availableDelegations'){
        let dsResult=[{
          serviceName:"None"
        }];
        this.dsServiceList=dsResult;
        this.subnetModel.resourceData.dsService='None';
      }else if(propName=='virtualNetworkAvailableEndpointServices'){
        this.servicesList=[];
        this.subnetModel.resourceData.services=null;
      }else if(propName=='virtualNetworks'&&!resourceObj){
        this.existingVNList=[];
      }else if(propName=='virtualNetworks'&&resourceObj){
        this.existingVNListForName=[];
      }else if(propName=='publicIPAddresses'){
        let PIPResult=[{
          name:"None"
        }];
        this.vmPublicIPList=PIPResult;
      }
    });
  }

  async getListByPropNameUsingGrpah(propName){
    
    let reqObj={
      fetchPropName:propName
    };
    await this.util.handleRequest('post','a3s_resource_getListByPropNameUsingGrpah',[],reqObj).then(async res=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='users'){
        this.assignUserList=res.value;
       } else if(propName=='applications'){
          this.assignApplicationList=res.value;
      }
      return  result;
    },err=>{
      let result=[{
        name:'None',
        id:'None'
      }];
      if(propName=='users'){
        this.assignUserList=[];
       } else if(propName=='applications'){
          this.assignApplicationList=[];
      }
    });
  }

  getCurrentResourceData(){
    switch(this.templateName){
      case "virtualNetwork" : return this.VNetmodel 
      case "virtualMachine" :return this[this.templateName+'model']
      case "subnet" :return this.subnetModel 
      case "azureADDomain" :return this.azureADDomainModel 
      case "storageAccount" :return this.storageAccountmodel 
      case "windowsVirtualDesktop": return this.WVDmodel
      case "networkInterface" :return this[this.templateName+'model']
      case "keyVault" :return this[this.templateName+'model']
      case "routeTable" :return this[this.templateName+'model']
      case "connections" :return this[this.templateName+'model']
      case "localNetworkGateway" :return this[this.templateName+'model']
      case "publicIP" :return this[this.templateName+'model']
      case "logAnalytics" :return this[this.templateName+'model'] 
      case "applicationInsight" :return this[this.templateName+'model']
      case "disk" :return this[this.templateName+'model']
      case "nsg" :return this[this.templateName+'model'] 
      case "loadBalancer" :return this[this.templateName+'model']      
      default :return this[this.templateName+'model']
    }
  }
async clearResourceModel(){
  switch(this.templateName){
    case "virtualNetwork" :{  
      this.VNetmodel.resourceName=null;
      this.VNetmodel.resourceData.virtualNetworkAddressSpace=null;
      this.VNetmodel.comment=null;
      break;
    }
    case "virtualMachine" : {
      this[this.templateName+'model'].resourceName=null;
      this[this.templateName+'model'].resourceData.VmSize=null;
      this[this.templateName+'model'].comment=null;
      break;
    }
    case "subnet" : {
      this.subnetModel.resourceName=null;
      this.subnetModel.resourceData.subnetAddressSpace=null;
      this.subnetModel.comment=null;
      break;
    }
    case "azureADDomain" : {
      this.azureADDomainModel.resourceName=null;
      this.azureADDomainModel.resourceData.subnetAddressSpace=null;
      this.azureADDomainModel.comment=null;
      break;
    }
    case "storageAccount" : {
      this.storageAccountmodel.resourceName=null;
      this.storageAccountmodel.resourceData.subnetAddressSpace=null;
      this.storageAccountmodel.comment=null;
      break;
    }
    case "windowsVirtualDesktop" : {
      this.WVDmodel.resourceName=null;
      this.WVDmodel.resourceData.numberOfVMs=null;
      this.WVDmodel.resourceData.VmSize=null;
      break;
    }
  }
}

  async triggerBtn(flag){
    if(flag=='save'){
      await this.saveResourceDetails();
      this.triggerBtnvalue.emit({value:false,type:'save'})
      await this.clearResourceModel();
      console.log(this.getCurrentResourceData());
    }else if(flag=='update'){
      await this.updateResourceDtls();
      let modalDtls;
      if(this.templateName=='virtualNetwork'){
        modalDtls=_.cloneDeep(this.VNetmodel);
      }else if(this.templateName=='subnet'){
        modalDtls=_.cloneDeep(this.subnetModel);
        modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      }else{
        modalDtls=_.cloneDeep(this[this.templateName+'model']);
        modalDtls['updateRequestFrom']=this.parentResourceDtls['updateRequestFrom'];
      }
      modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:true,data:modalDtls,type:'update'})
      await this.clearResourceModel();
      console.log(this.getCurrentResourceData());
    }else if(flag=='vmSize'||flag=='newDisk'||flag=='inboundRules'||flag=='outboundRules'){
      let modalDtls;
      if(this.templateName=='virtualMachine'){
        modalDtls=_.cloneDeep(this[this.templateName+'model']);
      }
     // modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:false,data:flag=='newDisk'?this.newDiskObj:flag=='inboundRules'||flag=='outboundRules'?(this.templateName=='nsg'?this[this.templateName+'model'].resourceData:this.vmNSGObj):this[this.templateName+'model'],vmSizeList:this.VmSizes,type:flag})
    }
    else if(flag=='tempDisk'){
      let modalDtls;
      if(this.templateName=='disk'){
        modalDtls=_.cloneDeep(this[this.templateName+'model']);
      }
     // modalDtls.resourceData=JSON.stringify(modalDtls.resourceData);
      this.triggerBtnvalue.emit({value:false,data:this[this.templateName+'model'].resourceData,vmSizeList:this.VmSizes,type:flag})
    }
  }

  close(){
    this.triggerBtnvalue.emit({value:false,type:'close'})
  }

  closeSubSideSheet(){

  }

  changeSKU(event){
    if(event.target.value=='Standard'){
    this.publicIPObj.SKU='Standard';
    this.publicIPObj.assignment='Static';
    this.publicIPObj.routingPreference='microsoftNetwork';
    }else if(event.target.value=='Basic'){
      this.publicIPObj.SKU='Basic';
      this.publicIPObj.assignment='Dynamic';
      this.publicIPObj.routingPreference='microsoftNetwork';
      }
  }

  checkNSGName(event,limit){
    let value=event.target.value;
    this.inputError.nameLen=false;
    this.inputError.nameValid=false;
    this.inputError.nameDupCheck=false;
    if(value){
      if(value.length>limit){
        this.inputError.nameLen=true;
        return;
      }
      if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
        this.inputError.nameValid = false;
      }
      else{
        this.inputError.nameValid = true;
        return;
      }
  
      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
          this.inputError.nameValid = true;
          return;
        }
      }
    
      if(this.nsgList&&this.nsgList.length>0){
        let findObj=this.nsgList.find((dt,index)=>dt.name==value&&dt.id);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.parentResourceDtls.devices){
        let findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.nsgmodel.resourceId);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
    }
  }

  validateWorksapceName(event,maxLimit){
    let value=event.target.value;
    this.inputError['workspaceMaxLen']=false;
    this.inputError['workspaceNameCheck']=false;
    this.inputError['workspaceEndCheck']=false;
    this.inputError['workspaceStartCheck']=false;
    this.inputError['workspacePartText']=false;
    this.inputError['workspaceNameUnqiue']=false;
    //this.inputError['fileNameHypensCheck']=false;
    if(value){
      let count=0,hypCount;
      if(value.includes('-ondemand')){
        this.inputError['workspacePartText'] = true;
        return;
      }
    for(let input of value){
      if(count==0&&(input=='-')){
        this.inputError['workspaceStartCheck'] = true;
        return;
      }
      // if(input=="-"){
      //   if(hypCount&&hypCount+1==count){
      //     this.inputError['fileNameHypensCheck'] = true;
      //     return;
      //   }
      //   hypCount=count;
      // }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError['workspaceNameCheck'] = true;
        return;
      }
      count=count+1;
    }
      if(value.length>maxLimit){
        this.inputError['workspaceMaxLen']=true;
        return;
      }
      if(value[value.length-1]=="-"){
        this.inputError['workspaceEndCheck']=true;
        return;
      }
      let findObj;
      if(!findObj){
        if(this.parentResourceDtls){
          let list=[];
          if(this.parentResourceDtls.devices){
            list=this.parentResourceDtls.devices;
          }else if(this.parentResourceDtls.length){
            list=this.parentResourceDtls
          }
          findObj=list.find(dt=>value==dt.resourceName&&dt.resourceId!=this[this.templateName+'model'].resourceId);
        }
      }
      if(findObj){
        this.inputError['workspaceNameUnqiue']=true;
        return
      }

      this.checkSynapseWorkspaceName(event,'workspaceNameUnqiue');
    }
  }

  async checkSynapseWorkspaceName(event,flagCheck){    
    let value=event.target.value;
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      name:value
    }
    await this.util.handleRequest('post','a3s_architectureBlueprint_checkSynapseWorkspaceName',[],reqObj,null,null,true).then(res=>{
      if(res&&res.available){
        this.inputError[flagCheck] =false; 
      }else{
        this.inputError[flagCheck] =true; 
      }
    })
  }

  validateFileSystemName(event,minLimit,maxLimit){
    let value=event.target.value;
    this.inputError['fileNameLen']=false;
    this.inputError['fileNameCheck']=false;
    this.inputError['fileNameEndCheck']=false;
    this.inputError['fileNameStartCheck']=false;
    this.inputError['fileNameHypensCheck']=false;
    if(value){
      let count=0,hypCount;
    for(let input of value){
      if(count==0&&(input=='-')){
        this.inputError['fileNameStartCheck'] = true;
        return;
      }
      if(input=="-"){
        if(hypCount&&hypCount+1==count){
          this.inputError['fileNameHypensCheck'] = true;
          return;
        }
        hypCount=count;
      }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError['fileNameCheck'] = true;
        return;
      }
      count=count+1;
    }
      if(value.length>maxLimit||value.length<minLimit){
        this.inputError['fileNameLen']=true;
        return;
      }
      if(value[value.length-1]=="-"){
        this.inputError['fileNameEndCheck']=true;
        return;
      }
    }
  }

  validateFileSystemNamePop(event,minLimit,maxLimit){
    let value=event.target.value;
    this.inputError['fileNameLenCheck']=false;
    this.inputError['fileNameValidation']=false;
    this.inputError['fileNameEnd']=false;
    this.inputError['fileNameStart']=false;
    this.inputError['fileNameHypens']=false;
    this.inputError['fileNameUnqiue']=false;
    if(value){
      let count=0,hypCount;
    for(let input of value){
      if(count==0&&(input=='-')){
        this.inputError['fileNameStart'] = true;
        return;
      }
      if(input=="-"){
        if(hypCount&&hypCount+1==count){
          this.inputError['fileNameHypens'] = true;
          return;
        }
        hypCount=count;
      }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError['fileNameValidation'] = true;
        return;
      }
      count=count+1;
    }
      if(value.length>maxLimit||value.length<minLimit){
        this.inputError['fileNameLenCheck']=true;
        return;
      }
      if(value[value.length-1]=="-"){
        this.inputError['fileNameEnd']=true;
        return;
      }

      let findObj=this.fileSystemNameList.find(dt=>dt.name==value);
      if(!findObj){
        if(this.parentResourceDtls){
          let list=[];
          if(this.parentResourceDtls.devices){
            list=this.parentResourceDtls.devices;
          }else if(this.parentResourceDtls.length){
            list=this.parentResourceDtls
          }
          findObj=list.find(dt=>{
            if(dt.resourceId!=this[this.templateName+'model'].resourceId){
              let resourceData=JSON.parse(dt.resourceData);
              if(resourceData.fileSystemName.name==value){
                return true;
              }
            }
          });
        }
      }
      if(findObj){
        this.inputError['fileNameUnqiue']=true;
      }
    }
  }

  validateAccountNameSA(event,minLimit,maxLimit){
    let value=event.target.value;
    this.inputError['accountNameValid']=false;
    this.inputError['accountNameCheck']=true;
    if(value){
      if(value.length>maxLimit||value.length<minLimit){
        this.inputError['accountNameValid']=true;
        return;
      }

      for(let input of value){
        if( !(this.isCharALowerCaseLetter(input) || this.isCharANumber(input)) ){
          this.inputError['accountNameValid'] = true;
          return;
        }
      }
      this.checkStorageNameAvail(event,'accountNameCheck');
    }
  }

  changeStorageAccountSA(){
    this.fileSystemNameList=[];
    if(this.synapseAnalyticsmodel.resourceData.storageAccount&&this.synapseAnalyticsmodel.resourceData.storageAccount.id){
      let reqObj={
        storageAccountId:this.synapseAnalyticsmodel.resourceData.storageAccount.id,
        token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      }
      this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
        if(res){
          this.fileSystemNameList=res.value;
        }
      });
    }
  }

  validateAdminUsername(limitField, limitNum){
    let value =limitField.target.value;
    this.inputError['adminStartValid'] = false;
    this.inputError['adminLenValid'] =false;
    this.inputError['adminValidChar'] =false;
    this.inputError['adminReservValid'] =false;
    if(value){
      if (value.length > limitNum) {
          this.inputError['adminLenValid'] = true;
          return;
      }

      if( !(this.isCharALetter(value[0]))){
        this.inputError['adminStartValid'] = true;
        return;
      }

      let regex=/\`|\~|\ |\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      if(regex.test(value)){
        this.inputError['adminValidChar'] =true;
        return;
      } 

      if(value=='admin'||value=='administrator'||value=='sa'||value=='root'||value=='dbmanager'||value=='loginmanager'||value=='dbo'||value=='guest'||value=='public'){
        this.inputError['adminReservValid'] =true;
        return;
      }
    }
  }

  validateAdminPassword(limitField,minLen,maxLen){
    let value =limitField.target.value;
    this.inputError['adminPLenValid'] =false;
    this.inputError['adminPValid'] =false;
    this.inputError['adminPNameValid'] =false;
    if(value){
      if (value.length > maxLen||value.length<minLen) {
          this.inputError['adminPLenValid'] = true;
          return;
      }

      // let regex=/\`|\~|\ |\@|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      // if(regex.test(value)){
      //   this.inputError['adminPValid'] =true;
      // } 
      // for(let input of value){
      //   if( !(this.isCharAUpperCaseLetter(input)|| this.isCharALowerCaseLetter(input) || this.isCharANumber(input) ||input=='!' || input=='$' || input=='#' || input=='%') ){
      //     this.inputError['adminPValid'] = true;
      //     return;
      //   }
      // }

      let count=0;
      if((/[A-Z]/).test(value)){
        count=count+1;
      }
      if((/[a-z]/).test(value)){
        count=count+1;
      }
      if((/[0-9]/).test(value)){
        count=count+1;
      }
      let regexT=/\%|\#|\$|\!|\`|\ |\(|\)|\-|\-|\~|\@|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      if(regexT.test(value)){
        count=count+1;
      }
      if(count<3){
        this.inputError['adminPValid'] = true;
        return;
      } 

      // if(value=='admin'||value=='administrator'||value=='sa'||value=='root'||value=='dbmanager'||value=='loginmanager'||value=='dbo'||value=='guest'||value=='public'){
      //   this.inputError['adminPNameValid'] =true;
      //   return;
      // }
    }
  }
  
  validateAdminConfrimPassword(event){
    let value =event.target.value;
    this.inputError['adminPConfirmValid'] =false;
    if(value){
      if(value==this.synapseAnalyticsmodel.resourceData.adminPassword){
        this.inputError['adminPConfirmValid'] =true;
      }
    }
  }

  validateManagedResourceGroup(event,maxLen){
    let value =event.target.value;
    this.inputError['managedLenValid'] =false;
    this.inputError['managedValid'] =false;
    this.inputError['managedDupValid'] =false;
    if(value){
      if(value.length>maxLen){
        this.inputError['managedLenValid'] =true;
      }

      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) ||input=='.' || input=='_' || input=='-' || input=='"') ){
          this.inputError['managedValid'] = true;
          return;
        }
      }

      if(value[value.length]=='.'){
        this.inputError['managedValid'] = true;
        return;
      }

      let findObj=this.resourceGroupList.find(dt=>dt.name==value);
      if(!findObj){
        if(this.parentResourceDtls){
          let list=[];
          if(this.parentResourceDtls.devices){
            list=this.parentResourceDtls.devices;
          }else if(this.parentResourceDtls.length){
            list=this.parentResourceDtls
          }
          findObj=list.find(dt=>{
            if(dt.resourceId!=this[this.templateName+'model'].resourceId){
              let resourceData=JSON.parse(dt.resourceData);
              if(resourceData.managedResourceGroup==value){
                return true;
              }
            }
          });
        }
      }
      if(findObj){
        this.inputError['managedDupValid']=true;
      }
    }
  }

  validateDPName(event,maxLen){
    let value =event.target.value;
    this.inputError['dedicatedNameLen'] =false;
    this.inputError['dedicatedNameCheck'] =false;
    this.inputError['dedicatedNameDup'] =false;
    this.inputError['dedicatedNameValid'] =false;
    this.inputError['dedicatedNameReserved'] =false;
    if(value){
      if(value.length>maxLen){
        this.inputError['dedicatedNameLen'] =true;
        return;
      }

      let regex=/\`|\~|\-|\@|\^|\&|\%|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      if(regex.test(value)||value[value.length-1]=="."||value[value.length-1]==" "){
        this.inputError['dedicatedNameValid'] =true;
        return;
      }
      
      let findObj;
      if(!findObj){
        if(this.parentResourceDtls){
          let list=[];
          if(this.parentResourceDtls.devices){
            list=this.parentResourceDtls.devices;
          }else if(this.parentResourceDtls.length){
            list=this.parentResourceDtls
          }
          findObj=list.find(dt=>{
            if(dt.resourceId!=this[this.templateName+'model'].resourceId){
              let resourceData=JSON.parse(dt.resourceData);              
              if(resourceData.dedicatedSQLPool.find(dt=>dt.name==value)){
                return true;
              }
            }
          });
        }
        if(this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool&&!findObj){
          findObj=this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool.find(dt=>dt.name==value);
         }
      }
      if(findObj){
        this.inputError['dedicatedNameDup']=true;
      }
    }
  }
  
  validateASPName(event,maxLen){
    let value =event.target.value;
    this.inputError['apacheNameLen'] =false;
    this.inputError['apacheNameStart'] =false;
    this.inputError['apacheNameEnd'] =false;
    this.inputError['apacheNameUnique'] =false;
    this.inputError['apacheNameValid'] =false;
    this.inputError['apacheNameReserved'] =false;
    if(value){
      if(value.length>maxLen){
        this.inputError['apacheNameLen'] =true;
        return;
      }

      if(!this.isCharALetter(value[0])){
        this.inputError['apacheNameStart'] =true;
        return;
      }      

      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input)) ){
          this.inputError['apacheNameValid'] = true;
          return;
        }
      }
      
      let findObj;
      if(!findObj){
        if(this.parentResourceDtls){
          let list=[];
          if(this.parentResourceDtls.devices){
            list=this.parentResourceDtls.devices;
          }else if(this.parentResourceDtls.length){
            list=this.parentResourceDtls
          }
          findObj=list.find(dt=>{
            if(dt.resourceId!=this[this.templateName+'model'].resourceId){
              let resourceData=JSON.parse(dt.resourceData);              
              if(resourceData.apacheSparkPool.find(dt=>dt.name==value)){
                return true;
              }
            }
          });
        }
        if(this.synapseAnalyticsmodel.resourceData.apacheSparkPool&&!findObj){
         findObj=this.synapseAnalyticsmodel.resourceData.apacheSparkPool.find(dt=>dt.name==value);
        }
      }
      if(findObj){
        this.inputError['apacheNameUnique']=true;
      }
    }
  }

  validateASPNumberMinu(event,minLen,maxLen){
    let value =event.target.value;
    this.inputError['numberMinutesLen'] =false;
    this.inputError['numberMinutesValid'] =false;
    this.inputError['numberMinutesIdle'] =false;
    if(value){
      if(value>maxLen||value<minLen){
        this.inputError['numberMinutesLen'] =true;
        return;
      }      

      for(let input of value){
        if( !(this.isCharANumber(input)) ){
          this.inputError['numberMinutesValid'] = true;
          return;
        }
      }
      
    }
  }

  performanceLevelChange(){
    if(this.SADedicatedPoolObj.performanceLevel){
      this.SADedicatedPoolObj.priceDetails.count=parseInt(this.SADedicatedPoolObj.performanceLevel.split('DW')[1].split('c')[0])/100;
      this.SADedicatedPoolObj.priceDetails.amount=1.51*this.SADedicatedPoolObj.priceDetails.count;
    }
  }

  addTenants(){
    this.synapseAnalyticsmodel.SAAzureADTenantShow=true;
    this.SAAzureADTenantObj={
      autoscale:'azure'
    };
  }

  validateTenant(event){
    let value=event.target.value;

  }

  createNewDSP(){
    this.synapseAnalyticsmodel.SADedicatedPoolShow=true;
    this.SADedicatedPoolObj={
      geoReduandant:'yes',
      performanceLevel:'DW100c',
      visibleGeoRedundantOption:false,
      tagList:[{}],
      priceDetails:{
        amount:1.51,
        count:1
      }
    };
    this.checkVisibleGeoRedundantOption();
  }

  checkVisibleGeoRedundantOption(){
    this.SADedicatedPoolObj.visibleGeoRedundantOption=false;
    if(this[this.templateName+'model']&&this[this.templateName+'model'].resourceData.resourceGroup){
      let resourceObj=this.resourceGroupList.find(dt=>dt.name==this[this.templateName+'model'].resourceData.resourceGroup);
      if(resourceObj.resourceGroupLocation||resourceObj.location){
        let reqObj={
          subscriptionId:this.projectData.subscriptionId,
          location:resourceObj.resourceGroupLocation||resourceObj.location,
          fetchPropName:'capabilities',
          token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
        }
        this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
          if(res&&res.status&&res.status!="Available"){
            this.SADedicatedPoolObj.visibleGeoRedundantOption=true;
          }
        });
      }else{
        this.SADedicatedPoolObj.visibleGeoRedundantOption=false;
      }
    } 
  }

  addTagSA(fieldObj){
    this[fieldObj].tagList.push({});
  }

  deleteTagSA(index,fieldObj){
    this[fieldObj].tagList.splice(index,1);
  }

  silderValueChange(){
    if(this.SAApacheSparkPoolObj.numberNodes){
      this.value=parseInt(this.SAApacheSparkPoolObj.numberNodes.toString());
    }
    if(this.SAApacheSparkPoolObj.maxNumberNodes){
      this.highValue=parseInt(this.SAApacheSparkPoolObj.maxNumberNodes.toString());
    }
    if(this.SAApacheSparkPoolObj.nodeSize){
      this.SAApacheSparkPoolObj.priceDetails={
        amountMin:(this.SAApacheSparkPoolObj.nodeSize.amount*this.value).toFixed(2),
        amountMax:(this.SAApacheSparkPoolObj.nodeSize.amount*this.highValue).toFixed(2)
      }
    }
  }

  changeValSider(){
    if(this.SAApacheSparkPoolObj.nodeSize){
      this.SAApacheSparkPoolObj.priceDetails={
        amountMin:(this.SAApacheSparkPoolObj.nodeSize.amount*this.value).toFixed(2),
        amountMax:(this.SAApacheSparkPoolObj.nodeSize.amount*this.highValue).toFixed(2)
      };
      
      this.SAApacheSparkPoolObj.numberNodes=this.value;
      this.SAApacheSparkPoolObj.maxNumberNodes=this.highValue;
      
    }
  }

  nodeSizeChange(){
    if(this.SAApacheSparkPoolObj.nodeSize){
      this.SAApacheSparkPoolObj.priceDetails={
        amountMin:(this.SAApacheSparkPoolObj.nodeSize.amount*this.value).toFixed(2),
        amountMax:(this.SAApacheSparkPoolObj.nodeSize.amount*this.highValue).toFixed(2)
      }
    }
  }
  
  createNewASP(){
    this.synapseAnalyticsmodel.SAApacheSprakPoolShow=true;
    this.SAApacheSparkPoolObj={
      nodeSize:this.nodeSizeList[1],
      autoscale:'enable',
      automaticPausing:'enable',
      apacheSpark:'2.4',
      packages:'enable',
      numberMinutes:15,
      numberNodes:3,
      maxNumberNodes:10,
      tagList:[{}],
      priceDetails:{
        amountMin:(this.nodeSizeList[1].amount*3).toFixed(2),
        amountMax:(this.nodeSizeList[1].amount*10).toFixed(2)
      }
    };
  }

  editDedicateSqlPool(i){
    let temp=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool[i]);
    this.SADedicatedPoolObj=_.cloneDeep(temp);
    this.SADedicatedPoolObj.updateIndex=i;
    this.synapseAnalyticsmodel.SADedicatedPoolShow=true;
  }
  
  editTenantSA(i){
    let temp=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.azureADTenants[i]);
    this.SAAzureADTenantObj=_.cloneDeep(temp);
    this.SAAzureADTenantObj.updateIndex=i;
    this.synapseAnalyticsmodel.SAAzureADTenantShow=true;
  }

  deleteDedicateSqlPool(i){
    this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool.splice(i,1);
  }

  deleteTenantSA(i){
    this.synapseAnalyticsmodel.resourceData.azureADTenants.splice(i,1);
  }

  editApacheSparkPool(i){
    let temp=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.apacheSparkPool[i]);
    this.SAApacheSparkPoolObj=_.cloneDeep(temp);
    this.SAApacheSparkPoolObj.updateIndex=i;
    this.synapseAnalyticsmodel.SAApacheSprakPoolShow=true;
  }

  deleteApacheSparkPool(i){
    this.synapseAnalyticsmodel.resourceData.apacheSparkPool.splice(i,1);
  }

  checkVmASName(event,limit){
    let value=event.target.value;
    this.inputError.nameLen=false;
    this.inputError.nameValid=false;
    this.inputError.nameDupCheck=false;
    if(value){
      if(value.length>limit){
        this.inputError.nameLen=true;
        return;
      }
      if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
        this.inputError.nameValid = false;
      }
      else{
        this.inputError.nameValid = true;
        return;
      }
  
      for(let input of value){
        if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
          this.inputError.nameValid = true;
          return;
        }
      }
    
      if(this.availabilitySetList&&this.availabilitySetList.length>0){
        let findObj=this.availabilitySetList.find((dt)=>dt.name==value&&dt.id);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
      if(this.parentResourceDtls.devices&&this.parentResourceDtls.devices.length>0&&this.availabilitySetmodel&&this.availabilitySetmodel.resourceName){
        let findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.availabilitySetmodel.resourceId);
        if(findObj){
          this.inputError.nameDupCheck=true;
          return;
        }
      }
    }
  }

  changeAvailablity(){
    if(this.virtualMachinemodel.resourceData.availabilityOption&&this.virtualMachinemodel.resourceData.availabilityOption=='availabilityZone'
    &&this.virtualMachinemodel.resourceData.availabilityZone){
      this.tempVmPublicIPList=this.vmPublicIPList.filter(dt=>!dt.zones||dt.zones.length==0||(dt.zones.findIndex(zt=>zt==this.virtualMachinemodel.resourceData.availabilityZone)>-1));
    }else{
      this.tempVmPublicIPList=this.vmPublicIPList;
    }
    if(this.tempVmPublicIPList&&this.tempVmPublicIPList.length>0){
      this.virtualMachinemodel.resourceData.publicIp=this.tempVmPublicIPList[0];
    }
  }

  changeSanpshot(modelObj){
    if(modelObj.snapshot&&modelObj.snapshot.properties&&modelObj.snapshot.properties.diskSizeGB
      &&modelObj.newVMDiskSize&&modelObj.newVMDiskSize.customDiskSize&&modelObj.newVMDiskSize.customDiskSize<modelObj.snapshot.properties.diskSizeGB){
      modelObj.newVMDiskSize=undefined;
    }
  }

  validateResourceName(event, limitNum){
    let value =event.target.value;
    if (value.length > limitNum) {
      this.inputError.maxLenResourceName = true;
      return;
    }
    else {
      this.inputError.maxLenResourceName = false;
    }

    let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(value[value.length-1]=='.'||regex.test(value)){
      this.inputError.validResourceName=true;
      return;
    }else{
      this.inputError.validResourceName=false;
    }

    if(this.resourceGroupList&&this.resourceGroupList.length){
      let findObj=this.resourceGroupList.find(dt=>dt.name==value&&!dt.newResourceGroupEdit);
      this.inputError.duplicateResourceName = findObj?true:false;
    }else {
      this.inputError.duplicateResourceName = false;
    }
  }
  laNameValidation(limitField, minLimit,maxLimit) {
    let value =limitField.target.value
    if (value.length > maxLimit || value.length<minLimit) {
        this.inputError.laname = true;
    }
    else this.inputError.laname = false;

    let regex=/\@|\&|\*|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
    if(value[0]=='-'||value[value.length-1]=='-' ||regex.test(value)){
      this.inputError.laSpecialChar =true;
      return;
    } 
    this.inputError.laSpecialChar =false;
  }

  VmNameValidation(limitField, limitNum) {
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMname = true;
    }
    else this.inputError.VMname = false;

    let regex=/\@|\&|\*|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
    if(value[0]=='_'||value[value.length-1]=='.' || value[value.length-1]=='-'||regex.test(value)){
      this.inputError.VmSpecialChar =true;
      return;
    } 
    this.inputError.VmSpecialChar =false;

    if((/^[0-9]*$/).test(value)){
      this.inputError.VMOnlyNUmbers =true;
      return;
    }

    this.inputError.VMOnlyNUmbers =false;

    this.vmDuplicateCheck(value);
    
  }

  workspaceNameValidation(limitField, limitNum) {
    let value =limitField.target.value;
    this.inputError.duplicateAi=false;
    if (value.length > limitNum) {
        this.inputError.VMname = true;
        return;
    }
    else this.inputError.VMname = false;

    let regex=/\@|\&|\ |\*|\.|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
    if(value[0]=='_'|| value[0]=='-'||regex.test(value)){
      this.inputError.VMname =true;
      return;
    } 
    this.inputError.VMname =false;

    // if((/^[0-9]*$/).test(value)){
    //   this.inputError.VMOnlyNUmbers =true;
    //   return;
    // }

    let findObj=this.lawList.find(dt=>dt.name==value);
    if(this.parentResourceDtls&&!findObj){
      findObj=this.parentResourceDtls.find(dt=>dt.resourceName==value&&dt.resourceId!=this.machineLearningmodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateAi=true;
      return;
    }
    this.inputError.duplicateAi=false;
    this.populateDefaultValueML();
    if(value.length<3){
      this.inputError.VMname = true;
      return;
    }
  }

  populateDefaultValueML(){
    if(this.machineLearningmodel.resourceName){
      let value=this.machineLearningmodel.resourceName.toString().substring(0, 10);
      let tempList=_.cloneDeep(this.storageAccountList);
      this.storageAccountList=[];
      let findObj=tempList.find(dt=>!dt.id&&!dt.createNew);
      if(!findObj){
        tempList.splice(0,0,{
          name:value+(Math.random().toString().split('.')[1]).substring(0,10)
        });
      }else{
        tempList[0].name=value+(Math.random().toString().split('.')[1]).substring(0,10);
      }
      this.storageAccountList=_.cloneDeep(tempList);
      this.machineLearningmodel.resourceData.storageAccount=this.storageAccountList[0];

      tempList=_.cloneDeep(this.keyVaultList);
      this.keyVaultList=[];
      let findObjK=tempList.find(dt=>!dt.id&&!dt.createNew);
      if(!findObjK){
        tempList.splice(0,0,{
          name:(isNaN(value[0])?value:'a'+value)+(Math.random().toString().split('.')[1]).substring(0,10)
        });
      }else{
        tempList[0].name=(isNaN(value[0])?value:'a'+value)+(Math.random().toString().split('.')[1]).substring(0,10);
      }
      this.keyVaultList=_.cloneDeep(tempList);
      this.machineLearningmodel.resourceData.keyVault=this.keyVaultList[0];

      tempList=_.cloneDeep(this.applicationInsightsList);
      this.applicationInsightsList=[];
      let findObjA=tempList.find(dt=>!dt.id&&!dt.createNew);
      if(!findObjA){
        tempList.splice(0,0,{
          name:value+(Math.random().toString().split('.')[1]).substring(0,10)
        });
      }else{
        tempList[0].name=value+(Math.random().toString().split('.')[1]).substring(0,10);
      }
      this.applicationInsightsList=_.cloneDeep(tempList);
      this.machineLearningmodel.resourceData.applicationInsights=this.applicationInsightsList[0];
    }else{
      let tempList=_.cloneDeep(this.storageAccountList);
      this.storageAccountList=[];
      tempList.splice(0,1);
      this.storageAccountList=_.cloneDeep(tempList);
      tempList=_.cloneDeep(this.keyVaultList);
      this.keyVaultList=[];
      tempList.splice(0,1);
      this.keyVaultList=_.cloneDeep(tempList);
      tempList=_.cloneDeep(this.applicationInsightsList);
      this.applicationInsightsList=[];
      tempList.splice(0,1);
      this.applicationInsightsList=_.cloneDeep(tempList);
    }
  }

  autoShutdownTimeValidation(event){
    let value=event.target.value;
    this.inputError['validTimeFormat']=!moment(value,'h:mm:ss A',true).isValid();
  }

  keyPairNameValidation(limitField){
    let value =limitField.target.value;
    this.inputError.keyPairNameSpChar = false;
    this.inputError['keyPairNameDup']=false;
    if(value){
      let regex=/\@|\&|\*|\+|\=|\[|\]|\||\\|\<|\,|\>|\?|\/|\"|\;|\ |\:|\s/;
      if(value[0]=='_'||value[value.length-1]=='.' || value[value.length-1]=='-'||regex.test(value)){
        this.inputError.keyPairNameSpChar =true;
        return
      } 

      let findObj=this.storedKeysList.find(dt=>dt.name==value);
      if(findObj){
        this.inputError['keyPairNameDup']=true;
      }else{
        if(this.parentResourceDtls.devices){
          this.parentResourceDtls.devices.find(dt=>{
            let resourceData = JSON.parse(dt.resourceData);
            if(resourceData.keyPairName==value&&dt.resourceId!=this.virtualMachinemodel.resourceId){
              this.inputError['keyPairNameDup']=true;
              return true;
            }
          });
        }
      }
    }
  }

  publicKeyValidation(limitField){
    let value =limitField.target.value;
    this.inputError.publicKeyValid = false;
    this.inputError['sshKeyBase64Valid'] = false;
    if(value){
      if(value.includes("ssh-rsa AAAAB3NzaC1yc2EAAAAD")){
        this.inputError.publicKeyValid = false;
        try{
          let valTest=value.split(" ")[1];
          if(valTest[valTest.length-1]=="="){
            this.inputError.publicKeyValid = false;
          }else{
            this.inputError.publicKeyValid = true;
            return;
          }
          window.atob(valTest);
          this.inputError['sshKeyBase64Valid'] = false;
        }catch(err){
          this.inputError['sshKeyBase64Valid'] = true;
        }
      }else{
        this.inputError.publicKeyValid = true;
      } 
    }
  }

  VmAdminUsernameValidation(limitField, limitNum){
    let value =limitField.target.value;
    this.inputError.VMAdminUsername = false;
    this.inputError.VmAdminSpecialChar =false;
    if(value){
      if (value.length > limitNum) {
          this.inputError.VMAdminUsername = true;
      }
      else this.inputError.VMAdminUsername = false;

      let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      if(value[0]=='_'||value[0]=='-'||regex.test(value)){
        this.inputError.VmAdminSpecialChar =true;
        return;
      } 
      this.inputError.VmAdminSpecialChar =false;

      if(value=='a'||value=='admin'){
        this.inputError.VMAdminReserv =true;
        return;
      }
    }

    this.inputError.VMAdminReserv =false;
  }

  VmAdminPasswordValidation(limitField, limitNum,minLimit){
    let value =limitField.target.value;
    this.inputError.VmAdminPasswordLen = false;
    this.inputError.VMAdminPassword =false;
    this.virtualMachinemodel.resourceData.adminConfirmPassword="";
    if(value){
      if (value.length > limitNum||value.length<minLimit) {
          this.inputError.VmAdminPasswordLen = true;
      }
      else this.inputError.VmAdminPasswordLen = false;

      let regex=/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\_|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
      let count=0;
      if((/[A-Z]/).test(value)){
        count=count+1;
      }
      if((/[a-z]/).test(value)){
        count=count+1;
      }
      if((/[0-9]/).test(value)){
        count=count+1;
      }
      if(!regex.test(value)){
        count=count+1;
      }
      if(count<3){
        this.inputError.VMAdminPassword =true;
        return;
      } 
      this.inputError.VMAdminPassword =false;
    }
  }

  VMAdminConfirmPassword(field){
    let value =field.target.value;
    if(value&&value!=this.virtualMachinemodel.resourceData.adminPassword){
    this.inputError.VMAdminPasswordMatch=true;
    }else{
      this.inputError.VMAdminPasswordMatch=false;
    }
  }

  checkLUNNumInput(item,limit,i){
    item.maxLUNNumLen=false;
    item.VMOnlyNUmbers =false;
    item.validEmpty=false;
    if(!item.LUNNum){
      item.validEmpty=true;
      return;
    }
    if(item.LUNNum&&(/^[0-9]*$/).test(item.LUNNum)){
      item.VMOnlyNUmbers =true;
      return;
    }
    if(item.LUNNum&&item.LUNNum>limit){
    item.maxLUNNumLen=true;
    return;
    }
  }

  vmDuplicateCheck(value){
    let findObj=this.virtualMachinesList.find(dt=>dt.name==value);
    if(this.parentResourceDtls.devices){
      findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.virtualMachinemodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateVnet =true; 
    }else{
      this.inputError.duplicateVnet =false; 
    }
  }

  VmDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VMdescription = true;
    }
    else this.inputError.VMdescription = false;
  }

  VNetNameValidation(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VNetName = true;
    }
    else this.inputError.VNetName = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError.VNetChar = false;
    }
    else{
      this.inputError.VNetChar = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError.VNetChar = true;
        return;
      }
    }

    let regex=/\`|\ |\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(regex.test(value)){
      this.inputError.VNetChar=true;
      return;
    }else{
      this.inputError.VNetChar=false;
    }

    let findObj=this.existingVNListForName.find(dt=>dt.name==value);
    if(findObj){
      this.inputError.duplicateVnet=true;
      return;
    }else if(this.existingNewVNList&&this.existingNewVNList.length){
      let findObjN=this.existingNewVNList.find(dt=>dt.resourceName==value&&dt.resourceId!=this.VNetmodel.resourceId);
      if(findObjN){
        this.inputError.duplicateVnet=true;
        return;
      }else{
        this.inputError.duplicateVnet=false;
      }
    }else{
      this.inputError.duplicateVnet=false;
    }
  }

  validationFirstConn(limitField, limitNum){
    let value =limitField.target.value;
    this.inputError['VNetNameFirstConn'] = false;
    this.inputError['VNetCharFirstConn']=false;
    if (value.length > limitNum) {
        this.inputError['VNetNameFirstConn'] = true;
        return;
    }


    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError['VNetCharFirstConn'] = false;
    }
    else{
      this.inputError['VNetCharFirstConn'] = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError['VNetCharFirstConn'] = true;
        return;
      }
    }

    let regex=/\`|\ |\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(regex.test(value)){
      this.inputError['VNetCharFirstConn']=true;
      return;
    }else{
      this.inputError['VNetCharFirstConn']=false;
    }
  }

  validationSecondConn(limitField, limitNum){
    let value =limitField.target.value;
    this.inputError['VNetNameSecondConn'] = false;
    this.inputError['VNetCharSecondConn']=false;
    if (value.length > limitNum) {
        this.inputError['VNetNameSecondConn'] = true;
        return;
    }


    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError['VNetCharSecondConn'] = false;
    }
    else{
      this.inputError['VNetCharSecondConn'] = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError['VNetCharSecondConn'] = true;
        return;
      }
    }

    let regex=/\`|\ |\~|\!|\@|\#|\$|\%|\^|\&|\*|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\s/;
    if(regex.test(value)){
      this.inputError['VNetCharSecondConn']=true;
      return;
    }else{
      this.inputError['VNetCharSecondConn']=false;
    }
  }

  vmDiskNameValidation(limitField,limitNum){
    let value =limitField.target.value;
    this.newDiskObj.nameLen = false;
    this.newDiskObj.nameDupCheck = false;
    this.newDiskObj.duplicateName = false;
    if (value.length > limitNum) {
        this.newDiskObj.nameLen = true;
    }
    else this.newDiskObj.nameLen = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.newDiskObj.nameValid = false;
    }
    else{
      this.newDiskObj.nameValid = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.newDiskObj.nameValid = true;
        return;
      }
    } 
    if(this.virtualMachinemodel.resourceData.newDiskList&&this.virtualMachinemodel.resourceData.newDiskList.length){
      let findObj=this.virtualMachinemodel.resourceData.newDiskList.find((dt,index)=>dt.diskName==value&&this.newDiskObj.updateIndex!=index);
      if(findObj){
        this.newDiskObj.duplicateName=true;
      }
    }
  }
  
  checkFqdnChar(event){
    let value=event.target.value;
    this.inputError.nameValid =false; 
    for(let input of value){
      if(!(this.isCharALetter(input))){
        this.inputError.nameValid = true;
        return;
      }
  }
}

  niDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateNi =false; 
    if(value){
   let findObj;//=this.networkInterfaceList.find(dt=>dt.name==value);
    if(this.parentResourceDtls.devices){
      findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.networkInterfacemodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateNi =true; 
    }else{
      this.inputError.duplicateNi =false; 
    }
  }
  }

  connNameDuplicateCheck(event,flagName){
    let value=event.target.value;
    this.inputError[flagName] =false; 
    if(value){
   let findObj;//=this.networkInterfaceList.find(dt=>dt.name==value);
    if(this.parentResourceDtls&&this.parentResourceDtls.devices){
      findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.connectionsmodel.resourceId);
    }
    if(findObj){
      this.inputError[flagName] =true; 
    }else{
      this.inputError[flagName] =false; 
    }
  }
  }

  connNameIPsecDuplicateCheck(event,fieldName,flagName){
    let value=event.target.value;
    this.inputError[flagName] =false; 
    if(value){
   let findObj;//=this.networkInterfaceList.find(dt=>dt.name==value);
    if(this.parentResourceDtls&&this.parentResourceDtls.devices){
      findObj=this.parentResourceDtls.devices.find(dt=>{
        if(dt.resourceData&&dt.resourceId!=this.connectionsmodel.resourceId){
          let resourceData=JSON.parse(dt.resourceData);
          if(resourceData[fieldName]==value){
            return true;
          }
        }
      });
    }
    if(findObj){
      this.inputError[flagName] =true; 
    }else{
      this.inputError[flagName] =false; 
    }
  }
  }


  filterStorageSkus(performance,kind){
    let kindVal=_.cloneDeep(kind);
    if(kind=='PageBlobStorage'){
      kindVal='StorageV2'
    }
    this.tempStorageSkusList=this.storageSkusList.filter(dt=>dt.tier==performance&&dt.kind==kindVal);
    this.tempStorageSkusList.map(dt=>{
      let findObj=this.util.getStatic('redundancy').find(ds=>ds.id==dt.name.split("_")[1]);
      if(findObj){
        dt.rdname=findObj.name;
      }
      return dt;
    });
    let findObj=this.tempStorageSkusList.find(dt=>dt.name.includes('_LRS'));
    if(this.storageAccountmodel&&this.storageAccountmodel.resourceData){
    this.storageAccountmodel.resourceData.redundancy= findObj?findObj.name:'Standard_LRS';
    }
    if(this.vmStorageAccount){
    this.vmStorageAccount.redundancy= findObj?findObj.name:'Standard_LRS';
    }
    if(this.createMLSAObj){
    this.createMLSAObj.replication= findObj?findObj.name:'Standard_LRS';
    }
  }

  saDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateSa =false; 
    if(value){
   let findObj=this.storageAccountList.find(dt=>dt.name==value);
    if(this.parentResourceDtls.devices){
      findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.storageAccountmodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateSa =true; 
      return;
    }else{
      this.inputError.duplicateSa =false; 
    }

    this.checkStorageNameAvail(event,'duplicateSa');
  }
  }

  async checkStorageNameAvail(event,flagCheck){    
    let value=event.target.value;
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      name:value
    }
    await this.util.handleRequest('post','a3s_architectureBlueprint_checkStorageName',[],reqObj,null,null,true).then(res=>{
      if(res&&res.nameAvailable){
        this.inputError[flagCheck] =false; 
      }else{
        this.inputError[flagCheck] =true; 
      }
    })
  }

  aiDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateAi =false; 
    if(value){
   let findObj=this.aiList.find(dt=>dt.name==value);
    if(this.parentResourceDtls.devices && !findObj){
      findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.applicationInsightmodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateAi =true; 
    }else{
      this.inputError.duplicateAi =false; 
    }
  }
  }

  diskDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateDisk =false; 
    if(value){
   let findObj=this.diskList.find(dt=>dt.name==value);
    if(this.parentResourceDtls.devices && !findObj){
      findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.diskmodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateDisk =true; 
    }else{
      this.inputError.duplicateDisk =false; 
    }
  }
  }

  lawDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateLaw =false; 
    if(value){
   let findObj=this.lawList.find(dt=>dt.name==value);
    if(this.parentResourceDtls.devices && !findObj){
      findObj=this.parentResourceDtls.devices.find(dt=>dt.resourceName==value&&dt.resourceId!=this.logAnalyticsmodel.resourceId);
    }
    if(findObj){
      this.inputError.duplicateLaw =true; 
    }else{
      this.inputError.duplicateLaw =false; 
    }
  }
  }


  vmPublicIPValidation(limitField,limitNum){
    let value =limitField.target.value;
    this.publicIPObj.nameLen = false;
    this.publicIPObj.nameDupCheck = false;
    this.publicIPObj.nameValid = false;
    if (value.length > limitNum) {
        this.publicIPObj.nameLen = true;
    }
    else this.publicIPObj.nameLen = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.publicIPObj.nameValid = false;
    }
    else{
      this.publicIPObj.nameValid = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.publicIPObj.nameValid = true;
        return;
      }
    }    

    if(this.tempVmPublicIPList&&this.tempVmPublicIPList.length){
      let findObj=this.tempVmPublicIPList.find(dt=>dt.name==value&&dt.id);
        if(findObj){
          this.publicIPObj.nameDupCheck = true;
        }
    }
  }

  isCharALetter(char){
    return (/[a-zA-Z]/).test(char);
  }

  isCharANumber(char){
    return (/[0-9]/).test(char);
  }

  isCharALowerCaseLetter(char){
    return (/[a-z]/).test(char);
  }

  isCharAUpperCaseLetter(char){
    return (/[A-Z]/).test(char);
  }

  isAddressSpace(string){
    return (/[0-9].[0-9]+.[0-9]+.[0-9]+[/][0-9]/).test(string);
  }

  VNetAddressSpace(limitField,item,index){
    let value =limitField.target.value
    if(!this.isAddressSpace(value)){
      console.log(value,this.isAddressSpace(value));
      item.VNetAdrspc=true;
      return;
    }
    item.VNetAdrspc=false;
    this.validateAddress(item,index);
  }

  validateOverloopAddress(vlIPRange,ipRangObj){
    if(vlIPRange.range.left.input==ipRangObj.range.left.input){
      return true;
    }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
     return true;
    }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]){
        if(vlIPRange.range.left.parts[2]>ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.right.parts[2]<ipRangObj.range.right.parts[2]){
            return true;
          }
        }else if(vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
          if(vlIPRange.range.left.parts[3]>ipRangObj.range.left.parts[3]){
            if(vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
              return true;
            }
          }
        }
      }
      // if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]&&vlIPRange.range.left.parts[4]==ipRangObj.range.left.parts[4]){
      //   return true
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]<ipRangObj.range.left.parts[1]&&vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]!=ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
      //   return true
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
      //   return true
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]<ipRangObj.range.left.parts[1]&&vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]!=ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
      //   return true
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]){
      //    return true;
      //  }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]<ipRangObj.range.left.parts[2]&&vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]==ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[2]!=ipRangObj.range.right.parts[2]&&vlIPRange.range.right.parts[2]>ipRangObj.range.right.parts[2]){
      //   return true;
      // }else if(vlIPRange.range.left.parts[0]==ipRangObj.range.left.parts[0]&&vlIPRange.range.left.parts[1]==ipRangObj.range.left.parts[1]&&vlIPRange.range.left.parts[2]==ipRangObj.range.left.parts[2]&&vlIPRange.range.left.parts[3]<ipRangObj.range.left.parts[3]&&vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]==ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[2]==ipRangObj.range.right.parts[2]&&vlIPRange.range.right.parts[3]!=ipRangObj.range.right.parts[3]&&vlIPRange.range.right.parts[3]>ipRangObj.range.right.parts[3]){
      //   return true;
      // }
    }else if(vlIPRange.range.left.parts[0]<ipRangObj.range.left.parts[0]){
      if(vlIPRange.range.right.parts[0]>ipRangObj.range.right.parts[0]){
        return true;
      }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]){
        if(vlIPRange.range.right.parts[1]>ipRangObj.range.right.parts[1]){
          return true;
        }
      }
    }else {
      return false;
    }
  }

  validateAddress(item,index){
    const splitAr=item.address.split('/');
    let vlIPRange;
    try{
      vlIPRange=new this.ipSubnetWork(new this.ipV4(splitAr[0]),splitAr[1]);
      item.VNetAdrspc=false;
    }catch(e){
      item.VNetAdrspc=true;
      return false;
    }
    if(vlIPRange.input!=item.address){
      item.notValidCIDR=true;
      item.validCIDRValue=vlIPRange.input;
      return false;
    }else{
      item.notValidCIDR=false;
      item.validCIDRValue=null;
    }

    if(this.publicIPs.includes(item.address)){
     item.publicAddress=true;
     return false;
    }else{
    item.publicAddress=false;
    }

    if(vlIPRange.range.left.parts[0]!="10"&&vlIPRange.range.left.parts[0]!="192"&&vlIPRange.range.left.parts[0]!="172"){
      item.publicAddress=true;
     return false;
    }else if(vlIPRange.range.left.parts[0]=="192"&&vlIPRange.range.left.parts[1]!="168"){
      item.publicAddress=true;
      return false;
    }else if(vlIPRange.range.left.parts[0]=="172"&&vlIPRange.range.left.parts[1]!="16"){
      item.publicAddress=true;
      return false;
    }else{
      item.publicAddress=false;
      }
    if(this.existingVNList&&this.existingVNList.length){
      let result=this.existingVNList.find(dt=>{
        if(dt.properties.addressSpace.addressPrefixes.length){
          let addressPrefixes=dt.properties.addressSpace.addressPrefixes;
          return addressPrefixes.find(vl=>{
            const splitArr=vl.split('/');
           let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
           if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
             item.overlapingValue=vl;
             return true
           }

          })
        }
      })
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

    if(this.existingNewVNList&&this.existingNewVNList.length){
      let result=this.existingNewVNList.find((dt)=>{
        try {
          if(dt.resourceData&&dt.resourceId!=this.VNetmodel.resourceId){
            let resourceData=JSON.parse(dt.resourceData);
           return resourceData.virtualNetworkAddressSpace.find(dtl=>{
              if(dtl.address){
                const splitArr=dtl.address.split('/');
                  let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
                if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
                  item.overlapingValue=dtl.address;
                  return true
                }
              }
            })
          }
        } catch (error) {}
      });
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

    if(item.address){
      let result=this.VNetmodel.resourceData.virtualNetworkAddressSpace.find((dt,i)=>{
        try {
          if(dt.address&&i!=index){
            const splitArr=dt.address.split('/');
              let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
              if(vlIPRange.input==ipRangObj.input){
                item.overlapingValue=dt.address;
                return true
              }else if(vlIPRange.range.right.input==ipRangObj.range.right.input){
                item.overlapingValue=dt.address;
                return true
              }else if(vlIPRange.range.right.parts[0]==ipRangObj.range.right.parts[0]&&vlIPRange.range.right.parts[1]==ipRangObj.range.right.parts[1]&&vlIPRange.range.right.parts[2]==ipRangObj.range.right.parts[2]&&vlIPRange.range.right.parts[3]<ipRangObj.range.right.parts[3]){
                item.overlapingValue=dt.address;
                return true
              }else if(this.validateOverloopAddress(vlIPRange,ipRangObj)){
              item.overlapingValue=dt.address;
              return true
            }
          }
        } catch (error) {}
      });
      if(result){
        item.overlooping=true;
        return true;
      }else{
        item.overlooping=false;
      }
    }

  }

  changeIdleTimeout(event){
    let value=event.target.value;
    this.inputError.limitidletimeout=false;
    this.inputError['limitidletimeoutNum']=false;
    if(value){
      for(let input of value){
        if( !(this.isCharANumber(input)) ){
          this.inputError['limitidletimeoutNum']=true;
          return;
        }
      }
      if((value<4 || value>30)){
        this.inputError.limitidletimeout=true;
      }
    }
  }

  changedpdTimeout(event){
    let value=event.target.value;
    this.inputError.dpdtimeout=false;
    this.inputError['dpdtimeoutNum']=false;
    if(value){
      for(let input of value){
        if( !(this.isCharANumber(input)) ){
          this.inputError['dpdtimeoutNum']=true;
          return;
        }
      }  

      if((value<9 || value>3600)){
        this.inputError.dpdtimeout=true;
      }
    }
  }

  changesaDataSizeLimit(event){
    let value=event.target.value;
    this.inputError.saDataSizelimit=false;
    this.inputError['saDataTimeNum']=false;
    if(value){
      for(let input of value){
        if( !(this.isCharANumber(input)) ){
          this.inputError['saDataTimeNum']=true;
          return;
        }
      }
      if((value<1024 || value>2147483647)){
      this.inputError.saDataSizelimit=true;
      }
    }
  }

  changesaLifeTimeLimit(event){
    let value=event.target.value;
    this.inputError.saLifeTimeLimit=false;
    this.inputError['saLifeTimeNum']=false;
    if(value){
      for(let input of value){
        if( !(this.isCharANumber(input)) ){
          this.inputError['saLifeTimeNum']=true;
          return;
        }
      }
      if((value<300 || value>172799)){
        this.inputError.saLifeTimeLimit=true;
      }
    }
  }

  sharedKeyNameValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.sharedKeyName = true;
        return
    }
    else this.inputError.sharedKeyName = false;

    for(let input of value){
      if(!(this.isCharALetter(input) || this.isCharANumber(input))){
        this.inputError.sharedKeyChar = true;
        return;
      }
    }
    this.inputError.sharedKeyChar = false;
  }


  checkTagInput(item,limitNum,index){
    if(limitNum==256){
      if(item.value&&limitNum<item.value.length){
      item.maxTagValueLen=true;
      return;
      }    
      item.maxTagValueLen=false;
    }else if(limitNum==512){
      let regex = /\%|\&|\\|\<|\>|\?|\//;
      if(item.tagName&&limitNum<item.tagName.length){
        item.maxTagNameLen=true;
        return;
      }else if(item.tagName&&regex.test(item.tagName)){
      item.specialCharTagName=true;
      return;
      }
      item.specialCharTagName=false;
      item.maxTagNameLen=false;

      let result=this.VNetmodel.resourceData.tagList.find((dt,i)=>{
        if(index!=i&&dt.tagName&&item.tagName){
         return dt.tagName==item.tagName;
        }
      });
      if(result){
        item.duplicateTag=true;
        return false;
      }else{
        item.duplicateTag=false;
      }
    }
  }


  VNetDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.VNetDescription = true;
    }
    else this.inputError.VNetDescription = false;
  }

  subnetNameValidation(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.subnetName = true;
    }
    else this.inputError.subnetName = false;

    if( (this.isCharALetter(value[0]) || this.isCharANumber(value[0])) && (this.isCharALetter(value[value.length-1]) || this.isCharANumber(value[value.length-1]) || value[value.length-1]=='_') ){
      this.inputError.subnetChar = false;
    }
    else{
      this.inputError.subnetChar = true;
      return;
    }

    for(let input of value){
      if( !(this.isCharALetter(input) || this.isCharANumber(input) || input=='_' || input=='-' || input=='.') ){
        this.inputError.subnetChar = true;
        return;
      }
    }

    if(this.parentResourceDtls&&this.parentResourceDtls.subnet&&this.parentResourceDtls.subnet.length){
      let findObj=this.parentResourceDtls.subnet.find(dt=>dt.resourceName==value&&this.subnetModel.resourceId!=dt.resourceId);
      if(findObj){
        this.inputError.subnetDuplicate=true;
        return;
      }else{
        this.inputError.subnetDuplicate=false;
      }
    }else{
      this.inputError.subnetDuplicate=false;
    }
  }

  subnetAddressSpace(limitField){
    let value =limitField.target.value
    if(!this.isAddressSpace(value)){
      console.log(value,this.isAddressSpace(value));
      this.inputError.subnetAdrspc=true;
      return;
    }
    this.inputError.subnetAdrspc=false;

    let splitArr=value.split('/');
    try{
      let vlRangeObj=new this.ipSubnetWork(new this.ipV4(splitArr[0]),splitArr[1]);
      this.inputError.subnetNotValidCIDR=false;
      if(vlRangeObj.bits>29){
        this.inputError.subnetPerfix=true;
        return;
      }else{
        this.inputError.subnetPerfix=false;
      }
      if(vlRangeObj.input!=value){
        this.inputError.subnetNotValidCIDR=true;
      }else{
        this.inputError.subnetNotValidCIDR=false;
      }
      let resourceData=JSON.parse(this.parentResourceDtls.resourceData);
      resourceData.virtualNetworkAddressSpace.find((dt,index)=>{
        if(dt.address){
          let splitAr=dt.address.split('/');
          let rangeObj=new this.ipSubnetWork(new this.ipV4(splitAr[0]),splitAr[1]);
            if(this.validateOverloopAddress(vlRangeObj,rangeObj)){
              this.inputError.subnetAddresNotInVnet=false;
              return true;
            }else if(index==resourceData.virtualNetworkAddressSpace.length-1){
              this.inputError.subnetAddresNotInVnet=true;
            }
        }
      })

      if(this.parentResourceDtls.subnet&&this.parentResourceDtls.subnet.length){
        this.parentResourceDtls.subnet.find(dt=>{
          if(dt.resourceData&&this.subnetModel.resourceId!=dt.resourceId){
            let resourceDataSub=JSON.parse(dt.resourceData);
            if(resourceDataSub.subnetAddressSpace){
              const splitArrSub=resourceDataSub.subnetAddressSpace.split('/');
              let ipRangObj=new this.ipSubnetWork(new this.ipV4(splitArrSub[0]),splitArrSub[1]);
            if(this.validateOverloopAddress(vlRangeObj,ipRangObj)){
              this.inputError.subnetOverlaping=true;
              this.subnetModel['overlapingValue']=resourceDataSub.subnetAddressSpace;
              return true;
            }else{
              this.inputError.subnetOverlaping=false;
              this.subnetModel['overlapingValue']='';
            }
            }
          }
        })
      }
    }catch(e){
      this.inputError.subnetNotValidCIDR=true;
      return;
    }
  }

  nicPrivateIpAddress(model) {
    this.inputError.nicValidIPError = false;
    this.inputError.nicRegisterIPError = false;
    this.inputError.nicAddrUsedError = false;
    this.inputError.nicSubnetRangeError = true;
    if (this.parentResourceDtls) {
      let dt=this.parentResourceDtls;
        if (dt.resourceData) {
          let resourceData = JSON.parse(dt.resourceData);
          if (resourceData.subnetAddressSpace) {
            let splitArr = resourceData.subnetAddressSpace.split('/');
            try {
              let val = model.resourceData.privateIPAddrSpace;
              let ipObj = new this.ipV4(val);
              this.inputError.nicSubnetRangeError = this.ipMatches(val, resourceData.subnetAddressSpace);
              if (this.inputError.nicSubnetRangeError) {
                let ipRange = new this.ipSubnetWork(new this.ipV4(splitArr[0]), splitArr[1]);
                if (val == ipRange.range.left.input || val == ipRange.range.right.input) {
                  this.inputError.nicRegisterIPError = true;
                } else if (ipObj.parts[0] == ipRange.range.left.parts[0] && ipObj.parts[1] == ipRange.range.left.parts[1] && ipObj.parts[2] == ipRange.range.left.parts[2] && (ipObj.parts[3] == ipRange.range.left.parts[3] + 1 || ipObj.parts[3] == ipRange.range.left.parts[3] + 2 || ipObj.parts[3] == ipRange.range.left.parts[3] + 3)) {
                  this.inputError.nicRegisterIPError = true;
                } else if (dt.devices) {
                  dt.devices.find(ds => {
                    if (ds.resourceData) {
                      let resourceData = JSON.parse(ds.resourceData);
                      if (ds.resourceId != model.resourceId && resourceData.privateIPAddrSpace && resourceData.privateIPAddrSpace == model.resourceData.privateIPAddrSpace) {
                        this.inputError.nicAddrUsedError = true;
                        return true;
                      }
                      else if (ds.resourceType == 'virtualMachine' && resourceData.vmPrivateIpAddress && resourceData.vmPrivateIpAddress == model.resourceData.privateIPAddrSpace) {
                        this.inputError.nicAddrUsedError = true;
                        return true;
                      }
                    }
                  })
                }
              }
            } catch (err) {
              this.inputError.nicValidIPError = true;
            }
          }
        }
      
    }
  }

  assignPrivateIPToDynamicNIC(){
    if(this.parentResourceDtls.resourceData && this.networkInterfacemodel.resourceData.privateIPAddr=="Dynamic"){
      let resourceData = JSON.parse(this.parentResourceDtls.resourceData);
      if(resourceData.subnetAddressSpace){
        let splitArr=resourceData.subnetAddressSpace.split('/');
        let ipRange = new this.ipSubnetWork(new this.ipV4(splitArr[0]), splitArr[1]);
        if(ipRange){            
          for(let i=4;i<ipRange.range.right.parts[3];i++){
            let ipVal=_.cloneDeep(ipRange.range.left.parts);
          ipVal[3]=ipRange.range.left.parts[3]+i;
          let latestIP=ipVal.join('.');
          let findObjIP;
          if (this.parentResourceDtls.devices) {
            findObjIP=this.parentResourceDtls.devices.find(ds => {
              if (ds.resourceData) {
                let resourceData = JSON.parse(ds.resourceData);
                if (ds.resourceId!=this.networkInterfacemodel.resourceId && resourceData.privateIPAddrSpace && resourceData.privateIPAddrSpace == latestIP) {
                  return true;
                }
                else if (resourceData.vmPrivateIpAddress && resourceData.vmPrivateIpAddress == latestIP) {
                  return true;
                }
              }
            })
          }
          if(!findObjIP){
          this.networkInterfacemodel.resourceData.privateIPAddrSpace=latestIP;
          break;
          }
        }
        }            
      }
    }
}


  subnetDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.subnetDescription = true;
    }
    else this.inputError.subnetDescription = false;
  }

  loadBalancerPrivateIpAddress(model) {
    this.inputError.nicValidIPError = false;
    this.inputError.nicRegisterIPError = false;
    this.inputError.nicAddrUsedError = false;
    this.inputError.nicSubnetRangeError = true;
    if(model.resourceData.subnet) {
      let dt=model;
        if (dt.resourceData) {
          let resourceData = dt.resourceData;
          if (resourceData.subnet.properties.addressPrefix) {
            let splitArr = resourceData.subnet.properties.addressPrefix.split('/');
            try {
              let val = model.resourceData.privateIPAddrSpace;
              let ipObj = new this.ipV4(val);
              this.inputError.nicSubnetRangeError = this.ipMatches(val, resourceData.subnet.properties.addressPrefix);
              if (this.inputError.nicSubnetRangeError) {
                let ipRange = new this.ipSubnetWork(new this.ipV4(splitArr[0]), splitArr[1]);
                if (val == ipRange.range.left.input || val == ipRange.range.right.input) {
                  this.inputError.nicRegisterIPError = true;
                } else if (ipObj.parts[0] == ipRange.range.left.parts[0] && ipObj.parts[1] == ipRange.range.left.parts[1] && ipObj.parts[2] == ipRange.range.left.parts[2] && (ipObj.parts[3] == ipRange.range.left.parts[3] + 1 || ipObj.parts[3] == ipRange.range.left.parts[3] + 2 || ipObj.parts[3] == ipRange.range.left.parts[3] + 3)) {
                  this.inputError.nicRegisterIPError = true;
                }
              }
            } catch (err) {
              this.inputError.nicValidIPError = true;
            }
          }
        }
      
    }
  }

  subnetUpdateAdvanceVal(){
    if(this.subnetModel.resourceData.natGatway!='None'){
      let findObj=this.natGetwaysList.find(dt=>dt.id==this.subnetModel.resourceData.natGatway);
      this.subnetModel.resourceData.natGatway=findObj?findObj.id:'None';
    }else if(this.subnetModel.resourceData.networkSecurityGroup!='None'){
      let findObj=this.nsgList.find(dt=>dt.id==this.subnetModel.resourceData.networkSecurityGroup);
      this.subnetModel.resourceData.networkSecurityGroup=findObj?findObj.id:'None';
    }else if(this.subnetModel.resourceData.routeTable!='None'){
      let findObj=this.routeTablesList.find(dt=>dt.id==this.subnetModel.resourceData.routeTable);
      this.subnetModel.resourceData.routeTable=findObj?findObj.id:'None';
    }else if(this.subnetModel.resourceData.dsService!='None'){
      let findObj=this.dsServiceList.find(dt=>dt.serviceName==this.subnetModel.resourceData.dsService.serviceName);
      this.subnetModel.resourceData.dsService=findObj?findObj:'None';
    }else if(this.subnetModel.resourceData.services instanceof Array &&(this.subnetModel.resourceData.services!=null||this.subnetModel.resourceData.services!='null')){
      let services=[];
      this.servicesList.forEach(dt=>{
        let findObj=this.subnetModel.resourceData.services.find(dtl=>dtl==dt.name);
        if(findObj){
          services.push(findObj);
        }
      })
      this.subnetModel.resourceData.services=services;
    }
  }

  storageAccountNameValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.storageAccountName = true;
        return
    }
    else this.inputError.storageAccountName = false;

    for(let input of value){
      if(!(this.isCharALetter(input) || this.isCharANumber(input))){
        this.inputError.storageAccountChar = true;
        return;
      }
    }
    this.inputError.storageAccountChar = false;
    this.saDuplicateCheck(limitField)
  }

  aiMLValidation(limitField, higherLimitNum){
    let value=limitField.target.value;
    this.inputError['appInsightDup'] =false; 
    if (value.length > higherLimitNum) {
        this.inputError['appInsightName'] = true;
        return
    }
    else this.inputError['appInsightName'] = false;

    for(let input of value){
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='_'||input=='-'||input=='.'||input=='('||input==')')){
        this.inputError['appInsightName'] = true;
        return;
      }
    }

    if(value[value.length-1]=="."){
      this.inputError['appInsightName'] = true;
      return;
    }
    this.inputError['appInsightName'] = false;

    
    if(value){
    let findObj=this.applicationInsightsList.find(dt=>dt.name==value);
      if(this.parentResourceDtls){
        let list=[];
        if(this.parentResourceDtls.devices){
          list=this.parentResourceDtls.devices;
        }else if(this.parentResourceDtls.length){
          list=this.parentResourceDtls
        }
        findObj=list.find(dt=>{
          if(dt.resourceId!=this.machineLearningmodel.resourceId){
            let resourceData=JSON.parse(dt.resourceData);
            if(resourceData.applicationInsights.name==value){
              return true;
            }
          }
        });
      }
      if(findObj){
        this.inputError['appInsightDup'] =true; 
        return;
      }else{
        this.inputError['appInsightDup'] =false; 
      }
    }
  }

  manageIdentityValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError['managedIdentityDup'] =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError['managedIdentityName'] = true;
        return
    }
    else this.inputError['managedIdentityName'] = false;

    if(!(this.isCharALetter(value[0]) || this.isCharANumber(value[0]))){
      this.inputError['managedIdentityName'] = true;
      return;
    }

    for(let input of value){
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-' || input == '_')){
        this.inputError['managedIdentityName'] = true;
        return;
      }
    }
    this.inputError['managedIdentityName'] = false;
    if(value){
      let findObj=this.userManagedIdentityList.find(dt=>dt.name==value);
        if(this.parentResourceDtls){
          let list=[];
          if(this.parentResourceDtls.devices){
            list=this.parentResourceDtls.devices;
          }else if(this.parentResourceDtls.length){
            list=this.parentResourceDtls
          }
          findObj=list.find(dt=>{
            if(dt.resourceId!=this.machineLearningmodel.resourceId){
              let resourceData=JSON.parse(dt.resourceData);
              if(resourceData.userManagedIdentity.name==value){
                return true;
              }
            }
          });
        }
        if(findObj){
          this.inputError['managedIdentityDup'] =true; 
          return;
        }else{
          this.inputError['managedIdentityDup'] =false; 
        }
      }
  }

  closeOtherPop(p1,p2){
    if(p1){
      p1.hide();
    }

    if(p2){
      p2.hide();
    }
  }

  containerMLValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError['containerRgDup'] =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError['containerRgName'] = true;
        return
    }
    else this.inputError['containerRgName'] = false;

    for(let input of value){
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input))){
        this.inputError['containerRgName'] = true;
        return;
      }
    }
    this.inputError['containerRgName'] = false;
    if(value){
      let findObj=this.containerRegistryList.find(dt=>dt.name==value);
        if(this.parentResourceDtls){
          let list=[];
          if(this.parentResourceDtls.devices){
            list=this.parentResourceDtls.devices;
          }else if(this.parentResourceDtls.length){
            list=this.parentResourceDtls
          }
          findObj=list.find(dt=>{
            if(dt.resourceId!=this.machineLearningmodel.resourceId){
              let resourceData=JSON.parse(dt.resourceData);
              if(resourceData.containerRegistry.name==value){
                return true;
              }
            }
          });
        }
        if(findObj){
          this.inputError['containerRgDup'] =true; 
          return;
        }else{
          this.inputError['containerRgDup'] =false; 
        }
      }
  }

  keyMLValidate(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError['keyVaultNamelen'] = true;
        return
    }
    else this.inputError['keyVaultNamelen'] = false;

    let count=0,hypCount;
    for(let input of value){
      if(count==0&&(this.isCharANumber(input)||input=='-')){
        this.inputError['keyVaultName'] = true;
        return;
      }
      if(input=="-"){
        if(hypCount&&hypCount+1==count){
          this.inputError['keyVaultName'] = true;
          return;
        }
        hypCount=count;
      }
      if(!input||!(this.isCharALetter(input) || this.isCharANumber(input)||input=='-')){
        this.inputError['keyVaultName'] = true;
        return;
      }
      count=count+1;
    }
    this.inputError['keyVaultName'] = false;
    this.keyMLDuplicateCheck(limitField)
  }

  keyMLDuplicateCheck(event){
    let value=event.target.value;
    this.inputError['keyVaultDup'] =false; 
    if(value){
   let findObj=this.keyVaultList.find(dt=>dt.name==value);
    if(this.parentResourceDtls){
      let list=[];
      if(this.parentResourceDtls.devices){
        list=this.parentResourceDtls.devices;
      }else if(this.parentResourceDtls.length){
        list=this.parentResourceDtls
      }
      findObj=list.find(dt=>{
        if(dt.resourceId!=this.machineLearningmodel.resourceId){
          let resourceData=JSON.parse(dt.resourceData);
          if(resourceData.keyVault.name==value){
            return true;
          }
        }
      });
    }
    if(findObj){
      this.inputError['keyVaultDup'] =true; 
      return;
    }else{
      this.inputError['keyVaultDup'] =false; 
    }

    this.checkKeyNameAvail(event);
  }
  }

  async checkKeyNameAvail(event){    
    let value=event.target.value;
    let reqObj={
      subscriptionId:this.projectData.subscriptionId,
      name:value
    }
    await this.util.handleRequest('post','a3s_architectureBlueprint_checkKeyName',[],reqObj,null,null,true).then(res=>{
      if(res&&res.nameAvailable){
        this.inputError['keyVaultDup'] =false; 
      }else{
        this.inputError['keyVaultDup'] =true; 
      }
    })
  }

  storageMLValidation(limitField, lowerLimitNum, higherLimitNum){
    let value=limitField.target.value;
    this.inputError.duplicateSa =false; 
    if (value.length > higherLimitNum || value.length <lowerLimitNum) {
        this.inputError.storageAccountName = true;
        return
    }
    else this.inputError.storageAccountName = false;

    for(let input of value){
      if(!input||!(this.isCharALowerCaseLetter(input) || this.isCharANumber(input))){
        this.inputError.storageAccountName = true;
        return;
      }
    }
    this.inputError.storageAccountName = false;
    this.saMLDuplicateCheck(limitField)
  }

  saMLDuplicateCheck(event){
    let value=event.target.value;
    this.inputError.duplicateSa =false; 
    if(value){
   let findObj=this.storageAccountList.find(dt=>dt.name==value);
    if(this.parentResourceDtls){
      let list=[];
      if(this.parentResourceDtls.devices){
        list=this.parentResourceDtls.devices;
      }else if(this.parentResourceDtls.length){
        list=this.parentResourceDtls
      }
      findObj=list.find(dt=>{
        if(dt.resourceId!=this[this.templateName+'model'].resourceId){
          let resourceData=JSON.parse(dt.resourceData);
          if(resourceData.storageAccount&&resourceData.storageAccount.name==value){
            return true;
          }
        }
      });
    }
    if(findObj){
      this.inputError.duplicateSa =true; 
      return;
    }else{
      this.inputError.duplicateSa =false; 
    }

    this.checkStorageNameAvail(event,'duplicateSa');
  }
  }


  validateServiceWithDelegate(){
    let dsService=this.subnetModel.resourceData.dsService;
    if(dsService&&(dsService.name=='Microsoft.BareMetal.AzureVMware'||dsService.name=='Microsoft.BareMetal.CrayServers'||dsService.name=='Microsoft.HardwareSecurityModules.dedicatedHSMs'||dsService.name=='Microsoft.Netapp.volumes')){
      if(this.subnetModel.resourceData.services&&this.subnetModel.resourceData.services.length>0){
        this.inputError.dsServiceCheck=true;
      }else{
        this.inputError.dsServiceCheck=false;
      }		
    }else{
      this.inputError.dsServiceCheck=false;
    }
  }

  storageAccountDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.storageAccountDescription = true;
    }
    else this.inputError.storageAccountDescription = false;
  }

  changeDaysRestore(limitField,flag){
    let value = limitField.target.value;
    for(let input of value){
      if( !(this.isCharANumber(input)) ){
        this.inputError[flag] = true;
        return;
      }
    }
    if (!value || parseInt(value)<1 || parseInt(value) > 364) {
      this.inputError[flag] = true;
    }
    else this.inputError[flag] = false;
  }

  checkSoftDelete(limitField, flag) {
    let value = limitField.target.value;
    if (!value || parseInt(value)<1 ||parseInt(value) > 365) {
      this.inputError[flag] = true;
    }
    else this.inputError[flag] = false;

  }

  azureADDomainNameValidation(limitField, limitNum){
    let value = limitField.target.value;
    let arr = value.split(".");
    if(arr.length>3){
      let prefix=arr[1];
      if(prefix.length > limitNum){
        this.inputError.azureADDomainName = true;
        return;
      }
    }
    this.inputError.azureADDomainName = false;
  }

  azureADDomainDescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.azureADDomainDescription = true;
    }
    else this.inputError.azureADDomainDescription = false;
  }

  WvdVmNumberValidation(limitField, limitNum){
    let value = limitField.target.value;
    value = parseInt(value)
    console.log(value);

    if (value > limitNum || Number.isNaN(value)) {
        this.inputError.WVDsize = true;
    }
    else this.inputError.WVDsize = false;
  }

  WVDdescription(limitField, limitNum){
    let value =limitField.target.value
    if (value.length > limitNum) {
        this.inputError.WVDdescription = true;
    }
    else this.inputError.WVDdescription = false;
  }

  saveResourceDetails(){
    var model = this.getCurrentResourceData();
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_createResource',[this.projectData.projectId],model).then(res=>{
      console.log('Successfully Saved')
    })

  }

  updateResourceDtls(){
    var model = this.getCurrentResourceData();
    console.log(model);
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    model.createdBy = userName
   return this.util.handleRequest('put','a3s_architectureBlueprint_updateResourceList',[model.resourceId],model).then(res=>{
      console.log('Successfully Update resource')
    })    
  }

  checkVnetAddress(){
    if(this.VNetmodel.resourceData.virtualNetworkAddressSpace.length>0){
      for(let i=0;i<this.VNetmodel.resourceData.virtualNetworkAddressSpace.length;i++){
        if(!this.VNetmodel.resourceData.virtualNetworkAddressSpace[i].address||this.VNetmodel.resourceData.virtualNetworkAddressSpace[i].VNetAdrspc||this.VNetmodel.resourceData.virtualNetworkAddressSpace[i].notValidCIDR||this.VNetmodel.resourceData.virtualNetworkAddressSpace[i].overlooping){
          return true;
        }
      }
    }
    return false;
  }

  checkDisable(value){
    switch (value){
      case 'rg-popup': return !this.newResourceGroup.resourceGroupName || !this.newResourceGroup.resourceGroupLocation
    }    
  }

  selectedSubnetType(event){
    if(event.target.value=='gatewaySubnet'){
      this.subnetModel.resourceName='GatewaySubnet';
      this.subnetModel.resourceData.networkSecurityGroup='None';
      if(this.parentResourceDtls&&this.parentResourceDtls.subnet&&this.parentResourceDtls.subnet.length){
        let findObj=this.parentResourceDtls.subnet.find(dt=>dt.resourceName==this.subnetModel.resourceName&&dt.resourceId!=this.subnetModel.resourceId);
        if(findObj){
          this.inputError.subnetDuplicate=true;
          //return;
        }else{
          this.inputError.subnetDuplicate=false;
        }
      }else{
        this.inputError.subnetDuplicate=false;
      }
    }else if(event.target.value=='subnet'){
      this.subnetModel.resourceName=null;
      this.subnetModel.resourceData.networkSecurityGroup='None';
    }
    this.subnetModel.resourceData.subnetType=event.target.value;
  }


  deleteRules(type,i){
    if(type=='inboundRules'){
      if(this.templateName=='nsg'){
        this.nsgmodel.resourceData.vmInBoundRule.splice(i,1);
      }else{
        this.vmNSGObj.vmInBoundRule.splice(i,1);
      }
    }else if(type=='outboundRules'){
      if(this.templateName=='nsg'){
        this.nsgmodel.resourceData.vmOutBoundRule.splice(i,1);
      }else{
        this.vmNSGObj.vmOutBoundRule.splice(i,1);
      }
    }
  }

  editRules(type,i){
    // if(type=='inboundRules'){
      if(this.templateName=='nsg'){
        this.nsgmodel.resourceData.updateIndex=i;
      }else{
      this.vmNSGObj.updateIndex=i;
      }
      this.triggerBtn(type);
      // }else if(type=='outboundRules'){
      //   this.vmNSGObj.updateIndex=i;
      //   this.triggerBtn('outboundRules');
      // }
  }

  changeEncryptionType(flagFrom){
    if(flagFrom=='newDisk' || flagFrom=='disk'){
      let findObj=this.diskEncryptionTypeList.find(dt=>(flagFrom=='newDisk' && dt.id==this.newDiskObj.encryptionType) || (flagFrom=='disk' && dt.id==this.diskmodel.resourceData.encryptionType.id));
      if(findObj&&findObj.encryptionType){
        this.diskEncryptionSetListForDisk=this.diskEncryptionMainSetList.filter(dt=>dt.properties&&dt.properties.encryptionType==findObj.encryptionType);
      }else{
        this.diskEncryptionSetListForDisk=[];
      }
    }else if(flagFrom=='newVm'){
      let findObj=this.diskEncryptionTypeList.find(dt=>dt.id==this.virtualMachinemodel.resourceData.diskEncryptionType);
      if(findObj&&findObj.encryptionType){
        this.diskEncryptionSetList=this.diskEncryptionMainSetList.filter(dt=>dt.properties&&dt.properties.encryptionType==findObj.encryptionType);
      }else{
        this.diskEncryptionSetList=[];
      }
    }
  }

  changeMaxShareList(event,modelObj){
    this.maxSharesList=[];
    if(event.target.value=='Yes'&&modelObj&&modelObj.newVMDiskSize&&modelObj.newVMDiskSize.selectVmDiskSizeData){
      let maxShare=modelObj.newVMDiskSize.selectVmDiskSizeData.capabilities[6].value;
      for(let i=2;i<=parseInt(maxShare);i++){
        this.maxSharesList.push({name:i});
      }
  }
}

  disabledEnableDiskShare(modelObj){
    if(modelObj&&modelObj.newVMDiskSize&&modelObj.newVMDiskSize.selectVmDiskSizeData&&(modelObj.newVMDiskSize.selectVmDiskSizeData.name!='Premium_LRS'||modelObj.newVMDiskSize.selectVmDiskSizeData.capabilities[6].value==1)){
      modelObj.enableSharedDisk='No';
      modelObj.esd='No';
      this.previousDiskSize=modelObj.newVMDiskSize.customDiskSize;
      return true;
    }else if(modelObj&&modelObj.newVMDiskSize&&modelObj.newVMDiskSize.selectVmDiskSizeData){
      if(modelObj.newVMDiskSize.customDiskSize!=this.previousDiskSize){
        modelObj.enableSharedDisk='No';
        modelObj.esd='No';
        this.previousDiskSize=modelObj.newVMDiskSize.customDiskSize;
      }
      return false;
    }else {
      return false;
    }
  }

  checkUpdateDomains(event,maxLimit){
    let value=event.target.value;
    this.inputError.asUpdateDomainsMaxLen=false;
    this.inputError.asUpdateDomainsLen=false;
    if(value&&value>maxLimit){
      this.inputError.asUpdateDomainsMaxLen=true;
    }else if(!value||value<1){
      this.inputError.asUpdateDomainsLen=true;
    }
  }

  checkfaultDomains(event,modelObj){
    let value=event.target.value;
    this.inputError['asFaultDomainsMaxLen']=false;
    this.inputError['asFaultDomainsLen']=false;
    if(!value||value<1){
      this.inputError['asFaultDomainsLen']=true;
      return;
    }
    if(value&&value==1){
      modelObj.updateDomains=1;
      this.inputError.asUpdateDomainsMaxLen=false;
      this.inputError.asUpdateDomainsLen=false;
    }else if(value&&parseInt(value)>3){
      this.inputError['asFaultDomainsMaxLen']=true;
    }
  }

  assignPrivateIPToVM(){
    if(this.sideSheetViewType=='new'){
      if(this.parentResourceDtls.resourceData){
        let resourceData = JSON.parse(this.parentResourceDtls.resourceData);
        if(resourceData.subnetAddressSpace){
          let splitArr=resourceData.subnetAddressSpace.split('/');
          let ipRange = new this.ipSubnetWork(new this.ipV4(splitArr[0]), splitArr[1]);
          if(ipRange){            
            for(let i=4;i<ipRange.range.right.parts[3];i++){
              let ipVal=_.cloneDeep(ipRange.range.left.parts);
            ipVal[3]=ipRange.range.left.parts[3]+i;
            let latestIP=ipVal.join('.');
            let findObjIP;
            if (this.parentResourceDtls.devices) {
              findObjIP=this.parentResourceDtls.devices.find(ds => {
                if (ds.resourceData) {
                  let resourceData = JSON.parse(ds.resourceData);
                  if (resourceData.privateIPAddrSpace && resourceData.privateIPAddrSpace == latestIP) {
                    return true;
                  }
                  else if (resourceData.vmPrivateIpAddress && resourceData.vmPrivateIpAddress == latestIP) {
                    return true;
                  }
                }
              })
            }
            if(!findObjIP){
            this.virtualMachinemodel.resourceData.vmPrivateIpAddress=latestIP;
            break;
            }
          }
          }            
        }
      }
    }
  }

  selectMLEncrypKeyVault(){
    this.machineLearningmodel.MLSelectKeyShow=true;
    if(this.machineLearningmodel.resourceData.encrypKeyVault){
    this.keyForMLObj=_.cloneDeep(this.machineLearningmodel.resourceData.encrypKeyVault);
    }else{
      this.keyForMLObj={};
    }
  }

  keyVaultChangeForKey(){
    this.versionList=[];
    this.keyList=[];
    this.keyForMLObj.key=undefined;
    this.keyForMLObj.version=undefined;
    if(this.keyForMLObj.keyVault){
      let reqObj={
        vaultUri:this.keyForMLObj.keyVault.name,
        token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      }
      this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
        if(res){
          this.keyList=res.value;
        }
      });
    }
  }

  keyChangeForVersion(){
    this.versionList=[];
    this.keyForMLObj.version=undefined;
    if(this.keyForMLObj.keyVault&&this.keyForMLObj.key){
      let reqObj={
        vaultUri:this.keyForMLObj.keyVault.name,
        token:`Bearer ${JSON.parse(sessionStorage.getItem('data'))['token']}`,
        keyName:this.keyForMLObj.key.kid.split('/')[this.keyForMLObj.key.kid.split('/').length-1]
      }
      this.util.handleRequest('post','a3s_resource_getListByPropName',[],reqObj).then(async res=>{
        if(res){
          this.versionList=res.value;
        }
      });
    }
  }

  openPop(popId,flag){
    if(flag=="createMLStorageAccount"){
      let findObj=this.storageAccountList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLSAObj=_.cloneDeep(findObj);
        this.filterStorageSkus('Standard','StorageV2');
        this.createMLSAObj.replication=findObj.replication;
      }else{
        this.createMLSAObj={};
        this.filterStorageSkus('Standard','StorageV2');
      }
    }
    if(flag=="createMLKeyVault"){
      let findObj=this.keyVaultList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLKeyObj=_.cloneDeep(findObj);
      }else{
        this.createMLKeyObj={};
      }
    }
    if(flag=="createMLApplicationInsights"){
      let findObj=this.applicationInsightsList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLAIObj=_.cloneDeep(findObj);
      }else{
        this.createMLAIObj={};
      }
    }
    if(flag=="createMLContainerRegistry"){
      let findObj=this.containerRegistryList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLCRObj=_.cloneDeep(findObj);
      }else{
        this.createMLCRObj={
          sku:'Standard'
        };
      }
    }
    if(flag=="createMLUserManagedIdentity"){
      let findObj=this.userManagedIdentityList.find(dt=>dt.createNew);
      if(findObj){
        this.createMLUMIObj=_.cloneDeep(findObj);
      }else{
        this.createMLUMIObj={};
      }
    }
    if(flag=="createSAAccount"){
      let findObj=this.storageAccountList.find(dt=>dt.createNew);
      if(findObj){
        this.createSAAccObj=_.cloneDeep(findObj);
      }else{
        this.createSAAccObj={};
      }
    }
    if(flag=="createSAFile"){
      let findObj=this.fileSystemNameList.find(dt=>dt.createNew);
      if(findObj){
        this.createSAFileObj=_.cloneDeep(findObj);
      }else{
        this.createSAFileObj={};
      }
    }
    popId.show();
  }

  updateSubSideSheet(type){
    switch (type) {
      case 'newDisk':{
        let tempList=_.cloneDeep(this.virtualMachinemodel.resourceData.newDiskList);
        this.virtualMachinemodel.resourceData.newDiskList=[];
        tempList[this.newDiskObj.updateIndex]=this.newDiskObj;
        tempList[this.newDiskObj.updateIndex].diskName=_.cloneDeep(this.newDiskObj.name);
        tempList[this.newDiskObj.updateIndex].updateIndex=undefined;
        this.virtualMachinemodel.resourceData.newDiskList=_.cloneDeep(tempList);
       // this.virtualMachinemodel.resourceData.newDiskList.push(this.newDiskObj);
        this.virtualMachinemodel.newDiskShow=false;
        return;
      }
      case 'SADedicatedPool':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool);
        this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=[];
        tempList[this.SADedicatedPoolObj.updateIndex]=this.SADedicatedPoolObj;
        tempList[this.SADedicatedPoolObj.updateIndex].updateIndex=undefined;
        this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=_.cloneDeep(tempList);
        this.synapseAnalyticsmodel.SADedicatedPoolShow=false;        
        return
      }
      case 'SAApacheSprakPool':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.apacheSparkPool);
        this.synapseAnalyticsmodel.resourceData.apacheSparkPool=[];
        tempList[this.SAApacheSparkPoolObj.updateIndex]=this.SAApacheSparkPoolObj;
        tempList[this.SAApacheSparkPoolObj.updateIndex].updateIndex=undefined;
        this.synapseAnalyticsmodel.resourceData.apacheSparkPool=_.cloneDeep(tempList);
        this.synapseAnalyticsmodel.SAApacheSprakPoolShow=false;        
        return
      }      
      case 'SAAzureADTenant':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.azureADTenants);
        this.synapseAnalyticsmodel.resourceData.azureADTenants=[];
        tempList[this.SAAzureADTenantObj.updateIndex]=this.SAAzureADTenantObj;
        tempList[this.SAAzureADTenantObj.updateIndex].updateIndex=undefined;
        this.synapseAnalyticsmodel.resourceData.azureADTenants=_.cloneDeep(tempList);
        this.synapseAnalyticsmodel.SAAzureADTenantShow=false;        
        return
      }
    }
  }

  saveSubSideSheet(type){
    switch(type){
      case 'publicIp':{
        let tempList=_.cloneDeep(this.vmPublicIPList);
        this.publicIPObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.publicIPObj;
          }else{
            tempList.push(this.publicIPObj);
          }
        }else{
          tempList.push(this.publicIPObj);
        }
        this.vmPublicIPList=[];
        this.vmPublicIPList=tempList;
        this.tempVmPublicIPList=[];
        this.changeAvailablity();
        this.virtualMachinemodel.resourceData.publicIp=_.cloneDeep(this.publicIPObj);
        this.virtualMachinemodel.publicIPShow=false;
        this.publicIPObj=resourceObject.publicIPObj;
        return;
      };
      case 'newDisk':{
        if(!this.virtualMachinemodel.resourceData.newDiskList){
          this.virtualMachinemodel.resourceData.newDiskList=[];
          this.newDiskObj.LUNNum=0;
        }else if(this.virtualMachinemodel.resourceData.newDiskList.length>0){
          let filterArr=_.cloneDeep(this.virtualMachinemodel.resourceData.newDiskList.filter(dt=>dt.LUNNum!==''&&dt.LUNNum!=null&&dt.LUNNum!=undefined));
          this.newDiskObj.LUNNum=filterArr.length?Number(Math.max(...filterArr.map(dt=>dt.LUNNum)))+1:0;
        }
        if(!this.newDiskObj.LUNNum){
          this.newDiskObj.LUNNum=0;
        }
        let tempList=_.cloneDeep(this.virtualMachinemodel.resourceData.newDiskList);
        this.virtualMachinemodel.resourceData.newDiskList=[];
        tempList.push(this.newDiskObj);
        tempList[tempList.length-1].diskName=_.cloneDeep(this.newDiskObj.name);
        this.virtualMachinemodel.resourceData.newDiskList=tempList;
       // this.virtualMachinemodel.resourceData.newDiskList.push(this.newDiskObj);
        this.virtualMachinemodel.newDiskShow=false;
        return;
      };
      case 'nsg':{
        if(!this.nsgList){
          this.nsgList=[];
        }
        //this.nsgList.push(this.vmNSGObj);
        let tempList=_.cloneDeep(this.nsgList);
        this.vmNSGObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.vmNSGObj;
          }else{
            tempList.push(this.vmNSGObj);
          }
        }else{
          tempList.push(this.vmNSGObj);
        }
        this.nsgList=[];
        this.nsgList=tempList;
        this.virtualMachinemodel.resourceData.networkSecurityGroup=_.cloneDeep(this.vmNSGObj);
        this.virtualMachinemodel.nsgShow=false;
        this.vmNSGObj={};
        return;
      }
      case 'lawObj':{
        if(!this.lawList){
          this.lawList=[];
        }
        //this.nsgList.push(this.vmNSGObj);
        let tempList=_.cloneDeep(this.lawList);
        this.lawObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.lawObj;
          }else{
            tempList.push(this.lawObj);
          }
        }else{
          tempList.push(this.lawObj);
        }
        this.lawList=[];
        this.lawList=tempList;
        this.applicationInsightmodel.resourceData.logaWorkspace=_.cloneDeep(this.lawObj);
        this.applicationInsightmodel.lawShow=false;
        //this.lawObj={};
        return;
      }
      case 'routesObj':{
        if(!this.routesList){
          this.routesList=[];
        }
        //this.nsgList.push(this.vmNSGObj);
        let tempList=_.cloneDeep(this.routesList);
        this.routesObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.routesObj;
          }else{
            tempList.push(this.routesObj);
          }
        }else{
          tempList.push(this.routesObj);
        }
        this.routesList=[];
        this.routesList=tempList;
        this.routeTablemodel.resourceData.routes=_.cloneDeep(this.routesObj);
        this.routeTablemodel.routesShow=false;
        //this.lawObj={};
        return;
      }
      case 'subnetsObj':{
        if(!this.subnetsList){
          this.subnetsList=[];
        }
        //this.nsgList.push(this.vmNSGObj);
        let tempList=_.cloneDeep(this.subnetsList);
        this.subnetsObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.subnetsObj;
          }else{
            tempList.push(this.subnetsObj);
          }
        }else{
          tempList.push(this.subnetsObj);
        }
        this.subnetsList=[];
        this.subnetsList=tempList;
        this.routeTablemodel.resourceData.subnets=_.cloneDeep(this.subnetsObj);
        this.routeTablemodel.subnetsShow=false;
        //this.lawObj={};
        return;
      }
      case 'vmASObj':{
        let tempList=_.cloneDeep(this.availabilitySetList);
        this.vmASObj.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.vmASObj;
          }else{
            tempList.push(this.vmASObj);
          }
        }else{
          tempList.push(this.vmASObj);
        }
        this.availabilitySetList=[];
        this.availabilitySetList=tempList;
        this.virtualMachinemodel.resourceData.availabilitySet=_.cloneDeep(this.vmASObj);
        this.virtualMachinemodel.availabilitySetShow=false;
        return;
      }
      case 'vmStorageAccount':{
        let tempList=_.cloneDeep(this.storageAccountList);
        this.vmStorageAccount.createNew=true;
        if(tempList.length>0){
          let index=tempList.findIndex(dt=>dt.createNew);
          if(index>-1){
          tempList[index]=this.vmStorageAccount;
          }else{
            tempList.push(this.vmStorageAccount);
          }
        }else{
          tempList.push(this.vmStorageAccount);
        }
        this.storageAccountList=[];
        this.storageAccountList=tempList;
        this.virtualMachinemodel.resourceData.diagnosticsStorageAccount=_.cloneDeep(this.vmStorageAccount);
        this.virtualMachinemodel.storageAccountShow=false;
        return;
      }
      case 'MLSelectKey':{
        this.machineLearningmodel.resourceData.encrypKeyVault=_.cloneDeep(this.keyForMLObj);
        this.machineLearningmodel.MLSelectKeyShow=false;
        this.keyForMLObj={};
        return;
      }
      case 'createMLStorageAccount':{
        let tempList=_.cloneDeep(this.storageAccountList);
        this.storageAccountList=[];
        this.createMLSAObj.createNew=true;
        let findObj=tempList.find(dt=>!dt.id);
        if(findObj){
          tempList[0]=_.cloneDeep(this.createMLSAObj);
        }else{
        tempList.splice(0,0,this.createMLSAObj);
        }
        this.storageAccountList=tempList;
        this.machineLearningmodel.resourceData.storageAccount=_.cloneDeep(this.createMLSAObj);
        return
      }
      case 'createMLKeyVault':{
        let tempList=_.cloneDeep(this.keyVaultList);
        this.keyVaultList=[];
        this.createMLKeyObj.createNew=true;
        let findObj=tempList.find(dt=>!dt.id);
        if(findObj){
          tempList[0]=_.cloneDeep(this.createMLKeyObj);
        }else{
        tempList.splice(0,0,this.createMLKeyObj);
        }
        this.keyVaultList=tempList;
        this.machineLearningmodel.resourceData.keyVault=_.cloneDeep(this.createMLKeyObj);
        return
      }
      case 'createMLApplicationInsights':{
        let tempList=_.cloneDeep(this.applicationInsightsList);
        this.applicationInsightsList=[];
        this.createMLAIObj.createNew=true;
        let findObj=tempList.find(dt=>!dt.id);
        if(findObj){
          tempList[0]=_.cloneDeep(this.createMLAIObj);
        }else{
        tempList.splice(0,0,this.createMLAIObj);
        }
        this.applicationInsightsList=tempList;
        this.machineLearningmodel.resourceData.applicationInsights=_.cloneDeep(this.createMLAIObj);
        return
      }
      case 'createMLContainerRegistry':{
        let tempList=_.cloneDeep(this.containerRegistryList);
        this.containerRegistryList=[];
        this.createMLCRObj.createNew=true;
        let findIndex=tempList.findIndex(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createMLCRObj);
        }else{
        tempList.push(this.createMLCRObj);
        }
        this.containerRegistryList=tempList;
        this.machineLearningmodel.resourceData.containerRegistry=_.cloneDeep(this.createMLCRObj);
        return
      }
      case 'createMLUserManagedIdentity':{
        let tempList=_.cloneDeep(this.userManagedIdentityList);
        this.userManagedIdentityList=[];
        this.createMLUMIObj.createNew=true;
        let findIndex=tempList.findIndex(dt=>dt.createNew);
        if(findIndex>-1){
          tempList[findIndex]=_.cloneDeep(this.createMLUMIObj);
        }else{
        tempList.push(this.createMLUMIObj);
        }
        this.userManagedIdentityList=tempList;
        this.machineLearningmodel.resourceData.userManagedIdentity=_.cloneDeep(this.createMLUMIObj);
        return
      }
      case 'createSAAccount':{
        let tempList=_.cloneDeep(this.storageAccountList);
        this.storageAccountList=[];
        this.createSAAccObj.createNew=true;
        let findObj=tempList.find(dt=>!dt.id);
        if(findObj){
          tempList[0]=_.cloneDeep(this.createSAAccObj);
        }else{
        tempList.splice(0,0,this.createSAAccObj);
        }
        this.storageAccountList=tempList;
        this.synapseAnalyticsmodel.resourceData.storageAccount=_.cloneDeep(this.createSAAccObj);
        return
      }
      case 'createSAFileObj':{
        let tempList=_.cloneDeep(this.fileSystemNameList);
        this.fileSystemNameList=[];
        this.createSAFileObj.createNew=true;
        let findObj=tempList.find(dt=>!dt.id);
        if(findObj){
          tempList[0]=_.cloneDeep(this.createSAFileObj);
        }else{
        tempList.splice(0,0,this.createSAFileObj);
        }
        this.fileSystemNameList=tempList;
        this.synapseAnalyticsmodel.resourceData.storageAccount=_.cloneDeep(this.createSAFileObj);
        return
      }
      case 'SADedicatedPool':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool);
        this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=[];
        tempList.push(this.SADedicatedPoolObj);
        this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool=tempList;
        this.synapseAnalyticsmodel.SADedicatedPoolShow=false;
        return
      }
      case 'SAApacheSprakPool':{
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.apacheSparkPool);
        this.synapseAnalyticsmodel.resourceData.apacheSparkPool=[];
        tempList.push(this.SAApacheSparkPoolObj);
        this.synapseAnalyticsmodel.resourceData.apacheSparkPool=tempList;
        this.synapseAnalyticsmodel.SAApacheSprakPoolShow=false;
        return
      }
      case 'SAAzureADTenant':{        
        let tempList=_.cloneDeep(this.synapseAnalyticsmodel.resourceData.azureADTenants);
        this.synapseAnalyticsmodel.resourceData.azureADTenants=[];
        tempList.push(this.SAAzureADTenantObj);
        this.synapseAnalyticsmodel.resourceData.azureADTenants=tempList;
        this.synapseAnalyticsmodel.SAAzureADTenantShow=false;
        return
      }
    }
  }

  disabledLoginAD(){
    if(this.virtualMachinemodel.resourceData.image.restricitLoginAzureAD){
      this.virtualMachinemodel.resourceData.loginWithAzureAD=false;
      return true;
    }else{
      return false
    }
  }

  disabledCheckBoxSA(flag){
    switch(flag){
      case 'largeFile':{
        if(this.storageAccountmodel.resourceData.kind=='PageBlobStorage'||this.storageAccountmodel.resourceData.kind=='BlockBlobStorage'){
          this.storageAccountmodel.resourceData.largeFileSharesState=false;
          this.storageAccountmodel.resourceData.isShareSoftDeleteEnabled=false;
          return true;
        }else {
          return false;
        }
      }
      case 'tracking':{
        if(this.storageAccountmodel.resourceData.kind=='PageBlobStorage'||this.storageAccountmodel.resourceData.kind=='FileStorage'){
          this.storageAccountmodel.resourceData.changeFeed=false;
          this.storageAccountmodel.resourceData.isVersioningEnabled=false;
          return true;
        }else {
          return false;
        }
      }
      case 'soft':{
        if(this.storageAccountmodel.resourceData.kind=='FileStorage'){
          this.storageAccountmodel.resourceData.isBlobSoftDeleteEnabled=false;
          this.storageAccountmodel.resourceData.isContainerSoftDeleteEnabled=false;
          return true;
        }else {
          return false;
        }
      }
      case 'point':{
        if(this.storageAccountmodel.resourceData.kind=='PageBlobStorage'||this.storageAccountmodel.resourceData.kind=='FileStorage'||this.storageAccountmodel.resourceData.kind=='BlockBlobStorage'){
          this.storageAccountmodel.resourceData.isContainerRestoreEnabled=false;
          return true;
        }else {
          return false;
        }
      }
      case 'largeFiles':{
        if(this.storageAccountmodel.resourceData.performance=='Standard'&&(this.storageAccountmodel.resourceData.redundancy.includes('GRS')||this.storageAccountmodel.resourceData.redundancy.includes('GZRS'))){
          return true;
        }else {
          return false;
        }
      }
      case 'trackings':{
        if(this.storageAccountmodel.resourceData.isContainerRestoreEnabled==true){
          this.storageAccountmodel.resourceData.changeFeed=true;
          this.storageAccountmodel.resourceData.isVersioningEnabled=true;
          return true;
        }else {
          this.storageAccountmodel.resourceData.changeFeed=false;
          this.storageAccountmodel.resourceData.isVersioningEnabled=false;
          return false;
        }
      }
    }
  }

  removeInputLB(flag){
    switch(flag){
      case 'routing':{
        if(this.storageAccountmodel.resourceData.kind=='PageBlobStorage'||this.storageAccountmodel.resourceData.kind=='BlockBlobStorage'){
          
          
          return true;
        }else {
          return false;
        }
      }
      case 'pipsku':{
        if(this.storageAccountmodel.resourceData.kind=='PageBlobStorage'||this.storageAccountmodel.resourceData.kind=='FileStorage'){
          

          return true;
        }else {
          return false;
        }
      }
      case 'ipassignment':{
        if(this.storageAccountmodel.resourceData.performance=='Standard'&&(this.storageAccountmodel.resourceData.redundancy=='GRS'||this.storageAccountmodel.resourceData.redundancy=='GZRS')){
          
          return true;
        }else {
          return false;
        }
      }
      case 'aZone':{
        if(this.storageAccountmodel.resourceData.isContainerRestoreEnabled==true){
          

          return true;
        }else {
          
          return false;
        }
      }
    }
  }


  checkDataDisabled(name){
    switch(name){
      case 'virtualMachine': return !this[this.templateName+'model'].resourceName || !this[this.templateName+'model'].resourceData.selectedVMSize || this.inputError.VmSpecialChar || this.inputError.VMname||this.inputError.duplicateVnet||this.inputError.VMOnlyNUmbers ||this.inputError.VMAdminUsername || this.inputError.VmAdminSpecialChar||this.inputError.VMAdminReserv||this.inputError.VMAdminPassword||this.inputError.VmAdminPasswordLen||this.inputError.VMAdminPasswordMatch
      ||(this.virtualMachinemodel.resourceData.availabilityOption=='availabilityZone'&&!this.virtualMachinemodel.resourceData.availabilityZone)||(this.virtualMachinemodel.resourceData.availabilityOption=='availabilitySet'&&!this.virtualMachinemodel.resourceData.availabilitySet)||(this.virtualMachinemodel.resourceData.diskEncryptionType!='1'&&!this.virtualMachinemodel.resourceData.diskEncryptionSet)||(this.virtualMachinemodel.resourceData.nicNSG=='Advanced'&&!this.virtualMachinemodel.resourceData.networkSecurityGroup)
      ||(this.virtualMachinemodel.resourceData.bootDiagnostics=='EWCSA'&&!this.virtualMachinemodel.resourceData.diagnosticsStorageAccount)
      ||!this.virtualMachinemodel.resourceData.adminUsername||(this.virtualMachinemodel.resourceType!='linuxVirtualMachine'||this.virtualMachinemodel.resourceData.authType=='password')&&!this.virtualMachinemodel.resourceData.adminPassword||(this.virtualMachinemodel.resourceType!='linuxVirtualMachine'||this.virtualMachinemodel.resourceData.authType=='password')&&!this.virtualMachinemodel.resourceData.adminConfirmPassword
      ||this.inputError.VMAdminReserv || this.inputError.VmAdminSpecialChar || this.inputError.VMAdminUsername||((this.virtualMachinemodel.resourceType!='linuxVirtualMachine'||this.virtualMachinemodel.resourceData.authType=='password')&&(this.inputError.VMAdminPassword || this.inputError.VmAdminPasswordLen||this.inputError.VMAdminPasswordMatch))
      ||this.virtualMachinemodel.resourceType=='linuxVirtualMachine'&&this.virtualMachinemodel.resourceData.authType=='ssh'&&((this.virtualMachinemodel.resourceData.sshPublicKey=='genNewKeyPair'&&(!this.virtualMachinemodel.resourceData.keyPairName||this.inputError.keyPairNameSpChar||this.inputError['keyPairNameDup']))||(this.virtualMachinemodel.resourceData.sshPublicKey=='useExistKeyAzure'&&!this.virtualMachinemodel.resourceData.storedKeys)||(this.virtualMachinemodel.resourceData.sshPublicKey=='useExistPublicKey'&&(!this.virtualMachinemodel.resourceData.publicKey||this.inputError.publicKeyValid||this.inputError['sshKeyBase64Valid'])))
      ||this.virtualMachinemodel.resourceData.autoShutdownStatus&&(!this.virtualMachinemodel.resourceData.autoShutdownTime||this.inputError['validTimeFormat']||(this.virtualMachinemodel.resourceData.autoShutdownNotificationStatus&&!this.virtualMachinemodel.resourceData.email));
      case 'virtualNetwork': return !this.VNetmodel.resourceName ||!this.VNetmodel.resourceData.resourceGroup || this.checkVnetAddress() || this.inputError.VNetChar || this.inputError.VNetName ||this.inputError.duplicateVnet;
      case 'subnet': return !this.subnetModel.resourceName || !this.subnetModel.resourceData.subnetAddressSpace || this.inputError.subnetAdrspc || this.inputError.subnetChar || this.inputError.subnetName || this.inputError.subnetDuplicate ||this.inputError.subnetAdrspc||this.inputError.subnetNotValidCIDR||this.inputError.subnetOverlaping||this.inputError.subnetPerfix||this.inputError.subnetAddresNotInVnet || this.inputError.dsServiceCheck;
      case 'storageAccount': return !this.storageAccountmodel.resourceName || this.inputError.storageAccountChar || this.inputError.storageAccountName || this.inputError.duplicateSa || this.inputError['blobSoftDeleteRetentionDays']||this.inputError['shareSoftDeleteRetentionDays']||this.inputError['restoredays'];
      case 'azureADDomain': return !this.azureADDomainModel.resourceName || this.inputError.azureADDomainName || this.inputError.azureADDomainDescription;
      case 'windowsVirtualDesktop': return !this.WVDmodel.resourceData.VmSize || !this.WVDmodel.resourceData.numberOfVMs || this.inputError.WVDdescription ||  this.inputError.WVDsize;
      case 'publicIp': return !this.publicIPObj.name;
      case 'newDisk': return !this.newDiskObj.name||!this.newDiskObj.sourceType||(this.newDiskObj.sourceType=='Snapshot'&&!this.newDiskObj.snapshot)||!this.newDiskObj.newVMDiskSize||!this.newDiskObj.encryptionType||(this.newDiskObj.encryptionType!='1'&&this.newDiskObj.diskEncryptionSet);
      case 'nsg': return !this.vmNSGObj.name;
      case 'networkInterface': return !this.networkInterfacemodel.resourceName||!this.networkInterfacemodel.resourceData.resourceGroup;
      case 'publicIPmodel': return !this.publicIPmodel.resourceName||!this.publicIPmodel.resourceData.resourceGroup;
      case 'logAnalytics': return !this.logAnalyticsmodel.resourceName||!this.logAnalyticsmodel.resourceData.resourceGroup || this.inputError.laSpecialChar || this.inputError.laname || this.inputError.VmSpecialChar || this.inputError.duplicateLaw;
      case 'applicationInsight': return !this.applicationInsightmodel.resourceName||!this.applicationInsightmodel.resourceData.resourceGroup || this.inputError.duplicateAi || this.inputError.VmSpecialChar || this.inputError.VMname || (this.applicationInsightmodel.resourceData.resMode=='workspaceBased' && !this.applicationInsightmodel.resourceData.logaWorkspace);
      case 'routeTable': return !this.routeTablemodel.resourceName||!this.routeTablemodel.resourceData.resourceGroup || this.inputError.VmSpecialChar || this.inputError.VMname; 
      case 'lawObj': return !this.lawObj.name|| this.inputError.laSpecialChar || this.inputError.laname || this.inputError.VmSpecialChar || this.inputError.duplicateLaw;
      case 'routesObj': return !this.routesObj.name|| this.inputError.laSpecialChar || this.inputError.laname || this.inputError.VmSpecialChar || this.inputError.duplicateLaw;
      case 'subnetsObj': return !this.subnetsObj.vnetName|| !this.subnetsObj.subnetName;
      case 'disk': return !this.diskmodel.resourceName||!this.diskmodel.resourceData.resourceGroup || this.inputError.duplicateDisk ||(this.diskmodel.resourceData.sourceType=='snapshot'&&!this.diskmodel.resourceData.snapshot)||(this.diskmodel.resourceData.encryptionType.name!='(default) Encryption at-rest with a platform-managed key'&&!this.diskmodel.resourceData.diskEncryptionSet);
      case 'nsgsr': return !this.nsgmodel.resourceName || !this.nsgmodel.resourceData.resourceGroup || this.inputError.nameLen || this.inputError.nameValid || this.inputError.nameDupCheck;
      case 'vmASObj': return !this.vmASObj.name||this.inputError.nameLen||this.inputError.nameValid||this.inputError.nameDupCheck||this.inputError.asUpdateDomainsLen||this.inputError.asUpdateDomainsMaxLen;
      case 'availabilitySet': return !this.availabilitySetmodel.resourceName || !this.availabilitySetmodel.resourceData.resourceGroup ||this.inputError.asUpdateDomainsLen || this.inputError.asUpdateDomainsMaxLen ||this.inputError['asFaultDomainsMaxLen'] || this.inputError['asFaultDomainsLen'];
      case 'loadBalancer': return !this.loadBalancermodel.resourceName||!this.loadBalancermodel.resourceData.resourceGroup || this.inputError.VMname || this.inputError.VmSpecialChar || !this.loadBalancermodel.resourceData.publicIP || !this.loadBalancermodel.resourceData.publicIPaddress;
      case 'localNetworkGateway': return !this.localNetworkGatewaymodel.resourceName||!this.localNetworkGatewaymodel.resourceData.resourceGroup || this.inputError.VMname || this.inputError.VmSpecialChar || (this.localNetworkGatewaymodel.resourceData.endpoint=='FQDN' && !this.localNetworkGatewaymodel.resourceData.fqdn) || (this.localNetworkGatewaymodel.resourceData.configureBGP && (!this.localNetworkGatewaymodel.resourceData.asn || !this.localNetworkGatewaymodel.resourceData.bgpPeeringAddress));
      case 'vmStorageAccount': return !this.vmStorageAccount.name||this.inputError.storageAccountName||this.inputError.storageAccountChar||this.inputError.duplicateSa;
      case 'keyVault': return !this.keyVaultmodel.resourceName;
      case 'connections': return !this.connectionsmodel.resourceName||!this.connectionsmodel.resourceData.resourceGroup || (this.connectionsmodel.resourceData.establishBidirectional && (!this.connectionsmodel.resourceData.connectionName || !this.connectionsmodel.resourceData.connectionReverseName||this.inputError['VNetNameFirstConn'] || this.inputError['VNetCharFirstConn'] || this.inputError['duplicateConnNameF']||this.inputError['VNetNameSecondConn'] || this.inputError['VNetCharSecondConn'] || this.inputError['duplicateConnNameSecondConn'])) || !this.connectionsmodel.resourceData.connectionType ||  (this.connectionsmodel.resourceData.connectionType=='IPsec' && (!this.connectionsmodel.resourceData.localNetworkGatewayName || !this.connectionsmodel.resourceData.localNetworkGatewayRG)) || !this.connectionsmodel.resourceData.virtualNetworkGatewayName || !this.connectionsmodel.resourceData.virtualNetworkGatewayRG || !this.connectionsmodel.resourceData.sharedKey ||  (this.connectionsmodel.resourceData.ipsecPolicies=='Custom' && (!this.connectionsmodel.resourceData.ikeEncryption || !this.connectionsmodel.resourceData.ikeIntegrity || !this.connectionsmodel.resourceData.dhGroup || !this.connectionsmodel.resourceData.ipsecEncryption || !this.connectionsmodel.resourceData.ipsecIntegrity || !this.connectionsmodel.resourceData.pfsGroup || !this.connectionsmodel.resourceData.saDataSizeKilobytes || !this.connectionsmodel.resourceData.saLifeTimeSeconds)) || this.inputError.VNetName || this.inputError.VNetChar || this.inputError.duplicateConnName || this.inputError.sharedKeyName || this.inputError.sharedKeyChar || this.inputError.saDataSizelimit || this.inputError.saLifeTimeLimit || this.inputError.dpdtimeout;
      case 'machineLearning': return !this.machineLearningmodel.resourceName||this.inputError.VMname||this.inputError.duplicateAi||(this.machineLearningmodel.resourceData.identityType=='userAssigned'&&!this.machineLearningmodel.resourceData.userManagedIdentity)||(this.machineLearningmodel.resourceData.encryptionType=='Customer'&&!this.machineLearningmodel.resourceData.encrypKeyVault);
      case 'synapseAnalytics': return !this.synapseAnalyticsmodel.resourceData.resourceGroup||!this.synapseAnalyticsmodel.resourceName||this.inputError['workspaceMaxLen']||this.inputError['workspaceNameCheck']||this.inputError['workspaceStartCheck']
      ||this.inputError['workspaceEndCheck']||this.inputError['workspacePartText']||this.inputError['workspaceNameUnqiue']||(this.synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='subscriptions'&&(!this.synapseAnalyticsmodel.resourceData.storageAccount
      ||this.synapseAnalyticsmodel.resourceData.fileSystemName))||(this.synapseAnalyticsmodel.resourceData.dataLakeStorageGen=='manually'&&(!this.synapseAnalyticsmodel.resourceData.fileSystemNameVal||this.inputError['fileNameCheck']||this.inputError['fileNameLen']
      ||this.inputError['fileNameEndCheck']||this.inputError['fileNameStartCheck']||this.inputError['fileNameHypensCheck']||!this.synapseAnalyticsmodel.resourceData.accountNameVal||!this.inputError?.accountNameCheck||this.inputError['accountNameValid']))
      ||!this.synapseAnalyticsmodel.resourceData.assignUser||!this.synapseAnalyticsmodel.resourceData.assignUser.length||!this.synapseAnalyticsmodel.resourceData.assignApplication||!this.synapseAnalyticsmodel.resourceData.assignApplication.length
      ||!this.synapseAnalyticsmodel.resourceData.adminUsername||this.inputError['adminStartValid']||this.inputError['adminLenValid']||this.inputError['adminValidChar']||this.inputError['adminReservValid']||!this.synapseAnalyticsmodel.resourceData.dedicatedSQLPool.length
      ||!this.synapseAnalyticsmodel.resourceData.apacheSparkPool.length||(this.synapseAnalyticsmodel.resourceData.doubleEncryp=='enable'&&this.synapseAnalyticsmodel.resourceData.encryptionKey=='identifier'&&(!this.synapseAnalyticsmodel.resourceData.keyIdentifier));
      case 'SAApacheSprakPool': return !this.SAApacheSparkPoolObj.name||this.inputError['apacheNameValid']||this.inputError['apacheNameStart']||this.inputError['apacheNameEnd']||this.inputError['apacheNameUnique']||this.inputError['apacheNameReserved']||this.inputError['apacheNameLen']||!this.SAApacheSparkPoolObj.numberMinutes||this.inputError['numberMinutesValid']||this.inputError['numberMinutesLen']||this.inputError['numberMinutesIdle']||!this.SAApacheSparkPoolObj.numberNodes||!this.SAApacheSparkPoolObj.maxNumberNodes
    }
  }
}
