 <div class="a3s-overview">
    <p class="heading" id="0">
            Overview
    </p>
             <p class="para">AI-powered Azure Automation as-a-Service or A3S is envisioned to be a comprehensive Azure 
                 cloud management SaaS platform focused on taking the grunt work out of customers Azure 
                 usage, thus enabling them to be more productive with Azure to build their business. </p>
            <p>The 4 key pillars of A3S are: </p>
                 
            <ul> 
            <li><b>Automate :</b> reduce manual effort to the minimum, keep automating all that is possible 
                across all aspects of Azure.  </li>
            <li><b>Simplify :</b> demystify Azure with simple and easy monitoring, management, optimization & 
                reporting.   </li>
            <li><b>Learn :</b> leverage AI to learn from customers Azure usage, identify patterns and insights to 
                make the platform smarter, ever evolving & learning.    </li>
            <li><b>Support :</b> 24x7 availability of highly trained engineers to support customers using A3S to 
                provide a truly delightful Managed Service experience. </li>
            </ul>
            <p>Functionality provided by the product are categorized into four functional buckets: </p>
            <ol>

                <li>Digital Transformation. </li>
                
               <li> Monitoring and Remediation. </li>
                
               <li>Governance, Risk, and Compliance (GRC). </li> 

               <li>Dedicated Support. </li>

            </ol>
</div>





