import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuzreSubscription } from 'src/app/@model/model';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-create-new-blueprint-project',
  templateUrl: './create-new-blueprint-project.component.html',
  styleUrls: ['./create-new-blueprint-project.component.scss']
})
export class CreateNewBlueprintProjectComponent implements OnInit {

  //subscriptions: any;
  subscriptions: any;
  // resourceGroups: any = []
  // countryList: any = []
  // rgLocation: any
  // disableLocationsDropdown: boolean = false
  // resourcesGroupLocationList: any = []
  loaded: boolean
  show: boolean = false
  result: any;

  formProject:FormGroup;
  rgSubscriptionCheck: boolean = true;
  newResourceGroup = {
    subscriptionId: null,
    resourceGroupName: null,
    resourceGroupLocation : null,
  }

  inputError={
    projectName: false,
    specialChar: false,
    projectNameExist:false,
    whiteSpace:false,
  }
  disableSaveBtn:boolean=false;
  departmentList: any = [];

  constructor(public util: UtilService, private fb:FormBuilder) 
    {
      // this.subscriptions = this.util.getCache('subscriptionList');
      // console.log( 'subscription list = ',this.subscriptions);
    }

  ngOnInit(): void {
    // this.subscriptions = await this.getSubscriptionsList();
    //this.subscriptions = this.util.getCache('subscriptionList');
    this.getSubscriptionsList();
    this.getAllDepartments();
    // this.resourcesGroupLocationList = this.util.getStatic('countryList');
    // this.countryList = this.resourcesGroupLocationList;
    // this.autofocusFlag=true;
    // console.log('hello hello');
    // console.log( 'subscription list = ',this.subscriptions);
    //setTimeout(this.getSubscriptionsList, 1000);
    this.formProject=this.fb.group({
      projectId: [null],
      projectName: [null,[Validators.required]],
      subscription: [null,[Validators.required]],
      subscriptionId:[null],
      subscriptionName:[null],
      departmentName:[null],
      createdBy:[null],
    })
    
  }

  // getLocationOfRG(){
  //   this.disableLocationsDropdown = true
  //   var rg
  //   for(rg of this.resourceGroups){
  //     if(rg.name == this.model.resourceGroup)
  //       this.model.resourceGroupLocation = rg.location
  //   }
  // }

 async getSubscriptionsList(){
    // this.subscriptions = this.util.getCache('subscriptionList');
    // console.log( 'subscription list = ',this.subscriptions);
    //return this.util.getCache('subscriptionList');
    let keys=Object.keys(sessionStorage);
    let userObjectId;
    for(let i=0;i<keys.length;i++){
      try{
        if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
          userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
          break;
        }
      }catch(e){
        console.log(e);
      }
    }
    let reqObj={  
      token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
      userObjectId:userObjectId
    }
    // a3s_architectureBlueprint_getSubscriptionListWithRole
    await this.util.handleRequest('get','a3s_subscription_getOnboardedA3s',[],reqObj,null,null,true).then(res=>{
          console.log('Subscription list ',res);
          this.subscriptions = res? res.recordset : []
          //this.loaded =  true;
      },err=>{
          //this.loaded =  true;
      })
    }

  getAllDepartments(){
    return this.util.handleRequest('get','a3s_static_getByTable',['dbo','departments'],null,null,null,true).then(res=>{
      if(!res || !res.recordset.length) return; 
      this.departmentList = res.recordset
    })
  }

  public onToggle(): void {
    this.show = !this.show;
  }

  // async getRgBySubscription(){
  //   if(!this.model.subscription) return;
  //   let subsid = this.subscriptions.filter(e=> e.displayName == this.model.subscription)[0].subscriptionId
  //   // let body={
  //   //     subscriptionId : subsid,
  //   // }
  //   this.loaded = false;
  //   // this._dataMigrationService.getRgBySubscription(body).subscribe(res=>{
  //   //     console.log('Resource Group list ',res);
  //   //     this.resourceGroups = res && res.value ? res.value : []
  //   //     this.loaded =  true;
  //   // },err=>{
  //   //     this.loaded =  true;
  //   // })
  //   this.model.resourceGroup = null;
  //   this.model.resourceGroupLocation = null;
  //   this.rgSubscriptionCheck = false;
  //   let keys=Object.keys(sessionStorage);
  //   let userObjectId;
  //   for(let i=0;i<keys.length;i++){
  //     try{
  //       if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
  //         userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
  //         break;
  //       }
  //     }catch(e){
  //       console.log(e);
  //     }
  //   }
  //   let reqObj={  
  //     subscriptionId : subsid,
  //     token:`${JSON.parse(sessionStorage.getItem('data'))['token']}`,
  //     userObjectId:userObjectId
  //   }
  //   await this.util.handleRequest('post','a3s_architectureBlueprint_getResourceGroupsWithRoleByID',[],reqObj,null,null,true).then(res=>{
  //         console.log('Resource Group list ',res);
  //       this.resourceGroups = res? res : []
  //       this.loaded =  true;
  //   },err=>{
  //       this.loaded =  true;
  //   })
    

  //   return;
  //   //  this.util.handleRequest('post','a3s_resourceGroup_getRgBySubscription',[],body).then(res=>{
  //   //     this.ResourceGroups = res || []
  //   // })
  // }

  // async createNewRgHelper(){
  //   await this.createNewResourceGroup();
  //   this.onToggle();
  //   //this.resourceGroups.push(this.newResourceGroup.resourceGroupName);
  //   await this.getRgBySubscription();    
  //   this.model.resourceGroup = this.newResourceGroup.resourceGroupName;
  //   this.model.resourceGroupLocation = this.newResourceGroup.resourceGroupLocation;
  //   this.disableLocationsDropdown = true;
    
  // }

  // async createNewResourceGroup(){
  //   let subsid = this.subscriptions.filter(e=> e.displayName == this.model.subscription)[0].subscriptionId
  //   this.newResourceGroup.subscriptionId=subsid;
  //   console.log(this.newResourceGroup);
  //   let result  = _.cloneDeep(this.newResourceGroup)
  //   this.util.handleRequest('post','a3s_serviceDeployment_createRG',[],result,null,null,true).then(res=>{
  //     return res;
  //   })
    
    
  //   // this._architectureBlueprintService.createResourceGroup(this.newResourceGroup).subscribe(res=>{
  //   //   return res;
  //   // });
  // }

  async saveProject(){
    // let model=this.formProject.value;
    let subscriptionName = this.subscriptions.filter(e=> e.subscriptionId == this.formProject.value.subscription)[0].subscriptionName
    // model['subscriptionId'] = this.formProject.value.subscription;
    // model['subscriptionName']= subscriptionName;
    // model['subscription']= subscriptionName;
    var userName = JSON.parse(localStorage.getItem('UserInfo'))['userName']
    this.formProject.setValue({
      ...this.formProject.value,
      subscriptionId:this.formProject.value.subscription,
      subscriptionName:subscriptionName,
      subscription:subscriptionName,
      departmentName:this.formProject.value.departmentName,
      createdBy:userName
    })
    let result  = _.cloneDeep(this.formProject.value)
    console.log('result',result);
    await this.util.handleRequest('post','a3s_architectureBlueprint_addproject',[],result,null,null,false).then(res=>{
      console.log('Project created successfully');
      if(!res) return;
      this.formProject.setValue({
        ...this.formProject.value,
        projectId:res.projectId,
      })
      this.util.setCache('currentProject',this.formProject.value.projectName);
    })
  }

  async routeToDiagramTool(){
    this.disableSaveBtn=true;
    await this.saveProject();
    this.disableSaveBtn=false;
    this.util.route(['/a3s/diagramTool'],true,{data : JSON.stringify(this.formProject.value)});
  }
  
  checkDisable(value){
    switch (value){
      case 'main-page': return (this.inputError.projectName||this.inputError.specialChar||this.inputError.projectNameExist||this.inputError.whiteSpace) || !this.formProject.valid;
      // case 'rg-popup': return !this.newResourceGroup.resourceGroupName || !this.newResourceGroup.resourceGroupLocation
    }
    
  }

  async getProjectByName(value){
   await this.util.handleRequest('get','a3s_architectureBlueprint_getBlueprintProjectByName',[value],null,null,null,true).then(res=>{
    if(res.projectId){
      this.inputError.projectNameExist=true;
    }else{
      this.inputError.projectNameExist=false;
    }
     return res;
   });
  }

  limitText(limitField, limitNum) {
    let value =limitField.target.value;
    this.inputError.specialChar = false;
    this.inputError.projectName = false;
    this.inputError.projectNameExist = false;
    this.inputError.whiteSpace = false;
    if(value[0]==" "){
      this.inputError.whiteSpace = true;
    } else if(value&&value.length>1){
      if(value[value.length-1]==" "){
        this.inputError.whiteSpace = true;
      }
    }
    if (value) {
      this.inputError.specialChar = !(/^[a-zA-Z0-9 ]+$/.test( value));
    }

    if (value.length > limitNum) {
        this.inputError.projectName = true;
        return;
    }
    else this.inputError.projectName = false;

    if(value&&!this.inputError.projectName&&!this.inputError.specialChar&&!this.inputError.whiteSpace){
      this.getProjectByName(value);      
    }
    
  }

}
