import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { delay, retry } from 'rxjs/operators';
import { FlowService } from '../../services/flow.service';
import { userManualTopics } from '../user-manual/userManualTopics';
import { LoginService } from 'src/app/services/login.service';
import { PublicClientApplication, SilentRequest, InteractionRequiredAuthError, RedirectRequest } from "@azure/msal-browser";
import { LoginMsalService } from "../../services/login-msal.service";
import { Subscription } from 'rxjs/internal/Subscription';
import { ProgressNotificationMessage } from "../../@model/model";
import { UtilService } from 'src/app/services/util.service';
import { DataMigrationService } from 'src/app/services/data-migration.service';
import { NotificationService } from 'src/app/services/notification.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    /** variables will be initialized using utilService! check ngOnInit */
    dashboardRoutes: any[] = [];
    team: any[] = [];
    slack: any = [];

    /** other variable  */
    mode = new FormControl('over');
    public myMSALOBJ: PublicClientApplication;
    setting = false;
    show = false;
    help = false;
    LinkedURLs;

    custom = false;
    customHeading = '';
    profile = false;
    user = '';
    userEmail: string;
    OU;
    urlData = '';
    userName = '';

    message;
    arrivednotification: boolean = false;
    notification: boolean = false;
    IsEmptyNotification: boolean = false;
    notificationDocs = [];
    NotificationMessage: ProgressNotificationMessage[] = [];
    NotificationList: any=[];
    userEmailProfile : string;
    a3sUserDetails: any;


    // Initial Migrate
    globalAdmin: boolean = false;
    loader = true;
    isApplicationLoaded: boolean = false;
    subscription: Subscription;
    isLoading = true;


    searchText:any;
    searchBuckets:any=[];
    manualContent = userManualTopics.content ;
    manualResult:any=[];
    azureDocument:any=[];
    azureResult:any=[];
   

    @HostListener('document:click', ['$event'])
    DocumentClick(event: Event) {
        var $target = $(event.target);
        this.dashboardRoutes.forEach(e=>{
            e.contentList.forEach(x=> {
                let element = document.getElementById(x.route)
                if(!$target.closest(element).length && !$target.closest("#no-access-tooltip").length){
                   x.restrictAccess = false
                }
            })
        })
    }
    
    constructor(
        private router: Router,
        private util:UtilService,
        private flowservice: FlowService,
        private _notificationService: NotificationService,
        private utilService: UtilService,
        private _dataMigrationService: DataMigrationService,
        private loginmsal: LoginMsalService,
        private loginService: LoginService,
    ) {
        this.receive_and_watch_for_progress_notifications();
    }

    /**
     * this functions watches the route change and starts updating the notification! 
     * ! this function is not used! 
     * @param data current route of the applcaition
     */
    urlChange(data: string) {
        if (!data.includes("dataMigration")) {
            // console.log("condition worked!");
            this.NotificationMessage.forEach(async (notification) => {
                if (notification.is_progress && !notification.watching_progress) {
                    notification.watching_progress = true;
                    await this.getJobStatus(notification._id).subscribe(res => {
                        this.setStatusProgress(res.response, notification._id);
                        this.startProgress(notification._id, notification.progress.jobs_complete);
                    }, err => this.stop_watching_for_notification_changes(err));
                    this.getSyncJobStatus(notification._id);
                }
            });
        } else {
            this.stop_watching_for_notification_changes();
        }
    }

    subscriptionId: string;

    search(event){
         let searchValue = event.target.value.toLowerCase()
         if(searchValue) $(".search-expand").addClass('open')
         else $(".search-expand").removeClass('open')
         let array = this.searchBuckets.filter(e=> e.searchProject.toLowerCase().includes(searchValue) ? true : false)
         this.result=array 
         let array1 = this.manualContent.filter(e=> e.value.toLowerCase().includes(searchValue) ? true : false )
         this.manualResult=array1 
     }

    route(list){
        $(".search-expand").removeClass('open')
        this.searchText='';
        this.util.route([list?.routingPath],true,{ data: JSON.stringify(list)})
    }  

    changeTab(list){
        $(".search-expand").removeClass('open')
        this.searchText='';
        localStorage.setItem('homeSearchManual',JSON.stringify(list))
        window.open('/UserManual', '_blank');
    }  

    setValue(){
        localStorage.setItem('homeSearchManual','Null')
    }

    getRoleClass(value){
        if(value) {
          let status = value.toLowerCase()
          switch (status) {
            case 'admin': return 'admin';
            case 'user': return 'user';
            default: return 'no-access';
          }
        }
        else return 'no-access';
    }

    /**
     * authentication functions that controls the routing and navigation based upon role and permissions a user have!
     * @param route route to which a user wish to migrate to
     * @param d related payload 
     */
    changeRoute(list, d = null) {
        let data = localStorage.getItem('a3sUserInfo') ? JSON.parse(localStorage.getItem('a3sUserInfo')) : null;
        if(data && data.scope.some(x=> x.bucketId == list.bucketId)) list['restrictAccess'] = false
        else return list['restrictAccess'] = true
        if (d) d.close();
        this.router.navigate([list.route]);
    }

    private silentProfileRequest: SilentRequest;
    private profileRedirectRequest: RedirectRequest;

    private async getTokenRedirect(silentRequest: SilentRequest, interactiveRequest: RedirectRequest): Promise<string> {
        // console.log('hel1')
        try {
            const response = await this.flowservice.msalobj.acquireTokenSilent(silentRequest);
            // console.log(response)
            return response.accessToken;
        } catch (e) {
            // console.log("silent token acquisition fails.");
            if (e instanceof InteractionRequiredAuthError) {
                // console.log("acquiring token using redirect");
                this.flowservice.msalobj.acquireTokenRedirect(interactiveRequest).catch(console.error);
            } else {
                console.error(e);
            }
        }
    }

    token() {
        console.log(this.flowservice.myacc,
            this.flowservice.silentreq,
            this.flowservice.preq);
        // this.silentProfileRequest.account = this.flowservice.myacc;
        // console.log(this.getTokenRedirect(this.flowservice.silentreq, this.flowservice.preq));
    }

    logoutLoader = false;
    logout() {
        this.logoutLoader = true;
        this.loginmsal.logout();
    }

    generateToken() {
        // console.log("token call");
        this.loginmsal.getnewToken();
    }

    loginUser = ''
    usernameInitials = ''
    endNotification = 5;

    /** list of subscriptions that are used in the navbar, 
        these susbcription will be unsusbcribed when user migrates 
        away or state of component is killed! */
    simple_notification_subscription: Subscription;
    progress_notification_subscription: Subscription;

    async ngOnInit() {
        console.log('Navbar started.....')
        // setting the static variables that are used in the component!!
        this.dashboardRoutes = _.cloneDeep(this.utilService.getStatic("dashboard_home_scree_routes"));
       
        // Initial load to get all the required data
        let xinterval = setInterval(() => {
            if (this.initialFun()) {
                clearInterval(xinterval)
            }
        }, 300)
        
        await this.getOrgDetails();
        await this.getA3sUserDetails();
        await this.getAllSubscription()
        await this.getAllSubscriptionFromAzure()

       
        this.userEmailProfile = localStorage.getItem('email')
        this.loginUser = this.get_user_details();
        this.usernameInitials = this.getInitials(this.loginUser);
        var get_Email = JSON.parse(localStorage.getItem('UserInfo'));
        this.userEmail = get_Email ? get_Email.email : '';
        this.userName = get_Email ? get_Email.userName : ''
        var matches = this.userName.match(/\b(\w)/g);
        this.user =matches ?  matches.join('') : '';

        this.myMSALOBJ = this.flowservice.msalobj;
        this.notification = true;
     

        // Get all the notification list from backend
        await this.getAllNotificationsFromBackend();
        this.subscription = this.util.getObservable().subscribe((res) => {
            if(res.refreshNotification){
                this.getAllNotificationsFromBackend();
            }
        })

        setInterval(() => { this.generateToken() }, 1000 * 60 * 60);
        // this.fetch_notification_from_db();

        // this.receive_and_watch_for_simple_notifications();
    }

    getAllNotificationsFromBackend(){
        return this.util.handleRequest('get','a3s_notifications_getAllNotifications',[this.userName?this.userName:this.loginUser],null,null,null,true).then(res=>{
            if(!res) return;
            this.NotificationList = res || []
            this.util.groupedLog(this.NotificationList,'Notifications list');
        })  
    }

    changeViewedStatusNotif(item){
        if(item.processType == 'frAnalytics'||item.processType == 'MFAnalytics') return;
        return this.util.handleRequest('put','a3s_notifications_updateNotificationViewedStatus',[item.notificationId],null,null,null,true).then(res=>{
            if(!res) return;
            console.log('res',res);
            this.getAllNotificationsFromBackend();
        }) 
    }

    ngAfterViewInit() {
        // console.log('Home', LoginComponent, this.logc);
    }

    get_user_details() {
        let user_details;
        for (let i = 0; sessionStorage.key(i); i++) {
            if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                user_details = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['name']
            }
        }
        return user_details;
    }

    getInitials(name) {
        var parts = name.split(' ')
        var initials = ''
        for (var i = 0; i < parts.length; i++) {
          if (parts[i].length > 0 && parts[i] !== '') {
            initials += parts[i][0]
          }
        }
        if(initials.length > 2)
            initials = initials.charAt(0) + initials.charAt(initials.length-1);
        return initials.toUpperCase()
    }

    ngOnDestroy() {
        if (this.simple_notification_subscription)
            this.simple_notification_subscription.unsubscribe();
        if (this.progress_notification_subscription)
            this.progress_notification_subscription.unsubscribe();
    }

    /**backend api call! for get admin*/
    getAdmin() {
        var payload = { "Operation": "GetAdmin" };
        this.flowservice.getAdmin(payload).subscribe(result => {
            result.recordset.forEach(element => {
                this.flowservice.AdminEmail = element.globalAdmin
                if (element.globalAdmin == this.userEmail) {
                    this.OU = element.ou
                }
            });
        })
    }

    /**function is used to clear the notification box when user have seen the notification! */
    notificationread() {
        this.arrivednotification = false;
    }

    // function to get notifications of previous jobs from backend!!
    fetch_notification_from_db() {
        const NotificationPayload = { // payload for getting notifications
            "Operation": "GetNotificationLog",
            "Email": this.userEmail,
            "Date": (new Date()).toISOString().slice(0, 10)
        }
        this.notification = false;
        this._notificationService.getLogData(NotificationPayload).subscribe(result => {
            if (result.recordset.length > 0) {
                this.notification = true;
                this.IsEmptyNotification = false;
                (result.recordset).forEach(element => {
                    // process notification based on the type of notification!!
                    var _message = { "Message": element.Activity, "MessageDate": element.Date, "MessageTime": element.Time };
                    this.NotificationMessage.push(_message);
                });
            }
        })
    }

    /** navigation functions for notification! */
    notification_navigation(notificaiton) {
        if (notificaiton.isNavigation)
        {
            if(notificaiton.processType=='diagramToolReviewComplete' || notificaiton.processType=='commentNotification' || notificaiton.processType=='bpDeploymentSuccess' || notificaiton.processType=='bpDeploymentFailed' || notificaiton.processType=='bpDeploymentStarted' || notificaiton.processType=='frAnalytics'|| notificaiton.processType=='frAnalyticsSuccess'|| notificaiton.processType=='frAnalyticsFailed' || notificaiton.processType=='MFAnalytics'|| notificaiton.processType=='MFAnalyticsSuccess'|| notificaiton.processType=='MFAnalyticsFailed' )
            {
                if(!notificaiton.data || notificaiton.data == "undefined") return;
                this.router.navigate(['/' + notificaiton.navigateTo], { queryParams: { data: notificaiton.data } });
            }
            else{
                this.router.navigate(['/' + notificaiton.navigate_to], { queryParams: { notification_id: notificaiton._id, process_type: notificaiton.process_type } });
            }
        }
        this.changeViewedStatusNotif(notificaiton)
    }

    updateViewAll(){
        return this.util.handleRequest('put','a3s_notifications_updateNotificationAllViewed').then(res=>{
            this.getAllNotificationsFromBackend();
        })
    }

    showBadge(){
        return this.arrivednotification || (this.NotificationList.length && this.NotificationList.some(e => !e.isViewed)) ?  true : false;
    }

    changeTimeFormat(createdTime){
        return this.util.changeTimeFormat(createdTime)
    }


    // receive_and_watch_for_simple_notifications() { // never called!!
    //     this.simple_notification_subscription = this.flowservice.SourceNotification$.pipe(retry(3), delay(1000))
    //         .subscribe(message => {
    //             let _notification: ProgressNotificationMessage = { Message: message["Message"] };
    //             this.notification_processing_helper(_notification);
    //         }, err => console.log(err))
    // }

    /** 
    - notification corresponding to the running jobs or processes!!
    - example: Data migration etc.*/
    receive_and_watch_for_progress_notifications() {
        this.progress_notification_subscription = this._notificationService
            .SourceProgrerssNotifications$.pipe(retry(3), delay(1000))
            .subscribe((message: ProgressNotificationMessage) =>
                this.notification_processing_helper(message), err => console.log(err));
    }

    /**
     * @param notification
        1. push notification if its simple notification
    */
    async notification_processing_helper(notification: ProgressNotificationMessage) {
        this.notification = true
        this.arrivednotification = true;
        var todayDate = new Date().toISOString().slice(0, 10);
        var d = new Date();
        var currTime = d.toTimeString().slice(0, 8);
        notification["MessageDate"] = todayDate;
        notification["MessageTime"] = currTime;
        this.message = notification

        // adding the notification to the navbar notification Array!!
        let _index = this.NotificationMessage.findIndex((obj => obj._id == notification._id));
        if (_index >= 0)
            this.NotificationMessage[_index] = notification;
        else this.NotificationMessage.push(notification);

        let temp = this._notificationService.get_all_live_notifications();
        if (this.NotificationMessage.length != temp.length) {
            this.NotificationMessage = temp;
            // console.log("OOPS!! how is this even possible!!");
        }
        // console.log('All notification',this.NotificationMessage)
    }

    getJobStatus(id) {
        return this._dataMigrationService.getJobStatus(id);
    }

    private interval;
    getSyncJobStatus(id) {
        this.interval = setInterval(async () => {
            await this.getJobStatus(id).subscribe(res => {
                this.setStatusProgress(res.response, id);
            }, err => this.stop_watching_for_notification_changes(err));
        }, 30000);
    }

    /**
     * FUNCTION UPDATES THE STATUS OF RUNNING PROCESS...
     * @param notification - Notification to be updated
     */
    update_progress_in_notificaiton(id, progress: number) {
        let _index = this.NotificationMessage.findIndex(obj => obj._id == id);
        if (_index >= 0) {
            this.NotificationMessage[_index].progress.jobs_complete = progress;
            this._notificationService.send_progress_notification(this.NotificationMessage[_index]);
        } else {
            let noti: ProgressNotificationMessage = this._notificationService.get_notification_if_exist(id);
            if (noti) {
                noti['progress']['jobs_complete'] = progress;
                this._notificationService.send_progress_notification(noti);
                this.NotificationMessage.push(noti);
            }
        }
    }

    private progressInterval;
    startProgress(id, progress: number) {
        this.progressInterval = setInterval(async () => {
            progress++;
            this.update_progress_in_notificaiton(id, progress);
        }, 10000);
    }

    setStatusProgress(res, id) {
        switch (res.state) {
            case 'running': {
                this.update_progress_in_notificaiton(id, 50);
            }
            case 'success': {
                this.update_progress_in_notificaiton(id, 100);
                this.stop_watching_for_notification_changes();
            }
            case 'error':
            case 'dead': {
                let _index = this.NotificationMessage.findIndex(obj => obj._id == id);
                if (_index >= 0) {
                    let _noti = this.NotificationMessage[_index];
                    _noti.is_error_occurred = true;
                    _noti.error_message = "Something went wrong! please try again...";
                    this.NotificationMessage[_index] = _noti;
                    this._notificationService.send_progress_notification(_noti);
                }
                this.stop_watching_for_notification_changes();
            }
        }
    }

    stop_watching_for_notification_changes(err?: any) {
        if (err) console.error(err);
        // notification should be created here!! for error!!
        this.NotificationMessage.map(notification => {
            notification.watching_progress = false;
            this._notificationService.send_progress_notification(notification);
        });
        if (this.interval && this.progressInterval) {
            clearInterval(this.interval);
            clearInterval(this.progressInterval);
        }
    }


    // ********************************************* Initial function ***************************************************//

    async initialFun() { 
        for (let i = 0; sessionStorage.key(i); i++) {
            // console.log(sessionStorage.key(i).indexOf('login.windows.net-accesstoken'), sessionStorage.key(i))
            if (sessionStorage.key(i).indexOf('login.windows.net-accesstoken') != -1) {
                await this.getRGData();
                // setTimeout(() => {
                //      this.getAllBlueprintProjects()
                //      this.getDeployedBlueprintProjects()
                // }, 5000);
                return true;
            }
        }
    }

    async getDeployedBlueprintProjects(){
        var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
        var createdBy = get_Email.userName;
        let keys=Object.keys(sessionStorage);
        let userObjectId;
        for(let i=0;i<keys.length;i++){
          try{
            if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
              userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
              break;
            }
          }catch(e){}
        }
        let userProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getAllProjects',[createdBy,userObjectId],null,null,null,true)
        let orgProject = await this.util.handleRequest('get','a3s_architectureBlueprint_getOrgProjects',[localStorage.getItem('id')],null,null,null,true);
        let providerList = await this.util.handleRequest('get','a3s_applicationHealthMonitor_getProviderList',[],null,null,null,true);
        if(providerList) {
            let data=providerList.body.value||[];
            data=data.filter(dt=>dt.properties&&(dt.properties.providerSettings.providerType=='SapNetWeaver'||dt.properties.providerSettings.providerType=='SapHana')&&dt.properties.provisioningState=='Succeeded');
            this.utilService.setCache('masterProviderList',data)
        }
        let list = [...userProject,...orgProject]
        this.utilService.setCache('deployedBlueprintProjectList',list)
    }

    async getAllBlueprintProjects(){
        var get_Email = JSON.parse(localStorage.getItem('UserInfo'))
        var createdBy = get_Email.userName;
        let keys=Object.keys(sessionStorage);
        let userObjectId;
        for(let i=0;i<keys.length;i++){
          try{
            if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
              userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
              break;
            }
          }catch(e){}
        }
        let list = await this.util.handleRequest('get','a3s_architectureBlueprint_getAllProjects',[createdBy,userObjectId],null,null,null,true)
        list.forEach(e => { e.searchProject=e.projectName
            e.projectTitle= 'Infrastructure'  
        if(list.status == 'PENDING' || list.status == 'UNDER REVIEW' || list.status == 'APPROVED')  
            return e.routingPath= 'a3s/diagramTool'  
        else
            return e.routingPath= 'a3s/infraDeploymentBucket/architectureBlueprint/architectureBlueprintList/architectureBlueprintSummary'  
        });
        let list1 = await this.util.handleRequest('get','a3s_dataMigration_allProject',[],null,null,null,true)
        list1.forEach(e => { e.searchProject=e.Project_Name
            e.projectTitle= 'Data Migration'  
            e.routingPath='a3s/dataMigrationBucket/dataMigration/dataMigrationList/dmSummary'  
            
        });
        let list2 = await this.util.handleRequest('get','a3s_dataGovernance_allProjects',[],null,null,null,true)
        list2.forEach(e => { e.searchProject=e.Project_Name
            e.projectTitle= 'Data Governance'    
            e.routingPath='a3s/dataMigrationBucket/dataGovernance/dataGovernanceList/dataGovernanceSummary'  
        });
        let list3 = await this.util.handleRequest('get','a3s_dataAnalytics_allProjects',[],null,null,null,true)
        list3.forEach(e => { e.searchProject=e.Project_Name
            e.projectTitle= 'Data Analytics'   
            e.routingPath='a3s/dataMigrationBucket/dataAnalytics/dataAnalyticsList/daSummary'   
        });
        let list4 = await this.util.handleRequest('get','a3s_dataOps_getAllProject',[],null,null,null,true)
        list4.forEach(e => { e.searchProject=e.projectName
            e.projectTitle= 'DataOps'    
            e.routingPath='a3s/dataMigrationBucket/dataOps/dataOpsList/dataOpsSummary'  
        });
        this.searchBuckets=[...list,...list1,...list2,...list3,...list4]

       
    }

    getAllSubscription(){
        // a3s_subscription_getAll
        return this.utilService.handleRequest('get','a3s_subscription_getOnboardedA3s',[],null,null,null,true).then(res=>{
            if(!res || !res.recordset.length) return;
            var unique = this.utilService.unique(res.recordset,['subscriptionId'])
            this.utilService.setCache('subscriptionList',unique)
        })
    }

    getOrgDetails(){
        return this.utilService.handleRequest('get','a3s_onboarded_orgDetails',[],null,null,null,true).then(res=>{
            if(!res || !res.recordset.length) return;
            this.utilService.setCache('organizationDetails',res.recordset[0])
        })
    }

    getA3sUserDetails(){
        let keys=Object.keys(sessionStorage);
        let userObjectId;
        for(let i=0;i<keys.length;i++){
          try{
            if(JSON.parse(sessionStorage.getItem(keys[i])).localAccountId){
              userObjectId=JSON.parse(sessionStorage.getItem(keys[i])).localAccountId;
              break;
            }
          }catch(e){}
        }
        return this.utilService.handleRequest('get','a3s_a3sAccess_userDetails',[userObjectId],null,null,null,true).then(res=>{
            if(!res || !res.length) return localStorage.setItem('a3sUserInfo',null);
            this.a3sUserDetails = res[0]
            localStorage.setItem('a3sUserInfo',JSON.stringify(this.a3sUserDetails))
        })
    }

    getAllSubscriptionFromAzure(){
        // a3s_subscription_getAll
        return this.utilService.handleRequest('get','a3s_subscription_getAll',[],null,null,null,true).then(res=>{
            if(!res || !res.length) return;
            var unique = this.utilService.unique(res,['SubscriptionID'])
            this.utilService.setCache('azureSubscriptionList',unique)
        })
    }

    profileImg : any;
    profileInitials = '';
    getimgsrc() {
        this.flowservice.getProfilePhoto().subscribe(
            result => {
                if (result.image) {
                    this.profileImg = result.image
                    this.utilService.setCache('userImage',this.profileImg)
                    //localStorage.setItem('userImage',this.profileImg);
                }
                else {
                    let name = '';
                    for (let i = 0; sessionStorage.key(i); i++) {
                        if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                            name = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['name']
                        }
                    }
                    let initials: any = name.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                    this.profileInitials = initials
                }
            },
            err => {
                let name = '';
                for (let i = 0; sessionStorage.key(i); i++) {
                    if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                        name = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['name']
                    }
                }
                let initials: any = name.match(/\b\w/g) || [];
                initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                this.profileInitials = initials
            }

        )

    }

    profileInfo = {};

    getProfileInfo() {
        this.flowservice.getProfileInfo().subscribe(
            result => {
                if (result[0].displayName) {
                    this.profileInfo = result[0];
                }
                else {
                    for (let i = 0; sessionStorage.key(i); i++) {
                        if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                            this.profileInfo['displayName'] = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['name']
                            this.profileInfo['mail'] = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['username']
                        }
                    }
                }
                localStorage.setItem('email', this.profileInfo['mail']);
                if (localStorage.getItem('email') == JSON.parse(localStorage.getItem('UserInfo')).GlobalAdmin) {
                    console.log("global admin true")
                    this.globalAdmin = true;
                }
                else {
                    this.globalAdmin = false;
                }
            },
            err => {
                for (let i = 0; sessionStorage.key(i); i++) {
                    if (sessionStorage.key(i).indexOf('login.windows.net-' + JSON.parse(localStorage.getItem('cred')).TenantID) != -1) {
                        this.profileInfo['displayName'] = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['name']
                        this.profileInfo['mail'] = JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))['username']
                    }
                }
                localStorage.setItem('email', this.profileInfo['mail'])
            }
        )
    }

    result;
    unique = []
    Uniqueelements = [];
    herlo = {
        "subsID": '',
        'Data': []
    }
    finalArr = []

    getRGData() {
        var value = localStorage.getItem('id');
        console.log(value, "4");
        return this.loginService.getRGData(value).subscribe(result => {
            console.log("--->", result)
            this.result = result
            if (this.result.userName && !this.result.Subscription_RGList) {
                var singleData = {};

                singleData['userName'] = this.result.userName;
                singleData['Subscription_RGList'] = []
                localStorage.setItem("UserInfo", JSON.stringify(singleData));
                localStorage.setItem("UserInfo1", JSON.stringify(singleData));
                this.getimgsrc();
                this.getProfileInfo();
                this.getAllBlueprintProjects()
                this.getDeployedBlueprintProjects()
                // this.utilService.hideLoader()
                this.loader = false;
                setTimeout(() => {
                    this.isApplicationLoaded = true
                    this.utilService.setObservable('isApplicationLoaded',true);
                }, 2000);
            }
            else if (this.result.userName.length > 0) {
                this.result["email"] = this.result.userName;

                //unique swt of subscriptions
                let uniqueSubsID: any = [...new Set(this.result.Subscription_RGList.map(d => d.SubscriptionID))]
                this.result.Subscription_RGList.forEach(element => {
                    if (element.Role == 'Contributor' || element.Role == 'Owner') {
                        if (element.State == 'Enabled') {
                            uniqueSubsID.forEach(ele => {
                                if (ele == element.SubscriptionID) {
                                    var array11 = {
                                        "subsID": element.SubscriptionID,
                                        "data": element
                                    }
                                    this.Uniqueelements.push(array11)
                                }
                            });
                        }
                    }
                });
                // console.log('we are iterating to', this.Uniqueelements.length - 1)
                for (var i = 0; i <= this.Uniqueelements.length - 1; i++) {
                    if (i > 0 && i < this.Uniqueelements.length - 1) {
                        if (this.Uniqueelements[i].subsID == this.Uniqueelements[i + 1].subsID) {
                            var newel = {
                                "SubscID": this.Uniqueelements[i].subsID,
                                "Data1": this.Uniqueelements[i].data,
                                "Data2": this.Uniqueelements[i + 1].data
                            }
                            // console.log(newel)
                            this.unique.push(newel)
                        }
                        else {
                            var newel11 = {
                                "SubscID": this.Uniqueelements[i].subsID,
                                "Data1": this.Uniqueelements[i].data
                            }
                            this.unique.push(newel11)
                        }
                    }
                    else {
                        // console.log('in else condition')
                        var newel1 = {
                            "SubscID": this.Uniqueelements[i].subsID,
                            "Data1": this.Uniqueelements[i].data,
                        }
                        this.unique.push(newel1)
                        // console.log(this.unique)
                    }
                }
                this.unique.forEach(pave => {
                    if (pave.Data2 != undefined) {
                        pave.Data2.ResourceGroups.forEach(els => {
                            pave.Data1.ResourceGroups.push(els)
                        });
                        // console.log(pave.Data2)
                        //creating unique pave1 ResourceGroups
                        var cleaned = [];
                        pave.Data1.ResourceGroups.forEach(function (itm) {
                            var unique = true;
                            cleaned.forEach(function (itm2) {
                                if (JSON.stringify(itm) === JSON.stringify(itm2)) unique = false;
                            });
                            if (unique) cleaned.push(itm);
                        });
                        this.herlo.subsID = pave.Data1.SubscriptionID
                        this.herlo.Data.push(cleaned)
                        this.finalArr.push(this.herlo)
                    }
                })
                this.unique.forEach(item => {
                    this.finalArr.forEach(element => {
                        if (element.subsID == item.SubscID) {
                            item.Data1.ResourceGroups = [];
                            element.Data.forEach(el => {
                                el.forEach(finalelements => {
                                    item.Data1.ResourceGroups.push(finalelements)
                                });
                            });
                        }
                    });
                })
                var temp = {}
                // Store each of the elements in an object keyed of of the name field.  If there is a collision (the name already exists) then it is just replaced with the most recent one.
                for (var i = 0; i < this.unique.length; i++) {
                    temp[this.unique[i].SubscID] = this.unique[i];
                }
                // Reset the array in varjson
                this.unique = [];
                // Push each of the values back into the array.
                for (var o in temp) {
                    this.unique.push(temp[o]);
                }
                // console.log('unique???', this.unique)
                // console.log(this.unique)
                var userInfo1 = { Subscription_RGList: [] };
                for (let i = 0; i < this.unique.length; i++) {
                    if (this.unique[i].Data1) {
                        userInfo1.Subscription_RGList.push(this.unique[i].Data1);
                        //  console.log(this.unique[i].Data1);
                    }
                }
                // console.log(userInfo1);
                localStorage.setItem('UserInfo1', JSON.stringify(userInfo1));
                localStorage.setItem('Resources', JSON.stringify(this.unique))
                localStorage.setItem("UserInfo", JSON.stringify(this.result));
                this.getimgsrc();
                this.getProfileInfo();
                this.getAllBlueprintProjects()
                this.getDeployedBlueprintProjects()
                // this.utilService.hideLoader()
                this.loader = false;
                setTimeout(() => {
                    this.isApplicationLoaded = true
                    this.utilService.setObservable('isApplicationLoaded',true);
                }, 2000);
            }
            else {
                // this.errors = true
                // this.showloader = false
                // this.err = "Wrong Credentials"
            }

        }, error => {
            // this.errors = true
            // this.showloader = false
            // this.err = "Wrong Credentials"
        })
    }

    openSupportChatBot(){
        this.flowservice.ShowChatbot();
    }

    checkAccessControl(){
        return this.a3sUserDetails && this.a3sUserDetails.scope && this.a3sUserDetails.scope.some(e=>e.id == 63)
    }

}

// !! notificaiton subscription mei comment pda hua tha!!

// var get_Email = JSON.parse(localStorage.getItem('UserInfo')).email
// var Timestamp = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
// let payloadtoSend = {
//     "Operation":"SendActivityLog",
//     "Email":get_Email,
//     "Name":this.userName,
//     "Activity":this.message,
//     "OU":this.OU,
//     "timestamp":Timestamp
// }
//   var data:any=message;
// if(!(data.status)){
//   this.flowservice.submittodb(payloadtoSend).subscribe(result=>{
//   console.log('Successfully submitted to db!')
// })
// }