<app-new-resource-group *ngIf="show" [anchor]="anchor" [show]="show" [projectData]="projectData" [newResourceGroup]="newResourceGroup" [resourceGroupList]="resourceGroupList" (onToggle)="onToggle($event)"></app-new-resource-group>
<div class="sidebar-right">
    <div>
    <div class="header">
        <div class="d-flex">
        <h4>Availability set</h4>
        <div class="text-right full-width">
            <button type="button" class="close pull-right" aria-label="Close" (click)="close();">
            <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
            </button>
        </div>
        </div>
    </div>
    <div class="body">
        <div class="form-group">
                    <div class="label-div">
                        <label for="resourceGroup" class="control-label">Resource group<span class="mandatory">*<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A resource group is a collection of resources that share the same lifecycle, permissions, and policies." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span></label>
                    </div>
                    <div class="input-box">
                        <ng-select [items]="resourceGroupList" bindLabel="name" bindValue="name" [(ngModel)]="availabilitySetmodel.resourceData.resourceGroup" name="resourceGroups" placeholder="Choose resource group" [clearable]="false" required>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 0px;padding-right: 13.33px;"><img src="/assets/icons/delete_new.png" alt="" (click)="deleteNewResourceGroup(item,index)"></span>
                                <span *ngIf="item.resourceGroupId" style="position: absolute;right: 24px;padding-right: 11.33px;"><img src="/assets/icons/edit.png" alt="" (click)="editNewResourceGroup(item,index)"></span>
                            </ng-template>
                        </ng-select>
                    </div> 
                    <div class="add-another-tag">
                    <p class="blue-text text-12-18 p-blue-btn mb-0 k-button" #anchor (click)="onToggle(null)">Create new</p>        
                    </div>
                </div> 
        <div class="form-group">
        <div class="label-div">
            <label for="name" class="control-label">Name<span class="mandatory">*</span></label>
        </div>
        <div class="input-box">
            <input type="text" class="form-control"
            [class.border-danger]="inputError?.nameLen || inputError?.nameValid || inputError?.nameDupCheck" id="name"
            autocomplete="off" [(ngModel)]="availabilitySetmodel.resourceName" placeholder="" name="name"
            (keyup)="checkVmASName($event,80)" required>
            <p class="text-danger" *ngIf="inputError?.nameLen">The name must be between 1 and 80 characters.</p>
            <p class="text-danger" *ngIf="inputError?.nameValid">The name must begin with a letter or number, end with a
            letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.</p>
            <p class="text-danger" *ngIf="inputError?.nameDupCheck">This availability set is already there in
            subscription</p>
        </div>
        </div>
        <div class="add-another-tag">
        <p class="blue-text cursor-pointer lbl-btn" *ngIf="!showView" (click)="hideShow()"><img
            src="/assets/icons/downward-arrow.svg" style="padding-right: 4px;"> View advanced settings (Optional)</p>
        <p class="blue-text cursor-pointer lbl-btn" *ngIf="showView" (click)="hideShow()"><img
            src="/assets/icons/upward-arrow.svg"> Hide advanced settings</p>
        </div>
        
        <div *ngIf="showView">
            <div class="form-group">
                <div class="label-div">
                <label for="faultDomains" class="control-label">Fault domains<span class="mandatory"><img
                        class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the same fault domain share 
                                a common power source and physical network switch." matTooltipClass="nav-tooltips"
                        matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="number" class="form-control remove-arrows" id="faultDomains" autocomplete="off"
                    [class.border-danger]="inputError?.asFaultDomainsLen || inputError?.asFaultDomainsMaxLen"
                    [(ngModel)]="availabilitySetmodel.resourceData.faultDomains" placeholder="" name="faultDomains"
                    (keyup)="checkfaultDomains($event,availabilitySetmodel.resourceData)" required>
                <p class="text-danger" *ngIf="inputError?.asFaultDomainsLen">Fault domains must be at least 1</p>
                <p class="text-danger" *ngIf="inputError?.asFaultDomainsMaxLen">Fault domains can not be at more than 3
                </p>
                </div>
            </div>
            <div class="form-group">
                <div class="label-div">
                <label for="updateDomains" class="control-label">Update domains<span class="mandatory"><img
                        class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Virtual machines in the same update domain will be restarted 
                                together during planned maintenance. Azure never restarts 
                                more than one update domain at a time" matTooltipClass="nav-tooltips"
                        matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                <input type="number" class="form-control remove-arrows"
                    [class.border-danger]="inputError?.asUpdateDomainsLen || inputError?.asUpdateDomainsMaxLen"
                    [disabled]="availabilitySetmodel?.resourceData?.faultDomains==1" id="updateDomains" autocomplete="off"
                    [(ngModel)]="availabilitySetmodel.resourceData.updateDomains" placeholder="" name="updateDomains"
                    (keyup)="checkUpdateDomains($event,20)" required>
                <p class="text-danger" *ngIf="inputError?.asUpdateDomainsLen">Update domains must be at least 1</p>
                <p class="text-danger" *ngIf="inputError?.asUpdateDomainsMaxLen">Update domains can not be at more than 20
                </p>
                <p class="text-muted-msg" *ngIf="availabilitySetmodel?.resourceData?.faultDomains==1">The update domain count must be 1 when fault
                    domain count is 1.</p>
                </div>
            </div>
            <div class="form-group" style="margin-bottom: 10px !important;">
                <div class="label-div">
                    <label for="useManagedDisks" class="control-label ">Use managed disks<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="Set this to 'Yes' if you plan to create virtual machines in this 
                        availability set with managed disks." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box d-flex">
                    <label class="col-sm-5 radio-label" style="padding-left: 0px !important;"><input type="radio" name="useManagedDisks" [value]="'Classic'" ([ngModel])="availabilitySetmodel.resourceData.useManagedDisks" [checked]="availabilitySetmodel.resourceData.useManagedDisks=='Classic'" (change)="availabilitySetmodel.resourceData.useManagedDisks=$event.target.value;"><span>No (Classic)</span></label>
                    <label class="col-sm-5 radio-label" style="padding-left: 0px !important;"><input type="radio" name="useManagedDisks" [value]="'Aligned'" ([ngModel])="availabilitySetmodel.resourceData.useManagedDisks" [checked]="availabilitySetmodel.resourceData.useManagedDisks=='Aligned'" (change)="availabilitySetmodel.resourceData.useManagedDisks=$event.target.value;"><span>Yes (Aligned)</span></label>                    
                </div>             
            </div>
            <div class="form-group">
                <div class="label-div">
                    <label for="proxPlacGroup" class="control-label ">Proximity placement group<span><img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="A proximity placement group is a logical grouping used to make 
                        sure that Azure compute resources are physically located 
                        close to each other. Proximity placement groups are useful
                        for workloads where low latency is a requirement." matTooltipClass="nav-tooltips" matTooltipShowDelay="500" [matTooltipPosition]="'above'"></span></label>
                </div>
                <div class="input-box">
                    <ng-select [items]="proxPlacGroupList" class="no-data"  notFoundText="No proximity placement group" bindLabel="name" bindValue="id" [(ngModel)]="availabilitySetmodel.resourceData.proxPlacGroup" name="proxPlacGroup" placeholder="" [clearable]="false"></ng-select>
                </div>             
            </div>
            <div class="form-group">
                <div class="label-div d-flex">
                <label class="control-label " style="min-width: max-content;">Tag name </label>
                <label class="control-label " style="min-width: max-content;padding-left: 6.5px !important;">Value
                </label>
                <label style="padding-left: 6px !important;width: 18px;"
                    *ngIf="availabilitySetmodel.resourceData&&availabilitySetmodel.resourceData.tagList&&availabilitySetmodel.resourceData.tagList.length>1"></label>
                <!-- <span class="">  <img src="/assets/Infra/info.svg" alt="" matTooltip="Tag names are case-insensitive and are limited to 512 characters." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></span> -->
                </div>
                <div *ngFor="let item of availabilitySetmodel.resourceData.tagList;let i = index">
                <div class="row" style="margin: 0px; padding: 0px;flex-flow: row;">

                    <div class="input-box" style="padding-bottom: 6px;">
                    <input type="text" class="form-control" id="tagVMName" autocomplete="off" [(ngModel)]="item.tagName"
                        name="tagVMName" (keyup)="checkTagInput(item,512,i)">
                    </div>

                    <div style="padding-left: 6px;padding-right: 6px;">:</div>

                    <div class="input-box">
                    <input type="text" class="form-control" id="tagVMValue" autocomplete="off" [(ngModel)]="item.value"
                        name="tagVMValue" (keyup)="checkTagInput(item,256)">
                    </div>

                    <span style="padding-left: 6px;"><img src="/assets/icons/delete.png" (click)="deleteTagVM(i)"
                        *ngIf="availabilitySetmodel.resourceData.tagList.length>1"></span>
                </div>
                <p class="text-danger" *ngIf="item.maxTagNameLen">Invalid tag name. The maximum supported length is
                    512.</p>
                <p class="text-danger" *ngIf="item.specialCharTagName">The following characters are not supported:
                    <>%&\?/.</p>
                <p class="text-danger" *ngIf="item.duplicateTag">Tag Name Already added here</p>
                <p class="text-danger" *ngIf="item.maxTagValueLen">Invalid tag value. The maximum supported length
                    is 256.</p>
                </div>

                <div class="add-another-tag">
                <p class="blue-text cursor-pointer lbl-btn" (click)="addTagVM()">Add another tag</p>
                </div>
            </div>

            <div class="form-group">
                <div class="label-div">
                <label for="comment" class="control-label">Description</label>
                </div>
                <div class="input-box">
                <textarea name="comment" class="comment-box" [class.border-danger]="inputError?.VMdescription"
                    [(ngModel)]="availabilitySetmodel.comments" placeholder="Describe the purpose of the resource"
                    (keyup)="VmDescription($event,300)"></textarea>
                <p class="text-danger" *ngIf="inputError?.VMdescription">Description cannot exceed 300 characters.</p>
                </div>
            </div>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
        <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='new'"
            [class.disabled]="checkDataDisabled()" (click)="triggerBtn('save')">Save</button>
        <button class="btn btn-btn-primary" *ngIf="sideSheetViewType=='edit'"
            [class.disabled]="checkDataDisabled()" (click)="triggerBtn('update')">Update</button>
        </div>
    </div>
    </div>
</div>