import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-doughnut-chart',
  template: `<div echarts [loading]='true' [options]="options" [style.height]="data.height?data.height+'px':''"></div>`,
})
export class DoughnutChartComponent implements OnChanges {
  options: any = {};
  @Input('data') data: any ={};
  constructor() { }

  ngOnChanges() {
    console.log(this.data);
    var chartLabelColors = ['#1DE4BD', '#FF4B68'];
    var data = this.data.data
    var total = this.data.total ||  this.getTotal(data)
    var labelFontSize=this.data.labelFontSize?this.data.labelFontSize:45;
    var lineHeight=this.data.lineHeight?this.data.lineHeight:52;
    var legendType=this.data.legendType?this.data.legendType:undefined;
    var seriesCenter=this.data.seriesCenter?this.data.seriesCenter:undefined;
    this.options = {
      color: this.data.color || chartLabelColors,
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ',
        padding: [10],
        textStyle: {
          fontSize: '11',
        },
      },
      legend: {
          bottom: '2%',
          left: 'left',
          type:legendType,
          data: this.data.legendData || []
      },
      series: [
          {
              name: 'Simple Pie Chart',
              type: 'pie',
              radius: ['40%', '70%'],
              center:seriesCenter,
              //height:this.data.height?this.data.height:undefined,
              avoidLabelOverlap: true,
              label: {
                color: '#000',
                fontSize: '20',
                position: 'center',
                formatter: function (params){
                  return `{a|${total}}` + '\n' + '{b|Total}'; // Use sum variable here
                },
                rich: {
                  a: {
                      color: 'black',
                      lineHeight: lineHeight,
                      fontSize:labelFontSize,//45,
                  },
                  b:{
                    lineHeight: 18,
                    fontSize:12,
                  }
                }
              },
              labelLine: {
                show: false,
              },
              // avoidLabelOverlap: false,
              // label: {
              //   normal: {
              //       show: true,
              //       position: 'center'
              //   },
              //   emphasis: {
              //       show: true,
              //       textStyle: {
              //           fontSize: '30',
              //           fontWeight: 'bold'
              //       }
              //   }
              // },
              // labelLine: {
              //     show: true
              // },
              data: data
          }
      ]
  };
  }


  getTotal(data){
    return data.reduce((v,i)=>{
      return v+i.value;
    },0)
  }
}
