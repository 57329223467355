<!-- <div class="metrics-content">
    <div class="header-container">
        <h2 class="title">Metrics</h2>
    </div>

    
     <div class="custom-filter-wrapper flex-custom ">
        <div class="custom-filter">
            <span class="text">Subscription</span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey"  placeholder="Search subscription"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="checkbox-cs" *ngFor="let data of filterObject?.subscriptionList; index as i">
                    <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter($event,data,'subscription')" checked>
                    <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.subscriptionName}}</label>
                </div>
                <div class="mt-3" *ngIf="!filterObject?.subscriptionList?.length">
                    <p  class="content-14 blur-text">No filter found</p>
                </div>
            </div>
        </div>
        <div class="custom-filter">
            <span class="text">Resource Group</span>
            <div class="filter-dropdown" id="filter-dropdown" >
                <div class="enlist-srch">
                    <input class="form-control" [(ngModel)]="searchKey"  placeholder="Search resource groups"/>
                    <img src="assets/icons/search.png">
                </div>
                <div class="checkbox-cs" *ngFor="let data of filterObject?.resourceGroupList; index as i">
                    <input type="checkbox" class="form-check-inline" id="f_listItem_{{i}}"  name="f_listItem_{{i}}" (change)="triggerFilter($event,data,'subscription')" checked>
                    <label class="value" for="f_listItem_{{i}}" id="f_listItemName_{{i}}">{{data?.resourceGroup}}</label>
                </div>
                <div  class="mt-3" *ngIf="!filterObject?.resourceGroupList?.length">
                    <p  class="content-14 blur-text">No filter found</p>
                </div>
            </div>
        </div>
        
      </div>
      
   
</div> -->


<div class="dashboard-body">

    <div class="data-wrapper mt-3">
        <div class="flex-custom no-wrap">
            <div class="header">
              <h4 class="header-text">Overall Health</h4>
              <p class="blur-text">Refreshed:{{ refreshedTimeFormat(refreshedTime.resources)}} . {{getTimeFilterText(resources.currentFilter)}}</p>
            </div>
            <div class="flex-custom no-wrap ml-auto">
                <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                matTooltipShowDelay="500" (click)="refreshData('resources')"><img
                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                <div class="cal-wrap">
                    <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                    matTooltipShowDelay="500" (click)="openFilter('customFilter0')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                    <div class="cal-box"  id="customFilter0">
                        <div class="cal-inner">
                        <div class="select-sec">
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',resources)">Last 30 minutes</div>
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',resources)">Last hour</div>
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',resources)">Last 4 hours</div>
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',resources)">Last 12 hours</div>
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',resources)">Last 24 hours</div>
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',resources)">Last 48 hours</div>
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',resources)">Last 3 days</div>
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',resources)">Last 7 days</div>
                            <div class="select-item" [class.active]="resources?.currentFilter?.type == 'custom'" (click)="openModal(openCal,resources)">Custom</div>
                        </div>
                        </div>
                    </div>
                </div>
                <span class="action-icon ml-2" matTooltip="Configure Health Thresholds" matTooltipClass="nav-tooltips"
                    matTooltipShowDelay="500" (click)="showHealthThreshold = true"><img
                    src="/assets/common-icons/health-threshold.svg" alt=""></span>
            </div>
        </div>
        <div class="custom-fixed-tables m-0" *ngIf="!resources.showLoader">
            <table class="table">
                <thead>
                  <tr>
                    <th>
                        <div class="flex-custom no-wrap">
                            <span>Availability (%)</span>
                            <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resourceList','resourceName')"> -->
                        </div>
                    </th>
                    <th>
                        <div class="flex-custom no-wrap">
                            <span>Server response time (ms)</span>
                            <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resourceList','availabilty')"> -->
                        </div>
                    </th>
                    <th>
                        <div class="flex-custom no-wrap">
                            <span>Failed requests</span>
                            <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resourceList','request')"> -->
                        </div>
                    </th>
                    <th>
                      <div class="flex-custom no-wrap">
                          <span>CPU usage (%)</span>
                          <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resourceList','latency')"> -->
                      </div>
                    </th>
                    <th>
                      <div class="flex-custom no-wrap">
                          <span>Memory available (mb)</span>
                          <!-- <img class="sort" src="assets/icons/sort.png" (click)="sortAll('resourceList','authenticationError')"> -->
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr class="cursor-pointer">
                      <td>
                          <div class="flex-custom no-wrap">
                            <span class="dot-status" [ngClass]="getMetricHealth(resources.availability,'availability')"></span>{{resources.availability | twoDecimal}}
                          </div>
                      </td>
                      <td>
                        <div class="flex-custom no-wrap">
                            <span class="dot-status" [ngClass]="getMetricHealth(resources.serverResponse,'serverResponse')"></span>{{resources.serverResponse | twoDecimal}}
                        </div>
                      </td>
                      <td>
                        <div class="flex-custom no-wrap">
                            <span class="dot-status" [ngClass]="getMetricHealth(resources.failedRequest,'failedRequest')"></span>{{resources.failedRequest | twoDecimal}}
                        </div>
                      </td>
                      <td>
                        <div class="flex-custom no-wrap">
                            <span class="dot-status" [ngClass]="getMetricHealth(resources.cpuUsage,'cpuUsage')"></span>{{resources.cpuUsage | twoDecimal}}
                        </div>
                      </td>
                      <td>
                        <div class="flex-custom no-wrap">
                            <span class="dot-status" [ngClass]="getMetricHealth(resources.memoryAvailable,'memoryAvailable')"></span>{{resources.memoryAvailable | twoDecimal}}
                        </div>
                      </td>
                  </tr>
                  <!-- <div class="col-md-12 mt-3" *ngIf="!resourceList?.length">
                    <p class="content-14 blur-text">No data Found</p>
                  </div> -->
                </tbody>
            </table>
        </div>
        <div class="loader-gif" *ngIf="resources?.showLoader">
            <img src="assets/icons/blue-loader.gif"/>
            <p>Getting data...</p>
        </div>
        <!-- <div class="dash-text-center" *ngIf="!resourceList?.length && !resources?.showLoader">
            <img src="/assets/health-monitoring/no-data.svg" alt="">
            <p class="text-14-22 bold-600 mt-3">No data found</p>
        </div> -->
     </div>

     
    <div class="row">
        <div class="col-md-4 left">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Availability(%)</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.appAvailablity)}} . {{getTimeFilterText(appAvailablityData.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('appAvailablity')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(availabilityPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter1')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter1">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',appAvailablityData)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',appAvailablityData)">Last hour</div>
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',appAvailablityData)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',appAvailablityData)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',appAvailablityData)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',appAvailablityData)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',appAvailablityData)">Last 3 days</div>
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',appAvailablityData)">Last 7 days</div>
                                        <div class="select-item" [class.active]="appAvailablityData?.currentFilter?.type == 'custom'" (click)="openModal(openCal,appAvailablityData)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="appAvailablityDataOptions" *ngIf="appAvailablityData?.xAxis?.length && appAvailablityDataOptions && !appAvailablityData?.showLoader"></div>
                <div class="loader-gif" *ngIf="appAvailablityData?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!appAvailablityData?.xAxis?.length && !appAvailablityData?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 middle">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Events</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.events)}} . {{getTimeFilterText(events.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('events')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(eventsPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter2')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter2">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',events)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',events)">Last hour</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',events)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',events)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',events)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',events)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',events)">Last 3 days</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',events)">Last 7 days</div>
                                        <div class="select-item" [class.active]="events?.currentFilter?.type == 'custom'" (click)="openModal(openCal,events)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="eventsOptions"
                    *ngIf="events?.xAxis?.length && eventsOptions && !events?.showLoader"></div>
                <div class="loader-gif" *ngIf="events?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!events?.xAxis?.length && !events?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Page Views</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.pageViews)}} . {{getTimeFilterText(pageViews.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('pageViews')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(pageViewsPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter3')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter3">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',pageViews)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',pageViews)">Last hour</div>
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',pageViews)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',pageViews)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',pageViews)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',pageViews)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',pageViews)">Last 3 days</div>
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',pageViews)">Last 7 days</div>
                                        <div class="select-item" [class.active]="pageViews?.currentFilter?.type == 'custom'" (click)="openModal(openCal,pageViews)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="pageViewsOptions"
                    *ngIf="pageViews?.xAxis?.length && pageViewsOptions && !pageViews?.showLoader"></div>
                <div class="loader-gif" *ngIf="pageViews?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!pageViews?.xAxis?.length && !pageViews?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>

        <div class="col-md-4 left">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Failed Request</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.failedRequests)}} . {{getTimeFilterText(failedRequest.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('failedRequests')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(failedRequestPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter4')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter4">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',failedRequest)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',failedRequest)">Last hour</div>
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',failedRequest)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',failedRequest)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',failedRequest)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',failedRequest)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',failedRequest)">Last 3 days</div>
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',failedRequest)">Last 7 days</div>
                                        <div class="select-item" [class.active]="failedRequest?.currentFilter?.type == 'custom'" (click)="openModal(openCal,failedRequest)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>                
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="failedRequestOptions" *ngIf="failedRequest?.xAxis?.length && failedRequestOptions && !failedRequest?.showLoader"></div>
                <div class="loader-gif" *ngIf="failedRequest?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!failedRequest?.xAxis?.length && !failedRequest?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 middle">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Server Performance (%)</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.performance)}} . {{getTimeFilterText(serverPerf.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('performance')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(serverPerformancePopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter5')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter5">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',serverPerf)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',serverPerf)">Last hour</div>
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',serverPerf)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',serverPerf)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',serverPerf)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',serverPerf)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',serverPerf)">Last 3 days</div>
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',serverPerf)">Last 7 days</div>
                                        <div class="select-item" [class.active]="serverPerf?.currentFilter?.type == 'custom'" (click)="openModal(openCal,serverPerf)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>                
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="serverPerfOptions" *ngIf="serverPerf?.xAxis?.length && serverPerfOptions  && !serverPerf?.showLoader"></div>
                <div class="loader-gif" *ngIf="serverPerf?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!serverPerf?.xAxis?.length && !serverPerf?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 right">
            <div class="graph-div">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Retention (%)</h4>
                            <p>Refreshed: {{ refreshedTimeFormat(refreshedTime.retention)}} . {{getTimeFilterText(retention.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="refreshData('retention')"><img src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips" matTooltipShowDelay="500" (click)="openPopup(retentionPopup)"><img src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter6')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter6">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',retention)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',retention)">Last hour</div>
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',retention)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',retention)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',retention)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',retention)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',retention)">Last 3 days</div>
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',retention)">Last 7 days</div>
                                        <div class="select-item" [class.active]="retention?.currentFilter?.type == 'custom'" (click)="openModal(openCal,retention)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>                
                </div>
                <div class="chart-ht" echarts [loading]='true' [options]="retentionDataOptions" *ngIf="retention?.xAxis?.length && retentionDataOptions && !retention?.showLoader"></div>
                <div class="loader-gif" *ngIf="retention?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!retention?.xAxis?.length && !retention?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>

        <div class="col-md-6 left">
            <div class="graph-div ht-big less">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Average CPU Usage</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.cpuPerf)}} . {{getTimeFilterText(cpuPerf.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('cpuPerf')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(avgCpuUsagePopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter7')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter7">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',cpuPerf)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',cpuPerf)">Last hour</div>
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',cpuPerf)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',cpuPerf)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',cpuPerf)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',cpuPerf)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',cpuPerf)">Last 3 days</div>
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',cpuPerf)">Last 7 days</div>
                                        <div class="select-item" [class.active]="cpuPerf?.currentFilter?.type == 'custom'" (click)="openModal(openCal,cpuPerf)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container  *ngIf="cpuPerf?.xAxis?.length && cpuPerfOptions  && !cpuPerf?.showLoader">
                    <div class="chart-ht" echarts [loading]='true' [options]="cpuPerfOptions"></div>
                    <div class="bot-strip">
                        <div class="str-name">Average CPU usage</div>
                        <div class="str-value">{{cpuPerf?.average}}%</div>
                    </div>
                </ng-container>
                <div class="loader-gif" *ngIf="cpuPerf?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!cpuPerf?.xAxis?.length && !cpuPerf?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div ht-big less">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Average Memory Usage</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.memoryPerf)}} . {{getTimeFilterText(memoryPerf.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('memoryPerf')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(avgMemUsagePopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter8')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter8">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',memoryPerf)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',memoryPerf)">Last hour</div>
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',memoryPerf)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',memoryPerf)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',memoryPerf)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',memoryPerf)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',memoryPerf)">Last 3 days</div>
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',memoryPerf)">Last 7 days</div>
                                        <div class="select-item" [class.active]="memoryPerf?.currentFilter?.type == 'custom'" (click)="openModal(openCal,memoryPerf)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container  *ngIf="memoryPerf?.xAxis?.length && memoryPerfOptions && !memoryPerf?.showLoader">
                    <div class="chart-ht" echarts [loading]='true' [options]="memoryPerfOptions"></div>
                    <div class="bot-strip">
                        <div class="str-name">Average memory usage</div>
                        <div class="str-value">{{memoryPerf?.average | bytesToSize}}</div>
                    </div>
                </ng-container>
                <div class="loader-gif" *ngIf="memoryPerf?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
               
                <div class="dash-text-center" *ngIf="!memoryPerf?.xAxis?.length && !memoryPerf?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>

        <div class="col-md-6 left">
            <div class="graph-div ht-big less">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Browser Exceptions</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.browserFailedRequest)}} . {{getTimeFilterText(browserFailedRequest.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('browserFailedRequest')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(browserExceptionPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter9')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter9">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',browserFailedRequest)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',browserFailedRequest)">Last hour</div>
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',browserFailedRequest)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',browserFailedRequest)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',browserFailedRequest)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',browserFailedRequest)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',browserFailedRequest)">Last 3 days</div>
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',browserFailedRequest)">Last 7 days</div>
                                        <div class="select-item" [class.active]="browserFailedRequest?.currentFilter?.type == 'custom'" (click)="openModal(openCal,browserFailedRequest)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="browserFailedRequest?.xAxis?.length && browserFailedRequestOptions && !browserFailedRequest?.showLoader">
                    <div class="chart-ht" echarts [loading]='true' [options]="browserFailedRequestOptions"></div>
                    <div class="bot-strip">
                        <div class="str-name">Average browser exceptions</div>
                        <div class="str-value">{{browserFailedRequest?.average}}</div>
                    </div>
                </ng-container>
                <div class="loader-gif" *ngIf="browserFailedRequest?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!browserFailedRequest?.xAxis?.length && !browserFailedRequest?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div ht-big less">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Server Exceptions</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.serverFailedRequests)}} . {{getTimeFilterText(serverFailedRequest.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('serverFailedRequests')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(serverExceptionPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter10')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter10">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',serverFailedRequest)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',serverFailedRequest)">Last hour</div>
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',serverFailedRequest)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',serverFailedRequest)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',serverFailedRequest)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',serverFailedRequest)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',serverFailedRequest)">Last 3 days</div>
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',serverFailedRequest)">Last 7 days</div>
                                        <div class="select-item" [class.active]="serverFailedRequest?.currentFilter?.type == 'custom'" (click)="openModal(openCal,serverFailedRequest)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="serverFailedRequest?.xAxis?.length && serverFailedRequestOptions && !serverFailedRequest?.showLoader">
                    <div class="chart-ht" echarts [loading]='true' [options]="serverFailedRequestOptions"></div>
                    <div class="bot-strip">
                        <div class="str-name">Average server exceptions</div>
                        <div class="str-value">{{serverFailedRequest?.average}}</div>
                    </div>
                </ng-container>
                <div class="loader-gif" *ngIf="serverFailedRequest?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!serverFailedRequest?.xAxis?.length && !serverFailedRequest?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>

        <div class="col-md-6 left">
            <div class="graph-div ht-big less mb-0">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Server Requests</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.serverRequests)}} . {{getTimeFilterText(serverRequests.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('serverRequests')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(serverRequestPopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter11')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter11">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',serverRequests)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',serverRequests)">Last hour</div>
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',serverRequests)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',serverRequests)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',serverRequests)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',serverRequests)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',serverRequests)">Last 3 days</div>
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',serverRequests)">Last 7 days</div>
                                        <div class="select-item" [class.active]="serverRequests?.currentFilter?.type == 'custom'" (click)="openModal(openCal,serverRequests)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="serverRequests?.xAxis?.length && serverRequestsOptions && !serverRequests?.showLoader">
                    <div class="chart-ht" echarts [loading]='true' [options]="serverRequestsOptions" ></div>
                    <div class="bot-strip">
                        <div class="str-name">Average server requests</div>
                        <div class="str-value">{{serverRequests?.average}}</div>
                    </div>
                </ng-container>
                <div class="loader-gif" *ngIf="serverRequests?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!serverRequests?.xAxis?.length && !serverRequests?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="graph-div ht-big less mb-0">
                <div class="heading">
                    <div class="d-flex">
                        <div class="about">
                            <h4>Server Response Time</h4>
                            <p>Refreshed: {{refreshedTimeFormat(refreshedTime.serverResponseTime)}} . {{getTimeFilterText(serverResponseTime.currentFilter)}}</p>
                        </div>
                        <div class="float-right flex-custom no-wrap">
                            <span class="action-icon" matTooltip="Refresh" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="refreshData('serverResponseTime')"><img
                                    src="/assets/health-monitoring/refresh.svg" alt=""></span>
                            <span class="action-icon ml-2" matTooltip="Table View" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openPopup(serverResponseTimePopup)"><img
                                    src="/assets/monitor/viewTable.svg" alt=""></span>
                            <div class="cal-wrap">
                                <span class="action-icon ml-2" matTooltip="Time range" matTooltipClass="nav-tooltips"
                                matTooltipShowDelay="500" (click)="openFilter('customFilter12')"><img src="/assets/health-monitoring/time.svg" alt="" class="mt-1"></span>
                                <div class="cal-box"  id="customFilter12">
                                    <div class="cal-inner">
                                    <div class="select-sec">
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'last30mins'" (click)="applyFilter('last30mins',serverResponseTime)">Last 30 minutes</div>
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'lasthr'" (click)="applyFilter('lasthr',serverResponseTime)">Last hour</div>
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'last4hr'" (click)="applyFilter('last4hr',serverResponseTime)">Last 4 hours</div>
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'last12hr'" (click)="applyFilter('last12hr',serverResponseTime)">Last 12 hours</div>
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'last24hr'" (click)="applyFilter('last24hr',serverResponseTime)">Last 24 hours</div>
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'last48hr'" (click)="applyFilter('last48hr',serverResponseTime)">Last 48 hours</div>
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'last3days'" (click)="applyFilter('last3days',serverResponseTime)">Last 3 days</div>
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'last7days'" (click)="applyFilter('last7days',serverResponseTime)">Last 7 days</div>
                                        <div class="select-item" [class.active]="serverResponseTime?.currentFilter?.type == 'custom'" (click)="openModal(openCal,serverResponseTime)">Custom</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="serverResponseTime?.xAxis?.length && serverResponseTimeOptions && !serverResponseTime?.showLoader">
                    <div class="chart-ht" echarts [loading]='true' [options]="serverResponseTimeOptions"></div>
                    <div class="bot-strip">
                        <div class="str-name">Average response time</div>
                        <div class="str-value">{{serverResponseTime?.average}} ms</div>
                    </div>
                </ng-container>
                <div class="loader-gif" *ngIf="serverResponseTime?.showLoader">
                    <img src="assets/icons/blue-loader.gif"/>
                    <p>Getting data...</p>
                </div>
                <div class="dash-text-center" *ngIf="!serverResponseTime?.xAxis?.length && !serverResponseTime?.showLoader">
                    <img src="/assets/health-monitoring/no-data.svg" alt="">
                    <p class="text-14-22 bold-600 mt-3">No data found</p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #openCal>
    <div class="medium-popup-wrapper">
      <div class="modal-top">
        <div class="popup-title">Choose time range</div>
        <button type="button" class="close ml-auto" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cal-inner">
          <div class="cal-outer">
            <div class="disp-text">From : {{ selectedData?.currentFilter.from | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.from" hour12Timer="true"></owl-date-time-inline>
          </div>
          <div class="cal-outer">
            <div class="disp-text">To : {{ selectedData?.currentFilter.to | a3sDateTimeFormat}}</div>
            <owl-date-time-inline [(ngModel)]="selectedData.currentFilter.to" hour12Timer="true"></owl-date-time-inline>
          </div>
        </div>
      </div>
      <div class="bottom-sec">
        <div class="left">Time selection is in UTC</div>
        <div class="right">
          <button class="btn-outline" (click)="closeModal()">Cancel</button>
          <button class="btn btn-btn-primary ml-2" (click)="applyCustomFilter()">Apply</button>
        </div>
      </div>
    </div>
</ng-template>


<!-- Pop Up Tables -->


<ng-template #availabilityPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Availability</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Availability test</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Application</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','applicationName')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Location</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','location')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>City</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','city')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Availability(%)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('appAvailablityData','percentage')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of appAvailablityData?.tableData?.rows">
                          <td>{{row?.name | displayTxt}}</td>
                          <td [title]="row?.applicationName">{{row?.applicationName | displayTxt}}</td>
                          <td>{{row?.location | displayTxt}}</td>
                          <td>{{row?.city | displayTxt}}</td>
                          <td>{{row?.percentage | twoDecimal | percent | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!appAvailablityData?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('availability')" *ngIf="appAvailablityData?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
</ng-template>

<ng-template #eventsPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Events</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Event name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('events','eventName')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Application</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('events','applicationName')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Operation name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('events','operation_Name')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Country</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('events','client_CountryOrRegion')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>City</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('events','client_City')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('events','timestamp')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of events?.tableData?.rows" class="cursor-pointer">
                          <td>{{row?.eventName | displayTxt}}</td>
                          <td [title]="row?.applicationName">{{row?.applicationName | displayTxt}}</td>
                          <td [title]="row?.operation_Name">{{row?.operation_Name | displayTxt}}</td>
                          <td>{{row?.client_CountryOrRegion | displayTxt}}</td>
                          <td>{{row?.client_City | displayTxt}}</td>
                          <td>{{row?.timestamp | a3sDateTimeFormat | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!events?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('events')" *ngIf="events?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #pageViewsPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Page Views</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Page name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('pageViews','pageName')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>URL</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('pageViews','url')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Country</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('pageViews','client_CountryOrRegion')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>City</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('pageViews','client_City')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('pageViews','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Duration(ms)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('pageViews','duration')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of pageViews?.tableData?.rows">
                          <td>{{row?.pageName | displayTxt}}</td>
                          <td>{{row?.url | displayTxt}}</td>
                          <td>{{row?.client_CountryOrRegion | displayTxt}}</td>
                          <td>{{row?.client_City | displayTxt}}</td>
                          <td>{{row?.timestamp | a3sDateTimeFormat | displayTxt}}</td>
                          <td>{{row?.duration | twoDecimal | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!pageViews?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('pageViews')" *ngIf="pageViews?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #avgCpuUsagePopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Average CPU Usage</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Application name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('cpuPerf','applicationName')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Country</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('cpuPerf','client_CountryOrRegion')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>City</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('cpuPerf','client_City')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('cpuPerf','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>CPU usage (avg)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('cpuPerf','value')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of cpuPerf?.tableData?.rows">
                          <td [title]="row?.applicationName">{{row?.applicationName | displayTxt}}</td>
                          <td>{{row?.client_CountryOrRegion | displayTxt}}</td>
                          <td>{{row?.client_City | displayTxt}}</td>
                          <td>{{row?.timestamp | a3sDateTimeFormat | displayTxt}}</td>
                          <td>{{row?.value | twoDecimal | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!cpuPerf?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('cpuPerf')" *ngIf="cpuPerf?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #avgMemUsagePopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Average Memory Usage</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>TimeStamp</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('memoryPerf','timeStamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Average</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('memoryPerf','average')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of memoryPerf?.tableData?.rows">
                          <td>{{row.timeStamp | a3sDateTimeFormat | displayTxt}}</td>
                          <td>{{row.average | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!memoryPerf?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('memoryPerf')" *ngIf="memoryPerf?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #browserExceptionPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Browser Exception</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('browserFailedRequest','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Browser exceptions</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('browserFailedRequest','exceptions/browser_sum')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of browserFailedRequest?.tableData?.rows">
                          <td>{{row?.timestamp | a3sDateTimeFormat| displayTxt}}</td>
                          <td>{{row['exceptions/browser_sum']}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!browserFailedRequest?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('browserFailedRequest')" *ngIf="browserFailedRequest?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #serverExceptionPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Server Exception</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverFailedRequest','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Server exceptions</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverFailedRequest','count_')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of serverFailedRequest?.tableData?.rows">
                          <td>{{row?.timestamp | a3sDateTimeFormat| displayTxt}}</td>
                          <td>{{row?.count_}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!serverFailedRequest?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('serverFailedRequest')" *ngIf="serverFailedRequest?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #serverRequestPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Server Requests</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverRequests','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Server requests</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverRequests','count_')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of serverRequests?.tableData?.rows">
                          <td>{{row?.timestamp | a3sDateTimeFormat| displayTxt}}</td>
                          <td>{{row?.count_}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!serverRequests?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('serverRequests')" *ngIf="serverRequests?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #serverResponseTimePopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Server Response Time</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Request name</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverResponseTime','requestName')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>URL</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverResponseTime','url')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Result code</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverResponseTime','resultCode')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverResponseTime','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Response time (ms)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverResponseTime','duration')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of serverResponseTime?.tableData?.rows">
                          <td>{{row?.requestName| displayTxt}}</td>
                          <td>{{row?.url | displayTxt}}</td>
                          <td>{{row?.resultCode}}</td>
                          <td>{{row?.timestamp | a3sDateTimeFormat |displayTxt}}</td>
                          <td>{{row?.duration | twoDecimal}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!serverResponseTime?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('serverResponseTime')" *ngIf="serverResponseTime?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #failedRequestPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Failed Requests Count</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedRequest','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Failed requests</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('failedRequest','failedCount')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of failedRequest?.tableData?.rows">
                          <td>{{row?.timestamp | a3sDateTimeFormat| displayTxt}}</td>
                          <td>{{row?.failedCount}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!failedRequest?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('failedRequest')" *ngIf="failedRequest?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #serverPerformancePopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Server Performance</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Time generated (UTC)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverPerf','timestamp')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Server performance(%)</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('serverPerf','performanceCounters/processCpuPercentageTotal_avg')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of serverPerf?.tableData?.rows" class="cursor-pointer">
                          <td>{{row?.timestamp | a3sDateTimeFormat | displayTxt}}</td>
                          <td>{{row['performanceCounters/processCpuPercentageTotal_avg'] | twoDecimal | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!serverPerf?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('serverPerf')" *ngIf="serverPerf?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #retentionPopup>
    <div class="medium-popup-wrapper2 width-700">
        <div class="modal-header">
            <div class="d-flex">
                <div class="heading-content"><h3>Retention</h3></div>
                <button class="cursor-pointer float-right" (click)="closeModal()"><img src="assets/Infra/blueprint/close.svg" alt=""></button>
            </div>
        </div>
        <div class="modal-body">
            <div class="custom-fixed-tables">
                <table class="table">
                    <thead>
                      <tr>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Used on</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','Cohort')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>Users</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','Users')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span><1w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','<1w')">
                            </div>
                        </th>
                        <th>
                            <div class="flex-custom no-wrap">
                                <span>1w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','+1w')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>2w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','+2w')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>3w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','+3w')">
                            </div>
                        </th>
                        <th class="borderBlueRight">
                            <div class="flex-custom no-wrap">
                                <span>4w</span>
                                <img class="sort" src="assets/icons/sort.png" (click)="sortAll('retention','+4w')">
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let row of retention?.tableData?.rows">
                          <td>{{row?.Cohort | displayTxt}}</td>
                          <td>{{row?.Users | displayTxt}}</td>
                          <td>{{row['<1w'] | displayTxt}}</td>
                          <td>{{row['+1w'] | displayTxt}}</td>
                          <td>{{row['+2w'] | displayTxt}}</td>
                          <td>{{row['+3w'] | displayTxt}}</td>
                          <td>{{row['+4w'] | displayTxt}}</td>
                      </tr>
                      <div class="col-md-12 mt-3" *ngIf="!retention?.tableData?.rows?.length">
                        <p class="content-14 blur-text">No data Found</p>
                      </div>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer d-flex">
            <button class="btn btn-outline" (click)="downloadFile('retention')" *ngIf="appAvailablityData?.tableData?.rows?.length">Download CSV</button>
            <button class="btn btn-btn-primary ml-auto" (click)="closeModal()">Done</button>
        </div>
        
    </div>
</ng-template>

<ng-container *ngIf="showHealthThreshold">
    <div class="sidebar-right single add-alert" >
        <div class="header">
            <div class="d-flex">
               <h4>Configure Health Thresholds</h4>
               <div class="text-right full-width">
                <button type="button" class="close pull-right" aria-label="Close" (click)="showHealthThreshold = false">
                  <span aria-hidden="true"><img src="assets/Infra/closeButton.svg" alt=""></span>
                </button>
              </div>
            </div>
        </div>

        <div class="body scroll-y">
            <form>
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Availability (%)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If availability (%) metric value is 100%, then web app is healthy." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width disabled" type="text" autocomplete="off" id="availability" name="availability" [(ngModel)]="healthThreshold.availability.threshold"  onlyNumber/>
                </div>    
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Server response time (ms)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If server response time (ms) is less than the threshold value, then web app is healthy." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="capacity" name="capacity" [(ngModel)]="healthThreshold.serverResponse.threshold" onlyNumber (keyup)="checkError($event.target.value,'serverResponse')"/>
                    <p class="text-danger" *ngIf="inputError.serverResponse">Value must be more than 0.</p>
                </div>    
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Failed requests<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If number of failed requests is less than the threshold value, then web app is healthy." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="latency" name="latency" [(ngModel)]="healthThreshold.failedRequest.threshold" onlyNumber (keyup)="checkError($event.target.value,'failedRequest')"/>
                    <p class="text-danger" *ngIf="inputError.failedRequest">Value must be more than 0.</p>
                </div>    
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Capacity used (%)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If capacity used (%) metric value is less than the threshold value, then web app is healthy." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="authentication" name="authentication" [(ngModel)]="healthThreshold.cpuUsage.threshold" onlyNumber (keyup)="checkError($event.target.value,'capacityUsed')"/>
                    <p class="text-danger" *ngIf="inputError.capacityUsed">Value must be between 1 and 100.</p>
                </div>    
                <div class="comm-mb">
                    <label class="flex-custom no-wrap">Memory available (mb)<img class="tooltip-icon" src="/assets/Infra/info.svg" alt="" matTooltip="If memory available (MB) metric value is more than the threshold value, then web app is healthy." matTooltipClass="nav-tooltips" matTooltipShowDelay="500"></label>
                    <input class="form-control full-width" type="text" autocomplete="off" id="throttling" name="throttling" [(ngModel)]="healthThreshold.memoryAvailable.threshold" onlyNumber (keyup)="checkError($event.target.value,'memoryAvailable')"/>
                    <p class="text-danger" *ngIf="inputError.memoryAvailable">Value must be between 1 and 100.</p>
                </div>     
            </form>
        </div>

        <div class="fixed-btm-btn flex-custom no-wrap">
            <span class="action-btn" (click)="resetThreshold()">Reset all values to default</span>
            <button class="btn btn-btn-primary" (click)="saveThreshold()" [class.disabled]="disableSaveBtn()">Save</button>
        </div>
    </div>
</ng-container>
