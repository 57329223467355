<div class="introduction">
   

    <p class="heading" id="1"  >Availability </p>
    <p class="para mb-0">Displays average percentage of time the app is available/reachable.    </p>
    <img src="assets\user-manual\apps-health-monitoring\Tile.svg" >
    <figcaption class="caption col-md-6">availability tile</figcaption>
 
          
</div>
