import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-stacked-bar-chart',
  template: `<div echarts [options]="options"></div>`,
})
export class HorizontalStackedBarChartComponent implements OnChanges {
  options: any = {};
  @Input('data') data: any = [];
  constructor() { }

  ngOnChanges() {
    this.options = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {            // Use axis to trigger tooltip
              type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
          }
      },
      legend: {
          data: ['Direct', 'Mail Ad', 'Affiliate Ad', 'Video Ad', 'Search Engine'],
          bottom: 0,
          borderWidth: 4,
          borderRadius: 100,
          left: 'left'
      },
      grid: {
          left: '3%',
          right: '4%',
          //bottom: '3%',
          containLabel: true
      },
      xAxis: {
          type: 'value'
      },
      yAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      series: [
          {
              name: 'Direct',
              type: 'bar',
              stack: 'total',
              barWidth: 25,
              label: {
                  show: true
              },
              emphasis: {
                  focus: 'series'
              },
              data: [320, 302, 301, 334, 390, 330, 320]
          },
          {
              name: 'Mail Ad',
              type: 'bar',
              stack: 'total',
              barWidth: 25,
              label: {
                  show: true
              },
              emphasis: {
                  focus: 'series'
              },
              data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
              name: 'Affiliate Ad',
              type: 'bar',
              stack: 'total',
              barWidth: 25,
              label: {
                  show: true
              },
              emphasis: {
                  focus: 'series'
              },
              data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
              name: 'Video Ad',
              type: 'bar',
              stack: 'total',
              barWidth: 25,
              label: {
                  show: true
              },
              emphasis: {
                  focus: 'series'
              },
              data: [150, 212, 201, 154, 190, 330, 410]
          },
          {
              name: 'Search Engine',
              type: 'bar',
              stack: 'total',
              barWidth: 25,
              label: {
                  show: true
              },
              emphasis: {
                  focus: 'series'
              },
              data: [820, 832, 901, 934, 1290, 1330, 1320]
          }
      ]
    };
  }

}
