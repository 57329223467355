<div class="introduction">


    <p class="heading" id="1">Create Budget and Set Alerts  </p>
    <ol>
        <img src="assets\user-manual\cm-and-optimize\Create-budget.svg" >
        <figcaption class="caption">Cost monitoring – set budgets</figcaption>

        <li>Select a project from left and click <b>Budget & Alerts</b>  tab on the right top corner. </li>
        <li>Set scope based on the filters (Subscription, Resource group, Resource). </li>
        <li>Provide <b>Name</b> and select <b>Billing period</b> from monthly, quarterly, or yearly. </li>
        <li>Select <b>Creation date</b> i.e., first day of first budget evaluation and <b>Expiration date.</b> </li>
        <li>Set the budget in <b>Amount.</b> </li>
        <li>Click<b> Next.</b> </li>
        <li>Set alert condition. </li>
        <img src="assets\user-manual\cm-and-optimize\Set-alerts.svg" >
        <figcaption class="caption"> Cost monitoring - set alert condition</figcaption>

        <li>Select <b>Type</b> (Alert to be created on Actual cost/Forecasted cost?).  </li>
        <li>Provide <b>% of Budget</b> (percentage of the amount on ‘Type’ selected above). </li>
        <li>Select <b>Action group</b> or click <b>Add another action group</b> to create new action group. </li>
        <li>Provide <b>Alert Recipients (email)</b> where email alerts will be sent, you can add multiple recipients.   </li>
        <li>Select <b>Language</b> or use default (English).  </li>
        <li>Click <b>Create.</b> </li>
    
    
    </ol>
  
</div>