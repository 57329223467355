import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { SymbolPaletteComponent, SymbolPalette, NodeModel, ConnectorModel, PaletteModel, ImageElement, DiagramComponent, IExportOptions, PrintAndExport, Diagram, DiagramConstraints } from '@syncfusion/ej2-angular-diagrams';
import { ExpandMode } from '@syncfusion/ej2-navigations';
import { filter } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
@Component({
  selector: 'app-architecture-blueprint',
  templateUrl: './architecture-blueprint.component.html',
  styleUrls: ['./architecture-blueprint.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArchitectureBlueprintComponent implements OnInit {


  constructor() { 
  
  }

  async ngOnInit(): Promise<void> {
  
  }

}
