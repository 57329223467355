<div class="introduction">
    <p class="heading" id="3">Oracle Server    </p>
    <ol>
       
                <li>Click on<b> Create new project.</b>  </li>
                <li>Select <b>Source data system – Oracle Server. </b> </li>
                <img src="assets\user-manual\data-&-AI\2.svg" >
                <figcaption class="caption">Oracle - Source system </figcaption>
                <li>Provide required details for Azure synapse as destination and Oracle server as the source system.  </li>
                <img src="assets\user-manual\data-&-AI\O-1.svg" >
                <figcaption class="caption">Oracle - source and target details</figcaption>
                <li>Click <b>Next</b> and wait for the source information to load completely. </li>
                <img src="assets\user-manual\data-&-AI\Oracle-dataLoading.png" >
                <figcaption class="caption">Oracle – Data Loading</figcaption>
                <li>Select table(s) to be migrated and click <b>Next.</b> </li>
                <img src="assets\user-manual\data-&-AI\O-2.svg" >
                <figcaption class="caption">Oracle – Data Preview </figcaption>
                <li>Click <b>Next</b> to migrate schema.  </li>
                <li>Click <b>Migrate data.</b> </li>
                <img src="assets\user-manual\data-&-AI\O-3.svg" >
                <figcaption class="caption">Oracle - Migrate data </figcaption>
                <li>Data migration progress will be displayed.  </li>
                <img src="assets\user-manual\data-&-AI\O-4.svg" >
                <figcaption class="caption">Oracle - Migration display </figcaption>
                <li>If data migration was successful, then Successful data migration message will be displayed. </li>
                <img src="assets\user-manual\data-&-AI\O-5.svg" >
                <figcaption class="caption">Oracle – Successful migration </figcaption>
                <li>In case of error/failure, a Failed data migration message will be displayed. </li>  
                <img src="assets\user-manual\data-&-AI\O-6.svg">
                <figcaption class="caption">Oracle – Failure Migration </figcaption>
                <li>In case of partial migration, Data migration partial success message will be displayed. </li>
                <img src="assets\user-manual\data-&-AI\O-7.svg" >
                <figcaption class="caption"> Oracle – Partial success  </figcaption>
                <li>Upon completion of the migration, project summary will be displayed. </li>
                <img src="assets\user-manual\data-&-AI\O-8.svg" >
                <figcaption class="caption"> Oracle - Summary </figcaption>
                <li>Click <b>View in A3S BI dashboard</b> button to view data in a dashboard.  </li>
                <img src="assets\user-manual\data-&-AI\SQL-BI.png" >
                <figcaption class="caption">Oracle - BI dashboard</figcaption> 
              
                
 
        
          
    </ol>
</div>
